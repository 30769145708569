import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { CITRPartialProgressDeletePagination } from './model';

export enum CITRPartialProgressActionTypes {
    CITRPartialProgressTemplateFileRequest = '[CITR Partial Progress] Template File Request',
    CITRPartialProgressTemplateFileSuccess = '[CITR Partial Progress] Template File Success',
    CITRPartialProgressTemplateFileError = '[CITR Partial Progress] Template File Error',
    CITRPartialProgressUploadLogRequest = '[CITR Partial Progress] Upload Log Request',
    CITRPartialProgressUploadLogSuccess = '[CITR Partial Progress] Upload Log Success',
    CITRPartialProgressUploadLogError = '[CITR Partial Progress] Upload Log Error',
    CITRPartialProgressDeleteRecordsRequest = '[CITR Partial Progress] Delete Records Request',
    CITRPartialProgressDeleteRecordsSuccess = '[CITR Partial Progress] Delete Records Success',
    CITRPartialProgressDeleteRecordsError = '[CITR Partial Progress] Delete Records Error',
    CITRPartialProgressSetStatusAndStartDate = '[CITR Partial Progress] Set Status and Start Date',
    CITRPartialProgressDeleteFilterPropertyUpdate = '[CITR Partial Progress] Delete Filter Property Update',
    CITRPartialProgressDeleteFilterReset = '[CITR Partial Progress] Delete Filter Reset',
    CITRPartialProgressPatchDeleteRecordRequest = '[CITR Partial Progress] Patch Delete Record Request',
    CITRPartialProgressPatchDeleteRecordSuccess = '[CITR Partial Progress] Patch Delete Record Success',
    CITRPartialProgressPatchDeleteRecordError = '[CITR Partial Progress] Patch Delete Record Error',
    CITRPartialProgressPatchAllDeleteRecordsRequest = '[CITR Partial Progress] Patch All Delete Records Request',
    CITRPartialProgressPatchAllDeleteRecordsSuccess = '[CITR Partial Progress] Patch All Delete Records Success',
    CITRPartialProgressPatchAllDeleteRecordsError = '[CITR Partial Progress] Patch All Delete Records Error',
    CITRPartialProgressValidateDataRequest = '[CITR Partial Progress] Validate Data Request',
    CITRPartialProgressValidateDataSuccess = '[CITR Partial Progress] Validate Data Success',
    CITRPartialProgressValidateDataError = '[CITR Partial Progress] Validate Data Error',
    CITRPartialProgressFileUploaded = '[CITR Partial Progress] File Uploaded',
    CITRPartialProgressClearInProgressSpinner = '[CITR Partial Progress] Clear In Progress Spinner',
    CITRPartialProgressDownloadOutputDataRequest = '[CITR Partial Progress] Download Output Data Request',
    CITRPartialProgressDownloadOutputDataSuccess = '[CITR Partial Progress] Download Output Data Success',
    CITRPartialProgressDownloadOutputDataError = '[CITR Partial Progress] Download Output Data Error',
    CITRPartialProgressValidateButtonStateRequest = '[CITR Partial Progress] Validate Button State Request',
    CITRPartialProgressValidateButtonStateSuccess = '[CITR Partial Progress] Validate Button State Success',
    CITRPartialProgressValidateButtonStateError = '[CITR Partial Progress] Validate Button State Error',
    CITRPartialProgressAddStartedLog = '[CITR Partial Progress] Add Started Log',
}

export class CITRPartialProgressTemplateFileRequest implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressTemplateFileRequest;

    constructor(public payload: string) {}
}

export class CITRPartialProgressTemplateFileSuccess implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class CITRPartialProgressTemplateFileError implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressTemplateFileError;

    constructor(public payload: string) {}
}

export class CITRPartialProgressAddStartedLog implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressAddStartedLog;
}

export class CITRPartialProgressUploadLogRequest implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressUploadLogRequest;
}

export class CITRPartialProgressUploadLogSuccess implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class CITRPartialProgressUploadLogError implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressUploadLogError;

    constructor(public payload: string) {}
}

export class CITRPartialProgressDeleteRequest implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressDeleteRecordsRequest;
}

export class CITRPartialProgressDeleteSuccess implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressDeleteRecordsSuccess;

    constructor(public payload: CITRPartialProgressDeletePagination) {}
}

export class CITRPartialProgressDeleteError implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressDeleteRecordsError;

    constructor(public payload: string) {}
}

export class CITRPartialProgressSetStatusAndStartDate implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class CITRPartialProgressDeleteFilterPropertyUpdate implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class CITRPartialProgressDeleteFilterReset implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressDeleteFilterReset;
}

export class CITRPartialProgressPatchDeleteRequest implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressPatchDeleteRecordRequest;

    constructor(public payload: { barcode: string; deleteState: boolean }) {}
}

export class CITRPartialProgressPatchAllDeleteRequest implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class CITRPartialProgressPatchDeleteSuccess implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressPatchDeleteRecordSuccess;
}

export class CITRPartialProgressPatchAllDeleteSuccess implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class CITRPartialProgressPatchDeleteError implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class CITRPartialProgressPatchAllDeleteError implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class CITRPartialProgressValidateDeleteRequest implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressValidateDataRequest;
}

export class CITRPartialProgressValidateDeleteSuccess implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressValidateDataSuccess;
}

export class CITRPartialProgressValidateDeleteError implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressValidateDataError;

    constructor(public payload: string) {}
}

export class CITRPartialProgressFileUploaded implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressFileUploaded;
}

export class CITRPartialProgressClearInProgressSpinner implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressClearInProgressSpinner;
}


export class CITRPartialProgressDownloadOutputDataRequest implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class CITRPartialProgressDownloadOutputDataSuccess implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class CITRPartialProgressDownloadOutputDataError implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class CITRPartialProgressValidateButtonStateRequest implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressValidateButtonStateRequest;
}

export class CITRPartialProgressValidateButtonStateSuccess implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class CITRPartialProgressValidateButtonStateError implements Action {
    readonly type = CITRPartialProgressActionTypes.CITRPartialProgressValidateButtonStateError;

    constructor(public payload: string) {}
}

export type CITRPartialProgressActions =
    | CITRPartialProgressTemplateFileRequest
    | CITRPartialProgressTemplateFileSuccess
    | CITRPartialProgressTemplateFileError
    | CITRPartialProgressUploadLogRequest
    | CITRPartialProgressUploadLogSuccess
    | CITRPartialProgressUploadLogError
    | CITRPartialProgressDeleteRequest
    | CITRPartialProgressDeleteSuccess
    | CITRPartialProgressDeleteError
    | CITRPartialProgressSetStatusAndStartDate
    | CITRPartialProgressDeleteFilterPropertyUpdate
    | CITRPartialProgressDeleteFilterReset
    | CITRPartialProgressPatchDeleteRequest
    | CITRPartialProgressPatchDeleteSuccess
    | CITRPartialProgressPatchDeleteError
    | CITRPartialProgressPatchAllDeleteRequest
    | CITRPartialProgressPatchAllDeleteSuccess
    | CITRPartialProgressPatchAllDeleteError
    | CITRPartialProgressValidateDeleteRequest
    | CITRPartialProgressValidateDeleteSuccess
    | CITRPartialProgressValidateDeleteError
    | CITRPartialProgressFileUploaded
    | CITRPartialProgressClearInProgressSpinner
    | CITRPartialProgressDownloadOutputDataRequest
    | CITRPartialProgressDownloadOutputDataSuccess
    | CITRPartialProgressDownloadOutputDataError
    | CITRPartialProgressValidateButtonStateRequest
    | CITRPartialProgressValidateButtonStateSuccess
    | CITRPartialProgressValidateButtonStateError
    | CITRPartialProgressAddStartedLog;
