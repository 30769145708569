import { Action, createAction, props } from '@ngrx/store';
import { ActivitiesConstraintsFilter, ActivityConstrainsReportDTO, ExceptionStatusDTO, HealthCleanlinessDTO, HealthFilter, OverdueDPLIFilter, OverdueExceptionsByPriorityDTO, OverdueExceptionsByPriorityFilters, OverdueExceptionsFilters, OverdueExceptionsShowHideColumns, SubsystemExceptionsDTO } from './model';
import * as moment from 'moment';
import { BaseResultDTO, UserDetail } from '../../common.model';
import { DetailedStatusDTO } from '../../details/model';


export enum HealthActionTypes {
    HealthCleanlinessFilterUpdate = '[Health Cleanliness] Filter Update',
    HealthCleanlinessFilterPropertyUpdate = '[Health Cleanliness] Filter Property Update',
    HealthCleanlinessFilterReset = '[Health Commitment Reliability] Filter Reset',
    HealthCleanlinessSetType = '[Health Commitment Reliability] Set Type',
    HealthCleanlinessRequest = '[Health Commitment Reliability] Request',
    HealthCleanlinessSuccess = '[Health Commitment Reliability] Success',
    HealthCleanlinessError = '[Health Commitment Reliability] Error',
    HealthCleanlinessSetWeekRange = '[Health Commitment Reliability] Set Week Range',

    HealthOverdueDPLIFilterUpdate = '[Health Overdue DPLI] Filter Update',
    HealthOverdueDPLIFilterPropertyUpdate = '[Health Overdue DPLI] Filter Property Update',
    HealthOverdueDPLIFilterReset = '[Health Overdue DPLI] Filter Reset',
    HealthOverdueDPLITableFilterReset = '[Health Overdue DPLI] Table Filter Reset',
    HealthOverdueDPLITableRequest = '[Health Overdue DPLI] Table Request',
    HealthOverdueDPLITableSuccess = '[Health Overdue DPLI] Table Success',
    HealthOverdueDPLITableError = '[Health Overdue DPLI] Table Error',
    HealthOverdueDPLIChartRequest = '[Health Overdue DPLI] Chart Request',
    HealthOverdueDPLIChartSuccess = '[Health Overdue DPLI] Chart Success',
    HealthOverdueDPLIChartError = '[Health Overdue DPLI] Chart Error',

    HealthOverdueDPLIExportToExcelRequest = '[Health Overdue DPLI] Export to Excel Request',
    HealthOverdueDPLIExportToExcelRequestSuccess = '[Health Overdue DPLI] Export to Excel Request Success',
    HealthOverdueDPLIExportToExcelRequestError = '[Health Overdue DPLI] Export to Excel Request Error',

    HealthOverdueExceptionsFilterPropertyUpdate = '[Health Overdue Exceptions] Filter Property Update',
    HealthOverdueExceptionsFilterStateUpdate = '[Health Overdue Exceptions] Filter State Update',
    HealthOverdueExceptionsFilterReset = '[Health Overdue Exceptions] Filter Reset',
    HealthOverdueExceptionsTableFilterReset = '[Health Overdue Exceptions] Table Filter Reset',
    HealthOverdueExceptionsColumnsVisibilityUpdate = '[Health Overdue Exceptions] Table Columns Visibility',
    HealthOverdueExceptionsTableRequest = '[Health Overdue Exceptions] Table Request',
    HealthOverdueExceptionsTableSuccess = '[Health Overdue Exceptions] Table Success',
    HealthOverdueExceptionsTableError = '[Health Overdue Exceptions] Table Error',
    HealthOverdueExceptionsChartRequest = '[Health Overdue Exceptions] Chart Request',
    HealthOverdueExceptionsChartSuccess = '[Health Overdue Exceptions] Chart Success',
    HealthOverdueExceptionsChartError = '[Health Overdue Exceptions] Chart Error',

    HealthOverdueExceptionsExportToExcelRequest = '[Health Overdue Exceptions] Export to Excel Request',
    HealthOverdueExceptionsExportToExcelRequestSuccess = '[Health Overdue Exceptions] Export to Excel Request Success',
    HealthOverdueExceptionsExportToExcelRequestError = '[Health Overdue Exceptions] Export to Excel Request Error',

    HealthOverdueExceptionsAddCommentRequest = '[Health Overdue Exceptions] Add Comment Request',
    HealthOverdueExceptionsAddCommentSuccess = '[Health Overdue Exceptions] Add Comment Request Success',
    HealthOverdueExceptionsAddCommentError = '[Health Overdue Exceptions] Add Comment Request Error',

    OverdueExceptionsByPriorityFilterPropertyUpdate = '[Overdue Exceptions By Priority] Filter Property Update',
    OverdueExceptionsByPriorityFilterStateUpdate = '[Overdue Exceptions By Priority] Filter State Update',
    OverdueExceptionsByPriorityFilterReset = '[Overdue Exceptions By Priority] Filter Reset',
    OverdueExceptionsByPriorityChartRequest = '[Overdue Exceptions By Priority] Chart Request',
    OverdueExceptionsByPriorityChartSuccess = '[Overdue Exceptions By Priority] Chart Success',
    OverdueExceptionsByPriorityChartError = '[Overdue Exceptions By Priority] Chart Error',
    OverdueExceptionsBySubsystemChartRequest = '[Overdue Exceptions By Subsystem] Chart Request',
    OverdueExceptionsBySubsystemChartSuccess = '[Overdue Exceptions By Subsystem] Chart Success',
    OverdueExceptionsBySubsystemChartError = '[Overdue Exceptions By Subsystem] Chart Error',
    OverdueExceptionsByPriorityExportToExcelRequest = '[Overdue Exceptions By Priority] Export to Excel Request',
    OverdueExceptionsByPriorityExportToExcelRequestSuccess = '[Overdue Exceptions By Priority] Export to Excel Request Success',
    OverdueExceptionsByPriorityExportToExcelRequestError = '[Overdue Exceptions By Priority] Export to Excel Request Error',

    ActivitiesConstraintsFilterReset = '[Activities Constraints] Filter Reset',
    ActivitiesConstraintsChartRequest = '[Activities Constraints] Chart Request',
    ActivitiesConstraintsChartSuccess = '[Activities Constraints] Chart Success',
    ActivitiesConstraintsChartError = '[Activities Constraints] Chart Error',
    ActivitiesConstraintsFilterStateUpdate = '[Activities Constraints] Filter State Update',
    ActivitiesConstraintsFilterSetWeekRange = '[Activities Constraints] Set Week Range',
    ITRConstraintsChartRequest = '[ITR Constraints] Chart Request',
    ITRConstraintsChartSuccess = '[ITR Constraints] Chart Success',
    ITRConstraintsChartError = '[ITR Constraints] Chart Error',
    ITRConstraintsFilterSetWeekRange = "[ITR Constraints] Set Week Range"
}

export class HealthCleanlinessFilterUpdate implements Action {
    readonly type = HealthActionTypes.HealthCleanlinessFilterUpdate;

    constructor(public payload: HealthFilter) {}
}

export class HealthCleanlinessFilterPropertyUpdate implements Action {
    readonly type = HealthActionTypes.HealthCleanlinessFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class HealthOverdueDPLIFilterUpdate implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLIFilterUpdate;

    constructor(public payload: OverdueDPLIFilter) {}
}

export class HealthOverdueDPLIFilterPropertyUpdate implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLIFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class HealthCleanlinessFilterReset implements Action {
    readonly type = HealthActionTypes.HealthCleanlinessFilterReset;
}

export class HealthOverdueDPLIFilterReset implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLIFilterReset;

    constructor(public payload = false) {}
}

export class HealthOverdueDPLITableFilterReset implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLITableFilterReset;

    constructor(public payload = false) {}
}

export class HealthCleanlinessSetType implements Action {
    readonly type = HealthActionTypes.HealthCleanlinessSetType;

    constructor(public payload: string) {}
}

export class HealthCleanlinessRequest implements Action {
    readonly type = HealthActionTypes.HealthCleanlinessRequest;
}

export class HealthCleanlinessSuccess implements Action {
    readonly type = HealthActionTypes.HealthCleanlinessSuccess;

    constructor(public payload: HealthCleanlinessDTO[]) {}
}

export class HealthCleanlinessError implements Action {
    readonly type = HealthActionTypes.HealthCleanlinessError;

    constructor(public error: string) {}
}

export class HealthOverdueDPLITableRequest implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLITableRequest;
}

export class HealthOverdueDPLITableSuccess implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLITableSuccess;

    constructor(public payload: BaseResultDTO<DetailedStatusDTO>) {}
}

export class HealthOverdueDPLITableError implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLITableError;

    constructor(public error: string) {}
}

export class HealthOverdueDPLIChartRequest implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLIChartRequest;
}

export class HealthOverdueDPLIChartSuccess implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLIChartSuccess;

    constructor(public payload: { name: string; value: number }[]) {}
}

export class HealthOverdueDPLIChartError implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLIChartError;

    constructor(public error: string) {}
}

export class HealthCleanlinessSetWeekRange implements Action {
    readonly type = HealthActionTypes.HealthCleanlinessSetWeekRange;

    constructor(public payload: moment.Moment) {}
}

export class HealthOverdueDPLIExportToExcelRequest implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLIExportToExcelRequest;
}

export class HealthOverdueDPLIExportToExcelRequestSuccess implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLIExportToExcelRequestSuccess;

    constructor(public payload: any) {
    }
}

export class HealthOverdueDPLIExportToExcelRequestError implements Action {
    readonly type = HealthActionTypes.HealthOverdueDPLIExportToExcelRequest;

    constructor(public error: any) {
    }
}

export class HealthOverdueExceptionsFilterStateUpdate implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsFilterStateUpdate;

    constructor(public payload: OverdueExceptionsFilters) {}
}
export class HealthOverdueExceptionsFilterPropertyUpdate implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class HealthOverdueExceptionsFilterReset implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsFilterReset;

    constructor(public payload = false) {}
}

export class HealthOverdueExceptionsColumnsVisibilityUpdate implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsColumnsVisibilityUpdate;

    constructor(public payload: OverdueExceptionsShowHideColumns) {}
}

export class HealthOverdueExceptionsTableFilterReset implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsTableFilterReset;

    constructor(public payload = false) {}
}

export class HealthOverdueExceptionsTableRequest implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsTableRequest;
}

export class HealthOverdueExceptionsTableSuccess implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsTableSuccess;

    constructor(public payload: BaseResultDTO<ExceptionStatusDTO>) {}
}

export class HealthOverdueExceptionsTableError implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsTableError;

    constructor(public error: string) {}
}

export class HealthOverdueExceptionsChartRequest implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsChartRequest;
}

export class HealthOverdueExceptionsChartSuccess implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsChartSuccess;

    constructor(public payload: { name: string; value: number }[]) {}
}

export class HealthOverdueExceptionsChartError implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsChartError;

    constructor(public error: string) {}
}

export class HealthOverdueExceptionsExportToExcelRequest implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsExportToExcelRequest;
}

export class HealthOverdueExceptionsExportToExcelRequestSuccess implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsExportToExcelRequestSuccess;

    constructor(public payload: any) {
    }
}

export class HealthOverdueExceptionsExportToExcelRequestError implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsExportToExcelRequestError;

    constructor(public error: any) {
    }
}

export class HealthOverdueExceptionsAddCommentRequest implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsAddCommentRequest;

    constructor(
        public payload: { entityId: string; description: string; mentions: UserDetail[] }
    ) {}
}

export class HealthOverdueExceptionsAddCommentSuccess implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsAddCommentSuccess;

    constructor(
        public payload: { entityId: string; description: string; mentions: UserDetail[] }
    ) {}
}

export class HealthOverdueExceptionsAddCommentError implements Action {
    readonly type = HealthActionTypes.HealthOverdueExceptionsAddCommentError;

    constructor(public payload: string) {}
}

export class OverdueExceptionsByPriorityFilterPropertyUpdate implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsByPriorityFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class OverdueExceptionsByPriorityFilterStateUpdate implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsByPriorityFilterStateUpdate;

    constructor(public payload: OverdueExceptionsByPriorityFilters) {}
}

export class OverdueExceptionsByPriorityFilterReset implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsByPriorityFilterReset;

    constructor(public payload = false) {}
}

export const overdueExceptionsByPriorityFilterColumnsReset = createAction(
    '[Overdue Exceptions By Priority] Filter Columns Reset'
);

export class  OverdueExceptionsByPriorityChartRequest implements Action {
    readonly type = HealthActionTypes. OverdueExceptionsByPriorityChartRequest;
}

export class OverdueExceptionsByPriorityChartSuccess implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsByPriorityChartSuccess;

    constructor(public payload: OverdueExceptionsByPriorityDTO[]) {}
}

export class OverdueExceptionsByPriorityChartError implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsByPriorityChartError;

    constructor(public error: string) {}
}

export const overdueExceptionsByPriorityTableRequest = createAction(
    '[Overdue Exceptions By Priority] Table Request',
);

export const overdueExceptionsByPriorityTableSuccess = createAction(
    '[Overdue Exceptions By Priority] Table Success',
    props<{ payload: BaseResultDTO<ExceptionStatusDTO> }>()
);

export const overdueExceptionsByPriorityTableError = createAction(
    '[Overdue Exceptions By Priority] Table Error',
    props<{error: string}>()
);

export const overdueExceptionsByPriorityColumnsVisibilityUpdate = createAction(
    '[Overdue Exceptions By Priority] Table Columns Visibility',
    props<{ payload: OverdueExceptionsShowHideColumns }>()
);

export const overdueExceptionsByPriorityTablesVisibilityUpdate = createAction(
    '[Overdue Exceptions By Priority] Tables Visibility',
    props<{ showTable: boolean, showSubsystemTable: boolean }>()
);

export class  OverdueExceptionsBySubsystemChartRequest implements Action {
    readonly type = HealthActionTypes. OverdueExceptionsBySubsystemChartRequest;
}

export class OverdueExceptionsBySubsystemChartSuccess implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsBySubsystemChartSuccess;

    constructor(public payload: OverdueExceptionsByPriorityDTO[]) {}
}

export class OverdueExceptionsBySubsystemChartError implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsBySubsystemChartError;

    constructor(public error: string) {}
}

export const overdueExceptionsBySubsystemTableRequest = createAction(
    '[Overdue Exceptions By Subsystem] Table Request'
);

export const overdueExceptionsBySubsystemTableSuccess = createAction(
    '[Overdue Exceptions By Subsystem] Table Success',
    props<{ payload: BaseResultDTO<SubsystemExceptionsDTO> }>()
);

export const overdueExceptionsBySubsystemTableError = createAction(
    '[Overdue Exceptions By Subsystem] Table Error',
    props<{ error: string }>()
);

export class OverdueExceptionsByPriorityExportToExcelRequest implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsByPriorityExportToExcelRequest;
}

export class OverdueExceptionsByPriorityExportToExcelRequestSuccess implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsByPriorityExportToExcelRequestSuccess;

    constructor(public payload: any) {
    }
}

export class OverdueExceptionsByPriorityExportToExcelRequestError implements Action {
    readonly type = HealthActionTypes.OverdueExceptionsByPriorityExportToExcelRequestError;

    constructor(public error: any) {
    }
}

export class ActivitiesConstraintsFilterReset implements Action {
    readonly type = HealthActionTypes.ActivitiesConstraintsFilterReset;

    constructor(public payload = false) {}
}
export class ActivitiesConstraintsChartRequest implements Action {
    readonly type = HealthActionTypes.ActivitiesConstraintsChartRequest;
}

export class ActivitiesConstraintsChartSuccess implements Action {
    readonly type = HealthActionTypes.ActivitiesConstraintsChartSuccess;

    constructor(public payload: ActivityConstrainsReportDTO[]) {}
}

export class ActivitiesConstraintsChartError implements Action {
    readonly type = HealthActionTypes.ActivitiesConstraintsChartError;

    constructor(public error: string) {}
}

export class  ActivitiesConstraintsFilterStateUpdate implements Action {
    readonly type = HealthActionTypes. ActivitiesConstraintsFilterStateUpdate;

    constructor(public payload:  ActivitiesConstraintsFilter) {}
}

export class ActivitiesConstraintsFilterSetWeekRange implements Action {
    readonly type = HealthActionTypes.ActivitiesConstraintsFilterSetWeekRange;

    constructor(public payload: moment.Moment) {}
}


export class ITRConstraintsChartRequest implements Action {
    readonly type = HealthActionTypes.ITRConstraintsChartRequest;
}

export class ITRConstraintsChartSuccess implements Action {
    readonly type = HealthActionTypes.ITRConstraintsChartSuccess;

    constructor(public payload: ActivityConstrainsReportDTO[]) {}
}

export class ITRConstraintsChartError implements Action {
    readonly type = HealthActionTypes.ITRConstraintsChartError;

    constructor(public error: string) {}
}
export class ITRConstraintsFilterSetWeekRange implements Action {
    readonly type = HealthActionTypes.ITRConstraintsFilterSetWeekRange;

    constructor(public payload: moment.Moment) {}
}

export type HealthActions =
    | HealthCleanlinessFilterUpdate
    | HealthCleanlinessFilterPropertyUpdate
    | HealthCleanlinessFilterReset
    | HealthCleanlinessSetType
    | HealthCleanlinessRequest
    | HealthCleanlinessSuccess
    | HealthCleanlinessError
    | HealthCleanlinessSetWeekRange

    | HealthOverdueDPLIChartRequest
    | HealthOverdueDPLIChartSuccess
    | HealthOverdueDPLIChartError
    | HealthOverdueDPLIFilterReset
    | HealthOverdueDPLIFilterUpdate
    | HealthOverdueDPLIFilterPropertyUpdate
    | HealthOverdueDPLIExportToExcelRequest
    | HealthOverdueDPLIExportToExcelRequestSuccess
    | HealthOverdueDPLIExportToExcelRequestError
    | HealthOverdueDPLITableRequest
    | HealthOverdueDPLITableSuccess
    | HealthOverdueDPLITableError
    | HealthOverdueDPLITableFilterReset

    | HealthOverdueExceptionsFilterStateUpdate
    | HealthOverdueExceptionsFilterPropertyUpdate
    | HealthOverdueExceptionsFilterReset
    | HealthOverdueExceptionsTableFilterReset
    | HealthOverdueExceptionsColumnsVisibilityUpdate
    | HealthOverdueExceptionsTableRequest
    | HealthOverdueExceptionsTableSuccess
    | HealthOverdueExceptionsTableError
    | HealthOverdueExceptionsChartRequest
    | HealthOverdueExceptionsChartSuccess
    | HealthOverdueExceptionsChartError
    | HealthOverdueExceptionsExportToExcelRequest
    | HealthOverdueExceptionsExportToExcelRequestSuccess
    | HealthOverdueExceptionsExportToExcelRequestError

    | HealthOverdueExceptionsAddCommentRequest
    | HealthOverdueExceptionsAddCommentSuccess
    | HealthOverdueExceptionsAddCommentError

    |OverdueExceptionsByPriorityFilterPropertyUpdate
    |OverdueExceptionsByPriorityFilterStateUpdate
    |OverdueExceptionsByPriorityFilterReset
    |ReturnType<typeof overdueExceptionsByPriorityFilterColumnsReset>
    |OverdueExceptionsByPriorityChartRequest
    |OverdueExceptionsByPriorityChartSuccess
    |OverdueExceptionsByPriorityChartError
    |ReturnType<typeof overdueExceptionsByPriorityTableRequest>
    |ReturnType<typeof overdueExceptionsByPriorityTableSuccess>
    |ReturnType<typeof overdueExceptionsByPriorityTableError>
    |ReturnType<typeof overdueExceptionsByPriorityColumnsVisibilityUpdate>
    |ReturnType<typeof overdueExceptionsByPriorityTablesVisibilityUpdate>
    |OverdueExceptionsBySubsystemChartRequest
    |OverdueExceptionsBySubsystemChartSuccess
    |OverdueExceptionsBySubsystemChartError
    |ReturnType<typeof overdueExceptionsBySubsystemTableRequest>
    |ReturnType<typeof overdueExceptionsBySubsystemTableSuccess>
    |ReturnType<typeof overdueExceptionsBySubsystemTableError>
    |OverdueExceptionsByPriorityExportToExcelRequest
    |OverdueExceptionsByPriorityExportToExcelRequestSuccess
    |OverdueExceptionsByPriorityExportToExcelRequestError

    |ActivitiesConstraintsFilterReset
    |ActivitiesConstraintsChartRequest
    |ActivitiesConstraintsChartSuccess
    |ActivitiesConstraintsChartError
    |ActivitiesConstraintsFilterStateUpdate
    |ActivitiesConstraintsFilterSetWeekRange
    |ITRConstraintsChartRequest
    |ITRConstraintsChartSuccess
    |ITRConstraintsChartError
    |ITRConstraintsFilterSetWeekRange;
