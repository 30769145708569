import { Zone } from 'src/app/enums';
import {
    OrderDirection, CheckListColumn,
    CalendarColumn, RFO,
    TCOUser,
    StagedStartUpPriority,
    NumericColumn
} from '../common.model';
import * as moment from 'moment';

export class TurnoverGroupDTO {
    id: string;
    name: string;
}

export class CriticalRfiFilter {
    page = 0;
    take = 25;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;
    changeTypes: string[] = ['RFI (iPIMS)'];
    subsystems: { id: string }[] = [];
    rfos: RFO[] = [];
    disciplines: string[] = [];
    designStatuses: string[] = [];
    priorities: string[] = [];
    timezoneOffset = 0;
    columnId: CheckListColumn = null;
    columnChangeType: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnTitle: CheckListColumn = null;
    columnSubsystem: CheckListColumn = null;
    columnWalkdownForecast: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;
    columnRfo: CheckListColumn = null;
    columnDesignStatus: CheckListColumn = null;
    columnStepStatus: CheckListColumn = null;
    columnTiComments: CheckListColumn = null;
    columnKpjvComments: CheckListColumn = null;
    columnPriority: CheckListColumn = null;
    columnPriorityManualOverride: CheckListColumn = null;
    columnPriorityFinal: CheckListColumn = null;
    columnSubPriority: CheckListColumn = null;
    columnRevision: CheckListColumn = null;
    columnSystemOwner: CheckListColumn = null;
    columnAreaBreakDown: CheckListColumn = null;
    columnRFSUForecast: CalendarColumn = null;
    columnRFOActual: CalendarColumn = null;
    columnRFSUActual: CalendarColumn = null;
    columnRFOForecast: CalendarColumn = null;
    columnRlmuToGo: NumericColumn = null;
    columnisVendorClarification: CheckListColumn = null;
    columnVendorName: CheckListColumn = null;
    showHideColumns = new ShowHideColumns();
    remaining = false;
    zone = Zone.CriticalRfi;
    columnResponseDuration :CheckListColumn = null; 
    columnCriticalPath :CheckListColumn = null; 
    columnNextToSign: CheckListColumn = null;
    columnExpectedTimeOfCompletion: CalendarColumn = null;
    columnRFIOriginator: CheckListColumn = null;
}
export class ShowHideColumns {
    type = false;
    discp = true;
    title = true;
    subsystem = true;
    walkdownForecast = true;
    rfsuPlan = true;
    rfo = true;
    designStatus = false;
    stepStatus = true;
    tiComment = true;
    kpjvComment = true;
    priority = true;
    priorityManualOverride = true;
    priorityFinal = true;
    subPriority = true;
    responseDuration = true;
    isVendorClarification = true;
    vendorName = false;
    criticalPath = true;
    revision = true;
    systemOwner = true;
    areaBreakDown = true;
    rfsuForecast = true;
    rfoForecast = true;
    rlmuToGo = false;
    nextToSign = false;
    rfsuActual = false;
    rfoActual = false;
    expectedTimeOfCompletion = true;
    rfiOriginator = false;
}

export class CriticalRfiDTO {
    id: number;
    number: string;
    type: string;
    discipline: string;
    title: string;
    subsystem: string;
    walkdownForecast: moment.Moment = null;
    rfsuPlan: moment.Moment = null;
    rfo: string;
    designStatus: string;
    currentStep: number;
    tiComment: string;
    kpjvComment: string;
    priority : string;
    priorityManualOverride : string;
    priorityFinal : string;
    subPriority : string;
    responseDuration : number;
    isVendorClarification : string;
    vendorName : string;
    criticalPath : string;
    rlmuToGo:number;
    nextToSign:string;
    expectedTimeOfCompletion: moment.Moment = null;
    originatingPersonName:string;
}

export interface CriticalRfiState {
    dataPagination: CriticalRfiDataPagination;
    filter: CriticalRfiFilter;
    isLoading: boolean;
    makeRequest: boolean;
}

export class CriticalRfiDataPagination {
    items: CriticalRfiDTO[] = [];
    totalCount = 0;
}
