import { ExportPageState, ExportPageDataPagination, ExportPageFilter } from './model';
import { ExportPageActions, ExportPageActionTypes } from './actions';

const initialState: ExportPageState = {
    dataPagination: new ExportPageDataPagination(),
    filter: new ExportPageFilter(),
    isLoading: false,
};

export function reducer(state = initialState, action: ExportPageActions) {
    switch (action.type) {
        case ExportPageActionTypes.ExportPageFileRequest:
        case ExportPageActionTypes.ExportPageFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ExportPageActionTypes.ExportPageFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: action.payload,
            };
        }
        case ExportPageActionTypes.ExportPageFileSuccess:
        case ExportPageActionTypes.ExportPageFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }

        default:
            return state;
    }
}
