import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { Config, DurationConfig, signOffFieldImplementationModeDisplay } from 'src/app/models/config';
import { ToastService } from 'src/app/services/shared/toast.service';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { OrderDirection } from 'src/app/store/common.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from 'src/app/constants';
import { DcnRfiSignOffFieldImplementationMode } from 'src/app/enums';

@Component({
    selector: 'general-app-configuration',
    templateUrl: './general-app-configuration.component.html',
    styleUrls: ['./general-app-configuration.component.scss'],
})
export class GeneralAppConfigurationComponent extends BaseComponent implements OnInit {
    commonConfigData: Config[];
    rundownConfigData: Config[];
    dcnrfiConfigData: Config[];
    ftpConfigData: Config[];
    durationConfig: DurationConfig[];
    isLoading = false;
    sortBy: string = 'key';
    direction: OrderDirection.Desc;
    displayedColumns = ['key', 'value', 'edit'];
    durationDisplayedColumns = ['rfoNumber', 'rfoName', 'bitrDuration', 'citrDuration', 'edit'];
    hvacSelectedSubsystems: any[] = [];
    SignOffFieldImplementationMode = DcnRfiSignOffFieldImplementationMode;
    signOffFieldImplementationModeDisplay = signOffFieldImplementationModeDisplay;

    constructor(
        private configurationService: ConfigurationService,
        private toastService: ToastService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
    }

    ngOnInit() {
        this.isLoading = true;

        this.configurationService
            .getConfigData()
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((commonConfigData) => {
                this.dcnrfiConfigData = commonConfigData
                    .filter(x => x.key === Constants.Config.signOffFiledImplementationModeKey);
                this.commonConfigData = commonConfigData.filter((c) => (c.id < 11 || c.id > 23)
                    && c.key !== Constants.Config.signOffFiledImplementationModeKey);
                this.rundownConfigData = commonConfigData.filter((c) => c.id === 11 || c.id === 13 || c.id === 15);
            });

        this.configurationService
            .getDurationConfigData()
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((durationConfigData) => {
                this.durationConfig = durationConfigData;
            });
        
        this.configurationService
            .getHVACSubsystemsConfigData()
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((subsystems) => {
                this.hvacSelectedSubsystems = subsystems;
            });
    }

    updateConfigData() {
        this.isLoading = true;

        this.configurationService
            .updateConfigData(this.commonConfigData.concat(this.dcnrfiConfigData))
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.toastService.Success('Configuration has been updated.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while updating configuration. Configuration has not been updated.'
                    );
                }
            );
    }

    updateDurationConfigData(config: DurationConfig) {
        this.isLoading = true;

        this.configurationService
            .updateDurationConfigData(config)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.toastService.Success('Duration configuration has been updated.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while updating duration configuration. Configuration has not been updated.'
                    );
                }
            );
    }

    save(config: Config) {
        if (config.key === 'Filenames for Tag Import') {
            if (!config.value.includes('Tag')) {
                this.toastService.Error('All expected filenames have to contain "Tag" in the filename.');
                return;
            }

            let fileNames = config.value.split(',');
            if (fileNames.length > 10) {
                this.toastService.Error('Maximum 10 files permitted. Re-enter key.');
                return;
            }

            if (this.findDuplicates(fileNames.map((s) => s.trim())).length > 0) {
                this.toastService.Error('Duplicate filenames in entry. Re-enter key.');
                return;
            }
        }

        config.isInEditMode = !config.isInEditMode;

        if (config.value !== config.prevValue) {
            this.updateConfigData();
        }
    }

    saveDuration(config: DurationConfig) {
        config.isInEditMode = !config.isInEditMode;
        this.updateDurationConfigData(config);
    }

    findDuplicates = (arr: any[]) => arr.filter((item, index) => arr.indexOf(item) != index);

    enterEditMode(config: Config) {
        config.isInEditMode = !config.isInEditMode;
        config.prevValue = config.value;
    }

    enterDurationEditMode(config: DurationConfig) {
        config.isInEditMode = !config.isInEditMode;
        config.prevBitrDuration = config.bitrDuration;
        config.prevCitrDuration = config.citrDuration;
    }

    exitEditMode(config: Config) {
        config.isInEditMode = !config.isInEditMode;
    }

    cancelEdit(config: Config) {
        config.isInEditMode = !config.isInEditMode;
        config.value = config.prevValue;
    }

    cancelDurationEdit(config: DurationConfig) {
        config.isInEditMode = !config.isInEditMode;
        config.bitrDuration = config.prevBitrDuration;
        config.citrDuration = config.prevCitrDuration;
    }

    setHVACSubsystems(subsystems: any[]) {
        this.hvacSelectedSubsystems = subsystems;
        const subsystemsToUpdate = this.hvacSelectedSubsystems.map(s => s.id);

        this.isLoading = true;

        this.configurationService
            .updateHVACSubsystemsConfigData(subsystemsToUpdate)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.toastService.Success('HVAC Subsystems configuration has been updated.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while updating HVAC Subsystems configuration. Configuration has not been updated.'
                    );
                }
            );
    }
}
