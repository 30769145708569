import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { SetInputEventArgs } from 'src/app/models/set-input';
import {
    Contractor,
    Gooc,
    ITRExcludeFilterRequestDTO,
    Milestone,
    RFO,
    StagedStartUpPriority,
    TCOACManager,
    TCOUser,
    Waypoint,
    WorkAreaDTO,
} from 'src/app/store/common.model';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { SkylineFilter, SkylineLevelOfDetails } from 'src/app/store/reports/skyline/model';
import { catchError, filter, map, take, takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/components/base.component';
import {
    SkylineFilterReset,
    SkylineFilterUpdate,
    SkylineFlagTogglesUpdate,
    SkylineGetExcelRequest,
    SkylinePrintReset,
    SkylineRequest,
    SkylineSetLevelOfDetails,
    SkylineSetWeekRange,
} from 'src/app/store/reports/skyline/actions';
import { MatDatepicker } from '@angular/material/datepicker';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { combineLatest, forkJoin } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import { RundownType } from 'src/app/store/reports/rundown/model';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-reports-skyline',
    templateUrl: './reports-skyline.component.html',
    styleUrls: ['./reports-skyline.component.scss'],
})
export class ReportsSkylineComponent extends BaseComponent implements OnInit {
    navLinks: { label: string; link: string; index: number }[];

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    isUsersPerProjectLoading = false;
    isRFSUCommitmentSkylineViewer = false;
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setITRDescriptionInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setConstructionAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setITRNumberInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWorkAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    areaCompletionLeads: TCOUser[] = [];
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    autocompleteDisplayedColumns = ['name', 'description'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    stagedStartUpPrioritiesAutocompleteDisplayedColumns = ['priority', 'priorityName'];
    projectTeamNamesCurrent: string[];
    prevWaypoints: Waypoint[] = [];
    filterForm: UntypedFormGroup;
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    acms: TCOACManager[] = [];
    sysOwners: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    deliveryEngineers: TCOUser[] = [];
    disciplines: any[] = [];
    workAreas: WorkAreaDTO[] = [];
    waypointScopes:string[] = [];
    wayPointNames:Waypoint[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    skylineHeader: string = '';
    showPortal = false;
    skylinePhase = '';
    skylineType = '';
    startDate = getCurrentWeekStartDate().add(-3, 'weeks');
    itrFilter = new ITRExcludeFilterRequestDTO();

    skylinePhase$ = this.store.select((store) => store.skylineState.skylinePhase);
    skylineType$ = this.store.select((store) => store.skylineState.skylineType);
    skylineFilter$ = this.store.select((store) => store.skylineState.filter);
    milestones$ = this.store.select((state) => state.skylineState.filter.milestones);
    waypoints$ = this.store.select((state) => state.skylineState.filter.waypoints);
    levelOfDetails$ = this.store.select((state) => state.skylineState.levelOfDetails);
    startDate$ = this.store.select((store) => store.skylineState.filter.startDate);
    isExcelGenerating$ = this.store.select((store) => store.skylineState.isExcelGenerating);
    flagToggles$ = this.store.select((state) => state.skylineState.flagToggles);

    @ViewChild('dateRangePicker', { static: true }) dateRangePicker: MatDatepicker<moment.Moment>;

    constructor(
        private store: Store<ApplicationState>,
        private lookupService: LookupService,
        private formService: FormService,
        private projectTeamsService: ProjectTeamsService,
        private toastService: ToastService,
        private roleService: RoleService
    ) {
        super();
        this.navLinks = [
            // {
            //     label: 'mc skyline - plan',
            //     link: './mc/plan',
            //     index: 0,
            // },
            //{
            //    label: 'mc skyline - commitment',
            //    link: './mc/commitment',
            //    index: 1,
            //},
            // {
            //     label: 'mc skyline - forecast',
            //     link: './mc/forecast',
            //     index: 2,
            // },
            {
                label: 'rfsu skyline - plan',
                link: './rfsu/plan',
                index: 3,
            },
            {
                label: 'rfsu skyline - commitment',
                link: './rfsu/commitment',
                index: 4,
            },
            {
                label: 'rfsu skyline - forecast',
                link: './rfsu/forecast',
                index: 5,
            },
            // {
            //     label: 'mc walkdown - forecast',
            //     link: './mcwalkdown/forecast',
            //     index: 6,
            // },
            {
                label: 'rfsu walkdown - forecast',
                link: './rfsuwalkdown/forecast',
                index: 7,
            },
            {
                label: 'C-ITR skyline - forecast',
                link: './citr/forecast',
                index: 8,
            },
            {
                label: 'RFO skyline - plan',
                link: './rfo/plan',
                index: 9,
            },
            {
                label: 'RFO skyline - forecast',
                link: './rfo/forecast',
                index: 10,
            },
        ];
        this.filterForm = this.formService.createSimpleForm(new SkylineFilter());
        this.dateRangePickerControl = new UntypedFormControl(this.startDate.toDate());
        this.dateRangePickerControlFormatted = new UntypedFormControl({
            value: this.formatDateRange(this.startDate),
            disabled: true,
        });
    }

    ngOnInit() {
        this.isRFSUCommitmentSkylineViewer = this.roleService.isInRole(Constants.applicableGroups.Admin) ||this.roleService.isInRole(Constants.applicableGroups.RFSUCommitmentSkylineViewers);
        if(!this.isRFSUCommitmentSkylineViewer){
            this.navLinks = this.navLinks.filter(s=> s.label !== 'rfsu skyline - commitment');
        }
        combineLatest([this.skylinePhase$, this.skylineType$])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(([phase, type]) => {
                setTimeout(() => {
                    this.skylinePhase = phase;
                    this.skylineType = type;
                    this.skylineHeader = `${
                        phase === 'mcwalkdown'
                            ? 'MC Walkdown'
                            : phase === 'rfsuwalkdown'
                            ? 'RFSU Walkdown'
                            : phase.toUpperCase()
                    } Skyline - ${
                        type === 'commitment' ? phase === 'mc' ? 'Monthly Commitment' : 'Commitment' : type.charAt(0).toUpperCase() + type.slice(1)
                    }`;
                });
            });

        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.lookupService
            .getStagedStartUpPriorities()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((ssup) => (this.stagedStartUpPriorities = ssup));

        this.skylineFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam(filter.projectTeamNames || []);
        });

        this.filterForm.controls.waypoints.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((waypoints: Waypoint[]) => {
                this.prevWaypoints = waypoints;
                this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
            });

        this.filterForm.controls.milestones.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((milestones: Milestone[]) => {
                let waypoints = JSON.parse(JSON.stringify(milestones));
                waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
                waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name');
                this.setMilestoneInput.emit(new SetInputEventArgs(false, '', milestones));
                if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) {
                    this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                    this.filterForm.controls.waypoints.setValue(waypoints);
                }
            });

        this.startDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startDate) => !!startDate)
            )
            .subscribe((startDate) => {
                this.dateRangePickerControl.setValue(moment(startDate), { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(startDate)));
            });

        this.dateRangePickerControl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((date: moment.Moment) => {
                this.store.dispatch(new SkylineSetWeekRange(date));
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
            });
            this.lookupService
            .getACLeads([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads));
        
        this.filterExpansionPanel.expanded = true;
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames);
    };

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    displayMultipleFilterSelected(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMilestones(
            search,
            take,
            page,
            this.filterForm.value.waypoints,
            this.filterForm.value.projectTeamNames
        );
    };

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getitrDescriptions = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchITRDecriptions(search, take, page, RundownType.CITR);
    };

    getContractorsforItr = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchCITRContractors(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((priorities: StagedStartUpPriority[]) => priorities));
    };

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    displayMultipleSelected(values: (Milestone | Waypoint | Contractor)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    displayStagedStartUpPrioritiesSelected() {
        return this.filterForm.controls.stagedStartUpPriorities.value.map((ssup) => ssup.priority).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    onPrintWindowClose() {
        this.showPortal = false;
        this.store.dispatch(new SkylinePrintReset());
    }

    search() {
        this.updateFilterByFormProperties();
        this.store.dispatch(new SkylineRequest({ printMode: false }));
    }

    changeWeekRange(weeks: number) {
        var newDate = moment(moment(this.dateRangePickerControl.value, 'YYYY-MM-DD').add(weeks, 'weeks').format());
        this.store.dispatch(new SkylineSetWeekRange(newDate));
        this.dateRangePickerControlFormatted.setValue(this.formatDateRange(newDate));
        this.dateRangePickerControl.setValue(newDate.toDate());
    }

    resetFilters() {
        this.store.dispatch(new SkylineFilterReset());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setConstructionAreaInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.setITRNumberInput.emit(new SetInputEventArgs(false, ''));
        this.setSubsystemInput.emit(new SetInputEventArgs(false, ''));
        this.setWorkAreaInput.emit(new SetInputEventArgs(false, ''));
        this.setITRDescriptionInput.emit(new SetInputEventArgs(false, ''));
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
    }

    setLevelOfDetails(level: SkylineLevelOfDetails) {
        this.store.dispatch(new SkylineSetLevelOfDetails(level));
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onStagedStartUpPrioritiesClosed() {
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(true));
    }

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    dateRangePickerFilter = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    private formatDateRange(startDate: moment.Moment) {
        const endDate = moment(startDate).add(12, 'weeks').add(6, 'days');
        return `${moment(startDate).format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
    }

    private checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName].value.length &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm.controls[formControlName].setValue(valid);
        }
    }

    private updateFilterByFormProperties() {
        let newFilter = new SkylineFilter();
        for (const key of Object.keys(this.filterForm.controls)) {
            if (key !== 'startDate' && key !== 'endDate') {
                const value = this.filterForm.controls[key].value;
                newFilter[key] = this.filterForm.controls[key].value;
            }
        }

        this.store.dispatch(new SkylineFilterUpdate(newFilter));
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    displaySelectedACM(acmIds: number[]) {
        return this.acms
            .filter((acm) => acmIds.includes(acm.id))
            .map((acm) => acm.name)
            .join(', ');
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedBoolean(boolValue: boolean) {
        if (boolValue === null) {
            return 'All';
        }
        return boolValue ? 'Yes' : 'No';
    }
    displaySelectedDeliveryEngineer(deliveryEngineerIds: number[]) {
        return this.deliveryEngineers
            .filter((deliveryEngineer) => deliveryEngineerIds.includes(deliveryEngineer.id))
            .map((deliveryEngineer) => deliveryEngineer.name)
            .join(', ');
    }
    // displaySelectedDiscipline(disciplineId: number[]) {
    //     return this.disciplines
    //         .filter((discipline) => disciplineId.includes(discipline.id))
    //         .map((discipline) => discipline.name)
    //         .join(', ');
    // }
    // displaySelectedWorkArea(workAreaId: number[]) {
    //     return this.workArea
    //         .filter((workArea) => workAreaId.includes(workArea.id))
    //         .map((workArea) => workArea.name)
    //         .join(', ');
    // }

    getConstructionArea = (search = '', take = 30, page = 0) =>
        this.lookupService.searchConstructionArea(search, take, page, this.filterForm.value.projectTeamNames);

    getSubSystems = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystems(search, take, page, this.filterForm.value.projectTeamNames);
    
    getITRNumber = (search = '', take = 30, page = 0) =>
        this.lookupService.searchITRNumberWithExcludeFilterForcitrSkyine(search, take, page,this.itrFilter);

    getWorkArea = (search = '', take = 30, page = 0) =>
        this.lookupService.searchWorkAreasforCITRSkyline(search, take, page);

    private getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'milestones');
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'waypoints');
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'contractors');
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'rfos');
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'goocs');
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'stagedStartUpPriorities');

        forkJoin([
            this.lookupService.getACMs(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getMCEngineers(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getSCManagers(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getSysOwners(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getStagedStartUpPriorities(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getDeliveryEngineers(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getDisciplines(),
            this.lookupService.searchWaypointScopesForRfo(),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([acms, mcEngineers, scManagers, sysOwners, prios, deliveryEngineers, disciplines, waypointScope]) => {
                    this.acms = acms;
                    this.checkIfSelectedUsersValid(this.acms, 'acm', 'id');
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');                    
                    this.stagedStartUpPriorities = prios;        
                    this.deliveryEngineers = deliveryEngineers;
                    this.checkIfSelectedUsersValid(this.deliveryEngineers, 'deliveryEngineer', 'id');
                    this.disciplines = disciplines;
                    this.waypointScopes = waypointScope;
                    
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    exportToExcel() {
        this.store.dispatch(new SkylineGetExcelRequest());
    }

    flagToggleUpdate(event: MatSlideToggleChange, propertyName: string) {
        this.store.dispatch(new SkylineFlagTogglesUpdate({ propertyName, value: event.checked }));
    }
    
    getwayPointsByScope (changeValue:string[]){
        this.wayPointNames = [];
            this.lookupService.searchWaypointsForRfo(changeValue).subscribe((result:Waypoint[])=>{
                this.wayPointNames = result.filter((s:Waypoint) => changeValue.map((w) => w).indexOf(s.waypointScope) > -1);
                this.wayPointNames = this.removeDuplicates(this.wayPointNames,"name");
            });
    }
}
