import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { DcnRfiRedlineRegisterService } from 'src/app/services/api/webapi-services/dcn-rfi-redline-register.service';

@Component({
  selector: 'app-dcn-rfi-filter',
  templateUrl: './dcn-rfi-filter.component.html',
  styleUrls: ['./dcn-rfi-filter.component.scss']
})
export class DcnRfiFilterComponent extends BaseComponent implements OnInit {
  @Input() filterForm: FormGroup;

  setDcnRfiNumbersSelectorValue = new EventEmitter<SetInputEventArgs>();
  setRedlineNumbersSelectorValue = new EventEmitter<SetInputEventArgs>();
  setDcnRfiImpactedSubsystemsSelectorValue = new EventEmitter<SetInputEventArgs>();
  setDcnRfiTitlesSelectorValue = new EventEmitter<SetInputEventArgs>();
  setDcnRfiContentSelectorValue = new EventEmitter<SetInputEventArgs>();
  setDcnRfiWorkpackNumberSelectorValue = new EventEmitter<SetInputEventArgs>();
  setDcnRfiWorkAreaSelectorValue = new EventEmitter<SetInputEventArgs>();

  dcnRfiDisciplines: string[] = [];
  dcnRfiEngineeringStatuses: string[] = [];
  dcnRfiImplementationStatuses: string[] = [];
  dcnRfiWorkpackStatuses: string[] = [];
  
  constructor(
    private dcnRfiRedlineRegisterService: DcnRfiRedlineRegisterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.dcnRfiRedlineRegisterService
      .getDisciplines()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((dcnRfiDisciplines: string[]) => (this.dcnRfiDisciplines = dcnRfiDisciplines));
    this.dcnRfiRedlineRegisterService
      .getDcnRfiEngineeringStatus()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((dcnRfiEngineeringStatuses: string[]) => (this.dcnRfiEngineeringStatuses = dcnRfiEngineeringStatuses));   
    this.dcnRfiRedlineRegisterService
      .getImplementationStatuses()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((dcnRfiImplementationStatuses: string[]) => (this.dcnRfiImplementationStatuses = dcnRfiImplementationStatuses));        
    this.dcnRfiRedlineRegisterService
      .getWorkpackStatuses()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((dcnRfiWorkpackStatuses: string[]) => (this.dcnRfiWorkpackStatuses = dcnRfiWorkpackStatuses));          
  }

  searchDcnRfiNumbers = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchDcnRfiNumbers(search, takeCount, page);
  };

  searchDcnRfiImpactedSubsystems = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchDcnRfiImpactedSubsystems(search, takeCount, page);
  };

  searchDcnRfiTitles = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchDcnRfiTitles(search, takeCount, page);
  };

  searchDcnRfiContent = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchDcnRfiContent(search, takeCount, page);
  };

  searchRedlineNumbers = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchRedlineNumbers(search, takeCount, page);
  };

  searchDcnRfiWorkpackNumbers = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchDcnRfiWorkpackNumbers(search, takeCount, page);
  };

  searchDcnRfiWorkArea = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchWorkArea(search, takeCount, page);
  };
}
