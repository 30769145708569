import { SubystemConflictsState, SubystemConflictsDataPagination, ChangeDocumentFilter } from './model';
import { SubystemConflictsActions, SubystemConflictsActionTypes } from './actions';

const initialState: SubystemConflictsState = {
    dataPagination: new SubystemConflictsDataPagination(),
    filter: new ChangeDocumentFilter(),
    isLoading: false,
};

export function reducer(state = initialState, action: SubystemConflictsActions) {
    switch (action.type) {
        case SubystemConflictsActionTypes.SubystemConflictsFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case SubystemConflictsActionTypes.SubystemConflictsFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: action.payload,
            };
        }
        case SubystemConflictsActionTypes.SubystemConflictsFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SubystemConflictsActionTypes.SubystemConflictsFilterPropertyUpdate: {
            let newFilter = {...state.filter};

            if (action.payload.key === 'sortBy') {
                newFilter.sortBy = action.payload.value.active;
                newFilter.direction = action.payload.value.direction;
            } else {
                if (Array.isArray(state.filter[action.payload.key])) {
                    newFilter[action.payload.key] = Object.assign([], action.payload.value);
                } else {
                    newFilter[action.payload.key] = action.payload.value;
                }
            }
            return {
                ...state,
                filter: {
                    ...newFilter
                }
            };
        }
        case SubystemConflictsActionTypes.SubystemConflictsFilterReset: {
            const newFilter = new ChangeDocumentFilter();
            newFilter.showHideColumns = state.filter.showHideColumns;
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        default:
            return state;
    }
}
