<main class="main">
    <app-loading-indicator *ngIf="subsystemWithScheduleActivitiesDataLoading"></app-loading-indicator>
    <a class="navigator" [routerLink]="" (click)="goBack()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">{{getTitle()}}</div>
        <div class="button-container">
            <button *ngIf="!lockFilters" class="sct-button sct-button-light margin-right20" (click)="exportToExcel()">export</button>
            <button *ngIf="weeklyPlanType === weeklyPlanTypes.weekly && isNextWorkingWeek && !lockFilters" type="button" class="sct-button sct-button-light margin-right20" (click)="showPortal = true">
                print
            </button>
            <button *ngIf="weeklyPlanType === weeklyPlanTypes.weekly && !lockFilters" class="sct-button sct-button-light margin-right20" (click)="goToITRReport()">performance report</button>
            <button *ngIf="weeklyPlanType === weeklyPlanTypes.weekly && !lockFilters" [disabled]="submitButtonDisabled || !isNextWorkingWeek" class="sct-button sct-button-light" (click)="submit()">
                submit
            </button>
        </div>
    </div>
    <div>
        <app-lookahead-filter *ngIf="!lockFilters" [planType]="weeklyPlanType"></app-lookahead-filter>
        <mat-accordion *ngIf="isNextWorkingWeek || !isWeekly || lockFilters" [multi]="multiPanels">
            <ng-container *ngIf="isWeekly && !lockFilters">
                <mat-expansion-panel [expanded]="multiPanels" [disabled]="multiPanels">
                    <mat-expansion-panel-header collapsedHeight="auto">
                        <h3 class="panel-title">Week Summary</h3>
                        <mat-icon [class]="multiPanels ? 'pin-color-enabled' : 'pin-color-disabled'" color="primary" (click)="setMultiPanels()">push_pin</mat-icon>
                    </mat-expansion-panel-header>
                    <app-week-summary (openPanelEvent)="openExpansionPanel($event)"></app-week-summary>
                </mat-expansion-panel>
            </ng-container>
            <mat-expansion-panel #inWeekExpansionPanel [expanded]="inWeekExpanded" (opened)="trackExpansionPanel('openedInWeek')"
            (closed)="trackExpansionPanel('closedInWeek')" [hideToggle]="!isWeekly" [disabled]="!isWeekly">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h3 *ngIf="isWeekly" class="panel-title">Activities in Week</h3>
                </mat-expansion-panel-header>
                <app-schedule-activity-planning-table
                *ngIf="!subsystemWithScheduleActivitiesDataLoading"
                [activityType]="activityTypes.inWeek"
                [planningItemsTableDataSource]="subsystemsWithScheduleActivities"
                [showHideColumns]="inWeekShowHideColumns"
                (showHideColumnsChanged)="onInWeekShowHideColumnsChange($event)"
                (goToAddSubsystemClicked)="goToNotInWeek()"
                >
                </app-schedule-activity-planning-table>
    
                <div
                    [ngClass]="{ hidden: subsystemsWithScheduleActivitiesTotalLength === 0 }"
                    class="paginator page-counter"
                >
                    <mat-paginator #inWeekPaginator
                        [length]="subsystemsWithScheduleActivitiesTotalLength"
                        [pageSizeOptions]="[10, 15, 20]"
                        [pageSize]="inWeekPageSize"
                        (page)="onInWeekPaginatorChange($event)"
                        showFirstLastButtons
                    >
                    </mat-paginator>
                    <input
                        type="number"
                        (change)="onInWeekPageChange($event.target.value)"
                        [value]="this.inWeekPaginator.pageIndex + 1"
                    />
                </div>
            </mat-expansion-panel>
            <ng-container *ngIf="isWeekly && !lockFilters">
                <mat-expansion-panel #notInWeekExpansionPanel (opened)="trackExpansionPanel('openedNotInWeek')" (closed)="trackExpansionPanel('closedNotInWeek')">
                    <mat-expansion-panel-header collapsedHeight="auto">
                        <h3 class="panel-title">Activities not in Week</h3>
                    </mat-expansion-panel-header>
                    <app-schedule-activity-planning-table
                    *ngIf="!subsystemsNotInWeekWithScheduleActivitiesDataLoading"
                    [activityType]="activityTypes.notInWeek"
                    [planningItemsTableDataSource]="subsystemsNotInWeekWithScheduleActivities"
                    [additionToPlan]="true"
                    [showHideColumns]="notInWeekShowHideColumns"
                    (showHideColumnsChanged)="onNotInWeekShowHideColumnsChange($event)"
                    >
                    </app-schedule-activity-planning-table>
                    <div
                        [ngClass]="{ hidden: subsystemsNotInWeekWithScheduleActivitiesTotalLength === 0 }"
                        class="paginator page-counter"
                    >
                        <mat-paginator #notInWeekPaginator
                            [length]="subsystemsNotInWeekWithScheduleActivitiesTotalLength"
                            [pageSizeOptions]="[10, 15, 20]"
                            [pageSize]="notInWeekPageSize"
                            (page)="onNotInWeekPaginatorChange($event)"
                            showFirstLastButtons
                        >
                        </mat-paginator>
                        <input
                            type="number"
                            (change)="onNotInWeekPageChange($event.target.value)"
                            [value]="this.notInWeekPaginator.pageIndex + 1"
                        />
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
        <app-schedule-activity-itr *ngIf="!isNextWorkingWeek && isWeekly && !lockFilters" [showExportButton]="false"></app-schedule-activity-itr>
    </div>
</main>
<app-schedule-activity-print (close)="onPrintWindowClose()" *ngIf="showPortal"></app-schedule-activity-print>
