<mat-form-field [ngClass]="{ 'multiple-items-error': invalid }" [appearance]="appearance">
    <mat-chip-list #chipList [disabled]="isDisabled">
        <div *ngIf="itemsSelected.length < 100">
            <mat-chip
                *ngFor="let item of itemsSelected"
                [selectable]="!isDisabled"
                [removable]="!isDisabled"
                (removed)="remove(item)"
            >
                {{ propertyToShow ? item[propertyToShow] : item }}
                {{ secondPropertyToShow && item[secondPropertyToShow] ? '(' + item[secondPropertyToShow] + ')' : '' }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </div>
        <div *ngIf="itemsSelected.length >= 100">
            <mat-chip>
                Many selected
            </mat-chip>
        </div>
        <input
            matInput
            [placeholder]="placeholderText"
            #chipInput
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            matChipInputAddOnBlur="true"
            [formControl]="itemCtrl"
        />
        <mat-error *ngIf="invalid">
            {{ errorMessage }}
        </mat-error>
    </mat-chip-list>
    <button
        class="search-btn"
        [disabled]="isDisabled"
        type="button"
        *ngIf="openSearchPopupCallback"
        (click)="openSearchPopupCallback(); $event.stopPropagation()"
        mat-icon-button
        title="Search"
    >
        <mat-icon>search</mat-icon>
    </button>
    <mat-autocomplete
        (infiniteScroll)="onScroll()"
        #auto="matAutocomplete"
        (closed)="closed()"
        [panelWidth]="panelWidth"
        (opened)="opened()"
    >
        <mat-checkbox class="select-all" *ngIf="isSelectAll" [checked]="selectAll" (change)="selectAllChange()"
            >Select all</mat-checkbox
        >
        <div *ngIf="canAddManually" class="add-manual-container">
            <button type="button" (click)="onCreateNewElement(); $event.stopPropagation()" mat-icon-button title="Add">
                <mat-icon svgIcon="add"></mat-icon>
            </button>
            <input
                (keydown)="addManual($event, createNewInput)"
                matInput
                [disabled]="!inCreateNewMode"
                [placeholder]="getCreateNewPlaceholderText"
                #createNewInput
            />
        </div>
        <ng-container *ngIf="!isLoading">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let element">
                        {{ propertyToShow ? element[propertyToShow] : element.id || element }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="no">
                    <th mat-header-cell *matHeaderCellDef>No</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.no }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.description }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="contract">
                    <th mat-header-cell *matHeaderCellDef>Contractor</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.contract }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="contractNo">
                    <th mat-header-cell *matHeaderCellDef>Contract No</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.contractNo }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.email }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="tagNo">
                    <th mat-header-cell *matHeaderCellDef>Tag No</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.tagNo }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="tagType">
                    <th mat-header-cell *matHeaderCellDef>Tag Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.tagType }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="rfoName">
                    <th mat-header-cell *matHeaderCellDef>RFO Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.rfoName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="numberedName">
                    <th mat-header-cell *matHeaderCellDef>Change Number</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.numberedName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="priority">
                    <th mat-header-cell *matHeaderCellDef>Priority</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.priority }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="priorityName">
                    <th mat-header-cell *matHeaderCellDef>Priority Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.priorityName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="workAreaName">
                    <th mat-header-cell *matHeaderCellDef>Work Area Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.workAreaName }}
                    </td>
                </ng-container>
                <ng-container
                    *ngFor="let customAction of customActions; let i = index"
                    [matColumnDef]="customAction.name"
                >
                    <th mat-header-cell disableClear *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            mat-icon-button
                            [title]="customAction.name"
                            (click)="customAction.action(element); $event.stopPropagation(); autocomplete.closePanel()"
                        >
                            <mat-icon [svgIcon]="customAction.icon" [matTooltip]="customAction.name"></mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr
                    [hidden]="displayedColumns.length === 1 || noResultFound"
                    mat-header-row
                    *matHeaderRowDef="displayedColumns"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    (click)="onSelect(row); $event.stopPropagation()"
                    [ngClass]="{ 'tr-selected': row.Selected }"
                ></tr>
            </table>
        </ng-container>
        <mat-option *ngIf="dataSource.data.length" hidden></mat-option>
        <mat-option *ngIf="isLoading || noResultFound" disabled>
            <div *ngIf="isLoading" class="lds-hourglass"></div>
            <h4 *ngIf="noResultFound && !isLoading">No results found</h4>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
