import { Action } from '@ngrx/store';
import { ScrollPosition } from './model';

export enum UXActionTypes {
    SaveScrollPosition = '[UX] Save'
}

export class SaveScrollPosition implements Action {
    readonly type = UXActionTypes.SaveScrollPosition;
    constructor(public payload: ScrollPosition) {}
}

export type UXActions = SaveScrollPosition;
