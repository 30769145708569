import { Injectable } from '@angular/core';
import { SkylineFilter, SkylineResponseDTO } from 'src/app/store/reports/skyline/model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class SkylineService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly skylineEndpoint: string = '/skyline';

    constructor(private http: HttpClient) {}

    filterSkylinePlanData(
        filter: SkylineFilter,
        phaseType: string,
        skylineType: string,
        printMode: boolean
    ): Observable<SkylineResponseDTO[]> {
        return this.http.post<SkylineResponseDTO[]>(
            `${this.rootUrl}${this.skylineEndpoint}/${phaseType}/${skylineType}/${printMode}`,
            filter
        );
    }

    getSkylineExcel(
        skylinePhase: string,
        skylineType: string,
        isDetailedView: boolean,
        filter: SkylineFilter
    ): Observable<BlobPart> {
        return this.http.post<BlobPart>(
            `${this.rootUrl}${this.skylineEndpoint}/excel/${skylinePhase}/${skylineType}/${isDetailedView}`,
            filter,
            { responseType: 'arraybuffer' as 'json' }
        );
    }

    downloadFishbone(phase: string) {
        return this.http.get<BlobPart>(`${this.rootUrl}${this.skylineEndpoint}/fishbone/${phase}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }
}
