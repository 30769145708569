export enum ScaleType {
  Time = 'time',
  Linear = 'linear',
  Ordinal = 'ordinal',
  Quantile = 'quantile'
}

export interface LegendOptions {
  colors: any;
  domain: any[];
  position: LegendPosition;
  title: string;
  scaleType: ScaleType;
}

export enum LegendPosition {
  Right = 'right',
  Below = 'below'
}

export enum LegendType {
  ScaleLegend = 'scaleLegend',
  Legend = 'legend'
}

export enum BarOrientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal'
}

export enum BarChartType {
  Standard = 'standard',
  Normalized = 'normalized',
  Stacked = 'stacked'
}

export interface Color {
  name: string;
  selectable: boolean;
  group: ScaleType;
  domain: string[];
}
  
  