import { MilestoneDashboardFilter } from '../reports/milestone-dashboard/model';
import { HomeActions, HomeDataActionTypes } from './actions';
import { HomeState } from './model';

const initialState: HomeState = {
    isLoadingSystemCompletionComplete: false,
    systemCompletionData: null,
    filterSystemCompletion: new MilestoneDashboardFilter(),
    isLoadingNPWComplete: false,
    npwData: null,
    filterNPW: new MilestoneDashboardFilter(),
};

export function reducer(state = initialState, action: HomeActions): HomeState {
    switch (action.type) {
        case HomeDataActionTypes.HomeSystemCompletionDataRequest: {
            return {
                ...state,
                isLoadingSystemCompletionComplete: true,
            };
        }
        case HomeDataActionTypes.HomeSystemCompletionDataRequestSuccess: {
            return {
                ...state,
                systemCompletionData: action.payload.filter((x) => {
                    return (
                        x.category != 'null'
                    );
                }),
                isLoadingSystemCompletionComplete: false,
            };
        }
        case HomeDataActionTypes.HomeSystemCompletionDataRequestError: {
            return {
                ...state,
                isLoadingSystemCompletionComplete: false,
            };
        }
        case HomeDataActionTypes.HomeSystemCompletionDataFilterPropertyUpdate: {
            return {
                ...state,
                filterSystemCompletion: {
                    ...state.filterSystemCompletion,
                    [action.payload.key]: Array.isArray(state.filterSystemCompletion[action.payload.key])
                        ? [...action.payload.value]
                        : action.payload.value,
                },
            };
        }
        case HomeDataActionTypes.HomeNPWDataRequest: {
            return {
                ...state,
                isLoadingNPWComplete: true,
            };
        }
        case HomeDataActionTypes.HomeNPWDataRequestSuccess: {
            return {
                ...state,
                npwData: action.payload.filter((x) => {
                    return (
                        x.category != 'null'
                    );
                }),
                isLoadingNPWComplete: false,
            };
        }
        case HomeDataActionTypes.HomeNPWDataRequestError: {
            return {
                ...state,
                isLoadingNPWComplete: false,
            };
        }
        case HomeDataActionTypes.HomeNPWDataFilterPropertyUpdate: {
            return {
                ...state,
                filterSystemCompletion: {
                    ...state.filterSystemCompletion,
                    [action.payload.key]: Array.isArray(state.filterNPW[action.payload.key])
                        ? [...action.payload.value]
                        : action.payload.value,
                },
            };
        }
        default:
            return state;
    }
}
