import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { Observable } from 'rxjs';
import { PLIPlanningDTO, PLIPlanningFilter } from 'src/app/store/pli-planning/model';
import { BaseResultDTO } from 'src/app/store/common.model';
import { SubsystemScope } from 'src/app/enums';
import { DetailedStatusDTO } from 'src/app/store/detailed-status/model';

@Injectable({
    providedIn: 'root',
})
export class PLIPlanningService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint = '/pliplanning';
    private readonly lookupEndpoint = '/pliplanninglookup';
    private readonly detailedStatusEndpoint: string = '/detailedstatus';
    private readonly endpointpliForecastClosure: string = '/detailedstatus/addPLIForecastClosureDate';

    constructor(private http: HttpClient) {}

    getPLIPlanningData(filter: PLIPlanningFilter, printMode = false): Observable<BaseResultDTO<PLIPlanningDTO>> {
        const endpointUrl = `${this.rootUrl}${this.endpoint}?printMode=${printMode}`;
        return this.http.post<BaseResultDTO<PLIPlanningDTO>>(endpointUrl, {
            ...filter,
        });
    }

    getPLISubsystemsFiltered(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/subsystems`, filter, { params });
    }

    getPLINumbersFiltered(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/plinumbers`, filter, { params });
    }

    getDescriptionsFiltered(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/descriptions`, filter, { params });
    }

    getTagNumbersFiltered(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/tagnumbers`, filter, { params });
    }

    getActionBysFiltered(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/actionby`, filter, { params });
    }

    getCategoriesFiltered(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/categories`, filter, { params });
    }

    getPunchTypesFiltered(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/punchtype`, filter, { params });
    }

    getRaisedBys(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/raisedby`, filter, { params });
    }

    getSubsystemNames(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/subsystemname`, filter, { params });
    }

    getTagTypes(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/tagtypes`, filter, { params });
    }

    getTagNames(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/tagnames`, filter, { params });
    }

    getMaterialReferences(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/materialref`, filter, { params });
    }

    getDisciplines(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/disciplines`, filter, { params });
    }

    searchConstraintFlagForPLIFilter(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/constraints`, filter, { params });
    }

    searchStatusForPLIFilter(search: string, take: number, page: number, filter: PLIPlanningFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.lookupEndpoint}/statuses`, filter, { params });
    }

    uncommitPLIs(weekStart: Date, plis: PLIPlanningDTO[]): Observable<PLIPlanningDTO[]> {
        const endpointUrl = `${this.rootUrl}${this.endpoint}/uncommit`;
        return this.http.post<PLIPlanningDTO[]>(endpointUrl, {
            weekStart: weekStart,
            PLIs: plis,
        });
    }

    generateExcel(filter: PLIPlanningFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generatepliexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    downloadAttachment(link: string) {
        return this.http.get<Blob>(
            `${this.rootUrl}${this.detailedStatusEndpoint}/attachments?link=${link}&scope=${SubsystemScope.PLI}`,
            {
                responseType: 'arraybuffer' as 'json',
            }
        );
    }

    addPLIForeCastClosureDate(pli: DetailedStatusDTO) {
        return this.http.post(`${this.rootUrl}${this.endpointpliForecastClosure}`, pli);
    }
}
