import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import * as moment from "moment";
import { of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { MilestoneDashboardService } from "src/app/services/api/webapi-services/reports/milestone-dashboard.service";
import { ToastService } from "src/app/services/shared/toast.service";
import { ApplicationState } from "../../model";
import { SubsystemRFSUDasbboardRedirectToDetailedStatus, SubsystemRFSUDashboardActionTypes, SubsystemRFSUDashboardError, SubsystemRFSUDashboardExportToExcelError, SubsystemRFSUDashboardExportToExcelSuccess, SubsystemRFSUDashboardSuccess } from "./actions";

@Injectable()
export class SubsystemRFSUDashboardEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private milestoneDashboardService: MilestoneDashboardService,
        private toastService: ToastService,
        private router: Router
    ) {}

    @Effect()
    subsystemRFSUDashboardRequest$ = this.actions$.pipe(
        ofType(SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardRequest),
        withLatestFrom(this.store.select((state) => state.subsystemRFSUDashboardState.filter)),
        mergeMap(([action, filter]) =>
            this.milestoneDashboardService.filterSubsystemRFSUData(filter).pipe(
                map((data) => new SubsystemRFSUDashboardSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Subsystem RFSU Dashboard data. Please contact Program Administrator.`
                    );
                    return of(new SubsystemRFSUDashboardError(error));
                })
            )
        )
    );

    @Effect()
    subsystemRFSUDashboardExportToExcel$ = this.actions$.pipe(
        ofType(SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.subsystemRFSUDashboardState.filter)),
        mergeMap(([, subsystemRFSUDashboardFilter]) => {
            subsystemRFSUDashboardFilter.timezoneOffset = new Date().getTimezoneOffset();
            return this.milestoneDashboardService.generateSubsystemRFSUExcel(subsystemRFSUDashboardFilter).pipe(
                map((excelData) => new SubsystemRFSUDashboardExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new SubsystemRFSUDashboardExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveSubsystemRFSUExcelData = this.actions$.pipe(
        ofType(SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardExportToExcelSuccess),
        map((action: SubsystemRFSUDashboardExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `SubsystemRFSUDashboard_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect({ dispatch: false })
    redirectToDetailedStatus$ = this.actions$.pipe(
        ofType<SubsystemRFSUDasbboardRedirectToDetailedStatus>(
            SubsystemRFSUDashboardActionTypes.SubsystemRFSUDasbboardRedirectToDetailedStatus
        ),
        withLatestFrom(this.store.select((state) => state.subsystemRFSUDashboardState.filter)),
        map(([action, filter]) => {
            const goocs = filter.goocs.map((g) => g.no);
            const mcEngineers = filter.mcEngineer;
            const systemOwners = filter.systemOwner;
            const contractors = filter.contractors.map((m) => m.name);

            this.router.navigate(['detailedstatus'], {
                queryParams: {
                    lockFilters: true,
                    subsystem: action.payload.subsystem,
                    scope: action.payload.exceptions ? 'EX' : action.payload.scope,
                    goocs: goocs.length ? goocs : undefined,
                    exScope: action.payload.exceptions ? 'ITR' : undefined,
                    subsystemScope: action.payload.exceptions ? 'AQVD' : undefined,
                    category: action.payload.category,
                    excludeExceptions: action.payload.exceptions ? undefined : ['MC', 'RFSU', 'RFO'],
                    mcEngineers: mcEngineers.length ? mcEngineers : undefined,
                    systemOwners: systemOwners.length ? systemOwners : undefined,
                    contractors: contractors.length ? contractors : undefined,
                    zone: action.payload.zone,
                    status: action.payload.status
                },
            });
        })
    );
}
