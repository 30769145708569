import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleService } from 'src/app/services/shared/role.service';

@Injectable({
    providedIn: 'root',
})
export class IsNotGathering implements CanActivate {
    constructor(private groupSvc: RoleService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.groupSvc.isGatheringOnly()) {
            return true;
        } else if (state.url === '/redzone') {
            this.router.navigate(['/changemanagement']);
            return false;
        }

        this.router.navigate(['/notauthorized']);
        return false;
    }
}
