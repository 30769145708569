import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap, takeWhile, withLatestFrom } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from 'src/app/modules/shared/components/comment-popup/comment-popup.component';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { CalendarColumn, CheckListColumn, OrderDirection, UserDetail } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    HealthOverdueExceptionsAddCommentRequest,
    OverdueExceptionsByPriorityChartRequest,
    OverdueExceptionsBySubsystemChartRequest,
    overdueExceptionsByPriorityTableRequest,
    OverdueExceptionsByPriorityFilterPropertyUpdate,
    overdueExceptionsByPriorityColumnsVisibilityUpdate,
    overdueExceptionsByPriorityFilterColumnsReset
} from 'src/app/store/reports/health/actions';
import { Constants } from 'src/app/constants';
import { ExceptionStatusDTO, OverdueExceptionsShowHideColumns } from 'src/app/store/reports/health/model';
import { SubsystemScope } from 'src/app/enums';

@Component({
    selector: 'app-overdue-exceptions-by-priority-table',
    templateUrl: './overdue-exceptions-by-priority-table.component.html',
    styleUrls: ['./overdue-exceptions-by-priority-table.component.scss'],
})
export class OverdueExceptionsByPriorityTableComponent extends BaseComponent implements OnInit {
    showColumns = new OverdueExceptionsShowHideColumns();
    sortBy = '';
    sortDirection = OrderDirection.Desc;
    pageSize = 25;
    displayedColumns = [
        'subsystem',
        'number',
        'description',
        'tagNumber',
        'tagType',
        'discipline',
        'exceptionNumber',
        'exceptionPhase',
        'targetCompletionDate',
        'latestComment',
    ];
    isReadOnly = false;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    table$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.table).pipe(filter((table) => !!table));
    overdueExceptionFilter$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter);
    selectedPriority$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter.selectedBarSeries);
    selectedLabel$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter.selectedBarLabel);
    isTableLoading$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.isTableLoading);

    columns = {
        columnSubsystem: new CheckListColumn(),
        columnNumber: new CheckListColumn(),
        columnDescription: new CheckListColumn(),
        columnTagNumber: new CheckListColumn(),
        columnTagType: new CheckListColumn(),
        columnDiscipline: new CheckListColumn(),
        columnExceptionNumber: new CheckListColumn(),
        columnExceptionPhase: new CheckListColumn(),
        columnComments: new CheckListColumn(),
        columnTargetCompletionDate: new CalendarColumn(),
    };

    constructor(
        private store: Store<ApplicationState>,
        private popupService: PopupService,
        private lookupService: LookupService,
        private roleService: RoleService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeToFilterColumnUpdate();
        this.isReadOnly = this.roleService.isReadOnly();
    }

    subscribeToFilterColumnUpdate = () => {
        this.overdueExceptionFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            for (let property in filter) {
                if (this.columns.hasOwnProperty(property) && this.columns[property] != filter[property]) {
                    this.columns[property] = filter[property];
                }

                if(filter.page !== this.paginator.pageIndex) {
                    this.paginator.pageIndex = filter.page;
                }
            }
        });
    };

    resetColumns = () => {
        this.showColumns = new OverdueExceptionsShowHideColumns();
    };

    showAllExceptions = () => {
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'selectedBarLabel',
                value: null,
            })
        );
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'selectedBarSeries',
                value: null,
            })
        );
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );

        this.store.dispatch(overdueExceptionsByPriorityFilterColumnsReset());
        this.store.dispatch(overdueExceptionsByPriorityTableRequest());
        this.store.dispatch(new OverdueExceptionsBySubsystemChartRequest());
        this.store.dispatch(new OverdueExceptionsByPriorityChartRequest());
    };

    search() {
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new OverdueExceptionsByPriorityChartRequest());
        this.store.dispatch(new OverdueExceptionsBySubsystemChartRequest());
        this.store.dispatch(overdueExceptionsByPriorityTableRequest());
    }

    reverseValue(columnName: string) {
        this.showColumns = {
            ...this.showColumns,
            [columnName]: !this.showColumns[columnName]
        };
        this.store.dispatch(overdueExceptionsByPriorityColumnsVisibilityUpdate({ payload: this.showColumns }));
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;

        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.sortDirection == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.sortDirection == OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                excludeBlanks: excludeBlanks,
                onlyBlanks: onlyBlanks,
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                showBlankOptions: showBlankOptions,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.store.dispatch(
                        new OverdueExceptionsByPriorityFilterPropertyUpdate({
                            key: propertyName,
                            value: value,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new OverdueExceptionsByPriorityFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {},
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn,
        isSortingOff = false,
        fireSearch = true
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupService.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.sortDirection == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.sortDirection == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: isSortingOff,
                action: (data) => {
                    this.store.dispatch(
                        new OverdueExceptionsByPriorityFilterPropertyUpdate({
                            key: propertyName,
                            value: data.calendarColumn,
                        })
                    );
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    if (fireSearch) {
                        this.search();
                    }
                },
            },
            345,
            580)
        );
    }

    openCommentPopup(element: ExceptionStatusDTO, event: any) {
        const comment = element.latestComment || '';
        if (this.isReadOnly) return;

        this.popupService.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: [],
                url: `details?scope=${SubsystemScope.EX}&id=${element.id}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (!bulk) {
                        this.store.dispatch(
                            new HealthOverdueExceptionsAddCommentRequest({
                                entityId: element.id.toString(),
                                description: value,
                                mentions,
                            })
                        );
                    }
                },
            })
        );

        event.stopPropagation();
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.sortDirection = direction;
            this.store.dispatch(
                new OverdueExceptionsByPriorityFilterPropertyUpdate({
                    key: 'direction',
                    value: direction,
                })
            );
        }

        if (columnName) {
            this.sortBy = columnName;
            this.store.dispatch(
                new OverdueExceptionsByPriorityFilterPropertyUpdate({
                    key: 'sortBy',
                    value: columnName,
                })
            );
        }
    }

    private withLatestFilter(action: (...actionArgs: any) => Observable<any>, ...args: any) {
        return of(null).pipe(
            withLatestFrom(this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter)),
            switchMap(([, filter]) => action(...args, filter))
        );
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.pageSize = pageEvent.pageSize;
            this.store.dispatch(
                new OverdueExceptionsByPriorityFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
        } else {
            this.store.dispatch(
                new OverdueExceptionsByPriorityFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(overdueExceptionsByPriorityTableRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    getSubsystems = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchSubsystemsWithOverdueExceptionByPriorityFilter, search, take, page);

    getNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchNumbersWithOverdueExceptionByPriorityFilter, search, take, page);

    getDescriptions = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchDescriptionsWithOverdueExceptionByPriorityFilter, search, take, page);

    getTagNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchTagNumbersWithOverdueExceptionByPriorityFilter, search, take, page);

    getTagTypes = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchTagTypesWithOverdueExceptionByPriorityFilter, search, take, page);

    getDisciplines = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchDisciplinesWithOverdueExceptionByPriorityFilter, search, take, page);

    getExceptionNumbers = (search = '', take = 30, page = 0) => 
        this.withLatestFilter(this.lookupService.searchExceptionNumberWithOverdueExceptionByPriorityFilter, search, take, page);
    
    getExceptionPhases = (search = '', take = 30, page = 0) => 
        this.withLatestFilter(this.lookupService.searchExceptionPhaseWithOverdueExceptionByPriorityFilter, search, take, page);

    getComments = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchCommentsWithOverdueExceptionByPriorityFilter, search, take, page);
}
