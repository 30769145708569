import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';

@Component({
    selector: 'app-mc-skyline',
    templateUrl: './skyline.component.html',
    styleUrls: ['./skyline.component.scss'],
})
export class MCSkylineComponent {
    isLoading$ = this.store.select((state) => state.milestoneDashboardState.mcMilestoneSkyline.isLoading);
    items$ = this.store.select((state) => state.milestoneDashboardState.mcMilestoneSkyline.items);
    weeks$ = this.store.select((state) => state.milestoneDashboardState.mcMilestoneSkyline.weeks);
    openAqvdsPerWeek$ = this.store.select((state) => state.milestoneDashboardState.mcMilestoneSkyline.openAqvdsPerWeek);

    constructor(private store: Store<ApplicationState>) {}
}
