import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MyPageDataPagination, MyPageFilter } from 'src/app/store/my-page/model';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class MyPageService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/myPage';
    constructor(private http: HttpClient) {}

    getMyPageData(filter: MyPageFilter) {
        return this.http.post<MyPageDataPagination>(`${this.rootUrl}${this.endpoint}`, filter);
    }

    markRead(mentionId: number, isRead: boolean){
        return this.http.post(`${this.rootUrl}${this.endpoint}/read/${mentionId}/${isRead}`, {});
    }
}
