import { Component, ViewChild, Inject, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base.component';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { MatCalendar } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlueZoneRFSUForecastDateUpdateRequest } from 'src/app/store/bluezone/actions';
import { PhaseType } from 'src/app/enums';
import { RFOForecastDateUpdateRequest, RFOFreezeDateUpdateRequest } from 'src/app/store/RFO/actions';
import { takeWhile } from 'rxjs/operators';
import { forecastDateUpdateRequest } from 'src/app/store/redzone/actions';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';

@Component({
    selector: 'app-clearable-mat-datepicker',
    templateUrl: './clearable-mat-datepicker.component.html',
    styleUrls: ['./clearable-mat-datepicker.component.scss'],
})
export class ClearableMatDatepickerComponent extends BaseComponent implements AfterViewInit {
    @ViewChild('calendar') calendar: MatCalendar<Date>;
    @Output() commonSaveAction: EventEmitter<moment.Moment> = new EventEmitter();

    selectedDate: Date;
    selectedDateSubsystem = '';
    selectedDateRFO = '';
    data: any[] = [];
    dateType: string;
    discipline: string;
    minDate: Date;
    maxDate: Date;
    allDisciplinesChecked = true;
    adjustMCForecastChecked = false;
    adjustDays = 21;

    constructor(
        @Inject(MAT_DIALOG_DATA) public popupData: any,
        private store: Store<ApplicationState>,
        private roleService: RoleService,
        private dialogRef: MatDialogRef<ClearableMatDatepickerComponent>
    ) {
        super();

        this.data = popupData.data;
        this.selectedDate = popupData.selectedDate !== '' ? moment(popupData.selectedDate).toDate() : null;
        ({
            selectedDateSubsystem: this.selectedDateSubsystem,
            selectedDateRFO: this.selectedDateRFO,
            dateType: this.dateType,
            discipline: this.discipline,
            minDate: this.minDate,
            maxDate: this.maxDate,
        } = popupData);
        if (popupData.commonSaveAction) {
            this.commonSaveAction
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((event) => popupData.commonSaveAction(event));
        }
    }

    saveDate() {
        if (
            this.dateType === 'mc' ||
            this.dateType === 'mcWalkdown' ||
            this.dateType === 'dac' ||
            this.dateType === 'constr90Walkdown'
        ) {
            let type;

            switch (this.dateType) {
                case 'mc':
                    type = PhaseType.MC;
                    break;
                case 'mcWalkdown':
                    type = PhaseType.Walkdown;
                    break;
                case 'dac':
                    type = PhaseType.DAC;
                    break;
                case 'constr90Walkdown':
                    type = PhaseType.Constr90Walkdown;
                    break;
            }
            const list = this.data.filter((m) => m.subsystem === this.selectedDateSubsystem);

            if (
                list.length > 0 &&
                this.selectedDate &&
                ((type === PhaseType.MC && !moment(list[0].mcForecast).isSame(moment(this.selectedDate).utc(true), 'day')) ||
                    (type === PhaseType.Walkdown &&
                        !moment(list[0].walkdownForecast).isSame(moment(this.selectedDate).utc(true), 'day')) ||
                    (type === PhaseType.DAC &&
                        !moment(list[0].dacForecast).isSame(moment(this.selectedDate).utc(true), 'day')) ||
                    (type === PhaseType.Constr90Walkdown &&
                        !moment(list[0].construction90Walkdown).isSame(moment(this.selectedDate).utc(true), 'day')))
            ) {
                this.store.dispatch(
                    forecastDateUpdateRequest({
                        subsystem: this.selectedDateSubsystem,
                        phaseType: type,
                        forecastDate: moment(this.selectedDate).utc(true),
                        discipline: this.discipline,
                        allDisciplines: this.allDisciplinesChecked,
                    })
                );
            }

            if (type === PhaseType.MC) {
                list.forEach((subsystem) => {
                    subsystem.mcForecast = this.selectedDate;
                });
            } else if (type === PhaseType.Constr90Walkdown) {
                list.forEach((subsystem) => {
                    subsystem.construction90Walkdown = this.selectedDate;
                });
            } else if (type === PhaseType.Walkdown) {
                if (this.adjustMCForecastChecked) {
                    const adjustedDate = moment(this.selectedDate).add(this.adjustDays, 'days').utc(true);
                    this.store.dispatch(
                        forecastDateUpdateRequest({
                            subsystem: this.selectedDateSubsystem,
                            phaseType: PhaseType.MC,
                            forecastDate: adjustedDate,
                            discipline: this.discipline,
                            allDisciplines: this.allDisciplinesChecked,
                        })
                    );
                    list.forEach((subsystem) => (subsystem.mcForecast = adjustedDate));
                }

                list.filter((x) => this.allDisciplinesChecked || x.discipline === this.discipline).forEach(
                    (subsystem) => {
                        subsystem.walkdownForecast = this.selectedDate;
                    }
                );
            } else if (type === PhaseType.DAC) {
                list.filter((x) => x.discipline === this.discipline).forEach((subsystem) => {
                    subsystem.dacForecast = this.selectedDate;
                });
            }

            this.selectedDateSubsystem = '';
        } else if (this.dateType === 'rfsu') {
            const list = this.data.filter((m) => m.subsystem === this.selectedDateSubsystem);
            const subsystem = this.data.find((s) => s.subsystem === this.selectedDateSubsystem);
            if (this.selectedDate && !moment(subsystem.rfsuForecast).isSame(moment(this.selectedDate).utc(true), 'day')) {
                this.store.dispatch(
                    new BlueZoneRFSUForecastDateUpdateRequest({
                        subsystem: this.selectedDateSubsystem,
                        type: PhaseType.RFSU,
                        rfsuForecastDate: moment(this.selectedDate).utc(true),
                        discipline: this.discipline,
                        allDisciplines: this.allDisciplinesChecked,
                    })
                );
            }
            list.forEach((subsystem) => {
                subsystem.rfsuForecast = this.selectedDate;
            });
            this.selectedDateSubsystem = '';
        } else if (this.dateType === 'rfsuWalkdown') {
            const subsystem = this.data.find((s) => s.subsystem === this.selectedDateSubsystem);
            const isSameDateAllowed =  this.roleService.isInRole(Constants.applicableGroups.Admin) ? true : !moment(subsystem.walkdownForecast).isSame(moment(this.selectedDate).utc(true), 'day') ;
            if (this.selectedDate && isSameDateAllowed) {
                this.store.dispatch(
                    new BlueZoneRFSUForecastDateUpdateRequest({
                        subsystem: this.selectedDateSubsystem,
                        type: PhaseType.Walkdown,
                        rfsuForecastDate: moment(this.selectedDate).utc(true),
                        discipline: this.discipline,
                        allDisciplines: this.allDisciplinesChecked,
                    })
                );
            }
            const list = this.data.filter((m) => m.subsystem === this.selectedDateSubsystem);
            if (this.adjustMCForecastChecked) {
                const adjustedDate = moment(this.selectedDate).add(this.adjustDays, 'days').utc(true);
                this.store.dispatch(
                    new BlueZoneRFSUForecastDateUpdateRequest({
                        subsystem: this.selectedDateSubsystem,
                        type: PhaseType.RFSU,
                        rfsuForecastDate: adjustedDate,
                        discipline: this.discipline,
                        allDisciplines: this.allDisciplinesChecked,
                    })
                );
                list.forEach((subsystem) => (subsystem.rfsuForecast = adjustedDate));
            }

            list.filter((x) => this.allDisciplinesChecked || x.discipline === this.discipline).forEach((subsystem) => {
                subsystem.walkdownForecast = this.selectedDate;
            });
            this.selectedDateSubsystem = '';
        } else if (this.dateType === 'rfo') {
            const rfo = this.data.find((s) => s.rfo === this.selectedDateRFO);
            if (this.selectedDate && !moment(rfo.rfoForecast).isSame(moment(this.selectedDate) .utc(true), 'day')) {
                this.store.dispatch(
                    new RFOForecastDateUpdateRequest({
                        rfo: this.selectedDateRFO,
                        rfoForecastDate: moment(this.selectedDate).utc(true),
                    })
                );
            }
            rfo.rfoForecast = this.selectedDate;
            this.selectedDateRFO = '';
         } else if (this.dateType === 'rfoFreeze') {
            const rfo = this.data.find((s) => s.rfo === this.selectedDateRFO);
            if (this.selectedDate && !moment(rfo.rfoFreeze).isSame(moment(this.selectedDate) .utc(true), 'day')) {
                this.store.dispatch(
                    new RFOFreezeDateUpdateRequest({
                        rfo: this.selectedDateRFO,
                        rfoFreezeDate: moment(this.selectedDate).utc(true),
                    })
                );
            }
            rfo.rfoFreeze = this.selectedDate;
            this.selectedDateRFO = '';
        } else if (this.dateType === 'common') {
            this.commonSaveAction.emit(moment(this.selectedDate).utc(true));
        }

        this.dialogRef.close(true);
    }

    ngAfterViewInit() {
        if (this.selectedDate !== null && this.selectedDate !== undefined) {
            setTimeout(() => (this.calendar.activeDate = moment(this.selectedDate).toDate()));
        }
    }

    clearDate() {
        this.selectedDate = null;

        if (
            this.dateType === 'mc' ||
            this.dateType === 'mcWalkdown' ||
            this.dateType === 'dac' ||
            this.dateType === 'constr90Walkdown'
        ) {
            let type;

            switch (this.dateType) {
                case 'mc':
                    type = PhaseType.MC;
                    break;
                case 'mcWalkdown':
                    type = PhaseType.Walkdown;
                    break;
                case 'dac':
                    type = PhaseType.DAC;
                    break;
                case 'constr90Walkdown':
                    type = PhaseType.Constr90Walkdown;
                    break;
            }

            const list = this.data.filter((m) => m.subsystem === this.selectedDateSubsystem);

            this.store.dispatch(
                forecastDateUpdateRequest({
                    subsystem: this.selectedDateSubsystem,
                    phaseType: type,
                    forecastDate: null,
                    discipline: this.discipline,
                    allDisciplines: this.allDisciplinesChecked,
                })
            );

            if (type === PhaseType.MC) {
                list.forEach((subsystem) => {
                    subsystem.mcForecast = this.selectedDate;
                });
            } else if (type === PhaseType.Constr90Walkdown) {
                list.forEach((subsystem) => {
                    subsystem.construction90Walkdown = this.selectedDate;
                });
            } else if (type === PhaseType.Walkdown) {
                list.filter((x) => this.allDisciplinesChecked || x.discipline === this.discipline).forEach(
                    (subsystem) => {
                        subsystem.walkdownForecast = this.selectedDate;
                    }
                );
            } else if (type === PhaseType.DAC) {
                list.forEach((subsystem) => {
                    subsystem.dacForecast = this.selectedDate;
                });
            }
        } else if (this.dateType === 'rfsu') {
            const subsystem = this.data.find((m) => m.subsystem === this.selectedDateSubsystem);

            this.store.dispatch(
                new BlueZoneRFSUForecastDateUpdateRequest({
                    subsystem: this.selectedDateSubsystem,
                    type: PhaseType.RFSU,
                    rfsuForecastDate: null,
                    discipline: this.discipline,
                    allDisciplines: this.allDisciplinesChecked,
                })
            );

            subsystem.rfsuForecast = null;
        } else if (this.dateType === 'rfsuWalkdown') {
            const subsystem = this.data.find((m) => m.subsystem === this.selectedDateSubsystem);

            this.store.dispatch(
                new BlueZoneRFSUForecastDateUpdateRequest({
                    subsystem: this.selectedDateSubsystem,
                    type: PhaseType.Walkdown,
                    rfsuForecastDate: null,
                    discipline: this.discipline,
                    allDisciplines: this.allDisciplinesChecked,
                })
            );

            subsystem.walkdownForecast = null;
        } else if (this.dateType === 'rfo') {
            const rfo = this.data.find((m) => m.rfo === this.selectedDateRFO);

            this.store.dispatch(
                new RFOForecastDateUpdateRequest({
                    rfo: this.selectedDateRFO,
                    rfoForecastDate: null,
                })
            );

            rfo.rfoForecast = null;
        }  else if (this.dateType === 'rfoFreeze') {
            const rfo = this.data.find((m) => m.rfo === this.selectedDateRFO);

            this.store.dispatch(
                new RFOFreezeDateUpdateRequest({
                    rfo: this.selectedDateRFO,
                    rfoFreezeDate: null,
                })
            );

            rfo.rfoFreeze = null;
        } else if (this.dateType === 'common') {
            this.commonSaveAction.emit(null);
        }

        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close();
    }
}
