<main class="main">
    <a class="navigator" [routerLink]="['/weeklyplanning']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span>{{ projectTeamName }} weekly planning session |</span>
            <span
                matTooltip="Click to change date range"
                matTooltipClass="scttooltip"
                class="date"
                *ngIf="!isWeekSelectorVisible"
                mat-icon-button
                (click)="showWeekSelector()"
                >{{ selectedWeekStartDate.value | date: ' MMM d' }} -{{ selectedWeekEndDate | date: ' MMM d y' }}</span
            >
        </div>
        <mat-form-field class="margin-left20" *ngIf="isWeekSelectorVisible" style="visibility: hidden;">
            <mat-label>Select week</mat-label>
            <mat-select
                #weekSelector="matSelect"
                [formControl]="selectedWeekStartDate"
                [compareWith]="compareDates"
                (openedChange)="onWeekSelectorClosed($event)"
            >
                <mat-option
                    *ngFor="let week of weekOptions"
                    [value]="week.startDate"
                    [ngClass]="setWeekOptionClasses(week)"
                    class="dateselect"
                >
                    {{ week.startDate | date: 'MMM d y' }} - {{ week.endDate | date: 'MMM d y' }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="weeklycom-button">
            <button
                *ngIf="isFilterVisible$ | async"
                (click)="reviewCommitments()"
                class="sct-button sct-button-light margin-right20"
            >
                weekly commitments
            </button>
        </div>
    </div>
    <nav mat-tab-nav-bar class="area-centered">
        <a
            mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
        >
            {{ link.label }}
        </a>
    </nav>
    <div class="filter-container" *ngIf="isFilterVisible$ | async">
        <app-weekly-planning-session-filter [projectTeamName]="projectTeamName" [expanded]="true"></app-weekly-planning-session-filter>
    </div>
    <router-outlet></router-outlet>
</main>
