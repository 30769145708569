<main>
    <form>
        <span class="close" (click)="close()">&times;</span>
        <p>enter {{title}}</p>
        <div
            #comment
            class="text-area"
            autocomplete="off"
            contenteditable="true"
            (paste)="onPaste($event)"
            [mention]="users"
            [mentionConfig]="mentionConfig"
        ></div>
        <div class="buttons-container flex center">
            <button type="button" (click)="close()" class="sct-button sct-button-white">cancel</button>
            <button type="button" (click)="onSubmit()" class="sct-button sct-button-light ok-button">ok</button>
            <button
                *ngIf="emailButtonVisible"
                type="button"
                (click)="onSendEmail()"
                class="sct-button sct-button-white"
                [disabled]="mentionedUsers.length == 0"
            >
                email
            </button>
            <button
                *ngIf="filteredCount !== 0"
                type="button"
                (click)="onSubmit(true)"
                class="sct-button sct-button-light ok-button lower-font"
            >
                Add to filtered ({{ filteredCount }})
            </button>
        </div>
    </form>
</main>
