import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ValidationFormService } from 'src/app/services/api/webapi-services/validation-form.service';
import {
    ValidationFormActionTypes,
    ValidationDocumentRequest,
    ValidationDocumentSuccess,
    ValidationDocumentError,
    ValidationUpdateStatusRequest,
    ValidationUpdateStatusSuccess,
    ValidationUpdateStatusError,
    ValidationUpdateRequest,
    ValidationUpdateError,
    ValidationUpdateSuccess,
    ValidationDocumentHeaderRequest,
    ValidationDocumentHeaderSuccess,
    ValidationDocumentHeaderError,
    ValidationDocumentChangesReviewedRequest,
    ValidationDocumentChangesReviewedError,
    ValidationDocumentChangesReviewedSuccess,
    ValidationDocumentChangeIsActiveRequest,
    ValidationDocumentChangeIsActiveSuccess,
    ValidationDocumentChangeIsActiveError,
    ValidationDocumentSaveSubsystemsRequest,
    ValidationDocumentSaveSubsystemsSuccess,
    ValidationDocumentSaveSubsystemsError,
    ValidationDocumentUpdateIsActiveForDisciplineRequest,
    ValidationDocumentUpdateIsActiveForDisciplineSuccess,
    ValidationDocumentUpdateIsActiveForDisciplineError,
    ValidationDocumentUpdateSystemizationRemarkRequest,
    ValidationDocumentUpdateSystemizationCompletedRequest,
    ValidationDocumentUpdateSystemizationCompletedSuccess
} from './action';
import { ValidationDocument, ValidationDocumentResult } from './model';
import { ValidStatuses } from 'src/app/models/validation-statuses';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';

@Injectable()
export class ValidationFormEffects {
    @Effect()
    loadValidationDocumentHeader$ = this.actions$.pipe(
        ofType(ValidationFormActionTypes.ValidationDocumentHeaderRequest),
        mergeMap((action: ValidationDocumentHeaderRequest) =>
            this.validationFormService.getValidationDocumentHeader(action.payload).pipe(
                map((validationDocHead) => new ValidationDocumentHeaderSuccess(validationDocHead)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading Validation Document Header. Please contact Program Administrator.'
                    );
                    return of(new ValidationDocumentHeaderError(error));
                })
            )
        )
    );

    @Effect()
    loadValidationDocument$ = this.actions$.pipe(
        ofType(ValidationFormActionTypes.ValidationDocumentRequest),
        mergeMap((action: ValidationDocumentRequest) =>
            this.validationFormService.getValidationDocument(action.payload).pipe(
                map((validationDoc) => new ValidationDocumentSuccess(validationDoc)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading Validation Document. Please contact Program Administrator.'
                    );
                    return of(new ValidationDocumentError(error));
                })
            )
        )
    );

    @Effect()
    validationDocumentUpdateStatus$ = this.actions$.pipe(
        ofType<ValidationUpdateStatusRequest>(ValidationFormActionTypes.ValidationUpdateStatusRequest),
        withLatestFrom(
            this.store.select((store) => store.validationFormState.updatedProperties),
            this.store.select((store) => store.validationFormState.validationDocumentResult)
        ),
        mergeMap(([action, updatedProperties, validationForm]) => {
            if (
                updatedProperties &&
                updatedProperties.length &&
                ((validationForm.validation.validationStatus === ValidStatuses.NotStarted ||
                    (validationForm.validation.validationStatus === ValidStatuses.InProgress &&
                        action.payload.newStatus === ValidStatuses.Completed))
                 ||(validationForm.validationICT.validationStatus === ValidStatuses.NotStarted ||
                    (validationForm.validationICT.validationStatus === ValidStatuses.InProgress &&
                        action.payload.newStatus === ValidStatuses.Completed))
                 ||(validationForm.validationSMP.validationStatus === ValidStatuses.NotStarted ||
                    (validationForm.validationSMP.validationStatus === ValidStatuses.InProgress &&
                        action.payload.newStatus === ValidStatuses.TestPackReview) ||
                    (validationForm.validationSMP.validationStatus === ValidStatuses.TestPackReview &&
                        action.payload.newStatus === ValidStatuses.Completed) ||
                    (validationForm.validationSMP.validationStatus === ValidStatuses.InProgress &&
                        action.payload.newStatus === ValidStatuses.Completed)))
            ) {
                return this.updateDocument(validationForm, updatedProperties).pipe(
                    ofType<ValidationUpdateSuccess>(ValidationFormActionTypes.ValidationUpdateSuccess),
                    mergeMap(() => {
                        return this.updateStatus(action);
                    })
                );
            } else {
                return this.updateStatus(action);
            }
        })
    );

    @Effect()
    validationDocumentUpdate$ = this.actions$.pipe(
        ofType<ValidationUpdateRequest>(ValidationFormActionTypes.ValidationUpdateRequest),
        withLatestFrom(
            this.store.select((store) => store.validationFormState.updatedProperties),
            this.store.select((store) => store.validationFormState.validationDocumentResult)
        ),
        mergeMap(([, updatedProperties, validationForm]) => {
            return this.updateDocument(validationForm, updatedProperties);
        })
    );

    @Effect()
    ValidationDocumentChangesReviewed$ = this.actions$.pipe(
        ofType<ValidationDocumentChangesReviewedRequest>(
            ValidationFormActionTypes.ValidationDocumentChangesReviewedRequest
        ),
        mergeMap((action) => {
            return this.validationFormService.reviewChanges(action.payload).pipe(
                map((validationHeader) => {
                    this.toastService.Success(`Validation document changes reviewed.`);
                    return new ValidationDocumentChangesReviewedSuccess(validationHeader);
                }),
                catchError((error) => {
                    this.toastService.Error('Error occurred while update. Please contact Program Administrator.');
                    return of(new ValidationDocumentChangesReviewedError(error));
                })
            );
        })
    );

    @Effect()
    ValidationDocumentChangeIsActive$ = this.actions$.pipe(
        ofType<ValidationDocumentChangeIsActiveRequest>(
            ValidationFormActionTypes.ValidationDocumentChangeIsActiveRequest
        ),
        mergeMap((action) => {
            return this.validationFormService.changeIsActive(action.payload).pipe(
                map((validationHeader) => {
                    let text = validationHeader.isActive
                        ? `Validation document has changed to Active.`
                        : `Validation document has changed to Inactive.`;
                    this.toastService.Success(text);
                    return new ValidationDocumentChangeIsActiveSuccess(validationHeader);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while update is Active. Please contact Program Administrator.'
                    );
                    return of(new ValidationDocumentChangeIsActiveError(error));
                })
            );
        })
    );

    @Effect()
    ValidationDocumentUpdateIsActiveForDiscipline$ = this.actions$.pipe(
        ofType<ValidationDocumentUpdateIsActiveForDisciplineRequest>(
            ValidationFormActionTypes.ValidationDocumentUpdateIsActiveForDisciplineRequest
        ),
        mergeMap((action) => {
            return this.validationFormService.updateIsActiveForValidationDiscipline(action.payload.id,action.payload.discipline).pipe(
                map((validation) => {
                    let text = validation.validation.isActive
                        ? `Validation has changed to Active.`
                        : validation.validationICT.isActive 
                        ? `ValidationICT has changed to Active.`
                        : validation.validationSMP.isActive 
                        ? `ValidationSMP has changed to Active.`
                        : `Validation has changed to Inactive.`;
                    this.toastService.Success(text);
                    return new ValidationDocumentUpdateIsActiveForDisciplineSuccess(validation);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while update is Active. Please contact Program Administrator.'
                    );
                    return of(new ValidationDocumentUpdateIsActiveForDisciplineError(error));
                })
            );
        })
    );

    @Effect()
    ValidationDocumentUpdateSystemizationRemark$ = this.actions$.pipe(
        ofType<ValidationDocumentUpdateSystemizationRemarkRequest>(
            ValidationFormActionTypes.ValidationDocumentUpdateSystemizationRemarkRequest
        ),
        mergeMap((action) => {
            return this.validationFormService.updateSystemizationRemark(action.payload.id,action.payload.remark).pipe(
                map(() => {
                    this.toastService.Success("Remarks has been updated");

                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while update is Active. Please contact Program Administrator.'
                    );
                    return of(new ValidationDocumentUpdateIsActiveForDisciplineError(error));
                })
            );
        })
    );

    @Effect()
    ValidationDocumentUpdateSystemizationCompleted$ = this.actions$.pipe(
        ofType<ValidationDocumentUpdateSystemizationCompletedRequest>(
            ValidationFormActionTypes.ValidationDocumentUpdateSystemizationCompletedRequest
        ),
        mergeMap((action) => {
            return this.validationFormService.updateSystemizationCompleted(action.payload.validId).pipe(
                map((data) => {
                    this.toastService.Success("Systemization Status has been updated"); 
                    return new ValidationDocumentUpdateSystemizationCompletedSuccess(data);         
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while update is Active. Please contact Program Administrator.'
                    );
                    return of(new ValidationDocumentUpdateIsActiveForDisciplineError(error));
                })
            );
        })
    );

    @Effect()
    saveSubsystemRequest$ = this.actions$.pipe(
        ofType<ValidationDocumentSaveSubsystemsRequest>(
            ValidationFormActionTypes.ValidationDocumentSaveSubsystemsRequest
        ),
        mergeMap((action) => {
            return this.adminPanelService.updateSubsystems(action.payload.id, action.payload.subsystems).pipe(
                switchMap((data) => [
                    new ValidationDocumentSaveSubsystemsSuccess(data),
                    new ValidationDocumentHeaderRequest(action.payload.validationId),
                ]),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while save Change documents subsytems. Please contact Program Administrator.'
                    );
                    return of(new ValidationDocumentSaveSubsystemsError(error));
                })
            );
        })
    );

    constructor(
        private actions$: Actions,
        private validationFormService: ValidationFormService,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private adminPanelService: AdminPanelService
    ) {}

    private updateDocument(validationForm: ValidationDocumentResult, updatedProperties: string[]) {
        return this.validationFormService.updateValidationDocument(validationForm, updatedProperties).pipe(
            map((validationDoc) => {
                this.toastService.Success('Validation form was successfully saved.');
                return new ValidationUpdateSuccess(validationDoc);
            }),
            catchError((error) => {
                this.toastService.Error(
                    'Error occurred while updating validation. Please contact Program Administrator.'
                );
                return of(new ValidationUpdateError(error));
            })
        );
    }

    private updateStatus(action: ValidationUpdateStatusRequest) {
        return this.validationFormService
            .updateValidationDocumentStatus(action.payload.id, action.payload.newStatus, action.payload.type)
            .pipe(
                map((validationDoc) => {
                    this.toastService.Success(
                        `Validation form was successfully updated`
                    );
                    return new ValidationUpdateStatusSuccess(validationDoc);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating validation status. Please contact Program Administrator.'
                    );
                    return of(new ValidationUpdateStatusError(error));
                })
            );
    }
}
