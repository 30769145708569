import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'app-reports-health-cleanliness-table',
    templateUrl: './reports-health-cleanliness-table.component.html',
    styleUrls: ['./reports-health-cleanliness-table.component.scss'],
})
export class ReportsHealthCleanlinessTableComponent extends BaseComponent implements OnInit {
    pastWeeks$ = this.store.select((store) => store.healthState.cleanliness.weeks);
    grandTotals$ = this.store.select((store) => store.healthState.cleanliness.data);
    pastWeeks = [];

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {
        this.pastWeeks$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.pastWeeks = data.map((s) => {
                return { week: moment(s.date).format('w'), date: s.weekEnd };
            });
        });
    }
}
