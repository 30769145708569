import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile, take, catchError } from 'rxjs/operators';
import { CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AddWaypointPopupComponent } from './add-popup/add-waypoint-popup.component';
import { BaseComponent } from 'src/app/components/base.component';
import { ToastService } from 'src/app/services/shared/toast.service';
import { WaypointDTO } from 'src/app/models/waypoint-dto';
import { WaypointService } from 'src/app/services/api/webapi-services/waypoint.service';
import { of } from 'rxjs';
import * as _ from 'lodash';
import { HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';

@Component({
    selector: 'app-waypoint-maintenance',
    templateUrl: './waypoint-maintenance.component.html',
    styleUrls: ['./waypoint-maintenance.component.scss'],
})
export class WaypointMaintenanceComponent extends BaseComponent implements OnInit {
    resultsLength = 0;
    pageSize = 10;
    sortBy: string = 'name';
    direction = OrderDirection.Asc;
    isLoading = false;
    displayedColumns = ['name', 'description', 'edit', 'remove'];
    dataSource = new MatTableDataSource<WaypointDTO>();
    allElements: WaypointDTO[] = [];
    columnNames: CheckListColumn = null;
    columnDescriptions: CheckListColumn = null;
    columnFilterValueBeforeReset: CheckListColumn = null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @Output() waypointsChanged = new EventEmitter<WaypointDTO[]>();
    @Output() waypointRemoved = new EventEmitter<WaypointDTO>();

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private popupService: PopupService,
        private waypointService: WaypointService,
        private toastService: ToastService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.isLoading = true;

        this.waypointService
            .get()
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error('Error occurred while getting waypoints. Please contact Program Administrator.');
                })
            )
            .subscribe(
                (data) => {
                    this.allElements = data;
                    this.populateDataTable(data);
                    this.isLoading = false;
                },
                (error) => {
                    this.isLoading = false;
                    this.toastService.Error(error);
                }
            );
    }

    populateDataTable(approvalMatrix: WaypointDTO[]) {
        this.dataSource = new MatTableDataSource(approvalMatrix);
        this.resultsLength = this.dataSource.data.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    remove(element: WaypointDTO) {
        const popupText = element.subsystemLinked
            ? `Waypoint ${element.name} linked to existing Subsystem. Deleting Waypoint also deletes all Milestones linked to the Waypoint. Are you sure you want to delete?`
            : `Deleting Waypoint also deletes all Milestones linked to the Waypoint. Delete Waypoint ${element.name}?`;
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: popupText,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.isLoading = true;
                    this.waypointService
                        .remove(element.name)
                        .pipe(takeWhile(() => this.isAlive === true))
                        .subscribe(
                            () => {
                                let data = this.dataSource.data.filter((s) => s.name !== element.name);
                                this.allElements = data;
                                this.populateDataTable(data);
                                this.isLoading = false;
                                this.toastService.Success(`Waypoint ${element.name} removed.`);
                                this.waypointsChanged.emit(data);
                                this.waypointRemoved.emit(element);
                            },
                            () => {
                                this.isLoading = false;
                                this.toastService.Error(
                                    `Error occurred while deleting ${element.name}. Please contact Program Administrator.`
                                );
                            }
                        );
                }
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(new PopupSettings(AddWaypointPopupComponent, 450, 400));

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    let data = this.dataSource.data;
                    data.push(result.data);
                    this.allElements = data;
                    this.populateDataTable(data);
                    this.waypointsChanged.emit(data);
                }
            });
    }

    enterEditMode(waypoint: WaypointDTO) {
        waypoint.isInEditMode = !waypoint.isInEditMode;
        waypoint.prevDescription = waypoint.description;
    }

    save(waypoint: WaypointDTO) {
        waypoint.isInEditMode = !waypoint.isInEditMode;

        if (waypoint.description !== waypoint.prevDescription) {
            this.isLoading = true;
            this.waypointService
                .update(waypoint)
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe(
                    () => {
                        this.isLoading = false;
                        this.toastService.Success(`Waypoint ${waypoint.name} saved.`);
                    },
                    (error) => {
                        this.isLoading = false;
                        if (error.status === 409) {
                            this.toastService.Error(`Waypoint entered ${waypoint.name} already exists in the SCMT.`);
                        } else {
                            this.toastService.Error(
                                `Error occurred while updating ${waypoint.name}. Please contact Program Administrator.`
                            );
                        }
                        this.rollbackChanges(waypoint);
                    }
                );
        }
    }

    cancelEdit(waypoint: WaypointDTO) {
        waypoint.isInEditMode = !waypoint.isInEditMode;
        this.rollbackChanges(waypoint);
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        this.paginator.pageIndex = newPage - 1;
        this.dataSource.paginator = this.paginator;
    }

    private rollbackChanges(waypoint: WaypointDTO) {
        waypoint.description = waypoint.prevDescription;
    }

    getValues = (search = '', take = 30, page = 0, column = 'number') => {
        let elements = this.filterElements(this.allElements);
        if (elements.length) {
            let result = elements.map((s) => s[column]).sort();
            if (search !== '') {
                result = result.filter((s) => s.toLowerCase().indexOf(search.toLowerCase()) > -1);
            }
            let counts = _.countBy(result);
            let keys = _.keys(counts);
            return of(keys.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(
                HeaderChecklistFilterComponent,
                400,
                650,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                    placeHolder: placeholder,
                    searchFunc: searchFunc,
                    selectedItems: selected ? [...selected.items] : [],
                    isSortingOff: false,
                    showCounts,
                    showInputSearch: showInputSearch,
                    column: columnName,
                    action: (data) => {
                        let value = new CheckListColumn();
                        value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                        this[propertyName] = value;

                        let results = this.search();
                        this.populateDataTable(results);
                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    },
                    resetColumnAction: () => {
                        this.columnFilterValueBeforeReset = this[propertyName];
                        this[propertyName] = null;
                    },
                    cancelAction: () => {
                        this[propertyName] = this.columnFilterValueBeforeReset;
                        this.columnFilterValueBeforeReset = null;
                    },
                },
                400,
                620
            )
        );
    }

    private filterElements(elements: WaypointDTO[]) {
        let filteredElements = elements;
        if (this.columnNames && this.columnNames.items.length) {
            filteredElements = elements.filter((e) => this.columnNames.items.some((i) => i === e.name));
        }
        if (this.columnDescriptions && this.columnDescriptions.items.length) {
            filteredElements = elements.filter((e) => this.columnDescriptions.items.some((i) => i === e.description));
        }
        return filteredElements;
    }

    private search() {
        let results = this.allElements;
        if (this.columnNames && this.columnNames.items.length) {
            results = results.filter((s) => this.columnNames.items.indexOf(s.name) > -1);
        }
        if (this.columnDescriptions && this.columnDescriptions.items.length) {
            results = results.filter((s) => this.columnDescriptions.items.indexOf(s.description) > -1);
        }
        return results;
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction = isAscendingSort ? 'asc' : 'desc';
            const matSort = this.dataSource.sort;
            const disableClear = false;

            matSort.sort({ id: null, start: direction, disableClear });
            matSort.sort({ id: columnName, start: direction, disableClear });

            this.dataSource.sort = matSort;
            this.direction = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.sortBy = columnName;
        } else {
            this.direction = OrderDirection.Asc;
            this.sortBy = 'name';
            const matSort = this.dataSource.sort;
            const disableClear = false;

            matSort.sort({ id: null, start: 'asc', disableClear });
            matSort.sort({ id: this.sortBy, start: 'asc', disableClear });
        }
    }
}
