import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { RedZoneService } from 'src/app/services/api/webapi-services/redzone.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { Zone } from 'src/app/enums';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import * as RedZoneActions from './actions';

@Injectable()
export class RedZoneEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private redZoneService: RedZoneService,
        private commentService: CommentService
    ) {}

    filterResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RedZoneActions.filterRequest),
            withLatestFrom(this.store.select((store) => store.redZone.filter)),
            mergeMap(([action, redZoneFilter]) => {
                let filter = action.filter ?? redZoneFilter;
                return this.redZoneService.getRedZoneData(filter).pipe(
                    map((dataPagination) => RedZoneActions.filterSuccess(dataPagination)),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while filtering subsystem summary page. Please contact Program Administrator.'
                        );
                        return of(RedZoneActions.filterError({ error }));
                    })
                );
            })
        )
    );

    deleteComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RedZoneActions.removeCommentRequest),
            withLatestFrom(this.store.select((store) => store.redZone.filter)),
            mergeMap(([action, filter]) =>
                this.commentService
                    .deleteZoneComment(action.id, filter.disciplineBreakdownEnabled ? Zone.Red : Zone.RedNoDiscipline)
                    .pipe(
                        map(() => {
                            this.toastService.Success('Comment successfully removed.');
                            return RedZoneActions.removeCommentSuccess({
                                id: action.id,
                                lastComment: action.lastComment,
                                entityId: action.entityId,
                            });
                        }),
                        catchError((error) => {
                            this.toastService.Error(
                                'Error occurred while removing Redzone comment. Please contact Program Administrator.'
                            );
                            return of(RedZoneActions.removeCommentError({ error }));
                        })
                    )
            )
        )
    );

    exportToExcel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RedZoneActions.exportToExcelRequest),
            withLatestFrom(this.store.select((store) => store.redZone.filter)),
            mergeMap(([, redZoneFilter]) => {
                const filter = {
                    ...redZoneFilter,
                    timezoneOffset: new Date().getTimezoneOffset(),
                };
                return this.redZoneService.generateExcel(filter).pipe(
                    map((excelData) => RedZoneActions.exportToExcelSuccess({ response: excelData })),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                        );
                        return of(RedZoneActions.exportToExcelError({ error }));
                    })
                );
            })
        )
    );

    saveExcelData$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RedZoneActions.exportToExcelSuccess),
                map((action) => {
                    this.toastService.Success('Data successfully exported to Excel.');

                    const blob = new Blob([action.response], {
                        type: 'application/octet-stream',
                    });

                    saveAs(blob, `RedZone_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
                })
            ),
        { dispatch: false }
    );

    updateMcForecastDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RedZoneActions.forecastDateUpdateRequest),
            mergeMap((action) => {
                return this.redZoneService
                    .updateForecastDate(
                        action.subsystem,
                        action.phaseType,
                        action.forecastDate,
                        getCurrentWeekStartDate().format('YYYY-MM-DD'),
                        action.discipline,
                        action.allDisciplines
                    )
                    .pipe(
                        map(() => RedZoneActions.forecastDateUpdateSuccess()),
                        catchError((error) => {
                            this.toastService.Error(
                                'Error occurred while updating MC Forecast date. Please contact Program Administrator.'
                            );
                            return of(RedZoneActions.forecastDateUpdateError({ error }));
                        })
                    );
            })
        )
    );

    addComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RedZoneActions.addCommentRequest),
            withLatestFrom(this.store.select((store) => store.redZone.filter)),
            mergeMap(([action, filter]) => {
                return this.commentService
                    .addCommentZone(
                        action.id.toString(),
                        action.description,
                        filter.disciplineBreakdownEnabled ? Zone.Red : Zone.RedNoDiscipline,
                        action.mentions
                    )
                    .pipe(
                        map(() => {
                            this.toastService.Success('Comment added successfully.');
                            return RedZoneActions.addCommentSuccess({
                                id: action.id,
                                description: action.description,
                                mentions: action.mentions,
                            });
                        }),
                        catchError((error) => {
                            this.toastService.Error(
                                'Error occurred while adding comment. Please contact Program Administrator.'
                            );
                            return of(RedZoneActions.addCommentError({ error }));
                        })
                    );
            })
        )
    );

    addBulkComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RedZoneActions.addBulkCommentRequest),
            withLatestFrom(this.store.select((store) => store.redZone.filter)),
            mergeMap(([action, filter]) => {
                return this.commentService
                    .addBulkCommentZone(
                        action.description,
                        action.mentions,
                        filter,
                        filter.disciplineBreakdownEnabled ? Zone.Red : Zone.RedNoDiscipline
                    )
                    .pipe(
                        map(() => {
                            this.toastService.Success('Bulk comment added successfully.');
                            return RedZoneActions.addBulkCommentSuccess({
                                description: action.description,
                                mentions: action.mentions,
                            });
                        }),
                        catchError((error) => {
                            this.toastService.Error(
                                'Error occurred while adding bulk comment. Please contact Program Administrator.'
                            );
                            return of(RedZoneActions.addBulkCommentError({ error }));
                        })
                    );
            })
        )
    );

    flagSubsystem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RedZoneActions.setFlagColorRequest),
            mergeMap((action) =>
                this.redZoneService.flagSubsystem(action.subsystem, action.color).pipe(
                    map(() => {
                        this.toastService.Success('Successfully updated subsystem flag color.');
                        return RedZoneActions.setFlagColorSuccess({
                            subsystem: action.subsystem,
                            color: action.color,
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occured while updating subsystem flag color. Please contact Program Administrator.'
                        );
                        return of(RedZoneActions.setFlagColorError({ error }));
                    })
                )
            )
        )
    );

    clearSubsystemFlag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RedZoneActions.clearFlagColorRequest),
            withLatestFrom(this.store.select((store) => store.redZone.filter)),
            mergeMap(([action, filter]) => {
                return this.redZoneService.clearSubystemFlag(filter).pipe(
                    map(() => {
                        this.toastService.Success('Successfully cleared subsystems flag color.');
                        return RedZoneActions.clearFlagColorSuccess();
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while clearing subsystem flag color. Please contact Program Administrator.'
                        );
                        return of(RedZoneActions.clearFlagColorError({ error }));
                    })
                );
            })
        )
    );
}
