import {
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    OnDestroy,
    AfterViewInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { CdkPortal, DomPortalHost } from '@angular/cdk/portal';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/components/base.component';
import { ScheduleActivityNotInWeekPlanningDataRequest, ScheduleActivityPlanningDataRequest } from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import { takeWhile } from 'rxjs/operators';
import { WeeklyPlanType } from 'src/app/enums';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { ScheduleActivityPlanningFilter } from 'src/app/store/weekly-planning/schedule-activity-planning/model';
import { WorkAreaWorkerDTO } from 'src/app/store/common.model';

@Component({
    selector: 'app-schedule-activity-print',
    templateUrl: './schedule-activity-print.component.html',
    styleUrls: ['./schedule-activity-print.component.scss', '../../schedule-activity-planning-table.component.scss'],
})
export class ScheduleActivityPrintComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() close = new EventEmitter();
    @ViewChild(CdkPortal) portal: CdkPortal;

    weeklyPlanType = WeeklyPlanType.weekly;
    filter: ScheduleActivityPlanningFilter;
    printInWeekIsLoading$ = this.store.select((state) => state.scheduleActivityPlanningState.printInWeekIsLoading);
    printNotInWeekIsLoading$ = this.store.select((state) => state.scheduleActivityPlanningState.printNotInWeekIsLoading);    
    printIsLoading$ = combineLatest([this.printInWeekIsLoading$, this.printNotInWeekIsLoading$], (x,y)=> x || y);      

    printData$ = this.store.select((state) => state.scheduleActivityPlanningState.printData);
    notInWeekPrintData$ = this.store.select((state) => state.scheduleActivityPlanningState.notInWeekPrintData);
    filter$ = this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter);
    weekEnd$ = this.store.select((state) =>
        moment(state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekStart).add(
            state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.lookaheadDays - 1,
            'days'
        ).format('D/MMM/YY')
    );

    private externalWindow: Window = null;
    private host: DomPortalHost;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private store: Store<ApplicationState>
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(new ScheduleActivityPlanningDataRequest(true));
        this.store.dispatch(new ScheduleActivityNotInWeekPlanningDataRequest(true));

        this.filter$.pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => {
                this.filter = data;
            })
    }

    ngAfterViewInit() {
        this.printIsLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            if (isLoading === false) {
                setTimeout(() => this.externalWindow.print(), 1000);
            };
        });

        this.externalWindow = window.open('', 'Print Schedule Activities', '');

        this.externalWindow.onbeforeunload = () => this.ngOnDestroy();

        this.host = new DomPortalHost(
            this.externalWindow.document.body,
            this.componentFactoryResolver,
            this.applicationRef,
            this.injector
        );

        document.querySelectorAll('link, style').forEach((htmlElement) => {
            this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
        });

        this.host.attach(this.portal);
        this.SetFuctionalityBackForNgTemplatesFromCdkPortal();
    }

    private SetFuctionalityBackForNgTemplatesFromCdkPortal() {
        const popoverContent = document.querySelector('popover-content') as HTMLElement;
        popoverContent.style.display = 'block';
        const buttonAdd = document.querySelector('.add-comment') as HTMLElement;
        buttonAdd.style.display = 'block';
    }

    ngOnDestroy() {
        this.externalWindow.close();
        this.close.emit();
        this.host.detach();
    }

    workersToList(workers: WorkAreaWorkerDTO[]) {
        return workers ? workers.map(w => w.name).join(', ') : '';
    }

    getWeekEnd(filter: ScheduleActivityPlanningFilter) {
        return (
            moment(filter?.weekStart)
                .add(filter.lookaheadDays - 1, 'day')
                .toDate() ?? ''
        );
    }

    getWorkAreas(filter: any) {
        return filter?.workAreas?.map(wa => typeof wa === 'string' ? wa : wa?.workAreaName).join(', ') || '';
    }
}
