import { FieldChangeSummaryAction, FieldChangeSummaryActionType } from './actions';
import { FieldChangeDocumentSummaryState, FieldChangeDocumentSummaryDTO } from './model';

const initialState: FieldChangeDocumentSummaryState = {
    data: new FieldChangeDocumentSummaryDTO(),
    isLoading: false,
};

export function reducer(state = initialState, action: FieldChangeSummaryAction) {
    switch (action.type) {
        case FieldChangeSummaryActionType.FieldChangeSummaryRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case FieldChangeSummaryActionType.FieldChangeSummarySuccess: {
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        }
        case FieldChangeSummaryActionType.FieldChangeSummaryError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}
