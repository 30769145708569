import {
    MilestoneDashboardState,
    MilestoneDashboardFilter,
    MilestoneCommentType,
    MilestoneCommentDTO,
    MilestoneDashboardType,
    TotalCompleteDataDTO,
} from './model';
import { MilestoneDashboardActions, MilestoneDashboardActionTypes } from './actions';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import { ICICLE_API_CALL_DATE_FORMAT, ICICLE_RANGE_WEEKS } from '../icicle/model';
import { WeekType } from 'src/app/enums';
import { MCScheduleState, DashboardSkylineState } from '../pre-mc/model';
import * as moment from 'moment';
import * as _ from 'lodash';
import {
    PlanningLookaheadCounters,
    PlanningLookaheadDTO,
    PlanningLookaheadReportDataItem,
    PlanningLookaheadState,
} from '../planning/model';
import { calculateTotal, withinWorkWeek } from '../planning/reducer';
import { Constants } from 'src/app/constants';

const initialState: MilestoneDashboardState = {
    icicle: [],
    isLoadingTotalComplete: false,
    isIcicleLoading: false,
    isRundownLoading: false,
    totalCompleteData: null,
    rundownData: null,
    filters: {
        rfo: new MilestoneDashboardFilter(),
        mc: new MilestoneDashboardFilter(),
    },
    dashboardType: null,
    icicleType: 'Forecast',
    monthViewEnabled: false,
    startDate: getCurrentWeekStartDate().add(ICICLE_RANGE_WEEKS, 'weeks').format(ICICLE_API_CALL_DATE_FORMAT),
    comments: [],
    isCommentLoading: false,
    mcMilestoneSkyline: new DashboardSkylineState(),
    mcMilestoneSchedule: new MCScheduleState(),
    lookahead: {
        ...new PlanningLookaheadState(),
        lookaheadType: 'mc',
        data: [],
    },
    rfsulookahead: {
        ...new PlanningLookaheadState(),
        lookaheadType: 'rfsu',
        data: [],
    },
    subsystemTable: null,
    isSubsystemTableLoading: false,
    rfsuMilestoneSkyline: new DashboardSkylineState(),
};

export function reducer(state = initialState, action: MilestoneDashboardActions): MilestoneDashboardState {
    switch (action.type) {
        case MilestoneDashboardActionTypes.MilestoneDashboardTotalCompleteRequest: {
            return {
                ...state,
                isLoadingTotalComplete: true,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardTotalCompleteRequestSuccess: {
            return {
                ...state,
                totalCompleteData: action.payload.map((x) => ({ ...x, expanded: false } as TotalCompleteDataDTO)),
                isLoadingTotalComplete: false,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardTotalCompleteRequestError: {
            return {
                ...state,
                isLoadingTotalComplete: false,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardFilterReset: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.type]: new MilestoneDashboardFilter(),
                },
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardFilterUpdate: {
            return action.payload.type === MilestoneDashboardType.MC
                ? {
                      ...state,
                      filters: { ...state.filters, mc: new MilestoneDashboardFilter(action.payload.filter) },
                  }
                : {
                      ...state,
                      filters: { ...state.filters, rfo: new MilestoneDashboardFilter(action.payload.filter) },
                  };
        }
        case MilestoneDashboardActionTypes.RFOMilestoneDashboardIcicleRequest:
        case MilestoneDashboardActionTypes.MCMilestoneDashboardIcicleRequest: {
            return {
                ...state,
                isIcicleLoading: true,
            };
        }
        case MilestoneDashboardActionTypes.RFOMilestoneDashboardIcicleRequestSuccess:
        case MilestoneDashboardActionTypes.MCMilestoneDashboardIcicleRequestSuccess: {
            return {
                ...state,
                icicle: action.payload.data,
                icicleType: action.payload.type,
                isIcicleLoading: false,
            };
        }
        case MilestoneDashboardActionTypes.RFOMilestoneDashboardIcicleRequestError:
        case MilestoneDashboardActionTypes.MCMilestoneDashboardIcicleRequestError: {
            return {
                ...state,
                isIcicleLoading: false,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardRundownRequest: {
            return {
                ...state,
                isRundownLoading: true,
                monthViewEnabled: action.payload.monthlyView,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardRundownSuccess: {
            return {
                ...state,
                isRundownLoading: false,
                rundownData: action.payload,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardRundownError: {
            return {
                ...state,
                isRundownLoading: false,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardSetType: {
            return {
                ...state,
                dashboardType: action.payload,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardWeekRangeSet: {
            return {
                ...state,
                startDate: action.payload,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardCommentsRequest: {
            return {
                ...state,
                isCommentLoading: true,
                comments: [],
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardCommentsSuccess: {
            return {
                ...state,
                isCommentLoading: false,
                comments: action.payload,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardAddCommentError:
        case MilestoneDashboardActionTypes.MilestoneDashboardRemoveCommentError:
        case MilestoneDashboardActionTypes.MilestoneDashboardCommentsError: {
            return {
                ...state,
                isCommentLoading: false,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardRemoveCommentRequest:
        case MilestoneDashboardActionTypes.MilestoneDashboardAddCommentRequest: {
            return {
                ...state,
                isCommentLoading: true,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardAddCommentSuccess: {
            let commentType = MilestoneCommentType[action.payload.type].toLowerCase();
            let commentsAll = [...state.comments].map((commentsSection) => {
                if (commentsSection.type === action.payload.userType) {
                    commentsSection[commentType] = [...commentsSection[commentType], action.payload.newComment];
                }
                return commentsSection;
            });

            return {
                ...state,
                isCommentLoading: false,
                comments: commentsAll,
            };
        }
        case MilestoneDashboardActionTypes.MilestoneDashboardRemoveCommentSuccess: {
            let commentType = MilestoneCommentType[action.payload.type].toLowerCase();
            let commentsAll = [...state.comments].map((commentsSection) => {
                if (commentsSection.type === action.payload.userType) {
                    commentsSection[commentType] = commentsSection[commentType].filter(
                        (comment) => comment.id !== action.payload.id
                    );
                }
                return commentsSection;
            });

            return {
                ...state,
                isCommentLoading: false,
                comments: commentsAll,
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardSkylineRequest: {
            return {
                ...state,
                mcMilestoneSkyline: {
                    ...state.mcMilestoneSkyline,
                    isLoading: true,
                },
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardSkylineSuccess: {
            const openAqvdsPerWeek = action.payload.map((week) => ({
                week: week.weekStartDate,
                count: _.sumBy(week.subsystems, (x) => x.aqvd),
            }));
            return {
                ...state,
                mcMilestoneSkyline: {
                    isLoading: false,
                    items: action.payload.map((week) => week.subsystems),
                    weeks: action.payload.map((week) => ({
                        date: moment(week.weekStartDate),
                        weekType: WeekType.Past,
                    })),
                    openAqvdsPerWeek,
                },
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardSkylineError: {
            return {
                ...state,
                mcMilestoneSkyline: {
                    ...state.mcMilestoneSkyline,
                    isLoading: false,
                },
            };
        }
        case MilestoneDashboardActionTypes.RFSUMilestoneDashboardSkylineRequest: {
            return {
                ...state,
                rfsuMilestoneSkyline: {
                    ...state.rfsuMilestoneSkyline,
                    isLoading: true,
                },
            };
        }
        case MilestoneDashboardActionTypes.RFSUMilestoneDashboardSkylineSuccess: {
            const openAqvdsPerWeek = action.payload.map((week) => ({
                week: week.weekStartDate,
                count: _.sumBy(week.subsystems, (x) => x.aqvd),
            }));
            return {
                ...state,
                rfsuMilestoneSkyline: {
                    isLoading: false,
                    items: action.payload.map((week) => week.subsystems),
                    weeks: action.payload.map((week) => ({
                        date: moment(week.weekStartDate),
                        weekType: WeekType.Past,
                    })),
                    openAqvdsPerWeek,
                },
            };
        }
        case MilestoneDashboardActionTypes.RFSUMilestoneDashboardSkylineError: {
            return {
                ...state,
                rfsuMilestoneSkyline: {
                    ...state.rfsuMilestoneSkyline,
                    isLoading: false,
                },
            };
        }
        case MilestoneDashboardActionTypes.ScheduleSaveRequest:
        case MilestoneDashboardActionTypes.ScheduleRequest: {
            return {
                ...state,
                mcMilestoneSchedule: {
                    ...state.mcMilestoneSchedule,
                    isLoading: true,
                },
            };
        }
        case MilestoneDashboardActionTypes.ScheduleSuccess: {
            return {
                ...state,
                mcMilestoneSchedule: {
                    isLoading: false,
                    entity: action.payload,
                },
            };
        }
        case MilestoneDashboardActionTypes.ScheduleSaveError:
        case MilestoneDashboardActionTypes.ScheduleError: {
            return {
                ...state,
                mcMilestoneSchedule: {
                    ...state.mcMilestoneSchedule,
                    isLoading: false,
                },
            };
        }
        case MilestoneDashboardActionTypes.ScheduleSaveSuccess: {
            return {
                ...state,
                mcMilestoneSchedule: {
                    ...state.mcMilestoneSchedule,
                    isLoading: false,
                    entity: action.payload,
                },
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardLookaheadRequest: {
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    isLoading: true,
                },
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardLookaheadSuccess: {
            const { data, giTotals, gpTotals, grandTotals } = calculateLookaheadStateData(state, action.payload);
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    isLoading: false,
                    data,
                    giTotals,
                    gpTotals,
                    grandTotals,
                },
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardLookaheadError: {
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    isLoading: false,
                },
            };
        }
        case MilestoneDashboardActionTypes.RFSUMilestoneDashboardLookaheadRequest: {
            return {
                ...state,
                rfsulookahead: {
                    ...state.rfsulookahead,
                    isLoading: true,
                },
            };
        }
        case MilestoneDashboardActionTypes.RFSUMilestoneDashboardLookaheadSuccess: {
            const { data, giTotals, gpTotals, grandTotals } = calculateLookaheadStateData(state, action.payload);
            return {
                ...state,
                rfsulookahead: {
                    ...state.rfsulookahead,
                    isLoading: false,
                    data,
                    giTotals,
                    gpTotals,
                    grandTotals,
                },
            };
        }
        case MilestoneDashboardActionTypes.RFSUMilestoneDashboardLookaheadError: {
            return {
                ...state,
                rfsulookahead: {
                    ...state.rfsulookahead,
                    isLoading: false,
                },
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardSubsystemTableRequest: {
            return {
                ...state,
                isSubsystemTableLoading: true,
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardSubsystemTableSuccess: {
            return {
                ...state,
                isSubsystemTableLoading: false,
                subsystemTable: action.payload,
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardSubsystemTableError: {
            return {
                ...state,
                isSubsystemTableLoading: false,
            };
        }
        case MilestoneDashboardActionTypes.MCMilestoneDashboardResetCharts: {
            return {
                ...state,
                subsystemTable: null,
                mcMilestoneSkyline: new DashboardSkylineState(),
                mcMilestoneSchedule: new MCScheduleState(),
                lookahead: {
                    ...new PlanningLookaheadState(),
                    lookaheadType: 'mc',
                    data: null,
                },
                comments: [],
            };
        }
        default:
            return state;
    }
}

const calculateLookaheadStateData = (state: MilestoneDashboardState, payload: PlanningLookaheadDTO[]) => {
    const currentWeekStartDate = getCurrentWeekStartDate();
    const data = _.chain(_.groupBy(payload, (item) => `${item.projectTeamName}--${item.forecastHeader}`))
        .map((value, key) => {
            const counters = state.lookahead.weeks.map((week) => {
                const planCount = _.filter(value, (v) => withinWorkWeek(v.plan, moment(week.date))).length;
                const actualCount = _.filter(value, (v) => withinWorkWeek(v.actual, moment(week.date))).length;
                const forecastCount = _.filter(value, (v) => withinWorkWeek(v.forecast, moment(week.date))).length;

                const result: PlanningLookaheadCounters = {
                    planCount: planCount,
                    actualCount: actualCount,
                    forecastCount: forecastCount,
                    weekType: moment(week.date).isBefore(currentWeekStartDate, 'days') ? WeekType.Past : WeekType.Upcoming,
                };
                return result;
            });

            const splittedKey = key.split('--');
            const result: PlanningLookaheadReportDataItem = {
                projectTeamName: splittedKey[0],
                forecastHeader: splittedKey[1],
                countersByWeek: counters,
            };
            return result;
        })
        .value();

    const gpTotals = calculateTotal(Constants.projectTeamNames.GP, data);
    const giTotals = calculateTotal(Constants.projectTeamNames.GI, data);

    const grandTotals = _.zipWith(gpTotals, giTotals, (v1, v2) => {
        return {
            planTotal: v1.planTotal + v2.planTotal,
            actualTotal: v1.actualTotal + v2.actualTotal,
            forecastTotal: v1.forecastTotal + v2.forecastTotal,
            weekType: v1.weekType,
        };
    });

    return {
        data,
        gpTotals,
        giTotals,
        grandTotals,
    };
};
