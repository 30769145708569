import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { PliDataUploadDeletePagination } from './model';

export enum PliDataUploadActionTypes {
    PliDataUploadTemplateFileRequest = '[PliDataUpload] Template File Request',
    PliDataUploadTemplateFileSuccess = '[PliDataUpload] Template File Success',
    PliDataUploadTemplateFileError = '[PliDataUpload] Template File Error',
    PliDataUploadLogRequest = '[PliDataUpload] Upload Log Request',
    PliDataUploadLogSuccess = '[PliDataUpload] Upload Log Success',
    PliDataUploadLogError = '[PliDataUpload] Upload Log Error',
    PliDataUploadDeleteRecordsRequest = '[PliDataUpload] Delete Records Request',
    PliDataUploadDeleteRecordsSuccess = '[PliDataUpload] Delete Records Success',
    PliDataUploadDeleteRecordsError = '[PliDataUpload] Delete Records Error',
    PliDataUploadSetStatusAndStartDate = '[PliDataUpload] Set Status and Start Date',
    PliDataUploadDeleteFilterPropertyUpdate = '[PliDataUpload] Delete Filter Property Update',
    PliDataUploadDeleteFilterReset = '[PliDataUpload] Delete Filter Reset',
    PliDataUploadPatchDeleteRecordRequest = '[PliDataUpload] Patch Delete Record Request',
    PliDataUploadPatchDeleteRecordSuccess = '[PliDataUpload] Patch Delete Record Success',
    PliDataUploadPatchDeleteRecordError = '[PliDataUpload] Patch Delete Record Error',
    PliDataUploadPatchAllDeleteRecordsRequest = '[PliDataUpload] Patch All Delete Records Request',
    PliDataUploadPatchAllDeleteRecordsSuccess = '[PliDataUpload] Patch All Delete Records Success',
    PliDataUploadPatchAllDeleteRecordsError = '[PliDataUpload] Patch All Delete Records Error',
    PliDataUploadValidateDataRequest = '[PliDataUpload] Validate Data Request',
    PliDataUploadValidateDataSuccess = '[PliDataUpload] Validate Data Success',
    PliDataUploadValidateDataError = '[PliDataUpload] Validate Data Error',
    PliDataUploadFileUploaded = '[PliDataUpload] File Uploaded',
    PliDataUploadClearInProgressSpinner = '[PliDataUpload] Clear In Progress Spinner',
    PliDataUploadDownloadOutputDataRequest = '[PliDataUpload] Download Output Data Request',
    PliDataUploadDownloadOutputDataSuccess = '[PliDataUpload] Download Output Data Success',
    PliDataUploadDownloadOutputDataError = '[PliDataUpload] Download Output Data Error',
    PliDataUploadValidateButtonStateRequest = '[PliDataUpload] Validate Button State Request',
    PliDataUploadValidateButtonStateSuccess = '[PliDataUpload] Validate Button State Success',
    PliDataUploadValidateButtonStateError = '[PliDataUpload] Validate Button State Error',
    PliDataUploadAddStartedLog = '[PliDataUpload] Add Started Log',
}

export class PliDataUploadTemplateFileRequest implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadTemplateFileRequest;

    constructor(public payload: string) {}
}

export class PliDataUploadTemplateFileSuccess implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class PliDataUploadTemplateFileError implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadTemplateFileError;

    constructor(public payload: string) {}
}

export class PliDataUploadAddStartedLog implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadAddStartedLog;
}

export class PliDataUploadLogRequest implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadLogRequest;
}

export class PliDataUploadLogSuccess implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class PliDataUploadLogError implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadLogError;

    constructor(public payload: string) {}
}

export class PliDataUploadDeleteRequest implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadDeleteRecordsRequest;
}

export class PliDataUploadDeleteSuccess implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadDeleteRecordsSuccess;

    constructor(public payload: PliDataUploadDeletePagination) {}
}

export class PliDataUploadDeleteError implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadDeleteRecordsError;

    constructor(public payload: string) {}
}

export class PliDataUploadSetStatusAndStartDate implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class PliDataUploadDeleteFilterPropertyUpdate implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class PliDataUploadDeleteFilterReset implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadDeleteFilterReset;
}

export class PliDataUploadPatchDeleteRequest implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadPatchDeleteRecordRequest;

    constructor(public payload: { itrpliNumber: string; deleteState: boolean }) {}
}

export class PliDataUploadPatchAllDeleteRequest implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class PliDataUploadPatchDeleteSuccess implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadPatchDeleteRecordSuccess;
}

export class PliDataUploadPatchAllDeleteSuccess implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class PliDataUploadPatchDeleteError implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class PliDataUploadPatchAllDeleteError implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class PliDataUploadValidateDeleteRequest implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadValidateDataRequest;
}

export class PliDataUploadValidateDeleteSuccess implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadValidateDataSuccess;
}

export class PliDataUploadValidateDeleteError implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadValidateDataError;

    constructor(public payload: string) {}
}

export class PliDataUploadFileUploaded implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadFileUploaded;
}

export class PliDataUploadClearInProgressSpinner implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadClearInProgressSpinner;
}


export class PliDataUploadDownloadOutputDataRequest implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class PliDataUploadDownloadOutputDataSuccess implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class PliDataUploadDownloadOutputDataError implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class PliDataUploadValidateButtonStateRequest implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadValidateButtonStateRequest;
}

export class PliDataUploadValidateButtonStateSuccess implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class PliDataUploadValidateButtonStateError implements Action {
    readonly type = PliDataUploadActionTypes.PliDataUploadValidateButtonStateError;

    constructor(public payload: string) {}
}

export type PliDataUploadActions =
    | PliDataUploadTemplateFileRequest
    | PliDataUploadTemplateFileSuccess
    | PliDataUploadTemplateFileError
    | PliDataUploadLogRequest
    | PliDataUploadLogSuccess
    | PliDataUploadLogError
    | PliDataUploadDeleteRequest
    | PliDataUploadDeleteSuccess
    | PliDataUploadDeleteError
    | PliDataUploadSetStatusAndStartDate
    | PliDataUploadDeleteFilterPropertyUpdate
    | PliDataUploadDeleteFilterReset
    | PliDataUploadPatchDeleteRequest
    | PliDataUploadPatchDeleteSuccess
    | PliDataUploadPatchDeleteError
    | PliDataUploadPatchAllDeleteRequest
    | PliDataUploadPatchAllDeleteSuccess
    | PliDataUploadPatchAllDeleteError
    | PliDataUploadValidateDeleteRequest
    | PliDataUploadValidateDeleteSuccess
    | PliDataUploadValidateDeleteError
    | PliDataUploadFileUploaded
    | PliDataUploadClearInProgressSpinner
    | PliDataUploadDownloadOutputDataRequest
    | PliDataUploadDownloadOutputDataSuccess
    | PliDataUploadDownloadOutputDataError
    | PliDataUploadValidateButtonStateRequest
    | PliDataUploadValidateButtonStateSuccess
    | PliDataUploadValidateButtonStateError
    | PliDataUploadAddStartedLog;
