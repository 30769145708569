import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import {
    PlanningCommitmentReliabilitySetType,
    PlanningCommitmentReliabilityRequest,
} from 'src/app/store/reports/planning/actions';

@Component({
    selector: 'app-reports-planning-commitment-reliability-content',
    templateUrl: './reports-planning-commitment-reliability-content.component.html',
    styleUrls: ['./reports-planning-commitment-reliability-content.component.scss'],
})
export class ReportsPlanningCommitmentReliabilityContentComponent implements OnInit {
    commitmentReliabilityType = '';

    isLoading$ = this.store.select((store) => store.planningState.commitmentReliability.isLoading);

    constructor(private activatedRoute: ActivatedRoute, private store: Store<ApplicationState>) {}

    ngOnInit() {
        this.commitmentReliabilityType = this.activatedRoute.routeConfig.path;
        this.store.dispatch(new PlanningCommitmentReliabilitySetType(this.commitmentReliabilityType));
        this.store.dispatch(new PlanningCommitmentReliabilityRequest());
    }
}
