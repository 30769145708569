import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';

export interface CWPRegisterState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deltaData: CWPRegisterDeltaPagination;
    isDeltaDataLoading: boolean;
    deleteData: CWPRegisterDeletePagination;
    isDeleteDataLoading: boolean;
    deltaFilter: CWPRegisterDeltaFilter;
    deleteFilter: CWPRegisterDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class CWPRegisterDeltaDTO {
    cwp: string;
    description: string;
    projectTeamNameLive: string;
    projectTeamNameImport: string;
    deltaState: boolean;
}

export class CWPRegisterDeleteDTO {
    cwp: string;
    description: string;
    deleteState: boolean;
    projectTeamName: string;
}

export class CWPRegisterDeltaFilter {
    page = 0;
    take = 10;
}

export class CWPRegisterDeleteFilter {
    page = 0;
    take = 10;
}

export class CWPRegisterDeltaPagination {
    items: CWPRegisterDeltaDTO[] = [];
    totalCount = 0;
}

export class CWPRegisterDeletePagination {
    items: CWPRegisterDeleteDTO[] = [];
    totalCount = 0;
}
