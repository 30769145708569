<div class="skyline-legend">
    <div class="legend-row plan" *ngIf="skylineType === 'forecast' && skylinePhase !== 'citr' && skylinePhase !== 'rfo'">
        <div class="legend-row-title">Subsystems Forecast:</div>
        <div class="legend-item-group">
            <div>
                <span class="legend-plan--progress">&nbsp;</span>
                {{ skylinePhase === 'rfsu' || skylinePhase === 'rfsuwalkdown' ? 'B-ITR & C-ITR' : 'QVD' }} Completion %
            </div>
            <div >
                <span class="legend-plan--mcwdcompl">&nbsp;</span>
                <span>RFSU Walkdown Completed </span>
            </div>
            <!-- <div *ngIf="skylinePhase !== 'rfsu' && skylinePhase !== 'rfsuwalkdown'">
                <span class="legend-plan--wdcompl">&nbsp;</span>
                <span>MC Walkdown completed </span>
            </div> -->
            <div>
                <span class="legend-plan--mccompl">&nbsp;</span>
                {{ skylinePhase === 'rfsu' || skylinePhase === 'rfsuwalkdown' ? 'RFSU' : 'MC' }} Completed
            </div>
            <div *ngIf="skylinePhase === 'rfsu'">
                <span *ngIf="(flagToggles$ | async)?.showMcIncomplete">
                    <span class="legend-item--mcincompl">&nbsp;</span>
                    MC Incomplete
                </span>
            </div>
        </div>

    </div>
    <div *ngIf="(skylineType === 'plan' || skylineType === 'commitment') && skylinePhase !== 'rfsu' && skylinePhase !== 'rfo' && skylinePhase !== 'rfsuwalkdown' && skylinePhase !== 'citr'">
        <div class="legend-row">
            <div class="legend-row-title">Subsystems on Plan:</div>
            <div class="legend-item-group">
                <div>
                    <span class="legend-item--progress">&nbsp;</span>
                    QVD Completion %
                </div>
                <div *ngIf="skylinePhase !== 'rfsu' && skylinePhase !== 'rfsuwalkdown'">
                    <span class="legend-item--wdcompl">&nbsp;</span>
                    <span>MC Walkdown completed </span>
                </div>
                <div>
                    <span class="legend-item--mccompl">&nbsp;</span>
                    MC Completed
                </div>
            </div>
        </div>
        <div class="legend-row" *ngIf="skylineType === 'plan' || skylineType === 'commitment'">
            <div class="legend-row-title">Subsystem late vs. Plan:</div>
            <div class="legend-item-group">
                <div>
                    <span class="legend-item--progress late">&nbsp;</span>
                    QVD Completion %
                </div>
                <div *ngIf="skylinePhase !== 'rfsu' && skylinePhase !== 'rfsuwalkdown'">
                    <span class="legend-item--wdcompl late">&nbsp;</span>
                    <span>MC Walkdown completed </span>
                </div>
                <div>
                    <span class="legend-item--mccompl late">&nbsp;</span>
                    MC Completed
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            (skylineType === 'plan' ) &&
            (skylinePhase === 'rfsu' || skylinePhase === 'rfsuwalkdown')
        "
    >
        <div class="legend-row">
            <div class="legend-item-group rfsu">
                <div>
                    <span class="legend-item--rfsuwldcompl">&nbsp;</span>
                    Walkdown Completed
                </div>
                <div>
                    <span class="legend-item--subsyslate">subsystem</span>
                    Subsystem late
                </div>
                <div>
                    <span *ngIf="(flagToggles$ | async)?.showMcIncomplete">
                        <span class="legend-item--mcincompl">&nbsp;</span>
                        MC Incomplete
                    </span>
                </div>
                <div>
                    <span *ngIf="(flagToggles$ | async)?.showRfsuEdossierInitiated">
                        <span class="legend-item--edosssier">&nbsp;</span>
                        e-Dossier Initited
                    </span>
                </div>                
            </div>
        </div>
        <div class="legend-row">
            <div class="legend-item-group rfsu">
                <div>
                    <span class="legend-item--progress">&nbsp;</span>
                    B&C ITR Completion %
                </div>
                <div>
                    <span class="legend-item--onplan">subsystem</span>
                    {{ 'Subsystem on plan' }}
                </div>
                <div>
                    <span class="legend-item--rfsucompl">subsystem</span>
                    RFSU Completed
                </div>
                <div>
                    <span *ngIf="(flagToggles$ | async)?.showOpsAppPending">
                        <span class="legend-item--opsapppend">&nbsp;</span>
                        Ops Approval Pending
                    </span>
                </div>
            </div>
        </div>
        <div class="legend-row">
            <div class="legend-item-group rfsu">
                <div>
                    <span *ngIf="(flagToggles$ | async)?.showWalkdownCommitted">
                        <span class="legend-item--wdcommitted">&nbsp;</span>
                        Walkdown Committed
                    </span>
                </div>
                <div>
                    <span *ngIf="(flagToggles$ | async)?.showNonCommissionable">
                        <span class="legend-item--noncommissionable">&nbsp;</span>
                        Non Commissionable
                    </span>
                </div>
                <div>

                </div>
                <div>
                    
                </div>
            </div>
        </div>
    </div>
    <div
    *ngIf="
        (skylineType === 'commitment' || skylineType === 'forecast') &&
        (skylinePhase === 'rfsu')
    "
>
    <div class="legend-row">
        <div class="legend-item-group rfsu">
            <div *ngIf="skylineType != 'forecast'">
                <span class="legend-item--rfsuwldcompl">&nbsp;</span>
                Walkdown Completed
            </div>
            <div *ngIf="skylineType != 'forecast'">
                <span class="legend-item--subsyslate">subsystem</span>
                Subsystem late
            </div>
            <div *ngIf="skylineType != 'forecast'">
                <span class="legend-item--progress">&nbsp;</span>
                B&C ITR Completion %
            </div>
            <div *ngIf="skylineType != 'forecast'">
                <span *ngIf="(flagToggles$ | async)?.showMcIncomplete">
                    <span class="legend-item--mcincompl">&nbsp;</span>
                    MC Incomplete
                </span>
            </div>
             
        </div>
    </div>
    <div class="legend-row">
        <div class="legend-item-group rfsu">
            <div *ngIf="skylineType != 'forecast'">
                <span class="legend-item--rfsucompl">subsystem</span>
                RFSU Completed on Time
            </div>
            <div *ngIf="skylineType != 'forecast'">
                <span class="legend-item--rfsucomplearly">subsystem</span>
                RFSU Completed Before Commitment
            </div>
            <div *ngIf="skylineType != 'forecast'">
                <span class="legend-item--rfsucompllate">subsystem</span>
                RFSU Completed After Commitment
            </div>
            <div>

            </div>
        </div>
    </div>
    <div class="legend-row">
        <div class="legend-item-group rfsu">
            <div>
                <span *ngIf="(flagToggles$ | async)?.showOpsAppPending">
                    <span class="legend-item--opsapppend">&nbsp;</span>
                    Ops Approval Pending
                </span>
            </div>
            <div>
                <span *ngIf="(flagToggles$ | async)?.showWalkdownCommitted">
                    <span class="legend-item--wdcommitted">&nbsp;</span>
                    Walkdown Committed
                </span>
            </div>
            <div>
                <span *ngIf="(flagToggles$ | async)?.showNonCommissionable">
                    <span class="legend-item--noncommissionable">&nbsp;</span>
                    Non Commissionable
                </span>
            </div>
            <div>

            </div>
        </div>
    </div>
    </div>
    <div *ngIf="(skylineType === 'forecast'  && skylinePhase === 'citr')">
        <div class="legend-row">
            <div class="legend-item-group rfsu">
                <div>
                    <span class="legend-item--citrincompl">&nbsp;</span>
                    C-ITR Not Started
                </div>
                <div>
                    <span class="legend-plan--progress">&nbsp;</span>
                    C-ITR Completion %
                </div>
                <div>
                    <span class="legend-plan--mccompl">&nbsp;</span>
                    C-ITR Completed
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="((skylineType === 'forecast' || skylineType === 'plan') && skylinePhase === 'rfo')">
        <div class="legend-row">
            <div class="legend-item-group">
                <div>
                    <span class="legend-item--onplan">RFO</span>
                    {{ 'RFO on plan' }}
                </div>
                <div>
                    <span class="legend-item--progress">&nbsp;</span>
                    RFO Completion %
                </div>
                <div>
                    <span class="legend-item--rfsucompl">RFO</span>
                    RFO Completed
                </div>
            </div>
        </div>
    </div>
</div>
