import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { WeeklyPlanType } from 'src/app/enums';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { ApplicationState } from 'src/app/store/model';
import {
    ScheduleActivityFillFilterPropertiesBasedonAreaRequest,
    ScheduleActivityPlanningFilterPropertyUpdate,
} from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import { ScheduleActivityPlanningFilter } from 'src/app/store/weekly-planning/schedule-activity-planning/model';

@Component({
    selector: 'app-lookahead-work-area-filter',
    templateUrl: './lookahead-work-area-filter.component.html',
    styleUrls: ['./lookahead-work-area-filter.component.scss'],
})
export class LookaheadWorkAreaFilterComponent extends BaseComponent implements OnInit {
    @Input() planType: WeeklyPlanType;
    @Input() filterForm: UntypedFormGroup;
    @Input() displayPeriodDays: number;
    @Input() printMode?: boolean = false;
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    @ViewChild('picker') picker;

    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;

    setWorkAreaInput = new EventEmitter<SetInputEventArgs>();
    setSubsystemInput = new EventEmitter<SetInputEventArgs>();

    isLoading = true;
    isWorkAreaSet = false;

    weeklyPlanTypes = WeeklyPlanType;

    constructor(
        private store: Store<ApplicationState>,
        private scheduleActivityLookupService: ScheduleActivityLookupService
    ) {
        super();
    }

    ngOnInit(): void {
        this.filterForm.controls.workAreas.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((val) => {
            this.store.dispatch(
                new ScheduleActivityPlanningFilterPropertyUpdate({
                    filter: {
                        ...this.filterForm.value,
                        workAreas: val,
                        subsystems: [],
                        goocs: [],
                        rfos: [],
                    } as ScheduleActivityPlanningFilter,
                })
            );

            if (!Boolean(val.length)) {
                this.setSubsystemInput.emit(new SetInputEventArgs(false, null, []));
                this.setWorkAreaInput.emit(new SetInputEventArgs(false, ''));
                this.isWorkAreaSet = false;
                this.resetWorkAreaFilters();
            } else {
                this.isWorkAreaSet = true;
                this.store.dispatch(
                    new ScheduleActivityFillFilterPropertiesBasedonAreaRequest({ areaName: val[0].workAreaName })
                );
            }
        });

    }

    resetWorkAreaFilters() {
        this.filterForm.controls.nbDisciplineLeads.setValue([]);
        this.filterForm.controls.nbEngineers.setValue([]);
        this.filterForm.controls.tcoAreaCoordinators.setValue([]);
        this.filterForm.controls.disciplines.setValue([]);
    }

    searchAreas = (search = '', takeCount = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchWorkAreas(search, takeCount, page, this.filterForm.value);
    };

    get tcoAreaCoordinators() {
        return this.filterForm?.value?.tcoAreaCoordinators?.map((l) => l.name) ?? [];
    }

    get nbDisciplineLeads() {
        return this.filterForm?.value?.nbDisciplineLeads?.map((l) => l.name) ?? [];
    }

    get nbEngineers() {
        return this.filterForm?.value?.nbEngineers?.map((l) => l.name) ?? [];
    }
}

