import { Injectable } from '@angular/core';
import {
    HealthFilter,
    HealthCleanlinessDTO,
    OverdueDPLIFilter,
    OverdueExceptionsFilters,
    ExceptionStatusDTO,
    OverdueExceptionsByPriorityFilters,
    OverdueExceptionsByPriorityDTO,
    ActivitiesConstraintsFilter,
    ActivityConstrainsReportDTO,
    SubsystemExceptionsDTO,
    ITRConstrainsReportDTO,
} from 'src/app/store/reports/health/model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { BaseResultDTO } from 'src/app/store/common.model';
import { DetailedStatusDTO } from 'src/app/store/details/model';

@Injectable({
    providedIn: 'root',
})
export class HealthService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly healthEndpoint: string = '/health';

    constructor(private http: HttpClient) {}

    filterHealthCleanlinessData(type: string, filter: HealthFilter) {
        return this.http.post<HealthCleanlinessDTO[]>(
            `${this.rootUrl}${this.healthEndpoint}/cleanliness/${type}`,
            filter
        );
    }

    filterOverdueDPLITableData(filter: OverdueDPLIFilter) {
        return this.http.post<BaseResultDTO<DetailedStatusDTO>>(
            `${this.rootUrl}${this.healthEndpoint}/overduedpli/table`,
            filter
        );
    }

    generateOverdueDPLIExcel(filter: OverdueDPLIFilter) {
        return this.http.post<BaseResultDTO<DetailedStatusDTO>>(
            `${this.rootUrl}${this.healthEndpoint}/overduedpli/generateexcel`,
            filter,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Disposition',
                }),
                responseType: 'blob' as 'json',
            }
        );
    }

    filterOverdueDPLIChartData(filter: OverdueDPLIFilter) {
        return this.http.post<{ name: string; value: number }[]>(
            `${this.rootUrl}${this.healthEndpoint}/overduedpli/chart`,
            filter
        );
    }

    filterOverdueExceptionsTableData(filter: OverdueExceptionsFilters) {
        return this.http.post<BaseResultDTO<ExceptionStatusDTO>>(
            `${this.rootUrl}${this.healthEndpoint}/overdueexceptions/table`,
            filter
        );
    }

    filterOverdueExceptionsChartData(filter: OverdueExceptionsFilters) {
        return this.http.post<{ name: string; value: number }[]>(
            `${this.rootUrl}${this.healthEndpoint}/overdueexceptions/chart`,
            filter
        );
    }

    generateOverdueExceptionsExcel(filter: OverdueExceptionsFilters) {
        return this.http.post<BaseResultDTO<ExceptionStatusDTO>>(
            `${this.rootUrl}${this.healthEndpoint}/overdueexceptions/generateexcel`,
            filter,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Disposition',
                }),
                responseType: 'blob' as 'json',
            }
        );
    }

    filterOverdueExceptionsByPriorityChartData(filter: OverdueExceptionsByPriorityFilters) {
        return this.http.post<OverdueExceptionsByPriorityDTO[]>(
            `${this.rootUrl}${this.healthEndpoint}/overdueexceptionsbypriority`,
            filter
        );
    }

    filterOverdueExceptionsByPriorityTableData(filter: OverdueExceptionsByPriorityFilters) {
        return this.http.post<BaseResultDTO<ExceptionStatusDTO>>(
            `${this.rootUrl}${this.healthEndpoint}/overdueexceptionsbypriority/table`,
            filter
        );
    }

    filterOverdueExceptionsBySubsystemChartData(filter: OverdueExceptionsByPriorityFilters) {
        return this.http.post<OverdueExceptionsByPriorityDTO[]>(
            `${this.rootUrl}${this.healthEndpoint}/overdueexceptionsbypriority/subsystemscount`,
            filter
        );
    }

    filterOverdueExceptionsBySubsystemTableData(filter: OverdueExceptionsByPriorityFilters) {
        return this.http.post<BaseResultDTO<SubsystemExceptionsDTO>>(
            `${this.rootUrl}${this.healthEndpoint}/overdueexceptionsbypriority/subsystemstable`,
            filter
        );
    }

    generateOverdueExceptionsByPriorityExcel(filter: OverdueExceptionsByPriorityFilters) {
        return this.http.post<BaseResultDTO<ExceptionStatusDTO>>(
            `${this.rootUrl}${this.healthEndpoint}/overdueexceptionsbypriority/generateexcel`,
            filter,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Disposition',
                }),
                responseType: 'blob' as 'json',
            }
        );
    }

    filterActivitiesConstraintsChartData(filter: ActivitiesConstraintsFilter) {
        let requestFilter = {
            ...filter,
            workAreas: filter.workAreas.map(x => typeof x === 'string' ? x : x['id'] ).filter(x => x != null || x != undefined)
        } as ActivitiesConstraintsFilter;
        return this.http.post<ActivityConstrainsReportDTO[]>(
            `${this.rootUrl}${this.healthEndpoint}/activitiesconstraints`,
            requestFilter
        );
    }

    filterITRsConstraintsChartData(filter: ActivitiesConstraintsFilter) {
        let requestFilter = {
            ...filter,
            workAreas: filter.workAreas.map(x => typeof x === 'string' ? x : x['id'] ).filter(x => x != null || x != undefined)
        } as ActivitiesConstraintsFilter;
        return this.http.post<ITRConstrainsReportDTO[]>(
            `${this.rootUrl}${this.healthEndpoint}/itrconstraints`,
            requestFilter
        );
    }
}
