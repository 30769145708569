import { IWPListingState } from './model';
import * as moment from 'moment';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { IWPListingActions, IWPListingActionTypes } from './actions';

const initialState: IWPListingState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: IWPListingActions) {
    switch (action.type) {
        case IWPListingActionTypes.IWPListingUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case IWPListingActionTypes.IWPListingUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case IWPListingActionTypes.IWPListingUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case IWPListingActionTypes.IWPListingSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case IWPListingActionTypes.IWPListingFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case IWPListingActionTypes.IWPListingClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case IWPListingActionTypes.IWPListingDownloadOutputDataRequest:
        case IWPListingActionTypes.IWPListingTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case IWPListingActionTypes.IWPListingTemplateFileError:
        case IWPListingActionTypes.IWPListingDownloadOutputDataSuccess:
        case IWPListingActionTypes.IWPListingDownloadOutputDataError:
        case IWPListingActionTypes.IWPListingTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case IWPListingActionTypes.IWPListingValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case IWPListingActionTypes.IWPListingAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'IWP';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        case IWPListingActionTypes.IWPListingValidateDeltaRequest: {
            return {
                ...state,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case IWPListingActionTypes.IWPListingValidateDeltaSuccess: {
            return {
                ...state,
            };
        }
        case IWPListingActionTypes.IWPListingValidateDeltaError: {
            return {
                ...state,
            };
        }
        default:
            return state;
    }
}
