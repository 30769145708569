import { Injectable } from '@angular/core';
import { RFOFilter, RFODataPagination } from 'src/app/store/RFO/model';
import * as moment from 'moment';
import { HttpClient ,HttpHeaders } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class RFOService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly rfoEndpoint: string = '/rfo';

    constructor(private http: HttpClient) {}

    getRFOData(filter: RFOFilter) {
        return this.http.post<RFODataPagination>(`${this.rootUrl}${this.rfoEndpoint}`, filter);
    }

    updateRFOForecastDate(RFOId: string, RFOForecastDate: moment.Moment) {
        return this.http.patch(
            `${this.rootUrl}${this.rfoEndpoint}/${RFOId}${
                RFOForecastDate != null ? '/' + RFOForecastDate.toJSON() : ''
            }`,
            {}
        );
    }
    updateRFOFreezeDate(RFOId: string, RFOFreezeDate: moment.Moment) {
        return this.http.patch(
            `${this.rootUrl}${this.rfoEndpoint}/rfoFreeze/${RFOId}${
                RFOFreezeDate != null ? '/' + RFOFreezeDate.toJSON() : ''
            }`,
            {}
        );
    }

    generateExcel(filter: RFOFilter) {
        return this.http.post(`${this.rootUrl}${this.rfoEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }
}
