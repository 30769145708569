<div class="chart-container" #chart>
    <mat-expansion-panel #chartPanel>
        <mat-expansion-panel-header></mat-expansion-panel-header>
        <ngx-charts-bar-vertical
            [view]="[900, 300]"
            [results]="chart$ | async"
            [gradient]="false"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showXAxisLabel]="false"
            [showYAxisLabel]="false"
            [yAxisTickFormatting]="yAxisTickFormatting"
            barPadding="150"
            [scheme]="colorScheme"
            [customColors]="customScheme"
            [animations]="false"            
            (select)="onSelect($event)"
        >
        </ngx-charts-bar-vertical>
        <div class="ovud-legend">
            <div>Overdue</div>
            <div></div>
        </div>
    </mat-expansion-panel>
</div>