import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    SkylineActionTypes,
    SkylineSuccess,
    SkylineError,
    SkylineRequest,
    SkylineGetExcelRequestSuccess,
    SkylineGetExcelRequest,
    SkylineGetExcelRequestError,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SkylineService } from 'src/app/services/api/webapi-services/reports/skyline.service';
import { of } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { SkylineFilter, SkylineLevelOfDetails, SKYLINE_API_CALL_DATE_FORMAT } from './model';

@Injectable()
export class SkylineEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private skylineService: SkylineService,
        private toastService: ToastService
    ) {}

    @Effect()
    skylinePlanRequest$ = this.actions$.pipe(
        ofType<SkylineRequest>(SkylineActionTypes.SkylineRequest),
        withLatestFrom(
            this.store.select((store) => _.head(store.skylineState.weeks)),
            this.store.select((store) => _.last(store.skylineState.weeks)),
            this.store.select((store) => store.skylineState.skylinePhase),
            this.store.select((store) => store.skylineState.skylineType),
            this.store.select((store) => store.skylineState.filter)
        ),
        mergeMap(([action, startDate, endDate, phase, type, filter]) => {
            const requestFilter = {
                ...filter,
                startDate: moment(startDate.date).format(SKYLINE_API_CALL_DATE_FORMAT),
                endDate: moment(endDate.date).add(6, 'days').format(SKYLINE_API_CALL_DATE_FORMAT)
            } as SkylineFilter;
            return this.skylineService.filterSkylinePlanData(requestFilter, phase, type, action.payload.printMode).pipe(
                map(
                    (subsystems) =>
                        new SkylineSuccess({
                            data: subsystems,
                            datePropertyName: `${phase}${type.charAt(0).toUpperCase()}${type.slice(1)}`,
                            printMode: action.payload.printMode,
                        })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting ${
                            phase === 'mcwalkdown' ? 'MC Walkdown' : 'rfsuwalkdown' ? 'RFSU Walkdown' : 'citr' ? 'CITR Skyline' : phase.toUpperCase()
                        } Skyline ${type.charAt(0).toUpperCase()}${type.slice(
                            1
                        )} data. Please contact Program Administrator.`
                    );
                    return of(new SkylineError(error));
                })
            );
        })
    );

    @Effect()
    requestOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(SkylineActionTypes.SkylineSetWeekRange),
        map(() => new SkylineRequest({ printMode: false }))
    );

    @Effect()
    requestOnDetailsLevelChanged$ = this.actions$.pipe(
        ofType(SkylineActionTypes.SkylineSetLevelOfDetails),
        map(() => new SkylineRequest({ printMode: false }))
    );

    @Effect()
    getSkylineExcel$ = this.actions$.pipe(
        ofType<SkylineGetExcelRequest>(SkylineActionTypes.SkylineGetExcelRequest),
        withLatestFrom(
            this.store.select((store) => store.skylineState.skylinePhase),
            this.store.select((store) => store.skylineState.skylineType),
            this.store.select((store) => store.skylineState.filter),
            this.store.select((store) => store.skylineState.levelOfDetails)
        ),
        mergeMap(([action, skylinePhase, skylineType, filter, levelOfDetails]) => {
            var isDetailedView = levelOfDetails === SkylineLevelOfDetails.High;
            if(skylinePhase == "citr"){
                isDetailedView = true;
            }
            return this.skylineService.getSkylineExcel(skylinePhase, skylineType, isDetailedView, filter).pipe(
                map(
                    (bytes: any) =>
                        new SkylineGetExcelRequestSuccess({ data: bytes, phase: skylinePhase, type: skylineType })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occured during generating Excel file. Please contact Program Administrator.'
                    );
                    return of(new SkylineGetExcelRequestError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveSkylineExcel$ = this.actions$.pipe(
        ofType<SkylineGetExcelRequestSuccess>(SkylineActionTypes.SkylineGetExcelRequestSuccess),
        map((action) => {
            const blob = new Blob([action.payload.data], {
                type: 'application/octet-stream',
            });
            saveAs(blob, `Skyline ${action.payload.phase.toUpperCase()} ${action.payload.type.toUpperCase()}.xlsx`);
        })
    );
}
