import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { DcnRfiRedlineRegisterService } from 'src/app/services/api/webapi-services/dcn-rfi-redline-register.service';

@Component({
  selector: 'app-redline-filter',
  templateUrl: './redline-filter.component.html',
  styleUrls: ['./redline-filter.component.scss']
})
export class RedlineFilterComponent extends BaseComponent implements OnInit {
  @Input() filterForm: FormGroup;

  setRedlineNumbersSelectorValue = new EventEmitter<SetInputEventArgs>();
  setRedlineSubsystemSelectorValue = new EventEmitter<SetInputEventArgs>();
  setDcnRfiNumbersSelectorValue = new EventEmitter<SetInputEventArgs>();
  setDcnRfiTitlesSelectorValue = new EventEmitter<SetInputEventArgs>();
  setRedlineTitlesSelectorValue = new EventEmitter<SetInputEventArgs>();
  setRedlineWorkAreaSelectorValue = new EventEmitter<SetInputEventArgs>();
  setRedlineRequiredForSelectorValue = new EventEmitter<SetInputEventArgs>();
  setNbResponsibleEngineersSelectorValue = new EventEmitter<SetInputEventArgs>();
  
  redlineDisciplines: string[] = [];
  rlmuStatuses: string[] = [];
  redlineStatuses: string[] = [];
  redlineRequiredFors: string[] = [];
  
  constructor(
    private dcnRfiRedlineRegisterService: DcnRfiRedlineRegisterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.dcnRfiRedlineRegisterService
      .getDisciplines()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((redlineDisciplines: string[]) => (this.redlineDisciplines = redlineDisciplines));
    this.dcnRfiRedlineRegisterService
      .getRedlineRlmuStatuses()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((rlmuStatuses: string[]) => (this.rlmuStatuses = rlmuStatuses));
      this.dcnRfiRedlineRegisterService
      .getRedlineStatuses()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((redlineStatuses: string[]) => (this.redlineStatuses = redlineStatuses));
      this.dcnRfiRedlineRegisterService
      .getRedlineRequiredFor()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((redlineRequiredFors: string[]) => (this.redlineRequiredFors = redlineRequiredFors));
  }

  searchRedlineNumbers = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchRedlineNumbers(search, takeCount, page);
  };

  searchRedlineSubsystems = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchRedlineSubsystems(search, takeCount, page);
  };

  searchDcnRfiNumbers = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchDcnRfiNumbers(search, takeCount, page);
  };

  searchRfiNumbers = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchRfiNumbers(search, takeCount, page);
  };

  searchRedlineTitles = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchRedlineTitles(search, takeCount, page);
  };

  searchRedlineWorkArea = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchWorkArea(search, takeCount, page);
  };

  searchRedlineNbEngineers = (search = '', takeCount = 10, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchRedlineNbEngineers(search, takeCount, page);
  };
}
