import { createReducer, on } from '@ngrx/store';
import { StatusUpdateState } from './model';
import * as StatusUpdateActions from './actions';

const initialState: StatusUpdateState = {
    logs: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isDataReadyToProcess: false,
    isAutomaticUploadInProgress: true,
};

export const statusUpdateReducer = createReducer(
    initialState,
    on(StatusUpdateActions.statusUpdateLogRequest, (state) => ({
        ...state,
        isLoading: true,
    })),
    on(StatusUpdateActions.statusUpdateLogSuccess, (state, action) => ({
        ...state,
        logs: action.response,
        isLoading: false,
    })),
    on(StatusUpdateActions.statusUpdateLogError, (state) => ({
        ...state,
        isLoading: false,
    })),
    on(StatusUpdateActions.statusUpdateClearInProgressSpinner, (state) => ({
        ...state,
        isImportInProgress: false,
    }))
);