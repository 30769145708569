import { Action } from "@ngrx/store";
import { ExpectedTimeofCompletionDTO, ManualPriority } from "./model";

export enum CriticalRFIManualPriorityHistoryActionTypes{
    CriticalRFIManualPriorityHistoryRequest = '[Critical RFI] Manual Priority History Request',
    CriticalRFIManualPriorityHistorySuccess = '[Critical RFI] Manual Priority History Success',
    CriticalRFIManualPriorityHistoryError = '[Critical RFI] Manual Priority History Error',
    CriticalRFIExpectedTimeofCompletionHistoryRequest = '[CriticalRFI] Expected Time of Completion History Request',
    CriticalRFIExpectedTimeofCompletionHistorySuccess = '[CriticalRFI] Expected Time of Completion History Success',
    CriticalRFIExpectedTimeofCompletionHistoryError = '[CriticalRFI] Expected Time of Completion History Error',
}

export class CriticalRFIManualPriorityHistoryRequest implements Action {
    readonly type = CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIManualPriorityHistoryRequest;

    constructor(public payload: { id: number }) {}
}

export class CriticalRFIManualPriorityHistorySuccess implements Action {
    readonly type = CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIManualPriorityHistorySuccess;

    constructor(public payload: ManualPriority[]) {}
}

export class CriticalRFIManualPriorityHistoryError implements Action {
    readonly type = CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIManualPriorityHistoryError;

    constructor(public payload: string) {}
}


export class CriticalRFIExpectedTimeofCompletionHistoryRequest implements Action {
    readonly type = CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIExpectedTimeofCompletionHistoryRequest;

    constructor(public payload: { id: number }) {}
}

export class CriticalRFIExpectedTimeofCompletionHistorySuccess implements Action {
    readonly type = CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIExpectedTimeofCompletionHistorySuccess;

    constructor(public payload: ExpectedTimeofCompletionDTO[]) {}
}

export class CriticalRFIExpectedTimeofCompletionHistoryError implements Action {
    readonly type = CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIExpectedTimeofCompletionHistoryError;

    constructor(public payload: string) {}
}
export type ManualPriorityActions = 
    | CriticalRFIExpectedTimeofCompletionHistoryRequest
    | CriticalRFIExpectedTimeofCompletionHistorySuccess
    | CriticalRFIExpectedTimeofCompletionHistoryError
    | CriticalRFIManualPriorityHistoryRequest
    | CriticalRFIManualPriorityHistorySuccess
    | CriticalRFIManualPriorityHistoryError;