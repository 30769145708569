import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { Observable } from 'rxjs';
import {
    MCWeeklyPlanningDTO,
    RFSUWeeklyPlanningDTO,
    CommitmentType,
    WeeklyPlanningFilter,
    HeaderFilterFetchColumn,
    WeeklyPlanningTableHeaderFilter,
    RFOWeeklyPlanningDTO,
    ShowHideColumns,
} from 'src/app/store/weekly-planning/model';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import {
    WeeklyPlanningFilterPropertyUpdate,
    WeeklyPlanningDataRequest,
    WeeklyPlanningCommitRequest,
    WeeklyPlanningDeleteCommitmentRequest,
    WeeklyPlanningFilterRequest,
} from 'src/app/store/weekly-planning/actions';
import { OrderDirection, CalendarColumn, CheckListColumn, UserDetail } from 'src/app/store/common.model';
import { takeWhile, take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { WeeklyPlanningService } from 'src/app/services/api/webapi-services/weekly-planning.service';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { Zone } from 'src/app/enums';
import { CommentsHistoryComponent } from 'src/app/components/comments-history/comments-history.component';
import { CommentDetailDTO } from 'src/app/store/detailed-status/model';
import { BlueZoneAddBulkCommentRequest, BlueZoneAddCommentRequest, BlueZoneRemoveCommentRequest } from 'src/app/store/bluezone/actions';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CommentPopupComponent } from 'src/app/modules/shared/components/comment-popup/comment-popup.component';

@Component({
    selector: 'app-weekly-planning-session-table',
    templateUrl: './weekly-planning-session-table.component.html',
    styleUrls: ['./weekly-planning-session-table.component.scss'],
})
export class WeeklyPlanningSessionTableComponent extends BaseComponent implements OnInit {
    [x: string]: any;
    @Input() planningType: string;
    @Input() planningTableIndex: number;
    @Input() tableHeader: string;

    data$: Observable<MCWeeklyPlanningDTO[] | RFSUWeeklyPlanningDTO[] | RFOWeeklyPlanningDTO[]>;
    isLoading$: Observable<boolean>;
    totalCount$: Observable<number>;
    sortBy$: Observable<string>;
    sortBy: string;
    direction$: Observable<OrderDirection>;
    take$: Observable<number>;
    direction: OrderDirection;
    showHideColumns = new ShowHideColumns();
    expanded = false;
    tableHeaderFilter$: Observable<WeeklyPlanningTableHeaderFilter>;
    tableHeaderFilter: WeeklyPlanningTableHeaderFilter;
    columnFilterValueBeforeReset: CheckListColumn = null;
    isRFSUCommitAllowed = false;

    pageSize;
    mcPlanningDisplayedColumns = [
        'subsystem',
        'subsystemName',
        'mcPlan',
        'mcForecast',
        'walkdownForecast',
        'walkdownActual',
        'aqvd',
        'apli',
        'npw',
        'redline',
        'comments',
        'actions',
    ];
    rfsuPlanningDisplayedColumns = [
        'subsystem',
        'subsystemName',
        'rfsuPlan',
        'rfsuForecast',
        'mcActual',
        'walkdownForecast',
        'walkdownActual',
        'edossierInitiation',
        'bitr',
        'citr',
        'aqvd',
        'apli',
        'bpli',
        'cpli',
        'npw',
        'redline',
        'comments',
        'actions',
    ];
    rfoPlanningDisplayedColumns = [
        'rfo',
        'rfoName',
        'rfoPlan',
        'rfoForecast',
        'bitr',
        'citr',
        'aqvd',
        'apli',
        'bpli',
        'cpli',
        'npw',
        'redline',
        'rfocomments',
        'actions',
    ];
    displayedColumns = this.mcPlanningDisplayedColumns;
    commitmentTypes = CommitmentType;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private store: Store<ApplicationState>,
        private weeklyPlanningService: WeeklyPlanningService,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private commentService: CommentService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private router: Router
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
    }

    ngOnInit(): void {

        this.isRFSUCommitAllowed = this.roleService.isInRole(Constants.applicableGroups.RFSUCommitButton) || this.roleService.isInRole(Constants.applicableGroups.Admin);

        switch (this.commitmentTypes[this.planningType.toUpperCase()]) {
            case CommitmentType.RFSU:
                this.displayedColumns = this.rfsuPlanningDisplayedColumns;
                break;
            case CommitmentType.RFO:
                this.displayedColumns = this.rfoPlanningDisplayedColumns;
                break;
            default:
                this.displayedColumns = this.mcPlanningDisplayedColumns;
                break;
        }
        this.data$ = this.store.select(
            (store) => store.weeklyPlanningState[this.planningType.toLowerCase()][this.planningTableIndex].items
        );
        this.isLoading$ = this.store.select(
            (store) => store.weeklyPlanningState[this.planningType.toLowerCase()][this.planningTableIndex].isLoading
        );
        this.totalCount$ = this.store.select(
            (store) => store.weeklyPlanningState[this.planningType.toLowerCase()][this.planningTableIndex].totalCount
        );
        this.sortBy$ = this.store.select(
            (store) => store.weeklyPlanningState[this.planningType.toLowerCase()][this.planningTableIndex].sortBy
        );
        this.direction$ = this.store.select(
            (store) => store.weeklyPlanningState[this.planningType.toLowerCase()][this.planningTableIndex].direction
        );
        this.take$ = this.store.select(
            (store) => store.weeklyPlanningState[this.planningType.toLowerCase()][this.planningTableIndex].take
        );

        this.tableHeaderFilter$ = this.store.select(
            (store) =>
                store.weeklyPlanningState[this.planningType.toLowerCase()][this.planningTableIndex].tableHeaderFilter
        );

        this.expanded$ = this.store.select(
            (store) => store.weeklyPlanningState[this.planningType.toLowerCase()][this.planningTableIndex].expanded
        );

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new WeeklyPlanningFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                    planningType: this.planningType,
                    planningTableIndex: this.planningTableIndex,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(
                new WeeklyPlanningDataRequest({
                    planningType: this.planningType,
                    planningTableIndex: this.planningTableIndex,
                })
            );
        });

        this.tableHeaderFilter$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.tableHeaderFilter = items));

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => (this.sortBy = sortBy));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => (this.direction = direction));
        this.take$.pipe(takeWhile(() => this.isAlive)).subscribe((take) => (this.pageSize = take));
        this.expanded$.pipe(takeWhile(() => this.isAlive)).subscribe((expanded) => (this.expanded = expanded));
    }

    openGroup(expanded: boolean) {
        this.expanded = expanded;
        this.store.dispatch(
            new WeeklyPlanningFilterPropertyUpdate({
                key: 'expanded',
                value: expanded,
                planningType: this.planningType,
                planningTableIndex: this.planningTableIndex,
            })
        );
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new WeeklyPlanningFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                    planningType: this.planningType,
                    planningTableIndex: this.planningTableIndex,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new WeeklyPlanningFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                    planningType: this.planningType,
                    planningTableIndex: this.planningTableIndex,
                })
            );
        }
        this.store.dispatch(
            new WeeklyPlanningDataRequest({
                planningType: this.planningType,
                planningTableIndex: this.planningTableIndex,
            })
        );
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    commit(subsystem: string) {
        this.store.dispatch(
            new WeeklyPlanningCommitRequest({
                subsystem,
                planningType: this.planningType,
                planningTableIndex: this.planningTableIndex,
            })
        );
    }

    deleteCommitment(subsystem: string) {
        this.store.dispatch(
            new WeeklyPlanningDeleteCommitmentRequest({
                subsystem,
                planningType: this.planningType,
                planningTableIndex: this.planningTableIndex,
            })
        );
    }

    private getLatestFilterData(): WeeklyPlanningFilter {
        let filter: WeeklyPlanningFilter;
        this.store.pipe(select((x) => x.weeklyPlanningState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new WeeklyPlanningFilterPropertyUpdate({
                    key: 'sortBy',
                    value: {
                        active: columnName,
                        direction,
                    },
                    planningType: this.planningType,
                    planningTableIndex: this.planningTableIndex,
                })
            );
        } else {
            this.store.dispatch(
                new WeeklyPlanningFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'subsystem', direction: OrderDirection.Desc },
                    planningType: this.planningType,
                    planningTableIndex: this.planningTableIndex,
                })
            );
        }
    }

    getSubsystems = (search = '', take = 30, page = 0) => {
        return this.weeklyPlanningService.getPlanningHeaderFilterData(
            this.planningType,
            this.planningTableIndex,
            this.getLatestFilterData(),
            page,
            take,
            'Subsystem',
            OrderDirection.Asc,
            this.tableHeaderFilter,
            HeaderFilterFetchColumn.Subsystems,
            search
        );
    };

    getRFOs = (search = '', take = 30, page = 0) => {
        return this.weeklyPlanningService.getPlanningHeaderFilterData(
            this.planningType,
            this.planningTableIndex,
            this.getLatestFilterData(),
            page,
            take,
            'RFO',
            OrderDirection.Asc,
            this.tableHeaderFilter,
            HeaderFilterFetchColumn.RFOs,
            search
        );
    };

    getSubsystemNames = (search = '', take = 30, page = 0) => {
        return this.weeklyPlanningService.getPlanningHeaderFilterData(
            this.planningType,
            this.planningTableIndex,
            this.getLatestFilterData(),
            page,
            take,
            'SubsystemName',
            OrderDirection.Asc,
            this.tableHeaderFilter,
            HeaderFilterFetchColumn.SubsystemNames,
            search
        );
    };

    getRFONames = (search = '', take = 30, page = 0) => {
        return this.weeklyPlanningService.getPlanningHeaderFilterData(
            this.planningType,
            this.planningTableIndex,
            this.getLatestFilterData(),
            page,
            take,
            'RFOName',
            OrderDirection.Asc,
            this.tableHeaderFilter,
            HeaderFilterFetchColumn.RFONames,
            search
        );
    };

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 620, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];

                    this.store.dispatch(
                        new WeeklyPlanningFilterPropertyUpdate({
                            key: propertyName,
                            value,
                            planningType: this.planningType,
                            planningTableIndex: this.planningTableIndex,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.store.dispatch(new WeeklyPlanningFilterRequest([this.planningTableIndex]));
                },
                resetColumnAction: () => {
                    this.columnFilterValueBeforeReset = this.tableHeaderFilter[propertyName];
                    this.store.dispatch(
                        new WeeklyPlanningFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                            planningType: this.planningType,
                            planningTableIndex: this.planningTableIndex,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new WeeklyPlanningFilterPropertyUpdate({
                            key: propertyName,
                            value: this.columnFilterValueBeforeReset,
                            planningType: this.planningType,
                            planningTableIndex: this.planningTableIndex,
                        })
                    );
                    this.columnFilterValueBeforeReset = null;
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.store.dispatch(
                        new WeeklyPlanningFilterPropertyUpdate({
                            key: propertyName,
                            value: data.calendarColumn,
                            planningType: this.planningType,
                            planningTableIndex: this.planningTableIndex,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.store.dispatch(new WeeklyPlanningFilterRequest([this.planningTableIndex]));
                },
            },
            345,
            580)
        );
    }

    reverseValue(columnName: string) {
        this.showHideColumns[columnName] = !this.showHideColumns[columnName];
    }

    redirectToZone(subsystem: string) {
        if (this.planningType.toLocaleLowerCase() == 'rfsu') {
            var url = this.router.createUrlTree(['bluezone'], {
                queryParams: { subsystem, discipline: 'all', showDisciplineBreakdown: false },
            });
            window.open(window.location.origin + url.toString(), '_blank');
        } else {
            var url = this.router.createUrlTree(['redzone'], { queryParams: { subsystem, discipline: 'all' } });
            window.open(window.location.origin + url.toString(), '_blank');
        }
    }

    openHistoryPopup(element: RFSUWeeklyPlanningDTO) {
        if (element.comments === null) {
            return;
        } 
        else {
            this.isLoading = this.store.select(
                (store) => store.weeklyPlanningState[this.planningType.toLowerCase()][this.planningTableIndex].isLoading);
            this.commentService
                .getCommentsBySubsystem(element.subsystem,Zone.BlueNoDiscipline)
                .subscribe((data) => {
                    this.isLoading = false;
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            CommentsHistoryComponent,
                            null,
                            null,
                            {
                                commentHist: data,
                                action: (id: number) => {
                                    data = data.filter((s) => s.id !== id);
                                    this.store.dispatch(
                                        new BlueZoneRemoveCommentRequest({
                                            id,
                                            lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                            entityId: element.subsystem,
                                        })
                                    );
                                    this.search()
                                },
                            },
                            200,
                            200
                        )
                    );
                });
        }
    }

    openCommentPopup(element: RFSUWeeklyPlanningDTO, event: any) {
        const comment = element.comments || '';
        if (this.isReadOnly) return;

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: element.mentions,
                url: `bluezone?subsystem=${element.subsystem}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new BlueZoneAddBulkCommentRequest({
                                description: value,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new BlueZoneAddCommentRequest({
                                entityId: element.subsystem,
                                description: value,
                                mentions,
                            })
                        );
                        this.search();
                    }
                },
            })
        );
        event.stopPropagation();
    }

    search() {
        //this.updateFilterByFormProperties();

        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new WeeklyPlanningFilterPropertyUpdate({
                key: 'page',
                value: 0,
                planningType : this.planningType,
            })
        );
        this.store.dispatch(new WeeklyPlanningFilterRequest([this.planningTableIndex]));
    }
}
