import { NgModule } from '@angular/core';
import { StoreModule as RxjsStoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducer as changeValidationDashboardReducer } from './reports/change-validation-dashboard/reducer';
import { userReducer as userReducer } from './user/reducer';
import { reducer as detailedStatusReducer } from './detailed-status/reducer';
import { reducer as detailsReducer } from './details/reducer';
import { reducer as blueZoneReducer } from './bluezone/reducer';
import { reducer as rfoReducer } from './RFO/reducer';
import { reducer as changeManagementReducer } from './change-management/reducer';
import { reducer as planningDataReducer } from './planning-upload/planning-data/reducer';
import { reducer as scheduleActivityReducer } from './planning-upload/schedule-activity/reducer';
import { reducer as activityBarcodeReducer } from './planning-upload/activity-barcode/reducer';
import { reducer as exceptionsPriorityReducer } from './planning-upload/exceptions-priority/reducer';
import { reducer as citrPartialProgressReducer } from './planning-upload/citr-partial-progress/reducer';
import { reducer as itrManhoursReducer } from './planning-upload/itr-manhours/reducer';
import { reducer as itrConstraintsReducer } from './planning-upload/itr-constraints/reducer';
import { reducer as flangeManagementReducer } from './manual-upload/flange-management/reducer';
import { reducer as redlineReducer } from './manual-upload/redline/reducer';
import { reducer as manualUploadReducer } from './manual-upload/reducer';
import { reducer as palnningUploadReducer } from './planning-upload/reducer';
import { reducer as changeDocumentDetailsReducer } from './change-document-details/reducer';
import { reducer as cwpRegisterReducer } from './manual-upload/cwp-register/reducer';
import { reducer as iwpListingReducer } from './manual-upload/iwp-listing/reducer';
import { reducer as rfoImportReducer } from './manual-upload/rfo/reducer';
import { reducer as exceptionsReducer } from './automatic-upload/exceptions/reducer';
import { reducer as imdbReducer } from './automatic-upload/imdb/reducer';
import { reducer as fieldChangeDocumentSummaryReducer } from './field-change-summary/reducer';
import { reducer as masterSubsystemListReducer } from './manual-upload/master-subsystem-list/reducer';
import { reducer as certificateReducer } from './automatic-upload/certificate/reducer';
import { reducer as tagsReducer } from './automatic-upload/tags/reducer';
import { reducer as PLIReducer } from './automatic-upload/pli/reducer';
import { reducer as loopReducer } from './automatic-upload/loop/reducer';
import { reducer as ITRReducer } from './automatic-upload/itr/reducer';
import { reducer as weeklyPlanningReducer } from './weekly-planning/reducer';
import { reducer as changeValidationReducer } from './change-validation/reducer';
import { reducer as validationFormReducer } from './validation-form/reducer';
import { reducer as contractorMaintenanceReducer } from './data-maintenance/team-data/contractor/reducer';
import { reducer as projectTeamRoleMaintenanceReducer } from './data-maintenance/team-data/projectTeamRole/reducer';
import { reducer as subsystemMaintenanceReducer } from './data-maintenance/subsystem-data/reducer';
import { reducer as lookupReducer } from './lookup/reducer';
import { reducer as skylineReducer } from './reports/skyline/reducer';
import { reducer as changeImpactedTagsReducer } from './change-management-upload-dcn/reducer';
import { reducer as planningReducer } from './reports/planning/reducer';
import { reducer as healthReducer } from './reports/health/reducer';
import { reducer as icicleReducer } from './reports/icicle/reducer';
import { reducer as rundownReducer } from './reports/rundown/reducer';
import { reducer as tagSearchReducer } from './tag-search/reducer';
import { reducer as milestoneDashboardReducer } from './reports/milestone-dashboard/reducer';
import { reducer as goocComparisonDashboardReducer } from './reports/gooc/reducer';
import { reducer as subsystemMCDashboardReducer } from './reports/subsystem-mc/reducer';
import { reducer as myPageReducer } from './my-page/reducer';
import { reducer as ExportPageReducer } from './export-page/reducer';
import { reducer as systemizedDrawingsReducer } from './manual-upload/systemized-drawings/reducer';
import { reducer as cowListReducer } from './manual-upload/carry-over-work/reducer';
import { reducer as loopStatusReducer } from './loop-status/reducer';
import { reducer as systemisedDrawingReducer } from './systemised-drawing/reducer';
import { reducer as preMCReducer } from './reports/pre-mc/reducer';
import { reducer as joditReducer } from './jodit-description/reducer';
import { reducer as ctaRegisterReducer } from './cta-register/reducer';
import { reducer as reportsDashboardReducer } from './reports/dashboard/reducer';
import { reducer as certificateSearchReducer } from './certificate/reducer';
import { reducer as homeReducer } from './home/reducer';
import { reducer as subsystemRFSUDashboardReducer } from './reports/subsystem-rfsu/reducer';
import { reducer as scheduledActivityPlanningReducer} from "./weekly-planning/schedule-activity-planning/reducer"
import { reducer as forecastHistoryReducer} from "./forecast-history/reducer";
import { reducer as fieldImplementationContractorHistoryReducer} from "./field-implementation-contractor-history/reducer";
import { reducer as scheduleActivityITRReducer} from "./schedule-activity-itr/reducer";
import { reducer as uxReducer } from './ux/reducer';
import { reducer as traceHeatingStatusReducer } from './trace-heating-status/reducer';
import { excelExportReducer } from './excel-export/reducer';
import { reducer as planningConfigReducer } from './planning-configuration/reducer';
import { reducer as dcnRfiRedlineRegisterReducer } from './dcn-rfi-redline/reducer';
import { reducer as PLIPlanningReducer } from './pli-planning/reducer';
import { reducer as PASCRStatusReducer } from './pascr-status/reducer';
import { reducer as exceptionsUploadReducer } from './planning-upload/exceptions-upload/reducer';
import { reducer as PliDataUploadReducer } from './planning-upload/pli-data-upload/reducer';
import { reducer as ITRCommentUploadReducer } from './planning-upload/itr-comment-upload/reducer';
import { reducer as RFODatesUploadReducer } from './planning-upload/rfo-dates-upload/reducer';
import { reducer as itrConstraintsContactsReducer } from './planning-upload/itr-constraints-contacts/reducer';
import { RedZoneEffects } from './redzone/effects';
import { DetailedStatusEffects } from './detailed-status/effects';
import { DetailsEffects } from './details/effects';
import { BlueZoneEffects } from './bluezone/effects';
import { ChangeManagementEffects } from './change-management/effects';
import { FlangeManagementEffects } from './manual-upload/flange-management/effects';
import { RedlineEffects } from './manual-upload/redline/effects';
import { ManualUploadEffects } from './manual-upload/effects';
import { PlanningUploadEffects } from './planning-upload/effects';
import { ChangeDocumentDetailsEffects } from './change-document-details/effects';
import { CWPRegisterEffects } from './manual-upload/cwp-register/effects';
import { IWPListingEffects } from './manual-upload/iwp-listing/effects';
import { RFOImportEffects } from './manual-upload/rfo/effects';
import { ExceptionsEffects } from './automatic-upload/exceptions/effects';
import { IMDBEffects } from './automatic-upload/imdb/effects';
import { FieldChangeDocumentSummaryEffects } from './field-change-summary/effects';
import { MasterSubsystemListEffects } from './manual-upload/master-subsystem-list/effects';
import { CertificateEffects } from './automatic-upload/certificate/effects';
import { TagsEffects } from './automatic-upload/tags/effects';
import { PLIEffects } from './automatic-upload/pli/effects';
import { ITREffects } from './automatic-upload/itr/effects';
import { WeeklyPlanningEffects } from './weekly-planning/effects';
import { ValidationFormEffects } from './validation-form/effect';
import { ChangeValidationEffects } from './change-validation/effects';
import { ContractorMaintenanceEffects } from './data-maintenance/team-data/contractor/effects';
import { ProjectTeamRoleMaintenanceEffects } from './data-maintenance/team-data/projectTeamRole/effects';
import { SubsystemMaintenanceEffects } from './data-maintenance/subsystem-data/effects';
import { SkylineEffects } from './reports/skyline/effects';
import { ChangeImpactedTagsEffects } from './change-management-upload-dcn/effects';
import { PlanningEffects } from './reports/planning/effects';
import { RundownEffects } from './reports/rundown/effects';
import { HealthEffects } from './reports/health/effects';
import { IcicleEffects } from './reports/icicle/effects';
import { TagSearchEffects } from './tag-search/effects';
import { ChangeValidationDashboardEffects } from './reports/change-validation-dashboard/effects';
import { MilestoneDashboardEffects } from './reports/milestone-dashboard/effects';
import { RFOEffects } from './RFO/effects';
import { SubystemConflictsEffects } from './subsystem-conflicts/effects';
import { reducer as subsystemConflictsReducer } from './subsystem-conflicts/reducer';
import { GOOCComparisonDashboardEffects } from './reports/gooc/effects';
import { MyPageEffects } from './my-page/effects';
import { ExportPageEffects } from './export-page/effects';
import { SystemizedDrawingsEffects } from './manual-upload/systemized-drawings/effects';
import { COWListEffects } from './manual-upload/carry-over-work/effects';
import { SubsystemMCDashboardEffects } from './reports/subsystem-mc/effects';
import { LoopEffects } from './automatic-upload/loop/effects';
import { LoopStatusEffects } from './loop-status/effects';
import { SystemisedDrawingEffects } from './systemised-drawing/effects';
import { PreMCEffects } from './reports/pre-mc/effects';
import { CTARegisterEffects } from './cta-register/effects';
import { ReportsDashboardEffects } from './reports/dashboard/effects';
import { CertificateSearchEffects } from './certificate/effects';
import { HomeEffects } from './home/effects';
import { SubsystemRFSUDashboardEffects } from './reports/subsystem-rfsu/effects';
import { ScheduleActivityEffects } from './planning-upload/schedule-activity/effects';
import { ActivityBarcodeEffects } from './planning-upload/activity-barcode/effects';
import { ExceptionsPriorityEffects } from './planning-upload/exceptions-priority/effects';
import { ITRManhoursEffects } from './planning-upload/itr-manhours/effects';
import { ITRConstraintsEffects } from './planning-upload/itr-constraints/effects';
import { PlanningDataEffects } from './planning-upload/planning-data/effects';
import {ScheduleActivityPlanningEffects} from "./weekly-planning/schedule-activity-planning/effects";
import {ForecastHistoryEffects} from "./forecast-history/effects";
import {FieldImplementationContractorHistoryEffects} from "./field-implementation-contractor-history/effects";
import { ScheduleActivityITREffects } from './schedule-activity-itr/effects';
import { TraceHeatingStatusEffects } from './trace-heating-status/effects';
import { ExcelExportEffects } from './excel-export/effects';
import { RedZoneStoreModule } from './redzone/module';
import { EffectsModule } from '@ngrx/effects';
import { PlanningConfigurationEffects } from './planning-configuration/effects';
import { DcnRfiRedlineRegisterEffects } from './dcn-rfi-redline/effects';
import { pipelineReducer } from './automatic-upload/pipeline/reducer';
import { PipelineEffects } from './automatic-upload/pipeline/effects';
import { PLIPlanningEffects } from './pli-planning/effects';
import { ImportLogsEffects } from './import-logs/effects';
import { importLogsReducer } from './import-logs/reducer';
import { UserEffects } from './user/effects';
import { PascrStatusEffects } from './pascr-status/effects';
import { statusUpdateReducer } from './automatic-upload/status-update/reducer';
import { StatusUpdateEffects } from './automatic-upload/status-update/effects';
import { ITRConstraintsContactsEffects } from './planning-upload/itr-constraints-contacts/effects';
import { CITRPartialProgressEffects } from './planning-upload/citr-partial-progress/effects';
import { reducer as CriticalPathReducer } from './planning-upload/critical-path/reducer';
import { CriticalPathEffects } from './planning-upload/critical-path/effects';
import { ExceptionsUploadEffects } from './planning-upload/exceptions-upload/effects';
import { PliDataUploadEffects } from './planning-upload/pli-data-upload/effects';
import { ITRCommentUploadEffects } from './planning-upload/itr-comment-upload/effects';
import { RFODatesUploadEffects } from './planning-upload/rfo-dates-upload/effects';
import { PriorityNumbersEffects } from './automatic-upload/priority-numbers/effects';
import { reducer as PriorityNumbersReducer } from './automatic-upload/priority-numbers/reducer';
import {reducer as criticalRfiReducer} from './critical-rfi/reducer';
import {CriticalRfiEffects} from './critical-rfi/effects';
import { reducer as leakTestPackTrackerSearchReducer } from './leak-test-pack-tracker/reducer';
import {reducer as leakTestPackPriorityHistoryReducer} from './ltp-priority-history/reducer';
import { LeakTestPackTrackerSearchEffects } from './leak-test-pack-tracker/effects';
import {reducer as ManualPriorityHistoryReducer} from './manual-priority-history/reducer';
import { ManualPriorityHistoryEffects } from './manual-priority-history/effects';
import { LeakTestPackPriorityHistoryEffects } from './ltp-priority-history/effects';
import {reducer as DataUpdateHistoryReducer} from './data-update-history/reducer'
import { DataUpdateHistoryEffects } from './data-update-history/effects';
@NgModule({
    declarations: [],
    imports: [
        RxjsStoreModule.forRoot({
            userState: userReducer,
            blueZoneState: blueZoneReducer,
            rfoState: rfoReducer,
            detailedStatusState: detailedStatusReducer,
            detailsState: detailsReducer,
            changeManagementState: changeManagementReducer,
            subsystemConflictsState: subsystemConflictsReducer,
            planningDataState: planningDataReducer,
            scheduleActivityState: scheduleActivityReducer,
            activityBarcodeState: activityBarcodeReducer,
            exceptionsPriorityState: exceptionsPriorityReducer,
            citrPartialProgressState: citrPartialProgressReducer,
            itrManhoursState: itrManhoursReducer,
            itrConstraintsState: itrConstraintsReducer,
            itrConstraintsContactsState:itrConstraintsContactsReducer,
            manualUploadState: manualUploadReducer,
            planningUploadState: palnningUploadReducer,
            changeDocumentDetailsState: changeDocumentDetailsReducer,
            cwpRegisterState: cwpRegisterReducer,
            iwpListingState: iwpListingReducer,
            rfoImportState: rfoImportReducer,
            exceptionsState: exceptionsReducer,
            fieldChangeDocumentSummaryState: fieldChangeDocumentSummaryReducer,
            masterSubsystemListState: masterSubsystemListReducer,
            certificateState: certificateReducer,
            pliState: PLIReducer,
            itrState: ITRReducer,
            tagsState: tagsReducer,
            pipelineState: pipelineReducer,
            statusUpdateState: statusUpdateReducer,
            weeklyPlanningState: weeklyPlanningReducer,
            changeValidationState: changeValidationReducer,
            validationFormState: validationFormReducer,
            contractorMaintenanceState: contractorMaintenanceReducer,
            projectTeamRoleMaintenanceState: projectTeamRoleMaintenanceReducer,
            subsystemMaintenanceState: subsystemMaintenanceReducer,
            lookupState: lookupReducer,
            skylineState: skylineReducer,
            changeImpactedTagsState: changeImpactedTagsReducer,
            planningState: planningReducer,
            healthState: healthReducer,
            icicleState: icicleReducer,
            rundownState: rundownReducer,
            tagSearchState: tagSearchReducer,
            changeValidationDashboardState: changeValidationDashboardReducer,
            milestoneDashboardState: milestoneDashboardReducer,
            imdbState: imdbReducer,
            goocComparisonDashboardState: goocComparisonDashboardReducer,
            myPageState: myPageReducer,
            ExportPageState: ExportPageReducer,
            systemizedDrawingsState: systemizedDrawingsReducer,
            subsystemMCDashboardState: subsystemMCDashboardReducer,
            loopState: loopReducer,
            loopStatusState: loopStatusReducer,
            systemisedDrawingState: systemisedDrawingReducer,
            cowListState: cowListReducer,
            preMCState: preMCReducer,
            joditDescriptionState: joditReducer,
            ctaRegisterState: ctaRegisterReducer,
            reportsDashboardState: reportsDashboardReducer,
            certificateSearchState: certificateSearchReducer,
            flangeManagementState: flangeManagementReducer,
            redlineState: redlineReducer,
            homeState: homeReducer,
            subsystemRFSUDashboardState: subsystemRFSUDashboardReducer,
            scheduleActivityPlanningState: scheduledActivityPlanningReducer,
            forecastHistoryState: forecastHistoryReducer,
            fieldImplementationContractorHistoryState : fieldImplementationContractorHistoryReducer,
            scheduleActivityITRState: scheduleActivityITRReducer,
            uxState: uxReducer,
            traceHeatingStatusState: traceHeatingStatusReducer,
            excelExportState: excelExportReducer,
            planningConfigState: planningConfigReducer,
            dcnRfiRedlineRegisterState: dcnRfiRedlineRegisterReducer,
            pliPlanningState: PLIPlanningReducer,
            importLogsState: importLogsReducer,
            pascrStatusState : PASCRStatusReducer,
            criticalPathState: CriticalPathReducer,           
            exceptionsUploadState : exceptionsUploadReducer,
            priorityNumbersState: PriorityNumbersReducer,       
            criticalRfiState : criticalRfiReducer,  
            pliDataUploadState : PliDataUploadReducer,
            ITRCommentUploadState : ITRCommentUploadReducer,
            RFODatesUploadState : RFODatesUploadReducer,
            leakTestPackTrackerSearchState : leakTestPackTrackerSearchReducer,
            leakTestPackPriorityHistoryState : leakTestPackPriorityHistoryReducer,
            manualPriorityHistoryState : ManualPriorityHistoryReducer,
            dataUpdateHistoryState: DataUpdateHistoryReducer,
        }),
        RedZoneStoreModule,
        EffectsModule.forRoot([
            RedZoneEffects,
            BlueZoneEffects,
            RFOEffects,
            DetailedStatusEffects,
            DetailsEffects,
            ChangeManagementEffects,
            SubystemConflictsEffects,
            ManualUploadEffects,
            PlanningUploadEffects,
            ChangeDocumentDetailsEffects,
            CWPRegisterEffects,
            IWPListingEffects,
            RFOImportEffects,
            ExceptionsEffects,
            FieldChangeDocumentSummaryEffects,
            MasterSubsystemListEffects,
            CertificateEffects,
            PLIEffects,
            ITREffects,
            TagsEffects,
            PipelineEffects,
            StatusUpdateEffects,
            WeeklyPlanningEffects,
            ChangeValidationEffects,
            ValidationFormEffects,
            ContractorMaintenanceEffects,
            ProjectTeamRoleMaintenanceEffects,
            SubsystemMaintenanceEffects,
            SkylineEffects,
            ChangeImpactedTagsEffects,
            PlanningEffects,
            HealthEffects,
            IcicleEffects,
            RundownEffects,
            TagSearchEffects,
            ChangeValidationDashboardEffects,
            MilestoneDashboardEffects,
            IMDBEffects,
            GOOCComparisonDashboardEffects,
            MyPageEffects,
            ExportPageEffects,
            SystemizedDrawingsEffects,
            SubsystemMCDashboardEffects,
            LoopEffects,
            LoopStatusEffects,
            SystemisedDrawingEffects,
            COWListEffects,
            PreMCEffects,
            CTARegisterEffects,
            ReportsDashboardEffects,
            CertificateSearchEffects,
            FlangeManagementEffects,
            RedlineEffects,
            HomeEffects,
            SubsystemRFSUDashboardEffects,
            PlanningDataEffects,
            ScheduleActivityEffects,
            ActivityBarcodeEffects,
            ExceptionsPriorityEffects,
            CITRPartialProgressEffects,
            ITRManhoursEffects,
            ITRConstraintsEffects,
            ITRConstraintsContactsEffects,
            ScheduleActivityPlanningEffects,
            ForecastHistoryEffects,
            FieldImplementationContractorHistoryEffects,
            ScheduleActivityITREffects,
            TraceHeatingStatusEffects,
            ExcelExportEffects,
            PlanningConfigurationEffects,
            DcnRfiRedlineRegisterEffects,
            PLIPlanningEffects,
            ImportLogsEffects,
            UserEffects,
            PascrStatusEffects,
            CriticalPathEffects,
            ExceptionsUploadEffects,
            PriorityNumbersEffects,
            ExceptionsUploadEffects,
            PliDataUploadEffects,
            ITRCommentUploadEffects,
            RFODatesUploadEffects,
            CriticalRfiEffects,
            LeakTestPackTrackerSearchEffects,
            LeakTestPackPriorityHistoryEffects,
            ManualPriorityHistoryEffects,
            DataUpdateHistoryEffects,
        ]),
        StoreDevtoolsModule.instrument({ maxAge: 0 }),
    ],
})
export class StoreModule {}
