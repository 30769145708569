import { Component, OnInit, EventEmitter, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../base.component';
import { LeakTestPackTrackerSearchExportToExcelRequest, LeakTestPackTrackerSearchFilterPropertyUpdate, LeakTestPackTrackerSearchFilterRequest, LeakTestPackTrackerSearchFilterReset, LeakTestPackTrackerSearchUpdatePriorityRequest } from 'src/app/store/leak-test-pack-tracker/actions';
import * as _ from 'lodash';
import { OrderDirection, CheckListColumn, CalendarColumn, NumericColumn } from 'src/app/store/common.model';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter, HeaderNumericFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { of } from 'rxjs';
import { Constants } from 'src/app/constants';
import { combineLatest } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  LeakTestPackTrackerSearchDTO, LeakTestPackTrackerSearchFilter, ShowHideLTPColumns } from 'src/app/store/leak-test-pack-tracker/model';
import { UntypedFormGroup } from '@angular/forms';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { HeaderNumericFilterComponent } from 'src/app/modules/shared/components/filter/header-numeric-filter/header-numeric-filter.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DetailedStatusSetMakeRequest } from 'src/app/store/detailed-status/actions';
import { Zone } from 'src/app/enums';
import { MatSelectChange } from '@angular/material/select';
import { LtpPriorityHistoryComponent } from './ltp-priority-history/ltp-priority-history.component';

@Component({
  selector: 'app-leak-test-pack-tracker-search',
  templateUrl: './leak-test-pack-tracker-search.component.html',
  styleUrls: ['./leak-test-pack-tracker-search.component.scss'],
})
export class LeakTestPackTrackerSearchComponent extends BaseComponent implements OnInit {
  filterForm: UntypedFormGroup;
  isLoading = false;
  resultsLength = 0;
  pageSize = 25;
  
  data: LeakTestPackTrackerSearchDTO[] = [];
  leakTestPackTrackerSearchGridData$ = this.store.select((state) => state.leakTestPackTrackerSearchState.dataPagination);
  leakTestPackTrackerSearchGridLoader$ = this.store.select((state) => state.leakTestPackTrackerSearchState.isLoading);
  leakTestPackTrackerSearchFilter$ = this.store.select((state) => state.leakTestPackTrackerSearchState.filter);
  sortBy$ = this.store.select((state) => state.leakTestPackTrackerSearchState.filter.sortBy);
  sortDirection$ = this.store.select((state) => state.leakTestPackTrackerSearchState.filter.direction);
  columnLeakTestPackTrackerNo: CheckListColumn = null;
  showColumns = new ShowHideLTPColumns();
  columnInstrumentSubsystem: CheckListColumn = null;
  columnPriority: CheckListColumn = null;
  columnSubsystemName: CheckListColumn = null;
  columnPreCommStart: CalendarColumn = null;
  columnPreCommFinish: CalendarColumn = null;
  columnCertificateComment: CheckListColumn = null;
  columnITRComment: CheckListColumn = null;
  columnBarcode : CheckListColumn = null;
  columnBITRRemaining: NumericColumn = null;
  columnCITRRemaining: NumericColumn = null;
  columnPLIRemaining: NumericColumn = null;
  
  todayDate = new Date();
  sortBy = '';
  direction: OrderDirection = OrderDirection.Desc;
  setTagInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
  Priorities:string[] = ['P1','P2','P3','P4','P5'];
  columnsToDisplay = [
    'leakTestPackTrackerNo',
    'subsystem',
    'subsystemName',
    'preCommStart',
    'preCommFinish',
    'bitrRemaining',
    'citrRemaining',
    'pliRemaining',
    'priority',
    'barcode',
    'certificateComment',
    'itrComment'   
  ];

  numericColumns = [
    'columnBITRRemaining',
    'columnCITRRemaining',
    'columnPLIRemaining'
];
  expandedElement: LeakTestPackTrackerSearchDTO | null;
  tableTypes = Constants.LeakTestPackTrackerSearchTableType;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private formSvc: FormService,
    private store: Store<ApplicationState>,
    private changeDetectorRef: ChangeDetectorRef,
    private lookupService: LookupService,
    private toastService: ToastService,
    private popupSvc: PopupService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private router: Router,
  ) {
    super();
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
  );
  this.iconRegistry.addSvgIcon(
      'del-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
  );

    this.filterForm = this.formSvc.createSimpleForm(new LeakTestPackTrackerSearchFilter());
  }

  ngOnInit(): void {
    this.leakTestPackTrackerSearchGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
      this.data = data.items.map((item) => ({ ...item, showActivity: false }));
      this.resultsLength = data.totalCount;
    });

    this.leakTestPackTrackerSearchFilter$.pipe(take(1)).subscribe((filter) => {
      this.filterForm.patchValue(filter, { emitEvent: false });
      this.paginator.pageIndex = filter.page;
    });

    this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => {
      this.sortBy = sortBy;
    });

    this.sortDirection$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => {
      this.direction = direction;
    });

    this.leakTestPackTrackerSearchGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
      this.isLoading = isLoading;
      // hack for the Angular bug:
      // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
      this.changeDetectorRef.detectChanges();
    });

    this.registerColumnHeaderFilters();
    this.store.dispatch(new LeakTestPackTrackerSearchFilterRequest());
  }
  updatePriority(change: MatSelectChange, ltp: LeakTestPackTrackerSearchDTO){
    const ltpPriority = _.cloneDeep(ltp);
    ltpPriority.priority = change.value;
    this.store.dispatch(
        new LeakTestPackTrackerSearchUpdatePriorityRequest({
            ltp: ltpPriority              
        })
    );
}
priorityUpdateHistory(event: MouseEvent,leakTestPackTrackerNo: string) {
  event.preventDefault();
  this.popupSvc.openPopup(
      new PopupSettings(LtpPriorityHistoryComponent, 550, 500, {
        leakTestPackTrackerNo: leakTestPackTrackerNo
      })
  );
}
cloneElement(element: any): any {
  return _.cloneDeep(element);
}

  private registerColumnHeaderFilters() {
    combineLatest([
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnLeakTestPackTrackerNo),
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnInstrumentSubsystem), 
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnPriority),       
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnPreCommStart),
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnPreCommFinish),
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnBITRRemaining),
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnCITRRemaining),
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnSubsystemName),
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnPLIRemaining),
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnBarcode),
      this.store.select((state) => state.leakTestPackTrackerSearchState.filter.columnCertificateComment)
    ]).pipe(takeWhile(() => this.isAlive))
      .subscribe(([
                 leakTestPackTrackerNo, 
                 instrumentSubsystem, 
                 priority,
                 precommStart,
                 precommFinish,
                 bitrRemaining,
                 citrRemaining,
                 substemName,
                 pliRemaining,
                barcode,
                certificateComment,
                itrComment
            ]) => {
        this.columnLeakTestPackTrackerNo = leakTestPackTrackerNo as CheckListColumn;
        this.columnInstrumentSubsystem = instrumentSubsystem as CheckListColumn;
        this.columnPriority = priority as CheckListColumn;
        this.columnPreCommStart = precommStart as CalendarColumn;
        this.columnPreCommFinish = precommFinish as CalendarColumn;
          this.columnBITRRemaining = bitrRemaining;
          this.columnCITRRemaining = citrRemaining;
        this.columnSubsystemName = substemName as CheckListColumn;
        this.columnPLIRemaining = pliRemaining;
        this.columnBarcode = barcode as CheckListColumn;
        this.columnCertificateComment = certificateComment as CheckListColumn;
        this.columnITRComment = itrComment as CheckListColumn;
      });
  }

  openHeaderNumericFilter(property: string, currentState: NumericColumn, placeHolder: string, propertyName: string) {
    this.popupSvc.openPopup(
        new PopupSettings<HeaderNumericFilter>(HeaderNumericFilterComponent, 400, 350, {
            placeHolder: placeHolder,
            showBlankOptions: true,
            isAscendingSort: this.sortBy === property && this.direction == OrderDirection.Asc,
            isDescendingSort: this.sortBy === property && this.direction == OrderDirection.Desc,
            minValue: currentState ? currentState.minValue : null,
            maxValue: currentState ? currentState.maxValue : null,
            excludeBlanks: currentState ? currentState.excludeBlanks : false,
            onlyBlanks: currentState ? currentState.onlyBlanks : false,
            onSumbit: (data: HeaderNumericFilter) => {
                let newFilterState = new NumericColumn();
                newFilterState = { ...data };

                const columnName = propertyName;
                if (
                    this.numericColumns.includes(columnName) &&
                    this.filterForm.controls.hasOwnProperty(columnName)
                ) {
                    this.filterForm.controls[columnName].setValue(newFilterState);
                }
                this.sortUpdateMain(data.isAscendingSort, data.isDescendingSort, property);
        
                this.search();
            },
            onReset: () => {
              this.store.dispatch(
                new LeakTestPackTrackerSearchFilterPropertyUpdate({
                  key: propertyName,
                  value: null,
                })
              );
            },
        } as HeaderNumericFilter)
    );
}

  private getLatestFilterData(): LeakTestPackTrackerSearchFilter {
    let filter: LeakTestPackTrackerSearchFilter;
    this.store.pipe(select((x) => x.leakTestPackTrackerSearchState, take(1))).subscribe((data) => (filter = data.filter));
    return filter;
  }

  getLeakTestPackTrackerNo = (search = '', take = 30, page = 0) =>
    this.lookupService.searchLtpNumbersWithLeakTestPackTrackerSearchFilter(search, take, page, this.getLatestFilterData());
  getInstrumentSubsystem = (search = '', take = 30, page = 0) =>
    this.lookupService.searchInstrumentSubsystemWithLeakTestPackTrackerSearchFilter(search, take, page, this.getLatestFilterData());  
    getPriorities = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPriorityWithLeakTestPackTrackerSearchFilter(search, take, page, this.getLatestFilterData());
    resetFilters() {
    this.store.dispatch(new LeakTestPackTrackerSearchFilterReset());
    this.setTagInput.emit(new SetInputEventArgs(false, ''));
    this.filterForm.setValue({ ...new LeakTestPackTrackerSearchFilter() });
  }

  exportToExcel() {
    this.store.dispatch(new LeakTestPackTrackerSearchExportToExcelRequest());
  }

  clearFilterProperty(propertyName: string) {
    if (Array.isArray(this.filterForm.controls[propertyName].value)) {
      this.filterForm.controls[propertyName].setValue([]);
    } else {
      this.filterForm.controls[propertyName].setValue('');
    }
    this.store.dispatch(
      new LeakTestPackTrackerSearchFilterPropertyUpdate({
        key: propertyName,
        value: [],
      })
    );
  }

  private updateFilterByFormProperties() {
    for (const key of Object.keys(this.filterForm.controls)) {
      if (key !== 'sortBy' && key !== 'direction') {
        const value = this.filterForm.controls[key].value;
        this.store.dispatch(new LeakTestPackTrackerSearchFilterPropertyUpdate({ key, value }));
      }
    }
  }

  displayMultipleSelected(values: any[], propertyName: string) {
    return values.map((x) => x[propertyName]).join(', ');
  }

  search() {
    this.updateFilterByFormProperties();
    this.paginator.pageIndex = 0;
    this.store.dispatch(
      new LeakTestPackTrackerSearchFilterPropertyUpdate({
        key: 'page',
        value: 0,
      })
    );
    this.store.dispatch(
      new LeakTestPackTrackerSearchFilterPropertyUpdate({
        key: 'take',
        value: this.pageSize,
      })
    );
    this.store.dispatch(new LeakTestPackTrackerSearchFilterRequest());
  }

  onPaginatorChange(pageEvent: PageEvent) {
    if (this.pageSize !== pageEvent.pageSize) {
      this.store.dispatch(
        new LeakTestPackTrackerSearchFilterPropertyUpdate({
          key: 'take',
          value: pageEvent.pageSize,
        })
      );
      this.pageSize = pageEvent.pageSize;
    } else {
      this.store.dispatch(
        new LeakTestPackTrackerSearchFilterPropertyUpdate({
          key: 'page',
          value: pageEvent.pageIndex,
        })
      );
    }
    this.store.dispatch(new LeakTestPackTrackerSearchFilterRequest());
  }

  onPageChange(newPage: number) {
    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > this.paginator.getNumberOfPages()) {
      newPage = this.paginator.getNumberOfPages();
    }
    let pageEvt = new PageEvent();
    pageEvt.pageIndex = newPage - 1;
    pageEvt.pageSize = this.pageSize;
    this.paginator.pageIndex = pageEvt.pageIndex;
    this.onPaginatorChange(pageEvt);
  }
  private sortUpdateMain(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
    if (isAscendingSort || isDescendingSort) {
      const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
      this.store.dispatch(
        new LeakTestPackTrackerSearchFilterPropertyUpdate({
          key: 'sortBy',
          value: { active: columnName, direction: direction },
        })
      );
    } else {
      this.store.dispatch(
        new LeakTestPackTrackerSearchFilterPropertyUpdate({
          key: 'sortBy',
          value: { active: 'leakTestPackTrackerNo', direction: OrderDirection.Asc },
        })
      );
    }
  }

  getValues = (search = '', take = 30, page = 0, column = 'activity', allElements = []) => {
    let elements = allElements.filter(
      (s) => s[column] && s[column].toLowerCase().indexOf(search.toLowerCase()) > -1
    );
    if (elements.length) {
      let el = elements.map((s) => s[column]);
      let counts = _.countBy(el);
      let keys = _.keys(counts);
      return of(keys.slice(page * take, page * take + take));
    } else {
      return of([]);
    }
  };



  

getInstrumentSubsystemName = (search = '', take = 30, page = 0) =>
    this.lookupService.searchInstrumentSubsystemNameWithLeakTestPackTrackerSearchFilter(search, take, page, this.getLatestFilterData());  
        
getBarcodes = (search = '', take = 30, page = 0) =>
    this.lookupService.searchBarcodeWithLeakTestPackTrackerSearchFilter(search, take, page, this.getLatestFilterData());
getCertificateComment = (search = '', take = 30, page = 0) =>
   this.lookupService.searchCertificatecommentWithLeakTestPackTrackerSearchFilter(search, take, page, this.getLatestFilterData());  
getITRComments = (search = '', take = 30, page = 0) =>
   this.lookupService.searchcommentWithLeakTestPackTrackerSearchFilter(search, take, page, this.getLatestFilterData());  
  
reverseValue(columnName: string) {
    this.showColumns = {
        ...this.showColumns,
        [columnName]: !this.showColumns[columnName],
    };
    this.store.dispatch(
        new LeakTestPackTrackerSearchFilterPropertyUpdate({
            key: 'ShowHideLTPColumns',
            value: this.showColumns          
        })
    );
}


  openHeaderDateFilter(
    columnName: string,
    propertyName: string,
    placeholder: string,
    calendarColumn: CalendarColumn,
    isSortingOff = false
  ) {
    const excludeBlanks =
      calendarColumn === null || calendarColumn == undefined ? false : calendarColumn?.excludeBlanks;
    const onlyBlanks = calendarColumn === null || calendarColumn == undefined ? false : calendarColumn?.onlyBlanks;
    const rangeDates = calendarColumn === null || calendarColumn == undefined ? [] : calendarColumn?.rangeDates;
    this.popupSvc.openPopup(
      new PopupSettings<HeaderDateFilter>(
        HeaderDateFilterComponent,
        345,
        600,
        {
          isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
          isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
          calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
          placeHolder: placeholder,
          isSortingOff: isSortingOff,
          action: (data) => {
            this.filterForm.controls[propertyName].setValue(data.calendarColumn);
            this.sortUpdateMain(data.isAscendingSort, data.isDescendingSort, columnName);
            this.search();
          },
        },
        345,
        580
      )
    );
  }

  openMainHeaderCheckListFilter(
    columnName: string,
    searchFunc: any,
    propertyName: string,
    placeholder: string,
    selected: CheckListColumn,
    showCounts: boolean = false,
    showInputSearch: boolean = true,
    isSortingOff: boolean = false,
    showBlankOptions: boolean = false
  ) {
    const excludeBlanks = selected === null ? false : selected?.excludeBlanks;
    const onlyBlanks = selected === null ? false : selected?.onlyBlanks;
    this.popupSvc.openPopup(
      new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
        isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
        isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
        placeHolder: placeholder,
        searchFunc: searchFunc,
        selectedItems: selected ? [...selected.items] : [],
        excludeBlanks: excludeBlanks,
        onlyBlanks: onlyBlanks,
        isSortingOff: isSortingOff,
        showCounts,
        showInputSearch: showInputSearch,
        action: (data) => {
          let value = new CheckListColumn();
          value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
          value.excludeBlanks = data.excludeBlanks;
          value.onlyBlanks = data.onlyBlanks;
          this.filterForm.controls[propertyName].setValue(value);
          this.sortUpdateMain(data.isAscendingSort, data.isDescendingSort, columnName);
          this.search();
        },
        resetColumnAction: () => {
          this.store.dispatch(
            new LeakTestPackTrackerSearchFilterPropertyUpdate({
              key: propertyName,
              value: null,
            })
          );
        },
        cancelAction: () => {
          this.store.dispatch(
            new LeakTestPackTrackerSearchFilterPropertyUpdate({
              key: propertyName,
              value: this.filterForm.controls[propertyName]?.value,
            })
          );
        },
      })
    );
  }
  resetColumns() {
    this.showColumns = new ShowHideLTPColumns();
}

goToITRandPLI(scope:string ,event: any, ltp: LeakTestPackTrackerSearchDTO = null) {
  
  if(ltp.bitrRemaining == 0 && scope == 'BITR') 
  {
    return;
  } else if (ltp.citrRemaining == 0 && scope == 'CITR')
  {
    return;
  } else if (ltp.pliRemaining == 0 && scope == 'PLI') {
    return;
  }
  this.store.dispatch(new DetailedStatusSetMakeRequest());
  this.ngZone.run(() =>
  this.router.navigate([`/detailedstatus`], {
      queryParams: {
          scope,  
          zone: Zone.LTP,
          barcodes: ltp.barcode,
          finalpunchItems: ltp.activity,
      },
  })
);
 }  
}
