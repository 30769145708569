import { ScheduleActivityITRActions, ScheduleActivityITRActionTypes } from './actions';
import {
    ScheduleActivityITRDataPagination,
    ScheduleActivityITRFilter,
    ScheduleActivityITRScope,
    ScheduleActivityITRState,
} from './model';
import * as _ from 'lodash';

const initialState: ScheduleActivityITRState = {
    dataPagination: new ScheduleActivityITRDataPagination(),
    isLoading: false,
    scope: ScheduleActivityITRScope.ToGo,
    filter: new ScheduleActivityITRFilter(),
    weeklyPlanITRsToGo: null,
    weeklyPlanSelectedITRs: null,
    weeklyPlanOccupiedITRs: [],
};

export function reducer(state = initialState, action: ScheduleActivityITRActions): ScheduleActivityITRState {
    switch (action.type) {
        case ScheduleActivityITRActionTypes.ITRDataRequest: {
            return {
                ...state,
                isLoading: true,
                weeklyPlanITRsToGo: null,
                weeklyPlanSelectedITRs: null,
            };
        }
        case ScheduleActivityITRActionTypes.ITRDataRequestSuccess:
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        case ScheduleActivityITRActionTypes.ITRDataRequestError:
            return {
                ...state,
                isLoading: false,
            };

        case ScheduleActivityITRActionTypes.FilterPropertyUpdate: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter,
                },
            };
        }

        case ScheduleActivityITRActionTypes.ExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ScheduleActivityITRActionTypes.ExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ScheduleActivityITRActionTypes.ExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ScheduleActivityITRActionTypes.AddCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ScheduleActivityITRActionTypes.AddCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((i) => i.id.toString() === action.payload.entityId);

            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                comments: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case ScheduleActivityITRActionTypes.AddCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ScheduleActivityITRActionTypes.ITRsToGoDetailsSuccess: {
            return {
                ...state,
                isLoading: false,
                weeklyPlanITRsToGo: action.payload[0],
                weeklyPlanSelectedITRs: action.payload[1],
                weeklyPlanOccupiedITRs: action.payload[2] || [],
            };
        }
        case ScheduleActivityITRActionTypes.SaveSelectedITRIdsRequest: {
            return {
                ...state,
                isLoading: true,
                weeklyPlanITRsToGo: null,
                weeklyPlanSelectedITRs: null,
            };
        }
        case ScheduleActivityITRActionTypes.SaveSelectedITRIdsSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ScheduleActivityITRActionTypes.SaveSelectedITRIdsError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ScheduleActivityITRActionTypes.FilterPropertyReset: {
            return {
                ...state,
                filter: new ScheduleActivityITRFilter(),
            };
        }
        default:
            return state;
    }
}
