<div class="configuration-panel-container">
    <div class="container300 container-radiobuttons margin-right20">
        <form [formGroup]="uploadFileSourceConfigForm">
            <div *ngIf="showUploadSource">
                <mat-label>Upload File Source:</mat-label>
                <mat-radio-group
                    formControlName="importTypeValue"
                    (change)="importTypeChange($event)"
                    aria-label="Upload File Source"
                >
                    <mat-radio-button [value]="'0'" class="margin-right40">ftp</mat-radio-button>
                    <mat-radio-button [value]="'1'" class="margin-right40">manual</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="source-filename-container">
                <span
                    >Filenames for {{ uploadType }} Import
                    <mat-icon
                        matTooltip={{tooltipMessage}}
                        matTooltipClass="scttooltip"
                        matTooltipPosition="right"
                        >info</mat-icon
                    ></span
                >
                <div *ngIf="!isInEditMode">
                    <mat-form-field class="container580 margin-right20 editable">
                        <textarea
                            matInput
                            formControlName="sourceFilenames"
                            matTextareaAutosize
                            matAutosizeMinRows="2"
                            readonly
                        ></textarea>
                    </mat-form-field>
                </div>
                <div *ngIf="isInEditMode" class="container580 editable">
                    <mat-form-field class="container580 margin-right20 editable">
                        <textarea
                            matInput
                            formControlName="sourceFilenames"
                            matTextareaAutosize
                            matAutosizeMinRows="2"
                        ></textarea>
                    </mat-form-field>
                </div>
                <div
                    class="center-vert"
                    [ngClass]="{ 'margin-right40': !isInEditMode, 'margin-right20': isInEditMode }"
                >
                    <button *ngIf="!isInEditMode" mat-icon-button (click)="enterEditMode()" title="edit">
                        <mat-icon svgIcon="edit"></mat-icon>
                    </button>
                    <button *ngIf="isInEditMode" mat-icon-button (click)="saveSourceFileNames()" title="accept">
                        <mat-icon>check_circle_outline</mat-icon>
                    </button>
                    <button *ngIf="isInEditMode" mat-icon-button (click)="cancelEditMode()" title="cancel">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
