import { Component, Inject} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ChangeType } from 'src/app/enums';
import { DesignStatusMappingDTO } from 'src/app/models/design-status-mapping-dto';
import { DesignStatusMappingService } from 'src/app/services/api/webapi-services/design-status-mapping-service';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
  selector: 'app-add-design-status-mapping',
  templateUrl: './add-design-status-mapping.component.html',
  styleUrls: ['./add-design-status-mapping.component.scss']
})
export class AddDesignStatusMappingComponent extends BaseComponent {
  loading: boolean;
  addDesignStatusMappingForm: UntypedFormGroup;
  designStatuses: string[] = ['In Engineering', 'To Implement', 'No Physical Work', 'Void', 'Superseded'];
  fieldImpacts: string[] = ['Yes', 'No', 'Yes, No'];
  changeType: string;
  changeTypes = ChangeType;
  rfiType: string = ChangeType[this.changeTypes.RFI];

  constructor(public dialogRef: MatDialogRef<AddDesignStatusMappingComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toastService: ToastService,
    private designStatusMappingService: DesignStatusMappingService) {
    super();
    this.changeType = data.changeType;

    this.addDesignStatusMappingForm = new UntypedFormGroup({
      changeType: new UntypedFormControl(this.changeType, Validators.required),
      designStatus: new UntypedFormControl('', Validators.required),
      fieldImpact: new UntypedFormControl('', Validators.required),
      sourceFileStatus: new UntypedFormControl('', Validators.required),
      revision: new UntypedFormControl(''),
      rfiImpact: new UntypedFormControl('')
    });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    this.loading = true;
    const form = new DesignStatusMappingDTO();

    form.designStatus = this.addDesignStatusMappingForm.controls.designStatus.value;
    form.changeType = this.addDesignStatusMappingForm.controls.changeType.value;
    form.fieldImpact = this.addDesignStatusMappingForm.controls.fieldImpact.value;
    form.sourceFileStatus = this.addDesignStatusMappingForm.controls.sourceFileStatus.value;
    form.revision = this.addDesignStatusMappingForm.controls.revision.value;
    form.rfiImpact = this.addDesignStatusMappingForm.controls.rfiImpact.value;

    this.designStatusMappingService
      .add(form)
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe(
        (data) => {
          this.loading = false;
          this.toastService.Success(
            `The design status mapping for ${form.changeType} has been added.`
          );

          this.dialogRef.close({ success: true, data: data });
        },
        (error) => {
          this.loading = false;
          if (error.status === 409) {
            this.toastService.Error('Design status mapping entered already exists in SCMT.');
          } else {
            this.toastService.Error(
              'An error occurred while adding a design status mapping. Please contact a Program Administrator.'
            );
          }
        }
      );
  }

}
