import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { line } from 'd3-shape';

//https://github.com/swimlane/ngx-charts/blob/master/projects/swimlane/ngx-charts/src/lib/line-chart/line-series.component.ts

@Component({
    selector: 'g[ngx-charts-line-series-icicle]',
    template: `
        <svg:g>
            <svg:g
                ngx-charts-line
                class="line-series"
                [data]="data"
                [path]="path"
                [stroke]="stroke"
                [animations]="animations"
                [class.active]="isActive(data)"
                [class.inactive]="isInactive(data)"
            />
        </svg:g>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IcicleLineSeriesComponent implements OnChanges {
    @Input() data;
    @Input() xScale;
    @Input() yScale;
    @Input() colors;
    @Input() scaleType;
    @Input() curve: any;
    @Input() activeEntries: any[];
    @Input() animations: boolean = true;

    path: string;
    stroke: any;

    ngOnChanges(changes: SimpleChanges): void {
        this.update();
    }

    update(): void {
        const data = this.data.series;

        const lineGen = this.getLineGenerator();
        this.path = lineGen(data) || '';

        this.stroke = this.colors.getColor(this.data.name);
    }

    getLineGenerator(): any {
        return line<any>()
            .x((d) => {
                const label = d.name;
                let value;
                if (this.scaleType === 'time') {
                    value = this.xScale(label);
                } else if (this.scaleType === 'linear') {
                    value = this.xScale(Number(label));
                } else {
                    value = this.xScale(label);
                }
                return value;
            })
            .y((d) => this.yScale(d.value))
            .curve(this.curve);
    }

    isActive(entry): boolean {
        if (!this.activeEntries) return false;
        const item = this.activeEntries.find((d) => {
            return entry.name === d.name;
        });
        return item !== undefined;
    }

    isInactive(entry): boolean {
        if (!this.activeEntries || this.activeEntries.length === 0) return false;
        const item = this.activeEntries.find((d) => {
            return entry.name === d.name;
        });
        return item === undefined;
    }
}
