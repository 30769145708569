import { DataItem } from "@swimlane/ngx-charts";
import { PlanningCommitmentReliabilityTotalItem } from "src/app/store/reports/planning/model";
import * as _ from 'lodash';


export class SeriesBuilder {
    private _series: DataItem[];
    private readonly _blackSeries: string = 'Per plan';
    private readonly _orangeSeries: string = 'Committed';
    private readonly _greenSeries: string = 'Committed and Achieved';
    private readonly _redSeries: string = 'Committed but not Achieved';
    private readonly _greySeries: string = 'Achieved but not committed';
   
    constructor() {
      this._series = [
      ]
    }
  
    perPlan(): SeriesBuilder {
      this._series.push({ name: this._blackSeries, value:  0 });
      return this;
    }

    committed(): SeriesBuilder {
      this._series.push({ name: this._orangeSeries, value:  0 });
      return this;
    }

    committedAchieved(): SeriesBuilder {
      this._series.push({ name: this._greenSeries, value:  0 });
      return this;
    }

    committedNotAchieved(): SeriesBuilder {
      this._series.push({ name: this._redSeries, value:  0 });
      return this;
    }

    achievedNotCommited(): SeriesBuilder {
      this._series.push({ name: this._greySeries, value:  0 });
      return this;
    }
    
  
    build(total: PlanningCommitmentReliabilityTotalItem): DataItem[] {
      for (const item of this._series) {
        switch(item.name) {
          case this._blackSeries:
            item.value = total.blackCount;
            break;
          case this._orangeSeries:
            item.value = total.orangeCount;
            break;
          case this._greenSeries:
            item.value = total.greenCount;
            break;
          case this._greySeries:
            item.value = total.greyCount;
            break;
          case this._redSeries:
            item.value = total.redCount;
            break;
        }
      }
      return _.cloneDeep(this._series);
    }
  }