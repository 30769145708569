import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { PlanningDataDeltaPagination } from './model';
import { Moment } from 'moment';

export enum PlanningDataActionTypes {
    PlanningDataUploadLogRequest = '[Planning Data] Upload Log Request',
    PlanningDataUploadLogSuccess = '[Planning Data] Upload Log Success',
    PlanningDataUploadLogError = '[Planning Data] Upload Log Error',
    PlanningDataDeltaRequest = '[Planning Data] Delta Request',
    PlanningDataDeltaSuccess = '[Planning Data] Delta Success',
    PlanningDataDeltaError = '[Planning Data] Delta Error',
    PlanningDataSetStatusAndStartDate = '[Planning Data] Set Status and Start Date',
    PlanningDataDeltaFilterPropertyUpdate = '[Planning Data] Delta Filter Property Update',
    PlanningDataPatchDeltaRequest = '[Planning Data] Patch Delta Request',
    PlanningDataPatchDeltaSuccess = '[Planning Data] Patch Delta Success',
    PlanningDataPatchDeltaError = '[Planning Data] Patch Delta Error',
    PlanningDataPatchAllDeltaRequest = '[Planning Data] Patch All Delta Request',
    PlanningDataPatchAllDeltaSuccess = '[Planning Data] Patch All Delta Success',
    PlanningDataPatchAllDeltaError = '[Planning Data] Patch All Delta Error',
    PlanningDataValidateDeltaRequest = '[Planning Data] Validate Delta Request',
    PlanningDataValidateDeltaSuccess = '[Planning Data] Validate Delta Success',
    PlanningDataValidateDeltaError = '[Planning Data] Validate Delta Error',
    PlanningDataFileUploaded = '[Planning Data] File Uploaded',
    PlannignDataClearInProgressSpinner = '[Planning Data] Clear In Progress Spinner',
    PlanningDataTemplateFileRequest = '[Planning Data] Template File Request',
    PlanningDataTemplateFileSuccess = '[Planning Data] Template File Success',
    PlanningDataTemplateFileError = '[Planning Data] Template File Error',
    PlanningDataDownloadOutputDataRequest = '[Planning Data] Download Output Data Request',
    PlanningDataDownloadOutputDataSuccess = '[Planning Data] Download Output Data Success',
    PlanningDataDownloadOutputDataError = '[Planning Data] Download Output Data Error',
    PlanningDataValidateButtonStateRequest = '[Planning Data] Validate Button State Request',
    PlanningDataValidateButtonStateSuccess = '[Planning Data] Validate Button State Success',
    PlanningDataValidateButtonStateError = '[Planning Data] Validate Button State Error',
    PlanningDataAddStartedLog = '[Planning Data] Add Started Log',
}

export class PlanningDataAddStartedLog implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataAddStartedLog;
}

export class PlanningDataUploadLogRequest implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataUploadLogRequest;
}

export class PlanningDataUploadLogSuccess implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class PlanningDataUploadLogError implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataUploadLogError;

    constructor(public payload: string) {}
}

export class PlanningDataDeltaRequest implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataDeltaRequest;
}

export class PlanningDataDeltaSuccess implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataDeltaSuccess;

    constructor(public payload: PlanningDataDeltaPagination) {}
}

export class PlanningDataDeltaError implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataDeltaError;

    constructor(public payload: string) {}
}

export class PlanningDataSetStatusAndStartDate implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class PlanningDataDeltaFilterPropertyUpdate implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataDeltaFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class PlanningDataPatchDeltaRequest implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataPatchDeltaRequest;

    constructor(public payload: { subsystem: string; deltaState: boolean }) {}
}

export class PlanningDataPatchAllDeltaRequest implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataPatchAllDeltaRequest;

    constructor(public payload: { deltaState: boolean }) {}
}

export class PlanningDataPatchDeltaSuccess implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataPatchDeltaSuccess;
}

export class PlanningDataPatchAllDeltaSuccess implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataPatchAllDeltaSuccess;

    constructor(public payload: { deltaState: boolean }) {}
}

export class PlanningDataPatchDeltaError implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataPatchDeltaError;

    constructor(public payload: string) {}
}

export class PlanningDataPatchAllDeltaError implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataPatchAllDeltaError;

    constructor(public payload: string) {}
}

export class PlanningDataValidateDeltaRequest implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataValidateDeltaRequest;
}

export class PlanningDataValidateDeltaSuccess implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataValidateDeltaSuccess;
}

export class PlanningDataValidateDeltaError implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataValidateDeltaError;

    constructor(public payload: string) {}
}

export class PlanningDataFileUploaded implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataFileUploaded;
}

export class PlannignDataClearInProgressSpinner implements Action {
    readonly type = PlanningDataActionTypes.PlannignDataClearInProgressSpinner;
}

export class PlanningDataTemplateFileRequest implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataTemplateFileRequest;

    constructor(public payload: string) {}
}

export class PlanningDataTemplateFileSuccess implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class PlanningDataTemplateFileError implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataTemplateFileError;

    constructor(public payload: string) {}
}

export class PlanningDataDownloadOutputDataRequest implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class PlanningDataDownloadOutputDataSuccess implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class PlanningDataDownloadOutputDataError implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class PlanningDataValidateButtonStateRequest implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataValidateButtonStateRequest;
}

export class PlanningDataValidateButtonStateSuccess implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class PlanningDataValidateButtonStateError implements Action {
    readonly type = PlanningDataActionTypes.PlanningDataValidateButtonStateError;

    constructor(public payload: string) {}
}

export type PlanningDataActions =
    | PlanningDataUploadLogRequest
    | PlanningDataUploadLogSuccess
    | PlanningDataUploadLogError
    | PlanningDataDeltaRequest
    | PlanningDataDeltaSuccess
    | PlanningDataDeltaError
    | PlanningDataSetStatusAndStartDate
    | PlanningDataDeltaFilterPropertyUpdate
    | PlanningDataPatchDeltaRequest
    | PlanningDataPatchDeltaSuccess
    | PlanningDataPatchDeltaError
    | PlanningDataPatchAllDeltaRequest
    | PlanningDataPatchAllDeltaSuccess
    | PlanningDataPatchAllDeltaError
    | PlanningDataValidateDeltaRequest
    | PlanningDataValidateDeltaSuccess
    | PlanningDataValidateDeltaError
    | PlanningDataFileUploaded
    | PlannignDataClearInProgressSpinner
    | PlanningDataTemplateFileRequest
    | PlanningDataTemplateFileSuccess
    | PlanningDataTemplateFileError
    | PlanningDataDownloadOutputDataRequest
    | PlanningDataDownloadOutputDataSuccess
    | PlanningDataDownloadOutputDataError
    | PlanningDataValidateButtonStateRequest
    | PlanningDataValidateButtonStateSuccess
    | PlanningDataValidateButtonStateError
    | PlanningDataAddStartedLog;
