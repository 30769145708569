<div class="schedule-container" *ngIf="(schedule$ | async) || (isLoading$ | async)">
    <h3>Schedule</h3>
    <button type="button" class="sct-button sct-button-light" [disabled]="isLoading$ | async" (click)="save()">
        save schedule
    </button>

    <div class="schedtimeln">
        <form [formGroup]="scheduleForm">
            <div *ngIf="events.length > 0" class="schedtimeln-content">
                <div class="schedtimeln-line"></div>
                <div *ngFor="let event of events" class="schedtimeln-item">
                    <mat-form-field style="text-align: center;" appearance="none">
                        <textarea
                            class="timeline-label"
                            maxlength="45"
                            [formControl]="event.labelControl"
                            matInput
                        ></textarea>
                    </mat-form-field>
                    <div class="timeline-icon"></div>
                    <mat-form-field appearance="standard">
                        <input [formControl]="event.control" matInput [matDatepicker]="picker" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="schedtimeln-item">
                    <mat-form-field appearance="none" class="goocmilestone">
                        <textarea class="timeline-label" value="GOOC Milestone" matInput readonly></textarea>
                    </mat-form-field>
                    <div class="timeline-icon goocmilestone"></div>
                    <mat-form-field appearance="standard" class="goocmilestone">
                        <input formControlName="goocMilestone" matInput [matDatepicker]="picker" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="isLoading$ | async" class="schedule-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
