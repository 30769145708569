import {
    Component,
    Input,
    ViewChild,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    AfterViewInit,
    OnInit,
} from '@angular/core';
import * as moment from 'moment';
import { SkylineDTO, SkylineLevelOfDetails, SkylineRFODTO } from 'src/app/store/reports/skyline/model';
import { BaseComponent } from 'src/app/components/base.component';
import { WeekType } from 'src/app/enums';
import * as _ from 'lodash';
import { PopoverContentComponent } from 'ngx-smart-popover';
import { Router } from '@angular/router';
import { getCurrentWeekStartDate, getStartWeekDateFromDate, getStartWeekFromDate, getWeekEndDate } from 'src/app/extensions';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-reports-skyline-chart',
    templateUrl: './reports-skyline-chart.component.html',
    styleUrls: ['./reports-skyline-chart.component.scss'],
})
export class ReportsSkylineChartComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() printMode = false;
    @Input() weeks: { date: moment.Moment; weekType: WeekType }[];
    @Input() subsystemsByWeek: SkylineDTO[][];
    @Input() rfosByWeek: SkylineRFODTO[][];
    @Input() openAqvdsPerWeek: { week: moment.Moment; count: number }[];
    @Input() openBitrsPerWeek: { week: moment.Moment; count: number }[];
    @Input() openCitrsPerWeek: { week: moment.Moment; count: number }[];
    @Input() pastWeeksCount: number;
    @Input() isLoading: boolean;
    @Input() skylinePhase = '';
    @Input() skylineType = '';
    @Input() levelOfDetails: SkylineLevelOfDetails;
    @ViewChild('subsystemDetailsPopover') popoverContent: PopoverContentComponent;
    @ViewChild('rfoDetailsPopover') rfopopoverContent: PopoverContentComponent;
    currentWeekStartDate: moment.Moment = getCurrentWeekStartDate();
    selectedSubsystem: SkylineDTO;
    selectedRFO: SkylineRFODTO;
    popoverPlacement = 'bottom-right';
    popoverParentClass = '';
    maxNumberOfStackedItems = [];
    detailsLevels = SkylineLevelOfDetails;
    flagToggles$ = this.store.select((state) => state.skylineState.flagToggles);
    flagToggles: { [propertyName: string]: boolean } = {};

    constructor(private cdRef: ChangeDetectorRef, private router: Router, private store: Store<ApplicationState>) {
        super();
    }
    ngOnInit(): void {
        this.flagToggles$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((togglesValues) => (this.flagToggles = togglesValues));
    }
    ngOnChanges(changes: SimpleChanges): void {
        const subsystems = changes.subsystemsByWeek;
        const rfos = changes.rfosByWeek;

        if (
            subsystems &&
            subsystems.currentValue &&
            subsystems.currentValue.length > 0 &&
            !_.every(subsystems.currentValue, (x) => x === null)
        ) {
            let max = _.maxBy(subsystems.currentValue, 'length').length;
            this.maxNumberOfStackedItems = new Array(max).fill(1).map((x, i) => ++i);
        } else if (
            rfos &&
            rfos.currentValue &&
            rfos.currentValue.length > 0 &&
            !_.every(rfos.currentValue, (x) => x === null)
        ) {
            let max = _.maxBy(rfos.currentValue, 'length').length;
            this.maxNumberOfStackedItems = new Array(max).fill(1).map((x, i) => ++i);
        } else {
            this.maxNumberOfStackedItems = [];
        }
    }

    ngAfterViewInit() {
        this.cdRef.detectChanges();
    }
    compareWithCurrentWeek(week: moment.Moment) {
        return moment(week).isSame(this.currentWeekStartDate, 'days');
    }

    popover(): any {
        return this.popoverContent ? this.popoverContent : '';
    }

    rfopopover(): any {
        return this.rfopopoverContent ? this.rfopopoverContent : '';
    }

    setBorderClass(subsystem: SkylineDTO) {
        if (
            (this.skylineType === 'forecast' &&
                (this.skylinePhase == 'mc' || this.skylinePhase == 'mcwalkdown') &&
                subsystem.walkdownCompleted) ||
            (this.skylineType === 'forecast' &&
                (this.skylinePhase == 'rfsu' || this.skylinePhase == 'rfsuwalkdown') &&
                subsystem.walkdownCompleted) || 
            (this.skylinePhase == 'citr' && subsystem.walkdownCompleted)
        ) {
            return 'fccompl-tile';
        }

        if (
            (this.skylineType === 'plan' || this.skylineType === 'commitment') &&
            this.skylinePhase == 'rfsu' &&
            subsystem.walkdownCompleted == true
        ) {
            {
                return 'wdcomplbrd-tile';
            }
        }
        if (
            (this.skylineType === 'plan' || this.skylineType === 'commitment' || this.skylineType === 'forecast' ) &&
            this.skylinePhase == 'rfsu' &&
            subsystem.walkdownCommitted == true && this.flagToggles.showWalkdownCommitted == true
        ) {
            {
                return 'wdcommitted-tile';
            }
        }

        if (
            !subsystem.walkdownCompleted ||
            ((this.skylineType === 'plan' || this.skylineType === 'commitment') && this.skylinePhase == 'rfsu')
        ) {
            return 'default-tile';
        } else if (subsystem.walkdownCompleted && (this.skylineType === 'plan' || this.skylineType === 'commitment') && this.skylinePhase != 'rfsu') {
            return `wdcompl-tile ${this.isSubsystemLate(subsystem) ? 'late' : ''}`;
        }
    }
    setRFOBorderClass(rfo : SkylineRFODTO) {
        if (
            ((this.skylineType === 'plan' || this.skylineType === 'forecast') && this.skylinePhase == 'rfo')
        ) {
            return 'default-tile';
        }

    }

    setBackgroundColor(subsystem: SkylineDTO) {
        const isSubsystemLate = this.isSubsystemLate(subsystem);
        let backgroundColor = 'white';
        let progressColor =
            isSubsystemLate && this.skylinePhase != 'rfsu' ? 'rgba(255, 0, 0, 0.25)' : 'rgba(0, 255, 0, 0.25)';
            let forecastColor = 'rgba(0, 150, 255, 0.25)';
        if (
            (subsystem.rfsuCompleted && (this.skylineType === 'plan') && this.skylinePhase === 'rfsu') 
        ) {
            return { background: '#6ABB04' };
        } 
        else if (subsystem.rfsuActual !== null && this.skylineType === 'commitment' && this.skylinePhase === 'rfsu')
        {
            let RfsuActualstartWeekDate = getStartWeekDateFromDate(subsystem.rfsuActual);
            if(subsystem.commitmentDate?.toString() < RfsuActualstartWeekDate.format().toString()){
                return { background: '#e21836' };

            }
            let RfsuActualEndWeekDate = RfsuActualstartWeekDate.add(6,'d');
            if(subsystem.commitmentDate?.toString() > RfsuActualEndWeekDate.format().toString()){
                return { background: '#0000FF' };
            }
            else return { background : '#6ABB04'};        
        }else if (
            subsystem.operationsApprovalOutstanding && this.flagToggles.showOpsAppPending &&    
            (this.skylineType === 'plan' || this.skylineType === 'commitment' || this.skylineType === 'forecast') && this.skylinePhase === 'rfsu'
        ) {
            return { background: '#FFE700' };
        } else if (
            (subsystem.rfsuEdossierInitiated && this.flagToggles.showRfsuEdossierInitiated &&
            (this.skylineType === 'plan' || this.skylineType === 'commitment'|| this.skylineType === 'forecast') && this.skylinePhase === 'rfsu')
        ) {
            if(this.skylineType === 'forecast'){ return {background: '#047aa4'}} else
            return { background: '#CCEFFC' };
        } else if (
            (subsystem.nonCommissionable && this.flagToggles.showNonCommissionable &&
            (this.skylineType === 'plan' || this.skylineType === 'commitment'|| this.skylineType === 'forecast') && this.skylinePhase === 'rfsu')
        ) {
            return { background: '#A9A9A9' };
        } else if (
            (subsystem.mcActual == null && (this.skylineType === 'plan' || this.skylineType === 'commitment' || this.skylineType === 'forecast') && this.skylinePhase === 'rfsu' && this.flagToggles.showMcIncomplete) 
            || (this.skylinePhase === 'citr' && this.skylineType === 'forecast' &&  subsystem.citrCompletionPercentageThisWeek === 0 && subsystem.itrStatus.toLowerCase() != 'complete' )
        ) {
            return { background: '#E7E7E7' };
        } else if (
            (this.skylinePhase === 'mc' || this.skylinePhase === 'mcwalkdown'
                ? subsystem.mcCompleted
                : subsystem.rfsuCompleted)
            || (this.skylinePhase === 'citr' && this.skylineType === 'forecast' &&  (subsystem.itrStatus.toLowerCase() === 'complete'))
        ) {
            return {
                background:
                    this.skylineType === 'forecast'
                        ? 'rgba(0, 25, 255, 0.7)'
                        : isSubsystemLate && this.skylinePhase != 'rfsu'
                        ? 'rgba(255, 0, 0, 0.6)'
                        : 'rgba(0, 255, 0, 0.8)',
                color: this.skylineType === 'forecast' || isSubsystemLate ? 'white' : 'black',
            };
        } else if (
            (this.skylinePhase === 'citr' && this.skylineType === 'forecast' 
             &&  (subsystem.citrCompletionPercentageThisWeek == null &&subsystem.citrCompletionPercentageLastWeek == null))
        ) {
            return { background: '#E7E7E7' };
        } 
        else if(this.skylinePhase === 'citr') {
            const completionPercentage = subsystem.citrCompletionPercentageThisWeek
            return {
                background:
                    'linear-gradient(to right, ' +
                    //(this.skylineType === 'forecast' ? progressColor : forecastColor) +
                    forecastColor +
                    ' ' +
                    completionPercentage +
                    '%, ' +
                    backgroundColor +
                    ' ' +
                    completionPercentage +
                    '%)',
            };
        } else {
            const completionPercentage =
                this.skylinePhase === 'mc' || this.skylinePhase === 'mcwalkdown'
                    ? subsystem.aqvdCompletionPercentage
                    : subsystem.bcitrCompletionPercentage;
            return {
                background:
                    'linear-gradient(to right, ' +
                    (this.skylineType === 'plan' || this.skylineType === 'commitment' ? progressColor : forecastColor) +
                    ' ' +
                    completionPercentage +
                    '%, ' +
                    backgroundColor +
                    ' ' +
                    completionPercentage +
                    '%)',
            };
        }
    }
    setRFOBackgroundColor(rfo: SkylineRFODTO){
        const isRFOLate = this.isRFOLate(rfo);
        let backgroundColor = 'white';
        let progressColor =//'rgba(0, 255, 0, 0.25)';
            isRFOLate && this.skylinePhase != 'rfo' ? 'rgba(255, 0, 0, 0.25)' : 'rgba(0, 255, 0, 0.25)';
            let forecastColor = 'rgba(0, 150, 255, 0.25)';
        if (
            (rfo.rfoCompleted && (this.skylineType === 'plan' || this.skylineType === 'forecast') && this.skylinePhase === 'rfo') 
        ) {
            return { background: '#6ABB04' };
        } else {
            const completionPercentage = rfo.rfoCompletionPercentage;
            return {
                background:
                    'linear-gradient(to right, ' +
                    (this.skylineType === 'plan' || this.skylineType === 'forecast' ? progressColor : forecastColor) +
                    ' ' +
                    completionPercentage +
                    '%, ' +
                    backgroundColor +
                    ' ' +
                    completionPercentage +
                    '%)',
            };
        }
    }

    setFontColor(subsystem: SkylineDTO) {
        const isSubsystemLate = this.isSubsystemLate(subsystem);
        let lateColor = '#E21836';
        let onTimeColor = '#555555';
        let fontColor = subsystem.rfsuCompleted ? '#FFFFFF' : isSubsystemLate ? lateColor : onTimeColor;
        if ((this.skylineType === 'plan' || this.skylineType === 'commitment') && this.skylinePhase == 'rfsu') {
            return fontColor;
        }
    }
    setRFOFontColor(rfo : SkylineRFODTO){
        const isRFOLate = this.isRFOLate(rfo);
        let lateColor = '#E21836';
        let onTimeColor = '#555555';
        let fontColor = rfo.rfoCompleted ? '#FFFFFF' : isRFOLate ? lateColor : onTimeColor;
        if ((this.skylineType === 'plan' || this.skylineType === 'forecast') && this.skylinePhase == 'rfo') {
            return fontColor;
        }
    }

    selectSubsystem(subsystem: SkylineDTO, weekPosition: number, subsystemPosition: number) {
        this.selectedSubsystem = subsystem;

        let verticalPosition = '',
            horizontalPosition = '';
        horizontalPosition = this.weeks.length - weekPosition < 3 ? 'left' : 'right';
        verticalPosition = subsystemPosition > 3 ? 'bottom' : 'top';
        this.popoverPlacement = `${verticalPosition}-${horizontalPosition}`;
    }

    selectRfo(rfo: SkylineRFODTO, weekPosition: number, rfoPosition: number) {
        this.selectedRFO = rfo;

        let verticalPosition = '',
            horizontalPosition = '';
        horizontalPosition = this.weeks.length - weekPosition < 3 ? 'left' : 'right';
        verticalPosition = rfoPosition > 3 ? 'bottom' : 'top';
        this.popoverPlacement = `${verticalPosition}-${horizontalPosition}`;
    }

    getOpenWeekCount(opensPerWeek: { week: moment.Moment; count: number }[], week: moment.Moment): number {
        if (opensPerWeek.length > 0) {
            let element = opensPerWeek.find((x) => moment(x.week).isSame(week, 'days'));
            return element !== undefined ? element.count : 0;
        }

        return 0;
    }

    parseWeekNumber(week: moment.Moment): string {
        return getWeekEndDate(week).format('W/MMM D');
    }

    getSubsystemsCount(week: moment.Moment): number {
        if (this.weeks && this.subsystemsByWeek) {
            var index = this.weeks.findIndex((x) => moment(x.date).isSame(week, 'days'));
            return this.subsystemsByWeek[index]?.length ? this.subsystemsByWeek[index].length : 0;
        }
        return 0;
    }

    getRFOsCount(week: moment.Moment): number {
        if (this.weeks && this.rfosByWeek) {
            var index = this.weeks.findIndex((x) => moment(x.date).isSame(week, 'days'));
            return this.rfosByWeek[index]?.length ? this.rfosByWeek[index].length : 0;
        }
        return 0;
    }

    redirectToRedZone(subsystem: string) {
        var url = this.router.createUrlTree(['redzone'], { queryParams: { subsystem } });
        window.open(window.location.origin + url.toString(), '_blank');
    }

    redirectToBluezone(subsystem: string) {
        var url = this.router.createUrlTree(['bluezone'], {
            queryParams: { subsystem, discipline: 'all', showDisciplineBreakdown: false },
        });
        window.open(window.location.origin + url.toString(), '_blank');
    }

    private isSubsystemLate(subsystem: SkylineDTO) {
        const forecast =
            this.skylinePhase === 'mc' || this.skylinePhase === 'mcwalkdown'
                ? subsystem.mcForecast
                : subsystem.rfsuForecast;
        const plan =
            this.skylinePhase === 'mcwalkdown'
                ? subsystem.mcPlan
                : this.skylineType === 'plan'
                ? this.skylinePhase === 'mc'
                    ? subsystem.mcPlan
                    : subsystem.rfsuPlan
                : subsystem.commitmentDate;

        return forecast && moment(forecast).isAfter(moment(plan), 'days');
    }

    private isRFOLate(rfo: SkylineRFODTO) {
        const forecast = rfo.rfoForecast;
        const plan = rfo.rfoPlan

        return forecast && moment(forecast).isAfter(moment(plan), 'days');
    }

    emptyClause(array:[][]) : boolean {
        if (array.length === 0) {
            return false;
        }else{
            if(array.every(a=> a.length <= 0))
            {return false;}
            else return true;
        } 
    }
}
