import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { ITRConstraintsDeletePagination } from './model';

export enum ITRConstraintsActionTypes {
    ITRConstraintsUploadLogRequest = '[ITR Constraints Upload] Upload Log Request',
    ITRConstraintsUploadLogSuccess = '[ITR Constraints Upload] Upload Log Success',
    ITRConstraintsUploadLogError = '[ITR Constraints Upload] Upload Log Error',
    ITRConstraintsDeleteRecordsRequest = '[ITR Constraints Upload] Delete Records Request',
    ITRConstraintsDeleteRecordsSuccess = '[ITR Constraints Upload] Delete Records Success',
    ITRConstraintsDeleteRecordsError = '[ITR Constraints Upload] Delete Records Error',
    ITRConstraintsSetStatusAndStartDate = '[ITR Constraints Upload] Set Status and Start Date',
    ITRConstraintsDeleteFilterPropertyUpdate = '[ITR Constraints Upload] Delete Filter Property Update',
    ITRConstraintsDeleteFilterReset = '[ITR Constraints Upload] Delete Filter Reset',
    ITRConstraintsPatchDeleteRecordRequest = '[ITR Constraints Upload] Patch Delete Record Request',
    ITRConstraintsPatchDeleteRecordSuccess = '[ITR Constraints Upload] Patch Delete Record Success',
    ITRConstraintsPatchDeleteRecordError = '[ITR Constraints Upload] Patch Delete Record Error',
    ITRConstraintsPatchAllDeleteRecordsRequest = '[ITR Constraints Upload] Patch All Delete Records Request',
    ITRConstraintsPatchAllDeleteRecordsSuccess = '[ITR Constraints Upload] Patch All Delete Records Success',
    ITRConstraintsPatchAllDeleteRecordsError = '[ITR Constraints Upload] Patch All Delete Records Error',
    ITRConstraintsValidateDataRequest = '[ITR Constraints Upload] Validate Data Request',
    ITRConstraintsValidateDataSuccess = '[ITR Constraints Upload] Validate Data Success',
    ITRConstraintsValidateDataError = '[ITR Constraints Upload] Validate Data Error',
    ITRConstraintsFileUploaded = '[ITR Constraints Upload] File Uploaded',
    ITRConstraintsClearInProgressSpinner = '[ITR Constraints Upload] Clear In Progress Spinner',
    ITRConstraintsTemplateFileRequest = '[ITR Constraints Upload] Template File Request',
    ITRConstraintsTemplateFileSuccess = '[ITR Constraints Upload] Template File Success',
    ITRConstraintsTemplateFileError = '[ITR Constraints Upload] Template File Error',
    ITRConstraintsDownloadOutputDataRequest = '[ITR Constraints Upload] Download Output Data Request',
    ITRConstraintsDownloadOutputDataSuccess = '[ITR Constraints Upload] Download Output Data Success',
    ITRConstraintsDownloadOutputDataError = '[ITR Constraints Upload] Download Output Data Error',
    ITRConstraintsValidateButtonStateRequest = '[ITR Constraints Upload] Validate Button State Request',
    ITRConstraintsValidateButtonStateSuccess = '[ITR Constraints Upload] Validate Button State Success',
    ITRConstraintsValidateButtonStateError = '[ITR Constraints Upload] Validate Button State Error',
    ITRConstraintsAddStartedLog = '[ITR Constraints Upload] Add Started Log',
}

export class ITRConstraintsAddStartedLog implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsAddStartedLog;
}

export class ITRConstraintsUploadLogRequest implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsUploadLogRequest;
}

export class ITRConstraintsUploadLogSuccess implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ITRConstraintsUploadLogError implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsUploadLogError;

    constructor(public payload: string) {}
}

export class ITRConstraintsDeleteRequest implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsDeleteRecordsRequest;
}

export class ITRConstraintsDeleteSuccess implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsDeleteRecordsSuccess;

    constructor(public payload: ITRConstraintsDeletePagination) {}
}

export class ITRConstraintsDeleteError implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ITRConstraintsSetStatusAndStartDate implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class ITRConstraintsDeleteFilterPropertyUpdate implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ITRConstraintsDeleteFilterReset implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsDeleteFilterReset;
}

export class ITRConstraintsPatchDeleteRequest implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsPatchDeleteRecordRequest;

    constructor(public payload: { constraintId: string; constraintStatus:string; deleteState: boolean }) {}
}

export class ITRConstraintsPatchAllDeleteRequest implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ITRConstraintsPatchDeleteSuccess implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsPatchDeleteRecordSuccess;
}

export class ITRConstraintsPatchAllDeleteSuccess implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ITRConstraintsPatchDeleteError implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class ITRConstraintsPatchAllDeleteError implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ITRConstraintsValidateDeleteRequest implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsValidateDataRequest;
}

export class ITRConstraintsValidateDeleteSuccess implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsValidateDataSuccess;
}

export class ITRConstraintsValidateDeleteError implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsValidateDataError;

    constructor(public payload: string) {}
}

export class ITRConstraintsFileUploaded implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsFileUploaded;
}

export class ITRConstraintsClearInProgressSpinner implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsClearInProgressSpinner;
}

export class ITRConstraintsTemplateFileRequest implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ITRConstraintsTemplateFileSuccess implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ITRConstraintsTemplateFileError implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsTemplateFileError;

    constructor(public payload: string) {}
}

export class ITRConstraintsDownloadOutputDataRequest implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ITRConstraintsDownloadOutputDataSuccess implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ITRConstraintsDownloadOutputDataError implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ITRConstraintsValidateButtonStateRequest implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsValidateButtonStateRequest;
}

export class ITRConstraintsValidateButtonStateSuccess implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class ITRConstraintsValidateButtonStateError implements Action {
    readonly type = ITRConstraintsActionTypes.ITRConstraintsValidateButtonStateError;

    constructor(public payload: string) {}
}

export type ITRConstraintsActions =
    | ITRConstraintsUploadLogRequest
    | ITRConstraintsUploadLogSuccess
    | ITRConstraintsUploadLogError
    | ITRConstraintsDeleteRequest
    | ITRConstraintsDeleteSuccess
    | ITRConstraintsDeleteError
    | ITRConstraintsSetStatusAndStartDate
    | ITRConstraintsDeleteFilterPropertyUpdate
    | ITRConstraintsDeleteFilterReset
    | ITRConstraintsPatchDeleteRequest
    | ITRConstraintsPatchDeleteSuccess
    | ITRConstraintsPatchDeleteError
    | ITRConstraintsPatchAllDeleteRequest
    | ITRConstraintsPatchAllDeleteSuccess
    | ITRConstraintsPatchAllDeleteError
    | ITRConstraintsValidateDeleteRequest
    | ITRConstraintsValidateDeleteSuccess
    | ITRConstraintsValidateDeleteError
    | ITRConstraintsFileUploaded
    | ITRConstraintsClearInProgressSpinner
    | ITRConstraintsTemplateFileRequest
    | ITRConstraintsTemplateFileSuccess
    | ITRConstraintsTemplateFileError
    | ITRConstraintsDownloadOutputDataRequest
    | ITRConstraintsDownloadOutputDataSuccess
    | ITRConstraintsDownloadOutputDataError
    | ITRConstraintsValidateButtonStateRequest
    | ITRConstraintsValidateButtonStateSuccess
    | ITRConstraintsValidateButtonStateError
    | ITRConstraintsAddStartedLog;
