import { Injectable } from '@angular/core';
import { DetailedStatusDataPagination, DetailedStatusFilterBase , DetailedStatusDTO as DetailedStatusDTO2, CommentDetailDTO } from 'src/app/store/detailed-status/model';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { SubsystemScope } from 'src/app/enums';
import { appConfig } from 'src/app/app.config';
import { Observable } from 'rxjs';
import { DetailedStatusDTO, PLIReviewType } from 'src/app/store/details/model';
import { ResponsiblePersonOrGroupDTO } from 'src/app/store/data-update-history/model';

@Injectable({
    providedIn: 'root',
})
export class DetailedStatusService {
   
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly detailedStatusEndpoint: string = '/detailedstatus';    
    private readonly constraintEndpoint: string = '/constraint';
    constructor(private http: HttpClient) {}

    getDetailedStatusData(filter: DetailedStatusFilterBase) {
        return this.http.post<DetailedStatusDataPagination>(`${this.rootUrl}${this.detailedStatusEndpoint}`, filter);
    }

    getConstraintCommentDetailsData(id: string,itrConstraintId:string, scope: SubsystemScope) {
        return this.http.get(`${this.rootUrl}${this.constraintEndpoint}/${scope}/${id}/${itrConstraintId}`);
    }
    getDetailsConstraintData(id: string, scope: SubsystemScope) {
        return this.http.get(`${this.rootUrl}${this.detailedStatusEndpoint}/${scope}/${id}`);
    }

    getDetailsData(id: string, scope: SubsystemScope) {
        return this.http.get(`${this.rootUrl}${this.detailedStatusEndpoint}/${scope}/${id}`);
    }

    generateExcel(filter: DetailedStatusFilterBase) {
        return this.http.post(`${this.rootUrl}${this.detailedStatusEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    uploadAttachments(formData: FormData, itemId: string, scope: SubsystemScope) {
        return this.http.post(
            `${this.rootUrl}${this.detailedStatusEndpoint}/attachments?id=${itemId}&scope=${scope}`,
            formData
        );
    }

    deleteAttachment(links: string[], scope: SubsystemScope) {
        let params = new HttpParams();
        links.forEach((link) => {
            params = params.append('links', link);
            params = params.append('scope', scope.toString());
        });
        return this.http.delete(`${this.rootUrl}${this.detailedStatusEndpoint}/attachments`, { params });
    }

    downloadAttachment(link: string, scope: SubsystemScope) {
        return this.http.get<Blob>(
            `${this.rootUrl}${this.detailedStatusEndpoint}/attachments?link=${link}&scope=${scope}`,
            {
                responseType: 'arraybuffer' as 'json',
            }
        );
    }

    reviewPLI(finalPunchItem: string, type: PLIReviewType): Observable<DetailedStatusDTO> {
        return this.http.post<DetailedStatusDTO>(`${this.rootUrl}${this.detailedStatusEndpoint}/review/${finalPunchItem}/${type}`, {});
    }

    deleteReviewPLI(id: number): Observable<DetailedStatusDTO> {
        return this.http.post<DetailedStatusDTO>(`${this.rootUrl}${this.detailedStatusEndpoint}/deletereview/${id}`, {});
    }

    patchConstraintFlag(entity: DetailedStatusDTO) {
        return this.http.patch(`${this.rootUrl}${this.detailedStatusEndpoint}/${entity.id}/constraintflag`, [
            { op: 'replace', path: '/ConstraintFlag', value: entity.constraintFlag },
        ]);
    }

    getFilteredRecordsCount(exceptionNumber:string) {
        return this.http.get(`${this.rootUrl}${this.detailedStatusEndpoint}/filteredrecordscount/${exceptionNumber}`);
    }

    updatePliImplementationTeam(payload:{number:string, implementationTeam: string})
    {
        let params = new HttpParams();
        params = params.append('Number',payload.number);
        params = params.append('ImplementationTeam',payload.implementationTeam);
        return this.http.post(`${this.rootUrl}${this.detailedStatusEndpoint}/updateimplementationteam`, {}, {params});
    }

    addPostRFOWorkExpectedClosureDate(postRfoData: DetailedStatusDTO2) {
        return this.http.post(`${this.rootUrl}${this.detailedStatusEndpoint}/addPostRFOWorkExpectedClosureDate`, postRfoData);
    }

    updatePostRFOManualPriority(postRfoData: DetailedStatusDTO2) {
        return this.http.post(`${this.rootUrl}${this.detailedStatusEndpoint}/addPostRFOWorkManualPriority`, postRfoData);
    }

    updatePostRFOResponsiblePersonOrGroup(responsiblePersonOrGroup: DetailedStatusDTO2) {
        return this.http.post(`${this.rootUrl}${this.detailedStatusEndpoint}/addPostRFOWorkResponsiblePersonOrGroup`, responsiblePersonOrGroup);
    }

    getPostRFOResponsiblePersonOrGroupHistory(postRFOData: DetailedStatusDTO2) {
        return this.http.post<ResponsiblePersonOrGroupDTO[]>(`${this.rootUrl}${this.detailedStatusEndpoint}/responsiblepersonorgrouphistory`, postRFOData);
    }
}
