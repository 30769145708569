import { Component, Inject, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { map, takeWhile, tap } from 'rxjs/operators';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { ChangeDocIWP, IWPLink } from 'src/app/store/change-document-details/model';
import {
    ChangeDocumentDetailsUpdateIwpRequest,
    ChangeDocumentDetailsGetIwpRequest,
} from 'src/app/store/change-document-details/actions';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-iwp-popup',
    templateUrl: './iwp-popup.component.html',
    styleUrls: ['./iwp-popup.component.scss'],
})
export class IwpPopupComponent extends BaseComponent {
    contractNo: string = null;
    displayedColumns = ['iwp', 'subsystems', 'actions'];
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setIWPInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    dataSource: MatTableDataSource<{ iwpNumber: string; subsystems: string[]; isInEditMode: boolean }>;
    changeDocNo: string;
    changeDocId: number;
    isAddingDisabled = false;
    isListComplete = false;
    hasUpdatePriviliges = false;
    changeDocSubsystems: string[] = [];

    iwps: ChangeDocIWP[] = [];
    iwpLink$ = this.store.select((state) => state.changeDocumentDetailsState.iwpLink);

    @ViewChild(MatTable, { static: true }) matTable: MatTable<any>;

    constructor(
        public dialogRef: MatDialogRef<IwpPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private store: Store<ApplicationState>,
        private lookupService: LookupService,
        private changeDetectorRef: ChangeDetectorRef,
        private roleService: RoleService,
        private toastService: ToastService
    ) {
        super();

        this.changeDocNo = data.changeDocNo;
        this.changeDocId = data.changeDocId;
        this.contractNo = data.contractNo;

        this.dataSource = new MatTableDataSource();
    }
    ngOnInit(): void {
        this.hasUpdatePriviliges =
            this.roleService.isInRole(Constants.applicableGroups.Admin) ||
            this.roleService.isInRole(Constants.applicableGroups.MCEngineer3GI) ||
            this.roleService.isInRole(Constants.applicableGroups.MCEngineer3GP);

        this.iwpLink$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data && data.changeDocIwps) {
                this.iwps = data.changeDocIwps;
                this.isListComplete = data.isListComplete;

                const tableData = this.iwps.map((x) => ({
                    iwpNumber: x.iwpNumber,
                    subsystems: x.subsystems,
                    isInEditMode: false,
                }));

                this.dataSource = new MatTableDataSource(tableData);
            }
        });

        this.store.dispatch(new ChangeDocumentDetailsGetIwpRequest({ id: this.changeDocId }));
    }

    parseSubsystems(subsystems: string[]) {
        return subsystems.map((x) => ({ name: x, selected: true }));
    }

    close(saveData: boolean) {
        if (saveData) {
            const changeDocIwps = this.dataSource.data
                .filter((x) => x.iwpNumber.length > 0)
                .map((x) =>
                    Object.assign(new ChangeDocIWP(), {
                        contractNo: this.contractNo,
                        iwpNumber: x.iwpNumber,
                        subsystems: x.subsystems,
                    })
                );

            const iwpLink = Object.assign(new IWPLink(), {
                isListComplete: this.isListComplete,
                changeDocId: this.changeDocId,
                changeDocIwps: changeDocIwps,
            });

            this.store.dispatch(new ChangeDocumentDetailsUpdateIwpRequest(iwpLink));
        }

        this.dataSource.data = [];
        this.dialogRef.close();
    }

    radioChange(radio: any) {
        this.isListComplete = radio.value === 'complete';
    }

    addRow() {
        this.dataSource.data.push({ iwpNumber: '', subsystems: [], isInEditMode: true });
        this.dataSource.filter = '';

        this.isAddingDisabled = true;
    }

    onSubsystemsClosed($event: any[], index: number) {
        this.dataSource.data[index].subsystems = $event.map((x) => x.name);
        this.setSubsystemInput.emit(new SetInputEventArgs(true));
    }

    onIWPClosed($event: any, index: number) {
        if (this.dataSource.data[index]) {
            this.dataSource.data[index].iwpNumber = $event && $event[0] ? $event[0].name : '';
            this.changeDetectorRef.detectChanges();
        }
    }

    searchIWPs = (search: string, take: number, page: number) => {
        return this.lookupService
            .searchIWPs(search, take, page, this.contractNo)
            .pipe(
                map((x: string[]) =>
                    x.filter((x) => !this.dataSource.data.map((y) => y.iwpNumber).includes(x)).map((y) => ({ name: y }))
                )
            );
    };

    getSubsystemsForChangeDocument = () => {
        return this.lookupService.getSubsystemsForChangeDocument(this.changeDocId).pipe(
            tap((subsystems: string[]) => (this.changeDocSubsystems = subsystems)),
            map((x: string[]) => x.map((y) => ({ name: y })))
        );
    };

    anyRowInEditMode(skipIndex: number = null) {
        return this.dataSource.data.filter((r, i) => i != skipIndex).some((r) => r.isInEditMode);
    }

    removeRow(index: number) {
        this.dataSource.data.splice(index, 1);
        this.dataSource.filter = '';
    }

    acceptRow(index: number) {
        this.dataSource.data[index].isInEditMode = false;
    }

    editRow(index: number) {
        this.dataSource.data[index].isInEditMode = true;
    }
}
