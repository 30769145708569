import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { MilestoneDashboardService } from 'src/app/services/api/webapi-services/reports/milestone-dashboard.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from '../../model';
import {
    GOOCComparisonDashboardActionTypes,
    GOOCComparison1DashboardError,
    GOOCComparison1DashboardExportToExcelError,
    GOOCComparison1DashboardExportToExcelSuccess,
    GOOCComparison1DashboardSuccess,
    GOOCComparison2DashboardSuccess,
    GOOCComparison2DashboardError,
    GOOCComparison2DashboardExportToExcelSuccess,
} from './actions';

@Injectable()
export class GOOCComparisonDashboardEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private milestoneDashboardService: MilestoneDashboardService,
        private toastService: ToastService
    ) {}

    @Effect()
    goocComparison1DashboardRequest$ = this.actions$.pipe(
        ofType(GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardRequest),
        withLatestFrom(this.store.select((state) => state.goocComparisonDashboardState.gooc1Filter)),
        mergeMap(([action, filter]) =>
            this.milestoneDashboardService.filterGOOC1Data(filter).pipe(
                map((data) => new GOOCComparison1DashboardSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting GOOC Comparison 1 Dashboard data. Please contact Program Administrator.`
                    );
                    return of(new GOOCComparison1DashboardError(error));
                })
            )
        )
    );

    @Effect()
    goocComparison2DashboardRequest$ = this.actions$.pipe(
        ofType(GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardRequest),
        withLatestFrom(this.store.select((state) => state.goocComparisonDashboardState.gooc2Filter)),
        mergeMap(([action, filter]) =>
            this.milestoneDashboardService.filterGOOC2Data(filter).pipe(
                map((data) => new GOOCComparison2DashboardSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting GOOC Comparison 2 Dashboard data. Please contact Program Administrator.`
                    );
                    return of(new GOOCComparison2DashboardError(error));
                })
            )
        )
    );

    @Effect()
    gooc1ExportToExcel$ = this.actions$.pipe(
        ofType(GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.goocComparisonDashboardState.gooc1Filter)),
        mergeMap(([, goocComparisonDashboardFilter]) => {
            goocComparisonDashboardFilter.timezoneOffset = new Date().getTimezoneOffset();
            return this.milestoneDashboardService.generateExcelGOOCComparison1(goocComparisonDashboardFilter).pipe(
                map((excelData) => new GOOCComparison1DashboardExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new GOOCComparison1DashboardExportToExcelError(error));
                })
            );
        })
    );

    @Effect()
    gooc2ExportToExcel$ = this.actions$.pipe(
        ofType(GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.goocComparisonDashboardState.gooc2Filter)),
        mergeMap(([, goocComparisonDashboardFilter]) => {
            goocComparisonDashboardFilter.timezoneOffset = new Date().getTimezoneOffset();
            return this.milestoneDashboardService.generateExcelGOOCComparison2(goocComparisonDashboardFilter).pipe(
                map((excelData) => new GOOCComparison2DashboardExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new GOOCComparison1DashboardExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    gooc1SaveExcelData = this.actions$.pipe(
        ofType(GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardExportToExcelSuccess),
        map((action: GOOCComparison1DashboardExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `GOOCComparison1Dashboard_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect({ dispatch: false })
    gooc2SaveExcelData = this.actions$.pipe(
        ofType(GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardExportToExcelSuccess),
        map((action: GOOCComparison2DashboardExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `GOOCComparison2Dashboard_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );
}
