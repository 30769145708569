<div class="main" #boundary>
    <a class="navigator" (click)="goToPreviousPage()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <form [formGroup]="validationForm">
        <div class="status-container display-flex">
            <svg
                width="20px"
                height="20px"
                viewBox="0 0 20 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g
                        id="New-FCNi-Copy"
                        transform="translate(-89.000000, -246.000000)"
                        stroke="#751269"
                        stroke-width="2"
                    >
                        <g id="Main-/-SubHeader-2" transform="translate(0.000000, 195.000000)">
                            <g id="Main-/-Titles-/-Subtitle-+-Icon" transform="translate(0.000000, 42.000000)">
                                <g id="Icons-/-Dark-/-Square-Group" transform="translate(90.000000, 10.000000)">
                                    <g
                                        id="Group-5"
                                        transform="translate(0.947368, 1.894737)"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            d="M15.1578947,8.52631579 L15.1578947,13.374613 C15.1578947,14.3594923 14.3594923,15.1578947 13.374613,15.1578947 L1.78328173,15.1578947 C0.798402428,15.1578947 1.20612929e-16,14.3594923 0,13.374613 L0,1.78328173 C-1.20612929e-16,0.798402428 0.798402428,1.80919393e-16 1.78328173,0 L5.68421053,0 L6.63157895,0"
                                            id="Rectangle-5"
                                        ></path>
                                    </g>
                                    <circle id="Oval-6" cx="13.7368421" cy="4.26315789" r="3.31578947"></circle>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <div class="title">
                current validation status
            </div>
            <span class="disciplinestatus">electrical: </span>
            <span class="validation-status" [ngClass]="getValidationClassName(validationForm.value.validationStatus)">{{
                validationForm.value.isActive?  validationForm.value.validationStatus : 'not applicable'
            }}</span>
            <span class="disciplinestatus">ict: </span>
            <span class="validation-status" [ngClass]="getValidationClassName(ictvalidationForm.value.isActive?  ictvalidationForm.value.validationStatus : 'not applicable')">{{
                ictvalidationForm.value.isActive?  ictvalidationForm.value.validationStatus : 'not applicable'
            }}</span>
            <span class="disciplinestatus">smp: </span>
            <span class="validation-status" [ngClass]="getValidationClassName(smpvalidationForm.value.isActive?  smpvalidationForm.value.validationStatus : 'not applicable')">{{
                smpvalidationForm.value.isActive?  smpvalidationForm.value.validationStatus : 'not applicable'
            }}</span>
            <div class="status-buttons">
                <button
                    style="background-color: #00adee; width: fit-content; margin-right: 20px;"
                    *ngIf="(validationForm.value.validationStatus == 'test pack requested'|| ictvalidationForm.value.validationStatus == 'test pack requested' || smpvalidationForm.value.validationStatus == 'test pack requested') && testPackRequestedSubsystems.length > 0 "
                    type="button"
                    class="sct-button"
                    (click)="subsysetmReadyForSignOff()"
                >
                    subsystem ready for sign-off
                </button>

                <button class="sct-button sct-button-white history-icon" (click)="openHistoryPopup()">
                    status history
                </button>
            </div>
        </div>
        <div class="header-container">
            <div class="position">
                <mat-form-field class="container140">
                    <input matInput placeholder="Type" [value]="validDocHeader.changeType" readonly />
                </mat-form-field>
                <mat-form-field class="container300">
                    <input
                        (click)="goToEb(validDocHeader.number)"
                        class="eb-link"
                        matInput
                        placeholder="Number"
                        [value]="validDocHeader.number"
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container140">
                    <input matInput placeholder="Revision" [value]="validDocHeader.revision" readonly />
                </mat-form-field>
                <mat-form-field class="container300">
                    <input matInput placeholder="Date" [value]="validDocHeader.date | date: 'd/MMM/y'" readonly />
                </mat-form-field>
                <mat-form-field appearance="none" class="container300">
                    <mat-label>Design Status</mat-label>
                    <input class="status" matInput placeholder="Status" [value]="validDocHeader.status" readonly />
                </mat-form-field>
                <div>
                    <mat-form-field class="container620 margin-right20 doctitle">
                        <textarea
                            matInput
                            placeholder="Title"
                            [value]="validDocHeader.title"
                            cdkTextareaAutosize
                            readonly
                        ></textarea>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <input matInput placeholder="Project Team" [value]="validDocHeader.projectTeamName" readonly />
                    </mat-form-field>
                    <mat-form-field class="container300">
                        <input matInput placeholder="Area" [value]="validDocHeader.area" readonly />
                    </mat-form-field>
                </div>
                <mat-form-field class="container300">
                    <input matInput placeholder="Discipline" [value]="validDocHeader.discipline" readonly />
                </mat-form-field>
                <mat-form-field class="container300">
                    <input matInput placeholder="Contractor" [value]="validDocHeader.contractor" readonly />
                </mat-form-field>

                <div>
                    <mat-form-field class="container620 margin-right20">
                        <textarea
                            matInput
                            [value]="validDocHeader.impactedTags === null ? '' : validDocHeader.impactedTags"
                            placeholder="Tags Impacted"
                            matTextareaAutosize
                            matAutosizeMinRows="4"
                            readonly
                        ></textarea>
                    </mat-form-field>
                    <mat-form-field class="container620">
                        <textarea
                            matInput
                            [value]="validDocHeader.impactedDrawings === null ? '' : validDocHeader.impactedDrawings"
                            placeholder="Drawings Impacted"
                            matTextareaAutosize
                            matAutosizeMinRows="4"
                            readonly
                        ></textarea>
                    </mat-form-field>
                </div>
                <div class="bottom-elements">
                    <mat-form-field class="container620">
                        <textarea
                            matInput
                            [value]="validDocHeader.notes === null ? '' : validDocHeader.notes"
                            placeholder="Notes"
                            matTextareaAutosize
                            matAutosizeMinRows="4"
                            readonly
                        ></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="calloff-container" *ngIf="validDocHeader.changeType === 'Call-Off'">
            <div class="scope-of-work-editor-container">
                <div>
                    Scope of work
                </div>
                <div class="separator-10px">
                    <div class="jodit-container description-disabled">
                        <div [innerHTML]="validDocHeader.scopeOfWork | keepHtml"></div>
                    </div>
                </div>
                <div>
                    <br />
                    Attachments
                    <mat-list>
                        <mat-list-item *ngFor="let attachment of validDocHeader.attachments">
                            <a (click)="downloadAttachment(attachment)">{{ attachment.name }}</a>
                            <div class="flex-align-right">
                                <button class="download-icon" (click)="downloadAttachment(attachment)">
                                    <svg
                                        width="18px"
                                        height="20px"
                                        viewBox="0 0 16 17"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                    >
                                        <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                                        <title>DB9A0693-F198-4BA2-9F22-DB074699D58C</title>
                                        <desc>Created with sketchtool.</desc>
                                        <g
                                            id="Page-1"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <g
                                                transform="translate(-1259.000000, -1440.000000)"
                                                stroke="#0066B2"
                                                stroke-width="2"
                                            >
                                                <g
                                                    id="Buttons/Primary-Copy"
                                                    transform="translate(1256.000000, 1438.000000)"
                                                >
                                                    <path d="M4,18 L18,18" id="Line-3"></path>
                                                    <path
                                                        d="M5.5,8.5 L16.5,8.5"
                                                        id="Line-3-Copy"
                                                        transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                                    ></path>
                                                    <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
        <div class="tq-container" *ngIf="validDocHeader.changeType === 'TQ'">
            <div class="scope-of-work-editor-container">
                <div>
                    Description
                </div>
                <div class="separator-10px">
                    <div class="jodit-container description-disabled">
                        <div [innerHTML]="validDocHeader.description | keepHtml"></div>
                    </div>
                </div>
                <div>
                    <br />
                    Description Attachments
                    <mat-list>
                        <mat-list-item
                            *ngFor="
                                let attachment of validDocHeader.tqAttachments
                                    | attachments: attachmentTypes.Description
                            "
                        >
                            <a (click)="downloadAttachment(attachment)">{{ attachment.name }}</a>
                            <div class="flex-align-right">
                                <button class="download-icon" (click)="downloadAttachment(attachment)">
                                    <svg
                                        width="18px"
                                        height="20px"
                                        viewBox="0 0 16 17"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                    >
                                        <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                                        <title>download</title>
                                        <desc>Created with sketchtool.</desc>
                                        <g
                                            id="Page-1"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <g
                                                transform="translate(-1259.000000, -1440.000000)"
                                                stroke="#0066B2"
                                                stroke-width="2"
                                            >
                                                <g
                                                    id="Buttons/Primary-Copy"
                                                    transform="translate(1256.000000, 1438.000000)"
                                                >
                                                    <path d="M4,18 L18,18" id="Line-3"></path>
                                                    <path
                                                        d="M5.5,8.5 L16.5,8.5"
                                                        id="Line-3-Copy"
                                                        transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                                    ></path>
                                                    <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
                <div>
                    Response
                </div>
                <div class="separator-10px">
                    <div class="jodit-container description-disabled">
                        <div [innerHTML]="validDocHeader.response | keepHtml"></div>
                    </div>
                </div>
                <div>
                    <br />
                    Response Attachments
                    <mat-list>
                        <mat-list-item
                            *ngFor="
                                let attachment of validDocHeader.tqAttachments | attachments: attachmentTypes.Response
                            "
                        >
                            <a (click)="downloadAttachment(attachment)">{{ attachment.name }}</a>
                            <div class="flex-align-right">
                                <button class="download-icon" (click)="downloadAttachment(attachment)">
                                    <svg
                                        width="18px"
                                        height="20px"
                                        viewBox="0 0 16 17"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                    >
                                        <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                                        <title>download</title>
                                        <desc>Created with sketchtool.</desc>
                                        <g
                                            id="Page-1"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <g
                                                transform="translate(-1259.000000, -1440.000000)"
                                                stroke="#0066B2"
                                                stroke-width="2"
                                            >
                                                <g
                                                    id="Buttons/Primary-Copy"
                                                    transform="translate(1256.000000, 1438.000000)"
                                                >
                                                    <path d="M4,18 L18,18" id="Line-3"></path>
                                                    <path
                                                        d="M5.5,8.5 L16.5,8.5"
                                                        id="Line-3-Copy"
                                                        transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                                    ></path>
                                                    <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
        <div class="systemization-container">
            <div class="position">
                <div class="validation-title" >systemization section
                    <div class="form-buttons-container">
                        <mat-slide-toggle
                            [color]="'primary'"
                            [checked]="validDocHeader.systemizationIsCompleted"
                            (toggleChange)="toggleSystemizationCompleted()"
                            class="results-toggle"
                        >
                        Completed
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="bottom-elements sub">
                    <div *ngIf="!isInEditMode">
                        <mat-form-field class="container580 margin-right20 editable">
                            <textarea
                                matInput
                                [value]="
                                    validDocHeader.subsystems.length > 0 ? parseWithCommas(validDocHeader.subsystems) : 
                                        ( validationForm.value.subsystemImpactedNA ? 'Subsystem impacted N/A' : '')
                                "
                                placeholder="Subsystems - Manual overwrite in SCMT"
                                matTextareaAutosize
                                matAutosizeMinRows="4"
                                readonly
                            ></textarea>
                        </mat-form-field>
                    </div>
                    <div *ngIf="isInEditMode" class="container580 editable">
                        <app-multiple-items-selector
                            [formControl]="subsystemFormControl"
                            [searchFunc]="getSubsystems"
                            [isAsync]="true"
                            [isSelectAll]="false"                            
                            placeholder="Subsystems - Manual overwrite in SCMT"
                            class="container580 subsytem-selector"
                        >
                        </app-multiple-items-selector>
                        <mat-slide-toggle
                            *ngIf="validDocHeader.projectTeamName"
                            [color]="'primary'"
                            [checked]="searchCurrentProjectSubsystems"
                            (toggleChange)="toggleSearchSubsystemProjectTeam()"
                        >
                            show {{ validDocHeader.projectTeamName }} subsystems only
                        </mat-slide-toggle>
                    </div>
                    <div
                        class="center-vert"
                        [ngClass]="{ 'margin-right40': !isInEditMode && isFormEnabled, 'margin-right20': isInEditMode }"
                    >
                        <button
                            *ngIf="!isInEditMode && isUserAdminOrValidator && isSubsystemManualOverwriteallowed && !validationForm.value.subsystemImpactedNA && !validDocHeader.systemizationIsCompleted"
                            mat-icon-button
                            (click)="enterEditMode()"
                            title="edit"
                        >
                            <mat-icon svgIcon="edit"></mat-icon>
                        </button>
                        <button *ngIf="isInEditMode" mat-icon-button (click)="saveSubsystems()" title="accept">
                            <mat-icon>check_circle_outline</mat-icon>
                        </button>
                        <button *ngIf="isInEditMode" mat-icon-button (click)="cancelSubsystems()" title="cancel">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                    <mat-form-field class="container580">
                        <textarea
                            matInput
                            placeholder="Subsystems in Source Database"
                            readonly
                            matTextareaAutosize
                            matAutosizeMinRows="4"
                            [value]="
                                validDocHeader.suggestedSubsystems === null ? '' : validDocHeader.suggestedSubsystems
                            "
                        ></textarea>
                    </mat-form-field>
                </div>

                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Date Completed"
                        [value]="validDocHeader.subsystemUpdatedDate | date: 'd/MMM/y'"
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Validator"
                        readonly
                        [value]="validDocHeader.subsystemUpdatedBy && validDocHeader.subsystemUpdatedBy.name + ' ' + validDocHeader.subsystemUpdatedBy.surname"
                    />
                </mat-form-field>
                <br>
                <mat-form-field class="container970 margin-right20 editable">
                    <mat-label>Remarks</mat-label>
                    <textarea
                        matInput
                        #systemizationRemarkInput                        
                        [value]="validDocHeader.systemizationRemark"
                        matTextareaAutosize
                        matAutosizeMinRows="5"
                    ></textarea>
                </mat-form-field>
                <button
                    [disabled]="false"                        
                    class="sct-button sct-button-light"
                    (click)="saveSystemizationRemark(systemizationRemarkInput.value)"
                    style="margin-left: 40px;"
                >
                    Save Remark
                </button>    
            </div>
        </div>
        <div class="validation-container">
            <div class="position">
                <div class="validation-title">validation section</div>
                <br>
                <div class="border">
                    <mat-checkbox formControlName="fieldImpact" style="min-width: 175px;">
                        Field Impact
                        <div
                            class="tooltip-icon"
                            matTooltip="Change document contains work to be completed in the field"
                            matTooltipClass="scttooltip"
                        ></div>
                    </mat-checkbox>
                    <mat-checkbox formControlName="subsystemImpactedNA" (change)="updateSubsystemImpactedNA($event)">
                        Subsystem impacted N/A
                        <div
                            class="tooltip-icon"
                            matTooltip="Subsystem impacted N/A"
                            matTooltipClass="scttooltip"
                        ></div>
                    </mat-checkbox>
                    <button
                        [disabled]="false"                        
                        class="sct-button sct-button-light"
                        (click)="save()"
                        style="margin-left: 40px;"
                    >
                        save
                    </button>

                </div>
                <br>
                <div class="validation-subtitle"> {{validationForm.value.discipline}}
                    <div>
                            <div class="form-buttons-container">
                                <ng-container *ngIf="users?.length > 0 && validationForm.value.isActive">
                                    <mat-form-field class="container300 margin-right20 flexitem">
                                        <mat-label>Validation Assigned to</mat-label>
                                        <mat-select matNativeControl formControlName="validationAssignee" [compareWith]="compareItems"
                                            (selectionChange)="updateValidationAssignee($event, this.validationForm.value.validationAssignee?.id)">
                                            <mat-option *ngFor="let u of users" [value]="u">{{ u.name }} </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <div class="push">
                                    <button class="sct-button sct-button-white margin-right20"
                                        *ngIf="notStartedButtonVisible() && validationForm.value.isActive"
                                        [disabled]="isFormEnabled && !validationForm.valid" (click)="changeStatus(ValidationStatuses.NotStarted,null)">
                                        not started
                                    </button>
                                    <button class="sct-button sct-button-white margin-right20"
                                        *ngIf="inProgressButtonVisible() && validationForm.value.isActive"
                                        [disabled]="isFormEnabled && !validationForm.valid" (click)="changeStatus(ValidationStatuses.InProgress,null)">
                                        in progress
                                    </button>
                                    <span matTooltip="Please review key attribute changes and assign subsystems before completing validation."
                                        matTooltipClass="scttooltip" [matTooltipDisabled]="
                                                                        !validDocHeader.isColumnsChanged &&
                                                                        validDocHeader.subsystems &&
                                                                        validDocHeader.subsystems.length > 0
                                                                    ">
                                        <button class="sct-button sct-button-white margin-right20"
                                            *ngIf="completedButtonVisible() && validationForm.value.isActive" [disabled]="completedButtonDiabled()"
                                            (click)="changeStatus(ValidationStatuses.Completed,null)">
                                            completed
                                        </button>
                                    </span>
                                    <button [disabled]="!validationForm.valid || !saveButtonEnabled"
                                        *ngIf="completedButtonVisible() && validationForm.value.isActive"
                                        class="sct-button sct-button-light margin-right20" (click)="save()">
                                        save
                                    </button>
                                    <mat-slide-toggle [color]="'primary'" [checked]="validationForm.value.isActive"
                                        (toggleChange)="toggleisActive(validationForm.value.id,validationForm.value.discipline,validationForm.value.isActive)"
                                        class="results-toggle">
                                        Active
                                    </mat-slide-toggle>
                                </div>
                            </div>
                    </div>
                </div>
                <div class = "border">
                    <mat-form-field class="container300 margin-right20">
                        <input
                            matInput
                            placeholder="Date In Progress"
                            [value]="validationForm.value.inProgressDate | date: 'd/MMM/y'"
                            readonly
                        />
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <input
                            matInput
                            placeholder="Date Completed"
                            [value]="validationForm.value.completedDate | date: 'd/MMM/y'"
                            readonly
                        />
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <input
                            matInput
                            placeholder="Validator"
                            readonly
                            [value]="validationForm.value.validator && validationForm.value.validator.name"
                        />
                    </mat-form-field>
                    <div class="checkbox-section" hidden>
                        <mat-checkbox formControlName="fieldImpact">
                            Field Impact
                            <div
                                class="tooltip-icon"
                                matTooltip="Change document contains work to be completed in the field"
                                matTooltipClass="scttooltip"
                            ></div>
                        </mat-checkbox>
                        <mat-checkbox formControlName="mcPlusChangesComplete">
                            MCPlus Changes Complete
                            <div
                                class="tooltip-icon"
                                matTooltip="Change document reviewed & any changes to MCPlus implemented"
                                matTooltipClass="scttooltip"
                            ></div>
                        </mat-checkbox>
                        <mat-checkbox formControlName="punched">
                            Punched
                            <div class="tooltip-icon" matTooltip="PL raised in MCPlus" matTooltipClass="scttooltip"></div>
                        </mat-checkbox>
                        <mat-checkbox formControlName="qvdUpdate">
                            QVD Update
                            <div class="tooltip-icon" matTooltip="QVD updated in MCPlus" matTooltipClass="scttooltip"></div>
                        </mat-checkbox>
                        <mat-checkbox formControlName="mappedToTag">
                            Mapped to Tag
                            <div class="tooltip-icon" matTooltip="Mapped to Tag" matTooltipClass="scttooltip"></div>
                        </mat-checkbox>
                        <mat-checkbox formControlName="subsystemImpactedNA" (change)="updateSubsystemImpactedNA($event)">
                            Subsystem impacted N/A
                            <div
                                class="tooltip-icon"
                                matTooltip="Subsystem impacted N/A"
                                matTooltipClass="scttooltip"
                            ></div>
                        </mat-checkbox>
                    </div>
                    <mat-form-field class="container100pct editable">
                        <mat-label>Remarks</mat-label>
                        <textarea
                            matInput
                            formControlName="validationRemark"
                            matTextareaAutosize
                            matAutosizeMinRows="5"
                        ></textarea>
                        <mat-error
                            *ngIf="
                                validationForm.controls['validationRemark'].invalid &&
                                validationForm.controls['validationRemark'].errors.required
                            "
                        >
                            Remarks are required.
                        </mat-error>
                    </mat-form-field>
                </div><br>

                <form [formGroup]="ictvalidationForm" *ngIf="showICT">
                        <div class="validation-subtitle"> {{ictvalidationForm.value.discipline}}
                            <div>
                                    <div class="form-buttons-container">
                                        <ng-container *ngIf="users?.length > 0 && ictvalidationForm.value.isActive">
                                            <mat-form-field class="container300 margin-right20 flexitem">
                                                <mat-label>Validation Assigned to</mat-label>
                                                <mat-select matNativeControl formControlName="validationAssignee" [compareWith]="compareItems"
                                                    (selectionChange)="updateValidationAssignee($event, this.ictvalidationForm.value.validationAssignee?.id)">
                                                    <mat-option *ngFor="let u of users" [value]="u">{{ u.name }} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>
                                        <div class="push">
                                            <button class="sct-button sct-button-white margin-right20"
                                                *ngIf="notStartedButtonVisibleForICT() && ictvalidationForm.value.isActive"
                                                [disabled]="isFormEnabled && !ictvalidationForm.valid"
                                                (click)="changeStatus(ValidationStatuses.NotStarted, 'ICT')">
                                                not started
                                            </button>
                                            <button class="sct-button sct-button-white margin-right20"
                                                *ngIf="inProgressButtonVisibleForICT() && ictvalidationForm.value.isActive"
                                                [disabled]="isFormEnabled && !ictvalidationForm.valid"
                                                (click)="changeStatus(ValidationStatuses.InProgress, 'ICT')">
                                                in progress
                                            </button>
                                            <span matTooltip="Please review key attribute changes and assign subsystems before completing validation."
                                                matTooltipClass="scttooltip" [matTooltipDisabled]="
                                                                                            !validDocHeader.isColumnsChanged &&
                                                                                            validDocHeader.subsystems &&
                                                                                            validDocHeader.subsystems.length > 0
                                                                                        ">
                                                <button class="sct-button sct-button-white margin-right20"
                                                    *ngIf="completedButtonVisibleForICT() && ictvalidationForm.value.isActive"
                                                    [disabled]="completedButtonDiabledForICT()" (click)="changeStatus(ValidationStatuses.Completed, 'ICT')">
                                                    completed
                                                </button>
                                            </span>
                                            <button [disabled]="!ictvalidationForm.valid || !ictsaveButtonEnabled"
                                                *ngIf="completedButtonVisibleForICT() && ictvalidationForm.value.isActive"
                                                class="sct-button sct-button-light margin-right20" (click)="save()">
                                                save
                                            </button>
                                            <mat-slide-toggle [color]="'primary'" [checked]="ictvalidationForm.value.isActive"
                                                (toggleChange)="toggleisActive(ictvalidationForm.value.id, ictvalidationForm.value.discipline,ictvalidationForm.value.isActive)"
                                                class="results-toggle">
                                                Active
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class = "border">
                            <mat-form-field class="container300 margin-right20">
                                <input
                                    matInput
                                    placeholder="Date In Progress"
                                    [value]="ictvalidationForm.value.inProgressDate | date: 'd/MMM/y'"
                                    readonly
                                />
                            </mat-form-field>
                            <mat-form-field class="container300 margin-right20">
                                <input
                                    matInput
                                    placeholder="Date Completed"
                                    [value]="ictvalidationForm.value.completedDate | date: 'd/MMM/y'"
                                    readonly
                                />
                            </mat-form-field>
                            <mat-form-field class="container300 margin-right20">
                                <input
                                    matInput
                                    placeholder="Validator"
                                    readonly
                                    [value]="ictvalidationForm.value.validator && ictvalidationForm.value.validator.name"
                                />
                            </mat-form-field>
                            <div class="checkbox-section" hidden>
                                <mat-checkbox formControlName="fieldImpact">
                                    Field Impact
                                    <div
                                        class="tooltip-icon"
                                        matTooltip="Change document contains work to be completed in the field"
                                        matTooltipClass="scttooltip"
                                    ></div>
                                </mat-checkbox>
                                <mat-checkbox formControlName="mcPlusChangesComplete">
                                    MCPlus Changes Complete
                                    <div
                                        class="tooltip-icon"
                                        matTooltip="Change document reviewed & any changes to MCPlus implemented"
                                        matTooltipClass="scttooltip"
                                    ></div>
                                </mat-checkbox>
                                <mat-checkbox formControlName="punched">
                                    Punched
                                    <div class="tooltip-icon" matTooltip="PL raised in MCPlus" matTooltipClass="scttooltip"></div>
                                </mat-checkbox>
                                <mat-checkbox formControlName="qvdUpdate">
                                    QVD Update
                                    <div class="tooltip-icon" matTooltip="QVD updated in MCPlus" matTooltipClass="scttooltip"></div>
                                </mat-checkbox>
                                <mat-checkbox formControlName="mappedToTag">
                                    Mapped to Tag
                                    <div class="tooltip-icon" matTooltip="Mapped to Tag" matTooltipClass="scttooltip"></div>
                                </mat-checkbox>
                                <mat-checkbox formControlName="subsystemImpactedNA" (change)="updateSubsystemImpactedNA($event)">
                                    Subsystem impacted N/A
                                    <div
                                        class="tooltip-icon"
                                        matTooltip="Subsystem impacted N/A"
                                        matTooltipClass="scttooltip"
                                    ></div>
                                </mat-checkbox>
                            </div>
                            <mat-form-field class="container100pct editable">
                                <mat-label>Remarks</mat-label>
                                <textarea
                                    matInput
                                    formControlName="validationRemark"
                                    matTextareaAutosize
                                    matAutosizeMinRows="5"
                                ></textarea>
                                <mat-error
                                    *ngIf="
                                        ictvalidationForm.controls['validationRemark'].invalid &&
                                        ictvalidationForm.controls['validationRemark'].errors.required
                                    "
                                >
                                    Remarks are required.
                                </mat-error>
                            </mat-form-field>
                        </div><br>
                </form>

                <form [formGroup]="smpvalidationForm" *ngIf="showSMP">
                    <div class="validation-subtitle"> {{smpvalidationForm.value.discipline}}
                        <div>
                                <div class="form-buttons-container">
                                    <ng-container *ngIf="users?.length > 0 && smpvalidationForm.value.isActive">
                                        <mat-form-field class="container300 margin-right10 flexitem">
                                            <mat-label>Validation Assigned to</mat-label>
                                            <mat-select matNativeControl formControlName="validationAssignee" [compareWith]="compareItems"
                                                (selectionChange)="updateValidationAssignee($event, this.smpvalidationForm.value.validationAssignee?.id)">
                                                <mat-option *ngFor="let u of users" [value]="u">{{ u.name }} </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <div class="push">
                                        <button class="sct-button sct-button-white margin-right15"
                                            *ngIf="notStartedButtonVisibleForSMP() && smpvalidationForm.value.isActive"
                                            [disabled]="isFormEnabled && !smpvalidationForm.valid"
                                            (click)="changeStatus(ValidationStatuses.NotStarted, 'SMP')">
                                            not started
                                        </button>
                                        <button class="sct-button sct-button-white margin-right15"
                                            *ngIf="inProgressButtonVisibleForSMP() && smpvalidationForm.value.isActive"
                                            [disabled]="isFormEnabled && !smpvalidationForm.valid"
                                            (click)="changeStatus(ValidationStatuses.InProgress , 'SMP')">
                                            in progress
                                        </button>
                                        <button class="sct-button sct-button-white margin-right15"
                                            *ngIf="testPackReviewButtonVisibleForSMP() && smpvalidationForm.value.isActive"
                                            [disabled]="isFormEnabled && !smpvalidationForm.valid" 
                                            (click)="changeStatus(ValidationStatuses.TestPackReview , 'SMP')">
                                            test pack requested
                                        </button>
                                        <span matTooltip="Please review key attribute changes and assign subsystems before completing validation."
                                            matTooltipClass="scttooltip" [matTooltipDisabled]="
                                                                                    !validDocHeader.isColumnsChanged &&
                                                                                    validDocHeader.subsystems &&
                                                                                    validDocHeader.subsystems.length > 0
                                                                                ">
                                            <button class="sct-button sct-button-white margin-right15"
                                                *ngIf="completedButtonVisibleForSMP() && smpvalidationForm.value.isActive"
                                                [disabled]="completedButtonDiabledForSMP()" (click)="changeStatus(ValidationStatuses.Completed , 'SMP')">
                                                completed
                                            </button>
                                        </span>
                                        <button [disabled]="!smpvalidationForm.valid || !smpsaveButtonEnabled"
                                            *ngIf="completedButtonVisibleForSMP() && smpvalidationForm.value.isActive"
                                            class="sct-button sct-button-light margin-right15" (click)="save()">
                                            save
                                        </button>
                                    </div>
                                    <div>                                        
                                        <mat-slide-toggle [color]="'primary'" [checked]="smpvalidationForm.value.isActive"
                                            (toggleChange)="toggleisActive(smpvalidationForm.value.id,smpvalidationForm.value.discipline,smpvalidationForm.value.isActive)"
                                            class="results-toggle">
                                            Active
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class = "border">
                        <mat-form-field class="container300 margin-right20">
                            <input
                                matInput
                                placeholder="Date In Progress"
                                [value]="smpvalidationForm.value.inProgressDate | date: 'd/MMM/y'"
                                readonly
                            />
                        </mat-form-field>
                        <mat-form-field class="container300 margin-right20">
                            <input
                                matInput
                                placeholder="Date Completed"
                                [value]="smpvalidationForm.value.completedDate | date: 'd/MMM/y'"
                                readonly
                            />
                        </mat-form-field>
                        <mat-form-field class="container300 margin-right20">
                            <input
                                matInput
                                placeholder="Validator"
                                readonly
                                [value]="smpvalidationForm.value.validator && smpvalidationForm.value.validator.name"
                            />
                        </mat-form-field>
                        <div class="checkbox-section" hidden>
                            <mat-checkbox formControlName="fieldImpact">
                                Field Impact
                                <div
                                    class="tooltip-icon"
                                    matTooltip="Change document contains work to be completed in the field"
                                    matTooltipClass="scttooltip"
                                ></div>
                            </mat-checkbox>
                            <mat-checkbox formControlName="mcPlusChangesComplete">
                                MCPlus Changes Complete
                                <div
                                    class="tooltip-icon"
                                    matTooltip="Change document reviewed & any changes to MCPlus implemented"
                                    matTooltipClass="scttooltip"
                                ></div>
                            </mat-checkbox>
                            <mat-checkbox formControlName="punched">
                                Punched
                                <div class="tooltip-icon" matTooltip="PL raised in MCPlus" matTooltipClass="scttooltip"></div>
                            </mat-checkbox>
                            <mat-checkbox formControlName="qvdUpdate">
                                QVD Update
                                <div class="tooltip-icon" matTooltip="QVD updated in MCPlus" matTooltipClass="scttooltip"></div>
                            </mat-checkbox>
                            <mat-checkbox formControlName="mappedToTag">
                                Mapped to Tag
                                <div class="tooltip-icon" matTooltip="Mapped to Tag" matTooltipClass="scttooltip"></div>
                            </mat-checkbox>
                            <mat-checkbox formControlName="subsystemImpactedNA" (change)="updateSubsystemImpactedNA($event)">
                                Subsystem impacted N/A
                                <div
                                    class="tooltip-icon"
                                    matTooltip="Subsystem impacted N/A"
                                    matTooltipClass="scttooltip"
                                ></div>
                            </mat-checkbox>
                        </div>
                        <mat-form-field class="container100pct editable">
                            <mat-label>Remarks</mat-label>
                            <textarea
                                matInput
                                formControlName="validationRemark"
                                matTextareaAutosize
                                matAutosizeMinRows="5"
                            ></textarea>
                            <mat-error
                                *ngIf="
                                    smpvalidationForm.controls['validationRemark'].invalid &&
                                    smpvalidationForm.controls['validationRemark'].errors.required
                                "
                            >
                                Remarks are required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>

            </div>
        </div>
        <div class="changes-container position">
            <div class="changes-title">
                key attribute changes
                <div
                    class="tooltip-icon"
                    matTooltip='Changes to key attributes as identified in the source database when Validation Status is "In Progress" or "Completed" are identified this section.
                    Key attributes flagged are: Subsystems, Revision, Contractor & Tags Impacted fields in Source database.
                    User shall review the new values and update the validation data.
                    If Status needs to be reversed, please contact your team lead.'
                    matTooltipClass="scttooltip-maxwidth"
                    matTooltipPosition="right"
                ></div>
            </div>
            <mat-form-field class="container100pct">
                <input
                    matInput
                    placeholder="Columns Changed"
                    [value]="
                        validDocHeader.columnsChanged != null ? formatColumnsChanged(validDocHeader.columnsChanged) : ''
                    "
                    readonly
                />
            </mat-form-field>
            <div class="buttons-container">
                <button
                    [disabled]="!validDocHeader.isColumnsChanged || !isFormEnabled"
                    class="sct-button sct-button-light"
                    (click)="reviewChanges()"
                >
                    changes reviewed
                </button>
            </div>
        </div>
        <div *ngIf="isPowerUser" class="buttons-container poweruser">
            <button class="sct-button sct-button-light" (click)="changeIsActive()">
                {{ validDocHeader.isActive ? 'Set Inactive' : 'Set Active' }}
            </button>
        </div>
        <app-loading-indicator
            *ngIf="
                (validationFormLoader$ | async) ||
                (validationFormHeaderLoader$ | async) ||
                historyLoading ||
                assignInProgress
            "
        >
        </app-loading-indicator>
    </form>
</div>
