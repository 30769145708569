import { Injectable } from '@angular/core';
import { RedZoneDataPagination } from 'src/app/store/redzone/model';
import { RedZoneFilter } from 'src/app/store/redzone/model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { PhaseType } from 'src/app/enums';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class RedZoneService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly redZoneEndpoint: string = '/redzone';
    constructor(private http: HttpClient) {}

    getRedZoneData(filter: RedZoneFilter) {
        return this.http.post<RedZoneDataPagination>(`${this.rootUrl}${this.redZoneEndpoint}`, filter);
    }

    clearSubystemFlag(filter: RedZoneFilter) {
        return this.http.post(`${this.rootUrl}${this.redZoneEndpoint}/clearflag`, filter);
    }

    generateExcel(filter: RedZoneFilter) {
        return this.http.post(`${this.rootUrl}${this.redZoneEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    updateForecastDate(
        subsystem: string,
        type: PhaseType,
        forecastDate: moment.Moment,
        currentWeek: string,
        discipline: string,
        allDisciplines = false
    ) {
        var formdata = new FormData();
        formdata.append('subsystem', subsystem);
        return this.http.patch(
            `${this.rootUrl}${this.redZoneEndpoint}/${type}${
                forecastDate != null ? '/' + forecastDate.toJSON() : ''
            }?currentWeek=${currentWeek}&discipline=${discipline}&allDisciplines=${allDisciplines}`,
            formdata
        );
    }

    flagSubsystem(subsystem: string, color: string) {
        var formdata = new FormData();
        formdata.append('subsystem', subsystem);
        return this.http.patch(`${this.rootUrl}${this.redZoneEndpoint}/${color}`, formdata);
    }
}
