import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';

@Component({
  selector: 'app-activities-constraints',
  templateUrl: './activities-constraints.component.html',
  styleUrls: ['./activities-constraints.component.scss']
})
export class ActivitiesConstraintsComponent implements OnInit {
  isChartLoading$ = this.store.select((state) => state.healthState.activitiesConstraints.isChartLoading);

  constructor(private store: Store<ApplicationState>,) { }

  ngOnInit(): void {
    // This is intentional
  }

}
