import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    TagSearchActionTypes,
    TagSearchFilterSuccess,
    TagSearchFilterError,
    TagSearchExportToExcelSuccess,
    TagSearchExportToExcelError,
} from './actions';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { TagSearchService } from 'src/app/services/api/webapi-services/tag-search.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Injectable()
export class TagSearchEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private tagSearchService: TagSearchService
    ) {}

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType(TagSearchActionTypes.TagSearchFilterRequest),
        withLatestFrom(this.store.select((store) => store.tagSearchState.filter)),
        mergeMap(([action, tagSearchFilter]) => {
            return this.tagSearchService.searchTag(tagSearchFilter).pipe(
                map((dataPagination) => {
                    return new TagSearchFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering tag search. Please contact Program Administrator.'
                    );
                    return of(new TagSearchFilterError(error));
                })
            );
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(TagSearchActionTypes.TagSearchExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.tagSearchState.filter)),
        mergeMap(([, tagSearchFilter]) => {
            const filter = {
                ...tagSearchFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.tagSearchService.generateExcel(filter).pipe(
                map((excelData) => new TagSearchExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new TagSearchExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(TagSearchActionTypes.TagSearchExportToExcelSuccess),
        map((action: TagSearchExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `TagSearch_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );
}
