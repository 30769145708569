<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add contractor mapping</span></p>
    <div>
        <form [formGroup]="addContractorMappingForm">
            <mat-form-field class="container300">
                <mat-label>Mapping</mat-label>
                <textarea formControlName="mapping" matInput name="mapping"></textarea>
            </mat-form-field>
            <mat-form-field class="container300">
                <mat-label>Contractor No</mat-label>
                <mat-select formControlName="contractNo">
                    <mat-option *ngFor="let c of contractors" [value]="c.contractNo">{{ c.contractNo }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="row">
                <button mat-button type="button" class="sct-button sct-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!addContractorMappingForm.valid"
                    class="sct-button sct-button-light"
                    (click)="onConfirm()"
                >
                    add mapping
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
