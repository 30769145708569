import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, OrderDirection } from '../../common.model';

export interface ExceptionsPriorityState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deleteData: ExceptionsPriorityDeletePagination;
    isDeleteDataLoading: boolean;
    deleteFilter: ExceptionsPriorityDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class ExceptionsPriorityDeleteDTO {
    deleteState: boolean;
    priority: string;
    exception: string;
}

export class ExceptionsPriorityDeleteFilter {
    page = 0;
    take = 10;
    sortBy = 'exception';
    direction: OrderDirection = OrderDirection.Desc;
    columnPriority: CheckListColumn = null;
    columnException: CheckListColumn = null;
}
export class ExceptionsPriorityDeletePagination {
    items: ExceptionsPriorityDeleteDTO[] = [];
    totalCount = 0;
}
