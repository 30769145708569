import { CTARegisterState, CTARegisterFilter, CTARegisterDataPagination } from './model';
import { CTARegisterActions, CTARegisterActionTypes } from './actions';

const initialState: CTARegisterState = {
    dataPagination: new CTARegisterDataPagination(),
    isLoading: false,
    filter: new CTARegisterFilter(),
};

export function reducer(state: CTARegisterState = initialState, action: CTARegisterActions) {
    switch (action.type) {
        case CTARegisterActionTypes.CTADeleteRequest:
        case CTARegisterActionTypes.CTARegisterDownloadDataRequest:
        case CTARegisterActionTypes.CTARegisterFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CTARegisterActionTypes.CTARegisterFilterSuccess: {
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        }
        case CTARegisterActionTypes.CTADeleteError:
        case CTARegisterActionTypes.CTADeleteSuccess:
        case CTARegisterActionTypes.CTARegisterDownloadDataError:
        case CTARegisterActionTypes.CTARegisterDownloadDataSuccess:
        case CTARegisterActionTypes.CTARegisterFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CTARegisterActionTypes.CTARegisterFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(action.payload.value)
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        default: {
            return state;
        }
    }
}
