<main class="main">
    <div class="title-container">
        <div class="title">Excel Exports</div>
    </div>
    <div class="results-container">

        <table
            mat-table
            matSort
            [dataSource]="data"            
            matSortDisableClear
        >
            <ng-container matColumnDef="createdBy">
                <th mat-header-cell disableClear *matHeaderCellDef >
                    <div class="sort-container">
                        <span>File Type</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell disableClear *matHeaderCellDef >
                    <div class="sort-container">
                        <span>Created</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.created | date: 'd/MMM/yy HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="fileName">
                <th mat-header-cell disableClear *matHeaderCellDef >
                    <div class="sort-container">
                        <span>File Name</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element" (click)="openLink(element.blobName, element.fileName)" class="arrow">
                    {{ element.fileName }}
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>
