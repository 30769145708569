import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';

export enum ITRConstraintsContactsActionTypes {
    ITRConstraintsContactsUploadLogRequest = '[ITR Constraints Contacts Upload] Upload Log Request',
    ITRConstraintsContactsUploadLogSuccess = '[ITR Constraints Contacts Upload] Upload Log Success',
    ITRConstraintsContactsUploadLogError = '[ITR Constraints Contacts Upload] Upload Log Error',
    ITRConstraintsContactsFileUploaded = '[ITR Constraints Contacts Upload] File Uploaded',
    ITRConstraintsContactsClearInProgressSpinner = '[ITR Constraints Contacts Upload] Clear In Progress Spinner',
    ITRConstraintsContactsTemplateFileRequest = '[ITR Constraints Contacts Upload] Template File Request',
    ITRConstraintsContactsTemplateFileSuccess = '[ITR Constraints Contacts Upload] Template File Success',
    ITRConstraintsContactsTemplateFileError = '[ITR Constraints Contacts Upload] Template File Error',
    ITRConstraintsContactsDownloadOutputDataRequest = '[ITR Constraints Contacts Upload] Download Output Data Request',
    ITRConstraintsContactsDownloadOutputDataSuccess = '[ITR Constraints Contacts Upload] Download Output Data Success',
    ITRConstraintsContactsDownloadOutputDataError = '[ITR Constraints Contacts Upload] Download Output Data Error',
    ITRConstraintsContactsValidateButtonStateRequest = '[ITR Constraints Contacts Upload] Validate Button State Request',
    ITRConstraintsContactsValidateButtonStateSuccess = '[ITR Constraints Contacts Upload] Validate Button State Success',
    ITRConstraintsContactsValidateButtonStateError = '[ITR Constraints Contacts Upload] Validate Button State Error',
    ITRConstraintsContactsAddStartedLog = '[ITR Constraints Contacts Upload] Add Started Log',
}

export class ITRConstraintsContactsAddStartedLog implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsAddStartedLog;
}

export class ITRConstraintsContactsUploadLogRequest implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsUploadLogRequest;
}

export class ITRConstraintsContactsUploadLogSuccess implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ITRConstraintsContactsUploadLogError implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsUploadLogError;

    constructor(public payload: string) {}
}
export class ITRConstraintsContactsFileUploaded implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsFileUploaded;
}

export class ITRConstraintsContactsClearInProgressSpinner implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsClearInProgressSpinner;
}

export class ITRConstraintsContactsTemplateFileRequest implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ITRConstraintsContactsTemplateFileSuccess implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ITRConstraintsContactsTemplateFileError implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsTemplateFileError;

    constructor(public payload: string) {}
}

export class ITRConstraintsContactsDownloadOutputDataRequest implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ITRConstraintsContactsDownloadOutputDataSuccess implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ITRConstraintsContactsDownloadOutputDataError implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ITRConstraintsContactsValidateButtonStateRequest implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsValidateButtonStateRequest;
}

export class ITRConstraintsContactsValidateButtonStateSuccess implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class ITRConstraintsContactsValidateButtonStateError implements Action {
    readonly type = ITRConstraintsContactsActionTypes.ITRConstraintsContactsValidateButtonStateError;

    constructor(public payload: string) {}
}

export type ITRConstraintsContactsActions =
    | ITRConstraintsContactsUploadLogRequest
    | ITRConstraintsContactsUploadLogSuccess
    | ITRConstraintsContactsUploadLogError
    | ITRConstraintsContactsFileUploaded
    | ITRConstraintsContactsClearInProgressSpinner
    | ITRConstraintsContactsTemplateFileRequest
    | ITRConstraintsContactsTemplateFileSuccess
    | ITRConstraintsContactsTemplateFileError
    | ITRConstraintsContactsDownloadOutputDataRequest
    | ITRConstraintsContactsDownloadOutputDataSuccess
    | ITRConstraintsContactsDownloadOutputDataError
    | ITRConstraintsContactsValidateButtonStateRequest
    | ITRConstraintsContactsValidateButtonStateSuccess
    | ITRConstraintsContactsValidateButtonStateError
    | ITRConstraintsContactsAddStartedLog;
