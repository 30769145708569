import {
    DcnRfiRedlineRegisterActions,
    DcnRfiRedlineRegisterActionTypes,
    DcnRfiRedlineRegisterAddCommentSuccess,
    dcnRfiRegisterDeleteCommentError,
    dcnRfiRegisterDeleteCommentRequest,
    dcnRfiRegisterDeleteCommentSuccess,
} from './actions';
import {
    DcnRfiRedlineRegisterFilter,
    DcnRfiRegisterDetails,
    DcnRfiRegisterDto,
    DcnRfiRegisterResultDto,
    RedlineRegisterDetails,
    RedlineRegisterDto,
    RedlineRegisterResultDto,
    RegisterType,
} from './model';
import * as _ from 'lodash';
import { id } from '@swimlane/ngx-charts';
import { SubsystemScope, Zone } from 'src/app/enums';

export interface DcnRfiRedlineRegisterState {
    dcnRfiRedlineRegisterFilter: DcnRfiRedlineRegisterFilter;
    isLoading: boolean;
    dcnRfiRegister: DcnRfiRegisterResultDto;
    redlineRegister: RedlineRegisterResultDto;
    dcnRfiRegisterDetails: DcnRfiRegisterDetails;
    redlineRegisterDetails: RedlineRegisterDetails;
    isSavingComment: boolean;
}

const initialState: DcnRfiRedlineRegisterState = {
    dcnRfiRedlineRegisterFilter: new DcnRfiRedlineRegisterFilter(),
    isLoading: false,
    dcnRfiRegister: { items: [] as DcnRfiRegisterDto[], totalCount: 0 } as DcnRfiRegisterResultDto,
    redlineRegister: { items: [] as RedlineRegisterDto[], totalCount: 0 } as RedlineRegisterResultDto,
    dcnRfiRegisterDetails: new DcnRfiRegisterDetails(),
    redlineRegisterDetails: new RedlineRegisterDetails(),
    isSavingComment: false,
};

export function reducer(state = initialState, action: DcnRfiRedlineRegisterActions) {
    switch (action.type) {
        case DcnRfiRedlineRegisterActionTypes.FilterPropertyUpdate: {
            return {
                ...state,
                dcnRfiRedlineRegisterFilter: _.assign(_.cloneDeep(state.dcnRfiRedlineRegisterFilter), action.filter),
            };
        }
        case DcnRfiRedlineRegisterActionTypes.FilterReset: {
            let filter = new DcnRfiRedlineRegisterFilter();
            filter.registerType = action.registerType;
            return {
                ...state,
                dcnRfiRedlineRegisterFilter: filter,
            };
        }
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDataRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDataRequestSuccess:
            return {
                ...state,
                dcnRfiRegister: action.dcnRfiRegisterResult,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDataRequestError:
            return {
                ...state,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.RedlineRegisterDataRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.RedlineRegisterDataRequestSuccess:
            return {
                ...state,
                redlineRegister: action.redlineRegisterResult,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.RedlineRegisterDataRequestError:
            return {
                ...state,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDetailsDataRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDetailsDataRequestSuccess:
            return {
                ...state,
                dcnRfiRegisterDetails: action.dcnRfiRegisterDetails,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDetailsDataRequestError:
            return {
                ...state,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.RedlineRegisterDetailsDataRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.RedlineRegisterDetailsDataRequestSuccess:
            return {
                ...state,
                redlineRegisterDetails: action.redlineRegisterDetails,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.RedlineRegisterDetailsDataRequestError:
            return {
                ...state,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddWorkpackRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddWorkpackSuccess:
            return {
                ...state,
                dcnRfiRegisterDetails: {
                    ...state.dcnRfiRegisterDetails,
                    workpacks: [...state.dcnRfiRegisterDetails.workpacks, action.workpack],
                },
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddWorkpackError:
            return {
                ...state,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddRedlineRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddRedlineSuccess:
            return {
                ...state,
                dcnRfiRegisterDetails: {
                    ...state.dcnRfiRegisterDetails,
                    redlineBaseDetails: [...state.dcnRfiRegisterDetails.redlineBaseDetails, action.redline],
                },
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddRedlineError:
            return {
                ...state,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkflowStatusRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkflowStatusSuccess:
            return {
                ...state,
                dcnRfiRegisterDetails: {
                    ...state.dcnRfiRegisterDetails,
                    workflowStatus: action.workflowStatus.workflowStatus,
                },
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkflowStatusError:
            return {
                ...state,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkpackRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkpackSuccess:
            const updatedWorkpacks = _.cloneDeep(state.dcnRfiRegisterDetails.workpacks);
            updatedWorkpacks.map((item) => {
                if (action.workpack.workpackNumber == item.workpackNumber) {
                    item.workpackStatus = action.workpack.workpackStatus;
                }
            });
            return {
                ...state,
                dcnRfiRegisterDetails: {
                    ...state.dcnRfiRegisterDetails,
                    workpacks: updatedWorkpacks,
                },
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkpackError:
            return {
                ...state,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateRlmuStatusRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateRlmuStatusSuccess:
            const updatedRlmuStatus = _.cloneDeep(state.dcnRfiRegisterDetails.redlineBaseDetails);
            updatedRlmuStatus.map((item) => {
                if (action.redlineBaseDetails.redlineNumber == item.redlineNumber) {
                    item.rlmuStatus = action.redlineBaseDetails.rlmuStatus;
                }
            });
            return {
                ...state,
                dcnRfiRegisterDetails: {
                    ...state.dcnRfiRegisterDetails,
                    redlineBaseDetails: updatedRlmuStatus,
                },
                redlineRegister: {
                    ...state.redlineRegister,
                    items: state.redlineRegister.items.map((d, i) => {
                        if (d.id === action.redlineBaseDetails.id) {
                            return {
                                ...d,
                                rlmuStatus: action.redlineBaseDetails.rlmuStatus,
                            };
                        }
                        return d;
                    }),
                },
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateRlmuStatusError:
            return {
                ...state,
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDeleteWorkpackRequest:
            return {
                ...state,
                isLoading: true,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDeleteWorkpackSuccess:
            const workpacks = _.cloneDeep(state.dcnRfiRegisterDetails.workpacks);
            workpacks.forEach((item, index) => {
                if (action.workpackNumber == item.workpackNumber) {
                    workpacks.splice(index, 1);
                }
            });
            return {
                ...state,
                dcnRfiRegisterDetails: {
                    ...state.dcnRfiRegisterDetails,
                    workpacks: workpacks,
                },
                isLoading: false,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterAddCommentRequest:
            return {
                ...state,
                isLoading: true,
                isSavingComment: true,
            };
        case DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterAddCommentSuccess:
            if (action.zone === Zone.ChangeManagement) {
                return {
                    ...state,
                    isLoading: false,
                    dcnRfiRegister: {
                        ...state.dcnRfiRegister,
                        items: state.dcnRfiRegister.items.map((d, i) => {
                            if (d.id === action.id) {
                                return {
                                    ...d,
                                    comment: action.comment.description,
                                    commentId: action.comment.id
                                };
                            }
                            return d;
                        }),
                    },
                    dcnRfiRegisterDetails: state.dcnRfiRegisterDetails?.id === action.id
                        ? {
                            ...state.dcnRfiRegisterDetails,
                            comment: action.comment.description,
                            commentId: action.comment.id
                        }
                        : state.dcnRfiRegisterDetails,
                    isSavingComment: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    redlineRegister: {
                        ...state.redlineRegister,
                        items: state.redlineRegister.items.map((d, i) => {
                            if (d.id === action.id) {
                                return {
                                    ...d,
                                    comment: action.comment.description,
                                    commentId: action.comment.id,
                                };
                            }
                            return d;
                        }),
                    },
                    redlineRegisterDetails: state.redlineRegisterDetails?.id === action.id
                        ? {
                            ...state.redlineRegisterDetails,
                            comment: action.comment.description,
                            commentId: action.comment.id,
                        }
                        : state.redlineRegisterDetails,
                    isSavingComment: false,
                };
            }

        case DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDeleteWorkpackError:
            return {
                ...state,
                isLoading: false,
            };

        case dcnRfiRegisterDeleteCommentRequest.type:
            return {
                ...state,
                isLoading: true,
            };

        case dcnRfiRegisterDeleteCommentSuccess.type:
            return {
                ...state,
                dcnRfiRegisterDetails: {
                    ...state.dcnRfiRegisterDetails,
                    comment: action.scope === SubsystemScope.ChangeDocument
                        && ((state.dcnRfiRegister !== null && state.dcnRfiRegister.items.length === 0)
                            || state.dcnRfiRegisterDetails?.id === state.dcnRfiRegister.items.find(x => x.commentId === action.id)?.id)
                        ? action.lastComment.description
                        : state.dcnRfiRegisterDetails.comment,
                    commentId: action.scope === SubsystemScope.ChangeDocument
                        && ((state.dcnRfiRegister !== null && state.dcnRfiRegister.items.length === 0)
                            || state.dcnRfiRegisterDetails?.id === state.dcnRfiRegister.items.find(x => x.commentId === action.id)?.id)
                        ? action.lastComment?.id
                        : state.dcnRfiRegisterDetails.commentId
                },
                dcnRfiRegister: {
                    ...state.dcnRfiRegister,
                    items: state.dcnRfiRegister?.items.map(x =>
                        action.scope === SubsystemScope.ChangeDocument && x.commentId === action.id
                        ? {...x, comment: action.lastComment?.description, commentId: action.lastComment?.id }
                        : x)
                },
                redlineRegisterDetails: {
                    ...state.redlineRegisterDetails,
                    comment: action.scope === SubsystemScope.Redline
                        && ((state.redlineRegister !== null && state.redlineRegister.items.length === 0)
                            || state.redlineRegisterDetails?.id === state.redlineRegister.items.find(x => x.commentId === action.id)?.id)
                        ? action.lastComment?.description
                        : state.redlineRegisterDetails.comment,
                    commentId: action.scope === SubsystemScope.Redline
                        && ((state.redlineRegister !== null && state.redlineRegister.items.length === 0)
                            || state.redlineRegisterDetails?.id === state.redlineRegister.items.find(x => x.commentId === action.id)?.id)
                        ? action.lastComment?.id
                        : state.redlineRegisterDetails.commentId    
                },
                redlineRegister: {
                    ...state.redlineRegister,
                    items: state.redlineRegister?.items.map(x =>
                        action.scope === SubsystemScope.Redline && x.commentId === action.id
                        ? {...x, comment: action.lastComment?.description, commentId: action.lastComment?.id }
                        : x)
                },
                isLoading: false,
            };
        
        case dcnRfiRegisterDeleteCommentError.type:
            return {
                ...state,
                isLoading: false,
            };
        
        default:
            return state;
    }
}
