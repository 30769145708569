import { ManualUploadState } from './model';
import { ManualUploadActions, ManualUploadActionTypes } from './actions';
import { interval, Observable } from 'rxjs';
import { startWith, delay } from 'rxjs/operators';
import { Constants } from 'src/app/constants';

const initialState: ManualUploadState = {
    activatedRoute: '',
    uploadInProgress: false,
    interval: interval(Constants.logRefreshInterval).pipe(startWith(0)),
};

export function reducer(state = initialState, action: ManualUploadActions): ManualUploadState {
    switch (action.type) {
        case ManualUploadActionTypes.ManualUploadSetActivatedRoute: {
            return {
                ...state,
                activatedRoute: action.payload,
            };
        }
        case ManualUploadActionTypes.ManualUploadFilesUploadRequest: {
            return {
                ...state,
                uploadInProgress: true,
                interval: new Observable(),
            };
        }
        case ManualUploadActionTypes.ManualUploadFilesUploadSuccess: {
            return {
                ...state,
                uploadInProgress: false,
                interval: interval(Constants.logRefreshInterval).pipe(
                    startWith(0),
                    delay(Constants.delayAfterUploadInterval)
                ),
            };
        }
        case ManualUploadActionTypes.ManualUploadFilesUploadError: {
            return {
                ...state,
                uploadInProgress: false,
            };
        }
        default:
            return state;
    }
}
