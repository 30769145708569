import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, filter } from 'rxjs/operators';
import {
    ValidatorPerformanceScoreboardDataDTO,
    VALIDATOR_PERFORMANCE_SCOREBOARD_NUM_OF_PAST_WEEKS,
} from 'src/app/store/reports/change-validation-dashboard/model';
import {
    ChangeValidationDashboardValidatorPerformanceScoreboardRequest,
    ChangeValidationDashboardValidatorPerformanceScoreboardSetWeekRange,
} from 'src/app/store/reports/change-validation-dashboard/actions';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { getNextWeekStartDate } from 'src/app/extensions';

@Component({
    selector: 'app-validator-performance-scoreboard',
    templateUrl: './validator-performance-scoreboard.component.html',
    styleUrls: ['./validator-performance-scoreboard.component.scss'],
})
export class ValidatorPerformanceScoreboardComponent extends BaseComponent implements OnInit {
    validatorPerformanceScoreboardData$ = this.store.select(
        (state) => state.changeValidationDashboardState.validatorPerformanceScoreboard.data
    );
    isLoading$ = this.store.select(
        (state) => state.changeValidationDashboardState.validatorPerformanceScoreboard.isLoading
    );
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    startDate$ = this.store.select(
        (store) => store.changeValidationDashboardState.validatorPerformanceScoreboard.rangeDateFilter.startDate
    );
    currentDate = moment();
    @ViewChild('dateRangePicker') dateRangePicker: MatDatepicker<moment.Moment>;

    validatorPerformanceScoreboardData: ValidatorPerformanceScoreboardDataDTO[] = [];
    view: number[] = [1280, 400];
    colorScheme = {
        domain: ['#D4AF37', '#BEC2CB', '#cd7f32', '#006400', '#4c924c', '#000000'],
    };
    gradient: boolean = true;
    rangeDateFilter = getNextWeekStartDate().add(-VALIDATOR_PERFORMANCE_SCOREBOARD_NUM_OF_PAST_WEEKS, 'weeks');

    constructor(private store: Store<ApplicationState>) {
        super();
        this.dateRangePickerControl = new UntypedFormControl();
        this.dateRangePickerControlFormatted = new UntypedFormControl({ value: '', disabled: true });
    }

    ngOnInit() {
        this.validatorPerformanceScoreboardData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (!data.length) {
                this.store.dispatch(new ChangeValidationDashboardValidatorPerformanceScoreboardRequest());
            }

            this.validatorPerformanceScoreboardData = data;
        });

        this.startDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startDate) => !!startDate)
            )
            .subscribe((startDate) => {
                this.dateRangePickerControl.setValue(moment(startDate).toDate(), { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(startDate)));
            });

        this.dateRangePickerControl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((date: moment.Moment) => {
                this.store.dispatch(new ChangeValidationDashboardValidatorPerformanceScoreboardSetWeekRange(date));
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
            });
    }

    dateRangePickerFilter = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    private formatDateRange(startDate: moment.Moment) {
        const endDate = moment(startDate).add(VALIDATOR_PERFORMANCE_SCOREBOARD_NUM_OF_PAST_WEEKS, 'weeks');
        return `${moment(startDate).format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
    }
}
