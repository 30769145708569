import { Action } from '@ngrx/store';
import { UserDetail } from '../common.model';
import { CriticalRfiDTO, CriticalRfiDataPagination } from './model';
import { ChangeDocumentDTO } from '../subsystem-conflicts/model';

export enum CriticalRfiActionTypes {
    CriticalRfiFilterRequest = '[CriticalRFI] Filter Request',
    CriticalRfiFilterSuccess = '[CriticalRFI] Filter Success',
    CriticalRfiFilterError = '[CriticalRFI] Filter Error',
    CriticalRfiFilterPropertyUpdate = '[CriticalRFI] Filter Property Update',
    CriticalRfiFilterReset = '[CriticalRFI] Filter Reset',
    CriticalRfiAddCommentRequest = '[CriticalRFI] Add Comment Request',
    CriticalRfiAddCommentSuccess = '[CriticalRFI] Add Comment Success',
    CriticalRfiAddCommentError = '[CriticalRFI] Add Comment Error',
    CriticalRfiExportToExcelRequest = '[CriticalRFI] Export to Excel Request',
    CriticalRfiExportToExcelSuccess = '[CriticalRFI] Export to Excel Success',
    CriticalRfiExportToExcelError = '[CriticalRFI] Export to Excel Error',
    CriticalRfiSetLoaderState = '[CriticalRFI] Set Loader State',
    CriticalRfiSetMakeRequest = '[CriticalRFI] Set Make Request',
    CriticalRfiAddBulkCommentRequest = '[CriticalRFI] Add Bulk Comment Request',
    CriticalRfiAddBulkCommentSuccess = '[CriticalRFI] Add Bulk Comment Success',
    CriticalRfiAddBulkCommentError = '[CriticalRFI] Add Bulk Comment Error',
    CriticalRfiDeleteCommentRequest = '[CriticalRFI] Delete Comment Request',
    CriticalRfiDeleteCommentSuccess = '[CriticalRFI] Delete Comment Success',
    CriticalRfiDeleteCommentError = '[CriticalRFI] Delete Comment Error',
    CriticalRfiUpdateFieldImplementationContractorRequest = '[CriticalRFI] Update Field Implementation Contractor Request',
    CriticalRfiFieldImplementationContractorSuccess = '[CriticalRFI] Field Implementation Contractor Success',
    CriticalRfiFieldImplementationContractorError = '[CriticalRFI] Field Implementation Contractor Error',
    CriticalRfiUpdateSubPriorityRequest = '[CriticalRFI] Update SubPriority Request',
    CriticalRfiUpdateSubPrioritySuccess = '[CriticalRFI] Update SubPriority Success',
    CriticalRfiUpdateSubPriorityError = '[CriticalRFI] Update SubPriority Error',
    CriticalRfiUpdateManualPriorityRequest = '[CriticalRFI] Update ManualPriority Request',
    CriticalRfiUpdateManualPrioritySuccess = '[CriticalRFI] Update ManualPriority Success',
    CriticalRfiUpdateManualPriorityError = '[CriticalRFI] Update ManualPriority Error',
    CriticalRfiAddExpectedClosureDateRequest = '[CriticalRFI] Add Expected Time of Completion Request',
    CriticalRfiAddExpectedClosureDateSuccess = '[CriticalRFI] Add Expected Time of Completion Success',
    CriticalRfiAddExpectedClosureDateError = '[CriticalRFI] Add Expected Time of Completion Error',
}

export class CriticalRfiUpdateSubPriorityRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiUpdateSubPriorityRequest;
    constructor(public payload: { subPriority: CriticalRfiDTO }) {}
}

export class CriticalRfiUpdateSubPrioritySuccess implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiUpdateSubPrioritySuccess;
    constructor(public payload: { subPriority: CriticalRfiDTO }) {}
}

export class CriticalRfiUpdateSubPriorityError implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiUpdateSubPriorityError;
    constructor(public payload: string) {}
}

export class CriticalRfiUpdateManualPriorityRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiUpdateManualPriorityRequest;
    constructor(public payload: { manualPriority: CriticalRfiDTO }) {}
}

export class CriticalRfiUpdateManualPrioritySuccess implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiUpdateManualPrioritySuccess;
    constructor(public payload: { manualPriority: CriticalRfiDTO }) {}
}

export class CriticalRfiUpdateManualPriorityError implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiUpdateManualPriorityError;
    constructor(public payload: string) {}
}
export class CriticalRfiFilterRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiFilterRequest;
}

export class CriticalRfiSetMakeRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiSetMakeRequest;
}

export class CriticalRfiFilterSuccess implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiFilterSuccess;

    constructor(public payload: CriticalRfiDataPagination) {}
}

export class CriticalRfiFilterError implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiFilterError;

    constructor(public payload: string) {}
}

export class CriticalRfiFilterPropertyUpdate implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class CriticalRfiFilterReset implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiFilterReset;
}

export class CriticalRfiAddCommentRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiAddCommentRequest;

    constructor(public payload: { id: number; description: string; mentions: UserDetail[]; type: string}) {}
}

export class CriticalRfiAddCommentSuccess implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiAddCommentSuccess;

    constructor(public payload: { id: number; description: string; mentions: UserDetail[] ;  type: string }) {}
}

export class CriticalRfiAddCommentError implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiAddCommentError;

    constructor(public payload: string) {}
}

export class CriticalRfiDeleteCommentRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiDeleteCommentRequest;

    constructor(public payload: { id: number, type: string }) {}
}

export class CriticalRfiDeleteCommentSuccess implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiDeleteCommentSuccess;

    constructor(public payload: { id: number }) {}
}

export class CriticalRfiDeleteCommentError implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiDeleteCommentError;

    constructor(public payload: string) {}
}

export class CriticalRfiAddBulkCommentRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiAddBulkCommentRequest;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class CriticalRfiAddBulkCommentSuccess implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiAddBulkCommentSuccess;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class CriticalRfiAddBulkCommentError implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiAddBulkCommentError;

    constructor(public payload: string) {}
}

export class CriticalRfiExportToExcelRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiExportToExcelRequest;
}

export class CriticalRfiExportToExcelSuccess implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class CriticalRfiExportToExcelError implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiExportToExcelError;

    constructor(public payload: string) {}
}

export class CriticalRfiUpdateFieldImplementationContractorRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiUpdateFieldImplementationContractorRequest;

    constructor(public payload: { id: number; fieldImplementationContractor: string }) {}
}

export class CriticalRfiFieldImplementationContractorSuccess implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiFieldImplementationContractorSuccess;

    constructor(public payload: { id: number; fieldImplementationContractor: string }) {}
}

export class CriticalRfiFieldImplementationContractorError implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiFieldImplementationContractorError;

    constructor(public payload: string) {}
}

export class CriticalRfiAddExpectedClosureDateRequest implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiAddExpectedClosureDateRequest;

    constructor(public payload: {criticalRfi: CriticalRfiDTO, expectedClosureDate: moment.Moment}) {}
}

export class CriticalRfiAddExpectedClosureDateSuccess implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiAddExpectedClosureDateSuccess;

    constructor(public payload: { criticalRfi: CriticalRfiDTO}, public changeDocId = 0) {}
}
export class CriticalRfiAddExpectedClosureDateError implements Action {
    readonly type = CriticalRfiActionTypes.CriticalRfiAddExpectedClosureDateError;

    constructor(public payload: string) {}
}

export type CriticalRfiActions =
    | CriticalRfiUpdateSubPriorityRequest
    | CriticalRfiUpdateSubPrioritySuccess
    | CriticalRfiUpdateSubPriorityError
    | CriticalRfiUpdateManualPriorityRequest
    | CriticalRfiUpdateManualPrioritySuccess
    | CriticalRfiUpdateManualPriorityError
    | CriticalRfiFilterRequest
    | CriticalRfiFilterSuccess
    | CriticalRfiFilterError
    | CriticalRfiFilterPropertyUpdate
    | CriticalRfiFilterReset
    | CriticalRfiAddCommentRequest
    | CriticalRfiAddCommentSuccess
    | CriticalRfiAddCommentError
    | CriticalRfiAddBulkCommentRequest
    | CriticalRfiAddBulkCommentSuccess
    | CriticalRfiAddBulkCommentError
    | CriticalRfiExportToExcelRequest
    | CriticalRfiExportToExcelSuccess
    | CriticalRfiExportToExcelError
    | CriticalRfiSetMakeRequest
    | CriticalRfiDeleteCommentRequest
    | CriticalRfiDeleteCommentSuccess
    | CriticalRfiDeleteCommentError
    | CriticalRfiUpdateFieldImplementationContractorRequest
    | CriticalRfiFieldImplementationContractorSuccess
    | CriticalRfiFieldImplementationContractorError
    | CriticalRfiAddExpectedClosureDateRequest
    | CriticalRfiAddExpectedClosureDateSuccess
    | CriticalRfiAddExpectedClosureDateError;
    
