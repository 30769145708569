import { Component, OnInit, OnDestroy, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/shared/toast.service';
import { UserDetail } from 'src/app/store/common.model';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { takeWhile } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { appConfig } from 'src/app/app.config';
import { EmailService } from 'src/app/services/shared/email.service';

@Component({
    selector: 'app-comment-popup',
    templateUrl: './comment-popup.component.html',
    styleUrls: ['./comment-popup.component.scss'],
})
export class CommentPopupComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    comment: string;
    title: string;
    action: (value: string, mentions: UserDetail[], bulk: boolean) => void;
    url: string;
    users: UserDetail[] = [];
    mentionedUsers: UserDetail[] = [];
    mentionConfig = {
        labelKey: 'name',
        triggerChar: '@',
        maxItems: 30,
        mentionSelect: (item: UserDetail) => {
            this.mentionedUsers = [...this.mentionedUsers, item];
            return `@${item.name}`;
        },
    };
    filteredCount = 0;
    emailButtonVisible = true;

    @ViewChild('comment') input: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CommentPopupComponent>,
        private toastService: ToastService,
        private lookupSvc: LookupService,
        private authService: MsalService,
        private emailService: EmailService
    ) {
        super();

        this.action = data.action;
        this.comment = data.comment || '';
        this.mentionedUsers = data.mentions || [];
        this.url = data.url || '';
        this.filteredCount = data.filteredCount || 0;
        this.emailButtonVisible = data.emailButtonVisible === false ? false : true;
        this.title = data.title || 'comment';
    }

    ngAfterViewInit() {
        this.input.nativeElement.innerText = this.comment;
    }

    ngOnInit(): void {
        this.lookupSvc
            .searchUsers('', null, null)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((users) => (this.users = users));

        if(this.title !== 'comment'){
            this.emailButtonVisible= false;
        }
    }

    close() {
        this.dialogRef.close();
    }

    onSubmit(bulk: boolean = false) {
        this.comment = this.input.nativeElement.innerText;
        if (this.comment == null || this.comment.trim().length === 0) {
            if(this.title == 'comment'){
                this.toastService.Info('Description cannot be empty.');
            }
            else{
                this.toastService.Info(this.title +' cannot be empty.');
            }
        } else {
            this.mentionedUsers = this.mentionedUsers.filter(
                (value, index, self) => self.map((s) => s.name).indexOf(value.name) === index
            );
            this.mentionedUsers.forEach((element, index) => {
                if (this.comment.indexOf(`@${element.name}`) === -1) {
                    this.mentionedUsers.splice(index, 1);
                }
            });
            this.action(this.comment, this.mentionedUsers, bulk);
            this.dialogRef.close();
        }
    }

    onSendEmail() {
        const recipients = this.mentionedUsers.map((s) => s.email).join(';');
        const account = this.authService.instance.getActiveAccount();
        let options = {
            subject: 'SCMT Action',
            body: `${account.name} has tagged you in SCMT\n\nPlease see the link below to review your latest tag:\n${appConfig.redirectUri}/${this.url}\n\nPlease see below link to SCMT My Page to see all your tags:\n${appConfig.redirectUri}/mypage`,
        };

        const mailto = document.createElement('a');
        document.body.appendChild(mailto);
        mailto.setAttribute('style', 'display: none');
        const url = this.emailService.getMailToURI(recipients, options);
        mailto.href = url;
        mailto.click();
        mailto.remove();
    }

    onPaste(event: ClipboardEvent) {
        this.users.forEach((user) => {
            if (event.clipboardData.getData('text').indexOf(`@${user.name}`) > -1) {
                this.mentionedUsers.push(user);
            }
        });
    }
}
