import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { ITRConstraintsContactsDeleteFilter, ITRConstraintsContactsDeletePagination, ITRConstraintsContactsState } from './model';
import { ITRConstraintsContactsActions, ITRConstraintsContactsActionTypes } from './actions';

const initialState: ITRConstraintsContactsState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new ITRConstraintsContactsDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new ITRConstraintsContactsDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: ITRConstraintsContactsActions): ITRConstraintsContactsState {
    switch (action.type) {
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        // case ITRConstraintsContactsActionTypes.ITRConstraintsContactsSetStatusAndStartDate: {
        //     return {
        //         ...state,
        //         uploadLogStatus: action.payload.status,
        //         uploadLogStartDate: action.payload.startDate,
        //     };
        // }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new ITRConstraintsContactsDeletePagination(),
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsTemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case ITRConstraintsContactsActionTypes.ITRConstraintsContactsAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'ConstraintsContacts';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

