import { ITRExcludeFilterRequestDTO, SubsystemExcludeFilterRequestDTO } from '../common.model';
import { PlanningConfigurationActions, PlanningConfigurationActionTypes } from './actions';
import { PlanningExcludeFilterConfigurationState } from './model';

const initialState: PlanningExcludeFilterConfigurationState = {
    isLoading: false,
    subsystemFilter: new SubsystemExcludeFilterRequestDTO(),
    subsystemData: null,
    itrFilter: new ITRExcludeFilterRequestDTO(),
    itrData: null,
};

export function reducer(
    state: PlanningExcludeFilterConfigurationState = initialState,
    action: PlanningConfigurationActions
): PlanningExcludeFilterConfigurationState {
    switch (action.type) {
        case PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFilterPropertyUpdate: {
            return {
                ...state,
                subsystemFilter: {
                    ...state.subsystemFilter,
                    ...action.payload,
                },
            };
        }
        case PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFilterUpdate: {
            return {
                ...state,
                subsystemFilter: action.payload,
            };
        }
        case PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFilterReset: {
            return {
                ...state,
                subsystemFilter: new SubsystemExcludeFilterRequestDTO(),
            };
        }
        case PlanningConfigurationActionTypes.PlanningConfigSubsystemAssignExcludeFiltersRequest:
        case PlanningConfigurationActionTypes.PlanningConfigITRExcludeFiltersRequest:
        case PlanningConfigurationActionTypes.PlanningConfigITRAssignExcludeFiltersRequest:
        case PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFiltersRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFiltersSuccess: {
            return {
                ...state,
                subsystemData: action.payload,
                isLoading: false,
            };
        }
        case PlanningConfigurationActionTypes.PlanningConfigITRExcludeFiltersError:
        case PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFiltersError:
        case PlanningConfigurationActionTypes.PlanningConfigITRAssignExcludeFiltersSuccess:
        case PlanningConfigurationActionTypes.PlanningConfigITRAssignExcludeFiltersError: 
        case PlanningConfigurationActionTypes.PlanningConfigSubsystemAssignExcludeFiltersSuccess:
        case PlanningConfigurationActionTypes.PlanningConfigSubsystemAssignExcludeFiltersError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case PlanningConfigurationActionTypes.PlanningConfigITRExcludeFilterPropertyUpdate: {
            return {
                ...state,
                itrFilter: {
                    ...state.itrFilter,
                    ...action.payload,
                },
            };
        }
        case PlanningConfigurationActionTypes.PlanningConfigITRExcludeFilterUpdate: {
            return {
                ...state,
                itrFilter: action.payload,
            };
        }
        case PlanningConfigurationActionTypes.PlanningConfigITRExcludeFilterReset: {
            return {
                ...state,
                itrFilter: new ITRExcludeFilterRequestDTO(),
            };
        }
        case PlanningConfigurationActionTypes.PlanningConfigITRExcludeFiltersSuccess: {
            return {
                ...state,
                itrData: action.payload,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
