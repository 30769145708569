import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { ContractorDTO } from 'src/app/models/contractor-dto';
import { ContractorsMaintenanceService } from 'src/app/services/api/webapi-services/contractor-maintenance.service';

@Component({
    selector: 'app-add-contractor-popup',
    templateUrl: './add-contractor-popup.component.html',
    styleUrls: ['./add-contractor-popup.component.scss'],
})
export class AddContractorPopupComponent extends BaseComponent {
    loading: boolean;
    addContractorForm: UntypedFormGroup;
    projectTeamNames: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<AddContractorPopupComponent>,
        private toastService: ToastService,
        private dataMaintenanceService: ContractorsMaintenanceService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        super();

        this.projectTeamNames = data.projectTeamNames;

        this.addContractorForm = new UntypedFormGroup({
            projectTeamNames: new UntypedFormControl(this.projectTeamNames[0], Validators.required),
            contractNo: new UntypedFormControl('', Validators.required),
            contract: new UntypedFormControl('', Validators.required),
        });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        const form = new ContractorDTO();

        form.projectTeamName = this.addContractorForm.controls.projectTeamNames.value;
        form.contractNo = this.addContractorForm.controls.contractNo.value;
        form.contract = this.addContractorForm.controls.contract.value;

        this.dataMaintenanceService.add(form).subscribe(
            (data) => {
                this.loading = false;
                this.toastService.Success(`The contractor ${form.contractNo} has been added.`);
                this.dialogRef.close({ success: true });
            },
            (error) => {
                this.loading = false;

                if (error.status === 409) {
                    this.toastService.Error('Contract No entered already exists in SCMT.');
                } else {
                    this.toastService.Error(
                        'An error occurred while adding a contractor. Please contact a Program Administrator.'
                    );
                }
            }
        );
    }
}
