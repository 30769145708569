import { SubsystemScope, WeeklyPlanActivityType } from 'src/app/enums';
import { OrderDirection, UserDetail } from '../common.model';

export interface ScheduleActivityITRState {
    dataPagination: ScheduleActivityITRDataPagination;
    isLoading: boolean;
    scope: ScheduleActivityITRScope;
    filter: ScheduleActivityITRFilter;
    weeklyPlanITRsToGo: number[];
    weeklyPlanSelectedITRs: number[];
    weeklyPlanOccupiedITRs: number[];
}

export class ScheduleActivityITRDataPagination {
    items: ScheduleActivityITRDTO[] = [];
    totalCount = 0;
}

export class ScheduleActivityITRDTO {
    id: number = null;
    activityName = '';
    subsystem = '';
    subsystemName = '';
    tagNumber = '';
    tagType = '';
    qvdNumber = '';
    qvdDescription = '';
    contractor = '';
    status = '';
    comments = '';
    discipline = '';
    docType = '';
    mentions: UserDetail[];
    barcode = '';
    scope: SubsystemScope = null;
    additionToPlan = false;
    isItrInCurrentWeek = false;
    constraintType = '';
}

export class ScheduleActivityITRFilter {
    take = 25;
    page = 0;
    sortBy: string = 'qvdNumber';
    direction: OrderDirection = OrderDirection.Desc;
    scope: ScheduleActivityITRScope;
    activityId: string;
    saveWeeklyPlan: boolean;
    activityDiscipline: string;
    additionToPlan: boolean;
    activityType: WeeklyPlanActivityType;

    showHideITRColumns = new ShowHideColumns();
    constructor() {
        this.scope = ScheduleActivityITRScope.ToGo;
    }
}

export enum ScheduleActivityITRScope {
    ToGo,
    InWeek,
    Committed,
    CommittedAchieved,
    CommittedNotAchieved,
    AchievedNotCommitted,
}

export class ShowHideColumns {
    showSubsys = true;
    showSubsysName = false;
    showActivityName = false;
    showDiscipline = true;
    showQVD = true;
    showTagType = false;
    showDocType = true;
    showStatusDate = false;
    showBarcode = false;
    showPreCommDates = false;
    showAdditionToPlan = false;
    showConstraintFlag = false;
    showWorkArea = false;
    showConstraintType = false;
    showStagedStartUpPriority = false;
    showManHour = false;

    showITRConstraintDueDate = false;
    showITRConstraintRaisedByUser = false;
    showITRConstraintRaisedDate = false;
    showITRConstraintResponsibleUser = false;
    showITRConstraintTypeName = false;
    showFMRorPOETADate = false;

}
