import { Injectable } from '@angular/core';
import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    ExportPageActionTypes,
    ExportPageFilterSuccess,
    ExportPageFilterError,
    ExportPageFileSuccess,
    ExportPageFileRequest,
    ExportPageFilterRequest,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { ExportPageService } from 'src/app/services/api/webapi-services/export-page.service';

@Injectable()
export class ExportPageEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private ExportPageService: ExportPageService,
        private toastService: ToastService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType(ExportPageActionTypes.ExportPageFilterRequest),
        withLatestFrom(this.store.select((store) => store.ExportPageState.filter)),
        mergeMap(([, filter]) => {
            return this.ExportPageService.getExportPageData().pipe(
                map((dataPagination) => {                    
                    return new ExportPageFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Export Page data. Please contact Program Administrator.'
                    );
                    return of(new ExportPageFilterError(error));
                })
            );
        })
    );

    @Effect()
    fileRequest$ = this.actions$.pipe(
        ofType(ExportPageActionTypes.ExportPageFileRequest),
        mergeMap(( {payload} : any) => {
            //console.log('payload:', payload)
            return this.ExportPageService.getExportPageFile(payload.link).pipe(
                map(
                    (data) => {
                        const blob = new Blob([data], {
                            type: 'application/octet-stream',
                        });            
                        saveAs(blob, payload.filename);
                        return new ExportPageFileSuccess();
                    }

                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Export Page data. Please contact Program Administrator.'
                    );
                    return of(new ExportPageFilterError(error));
                })
            );
        })
    );

    
}
