import { McMilestone, Gooc, RFO, Contractor, StagedStartUpPriority } from '../../common.model';
import { CommentDetailDTO } from '../../detailed-status/model';
import { IcicleDTO } from '../icicle/model';
import { PlanningLookaheadState } from '../planning/model';
import { MCScheduleState, DashboardSkylineState } from '../pre-mc/model';
import { SubsystemMCDashboardDTO } from '../subsystem-mc/model';

export class TotalCompleteDataDTO {
    areaBreakdown: string;
    category: string;
    total: number;
    complete: number;
    remaining: number;
    exceptions: number;
    completePercentage: number;
    sun: number;
    mon: number;
    tue: number;
    wed: number;
    thu: number;
    fri: number;
    sat: number;
    weeklyProgress: number;
    disciplineBreakdown: TotalCompleteDataDTO[];
    categoryBreakdown: TotalCompleteDataDTO[];
    expanded: boolean = false;
}

export class RundownDataDTO {
    name: string;
    series: {
        name: string;
        value: number;
    }[] = [];
}

export interface MilestoneDashboardState {
    icicle: IcicleDTO[];
    totalCompleteData: TotalCompleteDataDTO[];
    rundownData: RundownDataDTO[];
    isIcicleLoading: boolean;
    isLoadingTotalComplete: boolean;
    isRundownLoading: boolean;
    filters: {
        rfo: MilestoneDashboardFilter;
        mc: MilestoneDashboardFilter;
    };
    dashboardType: MilestoneDashboardType;
    icicleType: string;
    monthViewEnabled: boolean;
    startDate: string;
    comments: MilestoneCommentDTO[];
    isCommentLoading: boolean;
    mcMilestoneSkyline: DashboardSkylineState;
    mcMilestoneSchedule: MCScheduleState;
    lookahead: PlanningLookaheadState;
    subsystemTable: SubsystemMCDashboardDTO[];
    isSubsystemTableLoading: boolean;
    rfsuMilestoneSkyline: DashboardSkylineState;
    rfsulookahead: PlanningLookaheadState;
}

export class MilestoneDashboardFilter {
    public constructor(init?: Partial<MilestoneDashboardFilter>) {
        Object.assign(this, init);
    }
    mcMilestones: McMilestone[] = [];
    rfos: RFO[] = [];
    projectTeamNames: string[] = [];
    goocs: Gooc[] = [];
    subsystems: { id: string }[] = [];
    contractors: Contractor[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    systemOwner: string[] = [];
    mcEngineer: string[] = [];
    scManager: string[] = [];
}

export enum MilestoneDashboardType {
    MC,
    RFO,
    AreaAll,
    NPW,
}

export enum MilestoneCommentType {
    Progress,
    Forecast,
    Issues,
}

export class MilestoneCommentDTO {
    progress: CommentDetailDTO[];
    forecast: CommentDetailDTO[];
    issues: CommentDetailDTO[];
    type: string;
}
