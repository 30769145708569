import { SystemisedDrawingState, SystemisedDrawingFilter, SystemisedDrawingDataPagination } from './model';
import { SystemisedDrawingActions, SystemisedDrawingActionTypes } from './actions';

const initialState: SystemisedDrawingState = {
    dataPagination: new SystemisedDrawingDataPagination(),
    isLoading: false,
    filter: new SystemisedDrawingFilter(),
};

export function reducer(state: SystemisedDrawingState = initialState, action: SystemisedDrawingActions) {
    switch (action.type) {
        case SystemisedDrawingActionTypes.SystemisedDrawingFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case SystemisedDrawingActionTypes.SystemisedDrawingFilterSuccess: {
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        }
        case SystemisedDrawingActionTypes.SystemisedDrawingFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SystemisedDrawingActionTypes.SystemisedDrawingFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(action.payload.value)
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case SystemisedDrawingActionTypes.SystemisedDrawingFilterReset: {
            const newFilter = new SystemisedDrawingFilter();
            newFilter.sortBy = state.filter.sortBy;
            newFilter.direction = state.filter.direction;
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case SystemisedDrawingActionTypes.SystemisedDrawingExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case SystemisedDrawingActionTypes.SystemisedDrawingExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SystemisedDrawingActionTypes.SystemisedDrawingExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
