<main class="main">
    <a class="navigator" [routerLink]="['/weeklyplanning/session', projectTeamName, planningType]">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            view weekly commitments: {{ startDate | date: 'MMM d' | lowercase }} -
            {{ endDate | date: 'MMM d' | lowercase }}
        </div>
        <div class="buttons-container flex">
            <button
                [routerLink]="['/weeklyplanning/session', projectTeamName, planningType]"
                class="sct-button sct-button-white back-to-session margin-right20"
            >
                back to weekly planning session
            </button>
            <button [disabled]="submitButtonDisabled" (click)="submit()" class="sct-button sct-button-light">
                {{isFilterApplied ? 'submit only selected' : 'submit'}}
            </button>
        </div>
    </div>
    <div class="filter-container" *ngIf="planningType !== 'rfo'">
        <app-weekly-planning-session-filter 
            [projectTeamName]="projectTeamName" 
            [applyToWeeklyPlanningCommitments]="true" 
            [applyToWeeklyTopHeaderData]="true"
            [expanded]="false">
        </app-weekly-planning-session-filter>
    </div>
    <app-top-header-table
        #topHeader
        *ngIf="planningType !== 'rfo'"
        [startDate]="startDate"
        [projectTeamName]="projectTeamName"
        [planningType]="planningType"
        [loadDataOnInint]="false"
    ></app-top-header-table>
    <div class="weekly-commitments-container">
        <h4>planned & committed</h4>
        <table mat-table [dataSource]="plannedAndCommitted">
            <ng-container matColumnDef="subsystem">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    Subsystem
                </th>
                <td mat-cell *matCellDef="let element">{{ element.subsystem }}</td>
            </ng-container>
            <ng-container matColumnDef="rfo">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFO
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfo }}</td>
            </ng-container>
            <ng-container matColumnDef="subsystemName">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    Subsystem Name
                </th>
                <td mat-cell *matCellDef="let element">{{ element.subsystemName }}</td>
            </ng-container>
            <ng-container matColumnDef="rfoName">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFO Name
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfoName }}</td>
            </ng-container>
            <ng-container matColumnDef="mcPlan">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    MC Plan
                </th>
                <td mat-cell *matCellDef="let element">{{ element.mcPlan | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="rfsuPlan">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFSU Plan
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfsuPlan | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="rfoPlan">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFO Plan
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfoPlan | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="mcForecast">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    MC Forecast
                </th>
                <td mat-cell *matCellDef="let element">{{ element.mcForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="rfsuForecast">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFSU Forecast
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfsuForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="rfoForecast">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFO Forecast
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfoForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="aqvd">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    A-QVD
                </th>
                <td mat-cell *matCellDef="let element">{{ element.aqvd }}</td>
            </ng-container>
            <ng-container matColumnDef="bitr">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    B-ITR
                </th>
                <td mat-cell *matCellDef="let element">{{ element.bitr }}</td>
            </ng-container>
            <ng-container matColumnDef="citr">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    C-ITR
                </th>
                <td mat-cell *matCellDef="let element">{{ element.citr }}</td>
            </ng-container>
            <ng-container matColumnDef="apli">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    A-PLI
                </th>
                <td mat-cell *matCellDef="let element">{{ element.apli }}</td>
            </ng-container>
            <ng-container matColumnDef="bpli">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    B-PLI
                </th>
                <td mat-cell *matCellDef="let element">{{ element.bpli }}</td>
            </ng-container>
            <ng-container matColumnDef="cpli">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    C-PLI
                </th>
                <td mat-cell *matCellDef="let element">{{ element.cpli }}</td>
            </ng-container>
            <ng-container matColumnDef="npw">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    NPW
                </th>
                <td mat-cell *matCellDef="let element">{{ element.npw }}</td>
            </ng-container>
            <ng-container matColumnDef="redline">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    Redline
                </th>
                <td mat-cell *matCellDef="let element">{{ element.redline }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div class="status">Committed</div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <h4>committed subsystems additional to plan</h4>
        <table mat-table [dataSource]="additionalCommitments">
            <ng-container matColumnDef="subsystem">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    Subsystem
                </th>
                <td mat-cell *matCellDef="let element">{{ element.subsystem }}</td>
            </ng-container>
            <ng-container matColumnDef="rfo">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFO
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfo }}</td>
            </ng-container>
            <ng-container matColumnDef="subsystemName">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    Subsystem Name
                </th>
                <td mat-cell *matCellDef="let element">{{ element.subsystemName }}</td>
            </ng-container>
            <ng-container matColumnDef="rfoName">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFO Name
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfoName }}</td>
            </ng-container>
            <ng-container matColumnDef="mcPlan">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    MC Plan
                </th>
                <td mat-cell *matCellDef="let element">{{ element.mcPlan | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="rfsuPlan">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFSU Plan
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfsuPlan | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="rfoPlan">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFO Plan
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfoPlan | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="mcForecast">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    MC Forecast
                </th>
                <td mat-cell *matCellDef="let element">{{ element.mcForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="rfsuForecast">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFSU Forecast
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfsuForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="rfoForecast">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    RFO Forecast
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfoForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="aqvd">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    A-QVD
                </th>
                <td mat-cell *matCellDef="let element">{{ element.aqvd }}</td>
            </ng-container>
            <ng-container matColumnDef="bitr">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    B-ITR
                </th>
                <td mat-cell *matCellDef="let element">{{ element.bitr }}</td>
            </ng-container>
            <ng-container matColumnDef="citr">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    C-ITR
                </th>
                <td mat-cell *matCellDef="let element">{{ element.citr }}</td>
            </ng-container>
            <ng-container matColumnDef="apli">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    A-PLI
                </th>
                <td mat-cell *matCellDef="let element">{{ element.apli }}</td>
            </ng-container>
            <ng-container matColumnDef="bpli">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    B-PLI
                </th>
                <td mat-cell *matCellDef="let element">{{ element.bpli }}</td>
            </ng-container>
            <ng-container matColumnDef="cpli">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    C-PLI
                </th>
                <td mat-cell *matCellDef="let element">{{ element.cpli }}</td>
            </ng-container>
            <ng-container matColumnDef="npw">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    NPW
                </th>
                <td mat-cell *matCellDef="let element">{{ element.npw }}</td>
            </ng-container>
            <ng-container matColumnDef="redline">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    Redline
                </th>
                <td mat-cell *matCellDef="let element">{{ element.redline }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div class="status">Committed</div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="isLoading" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
</main>
