import { RFOImportState, RFODeltaPagination, RFODeltaFilter, RFODeletePagination, RFODeleteFilter, RFODeltaDTO } from './model';
import { RFOActions, RFOActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: RFOImportState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deltaData: new RFODeltaPagination(),
    isDeltaDataLoading: false,
    deleteData: new RFODeletePagination(),
    isDeleteDataLoading: false,
    deltaFilter: new RFODeltaFilter(),
    deleteFilter: new RFODeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: RFOActions) {
    switch (action.type) {
        case RFOActionTypes.RFOUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case RFOActionTypes.RFOUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case RFOActionTypes.RFOUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case RFOActionTypes.RFOPatchAllDeltaRequest:
        case RFOActionTypes.RFODeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case RFOActionTypes.RFODeltaSuccess: {
            return {
                ...state,
                deltaData: action.payload,
                isDeltaDataLoading: false,
            };
        }
        case RFOActionTypes.RFOPatchDeltaSuccess:
        case RFOActionTypes.RFOPatchDeltaError:
        case RFOActionTypes.RFOPatchAllDeltaError:
        case RFOActionTypes.RFODeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case RFOActionTypes.RFOSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case RFOActionTypes.RFODeltaFilterPropertyUpdate: {
            state.deltaFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case RFOActionTypes.RFODeleteFilterPropertyUpdate: {
            state.deleteFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case RFOActionTypes.RFOPatchDeltaRequest: {
            state.deltaData.items.find((d) => d.rfoId === action.payload.RFOId).deltaState = action.payload.deltaState;
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case RFOActionTypes.RFOPatchAllDeltaSuccess: {
            const delta = state.deltaData.items.map(
                (i) => ({ ...i, deltaState: action.payload.deltaState } as RFODeltaDTO)
            );
            return {
                ...state,
                deltaData: {
                    ...state.deltaData,
                    items: delta
                },
                isDeltaDataLoading: false,
            };
        }
        case RFOActionTypes.RFODeleteDataRequest:
        case RFOActionTypes.RFOPatchDeleteAllRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case RFOActionTypes.RFOPatchDeleteAllRecordsSuccess: {
            state.deleteData.items.forEach((i) => (i.deleteState = action.payload.deleteState));
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case RFOActionTypes.RFODeleteDataError:
        case RFOActionTypes.RFOPatchDeleteRecordSuccess:
        case RFOActionTypes.RFOPatchDeleteRecordError:
        case RFOActionTypes.RFOPatchDeleteAllRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case RFOActionTypes.RFOValidateDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case RFOActionTypes.RFOValidateDeltaSuccess: {
            return {
                ...state,
                deltaData: new RFODeltaPagination(),
                deleteData: new RFODeletePagination(),
                isDeltaDataLoading: false,
                isDeleteDataLoading: false,
            };
        }
        case RFOActionTypes.RFOValidateDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
                isDeleteDataLoading: false,
            };
        }
        case RFOActionTypes.RFOFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deltaData: new RFODeltaPagination(),
                deleteData: new RFODeletePagination(),
            };
        }
        case RFOActionTypes.RFOClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case RFOActionTypes.RFODownloadOutputDataRequest:
        case RFOActionTypes.RFOTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case RFOActionTypes.RFOTemplateFileError:
        case RFOActionTypes.RFODownloadOutputDataSuccess:
        case RFOActionTypes.RFODownloadOutputDataError:
        case RFOActionTypes.RFOTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case RFOActionTypes.RFODeleteDataSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case RFOActionTypes.RFOPatchDeleteRecordRequest: {
            state.deleteData.items.find((d) => d.rfoId === action.payload.rfoId).deleteState =
                action.payload.deleteState;
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case RFOActionTypes.RFOValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case RFOActionTypes.RFOAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'RFO';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}
