import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfiniteScrollDirective } from 'src/app/directives/infinite-scroll.directive';
import { MultipleItemsSelectorComponent } from './components/multiple-items-selector/multiple-items-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { HeaderDateFilterComponent } from './components/filter/header-date-filter/header-date-filter.component';
import { AscDescOrderComponent } from './components/filter/asc-desc-order/asc-desc-order.component';
import { HeaderChecklistFilterComponent } from './components/filter/header-checklist-filter/header-checklist-filter.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommentPopupComponent } from './components/comment-popup/comment-popup.component';
import { DeleteConfirmationPopupComponent } from './components/delete-confirmation-popup/delete-confirmation-popup.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { RangeDatePipe } from 'src/app/pipes/date-range.pipe';
import { MentionModule } from 'angular-mentions';
import { SubsystemSelectorComponent } from './components/subsystem-selector/subsystem-selector.component';
import { HeaderNumericFilterComponent } from './components/filter/header-numeric-filter/header-numeric-filter.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';

@NgModule({
    declarations: [
        LoadingIndicatorComponent,
        InfiniteScrollDirective,
        MultipleItemsSelectorComponent,
        HeaderChecklistFilterComponent,
        HeaderDateFilterComponent,
        AscDescOrderComponent,
        CommentPopupComponent,
        DeleteConfirmationPopupComponent,
        InformationDialogComponent,
        RangeDatePipe,
        SubsystemSelectorComponent,
        HeaderNumericFilterComponent,
        MultiselectComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTooltipModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatDatepickerModule,
        MatMenuModule,
        MatRadioModule,
        MatTabsModule,
        MatListModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
        MentionModule,
    ],
    exports: [
        LoadingIndicatorComponent,
        MultipleItemsSelectorComponent,
        InformationDialogComponent,
        RangeDatePipe,
        SubsystemSelectorComponent,
        MultiselectComponent
    ],
})
export class SharedModule {}
