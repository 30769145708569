import { createAction, props } from '@ngrx/store';
import { DataLog } from './model';

export const importStatusReceived = createAction(
    '[Imports] Import Status Received',
    props<{ importLogs: DataLog[] }>()
);
export const importStatusDisplayed = createAction(
    '[Imports] Import Status Displayed',
    props<{ notifications: DataLog[], toastId: number }>()
);
export const closeImportStatusToasts = createAction(
    '[Imports] Close Import Status Toasts'
);
export const connectionSet = createAction(
    '[Imports] SignalR Connection Set',
    props<{ payload: boolean }>()
);
