<mat-tab-group
    [(selectedIndex)]="registerType"
    #tabGroup
    [animationDuration]="1000"
    (selectedTabChange)="setTabIndex($event)"
    >
    <mat-tab [disabled]="isRedlineFilterApplied">
        <ng-template mat-tab-label>
            <span>DCN/RFI</span>
        </ng-template>
        <app-dcn-rfi-filter
            [filterForm]="filterForm"
        ></app-dcn-rfi-filter>
        <div class="search-btn-container">
            <button type="button" (click)="clearFilters($event)" class="sct-button sct-button-white">reset filters</button>
            <button type="button" (click)="search(registerTypes.Dcnrfi)" class="sct-button sct-button-light margin-left20">search</button>
        </div> 
    </mat-tab>
    <mat-tab [disabled]="isDcnRfiFilterApplied">
        <ng-template mat-tab-label>
            <span>Redline</span>
        </ng-template>
        <app-redline-filter
            [filterForm]="filterForm"
        ></app-redline-filter>
        <div class="search-btn-container">
            <button type="button" (click)="clearFilters($event)" class="sct-button sct-button-white">reset filters</button>
            <button type="button" (click)="search(registerTypes.Redline)" class="sct-button sct-button-light margin-left20">search</button>
        </div> 
    </mat-tab>
</mat-tab-group>
    
