import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ToastService } from 'src/app/services/shared/toast.service';
import { ChangeManagementService } from 'src/app/services/api/webapi-services/change-management.service';
import { ChangeManagementFieldImplementationContractorHistoryError, ChangeManagementFieldImplementationContractorHistoryRequest, ChangeManagementFieldImplementationContractorHistorySuccess, FieldImplementationContractorHistoryActionTypes } from './actions';
import { Injectable } from "@angular/core";

@Injectable()
export class FieldImplementationContractorHistoryEffects{

    constructor(
        private actions$: Actions,        
        private toastService: ToastService,
        private changeManagementService: ChangeManagementService
    ) {}

    @Effect()
    getFieldImplementationContrHistory$ = this.actions$.pipe(
        ofType<ChangeManagementFieldImplementationContractorHistoryRequest>(FieldImplementationContractorHistoryActionTypes.ChangeManagementFieldImplementationContractorHistoryRequest),
        mergeMap((action) => {
            return this.changeManagementService.getFieldImplementationContractorHistory(action.payload.id)
                .pipe(
                    map((data) => new ChangeManagementFieldImplementationContractorHistorySuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting Forecast date history. Please contact Program Administrator.'
                        );
                        return of(new ChangeManagementFieldImplementationContractorHistoryError(error));
                    })
                );
        })
    );
}
