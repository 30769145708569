<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span>{{ lookaheadType }} 6-Week lookahead</span>
        </div>
    </div>
    <!-- 
    <nav mat-tab-nav-bar class="area-centered">
        <a
            mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
        >
            {{ link.label }}
        </a>
    </nav>
    -->
    <div class="filter-container">
        <form [formGroup]="filterForm">
            <mat-accordion class="filter-container">
                <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                    <mat-expansion-panel-header collapsedHeight="auto">
                        <div *ngIf="!filterExpansionPanel.expanded">
                            <mat-chip-list aria-label="Active filters">
                                <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                    Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                    System Owner:
                                    {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                                    RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                    SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                    Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                    Area Breakdown:
                                    {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                                    RFSU Contractor:
                                    {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                                </mat-basic-chip>
                            </mat-chip-list>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="mixedapp-container">
                        <mat-form-field class="container300 margin-right15">
                            <mat-label>Project Team</mat-label>
                            <mat-select
                                formControlName="projectTeamNames"
                                multiple
                                (openedChange)="onProjectTeamsClosed($event)"
                            >
                                <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="container300 margin-right20">
                            <mat-label>SC Execution Area Manager</mat-label>
                            <mat-select formControlName="scManager" multiple>
                                <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="container300 margin-right15">
                            <mat-label>System Owner</mat-label>
                            <mat-select formControlName="systemOwner" multiple>
                                <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                *ngIf="lookaheadType === 'RFSU'"
                                class="container300"
                                [setInput]="setRFOInput"
                                formControlName="rfos"
                                [searchFunc]="getRFOs"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'rfos'"
                                [propertyToShow]="'name'"
                                [placeholder]="'RFO'"
                                [secondPropertyToShow]="'rfoName'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name', 'rfoName']"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                [setInput]="setStagedStartUpPrioritiesInput"
                                formControlName="stagedStartUpPriorities"
                                [searchFunc]="getStagedStartUpPriorities"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['priority', 'priorityName']"
                                (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                                (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                                [filterForm]="filterForm"
                                formSetter="stagedStartUpPriorities"
                                [propertyToShow]="'priority'"                                    
                                [secondPropertyToShow]="'priorityName'"
                                placeholder="Staged Start-up Priority"
                                [panelWidth]="480"
                                class="container300"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setRFSUContractorsInput"
                                formControlName="rfsuContractors"
                                [searchFunc]="getRFSUContractors"
                                [isAsync]="true"
                                [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                [filterForm]="filterForm"
                                [formSetter]="'rfsuContractors'"
                                [propertyToShow]="'contractNo'"
                                [placeholder]="'RFSU Contractor'"
                                [panelWidth]="480"
                                [secondPropertyToShow]="'contract'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <mat-form-field class="container300 margin-right15">
                            <mat-label>Area Completion Lead</mat-label>
                            <mat-select formControlName="areaCompletionLead" multiple>
                                <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setAreaBreakdownInput"
                                formControlName="areaBreakdown"
                                [searchFunc]="getAreaBreakdown"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'areaBreakdown'"
                                [placeholder]="'Area Breakdown'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                            <div class="lds-hourglass"></div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </form>
        <div class="buttons-container flex">
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
    </div>
    <div class="lookahead-container">
        <div class="subtitle-container">
            <h4>{{ lookaheadType?.toLowerCase() }} 6 week lookahead</h4>
            <div class="options-container">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="dateRangePickerControlFormatted" />
                    <input
                        matInput
                        [matDatepicker]="dateRangePicker"
                        [matDatepickerFilter]="dateRangePickerFilter"
                        [formControl]="dateRangePickerControl"
                        hidden
                    />
                    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                    <mat-datepicker #dateRangePicker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</main>
