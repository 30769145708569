import * as moment from 'moment';
import { OrderDirection, CheckListColumn, CalendarColumn, UserDetail } from '../common.model';

export interface LoopStatusState {
    dataPagination: LoopStatusDataPagination;
    filter: LoopStatusFilter;
    isLoading: boolean;
}

export class LoopStatusDataPagination {
    items: LoopStatusDTO[] = [];
    totalCount = 0;
}

export class LoopStatusFilter {
    page = 0;
    take = 25
    tagTypes: string[] = [];
    tags: { id: string }[] = [];
    tagDescription = '';
    tagStatuses: string[] = [];
    projectTeamNames: string[] = [];
    subsystems: { id: string }[] = [];
    timezoneOffset = 0;
    sortBy = 'loop';
    direction = OrderDirection.Asc;
    showHideColumns = new ShowHideColumns();
    columnGoocms: CheckListColumn = null;
    columnAreas: CheckListColumn = null;
    columnSubsystems: CheckListColumn = null;
    columnLoops: CheckListColumn = null;
    columnTagNumbers: CheckListColumn = null;
    columnActivityIds: CheckListColumn = null;
    columnQVDNumbers: CheckListColumn = null;
    columnQVDDescriptions: CheckListColumn = null;
    columnFolderRequests: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnMCPlan: CalendarColumn = null;
    columnMCForecast: CalendarColumn = null;
    columnMCActual: CalendarColumn = null;
    columnActivityStartDate: CalendarColumn = null;
    columnFolderStatuses: CheckListColumn = null;
    columnFolderStatusDate: CalendarColumn = null;
    columnFolderAssignments: CheckListColumn = null;
    columnFolderAssignmentDate: CalendarColumn = null;
    columnEngineerStatuses: CheckListColumn = null;
    columnEngineerStatusDate: CalendarColumn = null;
    columnConstraintClasses: CheckListColumn = null;
    columnResponsibleParties: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnLoopFolderConstraints: CheckListColumn = null;
}

export class LoopStatusDTO {
    loop: string;
    subsystem: string;
    goocm: string;
    area: string;
    mcActual: moment.Moment;
    mcForecast: moment.Moment;
    mcPlan: moment.Moment;
    comment: string;
    folderStatus: string;
    folderStatusDate: moment.Moment;
    folderAssignment: string;
    folderAssignmentDate: moment.Moment;
    engineerStatus: string;
    engineerStatusDate: moment.Moment;
    constraintClass: string;
    responsibleParty: string;
    mentions: UserDetail[];
    loopFolderConstraint: string;
    loopFolderRequest: string;
    activityId: string;
    activityStartDate: moment.Moment;
    tagNumber: string;
    tagType: string;
    qvdNumber: string;
    qvdDescription: string;
    barcode:string;
}

export class ShowHideColumns {
    mcActualDate = false;
    mcForecastDate = false;
    mcPlanDate = false;
    folderStatusDate = false;
    folderAssignment = false;
    folderAssignmentDate = false;
    engineerStatus = false;
    engineerStatusDate = false;
    constraintClass = false;
    responsibleParty = false;
    loopFolderConstraint = false;
    area = false;
    goocm = false;
}

