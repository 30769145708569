import { Action } from '@ngrx/store';
import {
    WeeklyValidationProgressDataDTO,
    MonthlyValidationProgressDataDTO,
    ChangeValidationDashboardProgressDataDTO,
    NameSeriesDataDTO,
    ValidatorPerformanceScoreboardDataDTO,
    ChangeValidationDashboardFilter,
} from './model';
import * as moment from 'moment';

export enum ChangeValidationDashboardActionTypes {
    ChangeValidationDashboardWeeklyValidationProgressRequest = '[ChangeValidationDashboard] Weekly Validation Progress Request',
    ChangeValidationDashboardWeeklyValidationProgressRequestSuccess = '[ChangeValidationDashboard] Weekly Validation Progress Success',
    ChangeValidationDashboardWeeklyValidationProgressRequestError = '[ChangeValidationDashboard] Weekly Validation Progress Error',
    ChangeValidationDashboardMonthlyValidationProgressRequest = '[ChangeValidationDashboard] Monthly Validation Progress Request',
    ChangeValidationDashboardMonthlyValidationProgressRequestSuccess = '[ChangeValidationDashboard] Monthly Validation Progress Success',
    ChangeValidationDashboardMonthlyValidationProgressRequestError = '[ChangeValidationDashboard] Monthly Validation Progress Error',
    ChangeValidationDashboardFilterPropertyUpdate = '[ChangeValidationDashboard] Filter Property Update',
    ChangeValidationDashboardFilterUpdate = '[ChangeValidationDashboard] Filter Update',
    ChangeValidationDashboardFilterReset = '[ChangeValidationDashboard] Filter Reset',
    ChangeValidationDashboardValidationProgressRequest = '[ChangeValidationDashboard] Validation Progress Request',
    ChangeValidationDashboardValidationProgressRequestSuccess = '[ChangeValidationDashboard] Validation Progress Request Success',
    ChangeValidationDashboardValidationProgressRequestError = '[ChangeValidationDashboard] Validation Progress Request Error',
    ChangeValidationDashboardCumulativeValidationProgressRequest = '[ChangeValidationDashboard] Cumulative Validation Progress Request',
    ChangeValidationDashboardWeeklyCumulativeValidationProgressRequest = '[ChangeValidationDashboard] Weekly Cumulative Validation Progress Request',
    ChangeValidationDashboardCumulativeValidationProgressRequestSuccess = '[ChangeValidationDashboard] Cumulative Validation Progress Request Success',
    ChangeValidationDashboardWeeklyCumulativeValidationProgressRequestSuccess = '[ChangeValidationDashboard] Weekly Cumulative Validation Progress Request Success',
    ChangeValidationDashboardCumulativeValidationProgressRequestError = '[ChangeValidationDashboard] Cumulative Validation Progress Request Error',
    ChangeValidationDashboardMonthlyChangeRaisedRequest = '[ChangeValidationDashboard] Monthly Change Raised Request',
    ChangeValidationDashboardWeeklyChangeRaisedRequest = '[ChangeValidationDashboard] Weekly Change Raised Request',
    ChangeValidationDashboardMonthlyChangeRaisedRequestSuccess = '[ChangeValidationDashboard] Monthly Change Raised Request Success',
    ChangeValidationDashboardWeeklyChangeRaisedRequestSuccess = '[ChangeValidationDashboard] Weekly Change Raised Request Success',
    ChangeValidationDashboardMonthlyChangeRaisedRequestError = '[ChangeValidationDashboard] Monthly Change Raised Request Error',
    ChangeValidationDashboardSystemisationProgressRequest = '[ChangeValidationDashboard] Systemisation Progress Request',
    ChangeValidationDashboardSystemisationProgressRequestSuccess = '[ChangeValidationDashboard] Systemisation Progress Request Success',
    ChangeValidationDashboardSystemisationProgressRequestError = '[ChangeValidationDashboard] Systemisation Progress Request Error',
    ChangeValidationDashboardValidatorPerformanceScoreboardRequest = '[ChangeValidationDashboard] Validator Performance Scoreboard Request',
    ChangeValidationDashboardValidatorPerformanceScoreboardRequestSuccess = '[ChangeValidationDashboard] Validator Performance Scoreboard Request Success',
    ChangeValidationDashboardValidatorPerformanceScoreboardRequestError = '[ChangeValidationDashboard] Validator Performance Scoreboard Request Error',
    ChangeValidationDashboardWeeklyValidationProgressSetWeekRange = '[ChangeValidationDashboard] Weekly Validation Progress Set Week Range',
    ChangeValidationDashboardMonthlyValidationProgressSetMonthRange = '[ChangeValidationDashboard] Monthly Validation Progress Set Month Range',
    ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange = '[ChangeValidationDashboard] Monthly Change Raised Set Month Range',
    ChangeValidationDashboardWeeklyChangeRaisedSetWeekRange = '[ChangeValidationDashboard] Weekly Change Raised Set Week Range',
    ChangeValidationDashboardCumulativeValidationProgressSetMonthRange = '[ChangeValidationDashboard] Cumulative Validation Progress Set Month Range',
    ChangeValidationDashboardWeeklyCumulativeValidationProgressSetWeekRange = '[ChangeValidationDashboard] Weekly Cumulative Validation Progress Set Week Range',
    ChangeValidationDashboardValidatorPerformanceScoreboardSetWeekRange = '[ChangeValidationDashboard] Validator Performance Scoreboard Set Week Range',
}

export class ChangeValidationDashboardWeeklyValidationProgressSetWeekRange implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressSetWeekRange;

    constructor(public payload: moment.Moment) {}
}

export class ChangeValidationDashboardMonthlyValidationProgressSetMonthRange implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressSetMonthRange;

    constructor(public payload: moment.Moment) {}
}

export class ChangeValidationDashboardValidatorPerformanceScoreboardSetWeekRange implements Action {
    readonly type =
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardSetWeekRange;

    constructor(public payload: moment.Moment) {}
}

export class ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange;
    constructor(public payload: moment.Moment) {}
}

export class ChangeValidationDashboardWeeklyChangeRaisedSetWeekRange implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyChangeRaisedSetWeekRange;
    constructor(public payload: moment.Moment) {}
}

export class ChangeValidationDashboardCumulativeValidationProgressSetMonthRange implements Action {
    readonly type =
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressSetMonthRange;

    constructor(public payload: moment.Moment) {}
}

export class ChangeValidationDashboardWeeklyCumulativeValidationProgressSetWeekRange implements Action {
    readonly type = 
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyCumulativeValidationProgressSetWeekRange;

    constructor(public payload: moment.Moment) {}
}

export class ChangeValidationDashboardWeeklyValidationProgressRequest implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressRequest;
}

export class ChangeValidationDashboardWeeklyValidationProgressRequestSuccess implements Action {
    readonly type =
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressRequestSuccess;

    constructor(public payload: WeeklyValidationProgressDataDTO[]) {}
}

export class ChangeValidationDashboardWeeklyValidationProgressRequestError implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressRequestError;

    constructor(public error: string) {}
}

export class ChangeValidationDashboardMonthlyValidationProgressRequest implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressRequest;
}

export class ChangeValidationDashboardMonthlyValidationProgressRequestSuccess implements Action {
    readonly type =
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressRequestSuccess;

    constructor(public payload: MonthlyValidationProgressDataDTO[]) {}
}

export class ChangeValidationDashboardMonthlyValidationProgressRequestError implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressRequestError;

    constructor(public error: string) {}
}

export class ChangeValidationDashboardValidationProgressRequest implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidationProgressRequest;
}

export class ChangeValidationDashboardValidationProgressRequestSuccess implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidationProgressRequestSuccess;

    constructor(public payload: ChangeValidationDashboardProgressDataDTO) {}
}

export class ChangeValidationDashboardValidationProgressRequestError implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidationProgressRequestError;

    constructor(public error: string) {}
}

export class ChangeValidationDashboardCumulativeValidationProgressRequest implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressRequest;
}

export class ChangeValidationDashboardCumulativeValidationProgressRequestSuccess implements Action {
    readonly type =
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressRequestSuccess;

    constructor(public payload: NameSeriesDataDTO[]) {}
}

export class ChangeValidationDashboardWeeklyCumulativeValidationProgressRequest implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyCumulativeValidationProgressRequest;
}

export class ChangeValidationDashboardWeeklyCumulativeValidationProgressRequestSuccess implements Action {
    readonly type =
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyCumulativeValidationProgressRequestSuccess;

    constructor(public payload: NameSeriesDataDTO[]) {}
}

export class ChangeValidationDashboardCumulativeValidationProgressRequestError implements Action {
    readonly type =
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressRequestError;

    constructor(public error: string) {}
}

export class ChangeValidationDashboardSystemisationProgressRequest implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardSystemisationProgressRequest;
}

export class ChangeValidationDashboardSystemisationProgressRequestSuccess implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardSystemisationProgressRequestSuccess;

    constructor(public payload: ChangeValidationDashboardProgressDataDTO) {}
}

export class ChangeValidationDashboardSystemisationProgressRequestError implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardSystemisationProgressRequestError;

    constructor(public error: string) {}
}

export class ChangeValidationDashboardMonthlyChangeRaisedRequest implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedRequest;
}

export class ChangeValidationDashboardWeeklyChangeRaisedRequest implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyChangeRaisedRequest;
}

export class ChangeValidationDashboardMonthlyChangeRaisedRequestSuccess implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedRequestSuccess;

    constructor(public payload: NameSeriesDataDTO[]) {}
}

export class ChangeValidationDashboardWeeklyChangeRaisedRequestSuccess implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyChangeRaisedRequestSuccess;

    constructor(public payload: NameSeriesDataDTO[]) {}
}

export class ChangeValidationDashboardMonthlyChangeRaisedRequestError implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedRequestError;

    constructor(public error: string) {}
}

export class ChangeValidationDashboardValidatorPerformanceScoreboardRequest implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardRequest;
}

export class ChangeValidationDashboardValidatorPerformanceScoreboardRequestSuccess implements Action {
    readonly type =
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardRequestSuccess;

    constructor(public payload: ValidatorPerformanceScoreboardDataDTO[]) {}
}

export class ChangeValidationDashboardValidatorPerformanceScoreboardRequestError implements Action {
    readonly type =
        ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardRequestError;

    constructor(public error: string) {}
}

export class ChangeValidationDashboardFilterReset implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardFilterReset;
}

export class ChangeValidationDashboardFilterPropertyUpdate implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ChangeValidationDashboardFilterUpdate implements Action {
    readonly type = ChangeValidationDashboardActionTypes.ChangeValidationDashboardFilterUpdate;
    constructor(public payload: ChangeValidationDashboardFilter) {}
}

export type ChangeValidationDashboardActions =
    | ChangeValidationDashboardWeeklyValidationProgressRequest
    | ChangeValidationDashboardWeeklyValidationProgressRequestSuccess
    | ChangeValidationDashboardWeeklyValidationProgressRequestError
    | ChangeValidationDashboardMonthlyValidationProgressRequest
    | ChangeValidationDashboardMonthlyValidationProgressRequestSuccess
    | ChangeValidationDashboardMonthlyValidationProgressRequestError
    | ChangeValidationDashboardValidationProgressRequest
    | ChangeValidationDashboardValidationProgressRequestSuccess
    | ChangeValidationDashboardValidationProgressRequestError
    | ChangeValidationDashboardCumulativeValidationProgressRequest
    | ChangeValidationDashboardCumulativeValidationProgressRequestSuccess
    | ChangeValidationDashboardWeeklyCumulativeValidationProgressRequest
    | ChangeValidationDashboardWeeklyCumulativeValidationProgressRequestSuccess
    | ChangeValidationDashboardCumulativeValidationProgressRequestError
    | ChangeValidationDashboardSystemisationProgressRequest
    | ChangeValidationDashboardSystemisationProgressRequestSuccess
    | ChangeValidationDashboardSystemisationProgressRequestError
    | ChangeValidationDashboardMonthlyChangeRaisedRequest
    | ChangeValidationDashboardWeeklyChangeRaisedRequest
    | ChangeValidationDashboardMonthlyChangeRaisedRequestSuccess
    | ChangeValidationDashboardWeeklyChangeRaisedRequestSuccess
    | ChangeValidationDashboardMonthlyChangeRaisedRequestError
    | ChangeValidationDashboardValidatorPerformanceScoreboardRequest
    | ChangeValidationDashboardValidatorPerformanceScoreboardRequestSuccess
    | ChangeValidationDashboardValidatorPerformanceScoreboardRequestError
    | ChangeValidationDashboardFilterPropertyUpdate
    | ChangeValidationDashboardFilterUpdate
    | ChangeValidationDashboardFilterReset
    | ChangeValidationDashboardWeeklyValidationProgressSetWeekRange
    | ChangeValidationDashboardMonthlyValidationProgressSetMonthRange
    | ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange
    | ChangeValidationDashboardWeeklyChangeRaisedSetWeekRange
    | ChangeValidationDashboardCumulativeValidationProgressSetMonthRange
    | ChangeValidationDashboardWeeklyCumulativeValidationProgressSetWeekRange
    | ChangeValidationDashboardValidatorPerformanceScoreboardSetWeekRange;
