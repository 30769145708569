<main class="main">
    <div class="title-container">
        <div class="title">
            Loop Folder Status
        </div>
        <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light" [style.width]="'100px'">
            export
        </button>
    </div>
    <div class="results-container">
        <div class="results-panel-header-container">
            <div class="results-panel-header-title-row">
                <div class="results-header-actions">
                    <button
                        mat-button
                        [matMenuTriggerFor]="columnsCustomizer"
                        class="results-columns-customizer"
                    >
                        <mat-icon svgIcon="edit"></mat-icon>
                        customize columns
                    </button>
                    <mat-menu #columnsCustomizer class="columns-customizer">
                        <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                        <div class="columns-customizer-list">
                            <mat-checkbox
                                [checked]="showHideColumns.mcActualDate"
                                (change)="reverseValue('mcActualDate')"
                                (click)="$event.stopPropagation()"
                                >MC Actual Date
                            </mat-checkbox>
                            <mat-checkbox
                                [checked]="showHideColumns.mcForecastDate"
                                (change)="reverseValue('mcForecastDate')"
                                (click)="$event.stopPropagation()"
                                >MC Forecast Date
                            </mat-checkbox>
                            <mat-checkbox class="hidecells"
                                [checked]="showHideColumns.folderStatusDate"
                                (change)="reverseValue('folderStatusDate')"
                                (click)="$event.stopPropagation()"
                                >Folder Status Date
                            </mat-checkbox>
                            <mat-checkbox
                                [checked]="showHideColumns.loopFolderConstraint"
                                (change)="reverseValue('loopFolderConstraint')"
                                (click)="$event.stopPropagation()"
                                >Loop Folder Constraint</mat-checkbox
                            >
                        </div>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="table-wrapper">
            <div class="scroll" app-double-scroll>
                <table
                    mat-table
                    matSort
                    [dataSource]="data"
                    [matSortActive]="sortBy"
                    matSortDisableClear
                    [matSortDirection]="direction"
                >

                    <ng-container matColumnDef="area">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showHideColumns.area
                            }"
                            (click)="openHeaderCheckListFilter('area', getAreas, 'columnAreas', 'Area', columnAreas)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnAreas)
                                    }"
                                    >Area
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'area'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td 
                        mat-cell 
                        *matCellDef="let element"
                        [ngClass]="{
                            hidecells: !showHideColumns.area
                        }"
                        >{{ element.area }}</td>
                    </ng-container>

                    <ng-container matColumnDef="subsystem">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'subsystem',
                                    getSubsystems,
                                    'columnSubsystems',
                                    'Subsystem',
                                    columnSubsystems
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnSubsystems)
                                    }"
                                    >Subsystem
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.subsystem }}</td>
                    </ng-container>

                    <ng-container matColumnDef="loop">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderCheckListFilter('loop', getLoops, 'columnLoops', 'Loop', columnLoops)"
                        >
                            <div class="sort-container">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnLoops)
                                    }"
                                    >Loop Number
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'loop'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.loop }}</td>
                    </ng-container>

                    <ng-container matColumnDef="tagNumber">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderCheckListFilter('tagNumber', getTagNumbers, 'columnTagNumbers', 'TagNumber', columnTagNumbers)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnTagNumbers)
                                    }"
                                    >Tag Number
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'tagNumber'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.tagNumber }}</td>
                    </ng-container>

                    <ng-container matColumnDef="activityId">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderCheckListFilter('activityId', getActivityIds, 'columnActivityIds', 'ActivityId', columnActivityIds)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnActivityIds)
                                    }"
                                    >Pre-Comm Activity Id
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'activityId'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.activityId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="activityStartDate">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderDateFilter('activityStartDate', 'columnActivityStartDate', 'Activity Start Date', columnActivityStartDate)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnActivityStartDate)
                                    }"
                                    >Activity Start Date from P6</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'activityStartDate'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td 
                            mat-cell 
                            *matCellDef="let element">
                            {{ element.activityStartDate | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="qvdNumber">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderCheckListFilter('qvdNumber', getQVDNumbers, 'columnQVDNumbers', 'QVD Number', columnQVDNumbers)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnQVDNumbers)
                                    }"
                                    >Check Sheet No
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'qvdNumber'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.qvdNumber }}</td>
                    </ng-container>

                    <ng-container matColumnDef="qvdDescription">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderCheckListFilter('qvdDescription', getQVDDescriptions, 'columnQVDDescriptions', 'QVD Derscription', columnQVDDescriptions)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnQVDDescriptions)
                                    }"
                                    >Check Sheet Description
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'qvdDescription'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.qvdDescription }}</td>
                    </ng-container>
                    <ng-container matColumnDef="barcode">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderCheckListFilter( 'barcode',
                                                        getBarcodes,
                                                        'columnBarcode',
                                                        'Barcode',
                                                        columnBarcode)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnBarcode)
                                    }"
                                    >Barcode</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'barcode'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element">
                            {{ element.barcode }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="loopFolderRequest">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderCheckListFilter('loopFolderRequest', 
                            getFolderRequests, 'columnFolderRequests', 'Loop Folder Request', 
                            columnFolderRequests)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnFolderRequests)
                                    }"
                                    >Loop Folder Request
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'loopFolderRequest'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.loopFolderRequest }}</td>
                    </ng-container>
                    
                    <ng-container matColumnDef="mcPlan">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showHideColumns.mcPlanDate
                            }"
                            (click)="openHeaderDateFilter('mcPlan', 'columnMCPlan', 'MC Plan', columnMCPlan)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnMCPlan)
                                    }"
                                    >MC Plan</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'mcPlan'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td 
                            mat-cell 
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showHideColumns.mcPlanDate
                            }">
                            {{ element.mcPlan | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="mcForecast">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showHideColumns.mcForecastDate
                            }"
                            (click)="
                                openHeaderDateFilter('mcForecast', 'columnMCForecast', 'MC Forecast', columnMCForecast)
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnMCForecast)
                                    }"
                                    >MC Forecast</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'mcForecast'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td 
                            mat-cell 
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showHideColumns.mcForecastDate
                            }">
                            {{ element.mcForecast | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="mcActual">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showHideColumns.mcActualDate,
                                'header-filtered': showUnderlineForCalendarColumn(columnMCActual)
                            }"
                            class="hover-pointer"
                            (click)="openHeaderDateFilter('mcActual', 'columnMCActual', 'MC Actual', columnMCActual)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnMCActual)
                                    }"
                                    >MC Actual</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'mcActual'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td 
                                mat-cell 
                                *matCellDef="let element"
                                [ngClass]="{
                                    hidecells: !showHideColumns.mcActualDate
                                }">
                            {{ element.mcActual | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="folderStatus">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'folderStatus',
                                    getFolderStatuses,
                                    'columnFolderStatuses',
                                    'Loop Folder Status',
                                    columnFolderStatuses,
                                    true
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnFolderStatuses)
                                    }"
                                    >Loop Folder Status
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'folderStatus'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.folderStatus }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="folderStatusDate">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showHideColumns.folderStatusDate
                            }"
                            (click)="
                                openHeaderDateFilter(
                                    'folderStatusDate',
                                    'columnFolderStatusDate',
                                    'Loop Folder Staus Date',
                                    columnFolderStatusDate
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnFolderStatusDate)
                                    }"
                                    >Loop Folder Status Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'folderStatusDate'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell 
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showHideColumns.folderStatusDate
                            }">
                            {{ element.folderStatusDate | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="loopFolderConstraint">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showHideColumns.loopFolderConstraint
                            }"
                            (click)="
                                openHeaderCheckListFilter(
                                    'loopFolderConstraint',
                                    getLoopFolderConstraints,
                                    'columnLoopFolderConstraints',
                                    'Loop Folder Constraint',
                                    columnLoopFolderConstraints,
                                    true
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnLoopFolderConstraints)
                                    }"
                                    >Loop Folder Constraint
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'loopFolderConstraint'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td 
                            mat-cell 
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showHideColumns.loopFolderConstraint
                            }">
                            <div *ngIf="!isLoopCordinator">{{ element.loopFolderConstraint }}</div>
                            <mat-select
                                *ngIf="isLoopCordinator"
                                matNativeControl
                                [(value)]="element.loopFolderConstraint"
                                (selectionChange)="
                                    updateLoop(
                                        $event,
                                        element.loop,
                                        element.tagNumber, 
                                        element.tagType,
                                        configKeys.loopFolderConstraintKey,
                                        'loopFolderConstraint',element
                                    )
                                "
                            >
                                <mat-option
                                    *ngFor="let folderConstraint of loopFolderConstraints"
                                    [value]="folderConstraint"
                                >
                                    {{ folderConstraint }}
                                </mat-option>
                            </mat-select>
                        </td>
                    </ng-container>



                    <ng-container matColumnDef="comment" stickyEnd>
                        <th mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                            <div class="sort-container">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnComments)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'comment',
                                            getComments,
                                            'columnComments',
                                            'Comment',
                                            columnComments,
                                            false,
                                            true,
                                            true
                                        )
                                    "
                                    >Comment</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'comment'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" >
                            <div class="comment-description" (click)="openCommentPopup(element, $event)">
                                <div *ngIf="element.comment && !isReadOnly; else notShow">
                                    <a><span [innerHTML]="element.comment | formatMentions: element.mentions"></span></a>
                                </div>
                                <div *ngIf="isReadOnly">
                                    <span [innerHTML]="element.comment | formatMentions: element.mentions"></span>
                                </div>
                                <ng-template #notShow>
                                    <button type="button" *ngIf="!isReadOnly" mat-stroked-button class="add-comment">
                                        Add
                                    </button>
                                </ng-template>
                            </div>          
                            <div
                               
                                class="comment-hist"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    (click)="openHistoryPopup(element)"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                    />
                                </svg>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>
