import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    CWPRegisterActionTypes,
    CWPRegisterUploadLogSuccess,
    CWPRegisterUploadLogError,
    CWPRegisterSetStatusAndStartDate,
    CWPRegisterDeltaRequest,
    CWPRegisterDeltaSuccess,
    CWPRegisterDeltaError,
    CWPRegisterPatchDeltaRequest,
    CWPRegisterPatchDeltaSuccess,
    CWPRegisterPatchDeltaError,
    CWPRegisterValidateDeltaSuccess,
    CWPRegisterValidateDeltaError,
    CWPRegisterClearInProgressSpinner,
    CWPRegisterTemplateFileRequest,
    CWPRegisterTemplateFileSuccess,
    CWPRegisterTemplateFileError,
    CWPRegisterDeleteDataRequest,
    CWPRegisterDeleteDataSuccess,
    CWPRegisterDeleteDataError,
    CWPRegisterPatchDeleteRecordRequest,
    CWPRegisterPatchDeleteRecordSuccess,
    CWPRegisterPatchDeleteRecordError,
    CWPRegisterValidateButtonStateSuccess,
    CWPRegisterValidateButtonStateError,
    CWPRegisterDownloadOutputDataRequest,
    CWPRegisterDownloadOutputDataSuccess,
    CWPRegisterDownloadOutputDataError,
    CWPRegisterPatchAllDeltaRequest,
    CWPRegisterPatchAllDeltaSuccess,
    CWPRegisterPatchAllDeltaError,
    CWPRegisterPatchDeleteAllRecordsRequest,
    CWPRegisterPatchDeleteAllRecordsSuccess,
    CWPRegisterPatchDeleteAllRecordsError,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class CWPRegisterEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(CWPRegisterActionTypes.CWPRegisterUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['CWP']).pipe(
                map((uploadLogData) => new CWPRegisterUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new CWPRegisterUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<CWPRegisterUploadLogSuccess>(CWPRegisterActionTypes.CWPRegisterUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.cwpRegisterState.uploadLogStatus),
            this.store.select((store) => store.cwpRegisterState.uploadLogStartDate)
        ),
        filter(([{ payload }, status, startDate]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return logs[0] && (status !== logs[0].status || startDate !== logs[0].startDate);
        }),
        map(([{ payload }]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return new CWPRegisterSetStatusAndStartDate({ status: logs[0].status, startDate: logs[0].startDate });
        })
    );

    @Effect()
    invokeDeltaRequest$ = this.actions$.pipe(
        ofType(CWPRegisterActionTypes.CWPRegisterSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.cwpRegisterState.uploadLogData)),
        filter(([, uploadLogs]) => {
            const logs = uploadLogs.filter((l) => l.status !== ImportStatuses.Info);
            return (logs[0] && logs[0].status === ImportStatuses.Finished) || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new CWPRegisterDeltaRequest(), new CWPRegisterDeleteDataRequest()])
    );

    @Effect()
    getDelta$ = this.actions$.pipe(
        ofType(CWPRegisterActionTypes.CWPRegisterDeltaRequest),
        withLatestFrom(this.store.select((store) => store.cwpRegisterState.deltaFilter)),
        mergeMap(([, deltaFilter]) =>
            this.manualUploadsService.getCWPRegisterDelta(deltaFilter.page, deltaFilter.take).pipe(
                map((deltaData: any) => new CWPRegisterDeltaSuccess(deltaData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delta records. Please contact Program Administrator'
                    );
                    return of(new CWPRegisterDeltaError(error));
                })
            )
        )
    );

    @Effect()
    getDeleteRecords$ = this.actions$.pipe(
        ofType(CWPRegisterActionTypes.CWPRegisterDeleteDataRequest),
        withLatestFrom(this.store.select((store) => store.cwpRegisterState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getCWPRegisterDeleteRecords(deleteFilter.page, deleteFilter.take).pipe(
                map((deleteData: any) => new CWPRegisterDeleteDataSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete records. Please contact Program Administrator'
                    );
                    return of(new CWPRegisterDeleteDataError(error));
                })
            )
        )
    );

    @Effect()
    patchDelta$ = this.actions$.pipe(
        ofType<CWPRegisterPatchDeltaRequest>(CWPRegisterActionTypes.CWPRegisterPatchDeltaRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchCWPRegisterDelta(action.payload.cwp, action.payload.deltaState).pipe(
                map(() => new CWPRegisterPatchDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deltaState ? 'rejecting' : 'accepting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new CWPRegisterPatchDeltaError(error));
                })
            )
        )
    );

    @Effect()
    patchAllDelta$ = this.actions$.pipe(
        ofType<CWPRegisterPatchAllDeltaRequest>(CWPRegisterActionTypes.CWPRegisterPatchAllDeltaRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchAllCWPRegisterDelta(action.payload.deltaState).pipe(
                map(() => new CWPRegisterPatchAllDeltaSuccess({ deltaState: action.payload.deltaState })),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deltaState ? 'rejecting' : 'accepting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new CWPRegisterPatchAllDeltaError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteRecord$ = this.actions$.pipe(
        ofType<CWPRegisterPatchDeleteRecordRequest>(CWPRegisterActionTypes.CWPRegisterPatchDeleteRecordRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchCWPRegisterDeleteRecord(action.payload.cwp, action.payload.deleteState).pipe(
                map(() => new CWPRegisterPatchDeleteRecordSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'deleting' : 'restoring'
                        } the record. Please contact Program Administrator`
                    );
                    return of(new CWPRegisterPatchDeleteRecordError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteAllRecords$ = this.actions$.pipe(
        ofType<CWPRegisterPatchDeleteAllRecordsRequest>(CWPRegisterActionTypes.CWPRegisterPatchDeleteAllRecordsRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchCWPRegisterDeleteAllRecords(action.payload.deleteState).pipe(
                map(() => new CWPRegisterPatchDeleteAllRecordsSuccess({ deleteState: action.payload.deleteState })),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'deleting' : 'restoring'
                        } the record. Please contact Program Administrator`
                    );
                    return of(new CWPRegisterPatchDeleteAllRecordsError(error));
                })
            )
        )
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(CWPRegisterActionTypes.CWPRegisterValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateCWPRegisterDelta().pipe(
                map(() => new CWPRegisterValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new CWPRegisterValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(CWPRegisterActionTypes.CWPRegisterDeltaRequest),
        withLatestFrom(this.store.select((store) => store.cwpRegisterState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new CWPRegisterClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<CWPRegisterTemplateFileRequest>(CWPRegisterActionTypes.CWPRegisterTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new CWPRegisterTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading CWP Register structure file. Please contact Program Administrator'
                    );
                    return of(new CWPRegisterTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<CWPRegisterTemplateFileSuccess>(CWPRegisterActionTypes.CWPRegisterTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'CwpRegister.csv');
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(
            CWPRegisterActionTypes.CWPRegisterDeltaSuccess,
            CWPRegisterActionTypes.CWPRegisterPatchDeleteAllRecordsSuccess,
            CWPRegisterActionTypes.CWPRegisterPatchDeleteRecordSuccess
        ),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('CWP').pipe(
                map((response: any) => new CWPRegisterValidateButtonStateSuccess(response)),
                catchError((error) => of(new CWPRegisterValidateButtonStateError(error)))
            )
        )
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<CWPRegisterDownloadOutputDataRequest>(CWPRegisterActionTypes.CWPRegisterDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new CWPRegisterDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new CWPRegisterDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<CWPRegisterDownloadOutputDataSuccess>(CWPRegisterActionTypes.CWPRegisterDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );
}
