import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/components/base.component';
import { HeaderNumericFilter } from 'src/app/models/filter-settings';

@Component({
    selector: 'app-header-numeric-filter',
    templateUrl: './header-numeric-filter.component.html',
    styleUrls: ['./header-numeric-filter.component.scss'],
})
export class HeaderNumericFilterComponent extends BaseComponent implements OnInit {
    filterConfig: HeaderNumericFilter;
    minControl: UntypedFormControl;
    maxControl: UntypedFormControl;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: HeaderNumericFilter,
        private dialogRef: MatDialogRef<HeaderNumericFilterComponent>
    ) {
        super();
        this.filterConfig = data;

        //this.minControl = new UntypedFormControl('', [Validators.max(this.maxControl?.value), Validators.min(0)]);
        //this.maxControl = new UntypedFormControl('', [Validators.min(this.minControl?.value || 0)]);
        this.minControl = new UntypedFormControl('', [Validators.max(this.maxControl?.value)]);
        this.maxControl = new UntypedFormControl('', [Validators.min(this.minControl?.value)]);
    }

    ngOnInit() {
        // This is intentional
    }

    onMinOut(event: any) {
        const value = event.target.value !== '' ? +event.target.value : null;
        //if (value != null && value >= 0 && this.maxControl?.value && value > this.maxControl.value) {
        if (value != null && this.maxControl?.value && value > this.maxControl.value) {
            this.maxControl.setValue(value);
        }
    }

    onMaxOut(event: any) {
        const value = event.target.value !== '' ? +event.target.value : null;
        //if (value != null && value >= 0 && this.minControl?.value && value < this.minControl.value) {
        if (value != null && this.minControl?.value && value < this.minControl.value) {
            this.minControl.setValue(value);
        }
    }

    updateBlanks(radio: any) {
        switch (radio.value) {
            case 'all':
                this.filterConfig.excludeBlanks = false;
                this.filterConfig.onlyBlanks = false;
                break;
            case 'onlyBlanks':
                this.filterConfig.excludeBlanks = false;
                this.filterConfig.onlyBlanks = true;
                break;
            case 'excludeBlanks':
                this.filterConfig.excludeBlanks = true;
                this.filterConfig.onlyBlanks = false;
                break;
        }
    }

    onReset() {
        this.filterConfig = {
            ...this.filterConfig,
            isAscendingSort: false,
            isDescendingSort: false,
            minValue: null,
            maxValue: null,
            excludeBlanks: false,
            onlyBlanks: false,
        };
    }

    onClose() {
        if (this.filterConfig.onCancel) {
            this.filterConfig.onCancel();
        }
        this.dialogRef.close(false);
    }

    onSubmit() {
        if (this.filterConfig.onSumbit) {
            this.filterConfig.onSumbit(this.filterConfig);
        }
        this.dialogRef.close(true);
    }
}
