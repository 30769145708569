import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { RFODeltaPagination, RFODeletePagination } from './model';
import { Moment } from 'moment';




export enum RFOActionTypes {
    RFOUploadLogRequest = '[RFO] Upload Log Request',
    RFOUploadLogSuccess = '[RFO] Upload Log Success',
    RFOUploadLogError = '[RFO] Upload Log Error',
    RFODeltaRequest = '[RFO] Delta Request',
    RFODeltaSuccess = '[RFO] Delta Success',
    RFODeltaError = '[RFO] Delta Error',
    RFOSetStatusAndStartDate = '[RFO] Set Status and Start Date',
    RFODeltaFilterPropertyUpdate = '[RFO] Delta Filter Property Update',
    RFODeleteFilterPropertyUpdate = '[RFO] Delete Filter Property Update',
    RFOPatchDeltaRequest = '[RFO] Patch Delta Request',
    RFOPatchDeltaSuccess = '[RFO] Patch Delta Success',
    RFOPatchDeltaError = '[RFO] Patch Delta Error',
    RFOPatchAllDeltaRequest = '[RFO] Patch All Delta Request',
    RFOPatchAllDeltaSuccess = '[RFO] Patch All Delta Success',
    RFOPatchAllDeltaError = '[RFO] Patch All Delta Error',
    RFOValidateDeltaRequest = '[RFO] Validate Delta Request',
    RFOValidateDeltaSuccess = '[RFO] Validate Delta Success',
    RFOValidateDeltaError = '[RFO] Validate Delta Error',
    RFOFileUploaded = '[RFO] File Uploaded',
    RFOClearInProgressSpinner = '[RFO] Clear In Progress Spinner',
    RFOTemplateFileRequest = '[RFO] Template File Request',
    RFOTemplateFileSuccess = '[RFO] Template File Success',
    RFOTemplateFileError = '[RFO] Template File Error',
    RFODeleteDataRequest = '[RFO] Delete Data Request',
    RFODeleteDataSuccess = '[RFO] Delete Data Success',
    RFODeleteDataError = '[RFO] Delete Data Error',
    RFOPatchDeleteRecordRequest = '[RFO] Patch Delete Record Request',
    RFOPatchDeleteRecordSuccess = '[RFO] Patch Delete Record Success',
    RFOPatchDeleteRecordError = '[RFO] Patch Delete Record Error',
    RFOPatchDeleteAllRecordsRequest = '[RFO] Patch Delete All Records Request',
    RFOPatchDeleteAllRecordsSuccess = '[RFO] Patch Delete All Records Success',
    RFOPatchDeleteAllRecordsError = '[RFO] Patch Delete All Records Error',
    RFOValidateButtonStateRequest = '[RFO] Validate Button State Request',
    RFOValidateButtonStateSuccess = '[RFO] Validate Button State Success',
    RFOValidateButtonStateError = '[RFO] Validate Button State Error',
    RFODownloadOutputDataRequest = '[RFO] Download Output Data Request',
    RFODownloadOutputDataSuccess = '[RFO] Download Output Data Success',
    RFODownloadOutputDataError = '[RFO] Download Output Data Error',
    RFOAddStartedLog = '[RFO] Add Started Log',
    RFOPatchDeleteAllRecordsRequestSuccess = "RFOPatchDeleteAllRecordsRequestSuccess",
    RFOPatchDeleteRecordRequestSuccess = "RFOPatchDeleteRecordRequestSuccess"
}

export class RFOAddStartedLog implements Action {
    readonly type = RFOActionTypes.RFOAddStartedLog;
}

export class RFOUploadLogRequest implements Action {
    readonly type = RFOActionTypes.RFOUploadLogRequest;
}

export class RFOUploadLogSuccess implements Action {
    readonly type = RFOActionTypes.RFOUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class RFOUploadLogError implements Action {
    readonly type = RFOActionTypes.RFOUploadLogError;

    constructor(public payload: string) {}
}

export class RFODeltaRequest implements Action {
    readonly type = RFOActionTypes.RFODeltaRequest;
}

export class RFODeltaSuccess implements Action {
    readonly type = RFOActionTypes.RFODeltaSuccess;

    constructor(public payload: RFODeltaPagination) {}
}

export class RFODeltaError implements Action {
    readonly type = RFOActionTypes.RFODeltaError;

    constructor(public payload: string) {}
}

export class RFOSetStatusAndStartDate implements Action {
    readonly type = RFOActionTypes.RFOSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class RFODeltaFilterPropertyUpdate implements Action {
    readonly type = RFOActionTypes.RFODeltaFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class RFODeleteFilterPropertyUpdate implements Action {
    readonly type = RFOActionTypes.RFODeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class RFOPatchDeltaRequest implements Action {
    readonly type = RFOActionTypes.RFOPatchDeltaRequest;

    constructor(public payload: { RFOId: string; deltaState: boolean }) {}
}

export class RFOPatchAllDeltaRequest implements Action {
    readonly type = RFOActionTypes.RFOPatchAllDeltaRequest;

    constructor(public payload: { deltaState: boolean }) {}
}

export class RFOPatchDeltaSuccess implements Action {
    readonly type = RFOActionTypes.RFOPatchDeltaSuccess;
}

export class RFOPatchAllDeltaSuccess implements Action {
    readonly type = RFOActionTypes.RFOPatchAllDeltaSuccess;

    constructor(public payload: { deltaState: boolean }) {}
}

export class RFOPatchDeltaError implements Action {
    readonly type = RFOActionTypes.RFOPatchDeltaError;

    constructor(public payload: string) {}
}

export class RFOPatchAllDeltaError implements Action {
    readonly type = RFOActionTypes.RFOPatchAllDeltaError;

    constructor(public payload: string) {}
}

export class RFOValidateDeltaRequest implements Action {
    readonly type = RFOActionTypes.RFOValidateDeltaRequest;
}

export class RFOValidateDeltaSuccess implements Action {
    readonly type = RFOActionTypes.RFOValidateDeltaSuccess;
}

export class RFOValidateDeltaError implements Action {
    readonly type = RFOActionTypes.RFOValidateDeltaError;

    constructor(public payload: string) {}
}

export class RFOFileUploaded implements Action {
    readonly type = RFOActionTypes.RFOFileUploaded;
}

export class RFOClearInProgressSpinner implements Action {
    readonly type = RFOActionTypes.RFOClearInProgressSpinner;
}

export class RFOTemplateFileRequest implements Action {
    readonly type = RFOActionTypes.RFOTemplateFileRequest;

    constructor(public payload: string) {}
}

export class RFOTemplateFileSuccess implements Action {
    readonly type = RFOActionTypes.RFOTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class RFOTemplateFileError implements Action {
    readonly type = RFOActionTypes.RFOTemplateFileError;

    constructor(public payload: string) {}
}

export class RFODeleteDataRequest implements Action {
    readonly type = RFOActionTypes.RFODeleteDataRequest;
}

export class RFODeleteDataSuccess implements Action {
    readonly type = RFOActionTypes.RFODeleteDataSuccess;

    constructor(public payload: RFODeletePagination) {}
}

export class RFODeleteDataError implements Action {
    readonly type = RFOActionTypes.RFODeleteDataError;

    constructor(public payload: string) {}
}

export class RFOPatchDeleteRecordRequest implements Action {
    readonly type = RFOActionTypes.RFOPatchDeleteRecordRequest;

    constructor(public payload: { rfoId: string; deleteState: boolean }) {}
}

export class RFOPatchDeleteRecordSuccess implements Action {
    readonly type = RFOActionTypes.RFOPatchDeleteRecordSuccess;
}

export class RFOPatchDeleteRecordError implements Action {
    readonly type = RFOActionTypes.RFOPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class RFOPatchDeleteAllRecordsRequest implements Action {
    readonly type = RFOActionTypes.RFOPatchDeleteAllRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class RFOPatchDeleteAllRecordsSuccess implements Action {
    readonly type = RFOActionTypes.RFOPatchDeleteAllRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class RFOPatchDeleteAllRecordsError implements Action {
    readonly type = RFOActionTypes.RFOPatchDeleteAllRecordsError;

    constructor(public payload: string) {}
}

export class RFOValidateButtonStateRequest implements Action {
    readonly type = RFOActionTypes.RFOValidateButtonStateRequest;
}

export class RFOValidateButtonStateSuccess implements Action {
    readonly type = RFOActionTypes.RFOValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class RFOValidateButtonStateError implements Action {
    readonly type = RFOActionTypes.RFOValidateButtonStateError;

    constructor(public payload: string) {}
}

export class RFODownloadOutputDataRequest implements Action {
    readonly type = RFOActionTypes.RFODownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class RFODownloadOutputDataSuccess implements Action {
    readonly type = RFOActionTypes.RFODownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class RFODownloadOutputDataError implements Action {
    readonly type = RFOActionTypes.RFODownloadOutputDataError;

    constructor(public payload: string) {}
}

export type RFOActions =
    | RFOUploadLogRequest
    | RFOUploadLogSuccess
    | RFOUploadLogError
    | RFODeltaRequest
    | RFODeltaSuccess
    | RFODeltaError
    | RFOSetStatusAndStartDate
    | RFODeltaFilterPropertyUpdate
    | RFODeleteFilterPropertyUpdate
    | RFOPatchDeltaRequest
    | RFOPatchDeltaSuccess
    | RFOPatchDeltaError
    | RFOPatchAllDeltaRequest
    | RFOPatchAllDeltaSuccess
    | RFOPatchAllDeltaError
    | RFOValidateDeltaRequest
    | RFOValidateDeltaSuccess
    | RFOValidateDeltaError
    | RFOFileUploaded
    | RFOClearInProgressSpinner
    | RFOTemplateFileRequest
    | RFOTemplateFileSuccess
    | RFOTemplateFileError
    | RFODeleteDataRequest
    | RFODeleteDataSuccess
    | RFODeleteDataError
    | RFOPatchDeleteRecordRequest
    | RFOPatchDeleteRecordSuccess
    | RFOPatchDeleteRecordError
    | RFOPatchDeleteAllRecordsRequest
    | RFOPatchDeleteAllRecordsSuccess
    | RFOPatchDeleteAllRecordsError
    | RFOValidateButtonStateRequest
    | RFOValidateButtonStateSuccess
    | RFOValidateButtonStateError
    | RFODownloadOutputDataRequest
    | RFODownloadOutputDataSuccess
    | RFODownloadOutputDataError
    | RFOAddStartedLog;
