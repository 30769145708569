<div class="chart-container">
    <div class="bar-chart-container">
        <ngx-charts-bar-vertical-2d
            [scheme]="colorScheme"
            [results]="chartData$ | async"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [roundDomains]="true"
            [yAxisTickFormatting]="axisFormat"
            [roundEdges]="false"
            [barPadding]="1"
        >
        </ngx-charts-bar-vertical-2d>
    </div>

    <div class="line-chart-container padding-left">
        <ngx-charts-line-chart
            [view]="[1110, 300]"
            [scheme]="colorScheme"
            [results]="lineData$ | async"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisTickFormatting]="yAxisTickPercentage"
            [roundDomains]="true"
            [yScaleMin]="0"
            [yScaleMax]="100"
        >
        </ngx-charts-line-chart>
    </div>
</div>
