<main class="main">
    <div class="title-container">
        <div class="title">SCMT Home</div>
    </div>
    <div class="results-container area-centered">
        <div class="tables-container">
 
            <div>
                <div class="subtitle blue">Systems Completion</div>
                <div class="table-label">RFSU Status</div>
                <table mat-table multiTemplateDataRows [dataSource]="systemCompletionData">
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>Category</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="tree-icon">
                                <div
                                    
                                    [ngClass]="{
                                        hidden: !element.categoryBreakdown.length
                                    }"
                                >
                                    <mat-icon (click)="element.expanded = true" *ngIf="!element.expanded"
                                        >chevron_right</mat-icon
                                    >
                                    <mat-icon (click)="element.expanded = false" *ngIf="element.expanded"
                                        >expand_more</mat-icon
                                    >
                                </div>
                                <span>
                                    {{ element.category }}
                                </span>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef>Total</th>
                        <td mat-cell *matCellDef="let element">{{ element.total }}</td>
                    </ng-container>
                    <ng-container matColumnDef="complete">
                        <th mat-header-cell *matHeaderCellDef>Complete</th>
                        <td mat-cell *matCellDef="let element">{{ element.complete }}</td>
                    </ng-container>
                    <ng-container matColumnDef="exceptions">
                        <th mat-header-cell *matHeaderCellDef>Exc</th>
                        <td mat-cell *matCellDef="let element">{{ element.exceptions }}</td>
                    </ng-container>
                    <ng-container matColumnDef="remaining">
                        <th mat-header-cell *matHeaderCellDef>Remaining</th>
                        <td mat-cell *matCellDef="let element">{{ element.remaining }}</td>
                    </ng-container>
                    <ng-container matColumnDef="completePercentage">
                        <th mat-header-cell *matHeaderCellDef>Complete %</th>
                        <td mat-cell *matCellDef="let element">{{ element.completePercentage }} %</td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                            [attr.colspan]="systemCompletionDataColumns.length"
                        >
                            <div
                                class="item-element-detail"
                                [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'"
                            >
                                <div class="item-element-description">
                                    <table
                                        mat-table
                                        [dataSource]="element.categoryBreakdown"
                                        multiTemplateDataRows
                                        class="subtable"
                                    >
                                        <ng-container
                                            matColumnDef="{{ column }}"
                                            *ngFor="let column of systemCompletionDataColumns"
                                        >
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let nestedElement">
                                                <span>
                                                    {{ nestedElement[column] }}
                                                    {{ column === 'completePercentage' ? '%' : '' }}
                                                </span>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="systemCompletionDataColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: systemCompletionDataColumns"></tr>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="systemCompletionDataColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: systemCompletionDataColumns; let element"
                        class="item-element-row"
                        [class.item-expanded-row]="element.expanded"
                    ></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="item-detail-row"></tr>
                </table>
                <div *ngIf="isSystemCompletionLoading$ | async" class="table-complete-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </div>

            <div>
                <div class="subtitle blue"><br></div>
                <div class="table-label"><br></div>
                <table mat-table multiTemplateDataRows [dataSource]="systemCompletionDataTags">
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>Category</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="tree-icon">
                                <div
                                    
                                    [ngClass]="{
                                        hidden: !element.categoryBreakdown.length
                                    }"
                                >
                                    <mat-icon (click)="element.expanded = true" *ngIf="!element.expanded"
                                        >chevron_right</mat-icon
                                    >
                                    <mat-icon (click)="element.expanded = false" *ngIf="element.expanded"
                                        >expand_more</mat-icon
                                    >
                                </div>
                                <span>
                                    {{ element.category }}
                                </span>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef>Total</th>
                        <td mat-cell *matCellDef="let element">{{ element.total }}</td>
                    </ng-container>
                    <ng-container matColumnDef="complete">
                        <th mat-header-cell *matHeaderCellDef>Complete</th>
                        <td mat-cell *matCellDef="let element">{{ element.complete }}</td>
                    </ng-container>
                    <ng-container matColumnDef="exceptions">
                        <th mat-header-cell *matHeaderCellDef>Exc</th>
                        <td mat-cell *matCellDef="let element">{{ element.exceptions }}</td>
                    </ng-container>
                    <ng-container matColumnDef="remaining">
                        <th mat-header-cell *matHeaderCellDef>Remaining</th>
                        <td mat-cell *matCellDef="let element">{{ element.remaining }}</td>
                    </ng-container>
                    <ng-container matColumnDef="completePercentage">
                        <th mat-header-cell *matHeaderCellDef>Complete %</th>
                        <td mat-cell *matCellDef="let element">{{ element.completePercentage }} %</td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td
                            mat-cell
                            *matCellDef="let element; let i = dataIndex"
                            [attr.colspan]="systemCompletionDataColumns.length"
                        >
                            <div
                                class="item-element-detail"
                                [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'"
                            >
                                <div class="item-element-description">
                                    <table
                                        mat-table
                                        [dataSource]="element.categoryBreakdown"
                                        multiTemplateDataRows
                                        class="subtable"
                                    >
                                        <ng-container
                                            matColumnDef="{{ column }}"
                                            *ngFor="let column of systemCompletionDataColumns"
                                        >
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let nestedElement">
                                                <span>
                                                    {{ nestedElement[column] }}
                                                    {{ column === 'completePercentage' ? '%' : '' }}
                                                </span>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="systemCompletionDataColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: systemCompletionDataColumns"></tr>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="systemCompletionDataColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: systemCompletionDataColumns; let element"
                        class="item-element-row"
                        [class.item-expanded-row]="element.expanded"
                    ></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="item-detail-row"></tr>
                </table>
                <div *ngIf="isSystemCompletionLoading$ | async" class="table-complete-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </div>

            <div>
                <div class="subtitle blue"><br></div>
                <div class="table-label">NPW Status - To Implement</div>
                <table mat-table multiTemplateDataRows [dataSource]="npwData">
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>Category</th>
                        <td mat-cell *matCellDef="let element">{{ element.category }}</td>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef>Total</th>
                        <td mat-cell *matCellDef="let element">{{ element.total }}</td>
                    </ng-container>
                    <ng-container matColumnDef="complete">
                        <th mat-header-cell *matHeaderCellDef>Complete</th>
                        <td mat-cell *matCellDef="let element">{{ element.complete }}</td>
                    </ng-container>
                    <ng-container matColumnDef="exceptions">
                        <th mat-header-cell *matHeaderCellDef>Exc</th>
                        <td mat-cell *matCellDef="let element">{{ element.exceptions }}</td>
                    </ng-container>
                    <ng-container matColumnDef="remaining">
                        <th mat-header-cell *matHeaderCellDef>Remaining</th>
                        <td mat-cell *matCellDef="let element">{{ element.remaining }}</td>
                    </ng-container>
                    <ng-container matColumnDef="completePercentage">
                        <th mat-header-cell *matHeaderCellDef>Complete %</th>
                        <td mat-cell *matCellDef="let element">{{ element.completePercentage }} %</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="npwDataColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: npwDataColumns; let element"
                        class="item-element-row"
                    ></tr>
                </table>
                <div *ngIf="isNPWLoading$ | async" class="table-complete-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </div>
            
            
        </div>

        <div class="tables-container">
            

            <div>
                <app-reports-rundown-content
                [homePage]="true"
                >                
                </app-reports-rundown-content>
            </div>

            
        </div>

<!--
        <div class="tables-container">
            
            <div>
                <div class="table-label">RFSU Forecast Trend</div>
                <ngx-charts-line-chart
                    [results]="data.sc.chartData"
                    [scheme]="colorScheme"
                    [xAxis]="true"
                    [yAxis]="true"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [yScaleMin]="0"
                    [yScaleMax]="100"
                    [view]="[1200, 200]"
                    [yAxisTickFormatting]="yAxisTickPercentage"
                    >
                    <ng-template #tooltipTemplate let-model="model">                            
                        <span>{{ model.series }} - {{ model.name }}: {{ model.value }} %</span>
                    </ng-template>
                    <ng-template #seriesTooltipTemplate let-model="model">
                        <p *ngFor="let serie of model; let index = index" class="chart-series-tooltip-body">
                            <span class="chart-series-color" [style.background-color]="serie.color"></span>
                            <span>{{ serie.series }} : {{ serie.value }}%</span>
                        </p>
                    </ng-template>
                </ngx-charts-line-chart>
                <div *ngIf="data.green.loading" class="table-complete-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </div>
        </div>
 -->
    </div>
</main>
