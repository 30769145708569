import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum TagsActionTypes {
    TagsUploadLogRequest = '[Tags] Upload Log Request',
    TagsUploadLogSuccess = '[Tags] Upload Log Success',
    TagsUploadLogError = '[Tags] Upload Log Error',
    TagsSetStatusAndStartDate = '[Tags] Set Status and Start Date',
    TagsFileUploaded = '[Tags] File Uploaded',
    TagsClearInProgressSpinner = '[Tags] Clear In Progress Spinner',
    TagsTemplateFileRequest = '[Tags] Template File Request',
    TagsTemplateFileSuccess = '[Tags] Template File Success',
    TagsTemplateFileError = '[Tags] Template File Error',
    TagsValidateDeltaRequest = '[Tags] Validate Delta Request',
    TagsValidateDeltaSuccess = '[Tags] Validate Delta Success',
    TagsValidateDeltaError = '[Tags] Validate Delta Error',
    TagsDownloadOutputDataRequest = '[Tags] Output data Request',
    TagsDownloadOutputDataSuccess = '[Tags] Output data Success',
    TagsDownloadOutputDataError = '[Tags] Output data File Error',
    TagsAddStartedLog = '[Tags] Add Started Log',
    TagsValidateButtonStateSuccess = '[Tags] Validate Button State Success',
    TagsValidateButtonStateError = '[Tags] Validate Button State Error',
}

export class TagsAddStartedLog implements Action {
    readonly type = TagsActionTypes.TagsAddStartedLog;
}

export class TagsUploadLogRequest implements Action {
    readonly type = TagsActionTypes.TagsUploadLogRequest;
}

export class TagsUploadLogSuccess implements Action {
    readonly type = TagsActionTypes.TagsUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class TagsUploadLogError implements Action {
    readonly type = TagsActionTypes.TagsUploadLogError;

    constructor(public payload: string) {}
}

export class TagsFileUploaded implements Action {
    readonly type = TagsActionTypes.TagsFileUploaded;
}

export class TagsClearInProgressSpinner implements Action {
    readonly type = TagsActionTypes.TagsClearInProgressSpinner;
}

export class TagsTemplateFileRequest implements Action {
    readonly type = TagsActionTypes.TagsTemplateFileRequest;

    constructor(public payload: string) {}
}

export class TagsTemplateFileSuccess implements Action {
    readonly type = TagsActionTypes.TagsTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class TagsTemplateFileError implements Action {
    readonly type = TagsActionTypes.TagsTemplateFileError;

    constructor(public payload: string) {}
}

export class TagsDownloadOutputDataRequest implements Action {
    readonly type = TagsActionTypes.TagsDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class TagsDownloadOutputDataSuccess implements Action {
    readonly type = TagsActionTypes.TagsDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class TagsDownloadOutputDataError implements Action {
    readonly type = TagsActionTypes.TagsDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class TagsSetStatusAndStartDate implements Action {
    readonly type = TagsActionTypes.TagsSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class TagsValidateDeltaRequest implements Action {
    readonly type = TagsActionTypes.TagsValidateDeltaRequest;
}

export class TagsValidateDeltaSuccess implements Action {
    readonly type = TagsActionTypes.TagsValidateDeltaSuccess;
}

export class TagsValidateDeltaError implements Action {
    readonly type = TagsActionTypes.TagsValidateDeltaError;

    constructor(public payload: string) {}
}

export class TagsValidateButtonStateSuccess implements Action {
    readonly type = TagsActionTypes.TagsValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; isAutomaticUploadInProgress: boolean; }) {}
}

export class TagsValidateButtonStateError implements Action {
    readonly type = TagsActionTypes.TagsValidateButtonStateError;

    constructor(public payload: string) {}
}

export type TagsActions =
    | TagsUploadLogRequest
    | TagsUploadLogSuccess
    | TagsUploadLogError
    | TagsFileUploaded
    | TagsClearInProgressSpinner
    | TagsTemplateFileRequest
    | TagsTemplateFileSuccess
    | TagsTemplateFileError
    | TagsSetStatusAndStartDate
    | TagsValidateDeltaRequest
    | TagsValidateDeltaSuccess
    | TagsValidateDeltaError
    | TagsDownloadOutputDataRequest
    | TagsDownloadOutputDataSuccess
    | TagsDownloadOutputDataError
    | TagsAddStartedLog
    | TagsValidateButtonStateSuccess
    | TagsValidateButtonStateError;
