import { Component, OnInit, EventEmitter, ViewChild, NgZone, ElementRef } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ApplicationState } from 'src/app/store/model';
import { Store, select } from '@ngrx/store';
import {
    RFOFilterRequest,
    RFOFilterPropertyUpdate,
    RFOFilterReset,
    RFOAddCommentRequest,
    RFORemoveCommentRequest,
    RFOAddBulkCommentRequest,
    RFOExportToExcelRequest,
} from 'src/app/store/RFO/actions';
import { Constants } from 'src/app/constants';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { RFOFilter, RFODTO, ShowHideColumns } from 'src/app/store/RFO/model';
import { TCOUser, OrderDirection, CalendarColumn, CheckListColumn, RFO, UserDetail, Waypoint } from 'src/app/store/common.model';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { take, map, takeWhile, catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PascrStatusFilterReset } from 'src/app/store/pascr-status/actions';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from '../../modules/shared/components/comment-popup/comment-popup.component';
import { forkJoin } from 'rxjs';
import { RoleService } from 'src/app/services/shared/role.service';
import { Zone } from 'src/app/enums';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { CommentsHistoryComponent } from '../comments-history/comments-history.component';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import {
    DetailedStatusFilterReset,
    DetailedStatusResetLockedFilter,
    DetailedStatusSetMakeRequest,
} from 'src/app/store/detailed-status/actions';
import { ChangeManagementSetMakeRequest, ChangeManagementFilterReset } from 'src/app/store/change-management/actions';
import { ClearableMatDatepickerComponent } from '../clearable-mat-datepicker/clearable-mat-datepicker.component';
import { getDetailedStatusFilterInstance } from 'src/app/extensions';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CommentDetailDTO } from 'src/app/store/detailed-status/model';
import { ForecastHistoryComponent } from '../forecast-history/forecast-history.component';
import * as moment from 'moment';

@Component({
    selector: 'app-rfo',
    templateUrl: './rfo.component.html',
    styleUrls: ['./rfo.component.scss'],
})
export class RFOComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    isLoading = false;
    isUsersPerProjectLoading = false;
    isForecastEditable =false;
    autocompleteDisplayedColumns = ['name', 'description'];
    todayDate = new Date();

    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    sortBy$ = this.store.select((state) => state.rfoState.filter.sortBy);
    direction$ = this.store.select((state) => state.rfoState.filter.direction);
    columnRFOFreeze$ =  this.store.select((state) => state.rfoState.filter.columnRFOFreeze);
    columnRFOForecast$ = this.store.select((state) => state.rfoState.filter.columnRFOForecast);
    columnRfsuForecastDate$ = this.store.select((state) => state.rfoState.filter.columnRfsuForecastDate);
    columnRFOPlan$ = this.store.select((state) => state.rfoState.filter.columnRFOPlan);
    columnRFOActual$ = this.store.select((state) => state.rfoState.filter.columnRFOActual);
    columnRFOs$ = this.store.select((state) => state.rfoState.filter.columnRFOs);
    columnRFOsNames$ = this.store.select((state) => state.rfoState.filter.columnRFOsNames);
    columnComments$ = this.store.select((state) => state.rfoState.filter.columnComments);
    columnAreaBreakdown$ = this.store.select((state) => state.rfoState.filter.columnAreaBreakdown);
    columnAreaCompletionLead$ = this.store.select((state) => state.rfoState.filter.columnAreaCompletionLead);
    columnSCAreaManager$ = this.store.select((state) => state.rfoState.filter.columnSCAreaManager);
    columnEdossierInitiation$ = this.store.select((state) => state.rfoState.filter.columnEdossierInitiation);
    columnEdossierStatus$ = this.store.select((state) => state.rfoState.filter.columnEdossierStatus);


    projectTeamNames: string[] = [];
    projectTeamNamesCurrent: string[];
    waypointScopes:string[] = [];
    wayPointNames:Waypoint[] = [];
    scManagers: TCOUser[] = [];
    areaCompletionLeads: TCOUser[] = [];
    sysOwners: TCOUser[] = [];

    displayedColumns: string[] = [
        'RFO',
        'RFOName',
        'RFOPlan',
        'RFOForecast',
        'RFOActual',
        'RFOFreeze',
        'edossierInitiationDate',
        'edossierStatus',
        'rfsuForecastDate',
        'noOfSubs',
        'rfsuRemaining',
        'bitr',
        'citr',
        'npw',
        'aqvd',
        'apli',
        'bpli',
        'cpli',
        'dpli',
        'cow',
        'ex',
        'pascr',
        'ac',
        'redline',
        'lun',
        'areaBreakdown',
        'areaCompletionLead',
        'scAreaManager',
        'comment',
    ];
    data: RFODTO[] = [];
    resultsLength = 0;
    sortBy = 'RFO';
    direction = OrderDirection.Desc;
    pageSize = 25;
    isReadOnly = false;

    showHideColumns = new ShowHideColumns();

    RFODataPagination$ = this.store.select((store) => store.rfoState.dataPagination);
    RFOLoader$ = this.store.select((store) => store.rfoState.isLoading);
    RFOFilter$ = this.store.select((store) => store.rfoState.filter);

    columnRFOForecast: CalendarColumn = null;
    columnRFOFreeze: CalendarColumn = null;
    columnRfsuForecastDate: CalendarColumn = null;
    columnRFOPlan: CalendarColumn = null;
    columnRFOActual: CalendarColumn = null;
    columnRFOs: CheckListColumn = null;
    columnRFOsNames: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    columnAreaCompletionLead: CheckListColumn = null;
    columnSCAreaManager: CheckListColumn = null;
    columnEdossierInitiation: CalendarColumn = null;
    columnEdossierStatus: CheckListColumn = null;
    selectedRowIndex = -1;

    showDetailedPage: string;
    blueScreen: boolean;
    blueScope: string;
    blueRFO: string;
    blueRFOs: RFO[];
    blueStatus: string[];
    blueExclude: string;
    blueRFSUCompleted: string;
    blueZone: string;
    blueCategory: string;
    blueChangeType: string;

    customdetailedStatusScopes = [
        'AQVD' , 
        'BITR' , 
        'CITR' , 
        'PLI' , 
        'EX' , 
        'Redline' ,
        'COW',
        'NPW',
        'PASCR'
       ];

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild("detailedPage") detailedPage: ElementRef;

    constructor(
        private store: Store<ApplicationState>,
        private formService: FormService,
        private lookupService: LookupService,
        private toastService: ToastService,
        private router: Router,
        private ngZone: NgZone,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private projectTeamsService: ProjectTeamsService,
        private commentService: CommentService,
        private route: ActivatedRoute,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new RFOFilter());
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
    }
    activeScopeDetailedView() {       
        return this.customdetailedStatusScopes.includes(this.showDetailedPage);       
    }

    ngOnInit() {
        this.checkForecastDateAccessibility();
        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.isReadOnly = this.roleService.isReadOnly();

        this.RFODataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.setReadyForRFOFlags();
            this.resultsLength = data.totalCount;
        });

        this.RFOLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => (this.isLoading = isLoading));

        this.RFOFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam(filter.projectTeamNames || []);
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.paginator.pageIndex = filter.page;
            this.pageSize = filter.take;
            this.showHideColumns = filter.showHideColumns;
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new RFOFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );

            this.paginator.pageIndex = 0;
            this.store.dispatch(new RFOFilterRequest());
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => (this.sortBy = sortBy));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => (this.direction = direction));

        this.columnRFOForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFOForecast = data;
        });
        this.columnRFOFreeze$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFOFreeze = data;
        });
        this.columnRfsuForecastDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRfsuForecastDate = data;
        });
        this.columnComments$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnComments = data;
        });
        this.columnRFOPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFOPlan = data;
        });

        this.columnRFOActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFOActual = data;
        });

        this.columnRFOs$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnRFOs = items;
        });

        this.columnRFOsNames$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnRFOsNames = items;
        });

        this.columnAreaBreakdown$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnAreaBreakdown = items;
        });

        this.columnAreaCompletionLead$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnAreaCompletionLead = items;
        });

        this.columnSCAreaManager$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnSCAreaManager = items;
        });

        this.columnEdossierInitiation$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnEdossierInitiation = items;
        });

        this.columnEdossierStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnEdossierStatus = items;
        });

        this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            if (paramMap.get('rfo')) {
                this.resetFilters();
                var rfo = new RFO();
                rfo.name = decodeURIComponent(paramMap.get('rfo'));
                this.filterForm.controls.RFOs.setValue([rfo]);
                this.paginator.pageIndex = 0;
                this.store.dispatch(
                    new RFOFilterPropertyUpdate({
                        key: 'page',
                        value: 0,
                    })
                );
                this.store.dispatch(new RFOFilterRequest(this.filterForm.value));
            } else if (this.resultsLength < this.pageSize) {
                this.store.dispatch(new RFOFilterRequest());
            }
        });
    }

    scrollToDetails() {
        setTimeout(() => {
            this.detailedPage.nativeElement.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 500);
    }

    resetDetailedDataITRs(scope: string) {
        this.showDetailedPage = null;

            this.showDetailedPage = scope
    }

    openDetailedStatusPage(rfo: string, scope: string, category?: string) {
        this.blueScope = scope;       
        this.blueZone = Zone.RFO.toString();
        this.blueCategory = category;

        if(scope == 'PLI' && category == 'C') { this.blueExclude = 'PSSR'; } else { this.blueExclude = null; }

        if(scope == 'EX' || scope == 'Redline') { this.blueRFSUCompleted = null; } else { this.blueRFSUCompleted = this.filterForm.controls.rfsuCompleted.value }

        if(scope == 'AQVD' || scope == 'BITR' || scope == 'CITR') { 
            this.blueStatus = ['Not Started'];
            this.blueRFO = rfo; 

            this.blueRFOs = null;
        } else {
            this.blueRFOs = [rfo].map((s) => ({ name: s } as RFO));

            this.blueRFO = null;
            this.blueStatus = null;
        }
        
        this.resetDetailedDataITRs(scope);
    }

    resetDetailedDataDocs(scope: string) {
        this.showDetailedPage = null;

        setTimeout(() => {
            this.showDetailedPage = scope
            this.store.dispatch(new ChangeManagementSetMakeRequest());
            this.store.dispatch(new ChangeManagementFilterReset());
            this.scrollToDetails();
          }, 100);
    }

    openChangeManagementPage(rfo: string, changeType: string, lockFilters = true) {
        this.blueRFO = rfo;
        this.blueChangeType = changeType;
        this.resetDetailedDataDocs(changeType);
    }

    resetDetailedDataPASCR(scope: string) {
        this.showDetailedPage = null;

        setTimeout(() => {
            this.showDetailedPage = scope
            this.store.dispatch(new PascrStatusFilterReset());
            this.scrollToDetails();
          }, 100);
    }

    gotoPascr(element : any)
    {
        this.blueRFO = element.rfo;
        this.resetDetailedDataPASCR('PASCR');
    }

    /*
    openDetailedStatusPage(rfo: string, scope: string, category?: string) {
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.store.dispatch(
            new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(scope), scope })
        );
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        this.ngZone.run(() =>
            this.router.navigate([`/detailedstatus`], {
                queryParams: {
                    scope,
                    rfos: rfo,
                    category,
                    zone: Zone.RFO,
                    lockFilters: true,
                    rfsucompleted: this.filterForm.controls.rfsuCompleted.value,
                    allowCommentsBulkUpdate: true,
                },
            })
        );
    }

    openChangeManagementPage(rfo: string, changeType: string, lockFilters = true) {
        this.store.dispatch(new ChangeManagementSetMakeRequest());
        this.store.dispatch(new ChangeManagementFilterReset());
        this.router.navigate(['/changemanagement'], {
            queryParams: { rfo, changeType, lockFilters, allowCommentsBulkUpdate: true },
        });
    }

    gotoPascr(element : any)
    {
        this.ngZone.run(() =>
        this.router.navigate([`/pascrstatus`], {
            queryParams: {
                RFO : element.rfo
            },
        })
    );
    }


        openDetailedStatusPageExcludedRFSU(rfo: string, scope: string, category?: string) {
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.store.dispatch(
            new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(scope), scope })
        );
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        this.ngZone.run(() =>
            this.router.navigate([`/detailedstatus`], {
                queryParams: {
                    scope,
                    rfos: rfo,
                    category,
                    zone: Zone.RFO,
                    lockFilters: true,
                    allowCommentsBulkUpdate: true,
                },
            })
        );
    }

    openDetailedStatusPageExcludedPSSR(rfo: string, scope: string, category?: string) {
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.store.dispatch(
            new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(scope), scope })
        );
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        this.ngZone.run(() =>
            this.router.navigate([`/detailedstatus`], {
                queryParams: {
                    scope,
                    rfos: rfo,
                    category,
                    zone: Zone.RFO,
                    lockFilters: true,
                    rfsucompleted: this.filterForm.controls.rfsuCompleted.value,
                    allowCommentsBulkUpdate: true,
                    excludeValue: 'PSSR'
                },
            })
        );
    }

    openDetailedStatusNSOnlyPage(rfo: string, status: string, scope: string, category?: string) {
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.store.dispatch(
            new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(scope), scope })
        );
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        this.ngZone.run(() =>
            this.router.navigate([`/detailedstatus`], {
                queryParams: {
                    scope,
                    rfo: rfo,
                    status: status,
                    category,
                    zone: Zone.RFO,
                    lockFilters: true,
                    rfsucompleted: this.filterForm.controls.rfsuCompleted.value,
                    allowCommentsBulkUpdate: true,
                },
            })
        );
    }

    */

    openLUNPage(rfo: string) {
        this.lookupService
            .getLunUrlByRFO(rfo)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((url) => {
                window.open(url);
            });
    }

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((RFOs: RFO[]) => RFOs));
    };

    reverseValue(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };
        this.store.dispatch(
            new RFOFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showHideColumns,
            })
        );
    }

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.isUsersPerProjectLoading = true;
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new RFOFilterReset());
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.setValue(new RFOFilter());
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new RFOFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.filterForm, key, value);
                }
            }
        }
    }

    search() {
        this.updateFilterByFormProperties();

        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new RFOFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new RFOFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new RFOFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new RFOFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new RFOFilterRequest());
    }

    


    checkForecastDateAccessibility() {
        this.isForecastEditable = this.roleService.isInRole(Constants.applicableGroups.Admin) ||
        this.roleService.isInRole(Constants.applicableGroups.RFOForecastDate);       
    }

    forecastDateHistory(event: MouseEvent, element: RFODTO, type: string) {
        if (!this.isForecastEditable) {
            return;
        }
        event.preventDefault();
        this.popupSvc.openPopup(
            new PopupSettings(ForecastHistoryComponent, 550, 500, {
                subsystem: element.rfo,
                dateType: type
            })
        );
    }
    openBlueZonePage(row : any, rfsuRemaining:boolean=false) {
        this.ngZone.run(() =>
        this.router.navigate([`/bluezone`], {
            queryParams: {
                rfo : row.rfo,
                rfoName : row.rfoName,
                rfsuRemaining:rfsuRemaining,
                rfsucompleted: this.filterForm.controls.rfsuCompleted.value,
            },
        })
    );
    }

    openDatepicker(element: RFODTO) {
        if (this.isReadOnly || element.rfoActual || !this.isForecastEditable) {
            return;
        }

        this.popupSvc.openPopup(
            new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                data: this.data,
                selectedDate: element.rfoForecast ? element.rfoForecast : '',
                selectedDateRFO: element.rfo,
                dateType: 'rfo',
                minDate: new Date(),
            })
        );
    }

    openRFOFreezeDatepicker(element: RFODTO) {
        if (!this.checkIsRFOFreeze(element) || !this.roleService.isInRole(Constants.applicableGroups.Admin)) {
            return;
        }
        this.popupSvc.openPopup(
            new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                data: this.data,
                selectedDate: element.rfoFreeze ? element.rfoFreeze : '',
                selectedDateRFO: element.rfo,
                dateType: 'rfoFreeze',
                minDate: new Date(),
            })
        );
    }

    private checkIsRFOFreeze(element: RFODTO)
    {
        var today =  moment();  
        // RFOActual is blank and RFOForecast is greater than today's Date then IsRFOFreeze = active
        if(element.rfoActual === null && element.rfoForecast !== null && moment.utc(element.rfoForecast) > today)
        {
            return true;
        }
        return false;
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    displaySelectedRFOCompleted(RFOCompleted: boolean) {
        if (RFOCompleted === null) {
            return 'All';
        }
        return RFOCompleted ? 'Yes' : 'No';
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    displayMultipleSelected(values: RFO[]) {
        return values.map((x) => x.name).join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    private getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'RFOs');

        forkJoin([
            this.lookupService.getSCManagers(projectTeamNames),
            this.lookupService.getACLeads(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
            this.lookupService.searchWaypointScopesForRfo()            
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([scManagers, areaCompletionLeads, sysOwners, waypointScope]) => {
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                    this.areaCompletionLeads = areaCompletionLeads;
                    this.checkIfSelectedUsersValid(this.areaCompletionLeads, 'areaCompletionLead', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                    this.waypointScopes = waypointScope;                    
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    private checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName].value.length &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm.controls[formControlName].setValue(valid);
        }
    }

    openCommentPopup(element: RFODTO, event: any) {
        const comment = element.comment || '';
        if (this.isReadOnly) return;

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: element.mentions,
                url: `rfo?rfo=${element.rfo}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new RFOAddBulkCommentRequest({
                                description: value,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new RFOAddCommentRequest({
                                entityId: element.rfo,
                                description: value,
                                mentions,
                            })
                        );
                    }
                },
            })
        );

        event.stopPropagation();
    }

    private getLatestFilterData(): RFOFilter {
        let filter: RFOFilter;
        this.store.pipe(select((x) => x.rfoState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getAreaBreakdown = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);

    getRFOsForHeaderFilter = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRFOsWithRFOZoneFilter(search, take, page, this.getLatestFilterData());

    getCommentsForHeaderFilter = (search = '', take = 30, page = 0) =>
        this.lookupService.searchCommentsWithRFOZoneFilter(search, take, page, this.getLatestFilterData());

    getRFOsNamesForHeaderFilter = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRFOsNamesWithRFOZoneFilter(search, take, page, this.getLatestFilterData());

    getAreaCompletionLeads = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaCompletionLeadsWithRFOZoneFilter(search, take, page, this.getLatestFilterData());
    
    getAreaBreakdowns = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakdownsWithRFOZoneFilter(search, take, page, this.getLatestFilterData());

    getSCExecutionAreaManagers = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSCExecutionAreaManagersWithRFOZoneFilter(search, take, page, this.getLatestFilterData());

    getEdossierStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchEdossierStatusesWithRFOZoneFilter(search, take, page, this.getLatestFilterData());

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 630, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                excludeBlanks: excludeBlanks,
                onlyBlanks: onlyBlanks,
                isSortingOff: false,
                showCounts: showCounts,
                showInputSearch,
                showBlankOptions: showBlankOptions,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.filterForm.controls[propertyName].setValue(value);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new RFOFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new RFOFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            },
            345,
            580)
        );
    }

    openHistoryPopup(element: RFODTO) {
        if (element.comment === null) {
            return;
        } else {
            this.isLoading = true;
            this.commentService.getCommentsBySubsystem(element.rfo.toString(), Zone.RFO).subscribe((data) => {
                this.isLoading = false;
                this.popupSvc.openPopup(
                    new PopupSettings(
                        CommentsHistoryComponent,
                        null,
                        null,
                        {
                            commentHist: data,
                            action: (id: number) => {
                                data = data.filter((s) => s.id !== id);
                                this.store.dispatch(
                                    new RFORemoveCommentRequest({
                                        id,
                                        lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                        rfo: element.rfo,
                                    })
                                );
                            },
                        },
                        200,
                        200
                    )
                );
            });
        }
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new RFOFilterPropertyUpdate({ key: 'sortBy', value: { active: columnName, direction: direction } })
            );
        } else {
            this.store.dispatch(
                new RFOFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'RFO', direction: OrderDirection.Asc },
                })
            );
        }
    }

    setReadyForRFOFlags() {
        this.data = this.data.map((element) => ({
            ...element,
            isReadyForRFO:
                element.bitr === 0 && element.citr === 0 && element.bpli === 0 && element.npw === 0 && element.ex === 0,
        }));
    }

    highlightRow(row: any) {
        this.selectedRowIndex = row;
    }

    exportToExcel() {
        this.updateFilterByFormProperties();
        this.store.dispatch(new RFOExportToExcelRequest());
    }

    resetColumns() {
        this.showHideColumns = new ShowHideColumns();
    }

    getwayPointsByScope (changeValue:string[]){
        this.wayPointNames = [];
            this.lookupService.searchWaypointsForRfo(changeValue).subscribe((result:Waypoint[])=>{
                this.wayPointNames = result.filter((s:Waypoint) => changeValue.map((w) => w).indexOf(s.waypointScope) > -1);
                this.wayPointNames = this.removeDuplicates(this.wayPointNames,"name");
            });
    }

    eDossierLink(rfo: string) {
        let Link = "https://chevron.sharepoint.com/sites/3PC-syscompcd/3gp/rfo/sitepages/RFOTracking.aspx?rfono=";
        return Link + encodeURIComponent(rfo); // rfo.replace("&", "%26").replace("/","_");
    };
    
    isRowUnderlined(data: RFODTO[], index: number) {
        if (index === data.length - 1) {
            return false;
        } else {
            return data[index].rfo != data[index + 1].rfo;
        }
    }
}
