<main class="main">
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    <div class="main-container">
        <mat-accordion>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>loop folder configuration</h4>
                </mat-expansion-panel-header>
                <div class="import-subtitle">
                    See
                    <b (click)="downloadRegisterTemplate('MASTER SWIFT.xlsx')">MASTER SWIFT.xlsx</b> structure.
                </div>
                <div class="results-container">
                    <table mat-table [dataSource]="commonConfigData" matSortDisableClear>
                        <ng-container matColumnDef="key">
                            <th mat-header-cell disableClear *matHeaderCellDef>Key</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="infotooltip" *ngIf="element.tooltip">
                                    {{ element.key }}
                                    <mat-icon matTooltip="{{ element.tooltip }}" matTooltipClass="scttooltip"
                                        >info
                                    </mat-icon>
                                </div>
                                <ng-container *ngIf="!element.tooltip">{{ element.key }}</ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <th mat-header-cell disableClear *matHeaderCellDef>Value</th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngIf="!element.isInEditMode">{{ element.value }}</div>
                                <div *ngIf="element.isInEditMode" class="editmode">
                                    <mat-form-field
                                        ><input
                                            matInput
                                            [(ngModel)]="element.value"
                                            [ngModelOptions]="{ standalone: true }"
                                            name="value"
                                            autocomplete="off"
                                        />
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell disableClear *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button
                                    *ngIf="!element.isInEditMode"
                                    mat-icon-button
                                    (click)="enterEditMode(element)"
                                    title="edit"
                                >
                                    <mat-icon svgIcon="edit"></mat-icon>
                                </button>
                                <button
                                    *ngIf="element.isInEditMode"
                                    mat-icon-button
                                    (click)="save(element)"
                                    title="accept"
                                >
                                    <mat-icon>check_circle_outline</mat-icon>
                                </button>
                                <button
                                    *ngIf="element.isInEditMode"
                                    mat-icon-button
                                    (click)="cancelEdit(element)"
                                    title="cancel"
                                >
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</main>
