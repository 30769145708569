import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeWhile} from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ProjectTeamMappingDTO } from 'src/app/models/project-team-mapping-dto';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ProjectTeamMappingService } from 'src/app/services/api/webapi-services/project-team-mapping-service';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
  selector: 'app-add-project-team-mapping',
  templateUrl: './add-project-team-mapping.component.html',
  styleUrls: ['./add-project-team-mapping.component.scss']
})
export class AddProjectTeamMappingComponent extends BaseComponent implements OnInit {
  loading: boolean;
  addProjectTeamMappingForm: UntypedFormGroup;
  projectTeams: string[] = [];
  uploadType: string;

  constructor(public dialogRef: MatDialogRef<AddProjectTeamMappingComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private lookupService: LookupService,
    private projectTeamMappingService: ProjectTeamMappingService,
    private toastService: ToastService) {
    super();

    this.uploadType = data.uploadType;

    this.addProjectTeamMappingForm = new UntypedFormGroup({
      uploadType: new UntypedFormControl(this.uploadType, Validators.required),
      projectTeamName: new UntypedFormControl('', Validators.required),
      sourceFileDataOwner: new UntypedFormControl('', Validators.required),
    });

  }

  ngOnInit(): void {
    this.lookupService
      .getProjectTeams()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((projectTeamNames: any) => this.projectTeams = projectTeamNames);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    this.loading = true;
    const form = new ProjectTeamMappingDTO();

    form.uploadType = this.addProjectTeamMappingForm.controls.uploadType.value;
    form.projectTeamName = this.addProjectTeamMappingForm.controls.projectTeamName.value;
    form.sourceFileDataOwner = this.addProjectTeamMappingForm.controls.sourceFileDataOwner.value;

    this.projectTeamMappingService
      .add(form)
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe(
        (data) => {
          this.loading = false;
          this.toastService.Success(
            `The project team mapping for ${form.uploadType} has been added.`
          );

          this.dialogRef.close({ success: true, data: data });
        },
        (error) => {
          this.loading = false;
          if (error.status === 409) {
            this.toastService.Error('Project team mapping entered already exists in SCMT.');
          } else {
            this.toastService.Error(
              'An error occurred while adding a project team mapping. Please contact a Program Administrator.'
            );
          }
        }
      );
  }


}
