<main class="main">
    <a class="navigator" [routerLink]="['/admin']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            Amend Contractor Mapping for Upload
        </div>
    </div>
    <div class="results-container">
        <table
            mat-table
            matSort
            [dataSource]="dataSource"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container matColumnDef="changeType">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Change Type</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="!element.isInEditMode">{{ element.changeType }}</div>
                    <div *ngIf="element.isInEditMode" class="editmode">
                        <mat-select [(value)]="element.changeType">
                            <mat-option *ngFor="let ct of changeTypes" [value]="ct">
                                {{ ct }}
                            </mat-option>
                        </mat-select>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="mapping">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Mapping</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="!element.isInEditMode">{{ element.mapping }}</div>
                    <div *ngIf="element.isInEditMode" class="editmode">
                        <mat-form-field
                            ><input
                                matInput
                                [(ngModel)]="element.mapping"
                                [ngModelOptions]="{ standalone: true }"
                                name="mapping"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="contractNo">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Contract No</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="!element.isInEditMode">{{ element.contractNo }}</div>
                    <div *ngIf="element.isInEditMode" class="editmode">
                        <mat-select
                            [(value)]="element.contractNo"
                            (openedChange)="onContractorsClosed($event, element)"
                        >
                            <mat-option *ngFor="let contractor of contractors" [value]="contractor.contractNo">
                                {{ contractor.contractNo }}
                            </mat-option>
                        </mat-select>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="projectTeamName">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Project Team</th>
                <td mat-cell *matCellDef="let element">{{ element.projectTeamName }}</td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.isInEditMode" mat-icon-button (click)="enterEditMode(element)" title="edit">
                        <mat-icon svgIcon="edit"></mat-icon>
                    </button>
                    <button *ngIf="element.isInEditMode" mat-icon-button (click)="save(element)" title="accept">
                        <mat-icon>check_circle_outline</mat-icon>
                    </button>
                    <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="remove">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="remove(element)" title="remove">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-toolbar>
            <span>Add new Contract Mapping</span>
            <button mat-icon-button (click)="add()" title="add">
                <mat-icon svgIcon="add"></mat-icon>
            </button>
        </mat-toolbar>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 15, 20]"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
</main>
