import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';

@Component({
    selector: 'app-rfsu-lookahead',
    templateUrl: './lookahead.component.html',
    styleUrls: ['./lookahead.component.scss'],
})
export class RFSULookaheadComponent {
    lookahead$ = this.store.select((state) => state.milestoneDashboardState.rfsulookahead);
    panelOpenState = false;

    constructor(private store: Store<ApplicationState>) {}
}
