import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../../base.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CriticalRFIExpectedTimeofCompletionHistoryRequest } from 'src/app/store/manual-priority-history/actions';

@Component({
  selector: 'app-etc-history',
  templateUrl: './etc-history.component.html',
  styleUrls: ['./etc-history.component.scss']
})

export class EtcHistoryComponent extends BaseComponent implements OnInit {
  displayedColumns: string[] = ['oldExpectedTimeOfCompletion', 'newExpectedTimeofCompletion', 'updatedDate', 'userFullName'];
  dataSource = new MatTableDataSource();
  isLoading = false;
  expectedTimeofCompletionHistory$ = this.store.select((state) => state.manualPriorityHistoryState.etcData);
  isLoading$ = this.store.select((state) => state.manualPriorityHistoryState.isLoading);
  changeDocId: number;
  constructor(public dialogRef: MatDialogRef<EtcHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private store: Store<ApplicationState>) {
    super();

    this.changeDocId = data.changeDocId
  }

  ngOnInit(): void {

    this.expectedTimeofCompletionHistory$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data)
      });

    this.isLoading$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((loading) => this.isLoading = loading);

    this.store.dispatch(
      new CriticalRFIExpectedTimeofCompletionHistoryRequest(
        {
          id: this.changeDocId
        }));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

}
