<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h3>Milestone Table</h3>
        </mat-panel-title>
        <mat-panel-description *ngIf="!panelOpenState">Expand panel to view details.</mat-panel-description>
    </mat-expansion-panel-header>
    <div class="table-complete-container">
        <table mat-table multiTemplateDataRows [dataSource]="totalCompleteData">
            <ng-container matColumnDef="category">
                <th mat-header-cell disableClear *matHeaderCellDef>Category</th>
                <td mat-cell *matCellDef="let element">
                    <div class="tree-icon">
                        <div
                            [ngClass]="{
                                hidden: !element.disciplineBreakdown.length
                            }"
                        >
                            <mat-icon (click)="toggleTotalCompleteDataDTO(element)" *ngIf="!element.expanded"
                                >chevron_right</mat-icon
                            >
                            <mat-icon (click)="toggleTotalCompleteDataDTO(element)" *ngIf="element.expanded">expand_more</mat-icon>
                        </div>
                        <span>{{ element.category }}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="total">
                <th mat-header-cell disableClear *matHeaderCellDef>Total</th>
                <td mat-cell *matCellDef="let element">{{ element.total }}</td>
            </ng-container>
            <ng-container matColumnDef="complete">
                <th mat-header-cell disableClear *matHeaderCellDef>Complete</th>
                <td mat-cell *matCellDef="let element">{{ element.complete }}</td>
            </ng-container>
            <ng-container matColumnDef="exceptions">
                <th mat-header-cell disableClear *matHeaderCellDef>Exc</th>
                <td mat-cell *matCellDef="let element; let i = dataIndex">
                    <span
                        *ngIf="i <= 6"
                        class="detailed-status-link"
                        (click)="openDetailedStatusPage(element.category, undefined, true)"
                    >
                        {{ element.exceptions }}
                    </span>
                    <span *ngIf="i > 6"> {{ element.exceptions }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="remaining">
                <th mat-header-cell disableClear *matHeaderCellDef>Remaining</th>
                <td mat-cell *matCellDef="let element">
                    <span
                        *ngIf="
                            element.category !== 'MC Certificates' &&
                            element.category !== 'MC Walkdowns' &&
                            element.category !== 'RFSU Certificates' &&
                            element.category !== 'RFSU Walkdowns'
                        "
                        (click)="
                            openDetailedStatusPage(
                                element.category,
                                undefined,
                                undefined,
                                element.category === 'Redline'
                                    ? dashboardType === dashboardTypes.MC
                                        ? 'Red'
                                        : 'Blue'
                                    : undefined
                            )
                        "
                        class="detailed-status-link"
                    >
                        {{ element.remaining }}
                    </span>
                    <span
                        *ngIf="
                            element.category === 'MC Certificates' ||
                            element.category === 'MC Walkdowns' ||
                            element.category === 'RFSU Certificates' ||
                            element.category === 'RFSU Walkdowns'
                        "
                    >
                        {{ element.remaining }}
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="completePercentage">
                <th mat-header-cell disableClear *matHeaderCellDef>% Complete</th>
                <td mat-cell *matCellDef="let element">{{ element.completePercentage }} %</td>
            </ng-container>
            <ng-container matColumnDef="sun">
                <th mat-header-cell disableClear *matHeaderCellDef>Sun <br />{{ this.datesList[0] }}</th>
                <td mat-cell *matCellDef="let element">{{ element.sun }}</td>
            </ng-container>
            <ng-container matColumnDef="mon">
                <th mat-header-cell disableClear *matHeaderCellDef>Mon <br />{{ this.datesList[1] }}</th>
                <td mat-cell *matCellDef="let element">{{ element.mon }}</td>
            </ng-container>
            <ng-container matColumnDef="tue">
                <th mat-header-cell disableClear *matHeaderCellDef>Tue <br />{{ this.datesList[2] }}</th>
                <td mat-cell *matCellDef="let element">{{ element.tue }}</td>
            </ng-container>
            <ng-container matColumnDef="wed">
                <th mat-header-cell disableClear *matHeaderCellDef>Wed <br />{{ this.datesList[3] }}</th>
                <td mat-cell *matCellDef="let element">{{ element.wed }}</td>
            </ng-container>
            <ng-container matColumnDef="thu">
                <th mat-header-cell disableClear *matHeaderCellDef>Thu <br />{{ this.datesList[4] }}</th>
                <td mat-cell *matCellDef="let element">{{ element.thu }}</td>
            </ng-container>
            <ng-container matColumnDef="fri">
                <th mat-header-cell disableClear *matHeaderCellDef>Fri <br />{{ this.datesList[5] }}</th>
                <td mat-cell *matCellDef="let element">{{ element.fri }}</td>
            </ng-container>
            <ng-container matColumnDef="sat">
                <th mat-header-cell disableClear *matHeaderCellDef>Sat <br />{{ this.datesList[6] }}</th>
                <td mat-cell *matCellDef="let element">{{ element.sat }}</td>
            </ng-container>
            <ng-container matColumnDef="weeklyProgress">
                <th mat-header-cell disableClear *matHeaderCellDef>Week Total</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.weeklyProgress }}
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="totalCompleteColumns.length">
                    <div class="item-element-detail" [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
                        <div class="item-element-description">
                            <table
                                mat-table
                                [dataSource]="element.disciplineBreakdown"
                                multiTemplateDataRows
                                class="subtable"
                            >
                                <ng-container matColumnDef="{{ column }}" *ngFor="let column of totalCompleteColumns">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let nestedElement">
                                        <span
                                            *ngIf="
                                                (column === 'exceptions' && i <= 6) ||
                                                (column === 'remaining' && i <= 8)
                                            "
                                            class="detailed-status-link"
                                            (click)="
                                                openDetailedStatusPage(
                                                    element.category,
                                                    nestedElement.category,
                                                    column === 'exceptions',
                                                    element.category === 'Redline'
                                                        ? dashboardType === dashboardTypes.MC
                                                            ? 'Red'
                                                            : 'Blue'
                                                        : undefined
                                                )
                                            "
                                        >
                                            {{ nestedElement[column] }}
                                        </span>
                                        <span
                                            *ngIf="
                                                (column === 'exceptions' && i > 6) ||
                                                (column !== 'exceptions' && column !== 'remaining')
                                            "
                                        >
                                            {{ nestedElement[column] }} {{ column === 'completePercentage' ? '%' : '' }}
                                        </span>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="totalCompleteColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: totalCompleteColumns"></tr>
                            </table>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="totalCompleteColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: totalCompleteColumns; let element"
                class="item-element-row"
                [class.item-expanded-row]="element.expanded"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="item-detail-row"></tr>
        </table>
        <div *ngIf="isLoading$ | async" class="table-complete-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
</mat-expansion-panel>
