import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../../../../base.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    ScheduleActivityPlanningService
} from '../../../../../services/api/webapi-services/schedule-activity-planning.service';
import { ScheduleActivityPlanningConstraint } from '../constraint-configuration.component';
import { takeWhile } from 'rxjs/operators';
import { ToastService } from '../../../../../services/shared/toast.service';

@Component({
    selector: 'app-add-schedule-activity-constraint',
    templateUrl: './add-schedule-activity-constraint.component.html',
    styleUrls: ['./add-schedule-activity-constraint.component.scss']
})
export class AddScheduleActivityConstraintComponent extends BaseComponent {
    formGroup: UntypedFormGroup;

    constructor(public dialogRef: MatDialogRef<AddScheduleActivityConstraintComponent>,
                public scheduleActivityService: ScheduleActivityPlanningService,
                private toastService: ToastService,
                @Inject(MAT_DIALOG_DATA) data) {
        super();

        this.formGroup = new UntypedFormGroup({
                name: new UntypedFormControl('', Validators.required),
            }
        );
    }

    onCancel() {
        this.dialogRef.close();
    }

    onConfirm() {
        const model = {
            name: this.formGroup.controls.name.value,
        } as ScheduleActivityPlanningConstraint;

        this.scheduleActivityService.addConstraintType(model)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (data) => {
                    this.toastService.Success('Constraint Type successfully added');

                    this.dialogRef.close({success: true, data});
                },
                (error) => {
                    if (error.status === 409) {
                        this.toastService.Error('Constraint Type entered already exists in SCMT.');
                    } else {
                        this.toastService.Error(
                            'An error occurred while adding the Constraint Type. Please contact a Program Administrator.'
                        );
                    }
                }
            );
    }
}
