<form [formGroup]="filterForm">
    <mat-accordion class="filter-container">
        <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" expanded>
            <mat-expansion-panel-header collapsedHeight="auto">
                <app-lookahead-filter-top-header-section
                    *ngIf="!printMode"
                    [filterForm]="filterForm"
                    [showFilters]="!filterExpansionPanel.expanded"
                ></app-lookahead-filter-top-header-section>
            </mat-expansion-panel-header>
            <div class="mixedapp-container">
                <app-multiple-items-selector
                    class="container300"
                    [panelWidth]="480"
                    [isAsync]="true"
                    formControlName="rfos"
                    formSetter="rfos"
                    propertyToShow="name"
                    [displayedColumns]="['name', 'rfoName']"
                    [setInput]="setRfoSelectorValue"
                    [filterForm]="filterForm"
                    [reloadOnOpen]="true"
                    [searchFunc]="searchRfos"
                    placeholder="RFO"
                    [secondPropertyToShow]="'rfoName'"
                >
                </app-multiple-items-selector>

                <mat-form-field class="container300">
                    <mat-label>SC Execution Area Manager</mat-label>
                    <mat-select formControlName="scManager" multiple>
                        <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <app-multiple-items-selector
                    class="container300"
                    formControlName="disciplines"
                    formSetter="disciplines"
                    placeholder="ITR Discipline"
                    propertyToShow="name"
                    [displayedColumns]="['name']"
                    [filterForm]="filterForm"
                    [isAsync]="false"
                    [searchFunc]="searchDiscipline"
                >
                </app-multiple-items-selector>

                <div class="container-selectable-filters" *ngIf="planType === weeklyPlanTypes.weekly">
                    <mat-form-field class="container300">
                        <mat-label>HVAC?</mat-label>
                        <mat-select placeholder="All" [formGroup]="filterForm" formControlName="hvacSubsystems">
                            <mat-option [value]="null">Include HVAC</mat-option>
                            <mat-option [value]="true">Only HVAC</mat-option>
                            <mat-option [value]="false">Exclude HVAC</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <app-lookahead-filter-common-section
                [filterForm]="filterForm"
                [planType]="planType"
                [displayPeriodDays]="displayPeriodDays"
                [printMode]="printMode"
            ></app-lookahead-filter-common-section>
        </mat-expansion-panel>
    </mat-accordion>
</form>
