import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import { RundownTableData, RundownType, RUNDOWN_TYPE_KEYS } from 'src/app/store/reports/rundown/model';
import * as _ from 'lodash';

@Component({
    selector: 'app-reports-rundown-table',
    templateUrl: './reports-rundown-table.component.html',
    styleUrls: ['./reports-rundown-table.component.scss'],
})
export class ReportsRundownTableComponent extends BaseComponent implements OnInit {
    @Input() homePage: boolean = false;
    tableData: RundownTableData[];
    headers = [];
    categoriesArea = [];
    rundownTypes = RundownType;
    rundownType: RundownType = RundownType.RFSU;
    pliCategories: string[] = [];

    rundownType$ = this.store.select((store) => store.rundownState.rundownType);
    pliCategories$ = this.store.select((state) => state.rundownState.filter.categories);
    tableData$ = this.store.select<RundownTableData[]>(
        (store) => store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].tableData
    );
    filteredByClosureStage$ = this.store.select((state) => state.rundownState.filteredByClosureStage);

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit(): void {
        this.categoriesArea = ['PBF Area', 'GTG Area', 'CM Area', 'WSG Area', 'DP Area'];

        this.rundownType$.pipe(takeWhile(() => this.isAlive)).subscribe((rundownType) => {
            this.rundownType = rundownType;
        });

        this.pliCategories$.pipe(takeWhile(() => this.isAlive)).subscribe((categories) => {
            this.pliCategories = categories;
        });

        this.tableData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.tableData = data;
            this.headers = data.map((s) => {
                return { week: s.name };
            });
        });

        combineLatest([
            this.store.select((state) => state.rundownState.planViewEnabled),
            this.tableData$,
            this.store.select(
                (store) =>
                    (store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()]
                        .lineChart[0]?.series.length || 0) +
                    (store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()]
                        .lineChart[1]?.series.length || 0)
            ),
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(([planViewEnabled, tableData, actualSeriesLength]) => {
                this.tableData = !planViewEnabled ? _.take(tableData, actualSeriesLength) : tableData;
                this.headers = (!planViewEnabled ? _.take(tableData, actualSeriesLength) : tableData).map((s) => {
                    return { week: s.name };
                });
            });
    }

    getValue(data: RundownTableData, seriesName: string, fieldName: string) {
        return data.series.filter((s) => s.name === seriesName)[0]?.[fieldName];
    }

    getBreakdownData(data: RundownTableData, seriesName: string, area: string) {
        var result = null;
        var breakdown = data.series.filter((s) => s.name === seriesName)[0]?.breakdown;

        breakdown != null ? result = breakdown.filter((x) => x.areaBreakdown === area)[0]?.count : result = 0;

        if(result > 0) {
            return result;
        } else {
            return null;
        }
        
        
    }
}
