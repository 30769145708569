import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatRadioChange } from '@angular/material/radio';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { filter, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { getCurrentWeekStartDate, getWeekEndDate, getWeekRange } from 'src/app/extensions';
import { ApplicationState } from 'src/app/store/model';
import {
    ActivitiesConstraintsChartRequest,
    ActivitiesConstraintsFilterSetWeekRange,
    ActivitiesConstraintsFilterStateUpdate,
    ITRConstraintsChartRequest,
    ITRConstraintsFilterSetWeekRange,
} from 'src/app/store/reports/health/actions';
import {
    ActivitiesConstraintsFilter,
    ActivityConstrainsReportDTO,
    ChartType,
    HEALTH_ACTIVITIES_CONSTRAINTS_NUM_OF_PAST_WEEKS,
} from 'src/app/store/reports/health/model';


@Component({
    selector: 'app-activities-constraints-chart',
    templateUrl: './activities-constraints-chart.component.html',
    styleUrls: ['./activities-constraints-chart.component.scss'],
})
export class ActivitiesConstraintsChartComponent extends BaseComponent implements OnInit {
    @ViewChild('chartPanel', { static: true }) chartExpansionPanel: MatExpansionPanel;
    @ViewChild('dateRangePicker', { static: true }) dateRangePicker: MatDatepicker<Date>;
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showXAxisLabel: boolean = false;
    showYAxisLabel: boolean = false;
    maxValue = 200;
    avaiableColors = [
        '#FF0000',
        '#808080',
        '#ed7c31',
        '#eb0533',
        '#d1c928',
        '#5b9ad5',
        '#01b529',
        '#543ebf',
        '#4CAF50',
        '#FFAB40',
        '#FFC107',
        '#FFFF00',
        '#CDDC39',
        '#B2FF59',
        '#2E7D32',
        '#64FFDA',
        '#00BCD4',
        '#40C4FF',
        '#1565C0',
        '#673AB7',
        '#E040FB',
        '#AD1457',
        '#F44336',
        '#C62828',
        '#32CD32'
    ];
    colorScheme = {
        domain: [],
    };
    chart$ = this.store.select((state) => state.healthState.activitiesConstraints.chart);
    startDate$ = this.store.select((state) => state.healthState.activitiesConstraints.filter.startDate);
    filter$ = this.store.select((state) => state.healthState.activitiesConstraints.filter);
    filter: ActivitiesConstraintsFilter;
    range$ = this.store.select((state) => state.healthState.activitiesConstraints.filter.range);
    lineChartData: any[] = [];
    range = 6;
    rangeDateFilter = getCurrentWeekStartDate().add(-HEALTH_ACTIVITIES_CONSTRAINTS_NUM_OF_PAST_WEEKS, 'weeks');
    currentChart: ChartType;
    readonly ChartType = ChartType;

    constructor(private store: Store<ApplicationState>) {
        super();
        this.dateRangePickerControl = new UntypedFormControl(this.rangeDateFilter.toDate());
    }

    ngOnInit(): void {
        this.chartExpansionPanel.expanded = true;
        this.currentChart = ChartType.Activities;
        this.store.dispatch(new ActivitiesConstraintsChartRequest());
        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filter = filter;
        });
        this.dateRangePickerControlFormatted = new UntypedFormControl({
            value: this.formatDateRange(this.rangeDateFilter),
            disabled: true,
        });

        this.chart$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            let maxVal = 0;
            let colorSchemeDomain: string[] = [];

            const chartData = data.map((d: ActivityConstrainsReportDTO, index) => {
                colorSchemeDomain.push(this.avaiableColors[index % this.avaiableColors.length]);
                return {
                    name: d.constraintType,
                    series: d.values.map((v) => {
                        maxVal = v.count > maxVal ? v.count : maxVal;
                        return { name: `W/E ${moment(getWeekEndDate(moment(v.key))).format('D MMM')}`, value: v.count };
                    }),
                };
            });

            this.colorScheme.domain = colorSchemeDomain;
            this.lineChartData = chartData;
            this.maxValue = maxVal;
        });

        this.range$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((range) => !!range)
            )
            .subscribe((range) => {
                this.range = range;
            });

        this.startDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startDate) => !!startDate)
            )
            .subscribe((startDate) => {
                this.dateRangePickerControl.setValue(startDate, { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(startDate)));
            });

        this.dateRangePickerControl.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((date: Date) => {
            if(this.currentChart === ChartType.Activities)
                this.store.dispatch(new ActivitiesConstraintsFilterSetWeekRange(moment(date)));
            else
                this.store.dispatch(new ITRConstraintsFilterSetWeekRange(moment(date)));
            this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(date)));
        });
    }

    dateRangePickerFilter = (d: Date | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    private formatDateRange(startDate: moment.Moment) {
        const newRange = getWeekRange(startDate, getCurrentWeekStartDate());
        this.store.dispatch(new ActivitiesConstraintsFilterStateUpdate({
            ...this.filter,
            range: newRange
        }));
        const endDate = moment(startDate)
            .add(newRange, 'weeks');
        return `${startDate.format('D MMM')} - ${getWeekEndDate(endDate).format('D MMM YYYY')}`;
    }

    chartTypeChange($event: MatRadioChange) {
        this.currentChart = $event.value;
        if (this.currentChart === ChartType.ITR) this.store.dispatch(new ITRConstraintsChartRequest());
        else this.store.dispatch(new ActivitiesConstraintsChartRequest());
    }
}

