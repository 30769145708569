import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FormService } from 'src/app/services/shared/form.service';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile, take, catchError, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import {
    OrderDirection,
    Contractor,
    TCOACManager,
    TCOUser,
    CalendarColumn,
    CheckListColumn,
} from 'src/app/store/common.model';
import { SubsystemMaintenanceDTO } from './subsystem-maintenance-dto';
import {
    SubsystemFilterPropertyUpdate,
    SubsystemFilterRequest,
    SubsystemSaveRequest,
} from 'src/app/store/data-maintenance/subsystem-data/actions';
import { SubsystemMaintenanceFilter } from 'src/app/store/data-maintenance/subsystem-data/model';
import { forkJoin, Observable, of } from 'rxjs';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import * as moment from 'moment';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderDateFilter, HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-subsystem-maintenance',
    templateUrl: './subsystem-maintenance.component.html',
    styleUrls: ['./subsystem-maintenance.component.scss'],
})
export class SubsystemMaintenanceComponent extends BaseComponent implements OnInit {
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    projectTeamNameMap = {};
    acms: TCOACManager[] = [];
    scManagers: TCOUser[] = [];
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];

    datepickerSelectedDate: UntypedFormControl;
    datepickerSelectedDateSubsystem = '';
    datepickerDateType: string;
    todayDate = new Date();

    projectTeamNamesCurrent: string[];

    resultsLength = 0;
    pageSize = 10;
    sortBy: string;
    direction = OrderDirection.Desc;
    isLoading = false;

    columnSubsystems: CheckListColumn = null;
    columnSubsystemNames: CheckListColumn = null;
    columnContracts: CheckListColumn = null;
    columnAreaManagers: CheckListColumn = null;
    columnSystemOwner: CheckListColumn = null;
    columnConstructionManager: CheckListColumn = null;
    columnMCEngineer: CheckListColumn = null;
    columnMCPlan: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;

    subsystemFilter$ = this.store.select((store) => store.subsystemMaintenanceState.filter);
    subsystemDataPagination$ = this.store.select((store) => store.subsystemMaintenanceState.subsystemDataPagination);
    subsystemLoader$ = this.store.select((store) => store.subsystemMaintenanceState.isLoading);

    columnSubsystems$ = this.store.select((state) => state.subsystemMaintenanceState.filter.columnSubsystems);
    columnSubsystemNames$ = this.store.select((state) => state.subsystemMaintenanceState.filter.columnSubsystemNames);
    columnContracts$ = this.store.select((state) => state.subsystemMaintenanceState.filter.columnContracts);
    columnAreaManagers$ = this.store.select((state) => state.subsystemMaintenanceState.filter.columnAreaManagers);
    columnSystemOwner$ = this.store.select((state) => state.subsystemMaintenanceState.filter.columnSystemOwner);
    columnConstructionManager$ = this.store.select(
        (state) => state.subsystemMaintenanceState.filter.columnConstructionManager
    );
    columnMCEngineer$ = this.store.select((state) => state.subsystemMaintenanceState.filter.columnMCEngineer);
    columnMCPlan$ = this.store.select((state) => state.subsystemMaintenanceState.filter.columnMCPlan);
    columnRFSUPlan$ = this.store.select((state) => state.subsystemMaintenanceState.filter.columnRFSUPlan);

    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];

    filterForm: UntypedFormGroup;

    displayedColumns = [
        'subsystem',
        'subsystemName',
        'contractorNo',
        'TCOASCAreaManager',
        'TCOSysOwner',
        'TCOACManager',
        'TCOMCEngineer',
        'MCPlan',
        'RFSUPlan',
        'edit',
    ];

    data: SubsystemMaintenanceDTO[] = [];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('datepicker', { static: true }) datepicker: MatDatepicker<moment.Moment>;

    constructor(
        private formService: FormService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store<ApplicationState>,
        private lookupService: LookupService,
        private toastService: ToastService,
        private popupSvc: PopupService
    ) {
        super();

        this.filterForm = this.formService.createSimpleForm(new SubsystemMaintenanceFilter());

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );

        this.datepickerSelectedDate = new UntypedFormControl(moment());
    }

    ngOnInit(): void {
        this.lookupService
            .getProjectTeamNames()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.watchFormChanges();

        this.subsystemDataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            const copy = cloneDeep(data);
            this.data = copy.items;
            this.resultsLength = copy.totalCount;
        });

        this.subsystemFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.paginator.pageIndex = filter.page;
        });

        this.columnSubsystems$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSubsystems = data));
        this.columnSubsystemNames$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnSubsystemNames = data));
        this.columnContracts$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnContracts = data));
        this.columnAreaManagers$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnAreaManagers = data));
        this.columnSystemOwner$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnSystemOwner = data));
        this.columnConstructionManager$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnConstructionManager = data));
        this.columnMCEngineer$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnMCEngineer = data));
        this.columnMCPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnMCPlan = data));
        this.columnRFSUPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFSUPlan = data));

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new SubsystemFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new SubsystemFilterRequest());
        });

        this.subsystemLoader$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => (this.isLoading = isLoading));

        this.store.dispatch(new SubsystemFilterRequest());

        forkJoin([
            this.lookupService.getACMs([]),
            this.lookupService.getSCManagers([]),
            this.lookupService.getMCEngineers([]),
            this.lookupService.getSysOwners([]),
            this.lookupService.searchContractors(null, null, null, []),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error('Error occurred while getting users. Please contact Program Administrator.');
                })
            )
            .subscribe(
                ([acms, scManagers, mcEngineers, sysOwners, contractors]) => {
                    this.acms = acms;
                    this.scManagers = scManagers;
                    this.mcEngineers = mcEngineers;
                    this.sysOwners = sysOwners;
                    this.contractors = contractors;

                    this.contractors.forEach((x) => {
                        if (this.projectTeamNameMap[x.projectTeamName] == null) {
                            this.projectTeamNameMap[x.projectTeamName] = {};
                        }
                        if (this.projectTeamNameMap[x.projectTeamName].contractors == null) {
                            this.projectTeamNameMap[x.projectTeamName].contractors = [];
                        }
                        this.projectTeamNameMap[x.projectTeamName].contractors.push(x);
                    });

                    this.scManagers.forEach((x) => {
                        if (this.projectTeamNameMap[x.projectTeamName] == null) {
                            this.projectTeamNameMap[x.projectTeamName] = {};
                        }
                        if (this.projectTeamNameMap[x.projectTeamName].scManagers == null) {
                            this.projectTeamNameMap[x.projectTeamName].scManagers = [];
                        }
                        this.projectTeamNameMap[x.projectTeamName].scManagers.push(x);
                    });

                    this.mcEngineers.forEach((x) => {
                        if (this.projectTeamNameMap[x.projectTeamName] == null) {
                            this.projectTeamNameMap[x.projectTeamName] = {};
                        }
                        if (this.projectTeamNameMap[x.projectTeamName].mcEngineers == null) {
                            this.projectTeamNameMap[x.projectTeamName].mcEngineers = [];
                        }
                        this.projectTeamNameMap[x.projectTeamName].mcEngineers.push(x);
                    });

                    this.sysOwners.forEach((x) => {
                        if (this.projectTeamNameMap[x.projectTeamName] == null) {
                            this.projectTeamNameMap[x.projectTeamName] = {};
                        }
                        if (this.projectTeamNameMap[x.projectTeamName].sysOwners == null) {
                            this.projectTeamNameMap[x.projectTeamName].sysOwners = [];
                        }
                        this.projectTeamNameMap[x.projectTeamName].sysOwners.push(x);
                    });

                    this.acms.forEach((x) => {
                        if (this.projectTeamNameMap[x.projectTeamName] == null) {
                            this.projectTeamNameMap[x.projectTeamName] = {};
                        }
                        if (this.projectTeamNameMap[x.projectTeamName].acms == null) {
                            this.projectTeamNameMap[x.projectTeamName].acms = [];
                        }
                        this.projectTeamNameMap[x.projectTeamName].acms.push(x);
                    });
                },
                (error) => {
                    this.toastService.Error(error);
                }
            );
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new SubsystemFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new SubsystemFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new SubsystemFilterRequest());
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getACMs = (search = '', take = 10, page = 0) =>
        this.withLatestFilter(this.lookupService.searchACManagersWithSubsystemMaintenanceFilter, search, take, page);

    getSCMs = (search = '', take = 10, page = 0) =>
        this.withLatestFilter(this.lookupService.searchAreaManagersWithSubsystemMaintenanceFilter, search, take, page);

    getMcEngineers = (search = '', take = 10, page = 0) =>
        this.withLatestFilter(this.lookupService.searchMCEngineersWithSubsystemMaintenanceFilter, search, take, page);

    getSysOwners = (search = '', take = 10, page = 0) =>
        this.withLatestFilter(this.lookupService.searchSystemOwnersWithSubsystemMaintenanceFilter, search, take, page);

    getContractorsForColumnHeader = (search = '', take = 10, page = 0) =>
        this.withLatestFilter(this.lookupService.searchContractorsWithSubsystemMaintenanceFilter, search, take, page);

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        const pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    private watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new SubsystemFilterPropertyUpdate({ key, value }));
            });
        }
    }

    enterEditMode(subsystemMaintenanceDTO: SubsystemMaintenanceDTO) {
        subsystemMaintenanceDTO.isInEditMode = !subsystemMaintenanceDTO.isInEditMode;

        subsystemMaintenanceDTO.prevcontractor = subsystemMaintenanceDTO.contractor;
        subsystemMaintenanceDTO.prevtcoascAreaManager = subsystemMaintenanceDTO.tcoascAreaManager;
        subsystemMaintenanceDTO.prevtcoSysOwner = subsystemMaintenanceDTO.tcoSysOwner;
        subsystemMaintenanceDTO.prevtcoacManager = subsystemMaintenanceDTO.tcoacManager;
        subsystemMaintenanceDTO.prevtcomcEngineer = subsystemMaintenanceDTO.tcomcEngineer;
        subsystemMaintenanceDTO.prevmcPlan = subsystemMaintenanceDTO.mcPlan;
        subsystemMaintenanceDTO.prevrfsuPlan = subsystemMaintenanceDTO.rfsuPlan;
    }

    save(subsystemMaintenanceDTO: SubsystemMaintenanceDTO) {
        subsystemMaintenanceDTO.isInEditMode = !subsystemMaintenanceDTO.isInEditMode;

        if (subsystemMaintenanceDTO.contractor != null) {
            subsystemMaintenanceDTO.contractorNo = subsystemMaintenanceDTO.contractor.contractNo;
        } else {
            subsystemMaintenanceDTO.contractorNo = null;
        }

        if (subsystemMaintenanceDTO.tcoascAreaManager != null) {
            subsystemMaintenanceDTO.tcoascAreaManagerId = subsystemMaintenanceDTO.tcoascAreaManager.id;
        } else {
            subsystemMaintenanceDTO.tcoascAreaManagerId = null;
        }

        if (subsystemMaintenanceDTO.tcoSysOwner != null) {
            subsystemMaintenanceDTO.tcoSysOwnerId = subsystemMaintenanceDTO.tcoSysOwner.id;
        } else {
            subsystemMaintenanceDTO.tcoSysOwnerId = null;
        }

        if (subsystemMaintenanceDTO.tcoacManager != null) {
            subsystemMaintenanceDTO.tcoacManagerId = subsystemMaintenanceDTO.tcoacManager.id;
        } else {
            subsystemMaintenanceDTO.tcoacManagerId = null;
        }

        if (subsystemMaintenanceDTO.tcomcEngineer != null) {
            subsystemMaintenanceDTO.tcomcEngineerId = subsystemMaintenanceDTO.tcomcEngineer.id;
        } else {
            subsystemMaintenanceDTO.tcomcEngineerId = null;
        }

        this.store.dispatch(new SubsystemSaveRequest(subsystemMaintenanceDTO));
    }

    cancelEdit(subsystemMaintenanceDTO: SubsystemMaintenanceDTO) {
        subsystemMaintenanceDTO.isInEditMode = !subsystemMaintenanceDTO.isInEditMode;

        subsystemMaintenanceDTO.contractor = subsystemMaintenanceDTO.prevcontractor;
        subsystemMaintenanceDTO.tcoascAreaManager = subsystemMaintenanceDTO.prevtcoascAreaManager;
        subsystemMaintenanceDTO.tcoSysOwner = subsystemMaintenanceDTO.prevtcoSysOwner;
        subsystemMaintenanceDTO.tcoacManager = subsystemMaintenanceDTO.prevtcoacManager;
        subsystemMaintenanceDTO.tcomcEngineer = subsystemMaintenanceDTO.prevtcomcEngineer;
        subsystemMaintenanceDTO.mcPlan = subsystemMaintenanceDTO.prevmcPlan;
        subsystemMaintenanceDTO.rfsuPlan = subsystemMaintenanceDTO.prevrfsuPlan;
    }

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new SubsystemFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new SubsystemFilterRequest());
    }

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getSubsystemsForColumnHeader = (search = '', take = 10, page = 0) =>
        this.withLatestFilter(this.lookupService.searchSubsystemsWithSubsystemMaintenanceFilter, search, take, page);

    getSubsystemsNames = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchSubsystemNames(search, take, page)
            .pipe(map((subsystemsNames: string[]) => subsystemsNames.map((s) => ({ id: s }))));
    };

    getSubsystemsNamesForColumnHeader = (search = '', take = 10, page = 0) =>
        this.withLatestFilter(
            this.lookupService.searchSubsystemNamesWithSubsystemMaintenanceFilter,
            search,
            take,
            page
        );

    compareContractors(contractor1: Contractor, contractor2: Contractor) {
        if (contractor1 === null || contractor2 === null) {
            return false;
        }

        return contractor1.contractNo === contractor2.contractNo;
    }

    compareTCOUsers(TCOUser1: TCOUser, TCOUser2: TCOUser) {
        if (TCOUser1 === null || TCOUser2 === null) {
            return false;
        }

        return TCOUser1.id === TCOUser2.id;
    }

    openDatepicker(element: SubsystemMaintenanceDTO, dateType: string) {
        if (dateType === 'mcPlan') {
            this.datepickerSelectedDate.setValue(element.mcPlan ? element.mcPlan : '');
            this.datepickerSelectedDateSubsystem = element.subsystem;
            this.datepicker.open();
        } else if (dateType === 'rfsuPlan') {
            this.datepickerSelectedDate.setValue(element.rfsuPlan ? element.rfsuPlan : '');
            this.datepickerSelectedDateSubsystem = element.subsystem;
            this.datepicker.open();
        }

        this.datepickerDateType = dateType;
    }

    onDatepickerClosed() {
        const subsystem = this.data.find((s) => s.subsystem === this.datepickerSelectedDateSubsystem);

        if (this.datepickerDateType === 'mcPlan') {
            subsystem.mcPlan = this.datepickerSelectedDate.value;
        } else if (this.datepickerDateType === 'rfsuPlan') {
            subsystem.rfsuPlan = this.datepickerSelectedDate.value;
        }

        this.datepickerSelectedDateSubsystem = '';
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, 345, 600, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            })
        );
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 620, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: columnName === 'subsystem' ? true : false,
                showCounts: showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new SubsystemFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new SubsystemFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    private withLatestFilter(action: (...actionArgs: any) => Observable<any>, ...args: any) {
        return of(null).pipe(
            withLatestFrom(this.store.select((state) => state.subsystemMaintenanceState.filter)),
            switchMap(([, filter]) => action(...args, filter))
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new SubsystemFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new SubsystemFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'subsystem', direction: OrderDirection.Desc },
                })
            );
        }
    }
}
