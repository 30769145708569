import { Injectable } from '@angular/core';
import { ContractorMappingDTO } from 'src/app/models/contractor-mapping-dto';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ContractorsMappingService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/contractormapping';
    }

    get(changeType: string = '') {
        return this.http.get<ContractorMappingDTO[]>(`${this.rootUrl}${this.endpoint}?changeType=${changeType}`);
    }

    remove(id: number) {
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?id=${id}`);
    }

    update(contractor: ContractorMappingDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, contractor);
    }

    add(contractor: ContractorMappingDTO) {
        return this.http.post<ContractorMappingDTO>(`${this.rootUrl}${this.endpoint}`, contractor);
    }
}
