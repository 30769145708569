import { Action } from '@ngrx/store';
import { CommentDetailDTO, DetailedStatusDataPagination, DetailedStatusDTO, DetailedStatusTableScrollProgress } from './model';
import { SubsystemScope, UserDefinedInputs } from 'src/app/enums';
import { AttachmentDTO } from '../details/model';
import { UserDetail } from '../common.model';
import { ITRConstraintDTO } from 'src/app/models/itr-constraint';
import { ExceptionScope } from '../../enums';

export enum DetailedStatusActionTypes {
    DetailedStatusFilterRequest = '[DetailedStatus] Filter Request',
    DetailedStatusFilterSuccess = '[DetailedStatus] Filter Success',
    DetailedStatusFilterError = '[DetailedStatus] Filter Error',
    DetailedStatusFilterPropertyUpdate = '[DetailedStatus] Property Update',
    DetailedStatusFilterMultiplePropertyUpdate = '[DetailedStatus] Multiple Property Update',
    DetailedStatusFilterReset = '[DetailedStatus] Filter Reset',
    DetailedStatusSetLoader = '[DetailedStatus] Set Loader Active',
    DetailedStatusAddCommentRequest = '[DetailedStatus] Add Comment Request',
    DetailedStatusAddO3ConstraintRequest = '[DetailedStatus] Add O3 Constraint Request',
    DetailedStatusAddCommentSuccess = '[DetailedStatus] Add Comment Success',
    DetailedStatusAddITRResponsibleCommentSuccess = '[DetailedStatus] Add ITR ResponsiblePerson Comment Success',
    DetailedStatusAddO3ConstraintSuccess = '[DetailedStatus] Add O3 Constraint Success',
    DetailedStatusAddCommentError = '[DetailedStatus] Add Comment Error',
    DetailedStatusAddO3ConstraintError = '[DetailedStatus] Add O3 Constraint Error',
    DetailedStatusRemoveCommentRequest = '[DetailedStatus] Remove Comment Request',
    DetailedStatusRemoveCommentSuccess = '[DetailedStatus] Remove Comment Success',
    DetailedStatusRemoveCommentError = '[DetailedStatus] Remove Comment Error',
    DetailedStatusRemoveExceptionCommentRequest = '[DetailedStatus] Remove Exception Comment Request',
    DetailedStatusRemoveExceptionCommentSuccess = '[DetailedStatus] Remove Exception Comment Success',
    DetailedStatusRemoveExceptionCommentError = '[DetailedStatus] Remove Exception Comment Error',
    DetailedStatusAddBulkCommentRequest = '[DetailedStatus] Add Bulk Comment Request',
    DetailedStatusAddBulkCommentSuccess = '[DetailedStatus] Add Bulk Comment Success',
    DetailedStatusAddBulkCommentError = '[DetailedStatus] Add Bulk Comment Error',
    DetailedStatusSetMakeRequest = '[DetailedStatus] Set Make Request',
    DetailedStatusSetLockedFilter = '[DetailedStatus] Set Locked Filter',
    DetailedStatusResetLockedFilter = '[DetailedStatus] Reset Locked Filter',
    DetailedStatusAttachmentsUpdated = '[DetailedStatus] Attachments Updated',
    DetailedStatusSetTableOffset = '[DetailedStatus] Set table offset',
    DetailedStatusAddITRConstraintRequest = '[DetailedStatus] Add ITR Constraint Request',
    DetailedStatusAddITRConstraintSuccess = '[DetailedStatus] Add ITR Constraint Success',
    DetailedStatusUpdateITRConstraintRequest = '[DetailedStatus] Update ITR Constraint Request',
    DetailedStatusUpdateITRConstraintSuccess = '[DetailedStatus] Update ITR Constraint Success',
    DetailedStatusITRConstraintError = '[DetailedStatus] ITR Constraint Change Error',
    GetStatusDetailsRequest = '[DetailedStatus] Get Status Details',
    GetStatusDetailsSuccess = '[DetailedStatus] Get Status Details Success',
    GetStatusDetailsError = '[DetailedStatus] Get Status Details Error',
    DetailedStatusAddEXForecastClosureDateRequest = '[DetailedStatus] Add EX Forecast Closure Date Request',
    DetailedStatusAddEXForecastClosureDateSuccess = '[DetailedStatus] Add EX Forecast Closure Date Success',
    DetailedStatusAddEXForecastClosureDateError = '[DetailedStatus] EX Forecast Closure Date Error',
    DetailedStatusAddResponsiblePersonRequest = '[DetailedStatus] Add Responsible Person Request',
    DetailedStatusAddResponsiblePersonSuccess = '[DetailedStatus] Add Responsible Person Success',
    DetailedStatusAddResponsiblePersonError = '[DetailedStatus] EX Responsible Person Error',
    DetailedStatusAddExPriorityRequest = '[DetailedStatus] Add EX Priority Request',
    DetailedStatusAddExPrioritySuccess = '[DetailedStatus] Add EX Priority Success',
    DetailedStatusAddExPriorityError = '[DetailedStatus] EX Priority Error',
    DetailedStatusAddBulkExceptionCommentRequest = '[DetailedStatus] Add Bulk Exception Comment Request',
    DetailedStatusAddBulkExceptionCommentSuccess = '[DetailedStatus] Add Bulk Exception Comment Success',
    DetailedStatusAddBulkExceptionCommentError = '[DetailedStatus] Add Bulk Exception Comment Error',
    DetailedStatusUpdatePliImplementationTeamRequest = '[DetailedStatus] Update Pli Implementation Team Request',
    DetailedStatusUpdatePliImplementationTeamSuccess = '[DetailedStatus] Update Pli Implementation Team Success',
    DetailedStatusUpdatePliImplementationTeamError = '[DetailedStatus] Update Pli Implementation Team Error',
    DetailedStatusAddPLIForecastClosureDateRequest = '[DetailedStatus] Add PLI Forecast Closure Date Request',
    DetailedStatusAddPLIForecastClosureDateSuccess = '[DetailedStatus] Add PLI Forecast Closure Date Success',
    DetailedStatusAddPLIForecastClosureDateError = '[DetailedStatus] PLI Forecast Closure Date Error',
    DetailedStatusAddPostRFOWorkExpectedClosureDateRequest = '[DetailedStatus] Add Post RFO Work Expected Closure Date Request',
    DetailedStatusAddPostRFOWorkExpectedClosureDateSuccess = '[DetailedStatus] Add Post RFO Work Expected Closure Date Success',
    DetailedStatusAddPostRFOWorkExpectedClosureDateError = '[DetailedStatus] Add Post RFO Work Expected Closure Date Error',
    DetailedStatusPostRFOWorkUpdateManualPriorityRequest = '[DetailedStatus] Post RFO Work Add Manual Priority Override Request',
    DetailedStatusPostRFOWorkUpdateManualPrioritySuccess = '[DetailedStatus] Post RFO Work Add Manual Priority Override Success',
    DetailedStatusPostRFOWorkUpdateManualPriorityError = '[DetailedStatus] Post RFO Work Add Manual Priority Override Error',
    DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest = '[DetailedStatus] Post RFO Work Add Responsible Person Or Group Request',
    DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupSuccess = '[DetailedStatus] Post RFO Work Add Responsible Person Or Group Success',
    DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupError = '[DetailedStatus] Post RFO Work Add Responsible Person Or Group Error'
}

export class GetStatusDetailsRequest implements Action {
    readonly type = DetailedStatusActionTypes.GetStatusDetailsRequest;

    constructor(public payload: { id: string; scope: SubsystemScope }) {}
}

export class GetStatusDetailsSuccess implements Action {
    readonly type = DetailedStatusActionTypes.GetStatusDetailsSuccess;

    constructor(public payload: DetailedStatusDTO) {}
}

export class GetStatusDetailsError implements Action {
    readonly type = DetailedStatusActionTypes.GetStatusDetailsError;

    constructor(public payload: string) {}
}
export class DetailedStatusAttachmentsUpdated implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAttachmentsUpdated;

    constructor(public payload: { number: string; attachments: AttachmentDTO[], scope : SubsystemScope }) {}
}

export class DetailedStatusFilterRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusFilterRequest;

    constructor(public payload: string) {}
}

export class DetailedStatusSetMakeRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusSetMakeRequest;
}

export class DetailedStatusFilterSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusFilterSuccess;

    constructor(public payload: DetailedStatusDataPagination) {}
}

export class DetailedStatusFilterError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusFilterError;

    constructor(public payload: string) {}
}

export class DetailedStatusFilterPropertyUpdate implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any; scope: string }) {}
}

export class DetailedStatusFilterMultiplePropertyUpdate implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusFilterMultiplePropertyUpdate;

    constructor(public payload: { items: { key: string; value: any }[]; scope: string }) {}
}

export class DetailedStatusFilterReset implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusFilterReset;

    constructor(public payload: { filterInstance: any; scope: any }) {}
}

export class DetailedStatusSetLoader implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusSetLoader;

    constructor(public payload: boolean) {}
}
export class DetailedStatusRemoveCommentRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusRemoveCommentRequest;

    constructor(public payload: { id: number; lastComment: CommentDetailDTO; entityId: string | number; scope: SubsystemScope, mainScope:SubsystemScope }) {
        // This is intentional
    }
}
export class DetailedStatusRemoveCommentSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusRemoveCommentSuccess;

    constructor(public payload: { id: number; lastComment: CommentDetailDTO; entityId: string | number }) {
        // This is intentional
    }
}

export class DetailedStatusRemoveCommentError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusRemoveCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class DetailedStatusRemoveExceptionCommentRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusRemoveExceptionCommentRequest;

    constructor(public payload: { id: number; lastComment: CommentDetailDTO; exscope : ExceptionScope; mainScope: SubsystemScope }) {
        // This is intentional
    }
}
export class DetailedStatusRemoveExceptionCommentSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusRemoveExceptionCommentSuccess;

    constructor(public payload: { id: number; lastComment: CommentDetailDTO; exscope : ExceptionScope; }) {
        // This is intentional
    }
}

export class DetailedStatusRemoveExceptionCommentError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusRemoveExceptionCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class DetailedStatusAddCommentRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddCommentRequest;

    constructor(
        public payload: { entityId: string; description: string; scope: SubsystemScope; mentions: UserDetail[];
        additionaEntityId? : string; exscope? : ExceptionScope; nbResponsiblePerson? : UserDefinedInputs ;
        subSystem? : string ; rfo? : string;
        }
    ) {}
}

export class DetailedStatusAddO3ConstraintRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddO3ConstraintRequest;

    constructor(
        public payload: { tagNumber: string; o3constraint: string; scope: SubsystemScope; mentions: UserDetail[];
        exceptionNumber : string; pliqvdNumber : string ;subsystem : string; exscope? : ExceptionScope;
        }
    ) {}
}

export class DetailedStatusAddCommentSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddCommentSuccess;

    constructor(
        public payload: { entityId: string; description: string; scope: SubsystemScope; mentions: UserDetail[]; 
            additionaEntityId? : string; exscope? : ExceptionScope;  nbResponsiblePerson? : UserDefinedInputs
            subSystem? : string ; rfo? : string;
        }
    ) {}
}
export class DetailedStatusAddITRResponsibleCommentSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddITRResponsibleCommentSuccess;

    constructor(
        public payload: { entityId: string; description: string; scope: SubsystemScope; mentions: UserDetail[]; 
            additionaEntityId? : string; exscope? : ExceptionScope;  nbResponsiblePerson? : UserDefinedInputs
            subSystem? : string ; rfo? : string;
        }
    ) {}
}

export class DetailedStatusAddO3ConstraintSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddO3ConstraintSuccess;

    constructor(
        public payload: { tagNumber: string; o3constraint: string; scope: SubsystemScope; mentions: UserDetail[]; 
            exceptionNumber : string; pliqvdNumber : string; subsystem : string; exscope? : ExceptionScope; }
    ) {}
}
export class DetailedStatusAddCommentError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddCommentError;

    constructor(public payload: string) {}
}

export class DetailedStatusAddO3ConstraintError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddO3ConstraintError;

    constructor(public payload: string) {}
}

export class DetailedStatusAddBulkCommentRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddBulkCommentRequest;

    constructor(public payload: { description: string; scope: SubsystemScope; mentions: UserDetail[] }) {}
}

export class DetailedStatusAddBulkCommentSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddBulkCommentSuccess;

    constructor(public payload: { description: string; scope: SubsystemScope; mentions: UserDetail[] }) {}
}

export class DetailedStatusAddBulkCommentError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddBulkCommentError;

    constructor(public payload: string) {}
}

export class DetailedStatusSetLockedFilter implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusSetLockedFilter;

    constructor(public payload: any) {}
}

export class DetailedStatusResetLockedFilter implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusResetLockedFilter;
}


export class DetailedStatusSetTableOffset implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusSetTableOffset;

    constructor(public payload: DetailedStatusTableScrollProgress) {}
}

export class DetailedStatusAddITRConstraintRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddITRConstraintRequest;

    constructor(public payload: {itrConstraint: ITRConstraintDTO, itr: DetailedStatusDTO}) {}
}

export class DetailedStatusAddITRConstraintSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddITRConstraintSuccess;

    constructor(public payload: {itrConstraint: ITRConstraintDTO, itr: DetailedStatusDTO}, public constId = 0) {}
}

export class DetailedStatusAddEXForecastClosureDateRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddEXForecastClosureDateRequest;

    constructor(public payload: {ex: DetailedStatusDTO, forecastClosureDate: moment.Moment}) {}
}

export class DetailedStatusAddEXForecastClosureDateSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddEXForecastClosureDateSuccess;

    constructor(public payload: { ex: DetailedStatusDTO}, public exId = 0) {}
}
export class DetailedStatusUpdateITRConstraintRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusUpdateITRConstraintRequest;

    constructor(public payload: {itrConstraint: ITRConstraintDTO, itr: DetailedStatusDTO}) {}
}

export class DetailedStatusUpdateITRConstraintSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusUpdateITRConstraintSuccess;

    constructor(public payload: {itrConstraint: ITRConstraintDTO, itr: DetailedStatusDTO}) {}
}

export class DetailedStatusITRConstraintError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusITRConstraintError;

    constructor(public payload: string) {}
}
export class DetailedStatusAddEXForecastClosureDateError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddEXForecastClosureDateError;

    constructor(public payload: string) {}
}
export class DetailedStatusAddResponsiblePersonRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddResponsiblePersonRequest;

    constructor(public payload: {ex: DetailedStatusDTO, responsiblePersonId: number , responsiblePerson: string, typeInput: UserDefinedInputs}) {}
}

export class DetailedStatusAddResponsiblePersonSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddResponsiblePersonSuccess;

    constructor(public payload: { ex: DetailedStatusDTO, typeInput: UserDefinedInputs}, public exId = 0) {}
}
export class DetailedStatusAddResponsiblePersonError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddResponsiblePersonError;

    constructor(public payload: string) {}
}

export class DetailedStatusAddExPriorityRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddExPriorityRequest;
    constructor(public payload: { priority: DetailedStatusDTO }) {}
}

export class DetailedStatusAddExPrioritySuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddExPrioritySuccess;
    constructor(public payload: { priority: DetailedStatusDTO }) {}
}

export class DetailedStatusAddExPriorityError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddExPriorityError;
    constructor(public payload: string) {}
}

export class DetailedStatusAddBulkExceptionCommentRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddBulkExceptionCommentRequest;

    constructor(public payload: { description: string; mentions: UserDetail[]; exceptionNumber:string, scope : SubsystemScope }) {}
}

export class DetailedStatusAddBulkExceptionCommentSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddBulkExceptionCommentSuccess;

    constructor(public payload: { description: string; mentions: UserDetail[]; exceptionNumber:string, scope : SubsystemScope }) {}
}

export class DetailedStatusAddBulkExceptionCommentError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddBulkExceptionCommentError;

    constructor(public payload: string) {}
}

export class DetailedStatusUpdatePliImplementationTeamRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusUpdatePliImplementationTeamRequest;

    constructor(public payload: { number: string;  implementationTeam:string }) {}
}

export class DetailedStatusUpdatePliImplementationTeamSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusUpdatePliImplementationTeamSuccess;

    constructor(public payload: { number: string;  implementationTeam:string }) {}
}

export class DetailedStatusUpdatePliImplementationTeamError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusUpdatePliImplementationTeamError;

    constructor(public payload: string) {}
}

export class DetailedStatusAddPLIForecastClosureDateRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddPLIForecastClosureDateRequest;

    constructor(public payload: {pli: DetailedStatusDTO, forecastClosureDate: moment.Moment}) {}
}

export class DetailedStatusAddPLIForecastClosureDateSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddPLIForecastClosureDateSuccess;

    constructor(public payload: { pli: DetailedStatusDTO}, public pliId = 0) {}
}
export class DetailedStatusAddPLIForecastClosureDateError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddPLIForecastClosureDateError;

    constructor(public payload: string) {}
}

export class DetailedStatusAddPostRFOWorkExpectedClosureDateRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddPostRFOWorkExpectedClosureDateRequest;

    constructor(public payload: {postRfoData: DetailedStatusDTO, expectedClosureDate: moment.Moment}) {}
}

export class DetailedStatusAddPostRFOWorkExpectedClosureDateSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddPostRFOWorkExpectedClosureDateSuccess;

    constructor(public payload: { postRfoData: DetailedStatusDTO}) {}
}
export class DetailedStatusAddPostRFOWorkExpectedClosureDateError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusAddPostRFOWorkExpectedClosureDateError;

    constructor(public payload: string) {}
}

export class DetailedStatusPostRFOWorkUpdateManualPriorityRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateManualPriorityRequest;
    constructor(public payload: { manualPriority: DetailedStatusDTO }) {}
}

export class DetailedStatusPostRFOWorkUpdateManualPrioritySuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateManualPrioritySuccess;
    constructor(public payload: { manualPriority: DetailedStatusDTO }) {}
}

export class DetailedStatusPostRFOWorkUpdateManualPriorityError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateManualPriorityError;
    constructor(public payload: string) {}
}

export class DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest;
    constructor(public payload: { responsiblePersonOrGroup: DetailedStatusDTO }) {}
}

export class DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupSuccess implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupSuccess;
    constructor(public payload: { responsiblePersonOrGroup: DetailedStatusDTO }) {}
}

export class DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupError implements Action {
    readonly type = DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupError;
    constructor(public payload: string) {}
}


export type DetailedStatusActions =
    | GetStatusDetailsRequest
    | DetailedStatusFilterRequest
    | DetailedStatusFilterSuccess
    | DetailedStatusFilterError
    | DetailedStatusFilterPropertyUpdate
    | DetailedStatusFilterMultiplePropertyUpdate
    | DetailedStatusFilterReset
    | DetailedStatusSetLoader
    | DetailedStatusAddCommentRequest
    | DetailedStatusAddCommentSuccess
    |DetailedStatusAddITRResponsibleCommentSuccess
    | DetailedStatusAddCommentError
    | DetailedStatusAddBulkCommentRequest
    | DetailedStatusAddBulkCommentSuccess
    | DetailedStatusAddBulkCommentError
    | DetailedStatusSetMakeRequest
    | DetailedStatusSetLockedFilter
    | DetailedStatusResetLockedFilter
    | DetailedStatusAttachmentsUpdated
    | DetailedStatusSetTableOffset
    | DetailedStatusAddITRConstraintRequest
    | DetailedStatusAddITRConstraintSuccess
    | DetailedStatusUpdateITRConstraintRequest
    | DetailedStatusUpdateITRConstraintSuccess
    | DetailedStatusITRConstraintError
    | DetailedStatusAddEXForecastClosureDateRequest
    | DetailedStatusAddEXForecastClosureDateSuccess
    | DetailedStatusAddEXForecastClosureDateError
    | DetailedStatusAddResponsiblePersonRequest
    | DetailedStatusAddResponsiblePersonSuccess
    | DetailedStatusAddResponsiblePersonError
    | DetailedStatusAddExPriorityRequest
    | DetailedStatusAddExPrioritySuccess
    | DetailedStatusAddExPriorityError
    | DetailedStatusRemoveCommentRequest
    | DetailedStatusRemoveCommentSuccess
    | DetailedStatusRemoveCommentError
    | DetailedStatusRemoveExceptionCommentRequest 
    | DetailedStatusRemoveExceptionCommentSuccess 
    | DetailedStatusRemoveExceptionCommentError 
    | DetailedStatusAddO3ConstraintRequest
    | DetailedStatusAddO3ConstraintSuccess
    | DetailedStatusAddO3ConstraintError
    | DetailedStatusAddBulkExceptionCommentRequest
    | DetailedStatusAddBulkExceptionCommentSuccess
    | DetailedStatusAddBulkExceptionCommentError
    | DetailedStatusUpdatePliImplementationTeamRequest
    | DetailedStatusUpdatePliImplementationTeamSuccess
    | DetailedStatusUpdatePliImplementationTeamError
    | DetailedStatusAddPLIForecastClosureDateRequest
    | DetailedStatusAddPLIForecastClosureDateSuccess
    | DetailedStatusAddPLIForecastClosureDateError
    | DetailedStatusAddPostRFOWorkExpectedClosureDateRequest
    | DetailedStatusAddPostRFOWorkExpectedClosureDateSuccess
    | DetailedStatusAddPostRFOWorkExpectedClosureDateError
    | DetailedStatusPostRFOWorkUpdateManualPriorityRequest
    | DetailedStatusPostRFOWorkUpdateManualPrioritySuccess
    | DetailedStatusPostRFOWorkUpdateManualPriorityError
    | DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest
    | DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupSuccess
    | DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupError
    ;

