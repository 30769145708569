import { LookupState } from './model';
import { LookupActions, LookupActionTypes } from './actions';

const initialState: LookupState = {
    disciplines: null,
    acms: null,
    scmanagers: null,
    deliveryengineers: null,
    waypoints: null,
    sysowners: null,
    mcengineers: null,
    contractors: null,
    milestones: null,
    mcMilestones: null,
    goocs: null,
};

export function reducer(state: LookupState = initialState, action: LookupActions) {
    switch (action.type) {
        case LookupActionTypes.LookupSave: {
            return {
                ...state,
                [action.payload.key]: action.payload.value
            };
        }
        default: {
            return state;
        }
    }
}
