import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { WeeklyPlanActivityType, WeeklyPlanType } from 'src/app/enums';
import { PopupSettings } from 'src/app/models/popup-settings';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ApplicationState } from 'src/app/store/model';
import { ScheduleActivityITRFilterPropertyReset, ScheduleActivityITRFilterPropertyUpdate } from 'src/app/store/schedule-activity-itr/actions';
import { ScheduleActivityITRFilter, ScheduleActivityITRScope } from 'src/app/store/schedule-activity-itr/model';
import {
    ScheduleActivityPlanningFilterPropertyUpdate,
    ScheduleActivityPlanningResetITRFilter,
    ScheduleActivityNotInWeekPlanningDataRequest,
} from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import {
    ScheduleActivityPlanningFilter,
    ShowHideColumns,
    SubsystemWithScheduleActivitiesDTO,
} from '../../../../../store/weekly-planning/schedule-activity-planning/model';
import { AddSubsystemComponent } from './add-subsystem/add-subsystem.component';

@Component({
    selector: 'app-schedule-activity-planning-table',
    templateUrl: './schedule-activity-planning-table.component.html',
    styleUrls: ['./schedule-activity-planning-table.component.scss'],
})
export class ScheduleActivityPlanningTableComponent extends BaseComponent implements OnInit {
    @Input() activityType: WeeklyPlanActivityType;
    @Input() planningItemsTableDataSource: SubsystemWithScheduleActivitiesDTO[];
    @Input() additionToPlan = false;
    @Input() showHideColumns = new ShowHideColumns();
    @Output() showHideColumnsChanged: EventEmitter<ShowHideColumns> = new EventEmitter();
    @Output() goToAddSubsystemClicked: EventEmitter<boolean> = new EventEmitter();
    expanded = true;
    weeklyPlanType: WeeklyPlanType;
    weeklyPlanTypes = WeeklyPlanType;
    activityTypes = WeeklyPlanActivityType;
    lockFilters = false;

    scheduleActivityPlanningFilter$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter
    );

    constructor(
        private store: Store<ApplicationState>,
        private router: Router,
        private ngZone: NgZone,
        private popupSvc: PopupService
    ) {
        super();
    }

    ngOnInit(): void {
        this.scheduleActivityPlanningFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.expanded = filter.showActivities;
            this.weeklyPlanType = filter.weeklyPlanType;
            this.lockFilters = filter.lockFilters;
        });
    }

    expandAll() {
        this.expanded = true;
        this.updateFilterState();
    }

    collapseAll() {
        this.expanded = false;
        this.updateFilterState();
    }

    reverseValue(columnName: string) {
        let showHideColumnsNew = { ...this.showHideColumns };
        showHideColumnsNew[columnName] = !showHideColumnsNew[columnName];
        this.showHideColumnsChanged.emit(showHideColumnsNew);
        this.updateFilterState();
    }

    updateFilterState() {
        this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
                filter: {
                    showActivities: this.expanded,
                } as ScheduleActivityPlanningFilter,
            })
        );
    }

    redirectToBluezone(subsystem: string) {
        var url = this.router.createUrlTree(['bluezone'], {
            queryParams: { subsystem, showDisciplineBreakdown: 'false' },
        });
        window.open(window.location.origin + url.toString(), '_blank');
    }

    showITRInWeek() {
        this.store.dispatch(new ScheduleActivityPlanningResetITRFilter());
        this.store.dispatch(new ScheduleActivityITRFilterPropertyReset());
        const scope = ScheduleActivityITRScope.InWeek;
        this.store.dispatch(
            new ScheduleActivityITRFilterPropertyUpdate(
                ({ scope: scope, additionToPlan: true }) as ScheduleActivityITRFilter
            )
        );
        
        this.ngZone.run(() =>
            this.router.navigate([`/weeklyplanning/lookaheaditrs`])
        );
    }

    goToAddSubsystem() {
        this.goToAddSubsystemClicked.emit();
    }

    addSubsystem() {
        this.popupSvc
            .openPopup(new PopupSettings(AddSubsystemComponent, 1320, 820, {}))
            .afterClosed()
            .subscribe(() => {
                this.store.dispatch(new ScheduleActivityNotInWeekPlanningDataRequest());
            });
    }
}
