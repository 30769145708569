import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '../base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent extends BaseComponent {
    modalTitle: string;
    modalText: string;
    modalHtmlText: string;
    isYesDisabled = false;
    isDiscardChanges = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public store: Store<ApplicationState>) {
        super();
        this.modalTitle = data.title;
        this.modalText = data.text;
        this.modalHtmlText = data.htmlText;
        this.isDiscardChanges = data.isDiscardChanges;
    }
}
