import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeWhile, withLatestFrom } from 'rxjs/operators';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { CertificateSearchService } from 'src/app/services/api/webapi-services/certificate-search.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    CertificateSearchExportToExcelRequest,
    CertificateSearchFilterPropertyUpdate,
    CertificateSearchFilterReset,
    CertificateSearchRequest,
} from 'src/app/store/certificate/actions';
import { CertificateSearchFilter } from 'src/app/store/certificate/model';
import {
    CalendarColumn,
    CheckListColumn,
    Contractor,
    Gooc,
    OrderDirection,
    RFO,
    StagedStartUpPriority,
    TCOUser,
} from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-certificate-search',
    templateUrl: './certificate.component.html',
    styleUrls: ['./certificate.component.scss'],
})
export class CertificateSearchComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    projectTeamNames: string[] = [];
    projectTeamNamesCurrent: string[];
    contractors: Contractor[] = [];
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    areaCompletionLeads: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    disciplines: any[] = [];
    displayedColumns = ['subsystem', 'discipline', 'certificateType', 'status', 'statusDate', 'certificateNumber'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    resultsLength = 0;
    sortBy = 'subsystem';
    direction = OrderDirection.Desc;
    pageSize = 25;
    columnSubsystem: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnCertificateType: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnCertificateNumber: CheckListColumn = null;

    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPriorityInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    filter$ = this.store.select((state) => state.certificateSearchState.filter);
    data$ = this.store.select((state) => state.certificateSearchState.data).pipe(filter((data) => !!data));
    isLoading$ = this.store.select((state) => state.certificateSearchState.isLoading);
    columnSubsystem$ = this.store.select((state) => state.certificateSearchState.filter.columnSubsystem);
    columnDiscipline$ = this.store.select((state) => state.certificateSearchState.filter.columnDiscipline);
    columnCertificateType$ = this.store.select((state) => state.certificateSearchState.filter.columnCertificateType);
    columnStatus$ = this.store.select((state) => state.certificateSearchState.filter.columnStatus);
    columnStatusDate$ = this.store.select((state) => state.certificateSearchState.filter.columnStatusDate);
    columnCertificateNumber$ = this.store.select(
        (state) => state.certificateSearchState.filter.columnCertificateNumber
    );

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private formService: FormService,
        private projectTeamsService: ProjectTeamsService,
        private store: Store<ApplicationState>,
        private lookupService: LookupService,
        private toastService: ToastService,
        private popupSvc: PopupService,
        private certificateSearchService: CertificateSearchService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new CertificateSearchFilter());
    }

    ngOnInit() {
        //this.filterExpansionPanel.expanded = true;

        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: any[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines. Please contact Program Administrator.'
                    );
                }
            );

        this.filter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam(filter.projectTeamNames || []);
        });

        this.columnSubsystem$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSubsystem = data));
        this.columnDiscipline$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDiscipline = data));
        this.columnCertificateType$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnCertificateType = data));
        this.columnStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnStatus = data));
        this.columnStatusDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnStatusDate = data));
        this.columnCertificateNumber$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnCertificateNumber = data));

        this.store.dispatch(new CertificateSearchRequest());
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    displayMultipleSelected(values: (Contractor | RFO)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedPriorities(priorities: StagedStartUpPriority[]) {
        return priorities.map((p) => p.priorityName).join(', ');
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    onContractorsClosed() {
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(true));
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new CertificateSearchFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new CertificateSearchFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new CertificateSearchRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    search() {
        this.updateFilterByFormProperties();

        this.paginator.pageIndex = 0;
        
        this.store.dispatch(
            new CertificateSearchFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new CertificateSearchRequest());
    }

    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new CertificateSearchFilterReset());
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPriorityInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.setValue(new CertificateSearchFilter());
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
    }

    exportToExcel() {
        this.store.dispatch(new CertificateSearchExportToExcelRequest());
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts: showCounts,
                showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.filterForm.controls[propertyName].setValue(value);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new CertificateSearchFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new CertificateSearchFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            },
            345,
            580)
        );
    }

    getAreaBreakdown = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    
    getRFSUContractors = (search = '', take = 10, page = 0) => {
            return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
                map((rfsuContractors: Contractor[]) => {
                    return rfsuContractors;
                })
            );
        };

    getColumnSubsystems = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.certificateSearchService.searchColumnSubsystemsWithFilter, search, take, page);

    getColumnDisciplines = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.certificateSearchService.searchColumnDisciplinesWithFilter, search, take, page);

    getColumnCertificateTypes = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.certificateSearchService.searchColumnCertificateTypesWithFilter, search, take, page);

    getColumnStatuses = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.certificateSearchService.searchColumnStatusesWithFilter, search, take, page);

    getColumnCertificateNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(
            this.certificateSearchService.searchColumnCertificateNumbersWithFilter,
            search,
            take,
            page
        );

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) =>
        this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((startUpPriorities: StagedStartUpPriority[]) => startUpPriorities));

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new CertificateSearchFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.filterForm, key, value);
                }
            }
        }
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new CertificateSearchFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new CertificateSearchFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'subsystem', direction: OrderDirection.Desc },
                })
            );
        }
    }

    private withLatestFilter(action: (...actionArgs: any) => Observable<any>, ...args: any) {
        return of(null).pipe(
            withLatestFrom(this.store.select((state) => state.certificateSearchState.filter)),
            switchMap(([, filter]) => action(...args, filter))
        );
    }

    private getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'contractors');
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'rfos');
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'goocs');

        forkJoin([
            this.lookupService.getACLeads(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
            this.lookupService.getSCManagers(projectTeamNames),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([areaCompletionLeads, sysOwners, scManagers]) => {
                    this.areaCompletionLeads = areaCompletionLeads;
                    this.checkIfSelectedUsersValid(this.areaCompletionLeads, 'areaCompletionLead', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                }
            );
    }

    private checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName].value.length &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm.controls[formControlName].setValue(valid);
        }
    }
}
