import { createReducer, on } from '@ngrx/store';
import { DataLog, ImportLogState } from './model';
import * as ImportLogActions from './actions';

const initialState: ImportLogState = {
    importLogs: [],
    //SAST SCAN CHANGES
    //importLogsNotifications: [] = [],
    importLogsNotifications: [],
    connected: true,
};

export const importLogsReducer = createReducer(
    initialState, 
    on(ImportLogActions.importStatusReceived, (state, action) => {
        const notifications = [...state.importLogsNotifications];
        const updatedNotifications = action.importLogs
            .filter(log => notifications.some(notification => notification.id === log.id && notification.status !== log.status));
        const newNotifications = notifications.map(notification => {
            const updated = updatedNotifications.find(x => x.id === notification.id)
            if (updated) {
                return {
                    ...notification,
                    status: updated.status,
                    endDate: updated.endDate
                }
            } else {
                return notification
            }
        });
        return {
            ...state,
            importLogs: action.importLogs,
            importLogsNotifications: newNotifications
        }
    }),
    on(ImportLogActions.connectionSet, (state, action) => ({
            ...state,
            connected: action.payload,
    })),
    on(ImportLogActions.importStatusDisplayed, (state, action) => {
        const newNotifications = action.notifications.map(log => ({
            ...log,
            toastId: action.toastId

        }));
        return {
            ...state,
            importLogsNotifications: [
                ...state.importLogsNotifications,
                ...newNotifications
            ]
        }
    }),
);
