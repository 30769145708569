import { Injectable } from '@angular/core';
import { History } from 'src/app/store/validation-form/model';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class HistoryService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly sctollEndpoint: string = '/validationHistory';

    constructor(private http: HttpClient) {}

    getHistory(id: number) {
        return this.http.get<History[]>(`${this.rootUrl}${this.sctollEndpoint}/${id}`);
    }
}
