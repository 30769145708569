import { createAction, props } from '@ngrx/store';
import { PipelineLog } from 'src/app/models/pipeline-log';

export const statusUpdateLogRequest = createAction('[Status Update] Upload Log Request');
export const statusUpdateLogSuccess = createAction(
    '[Status Update] Upload Log Success', props<{ response: PipelineLog[] }>()
    );
export const statusUpdateLogError = createAction(
    '[Status Update] Upload Log Error', props<{ error: string }>()
    );
export const statusUpdateClearInProgressSpinner = createAction(
    '[Status Update] Clear In Progress Spinner'
    );
