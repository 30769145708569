import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Subscription } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { ExceptionsPriorityDeleteDTO, ExceptionsPriorityDeleteFilter } from 'src/app/store/planning-upload/exceptions-priority/model';
import {
    ExceptionsPriorityUploadLogRequest,
    ExceptionsPriorityPatchDeleteRequest,
    ExceptionsPriorityPatchAllDeleteRequest,
    ExceptionsPriorityDeleteFilterPropertyUpdate,
    ExceptionsPriorityDeleteRequest,
    ExceptionsPriorityTemplateFileRequest,
    ExceptionsPriorityDownloadOutputDataRequest,
    ExceptionsPriorityDeleteFilterReset,
} from 'src/app/store/planning-upload/exceptions-priority/actions';
import { PlanningUploadSetActivatedRoute } from 'src/app/store/planning-upload/actions';
import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';

@Component({
    selector: 'app-exceptions-priority',
    templateUrl: './exceptions-priority.component.html',
    styleUrls: ['./exceptions-priority.component.scss'],
})
export class ExceptionsPriorityComponent extends BaseComponent implements OnInit {

    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    deleteData: MatTableDataSource<ExceptionsPriorityDeleteDTO>;
    deleteDataResultsLength = 0;
    deleteDataPageSize = 10;
    displayedDeleteDataColumns = [
        'exception',
        'priority',
        'actions',
    ];

    sortBy$ = this.store.select((state) => state.exceptionsPriorityState.deleteFilter.sortBy);
    direction$ = this.store.select((state) => state.exceptionsPriorityState.deleteFilter.direction);
    uploadLogData$ = this.store.select((store) => store.exceptionsPriorityState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.exceptionsPriorityState.isUploadLogLoading);
    deleteData$ = this.store.select((store) => store.exceptionsPriorityState.deleteData);
    isDeleteDataLoading$ = this.store.select((store) => store.exceptionsPriorityState.isDeleteDataLoading);
    isLoading$ = this.store.select((store) => store.exceptionsPriorityState.isLoading);
    interval$ = this.store.select((store) => store.planningUploadState.interval);
    intervalSub: Subscription = null;
    deleteFilter$ = this.store.select((store) => store.exceptionsPriorityState.deleteFilter);

    columnPriority$ = this.store.select((state) => state.exceptionsPriorityState.deleteFilter.columnPriority);
    columnException$ = this.store.select((state) => state.exceptionsPriorityState.deleteFilter.columnException);

    showOrder = true;
    sortBy: string;
    direction = OrderDirection.Desc;
    deleteValidationFilterForm: UntypedFormGroup;
    columnPriority: CheckListColumn = null;
    columnException: CheckListColumn = null;

    constructor(private store: Store<ApplicationState>,
        private activatedRoute: ActivatedRoute,
        private popupSvc: PopupService,
        private formService: FormService,
        private lookupService: LookupService) {
        super();

        this.deleteValidationFilterForm = this.formService.createSimpleForm(new ExceptionsPriorityDeleteFilter());
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new PlanningUploadSetActivatedRoute(urlSegments[0].path));
        });
        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new ExceptionsPriorityUploadLogRequest());
            });
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

        this.deleteData$.pipe(takeWhile(() => this.isAlive)).subscribe((deleteData) => {
            this.deleteData = new MatTableDataSource(deleteData.items);
            this.deleteDataResultsLength = deleteData.totalCount;
        });

        this.deleteFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.deleteValidationFilterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.sortBy = data));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.direction = data));

        this.columnPriority$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPriority = data));

        this.columnException$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnException = data));

    }

    restoreItem(element: ExceptionsPriorityDeleteDTO) {

        this.store.dispatch(
            new ExceptionsPriorityPatchDeleteRequest({
                exception: element.exception,
                deleteState: false,
            })
        );
    }

    deleteItem(element: ExceptionsPriorityDeleteDTO) {

        this.store.dispatch(
            new ExceptionsPriorityPatchDeleteRequest({
                exception: element.exception,
                deleteState: true,
            })
        );
    }

    patchAllDeleteRecords(deleteState: boolean) {
        this.store.dispatch(
            new ExceptionsPriorityPatchAllDeleteRequest({
                deleteState: deleteState,
            })
        );
    }

    onDeleteDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deleteDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new ExceptionsPriorityDeleteFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deleteDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new ExceptionsPriorityDeleteFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new ExceptionsPriorityDeleteRequest());
    }

    downloadTemplate(fileName: string) {
        this.store.dispatch(new ExceptionsPriorityTemplateFileRequest(fileName));
    }

    downloadData(filePath: string) {
        this.store.dispatch(new ExceptionsPriorityDownloadOutputDataRequest(filePath));
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.deleteValidationFilterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            },
            345,
            580)
        );
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts: showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.deleteValidationFilterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new ExceptionsPriorityDeleteFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new ExceptionsPriorityDeleteFilterPropertyUpdate({
                            key: propertyName,
                            value: this.deleteValidationFilterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new ExceptionsPriorityDeleteFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new ExceptionsPriorityDeleteFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'exception', direction: OrderDirection.Desc },
                })
            );
        }
    }

    search() {
        this.updateFilterByFormProperties();

        this.store.dispatch(
            new ExceptionsPriorityDeleteFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new ExceptionsPriorityDeleteRequest());
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.deleteValidationFilterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.deleteValidationFilterForm.controls[key].value;
                this.store.dispatch(new ExceptionsPriorityDeleteFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.deleteValidationFilterForm, key, value);
                }
            }
        }
    }

    resetFilters() {
        this.store.dispatch(new ExceptionsPriorityDeleteFilterReset());
        this.store.dispatch(new ExceptionsPriorityDeleteRequest());
    }

    private getLatestFilterData(): ExceptionsPriorityDeleteFilter {
        let filter: ExceptionsPriorityDeleteFilter;
        this.store.pipe(select((x) => x.exceptionsPriorityState, take(1))).subscribe((data) => (filter = data.deleteFilter));
        return filter;
    }

    getPriority = (search = '', take = 30, page = 0) =>
        this.lookupService.searchPriorityWithExceptionsPriorityDeleteFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );

    getException = (search = '', take = 30, page = 0) =>
        this.lookupService.searchExceptionWithExceptionsPriorityDeleteFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );

    
}

