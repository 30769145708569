import { CertificateState } from './model';
import { CertificateActions, CertificateActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: CertificateState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isDataReadyToProcess: false,
    recordsForDeletion: 0,
    isAutomaticUploadInProgress: true,
};

export function reducer(state = initialState, action: CertificateActions) {
    switch (action.type) {
        case CertificateActionTypes.CertificateUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case CertificateActionTypes.CertificateUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case CertificateActionTypes.CertificateUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case CertificateActionTypes.CertificateSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case CertificateActionTypes.CertificateFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case CertificateActionTypes.CertificateClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case CertificateActionTypes.CertificateTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CertificateActionTypes.CertificateTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CertificateActionTypes.CertificateTemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CertificateActionTypes.CertificateValidateDeltaRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CertificateActionTypes.CertificateValidateDeltaSuccess: {
            return {
                ...state,
                isLoading: false,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case CertificateActionTypes.CertificateValidateDeltaError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CertificateActionTypes.CertificateDataInfoSuccess: {
            return {
                ...state,
                isDataReadyToProcess: action.payload.readyToProcess[0],
                recordsForDeletion: action.payload.recordsForDeletion[0],
                isAutomaticUploadInProgress: action.payload.isAutomaticUploadInProgress,
            };
        }
        case CertificateActionTypes.CertificateDataInfoError: {
            return {
                ...state,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case CertificateActionTypes.CertificateDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CertificateActionTypes.CertificateDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CertificateActionTypes.CertificateDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CertificateActionTypes.CertificateAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'Certificate';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}
