import { Component, OnInit, ChangeDetectorRef, ViewChild, NgZone } from '@angular/core';
import { BaseComponent } from '../base.component';
import { take, takeWhile } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { select, Store } from '@ngrx/store';
import { CommentType, MyPageDTO, MyPageFilter } from 'src/app/store/my-page/model';
import { UntypedFormGroup } from '@angular/forms';
import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MyPageFilterPropertyUpdate, MyPageFilterRequest, MyPageMarkReadRequest } from 'src/app/store/my-page/actions';
import { Router } from '@angular/router';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';

@Component({
    selector: 'app-my-page',
    templateUrl: './my-page.component.html',
    styleUrls: ['./my-page.component.scss'],
})
export class MyPageComponent extends BaseComponent implements OnInit {
    data: MyPageDTO[] = [];
    displayedColumns: string[] = ['type', 'comment', 'author', 'date', 'read', 'gotolink'];
    isLoading = false;
    dataPagination$ = this.store.select((state) => state.myPageState.dataPagination);
    stateLoader$ = this.store.select((state) => state.myPageState.isLoading);
    registerFilter$ = this.store.select((state) => state.myPageState.filter);
    filterForm: UntypedFormGroup;
    resultsLength = 0;
    sortBy$ = this.store.select((state) => state.myPageState.filter.sortBy);
    direction$ = this.store.select((state) => state.myPageState.filter.direction);
    sortBy = 'date';
    pageSize = 10;
    direction: OrderDirection = OrderDirection.Desc;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    columnTypes$ = this.store.select((state) => state.myPageState.filter.columnTypes);
    columnAuthors$ = this.store.select((state) => state.myPageState.filter.columnAuthors);
    columnDates$ = this.store.select((state) => state.myPageState.filter.columnDates);
    columnTypes: CheckListColumn = null;
    columnAuthors: CheckListColumn = null;
    columnDates: CalendarColumn = null;

    constructor(
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private router: Router,
        private popupSvc: PopupService,
        private lookupService: LookupService,
        private formService: FormService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new MyPageFilter());
    }

    ngOnInit() {
        this.stateLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.dataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.registerFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.sortBy = filter.sortBy;
            this.paginator.pageIndex = filter.page;
            this.paginator.pageSize = filter.take;
            this.pageSize = filter.take;
            this.direction = filter.direction;
        });

        this.columnTypes$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnTypes = data));
        this.columnDates$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDates = data));
        this.columnAuthors$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnAuthors = data));

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new MyPageFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new MyPageFilterRequest());
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => (this.sortBy = sortBy));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => (this.direction = direction));

        this.store.dispatch(new MyPageFilterRequest());

        this.filterForm.controls.unreadOnly.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((unreadOnly) => {
            this.store.dispatch(
                new MyPageFilterPropertyUpdate({
                    key: 'unreadOnly',
                    value: unreadOnly,
                })
            );
            this.store.dispatch(new MyPageFilterRequest());
        });
    }

    getCommentType(type: CommentType): string {
        return CommentType[type];
    }

    openComment(link: string) {
        this.ngZone.run(() => this.router.navigateByUrl(link));
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new MyPageFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new MyPageFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new MyPageFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            },
            345,
            580)
        );
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts: showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new MyPageFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new MyPageFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new MyPageFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new MyPageFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'date', direction: OrderDirection.Desc },
                })
            );
        }
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new MyPageFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.filterForm, key, value);
                }
            }
        }
    }

    search() {
        this.updateFilterByFormProperties();

        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new MyPageFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new MyPageFilterRequest());
    }

    private getLatestFilterData(): MyPageFilter {
        let filter: MyPageFilter;
        this.store.pipe(select((x) => x.myPageState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    markRead(item: MyPageDTO) {
        item.isRead = !item.isRead;
        this.store.dispatch(new MyPageMarkReadRequest({ mentionId: item.id, isRead: item.isRead }));
    }

    getAuthors = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAuthorsWithMyPageFilter(search, take, page, this.getLatestFilterData());

    getTypes = (search = '', take = 30, page = 0) =>
        this.lookupService.searchTypesWithMyPageFilter(search, take, page, this.getLatestFilterData());
}
