import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/components/base.component';
import { FormService } from 'src/app/services/shared/form.service';
import { CreateActivityDTO } from 'src/app/store/weekly-planning/schedule-activity-planning/model';
import { ActivityValidators } from 'src/app/validators/activity.validators';

@Component({
  selector: 'app-create-activity',
  templateUrl: './create-activity.component.html',
  styleUrls: ['./create-activity.component.scss']
})
export class CreateActivityComponent extends BaseComponent {
  activityForm: UntypedFormGroup;

  disciplines = ['Electrical', 'Instrument', 'Mechanical', 'Telecom'];

  activityTypes = [{ name: 'precommissioning', value: 'PC' },
                { name: 'commissioning', value: 'COM' },
                { name: 'temporary/set-up', value: 'TEMP' }];
  
  constructor(
    private dialogRef: MatDialogRef<CreateActivityComponent>,
    private activityValidators: ActivityValidators,
    private formService: FormService) {

    super();
    this.activityForm = this.formService.createForm(new CreateActivityDTO, this.activityValidators);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    this.dialogRef.close({newActivity: this.activityForm.value});
  }
}
