import { UserDetail } from '../common.model';
import * as moment from 'moment';
import { ProjectTeamWithContract } from 'src/app/models/project-team-with-contract';
import { CallOffAttachment, TQAttachment } from '../change-document-details/model';

export interface ValidationFormState {
    isLoading: boolean;
    isHeaderLoading: boolean;
    validationDocument: ValidationDocument;
    validationDocumentResult:ValidationDocumentResult;
    validationDocumentHeader: ValidationDocumentHeader;
    updatedProperties: string[];
}

export class History {
    id: number;
    validationId: number;
    user: UserDetail;
    date: moment.Moment;
    status: string;
    action: string;
}

export class ValidationDocument {
    id: number = null;
    validator: UserDetail = new UserDetail();
    validationStatus = '';
    inProgressDate: moment.Moment = null;
    completedDate: moment.Moment = null;
    fieldImpact = false;
    mcPlusChangesComplete = false;
    qvdUpdate = false;
    punched = false;
    validationRemark = '';
    status = '';
    mappedToTag = false;
    subsystemImpactedNA = false;
    discipline = '';
    isActive = false;
    validationAssignee: UserDetail = new UserDetail();
}

export class ValidationDocumentResult{
    validation :ValidationDocument;
    validationICT :ValidationDocument;
    validationSMP :ValidationDocument;
}

export class UserDetailForSubsystem {
    name = '';
    surname = '';
}

export class ValidationDocumentHeader {
    number = '';
    changeType = '';
    revision = '';
    title = '';
    status = '';
    columnsChanged = '';
    isColumnsChanged = false;
    isActive = true;
    date: Date = null;
    projectTeamName = '';
    area = '';
    discipline = '';
    impactedTags = '';
    reasonForChange = '';
    contractor = '';
    cwp = '';
    impactedDrawings = '';
    subsystems: { id: string }[] = [];
    suggestedSubsystems = '';
    notes = '';
    id = 0;
    projectTeamWithContract: ProjectTeamWithContract[] = [];
    mcPlusSubsystems = '';
    mcPlusTags = '';
    scopeOfWork = '';
    description = '';
    response = '';
    attachments: CallOffAttachment[] = [];
    tqAttachments: TQAttachment[] = [];
    subsystemUpdatedBy: UserDetailForSubsystem;
    subsystemUpdatedDate: Date = null;
    systemizationIsCompleted = false;
    systemizationRemark = '';
    testPackCompletedSubsystems = '';
}
