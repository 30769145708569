import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly userEndpoint: string = '/user';

    constructor(private http: HttpClient) {}

    addUserDetails() {
        return this.http.post(`${this.rootUrl}${this.userEndpoint}`, {});
    }

    getUserContracts() {
        return this.http.get(`${this.rootUrl}${this.userEndpoint}/usercontracts`);
    }
}
