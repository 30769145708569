import { SubsystemMCDashboardActions, SubsystemMCDashboardActionTypes } from './actions';
import { SubsystemMCDashboardFilter, SubsystemMCDashboardState } from './model';

const initialState: SubsystemMCDashboardState = {
    dashboard1Filter: new SubsystemMCDashboardFilter(),
    dashboard2Filter: new SubsystemMCDashboardFilter(),
    dashboard1Items: null,
    dashboard2Items: null,
    dashboard1IsLoading: false,
    dashboard2IsLoading: false,
    dashboard1TotalCount: 0,
    dashboard2TotalCount: 0,
};

export function reducer(state = initialState, action: SubsystemMCDashboardActions): SubsystemMCDashboardState {
    switch (action.type) {
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard1Request:
            return {
                ...state,
                dashboard1IsLoading: true,
            };
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard1Success:
            return {
                ...state,
                dashboard1IsLoading: false,
                dashboard1Items: action.payload.items,
                dashboard1TotalCount: action.payload.totalCount,
            };
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard1Error:
            return {
                ...state,
                dashboard1IsLoading: false,
            };
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard1UpdateFilterProperties:
            return {
                ...state,
                dashboard1Filter: { ...state.dashboard1Filter, ...action.payload },
            };
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard1ExportToExcelRequest: {
            return {
                ...state,
                dashboard1IsLoading: true,
            };
        }
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard1ExportToExcelSuccess: {
            return {
                ...state,
                dashboard1IsLoading: false,
            };
        }
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard1ExportToExcelError: {
            return {
                ...state,
                dashboard1IsLoading: false,
            };
        }
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard2Request:
            return {
                ...state,
                dashboard2IsLoading: true,
            };
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard2Success:
            return {
                ...state,
                dashboard2IsLoading: false,
                dashboard2Items: action.payload.items,
                dashboard2TotalCount: action.payload.totalCount,
            };
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard2Error:
            return {
                ...state,
                dashboard2IsLoading: false,
            };
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard2UpdateFilterProperties:
            return {
                ...state,
                dashboard2Filter: { ...state.dashboard2Filter, ...action.payload },
            };
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard2ExportToExcelRequest: {
            return {
                ...state,
                dashboard2IsLoading: true,
            };
        }
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard2ExportToExcelSuccess: {
            return {
                ...state,
                dashboard2IsLoading: false,
            };
        }
        case SubsystemMCDashboardActionTypes.SubsystemMCDashboard2ExportToExcelError: {
            return {
                ...state,
                dashboard2IsLoading: false,
            };
        }
        default:
            return state;
    }
}
