import {
    PlanningState,
    PlanningLookaheadState,
    PlanningLookaheadCounters,
    PlanningLookaheadReportDataItem,
    PlanningLookaheadTotalItem,
    PlanningFilter,
    PLANNING_LOOKAHEAD_NUM_OF_PAST_WEEKS,
    PLANNING_LOOKAHEAD_NUM_OF_UPCOMING_WEEKS,
    PlanningCommitmentReliabilityState,
    PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_WEEKS,
    PlanningCommitmentReliabilityTotalItem,
    PlanningCommitmentReliabilityDTO,
    PlanValueCountByWeek,
    PLANNING_COMMITMENT_RELIABILITY_NUM_OF_UPCOMING_WEEKS,
    PlanningLookaheadDTO,
    PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_MONTH,
    PLANNING_COMMITMENT_RELIABILITY_NUM_OF_UPCOMING_MONTH,
} from './model';
import { PlanningActions, PlanningActionTypes } from './actions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { initWeekRange, getCurrentWeekStartDate, initMonthRange } from 'src/app/extensions';
import { WeekType } from 'src/app/enums';
import { Constants } from 'src/app/constants';

const initialState: PlanningState = {
    lookahead: new PlanningLookaheadState(),
    commitmentReliability: { ...new PlanningCommitmentReliabilityState(), planViewEnabled: true },
};

export function reducer(state = initialState, action: PlanningActions): PlanningState {
    switch (action.type) {
        case PlanningActionTypes.PlanningLookaheadSetType: {
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    lookaheadType: action.payload.toUpperCase(),
                },
            };
        }
        case PlanningActionTypes.CommitmentReliabilityTogglePlanView: {
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    planViewEnabled: !state.commitmentReliability.planViewEnabled,
                },
            };
        }
        case PlanningActionTypes.PlanningLookaheadFilterUpdate: {
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    filter: {
                        ...state.lookahead.filter,
                        ...action.payload,
                    },
                },
            };
        }
        case PlanningActionTypes.PlanningLookaheadFilterPropertyUpdate: {
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    filter: {
                        ...state.lookahead.filter,
                        [action.payload.key]: Array.isArray(state.lookahead.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                },
            };
        }
        case PlanningActionTypes.PlanningLookaheadFilterReset: {
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    filter: new PlanningFilter(),
                },
            };
        }
        case PlanningActionTypes.PlanningLookaheadRequest: {
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    isLoading: true,
                },
            };
        }
        case PlanningActionTypes.PlanningLookaheadSuccess: {
            const { data, giTotals, gpTotals, grandTotals } = calculateLookaheadStateData(state, action.payload);
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    data,
                    giTotals,
                    gpTotals,
                    grandTotals,
                    isLoading: false,
                },
            };
        }
        case PlanningActionTypes.PlanningLookaheadError: {
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    isLoading: false,
                },
            };
        }
        case PlanningActionTypes.PlanningLookaheadSetWeekRange: {
            return {
                ...state,
                lookahead: {
                    ...state.lookahead,
                    weeks: initWeekRange(
                        action.payload,
                        PLANNING_LOOKAHEAD_NUM_OF_PAST_WEEKS + PLANNING_LOOKAHEAD_NUM_OF_UPCOMING_WEEKS
                    ),
                    grandTotals: [],
                },
            };
        }
        case PlanningActionTypes.PlanningCommitmentReliabilitySetType: {
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    commitmentReliabilityType: action.payload.toUpperCase(),
                    data: [],
                    weeks: initWeekRange(
                        getCurrentWeekStartDate().add(-PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_WEEKS, 'weeks'),
                        PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_WEEKS +
                            PLANNING_COMMITMENT_RELIABILITY_NUM_OF_UPCOMING_WEEKS
                    ),
                },
            };
        }
        case PlanningActionTypes.PlanningCommitmentReliabilityFilterUpdate: {
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    filter: {
                        ...state.commitmentReliability.filter,
                        ...action.payload,
                    },
                },
            };
        }
        case PlanningActionTypes.PlanningCommitmentReliabilityFilterPropertyUpdate: {
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    filter: {
                        ...state.commitmentReliability.filter,
                        [action.payload.key]: Array.isArray(state.commitmentReliability.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                },
            };
        }
        case PlanningActionTypes.PlanningCommitmentReliabilityFilterReset: {
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    filter: new PlanningFilter(),
                },
            };
        }
        case PlanningActionTypes.PlanningCommitmentReliabilityRequest: {
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    isLoading: true,
                },
            };
        }
        case PlanningActionTypes.PlanningCommitmentReliabilitySuccess: {
            let type = state.commitmentReliability.commitmentReliabilityType;
            let data = state.commitmentReliability.monthViewEnabled
                ? state.commitmentReliability.months.map((month) =>
                      formatPlanningCommitmentReliabilityChartSerie(
                          action.weeklyPlanning.planningCommitment,
                          action.weeklyPlanning.planValue,
                          moment(month.date),
                          type,
                          state.commitmentReliability.monthViewEnabled
                      )
                  )
                : state.commitmentReliability.weeks.map((week) =>
                      formatPlanningCommitmentReliabilityChartSerie(
                          action.weeklyPlanning.planningCommitment,
                          action.weeklyPlanning.planValue,
                          moment(week.date),
                          type,
                          state.commitmentReliability.monthViewEnabled
                      )
                  );
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    data,
                    isLoading: false,
                },
            };
        }
        case PlanningActionTypes.PlanningCommitmentReliabilityError: {
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    isLoading: false,
                },
            };
        }
        case PlanningActionTypes.PlanningCommitmentReliabilitySetWeekRange: {
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    weeks: initWeekRange(
                        action.payload,
                        PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_WEEKS +
                            PLANNING_COMMITMENT_RELIABILITY_NUM_OF_UPCOMING_WEEKS
                    ),
                    data: [],
                    monthViewEnabled: false,
                },
            };
        }
        case PlanningActionTypes.PlanningCommitmentReliabilitySetMonthRange: {
            return {
                ...state,
                commitmentReliability: {
                    ...state.commitmentReliability,
                    months: initMonthRange(
                        action.payload,
                        PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_MONTH +
                            PLANNING_COMMITMENT_RELIABILITY_NUM_OF_UPCOMING_MONTH
                    ),
                    data: [],
                    monthViewEnabled: true,
                },
            };
        }
        default:
            return state;
    }
}

export const endDate = (commitment: PlanningCommitmentReliabilityDTO, type: string) => {
    return type === 'MC' ? commitment.mcActual : type === 'RFSU' ? commitment.rfsuActual : commitment.itrCompleted;
};

export const withinWorkWeek = (dateToCheck: moment.Moment, startDate: moment.Moment) => {
    return (
        moment(dateToCheck).isSameOrAfter(moment(startDate), 'days') &&
        moment(dateToCheck).isSameOrBefore(moment(startDate).add(6, 'days'), 'days')
    );
};

export const beforeWeek = (dateToCheck: moment.Moment, startDate: moment.Moment) => {
    return moment(dateToCheck).isSameOrBefore(moment(startDate).add(6, 'days'), 'days');
};

export const afterWeek = (dateToCheck: moment.Moment, startDate: moment.Moment) => {
    return moment(dateToCheck).isAfter(moment(startDate).add(6, 'days'), 'days');
};

export const withinMonth = (dateToCheck: moment.Moment, startDate: moment.Moment) => {
    return moment(dateToCheck).isSame(moment(startDate), 'month');
};

export const beforeMonth = (dateToCheck: moment.Moment, startDate: moment.Moment) => {
    return moment(dateToCheck).isSameOrBefore(moment(startDate).add(1, 'month'), 'month');
};

export const afterMonth = (dateToCheck: moment.Moment, startDate: moment.Moment) => {
    return moment(dateToCheck).isAfter(moment(startDate).add(1, 'month'), 'month');
};

export const calculateTotal = (projectTeamName: string, data: PlanningLookaheadReportDataItem[]) => {
    return _.chain(data)
        .filter((item) => item.projectTeamName === projectTeamName)
        .map((item) =>
            _.map(item.countersByWeek, (item) => [item.planCount, item.actualCount, item.forecastCount, item.weekType])
        )
        .thru((counters) => {
            const totals: PlanningLookaheadTotalItem[] = [];
            _.times(10, (i) => {
                const weekTotal: PlanningLookaheadTotalItem = {
                    planTotal: 0,
                    actualTotal: 0,
                    forecastTotal: 0,
                    weekType: WeekType.Past,
                };
                _.times(counters.length, (j) => {
                    weekTotal.planTotal += counters[j][i][0];
                    weekTotal.actualTotal += counters[j][i][1];
                    weekTotal.forecastTotal += counters[j][i][2];
                    weekTotal.weekType = counters[j][i][3];
                });
                totals.push(weekTotal);
            });
            return totals;
        })
        .value();
};

export const formatPlanningCommitmentReliabilityChartSerie = (
    planningCommitment: PlanningCommitmentReliabilityDTO[],
    planValue: PlanValueCountByWeek[],
    week: moment.Moment,
    type: string,
    monthly: boolean
) => {
    let committedAchieved: PlanningCommitmentReliabilityDTO[] = [];
    let committedNotAcheived: PlanningCommitmentReliabilityDTO[] = [];
    let achievedNotCommitted: PlanningCommitmentReliabilityDTO[] = [];
    let commitedByAdditionalSubsystem: PlanningCommitmentReliabilityDTO[] = [];

    let greyCount = 0;
    let blackCount = 0;
    let orangeCount = 0;

    if (type === 'ITR') {
        committedAchieved = _.filter(planningCommitment, (v) =>
            monthly
                ? withinMonth(v.committedForWeek, week) && withinMonth(endDate(v, type), week)
                : withinWorkWeek(v.itrCompleted, week) && withinWorkWeek(endDate(v, type), week)
        );
        committedAchieved = _.uniqBy(committedAchieved, ({ committedForWeek, id }) => `${committedForWeek}${id}`);

        committedNotAcheived = _.filter(
            planningCommitment,
            (v) =>
                withinWorkWeek(v.committedForWeek, week) &&
                v.isSubmitted &&
                (!endDate(v, type) || !withinWorkWeek(endDate(v, type), week))
        );


        greyCount = achievedNotCommitted.length;
        achievedNotCommitted = _.filter(planningCommitment, (v) => withinWorkWeek(endDate(v, type), week));
        achievedNotCommitted = _.uniqBy(achievedNotCommitted, 'id');

        commitedByAdditionalSubsystem = _.filter(
            planningCommitment,
            (v) => v.isSubsystemNotInWeek && v.isSubmitted && withinWorkWeek(v.committedForWeek, week)
        );
        commitedByAdditionalSubsystem = _.uniqBy(
            commitedByAdditionalSubsystem,
            ({ committedForWeek, id }) => `${committedForWeek}${id}`
        );

        greyCount = achievedNotCommitted.length - committedAchieved.length;
        orangeCount = committedAchieved.length + committedNotAcheived.length;
        blackCount = planValue?.find((s) => week.isSame(s.date, 'date'))?.count ?? 0;
    } else {
        committedAchieved = _.filter(planningCommitment, (v) =>
            monthly
                ? withinMonth(v.committedForWeek, week) && v.isSubmitted && beforeMonth(endDate(v, type), week)
                : withinWorkWeek(v.committedForWeek, week) && v.isSubmitted && beforeWeek(endDate(v, type), week)
        );
        committedNotAcheived = _.filter(planningCommitment, (v) =>
            monthly
                ? withinMonth(v.committedForWeek, week) &&
                  v.isSubmitted &&
                  (!endDate(v, type) || afterMonth(endDate(v, type), week))
                : withinWorkWeek(v.committedForWeek, week) &&
                  v.isSubmitted &&
                  (!endDate(v, type) || afterWeek(endDate(v, type), week))
        );
        achievedNotCommitted = _.filter(planningCommitment, (v) =>
            monthly
                ? (!withinMonth(v.committedForWeek, week) || !v.isSubmitted) && withinMonth(endDate(v, type), week)
                : (!withinWorkWeek(v.committedForWeek, week) || !v.isSubmitted) &&
                  withinWorkWeek(endDate(v, type), week)
        );
        const uniqueSubsystems = [
            ...new Set(
                achievedNotCommitted
                    .filter((x) => committedAchieved.filter((s) => s.subsystem == x.subsystem).length == 0)
                    .map((item) => item.subsystem)
            ),
        ];
        greyCount = uniqueSubsystems.length;
    }

    const greenCount = committedAchieved.length;
    const redCount = committedNotAcheived.length;

    if (type === 'RFSU') {
        orangeCount = greenCount + redCount;
        blackCount = planValue?.find((s) => week.isSame(s.date, 'date'))?.count ?? 0;
    }

    let percentage = 0;
    if (greenCount !== 0) {
        percentage = Math.round((greenCount / (greenCount + redCount)) * 100);
    }

    let blueCount = orangeCount ? Math.round((1 - commitedByAdditionalSubsystem.length / orangeCount) * 100) : 0;
    const result: PlanningCommitmentReliabilityTotalItem = {
        greenCount,
        redCount,
        greyCount,
        percentage,
        blackCount,
        orangeCount,
        blueCount,
        weekStart: week,
    };
    return result;
};

const calculateLookaheadStateData = (state: PlanningState, payload: PlanningLookaheadDTO[]) => {
    const currentWeekStartDate = getCurrentWeekStartDate();
    const data = _.chain(_.groupBy(payload, (item) => `${item.projectTeamName}--${item.forecastHeader}`))
        .map((value, key) => {
            const counters = state.lookahead.weeks.map((week) => {
                const planCount = _.filter(value, (v) => withinWorkWeek(v.plan, moment(week.date))).length;
                const actualCount = _.filter(value, (v) => withinWorkWeek(v.actual, moment(week.date))).length;
                const forecastCount = _.filter(value, (v) => withinWorkWeek(v.forecast, moment(week.date))).length;

                const result: PlanningLookaheadCounters = {
                    planCount: planCount,
                    actualCount: actualCount,
                    forecastCount: forecastCount,
                    weekType: moment(week.date).isBefore(currentWeekStartDate, 'days')
                        ? WeekType.Past
                        : WeekType.Upcoming,
                };
                return result;
            });

            const splittedKey = key.split('--');
            const result: PlanningLookaheadReportDataItem = {
                projectTeamName: splittedKey[0],
                forecastHeader: splittedKey[1],
                countersByWeek: counters,
            };
            return result;
        })
        .value();

    const gpTotals = calculateTotal(Constants.projectTeamNames.GP, data);
    const giTotals = calculateTotal(Constants.projectTeamNames.GI, data);

    const grandTotals = _.zipWith(gpTotals, giTotals, (v1, v2) => {
        return {
            planTotal: v1.planTotal + v2.planTotal,
            actualTotal: v1.actualTotal + v2.actualTotal,
            forecastTotal: v1.forecastTotal + v2.forecastTotal,
            weekType: v1.weekType,
        };
    });

    return {
        data,
        gpTotals,
        giTotals,
        grandTotals,
    };
};
