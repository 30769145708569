<div class="popup">
    <div class="icon-remove" (click)="onCancel()"></div>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">status change history</span>
    </p>
    <mat-dialog-content>
        <table
            mat-table
            matSort
            [dataSource]="dataSource"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>status</th>
                <td mat-cell *matCellDef="let element">
                    <span class="align-status validation-status" [ngClass]="getValidationClassName(element.status)">{{
                        element.status
                    }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>date updated</th>
                <td mat-cell *matCellDef="let element">{{ element.date | date: 'd/MMM/y' }}</td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>changed by</th>
                <td mat-cell *matCellDef="let element">{{ element.user.name }} {{ element.user.surname }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>updated properties</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.action }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-dialog-content>
    <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
</div>
