import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent extends BaseComponent implements OnInit {
    navLinks: { label: string; link?: string }[] = [];
    isLoading = false;

    ngOnInit(): void {
        this.navLinks = [
            {
                label: 'general app',
                link: './general-app-configuration',
            },
            {
                label: 'manual uploads',
                // link: './manual-uploads',
            },
            {
                label: 'automated file transfers - MCPlus',
                link: './automated-file-transfers-mcplus',
            },
            {
                label: 'automated file transfers - other sources',
                link: './automated-file-transfers-other-sources',
            },
            {
                label: 'change management - automated',
                // link: './change-management-automated',
            },
            {
                label: 'change management - manual',
                link: './change-management-manual',
            },
        ];
    }
}
