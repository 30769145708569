import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ToastService } from 'src/app/services/shared/toast.service';
import { ChangeManagementService } from 'src/app/services/api/webapi-services/change-management.service';
import { CriticalRFIManualPriorityHistoryError, CriticalRFIManualPriorityHistoryActionTypes, CriticalRFIManualPriorityHistoryRequest, CriticalRFIManualPriorityHistorySuccess, CriticalRFIExpectedTimeofCompletionHistoryRequest, CriticalRFIExpectedTimeofCompletionHistoryError, CriticalRFIExpectedTimeofCompletionHistorySuccess } from './actions';
import { Injectable } from "@angular/core";

@Injectable()
export class ManualPriorityHistoryEffects{

    constructor(
        private actions$: Actions,        
        private toastService: ToastService,
        private changeManagementService: ChangeManagementService
    ) {}

    @Effect()
    getManualPriorityHistory$ = this.actions$.pipe(
        ofType<CriticalRFIManualPriorityHistoryRequest>(CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIManualPriorityHistoryRequest),
        mergeMap((action) => {
            return this.changeManagementService.getManualPriorityHistory(action.payload.id)
                .pipe(
                    map((data) => new CriticalRFIManualPriorityHistorySuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting Manual Override Priority history. Please contact Program Administrator.'
                        );
                        return of(new CriticalRFIManualPriorityHistoryError(error));
                    })
                );
        })
    );

    @Effect()
    getExpectedTimeOfCompletionHistory$ = this.actions$.pipe(
        ofType<CriticalRFIExpectedTimeofCompletionHistoryRequest>(CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIExpectedTimeofCompletionHistoryRequest),
        mergeMap((action) => {
            return this.changeManagementService.getExpectedTimeofCompletionHistory(action.payload.id)
                .pipe(
                    map((data) => new CriticalRFIExpectedTimeofCompletionHistorySuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting Expected Time of Completion history. Please contact Program Administrator.'
                        );
                        return of(new CriticalRFIExpectedTimeofCompletionHistoryError(error));
                    })
                );
        })
    );
}
