<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">Subsystem MC Dashboard 2</div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.goocs.value.length" removable>
                                GOOC: {{ displayGoocsSelected(filterForm.controls.goocs.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('goocs')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcEngineer.value.length" removable>
                                MC Engineer: {{ displaySelectedMCEngineer(filterForm.controls.mcEngineer.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('mcEngineer')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                System Owner: {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.stagedStartUpPriorities.value.length" removable>
                                Staged Start Up Priorities:
                                {{ displayMultipleSelected(filterForm.controls.stagedStartUpPriorities.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('stagedStartUpPriorities')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <app-multiple-items-selector
                        class="container620 margin-right20"
                        [setInput]="setGoocInput"
                        formControlName="goocs"
                        [searchFunc]="getGoocs"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="goocAutocompleteDisplayedColumns"
                        [filterForm]="filterForm"
                        [formSetter]="'goocs'"
                        [propertyToShow]="'no'"
                        [placeholder]="'GOOC'"
                    >
                    </app-multiple-items-selector>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>MC Engineer</mat-label>
                        <mat-select formControlName="mcEngineer" multiple>
                            <mat-option *ngFor="let mce of mcEngineers" [value]="mce.id">{{ mce.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwner" multiple>
                            <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mixedapp-container">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManager" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="container300 margin-right20">
                        <app-multiple-items-selector
                            [setInput]="setContractorInput"
                            formControlName="contractors"
                            [searchFunc]="getContractors"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            (autocompleteClosed)="onContractorsClosed()"
                            (itemRemoved)="onContractorsClosed()"
                            [filterForm]="filterForm"
                            formSetter="contractors"
                            propertyToShow="contractNo"
                            placeholder="MSL Contractor"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300">
                        <app-multiple-items-selector
                            [setInput]="setStagedStartUpPriorityInput"
                            formControlName="stagedStartUpPriorities"
                            [searchFunc]="getStagedStartUpPriorities"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="stagedStartUpPrioritiesAutocompleteDisplayedColumns"
                            (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                            (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                            [filterForm]="filterForm"
                            formSetter="stagedStartUpPriorities"
                            propertyToShow="priority"
                            placeholder="Staged Start-up Priority"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'priorityName'"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
    <div class="buttons-container flex">
        <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
            export to excel
        </button>
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filter
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">
            search
        </button>
    </div>
    <div class="results-container">
        <div class="table-checkboxes">
            <mat-checkbox [checked]="showHideColumns.goocNo" (change)="reverseValue('goocNo')"
                >GOOC Number
            </mat-checkbox>
            <mat-checkbox [checked]="showHideColumns.constructionArea" (change)="reverseValue('constructionArea')"
                >Construction Area</mat-checkbox
            >
            <mat-checkbox [checked]="showHideColumns.mcSequence" (change)="reverseValue('mcSequence')"
                >MC Sequence</mat-checkbox
            >
        </div>
        <div
            [ngClass]="{
                'scroll-container': scrollSearch
            }"
        >
            <table mat-table [dataSource]="data">
                <ng-container matColumnDef="subsystemNo">
                    <th mat-header-cell disableClear *matHeaderCellDef>Subsystem</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.subsystemNo }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="subsystemName">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        Subsystem Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.subsystemName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="goocNo">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        [ngClass]="{
                            hidecells: !showHideColumns.goocNo
                        }"
                    >
                        GOOC Number
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [ngClass]="{
                            hidecells: !showHideColumns.goocNo
                        }"
                    >
                        {{ element.goocNo }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="constructionArea">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        [ngClass]="{
                            hidecells: !showHideColumns.constructionArea
                        }"
                    >
                        Construction Area
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [ngClass]="{
                            hidecells: !showHideColumns.constructionArea
                        }"
                    >
                        {{ element.constructionArea }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="mcSequence">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        [ngClass]="{
                            hidecells: !showHideColumns.mcSequence
                        }"
                    >
                        MC Sequence
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [ngClass]="{
                            hidecells: !showHideColumns.mcSequence
                        }"
                    >
                        {{ element.mcSequence }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="unallocated">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>QVDs Allocated</div>
                        <div>
                            <div>Total</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.unallocated?.total }}</div>
                            <div>{{ 100 - element.unallocated?.percentComplete | number: '1.2-2' }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="hydrotests">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>Hydrotests Complete</div>
                        <div>
                            <div>Total</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.hydrotests?.total }}</div>
                            <div>{{ element.hydrotests?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="reinstatements">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>Reinstatements Complete</div>
                        <div>
                            <div>Total</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.reinstatements?.total }}</div>
                            <div>{{ element.reinstatements?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="terminations">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>Terminations Complete</div>
                        <div>
                            <div>Total</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.terminations?.total }}</div>
                            <div>{{ element.terminations?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="instrument">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>Instrument Complete</div>
                        <div>
                            <div>Total</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.instrument?.total }}</div>
                            <div>{{ element.instrument?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="exInsp">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>EX Insp. Complete</div>
                        <div>
                            <div>Total</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.exInsp?.total }}</div>
                            <div>{{ element.exInsp?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="heatTrace">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>Heat Trace Complete</div>
                        <div>
                            <div>Total</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.heatTrace?.total }}</div>
                            <div>{{ element.heatTrace?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="lsp">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>LSP Complete</div>
                        <div>
                            <div>Total</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.lsp?.total }}</div>
                            <div>{{ element.lsp?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr
                    [ngClass]="{ total: !row.subsystemNo }"
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </div>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
                [disabled]="scrollSearch"
            >
            </mat-paginator>
            <input
                type="number"
                [value]="this.paginator.pageIndex + 1"
                (change)="onPageChange($event.target.value)"
                [disabled]="scrollSearch"
            />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading$ | async"></app-loading-indicator>
</main>
