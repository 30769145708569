import { Injectable } from '@angular/core';
import { WaypointDTO } from 'src/app/models/waypoint-dto';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class WaypointService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/waypoint';
    }

    get() {
        return this.http.get<WaypointDTO[]>(`${this.rootUrl}${this.endpoint}`);
    }

    remove(name: string) {
        var encodedName = encodeURIComponent(name);
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?name=${encodedName}`);
    }

    update(waypoint: WaypointDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, waypoint);
    }

    add(waypoint: WaypointDTO) {
        return this.http.post<WaypointDTO>(`${this.rootUrl}${this.endpoint}`, waypoint);
    }
}
