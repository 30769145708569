<main class="main">
    <div class="import-container">
        <h4>Subsystem schedule/plan import</h4>
        <div class="import-subtitle">
            <button
                mat-icon-button
                (click)="showOrder = !showOrder"
                title="{{
                    showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                }}"
            >
                <mat-icon>info</mat-icon>
            </button>
            See
            <b (click)="downloadTemplate('Subsystem Planning Dates.xlsx')">Subsystem Planning Dates.xlsx</b> structure.
            Subsystem MC Plan, RFSU Plan, MC Walkdown Forecast, RFSU Walkdown Forecast, MC Forecast, RFSU Forecast, Pre-Comm Start & Pre-Comm End Dates
            in SCMT are synchronised to upload file.<br /><br />Where existing MC/RFSU Plan dates differ from upload
            file, Admin is given the opportunity to reject update - by default, all dates are aligned with the uploaded
            file​, except if upload file has BLANK entry; then existing data in SCMT is maintained.<br /><br />MC
            Walkdown Forecast in upload file automatically overwrite current MC Walkdown Forecast date in SCMT,
            except if the upload file has BLANK entries for MC Walkdown Forecast date or any of the certificates ('RFSU', 'MCC2', 'FPL-SS') is accepted 
            or Waldkown Actual date in SCMT is not blank.
            <br />MC Forecast in upload file automatically overwrite current MC Forecast date in SCMT,
            except if the upload file has BLANK entries for MC Forecast date or the 'MCC2' certificate is accepted.<br /><br />
            RFSU Forecast dates in upload file will automatically overwrite SCMT RFSU forecast date. <br />
            RFSU Walkdown Forecast Dates in upload file will automatically overwrite SCMT RFSU Walkdown Forecast Date.<br /><br />
            Result file has a tab (Quarantine Results) that contains list of Subsystems in SCMT that are not found in
            the upload file.
        </div>
        <div *ngIf="!showOrder" class="list-container">
            <div class="list-structure">
                <ol>
                    <li>Subsystem No.</li>
                    <li>MC Plan Basis</li>
                    <li>MC Forecast</li>
                    <li>MC Walkdown Forecast</li>
                    <li>RFSU Walkdown Forecast</li>
                    <li>RFSU Plan Basis</li>
                    <li>RFSU Forecast</li>
                    <li>Pre-Comm Start</li>
                    <li>Pre-Comm Finish</li>
                </ol>
            </div>
        </div>
    </div>
    <div class="delta-container">
        <div class="section-header">
            <h4>Delta validation</h4>
            <span> - validate changes to Subsystem Planning data in live database</span>
        </div>
        <span *ngIf="!deltaData.data.length">No delta validation data available.</span>
        <div class="changebuttons-container">
            <button (click)="patchAllChanges(true)" *ngIf="deltaData.data.length" mat-icon-button title="accept">
                <mat-icon>check_circle_outline</mat-icon>
            </button>
            <span *ngIf="deltaData.data.length" class="margin-right20">Accept all changes</span>
            <button (click)="patchAllChanges(false)" *ngIf="deltaData.data.length" mat-icon-button title="reject">
                <mat-icon>highlight_off</mat-icon>
            </button>
            <span *ngIf="deltaData.data.length">Reject all changes</span>
        </div>
        <table mat-table [dataSource]="deltaData" *ngIf="deltaData.data.length">
            <ng-container matColumnDef="subsystem">
                <th mat-header-cell *matHeaderCellDef class="empty">Subsystem</th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.subsystem }}
                </td>
            </ng-container>
            <ng-container matColumnDef="projectTeamName">
                <th mat-header-cell *matHeaderCellDef class="empty">Project Team</th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.projectTeamName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="contractor">
                <th mat-header-cell *matHeaderCellDef class="empty">Contractor</th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.contractor }}
                </td>
            </ng-container>
            <ng-container matColumnDef="mcPlanLive">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150">MC Plan</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.mcPlanLive | date: 'd/MMM/y' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="rfsuPlanLive">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150">RFSU Plan</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.rfsuPlanLive | date: 'd/MMM/y' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="arrow">
                <th mat-header-cell *matHeaderCellDef class="empty cell-width-75"></th>
                <td mat-cell *matCellDef="let element" class="empty">
                    <mat-icon>arrow_forward</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="mcPlanImport">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150">MC Plan</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.mcPlanImport | date: 'd/MMM/y' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="rfsuPlanImport">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150">RFSU Plan</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.rfsuPlanImport | date: 'd/MMM/y' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="empty cell-width-150"></th>
                <td mat-cell *matCellDef="let element" class="empty">
                    <div *ngIf="!element.deltaState" (click)="acceptChange(element)">
                        <button mat-icon-button title="accept">
                            <mat-icon>check_circle_outline</mat-icon>
                        </button>
                        <span>Accept</span>
                    </div>
                    <div *ngIf="element.deltaState" (click)="rejectChange(element)">
                        <button mat-icon-button title="reject">
                            <mat-icon>highlight_off</mat-icon>
                        </button>
                        <span>Reject</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="emptyitem">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"></th>
            </ng-container>
            <ng-container matColumnDef="firstGroupDesc">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">Current Data in SCMT</th>
            </ng-container>
            <ng-container matColumnDef="secondGroupDesc">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">Update in Upload File</th>
            </ng-container>
            <tr
                mat-header-row
                *matHeaderRowDef="['emptyitem', 'firstGroupDesc', 'arrow', 'secondGroupDesc']"
                class="top-header"
            ></tr>
            <tr mat-header-row *matHeaderRowDef="displayedDeltaDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDeltaDataColumns"></tr>
        </table>
        <mat-paginator
            *ngIf="deltaData.data.length"
            [length]="deltaDataResultsLength"
            [pageSize]="deltaDataPageSize"
            [pageSizeOptions]="[10, 15, 20]"
            (page)="onDeltaDataPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <div *ngIf="isDeltaDataLoading$ | async" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="upload-log-container">
        <h4>Import history</h4>
        <table mat-table [dataSource]="uploadLogData">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="getImportClassName(element.status)">
                        {{ element.status }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Start date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.startDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.endDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="infoMessage">
                <th mat-header-cell *matHeaderCellDef>Info message</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.infoMessage ? element.infoMessage : ''"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="errorMessage">
                <th mat-header-cell *matHeaderCellDef>Error message</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.errorMessage ? element.errorMessage : 'No errors' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.user }}
                </td>
            </ng-container>
            <ng-container matColumnDef="downloadResult">
                <th mat-header-cell *matHeaderCellDef>Download result</th>
                <td mat-cell *matCellDef="let element">
                    <svg
                        class="downloadIcon"
                        *ngIf="element.outputDataLink"
                        (click)="downloadData(element.outputDataLink)"
                        width="18px"
                        height="20px"
                        viewBox="0 0 16 17"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                        <title>{{ element.outputDataLink }}</title>
                        <desc>Created with sketchtool.</desc>
                        <g
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <g
                                id="New-FCNi-Copy"
                                transform="translate(-1259.000000, -1440.000000)"
                                stroke="#0066B2"
                                stroke-width="2"
                            >
                                <g id="Buttons/Primary-Copy" transform="translate(1256.000000, 1438.000000)">
                                    <path d="M4,18 L18,18" id="Line-3"></path>
                                    <path
                                        d="M5.5,8.5 L16.5,8.5"
                                        id="Line-3-Copy"
                                        transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                    ></path>
                                    <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span *ngIf="isImportInProgress(element.status)" class="in-progress"></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedUploadLogDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedUploadLogDataColumns"></tr>
        </table>
        <div *ngIf="isUploadLogLoading$ | async" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading$ | async"> </app-loading-indicator>
</main>
