import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, OrderDirection } from '../../common.model';

export interface ITRManhoursState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deleteData: ITRManhoursDeletePagination;
    isDeleteDataLoading: boolean;
    deleteFilter: ITRManhoursDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class ITRManhoursDeleteDTO {
    deleteState: boolean;
    qvdNumber: string;
    manhour: string;
}

export class ITRManhoursDeleteFilter {
    page = 0;
    take = 10;
    sortBy = 'qvdNumber';
    direction: OrderDirection = OrderDirection.Desc;
    columnQVDNumbers: CheckListColumn = null;
}
export class ITRManhoursDeletePagination {
    items: ITRManhoursDeleteDTO[] = [];
    totalCount = 0;
}
