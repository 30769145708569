<div class="popup">
    <p mat-dialog-title class="title">
        <span class="close" (click)="close(false)">&times;</span>
        <span class="font-gotham-narrow-bold">select IWPs to link to {{ changeDocNo }}</span>
    </p>

    <div class="radio-button-container">
        <mat-radio-group class="radio-group" aria-label="Select an option" [disabled]="!hasUpdatePriviliges">
            <mat-radio-button value="partial" (change)="radioChange($event)" [checked]="!isListComplete"
                >Partial IWP List
            </mat-radio-button>
            <mat-radio-button value="complete" (change)="radioChange($event)" [checked]="isListComplete"
                >IWP List Complete</mat-radio-button
            >
        </mat-radio-group>
        <button
            *ngIf="hasUpdatePriviliges"
            type="button"
            class="sct-button sct-button-light ok-button"
            (click)="addRow()"
            [disabled]="anyRowInEditMode()"
        >
            Add IWP
        </button>
    </div>

    <div class="table-container">
        <mat-table #table [dataSource]="dataSource">
            <ng-container matColumnDef="iwp">
                <mat-header-cell *matHeaderCellDef> IWP </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <div *ngIf="!row.isInEditMode">{{ row.iwpNumber }}</div>
                    <app-multiple-items-selector
                        *ngIf="row.isInEditMode"
                        class="container620 wide-field"
                        [setInput]="setIWPInput"
                        [isDisabled]="!hasUpdatePriviliges"
                        [isSelectAll]="false"
                        [setValue]="row.iwpNumber.length > 0 ? [{ name: row.iwpNumber, selected: true }] : null"
                        [searchFunc]="searchIWPs"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['name']"
                        (autocompleteClosed)="onIWPClosed($event, i)"
                        (itemRemoved)="onIWPClosed($event, i)"
                        [propertyToShow]="'name'"
                        [oneItemMode]="true"
                        [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="subsystems">
                <mat-header-cell *matHeaderCellDef> Subsystems </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <div *ngIf="!row.isInEditMode">{{ row.subsystems.join(', ') }}</div>
                    <app-multiple-items-selector
                        *ngIf="row.isInEditMode"
                        class="container620 wide-field"
                        [setInput]="setSubsystemInput"
                        [isDisabled]="row.iwpNumber.length === 0 || !hasUpdatePriviliges"
                        [setValue]="parseSubsystems(row.subsystems)"
                        [searchFunc]="getSubsystemsForChangeDocument"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['name']"
                        (autocompleteClosed)="onSubsystemsClosed($event, i)"
                        (itemRemoved)="onSubsystemsClosed($event, i)"
                        [propertyToShow]="'name'"
                        [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <div class="actions-container">
                        <button
                            *ngIf="!row.isInEditMode"
                            mat-icon-button
                            class="edit-row"
                            (click)="editRow(i)"
                            [disabled]="anyRowInEditMode(i)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button
                            *ngIf="row.isInEditMode"
                            mat-icon-button
                            class="accept-row"
                            (click)="acceptRow(i)"
                            [disabled]="!row.iwpNumber"
                        >
                            <mat-icon>done</mat-icon>
                        </button>
                        <button mat-icon-button class="remove-row" (click)="removeRow(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>

    <div></div>
</div>

<div class="buttons-container-space margin-from-top-left">
    <button type="button" (click)="close(false)" class="sct-button sct-button-white">cancel</button>
    <button
        type="submit"
        *ngIf="hasUpdatePriviliges"
        class="sct-button sct-button-light ok-button"
        (click)="close(true)"
        [disabled]="anyRowInEditMode()"
    >
        submit
    </button>
</div>
