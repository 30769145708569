import { createReducer, on } from '@ngrx/store';
import { PipelineState } from './model';
import * as PipelineActions from './actions';

const initialState: PipelineState = {
    logs: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isDataReadyToProcess: false,
    isAutomaticUploadInProgress: true,
};

export const pipelineReducer = createReducer(
    initialState,
    on(PipelineActions.pipelineLogRequest, (state) => ({
        ...state,
        isLoading: true,
    })),
    on(PipelineActions.pipelineLogSuccess, (state, action) => ({
        ...state,
        logs: action.response,
        isLoading: false,
    })),
    on(PipelineActions.pipelineLogError, (state) => ({
        ...state,
        isLoading: false,
    })),
    on(PipelineActions.pipelineClearInProgressSpinner, (state) => ({
        ...state,
        isImportInProgress: false,
    }))
);