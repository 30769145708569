import { OrderDirection, StagedStartUpPriority } from "../common.model";

export class TraceHeatingStatusFilter {
    take: number = 25;
    page: number = 0;
    tdbSubsystems: Subsystem[] = [];
    processSubsystems: Subsystem[] = [];
    isTdbMcComplete?: boolean = null;
    isAllProcessMcCompleteOnCircuit?: boolean = null;
    circuit: MultipleItemsSelector[] = [];
    heatTrackingTapes: MultipleItemsSelector[] = [];
    tdbStagedPriority: StagedStartUpPriority[] = [];
    processStagedPriority: StagedStartUpPriority[] = [];
    orderBy: OrderDirection = OrderDirection.Asc;
    timezoneOffset = 0;
}

export class Subsystem {
    id: string;
    name: string;
}

export class MultipleItemsSelector {
    id: string;
}

export class TraceHeatingStatusDto {
    tdbSubsystem: string;
    tdbMcDate?: Date;
    tdbCircuit: string;
    tdbHeatTracingTape: string;
    processSubsystem: string;
    processMcDate?: Date;
    attachments: THSAttachmentDto[] = [];
}

export class THSAttachmentDto {
    tdbSubsystem: string;
    name: string;
    link: string;
    file?: File;
    isValid?: boolean;
}

export class TraceHeatingStatusResultDto {
    items: TraceHeatingStatusDto[];
    totalCount: number;
}
