import { OrderDirection, RFO } from '../common.model';

export interface SystemisedDrawingState {
    dataPagination: SystemisedDrawingDataPagination;
    filter: SystemisedDrawingFilter;
    isLoading: boolean;
}

export class SystemisedDrawingDataPagination {
    items: SystemisedDrawingDTO[] = [];
    totalCount = 0;
}

export class SystemisedDrawingFilter {
    page = 0;
    take = 25;
    systemisedDrawingNo: string[] = [];
    subsystems: { id: string }[] = [];
    rfos: RFO[] = [];
    areaBreakdown: string[] = [];
    engineeringDrawingNo: string[] = [];
    timezoneOffset = 0;
    sortBy = 'systemisedDrawingNo';
    direction = OrderDirection.Asc;
}

export class SystemisedDrawingDTO {
    systemisedDrawingNo: string;
    subsystems: string;
    engineeringDrawingNo: string;
}
