import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../../base.component';
import { takeWhile } from 'rxjs/operators';
import { PLIPlanningExportToExcelRequest } from 'src/app/store/pli-planning/actions';
import { SaveWeekPlanRequest } from 'src/app/store/pli-planning/actions';
import { PLIPlanningFilter } from 'src/app/store/pli-planning/model';
import * as moment from 'moment';
import { getCurrentWeekStartDate } from 'src/app/extensions';

@Component({
    selector: 'app-pli-planning',
    templateUrl: './pli-planning.component.html',
    styleUrls: ['./pli-planning.component.scss'],
})
export class PLIPlanningComponent extends BaseComponent implements OnInit {
    isLoading$ = this.store.select((store) => store.pliPlanningState.isLoading);
    filter$ = this.store.select((store) => store.pliPlanningState.filter);
    filter = new PLIPlanningFilter();

    get isFutureWeek(): boolean {
        return moment(this.filter.weekStart).isAfter(getCurrentWeekStartDate());
    }

    constructor(private store: Store<ApplicationState>, private location: Location) {
        super();
    }

    ngOnInit(): void {
        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data) {
                this.filter = { ...this.filter, ...data };
            }
        });
    }

    goBack() {
        this.location.back();
    }

    exportToExcel() {
        this.store.dispatch(new PLIPlanningExportToExcelRequest());
    }

    saveWeekPlan() {
        this.store.dispatch(new SaveWeekPlanRequest());
    }
}
