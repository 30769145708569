<main class="main">
    <div class="main-container">
        <mat-accordion>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="64px">
                    <h4>MAC DCN import</h4>
                </mat-expansion-panel-header>
                <div class="import-subtitle">
                    See
                    <b (click)="downloadRegisterTemplate(MACDCNRegisterName)">MAC DCN List.xlsx</b> structure.
                    Records in upload file are updated into SCMT. Data only imported for matching subsystems in SCMT.
                    Foreign key links to existing Contractors in SCMT are made at upload using Source Data mapping rules
                    (Contract field would be BLANK if no match exists). Recommended sequence of data update: Contractor,
                    Master Subsystem List, MAC DCN. Result file has a tab (Quarantine Results) that contains list of
                    MAC DCNs in SCMT that are not found in the upload file (tagged as "RecordNotFound"). The Quarantine
                    Result tab also lists MAC DCNs imported from the upload file that do not contain match to Contractor
                    (tagged as "NoMatchingContractor"), or Design Status in SCMT (tagged as "NoMatchingDesignStatus"),
                    or Implementation Status in SCMT (tagged as "NoMatchingImplementationStatus") -
                    these data mapping rules are adjustable through Admin Configuration page.
                </div>

                <h4>Source Data mapping</h4>
                <div class="import-subtitle">
                    <div>
                        Adjust rules for mapping the "Contractor" column in upload file to Contractors in SCMT and rules
                        for mapping the "Status" column in upload file to change document Design Status & Implementation
                        Status in SCMT.
                        Multiple source values can be mapped to single SCMT value - where there are multiple values,
                        enter as comma-separated
                        (e.g. "MEIG, MEI-G" mapped to K-500-182-ON in SCMT). Design Status mapping is also dependent on
                        Field Impact of a Change
                        (by default all MAC DCNs have field impact; verified through Change Validation by the
                        Certification Team).
                        Implementation Status, by default, apply to Changes with a field impact (this no selection of
                        Field Impact required).
                    </div>
                </div>
                <app-configuration-panel [changeType]="getChangeTypeString(changeTypes.MACDCN)">
                </app-configuration-panel>
            </mat-expansion-panel>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="64px">
                    <h4>RFI Import</h4>
                </mat-expansion-panel-header>

                <div class="import-subtitle">
                    <div>
                        See <b (click)="downloadRegisterTemplate(RFIRegisterName)">{{ RFIRegisterName }}</b>structure.
                        Records in upload file are updated into SCMT. Data only
                        imported for matching Contractors in SCMT using Source Data mapping rules. Foreign key links to
                        any matching subsystems are made at upload based on subsystems in upload file. Recommended
                        sequence of data update: Contractor, Master Subsystem List, RFI Register.
                        Result file has a tab (Quarantine Results) that contains list of RFIs in SCMT that are not found
                        in the upload file (tagged as "RecordNotFound"). The Quarantine Result tab also lists RFIs
                        imported from the upload file that do not contain match to Design Status in SCMT (tagged as
                        "NoMatchingDesignStatus") - these data mapping rules are adjustable through Admin Configuration
                        page.

                        <h4>Source Data mapping</h4>
                        <div class="import-subtitle">
                            <div>
                                Adjust rules for mapping the "Contractor" column in upload file to Contractors in SCMT
                                and rules
                                for mapping the
                                "Status" column in upload file to change document Design Status & Implementation Status
                                in SCMT.
                                Multiple source values can be mapped to single SCMT value - where there are multiple
                                values,
                                enter as
                                comma-separated
                                (e.g. "MEIG, MEI-G" mapped to K-500-182-ON in SCMT). Design Status mapping is also
                                dependent on
                                Field Impact of
                                a Change
                                (by default all MAC DCNs have field impact; verified through Change Validation by the
                                Certification Team).
                                Implementation Status, by default, apply to Changes with a field impact (this no
                                selection of
                                Field Impact
                                required).
                            </div>
                        </div>
                    </div>
                </div>
                <app-configuration-panel [changeType]="getChangeTypeString(changeTypes.RFI)"></app-configuration-panel>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</main>