import { OrderDirection } from '../../../common.model';
import { ContractorDTO } from 'src/app/models/contractor-dto';

export class ContractorFilter {
    page = 0;
    take = 5;
    sortBy = 'projectTeamName';
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
}

export interface ContractorMaintenanceState {
    contractorDataPagination: ContractorDataPagination;
    filter: ContractorFilter;
    isLoading: boolean;
}

export class ContractorDataPagination {
    items: ContractorDTO[] = [];
    totalCount = 0;
}
