import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, OrderDirection } from '../../common.model';

export interface ScheduleActivityState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deleteData: ScheduleActivityDeletePagination;
    isDeleteDataLoading: boolean;
    deleteFilter: ScheduleActivityDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class ScheduleActivityDeleteDTO {
    deleteState: boolean;
    subsystem: string;
    activityId: string;
    activity: string;
    activityDiscipline: string;
    area: string;
    finish: moment.Moment;
    start: moment.Moment;
}

export class ScheduleActivityDeleteFilter {
    page = 0;
    take = 10;
    sortBy = 'activityId';
    direction: OrderDirection = OrderDirection.Desc;
    columnActivityIds: CheckListColumn = null;
    columnActivityNames: CheckListColumn = null;
    columnStart: CalendarColumn = null;
    columnFinish: CalendarColumn = null;
    columnSubsystems: CheckListColumn = null;
    columnActivityDisciplines: CheckListColumn = null;
    columnAreas: CheckListColumn = null;
}
export class ScheduleActivityDeletePagination {
    items: ScheduleActivityDeleteDTO[] = [];
    totalCount = 0;
}
