<div class="filter-container">
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-basic-chip *ngIf="filterForm.controls.rfos?.value.length" removable>
                            RFO: {{ displayMultipleByName(filterForm.controls.rfos.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.subsystems?.value.length" removable>
                            Subsystem: {{ displayMultipleByName(filterForm.controls.subsystems.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('subsystems')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                            ITR Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.scManagers.value.length" removable>
                            SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManagers.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('scManagers')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                            ITR Contractor: {{ displayMultipleByName(filterForm.controls.contractors.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.constraintTypes.value.length" removable>
                            Constraint Types: {{ filterForm.controls.constraintTypes.value.join(', ') }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('constraintTypes')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.workAreas.value.length" removable>
                            Work Areas: {{ filterForm.controls.workAreas.value.join(', ') }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('workAreas')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                            Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                            Area Breakdown:
                            {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                            RFSU Contractor:
                            {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                        </mat-basic-chip>
                    </div>
                </mat-expansion-panel-header>

                <div class="filter-row">

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            formControlName="subsystems"
                            [setInput]="setSubsystemInput"
                            [searchFunc]="getSubsystems"
                            [isAsync]="true"
                            placeholder="Subsystem"
                            [displayedColumns]="['id', 'name']"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>ITR Discipline</mat-label>
                        <mat-select formControlName="disciplines" multiple>
                            <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManagers" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="contractors"
                            [searchFunc]="getContractorsData"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['contractNo', 'contract']"
                            [filterForm]="filterForm"
                            [formSetter]="'contractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'ITR Contractor'"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            [secondPropertyToShow]="'contract'"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>Constraint Type</mat-label>
                        <mat-select formControlName="constraintTypes" multiple>
                            <mat-option *ngFor="let cType of constraintTypes" [value]="cType">{{ cType }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300 margin-right20"
                            #workAreaItemSelector
                            placeholder="Work Area"
                            formControlName="workAreas"
                            formSetter="workAreas"
                            propertyToShow="id"
                            [oneItemMode]="true"
                            [displayedColumns]="['id']"
                            [searchFunc]="searchWorkAreas"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [setInput]="setWorkAreaInput"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFSUContractorsInput"
                            formControlName="rfsuContractors"
                            [searchFunc]="getRFSUContractors"
                            [isAsync]="true"
                            [displayedColumns]="['contractNo', 'contract']"
                            [filterForm]="filterForm"
                            [formSetter]="'rfsuContractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'RFSU Contractor'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>Area Completion Lead</mat-label>
                        <mat-select formControlName="areaCompletionLead" multiple>
                            <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>

    <div class="buttons-container flex">
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filters
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
    </div>
</div>

