<main class="main register-container">
    <form [formGroup]="filterForm">
        <div class="title-container">
            <div class="title">
                Change validation register
            </div>
            <div class="lookahead-button">
                <button type="button" (click)="addNewChange()" class="sct-button sct-button-light">
                    add new change
                </button>
            </div>
        </div>
        <mat-accordion class="filter-container">
            <mat-expansion-panel [ngClass]="{'hideme':!showFilterPanel}" #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                </mat-expansion-panel-header>
                <mat-form-field class="margin-right20 container300">
                    <mat-label>Number</mat-label>
                    <input formControlName="number" matInput />
                </mat-form-field>
                <mat-form-field class="margin-right20 container620">
                    <mat-label>Title</mat-label>
                    <input formControlName="title" matInput />
                </mat-form-field>
                <mat-form-field class="container300">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="types" multiple>
                        <mat-option *ngFor="let type of changeTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Data Updated</mat-label>
                    <mat-select formControlName="dataUpdated" multiple>
                        <mat-option value="0">Yes</mat-option>
                        <mat-option value="1">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Project Team</mat-label>
                    <mat-select formControlName="projectTeamNames" multiple>
                        <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Discipline</mat-label>
                    <mat-select formControlName="disciplines" multiple>
                        <mat-option *ngFor="let discipline of disciplines" [value]="discipline">{{ discipline }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="container300">
                    <mat-label>Design Status</mat-label>
                    <mat-select formControlName="changeStatuses" multiple>
                        <mat-option *ngFor="let cs of changeStatuses" [value]="cs">{{ cs }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="mixedapp-container">
                    <div class="container300-mh55 margin-right20">
                        <app-multiple-items-selector class="container300" formControlName="validators"
                            [searchFunc]="getValidators" isAsync="true" propertyToShow="name" placeholder="Validator"
                            [setInput]="setValidatorsInput" [ngStyle]="{ display: isIE == true ? 'inline' : null }">
                        </app-multiple-items-selector>
                    </div>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Validation Status</mat-label>
                        <mat-select formControlName="validationStatuses" multiple>
                            <mat-option value="not started">Not Started</mat-option>
                            <mat-option value="in progress">In Progress</mat-option>
                            <mat-option value="completed">Completed</mat-option>
                            <mat-option value="test pack requested">Test Pack Requested</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="container620-mh55 margin-right20">
                        <app-multiple-items-selector class="container620" formControlName="subsystems"
                            [searchFunc]="getSubsystems" [displayedColumns]="subsystemAutocompleteDisplayedColumns"
                            [setInput]="setSubsystemInput" [isAsync]="true" placeholder="Subsystem"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }">
                        </app-multiple-items-selector>
        
                    </div>
                </div>
                <div class="mixedapp-container">
                    <div class="container620-mh55 margin-right20">
                        <app-multiple-items-selector class="container620" [setInput]="setRFSUContractorsInput"
                            formControlName="rfsuContractors" [searchFunc]="getRFSUContractors" [isAsync]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns" [filterForm]="filterForm"
                            [formSetter]="'rfsuContractors'" [propertyToShow]="'contractNo'" [placeholder]="'RFSU Contractor'"
                            [panelWidth]="480" [secondPropertyToShow]="'contract'">
                        </app-multiple-items-selector>
                    </div>
                    <div class="container620-mh55 margin-right20">
                        <app-multiple-items-selector class="container620" [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown" [searchFunc]="getAreaBreakdown" [isAsync]="true"
                            [filterForm]="filterForm" [formSetter]="'areaBreakdown'" [placeholder]="'Area Breakdown'">
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div class="mixedapp-container radiobuttons">
                    <div class="container-radiobuttons margin-right20" *ngIf="isActiveFilter">
                        <mat-label class="title-radiobuttons">Show:</mat-label>
                        <mat-radio-group aria-label="Select if active" formControlName="isActive"
                            (change)="activeChange($event)">
                            <mat-radio-button [value]="true" [checked]="filterForm.controls.isActive.value"
                                [ngStyle]="{ 'font-weight': filterForm.controls.isActive.value == true ? '600' : '400' }"
                                class="margin-right40">Active
                            </mat-radio-button>
                            <mat-radio-button [value]="false" [checked]="!filterForm.controls.isActive.value"
                                [ngStyle]="{ 'font-weight': !filterForm.controls.isActive.value == true ? '600' : '400' }">
                                Inactive</mat-radio-button>
                        </mat-radio-group>
        
                    </div>
                </div>
                <div class="mixedapp-container radiobuttons">
                    <div class="radiobuttons">
                        <div class="container-radiobuttons margin-right20" *ngIf="isActiveFilter">
                            <mat-label class="title-radiobuttons">Show systemisation:</mat-label>
                            <mat-radio-group aria-label="Select if active" formControlName="isSystemisationComplete"
                                (change)="systemisationChange($event)">
                                <mat-radio-button value="all"
                                    [checked]="filterForm.controls.isSystemisationComplete.value === 'all'" [ngStyle]="{
                                                'font-weight':
                                                    filterForm.controls.isSystemisationComplete.value == 'all' ? '600' : '400'
                                            }">All
                                </mat-radio-button>
                                <mat-radio-button value="complete"
                                    [checked]="filterForm.controls.isSystemisationComplete.value === 'complete'" [ngStyle]="{
                                                'font-weight':
                                                    filterForm.controls.isSystemisationComplete.value == 'complete' ? '600' : '400'
                                            }">Complete
                                </mat-radio-button>
                                <mat-radio-button value="incomplete"
                                    [checked]="filterForm.controls.isSystemisationComplete.value === 'incomplete'" [ngStyle]="{
                                                'font-weight':
                                                    filterForm.controls.isSystemisationComplete.value == 'incomplete'
                                                        ? '600'
                                                        : '400'
                                            }">
                                    Incomplete</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector class="container300" [setInput]="setGoocInput" formControlName="goocs"
                            [searchFunc]="getGoocs" [isAsync]="true" [isTableAutoComplete]="true"
                            [displayedColumns]="goocAutocompleteDisplayedColumns" [filterForm]="filterForm"
                            [formSetter]="'goocs'" [propertyToShow]="'no'" [placeholder]="'GOOC Milestone'" [panelWidth]="480">
                        </app-multiple-items-selector>
                    </div>
        
                    <div class="container300-mh">
                        <app-multiple-items-selector class="container300" [setInput]="setRFOInput" formControlName="rfos"
                            [searchFunc]="getRFOs" [isAsync]="true" [filterForm]="filterForm" [formSetter]="'rfos'"
                            [propertyToShow]="'name'" [placeholder]="'RFO'" [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true" [displayedColumns]="['name', 'rfoName']">
                        </app-multiple-items-selector>
                    </div>
        
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
                export to excel
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
        <div class="results-container">
            <div class="results-panel-container">
                <div class="results-panel-header-container">
                    <div class="results-panel-header-title-row">
                        <div class="title-date"></div>
                        <div class="results-header-actions">
                                <button
                                    style="margin-left: 1050px; margin-bottom: 20px;"
                                    mat-button
                                    [matMenuTriggerFor]="columnsCustomizer"
                                    class="results-columns-customizer"
                                >
                                    <mat-icon  svgIcon="edit"></mat-icon>
                                    customize columns
                                </button>
                                <mat-menu #columnsCustomizer class="columns-customizer">
                                    <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                                    <div class="columns-customizer-list">
                                        <mat-checkbox 
                                            [checked]="showHideColumns.stepStatus" 
                                            (change)="reverseValue('stepStatus')"
                                            (click)="$event.stopPropagation()"
                                            >IPIMS Step
                                        </mat-checkbox>
                                        <mat-checkbox
                                            [checked]="showHideColumns.implementationStatus"
                                            (change)="reverseValue('implementationStatus')"
                                            (click)="$event.stopPropagation()"
                                            >Implementation Status
                                        </mat-checkbox>
                                        <mat-checkbox
                                            [checked]="showHideColumns.subsystem"
                                            (change)="reverseValue('subsystem')"
                                            (click)="$event.stopPropagation()"
                                            >Subsystem
                                        </mat-checkbox>
                                        <mat-checkbox
                                            [checked]="showHideColumns.rfsuPlan"
                                            (change)="reverseValue('rfsuPlan')"
                                            (click)="$event.stopPropagation()"
                                            >RFSU Plan</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.rfsuForecast"
                                            (change)="reverseValue('rfsuForecast')"
                                            (click)="$event.stopPropagation()"
                                            >RFSU Forecast</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.rfsuActual"
                                            (change)="reverseValue('rfsuActual')"
                                            (click)="$event.stopPropagation()"
                                            >RFSU Actual</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.rfo"
                                            (change)="reverseValue('rfo')"
                                            (click)="$event.stopPropagation()"
                                            >RFO</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.rfoPlan"
                                            (change)="reverseValue('rfoPlan')"
                                            (click)="$event.stopPropagation()"
                                            >RFO Plan</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.rfoForecast"
                                            (change)="reverseValue('rfoForecast')"
                                            (click)="$event.stopPropagation()"
                                            >RFO Forecast</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.rfoActual"
                                            (change)="reverseValue('rfoActual')"
                                            (click)="$event.stopPropagation()"
                                            >RFO Actual</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.mcPlan"
                                            (change)="reverseValue('mcPlan')"
                                            (click)="$event.stopPropagation()"
                                            >MC Plan</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.mcForecast"
                                            (change)="reverseValue('mcForecast')"
                                            (click)="$event.stopPropagation()"
                                            >MC Forecast</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.mcActual"
                                            (change)="reverseValue('mcActual')"
                                            (click)="$event.stopPropagation()"
                                            >MC Actual</mat-checkbox
                                        >
                                        <mat-checkbox
                                            [checked]="showHideColumns.fieldImplementationContractor"
                                            (change)="reverseValue('fieldImplementationContractor')"
                                            (click)="$event.stopPropagation()"
                                            >Field Implementation Contractor</mat-checkbox
                                        >
                                    </div>
                                </mat-menu>
                                <mat-slide-toggle
                                    [color]="'primary'"
                                    [checked]="singleViewEnabled"
                                    (toggleChange)="toggleViews()"
                                    class="results-header-toggle"
                                >
                                    <a>single view (unique document)</a>
                                </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-wrapper">
                <div class="scroll">
                    <div class="table-container">
                        <app-mat-table-sticky-header [scrollKey]="'changevalidation'">
                            <table
                            mat-table
                            matSort
                            [dataSource]="data"
                            [matSortActive]="sortBy"
                            matSortDisableClear
                            [matSortDirection]="direction"
                        >
                            <ng-container matColumnDef="number" sticky>
                                <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                                    <div class="sort-container">
                                        <span
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'number',
                                                    getNumbers,
                                                    'columnNumber',
                                                    'Number',
                                                    columnNumber
                                                )
                                            "
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnNumber)
                                            }"
                                            >Change Number</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'number'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a (click)="openValidationView(element.validationId, element.id)">{{
                                        element.number
                                    }}</a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="revision">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'revision',
                                            getRevisions,
                                            'columnRevision',
                                            'Revision',
                                            columnRevision
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRevision)
                                            }"
                                            >Rev</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'revision'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.revision }}</td>
                            </ng-container>
                            <ng-container matColumnDef="changeType">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'changeType',
                                            getChangeTypes,
                                            'columnType',
                                            'Change Type',
                                            columnType,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnType)
                                            }"
                                            >Type</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'changeType'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.changeType }}</td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'status',
                                            getStatuses,
                                            'columnChangeStatus',
                                            'Status',
                                            columnChangeStatus,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnChangeStatus)
                                            }"
                                            >Design Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'status'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                            </ng-container>
                            <ng-container matColumnDef="stepStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.stepStatus
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'currentStep',
                                            getStepStatuses,
                                            'columnStepStatus',
                                            'IPIMS Step',
                                            columnStepStatus,
                                            true,
                                            true,
                                            false,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnStepStatus)
                                            }"
                                            >IPIMS Step</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'currentStep'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.stepStatus }"
                                >
                                    {{ element.changeType !='RFI (iPIMS)' ? "N/A" : element.currentStep  == 0 ? "" : element.currentStep }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="implementationStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.implementationStatus,
                                        'header-filtered': columnimplementationStatus && columnimplementationStatus.items.length
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'implementationStatus',
                                            getImplementationStatuses,
                                            'columnimplementationStatus',
                                            'Implementation Status',
                                            columnimplementationStatus,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnimplementationStatus)
                                            }"
                                            >Implementation Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'implementationStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element;" [ngClass]="{
                                                                hidecells: !showHideColumns.implementationStatus
                                                            }">
                                {{ element.implementationStatus }}</td>
                            </ng-container>
                            <ng-container matColumnDef="issueDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('issueDate', 'columnIssueDate', 'Issue Date', columnIssueDate)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnIssueDate)
                                            }"
                                            >Issue Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'issueDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.issueDate | date: 'd/MMM/y' }}</td>
                            </ng-container>
                            <ng-container matColumnDef="createdDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('createdDate', 'columnCreatedDate', 'Created Date', columnCreatedDate)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnCreatedDate)
                                            }"
                                            >Created Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'createdDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.createdDate | date: 'd/MMM/y' }}</td>
                            </ng-container>
                            <ng-container matColumnDef="validationStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'validationStatus',
                                            getValidationStatuses,
                                            'columnValidationStatus',
                                            'Validation Status',
                                            columnValidationStatus,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnValidationStatus)
                                            }"
                                            >Validation Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'validationStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div
                                        class="validation-status"
                                        [ngClass]="getValidationClassName(element.validationStatus)"
                                    >
                                        {{ element.validationStatus }}
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="elevalidationStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'elevalidationStatus',
                                            getELEValidationStatuses,
                                            'columnELEValidationStatus',
                                            'Validation Status',
                                            columnELEValidationStatus,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnELEValidationStatus)
                                            }"
                                            >Discipline Validation Electrical</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'elevalidationStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.validationELEStatus === 'not active'" class="validation-status">
                                        <mat-icon svgIcon="inactive"></mat-icon>
                                    </div>
                                    <div *ngIf="element.validationELEStatus === 'not started'" class="validation-status">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="-3 0 22 22" width="35" opacity="0.3">
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
                                        </svg>
                                    </div>
                                    <div *ngIf="element.validationELEStatus === 'in progress'" class="validation-status">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="-3 0 22 22" width="35">
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
                                        </svg>
                                    </div>
                                    <div *ngIf="element.validationELEStatus === 'completed'" class="validation-status">
                                        <mat-icon svgIcon="completed"></mat-icon>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ictvalidationStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'ictvalidationStatus',
                                            getICTValidationStatuses,
                                            'columnICTValidationStatus',
                                            'ICT Validation Status',
                                            columnICTValidationStatus,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnICTValidationStatus)
                                            }"
                                            >Discipline Validation ICT</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'ictvalidationStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.validationICTStatus === 'not active'" class="validation-status">
                                        <mat-icon svgIcon="inactive"></mat-icon>
                                    </div>
                                    <div *ngIf="element.validationICTStatus === 'not started'" class="validation-status">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="-3 0 22 22" width="35" opacity="0.3">
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
                                        </svg>
                                    </div>
                                    <div *ngIf="element.validationICTStatus === 'in progress'" class="validation-status">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="-3 0 22 22" width="35">
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
                                        </svg>
                                    </div>
                                    <div *ngIf="element.validationICTStatus === 'completed'" class="validation-status">
                                        <mat-icon svgIcon="completed"></mat-icon>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="smpvalidationStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'smpvalidationStatus',
                                            getSMPValidationStatuses,
                                            'columnSMPValidationStatus',
                                            'SMP Validation Status',
                                            columnSMPValidationStatus,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnSMPValidationStatus)
                                            }"
                                            >Discipline Validation SMP</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'smpvalidationStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.validationSMPStatus === 'not active'" class="validation-status">
                                        <mat-icon svgIcon="inactive"></mat-icon>
                                    </div>
                                    <div *ngIf="element.validationSMPStatus === 'not started'" class="validation-status">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="-3 0 22 22" width="35" opacity="0.3">
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
                                        </svg>
                                    </div>
                                    <div *ngIf="element.validationSMPStatus === 'in progress'" class="validation-status">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="-3 0 22 22" width="35">
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
                                        </svg>
                                    </div>
                                    <div *ngIf="element.validationSMPStatus === 'test pack requested'" class="validation-status">
                                        <mat-icon svgIcon="testpackreview"></mat-icon>
                                    </div>
                                    <div *ngIf="element.validationSMPStatus === 'completed'" class="validation-status">
                                        <mat-icon svgIcon="completed"></mat-icon>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="title">
                                <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                                    <div class="sort-container">
                                        <span
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'title',
                                                    getTitles,
                                                    'columnTitle',
                                                    'Title',
                                                    columnTitle
                                                )
                                            "
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnTitle)
                                            }"
                                            >Document Title</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'title'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.title }}</td>
                            </ng-container>
                            <ng-container matColumnDef="discipline">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'discipline',
                                            getDisciplines,
                                            'columnDiscipline',
                                            'Discipline',
                                            columnDiscipline,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnDiscipline)
                                            }"
                                            >Discipline</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.discipline }}</td>
                            </ng-container>
                            <ng-container matColumnDef="projectTeamName">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'projectTeamName',
                                            getProjectTeams,
                                            'columnProjectTeamName',
                                            'Project Team',
                                            columnProjectTeamName,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnProjectTeamName)
                                            }"
                                            >Project Team</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'projectTeamName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.projectTeamName }}</td>
                            </ng-container>
                            <ng-container matColumnDef="contractor">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'contractor',
                                            getContractors,
                                            'columnContractor',
                                            'Contractor',
                                            columnContractor,
                                            false,
                                            true,
                                            false,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnContractor)
                                            }"
                                            >Contractor</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'contractor'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.contractor }}</td>
                            </ng-container>
                            <ng-container matColumnDef="fieldImplementationContractor">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.fieldImplementationContractor,
                                        'header-filtered': showUnderlineForCheckListColumn(columnFieldImplementationContractor)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'fieldImplementationContractor',
                                            getFieldImplementationContractors,
                                            'columnFieldImplementationContractor',
                                            'Field Implementation Contractor',
                                            columnFieldImplementationContractor,
                                            false,
                                            true,
                                            false,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                hidecells: !showHideColumns.fieldImplementationContractor,
                                                'header-underline': showUnderlineForCheckListColumn(columnFieldImplementationContractor)
                                            }"
                                            >Field Implementation Contractor</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'fieldImplementationContractor'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.fieldImplementationContractor}">{{ element.fieldImplementationContractor }}</td>
                            </ng-container>
                            <ng-container matColumnDef="dataUpdated">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'dataUpdated',
                                            getDataUpdated,
                                            'columnDataUpdated',
                                            'Data Updated',
                                            columnDataUpdated,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnDataUpdated)
                                            }"
                                            >Data Updated</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'dataUpdated'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.dataUpdated ? 'Yes' : 'No' }}</td>
                            </ng-container>
                            <ng-container matColumnDef="validator">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'validator',
                                            getValidatorsColumn,
                                            'columnValidator',
                                            'Validator',
                                            columnValidator,
                                            false,
                                            true,
                                            false,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnValidator)
                                            }"
                                            >Validator</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'validator'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.validator }}<br>
                                    {{ element.ictValidator }}<br>
                                    {{ element.smpValidator }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="subsystem">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.subsystem,
                                        'header-filtered': columnSubsystem && columnSubsystem.items.length
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subsystem',
                                            getSubsystemsColumn,
                                            'columnSubsystem',
                                            'subsystem',
                                            columnSubsystem,
                                            false,
                                            true,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnSubsystem)
                                            }"
                                            >SubSystem</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element;" [ngClass]="{
                                                                hidecells: !showHideColumns.subsystem
                                                            }">
                                {{ element.subsystem }}</td>
                            </ng-container>
                            <ng-container matColumnDef="rfo">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfo,
                                        'header-filtered': columnRfo && columnRfo.items.length
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'rfo',
                                            getRfosColumn,
                                            'columnRfo',
                                            'rfo',
                                            columnRfo
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRfo)
                                            }"
                                            >RFO</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfo'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element;" [ngClass]="{
                                                                hidecells: !showHideColumns.rfo
                                                            }">
                                {{ element.rfo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuPlan">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuPlan,
                                        'header-filtered': showUnderlineForCalendarColumn(columnrfsuPlan)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('rfsuPlan', 'columnrfsuPlan', 'RFSU Plan', columnrfsuPlan)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>RFSU Plan</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfsuPlan}"
                                >
                                    {{ element.rfsuPlan | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuForecast,
                                        'header-filtered': showUnderlineForCalendarColumn(columnrfsuForecast)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('rfsuForecast', 'columnrfsuForecast', 'RFSU Forecast', columnrfsuForecast)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>RFSU Forecast</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfsuForecast }"
                                >
                                    {{ element.rfsuForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuActual,
                                        'header-filtered': showUnderlineForCalendarColumn(columnrfsuActual)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('rfsuActual', 'columnrfsuActual', 'RFSU Actual', columnrfsuActual)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>RFSU Actual</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfsuActual }"
                                >
                                    {{ element.rfsuActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mcPlan">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.mcPlan,
                                        'header-filtered': showUnderlineForCalendarColumn(columnmcPlan)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('mcPlan', 'columnmcPlan', 'MC Plan', columnmcPlan)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>MC Plan</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'mcPlan'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.mcPlan }"
                                >
                                    {{ element.mcPlan | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mcForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.mcForecast,
                                        'header-filtered': showUnderlineForCalendarColumn(columnmcForecast)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('mcForecast', 'columnmcForecast', 'MC Forecast', columnmcForecast)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>MC Forecast</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'mcForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.mcForecast }"
                                >
                                    {{ element.mcForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mcActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.mcActual,
                                        'header-filtered': showUnderlineForCalendarColumn(columnmcActual)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('mcActual', 'columnmcActual', 'MC Actual', columnmcActual)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>MC Actual</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'mcActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.mcActual }"
                                >
                                    {{ element.mcActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfoPlan">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfoPlan,
                                        'header-filtered': showUnderlineForCalendarColumn(columnrfoPlan)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('rfoPlan', 'columnrfoPlan', 'RFO Plan', columnrfoPlan)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>RFO Plan</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfoPlan'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfoPlan }"
                                >
                                    {{ element.rfoPlan | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfoForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfoForecast,
                                        'header-filtered': showUnderlineForCalendarColumn(columnrfoForecast)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('rfoForecast', 'columnrfoForecast', 'RFO Forecast', columnrfoForecast)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>RFO Forecast</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfoForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfoForecast }"
                                >
                                    {{ element.rfoForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfoActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfoActual,
                                        'header-filtered': showUnderlineForCalendarColumn(columnrfoActual)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('rfoActual', 'columnrfoActual', 'RFO Actual', columnrfoActual)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>RFO Actual</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfoActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfoActual }"
                                >
                                    {{ element.rfoActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                        </app-mat-table-sticky-header>                    
                    </div>
                </div>
            </div>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 50]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    min="1"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>
