import { Component, OnDestroy } from '@angular/core';
import { UserDetail, CheckListColumn, CalendarColumn, AppRole, NumericColumn } from '../store/common.model';
import { ImportStatuses } from '../models/import-statuses';
import { ValidStatuses } from '../models/validation-statuses';
import { Account } from 'msal';
import { UntypedFormGroup } from '@angular/forms';

@Component({ template: '' })
export class BaseComponent implements OnDestroy {
    isAlive = true;
    readonly isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);
    ngOnDestroy() {
        this.isAlive = false;
    }

    isNotNullAndNotUndefined(item: any): boolean {
        return item !== null && item !== undefined;
    }

    areUsersEqual(user: Account, decisionUser: UserDetail) {
        return (
            this.isNotNullAndNotUndefined(user) &&
            this.isNotNullAndNotUndefined(decisionUser) &&
            user.userName.toLowerCase() === decisionUser.email.toLowerCase()
        );
    }

    isItemExistsInArray(item: any): boolean {
        return this.isNotNullAndNotUndefined(item) && item.length > 0;
    }

    getImportClassName(status: string) {
        switch (status) {
            case ImportStatuses.Started:
            case ImportStatuses.PreprocessingStarted:
                return 'started-status';
            case ImportStatuses.Finished:
            case ImportStatuses.PreprocessingFinished:
                return 'finished-status';
            case ImportStatuses.Terminated:
            case ImportStatuses.Error:
            case ImportStatuses.PreprocessingError:
            case ImportStatuses.ProcessingError:
                return 'error-status';
            case ImportStatuses.Info:
                return 'info-status';
            case ImportStatuses.Warning:
                return 'warning-status';
            case ImportStatuses.Validating:
            case ImportStatuses.Uploading:
                return 'started-status';
            case ImportStatuses.Validated:
                return 'finished-status';
        }
    }

    isImportInProgress(status: string) {
        return status === 'Started' || status === 'Validating';
    }

    getValidationClassName(status: string) {
        switch (status) {
            case ValidStatuses.NotStarted:
                return 'not-started-status';
            case ValidStatuses.InProgress:
                return 'in-progress-status';
            case ValidStatuses.Completed:
                return 'completed-status';
            case ValidStatuses.TestPackReview:
                return 'testPackReview-status';
            case 'changes reviewed':
                return 'changes-reviewed-status';
            case 'active':
                return 'active-status';
            case 'inactive':
                return 'inactive-status';
            case 'not applicable':
                return 'not-applicable-status';
        }
    }

    showUnderlineForCalendarColumn(calendarColumn: CalendarColumn): boolean {
        return (
            calendarColumn !== null &&
            calendarColumn !== undefined &&
            calendarColumn.rangeDates &&
            (calendarColumn.rangeDates.length > 0 ||
                calendarColumn.excludeBlanks !== false ||
                calendarColumn.onlyBlanks !== false)
        );
    }

    showUnderlineForCheckListColumn(column: CheckListColumn): boolean {
        return (
            column !== null &&
            column !== undefined &&
            column.items &&
            (column.items.length > 0 || column.excludeBlanks !== false || column.onlyBlanks !== false)
        );
    }

    showUnderlineForNumericColumn(column: NumericColumn): boolean {
        return (
            column !== null &&
            column !== undefined &&
            (column.minValue !== null ||
                column.maxValue !== null ||
                column.excludeBlanks !== false ||
                column.onlyBlanks !== false)
        );
    }

    setHeaderFilterPerPageFilter(filterForm: UntypedFormGroup, key: string, value: any) {
        const filterControl = filterForm.controls['column' + key.charAt(0).toUpperCase() + key.slice(1)];
        const filterControlPluralv1 = filterForm.controls['column' + key.charAt(0).toUpperCase() + key.slice(1, -1)];
        const filterControlPluralv2 = filterForm.controls['column' + key.charAt(0).toUpperCase() + key.slice(1, -2)];
        const control = filterControl || filterControlPluralv1 || filterControlPluralv2;
        if (key.toLowerCase() !== 'subsystems' && key.toLowerCase() !== 'subsystem' && control && control.value) {
            const items = control.value.items.filter((c) => value.indexOf(c) !== -1) || [];
            control.patchValue({ items });
        }
    }

    compareRoles(r1: AppRole, r2: AppRole): boolean {
        return AppRole[r1] === AppRole[r2];
    }

    arraysEqual(_arr1, _arr2) {
        if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;

        let arr1 = _arr1.concat().sort();
        let arr2 = _arr2.concat().sort();

        for (var i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }

    findRole(types: { key: string; value: number }[], appRole: AppRole) {
        if (!appRole) {
            return '';
        }
        return types.find((s) => s.value === appRole).key;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    removeDuplicates(myArray, Prop) {
        return myArray.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
        });
      }
}
