import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SubsystemScope } from 'src/app/enums';
import { DetailedStatusSetMakeRequest } from 'src/app/store/detailed-status/actions';
import { ApplicationState } from 'src/app/store/model';
import { SubsystemMCDasbboard1RedirectToDetailedStatus } from 'src/app/store/reports/subsystem-mc/actions';
import { SubsystemMCDashboardDTO } from 'src/app/store/reports/subsystem-mc/model';

@Component({
    selector: 'app-mc-sub-system',
    templateUrl: './sub-system.component.html',
    styleUrls: ['./sub-system.component.scss'],
})
export class MCSubSystemTableComponent {
    panelOpenState = false;
    displayedColumns = [
        'subsystemNo',
        'subsystemName',
        'mcPlanDate',
        'mcForecast',
        'mcActualDate',
        'aqvd',
        'redlines',
        'pli',
    ];
    data: SubsystemMCDashboardDTO[] = [];

    data$ = this.store.select((state) => state.milestoneDashboardState.subsystemTable);
    isLoading$ = this.store.select((state) => state.milestoneDashboardState.isSubsystemTableLoading);

    constructor(private store: Store<ApplicationState>) {}

    openDetailedStatusPage(subsystem: string, scope: string, category: string = undefined, zone: string = undefined ) {
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        this.store.dispatch(
            new SubsystemMCDasbboard1RedirectToDetailedStatus({
                subsystem,
                scope,
                exceptions: SubsystemScope[scope] === SubsystemScope.EX,
                category,
                zone,
            })
        );
    }
}
