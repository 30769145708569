<form class="filter-assignment-container">
    <div class="header-row">
        <p>{{ config.isAssignment ? 'Assign filter' : 'Remove filter' }}</p>
        <button mat-icon-button type="button" (click)="onCancel()" aria-label="Close dialog" class="close-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="section-row">
        <mat-form-field *ngIf="!isLoading" class="exclude-form-control">
            <mat-chip-list #chipList>
                <mat-chip
                    *ngFor="let excludeValue of selectedExcludeValue"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="remove(excludeValue)"
                >
                    {{ excludeValue }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                    placeholder="Exclude Filters"
                    #excludeInput
                    [formControl]="excludeControl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)"
                />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let val of filteredExcludeValues$ | async" [value]="val">
                    {{ val }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="buttons-container section-row">
        <div class="dialog-actions-row section-row">
            <button mat-stroked-button type="button" (click)="onCancel()">
                cancel
            </button>
            <button mat-flat-button color="primary" type="submit" (click)="onSubmit()" [disabled]="isSaveDisabled()">
                save
            </button>
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</form>
