import { Component, EventEmitter, ViewChild, OnInit, ElementRef, HostListener } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BaseComponent } from '../../base.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { map, takeWhile, take, catchError, startWith, mergeMap } from 'rxjs/operators';
import {
    Milestone,
    McMilestone,
    Gooc,
    RFO,
    TCOUser,
    Contractor,
    StagedStartUpPriority,
} from 'src/app/store/common.model';
import { FormService } from 'src/app/services/shared/form.service';
import {
    MilestoneCommentDTO,
    MilestoneCommentType,
    MilestoneDashboardFilter,
    MilestoneDashboardType,
} from 'src/app/store/reports/milestone-dashboard/model';
import {
    MilestoneDashboardFilterReset,
    MilestoneDashboardTotalCompleteRequest,
    MilestoneDashboardRundownRequest,
    MCMilestoneDashboardIcicleRequest,
    RFOMilestoneDashboardIcicleRequest,
    MilestoneDashboardWeekRangeSet,
    MilestoneDashboardCommentsRequest,
    MilestoneDashboardAddCommentRequest,
    MilestoneDashboardRemoveCommentRequest,
    MCMilestoneDashboardSkylineRequest,
    ScheduleRequest,
    MCMilestoneDashboardLookaheadRequest,
    MCMilestoneDashboardSubsystemTableRequest,
    MCMilestoneDashboardResetCharts,
    RFSUMilestoneDashboardSkylineRequest,
    RFSUMilestoneDashboardLookaheadRequest,
    MilestoneDashboardFilterUpdate,
} from 'src/app/store/reports/milestone-dashboard/actions';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { defer, forkJoin, interval, Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { Constants } from 'src/app/constants';
import { PopupService } from 'src/app/services/shared/popup.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { CommentsHistoryComponent } from '../../comments-history/comments-history.component';
import { appConfig } from 'src/app/app.config';
import { InformationDialogComponent } from 'src/app/modules/shared/components/information-dialog/information-dialog.component';
import { JoditDescriptionPreserveBackButton, JoditDescriptionSetType } from 'src/app/store/jodit-description/actions';
import { MsalService } from '@azure/msal-angular';
import { ResizeEvent } from 'angular-resizable-element';

declare const Jodit: any;
@Component({
    selector: 'app-milestone-dashboard',
    templateUrl: './milestone-dashboard.component.html',
    styleUrls: ['./milestone-dashboard.component.scss'],
})
export class MilestoneDashboardComponent extends BaseComponent implements OnInit {
    navLinks: { label: string; link: string; index: number }[];

    filterForm: UntypedFormGroup;
    setMcMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    areaCompletionLeads: TCOUser[] = [];
    subsystemAutocompleteDisplayedColumns = ['id', 'name'];
    mcMilestoneAutocompleteDisplayedColumns = ['name'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    stagedStartUpPrioritiesAutocompleteDisplayedColumns = ['priority', 'priorityName'];
    projectTeamNames: string[] = [];
    projectTeamNamesCurrent: string[];
    isIcicleLoading = false;
    isCommentLoading = false;
    isUsersPerProjectLoading = false;
    dashboardTypes = MilestoneDashboardType;
    dashboardType: MilestoneDashboardType = null;
    dashboardTypeName: string;
    commentTypes = MilestoneCommentType;

    mcMilestoneDashboardFilter$ = this.store.select((state) => state.milestoneDashboardState.filters.mc);
    rfsuMilestoneDashboardFilter$ = this.store.select((state) => state.milestoneDashboardState.filters.rfo);
    mcFilter = new MilestoneDashboardFilter();
    rfsuFilter = new MilestoneDashboardFilter();
    icicleData$ = this.store.select((state) => state.milestoneDashboardState.icicle);
    icicleType$ = this.store.select((state) => state.milestoneDashboardState.icicleType);
    isIcicleLoading$ = this.store.select((state) => state.milestoneDashboardState.isIcicleLoading);
    dashboardType$ = this.store.select((state) => state.milestoneDashboardState.dashboardType);
    monthViewEnabled = false;
    monthViewEnabled$ = this.store.select((store) => store.milestoneDashboardState.monthViewEnabled);
    isCommentLoading$ = this.store.select((state) => state.milestoneDashboardState.isCommentLoading);
    isCommentsVisible$ = this.store.select(
        (state) =>
            (state.milestoneDashboardState.comments && state.milestoneDashboardState.comments.length > 0) ||
            state.milestoneDashboardState.isCommentLoading
    );
    isCommentsVisible = false;
    isSkylineVisible$ = this.store.select(
        (state) =>
            (state.milestoneDashboardState.mcMilestoneSkyline.weeks &&
                state.milestoneDashboardState.mcMilestoneSkyline.weeks.length > 0) ||
            state.milestoneDashboardState.mcMilestoneSkyline.isLoading
    );
    isRFSUSkylineVisible$ = this.store.select(
        (state) =>
            (state.milestoneDashboardState.rfsuMilestoneSkyline.weeks &&
                state.milestoneDashboardState.rfsuMilestoneSkyline.weeks.length > 0) ||
            state.milestoneDashboardState.rfsuMilestoneSkyline.isLoading
    );

    comments$ = this.store.select((store) => store.milestoneDashboardState.comments);
    comments: { type: string; progress: string; issues: string; forecast: string }[] = [];
    allComments: MilestoneCommentDTO[] = [];
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    startDate$ = this.store.select((state) => state.milestoneDashboardState.startDate);
    startDate: string;
    isLongerLabel = false;
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    contractors: Contractor[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];

    @ViewChild('scrollTargetDescription') commentsRef: ElementRef;
    jodit: any;
    tokenRefreshSubs: Subscription;
    tokenRefreshInterval = Constants.TokenRefreshInterval;
    tokenRefreshInterval$ = interval(this.tokenRefreshInterval);
    joditUserType: string = '';
    joditDescriptionType: MilestoneCommentType = null;
    joditDescriptionType$ = this.store.select((state) => state.joditDescriptionState.type);
    joditUserType$ = this.store.select((state) => state.joditDescriptionState.userType);

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>,
        private projectTeamsService: ProjectTeamsService,
        private toastService: ToastService,
        private popupSvc: PopupService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private authService: MsalService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new MilestoneDashboardFilter());
        this.navLinks = [
            // {
            //     label: 'mc milestone dashboard',
            //     link: './mc',
            //     index: 0,
            // },
            {
                label: 'rfsu milestone dashboard',
                link: './rfo',
                index: 1,
            },
        ];
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
    }

    ngOnInit() {
        this.startDate$.pipe(takeWhile(() => this.isAlive)).subscribe((startDate) => {
            this.startDate = startDate;
        });
        this.projectTeamsService
            .getTeams()
            .pipe(take(1))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });
        this.filterExpansionPanel.expanded = true;
        this.rfsuMilestoneDashboardFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            if (this.dashboardType === this.dashboardTypes.RFO) {
                this.filterForm.patchValue(filter, { emitEvent: false });
                this.getUsersPerProjectTeam();
                this.rfsuFilter = filter;
            }
        });
        this.mcMilestoneDashboardFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            if (this.dashboardType === this.dashboardTypes.MC) {
                this.filterForm.setValue(filter, { emitEvent: false });
                this.getUsersPerProjectTeam();
                this.mcFilter = filter;
            }
        });
        this.isIcicleLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) =>
            setTimeout(() => {
                this.isIcicleLoading = isLoading;
            })
        );
        this.isCommentsVisible$.pipe(takeWhile(() => this.isAlive)).subscribe((isCommentsVisible) =>
            setTimeout(() => {
                this.isCommentsVisible = isCommentsVisible;
            })
        );
        this.isCommentLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) =>
            setTimeout(() => {
                this.isCommentLoading = isLoading;
            })
        );
        this.dashboardType$.pipe(takeWhile(() => this.isAlive)).subscribe((type) =>
            setTimeout(() => {
                this.dashboardType = type;
                if (this.dashboardType === this.dashboardTypes.MC) {
                    this.dashboardTypeName = 'MC';
                    this.filterForm.setValue(this.mcFilter, { emitEvent: false });
                } else {
                    this.dashboardTypeName = 'RFSU';
                    this.filterForm.setValue(this.rfsuFilter, { emitEvent: false });
                }
            })
        );
        this.monthViewEnabled$.pipe(takeWhile(() => this.isAlive)).subscribe((monthViewEnabled) => {
            this.monthViewEnabled = monthViewEnabled;
        });
        this.joditDescriptionType$.pipe(takeWhile(() => this.isAlive)).subscribe((type) => {
            this.joditDescriptionType = type;
        });
        this.joditUserType$.pipe(takeWhile(() => this.isAlive)).subscribe((type) => {
            this.joditUserType = type;
        });

        this.comments$.pipe(takeWhile(() => this.isAlive)).subscribe((comments) => {
            let result = comments.map((c) => {
                let issues = _.head(_.orderBy(c.issues, ['date'], ['desc']));
                let issuesComment = issues ? issues.description : '';
                let forecast = _.head(_.orderBy(c.forecast, ['date'], ['desc']));
                let forecastComment = forecast ? forecast.description : '';
                let progress = _.head(_.orderBy(c.progress, ['date'], ['desc']));
                let progressComment = progress ? progress.description : '';
                return { type: c.type, issues: issuesComment, progress: progressComment, forecast: forecastComment };
            });
            setTimeout(() => {
                this.allComments = comments;
                this.comments = result;
            });
        });

        this.setupJodit();

        this.lookupService
            .getACLeads([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads));
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    private setupJodit() {
        this.jodit = new Jodit('#editor', {
            language: 'en',
            uploader: {
                url: `${appConfig.apiEndpoint}/image`,
                headers: {
                    Authorization: '',
                },
                prepareData: (formData: FormData) => {
                    let no =
                        this.dashboardType === MilestoneDashboardType.MC
                            ? this.filterForm.controls.goocs.value[0].no
                            : this.filterForm.controls.rfos.value[0].name;
                    formData.append(
                        'no',
                        `${this.dashboardTypes[this.dashboardType]}-${this.joditUserType}-${
                            this.commentTypes[this.joditDescriptionType]
                        }-${no}`
                    );
                },
                error: () => {
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            InformationDialogComponent,
                            null,
                            null,
                            {
                                title: 'Not allowed image format',
                                text: 'Images are allowed only in jpg, jpeg, bmp and png format.',
                            },
                            1,
                            1
                        )
                    );
                },
            },
            buttons: `|,bold,strikethrough,underline,italic,|,
                superscript,subscript,|,ul,ol,|,outdent,indent,|,
                font,fontsize,brush,paragraph,|,image,table,link,|,
                align,undo,redo,\n,cut,hr,eraser,copyformat,|,symbol`,
            extraButtons: [
                {
                    name: 'FullWindow',
                    iconURL: 'assets/images/icons/close.svg',
                    exec: (editor) => {
                        this.saveIfChanged();
                        editor.toggleFullSize();
                        document.getElementsByClassName('editor-jodit-container')[0].className += ' hidden-jodit';
                        this.scrollToElement();
                        this.store.dispatch(new JoditDescriptionSetType({ type: null, userType: null }));
                        this.tokenRefreshSubs.unsubscribe();
                    },
                },
            ],
            events: {
                change: (newValue) => {
                    if (this.joditDescriptionType !== null) {
                        if (newValue.indexOf(';base64,') > -1 || newValue.indexOf('src="file:') > -1) {
                            if (this.joditDescriptionType === MilestoneCommentType.Forecast) {
                                this.jodit.value =
                                    this.comments.find((s) => s.type === this.joditUserType).forecast || '';
                            } else if (this.joditDescriptionType === MilestoneCommentType.Issues) {
                                this.jodit.value =
                                    this.comments.find((s) => s.type === this.joditUserType).issues || '';
                            } else if (this.joditDescriptionType === MilestoneCommentType.Progress) {
                                this.jodit.value =
                                    this.comments.find((s) => s.type === this.joditUserType).progress || '';
                            }
                            this.popupSvc.openPopup(
                                new PopupSettings(
                                    InformationDialogComponent,
                                    null,
                                    null,
                                    {
                                        title: 'Paste not allowed for the images',
                                        text: 'Please use Insert Image option from toolbar menu to insert an image.',
                                    },
                                    1,
                                    1
                                )
                            );
                        }
                    }
                },
            },
        });
    }

    private scrollToElement() {
        this.commentsRef.nativeElement.scrollIntoView({ behavior: 'auto', block: 'start' });
    }

    openJoditPopup(type: MilestoneCommentType, description: string, userType: string) {
        document.getElementsByClassName('editor-jodit-container')[0].className = document
            .getElementsByClassName('editor-jodit-container')[0]
            .className.replace('hidden-jodit', '');
        this.store.dispatch(new JoditDescriptionSetType({ type, userType }));
        this.jodit.value = description || '';
        this.jodit.toggleFullSize(true);

        this.tokenRefreshSubs = this.tokenRefreshInterval$
            .pipe(
                takeWhile(() => this.isAlive),
                startWith(0),
                mergeMap(() =>
                    this.authService.acquireTokenSilent({ scopes: ['user.read'] }).pipe(
                        map((result) => {
                            this.jodit.uploader.options.headers = { Authorization: `Bearer ${result.accessToken}` };
                        })
                    )
                )
            )
            .subscribe();
    }

    @HostListener('window:popstate')
    onPopState() {
        if (this.joditDescriptionType !== null) {
            this.saveIfChanged();
            this.jodit.toggleFullSize();
            document.getElementsByClassName('editor-jodit-container')[0].className += ' hidden-jodit';
            this.tokenRefreshSubs.unsubscribe();
            this.scrollToElement();
            this.store.dispatch(new JoditDescriptionSetType({ type: null, userType: null }));
            this.store.dispatch(new JoditDescriptionPreserveBackButton(true));
        }
    }

    saveIfChanged() {
        var value = '';
        switch (this.joditDescriptionType) {
            case MilestoneCommentType.Forecast:
                value = this.comments.find((s) => s.type == this.joditUserType).forecast;
                break;
            case MilestoneCommentType.Progress:
                value = this.comments.find((s) => s.type == this.joditUserType).progress;
                break;
            case MilestoneCommentType.Issues:
                value = this.comments.find((s) => s.type == this.joditUserType).issues;
                break;
            default:
                break;
        }
        if (this.jodit.value !== value) {
            let no =
                this.dashboardType === MilestoneDashboardType.MC
                    ? this.filterForm.controls.goocs.value[0].no
                    : this.filterForm.controls.rfos.value[0].name;
            this.store.dispatch(
                new MilestoneDashboardAddCommentRequest({
                    entityId: `${this.dashboardTypes[this.dashboardType]}-${this.joditUserType}-${
                        this.commentTypes[this.joditDescriptionType]
                    }-${no}`,
                    description: this.jodit.value,
                    mentions: [],
                    type: this.joditDescriptionType,
                    userType: this.joditUserType,
                })
            );
        }
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }
    onStagedStartUpPrioritiesClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((prio: StagedStartUpPriority[]) => prio));
    };

    private checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName].value.length &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm.controls[formControlName].setValue(valid);
        }
    }

    getMcMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMcMilestones(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((mcMilestones: McMilestone[]) => mcMilestones));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((RFOs: RFO[]) => RFOs));
    };

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.filterForm.value.projectTeamNames);
    };

    displayMultipleSelected(values: Milestone[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    displaySubsystemsSelected(values: { id: string }[]) {
        return values.map((x) => x.id).join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.setMcMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.mcMilestones.setValue([]);
        this.filterForm.controls.mcEngineer.setValue([]);
        this.filterForm.controls.scManager.setValue([]);
        this.filterForm.controls.systemOwner.setValue([]);
        this.filterForm.controls.stagedStartUpPriorities.setValue([]);
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
        this.store.dispatch(
            new MilestoneDashboardFilterReset({ type: this.dashboardType === MilestoneDashboardType.MC ? 'mc' : 'rfo' })
        );
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.isUsersPerProjectLoading = true;
            this.getUsersPerProjectTeam();
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    getUsersPerProjectTeam() {
        this.setMcMilestoneInput.emit(new SetInputEventArgs(true));
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'mcMilestones');
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'contractors');
        this.getSystemOwnersAndEnginners();
    }

    private getSystemOwnersAndEnginners() {
        this.isUsersPerProjectLoading = true;
        forkJoin([
            this.lookupService.getMCEngineers(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getSysOwners(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getSCManagers(this.filterForm.controls.projectTeamNames.value || []),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners, scManagers]) => {
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    private setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    saveFilter() {
        this.store.dispatch(
            new MilestoneDashboardFilterUpdate({ filter: this.filterForm.value, type: this.dashboardType })
        );
    }

    search() {
        this.store.dispatch(
            new MilestoneDashboardFilterUpdate({ filter: this.filterForm.value, type: this.dashboardType })
        );
        this.store.dispatch(new MilestoneDashboardTotalCompleteRequest());
        if (this.dashboardType === this.dashboardTypes.MC) {
            if (this.filterForm.controls.goocs.value.length > 0) {
                this.store.dispatch(new MCMilestoneDashboardIcicleRequest());
                this.store.dispatch(new MCMilestoneDashboardSkylineRequest(this.filterForm.controls.goocs.value[0]));
                this.store.dispatch(new ScheduleRequest(this.filterForm.controls.goocs.value[0]));
                this.store.dispatch(new MCMilestoneDashboardLookaheadRequest(this.filterForm.controls.goocs.value[0]));
                this.store.dispatch(
                    new MCMilestoneDashboardSubsystemTableRequest(this.filterForm.controls.goocs.value[0])
                );
            } else {
                this.store.dispatch(new MCMilestoneDashboardResetCharts());
            }
        } else if (this.dashboardType === this.dashboardTypes.RFO) {
            this.store.dispatch(new RFOMilestoneDashboardIcicleRequest());
            if (this.filterForm.controls.rfos.value.length > 0) {
                this.store.dispatch(new RFSUMilestoneDashboardSkylineRequest(this.filterForm.controls.rfos.value[0]));
                this.store.dispatch(new RFSUMilestoneDashboardLookaheadRequest(this.filterForm.controls.rfos.value[0]));
            }
        }
        this.store.dispatch(new MilestoneDashboardRundownRequest({ monthlyView: this.monthViewEnabled }));
        if (this.filterForm.controls.goocs.value.length === 1 || this.filterForm.controls.rfos.value.length === 1) {
            this.store.dispatch(new MilestoneDashboardCommentsRequest());
        }
    }

    startDateSet(startDate: string) {
        this.store.dispatch(new MilestoneDashboardWeekRangeSet(startDate));
    }

    onLongerLabel(isLongerLabel: boolean) {
        setTimeout(() => (this.isLongerLabel = isLongerLabel));
    }

    openCommentsHistoryPopup(section: string, event: any, type: string) {
        event.preventDefault();
        event.stopPropagation();

        this.popupSvc.openPopup(
            new PopupSettings(
                CommentsHistoryComponent,
                null,
                null,
                {
                    commentHist: this.allComments
                        .find((s) => s.type === type)
                        [section].map((c) => ({
                            user: {
                                name: c.userName,
                                surname: '',
                            },
                            date: c.date,
                            description: c.description,
                            mentions: c.mentions,
                            id: c.id,
                        })),
                    action: (id: number) => {
                        this.store.dispatch(
                            new MilestoneDashboardRemoveCommentRequest({
                                id,
                                type: MilestoneCommentType[section.charAt(0).toUpperCase() + section.slice(1)],
                                userType: type,
                            })
                        );
                    },
                },
                200,
                200
            )
        );
    }

    commentSizeOptions: { height?: number }[] = [{}, {}, {}, {}, {}];

    public onResize(evt: ResizeEvent, index: number): void {
        this.commentSizeOptions[index].height = evt.rectangle.height;
    }

    validate(event: ResizeEvent): boolean {
        return event.rectangle.height >= 200;
    }
}
