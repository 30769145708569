<main class="main">
  <a class="navigator" [routerLink]="['/admin']">
    <mat-icon>keyboard_arrow_left</mat-icon>
    Go back
  </a>
  <div class="title-container">
    <div class="title">
      configuration
    </div>
  </div>

  <nav mat-tab-nav-bar class="tab-nav-px15">
    <a mat-tab-link
       *ngFor="let link of navLinks"
       [routerLink]="link.link"
       routerLinkActive
       #rla="routerLinkActive"
       [active]="rla.isActive && link.link"
       [disabled]="!link.link">
      {{ link.label }}
    </a>
  </nav>

  <router-outlet></router-outlet>
</main>
