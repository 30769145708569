import { Component, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { ColorHelper, ScaleType } from '@swimlane/ngx-charts';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import {
    OverdueExceptionsByPriorityChartRequest,
    OverdueExceptionsBySubsystemChartRequest,
    overdueExceptionsBySubsystemTableRequest,
    OverdueExceptionsByPriorityFilterPropertyUpdate,
    overdueExceptionsByPriorityTableRequest,
    overdueExceptionsByPriorityTablesVisibilityUpdate,
} from 'src/app/store/reports/health/actions';

@Component({
    selector: 'app-overdue-exceptions-by-priority-chart',
    templateUrl: './overdue-exceptions-by-priority-chart.component.html',
    styleUrls: ['./overdue-exceptions-by-priority-chart.component.scss'],
})
export class OverdueExceptionsByPriorityChartComponent extends BaseComponent implements OnInit {
    chart$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.chart);
    subsystemChart$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.subsystemChart);

    @ViewChild('chartPanel', { static: true }) chartExpansionPanel: MatExpansionPanel;

    colorScheme = { domain: ['#f18f92', '#0ed145'] };
    customScheme = [
        { name: 'Overdue', value: '#f18f92' },
        { name: 'Not Overdue', value: '#0ed145' },
    ];
    barChartLabels: string[] = ['Overdue', 'Not Overdue'];
    barChartColors: ColorHelper;

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit(): void {
        this.chartExpansionPanel.expanded = true;

        this.barChartColors = new ColorHelper('natural', ScaleType.Ordinal, this.barChartLabels, this.customScheme);

        this.store.dispatch(new OverdueExceptionsByPriorityChartRequest());
        this.store.dispatch(new OverdueExceptionsBySubsystemChartRequest());
    }

    onSelect(event, which: string) {
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'selectedBarLabel',
                value: `${event.label}`,
            })
        );
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'selectedBarSeries',
                value: `${event.series}`,
            })
        );
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );

        const showExceptions = which === 'exceptions';
        const showSubsystems = which === 'subsystems';
        this.store.dispatch(
            overdueExceptionsByPriorityTablesVisibilityUpdate({
                showTable: showExceptions,
                showSubsystemTable: showSubsystems,
            })
        );

        if (showExceptions) {
            this.store.dispatch(overdueExceptionsByPriorityTableRequest());
        }

        if (showSubsystems) {
            this.store.dispatch(overdueExceptionsBySubsystemTableRequest());
        }
    }
}
