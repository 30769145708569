import { Component, OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, map, take, takeWhile } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Constants } from 'src/app/constants';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import {
    DetailsAddCommentRequest,
    GetDetailsRequest,
    DetailsDeleteCommentRequest,
    DetailsPLIReviewRequest,
    DetailsPLIReviewDeleteRequest,
    DetailsAddConstraintCommentRequest,
    DetailsDeleteConstraintCommentRequest,
    UpdateDetailsRequest,
} from 'src/app/store/details/actions';
import { Attachment, DetailedStatusDTO, AttachmentDTO, PLIReviewType, MaterialsDTO } from 'src/app/store/details/model';
import { ConstraintTeamsDTO , DetailedStatusDTO as DetailedStatusServiceDTO } from 'src/app/store/detailed-status/model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from '../../modules/shared/components/comment-popup/comment-popup.component';
import * as moment from 'moment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SubsystemScope } from 'src/app/enums';
import { subsystemScopeEnumToString } from 'src/app/extensions';
import { DeleteConfirmationPopupComponent } from '../../modules/shared/components/delete-confirmation-popup/delete-confirmation-popup.component';
import { ChangeManagementSimple, CommentDTO, ContraintContactUserDetail, UserDetail } from 'src/app/store/common.model';
import { RoleService } from 'src/app/services/shared/role.service';
import { Dictionary } from 'typescript-collections';
import { DetailedStatusService } from 'src/app/services/api/webapi-services/detailed-status.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import {
    DetailedStatusAttachmentsUpdated,
    DetailedStatusUpdateITRConstraintRequest,
} from 'src/app/store/detailed-status/actions';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ScheduleActivityPlanningConstraint } from 'src/app/components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ITRConstraintDTO } from 'src/app/models/itr-constraint';
import { MsalService } from '@azure/msal-angular';
import { TagKeyDTO } from 'src/app/store/tag-search/model';
import { ProjectTeamService } from 'src/app/services/api/webapi-services/project-team.service';
import { cloneDeep, uniqBy } from 'lodash';
@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
})
export class DetailsComponent extends BaseComponent implements OnInit {
    data: DetailedStatusDTO = new DetailedStatusDTO();
    comments: CommentDTO[] = [];
    materialDetails :MaterialsDTO[] = [];
    constraintComments: CommentDTO[] = [];
    comment = '';
    displayedColumns: string[] = ['comment', 'user', 'date', 'delete'];
    displayedMaterialsColumns: string[] = [
        'abbreviated',
        'itemCode',
        'size',
        'qty',
        'uom',
        'status',
        'quarantine',
        'eta',
        'cwp'
    ]
    scope: SubsystemScope;
    id: string;
    scopeToString = '';
    isLoading = false;
    isConstraintSignedOff = false;
    signOffDate = '';
    closedByUser = '';
    item$ = this.store.select((state) => state.detailsState.item);
    comments$ = this.store.select((state) => state.detailsState.comments);
    materials$ = this.store.select((state) => state.detailsState.materialDetails);
    constraintComments$ = this.store.select((state) => state.detailsState.constraintComments);
    stateLoader$ = this.store.select((state) => state.detailsState.isLoading);
    //stateConstraintLoader$ = this.store.select((state) => state.detailedStatusState.isLoading);
    hasHistory = false;
    isReadOnly = false;
    previousPageScope: SubsystemScope;
    subsystemScopes = SubsystemScope;
    uploadedAttachmentsActionInProgress = new Dictionary<number, boolean>();
    usersAutocompleteDisplayedColumns = ['name', 'email'];
    changeAutocompleteDisplayedColumns = ['name', 'numberedName'];
    usersAutocompleteDisplayedColumnsWithoutEmail = ['name'];
    tagsAutocompleteDisplayedColumns = ['tagNo'];
    barcodesAutocompleteDisplayedColumns = ['tagNo'];
    fmRorPO: string[];
    // waypointRequired: string[];
    associatedNPW: string[];
    users: UserDetail[] = [];
    changes: ChangeManagementSimple[] = [];
    
    constraintContactUsers: ContraintContactUserDetail[] = [];
    responsibleGroup : UserDetail[]= [];
    allResponsibleGroup: UserDetail[]=[];
    setUsersInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setResponsibleGroupInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setTagInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setBarcodeInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    constraintTeams : ConstraintTeamsDTO[] ;
    newAttachments: Attachment[] = [];
    listofSelectedBarcodes: TagKeyDTO[] = [];
    isUploadInProgress = false;
    canSignOff = false;
    isAdmin = false;
    isConstraintsforecastclosuredateGroupMember = false;
    types = PLIReviewType;
    constraintDetailsForm: UntypedFormGroup;
    showButtons = false;
    currentUser: string;
    constraintStatus = '';
    constraintTypes: ScheduleActivityPlanningConstraint[] = [];
    tagNo: TagKeyDTO[];
    tagsNo: string[];
    barcodesNo: TagKeyDTO[];
    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private router: Router,
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
        private popupSvc: PopupService,
        private roleService: RoleService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private detailedStatusService: DetailedStatusService,
        private toastService: ToastService,
        private scheduleActivityLookupService: ScheduleActivityLookupService,
        private lookupService: LookupService,
        private authService: MsalService,
        private projectTeamService: ProjectTeamService,
    ) {
        super();
        this.hasHistory = this.router.navigated;
        iconRegistry.addSvgIcon(
            'del-icon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
        this.constraintDetailsForm = new UntypedFormGroup({
            constraintType: new UntypedFormControl('', Validators.required),
            constraintForecastduedate: new UntypedFormControl({value: '', disabled: false}),
            responsiblepersongroup: new UntypedFormControl(''),
            itrConstraintDescription: new UntypedFormControl(''),
            fmRorPO: new UntypedFormControl(''),
            // waypointRequired: new UntypedFormControl(''),
            fmRorPOETADate: new UntypedFormControl(''),
            associatedNPW: new UntypedFormControl(''),
            raisedDate: new UntypedFormControl(''),
           // username: new UntypedFormControl('', Validators.required),
            additionalUsername: new UntypedFormControl(''),
            itrConstraintRaisedBy: new UntypedFormControl('', Validators.required),
            tagNo: new UntypedFormControl(''),
            barcodeNo: new UntypedFormControl(''),
            teamname: new UntypedFormControl('')
        });
    }

    ngOnInit() {
        this.currentUser = this.authService.instance.getActiveAccount().name;
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.isConstraintsforecastclosuredateGroupMember = this.roleService.isInRole(Constants.applicableGroups.Constraintsforecastclosuredate) || this.isAdmin;
        this.getConstraintTeams();

        this.canSignOff = this.roleService.isInAnyRole([
            Constants.applicableGroups.DPLIReviewers,
            Constants.applicableGroups.Admin,
        ]);

        this.isReadOnly = this.roleService.isReadOnly();
        if (this.isReadOnly) this.displayedColumns = ['comment', 'user', 'date'];

        this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            this.scope = SubsystemScope[paramMap.get('scope')];
            this.id = paramMap.get('id');
            this.scopeToString = subsystemScopeEnumToString(this.scope);
            this.previousPageScope = Number(paramMap.get('previousPageScope'));
            if (this.scope != 10) {
                this.store.dispatch(new GetDetailsRequest({ id: paramMap.get('id'), scope: this.scope }));
            }
        });

        this.stateLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.item$.pipe(
                takeWhile(() => this.isAlive),
                finalize(() => (this.isLoading = false))
            ).subscribe((item) => {
            this.data = item;
            this.comments = item.comments ? [...item.comments] : [];          
            if (this.scope === 10) {
                this.isLoading = true;                
                this.constraintStatus = this.data.itrConstraintStatus;
                this.getTagsForConstraintID();
                this.getBarcodesForConstraintID();
                this.getConstraintTypes();
                this.getUsers();
                this.getChangeMangement();
                this.formatConstraintDetailsDates();
                this.getOtherConstraintFieldsForEditing();        
                if(this.data.itrConstraintStatus == 'Closed') {
                    this.constraintDetailsForm.markAsPristine();
                    this.constraintDetailsForm.disable();
                }
            }
            this.comments.sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)));
            this.showButtons =
                item.category === 'D' &&
                this.canSignOff &&
                item.reviewStatusComplete !== 'Rejected' &&
                item.reviewStatusComplete !== 'Endorsed';
        });

        this.comments$.pipe(takeWhile(() => this.isAlive)).subscribe((comments) => {
            this.comments = comments ? [...comments] : [];
            this.comments.sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)));
        });
        this.constraintComments$.pipe(takeWhile(() => this.isAlive)).subscribe((comments) => {
            this.constraintComments = comments ? [...comments] : [];
            this.constraintComments.sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)));
        });
        this.materials$.pipe(takeWhile(() => this.isAlive)).subscribe((materials) => {
            this.materialDetails = materials ? [...materials] : [];
        });
    }

    isConstraintForecastClosureDateEnabled() {
        if(this.constraintDetailsForm.controls.constraintType.value?.name === 'Open Punch Item') {
        this.isConstraintsforecastclosuredateGroupMember ? this.constraintDetailsForm.controls.constraintForecastduedate.enable() :
        this.constraintDetailsForm.controls.constraintForecastduedate.disable();
        } else {
            this.constraintDetailsForm.controls.constraintForecastduedate.enable();
        }  
    }

    formatConstraintDetailsDates() {
        if (this.data.itrConstraintDueDate instanceof moment) {
            this.constraintDetailsForm.controls['constraintForecastduedate'].setValue(
                this.data.itrConstraintDueDate.format()
            );
        } else {
            this.constraintDetailsForm.controls['constraintForecastduedate'].setValue(this.data.itrConstraintDueDate);
        }
        if (this.data.itrConstraintRaisedDate instanceof moment) {
            this.constraintDetailsForm.controls['raisedDate'].setValue(this.data.itrConstraintRaisedDate.format());
        } else {
            this.constraintDetailsForm.controls['raisedDate'].setValue(this.data.itrConstraintRaisedDate);
        }
        if (this.data.fmRorPOETADate instanceof moment) {
            this.constraintDetailsForm.controls['fmRorPOETADate'].setValue(this.data.fmRorPOETADate.format());
        } else {
            this.constraintDetailsForm.controls['fmRorPOETADate'].setValue(this.data.fmRorPOETADate);
        }
        this.signOffDate = moment(this.data.itrConstraintStatusDate).format('DD-MMM-YYYY HH:mm:ss');
    }

    goToITRConstraints(event: any, itr: DetailedStatusDTO = null) {
        if (itr != null) {
            localStorage.setItem(
                'selectedbarcodes',
                JSON.stringify(
                    this.listofSelectedBarcodes
                )
            );
            const selection = window.getSelection();
            if (selection.toString().length === 0) {
                const url = this.router.serializeUrl(
                    this.router.createUrlTree([`/detailedstatus`],                     
                    {queryParams: {
                        scope: 'ConstraintDetails',
                        id: itr.id,
                    }})
                  );
                  window.open(url, '_blank');
                  event.stopPropagation();
            }
        }
    }

    getConstraintTypes() {
        this.scheduleActivityLookupService
            .searchConstraintTypes(100000)
            .pipe(
                take(1)
            )
            .subscribe((data) => {
                this.constraintTypes = data.items;
                this.constraintDetailsForm.controls.constraintType.setValue(
                    this.constraintTypes.find((t) => t.name === this.data.itrConstraintTypeName)
                );
                this.isConstraintForecastClosureDateEnabled();
            });
    }

    getConstraintTeams = () => {
        this.projectTeamService.getProjectSubTeams()
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((data) => {
                this.constraintTeams = data;
                this.constraintDetailsForm.controls.teamname.setValue(
                    this.constraintTeams.find((t) => t.projectSubTeamName === this.data.itrConstraintRaisedByTeam)
                );
             });
    };

    getOtherConstraintFieldsForEditing() {
        this.constraintDetailsForm.controls.itrConstraintDescription.setValue(this.data.itrConstraintDescription);
        this.constraintDetailsForm.controls.fmRorPO.setValue(this.data.fmRorPO);
        // this.constraintDetailsForm.controls.waypointRequired.setValue(this.data.waypointRequired);
    }

    getTags = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchTagsforSubsystem(search, take, page, [], this.id).pipe(
            takeWhile(() => this.isAlive),
            map((tags: string[]) => tags)
        );
    };
    getBarcodes = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchBarcodesforConstraints(search, take, page).pipe(
            takeWhile(() => this.isAlive),
            map((barcodes: string[]) => barcodes
            )
        );        
    };

    getUsers() {
        this.lookupService
            .searchUsers('', 0, 5000)
            .pipe(take(1))
            .subscribe((data) => {
                this.users = data;

                let additionaluser = this.users.find((t) => t.name === this.data.itrConstraintAdditionalResponsibleUser);
                        if ( additionaluser != undefined) {
                            this.constraintDetailsForm.controls['additionalUsername'].setValue([additionaluser]);
                        }
                let itrConstraintRaisedByUser = this.users.find((t) => t.name === this.data.itrConstraintRaisedByUser);
                if (itrConstraintRaisedByUser != undefined) {
                    this.constraintDetailsForm.controls['itrConstraintRaisedBy'].setValue([itrConstraintRaisedByUser]);
                }
                
                let closedByUser0 = this.users.find((t) => t.name === this.data.itrConstraintClosedByUser);
                if (closedByUser0 != undefined) {
                    this.closedByUser = this.users.find((t) => t.name === this.data.itrConstraintClosedByUser).name;
                }

            });
            this.lookupService.searchContraintUsers(0, 5000)
            .pipe(
                take(1)
            )
            .subscribe(
                (data) => {
                    this.constraintContactUsers = data;
                }
            );
            this.lookupService.searchResponsibleGroup()
            .pipe(
                take(1)
            )
            .subscribe(
                (data: UserDetail[]) => {
                    this.responsibleGroup = data;
                   this.allResponsibleGroup = data;
                        let respuser = this.responsibleGroup.find((t) => t.name === this.data.itrConstraintResponsibleGroup);
                        if (respuser != undefined) {
                            this.constraintDetailsForm.controls['responsiblepersongroup'].setValue([respuser]);
                        }                                                                 
                                      
                }
            );
    }

    getChangeMangement() {
        this.lookupService
            .searchIDandNameWithChangeManagementFilter('', 0, 5000)
            .pipe(take(1))
            .subscribe((data) => {
                this.changes = <ChangeManagementSimple[]>data;

                let npw = this.changes.find((t) => t.name === this.data.associatedNPW);
                if (npw != undefined) {
                    this.constraintDetailsForm.controls['associatedNPW'].setValue([npw]);
                }
            });
    }
    goToPreviousPage() {
        if (this.scope === SubsystemScope.ConstraintDetails) {
            if (!this.constraintDetailsForm.pristine) {
                this.popupSvc
                    .openPopup(
                        new ConfirmDialogPopupSettings({
                            title: 'Confirm action',
                            text: `Do you want to discard changes?`,
                        })
                    )
                    .afterClosed()
                    .pipe(takeWhile(() => this.isAlive === true))
                    .subscribe((answer) => {
                        if (answer) {
                            this.router.navigate(['/detailedstatus'], {
                                queryParams: { scope: this.subsystemScopes[this.scope] },
                            });
                        }
                    });
            } else {
                this.router.navigate(['/detailedstatus'], { queryParams: { scope: this.subsystemScopes[this.scope] } });
            }

            return;
        }
        if (this.hasHistory) {
            this.location.back();
        } else {
            this.router.navigate(['/detailedstatus'], { queryParams: { scope: this.subsystemScopes[this.scope] } });
        }
    }

    getType(element: PLIReviewType) {
        switch (element) {
            case PLIReviewType.Endorsed:
                return 'Endorsed';
            case PLIReviewType.Rejected:
                return 'Rejected';
            case PLIReviewType.TBC:
                return 'TBC';
            default:
                break;
        }
    }

    signOff(type: PLIReviewType) {
        this.popupSvc
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to complete review?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.store.dispatch(new DetailsPLIReviewRequest({ finalPunchItem: this.data.number, type }));
                }
            });
    }

    deleteSignOff(id: number) {
        this.popupSvc
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete review?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.store.dispatch(new DetailsPLIReviewDeleteRequest({ id }));
                }
            });
    }

    revertConstraintSignOff() {
        this.popupSvc
        .openPopup(
            new ConfirmDialogPopupSettings({
                title: 'Confirm action',
                text: `Are you sure you want to revert signoff?`,
            })
        )
        .afterClosed()
        .pipe(takeWhile(() => this.isAlive === true))
        .subscribe((answer) => {
            if (answer) {
                let form = this.getFormFields();
                form.deleted = '';
                this.constraintStatus = 'Open';
                form.ConstraintStatus = 'RevertSignoff';
                form.ConstraintStatusDate = moment(null);              
                this.isConstraintSignedOff =  false;
                this.updateRequest(form);
                this.constraintDetailsForm.enable();
            }
        });
    }

    removeComment(element: CommentDTO) {
        if (this.scope === SubsystemScope.ConstraintDetails) {
            this.popupSvc.openPopup(
                new PopupSettings(DeleteConfirmationPopupComponent, 400, 130, {
                    action: (value: boolean) => {
                        if (value) {
                            this.store.dispatch(
                                new DetailsDeleteCommentRequest({ id: element.id, scope: SubsystemScope.BITR })
                            );
                        }
                    },
                })
            );
            return;
        }
        this.popupSvc.openPopup(
            new PopupSettings(DeleteConfirmationPopupComponent, 400, 130, {
                action: (value: boolean) => {
                    if (value) {
                        this.store.dispatch(new DetailsDeleteCommentRequest({ id: element.id, scope: this.scope }));
                    }
                },
            })
        );
    }

    removeConstraintComment(element: CommentDTO) {
        this.popupSvc.openPopup(
            new PopupSettings(DeleteConfirmationPopupComponent, 400, 130, {
                action: (value: boolean) => {
                    if (value) {
                        this.store.dispatch(
                            new DetailsDeleteConstraintCommentRequest({ id: element.id, scope: this.scope })
                        );
                    }
                },
            })
        );
    }

    openConstraintCommentPopup(event: any) {
        if (this.isReadOnly) return;
        if (this.scope === SubsystemScope.ConstraintDetails) {
            event.scope = this.scope;
            event.id = event.itrConstraintId;
        }
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                url: `details?scope=${this.subsystemScopes[this.scope]}&id=${
                    this.scope === SubsystemScope.PLI || this.scope === SubsystemScope.COW
                        ? this.data.number
                        : this.data.id.toString()
                }`,
                action: (value: string, mentions: UserDetail[]) => {
                    if (this.scope === SubsystemScope.PLI || this.scope === SubsystemScope.COW) {
                        this.store.dispatch(
                            new DetailsAddCommentRequest({
                                entityId: this.data.number,
                                description: value,
                                scope: this.scope,
                                mentions,
                            })
                        );
                    } else if (this.scope === SubsystemScope.ConstraintDetails) {
                        this.store.dispatch(
                            new DetailsAddConstraintCommentRequest({
                                entityId: this.data.itrConstraintId.toString(),
                                description: value,
                                scope: this.scope,
                                mentions,
                            })
                        );
                    }

                    this.comment = '';
                },
            })
        );

        event.stopPropagation();
    }

    openCommentPopup(event: any) {
        if (this.isReadOnly) return;
        if (this.scope === SubsystemScope.ConstraintDetails) {
            event.scope = this.scope;
            event.id = event.itrConstraintId;
        }
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                url: `details?scope=${this.subsystemScopes[this.scope]}&id=${
                    this.scope === SubsystemScope.PLI || this.scope === SubsystemScope.COW
                        ? this.data.number
                        : this.data.id.toString()
                }`,
                action: (value: string, mentions: UserDetail[]) => {
                    if (this.scope === SubsystemScope.PLI || this.scope === SubsystemScope.COW) {
                        this.store.dispatch(
                            new DetailsAddCommentRequest({
                                entityId: this.data.number,
                                description: value,
                                scope: this.scope,
                                mentions,
                            })
                        );
                    } else if (this.scope === SubsystemScope.ConstraintDetails) {
                        this.store.dispatch(
                            new DetailsAddCommentRequest({
                                entityId: this.data.id.toString(),
                                description: value,
                                scope: SubsystemScope.BITR,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new DetailsAddCommentRequest({
                                entityId: this.data.id.toString(),
                                description: value,
                                scope: this.scope,
                                mentions,
                            })
                        );
                    }

                    this.comment = '';
                },
            })
        );

        event.stopPropagation();
    }

    goToCoreworx() {
        window.open(this.data.coreworxLink, '_blank');
    }

    removeNewAttachment(index: number) {
        this.newAttachments.splice(index, 1);
    }

    fileChangeEvent(files: Array<File>) {
        const filesWrapper = new Array<File>();
        filesWrapper.push(...files);
        filesWrapper.forEach((file) => {
            if (file.size === 0) {
                this.toastService.Info(`File ${file.name} has 0B size. Please upload a valid file.`);
                return;
            }
            if (
                this.newAttachments.filter((x) => x.file.name === file.name).length > 0 ||
                (this.data.attachments &&
                    this.data.attachments.filter((x: AttachmentDTO) => x.name === file.name).length > 0)
            ) {
                this.toastService.Info(
                    'File ' + file.name + ' already uploaded. Please delete existing file and re-upload.'
                );
            } else {
                const attachment = new Attachment();
                attachment.file = file;
                attachment.name = file.name;
                attachment.isValid = true;
                this.newAttachments.push(attachment);
            }
        });
    }

    uploadNewAttachments() {
        const formData = new FormData();
        this.newAttachments.forEach((file) => {
            formData.append(file.file.name, file.file);
        });
        this.isUploadInProgress = true;
        const number = this.scope === this.subsystemScopes.ConstraintDetails ? this.data.itrConstraintToITRId.toString() : this.data.number;
        this.detailedStatusService
            .uploadAttachments(formData, number, this.scope)
            .pipe(
                take(1),
                finalize(() => (this.isUploadInProgress = false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while uploading attachment(s). Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((uploadedAttachments: Dictionary<string, string>) => {
                this.newAttachments = [];
                const attachments = Object.keys(uploadedAttachments).map(
                    (key): AttachmentDTO => {
                        return {
                            name: key,
                            link: uploadedAttachments[key],
                        };
                    }
                );
                let copyObj = cloneDeep(this.data);
                copyObj.attachments = [...this.data.attachments, ...attachments];
                this.store.dispatch(
                    new DetailedStatusAttachmentsUpdated({
                        number: this.scope === SubsystemScope.ConstraintDetails ? this.data.itrConstraintToITRId.toString() : this.data.number,
                        attachments: copyObj.attachments,
                        scope: this.scope
                    })
                );
                this.store.dispatch(new UpdateDetailsRequest(copyObj));                
            });
    }

    isAdminandPerformanceEnginnerAccess() {
        return (this.roleService.isInRole(Constants.applicableGroups.Admin) ||
        this.roleService.isInRole(Constants.applicableGroups.ExceptionsTracking));
    }
    clearNewAttachments() {
        this.newAttachments = [];
    }

    downloadUploadedAttachment(index: number) {
        if (this.data.attachments[index].link.includes('chevron.sharepoint')) {
            window.open(this.data.attachments[index].link, "_blank")
            return
        }
        this.uploadedAttachmentsActionInProgress.setValue(index, true);
        this.detailedStatusService
            .downloadAttachment(this.data.attachments[index].link, this.scope)
            .pipe(
                take(1),
                finalize(() => this.uploadedAttachmentsActionInProgress.setValue(index, false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, this.data.attachments[index].name);
            });
    }

    deleteUploadedAttachment(index: number) {
        this.uploadedAttachmentsActionInProgress.setValue(index, true);
        this.detailedStatusService
            .deleteAttachment([this.data.attachments[index].link], this.scope)
            .pipe(
                take(1),
                finalize(() => this.uploadedAttachmentsActionInProgress.setValue(index, false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while deleting attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe(() => {

                let copyObj = cloneDeep(this.data);
                copyObj.attachments = [
                    ...copyObj.attachments.slice(0, index),
                    ...copyObj.attachments.slice(index + 1),
                ];
                this.store.dispatch(
                    new DetailedStatusAttachmentsUpdated({
                        number: this.scope === SubsystemScope.ConstraintDetails ? this.data.itrConstraintToITRId.toString() : this.data.number,
                        attachments: copyObj.attachments,
                        scope : this.scope
                    })
                );
                this.store.dispatch(new UpdateDetailsRequest(copyObj));   
            });
    }

    anyActionPending() {
        this.uploadedAttachmentsActionInProgress.values().some((v) => v);
    }
    searchITRConstraintResponsibleGroup = (search: string, take: number, page: number) => {    
        let filteredconstraintContactUsers = this.constraintContactUsers.filter((cu)=> cu.constraintTypeId === this.constraintDetailsForm.controls.constraintType.value.id)
        this.responsibleGroup = this.allResponsibleGroup.filter(data => {
         return filteredconstraintContactUsers.some(cc => cc.constraintResponsibleGroupId === data.id)
        });    
        let elements = this.responsibleGroup.filter(
            (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        if (elements.length) {
            elements.forEach((s: any) => {
                s.Selected = false;
                return s;
            });
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    onUsersClosed() {
        this.setUsersInput.emit(new SetInputEventArgs(true));
        this.setResponsibleGroupInput.emit(new SetInputEventArgs(true));
        this.setTagInput.emit(new SetInputEventArgs(true));
        this.setBarcodeInput.emit(new  SetInputEventArgs(true));
    }

    searchUsers = (search: string, take: number, page: number) => {
        let elements = this.users.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
        if (elements.length) {
            elements.forEach((s: any) => {
                s.Selected = false;
                return s;
            });
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    searchChanges = (search: string, take: number, page: number) => {
        console.log('search: ', search);
        let elements = this.changes.filter(
            (item) => item.numberedName.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        if (elements.length) {
            elements.forEach((s: any) => {
                s.Selected = false;
                return s;
            });
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    save() {
        this.popupSvc
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to save?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    const form = this.getFormFields();
                    form.ConstraintStatusDate = moment.utc();
                    this.updateRequest(form);
                }
            });
    }

    constraintSignOff() {
        this.popupSvc
        .openPopup(
            new ConfirmDialogPopupSettings({
                title: 'Confirm action',
                text: `CAN CONSTRAINT BE CLOSED?`,
            })
        )
        .afterClosed()
        .pipe(takeWhile(() => this.isAlive === true))
        .subscribe((answer) => {
            if (answer) {
                let form = this.getFormFields();
                form.deleted = 'deleted';
                this.constraintStatus = 'Closed';
                form.ConstraintStatus = 'Signoff'; // as we are going status by ITRConstraintToITR status column , 
                 //using this column for checking whether its signoff or not but db value will be updated between 'Closed' and 'Open' value only
                form.ConstraintStatusDate = moment.utc();
                this.signOffDate = moment().format('DD-MMM-YYYY HH:mm:ss');
                this.closedByUser = this.currentUser;
                this.isConstraintSignedOff = true;
                this.updateRequest(form);
                this.constraintDetailsForm.disable();
            }
        });
       
    }

    private mappingModels(constraintForm: ITRConstraintDTO) {
        let itr = new DetailedStatusServiceDTO();
        itr.itrConstraintTypeName = constraintForm.constraintTypeName;
        itr.fmRorPO = constraintForm.fmrOrPO;
        // itr.waypointRequired = constraintForm.waypointRequired;
        itr.associatedNPW = constraintForm.associatedNPW;
        itr.itrConstraintDueDate = constraintForm.dueDate?.isValid() ? constraintForm.dueDate : null;
        itr.fmRorPOETADate = constraintForm.fmRorPOETADate?.isValid() ? constraintForm.fmRorPOETADate : null;
        itr.itrConstraintResponsibleUser = constraintForm.responsibleUserName;
        itr.itrConstraintAdditionalResponsibleUser = constraintForm.additionalResponsibleUserName;
        itr.itrConstraintRaisedDate = constraintForm.constraintRaisedDate;
        itr.itrConstraintRaisedByUser = constraintForm.raisedByUserName;
        itr.associatedNPWChangeType = constraintForm.associatedNPWChangeType;
        itr.itrConstraintDescription = constraintForm.itrConstraintDescription;
        itr.itrConstraintTypeName = constraintForm.constraintTypeName
        itr.tagNo = constraintForm.tagNo;
        itr.barcodes = constraintForm.barcodes;

        return itr;
    }

    private updateRequest(form: ITRConstraintDTO) {
        const payload = {
            itrConstraint: form,
            itr: this.mappingModels(form),
        };

        this.store.dispatch(new DetailedStatusUpdateITRConstraintRequest(payload));
        this.constraintDetailsForm.markAsPristine();
    }
    populateGroups(type : number){
        let filteredconstraintContactUsers = this.constraintContactUsers.filter((cu)=> cu.constraintTypeId === this.constraintDetailsForm.controls.constraintType.value.id)
        this.responsibleGroup = this.allResponsibleGroup.filter(data => {
         return filteredconstraintContactUsers.some(cc => cc.constraintResponsibleGroupId === data.id)
        });  
        this.constraintDetailsForm.controls['responsiblepersongroup'].setValue([]);
       this.isConstraintForecastClosureDateEnabled();    
        this.setResponsibleGroupInput.emit(new SetInputEventArgs(true));
       
    }
    private getTagsForConstraintID() {
        this.lookupService
            .searchTagsforConstraintID('', [], this.data.id, this.data.itrConstraintId)
            .pipe(
                takeWhile(() => this.isAlive),
                map((tags: string[]) => tags)
            )
            .subscribe((data) => {
                this.tagsNo = data;

                if (this.tagsNo && this.tagsNo.length > 0) {
                    this.constraintDetailsForm.controls['tagNo'].setValue([...this.tagsNo]);
                }
            });
    }
    private getBarcodesForConstraintID() {
        this.lookupService
            .searchBarcodesforConstraintID('', [], this.data.id, this.data.itrConstraintId)
            .pipe(
                takeWhile(() => this.isAlive),
                map((tags: TagKeyDTO[]) => tags)
            )
            .subscribe((data) => {
                this.barcodesNo = data;

                if (this.barcodesNo && this.barcodesNo.length > 0 && this.constraintStatus !== 'Closed') {
                   // let uniqureBarcodes = uniqBy(this.barcodesNo, (item: TagKeyDTO)=> item.tagNo);
                    this.constraintDetailsForm.controls['barcodeNo'].setValue([...this.barcodesNo]);
                } else {
                   var indBarcode = new TagKeyDTO();
                    indBarcode.tagNo = this.data.barcode ;
                    this.constraintDetailsForm.get('barcodeNo').setValue([indBarcode]);
                }
            });
    }

    private getFormFields() {
        let form = new ITRConstraintDTO();
        form.itrId = this.data.id;
        form.constraintType = this.constraintDetailsForm.controls.constraintType.value.id;
        form.constraintTypeName = this.constraintDetailsForm.controls.constraintType.value.name;
        try {
            form.responsibleGroup = this.constraintDetailsForm.controls.responsiblepersongroup.value[0]?.id ?? null;
            form.responsibleUserName = this.constraintDetailsForm.controls.responsiblepersongroup.value[0]?.name ?? '';
            form.additionalResponsibleUser = this.constraintDetailsForm.controls.additionalUsername.value[0]?.id ?? null;
            form.additionalResponsibleUserName = this.constraintDetailsForm.controls.additionalUsername.value[0]?.name ?? "";
            form.constraintRaisedByUser =
                this.constraintDetailsForm.controls.itrConstraintRaisedBy.value[0]?.id ?? null;
            form.raisedByUserName = this.constraintDetailsForm.controls.itrConstraintRaisedBy.value[0]?.name ?? '';
            if(this.constraintDetailsForm.controls.tagNo !== undefined &&
                this.constraintDetailsForm.controls.tagNo.value.length > 0) {
                form.tagNo = this.constraintDetailsForm.controls.tagNo.value.map((x) => x.id);
            } else {
                form.tagNo = [];
            } 
         
            form.barcodes = this.constraintDetailsForm.controls.barcodeNo.value.map((x) => x.tagNo);         
            form.associatedNPW = this.constraintDetailsForm.controls.associatedNPW.value[0]?.name ?? '';
            form.constraintRaisedByTeamId = this.constraintDetailsForm.controls.teamname?.value?.id ?? null;
            form.associatedNPWChangeType = this.changes.find((t) => t.name === form.associatedNPW).typeName;
            
        } catch {
            // This is intentional
        }
        form.dueDate = moment(this.constraintDetailsForm.controls.constraintForecastduedate.value).utc();
        form.constraintRaisedDate = moment(this.constraintDetailsForm.controls.raisedDate.value).utc();
        form.id = this.data?.itrConstraintId;

        form.fmrOrPO = this.constraintDetailsForm.controls.fmRorPO.value;
        // form.waypointRequired = this.constraintDetailsForm.controls.waypointRequired.value;
        form.itrConstraintDescription = this.constraintDetailsForm.controls.itrConstraintDescription.value;

        let fmrdate = this.constraintDetailsForm.controls.fmRorPOETADate?.value;
        if (fmrdate != null) {
            form.fmRorPOETADate = moment(fmrdate)?.utc();
        } else {
            form.fmRorPOETADate = null;
        }
        return form;
    }
}
