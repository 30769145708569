<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span>{{ skylineHeader }}</span>
        </div>
        <div *ngIf="skylinePhase !== 'rfo'">
            <button class="sct-button sct-button-light margin-right20" (click)="exportToExcel()">
                <span>Export to excel<span class="lds-hourglass-small" *ngIf="isExcelGenerating$ | async"></span></span>
            </button>
            <span matTooltip="print the full skyline on A0 page" matTooltipClass="scttooltip">
                <button class="sct-button sct-button-light" (click)="showPortal = true">
                    A0 print
                </button>
            </span>
        </div>
    </div>
    <nav mat-tab-nav-bar class="area-centered">
        <a
            mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
        >
            {{ link.label }}
        </a>
    </nav>
    <div class="filter-container">
        <form [formGroup]="filterForm">
            <mat-accordion class="filter-container">
                <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                    <mat-expansion-panel-header collapsedHeight="auto">
                        <div *ngIf="!filterExpansionPanel.expanded">
                            <mat-chip-list aria-label="Active filters">
                                <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                    Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                    Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('contractors')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip
                                    *ngIf="filterForm.controls.stagedStartUpPriorities.value.length"
                                    removable
                                >
                                    Staged Start Up Priorities: {{ displayStagedStartUpPrioritiesSelected() }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('stagedStartUpPriorities')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.constructionArea.value.length" removable>
                                    Construction Area:
                                    {{ displayMultipleFilterSelected(filterForm.controls.constructionArea.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('constructionArea')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                    System Owner:
                                    {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                    SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.requiredForSteamblow && skylinePhase != 'citr'" removable>
                                    Required for Steamblow: {{ displaySelectedBoolean(filterForm.controls.requiredForSteamblow.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('requiredForSteamblow')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.deliveryEngineer.value.length" removable>
                                    Delivery Engineer: {{ displaySelectedDeliveryEngineer(filterForm.controls.deliveryEngineer.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('deliveryEngineer')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                    Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                    Area Breakdown:
                                    {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                                    RFSU Contractor:
                                    {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                                </mat-basic-chip>
                                <!-- <mat-basic-chip *ngIf="filterForm.controls.discipline.value.length" removable>
                                    Discipline: {{ displaySelectedDiscipline(filterForm.controls.discipline.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('discipline')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.workArea.value.length" removable>
                                    Work Area: {{ displaySelectedWorkArea(filterForm.controls.workArea.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('workArea')">cancel</mat-icon>
                                </mat-basic-chip> -->
                            </mat-chip-list>
                        </div>
                    </mat-expansion-panel-header>

                    <div class="mixedapp-container">

                        <mat-form-field class="container300 margin-right15">
                            <mat-label>Project Team</mat-label>
                            <mat-select
                                formControlName="projectTeamNames"
                                multiple
                                (openedChange)="onProjectTeamsClosed($event)"
                            >
                                <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="container300 margin-right15" *ngIf="skylinePhase !== 'rfo'">
                            <app-multiple-items-selector
                                [setInput]="setStagedStartUpPrioritiesInput"
                                formControlName="stagedStartUpPriorities"
                                [searchFunc]="getStagedStartUpPriorities"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="stagedStartUpPrioritiesAutocompleteDisplayedColumns"
                                (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                                (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                                [filterForm]="filterForm"
                                formSetter="stagedStartUpPriorities"
                                propertyToShow="priority"
                                placeholder="Staged Start-up Priority"
                                [panelWidth]="480"
                                [secondPropertyToShow]="'priorityName'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div *ngIf="skylinePhase === 'rfsu' || skylinePhase === 'rfo' || (skylinePhase === 'mc' && skylineType === 'forecast') || skylinePhase === 'rfsuwalkdown' || skylinePhase === 'citr'" class="container300 margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setRFOInput"
                                formControlName="rfos"
                                [searchFunc]="getRFOs"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'rfos'"
                                [propertyToShow]="'name'"
                                [placeholder]="'RFO'"
                                [secondPropertyToShow]="'rfoName'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name', 'rfoName']"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <mat-form-field class="container300 margin-right20" *ngIf="skylinePhase === 'rfo'">
                            <mat-label>Scope</mat-label>
                            <mat-select formControlName="waypointScopes" multiple (selectionChange)="getwayPointsByScope($event.value)">
                                <mat-option *ngFor="let sc of waypointScopes" [value]="sc"> {{sc}} </mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <mat-form-field class="container300 margin-right20" *ngIf="skylinePhase === 'rfo'">
                            <mat-label>Waypoint</mat-label>
                            <mat-select formControlName="waypointNames" multiple>
                                <mat-option *ngFor="let sc of wayPointNames" [value]="sc.name">{{sc.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div class="mixedapp-container">

                        <div class="container300 margin-right15" *ngIf="skylinePhase !== 'rfo'">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setRFSUContractorsInput"
                                formControlName="rfsuContractors"
                                [searchFunc]="getRFSUContractors"
                                [isAsync]="true"
                                [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                [filterForm]="filterForm"
                                [formSetter]="'rfsuContractors'"
                                [propertyToShow]="'contractNo'"
                                [placeholder]="'RFSU Contractor'"
                                [panelWidth]="480"
                                [secondPropertyToShow]="'contract'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <mat-form-field class="container300 margin-right15">
                            <mat-label>Area Completion Lead</mat-label>
                            <mat-select formControlName="areaCompletionLead" multiple>
                                <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setAreaBreakdownInput"
                                formControlName="areaBreakdown"
                                [searchFunc]="getAreaBreakdown"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'areaBreakdown'"
                                [placeholder]="'Area Breakdown'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <mat-form-field class="container300 margin-right15" *ngIf="skylinePhase === 'rfo'">
                            <mat-label>SC Execution Area Manager</mat-label>
                            <mat-select formControlName="scManager" multiple>
                                <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div class="mixedapp-container" *ngIf="skylinePhase === 'citr'">

                        <mat-form-field class="container300 margin-right15">
                            <mat-label>SC Execution Area Manager</mat-label>
                            <mat-select formControlName="scManager" multiple>
                                <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                [setInput]="setContractorInput"
                                formControlName="contractors"
                                [searchFunc]="getContractorsforItr"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                (autocompleteClosed)="onContractorsClosed()"
                                (itemRemoved)="onContractorsClosed()"
                                [filterForm]="filterForm"
                                formSetter="contractors"
                                propertyToShow="contractNo"
                                placeholder="ITR Contractor"
                                [panelWidth]="480"
                                [secondPropertyToShow]="'contract'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setSubsystemInput"
                                formControlName="subSystems"
                                [searchFunc]="getSubSystems"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'subSystems'"
                                [placeholder]="'Subsystem'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                [setInput]="setWaypointInput"
                                formControlName="waypoints"
                                [searchFunc]="getWaypoints"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'waypoints'"
                                [propertyToShow]="'name'"
                                [placeholder]="'Waypoint'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name']"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>
                        
                        <app-multiple-items-selector
                        class="container300 margin-right15"
                        [setInput]="setMilestoneInput"
                        formControlName="milestone"
                        [searchFunc]="getMilestones"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'milestones'"
                        [propertyToShow]="'name'"
                        [placeholder]="'Milestone'"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['name']"
                        [panelWidth]="480"
                    >
                        </app-multiple-items-selector>

                        <mat-form-field class="container300 margin-right15">
                            <mat-label>ITR Discipline</mat-label>
                            <mat-select formControlName="disciplines" multiple>
                                <mat-option *ngFor="let d of disciplines" [value]="d">{{ d }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setITRNumberInput"
                                formControlName="qvdNumbers"
                                [searchFunc]="getITRNumber"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'qvdNumbers'"
                                [propertyToShow]="'number'"
                                [placeholder]="'ITR Number'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setWorkAreaInput"
                                formControlName="workAreas"
                                [searchFunc]="getWorkArea"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'workAreas'"
                                [propertyToShow]="'workAreaName'"
                                [placeholder]="'Work Area'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector formControlName="itrDescriptions" [searchFunc]="getitrDescriptions" [isAsync]="true" [filterForm]="filterForm" [formSetter]="'itrDescriptions'"
                                placeholder="ITR Description" [isSelectAll]="false" propertyToShow="description" [setInput]="setITRDescriptionInput">
                            </app-multiple-items-selector>
                        </div>

                        <div class="radiobuttons-container margin-right15">
                                <mat-label class="title-radiobuttons">HVAC :&nbsp;
                                </mat-label>
                                <mat-radio-group formControlName="hvac" class="radiobuttons-group">
                                    <mat-radio-button class="radio-button-item" [value]="null"> Include </mat-radio-button>&nbsp;
                                    <mat-radio-button class="radio-button-item" [value]="false"> Exclude </mat-radio-button>&nbsp;
                                    <mat-radio-button class="radio-button-item" [value]="true"> Show Only HVAC </mat-radio-button>&nbsp;
                                </mat-radio-group>
                        </div>

                    </div>
                    <div class="mixedapp-container" *ngIf="skylinePhase != 'citr' && skylinePhase != 'rfo'">

                        <div class="container300 margin-right15" *ngIf="skylineType == 'plan'">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setConstructionAreaInput"
                                formControlName="constructionArea"
                                [searchFunc]="getConstructionArea"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'constructionArea'"
                                [placeholder]="'Construction Area'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <mat-form-field class="container300 margin-right15">
                            <mat-label>System Owner</mat-label>
                            <mat-select formControlName="systemOwner" multiple>
                                <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="container300 margin-right15">
                            <mat-label>SC Execution Area Manager</mat-label>
                            <mat-select formControlName="scManager" multiple>
                                <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                [setInput]="setWaypointInput"
                                formControlName="waypoints"
                                [searchFunc]="getWaypoints"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'waypoints'"
                                [propertyToShow]="'name'"
                                [placeholder]="'Waypoint'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name']"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                [setInput]="setMilestoneInput"
                                formControlName="milestones"
                                [searchFunc]="getMilestones"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'milestones'"
                                [propertyToShow]="'name'"
                                [placeholder]="'Milestone'"
                                [secondPropertyToShow]="'description'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name', 'description']"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div *ngIf="(skylinePhase === 'mc' && skylineType === 'forecast') || skylinePhase === 'mcwalkdown' || (skylinePhase === 'rfsu' && skylineType === 'forecast') || skylinePhase === 'rfsuwalkdown'" class="container300 margin-right15">
                            <mat-form-field>
                                <mat-label>Required for Steamblow</mat-label>
                                <mat-select
                                    placeholder="All"
                                    [formGroup]="filterForm"
                                    formControlName="requiredForSteamblow"
                                    aria-label="Required for Steamblow"
                                    disableOptionCentering
                                >
                                    <mat-option [value]="null">All</mat-option>
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <mat-form-field *ngIf="(skylinePhase === 'rfsu' && skylineType === 'plan') || (skylinePhase === 'rfsu' && skylineType === 'forecast') || skylinePhase === 'rfsuwalkdown'" class="container300 margin-right20">
                            <mat-label>Delivery Engineer</mat-label>
                                <mat-select formControlName="deliveryEngineer" multiple disableOptionCentering>
                                    <mat-option *ngFor="let de of deliveryEngineers" [value]="de.id"
                                        >{{ de.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                        <div class="lds-hourglass"></div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </form>
        <div class="buttons-container flex">
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
    </div>
    <div class="skyline-plan-container">
        <div class="subtitle-container">
            <h4>{{ skylineHeader.toLowerCase() }}</h4>
            <div class="options-container">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="dateRangePickerControlFormatted" />
                    <input
                        matInput
                        [matDatepicker]="dateRangePicker"
                        [matDatepickerFilter]="dateRangePickerFilter"
                        [formControl]="dateRangePickerControl"
                        hidden
                    />
                    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                    <mat-datepicker #dateRangePicker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="details-level-container">
                <label>Adjust view</label>
                <mat-icon (click)="setLevelOfDetails(3)" [ngClass]="{ active: (levelOfDetails$ | async) === 3 }">
                    <svg
                        width="22px"
                        height="22px"
                        viewBox="0 0 22 22"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <title>Wide view</title>
                        <desc>list 1</desc>
                        <g
                            id="Icons-/-small-/-list-off-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <g id="Group-5" stroke="#DBDCDD" stroke-width="2">
                                <rect
                                    id="Rectangle-10"
                                    x="1"
                                    y="8.61538462"
                                    width="9"
                                    height="4.76923077"
                                    rx="2"
                                ></rect>
                                <rect
                                    id="Rectangle-10"
                                    x="11"
                                    y="8.61538462"
                                    width="9"
                                    height="4.76923077"
                                    rx="2"
                                ></rect>
                                <rect id="Rectangle-10-Copy" x="1" y="1" width="9" height="4.76923077" rx="2"></rect>
                                <rect id="Rectangle-10-Copy" x="11" y="1" width="9" height="4.76923077" rx="2"></rect>
                                <rect
                                    id="Rectangle-10"
                                    x="1"
                                    y="16.2307692"
                                    width="9"
                                    height="4.76923077"
                                    rx="2"
                                ></rect>
                                <rect
                                    id="Rectangle-10"
                                    x="11"
                                    y="16.2307692"
                                    width="9"
                                    height="4.76923077"
                                    rx="2"
                                ></rect>
                            </g>
                        </g>
                    </svg>
                </mat-icon>
                <mat-icon (click)="setLevelOfDetails(0)" [ngClass]="{ active: (levelOfDetails$ | async) === 0 }">
                    <svg
                        width="22px"
                        height="22px"
                        viewBox="0 0 22 22"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <title>Low details view</title>
                        <desc>list 1</desc>
                        <g
                            id="Icons-/-small-/-list-off-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <g id="Group-5" stroke="#DBDCDD" stroke-width="2">
                                <rect
                                    id="Rectangle-10"
                                    x="1"
                                    y="8.61538462"
                                    width="20"
                                    height="4.76923077"
                                    rx="2"
                                ></rect>
                                <rect id="Rectangle-10-Copy" x="1" y="1" width="20" height="4.76923077" rx="2"></rect>
                                <rect
                                    id="Rectangle-10"
                                    x="1"
                                    y="16.2307692"
                                    width="20"
                                    height="4.76923077"
                                    rx="2"
                                ></rect>
                            </g>
                        </g>
                    </svg>
                </mat-icon>
                <mat-icon (click)="setLevelOfDetails(1)" [ngClass]="{ active: (levelOfDetails$ | async) === 1 }">
                    <svg
                        width="22px"
                        height="22px"
                        viewBox="0 0 22 22"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <title>Medium details view</title>
                        <desc>list 2</desc>
                        <g
                            id="Icons-/-small-/-list-off-2"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <g id="Group-6" stroke="#DBDCDD" stroke-width="2">
                                <rect id="Rectangle-10" x="1" y="1" width="20" height="7.30769231" rx="2"></rect>
                                <rect
                                    id="Rectangle-10"
                                    x="1"
                                    y="13.6923077"
                                    width="20"
                                    height="7.30769231"
                                    rx="2"
                                ></rect>
                            </g>
                        </g>
                    </svg>
                </mat-icon>
                <mat-icon (click)="setLevelOfDetails(2)" [ngClass]="{ active: (levelOfDetails$ | async) === 2 }">
                    <svg
                        width="22px"
                        height="22px"
                        viewBox="0 0 22 22"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <title>Detailed view</title>
                        <desc>list 3</desc>
                        <g
                            id="Icons-/-small-/-list-off-3"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <g id="Group-6" stroke="#DBDCDD" stroke-width="2">
                                <rect id="Rectangle-10-Copy-2" x="1" y="1" width="20" height="20" rx="2"></rect>
                            </g>
                        </g>
                    </svg>
                </mat-icon>
            </div>
        </div>
        <div class="weekschange-container">
            <div>
                <mat-icon (click)="changeWeekRange(-5)" class="weekschange">keyboard_double_arrow_left</mat-icon>
                <mat-icon (click)="changeWeekRange(-1)" class="weekschange">keyboard_arrow_left</mat-icon>
            </div>
            <div *ngIf="skylinePhase === 'rfsu' && (skylineType === 'plan' || skylineType === 'commitment' || skylineType === 'forecast')" class="toggles-container">
                <mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showWalkdownCommitted"
                    (change)="flagToggleUpdate($event, 'showWalkdownCommitted')"
                    class="margin-right20"
                    >Walkdown Committed</mat-slide-toggle
                ><mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showMcIncomplete"
                    (change)="flagToggleUpdate($event, 'showMcIncomplete')"
                    class="margin-right20"
                    >MC Incomplete</mat-slide-toggle
                >
                <mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showRfsuEdossierInitiated"
                    (change)="flagToggleUpdate($event, 'showRfsuEdossierInitiated')"
                    class="margin-right20"
                    >RFSU E-dossier Initiated</mat-slide-toggle
                >
                <mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showOpsAppPending"
                    (change)="flagToggleUpdate($event, 'showOpsAppPending')"
                    class="margin-right20"
                    >Ops Approval Outstanding</mat-slide-toggle
                >
                <mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showNonCommissionable"
                    (change)="flagToggleUpdate($event, 'showNonCommissionable')"
                    >Non Commissionable</mat-slide-toggle
                >
            </div>
            <div>
                <mat-icon (click)="changeWeekRange(1)" class="weekschange">keyboard_arrow_right</mat-icon>
                <mat-icon (click)="changeWeekRange(5)" class="weekschange"
                    >keyboard_double_arrow_right</mat-icon
                >
            </div>
        </div>
        <router-outlet></router-outlet>
        <div class="weekschange-container">
            <div>
                <mat-icon (click)="changeWeekRange(-5)" class="weekschange">keyboard_double_arrow_left</mat-icon>
                <mat-icon (click)="changeWeekRange(-1)" class="weekschange">keyboard_arrow_left</mat-icon>
            </div>
            <div *ngIf="skylinePhase === 'rfsu' && (skylineType === 'plan' || skylineType === 'commitment' || skylineType === 'forecast')" class="toggles-container">
                <mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showWalkdownCommitted"
                    (change)="flagToggleUpdate($event, 'showWalkdownCommitted')"
                    class="margin-right20"
                    >Walkdown Committed</mat-slide-toggle
                >
                <mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showMcIncomplete"
                    (change)="flagToggleUpdate($event, 'showMcIncomplete')"
                    class="margin-right20"
                    >MC Incomplete</mat-slide-toggle
                >
                <mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showRfsuEdossierInitiated"
                    (change)="flagToggleUpdate($event, 'showRfsuEdossierInitiated')"
                    class="margin-right20"
                    >RFSU E-dossier Initiated</mat-slide-toggle
                >
                <mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showOpsAppPending"
                    (change)="flagToggleUpdate($event, 'showOpsAppPending')"
                    class="margin-right20"
                    >Ops Approval Outstanding</mat-slide-toggle
                >
                <mat-slide-toggle
                    [checked]="(flagToggles$ | async)?.showNonCommissionable"
                    (change)="flagToggleUpdate($event, 'showNonCommissionable')"
                    >Non Commissionable</mat-slide-toggle
            >
            </div>
            <div>
                <mat-icon (click)="changeWeekRange(1)" class="weekschange">keyboard_arrow_right</mat-icon>
                <mat-icon (click)="changeWeekRange(5)" class="weekschange"
                    >keyboard_double_arrow_right</mat-icon
                >
            </div>
        </div>
        <app-skyline-legend [skylinePhase]="skylinePhase" [skylineType]="skylineType"></app-skyline-legend>
    </div>
</main>

<app-skyline-print (close)="onPrintWindowClose()" *ngIf="showPortal"></app-skyline-print>
