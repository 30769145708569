import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { of } from 'rxjs';
import { filter, take, takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/constants';
import { SubsystemScope, WeeklyPlanActivityType, WeeklyPlanType } from 'src/app/enums';
import { getCurrentWeekStartDate, getWeekEndDates } from 'src/app/extensions';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from 'src/app/modules/shared/components/comment-popup/comment-popup.component';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { ITRConstraintTypeSelectorPopupComponent } from 'src/app/components/detailed-status/constraint-type-selector/constraint-type-selector.component';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { ScheduleActivityPlanningConstraint } from 'src/app/components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import { CalendarColumn, CheckListColumn, OrderDirection, UserDetail } from 'src/app/store/common.model';
import { ITRConstraintDTO } from 'src/app/models/itr-constraint';
import { ApplicationState } from 'src/app/store/model';
import {
    ScheduleActivityITRAddCommentRequest,
    ScheduleActivityITRDataRequest,
    ScheduleActivityITRExportToExcelRequest,
    ScheduleActivityITRFilterPropertyUpdate,
    ScheduleActivityITRSaveSelectedITRIdsRequest,
} from 'src/app/store/schedule-activity-itr/actions';
import {
    DetailedStatusFilterPropertyUpdate,
    DetailedStatusFilterReset,
    DetailedStatusResetLockedFilter,
    DetailedStatusSetMakeRequest,
    DetailedStatusSetTableOffset,
} from 'src/app/store/detailed-status/actions';
import { getDetailedStatusFilterInstance, getNextWeekStartDate } from 'src/app/extensions';
import {
    ScheduleActivityITRDTO,
    ScheduleActivityITRFilter,
    ScheduleActivityITRScope,
    ShowHideColumns,
} from 'src/app/store/schedule-activity-itr/model';
import {
    ScheduleActivityClearFilters,
    ScheduleActivityPlanningFilterPropertyUpdate,
} from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import { ScheduleActivityPlanningFilter } from 'src/app/store/weekly-planning/schedule-activity-planning/model';
import { ComponentCanDeactivate } from 'src/guards/pending-changes.guard';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-schedule-activity-itr',
    templateUrl: './schedule-activity-itr.component.html',
    styleUrls: ['./schedule-activity-itr.component.scss'],
})
export class ScheduleActivityItrComponent extends BaseComponent implements OnInit, ComponentCanDeactivate {
    @Input() showExportButton: boolean = true;
    scope: ScheduleActivityITRScope;
    filterForm: UntypedFormGroup;
    isLoading = false;
    resultsLength = 0;
    subsystem: string;
    data: ScheduleActivityITRDTO[] = [];
    itrScopes = ScheduleActivityITRScope;
    isReadOnly = false;
    weeklyPlanType: WeeklyPlanType;
    weeklyPlanTypes = WeeklyPlanType;
    weekStart: moment.Moment;
    weekEnd:moment.Moment;
    schedulePlanFilter: ScheduleActivityPlanningFilter;
    get isNextWorkingWeek(): boolean {
        return false;
    }

    @ViewChild('activityActionPopover', { static: false }) popover: any;

    columnITRSubsystems: CheckListColumn = null;
    columnITRSubsystems$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRSubsystems
    );

    columnITRSubsystemNames: CheckListColumn = null;
    columnITRSubsystemNames$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRSubsystemNames
    );

    columnITRActivityNames: CheckListColumn = null;
    columnITRActivityNames$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRActivityNames
    );

    columnITRTagNumbers: CheckListColumn = null;
    columnITRTagNumbers$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRTagNumbers
    );

    columnIsItrInCurrentWeek: CheckListColumn = null;
    columnIsItrInCurrentWeek$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnIsItrInCurrentWeek
    );

    columnITRTagTypes: CheckListColumn = null;
    columnITRTagTypes$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRTagTypes
    );

    columnITRDisciplines: CheckListColumn = null;
    columnITRDisciplines$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRDisciplines
    );

    columnITRNumbers: CheckListColumn = null;
    columnITRNumbers$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRNumbers
    );

    columnITRDescriptions: CheckListColumn = null;
    columnITRDescriptions$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRDescriptions
    );

    columnITRContractors: CheckListColumn = null;
    columnITRContractors$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRContractors
    );

    columnITRDocTypes: CheckListColumn = null;
    columnITRDocTypes$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRDocTypes
    );

    columnITRStatuses: CheckListColumn = null;
    columnITRStatuses$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRStatuses
    );

    columnITRBarcodes: CheckListColumn = null;
    columnITRBarcodes$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRBarcodes
    );

    columnStagedStartUpPriority: CheckListColumn = null;
    columnStagedStartUpPriority$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnStagedStartUpPriority
    );

    columnManHour: CheckListColumn = null;
    columnManHour$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnManHour
    );

    columnITRAdditionToPlan: CheckListColumn = null;
    columnITRAdditionToPlan$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRAdditionToPlan
    );

    columnITRComments: CheckListColumn = null;
    columnITRComments$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRComments
    );

    columnITRStatusDate: CalendarColumn = null;
    columnITRStatusDate$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRStatusDate
    );

    columnITRPreCommStart: CalendarColumn = null;
    columnITRPreCommStart$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRPreCommStart
    );

    columnITRPreCommFinish: CalendarColumn = null;
    columnITRPreCommFinish$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRPreCommFinish
    );

    columnITRActivityIds: CheckListColumn = null;
    columnITRActivityIds$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRActivityIds
    );

    columnITRConstraintFlags: CheckListColumn = null;
    columnITRConstraintFlags$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRConstraintFlags
    );

    columnITRWorkAreas: CheckListColumn = null;
    columnITRWorkAreas$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnITRWorkAreas
    );

    columnConstraintType: CheckListColumn = null;
    columnConstraintType$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnConstraintType
    );

    scheduleActivityITRGridData$ = this.store.select((state) => state.scheduleActivityITRState.dataPagination);
    scheduleActivityITRGridLoader$ = this.store.select((state) => state.scheduleActivityITRState.isLoading);

    scheduleActivityITRFilter$ = this.store.select((state) => state.scheduleActivityITRState.filter);

    weeklyPlanType$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weeklyPlanType
    );

    weeklyPlanFilter$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter
    );

    weekStart$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekStart
    );

    weekEnd$ = this.store.select(
        (state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekEnd
    );

    scope$ = this.store.select((state) => state.scheduleActivityITRState.filter.scope);

    weeklyPlanningITRsDetails$ = this.store
        .select((state) => [
            state.scheduleActivityITRState.weeklyPlanITRsToGo,
            state.scheduleActivityITRState.weeklyPlanOccupiedITRs,
        ])
        .pipe(filter((details) => Array.isArray(details[0]) && Array.isArray(details[1])));

    weeklyPlanSelectedITRs$ = this.store.select((state) => state.scheduleActivityITRState.weeklyPlanSelectedITRs);

    direction$ = this.store.select((state) => state.scheduleActivityITRState.filter.direction);
    sortBy$ = this.store.select((state) => state.scheduleActivityITRState.filter.sortBy);

    showColumns = new ShowHideColumns();
    sortBy = 'qvdNumber';
    pageSize = 25;
    activityId?: string;
    saveWeeklyPlan = false;
    weeklyPlanITRIds: number[] = [];
    weeklyPlanSelection: SelectionModel<number> = null;
    initialySelectedITRs: number[] = [];
    weeklyPlanDisabled: number[] = [];
    activityDiscipline?: string;
    additionToPlan = false;
    activityType = WeeklyPlanActivityType.inWeek;
    direction: OrderDirection = OrderDirection.Desc;
    localStorageResetFilter: boolean = false;

    displayedColumns = [
        'addConstraint',
        'subsystem',
        'subsystemName',
        'tagNumber',
        'tagType',
        'activityId',
        'activityName',
        'barcode',
        'manHour',
        'discipline',
        'number',
        'additionToPlan',
        'description',
        'itrConstraintTypeName',
        'constraintFlag',
        'preCommStart',
        'preCommFinish',
        'contractor',
        'status',
        'statusDate',        
        'docType',
        'workAreaName',
        'stagedStartUpPriority',                
        'itrConstraintResponsibleUser',
        'itrConstraintDueDate',
        'itrConstraintRaisedByUser',
        'itrConstraintRaisedDate',
        'latestComment'
        
    ];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private store: Store<ApplicationState>,
        private formSvc: FormService,
        private ngZone: NgZone,
        private popupSvc: PopupService,
        private router: Router,
        private roleService: RoleService,
        private scheduleActivityLookupService: ScheduleActivityLookupService
    ) {
        super();

        this.filterForm = this.formSvc.createSimpleForm(new ScheduleActivityITRFilter());
    }

    ngOnInit(): void {
        this.updateFiltersFromLocalStorage();

        this.isReadOnly = this.roleService.isReadOnly();

        this.columnITRSubsystems$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRSubsystems = data));
        this.columnITRSubsystemNames$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRSubsystemNames = data));
        this.columnITRActivityNames$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRActivityNames = data));
        this.columnITRTagNumbers$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRTagNumbers = data));
        this.columnITRTagTypes$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRTagTypes = data));
        this.columnITRDisciplines$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRDisciplines = data));
        this.columnITRNumbers$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnITRNumbers = data));
        this.columnITRDescriptions$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRDescriptions = data));
        this.columnITRContractors$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRContractors = data));
        this.columnITRDocTypes$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRDocTypes = data));
        this.columnITRStatuses$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRStatuses = data));
        this.columnITRBarcodes$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRBarcodes = data));
        this.columnStagedStartUpPriority$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnStagedStartUpPriority = data));
        this.columnManHour$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnManHour = data));
        this.columnITRComments$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRComments = data));
        this.columnITRStatusDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRStatusDate = data));
        this.columnITRPreCommStart$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRPreCommStart = data));
        this.columnITRPreCommFinish$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRPreCommFinish = data));
        this.columnITRActivityIds$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRActivityIds = data));
        this.columnITRConstraintFlags$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRConstraintFlags = data));
        this.columnITRAdditionToPlan$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRAdditionToPlan = data));
        this.columnITRWorkAreas$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRWorkAreas = data));
        this.columnConstraintType$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnConstraintType = data));

        this.weeklyPlanType$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.weeklyPlanType = data));
        this.weeklyPlanFilter$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((filter) => (this.schedulePlanFilter = filter));

        this.scheduleActivityITRGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.scheduleActivityITRGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.isLoading = data;
        });

        this.weeklyPlanningITRsDetails$.pipe(takeWhile(() => this.isAlive)).subscribe((details) => {
            this.weeklyPlanITRIds = details[0];
            this.weeklyPlanDisabled = details[1] || [];
        });

        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => (this.direction = direction));
        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => (this.sortBy = sortBy));
        this.scope$.pipe(takeWhile(() => this.isAlive)).subscribe((scope) => (this.scope = scope));

        this.scheduleActivityITRFilter$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((filter: ScheduleActivityITRFilter) => {
                this.activityDiscipline = filter.activityDiscipline;
                this.activityId = filter.activityId;
                this.additionToPlan = filter.additionToPlan;
                this.direction = filter.direction;
                this.pageSize = filter.page;
                this.saveWeeklyPlan = filter.saveWeeklyPlan;
                this.scope = filter.scope;
                this.showColumns = filter.showHideITRColumns;
                this.sortBy = filter.sortBy;
                this.pageSize = filter.take;
                this.activityType = filter.activityType;
            });

        this.sortBy = 'qvdNumber';
        this.store.dispatch(
            new ScheduleActivityITRFilterPropertyUpdate({ page: 0, take: this.pageSize } as ScheduleActivityITRFilter)
        );


        this.weekStart$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.weekStart = moment(data)));
        this.weekEnd$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.weekEnd = moment(data)));
        this.search();
        this.weeklyPlanSelectedITRs$
            .pipe(
                filter((selectedITRs) => !!selectedITRs),
                take(1)
            )
            .subscribe((selectedITRs) => {
                this.initialySelectedITRs = selectedITRs || [];
                this.weeklyPlanSelection = new SelectionModel(true, selectedITRs);
            });
    }

    @HostListener('window:beforeunload')
    canDeactivate() {
        return _.isEqual(this.initialySelectedITRs, this.weeklyPlanSelection?.selected || []);
    }

    isWeeklyPlanITRDisabled(id: number) {
        return this.weeklyPlanDisabled.includes(id);
    }

    search() {
        this.store.dispatch(new ScheduleActivityITRDataRequest());
    }

    showHideColumns(initWidth: number) {
        return {
            left: `${initWidth - +!this.showColumns.showSubsys * 190 - +!this.showColumns.showSubsysName * 115}px`,
        };
    }

    reverseValue(columnName: string) {
        this.showColumns = {
            ...this.showColumns,
            [columnName]: !this.showColumns[columnName],
        };
        this.store.dispatch(
            new ScheduleActivityITRFilterPropertyUpdate({
                showHideITRColumns: this.showColumns,
            } as ScheduleActivityITRFilter)
        );
    }

    exportToExcel() {
        this.store.dispatch(
            new ScheduleActivityITRExportToExcelRequest({
                scope: this.scope,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                sortBy: this.sortBy,
            })
        );
    }

    

    openDetailsPage(row: any) {
        var selection = window.getSelection();
        if (selection.toString().length === 0) {
            this.ngZone.run(() =>
                this.router.navigate([`/details`], {
                    state: { data: { row, bySubsystem: true } },
                    queryParams: {
                        scope: SubsystemScope[row.scope],
                        id: row.id,
                    },
                })
            );
        }
    }
    //open detailedstatus 
    openStatusDetailsPage(row: any, event: any) {
        var selection = window.getSelection();
        if (selection.toString().length === 0) {
            const url = this.router.serializeUrl(
                this.router.createUrlTree([`/detailedstatus`],                     
                {queryParams: {
                    scope: 'ConstraintDetails',
                    id: row.id,
                }})
              );
              window.open(url, '_blank');
              event.stopPropagation();
        }
    }

 

    updateIsConstrained(event: any) {
        const changeTo = true;
        event.stopPropagation();

        if (changeTo) {
            this.popupSvc
                .openPopup(new PopupSettings(ITRConstraintTypeSelectorPopupComponent, 450, 300, {}))
                .afterClosed()
                .subscribe((dialogResult) => {                   
                    if (_.has(dialogResult, 'constraintType')) {
                        // This is intentional
                    }
                });
            return;
        }


        event.stopPropagation();
    }


    openCommentPopup(element: ScheduleActivityITRDTO, event: any) {
        const comment = element.comments || '';
        if (this.isReadOnly) return;

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: element.mentions,
                filteredCount: 0,
                url: `details?scope=${SubsystemScope[element.scope]}&id=${element.id}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    this.store.dispatch(
                        new ScheduleActivityITRAddCommentRequest({
                            entityId: element.id.toString(),
                            description: value,
                            scope: element.scope,
                            mentions,
                        })
                    );
                },
            })
        );

        event.stopPropagation();
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new ScheduleActivityITRFilterPropertyUpdate({ take: pageEvent.pageSize } as ScheduleActivityITRFilter)
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new ScheduleActivityITRFilterPropertyUpdate({ page: pageEvent.pageIndex } as ScheduleActivityITRFilter)
            );
        }
        this.store.dispatch(new ScheduleActivityITRDataRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    isWeeklyPlanAllSelected() {
        return (
            this.weeklyPlanSelection.selected.length ===
            this.weeklyPlanITRIds.filter((wp) => !this.weeklyPlanDisabled.includes(wp)).length
        );
    }

    weeklyPlanMasterToggle() {
        this.isWeeklyPlanAllSelected()
            ? this.weeklyPlanSelection.clear()
            : this.weeklyPlanITRIds.forEach((row) => {
                  if (!this.weeklyPlanDisabled.includes(row)) {
                      this.weeklyPlanSelection.select(row);
                  }
              });
    }

    saveWeeklyPlanSelectedITRIds() {
        this.store.dispatch(
            new ScheduleActivityITRSaveSelectedITRIdsRequest({
                activityId: this.activityId,
                selectedIds: this.weeklyPlanSelection.selected,
                activityDiscipline: this.activityDiscipline,
                additionToPlan: this.additionToPlan,
            })
        );
        this.initialySelectedITRs = this.weeklyPlanSelection.selected;
    }

    getGoBackRouterLink() {
        return `/weeklyplanning/lookahead/${this.weeklyPlanTypes[this.weeklyPlanType]}`;
    }

    getTitle() {
        return this.scope === ScheduleActivityITRScope.ToGo
            ? 'ITR To Go'
            : this.scope === ScheduleActivityITRScope.InWeek
            ? 'ITRs In Period'
            : this.scope === ScheduleActivityITRScope.Committed
            ? 'ITR Commited'
            : this.scope === ScheduleActivityITRScope.CommittedAchieved
            ? 'ITR Commited & Achieved'
            : this.scope === ScheduleActivityITRScope.CommittedNotAchieved
            ? 'ITR Commited but not Achieved'
            : 'ITR Achieved but not Committed';
    }

    getGoBackTitle() {
        return this.weeklyPlanType === WeeklyPlanType.weekly ? 'Weekly Plan' : '4-week lookahead';
    }

    get currentlySetWeekEnd() {
        return moment(this.weekStart).add(6, 'day').toDate() ?? '';
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        isSortingOff: boolean = false,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(
                HeaderChecklistFilterComponent,
                columnName === 'description' ? 800 : 400,
                650,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    placeHolder: placeholder,
                    searchFunc: searchFunc,
                    selectedItems: selected ? [...selected.items] : [],
                    excludeBlanks: excludeBlanks,
                    onlyBlanks: onlyBlanks,
                    isSortingOff: isSortingOff,
                    showCounts,
                    showInputSearch: showInputSearch,
                    showBlankOptions: showBlankOptions,
                    action: (data) => {
                        let value = new CheckListColumn();
                        value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                        value.excludeBlanks = data.excludeBlanks;
                        value.onlyBlanks = data.onlyBlanks;
                        this.store.dispatch(
                            new ScheduleActivityPlanningFilterPropertyUpdate({
                                filter: {
                                    ...this.schedulePlanFilter,
                                    [propertyName]: value,
                                } as ScheduleActivityPlanningFilter,
                            })
                        );

                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                        this.search();
                    },
                    resetColumnAction: () => {
                        this.store.dispatch(
                            new ScheduleActivityPlanningFilterPropertyUpdate({
                                filter: {
                                    ...this.schedulePlanFilter,
                                    [propertyName]: null,
                                } as ScheduleActivityPlanningFilter,
                            })
                        );
                    },
                }
            )
        );
    }

    openHeaderDateFilter(propertyName: string, placeholder: string, calendarColumn: CalendarColumn) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(
                HeaderDateFilterComponent,
                null,
                null,
                {
                    isAscendingSort: false,
                    isDescendingSort: false,
                    calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                    placeHolder: placeholder,
                    isSortingOff: true,
                    action: (data) => {
                        this.store.dispatch(
                            new ScheduleActivityPlanningFilterPropertyUpdate({
                                filter: {
                                    ...this.schedulePlanFilter,
                                    [propertyName]: data.calendarColumn,
                                } as ScheduleActivityPlanningFilter,
                            })
                        );
                        this.search();
                    },
                },
                345,
                580
            )
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new ScheduleActivityITRFilterPropertyUpdate({
                    sortBy: columnName,
                    direction: direction,
                } as ScheduleActivityITRFilter)
            );
        } else {
            this.store.dispatch(
                new ScheduleActivityITRFilterPropertyUpdate({
                    sortBy: 'qvdNumber',
                    direction: OrderDirection.Desc,
                } as ScheduleActivityITRFilter)
            );
        }
    }

    getSubsystems = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchSubsystemsForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getSubsystemNames = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchSubsystemNamesForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getActivityNames = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchActivityNamesForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getTagNumbers = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchTagNumbersForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getTagTypes = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchTagTypesForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getConstraintFlags = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchConstraintFlagForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getDisciplines = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchDisciplinesForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getITRNumbers = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchITRNumbersForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                skipActivityDatesValidation: true,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getItrInCurrentWeek = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchItrInCurrentWeek(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                skipActivityDatesValidation: true,
            },
            this.scope
        );
    };

    getITRDescriptions = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchITRDescriptionsForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getITRAdditionToPlan = () => {
        return of(['Yes', 'No']);
    };

    getContractors = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchContractorsForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getDocTypes = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchDocTypesForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getStatuses = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchStatusesForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getBarcodes = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchBarcodesForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getComments = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchCommentsForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getActivityIds = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchActivityIdsForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getWorkAreas = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchWorkAreasForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    getConstraintTypes = (search = '', take = 30, page = 0) => {
        return this.scheduleActivityLookupService.searchConstraintTypesForITRFilter(
            search,
            take,
            page,
            {
                ...this.schedulePlanFilter,
                activityId: this.activityId,
                activityDiscipline: this.activityDiscipline,
                activityType: this.activityType,
            },
            this.scope
        );
    };

    displayAdditionToPlan(additionToPlan: boolean) {
        return additionToPlan ? 'Yes' : 'No';
    }

    private updateFiltersFromLocalStorage(): void {
        let scheduleActivityItrFilter = localStorage.getItem('scheduleActivityItrFilter');

        if (!scheduleActivityItrFilter) {
            this.store
                .select(
                    (store) =>
                        store.scheduleActivityPlanningState.scheduleActivityPlanningFilter
                            .itrCommittmentReliabilityScope
                )
                .subscribe((result) => {
                    if (result) {
                        this.store.dispatch(new ScheduleActivityClearFilters());
                        this.store.dispatch(
                            new ScheduleActivityPlanningFilterPropertyUpdate({
                                filter: { weekStart: getCurrentWeekStartDate(), weekEnd:getWeekEndDates() } as ScheduleActivityPlanningFilter,
                            })
                        );
                    }
                });
            this.localStorageResetFilter = false;

            return;
        }

        localStorage.removeItem('scheduleActivityItrFilter');
        let filterLocalState = JSON.parse(scheduleActivityItrFilter, reviever) as ScheduleActivityPlanningFilter;
        this.store.dispatch(new ScheduleActivityPlanningFilterPropertyUpdate({ filter: filterLocalState }));
        this.scope = filterLocalState.itrCommittmentReliabilityScope;
        this.store.dispatch(
            new ScheduleActivityITRFilterPropertyUpdate({
                scope: filterLocalState.itrCommittmentReliabilityScope,
            } as ScheduleActivityITRFilter)
        );
        this.localStorageResetFilter = true;
    }
}

let reviever = function (key, value) {
    if (!moment(this[key], moment.ISO_8601, true).isValid()) {
        return value;
    }
    return moment(value);
};


