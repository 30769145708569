import { ForecastHistoryActions, ForecastHistoryActionTypes } from "./actions";
import { ForecastHistoryState } from "./model";

const initialState: ForecastHistoryState = {
    data: [],
    isLoading: false,
};

export function reducer(state: ForecastHistoryState = initialState, action: ForecastHistoryActions): ForecastHistoryState {
    switch (action.type) {
        case ForecastHistoryActionTypes.ForecastDateHistoryRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ForecastHistoryActionTypes.ExceptionForecastDateHistoryRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ForecastHistoryActionTypes.ForecastDateHistorySuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        }
        case ForecastHistoryActionTypes.ForecastDateHistoryError: {
            return {
                ...state,
                data: [],
                isLoading: false
            };
        }
        default: {
            return state;
        }
    }
}