import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { catchError, map, take, takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Contractor, Gooc, OrderDirection, RFO, StagedStartUpPriority, TCOUser } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    SubsystemMCDashboardColumnSummary,
    SubsystemMCDashboardPLIColumnSummary,
} from 'src/app/store/reports/subsystem-mc/model';
import {
    SubsystemRFSUDasbboardRedirectToDetailedStatus,
    SubsystemRFSUDashboardExportToExcelRequest,
    SubsystemRFSUDashboardRequest,
    SubsystemRFSUDashboardUpdateFilterProperties,
} from 'src/app/store/reports/subsystem-rfsu/actions';
import { SubsystemRFSUDashboardDTO, SubsystemRFSUDashboardFilter } from 'src/app/store/reports/subsystem-rfsu/model';
import { forkJoin } from 'rxjs';
import { DetailedStatusSetMakeRequest } from 'src/app/store/detailed-status/actions';
import { SubsystemScope } from 'src/app/enums';
import { ChangeManagementFilterReset, ChangeManagementSetMakeRequest } from 'src/app/store/change-management/actions';
import { Router } from '@angular/router';

@Component({
    selector: 'app-subsystem-rfsu-dashboard',
    templateUrl: './subsystem-rfsu-dashboard.component.html',
    styleUrls: ['./subsystem-rfsu-dashboard.component.scss'],
})
export class SubsystemRfsuDashboardComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRfosInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    rfosAutocompleteDisplayedColumns = ['rfoName', 'name'];
    stagedStartUpPrioritiesAutocompleteColumns = ['priorityName', 'priority'];
    displayedColumns = [
        'subsystemNo',
        'subsystemName',
        'rfsuPlanDate',
        'rfsuForecast',
        'rfsuActualDate',
        'rfsuWalkdownActualDate',
        'aqvd',
        'bitr',
        'citr',
        'redlines',
        'pli',
        'npw',
    ];
    data: SubsystemRFSUDashboardDTO[] = [];
    pageSize = 10;
    selectedPageSize = 10;
    resultsLength = 0;
    isPaginatorVisible = true;
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    isUsersPerProjectLoading = false;
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    contractors: Contractor[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    scrollSearch = false;
    isLoading$ = this.store.select((state) => state.subsystemRFSUDashboardState.isLoading);
    items$ = this.store.select((state) => state.subsystemRFSUDashboardState.items);
    filter$ = this.store.select((state) => state.subsystemRFSUDashboardState.filter);
    totalCount$ = this.store.select((state) => state.subsystemRFSUDashboardState.totalCount);
    sortBy = 'subsystemNo';
    direction = OrderDirection.Desc;

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private router: Router
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new SubsystemRFSUDashboardFilter());
    }

    private areAnyFiltersApplied() {
        const {
            contractors,
            goocs,
            rfos,
            mcEngineer,
            systemOwner,
            stagedStartUpPriorities,
            scManagers,
        } = this.filterForm.value;
        return Boolean(
            contractors.length +
                goocs.length +
                rfos.length +
                mcEngineer.length +
                systemOwner.length +
                stagedStartUpPriorities.length +
                (scManagers && scManagers.length)
        );
    }

    ngOnInit(): void {
        this.filterExpansionPanel.expanded = true;

        this.items$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            if (!items) {
                this.filterSubsystemRFSUData();
                return;
            }
            this.data = items;
        });

        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.direction = filter.direction;
            this.sortBy = filter.sortBy;
            this.scrollSearch = filter.scrollSearch;
            if (!this.scrollSearch) {
                this.paginator.pageIndex = filter.page;
                this.pageSize = filter.take;
            }
        });

        this.totalCount$.pipe(takeWhile(() => this.isAlive)).subscribe((totalCount) => {
            this.resultsLength = totalCount;
            if (this.areAnyFiltersApplied()) {
                this.pageSize = totalCount;
                this.addTotalRecord();
            }
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.filterForm.controls.sortBy.setValue(sortChange.active);
            this.filterForm.controls.direction.setValue(sortChange.direction);
            this.store.dispatch(
                new SubsystemRFSUDashboardUpdateFilterProperties({
                    direction: this.filterForm.value.direction,
                    sortBy: this.filterForm.value.sortBy,
                })
            );
            this.filterSubsystemRFSUData();
        });

        forkJoin([
            this.lookupService.getMCEngineers([]),
            this.lookupService.getSysOwners([]),
            this.lookupService.getSCManagers([]),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error(
                        'Error occurred while getting MC Engineers or System Owners. Please contact Program Administrator.'
                    );
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners, scManagers]) => {
                    this.mcEngineers = mcEngineers;
                    this.sysOwners = sysOwners;
                    this.scManagers = scManagers;
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    private filterSubsystemRFSUData() {
        this.store.dispatch(new SubsystemRFSUDashboardRequest());
    }

    addTotalRecord() {
        const lastRecord = _.last(this.data);
        if (lastRecord && !lastRecord.subsystemNo) {
            return;
        }

        const totalRecord = new SubsystemRFSUDashboardDTO();
        totalRecord.aqvd = new SubsystemMCDashboardColumnSummary();
        totalRecord.aqvd.total = _.sum(this.data.map((s) => s.aqvd.total));
        totalRecord.aqvd.remaining = _.sum(this.data.map((s) => s.aqvd.remaining));
        totalRecord.aqvd.complete = _.sum(this.data.map((s) => s.aqvd.complete));
        totalRecord.aqvd.exceptions = _.sum(this.data.map((s) => s.aqvd.exceptions));
        totalRecord.aqvd.percentComplete = (totalRecord.aqvd.total === 0
            ? 100
            : Math.round((totalRecord.aqvd.complete / totalRecord.aqvd.total + Number.EPSILON) * 100 * 100) / 100
        )
            .toFixed(2)
            .toString();
        totalRecord.pli = new SubsystemMCDashboardPLIColumnSummary();
        totalRecord.pli.apliOpen = _.sum(this.data.map((s) => s.pli.apliOpen));
        totalRecord.pli.bpliOpen = _.sum(this.data.map((s) => s.pli.bpliOpen));
        totalRecord.pli.cpliOpen = _.sum(this.data.map((s) => s.pli.cpliOpen));
        totalRecord.pli.dpliOpen = _.sum(this.data.map((s) => s.pli.dpliOpen));
        totalRecord.redlines = new SubsystemMCDashboardColumnSummary();
        totalRecord.redlines.total = _.sum(this.data.map((s) => s.redlines.total));
        totalRecord.redlines.remaining = _.sum(this.data.map((s) => s.redlines.remaining));
        totalRecord.redlines.complete = _.sum(this.data.map((s) => s.redlines.complete));
        totalRecord.redlines.percentComplete = (totalRecord.redlines.total === 0
            ? 100
            : Math.round((totalRecord.redlines.complete / totalRecord.redlines.total + Number.EPSILON) * 100 * 100) /
              100
        )
            .toFixed(2)
            .toString();

        totalRecord.npw = new SubsystemMCDashboardColumnSummary();
        totalRecord.npw.total = _.sum(this.data.map((s) => s.npw.total));
        totalRecord.npw.remaining = _.sum(this.data.map((s) => s.npw.remaining));
        totalRecord.npw.complete = _.sum(this.data.map((s) => s.npw.complete));
        totalRecord.npw.percentComplete = (totalRecord.npw.total === 0
            ? 100
            : Math.round((totalRecord.npw.complete / totalRecord.npw.total + Number.EPSILON) * 100 * 100) / 100
        )
            .toFixed(2)
            .toString();

        totalRecord.bitr = new SubsystemMCDashboardColumnSummary();
        totalRecord.bitr.total = _.sum(this.data.map((s) => s.bitr.total));
        totalRecord.bitr.remaining = _.sum(this.data.map((s) => s.bitr.remaining));
        totalRecord.bitr.complete = _.sum(this.data.map((s) => s.bitr.complete));
        totalRecord.bitr.percentComplete = (totalRecord.bitr.total === 0
            ? 100
            : Math.round((totalRecord.bitr.complete / totalRecord.bitr.total + Number.EPSILON) * 100 * 100) / 100
        )
            .toFixed(2)
            .toString();

        totalRecord.citr = new SubsystemMCDashboardColumnSummary();
        totalRecord.citr.total = _.sum(this.data.map((s) => s.citr.total));
        totalRecord.citr.remaining = _.sum(this.data.map((s) => s.citr.remaining));
        totalRecord.citr.complete = _.sum(this.data.map((s) => s.citr.complete));
        totalRecord.citr.percentComplete = (totalRecord.citr.total === 0
            ? 100
            : Math.round((totalRecord.citr.complete / totalRecord.citr.total + Number.EPSILON) * 100 * 100) / 100
        )
            .toFixed(2)
            .toString();

        this.data.push(totalRecord);
    }

    displayMultipleSelected(values: Contractor[], property: string = 'name') {
        return values.map((x) => x[property]).join(', ');
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onStagedStartUpPrioritiesClosed() {
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(true));
    }

    getContractors = (search = '', takeCount = 10, page = 0) => {
        return this.lookupService
            .searchMSLContractors(search, takeCount, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getStagedStartUpPriorities = (search = '', takeCount = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, takeCount, page, this.filterForm.value.projectTeamNames)
            .pipe(map((priorities: StagedStartUpPriority[]) => priorities));
    };

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    applySort(property: string) {
        if (this.sortBy !== property) {
            this.direction = OrderDirection.Desc;
        } else {
            this.direction = this.direction === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc;
        }
        this.sortBy = property;
        this.filterForm.controls.sortBy.setValue(property);
        this.filterForm.controls.direction.setValue(this.direction);
        this.store.dispatch(
            new SubsystemRFSUDashboardUpdateFilterProperties({
                direction: this.filterForm.value.direction,
                sortBy: this.filterForm.value.sortBy,
            })
        );
        this.filterSubsystemRFSUData();
    }

    getGoocs = (search = '', takeCount = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, takeCount, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getRfos = (search = '', takeCount = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, takeCount, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    resetFilters() {
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setRfosInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.mcEngineer.setValue([]);
        this.filterForm.controls.systemOwner.setValue([]);
        this.filterForm.controls.scManager.setValue([]);
        this.store.dispatch(
            new SubsystemRFSUDashboardUpdateFilterProperties({
                goocs: [],
                rfos: [],
                contractors: [],
                mcEngineer: [],
                systemOwner: [],
            })
        );
    }

    search() {
        this.scrollSearch = this.areAnyFiltersApplied();
        if (!this.areAnyFiltersApplied()) {
            this.pageSize = this.selectedPageSize;
        }
        this.paginator.pageIndex = 0;
        this.updateFilterStore();
        this.filterSubsystemRFSUData();
    }

    updateFilterStore() {
        this.store.dispatch(
            new SubsystemRFSUDashboardUpdateFilterProperties({
                goocs: this.filterForm.value.goocs,
                rfos: this.filterForm.value.rfos,
                contractors: this.filterForm.value.contractors,
                systemOwner: this.filterForm.value.systemOwner,
                mcEngineer: this.filterForm.value.mcEngineer,
                scManager: this.filterForm.value.scManager,
                stagedStartUpPriorities: this.filterForm.value.stagedStartUpPriorities,
                page: this.paginator.pageIndex,
                take: this.pageSize,
                direction: this.filterForm.value.direction,
                sortBy: this.filterForm.value.sortBy,
                scrollSearch: this.scrollSearch,
            })
        );
    }

    onPaginatorChange(pageEvent: PageEvent) {
        this.pageSize = pageEvent.pageSize;
        this.selectedPageSize = pageEvent.pageSize;
        this.store.dispatch(
            new SubsystemRFSUDashboardUpdateFilterProperties({
                page: pageEvent.pageIndex,
                take: pageEvent.pageSize,
            })
        );
        this.filterSubsystemRFSUData();
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        const pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    exportToExcel() {
        this.updateFilterStore();
        this.store.dispatch(new SubsystemRFSUDashboardExportToExcelRequest());
    }

    openDetailedStatusPage(subsystem: string, scope: string, category?: string, zone?: string, status?: string[]) {
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        this.store.dispatch(
            new SubsystemRFSUDasbboardRedirectToDetailedStatus({
                subsystem,
                scope,
                exceptions: SubsystemScope[scope] === SubsystemScope.EX,
                category,
                zone,
                status,
            })
        );
    }

    openChangeManagementPage(subsystem: string, changeType: string, lockFilters = true) {
        const goocs = this.filterForm.controls.goocs.value.map((g) => g.no);
        const rfos = this.filterForm.controls.rfos.value.map((r) => r.no);
        const mcEngineers = this.filterForm.controls.mcEngineer.value;
        const systemOwners = this.filterForm.controls.systemOwner.value;
        const contractors = this.filterForm.controls.contractors.value.map((m) => m.name);
        this.store.dispatch(new ChangeManagementSetMakeRequest());
        this.store.dispatch(new ChangeManagementFilterReset());
        this.router.navigate(['/changemanagement'], {
            queryParams: { subsystem, changeType, lockFilters, goocs, rfos, mcEngineers, systemOwners, contractors },
        });
    }
}
