import { Injectable } from '@angular/core';
import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    CriticalRfiActionTypes,
    CriticalRfiFilterSuccess,
    CriticalRfiFilterError,
    CriticalRfiAddCommentRequest,
    CriticalRfiAddCommentSuccess,
    CriticalRfiAddCommentError,
    CriticalRfiExportToExcelSuccess,
    CriticalRfiExportToExcelError,
    CriticalRfiAddBulkCommentRequest,
    CriticalRfiAddBulkCommentSuccess,
    CriticalRfiAddBulkCommentError,
    CriticalRfiDeleteCommentRequest,
    CriticalRfiDeleteCommentSuccess,
    CriticalRfiDeleteCommentError,
    CriticalRfiFilterRequest,
    CriticalRfiUpdateFieldImplementationContractorRequest,
    CriticalRfiFieldImplementationContractorError,
    CriticalRfiFieldImplementationContractorSuccess,
    CriticalRfiUpdateSubPriorityRequest,
    CriticalRfiUpdateSubPrioritySuccess,
    CriticalRfiUpdateSubPriorityError,
    CriticalRfiUpdateManualPriorityRequest,
    CriticalRfiUpdateManualPrioritySuccess,
    CriticalRfiUpdateManualPriorityError,
    CriticalRfiAddExpectedClosureDateRequest,
    CriticalRfiAddExpectedClosureDateSuccess,
    CriticalRfiAddExpectedClosureDateError,    
} from './actions';
import { withLatestFrom, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { ChangeManagementService } from 'src/app/services/api/webapi-services/change-management.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { Zone } from 'src/app/enums';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable()
export class CriticalRfiEffects {    
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private CriticalRfiService: ChangeManagementService,
        private toastService: ToastService,
        private commentService: CommentService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType(CriticalRfiActionTypes.CriticalRfiFilterRequest),
        withLatestFrom(this.store.select((store) => store.criticalRfiState.filter)),
        mergeMap(([, filter]) => {
            return this.CriticalRfiService.getCriticalRfiData(filter).pipe(
                map((dataPagination) => {
                    return new CriticalRfiFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Change management data. Please contact Program Administrator.'
                    );
                    return of(new CriticalRfiFilterError(error));
                })
            );
        })
    );

    @Effect()
    updateSubPriority$ = this.actions$.pipe(
        ofType<CriticalRfiUpdateSubPriorityRequest>(CriticalRfiActionTypes.CriticalRfiUpdateSubPriorityRequest),
        mergeMap(({ payload }) =>
                this.CriticalRfiService.updateChangeDocSubPriority(payload.subPriority).pipe(
                map(() => {                    
                    this.toastService.Success('ChangeDocument SubPriority Updated');
                    return new CriticalRfiUpdateSubPrioritySuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating ChangeDocument SubPriority. Please contact Program Administrator.'
                    );
                    return of(new CriticalRfiUpdateSubPriorityError(error));
                })
            )
        )
    );

    @Effect()
    updateManualPriority$ = this.actions$.pipe(
        ofType<CriticalRfiUpdateManualPriorityRequest>(CriticalRfiActionTypes.CriticalRfiUpdateManualPriorityRequest),
        mergeMap(({ payload }) =>
                this.CriticalRfiService.updateChangeDocManualPriority(payload.manualPriority).pipe(
                map(() => {                    
                    this.toastService.Success('ChangeDocument Manual Priority Updated');
                    return new CriticalRfiUpdateManualPrioritySuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating ChangeDocument SubPriority. Please contact Program Administrator.'
                    );
                    return of(new CriticalRfiUpdateManualPriorityError(error));
                })
            )
        )
    );

    @Effect()
    addDetailComment$ = this.actions$.pipe(
        ofType<CriticalRfiAddCommentRequest>(CriticalRfiActionTypes.CriticalRfiAddCommentRequest),
        mergeMap(({ payload }) =>
            this.commentService
                .addCommentCriticalRfi(payload.id.toString(), payload.description, Zone.CriticalRfi, payload.mentions, payload.type)
                .pipe(
                    map(() => {
                        this.toastService.Success('Comment successfully added.');
                        return new CriticalRfiAddCommentSuccess({
                            id: payload.id,
                            description: payload.description,
                            mentions: payload.mentions,
                            type : payload.type
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding comment. Please contact Program Administrator.'
                        );
                        return of(new CriticalRfiAddCommentError(error));
                    })
                )
        )
    );

    @Effect()
    addBulkDetailComment$ = this.actions$.pipe(
        ofType<CriticalRfiAddBulkCommentRequest>(
            CriticalRfiActionTypes.CriticalRfiAddBulkCommentRequest
        ),
        withLatestFrom(this.store.select((store) => store.criticalRfiState.filter)),
        mergeMap(([action, filter]) =>
            this.commentService
                .addBulkCommentZone(action.payload.description, action.payload.mentions, filter, Zone.CriticalRfi)
                .pipe(
                    map(() => {
                        this.toastService.Success('Bulk Comment successfully added.');
                        return new CriticalRfiAddBulkCommentSuccess({
                            description: action.payload.description,
                            mentions: action.payload.mentions,
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding bulk comment. Please contact Program Administrator.'
                        );
                        return of(new CriticalRfiAddBulkCommentError(error));
                    })
                )
        )
    );


    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(CriticalRfiActionTypes.CriticalRfiExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.criticalRfiState.filter)),
        mergeMap(([, CriticalRfiFilter]) => {
            const filter = {
                ...CriticalRfiFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.CriticalRfiService.generateCriticalRfiExcel(filter).pipe(
                map((excelData) => new CriticalRfiExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new CriticalRfiExportToExcelError(error));
                })
            );
        })
    );

    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<CriticalRfiDeleteCommentRequest>(CriticalRfiActionTypes.CriticalRfiDeleteCommentRequest),
        withLatestFrom(this.store.select((store) => store.criticalRfiState.dataPagination.items)),
        mergeMap(([{ payload }, items]) =>
            this.commentService.deleteCommentCriticalRfi(payload.id, payload.type).pipe(
                map(() => {
                    this.toastService.Success('Comment deleted successfully.');
                    return new CriticalRfiDeleteCommentSuccess({ id: payload.id });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while deleting comment. Please contact Program Administrator.'
                    );
                    return of(new CriticalRfiDeleteCommentError(error));
                })
            )
        ),
        switchMap(() => of(new CriticalRfiFilterRequest()))
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(CriticalRfiActionTypes.CriticalRfiExportToExcelSuccess),
        map((action: CriticalRfiExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `CriticalRfi_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );  
    
    @Effect()
    addCriticalRfiExpectedClosureDate$ = this.actions$.pipe(
        ofType<CriticalRfiAddExpectedClosureDateRequest>(CriticalRfiActionTypes.CriticalRfiAddExpectedClosureDateRequest),
        mergeMap(({ payload }) => {
            let newPayload = _.cloneDeep(payload);
            newPayload.criticalRfi.expectedTimeOfCompletion = newPayload.expectedClosureDate;        
              return this.CriticalRfiService.addCriticalRfiExpectedClosureDate(newPayload.criticalRfi).pipe(
                map((criticalRfi) => {
                    this.toastService.Success('Critical RFI Expected Time Of Completion added successfully.');
                    return new CriticalRfiAddExpectedClosureDateSuccess(newPayload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding Critical RFI Expected Time Of Completion. Please contact Program Administrator.'
                    );
                    return of(new CriticalRfiAddExpectedClosureDateError(error));
                })
            )
            })
    );
}
