import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    RFOActionTypes,
    RFOUploadLogSuccess,
    RFOUploadLogError,
    RFOSetStatusAndStartDate,
    RFODeltaRequest,
    RFODeltaSuccess,
    RFODeltaError,
    RFOPatchDeltaRequest,
    RFOPatchDeltaSuccess,
    RFOPatchDeltaError,
    RFOValidateDeltaSuccess,
    RFOValidateDeltaError,
    RFOClearInProgressSpinner,
    RFOTemplateFileRequest,
    RFOTemplateFileSuccess,
    RFOTemplateFileError,
    RFODeleteDataRequest,
    RFODeleteDataSuccess,
    RFODeleteDataError,
    RFOPatchDeleteRecordRequest,
    RFOPatchDeleteRecordSuccess,
    RFOPatchDeleteRecordError,
    RFOValidateButtonStateSuccess,
    RFOValidateButtonStateError,
    RFODownloadOutputDataRequest,
    RFODownloadOutputDataSuccess,
    RFODownloadOutputDataError,
    RFOPatchAllDeltaRequest,
    RFOPatchAllDeltaSuccess,
    RFOPatchAllDeltaError,
    RFOPatchDeleteAllRecordsRequest,
    RFOPatchDeleteAllRecordsSuccess,
    RFOPatchDeleteAllRecordsError,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class RFOImportEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(RFOActionTypes.RFOUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['RFO']).pipe(
                map((uploadLogData) => new RFOUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new RFOUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<RFOUploadLogSuccess>(RFOActionTypes.RFOUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.rfoImportState.uploadLogStatus),
            this.store.select((store) => store.rfoImportState.uploadLogStartDate)
        ),
        filter(([{ payload }, status, startDate]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return logs[0] && (status !== logs[0].status || startDate !== logs[0].startDate);
        }),
        map(([{ payload }]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return new RFOSetStatusAndStartDate({
                status: logs[0].status,
                startDate: logs[0].startDate,
            });
        })
    );

    @Effect()
    invokeDeltaRequest$ = this.actions$.pipe(
        ofType(RFOActionTypes.RFOSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.rfoImportState.uploadLogData)),
        filter(([, uploadLogs]) => {
            const logs = uploadLogs.filter((l) => l.status !== ImportStatuses.Info);
            return (logs[0] && logs[0].status === ImportStatuses.Finished) || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new RFODeltaRequest(), new RFODeleteDataRequest()])
    );

    @Effect()
    getDelta$ = this.actions$.pipe(
        ofType(RFOActionTypes.RFODeltaRequest),
        withLatestFrom(this.store.select((store) => store.rfoImportState.deltaFilter)),
        mergeMap(([, deltaFilter]) =>
            this.manualUploadsService.getRFODelta(deltaFilter.page, deltaFilter.take).pipe(
                map((deltaData: any) => new RFODeltaSuccess(deltaData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delta records. Please contact Program Administrator'
                    );
                    return of(new RFODeltaError(error));
                })
            )
        )
    );

    @Effect()
    getDeleteRecords$ = this.actions$.pipe(
        ofType(RFOActionTypes.RFODeleteDataRequest),
        withLatestFrom(this.store.select((store) => store.rfoImportState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getRFODeleteRecords(deleteFilter.page, deleteFilter.take).pipe(
                map((deleteData: any) => new RFODeleteDataSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete records. Please contact Program Administrator'
                    );
                    return of(new RFODeleteDataError(error));
                })
            )
        )
    );

    @Effect()
    patchDelta$ = this.actions$.pipe(
        ofType<RFOPatchDeltaRequest>(RFOActionTypes.RFOPatchDeltaRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchRFODelta(action.payload.RFOId, action.payload.deltaState).pipe(
                map(() => new RFOPatchDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deltaState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new RFOPatchDeltaError(error));
                })
            )
        )
    );

    @Effect()
    patchAllDelta$ = this.actions$.pipe(
        ofType<RFOPatchAllDeltaRequest>(RFOActionTypes.RFOPatchAllDeltaRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchRFOAllDelta(action.payload.deltaState).pipe(
                map(() => new RFOPatchAllDeltaSuccess({ deltaState: action.payload.deltaState })),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deltaState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new RFOPatchAllDeltaError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteRecord$ = this.actions$.pipe(
        ofType<RFOPatchDeleteRecordRequest>(RFOActionTypes.RFOPatchDeleteRecordRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchRFODeleteRecord(action.payload.rfoId, action.payload.deleteState).pipe(
                map(() => new RFOPatchDeleteRecordSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'deleting' : 'restoring'
                        } the record. Please contact Program Administrator`
                    );
                    return of(new RFOPatchDeleteRecordError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteAllRecords$ = this.actions$.pipe(
        ofType<RFOPatchDeleteAllRecordsRequest>(RFOActionTypes.RFOPatchDeleteAllRecordsRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchRFODeleteAllRecords(action.payload.deleteState).pipe(
                map(() => new RFOPatchDeleteAllRecordsSuccess({ deleteState: action.payload.deleteState })),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'deleting' : 'restoring'
                        } the record. Please contact Program Administrator`
                    );
                    return of(new RFOPatchDeleteAllRecordsError(error));
                })
            )
        )
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(RFOActionTypes.RFOValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateRFODelta().pipe(
                map(() => new RFOValidateDeltaSuccess()),
                catchError((error) => {
                    const conflictStatusCode = 409;
                    if (error.status !== conflictStatusCode) {
                        this.toastService.Error(
                            'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                        );
                    } else {
                        this.toastService.Error(
                            'The import file does not have all RFOs required. There are subsystems in the database which have RFOs set, that are not present in the RFO import file.'
                        );
                    }

                    return of(new RFOValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(RFOActionTypes.RFODeltaRequest),
        withLatestFrom(this.store.select((store) => store.rfoImportState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new RFOClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<RFOTemplateFileRequest>(RFOActionTypes.RFOTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new RFOTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading RFO structure file. Please contact Program Administrator'
                    );
                    return of(new RFOTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<RFOTemplateFileSuccess>(RFOActionTypes.RFOTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'RFO Planning Dates.xlsx');
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(
            RFOActionTypes.RFODeltaSuccess,
            RFOActionTypes.RFOPatchDeleteAllRecordsSuccess,
            RFOActionTypes.RFOPatchDeleteRecordSuccess
        ),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('RFO').pipe(
                map((response: any) => new RFOValidateButtonStateSuccess(response)),
                catchError((error) => of(new RFOValidateButtonStateError(error)))
            )
        )
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<RFODownloadOutputDataRequest>(RFOActionTypes.RFODownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new RFODownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new RFODownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<RFODownloadOutputDataSuccess>(RFOActionTypes.RFODownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );
}
