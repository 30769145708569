import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { ApplicationRef, Component, ComponentFactoryResolver, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import { TraceHeatingStatusDataRequest } from 'src/app/store/trace-heating-status/action';
import { TraceHeatingStatusDto } from 'src/app/store/trace-heating-status/model';

@Component({
  selector: 'app-trace-heating-status-print',
  templateUrl: './trace-heating-status-print.component.html',
  styleUrls: ['./trace-heating-status-print.component.scss']
})
export class TraceHeatingStatusPrintComponent extends BaseComponent implements OnInit {
  @Output() close = new EventEmitter();
  @ViewChild(CdkPortal) portal: CdkPortal;

  printIsLoading$ = this.store.select((state) => state.traceHeatingStatusState.printIsLoading);
  printData$ = this.store.select((state) => state.traceHeatingStatusState.printData.items);

  private externalWindow: Window = null;
  private host: DomPortalOutlet;
  printData: MatTableDataSource<TraceHeatingStatusDto>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private store: Store<ApplicationState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new TraceHeatingStatusDataRequest(true));
    this.printData$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
      this.printData = new MatTableDataSource(items);
  });
  }

  ngAfterViewInit() {
    this.printIsLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
        if (isLoading === false) {
            setTimeout(() => this.externalWindow.print(), 1000);
        };
    });

    this.externalWindow = window.open('', 'Print Schedule Activities', '');

    this.externalWindow.onbeforeunload = () => this.ngOnDestroy();

    this.host = new DomPortalOutlet(
        this.externalWindow.document.body,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector
    );

    document.querySelectorAll('link, style').forEach((htmlElement) => {
        this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
    });

    this.host.attach(this.portal);
  }

  ngOnDestroy() {
    this.externalWindow.close();
    this.close.emit();
    this.host.detach();
  }
}
