<div class="top-header-section-container">
    <div class="row-container">
        <div class="minitable-container">
            <h4>Subsystem {{ planningTypeUppercase }}s (vs. L4 Schedule) - Past Weekly Trend</h4>
            <div class="chart-table-container">
                <div class="chart-row">
                    <ngx-charts-line-chart
                        [results]="reliabilityChartData"
                        [scheme]="colorScheme"
                        [xAxis]="true"
                        [yAxis]="true"
                        [showXAxisLabel]="false"
                        [showYAxisLabel]="false"
                        [roundDomains]="true"
                        [yScaleMin]="0"
                        [yScaleMax]="100"
                        [view]="[600, 300]"
                        [yAxisTickFormatting]="yAxisTickPercentage"
                        >
                        <ng-template #tooltipTemplate let-model="model">                            
                            <span>{{ model.series }} - {{ model.name }}: {{ model.value }} %</span>
                        </ng-template>
                        <ng-template #seriesTooltipTemplate let-model="model">
                            <p *ngFor="let serie of model; let index = index" class="chart-series-tooltip-body">
                                <span class="chart-series-color" [style.background-color]="serie.color"></span>
                                <span>{{ serie.series }} : {{ serie.value }}%</span>
                            </p>
                        </ng-template>
                    </ngx-charts-line-chart>
                </div>
                <div class="title-row">
                    <p>Performance vs. Plan</p>
                </div>
                <div class="tabe-row print-hidden">
                    <mat-accordion>
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>   
                              Table data                    
                          </mat-expansion-panel-header>
                          <table mat-table [dataSource]="topHeaderSectionByPastWeeks">
                            <ng-container matColumnDef="plan">
                                <th mat-header-cell disableClear *matHeaderCellDef>Weekly<br />Achievement</th>
                                <td mat-cell *matCellDef="let element" class="bold-text">{{ element.achievedTotal }}</td>
                            </ng-container>
                            <ng-container matColumnDef="week">
                                <th mat-header-cell disableClear *matHeaderCellDef>Week<br />(W/E)</th>
                                <td mat-cell *matCellDef="let element">{{ element.week | date: 'd-MMM' | lowercase }}</td>
                            </ng-container>
                            <ng-container matColumnDef="planned">
                                <th mat-header-cell disableClear *matHeaderCellDef>
                                    Planned <br />
                                    per L4 Schedule
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.planned }}</td>
                            </ng-container>
                            <ng-container matColumnDef="plannedAndAchieved">
                                <th mat-header-cell disableClear *matHeaderCellDef>Achieved <br />in the Week</th>
                                <td mat-cell *matCellDef="let element">{{ element.plannedAndAchieved }}</td>
                            </ng-container>
                            <ng-container matColumnDef="previouslyAchieved">
                                <th mat-header-cell disableClear *matHeaderCellDef>Previously <br />Achieved</th>
                                <td mat-cell *matCellDef="let element">{{ element.previouslyAchieved }}</td>
                            </ng-container>
                            <ng-container matColumnDef="l4Reliability">
                                <th mat-header-cell disableClear *matHeaderCellDef>
                                    L4<br />
                                    Reliability
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.l4Reliability }} %</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="topHeaderPastWeeksColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: topHeaderPastWeeksColumns"></tr>
                        </table>
                        </mat-expansion-panel>
                      </mat-accordion>
                </div>
            </div>            
        </div>
        <div class="minitable-container">
            <h4>Subsystem {{ planningTypeUppercase }}s (vs. L4 Schedule) - Current Week</h4>
            <table mat-table [dataSource]="topHeaderSectionByUsersTable">
                <ng-container matColumnDef="plan">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        Achieved <br />W/E {{ getCurrentWeekEndDate | date: 'd-MMM' | lowercase }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="bold-text">{{ element.achievedTotal }}</td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        {{ planningTypeUppercase === 'MC' ? 'Construction Area' : 'System Owner' }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
                </ng-container>
                <ng-container matColumnDef="planned">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        Planned <br />
                        per L4 Schedule
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.planned }}</td>
                </ng-container>
                <ng-container matColumnDef="plannedAndAchieved">
                    <th mat-header-cell disableClear *matHeaderCellDef>Achieved <br />in the Week</th>
                    <td mat-cell *matCellDef="let element">{{ element.plannedAndAchieved }}</td>
                </ng-container>
                <ng-container matColumnDef="previouslyAchieved">
                    <th mat-header-cell disableClear *matHeaderCellDef>Previously <br />Achieved</th>
                    <td mat-cell *matCellDef="let element">{{ element.previouslyAchieved }}</td>
                </ng-container>
                <ng-container matColumnDef="l4Reliability">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        L4<br />
                        Reliability
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.l4Reliability }} %</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="topHeaderColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: topHeaderColumns"></tr>
            </table>
        </div>
    </div>
    <div class="row-container acms-header">
        <div class="minitable-container">
            <h4>Subsystem {{ planningTypeUppercase }}s (Weekly Commitments) - Past Weekly Trend</h4>
            <div class="chart-table-container">
                <div class="chart-row">
                    <ngx-charts-line-chart
                        [results]="commintmentReliabilityChartData"
                        [scheme]="colorScheme"
                        [xAxis]="true"
                        [yAxis]="true"
                        [showXAxisLabel]="false"
                        [showYAxisLabel]="false"
                        [roundDomains]="true"
                        [yScaleMin]="0"
                        [yScaleMax]="100"
                        [view]="[600, 300]"
                        [yAxisTickFormatting]="yAxisTickPercentage"
                        >
                        <ng-template #tooltipTemplate let-model="model">                            
                            <span>{{ model.series }} - {{ model.name }}: {{ model.value }} %</span>
                        </ng-template>
                        <ng-template #seriesTooltipTemplate let-model="model">
                            <p *ngFor="let serie of model; let index = index" class="chart-series-tooltip-body">
                                <span class="chart-series-color" [style.background-color]="serie.color"></span>
                                <span>{{ serie.series }} : {{ serie.value }}%</span>
                            </p>
                        </ng-template>
                    </ngx-charts-line-chart>
                </div>                
                <div class="title-row">
                    <p>Performance vs. Weekly Forecast Commitment</p>
                </div>
                <div class="tabe-row print-hidden">
                    <mat-accordion>
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>   
                              Table data                    
                          </mat-expansion-panel-header>
                          <table mat-table [dataSource]="topHeaderSectionByPastWeeks">
                            <ng-container matColumnDef="week">
                                <th mat-header-cell disableClear *matHeaderCellDef>Week<br />(W/E)</th>
                                <td mat-cell *matCellDef="let element">{{ element.week | date: 'd-MMM' | lowercase }}</td>
                            </ng-container>
                            <ng-container matColumnDef="committed">
                                <th mat-header-cell disableClear *matHeaderCellDef>
                                    Commitment
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.committed }}</td>
                            </ng-container>
                            <ng-container matColumnDef="committedAndAchieved">
                                <th mat-header-cell disableClear *matHeaderCellDef>
                                    Achieved
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.committedAndAchieved }}</td>
                            </ng-container>
                            <ng-container matColumnDef="committmentReliability">
                                <th mat-header-cell disableClear *matHeaderCellDef>
                                    Commitment<br />
                                    Reliability
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.committmentReliability }} %</td>
                            </ng-container>
                            <ng-container matColumnDef="achievedButNotCommitted">
                                <th mat-header-cell disableClear *matHeaderCellDef>
                                    Additions<br />
                                    to Commitment
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.achievedButNotCommitted }}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="topHeaderWeekPastCommittmentsColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: topHeaderWeekPastCommittmentsColumns"></tr>
                            </table>
                        </mat-expansion-panel>
                      </mat-accordion>
                </div>
            </div>
        </div>
        <div class="minitable-container">
            <h4>Subsystem {{ planningTypeUppercase }}s (Weekly Commitments) - Current Week</h4>
            <table mat-table [dataSource]="topHeaderSectionByUsersTable">
                <ng-container matColumnDef="user">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        {{ planningTypeUppercase === 'MC' ? 'Construction Area' : 'System Owner' }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
                </ng-container>
                <ng-container matColumnDef="committed">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        Commitment
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.committed }}</td>
                </ng-container>
                <ng-container matColumnDef="committedAndAchieved">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        Achieved
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.committedAndAchieved }}</td>
                </ng-container>
                <ng-container matColumnDef="committmentReliability">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        Commitment<br />
                        Reliability
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.committmentReliability }} %</td>
                </ng-container>
                <ng-container matColumnDef="achievedButNotCommitted">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        Additions<br />
                        to Commitment
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.achievedButNotCommitted }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="topHeaderWeekColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: topHeaderWeekColumns"></tr>
            </table>
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
</div>
