import {
    Waypoint,
    Milestone,
    OrderDirection,
    Contractor,
    CheckListColumn,
    CalendarColumn,
    NumericColumn,
    UserDetail,
    Gooc,
    RFO,
    McMilestone,
    System,
    StagedStartUpPriority,
} from '../common.model';
import * as moment from 'moment';
import { SubsystemScope, Zone, ExceptionScope, ExceptionType } from 'src/app/enums';
import { DetailedStatusLockedFilter } from 'src/app/models/detailed-status-locked-filter';
import { AttachmentDTO, ReviewStatusDTO } from '../details/model';
import { TagKeyDTO } from '../tag-search/model';

export interface DetailedStatusState {
    dataPagination: DetailedStatusDataPagination;
    isLoading: boolean;
    makeRequest: boolean;
    AQVD: AQVDDetailedStatusFilter;
    BITR: BITRDetailedStatusFilter;
    CITR: CITRDetailedStatusFilter;
    PLI: PLIDetailedStatusFilter;
    Redline: RedlineDetailedStatusFilter;
    EX: EXDetailedStatusFilter;
    lockedFilter: DetailedStatusLockedFilter;
    COW: COWDetailedStatusFilter;
    tableOffset: DetailedStatusTableScrollProgress;
    BITRandCITR: BITRandCITRDetailedStatusFilter;
    ConstraintDetails: ConstraintDetailsStatusFilter;
    RFSUWorkToGoSearch : RFSUWorkToGoSearchDetailedStatusFilter;
    RFOWorkToGoSearch : RFOWorkToGoSearchDetailedStatusFilter;
}

export class DetailedStatusTableScrollProgress {
    redzone?: number;
    bluezone?: number;
}

export class DetailedStatusDataPagination {
    items: DetailedStatusDTO[] = [];
    totalCount = 0;
}

export class DetailedStatusFilterBase {
    itrid = 0;
    page = 0;
    take = 25;
    sortBy: string;
    scope: SubsystemScope = null;

    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    disciplines: string[] = [];
    subsystem = '';
    priority = '';
    category = [];
    discipline: string;
    columnSubsystem: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnNumber: CheckListColumn = null;
    columnPriority: CheckListColumn = null;
    columnDescription: CheckListColumn = null;
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    systemOwner: string[] = [];
    mcEngineer: string[] = [];
    goocs: Gooc[] = [];
    mcMilestones: McMilestone[] = [];
    rfos: RFO[] = [];
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    deliveryEngineer: string[] = [];
    showHideColumns = new ShowHideColumns();
    constructor() {
        this.sortBy = 'number';
    }
}

export class AQVDDetailedStatusFilter extends DetailedStatusFilterBase {
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    excludeExceptions: ExceptionType[] = [];
    acm: string[] = [];
    scManager: string[] = [];
    constructionArea: string[] = [];
    areaBreakdown: string[] = [];
    columnNBResponsiblePersonComments = null; 
    rfsuContractors: string[] = [];
    areaCompletionLead: string[] = [];
    mcEngineer: string[] = [];
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWorkOrderNum: CheckListColumn = null;
    columnETransferNumber: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;

    columnSubsystemName: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnDocType: CheckListColumn = null;
    columnDocType2: CheckListColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    timezoneOffset = 0;
    mcMilestones: Milestone[] = [];
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
    columnMcPlan: CalendarColumn = null;
    columnRFOPlan: CalendarColumn = null;
    columnMcForecast: CalendarColumn = null;
    columnRfsuForecast: CalendarColumn = null;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    columnConstraintFlag: CheckListColumn = null;
    columnRFO: CheckListColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    constructor() {
        super();
        this.scope = SubsystemScope.AQVD;
    }
}

export class BITRDetailedStatusFilter extends DetailedStatusFilterBase {
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    requiredForSteamblow: boolean = null;
    excludeExceptions: ExceptionType[] = [];
    columnNBResponsiblePersonComments = null; 
    scManager: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    areaCompletionLead: string[] = [];
    systemOwner: string[] = [];
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWorkOrderNum: CheckListColumn = null;
    columnETransferNumber: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnSubsystemName: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnActivityID: CheckListColumn = null;
    columnPreCommStart: CalendarColumn = null;
    columnPreCommFinish: CalendarColumn = null;
    columnDocType: CheckListColumn = null;
    columnDocType2: CheckListColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnRfsuForecast: CalendarColumn = null;
    columnRfsuPlan: CalendarColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnItrInCurrentWeek: CheckListColumn = null;
    rfos: RFO[] = [];
    timezoneOffset = 0;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    columnConstraintFlag: CheckListColumn = null;
    columnITRConstraintCount: NumericColumn = null;
    columnRFO: CheckListColumn = null;
    columnRFOPlan: CalendarColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    constructor() {
        super();
        this.scope = SubsystemScope.BITR;
    }
}

export class BITRandCITRDetailedStatusFilter extends DetailedStatusFilterBase {
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    excludeExceptions: ExceptionType[] = [];
    scManager: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    areaCompletionLead: string[] = [];
    systemOwner: string[] = [];
    columnSubsystemName: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnPreCommStart: CalendarColumn = null;
    columnPreCommFinish: CalendarColumn = null;
    columnDocType: CheckListColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnRfsuForecast: CalendarColumn = null;
    columnRfsuPlan: CalendarColumn = null;
    columnItrInCurrentWeek: CheckListColumn = null;
    columnWorkAreaName:  CheckListColumn = null;
    columnITRConstraintDueDate: CalendarColumn = null;
    columnITRConstraintRaisedDate: CalendarColumn = null;
    columnITRConstraintStatusDate: CalendarColumn = null;
    columnITRConstraintId: CheckListColumn = null;
    
    columnExceptionNumber: CheckListColumn = null;
    columnITRConstraintTypeName: CheckListColumn = null;
    columnITRConstraintStatus: CheckListColumn = null;
    columnITRConstraintResponsibleUser: CheckListColumn = null;
    columnITRConstraintAdditionalResponsibleUser: CheckListColumn = null;
    columnITRConstraintRaisedByUser: CheckListColumn = null;
    columnITRConstraintRaisedByTeam: CheckListColumn = null;
    columnAssociatedNPW: CheckListColumn = null;
    columnAssociatedPLI: CheckListColumn = null;
    columnAssociatedQVD: CheckListColumn = null;
    columnFMRorPO: CheckListColumn = null;
    columnWaypointRequired: CheckListColumn = null;
    columnFMRorPOETADate: CalendarColumn = null;
    columnRFO: CheckListColumn = null;
    columnRFOPlan: CalendarColumn = null ;
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnActivityID: CheckListColumn = null; 
    columnActivityName: CheckListColumn = null;
    columnITRConstraintCount: NumericColumn = null;
    columnMaterialsTotalCount: NumericColumn = null;
    columnManhours: NumericColumn = null;
    columnMCActual: CalendarColumn = null;
    columnRFSUActual: CalendarColumn = null;
    columnWorkOrderNum: CheckListColumn = null;
    columnETransferNumber: CheckListColumn = null;

    columnItrId: number = 0;
    rfos: RFO[] = [];
    timezoneOffset = 0;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    columnConstraintFlag: CheckListColumn = null;

    constructor() {
        super();
        this.scope = SubsystemScope.BITRandCITR;
    }
}

export class ConstraintDetailsStatusFilter extends DetailedStatusFilterBase {
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    excludeExceptions: ExceptionType[] = [];
    scManager: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    areaCompletionLead: string[] = [];
    systemOwner: string[] = [];
    id: number = null;

    columnExceptionNumber: CheckListColumn = null;
    columnSubsystemName: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnPreCommStart: CalendarColumn = null;
    columnPreCommFinish: CalendarColumn = null;
    columnDocType: CheckListColumn = null;
    columnDocType2: CheckListColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnRfsuForecast: CalendarColumn = null;
    columnRfsuPlan: CalendarColumn = null;
    columnItrInCurrentWeek: CheckListColumn = null;
    columnITRConstraintDueDate: CalendarColumn = null;
    columnITRConstraintRaisedDate: CalendarColumn = null;
    columnITRConstraintStatusDate: CalendarColumn = null;
    columnITRConstraintId: CheckListColumn = null;
    columnITRConstraintDescription: CheckListColumn = null;
    columnITRConstraintStatus: CheckListColumn = null;
    columnRFSUWalkdownForecast: CalendarColumn = null;
    columnItrId: number = 0;
    columnMaterialsTotalCount: NumericColumn = null;
    columnITRConstraintTypeName: CheckListColumn = null;
    columnITRConstraintResponsibleUser: CheckListColumn = null;
    columnITRConstraintResponsibleGroup: CheckListColumn = null;
    columnITRConstraintAdditionalResponsibleUser: CheckListColumn = null;
    columnITRConstraintRaisedByUser: CheckListColumn = null;
    columnITRConstraintRaisedByTeam: CheckListColumn = null;
    columnWorkAreaName:  CheckListColumn = null;
    ColumnAttachmentName: CheckListColumn = null;
    columnAssociatedNPW: CheckListColumn = null;
    columnAssociatedPLI: CheckListColumn = null;
    columnAssociatedQVD: CheckListColumn = null;
    columnLatestITRComment: CheckListColumn = null;
    columnAssociatedComments: CheckListColumn = null;
    columnAQVDContractor: CheckListColumn = null;
    ColumnPLIActionedBy: CheckListColumn = null;
    columnFMRorPO: CheckListColumn = null;
    columnWaypointRequired: CheckListColumn = null;
    columnFMRorPOETADate: CalendarColumn = null;
    columnRFO: CheckListColumn = null;
    columnRFOPlan: CalendarColumn = null ;
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnManhours: NumericColumn = null;
    columnActivityID: CheckListColumn = null; 
    columnPriorityNo: CheckListColumn = null;
    columnCriticalPath: CheckListColumn = null; 
    columnETA: CalendarColumn = null;
    columnWorkOrderNum: CheckListColumn = null;
    columnETransferNumber: CheckListColumn = null;
    
    rfos: RFO[] = [];
    timezoneOffset = 0;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    columnConstraintFlag: CheckListColumn = null;

    constructor() {
        super();
        this.scope = SubsystemScope.ConstraintDetails;
    }
}

export class CITRDetailedStatusFilter extends DetailedStatusFilterBase {
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    requiredForSteamblow: boolean = null;
    excludeExceptions: ExceptionType[] = [];
    scManager: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    areaCompletionLead: string[] = [];
    systemOwner: string[] = [];
    columnSubsystemName: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnActivityID: CheckListColumn = null;
    columnPreCommStart: CalendarColumn = null;
    columnPreCommFinish: CalendarColumn = null;
    columnDocType: CheckListColumn = null;
    columnDocType2: CheckListColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnRfsuForecast: CalendarColumn = null;
    columnNBResponsiblePersonComments = null; 
    columnRfsuPlan: CalendarColumn = null;
    columnItrInCurrentWeek: CheckListColumn = null;
    columnITRConstraintTypeName: CheckListColumn = null;
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWorkOrderNum: CheckListColumn = null;
    columnETransferNumber: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    rfos: RFO[] = [];
    timezoneOffset = 0;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    columnConstraintFlag: CheckListColumn = null;
    columnITRConstraintCount: NumericColumn = null;
    columnRFO: CheckListColumn = null;
    columnRFOPlan: CalendarColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    constructor() {
        super();
        this.scope = SubsystemScope.CITR;
    }
}

export class PLIDetailedStatusFilter extends DetailedStatusFilterBase {
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    rfoType: boolean = null;
    requiredForSteamblow: boolean = null;
    excludeExceptions: ExceptionType[] = [];
    acm: string[] = [];
    constructionArea: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    areaCompletionLead: string[] = [];
    scManager: string[] = [];
    mcEngineer: string[] = [];
    systemOwner: string[] = [];
    columnSubsystemName: CheckListColumn = null;
    columnCategory: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnActionBy: CheckListColumn = null;
    columnPliImplementationTeam: CheckListColumn = null;
    columnMaterialReference: CheckListColumn = null;
    ColumnAttachmentName: CheckListColumn = null;
    columnClassification: CheckListColumn = null;
    columnPunchType: CheckListColumn = null;
    columnReviewStatusComplete: CheckListColumn = null;
    columnACNumber: CheckListColumn = null;
    columnRaisedBy: CheckListColumn = null;
    columnTagDiscipline: CheckListColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWorkOrderNum: CheckListColumn = null;
    columnETransferNumber: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnRequiredByDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    columnMaterialsTotalCount: NumericColumn = null;
    columnETA: CalendarColumn = null;
    mcMilestones: Milestone[] = [];
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
    timezoneOffset = 0;
    columnMcPlan: CalendarColumn = null;
    columnMcForecast: CalendarColumn = null;
    columnRfsuPlan: CalendarColumn = null;
    columnRFOPlan: CalendarColumn = null;
    columnRfsuForecast: CalendarColumn = null;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    columnStatus: CheckListColumn = null;
    columnPLIForecastClosureDate: CalendarColumn = null;
    columnMaterialReviewAssignedTo: CheckListColumn = null;
    columnMaterialReviewStatus: CheckListColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    columnRFO: CheckListColumn = null;
    columnNBResponsiblePersonComments = null; 
    CheckListColumn = null;
    constructor() {
        super();
        this.scope = SubsystemScope.PLI;
        this.showHideColumns.showRFO = false;
    }
}

export class COWDetailedStatusFilter extends DetailedStatusFilterBase {
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    scManager: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    areaCompletionLead: string[] = [];
    mcEngineer: string[] = [];
    systemOwner: string[] = [];
    columnSubsystemName: CheckListColumn = null;
    columnCategory: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnActionBy: CheckListColumn = null;
    columnRaisedBy: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnPunchType: CheckListColumn = null;
    mcMilestones: Milestone[] = [];
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
    timezoneOffset = 0;
    columnCowDocNumber: CheckListColumn = null;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];

    constructor() {
        super();
        this.scope = SubsystemScope.COW;
    }
}

export class RedlineDetailedStatusFilter extends DetailedStatusFilterBase {
    redReqTypes: string[] = [];
    docGroups: string[] = [];
    areas: string[] = [];
    units: string[] = [];
    revTypes: string[] = [];
    mcdrConstrRedlineCats: string[] = [];
    mocs: string[] = [];
    columnRedDate: CalendarColumn = null;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    cwps: { id: string }[] = [];
    redType: string[] = [];
    scManager: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    waypoints: Waypoint[] = [];
    areaCompletionLead: string[] = [];    
    subsystems: { id: string }[] = [];
    columnRevision: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnDocType: CheckListColumn = null;
    columnRedType: CheckListColumn = null;
    columnRedlineExceptionNumber: CheckListColumn = null;
    columnOriginalSubsystem: CheckListColumn = null;
    columnRFO:CheckListColumn = null;
    columnRedReqType: CheckListColumn = null;
    columnRedStatus: CheckListColumn = null;
    columnChangeId: CheckListColumn = null;
    columnMcdrConstrRedCat: CheckListColumn = null;
    columnContainsChange: CheckListColumn = null;
    columnTargetCompletionDate: CalendarColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    columnExpectedTimeOfCompletion: CalendarColumn = null;
    statuses: string[] = [];
    systems: System[] = [];
    timezoneOffset = 0;

    constructor() {
        super();
        this.scope = SubsystemScope.Redline;
    }
}

export class EXDetailedStatusFilter extends DetailedStatusFilterBase {
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    exceptionPhases: string[] = [];
    acm: string[] = [];
    constructionArea: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    areaCompletionLead: string[] = [];
    scManager: string[] = [];
    mcEngineer: string[] = [];
    systemOwner: string[] = [];
    exScope = ExceptionScope.All;
    columnAreaBreakdown: CheckListColumn = null;
    columnSubsystem:CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnActivityID: CheckListColumn = null;
    columnManhours: NumericColumn = null;
    columnRFOPlan: CalendarColumn = null ;
    columnRfsuPlan: CalendarColumn = null ;
    columnTagName: CheckListColumn = null;
    columnExceptionResponsbilePerson: CheckListColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    columnExceptionStatus: CheckListColumn = null;
    columnExceptionPhase: CheckListColumn = null;
    columnTargetCompletionDate: CalendarColumn = null;
    columnForecastClosureDate: CalendarColumn = null;
    columnLastConstraintDate: CalendarColumn = null;
    columnPreCommStart: CalendarColumn = null;
    columnPreCommFinish: CalendarColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnActionBy: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnExceptionPriority: CheckListColumn = null;
    columnITRConstraintCount: NumericColumn = null;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    overdue: string[] = [];
    columnLatestExceptionComment: CheckListColumn = null;
    columnO3ConstraintID: CheckListColumn = null;
    columnExceptionAdditionalResponsibleUser: CheckListColumn = null;
    columnFMRorPO: CheckListColumn = null;
    columnAssociatedNPW: CheckListColumn = null;
    columnRFO: CheckListColumn = null;
    columnCategory: CheckListColumn = null;
    constructor() {
        super();
        this.scope = SubsystemScope.EX;
        this.showHideColumns.showExceptionNumber = true;
    }
}

export class RFSUWorkToGoSearchDetailedStatusFilter extends DetailedStatusFilterBase {
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    exceptionPhases: string[] = [];
    acm: string[] = [];
    constructionArea: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    areaCompletionLead: string[] = [];
    scManager: string[] = [];
    mcEngineer: string[] = [];
    systemOwner: string[] = [];
    deliveryEngineer: string[] = [];
    exScope = ExceptionScope.All;
    rfsuCommitted: boolean = null;
    rfsuRemaining:boolean = false;
    hasLUN: boolean = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnActivityID: CheckListColumn = null;
    columnManhours: NumericColumn = null;
    columnRFOPlan: CalendarColumn = null ;
    columnRfsuPlan: CalendarColumn = null ;
    columnRfsuForecast: CalendarColumn = null;
    columnTagName: CheckListColumn = null;
    columnDeliveryEngineer: CheckListColumn = null;
    columnExceptionResponsbilePerson: CheckListColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    columnExceptionPhase: CheckListColumn = null;
    columnTargetCompletionDate: CalendarColumn = null;
    columnForecastClosureDate: CalendarColumn = null;
    columnLastConstraintDate: CalendarColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnDocType: CheckListColumn = null;
    columnActionBy: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnSubsystemName:CheckListColumn = null;
    columnITRConstraintCount: NumericColumn = null;
    columnPreCommStart: CalendarColumn = null;
    columnPreCommFinish: CalendarColumn = null;
    columnRFO: CheckListColumn = null;
    columnRFSUWalkdownForecast: CalendarColumn = null;
    columnMCActual: CalendarColumn = null;
    columnRFSUActual: CalendarColumn = null;
    columnPriority: CheckListColumn = null;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    overdue: string[] = [];
    columnAreaBreakdown: CheckListColumn = null;
    systems: System[] = [];
    subsystems: System[] = [];

    constructor() {
        super();
        this.scope = SubsystemScope.RFSUWorkToGoSearch;
    }
}

export class RFOWorkToGoSearchDetailedStatusFilter extends DetailedStatusFilterBase {
    areaBreakdown: string[] = [];
    rfo = '';
    rfsuContractors: string[] = [];
    prePostRFOCreatedItem: boolean = null;
    columnBarcode: CheckListColumn = null;
    columnPli: CheckListColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    columnSubsystem:CheckListColumn = null;
    columnRFO: CheckListColumn = null;
    columnNpw: CheckListColumn = null;
    columnPascr:CheckListColumn = null;
    columnRedline: CheckListColumn = null;
    columnPostRFOWorkExpectedClosureDate: CalendarColumn = null;
    columnRFOActual: CalendarColumn = null;
    columnQVDNumber: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnPliCreatedDate: CalendarColumn = null;
    columnNpwIssueDate: CalendarColumn = null;
    columnItemCreatedDate: CalendarColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    columnResponsiblePersonOrGroup: CheckListColumn = null;
    columnItemComment: CheckListColumn = null;
    columnPriorityManualOverride: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnExceptionPhase: CheckListColumn = null;
    columnExceptionPriority: CheckListColumn = null;
    constructor() {
        super();
        this.scope = SubsystemScope.RFOWorkToGoSearch;
        this.sortBy = 'rfo';
        this.direction = OrderDirection.Asc;
    }
}

export class DetailedStatusDTO {
    id: number = null;
    itrid: number = null;
    itrId: number = null;
    columnItrId: number = null;
    subsystem = '';
    subsystemName = '';
    priority = '';

    rfo = '';
    columnRFO: CheckListColumn = null;
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnPliImplementationTeam:CheckListColumn = null;
    
    activityID: string[];
    columnActivityID: CheckListColumn = null; 
    activityName: string[];
    columnActivityName: CheckListColumn = null;
    associatedNPW = '';
    associatedPLI = '';
    associatedQVD= '';
    associatedNPWChangeType = '';
    columnAssociatedNPW: CheckListColumn = null;
    columnAssociatedPLI: CheckListColumn = null;
    columnAssociatedQVD: CheckListColumn = null;
    fmRorPO = '';
    waypointRequired = '';
    itrConstraintDescription = '';
    itrConstraintStatus = '';
    columnFMRorPO: CheckListColumn = null;
    columnWaypointRequired: CheckListColumn = null;
    itrConstraintTypeName = '';
    columnITRConstraintTypeName: CheckListColumn = null;
    itrConstraintResponsibleUser ='';
    columnITRConstraintResponsibleUser: CheckListColumn = null;
    itrConstraintAdditionalResponsibleUser = '';
    columnITRConstraintAdditionalResponsibleUser: CheckListColumn = null;
    itrConstraintRaisedByUser = '';
    columnITRConstraintRaisedByUser: CheckListColumn = null;
    itrConstraintCount = 0;
    columnITRConstraintCount: NumericColumn = null;
    manhours = 0;
    columnManhours: number = 0;
    columnITRConstraintId: CheckListColumn = null;    
    tagNumber = '';
    tagType = '';
    tagName = '';
    number = '';
    description = '';
    contractor = '';
    status = '';
    comments: CommentDetailDTO[] = [];
    latestComment = '';
    latestExceptionComment = '';
    o3ConstraintId = '';
    latestITRComment = '';
    latestNBResponsiblePersonComment = '';
    associatedComment = '';
    aqvdContractor = '';
    pliActionedBy = '';
    category = '';
    actionBy = '';
    zone: Zone = null;
    scope: SubsystemScope = null;
    discipline = '';
    targetCompletionDate: moment.Moment;
    revision = '';
    redType = '';
    redRedlineExceptionNumber = '';
    changeId = '';
    docType = '';
    docType2 = '';
    attachments: AttachmentDTO[] = [];
    mentions: UserDetail[];
    materialReference = '';
    containsChange = '';
    COWNumber = '';
    COWDocNumber = '';
    reviewStatusComplete = '';
    reviewStatus: ReviewStatusDTO[] = [];
    acNumber = '';
    barcode = '';
    constraintFlag = '';
    isConstraintEdit = false;
    prevConstraintFlag = '';
    itrConstraintDeleted = '';
    exceptionNumber = '';
    exceptionStatus = '';
    itrConstraintDueDate?: moment.Moment;
    itrConstraintRaisedDate: moment.Moment;
    itrConstraintStatusDate: moment.Moment;
    rfsuWalkdownForecast: moment.Moment;
    itrConstraintId: number = null;
    fmRorPOETADate?: moment.Moment;
    tagNo: TagKeyDTO[];
    barcodes: TagKeyDTO[] = [];
    responsiblePerson = '';
    responsiblePersonId :number = -1;
    exceptionAdditionalResponsibleUser = '';
    exceptionAdditionalResponsibleUserId: number = -1;
    forecastClosureDate? : moment.Moment;
    lastConstraintDate? : moment.Moment;
    itrConstraintToITRId : number = null;
    columnCriticalPath: CheckListColumn = null;
    columnPriorityNo: CheckListColumn = null;
    pliImplementationTeam:string= '';
    exceptionPriority : '';
    pliForecastClosureDate?: moment.Moment;
    itrConstraintResponsibleGroup = '';
    pli : '';
    pliCreatedDate? : moment.Moment;
    npw : '';
    npwIssueDate? : moment.Moment;
    pascr : '';
    redline : '';
    postRFOWorkExpectedClosureDate?: moment.Moment;
    itemCreatedDate? : moment.Moment;
    qvdNumber : '';
    rfsuContractorNo : '';
    rfoActual? : moment.Moment;
    npwId: number = null;
    redlineId: number = null;
    itrCheckSheetType : '';
    npwType : '';
    responsiblePersonOrGroup : '';
    itemType : null;
    itemComment : '';
    priorityManualOverride : '';
    responsiblePersonOrGroupsForPostRFOWork: String[];
    expectedTimeOfCompletion: moment.Moment = null;
}

export class CommentDetailDTO {
    id: number;
    description = '';
    userName = '';
    date: moment.Moment;
    mentions: UserDetail[];
}

export class O3ConstraintDTO {
    tagNumber: string;
    exceptionNumber: string;
    pliqvdNumber: string;
    o3ConstraintId = '';
    userName = '';
    date: moment.Moment;
    mentions: UserDetail[];
}
export class ConstraintTeamsDTO {
    id: number;
    projectSubTeamName = '';
    isActive = true;
}

export class ExpectedTimeofCompletionDTO {
    id: number;
    redlineId: number;
    oldExpectedTimeOfCompletion: moment.Moment;;
    newExpectedTimeOfCompletion: moment.Moment;;
    userFullName: string;
    updatedDate: Date;
}

export class ShowHideColumns {
    showSubsys = true;
    showOrigSubsys = true;
    showNBResponsiblePersonComment = false;
    showSubsysName = false;
    showDiscipline = true;
    showContractor = true;
    showStatus = true;
    showQVD = true;
    showTargetCompletionDate = true;
    showTagNo = true;
    showTagType = false;
    showTagName = false;
    showDocType = true;
    showDocType2 = false;
    showRedType = true;
    showDE= false;
    showRedReqType = true;
    showMcdrConstrRedCat = false;
    showContainsChange = false;
    showPunchType = true;
    showReviewStatus = false;
    showACNumber = false;
    showRaisedBy = false;
    showChangeId = false;
    showRaisedDate = false;
    showStatusDate = false;
    showMaterialReference = false;
    showClassification = false;
    showAttachments = false;
    showCowDocNo = false;
    showBarcode = false;
    showPreCommDates = false;
    showMcPlan = false;
    showMcForecast = false;
    showRfsuPlan = false;
    showRfsuForecast = false;
    isItrInCurrentWeek = false;
    showConstraintFlag = false;
    //showAddconstraints = false;
    showITRConstraintDueDate = true;
    showITRConstraintRaisedByUser = false;
    showITRConstraintRaisedByTeam = false;
    showITRConstraintRaisedDate = false;
    showITRConstraintStatusDate = false;
    showITRConstraintResponsibleUser = false;
    showITRConstraintAdditionalResponsibleUser = false;
    showITRConstraintTypeName = true;
    showFMRorPO = false;
    showWaypointRequired = false;
    showITRConstraintDescription = true;
    showITRConstraintStatus = false;
    showRFSUWalkdownForecast = false;
    showITRConstraintId = false;
    showFMRorPOETADate = false; 
    showAssociatedNPW = false;
    showAssociatedPLI = false;
    showAssociatedQVD = false;
    showLatestITRComment = true;
    showAssociatedComment = false;
    showAQVDContractor = false;
    showPLIActionedBy = false;
    showITRConstraintResponsibleGroup = false;
    showITRConstraintCount = true;
    showPriority = false;
    showActivityID = false;
    showActivityName = false;
    showRFO = true;
    showWaypoint = false;
    showAreaBreakdown = false;
    showMilestone = false;
    showManhours = false
    showWorkArea = false;
    showTagDiscipline = true;
    showRFOPlan = false;
    showForecastClosureDate = true;
    showLastConstraintDate = true;
    showExceptionResponsbilePerson= true;
    showActionBy = false;
    showPliImplementationTeam = false;
    showExceptionNumber = true;
    showExceptionStatus = true;
    showLatestExceptionComment = true;
    showO3ConstraintID = false;
    showExceptionAdditionalResponsbilePerson = false;
    showPriorityNo = false;
    showCriticalPath = false;
    showExceptionPriority = true;
    showExceptionPhase = true;
    showMaterialsTotalCount = false;
    showMCActual = false;
    showRFSUActual = false;
    showPLIForecastClosureDate = true;
    showCategory = true;
    showMaterialReviewAssignedTo = false;
    showMaterialReviewStatus = false;
    showFmrLatestETA = false;
    showRequiredByDate = false;
    showPostRfoBarcode = false;
    showPostRfoQVDNumber = true;
    showPostRfoTagNumber = true;
    showPostRfoExceptionNumber = true;
    showPostRfoPli = true;
    showPostRfoNpw = true;
    showPostRfoPascr = true;
    showPostRfoRedline = true;
    showPostRFOWorkExpectedClosureDate = true;
    showWorkOrderNum = true;
    showETransferNumber = true;
    showPostRfoPliCreatedDate = false;
    showPostRfoNpwIssueDate = false;
    showPostRfoActualDate = false;
    showRedlineExceptionNumber = true;
    showPostRfoResponsiblePersonOrGroup = true;
    showPriorityManualOverride = true;
    showRevision = true;
    showExpectedTimeOfCompletion = false;
}
