import { ExceptionsState } from './model';
import { ExceptionsActions, ExceptionsActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: ExceptionsState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isDataReadyToProcess: false,
    recordsForDeletion: 0,
    isAutomaticUploadInProgress: true,
};

export function reducer(state = initialState, action: ExceptionsActions) {
    switch (action.type) {
        case ExceptionsActionTypes.ExceptionsUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case ExceptionsActionTypes.ExceptionsUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case ExceptionsActionTypes.ExceptionsUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case ExceptionsActionTypes.ExceptionsSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case ExceptionsActionTypes.ExceptionsFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case ExceptionsActionTypes.ExceptionsClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case ExceptionsActionTypes.ExceptionsTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ExceptionsActionTypes.ExceptionsTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ExceptionsActionTypes.ExceptionsTemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ExceptionsActionTypes.ExceptionsValidateDeltaRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ExceptionsActionTypes.ExceptionsValidateDeltaSuccess: {
            return {
                ...state,
                isLoading: false,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case ExceptionsActionTypes.ExceptionsValidateDeltaError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ExceptionsActionTypes.ExceptionsDataInfoSuccess: {
            return {
                ...state,
                isDataReadyToProcess: action.payload.readyToProcess[0],
                recordsForDeletion: action.payload.recordsForDeletion[0],
                isAutomaticUploadInProgress: action.payload.isAutomaticUploadInProgress,
            };
        }
        case ExceptionsActionTypes.ExceptionsDataInfoError: {
            return {
                ...state,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case ExceptionsActionTypes.ExceptionsDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ExceptionsActionTypes.ExceptionsDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ExceptionsActionTypes.ExceptionsDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ExceptionsActionTypes.ExceptionsAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'Exceptions';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}
