import { Action } from '@ngrx/store';
import { BlueZoneDataPagination, BlueZoneDTO, BlueZoneFilter } from './model';
import * as moment from 'moment';
import { UserDetail } from '../common.model';
import { CommentDetailDTO } from '../detailed-status/model';
import { PhaseType } from 'src/app/enums';

export enum BlueZoneActionTypes {
    BlueZoneFilterRequest = '[BlueZone] Filter Request',
    BlueZoneFilterSuccess = '[BlueZone] Filter Success',
    BlueZoneFilterError = '[BlueZone] Filter Error',
    BlueZoneFilterPropertyUpdate = '[BlueZone] Property Update',
    BlueZoneFilterReset = '[BlueZone] Filter Reset',
    BlueZoneExportToExcelRequest = '[BlueZone] Export to Excel Request',
    BlueZoneExportToExcelSuccess = '[BlueZone] Export to Excel Success',
    BlueZoneExportToExcelError = '[BlueZone] Export to Excel Error',
    BlueZoneExportOnePagerRequest = '[BlueZone] Export One Pager Request',
    BlueZoneExportOnePagerSuccess = '[BlueZone] Export One Pager Success',
    BlueZoneExportOnePagerError = '[BlueZone] Export One Pager Error',
    BlueZoneRFSUForecastDateUpdateRequest = '[BlueZone] RFSU Forecast Date Update Request',
    BlueZoneRFSUForecastDateUpdateSuccess = '[BlueZone] RFSU Forecast Date Update Success',
    BlueZoneRFSUForecastDateUpdateError = '[BlueZone] RFSU Forecast Date Update Error',
    BlueZoneAddCommentRequest = '[BlueZone] Add comment Request',
    BlueZoneAddCommentSuccess = '[BlueZone] Add comment Success',
    BlueZoneAddCommentError = '[BlueZone] Add comment Error',
    BlueZoneRemoveCommentRequest = '[BlueZone] Remove Comment Request',
    BlueZoneRemoveCommentSuccess = '[BlueZone] Remove Comment Success',
    BlueZoneRemoveCommentError = '[BlueZone] Remove Comment Error',
    BlueZoneAddBulkCommentRequest = '[BlueZone] Add bulk comment Request',
    BlueZoneAddBulkCommentSuccess = '[BlueZone] Add bulk comment Success',
    BlueZoneAddBulkCommentError = '[BlueZone] Add bulk comment Error',
    BlueZoneUpdateBacklogCatRequest = '[BlueZone] Update Backlog Cat Request',
    BlueZoneUpdateBacklogCatSuccess = '[BlueZone] Update Backlog Cat Success',
    BlueZoneUpdateBacklogCatError = '[BlueZone] Update Backlog Cat Error',
    BlueZoneClearFlagColorRequest = '[BlueZone] Clear Flag Color Request',
    BlueZoneClearFlagColorSuccess = '[BlueZone] Clear Flag Color Success',
    BlueZoneClearFlagColorError = '[BlueZone] Clear Flag Color Error',
    BlueZoneSetFlagColorRequest = '[BlueZone] Set Flag Color Request',
    BlueZoneSetFlagColorSuccess = '[BlueZone] Set Flag Color Success',
    BlueZoneSetFlagColorError = '[BlueZone] Set Flag Color Error',
    BlueZoneToggleWalkdownCommitmentRequest = '[BlueZone] Toggle Walkdown Commitment Request',
    BlueZoneToggleWalkdownCommitmentSuccess = '[BlueZone] Toggle Walkdown Commitment Success',
    BlueZoneToggleWalkdownCommitmentError = '[BlueZone] Toggle Walkdown Commitment Error',
}

export class BlueZoneRemoveCommentRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneRemoveCommentRequest;

    constructor(public payload: { id: number; lastComment: CommentDetailDTO; entityId: string | number }) {
        // This is intentional
    }
}

export class BlueZoneRemoveCommentSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneRemoveCommentSuccess;

    constructor(public payload: { id: number; lastComment: CommentDetailDTO; entityId: string | number }) {
        // This is intentional
    }
}

export class BlueZoneRemoveCommentError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneRemoveCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneFilterRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneFilterRequest;

    constructor(public payload: BlueZoneFilter = null) {
        // This is intentional
    }
}

export class BlueZoneFilterSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneFilterSuccess;

    constructor(public payload: BlueZoneDataPagination) {
        // This is intentional
    }
}

export class BlueZoneFilterError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneFilterError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneFilterPropertyUpdate implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {
        // This is intentional
    }
}

export class BlueZoneFilterReset implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneFilterReset;
}

export class BlueZoneRFSUForecastDateUpdateRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneRFSUForecastDateUpdateRequest;

    constructor(
        public payload: {
            subsystem: string;
            type: PhaseType;
            rfsuForecastDate: moment.Moment;
            discipline: string;
            allDisciplines: boolean;
        }
    ) {
        // This is intentional
    }
}

export class BlueZoneRFSUForecastDateUpdateSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneRFSUForecastDateUpdateSuccess;
}

export class BlueZoneRFSUForecastDateUpdateError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneRFSUForecastDateUpdateError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneAddCommentRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneAddCommentRequest;

    constructor(public payload: { entityId: string | number; description: string; mentions: UserDetail[] }) {
        // This is intentional
    }
}

export class BlueZoneAddCommentSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneAddCommentSuccess;

    constructor(public payload: { entityId: string | number; description: string; mentions: UserDetail[] }) {
        // This is intentional
    }
}

export class BlueZoneAddCommentError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneAddCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneAddBulkCommentRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneAddBulkCommentRequest;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {
        // This is intentional
    }
}

export class BlueZoneAddBulkCommentSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneAddBulkCommentSuccess;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {
        // This is intentional
    }
}

export class BlueZoneAddBulkCommentError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneAddBulkCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneExportToExcelRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneExportToExcelRequest;
}

export class BlueZoneExportToExcelSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneExportToExcelSuccess;

    constructor(public payload: any) {
        // This is intentional
    }
}

export class BlueZoneExportToExcelError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneExportToExcelError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneExportOnePagerRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneExportOnePagerRequest;
}

export class BlueZoneExportOnePagerSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneExportOnePagerSuccess;

    constructor(public payload: any) {
        // This is intentional
    }
}

export class BlueZoneExportOnePagerError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneExportOnePagerError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneUpdateBacklogCatRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneUpdateBacklogCatRequest;

    constructor(public payload: BlueZoneDTO) {
        // This is intentional
    }
}
export class BlueZoneUpdateBacklogCatSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneUpdateBacklogCatSuccess;
}
export class BlueZoneUpdateBacklogCatError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneUpdateBacklogCatError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneClearFlagColorRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneClearFlagColorRequest;

    constructor() {
        // This is intentional
    }
}

export class BlueZoneClearFlagColorSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneClearFlagColorSuccess;

    constructor() {
        // This is intentional
    }
}

export class BlueZoneClearFlagColorError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneClearFlagColorError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneSetFlagColorRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneSetFlagColorRequest;

    constructor(public payload: { subsystem: string; color: string }) {
        // This is intentional
    }
}

export class BlueZoneSetFlagColorSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneSetFlagColorSuccess;

    constructor(public payload: { subsystem: string; color: string }) {
        // This is intentional
    }
}

export class BlueZoneSetFlagColorError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneSetFlagColorError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class BlueZoneToggleWalkdownCommitmentRequest implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneToggleWalkdownCommitmentRequest;

    constructor(public payload: { subsystem: string; committed: boolean }) {
        // This is intentional
    }
}

export class BlueZoneToggleWalkdownCommitmentSuccess implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneToggleWalkdownCommitmentSuccess;

    constructor(public payload: { subsystem: string; committed: boolean }) {
        // This is intentional
    }
}

export class BlueZoneToggleWalkdownCommitmentError implements Action {
    readonly type = BlueZoneActionTypes.BlueZoneToggleWalkdownCommitmentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export type BlueZoneActions =
    | BlueZoneFilterRequest
    | BlueZoneFilterSuccess
    | BlueZoneFilterError
    | BlueZoneFilterPropertyUpdate
    | BlueZoneFilterReset
    | BlueZoneRFSUForecastDateUpdateRequest
    | BlueZoneRFSUForecastDateUpdateSuccess
    | BlueZoneRFSUForecastDateUpdateError
    | BlueZoneAddCommentRequest
    | BlueZoneAddCommentSuccess
    | BlueZoneAddCommentError
    | BlueZoneAddBulkCommentRequest
    | BlueZoneAddBulkCommentSuccess
    | BlueZoneAddBulkCommentError
    | BlueZoneExportToExcelRequest
    | BlueZoneExportToExcelSuccess
    | BlueZoneExportToExcelError
    | BlueZoneExportOnePagerRequest
    | BlueZoneExportOnePagerSuccess
    | BlueZoneExportOnePagerError
    | BlueZoneRemoveCommentRequest
    | BlueZoneRemoveCommentSuccess
    | BlueZoneRemoveCommentError
    | BlueZoneUpdateBacklogCatRequest
    | BlueZoneUpdateBacklogCatSuccess
    | BlueZoneUpdateBacklogCatError
    | BlueZoneClearFlagColorRequest
    | BlueZoneClearFlagColorSuccess
    | BlueZoneClearFlagColorError
    | BlueZoneSetFlagColorRequest
    | BlueZoneSetFlagColorSuccess
    | BlueZoneSetFlagColorError
    | BlueZoneToggleWalkdownCommitmentRequest
    | BlueZoneToggleWalkdownCommitmentSuccess
    | BlueZoneToggleWalkdownCommitmentError;
