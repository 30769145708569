import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile, take, catchError } from 'rxjs/operators';
import { OrderDirection, Contractor } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AddContractorMappingPopupComponent } from './add-popup/add-contractor-mapping-popup.component';
import { BaseComponent } from 'src/app/components/base.component';
import { ContractorMappingDTO } from 'src/app/models/contractor-mapping-dto';
import { ContractorsMappingService } from 'src/app/services/api/webapi-services/contractor-mapping.service';
import { forkJoin } from 'rxjs';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-contractors-mappings',
    templateUrl: './contractors-mappings.component.html',
    styleUrls: ['./contractors-mappings.component.scss'],
})
export class ContractorsMappingsComponent extends BaseComponent implements OnInit {
    resultsLength = 0;
    pageSize = 10;
    sortBy: string = 'changeType';
    direction = OrderDirection.Desc;
    isLoading = false;
    filterForm: UntypedFormGroup;
    displayedColumns = ['changeType', 'mapping', 'contractNo', 'projectTeamName', 'edit', 'remove'];
    dataSource = new MatTableDataSource<ContractorMappingDTO>();
    contractors: Contractor[] = [];
    readonly changeTypes = ['DCN', 'RFI', 'NCR', 'MAC DCN', 'RFI (iPIMS)', 'SID'];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private popupService: PopupService,
        private contractorMappingService: ContractorsMappingService,
        private lookupService: LookupService,
        private toastService: ToastService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.isLoading = true;
        forkJoin([this.lookupService.searchContractors(null, null, null, []), this.contractorMappingService.get()])
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error('Error occurred while getting data. Please contact Program Administrator.');
                })
            )
            .subscribe(
                ([contractors, mappings]) => {
                    this.contractors = contractors;
                    this.dataSource = new MatTableDataSource(mappings);
                    this.resultsLength = this.dataSource.data.length;
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.isLoading = false;
                },
                (error) => {
                    this.isLoading = false;
                    this.toastService.Error(error);
                }
            );
    }

    remove(element: ContractorMappingDTO) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete ${element.mapping} mapping?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.isLoading = true;
                    this.contractorMappingService
                        .remove(element.id)
                        .pipe(takeWhile(() => this.isAlive === true))
                        .subscribe(
                            () => {
                                let data = this.dataSource.data.filter((s) => s.id !== element.id);
                                this.dataSource = new MatTableDataSource(data);
                                this.resultsLength = this.dataSource.data.length;
                                this.dataSource.sort = this.sort;
                                this.dataSource.paginator = this.paginator;
                                this.isLoading = false;
                            },
                            () => {
                                this.isLoading = false;
                                this.toastService.Error(
                                    `Error occurred while deleting ${element.mapping} mapping. Please contact Program Administrator.`
                                );
                            }
                        );
                }
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(
            new PopupSettings(AddContractorMappingPopupComponent, 450, 430, {
                changeTypes: this.changeTypes,
                contractors: this.contractors,
            })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    const data = this.dataSource.data;
                    data.push(result.data);
                    this.dataSource.data = data;
                    this.resultsLength = this.dataSource.data.length;
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                }
            });
    }

    enterEditMode(contractorMapping: ContractorMappingDTO) {
        contractorMapping.isInEditMode = !contractorMapping.isInEditMode;
        contractorMapping.prevContractNo = contractorMapping.contractNo;
        contractorMapping.prevChangeType = contractorMapping.changeType;
        contractorMapping.prevMapping = contractorMapping.mapping;
        contractorMapping.prevProjectTeamName = contractorMapping.projectTeamName;
    }

    save(contractorMapping: ContractorMappingDTO) {
        contractorMapping.isInEditMode = !contractorMapping.isInEditMode;

        if (
            contractorMapping.contractNo !== contractorMapping.prevContractNo ||
            contractorMapping.projectTeamName !== contractorMapping.prevProjectTeamName ||
            contractorMapping.changeType !== contractorMapping.prevChangeType ||
            contractorMapping.mapping !== contractorMapping.prevMapping
        ) {
            this.isLoading = true;
            this.contractorMappingService
                .update(contractorMapping)
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe(
                    () => {
                        this.isLoading = false;
                    },
                    (error) => {
                        this.isLoading = false;
                        if (error.status === 409) {
                            this.toastService.Error(
                                `Mapping entered ${contractorMapping.changeType} ${contractorMapping.mapping} already exists in the SCMT.`
                            );
                        } else {
                            this.toastService.Error(
                                `Error occurred while updating ${contractorMapping.mapping} mapping. Please contact Program Administrator.`
                            );
                        }
                        this.rollbackChanges(contractorMapping);
                    }
                );
        }
    }

    cancelEdit(contractorMapping: ContractorMappingDTO) {
        contractorMapping.isInEditMode = !contractorMapping.isInEditMode;
        this.rollbackChanges(contractorMapping);
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        this.paginator.pageIndex = newPage - 1;
        this.dataSource.paginator = this.paginator;
    }

    onContractorsClosed(isOpen: boolean, element: ContractorMappingDTO) {
        if (!isOpen) {
            element.projectTeamName = this.contractors.find((s) => s.contractNo === element.contractNo).projectTeamName;
        }
    }

    private rollbackChanges(contractorMapping: ContractorMappingDTO) {
        contractorMapping.contractNo = contractorMapping.prevContractNo;
        contractorMapping.changeType = contractorMapping.prevChangeType;
        contractorMapping.mapping = contractorMapping.prevMapping;
        contractorMapping.projectTeamName = contractorMapping.prevProjectTeamName;
    }
}
