import { Component, OnInit, ViewChild, ChangeDetectorRef, EventEmitter, NgZone } from '@angular/core';
import { BaseComponent } from '../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, map, take } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSort, Sort } from '@angular/material/sort';
import {
    ChangeValidationFilterRequest,
    ChangeValidationFilterPropertyUpdate,
    ChangeValidationFilterReset,
    ChangeValidationExportToExcelRequest,
} from 'src/app/store/change-validation/actions';
import { Router } from '@angular/router';
import {
    ChangeValidationFilter,
    ChangeValidationChangeDocumentDTO,
    OrderDirection,
    ShowHideColumns,
} from 'src/app/store/change-validation/model';
import { Gooc, RFO, Contractor } from 'src/app/store/common.model';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ValidationFormService } from 'src/app/services/api/webapi-services/validation-form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { CalendarColumn, CheckListColumn } from 'src/app/store/common.model';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ValidStatuses } from 'src/app/models/validation-statuses';
import { AddNewChangeComponent } from './addNewChange/add-new-change.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-change-validation',
    templateUrl: './change-validation.component.html',
    styleUrls: ['./change-validation.component.scss'],
})
export class ChangeValidationComponent extends BaseComponent implements OnInit {
    showFilterPanel=true;
    filterForm: UntypedFormGroup;
    isPowerUser = false;
    isActiveFilter = false;
    projectTeamNames: string[] = [];
    isLoading = false;
    resultsLength = 0;
    data: ChangeValidationChangeDocumentDTO[] = [];
    showHideColumns = new ShowHideColumns();
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    singleViewEnabled = true;
    sortBy$ = this.store.select((state) => state.changeValidationState.filter.sortBy);
    direction$ = this.store.select((state) => state.changeValidationState.filter.direction);
    registerGridData$ = this.store.select((state) => state.changeValidationState.dataPagination);
    registerGridLoader$ = this.store.select((state) => state.changeValidationState.isLoading);
    registerFilter$ = this.store.select((state) => state.changeValidationState.filter);
    setValidatorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setCWPInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    sortBy = 'number';
    pageSize = 25;
    direction: OrderDirection = OrderDirection.Desc;
    disciplines: string[] = [];
    readonly isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);
    displayedColumns: string[] = [
        'number',
        'revision',
        'changeType',
        'status',
        'stepStatus',
        'implementationStatus',
        'createdDate',
        'issueDate',
        'validationStatus',
        'elevalidationStatus',
        'ictvalidationStatus',
        'smpvalidationStatus',
        'title',
        'discipline',
        'subsystem',
        'rfo',
        'rfsuPlan',
        'rfsuForecast',
        'rfsuActual',
        'mcPlan',
        'mcForecast',
        'mcActual',
        'rfoPlan',
        'rfoForecast',
        'rfoActual',
        'projectTeamName',
        'contractor',
        'fieldImplementationContractor',
        // 'impactedArea',
        // 'impactedCWP',
        'dataUpdated',        
        'validator'        
    ];
    subsystemAutocompleteDisplayedColumns = ['id', 'name'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    changeTypes: string[] = [];
    changeStatuses: string[] = [];
    rfo: string;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    columnNumber$ = this.store.select((state) => state.changeValidationState.filter.columnNumber);
    columnSubsystem$ = this.store.select((state) => state.changeValidationState.filter.columnSubsystem);
    columnRfo$ = this.store.select((state) => state.changeValidationState.filter.columnRfo);
    columnRevision$ = this.store.select((state) => state.changeValidationState.filter.columnRevision);
    columnType$ = this.store.select((state) => state.changeValidationState.filter.columnType);
    columnChangeStatus$ = this.store.select((state) => state.changeValidationState.filter.columnChangeStatus);
    columnimplementationStatus$ = this.store.select((state) => state.changeValidationState.filter.columnimplementationStatus);
    columnIssueDate$ = this.store.select((state) => state.changeValidationState.filter.columnIssueDate);
    columnValidationStatus$ = this.store.select((state) => state.changeValidationState.filter.columnValidationStatus);
    columnELEValidationStatus$ = this.store.select((state) => state.changeValidationState.filter.columnELEValidationStatus);
    columnICTValidationStatus$ = this.store.select((state) => state.changeValidationState.filter.columnICTValidationStatus);
    columnSMPValidationStatus$ = this.store.select((state) => state.changeValidationState.filter.columnSMPValidationStatus);
    columnTitle$ = this.store.select((state) => state.changeValidationState.filter.columnTitle);
    columnDiscipline$ = this.store.select((state) => state.changeValidationState.filter.columnDiscipline);
    columnProjectTeamName$ = this.store.select((state) => state.changeValidationState.filter.columnProjectTeamName);
    columnCreatedDate$ = this.store.select((state) => state.changeValidationState.filter.columnCreatedDate);
    columnrfsuPlan$ = this.store.select((state) => state.changeValidationState.filter.columnrfsuPlan);
    columnrfsuForecast$ = this.store.select((state) => state.changeValidationState.filter.columnrfsuForecast);
    columnrfsuActual$ = this.store.select((state) => state.changeValidationState.filter.columnrfsuActual);
    columnmcPlan$ = this.store.select((state) => state.changeValidationState.filter.columnmcPlan);
    columnmcForecast$ = this.store.select((state) => state.changeValidationState.filter.columnmcForecast);
    columnmcActual$ = this.store.select((state) => state.changeValidationState.filter.columnmcActual);
    columnrfoPlan$ = this.store.select((state) => state.changeValidationState.filter.columnrfoPlan);
    columnrfoForecast$ = this.store.select((state) => state.changeValidationState.filter.columnrfoForecast);
    columnrfoActual$ = this.store.select((state) => state.changeValidationState.filter.columnrfoActual);      
    columnArea$ = this.store.select((state) => state.changeValidationState.filter.columnArea);
    columnCWP$ = this.store.select((state) => state.changeValidationState.filter.columnCWP);
    columnDataUpdated$ = this.store.select((state) => state.changeValidationState.filter.columnDataUpdated);
    columnValidator$ = this.store.select((state) => state.changeValidationState.filter.columnValidator);    
    columnContractor$ = this.store.select((state) => state.changeValidationState.filter.columnContractor);  
    columnFieldImplementationContractor$ = this.store.select((state) => state.changeValidationState.filter.columnFieldImplementationContractor);  
    columnStepStatus$ = this.store.select((state) => state.changeValidationState.filter.columnStepStatus);
    columnType: CheckListColumn = null;
    columnDataUpdated: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnArea: CheckListColumn = null;
    columnCWP: CheckListColumn = null;
    columnIssueDate: CalendarColumn = null;
    columnNumber: CheckListColumn = null;
    columnSubsystem: CheckListColumn = null;
    columnRfo: CheckListColumn = null;
    columnProjectTeamName: CheckListColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnRevision: CheckListColumn = null;
    columnChangeStatus: CheckListColumn = null;
    columnimplementationStatus: CheckListColumn = null;
    columnTitle: CheckListColumn = null;
    columnValidationStatus: CheckListColumn = null;
    columnELEValidationStatus: CheckListColumn = null;
    columnICTValidationStatus: CheckListColumn = null;
    columnSMPValidationStatus: CheckListColumn = null;
    columnValidator: CheckListColumn = null;
    columnrfsuPlan: CalendarColumn = null;
    columnrfsuForecast: CalendarColumn = null;
    columnrfsuActual: CalendarColumn = null;
    columnmcPlan: CalendarColumn = null;
    columnmcForecast: CalendarColumn = null;
    columnmcActual: CalendarColumn = null;
    columnrfoPlan: CalendarColumn = null;
    columnrfoForecast: CalendarColumn = null;
    columnrfoActual: CalendarColumn = null;
    columnContractor: CheckListColumn = null;
    columnFieldImplementationContractor: CheckListColumn = null;
    columnStepStatus: CheckListColumn = null;
    rfos: RFO[] = [];
    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private validationFormService: ValidationFormService,
        private toastService: ToastService,
        private ngZone: NgZone,
        private roleService: RoleService,
        private projectTeamsService: ProjectTeamsService,
        private popupSvc: PopupService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'inactive',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/error.svg')
        );
        this.iconRegistry.addSvgIcon(
            'completed',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/checked.svg')
        );
        this.iconRegistry.addSvgIcon(
            'testpackreview',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/accept-gray.svg')
        );


        this.filterForm = this.formSvc.createSimpleForm(new ChangeValidationFilter());
    }

    ngOnInit() {
        this.projectTeamsService
            .getValidatorTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.registerFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.paginator.pageIndex = filter.page;
            this.paginator.pageSize = filter.take;
            this.pageSize = filter.take;
            this.direction = filter.direction;
            this.showHideColumns = filter.showHideColumns;
        });

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: string[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting discipline. Please contact Program Administrator.'
                    );
                }
            );

        this.lookupService
            .getChangeTypes()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (types: string[]) => (this.changeTypes = types.filter(x => x !== 'SID' && x !== 'Call-Off' && x !== 'TMOC' && x !== 'SC Call-Off')),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting change types. Please contact Program Administrator.'
                    );
                }
            );

        this.lookupService
            .getDesignStatuses()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (statuses: string[]) => (this.changeStatuses = statuses),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting change statuses. Please contact Program Administrator.'
                    );
                }
            );

        this.registerGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.registerGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.columnType$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnType = data));
        this.columnDataUpdated$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnDataUpdated = data));
        this.columnDiscipline$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDiscipline = data));
        this.columnArea$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnArea = items));
        this.columnCWP$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnCWP = items));
        this.columnIssueDate$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnIssueDate = items));
        this.columnNumber$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnNumber = items));
        this.columnSubsystem$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnSubsystem = items));
        this.columnRfo$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnRfo = items));
        this.columnCreatedDate$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnCreatedDate = items));
        this.columnrfsuPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnrfsuPlan = items));
        this.columnrfsuForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnrfsuForecast = items));
        this.columnrfsuActual$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnrfsuActual = items));
        this.columnmcPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnmcPlan = items));
        this.columnmcForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnmcForecast = items));
        this.columnmcActual$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnmcActual = items));
        this.columnrfoPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnrfoPlan = items));
        this.columnrfoForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnrfoForecast = items));
        this.columnrfoActual$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnrfoActual = items));
        this.columnProjectTeamName$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnProjectTeamName = items));
        this.columnRevision$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnRevision = items));
        this.columnChangeStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnChangeStatus = items));
        this.columnimplementationStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnimplementationStatus = items));
        this.columnTitle$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnTitle = items));
        this.columnValidationStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnValidationStatus = items));
        this.columnELEValidationStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnELEValidationStatus = items));
        this.columnICTValidationStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnICTValidationStatus = items));
        this.columnSMPValidationStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnSMPValidationStatus = items));
        this.columnValidator$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnValidator = items));
        this.columnContractor$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnContractor = items));
        this.columnFieldImplementationContractor$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnFieldImplementationContractor = items));
        this.columnStepStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnStepStatus = items));

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new ChangeValidationFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new ChangeValidationFilterRequest());
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => (this.sortBy = sortBy));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => (this.direction = direction));
        this.isActiveFilter = (
            this.roleService.isInRole(Constants.applicableGroups.Admin) ||
            this.roleService.isInRole(Constants.applicableGroups.SetInactiveNPW)  ||
            this.roleService.isInRole(Constants.applicableGroups.Validator3GP) || 
            this.roleService.isInRole(Constants.applicableGroups.Validator3GI) || 
            this.roleService.isInRole(Constants.applicableGroups.NPWValidation)
            ) ? true : false;
        this.isPowerUser = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.store.dispatch(new ChangeValidationFilterRequest());
    }

    openValidationView(validationId: number, id: number): void {
        if (!validationId) {
            this.isLoading = true;
            this.validationFormService
                .createValidationDocument(id)
                .pipe(takeWhile(() => this.isAlive))
                .subscribe(
                    (validId: number) => this.ngZone.run(() => this.router.navigate([`/validation/${validId}`])),
                    () => {
                        this.toastService.Error(
                            'Error occurred while creating validation document. Please contact Program Administrator.'
                        );
                    }
                );
        } else {
            this.ngZone.run(() => this.router.navigate([`/validation/${validationId}`]));
        }
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new ChangeValidationFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new ChangeValidationFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new ChangeValidationFilterRequest());
    }

    getValidators = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchUsers(search, page, take);
    };

    resetFilters() {
        this.store.dispatch(new ChangeValidationFilterReset());
        this.setValidatorsInput.emit(new SetInputEventArgs(false, ''));
        this.setCWPInput.emit(new SetInputEventArgs(false, ''));
        this.setSubsystemInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false,''));
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = 10;
        this.filterForm.setValue(new ChangeValidationFilter());
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
    }

    exportToExcel() {
        this.store.dispatch(new ChangeValidationExportToExcelRequest());
    }

    activeChange(change: MatRadioChange) {
        this.store.dispatch(
            new ChangeValidationFilterPropertyUpdate({
                key: 'isActive',
                value: change.value,
            })
        );
    }

    systemisationChange(change: MatRadioChange) {
        //if (change.value !== 'all') {
        //    this.filterForm.controls.validationStatuses.setValue([ValidStatuses.Completed]);
        //    this.filterForm.controls.validationStatuses.disable();
        //} else {
        //    this.filterForm.controls.validationStatuses.setValue([]);
        //    this.filterForm.controls.validationStatuses.enable();
        //}

        this.store.dispatch(
            new ChangeValidationFilterPropertyUpdate({
                key: 'isSystemisationComplete',
                value: change.value,
            })
        );
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getAreaBreakdown = (search = '', take = 30, page = 0) =>
    this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);

    getRFSUContractors = (search = '', take = 10, page = 0) => {
            return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
                map((rfsuContractors: Contractor[]) => {
                    return rfsuContractors;
                })
            );
        };    

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getCWPs = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchCWPs(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            takeWhile(() => this.isAlive),
            map((cwps: string[]) => cwps)
        );
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    search() {
        this.updateFilterByFormProperties()
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new ChangeValidationFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new ChangeValidationFilterRequest());
    }

    private getLatestFilterData(): ChangeValidationFilter {
        let filter: ChangeValidationFilter;
        this.store.pipe(select((x) => x.changeValidationState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getNumbers = (search = '', take = 30, page = 0) =>
        this.lookupService.searchNumbersWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getDisciplines = (search = '', take = 30, page = 0) =>
        this.lookupService.searchDisciplinesWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getChangeTypes = (search = '', take = 30, page = 0) =>
        this.lookupService.searchChangeTypesWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getDataUpdated = (search = '') =>
        this.lookupService.searchDataUpdatedWithChangeValidationFilter(search, this.getLatestFilterData());

    getStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchStatusesWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getImplementationStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchImplementationStatusesWithChangeValidationFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );
    getValidationStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchValidationStatusesWithChangeValidationFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );

    getELEValidationStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchELEValidationStatusesWithChangeValidationFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );
        
    getICTValidationStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchICTValidationStatusesWithChangeValidationFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );
    
    getSMPValidationStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSMPValidationStatusesWithChangeValidationFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );

    getImpactedAreas = (search = '', take = 30, page = 0) =>
        this.lookupService.searchImpactedAreasWithChangeValidationFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );

    getImpactedCWPs = (search = '', take = 30, page = 0) =>
        this.lookupService.searchImpactedCWPsWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getProjectTeams = (search = '', take = 30, page = 0) =>
        this.lookupService.searchProjectTeamsWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getContractors = (search = '', take = 30, page = 0) =>
        this.lookupService.searchContractorsWithValidationFilter(search, take, page, this.getLatestFilterData());

    getFieldImplementationContractors = (search = '', take = 30, page = 0) =>
        this.lookupService.searchFieldImplementationContractorWithValidationFilter(search, take, page, this.getLatestFilterData());

    getRevisions = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRevisionsWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getTitles = (search = '', take = 30, page = 0) =>
        this.lookupService.searchTitlesWithChangeValidationFilter(search, take, page, this.getLatestFilterData());    

    getValidatorsColumn = (search = '', take = 30, page = 0) =>
        this.lookupService.searchValidatorsWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getSubsystemsColumn = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getRfosColumn = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRfoWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    getStepStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchStepStatusesWithChangeValidationFilter(search, take, page, this.getLatestFilterData());

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        isSortingOff: boolean = false,
        showBlankOption: boolean = false
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(
                HeaderChecklistFilterComponent,
                columnName === 'title' ? 800 : 400,
                620,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    placeHolder: placeholder,
                    searchFunc: searchFunc,
                    selectedItems: selected ? [...selected.items] : [],
                    onlyBlanks: selected ? selected.onlyBlanks : false,
                    excludeBlanks: selected ? selected.excludeBlanks : false,
                    isSortingOff: isSortingOff,
                    showCounts,
                    showInputSearch: showInputSearch,
                    showBlankOptions : showBlankOption,
                    action: (data) => {
                        if(columnName === 'currentStep' && (data.selectedItems.indexOf('N/A') > -1 || data.selectedItems.indexOf('') > -1))
                        {
                            data.selectedItems = data.selectedItems.map(item => {
                                item === 'N/A' ? item = null : item === '' ? item = '0' : item=item;
                                return item;
                            });
                        }     
                        let value = new CheckListColumn();
                        value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                        value.excludeBlanks = data.excludeBlanks;
                        value.onlyBlanks = data.onlyBlanks;
                        if (columnName === 'validator' && !value.selectAll) value.items = value.items.filter(x => x !=="");
                        this.filterForm.controls[propertyName].setValue(value);

                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                        this.search();
                    },
                    resetColumnAction: () => {
                        this.store.dispatch(
                            new ChangeValidationFilterPropertyUpdate({
                                key: propertyName,
                                value: null,
                            })
                        );
                    },
                    cancelAction: () => {
                        this.store.dispatch(
                            new ChangeValidationFilterPropertyUpdate({
                                key: propertyName,
                                value: this.filterForm.controls[propertyName].value,
                            })
                        );
                    },
                }
            )
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            },
            345,
            580)
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new ChangeValidationFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new ChangeValidationFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'number', direction: OrderDirection.Desc },
                })
            );
        }
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new ChangeValidationFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.filterForm, key, value);
                }
            }
        }
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    addNewChange() {
        this.popupSvc.openPopup(new PopupSettings(AddNewChangeComponent, 1300, 600));
    }

    reverseValue(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };
        this.store.dispatch(
            new ChangeValidationFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showHideColumns,
            })
        );
        this.filterForm.controls.showHideColumns.setValue(this.showHideColumns);
    }

    resetColumns() {
        this.showHideColumns = new ShowHideColumns();
    }

    toggleViews(){
        this.singleViewEnabled = ! this.singleViewEnabled;
        this.filterForm.controls.singleViewEnabled.setValue(this.singleViewEnabled);
        this.store.dispatch(
            new ChangeValidationFilterPropertyUpdate({
                key: 'singleViewEnabled',
                value: this.singleViewEnabled,
            })
        );
        this.store.dispatch(new ChangeValidationFilterRequest());
    }
}
