import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastService } from 'src/app/services/shared/toast.service';
import { withLatestFrom, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    IcicleActionTypes,
    IcicleRequestSuccess,
    IcicleRequestError,
    IcicleRequest,
    IcicleWeekRangeSet,
} from './actions';
import { IcicleService } from 'src/app/services/api/webapi-services/reports/icicle.service';

@Injectable()
export class IcicleEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private icicleService: IcicleService,
        private toastService: ToastService
    ) {}

    @Effect()
    icicleRequest$ = this.actions$.pipe(
        ofType<IcicleRequest>(IcicleActionTypes.IcicleRequest),
        withLatestFrom(
            this.store.select((store) => store.icicleState.filter),
            this.store.select((store) => store.icicleState.startDate)
        ),
        mergeMap(([action, icicleFilter, startDate]) => {
            return this.icicleService.getIcicleForecastData(icicleFilter, action.payload, startDate).pipe(
                switchMap((data) => {
                    if (data.length === 0) {
                        return this.icicleService
                            .getIciclePlanData(icicleFilter, action.payload, startDate)
                            .pipe(map((planData) => new IcicleRequestSuccess({ data: planData, type: 'Plan' })));
                    } else return of(new IcicleRequestSuccess({ data: data, type: 'Forecast' }));
                }),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting icicle chart data. Please contact Program Administrator.`
                    );
                    return of(new IcicleRequestError(error));
                })
            );
        })
    );

    @Effect()
    icicleOnWeekRangeChanged$ = this.actions$.pipe(
        ofType<IcicleWeekRangeSet>(IcicleActionTypes.IcicleWeekRangeSet),
        map((action) => new IcicleRequest(action.payload.type))
    );
}
