import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';

export interface PlanningDataState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deltaData: PlanningDataDeltaPagination;
    isDeltaDataLoading: boolean;
    deltaFilter: PlanningDataDeltaFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class PlanningDataDeltaDTO {
    subsystem: string;
    projectTeamName: string;
    contractor: string;
    mcPlanLive: moment.Moment;
    rfsuPlanLive: moment.Moment;
    mcPlanImport: moment.Moment;
    rfsuPlanImport: moment.Moment;
    deltaState: boolean;
}

export class PlanningDataDeltaFilter {
    page = 0;
    take = 10;
}
export class PlanningDataDeltaPagination {
    items: PlanningDataDeltaDTO[] = [];
    totalCount = 0;
}
