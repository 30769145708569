import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CertificateSearchService } from 'src/app/services/api/webapi-services/certificate-search.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from '../model';
import {
    CertificateSearchActionTypes,
    CertificateSearchError,
    CertificateSearchExportToExcelError,
    CertificateSearchExportToExcelSuccess,
    CertificateSearchSuccess,
} from './actions';
import * as moment from 'moment';

@Injectable()
export class CertificateSearchEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private certificateSearchService: CertificateSearchService,
        private toastService: ToastService
    ) {}

    @Effect()
    searchCertificate$ = this.actions$.pipe(
        ofType(CertificateSearchActionTypes.CertificateSearchRequest),
        withLatestFrom(this.store.select((state) => state.certificateSearchState.filter)),
        mergeMap(([, filter]) => {
            const f = {
                ...filter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.certificateSearchService.searchCertificate(f).pipe(
                map((data) => new CertificateSearchSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while getting Certificate data. Please contact Program Administrator.`
                    );
                    return of(new CertificateSearchError(error));
                })
            );
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(CertificateSearchActionTypes.CertificateSearchExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.certificateSearchState.filter)),
        mergeMap(([, certificateSearchFilter]) => {
            const filter = {
                ...certificateSearchFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.certificateSearchService.generateExcel(filter).pipe(
                map((excelData) => new CertificateSearchExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new CertificateSearchExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(CertificateSearchActionTypes.CertificateSearchExportToExcelSuccess),
        map((action: CertificateSearchExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `CertificateSearch_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );
}
