<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">{{ dashboardTypeName }} Milestone Dashboard</div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.subsystems.value.length" removable>
                                Subsystem: {{ displaySubsystemsSelected(filterForm.controls.subsystems.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('subsystems')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcMilestones.value.length" removable>
                                MC Milestone: {{ displayMultipleSelected(filterForm.controls.mcMilestones.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('mcMilestones')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                                RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                System Owner: {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.stagedStartUpPriorities.value.length" removable>
                                Staged Start Up Priority:
                                {{ displayMultipleSelected(filterForm.controls.stagedStartUpPriorities.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('stagedStartUpPriorities')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                Area Breakdown:
                                {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                                RFSU Contractor:
                                {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>Project Team</mat-label>
                        <mat-select
                            formControlName="projectTeamNames"
                            multiple
                            (openedChange)="onProjectTeamsClosed($event)"
                        >
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container620 margin-right15">
                        <app-multiple-items-selector
                            class="container620"
                            [setInput]="setSubsystemInput"
                            formControlName="subsystems"
                            [searchFunc]="getSubsystems"
                            [isAsync]="true"
                            [displayedColumns]="subsystemAutocompleteDisplayedColumns"
                            [isTableAutoComplete]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'subsystems'"
                            [placeholder]="'Subsystem'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15" *ngIf="dashboardType === dashboardTypes.MC">
                        <app-multiple-items-selector
                            class="container620"
                            [setInput]="setMcMilestoneInput"
                            formControlName="mcMilestones"
                            [searchFunc]="getMcMilestones"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="mcMilestoneAutocompleteDisplayedColumns"
                            [filterForm]="filterForm"
                            [formSetter]="'mcMilestones'"
                            [propertyToShow]="'name'"
                            [placeholder]="'MC Milestone'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15" *ngIf="dashboardType === dashboardTypes.RFO">
                        <app-multiple-items-selector
                            class="container620"
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [oneItemMode]="true"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwner" multiple>
                            <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            [setInput]="setStagedStartUpPrioritiesInput"
                            formControlName="stagedStartUpPriorities"
                            [searchFunc]="getStagedStartUpPriorities"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="stagedStartUpPrioritiesAutocompleteDisplayedColumns"
                            (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                            (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                            [filterForm]="filterForm"
                            formSetter="stagedStartUpPriorities"
                            propertyToShow="priority"
                            placeholder="Staged Start-up Priority"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'priorityName'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManager" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFSUContractorsInput"
                            formControlName="rfsuContractors"
                            [searchFunc]="getRFSUContractors"
                            [isAsync]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            [filterForm]="filterForm"
                            [formSetter]="'rfsuContractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'RFSU Contractor'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>Area Completion Lead</mat-label>
                        <mat-select formControlName="areaCompletionLead" multiple>
                            <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>

                </div>
                <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
    <div class="buttons-container flex">
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filters
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">
            search
        </button>
    </div>
    <div class="results-container">
        <div *ngIf="dashboardType === dashboardTypes.MC && filterForm.controls.goocs.value.length === 1">
            <app-mc-schedule></app-mc-schedule>
            <div *ngIf="isSkylineVisible$ | async" class="rundown-margin">
                <app-mc-skyline></app-mc-skyline>
                <app-skyline-legend skylinePhase="mc" skylineType="plan"></app-skyline-legend>
            </div>
            <div class="rundown-margin lookahead">
                <app-mc-lookahead></app-mc-lookahead>
            </div>
        </div>
        <div *ngIf="dashboardType === dashboardTypes.RFO && filterForm.controls.rfos.value.length === 1">
            <div *ngIf="isRFSUSkylineVisible$ | async" class="rundown-margin">
                <app-rfsu-skyline></app-rfsu-skyline>
                <app-skyline-legend skylinePhase="rfsu" skylineType="plan"></app-skyline-legend>
            </div>
            <div class="rundown-margin lookahead">
                <app-rfsu-lookahead></app-rfsu-lookahead>
            </div>
        </div>
        <div class="row rundown-margin">
            <app-rundown-curves style="width: 100%;"></app-rundown-curves>
        </div>
        <div
            class="row rundown-margin"
            *ngIf="
                (dashboardType === dashboardTypes.MC && filterForm.controls.goocs.value.length > 0) ||
                (dashboardType === dashboardTypes.RFO && filterForm.controls.rfos.value.length > 0)
            "
        >
            <app-icicle-chart-main
                [data]="icicleData$ | async"
                [type]="icicleType$ | async"
                [view]="[1260, 600]"
                [isLoading]="isIcicleLoading"
                [startDate]="startDate"
                [ngClass]="{ longerlabel: isLongerLabel, 'icicle-container': true }"
                (startDateSet)="startDateSet($event)"
                (longerLabel)="onLongerLabel($event)"
            ></app-icicle-chart-main>
        </div>
        <div class="row rundown-margin">
            <app-table-total-complete></app-table-total-complete>
        </div>
        <div *ngIf="dashboardType === dashboardTypes.MC && filterForm.controls.goocs.value.length === 1">
            <app-mc-sub-system class="row rundown-margin"></app-mc-sub-system>
        </div>
    </div>
    <div #scrollTargetDescription></div>
    <div
        class="comments-section-container"
        *ngIf="
            ((filterForm.controls.goocs.value.length === 1 && dashboardType === dashboardTypes.MC) ||
                (filterForm.controls.rfos.value.length === 1 && dashboardType === dashboardTypes.RFO)) &&
            isCommentsVisible
        "
    >
        <div class="comments-section-header">
            <div>
                <h3>Progress</h3>
            </div>
            <div>
                <h3>Forecast</h3>
            </div>
            <div>
                <h3>Issues &amp; Mitigations</h3>
            </div>
        </div>
        <div class="comment-table" *ngIf="!isCommentLoading">
            <div *ngFor="let comment of comments; index as i" class="comment-row">
                <div
                    mwlResizable
                    [enableGhostResize]="true"
                    class="res-container"
                    [style.height.px]="commentSizeOptions[i].height"
                    (resizeEnd)="onResize($event, i)"
                    [validateResize]="validate"
                >
                    <div class="comment-cell user-type-container">
                        <div>{{ comment.type }}</div>
                    </div>
                    <div
                        class="comment-cell"
                        (click)="openJoditPopup(commentTypes.Progress, comment.progress, comment.type)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="28"
                            viewBox="0 0 24 24"
                            width="28"
                            class="history-icon"
                            (click)="openCommentsHistoryPopup('progress', $event, comment.type)"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                            />
                        </svg>
                        <div class="jodit-container-comments" [innerHTML]="comment.progress | keepHtml"></div>
                    </div>

                    <div
                        class="comment-cell"
                        (click)="openJoditPopup(commentTypes.Forecast, comment.forecast, comment.type)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="28"
                            viewBox="0 0 24 24"
                            width="28"
                            class="history-icon"
                            (click)="openCommentsHistoryPopup('forecast', $event, comment.type)"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                            />
                        </svg>
                        <div class="jodit-container-comments" [innerHTML]="comment.forecast | keepHtml"></div>
                    </div>

                    <div
                        class="comment-cell"
                        (click)="openJoditPopup(commentTypes.Issues, comment.issues, comment.type)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="28"
                            viewBox="0 0 24 24"
                            width="28"
                            class="history-icon"
                            (click)="openCommentsHistoryPopup('issues', $event, comment.type)"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                            />
                        </svg>
                        <div class="jodit-container-comments" [innerHTML]="comment.issues | keepHtml"></div>
                    </div>
                    <div
                        mwlResizeHandle
                        class="resize-handle-bottom"
                        [resizeEdges]="{ bottom: true }"
                    ></div>
                </div>
            </div>
        </div>
        <div *ngIf="isCommentLoading" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <router-outlet></router-outlet>
</main>
<div class="editor-jodit-container hidden-jodit">
    <textarea id="editor"></textarea>
</div>
