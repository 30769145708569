import { Action } from '@ngrx/store';
import { UserDetail } from '../common.model';
import { ChangeManagementDataPagination } from './model';
import { Zone } from 'src/app/enums';

export enum ChangeManagementActionTypes {
    ChangeManagementFilterRequest = '[Change Management] Filter Request',
    ChangeManagementFilterSuccess = '[Change Management] Filter Success',
    ChangeManagementFilterError = '[Change Management] Filter Error',
    ChangeManagementFilterPropertyUpdate = '[Change Management] Filter Property Update',
    ChangeManagementFilterReset = '[Change Management] Filter Reset',
    ChangeManagementAddCommentRequest = '[Change Management] Add Comment Request',
    ChangeManagementAddCommentSuccess = '[Change Management] Add Comment Success',
    ChangeManagementAddCommentError = '[Change Management] Add Comment Error',
    ChangeManagementExportToExcelRequest = '[ChangeManagement] Export to Excel Request',
    ChangeManagementExportToExcelSuccess = '[ChangeManagement] Export to Excel Success',
    ChangeManagementExportToExcelError = '[ChangeManagement] Export to Excel Error',
    ChangeManagementSetLoaderState = '[ChangeManagement] Set Loader State',
    ChangeManagementSetMakeRequest = '[ChangeManagement] Set Make Request',
    ChangeManagementAddBulkCommentRequest = '[Change Management] Add Bulk Comment Request',
    ChangeManagementAddBulkCommentSuccess = '[Change Management] Add Bulk Comment Success',
    ChangeManagementAddBulkCommentError = '[Change Management] Add Bulk Comment Error',
    ChangeManagementDeleteCommentRequest = '[Change Management] Delete Comment Request',
    ChangeManagementDeleteCommentSuccess = '[Change Management] Delete Comment Success',
    ChangeManagementDeleteCommentError = '[Change Management] Delete Comment Error',
    ChangeManagementUpdateFieldImplementationContractorRequest = '[Change Management] Update Field Implementation Contractor Request',
    ChangeManagementFieldImplementationContractorSuccess = '[Change Management] Field Implementation Contractor Success',
    ChangeManagementFieldImplementationContractorError = '[Change Management] Field Implementation Contractor Error',    
}

export class ChangeManagementFilterRequest implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementFilterRequest;
}

export class ChangeManagementSetMakeRequest implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementSetMakeRequest;
}

export class ChangeManagementFilterSuccess implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementFilterSuccess;

    constructor(public payload: ChangeManagementDataPagination) {}
}

export class ChangeManagementFilterError implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementFilterError;

    constructor(public payload: string) {}
}

export class ChangeManagementFilterPropertyUpdate implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ChangeManagementFilterReset implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementFilterReset;
}

export class ChangeManagementAddCommentRequest implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementAddCommentRequest;

    constructor(public payload: { id: number; description: string; mentions: UserDetail[]; zone? : Zone  }) {}
}

export class ChangeManagementAddCommentSuccess implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementAddCommentSuccess;

    constructor(public payload: { id: number; description: string; mentions: UserDetail[]; zone?: Zone }) {}
}

export class ChangeManagementAddCommentError implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementAddCommentError;

    constructor(public payload: string) {}
}

export class ChangeManagementDeleteCommentRequest implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementDeleteCommentRequest;

    constructor(public payload: { id: number }) {}
}

export class ChangeManagementDeleteCommentSuccess implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementDeleteCommentSuccess;

    constructor(public payload: { id: number }) {}
}

export class ChangeManagementDeleteCommentError implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementDeleteCommentError;

    constructor(public payload: string) {}
}

export class ChangeManagementAddBulkCommentRequest implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementAddBulkCommentRequest;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class ChangeManagementAddBulkCommentSuccess implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementAddBulkCommentSuccess;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class ChangeManagementAddBulkCommentError implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementAddBulkCommentError;

    constructor(public payload: string) {}
}

export class ChangeManagementExportToExcelRequest implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementExportToExcelRequest;
}

export class ChangeManagementExportToExcelSuccess implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class ChangeManagementExportToExcelError implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementExportToExcelError;

    constructor(public payload: string) {}
}

export class ChangeManagementUpdateFieldImplementationContractorRequest implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementUpdateFieldImplementationContractorRequest;

    constructor(public payload: { id: number; fieldImplementationContractor: string }) {}
}

export class ChangeManagementFieldImplementationContractorSuccess implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementFieldImplementationContractorSuccess;

    constructor(public payload: { id: number; fieldImplementationContractor: string }) {}
}

export class ChangeManagementFieldImplementationContractorError implements Action {
    readonly type = ChangeManagementActionTypes.ChangeManagementFieldImplementationContractorError;

    constructor(public payload: string) {}
}

export type ChangeManagementActions =
    | ChangeManagementFilterRequest
    | ChangeManagementFilterSuccess
    | ChangeManagementFilterError
    | ChangeManagementFilterPropertyUpdate
    | ChangeManagementFilterReset
    | ChangeManagementAddCommentRequest
    | ChangeManagementAddCommentSuccess
    | ChangeManagementAddCommentError
    | ChangeManagementAddBulkCommentRequest
    | ChangeManagementAddBulkCommentSuccess
    | ChangeManagementAddBulkCommentError
    | ChangeManagementExportToExcelRequest
    | ChangeManagementExportToExcelSuccess
    | ChangeManagementExportToExcelError
    | ChangeManagementSetMakeRequest
    | ChangeManagementDeleteCommentRequest
    | ChangeManagementDeleteCommentSuccess
    | ChangeManagementDeleteCommentError
    | ChangeManagementUpdateFieldImplementationContractorRequest
    | ChangeManagementFieldImplementationContractorSuccess
    | ChangeManagementFieldImplementationContractorError;
    
