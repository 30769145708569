import { Action } from '@ngrx/store';
import { ChangeImpactedTagsDataPagination } from './model';

export enum ChangeImpactedTagsActionTypes {
    ChangeImpactedTagsFilterRequest = '[Change Impacted Tags] Filter Request',
    ChangeImpactedTagsFilterSuccess = '[Change Impacted Tags] Filter Success',
    ChangeImpactedTagsFilterError = '[Change Impacted Tags] Filter Error',
    ChangeImpactedTagsFilterPropertyUpdate = '[Change Impacted Tags] Filter Property Update',
    ChangeImpactedTagsFilterReset = '[Change Impacted Tags] Filter Reset',
    ChangeImpactedTagsUpdateSetRequest = '[Change Impacted Tags] New DataSet Request',
    ChangeImpactedTagsNewDataSetSuccess = '[Change Impacted Tags] New DataSet Success',
    ChangeImpactedTagsNewDataSetError = '[Change Impacted Tags] New DataSet Error',
    ChangeImpactedTagsUploadRequest = '[Change Impacted Tags] Upload Request',
    ChangeImpactedTagsUploadSuccess = '[Change Impacted Tags] Upload Success',
    ChangeImpactedTagsUploadError = '[Change Impacted Tags] Upload Error',
}

export class ChangeImpactedTagsFilterRequest implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterRequest;
}

export class ChangeImpactedTagsFilterSuccess implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterSuccess;

    constructor(public payload: ChangeImpactedTagsDataPagination) {}
}

export class ChangeImpactedTagsFilterError implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterError;

    constructor(public payload: string) {}
}

export class ChangeImpactedTagsFilterPropertyUpdate implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ChangeImpactedTagsFilterReset implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterReset;
}

export class ChangeImpactedTagsNewDataSetRequest implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsUpdateSetRequest;

    constructor(public payload: { id: number; impactedTags: string[] }) {}
}

export class ChangeImpactedTagsNewDataSetSuccess implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsNewDataSetSuccess;
}

export class ChangeImpactedTagsNewDataSetError implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsNewDataSetError;

    constructor(public payload: string) {}
}

export class ChangeImpactedTagsUploadRequest implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsUploadRequest;

    constructor(public payload: { formData: FormData; id: number }) {}
}

export class ChangeImpactedTagsUploadSuccess implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsUploadSuccess;

    constructor(public payload: { tags: string[]; id: number }) {}
}

export class ChangeImpactedTagsUploadError implements Action {
    readonly type = ChangeImpactedTagsActionTypes.ChangeImpactedTagsUploadError;

    constructor(public payload: string) {}
}

export type ChangeImpactedTagsActions =
    | ChangeImpactedTagsFilterRequest
    | ChangeImpactedTagsFilterSuccess
    | ChangeImpactedTagsFilterError
    | ChangeImpactedTagsFilterPropertyUpdate
    | ChangeImpactedTagsFilterReset
    | ChangeImpactedTagsNewDataSetRequest
    | ChangeImpactedTagsNewDataSetSuccess
    | ChangeImpactedTagsNewDataSetError
    | ChangeImpactedTagsUploadRequest
    | ChangeImpactedTagsUploadSuccess
    | ChangeImpactedTagsUploadError;
