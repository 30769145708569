import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { PlanningLookaheadSetType, PlanningLookaheadRequest } from 'src/app/store/reports/planning/actions';

@Component({
    selector: 'app-reports-planning-lookahead-content',
    templateUrl: './reports-planning-lookahead-content.component.html',
    styleUrls: ['./reports-planning-lookahead-content.component.scss'],
})
export class ReportsPlanningLookaheadContentComponent implements OnInit {
    lookaheadType = '';

    isLoading$ = this.store.select((store) => store.planningState.lookahead.isLoading);
    lookahead$ = this.store.select((state) => state.planningState.lookahead);

    constructor(private activatedRoute: ActivatedRoute, private store: Store<ApplicationState>) {}

    ngOnInit() {
        this.lookaheadType = this.activatedRoute.routeConfig.path;
        this.store.dispatch(new PlanningLookaheadSetType(this.lookaheadType));
        this.store.dispatch(new PlanningLookaheadRequest());
    }
}
