import { Injectable } from '@angular/core';
import { IcicleDTO, IcicleFilter } from 'src/app/store/reports/icicle/model';
import { HttpClient } from '@angular/common/http';
import { PhaseType } from 'src/app/enums';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class IcicleService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly skylineEndpoint: string = '/icicle';

    constructor(private http: HttpClient) {}

    getIcicleForecastData(filter: IcicleFilter, type: PhaseType, startDate: string) {
        return this.http.post<IcicleDTO[]>(
            `${this.rootUrl}${this.skylineEndpoint}/forecast/${type}?startDate=${startDate}`,
            filter
        );
    }

    getIciclePlanData(filter: IcicleFilter, type: PhaseType, startDate: string) {
        return this.http.post<IcicleDTO[]>(
            `${this.rootUrl}${this.skylineEndpoint}/plan/${type}?startDate=${startDate}`,
            filter
        );
    }
}
