import { OrderDirection, Contractor, CalendarColumn, CheckListColumn } from '../../common.model';
import { SubsystemMaintenanceDTO } from 'src/app/components/admin-section/data-maintenance/subsystem-data/subsystem-maintenance-dto';

export class SubsystemMaintenanceFilter {
    page = 0;
    take = 10;
    sortBy = 'contractorNo';
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    scAreaManagers: number[] = [];
    acManagers: number[] = [];
    sysOwners: number[] = [];
    mcEngineers: number[] = [];
    subsystem = [];
    subsystemName = [];

    columnSubsystems: CheckListColumn = null;
    columnSubsystemNames: CheckListColumn = null;
    columnContracts: CheckListColumn = null;
    columnAreaManagers: CheckListColumn = null;
    columnSystemOwner: CheckListColumn = null;
    columnConstructionManager: CheckListColumn = null;
    columnMCEngineer: CheckListColumn = null;
    columnMCPlan: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;
}

export interface SubsystemMaintenanceState {
    subsystemDataPagination: SubsystemDataPagination;
    filter: SubsystemMaintenanceFilter;
    isLoading: boolean;
}

export class SubsystemDataPagination {
    items: SubsystemMaintenanceDTO[] = [];
    totalCount = 0;
}
