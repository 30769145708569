import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { ConstraintTeamsDTO } from 'src/app/store/detailed-status/model';
import { BaseResultDTO } from 'src/app/store/common.model';

@Injectable({
    providedIn: 'root',
})
export class ProjectTeamService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly projectTeamEndpoint: string = '/projectTeam';

    constructor(private http: HttpClient) {}

    addProjectTeam(projectTeamName: string) {
        return this.http.post(`${this.rootUrl}${this.projectTeamEndpoint}/${projectTeamName}`, null);
    }

    removeProjectTeam(projectTeamName: string) {
        return this.http.delete(`${this.rootUrl}${this.projectTeamEndpoint}/${projectTeamName}`);
    }

    getProjectSubTeams() {    
        return this.http.get<ConstraintTeamsDTO[]>(
            `${this.rootUrl}${this.projectTeamEndpoint}`);
    }
}
