import { Action } from '@ngrx/store';
import { BaseResultDTO } from '../common.model';
import {
    MCWeeklyPlanningDTO,
    RFSUWeeklyPlanningDTO,
    WeeklyPlanningCommitDTO,
    WeeklyPlanningCommitmentsDTO,
    WeeklyPlanningFilter,
    WeeklyPlanningTopHeaderResultDTO
} from './model';
import * as moment from 'moment';

export enum WeeklyPlanningActionTypes {
    WeeklyPlanningDataRequest = '[Weekly Planning] Data Request',
    WeeklyPlanningDataSuccess = '[Weekly Planning] Data Success',
    WeeklyPlanningDataError = '[Weekly Planning] Data Error',
    WeeklyPlanningSetStartDate = '[Weekly Planning] Set Start Date',
    WeeklyPlanningSetProjectTeam = '[Weekly Planning] Set Project Team',
    WeeklyPlanningFilterPropertyUpdate = '[Weekly Planning] Filter Property Update',
    WeeklyPlanningSetPlanningType = '[Weekly Planning] Set Planning Type',
    WeeklyPlanningFilterRequest = '[Weekly Planning] Filter Request',
    WeeklyPlanningCommitmentsFilterRequest = '[Weekly Planning] Commitments Filter Request',
    WeeklyPlanningFilterReset = '[Weekly Planning] Filter Reset',
    WeeklyPlanningCommitRequest = '[Weekly Planning] Commit Request',
    WeeklyPlanningCommitSuccess = '[Weekly Planning] Commit Success',
    WeeklyPlanningCommitError = '[Weekly Planning] Commit Error',
    WeeklyPlanningDeleteCommitmentRequest = '[Weekly Planning] Delete Commitment Request',
    WeeklyPlanningDeleteCommitmentSuccess = '[Weekly Planning] Delete Commitment Success',
    WeeklyPlanningDeleteCommitmentError = '[Weekly Planning] Delete Commitment Error',
    WeeklyPlanningCommitmentsRequest = '[Weekly Planning] Commitments Request',
    WeeklyPlanningCommitmentsSuccess = '[Weekly Planning] Commitments Success',
    WeeklyPlanningCommitmentsError = '[Weekly Planning] Commitments Error',
    WeeklyPlanningSubmitCommitmentsRequest = '[Weekly Planning] Submit Commitments Request',
    WeeklyPlanningSubmitCommitmentsSuccess = '[Weekly Planning] Submit Commitments Success',
    WeeklyPlanningSubmitCommitmentsError = '[Weekly Planning] Submit Commitments Error',
    WeeklyPlanningTopHeaderSectionDataRequest = '[Weekly Planning] Top Header Section Data Request',
    WeeklyPlanningTopHeaderSectionDataSuccess = '[Weekly Planning] Top Header Section Data Success',
    WeeklyPlanningTopHeaderSectionDataError = '[Weekly Planning] Top Header Section Data Error',
}

export class WeeklyPlanningDataRequest implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningDataRequest;

    constructor(public payload: { planningType: string; planningTableIndex: number }) { }
}

export class WeeklyPlanningDataSuccess implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningDataSuccess;

    constructor(
        public payload: {
            result: BaseResultDTO<MCWeeklyPlanningDTO | RFSUWeeklyPlanningDTO>;
            planningType: string;
            planningTableIndex: number;
        }
    ) { }
}

export class WeeklyPlanningDataError implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningDataError;

    constructor(public payload: { error: string; planningType: string; planningTableIndex: number }) { }
}

export class WeeklyPlanningSetStartDate implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningSetStartDate;

    constructor(public payload: moment.Moment) { }
}

export class WeeklyPlanningSetProjectTeam implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningSetProjectTeam;

    constructor(public payload: string) { }
}

export class WeeklyPlanningFilterPropertyUpdate implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any; planningType?: string; planningTableIndex?: number }) { }
}

export class WeeklyPlanningSetPlanningType implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningSetPlanningType;

    constructor(public payload: string) { }
}

export class WeeklyPlanningFilterRequest implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningFilterRequest;

    constructor(public payload: number[]) { }
}

export class WeeklyPlanningCommitmentsFilterRequest implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningCommitmentsFilterRequest;

    constructor(public projectTeamName: string) { }
}

export class WeeklyPlanningFilterReset implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningFilterReset;
}

export class WeeklyPlanningCommitRequest implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningCommitRequest;

    constructor(public payload: { planningType: string; planningTableIndex: number; subsystem: string }) { }
}

export class WeeklyPlanningCommitSuccess implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningCommitSuccess;

    constructor(
        public payload: { planningType: string; planningTableIndex: number; commit: WeeklyPlanningCommitDTO }
    ) { }
}

export class WeeklyPlanningCommitError implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningCommitError;

    constructor(public payload: { error: string; planningType: string; planningTableIndex: number }) { }
}

export class WeeklyPlanningDeleteCommitmentRequest implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningDeleteCommitmentRequest;

    constructor(public payload: { planningType: string; planningTableIndex: number; subsystem: string }) { }
}

export class WeeklyPlanningSubmitCommitmentsRequest implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningSubmitCommitmentsRequest;

    constructor(public payload: { planningType: string; }) { }
}

export class WeeklyPlanningTopHeaderSectionDataRequest implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningTopHeaderSectionDataRequest;

    constructor(public payload: { projectTeamName: string; filter: WeeklyPlanningFilter }) { }
}

export class WeeklyPlanningTopHeaderSectionDataSuccess implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningTopHeaderSectionDataSuccess;

    constructor(
        public payload: {
            result: WeeklyPlanningTopHeaderResultDTO;
        }
    ) { }
}

export class WeeklyPlanningTopHeaderSectionDataError implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningTopHeaderSectionDataError;

    constructor(public payload: { error: string; planningType: string; }) { }
}

export class WeeklyPlanningCommitmentsRequest implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningCommitmentsRequest;

    constructor(public payload: { projectTeamName: string, planningType: string }) { }
}

export class WeeklyPlanningCommitmentsSuccess implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningCommitmentsSuccess;

    constructor(
        public payload: {
            result: WeeklyPlanningCommitmentsDTO;
        }
    ) { }
}

export class WeeklyPlanningCommitmentsError implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningCommitmentsError;

    constructor(public payload: { error: string; planningType: string; }) { }
}


export class WeeklyPlanningDeleteCommitmentSuccess implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningDeleteCommitmentSuccess;

    constructor(
        public payload: { planningType: string; planningTableIndex: number; commit: WeeklyPlanningCommitDTO }
    ) { }
}

export class WeeklyPlanningDeleteCommitmentError implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningDeleteCommitmentError;

    constructor(public payload: { error: string; planningType: string; planningTableIndex: number }) { }
}

export class WeeklyPlanningSubmitCommitmentsSuccess implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningSubmitCommitmentsSuccess;
}

export class WeeklyPlanningSubmitCommitmentsError implements Action {
    readonly type = WeeklyPlanningActionTypes.WeeklyPlanningSubmitCommitmentsError;

    constructor(public payload: { error: string; planningType: string; }) { }
}

export type WeeklyPlanningActions =
    | WeeklyPlanningDataRequest
    | WeeklyPlanningDataSuccess
    | WeeklyPlanningDataError
    | WeeklyPlanningSetStartDate
    | WeeklyPlanningSetProjectTeam
    | WeeklyPlanningFilterPropertyUpdate
    | WeeklyPlanningSetPlanningType
    | WeeklyPlanningFilterRequest
    | WeeklyPlanningFilterReset
    | WeeklyPlanningCommitRequest
    | WeeklyPlanningCommitSuccess
    | WeeklyPlanningCommitError
    | WeeklyPlanningDeleteCommitmentRequest
    | WeeklyPlanningDeleteCommitmentSuccess
    | WeeklyPlanningDeleteCommitmentError
    | WeeklyPlanningSubmitCommitmentsRequest
    | WeeklyPlanningSubmitCommitmentsSuccess
    | WeeklyPlanningSubmitCommitmentsError
    | WeeklyPlanningCommitmentsRequest
    | WeeklyPlanningCommitmentsSuccess
    | WeeklyPlanningCommitmentsError
    | WeeklyPlanningCommitmentsFilterRequest
    | WeeklyPlanningTopHeaderSectionDataRequest
    | WeeklyPlanningTopHeaderSectionDataSuccess
    | WeeklyPlanningTopHeaderSectionDataError;
