export class ImportStatuses {
    public static readonly Started = 'Started';
    public static readonly PreprocessingStarted = 'Preprocessing started';
    public static readonly Finished = 'Finished';
    public static readonly PreprocessingFinished = "Preprocessing Finished";
    public static readonly Error = 'Error';
    public static readonly Terminated = 'Terminated';
    public static readonly PreprocessingError = "Preprocessing Error";
    public static readonly ProcessingError = "Upload And Validate Error";
    public static readonly Info = 'Info';
    public static readonly Warning = 'Warning';
    public static readonly Validating = 'Validating';
    public static readonly Validated = 'Validated';
    public static readonly Uploading = "Uploading";
}
