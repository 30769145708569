import { COWListState } from './model';
import * as moment from 'moment';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { COWActions, COWActionTypes } from './actions';

const initialState: COWListState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action:COWActions){
    switch(action.type){
        case COWActionTypes.COWAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'COW';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        case COWActionTypes.COWUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case COWActionTypes.COWFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case COWActionTypes.COWUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case COWActionTypes.COWUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case COWActionTypes.COWDownloadOutputDataRequest:
        case COWActionTypes.COWTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case COWActionTypes.COWTemplateFileError:
        case COWActionTypes.COWDownloadOutputDataSuccess:
        case COWActionTypes.COWDownloadOutputDataError:
        case COWActionTypes.COWTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case COWActionTypes.COWSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case COWActionTypes.COWClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case COWActionTypes.COWValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case COWActionTypes.COWValidateDeltaRequest: {
            return {
                ...state,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case COWActionTypes.COWValidateDeltaSuccess: {
            return {
                ...state,
            };
        }
        case COWActionTypes.COWValidateDeltaError: {
            return {
                ...state,
            };
        }
        default:
            return state;
    }
}