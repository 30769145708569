import { ChangeValidationState, ChangeValidationDataPagination, ChangeValidationFilter } from './model';
import { ChangeValidationActions, ChangeValidationActionTypes } from './actions';

const initialState: ChangeValidationState = {
    dataPagination: new ChangeValidationDataPagination(),
    isLoading: false,
    filter: new ChangeValidationFilter(),
};

export function reducer(
    state: ChangeValidationState = initialState,
    action: ChangeValidationActions
): ChangeValidationState {
    switch (action.type) {
        case ChangeValidationActionTypes.ChangeValidationExportToExcelRequest:
        case ChangeValidationActionTypes.ChangeValidationFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ChangeValidationActionTypes.ChangeValidationFilterSuccess: {
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        }
        case ChangeValidationActionTypes.ChangeValidationFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(action.payload.value)
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case ChangeValidationActionTypes.ChangeValidationFilterReset: {
            const newFilter = new ChangeValidationFilter();
            newFilter.sortBy = state.filter.sortBy;
            newFilter.direction = state.filter.direction;
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case ChangeValidationActionTypes.ChangeValidationFilterError:
        case ChangeValidationActionTypes.ChangeValidationExportToExcelError:
        case ChangeValidationActionTypes.ChangeValidationExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
