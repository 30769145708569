import { createAction, props } from '@ngrx/store';
import { ExportEvent } from './model';


export const getRecentExportEventsRequest = createAction('[ExcelExport] Get Recent Events');
export const getRecentExportEventsSuccess = createAction(
    '[ExcelExport] Get Recent Events Success',
    props<{ payload: ExportEvent[]}>()
);
export const getRecentExportEventsError = createAction(
    '[ExcelExport] Get Recent Events Error',
    props<{ payload: string }>()
);
export const exportEventUpdated = createAction(
    '[ExcelExport] Export Event Updated',
    props<{ exportEvent: ExportEvent }>()
);
export const detailedStatusExportToExcelRequest = createAction(
    '[ExcelExport] Detailed Status Export to Excel Request',
    props<{ payload: string }>()
);
export const detailedStatusExportToExcelSuccess = createAction(
    '[ExcelExport] Detailed Status Export to Excel Success',
    props<{ payload: ExportEvent }>()
);
export const detailedStatusExportToExcelError = createAction(
    '[ExcelExport] Detailed Status Export to Excel Error',
    props<{ payload: string }>()
);
export const downloadExportedFileRequest = createAction(
    '[ExcelExport] Download Exported File Request',
    props<{ exportEvent: ExportEvent }>()
);
export const downloadExportedFileSuccess = createAction(
    '[ExcelExport] Download Exported File Success',
    props<{ content: BlobPart; fileName: string }>()
);
export const downloadExportedFileError = createAction(
    '[ExcelExport] Download Exported File Error',
    props<{ payload: string }>()
);
export const connectionSet = createAction(
    '[ExcelExport] SignalR Connection Set',
    props<{ payload: boolean }>()
);
