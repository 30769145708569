<div class="past-commitments-container" [formGroup]="filterForm">
    <div class="filter-container mixedapp-container">
        <app-multiple-items-selector
            *ngIf="planningType !== commitmentTypes.RFO"
            class="container620 margin-right20"
            [setInput]="setContractorInput"
            formControlName="contractors"
            [searchFunc]="getContractors"
            [isAsync]="true"
            [isTableAutoComplete]="true"
            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
            (autocompleteClosed)="onContractorsClosed()"
            (itemRemoved)="onContractorsClosed()"
            [filterForm]="filterForm"
            [formSetter]="'contractors'"
            [propertyToShow]="'contractNo'"
            [placeholder]="'Contractor'"
            [secondPropertyToShow]="'contract'"
        >
        </app-multiple-items-selector>
        <app-multiple-items-selector        
            *ngIf="planningType === commitmentTypes.RFSU"
            class="container300 margin-right20"
            formControlName="rfos"
            [searchFunc]="searchRfos"
            [isAsync]="true"
            [filterForm]="filterForm"
            [formSetter]="'rfos'"
            [propertyToShow]="'name'"
            [placeholder]="'RFO'"
            [secondPropertyToShow]="'rfoName'"
            [isTableAutoComplete]="true"
            [displayedColumns]="['name', 'rfoName']"
            [panelWidth]="480"
        >
        </app-multiple-items-selector>
    </div>
    <div *ngIf="planningType !== commitmentTypes.RFO" class="buttons-container flex">
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filters
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
    </div>
    <h4>past commitments</h4>
    <div class="table-container">
        <table mat-table [dataSource]="data$ | async" matSortDisableClear>
            <ng-container matColumnDef="id">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    {{ planningType === commitmentTypes.RFO ? 'RFO' : 'Subsystem' }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    {{ planningType === commitmentTypes.RFO ? 'RFO' : 'Subsystem' }} Name
                </th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="actual">
                <th mat-header-cell disableClear *matHeaderCellDef>{{ commitmentTypes[planningType] }} Actual</th>
                <td mat-cell *matCellDef="let element">{{ element.actual | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="plan">
                <th mat-header-cell disableClear *matHeaderCellDef>{{ commitmentTypes[planningType] }} Plan</th>
                <td mat-cell *matCellDef="let element">{{ element.plan | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="forecast">
                <th mat-header-cell disableClear *matHeaderCellDef>{{ commitmentTypes[planningType] }} Forecast</th>
                <td mat-cell *matCellDef="let element">{{ element.forecast | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="walkdownActual">
                <th mat-header-cell disableClear *matHeaderCellDef>Walkdown Actual</th>
                <td mat-cell *matCellDef="let element">{{ element.walkdownActual | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">Committed</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <div *ngIf="isLoading" class="delta-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
