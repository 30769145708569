import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
    ScheduleActivityAddCommentDto,
    ScheduleActivityPlanningService,
    ScheduleActivityPropertyUpdateDto,
    ScheduleActivityUpdateDto,
    WeeklyStatusBooleanProperties,
} from '../../../../../../services/api/webapi-services/schedule-activity-planning.service';
import { ToastService } from '../../../../../../services/shared/toast.service';
import { BaseComponent } from '../../../../../base.component';
import {
    ScheduleActivityPlanningDTO,
    ShowHideColumns,
    SubsystemWithScheduleActivitiesDTO,
} from '../../../../../../store/weekly-planning/schedule-activity-planning/model';
import * as moment from 'moment';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ClearableMatDatepickerComponent } from 'src/app/components/clearable-mat-datepicker/clearable-mat-datepicker.component';
import { Observable } from 'rxjs';
import {
    DetailedStatusFilterReset,
    DetailedStatusResetLockedFilter,
    DetailedStatusSetMakeRequest,
} from 'src/app/store/detailed-status/actions';
import { ApplicationState } from 'src/app/store/model';
import { WeeklyPlanActivityType, Zone } from 'src/app/enums';
import { getDetailedStatusFilterInstance } from 'src/app/extensions';
import { Moment } from 'moment';
import { CommentPopupComponent } from 'src/app/modules/shared/components/comment-popup/comment-popup.component';
import { UserDetail } from 'src/app/store/common.model';
import { PopoverPlacement } from 'ngx-smart-popover';
import { take, takeWhile } from 'rxjs/operators';
import {
    ScheduleActivityAddCommentRequest,
    ScheduleActivityNotInWeekPropertyUpdate,
    ScheduleActivityNotInWeekUpdate,
    ScheduleActivityPlanningResetITRFilter,
    ScheduleActivityPropertyUpdate,
    ScheduleActivityRemoveCommentRequest,
    ScheduleActivityUpdate,
    ScheduleActivityWeekSummaryDataRequest,
} from '../../../../../../store/weekly-planning/schedule-activity-planning/actions';
import { ConstraintTypeSelectorPopupComponent } from './constraint-type-selector/constraint-type-selector.component';
import { ScheduleActivityPlanningConstraint } from '../../../../sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import * as _ from 'lodash';
import { ScheduleActivityITRFilterPropertyReset, ScheduleActivityITRFilterPropertyUpdate } from 'src/app/store/schedule-activity-itr/actions';
import { ScheduleActivityITRFilter, ScheduleActivityITRScope } from 'src/app/store/schedule-activity-itr/model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { CommentsHistoryComponent } from 'src/app/components/comments-history/comments-history.component';
import { CommentDetailDTO } from 'src/app/store/detailed-status/model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

type ForecastDateType = 'forecastStart' | 'forecastFinish';

@Component({
    selector: 'app-schedule-activity-planning-subsystem-table',
    templateUrl: './schedule-activity-planning-subsystem-table.component.html',
    styleUrls: ['./schedule-activity-planning-subsystem-table.component.scss'],
})
export class ScheduleActivityPlanningSubsystemTableComponent extends BaseComponent implements OnInit {
    PopoverPlacement = PopoverPlacement;

    @ViewChild('activityActionPopover', { static: false }) popover: any;

    constructor(
        private scheduleActivityService: ScheduleActivityPlanningService,
        private toastService: ToastService,
        private roleService: RoleService,
        private popupSvc: PopupService,
        private store: Store<ApplicationState>,
        private ngZone: NgZone,
        private router: Router,
        private commentService: CommentService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
    }

    get isSelectedRowInWeeklyPlan() {
        return this?.selectedRow?.weeklyStatus?.inWeeklyPlan ?? false;
    }

    get doesSelectedRowHaveWorkPacksReady() {
        return this?.selectedRow?.weeklyStatus?.areWorkPacksReady ?? false;
    }

    get doesSelectedRowHaveWorkComplete() {
        return this?.selectedRow?.weeklyStatus?.isWorkComplete ?? false;
    }

    get isSelectedRowConstrained() {
        return this?.selectedRow?.isConstrained ?? false;
    }

    get areWorkCompleteStatusFeaturesEnabled() {
        const currentWeekStart = moment.utc().startOf('isoWeek').add(-2, 'days');
        return this.currentlyViewedWeekStart < currentWeekStart;
    }

    @Input() subsystemWithActivities: SubsystemWithScheduleActivitiesDTO;
    @Input() additionToPlan = false;
    @Input() showHideColumns: ShowHideColumns = new ShowHideColumns();
    @Input() activityType: WeeklyPlanActivityType;
    @Input() printMode: boolean = false;

    currentlyViewedWeekStart$: Observable<Moment> = this.store.select(
        (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekStart
    );
    currentlyViewedWeekStart: Moment;

    displayedColumns: string[] = [
        'activityId',
        'activityName',
        'activityDiscipline',
        'constraintType',
        'planStart',
        'planFinish',
        'forecastStart',
        'forecastFinish',
        'mhrsInWeek',
        'itrToGo',
        'itrInWeek',
        'workPacksReady',
        'comment',
    ];

    showActivities = true;
    showActivities$ = this.store.select(
        (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.showActivities
    );

    selectedRow: ScheduleActivityPlanningDTO;
    isAdmin = false;
    itrScopes = ScheduleActivityITRScope;

    ngOnInit(): void {
        this.currentlyViewedWeekStart$.subscribe((next) => (this.currentlyViewedWeekStart = next));

        this.showActivities$.pipe(takeWhile(() => this.isAlive)).subscribe((value) => (this.showActivities = value));

        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
    }

    saveComment(row: ScheduleActivityPlanningDTO, value: string, mentions: UserDetail[]) {
        row.comment = value;
        const payload = {
            activityId: row.activityId,
            activityDiscipline: row.activityDiscipline,
            description: row.comment,
            mentions: mentions,
        } as ScheduleActivityAddCommentDto;
        this.store.dispatch(new ScheduleActivityAddCommentRequest(payload));
    }

    openHistoryPopup(element: ScheduleActivityPlanningDTO) {
        if (element.comment === null) {
            return;
        } else {
            this.commentService
                .getActivityComments(
                    element.activityId,
                    element.activityDiscipline
                )
                .subscribe((data) => {
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            CommentsHistoryComponent,
                            null,
                            null,
                            {
                                commentHist: data,
                                action: (id: number) => {
                                    data = data.filter((s) => s.id !== id);
                                    this.store.dispatch(
                                        new ScheduleActivityRemoveCommentRequest({
                                            id,
                                            subsystem: element.subsystem,
                                            activityId:  element.activityId,
                                            activityDiscipline: element.activityDiscipline,
                                            lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                        })
                                    );
                                },
                            },
                            200,
                            200
                        )
                    );
                });
        }
    }

    toggleWeeklyStatusProp(prop: WeeklyStatusBooleanProperties, row = this.selectedRow) {
        if (row.isConstrained && !row.weeklyStatus.inWeeklyPlan) {
            this.popover.hide();
            this.popupSvc
                .openPopup(
                    new PopupSettings(ConfirmDialogComponent, 600, 220, {
                        title: 'Confirm action',
                        text: 'Activity has Constraints. Confirm Activity to be in Weekly Work Plan?',
                    })
                )
                .afterClosed()
                .pipe(take(1))
                .subscribe((answer) => {
                    if (answer) {
                        this.toggleActivityInWeekPlanAction(prop, row);
                    }
                });
        } else {
            this.toggleActivityInWeekPlanActionWithConfirmation(prop, row);
        }
    }

    private toggleActivityInWeekPlanActionWithConfirmation(prop: WeeklyStatusBooleanProperties, row: ScheduleActivityPlanningDTO) {
        if(prop === 'inWeeklyPlan' && row.weeklyStatus.inWeeklyPlan && row.selectedITRsCount > 0) {
            this.popover.hide();
            this.popupSvc
                .openPopup(
                    new PopupSettings(ConfirmDialogComponent, 600, 220, {
                        title: 'Confirm action',
                        text: 'ITRs selected for Week in this Activity. Do you want to remove selected ITRs?',
                    })
                )
                .afterClosed()
                .pipe(take(1))
                .subscribe((answer) => {
                    if (answer) {
                        this.toggleActivityInWeekPlanAction(prop, row, true);
                    } else {
                        this.toggleActivityInWeekPlanAction(prop, row);
                    }
                });
        } else {
            this.toggleActivityInWeekPlanAction(prop, row);
        }        
    }

    private toggleActivityInWeekPlanAction(prop: WeeklyStatusBooleanProperties, row: ScheduleActivityPlanningDTO, removeSelectedItrs?: boolean) {
        const payload = {
            activityId: row.activityId,
            activityDiscipline: row.activityDiscipline,
            weekStart: moment(this.currentlyViewedWeekStart).toDate(),
            removeSelectedItrs: removeSelectedItrs,
            property: prop,
            value: !row.weeklyStatus[prop],
        } as ScheduleActivityPropertyUpdateDto;
        const subsystem = this.subsystemWithActivities.subsystem;

        this.activityType == WeeklyPlanActivityType.inWeek
            ? this.store.dispatch(new ScheduleActivityPropertyUpdate(payload, subsystem))
            : this.store.dispatch(new ScheduleActivityNotInWeekPropertyUpdate(payload, subsystem));      
    }

    updateMhrsInWeek(row) {
        if (row.weeklyStatus.mhrsInWeek < 0) {
            this.toastService.Error('The value of Mhrs In Week cannot be smaller than 0');
            return;
        }
        const payload = {
            activityId: row.activityId,
            activityDiscipline: row.activityDiscipline,
            weekStart: this.currentlyViewedWeekStart.toDate(),
            property: 'MhrsInWeek',
            value: row.weeklyStatus.mhrsInWeek,
        } as ScheduleActivityPropertyUpdateDto;
        const subsystem = this.subsystemWithActivities.subsystem;

        this.activityType == WeeklyPlanActivityType.inWeek
            ? this.store.dispatch(new ScheduleActivityPropertyUpdate(payload, subsystem))
            : this.store.dispatch(new ScheduleActivityNotInWeekPropertyUpdate(payload, subsystem));
    }

    updateIsConstrained() {
        const changeTo = !this.isSelectedRowConstrained;

        if (changeTo) {
            this.popover.hide();
            this.popupSvc
                .openPopup(new PopupSettings(ConstraintTypeSelectorPopupComponent, 450, 300, {}))
                .afterClosed()
                .subscribe((dialogResult) => {
                    if (_.has(dialogResult, 'constraintType')) {
                        this.updateIsConstrainedRequest(changeTo, dialogResult.constraintType);
                    }
                });
            return;
        }

        this.updateIsConstrainedRequest(changeTo);
    }

    private updateIsConstrainedRequest(
        isConstrained: boolean,
        constraintType: ScheduleActivityPlanningConstraint = null
    ) {
        const payload = {
            activityId: this.selectedRow.activityId,
            activityDiscipline: this.selectedRow.activityDiscipline,
            constraintTypeId: constraintType?.id,
            isConstrained,
            constraintType: constraintType?.name,
        } as ScheduleActivityUpdateDto;
        const subsystem = this.subsystemWithActivities.subsystem;
        this.activityType == WeeklyPlanActivityType.inWeek
            ? this.store.dispatch(new ScheduleActivityUpdate(payload, subsystem))
            : this.store.dispatch(new ScheduleActivityNotInWeekUpdate(payload, subsystem));
    }

    saveDate(row: ScheduleActivityPlanningDTO, type: 'forecastStart' | 'forecastFinish') {
        const payload = {
            activityId: this.selectedRow.activityId,
            activityDiscipline: this.selectedRow.activityDiscipline,
            [type]: row[type],
        } as ScheduleActivityUpdateDto;
        const subsystem = this.subsystemWithActivities.subsystem;
        this.activityType == WeeklyPlanActivityType.inWeek
            ? this.store.dispatch(new ScheduleActivityUpdate(payload, subsystem))
            : this.store.dispatch(new ScheduleActivityNotInWeekUpdate(payload, subsystem));
    }

    highlightRow(row: any) {
        this.selectedRow = row;
    }

    openDatepicker(row: ScheduleActivityPlanningDTO, type: ForecastDateType) {
        let date;
        if (type === 'forecastStart') {
            date = row.forecastStart ? row.forecastStart : '';
        } else if (type === 'forecastFinish') {
            date = row.forecastFinish ? row.forecastFinish : '';
        }

        this.popupSvc.openPopup(
            new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                selectedDate: date,
                dateType: 'common',
                minDate: type === 'forecastStart' ? new Date() : row.forecastStart ?? new Date(),
                maxDate: type === 'forecastStart' ? row.forecastFinish : null,
                commonSaveAction: (d: moment.Moment) => {
                    type === 'forecastStart' ? (row.forecastStart = d?.toDate()) : (row.forecastFinish = d?.toDate());
                    this.saveDate(row, type);
                },
            })
        );
    }

    showITRs(itrScope: ScheduleActivityITRScope, activityId: string, activityDiscipline: string, saveWeeklyPlan = false){
        this.store.dispatch(new ScheduleActivityPlanningResetITRFilter());
        this.store.dispatch(new ScheduleActivityITRFilterPropertyReset());
        this.store.dispatch(
            new ScheduleActivityITRFilterPropertyUpdate(
                ({ 
                    scope: itrScope, 
                    additionToPlan: this.additionToPlan,
                    activityId: activityId,
                    saveWeeklyPlan: saveWeeklyPlan,
                    activityDiscipline: activityDiscipline,
                    activityType: this.activityType
                }) as ScheduleActivityITRFilter
            )
        );

        this.ngZone.run(() =>
            this.router.navigate([`/weeklyplanning/lookaheaditrs`])
        );
    }

    openDetailedStatusPage(
        activityId: string,
        subsystem: string,
        scope: string,
        discipline: string,
        status: string = null,
        saveWeeklyPlan = false
    ) {
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.store.dispatch(
            new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(scope), scope })
        );
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        const itrDisciplines = discipline !== 'Mechanical' ? [discipline] : ['Mechanical', 'Civil', 'Piping'];
        this.ngZone.run(() =>
            this.router.navigate([`/detailedstatus`], {
                queryParams: {
                    scope,
                    subsystem,
                    discipline: itrDisciplines,
                    zone: Zone.Red,
                    status,
                    lockFilters: true,
                    activityId,
                    saveWeeklyPlan,
                    weekStart: this.currentlyViewedWeekStart.format('YYYY-MM-DD'),
                },
            })
        );
    }

    openCommentPopup(row: ScheduleActivityPlanningDTO, event: any) {
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment: row.comment,
                emailButtonVisible: false,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    this.saveComment(row, value, mentions);
                },
            })
        );

        event.stopPropagation();
    }

    getStartDateActivityStatusSuffix(row) {
        return row.activityStatus === 'Started' || row.activityStatus === 'Complete' ? 'A' : '';
    }

    getFinishDateActivityStatusSuffix(row) {
        return row.activityStatus === 'Complete' ? 'A' : '';
    }
}
