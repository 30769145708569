import { CriticalRFIManualPriorityHistoryActionTypes, ManualPriorityActions } from "./actions";
import { ManualPriorityHistoryState } from "./model";


const initialState: ManualPriorityHistoryState = {
    data: [],
    etcData: [],
    isLoading: false,
};

export function reducer(state: ManualPriorityHistoryState = initialState, action: ManualPriorityActions): ManualPriorityHistoryState {
    switch (action.type) {
        case CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIManualPriorityHistoryRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIManualPriorityHistorySuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        }
        case CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIManualPriorityHistoryError: {
            return {
                ...state,
                data: [],
                isLoading: false
            };
        }
        case CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIExpectedTimeofCompletionHistoryRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIExpectedTimeofCompletionHistorySuccess: {
            return {
                ...state,
                etcData: action.payload,
                isLoading: false
            };
        }
        case CriticalRFIManualPriorityHistoryActionTypes.CriticalRFIExpectedTimeofCompletionHistoryError: {
            return {
                ...state,
                etcData: [],
                isLoading: false
            };
        }
        default: {
            return state;
        }

    }
}