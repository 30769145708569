import { Component, OnInit, EventEmitter, ViewChild, NgZone, ElementRef, AfterViewChecked } from '@angular/core';
import { BaseComponent } from '../base.component';
import { FormGroup } from '@angular/forms';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/services/shared/toast.service';
import { RedZoneDTO, RedZoneFilter, ShowHideColumns } from 'src/app/store/redzone/model';
import { FormService } from 'src/app/services/shared/form.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { Store, select } from '@ngrx/store';
import { takeWhile, map, take, catchError } from 'rxjs/operators';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from '../../modules/shared/components/comment-popup/comment-popup.component';
import { ApplicationState } from 'src/app/store/model';
import { Constants } from 'src/app/constants';
import {
    Contractor,
    TCOACManager,
    TCOUser,
    Waypoint,
    Milestone,
    OrderDirection,
    Gooc,
    CalendarColumn,
    CheckListColumn,
    McMilestone,
    System,
    UserDetail,
    NumericColumn,
    StagedStartUpPriority,
} from 'src/app/store/common.model';
import { HeaderDateFilter, HeaderCheckListFilter, HeaderNumericFilter } from 'src/app/models/filter-settings';
import { forkJoin, of } from 'rxjs';
import * as _ from 'lodash';
import { RoleService } from 'src/app/services/shared/role.service';
import { Zone } from 'src/app/enums';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { CommentsHistoryComponent } from '../comments-history/comments-history.component';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import {
    DetailedStatusSetMakeRequest,
    DetailedStatusFilterReset,
    DetailedStatusResetLockedFilter,
    DetailedStatusSetTableOffset,
} from 'src/app/store/detailed-status/actions';
import { ChangeManagementSetMakeRequest, ChangeManagementFilterReset } from 'src/app/store/change-management/actions';
import { ClearableMatDatepickerComponent } from '../clearable-mat-datepicker/clearable-mat-datepicker.component';
import { getDetailedStatusFilterInstance } from 'src/app/extensions';
import { CommentDetailDTO } from 'src/app/store/detailed-status/model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PopoverContentComponent } from 'ngx-smart-popover';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { ForecastHistoryComponent } from '../forecast-history/forecast-history.component';
import { MatTable } from '@angular/material/table';
import { HeaderNumericFilterComponent } from 'src/app/modules/shared/components/filter/header-numeric-filter/header-numeric-filter.component';
import * as RedZoneActions from 'src/app/store/redzone/actions';

@Component({
    selector: 'app-redzone',
    templateUrl: './redzone.component.html',
    styleUrls: ['./redzone.component.scss'],
})
export class RedZoneComponent extends BaseComponent implements OnInit, AfterViewChecked {
    filterForm: FormGroup;
    isLoading = false;
    isUsersPerProjectLoading = false;
    resultsLength = 0;
    data: RedZoneDTO[] = [];
    mcStatusGridData$ = this.store.select((state) => state.redZone.dataPagination);
    mcStatusGridLoader$ = this.store.select((state) => state.redZone.isLoading);
    mcStatusFilter$ = this.store.select((state) => state.redZone.filter);
    mcStatusCurrentPage$ = this.store.select((state) => state.redZone.filter.page);
    columnMCForecast$ = this.store.select((state) => state.redZone.filter.columnMCForecast);
    columnMCPlan$ = this.store.select((state) => state.redZone.filter.columnMCPlan);
    columnMCCommitment$ = this.store.select((state) => state.redZone.filter.columnMCCommitment);
    columnMCActual$ = this.store.select((state) => state.redZone.filter.columnMCActual);
    columnSubsystems$ = this.store.select((state) => state.redZone.filter.columnSubsystems);
    columnComments$ = this.store.select((state) => state.redZone.filter.columnComments);
    columnDisciplines$ = this.store.select((state) => state.redZone.filter.columnDisciplines);
    columnNonCommissionable$ = this.store.select((state) => state.redZone.filter.columnNonCommissionable);
    columnSubsystemNames$ = this.store.select((state) => state.redZone.filter.columnSubsystemNames);
    columnDACActual$ = this.store.select((state) => state.redZone.filter.columnDACActual);
    columnDACForecast$ = this.store.select((state) => state.redZone.filter.columnDACForecast);
    columnWalkdownActual$ = this.store.select((state) => state.redZone.filter.columnWalkdownActual);
    columnWalkdownSch$ = this.store.select((state) => state.redZone.filter.columnWalkdownSch);
    columnWalkdownForecast$ = this.store.select((state) => state.redZone.filter.columnWalkdownForecast);
    columnFPLUpload$ = this.store.select((state) => state.redZone.filter.columnFPLUpload);
    columnFlags$ = this.store.select((state) => state.redZone.filter.columnFlags);
    sortBy$ = this.store.select((state) => state.redZone.filter.sortBy);
    direction$ = this.store.select((state) => state.redZone.filter.direction);
    milestones$ = this.store.select((state) => state.redZone.filter.milestones);
    waypoints$ = this.store.select((state) => state.redZone.filter.waypoints);
    columnPriorityName$ = this.store.select((state) => state.redZone.filter.columnPriorityName);
    columnGoocNo$ = this.store.select((state) => state.redZone.filter.columnGoocNo);
    columnConstruction90Walkdown$ = this.store.select((state) => state.redZone.filter.columnConstruction90Walkdown);
    columnMCSequence$ = this.store.select((state) => state.redZone.filter.columnMCSequence);

    columnAqvDsRemaining$ = this.store.select((state) => state.redZone.filter.columnAqvDsRemaining);
    columnAqvDsTotal$ = this.store.select((state) => state.redZone.filter.columnAqvDsTotal);
    columnAqvDsCompleted$ = this.store.select((state) => state.redZone.filter.columnAqvDsCompleted);
    columnAqvDsPercentage$ = this.store.select((state) => state.redZone.filter.columnAqvDsPercentage);
    columnApli$ = this.store.select((state) => state.redZone.filter.columnApli);
    columnNpw$ = this.store.select((state) => state.redZone.filter.columnNpw);
    columnBitr$ = this.store.select((state) => state.redZone.filter.columnBitr);
    columnBpli$ = this.store.select((state) => state.redZone.filter.columnBpli);
    columnCpli$ = this.store.select((state) => state.redZone.filter.columnCpli);
    columnDpli$ = this.store.select((state) => state.redZone.filter.columnDpli);
    columnEx$ = this.store.select((state) => state.redZone.filter.columnEx);
    columnCow$ = this.store.select((state) => state.redZone.filter.columnCow);
    columnRedlinesRemaining$ = this.store.select((state) => state.redZone.filter.columnRedlinesRemaining);
    columnRedlinesCompleted$ = this.store.select((state) => state.redZone.filter.columnRedlinesCompleted);
    columnRedlinesTotal$ = this.store.select((state) => state.redZone.filter.columnRedlinesTotal);
    columnRedlinesPercentage$ = this.store.select((state) => state.redZone.filter.columnRedlinesPercentage);
    columnFlangeMgtTotal$ = this.store.select((state) => state.redZone.filter.columnFlangeMgtTotal);
    columnFlangeMgtTotalCompleted$ = this.store.select((state) => state.redZone.filter.columnFlangeMgtTotalCompleted);
    columnFlangeMgtTotalRemaining$ = this.store.select((state) => state.redZone.filter.columnFlangeMgtTotalRemaining);
    columnFlangeMgtTotalPercentage$ = this.store.select((state) => state.redZone.filter.columnFlangeMgtTotalPercentage);

    numericColumns = [
        'columnSafetyRewalkdown',
        'columnAqvDsRemaining',
        'columnAqvDsTotal',
        'columnAqvDsCompleted',
        'columnAqvDsPercentage',
        'columnApli',
        'columnNpw',
        'columnBitr',
        'columnBpli',
        'columnCpli',
        'columnDpli',
        'columnEx',
        'columnCow',
        'columnRedlinesRemaining',
        'columnRedlinesCompleted',
        'columnRedlinesTotal',
        'columnRedlinesPercentage',
        'columnFlangeMgtTotal',
        'columnFlangeMgtTotalCompleted',
        'columnFlangeMgtTotalRemaining',
        'columnFlangeMgtTotalPercentage',
    ];

    prevWaypoints: Waypoint[] = [];
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMcMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setConstructionAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    sortBy = 'subsystem';
    pageSize = 25;
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    projectTeamNamesCurrent: string[];
    contractors: Contractor[] = [];
    acms: TCOACManager[] = [];
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    readonly isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);
    defaultDisplayedColumns: string[] = [
        'subsystem',
        'discipline',
        'subsystemName',
        'mcPlan',
        'mcForecast',
        'mcActual',
        'construction90Walkdown',
        'dacForecast',
        'dacActual',
        'walkdownForecast',
        'walkdownSch',
        'walkdownActual',
        'safetyRewalkdown',
        'fplUpload',
        'aqvd',
        'apli',
        'npw',
        'bitr',
        'bpli',
        'cpli',
        'dpli',
        'cow',
        'ex',
        'redline',
        'priorityName',
        'flangeMgt',
        'nonCommissionable',
        'mcSequence',
        'goocNo',
        'comment',
    ];
    displayedColumns = this.defaultDisplayedColumns;
    todayDate = new Date();
    autocompleteDisplayedColumns = ['name', 'description'];
    systemAutocompleteDisplayedColumns = ['no', 'name'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    priorityAutocompleteDisplayedColumns = ['priority', 'priorityName'];
    mcMilestoneAutocompleteDisplayedColumns = ['name'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    showHideColumns = new ShowHideColumns();
    columnMCForecast: CalendarColumn = null;
    columnMCPlan: CalendarColumn = null;
    columnMCCommitment: CalendarColumn = null;
    columnMCActual: CalendarColumn = null;
    columnConstruction90Walkdown: CalendarColumn = null;
    columnSubsystems: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnSubsystemNames: CheckListColumn = null;
    columnWalkdownActual: CalendarColumn = null;
    columnWalkdownForecast: CalendarColumn = null;
    columnFPLUpload: CalendarColumn = null;
    columnDACActual: CalendarColumn = null;
    columnDACForecast: CalendarColumn = null;
    columnDisciplines: CheckListColumn = null;
    columnNonCommissionable: CheckListColumn = null;
    columnPriorityName: CheckListColumn = null;
    columnFlags: CheckListColumn = null;
    columnWalkdownSch: CalendarColumn = null;
    columnGoocNo: CheckListColumn = null;
    columnMCSequence: CheckListColumn = null;
    columnSafetyRewalkdown: NumericColumn = null;
    columnAqvDsRemaining: NumericColumn = null;
    columnAqvDsTotal: NumericColumn = null;
    columnAqvDsCompleted: NumericColumn = null;
    columnAqvDsPercentage: NumericColumn = null;
    columnApli: NumericColumn = null;
    columnNpw: NumericColumn = null;
    columnBitr: NumericColumn = null;
    columnBpli: NumericColumn = null;
    columnCpli: NumericColumn = null;
    columnDpli: NumericColumn = null;
    columnEx: NumericColumn = null;
    columnCow: NumericColumn = null;
    columnRedlinesRemaining: NumericColumn = null;
    columnRedlinesCompleted: NumericColumn = null;
    columnRedlinesTotal: NumericColumn = null;
    columnRedlinesPercentage: NumericColumn = null;
    columnFlangeMgtTotal: NumericColumn = null;
    columnFlangeMgtTotalCompleted: NumericColumn = null;
    columnFlangeMgtTotalRemaining: NumericColumn = null;
    columnFlangeMgtTotalPercentage: NumericColumn = null;
    isReadOnly = false;
    disciplineBreakdownEnabled = false;
    selectedSubsystem = '';
    selectedRowIndex = -1;
    isAdmin = false;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild('trafficLightPopover', { static: true }) popoverContent: PopoverContentComponent;
    @ViewChild('tableContainer', { static: false }) table: ElementRef;
    @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;

    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private router: Router,
        private lookupService: LookupService,
        private toastService: ToastService,
        private ngZone: NgZone,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private projectTeamsService: ProjectTeamsService,
        private commentService: CommentService,
        private route: ActivatedRoute,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();

        this.filterForm = this.formSvc.createSimpleForm(new RedZoneFilter());
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
    }

    ngOnInit() {
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });
        this.filterExpansionPanel.expanded = true;
        this.isReadOnly = this.roleService.isReadOnly();

        this.mcStatusCurrentPage$.pipe(take(1)).subscribe((currentPage) => (this.paginator.pageIndex = currentPage));

        this.mcStatusFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam(filter.projectTeamNames || []);
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.showHideColumns = filter.showHideColumns;
            this.pageSize = filter.take;
            this.disciplineBreakdownEnabled = filter.disciplineBreakdownEnabled;
        });
        this.mcStatusGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            _.map(
                _.groupBy(
                    data.items.map((d) => ({ ...new RedZoneDTO(), ...d })),
                    (item) => item.subsystem
                ),
                (group) => (_.last(group).isLastInGroup = true)
            );
            this.data = data.items;
            this.setReadyForMcFlags();
            this.resultsLength = data.totalCount;
        });

        this.mcStatusGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            //this.changeDetectorRef.detectChanges();
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => (this.sortBy = sortBy));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => (this.direction = direction));

        this.columnMCForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnMCForecast = data));
        this.columnMCPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnMCPlan = data));
        this.columnMCCommitment$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnMCCommitment = data));
        this.columnMCActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnMCActual = data));
        this.columnComments$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnComments = items));
        this.columnSubsystems$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnSubsystems = items));
        this.columnFlags$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnFlags = items));
        this.columnDisciplines$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnDisciplines = items));
        this.columnSubsystemNames$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnSubsystemNames = items));
        this.columnDACActual$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnDACActual = items));
        this.columnDACForecast$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnDACForecast = items));
        this.columnWalkdownActual$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnWalkdownActual = items));
        this.columnWalkdownForecast$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnWalkdownForecast = items));
        this.columnNonCommissionable$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnNonCommissionable = items));
        this.columnFPLUpload$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnFPLUpload = items));
        this.columnWalkdownSch$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnWalkdownSch = items));
        this.columnPriorityName$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnPriorityName = items));
        this.columnGoocNo$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnGoocNo = items));
        this.columnConstruction90Walkdown$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnConstruction90Walkdown = data));

        this.columnMCSequence$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnMCSequence = items));

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key: 'sortBy', value: sortChange }));
            this.paginator.pageIndex = 0;
            this.store.dispatch(RedZoneActions.filterRequest({}));
        });

        this.filterForm.controls.waypoints.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((waypoints: Waypoint[]) => {
                this.prevWaypoints = waypoints;
                this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
            });

        this.filterForm.controls.milestones.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((milestones: Milestone[]) => {
                let waypoints = JSON.parse(JSON.stringify(milestones));
                waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
                waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name');
                this.setMilestoneInput.emit(new SetInputEventArgs(false, '', milestones));
                if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) {
                    this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                    this.filterForm.controls.waypoints.setValue(waypoints);
                }
            });

        this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            if (paramMap.get('subsystem') && !paramMap.get('discipline')) {
                this.resetFilters();
                let subystemFilter = new CheckListColumn();
                subystemFilter.items = [decodeURIComponent(paramMap.get('subsystem'))];
                this.columnSubsystems = subystemFilter;
                this.filterForm.controls.columnSubsystems.setValue(subystemFilter);
                this.paginator.pageIndex = 0;
                this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key: 'page', value: 0 }));
                if (this.disciplineBreakdownEnabled) {
                    this.toggleDisciplineBreakdown();
                } else {
                    this.store.dispatch(RedZoneActions.filterRequest({ filter: this.filterForm.value }));
                }
            } else if (paramMap.get('subsystem') && paramMap.get('discipline')) {
                this.resetFilters();

                let subystemFilter = new CheckListColumn();
                subystemFilter.items = [decodeURIComponent(paramMap.get('subsystem'))];

                let disciplineFilter = new CheckListColumn();
                const discipline = decodeURIComponent(paramMap.get('discipline'));
                disciplineFilter.items = discipline === '(Blanks)' ? [''] : [discipline];

                this.columnSubsystems = subystemFilter;
                this.columnDisciplines = disciplineFilter;
                this.filterForm.controls.columnSubsystems.setValue(subystemFilter);
                this.filterForm.controls.columnDisciplines.setValue(disciplineFilter);
                this.paginator.pageIndex = 0;
                this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key: 'page', value: 0 }));
                if (!this.disciplineBreakdownEnabled) {
                    this.toggleDisciplineBreakdown();
                } else {
                    this.store.dispatch(RedZoneActions.filterRequest({ filter: this.filterForm.value }));
                }
            } else if (this.resultsLength < this.pageSize) {
                this.store.dispatch(RedZoneActions.filterRequest({}));
            }
        });

        this.subscribeNumericFiltersUpdates();
    }

    ngAfterViewInit() {
        this.store
            .select((state) => state.detailedStatusState.tableOffset.redzone)
            .pipe(take(1))
            .subscribe((offset) => (this.table.nativeElement.scrollTop = offset ?? 0));

        this.store.dispatch(new DetailedStatusSetTableOffset({ redzone: 0 }));
    }

    ngAfterViewChecked(): void {
        if (this.matTable) {
            this.matTable.updateStickyColumnStyles();
        }
    }

    getSubsystemsInfoMessage() {
        if (this.resultsLength == 1) {
            if (this.disciplineBreakdownEnabled) {
                return 'subsystem-discipline';
            } else {
                return 'subsystem';
            }
        } else {
            if (this.disciplineBreakdownEnabled) {
                return 'subsystems-disciplines';
            } else {
                return 'subsystems';
            }
        }
    }

    subscribeNumericFiltersUpdates() {
        for (let col of this.numericColumns) {
            if (this[`${col}$`]) {
                this[`${col}$`].pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this[col] = data));
            }
        }
    }

    resetColumns() {
        this.showHideColumns = new ShowHideColumns();
    }

    openHeaderNumericFilter(property: string, currentState: NumericColumn, placeHolder: string) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderNumericFilter>(HeaderNumericFilterComponent, 400, 350, {
                placeHolder: placeHolder,
                showBlankOptions: true,
                isAscendingSort: this.sortBy === property && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === property && this.direction == OrderDirection.Desc,
                minValue: currentState ? currentState.minValue : null,
                maxValue: currentState ? currentState.maxValue : null,
                excludeBlanks: currentState ? currentState.excludeBlanks : false,
                onlyBlanks: currentState ? currentState.onlyBlanks : false,
                onSumbit: (data: HeaderNumericFilter) => {
                    let newFilterState = new NumericColumn();
                    newFilterState = { ...data };

                    const columnName = `column${this.capitalizeFirstLetter(property)}`;
                    if (
                        this.numericColumns.includes(columnName) &&
                        this.filterForm.controls.hasOwnProperty(columnName)
                    ) {
                        this.filterForm.controls[columnName].setValue(newFilterState);
                    }

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, property);
                    this.search();
                },
                onReset: () => {
                    const columnName = `column${this.capitalizeFirstLetter(property)}`;
                    this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key: columnName, value: null }));
                },
            } as HeaderNumericFilter)
        );
    }

    toggleDisciplineBreakdown() {
        this.displayedColumns = this.disciplineBreakdownEnabled
            ? this.defaultDisplayedColumns.filter((c) => c !== 'discipline')
            : this.defaultDisplayedColumns;
        if (this.showHideColumns.showDacStatus) {
            this.reverseValue('showDacStatus');
        }
        this.disciplineBreakdownEnabled = !this.disciplineBreakdownEnabled;
        this.filterForm.controls.disciplineBreakdownEnabled.setValue(this.disciplineBreakdownEnabled);
        this.store.dispatch(
            RedZoneActions.filterPropertyUpdate({
                key: 'disciplineBreakdownEnabled',
                value: this.disciplineBreakdownEnabled,
            })
        );
        this.filterForm.controls.columnDisciplines.setValue(null);
        this.store.dispatch(
            RedZoneActions.filterPropertyUpdate({
                key: 'columnDisciplines',
                value: null,
            })
        );
        this.store.dispatch(RedZoneActions.filterRequest({ filter: this.filterForm.value }));
    }

    reverseValue(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };
        this.store.dispatch(
            RedZoneActions.filterPropertyUpdate({ key: 'showHideColumns', value: this.showHideColumns })
        );
    }

    checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName].value.length &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm.controls[formControlName].setValue(valid);
        }
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'milestones');
        this.setMcMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'mcMilestones');
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'waypoints');
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'goocs');
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'contractors');
        this.setSystemInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'systems');

        forkJoin([
            this.lookupService.getACMs(projectTeamNames),
            this.lookupService.getMCEngineers(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([acms, mcEngineers, sysOwners]) => {
                    this.acms = acms;
                    this.checkIfSelectedUsersValid(this.acms, 'acm', 'id');
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getConstructionArea = (search = '', take = 30, page = 0) =>
        this.lookupService.searchConstructionArea(search, take, page, this.filterForm.value.projectTeamNames);

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((waypoints: Waypoint[]) => {
                return waypoints;
            })
        );
    };

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.isUsersPerProjectLoading = true;
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMilestones(
                search,
                take,
                page,
                this.filterForm.value.waypoints,
                this.filterForm.value.projectTeamNames
            )
            .pipe(map((milestones: Milestone[]) => milestones));
    };

    getMcMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMcMilestones(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((mcMilestones: McMilestone[]) => mcMilestones));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getSystems = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchSystems(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((systems: System[]) => systems));
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((prio: StagedStartUpPriority[]) => prio));
    };

    onStagedStartUpPrioritiesClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key: 'take', value: pageEvent.pageSize }));
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key: 'page', value: pageEvent.pageIndex }));
        }
        this.store.dispatch(RedZoneActions.filterRequest({}));
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    resetFilters(event: Event = null) {
        if (event) {
            event.stopPropagation();
        }

        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(RedZoneActions.filterReset());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setSystemInput.emit(new SetInputEventArgs(false, ''));
        this.setConstructionAreaInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.setValue(new RedZoneFilter());
    }

    displayMultipleFilterSelected(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    exportToExcel() {
        this.store.dispatch(RedZoneActions.exportToExcelRequest());
    }

    saveScrollProgress() {
        this.store.dispatch(new DetailedStatusSetTableOffset({ redzone: this.table.nativeElement.scrollTop }));
    }

    openDetailedStatusPage(subsystem: string, scope: string, discipline: string, category?: string, status?: string[]) {
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.store.dispatch(
            new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(scope), scope })
        );
        this.saveScrollProgress();
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        const disciplines = discipline ? [discipline] : null;
        this.ngZone.run(() =>
            this.router.navigate([`/detailedstatus`], {
                queryParams: {
                    scope,
                    subsystem,
                    discipline: disciplines,
                    category,
                    zone: Zone.Red,
                    status,
                    lockFilters: true,
                    allowCommentsBulkUpdate: true,
                },
            })
        );
    }

    openCommentPopup(element: RedZoneDTO, event: any) {
        const comment = element.comment || '';
        if (this.isReadOnly) return;

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: element.mentions,
                url: this.disciplineBreakdownEnabled
                    ? `redzone?subsystem=${element.subsystem}&discipline=${element.discipline}`
                    : `redzone?subsystem=${element.subsystem}&showDisciplineBreakdown=true`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(RedZoneActions.addBulkCommentRequest({ description: value, mentions }));
                    } else {
                        this.store.dispatch(
                            RedZoneActions.addCommentRequest({
                                id: this.disciplineBreakdownEnabled
                                    ? element.subsystemToDisciplineId
                                    : element.subsystem,
                                description: value,
                                mentions,
                            })
                        );
                    }
                },
            })
        );

        event.stopPropagation();
    }

    displaySelectedACM(acmIds: number[]) {
        return this.acms
            .filter((acm) => acmIds.includes(acm.id))
            .map((acm) => acm.name)
            .join(', ');
    }

    displaySelectedRadioYesNo(value: boolean) {
        if (value === null) {
            return 'All';
        }
        return value ? 'Yes' : 'No';
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displayMultipleSelected(values: (Milestone | Waypoint | Contractor)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    displaySystemsSelected(values: System[]) {
        return values.map((x) => x.no).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    forecastDateHistory(event: MouseEvent, element: RedZoneDTO, type: string) {
        event.preventDefault();
        this.popupSvc.openPopup(
            new PopupSettings(ForecastHistoryComponent, 550, 500, {
                subsystem: element.subsystem,
                dateType: type,
                discipline: element.discipline,
            })
        );
    }

    openDatepicker(element: RedZoneDTO, type: string) {
        let date;
        if (type === 'mc') {
            if (this.isReadOnly || element.mcActual) {
                return;
            }
            date = element.mcForecast ? element.mcForecast : '';
        } else if (type === 'dac') {
            if (this.isReadOnly || element.dacActual) {
                return;
            }
            date = element.dacForecast ? element.dacForecast : '';
        } else if (type === 'mcWalkdown') {
            if (this.isReadOnly || element.walkdownActual) {
                return;
            }
            date = element.walkdownForecast ? element.walkdownForecast : '';
        } else if (type === 'constr90Walkdown') {
            date = element.construction90Walkdown ? element.construction90Walkdown : '';
        }

        this.popupSvc.openPopup(
            new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                data: this.data,
                selectedDate: date,
                selectedDateSubsystem: element.subsystem,
                dateType: type,
                discipline: element.discipline,
                minDate: new Date(),
            })
        );
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.filterForm, key, value);
                }
            }
        }
    }

    clearflagSubsystem() {
        this.popupSvc
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to clear flags for all filtered subsystems (${this.resultsLength})?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.store.dispatch(RedZoneActions.clearFlagColorRequest());
                }
            });
    }

    search() {
        this.updateFilterByFormProperties();

        this.paginator.pageIndex = 0;
        this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key: 'page', value: 0 }));
        this.store.dispatch(RedZoneActions.filterRequest({}));
    }

    openChangeManagementPage(subsystem: string, changeType: string, discipline: string, lockFilters = true) {
        this.store.dispatch(new ChangeManagementSetMakeRequest());
        this.store.dispatch(new ChangeManagementFilterReset());
        this.saveScrollProgress();
        this.router.navigate(['/changemanagement'], {
            queryParams: { subsystem, changeType, discipline, lockFilters, allowCommentsBulkUpdate: true },
        });
    }

    private getLatestFilterData(): RedZoneFilter {
        let filter: RedZoneFilter;
        this.store.pipe(select((x) => x.redZone, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getSubsystems = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemsWithRedZoneFilter(search, take, page, this.getLatestFilterData());

    getComments = (search = '', take = 30, page = 0) =>
        this.lookupService.searchCommentsWithRedZoneFilter(search, take, page, this.getLatestFilterData());

    getSubsystemNames = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemNamesWithRedZoneFilter(search, take, page, this.getLatestFilterData());

    getNonCommissionable = () => of(['Yes', 'No', 'Empty']);

    getDACStatus = (search = '', take = 30, page = 0) =>
        this.lookupService.searchDACStatusWithRedZoneFilter(search, take, page, this.getLatestFilterData());

    getWalkdownStatus = (search = '', take = 30, page = 0) =>
        this.lookupService.searchWalkdownStatusWithRedZoneFilter(search, take, page, this.getLatestFilterData());

    getGoocNo = (search = '', take = 30, page = 0) =>
        this.lookupService.searchGoocNoWithRedZoneFilter(search, take, page, this.getLatestFilterData());

    getMCSequences = (search = '', take = 30, page = 0) =>
        this.lookupService.searchMCSequencesWithRedZoneFilter(search, take, page, this.getLatestFilterData());

    getPriorityNames = (search = '', take = 30, page = 0) =>
        this.lookupService.searchPriorityNamesWithRedZoneFilter(search, take, page, this.getLatestFilterData());

    getDisciplines = (search = '', take = 30, page = 0) =>
        this.lookupService.searchDisciplinesWithRedZoneFilter(search, take, page, this.getLatestFilterData());

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 690, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                excludeBlanks: excludeBlanks,
                onlyBlanks: onlyBlanks,
                selectedFlags: this.columnFlags ? [...this.columnFlags.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                showBlankOptions: showBlankOptions,
                showTrafficLightFilter: columnName === 'subsystem',
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.filterForm.controls[propertyName].setValue(value);
                    if (columnName === 'subsystem') {
                        let flags = new CheckListColumn();
                        flags.items = data.selectedFlags;
                        this.filterForm.controls['columnFlags'].setValue(flags);
                    }
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key: propertyName, value: null }));
                    if (columnName === 'subsystem') {
                        this.store.dispatch(RedZoneActions.filterPropertyUpdate({ key: 'columnFlags', value: null }));
                    }
                },
                cancelAction: () => {
                    this.store.dispatch(
                        RedZoneActions.filterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                    if (columnName === 'subsystem') {
                        this.store.dispatch(
                            RedZoneActions.filterPropertyUpdate({
                                key: 'columnFlags',
                                value: this.filterForm.controls.columnFlags.value,
                            })
                        );
                    }
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(
                HeaderDateFilterComponent,
                null,
                null,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                    placeHolder: placeholder,
                    isSortingOff: false,
                    action: (data) => {
                        this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                        this.search();
                    },
                },
                345,
                580
            )
        );
    }

    applySort(property: string) {
        let isDesc = true;
        if (this.sortBy === property) {
            isDesc = this.direction === OrderDirection.Asc;
        }

        this.sortUpdate(!isDesc, isDesc, property);
        this.search();
    }

    openHistoryPopup(element: RedZoneDTO) {
        if (element.comment === null) {
            return;
        } else {
            this.isLoading = true;
            this.commentService
                .getCommentsBySubsystem(
                    this.disciplineBreakdownEnabled ? element.subsystemToDisciplineId.toString() : element.subsystem,
                    this.disciplineBreakdownEnabled ? Zone.Red : Zone.RedNoDiscipline
                )
                .subscribe((data) => {
                    this.isLoading = false;
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            CommentsHistoryComponent,
                            null,
                            null,
                            {
                                commentHist: data,
                                action: (id: number) => {
                                    data = data.filter((s) => s.id !== id);
                                    this.store.dispatch(
                                        RedZoneActions.removeCommentRequest({
                                            id,
                                            lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                            entityId: this.disciplineBreakdownEnabled
                                                ? element.subsystemToDisciplineId
                                                : element.subsystem,
                                        })
                                    );
                                },
                            },
                            200,
                            200
                        )
                    );
                });
        }
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                RedZoneActions.filterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                RedZoneActions.filterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: this.sortBy, direction: this.direction },
                })
            );
        }
    }

    setReadyForMcFlags() {
        this.data = this.data.map((d) => ({
            ...d,
            isReadyForMc: d.aqvDsRemaining === 0 && d.apli === 0 && d.npw === 0 && d.ex === 0,
        }));
    }

    selectSubsystem(subsystem: string) {
        this.selectedSubsystem = subsystem;
    }

    flagSubsystem(subsystem: string, color: string) {
        this.store.dispatch(RedZoneActions.setFlagColorRequest({ subsystem, color }));
        this.popoverContent.hide();
    }

    getSubsystemClasses(data: RedZoneDTO[], index: number) {
        const flagClasses = this.getFlagColorClassName(data[index]);
        return {
            ...flagClasses,
            'row-underline': this.isRowUnderlined(data, index),
        };
    }

    getFlagColorClassName(item: RedZoneDTO) {
        return { [`${item.redZoneFlag}-flag`]: !!item.redZoneFlag, flag: true };
    }

    isRowUnderlined(data: RedZoneDTO[], index: number) {
        if (!this.disciplineBreakdownEnabled || index === data.length - 1) {
            return false;
        } else {
            return data[index].subsystem != data[index + 1].subsystem;
        }
    }

    highlightRow(row: any) {
        this.selectedRowIndex = row;
    }

    toggleExpansionPanel(event: Event) {
        if (event) event.stopPropagation();
        this.filterExpansionPanel.toggle();
    }
}
