import { Injectable } from '@angular/core';
import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    ChangeManagementActionTypes,
    ChangeManagementFilterSuccess,
    ChangeManagementFilterError,
    ChangeManagementAddCommentRequest,
    ChangeManagementAddCommentSuccess,
    ChangeManagementAddCommentError,
    ChangeManagementExportToExcelSuccess,
    ChangeManagementExportToExcelError,
    ChangeManagementAddBulkCommentRequest,
    ChangeManagementAddBulkCommentSuccess,
    ChangeManagementAddBulkCommentError,
    ChangeManagementDeleteCommentRequest,
    ChangeManagementDeleteCommentSuccess,
    ChangeManagementDeleteCommentError,
    ChangeManagementFilterRequest,
    ChangeManagementUpdateFieldImplementationContractorRequest,
    ChangeManagementFieldImplementationContractorError,
    ChangeManagementFieldImplementationContractorSuccess    
} from './actions';
import { withLatestFrom, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { ChangeManagementService } from 'src/app/services/api/webapi-services/change-management.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { Zone } from 'src/app/enums';
import * as moment from 'moment';

@Injectable()
export class ChangeManagementEffects {    
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private changeManagementService: ChangeManagementService,
        private toastService: ToastService,
        private commentService: CommentService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType(ChangeManagementActionTypes.ChangeManagementFilterRequest),
        withLatestFrom(this.store.select((store) => store.changeManagementState.filter)),
        mergeMap(([, filter]) => {
            return this.changeManagementService.getChangeManagementData(filter).pipe(
                map((dataPagination) => {
                    return new ChangeManagementFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Change management data. Please contact Program Administrator.'
                    );
                    return of(new ChangeManagementFilterError(error));
                })
            );
        })
    );

    @Effect()
    addDetailComment$ = this.actions$.pipe(
        ofType<ChangeManagementAddCommentRequest>(ChangeManagementActionTypes.ChangeManagementAddCommentRequest),
        mergeMap(({ payload }) =>
            this.commentService
                .addCommentZone(payload.id.toString(), payload.description, payload.zone || Zone.ChangeManagement, 
                payload.mentions)
                .pipe(
                    map(() => {
                        this.toastService.Success('Comment successfully added.');
                        return new ChangeManagementAddCommentSuccess({
                            id: payload.id,
                            description: payload.description,
                            mentions: payload.mentions,
                            zone: payload.zone || Zone.ChangeManagement
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding comment. Please contact Program Administrator.'
                        );
                        return of(new ChangeManagementAddCommentError(error));
                    })
                )
        )
    );

    @Effect()
    addBulkDetailComment$ = this.actions$.pipe(
        ofType<ChangeManagementAddBulkCommentRequest>(
            ChangeManagementActionTypes.ChangeManagementAddBulkCommentRequest
        ),
        withLatestFrom(this.store.select((store) => store.changeManagementState.filter)),
        mergeMap(([action, filter]) =>
            this.commentService
                .addBulkCommentZone(action.payload.description, action.payload.mentions, filter, Zone.ChangeManagement)
                .pipe(
                    map(() => {
                        this.toastService.Success('Bulk Comment successfully added.');
                        return new ChangeManagementAddBulkCommentSuccess({
                            description: action.payload.description,
                            mentions: action.payload.mentions,
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding bulk comment. Please contact Program Administrator.'
                        );
                        return of(new ChangeManagementAddBulkCommentError(error));
                    })
                )
        )
    );

    @Effect()
    updateFieldImplementationContractor$ = this.actions$.pipe(
        ofType<ChangeManagementUpdateFieldImplementationContractorRequest>(ChangeManagementActionTypes.ChangeManagementUpdateFieldImplementationContractorRequest),
        mergeMap((action) => {
            return this.changeManagementService.updateFieldImplementationContractor(action.payload)
            .pipe(
                map(() => {
                    this.toastService.Success('Field Implementation Contractor Updated.');
                    return new ChangeManagementFieldImplementationContractorSuccess(action.payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering loop status. Please contact Program Administrator.'
                    );
                    return of(new ChangeManagementFieldImplementationContractorError(error));
                })
            );
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(ChangeManagementActionTypes.ChangeManagementExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.changeManagementState.filter)),
        mergeMap(([, changeManagementFilter]) => {
            const filter = {
                ...changeManagementFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.changeManagementService.generateExcel(filter).pipe(
                map((excelData) => new ChangeManagementExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new ChangeManagementExportToExcelError(error));
                })
            );
        })
    );

    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<ChangeManagementDeleteCommentRequest>(ChangeManagementActionTypes.ChangeManagementDeleteCommentRequest),
        withLatestFrom(this.store.select((store) => store.changeManagementState.dataPagination.items)),
        mergeMap(([{ payload }, items]) =>
            this.commentService.deleteCommentChangeDocument(payload.id).pipe(
                map(() => {
                    this.toastService.Success('Comment deleted successfully.');
                    return new ChangeManagementDeleteCommentSuccess({ id: payload.id });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while deleting comment. Please contact Program Administrator.'
                    );
                    return of(new ChangeManagementDeleteCommentError(error));
                })
            )
        ),
        switchMap(() => of(new ChangeManagementFilterRequest()))
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(ChangeManagementActionTypes.ChangeManagementExportToExcelSuccess),
        map((action: ChangeManagementExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `ChngMgmt_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );    
}
