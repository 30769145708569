import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewEncapsulation,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  BaseChartComponent,
  calculateViewDimensions,
  ColorHelper,
  DataItem,
  ViewDimensions,
} from '@swimlane/ngx-charts';
import { scaleBand, scaleLinear } from 'd3-scale';
import { BarChartType, BarOrientation, LegendOptions, LegendPosition, ScaleType } from '../../utils/Types';

@Component({
  selector: 'app-custom-grouped-vertical-stacked-chart',
  templateUrl: './custom-grouped-vertical-stacked-chart.component.html',
  styleUrls: ['./custom-grouped-vertical-stacked-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*',
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' })),
      ]),
    ]),
  ],
})
export class CustomGroupedVerticalStackedChartComponent extends BaseChartComponent {
  @Input() stackChartResult: any;
  @Input() activeEntries: any[] = [];

  @Input() xAxis;
  @Input() yAxis;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() showXAxisLabel: boolean;
  @Input() showYAxisLabel: boolean;
  @Input() trimXAxisTicks: boolean = true;
  @Input() trimYAxisTicks: boolean = true;
  @Input() rotateXAxisTicks: boolean = true;
  @Input() maxXAxisTickLength: number = 16;
  @Input() maxYAxisTickLength: number = 16;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  @Input() yAxisTicks: any[];
  @Input() yScaleMax: number;

  @Input() animations: boolean = true;
  @Input() gradient: boolean = false;
  @Input() schemeType: ScaleType;
  @Input() showGridLines: boolean = true;
  @Input() tooltipDisabled: boolean = false;
  @Input() barPadding: number = 8;
  @Input() groupPadding: number = 16;
  @Input() roundDomains: boolean = false;
  @Input() roundEdges: boolean = true;
  @Input() showDataLabel: boolean = false;
  @Input() dataLabelFormatting: any;
  @Input() noBarWhenZero: boolean = true;

  @Input() showLegend: boolean = false;
  @Input() legendTitle: string = 'Legend';
  @Input() legendPosition: LegendPosition = LegendPosition.Right;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;
  xAxisHeight: number = 0;
  yAxisWidth: number = 0;
  groupDomain: string[];
  innerDomain: string[];
  valueDomain: [number, number];
  groupScale: any;
  innerScale: any;
  valueScale: any;
  xScale: any;
  yScale: any;
  numberOfColumns: number = 0;
  stackedColumnPlace: number = 0;
  transform: string;
  colors: ColorHelper;
  margin: number[] = [10, 20, 10, 20];
  legendOptions: LegendOptions;
  dataLabelMaxHeight: any = { negative: 0, positive: 0 };
  isSSR = false;
  barOrientation = BarOrientation;
  barChartType = BarChartType;

  update(): void {
    super.update();
    if (!this.stackChartResult) {
      this.stackChartResult = { place: 0, data: [{ name: '', series: [{ name: '', value: 0 }] }] };
      this.stackedColumnPlace = this.stackChartResult.place - 1;
    }
    if (!this.showDataLabel) {
      this.dataLabelMaxHeight = { negative: 0, positive: 0 };
    }
    this.margin = [10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.showLegend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition,
    });

    if (this.showDataLabel) {
      this.dims.height -= this.dataLabelMaxHeight.negative;
    }

    this.formatDates();
    this.setStackedColumnPlace();
    const stackedNumberOfColumns = 1;
    this.numberOfColumns = this.getSeriesFromVerticalChartData().length + stackedNumberOfColumns;

    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();
    this.valueDomain = this.getValueDomain();

    this.groupScale = this.getGroupScale();
    this.innerScale = this.getInnerScale();
    this.valueScale = this.getValueScale();

    this.xScale = this.getXScale();
    this.yScale = this.getYScale();

    this.setColors();
    this.legendOptions = this.getLegendOptions();
    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
  }

  getXScale(): any {
    const spacing = this.groupDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().rangeRound([0, this.dims.width]).paddingInner(spacing).domain(this.groupDomain);
  }

  getYScale(): any {
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.valueDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  onDataLabelMaxHeightChanged(event, groupIndex: number): void {
    if (event.size.negative) {
      this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
    } else {
      this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
    }
    if (groupIndex === this.results.length - 1) {
      setTimeout(() => this.update());
    }
  }

  getGroupScale(): any {
    const spacing = this.groupDomain.length / (this.dims.height / this.groupPadding + 1);

    return scaleBand()
      .rangeRound([0, this.dims.width])
      .paddingInner(spacing)
      .paddingOuter(spacing / 2)
      .domain(this.groupDomain);
  }

  /* 
  * Sets place of vertical columns on chart
  */
  getInnerScale(): any {
    const domain = this.getDomainSeries();
    const width = this.groupScale.bandwidth();
    const spacing = this.innerDomain.length / (width / this.barPadding + 1);
    return scaleBand().rangeRound([0, width]).paddingInner(spacing).domain(domain);
  }

  /*
  * Get domain item series (each (single or stacked as one) column name)
  */
  private getDomainSeries() {
    const domain: string[] = [];
    for (let index = 0; index < this.results.length; index++) {
      const verticalDataItems = this.results[index];
      for (const verticalDataItem of verticalDataItems.series) {
        if (!domain.includes(verticalDataItem.name)) {
          domain.push(verticalDataItem.name);
        }
      }

      const stackedDomain: string[] = [];
      for (const stackedDataItem of this.stackChartResult.data[index].series) {
        if (!stackedDomain.includes(stackedDataItem.name)) {
          stackedDomain.push(stackedDataItem.name);
        }
      }
      const stackedItem = stackedDomain.toString();
      if (!domain.includes(stackedItem)) {
        domain.splice(this.stackedColumnPlace - 1, 0, stackedItem);
      }
    }

    return domain;
  }

  getValueScale(): any {
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.valueDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  getGroupDomain(): string[] {
    const domain = [];
    for (const group of this.results) {
      if (!domain.includes(group.label)) {
        domain.push(group.label);
      }
    }

    return domain;
  }

  /*
  * Get separately domain items (each single column name)
  */
  getInnerDomain(): string[] {
    return this.getSeriesFromVerticalChartData()
      .concat(this.getSeriesFromStackedChartData());
  }

  private getSeriesFromVerticalChartData(): string[] {
    const domain: string[] = [];
    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.label)) {
          domain.push(d.label);
        }
      }
    }
    return domain;
  }

  private getSeriesFromStackedChartData(): string[] {
    const domain: string[] = [];
    for (const group of this.stackChartResult.data) {
      for (const d of group.series) {
        if (!domain.includes(d.name)) {
          domain.push(d.name);
        }
      }
    }
    return domain;
  }

  /*
  * Get min, max values from chart data.
  */
  getValueDomain(): [number, number] {
    const domain = [];
    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.value)) {
          domain.push(d.value);
        }
      }
    }

    for (const group of this.stackChartResult.data) {
      let stackedSum = 0;
      for (const d of group.series) {
        stackedSum += d.value;
      }
      domain.push(stackedSum);
    }

    const min = Math.min(0, ...domain);
    const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(0, ...domain);

    return [min, max];
  }

  /*
  * Sets place of vertical chart columns
  */
  groupTransform(group: any): string {
    return `translate(${this.groupScale(group.label) || 0}, 0)`;
  }

  /*
  * Sets place of stacked chart column
  */
  groupTransformForStackedColumn(group: any): string {
    const width = this.groupScale.bandwidth();
    const spacing =  this.groupScale.paddingInner() * this.innerDomain.length + this.groupScale.paddingOuter() * this.innerDomain.length;
    const oneColumnLength = ((width - (width / this.numberOfColumns)) + spacing) / (this.numberOfColumns - 1);
    return `translate(${this.groupScale(group.name) + (oneColumnLength * (this.stackedColumnPlace - 1) - (spacing / 2)) || 0}, 0)`;
  }

  onClick(data, group?: DataItem): void {
    if (group) {
      data.series = group.name;
    }

    this.select.emit(data);
  }

  trackBy: TrackByFunction<DataItem> = (index: number, item: DataItem) => {
    return item.name;
  };

  setColors(): void {
    let domain;
    if (this.schemeType === ScaleType.Ordinal) {
      domain = this.innerDomain;
    } else {
      domain = this.valueDomain;
    }

    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions(): LegendOptions {
    const opts = {
      scaleType: this.schemeType as any,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition,
    };
    if (opts.scaleType === ScaleType.Ordinal) {
      opts.domain = this.innerDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.valueDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  updateYAxisWidth({ width }: { width: number }): void {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }: { height: number }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(event, group: DataItem, fromLegend: boolean = false): void {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    const items = this.results
      .map((g) => g.series)
      .flat()
      .filter((i) => {
        if (fromLegend) {
          return i.label === item.name;
        } else {
          return i.name === item.name && i.series === item.series;
        }
      });

    this.activeEntries = [...items];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(event, group: DataItem, fromLegend: boolean = false): void {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    this.activeEntries = this.activeEntries.filter((i) => {
      if (fromLegend) {
        return i.label !== item.name;
      } else {
        return !(i.name === item.name && i.series === item.series);
      }
    });

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

  private setStackedColumnPlace() {
    this.stackedColumnPlace = this.stackChartResult.place;
    if (this.stackedColumnPlace - this.numberOfColumns >= 1) {
      this.stackedColumnPlace = this.numberOfColumns;
    }
  }
}

