<div class="weekly-change-raised-container">
    <h4>changes raised by type</h4>

    <div class="chart-container">
        <div class="bar-chart-container">
            <ngx-charts-bar-vertical-stacked
                [results]="data"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
            >
            </ngx-charts-bar-vertical-stacked>
        </div>
    </div>
    <div *ngIf="isLoading$ | async" class="weekly-change-raised-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
