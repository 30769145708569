import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import {
    ConstraintSelectionMode,
    ScheduleActivityPlanningConstraint,
} from 'src/app/components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';

@Component({
    selector: 'app-constraint-type-selector',
    templateUrl: './constraint-type-selector.component.html',
    styleUrls: ['./constraint-type-selector.component.scss'],
})
export class ConstraintTypeSelectorPopupComponent implements OnInit {
    selectionMode: ConstraintSelectionMode;
    constraintTypes: ScheduleActivityPlanningConstraint[] = [];
    constraintTypeForm: UntypedFormGroup;
    isLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<ConstraintTypeSelectorPopupComponent>,
        private scheduleActivityLookupService: ScheduleActivityLookupService,
        private configurationService: ConfigurationService
    ) {
        this.constraintTypeForm = new UntypedFormGroup({ constraintType: new UntypedFormControl('') });
    }

    ngOnInit() {
        this.isLoading = true;
        forkJoin([
            this.scheduleActivityLookupService.searchConstraintTypes(100000),
            this.configurationService.getConstraintSelectionMode(),
        ])
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe(([{ items }, selectionMode]) => {
                this.constraintTypes = items;
                this.constraintTypeForm = new UntypedFormGroup({
                    constraintType: new UntypedFormControl(
                        '',
                        selectionMode === ConstraintSelectionMode.Mandatory ? [Validators.required] : []
                    ),
                });
                this.constraintTypeForm.markAllAsTouched();
            });
    }

    save() {
        this.dialogRef.close({ constraintType: this.constraintTypeForm.controls.constraintType.value });
    }
}
