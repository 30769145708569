import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';

export interface MasterSubsystemListState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deltaData: MasterSubsystemListDeltaPagination;
    isDeltaDataLoading: boolean;
    deleteData: MasterSubsystemListDeletePagination;
    isDeleteDataLoading: boolean;
    deltaFilter: MasterSubsystemListDeltaFilter;
    deleteFilter: MasterSubsystemListDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
    isAutomaticUploadInProgress: boolean;
}

export class MasterSubsystemListDeltaDTO {
    subsystem: string;
    projectTeamNameLive: string;
    projectTeamNameImport: string;
    acmLive: string;
    acmImport: string;
    scManagerLive: string;
    scManagerImport: string;
    mcEngineerLive: string;
    mcEngineerImport: string;
    systemOwnerLive: string;
    systemOwnerImport: string;
    waypointLive: string;
    waypointImport: string;
    milestoneLive: string;
    milestoneImport: string;
    contractorLive: string;
    contractorImport: string;
    deltaState: boolean;
}

export class MasterSubsystemListDeleteDTO {
    subsystem: string;
    subsystemName: string;
    projectTeamName: string;
    mcEngineer: string;
    systemOwner: string;
    deleteState: boolean;
}

export class MasterSubsystemListDeltaFilter {
    page = 0;
    take = 10;
}

export class MasterSubsystemListDeleteFilter {
    page = 0;
    take = 10;
}

export class MasterSubsystemListDeltaPagination {
    items: MasterSubsystemListDeltaDTO[] = [];
    totalCount = 0;
}

export class MasterSubsystemListDeletePagination {
    items: MasterSubsystemListDeleteDTO[] = [];
    totalCount = 0;
}
