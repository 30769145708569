<div class="background">
    <app-top-header></app-top-header>
    <app-navbar></app-navbar>
    <router-outlet *ngIf="!isIframe"></router-outlet>
    <app-footer></app-footer>
    <app-excel-export-list
        [items]="recentExportEvents$ | async"
        [expanded]="anyUnprocessedEvents$ | async"
        [connected]="connected$ | async">
    </app-excel-export-list>
</div>
