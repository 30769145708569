import { Action } from '@ngrx/store';
import { BaseResultDTO, UserDetail } from '../common.model';
import { PLIPlanningDTO, PLIPlanningFilter } from './model';

export enum PLIPlanningActionTypes {
    PLIPlanningDataRequest = '[PLI PLanning] Data request',
    PLIDataRequestSuccess = '[PLI PLanning] Data request Success',
    PLIDataRequestError = '[PLI PLanning] Data request Error',
    PLIPlanningDataUpdate = '[PLI Planning] Update table data',

    FilterUpdate = '[PLI PLanning] Filter Update',

    UncommittedPLIsUpdate = '[PLI PLanning] Uncommitted PLIs Update',
    SaveWeekPlanRequest = '[PLI PLanning] Save Week plan',
    SaveWeekPlanRequestSuccess = '[PLI PLanning] Save Week plan Success',
    SaveWeekPlanRequestError = '[PLI PLanning] Save Week plan Error',

    ExportToExcelRequest = '[PLI PLanning] Export to Excel Request',
    ExportToExcelSuccess = '[PLI PLanning] Export to Excel Success',
    ExportToExcelError = '[PLI PLanning] Export to Excel Error',

    AddCommentRequest = '[PLI PLanning] Add Comment Request',
    AddCommentSuccess = '[PLI PLanning] Add Comment Success',
    AddCommentError = '[PLI PLanning] Add Comment Error',
}

export class PLIPLanningDataRequest implements Action {
    readonly type = PLIPlanningActionTypes.PLIPlanningDataRequest;

    constructor() {
        // This is intentional
    }
}

export class PLIPLanningDataRequestSuccess implements Action {
    readonly type = PLIPlanningActionTypes.PLIDataRequestSuccess;

    constructor(public payload: BaseResultDTO<PLIPlanningDTO>) {
        // This is intentional
    }
}

export class PLIPLanningDataRequestError implements Action {
    readonly type = PLIPlanningActionTypes.PLIDataRequestError;

    constructor(public payload: { error: any }) {
        // This is intentional
    }
}

export class PLIPlanningFilterUpdate implements Action {
    readonly type = PLIPlanningActionTypes.FilterUpdate;

    constructor(public payload: PLIPlanningFilter) {
        // This is intentional
    }
}

export class UncommittedPLIsUpdate implements Action {
    readonly type = PLIPlanningActionTypes.UncommittedPLIsUpdate;

    constructor(public payload: PLIPlanningDTO[]) {
        // This is intentional
    }
}

export class SaveWeekPlanRequest implements Action {
    readonly type = PLIPlanningActionTypes.SaveWeekPlanRequest;

    constructor() {
        // This is intentional
    }
}

export class SaveWeekPlanRequestSuccess implements Action {
    readonly type = PLIPlanningActionTypes.SaveWeekPlanRequestSuccess;

    constructor(public payload: PLIPlanningDTO[]) {
        // This is intentional
    }
}

export class SaveWeekPlanRequestError implements Action {
    readonly type = PLIPlanningActionTypes.SaveWeekPlanRequestError;

    constructor(public payload: { error: any }) {
        // This is intentional
    }
}

export class PLIPlanningExportToExcelRequest implements Action {
    readonly type = PLIPlanningActionTypes.ExportToExcelRequest;

    constructor() {
        // This is intentional
    }
}

export class PLIPlanningExportToExcelRequestSuccess implements Action {
    readonly type = PLIPlanningActionTypes.ExportToExcelSuccess;

    constructor(public payload: any) {
        // This is intentional
    }
}

export class PLIPlanningExportToExcelRequestError implements Action {
    readonly type = PLIPlanningActionTypes.ExportToExcelError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class PLIPlanningAddCommentRequest implements Action {
    readonly type = PLIPlanningActionTypes.AddCommentRequest;

    constructor(public payload: { entityId: string; description: string; mentions: UserDetail[] }) {
        // This is intentional
    }
}

export class PLIPlanningAddCommentSuccess implements Action {
    readonly type = PLIPlanningActionTypes.AddCommentSuccess;

    constructor(public payload: { entityId: string; description: string; mentions: UserDetail[] }) {
        // This is intentional
    }
}

export class PLIPlanningAddCommentError implements Action {
    readonly type = PLIPlanningActionTypes.AddCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class PLIPLanningDataUpdate implements Action {
    readonly type = PLIPlanningActionTypes.PLIPlanningDataUpdate;

    constructor(public payload: PLIPlanningDTO[]) {
        // This is intentional
    };
}

export type PLIPlanningActions =
    | PLIPLanningDataRequest
    | PLIPLanningDataRequestSuccess
    | PLIPLanningDataRequestError
    | PLIPlanningFilterUpdate
    | UncommittedPLIsUpdate
    | SaveWeekPlanRequest
    | SaveWeekPlanRequestSuccess
    | SaveWeekPlanRequestError
    | PLIPlanningExportToExcelRequest
    | PLIPlanningExportToExcelRequestSuccess
    | PLIPlanningExportToExcelRequestError
    | PLIPlanningAddCommentRequest
    | PLIPlanningAddCommentSuccess
    | PLIPlanningAddCommentError
    | PLIPLanningDataUpdate;
