import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum SystemizedDrawingsActionTypes {
    SystemizedDrawingsUploadLogRequest = '[Systemized Drawings] Upload Log Request',
    SystemizedDrawingsUploadLogSuccess = '[Systemized Drawings] Upload Log Success',
    SystemizedDrawingsUploadLogError = '[Systemized Drawings] Upload Log Error',
    SystemizedDrawingsSetStatusAndStartDate = '[Systemized Drawings] Set Status and Start Date',
    SystemizedDrawingsValidateDeltaRequest = '[Systemized Drawings] Validate Delta Request',
    SystemizedDrawingsValidateDeltaSuccess = '[Systemized Drawings] Validate Delta Success',
    SystemizedDrawingsValidateDeltaError = '[Systemized Drawings] Validate Delta Error',
    SystemizedDrawingsFileUploaded = '[Systemized Drawings] File Uploaded',
    SystemizedDrawingsClearInProgressSpinner = '[Systemized Drawings] Clear In Progress Spinner',
    SystemizedDrawingsTemplateFileRequest = '[Systemized Drawings] Template File Request',
    SystemizedDrawingsTemplateFileSuccess = '[Systemized Drawings] Template File Success',
    SystemizedDrawingsTemplateFileError = '[Systemized Drawings] Template File Error',
    SystemizedDrawingsValidateButtonStateRequest = '[Systemized Drawings] Validate Button State Request',
    SystemizedDrawingsValidateButtonStateSuccess = '[Systemized Drawings] Validate Button State Success',
    SystemizedDrawingsValidateButtonStateError = '[Systemized Drawings] Validate Button State Error',
    SystemizedDrawingsDownloadOutputDataRequest = '[Systemized Drawings] Download Output Data Request',
    SystemizedDrawingsDownloadOutputDataSuccess = '[Systemized Drawings] Download Output Data Success',
    SystemizedDrawingsDownloadOutputDataError = '[Systemized Drawings] Download Output Data Error',
    SystemizedDrawingsAddStartedLog = '[SystemizedDrawings] Add Started Log',
}

export class SystemizedDrawingsAddStartedLog implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsAddStartedLog;
}

export class SystemizedDrawingsUploadLogRequest implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsUploadLogRequest;
}

export class SystemizedDrawingsUploadLogSuccess implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class SystemizedDrawingsUploadLogError implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsUploadLogError;

    constructor(public payload: string) {}
}

export class SystemizedDrawingsSetStatusAndStartDate implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class SystemizedDrawingsValidateDeltaRequest implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsValidateDeltaRequest;
}

export class SystemizedDrawingsValidateDeltaSuccess implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsValidateDeltaSuccess;
}

export class SystemizedDrawingsValidateDeltaError implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsValidateDeltaError;

    constructor(public payload: string) {}
}

export class SystemizedDrawingsFileUploaded implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsFileUploaded;
}

export class SystemizedDrawingsClearInProgressSpinner implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsClearInProgressSpinner;
}

export class SystemizedDrawingsTemplateFileRequest implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsTemplateFileRequest;

    constructor(public payload: string) {}
}

export class SystemizedDrawingsTemplateFileSuccess implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class SystemizedDrawingsTemplateFileError implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsTemplateFileError;

    constructor(public payload: string) {}
}

export class SystemizedDrawingsValidateButtonStateRequest implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsValidateButtonStateRequest;
}

export class SystemizedDrawingsValidateButtonStateSuccess implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class SystemizedDrawingsValidateButtonStateError implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsValidateButtonStateError;

    constructor(public payload: string) {}
}

export class SystemizedDrawingsDownloadOutputDataRequest implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class SystemizedDrawingsDownloadOutputDataSuccess implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class SystemizedDrawingsDownloadOutputDataError implements Action {
    readonly type = SystemizedDrawingsActionTypes.SystemizedDrawingsDownloadOutputDataError;

    constructor(public payload: string) {}
}

export type SystemizedDrawingsActions =
    | SystemizedDrawingsUploadLogRequest
    | SystemizedDrawingsUploadLogSuccess
    | SystemizedDrawingsUploadLogError
    | SystemizedDrawingsSetStatusAndStartDate
    | SystemizedDrawingsValidateDeltaRequest
    | SystemizedDrawingsValidateDeltaSuccess
    | SystemizedDrawingsValidateDeltaError
    | SystemizedDrawingsFileUploaded
    | SystemizedDrawingsClearInProgressSpinner
    | SystemizedDrawingsTemplateFileRequest
    | SystemizedDrawingsTemplateFileSuccess
    | SystemizedDrawingsTemplateFileError
    | SystemizedDrawingsValidateButtonStateRequest
    | SystemizedDrawingsValidateButtonStateSuccess
    | SystemizedDrawingsValidateButtonStateError
    | SystemizedDrawingsDownloadOutputDataRequest
    | SystemizedDrawingsDownloadOutputDataSuccess
    | SystemizedDrawingsDownloadOutputDataError
    | SystemizedDrawingsAddStartedLog;
