import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, takeWhile, take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { SubsystemScope } from 'src/app/enums';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { TraceHeatingStatusService } from 'src/app/services/api/webapi-services/reports/trace-heating-status.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AttachmentDTO } from 'src/app/store/details/model';
import { ApplicationState } from 'src/app/store/model';
import { TraceHeatingStatusAttachmentsUpdated, TraceHeatingStatusDownloadAttachmentRequest } from 'src/app/store/trace-heating-status/action';
import { Subsystem, THSAttachmentDto } from 'src/app/store/trace-heating-status/model';
import { Dictionary } from 'typescript-collections';

@Component({
  selector: 'app-model-shots',
  templateUrl: './model-shots.component.html',
  styleUrls: ['./model-shots.component.scss']
})
export class ModelShotsComponent extends BaseComponent implements OnInit {
  filterForm: FormGroup;
  setTdbSubsystemsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

  scope: SubsystemScope = SubsystemScope.THS;
  attachments: THSAttachmentDto[] = [];
  attachmentsToUpload : THSAttachmentDto[] = [];
  scopeToString = '';
  isUploadInProgress = false;
  uploadingAttachmentsActionInProgress: boolean = false;
  uploadDisabled = true;
  isLoading = false;
  traceHeatingStatisGridLoader$ = this.store.select((state) => state.traceHeatingStatusState.isLoading);

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<ModelShotsComponent>,
    private formService: FormService,
    private traceHeatingStatusService: TraceHeatingStatusService,
    private toastService: ToastService,
    private store: Store<ApplicationState>,
  ) {
    super();
    this.filterForm = this.formService.createSimpleForm({ tdbSubsystems: []});
  }

  ngOnInit(): void {
    this.filterForm.get('tdbSubsystems').valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((subsystems : Subsystem[]) => {
        this.traceHeatingStatusService.getTHSAttachment(subsystems[0]?.id).pipe(takeWhile(() => this.isAlive)).subscribe((attachments) => {
          this.attachments = attachments;
        });
        this.uploadDisabled = subsystems[0] ? false : true;
    });
    this.traceHeatingStatisGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
        this.isLoading = isLoading;
    });
  }

  close() {
    this.dialogRef.close();
  }

  getTdbSubsystems = (search = '', take = 10, page = 0) => {
    return this.traceHeatingStatusService.searchSubsystems(search, take, page, true);
  };

  fileChangeEvent(files: Array<File>) {
    const filesWrapper = new Array<File>();
    filesWrapper.push(...files);
    filesWrapper.forEach((file) => {
        if (file.size === 0) {
            this.toastService.Info(`File ${file.name} has 0B size. Please upload a valid file.`);
            return;
        }
        if (
            this.attachments.filter((x) => x.file?.name === file.name).length > 0 ||
            (this.attachments &&
                this.attachments.filter((x: AttachmentDTO) => x.name === file.name).length > 0)
        ) {
            this.toastService.Info(
                'File ' + file.name + ' already uploaded. Please delete existing file and re-upload.'
            );
        } else {
            const attachment = new THSAttachmentDto();
            attachment.file = file;
            attachment.name = file.name;
            attachment.isValid = true;
            this.attachmentsToUpload.push(attachment);
            this.uploadingAttachmentsActionInProgress = true;
        }
    });
  }

  uploadNewAttachments() {
    this.isLoading = true;
    const formData = new FormData();
    this.attachmentsToUpload.forEach((file) => {
        formData.append(file.file.name, file.file);
    });
    const tdbSubsystem = this.filterForm.value['tdbSubsystems'][0].id
    this.traceHeatingStatusService
        .uploadAttachments(formData, tdbSubsystem, this.scope)
        .pipe(
            take(1),
            catchError(() => {
                this.toastService.Error(
                    'Error has occurred while uploading attachment(s). Please contact Program Administrator.'
                );
                return of(null);
            })
        )
        .subscribe((uploadedAttachments: Dictionary<string, string>) => {
            const attachments = Object.keys(uploadedAttachments).map(
                (key): THSAttachmentDto => {
                    return {
                        name: key,
                        link: uploadedAttachments[key],
                        tdbSubsystem: tdbSubsystem
                    };
                }
            );
            this.attachments = [...this.attachments, ...attachments];
            this.attachmentsToUpload = [];
            this.store.dispatch(new TraceHeatingStatusAttachmentsUpdated(tdbSubsystem, this.attachments))
            this.isLoading = false;
        });
  }

  downloadUploadedAttachment(index: number) {
    this.store.dispatch(new TraceHeatingStatusDownloadAttachmentRequest(this.attachments, index));
  }

  deleteUploadedAttachment(index: number) {
    this.isLoading = true;
    const tdbSubsystem = this.filterForm.value['tdbSubsystems'][0].id
    this.traceHeatingStatusService
        .deleteAttachment([this.attachments[index].link], this.scope)
        .pipe(
            take(1),
            catchError(() => {
                this.toastService.Error(
                    'Error has occurred while deleting attachment. Please contact Program Administrator.'
                );
                return of(null);
            })
        )
        .subscribe(() => {
            this.attachments = [
                ...this.attachments.slice(0, index),
                ...this.attachments.slice(index + 1),
            ];
            this.store.dispatch(new TraceHeatingStatusAttachmentsUpdated(tdbSubsystem, this.attachments))
            this.isLoading = false;

        });
  }
}
