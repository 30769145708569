import { Component, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BaseComponent } from '../../base.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { map, takeWhile } from 'rxjs/operators';
import { Area, Contractor } from 'src/app/store/common.model';
import { FormService } from 'src/app/services/shared/form.service';
import { ChangeValidationDashboardFilter } from 'src/app/store/reports/change-validation-dashboard/model';
import {
    ChangeValidationDashboardFilterReset,
    ChangeValidationDashboardWeeklyValidationProgressRequest,
    ChangeValidationDashboardMonthlyValidationProgressRequest,
    ChangeValidationDashboardValidationProgressRequest,
    ChangeValidationDashboardCumulativeValidationProgressRequest,
    ChangeValidationDashboardSystemisationProgressRequest,
    ChangeValidationDashboardMonthlyChangeRaisedRequest,
    ChangeValidationDashboardFilterUpdate,
} from 'src/app/store/reports/change-validation-dashboard/actions';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-change-validation-dashboard',
    templateUrl: './change-validation-dashboard.component.html',
    styleUrls: ['./change-validation-dashboard.component.scss'],
})
export class ChangeValidationDashboardComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    autocompleteDisplayedColumns = ['name', 'description'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    changeValidationDashboardFilter$ = this.store.select((state) => state.changeValidationDashboardState.filter);
    projectTeamNames: string[] = [];
    projectTeamNamesCurrent: string[];
    changeStatuses: string[];
    disciplines: string[] = [];
    changeTypes: string[] = [];
    isUsersPerProjectLoading = false;
    weekViewEnabledForValidationProgress = false;

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>,
        private projectTeamsService: ProjectTeamsService,
        private toastService: ToastService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new ChangeValidationDashboardFilter());
    }

    ngOnInit() {
        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });
        this.filterExpansionPanel.expanded = true;
        this.changeValidationDashboardFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam(filter.projectTeamNames || []);
        });

        this.filterForm.valueChanges.pipe(
            takeWhile(() => this.isAlive)
        ).subscribe((filters) => {
            this.store.dispatch(new ChangeValidationDashboardFilterUpdate(filters as ChangeValidationDashboardFilter));
        });

        this.lookupService
            .getDesignStatuses()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (statuses: string[]) => {
                    this.changeStatuses = statuses.filter(item => item != 'Void' && item != 'Superseded');
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while getting change statuses. Please contact Program Administrator.'
                    );
                }
            );

        forkJoin([this.lookupService.getDisciplines(), this.lookupService.getChangeTypes()])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([disciplines, changeTypes]) => {
                    this.disciplines = disciplines;
                    this.changeTypes = changeTypes.filter(x => x !== 'SID' && x !== 'Call-Off' && x !== 'TMOC' && x !== 'SC Call-Off');
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines or change types. Please contact Program Administrator.'
                    );
                }
            );
    }

    getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setAreaInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'contractors');
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'area');
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchChangeDocContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getAreas = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchAreas(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((area: Area[]) => area));
    };

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.isUsersPerProjectLoading = true;
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    displayMultipleSelected(values: Contractor[]) {
        return values.map((x) => x.name).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }
    
    toggleMonthView() {
        this.weekViewEnabledForValidationProgress = !this.weekViewEnabledForValidationProgress;
    }

    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setAreaInput.emit(new SetInputEventArgs(false, ''));
        this.store.dispatch(new ChangeValidationDashboardFilterReset());
    }

    search() {
        this.store.dispatch(new ChangeValidationDashboardWeeklyValidationProgressRequest());
        this.store.dispatch(new ChangeValidationDashboardMonthlyValidationProgressRequest());
        this.store.dispatch(new ChangeValidationDashboardValidationProgressRequest());
        this.store.dispatch(new ChangeValidationDashboardCumulativeValidationProgressRequest());
        this.store.dispatch(new ChangeValidationDashboardSystemisationProgressRequest());
        this.store.dispatch(new ChangeValidationDashboardMonthlyChangeRaisedRequest());
    }
}
