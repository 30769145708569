import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { HealthService } from 'src/app/services/api/webapi-services/reports/health.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    HealthActionTypes,
    HealthCleanlinessSuccess,
    HealthCleanlinessError,
    HealthCleanlinessRequest,
    HealthOverdueDPLITableSuccess,
    HealthOverdueDPLITableError,
    HealthOverdueDPLIChartSuccess,
    HealthOverdueDPLIChartError,
    HealthOverdueDPLIExportToExcelRequestSuccess,
    HealthOverdueDPLIExportToExcelRequestError,
    HealthOverdueExceptionsTableSuccess,
    HealthOverdueExceptionsTableError,
    HealthOverdueExceptionsChartSuccess,
    HealthOverdueExceptionsChartError,
    HealthOverdueExceptionsExportToExcelRequestSuccess,
    HealthOverdueExceptionsAddCommentRequest,
    HealthOverdueExceptionsAddCommentSuccess,
    HealthOverdueExceptionsAddCommentError,
    HealthOverdueExceptionsExportToExcelRequestError,
    OverdueExceptionsByPriorityChartSuccess,
    OverdueExceptionsByPriorityChartError,
    OverdueExceptionsBySubsystemChartSuccess,
    OverdueExceptionsBySubsystemChartError,
    overdueExceptionsBySubsystemTableRequest,
    overdueExceptionsBySubsystemTableSuccess,
    overdueExceptionsBySubsystemTableError,
    overdueExceptionsByPriorityTableRequest,
    overdueExceptionsByPriorityTableSuccess,
    overdueExceptionsByPriorityTableError,
    OverdueExceptionsByPriorityExportToExcelRequestSuccess,
    OverdueExceptionsByPriorityExportToExcelRequestError,
    ActivitiesConstraintsChartSuccess,
    ActivitiesConstraintsChartError,
    ActivitiesConstraintsChartRequest,
    ITRConstraintsChartSuccess,
    ITRConstraintsChartError,
    ITRConstraintsChartRequest
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { of } from 'rxjs';
import { ActivitiesConstraintsFilter, HealthFilter, HEALTH_API_CALL_DATE_FORMAT, OverdueExceptionsByPriorityFilters } from './model';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { SubsystemScope } from 'src/app/enums';
import { saveAs } from 'file-saver';

@Injectable()
export class HealthEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private healthService: HealthService,
        private toastService: ToastService,
        private commentService: CommentService
    ) {}

    @Effect()
    planningCleanlinessRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthCleanlinessRequest),
        withLatestFrom(
            this.store.select((store) => _.head(store.healthState.cleanliness.weeks)),
            this.store.select((store) => _.last(store.healthState.cleanliness.weeks)),
            this.store.select((store) => store.healthState.cleanliness.cleanlinessType),
            this.store.select((store) => store.healthState.cleanliness.filter)
        ),
        mergeMap(([, startDate, endDate, cleanlinessType, filter]) => {
            const requestfilter = {
                ...filter,
                startDate: moment(startDate.date).format(HEALTH_API_CALL_DATE_FORMAT),
                endDate: moment(endDate.date).add(6, 'days').format(HEALTH_API_CALL_DATE_FORMAT),
            } as HealthFilter;

            return this.healthService.filterHealthCleanlinessData(cleanlinessType, requestfilter).pipe(
                map((subsystems) => new HealthCleanlinessSuccess(subsystems)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting ${cleanlinessType.toUpperCase()} Cleanliness data. Please contact Program Administrator.`
                    );
                    return of(new HealthCleanlinessError(error));
                })
            );
        })
    );

    @Effect()
    requestCleanlinessOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthCleanlinessSetWeekRange),
        map(() => new HealthCleanlinessRequest())
    );

    @Effect()
    overdueDPLITableRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthOverdueDPLITableRequest),
        withLatestFrom(this.store.select((state) => state.healthState.overdueDpli.filter)),
        mergeMap(([, filter]) =>
            this.healthService.filterOverdueDPLITableData(filter).pipe(
                map((response) => new HealthOverdueDPLITableSuccess(response)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Overdue DPLI Table data. Please contact Program Administrator.`
                    );
                    return of(new HealthOverdueDPLITableError(error));
                })
            )
        )
    );

    @Effect()
    overdueDPLIExportToExcelRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthOverdueDPLIExportToExcelRequest),
        withLatestFrom(this.store.select((state) => state.healthState.overdueDpli.filter)),
        mergeMap(([, filter]) => {
            return this.healthService.generateOverdueDPLIExcel(filter).pipe(
                map(
                    (excelData) => new HealthOverdueDPLIExportToExcelRequestSuccess(excelData),
                    catchError((err) => {
                        this.toastService.Error(
                            'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                        );
                        return of(new HealthOverdueDPLIExportToExcelRequestError(err));
                    })
                )
            );
        })
    );

    @Effect({ dispatch: false })
    overdueDPLIExportToExcelSaveData$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthOverdueDPLIExportToExcelRequestSuccess),
        map((action: HealthOverdueDPLIExportToExcelRequestSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `OverdueDPLIReport_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
        })
    );

    @Effect()
    overdueDPLIChartRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthOverdueDPLIChartRequest),
        withLatestFrom(this.store.select((state) => state.healthState.overdueDpli.filter)),
        mergeMap(([, filter]) =>
            this.healthService.filterOverdueDPLIChartData(filter).pipe(
                map((response) => new HealthOverdueDPLIChartSuccess(response)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Overdue DPLI Chart data. Please contact Program Administrator.`
                    );
                    return of(new HealthOverdueDPLIChartError(error));
                })
            )
        )
    );

    @Effect()
    overdueExceptionsTableRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthOverdueExceptionsTableRequest),
        withLatestFrom(this.store.select((state) => state.healthState.overdueExceptions.filter)),
        mergeMap(([, filter]) =>
            this.healthService.filterOverdueExceptionsTableData(filter).pipe(
                map((response) => new HealthOverdueExceptionsTableSuccess(response)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Overdue Exceptions Table data. Please contact Program Administrator.`
                    );
                    return of(new HealthOverdueExceptionsTableError(error));
                })
            )
        )
    );

    @Effect()
    overdueExceptionsChartRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthOverdueExceptionsChartRequest),
        withLatestFrom(this.store.select((state) => state.healthState.overdueExceptions.filter)),
        mergeMap(([, filter]) =>
            this.healthService.filterOverdueExceptionsChartData(filter).pipe(
                map((response) => new HealthOverdueExceptionsChartSuccess(response)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Overdue Exceptions Chart data. Please contact Program Administrator.`
                    );
                    return of(new HealthOverdueExceptionsChartError(error));
                })
            )
        )
    );

    @Effect()
    overdueExceptionsExportToExcelRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthOverdueExceptionsExportToExcelRequest),
        withLatestFrom(this.store.select((state) => state.healthState.overdueExceptions.filter)),
        mergeMap(([, filter]) => {
            return this.healthService.generateOverdueExceptionsExcel(filter).pipe(
                map((excelData) => {
                    this.toastService.Success('Data successfully exported to Excel.');

                    const blob = new Blob([excelData as any], {
                        type: 'application/octet-stream',
                    });

                    saveAs(blob, `OverdueExceptionsReport_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
                    return new HealthOverdueExceptionsExportToExcelRequestSuccess(excelData);
                }),
                catchError((err) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new HealthOverdueExceptionsExportToExcelRequestError(err));
                })
            );
        })
    );

    @Effect()
    addComment$ = this.actions$.pipe(
        ofType(HealthActionTypes.HealthOverdueExceptionsAddCommentRequest),
        mergeMap((action: HealthOverdueExceptionsAddCommentRequest) =>
            this.commentService
                .addComment(
                    action.payload.entityId,
                    action.payload.description,
                    SubsystemScope.AQVD,
                    action.payload.mentions
                )
                .pipe(
                    map(() => {
                        this.toastService.Success('Comment successfully added.');
                        return new HealthOverdueExceptionsAddCommentSuccess(action.payload);
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while updating comment. Please contact Program Administrator.'
                        );
                        return of(new HealthOverdueExceptionsAddCommentError(error));
                    })
                )
        )
    );

    @Effect()
    overdueExceptionsByPriorityChartRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.OverdueExceptionsByPriorityChartRequest),
        withLatestFrom(this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter)),
        mergeMap(([, filter]) => {
            let requestFilter = {
                ...filter,
                actionBy: filter.actionBy
                    .map((x) => (typeof x === 'string' ? x : x['id']))
                    .filter((x) => x != null || x != undefined),
            } as OverdueExceptionsByPriorityFilters;
            return this.healthService.filterOverdueExceptionsByPriorityChartData(requestFilter).pipe(
                map((response) => new OverdueExceptionsByPriorityChartSuccess(response)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Overdue Exceptions By Priority Chart data. Please contact Program Administrator.`
                    );
                    return of(new OverdueExceptionsByPriorityChartError(error));
                })
            );
        })
    );

    overdueExceptionsByPriorityTableRequest$ = createEffect(
        () => this.actions$.pipe(
            ofType(overdueExceptionsByPriorityTableRequest),
            withLatestFrom(this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter)),
            mergeMap(([, filter]) => {
                let requestFilter = {
                    ...filter,
                    actionBy: filter.actionBy
                        .map((x) => (typeof x === 'string' ? x : x['id']))
                        .filter((x) => x != null || x != undefined),
                } as OverdueExceptionsByPriorityFilters;

                return this.healthService.filterOverdueExceptionsByPriorityTableData(requestFilter).pipe(
                    map((response) => overdueExceptionsByPriorityTableSuccess({ payload: response })),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while getting Overdue Exceptions By Priority Table data. Please contact Program Administrator.`
                        );
                        return of(overdueExceptionsByPriorityTableError({ error: error }));
                    })
                );
            })
        )
    );

    @Effect()
    overdueExceptionsBySubsystemChartRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.OverdueExceptionsBySubsystemChartRequest),
        withLatestFrom(this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter)),
        mergeMap(([, filter]) => {
            let requestFilter = {
                ...filter,
                actionBy: filter.actionBy
                    .map((x) => (typeof x === 'string' ? x : x['id']))
                    .filter((x) => x != null || x != undefined),
            } as OverdueExceptionsByPriorityFilters;

            return this.healthService.filterOverdueExceptionsBySubsystemChartData(requestFilter).pipe(
                map((response) => new OverdueExceptionsBySubsystemChartSuccess(response)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Overdue Exceptions By Priority Chart data. Please contact Program Administrator.`
                    );
                    return of(new OverdueExceptionsBySubsystemChartError(error));
                })
            );
        })
    );

    overdueExceptionsBySubsystemTableRequest$ = createEffect(
        () => this.actions$.pipe(
            ofType(overdueExceptionsBySubsystemTableRequest),
            withLatestFrom(this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter)),
            mergeMap(([, filter]) => {
                let requestFilter = {
                    ...filter,
                    actionBy: filter.actionBy
                        .map((x) => (typeof x === 'string' ? x : x['id']))
                        .filter((x) => x != null || x != undefined),
                } as OverdueExceptionsByPriorityFilters;

                return this.healthService.filterOverdueExceptionsBySubsystemTableData(requestFilter).pipe(
                    map((response) => overdueExceptionsBySubsystemTableSuccess({ payload: response })),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while getting Overdue Exceptions By Priority Table data. Please contact Program Administrator.`
                        );
                        return of(overdueExceptionsBySubsystemTableError({ error: error }));
                    })
                );
            })
        )
    );

    @Effect()
    overdueExceptionsByPriorityExportToExcelRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.OverdueExceptionsByPriorityExportToExcelRequest),
        withLatestFrom(this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter)),
        mergeMap(([, filter]) => {
            let requestFilter = {
                ...filter,
                actionBy: filter.actionBy
                    .map((x) => (typeof x === 'string' ? x : x['id']))
                    .filter((x) => x != null || x != undefined),
            } as OverdueExceptionsByPriorityFilters;
            return this.healthService.generateOverdueExceptionsByPriorityExcel(requestFilter).pipe(
                map((excelData) => {
                    this.toastService.Success('Data successfully exported to Excel.');

                    const blob = new Blob([excelData as any], {
                        type: 'application/octet-stream',
                    });

                    saveAs(blob, `OverdueExceptionsByPriorityReport_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
                    return new OverdueExceptionsByPriorityExportToExcelRequestSuccess(excelData);
                }),
                catchError((err) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new OverdueExceptionsByPriorityExportToExcelRequestError(err));
                })
            );
        })
    );

    @Effect()
    ActivitiesConstraintsChartRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.ActivitiesConstraintsChartRequest),
        withLatestFrom(
            this.store.select((store) => _.head(store.healthState.activitiesConstraints.weeks)),
            this.store.select((store) => _.last(store.healthState.activitiesConstraints.weeks)),
            this.store.select((state) => state.healthState.activitiesConstraints.filter)),
        mergeMap(([,startDate, endDate, filter]) => {

            const requestfilter = {
                ...filter,
                startDate: moment(startDate.date).format(HEALTH_API_CALL_DATE_FORMAT),
                endDate: moment(endDate.date).add(6, 'days').format(HEALTH_API_CALL_DATE_FORMAT),
            } as ActivitiesConstraintsFilter;

            return this.healthService.filterActivitiesConstraintsChartData(requestfilter).pipe(
                map((response) => new ActivitiesConstraintsChartSuccess(response)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Activities Constraint Chart data. Please contact Program Administrator.`
                    );
                    return of(new ActivitiesConstraintsChartError(error));
                })
            );
        })
    );
    @Effect()
    activitiesConstraintsFilterSetWeekRange$ = this.actions$.pipe(
        ofType(HealthActionTypes.ActivitiesConstraintsFilterSetWeekRange),
        map(() => new ActivitiesConstraintsChartRequest())
    );

    @Effect()
    ITRConstraintsChartRequest$ = this.actions$.pipe(
        ofType(HealthActionTypes.ITRConstraintsChartRequest),
        withLatestFrom(
            this.store.select((store) => _.head(store.healthState.activitiesConstraints.weeks)),
            this.store.select((store) => _.last(store.healthState.activitiesConstraints.weeks)),
            this.store.select((state) => state.healthState.activitiesConstraints.filter)),
        mergeMap(([,startDate, endDate, filter]) => {

            const requestfilter = {
                ...filter,
                startDate: moment(startDate.date).format(HEALTH_API_CALL_DATE_FORMAT),
                endDate: moment(endDate.date).add(6, 'days').format(HEALTH_API_CALL_DATE_FORMAT),
            } as ActivitiesConstraintsFilter;

            return this.healthService.filterITRsConstraintsChartData(requestfilter).pipe(
                map((response) => new ITRConstraintsChartSuccess(response)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Activities Constraint Chart data. Please contact Program Administrator.`
                    );
                    return of(new ITRConstraintsChartError(error));
                })
            );
        })
    );

    @Effect()
    ITRConstraintsFilterSetWeekRange$ = this.actions$.pipe(
        ofType(HealthActionTypes.ITRConstraintsFilterSetWeekRange),
        map(() => new ITRConstraintsChartRequest())
    );
}
