<main class="main">
    <div class="title-container">
        <div class="title">My Page</div>
    </div>
    <div class="results-container">
        <div class="results-title">
            <form [formGroup]="filterForm">
                <mat-checkbox formControlName="unreadOnly">
                    Show only unread
                </mat-checkbox>
            </form>
        </div>

        <table
            mat-table
            matSort
            [dataSource]="data"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container matColumnDef="type">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter('type', getTypes, 'columnTypes', 'Type', columnTypes, true, false)
                    "
                >
                    <div class="sort-container">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnTypes)
                            }"
                            >Type</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'type'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ getCommentType(element.type) }}</td>
            </ng-container>
            <ng-container matColumnDef="comment">
                <th mat-header-cell disableClear *matHeaderCellDef>Comment</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.comment | formatMentions: element.mentions"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="author">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter('author', getAuthors, 'columnAuthors', 'Author', columnAuthors, true)
                    "
                >
                    <div class="sort-container">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnAuthors)
                            }"
                            >Author
                        </span>
                        <div class="sort-icon" *ngIf="sortBy === 'author'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.author }}</td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="openHeaderDateFilter('date', 'columnDates', 'Comment Date', columnDates)"
                >
                    <div class="sort-container">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCalendarColumn(columnDates)
                            }"
                            >Comment Date</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'date'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.date | date: 'd/MMM/yy' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="read">
                <th mat-header-cell disableClear *matHeaderCellDef>Read</th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox [checked]="element.isRead" (change)="markRead(element)"></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="gotolink">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon (click)="openComment(element.link)" class="arrow">arrow_forward</mat-icon>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>
