import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as UserActions from './actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { UserService } from 'src/app/services/api/webapi-services/user.service';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private toastService: ToastService,
        private userService: UserService
    ) {}

    syncUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.synchronizeUser),
            mergeMap(() => {
                return this.userService.addUserDetails().pipe(
                    map(() => {
                        this.toastService.Success('User has been added.');
                    }),
                    catchError((res) => {
                        if (res.status !== 302 && res.status !== 0) {
                            this.toastService.Error(
                                'Error occurred while adding user. '
                                +'Please contact Program Administrator.'
                                );
                        }
                        return of();
                    })
                )
            })
        ),
        { dispatch: false }
    );
}