import { find } from 'lodash';
import { BaseResultDTO } from '../common.model';
import { PLIPlanningActions, PLIPlanningActionTypes } from './actions';
import { PLIColumnsVisibility, PLIPlanningDTO, PLIPlanningFilter, PLIPlanningState } from './model';

const initialState: PLIPlanningState = {
    data: { items: [], totalCount: 0 } as BaseResultDTO<PLIPlanningDTO>,
    filter: new PLIPlanningFilter(),
    columnsVisibility: new PLIColumnsVisibility(),
    uncommittedPLIs: [],
    isLoading: false,
};

export function reducer(state = initialState, action: PLIPlanningActions): PLIPlanningState {
    switch (action.type) {
        case PLIPlanningActionTypes.SaveWeekPlanRequest:
        case PLIPlanningActionTypes.PLIPlanningDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case PLIPlanningActionTypes.PLIDataRequestSuccess:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case PLIPlanningActionTypes.PLIDataRequestError:
            return {
                ...state,
                isLoading: false,
            };
        case PLIPlanningActionTypes.PLIPlanningDataUpdate:
            return {
                ...state,
                isLoading: false,
                data: {
                    items: action.payload,
                    totalCount: state.data.totalCount
                }
            };
        case PLIPlanningActionTypes.FilterUpdate:
            return {
                ...state,
                filter: { ...action.payload },
                isLoading: false,
            };
        case PLIPlanningActionTypes.UncommittedPLIsUpdate:
            return {
                ...state,
                uncommittedPLIs: [...action.payload],
                isLoading: false,
            };
        case PLIPlanningActionTypes.SaveWeekPlanRequestSuccess:
            const newDataItems = state.data.items.map((x) => {
                const uncommitted = find(action.payload, { finalPunchItem: x.finalPunchItem });
                return uncommitted || x;
            });
            return {
                ...state,
                data: {
                    items: newDataItems,
                    totalCount: state.data.totalCount,
                },
                isLoading: false,
            };
        case PLIPlanningActionTypes.SaveWeekPlanRequestError:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}
