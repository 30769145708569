import { LoopStatusState, LoopStatusFilter, LoopStatusDataPagination } from './model';
import { LoopStatusActions, LoopStatusActionTypes } from './actions';
import { Constants } from 'src/app/constants';
import * as moment from 'moment';
import { act } from '@ngrx/effects';
import * as _ from "lodash";
import { Config } from 'src/app/models/config';
import { constant } from 'lodash';

import { dateNow } from '@microsoft/applicationinsights-core-js';
import { dateTimeUtilsNow } from '@microsoft/applicationinsights-common';
import { Action } from 'rxjs/internal/scheduler/Action';

const initialState: LoopStatusState = {
    dataPagination: new LoopStatusDataPagination(),
    isLoading: false,
    filter: new LoopStatusFilter(),
};

export function reducer(state: LoopStatusState = initialState, action: LoopStatusActions): LoopStatusState {
    switch (action.type) {
        case LoopStatusActionTypes.LoopStatusExportToExcelRequest:
        case LoopStatusActionTypes.LoopStatusAddBulkCommentRequest:
        case LoopStatusActionTypes.LoopStatusAddCommentRequest:
        case LoopStatusActionTypes.LoopStatusFilterRequest:
        case LoopStatusActionTypes.LoopStatusUpdateRequest: {
            return {
                ...state,
                isLoading: true
 
                
            };
        }
        case LoopStatusActionTypes.LoopStatusUpdateSuccess: {
            var elementIndex = state.dataPagination.items.findIndex((s) => s.loop === action.payload.loopName 
                                        && s.tagNumber === action.payload.tagNumber 
                                        && s.tagType === action.payload.tagType);
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        let filter = _.cloneDeep(action.payload.loopfolderRequest)
                        if (i === elementIndex) {                         
                         
                         filter = action.payload.value == Constants.Config.complete || 
                                  action.payload.value == Constants.Config.HardCopy ||
                                  action.payload.value == Constants.Config.SoftCopy ||
                                  action.payload.value== Constants.Config.IssueToExecution 
                        //  || ((action.payload.activiyStartDate != null && (action.payload.activiyStartDate) as any <= currentDate)
                        //   && action.payload.mcActual == null) 
                          ? Constants.Config.Requested :
                        //  ((action.payload.activiyStartDate!= null && (action.payload.activiyStartDate) as any <= currentDate)
                        //   && action.payload.mcActual!=null) ? Constants.Config.Late :
                           filter;

                            const item = { ...d,['loopFolderRequest']: filter,
                                 [action.payload.objectKey]: action.payload.value };
                            switch (action.payload.updateKey) {
                                case Constants.Config.loopFolderStatusKey:
                                    return {
                                        ...item,
                                        folderStatusDate: moment(),
                                    };
                                case Constants.Config.loopFolderAssignmentKey:
                                    return {
                                        ...item,
                                        folderAssignmentDate:
                                            action.payload.default === action.payload.value ? null : moment(),
                                    };
                                case Constants.Config.engineerStatusKey:
                                    return {
                                        ...item,
                                        engineerStatusDate:
                                            action.payload.default === action.payload.value ? null : moment(),
                                    };
                                default:
                                    return item;
                            }
                        }
                        return d;
                    }),
                },
            };
        }
        case LoopStatusActionTypes.LoopStatusExportToExcelError:
        case LoopStatusActionTypes.LoopStatusExportToExcelSuccess:
        case LoopStatusActionTypes.LoopStatusAddBulkCommentError:
        case LoopStatusActionTypes.LoopStatusAddCommentError:
        case LoopStatusActionTypes.LoopStatusFilterError:
        case LoopStatusActionTypes.LoopStatusUpdateError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LoopStatusActionTypes.LoopStatusFilterSuccess: {
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        }
        case LoopStatusActionTypes.LoopStatusAddCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex(
                (i) =>
                    i.loop === action.payload.loopName &&
                    i.tagNumber === action.payload.tagNumber &&
                    i.tagType === action.payload.tagType
            );

            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                comment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case LoopStatusActionTypes.LoopStatusAddBulkCommentSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((item) => ({
                        ...item,
                        comment: action.payload.description,
                        mentions: action.payload.mentions,
                    })),
                },
            };
        }
        case LoopStatusActionTypes.LoopStatusRemoveCommentSuccess:{
            const itemIndex = state.dataPagination.items.findIndex(
                (i) =>
                    i.loop === action.payload.loopName &&
                    i.tagNumber === action.payload.tagNumber &&
                    i.tagType === action.payload.tagType
            );
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                comment: action.payload.lastComment.description,
                                mentions: action.payload.lastComment.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case LoopStatusActionTypes.LoopStatusFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(action.payload.value)
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case LoopStatusActionTypes.LoopStatusFilterReset: {
            const newFilter = new LoopStatusFilter();
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        default: {
            return state;
        }
    }
}
