<div class="validator-performance-scoreboard-container">
    <h4>validation stars for the week</h4>
    <div class="subtitle-container">
        <div class="options-container">
            <mat-form-field appearance="outline">
                <input matInput [formControl]="dateRangePickerControlFormatted" />
                <input
                    matInput
                    [matDatepicker]="dateRangePicker"
                    [matDatepickerFilter]="dateRangePickerFilter"
                    [formControl]="dateRangePickerControl"
                    hidden
                    [max]="rangeDateFilter"
                />
                <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                <mat-datepicker #dateRangePicker disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="chart-container">
        <ngx-charts-advanced-pie-chart
            [view]="view"
            [scheme]="colorScheme"
            [results]="validatorPerformanceScoreboardData"
            [gradient]="gradient"
            [label]="'Total completed validations'"
        >
        </ngx-charts-advanced-pie-chart>
    </div>
    <div *ngIf="isLoading$ | async" class="validator-performance-scoreboard-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
