import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { withLatestFrom, mergeMap, catchError, map } from 'rxjs/operators';
import { MilestoneDashboardService } from 'src/app/services/api/webapi-services/reports/milestone-dashboard.service';
import { PlanningService } from 'src/app/services/api/webapi-services/reports/planning.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from '../model';
import { MilestoneDashboardType } from '../reports/milestone-dashboard/model';
import { HomeDataActionTypes, HomeSystemCompletionDataRequestError, HomeSystemCompletionDataRequestSuccess, HomeNPWDataRequestError, HomeNPWDataRequestSuccess } from './actions';

@Injectable()
export class HomeEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private milestoneDashboardService: MilestoneDashboardService,
        private toastService: ToastService,
        private planningService: PlanningService
    ) {}

    

    @Effect()
    totalSystemCompletionCompleteRequest$ = this.actions$.pipe(
        ofType(HomeDataActionTypes.HomeSystemCompletionDataRequest),
        withLatestFrom(
            this.store.select((store) => store.homeState.filterSystemCompletion),
        ),
        mergeMap(([action, milestoneDashboardFilter]) => {
            return this.milestoneDashboardService
                .filterTotalCompleteData(milestoneDashboardFilter, MilestoneDashboardType.AreaAll)
                .pipe(
                    map((data) => new HomeSystemCompletionDataRequestSuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while getting System Completion Data. Please contact Program Administrator.`
                        );
                        return of(new HomeSystemCompletionDataRequestError(error));
                    })
                );
        })
    );

    @Effect()
    totalNPWCompleteRequest$ = this.actions$.pipe(
        ofType(HomeDataActionTypes.HomeNPWDataRequest),
        withLatestFrom(
            this.store.select((store) => store.homeState.filterNPW),
        ),
        mergeMap(([action, milestoneDashboardFilter]) => {
            return this.milestoneDashboardService
                .filterTotalCompleteData(milestoneDashboardFilter, MilestoneDashboardType.NPW)
                .pipe(
                    map((data) => new HomeNPWDataRequestSuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while getting NPW Data. Please contact Program Administrator.`
                        );
                        return of(new HomeNPWDataRequestError(error));
                    })
                );
        })
    );
}
