import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { map, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ExceptionScope } from 'src/app/enums';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Contractor, Gooc, RFO, TCOUser } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    HealthOverdueExceptionsChartRequest,
    HealthOverdueExceptionsExportToExcelRequest,
    HealthOverdueExceptionsFilterPropertyUpdate,
    HealthOverdueExceptionsFilterReset,
    HealthOverdueExceptionsFilterStateUpdate,
    HealthOverdueExceptionsTableRequest,
} from 'src/app/store/reports/health/actions';
import { OverdueExceptionsFilters } from 'src/app/store/reports/health/model';

@Component({
    selector: 'app-overdue-exceptions-filter',
    templateUrl: './overdue-exceptions-filter.component.html',
    styleUrls: ['./overdue-exceptions-filter.component.scss'],
})
export class OverdueExceptionsFilterComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    disciplines: any[] = [];
    sysOwners: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    exceptionScopes = ExceptionScope;
    areaCompletionLeads: TCOUser[] = [];

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    overdueExceptionsFilter$ = this.store.select((state) => state.healthState.overdueExceptions.filter);

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private toastService: ToastService,
        private store: Store<ApplicationState>
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new OverdueExceptionsFilters());
    }

    ngOnInit(): void {
        this.filterExpansionPanel.expanded = true;

        this.subscribeToFormChanges();
        this.fetchPopulateDisciplines();
        this.fetchPopulateSysOwners();
        this.fetchPopulateScManagers();
        this.fetchPopulateACLs();

        this.overdueExceptionsFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
        });
    }

    subscribeToFormChanges = () => {
        this.filterForm.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
            const newFilter = value as OverdueExceptionsFilters;
            this.store.dispatch(new HealthOverdueExceptionsFilterStateUpdate(newFilter));
        });
    };

    fetchPopulateDisciplines = () => {
        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: any[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines. Please contact Program Administrator.'
                    );
                }
            );
    };

    fetchPopulateSysOwners = () => {
        this.lookupService
            .getSysOwners([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (sysOwners: any[]) => (this.sysOwners = sysOwners),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting system owners. Please contact Program Administrator.'
                    );
                }
            );
    };

    fetchPopulateScManagers = () => {
        this.lookupService
            .getSCManagers([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (scManagers: any[]) => (this.scManagers = scManagers),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting SC Managers. Please contact Program Administrator.'
                    );
                }
            );
    };

    fetchPopulateACLs = () => {
        this.lookupService
        .getACLeads([])
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting Area Completion Leads. Please contact Program Administrator.'
                    );
                }
            );
    };

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getContractorsData = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, [])
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    displayMultipleByName(values: (Gooc | RFO | Contractor)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    clearFilterProperty(propertyName: string) {
        let value: any;

        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            value = [];
        } else if (typeof this.filterForm.controls[propertyName].value === 'object') {
            value = null;
        } else {
            value = '';
        }

        this.filterForm.controls[propertyName].setValue(value);
    }

    onScopeClosed(isOpen: boolean) {
        if (!isOpen) {
            this.search();
        }        
    }

    exportToExcel() {
        this.store.dispatch(new HealthOverdueExceptionsExportToExcelRequest());
    }

    resetFilters() {
        this.store.dispatch(new HealthOverdueExceptionsFilterReset());
    }

    search() {
        this.store.dispatch(
            new HealthOverdueExceptionsFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new HealthOverdueExceptionsChartRequest());
        this.store.dispatch(new HealthOverdueExceptionsTableRequest());
    }
}
