import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

export interface IValidators {
    getValidators(fieldName: string);
}

export class UniqueProjectTeamNameValidator implements Validator {
    constructor(private projectTeamNames: string[]) {}

    validate = (control: AbstractControl): ValidationErrors => {
        return this.projectTeamNames.some((pt) => pt.toLowerCase() === control.value.toLowerCase())
            ? { uniqueProjectTeamName: true }
            : null;
    };

    registerOnValidatorChange?(fn: () => void): void {
        throw new Error('Method not implemented.');
    }
}
