import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-confirmation-popup',
    templateUrl: './delete-confirmation-popup.component.html',
    styleUrls: ['./delete-confirmation-popup.component.scss'],
})
export class DeleteConfirmationPopupComponent extends BaseComponent implements OnInit, OnDestroy {
    action: (value: boolean) => void;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DeleteConfirmationPopupComponent>
    ) {
        super();

        this.action = data.action;
    }

    ngOnInit(): void {
        // This is intentional
    }

    close() {
        this.dialogRef.close();
    }

    onConfirmation() {
        this.action(true);
        this.dialogRef.close();
    }
}
