import { Component, Inject } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ChangeManagementService } from 'src/app/services/api/webapi-services/change-management.service';
import { SignOffRequest } from 'src/app/models/sing-off-request';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
    selector: 'app-delete-sign-off-popup',
    templateUrl: './delete-sign-off-popup.component.html',
    styleUrls: ['./delete-sign-off-popup.component.scss'],
})
export class DeleteSignOffPopupComponent extends BaseComponent {
    loading: boolean;
    id: number;
    signOffId: number;
    signOffForm: UntypedFormGroup;
    contract: string;
    subsystemsArr: string[] = [];
    subsTodelete: string[] = [];
    constructor(
        public dialogRef: MatDialogRef<DeleteSignOffPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: ToastService,
        private changeManagementService: ChangeManagementService
    ) {
        super();
        this.id = data.id;
        this.contract = data.contract;
        this.subsystemsArr = [...data.subsystems.split(',')];
        this.subsystemsArr.unshift('All');
        this.signOffId = data.signOffId;
        this.signOffForm = new UntypedFormGroup({ subsystems: new UntypedFormControl('', Validators.required) });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    selectionChange(change: MatOptionSelectionChange) {
        if (change.source.value === 'All' && change.isUserInput) {
            if (change.source.selected) {
                this.signOffForm.controls['subsystems'].setValue(this.subsystemsArr);
            } else {
                this.signOffForm.controls['subsystems'].setValue([]);
            }
        } else if (change.isUserInput && !change.source.selected) {
            let sub = this.signOffForm.controls['subsystems'].value;
            if (sub && sub.indexOf('All') > -1) {
                let filteredVal = sub.filter((s: string) => s !== 'All' && s !== change.source.value);
                this.signOffForm.controls['subsystems'].setValue(filteredVal);
            }
        }
    }

    public onConfirm(): void {
        this.loading = true;
        let form = new SignOffRequest();
        form.id = this.id;
        form.contract = this.contract;
        form.subsystems = this.signOffForm.controls.subsystems.value.filter((s: string) => s !== 'All');
        form.signOffId = this.signOffId;
        this.changeManagementService.deleteSignOff(form).subscribe(
            (data) => {
                this.loading = false;
                this.toastService.Success('Field implementation signed off successfully deleted.');
                this.dialogRef.close({ changeDoc: data });
            },
            (result) => {
                this.loading = false;
                if (result.status === 400) {
                    this.toastService.Error(result.error);
                } else {
                    this.toastService.Error(
                        'Error occurred while deleting signing off of field implementation. Please contact Program Administrator.'
                    );
                }
            }
        );
    }
}
