<main class="main">
    <a class="navigator" (click)="goToPreviousPage()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">{{ scopeToString }} details page</div>
        <div class="buttons-container">
            <button *ngIf="showButtons" type="button" class="sct-button red" (click)="signOff(types.Rejected)">
                review complete - rejected
            </button>
            <button *ngIf="showButtons" type="button" class="sct-button orange" (click)="signOff(types.TBC)">
                review complete - TBC
            </button>
            <button *ngIf="showButtons" type="button" class="sct-button green" (click)="signOff(types.Endorsed)">
                review complete - endorsed
            </button>

            <button *ngIf="scope === subsystemScopes.ConstraintDetails" [disabled] ="!constraintDetailsForm.valid" (click)="save()"  type="button" class="sct-button sct-button-light">
                save
            </button>
            <button *ngIf="scope === subsystemScopes.ConstraintDetails"  [disabled] ="!constraintDetailsForm.valid || constraintStatus === 'Closed'" (click)="constraintSignOff()" type="button" class="sct-button sct-button-light">
                sign-off constraint
            </button>
        </div>
    </div>



    <div class="details-container" *ngIf="scope !== subsystemScopes.Redline; else redlineDetails">

        <ng-container *ngIf="scope === subsystemScopes.ConstraintDetails; else details">
            <form [formGroup]="constraintDetailsForm">
                
            <mat-form-field class="container300  margin-right20">
                <input matInput placeholder="Subsystem" [value]="data.subsystem" readonly />
            </mat-form-field>
            <mat-form-field class="container300  margin-right20">
                <input matInput placeholder="Subsystem Name" [value]="data.subsystemName" readonly />
            </mat-form-field>
            <mat-form-field class="container620  margin-right20">
                <input matInput placeholder="Tag Number" [value]="data.tagNumber" readonly />
            </mat-form-field>
            <mat-form-field class="container300  margin-right20">
                <input matInput placeholder="Tag Type" [value]="data.tagType" readonly />
            </mat-form-field>
            <mat-form-field class="container300  margin-right20">
                <input matInput placeholder="ITR Number" [value]="data.number" readonly />
            </mat-form-field>        
            <mat-form-field class="container620 margin-right20">
                <mat-label>ITR Description</mat-label>
                <textarea matInput [value]="data.description" cdkTextareaAutosize cdkAutosizeMinRows="1"
                    readonly></textarea>
            </mat-form-field>
            <mat-form-field class="container300 margin-right20">
                <input matInput placeholder="ITR Status" [value]="data.status" readonly />
            </mat-form-field>
            <mat-form-field class="container300 margin-right20">
                <input matInput placeholder="Exception Number" [value]="data.exceptionNumber" readonly />
            </mat-form-field>
            <mat-form-field class="container300  margin-right20">
                <input matInput placeholder="Discipline" [value]="data.discipline" readonly />
            </mat-form-field>
            <mat-form-field class="container300  margin-right20">
                <input matInput placeholder="Barcode" [value]="data.barcode" readonly />
            </mat-form-field>

            <mat-form-field class="container300  margin-right20">
                <input matInput placeholder="Tag Name" [value]="data.tagName" readonly />
            </mat-form-field>

            <mat-form-field  class="container300 margin-right20">
                <mat-label>Constraint Raised By Team</mat-label>
                <mat-select  formControlName="teamname">
                    <mat-option *ngFor="let type of constraintTeams" [value]="type">
                        {{ type.projectSubTeamName }}
                    </mat-option>
                </mat-select>
    
            </mat-form-field>
            <div class="margin-right20 dv-height"></div>
            <br> 
            <mat-form-field class="container300  margin-right20">
                <input matInput placeholder="Constraint Id" [value]="data.itrConstraintIDString" readonly />
            </mat-form-field>
            <mat-form-field class="container300  margin-right20">
                <input matInput placeholder="Constraint Status" [value]="constraintStatus" readonly />
            </mat-form-field>
            <mat-form-field class="container300 margin-right20">
                <mat-label>ITR Constraint Type Name</mat-label>
                <mat-select formControlName="constraintType">
                    <mat-option *ngFor="let type of constraintTypes" [value]="type" (click)="populateGroups(type.id)">
                        {{ type.name }}
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="constraintDetailsForm.controls.constraintType.errors?.required"
                    >Constraint Type is required.</mat-error
                >
            </mat-form-field>
            <mat-form-field appearance="standard" class="container300 margin-right20">
                <mat-label>Constraint Forecast Closure Date</mat-label>
                <input
                    matInput
                    formControlName="constraintForecastduedate"
                    [matDatepicker]="picker"
                    [value]="data.itrConstraintDueDate"  
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="container620 margin-right20">
                <mat-label>Constraint Description</mat-label>
                <textarea matInput  formControlName= "itrConstraintDescription" cdkTextareaAutosize cdkAutosizeMinRows="1"></textarea>
            </mat-form-field>

            <!-- <mat-form-field class="container300 margin-right20">
                <mat-label>Required for Milestone/Waypoint</mat-label>
                <mat-select matInput formControlName="waypointRequired">
                    <mat-option value=""></mat-option>
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field> -->

            <mat-form-field class="container300 margin-right20">
                <mat-label>FMR or PO</mat-label>
                <textarea matInput formControlName="fmRorPO" cdkTextareaAutosize cdkAutosizeMinRows="1"></textarea>
            </mat-form-field>

                <mat-form-field appearance="standard" class="container300 margin-right20">
                    <mat-label>FMR or PO ETA Date</mat-label>
                    <input
                        matInput
                        formControlName="fmRorPOETADate"
                        [matDatepicker]="FMRpicker"
                    />
                    <mat-datepicker-toggle matSuffix [for]="FMRpicker">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #FMRpicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="standard" class="container300 margin-right20">
                    <mat-label>ITR Constraint Raised Date</mat-label>
                    <input
                        matInput
                        formControlName="raisedDate"
                        [matDatepicker]="dpicker"
                    />
                    <mat-datepicker-toggle matSuffix [for]="dpicker">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #dpicker></mat-datepicker>
                </mat-form-field>
        
            <app-multiple-items-selector
            class="container620 margin-right20"
            [setInput]=""
            [searchFunc]="searchChanges"
            [isAsync]="true"
            [isSelectAll]="false"
            [oneItemMode]="true"
            formControlName="associatedNPW"
            [propertyToShow]="'numberedName'"
            [placeholder]="'Associated NPW'"
            [formSetter]="'associatedNPW'"
            [filterForm]="constraintDetailsForm"
            [panelWidth]="480"
            [isTableAutoComplete]="false"
            [displayedColumns]="changeAutocompleteDisplayedColumns"
            >
            </app-multiple-items-selector>
         
            <!-- <app-multiple-items-selector
            class="container620 margin-right20"
            [setInput]="setUsersInput"
            [searchFunc]="searchUsers"
            [isAsync]="true"
            [isSelectAll]="false"
            [oneItemMode]="true"
            formControlName="username"
            [propertyToShow]="'name'"
            [placeholder]="'ITR Constraint Responsible'"
            [formSetter]="'username'"
            [filterForm]="constraintDetailsForm"
            (autocompleteClosed)="onUsersClosed()"
            [isTableAutoComplete]="true"
            [displayedColumns]="usersAutocompleteDisplayedColumns"
            [panelWidth]="480"
            [required]="true"
            [errorMessage]='"Responsible User is required."'
            [invalid]="constraintDetailsForm.controls.username.errors?.required"
            >   
            </app-multiple-items-selector>  -->
            <app-multiple-items-selector
            class="container620 margin-right20"
            [setInput]="setResponsibleGroupInput"
            [searchFunc]="searchITRConstraintResponsibleGroup"
            [isAsync]="true"
            [isSelectAll]="false"
            [oneItemMode]="true"
            formControlName="responsiblepersongroup"
            [propertyToShow]="'name'"
            [placeholder]="'ITR Constraint Responsible Group'"
            [formSetter]="'responsiblepersongroup'"
            [filterForm]="constraintDetailsForm"
            (autocompleteClosed)="onUsersClosed()"
            [isTableAutoComplete]="true"
            [displayedColumns]="usersAutocompleteDisplayedColumnsWithoutEmail"
            [panelWidth]="480"
            [required]="true"
            [errorMessage]='"Responsible Group is required."'
            [invalid]="constraintDetailsForm.controls.responsiblepersongroup.errors?.required"
            >   
            </app-multiple-items-selector> 
            <app-multiple-items-selector
        class="container620 margin-right20"
        [setInput]="setUsersInput"
        [searchFunc]="searchUsers"
        [isAsync]="true"
        [isSelectAll]="false"
        [oneItemMode]="true"
        formControlName="additionalUsername"
        [propertyToShow]="'name'"
        [placeholder]="'Additional ITR Constraint Responsible'"
        [formSetter]="'additionalUsername'"
        [filterForm]="constraintDetailsForm"
        (autocompleteClosed)="onUsersClosed()"
        [isTableAutoComplete]="true"
        [displayedColumns]="usersAutocompleteDisplayedColumns"
        >
        </app-multiple-items-selector>  
            <app-multiple-items-selector
        class="container620 margin-right20"
        [setInput]="setUsersInput"
        [searchFunc]="searchUsers"
        [isAsync]="true"
        [isSelectAll]="false"
        [oneItemMode]="true"
        formControlName="itrConstraintRaisedBy"
        [propertyToShow]="'name'"
        [placeholder]="'ITR Constraint Raised by'"
        [formSetter]="'itrConstraintRaisedBy'"
        [filterForm]="constraintDetailsForm"
        (autocompleteClosed)="onUsersClosed()"
        [isTableAutoComplete]="true"
        [displayedColumns]="usersAutocompleteDisplayedColumns"
        [panelWidth]="480"
        [required]="true"
        [errorMessage]="'Raised By is required.'"
        [invalid]="constraintDetailsForm.controls.itrConstraintRaisedBy.errors?.required"
        >
         </app-multiple-items-selector>
           <!-- <app-multiple-items-selector
            class="container620 margin-right20"
            [setInput]="setTagInput"
            formControlName="tagNo"
            [searchFunc]="getTags"
            [isAsync]="true"
            placeholder="Tag No"
            [formSetter]="'tagNo'"
            [filterForm]="constraintDetailsForm"
            (autocompleteClosed)="onUsersClosed()"
            [displayedColumns]="tagsAutocompleteDisplayedColumns"
            [propertyToShow]="'tagNo'"
            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
            [panelWidth]="480"
            >
        </app-multiple-items-selector> -->
            <app-multiple-items-selector
                class="container620 margin-right20"
                [setInput]="setBarcodeInput"
                formControlName="barcodeNo"
                [searchFunc]="getBarcodes"
                [isAsync]="false"
                placeholder="ITR Barcode"
                [formSetter]="'barcodeNo'"
                [filterForm]="constraintDetailsForm"
                (autocompleteClosed)="onUsersClosed()"
                [displayedColumns]="barcodesAutocompleteDisplayedColumns"
                [propertyToShow]="'tagNo'"
                [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                [required]="true"
                >
        </app-multiple-items-selector>
        <mat-error *ngIf="constraintDetailsForm.controls.barcodeNo.errors?.required"
                >ITR Barcode is required.</mat-error>
        </form>
        </ng-container>
        <div class="sign-off" *ngIf="data.reviewStatus && data.reviewStatus.length > 0">
            <div *ngFor="let rs of data.reviewStatus">
                <span>
                    D Punch List Item {{ getType(rs.type) }}. Review completed by <b>{{ rs.user }}</b> on
                    <b>{{ rs.signOffDate | date: 'MMMM dd yyyy, h:mm:ss a' }}</b> for
                    <b>{{ data.number }}</b>
                    <mat-icon class="hover-pointer delete-icon" *ngIf="isAdmin" svgIcon="del-icon"
                        (click)="deleteSignOff(rs.id)"></mat-icon>
                </span>
            </div>
        </div>
    </div>

    <ng-template #details>
        <mat-form-field class="container300">
            <input matInput placeholder="Subsystem" [value]="data.subsystem" readonly />
        </mat-form-field>
        <mat-form-field class="container300">
            <input matInput placeholder="Subsystem Name" [value]="data.subsystemName" readonly />
        </mat-form-field>
        <mat-form-field class="container300">
            <input matInput placeholder="Tag Number" [value]="data.tagNumber" readonly />
        </mat-form-field>
        <mat-form-field *ngIf="scope !== subsystemScopes.COW" class="container300">
            <input matInput placeholder="Tag Type" [value]="data.tagType" readonly />
        </mat-form-field>
        <mat-form-field class="container300">
            <input matInput placeholder="Number" [value]="data.number" readonly />
        </mat-form-field>
        <mat-form-field class="container620">
            <mat-label>Description</mat-label>
            <textarea matInput [value]="data.description" cdkTextareaAutosize cdkAutosizeMinRows="1"
                readonly></textarea>
        </mat-form-field>
        <mat-form-field *ngIf="scope === subsystemScopes.COW" class="container300">
            <input matInput placeholder="COW Doc Number" [value]="data.cowDocNumber" readonly />
        </mat-form-field>
        <mat-form-field *ngIf="scope !== subsystemScopes.ConstraintDetails" class="container300">
            <input matInput placeholder="Contractor" [value]="data.contractor" readonly />
        </mat-form-field>
        <mat-form-field class="container300 margin-right20">
            <input matInput placeholder="Status" [value]="data.status" readonly />
        </mat-form-field>
        <mat-form-field *ngIf="scope !== subsystemScopes.COW" class="container620 margin-right20">
            <input matInput placeholder="Exception Number" [value]="data.exceptionNumber" readonly />
        </mat-form-field>
        <mat-form-field class="container300 margin-right20">
            <input matInput placeholder="Discipline" [value]="data.discipline" readonly />
        </mat-form-field>
        <mat-form-field
            *ngIf="scope === subsystemScopes.AQVD || scope === subsystemScopes.BITR || scope === subsystemScopes.CITR"
            class="container300 margin-right20">
            <input matInput placeholder="Barcode" [value]="data.barcode" readonly />
        </mat-form-field>
        <mat-form-field
            *ngIf="scope === subsystemScopes.AQVD || scope === subsystemScopes.BITR || scope === subsystemScopes.CITR || scope === subsystemScopes.PLI"
            class="container300 margin-right20">
            <input matInput placeholder="Work Order Number" [value]="data.workOrderNum" readonly />
        </mat-form-field>
        <mat-form-field
        *ngIf="scope === subsystemScopes.BITR || scope === subsystemScopes.CITR"
        class="container300 cursor-pointer" (click)="goToITRConstraints($event,data)">
        <input matInput placeholder="Constraint" [value]="data.itrConstraintCount" class="cursor-pointer" readonly />
    </mat-form-field>
        <div *ngIf="scope === subsystemScopes.PLI || scope === subsystemScopes.COW">
            <mat-form-field class="container300 margin-right20">
                <input matInput placeholder="Category" [value]="data.category" readonly />
            </mat-form-field>
            <mat-form-field class="container300 margin-right20">
                <input matInput placeholder="Action By" [value]="data.actionBy" readonly />
            </mat-form-field>
            <mat-form-field class="container300 margin-right20" *ngIf="scope === subsystemScopes.PLI">
                <input matInput placeholder="Punch Type" [value]="data.punchType" readonly />
            </mat-form-field>
            <mat-form-field class="container300 margin-right20" *ngIf="scope === subsystemScopes.PLI">
                <input matInput placeholder="Material Review Status" [value]="data.materialReviewStatus" readonly />
            </mat-form-field>
            <mat-form-field class="container300 margin-right20" *ngIf="scope === subsystemScopes.PLI">
                <input matInput placeholder="Material Review AssignedTo" [value]="data.materialReviewAssignedTo" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Raised By" [value]="data.raisedBy" readonly />
            </mat-form-field>
        </div>
    </ng-template>
    <ng-template #redlineDetails>
        <div class="details-container">
            <mat-form-field class="container300">
                <input matInput placeholder="Subsystem" [value]="data.subsystem" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Document No" [value]="data.number" readonly />
            </mat-form-field>
            <mat-form-field class="container620">
                <textarea class="small-initial-height" matInput [value]="data.description" placeholder="Title"
                    matTextareaAutosize matAutosizeMinRows="4" readonly></textarea>
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Revision" [value]="data.revision" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Contractor" [value]="data.contractor" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Discipline" [value]="data.discipline" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Document Type" [value]="data.docType" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Redline Type" [value]="data.redType" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Project Team" [value]="data.projectTeamName" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Redline Required Type" [value]="data.redReqType" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Coreworx Link" [value]="data.coreworxLink" (click)="goToCoreworx()"
                    class="coreworx-link" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Document Group" [value]="data.docGroup" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Area" [value]="data.area" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Unit" [value]="data.unit" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Revision Type" [value]="data.revisionType" readonly />
            </mat-form-field>
            <mat-form-field class="container620">
                <textarea class="small-initial-height" matInput [value]="data.cwp" placeholder="CWP" matTextareaAutosize
                    matAutosizeMinRows="4" readonly></textarea>
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="MOC" [value]="data.moc" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="MCDR Constr Redline Category" [value]="data.mcdrConstrRedCat" readonly />
            </mat-form-field>
            <mat-form-field class="container300 margin-right20">
                <input matInput placeholder="Redline Status" [value]="data.redStatus" readonly />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="Redline Date" [value]="data.redDate | date: 'd/MMM/y'" readonly />
            </mat-form-field>
        </div>
    </ng-template>
   
    <ng-container *ngIf="isConstraintSignedOff || constraintStatus === 'Closed'"> 
        <div class="sign-off">
            <span>
                Constraint Closure confirmed  by <b>{{ this.closedByUser }}</b> on
                <b>{{ signOffDate }}</b> for
                <b>{{ data.barcode }}</b>
                <mat-icon class="hover-pointer delete-icon" *ngIf="isAdminandPerformanceEnginnerAccess()" svgIcon="del-icon"
                    (click)="revertConstraintSignOff()"></mat-icon>
            </span>
        </div>          
        <br>
       </ng-container>
    <div *ngIf="scope === subsystemScopes.PLI || scope === subsystemScopes.COW || scope === subsystemScopes.ConstraintDetails" class="attachments-container">
        <div class="attachments-header">{{ scopeToString }} attachments</div>
        <div *ngIf="!isReadOnly" appDragAndDrop (filesAdded)="fileChangeEvent($event)" class="drag-and-drop-container">
            <div class="DandD-img"></div>
            <div class="drag-and-drop">
                Drop files here or click to
                <button class="upload-file-button" type="button" (click)="attachmentFiles.click($event)">
                    select files to upload
                </button>
                <div class="tooltip-icon" matTooltip="Maximum file upload limit is 100 MB at a time"
                    matTooltipClass="cofftooltip"></div>
                <input multiple #attachmentFiles type="file" (click)="attachmentFiles.value = null"
                    (change)="fileChangeEvent($event.target.files)" />
            </div>
        </div>
        <div class="new-attachments-container" *ngIf="newAttachments.length">
            <div class="new-attachment" *ngFor="let newAttachment of newAttachments; let i = index">
                <button type="button" class="remove-new-attachment" (click)="removeNewAttachment(i)"
                    [disabled]="isUploadInProgress"></button>
                <label>{{ newAttachment.name }}</label>
            </div>
            <div class="new-attachments-buttons">
                <div class="lds-hourglass margin-right20" *ngIf="isUploadInProgress"></div>
                <button type="button" class="sct-button sct-button-light margin-right20"
                    (click)="uploadNewAttachments()" [disabled]="isUploadInProgress">
                    upload
                </button>
                <button type="button" class="sct-button sct-button-white" (click)="clearNewAttachments()"
                    [disabled]="isUploadInProgress">
                    clear all
                </button>
            </div>
        </div>
        <div class="uploaded-attachments-container" *ngIf="data.attachments && data.attachments.length">
            <div class="uploaded-attachment" *ngFor="let uploadedAttachment of data.attachments; let i = index">
                <label>{{ uploadedAttachment.name }}</label>
                <div class="uploaded-attachment-buttons">
                    <div class="lds-hourglass margin-right20"
                        *ngIf="uploadedAttachmentsActionInProgress.getValue(i) || false"></div>
                    <button type="button" class="download-uploaded-attachment" (click)="downloadUploadedAttachment(i)"
                        [disabled]="anyActionPending()"></button>
                    <button *ngIf="!isReadOnly" type="button" class="delete-uploaded-attachment"
                        (click)="deleteUploadedAttachment(i)" [disabled]="anyActionPending()"></button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="scope === subsystemScopes.PLI" class="comments-container">
     <div class="sub-title">Material Status</div>
     <br>
        <div class="scroll">
            <table mat-table matSort [dataSource]="materialDetails">
                <ng-container matColumnDef="abbreviated">
                    <th app-col-resize mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Abbreviated description</th>
                    <td mat-cell *matCellDef="let element">
                       {{element.abbreviated}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="itemCode">
                    <th app-col-resize mat-header-cell mat-sort-header disableClear *matHeaderCellDef class="center">Item Code</th>
                    <td mat-cell *matCellDef="let element">
                       {{element.itemCode}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="size">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">Size</th>
                    <td mat-cell *matCellDef="let element">{{ element.size }}</td>
                </ng-container>
                <ng-container matColumnDef="qty">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">Qty</th>
                    <td mat-cell *matCellDef="let element">{{ element.qty }}</td>
                </ng-container>
                <ng-container matColumnDef="uom">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">UoM</th>
                    <td mat-cell *matCellDef="let element">{{ element.uom }}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">Status</th>
                    <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                </ng-container>
                <ng-container matColumnDef="quarantine">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">Quarantine</th>
                    <td mat-cell *matCellDef="let element">{{ element.quarantine }}</td>
                </ng-container>
                <ng-container matColumnDef="eta">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef mat-sort-header class="center">
                        ETA
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.eta | date: 'MMMM dd yyyy, h:mm:ss a' }}</td>
                </ng-container> 
                <ng-container matColumnDef="cwp">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">CWP</th>
                    <td mat-cell *matCellDef="let element">{{ element.cwp }}</td>
                </ng-container>              
                <tr mat-header-row *matHeaderRowDef="displayedMaterialsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedMaterialsColumns"></tr>
            </table>
        </div>
    </div>
    <div *ngIf="scope === subsystemScopes.ConstraintDetails" class="comments-container">
        <button *ngIf="!isReadOnly" type="button" class="sct-button sct-button-light"
            (click)="openConstraintCommentPopup($event)">
            Add Constraint comment
        </button>
        <div class="scroll">
            <table mat-table matSort [dataSource]="constraintComments">
                <ng-container matColumnDef="comment">
                    <th app-col-resize mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Comment</th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHTML]="element.description | formatMentions: element.mentions"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">User</th>
                    <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef mat-sort-header class="center">
                        Date
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.date | date: 'MMMM dd yyyy, h:mm:ss a' }}</td>
                </ng-container>
                <ng-container matColumnDef="delete">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon svgIcon="del-icon" (click)="removeConstraintComment(element)"></mat-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
    </div>
    <div class="comments-container">
        <button *ngIf="!isReadOnly" type="button" class="sct-button sct-button-light"
            (click)="openCommentPopup($event)">
           {{ scope === subsystemScopes.ConstraintDetails ? 'Add ITR comment'  :  'Add comment' }} 
        </button>
        <div class="scroll">
            <table mat-table matSort [dataSource]="comments">
                <ng-container matColumnDef="comment">
                    <th app-col-resize mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Comment</th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHTML]="element.description | formatMentions: element.mentions"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">User</th>
                    <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef mat-sort-header class="center">
                        Date
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.date | date: 'MMMM dd yyyy, h:mm:ss a' }}</td>
                </ng-container>
                <ng-container matColumnDef="delete">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon svgIcon="del-icon" (click)="removeComment(element)"></mat-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
    </div>



    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>