import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from 'src/app/app.config';
import {
    LeakTestPackTrackerSearchDTO,
    LeakTestPackTrackerSearchDataPagination,
    LeakTestPackTrackerSearchFilter,
} from 'src/app/store/leak-test-pack-tracker/model';
import { LeakTestPackPriority } from 'src/app/store/ltp-priority-history/model';

@Injectable({
    providedIn: 'root',
})
export class LeakTestPackTrackerSearchService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly leakTestPackTrackerSearchEndpoint: string = '/leaktestpacktrackersearch';
    constructor(private http: HttpClient) {}

    searchLeakTestPackTrackerService(filter: LeakTestPackTrackerSearchFilter) {
        return this.http.post<LeakTestPackTrackerSearchDataPagination>(
            `${this.rootUrl}${this.leakTestPackTrackerSearchEndpoint}`,
            filter
        );
    }

    generateExcel(filter: LeakTestPackTrackerSearchFilter) {
        return this.http.post(`${this.rootUrl}${this.leakTestPackTrackerSearchEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }
    updateLTPpriority(ltp: LeakTestPackTrackerSearchDTO) {
        return this.http.post(`${this.rootUrl}${this.leakTestPackTrackerSearchEndpoint}/updatePriority`, ltp);
    }

    getPriorityHistory(
        leakTestPackTrackerNo : string
    ) {
        return this.http.get<LeakTestPackPriority[]>(
            `${this.rootUrl}${this.leakTestPackTrackerSearchEndpoint}/getltppriorityhistory?leakTestPackNo=${leakTestPackTrackerNo}`,
            {}
        );
    }
}
