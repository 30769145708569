<main class="main">
    <form [formGroup]="filterForm">
        <div class="header-container">
            <div class="header-title">
                {{ headerText }}<span *ngIf="lockFilters || subsystemScopes.ConstraintDetails">: {{ subsystem }}{{ rfo }}</span>
                <span *ngIf="subsystemName"> - {{ this.subsystemName }} </span>                
            </div>
            <div class="header-buttons">
                <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20" *ngIf="blueScreen">
                    export to excel
                </button>
            </div>
        </div>
        <mat-accordion class="filter-container" *ngIf="!blueScreen">
            <mat-expansion-panel [ngClass]="{'hideme':!showFilterPanel}" [disabled]="lockFilters" #filterExpansionPanel="matExpansionPanel" >
                <mat-expansion-panel-header collapsedHeight="auto" >
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <div class="warning-text" *ngIf="scope === subsystemScopes.RFOWorkToGoSearch && !filterForm.controls.rfos?.value.length"> To view the data, apply the RFO filter first.</div>
                            <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('projectTeamNames')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcMilestones?.value.length" removable>
                                MC Milestone: {{ displayMultipleSelected(filterForm.controls.mcMilestones.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('mcMilestones')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>

                            <mat-basic-chip *ngIf="filterForm.controls.systems?.value.length" removable>
                                System: {{ displaySystemsSelected(filterForm.controls.systems.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('systems')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.subsystems?.value.length" removable>
                                Subsystem: {{ displayMultipleSelectedwithProperty(filterForm.controls.subsystems.value, 'id') }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('subsystems')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfos?.value.length" removable>
                                RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('rfos')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('contractors')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('disciplines')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.category?.value.length" removable>
                                Category: {{ filterForm.controls.category.value.join(', ') }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('category')">
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areas?.value.length" removable>
                                Areas: {{ filterForm.controls.areas.value.join(', ') }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('areas')">
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.units?.value.length" removable>
                                Units: {{ filterForm.controls.units.value.join(', ') }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('units')">
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.revTypes?.value.length" removable>
                                Revision Types: {{ filterForm.controls.revTypes.value.join(', ') }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('revTypes')">
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.docGroups?.value.length" removable>
                                Documnet Groups: {{ filterForm.controls.docGroups.value.join(', ') }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('docGroups')">
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.redReqTypes?.value.length" removable>
                                Redline Requiered Types: {{ filterForm.controls.redReqTypes.value.join(', ') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('redReqTypes')"
                                >
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.cwps?.value.length" removable>
                                CWPs: {{ displayMultipleSelectedById(filterForm.controls.cwps.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('cwps')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mocs?.value.length" removable>
                                MOCs: {{ filterForm.controls.mocs.value.join(', ') }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('mocs')">
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.columnRedDate?.value" removable>
                                Redline Dates: {{ filterForm.controls.columnRedDate.value.rangeDates | rangeDate }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('columnRedDate')"
                                >
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcdrConstrRedlineCats?.value.length" removable>
                                MCDR Constr Redline Categories:
                                {{ filterForm.controls.mcdrConstrRedlineCats.value.join(', ') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('mcdrConstrRedlineCats')"
                                >
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcCompleted">
                                MC Completed:
                                {{ displaySelectedMCCompleted(filterForm.controls.mcCompleted.value) }}
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="this.filterStatuses && this.filterStatuses.length > 0">
                                Status:
                                {{ this.filterStatuses.join(',') }}
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfsuCompleted">
                                RFSU Completed:
                                {{ displaySelectedRFSUCompleted(filterForm.controls.rfsuCompleted.value) }}
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfoType">
                                RFO Type:
                                {{ displaySelectedRFOType(filterForm.controls.rfoType.value) }}
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.prePostRFOCreatedItem">
                                Pre/Post RFO Created Item:
                                {{ displaySelectedPrePostRFOCreated(filterForm.controls.prePostRFOCreatedItem.value) }}
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.excludeExceptions?.value.length">
                                Exclude Exceptions:
                                {{ displayExcludeExceptions(filterForm.controls.excludeExceptions.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('excludeExceptions')"
                                >
                                    cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.constructionArea?.value.length" removable>
                                Construction Area:
                                {{ displayMultipleSelectedById(filterForm.controls.constructionArea.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('constructionArea')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown?.value.length" removable>
                                Area Breakdown:
                                {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('areaBreakdown')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors?.value.length" removable>
                                RFSU Contractor:
                                {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('rfsuContractors')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcEngineer?.value.length" removable>
                                MC Engineer: {{ displaySelectedMCEngineer(filterForm.controls.mcEngineer.value) }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('mcEngineer')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.scManager?.value.length" removable>
                                SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('scManager')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areaCompletionLead?.value.length" removable>
                                Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('areaCompletionLead')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.systemOwner?.value.length" removable>
                                System Owner: {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('systemOwner')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.stagedStartUpPriorities?.value.length" removable>
                                Priorities:
                                {{ displaySelectedPriorities(filterForm.controls.stagedStartUpPriorities.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('stagedStartUpPriorities')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.requiredForSteamblow">
                                Required for Steamblow:
                                {{ displaySelectedRequiredForSteamblow(filterForm.controls.requiredForSteamblow.value) }}
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.deliveryEngineer.value.length" removable>
                                Delivery Engineer: {{ displaySelectedDeliveryEngineer(filterForm.controls.deliveryEngineer.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('deliveryEngineer')">cancel</mat-icon>
                            </mat-basic-chip>
                            
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>

                <div *ngIf="scope === subsystemScopes.RFSUWorkToGoSearch">
                    <div class="warning-text">To view the data, apply the Subsystems filter first.</div>
                    <div class="container-multiselector">
                        <app-multiple-items-selector
                                class="multiselector margin-right20"
                                formControlName="subsystems"
                                [searchFunc]="getRFSUSubsystems"
                                [setInput]="setSubsystemInput"
                                [isAsync]="true"
                                placeholder="Subsystem"
                                [displayedColumns]="subsystem2AutocompleteDisplayedColumns"
                                [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                                [panelWidth]="480"
                            >
                        </app-multiple-items-selector>
                        
                        <app-multiple-items-selector
                            class="multiselector margin-right20"
                            [setInput]="setGoocInput"
                            formControlName="goocs"
                            [searchFunc]="getGoocs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'goocs'"
                            [propertyToShow]="'name'"
                            [placeholder]="'GOOC'"                        
                            [isTableAutoComplete]="true"
                            [displayedColumns]="goocAutocompleteDisplayedColumns"
                        >
                        </app-multiple-items-selector>

                        
                    </div>

                    <div class="mixedapp-container">
                        <mat-form-field class="container300 margin-right20 flexitem">
                            <mat-label>Project Team</mat-label>
                            <mat-select
                                formControlName="projectTeamNames"
                                multiple
                                (openedChange)="onProjectTeamsClosed($event)"
                            >
                                <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <app-multiple-items-selector
                                class="container300 margin-right20"
                                [setInput]="setContractorInput"
                                formControlName="contractors"
                                [searchFunc]="getContractorsMulti"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                (autocompleteClosed)="onContractorsClosed()"
                                (itemRemoved)="onContractorsClosed()"
                                [filterForm]="filterForm"
                                [formSetter]="'contractors'"
                                [propertyToShow]="'contractNo'"
                                [placeholder]="contractorLabel"
                                [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                                [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                        <mat-form-field class="container300 margin-right20 flexitem">
                            <mat-label>Discipline</mat-label>
                            <mat-select formControlName="disciplines" multiple>
                                <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <app-multiple-items-selector
                            class="container300 margin-right20"
                            [setInput]="setStagedStartUpPriorityInput"
                            formControlName="stagedStartUpPriorities"
                            [isAsync]="true"
                            [searchFunc]="getStagedStartUpPriorities"
                            [filterForm]="filterForm"
                            [formSetter]="'stagedStartUpPriorities'"
                            [propertyToShow]="'priorityName'"
                            [placeholder]="'Staged Start-up Priority'"
                            [secondPropertyToShow]="'priority'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['priority', 'priorityName']"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container-multiselector">                       

                        <app-multiple-items-selector
                            class="multiselector margin-right20"
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                        >
                        </app-multiple-items-selector>

                        <app-multiple-items-selector
                            class="multiselector margin-right20"
                            [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                        
                        <app-multiple-items-selector
                        class="multiselector margin-right20"
                        [setInput]="setWaypointInput"
                        formControlName="waypoints"
                        [searchFunc]="getWaypoints"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'waypoints'"
                        [propertyToShow]="'name'"
                        [placeholder]="'Waypoint'"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['name']"
                    >
                    </app-multiple-items-selector>

                    <app-multiple-items-selector
                           class="multiselector margin-right20"
                           [setInput]="setRFSUContractorsInput"
                           formControlName="rfsuContractors"
                           [searchFunc]="getRFSUContractors"
                           [isAsync]="true"
                           [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                           [filterForm]="filterForm"
                           [formSetter]="'rfsuContractors'"
                           [propertyToShow]="'contractNo'"
                           [placeholder]="'RFSU Contractor'"
                           [secondPropertyToShow]="'contract'"
                           [panelWidth]="480"
                       >
                    </app-multiple-items-selector>
                    </div>

                    <div class="mixedapp-container">

                        <mat-form-field class="container300 margin-right20 flexitem">
                            <mat-label>SC Execution Area Manager</mat-label>
                            <mat-select formControlName="scManager" multiple>
                                <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <mat-form-field class="container300 margin-right20 flexitem">
                            <mat-label>Area Completion Lead</mat-label>
                            <mat-select formControlName="areaCompletionLead" multiple>
                                <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <mat-form-field class="container300 margin-right20 flexitem">
                            <mat-label>System Owner</mat-label>
                            <mat-select formControlName="systemOwner" multiple>
                                <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!---->
                        <mat-form-field class="container300 margin-right20 flexitem" 
                        *ngIf="scope == subsystemScopes.RFSUWorkToGoSearch">
                            <mat-label>Delivery Engineer</mat-label>
                                    <mat-select formControlName="deliveryEngineer" multiple disableOptionCentering>
                                        <mat-option *ngFor="let de of deliveryEngineers" [value]="de.id"
                                            >{{ de.name }}
                                    </mat-option>
                                </mat-select>
                        </mat-form-field>


                    </div>
                    
                </div>

                <div class="mixedapp-container" *ngIf="scope !== subsystemScopes.RFOWorkToGoSearch && scope !== subsystemScopes.RFSUWorkToGoSearch">
                    <mat-form-field class="container300 margin-right20 flexitem">
                        <mat-label>Project Team</mat-label>
                        <mat-select
                            formControlName="projectTeamNames"
                            multiple
                            (openedChange)="onProjectTeamsClosed($event)"
                        >
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="container620-mh margin-right20">
                        <app-multiple-items-selector
                            class="container620"
                            [setInput]="setContractorInput"
                            formControlName="contractors"
                            [searchFunc]="getContractorsMulti"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            (autocompleteClosed)="onContractorsClosed()"
                            (itemRemoved)="onContractorsClosed()"
                            [filterForm]="filterForm"
                            [formSetter]="'contractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="contractorLabel"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <mat-form-field class="container300 margin-right20 flexitem">
                        <mat-label>{{ scope == subsystemScopes.PLI ? "PL Discipline" :  "Discipline"}}</mat-label>
                        <mat-select formControlName="disciplines" multiple>
                            <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    class="container-multiselector"
                    *ngIf="
                        scope === subsystemScopes.PLI ||
                        scope === subsystemScopes.AQVD ||
                        scope === subsystemScopes.COW ||
                        scope === subsystemScopes.EX
                    "
                >
                    <div class="margin-right20" *ngIf="!hideMCmileStone.includes(scope)">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setMcMilestoneInput"
                            formControlName="mcMilestones"
                            [searchFunc]="getMcMilestones"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="mcMilestoneAutocompleteDisplayedColumns"
                            [filterForm]="filterForm"
                            [formSetter]="'mcMilestones'"
                            [propertyToShow]="'name'"
                            [placeholder]="'MC Milestone'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div>
                        <app-multiple-items-selector
                            class="container300 margin-right20"
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container310 container-radiobuttons margin-right20" style ="padding-bottom: 10px;"
                        *ngIf="scope == subsystemScopes.PLI">
                        <mat-label class="title-radiobuttons">RFO Type</mat-label>
                        <mat-radio-group formControlName="rfoType" aria-label="RFO Type">
                            <mat-radio-button [value]="true" class="margin-right40">RFO</mat-radio-button>
                            <mat-radio-button [value]="false" class="margin-right40">Post RFO</mat-radio-button>
                            <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div
                    class="container-multiselector"
                    *ngIf="scope === subsystemScopes.BITR || scope === subsystemScopes.CITR || scope === subsystemScopes.BITRandCITR || scope === subsystemScopes.ConstraintDetails"
                >
                    <app-multiple-items-selector
                        class="multiselector margin-right20"
                        [setInput]="setRFOInput"
                        formControlName="rfos"
                        [searchFunc]="getRFOs"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'rfos'"
                        [propertyToShow]="'name'"
                        [placeholder]="'RFO'"
                        [secondPropertyToShow]="'rfoName'"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['name', 'rfoName']"
                    >
                    </app-multiple-items-selector>
                </div>
                

                <div class="container-multiselector" *ngIf="scope === subsystemScopes.RFOWorkToGoSearch">
                    <div class="warning-text">To view the data, apply the RFO filter first.</div>
                </div>
                
                <div class="container-multiselector" *ngIf="scope === subsystemScopes.RFOWorkToGoSearch">               
                    <mat-form-field class="multiselector margin-right20">
                        <mat-label>Project Team</mat-label>
                        <mat-select formControlName="projectTeamNames" multiple (openedChange)="onProjectTeamsClosed($event)">
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
            
                    <app-multiple-items-selector class="multiselector margin-right20" [setInput]="setRFOInput" formControlName="rfos"
                        [searchFunc]="getRFOs" [isAsync]="true" [filterForm]="filterForm" [formSetter]="'rfos'"
                        [propertyToShow]="'name'" [placeholder]="'RFO'" [secondPropertyToShow]="'rfoName'" [isTableAutoComplete]="true"
                        [displayedColumns]="['name', 'rfoName']">
                    </app-multiple-items-selector>              
                </div>
                
                <div class="container-multiselector" *ngIf="scope == subsystemScopes.RFOWorkToGoSearch">
                    <app-multiple-items-selector class="multiselector margin-right20" [setInput]="setRFSUContractorsInput"
                        formControlName="rfsuContractors" [searchFunc]="getRFSUContractors" [isAsync]="true"
                        [displayedColumns]="contractorsAutocompleteDisplayedColumns" [filterForm]="filterForm"
                        [formSetter]="'rfsuContractors'" [propertyToShow]="'contractNo'" [placeholder]="'RFSU Contractor'"
                        [secondPropertyToShow]="'contract'" [panelWidth]="480">
                    </app-multiple-items-selector>
                
                    <app-multiple-items-selector class="multiselector margin-right20" [setInput]="setAreaBreakdownInput"
                        formControlName="areaBreakdown" [searchFunc]="getAreaBreakdown" [isAsync]="true" [filterForm]="filterForm"
                        [formSetter]="'areaBreakdown'" [placeholder]="'Area Breakdown'">
                    </app-multiple-items-selector>

                    <mat-form-field class="multiselector margin-right20">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwner" multiple>
                            <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="container300 container-radiobuttons margin-right20" *ngIf="scope === subsystemScopes.RFOWorkToGoSearch">
                    <mat-label class="title-radiobuttons">Pre/Post RFO Created Items</mat-label>
                    <mat-radio-group formControlName="prePostRFOCreatedItem" aria-label="Pre/Post RFO Created Items">
                        <mat-radio-button [value]="true" class="margin-right40">Pre</mat-radio-button>
                        <mat-radio-button [value]="false" class="margin-right40">Post</mat-radio-button>
                        <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div *ngIf="scope === subsystemScopes.Redline">
                    <div class="mixedapp-container redline">
                        <mat-form-field class="container300 margin-right20">
                            <mat-label>Redline Type</mat-label>
                            <mat-select formControlName="redType" multiple>
                                <mat-option *ngFor="let rt of redTypes" [value]="rt">{{ rt }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <app-multiple-items-selector
                            class="container300 margin-right20"
                            [setInput]="setSubsystemInput"
                            formControlName="subsystems"
                            [searchFunc]="getSubsystems"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [filterForm]="filterForm"
                            [propertyToShow]="'value'"
                            [formSetter]="'subsystems'"
                            [placeholder]="'Subsystem'"
                            [panelWidth]="480"
                            [reloadOnOpen]="true"
                        >
                        </app-multiple-items-selector>

                        <mat-form-field class="container300 margin-right20 flexitem">
                            <mat-label>Document Group</mat-label>
                            <mat-select formControlName="docGroups" multiple>
                                <mat-option *ngFor="let dg of docGroups" [value]="dg">{{ dg }} </mat-option>
                            </mat-select>
                        </mat-form-field>                    
                        <mat-form-field class="container300 margin-right20">
                            <mat-label>Redline Status</mat-label>
                            <mat-select formControlName="statuses" multiple>
                                <mat-option *ngFor="let s of statuses" [value]="s">{{ s }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="container300-mh55 mixedapp-container redline">
                       
                        <mat-form-field *ngIf="scope !== subsystemScopes.Redline" class="container300 margin-right20 flexitem">
                            <mat-label>Area</mat-label>
                            <mat-select formControlName="areas" multiple>
                                <mat-option *ngFor="let a of areas" [value]="a">{{ a }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="scope !== subsystemScopes.Redline" class="container300 margin-right20 flexitem">
                            <mat-label>Unit</mat-label>
                            <mat-select formControlName="units" multiple>
                                <mat-option *ngFor="let u of units" [value]="u">{{ u }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <app-multiple-items-selector
                            class="container300 margin-right20"
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300-mh55 mixedapp-container redline">
                        <app-multiple-items-selector
                        *ngIf="scope !== subsystemScopes.Redline"
                            class="container300 margin-right20"
                            formControlName="cwps"
                            [searchFunc]="getCWPs"
                            [isAsync]="true"
                            placeholder="CWP"
                            [setInput]="setCWPInput"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                        <app-multiple-items-selector
                        *ngIf="scope !== subsystemScopes.Redline"
                            class="container300"
                            [setInput]="setSystemInput"
                            formControlName="systems"
                            [searchFunc]="getSystems"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="systemAutocompleteDisplayedColumns"
                            [filterForm]="filterForm"
                            [propertyToShow]="'no'"
                            [formSetter]="'systems'"
                            [placeholder]="'System'"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <mat-form-field
                    class="container300 margin-right20"
                    *ngIf="scope === subsystemScopes.PLI || scope === subsystemScopes.COW"
                >
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category" multiple>
                        <mat-option value="A">A</mat-option>
                        <mat-option value="B">B</mat-option>
                        <mat-option value="C">C</mat-option>
                        <mat-option value="D">D</mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    class="container300 container-radiobuttons margin-right20"
                    *ngIf="!hideMCcompleted.includes(scope)"
                >
                    <mat-label class="title-radiobuttons">MC Completed</mat-label>
                    <mat-radio-group formControlName="mcCompleted" aria-label="MC Completed">
                        <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                        <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                        <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div
                    class="container300 container-radiobuttons margin-right20"
                    *ngIf="scope !== subsystemScopes.Redline && scope !== subsystemScopes.RFSUWorkToGoSearch && scope !== subsystemScopes.RFOWorkToGoSearch"
                >
                    <mat-label class="title-radiobuttons">RFSU Completed</mat-label>
                    <mat-radio-group formControlName="rfsuCompleted" aria-label="RFSU Completed">
                        <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                        <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                        <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field
                    *ngIf="
                        scope !== subsystemScopes.EX &&
                        scope !== subsystemScopes.Redline &&
                        scope !== subsystemScopes.COW && 
                        scope !== subsystemScopes.RFSUWorkToGoSearch &&
                        scope !== subsystemScopes.RFOWorkToGoSearch
                    "
                    class="container300 margin-right20 flexitem"
                >
                    <mat-label>Exclude Exceptions</mat-label>
                    <mat-select formControlName="excludeExceptions" multiple [compareWith]="compareExceptionTypes">
                        <mat-option
                            *ngFor="
                                let ex of scope !== subsystemScopes.BITR && scope !== subsystemScopes.CITR && scope !== subsystemScopes.BITRandCITR && scope !== subsystemScopes.ConstraintDetails
                                    ? (exceptionTypes | enumKeyValuelist)
                                    : RFexceptionTypes
                            "
                            [value]="ex.key"
                            >{{ ex.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="scope === subsystemScopes.EX" class="container300 margin-right20 flexitem">
                    <mat-label>Exception Phase</mat-label>
                    <mat-select formControlName="exceptionPhases" multiple>
                        <mat-option value="Fabrication MC">Fabrication MC</mat-option>
                        <mat-option value="Mechanical Completion">Mechanical Completion</mat-option>
                        <mat-option value="Pre Startup Safety Review">Pre Startup Safety Review</mat-option>
                        <mat-option value="Ready for Operations">Ready for Operations</mat-option>
                        <mat-option value="Ready For Start Up">Ready For Start Up</mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    class="container300 margin-right20 flexitem"
                    *ngIf="
                       
                        scope !== subsystemScopes.BITR &&
                        scope !== subsystemScopes.BITRandCITR &&
                        scope !== subsystemScopes.ConstraintDetails &&
                        scope !== subsystemScopes.CITR &&
                        scope !== subsystemScopes.Redline &&
                        scope !== subsystemScopes.COW &&
                        scope !== subsystemScopes.RFSUWorkToGoSearch &&
                        scope !== subsystemScopes.RFOWorkToGoSearch
                     
                    "
                >
                    <app-multiple-items-selector
                        class="container300"
                        [setInput]="setConstructionAreaInput"
                        formControlName="constructionArea"
                        [searchFunc]="getConstructionArea"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'constructionArea'"
                        [placeholder]="'Construction Area'"
                    >
                    </app-multiple-items-selector>
                </div>
                <mat-form-field class="container300 margin-right20 flexitem" *ngIf="scope !== subsystemScopes.RFSUWorkToGoSearch && scope !== subsystemScopes.RFOWorkToGoSearch">
                    <mat-label>SC Execution Area Manager</mat-label>
                    <mat-select formControlName="scManager" multiple>
                        <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="container300 margin-right20 flexitem" *ngIf="scope !== subsystemScopes.RFSUWorkToGoSearch && scope !== subsystemScopes.RFOWorkToGoSearch">
                    <mat-label>Area Completion Lead</mat-label>
                    <mat-select formControlName="areaCompletionLead" multiple>
                        <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                    </mat-select>
                </mat-form-field>


                <div class="container300-mh margin-right20" *ngIf="scope !== subsystemScopes.RFSUWorkToGoSearch && scope !== subsystemScopes.RFOWorkToGoSearch">
                    <app-multiple-items-selector
                        class="container300"
                        [setInput]="setAreaBreakdownInput"
                        formControlName="areaBreakdown"
                        [searchFunc]="getAreaBreakdown"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'areaBreakdown'"
                        [placeholder]="'Area Breakdown'"
                    >
                    </app-multiple-items-selector>
                </div>             

                <mat-form-field
                    class="container300 margin-right20 flexitem"
                    *ngIf="scope !== subsystemScopes.AQVD && scope !== subsystemScopes.Redline && scope !== subsystemScopes.RFSUWorkToGoSearch && scope !== subsystemScopes.RFOWorkToGoSearch"
                >
                    <mat-label>System Owner</mat-label>
                    <mat-select formControlName="systemOwner" multiple>
                        <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    class="mixedapp-container"
                    *ngIf="
                        scope === subsystemScopes.AQVD ||
                        scope === subsystemScopes.BITR ||
                        scope === subsystemScopes.BITRandCITR ||
                        scope === subsystemScopes.ConstraintDetails ||
                        scope === subsystemScopes.CITR ||
                        scope === subsystemScopes.PLI ||
                        scope === subsystemScopes.COW ||
                        scope === subsystemScopes.EX ||
                        scope === subsystemScopes.Redline
                    "
                >

                    <app-multiple-items-selector
                        class="container300 margin-right20"
                        [setInput]="setStagedStartUpPriorityInput"
                        formControlName="stagedStartUpPriorities"
                        [isAsync]="true"
                        [searchFunc]="getStagedStartUpPriorities"
                        [filterForm]="filterForm"
                        [formSetter]="'stagedStartUpPriorities'"
                        [propertyToShow]="'priorityName'"
                        [placeholder]="'Staged Start-up Priority'"
                        [secondPropertyToShow]="'priority'"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['priority', 'priorityName']"
                    >
                    </app-multiple-items-selector>
                </div>
                <div
                    class="container300 container-radiobuttons margin-right20"
                    *ngIf="
                        scope === subsystemScopes.BITR ||
                        scope === subsystemScopes.CITR ||
                        scope === subsystemScopes.PLI"
                >
                    <mat-label class="title-radiobuttons">Required for Steamblow</mat-label>
                    <mat-radio-group formControlName="requiredForSteamblow" aria-label="Required for Steamblow">
                        <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                        <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                        <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field class="container300 margin-right20 flexitem" 
                    *ngIf="
                        scope === subsystemScopes.AQVD ||
                        scope === subsystemScopes.BITR ||
                        scope === subsystemScopes.CITR ||
                        scope === subsystemScopes.PLI ||
                        scope === subsystemScopes.EX
                    ">
                        <mat-label>Delivery Engineer</mat-label>
                                <mat-select formControlName="deliveryEngineer" multiple disableOptionCentering>
                                    <mat-option *ngFor="let de of deliveryEngineers" [value]="de.id"
                                        >{{ de.name }}
                                </mat-option>
                            </mat-select>
                </mat-form-field>
                <mat-form-field
                    class="container300 margin-right20 flexitem"
                    *ngIf="scope === subsystemScopes.EX"
                >
                    <mat-label>Overdue</mat-label>
                    <mat-select formControlName="overdue" multiple>
                        <mat-option *ngFor="let od of overDue" [value]="od">{{ od }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    class="container-multiselector"
                    *ngIf="
                        scope === subsystemScopes.AQVD ||
                        scope === subsystemScopes.BITR ||
                        scope === subsystemScopes.BITRandCITR ||
                        scope === subsystemScopes.ConstraintDetails ||
                        scope === subsystemScopes.CITR ||
                        scope === subsystemScopes.PLI ||
                        scope === subsystemScopes.EX ||
                        scope === subsystemScopes.COW ||
                        scope === subsystemScopes.Redline
                    "
                >
                    <app-multiple-items-selector
                        class="multiselector margin-right20"
                        [setInput]="setWaypointInput"
                        formControlName="waypoints"
                        [searchFunc]="getWaypoints"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'waypoints'"
                        [propertyToShow]="'name'"
                        [placeholder]="'Waypoint'"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['name']"
                    >
                    </app-multiple-items-selector>

                    <app-multiple-items-selector
                           class="multiselector margin-right20"
                           [setInput]="setRFSUContractorsInput"
                           formControlName="rfsuContractors"
                           [searchFunc]="getRFSUContractors"
                           [isAsync]="true"
                           [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                           [filterForm]="filterForm"
                           [formSetter]="'rfsuContractors'"
                           [propertyToShow]="'contractNo'"
                           [placeholder]="'RFSU Contractor'"
                           [secondPropertyToShow]="'contract'"
                           [panelWidth]="480"
                       >
                    </app-multiple-items-selector>
                </div>
                


                <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                    <div class="lds-hourglass"></div>
                </div>
                <ng-container *ngIf="scope == subsystemScopes.AQVD">
                    <div class="emptyspace"></div>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <button type="button" *ngIf="scope === subsystemScopes.BITRandCITR && !blueScreen" (click)="OpenAllItrList()" class="sct-button sct-button-light margin-right20">
                show ITR constraints
            </button>
            <!-- <button type="button" [disabled]="!exportExcelAllow" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20"> -->
            <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20" *ngIf="!blueScreen">
                export to excel
            </button>
            <button
                type="button"
                *ngIf="!lockFilters"
                (click)="resetFilters()"
                class="sct-button sct-button-light margin-right20"
            >
                reset filters
            </button>
            <button type="button" *ngIf="!lockFilters" (click)="search()" class="sct-button sct-button-light">
                search
            </button>
        </div>
        <div class="flex" *ngIf="scope === subsystemScopes.ConstraintDetails" style="padding:10px;">
            <a class="navigator" [routerLink]="" (click)="goBackToItrList()" style="margin-bottom: -28px;">
                <mat-icon style="margin-bottom:-5px; margin-right: -5px;">keyboard_arrow_left</mat-icon>
                <span >  Go back to ITR list </span>
            </a>
        </div>        
        <div class="results-container">
            <mat-form-field class="container300" *ngIf="scope === subsystemScopes.EX">
                <mat-label>Scope</mat-label>
                <mat-select formControlName="exScope" (openedChange)="onScopeClosed($event)">
                    <mat-option [value]="exceptionScopes.ITR">ITR</mat-option>
                    <mat-option [value]="exceptionScopes.PLI">PLI</mat-option>
                    <mat-option [value]="exceptionScopes.Redline">Redline</mat-option>
                    <mat-option [value]="exceptionScopes.ChangeDocument">NPW</mat-option>
                    <mat-option [value]="exceptionScopes.All">All</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="results-title">
                <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
                <div  *ngIf="scope === subsystemScopes.ConstraintDetails && this.itrid > 0">
                    <button type="button" (click)="addITRConstraint($event)" class="sct-button sct-button-light margin-right40">
                        Add Constraint
                    </button>
                </div>
                <div class="results-panel-header-container">
                    <div class="results-panel-header-title-row">
                        <div class="results-header-actions">
                            <button mat-button [matMenuTriggerFor]="columnsCustomizer" class="results-columns-customizer">
                                <mat-icon svgIcon="edit"></mat-icon>
                                customize columns
                            </button>
                            <mat-menu #columnsCustomizer class="columns-customizer">
                                <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                                <div class="columns-customizer-list">
                                    <mat-checkbox
                                        *ngIf="(!lockFilters && scope !== subsystemScopes.EX && scope !== subsystemScopes.Redline && scope !== subsystemScopes.RFOWorkToGoSearch) 
                                            || scope === subsystemScopes.RFSUWorkToGoSearch"
                                        [checked]="showColumns.showSubsysName" 
                                        (change)="reverseValue('showSubsysName')"
                                        (click)="$event.stopPropagation()"
                                        >Subsystem Name</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails
                                            || scope === subsystemScopes.EX
                                            || scope === subsystemScopes.CITR" 
                                        [checked]="showColumns.showRfsuPlan"
                                        (change)="reverseValue('showRfsuPlan')"
                                        (click)="$event.stopPropagation()"
                                        >RFSU Plan</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.EX" 
                                        [checked]="showColumns.showActionBy"
                                        (change)="reverseValue('showActionBy')"
                                        (click)="$event.stopPropagation()"
                                        >Action By</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.EX" 
                                        [checked]="showColumns.showCategory"
                                        (change)="reverseValue('showCategory')"
                                        (click)="$event.stopPropagation()"
                                        >Category</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.EX"
                                        [checked]="showColumns.showExceptionNumber" 
                                        (change)="reverseValue('showExceptionNumber')"
                                        (click)="$event.stopPropagation()"
                                        >Exception Number</mat-checkbox
                                    >
                                    <mat-checkbox
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch"
                                        [checked]="showColumns.showPostRfoExceptionNumber" 
                                        (change)="reverseValue('showPostRfoExceptionNumber')"
                                        (click)="$event.stopPropagation()"
                                        >Exceptions</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI 
                                            ||  scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showMaterialsTotalCount" 
                                        (change)="reverseValue('showMaterialsTotalCount')"
                                        (click)="$event.stopPropagation()"
                                        >Materials Total</mat-checkbox
                                    >
                                    <mat-checkbox
                                        *ngIf="scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.AQVD  
                                            || scope === subsystemScopes.CITR"
                                        [checked]="showColumns.showRfsuForecast" 
                                        (change)="reverseValue('showRfsuForecast')"
                                        (click)="$event.stopPropagation()"
                                        >RFSU Forecast</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.BITRandCITR" 
                                        [checked]="showColumns.showMCActual"
                                        (change)="reverseValue('showMCActual')"
                                        (click)="$event.stopPropagation()"
                                        >MC Actual</mat-checkbox
                                    > 
                                    <mat-checkbox
                                        *ngIf="scope === subsystemScopes.BITRandCITR" 
                                        [checked]="showColumns.showRFSUActual"
                                        (change)="reverseValue('showRFSUActual')"
                                        (click)="$event.stopPropagation()"
                                        >RFSU Actual</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showRFSUWalkdownForecast"
                                        (change)="reverseValue('showRFSUWalkdownForecast')"
                                        (click)="$event.stopPropagation()"
                                        >RFSU Walkdown Forecast</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="lockFilters" 
                                        [checked]="showColumns.showSubsys"
                                        (change)="reverseValue('showSubsys')"
                                        (click)="$event.stopPropagation()"
                                        >{{scope === subsystemScopes.Redline ? 'Subsystem/RFO' : 'Subsystem'}}</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.AQVD 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.CITR" 
                                        [checked]="showColumns.showStatusDate" 
                                        (change)="reverseValue('showStatusDate')"
                                        (click)="$event.stopPropagation()"
                                        >Status Date</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI" 
                                        [checked]="showColumns.showReviewStatus"
                                        (change)="reverseValue('showReviewStatus')"
                                        (click)="$event.stopPropagation()"
                                        >Review Status</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI" 
                                        [checked]="showColumns.showACNumber"
                                        (change)="reverseValue('showACNumber')"
                                        (click)="$event.stopPropagation()"
                                        >AC Number</mat-checkbox
                                    >
                                    <mat-checkbox
                                        *ngIf="scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.COW 
                                            || scope === subsystemScopes.RFSUWorkToGoSearch"
                                        [checked]="showColumns.showTagNo" 
                                        (change)="reverseValue('showTagNo')"
                                        (click)="$event.stopPropagation()"
                                        >Tag Number</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFSUWorkToGoSearch" 
                                        [checked]="showColumns.showDE"
                                        (change)="reverseValue('showDE')"
                                        (click)="$event.stopPropagation()"
                                        >Delivery Engineers</mat-checkbox
                                    >
                                    <mat-checkbox
                                        *ngIf="(scope === subsystemScopes.BITRandCITR || scope === subsystemScopes.PLI) 
                                            && category != 'act'"
                                        [checked]="showColumns.showStatus" 
                                        (change)="reverseValue('showStatus')"
                                        (click)="$event.stopPropagation()"
                                        >Status</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails
                                            || scope === subsystemScopes.EX
                                            || scope === subsystemScopes.RFOWorkToGoSearch
                                            || scope === subsystemScopes.RFSUWorkToGoSearch" 
                                        [checked]="showColumns.showContractor"
                                        (change)="reverseValue('showContractor')"
                                        (click)="$event.stopPropagation()"
                                        >{{scope === subsystemScopes.EX ? 'A-QVD/ITR Contractor' : scope === subsystemScopes.RFSUWorkToGoSearch ? 'ITR Contractor ' : 'Contractor'}}</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope !== subsystemScopes.Redline 
                                            && scope !== subsystemScopes.COW 
                                            && scope !== subsystemScopes.RFOWorkToGoSearch"
                                        [checked]="showColumns.showTagName" 
                                        (change)="reverseValue('showTagName')"
                                        (click)="$event.stopPropagation()"
                                        >Tag Description</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope !== subsystemScopes.Redline 
                                            && scope !== subsystemScopes.COW 
                                            && scope !== subsystemScopes.RFOWorkToGoSearch"
                                        [checked]="showColumns.showTagType" 
                                        (change)="reverseValue('showTagType')"
                                        (click)="$event.stopPropagation()"
                                        >Tag Type</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope !== subsystemScopes.Redline 
                                            && scope !== subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showDiscipline"
                                        (change)="reverseValue('showDiscipline')"
                                        (click)="$event.stopPropagation()"
                                        >{{ scope == subsystemScopes.PLI ? "PL Discipline" : "Discipline"}}</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope !== subsystemScopes.PLI 
                                            && scope !== subsystemScopes.COW 
                                            && scope !== subsystemScopes.Redline 
                                            && scope !== subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showQVD" 
                                        (change)="reverseValue('showQVD')"
                                        (click)="$event.stopPropagation()"
                                        >Description</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.EX" 
                                        [checked]="showColumns.showPreCommDates" 
                                        (change)="reverseValue('showPreCommDates')"
                                        (click)="$event.stopPropagation()"
                                        >Activity Dates</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.Redline 
                                            || scope === subsystemScopes.AQVD 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.RFSUWorkToGoSearch" 
                                        [checked]="showColumns.showDocType" 
                                        (change)="reverseValue('showDocType')"
                                        (click)="$event.stopPropagation()"
                                        >Doc Type</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.AQVD 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails 
                                            || scope === subsystemScopes.CITR" 
                                        [checked]="showColumns.showDocType2" 
                                        (change)="reverseValue('showDocType2')"
                                        (click)="$event.stopPropagation()"
                                        >Doc Type 2</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.AQVD 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.RFSUWorkToGoSearch" 
                                        [checked]="showColumns.showPriority" 
                                        (change)="reverseValue('showPriority')"
                                        (click)="$event.stopPropagation()"
                                        >Staged Start-up Priority</mat-checkbox
                                    >
                                    <mat-checkbox
                                        *ngIf="scope === subsystemScopes.Redline" 
                                        [checked]="showColumns.showRedType"
                                        (change)="reverseValue('showRedType')"
                                        (click)="$event.stopPropagation()"
                                        >Redline Type</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.Redline" 
                                        [checked]="showColumns.showOrigSubsys"
                                        (change)="reverseValue('showOrigSubsys')"
                                        (click)="$event.stopPropagation()"
                                        >Original Subsysyem</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.Redline" 
                                        [checked]="showColumns.showMcdrConstrRedCat"
                                        (change)="reverseValue('showMcdrConstrRedCat')"
                                        (click)="$event.stopPropagation()"
                                        >MCDR Constr Redline Category</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.Redline" 
                                        [checked]="showColumns.showChangeId"
                                        (change)="reverseValue('showChangeId')"
                                        (click)="$event.stopPropagation()"
                                        >Change ID</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.EX" 
                                        [checked]="showColumns.showTargetCompletionDate"
                                        (change)="reverseValue('showTargetCompletionDate')"
                                        (click)="$event.stopPropagation()"
                                        >Target Completion Date</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.EX" 
                                        [checked]="showColumns.showLastConstraintDate"
                                        (change)="reverseValue('showLastConstraintDate')"
                                        (click)="$event.stopPropagation()"
                                        >Last ITR Constraint Date</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI" 
                                        [checked]="showColumns.showPunchType"
                                        (change)="reverseValue('showPunchType')"
                                        (click)="$event.stopPropagation()"
                                        >Punch Type</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.AQVD 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.CITR" 
                                        [checked]="showColumns.showNBResponsiblePersonComment"
                                        (change)="reverseValue('showNBResponsiblePersonComment')"
                                        (click)="$event.stopPropagation()"
                                        >Responsible Person</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.COW"
                                        [checked]="showColumns.showRaisedBy" 
                                        (change)="reverseValue('showRaisedBy')"
                                        (click)="$event.stopPropagation()"
                                        >Raised By</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI" 
                                        [checked]="showColumns.showRaisedDate"
                                        (change)="reverseValue('showRaisedDate')"
                                        (click)="$event.stopPropagation()"
                                        >Raised Date</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI" 
                                        [checked]="showColumns.showMaterialReviewStatus"
                                        (change)="reverseValue('showMaterialReviewStatus')"
                                        (click)="$event.stopPropagation()"
                                        >Material Review Status</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI" 
                                        [checked]="showColumns.showMaterialReviewAssignedTo"
                                        (change)="reverseValue('showMaterialReviewAssignedTo')"
                                        (click)="$event.stopPropagation()"
                                        >Material Review Assigned To</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI" 
                                        [checked]="showColumns.showMaterialReference"
                                        (change)="reverseValue('showMaterialReference')"
                                        (click)="$event.stopPropagation()"
                                        >Material Reference</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI" 
                                        [checked]="showColumns.showClassification"
                                        (change)="reverseValue('showClassification')"
                                        (click)="$event.stopPropagation()"
                                        >Classification</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.COW" 
                                        [checked]="showColumns.showCowDocNo"
                                        (change)="reverseValue('showCowDocNo')"
                                        (click)="$event.stopPropagation()"
                                        >COW Doc Number</mat-checkbox
                                    >
                                    <mat-checkbox
                                        *ngIf="scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.COW"
                                        [checked]="scope == subsystemScopes.PLI ? !showColumns.showAttachments : showColumns.showAttachments"
                                        (change)="reverseValue('showAttachments')"
                                        (click)="$event.stopPropagation()"
                                        >Attachments</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.BITRandCITR
                                            || scope === subsystemScopes.EX 
                                            || scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showActivityID" 
                                        (change)="reverseValue('showActivityID')"
                                        (click)="$event.stopPropagation()"
                                        >Activity ID</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.EX"
                                        [checked]="showColumns.showExceptionAdditionalResponsbilePerson"
                                        (change)="reverseValue('showExceptionAdditionalResponsbilePerson')"
                                        (click)="$event.stopPropagation()"
                                        >Additional Responsible Person</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.AQVD 
                                            || scope ===  subsystemScopes.BITR 
                                            || scope === subsystemScopes.CITR
                                            || scope === subsystemScopes.EX" 
                                        [checked]="showColumns.showRaisedDate"
                                        (change)="reverseValue('showRaisedDate')"
                                        (click)="$event.stopPropagation()"
                                        >Created Date</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI" 
                                        [checked]="showColumns.showRequiredByDate"
                                        (change)="reverseValue('showRequiredByDate')"
                                        (click)="$event.stopPropagation()"
                                        >Required By Date</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.AQVD 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.EX 
                                            || scope === subsystemScopes.RFSUWorkToGoSearch" 
                                        [checked]="showColumns.showBarcode"
                                        (change)="reverseValue('showBarcode')"
                                        (click)="$event.stopPropagation()"
                                        >Barcode</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoBarcode"
                                        (change)="reverseValue('showPostRfoBarcode')"
                                        (click)="$event.stopPropagation()"
                                        >Barcode</mat-checkbox
                                    >                                        
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoQVDNumber"
                                        (change)="reverseValue('showPostRfoQVDNumber')"
                                        (click)="$event.stopPropagation()"
                                        >ITR</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoTagNumber"
                                        (change)="reverseValue('showPostRfoTagNumber')"
                                        (click)="$event.stopPropagation()"
                                        >Tag Number</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.CITR
                                            || scope === subsystemScopes.EX
                                            || scope === subsystemScopes.RFSUWorkToGoSearch"
                                        [checked]="showColumns.showManhours" 
                                        (change)="reverseValue('showManhours')"
                                        (click)="$event.stopPropagation()"
                                        >Manhours</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.BITRandCITR 
                                                || scope === subsystemScopes.EX" 
                                        [checked]="showColumns.showITRConstraintCount"
                                        (change)="reverseValue('showITRConstraintCount')"
                                        (click)="$event.stopPropagation()"
                                        >ITR Constraints</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showITRConstraintTypeName"
                                        (change)="reverseValue('showITRConstraintTypeName')"
                                        (click)="$event.stopPropagation()"
                                        >Constraint Type</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showITRConstraintId" 
                                        (change)="reverseValue('showITRConstraintId')"
                                        (click)="$event.stopPropagation()"
                                        >Constraint ID</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showITRConstraintDescription"
                                        (change)="reverseValue('showITRConstraintDescription')"
                                        (click)="$event.stopPropagation()"
                                        >Constraint Description</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showITRConstraintDueDate"
                                        (change)="reverseValue('showITRConstraintDueDate')"
                                        (click)="$event.stopPropagation()"
                                        >Constraint Forecast Closure Date</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails" 
                                        [checked]="showColumns.showFMRorPOETADate"
                                        (change)="reverseValue('showFMRorPOETADate')"

                                        >FMR or PO ETA Date</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.PLI ||
                                            scope === subsystemScopes.ConstraintDetails" 
                                        [checked]="showColumns.showFmrLatestETA"
                                        (change)="reverseValue('showFmrLatestETA')"
                                        (click)="$event.stopPropagation()"
                                        >ETA</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails 
                                                || scope === subsystemScopes.RFSUWorkToGoSearch" 
                                        [checked]="showColumns.showExceptionNumber"
                                        (change)="reverseValue('showExceptionNumber')"
                                        (click)="$event.stopPropagation()"
                                        >ITR Exception Number</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showITRConstraintResponsibleGroup"
                                        (change)="reverseValue('showITRConstraintResponsibleGroup')"
                                        (click)="$event.stopPropagation()"
                                        >ITR Constraint Responsible Group</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showITRConstraintRaisedByUser"
                                        (change)="reverseValue('showITRConstraintRaisedByUser')"
                                        (click)="$event.stopPropagation()"
                                        >ITR Constraint Raised By</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showITRConstraintRaisedByTeam"
                                        (change)="reverseValue('showITRConstraintRaisedByTeam')"
                                        (click)="$event.stopPropagation()"
                                        >ITR Constraint Raised By Team</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showITRConstraintRaisedDate"
                                        (change)="reverseValue('showITRConstraintRaisedDate')"
                                        (click)="$event.stopPropagation()"
                                        >ITR Constraint Raised Date</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails
                                                || scope === subsystemScopes.EX " 
                                        [checked]="showColumns.showFMRorPO"
                                        (change)="reverseValue('showFMRorPO')"
                                        (click)="$event.stopPropagation()"
                                        >FMR or PO</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.EX || scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showExceptionPriority"
                                        (change)="reverseValue('showExceptionPriority')"
                                        (click)="$event.stopPropagation()"
                                        >Plant Start Up Impact</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.EX || scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showExceptionPhase"
                                        (change)="reverseValue('showExceptionPhase')"
                                        (click)="$event.stopPropagation()"
                                        >Exception Phase</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails 
                                            || scope === subsystemScopes.BITRandCITR
                                            || scope === subsystemScopes.PLI
                                            || scope === subsystemScopes.EX
                                            || scope === subsystemScopes.RFSUWorkToGoSearch" 
                                        [checked]="showColumns.showRFO"
                                        (change)="reverseValue('showRFO')"
                                        (click)="$event.stopPropagation()"
                                        >RFO</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope !== subsystemScopes.COW 
                                            && scope !== subsystemScopes.Redline 
                                            && scope !== subsystemScopes.RFSUWorkToGoSearch 
                                            && scope !== subsystemScopes.RFOWorkToGoSearch"
                                        [checked]="showColumns.showRFOPlan" 
                                        (change)="reverseValue('showRFOPlan')"
                                        (click)="$event.stopPropagation()"
                                        >RFO Plan</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.AQVD 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.EX 
                                            || scope === subsystemScopes.RFSUWorkToGoSearch" 
                                        [checked]="showColumns.showWaypoint"
                                        (change)="reverseValue('showWaypoint')"
                                        (click)="$event.stopPropagation()"
                                        >Waypoint</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.AQVD 
                                            || scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.BITRandCITR 
                                            || scope === subsystemScopes.ConstraintDetails 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.PLI 
                                            || scope === subsystemScopes.EX 
                                            || scope === subsystemScopes.RFSUWorkToGoSearch" 
                                        [checked]="showColumns.showMilestone"
                                        (change)="reverseValue('showMilestone')"
                                        (click)="$event.stopPropagation()"
                                        >Milestone</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails
                                                || scope === subsystemScopes.EX" 
                                        [checked]="showColumns.showAssociatedNPW"
                                        (change)="reverseValue('showAssociatedNPW')"
                                        (click)="$event.stopPropagation()"
                                        >Associated NPW</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails" 
                                        [checked]="showColumns.showAssociatedPLI"
                                        (change)="reverseValue('showAssociatedPLI')"
                                        (click)="$event.stopPropagation()"
                                        >Associated PLI</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails" 
                                        [checked]="showColumns.showAssociatedQVD"
                                        (change)="reverseValue('showAssociatedQVD')"
                                        (click)="$event.stopPropagation()"
                                        >Associated QVD</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showAssociatedComment"
                                        (change)="reverseValue('showAssociatedComment')"
                                        (click)="$event.stopPropagation()"
                                        >Associated Comment</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails" 
                                        [checked]="showColumns.showAQVDContractor"
                                        (change)="reverseValue('showAQVDContractor')"
                                        (click)="$event.stopPropagation()"
                                        >AQVD Contractor</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails" 
                                        [checked]="showColumns.showPLIActionedBy"
                                        (change)="reverseValue('showPLIActionedBy')"
                                        (click)="$event.stopPropagation()"
                                        >PLI Actioned By</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails"
                                        [checked]="showColumns.showLatestITRComment" 
                                        (change)="reverseValue('showLatestITRComment')"
                                        (click)="$event.stopPropagation()"
                                        >ITR Comment</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.ConstraintDetails" 
                                        [checked]="showColumns.showPriorityNo"
                                        (change)="reverseValue('showPriorityNo')"
                                        (click)="$event.stopPropagation()"
                                        >Priority</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.PLI
                                            || scope === subsystemScopes.RFSUWorkToGoSearch 
                                            || scope === subsystemScopes.RFOWorkToGoSearch
                                            || scope === subsystemScopes.AQVD 
                                            || scope === subsystemScopes.EX
                                            || scope === subsystemScopes.Redline" 
                                        [checked]="showColumns.showAreaBreakdown"
                                        (change)="reverseValue('showAreaBreakdown')"
                                        (click)="$event.stopPropagation()"
                                        >Area Breakdown</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.Redline" 
                                        [checked]="showColumns.showRevision"
                                        (change)="reverseValue('showRevision')"
                                        (click)="$event.stopPropagation()"
                                        >Revision</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.PLI
                                            || scope === subsystemScopes.AQVD " 
                                        [checked]="showColumns.showWorkOrderNum"
                                        (change)="reverseValue('showWorkOrderNum')"
                                        (click)="$event.stopPropagation()"
                                        >Work Order Number</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.BITR 
                                            || scope === subsystemScopes.CITR 
                                            || scope === subsystemScopes.PLI
                                            || scope === subsystemScopes.AQVD " 
                                        [checked]="showColumns.showETransferNumber"
                                        (change)="reverseValue('showETransferNumber')"
                                        (click)="$event.stopPropagation()"
                                        >eTransfer Number</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoActualDate"
                                        (change)="reverseValue('showPostRfoActualDate')"
                                        (click)="$event.stopPropagation()"
                                        >RFO Actual</mat-checkbox
                                    > 
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoQVDNumber"
                                        (change)="reverseValue('showPostRfoQVDNumber')"
                                        (click)="$event.stopPropagation()"
                                        >Check Sheet Number</mat-checkbox
                                    >     
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoTagNumber"
                                        (change)="reverseValue('showPostRfoTagNumber')"
                                        (click)="$event.stopPropagation()"
                                        >Tag Number</mat-checkbox
                                    >                                                                                         
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoPli"
                                        (change)="reverseValue('showPostRfoPli')"
                                        (click)="$event.stopPropagation()"
                                        >PLI (A, B, C, D - Combined)</mat-checkbox
                                    >    
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoPliCreatedDate"
                                        (change)="reverseValue('showPostRfoPliCreatedDate')"
                                        (click)="$event.stopPropagation()"
                                        >PLI Creation Date</mat-checkbox
                                    >                                     
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoNpw"
                                        (change)="reverseValue('showPostRfoNpw')"

                                        >NPW</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoNpwIssueDate"
                                        (change)="reverseValue('showPostRfoNpwIssueDate')"
                                        (click)="$event.stopPropagation()"
                                        >NPW Issued Date</mat-checkbox
                                    >                                      
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoPascr"
                                        (change)="reverseValue('showPostRfoPascr')"
                                        (click)="$event.stopPropagation()"
                                        >PAS CR</mat-checkbox
                                    >
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoRedline"
                                        (change)="reverseValue('showPostRfoRedline')"
                                        (click)="$event.stopPropagation()"
                                        >Redline</mat-checkbox
                                    >  
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPriorityManualOverride"
                                        (change)="reverseValue('showPriorityManualOverride')"
                                        (click)="$event.stopPropagation()"
                                        >Priority Manual Override</mat-checkbox
                                    > 
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRfoResponsiblePersonOrGroup"
                                        (change)="reverseValue('showPostRfoResponsiblePersonOrGroup')"
                                        (click)="$event.stopPropagation()"
                                        >Responsible Person/Group</mat-checkbox
                                    > 
                                    <mat-checkbox 
                                        *ngIf="scope === subsystemScopes.RFOWorkToGoSearch" 
                                        [checked]="showColumns.showPostRFOWorkExpectedClosureDate"
                                        (change)="reverseValue('showPostRFOWorkExpectedClosureDate')"
                                        (click)="$event.stopPropagation()"
                                        >Expected Closure Date</mat-checkbox
                                    >                                                                                                                                          
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div class="scroll" #detailedPage>
                <div class="table-container">
                    <app-mat-table-sticky-header [scrollKey]="scope + '-detailed-status'">
                        <table
                            mat-table
                            matSort
                            [dataSource]="data"
                            [matSortActive]="sortBy"
                            matSortDisableClear
                            [matSortDirection]="direction"
                            [ngClass]="{
                                PLI: scope === subsystemScopes.PLI,
                                COW: scope === subsystemScopes.COW,
                                ITR:
                                    scope === subsystemScopes.BITR ||
                                    scope === subsystemScopes.CITR ||
                                    scope === subsystemScopes.BITRandCITR ||
                                    scope === subsystemScopes.ConstraintDetails ||
                                    scope === subsystemScopes.AQVD
                            }"
                        >
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef
                            [ngClass]="{
                                hidecells: scope !== subsystemScopes.BITRandCITR && scope !== subsystemScopes.BITR && scope !== subsystemScopes.CITR
                            }"></th>
                            <td
                            mat-cell
                            *matCellDef="let row"
                            [ngClass]="{ hidecells: scope !== subsystemScopes.BITRandCITR && scope !== subsystemScopes.BITR && scope !== subsystemScopes.CITR  }"   
                        >
                                <mat-checkbox                                 
                                    (click)="highlightRow($event,row)"
                                    (change)="$event"
                                    [checked]="isSelected(row)"
                                >
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="addConstraint">
                            <!-- <th
                                app-col-resize
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showAddconstraints || (scope !== subsystemScopes.BITRandCITR && scope !== subsystemScopes.BITR && scope !== subsystemScopes.CITR)
                                }"
                            > -->
                            <th
                                app-col-resize
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: scope !== subsystemScopes.BITRandCITR && scope !== subsystemScopes.BITR && scope !== subsystemScopes.CITR
                                }"
                            >
                                <div class="sort-container center">
                                    <span class="hover-pointer">Add Constraint</span>
                                </div>
                            </th>
                            <!-- <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showAddconstraints || (scope !== subsystemScopes.BITRandCITR && scope !== subsystemScopes.BITR && scope !== subsystemScopes.CITR)  }"   
                            > -->
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: scope !== subsystemScopes.BITRandCITR && scope !== subsystemScopes.BITR && scope !== subsystemScopes.CITR  }"   
                            >
                            <a type="button" class="" (click)="goToITRConstraints($event,element)">
                               Add Constraints
                            </a>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="redType">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                class="hover-pointer"
                                [ngClass]="{
                                    hidecells: !showColumns.showRedType
                                }"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'redType',
                                        getRedTypes,
                                        'columnRedType',
                                        'Redline Type',
                                        columnRedType,
                                        true,
                                        true
                                    )
                                "
                            >
                                <div class="sort-container center">
                                    <span
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnRedType)
                                        }"
                                        >Redline Type</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'redType'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{
                                    hidecells: !showColumns.showRedType
                                }"
                            >
                                {{ element.redType }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="redlineExceptionNumber">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                class="hover-pointer"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'redlineExceptionNumber',
                                        getRedlineExceptionNumber,
                                        'columnRedlineExceptionNumber',
                                        'Redline Exception Number',
                                        columnRedlineExceptionNumber,
                                        true,
                                        true
                                    )
                                "
                            >
                                <div class="sort-container center">
                                    <span
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnRedlineExceptionNumber)
                                        }"
                                        > Exception Number</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'redlineExceptionNumber'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                            >
                                {{ element.redlineExceptionNumber | breakLine: ',' }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="redReqType">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                class="hover-pointer"
                                [ngClass]="{
                                    hidecells: !showColumns.showRedReqType
                                }"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'redReqType',
                                        getRedReqTypes,
                                        'columnRedReqType',
                                        'Red Req Type',
                                        columnRedReqType,
                                        true,
                                        true
                                    )
                                "
                            >
                                <div class="sort-container center">
                                    <span
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnRedReqType)
                                        }"
                                        >Required For</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'redReqType'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{
                                    hidecells: !showColumns.showRedReqType
                                }"
                            >
                                {{ element.redReqType }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="subsystem">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showSubsys 
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'subsystem',
                                                    getSubsystems,
                                                    'columnSubsystem',
                                                    'Subsystem',
                                                    columnSubsystem,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnSubsystem)
                                            }"
                                            >{{scope === subsystemScopes.Redline ? 'Subsystem/RFO' : 'Subsystem'}}</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showSubsys  }"
                                >
                                    {{ element.subsystem }}
                                </td>
                            </ng-container>

                            
                            <ng-container matColumnDef="originalSubSystem">
                                <th 
                                    app-col-resize                                   
                                    mat-header-cell   
                                    disableClear                                 
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showOrigSubsys 
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'originalSubSystem',
                                                    getOrigSubsystems,
                                                    'columnOriginalSubsystem',
                                                    'originalSubSystem',
                                                    columnOriginalSubsystem,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnOriginalSubsystem)
                                            }"
                                            >Original Subsystems</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'originalSubSystem'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showOrigSubsys  }"
                                >
                                    {{ element.originalSubSystem }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="subsystemName">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: lockFilters || !showColumns.showSubsysName 
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subsystemName',
                                            getSubsystemNames,
                                            'columnSubsystemName',
                                            'Subsystem Name',
                                            columnSubsystemName
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnSubsystemName)
                                            }"
                                            >Subsystem Name</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: lockFilters || !showColumns.showSubsysName }"
                                >
                                    {{ element.subsystemName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="deliveryEng">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showColumns.showDE }"
                                    class="hover-pointer"                                 
                                    (click)="      
                                    openHeaderCheckListFilter(
                                            'deliveryEngineer',
                                            getDeliveryEngineer,
                                            'columnDeliveryEngineer',
                                            'Delivery Engineer',
                                            columnDeliveryEngineer,
                                            false,
                                            true
                                        )
                                "
                                >
                                    <div class="sort-container center">
                                        <span
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnDeliveryEngineer)
                                        }"
                                            >Delivery Engineer</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'deliveryEng'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{hidecells: !showColumns.showDE}"
                                >
                                    {{ element.deliveryEngineer }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuPlan">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showColumns.showRfsuPlan }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('rfsuPlan', 'columnRfsuPlan', 'RFSU Plan', columnRfsuPlan)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRfsuPlan)
                                            }"
                                            >RFSU Plan</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showRfsuPlan }"
                                >
                                    {{ element.rfsuPlan | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showColumns.showRfsuForecast }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfsuForecast',
                                            'columnRfsuForecast',
                                            'RFSU Forecast',
                                            columnRfsuForecast
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRfsuForecast)
                                            }"
                                            >RFSU Forecast</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showRfsuForecast }"
                                >
                                    {{ element.rfsuForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="lastConstraintDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showColumns.showLastConstraintDate }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'lastConstraintDate',
                                            'columnLastConstraintDate',
                                            'Last ITR Constraint Date',
                                            columnLastConstraintDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnLastConstraintDate)
                                            }"
                                            >Last ITR Constraint Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'lastConstraintDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showLastConstraintDate }"
                                >
                                    {{ element.lastConstraintDate | date: 'd-MMM-yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="forecastClosureDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showForecastClosureDate
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'forecastClosureDate',
                                            'columnForecastClosureDate',
                                            'Forecast Closure Date',
                                            columnForecastClosureDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>Forecast Closure Date</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'forecastClosureDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    (click)="openDatepicker($event,element, 'forecastClosureDate')"
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showColumns.showForecastClosureDate,
                                        'cursor-pointer':
                                        isExceptionsTracking || !isReadOnly 
                                    }"
                                    (contextmenu)="exceptionForecastDateHistory($event, element, 'forecastClosureDate')"
                                >
                                    {{ element.forecastClosureDate | date: 'd-MMM-yy' }}
                                    <button
                                        *ngIf="!element.forecastClosureDate && !isReadOnly"
                                        type="button"
                                        mat-icon-button
                                    >
                                        <mat-icon>calendar_today</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
               
                            <ng-container matColumnDef="exceptionResponsiblePerson">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showExceptionResponsbilePerson
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'responsiblePerson',
                                            getITRConstraintRaisedByUser,
                                            'columnExceptionResponsbilePerson',
                                            'Responsible Person',
                                            columnExceptionResponsbilePerson,
                                            false,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnExceptionResponsbilePerson)
                                            }"
                                            >Responsible Person</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'exceptionResponsiblePerson'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" 
                                (click)="openUserdefinedPopup(element, $event, userDefinedinputs.ResponsiblePerson)">
                                    <div [ngSwitch]="isExceptionTrackingstr">
                                        <ng-container *ngSwitchCase="'yes_i_have_edit_permissions'">
                                            <div *ngIf="element.responsiblePerson && !isReadOnly; else notShow">
                                                <a
                                                    ><span
                                                        [innerHTML]="element.responsiblePerson"
                                                    ></span
                                                ></a>
                                            </div>
                                            <ng-template #notShow>
                                                <button
                                                    type="button"
                                                    *ngIf="!isReadOnly"
                                                    mat-stroked-button
                                                    class="add-responsibleuser"
                                                >
                                                    Add Responsbile Person
                                                </button>
                                            </ng-template>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'no_i_dont_have_edit_permissions'">
                                            {{element.responsiblePerson}}
                                        </ng-container>
                                       
                                      </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="exceptionAdditionalResponsibleUser">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showExceptionAdditionalResponsbilePerson
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'exceptionAdditionalResponsibleUser',
                                            getITRConstraintAdditionalResponsibleUser,
                                            'columnExceptionAdditionalResponsibleUser',
                                            'Additional Responsible Person',
                                            columnExceptionAdditionalResponsibleUser,
                                            false,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnExceptionAdditionalResponsibleUser)
                                            }"
                                            >Addt.Resp.Person</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'exceptionAdditionalResponsibleUser'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" 
                                [ngClass]="{ hidecells: !showColumns.showExceptionAdditionalResponsbilePerson }"
                                (click)="openUserdefinedPopup(element, $event, userDefinedinputs.AdditionalResponsiblePerson)">
                                    <div [ngSwitch]="isExceptionTrackingstr">
                                        <ng-container *ngSwitchCase="'yes_i_have_edit_permissions'">
                                            <div *ngIf="element.exceptionAdditionalResponsibleUser && !isReadOnly; else notShow">
                                                <a
                                                    ><span
                                                        [innerHTML]="element.exceptionAdditionalResponsibleUser"
                                                    ></span
                                                ></a>
                                            </div>
                                            <ng-template #notShow>
                                                <button
                                                    type="button"
                                                    *ngIf="!isReadOnly"
                                                    mat-stroked-button
                                                    class="add-responsibleuser"
                                                >
                                                    Add Addt.Resp.Person
                                                </button>
                                            </ng-template>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'no_i_dont_have_edit_permissions'">
                                            {{element.exceptionAdditionalResponsibleUser}}
                                        </ng-container>
                                       
                                      </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuWalkdownForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showColumns.showRFSUWalkdownForecast }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfsuWalkdownForecast',
                                            'columnRFSUWalkdownForecast',
                                            'RFSU Walkdown Forecast',
                                            columnRFSUWalkdownForecast
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFSUWalkdownForecast)
                                            }"
                                            >RFSU Walkdown Forecast</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuWalkdownForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showRFSUWalkdownForecast }"
                                >
                                    {{ element.rfsuWalkdownForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="category">
                                <th
                                    mat-header-cell
                                    [ngClass]="{ hidecells: !showColumns.showCategory}"
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'category',
                                            getCategories,
                                            'columnCategory',
                                            'Category',
                                            columnCategory,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnCategory)
                                            }"
                                            >Category</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'category'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showCategory}"
                                >{{ element.category }}</td>
                            </ng-container>
                            <ng-container
                                matColumnDef="tagNumber"
                                
                            >
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showTagNo
                                    }"
                                    [ngStyle]="{
                                        left: lockFilters
                                            ? showColumns.showSubsys
                                                ? '190px'
                                                : '0px'
                                            : showColumns.showSubsysName
                                            ? '305px'
                                            : '190px'
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnTagNumber)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'tagNumber',
                                                    getTagNumbers,
                                                    'columnTagNumber',
                                                    'Tag Number',
                                                    columnTagNumber
                                                )
                                            "
                                            >Tag Number</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'tagNumber'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showTagNo }"
                                    [ngStyle]="{
                                        left: lockFilters
                                            ? showColumns.showSubsys
                                                ? '190px'
                                                : '0px'
                                            : showColumns.showSubsysName
                                            ? '305px'
                                            : '190px'
                                    }"
                                >
                                    {{ element.tagNumber }}
                                </td>
                            </ng-container>
                            <ng-container
                                matColumnDef="tagType"                            
                            >
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showTagType
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'tagType',
                                            getTagTypes,
                                            'columnTagType',
                                            'Tag Type',
                                            columnTagType,
                                            showCounts()
                                        )
                                    "
                                    [ngStyle]="showHideColumns(449)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnTagType)
                                            }"
                                            >Tag Type</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'tagType'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showTagType }"
                                    [ngStyle]="showHideColumns(449)"
                                >
                                    {{ element.tagType }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tagName">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showTagName
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'tagName',
                                            getTagNames,
                                            'columnTagName',
                                            'Tag Name',
                                            columnTagName,
                                            showCounts()
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnTagName)
                                            }"
                                            >Tag Description</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'tagName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showTagName }"
                                >
                                    {{ element.tagName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="cowDocNumber">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: scope !== subsystemScopes.COW || !showColumns.showCowDocNo
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'cowDocNumber',
                                            getCowDocNumbers,
                                            'columnCowDocNumber',
                                            'cowDocNumber',
                                            columnCowDocNumber
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnCowDocNumber)
                                            }"
                                            >COW Doc Number</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'cowDocNumber'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells: scope !== subsystemScopes.COW || !showColumns.showCowDocNo
                                    }"
                                >
                                    {{ element.cowDocNumber }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="actionBy">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'actionBy',
                                            getActionBys,
                                            'columnActionBy',
                                            'Action By',
                                            columnActionBy,
                                            showCounts()
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnActionBy)
                                            }"
                                            > {{scope !== subsystemScopes.RFSUWorkToGoSearch ? 'PLI Action By' : 'Action by'}}</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'actionBy'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.actionBy }}</td>
                            </ng-container>

                            <ng-container matColumnDef="pliImplementationTeam">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showColumns.showPliImplementationTeam
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'pliImplementationTeam',
                                            getImplementationTeam,
                                            'columnPliImplementationTeam',
                                            'implementation Team',
                                            columnPliImplementationTeam                                            
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPliImplementationTeam)
                                            }"
                                            >PLI Implementation Team</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'pliImplementationTeam'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>

                                <span *ngIf="!pliImplementationTeamAcces()">
                                    <td mat-cell *matCellDef="let element"  [ngClass]="{ hidecells: !showColumns.showPliImplementationTeam }">{{ element.pliImplementationTeam }}</td>
                                </span>

                                <span *ngIf="pliImplementationTeamAcces()">
                                    <td 
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngClass]="{ hidecells: !showColumns.showPliImplementationTeam }"   
                                        (click)="stopeventProp($event)"                                     
                                    >                                    
                                        <mat-select                               
                                        matNativeControl
                                        [(value)]="element.pliImplementationTeam"                                                                        
                                        (selectionChange)="updatePliImplementationTeam($event, element.number)"                                
                                        >
                                         <mat-option>None</mat-option>
                                            <mat-option *ngFor="let impt of pliImplementationTeams" [value]="impt.name">
                                                {{ impt.name }}
                                            </mat-option>
                                        </mat-select>
                                    </td>
                                </span>
                                
                            </ng-container>

                            <ng-container matColumnDef="exActionBy">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showActionBy
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'actionBy',
                                            getActionBys,
                                            'columnActionBy',
                                            'Action By',
                                            columnActionBy,
                                            showCounts()
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnActionBy)
                                            }"
                                            >Action by</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'exActionBy'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showActionBy }"
                                
                                >{{ element.actionBy }}</td>
                            </ng-container>
                            <ng-container matColumnDef="punchType">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPunchType
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'punchType',
                                            getPunchTypeValues,
                                            'columnPunchType',
                                            'Punch Type',
                                            columnPunchType,
                                            showCounts()
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPunchType)
                                            }"
                                            >Punch Type</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'punchType'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPunchType }"
                                >
                                    {{ element.punchType }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="reviewStatusComplete">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showReviewStatus
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'reviewStatusComplete',
                                            getReviewStatusComplete,
                                            'columnReviewStatusComplete',
                                            'D Review Status',
                                            columnReviewStatusComplete,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnReviewStatusComplete
                                                )
                                            }"
                                            >D Review Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'reviewStatusComplete'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showReviewStatus }"
                                >
                                    {{ element.reviewStatusComplete }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="acNumber">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showACNumber
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'acNumber',
                                            getACNumber,
                                            'columnACNumber',
                                            'AC Number',
                                            columnACNumber,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnACNumber
                                                )
                                            }"
                                            >AC Number</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'acNumber'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showACNumber }"
                                >
                                    {{ element.acNumber }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="createdDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showRaisedDate
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'createdDate',
                                            'columnCreatedDate',
                                            'Raised Date',
                                            columnCreatedDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnCreatedDate)
                                            }"
                                            >{{ scope === subsystemScopes.PLI ? "Raised Date" : "Created Date"}}</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'createdDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showRaisedDate }"
                                >
                                    {{ element.createdDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="requiredByDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showRequiredByDate
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'requiredByDate',
                                            'columnRequiredByDate',
                                            'Required By Date',
                                            columnRequiredByDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRequiredByDate)
                                            }"
                                            > Required By Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'requiredByDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showRequiredByDate }"
                                >
                                    {{ element.requiredByDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="materialReviewStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showMaterialReviewStatus
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'materialReviewStatus',
                                            getMaterialReviewStatusDetails,
                                            'columnMaterialReviewStatus',
                                            'Material Review Status',
                                            columnMaterialReviewStatus
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnMaterialReviewStatus)
                                            }"
                                            >Material Review Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'materialReviewStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showMaterialReviewStatus }"
                                >
                                    {{ element.materialReviewStatus }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="materialReviewAssignedTo">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showMaterialReviewAssignedTo
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'materialReviewAssignedTo',
                                            getMaterialReviewAssignedToDetails,
                                            'columnMaterialReviewAssignedTo',
                                            'Material Review AssignedTo',
                                            columnMaterialReviewAssignedTo
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnMaterialReviewAssignedTo)
                                            }"
                                            >Material Review AssignedTo</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'materialReviewAssignedTo'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showMaterialReviewAssignedTo }"
                                >
                                    {{ element.materialReviewAssignedTo }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="raisedBy">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showRaisedBy
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'raisedBy',
                                            getRaisedByValues,
                                            'columnRaisedBy',
                                            'Raised By',
                                            columnRaisedBy
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRaisedBy)
                                            }"
                                            >Raised By</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'raisedBy'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showRaisedBy }"
                                >
                                    {{ element.raisedBy }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="discipline">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showDiscipline
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'discipline',
                                            getDisciplines,
                                            'columnDiscipline',
                                            'Discipline',
                                            columnDiscipline,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnDiscipline)
                                            }"
                                            >{{ scope == subsystemScopes.PLI ? "PL Discipline" :  "Discipline"}}</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showDiscipline }"
                                >
                                    {{ element.discipline }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tagDiscipline">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showTagDiscipline
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'tagDiscipline',
                                            getTagDisciplineValues,
                                            'columnTagDiscipline',
                                            'Tag Discipline',
                                            columnTagDiscipline
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnTagDiscipline)
                                            }"
                                            >Tag Discipline</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'tagDiscipline'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showTagDiscipline }"
                                >
                                    {{ element.tagDiscipline }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="priorityNo">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPriorityNo || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'priorityNo',
                                            getPriorityNo,
                                            'columnPriorityNo',
                                            'Priority',
                                            columnPriorityNo
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPriorityNo)
                                            }"
                                            >Priority</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'priorityNo'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPriorityNo }"
                                >
                                    {{ element.priorityNo }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="number">
                                <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'number',
                                                    getNumbers,
                                                    'columnNumber',
                                                    'Number',
                                                    columnNumber,
                                                    scope === subsystemScopes.PLI || scope === subsystemScopes.COW
                                                        ? false
                                                        : true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnNumber)
                                            }"
                                            >{{ displayNumberColumnName() }}</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'number'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngStyle]="showHideColumns(482)">
                                    <span *ngIf="scope !== subsystemScopes.Redline">{{ element.number }}</span>
                                    <div
                                        *ngIf="scope === subsystemScopes.Redline"
                                        (click)="openCoreworx(element.coreworxLink, $event)"
                                        class="coreworx-link"
                                    >
                                        {{ element.number }}
                                    </div>
                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="criticalPath">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showCriticalPath || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'criticalPath',
                                            getCriticalPath,
                                            'columnCriticalPath',
                                            'Critical Path',
                                            columnCriticalPath
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnCriticalPath)
                                            }"
                                            >Critical Path</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'criticalPath'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showCriticalPath }"
                                >
                                    {{ element.criticalPath }}
                                </td>
                            </ng-container> -->
                            <ng-container matColumnDef="description">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showQVD
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'description',
                                                    getDescriptions,
                                                    'columnDescription',
                                                    scope === subsystemScopes.Redline ? 'Title' : 'Description',
                                                    columnDescription,
                                                    showCounts()
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnDescription)
                                            }"
                                            >{{ displayDescriptionColumnName() }}</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'description'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showQVD }">
                                    {{ element.description }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="preCommStart">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPreCommDates
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'preCommStart',
                                            'columnPreCommStart',
                                            'Pre-Comm Start',
                                            columnPreCommStart
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnPreCommStart)
                                            }"
                                            >Activity Start</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'preCommStart'">
                                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPreCommDates }"
                                >
                                    {{ element.preCommStart | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="preCommFinish">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPreCommDates
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'preCommFinish',
                                            'columnPreCommFinish',
                                            'Pre-Comm Finish',
                                            columnPreCommFinish
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnPreCommFinish)
                                            }"
                                            >Activity Finish</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'preCommFinish'">
                                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPreCommDates }"
                                >
                                    {{ element.preCommFinish | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="barcode">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="scope === subsystemScopes.RFOWorkToGoSearch ? { hidecells: !showColumns.showPostRfoBarcode } : { hidecells: !showColumns.showBarcode }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'barcode',
                                                    getBarcodes,
                                                    'columnBarcode',
                                                    'Barcode',
                                                    columnBarcode
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnBarcode)
                                            }"
                                            > Barcode</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'barcode'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="scope === subsystemScopes.RFOWorkToGoSearch ? { hidecells: !showColumns.showPostRfoBarcode } : { hidecells: !showColumns.showBarcode }"
                                >
                                    {{ element.barcode }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="contractor">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{ hidecells: !showColumns.showContractor }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'contractor',
                                            getContractors,
                                            'columnContractor',
                                            'Contractor',
                                            columnContractor,
                                            showCounts(),
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnContractor)
                                            }"
                                            >{{scope === subsystemScopes.EX ? 'A-QVD/ITR Contractor' :
                                            scope === subsystemScopes.RFSUWorkToGoSearch ? 'ITR Contractor ' : 'Contractor'}}</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'contractor'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showContractor }">{{ element.contractor }}</td>
                            </ng-container>
                            <ng-container matColumnDef="isItrInCurrentWeek">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showColumns.isItrInCurrentWeek }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'isItrInCurrentWeek',
                                                    getItrInCurrentWeek,
                                                    'columnItrInCurrentWeek',
                                                    'ITR in Current Week?',
                                                    columnItrInCurrentWeek,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnItrInCurrentWeek
                                                )
                                            }"
                                            >ITR in Current Week?</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'isItrInCurrentWeek'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.isItrInCurrentWeek }"
                                >
                                    {{ booleanYesNo(element.isItrInCurrentWeek) }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="revision">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showColumns.showRevision
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'revision',
                                            getRevisions,
                                            'columnRevision',
                                            'Revision',
                                            columnRevision,
                                            showCounts()
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRevision)
                                            }"
                                            >Revision</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'revision'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{hidecells: !showColumns.showRevision}">{{ element.revision }}</td>
                            </ng-container>
                            <ng-container matColumnDef="docType">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showColumns.showDocType
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'docType',
                                            getDocTypes,
                                            'columnDocType',
                                            'DocType',
                                            columnDocType,
                                            showCounts()
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnDocType)
                                            }"
                                            >Doc Type</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'docType'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells: !showColumns.showDocType
                                    }"
                                >
                                    {{ element.docType }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="docType2">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showColumns.showDocType2
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'docType2',
                                            getDocType2s,
                                            'columnDocType2',
                                            'Doc Type 2',
                                            columnDocType2,
                                            showCounts()
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnDocType2)
                                            }"
                                            >Doc Type 2</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'docType2'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells: !showColumns.showDocType2
                                    }"
                                >
                                    {{ element.docType2 }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="priority">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: lockFilters || !showColumns.showPriority
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'priority',
                                                    getPriority,
                                                    'columnPriority',
                                                    'Staged Start-up Priority',
                                                    columnPriority,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPriority)
                                            }"
                                            >Priority</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'priority'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: lockFilters || !showColumns.showPriority }"
                                >
                                    {{ element.priority }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="exceptionPriority">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: lockFilters || !showColumns.showExceptionPriority
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'exceptionPriority',
                                                    getExceptionPriority,
                                                    'columnExceptionPriority',
                                                    'Exception Plant Start Up Impact',
                                                    columnExceptionPriority,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnExceptionPriority)
                                            }"
                                            >Exception Plant Start Up Impact</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'exceptionPriority'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: lockFilters || !showColumns.showExceptionPriority }"
                            >
                                {{ element.exceptionPriority }}
                                </td>
                                                           
                            </ng-container>
                            <ng-container matColumnDef="mcActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showMCActual
                                    }"
                                  
                                >
                                    <div class="sort-container center">
                                        <span
                                        class="hover-pointer"
                                        (click)="openHeaderDateFilter('mcActual', 'columnMCActual', 'MC Actual', columnMCActual)"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnMCActual)
                                            }"
                                            >MC Actual</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'mcActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showMCActual }">
                                    {{ element.mcActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showRFSUActual,
                                        'header-underline': showUnderlineForCalendarColumn(columnRFSUActual)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfsuActual',
                                            'columnRFSUActual',
                                            'RFSU Actual',
                                            columnRFSUActual
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>RFSU Actual</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showColumns.showRFSUActual                                     
                                    }"
                                >
                                    {{ element.rfsuActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfo">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showRFO
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'rfo',
                                                    getRFO,
                                                    'columnRFO',
                                                    'RFO',
                                                    columnRFO
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRFO)
                                            }"
                                            >RFO</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfo'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showRFO }"
                                >
                                    {{ element.rfo }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="waypointDescription">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showWaypoint
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'waypointDescription',
                                                    getWaypointDescription,
                                                    'columnWaypointDescription',
                                                    'Waypoint',
                                                    columnWaypointDescription
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnWaypointDescription)
                                            }"
                                            >Waypoint</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'waypointDescription'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showWaypoint }"
                                >
                                    {{ element.waypointDescription }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="areaBreakdown">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showAreaBreakdown
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'areaBreakdown',
                                                    getAreaBreakdownValues,
                                                    'columnAreaBreakdown',
                                                    'Area Breakdown',
                                                    columnAreaBreakdown
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAreaBreakdown)
                                            }"
                                            >Area Breakdown</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'areaBreakdown'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showAreaBreakdown }"
                                >
                                    {{ element.areaBreakdown }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="milestoneDescription">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showMilestone
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'milestoneDescription',
                                                    getMilestoneDescription,
                                                    'columnMilestoneDescription',
                                                    'Milestone',
                                                    columnMilestoneDescription
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnMilestoneDescription)
                                            }"
                                            >Milestone</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'milestoneDescription'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showMilestone }"
                                >
                                    {{ element.milestoneDescription }}
                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="milestoneTargetDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showMilestone && !showColumns.showWaypoint
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'milestoneTargetDate',
                                            'columnMilestoneTargetDate',
                                            'Milestone Target Date',
                                            columnMilestoneTargetDate
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(
                                                    columnMilestoneTargetDate
                                                )
                                            }"
                                            >Roadmap Target Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'milestoneTargetDate'">
                                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showMilestone && !showColumns.showWaypoint }"
                                >
                                    {{ element.milestoneTargetDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container> -->
                            <ng-container matColumnDef="activityName">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showActivityName
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'activityName',
                                                    getActivityName,
                                                    'columnActivityName',
                                                    'Activity Name',
                                                    columnActivityName,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnActivityName)
                                            }"
                                            >Activity Name</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'activityName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showActivityName }"
                                >
                                    {{ element.activityName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="activityID">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showActivityID
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'activityID',
                                                    getActivityID,
                                                    'columnActivityID',
                                                    'Activity ID',
                                                    columnActivityID,
                                                    false
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnActivityID)
                                            }"
                                            >Activity ID</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'activityID'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showActivityID }"
                                >
                                    {{ element.activityID }}
                                </td>
                            </ng-container> 
                            <ng-container matColumnDef="rfoplan">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showRFOPlan
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('rfoplan', 'columnRFOPlan', 'RFO Plan', columnRFOPlan)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFOPlan)
                                            }"
                                            >RFO Plan</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfoplan'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showRFOPlan }"
                                >
                                    {{ element.rfoPlan | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container> 
                            <ng-container matColumnDef="associatedNPW">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showAssociatedNPW  || ( scope !== subsystemScopes.ConstraintDetails
                                        && scope !== subsystemScopes.EX )
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'associatedNPW',
                                                    getAssociatedNPW,
                                                    'columnAssociatedNPW',
                                                    'Associated NPW',
                                                    columnAssociatedNPW,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAssociatedNPW)
                                            }"
                                            >Associated NPW</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'associatedNPW'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showAssociatedNPW }"
                                >
                                    <a *ngIf="element.associatedNPWChangeType != null" (click)="openNPWDetailsPage(element)">
                                        {{ element.associatedNPW}}
                                        &nbsp;-&nbsp;
                                        {{ element.associatedNPWChangeNumber }}
                                    </a>
                                    <span *ngIf="element.associatedNPWChangeType == null" >{{ element.associatedNPW }}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="associatedPLI">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showAssociatedPLI  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'associatedPLI',
                                                    getAssociatedPLI,
                                                    'columnAssociatedPLI',
                                                    'Associated PLI',
                                                    columnAssociatedPLI,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAssociatedPLI)
                                            }"
                                            >Associated PLI</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'associatedPLI'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th> 
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showAssociatedPLI }"
                                >                                
                                    {{ element.associatedPLI }}
                                </td>       
                            </ng-container>
                            <ng-container matColumnDef="associatedQVD">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showAssociatedQVD 
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'associatedQVD',
                                                    getAssociatedQVD,
                                                    'columnAssociatedQVD',
                                                    'Associated QVD',
                                                    columnAssociatedQVD,
                                                    false
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAssociatedQVD)
                                            }"
                                            >Associated QVD</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'associatedQVD'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th> 
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showAssociatedQVD }"
                                >                                
                                    {{ element.associatedQVD }}
                                </td>       
                            </ng-container>
                            
                            <ng-container matColumnDef="redStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'redStatus',
                                            getRedStatuses,
                                            'columnRedStatus',
                                            'Status',
                                            columnRedStatus,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRedStatus)
                                            }"
                                            >Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'redStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.redStatus }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mcdrConstrRedCat">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showColumns.showMcdrConstrRedCat
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'mcdrConstrRedCat',
                                            getMcdrConstrRedCats,
                                            'columnMcdrConstrRedCat',
                                            'MCDR Constr Redline Cat',
                                            columnMcdrConstrRedCat,
                                            true,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnMcdrConstrRedCat
                                                )
                                            }"
                                            >MCDR Constr Redline Category</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'mcdrConstrRedCat'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells: !showColumns.showMcdrConstrRedCat
                                    }"
                                >
                                    {{ element.mcdrConstrRedCat }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="changeId">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showColumns.showChangeId
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'changeId',
                                            getChangeIds,
                                            'columnChangeId',
                                            'Change ID',
                                            columnChangeId,
                                            true,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnChangeId)
                                            }"
                                            >Change ID</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'changeId'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells: !showColumns.showChangeId
                                    }"
                                >
                                    {{ element.changeId }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="containsChange">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showColumns.showContainsChange
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'containsChange',
                                            getContainsChange,
                                            'columnContainsChange',
                                            'Contains Change',
                                            columnContainsChange,
                                            true,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnContainsChange
                                                )
                                            }"
                                            >Contains Change</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'containsChange'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells: !showColumns.showContainsChange
                                    }"
                                >
                                    {{ element.containsChange }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="exceptionNumber">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="scope === subsystemScopes.RFOWorkToGoSearch ? {hidecells: !showColumns.showPostRfoExceptionNumber } : {hidecells: !showColumns.showExceptionNumber }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'exceptionNumber',
                                            getExceptionNumbers,
                                            'columnExceptionNumber',
                                            'ITR Exception Number',
                                            columnExceptionNumber,
                                            showCounts()
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnExceptionNumber
                                                )
                                            }"
                                            >{{ scope == subsystemScopes.ConstraintDetails ?  "ITR Exception Number" : scope == subsystemScopes.RFOWorkToGoSearch ? "Exceptions" : "Exception Number"  }}</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'exceptionNumber'">
                                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <div style="display: flex; justify-content: space-between;"> 
                                    <td mat-cell *matCellDef="let element" 
                                        [ngClass]="scope === subsystemScopes.RFOWorkToGoSearch ? {hidecells: !showColumns.showPostRfoExceptionNumber } : {hidecells: !showColumns.showExceptionNumber }"
                                        (click)="stopEvent($event)">
                                        <a href="{{ openExceptionPDFPage(element) }}" target="_blank">
                                            {{ element.exceptionNumber }}
                                        </a>
                                    </td>
                                </div>
                            </ng-container>
                            <ng-container matColumnDef="exceptionPhase">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: lockFilters || !showColumns.showExceptionPhase
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'exceptionPhase',
                                                    getExceptionPhases,
                                                    'columnExceptionPhase',
                                                    'Exception Phase',
                                                    columnExceptionPhase,
                                                    showCounts()
                                                )
                                            "
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnExceptionPhase
                                                )
                                            }"
                                            >Exception Phase</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'exceptionPhase'">
                                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: lockFilters || !showColumns.showExceptionPhase }">{{ element.exceptionPhase }}</td>
                            </ng-container>
                            <ng-container matColumnDef="exceptionStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'exceptionStatus',
                                            getExceptionStatus,
                                            'columnExceptionStatus',
                                            'Exception Status',
                                            columnExceptionStatus,
                                            showCounts()
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnExceptionStatus
                                                )
                                            }"
                                            >Exception Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'exceptionStatus'">
                                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.exceptionStatus }}</td>
                            </ng-container>
                            <ng-container matColumnDef="targetCompletionDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showTargetCompletionDate
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'targetCompletionDate',
                                            'columnTargetCompletionDate',
                                            'Target Completion Date',
                                            columnTargetCompletionDate
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(
                                                    columnTargetCompletionDate
                                                )
                                            }"
                                            >Target Completion Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'targetCompletionDate'">
                                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showTargetCompletionDate }"
                                >
                                    {{ element.targetCompletionDate }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells:
                                            (lockFilters &&
                                            this.filterStatuses != null &&
                                            this.filterStatuses.length === 1) ||
                                            !showColumns.showStatus
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'status',
                                            getStatuses,
                                            'columnStatus',
                                            'Status',
                                            columnStatus,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnStatus)
                                            }"
                                            >Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'status'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells:
                                            (lockFilters &&
                                            this.filterStatuses != null &&
                                            this.filterStatuses.length === 1) ||
                                            !showColumns.showStatus
                                    }"
                                >
                                    {{ element.status }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="statusDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showStatusDate
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'statusDate',
                                            'columnStatusDate',
                                            'Status Date',
                                            columnStatusDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnStatusDate)
                                            }"
                                            >Status Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'statusDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showStatusDate }"
                                >
                                    {{ element.statusDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="attachments">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: scope == subsystemScopes.PLI ? showColumns.showAttachments : !showColumns.showAttachments }"
                                    class="hover-pointer"
                                   
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'attachmentName',
                                                    getAttachments,
                                                    'ColumnAttachmentName',
                                                    'Attachments',
                                                    ColumnAttachmentName
                                                )
                                            "
                                >
                                
                                <div class="sort-container center">
                                    <span>Attachment</span>
                                    <div class="sort-icon" *ngIf="sortBy === 'attachmentName'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    class="attachments"
                                    [ngClass]="{ hidecells: scope == subsystemScopes.PLI ? showColumns.showAttachments : !showColumns.showAttachments }"
                                >
                                    <div class="uploaded-attachment-buttons" *ngFor="let att of element.attachments">
                                        <div
                                            class="lds-hourglass"
                                            *ngIf="uploadedAttachmentsActionInProgress.getValue(att.link) || false"
                                        ></div>
                                        <button
                                            *ngIf="!uploadedAttachmentsActionInProgress.getValue(att.link)"
                                            [title]="att.name"
                                            type="button"
                                            class="download-uploaded-attachment"
                                            (click)="download(att, $event)"
                                        ></button>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="materialReference">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showMaterialReference
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnMaterialReference
                                                )
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'materialReference',
                                                    getMaterialReferences,
                                                    'columnMaterialReference',
                                                    'MaterialReference',
                                                    columnMaterialReference
                                                )
                                            "
                                            >Material Reference</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'materialReference'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells: !showColumns.showMaterialReference
                                    }"
                                >
                                    <div>{{ element.materialReference }}</div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="classification">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showClassification
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnClassification
                                                )
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'classification',
                                                    getClassifications,
                                                    'columnClassification',
                                                    'Classification',
                                                    columnClassification
                                                )
                                            "
                                            >Classification</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'classification'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells: !showColumns.showClassification
                                    }"
                                >
                                    <div>{{ element.classification }}</div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="constraintFlag">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showConstraintFlag
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(
                                                    columnConstraintFlag
                                                )
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'constraintFlag',
                                                    getItrConstraintFlag,
                                                    'columnConstraintFlag',
                                                    'Constraint Flag',
                                                    columnConstraintFlag,
                                                    true
                                                )
                                            "
                                            >Constraint Flag</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'constraintFlag'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{
                                        hidecells: !showColumns.showConstraintFlag
                                    }"
                                    class="constraint-container"
                                >
                                    <div *ngIf="!element.isConstraintEdit" class="constraint-state">
                                        <span>{{ element.constraintFlag }}</span>
                                        <button
                                            mat-icon-button
                                            (click)="enterConstraintEditMode($event, element)"
                                            title="edit"
                                        >
                                            <mat-icon svgIcon="edit"></mat-icon>
                                        </button>
                                    </div>
                                    <div *ngIf="element.isConstraintEdit" class="constraint-state constraint-edit">
                                        <mat-form-field appearance="fill" (click)="stopEvent($event)">
                                            <mat-label>Constraint Flag</mat-label>
                                            <mat-select [(value)]="element.constraintFlag">
                                                <mat-option
                                                    *ngFor="let constraintFlag of constraintFlags"
                                                    [value]="constraintFlag"
                                                    >{{ constraintFlag }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div class="constraint-buttons">
                                            <button
                                                mat-icon-button
                                                (click)="saveConstraintUpdate($event, element)"
                                                title="accept"
                                            >
                                                <mat-icon>check_circle_outline</mat-icon>
                                            </button>
                                            <button
                                                mat-icon-button
                                                (click)="cancelConstraintUpdate($event, element)"
                                                title="cancel"
                                            >
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="itrConstraintCount">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintCount || (scope != subsystemScopes.BITRandCITR && scope != subsystemScopes.BITR && scope != subsystemScopes.CITR && scope != subsystemScopes.RFSUWorkToGoSearch )
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-filtered': showUnderlineForNumericColumn(columnITRConstraintCount)
                                            }"
                                            (click)="
                                                openHeaderNumericFilter(
                                                    'itrConstraintCount',
                                                    columnITRConstraintCount,
                                                    'ITR Constraints',
                                                    'columnITRConstraintCount'
                                                )
                                            "
                                            >ITR Constraints</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintCount'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintCount || (scope != subsystemScopes.BITRandCITR && scope != subsystemScopes.BITR && scope != subsystemScopes.CITR && scope !== subsystemScopes.RFSUWorkToGoSearch) }" (click)="goToITRConstraints($event,element)"
                                >   <span class="cursor-pointer center">
                                        <a>
                                            {{ element.itrConstraintCount }}
                                        </a>
                                    </span>                             

                                </td>
                            </ng-container>
                            <ng-container matColumnDef="exItrConstraintCount">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintCount 
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-filtered': showUnderlineForNumericColumn(columnITRConstraintCount)
                                            }"
                                            (click)="
                                                openHeaderNumericFilter(
                                                    'itrConstraintCount',
                                                    columnITRConstraintCount,
                                                    'ITR Constraints',
                                                    'columnITRConstraintCount'
                                                )
                                            "
                                            >ITR Constraints</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'exItrConstraintCount'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintCount } "
                                     (click)="goToITRConstraints($event,element) "
                                >   <span class="cursor-pointer center">
                                        <a>
                                            {{ element.itrConstraintCount }}
                                        </a>
                                    </span>                             

                                </td>
                            </ng-container>
                            <ng-container matColumnDef="itrConstraintTypeName">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintTypeName || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itrConstraintTypeName',
                                                    getITRConstraintTypeName,
                                                    'columnITRConstraintTypeName',
                                                    'ITR Constraint Type',
                                                    columnITRConstraintTypeName,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnITRConstraintTypeName)
                                            }"
                                            >ITR Constraint Type</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintTypeName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintTypeName  || scope !== subsystemScopes.ConstraintDetails }"
                                >
                                    {{ element.itrConstraintTypeName }}                                                                   
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="itrConstraintDueDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintDueDate  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'itrConstraintDueDate',
                                            'columnITRConstraintDueDate',
                                            'Constraint Forecast Closure Date',
                                            columnITRConstraintDueDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnITRConstraintDueDate)
                                            }"
                                            >Constraint Forecast Closure Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintDueDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintDueDate  || scope !== subsystemScopes.ConstraintDetails }"
                                >
                                    {{ element.itrConstraintDueDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                         
                            <ng-container matColumnDef="itrConstraintRaisedDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintRaisedDate  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'itrConstraintRaisedDate',
                                            'columnITRConstraintRaisedDate',
                                            'ITR Constraint Raised Date',
                                            columnITRConstraintRaisedDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnITRConstraintRaisedDate)
                                            }"
                                            >ITR Constraint Raised Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintRaisedDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintRaisedDate  || scope !== subsystemScopes.ConstraintDetails }"
                                >
                                    {{ element.itrConstraintRaisedDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="itrConstraintStatusDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintStatusDate  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'itrConstraintStatusDate',
                                            'columnITRConstraintStatusDate',
                                            'ITR Constraint Status Date',
                                            columnITRConstraintStatusDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnITRConstraintStatusDate)
                                            }"
                                            >ITR Constraint Status Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintStatusDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintStatusDate  || scope !== subsystemScopes.ConstraintDetails }"
                                >
                                    {{ element.itrConstraintStatusDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container> -->
                            <ng-container matColumnDef="fmRorPOETADate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showFMRorPOETADate  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'fmRorPOETADate',
                                            'columnFMRorPOETADate',
                                            'FMR or PO ETA Date',
                                            columnFMRorPOETADate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnFMRorPOETADate)
                                            }"
                                            >FMR or PO ETA Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'fmRorPOETADate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showFMRorPOETADate  || scope !== subsystemScopes.ConstraintDetails }"
                                >
                                {{ element.fmRorPOETADate != null ? ( element.fmRorPOETADate | date: 'd/MMM/yy' ) : '' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="eta">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showFmrLatestETA 
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'eta',
                                            'columnETA',
                                            'ETA',
                                            columnETA
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnETA)
                                            }"
                                            >ETA</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'eta'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showFmrLatestETA }"
                                >
                                {{ element.eta != null ? ( element.eta | date: 'd/MMM/yy' ) : '' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="materialsTotalCount">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showMaterialsTotalCount        
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-filtered': showUnderlineForNumericColumn(columnMaterialsTotalCount)
                                            }"
                                            (click)="
                                                openHeaderNumericFilter(
                                                    'materialsTotalCount',
                                                    columnMaterialsTotalCount,
                                                    'Materials Total',
                                                    'columnMaterialsTotalCount'
                                                )
                                            "
                                            >Materials Total</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'materialsTotalCount'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showMaterialsTotalCount }"  (click)="goToPLIDetails($event,element)"
                                >   <span class="cursor-pointer center">
                                    <div *ngIf="element.itrConstraintTypeName !== punchItemConstraintType && scope === subsystemScopes.ConstraintDetails; else withlink">                                       
                                    <span [innerHTML]="element.materialsTotalCount"></span>
                                    </div>                          
                                    <ng-template #withlink>
                                        <a>
                                            {{ element.materialsTotalCount }}
                                        </a>
                                    </ng-template>            
                                    </span>                             

                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="itrConstraintResponsibleUser">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintResponsibleUser  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itrConstraintResponsibleUser',
                                                    getITRConstraintResponsibleUser,
                                                    'columnITRConstraintResponsibleUser',
                                                    'ITR Constraint Responsible User',
                                                    columnITRConstraintResponsibleUser,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnITRConstraintResponsibleUser)
                                            }"
                                            >ITR Constraint Responsible User</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintResponsibleUser'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintResponsibleUser  || scope !== subsystemScopes.ConstraintDetails}"
                                >
                                    {{ element.itrConstraintResponsibleUser }}
                                </td>
                            </ng-container> -->
                            <ng-container matColumnDef="itrConstraintResponsibleGroup">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintResponsibleGroup  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itrConstraintResponsibleGroup',
                                                    getITRConstraintResponsibleGroup,
                                                    'columnITRConstraintResponsibleGroup',
                                                    'ITR Constraint Responsible Group',
                                                    columnITRConstraintResponsibleGroup,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnITRConstraintResponsibleGroup)
                                            }"
                                            >ITR Constraint Responsible Group</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintResponsibleGroup'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintResponsibleGroup  || scope !== subsystemScopes.ConstraintDetails}"
                                >
                                    {{ element.itrConstraintResponsibleGroup }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="itrConstraintDescription">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintDescription  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itrConstraintDescription',
                                                    getITRConstraintDescription,
                                                    'columnITRConstraintDescription',
                                                    'ITR Constraint Description',
                                                    columnITRConstraintDescription,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnITRConstraintDescription)
                                            }"
                                            >ITR Constraint Description</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintDescription'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintDescription  || scope !== subsystemScopes.ConstraintDetails}"
                                >
                                    {{ element.itrConstraintDescription }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="itrConstraintStatus">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintStatus  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itrConstraintStatus',
                                                    getITRConstraintStatus,
                                                    'columnITRConstraintStatus',
                                                    'ITR Constraint Status',
                                                    columnITRConstraintStatus,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnITRConstraintStatus)
                                            }"
                                            >ITR Constraint Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintStatus  || scope !== subsystemScopes.ConstraintDetails}"
                                >
                                    {{ element.itrConstraintStatus }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="itrConstraintId">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintId  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itrConstraintId',
                                                    getITRConstraintId,
                                                    'columnITRConstraintId',
                                                    'ITR Constraint ID',
                                                    columnITRConstraintId,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnITRConstraintId)
                                            }"
                                            >Constraint ID</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintId'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintId  || scope !== subsystemScopes.ConstraintDetails}"
                                >
                                    {{ element.itrConstraintId == null ? "new" : "SC-CON-" + element.itrConstraintId }}
                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="itrConstraintAdditionalResponsibleUser">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintAdditionalResponsibleUser  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itrConstraintAdditionalResponsibleUser',
                                                    getITRConstraintAdditionalResponsibleUser,
                                                    'columnITRConstraintAdditionalResponsibleUser',
                                                    'Additional Responsible User',
                                                    columnITRConstraintAdditionalResponsibleUser,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnITRConstraintAdditionalResponsibleUser)
                                            }"
                                            >Additional Responsible User</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintAdditionalResponsibleUser'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintAdditionalResponsibleUser  || scope !== subsystemScopes.ConstraintDetails }"
                                >
                                    {{ element.itrConstraintAdditionalResponsibleUser }}
                                </td>
                            </ng-container> -->
                            <ng-container matColumnDef="itrConstraintRaisedByUser">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintRaisedByUser  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itrConstraintRaisedByUser',
                                                    getITRConstraintRaisedByUser,
                                                    'columnITRConstraintRaisedByUser',
                                                    'ITR Constraint RaisedBy User',
                                                    columnITRConstraintRaisedByUser,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnITRConstraintRaisedByUser)
                                            }"
                                            >ITR Constraint Raised By</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintRaisedByUser'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintRaisedByUser  || scope !== subsystemScopes.ConstraintDetails }"
                                >
                                    {{ element.itrConstraintRaisedByUser }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="itrConstraintRaisedByTeam">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showITRConstraintRaisedByTeam  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itrConstraintRaisedByTeam',
                                                    getITRConstraintRaisedByTeam,
                                                    'columnITRConstraintRaisedByTeam',
                                                    'ITR Constraint RaisedBy Team',
                                                    columnITRConstraintRaisedByTeam,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnITRConstraintRaisedByTeam)
                                            }"
                                            >ITR Constraint Raised By Team</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'itrConstraintRaisedByTeam'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showITRConstraintRaisedByTeam  || scope !== subsystemScopes.ConstraintDetails }"
                                >
                                    {{ element.itrConstraintRaisedByTeam }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fmRorPO">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showFMRorPO  || ( scope !== subsystemScopes.ConstraintDetails
                                        && scope !== subsystemScopes.EX )
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'fmRorPO',
                                                    getFMRorPO,
                                                    'columnFMRorPO',
                                                    'FMR or PO',
                                                    columnFMRorPO
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnFMRorPO)
                                            }"
                                            >FMR or PO</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'fmRorPO'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showFMRorPO  || ( scope !== subsystemScopes.ConstraintDetails
                                        && scope !== subsystemScopes.EX )}"
                                >
                                    {{ element.fmRorPO }}
                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="waypointRequired">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showWaypointRequired  || scope !== subsystemScopes.ConstraintDetails
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'waypointRequired',
                                                    getWaypointRequired,
                                                    'columnWaypointRequired',
                                                    'Required for Milestone/Waypoint',
                                                    columnWaypointRequired,
                                                    true,
                                                    false,
                                                    false,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnWaypointRequired)
                                            }"
                                            >Required for Milestone/Waypoint</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'waypointRequired'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showWaypointRequired  || scope !== subsystemScopes.ConstraintDetails }"
                                >
                                    {{ element.waypointRequired }}
                                </td>
                            </ng-container> -->
                            <ng-container matColumnDef="manhours">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showManhours
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="openHeaderNumericFilter('manhours', columnManhours, 'Man hours','columnManhours')"
                                            >Manhours</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'manhours'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    class="center"
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showManhours }"
                                >
                                    {{ element.manhours }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="workAreaName">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showWorkArea
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'workAreaName',
                                                    getWorkAreaName,
                                                    'columnWorkAreaName',
                                                    'Work Area',
                                                    columnWorkAreaName,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnWorkAreaName)
                                            }"
                                            >Work Area</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'workAreaName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showWorkArea }">
                                    {{ element.workAreaName }}
                                </td>
                            </ng-container>                            
                            <ng-container matColumnDef="workOrderNum">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showWorkOrderNum
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'workOrderNum',
                                                    getWorkOrderNum,
                                                    'columnWorkOrderNum',
                                                    'Work Order Number',
                                                    columnWorkOrderNum,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnWorkOrderNum)
                                            }"
                                            >Work Order Number</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'workOrderNum'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showWorkOrderNum }">
                                    {{ element.workOrderNum }}
                                </td>
                            </ng-container>                            
                            <ng-container matColumnDef="eTransferNumber">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showETransferNumber
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'eTransferNum',
                                                    getETransferNumber,
                                                    'columnETransferNumber',
                                                    'eTransfer Number',
                                                    columnETransferNumber,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnETransferNumber)
                                            }"
                                            >eTransfer Number</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'eTransferNum'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showETransferNumber }">
                                    {{ element.eTransferNum }}
                                </td>
                            </ng-container>  
                            <!-- <ng-container matColumnDef="latestComment">
                                <th mat-header-cell disableClear *matHeaderCellDef app-col-resize
                                
                                >
                                    <div class="sort-container center">
                                        <span                                        
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnComments)                                              
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'latestComment',
                                                    getComments,
                                                    'columnComments',
                                                    'Comment',
                                                    columnComments,
                                                    false,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                        >
                                            {{ scope === subsystemScopes.ConstraintDetails ? 'Constraint Comment' : 
                                            (scope === subsystemScopes.BITRandCITR || scope === subsystemScopes.BITR || scope === subsystemScopes.CITR ) ? 'ITR Comment' : 
                                            (scope === subsystemScopes.EX) ? 'ITR/PLI Comment' : 'Comment' }}
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'latestComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" (click)="openCommentPopup(element, $event)"
                                    
                                >
                                    <div *ngIf="element.latestComment && !isReadOnly; else notShow">
                                        <a
                                            ><span
                                                [innerHTML]="element.latestComment | formatMentions: element.mentions"
                                            ></span
                                        ></a>
                                    </div>
                                    <div *ngIf="isReadOnly">
                                        <span
                                            [innerHTML]="element.latestComment | formatMentions: element.mentions"
                                        ></span>
                                    </div>
                                    <ng-template #notShow>
                                        <button
                                            type="button"
                                            *ngIf="!isReadOnly"
                                            mat-stroked-button
                                            class="add-comment"
                                        >
                                            Add
                                        </button>
                                    </ng-template>
                                </td>
                            </ng-container> -->
                            <ng-container matColumnDef="latestITRComment">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showLatestITRComment || scope !== subsystemScopes.ConstraintDetails
                                    }"

                                    (click)="
                                                openHeaderCheckListFilter(
                                                    'latestITRComment',
                                                    getITRConstraintComment,
                                                    'columnLatestITRComment',
                                                    'latestITRComment',
                                                    columnLatestITRComment                                                    
                                                )
                                            "
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnLatestITRComment)
                                            }"
                                            >ITR Comment</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'latestITRComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showLatestITRComment || scope !== subsystemScopes.ConstraintDetails }">
                                    {{ element.latestITRComment }}
                                </td>
                            </ng-container> 
                            <ng-container matColumnDef="associatedComment">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showAssociatedComment || scope !== subsystemScopes.ConstraintDetails
                                    }"

                                    (click)="
                                                openHeaderCheckListFilter(
                                                    'associatedComment',
                                                    getAssociatedComment,
                                                    'columnAssociatedComments',
                                                    'Associated Comment',
                                                    columnAssociatedComments                                                    
                                                )
                                            "
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAssociatedComments)
                                            }"
                                            >Associated Comment</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'associatedComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showAssociatedComment || scope !== subsystemScopes.ConstraintDetails }">
                                    {{ element.associatedComment }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="aqvdContractor">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showAQVDContractor || scope !== subsystemScopes.ConstraintDetails
                                    }"

                                    (click)="
                                                openHeaderCheckListFilter(
                                                    'aqvdContractor',
                                                    getAQVDContractor,
                                                    'columnAQVDContractor',
                                                    'AQVD Contractor',
                                                    columnAQVDContractor                                                    
                                                )
                                            "
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAQVDContractor)
                                            }"
                                            >AQVD Contractor</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'aqvdContractor'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showAQVDContractor || scope !== subsystemScopes.ConstraintDetails }">
                                    {{ element.aqvdContractor }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="pliActionedBy">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPLIActionedBy || scope !== subsystemScopes.ConstraintDetails
                                    }"

                                    (click)="
                                                openHeaderCheckListFilter(
                                                    'pliActionedBy',
                                                    getPLIActionBy,
                                                    'ColumnPLIActionedBy',
                                                    'PLI Action By',
                                                    ColumnPLIActionedBy                                                    
                                                )
                                            "
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(ColumnPLIActionedBy)
                                            }"
                                            >PLI Action By</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'pliActionedBy'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showPLIActionedBy || scope !== subsystemScopes.ConstraintDetails }">
                                    {{ element.pliActionedBy }}
                                </td>
                            </ng-container> 
                            <ng-container matColumnDef="latestExceptionComment">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showLatestExceptionComment 
                                    }"

                                    (click)="
                                                openHeaderCheckListFilter(
                                                    'latestExceptionComment',
                                                    getExceptionsComment,
                                                    'columnLatestExceptionComment',
                                                    'Exception Comment',
                                                    columnLatestExceptionComment,
                                                    false,
                                                    true,
                                                    false,
                                                    true                                                    
                                                )
                                            "
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnLatestExceptionComment)
                                            }"
                                            >Exception Comment</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'latestExceptionComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">

                                    <div style="display: flex; justify-content: space-between;"> 
                                        <div (click)="openExceptionCommentPopup(element, $event)"  class="comment-description">
                                            <div *ngIf="element.latestExceptionComment && !isReadOnly; else notShow">
                                                <a
                                                    ><span
                                                        [innerHTML]="element.latestExceptionComment | formatMentions: element.mentions"
                                                    ></span
                                                ></a>
                                            </div>
                                            <div *ngIf="isReadOnly">
                                                <span
                                                    [innerHTML]="element.latestExceptionComment | formatMentions: element.mentions"
                                                ></span>
                                            </div>
                                            <ng-template #notShow>
                                                <button
                                                    type="button"
                                                    *ngIf="!isReadOnly"
                                                    mat-stroked-button
                                                    class="add-comment"
                                                >
                                                    Add
                                                </button>
                                            </ng-template>
                                        </div>

                                        <div
                                                        [ngClass]="{ notactive: !element.latestExceptionComment, active: element.latestExceptionComment,
                                                                hidecells: scope !== subsystemScopes.AQVD && scope !== subsystemScopes.BITR &&  
                                                                scope !== subsystemScopes.CITR && scope !== subsystemScopes.BITRandCITR && 
                                                                scope !== subsystemScopes.PLI && scope !== subsystemScopes.EX}"
                                                        class="comment-hist"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            width="24"
                                                            (click)="openExceptionHistoryPopup(element);$event.stopPropagation()"
                                                        >
                                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                                            <path
                                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                                            />
                                                        </svg>
                                        </div>
                                    </div>
                                </td>
                            </ng-container> 
                            <ng-container matColumnDef="latestNBResponsiblePersonComment">
                                <th 
                                mat-header-cell 
                                disableClear 
                                *matHeaderCellDef 
                                app-col-resize
                                [ngClass]="{
                                    hidecells: !showColumns.showNBResponsiblePersonComment                                                                            
                               }"
                                >
                                    <div class="sort-container center">
                                        <span                                        
                                            class="hover-pointer"
                                            [ngClass]="{                                                
                                                'header-underline': showUnderlineForCheckListColumn(columnNBResponsiblePersonComments)                                              
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'latestNBResponsiblePersonComment',
                                                    getNBResponsiplePersonComments,
                                                    'columnNBResponsiblePersonComments',
                                                    'Responsible Person',
                                                    columnNBResponsiblePersonComments,
                                                    false,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                        >
                                          Responsible Person
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'latestNBResponsiblePersonComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                mat-cell 
                                *matCellDef="let element"
                                [ngClass]="{
                                    hidecells: !showColumns.showNBResponsiblePersonComment                                             
                               }"
                                > 
                                
                                    <div style="display: flex; justify-content: space-between;"> 
                                        <div
                                            (click)="openNBResponsiblePersonCommentPopup(element, $event)"
                                            class="comment-description"
                                        >
                                    
                                            <div *ngIf="element.latestNBResponsiblePersonComment && showResponsibleComment; else notShow">
                                                <a
                                                    ><span
                                                        [innerHTML]="element.latestNBResponsiblePersonComment | formatMentions: element.mentions"
                                                    ></span
                                                ></a>
                                            </div>
                                            <div *ngIf="!showResponsibleComment">
                                                <span
                                                    [innerHTML]="element.latestNBResponsiblePersonComment | formatMentions: element.mentions"
                                                ></span>
                                            </div>
                                            <ng-template #notShow>
                                                <button
                                                    type="button"
                                                    *ngIf="showResponsibleComment"
                                                    mat-stroked-button
                                                    class="add-comment"
                                                >
                                                    Add
                                                </button>
                                            </ng-template>
                                        </div>
                                        
                                    </div>
                                
                                </td>
                            </ng-container> 

                            <!-- <ng-container matColumnDef="o3ConstraintID">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showO3ConstraintID 
                                    }"

                                    (click)="
                                                openHeaderCheckListFilter(
                                                    'o3ConstraintID',
                                                    getO3ConstraintId,
                                                    'columnO3ConstraintID',
                                                    'O3 Constraint ID',
                                                    columnO3ConstraintID,
                                                    false,
                                                    true,
                                                    false,
                                                    false                                                    
                                                )
                                            "
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnO3ConstraintID)
                                            }"
                                            >O3 Constraint ID</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'o3ConstraintID'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showO3ConstraintID }">

                                    <div style="display: flex; justify-content: space-between;"> 
                                        <div (click)="openO3ConstraintPopup(element, $event)"  class="comment-description">
                                            <div *ngIf="element.o3ConstraintId && !isReadOnly; else notShow">
                                                <a
                                                    ><span
                                                        [innerHTML]="element.o3ConstraintId | formatMentions: element.mentions"
                                                    ></span
                                                ></a>
                                            </div>
                                            <div *ngIf="isReadOnly">
                                                <span
                                                    [innerHTML]="element.o3ConstraintId | formatMentions: element.mentions"
                                                ></span>
                                            </div>
                                            <ng-template #notShow>
                                                <button
                                                    type="button"
                                                    *ngIf="!isReadOnly"
                                                    mat-stroked-button
                                                    class="add-comment"
                                                >
                                                    Add
                                                </button>
                                            </ng-template>
                                        </div>
                                    </div>
                                </td>

                            </ng-container>   -->
                            
                            <ng-container matColumnDef="latestComment" stickyEnd>
                                <th mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                                    <div class="sort-container center">
                                        <span                                        
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnComments)                                              
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'latestComment',
                                                    getComments,
                                                    'columnComments',
                                                    'Comment',
                                                    columnComments,
                                                    false,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                        >
                                            {{ scope === subsystemScopes.ConstraintDetails ? 'Constraint Comment' : 
                                            (scope === subsystemScopes.BITRandCITR || scope === subsystemScopes.BITR || scope === subsystemScopes.CITR ) ? 'ITR Comment' : 
                                            (scope === subsystemScopes.EX) ? 'Affected Item Comment' : 'Comment' }}
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'latestComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element"> 
                                
                                    <div style="display: flex; justify-content: space-between;"> 
                                        <div
                                            (click)="openCommentPopup(element, $event)"
                                            class="comment-description"
                                        >
                                    
                                            <div *ngIf="element.latestComment && !isReadOnly; else notShow">
                                                <a
                                                    ><span
                                                        [innerHTML]="element.latestComment | formatMentions: element.mentions"
                                                    ></span
                                                ></a>
                                            </div>
                                            <div *ngIf="isReadOnly">
                                                <span
                                                    [innerHTML]="element.latestComment | formatMentions: element.mentions"
                                                ></span>
                                            </div>
                                            <ng-template #notShow>
                                                <button
                                                    type="button"
                                                    *ngIf="!isReadOnly"
                                                    mat-stroked-button
                                                    class="add-comment"
                                                >
                                                    Add
                                                </button>
                                            </ng-template>
                                        </div>
                                        <div
                                                    [ngClass]="{ notactive: !element.latestComment, active: element.latestComment,
                                                            hidecells: scope !== subsystemScopes.AQVD && scope !== subsystemScopes.BITR &&  
                                                            scope !== subsystemScopes.CITR && scope !== subsystemScopes.BITRandCITR && 
                                                            scope !== subsystemScopes.PLI && scope !== subsystemScopes.EX && scope !== subsystemScopes.RFOWorkToGoSearch}"
                                                    class="comment-hist"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        (click)="openHistoryPopup(element);$event.stopPropagation()"
                                                    >
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path
                                                            d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                                        />
                                                    </svg>
                                        </div>
                                    </div>
                                
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="pliForecastClosureDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPLIForecastClosureDate  || scope !== subsystemScopes.PLI,
                                        'header-filtered': showUnderlineForCalendarColumn(columnPLIForecastClosureDate)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                    openHeaderDateFilter(
                                        'pliForecastClosureDate',
                                        'columnPLIForecastClosureDate',
                                        'PLI Forecast Closure Date',
                                        columnPLIForecastClosureDate,
                                        true
                                    )
                                "
                                >
                                    <div class="sort-container center">
                                        <span>PLI Forecast Closure Date</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'pliForecastClosureDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    (click)="openPLIForecastClosureDatepicker($event,element, 'pliForecastClosureDate')"
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        'cursor-pointer':
                                            isAdmin || isPliForecastClosureDateUpdateAllowed,
                                        'row-underline': element.itrConstraintDueDate != null ? true : false
                                    }"
                                    (contextmenu)="forecastDateHistory($event, element, 'pliForecastClosureDate')"
                                >
                                    {{ element.itrConstraintDueDate != null ? (element.itrConstraintDueDate | date: 'd/MMM/yy') : (element.pliForecastClosureDate | date: 'd/MMM/yy') }}
                                        <button
                                            *ngIf="element.itrConstraintDueDate != null"
                                            mat-icon-button
                                            title="{{
                                                'Associated Constraint Forecast Closure Date.'
                                            }}"
                                        >
                                            <mat-icon>info</mat-icon>
                                        </button>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="rfoActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoActualDate,
                                        'header-underline': showUnderlineForCalendarColumn(columnRFOActual)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfoActual',
                                            'columnRFOActual',
                                            'RFO Actual',
                                            columnRFOActual
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>RFO Actual</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'rfoActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoActualDate                                     
                                    }"
                                >
                                    {{ element.rfoActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="qvdNumber">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoQVDNumber
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'qvdNumber',
                                                    getQVDNumbers,
                                                    'columnQVDNumber',
                                                    'ITR',
                                                    columnQVDNumber,
                                                    true,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnQVDNumber)
                                            }"
                                            >ITR</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'qvdNumber'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <!-- <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoQVDNumber }"
                                >
                                    {{ element.qvdNumber }}
                                </td> -->
                                <td mat-cell *matCellDef="let element" 
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoQVDNumber }"
                                    (click)="openItemDetailsPage(element, 'ITR')">
                                    <a>
                                        {{ element.qvdNumber }}
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="tagNumberPostRFO">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoTagNumber
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'tagNumber',
                                                    getPostRFOTagNumbers,
                                                    'columnTagNumber',
                                                    'Tag Number',
                                                    columnTagNumber,
                                                    false,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnTagNumber)
                                            }"
                                            >Tag Number</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'tagNumber'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoTagNumber }"
                                >
                                    {{ element.tagNumber }}
                                </td>
                            </ng-container>                           

                            <ng-container matColumnDef="pli">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoPli
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'pli',
                                                    getPlis,
                                                    'columnPli',
                                                    'PLI',
                                                    columnPli,
                                                    true,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPli)
                                            }"
                                            >PLI (A, B, C, D - Combined)</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'pli'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <!-- <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoPli }"
                                >
                                    {{ element.pli }}
                                </td> -->
                                <td mat-cell *matCellDef="let element" 
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoPli }"
                                    (click)="openItemDetailsPage(element, 'PLI')">
                                    <a>
                                        {{ element.pli }}
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="pliCreatedDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoPliCreatedDate,
                                        'header-underline': showUnderlineForCalendarColumn(columnPliCreatedDate)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'pliCreatedDate',
                                            'columnPliCreatedDate',
                                            'PLI Creation Date',
                                            columnPliCreatedDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>PLI Creation Date</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'pliCreatedDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoPliCreatedDate                                     
                                    }"
                                >
                                    {{ element.pliCreatedDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="npw">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoNpw
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'npw',
                                                    getNpws,
                                                    'columnNpw',
                                                    'NPW',
                                                    columnNpw,
                                                    false,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnNpw)
                                            }"
                                            >NPW</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'npw'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <!-- <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoNpw }"
                                >
                                    {{ element.npw }}
                                </td> -->
                                <td mat-cell *matCellDef="let element" 
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoNpw }"
                                    (click)="openItemDetailsPage(element, 'NPW')">
                                    <a>
                                        {{ element.npw }}
                                    </a>
                                </td>                                  
                            </ng-container>

                            <ng-container matColumnDef="npwIssueDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoNpwIssueDate,
                                        'header-underline': showUnderlineForCalendarColumn(columnNpwIssueDate)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'npwIssueDate',
                                            'columnNpwIssueDate',
                                            'NPW Issued Date',
                                            columnNpwIssueDate
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>NPW Issued Date</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'npwIssueDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoNpwIssueDate                                     
                                    }"
                                >
                                    {{ element.npwIssueDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="pascr">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoPascr
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'pascr',
                                                    getPascrs,
                                                    'columnPascr',
                                                    'PAS CR',
                                                    columnPascr,
                                                    false,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPascr)
                                            }"
                                            >PAS CR</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'pascr'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoPascr }"
                                >
                                    {{ element.pascr }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="redline">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoRedline
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'redline',
                                                    getRedlines,
                                                    'columnRedline',
                                                    'Redline',
                                                    columnRedline,
                                                    false,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRedline)
                                            }"
                                            >Redline</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'redline'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <!-- <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoRedline }"
                                >
                                    {{ element.redline }}
                                </td> -->
                                <td mat-cell *matCellDef="let element" 
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoRedline }"
                                    (click)="openItemDetailsPage(element, 'Redline')">
                                    <a>
                                        {{ element.redline }}
                                    </a>
                                </td>                                
                            </ng-container>

                            <ng-container matColumnDef="responsiblePersonOrGroup">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRfoResponsiblePersonOrGroup
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'responsiblePersonOrGroup',
                                            getResponsiblePersonOrGroup,
                                            'columnResponsiblePersonOrGroup',
                                            'Responsible Person/Group',
                                            columnResponsiblePersonOrGroup,
                                            false,
                                            true,
                                            false,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnResponsiblePersonOrGroup)
                                            }"
                                            >Responsible Person/Group</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'responsiblePersonOrGroup'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                    <td 
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showPostRfoResponsiblePersonOrGroup }"
                                    (contextmenu)="responsiblePersonOrGroupUpdateHistory($event,element)"
                            >                                    
                                    <mat-select                               
                                    matNativeControl
                                    [(value)]="element.responsiblePersonOrGroup" 
                                    (selectionChange)="updateResponsiblePersonOrGroup($event, cloneElement(element))"                                
                                    >
                                        <mat-option class="multiline-mat-option" *ngFor="let responsiblePersonOrGroup of element.responsiblePersonOrGroupsForPostRFOWork" [value]="responsiblePersonOrGroup">
                                            {{ responsiblePersonOrGroup }}
                                        </mat-option>
                                        <mat-option class="multiline-mat-option" *ngIf="!(element.responsiblePersonOrGroupsForPostRFOWork).includes(element.responsiblePersonOrGroup)" [value]="element.responsiblePersonOrGroup">
                                            {{ element.responsiblePersonOrGroup }}
                                        </mat-option>
                                    </mat-select>
                            </td>
                            </ng-container>
                        
                            <ng-container matColumnDef="postRFOWorkExpectedClosureDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showPostRFOWorkExpectedClosureDate,
                                        'header-filtered': showUnderlineForCalendarColumn(columnPostRFOWorkExpectedClosureDate)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                    openHeaderDateFilter(
                                        'postRFOWorkExpectedClosureDate',
                                        'columnPostRFOWorkExpectedClosureDate',
                                        'Expected Closure Date',
                                        columnPostRFOWorkExpectedClosureDate,
                                        true
                                    )
                                "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnPostRFOWorkExpectedClosureDate)
                                            }"
                                            >Expected Closure Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'postRFOWorkExpectedClosureDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    (click)="openPostRFOWorkExpectedClosureDatepicker($event,element, 'postRFOWorkExpectedClosureDate')"
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        'cursor-pointer':
                                            isAdmin || isPostRFOWorkExpectedClosureDateUpdateAllowed,
                                        'row-underline': element.postRFOWorkExpectedClosureDate != null ? true : false,
                                        hidecells: !showColumns.showPostRFOWorkExpectedClosureDate
                                    }"
                                    (contextmenu)="expectedClosureDateHistory($event, element)"
                                >
                                    {{ element.postRFOWorkExpectedClosureDate | date: 'd/MMM/yy' }}
                                    <button
                                        *ngIf="!element.postRFOWorkExpectedClosureDate && !isReadOnly"
                                        type="button"
                                        mat-icon-button
                                    >
                                        <mat-icon>calendar_today</mat-icon>
                                    </button>
                                </td>
                            </ng-container>    
                            
                            <ng-container matColumnDef="itemComment">
                                <th mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                                    <div class="sort-container center">
                                        <span                                        
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnItemComment)                                              
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'itemComment',
                                                    getItemComments,
                                                    'columnItemComment',
                                                    'Original Source Comment',
                                                    columnItemComment,
                                                    false,
                                                    true,
                                                    false,
                                                    true
                                                )
                                            "
                                        >
                                            Original Source Comment
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'itemComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element"> 
                                
                                    <div style="display: flex; justify-content: space-between;"> 
                                        <div
                                            class="comment-description"
                                        >

                                            <div *ngIf="!isReadOnly">
                                                <a
                                                    ><span
                                                        [innerHTML]="element.itemComment | formatMentions: element.mentions"
                                                    ></span
                                                ></a>
                                            </div>
                                            <div *ngIf="isReadOnly">
                                                <span
                                                    [innerHTML]="element.itemComment | formatMentions: element.mentions"
                                                ></span>
                                            </div>
                                        </div>
                                        <div
                                                    [ngClass]="{ notactive: !element.itemComment, active: element.itemComment,
                                                            hidecells: scope !== subsystemScopes.AQVD && scope !== subsystemScopes.BITR &&  
                                                            scope !== subsystemScopes.CITR && scope !== subsystemScopes.BITRandCITR && 
                                                            scope !== subsystemScopes.PLI && scope !== subsystemScopes.EX && scope !== subsystemScopes.RFOWorkToGoSearch}"
                                                    class="comment-hist"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        (click)="openItemCommentHistoryPopup(element);$event.stopPropagation()"
                                                    >
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path
                                                            d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                                        />
                                                    </svg>
                                        </div>
                                    </div>
                                
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="priorityManualOverride">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showColumns.showPriorityManualOverride }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'priorityManualOverride',
                                            getManualPriorities,
                                            'columnPriorityManualOverride',
                                            'Priority Manual Override',
                                            columnPriorityManualOverride,
                                            true,
                                            true,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPriorityManualOverride)
                                            }"
                                            >Priority Manual Override</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'PriorityManualOverride'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngClass]="{ hidecells: !showColumns.showPriorityManualOverride }"
                                >                                    
                                        <mat-select                               
                                        matNativeControl
                                        [value]="element.priorityManualOverride" 
                                        (selectionChange)="updateManualPriority($event, cloneElement(element))"                                
                                        >
                                            <mat-option *ngFor="let manualPriority of Manualpriorities" [value]="manualPriority">
                                                {{ manualPriority }}
                                            </mat-option>
                                        </mat-select>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns"
                                (click)="openDetailsPage(row)"
                            ></tr>
                        </table>
                    </app-mat-table-sticky-header>
                </div>
            </div>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 50]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>
