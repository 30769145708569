

import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { ITRConstraintsDeleteFilter, ITRConstraintsDeletePagination, ITRConstraintsState } from './model';
import { ITRConstraintsActions, ITRConstraintsActionTypes } from './actions';

const initialState: ITRConstraintsState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new ITRConstraintsDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new ITRConstraintsDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: ITRConstraintsActions): ITRConstraintsState {
    switch (action.type) {
        case ITRConstraintsActionTypes.ITRConstraintsUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsDeleteRecordsSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsDeleteRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsDeleteFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        [action.payload.key]: Array.isArray(state.deleteFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case ITRConstraintsActionTypes.ITRConstraintsDeleteFilterReset: {
            const newFilter = new ITRConstraintsDeleteFilter();
            return {
                ...state,
                deleteFilter: { ...newFilter },
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsPatchDeleteRecordRequest: {
            let itemIndex = state.deleteData.items.findIndex((d) => d.constraintId === action.payload.constraintId);
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                deleteState: action.payload.deleteState,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsValidateDataError:
        case ITRConstraintsActionTypes.ITRConstraintsPatchAllDeleteRecordsError:
        case ITRConstraintsActionTypes.ITRConstraintsPatchAllDeleteRecordsRequest:
        case ITRConstraintsActionTypes.ITRConstraintsPatchDeleteRecordError:
        case ITRConstraintsActionTypes.ITRConstraintsPatchDeleteRecordSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsPatchAllDeleteRecordsSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item) => ({ ...item, deleteState: action.payload.deleteState })),
                },
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsValidateDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsValidateDataSuccess: {
            return {
                ...state,
                deleteData: new ITRConstraintsDeletePagination(),
                isDeleteDataLoading: false,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new ITRConstraintsDeletePagination(),
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsDownloadOutputDataError:
        case ITRConstraintsActionTypes.ITRConstraintsDownloadOutputDataSuccess:
        case ITRConstraintsActionTypes.ITRConstraintsDownloadOutputDataRequest:
        case ITRConstraintsActionTypes.ITRConstraintsTemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case ITRConstraintsActionTypes.ITRConstraintsAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'ITRConstraints';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

