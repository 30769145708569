import { Component, Input, OnInit } from '@angular/core';
import {
    WeeklyPlanningUsersTopHeaderDTO,
    WeeklyPlanningPastTopHeaderDTO,
    DailyDashboardDTO,
    WeeklyPlanningFilter,
} from 'src/app/store/weekly-planning/model';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';
import { getStartWeekFromDate, getWeekEndDate } from 'src/app/extensions';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { WeeklyPlanningTopHeaderSectionDataRequest } from 'src/app/store/weekly-planning/actions';
import { ChartSeriesDataDTO } from 'src/app/models/chart-series-dto';

@Component({
    selector: 'app-top-header-table',
    templateUrl: './top-header-table.component.html',
    styleUrls: ['./top-header-table.component.scss'],
})
export class TopHeaderTableComponent extends BaseComponent implements OnInit {
    planningTypeUppercase: string;
    schedulePanelOpened = false;
    colorScheme = {domain: ['#32CD32']};

    @Input() set planningType(value: string) {
        if (value) {
            this.planningTypeUppercase = value.toUpperCase();
        }
    }

    @Input() startDate: moment.Moment;
    @Input() projectTeamName: string;
    additionalForcedBackingField: DailyDashboardDTO[] = [];
    @Input() set additionalForced(value: DailyDashboardDTO[]) {
        this.additionalForcedBackingField = value;
        if (value) {
            this.calculateTableSource(this.additionalForcedBackingField, this.additionalAdditionsBackingField);
        }
    }
    additionalAdditionsBackingField: DailyDashboardDTO[] = [];
    @Input() set additionalAdditions(value: DailyDashboardDTO[]) {
        this.additionalAdditionsBackingField = value;
        if (value) {
            this.calculateTableSource(this.additionalForcedBackingField, this.additionalAdditionsBackingField);
        }
    }
    @Input() loadDataOnInint = true;

    isLoading = false;
    topHeaderSectionByUsers: WeeklyPlanningUsersTopHeaderDTO[] = [];
    topHeaderSectionByUsersTable: WeeklyPlanningUsersTopHeaderDTO[] = [];
    topHeaderSectionByPastWeeks: WeeklyPlanningPastTopHeaderDTO[] = [];
    topHeaderColumns = ['plan', 'user', 'planned', 'plannedAndAchieved', 'previouslyAchieved', 'l4Reliability'];
    topHeaderWeekColumns = [
        'user',
        'committed',
        'committedAndAchieved',
        'committmentReliability',
        'achievedButNotCommitted',
    ];
    topHeaderPastWeeksColumns = [
        'plan',
        'week',
        'planned',
        'plannedAndAchieved',
        'previouslyAchieved',
        'l4Reliability',
    ];
    topHeaderWeekPastCommittmentsColumns = [
        'week',
        'committed',
        'committedAndAchieved',
        'committmentReliability',
        'achievedButNotCommitted',
    ];

    isLoading$ = this.store.select((store) => store.weeklyPlanningState.topHeaderSectionDataLoading);
    topHeaderSectionData$ = this.store.select((store) => store.weeklyPlanningState.topHeaderSectionData);
    reliabilityChartData: ChartSeriesDataDTO[] = [];
    commintmentReliabilityChartData: ChartSeriesDataDTO[] = [];

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {

        if (this.loadDataOnInint) {
            let filter = new WeeklyPlanningFilter();
            filter.startDate = this.startDate;
            this.search(filter);
        }

        this.isLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((loading) => this.isLoading = loading);

        this.topHeaderSectionData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data.byUsers && data.byPastWeeks) {
                this.topHeaderSectionByUsers = data.byUsers;
                this.topHeaderSectionByUsersTable = data.byUsers;
                
                let topHeaderSectionByPastWeeks: WeeklyPlanningPastTopHeaderDTO[] = [];
                data.byPastWeeks.forEach(weekData => {
                    topHeaderSectionByPastWeeks.unshift({ ...weekData, week: getWeekEndDate(weekData.week)});
                })
                this.topHeaderSectionByPastWeeks = topHeaderSectionByPastWeeks;

                this.reliabilityChartData = [
                    this.formatWeeksToChartSeries(
                        data.byPastWeeks, 
                        'L4 Reliability', 
                        (data: WeeklyPlanningPastTopHeaderDTO) => data.l4Reliability
                        )];
                this.commintmentReliabilityChartData = [
                    this.formatWeeksToChartSeries(
                        data.byPastWeeks, 
                        'Commitment Reliability', 
                        (data: WeeklyPlanningPastTopHeaderDTO) => data.committmentReliability
                        )];
            }
        });
    }

    private formatWeeksToChartSeries(data: WeeklyPlanningPastTopHeaderDTO[], name: string, selector: (data: WeeklyPlanningPastTopHeaderDTO) => number) {
        let result: ChartSeriesDataDTO = { name: name, series: [] };
        [...data].reverse().forEach(weekData => {
            result.series.push({
                name: `W/E ${getWeekEndDate(weekData.week).format('D MMM')}`,
                value: selector(weekData)
            })
        });
        return result;
    }

    search(filter: WeeklyPlanningFilter) {
        this.store.dispatch(new WeeklyPlanningTopHeaderSectionDataRequest({
            projectTeamName: this.projectTeamName,
            filter: filter
        }));
    }

    calculateTableSource(forced: DailyDashboardDTO[], additions: DailyDashboardDTO[]) {
        const clone = _.cloneDeep(this.topHeaderSectionByUsers);

        for (let index = 0; index < forced.length; index++) {
            const element = forced[index];
            const item = clone.find((x) => x.userName === element.userName);
            const unassignedItem = clone.find((x) => x.userName === 'Unassigned');

            if (item) {
                this.updateAchivedCommittments(item);
            } else if (element.userName !== null) {
                const weeklyTop = Object.assign({}, new WeeklyPlanningUsersTopHeaderDTO(), {
                    userName: element.userName,
                });
                this.updateAchivedCommittments(weeklyTop);

                clone.push();
            } else if (unassignedItem) {
                this.updateAchivedCommittments(unassignedItem);
            }

            const total = clone.find((x) => x.userName === 'Total');

            total.committedAndAchieved++;
            total.committmentReliability = this.getPercentageCommittmentReliability(total);
        }

        for (let index = 0; index < additions.length; index++) {
            const element = additions[index];
            const item = clone.find((x) => x.userName === element.userName);
            const unassignedItem = clone.find((x) => x.userName === 'Unassigned');
            const total = clone.find((x) => x.userName === 'Total');
            const planDate = element[this.planningTypeUppercase === 'MC' ? 'mcPlan' : 'rfsuPlan'];

            if (item) {
                this.updatePlannedCommitments(planDate, item, total);

                item.achievedButNotCommitted++;
            } else if (element.userName !== null) {
                const weeklyTop = Object.assign({}, new WeeklyPlanningUsersTopHeaderDTO(), {
                    userName: element.userName,
                });

                this.updatePlannedCommitments(planDate, weeklyTop, total);

                weeklyTop.achievedButNotCommitted++;

                clone.push();
            } else if (unassignedItem) {
                this.updatePlannedCommitments(planDate, unassignedItem, total);

                unassignedItem.achievedButNotCommitted++;
            }

            total.achievedButNotCommitted++;
        }

        this.topHeaderSectionByUsersTable = clone;
    }

    private updateAchivedCommittments(item: WeeklyPlanningUsersTopHeaderDTO) {
        item.committedAndAchieved++;
        item.committmentReliability = this.getPercentageCommittmentReliability(item);
    }

    private updatePlannedCommitments(
        planDate: moment.Moment,
        item: WeeklyPlanningUsersTopHeaderDTO,
        total: WeeklyPlanningUsersTopHeaderDTO
    ) {
        if (planDate === null) return;

        if (this.isUnderCurrentWeek(moment(planDate))) {
            item.plannedAndAchieved++;
            item.l4Reliability = this.getPercentageL4Reliability(item);

            total.plannedAndAchieved++;
            total.l4Reliability = this.getPercentageL4Reliability(total);
        }
    }

    private isUnderCurrentWeek(planDate: moment.Moment) {
        return planDate.isBetween(
            getStartWeekFromDate(this.startDate),
            this.startDate.clone().add(6, 'd'),
            undefined,
            '[]'
        );
    }

    getPercentageCommittmentReliability(item: WeeklyPlanningUsersTopHeaderDTO): number {
        return item.committed === 0
            ? 0
            : Math.round((item.committedAndAchieved / item.committed + Number.EPSILON) * 100 * 100) / 100;
    }

    getPercentageL4Reliability(item: WeeklyPlanningUsersTopHeaderDTO): number {
        return item.planned === 0
            ? 0
            : Math.round(
                ((item.plannedAndAchieved + item.previouslyAchieved) / item.planned + Number.EPSILON) * 100 * 100
            ) / 100;
    }

    yAxisTickPercentage(value) {
        return `${value}%`;
    }

    get getCurrentWeekEndDate() {
        return getWeekEndDate(this.startDate).toDate()
    }
}
