import { Waypoint, Milestone, Gooc, RFO, StagedStartUpPriority, Contractor } from '../../common.model';
import * as moment from 'moment';
import { WeekType } from 'src/app/enums';

export const SKYLINE_NUM_OF_PAST_WEEKS = 3;
export const SKYLINE_NUM_OF_PAST_WEEKS_WIDE = 8;
export const SKYLINE_NUM_OF_UPCOMING_WEEKS_MEDIUM_HIGH = 3;
export const SKYLINE_NUM_OF_UPCOMING_WEEKS_LOW = 10;
export const SKYLINE_NUM_OF_UPCOMING_WEEKS_WIDE = 18;
export const SKYLINE_API_CALL_DATE_FORMAT = 'YYYY-MM-DD';
export const SKYLINE_NUM_PRINT_WEEKS = 40;

export class SkylineDTO {
    subsystem: string;
    subsystemName: string;
    waypoint: string;
    mcPlan: moment.Moment;
    mcActual: moment.Moment;
    mcForecast: moment.Moment;
    rfsuPlan: moment.Moment;
    rfsuActual: moment.Moment;
    rfsuForecast: moment.Moment;
    aqvd: number;
    aqvdClosed: number;
    apli: number;
    apliClosed: number;
    aqvdCompletionPercentage: number;
    bitr: number;
    bitrClosed: number;
    bpli: number;
    bpliClosed: number;
    bitrCompletionPercentage: number;
    citr: number;
    citrClosed: number;
    cpli: number;
    cpliClosed: number;
    citrCompletionPercentage: number;
    bcitrCompletionPercentage: number;
    walkdownCompleted: boolean;
    mcCompleted: boolean;
    rfsuCompleted: boolean;
    rfsuEdossierInitiated: boolean;
    operationsApprovalOutstanding: boolean;
    commitmentDate: moment.Moment;
    walkdownCommitted: boolean;
    qvdNumber: string;
    qvdDescription: string;
    barcode: string;
    tagNumber: string;
    citrCompletionPercentageLastWeek: number;
    citrCompletionPercentageThisWeek: number;
    itrStatus: string;
    nonCommissionable: boolean;
}

export class SkylineRFODTO {
    rfo: string;
    rfoName: string;
    rfoPlan: moment.Moment;
    rfoActual: moment.Moment;
    rfoForecast: moment.Moment;
    CommitmentDate: moment.Moment;
    aqvd: number;
    aqvdClosed: number;
    apli: number;
    apliClosed: number;
    aqvdCompletionPercentage: number;
    bitr: number;
    bitrClosed: number;
    bpli: number;
    bpliClosed: number;
    bitrCompletionPercentage: number;
    citr: number;
    citrClosed: number;
    cpli: number;
    cpliClosed: number;
    citrCompletionPercentage: number;
    bcitrCompletionPercentage: number;
    rfoCompleted: number;
    numberOfSubsystems: number;
    numberOfSubsystemsRFSUCompleted: number;
    numberOfSubsystemsRFSURemaining: number;
    rfoCompletionPercentage: number;
    openBITR: number;
    openCITR: number;
}

export interface SkylineResponseDTO {
    weekStartDate: moment.Moment;
    subsystems: SkylineDTO[];
    rfos:SkylineRFODTO[];
}

export enum SkylineLevelOfDetails {
    Low,
    Medium,
    High,
    Wide,
}

export class SkylineFilter {
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    waypoints: Waypoint[] = [];
    waypointScopes:string[] = [];
    waypointNames:string[] = [];
    milestones: Milestone[] = [];
    goocs: Gooc[] = [];
    startDate: string;
    endDate: string;
    mcEngineer: string[] = [];
    systemOwner: string[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    acm: string[] = [];
    scManager: string[] = [];
    rfos: RFO[] = [];
    constructionArea: string[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    requiredForSteamblow: boolean = null;
    deliveryEngineer: string[] = [];
    workAreas:string[] = [];
    disciplines: string[] = [];
    milestone: Milestone[] = [];
    subSystems: string[] = [];
    qvdNumbers:any[] = [];
    hvac?: boolean = null;
    itrDescriptions: string[] = [];
}

export interface SkylineState {
    skylinePhase: string;
    skylineType: string;
    filter: SkylineFilter;
    levelOfDetails: SkylineLevelOfDetails;
    weeks: { date: Date; weekType: WeekType }[];
    isLoading: boolean;
    printIsLoading: boolean;
    subsystemsByWeek: SkylineDTO[][];
    rfosByWeek: SkylineRFODTO[][];
    openAqvdsPerWeek: { week: Date; count: number }[];
    openBitrsPerWeek: { week: Date; count: number }[];
    openCitrsPerWeek: { week: Date; count: number }[];
    skylineParts: SkylinePart[];
    isExcelGenerating: boolean;
    flagToggles?: {
        showMcIncomplete?: boolean;
        showRfsuEdossierInitiated?: boolean;
        showOpsAppPending?: boolean;
        showWalkdownCommitted?: boolean;
        showNonCommissionable?: boolean;
    };
}

export class SkylinePart {
    isLoading: boolean;
    weeks: { date: Date; weekType: WeekType }[];
    subsystemsByWeek: SkylineDTO[][];
    rfosByWeek: SkylineRFODTO[][];
    openAqvdsPerWeek: { week: Date; count: number }[];
    openBitrsPerWeek: { week: Date; count: number }[];
    openCitrsPerWeek: { week: Date; count: number }[];
}

export class SkylineSuccessPayload {
    data: SkylineResponseDTO[];
    datePropertyName: string;
    printMode: boolean;
}
