<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            Change Validation Dashboard
        </div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.changeTypes.value.length" removable>
                                Change Type: {{ filterForm.controls.changeTypes.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('changeTypes')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.changeStatuses.value.length" removable>
                                Change Status: {{ filterForm.controls.changeStatuses.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('changeStatuses')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Project Team</mat-label>
                        <mat-select
                            formControlName="projectTeamNames"
                            multiple
                            (openedChange)="onProjectTeamsClosed($event)"
                        >
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setContractorInput"
                            formControlName="contractors"
                            [searchFunc]="getContractors"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            (autocompleteClosed)="onContractorsClosed()"
                            (itemRemoved)="onContractorsClosed()"
                            [filterForm]="filterForm"
                            [formSetter]="'contractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'Contract Assigned to Change'"
                            [panelWidth]="480"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Change Type</mat-label>
                        <mat-select formControlName="changeTypes" multiple>
                            <mat-option *ngFor="let ct of changeTypes" [value]="ct">{{ ct }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Discipline</mat-label>
                        <mat-select formControlName="disciplines" multiple>
                            <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mixedapp-container">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Change Status</mat-label>
                        <mat-select formControlName="changeStatuses" multiple>
                            <mat-option *ngFor="let cs of changeStatuses" [value]="cs">{{ cs }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
    <div class="buttons-container flex">
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filters
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">
            search
        </button>
    </div>
    <div class="results-container">
        <div class="doublechart-container">
            <div class="doublechart-item">
                <div style="display: flex;">
                    <h4 style="width: 48%;">{{weekViewEnabledForValidationProgress ? 'weekly' : 'monthly' }} validation progress</h4>
                    <div style="width: 48%;">            
                        <mat-slide-toggle [color]="'primary'" [checked]="weekViewEnabledForValidationProgress" (toggleChange)="toggleMonthView()">
                            show weekly view
                        </mat-slide-toggle>
                    </div>
                </div>

                <app-monthly-validation-progress *ngIf="!weekViewEnabledForValidationProgress"></app-monthly-validation-progress>
                <app-weekly-validation-progress *ngIf="weekViewEnabledForValidationProgress"></app-weekly-validation-progress>
                
            </div>
            <div class="doublechart-item">
                <app-cumulative-validation-progress *ngIf="!weekViewEnabledForValidationProgress"></app-cumulative-validation-progress>
                <app-cumulative-validation-progress-weekly *ngIf="weekViewEnabledForValidationProgress"></app-cumulative-validation-progress-weekly>
            </div>
        </div>
        <div class="singlechart-container">
            <app-monthly-change-raised  *ngIf="!weekViewEnabledForValidationProgress"></app-monthly-change-raised>
            <app-weekly-change-raised  *ngIf="weekViewEnabledForValidationProgress"></app-weekly-change-raised>
        </div>
        <div class="doublechart-container">
            <div class="doublechart-item"><app-validation-progress></app-validation-progress></div>
            <div class="doublechart-item"><app-systemisation-progress></app-systemisation-progress></div>
        </div>

        <div class="singlechart-container">
            <app-validator-performance-scoreboard></app-validator-performance-scoreboard>
        </div>
    </div>
</main>
