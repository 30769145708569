import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceChar',
})
export class ReplaceCharPipe implements PipeTransform {
    transform(value: string, character: string): string {
        let regex = new RegExp(character, 'g');
        if (value.indexOf(character) > 0) {
            return value.replace(regex, character + ' ');
        } else return value;
    }
}
