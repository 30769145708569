import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { DesignStatusMappingDTO } from 'src/app/models/design-status-mapping-dto';

@Injectable({
    providedIn: 'root',
})
export class DesignStatusMappingService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/designstatusmapping';
    }

    get(changeType:string) {
        return this.http.get<DesignStatusMappingDTO[]>(`${this.rootUrl}${this.endpoint}?changetype=${changeType}`);
    }

    remove(id: number) {
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?id=${id}`);
    }

    update(contractor: DesignStatusMappingDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, contractor);
    }

    add(contractor: DesignStatusMappingDTO) {
        return this.http.post<DesignStatusMappingDTO>(`${this.rootUrl}${this.endpoint}`, contractor);
    }
}
