<main class="main">
    <form [formGroup]="filterForm">
        <div class="title-container">
            <div class="title">
                Ready for Operation (RFO) status
            </div>
        </div>
        <mat-accordion class="filter-container" style="display:inline !important">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.RFOs.value.length" removable>
                                RFO: {{ displayMultipleSelected(filterForm.controls.RFOs.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('RFOs')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip>
                                RFO Completed:
                                {{ displaySelectedRFOCompleted(filterForm.controls.RFOCompleted.value) }}
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                Area Breakdown:
                                {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Project Team</mat-label>
                        <mat-select
                            formControlName="projectTeamNames"
                            multiple
                            (openedChange)="onProjectTeamsClosed($event)"
                        >
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManager" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Area Completion Lead</mat-label>
                        <mat-select formControlName="areaCompletionLead" multiple>
                            <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>


                   

                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFOInput"
                            formControlName="RFOs"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'RFOs'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                </div>

                <div class="mixedapp-container">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Scope</mat-label>
                        <mat-select formControlName="waypointScopes" multiple (selectionChange)="getwayPointsByScope($event.value)">
                            <mat-option *ngFor="let sc of waypointScopes" [value]="sc"> {{sc}} </mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Waypoint</mat-label>
                        <mat-select formControlName="waypointNames" multiple>
                            <mat-option *ngFor="let sc of wayPointNames" [value]="sc.name">{{sc.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <div class="container-radiobuttons ">
                        <mat-label class="title-radiobuttons">RFSU Completed</mat-label>
                        <mat-radio-group formControlName="rfsuCompleted" aria-label="RFSU Completed">
                            <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                            <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                            <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="container-radiobuttons">
                        <mat-label class="title-radiobuttons">RFO Completed</mat-label>
                        <mat-radio-group formControlName="RFOCompleted" aria-label="RFO Completed">
                            <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                            <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                            <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                
                <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
           export to excel
                </button>
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
        <div class="results-container">
            <div class="results-title">
                <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
                <div class="results-header-actions">
                    <button style="color:#00adee !important; outline: none;"
                        mat-button
                        [matMenuTriggerFor]="columnsCustomizer"
                        class="results-columns-customizer"
                    >
                        <mat-icon svgIcon="edit"></mat-icon>
                        customize columns
                    </button>
                    <mat-menu #columnsCustomizer class="columns-customizer">
                        <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                        <div class="columns-customizer-list">
                            <mat-checkbox 
                                [checked]="showHideColumns.rfoName" 
                                (change)="reverseValue('rfoName')"
                                (click)="$event.stopPropagation()"
                                >RFO Name</mat-checkbox>
                            <mat-checkbox 
                                [checked]="showHideColumns.dates" 
                                (change)="reverseValue('dates')"
                                (click)="$event.stopPropagation()"
                                >RFO Dates</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.showRFOFreeze" 
                                (change)="reverseValue('showRFOFreeze')"
                                (click)="$event.stopPropagation()"
                                >RFO Freeze</mat-checkbox
                            > 
                            <mat-checkbox 
                                [checked]="showHideColumns.showEdossierInitiation" 
                                (change)="reverseValue('showEdossierInitiation')"
                                (click)="$event.stopPropagation()"
                                >eDossier Initiation</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.showEdossierStatus" 
                                (change)="reverseValue('showEdossierStatus')"
                                (click)="$event.stopPropagation()"
                                >eDossier Status</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.noOfSubs" 
                                (change)="reverseValue('noOfSubs')"
                                (click)="$event.stopPropagation()"
                                >No. of Subs.</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.rfsuRemaining" 
                                (change)="reverseValue('rfsuRemaining')"
                                (click)="$event.stopPropagation()"
                                >RFSU Remaining</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.ITRs" 
                                (change)="reverseValue('ITRs')"
                                (click)="$event.stopPropagation()"
                                >B/C-ITR</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.NPW" 
                                (change)="reverseValue('NPW')"
                                (click)="$event.stopPropagation()"
                                >NPW</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.APLIs" 
                                (change)="reverseValue('APLIs')"
                                (click)="$event.stopPropagation()"
                                >A-QVD/A-PLI
                            </mat-checkbox>
                            <mat-checkbox 
                                [checked]="showHideColumns.BPLIs" 
                                (change)="reverseValue('BPLIs')"
                                (click)="$event.stopPropagation()"
                                >B-PLI/C-PLI
                            </mat-checkbox>
                            <mat-checkbox 
                                [checked]="showHideColumns.DEX" 
                                (change)="reverseValue('DEX')"
                                (click)="$event.stopPropagation()"
                                >D-PLI/Exc</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.AC" 
                                (change)="reverseValue('AC')"
                                (click)="$event.stopPropagation()"
                                >AC</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.COW" 
                                (change)="reverseValue('COW')"
                                (click)="$event.stopPropagation()"
                                >COW</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.redline" 
                                (change)="reverseValue('redline')"
                                (click)="$event.stopPropagation()"
                                >Redline</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.LUN" 
                                (change)="reverseValue('LUN')"
                                (click)="$event.stopPropagation()"
                                >LUN</mat-checkbox
                            > 
                            <mat-checkbox 
                                [checked]="showHideColumns.showAreaBreakdown" 
                                (change)="reverseValue('showAreaBreakdown')"
                                (click)="$event.stopPropagation()"
                                >Area Breakdown</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.showAreaCompletionLead" 
                                (change)="reverseValue('showAreaCompletionLead')"
                                (click)="$event.stopPropagation()"
                                >Area Completion Lead</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.showSCAreaManager" 
                                (change)="reverseValue('showSCAreaManager')"
                                (click)="$event.stopPropagation()"
                                >SC Execution Area Manager</mat-checkbox
                            >
                        </div>
                    </mat-menu>
                </div>

            </div>
            <div class="table-wrapper">
                <div
                    class="scroll"
                    [ngClass]="{
                        'RFOnm-hidden': !showHideColumns.rfoName && isIE
                    }"
                >
                    <div class="table-container">
                        <table
                            mat-table
                            matSort
                            [dataSource]="data"
                            [matSortActive]="sortBy"
                            matSortDisableClear
                            matSortStart="desc"
                            [matSortDirection]="direction"
                        >
                            <ng-container matColumnDef="RFO" [sticky]="!isIE ? 'sticky' : null">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer sticky-col1"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'RFO',
                                            getRFOsForHeaderFilter,
                                            'columnRFOs',
                                            'RFO',
                                            columnRFOs
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRFOs)
                                            }"
                                            >RFO</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'RFO'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" class="sticky-col1">{{ element.rfo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="RFOName" [sticky]="!isIE ? 'sticky' : null">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfoName
                                    }"
                                    class="hover-pointer sticky-col2"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'RFOName',
                                            getRFOsNamesForHeaderFilter,
                                            'columnRFOsNames',
                                            'RFO Name',
                                            columnRFOsNames
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRFOsNames)
                                            }"
                                            >RFO Name</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'RFOName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    class="sticky-col2"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfoName,
                                        'green-indicator': element.isReadyForRFO
                                    }"
                                >
                                    {{ element.rfoName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="RFOPlan">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.dates
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter('RFOPlan', 'columnRFOPlan', 'RFO Plan', columnRFOPlan)
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFOPlan)
                                            }"
                                            >RFO Plan</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'RFOPlan'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.dates }"
                                >
                                    {{ element.rfoPlan | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="RFOForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.dates
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'RFOForecast',
                                            'columnRFOForecast',
                                            'RFO Forecast',
                                            columnRFOForecast
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFOForecast)
                                            }"
                                            >RFO Forecast</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'RFOForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    (click)="openDatepicker(element)"
                                    mat-cell
                                    [ngClass]="{
                                        hidecells: !showHideColumns.dates,
                                        'cursor-pointer': !isReadOnly && !element.RFOActual
                                    }"
                                    *matCellDef="let element"
                                    (contextmenu)="forecastDateHistory($event, element, 'rfo')"
                                >
                                    {{ element.rfoForecast | date: 'd/MMM/yy' }}
                                    <button
                                        type="button"
                                        *ngIf="!element.rfoForecast && !isReadOnly && !element.rfoActual"
                                        mat-icon-button
                                        class="date-picker-icon"
                                    >
                                        <mat-icon>calendar_today</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="RFOFreeze">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef                                 
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'RFOFreeze',
                                            'columnRFOFreeze',
                                            'RFO Freeze',
                                            columnRFOFreeze
                                        )
                                    "
                                    [ngClass]="{ hidecells: !showHideColumns.showRFOFreeze }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFOFreeze)
                                            }"
                                            >RFO Freeze</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'RFOFreeze'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    (click)="openRFOFreezeDatepicker(element)"
                                    mat-cell
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showRFOFreeze,
                                        'cursor-pointer': !isReadOnly && !element.RFOActual
                                    }"
                                    *matCellDef="let element"
                                    
                                >
                                    {{ element.rfoActual !== null ? "N/A" : element.rfoFreeze | date: 'd/MMM/yy' }}
                                    <!-- <button
                                        type="button"
                                        *ngIf="!element.rfoForecast && !isReadOnly && !element.rfoActual"
                                        mat-icon-button
                                        class="date-picker-icon"
                                    >
                                        <mat-icon>calendar_today</mat-icon>
                                    </button> -->
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="edossierInitiationDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showEdossierInitiation,
                                        'header-filtered': showUnderlineForCalendarColumn(columnEdossierInitiation)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'edossierInitiationDate',
                                            'columnEdossierInitiation',
                                            'eDossier Initiation',
                                            columnEdossierInitiation
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>eDossier Initiation</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'edossierInitiationDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showEdossierInitiation,
                                        'row-underline': isRowUnderlined(data, idx)
                                    }"
                                >
                                    {{ element.edossierInitiationDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="edossierStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showEdossierStatus,
                                        'header-filtered': showUnderlineForCheckListColumn(columnEdossierStatus)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'edossierStatus',
                                            getEdossierStatuses,
                                            'columnEdossierStatus',
                                            'eDossier Status',
                                            columnEdossierStatus,
                                            true,
                                            false,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>eDossier Status</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'edossierStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showEdossierStatus,
                                        'row-underline': isRowUnderlined(data, idx)
                                    }"
                                >
                                    <div *ngIf="element.edossierStatus !== 'Not Started'">
                                        <a href="{{ eDossierLink(element.rfo) }}" target="_blank">
                                            {{ element.edossierStatus }}
                                        </a>
                                    </div>
                                    <div *ngIf="element.edossierStatus === 'Not Started'">
                                        {{ element.edossierStatus }}
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="RFOActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.dates
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'RFOActual',
                                            'columnRFOActual',
                                            'RFO Actual',
                                            columnRFOActual
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFOActual)
                                            }"
                                            >RFO Actual</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'RFOActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.dates }"
                                >
                                    {{ element.rfoActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="rfsuForecastDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.dates
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfsuForecastDate',
                                            'columnRfsuForecastDate',
                                            'RFSU Forecast',
                                            columnRfsuForecastDate
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRfsuForecastDate)
                                            }"
                                            >Forecast of Last Subs Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuForecastDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.dates }"
                                >
                                    {{ element.rfsuForecastDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="noOfSubs">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.noOfSubs }"
                                >
                                    No. of Subs.
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.noOfSubs }"
                                >
                                    <span><a (click)="openBlueZonePage(element)">
                                        {{ element.noOfSubs }}
                                    </a>
                                    </span>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="rfsuRemaining">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.rfsuRemaining }"
                                >
                                RFSU Remaining
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfsuRemaining }"
                                >
                                    <span><a (click)="openBlueZonePage(element,true)">
                                        {{ element.rfsuRemaining }}
                                    </a>
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="bitr">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.ITRs }"
                                >
                                    B-ITR
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.ITRs }">
                                    <span><a (click)="openDetailedStatusPage(element.rfo, 'BITR')">
                                        {{ element.bitr }}
                                        </a></span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="citr">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.ITRs }"
                                >
                                    C-ITR
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.ITRs }">
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'CITR')">
                                            {{ element.citr }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="npw">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.NPW }"
                                >
                                    NPW
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.NPW }">
                                    <span
                                        ><a (click)="openChangeManagementPage(element.rfo, 'NPW')">
                                            {{ element.npw }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="aqvd">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.APLIs }"
                                >
                                    A-QVD
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.APLIs }"
                                >
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'AQVD')">
                                            {{ element.aqvd }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="apli">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.APLIs }"
                                >
                                    A-PLI
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.APLIs }"
                                >
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'PLI', 'A')">
                                            {{ element.apli }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="bpli">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.BPLIs }"
                                >
                                    B-PLI
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.BPLIs }"
                                >
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'PLI', 'B')">
                                            {{ element.bpli }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="cpli">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.BPLIs }"
                                >
                                    C-PLI
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.BPLIs }"
                                >
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'PLI', 'C')">
                                            {{ element.cpli }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="dpli">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.DEX }"
                                >
                                    D-PLI
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.DEX }">
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'PLI', 'D')">
                                            {{ element.dpli }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ex">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.DEX }"
                                >
                                    Exc
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.DEX }">
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'EX')">
                                            {{ element.ex }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="pascr">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.PASCR }"
                                >
                                    PASCR
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.PASCR }">
                                    <span
                                        ><a
                                            (click)="gotoPascr(element)"
                                        >
                                            {{ element.pascr }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ac">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.AC }"
                                >
                                    AC
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.AC }">
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'PLI', 'AC')">
                                            {{ element.ac }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="cow">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.COW }"
                                >
                                    COW
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.COW }">
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'COW')">
                                            {{ element.cow }}
                                        </a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="redline">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.redline }"
                                >
                                    Redline
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.redline }"
                                >
                                    <span
                                        ><a (click)="openDetailedStatusPage(element.rfo, 'Redline', 'Required')">{{
                                            element.redline
                                        }}</a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="lun">
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.LUN }"
                                >
                                    LUN
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.LUN }">
                                    <span
                                        ><a (click)="openLUNPage(element.rfo)">{{ element.lun }}</a></span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="comment" stickyEnd>
                                <th mat-header-cell disableClear *matHeaderCellDef>
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnComments)
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'comment',
                                                    getCommentsForHeaderFilter,
                                                    'columnComments',
                                                    'Comment',
                                                    columnComments,
                                                    false,
                                                    true,
                                                    true
                                                )
                                            "
                                            >Comment</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'comment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div>
                                        <div (click)="openCommentPopup(element, $event)" class="comment-description">
                                            <div *ngIf="element.comment && !isReadOnly; else notShow">
                                                <a
                                                    ><span
                                                        [innerHTML]="element.comment | formatMentions: element.mentions"
                                                    ></span
                                                ></a>
                                            </div>
                                            <div *ngIf="isReadOnly">
                                                <span
                                                    [innerHTML]="element.comment | formatMentions: element.mentions"
                                                ></span>
                                            </div>
                                            <ng-template #notShow>
                                                <button
                                                    type="button"
                                                    *ngIf="!isReadOnly"
                                                    mat-stroked-button
                                                    class="add-comment"
                                                >
                                                    Add
                                                </button>
                                            </ng-template>
                                        </div>
                                        <div
                                            [ngClass]="{ notactive: !element.comment, active: element.comment }"
                                            class="comment-hist"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                width="24"
                                                (click)="openHistoryPopup(element)"
                                            >
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path
                                                    d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="areaBreakdown">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showAreaBreakdown,
                                        'header-filtered': columnAreaBreakdown && columnAreaBreakdown.items.length > 0
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'areaBreakdown',
                                            getAreaBreakdowns,
                                            'columnAreaBreakdown',
                                            'AreaBreakdown',
                                            columnAreaBreakdown,
                                            false,
                                            false,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAreaBreakdown)
                                            }"
                                            >Area Breakdown</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'areaBreakdown'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showAreaBreakdown
                                    }"
                                >
                                    {{ element.areaBreakdown }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="areaCompletionLead">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showAreaCompletionLead,
                                        'header-filtered': columnAreaCompletionLead && columnAreaCompletionLead.items.length > 0
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'areaCompletionLead',
                                            getAreaCompletionLeads,
                                            'columnAreaCompletionLead',
                                            'AreaCompletionLead',
                                            columnAreaCompletionLead,
                                            false,
                                            false,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAreaCompletionLead)
                                            }"
                                            >Area Completion Lead</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'areaCompletionLead'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showAreaCompletionLead
                                    }"
                                >
                                    {{ element.areaCompletionLead }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="scAreaManager">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    class="hover-pointer"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showSCAreaManager,
                                        'header-filtered': columnSCAreaManager && columnSCAreaManager.items.length > 0
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'scAreaManager',
                                            getSCExecutionAreaManagers,
                                            'columnSCAreaManager',
                                            'SC Area Manager',
                                            columnSCAreaManager,
                                            false,
                                            false,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnSCAreaManager)
                                            }"
                                            >SC Execution Area Manager</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'scAreaManager'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.showSCAreaManager
                                    }"
                                >
                                    {{ element.scAreaManager }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns"
                                [ngClass]="{ highlight: selectedRowIndex == row }"
                                (click)="highlightRow(row)"
                            ></tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 50]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>

<div class="tables-container" #detailedPage>   

    <div *ngIf="activeScopeDetailedView() && showDetailedPage !== 'NPW' && showDetailedPage !== 'PASCR'">
        <app-detailed-status
        [blueScreen]="true"
        [blueScope]="blueScope"
        [blueRFOs]="blueRFOs"
        [blueRFO]="blueRFO"
        [blueStatus]="blueStatus"
        [blueZone]="blueZone"
        [blueCategory]="blueCategory"
        [blueExclude]="blueExclude"
        [blueRFSUCompleted]="blueRFSUCompleted"
        [blueAllowComments]="true"
        [blueLockFilters]="true"
        >                
        </app-detailed-status>
    </div>

    <div *ngIf="activeScopeDetailedView() && showDetailedPage === 'NPW'">
        <app-change-management
        [blueScreen]="true"
        [blueRFO]="blueRFO"
        [blueChangeType]="blueChangeType"
        [blueAllowComments]="true"
        [blueLockFilters]="true"
        >                
        </app-change-management>
    </div>

    <div *ngIf="activeScopeDetailedView() && showDetailedPage === 'PASCR'">
        <pascr-status
        [blueScreen]="true"
        [blueRFO]="blueRFO"
        >                
        </pascr-status>
    </div>

    
</div>
