<form [formGroup]="filterForm">
    <mat-accordion class="filter-container">
        <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" expanded class="filter-panel-container">
            <mat-expansion-panel-header collapsedHeight="auto">
                <app-register-filter-top-header 
                    [filterForm]="filterForm"
                    [filterExpansionPanel]="filterExpansionPanel"
                ></app-register-filter-top-header>
            </mat-expansion-panel-header>
            <div class="filter-panel-content-container">
                <div class="filters-row col4">
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="dcnRfiNumber"
                        [isAsync]="true"
                        [setInput]="setDcnRfiNumbersSelectorValue"
                        [formSetter]="'dcnRfiNumber'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchDcnRfiNumbers"
                        [panelWidth]="480"
                        placeholder="DCN/RFI Number"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="redlineNumber"
                        [isAsync]="true"
                        [setInput]="setRedlineNumbersSelectorValue"
                        [formSetter]="'redlineNumber'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchRedlineNumbers"
                        [panelWidth]="480"
                        placeholder="Redline Document Number"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="filter-item">
                        <mat-form-field class="container300 margin-right20 grow">
                            <mat-label>DCN/RFI Discipline</mat-label>
                            <mat-select formControlName="dcnRfiDiscipline" multiple>
                                <mat-option *ngFor="let discipline of dcnRfiDisciplines" [value]="discipline">{{ discipline }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filter-item">
                        <mat-form-field class="container300 margin-right20 grow">
                            <mat-label>DCN/RFI Engineering Status</mat-label>
                            <mat-select formControlName="dcnRfiEngineeringStatus" multiple>
                                <mat-option *ngFor="let engineeringStatus of dcnRfiEngineeringStatuses" [value]="engineeringStatus">{{ engineeringStatus }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="filter-panel-content-container">
                <div class="filters-row col4">
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="dcnRfiImpactedSubsystem"
                        [isAsync]="true"
                        [setInput]="setDcnRfiImpactedSubsystemsSelectorValue"
                        [formSetter]="'dcnRfiImpactedSubsystem'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchDcnRfiImpactedSubsystems"
                        [panelWidth]="480"
                        placeholder="DCN/RFI Subsystems"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="dcnRfiTitle"
                        [isAsync]="true"
                        [setInput]="setDcnRfiTitlesSelectorValue"
                        [formSetter]="'dcnRfiTitle'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchDcnRfiTitles"
                        [panelWidth]="480"
                        placeholder="DCN/RFI Title"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="dcnRfiContent"
                        [isAsync]="true"
                        [setInput]="setDcnRfiContentSelectorValue"
                        [formSetter]="'dcnRfiContent'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchDcnRfiContent"
                        [panelWidth]="480"
                        placeholder="DCN/RFI Content"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="dcnRfiWorkpackNumber"
                        [isAsync]="true"
                        [setInput]="setDcnRfiWorkpackNumberSelectorValue"
                        [formSetter]="'dcnRfiWorkpackNumber'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchDcnRfiWorkpackNumbers"
                        [panelWidth]="480"
                        placeholder="NB Workpack Number"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
            </div>
            <div class="filter-panel-content-container">
                <div class="filters-row col3">
                    <div class="filter-item">
                        <mat-form-field class="container300 margin-right20 grow">
                            <mat-label>Workpack Status</mat-label>
                            <mat-select formControlName="dcnRfiWorkpackStatus" multiple>
                                <mat-option *ngFor="let workpackStatus of dcnRfiWorkpackStatuses" [value]="workpackStatus">{{ workpackStatus }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filter-item">
                        <mat-form-field class="container300 margin-right20 grow">
                            <mat-label>Implementation Status</mat-label>
                            <mat-select formControlName="dcnRfiImplementationStatus" multiple>
                                <mat-option *ngFor="let implementationStatus of dcnRfiImplementationStatuses" [value]="implementationStatus">{{ implementationStatus }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="workArea"
                        [isAsync]="true"
                        [setInput]="setDcnRfiWorkAreaSelectorValue"
                        [formSetter]="'workArea'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchDcnRfiWorkArea"
                        [panelWidth]="480"
                        placeholder="Work Area"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>
