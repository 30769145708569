import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { ProjectTeamRoleDTO } from 'src/app/models/project-team-role-dto';
import { ProjectTeamRolesMaintenanceService } from 'src/app/services/api/webapi-services/project-team-roles-maintenance.service';
import { TCORole, GetTCORoleName } from 'src/app/enums';

@Component({
    selector: 'app-add-project-team-role-popup',
    templateUrl: './add-project-team-role-popup.component.html',
    styleUrls: ['./add-project-team-role-popup.component.scss'],
})
export class AddProjectTeamRolePopupComponent extends BaseComponent {
    loading: boolean;
    addForm: UntypedFormGroup;
    projectTeamNames: string[] = [];

    roles: { value: TCORole; text: string }[] = [
        { value: TCORole.SCManager, text: GetTCORoleName(TCORole.SCManager) },
        { value: TCORole.MCEngineer, text: GetTCORoleName(TCORole.MCEngineer) },
        { value: TCORole.SystemOwner, text: GetTCORoleName(TCORole.SystemOwner) },
        { value: TCORole.ACM, text: GetTCORoleName(TCORole.ACM) },
    ];

    constructor(
        public dialogRef: MatDialogRef<AddProjectTeamRolePopupComponent>,
        private toastService: ToastService,
        private dataMaintenanceService: ProjectTeamRolesMaintenanceService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        super();

        this.projectTeamNames = data.projectTeamNames;

        this.addForm = new UntypedFormGroup({
            projectTeamName: new UntypedFormControl(this.projectTeamNames[0], Validators.required),
            role: new UntypedFormControl(this.roles[0].value, Validators.required),
            name: new UntypedFormControl('', [Validators.required, this.noWhitespaceValidator]),
        });
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        const form = new ProjectTeamRoleDTO();

        form.projectTeamName = this.addForm.controls.projectTeamName.value;
        form.role = this.addForm.controls.role.value;
        form.name = this.addForm.controls.name.value.trim();

        this.dataMaintenanceService.add(form).subscribe(
            () => {
                this.loading = false;
                this.toastService.Success(`The role ${form.name} has been added.`);
                this.dialogRef.close({ success: true });
            },
            (error) => {
                this.loading = false;

                if (error.status === 409) {
                    this.toastService.Error('Name (in this Project Team) entered already exists in SCMT.');
                } else {
                    this.toastService.Error(
                        'An error occurred while adding a project team role. Please contact a Program Administrator.'
                    );
                }
            }
        );
    }
}
