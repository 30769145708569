import { Component, OnInit } from '@angular/core';
import { RoleService } from '../../services/shared/role.service';
import { Constants } from '../../constants';
import { BaseComponent } from '../base.component';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { takeWhile } from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent implements OnInit {
    isAdmin = false;
    isReadOnly = false;
    isGatheringOnly = false;
    isChangeDocValidator = false;
    fileDownloading = false;

    constructor(
        private roleService: RoleService,
        private adminPanelService: AdminPanelService,
        private toastService: ToastService
    ) {
        super();
    }

    ngOnInit() {
        this.isReadOnly = this.roleService.isReadOnly();
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.isGatheringOnly = this.roleService.isGatheringOnly();
        this.isChangeDocValidator =
            this.roleService.isInRole(Constants.applicableGroups.Validator3GI) ||
            this.roleService.isInRole(Constants.applicableGroups.Validator3GP) ||
            this.roleService.isInRole(Constants.applicableGroups.Admin);
    }

    downloadData(fileType: string) {
        this.fileDownloading = true;
        this.adminPanelService
            .downloadSourceFile(fileType)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (response: HttpResponse<ArrayBuffer>) => {
                    let fileName = response.headers.get('content-disposition');
                    this.fileDownloading = false;
                    if (response.body.byteLength === 0) {
                        this.toastService.Info(`There is no file data.`);
                        return;
                    }
                    const blob = new Blob([response.body], {
                        type: 'application/octet-stream',
                    });
                    if (fileName) {
                        fileName = fileName.split('filename=')[1].split(';')[0].replace(/"/g, "");
                    }
                    saveAs(blob, fileName);
                },
                () => {
                    this.fileDownloading = false;
                    this.toastService.Error(
                        'Error occurred while downloading file data. Please contact Program Administrator.'
                    );
                }
            );
    }
}
