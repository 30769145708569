import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import * as _ from 'lodash';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { PliImplementationTeam } from 'src/app/models/pli-implementation-team';

@Component({
  selector: 'app-pli-implementation-team',
  templateUrl: './pli-implementation-team.component.html',
  styleUrls: ['./pli-implementation-team.component.scss']
})
export class PliImplementationTeamComponent implements OnInit {
  isLoading = false;
  implementationTeams: PliImplementationTeam[] = [];
  displayedColumns = ['name', 'actions'];
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private lookupService: LookupService,
    private configurationService: ConfigurationService,
    private toastService: ToastService

  ) { 

    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
    );
    this.iconRegistry.addSvgIcon(
      'delete',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
    );
    this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));

  }

  ngOnInit(): void {
    this.lookupService
      .getPliImplementationTeam()
      .pipe(take(1))
      .subscribe((data) => {
        this.implementationTeams = [
          ...data.map((d) => ({ id: d.id, name: d.name, isInEditMode: false, prevName: '' })),
        ];
      });
  }

  add() {
    this.implementationTeams = [...this.implementationTeams, { id: 0, name: '', isInEditMode: true, prevName: '' }];
  }

  remove(element: PliImplementationTeam) {
    this.isLoading = true;
    this.configurationService
      .deletePliImplementationTeam(element.id)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false)),
        catchError((error) => {
          this.toastService.Error(
            'Error has occurred while deleting PLI Implementation Team. Please contact Program Administrator.'
          );
          return of(error);
        })
      )
      .subscribe((id) => {
        this.implementationTeams = [..._.filter(this.implementationTeams, (c) => c.id !== id)];
        this.toastService.Success('PLI Implementation Team has been removed.');
      });
  }

  enterEditMode(element: PliImplementationTeam) {
    element.isInEditMode = true;
    element.prevName = element.name;
  }

  cancelEdit(element: PliImplementationTeam) {
    element.isInEditMode = false;
    element.name = element.prevName;
    this.implementationTeams = [..._.filter(this.implementationTeams, (c) => c.id > 0)];
  }

  update(element: PliImplementationTeam) {
    this.isLoading = true;
    this.configurationService
      .updatePliImplementationTeam(element)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          element.isInEditMode = false;
        }),
        catchError((error) => {
          this.toastService.Error(
            'Error has occurred while updating/creating PLI Implementation Team. Please contact Program Administrator.'
          );
          return of(error);
        })
      )
      .subscribe((data) => {
        this.toastService.Success(
          `PLI Implementation Team has been successfully ${data.id !== element.id ? 'created.' : 'updated.'}`
        );
        element.id = data.id;
      });
  }

}
