import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
// tslint:disable-next-line: max-line-length
import {
    ProjectTeamRoleMaintenanceActionTypes,
    ProjectTeamRoleFilterSuccess,
    ProjectTeamRoleFilterError,
    ProjectTeamRoleSaveSuccess,
    ProjectTeamRoleSaveRequest,
    ProjectTeamRoleSaveError,
    ProjectTeamRoleRemoveSuccess,
    ProjectTeamRoleRemoveError,
    ProjectTeamRoleRemoveRequest,
    ProjectTeamRoleFilterRequest,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { ApplicationState } from '../../../model';
import { ProjectTeamRolesMaintenanceService } from 'src/app/services/api/webapi-services/project-team-roles-maintenance.service';

@Injectable()
export class ProjectTeamRoleMaintenanceEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private projectTeamRolesMaintenanceService: ProjectTeamRolesMaintenanceService,
        private toastService: ToastService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType(ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleFilterRequest),
        withLatestFrom(this.store.select((store) => store.projectTeamRoleMaintenanceState.filter)),
        mergeMap(([, filter]) => {
            return this.projectTeamRolesMaintenanceService.get(filter).pipe(
                map((dataPagination) => {
                    return new ProjectTeamRoleFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering ProjectTeamRole data. Please contact Program Administrator.'
                    );
                    return of(new ProjectTeamRoleFilterError(error));
                })
            );
        })
    );

    @Effect()
    saveProjectTeamRoleRequest$ = this.actions$.pipe(
        ofType<ProjectTeamRoleSaveRequest>(ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleSaveRequest),
        mergeMap((action) => {
            return this.projectTeamRolesMaintenanceService.update(action.payload).pipe(
                map(() => {
                    return new ProjectTeamRoleSaveSuccess();
                }),
                catchError((error) => {
                    action.payload.name = action.payload.prevName;
                    if (error.status === 409) {
                        this.toastService.Error('Name (in this Project Team) entered already exists in SCMT.');
                    } else {
                        this.toastService.Error(
                            'Error occurred while saving a ProjectTeamRole. Please contact Program Administrator.'
                        );
                    }
                    return of(new ProjectTeamRoleSaveError(error));
                })
            );
        })
    );

    @Effect()
    removeProjectTeamRoleRequest$ = this.actions$.pipe(
        ofType<ProjectTeamRoleRemoveRequest>(ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleRemoveRequest),
        mergeMap((action) => {
            return this.projectTeamRolesMaintenanceService.remove(action.id).pipe(
                switchMap(() => [new ProjectTeamRoleRemoveSuccess(), new ProjectTeamRoleFilterRequest()]),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while removing a ProjectTeamRole. Please contact Program Administrator.'
                    );
                    return of(new ProjectTeamRoleRemoveError(error));
                })
            );
        })
    );
}
