import {
    Component,
    ElementRef,
    ViewChild,
    Input,
    forwardRef,
    OnInit,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { Observable, timer, iif, of, Subject } from 'rxjs';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { startWith, debounce, tap, takeWhile, filter, switchMap, exhaustMap, scan, take } from 'rxjs/operators';
import { BaseComponent } from '../../../../components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ToastService } from 'src/app/services/shared/toast.service';
import * as _ from 'lodash';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomActionSettings } from 'src/app/models/custom-action-settings';

@Component({
    selector: 'app-multiple-items-selector',
    templateUrl: './multiple-items-selector.component.html',
    styleUrls: ['./multiple-items-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultipleItemsSelectorComponent),
            multi: true,
        },
    ],
})
export class MultipleItemsSelectorComponent extends BaseComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input() propertyToShow = 'id';
    @Input() secondPropertyToShow = null;
    @Input() placeholder = '';
    @Input() isAsync = false;
    @Input() canAddManually = false;
    @Input() invalid = false;
    @Input() errorMessage = '';
    @Input() isTableAutoComplete = false;
    @Input() required: boolean;
    @Input() searchFunc: (id?: string, take?: number, page?: number) => Observable<any[]>;
    @Input() openSearchPopupCallback: () => void;
    @Input() setInput: EventEmitter<SetInputEventArgs>;
    @Input() displayedColumns = ['id'];
    @Input() setValue: any;
    @Input() filterForm: UntypedFormGroup;
    @Input() formSetter: string;
    @Input() oneItemMode = false;
    @Input() isDisabled = false;
    @Input() isSelectAll = true;
    @Input() panelWidth = null;
    @Input() reloadOnOpen = false;
    @Input() appearance = null;
    @Input() customActions: CustomActionSettings[] = [];

    @Output() autocompleteClosed: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemRemoved: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('chipInput', { static: true }) chipInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;

    itemsSelected: any[] = [];
    itemsAvailable: any[] = [];
    isLoading = true;
    placeholderText: string;
    noResultFound = false;
    dataSource: MatTableDataSource<any>;
    itemCtrl = new UntypedFormControl('');
    separatorKeysCodes: number[] = [ENTER, COMMA];
    nextPage$ = new Subject();
    previouslyEmittedItems = [];
    selectAll = false;
    inCreateNewMode = false;

    propagateChange = (_: any) => {};
    propagateTouched = (_: any) => {};

    constructor(
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();
        this.dataSource = new MatTableDataSource([]);

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'duplicate',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/duplicate.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete_gray',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete_gray.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    writeValue(value: any[]) {
        if (value) {
            this.dataSource.data = this.dataSource.data.map((d) => ({ ...d, Selected: undefined }));
            this.dataSource.data
                .filter((d) => value.some((v) => this.compareItems(d, v)))
                .forEach((d) => (d.Selected = true));
            this.itemsSelected = value;
        }
    }

    selectAllChange() {
        this.selectAll = !this.selectAll;
        if (this.selectAll) {
            this.isLoading = true;
            this.dataSource.data = this.dataSource.data.map((r) => ({ ...r, Selected: true }));
            this.searchFunc(this.itemCtrl.value, 1000000, 0)
                .pipe(take(1))
                .subscribe((data) => {
                    this.itemsSelected = data;
                    this.isLoading = false;
                });
        } else {
            this.dataSource.data.forEach((r) => (r.Selected = false));
            this.itemsSelected = [];
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) {
        this.propagateTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
        if (isDisabled) {
            this.itemCtrl.disable({ emitEvent: false });
        } else {
            this.itemCtrl.enable({ emitEvent: false });
        }
    }

    ngOnInit(): void {
        if (this.customActions.length > 0) {
            this.customActions.forEach((item) => this.displayedColumns.push(item.name));
        }

        if (this.oneItemMode) {
            this.isSelectAll = false;
        }
        if (this.setInput) {
            this.setInput.pipe(takeWhile(() => this.isAlive === true)).subscribe((args: SetInputEventArgs) => {
                if (args.itemList.length > 0) {
                    if (args.preserveInputValue) {
                        let result = _.unionBy(this.itemsSelected, args.itemList, this.propertyToShow);
                        this.itemsSelected = [...result];
                        this.itemsSelected.forEach((x) => this.selected(x));
                        this.dataSource.data
                            .filter((r) => this.itemsSelected.findIndex((i) => this.compareItems(i, r)) !== -1)
                            .forEach((r) => (r.Selected = true));
                    } else {
                        this.itemsSelected = [...args.itemList];
                        this.itemsSelected.forEach((x) => this.selected(x));
                        this.dataSource.data
                            .filter((r) => this.itemsSelected.findIndex((i) => this.compareItems(i, r)) !== -1)
                            .forEach((r) => (r.Selected = true));
                    }
                } else {
                    if (args.preserveInputValue) {
                        this.itemCtrl.setValue(this.itemCtrl.value);
                    } else {
                        this.itemCtrl.setValue(args.input);
                        this.chipInput.nativeElement.value = args.input;
                        this.selectAll = false;
                    }
                }
            });
        }
        if (this.setValue) {
            this.writeValue(this.setValue);
        }

        this.required = this.required !== undefined;
        this.placeholderText = this.required ? this.placeholder + ' *' : this.placeholder;
        if (this.searchFunc && !this.isAsync && !this.reloadOnOpen) {
            this.searchFunc()
                .pipe(takeWhile(() => this.isAlive))
                .subscribe(
                    (data) => {
                        data.filter((r) => this.itemsSelected.findIndex((i) => this.compareItems(i, r)) !== -1).forEach(
                            (r) => (r.Selected = true)
                        );
                        this.itemsAvailable = !this.isAsync ? data : [];
                        this.dataSource.data = data;
                        this.noResultFound = false;
                    },
                    () => {
                        this.toastService.Error(
                            'Error occurred while searching. Please contact Program Administrator.'
                        );
                    }
                );
        }

        this.itemCtrl.valueChanges
            .pipe(
                takeWhile(() => this.isAlive),
                tap((value) => {
                    this.isLoading = this.isAsync;
                    if (value) {
                        this.selectAll = false;
                    }
                    this.changeDetectorRef.detectChanges();
                }),
                debounce(() => timer(this.isAsync ? 800 : 0)),
                startWith(''),
                filter((value) => typeof value === 'string' && this.searchFunc !== undefined),
                switchMap((value) => iif(() => this.isAsync, this.applyInfiniteScroll(value), of(this.filter(value))))
            )
            .subscribe((data) => {
                data.filter((r) => this.itemsSelected.findIndex((i) => this.compareItems(i, r)) !== -1).forEach(
                    (r) => (r.Selected = true)
                );
                this.dataSource.data = data;
                this.isLoading = false;
                this.noResultFound = data ? data.length === 0 : true;
                if (this.isAlive) {
                    this.changeDetectorRef.detectChanges();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['placeholder']) {
            this.placeholderText = this.required ? this.placeholder + ' *' : this.placeholder;
        }
    }

    applyInfiniteScroll(value: string) {
        let currentPage = 0;
        return this.nextPage$.pipe(
            startWith(currentPage),
            exhaustMap(() => this.searchFunc(value, 10, currentPage)),
            takeWhile((newItems: any[]) => {
                if (newItems.length === 0 && currentPage === 0) {
                    this.isLoading = false;
                    this.noResultFound = true;
                    this.dataSource.data = [];
                }
                return newItems.length > 0;
            }),
            scan((allItems, newItems) => {
                if (currentPage === 0) {
                    allItems = [];
                }
                return allItems.concat(newItems);
            }, []),
            tap(() => currentPage++)
        );
    }

    remove = (item: string) => {
        if (!this.isTableAutoComplete) {
            this.dataSource.data = this.dataSource.data.map((r) => {
                if (this.compareItems(r, item)) {
                    return {
                        ...r,
                        Selected: undefined,
                    };
                }
                return r;
            });
        }
        const index = this.itemsSelected.indexOf(item);
        if (index >= 0) {
            this.itemsSelected = this.itemsSelected.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        Selected: false,
                    };
                }
                return item;
            });
            this.dataSource.data.filter((r) => this.compareItems(r, item)).forEach((r) => (r.Selected = false));
            this.itemsSelected.splice(index, 1);
        }

        if (this.filterForm) {
            this.filterForm.controls[this.formSetter].setValue(this.itemsSelected, { emitEvent: false });
        }
        this.itemRemoved.emit(this.itemsSelected);
        this.itemCtrl.setValue(this.chipInput.nativeElement.value, { emitEvent: false });
        this.propagateChange(this.itemsSelected);
        this.previouslyEmittedItems = [...this.itemsSelected];
        this.selectAll = false;
    };

    onSelect = (item: any) => {
        const selectedItemIndex = this.itemsSelected.findIndex((i) => this.compareItems(i, item));
        if (!item.Selected && selectedItemIndex === -1) {
            this.selected(item);
        } else {
            this.itemsSelected
                .filter((r) => this.compareItems(r, item))
                .forEach((i) => {
                    const itemToDeleteIndex = this.itemsSelected.indexOf(i);
                    this.itemsSelected.splice(itemToDeleteIndex, 1);
                });
            item.Selected = false;
            this.dataSource.data.filter((r) => this.compareItems(r, item)).forEach((r) => (r.Selected = false));
            if (this.filterForm) {
                this.filterForm.controls[this.formSetter].setValue(this.itemsSelected);
            }
            this.selectAll = false;
            this.chipInput.nativeElement.blur();
            this.changeDetectorRef.detectChanges();
        }
    };

    selected = (item: any) => {
        if (!item.Selected && this.itemsSelected.findIndex((i) => this.compareItems(i, item)) === -1) {
            if (this.oneItemMode && this.itemsSelected.length === 1) {
                let previouslySelected = this.dataSource.data.find((r) => this.compareItems(r, this.itemsSelected[0]));
                if (previouslySelected) {
                    previouslySelected.Selected = false;
                }
                this.itemsSelected = [item];
            } else {
                this.itemsSelected = [...this.itemsSelected, item];
            }

            item.Selected = true;
            this.dataSource.data.filter((r) => this.compareItems(r, item)).forEach((r) => (r.Selected = true));

            if (this.filterForm) {
                this.filterForm.controls[this.formSetter].setValue(this.itemsSelected);
            }
            this.chipInput.nativeElement.blur();
            this.changeDetectorRef.detectChanges();
            if (this.oneItemMode) {
                this.autocomplete.closePanel();
            }
        }
    };

    closed = () => {
        if (this.shouldEmitItems()) {
            this.propagateChange(this.itemsSelected);
            this.previouslyEmittedItems = [...this.itemsSelected];
        }
        this.autocompleteClosed.emit(this.itemsSelected);
        this.chipInput.nativeElement.value = '';
        this.itemCtrl.setValue('', { emitEvent: false });
        this.inCreateNewMode = false;
    };

    opened = () => {
        if (this.reloadOnOpen && this.searchFunc) {
            setTimeout(() => (this.isLoading = true));
            this.searchFunc()
                .pipe(takeWhile(() => this.isAlive))
                .subscribe(
                    (data) => {
                        data.filter((r) => this.itemsSelected.findIndex((i) => this.compareItems(i, r)) !== -1).forEach(
                            (r) => (r.Selected = true)
                        );
                        this.itemsAvailable = !this.isAsync ? data : [];
                        this.dataSource.data = data;
                        this.noResultFound = data ? data.length === 0 : true;
                        this.isLoading = false;
                    },
                    () => {
                        this.toastService.Error(
                            'Error occurred while searching. Please contact Program Administrator.'
                        );
                    }
                );
        }
    };

    getValue() {
        return this.itemsSelected
            .map((elem) => {
                return elem[this.propertyToShow];
            })
            .join(', ');
    }

    addManual(event: any, input: any) {
        if (this.canAddManually && event.keyCode === ENTER && input.value !== '') {
            if (this.itemsSelected.map((i) => i[this.propertyToShow]).indexOf(input.value) !== -1) {
                input.value = '';
                return;
            }

            const obj: any = {};
            obj[this.propertyToShow] = input.value;
            obj.Manual = true;

            if (this.oneItemMode && this.itemsSelected.length === 1) {
                let previouslySelected = this.dataSource.data.find((r) => this.compareItems(r, this.itemsSelected[0]));
                if (previouslySelected) {
                    previouslySelected.Selected = false;
                }
                this.itemsSelected = [obj];
            } else {
                this.itemsSelected.push(obj);
            }

            this.propagateChange(this.itemsSelected);
            this.previouslyEmittedItems = [...this.itemsSelected];
            input.value = '';

            if (this.oneItemMode) {
                this.autocomplete.closePanel();
            }
        }
    }

    private shouldEmitItems = () => {
        const prevItems = this.previouslyEmittedItems.map((i) => i[this.propertyToShow]).sort();
        const currItems = this.itemsSelected.map((i) => i[this.propertyToShow]).sort();

        return !(
            prevItems.length === currItems.length && currItems.every((value, index) => value === prevItems[index])
        );
    };

    private filter(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.itemsAvailable.filter((i) => i[this.propertyToShow].toLowerCase().indexOf(filterValue) === 0);
    }

    private compareItems(i1: any, i2: any) {
        return this.propertyToShow ? i1[this.propertyToShow] === i2[this.propertyToShow] : i1 === i2;
    }

    onScroll() {
        this.nextPage$.next();
    }

    onCreateNewElement() {
        this.inCreateNewMode = !this.inCreateNewMode;
    }

    get getCreateNewPlaceholderText() {
        return !this.inCreateNewMode ? `New ${this.placeholder}` : `Enter new ${this.placeholder} name`;
    }
}
