import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap, takeWhile, take, delay, finalize } from 'rxjs/operators';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ChangeDocumentDeleteDTO, ChangeDocumentImportDTO } from 'src/app/models/change-document-dto';
import { OrderDirection } from 'src/app/store/common.model';
import { ChangeDocumentDeltaFilter } from 'src/app/models/change-document-delta-filter';
import * as moment from 'moment';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { ImportType } from '../import-type';
import { LiveDataService } from 'src/app/services/api/webapi-services/live-data.service';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import * as _ from 'lodash';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-change-document',
    templateUrl: './change-document.component.html',
    styleUrls: ['./change-document.component.scss'],
})
export class ChangeDocumentComponent extends BaseComponent implements OnInit {
    isAdmin = false;
    isImportHistoryLoading = false;
    fileUploading: boolean;
    processInProgress: boolean;
    fileDownloading: boolean;
    showOrder: boolean = true;
    readonly displayedColumns: string[] = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    readonly dataTypes = Constants.changeTypes;
    displayedDCNTmpDataColumns: string[] = [
        'changeNumber',
        'projectTeamName',
        'space',
        'contractTmp',
        'forward',
        'contractLive',
        'space',
        'accept',
    ];

    displayedDeleteDataColumns = ['number', 'contract', 'projectTeamName', 'actions'];

    oneColumnTopHeader: string[] = ['zeroGroup', 'firstDesc', 'space', 'secondDesc'];
    displayedTmpDataColumns: string[] = this.displayedDCNTmpDataColumns;
    displayedTmpTopHeader: string[] = this.oneColumnTopHeader;
    dataTypesReadyToProcess: Array<boolean> = new Array(this.dataTypes.length);
    datatypesRecordsForDeletion: Array<number> = new Array(this.dataTypes.length);
    dataTypesInProgress: Array<boolean> = new Array(this.dataTypes.length);
    dataSource: MatTableDataSource<UpdateDataLog>;
    @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

    tmpData: ChangeDocumentImportDTO[] = [];
    deleteData: MatTableDataSource<ChangeDocumentDeleteDTO>;
    deleteDataResultsLength = 0;
    deleteDataPageSize = 10;
    resultsLength = 0;
    sortBy = 'changeNumber';
    direction = OrderDirection.Desc;
    pageSize = 10;
    deltaFilter: ChangeDocumentDeltaFilter = new ChangeDocumentDeltaFilter();
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    isDeltaLoading = false;
    isDeleteDataLoading = false;
    deleteDataPage = 0;
    showDelta = true;
    RFIRegisterName = 'RFI Register.xlsx';
    NCRRegisterName = 'Tengiz NCR.xls';
    ContractorNCRRegisterName = 'Contractor NCR.xlsx';
    PCNRegisterName = 'PCN Register.xlsx';
    SurveillanceRegisterName = 'Surveillance.xlsx';
    MACDCNRegisterName = 'MAC DCN List.xlsx';
    Materials = 'Materials_template.xlsx'
    logSubscription$: Subscription;
    changeDocType: string = 'DCN';
    isConfigLoading = false;
    isProcessButtonDisabled = false;
    recordsForDeletion = 0;
    isUploadButtonDisabled = false;

    constructor(
        private adminPanelService: AdminPanelService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private roleService: RoleService,
        private popupService: PopupService,
        private liveDataService: LiveDataService,
        private configurationService: ConfigurationService,
        private route: ActivatedRoute
    ) {
        super();
        this.deleteData = new MatTableDataSource<ChangeDocumentDeleteDTO>();
    }

    ngOnInit() {
        this.route.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap: ParamMap) => {
            setTimeout(() => {
                let routeType = paramMap.get('type');
                if (routeType) {
                    this.tabGroup.selectedIndex = this.dataTypes.indexOf(routeType.toUpperCase());
                }
                this.isProcessButtonDisabled = !this.dataTypesReadyToProcess[this.tabGroup.selectedIndex];
                this.recordsForDeletion = this.datatypesRecordsForDeletion[this.tabGroup.selectedIndex];
                this.isUploadButtonDisabled =
                    this.dataTypes[this.tabGroup.selectedIndex] === 'TMOC' ||
                    this.dataTypes[this.tabGroup.selectedIndex] === 'DCN' ||
                    this.dataTypes[this.tabGroup.selectedIndex] === 'SWIFTPLI' ||
                    this.dataTypes[this.tabGroup.selectedIndex] === 'RFI (iPIMS)' ||
                    this.dataTypes[this.tabGroup.selectedIndex] === 'SID' ||
                    this.dataTypes[this.tabGroup.selectedIndex] === 'PAS CR' ||
                    this.dataTypes[this.tabGroup.selectedIndex] === 'LeakTestPackTracker';
                this.getImportHistoryData([this.dataTypes[this.tabGroup.selectedIndex]]);
                this.getDeltaData();
            });
        });

        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.adminPanelService.downloadFileSubject$.pipe(takeWhile(() => this.isAlive)).subscribe((downloadSuccess) => {
            this.fileDownloading = false;

            if (!downloadSuccess) {
                this.toastService.Error('Error occured while downloading file. Please contact Program Administrator.');
            }
        });
        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.deltaFilter.sortBy = sortChange.active;
            this.deltaFilter.direction = OrderDirection[sortChange.direction];
            this.paginator.pageIndex = 0;
            this.getDeltaData();
        });
    }

    getImportHistoryData(types: string[], withDelay: boolean = false) {
        if (this.logSubscription$) {
            this.logSubscription$.unsubscribe();
        }
        let logsInterval = interval(Constants.logRefreshInterval).pipe(
            startWith(0),
            takeWhile(() => this.isAlive),
            delay(+withDelay * Constants.delayAfterUploadInterval),
            switchMap((_) => {
                this.isImportHistoryLoading = true;
                return this.adminPanelService.getLogs(types);
            })
        );
        this.logSubscription$ = logsInterval.pipe(takeWhile(() => this.isAlive)).subscribe(
            (data) => {
                this.isImportHistoryLoading = false;
                this.setDataInfo();
                this.dataSource = new MatTableDataSource(data);
                this.changeDetectorRef.detectChanges();
            },
            () => {
                this.toastService.Error('Error occurred while getting logs. Please contact Program Administrator.');
            }
        );
    }

    onTabChange($event: MatTabChangeEvent) {
        this.changeDocType = this.dataTypes[$event.index];
        this.getDeltaData();
        this.getImportHistoryData([this.dataTypes[this.tabGroup.selectedIndex]]);
        this.isUploadButtonDisabled =
            this.dataTypes[this.tabGroup.selectedIndex] === 'TMOC' ||
            this.dataTypes[this.tabGroup.selectedIndex] === 'DCN' ||
            this.dataTypes[this.tabGroup.selectedIndex] === 'SWIFTPLI' ||
            this.dataTypes[this.tabGroup.selectedIndex] === 'RFI (iPIMS)' ||
            this.dataTypes[this.tabGroup.selectedIndex] === 'SID' ||
            this.dataTypes[this.tabGroup.selectedIndex] === 'PAS CR' ||
            this.dataTypes[this.tabGroup.selectedIndex] === 'LeakTestPackTracker' ;
    }

    getDeltaData() {
        this.showDelta = this.tabGroup && this.dataTypesReadyToProcess[this.tabGroup.selectedIndex];
        this.changeDetectorRef.detectChanges();

        if (this.showDelta) {
            switch (this.dataTypes[this.tabGroup.selectedIndex]) {
                case 'DCN':
                case 'SWIFTPLI':
                case 'RFI':
                case 'NCR':
                case 'TMOC':
                case 'MAC DCN':
                case 'SID':
                case 'PAS CR':
                case 'LeakTestPackTracker':
                case 'Materials':
                    this.displayedTmpDataColumns = this.displayedDCNTmpDataColumns;
                    this.displayedTmpTopHeader = this.oneColumnTopHeader;
                    break;
                default:
                    break;
            }
            this.deltaFilter.type = this.dataTypes[this.tabGroup.selectedIndex];
            this.isDeltaLoading = true;
            this.adminPanelService
                .getDeltaData(this.deltaFilter)
                .pipe(take(1))
                .subscribe(
                    (data) => {
                        this.tmpData = data.items;
                        this.resultsLength = data.totalCount;
                        this.isDeltaLoading = false;
                    },
                    () => {
                        this.isDeltaLoading = false;
                        this.toastService.Error(
                            'Error occurred while getting Delta result table. Please contact Program Administrator.'
                        );
                    }
                );
            this.getDeleteData();
        }
    }

    onDeleteDataPaginatorChange(pageEvent: PageEvent) {
        this.deleteDataPage = pageEvent.pageIndex;
        this.deleteDataPageSize = pageEvent.pageSize;
        this.getDeleteData();
    }

    getDeleteData() {
        this.isDeleteDataLoading = true;
        this.adminPanelService
            .getChangeDocumentDeleteRecords(
                this.deleteDataPage,
                this.deleteDataPageSize,
                this.dataTypes[this.tabGroup.selectedIndex]
            )
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (deltaData) => {
                    this.isDeleteDataLoading = false;
                    this.deleteData = new MatTableDataSource(deltaData.items);
                    this.deleteDataResultsLength = deltaData.totalCount;
                },
                () => {
                    this.isDeleteDataLoading = false;
                    this.toastService.Error(
                        'Error occurred while getting delete data for document change. Please contact Program Administrator'
                    );
                }
            );
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.pageSize = pageEvent.pageSize;
        }
        this.deltaFilter.take = pageEvent.pageSize;
        this.deltaFilter.page = pageEvent.pageIndex;
        this.getDeltaData();
    }

    setDataInfo() {
        this.adminPanelService
            .getDataInfo(this.dataTypes)
            .pipe(take(1))
            .subscribe(
                (data: any) => {
                    this.dataTypesReadyToProcess = data.readyToProcess;
                    this.isProcessButtonDisabled = !this.dataTypesReadyToProcess[this.tabGroup.selectedIndex];
                    this.datatypesRecordsForDeletion = data.recordsForDeletion;
                    this.recordsForDeletion = this.datatypesRecordsForDeletion[this.tabGroup.selectedIndex];
                    this.dataTypesInProgress = data.inProgress;
                    this.getDeltaData();
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while refreshing buttons status. Please contact Program Administrator.'
                    );
                }
            );
    }

    fileChangeEvent(files: Array<File>) {
        const filesWrapper = new Array<any>();
        filesWrapper.push(...files);
        if (this.changeDocType === Constants.changeTypes[4] ) {
            this.isConfigLoading = true;
            this.configurationService
                .getConfigData()
                .pipe(
                    takeWhile(() => this.isAlive),
                    finalize(() => (this.isConfigLoading = false))
                )
                .subscribe((configData) => {
                    const expectedFilenames = configData
                        .find((x) => x.key === `Filenames for ${this.changeDocType} Import`)
                        .value.split(',')
                        .map((n) => n.trim());

                    if (!_.every(expectedFilenames, (fileName) => filesWrapper.some((f) => f.name === fileName))) {
                        this.toastService.Error(
                            'Please select all expected files: ' +
                                expectedFilenames.join(', ') +
                                ' (you can change it in the configuration)'
                        );
                        return;
                    }
                    this.uploadFiles(filesWrapper);
                });
        } else {
            this.uploadFiles(filesWrapper);
        }
    }

    uploadFiles(filesWrapper: Array<any>) {
        this.showOrder = false;

        if (this.tabGroup) {
            this.dataTypesReadyToProcess[this.tabGroup.selectedIndex] = false;
            this.isProcessButtonDisabled = true;
            this.datatypesRecordsForDeletion[this.tabGroup.selectedIndex] = 0;
            this.recordsForDeletion = 0;
            this.dataTypesInProgress[this.tabGroup.selectedIndex] = true;
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = this.dataTypes[this.tabGroup.selectedIndex];
            updateLog.startDate = moment();
            const data = this.dataSource.data;
            data.unshift(updateLog);
            this.dataSource.data = data;
        }

        const formData = new FormData();
        filesWrapper.forEach((file) => {
            const fileName = file.name || file.fileName;
            formData.append(this.dataTypes[this.tabGroup.selectedIndex], file, fileName);
        });
        this.fileUploading = true;
        this.logSubscription$.unsubscribe();
        this.adminPanelService
            .uploadFiles(formData)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.getImportHistoryData([this.dataTypes[this.tabGroup.selectedIndex]], true);
                    this.fileUploading = false;
                    this.toastService.Success(
                        'Files successfully uploaded. Please check progress indicator and table with logs to check status of preprocessing.'
                    );
                },
                () => {
                    this.fileUploading = false;
                    this.toastService.Error(
                        'Error occurred while uploading files. Please contact Program Administrator.'
                    );
                }
            );
    }

    downloadData(filePath: string) {
        this.fileDownloading = true;
        this.adminPanelService.downloadFile(filePath);
    }

    process() {
        let warning = this.recordsForDeletion > 0 ?
        `<p><span class="warning"">Warning: </span> This action will delete ${this.recordsForDeletion} record(s).</p>` : '';
        
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    htmlText: `<p>Are you sure you want to import data and update existing records?</p>`
                    + warning,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    this.processInProgress = true;
                    this.adminPanelService
                        .processDataSource(this.dataTypes[this.tabGroup.selectedIndex])
                        .pipe(takeWhile(() => this.isAlive))
                        .subscribe(
                            () => {
                                this.dataTypesReadyToProcess[this.tabGroup.selectedIndex] = false;
                                this.isProcessButtonDisabled = true;
                                this.datatypesRecordsForDeletion[this.tabGroup.selectedIndex] = 0;
                                this.recordsForDeletion = 0;
                                this.processInProgress = false;
                                this.getDeltaData();
                            },
                            () => {
                                this.processInProgress = false;
                                this.toastService.Error(
                                    'Error occurred while processing data type. Please contact Program Administrator'
                                );
                            }
                        );
                }
            });
    }

    acceptOrRejectChange(item: ChangeDocumentImportDTO) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to accept current change for the record ${item.changeNumber}?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    this.processInProgress = true;
                    this.adminPanelService
                        .acceptChange(item)
                        .pipe(takeWhile(() => this.isAlive))
                        .subscribe(
                            () => {
                                this.processInProgress = false;
                                this.getDeltaData();
                            },
                            () => {
                                this.processInProgress = false;
                                this.toastService.Error(
                                    'Error occurred while accepting document change. Please contact Program Administrator'
                                );
                            }
                        );
                }
            });
    }

    deleteChangeDocument(element: ChangeDocumentDeleteDTO) {
        element.deleteState = true;
        this.isDeleteDataLoading = true;
        this.adminPanelService
            .patchChangeDocumentDeleteRecord(element.id, true)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.isDeleteDataLoading = false;
                    this.setDataInfo();
                },
                () => {
                    this.isDeleteDataLoading = false;
                    this.toastService.Error(
                        'Error occurred while deleting document change. Please contact Program Administrator'
                    );
                }
            );
    }

    restoreChangeDocument(element: ChangeDocumentDeleteDTO) {
        element.deleteState = false;
        this.isDeleteDataLoading = true;
        this.adminPanelService
            .patchChangeDocumentDeleteRecord(element.id, false)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.isDeleteDataLoading = false;
                    this.setDataInfo();
                },
                () => {
                    this.isDeleteDataLoading = false;
                    this.toastService.Error(
                        'Error occurred while restoring document change. Please contact Program Administrator'
                    );
                }
            );
    }

    patchAllDeleteRecords(deleteState: boolean) {
        this.deleteData.data.forEach((s) => (s.deleteState = deleteState));
        this.isDeleteDataLoading = true;
        this.adminPanelService
            .patchChangeDocumentDeleteAllRecords(deleteState, this.dataTypes[this.tabGroup.selectedIndex])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.isDeleteDataLoading = false;
                    this.setDataInfo();
                },
                () => {
                    this.isDeleteDataLoading = false;
                    this.toastService.Error(
                        'Error occurred while restoring/deleting all document changes. Please contact Program Administrator'
                    );
                }
            );
    }

    downloadRegisterTemplate(name: string) {
        this.fileDownloading = true;
        this.adminPanelService.downloadRegisterTemplate(name);
    }

    downloadSourceFile() {
        this.fileDownloading = true;
        let type = this.dataTypes[this.tabGroup.selectedIndex];
        this.adminPanelService.downloadSourceFile(type).subscribe(
            (resp: HttpResponse<ArrayBuffer>) => {
                this.fileDownloading = false;
                if (resp.body.byteLength === 0) {
                    this.toastService.Info('There is no source import file data.');
                    return;
                }
                const blob = new Blob([resp.body], {
                    type: 'application/octet-stream',
                });
                let extension = 'xlsx';
                if (type === 'Contractor NCR' || type === 'Surveillance') {
                    extension = 'zip';
                }
                saveAs(blob, `source_file_${type}.${extension}`);
            },
            () => {
                this.fileDownloading = false;
                this.toastService.Error(
                    'Error occurred while downloading source file data. Please contact Program Administrator.'
                );
            }
        );
    }

    downloadLiveFile() {
        this.fileDownloading = true;
        let importType: ImportType;

        switch (this.dataTypes[this.tabGroup.selectedIndex]) {
            case 'MAC DCN':
                importType = ImportType.MACDCN;
                break;
            case 'DCN':
                importType = ImportType.DCN;
                break;
            case 'TMOC':
                importType = ImportType.TMOC;
                break;
            case 'RFI':
                importType = ImportType.RFI;
                break;
            case 'NCR':
                importType = ImportType.NCR;
                break;
            case 'PCN':
                importType = ImportType.PCN;
                break;
            case 'Contractor NCR':
                importType = ImportType.ContractorNCR;
                break;
            case 'Surveillance':
                importType = ImportType.Surveillance;
                break;
            case 'RFI (iPIMS)':
                importType = ImportType.RFIIPIMS;
                break;
            case 'SID':
                importType = ImportType.SID;
                break;
            case 'PAS CR':
                importType = ImportType.PASCR;
                break;
            case 'LeakTestPackTracker':
                importType = ImportType.LeakTestPackTracker;
                break;
            case 'Materials':
                importType = ImportType.Materials;
                break;
            case 'SWIFTPLI':
                    importType = ImportType.SWIFTPLI;
                    break;
            default:
                break;
        }
        this.liveDataService.downloadLiveDataAsync(importType).subscribe(
            (data: any) => {
                const blob = new Blob([data], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, `livedata_${ImportType[importType]}_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);

                this.fileDownloading = false;
            },
            () => {
                this.fileDownloading = false;
                this.toastService.Error(
                    'Error occurred while downloading live data. Please contact Program Administrator.'
                );
            }
        );
    }

    acceptOrRejectDocuments(acceptAll: boolean) {
        this.isDeltaLoading = true;
        this.adminPanelService
            .acceptAllDeltaRecords(this.dataTypes[this.tabGroup.selectedIndex], acceptAll)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (params) => {
                    this.getDeltaData();
                },
                (error) => {
                    this.isDeltaLoading = false;
                    this.toastService.Error(
                        'Error occurred while accepting or rejecting delta changes. Please contact Program Administrator.'
                    );
                }
            );
    }
}
