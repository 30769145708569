import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-configuration-panel-mcplus',
  templateUrl: './configuration-panel-mcplus.component.html',
  styleUrls: ['./configuration-panel-mcplus.component.scss']
})
export class ConfigurationPanelMCplusComponent extends BaseComponent implements OnInit {
  @Input() uploadType: string;
  constructor() { 
    super();
  }

  ngOnInit(): void {
    // This is intentional
  }

}