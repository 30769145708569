import * as moment from 'moment';
import {
    Milestone,
    Waypoint,
    OrderDirection,
    Gooc,
    Contractor,
    CalendarColumn,
    CheckListColumn,
    System,
    UserDetail,
    StagedStartUpPriority,
    NumericColumn,
} from '../common.model';

export interface RedZoneState {
    dataPagination: RedZoneDataPagination;
    filter: RedZoneFilter;
    isLoading: boolean;
}

export class RedZoneDataPagination {
    items: RedZoneDTO[] = [];
    totalCount = 0;
}

export class RedZoneFilter {
    page = 0;
    take = 25;
    sortBy = 'subsystem';
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    mcMilestones: Milestone[] = [];
    goocs: Gooc[] = [];
    subsystem = '';
    subsystemName = '';
    systems: System[] = [];
    mcEngineer: string[] = [];
    systemOwner: string[] = [];
    disciplines: string[] = [];
    acm: string[] = [];
    mcCompleted: boolean = null;
    mcCommitted: boolean = null;
    requiredForSteamblow: boolean = null;
    columnMCPlan: CalendarColumn = null;
    columnMCCommitment: CalendarColumn = null;
    columnMCForecast: CalendarColumn = null;
    columnMCActual: CalendarColumn = null;
    columnSubsystems: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnDisciplines: CheckListColumn = null;
    columnSubsystemNames: CheckListColumn = null;
    columnNonCommissionable: CheckListColumn = null;
    columnPriorityName: CheckListColumn = null;
    columnMCSequence: CheckListColumn = null;
    columnFlags: CheckListColumn = null;
    timezoneOffset = 0;
    columnDACActual: CalendarColumn = null;
    columnDACForecast: CalendarColumn = null;
    columnWalkdownActual: CalendarColumn = null;
    columnWalkdownForecast: CalendarColumn = null;
    columnWalkdownSch: CalendarColumn = null;
    columnFPLUpload: CalendarColumn = null;
    showHideColumns = new ShowHideColumns();
    disciplineBreakdownEnabled: boolean = false;
    constructionArea: string[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    columnGoocNo: CheckListColumn = null;
    columnConstruction90Walkdown: CalendarColumn = null;

    columnSafetyRewalkdown: NumericColumn = null;
    columnAqvDsRemaining: NumericColumn = null;
    columnAqvDsTotal: NumericColumn = null;
    columnAqvDsCompleted: NumericColumn = null;
    columnAqvDsPercentage: NumericColumn = null;
    columnApli: NumericColumn = null;
    columnNpw: NumericColumn = null;
    columnBitr: NumericColumn = null;
    columnBpli: NumericColumn = null;
    columnCpli: NumericColumn = null;
    columnDpli: NumericColumn = null;
    columnEx: NumericColumn = null;
    columnCow: NumericColumn = null;
    columnRedlinesRemaining: NumericColumn = null;
    columnRedlinesCompleted: NumericColumn = null;
    columnRedlinesTotal: NumericColumn = null;
    columnRedlinesPercentage: NumericColumn = null;
    columnFlangeMgtTotal: NumericColumn = null;
    columnFlangeMgtTotalCompleted: NumericColumn = null;
    columnFlangeMgtTotalRemaining: NumericColumn = null;
    columnFlangeMgtTotalPercentage: NumericColumn = null;
}

export class ShowHideColumns {
    showSubsystemName = true;
    showMCPlanDate = true;
    showMCCommitmentDate = false;
    showMCForecastDate = true;
    showMCActualDate = true;
    showAQVD = true;
    showAPLI = true;
    showNpw = true;
    showBPLI = false;
    showBITR = false;
    showCPLI = false;
    showDPLI = false;
    showExc = true;
    showRedline = true;
    showPriorityName = false;
    showNonComm = false;
    showDacStatus = false;
    showWalkdownForecast = true;
    showWalkdownScheduled = false;
    showWalkdownActual = false;
    showSafetyRewalkdown = false;
    showCOW = false;
    showAQVDTotalAndComplete = false;
    showRedlineTotalAndComplete = false;
    showFlangeMgt = false;
    showFPLUpload = false;
    showMCSequence = false;
    showGoocNo = false;
    showConstructionWalkdown = false;
}

export class RedZoneDTO {
    subsystemToDisciplineId: number;
    subsystem: string;
    subsystemName: string;
    discipline: string;
    mcPlan: moment.Moment;
    mcForecast: moment.Moment;
    mcActual: moment.Moment;
    safetyRewalkdown: number;
    aqvDsRemaining: number;
    aqvDsTotal: number;
    aqvDsCompleted: number;
    aqvDsPercentage: number;
    apli: number;
    npw: number;
    bitr: number;
    citr: number;
    bpli: number;
    cpli: number;
    dpli: number;
    ex: number;
    cow: number;
    comment: string;
    isReadyForMc = false;
    dacActual: moment.Moment;
    dacForecast: moment.Moment;
    walkdownActual: moment.Moment;
    walkdownSch: moment.Moment;
    walkdownForecast: moment.Moment;
    nonCommissionable: string;
    redlinesRemaining: number;
    redlinesCompleted: number;
    redlinesTotal: number;
    redlinesPercentage: number;
    flangeMgtTotal: number;
    flangeMgtTotalCompleted: number;
    flangeMgtTotalRemaining: number;
    flangeMgtTotalPercentage: number;
    isLastInGroup = false;
    mentions: UserDetail[];
    redZoneFlag: string;
    priorityName: string;
    goocNo: string;
    construction90Walkdown: moment.Moment;
}
