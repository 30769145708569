<main class="main">
    <a class="navigator" [routerLink]="['/admin']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            Upload Project Change Registers
        </div>
        <div class="buttons-container flex">
            <button
                class="sct-button sct-button-light margin-right20"
                (click)="process()"
                [disabled]="isProcessButtonDisabled"
            >
                validate & push
                <div class="change-doc-type">{{ changeDocType }}</div>
            </button>
            <button
                [disabled]="isUploadButtonDisabled"
                type="button"
                class="sct-button sct-button-light margin-right20"
                (click)="importFile.click($event)"
            >
                upload
            </button>
            <input
                multiple
                #importFile
                type="file"
                (click)="importFile.value = null"
                (change)="fileChangeEvent($event.target.files)"
            />
            <button
                *ngIf="isAdmin"
                type="button"
                class="sct-button sct-button-light margin-right20"
                (click)="downloadLiveFile()"
            >
                download live data
            </button>
            <button
                *ngIf="isAdmin"
                type="button"
                class="sct-button sct-button-light"
                (click)="downloadSourceFile()"
                [disabled]="isUploadButtonDisabled"
            >
                download last source
                <div class="change-doc-type">data</div>
            </button>
        </div>
    </div>
    <div class="import-container">
        <mat-tab-group #tabGroup (selectedTabChange)="onTabChange($event)" [animationDuration]="1000">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('DCN')] }">DCN</span>
                </ng-template>
                <h4>IMDB DCN import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    IMDB DCN is imported every day 6:00 AM and 12:00 (Tengiz time) from IMDB SQL. For all change
                    document types, next import processes are not removing old records, they are only adding new records
                    and overriding existing ones. Import also validate Contracts to match SCMT Contract no. There is a
                    delta validation rule that if Contract has changed for the given Change number, Admin needs to
                    accept that change in the admin delta validation page.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>DCN_NO (Number)</li>
                            <li>REV (Revision)</li>
                            <li>TITLE (Title)</li>
                            <li>PROJECT (Project Team)</li>
                            <li>AREA (Area)</li>
                            <li>DISCIPLINE (Discipline)</li>
                            <li>CWP (Cwp)</li>
                            <li>CONTRACT (Contract No)</li>
                            <li>STATUS (Status)</li>
                            <li>PDS_ISSUE_DATE (Issue Date)</li>
                            <li>AFFECTED_DOCS (Affected Deliverables)</li>
                            <li>REASON (Reason For Change)</li>
                            <li>DETAIL_DESCRIPTION (Remark)</li>
                            <li>CONSTRUCTION_IMPACT (Physical Verification Required)</li>
                            <li>V_DCN_TO_SUBSYS_SCMT.SUB_SYS (Subsystems)</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('SWIFT')] }">SWIFT</span>
                </ng-template>
                <h4>SWIFT import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    SWIFT is imported every day 6:00 AM and 12:00 (Tengiz time) from SWIFT SQL. Swift Material table will be removed and 
                    added on every import.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>Final Punch Item</li>
                            <li>Material Review Status</li>
                            <li>Material Review AssignedTo</li>                       
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('RFI')] }">RFI</span>
                </ng-template>
                <h4>RFI import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    See <b (click)="downloadRegisterTemplate(RFIRegisterName)">{{ RFIRegisterName }}</b> structure.
                    Records in upload file are updated into SCMT. Data only imported for matching Contractors in SCMT
                    using Source Data mapping rules. Foreign key links to any matching subsystems are made at upload
                    based on subsystems in upload file. Recommended sequence of data update: Contractor, Master
                    Subsystem List, RFI Register. Result file has a tab (Quarantine Results) that contains list of RFIs
                    in SCMT that are not found in the upload file (tagged as "RecordNotFound"). The Quarantine Result
                    tab also lists RFIs imported from the upload file that do not contain match to Design Status in SCMT
                    (tagged as "NoMatchingDesignStatus") - these data mapping rules are adjustable through Admin
                    Configuration page.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>File</li>
                            <li>Document No</li>
                            <li>Title</li>
                            <li>Revision</li>
                            <li>Status</li>
                            <li>Type</li>
                            <li>Date Modified</li>
                            <li>Revision Date</li>
                            <li>Company Name</li>
                            <li>Contract Number</li>
                            <li>Project Team</li>
                            <li>RFI Category</li>
                            <li>RFI Impact</li>
                            <li>Additional Comments</li>
                            <li>Red Line</li>
                            <li>Redline Doc No</li>
                            <li>Impacted Tags 1</li>
                            <li>Impacted Tags 2</li>
                            <li>Subsystems 1</li>
                            <li>Subsystems 2</li>
                            <li>Subsystems 3</li>
                            <li>CWP No.</li>
                            <li>Area</li>
                            <li>Unit</li>
                            <li>Technical Disciplines</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('NCR')] }">NCR</span>
                </ng-template>
                <h4>NCR import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    See <b (click)="downloadRegisterTemplate(NCRRegisterName)">{{ NCRRegisterName }}</b> structure. SCMT
                    synchronised to upload file. Data only imported for matching project team MEI scope (matching
                    "Contract Number" column). For existing NCRs that are validated in SCMT, any deltas in the upload
                    file against the Contractor field is highlighted for Admin action. Result file has a tab (Quarantine
                    Results) that contains list of NCRs in SCMT that are not found in the upload file.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>NCR Number</li>
                            <li>Issue Date</li>
                            <li>Client / Project</li>
                            <li>Contractor Name</li>
                            <li>Contract Number</li>
                            <li>Discipline</li>
                            <li>Contract Scope</li>
                            <li>Source</li>
                            <li>NCR Issued To</li>
                            <li>Response Due</li>
                            <li>NCR Date Completed</li>
                            <li>NCR Status</li>
                            <li>Business Line</li>
                            <li>Response</li>
                            <li>Risk Level</li>
                            <li>NCR Description</li>
                            <li>Tag Number</li>
                            <li>Turnover System</li>
                            <li>SubSystem</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('Contractor NCR')] }"
                        >Contractor NCR</span
                    >
                </ng-template>
                <h4>Contractor NCR import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    See
                    <b (click)="downloadRegisterTemplate(ContractorNCRRegisterName)">{{ ContractorNCRRegisterName }}</b>
                    structure. SCMT synchronised to upload file. Data only imported for matching project team MEI scope
                    (matching "Contract Number" column). For existing Contractor NCRs that are validated in SCMT, any
                    deltas in the upload file against the Contractor field is highlighted for Admin action. Result file
                    has a tab (Quarantine Results) that contains list of Contractor NCRs in SCMT that are not found in
                    the upload file.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>NCR No.</li>
                            <li>NCR Type</li>
                            <li>Issued Date</li>
                            <li>Client /Project</li>
                            <li>Business Partner Name</li>
                            <li>Contract Number</li>
                            <li>Discipline</li>
                            <li>Contract Scope</li>
                            <li>Source</li>
                            <li>Issued By</li>
                            <li>Response Due</li>
                            <li>Business Line</li>
                            <li>Response</li>
                            <li>Risk Level</li>
                            <li>NCR Description</li>
                            <li>Tag No.</li>
                            <li>Turnover System</li>
                            <li>Subsystem</li>
                            <li>Area</li>
                            <li>Status</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="false">
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('PCN')] }">PCN</span>
                </ng-template>
                <h4>PCN import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    See <b (click)="downloadRegisterTemplate(PCNRegisterName)">{{ PCNRegisterName }}</b> structure. SCMT
                    synchronised to upload file. Data only imported PCN type different than 'C'. For existing PCNs that
                    are validated in SCMT, any deltas in the upload file against the Contractor field is highlighted for
                    Admin action. Result file has a tab (Quarantine Results) that contains list of PCNs in SCMT that are
                    not found in the upload file.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>PCN ID</li>
                            <li>Title</li>
                            <li>App Status</li>
                            <li>PCN Type</li>
                            <li>Module</li>
                            <li>Modified Date</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="false">
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('Surveillance')] }"
                        >Surveillance</span
                    >
                </ng-template>
                <h4>Surveillance import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    See
                    <b (click)="downloadRegisterTemplate(SurveillanceRegisterName)">{{ SurveillanceRegisterName }}</b>
                    structure. SCMT synchronised to upload file. Data only imported for matching project team MEI scope
                    (matching "Contract Number" column). For existing Surveillance records that are validated in SCMT,
                    any deltas in the upload file against the Contractor field is highlighted for Admin action. Result
                    file has a tab (Quarantine Results) that contains list of Surveillance records in SCMT that are not
                    found in the upload file.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>REPORT NO.</li>
                            <li>PROJECT</li>
                            <li>SR NO.</li>
                            <li>ISSUED BY</li>
                            <li>B2B</li>
                            <li>CONTRACTOR/ISSUED TO</li>
                            <li>CONTRACT NO.</li>
                            <li>AREA</li>
                            <li>FACILITY</li>
                            <li>CWA / AREA</li>
                            <li>SUBSYSTEM No</li>
                            <li>TAG No</li>
                            <li>DISCIPLINE</li>
                            <li>DESCRIPTION</li>
                            <li>OBSERVATION</li>
                            <li>ACTION REQUIRED (yes/no)</li>
                            <li>DATE OPEN</li>
                            <li>STATUS</li>
                            <li>TARGET DATE</li>
                            <li>RECOMMENDED CORRECTIVE ACTION</li>
                            <li>COMMENT ON SRs OPEN GREATER THAN 30 DAYS</li>
                            <li>CURRENT ACTIONER</li>
                            <li>DATE CLOSED</li>
                            <li>CLOSED BY</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('TMOC')] }">TMOC</span>
                </ng-template>
                <h4>TMOC import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    For all change document types, next import processes are not removing old records, they are only
                    adding new records and overriding existing ones. Import also validate Contracts to match SCMT
                    Contract no. There is a delta validation rule that if Contract has changed for the given Change
                    number, Admin needs to accept that change in the admin delta validation page.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>MOC No</li>
                            <li>MOC Title</li>
                            <li>MOC Delivery Team</li>
                            <li>Area</li>
                            <li>Current Status</li>
                            <li>MOC Initiation Date</li>
                            <li>MOC RFINo</li>
                            <li>MOC Type Of Change</li>
                            <li>Sub System</li>
                            <li>Functional Reviews</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('MAC DCN')] }"
                        >MAC DCN</span
                    >
                </ng-template>
                <h4>MAC DCN import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    See
                    <b (click)="downloadRegisterTemplate(MACDCNRegisterName)">{{ MACDCNRegisterName }}</b> structure.
                    Records in upload file are updated into SCMT. Data only imported for matching subsystems in SCMT.
                    Foreign key links to existing Contractors in SCMT are made at upload using Source Data mapping rules
                    (Contract field would be BLANK if no match exists). Recommended sequence of data update: Contractor,
                    Master Subsystem List, MAC DCN. Result file has a tab (Quarantine Results) that contains list of MAC
                    DCNs in SCMT that are not found in the upload file (tagged as "RecordNotFound"). The Quarantine
                    Result tab also lists MAC DCNs imported from the upload file that do not contain match to Contractor
                    (tagged as "NoMatchingContractor"), or Design Status in SCMT (tagged as "NoMatchingDesignStatus"),
                    or Implementation Status in SCMT (tagged as "NoMatchingImplementationStatus") - these data mapping
                    rules are adjustable through Admin Configuration page.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>RIE</li>
                            <li>DCN Document Number</li>
                            <li>Rev</li>
                            <li>Issued Date</li>
                            <li>Title</li>
                            <li>SUBSYSTEM/COMM. PACK No.</li>
                            <li>Status</li>
                            <li>Contractor</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('RFI (iPIMS)')] }"
                        >RFI (iPIMS)</span
                    >
                </ng-template>
                <h4>RFI (iPIMS) import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    RFI (iPIMS) is imported every day 5:00 AM and 15:00 from chevron data lake. For all change document
                    types, next import processes are not removing old records, they are only adding new records and
                    overriding existing ones. Import also validate Contracts to match SCMT Contract no.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>QueryNo (Number)</li>
                            <li>Revision</li>
                            <li>Title</li>
                            <li>WBSArea (Impacted Area)</li>
                            <li>Discipline</li>
                            <li>RequestingScope (Contract No)</li>
                            <li>Status</li>
                            <li>DateOriginated (Issue Date)</li>
                            <li>AffectedTags (Impacted Tags)</li>
                            <li>OtherImpacts (Reason For Change)</li>
                            <li>QueryDescription (Remark)</li>
                            <li>SubSystem</li>
                            <li>QrCodeValue (QRCode)</li>
                            <li>Step Status (Current Step)</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('SID')] }">SID</span>
                </ng-template>
                <h4>SID import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    For all change document types, next import processes are not removing old records, they are only
                    adding new records and overriding existing ones. Import also validate Contracts to match SCMT
                    Contract no. There is a delta validation rule that if Contract has changed for the given Change
                    number, Admin needs to accept that change in the admin delta validation page.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>Description of Discrepancy</li>
                            <li>Subsystem</li>
                            <li>Discipline</li>
                            <li>Proposed Resolution</li>
                            <li>Action By</li>
                            <li>Status</li>
                            <li>SID Tag No</li>
                            <li>Project</li>
                            <li>Date Found</li>
                            <li>Reference Section of SID-SU-5106-TCO Walkways - Workspaces & Access</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('PASCR')] }">PAS CR</span>
                </ng-template>
                <h4>PAS CR import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    PAS CR Imports will happen twice a day, 6:00 AM and 2:00 PM to fetch data from sharepoint. Every Import will fetch only  'Open', 'Ready to Close' and 'Recycled' status records.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>Name</li>
                            <li>Title</li>
                            <li>SubSysDes</li>
                            <li>ApprovalStage</li>
                            <li>Status</li>
                            <li>ChangeType</li>
                            <li>OriginatorDate</li>
                            <li>TargetCompletionDate</li>
                            <li>RFI/RIMS</li>
                            <li>MOCReference</li>
                            <li>Associated Tags</li>
                        </ol>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('LeakTestPackTracker')] }">Leak Test Pack Tracker</span>
                </ng-template>
                <h4>Leak Test Pack Tracker import</h4>
                <div class="import-subtitle">
                    <button
                        mat-icon-button
                        (click)="showOrder = !showOrder"
                        title="{{
                            showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                        }}"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    Leak Test Pack Tracker Imports data from Certification portal to SCMT portal. It will happen twice in a day.
                </div>
                <div *ngIf="!showOrder" class="list-container">
                    <div class="list-structure">
                        <ol>
                            <li>LTP</li>
                            <li>Instrument Subsystem</li>
                            <li>Tag</li>
                            <li>Activity</li>
                            <li>Activity Status</li>
                            <li>Barcode</li>
                            <li>Comment</li>                            
                        </ol>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf('Materials')] }">Materials </span>
                </ng-template>
                <h4>Materials import</h4>
                <div class="import-subtitle">
                    <button
                    mat-icon-button                        
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                    See
                    <b (click)="downloadRegisterTemplate(Materials)">{{ Materials }}</b> structure. Manual upload of  Materials Imports data to SCMT portal. 
                    New update will come Once in a Week.
                </div>   
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="delta-container" [ngClass]="{ 'do-not-display': !showDelta }">
        <h4>Delta validation</h4>
        <span *ngIf="!tmpData.length">No delta validation data available.</span>
        <div class="changebuttons-container">
            <button (click)="acceptOrRejectDocuments(true)" *ngIf="tmpData.length" mat-icon-button title="accept">
                <mat-icon>check_circle_outline</mat-icon>
            </button>
            <span *ngIf="tmpData.length" class="margin-right20">Accept all changes</span>

            <button (click)="acceptOrRejectDocuments(false)" *ngIf="tmpData.length" mat-icon-button title="reject">
                <mat-icon>highlight_off</mat-icon>
            </button>
            <span *ngIf="tmpData.length">Reject all changes</span>
        </div>
        <table
            mat-table
            matSort
            [dataSource]="tmpData"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
            [ngStyle]="{ display: tmpData.length > 0 ? 'table' : 'none' }"
        >
            <ng-container matColumnDef="changeNumber">
                <th mat-sort-header disableClear mat-header-cell *matHeaderCellDef class="empty">Change Number</th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.changeNumber }}
                </td>
            </ng-container>
            <ng-container matColumnDef="projectTeamName">
                <th mat-sort-header disableClear mat-header-cell *matHeaderCellDef class="empty">
                    Current Project Team
                </th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.projectTeamName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="contractTmp">
                <th mat-header-cell *matHeaderCellDef class="cell-width-200 borderLeft borderRight">
                    Contract
                </th>
                <td mat-cell *matCellDef="let element" class="borderLeft borderRight">
                    {{ element.contractTmp }}
                </td>
            </ng-container>
            <ng-container matColumnDef="forward">
                <th mat-header-cell *matHeaderCellDef class="empty"></th>
                <td mat-cell *matCellDef="let element" class="empty">
                    <mat-icon>arrow_forward</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="space">
                <th mat-header-cell *matHeaderCellDef class="empty cell-width-30"></th>
                <td mat-cell *matCellDef="let element" class="empty"></td>
            </ng-container>
            <ng-container matColumnDef="contractLive">
                <th mat-header-cell *matHeaderCellDef class="cell-width-200 borderLeft borderRight">Contract</th>
                <td mat-cell *matCellDef="let element" class="borderLeft borderRight">
                    {{ element.contractLive }}
                </td>
            </ng-container>
            <ng-container matColumnDef="accept">
                <th mat-header-cell *matHeaderCellDef class="empty cell-width-75"></th>
                <td mat-cell *matCellDef="let element" class="empty">
                    <button
                        *ngIf="!element.isAccepted && element.isAccepted !== null"
                        mat-icon-button
                        (click)="acceptOrRejectChange(element)"
                        title="accept"
                    >
                        <mat-icon>check_circle_outline</mat-icon> Accept
                    </button>
                    <button
                        *ngIf="element.isAccepted"
                        mat-icon-button
                        (click)="acceptOrRejectChange(element)"
                        title="reject"
                    >
                        <mat-icon>highlight_off</mat-icon> Reject
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="zeroGroup">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"></th>
            </ng-container>
            <ng-container matColumnDef="firstDesc">
                <th mat-header-cell *matHeaderCellDef>Update in Upload File</th>
            </ng-container>
            <ng-container matColumnDef="secondDesc">
                <th mat-header-cell *matHeaderCellDef>Current Data in SCMT</th>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedTmpTopHeader" class="top-header"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedTmpDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedTmpDataColumns"></tr>
        </table>
        <mat-paginator
            [ngStyle]="{ visibility: tmpData.length > 0 ? 'visible' : 'hidden' }"
            [length]="resultsLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 15, 20]"
            (page)="onPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <div *ngIf="isDeltaLoading" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="delete-container" [ngClass]="{ 'do-not-display': !showDelta }">
        <div class="section-header">
            <h4>Records marked for deletion</h4>
            <span>
                - change documents not present in upload file; validate deletion of existing change document in live
                database</span
            >
        </div>
        <span *ngIf="!deleteData.data.length">No delete records available.</span>
        <div class="changebuttons-container">
            <button (click)="patchAllDeleteRecords(true)" *ngIf="deleteData.data.length" mat-icon-button title="accept">
                <mat-icon>delete</mat-icon>
            </button>
            <span *ngIf="deleteData.data.length" class="margin-right20">Delete all records</span>
            <button
                (click)="patchAllDeleteRecords(false)"
                *ngIf="deleteData.data.length"
                mat-icon-button
                title="reject"
            >
                <mat-icon>restore_from_trash</mat-icon>
            </button>
            <span *ngIf="deleteData.data.length">Keep all records</span>
        </div>
        <table mat-table [dataSource]="deleteData" *ngIf="deleteData.data.length">
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef>Number</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.number }}
                </td>
            </ng-container>
            <ng-container matColumnDef="contract">
                <th mat-header-cell *matHeaderCellDef>Contract</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.contract }}
                </td>
            </ng-container>
            <ng-container matColumnDef="projectTeamName">
                <th mat-header-cell *matHeaderCellDef>Project Team</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.projectTeamName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150"></th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleteState" (click)="restoreChangeDocument(element)">
                        <button mat-icon-button title="restore">
                            <mat-icon>restore_from_trash</mat-icon>
                        </button>
                        <span>Restore</span>
                    </div>
                    <div *ngIf="!element.deleteState" (click)="deleteChangeDocument(element)">
                        <button mat-icon-button title="reject">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <span>Delete</span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedDeleteDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDeleteDataColumns"></tr>
        </table>
        <mat-paginator
            *ngIf="deleteData.data.length"
            [length]="deleteDataResultsLength"
            [pageSize]="deleteDataPageSize"
            [pageSizeOptions]="[10, 15, 20]"
            (page)="onDeleteDataPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <div *ngIf="isDeleteDataLoading" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="upload-log-container">
        <h4>Import history</h4>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="getImportClassName(element.status)">
                        {{ element.status }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Start Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.startDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.endDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="infoMessage">
                <th mat-header-cell *matHeaderCellDef>Info Message</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.infoMessage"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="errorMessage">
                <th mat-header-cell *matHeaderCellDef>Error Message</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.errorMessage ? element.errorMessage : 'No errors' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.user }}
                </td>
            </ng-container>
            <ng-container matColumnDef="downloadResult">
                <th mat-header-cell *matHeaderCellDef>Download Result</th>
                <td mat-cell *matCellDef="let element">
                    <svg
                        class="downloadIcon"
                        *ngIf="element.outputDataLink"
                        (click)="downloadData(element.outputDataLink)"
                        width="18px"
                        height="20px"
                        viewBox="0 0 16 17"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                        <title>{{ element.outputDataLink }}</title>
                        <desc>Created with sketchtool.</desc>
                        <g
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <g
                                id="New-FCNi-Copy"
                                transform="translate(-1259.000000, -1440.000000)"
                                stroke="#0066B2"
                                stroke-width="2"
                            >
                                <g id="Buttons/Primary-Copy" transform="translate(1256.000000, 1438.000000)">
                                    <path d="M4,18 L18,18" id="Line-3"></path>
                                    <path
                                        d="M5.5,8.5 L16.5,8.5"
                                        id="Line-3-Copy"
                                        transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                    ></path>
                                    <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span
                        *ngIf="isImportInProgress(element.status)"
                        [ngClass]="{ 'in-progress': dataTypesInProgress[dataTypes.indexOf(element.type)] }"
                    ></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="isImportHistoryLoading" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <app-loading-indicator *ngIf="fileUploading || fileDownloading || processInProgress"> </app-loading-indicator>
</main>
