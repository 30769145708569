import { Component, OnInit, ViewChild, EventEmitter, NgZone } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { OrderDirection, CheckListColumn, PriorityType } from 'src/app/store/common.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FormService } from 'src/app/services/shared/form.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Store, select } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, take, map } from 'rxjs/operators';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { Router } from '@angular/router';
import { ValidationFormService } from 'src/app/services/api/webapi-services/validation-form.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import {
    SubystemConflictsFilterRequest,
    SubystemConflictsFilterPropertyUpdate,
    SubystemConflictsFilterReset,
} from 'src/app/store/subsystem-conflicts/actions';
import {
    ChangeDocumentDTO,
    ChangeDocumentFilter,
    ShowHideConflictsColumns,
} from 'src/app/store/subsystem-conflicts/model';

@Component({
    selector: 'app-subsystem-conflicts',
    templateUrl: './subsystem-conflicts.component.html',
    styleUrls: ['./subsystem-conflicts.component.scss'],
})
export class SubsystemConflictsComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    projectTeamNames: string[] = [];
    changeTypes: string[] = [];
    disciplines: string[] = [];
    isLoading = false;
    sortBy: string;
    direction = OrderDirection.Desc;
    data: ChangeDocumentDTO[] = [];
    resultsLength = 0;
    pageSize = 10;
    originalColumns = ['type', 'number', 'manualOverrideSubsystems', 'mcPlusSubsystems', 'importDataSourceSubsystems'];
    displayedColumns = this.originalColumns;
    subsystemConflictsFilter$ = this.store.select((store) => store.subsystemConflictsState.filter);
    subsystemConflictsLoader$ = this.store.select((store) => store.subsystemConflictsState.isLoading);
    subsystemConflictsDataPagination$ = this.store.select((store) => store.subsystemConflictsState.dataPagination);
    columnManualOverrideSubsystems$ = this.store.select(
        (state) => state.subsystemConflictsState.filter.columnManualOverrideSubsystems
    );
    columnMCPlusSubsystems$ = this.store.select((state) => state.subsystemConflictsState.filter.columnMCPlusSubsystems);
    columnImportDataSourceSubsystems$ = this.store.select(
        (state) => state.subsystemConflictsState.filter.columnImportDataSourceSubsystems
    );

    setCWPInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    columnManualOverrideSubsystems: CheckListColumn = null;
    columnMCPlusSubsystems: CheckListColumn = null;
    columnImportDataSourceSubsystems: CheckListColumn = null;
    showHideColumns = new ShowHideConflictsColumns();
    isManualOverrideDisabled = false;
    isImportDataSourceDisabled = false;
    isMCPlusDisabled = false;

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private formService: FormService,
        private lookupService: LookupService,
        private toastService: ToastService,
        private store: Store<ApplicationState>,
        private projectTeamsService: ProjectTeamsService,
        private router: Router,
        private ngZone: NgZone,
        private validationFormService: ValidationFormService,
        private popupSvc: PopupService
    ) {
        super();

        this.filterForm = this.formService.createSimpleForm(new ChangeDocumentFilter());
    }

    ngOnInit() {
        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.watchFormChanges();
        this.filterExpansionPanel.expanded = true;

        this.lookupService
            .getChangeTypes()
            .pipe(take(1))
            .subscribe((changeTypes) => {
                this.changeTypes = changeTypes;
            });

        this.subsystemConflictsDataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.subsystemConflictsFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.showHideColumns = filter.showHideColumns;
            if (!this.showHideColumns.manualOverrideSubsystems) {
                this.removeColumn('manualOverrideSubsystems');
            }
            if (!this.showHideColumns.mcPlusSubsystems) {
                this.removeColumn('mcPlusSubsystems');
            }
            if (!this.showHideColumns.importDataSourceSubsystems) {
                this.removeColumn('importDataSourceSubsystems');
            }
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.paginator.pageIndex = filter.page;
        });

        this.subsystemConflictsLoader$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => (this.isLoading = isLoading));

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: any[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines. Please contact Program Administrator.'
                    );
                }
            );

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new SubystemConflictsFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new SubystemConflictsFilterRequest());
        });

        this.columnImportDataSourceSubsystems$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnImportDataSourceSubsystems = items));
        this.columnMCPlusSubsystems$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnMCPlusSubsystems = items));
        this.columnManualOverrideSubsystems$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnManualOverrideSubsystems = items));

        this.store.dispatch(new SubystemConflictsFilterRequest());
    }

    getCWPs = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchCWPs(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            takeWhile(() => this.isAlive),
            map((cwps: string[]) => cwps)
        );
    };

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new SubystemConflictsFilterReset());
        this.setCWPInput.emit(new SetInputEventArgs(false, ''));
    }

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new SubystemConflictsFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new SubystemConflictsFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new SubystemConflictsFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new SubystemConflictsFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new SubystemConflictsFilterRequest());
    }

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    private watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new SubystemConflictsFilterPropertyUpdate({ key, value }));
            });
        }
    }

    openValidationView(validationId: number, id: number): void {
        if (!validationId) {
            this.isLoading = true;
            this.validationFormService
                .createValidationDocument(id)
                .pipe(takeWhile(() => this.isAlive))
                .subscribe(
                    (validId: number) =>
                        this.ngZone.run(() =>
                            this.router.navigate([`/validation/${validId}`], {
                                queryParams: { previousPage: 'subsystemconflicts' },
                            })
                        ),
                    () => {
                        this.toastService.Error(
                            'Error occurred while creating validation document. Please contact Program Administrator.'
                        );
                    }
                );
        } else {
            this.ngZone.run(() =>
                this.router.navigate([`/validation/${validationId}`], {
                    queryParams: { previousPage: 'subsystemconflicts' },
                })
            );
        }
    }

    private getLatestFilterData(subsystemType: PriorityType): ChangeDocumentFilter {
        let filter: ChangeDocumentFilter;
        this.store.pipe(select((x) => x.subsystemConflictsState, take(1))).subscribe((data) => (filter = data.filter));

        filter.filterType = subsystemType;
        return filter;
    }

    getManualOverrideSubsystems = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemsWithConfilctsFilter(
            search,
            take,
            page,
            this.getLatestFilterData(PriorityType.Manual)
        );

    getMCPlusSubsystems = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemsWithConfilctsFilter(
            search,
            take,
            page,
            this.getLatestFilterData(PriorityType.MCPlus)
        );

    getImportDataSourceSubsystems = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemsWithConfilctsFilter(
            search,
            take,
            page,
            this.getLatestFilterData(PriorityType.ChangeDocImport)
        );

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 620, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: true,
                showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new SubystemConflictsFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new SubystemConflictsFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new SubystemConflictsFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new SubystemConflictsFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'number', direction: OrderDirection.Desc },
                })
            );
        }
    }

    removeColumn(columnName: string) {
        if (this.displayedColumns.indexOf(columnName) < 0) {
            this.displayedColumns.splice(this.originalColumns.indexOf(columnName), 0, columnName);
        } else {
            this.displayedColumns = this.displayedColumns.filter((column) => column !== columnName);
        }
    }

    showHideColumn(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };

        this.isManualOverrideDisabled =
            !this.showHideColumns.mcPlusSubsystems || !this.showHideColumns.importDataSourceSubsystems;
        this.isMCPlusDisabled =
            !this.showHideColumns.manualOverrideSubsystems || !this.showHideColumns.importDataSourceSubsystems;
        this.isImportDataSourceDisabled =
            !this.showHideColumns.mcPlusSubsystems || !this.showHideColumns.manualOverrideSubsystems;

        this.store.dispatch(
            new SubystemConflictsFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showHideColumns,
            })
        );

        let comparison = [];
        if (this.showHideColumns.mcPlusSubsystems) {
            comparison.push(PriorityType.MCPlus);
        }
        if (this.showHideColumns.manualOverrideSubsystems) {
            comparison.push(PriorityType.Manual);
        }
        if (this.showHideColumns.importDataSourceSubsystems) {
            comparison.push(PriorityType.ChangeDocImport);
        }
        this.store.dispatch(
            new SubystemConflictsFilterPropertyUpdate({
                key: 'comparison',
                value: comparison,
            })
        );

        this.removeColumn(columnName);
        this.search();
    }
}
