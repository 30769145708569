import { OrderDirection, CalendarColumn, CheckListColumn, RFO, UserDetail } from '../common.model';
import * as moment from 'moment';

export interface RFOState {
    dataPagination: RFODataPagination;
    filter: RFOFilter;
    isLoading: boolean;
}

export class RFODataPagination {
    items: RFODTO[] = [];
    totalCount = 0;
}

export class RFOFilter {
    page = 0;
    take = 25;
    sortBy = 'RFO';
    direction: OrderDirection = OrderDirection.Asc;
    projectTeamNames: string[] = [];
    RFOs: RFO[] = [];
    scManager: string[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    waypointScopes:string[] = [];
    waypointNames:string[] = [];
    systemOwner: string[] = [];
    RFOCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    columnRFOPlan: CalendarColumn = null;
    columnRfsuActual: CalendarColumn = null;
    columnRFOForecast: CalendarColumn = null;
    columnRFOFreeze: CalendarColumn = null;
    columnRFOActual: CalendarColumn = null;
    columnRFOs: CheckListColumn = null;
    columnRFOsNames: CheckListColumn = null;
    showHideColumns = new ShowHideColumns();
    columnComments: CheckListColumn = null;
    columnRfsuForecastDate : CalendarColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    columnAreaCompletionLead: CheckListColumn = null;
    columnSCAreaManager: CheckListColumn = null;
    columnEdossierInitiation: CalendarColumn = null;
    columnEdossierStatus: CheckListColumn = null;
}

export class ShowHideColumns {
    rfoName = true;
    dates = true;
    noOfSubs = true;
    ITRs = true;
    NPW = true;
    LUN= false;
    APLIs = false;
    BPLIs = true;
    COW = false;
    DEX = true;
    redline = true;
    AC = true;
    PASCR = true;
    rfsuRemaining = true;  
    showAreaBreakdown = false;
    showAreaCompletionLead = false;  
    showSCAreaManager = false; 
    showEdossierInitiation = false;
    showEdossierStatus = true;
    showRFOFreeze = false;
}

export class RFODTO {
    rfo: string;
    RFOName: string;
    RFOPlan: moment.Moment;
    rfoForecast: moment.Moment;
    rfoActual: moment.Moment;
    rfoFreeze: moment.Moment;
    aqvd: number;
    apli: number;
    npw: number;
    bitr: number;
    citr: number;
    bpli: number;
    cpli: number;
    dpli: number;
    ex: number;
    cow: number;
    comment: string;
    isReadyForRFO = false;
    mentions: UserDetail[];
    lun: number;
    rfsuForecastDate:moment.Moment;
    rfsuRemaining:number;
    areaBreakdown: string;
    areaCompletionLead: string;
    scAreaManager: string;
    isRFOFreeze: boolean;    
    edossierInitiationDate: moment.Moment;
    edossierStatus: string;
}
