import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserDetail } from '../store/common.model';

@Pipe({
    name: 'formatMentions',
    pure: false,
})
export class FormatMentionsPipe implements PipeTransform {
    private readonly mentionTemplate =
        '<span style="background-color: rgb(244, 244, 244); color: rgb(16, 110, 190)"><b>$&</b></span>';

    constructor(private sanitized: DomSanitizer) {}

    transform(text: string, mentions: UserDetail[]): any {
        if (!text) {
            return '';
        }

        if (!!mentions) {
            mentions.forEach(element => {
                let name = element.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                var re = new RegExp(`@${name}`,"gi");
                text = text.replace(re, this.mentionTemplate);
            });
        }
       
        
        let html = this.sanitized.bypassSecurityTrustHtml(text);

        return html;
    }
}
