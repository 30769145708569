import {
    MasterSubsystemListState,
    MasterSubsystemListDeltaPagination,
    MasterSubsystemListDeltaFilter,
    MasterSubsystemListDeletePagination,
    MasterSubsystemListDeleteFilter,
    MasterSubsystemListDeltaDTO,
} from './model';
import { MasterSubsystemListActions, MasterSubsystemListActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: MasterSubsystemListState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deltaData: new MasterSubsystemListDeltaPagination(),
    isDeltaDataLoading: false,
    deleteData: new MasterSubsystemListDeletePagination(),
    isDeleteDataLoading: false,
    deltaFilter: new MasterSubsystemListDeltaFilter(),
    deleteFilter: new MasterSubsystemListDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
    isAutomaticUploadInProgress: true,
};

export function reducer(state = initialState, action: MasterSubsystemListActions): MasterSubsystemListState {
    switch (action.type) {
        case MasterSubsystemListActionTypes.MasterSubsystemListUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaRequest:
        case MasterSubsystemListActionTypes.MasterSubsystemListDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeltaSuccess: {
            return {
                ...state,
                deltaData: action.payload,
                isDeltaDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaSuccess:
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaError:
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaError:
        case MasterSubsystemListActionTypes.MasterSubsystemListDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeltaFilterPropertyUpdate: {
            state.deltaFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeleteFilterPropertyUpdate: {
            state.deleteFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaRequest: {
            state.deltaData.items.find((d) => d.subsystem === action.payload.subsystem).deltaState =
                action.payload.deltaState;
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaSuccess: {
            const delta = state.deltaData.items.map(
                (i) => ({ ...i, deltaState: action.payload.deltaState } as MasterSubsystemListDeltaDTO)
            );
            return {
                ...state,
                deltaData: {
                    ...state.deltaData,
                    items: delta
                },
                isDeltaDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataRequest:
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsSuccess: {
            state.deleteData.items.forEach((i) => (i.deleteState = action.payload.deleteState));
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataError:
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordSuccess:
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordError:
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaSuccess: {
            return {
                ...state,
                deltaData: new MasterSubsystemListDeltaPagination(),
                deleteData: new MasterSubsystemListDeletePagination(),
                isDeltaDataLoading: false,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deltaData: new MasterSubsystemListDeltaPagination(),
                deleteData: new MasterSubsystemListDeletePagination(),
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataRequest:
        case MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileError:
        case MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataSuccess:
        case MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataError:
        case MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordRequest: {
            state.deleteData.items.find((d) => d.subsystem === action.payload.subsystem).deleteState =
                action.payload.deleteState;
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
                isAutomaticUploadInProgress: action.payload.isAutomaticUploadInProgress,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'Subsystems';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}
