import { CriticalRfiState, CriticalRfiDataPagination, CriticalRfiFilter } from './model';
import { CriticalRfiActions, CriticalRfiActionTypes, CriticalRfiAddExpectedClosureDateError, CriticalRfiAddExpectedClosureDateSuccess } from './actions';
import * as _ from 'lodash';

const initialState: CriticalRfiState = {
    dataPagination: new CriticalRfiDataPagination(),
    filter: new CriticalRfiFilter(),
    isLoading: false,
    makeRequest: false,
};

export function reducer(state = initialState, action: CriticalRfiActions): CriticalRfiState {
    switch (action.type) {
        case CriticalRfiActionTypes.CriticalRfiFilterRequest: {
            return {
                ...state,
                isLoading: true,
                makeRequest: false,
            };
        }
        case CriticalRfiActionTypes.CriticalRfiSetMakeRequest: {
            return {
                ...state,
                makeRequest: true,
            };
        }
        case CriticalRfiActionTypes.CriticalRfiFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: action.payload,
            };
        }
        case CriticalRfiActionTypes.CriticalRfiAddExpectedClosureDateSuccess:
        case CriticalRfiActionTypes.CriticalRfiAddExpectedClosureDateError:
        case CriticalRfiActionTypes.CriticalRfiUpdateSubPrioritySuccess :
        case CriticalRfiActionTypes.CriticalRfiUpdateSubPriorityError :
        case CriticalRfiActionTypes.CriticalRfiUpdateManualPriorityError :
        case CriticalRfiActionTypes.CriticalRfiAddCommentError:
        case CriticalRfiActionTypes.CriticalRfiAddBulkCommentError:
        case CriticalRfiActionTypes.CriticalRfiDeleteCommentSuccess:
        case CriticalRfiActionTypes.CriticalRfiDeleteCommentError:
        case CriticalRfiActionTypes.CriticalRfiExportToExcelSuccess:
        case CriticalRfiActionTypes.CriticalRfiExportToExcelError:
        case CriticalRfiActionTypes.CriticalRfiFieldImplementationContractorError:
        case CriticalRfiActionTypes.CriticalRfiFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CriticalRfiActionTypes.CriticalRfiUpdateManualPrioritySuccess :{
            return {
                ...state,
                isLoading: false,
            };
        }
        case CriticalRfiActionTypes.CriticalRfiUpdateSubPriorityRequest: {
           const newChangeDocStatuses = _.cloneDeep(state.dataPagination.items);
            newChangeDocStatuses.forEach((doc) => {
                if(doc.id === action.payload.subPriority.id) {
                    doc.subPriority = action.payload.subPriority.subPriority;
                }                       
            });
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: newChangeDocStatuses
                },
                isLoading: true,
            };
        }
        case CriticalRfiActionTypes.CriticalRfiUpdateManualPriorityRequest: {
            const newChangeDocStatuses = _.cloneDeep(state.dataPagination.items);
             newChangeDocStatuses.forEach((doc) => {
                 if(doc.id === action.payload.manualPriority.id) {
                     doc.priorityManualOverride= action.payload.manualPriority.priorityManualOverride;
                     doc.priorityFinal = action.payload.manualPriority.priorityManualOverride;
                 }                       
             });
             return {
                 ...state,
                 dataPagination: {
                     ...state.dataPagination,
                     items: newChangeDocStatuses
                 },
                 isLoading: true,
             };
         }
        case CriticalRfiActionTypes.CriticalRfiFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(action.payload.value)
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case CriticalRfiActionTypes.CriticalRfiFilterReset: {
            const newFilter = new CriticalRfiFilter();
            newFilter.sortBy = state.filter.sortBy;
            newFilter.direction = state.filter.direction;
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case CriticalRfiActionTypes.CriticalRfiAddBulkCommentRequest:
        case CriticalRfiActionTypes.CriticalRfiDeleteCommentRequest:
        case CriticalRfiActionTypes.CriticalRfiExportToExcelRequest:
        case CriticalRfiActionTypes.CriticalRfiUpdateFieldImplementationContractorRequest:    
        case CriticalRfiActionTypes.CriticalRfiAddCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CriticalRfiActionTypes.CriticalRfiAddCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((x) => x.id === action.payload.id);
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex && action.payload.type.toLowerCase() === "ticomment") {
                            return {
                                ...d,
                                tiComment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        if(i === itemIndex && action.payload.type.toLowerCase()  === "kpjvcomment") {
                            return {
                                ...d,
                                kpjvComment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case CriticalRfiActionTypes.CriticalRfiAddBulkCommentSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((item) => ({
                        ...item,
                        comment: action.payload.description,
                        mentions: action.payload.mentions,
                    })),
                },
            };
        }

        case CriticalRfiActionTypes.CriticalRfiFieldImplementationContractorSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((x) => x.id === action.payload.id);

            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                fieldImplementationContractor: action.payload.fieldImplementationContractor                                
                            };
                        }
                        return d;
                    }),
                },
            };
        }

        case CriticalRfiActionTypes.CriticalRfiAddExpectedClosureDateRequest: {
            const newCriticalRfiData = _.cloneDeep(state.dataPagination.items);
            newCriticalRfiData.forEach((criticalRfi) => {
                if(criticalRfi.number === action.payload.criticalRfi.number) {
                    criticalRfi.expectedTimeOfCompletion = action.payload.expectedClosureDate; 
                }
            });
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: newCriticalRfiData
                },
                isLoading: true,
            };
        }

       
        default:
            return state;
    }
}
