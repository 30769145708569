import { TraceHeatingStatusActions, TraceHeatingStatusActionTypes } from "./action";
import { TraceHeatingStatusDto, TraceHeatingStatusFilter, TraceHeatingStatusResultDto } from "./model";
import * as _ from 'lodash';

export interface TraceHeatingStatusState {
    isLoading: boolean;
    traceHeatingStatusFilter: TraceHeatingStatusFilter;
    traceHeatingStatus: TraceHeatingStatusResultDto;
    printData: TraceHeatingStatusResultDto;
    printIsLoading: boolean;
    uploadingAttachmentsActionInProgress: boolean;
}

const initialState: TraceHeatingStatusState = {
    isLoading: false,
    traceHeatingStatusFilter: new TraceHeatingStatusFilter(),
    traceHeatingStatus: { items: [] as TraceHeatingStatusDto[], totalCount: 0 } as TraceHeatingStatusResultDto,
    printData: { items: [] as TraceHeatingStatusDto[], totalCount: 0 } as TraceHeatingStatusResultDto,
    printIsLoading: false,
    uploadingAttachmentsActionInProgress: false
};


export function reducer(state = initialState, action: TraceHeatingStatusActions) {
    switch (action.type) {
        case TraceHeatingStatusActionTypes.TraceHeatingStatusDataRequest:
            return action.printMode ? 
            { ...state, printIsLoading: true } :
            { ...state, isLoading: true };
        case TraceHeatingStatusActionTypes.TraceHeatingStatusDataRequestSuccess:
            return action.printMode ?
            {  ...state,  printData: action.traceHeatingStatus, printIsLoading: false } :
            {  ...state,  traceHeatingStatus: action.traceHeatingStatus, isLoading: false };
        case TraceHeatingStatusActionTypes.TraceHeatingStatusDataRequestError:
            return { 
                ...state,
                isLoading: false,
                printIsLoading: false
            };
        case TraceHeatingStatusActionTypes.TraceHeatingStatusFilterReset:
            return {
                ...state,
                traceHeatingStatusFilter: new TraceHeatingStatusFilter()
            }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusFilterUpdate:
            return {
                ...state,
                traceHeatingStatusFilter: {
                    ...state.traceHeatingStatusFilter,
                    ...action.filter
                }
            }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusExportToExcelRequest: {
            return {
                ...state,
                isLoading: true
            };
        }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false
            };
        }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case TraceHeatingStatusActionTypes.ResetPrint: {
            return {
                ...state,
                printData: [],
            };
        }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusAttachmentsUpdated: {
            let items = _.cloneDeep(state.traceHeatingStatus.items.filter((i) => i.tdbSubsystem === action.tdbSubsystem));
            if (items.length === 0) {
                return { ...state };
            }

            items.forEach(item => {
                item.attachments = action.attachments;
            });
            
            return  {
                ...state,
                traceHeatingStatus: { ...state.traceHeatingStatus,
                    items: items

                },
                
            };
        }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusDownloadAttachmentRequest:
            return {
                ...state,
                isLoading: true
            }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusDownloadAttachmentSuccess:
            return {
                ...state,
                isLoading: false
            }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusDownloadAttachmentError:
            return {
                ...state,
                isLoading: false
            }    
        case TraceHeatingStatusActionTypes.TraceHeatingStatusDeleteAttachmentRequest:
            return {
                ...state,
                isLoading: true
            }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusDeleteAttachmentSuccess:
            return {
                ...state,
                isLoading: false
            }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusDeleteAttachmentError:
            return {
                ...state,
                isLoading: false
            }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusUploadAttachmentsRequest:
        return {
            ...state,
            isLoading: true
        }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusUploadAttachmentsSuccess:
            return {
                ...state,
                isLoading: false
            }
        case TraceHeatingStatusActionTypes.TraceHeatingStatusUploadAttachmentsError:
            return {
                ...state,
                isLoading: false
            }          
        default:
            return state;
    }
}