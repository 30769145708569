<div class="chart-container">
    <div *ngIf="!homePage" class="line-chart-container padding-left" #lineChart>
        <ngx-charts-line-chart
            [scheme]="displayColorScheme"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [results]="displayLineChartData"
            [roundDomains]="true"
            [yScaleMin]="yScaleMin$ | async"
            [yScaleMax]="yScaleMax$ | async"
            [view]="[1200, 300]"
        >
        </ngx-charts-line-chart>
    </div>
    <div
        class="header-container"
        #header
        *ngIf="!(monthViewEnabled$ | async) && !(quarterViewEnabled$ | async)"
        [ngStyle]="{ width: headerWidth, 'margin-left': headerMarginLeft }"
    >
        <div
            *ngIf="(pastWeeks$ | async).length"
            class="past-weeks"
            [ngStyle]="{ 'flex-grow': (pastWeeks$ | async).length }"
            [ngClass]="{
                'vertical-divider': true
            }"
        >
            <img src="../../../../../../assets/images/icons/arrow_back-gray.svg" />
            <span>past weeks</span>
        </div>
        <div
            *ngIf="(pastWeeks$ | async).length < (range$ | async) - 1"
            class="forecast"
            [ngStyle]="{ 'flex-grow': (range$ | async) - 1 - (pastWeeks$ | async).length }"
        >
            <span>Forecast</span>
            <img src="../../../../../../assets/images/icons/arrow_forward-gray.svg" />
        </div>
    </div>
    <div *ngIf="!(showGroupedVerticalChart$ | async)" class="bar-chart-container">
        <ngx-charts-bar-vertical-2d
            [scheme]="colorScheme"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [yAxisTickFormatting]="formatToIntegerString"
            [results]="displayBarChartData"
            [roundDomains]="true"
            [roundEdges]="false"
            [barPadding]="1"
            [view]="[1260, 550]"
        >
        </ngx-charts-bar-vertical-2d>
    </div>
    <div [ngClass]="{ pli2: (rundownType$ | async) === 'PLI2' }">
        <div *ngIf="(rundownType$ | async) === 'PLI2'" class="net-change">net change</div>
        <div *ngIf="showGroupedVerticalChart$ | async" class="bar-chart-container">
            <ngx-charts-bar-vertical-2d-stacked
                [scheme]="barColorScheme"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [showGridLines]="true"
                [results]="displayBarChartData"
                [roundDomains]="true"
                [roundEdges]="false"
                [barPadding]="1"
                [view]="[1260, 550]"
            >
            </ngx-charts-bar-vertical-2d-stacked>
        </div>
        <ngx-charts-legend *ngIf="showBarLegend" class="chart-legend" [data]="barChartLabels" [colors]="barChartColors">
        </ngx-charts-legend>
    </div>
</div>
