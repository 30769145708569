import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum LoopActionTypes {
    LoopUploadLogRequest = '[Loop] Upload Log Request',
    LoopUploadLogSuccess = '[Loop] Upload Log Success',
    LoopUploadLogError = '[Loop] Upload Log Error',
    LoopSetStatusAndStartDate = '[Loop] Set Status and Start Date',
    LoopFileUploaded = '[Loop] File Uploaded',
    LoopClearInProgressSpinner = '[Loop] Clear In Progress Spinner',
    LoopDataInfoRequest = '[Loop] Data Info Request',
    LoopDataInfoSuccess = '[Loop] Data Info Success',
    LoopDataInfoError = '[Loop] Data Info Error',
    LoopDownloadOutputDataRequest = '[Loop] Output data Request',
    LoopDownloadOutputDataSuccess = '[Loop] Output data Success',
    LoopDownloadOutputDataError = '[Loop] Output data File Error',
    LoopAddStartedLog = '[Loop] Add Started Log',
    LoopValidateDeltaRequest = '[Loop] Validate Delta Request',
    LoopValidateDeltaSuccess = '[Loop] Validate Delta Success',
    LoopValidateDeltaError = '[Loop] Validate Delta Error',
    LoopTemplateFileRequest = '[Loop] Template File Request',
    LoopTemplateFileSuccess = '[Loop] Template File Success',
    LoopTemplateFileError = '[Loop] Template File Error',
}

export class LoopAddStartedLog implements Action {
    readonly type = LoopActionTypes.LoopAddStartedLog;
}

export class LoopUploadLogRequest implements Action {
    readonly type = LoopActionTypes.LoopUploadLogRequest;
}

export class LoopUploadLogSuccess implements Action {
    readonly type = LoopActionTypes.LoopUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class LoopUploadLogError implements Action {
    readonly type = LoopActionTypes.LoopUploadLogError;

    constructor(public payload: string) {}
}

export class LoopFileUploaded implements Action {
    readonly type = LoopActionTypes.LoopFileUploaded;
}

export class LoopClearInProgressSpinner implements Action {
    readonly type = LoopActionTypes.LoopClearInProgressSpinner;
}

export class LoopDownloadOutputDataRequest implements Action {
    readonly type = LoopActionTypes.LoopDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class LoopDownloadOutputDataSuccess implements Action {
    readonly type = LoopActionTypes.LoopDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class LoopDownloadOutputDataError implements Action {
    readonly type = LoopActionTypes.LoopDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class LoopSetStatusAndStartDate implements Action {
    readonly type = LoopActionTypes.LoopSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class LoopDataInfoSuccess implements Action {
    readonly type = LoopActionTypes.LoopDataInfoSuccess;

    constructor(public payload: {
        readyToProcess: boolean[];
        inProgress: boolean[];
        recordsForDeletion: number[];
        isAutomaticUploadInProgress: boolean;
    }) {}
}

export class LoopDataInfoError implements Action {
    readonly type = LoopActionTypes.LoopDataInfoError;

    constructor(public payload: string) {}
}

export class LoopDataInfoRequest implements Action {
    readonly type = LoopActionTypes.LoopDataInfoRequest;
}

export class LoopValidateDeltaRequest implements Action {
    readonly type = LoopActionTypes.LoopValidateDeltaRequest;
}

export class LoopValidateDeltaSuccess implements Action {
    readonly type = LoopActionTypes.LoopValidateDeltaSuccess;
}

export class LoopValidateDeltaError implements Action {
    readonly type = LoopActionTypes.LoopValidateDeltaError;

    constructor(public payload: string) {}
}

export class LoopTemplateFileRequest implements Action {
    readonly type = LoopActionTypes.LoopTemplateFileRequest;

    constructor(public payload: string) {}
}

export class LoopTemplateFileSuccess implements Action {
    readonly type = LoopActionTypes.LoopTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class LoopTemplateFileError implements Action {
    readonly type = LoopActionTypes.LoopTemplateFileError;

    constructor(public payload: string) {}
}

export type LoopActions =
    | LoopUploadLogRequest
    | LoopUploadLogSuccess
    | LoopUploadLogError
    | LoopFileUploaded
    | LoopClearInProgressSpinner
    | LoopSetStatusAndStartDate
    | LoopDataInfoRequest
    | LoopDataInfoSuccess
    | LoopDataInfoError
    | LoopDownloadOutputDataRequest
    | LoopDownloadOutputDataSuccess
    | LoopDownloadOutputDataError
    | LoopAddStartedLog
    | LoopValidateDeltaRequest
    | LoopValidateDeltaSuccess
    | LoopValidateDeltaError
    | LoopTemplateFileRequest
    | LoopTemplateFileSuccess
    | LoopTemplateFileError;
