import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/components/base.component';
import { Contractor, Gooc, RFO, StagedStartUpPriority, TCOUser } from 'src/app/store/common.model';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { FormService } from 'src/app/services/shared/form.service';
import { WeeklyPlanningFilter } from 'src/app/store/weekly-planning/model';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { catchError, map, take, takeWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import * as moment from 'moment';
import { ToastService } from 'src/app/services/shared/toast.service';
import { forkJoin } from 'rxjs';
import { WeeklyPlanningFilterPropertyUpdate } from 'src/app/store/weekly-planning/actions';

@Component({
    selector: 'app-daily-planning-session-filter',
    templateUrl: './daily-planning-session-filter.component.html',
    styleUrls: ['./daily-planning-session-filter.component.scss'],
})
export class DailyPlanningSessionFilterComponent extends BaseComponent implements OnInit {
    @Input() projectTeamName: string;
    @Input() planningType: string;
    @Input() startDate: moment.Moment;
    @Output() searchEvent: EventEmitter<WeeklyPlanningFilter> = new EventEmitter();

    filterForm: UntypedFormGroup;
    contractors: Contractor[] = [];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    scManagers: TCOUser[] = [];
    systemOwners: TCOUser[] = [];
    isUsersPerProjectLoading = false;

    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    planningFilter$ = this.store.select((store) => store.weeklyPlanningState.filter);

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>,
        private toastService: ToastService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new WeeklyPlanningFilter());
    }

    ngOnInit(): void {
        this.filterForm.controls.startDate.patchValue(this.startDate);
        this.planningFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam([this.projectTeamName]);
        });
        this.watchFormChanges();
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, [this.projectTeamName])
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, [this.projectTeamName])
            .pipe(map((rfos: RFO[]) => rfos));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    resetFilters() {
        this.filterForm.controls.contractors.patchValue([]);
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.rfos.patchValue([]);
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.scManagers.patchValue([]);
        this.filterForm.controls.systemOwners.patchValue([]);
        this.filterForm.controls.goocs.patchValue([]);
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.stagedStartUpPriorities.setValue([]);
        this.filterForm.controls.areaBreakdown.patchValue([]);
        this.filterForm.controls.areaBreakdown.patchValue([]);
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.rfsuContractors.patchValue([]);
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.rfsuContractors.patchValue([]);
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
    }

    search() {
        this.searchEvent.emit(this.filterForm.value);
    }

    displayMultipleSelected(values: Contractor[]) {
        return values.map((x) => x.name).join(', ');
    }

    private getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'rfos');
        forkJoin([
            this.lookupService.getSCManagers(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([scManagers, sysOwners]) => {
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManagers', 'id');
                    this.systemOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.systemOwners, 'systemOwners', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((prio: StagedStartUpPriority[]) => prio));
    };
    
    onStagedStartUpPrioritiesClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    private checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName].value.length &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid && this.filterForm.controls[formControlName].value.length != valid.length) {
            this.filterForm.controls[formControlName].setValue(valid);
        }
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid && this.filterForm.controls[controlName].value.length != valid.length) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.systemOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    private watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new WeeklyPlanningFilterPropertyUpdate({ key, value }));
            });
        }
    }
}
