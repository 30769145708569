import {
    TagSearchState,
    TagSearchFilter,
    TagSearchDataPagination,
    TagSearchITRHeaderFilter,
    TagSearchPLIHeaderFilter,
    TagSearchChangeDocHeaderFilter,
} from './model';
import { TagSearchActions, TagSearchActionTypes } from './actions';
import { OrderDirection } from '../common.model';
import * as _ from 'lodash';
import { Constants } from 'src/app/constants';

const initialState: TagSearchState = {
    dataPagination: new TagSearchDataPagination(),
    isLoading: false,
    filter: new TagSearchFilter(),
};

export function reducer(state: TagSearchState = initialState, action: TagSearchActions): TagSearchState {
    switch (action.type) {
        case TagSearchActionTypes.TagSearchFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case TagSearchActionTypes.TagSearchFilterSuccess: {
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: action.payload.items.map((s) => {
                        return {
                            ...s,
                            itrHeaderFilter: new TagSearchITRHeaderFilter(),
                            pliHeaderFilter: new TagSearchPLIHeaderFilter(),
                            changeDocHeaderFilter: new TagSearchChangeDocHeaderFilter(),
                        };
                    }),
                    totalCount: action.payload.totalCount,
                },
                isLoading: false,
            };
        }
        case TagSearchActionTypes.TagSearchFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case TagSearchActionTypes.TagSearchFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case TagSearchActionTypes.TagSearchHeaderFilterPropertyUpdate: {
            let item = state.dataPagination.items.find(
                (s) => s.tagDetails.tagNo === action.payload.tagNo && s.tagDetails.tagType === action.payload.tagType
            );
            const itemInex = state.dataPagination.items.findIndex(
                (s) => s.tagDetails.tagNo === action.payload.tagNo && s.tagDetails.tagType === action.payload.tagType
            );

            let filterObject = null;
            let elementArray = null;
            if (action.payload.type === Constants.tagSearchTableType.itr) {
                filterObject = { ...item.itrHeaderFilter };
                elementArray = [...item.itRs];
            } else if (action.payload.type === Constants.tagSearchTableType.pli) {
                filterObject = { ...item.pliHeaderFilter };
                elementArray = [...item.plIs];
            } else if (action.payload.type === Constants.tagSearchTableType.changeDoc) {
                filterObject = { ...item.changeDocHeaderFilter };
                elementArray = [...item.changeDocs];
            }

            if (Array.isArray(filterObject[action.payload.key])) {
                filterObject[action.payload.key] = Object.assign([], action.payload.value);
            } else if (typeof action.payload.value === 'object' && action.payload.value !== null) {
                filterObject[action.payload.key] = Object.assign({}, action.payload.value);
            } else {
                filterObject[action.payload.key] = action.payload.value;
            }

            if (action.payload.key !== 'sortBy') {
                var columnKeys = _.keys(filterObject).filter((s) => s.indexOf('column') > -1 && filterObject[s]);
                elementArray = elementArray.map((s) => {
                    let isHidden = false;
                    columnKeys.forEach((k) => {
                        let filterItems = filterObject[k].items;
                        let val = s[k.replace('column', '')];
                        isHidden = isHidden || (filterItems.length > 0 ? filterItems.indexOf(val) === -1 : false);
                    });
                    return {
                        ...s,
                        hidden: isHidden,
                    };
                });
            } else {
                let dir = filterObject.sortBy.direction === OrderDirection.Asc ? -1 : 1;
                elementArray = [
                    ...elementArray.sort((a, b) => {
                        return a[filterObject.sortBy.active] > b[filterObject.sortBy.active] ? dir : -dir;
                    }),
                ];
            }
            
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemInex) {
                            if (action.payload.type === Constants.tagSearchTableType.itr) {
                                return {
                                    ...d,
                                    itrHeaderFilter: { ...filterObject },
                                    itRs: [...elementArray],
                                };
                            } else if (action.payload.type === Constants.tagSearchTableType.pli) {
                                return {
                                    ...d,
                                    pliHeaderFilter: { ...filterObject },
                                    plIs: [...elementArray],
                                };
                            } else if (action.payload.type === Constants.tagSearchTableType.changeDoc) {
                                return {
                                    ...d,
                                    changeDocHeaderFilter: { ...filterObject },
                                    changeDocs: [...elementArray],
                                };
                            }
                        }
                        return d;
                    }),
                },
            };
        }
        case TagSearchActionTypes.TagSearchFilterReset: {
            const newFilter = new TagSearchFilter();
            newFilter.tagStatuses = ['ACTIVE'];
            newFilter.sortBy = state.filter.sortBy;
            newFilter.direction = state.filter.direction;
            return {
                ...state,
                filter: { ...newFilter },
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((s) => ({
                        ...s,
                        itrHeaderFilter: new TagSearchITRHeaderFilter(),
                        pliHeaderFilter: new TagSearchPLIHeaderFilter(),
                        changeDocHeaderFilter: new TagSearchChangeDocHeaderFilter(),
                    })),
                },
            };
        }
        case TagSearchActionTypes.TagSearchExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case TagSearchActionTypes.TagSearchExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case TagSearchActionTypes.TagSearchExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
