import {
    CalendarColumn,
    CheckListColumn,
    Contractor,
    Gooc,
    Milestone,
    OrderDirection,
    RFO,
    StagedStartUpPriority,
    UserDetail,
    Waypoint,
} from '../common.model';
import * as moment from 'moment';

export enum CommitmentType {
    MC,
    RFSU,
    RFO,
}

export interface WeeklyPlanningCommitDTO {
    commitmentKey: string;
    commitmentType: string;
    committedForWeek: moment.Moment;
}

export class WeeklyPlanningFilter {
    acms: number[] = [];
    scManagers: number[] = [];
    mcEngineers: number[] = [];
    systemOwners: number[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    contractors: Contractor[] = [];
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    startDate: moment.Moment;
    projectTeamName: string;
    rfos: RFO[] = [];
    goocs: Gooc[] = [];
    constructionArea: string[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];

    constructor() {
        this.startDate = moment().utc().startOf('day');
        if (this.startDate.isoWeekday() !== 6) {
            this.startDate.add(-1, 'weeks').isoWeekday(6);
        }
    }
}

export class MCWeeklyPlanningDTO {
    subsystem: string;
    subsystemName: string;
    mcActual?: moment.Moment;
    mcPlan?: moment.Moment;
    mcForecast?: moment.Moment;
    aqvd?: number;
    apli: number;
    npw: number;
    comments: string;
    committedForWeek: moment.Moment;
    isSubmitted: boolean;
    isDeleted: boolean;
    redline: number;
    mentions: UserDetail[];
    walkdownActual?: moment.Moment;
    walkdownForecast?: moment.Moment;
}

export class DailyDashboardDTO {
    subsystem?: string;
    subsystemName?: string;
    rfo?: string;
    rfoName?: string;
    mcPlan?: moment.Moment;
    mcActual?: moment.Moment;
    mcForecast?: moment.Moment;
    rfsuPlan?: moment.Moment;
    rfsuActual?: moment.Moment;
    rfsuForecast?: moment.Moment;
    rfoPlan?: moment.Moment;
    rfoActual?: moment.Moment;
    rfoForecast?: moment.Moment;
    walkdownActual?: moment.Moment;
    aqvd: number;
    apli: number;
    npw: number;
    bpli: number;
    cpli: number;
    bitr: number;
    citr: number;
    comment: string;
    userName: string;
    redline: number;
    scManager?: string;
    systemOwner?: string;
    edossierStatus?: string;
}

export class RFSUWeeklyPlanningDTO {
    subsystem?: string;
    subsystemName?: string;
    rfsuActual?: moment.Moment;
    rfsuPlan?: moment.Moment;
    rfsuForecast?: moment.Moment;
    bitr?: number;
    citr?: number;
    aqvd?: number;
    apli: number;
    bpli?: number;
    cpli?: number;
    npw: number;
    comments: string;
    committedForWeek: moment.Moment;
    isSubmitted: boolean;
    isDeleted: boolean;
    redline: number;
    mentions: UserDetail[];
}

export class RFOWeeklyPlanningDTO {
    rfo?: string;
    rfoName?: string;
    rfoActual?: moment.Moment;
    rfoPlan?: moment.Moment;
    rfoForecast?: moment.Moment;
    bitr?: number;
    citr?: number;
    aqvd?: number;
    apli?: number;
    bpli?: number;
    cpli?: number;
    npw: number;
    redline: number;
    comments: string;
    committedForWeek: moment.Moment;
    isSubmitted: boolean;
    isDeleted: boolean;
    mentions: UserDetail[];
}

export class WeeklyPlanningCommitmentsDTO {
    plannedAndCommitted: (MCWeeklyPlanningDTO | RFSUWeeklyPlanningDTO)[];
    additionalCommitments: (MCWeeklyPlanningDTO | RFSUWeeklyPlanningDTO)[];
}

export class WeeklyPlanningTopHeaderBaseDTO {
    committed: number;
    committedAndAchieved: number;
    committmentReliability: number;
    achievedButNotCommitted: number;
    achievedTotal: number;
    planned: number;
    plannedAndAchieved: number;
    previouslyAchieved: number;
    l4Reliability: number;
}

export class WeeklyPlanningUsersTopHeaderDTO extends WeeklyPlanningTopHeaderBaseDTO {
    userName: string;
}

export class WeeklyPlanningPastTopHeaderDTO extends WeeklyPlanningTopHeaderBaseDTO {
    week: moment.Moment;
}

export class WeeklyPlanningTopHeaderResultDTO {
    byUsers: WeeklyPlanningUsersTopHeaderDTO[];
    byPastWeeks: WeeklyPlanningPastTopHeaderDTO[];
}

export class WeeklyPlanningTableResult<T> {
    items: T[] = [];
    page = 0;
    take = 10;
    sortBy: string;
    direction = OrderDirection.Asc;
    isLoading = false;
    totalCount = 0;
    tableHeaderFilter = new WeeklyPlanningTableHeaderFilter();
    expanded = false;

    constructor(sortBy?: string) {
        this.sortBy = sortBy ? sortBy : 'subsystem';
    }
}

export class WeeklyPlanningTableHeaderFilter {
    columnSubsystems: CheckListColumn;
    columnSubsystemNames: CheckListColumn;
    columnMCPlan: CalendarColumn;
    columnRFSUPlan: CalendarColumn;
    columnMCForecast: CalendarColumn;
    columnRFSUForecast: CalendarColumn;
    columnRFOs: CheckListColumn;
    columnRFONames: CheckListColumn;
    columnRFOPlan: CalendarColumn;
    columnRFOForecast: CalendarColumn;
    columnMCActual: CalendarColumn;
    columnWalkdownForecast: CalendarColumn;
    columnWalkdownActual: CalendarColumn;
    columnEdossierInitiation: CalendarColumn;

    constructor() {
        this.columnMCForecast = null;
        this.columnSubsystems = null;
        this.columnSubsystemNames = null;
        this.columnMCPlan = null;
        this.columnRFSUForecast = null;
        this.columnRFSUPlan = null;
        this.columnRFOs = null;
        this.columnRFONames = null;
        this.columnRFOPlan = null;
        this.columnRFOForecast = null;
        this.columnMCActual = null;
        this.columnWalkdownForecast = null;
        this.columnWalkdownActual = null;
        this.columnEdossierInitiation = null;
    }
}

export interface WeeklyPlanningState {
    mc: WeeklyPlanningTableResult<MCWeeklyPlanningDTO>[];
    rfsu: WeeklyPlanningTableResult<RFSUWeeklyPlanningDTO>[];
    rfo: WeeklyPlanningTableResult<RFOWeeklyPlanningDTO>[];
    filter: WeeklyPlanningFilter;
    planningType: string;
    commitments: WeeklyPlanningCommitmentsDTO;
    commitmentsLoading: boolean;
    topHeaderSectionData: WeeklyPlanningTopHeaderResultDTO;
    topHeaderSectionDataLoading: boolean;
}

export enum HeaderFilterFetchColumn {
    None,
    Subsystems,
    SubsystemNames,
    RFOs,
    RFONames,
}

export class ShowHideColumns {
    walkdownForecast = false;
    walkdownActual = false;
    mcActual = false;
    edossierInitiation = false;
}

export class WeekSummaryDto {
    plan: number;
    committedAsPerPlan: number;
    committedOutOfPlan: number;
    notConstrainedAndNotCommitted: number;
    constrainedAndNotCommitted: number;
    notCommittedAndRequiredForRFSU: number;
}
