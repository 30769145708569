import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import { debounceTime, take, takeWhile } from 'rxjs/operators';
import { DcnRfiRegisterDetails, RedlineBaseDetails, RedlineRegisterDetails, RegisterType, StatusType, WorkflowStatusUpdate, Workpacks } from 'src/app/store/dcn-rfi-redline/model';
import { DcnRfiRedlineRegisterAddCommentRequest, DcnRfiRegisterAddRedlineRequest, DcnRfiRegisterAddWorkpackRequest, dcnRfiRegisterDeleteCommentRequest, DcnRfiRegisterDeleteWorkpackRequest, DcnRfiRegisterDetailsDataRequest, DcnRfiRegisterUpdateRlmuStatusRequest, DcnRfiRegisterUpdateWorkflowStatusRequest, DcnRfiRegisterUpdateWorkpackRequest, RedlineRegisterDetailsDataRequest } from 'src/app/store/dcn-rfi-redline/actions';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ChangeDocStatus } from 'src/app/store/common.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DcnRfiRedlineRegisterService } from 'src/app/services/api/webapi-services/dcn-rfi-redline-register.service';
import { FormControl } from '@angular/forms';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { SignOffPopupComponent } from 'src/app/components/change-document-details/sign-off-popup/sign-off-popup.component';
import { SignOffStatusDTO } from 'src/app/store/change-document-details/model';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { DcnRfiSignOffFieldImplementationMode, SubsystemScope, Zone } from 'src/app/enums';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { CommentsHistoryComponent } from 'src/app/components/comments-history/comments-history.component';

@Component({
  selector: 'app-dcn-rfi-details',
  templateUrl: './dcn-rfi-details.component.html',
  styleUrls: ['./dcn-rfi-details.component.scss']
})
export class DcnRfiDetailsComponent extends BaseComponent implements OnInit {
  readonly NovusBolashakContract = "K-500-203-ON"

  formControl = new FormControl<any>('');
  dcnRfiDetails = new DcnRfiRegisterDetails();
  redlineDetails = new RedlineRegisterDetails();
  isLoading: boolean = false;
  alreadySignedOff = false;
  canSignOff = false;
  changeDocStatuses = ChangeDocStatus;
  displayedColumns: string[] = ['redlineNumber', 'tcoStatus', 'rlmuStatus'];
  displayedWorkpackColumns: string[] = ['workpackNumber', 'workpackStatus'];
  statusTypes = StatusType;
  changeDocId: number;
  expandTextArea = false;
  redlineNumbers: any[];
  subsToSignOff: string[] = [];
  registerTypes = RegisterType;
  registerType: RegisterType;
  isEditingComment: boolean = false;
  newComment: string = '';

  newWorkpackStatus: string = '';
  newNbWorkpackNumber: string = '';
  showNewWorkpackField = false;

  showNewRedlineField = false;
  newTcoStatus: string = '';
  newRlmuStatus: string = '';

  signOffFieldImplementationMode: DcnRfiSignOffFieldImplementationMode = DcnRfiSignOffFieldImplementationMode.DisabledForRLMUToGo;
  
  get isNewWorkpackDisabled() {
    return this.newWorkpackStatus === '' || this.newNbWorkpackNumber === '';
  }

  get isNewRedlineDisabled() {
    return this.formControl.value === '' || this.newTcoStatus === '' || this.newRlmuStatus === '';
  }

  get currentElement() : DcnRfiRegisterDetails | RedlineRegisterDetails {
    return this.registerType === RegisterType.Dcnrfi
      ? this.dcnRfiDetails
      : this.redlineDetails;
  }

  get currentScope() : SubsystemScope {
    return this.registerType === RegisterType.Dcnrfi
      ? SubsystemScope.ChangeDocument
      : SubsystemScope.Redline;
  }

  get currentZone() : Zone {
    return this.registerType === RegisterType.Dcnrfi
      ? Zone.ChangeManagement
      : Zone.Redline;
  }

  dcnRfiRedlineRegisterGridLoader$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.isLoading);
  dcnRfiDetails$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRegisterDetails);
  redlineDetails$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.redlineRegisterDetails);
  isSavingComment$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.isSavingComment);

  constructor(
    private store: Store<ApplicationState>,
    private route: ActivatedRoute,
    private location: Location,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dcnRfiRedlineRegisterService: DcnRfiRedlineRegisterService,
    private popupService: PopupService,
    private configurationService: ConfigurationService,
    private readonly commentService: CommentService
    ) {
    super();
    this.iconRegistry.addSvgIcon(
      'expand',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/expand.svg')
    );
    this.iconRegistry.addSvgIcon(
      'collapse',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/collapse.svg')
    );
    this.iconRegistry.addSvgIcon(
      "history",
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/history.svg')
    );
    this.iconRegistry.addSvgIcon(
      'del-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'add',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg')
    );
    this.iconRegistry.addSvgIcon(
      'add-gray',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add-gray.svg')
    );
    this.iconRegistry.addSvgIcon(
      'accept',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/accept.svg')
    );
    this.iconRegistry.addSvgIcon(
      'accept-gray',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/accept-gray.svg')
    );
    this.iconRegistry.addSvgIcon(
      'delete',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
    );
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap: ParamMap) => {
      this.changeDocId = +paramMap.get('id');
      this.registerType = this.registerTypes[this.titleCase(paramMap.get('type')) as keyof typeof RegisterType];
    });
    this.dcnRfiRedlineRegisterGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });

    if (this.registerType === this.registerTypes.Dcnrfi) {
      this.store.dispatch(new DcnRfiRegisterDetailsDataRequest(this.changeDocId));

      this.dcnRfiDetails$.pipe(takeWhile(() => this.isAlive)).subscribe((dcnRfiDetails) => {
        this.dcnRfiDetails = dcnRfiDetails;
        this.updateSubsToSignOff(this.dcnRfiDetails?.subsystems, this.dcnRfiDetails?.signOffs);
      });
    } else {
      this.store.dispatch(new RedlineRegisterDetailsDataRequest(this.changeDocId))
      this.redlineDetails$.pipe(takeWhile(() => this.isAlive)).subscribe((redlineDetails) => {
        this.redlineDetails = redlineDetails;
      });
    }

    this.configurationService.getConfigData()
      .pipe(take(1))
      .subscribe(config => {
        const signOffModeStr = config.find(x => x.key === Constants.Config.signOffFiledImplementationModeKey)?.value;
        this.signOffFieldImplementationMode = DcnRfiSignOffFieldImplementationMode[signOffModeStr]
          ?? DcnRfiSignOffFieldImplementationMode.DisabledForRLMUToGo;
      });
    
    this.isSavingComment$.pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      if (value === false && this.isEditingComment === true) {
        this.isEditingComment = false;
      }
    });
  }

  goToPreviousPage() {
    this.location.back();
  }

  getNgClass(statusType: StatusType, value: string) {
    if (statusType === this.statusTypes.Workflow) {
      return {'status-red': value === 'Review Required', 'status-blue': value === 'Reviewed'}
    } else if (statusType === this.statusTypes.Engineering) {
      return {'status-grey': value === 'In Engineering', 'status-blue': value === 'To Implement' || value === 'No Physical Work', 'status-red': value === 'Void'}
    } else if (statusType === this.statusTypes.Workpack) {
      return {'status-select': value === '', 'status-red': value === 'Quarantined', 'status-blue': value === 'Released'}
    } else if (statusType === this.statusTypes.Tco) {
      return {'status-select': value === '', 'status-grey': value === 'Required', 'status-blue': value === 'Received'}
    } else if (statusType === this.statusTypes.Rlmu) {
      return {'status-select': value === '', 'status-grey': value !== ''}
    }
  }

  signOff() {
    let dialogRef = this.popupService.openPopup(
        new PopupSettings(SignOffPopupComponent, 450, 300, {
            id: this.dcnRfiDetails.id,
            subsystems: this.subsToSignOff,
            contract: this.NovusBolashakContract,
        })
    );

    dialogRef
        .afterClosed()
        .pipe(takeWhile(() => this.isAlive === true))
        .subscribe((result) => {
            if (result && result.changeDoc) {
              if (this.registerType === RegisterType.Dcnrfi) {
                this.updateSubsToSignOff(this.dcnRfiDetails.subsystems, this.dcnRfiDetails.signOffs);
                this.store.dispatch(new DcnRfiRegisterDetailsDataRequest(this.changeDocId));
              } else {
                this.updateSubsToSignOff(this.redlineDetails.subsystems, this.redlineDetails.signOffs);
                this.store.dispatch(new RedlineRegisterDetailsDataRequest(this.changeDocId));
              }
            }
        });
}

updateSubsToSignOff(subsystems: string, signOffsDto: SignOffStatusDTO[]) {
  let subs = subsystems
      .split(',')
      .map(s => s.trim())
      .filter((s) => s !== '');

      signOffsDto.forEach((el: SignOffStatusDTO) => {
      const elSubs = el.subsystems 
          ? el.subsystems.split(',').map(s => s.trim()).filter((s) => s !== '') : [];
      if(elSubs.length > 0) {
          subs = [...subs.filter(s => !elSubs.includes(s))];
      }
  });
  
  this.subsToSignOff = subs;
}

  addNewItem(statusType: StatusType) {
    if (statusType === this.statusTypes.Workpack) {
      const workpack = { 
        id: 0, 
        workpackNumber: this.newNbWorkpackNumber, 
        workpackStatus: this.newWorkpackStatus,
        changeDocId: this.changeDocId
      } as Workpacks
      this.store.dispatch(new DcnRfiRegisterAddWorkpackRequest(workpack))
      this.resetWorkpackFields();
    } else if (statusType === this.statusTypes.Redline) {
      const redline = { 
        id: 0, 
        changeDocId: this.changeDocId,
        redlineNumber: this.formControl.value,
        tcoStatus: this.newTcoStatus,
        rlmuStatus: this.newRlmuStatus
      } as RedlineBaseDetails
      this.store.dispatch(new DcnRfiRegisterAddRedlineRequest(redline))
      this.resetRedlineFields();
      this.store.dispatch(new DcnRfiRegisterDetailsDataRequest(this.changeDocId));
    }
  }

    removeNewItem(statusType: StatusType) {
      if (statusType === this.statusTypes.Workpack) {
        this.resetWorkpackFields();
      } else if (statusType === this.statusTypes.Redline) {
        this.resetRedlineFields();
      }
  }

  expandCollapseItems() {
    this.expandTextArea = !this.expandTextArea;
  }

  searchRedlineNumbers = (search = '', takeCount = 100000, page = 0) => {
    return this.dcnRfiRedlineRegisterService.searchRedlineNumbers(search, takeCount, page).pipe(debounceTime(300))
      .subscribe((numbers: {id: string}[]) => {
        this.redlineNumbers = numbers;
      });;
  };

  isDisabledSignOff() {
    let rlmuStatuses = this.dcnRfiDetails.redlineBaseDetails.filter(d => d.rlmuStatus === 'Not Validated' || d.rlmuStatus === 'Rejected by DLH');
    return this.signOffFieldImplementationMode === DcnRfiSignOffFieldImplementationMode.DisabledForRLMUToGo 
      && rlmuStatuses.length > 0;
  }

  updateWorkflowStatus(changeDocId: number, newWorkflowStatus: string) {
    const workflow = { changeDocId: changeDocId, workflowStatus: newWorkflowStatus } as WorkflowStatusUpdate;
    this.store.dispatch(new DcnRfiRegisterUpdateWorkflowStatusRequest(workflow))
  }

  updateWorkpackStatus(workpackNumber: string, newWorkpackStatus: string) {
    const workpack = { changeDocId: this.dcnRfiDetails.id, workpackNumber: workpackNumber, workpackStatus: newWorkpackStatus} as Workpacks;
    this.store.dispatch(new DcnRfiRegisterUpdateWorkpackRequest(workpack));
  }

  updateRlmuStatus(id: number, redlineNumber: string, tcoStatus: string, newRlmuStatus: string) {
    const rlmuStatus = { id, changeDocId: this.dcnRfiDetails.id ?? 0, redlineNumber, tcoStatus, rlmuStatus: newRlmuStatus } as RedlineBaseDetails;
    this.store.dispatch(new DcnRfiRegisterUpdateRlmuStatusRequest(rlmuStatus));
  }

  deleteWorkpack(workpackNumber: string) {
    this.store.dispatch(new DcnRfiRegisterDeleteWorkpackRequest(this.dcnRfiDetails.id, workpackNumber));
  }

  openHistoryPopup = () => {
    this.isLoading = true;
    this.commentService
        .getComments(`${this.currentElement.id}`, this.currentScope)
        .subscribe((data) => {
            this.isLoading = false;
            const scope = this.currentScope;
            this.popupService.openPopup(
                new PopupSettings(
                    CommentsHistoryComponent,
                    null,
                    null,
                    {
                        commentHist: data,
                        action: (id: number) => {
                            data = data.filter((s) => s.id !== id);
                            this.store.dispatch(dcnRfiRegisterDeleteCommentRequest({
                                id,
                                scope,
                                lastComment: data.length ? data[0] : null
                            }));
                        },
                    },
                    200,
                    200
                )
            );
        });
  };

  startEditingComment = () => {
    this.newComment = '';
    this.isEditingComment = true;
  };

  saveNewComment = () => {
    this.store.dispatch(
      new DcnRfiRedlineRegisterAddCommentRequest(
          this.currentElement.id,
          this.newComment,
          [],
          this.currentZone
      )
    );
  };

  cancelEditingComment = () => {
    this.newComment = this.currentElement.comment;
    this.isEditingComment = false;
  };

  private resetWorkpackFields() {
    this.showNewWorkpackField = false;
    this.newWorkpackStatus = '';
    this.newNbWorkpackNumber = '';
  }

  private resetRedlineFields() {
    this.showNewRedlineField = false;
    this.formControl.reset();
    this.newTcoStatus = '';
    this.newRlmuStatus = '';
  }

  private titleCase(string: string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  goToEb() {
    if (this.dcnRfiDetails.qrCode !== '' && this.dcnRfiDetails.qrCode !== null) {
      window.open(this.dcnRfiDetails.qrCode, '_blank');
  }
  else
  {
    let url = Constants.baseEbUrl.replace('{docNo}', this.changeDocId.toString());
    window.open(url, '_blank');
  }    
  }
}
