import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile } from 'rxjs/operators';
import { WaypointService } from 'src/app/services/api/webapi-services/waypoint.service';
import { WaypointDTO } from 'src/app/models/waypoint-dto';

@Component({
    selector: 'app-add-waypoint-popup',
    templateUrl: './add-waypoint-popup.component.html',
    styleUrls: ['./add-waypoint-popup.component.scss'],
})
export class AddWaypointPopupComponent extends BaseComponent {
    loading: boolean;
    addWaypointForm: UntypedFormGroup;

    constructor(
        public dialogRef: MatDialogRef<AddWaypointPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: ToastService,
        private waypointService: WaypointService
    ) {
        super();

        this.addWaypointForm = new UntypedFormGroup({
            name: new UntypedFormControl('', Validators.required),
            description: new UntypedFormControl(''),
        });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        const form = new WaypointDTO();

        form.name = this.addWaypointForm.controls.name.value;
        form.description = this.addWaypointForm.controls.description.value;

        this.waypointService
            .add(form)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
                (data) => {
                    this.loading = false;
                    this.toastService.Success(`The waypoint ${form.name} has been added.`);
                    this.dialogRef.close({ success: true, data: data });
                },
                (error) => {
                    this.loading = false;
                    if (error.status === 409) {
                        this.toastService.Error('Waypoint entered already exists in SCMT.');
                    } else {
                        this.toastService.Error(
                            'An error occurred while adding a waypoint. Please contact a Program Administrator.'
                        );
                    }
                }
            );
    }
}
