import { Action } from "@ngrx/store";
import { LeakTestPackPriority } from "./model";

export enum LeakTestPackPriorityHistoryActionTypes{
    LeakTestPackPriorityHistoryRequest = '[Leak Test Pack History] Leak Test Pack Priority History Request',
    LeakTestPackPriorityHistorySuccess = '[Leak Test Pack History] Leak Test Pack Priority History Success',
    LeakTestPackPriorityHistoryError = '[Leak Test Pack History] Leak Test Pack Priority History Error',
}

export class LeakTestPackPriorityHistoryRequest implements Action {
    readonly type = LeakTestPackPriorityHistoryActionTypes.LeakTestPackPriorityHistoryRequest;

    constructor(public payload: { leakTestPackTrackerNo: string }) {}
}

export class LeakTestPackPriorityHistorySuccess implements Action {
    readonly type = LeakTestPackPriorityHistoryActionTypes.LeakTestPackPriorityHistorySuccess;

    constructor(public payload: LeakTestPackPriority[]) {}
}

export class LeakTestPackPriorityHistoryError implements Action {
    readonly type = LeakTestPackPriorityHistoryActionTypes.LeakTestPackPriorityHistoryError;

    constructor(public payload: string) {}
}

export type LeakTestPackPriorityActions = 
    | LeakTestPackPriorityHistoryRequest
    | LeakTestPackPriorityHistorySuccess
    | LeakTestPackPriorityHistoryError;