import { BaseComponent } from '../../base.component';
import { Component, OnInit } from '@angular/core';
import { ApplicationState } from 'src/app/store/model';
import { Store, Action } from '@ngrx/store';
import { takeWhile, finalize } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ManualUploadFilesUploadRequest } from 'src/app/store/manual-upload/actions';
import * as _ from 'lodash';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import {
    ExceptionsFileUploaded,
    ExceptionsValidateDeltaRequest,
    ExceptionsAddStartedLog,
} from 'src/app/store/automatic-upload/exceptions/actions';
import {
    CertificateFileUploaded,
    CertificateValidateDeltaRequest,
    CertificateAddStartedLog,
} from 'src/app/store/automatic-upload/certificate/actions';
import { PLIValidateDeltaRequest, PLIFileUploaded, PLIAddStartedLog } from 'src/app/store/automatic-upload/pli/actions';
import { ITRValidateDeltaRequest, ITRFileUploaded, ITRAddStartedLog } from 'src/app/store/automatic-upload/itr/actions';
import {
    TagsValidateDeltaRequest,
    TagsFileUploaded,
    TagsAddStartedLog,
} from 'src/app/store/automatic-upload/tags/actions';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { UploadLinkDTO } from 'src/app/models/upload-link-dto';
import {
    IMDBAddStartedLog,
    IMDBFileUploaded,
    IMDBValidateDeltaRequest,
} from 'src/app/store/automatic-upload/imdb/actions';
import {
    LoopAddStartedLog,
    LoopFileUploaded,
    LoopValidateDeltaRequest,
} from 'src/app/store/automatic-upload/loop/actions';
import { ImportType } from '../import-type';
import * as moment from 'moment';
import { LiveDataService } from 'src/app/services/api/webapi-services/live-data.service';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { PriorityNumbersDeltaRequest } from 'src/app/store/automatic-upload/priority-numbers/actions';

@Component({
    selector: 'app-automatic-upload',
    templateUrl: './automatic-upload.component.html',
    styleUrls: ['./automatic-upload.component.scss'],
})
export class AutomaticUploadComponent extends BaseComponent implements OnInit {
    activatedRoute$ = this.store.select((store) => store.manualUploadState.activatedRoute);
    uploadInProgress$ = this.store.select((store) => store.manualUploadState.uploadInProgress);
    isAdmin = false;
    navLinks: UploadLinkDTO[] = [];
    activeNavLink: UploadLinkDTO;
    isValidateButtonDisabled = true;
    isAutomaticUploadInProgress = true;
    recordsForDeletion = 0;
    isConfigLoading = false;
    validateActions: Action[] = [
        new ITRValidateDeltaRequest(),
        new PLIValidateDeltaRequest(),
        new TagsValidateDeltaRequest(),
        new CertificateValidateDeltaRequest(),
        new ExceptionsValidateDeltaRequest(),
        new IMDBValidateDeltaRequest(),
        new LoopValidateDeltaRequest(),
    ];
    inProgressObservables = [
        this.store.select((store) => store.itrState.isImportInProgress),
        this.store.select((store) => store.pliState.isImportInProgress),
        this.store.select((store) => store.tagsState.isImportInProgress),
        this.store.select((store) => store.certificateState.isImportInProgress),
        this.store.select((store) => store.exceptionsState.isImportInProgress),
        this.store.select((store) => store.imdbState.isImportInProgress),
        this.store.select((store) => store.loopState.isImportInProgress),
    ];

    constructor(
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private popupService: PopupService,
        private configurationService: ConfigurationService,
        private liveDataService: LiveDataService,
        private roleService: RoleService
    ) {
        super();
        this.navLinks = [
            {
                label: 'itr',
                link: './itr',
                index: 0,
                type: 'ITR',
                fileUploadedAction: new ITRFileUploaded(),
                addLogAction: new ITRAddStartedLog(),
            },
            {
                label: 'pli',
                link: './pli',
                index: 1,
                type: 'PLI',
                fileUploadedAction: new PLIFileUploaded(),
                addLogAction: new PLIAddStartedLog(),
            },
            {
                label: 'subsystem to tag',
                link: './tag',
                index: 2,
                type: 'Tags',
                fileUploadedAction: new TagsFileUploaded(),
                addLogAction: new TagsAddStartedLog(),
            },
            {
                label: 'certificate',
                link: './certificate',
                index: 3,
                type: 'Certificates',
                fileUploadedAction: new CertificateFileUploaded(),
                addLogAction: new CertificateAddStartedLog(),
            },
            {
                label: 'exception',
                link: './exceptions',
                index: 4,
                type: 'Exceptions',
                fileUploadedAction: new ExceptionsFileUploaded(),
                addLogAction: new ExceptionsAddStartedLog(),
            },
            {
                label: 'imdb redline',
                link: './imdb',
                index: 5,
                type: 'IMDB',
                fileUploadedAction: new IMDBFileUploaded(),
                addLogAction: new IMDBAddStartedLog(),
            },
            {
                label: 'loop',
                link: './loop',
                index: 6,
                type: 'Loop',
                fileUploadedAction: new LoopFileUploaded(),
                addLogAction: new LoopAddStartedLog(),
            },
            {
                label: 'pipeline',
                link: './pipeline',
                index: 7,
                type: 'Pipeline',
                fileUploadedAction: null,
                addLogAction: null,
            },
            {
                label: 'Status Update',
                link: './status-update',
                index: 8,
                type: 'status-update',
                fileUploadedAction: null,
                addLogAction: null,
            },
            // {
            //     label: 'Priority Numbers',
            //     link: './priority-numbers',
            //     index: 9,
            //     type: 'PriorityNumbers',
            //     fileUploadedAction: new LoopFileUploaded(),
            //     addLogAction: new LoopAddStartedLog(),
            // },
        ];
    }

    ngOnInit() {
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        combineLatest([
            this.store.select((store) => store.itrState.isDataReadyToProcess),
            this.store.select((store) => store.pliState.isDataReadyToProcess),
            this.store.select((store) => store.tagsState.isDataReadyToProcess),
            this.store.select((store) => store.certificateState.isDataReadyToProcess),
            this.store.select((store) => store.exceptionsState.isDataReadyToProcess),
            this.store.select((store) => store.imdbState.isDataReadyToProcess),
            this.store.select((store) => store.loopState.isDataReadyToProcess),
            this.activatedRoute$,
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => {
                setTimeout(() => {
                    this.activeNavLink = this.navLinks.find((n) => n.link === `./${data[data.length - 1]}`);
                    this.isValidateButtonDisabled = this.activeNavLink ? !data[this.activeNavLink.index] : true;
                });
            });

        combineLatest([
            this.store.select((store) => store.itrState.recordsForDeletion),
            this.store.select((store) => store.pliState.recordsForDeletion),
            this.store.select((store) => store.tagsState.recordsForDeletion),
            this.store.select((store) => store.certificateState.recordsForDeletion),
            this.store.select((store) => store.exceptionsState.recordsForDeletion),
            this.store.select((store) => store.imdbState.recordsForDeletion),
            this.store.select((store) => store.loopState.recordsForDeletion),
            this.activatedRoute$,
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data: number[]) => {
                setTimeout(() => {
                    this.activeNavLink = this.navLinks.find((n) => n.link === `./${data[data.length - 1]}`);
                    this.recordsForDeletion = this.activeNavLink ? data[this.activeNavLink.index] : 0;
                });
            });

        combineLatest([
            this.store.select((store) => store.itrState.isAutomaticUploadInProgress),
            this.store.select((store) => store.pliState.isAutomaticUploadInProgress),
            this.store.select((store) => store.tagsState.isAutomaticUploadInProgress),
            this.store.select((store) => store.certificateState.isAutomaticUploadInProgress),
            this.store.select((store) => store.exceptionsState.isAutomaticUploadInProgress),
            this.store.select((store) => store.imdbState.isAutomaticUploadInProgress),
            this.store.select((store) => store.loopState.isAutomaticUploadInProgress),
            this.activatedRoute$,
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data: boolean[]) => {
                setTimeout(() => {
                    this.activeNavLink = this.navLinks.find((n) => n.link === `./${data[data.length - 1]}`);
                    this.isAutomaticUploadInProgress = this.activeNavLink ? data[this.activeNavLink.index] : false;
                });
            });
    }

    fileChangeEvent(files: Array<File>) {
        const filesWrapper = new Array<File>();
        const formData = new FormData();
        filesWrapper.push(...files);

        const tagsImport = this.activeNavLink.link === './tag';

        if (tagsImport) {
            let areAllExpectedFilesSelected = true;
            this.isConfigLoading = true;
            this.configurationService
                .getConfigData()
                .pipe(
                    takeWhile(() => this.isAlive),
                    finalize(() => (this.isConfigLoading = false))
                )
                .subscribe((configData) => {
                    const expectedFilenames = configData
                        .find((x) => x.key === 'Filenames for Tag Import')
                        .value.split(',')
                        .map((n) => n.trim());

                    expectedFilenames.forEach((file) => {
                        if (!filesWrapper.some((x) => x.name === file)) {
                            areAllExpectedFilesSelected = false;
                        }
                    });

                    if (!areAllExpectedFilesSelected) {
                        this.toastService.Error(
                            'Please select all expected files: ' +
                                expectedFilenames.join(', ') +
                                ' (you can change it in the configuration)'
                        );
                        return;
                    }

                    this.uploadFiles(filesWrapper, formData);
                });
        } else {
            this.uploadFiles(filesWrapper, formData);
        }
    }

    uploadFiles(filesWrapper: Array<File>, formData: FormData) {
        const fileTypes: string[] = [];
        filesWrapper.forEach((file) => {
            let type = this.activeNavLink.type;

            fileTypes.push(type);
            formData.append(type, file, file.name);
        });

        _.uniq(fileTypes).forEach((t) => {
            this.store.dispatch(this.activeNavLink.fileUploadedAction);
            this.store.dispatch(this.activeNavLink.addLogAction);
        });

        this.store.dispatch(new ManualUploadFilesUploadRequest(formData));
    }

    validateDelta() {
        let warning = this.recordsForDeletion > 0 ?
        `<p><span class="warning"">Warning: </span> This action will delete ${this.recordsForDeletion} record(s).</p>` : '';
        
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    htmlText: `<p>Are you sure you want to import data and update existing records?</p>`
                    + warning,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.store.dispatch(this.validateActions[this.activeNavLink.index]);
                    this.isValidateButtonDisabled = true;
                    this.recordsForDeletion = 0;
                }
            });
    }

    downloadLiveFile() {
        this.isConfigLoading = true;
        let importType: ImportType;

        switch (this.activeNavLink.label) {
            case 'itr':
                importType = ImportType.ITR;
                break;
            case 'pli':
                importType = ImportType.PLI;
                break;
            case 'subsystem to tag':
                importType = ImportType.Tag;
                break;
            case 'certificate':
                importType = ImportType.Certificate;
                break;
            case 'exception':
                importType = ImportType.Exception;
                break;
            case 'imdb redline':
                importType = ImportType.IMDBRedline;
                break;
            case 'loop':
                importType = ImportType.Loop;
                break;
            case 'Priority Numbers':
                importType = ImportType.PriorityNumbers;
                break;
        }

        this.liveDataService.downloadLiveDataAsync(importType).subscribe((data: any) => {
            const blob = new Blob([data], {
                type: 'application/octet-stream',
            });
            saveAs(blob, `livedata_${ImportType[importType]}_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);

            this.isConfigLoading = false;
        });
    }
}
