import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';

@Component({
  selector: 'app-overdue-exceptions-by-priority',
  templateUrl: './overdue-exceptions-by-priority.component.html',
  styleUrls: ['./overdue-exceptions-by-priority.component.scss']
})
export class OverdueExceptionsByPriorityComponent implements OnInit {
  isChartLoading$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.isChartLoading);
  isSubsystemChartLoading$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.isSubsystemChartLoading);
  showTable$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.showTable);
  showSubsystemTable$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.showSubsystemTable);

  constructor(private store: Store<ApplicationState>,) { }

  ngOnInit(): void {
    // This is intentional
  }

}
