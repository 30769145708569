import { Action } from '@ngrx/store';
import { TotalCompleteDataDTO } from '../reports/milestone-dashboard/model';

export class HomeSystemCompletionDataRequest implements Action {
    readonly type = HomeDataActionTypes.HomeSystemCompletionDataRequest;
}

export class HomeSystemCompletionDataRequestSuccess implements Action {
    readonly type = HomeDataActionTypes.HomeSystemCompletionDataRequestSuccess;
    constructor(public payload: TotalCompleteDataDTO[]) {}
}

export class HomeSystemCompletionDataRequestError implements Action {
    readonly type = HomeDataActionTypes.HomeSystemCompletionDataRequestError;
    constructor(public error: string) {}
}

export class HomeSystemCompletionDataFilterPropertyUpdate implements Action {
    readonly type = HomeDataActionTypes.HomeSystemCompletionDataFilterPropertyUpdate;
    constructor(public payload: { key: string; value: any }) {}
}

export class HomeNPWDataRequest implements Action {
    readonly type = HomeDataActionTypes.HomeNPWDataRequest;
}

export class HomeNPWDataRequestSuccess implements Action {
    readonly type = HomeDataActionTypes.HomeNPWDataRequestSuccess;
    constructor(public payload: TotalCompleteDataDTO[]) {}
}

export class HomeNPWDataRequestError implements Action {
    readonly type = HomeDataActionTypes.HomeNPWDataRequestError;
    constructor(public error: string) {}
}

export class HomeNPWDataFilterPropertyUpdate implements Action {
    readonly type = HomeDataActionTypes.HomeNPWDataFilterPropertyUpdate;
    constructor(public payload: { key: string; value: any }) {}
}

export enum HomeDataActionTypes {
    HomeSystemCompletionDataRequest = '[Home Data] SystemCompletion Status Request',
    HomeSystemCompletionDataRequestSuccess = '[Home Data] SystemCompletion Status Success',
    HomeSystemCompletionDataRequestError = '[Home Data] SystemCompletion Status  Error',
    HomeSystemCompletionDataFilterPropertyUpdate = '[Home Data] SystemCompletion Filter Property Update',
    HomeNPWDataRequest = '[Home Data] NPW Status Request',
    HomeNPWDataRequestSuccess = '[Home Data] NPW Status Success',
    HomeNPWDataRequestError = '[Home Data] NPW Status  Error',
    HomeNPWDataFilterPropertyUpdate = '[Home Data] NPW Filter Property Update',
}

export type HomeActions =
    | HomeSystemCompletionDataRequest
    | HomeSystemCompletionDataRequestSuccess
    | HomeSystemCompletionDataRequestError
    | HomeSystemCompletionDataFilterPropertyUpdate
    | HomeNPWDataRequest
    | HomeNPWDataRequestSuccess
    | HomeNPWDataRequestError
    | HomeNPWDataFilterPropertyUpdate;
