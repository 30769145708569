import { Action } from '@ngrx/store';
import { MyPageDataPagination } from './model';

export enum MyPageActionTypes {
    MyPageFilterRequest = '[My Page] Filter Request',
    MyPageFilterSuccess = '[My Page] Filter Success',
    MyPageFilterError = '[My Page] Filter Error',
    MyPageFilterPropertyUpdate = '[My Page] Filter Property Update',
    MyPageFilterReset = '[My Page] Filter Reset',
    MyPageMarkReadRequest = '[My Page] Mark Read Request',
    MyPageMarkReadSuccess = '[My Page] Mark Read Success',
    MyPageMarkReadError = '[My Page] Mark Read Error',
}

export class MyPageFilterRequest implements Action {
    readonly type = MyPageActionTypes.MyPageFilterRequest;
}

export class MyPageFilterSuccess implements Action {
    readonly type = MyPageActionTypes.MyPageFilterSuccess;

    constructor(public payload: MyPageDataPagination) {}
}

export class MyPageFilterError implements Action {
    readonly type = MyPageActionTypes.MyPageFilterError;

    constructor(public payload: string) {}
}

export class MyPageFilterPropertyUpdate implements Action {
    readonly type = MyPageActionTypes.MyPageFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class MyPageFilterReset implements Action {
    readonly type = MyPageActionTypes.MyPageFilterReset;
}

export class MyPageMarkReadRequest implements Action {
    readonly type = MyPageActionTypes.MyPageMarkReadRequest;

    constructor(public payload: { mentionId: number; isRead: boolean }) {}
}

export class MyPageMarkReadSuccess implements Action {
    readonly type = MyPageActionTypes.MyPageMarkReadSuccess;
}

export class MyPageMarkReadError implements Action {
    readonly type = MyPageActionTypes.MyPageMarkReadError;

    constructor(public payload: string) {}
}

export type MyPageActions =
    | MyPageFilterRequest
    | MyPageFilterSuccess
    | MyPageFilterError
    | MyPageFilterPropertyUpdate
    | MyPageFilterReset
    | MyPageMarkReadRequest
    | MyPageMarkReadSuccess
    | MyPageMarkReadError;
