import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import * as ExportEventActions from './actions';
import { mergeMap, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ExcelExportService } from 'src/app/services/api/webapi-services/excel-export.service';
import { SignalRService } from 'src/app/services/api/signalr.service';
import { ExportEvent } from './model';
import { DetailedStatusSetLoader } from '../detailed-status/actions';

@Injectable()
export class ExcelExportEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private excelExportService: ExcelExportService,
        private signalRService: SignalRService
    ) {}

    getUnprocessedExportEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExportEventActions.getRecentExportEventsRequest),
            mergeMap(() =>
                this.excelExportService.getRecent().pipe(
                    map((payload) => ExportEventActions.getRecentExportEventsSuccess({ payload })),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting export events. Please contact Program Administrator.'
                        );
                        return of(ExportEventActions.getRecentExportEventsError(error));
                    })
                )
            )
    ));

    getRecentExportEventsReceived$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExportEventActions.getRecentExportEventsSuccess),
            mergeMap((action) => {
                if (action.payload?.length > 0 && action.payload?.some((x) => x.status === 'New')) {
                    this.signalRService.connectToExcelHub();
                }
                return of();
            })
        ), { dispatch: false }
    );

    exportToExcelRequest$ = createEffect(() =>
    this.actions$.pipe(
        ofType(ExportEventActions.detailedStatusExportToExcelRequest),
        mergeMap(() => {
            this.signalRService.connectToExcelHub();
            return of(new DetailedStatusSetLoader(true));
        })
    ));

    exportToExcel$ = createEffect(() =>
    this.actions$.pipe(
        ofType(ExportEventActions.detailedStatusExportToExcelRequest),
        map((action) => action.payload),
        mergeMap((scope) =>
            of(scope).pipe(
                withLatestFrom(
                    this.store.select((store) => store.detailedStatusState[scope]),
                    this.store.select((state) => state.detailedStatusState.lockedFilter)
                )
            )
        ),
        switchMap(([payload, detailedStatusFilter, lockedFilter]) => {
            const filter = {
                ...detailedStatusFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.excelExportService.sendDetailedStatusExportEvent(lockedFilter || filter).pipe(
                switchMap((payload: ExportEvent) => {
                    this.toastService.Success(
                        'Export request sent. Download will start automatically when the file is ready.'
                    );
                    return [ExportEventActions.detailedStatusExportToExcelSuccess({ payload }), new DetailedStatusSetLoader(false)];
                }),
                catchError((error) => {
                    if (error.status === 409) {
                        this.toastService.Info(
                            "You've already requested to export this file. Wait for the file to be ready."
                        );
                    } else {
                        this.toastService.Error(
                            'Error occurred while sending export request. Please contact Program Administrator.'
                        );
                    }
                    return from([ExportEventActions.detailedStatusExportToExcelError(error), new DetailedStatusSetLoader(false)]);
                })
            );
        })
    ));

    exportEventDownloadRequest$ = createEffect(() =>
    this.actions$.pipe(
        ofType(ExportEventActions.downloadExportedFileRequest),
        mergeMap((action) =>
            this.excelExportService.download(action.exportEvent.blobName).pipe(
                map(
                    (data) =>
                        ExportEventActions.downloadExportedFileSuccess({
                            content: data,
                            fileName: action.exportEvent.fileName,
                        })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while downloading file ${action.exportEvent.fileName}. Please contact Program Administrator.`
                    );
                    return of(ExportEventActions.downloadExportedFileError(error));
                })
            )
        )
    ));

    downloadRequestSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(ExportEventActions.downloadExportedFileSuccess),
            map((action) => {
                const blob = new Blob([action.content], {
                    type: 'application/octet-stream',
                });

                saveAs(blob, action.fileName);
            })
        ), { dispatch: false }
    );
}
