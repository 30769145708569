<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span>Activities Constraints</span>
        </div>
    </div>

    <app-activities-constraints-filter></app-activities-constraints-filter>
    <app-activities-constraints-chart></app-activities-constraints-chart>

    <app-loading-indicator *ngIf="(isChartLoading$ | async)"></app-loading-indicator>
</main>