import { createAction, props } from '@ngrx/store';
import { RedZoneDataPagination, RedZoneFilter } from './model';
import * as moment from 'moment';
import { PhaseType } from 'src/app/enums';
import { UserDetail } from '../common.model';
import { CommentDetailDTO } from '../detailed-status/model';

export const filterRequest = createAction('[RedZone] Filter Request', props<{filter?: RedZoneFilter}>());
export const filterSuccess = createAction('[RedZone] Filter Success', props<RedZoneDataPagination>());
export const filterError = createAction('[RedZone] Filter Error', props<{ error: string }>());

export const filterPropertyUpdate = createAction(
    '[RedZone] Filter Property Update',
    props<{ key: string; value: any }>()
);
export const filterReset = createAction('[RedZone] Filter Reset');

export const exportToExcelRequest = createAction('[RedZone] Export to Excel Request');
export const exportToExcelSuccess = createAction('[RedZone] Export to Excel Success', props<{ response: any }>());
export const exportToExcelError = createAction('[RedZone] Export to Excel Error', props<{ error: string }>());

export const forecastDateUpdateRequest = createAction(
    '[RedZone] Forecast Date Update Request',
    props<{
        subsystem: string;
        phaseType: PhaseType;
        forecastDate: moment.Moment;
        discipline: string;
        allDisciplines: boolean;
    }>()
);
export const forecastDateUpdateSuccess = createAction('[RedZone] Forecast Date Update Success');
export const forecastDateUpdateError = createAction('[RedZone] Forecast Date Update Error', props<{ error: string }>());

export const addCommentRequest = createAction(
    '[RedZone] Add Comment Request',
    props<{ id: any; description: string; mentions: UserDetail[] }>()
);
export const addCommentSuccess = createAction(
    '[RedZone] Add Comment Success',
    props<{ id: any; description: string; mentions: UserDetail[] }>()
);
export const addCommentError = createAction('[RedZone] Add Comment Error', props<{ error: string }>());

export const addBulkCommentRequest = createAction(
    '[RedZone] Add Bulk Comment Request',
    props<{ description: string; mentions: UserDetail[] }>()
);
export const addBulkCommentSuccess = createAction(
    '[RedZone] Add Bulk Comment Success',
    props<{ description: string; mentions: UserDetail[] }>()
);
export const addBulkCommentError = createAction('[RedZone] Add Bulk Comment Error', props<{ error: string }>());
export const removeCommentRequest = createAction(
    '[RedZone] Remove Comment Request',
    props<{ id: number; lastComment: CommentDetailDTO; entityId: any }>()
);
export const removeCommentSuccess = createAction(
    '[RedZone] Remove Comment Success',
    props<{ id: number; lastComment: CommentDetailDTO; entityId: any }>()
);
export const removeCommentError = createAction('[RedZone] Remove Comment Error', props<{ error: string }>());

export const setFlagColorRequest = createAction(
    '[RedZone] Set Flag Color Request',
    props<{ subsystem: string; color: string }>()
);
export const setFlagColorSuccess = createAction(
    '[RedZone] Set Flag Color Success',
    props<{ subsystem: string; color: string }>()
);
export const setFlagColorError = createAction('[RedZone] Set Flag Color Error', props<{ error: string }>());
export const clearFlagColorRequest = createAction('[RedZone] Clear Flag Color Request');
export const clearFlagColorSuccess = createAction('[RedZone] Clear Flag Color Success');
export const clearFlagColorError = createAction('[RedZone] Clear Flag Color Error', props<{ error: string }>());
