import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { take, takeWhile } from 'rxjs/operators';
import { ManualUploadSetActivatedRoute } from 'src/app/store/manual-upload/actions';
import { MatTableDataSource } from '@angular/material/table';
import * as StatusUpdateActions from 'src/app/store/automatic-upload/status-update/actions';
import { Subscription } from 'rxjs';
import { PipelineLog } from 'src/app/models/pipeline-log';

@Component({
    selector: 'app-status-update',
    templateUrl: './status-update.component.html',
    styleUrls: ['./status-update.component.scss'],
})
export class StatusUpdateComponent extends BaseComponent implements OnInit {
    logs: MatTableDataSource<PipelineLog>;
    displayedUploadLogDataColumns = [
        'runId',
        'type',
        'status',
        'startDate',
        'endDate',
        'message',
    ];

    logs$ = this.store.select((store) => store.statusUpdateState.logs.filter((logs) => logs.type.endsWith('Status Update')));
    isLoading$ = this.store.select((store) => store.statusUpdateState.isLoading);
    showOrder = true;
    interval$ = this.store.select((store) => store.manualUploadState.interval);
    intervalSub: Subscription = null;

    constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new ManualUploadSetActivatedRoute(urlSegments[0].path));
        });

        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(StatusUpdateActions.statusUpdateLogRequest());
            });
        });

        this.logs$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((logs) => (this.logs = new MatTableDataSource(logs)));
    }
}
