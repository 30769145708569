import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { AutomaticUploadTrigger } from 'src/app/components/admin-section/scmt/automated-file-transfers-mcplus/automatic-uploads/time-triggers/time-triggers.component';
import { PipelineLog } from 'src/app/models/pipeline-log';

@Injectable({
    providedIn: 'root',
})
export class AutomaticUploadsService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly configurationEndpoint: string = '/automaticuploads';

    constructor(private http: HttpClient) {}

    getAutomaticUploadTriggers() {
        return this.http.get<AutomaticUploadTrigger[]>(`${this.rootUrl}${this.configurationEndpoint}/triggers`);
    }

    updateAutomaticUploadTrigger(entity: AutomaticUploadTrigger) {
        return this.http.post<AutomaticUploadTrigger>(`${this.rootUrl}${this.configurationEndpoint}/triggers`, entity);
    }

    deleteAutomaticUploadTrigger(id: number) {
        return this.http.delete<number>(`${this.rootUrl}${this.configurationEndpoint}/triggers/${id}`);
    }

    getAutomaticUploadState() {
        return this.http.get<number>(`${this.rootUrl}${this.configurationEndpoint}/state`);
    }

    runAutomaticUpload() {
        return this.http.get<number>(`${this.rootUrl}${this.configurationEndpoint}/run`);
    }
    
    getLogs() {
        return this.http.get<PipelineLog[]>(`${this.rootUrl}${this.configurationEndpoint}/logs`);
    }
}
