import { Action, createAction, props } from '@ngrx/store';
import { SubsystemScope, Zone } from 'src/app/enums';
import { UserDetail } from '../common.model';
import { CommentDetailDTO } from '../detailed-status/model';
import {
    DcnRfiRedlineRegisterFilter,
    DcnRfiRegisterDetails,
    DcnRfiRegisterResultDto,
    RedlineBaseDetails,
    RedlineRegisterDetails,
    RedlineRegisterResultDto,
    RegisterType,
    WorkflowStatusUpdate,
    Workpacks,
} from './model';

export enum DcnRfiRedlineRegisterActionTypes {
    FilterPropertyUpdate = '[DCN/RFI Redline Register] Filter was set',
    FilterReset = '[DCN/RFI Redline Register] Filter Reset',

    DcnRfiRegisterDataRequest = '[DCN/RFI Redline Register] Requesting Dcn/Rfi Register',
    DcnRfiRegisterDataRequestSuccess = '[DCN/RFI Redline Register] Request for Dcn/Rfi Register data Success',
    DcnRfiRegisterDataRequestError = '[DCN/RFI Redline Register] Request for Dcn/Rfi Register data Error',

    RedlineRegisterDataRequest = '[DCN/RFI Redline Register] Requesting Redline Register',
    RedlineRegisterDataRequestSuccess = '[DCN/RFI Redline Register] Request for Redline Register data Success',
    RedlineRegisterDataRequestError = '[DCN/RFI Redline Register] Request for Redline Register data Error',

    DcnRfiRegisterDetailsDataRequest = '[DCN/RFI Redline Register] Requesting Dcn/Rfi Register Details',
    DcnRfiRegisterDetailsDataRequestSuccess = '[DCN/RFI Redline Register] Request for Dcn/Rfi Register Details data Success',
    DcnRfiRegisterDetailsDataRequestError = '[DCN/RFI Redline Register] Request for Dcn/Rfi Register Details data Error',

    RedlineRegisterDetailsDataRequest = '[DCN/RFI Redline Register] Requesting Redline Register Details',
    RedlineRegisterDetailsDataRequestSuccess = '[DCN/RFI Redline Register] Request for Redline Register Details data Success',
    RedlineRegisterDetailsDataRequestError = '[DCN/RFI Redline Register] Request for Redline Register Details data Error',

    DcnRfiRedlineRegisterAddCommentRequest = '[DCN/RFI Redline Register] Add Comment Request',
    DcnRfiRedlineRegisterAddCommentSuccess = '[DCN/RFI Redline Register] Add Comment Success',
    DcnRfiRedlineRegistertAddCommentError = '[DCN/RFI Redline Register] Add Comment Error',

    DcnRfiRegisterAddWorkpackRequest = '[DCN/RFI Redline Register] Add Workpack Request',
    DcnRfiRegisterAddWorkpackSuccess = '[DCN/RFI Redline Register] Add Workpack Success',
    DcnRfiRegisterAddWorkpackError = '[DCN/RFI Redline Register] Add Workpack Error',

    DcnRfiRegisterAddRedlineRequest = '[DCN/RFI Redline Register] Add Redline Request',
    DcnRfiRegisterAddRedlineSuccess = '[DCN/RFI Redline Register] Add Redline Success',
    DcnRfiRegisterAddRedlineError = '[DCN/RFI Redline Register] Add Redline Error',

    DcnRfiRedlineRegisterExportToExcelRequest = '[DCN/RFI Redline Register] Export to Excel Request',
    DcnRfiRedlineRegisterExportToExcelSuccess = '[DCN/RFI Redline Register] Export to Excel Success',
    DcnRfiRedlineRegisterExportToExcelError = '[DCN/RFI Redline Register] Export to Excel Error',

    DcnRfiRegisterUpdateWorkflowStatusRequest = '[DCN/RFI Redline Register] Update WorkflowStatus Request',
    DcnRfiRegisterUpdateWorkflowStatusSuccess = '[DCN/RFI Redline Register] Update WorkflowStatus Success',
    DcnRfiRegisterUpdateWorkflowStatusError = '[DCN/RFI Redline Register] Update WorkflowStatus Error',

    DcnRfiRegisterUpdateWorkpackRequest = '[DCN/RFI Redline Register] Update Workpack Request',
    DcnRfiRegisterUpdateWorkpackSuccess = '[DCN/RFI Redline Register] Update Workpack Success',
    DcnRfiRegisterUpdateWorkpackError = '[DCN/RFI Redline Register] Update Workpack Error',

    DcnRfiRegisterUpdateRlmuStatusRequest = '[DCN/RFI Redline Register] Update Rlmu Status Request',
    DcnRfiRegisterUpdateRlmuStatusSuccess = '[DCN/RFI Redline Register] Update Rlmu Status Success',
    DcnRfiRegisterUpdateRlmuStatusError = '[DCN/RFI Redline Register] Update Rlmu Status Error',

    DcnRfiRegisterDeleteWorkpackRequest = '[DCN/RFI Redline Register] Delete Workpack Request',
    DcnRfiRegisterDeleteWorkpackSuccess = '[DCN/RFI Redline Register] Delete Workpack Success',
    DcnRfiRegisterDeleteWorkpackError = '[DCN/RFI Redline Register] Delete Workpack Error',
}

export class DcnRfiRedlineRegisterFilterPropertyUpdate implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.FilterPropertyUpdate;

    constructor(public filter: DcnRfiRedlineRegisterFilter) {
        // This is intentional
    }
}

export class DcnRfiRedlineRegisterFilterReset implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.FilterReset;

    constructor(public registerType: RegisterType) {
        // This is intentional
    }
}

export class DcnRfiRegisterDataRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDataRequest;

    constructor() {
        // This is intentional
    }
}

export class DcnRfiRegisterDataRequestSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDataRequestSuccess;

    constructor(public dcnRfiRegisterResult: DcnRfiRegisterResultDto) {
        // This is intentional
    }
}

export class DcnRfiRegisterDataRequestError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDataRequestError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class RedlineRegisterDataRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.RedlineRegisterDataRequest;

    constructor() {
        // This is intentional
    }
}

export class RedlineRegisterDataRequestSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.RedlineRegisterDataRequestSuccess;

    constructor(public redlineRegisterResult: RedlineRegisterResultDto) {
        // This is intentional
    }
}

export class RedlineRegisterDataRequestError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.RedlineRegisterDataRequestError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class DcnRfiRegisterDetailsDataRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDetailsDataRequest;

    constructor(public id: number) {
        // This is intentional
    }
}

export class DcnRfiRegisterDetailsDataRequestSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDetailsDataRequestSuccess;

    constructor(public dcnRfiRegisterDetails: DcnRfiRegisterDetails) {
        // This is intentional
    }
}

export class DcnRfiRegisterDetailsDataRequestError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDetailsDataRequestError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class RedlineRegisterDetailsDataRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.RedlineRegisterDetailsDataRequest;

    constructor(public id: number) {
        // This is intentional
    }
}

export class RedlineRegisterDetailsDataRequestSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.RedlineRegisterDetailsDataRequestSuccess;

    constructor(public redlineRegisterDetails: RedlineRegisterDetails) {
        // This is intentional
    }
}

export class RedlineRegisterDetailsDataRequestError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.RedlineRegisterDetailsDataRequestError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class DcnRfiRedlineRegisterAddCommentRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterAddCommentRequest;

    constructor(public id: number, public description: string, public mentions: UserDetail[], public zone: Zone) {
        // This is intentional
    }
}

export class DcnRfiRedlineRegisterAddCommentSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterAddCommentSuccess;

    constructor(public id: number, public comment: CommentDetailDTO, public zone: Zone) {
        // This is intentional
    }
}

export class DcnRfiRedlineRegistertAddCommentError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegistertAddCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class DcnRfiRegisterAddWorkpackRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddWorkpackRequest;

    constructor(public workpack: Workpacks) {
        // This is intentional
    }
}

export class DcnRfiRegisterAddWorkpackSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddWorkpackSuccess;

    constructor(public workpack: Workpacks) {
        // This is intentional
    }
}

export class DcnRfiRegisterAddWorkpackError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddWorkpackError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class DcnRfiRegisterAddRedlineRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddRedlineRequest;

    constructor(public redline: RedlineBaseDetails) {
        // This is intentional
    }
}

export class DcnRfiRegisterAddRedlineSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddRedlineSuccess;

    constructor(public redline: RedlineBaseDetails) {
        // This is intentional
    }
}

export class DcnRfiRegisterAddRedlineError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddRedlineError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class DcnRfiRedlineRegisterExportToExcelRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterExportToExcelRequest;
}

export class DcnRfiRedlineRegisterExportToExcelSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterExportToExcelSuccess;

    constructor(public payload: any) {
        // This is intentional
    }
}

export class DcnRfiRedlineRegisterExportToExcelError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterExportToExcelError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class DcnRfiRegisterUpdateWorkflowStatusRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkflowStatusRequest;

    constructor(public workflowStatus: WorkflowStatusUpdate) {
        // This is intentional
    }
}

export class DcnRfiRegisterUpdateWorkflowStatusSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkflowStatusSuccess;

    constructor(public workflowStatus: WorkflowStatusUpdate) {
        // This is intentional
    }
}

export class DcnRfiRegisterUpdateWorkflowStatusError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkflowStatusError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class DcnRfiRegisterUpdateWorkpackRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkpackRequest;

    constructor(public workpack: Workpacks) {
        // This is intentional
    }
}

export class DcnRfiRegisterUpdateWorkpackSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkpackSuccess;

    constructor(public workpack: Workpacks) {
        // This is intentional
    }
}

export class DcnRfiRegisterUpdateWorkpackError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkpackError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class DcnRfiRegisterUpdateRlmuStatusRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateRlmuStatusRequest;

    constructor(public redlineBaseDetails: RedlineBaseDetails) {
        // This is intentional
    }
}

export class DcnRfiRegisterUpdateRlmuStatusSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateRlmuStatusSuccess;

    constructor(public redlineBaseDetails: RedlineBaseDetails) {
        // This is intentional
    }
}

export class DcnRfiRegisterUpdateRlmuStatusError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateRlmuStatusError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class DcnRfiRegisterDeleteWorkpackRequest implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDeleteWorkpackRequest;

    constructor(public changeDocId: number, public workpackNumber: string) {
        // This is intentional
    }
}

export class DcnRfiRegisterDeleteWorkpackSuccess implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDeleteWorkpackSuccess;

    constructor(public changeDocId: number, public workpackNumber: string) {
        // This is intentional
    }
}

export class DcnRfiRegisterDeleteWorkpackError implements Action {
    readonly type = DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDeleteWorkpackError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export const dcnRfiRegisterDeleteCommentRequest = createAction(
    '[DCN/RFI Redline Register] Delete Comment Request',
    props<{ id: number, scope: SubsystemScope, lastComment: CommentDetailDTO }>()
);

export const dcnRfiRegisterDeleteCommentSuccess = createAction(
    '[DCN/RFI Redline Register] Delete Comment Success',
    props<{ id: number, scope: SubsystemScope, lastComment: CommentDetailDTO }>()
);

export const dcnRfiRegisterDeleteCommentError = createAction(
    '[DCN/RFI Redline Register] Delete Comment Error',
    props<{ error: string }>()
);

export type DcnRfiRedlineRegisterActions =
    | DcnRfiRedlineRegisterFilterPropertyUpdate
    | DcnRfiRedlineRegisterFilterReset
    | DcnRfiRegisterDataRequest
    | DcnRfiRegisterDataRequestSuccess
    | DcnRfiRegisterDataRequestError
    | RedlineRegisterDataRequest
    | RedlineRegisterDataRequestSuccess
    | RedlineRegisterDataRequestError
    | DcnRfiRegisterDetailsDataRequest
    | DcnRfiRegisterDetailsDataRequestSuccess
    | DcnRfiRegisterDetailsDataRequestError
    | RedlineRegisterDetailsDataRequest
    | RedlineRegisterDetailsDataRequestSuccess
    | RedlineRegisterDetailsDataRequestError
    | DcnRfiRedlineRegisterAddCommentRequest
    | DcnRfiRedlineRegisterAddCommentSuccess
    | DcnRfiRedlineRegistertAddCommentError
    | DcnRfiRegisterAddWorkpackRequest
    | DcnRfiRegisterAddWorkpackSuccess
    | DcnRfiRegisterAddWorkpackError
    | DcnRfiRegisterAddRedlineRequest
    | DcnRfiRegisterAddRedlineSuccess
    | DcnRfiRegisterAddRedlineError
    | DcnRfiRedlineRegisterExportToExcelRequest
    | DcnRfiRedlineRegisterExportToExcelSuccess
    | DcnRfiRedlineRegisterExportToExcelError
    | DcnRfiRegisterUpdateWorkflowStatusRequest
    | DcnRfiRegisterUpdateWorkflowStatusSuccess
    | DcnRfiRegisterUpdateWorkflowStatusError
    | DcnRfiRegisterUpdateWorkpackRequest
    | DcnRfiRegisterUpdateWorkpackSuccess
    | DcnRfiRegisterUpdateWorkpackError
    | DcnRfiRegisterUpdateRlmuStatusRequest
    | DcnRfiRegisterUpdateRlmuStatusSuccess
    | DcnRfiRegisterUpdateRlmuStatusError
    | DcnRfiRegisterDeleteWorkpackRequest
    | DcnRfiRegisterDeleteWorkpackSuccess
    | DcnRfiRegisterDeleteWorkpackError
    | ReturnType<typeof dcnRfiRegisterDeleteCommentRequest>
    | ReturnType<typeof dcnRfiRegisterDeleteCommentSuccess>
    | ReturnType<typeof dcnRfiRegisterDeleteCommentError>;
