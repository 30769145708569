<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add users to sign-off NPW</span></p>
    <div>
        <form [formGroup]="addUserToContractsForm">
            <mat-form-field>
                <mat-label>Project Team</mat-label>
                <mat-select
                    [compareWith]="compareRoles"
                    formControlName="userRole"
                    (openedChange)="onRoleClosed($event)"
                >
                    <mat-option *ngFor="let c of types" [value]="c.value">
                        {{ c.key }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <app-multiple-items-selector
                class="container300"
                [setInput]="setUsersInput"
                [searchFunc]="searchUsers"
                [isAsync]="true"
                [isSelectAll]="false"
                [oneItemMode]="true"
                formControlName="user"
                [propertyToShow]="'name'"
                [placeholder]="'Users'"
                [formSetter]="'user'"
                [filterForm]="addUserToContractsForm"
                (autocompleteClosed)="onUsersClosed()"
                [isTableAutoComplete]="true"
                [displayedColumns]="usersAutocompleteDisplayedColumns"
            >
            </app-multiple-items-selector>

            <mat-form-field>
                <mat-label>Contracts</mat-label>
                <mat-select formControlName="contracts" multiple>
                    <mat-option
                        *ngFor="
                            let c of contractors
                                | contractorList: findRole(types, addUserToContractsForm.controls['userRole'].value)
                        "
                        [value]="c.contractNo"
                        >{{ c.contractNo }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
            <div class="row">
                <button mat-button type="button" class="sct-button sct-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!addUserToContractsForm.valid"
                    class="sct-button sct-button-light"
                    (click)="onConfirm()"
                >
                    add
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
