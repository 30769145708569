import {
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    OnDestroy,
    AfterViewInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { CdkPortal, DomPortalHost} from '@angular/cdk/portal';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { SkylineRequest } from 'src/app/store/reports/skyline/actions';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { WeekType } from 'src/app/enums';
import { SkylinePart } from 'src/app/store/reports/skyline/model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-skyline-print',
    templateUrl: './skyline-print.component.html',
    styleUrls: ['./skyline-print.component.scss', '../reports-skyline.component.scss'],
})
export class SkylinePrintComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() close = new EventEmitter();

    @ViewChild(CdkPortal) portal: CdkPortal;

    private externalWindow: Window = null;
    private host: DomPortalHost;
    skylinePhase = '';
    skylineType = '';
    printIsLoading$ = this.store.select((state) => state.skylineState.printIsLoading);
    parts$ = this.store.select((state) => state.skylineState.skylineParts);

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private store: Store<ApplicationState>,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        [this.skylinePhase, this.skylineType] = this.activatedRoute.routeConfig.path.split('/');

        if (this.skylinePhase === 'skyline' && this.skylineType === undefined) {
            [this.skylinePhase, this.skylineType] = this.activatedRoute.firstChild.snapshot.routeConfig.path.split('/');
        }
        this.store.dispatch(new SkylineRequest({ printMode: true }));
    }

    ngAfterViewInit() {
        this.printIsLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            if (isLoading === false) {
                setTimeout(() => this.externalWindow.print(), 1000);
            }
        });

        this.externalWindow = window.open('', 'PRINT', '');

        this.externalWindow.onbeforeunload = () => this.ngOnDestroy();

        this.host = new DomPortalHost(
            this.externalWindow.document.body,
            this.componentFactoryResolver,
            this.applicationRef,
            this.injector
        );

        document.querySelectorAll('link, style').forEach((htmlElement) => {
            this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
        });

        this.host.attach(this.portal);
    }

    getPastWeeksLength(part: SkylinePart) {
        if (part.weeks === undefined) return 0;
        return part.weeks.filter((w) => w.weekType === WeekType.Past).length;
    }

    ngOnDestroy() {
        this.externalWindow.close();
        this.close.emit();
        this.host.detach();
    }
}
