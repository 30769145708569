import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { takeWhile, finalize, take, catchError } from 'rxjs/operators';
import { Dictionary } from 'typescript-collections';
import { saveAs } from 'file-saver';
import { forkJoin, of, throwError } from 'rxjs';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { Attachment, CTAAttachment, CTAFormGroup, CTARegisterDTO } from 'src/app/store/cta-register/model';
import { AreaDTO } from 'src/app/store/common.model';
import { BaseComponent } from 'src/app/components/base.component';
import { ToastService } from 'src/app/services/shared/toast.service';
import { CTAService } from 'src/app/services/api/webapi-services/cta.service';
import { FormService } from 'src/app/services/shared/form.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { CTARegisterFilterRequest } from 'src/app/store/cta-register/actions';


@Component({
    selector: 'app-cta-form',
    templateUrl: './cta-form.component.html',
    styleUrls: ['./cta-form.component.scss'],
})
export class CTAFormComponent extends BaseComponent implements OnInit {
    areas: AreaDTO[] = [];
    ctaFormGroup: UntypedFormGroup;
    isLoading: boolean = false;
    newAttachments: Attachment[] = [];
    deletedAttachments: Attachment[] = [];
    isUploadInProgress = false;
    editCTA: CTAFormGroup = null;
    titleText = 'create new CTA';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CTARegisterDTO,
        private dialogRef: MatDialogRef<CTAFormComponent>,
        private toastService: ToastService,
        private ctaService: CTAService,
        private formSvc: FormService,
        private lookupService: LookupService,
        private store: Store<ApplicationState>
    ) {
        super();
        this.ctaFormGroup = this.formSvc.createSimpleForm(new CTAFormGroup());
    }

    ngOnInit() {
        if (this.data) {
            this.editCTA = {
                ...new CTAFormGroup(),
                areaId: this.data.areaId,
                description: this.data.description,
                date: this.data.date,
                fileName: this.data.fileName,
                attachments: this.data.attachments,
                isEdit: true
            };
            this.ctaFormGroup.controls.fileName.disable();
            this.ctaFormGroup.patchValue(this.editCTA, { emitEvent: false });
            this.ctaFormGroup.updateValueAndValidity();
            this.titleText = 'edit CTA';
        }
        this.isLoading = true;

        forkJoin([
            this.lookupService.getAreas()
        ])
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe(([areas]) => {
                this.areas = areas;
            });
    }

    close() {
        this.deleteAllAttachments();
        this.dialogRef.close();
    }

    submit() {
        if (this.deletedAttachments.length > 0) {
            this.deletedAttachments.forEach((element: CTAAttachment) => {
                this.deleteFile(element);
            });
            this.deletedAttachments = [];
        }
        if (this.newAttachments.length === 0) {
            this.submitCTA();
        } else {
            this.uploadNewAttachments();
        }
    }

    submitCTA() {
        const cta = new CTARegisterDTO();
        cta.areaId = this.ctaFormGroup.controls.areaId.value;
        cta.description = this.ctaFormGroup.controls.description.value;
        cta.fileName = this.ctaFormGroup.controls.fileName.value;
        cta.attachments = this.ctaFormGroup.controls.attachments.value;
        cta.date = this.ctaFormGroup.controls.date.value;
        this.isLoading = true;

        if (this.data) {
            cta.id = this.data.id;
            cta.fileName = this.data.fileName;
            this.ctaService
                .updateCTA(cta)
                .pipe(
                    takeWhile(() => this.isAlive),
                    finalize(() => (this.isLoading = false)),
                    catchError((err) => {
                       
                            this.toastService.Error(
                                'Error has occurred while updating CTA. Please contact Program Administrator.'
                            );
                        
                        return throwError(err);
                    })
                )
                .subscribe(() => {
                    this.toastService.Success('CTA saved correctly.');
                    this.store.dispatch(new CTARegisterFilterRequest());
                    this.dialogRef.close();
                });
        } else {
            this.ctaService
                .addCTA(cta)
                .pipe(
                    takeWhile(() => this.isAlive),
                    finalize(() => (this.isLoading = false)),
                    catchError(() => {
                        this.toastService.Error(
                            'Error has occurred while creating new CTA. Please contact Program Administrator.'
                        );
                        return of(null);
                    })
                )
                .subscribe(() => {
                    this.toastService.Success('CTA saved correctly.');
                    this.dialogRef.close();
                });
        }
    }

    fileChangeEvent(files: Array<File>) {
        const filesCTAapper = new Array<File>();
        filesCTAapper.push(...files);
        filesCTAapper.forEach((file) => {
            if (file.size === 0) {
                this.toastService.Info(`File ${file.name} has 0B size. Please upload a valid file.`);
                return;
            }
            if (
                this.newAttachments.filter((x) => x.file.name === file.name).length > 0 ||
                (this.ctaFormGroup.controls.attachments.value &&
                    this.ctaFormGroup.controls.attachments.value.filter((x: CTAAttachment) => x.name === file.name)
                        .length > 0)
            ) {
                this.toastService.Info(
                    'File ' + file.name + ' already uploaded. Please delete existing file and re-upload.'
                );
            } else {
                const attachment = new Attachment();
                attachment.file = file;
                attachment.name = file.name;
                attachment.isValid = true;
                this.newAttachments.push(attachment);

                const ctaAttachment = new CTAAttachment();
                ctaAttachment.name = file.name;
                this.ctaFormGroup.controls.attachments.setValue([
                    ...this.ctaFormGroup.controls.attachments.value,
                    ctaAttachment,
                ]);
            }
        });
    }

    uploadNewAttachments() {
        const formData = new FormData();
        this.newAttachments.forEach((file) => {
            formData.append(file.file.name, file.file);
        });
        this.isUploadInProgress = true;
        this.ctaService
            .uploadCTAAttachments(formData)
            .pipe(
                take(1),
                finalize(() => (this.isUploadInProgress = false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while uploading attachment(s). Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((uploadedAttachments: Dictionary<string, string>) => {
                this.newAttachments = [];
                let attachments = this.ctaFormGroup.controls.attachments.value;
                attachments.forEach((element: CTAAttachment) => {
                    if (!element.link) {
                        element.link = uploadedAttachments[element.name];
                    }
                });
                this.ctaFormGroup.controls.attachments.setValue(attachments);
                this.submitCTA();
            });
    }

    downloadUploadedAttachment(uploadedAttachment: CTAAttachment) {
        this.ctaService
            .downloadCTAAttachment(uploadedAttachment.link)
            .pipe(
                take(1),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, uploadedAttachment.name);
            });
    }

    deleteUploadedAttachment(uploadedAttachment: CTAAttachment) {
        let deleteIndexFormGroup = this.ctaFormGroup.controls.attachments.value.findIndex(
            (s) => s.name === uploadedAttachment.name
        );

        if (!uploadedAttachment.link) {
            let deleteIndex = this.newAttachments.findIndex((s) => s.name === uploadedAttachment.name);
            this.newAttachments.splice(deleteIndex, 1);
        } else {
            this.deletedAttachments.push(this.ctaFormGroup.controls.attachments.value[deleteIndexFormGroup]);
        }

        this.ctaFormGroup.controls.attachments.setValue([
            ...this.ctaFormGroup.controls.attachments.value.slice(0, deleteIndexFormGroup),
            ...this.ctaFormGroup.controls.attachments.value.slice(deleteIndexFormGroup + 1),
        ]);
    }

    deleteFile(uploadedAttachment: CTAAttachment) {
        this.ctaService
            .deleteCTAAttachment([uploadedAttachment.link])
            .pipe(
                take(1),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while deleting attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe(() => {});
    }

    @HostListener('window:popstate')
    onPopState() {
        this.deleteAllAttachments();
    }

    @HostListener('window:beforeunload')
    onBeforeUnload() {
        this.deleteAllAttachments();
    }

    private deleteAllAttachments() {
        if (
            (!this.editCTA || !this.editCTA.isEdit) &&
            this.ctaFormGroup.controls.attachments.value &&
            this.ctaFormGroup.controls.attachments.value.length
        ) {
            this.ctaService
                .deleteCTAAttachment(this.ctaFormGroup.controls.attachments.value.map((a: CTAAttachment) => a.link))
                .pipe(take(1))
                .subscribe();
            this.ctaFormGroup.controls.attachments.setValue([]);
        }
    }
}
