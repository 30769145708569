import { Injectable } from '@angular/core';
import { CommentDetailDTO, DetailedStatusFilterBase, O3ConstraintDTO } from 'src/app/store/detailed-status/model';
import { Zone, SubsystemScope, ExceptionScope} from 'src/app/enums';
import { HttpClient } from '@angular/common/http';
import { UserDetail } from 'src/app/store/common.model';
import { appConfig } from 'src/app/app.config';
import {
    MilestoneCommentDTO,
    MilestoneDashboardType,
} from 'src/app/store/reports/milestone-dashboard/model';
import { RedZoneFilter } from 'src/app/store/redzone/model';
import { BlueZoneFilter } from 'src/app/store/bluezone/model';
import { ChangeManagementFilter } from 'src/app/store/change-management/model';
import { RFOFilter } from 'src/app/store/RFO/model';
import { LoopStatusFilter } from 'src/app/store/loop-status/model';
import { PascrStatusFilter } from 'src/app/store/pascr-status/model';
import { ScheduleActivityAddCommentDto } from './schedule-activity-planning.service';
import { Observable } from 'rxjs';
import { CriticalRfiFilter } from 'src/app/store/critical-rfi/model';

@Injectable({
    providedIn: 'root',
})
export class CommentService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly commentEndpoint: string = '/comment';
    constructor(private http: HttpClient) {}

    addComment(entityId: string, description: string, scope: SubsystemScope, mentions: UserDetail[]) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/${scope}`, {
            EntityId: entityId,
            Description: description,
            Mentions: mentions,
        });
    }

    addNBResponsiblePersonComment(entityId: string, description: string, scope: SubsystemScope, mentions: UserDetail[]) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/nbresponsibleperson/${scope}`, {
            EntityId: entityId,
            Description: description,
            Mentions: mentions,
        });
    }

    addRFOWorksToGOComment(entityId: string, description: string, scope: SubsystemScope, mentions: UserDetail[], subSystem : string, rfo : string) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/rfoworkstogo/${scope}`, {
            EntityId: entityId,
            Description: description,
            Mentions: mentions,
            Subsystem : subSystem,
            RFO : rfo,
        });
    }
    
    addO3Constraint(tagNumber: string, exceptionNumber:string, pliqvdNumber:string, subsystem : string ,o3ConstraintID: string, scope: SubsystemScope, mentions: UserDetail[]) {
        return this.http.post<O3ConstraintDTO>(`${this.rootUrl}${this.commentEndpoint}/o3constraint/${scope}`, {
            tagNumber: tagNumber,
            exceptionNumber:exceptionNumber,
            pliqvdNumber: pliqvdNumber,
            subsystem: subsystem,
            o3ConstraintId: o3ConstraintID,
            Mentions: mentions,
        });
    }

    addBulkComment(
        description: string,
        scope: SubsystemScope,
        mentions: UserDetail[],
        filter: DetailedStatusFilterBase
    ) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/bulk/${scope}`, {
            comment: {
                Description: description,
                Mentions: mentions,
            },
            filter,
        });
    }
    addBulkExceptionComment(
        description: string,        
        mentions: UserDetail[],
        exceptionNumber : string
        
    ) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/exceptionbulk`, {
            comment: {
                Description: description,
                Mentions: mentions,
            },
            exceptionNumber,
        });
    }

    addBulkCommentZone(
        description: string,
        mentions: UserDetail[],
        filter: RedZoneFilter | BlueZoneFilter | ChangeManagementFilter | RFOFilter | CriticalRfiFilter,
        zone: Zone
    ) {
        return this.http.post(`${this.rootUrl}${this.commentEndpoint}/bulk/zone/${zone}`, {
            comment: {
                Description: description,
                Mentions: mentions,
            },
            filter,
        });
    }

    addCommentZone(entityId: string, description: string, zone: Zone, mentions: UserDetail[]) : Observable<CommentDetailDTO> {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/zone/${zone}`, {
            EntityId: entityId,
            Description: description,
            Mentions: mentions,
        });
    }

    addCommentCriticalRfi(entityId: string, description: string, zone: Zone, mentions: UserDetail[],type: string) : Observable<CommentDetailDTO> {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/criticalrfi/${zone}`, {
            EntityId: entityId,
            Description: description,
            Mentions: mentions,
            Type: type
        });
    }

    addCommentChangeDocument(entityId: string, description: string, mentions: UserDetail[]) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/changeDocument`, {
            EntityId: entityId,
            Description: description,
            Mentions: mentions,
        });
    }

    addCommentLoop(loopName: string, tagNumber: string, tagType: string, description: string, mentions: UserDetail[]) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/loop`, {
            LoopName: loopName,
            TagNumber: tagNumber,
            TagType: tagType,
            Description: description,
            Mentions: mentions,
        });
    }
    
    addCommentPascr(pascrNumber: string, description: string, mentions: UserDetail[]) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/pascr`, {
            PascrNumber: pascrNumber,
            Description: description,
            Mentions: mentions,
        });
    }

    addExceptionComment(entityId: string, description: string, additionalEntityId: string, scope: SubsystemScope, mentions: UserDetail[]) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/exception/${scope}`, {
            EntityId: entityId,
            AdditionaEntityId:additionalEntityId, 
            Description: description,
            Mentions: mentions,
        });
    }

    addBulkCommentLoop(description: string, mentions: UserDetail[], filter: LoopStatusFilter) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/bulk/loop`, {
            comment: {
                Description: description,
                Mentions: mentions,
            },
            filter,
        });
    }

    addBulkCommentPascr(description: string, mentions: UserDetail[], filter: PascrStatusFilter) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/bulk/pascr`, {
            comment: {
                Description: description,
                Mentions: mentions,
            },
            filter,
        });
    }

    addCommentMilestone(entityId: string, description: string, mentions: UserDetail[]) {
        return this.http.post<CommentDetailDTO>(`${this.rootUrl}${this.commentEndpoint}/milestone`, {
            EntityId: entityId,
            Description: description,
            Mentions: mentions,
        });
    }

    deleteCommentMilestone(id: number) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/milestone/${id}`);
    }

    getMilestioneComments(no: string, type: MilestoneDashboardType) {
        return this.http.get<MilestoneCommentDTO[]>(`${this.rootUrl}${this.commentEndpoint}/milestone/${no}/${type}`);
    }

    getComments(entityId: string, scope: SubsystemScope) {
        return this.http.get<CommentDetailDTO[]>(`${this.rootUrl}${this.commentEndpoint}/${scope}/${entityId}`);
    }

    getCriticalRfiComments(entityId: string, type: string) {
        return this.http.get<CommentDetailDTO[]>(`${this.rootUrl}${this.commentEndpoint}/criticalrfi/${type}/${entityId}`);
    }

    deleteZoneComment(entityId: number, zone: Zone) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/zone/${zone}/${entityId}`);
    }
    deleteITRComment(Id: number, scope: SubsystemScope) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/commentshistory/${scope}/${Id}`);
    }

    deleteExceptionComment(Id: number, exscope: ExceptionScope) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/exceptioncommentshistory/${exscope}/${Id}`);
    }

    deleteRFOWorksToGoComment(Id: number, scope: SubsystemScope) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/rfoworkstogohistory/${scope}/${Id}`);
    }

    getCommentsBySubsystem(subsystemId: string, scope: Zone) {
        return this.http.get<CommentDetailDTO[]>(`${this.rootUrl}${this.commentEndpoint}/zone/${scope}/${subsystemId}`);
    }
    
    getCommentsHistory(Id: string, scope: SubsystemScope) {
        return this.http.get<CommentDetailDTO[]>(`${this.rootUrl}${this.commentEndpoint}/commentshistory/${scope}/${Id}`);
    }

    getExceptionCommentsHistory(entityId: string, additionaEntityId: string, exscope: ExceptionScope) {
        return this.http.get<CommentDetailDTO[]>(`${this.rootUrl}${this.commentEndpoint}/exceptioncommentshistory/${exscope}/${entityId}/${additionaEntityId}`);
    }

    getRFOWorksToGoCommentsHistory(entityId: string, rfo: string, subsystem: string) {
        return this.http.get<CommentDetailDTO[]>(`${this.rootUrl}${this.commentEndpoint}/rfoworkstogocommentshistory/${rfo}/${subsystem}/${entityId}/`);
    }

    addCommentAcativity(comment: ScheduleActivityAddCommentDto): Observable<any>  {
        return this.http.post(`${this.rootUrl}${this.commentEndpoint}/activity`, comment);
    }

    getActivityComments(activityId: string, discipline: string) {
        return this.http.get<CommentDetailDTO[]>(`${this.rootUrl}${this.commentEndpoint}/activity/${activityId}/${discipline}`);
    }

    deleteCommentActivity(id: number) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/activity/${id}`);
    }

    deleteComment(id: number, scope: SubsystemScope) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/${scope}/${id}`, {});
    }

    deleteCommentChangeDocument(id: number) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/changeDocument/${id}`, {});
    }

    getLoopComments(loopName: string, tagNumber: string, tagType: string){
        return this.http.get<CommentDetailDTO[]>(`${this.rootUrl}${this.commentEndpoint}/loop/${loopName}/${tagNumber}/${tagType}`);
    }
    
    getPascrComments(pascrNumber: string){
        return this.http.get<CommentDetailDTO[]>(`${this.rootUrl}${this.commentEndpoint}/pascr/${pascrNumber}`);
    }

    deleteCommentLoop(id: number) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/loop/${id}`);
    }

    deleteCommentPascr(id: number) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/pascr/${id}`);
    }

    deleteCommentCriticalRfi(id: number, type: string) {
        return this.http.delete(`${this.rootUrl}${this.commentEndpoint}/criticalrfi/${type}/${id}`, {});
    }
}
