import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { ITRCommentUploadDeletePagination } from './model';

export enum ITRCommentUploadActionTypes {
    ITRCommentUploadTemplateFileRequest = '[ITRCommentUpload] Template File Request',
    ITRCommentUploadTemplateFileSuccess = '[ITRCommentUpload] Template File Success',
    ITRCommentUploadTemplateFileError = '[ITRCommentUpload] Template File Error',
    ITRCommentUploadLogRequest = '[ITRCommentUpload] Upload Log Request',
    ITRCommentUploadLogSuccess = '[ITRCommentUpload] Upload Log Success',
    ITRCommentUploadLogError = '[ITRCommentUpload] Upload Log Error',
    ITRCommentUploadDeleteRecordsRequest = '[ITRCommentUpload] Delete Records Request',
    ITRCommentUploadDeleteRecordsSuccess = '[ITRCommentUpload] Delete Records Success',
    ITRCommentUploadDeleteRecordsError = '[ITRCommentUpload] Delete Records Error',
    ITRCommentUploadSetStatusAndStartDate = '[ITRCommentUpload] Set Status and Start Date',
    ITRCommentUploadDeleteFilterPropertyUpdate = '[ITRCommentUpload] Delete Filter Property Update',
    ITRCommentUploadDeleteFilterReset = '[ITRCommentUpload] Delete Filter Reset',
    ITRCommentUploadPatchDeleteRecordRequest = '[ITRCommentUpload] Patch Delete Record Request',
    ITRCommentUploadPatchDeleteRecordSuccess = '[ITRCommentUpload] Patch Delete Record Success',
    ITRCommentUploadPatchDeleteRecordError = '[ITRCommentUpload] Patch Delete Record Error',
    ITRCommentUploadPatchAllDeleteRecordsRequest = '[ITRCommentUpload] Patch All Delete Records Request',
    ITRCommentUploadPatchAllDeleteRecordsSuccess = '[ITRCommentUpload] Patch All Delete Records Success',
    ITRCommentUploadPatchAllDeleteRecordsError = '[ITRCommentUpload] Patch All Delete Records Error',
    ITRCommentUploadValidateDataRequest = '[ITRCommentUpload] Validate Data Request',
    ITRCommentUploadValidateDataSuccess = '[ITRCommentUpload] Validate Data Success',
    ITRCommentUploadValidateDataError = '[ITRCommentUpload] Validate Data Error',
    ITRCommentUploadFileUploaded = '[ITRCommentUpload] File Uploaded',
    ITRCommentUploadClearInProgressSpinner = '[ITRCommentUpload] Clear In Progress Spinner',
    ITRCommentUploadDownloadOutputDataRequest = '[ITRCommentUpload] Download Output Data Request',
    ITRCommentUploadDownloadOutputDataSuccess = '[ITRCommentUpload] Download Output Data Success',
    ITRCommentUploadDownloadOutputDataError = '[ITRCommentUpload] Download Output Data Error',
    ITRCommentUploadValidateButtonStateRequest = '[ITRCommentUpload] Validate Button State Request',
    ITRCommentUploadValidateButtonStateSuccess = '[ITRCommentUpload] Validate Button State Success',
    ITRCommentUploadValidateButtonStateError = '[ITRCommentUpload] Validate Button State Error',
    ITRCommentUploadAddStartedLog = '[ITRCommentUpload] Add Started Log',
}

export class ITRCommentUploadTemplateFileRequest implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ITRCommentUploadTemplateFileSuccess implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ITRCommentUploadTemplateFileError implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadTemplateFileError;

    constructor(public payload: string) {}
}

export class ITRCommentUploadAddStartedLog implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadAddStartedLog;
}

export class ITRCommentUploadLogRequest implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadLogRequest;
}

export class ITRCommentUploadLogSuccess implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ITRCommentUploadLogError implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadLogError;

    constructor(public payload: string) {}
}

export class ITRCommentUploadDeleteRequest implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadDeleteRecordsRequest;
}

export class ITRCommentUploadDeleteSuccess implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadDeleteRecordsSuccess;

    constructor(public payload: ITRCommentUploadDeletePagination) {}
}

export class ITRCommentUploadDeleteError implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ITRCommentUploadSetStatusAndStartDate implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class ITRCommentUploadDeleteFilterPropertyUpdate implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ITRCommentUploadDeleteFilterReset implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadDeleteFilterReset;
}

export class ITRCommentUploadPatchDeleteRequest implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadPatchDeleteRecordRequest;

    constructor(public payload: { itrpliNumber: string; deleteState: boolean }) {}
}

export class ITRCommentUploadPatchAllDeleteRequest implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ITRCommentUploadPatchDeleteSuccess implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadPatchDeleteRecordSuccess;
}

export class ITRCommentUploadPatchAllDeleteSuccess implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ITRCommentUploadPatchDeleteError implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class ITRCommentUploadPatchAllDeleteError implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ITRCommentUploadValidateDeleteRequest implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadValidateDataRequest;
}

export class ITRCommentUploadValidateDeleteSuccess implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadValidateDataSuccess;
}

export class ITRCommentUploadValidateDeleteError implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadValidateDataError;

    constructor(public payload: string) {}
}

export class ITRCommentUploadFileUploaded implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadFileUploaded;
}

export class ITRCommentUploadClearInProgressSpinner implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadClearInProgressSpinner;
}


export class ITRCommentUploadDownloadOutputDataRequest implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ITRCommentUploadDownloadOutputDataSuccess implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ITRCommentUploadDownloadOutputDataError implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ITRCommentUploadValidateButtonStateRequest implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadValidateButtonStateRequest;
}

export class ITRCommentUploadValidateButtonStateSuccess implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class ITRCommentUploadValidateButtonStateError implements Action {
    readonly type = ITRCommentUploadActionTypes.ITRCommentUploadValidateButtonStateError;

    constructor(public payload: string) {}
}

export type ITRCommentUploadActions =
    | ITRCommentUploadTemplateFileRequest
    | ITRCommentUploadTemplateFileSuccess
    | ITRCommentUploadTemplateFileError
    | ITRCommentUploadLogRequest
    | ITRCommentUploadLogSuccess
    | ITRCommentUploadLogError
    | ITRCommentUploadDeleteRequest
    | ITRCommentUploadDeleteSuccess
    | ITRCommentUploadDeleteError
    | ITRCommentUploadSetStatusAndStartDate
    | ITRCommentUploadDeleteFilterPropertyUpdate
    | ITRCommentUploadDeleteFilterReset
    | ITRCommentUploadPatchDeleteRequest
    | ITRCommentUploadPatchDeleteSuccess
    | ITRCommentUploadPatchDeleteError
    | ITRCommentUploadPatchAllDeleteRequest
    | ITRCommentUploadPatchAllDeleteSuccess
    | ITRCommentUploadPatchAllDeleteError
    | ITRCommentUploadValidateDeleteRequest
    | ITRCommentUploadValidateDeleteSuccess
    | ITRCommentUploadValidateDeleteError
    | ITRCommentUploadFileUploaded
    | ITRCommentUploadClearInProgressSpinner
    | ITRCommentUploadDownloadOutputDataRequest
    | ITRCommentUploadDownloadOutputDataSuccess
    | ITRCommentUploadDownloadOutputDataError
    | ITRCommentUploadValidateButtonStateRequest
    | ITRCommentUploadValidateButtonStateSuccess
    | ITRCommentUploadValidateButtonStateError
    | ITRCommentUploadAddStartedLog;
