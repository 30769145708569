<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span>Overdue Cat D PLIs</span>
        </div>
    </div>
    <div class="filter-container">
        <form [formGroup]="filterForm">
            <mat-accordion class="filter-container">
                <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                    <mat-expansion-panel-header collapsedHeight="auto">
                        <div *ngIf="!filterExpansionPanel.expanded">
                            <mat-chip-list aria-label="Active filters">
                                <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                    Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                    Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.systemOwners.value.length" removable>
                                    System Owner:
                                    {{ displaySelectedSystemOwner(filterForm.controls.systemOwners.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('systemOwners')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip>
                                    Project Action Only:
                                    {{ displaySelectedRadiobutton(filterForm.controls.projectActionOnly.value) }}
                                </mat-basic-chip>
                                <mat-basic-chip>
                                    Overdue Only:
                                    {{ displaySelectedRadiobutton(filterForm.controls.overdueOnly.value) }}
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                    SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                                </mat-basic-chip>
                            </mat-chip-list>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="mixedapp-container">
                        <mat-form-field class="container300 margin-right20">
                            <mat-label>Project Team</mat-label>
                            <mat-select
                                formControlName="projectTeamNames"
                                multiple
                                (openedChange)="onProjectTeamsClosed($event)"
                            >
                                <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="container300 margin-right20">
                            <mat-label>Discipline</mat-label>
                            <mat-select formControlName="disciplines" multiple>
                                <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="container300 margin-right20">
                            <mat-label>System Owner</mat-label>
                            <mat-select formControlName="systemOwners" multiple>
                                <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="container300 margin-right20">
                            <mat-label>SC Execution Area Manager</mat-label>
                            <mat-select formControlName="scManager" multiple>
                                <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="mixedapp-container">
                        <div class="container-radiobuttons">
                            <mat-label class="title-radiobuttons">Project Action Only</mat-label>
                            <mat-radio-group formControlName="projectActionOnly">
                                <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                                <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                                <mat-radio-button [value]="null">All</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="container-radiobuttons">
                            <mat-label class="title-radiobuttons">Overdue Only</mat-label>
                            <mat-radio-group formControlName="overdueOnly">
                                <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                                <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                                <mat-radio-button [value]="null">All</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </form>
        <div class="buttons-container flex">
            <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
                export to excel
            </button>
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search(true, true)" class="sct-button sct-button-light">search</button>
        </div>
    </div>
    <div class="chart-container" #chart>
        <mat-expansion-panel #chartPanel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Age of Cat-D PLIs (Project Action Only)
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ngx-charts-bar-vertical
                [view]="[900, 300]"
                [results]="chart$ | async"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="false"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [yAxisTickFormatting]="yAxisTickFormatting"
                barPadding="150"
                [scheme]="colorScheme"
                [animations]="false"
                (select)="onSelect($event)"
            >
            </ngx-charts-bar-vertical>
            <div class="ovud-legend">
                <div>Overdue</div>
                <div>Under Review</div>
            </div>
        </mat-expansion-panel>
    </div>
    <div class="results-container">
        <div class="results-title">
            <div class="table-checkboxes">
                <mat-checkbox [checked]="showSubsysName" (change)="showSubsysName = !showSubsysName"
                    >Subsystem Name</mat-checkbox
                >
                <mat-checkbox [checked]="showTagNo" (change)="showTagNo = !showTagNo">Tag Number</mat-checkbox>
                <mat-checkbox [checked]="showTagType" (change)="showTagType = !showTagType">Tag Type</mat-checkbox>
                <mat-checkbox [checked]="showSystemOwner" (change)="showSystemOwner = !showSystemOwner"
                    >System Owner</mat-checkbox
                >
                <mat-checkbox [checked]="showPunchType" (change)="showPunchType = !showPunchType"
                    >Punch Type</mat-checkbox
                >
                <mat-checkbox [checked]="showRaisedBy" (change)="showRaisedBy = !showRaisedBy">Raised By</mat-checkbox>
                <mat-checkbox [checked]="showRaisedDate" (change)="showRaisedDate = !showRaisedDate"
                    >Raised Date</mat-checkbox
                >
                <mat-checkbox
                    [checked]="showMaterialReference"
                    (change)="showMaterialReference = !showMaterialReference"
                    >Material Reference</mat-checkbox
                >
                <mat-checkbox [checked]="showReviewStatus" (change)="showReviewStatus = !showReviewStatus">
                    D Review Status
                </mat-checkbox>
                <mat-checkbox [checked]="showAttachments" (change)="showAttachments = !showAttachments"
                    >Attachments</mat-checkbox
                >
            </div>
        </div>
        <div class="scroll" app-double-scroll>
            <div class="table-container">
                <table
                    mat-table
                    matSort
                    [dataSource]="(table$ | async)?.items"
                    [matSortActive]="sortBy"
                    matSortDisableClear
                    [matSortDirection]="direction"
                >
                    <ng-container matColumnDef="subsystem" sticky>
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subsystem',
                                            getSubsystems,
                                            'columnSubsystem',
                                            'Subsystem',
                                            columnSubsystem,
                                            true
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnSubsystem)
                                    }"
                                    >Subsystem</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.subsystem }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="subsystemName" sticky>
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showSubsysName
                            }"
                        >
                            <div class="sort-container">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subsystemName',
                                            getSubsystemNames,
                                            'columnSubsystemName',
                                            'Subsystem Name',
                                            columnSubsystemName,
                                            true
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnSubsystemName)
                                    }"
                                    >Subsystem Name</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showSubsysName }">
                            {{ element.subsystemName }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="reviewStatusComplete" sticky>
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{ hidecells: !showReviewStatus }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'reviewStatusComplete',
                                            getReviewStatusComplete,
                                            'columnReviewStatusComplete',
                                            'D Review Status',
                                            columnReviewStatus,
                                            true
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnReviewStatus)
                                    }"
                                    >D Review Status</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'reviewStatusComplete'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showReviewStatus }">
                            {{ element.reviewStatusComplete }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Category
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.category }}</td>
                    </ng-container>
                    <ng-container matColumnDef="tagNumber">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showTagNo
                            }"
                            [ngStyle]="{
                                left: showSubsysName ? '280px' : '165px'
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnTagNumber)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'tagNumber',
                                            getTagNumbers,
                                            'columnTagNumber',
                                            'Tag Number',
                                            columnTagNumber
                                        )
                                    "
                                    >Tag Number</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'tagNumber'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{ hidecells: !showTagNo }"
                            [ngStyle]="{
                                left: showSubsysName ? '280px' : '165px'
                            }"
                        >
                            {{ element.tagNumber }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="tagType">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showTagType
                            }"
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'tagType',
                                    getTagTypes,
                                    'columnTagType',
                                    'Tag Type',
                                    columnTagType,
                                    true
                                )
                            "
                            [ngStyle]="showHideColumns(372)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnTagType)
                                    }"
                                    >Tag Type</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'tagType'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{ hidecells: !showTagType }"
                            [ngStyle]="showHideColumns(372)"
                        >
                            {{ element.tagType }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="systemOwner">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showSystemOwner
                            }"
                            (click)="
                                openHeaderCheckListFilter(
                                    'systemOwner',
                                    getSystemOwners,
                                    'columnSystemOwner',
                                    'System Owner',
                                    columnSystemOwner,
                                    true
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnSystemOwner)
                                    }"
                                    >System Owner</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'systemOwner'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showSystemOwner
                            }"
                        >
                            {{ element.systemOwner }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actionBy">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'actionBy',
                                    getActionBys,
                                    'columnActionBy',
                                    'Action By',
                                    columnActionBy,
                                    true
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnActionBy)
                                    }"
                                    >Action by</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'actionBy'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.actionBy }}</td>
                    </ng-container>
                    <ng-container matColumnDef="punchType">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showPunchType
                            }"
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'punchType',
                                    getPunchTypeValues,
                                    'columnPunchType',
                                    'Punch Type',
                                    columnPunchType,
                                    true
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnPunchType)
                                    }"
                                    >Punch Type</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'punchType'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showPunchType }">
                            {{ element.punchType }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="createdDate">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showRaisedDate
                            }"
                            class="hover-pointer"
                            (click)="
                                openHeaderDateFilter(
                                    'createdDate',
                                    'columnCreatedDate',
                                    'Raised Date',
                                    columnCreatedDate
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnCreatedDate)
                                    }"
                                    >Raised Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'createdDate'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showRaisedDate }">
                            {{ element.createdDate | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="raisedBy">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showRaisedBy
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'raisedBy',
                                            getRaisedByValues,
                                            'columnRaisedBy',
                                            'Raised By',
                                            columnRaisedBy,
                                            true
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnRaisedBy)
                                    }"
                                    >Raised By</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'raisedBy'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showRaisedBy }">
                            {{ element.raisedBy }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="discipline">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'discipline',
                                            getDisciplines,
                                            'columnDiscipline',
                                            'Discipline',
                                            columnDiscipline,
                                            true
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnDiscipline)
                                    }"
                                    >Discipline</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.discipline }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="number">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'number',
                                            getNumbers,
                                            'columnNumber',
                                            'Number',
                                            columnNumber
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnNumber)
                                    }"
                                    >PLI</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'number'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="showHideColumns(482)">
                            {{ element.number }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'description',
                                            getDescriptions,
                                            'columnDescription',
                                            'PLI Description',
                                            columnDescription
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnDescription)
                                    }"
                                    >PLI Description</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'description'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.description }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="attachments">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="center"
                            [ngClass]="{ hidecells: !showAttachments }"
                        >
                            Attachments
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="attachments"
                            [ngClass]="{ hidecells: !showAttachments }"
                        >
                            <div class="uploaded-attachment-buttons" *ngFor="let att of element.attachments">
                                <div
                                    class="lds-hourglass"
                                    *ngIf="uploadedAttachmentsActionInProgress.getValue(att.link) || false"
                                ></div>
                                <button
                                    *ngIf="!uploadedAttachmentsActionInProgress.getValue(att.link)"
                                    [title]="att.name"
                                    type="button"
                                    class="download-uploaded-attachment"
                                    (click)="download(att, $event)"
                                ></button>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="materialReference">
                        <th
                            app-col-resize
                            mat-header-cell
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showMaterialReference
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnMaterialReference)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'materialReference',
                                            getMaterialReferences,
                                            'columnMaterialReference',
                                            'MaterialReference',
                                            columnMaterialReference
                                        )
                                    "
                                    >Material Reference</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'materialReference'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showMaterialReference
                            }"
                        >
                            <div>{{ element.materialReference }}</div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="latestComment">
                        <th mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                            <div class="sort-container">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnComments)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'latestComment',
                                            getComments,
                                            'columnComments',
                                            'Comment',
                                            columnComments,
                                            false,
                                            true,
                                            true
                                        )
                                    "
                                    >Comment</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'latestComment'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span [innerHTML]="element.latestComment | formatMentions: element.mentions"></span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="(table$ | async)?.totalCount"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="(isTableLoading$ | async) || (isChartLoading$ | async)"></app-loading-indicator>
</main>
