import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { SystemisedDrawingDataPagination, SystemisedDrawingFilter } from 'src/app/store/systemised-drawing/model';

@Injectable({
    providedIn: 'root',
})
export class SystemisedDrawingService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/systemiseddrawing';
    constructor(private http: HttpClient) {}

    searchSystemisedDrawing(filter: SystemisedDrawingFilter) {
        return this.http.post<SystemisedDrawingDataPagination>(`${this.rootUrl}${this.endpoint}`, filter);
    }

    getEngineeringDrawingNo(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}/engineeringdrawings`, { params });
    }
    getSystemisedDrawingNo(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}/systemiseddrawings`, { params });
    }

    generateExcel(filter: SystemisedDrawingFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    private getParams(search: string, take: number, page: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        return params;
    }
}
