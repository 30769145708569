<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">{{headerTitle}}</span></p>
    <div>
        <form [formGroup]="responsiblePersonForm">
            <app-multiple-items-selector
            class="container620 margin-right20"
            [setInput]="setUsersInput"
            [searchFunc]="searchUsers"
            [isAsync]="true"
            [isSelectAll]="false"
            [oneItemMode]="true"
            formControlName="username"
            [propertyToShow]="'name'"
            [placeholder]="setLabel()"
            [formSetter]="'username'"
            [filterForm]="responsiblePersonForm"
            (autocompleteClosed)="onUsersClosed()"
            [isTableAutoComplete]="true"
            [displayedColumns]="usersAutocompleteDisplayedColumns"
            [panelWidth]="605"       
            >   
            </app-multiple-items-selector>
            <div class="row">
                <button mat-button type="button" class="sct-button sct-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"                  
                    class="sct-button sct-button-light"
                    (click)="onConfirm()"
                >
                    submit
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>


    

