import { CalendarColumn, CheckListColumn, Contractor, Gooc, OrderDirection, RFO, StagedStartUpPriority, WorkAreaWorkerDTO } from '../../common.model';
import { getCurrentWeekStartDate, getWeekEndDates } from 'src/app/extensions';
import { WeeklyPlanActivityType, WeeklyPlanType } from 'src/app/enums';
import { ScheduleActivityITRScope } from '../../schedule-activity-itr/model';

export class ScheduleActivityWeeklyStatus {
    weekStart: Date;
    mhrsInWeek: number;
    activityId: string;
    activityDiscipline: string;

    areWorkPacksReady: boolean;
    inWeeklyPlan?: boolean;
    isWorkComplete: boolean;
    itrInWeek: number;
}

export class ScheduleActivityPlanningUpdateDTO {
    activityId: string;
    activityDiscipline: string;
    forecastFinish?: Date;
    forecastStart?: Date;
    isConstrained: boolean;

    weeklyStatus: ScheduleActivityWeeklyStatus;
    activityStatus: string;
}

export class CreateActivityDTO {
    baseActivityId: string = '';
    activity: string = '';
    activityDiscipline: string = '';
    subsystem: string = '';
    forecastFinish?: Date = null;
    forecastStart?: Date = null;
    phase: string = '';
}

export class ScheduleActivityPlanningDTO {
    activityId: string;
    activity: string;
    activityDiscipline: string;
    activityStatus: string;

    subsystem: string;
    subsystemName: string;

    planStart: Date;
    planFinish: Date;

    forecastFinish?: Date;
    forecastStart?: Date;

    pmcsDiscipline: string;
    area: number;

    itrToGo: number;
    selectedITRsCount: number;
    phase: string;
    isConstrained: boolean;
    constraintType: string;
    fourWeeksComment: string;
    userId?: number;
    comment: string;
    weeklyStatus: ScheduleActivityWeeklyStatus;
    weekColumns: ActivityInWeekColumn[];
}

export class ActivityInWeekColumn {
    week: Date;
    inWeek: boolean;
    columnId: string;
}

export class SubsystemWithScheduleActivitiesDTO {
    subsystem: string;
    subsystemName: string;
    activities: ScheduleActivityPlanningDTO[];
    mcActual?: Date;
    mcForecast?: Date;
    mcPlan?: Date;
    rfsuPlan?: Date;
    rfsuActual?: Date;
    rfsuForecast?: Date;
    isSubmitted: boolean;
    activityFocus: string;

    isLoading: boolean;
}

export class SubsystemsInWeek {
    weekStart = getCurrentWeekStartDate();
    subsystems: string[] = [];
}

export class ScheduleActivityPlanningFilter {
    workAreas: string[] = [];
    disciplines: { name: string }[] = [];
    nbDisciplineLeads: WorkAreaWorkerDTO[] = [];
    nbEngineers: WorkAreaWorkerDTO[] = [];
    tcoAreaCoordinators: WorkAreaWorkerDTO[] = [];
    inWeeklyPlan?: boolean = null;
    markedAsComplete?: boolean = null;
    hasNoITRsToGo?: boolean = null;
    hasNoConstraint?: boolean = null;
    hideCurrentITRs?: boolean = null;
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    mcCompleted?: boolean = null;
    hvacSubsystems?: boolean = null;
    skipActivityDatesValidation?: boolean = null;
    areas: string[] = [];
    subsystems: { id: string; name: string }[] = [];
    itrContractors: { id: string }[] | Contractor[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];

    priorities: { id: string }[] = [];
    scManager: string[] = [];
    selectedTabIndex: number = 1;
    lockFilters?: boolean = false;
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
    subsystemExcludeFilter: { id: string }[] = [];
    itrExcludeFilter: { id: string }[] = [];

    weekStart = getCurrentWeekStartDate();
    weekEnd  = getWeekEndDates();
    lookaheadDays = 7;
    take = 10;
    page = 0;
    sortBy: string = 'planStart';
    direction: OrderDirection = OrderDirection.Desc;

    activityId?: string;
    activityDiscipline?: string;
    activityType: WeeklyPlanActivityType = WeeklyPlanActivityType.inWeek;

    showHideColumns: ShowHideColumns = new ShowHideColumns();
    showHideColumnsNotInWeek: ShowHideColumns = new ShowHideColumns();
    showActivities = true;
    weeklyPlanType: WeeklyPlanType = WeeklyPlanType.weekly;

    columnDisciplines?: CheckListColumn = null;
    columnActivityNames?: CheckListColumn = null;
    columnActivityIds?: CheckListColumn = null;
    columnComments?: CheckListColumn = null;
    columnPlanFinish?: CalendarColumn = null;
    columnPlanStart?: CalendarColumn = null;
    columnForecastStart?: CalendarColumn = null;
    columnForecastFinish?: CalendarColumn = null;

    columnITRSubsystems?: CheckListColumn = null;
    columnITRSubsystemNames?: CheckListColumn = null;
    columnITRTagNumbers?: CheckListColumn = null;
    columnITRTagTypes?: CheckListColumn = null;
    columnITRDisciplines?: CheckListColumn = null;
    columnITRNumbers?: CheckListColumn = null;
    columnIsItrInCurrentWeek?: CheckListColumn = null;
    columnITRDescriptions?: CheckListColumn = null;
    columnITRContractors?: CheckListColumn = null;
    columnITRDocTypes?: CheckListColumn = null;
    columnITRStatuses?: CheckListColumn = null;
    columnITRBarcodes?: CheckListColumn = null;
    columnStagedStartUpPriority?: CheckListColumn = null;
    columnManHour?: CheckListColumn = null; 
    columnITRComments?: CheckListColumn = null;
    columnITRStatusDate: CalendarColumn = null;
    columnITRPreCommStart: CalendarColumn = null;
    columnITRPreCommFinish: CalendarColumn = null;
    columnITRActivityIds?: CheckListColumn = null;
    columnITRConstraintFlags?: CheckListColumn = null;
    columnITRActivityNames?: CheckListColumn = null;
    columnITRAdditionToPlan?: CheckListColumn = null;
    columnITRWorkAreas?: CheckListColumn = null;
    columnConstraintType?: CheckListColumn = null;
    weekSummaryFilter: WeekSummaryFilter = null;
    itrCommittmentReliabilityScope: ScheduleActivityITRScope = null;
}

export class ShowHideColumns {
    showPlanDates = false;
    showForecastDates = false;
    showMCMilestone = false;
    showRFSUMilestone = false;
    showActivityId = false;
    showConstraintType = false;
}

export class WeekSummaryFilter {
    showCommittedAsPerPlan: boolean = false;
    showCommittedOutOfPlan: boolean = false;
    showNotConstrainedAndNotCommitted: boolean = false;
    showConstrainedAndNotCommitted: boolean = false;
    showNotCommittedAndRequiredForRFSU: boolean = false;
}
