import { ProjectTeamRoleMaintenanceState, ProjectTeamRoleFilter, ProjectTeamRolesDataPagination } from './model';
import { ProjectTeamRoleActions, ProjectTeamRoleMaintenanceActionTypes } from './actions';
import { cloneDeep } from 'lodash';

const initialState: ProjectTeamRoleMaintenanceState = {
    projectTeamRoleDataPagination: new ProjectTeamRolesDataPagination(),
    filter: new ProjectTeamRoleFilter(),
    isLoading: false,
};

export function reducer(state = initialState, action: ProjectTeamRoleActions): ProjectTeamRoleMaintenanceState {
    switch (action.type) {
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleSaveRequest:
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleRemoveRequest:
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                projectTeamRoleDataPagination: action.payload,
            };
        }
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleRemoveError:
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleSaveError:
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleFilterError:
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleSaveSuccess:
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleRemoveSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleFilterPropertyUpdate: {
            let newFilter = cloneDeep(state.filter);
            if (action.payload.key === 'sortBy') {
                newFilter.sortBy = action.payload.value.active;
                newFilter.direction = action.payload.value.direction;
            } else {
                if (Array.isArray(state.filter[action.payload.key])) {
                    newFilter[action.payload.key] = Object.assign([], action.payload.value);
                } else {
                    newFilter[action.payload.key] = action.payload.value;
                }
            }
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        default:
            return state;
    }
}
