<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add constraint type</span></p>
    <div>

        <form [formGroup]="formGroup">
            <mat-form-field class="container300">
                <mat-label>Weekly Planning Activity Constraint Type</mat-label>
                <textarea matInput name="name" formControlName="name"></textarea>
            </mat-form-field>

            <div class="row">
                <button mat-button type="button" class="sct-button sct-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!formGroup.valid"
                    class="sct-button sct-button-light"
                    (click)="onConfirm()"
                >
                    add constraint type
                </button>
            </div>
        </form>
    </div>
</div>
