import * as moment from 'moment';
import {
    SubsystemMCDashboardColumnSummary,
    SubsystemMCDashboardPLIColumnSummary,
} from '../subsystem-mc/model';
import { Contractor, Gooc, OrderDirection, RFO, StagedStartUpPriority, TCOUser } from '../../common.model';

export class SubsystemRFSUDashboardDTO {
    subsystemNo: string;
    subsystemName: string;
    rfsuPlanDate: moment.Moment;
    rfsuForecast: moment.Moment;
    rfsuActualDate: moment.Moment;
    rfsuWalkdownActualDate: moment.Moment;
    aqvd: SubsystemMCDashboardColumnSummary;
    bitr: SubsystemMCDashboardColumnSummary;
    citr: SubsystemMCDashboardColumnSummary;
    redlines: SubsystemMCDashboardColumnSummary;
    pli: SubsystemMCDashboardPLIColumnSummary;
    npw: SubsystemMCDashboardColumnSummary;
}

export class SubsystemRFSUDashboardFilter {
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
    page = 0;
    take = 10;
    timezoneOffset = 0;
    sortBy = 'subsystemNo';
    direction = OrderDirection.Desc;
    contractors: Contractor[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    mcEngineer: TCOUser[] = [];
    scManager: TCOUser[] = [];
    systemOwner: TCOUser[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    scrollSearch = false;
}

export interface SubsystemRFSUDashboardState {
    filter: SubsystemRFSUDashboardFilter;
    items: SubsystemRFSUDashboardDTO[];
    isLoading: boolean;
    totalCount: number;
}
