
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    ITRManhoursActionTypes,
    ITRManhoursUploadLogSuccess,
    ITRManhoursUploadLogError,
    ITRManhoursValidateButtonStateSuccess,
    ITRManhoursValidateButtonStateError,
    ITRManhoursDownloadOutputDataSuccess,
    ITRManhoursDownloadOutputDataError,
    ITRManhoursDownloadOutputDataRequest,
    ITRManhoursTemplateFileSuccess,
    ITRManhoursTemplateFileRequest,
    ITRManhoursTemplateFileError,
    ITRManhoursClearInProgressSpinner,
    ITRManhoursValidateDeleteSuccess,
    ITRManhoursValidateDeleteError,
    ITRManhoursPatchAllDeleteRequest,
    ITRManhoursPatchAllDeleteSuccess,
    ITRManhoursPatchAllDeleteError,
    ITRManhoursPatchDeleteRequest,
    ITRManhoursPatchDeleteSuccess,
    ITRManhoursPatchDeleteError,
    ITRManhoursDeleteSuccess,
    ITRManhoursDeleteError,
    ITRManhoursDeleteRequest,
    ITRManhoursSetStatusAndStartDate,
} from './actions';

@Injectable()
export class ITRManhoursEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(ITRManhoursActionTypes.ITRManhoursUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['ITRManhours']).pipe(
                map((uploadLogData) => new ITRManhoursUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new ITRManhoursUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<ITRManhoursUploadLogSuccess>(ITRManhoursActionTypes.ITRManhoursUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.itrManhoursState.uploadLogStatus),
            this.store.select((store) => store.itrManhoursState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new ITRManhoursSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    invokeDeleteRequest$ = this.actions$.pipe(
        ofType(ITRManhoursActionTypes.ITRManhoursSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.itrManhoursState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        map(() => new ITRManhoursDeleteRequest())
    );

    @Effect()
    getDelete$ = this.actions$.pipe(
        ofType(ITRManhoursActionTypes.ITRManhoursDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.itrManhoursState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getITRManhoursDeleteRecords(deleteFilter).pipe(
                map((deleteData: any) => new ITRManhoursDeleteSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete data records. Please contact Program Administrator'
                    );
                    return of(new ITRManhoursDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteData$ = this.actions$.pipe(
        ofType<ITRManhoursPatchDeleteRequest>(ITRManhoursActionTypes.ITRManhoursPatchDeleteRecordRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchITRManhoursDeleteRecord(
                action.payload.qvdNumber, action.payload.deleteState
                ).pipe(
                map(() => new ITRManhoursPatchDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ITRManhoursPatchDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchAllDelete$ = this.actions$.pipe(
        ofType<ITRManhoursPatchAllDeleteRequest>(ITRManhoursActionTypes.ITRManhoursPatchAllDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.itrManhoursState.deleteFilter)),
        mergeMap(([action, deleteFilter]) =>
            this.manualUploadsService.patchAllITRManhoursDeleteRecords(deleteFilter, action.payload.deleteState).pipe(
                map(() => new ITRManhoursPatchAllDeleteSuccess({ deleteState: action.payload.deleteState })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ITRManhoursPatchAllDeleteError(error));
                })
            )
        )
    );

    @Effect()
    validateDelete$ = this.actions$.pipe(
        ofType(ITRManhoursActionTypes.ITRManhoursValidateDataRequest),
        mergeMap(() =>
            this.manualUploadsService.validateITRManhoursData().pipe(
                map(() => new ITRManhoursValidateDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new ITRManhoursValidateDeleteError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(ITRManhoursActionTypes.ITRManhoursDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.itrManhoursState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new ITRManhoursClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<ITRManhoursTemplateFileRequest>(ITRManhoursActionTypes.ITRManhoursTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new ITRManhoursTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading ITR Manhours structure file. Please contact Program Administrator'
                    );
                    return of(new ITRManhoursTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<ITRManhoursTemplateFileSuccess>(ITRManhoursActionTypes.ITRManhoursTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'ITR Manhours Upload.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<ITRManhoursDownloadOutputDataRequest>(ITRManhoursActionTypes.ITRManhoursDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new ITRManhoursDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new ITRManhoursDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<ITRManhoursDownloadOutputDataSuccess>(ITRManhoursActionTypes.ITRManhoursDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(ITRManhoursActionTypes.ITRManhoursDeleteRecordsSuccess,
            ITRManhoursActionTypes.ITRManhoursPatchAllDeleteRecordsSuccess,
            ITRManhoursActionTypes.ITRManhoursPatchDeleteRecordSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('ITRManhours').pipe(
                map((response: any) => new ITRManhoursValidateButtonStateSuccess(response)),
                catchError((error) => of(new ITRManhoursValidateButtonStateError(error)))
            )
        )
    );
}

