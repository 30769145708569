<mat-tab-group
    [(selectedIndex)]="tabIndex"
    *ngIf="!printMode; else printTemplate"
    #tabGroup
    [animationDuration]="1000"
    class="area-centered"
    (selectedTabChange)="setTabIndex($event)"
>
    <mat-tab [disabled]="isActivityFilterApplied">
        <ng-template mat-tab-label>
            <span>work area filters</span>
        </ng-template>
        <app-lookahead-work-area-filter
            [filterForm]="filterForm"
            [planType]="planType"
            [displayPeriodDays]="displayPeriodDays"
        ></app-lookahead-work-area-filter>
    </mat-tab>
    <mat-tab [disabled]="isWorkAreaFilterApplied">
        <ng-template mat-tab-label>
            <span>activity filters</span>
        </ng-template>
        <app-lookahead-activities-filter
            [filterForm]="filterForm"
            [planType]="planType"
            [displayPeriodDays]="displayPeriodDays"
        ></app-lookahead-activities-filter>
    </mat-tab>
</mat-tab-group>

<ng-template #printTemplate>
    <app-lookahead-work-area-filter
        [filterForm]="filterForm"
        [planType]="planType"
        [displayPeriodDays]="displayPeriodDays"
        [printMode]="true"
    ></app-lookahead-work-area-filter>
    <app-lookahead-activities-filter
        [filterForm]="filterForm"
        [planType]="planType"
        [displayPeriodDays]="displayPeriodDays"
        [printMode]="true"
    ></app-lookahead-activities-filter>
</ng-template>
