import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
// tslint:disable-next-line: max-line-length
import {
    ContractorMaintenanceActionTypes,
    ContractorFilterSuccess,
    ContractorFilterError,
    ContractorSaveSuccess,
    ContractorSaveRequest,
    ContractorSaveError,
    ContractorRemoveSuccess,
    ContractorRemoveError,
    ContractorRemoveRequest,
    ContractorFilterRequest,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { ApplicationState } from '../../../model';
import { ContractorsMaintenanceService } from 'src/app/services/api/webapi-services/contractor-maintenance.service';

@Injectable()
export class ContractorMaintenanceEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private contractorsMaintenanceService: ContractorsMaintenanceService,
        private toastService: ToastService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType(ContractorMaintenanceActionTypes.ContractorFilterRequest),
        withLatestFrom(this.store.select((store) => store.contractorMaintenanceState.filter)),
        mergeMap(([, filter]) => {
            return this.contractorsMaintenanceService.get(filter).pipe(
                map((dataPagination) => {
                    return new ContractorFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Contractor data. Please contact Program Administrator.'
                    );
                    return of(new ContractorFilterError(error));
                })
            );
        })
    );

    @Effect()
    saveContractorRequest$ = this.actions$.pipe(
        ofType<ContractorSaveRequest>(ContractorMaintenanceActionTypes.ContractorSaveRequest),
        mergeMap((action) => {
            return this.contractorsMaintenanceService.update(action.payload).pipe(
                map(() => {
                    return new ContractorSaveSuccess();
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while saving a contractor. Please contact Program Administrator.'
                    );
                    return of(new ContractorSaveError(error));
                })
            );
        })
    );

    @Effect()
    removeContractorRequest$ = this.actions$.pipe(
        ofType<ContractorRemoveRequest>(ContractorMaintenanceActionTypes.ContractorRemoveRequest),
        mergeMap((action) => {
            return this.contractorsMaintenanceService.remove(action.contractNo).pipe(
                switchMap(() => [new ContractorRemoveSuccess(), new ContractorFilterRequest()]),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while removing a contractor. Please contact Program Administrator.'
                    );
                    return of(new ContractorRemoveError(error));
                })
            );
        })
    );
}
