import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupService } from '../../../../services/shared/popup.service';
import { PopupSettings } from '../../../../models/popup-settings';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { BaseComponent } from '../../../base.component';
import { MatTableDataSource } from '@angular/material/table';
import { AddScheduleActivityConstraintComponent } from './add-schedule-activity-constraint-component/add-schedule-activity-constraint.component';
import { ScheduleActivityLookupService } from '../../../../services/api/webapi-services/schedule-activity-lookup.service';
import { ScheduleActivityPlanningService } from '../../../../services/api/webapi-services/schedule-activity-planning.service';
import { ToastService } from '../../../../services/shared/toast.service';
import { MatRadioChange } from '@angular/material/radio';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

export class ScheduleActivityPlanningConstraint {
    id: number;
    name: string;
    prevName: string;
    isInEditMode = false;
}

export enum ConstraintSelectionMode {
    Mandatory,
    Optional,
}

@Component({
    selector: 'app-constraint-configuration',
    templateUrl: './constraint-configuration.component.html',
    styleUrls: ['./constraint-configuration.component.scss'],
})
export class ConstraintConfigurationComponent extends BaseComponent implements OnInit {
    constraints: MatTableDataSource<ScheduleActivityPlanningConstraint> = new MatTableDataSource<
        ScheduleActivityPlanningConstraint
    >();

    displayedColumns = ['key', 'edit', 'delete'];
    pageSize = 5;
    resultsLength = 0;
    isConstraintMandatory = false;
    isLoading = false;
    constraintSelectionModes = ConstraintSelectionMode;
    selectedConstraintSelectionMode: ConstraintSelectionMode = null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private lookupService: ScheduleActivityLookupService,
        private toastService: ToastService,
        private scheduleActivityService: ScheduleActivityPlanningService,
        private popupService: PopupService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private configurationService: ConfigurationService
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.isLoading = true;
        forkJoin([this.lookupService.searchConstraintTypes(), this.configurationService.getConstraintSelectionMode()])
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe(([constraints, mode]) => {
                this.constraints.data = constraints.items;
                this.resultsLength = constraints.totalCount;
                this.selectedConstraintSelectionMode = mode;
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(
            new PopupSettings(AddScheduleActivityConstraintComponent, 450, 430, {})
        );

        dialogRef
            .afterClosed()
            .pipe(
                takeWhile(() => this.isAlive === true),
                finalize(() => {
                    if (this.constraints.data.length > this.paginator.pageSize * this.paginator.pageIndex) {
                        this.paginator.lastPage();
                    }
                })
            )
            .subscribe((result) => {
                if (result && result.success) {
                    this.searchConstraintTypes(this.paginator.pageSize, this.paginator.pageIndex);
                }
            });
    }

    save(constraint: ScheduleActivityPlanningConstraint) {
        constraint.isInEditMode = !constraint.isInEditMode;

        if (constraint.name !== constraint.prevName) {
            this.isLoading = true;
            this.scheduleActivityService
                .updateConstraintType(constraint)
                .pipe(
                    take(1),
                    finalize(() => (this.isLoading = false))
                )
                .subscribe();
        }
    }

    delete(element) {
        this.isLoading = true;
        this.scheduleActivityService
            .deleteConstraintType(element)
            .pipe(
                takeWhile(() => this.isAlive),
                finalize(() => {
                    this.isLoading = false;
                    if (this.constraints.data.length <= this.paginator.pageSize) {
                        this.paginator.previousPage();
                    }
                })
            )
            .subscribe(
                (result) => {
                    this.toastService.Success('Deleted the Constraint Type successfully.');
                    this.searchConstraintTypes(this.paginator.pageSize);
                },
                (error) => {
                    this.toastService.Error('An error deleting the Constraint Type occurred.');
                }
            );
    }

    constraintSelectionChanged($event: MatRadioChange) {
        this.isLoading = true;
        this.configurationService
            .updateConstraintSelectionMode(this.constraintSelectionModes[$event.value])
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe(
                () => {
                    this.toastService.Success('Constraint Selection Mode updated successfully.');
                },
                () => {
                    this.toastService.Error('An error updating the Constraint Selection Mode.');
                }
            );
    }

    enterEditMode(constraint: ScheduleActivityPlanningConstraint) {
        constraint.isInEditMode = !constraint.isInEditMode;
        constraint.prevName = constraint.name;
    }

    cancelEdit(constraint: ScheduleActivityPlanningConstraint) {
        constraint.isInEditMode = !constraint.isInEditMode;
        constraint.name = constraint.prevName;
    }

    onPaginatorChange(pageEvent: PageEvent) {
        this.searchConstraintTypes(pageEvent.pageSize, pageEvent.pageIndex);
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    private searchConstraintTypes(takeCount = 5, page = 0, search = '') {
        this.isLoading = true;
        this.lookupService
            .searchConstraintTypes(takeCount, page, search)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((constraints) => {
                this.constraints.data = constraints.items;
                this.resultsLength = constraints.totalCount;
            });
    }
}
