<main class="main">
    <form [formGroup]="filterForm">
        <div class="title-container">
            <div class="title">
                Certificate status
            </div>
        </div>
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                                RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areaCompletionLead.value.length" removable>
                                Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('areaCompletionLead')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                Area Breakdown:
                                {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                                RFSU Contractor:
                                {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                System Owner: {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.stagedStartUpPriorities?.value.length" removable>
                                Priorities:
                                {{ displaySelectedPriorities(filterForm.controls.stagedStartUpPriorities.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('stagedStartUpPriorities')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Project Team</mat-label>
                        <mat-select
                            formControlName="projectTeamNames"
                            multiple
                            (openedChange)="onProjectTeamsClosed($event)"
                        >
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFSUContractorsInput"
                            formControlName="rfsuContractors"
                            [searchFunc]="getRFSUContractors"
                            [isAsync]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            [filterForm]="filterForm"
                            [formSetter]="'rfsuContractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'RFSU Contractor'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Discipline</mat-label>
                        <mat-select formControlName="disciplines" multiple>
                            <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManager" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mixedapp-container">

                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Area Completion Lead</mat-label>
                        <mat-select formControlName="areaCompletionLead" multiple>
                            <mat-option *ngFor="let mce of areaCompletionLeads" [value]="mce.id">{{ mce.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <mat-form-field class="container300">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwner" multiple>
                            <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mixedapp-container">
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setStagedStartUpPriorityInput"
                            formControlName="stagedStartUpPriorities"
                            [isAsync]="true"
                            [searchFunc]="getStagedStartUpPriorities"
                            [filterForm]="filterForm"
                            [formSetter]="'stagedStartUpPriorities'"
                            [propertyToShow]="'priorityName'"
                            [placeholder]="'Staged Start-up Priority'"
                            [secondPropertyToShow]="'priority'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['priority', 'priorityName']"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
                export to excel
            </button>
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
        <div class="results-container">
            <table mat-table [dataSource]="(data$ | async)?.items">
                <ng-container matColumnDef="subsystem">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnSubsystem)
                                }"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'subsystem',
                                        getColumnSubsystems,
                                        'columnSubsystem',
                                        'Subsystem',
                                        columnSubsystem,
                                        true
                                    )
                                "
                                >Subsystem</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.subsystem }}</td>
                </ng-container>
                <ng-container matColumnDef="discipline">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div class="sort-container center">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnDiscipline)
                                }"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'discipline',
                                        getColumnDisciplines,
                                        'columnDiscipline',
                                        'Discipline',
                                        columnDiscipline,
                                        true
                                    )
                                "
                                >Discipline</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.discipline }}</td>
                </ng-container>
                <ng-container matColumnDef="certificateType">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div class="sort-container center">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnCertificateType)
                                }"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'certificateType',
                                        getColumnCertificateTypes,
                                        'columnCertificateType',
                                        'Certificate Type',
                                        columnCertificateType,
                                        true
                                    )
                                "
                                >Certificate Type</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'certificateType'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.certificateType }}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div class="sort-container center">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnStatus)
                                }"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'status',
                                        getColumnStatuses,
                                        'columnStatus',
                                        'Status',
                                        columnStatus,
                                        true
                                    )
                                "
                                >Status</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'status'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                </ng-container>
                <ng-container matColumnDef="statusDate">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCalendarColumn(columnStatusDate)
                                }"
                                (click)="
                                    openHeaderDateFilter(
                                        'statusDate',
                                        'columnStatusDate',
                                        'Status Date',
                                        columnStatusDate
                                    )
                                "
                                >Status Date</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'statusDate'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.statusDate | date: 'd/MMM/yy' }}</td>
                </ng-container>
                <ng-container matColumnDef="certificateNumber">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnCertificateNumber)
                                }"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'certificateNumber',
                                        getColumnCertificateNumbers,
                                        'columnCertificateNumber',
                                        'Certificate Number',
                                        columnCertificateNumber
                                    )
                                "
                                >Certificate Number</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'certificateNumber'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.certificateNumber }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="(data$ | async)?.totalCount || 0"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 50]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
    </form>
    <app-loading-indicator *ngIf="isLoading$ | async"></app-loading-indicator>
</main>
