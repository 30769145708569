import { Component, OnInit} from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile } from 'rxjs/operators';
import {
    NameSeriesDataDTO,
    ChangeValidationDashboardProgressTotalByStatusDTO,
    ChangeValidationDashboardProgressTotalByProjectTeamDTO,
} from 'src/app/store/reports/change-validation-dashboard/model';
import { ChangeValidationDashboardValidationProgressRequest } from 'src/app/store/reports/change-validation-dashboard/actions';
import * as _ from 'lodash';

@Component({
    selector: 'app-validation-progress',
    templateUrl: './validation-progress.component.html',
    styleUrls: ['./validation-progress.component.scss'],
})
export class ValidationProgressComponent extends BaseComponent implements OnInit {
    validationProgressData$ = this.store.select((state) => state.changeValidationDashboardState.validationProgressData);
    isLoading$ = this.store.select((state) => state.changeValidationDashboardState.isValidationProgressLoading);
    totalChangeDocuments$ = this.store.select(
        (state) => state.changeValidationDashboardState.validationProgressData.totalChangeDocuments
    );
    completed$ = this.store.select((state) =>
        state.changeValidationDashboardState.validationProgressData.totalChangeDocuments > 0
            ? state.changeValidationDashboardState.validationProgressData.completedChangeDocuments /
              state.changeValidationDashboardState.validationProgressData.totalChangeDocuments
            : 0
    );
    completed: number;
    totalsByProjectTeam: ChangeValidationDashboardProgressTotalByProjectTeamDTO[];
    totalsByStatus: ChangeValidationDashboardProgressTotalByStatusDTO[];
    total: number;

    colorScheme = {
        domain: ['#5AA454', '#269B9B', '#FF963E', '#8C8F93', '#A9A9A9'],
    };

    validationProgressData: NameSeriesDataDTO[] = [];

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {
        this.validationProgressData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data.totalChangeDocuments === null) {
                this.store.dispatch(new ChangeValidationDashboardValidationProgressRequest());
            } else {
                this.validationProgressData = _.sortBy(data.progressChart, ['name']).reverse();
                this.totalsByProjectTeam = data.totalsByProjectTeam;
                this.totalsByStatus = data.totalsByStatus;
                this.total = data.totalChangeDocuments;
            }
        });

        this.completed$.pipe(takeWhile(() => this.isAlive)).subscribe((completed) => (this.completed = completed));
    }

    formatPercentage(val) {
        return val + '%';
    }

    getTooltipValue(value: number, projectTeam: string) {
        if (this.totalsByProjectTeam != null) {
            return Math.round(
                (value / 100) * this.totalsByProjectTeam.filter((x) => x.projectTeam === projectTeam)[0].total
            );
        }
        return 0;
    }

    getLegendValue(status: string) {
        if (this.totalsByStatus != null) {
            let value = ((this.totalsByStatus.filter((x) => x.status === status)[0]?.total / this.total) * 100).toFixed(
                2
            );
            if (value === 'NaN') {
                return 0;
            }
            return value;
        }
        return 0;
    }
}
