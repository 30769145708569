import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { Injectable } from '@angular/core';
import { WorkAreaDTO } from 'src/app/store/common.model';

@Injectable({
    providedIn: 'root',
})
export class WorkAreaService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly workAreaEndpoint: string = '/workarea';
    constructor(private http: HttpClient) {}

    addWorkArea(workArea: WorkAreaDTO): Observable<any> {
        return this.http.post(`${this.rootUrl}${this.workAreaEndpoint}`, workArea);
    }

    updateWorkArea(workArea: WorkAreaDTO): Observable<any> {
        return this.http.put(`${this.rootUrl}${this.workAreaEndpoint}`, workArea);
    }

    getWorkAreaByName(workAreaName: string) {
        const areaName = encodeURIComponent(workAreaName);
        return this.http.get<WorkAreaDTO>(`${this.rootUrl}${this.workAreaEndpoint}?name=${areaName}`);
    }

    deleteWorkAreaByName(workAreaName: string) {
        const areaName = encodeURIComponent(workAreaName);
        return this.http.delete<boolean>(`${this.rootUrl}${this.workAreaEndpoint}?name=${areaName}`);
    }

    generateexcel() {
        return this.http.post(`${this.rootUrl}${this.workAreaEndpoint}/generateexcel`, null, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }
}
