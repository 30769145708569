import { Action } from '@ngrx/store';
import { SystemisedDrawingDataPagination, SystemisedDrawingFilter } from './model';

export enum SystemisedDrawingActionTypes {
    SystemisedDrawingFilterRequest = '[Systemised Drawing] Filter Request',
    SystemisedDrawingFilterSuccess = '[Systemised Drawing] Filter Success',
    SystemisedDrawingFilterError = '[Systemised Drawing] Filter Error',
    SystemisedDrawingFilterPropertyUpdate = '[Systemised Drawing] Property Update',
    SystemisedDrawingFilterReset = '[Systemised Drawing] Filter Reset',
    SystemisedDrawingExportToExcelRequest = '[Systemised Drawing] Export to Excel Request',
    SystemisedDrawingExportToExcelSuccess = '[Systemised Drawing] Export to Excel Success',
    SystemisedDrawingExportToExcelError = '[Systemised Drawing] Export to Excel Error',
}

export class SystemisedDrawingFilterRequest implements Action {
    readonly type = SystemisedDrawingActionTypes.SystemisedDrawingFilterRequest;

    constructor(public payload: SystemisedDrawingFilter = null) {}
}

export class SystemisedDrawingFilterSuccess implements Action {
    readonly type = SystemisedDrawingActionTypes.SystemisedDrawingFilterSuccess;

    constructor(public payload: SystemisedDrawingDataPagination) {}
}

export class SystemisedDrawingFilterError implements Action {
    readonly type = SystemisedDrawingActionTypes.SystemisedDrawingFilterError;

    constructor(public payload: string) {}
}

export class SystemisedDrawingFilterPropertyUpdate implements Action {
    readonly type = SystemisedDrawingActionTypes.SystemisedDrawingFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class SystemisedDrawingFilterReset implements Action {
    readonly type = SystemisedDrawingActionTypes.SystemisedDrawingFilterReset;
}

export class SystemisedDrawingExportToExcelRequest implements Action {
    readonly type = SystemisedDrawingActionTypes.SystemisedDrawingExportToExcelRequest;
}

export class SystemisedDrawingExportToExcelSuccess implements Action {
    readonly type = SystemisedDrawingActionTypes.SystemisedDrawingExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class SystemisedDrawingExportToExcelError implements Action {
    readonly type = SystemisedDrawingActionTypes.SystemisedDrawingExportToExcelError;

    constructor(public payload: string) {}
}

export type SystemisedDrawingActions =
    | SystemisedDrawingFilterRequest
    | SystemisedDrawingFilterSuccess
    | SystemisedDrawingFilterError
    | SystemisedDrawingFilterPropertyUpdate
    | SystemisedDrawingFilterReset
    | SystemisedDrawingExportToExcelRequest
    | SystemisedDrawingExportToExcelSuccess
    | SystemisedDrawingExportToExcelError;
