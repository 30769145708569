import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile } from 'rxjs/operators';
import { MilestoneService } from 'src/app/services/api/webapi-services/milestone.service';
import { MilestoneDTO } from 'src/app/models/milestone-dto';
import { WaypointDTO } from 'src/app/models/waypoint-dto';

@Component({
    selector: 'app-add-milestone-popup',
    templateUrl: './add-milestone-popup.component.html',
    styleUrls: ['./add-milestone-popup.component.scss'],
})
export class AddMilestonePopupComponent extends BaseComponent {
    loading: boolean;
    addMilestoneForm: UntypedFormGroup;
    waypoints: WaypointDTO[] = [];

    constructor(
        public dialogRef: MatDialogRef<AddMilestonePopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: ToastService,
        private milestoneService: MilestoneService
    ) {
        super();
        this.waypoints = data.waypoints;

        this.addMilestoneForm = new UntypedFormGroup({
            name: new UntypedFormControl('', Validators.required),
            description: new UntypedFormControl(''),
            waypointName: new UntypedFormControl(null),
        });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        const form = new MilestoneDTO();

        form.name = this.addMilestoneForm.controls.name.value;
        form.description = this.addMilestoneForm.controls.description.value;
        form.waypointName = this.addMilestoneForm.controls.waypointName.value;

        this.milestoneService
            .add(form)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
                (data) => {
                    this.loading = false;
                    this.toastService.Success(`The milestone ${form.name} has been added.`);
                    this.dialogRef.close({ success: true, data: data });
                },
                (error) => {
                    this.loading = false;
                    if (error.status === 409) {
                        this.toastService.Error('Milestone entered already exists in SCMT.');
                    } else {
                        this.toastService.Error(
                            'An error occurred while adding a milestone. Please contact a Program Administrator.'
                        );
                    }
                }
            );
    }
}
