import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { take, takeWhile } from 'rxjs/operators';
import { ManualUploadSetActivatedRoute } from 'src/app/store/manual-upload/actions';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Subscription } from 'rxjs';
import {
    CWPRegisterUploadLogRequest,
    CWPRegisterPatchDeltaRequest,
    CWPRegisterPatchDeleteRecordRequest,
    CWPRegisterTemplateFileRequest,
    CWPRegisterDeltaFilterPropertyUpdate,
    CWPRegisterDeltaRequest,
    CWPRegisterDeleteFilterPropertyUpdate,
    CWPRegisterDeleteDataRequest,
    CWPRegisterDownloadOutputDataRequest,
    CWPRegisterPatchAllDeltaRequest,
    CWPRegisterPatchDeleteAllRecordsRequest,
} from 'src/app/store/manual-upload/cwp-register/actions';
import { CWPRegisterDeltaDTO, CWPRegisterDeleteDTO } from 'src/app/store/manual-upload/cwp-register/model';

@Component({
    selector: 'app-cwp-register',
    templateUrl: './cwp-register.component.html',
    styleUrls: ['./cwp-register.component.scss'],
})
export class CWPRegisterComponent extends BaseComponent {
    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    deltaData: MatTableDataSource<CWPRegisterDeltaDTO>;
    deltaDataResultsLength = 0;
    deltaDataPageSize = 10;
    displayedDeltaDataColumns = [
        'cwp',
        'description',
        'projectTeamNameLive',
        'arrow',
        'projectTeamNameImport',
        'actions',
    ];
    deleteData: MatTableDataSource<CWPRegisterDeleteDTO>;
    deleteDataResultsLength = 0;
    deleteDataPageSize = 10;
    displayedDeleteDataColumns = ['projectTeamName', 'cwp', 'description', 'actions'];

    uploadLogData$ = this.store.select((store) => store.cwpRegisterState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.cwpRegisterState.isUploadLogLoading);
    deltaData$ = this.store.select((store) => store.cwpRegisterState.deltaData);
    isDeltaDataLoading$ = this.store.select((store) => store.cwpRegisterState.isDeltaDataLoading);
    deleteData$ = this.store.select((store) => store.cwpRegisterState.deleteData);
    isDeleteDataLoading$ = this.store.select((store) => store.cwpRegisterState.isDeleteDataLoading);
    isLoading$ = this.store.select((store) => store.cwpRegisterState.isLoading);
    interval$ = this.store.select((store) => store.manualUploadState.interval);
    intervalSub: Subscription = null;
    showOrder = true;

    constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new ManualUploadSetActivatedRoute(urlSegments[0].path));
        });

        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new CWPRegisterUploadLogRequest());
            });
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

        this.deltaData$.pipe(takeWhile(() => this.isAlive)).subscribe((deltaData) => {
            this.deltaData = new MatTableDataSource(deltaData.items);
            this.deltaDataResultsLength = deltaData.totalCount;
        });

        this.deleteData$.pipe(takeWhile(() => this.isAlive)).subscribe((deltaData) => {
            this.deleteData = new MatTableDataSource(deltaData.items);
            this.deleteDataResultsLength = deltaData.totalCount;
        });
    }

    rejectChange(element: CWPRegisterDeltaDTO) {
        element.deltaState = true;

        this.store.dispatch(
            new CWPRegisterPatchDeltaRequest({
                cwp: element.cwp,
                deltaState: element.deltaState,
            })
        );
    }

    acceptChange(element: CWPRegisterDeltaDTO) {
        element.deltaState = false;

        this.store.dispatch(
            new CWPRegisterPatchDeltaRequest({
                cwp: element.cwp,
                deltaState: element.deltaState,
            })
        );
    }

    patchAllChanges(deltaState: boolean) {
        this.store.dispatch(
            new CWPRegisterPatchAllDeltaRequest({
                deltaState: deltaState,
            })
        );
    }

    patchAllDeleteRecords(deleteState: boolean) {
        this.store.dispatch(
            new CWPRegisterPatchDeleteAllRecordsRequest({
                deleteState: deleteState,
            })
        );
    }

    deleteCWP(element: CWPRegisterDeleteDTO) {
        element.deleteState = true;

        this.store.dispatch(
            new CWPRegisterPatchDeleteRecordRequest({
                cwp: element.cwp,
                deleteState: element.deleteState,
            })
        );
    }

    restoreCWP(element: CWPRegisterDeleteDTO) {
        element.deleteState = false;

        this.store.dispatch(
            new CWPRegisterPatchDeleteRecordRequest({
                cwp: element.cwp,
                deleteState: element.deleteState,
            })
        );
    }

    downloadTemplate(fileName: string) {
        this.store.dispatch(new CWPRegisterTemplateFileRequest(fileName));
    }

    onDeltaDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deltaDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new CWPRegisterDeltaFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deltaDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new CWPRegisterDeltaFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new CWPRegisterDeltaRequest());
    }

    onDeleteDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deleteDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new CWPRegisterDeleteFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deleteDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new CWPRegisterDeleteFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new CWPRegisterDeleteDataRequest());
    }

    downloadData(filePath: string) {
        this.store.dispatch(new CWPRegisterDownloadOutputDataRequest(filePath));
    }
}
