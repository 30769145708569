import { Component, OnInit, EventEmitter, ViewChild, ChangeDetectorRef, NgZone, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { BaseComponent } from '../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/shared/toast.service';
import { CommentDetailDTO, DetailedStatusDTO, DetailedStatusFilterBase, ShowHideColumns } from 'src/app/store/detailed-status/model';
import { DetailedStatusDTO as DetailedStatusServiceDTO } from 'src/app/store/details/model';
import { FormService } from 'src/app/services/shared/form.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { Store } from '@ngrx/store';
import { HeaderNumericFilterComponent } from 'src/app/modules/shared/components/filter/header-numeric-filter/header-numeric-filter.component';
import {
    DetailedStatusFilterPropertyUpdate,
    DetailedStatusFilterRequest,
    DetailedStatusFilterReset,
    DetailedStatusFilterMultiplePropertyUpdate,
    DetailedStatusAddCommentRequest,
    DetailedStatusSetLockedFilter,
    DetailedStatusResetLockedFilter,
    DetailedStatusAddBulkCommentRequest,
    DetailedStatusAddITRConstraintRequest,
    DetailedStatusUpdateITRConstraintRequest,
    DetailedStatusAddEXForecastClosureDateRequest,
    DetailedStatusAddExPriorityRequest,
    DetailedStatusRemoveCommentRequest,
    DetailedStatusRemoveExceptionCommentRequest,
    DetailedStatusAddO3ConstraintRequest,
    DetailedStatusAddBulkExceptionCommentRequest,
    DetailedStatusUpdatePliImplementationTeamRequest,
    DetailedStatusAddPLIForecastClosureDateRequest,
    DetailedStatusAddPostRFOWorkExpectedClosureDateRequest,
    DetailedStatusPostRFOWorkUpdateManualPriorityRequest,
    DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest,
    DetailedStatusAddExpectedClosureDateRequest,
} from 'src/app/store/detailed-status/actions';
import {
    GetDetailsRequest,
    GetConstraintDetailsSuccess,
    GetConstraintCommentDetailsRequest,
} from 'src/app/store/details/actions';
import { ITRConstraintDTO } from 'src/app/models/itr-constraint';
import { takeWhile, map, take, catchError, switchMap, withLatestFrom, finalize } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { Constants } from 'src/app/constants';
import {
    Contractor,
    TCOACManager,
    TCOUser,
    Milestone,
    Waypoint,
    OrderDirection,
    CheckListColumn,
    CalendarColumn,
    NumericColumn,
    UserDetail,
    McMilestone,
    Gooc,
    RFO,
    System,
    StagedStartUpPriority
} from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from '../../modules/shared/components/comment-popup/comment-popup.component';
import { SubsystemScope, Zone, ExceptionScope, ExceptionType, UserDefinedInputs } from 'src/app/enums';
import { getDetailedStatusFilterInstance, subsystemScopeEnumToString } from 'src/app/extensions';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { RoleService } from 'src/app/services/shared/role.service';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { HeaderCheckListFilter, HeaderDateFilter, HeaderNumericFilter } from 'src/app/models/filter-settings';
import { Location } from '@angular/common';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Dictionary } from 'typescript-collections';
import { AttachmentDTO } from 'src/app/store/details/model';
import { DetailedStatusService } from 'src/app/services/api/webapi-services/detailed-status.service';
import { DetailedStatusLockedFilter } from 'src/app/models/detailed-status-locked-filter';
import { DetailsConstraintFlagUpdateRequest } from 'src/app/store/details/actions';
import { detailedStatusExportToExcelRequest } from 'src/app/store/excel-export/actions';
import { ITRConstraintTypeSelectorPopupComponent } from 'src/app/components/detailed-status/constraint-type-selector/constraint-type-selector.component';
import * as moment from 'moment';
import { TagKeyDTO } from 'src/app/store/tag-search/model';
import { ClearableMatDatepickerComponent } from '../clearable-mat-datepicker/clearable-mat-datepicker.component';
import { ForecastHistoryComponent } from '../forecast-history/forecast-history.component';
import { ResponsbilePersonSelectorComponent } from './responsbile-person-selector/responsbile-person-selector.component';
import { MatSelectChange } from '@angular/material/select';
import { CommentsHistoryComponent } from '../comments-history/comments-history.component';
import { PliImplementationTeam } from 'src/app/models/pli-implementation-team';
import { CommentsHistoryWithoutDeleteComponent } from '../comments-history-without-deletebutton/comments-history-without-delete.component';
import { DataUpdateHistoryComponent } from '../data-update-history/data-update-history.component';
import { DetailedStatusEtcHistoryComponent } from './etc-history/etc-history.component';


@Component({
    selector: 'app-detailed-status',
    templateUrl: './detailed-status.component.html',
    styleUrls: ['./detailed-status.component.scss'],
})
export class DetailedStatusComponent extends BaseComponent implements OnInit, OnChanges {
    contractorLabel = '';
    deleteme = '';
    itrid = 0;
    id = null;
    selectedITR: DetailedStatusDTO;
    listofSelectedBarcodes: TagKeyDTO[] = [];
    item: DetailedStatusDTO;
    filterForm: UntypedFormGroup;
    isLoading = false;
    isUsersPerProjectLoading = false;
    rfsuCompleted = null;
    rfoType = null;
    resultsLength = 0;
    hideMCcompleted = [SubsystemScope.AQVD , SubsystemScope.BITRandCITR, SubsystemScope.BITR, SubsystemScope.CITR
    ,  SubsystemScope.Redline, SubsystemScope.EX, SubsystemScope.RFSUWorkToGoSearch, SubsystemScope.RFOWorkToGoSearch];
    hideMCmileStone = [SubsystemScope.AQVD , SubsystemScope.PLI, SubsystemScope.COW, SubsystemScope.EX];
    data: DetailedStatusDTO[] = [];
    item$ = this.store.select((state) => state.detailsState.item);
    detailedStatusGridData$ = this.store.select((state) => state.detailedStatusState.dataPagination);
    detailedStatusGridLoader$ = this.store.select((state) => state.detailedStatusState.isLoading);
    detailedStatusFilter$ = this.store.select((state) =>
        state.detailedStatusState.lockedFilter
            ? state.detailedStatusState.lockedFilter
            : state.detailedStatusState[this.subsystemScopes[this.scope]]
    );
    makeRequest$ = this.store.select((state) => state.detailedStatusState.makeRequest);
    showColumns = new ShowHideColumns();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setCWPInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMcMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setConstructionAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPriorityInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    prevWaypoints: Waypoint[] = []; //dzhen-correct
    sortBy = '';
    pageSize = 25;
    punchItemConstraintType= Constants.ConstraintPunchItemType;
    direction: OrderDirection = OrderDirection.Desc;
    disciplines: any[] = [];
    projectTeamNames: string[] = [];
    units: string[] = [];
    statuses: string[] = ['Required', 'Received'];
    exceptionPriorities : string[] = ['None','P1'];
    areas: string[] = [];
    revTypes: string[] = [];
    rfos: RFO[] = [];
    systems: System[] = [];
    excludeValue = '';
    docGroups: string[] = [];
    pliImplementationTeams: PliImplementationTeam[] = [];
    redReqTypes: string[] = [];
    redTypes: string[] = [];
    mcdrConstrRedlineCats: string[] = [];
    mocs: string[] = [];
    projectTeamNamesCurrent: string[];
    contractors: Contractor[] = [];
    acms: TCOACManager[] = [];
    scManagers: TCOUser[] = [];
    areaCompletionLeads: TCOUser[] = [];
    sysOwners: TCOUser[] = [];
    overDue: String[] = ["Yes"];
    mcEngineers: TCOUser[] = [];
    constraintFlags: string[] = [];
    deliveryEngineers: TCOUser[] = [];
    Manualpriorities:string[] = ['P1','P2','P3','P1.1','P1.2','P1.3'];
    readonly isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);
    defaultColumns: string[] = [
        'subsystem',
        'subsystemName',
        'tagNumber',
        'tagName',
        'tagType',
        'discipline',
        'priority',
        'number',
        'description',
        'contractor',
        'status',
        'latestComment',
    ];
    itrColumns = [
        'select',
        'addConstraint',
        'subsystem',
        'subsystemName',
        'tagNumber',
        'tagName',
        'tagType',
        'rfsuPlan',
        'rfsuForecast',
        'discipline',
        'number',
        'itrConstraintResponsibleGroup',
        'description',
        'preCommStart',
        'preCommFinish',
        'contractor',
        'status',
        'areaBreakdown',
        'statusDate',
        'createdDate',
        'activityID',
        'barcode',
        'manhours',
        'docType',
        'docType2',
        'priority',
        'waypointDescription',         
        'rfoplan',
        'mcActual',
        'rfsuActual',
        'milestoneDescription',
        'eta',
        //'milestoneTargetDate',       
        'activityName',
        'associatedNPW',
        'associatedPLI',
        'associatedQVD',
        'associatedComment',
        'aqvdContractor',
        'pliActionedBy',
        // 'criticalPath',
        'priorityNo',
        'constraintFlag',
        'itrConstraintCount',
        'itrConstraintId',
        'itrConstraintStatus',
        // 'itrConstraintStatusDate',
        'itrConstraintTypeName',
        'itrConstraintDescription',
        'itrConstraintDueDate',
        //'itrConstraintResponsibleUser',
        //'itrConstraintAdditionalResponsibleUser',
        'itrConstraintRaisedByUser',
        'itrConstraintRaisedByTeam',
        'itrConstraintRaisedDate',
        'rfsuWalkdownForecast',
        'exceptionNumber',
        'fmRorPO',
        'fmRorPOETADate',
        'materialsTotalCount',
        // 'waypointRequired',
        'rfo',
        'latestNBResponsiblePersonComment',
        'workAreaName',
        'workOrderNum',
        'eTransferNumber',
        //'attachments',
        'latestITRComment',
        'latestComment',        
    ];
    pliColumns: string[] = [
        'subsystem',
        'subsystemName',
        'rfsuPlan',
        'rfsuForecast',
        'number',
        'description',
        'category',
        'tagNumber',
        'tagName',
        'tagType',
        'priority',
        'waypointDescription', 
        'areaBreakdown', 
        'milestoneDescription',
        'eta',
        'requiredByDate',
        //'milestoneTargetDate', 
        'materialsTotalCount',
        'materialReviewStatus',
        'materialReviewAssignedTo',
        'status', 
        'rfoplan',
        'actionBy',      
        'pliImplementationTeam',
        'latestNBResponsiblePersonComment',
        'punchType',
        'createdDate',
        'exceptionNumber',
        'raisedBy',
        'rfo',
        'discipline',
        'tagDiscipline',
        'materialReference',
        'pliForecastClosureDate',
        'classification',
        'reviewStatusComplete',
        'acNumber',
        'workOrderNum',
        'eTransferNumber',        
        'attachments',
        'latestComment',
    ];
    cowColumns: string[] = [
        'subsystem',
        'subsystemName',
        'number',
        'description',
        'category',
        'tagNumber',
        'actionBy',
        'raisedBy',
        'discipline',
        'cowDocNumber',
        'attachments',
        'latestComment',
    ];
    exColumns: string[] = [
        'subsystem',
        'number',
        'forecastClosureDate',
        'lastConstraintDate',
        'description',
        'tagNumber',
        'tagName',
        'tagType',
        'areaBreakdown',
        'discipline',
        'waypointDescription', 
        'milestoneDescription',
        //'milestoneTargetDate',
        'createdDate',   
        'exceptionNumber',
        'exceptionPhase',
        'exceptionStatus',
        'targetCompletionDate',
        'rfsuPlan', 
        'rfo',    
        'fmRorPO',  
        'associatedNPW',
        'barcode',
        'manhours', 
        'category',     
        'rfoplan',
        'activityID',
        'preCommStart',
        'preCommFinish',
        'exItrConstraintCount',
        //'o3ConstraintID',
        'exActionBy', 
        'contractor',
        'exceptionResponsiblePerson',
        'exceptionAdditionalResponsibleUser',
        'exceptionPriority',
        'latestExceptionComment',
        'latestComment',        
    ];

    rfsuWorkToGoSearch: string[] = [
        'subsystem',
        'subsystemName',
        'docType',
        'tagNumber',
        'tagName',
        'tagType',
        'discipline',
        'number',        
        'description',
        'actionBy',     
        'barcode',
        'deliveryEng',
        'rfsuPlan',
       // 'rfsuForecast', 
       // 'rfsuWalkdownForecast',
        'priority',
        'manhours',
        'mcActual',
        'rfsuActual',
        //'itrConstraintCount',
        //'preCommStart',
        //'preCommFinish',
        'contractor',
        'rfo',    
        'areaBreakdown',
        'exceptionNumber',    
        'waypointDescription', 
        'milestoneDescription',  
        //'milestoneTargetDate',
        'activityID',        
      //  'latestComment',
    ];

    rfoWorkToGoSearch: string[] = [
        'rfo',
        'rfoActual',
        'subsystem',
        'areaBreakdown',
        'contractor',
        'qvdNumber',
        'tagNumberPostRFO',
        'barcode',
        'pli',
        'pliCreatedDate',
        'exceptionNumber',
        'exceptionPriority',
        'exceptionPhase',
        'npw',
        'npwIssueDate',
        'pascr',
        'redline',
        'priorityManualOverride',
        'responsiblePersonOrGroup',
        'postRFOWorkExpectedClosureDate',
        'itemComment',
        'latestComment',
    ];
    redlineColumns: string[] = [
        'areaBreakdown',
        'rfo',
        'redType',
        'redReqType',
        'subsystem',
        'originalSubSystem',
        'number',
        'description',
        'revision',
        'contractor',
        'discipline',
        'docType',
        'redStatus',
        'redlineExceptionNumber',
        'mcdrConstrRedCat',
        'changeId',
        'containsChange',
        'expectedTimeOfCompletion',
        'latestComment',
    ];
    numericColumns = [
        'columnITRConstraintCount',
        'columnManhours',
        'columnMaterialsTotalCount'
    ];
    displayedColumns = this.defaultColumns;
    scope: SubsystemScope;
    exceptionScopes = ExceptionScope;
    userDefinedinputs = UserDefinedInputs;
    subsystemScopes = SubsystemScope;
    exceptionTypes = ExceptionType;
    RFexceptionTypes = [
        { key: ExceptionType.RFSU, value: ExceptionType[ExceptionType.RFSU] },
        { key: ExceptionType.RFO, value: ExceptionType[ExceptionType.RFO] },
    ];
    subsystem: string;
    subsystemName: string;
    rfo: string;
    waypoint: string;
    milestone: string;
    category: string;
    discipline: string[];
    priority: string[];
    activityID: string[];
    activityName: string[];
    fmRorPO: string[];
    // waypointRequired: string[];
    associatedNPW: string[];
    associatedPLI: string[];
    associatedQVD: string[];
    PriorityNo: string;
    // criticalPath: string;
    filterStatuses: string[];
    todayDate = new Date();
    exceptionPhases: string[];
    exceptionStatus: string[];
    subsystems: string[];
    barcodes:string[];
    activities: string[];
    mcMilestones: McMilestone[];
    goocs: Gooc[];
    systemOwnersFilter: number[];
    mcEngineersFilter: number[];
    contractorsFilter: Contractor[];
    stagedStartUpPriorityFilter: StagedStartUpPriority[];
    waypoints: Waypoint[];
    milestones: Milestone[];
    autocompleteDisplayedColumns = ['name', 'description'];
    systemAutocompleteDisplayedColumns = ['no', 'name'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    mcMilestoneAutocompleteDisplayedColumns = ['name'];
    subsystemAutocompleteDisplayedColumns = ['value'];
    subsystem2AutocompleteDisplayedColumns = ['id', 'name'];
    lockFilters = false;
    isNewPage = false;
    showFilterPanel=true;
    headerText: string;
    zone: Zone = null;
    isReadOnly = false;
    isExceptionsTracking = false;
    isExceptionTrackingstr ="false";
    currentExScope = ExceptionScope.All;
    uploadedAttachmentsActionInProgress = new Dictionary<string, boolean>();
    allowCommentsBulkUpdate = false;
    manhours = 0;
    exportExcelAllow: boolean = true;

    columnCategory: CheckListColumn = null;
    columnRevision: CheckListColumn = null;
    columnDocType: CheckListColumn = null;
    columnDocType2: CheckListColumn = null;
    columnRedType: CheckListColumn = null;
    columnRedlineExceptionNumber: CheckListColumn = null;
    columnRedReqType: CheckListColumn = null;
    columnRedStatus: CheckListColumn = null;
    columnChangeId: CheckListColumn = null;
    columnDeliveryEngineer: CheckListColumn = null;
    columnMcdrConstrRedCat: CheckListColumn = null;
    columnContainsChange: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnNumber: CheckListColumn = null;
    columnCowDocNumber: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnActionBy: CheckListColumn = null;
    columnPliImplementationTeam: CheckListColumn = null;
    columnPunchType: CheckListColumn = null;
    columnReviewStatusComplete: CheckListColumn = null;
    columnACNumber: CheckListColumn = null;
    columnRaisedBy: CheckListColumn = null;
    columnTagDiscipline: CheckListColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnDescription: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnPriority: CheckListColumn = null;
    columnExceptionPriority: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnNBResponsiblePersonComments: CheckListColumn = null;
    columnSubsystem: CheckListColumn = null;
    columnOriginalSubsystem: CheckListColumn = null;
    columnSubsystemName: CheckListColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    columnExceptionStatus: CheckListColumn = null;
    columnExceptionPhase: CheckListColumn = null;
    columnTargetCompletionDate: CalendarColumn = null;
    columnMaterialReference: CheckListColumn = null;
    ColumnAttachmentName: CheckListColumn = null;
    columnClassification: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnPreCommStart: CalendarColumn = null;
    columnPreCommFinish: CalendarColumn = null;
    columnMcPlan: CalendarColumn = null;
    columnRequiredByDate: CalendarColumn = null;
    columnMcForecast: CalendarColumn = null;
    columnRfsuPlan: CalendarColumn = null;
    columnRFOPlan: CalendarColumn = null;
    columnRfsuForecast: CalendarColumn = null;
    columnItrInCurrentWeek: CheckListColumn = null;
    columnConstraintFlag: CheckListColumn = null;

    columnITRConstraintTypeName: CheckListColumn = null;
    columnITRConstraintDueDate: CalendarColumn = null;
    columnFMRorPOETADate: CalendarColumn = null;
    columnETA: CalendarColumn = null;
    columnITRConstraintResponsibleUser: CheckListColumn = null;
    columnITRConstraintResponsibleGroup: CheckListColumn = null;
    columnITRConstraintAdditionalResponsibleUser: CheckListColumn = null;
    columnITRConstraintRaisedByUser: CheckListColumn = null;
    columnITRConstraintRaisedByTeam: CheckListColumn = null;
    columnMaterialReviewStatus: CheckListColumn = null;
    columnMaterialReviewAssignedTo: CheckListColumn = null;
    columnITRConstraintRaisedDate: CalendarColumn = null;
    columnITRConstraintStatusDate: CalendarColumn = null;
    columnRFSUWalkdownForecast: CalendarColumn = null;
    columnForecastClosureDate: CalendarColumn = null;
    columnLastConstraintDate: CalendarColumn = null;
    columnFMRorPO: CheckListColumn = null;
    columnWaypointRequired: CheckListColumn = null;
    columnAssociatedNPW: CheckListColumn = null;
    columnAssociatedPLI: CheckListColumn = null;
    columnAssociatedQVD: CheckListColumn = null;
    columnPriorityNo: CheckListColumn = null;
    columnCriticalPath: CheckListColumn = null;
    columnITRConstraintDescription: CheckListColumn = null;
    columnITRConstraintStatus: CheckListColumn = null;
    columnITRConstraintId: CheckListColumn = null;

    columnITRConstraintCount: NumericColumn = null;
    columnItrId: number = 0;
    columnActivityID: CheckListColumn = null;
    columnActivityName: CheckListColumn = null;
    columnRFO: CheckListColumn = null;
    columnRFSUActual: CalendarColumn = null;
    columnMCActual: CalendarColumn = null;
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnExceptionResponsbilePerson: CheckListColumn = null;
    columnExceptionAdditionalResponsibleUser: CheckListColumn = null;
    columnManhours: NumericColumn = null;
    columnWorkAreaName: CheckListColumn = null;
    columnWorkOrderNum: CheckListColumn = null;
    columnETransferNumber: CheckListColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    columnLatestITRComment: CheckListColumn = null;
    columnAssociatedComments: CheckListColumn = null;
    columnAQVDContractor: CheckListColumn = null;
    ColumnPLIActionedBy: CheckListColumn = null;
    columnLatestExceptionComment: CheckListColumn = null;
    columnO3ConstraintID: CheckListColumn = null;
    columnMaterialsTotalCount: NumericColumn = null;
    columnPLIForecastClosureDate: CalendarColumn = null;
    columnPostRFOWorkExpectedClosureDate: CalendarColumn = null;
    columnPli: CheckListColumn = null;
    columnNpw: CheckListColumn = null;
    columnPascr:CheckListColumn = null;
    columnRedline: CheckListColumn = null;
    columnRFOActual: CalendarColumn = null;  
    columnQVDNumber: CheckListColumn = null; 
    columnPliCreatedDate: CalendarColumn = null;
    columnNpwIssueDate: CalendarColumn = null;
    columnItemCreatedDate: CalendarColumn = null;
    columnResponsiblePersonOrGroup: CheckListColumn = null;
    columnItemComment: CheckListColumn = null;
    columnPriorityManualOverride: CheckListColumn = null;
    columnExpectedTimeOfCompletion: CalendarColumn = null;
    columnITRConstraintCount$ = this.store.select((state) => state.detailedStatusState.lockedFilter.columnITRConstraintCount);
    columnManhoursCount$ = this.store.select((state) => state.detailedStatusState.lockedFilter.columnManhours);
    columnMaterialsTotalCount$ = this.store.select((state) => state.detailedStatusState.lockedFilter.columnMaterialsTotalCount);
    isAdmin = false;
    isNBResponsiblePerson = false;
    isITRResponsiblePerson = false;
    isPliForecastClosureDateUpdateAllowed = false;
    isPostRFOWorkExpectedClosureDateUpdateAllowed = false;

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild("detailedPage") detailedPage: ElementRef;

    @Input() blueScreen: boolean = false;
    @Input() blueScope: string;
    @Input() blueSubsystem: string;
    @Input() blueRFO: string;
    @Input() blueRFOs: RFO[];
    @Input() blueStatus: string[];
    @Input() blueDiscipline: string[];
    @Input() blueZone: string;
    @Input() blueCategory: string;
    @Input() blueExclude: string;
    @Input() blueRFSUCompleted: string;
    @Input() blueAllowComments: boolean = true;
    @Input() blueLockFilters: boolean = true;


    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private toastService: ToastService,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private projectTeamsService: ProjectTeamsService,
        private commentService: CommentService,
        private location: Location,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private detailedStatusService: DetailedStatusService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'icon-cal',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon-cal.svg')
        );

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );

/*
        const filterInstance = getDetailedStatusFilterInstance(route.snapshot.queryParamMap.get('scope'));
        this.filterForm = formSvc.createSimpleForm(filterInstance);
*/                
    }
    get showResponsibleComment() {
        if(this.scope === this.subsystemScopes.PLI) {
            return this.isNBResponsiblePerson ? true : false
        } else {
            return this.isITRResponsiblePerson ? true : false
        }      
    }
    ngOnInit() {
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.isPliForecastClosureDateUpdateAllowed = this.roleService.isInRole(Constants.applicableGroups.PLIForecastClosureDate);
        this.isNBResponsiblePerson = this.roleService.isInRole(Constants.applicableGroups.PLIImplementationTeamNB);
        this.isITRResponsiblePerson = this.roleService.isInRole(Constants.applicableGroups.ResponsiblePerson);
        this.isPostRFOWorkExpectedClosureDateUpdateAllowed = !this.roleService.isInRole(Constants.applicableGroups.ReadOnly);
        if(this.roleService.isInRole(Constants.applicableGroups.ExceptionsTracking))
        {
            this.isExceptionsTracking = true;
            this.isExceptionTrackingstr = "yes_i_have_edit_permissions";
        } else {
            this.isExceptionsTracking = false;
            this.isExceptionTrackingstr = "no_i_dont_have_edit_permissions";
        }
        this.itrid = 0;
        this.id = null;
        this.detailedStatusGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items.map((item) => Object.assign({}, item, { isConstraintEdit: false }));
            this.resultsLength = data.totalCount;
        });
        
        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });
        this.isReadOnly = this.roleService.isReadOnly();

        this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {

            if(this.blueScreen) {
                this.scope = SubsystemScope[this.blueScope];
                this.subsystem = this.blueSubsystem ? this.blueSubsystem: null;
                this.rfo = this.blueRFO ? this.blueRFO: null;
                this.rfos = this.blueRFOs ? this.blueRFOs: null;
                this.filterStatuses = this.blueStatus ? this.blueStatus : [];
                this.discipline = this.blueDiscipline ? this.blueDiscipline : [];
                this.category = this.blueCategory ? this.blueCategory : null;
                this.allowCommentsBulkUpdate = this.blueAllowComments;
                this.lockFilters = this.blueLockFilters;
                this.excludeValue = this.blueExclude;
                this.rfsuCompleted = this.blueRFSUCompleted;

                if (this.blueZone) {
                    this.zone = Zone[this.blueZone];
                } else {
                    this.zone = null;
                }
            } else {
                this.scope = SubsystemScope[paramMap.get('scope')];
                this.subsystem = paramMap.get('subsystem');
                this.rfo = paramMap.get('rfo');
                this.rfos = paramMap.getAll('rfos').map((s) => ({ name: s } as RFO));
                this.filterStatuses = paramMap.getAll('status');
                this.discipline = paramMap.getAll('discipline');
                this.category = paramMap.get('category');
                this.allowCommentsBulkUpdate = paramMap.get('allowCommentsBulkUpdate') === 'true';
                this.lockFilters = paramMap.get('lockFilters') === 'true';
                this.excludeValue = paramMap.get('excludeValue');
                this.rfsuCompleted = paramMap.getAll('rfsucompleted').length === 0 ? null : JSON.parse(paramMap.get('rfsucompleted'));

                if (paramMap.get('zone')) {
                    this.zone = Zone[paramMap.get('zone')];
                } else {
                    this.zone = null;
                }
            }
            this.commonRouteconfig(paramMap);
        });

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: any[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines. Please contact Program Administrator.'
                    );
                }
            );

        forkJoin([
            this.lookupService.getUnits(),
            this.lookupService.getChangeDocAreas(),
            this.lookupService.getRevTypes(),
            this.lookupService.getDocGroups(),
            this.lookupService.getRedReqTypes(),
            this.lookupService.getMcdrConstrRedlineCats(),
            this.lookupService.getMOCs(),
            this.lookupService.getRedTypes(),
            this.lookupService.getSCManagers([]),
            this.lookupService.getACLeads([]),
            this.lookupService.getMCEngineers([]),
            this.lookupService.getSysOwners([])            
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error('Error occurred while getting data. Please contact Program Administrator.');
                })
            )
            .subscribe(
                ([
                    units,
                    areas,
                    revTypes,
                    docGroups,
                    redReqTypes,
                    mcdrConstrRedlineCats,
                    mocs,
                    redTypes,
                    scManagers,
                    areaCompletionLeads,
                    mcEngineers,
                    sysOwners                    
                ]) => {
                    this.units = units;
                    this.areas = areas;
                    this.revTypes = revTypes;
                    this.docGroups = docGroups;
                    this.redReqTypes = redReqTypes;
                    this.redTypes = redTypes;
                    this.mcdrConstrRedlineCats = mcdrConstrRedlineCats.map((s) => s.value);
                    this.mocs = mocs;
                    this.scManagers = scManagers;
                    this.areaCompletionLeads = areaCompletionLeads;
                    this.mcEngineers = mcEngineers;
                    this.sysOwners = sysOwners;                    
                },
                (error) => {
                    this.toastService.Error(error);
                }
            );

        this.detailedStatusGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                    scope: this.subsystemScopes[this.scope],
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new DetailedStatusFilterRequest(this.subsystemScopes[this.scope]));
        });       

        this.lookupService
            .getConstraintFlags()
            .pipe(take(1))
            .subscribe((data) => {
                this.constraintFlags = [...data.map((x) => x.name)];
                this.constraintFlags.unshift('');
            });
            
            if(this.scope === SubsystemScope.PLI)
            {
                this.lookupService
                    .getPliImplementationTeam()
                    .pipe(take(1))
                    .subscribe((data) => {                        
                        if(this.roleService.isInRole(Constants.applicableGroups.PLIImplementationTeamCOG))                                                     
                            this.pliImplementationTeams = data.filter(x=> x.name === '3GP COG SSOR' || x.name === '3GP MEI CCEP COG' || x.name === 'Commissioning Support Group');                                                     
                        else if(this.roleService.isInRole(Constants.applicableGroups.PLIImplementationTeamICT))                        
                            this.pliImplementationTeams = data.filter(x=> x.name === '3GP MEI SK HIT Team' || x.name === '3GP TEGRA' || x.name == '3GP MEI SK');                             
                        else
                            this.pliImplementationTeams = [];
                    });
            }           
    }   
    
    ngOnChanges(changes: SimpleChanges): void {
          this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {

            if(this.blueScreen) {
                this.scope = SubsystemScope[this.blueScope];
                this.subsystem = this.blueSubsystem ? this.blueSubsystem: null;
                this.rfo = this.blueRFO ? this.blueRFO: null;
                this.rfos = this.blueRFOs ? this.blueRFOs: null;
                this.filterStatuses = this.blueStatus ? this.blueStatus : [];
                this.discipline = this.blueDiscipline ? this.blueDiscipline : [];
                this.category = this.blueCategory ? this.blueCategory : null;
                this.allowCommentsBulkUpdate = this.blueAllowComments;
                this.lockFilters = this.blueLockFilters;
                this.excludeValue = this.blueExclude;
                this.rfsuCompleted = this.blueRFSUCompleted;

                if (this.blueZone) {
                    this.zone = Zone[this.blueZone];
                } else {
                    this.zone = null;
                }
            } else {
                this.scope = SubsystemScope[paramMap.get('scope')];
                this.subsystem = paramMap.get('subsystem');
                this.rfo = paramMap.get('rfo');
                this.rfos = paramMap.getAll('rfos').map((s) => ({ name: s } as RFO));
                this.filterStatuses = paramMap.getAll('status');
                this.discipline = paramMap.getAll('discipline');
                this.category = paramMap.get('category');
                this.allowCommentsBulkUpdate = paramMap.get('allowCommentsBulkUpdate') === 'true';
                this.lockFilters = paramMap.get('lockFilters') === 'true';
                this.excludeValue = paramMap.get('excludeValue');
                this.rfsuCompleted = paramMap.getAll('rfsucompleted').length === 0 ? null : JSON.parse(paramMap.get('rfsucompleted'));

                if (paramMap.get('zone')) {
                    this.zone = Zone[paramMap.get('zone')];
                } else {
                    this.zone = null;
                }
            }

            this.commonRouteconfig(paramMap)
           
            this.store.dispatch(new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(this.subsystemScopes[this.scope]), scope: this.subsystemScopes[this.scope] }));

            this.store.dispatch(new DetailedStatusFilterRequest(this.subsystemScopes[this.scope])); 
                
    });
}

private commonRouteconfig(paramMap) {
    let pageScope = null;
    pageScope = this.scope;
    this.setFlagToDisableExcelExport(pageScope);
    this.id = paramMap.get('id');
    if (this.scope === SubsystemScope.ConstraintDetails && this.id != null) {
        this.store.dispatch(
            new DetailedStatusFilterPropertyUpdate({
                key: 'columnItrId',
                value: this.id,
                scope: this.subsystemScopes[this.scope],
            })
        );
        this.itrid = Number(this.id);                
        this.store.dispatch(new GetDetailsRequest({ id: paramMap.get('id'), scope: 10 }));
        this.item$.pipe(takeWhile(() => this.isAlive)).subscribe((item) => {
            this.selectedITR = new DetailedStatusDTO();
            this.selectedITR.id = item.id;
            this.selectedITR.subsystem = item.subsystem;
            this.selectedITR.subsystemName = item.subsystemName;
            this.selectedITR.number = item.number;
            this.selectedITR.tagNumber = item.tagNumber;
            this.selectedITR.discipline = item.discipline;
            this.selectedITR.description = item.description;
            this.selectedITR.contractor = item.contractor;
            this.selectedITR.status = item.status;
        });
    }
    this.contractorLabel = this.getContractorLabel(this.scope);
    this.detailedStatusFilter$
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((filter: DetailedStatusLockedFilter | DetailedStatusFilterBase) => {
            this.showColumns = { ...filter.showHideColumns };
        });
    this.sortBy = 'number';
    this.priority = paramMap.getAll('priority');
    this.activityID = paramMap.getAll('activityID');
    this.activityName = paramMap.getAll('activityName');
    this.fmRorPO = paramMap.getAll('fmRorPO');
    this.isNewPage = paramMap.get('new') === 'true';
    this.exceptionPhases = paramMap.getAll('exceptionPhases');
    this.subsystems = paramMap.getAll('subsystems');
    this.rfoType = paramMap.getAll('rfotype').length === 0 ? null : JSON.parse(paramMap.get('rfotype'));
    this.barcodes = paramMap.getAll('barcodes');
    this.activities = paramMap.getAll('finalpunchItems');
    this.mcMilestones = paramMap.getAll('mcMilestones').map((m) => ({ name: m } as McMilestone));
    this.goocs = paramMap.getAll('goocs').map((g) => ({ no: g } as Gooc));
    this.contractorsFilter = paramMap.getAll('contractors').map(
        (s) =>
        ({
            contractNo: s,
            name: s,
        } as Contractor)
    );
    this.stagedStartUpPriorityFilter = paramMap.getAll('stagedStartUpPriorities').map(
        (p) =>
        ({
            priority: p,
            priorityName: p,
        } as StagedStartUpPriority)
    );
    this.mcEngineersFilter = paramMap.getAll('mcEngineers').map((s) => +s);
    this.systemOwnersFilter = paramMap.getAll('systemOwners').map((s) => +s);
    if(this.filterExpansionPanel)
        this.filterExpansionPanel.expanded = !this.lockFilters;
    const exceptionScope = paramMap.get('exScope');
    const projectTeamNames = paramMap.getAll('projectTeamNames');

    
    if (this.lockFilters) {       
        this.getSystemOwnerAndEnginner(projectTeamNames);
        this.store.dispatch(
            new DetailedStatusSetLockedFilter({
                page: 0,
                zone: this.zone,
                scope: this.scope,
                subsystem: this.subsystem,
                itrid: this.itrid,
                rfo: this.rfo,
                category: [this.category],
                discipline: this.discipline,
                sortBy: this.sortBy,
                exceptionPhases: this.exceptionPhases,
                // columnSubsystem: {
                //     items: this.scope === SubsystemScope.EX ? [this.subsystem] : this.subsystems,
                // },
                columnStatus:
                    this.scope === SubsystemScope.AQVD ||
                        this.scope === SubsystemScope.BITR ||
                        this.scope === SubsystemScope.CITR ||
                        this.scope === SubsystemScope.BITRandCITR ||
                        this.scope === SubsystemScope.ConstraintDetails
                        ? {
                            items: this.filterStatuses,
                        }
                        : null,
                columnRedStatus:
                    this.scope === SubsystemScope.Redline
                        ? {
                            items: this.filterStatuses,
                        }
                        : null,
                mcMilestones: this.mcMilestones,
                goocs: this.goocs,
                projectTeamNames: projectTeamNames,
                exScope: exceptionScope ? this.exceptionScopes[exceptionScope] : this.currentExScope,
                subsystemScope: paramMap.get('subsystemScope'),
                systemOwner: this.systemOwnersFilter,
                mcEngineer: this.mcEngineersFilter,
                waypoints: this.waypoints,
                columnContractor: {
                    items: this.contractorsFilter.map((s) => s.contractNo),
                },
                contractors: this.contractorsFilter,
                rfos: this.rfos,
                systems: this.systems,
                rfsuCompleted:this.rfsuCompleted,
                rfoType: this.rfoType,
                stagedStartUpPriorities: this.stagedStartUpPriorityFilter,
                excludeValue: this.excludeValue
            })
        );
    } else {
        this.store.dispatch(new DetailedStatusResetLockedFilter());
    }
    this.headerText = subsystemScopeEnumToString(this.scope);
    if(this.category === 'act'){
        this.headerText = 'ACTs';
    }
    if (!this.lockFilters && this.headerText !='Post-RFO Work To Go') {
        this.showColumns = {
            ...this.showColumns,
            showSubsys: true,
        };
        this.headerText += ' status';
    } else {
        if (this.subsystem != null) {
            this.showColumns = {
                ...this.showColumns,
                showSubsys: false,
            };
            this.headerText += ' by subsystem';
        } else if (this.rfo != null) {
            this.headerText += ' by RFO';
        }
    }

    if(this.lockFilters && this.scope === SubsystemScope.Redline) {
        this.showColumns = {
            ...this.showColumns,
            showRFO: false,
        };
    }
    else if(!this.lockFilters && this.scope === SubsystemScope.Redline) {
        this.showColumns = {
            ...this.showColumns,
            showRFO: true,
            showAreaBreakdown: true,
            showDocType: false,
            showRevision: false
        };
    }
    if(this.scope === SubsystemScope.RFSUWorkToGoSearch)
    {
        this.showColumns.showSubsysName = true;
        this.showColumns.showTagName = true;
        this.showColumns.showTagType = true;
        this.showColumns.showBarcode = true;
        this.showColumns.showRfsuPlan = true;
        this.showColumns.showPreCommDates = true;
        this.showColumns.showITRConstraintCount = false;
    }   
    if(this.scope === SubsystemScope.RFOWorkToGoSearch)
    {
        this.showColumns.showAreaBreakdown = true;
        this.showColumns.showContractor = false;
        this.showColumns.showExceptionPhase = false;
    }
    //this.showColumns.showDocType = this.scope === SubsystemScope.Redline;
    this.showColumns.showDiscipline =
        this.scope !== SubsystemScope.BITRandCITR && this.scope !== SubsystemScope.ConstraintDetails;
    this.showColumns.showStatus =
        this.scope !== SubsystemScope.BITRandCITR && this.scope !== SubsystemScope.ConstraintDetails;
    this.showColumns.showContractor =
        this.scope !== SubsystemScope.BITRandCITR && this.scope !== SubsystemScope.ConstraintDetails && this.scope !== SubsystemScope.RFOWorkToGoSearch;
    if(this.scope === SubsystemScope.EX) {
        this.showColumns.showContractor = false;
        this.showColumns.showITRConstraintCount = false;
        this.showColumns.showCategory = false;
    }
    this.store.dispatch(
        new DetailedStatusFilterPropertyUpdate({
            key: 'showHideColumns',
            value: this.showColumns,
            scope: this.subsystemScopes[this.scope],
        })
    );

    const filterInstance = getDetailedStatusFilterInstance(this.subsystemScopes[this.scope]);
    this.filterForm = this.formSvc.createSimpleForm(filterInstance);
    this.paginator.pageIndex = 0;
    // if(this.scope === SubsystemScope.RFSUWorkToGoSearch)
    //     this.showFilterPanel = true;
    // else
    // {
        this.showFilterPanel = true;                            
        if(this.filterExpansionPanel)
        {                    
            this.filterExpansionPanel.expanded=!this.lockFilters;
        }
    //}
    this.store
        .select((state) => state.detailedStatusState[this.subsystemScopes[this.scope]])
        .pipe(withLatestFrom(this.store.select((state) => state.detailedStatusState.lockedFilter)), take(1))
        .subscribe(([filter, lockedFilter]) => {
            this.filterForm.patchValue(lockedFilter || filter, { emitEvent: false });
            if (this.isNewPage) {
                this.getUsersPerProjectTeam(filter.projectTeamNames || []);
                this.location.go('/detailedstatus?scope=' + paramMap.get('scope'));
                if (this.scope === SubsystemScope.EX) {
                    this.zone = null;
                }                       
                
                this.store.dispatch(new DetailedStatusFilterRequest(this.subsystemScopes[this.scope]));
            }
        });
        
    combineLatest([
        this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.page
                : state.detailedStatusState[this.subsystemScopes[this.scope]].page
        ),
        this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.sortBy
                : state.detailedStatusState[this.subsystemScopes[this.scope]].sortBy
        ),
        this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.direction
                : state.detailedStatusState[this.subsystemScopes[this.scope]].direction
        ),
    ])
        .pipe(takeWhile(() => this.isAlive))
        .subscribe(([page, sortBy, direction]) => {
            this.paginator.pageIndex = page;
            this.sortBy = sortBy;
            this.direction = direction;
        });

    this.registerColumnHeaderFilters();

    if (this.lockFilters) {
        this.store.dispatch(
            new DetailedStatusFilterMultiplePropertyUpdate({
                items: [
                    {
                        key: 'category',
                        value: this.category ? [this.category] : [],
                    },
                    {
                        key: 'disciplines',
                        value: this.discipline ? this.discipline : [],
                    },
                    {
                        key: 'mcEngineer',
                        value: this.mcEngineersFilter ? this.mcEngineersFilter : [],
                    },
                    {
                        key: 'systemOwner',
                        value: this.systemOwnersFilter ? this.systemOwnersFilter : [],
                    },
                    {
                        key: 'contractors',
                        value: this.contractorsFilter ? this.contractorsFilter : [],
                    },
                    {
                        key: 'stagedStartUpPriorities',
                        value: this.stagedStartUpPriorityFilter ? this.stagedStartUpPriorityFilter : [],
                    },
                    {
                        key: 'goocs',
                        value: this.goocs ? this.goocs : [],
                    },
                    {
                        key: 'mcMilestones',
                        value: this.mcMilestones ? this.mcMilestones : [],
                    },
                    {
                        key: 'rfos',
                        value: this.rfos ? this.rfos : [],
                    },
                    {
                        key: 'systems',
                        value: this.systems ? this.systems : [],
                    },
                    {
                        key: 'subsystems',
                        value: this.subsystems ? this.subsystems : [],
                    },
                    {
                        key: 'exScope',
                        value: this.currentExScope,
                    },
                    {
                        key: 'waypoints',
                        value: this.waypoints ? this.waypoints : [],
                    },
                    {
                        key: 'milestones',
                        value: this.milestones ? this.milestones : [],
                    },
                ],
                scope: this.subsystemScopes[this.scope],
            })
        );

        this.filterForm.controls.category.setValue(this.category ? [this.category] : []);
        this.filterForm.controls.disciplines.setValue(this.discipline ? this.discipline : []);
        this.filterForm.controls.mcEngineer.setValue(this.mcEngineersFilter ? this.mcEngineersFilter : []);
        this.filterForm.controls.systemOwner.setValue(this.systemOwnersFilter ? this.systemOwnersFilter : []);
        this.filterForm.controls.contractors.setValue(this.contractorsFilter ? this.contractorsFilter : []);
        this.filterForm.controls.goocs.setValue(this.goocs ? this.goocs : []);
        this.filterForm.controls.mcMilestones.setValue(this.mcMilestones ? this.mcMilestones : []);
        this.filterForm.controls.rfos.setValue(this.rfos ? this.rfos : []);
        this.filterForm.controls.systems?.setValue(this.systems ? this.systems : []);
        this.filterForm.controls.subsystems?.setValue(this.subsystems ? this.subsystems : []);
        this.filterForm.controls.waypoints.setValue(this.waypoints ? this.waypoints : []);
        this.filterForm.controls.milestones.setValue(this.milestones ? this.milestones : []);
        if(this.scope !== SubsystemScope.Redline) {
            this.filterForm.controls.rfsuCompleted.setValue(this.rfsuCompleted ? this.rfsuCompleted : null);
        }     
        if(this.scope == SubsystemScope.PLI) {
            this.filterForm.controls.rfoType.setValue(this.rfoType ? this.rfoType : null);
        }         
    }

    this.store.dispatch(
        new DetailedStatusFilterMultiplePropertyUpdate({
            items: [
                {
                    key: 'subsystem',
                    value: this.subsystem ? this.subsystem : '',
                },
                {
                    key: 'rfo',
                    value: this.rfo ? this.rfo : '',
                },
                {
                    key: 'scope',
                    value: this.scope,
                },
                {
                    key: 'sortBy',
                    value: this.sortBy,
                },
                {
                    key: 'zone',
                    value: this.zone,
                },
                {
                    key: 'exScope',
                    value: this.currentExScope,
                },
            ],
            scope: this.subsystemScopes[this.scope],
        })
    );

    if (this.scope === SubsystemScope.ConstraintDetails) {
        this.store.dispatch(
            new DetailedStatusFilterPropertyUpdate({
                key: 'columnITRConstraintStatus',
                value: {
                    "items": [
                        "Open",
                        "Closed"
                    ]
                    },
                scope: this.subsystemScopes[this.scope],
            })
        );
    }

    this.filterForm.controls.subsystem.setValue(this.subsystem ? this.subsystem : '', { emitEvent: false });

    if (this.subsystem != null) {
        this.lookupService.getSubsystemName(this.subsystem).subscribe((subsystemName) => {
            this.subsystemName = subsystemName;
        });
    }

    this.filterForm.controls.waypoints?.valueChanges
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((waypoints: Waypoint[]) => {
            this.prevWaypoints = waypoints; //dzhen-correct
            this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
        });

    this.filterForm.controls.milestones?.valueChanges
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((milestones: Milestone[]) => {
            let waypoints = JSON.parse(JSON.stringify(milestones));
            waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
            waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name'); //dzhen-correct
            if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) { //dzhen-correct
               this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                this.filterForm.controls.waypoints.setValue(waypoints);
            }
        });

    this.makeRequest$.pipe(takeWhile(() => this.isAlive)).subscribe((makeRequest) => {              
        if(this.zone?.toString() === Zone[Zone.LTP]) {
            this.prepareFilterForLTP();
            this.search();
        } else {
             if (makeRequest || this.resultsLength === 0) {
            this.store.dispatch(new DetailedStatusFilterRequest(this.subsystemScopes[this.scope]));
        }
        }
    });

    if (this.scope && this.scope === SubsystemScope.PLI) {
        this.displayedColumns = this.pliColumns;
    } else if (this.scope && this.scope === SubsystemScope.EX) {
        this.displayedColumns = this.exColumns;
    } else if (this.scope && this.scope === SubsystemScope.RFSUWorkToGoSearch) {
        this.displayedColumns = this.rfsuWorkToGoSearch;
    } else if (this.scope && this.scope === SubsystemScope.RFOWorkToGoSearch) {
        this.displayedColumns = this.rfoWorkToGoSearch;
    } else if (this.scope && this.scope === SubsystemScope.Redline) {
        this.displayedColumns = this.redlineColumns;
    } else if (this.scope && this.scope === SubsystemScope.COW) {
        this.displayedColumns = this.cowColumns;
    } else if (
        this.scope === SubsystemScope.BITR ||
        this.scope === SubsystemScope.CITR ||
        this.scope === SubsystemScope.BITRandCITR ||
        this.scope === SubsystemScope.ConstraintDetails
    ) {
        this.displayedColumns = [
            ...this.itrColumns.slice(0, this.itrColumns.length - 2),
            'isItrInCurrentWeek',
            ...this.itrColumns.slice(this.itrColumns.length - 2),
        ];
    } else if (this.scope === SubsystemScope.AQVD) {
        this.displayedColumns = this.itrColumns;
    } else {
        this.displayedColumns = this.defaultColumns;
    }    
    this.scrollToDetails();       
}
    private prepareFilterForLTP() {
        if( this.subsystemScopes[this.scope] === 'PLI') {

            let finalpunchItems = this.activities[0]?.split(',');
            let finalPunchItemsCheckList = new CheckListColumn();
            finalPunchItemsCheckList.items = finalpunchItems.length > 0 ? finalpunchItems : [];
            finalPunchItemsCheckList.excludeBlanks = false;
            finalPunchItemsCheckList.onlyBlanks = false;
            let statusValue = new CheckListColumn();
            statusValue.items = [
                "Not Started PL"
            ]
            statusValue.excludeBlanks = false;
            statusValue.onlyBlanks = false;
            this.filterForm.controls.columnNumber?.setValue(finalPunchItemsCheckList);
            this.filterForm.controls.columnStatus?.setValue(statusValue);
           
        } else {
            let barcodes = this.barcodes[0]?.split(',');
            let barcodeCheckListItems = new CheckListColumn();
            barcodeCheckListItems.items = barcodes.length > 0 ? barcodes : [];
            barcodeCheckListItems.excludeBlanks = false;
            barcodeCheckListItems.onlyBlanks = false;
            let statusValue = new CheckListColumn();
            statusValue.items = [
                "Not Started"
            ];
            statusValue.excludeBlanks = false;
            statusValue.onlyBlanks = false;
            this.filterForm.controls.columnBarcode?.setValue(barcodeCheckListItems);
            this.filterForm.controls.columnStatus?.setValue(statusValue);
        }

    }

    addITRConstraint(event: any) {
        event.stopPropagation();
        if (this.itrid > 0) {
            this.popupSvc
                .openPopup(
                    new PopupSettings(ITRConstraintTypeSelectorPopupComponent, 450, 900, {
                        selecteditrid: this.itrid,
                        isadding: true
                    })
                )
                .afterClosed()
                .subscribe((dialogResult) => {
                    if (_.has(dialogResult, 'constraintForm')) {
                        this.addITRConstraintRequest(dialogResult.constraintForm);
                    }
                    localStorage.removeItem('selectedbarcodes');
                });
            return;
        }
    }

    updateITRConstraint(row: DetailedStatusDTO) {
        this.selectedITR = row;
        this.popupSvc
            .openPopup(
                new PopupSettings(ITRConstraintTypeSelectorPopupComponent, 450, 900, {
                    selecteditrid: row.id,
                    selecteditr: row,
                    isadding: false,
                })
            )
            .afterClosed()
            .subscribe((dialogResult) => {
                if (_.has(dialogResult, 'constraintForm')) {
                    this.updateITRConstraintRequest(dialogResult.constraintForm);
                }
            });
        return;
    }

    private addITRConstraintRequest(constraintForm: ITRConstraintDTO) {
        let hardRefresh = false;
        if (!this.selectedITR) {
            this.selectedITR = new DetailedStatusDTO();
            hardRefresh = true;
        }
        let sITR = _.cloneDeep(this.selectedITR);
        sITR.itrConstraintTypeName = constraintForm.constraintTypeName;
        sITR.fmRorPO = constraintForm.fmrOrPO;
        // sITR.waypointRequired = constraintForm.waypointRequired;
        sITR.associatedNPW = constraintForm.associatedNPW;
        sITR.associatedPLI = constraintForm.associatedPLI;
        sITR.associatedQVD = constraintForm.associatedQVD;
        sITR.itrConstraintDueDate = constraintForm.dueDate.isValid() ? constraintForm.dueDate : null;
        sITR.fmRorPOETADate = constraintForm.fmRorPOETADate.isValid() ? constraintForm.fmRorPOETADate : null;
        sITR.itrConstraintResponsibleUser = constraintForm.responsibleUserName;
        sITR.itrConstraintAdditionalResponsibleUser = constraintForm.additionalResponsibleUserName;
        sITR.itrConstraintRaisedDate = constraintForm.constraintRaisedDate;
        sITR.itrConstraintRaisedByUser = constraintForm.raisedByUserName;
        sITR.itrConstraintDescription = constraintForm.itrConstraintDescription;
        sITR.associatedNPWChangeType = constraintForm.associatedNPWChangeType;
        this.selectedITR.tagNo = constraintForm.tagNo;
        let payload = {
            itrConstraint: constraintForm,
            itr: sITR,
        };
        console.log('fmr poe date: ', sITR.fmRorPOETADate);
        this.store.dispatch(new DetailedStatusAddITRConstraintRequest(payload));

    }

    private updateITRConstraintRequest(constraintForm: ITRConstraintDTO) {
        this.selectedITR.itrConstraintTypeName = constraintForm.constraintTypeName;
        this.selectedITR.fmRorPO = constraintForm.fmrOrPO;
        // this.selectedITR.waypointRequired = constraintForm.waypointRequired;
        this.selectedITR.associatedNPW = constraintForm.associatedNPW;
        this.selectedITR.itrConstraintDueDate = constraintForm.dueDate?.isValid() ? constraintForm.dueDate : null;
        this.selectedITR.fmRorPOETADate = constraintForm.fmRorPOETADate?.isValid()
            ? constraintForm.fmRorPOETADate
            : null;
        this.selectedITR.itrConstraintResponsibleUser = constraintForm.responsibleUserName;
        this.selectedITR.itrConstraintAdditionalResponsibleUser = constraintForm.additionalResponsibleUserName;
        this.selectedITR.itrConstraintRaisedDate = constraintForm.constraintRaisedDate;
        this.selectedITR.itrConstraintRaisedByUser = constraintForm.raisedByUserName;
        this.selectedITR.associatedNPWChangeType = constraintForm.associatedNPWChangeType;
        this.selectedITR.tagNo = constraintForm.tagNo;
        const payload = {
            itrConstraint: constraintForm,
            itr: this.selectedITR,
        };

        this.store.dispatch(new DetailedStatusUpdateITRConstraintRequest(payload));
    }

    getSystemOwnerAndEnginner(projectTeamNames: string[]) {
        forkJoin([
            this.lookupService.getMCEngineers(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
            this.lookupService.getDeliveryEngineers(projectTeamNames),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners, deliveryEngineers]) => {
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                    this.deliveryEngineers = deliveryEngineers;
                    this.checkIfSelectedUsersValid(this.deliveryEngineers, 'deliveryEngineer', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    openDatepicker(event: any ,element: DetailedStatusDTO, type: string) {
        event.stopPropagation();
        let date;
        if (!this.isExceptionsTracking) {
            return;
        } 
            this.popupSvc.openPopup(
                new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                  selectedDate: date,
                  dateType: 'common',               
                  commonSaveAction: (d: moment.Moment) => {

                    this.saveDate(element, type,d);
                  },
                })
              );
    }

    saveDate(row: DetailedStatusDTO, type: string, forecastDate: moment.Moment) {

       if (type == "pliForecastClosureDate"){
            let selectedForecastClosureDate =  forecastDate ;
                const payload = {
                    pli: row,
                    forecastClosureDate: selectedForecastClosureDate
                } ;
            this.store.dispatch(new DetailedStatusAddPLIForecastClosureDateRequest(payload));
       }else if(type == "postRFOWorkExpectedClosureDate"){
            let selectedExpectedClosureDate =  forecastDate ;
                const payload = {
                    postRfoData: row,
                    expectedClosureDate: selectedExpectedClosureDate
                } ;
            this.store.dispatch(new DetailedStatusAddPostRFOWorkExpectedClosureDateRequest(payload));
       }else if (type== "expectedTimeOfCompletion"){
            let selectedExpectedClosureDate =  forecastDate ;
                const payload = {
                detailedStatusData: row,
                expectedClosureDate: selectedExpectedClosureDate,
                subsystemScope: row.scope
                } ;
            this.store.dispatch(new DetailedStatusAddExpectedClosureDateRequest(payload));
        }else{
            let selectedForecastClosureDate =  forecastDate ;
                const payload = {
                    ex: row,
                    forecastClosureDate: selectedForecastClosureDate
                } ;
            this.store.dispatch(new DetailedStatusAddEXForecastClosureDateRequest(payload));
        }
    }

    checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm?.controls[formControlName] &&
            this.filterForm?.controls[formControlName].value &&
            this.filterForm?.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm?.controls[formControlName].setValue(valid);
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: formControlName,
                    value: this.filterForm.controls[formControlName].value,
                    scope: this.subsystemScopes[this.scope],
                })
            );
        }
    }


    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName] &&
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'milestones');
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'waypoints');
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'contractors');
        this.setCWPInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'cwps');
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'goocs');
        this.setMcMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'mcMilestones');
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'rfos');
        this.setSystemInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'systems');
        this.setSubsystemInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'subsystems');

        forkJoin([
            this.lookupService.getACMs(projectTeamNames),
            this.lookupService.getSCManagers(projectTeamNames),
            this.lookupService.getACLeads(projectTeamNames),
            this.lookupService.getMCEngineers(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
            this.lookupService.getDeliveryEngineers(projectTeamNames),
        
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([acms, scManagers, areaCompletionLeads, mcEngineers, sysOwners, deliveryEngineers]) => {
                    this.acms = acms;
                    this.checkIfSelectedUsersValid(this.acms, 'acm', 'id');
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                    this.areaCompletionLeads = areaCompletionLeads;
                    this.checkIfSelectedUsersValid(this.areaCompletionLeads, 'areaCompletionLead', 'id');
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                    this.deliveryEngineers = deliveryEngineers;
                    this.checkIfSelectedUsersValid(this.deliveryEngineers, 'deliveryEngineer', 'id');
                    
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    getContractorsMulti = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((waypoints: Waypoint[]) => {
                return waypoints;
            })
        );
    };

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.isUsersPerProjectLoading = true;
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMilestones(
                search,
                take,
                page,
                this.filterForm.value.waypoints,
                this.filterForm.value.projectTeamNames
            )
            .pipe(map((milestones: Milestone[]) => milestones));
    };

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onRFSUContractorsClosed() {
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(true));
    }

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                    scope: this.subsystemScopes[this.scope],
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                    scope: this.subsystemScopes[this.scope],
                })
            );
        }
        this.store.dispatch(new DetailedStatusFilterRequest(this.subsystemScopes[this.scope]));
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    resetFilters() {
        if(this.filterExpansionPanel)
            this.filterExpansionPanel.expanded = true;
        const filterInstance = getDetailedStatusFilterInstance(this.subsystemScopes[this.scope]);
        this.filterForm.patchValue(filterInstance, { emitEvent: false });        
        this.store.dispatch(new DetailedStatusFilterReset({ filterInstance, scope: this.subsystemScopes[this.scope] }));
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setCWPInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setSystemInput.emit(new SetInputEventArgs(false, ''));
        this.setMcMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setSubsystemInput.emit(new SetInputEventArgs(false, ''));
        this.setConstructionAreaInput.emit(new SetInputEventArgs(false, ''));
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPriorityInput.emit(new SetInputEventArgs(false, ''));
        this.getUsersPerProjectTeam(this.projectTeamNames);
        this.listofSelectedBarcodes = [];
        this.setFlagToDisableExcelExport(filterInstance.scope);
    }

    getCWPs = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchCWPs(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            takeWhile(() => this.isAlive),
            map((cwps: string[]) => cwps)
        );
    };

    getSystems = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchSystems(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((systems: System[]) => systems));
    };

    getRFSUSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.filterForm.value.projectTeamNames);
    };

    displaySystemsSelected(values: System[]) {
        return values.map((x) => x.no).join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }


    getMcMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMcMilestones(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((mcMilestones: McMilestone[]) => mcMilestones));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    exportToExcel() {
        this.store.dispatch(detailedStatusExportToExcelRequest({ payload: this.subsystemScopes[this.scope] }));
    }

    openNPWDetailsPage(row: any) {
        this.ngZone.run(() =>
            this.router.navigate([`/changeDocumentDetails/${row.associatedNPWChangeType}/${row.associatedNPW}`])
        );
    }

    openDetailsPage(row: any) {
        if(this.scope == this.subsystemScopes.RFSUWorkToGoSearch || this.scope == this.subsystemScopes.RFOWorkToGoSearch) {
            return;
        }
        if (this.scope === this.subsystemScopes.ConstraintDetails) {
            this.store.dispatch(
                new GetConstraintCommentDetailsRequest({
                    id: row.id,
                    itrConstraintId: row.itrConstraintId,
                    scope: this.scope,
                })
            );
            this.store.dispatch(
                new GetConstraintDetailsSuccess({
                    id: row.id,
                    detail: row,
                })
            );
            this.ngZone.run(() =>
                this.router.navigate([`/details`], {
                    state: { data: { row, bySubsystem: this.lockFilters } },
                    queryParams: {
                        scope: SubsystemScope[this.scope],
                        id: row.id ? row.id : row.number,
                    },
                })
            );

            return;
        }
        let selection = window.getSelection();
        if (selection.toString().length === 0) {
            if(row.scope == SubsystemScope.ChangeDocument) {
                this.ngZone.run(() =>
                    this.router.navigate([`/changeDocumentDetails/${row.associatedNPW}/${row.id}`])
                );
            } else {
                this.ngZone.run(() =>
                    this.router.navigate([`/details`], {
                        state: { data: { row, bySubsystem: this.lockFilters } },
                        queryParams: {
                            scope: SubsystemScope[row.scope],
                            id: row.id ? row.id : row.number,
                            previousPageScope: this.scope,
                        },
                    })
                );
            }
        }
    }

    openItemDetailsPage(row: any, type : string) {
        let id;
        if(type == "NPW" && row.npwId !== null){
            this.ngZone.run(() =>
            this.router.navigate([`/changeDocumentDetails/${row.npwType}/${row.npwId}`]));
            return;
        } else if(type == "ITR" && row.itrId !== null){
            id = row.itrId;
            row.scope = row.itrCheckSheetType == "Comm Sheet (C2)" ? SubsystemScope.CITR : row.itrCheckSheetType == "Pre-Comm Tengiz Site Checksheets (B2)" ? SubsystemScope.BITR : SubsystemScope.AQVD;
        } else if (type == "PLI" && row.pli !== null){
            id = row.pli;
            row.scope = SubsystemScope.PLI;
        } else  if (type == "Redline" && row.redlineId !== null){
            id = row.redlineId;
            row.scope = SubsystemScope.Redline;
        } else {return;}

        this.ngZone.run(() =>
            this.router.navigate([`/details`], {
                state: { data: { row, bySubsystem: this.lockFilters } },
                queryParams: {
                    scope: SubsystemScope[row.scope],
                    id: id,
                    previousPageScope: this.scope,
                },
            })
        );
    }

    private withLatestFilter(action: (...actionArgs: any) => Observable<any>, ...args: any) {
        return of(this.subsystemScopes[this.scope]).pipe(
            withLatestFrom(
                this.store.select((state) => state.detailedStatusState[this.subsystemScopes[this.scope]]),
                this.store.select((state) => state.detailedStatusState.lockedFilter)
            ),
            switchMap(([, filter, lockedFilter]) => action(...args, lockedFilter || filter))
        );
    }

    getDisciplines = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchDisciplinesWithDetailedStatusFilter, search, take, page);

    getCategories = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchCategoriesWithDetailedStatusFilter, search, take, page);

    getTagNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchTagNumbersWithDetailedStatusFilter, search, take, page);

    getTagTypes = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchTagTypesWithDetailedStatusFilter, search, take, page);

    getTagNames = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchTagNamesWithDetailedStatusFilter, search, take, page);

    getNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchNumbersWithDetailedStatusFilter, search, take, page);

    getCowDocNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchCowDocNumbersWithDetailedStatusFilter, search, take, page);

    getContractors = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchContractorsWithDetailedStatusFilter, search, take, page);

    getStatuses = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchStatusesWithDetailedStatusFilter, search, take, page);

    getActionBys = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchActionBysWithDetailedStatusFilter, search, take, page);

    getImplementationTeam = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchImplementationTeamWithDetailedStatusFilter, search, take, page);

    getDescriptions = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchDescriptionsWithDetailedStatusFilter, search, take, page);

    getBarcodes = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchBarcodesWithDetailedStatusFilter, search, take, page);

    getITRConstraintTypeName = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintTypeName, search, take, page);

    getAssociatedComment = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchAssociatedComment, search, take, page);
    
    getAQVDContractor = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchAQVDContractor, search, take, page);
    
    getPLIActionBy = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchPLIActionBy, search, take, page);

    getITRConstraintComment = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintComment, search, take, page);

    getExceptionsComment = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchExceptionsComment, search, take, page);

    getO3ConstraintId = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchO3ConstraintId, search, take, page);

    getITRConstraintResponsibleUser = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintResponsibleUser, search, take, page);

    getITRConstraintResponsibleGroup = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintResponsibleGroup, search, take, page);

    getITRConstraintDescription = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintDescription, search, take, page);

    getITRConstraintStatus = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintStatus, search, take, page);

    getITRConstraintId = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintId, search, take, page);

    getITRConstraintAdditionalResponsibleUser = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintAdditionalResponsibleUser, search, take, page);

    getITRConstraintRaisedByUser = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintRaisedByUser, search, take, page);
    getDeliveryEngineer = (search = '', take=30, page =0) =>
    this.withLatestFilter(this.lookupService.searchDeliveryEngineerWithDetailedStatusFilter,search, take, page);

    getITRConstraintRaisedByTeam = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchITRConstraintRaisedByTeam, search, take, page);

    getFMRorPO = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchFMRorPO, search, take, page);

    getWaypointRequired = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchWaypointRequired, search, take, page);

    getAssociatedNPW = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchAssociatedNPW, search, take, page);

    getAssociatedPLI = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchAssociatedPLI, search, take, page);
    getAssociatedQVD = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchAssociatedQVD, search, take, page);
    getCriticalPath = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchCriticalPathWithDetailedStatusFilter, search, take, page);
    
    getPriorityNo = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchPriorityNoWithDetailedStatusFilter, search, take, page);

    getActivityID = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchActivityID, search, take, page);

    getActivityName = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchActivityName, search, take, page);

    getRFO = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchRFO, search, take, page);

    getWaypoint = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchWaypoint, search, take, page);

    getWaypointDescription = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchWaypointDescription, search, take, page);
    
    getAreaBreakdownValues = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchAreaBreakdownValues, search, take, page);

    getMilestone = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchMilestone, search, take, page);

    getMilestoneDescription = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchMilestoneDescription, search, take, page);

    getWorkAreaName = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchWorkAreaName, search, take, page);

    getWorkOrderNum = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchWorkOrderNum, search, take, page);
    
    getETransferNumber = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchETransferNumber, search, take, page);

    getPriority = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchPriority, search, take, page);
    
    getExceptionPriority = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchExceptionPriority, search, take, page);

    getSubsystemNames = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchSubsystemNamesWithDetailedStatusFilter, search, take, page);

    getSubsystems = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchSubsystemsWithDetailedStatusFilter, search, take, page);

    getOrigSubsystems = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchOriginalSubsystemsWithDetailedStatusFilter, search, take, page);

    getChangeIds = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchChangeIdsWithDetailedStatusFilter, search, take, page);

    getConstructionArea = (search = '', take = 30, page = 0) =>
        this.lookupService.searchConstructionArea(search, take, page, this.filterForm.value.projectTeamNames);
   
    getAreaBreakdown = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    getRFSUContractors = (search = '', take = 10, page = 0) =>
        this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames);

    getComments = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchCommentsWithDetailedStatusFilter, search, take, page);

    getNBResponsiplePersonComments = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchNBCommentsWithDetailedStatusFilter, search, take, page);
    getMaterialReferences = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchMaterialReferencesWithDetailedStatusFilter, search, take, page);

    getClassifications = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchClassificationWithDetailedStatusFilter, search, take, page);
    getAttachments = () => of(['Yes', 'No']);

    getExceptionNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchExceptionNumbersWithDetailedStatusFilter, search, take, page);

    getExceptionPhases = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchExceptionPhasesWithDetailedStatusFilter, search, take, page);

    getExceptionStatus = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchExceptionStatusWithDetailedStatusFilter, search, take, page);

    getPunchTypeValues = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchPunchTypeValuesWithDetailedStatusFilter, search, take, page);

    getReviewStatusComplete = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(
            this.lookupService.searchReviewStatusCompleteWithDetailedStatusFilter,
            search,
            take,
            page
        );

    getACNumber = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(
            this.lookupService.searchACNumberWithDetailedStatusFilter,
            search,
            take,
            page
        );

    getRaisedByValues = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchRaisedByValuesWithDetailedStatusFilter, search, take, page);
    getMaterialReviewStatusDetails = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchMaterialReviewStatusWithDetailedStatusFilter, search, take, page);
    getMaterialReviewAssignedToDetails = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchMaterialReviewAssignedToWithDetailedStatusFilter, search, take, page);

    getTagDisciplineValues = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchTagDisciplineValuesWithDetailedStatusFilter, search, take, page);

    getRedTypes = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchRedTypesWithDetailedStatusFilter, search, take, page);

    getRedlineExceptionNumber = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchRedlineExceptionNumberWithDetailedStatusFilter, search, take, page);

    getRedReqTypes = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchRedReqTypesWithDetailedStatusFilter, search, take, page);

    getRedStatuses = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchRedStatusesWithDetailedStatusFilter, search, take, page);

    getMcdrConstrRedCats = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchMcdrConstrRedCatsWithDetailedStatusFilter, search, take, page);

    getContainsChange = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchContainsChangeWithDetailedStatusFilter, search, take, page);

    getDocTypes = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchDocTypesWithDetailedStatusFilter, search, take, page);

    getDocType2s = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchDocType2sWithDetailedStatusFilter, search, take, page);

    getRevisions = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchRevisionsWithDetailedStatusFilter, search, take, page);

    getItrInCurrentWeek = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchItrInWeekWithDetailedStatusFilter, search, take, page);

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) =>
        this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((startUpPriorities: StagedStartUpPriority[]) => startUpPriorities));

    getItrConstraintFlag = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchItrConstraintFlag, search, take, page);

    getQVDNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchNumbersWithDetailedStatusFilter, search, take, page);
    
    getPostRFOTagNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchTagNumbersWithDetailedStatusFilter, search, take, page);

    getPlis = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchPlisWithDetailedStatusFilter, search, take, page);
        
    getNpws = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchNpwsWithDetailedStatusFilter, search, take, page);

    getPascrs = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchPascrsWithDetailedStatusFilter, search, take, page);

    getRedlines = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchRedlinesWithDetailedStatusFilter, search, take, page);
    
    getResponsiblePersonOrGroup = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchResponsiblePersonorGroupWithDetailedStatusFilter, search, take, page);

    getItemComments = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchItemCommentsWithDetailedStatusFilter, search, take, page);

    getManualPriorities = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchManualPrioritiesWithDetailedStatusFilter,search, take, page);

    openHeaderNumericFilter(property: string, currentState: NumericColumn, placeHolder: string, propertyName: string) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderNumericFilter>(HeaderNumericFilterComponent, 400, 350, {
                placeHolder: placeHolder,
                showBlankOptions: true,
                isAscendingSort: this.sortBy === property && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === property && this.direction == OrderDirection.Desc,
                minValue: currentState ? currentState.minValue : null,
                maxValue: currentState ? currentState.maxValue : null,
                excludeBlanks: currentState ? currentState.excludeBlanks : false,
                onlyBlanks: currentState ? currentState.onlyBlanks : false,
                onSumbit: (data: HeaderNumericFilter) => {
                    let newFilterState = new NumericColumn();
                    newFilterState = { ...data };

                    const columnName = propertyName;
                    if (
                        this.numericColumns.includes(columnName) &&
                        this.filterForm.controls.hasOwnProperty(columnName)
                    ) {
                        this.filterForm.controls[columnName].setValue(newFilterState);
                    }

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, property);
                    this.search();
                },
                onReset: () => {
                    const columnName = propertyName;
                    this.store.dispatch(
                        new DetailedStatusFilterPropertyUpdate({
                            key: columnName,
                            value: null,
                            scope: this.subsystemScopes[this.scope],
                        })
                    );
                    this.setFlagToDisableExcelExport(this.scope);
                },
            } as HeaderNumericFilter)
        );
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        isSortingOff: boolean = false,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected?.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected?.onlyBlanks;

        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(
                HeaderChecklistFilterComponent,
                columnName === 'description' ? 800 : 400,
                650,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    placeHolder: placeholder,
                    searchFunc: searchFunc,
                    selectedItems: selected ? [...selected.items] : [],
                    excludeBlanks: excludeBlanks,
                    onlyBlanks: onlyBlanks,
                    isSortingOff: isSortingOff,
                    showCounts,
                    showInputSearch: showInputSearch,
                    showBlankOptions: (this.scope == SubsystemScope.RFOWorkToGoSearch && (columnName=='areaBreakdown' || columnName=='contractor' || columnName=='barcode' || columnName == 'exceptionNumber' || columnName == 'exceptionPriority' || columnName == 'exceptionPhase')) ? true : showBlankOptions,
                    action: (data) => {
                        let value = new CheckListColumn();
                        value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                        value.excludeBlanks = data.excludeBlanks;
                        value.onlyBlanks = data.onlyBlanks;

                        this.filterForm.controls[propertyName]?.setValue(value);

                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                        this.search();
                    },
                    resetColumnAction: () => {
                        this.store.dispatch(
                            new DetailedStatusFilterPropertyUpdate({
                                key: propertyName,
                                value: null,
                                scope: this.subsystemScopes[this.scope],
                            })
                        );
                    },
                    cancelAction: () => {
                        this.store.dispatch(
                            new DetailedStatusFilterPropertyUpdate({
                                key: propertyName,
                                value: this.filterForm.controls[propertyName]?.value,
                                scope: this.subsystemScopes[this.scope],
                            })
                        );
                    },
                }
            )
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn,
        isSortingOff = false,
        fireSearch = true
    ) {
        const excludeBlanks =
            calendarColumn === null || calendarColumn == undefined ? false : calendarColumn?.excludeBlanks;
        const onlyBlanks = calendarColumn === null || calendarColumn == undefined ? false : calendarColumn?.onlyBlanks;
        const rangeDates = calendarColumn === null || calendarColumn == undefined ? [] : calendarColumn?.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(
                HeaderDateFilterComponent,
                345,
                600,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                    placeHolder: placeholder,
                    isSortingOff: isSortingOff,
                    action: (data) => {
                        this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                        if (fireSearch) {
                            this.search();
                        }
                    },
                },
                345,
                580
            )
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                    scope: this.subsystemScopes[this.scope],
                })
            );
        } else if(this.scope == SubsystemScope.RFOWorkToGoSearch){
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'rfo', direction: OrderDirection.Asc },
                    scope: this.subsystemScopes[this.scope],
                })
            );
        } else {
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'number', direction: OrderDirection.Desc },
                    scope: this.subsystemScopes[this.scope],
                })
            );
        }
    }

    openCommentPopup(element: DetailedStatusDTO, event: any) {
        const comment = element.latestComment || '';
        if (this.isReadOnly) return;
        if (this.scope === SubsystemScope.ConstraintDetails) {
            element.scope = this.scope;
            element.id = element.itrConstraintId;
        }
        if(this.scope === SubsystemScope.RFOWorkToGoSearch){
            element.scope = this.scope;
            element.number = element.barcode != null ?  element.barcode : element.pli != null ? element.pli : element.exceptionNumber != null ? element.exceptionNumber : element.pascr != null ? element.pascr : element.npw != null ? element.npw : element.redline;
    
        }
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: element.mentions,
                filteredCount: this.allowCommentsBulkUpdate ? this.resultsLength : 0,
                url: `details?scope=${this.subsystemScopes[element.scope]}&id=${element.scope === SubsystemScope.PLI || element.scope === SubsystemScope.COW
                        ? element.number
                        : element.id
                    }`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new DetailedStatusAddBulkCommentRequest({
                                description: value,
                                scope: this.scope === this.subsystemScopes.EX ? this.scope : element.scope,
                                mentions,
                            })
                        );
                    } else {
                        element.scope === SubsystemScope.RFOWorkToGoSearch ?
                        this.store.dispatch(
                            new DetailedStatusAddCommentRequest({
                                entityId:element.number,                                    
                                description: value,
                                scope: element.scope,
                                mentions,
                                subSystem : element.subsystem,
                                rfo : element.rfo,
                            })
                        )
                        : this.store.dispatch(
                            new DetailedStatusAddCommentRequest({
                                entityId:
                                    element.scope === SubsystemScope.PLI || element.scope === SubsystemScope.COW
                                        ? element.number
                                        : element.id.toString(),
                                description: value,
                                scope: element.scope,
                                mentions,
                            })
                        );
                    }
                },
            })
        );

        event.stopPropagation();
    }

    openNBResponsiblePersonCommentPopup(element: DetailedStatusDTO, event: any) {
        const comment = element.latestNBResponsiblePersonComment || '';
       if(element.scope === SubsystemScope.PLI) {
        if (!this.isNBResponsiblePerson) return;
       } else {
        if (!this.isITRResponsiblePerson) return;
       }    
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: element.mentions,
                filteredCount: this.allowCommentsBulkUpdate ? this.resultsLength : 0,
                url: `details?scope=${this.subsystemScopes[element.scope]}&id=${element.scope === SubsystemScope.PLI || element.scope === SubsystemScope.COW
                        ? element.number
                        : element.id
                    }`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new DetailedStatusAddBulkCommentRequest({
                                description: value,
                                scope: this.scope === this.subsystemScopes.EX ? this.scope : element.scope,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new DetailedStatusAddCommentRequest({
                                entityId:element.scope === SubsystemScope.PLI || element.scope === SubsystemScope.COW
                                ? element.number
                                : element.id.toString(),                                  
                                description: value,
                                scope: element.scope,
                                mentions,
                                nbResponsiblePerson: UserDefinedInputs.ResponsiblePerson
                            })
                        );
                    }
                },
            })
        );

        event.stopPropagation();
    }

    openExceptionCommentPopup(element: DetailedStatusDTO, event: any) {
        const comment = element.latestExceptionComment || '';
        if (this.isReadOnly) return;
        this.isLoading = true;
        this.detailedStatusService.getFilteredRecordsCount(element.exceptionNumber).subscribe((data:number)=>{
            this.isLoading = false;
            this.popupSvc.openPopup(
                new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                    comment,
                    mentions: element.mentions,
                    filteredCount: this.allowCommentsBulkUpdate ? this.resultsLength : data > 0 ? data : 0,
                    url: `details?scope=${this.subsystemScopes[element.scope]}&id=${element.scope === SubsystemScope.PLI
                            ? element.number
                            : element.id
                        }`,
                    action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                        if (bulk && this.allowCommentsBulkUpdate) {
                            this.store.dispatch(
                                new DetailedStatusAddBulkCommentRequest({
                                    description: value,
                                    scope:  element.scope,
                                    mentions,
                                })
                            );
                        }
                        else if(bulk && ! this.allowCommentsBulkUpdate)
                        {
                            this.store.dispatch(
                                new DetailedStatusAddBulkExceptionCommentRequest({
                                    description: value, 
                                    exceptionNumber: element.exceptionNumber, 
                                    mentions: mentions, 
                                    scope: this.scope
                                })
                            );
                        }                        
                        else {
                            var exscope = ExceptionScope.ITR;
                            switch(element.scope) {
                                case SubsystemScope.PLI:
                                    exscope = ExceptionScope.PLI;
                                    break;
                                case SubsystemScope.Redline:
                                    exscope = ExceptionScope.Redline;
                                    break;
                                case SubsystemScope.ChangeDocument:
                                    exscope = ExceptionScope.ChangeDocument;
                                    break;
                                default:
                                    exscope = ExceptionScope.ITR;
                                    break;
                            }

                            this.store.dispatch(
                                new DetailedStatusAddCommentRequest({
                                    entityId: element.exceptionNumber,
                                    description: value,
                                    scope: element.scope,
                                   mentions: mentions,
                                   additionaEntityId: element.scope === SubsystemScope.PLI 
                                   ? element.number
                                   : element.id.toString() ,
                                exscope: exscope //SubsystemScope.PLI ? ExceptionScope.PLI : ExceptionScope.ITR
    
                                })
                            );
                        }
                    },
                })
            );    
            
        });        
        event.stopPropagation();
    }

    openO3ConstraintPopup(element: DetailedStatusDTO, event: any) {
        const comment = element.o3ConstraintId || '';
        if (this.isReadOnly) return;

        var exscope = ExceptionScope.ITR;
        switch(element.scope) {
            case SubsystemScope.PLI:
                exscope = ExceptionScope.PLI;
                break;
            case SubsystemScope.Redline:
                exscope = ExceptionScope.Redline;
                break;
            case SubsystemScope.ChangeDocument:
                exscope = ExceptionScope.ChangeDocument;
                break;
            default:
                exscope = ExceptionScope.ITR;
                break;
        }

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                title: "O3 Constraint Id" ,
                mentions: element.mentions,
                filteredCount: this.allowCommentsBulkUpdate ? this.resultsLength : 0,
                url: `details?scope=${this.subsystemScopes[element.scope]}&id=${element.scope === SubsystemScope.PLI
                        ? element.number
                        : element.id
                    }`,
                action: (value: string, mentions: UserDetail[]) => {
                        this.store.dispatch(
                            new DetailedStatusAddO3ConstraintRequest({
                                tagNumber: element.tagNumber,
                                o3constraint: value,
                                scope: element.scope,
                                mentions: mentions,
                                exceptionNumber: element.exceptionNumber ,
                                pliqvdNumber: element.number,
                                subsystem: element.subsystem,
                                exscope: exscope //element.scope === SubsystemScope.PLI ? ExceptionScope.PLI : ExceptionScope.ITR
                            })
                        );
                },
            })
        );

        event.stopPropagation();
    }

    openUserdefinedPopup(element: DetailedStatusDTO, event: any, type: UserDefinedInputs) {
        event.stopPropagation();
        if(!this.isExceptionsTracking) {
            return;
        }
        let userDefinedPropertyName : string ;
        let userDefinedPropertyId : number ;
        if(type === UserDefinedInputs.ResponsiblePerson) {
            userDefinedPropertyName =    element.responsiblePerson || '';
            userDefinedPropertyId = element.responsiblePersonId  ;
        } else if(type === UserDefinedInputs.AdditionalResponsiblePerson) {
            userDefinedPropertyName = element.exceptionAdditionalResponsibleUser || '';
            userDefinedPropertyId = element.exceptionAdditionalResponsibleUserId ;
        }
        if (this.isReadOnly) return;
        let dialogRef =  this.popupSvc.openPopup(
            new PopupSettings(ResponsbilePersonSelectorComponent, Constants.popupWidth, Constants.popupHeight, {
                userDefinedPropertyName,
                userDefinedPropertyId,
                row: element,
                url: `details?scope=${this.subsystemScopes[element.scope]}&id=${element.scope === SubsystemScope.PLI || element.scope === SubsystemScope.COW
                        ? element.number
                        : element.id
                    }` ,
                type            
            })
        );
        dialogRef
        .afterClosed()
        .pipe(takeWhile(() => this.isAlive === true))
        .subscribe((result) => {
            if (result && result.element) {
               element = result.element;
            }
        });      
    }

    displaySelectedPriorities(priorities: StagedStartUpPriority[]) {
        return priorities.map((p) => p.priorityName).join(', ');
    }

    displaySelectedACM(acmIds: number[]) {
        return this.acms
            .filter((acm) => acmIds.includes(acm.id))
            .map((acm) => acm.name)
            .join(', ');
    }

    displaySelectedMCCompleted(mcCompleted: boolean) {
        if (mcCompleted === null) {
            return 'All';
        }
        return mcCompleted ? 'Yes' : 'No';
    }

    displaySelectedRFSUCompleted(rfsuCompleted: boolean) {
        if (rfsuCompleted === null) {
            return 'All';
        }
        return rfsuCompleted ? 'Yes' : 'No';
    }

    displaySelectedRFOType(rfoType: boolean) {
        if (rfoType === null) {
            return 'All';
        }
        return rfoType ? 'Yes' : 'No';
    }

    displaySelectedPrePostRFOCreated(prePostRFOCreatedItem: boolean) {
        if (prePostRFOCreatedItem === null) {
            return 'All';
        }
        return prePostRFOCreatedItem ? 'Yes' : 'No';
    }

    displaySelectedRequiredForSteamblow(requiredForSteamblow: boolean) {
        if (requiredForSteamblow === null) {
            return 'All';
        }
        return requiredForSteamblow ? 'Yes' : 'No';
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }
    displaySelectedDeliveryEngineer(deliveryEngineerIds: number[]) {
        return this.deliveryEngineers
            .filter((deliveryEngineer) => deliveryEngineerIds.includes(deliveryEngineer.id))
            .map((deliveryEngineer) => deliveryEngineer.name)
            .join(', ');
    }

    displayMultipleSelected(values: (Milestone | Waypoint | Contractor)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    displayMultipleSelectedwithProperty(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    displayExcludeExceptions(values: ExceptionType[]) {
        return values.map((x) => ExceptionType[x]).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else if (typeof this.filterForm.controls[propertyName].value === 'object') {
            this.filterForm.controls[propertyName].setValue(null);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
        this.store.dispatch(
            new DetailedStatusFilterPropertyUpdate({
                key: propertyName,
                value: [],
                scope: this.subsystemScopes[this.scope],
            })
        );
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction' || key === 'scope' || key === 'zone')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(
                    new DetailedStatusFilterPropertyUpdate({ key, value, scope: this.subsystemScopes[this.scope] })
                );
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.filterForm, key, value);
                }
            }
        }
    }

    search() {
        this.updateFilterByFormProperties();
        this.exportExcelAllow = true;
        if(!this.checkIfAnyFilterSelected())
        {
          this.setFlagToDisableExcelExport(this.scope);
        }
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new DetailedStatusFilterPropertyUpdate({
                key: 'page',
                value: 0,
                scope: this.subsystemScopes[this.scope],
            })
        );

        if (this.itrid > 0) {
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: 'columnItrId',
                    value: this.itrid,
                    scope: this.subsystemScopes[this.scope],
                })
            );
            this.scope = this.subsystemScopes.ConstraintDetails;
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: 'scope',
                    value: this.scope,
                    scope: this.subsystemScopes[this.scope],
                })
            );
        }
        this.store.dispatch(new DetailedStatusFilterRequest(this.subsystemScopes[this.scope]));
    }

    displayNumberColumnName() {
        switch (this.scope) {
            case this.subsystemScopes.PLI:
                return 'PLI';
            case this.subsystemScopes.EX:
                return 'Affected Item No';
            case this.subsystemScopes.RFSUWorkToGoSearch:
                return 'Work Item';
            case this.subsystemScopes.BITR:
                return 'B-ITR Number';
            case this.subsystemScopes.CITR:
                return 'C-ITR Number';
            case this.subsystemScopes.BITRandCITR:
                return 'ITR Number';
            case this.subsystemScopes.ConstraintDetails:
                return 'ITR Number';
            case this.subsystemScopes.Redline:
                return 'Doc No';
            case this.subsystemScopes.COW:
                return 'COW';
            default:
                return 'QVD Number';
        }
    }

    displayDescriptionColumnName() {
        switch (this.scope) {
            case this.subsystemScopes.PLI:
                return 'PLI Description';
            case this.subsystemScopes.EX:
                return 'Affected Item Description';
            case this.subsystemScopes.RFSUWorkToGoSearch:
                return 'Work item Description';
            case this.subsystemScopes.BITR:
                return 'B-ITR Description';
            case this.subsystemScopes.CITR:
                return 'C-ITR Description';
            case this.subsystemScopes.BITRandCITR:
                return 'ITR Description';
            case this.subsystemScopes.ConstraintDetails:
                return 'ITR Description';
            case this.subsystemScopes.Redline:
                return 'Title';
            case this.subsystemScopes.COW:
                return 'COW Description';
            default:
                return 'QVD Description';
        }
    }

    showCounts() {
        switch (this.scope) {
            case this.subsystemScopes.EX:
                return false;
            default:
                return true;
        }
    }

    showBlankOptions() {
        switch (this.scope) {
            case this.subsystemScopes.EX:
                return false;
            default:
                return true;
        }
    }

    onScopeClosed(isOpen: boolean) {
        if (!isOpen && this.currentExScope !== this.filterForm.controls.exScope.value) {
            this.currentExScope = this.filterForm.controls.exScope.value;
            this.search();
        }
    }

    compareExceptionTypes(r1: ExceptionType, r2: ExceptionType): boolean {
        return ExceptionType[r1] === ExceptionType[r2];
    }

    showHideColumns(initWidth: number) {
        if (this.lockFilters && this.showColumns.showSubsysName) {
            this.showColumns.showSubsysName = false;
            this.store.dispatch(
                new DetailedStatusFilterPropertyUpdate({
                    key: 'showHideColumns',
                    value: this.showColumns,
                    scope: this.subsystemScopes[this.scope],
                })
            );
        }
        if (this.scope === SubsystemScope.PLI) {
            return {
                left: `${initWidth -
                    202 -
                    +!this.showColumns.showSubsys * 190 -
                    +!this.showColumns.showSubsysName * 150 -
                    +!this.showColumns.showTagType * 110
                    }px`,
                width: `110px`,
            };
        } else if (this.scope === SubsystemScope.Redline) {
            return {
                left: `${initWidth - 317 - +!this.showColumns.showSubsys * 190}px`,
                width: `236px`,
            };
        } else if (this.scope === SubsystemScope.EX || this.scope === SubsystemScope.RFOWorkToGoSearch) {
            return;
        } else {
            return {
                left: `${initWidth -
                    +!this.showColumns.showSubsys * 190 -
                    +!this.showColumns.showSubsysName * 115 -
                    +!this.showColumns.showTagNo * 144
                    }px`,
            };
        }
    }

    reverseValue(columnName: string) {
        this.showColumns = {
            ...this.showColumns,
            [columnName]: !this.showColumns[columnName],
        };
        this.store.dispatch(
            new DetailedStatusFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showColumns,
                scope: this.subsystemScopes[this.scope],
            })
        );
        this.filterForm.controls.showHideColumns.setValue(this.showColumns);
    }

    openCoreworx(link: string, event$: MouseEvent) {
        window.open(link, '_blank');
        event$.stopPropagation();
    }

    private registerColumnHeaderFilters() {
        combineLatest([
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnCategory
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnCategory
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRevision
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRevision
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRedType
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRedType
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRedReqType
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRedReqType
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnChangeId
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnChangeId
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRedStatus
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRedStatus
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnContainsChange
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnContainsChange
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnMcdrConstrRedCat
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnMcdrConstrRedCat
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnDocType
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnDocType
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnDocType2
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnDocType2
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnTagNumber
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnTagNumber
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnTagType
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnTagType
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnTagName
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnTagName
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnNumber
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnNumber
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnCowDocNumber
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnCowDocNumber
            ),

            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnSubsystem
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnSubsystem
            ),

            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnOriginalSubsystem
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnOriginalSubsystem
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnSubsystemName
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnSubsystemName
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnContractor
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnContractor
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnStatus
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnStatus
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnMaterialReference
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnMaterialReference
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.ColumnAttachmentName
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.ColumnAttachmentName
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnClassification
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnClassification
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnActionBy
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnActionBy
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnDeliveryEngineer
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnDeliveryEngineer
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnPliImplementationTeam
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPliImplementationTeam
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnPunchType
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPunchType
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnReviewStatusComplete
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnReviewStatusComplete
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnACNumber
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnACNumber
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRaisedBy
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRaisedBy
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnTagDiscipline
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnTagDiscipline
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnCreatedDate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnCreatedDate
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnDescription
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnDescription
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnDiscipline
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnDiscipline
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnExceptionNumber
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnExceptionNumber
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnExceptionPriority
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnExceptionPriority
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnExceptionPhase
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnExceptionPhase
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnExceptionStatus
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnExceptionStatus
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnTargetCompletionDate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnTargetCompletionDate
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnLastConstraintDate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnLastConstraintDate
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnForecastClosureDate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnForecastClosureDate
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnComments
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnComments
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnNBResponsiblePersonComments
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnNBResponsiblePersonComments
              ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnBarcode
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnBarcode
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnPreCommStart
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPreCommStart
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRFOPlan
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRFOPlan
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnMCActual
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnMCActual
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRFSUActual
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRFSUActual
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnPreCommFinish
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPreCommFinish
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnStatusDate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnStatusDate
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnMcPlan
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnMcPlan
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnMcForecast
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnMcForecast
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRfsuPlan
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRfsuPlan
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRfsuForecast
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRfsuForecast
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRFSUWalkdownForecast
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRFSUWalkdownForecast
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnPriority
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPriority
            ),           
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnRFO
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRFO
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnWaypoint
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnWaypoint
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnWaypointDescription
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnWaypointDescription
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnAreaBreakdown
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnAreaBreakdown
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnWaypointTargetDate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnWaypointTargetDate
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnMilestone
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnMilestone
            ),            
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnMilestoneDescription
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnMilestoneDescription
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnMilestoneTargetDate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnMilestoneTargetDate
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnActivityID
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnActivityID
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnActivityName
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnActivityName
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnITRConstraintCount
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintCount
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnMaterialsTotalCount
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnMaterialsTotalCount
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnITRConstraintDescription
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintDescription
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnITRConstraintStatus
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintStatus
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnITRConstraintId
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintId
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnFMRorPO
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnFMRorPO
            ),
            // this.store.select((state) => 
            //     state.detailedStatusState.lockedFilter
            //         ? state.detailedStatusState.lockedFilter.columnWaypointRequired
            //         : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnWaypointRequired
            // ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnAssociatedNPW
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnAssociatedNPW
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnAssociatedPLI
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnAssociatedPLI
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnAssociatedQVD
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnAssociatedQVD
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnPriorityNo
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPriorityNo
            ),
            // this.store.select((state) =>
            //     state.detailedStatusState.lockedFilter
            //         ? state.detailedStatusState.lockedFilter.columnCriticalPath
            //         : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnCriticalPath
            // ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnITRConstraintTypeName
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintTypeName
            ),
            // this.store.select((state) =>
            //     state.detailedStatusState.lockedFilter
            //         ? state.detailedStatusState.lockedFilter.columnITRConstraintResponsibleUser
            //         : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintResponsibleUser
            // ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnITRConstraintResponsibleGroup
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintResponsibleGroup
            ),
            // this.store.select((state) =>
            //     state.detailedStatusState.lockedFilter
            //         ? state.detailedStatusState.lockedFilter.columnITRConstraintAdditionalResponsibleUser
            //         : state.detailedStatusState[this.subsystemScopes[this.scope]]
            //             ?.columnITRConstraintAdditionalResponsibleUser
            // ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnITRConstraintRaisedByUser
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintRaisedByUser
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnITRConstraintRaisedByTeam
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintRaisedByTeam
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnITRConstraintDueDate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintDueDate
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnFMRorPOETADate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnFMRorPOETADate
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnITRConstraintRaisedDate
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintRaisedDate
            ),
            // this.store.select((state) =>
            //     state.detailedStatusState.lockedFilter
            //         ? state.detailedStatusState.lockedFilter.columnITRConstraintStatusDate
            //         : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnITRConstraintStatusDate
            // ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnManhours
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnManhours
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnWorkAreaName
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnWorkAreaName
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnWorkOrderNum
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnWorkOrderNum
            ),
            
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnLatestITRComment
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnLatestITRComment
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnAssociatedComments
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnAssociatedComments
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnAQVDContractor
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnAQVDContractor
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.ColumnPLIActionedBy
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.ColumnPLIActionedBy
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnLatestExceptionComment
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnLatestExceptionComment
            ),
            // this.store.select((state) =>
            // state.detailedStatusState.lockedFilter
            //     ? state.detailedStatusState.lockedFilter.columnO3ConstraintID
            //     : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnO3ConstraintID
            // ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnItrInCurrentWeek
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnItrInCurrentWeek
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnConstraintFlag
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnConstraintFlag
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnPLIForecastClosureDate
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPLIForecastClosureDate
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnETA
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnETA
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnRequiredByDate
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRequiredByDate
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnPli
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPli
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnNpw
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnNpw
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnPascr
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPascr
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnRedline
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRedline
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnPostRFOWorkExpectedClosureDate
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPostRFOWorkExpectedClosureDate
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnRFOActual
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRFOActual
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnQVDNumber
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnQVDNumber
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnPliCreatedDate
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPliCreatedDate
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnNpwIssueDate
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnNpwIssueDate
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnItemCreatedDate
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnItemCreatedDate
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnResponsiblePersonOrGroup
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnResponsiblePersonOrGroup
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnItemComment
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnItemComment
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnPriorityManualOverride
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnPriorityManualOverride
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnRedlineExceptionNumber
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnRedlineExceptionNumber
            ),
            this.store.select((state) =>
            state.detailedStatusState.lockedFilter
                ? state.detailedStatusState.lockedFilter.columnETransferNumber
                : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnETransferNumber
            ),
            this.store.select((state) =>
                state.detailedStatusState.lockedFilter
                    ? state.detailedStatusState.lockedFilter.columnExpectedTimeOfCompletion
                    : state.detailedStatusState[this.subsystemScopes[this.scope]]?.columnExpectedTimeOfCompletion
                ),
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([
                    category,
                    revision,
                    redType,
                    redReqType,
                    changeId,
                    redStatus,
                    containsChange,
                    mcdr,
                    docType,
                    docType2,
                    tagNumber,
                    tagType,
                    tagName,
                    number,
                    cowDocNumber,
                    subsystem,
                    originalSubsystem,
                    subsystemName,
                    contractor,
                    status,
                    materialReference,  
                    attachmentName,                  
                    classification,
                    actionBy,
                    deliveryEngineer,
                    implementationTeam,
                    punchType,
                    reviewStatusComplete,
                    acNumber,
                    raisedBy,
                    tagDiscipline,
                    createdDate,
                    description,
                    discipline,                    
                    exceptionNumber,
                    exceptionPriority,
                    exceptionPhase,
                    exceptionStatus,
                    targetCompletionDate,
                    lastConstraintDate,
                    forecastClosureDate,
                    comments,
                    latestNBResponsiblePersonComment,
                    barcode,
                    preCommStart,
                    rfoplan,
                    mcActual,
                    rfsuActual,
                    preCommFinish,
                    statusDate,
                    mcPlan,
                    mcForecast,
                    rfsuPlan,
                    rfsuForecast,
                    rfsuWalkdownForecast,
                    priority,                    
                    rfo,                    
                    waypoint,
                    waypointDescription,
                    areaBreakdown,
                    waypointTargetDate,
                    milestone,
                    milestoneDescription,
                    milestoneTargetDate,
                    activityID,
                    activityName,
                    itrConstraintCount,
                    materialsTotalCount,
                    itrConstraintDescription,
                    itrConstraintStatus,
                    itrConstraintId,
                    fmRorPO,
                    // waypointRequired,
                    associatedNPW,                    
                    associatedPLI,
                    associatedQVD,
                    priorityNo,
                    // criticalPath,                    
                    itrConstraintTypeName,
                    // itrConstraintResponsibleUser,
                    itrConstraintResponsibleGroup,
                    // itrConstraintAdditionalResponsibleUser,
                    itrConstraintRaisedByUser,
                    iTRConstraintRaisedByTeam,
                    itrConstraintDueDate,
                    fmRorPOETADate,
                    itrConstraintRaisedDate,
                    // itrConstraintStatusDate,
                    manhours,
                    workAreaName,                   
                    workOrderNum,                   
                    latestITRComment,
                    associatedComment,
                    aqvdContractor,
                    pliActionedBy,
                    latestExceptionComment,
                    //o3ConstraintID,
                    itrInWeek,
                    constraintFlag,                  
                    pliForecastClosureDate,
                    eta,
                    requiredByDate,
                    pli,
                    npw,
                    pascr,
                    redline,
                    postRFOWorkExpectedClosureDate,
                    rfoActual,
                    qvdNumber,
                    pliCreatedDate,
                    npwIssueDate,
                    itemCreatedDate,
                    responsiblePersonOrGroup,
                    itemComment,
                    priorityManualOverride,
                    redlineExceptionNumber,
                    etransferNumber,
                    expectedTimeOfCompletion    
                ]) => {
                    this.columnCategory = category;
                    this.columnRevision = revision;
                    this.columnRedType = redType;
                    this.columnRedReqType = redReqType;
                    this.columnChangeId = changeId;
                    this.columnRedStatus = redStatus;
                    this.columnContainsChange = containsChange;
                    this.columnMcdrConstrRedCat = mcdr;
                    this.columnDocType = docType;
                    this.columnDocType2 = docType2;
                    this.columnTagNumber = tagNumber;
                    this.columnTagType = tagType;
                    this.columnTagName = tagName;
                    this.columnNumber = number;
                    this.columnCowDocNumber = cowDocNumber;
                    this.columnSubsystem = subsystem;
                    this.columnOriginalSubsystem = originalSubsystem;
                    this.columnSubsystemName = subsystemName;
                    this.columnContractor = contractor;
                    this.columnStatus = status;
                    this.columnMaterialReference = materialReference;
                    this.ColumnAttachmentName = attachmentName;
                    this.columnClassification = classification;
                    this.columnActionBy = actionBy;
                    this.columnDeliveryEngineer = deliveryEngineer;
                    this.columnPliImplementationTeam = implementationTeam;
                    this.columnPunchType = punchType;
                    this.columnReviewStatusComplete = reviewStatusComplete;
                    this.columnACNumber = acNumber;
                    this.columnRaisedBy = raisedBy;
                    this.columnTagDiscipline = tagDiscipline;
                    this.columnCreatedDate = createdDate;
                    this.columnDescription = description;
                    this.columnDiscipline = discipline;
                    this.columnExceptionNumber = exceptionNumber;
                    this.columnExceptionPriority = exceptionPriority;
                    this.columnExceptionPhase = exceptionPhase;
                    this.columnExceptionStatus = exceptionStatus;
                    this.columnTargetCompletionDate = targetCompletionDate;
                    this.columnLastConstraintDate = lastConstraintDate;
                    this.columnForecastClosureDate = forecastClosureDate;
                    this.columnComments = comments;
                    this.columnNBResponsiblePersonComments = latestNBResponsiblePersonComment;
                    this.columnBarcode = barcode;
                    this.columnPreCommStart = preCommStart;
                    this.columnRFOPlan =rfoplan;
                    this.columnMCActual = mcActual;
                    this.columnRFSUActual = rfsuActual;
                    this.columnPreCommFinish = preCommFinish;
                    this.columnStatusDate = statusDate;
                    this.columnMcPlan = mcPlan;
                    this.columnMcForecast = mcForecast;
                    this.columnRfsuPlan = rfsuPlan;
                    this.columnRfsuForecast = rfsuForecast;
                    this.columnRFSUWalkdownForecast = rfsuWalkdownForecast;
                    this.columnPriority = priority;
                    this.columnRFO = rfo;  
                    this.columnWaypoint = waypoint;  
                    this.columnWaypointDescription = waypointDescription;
                    this.columnAreaBreakdown = areaBreakdown;
                    this.columnWaypointTargetDate = waypointTargetDate;
                    this.columnMilestone = milestone;
                    this.columnMilestoneDescription = milestoneDescription;
                    this.columnMilestoneTargetDate = milestoneTargetDate;                
                    this.columnActivityID = activityID;
                    this.columnActivityName = activityName;
                    this.columnITRConstraintCount = itrConstraintCount;
                    this.columnMaterialsTotalCount =  materialsTotalCount;
                    this.columnITRConstraintDescription = itrConstraintDescription;
                    this.columnITRConstraintStatus = itrConstraintStatus;
                    this.columnITRConstraintId = itrConstraintId;
                    this.columnFMRorPO = fmRorPO;
                    // this.columnWaypointRequired = waypointRequired;
                    this.columnAssociatedNPW = associatedNPW;
                    this.columnAssociatedPLI = associatedPLI;
                    this.columnAssociatedQVD = associatedQVD;
                    this.columnPriorityNo = priorityNo;
                    // this.columnCriticalPath = criticalPath;
                    this.columnITRConstraintTypeName = itrConstraintTypeName;
                    //this.columnITRConstraintResponsibleUser = itrConstraintResponsibleUser;
                    this.columnITRConstraintResponsibleGroup = itrConstraintResponsibleGroup;
                    //this.columnITRConstraintAdditionalResponsibleUser = itrConstraintAdditionalResponsibleUser;
                    this.columnITRConstraintRaisedByUser = itrConstraintRaisedByUser;
                    this.columnITRConstraintRaisedByTeam = iTRConstraintRaisedByTeam;
                    this.columnITRConstraintDueDate = itrConstraintDueDate;
                    this.columnFMRorPOETADate = fmRorPOETADate;
                    this.columnITRConstraintRaisedDate = itrConstraintRaisedDate;
                    // this.columnITRConstraintStatusDate = itrConstraintStatusDate;
                    this.columnManhours = manhours;
                    this.columnWorkAreaName = workAreaName;
                    this.columnWorkOrderNum = workOrderNum;
                    this.columnLatestITRComment = latestITRComment;
                    this.columnAssociatedComments = associatedComment;
                    this.columnAQVDContractor = aqvdContractor;
                    this.ColumnPLIActionedBy = pliActionedBy;
                    this.columnLatestExceptionComment = latestExceptionComment;
                    //this.columnO3ConstraintID = o3ConstraintID;
                    this.columnItrInCurrentWeek = itrInWeek;
                    this.columnConstraintFlag = constraintFlag;
                    this.columnPLIForecastClosureDate = pliForecastClosureDate;
                    this.columnETA = eta;
                    this.columnRequiredByDate = requiredByDate;
                    this.columnPli = pli;
                    this.columnNpw = npw;
                    this.columnPascr = pascr;
                    this.columnRedline = redline;
                    this.columnPostRFOWorkExpectedClosureDate = postRFOWorkExpectedClosureDate;
                    this.columnRFOActual = rfoActual;
                    this.columnQVDNumber = qvdNumber;
                    this.columnPliCreatedDate = pliCreatedDate;
                    this.columnNpwIssueDate = npwIssueDate;
                    this.columnItemCreatedDate = itemCreatedDate;
                    this.columnResponsiblePersonOrGroup = responsiblePersonOrGroup;
                    this.columnItemComment = itemComment;
                    this.columnPriorityManualOverride = priorityManualOverride;
                    this.columnRedlineExceptionNumber = redlineExceptionNumber;
                    this.columnETransferNumber = etransferNumber;
                    this.columnExpectedTimeOfCompletion = expectedTimeOfCompletion;
                }
            );
    }

    download(attachment: AttachmentDTO, e) { 
        e.stopPropagation();
        e.preventDefault();

        if (attachment.link.includes('chevron.sharepoint')) {
            window.open(attachment.link, "_blank")
            return
        }

        this.uploadedAttachmentsActionInProgress.setValue(attachment.link, true);
        this.detailedStatusService
            .downloadAttachment(attachment.link, this.scope)
            .pipe(
                take(1),
                finalize(() => this.uploadedAttachmentsActionInProgress.setValue(attachment.link, false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, attachment.name);
            });
    }

    booleanYesNo(additionToPlan: boolean) {
        return additionToPlan ? 'Yes' : 'No';
    }

    private getContractorLabel(scope: SubsystemScope) {
        return scope === SubsystemScope.AQVD
            ? 'Contractor assigned to QVD'
            : scope === SubsystemScope.BITR ||
                scope === SubsystemScope.CITR ||
                SubsystemScope.BITRandCITR ||
                SubsystemScope.ConstraintDetails
                ? 'Contractor assigned to ITR'
                : 'Contractor';
    }

    enterConstraintEditMode(event: Event, element: DetailedStatusDTO) {
        event.stopPropagation();
        element.isConstraintEdit = true;
        element.prevConstraintFlag = element.constraintFlag;
    }

    saveConstraintUpdate(event: Event, element: DetailedStatusDTO) {
        event.stopPropagation();
        element.isConstraintEdit = false;
        if (element.constraintFlag !== element.prevConstraintFlag) {
            this.store.dispatch(
                new DetailsConstraintFlagUpdateRequest({
                    id: element.id,
                    constraintFlag: element.constraintFlag,
                } as DetailedStatusServiceDTO)
            );
        }
    }

    cancelConstraintUpdate(event: Event, element: DetailedStatusDTO) {
        event.stopPropagation();
        element.isConstraintEdit = false;
        element.constraintFlag = element.prevConstraintFlag;
    }

    stopEvent(event: any) {
        event.stopPropagation();
    }

    changeColumnDefaults() {
        this.showColumns.showStatus = false;
        this.showColumns.showContractor = false;
        this.showColumns.showDiscipline = false;
        this.store.dispatch(
            new DetailedStatusFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showColumns,
                scope: this.subsystemScopes[this.scope],
            })
        );
    }

    goBackToItrList() {
        this.scope = this.subsystemScopes.BITRandCITR;
        this.selectedITR = null;
        this.itrid = 0;
        this.changeColumnDefaults();
        this.store.pipe(take(1)).subscribe(() => {
            this.location.go('/detailedstatus?scope=BITRandCITR');
            this.store.dispatch(new DetailedStatusFilterRequest(this.subsystemScopes[this.scope]));
        });
    }

    OpenAllItrList() {
        this.scope = this.subsystemScopes.ConstraintDetails;
        this.selectedITR = null;
        this.itrid = 0;
        this.resetFilters();
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.changeColumnDefaults();
        this.store.dispatch(
            new DetailedStatusFilterPropertyUpdate({
                key: 'columnITRConstraintStatus',
                value: null,
                scope: this.subsystemScopes[this.scope],
            })
        );

        const filterInstance = getDetailedStatusFilterInstance(this.subsystemScopes[this.scope]);
        this.filterForm = this.formSvc.createSimpleForm(filterInstance);

        this.store.pipe(take(1)).subscribe(() => {
            this.location.go('/detailedstatus?scope=ConstraintDetails');
            this.store.dispatch(new DetailedStatusFilterRequest(this.subsystemScopes[this.scope]));
        });
    }

    goToITRConstraints(event: any, itr: DetailedStatusDTO = null) {
        if (itr != null) {
            localStorage.setItem(
                'selectedbarcodes',
                JSON.stringify(
                    this.listofSelectedBarcodes
                )
            );
            const selection = window.getSelection();
            if (selection.toString().length === 0) {
                const url = this.router.serializeUrl(
                    this.router.createUrlTree([`/detailedstatus`],
                        {
                            queryParams: {
                                scope: 'ConstraintDetails',
                                id: itr.id,
                            }
                        })
                );
                window.open(url, '_blank');
                event.stopPropagation();
            }
        }
    }

    goToPLIDetails(event: any, row: DetailedStatusDTO = null) {
        if(row.itrConstraintTypeName !== this.punchItemConstraintType) {
            return ;
        }
        if (row != null) {
        let selection = window.getSelection();
        if (selection.toString().length === 0) {
            this.ngZone.run(() =>
                this.router.navigate([`/details`], {
                    state: { data: { row, bySubsystem: this.lockFilters } },
                    queryParams: {
                        scope: this.subsystemScopes[SubsystemScope.PLI],
                        id: row.associatedPLI,
                        previousPageScope: this.scope,
                    },
                })
            );
        }
    }
    }

    highlightRow(event: any, row: DetailedStatusDTO) {
        this.selectedITR = row;
        let tagKey = new TagKeyDTO();
        tagKey.tagNo = row.barcode;
        tagKey.id = row.id
        if (this.listofSelectedBarcodes.find((item) => item.tagNo === row.barcode)) {
            this.listofSelectedBarcodes = this.listofSelectedBarcodes.filter((_, index) => _.tagNo !== tagKey.tagNo);
        } else {
            this.listofSelectedBarcodes.push(tagKey);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    isSelected(row: DetailedStatusDTO) {
        return this.listofSelectedBarcodes.find((item) => item.tagNo === row.barcode) !== undefined;
    }

    setFlagToDisableExcelExport(pageScope : SubsystemScope) {
        if(pageScope === SubsystemScope.AQVD || pageScope === SubsystemScope.BITR || pageScope === SubsystemScope.PLI )
        {
            this.exportExcelAllow = false;                
        }
        else{
            this.exportExcelAllow = true;       
        }
    }

    private checkIfAnyFilterSelected() : boolean {
        let returnResult : boolean = false;
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction' || key === 'scope' || key === 'zone')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(
                    new DetailedStatusFilterPropertyUpdate({ key, value, scope: this.subsystemScopes[this.scope] })
                );
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    returnResult = true;
                }
                if (
                    (key.indexOf('column') === -1 && typeof value == "boolean") || 
                    (value !=null  &&  (value.items !=null || value.items !=undefined) && value.items.length > 0 ) || 
                    (value !=null  && (value.rangeDates !=null || value.rangeDates !=undefined) && value.rangeDates.length>0) ||
                    (value !=null  && (value.excludeBlanks !=null || value.excludeBlanks !=undefined) && value.excludeBlanks === true) ||
                    (value !=null  && (value.onlyBlanks !=null || value.onlyBlanks !=undefined) && value.onlyBlanks === true) ||
                    (value !=null  && (value.minValue !=null || value.minValue !=undefined) && value.minValue >= 0) ||
                    (value !=null  && (value.maxValue !=null || value.maxValue !=undefined) && value.maxValue >= 0) 
                ) {
                    returnResult = true;
                }

            }
        }
        return returnResult;
    }

    resetColumns() {
        this.showColumns = new ShowHideColumns();
    }

    exceptionPriorityAccess(){
        let isValid = this.roleService.isInRole(Constants.applicableGroups.Admin) ||
        this.roleService.isInRole(Constants.applicableGroups.SystemOwners)
        ? true : false;
        return isValid;
    }

    updateExceptionPriority(change: MatSelectChange, detailedStatus: DetailedStatusDTO){
        detailedStatus.exceptionPriority = change.value;
        this.store.dispatch(
            new DetailedStatusAddExPriorityRequest({
                priority : detailedStatus              
            })
        );
    }

    stopeventProp(event: MouseEvent){
        event.stopPropagation();        
    }
    openHistoryPopup(element: DetailedStatusDTO) {
        var scope = element.scope;
        let pageScope : SubsystemScope =  this.scope;
        if (element.latestComment === null) {
            return;
        } else {
            this.isLoading = true;
            this.scope == SubsystemScope.RFOWorkToGoSearch ?
                this.commentService
                    .getRFOWorksToGoCommentsHistory(  
                        element.barcode !== null ? element.barcode : element.pli !== null ? element.pli : element.exceptionNumber !== null ? element.exceptionNumber : element.npw !== null ? element.npw : element.pascr !== null ? element.pascr : element.redline,  
                        element.rfo,
                        element.subsystem
                    )
                    .subscribe((data) => {
                    this.isLoading = false;
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            CommentsHistoryComponent,
                            null,
                            null,
                            {
                                commentHist: data,
                                action: (id: number) => {
                                    data = data.filter((s) => s.id !== id);
                                    this.store.dispatch(
                                        new DetailedStatusRemoveCommentRequest({
                                            id,
                                            lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                            entityId: element.barcode !== null ? element.barcode : element.pli !== null ? element.pli : element.exceptionNumber !== null ? element.exceptionNumber : element.npw !== null ? element.npw : element.pascr !== null ? element.pascr : element.redline,
                                            scope : pageScope,
                                            mainScope:pageScope
                                        })
                                    );
                                    this.isLoading = false;
                                },
                            },
                            200,
                            200
                        )
                    );
                })
            : this.commentService
                .getCommentsHistory(  
                    element.scope == SubsystemScope.PLI ? element.number.toString() : element.id.toString(),  
                    element.scope
                )
                .subscribe((data) => {
                    this.isLoading = false;
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            CommentsHistoryComponent,
                            null,
                            null,
                            {
                                commentHist: data,
                                action: (id: number) => {
                                    data = data.filter((s) => s.id !== id);
                                    this.store.dispatch(
                                        new DetailedStatusRemoveCommentRequest({
                                            id,
                                            lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                            entityId: this.itrid.toString(),
                                            scope,
                                            mainScope:pageScope
                                        })
                                    );
                                    this.isLoading = false;
                                },
                            },
                            200,
                            200
                        )
                    );
                });                             
        }
    }

    openItemCommentHistoryPopup(element: DetailedStatusDTO) {
        var scope = element.scope;
        let type = element.itemType;
        let pageScope : SubsystemScope =  this.scope;
        if (element.itemComment === null) {
            return;
        } else {
            this.isLoading = true;
            (element.itemType == 'ITR' || element.itemType == 'PLI') && (element.exceptionNumber == null || element.exceptionNumber == '') ?
                this.commentService
                    .getCommentsHistory(  
                        type == 'PLI' ? element.pli.toString() : element.itrId.toString(),  
                        type == 'PLI' ? SubsystemScope.PLI : SubsystemScope.BITR
                    )
                    .subscribe((data) => {
                        this.isLoading = false;
                        this.popupSvc.openPopup(
                            new PopupSettings(
                                CommentsHistoryWithoutDeleteComponent,
                                null,
                                null,
                                {
                                    commentHist: data,
                                },
                                200,
                                200
                            )
                        );
                    })
            : element.itemType  == 'Exception' || (element.exceptionNumber !== null && element.exceptionNumber !== '') ?
                this.commentService
                    .getExceptionCommentsHistory(  
                        element.exceptionNumber.toString(),
                        element.pli !== null ? element.pli.toString() : element.itrId !== null ? element.itrId.toString() : element.npwId !== null ? element.npwId.toString() : element.redlineId !== null ? element.redlineId.toString() : null ,  
                        element.pli !== null ? ExceptionScope.PLI : element.itrId !== null ? ExceptionScope.ITR : element.npwId !== null ? ExceptionScope.ChangeDocument : element.redlineId !== null ? ExceptionScope.Redline : null

                    )
                    .subscribe((data) => {
                        this.isLoading = false;
                        this.popupSvc.openPopup(
                            new PopupSettings(
                                CommentsHistoryWithoutDeleteComponent,
                                null,
                                null,
                                {
                                    commentHist: data,
                                },
                                200,
                                200
                            )
                        );
                    })
            : element.itemType  == 'PASCR' ?
                this.commentService
                    .getPascrComments(  
                        element.pascr.toString()
                    )
                    .subscribe((data) => {
                        this.isLoading = false;
                        this.popupSvc.openPopup(
                            new PopupSettings(
                                CommentsHistoryWithoutDeleteComponent,
                                null,
                                null,
                                {
                                    commentHist: data,
                                },
                                200,
                                200
                            )
                        );
                    }) 
            :  this.commentService  
                    .getComments(  
                        element.npwId !== null ? element.npwId.toString() : element.redlineId.toString(),
                        element.npwId !== null ? SubsystemScope.ChangeDocument : SubsystemScope.Redline
                    )
                    .subscribe((data) => {
                        this.isLoading = false;
                        this.popupSvc.openPopup(
                            new PopupSettings(
                                CommentsHistoryWithoutDeleteComponent,
                                null,
                                null,
                                {
                                    commentHist: data,
                                },
                                200,
                                200
                            )
                        );
                    }) ;                    
        }
    }

    pliImplementationTeamAcces()
    {
        let isValid = this.roleService.isInRole(Constants.applicableGroups.PLIImplementationTeamCOG)||
                        this.roleService.isInRole(Constants.applicableGroups.PLIImplementationTeamICT)
        ? true : false;
        return isValid;
    }

    updatePliImplementationTeam(change: MatSelectChange, id: string) {
        this.store.dispatch(
            new DetailedStatusUpdatePliImplementationTeamRequest({
                number: id,
                implementationTeam: change.value ?? ''           
            })
        );
    }

    openExceptionHistoryPopup(element: DetailedStatusDTO) {
        var exscope = ExceptionScope.ITR;
        switch(element.scope) {
            case SubsystemScope.PLI:
                exscope = ExceptionScope.PLI;
                break;
            case SubsystemScope.Redline:
                exscope = ExceptionScope.Redline;
                break;
            case SubsystemScope.ChangeDocument:
                exscope = ExceptionScope.ChangeDocument;
                break;
            default:
                exscope = ExceptionScope.ITR;
                break;
        }
        //var exscope = element.scope === SubsystemScope.PLI ? ExceptionScope.PLI : ExceptionScope.ITR ;
        if (element.latestExceptionComment === null) {
            return;
        } else {
            this.isLoading = true; 
            this.commentService
                .getExceptionCommentsHistory(element.exceptionNumber,
                    exscope == ExceptionScope.PLI ? element.number.toString() : element.id.toString(),  
                    exscope)
                .subscribe((data) => {
                    this.isLoading = false;
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            CommentsHistoryComponent,
                            null,
                            null,
                            {
                                commentHist: data,
                                action: (id: number) => {
                                    data = data.filter((s) => s.id !== id);
                                    this.store.dispatch(
                                        new DetailedStatusRemoveExceptionCommentRequest({
                                            id,
                                            lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                            exscope: exscope,
                                            mainScope:this.scope
                                        })
                                    );
                                    this.isLoading = false;
                                },
                            },
                            200,
                            200
                        )
                    );
                });                             
        }
    }

    openExceptionPDFPage(row: DetailedStatusDTO) {
        let URL = "https://chevron.sharepoint.com/teams/3PC-tco-3gp-sccert/Documents%20SITE/EXCEPTION/3GP%20Exception%20PDF/Exceptions_";
        return URL + row.exceptionNumber + ".pdf";
    }

    openPLIForecastClosureDatepicker(event: any ,element: DetailedStatusDTO, type: string) {
        event.stopPropagation();
        let date;
        if (this.isAdmin || this.isPliForecastClosureDateUpdateAllowed) {

            this.popupSvc.openPopup(
                new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                  selectedDate: date,
                  dateType: 'common',               
                  commonSaveAction: (d: moment.Moment) => {

                    this.saveDate(element, type,d);
                  },
                })
              );
        } else{
            return;
        }
    }

    openPostRFOWorkExpectedClosureDatepicker(event: any ,element: DetailedStatusDTO, type: string) {
        event.stopPropagation();
        let date;
        if (this.isAdmin || this.isPostRFOWorkExpectedClosureDateUpdateAllowed) {

            this.popupSvc.openPopup(
                new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                  selectedDate: date,
                  dateType: 'common',               
                  commonSaveAction: (d: moment.Moment) => {

                    this.saveDate(element, type,d);
                  },
                })
              );
        } else{
            return;
        }
    }

    forecastDateHistory(event: MouseEvent, element: DetailedStatusDTO, type: string) {
        event.preventDefault();
        event.stopPropagation();
        this.popupSvc.openPopup(
            new PopupSettings(ForecastHistoryComponent, 550, 500, {
                subsystem: element.subsystem,
                dateType: type,
                discipline: element.discipline,
                number: element.number,
            })
        );
    }

    expectedClosureDateHistory(event: MouseEvent, element: DetailedStatusDTO){
        event.preventDefault();
        event.stopPropagation();
        this.popupSvc.openPopup(
            new PopupSettings(ForecastHistoryComponent, 550, 500, {
                rfo: element.rfo,
                subsystem: element.subsystem,
                number: element.barcode != null ?  element.barcode : element.pli != null ? element.pli : element.exceptionNumber != null ? element.exceptionNumber : element.pascr != null ? element.pascr : element.npw != null ? element.npw : element.redline,
                dateType: "postRFOExpectedClosureDate",
            })
        );
    }

    exceptionForecastDateHistory(event: MouseEvent, element: DetailedStatusDTO, type: string) {
        event.preventDefault();
        event.stopPropagation();
        this.popupSvc.openPopup(
            new PopupSettings(ForecastHistoryComponent, 550, 500, {
                dateType: type,
                number: element.exceptionNumber,
                pliOrBarcode: element.number,
                itrId: element.id,
                scope: element.scope
            })
        );
    }

    scrollToDetails() {
        setTimeout(() => {
            this.detailedPage.nativeElement.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 500);
    }

    responsiblePersonOrGroupUpdateHistory(event: MouseEvent,element: DetailedStatusDTO) {
        event.preventDefault();
        this.popupSvc.openPopup(
            new PopupSettings(DataUpdateHistoryComponent, 700, 500, {
                itemNumber: element.barcode != null ?  element.barcode : element.pli != null ? element.pli : element.pascr != null ? element.pascr : element.npw != null ? element.npw : element.redline,
                exceptionNumber: element.exceptionNumber,
                rfo: element.rfo,
                subsystem:element.subsystem,
                postRFOData:element,
                recordUpdateType:'ResponsiblePersonOrGroup'
            })
        );
    }

    updateManualPriority(change: MatSelectChange, data: DetailedStatusDTO){
        const clonedData = _.cloneDeep(data);
        clonedData.priorityManualOverride = change.value;
        data.priorityManualOverride = change.value;
        this.store.dispatch(
            new DetailedStatusPostRFOWorkUpdateManualPriorityRequest({
                manualPriority: clonedData              
            })
        );
    }

    updateResponsiblePersonOrGroup(change: MatSelectChange, data: DetailedStatusDTO){
        const clonedData = _.cloneDeep(data);
        clonedData.responsiblePersonOrGroup = change.value;
        data.responsiblePersonOrGroup = change.value;
        this.store.dispatch(
            new DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest({
                responsiblePersonOrGroup: clonedData              
            })
        );
    }

    cloneElement(element: any): any {
        return _.cloneDeep(element);
    }

    openDetailedStatusExpectedTimeOfCompletionDatepicker(event: any ,element: DetailedStatusDTO, type: string) {
        event.stopPropagation();
        let date;
        if (this.isAdmin || !this.isAdmin) {

            this.popupSvc.openPopup(
                new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                  selectedDate: date,
                  dateType: 'common',               
                  commonSaveAction: (d: moment.Moment) => {

                    this.saveDate(element, type,d);
                  },
                })
              );
        } else{
            return;
        }
    }

    expectedTimeofCompletionHistory(event: MouseEvent,element: DetailedStatusDTO) {
        event.preventDefault();
        this.popupSvc.openPopup(
            new PopupSettings(DetailedStatusEtcHistoryComponent, 550, 500, {
                redlineId: element.id,
                scope: this.scope
            })
        );
    }

}
