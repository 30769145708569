import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { PlanningService } from 'src/app/services/api/webapi-services/reports/planning.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    PlanningActionTypes,
    PlanningLookaheadSuccess,
    PlanningLookaheadError,
    PlanningLookaheadRequest,
    PlanningCommitmentReliabilitySuccess,
    PlanningCommitmentReliabilityError,
    PlanningCommitmentReliabilityRequest,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { of } from 'rxjs';
import { PlanningFilter, PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT, WeeklyPlanningCommitmentDTO } from './model';
import { WeekType } from 'src/app/enums';

@Injectable()
export class PlanningEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private planningService: PlanningService,
        private toastService: ToastService
    ) {}

    @Effect()
    planningLookaheadRequest$ = this.actions$.pipe(
        ofType(PlanningActionTypes.PlanningLookaheadRequest),
        withLatestFrom(
            this.store.select((store) => _.head(store.planningState.lookahead.weeks)),
            this.store.select((store) => _.last(store.planningState.lookahead.weeks)),
            this.store.select((store) => store.planningState.lookahead.lookaheadType),
            this.store.select((store) => store.planningState.lookahead.filter)
        ),
        mergeMap(([, startDate, endDate, lookaheadType, filter]) => {
            const requestfilter = {
                ...filter,
                startDate: moment(startDate.date).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                endDate: moment(endDate.date).add(6, 'days').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
            } as PlanningFilter;
            return this.planningService.filterPlanningLookaheadData(lookaheadType, requestfilter).pipe(
                map((subsystems) => new PlanningLookaheadSuccess(subsystems)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting ${lookaheadType.toUpperCase()} Lookahead data. Please contact Program Administrator.`
                    );
                    return of(new PlanningLookaheadError(error));
                })
            );
        })
    );

    @Effect()
    requestOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(PlanningActionTypes.PlanningLookaheadSetWeekRange),
        map(() => new PlanningLookaheadRequest())
    );

    @Effect()
    planningCommitmentReliabilityRequest$ = this.actions$.pipe(
        ofType(PlanningActionTypes.PlanningCommitmentReliabilityRequest),
        withLatestFrom(
            this.store.select((store) => _.head(store.planningState.commitmentReliability.weeks)),
            this.store.select((store) => _.last(store.planningState.commitmentReliability.weeks)),
            this.store.select((store) => store.planningState.commitmentReliability.commitmentReliabilityType),
            this.store.select((store) => store.planningState.commitmentReliability.filter),
            this.store.select((store) => store.planningState.commitmentReliability.monthViewEnabled),
            this.store.select((store) =>
                _.head<{ date: Date; weekType: WeekType }>(
                    store.planningState.commitmentReliability.months
                )
            ),
            this.store.select((store) =>
                _.last<{ date: Date; weekType: WeekType }>(
                    store.planningState.commitmentReliability.months
                )
            ),
        ),
        mergeMap(([, startDate, endDate, commitmentReliabilityType, filter, monthlyViewEnabled, monthStartDate, monthEndDate]) => {
            const requestfilter = {
                ...filter,
                disciplines: filter.disciplines?.filter((d) => d && d.name).map((d) => d.name),
                startDate: monthlyViewEnabled ? moment(monthStartDate.date).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT) : moment(startDate.date).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                endDate: monthlyViewEnabled ? moment(monthEndDate.date).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT) : moment(endDate.date).add(6, 'days').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                
            } as PlanningFilter;
            return this.planningService
                .filterPlanningCommitmentReliabilityData(commitmentReliabilityType, requestfilter, monthlyViewEnabled)
                .pipe(
                    map((weeklyCommitment) => new PlanningCommitmentReliabilitySuccess(weeklyCommitment)),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while getting ${commitmentReliabilityType.toUpperCase()} Commitment Reliability data. Please contact Program Administrator.`
                        );
                        return of(new PlanningCommitmentReliabilityError(error));
                    })
                );
        })
    );

    @Effect()
    requestCommitmentReliabilityOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(PlanningActionTypes.PlanningCommitmentReliabilitySetWeekRange),
        map(() => new PlanningCommitmentReliabilityRequest())
    );

    @Effect()
    requestCommitmentReliabilityOnMonthRangeChanged$ = this.actions$.pipe(
        ofType(PlanningActionTypes.PlanningCommitmentReliabilitySetMonthRange),
        map(() => new PlanningCommitmentReliabilityRequest())
    );
}
