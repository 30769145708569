<main class="main">
    <form [formGroup]="filterForm" class="main-content">
        <div class="header-container">
            <div class="header-title">
                Trace Heating status
            </div>
            <div class="header-buttons">
                <button
                    type="button"
                    (click)="showPortal = true"
                    class="sct-button sct-button-light"
                    [style.width]="'100px'"
                >
                    print
                </button>
                <button
                    type="button"
                    (click)="exportToExcel()"
                    class="sct-button sct-button-light"
                    [style.width]="'100px'"
                    [style.margin-left]="'10px'"
                >
                    export
                </button>
            </div>
        </div>
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" expanded class="filter-panel-container">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div class="filter-panel-header-container">
                        <div class="filter-panel-header-content">
                            <div class="filter-by-button">
                                <img src="assets/images/icons/filter-blue.svg" />
                                filter by
                            </div>
                            <div *ngIf="!filterExpansionPanel.expanded">
                                <mat-chip-list aria-label="Active filters">
                                    <mat-basic-chip *ngIf="filterForm.controls.tdbSubsystems.value.length" removable>
                                        TDB Subsystems:
                                        {{ displayMultipleItemsSelected(filterForm.controls.tdbSubsystems.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('tdbSubsystems')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip
                                        *ngIf="filterForm.controls.processSubsystems.value.length"
                                        removable
                                    >
                                        Process Subsystems:
                                        {{ displayMultipleItemsSelected(filterForm.controls.processSubsystems.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('processSubsystems')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip>
                                        TDB MC Complete:
                                        {{ displaySelectedBoolean(filterForm.controls.isTdbMcComplete.value) }}
                                    </mat-basic-chip>
                                    <mat-basic-chip>
                                        All Process Mc's Complete on Circuit:
                                        {{
                                            displaySelectedBoolean(
                                                filterForm.controls.isAllProcessMcCompleteOnCircuit.value
                                            )
                                        }}
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.circuit.value.length" removable>
                                        Circuit: {{ displayMultipleItemsSelected(filterForm.controls.circuit.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('circuit')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip
                                        *ngIf="filterForm.controls.heatTrackingTapes.value.length"
                                        removable
                                    >
                                        Heat Tracking Tapes:
                                        {{ displayMultipleItemsSelected(filterForm.controls.heatTrackingTapes.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('heatTrackingTapes')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip
                                        *ngIf="filterForm.controls.tdbStagedPriority.value.length"
                                        removable
                                    >
                                        TDB Staged Start-up Priority:
                                        {{ displayStageUpPriorities(filterForm.controls.tdbStagedPriority.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('tdbStagedPriority')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip
                                        *ngIf="filterForm.controls.processStagedPriority.value.length"
                                        removable
                                    >
                                        Process Staged Start-up Priority:
                                        {{ displayStageUpPriorities(filterForm.controls.processStagedPriority.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('processStagedPriority')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                        <div class="filter-panel-header-buttons">
                            <button mat-button (click)="resetFilters($event)">Reset filters &times;</button>
                            <button mat-button class="light-blue" (click)="toggleExpansionPanel($event)">
                                {{ filterExpansionPanel.expanded ? 'Hide panel' : 'Show panel' }}
                            </button>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <div class="container-multiselector">
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container620"
                            [setInput]="setTdbSubsystemsInput"
                            formControlName="tdbSubsystems"
                            [searchFunc]="getTdbSubsystems"
                            [isAsync]="true"
                            [displayedColumns]="['id', 'name']"
                            [filterForm]="filterForm"
                            [formSetter]="'tdbSubsystems'"
                            [propertyToShow]="'id'"
                            [placeholder]="'TDB Subsystem'"
                            [panelWidth]="480"
                            [reloadOnOpen]="true"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container620 margin-right20"
                            [setInput]="setProcessSubsystemsInput"
                            formControlName="processSubsystems"
                            [searchFunc]="getProcessSubsystems"
                            [isAsync]="true"
                            [displayedColumns]="['id', 'name']"
                            [filterForm]="filterForm"
                            [formSetter]="'processSubsystems'"
                            [propertyToShow]="'id'"
                            [placeholder]="'Process Subsystem'"
                            [panelWidth]="480"
                            [reloadOnOpen]="true"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div class="container-multiselector">
                    <div class="container-radiobuttons margin-right20">
                        <mat-label class="title-radiobuttons">TDB MC Complete</mat-label>
                        <mat-radio-group formControlName="isTdbMcComplete" aria-label="TDB MC Complete">
                            <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                            <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                            <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="container-radiobuttons margin-left362">
                        <mat-label class="title-radiobuttons">All Process MC's Complete on Circuit</mat-label>
                        <mat-radio-group
                            formControlName="isAllProcessMcCompleteOnCircuit"
                            aria-label="All Process MC's Complete on Circuit"
                        >
                            <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                            <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                            <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="container-multiselector">
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container620"
                            [setInput]="setCircuit"
                            formControlName="circuit"
                            [searchFunc]="getCircuits"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'circuit'"
                            [placeholder]="'Circuit'"
                            [panelWidth]="480"
                            [reloadOnOpen]="true"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container620 margin-right20"
                            [setInput]="setHeatTrackingTapes"
                            formControlName="heatTrackingTapes"
                            [searchFunc]="getHeatTrackingTapes"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'heatTrackingTapes'"
                            [placeholder]="'Heat Tracking Tapes'"
                            [panelWidth]="480"
                            [reloadOnOpen]="true"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div class="container-multiselector">
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container620"
                            [setInput]="setTdbStagedPriority"
                            formControlName="tdbStagedPriority"
                            [searchFunc]="getStagedUpPriorities"
                            [isAsync]="true"
                            [displayedColumns]="['priority', 'priorityName']"
                            [filterForm]="filterForm"
                            [formSetter]="'tdbStagedPriority'"
                            [propertyToShow]="'priorityName'"
                            [placeholder]="'TDB Staged Start-up Priority'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'priority'"
                            [isTableAutoComplete]="true"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container620 margin-right20"
                            [setInput]="setProcessStagedPriority"
                            formControlName="processStagedPriority"
                            [searchFunc]="getStagedUpPriorities"
                            [isAsync]="true"
                            [displayedColumns]="['priority', 'priorityName']"
                            [filterForm]="filterForm"
                            [formSetter]="'processStagedPriority'"
                            [propertyToShow]="'priorityName'"
                            [placeholder]="'Process Staged Start-up Priority'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'priority'"
                            [isTableAutoComplete]="true"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div class="search-btn-container">
                    <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div *ngIf="isAdmin" class="search-btn-container">
            <button type="button" (click)="openPopupUploadModelShots()" class="sct-button sct-button-light sct-button-upload">upload model shots</button>
        </div>
        <app-trace-heating-status-table #table [dataSource]="traceHeatingStatusDataSource"></app-trace-heating-status-table>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input
                type="number"
                [value]="this.paginator.pageIndex + 1"
                (change)="onPageChange($event.target.value)"
            />
        </div>
    </form>
</main>
<app-trace-heating-status-print (close)="onPrintWindowClose()" *ngIf="showPortal"></app-trace-heating-status-print>