

import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { ActivityBarcodeDeleteFilter, ActivityBarcodeDeletePagination, ActivityBarcodeState } from './model';
import { ActivityBarcodeActions, ActivityBarcodeActionTypes } from './actions';

const initialState: ActivityBarcodeState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new ActivityBarcodeDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new ActivityBarcodeDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: ActivityBarcodeActions): ActivityBarcodeState {
    switch (action.type) {
        case ActivityBarcodeActionTypes.ActivityBarcodeUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodePatchAllDeleteRecordsRequest:
        case ActivityBarcodeActionTypes.ActivityBarcodeDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeDeleteRecordsSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeValidateDataError:
        case ActivityBarcodeActionTypes.ActivityBarcodePatchAllDeleteRecordsError:
        case ActivityBarcodeActionTypes.ActivityBarcodePatchDeleteRecordError:
        case ActivityBarcodeActionTypes.ActivityBarcodePatchDeleteRecordSuccess:
        case ActivityBarcodeActionTypes.ActivityBarcodeDeleteRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeDeleteFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        [action.payload.key]: Array.isArray(state.deleteFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeDeleteFilterReset: {
            const newFilter = new ActivityBarcodeDeleteFilter();
            return {
                ...state,
                deleteFilter: { ...newFilter },
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodePatchDeleteRecordRequest: {
            var itemIndex = state.deleteData.items.findIndex((d) => d.barcode === action.payload.barcode);
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                deleteState: action.payload.deleteState,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodePatchAllDeleteRecordsSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item) => ({ ...item, deleteState: action.payload.deleteState })),
                },
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeValidateDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeValidateDataSuccess: {
            return {
                ...state,
                deleteData: new ActivityBarcodeDeletePagination(),
                isDeleteDataLoading: false,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new ActivityBarcodeDeletePagination(),
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeDownloadOutputDataRequest:
        case ActivityBarcodeActionTypes.ActivityBarcodeTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeTemplateFileError:
        case ActivityBarcodeActionTypes.ActivityBarcodeDownloadOutputDataSuccess:
        case ActivityBarcodeActionTypes.ActivityBarcodeDownloadOutputDataError:
        case ActivityBarcodeActionTypes.ActivityBarcodeTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case ActivityBarcodeActionTypes.ActivityBarcodeAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'ActivityBarcode';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

