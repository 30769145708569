import { Injectable } from '@angular/core';
import { BlueZoneFilter, BlueZoneDataPagination, BlueZoneDTO } from 'src/app/store/bluezone/model';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { PhaseType } from 'src/app/enums';

@Injectable({
    providedIn: 'root',
})
export class BlueZoneService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly blueZoneEndpoint: string = '/bluezone';

    constructor(private http: HttpClient) {}

    getRedZoneData(filter: BlueZoneFilter) {
        return this.http.post<BlueZoneDataPagination>(`${this.rootUrl}${this.blueZoneEndpoint}`, filter);
    }

    updateRFSUForecastDate(
        subsystem: string,
        type: PhaseType,
        rfsuForecastDate: moment.Moment,
        currentWeek: string,
        discipline: string,
        allDisciplines = false
    ) {
        var formdata = new FormData();
        formdata.append('subsystem', subsystem);
        return this.http.patch(
            `${this.rootUrl}${this.blueZoneEndpoint}${
                rfsuForecastDate != null ? '/' + rfsuForecastDate.toJSON() : ''
            }?currentWeek=${currentWeek}&type=${type}&discipline=${discipline}&allDisciplines=${allDisciplines}`,
            formdata
        );
    }

    updateBacklogCat(element: BlueZoneDTO) {
        return this.http.post(`${this.rootUrl}${this.blueZoneEndpoint}/backlogCat`, element);
    }

    generateExcel(filter: BlueZoneFilter) {
        return this.http.post(`${this.rootUrl}${this.blueZoneEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    generateOnePager(filter: BlueZoneFilter) {
        return this.http.post(`${this.rootUrl}${this.blueZoneEndpoint}/onepager`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    flagSubsystem(subsystem: string, color: string) {
        var formdata = new FormData();
        formdata.append('subsystem', subsystem);
        return this.http.patch(`${this.rootUrl}${this.blueZoneEndpoint}/flagsubsystem/${color}`, formdata);
    }

    clearSubystemFlag(filter: BlueZoneFilter) {
        return this.http.post(`${this.rootUrl}${this.blueZoneEndpoint}/clearflag`, filter);
    }

    toggleWalkdownCommitment(subsystem: string, committed: boolean) {
        var formdata = new FormData();
        formdata.append('subsystem', subsystem);
        formdata.append('committed', `${committed}`);
        return this.http.patch(`${this.rootUrl}${this.blueZoneEndpoint}/toggleWalkdownCommitment`, formdata);
    }
}
