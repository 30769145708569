import { Action } from '@ngrx/store';
import { FieldChangeDocumentSummaryDTO } from './model';

export enum FieldChangeSummaryActionType {
    FieldChangeSummaryRequest = '[Field Change Summary] Summary Request',
    FieldChangeSummarySuccess = '[Field Change Summary] Summary Success',
    FieldChangeSummaryError = '[Field Change Summary] Summary Error',
}

export class FieldChangeSummaryRequest implements Action {
    readonly type = FieldChangeSummaryActionType.FieldChangeSummaryRequest;

    constructor(public payload: string) {}
}

export class FieldChangeSummarySuccess implements Action {
    readonly type = FieldChangeSummaryActionType.FieldChangeSummarySuccess;

    constructor(public payload: FieldChangeDocumentSummaryDTO) {}
}

export class FieldChangeSummaryError implements Action {
    readonly type = FieldChangeSummaryActionType.FieldChangeSummaryError;

    constructor(public payload: string) {}
}

export type FieldChangeSummaryAction = FieldChangeSummaryRequest | FieldChangeSummarySuccess | FieldChangeSummaryError;
