import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    SystemisedDrawingActionTypes,
    SystemisedDrawingFilterSuccess,
    SystemisedDrawingFilterError,
    SystemisedDrawingExportToExcelSuccess,
    SystemisedDrawingExportToExcelError,
    SystemisedDrawingFilterRequest,
} from './actions';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { SystemisedDrawingService } from 'src/app/services/api/webapi-services/systemised-drawing.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Injectable()
export class SystemisedDrawingEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private systemisedDrawingService: SystemisedDrawingService
    ) {}

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<SystemisedDrawingFilterRequest>(SystemisedDrawingActionTypes.SystemisedDrawingFilterRequest),
        withLatestFrom(this.store.select((store) => store.systemisedDrawingState.filter)),
        mergeMap(([action, systemisedDrawingFilter]) => {
            let filter = action.payload ?? systemisedDrawingFilter;
            return this.systemisedDrawingService.searchSystemisedDrawing(filter).pipe(
                map((dataPagination) => {
                    return new SystemisedDrawingFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering systemised drawings. Please contact Program Administrator.'
                    );
                    return of(new SystemisedDrawingFilterError(error));
                })
            );
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(SystemisedDrawingActionTypes.SystemisedDrawingExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.systemisedDrawingState.filter)),
        mergeMap(([, systemisedDrawingFilter]) => {
            const filter = {
                ...systemisedDrawingFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.systemisedDrawingService.generateExcel(systemisedDrawingFilter).pipe(
                map((excelData) => new SystemisedDrawingExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new SystemisedDrawingExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(SystemisedDrawingActionTypes.SystemisedDrawingExportToExcelSuccess),
        map((action: SystemisedDrawingExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `SystemisedDrawing_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );
}
