import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import {
    FlangeManagementActionTypes,
    FlangeManagementUploadLogSuccess,
    FlangeManagementUploadLogError,
    FlangeManagementSetStatusAndStartDate,
    FlangeManagementValidateDeltaSuccess,
    FlangeManagementValidateDeltaError,
    FlangeManagementClearInProgressSpinner,
    FlangeManagementTemplateFileRequest,
    FlangeManagementTemplateFileSuccess,
    FlangeManagementTemplateFileError,
    FlangeManagementDownloadOutputDataRequest,
    FlangeManagementDownloadOutputDataSuccess,
    FlangeManagementDownloadOutputDataError,
    FlangeManagementValidateButtonStateSuccess,
    FlangeManagementValidateButtonStateError,
    FlangeManagementValidateButtonStateRequest,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class FlangeManagementEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(FlangeManagementActionTypes.FlangeManagementUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['FlangeManagement']).pipe(
                map((uploadLogData) => new FlangeManagementUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new FlangeManagementUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<FlangeManagementUploadLogSuccess>(FlangeManagementActionTypes.FlangeManagementUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.flangeManagementState.uploadLogStatus),
            this.store.select((store) => store.flangeManagementState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new FlangeManagementSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    invokeDeltaRequest$ = this.actions$.pipe(
        ofType(FlangeManagementActionTypes.FlangeManagementSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.flangeManagementState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        map(() => new FlangeManagementValidateButtonStateRequest())
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(FlangeManagementActionTypes.FlangeManagementValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateFlangeManagementDelta().pipe(
                map(() => new FlangeManagementValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new FlangeManagementValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(FlangeManagementActionTypes.FlangeManagementValidateButtonStateRequest),
        withLatestFrom(this.store.select((store) => store.flangeManagementState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new FlangeManagementClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<FlangeManagementTemplateFileRequest>(FlangeManagementActionTypes.FlangeManagementTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new FlangeManagementTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Flange Management structure file. Please contact Program Administrator'
                    );
                    return of(new FlangeManagementTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<FlangeManagementTemplateFileSuccess>(FlangeManagementActionTypes.FlangeManagementTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'Flange Management.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<FlangeManagementDownloadOutputDataRequest>(FlangeManagementActionTypes.FlangeManagementDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new FlangeManagementDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new FlangeManagementDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<FlangeManagementDownloadOutputDataSuccess>(FlangeManagementActionTypes.FlangeManagementDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(FlangeManagementActionTypes.FlangeManagementValidateButtonStateRequest),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('FlangeManagement').pipe(
                map((response: any) => new FlangeManagementValidateButtonStateSuccess(response)),
                catchError((error) => of(new FlangeManagementValidateButtonStateError(error)))
            )
        )
    );
}
