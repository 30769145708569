

import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { CriticalPathDeleteFilter, CriticalPathDeletePagination, CriticalPathState } from './model';
import { CriticalPathActions, CriticalPathActionTypes } from './actions';

const initialState: CriticalPathState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new CriticalPathDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new CriticalPathDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: CriticalPathActions): CriticalPathState {
    switch (action.type) {
        case CriticalPathActionTypes.CriticalPathUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case CriticalPathActionTypes.CriticalPathUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case CriticalPathActionTypes.CriticalPathUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case CriticalPathActionTypes.CriticalPathPatchAllDeleteRecordsRequest:
        case CriticalPathActionTypes.CriticalPathDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case CriticalPathActionTypes.CriticalPathDeleteRecordsSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case CriticalPathActionTypes.CriticalPathValidateDataError:
        case CriticalPathActionTypes.CriticalPathPatchAllDeleteRecordsError:
        case CriticalPathActionTypes.CriticalPathPatchDeleteRecordError:
        case CriticalPathActionTypes.CriticalPathPatchDeleteRecordSuccess:
        case CriticalPathActionTypes.CriticalPathDeleteRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case CriticalPathActionTypes.CriticalPathSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case CriticalPathActionTypes.CriticalPathDeleteFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        [action.payload.key]: Array.isArray(state.deleteFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case CriticalPathActionTypes.CriticalPathDeleteFilterReset: {
            const newFilter = new CriticalPathDeleteFilter();
            return {
                ...state,
                deleteFilter: { ...newFilter },
            };
        }
        case CriticalPathActionTypes.CriticalPathPatchDeleteRecordRequest: {
            let itemIndex = state.deleteData.items.findIndex((d) => d.activityId === action.payload.activityId);
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                deleteState: action.payload.deleteState,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case CriticalPathActionTypes.CriticalPathPatchAllDeleteRecordsSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item) => ({ ...item, deleteState: action.payload.deleteState })),
                },
            };
        }
        case CriticalPathActionTypes.CriticalPathValidateDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case CriticalPathActionTypes.CriticalPathValidateDataSuccess: {
            return {
                ...state,
                deleteData: new CriticalPathDeletePagination(),
                isDeleteDataLoading: false,
            };
        }
        case CriticalPathActionTypes.CriticalPathFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new CriticalPathDeletePagination(),
            };
        }
        case CriticalPathActionTypes.CriticalPathClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case CriticalPathActionTypes.CriticalPathDownloadOutputDataRequest:
        case CriticalPathActionTypes.CriticalPathTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CriticalPathActionTypes.CriticalPathTemplateFileError:
        case CriticalPathActionTypes.CriticalPathDownloadOutputDataSuccess:
        case CriticalPathActionTypes.CriticalPathDownloadOutputDataError:
        case CriticalPathActionTypes.CriticalPathTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CriticalPathActionTypes.CriticalPathValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case CriticalPathActionTypes.CriticalPathAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'CriticalPath';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

