export class ContractorMappingDTO {
    id: number;
    changeType: string;
    projectTeamName: string;
    contractNo: string;
    mapping: string;
    isInEditMode: boolean;
    prevContractNo: string;
    prevChangeType: string;
    prevMapping: string;
    prevProjectTeamName: string;
}
