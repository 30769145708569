/* tslint:disable:max-line-length */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RedZoneComponent } from './components/redzone/redzone.component';
import { NotAuthorizedComponent } from './components/error/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { UserSynchronizationComponent } from './components/user-synchronization/user-synchronization.component';
import { DetailedStatusComponent } from './components/detailed-status/detailed-status.component';
import { DetailsComponent } from './components/details/details.component';
import { ChangeManagementComponent } from './components/change-management/change-management.component';
import { BlueZoneComponent } from './components/bluezone/bluezone.component';
import { ChangeDocumentComponent } from './components/admin-section/change-document/change-document.component';
import { DashboardComponent } from './components/admin-section/dashboard/dashboard.component';
import { AdminComponent } from './components/admin-section/admin.component';
import { ManualUploadComponent } from './components/admin-section/manual-upload/manual-upload.component';
import { MasterSubsystemListComponent } from './components/admin-section/manual-upload/master-subsystem-list/master-subsystem-list.component';
import { CWPRegisterComponent } from './components/admin-section/manual-upload/cwp-register/cwp-register.component';
import { FlangeManagementComponent } from './components/admin-section/manual-upload/flange-management/flange-management.component';
import { RedlineComponent } from './components/admin-section/manual-upload/redline/redline.component';
import { SubsystemConflictsComponent } from './components/admin-section/subsystem-conflicts/subsystem-conflicts.component';
import { ChangeDocumentDetailsComponent } from './components/change-document-details/change-document-details.component';
import { IsAdminGuard } from 'src/guards/is-admin-guard';
import { IsInGroupGuard } from 'src/guards/is-in-group-guard';
import { AutomaticUploadComponent } from './components/admin-section/automatic-upload/automatic-upload.component';
import { ExceptionComponent } from './components/admin-section/automatic-upload/exceptions/exceptions.component';
import { ITRComponent } from './components/admin-section/automatic-upload/itr/itr.component';
import { PLIComponent } from './components/admin-section/automatic-upload/pli/pli.component';
import { TagComponent } from './components/admin-section/automatic-upload/tag/tag.component';
import { CertificateComponent } from './components/admin-section/automatic-upload/certificate/certificate.component';
import { IsNotGathering } from 'src/guards/is-not-gathering';
import { FieldChangeSummaryComponent } from './components/change-management/field-change-summary/field-change-summary.component';
import { WeeklyPlanningComponent } from './components/weekly-planning/weekly-planning.component';
import { WeeklyPlanningDashboardComponent } from './components/weekly-planning/dashboard/weekly-planning-dashboard.component';
import { WeeklyPlanningSessionComponent } from './components/weekly-planning/session/weekly-planning-session.component';
import { WeeklyPlanningSessionMCComponent } from './components/weekly-planning/session/mc/weekly-planning-session-mc.component';
import { WeeklyPlanningSessionRFSUComponent } from './components/weekly-planning/session/rfsu/weekly-planning-session-rfsu.component';
import { ChangeValidationComponent } from './components/change-validation/change-validation.component';
import { ValidationFormComponent } from './components/validation-form/validation-form.component';
import { DataMaintenanceComponent } from './components/admin-section/data-maintenance/data-maintenance.component';
import { TeamDataComponent } from './components/admin-section/data-maintenance/team-data/team-data.component';
import { IsPlanningGroupGuard } from 'src/guards/is-planning-group.guard';
import { WeeklyPlanningCommitmentsComponenet } from './components/weekly-planning/commitments/weekly-planning-commitments.component';
import { SubsystemMaintenanceComponent } from './components/admin-section/data-maintenance/subsystem-data/subsystem-maintenance.component';
import { WeeklyPlanningDailyComponent } from './components/weekly-planning/daily/weekly-planning-daily.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportsDashboardComponent } from './components/reports/dashboard/reports-dashboard.component';
import { ReportsPlanningLookaheadComponent } from './components/reports/planning/lookahead/reports-planning-lookahead.component';
import { ReportsPlanningLookaheadContentComponent } from './components/reports/planning/lookahead/content/reports-planning-lookahead-content.component';
import { ReportsRundownComponent } from './components/reports/rundown/reports-rundown.component';
import { ReportsRundownContentComponent } from './components/reports/rundown/content/reports-rundown-content.component';
import { ReportsPlanningCommitmentReliabilityComponent } from './components/reports/planning/commitment-reliability/reports-planning-commitment-reliability.component';
import { ReportsPlanningCommitmentReliabilityContentComponent } from './components/reports/planning/commitment-reliability/content/reports-planning-commitment-reliability-content.component';
import { ConfigurationComponent } from './components/admin-section/scmt/configuration.component';
import { IcicleReportComponent } from './components/reports/icicle/icicle-report.component';
import { IsValidatorRole } from 'src/guards/is-validator-role';
import { ContractorsMappingsComponent } from './components/admin-section/change-document/contractors-mappings-component/contractors-mappings.component';
import { ReportsHealthCleanlinessComponent } from './components/reports/health/cleanliness/reports-health-cleanliness.component';
import { ProjectTeamMaintenanceComponent } from './components/admin-section/data-maintenance/project-team-data/project-team-maintenance.component';
import { MilestoneWaypointComponent } from './components/admin-section/data-maintenance/milestone-waypoint/milestone-waypoint.component';
import { ReportsHealthCleanlinessContentComponent } from './components/reports/health/cleanliness/content/reports-health-cleanliness-content.component';
import { TagSearchComponent } from './components/tag-search/tag-search.component';
import { IwpListingComponent } from './components/admin-section/manual-upload/iwp-listing/iwp-listing.component';
import { ChangeValidationDashboardComponent } from './components/reports/change-validation-dashboard/change-validation-dashboard.component';
import { MilestoneDashboardComponent } from './components/reports/milestone-dashboard/milestone-dashboard.component';
import { MCPlusComponent } from './components/admin-section/change-document/mcplus/mcplus.component';
import { ReportsSkylineComponent } from './components/reports/skyline/reports-skyline.component';
import { RFOComponent } from './components/rfo/rfo.component';
import { SkylineSimpleComponent } from './components/reports/skyline/simple/skyline-simple.component';
import { RFOImportComponent } from './components/admin-section/manual-upload/rfo/rfo.component';
import { MCMilestoneDashboardComponent } from './components/reports/milestone-dashboard/mc/mc-milestone-dashboard.component';
import { RFOMilestoneDashboardComponent } from './components/reports/milestone-dashboard/rfo/rfo-milestone-dashboard.component';
import { UserToContractsComponent } from './components/admin-section/data-maintenance/user-to-contracts/user-to-contracts.component';
import { IMDBComponent } from './components/admin-section/automatic-upload/imdb/imdb.component';
import { WeeklyPlanningSessionRFOComponent } from './components/weekly-planning/session/rfo/weekly-planning-session-rfo.component';
import { GOOC1Component } from './components/reports/milestone-dashboard/gooc1/gooc1.component';
import { MyPageComponent } from './components/my-page/my-page.component';
import { ExportPageComponent } from './components/export-page/export-page.component';
import { SystemizedDrawingsComponent } from './components/admin-section/manual-upload/systemized-drawings/systemized-drawings.component';
import { SubsystemMCDashboardComponent } from './components/reports/milestone-dashboard/subsystem-mc-dashboard/subsystem-mc-dashboard.component';
import { LoopComponent } from './components/admin-section/automatic-upload/loop/loop.component';
import { LoopStatusComponent } from './components/loop-status/loop-status.component';
import { SystemisedDrawingComponent } from './components/systemised-drawing/systemised-drawing.component';
import { GOOC2Component } from './components/reports/milestone-dashboard/gooc2/gooc2.component';
import { COWListComponent } from './components/admin-section/manual-upload/cow-list/cow-list.component';
import { SubsystemMCDashboard2Component } from './components/reports/milestone-dashboard/subsystem-mc-dashboard2/subsystem-mc-dashboard2.component';
import { CTARegisterComponent } from './components/cta/cta-register.component';
import { OverdueDPLIComponent } from './components/reports/health/overdue-dpli/overdue-dpli.component';
import { GeneralAppConfigurationComponent } from './components/admin-section/scmt/general-app/general-app-configuration.component';
import { CertificateSearchComponent } from './components/certificate/certificate.component';
import { ChangeManagementManualComponent } from './components/admin-section/scmt/change-management-manual/change-management-manual.component';
import { AutomatedFileTransfersMcplusComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/automated-file-transfers-mcplus.component';
import { HomeComponent } from './components/home/home.component';
import { SubsystemRfsuDashboardComponent } from './components/reports/milestone-dashboard/subsystem-rfsu-dashboard/subsystem-rfsu-dashboard.component';
import { SCWorkAreaConfigurationComponent } from './components/weekly-planning/sc-work-area-configuration/sc-work-area-configuration.component';
import { ScheduleActivityComponent } from './components/weekly-planning/planning-upload/schedule-activity/schedule-activity.component';
import { ActivityBarcodeComponent } from './components/weekly-planning/planning-upload/activity-barcode/activity-barcode.component';
import { ExceptionsPriorityComponent } from './components/weekly-planning/planning-upload/exceptions-priority/exceptions-priority.component';
import { ITRManhoursComponent } from './components/weekly-planning/planning-upload/itr-manhours/itr-manhours.component';
import { PlanningUploadComponent } from './components/weekly-planning/planning-upload/planning-upload.component';
import { PlanningDataComponent } from './components/weekly-planning/planning-upload/planning-data/planning-data.component';
import { ScheduleActivityPlanningComponent } from './components/weekly-planning/sc-planning/lookahead/schedule-activity-planning.component';
import { ScPlanningConfigurationComponent } from './components/weekly-planning/sc-planning-configuration/sc-planning-configuration.component';
import { AutomatedFileTransfersOtherSourcesComponent } from './components/admin-section/scmt/automated-file-transfers-other-sources/automated-file-transfers-other-sources.component';
import { ScheduleActivityItrComponent } from './components/schedule-activity-itr/schedule-activity-itr.component';
import { PendingChangesGuard } from 'src/guards/pending-changes.guard';
import { OverdueExceptionsComponent } from './components/reports/health/overdue-exceptions/overdue-exceptions.component';
import { ScPriorityConfigurationComponent } from './components/weekly-planning/sc-priority-configuration/sc-priority-configuration.component';
import { TraceHeatingStatusComponent } from './components/reports/trace-heating-status/trace-heating-status.component';
import { MsalGuard } from '@azure/msal-angular';
import { ScExcludeConfigurationComponent } from './components/weekly-planning/sc-exclude-configuration/sc-exclude-configuration.component';
import { DcnRfiRedlineRegisterComponent } from './components/dcn-rfi-redline-register/dcn-rfi-redline-register.component';
import { DcnRfiDetailsComponent } from './components/dcn-rfi-redline-register/details/dcn-rfi-details/dcn-rfi-details.component';
import { PipelineComponent } from './components/admin-section/automatic-upload/pipeline/pipeline.component';
import { OverdueExceptionsByPriorityComponent } from './components/reports/health/overdue-exceptions-by-priority/overdue-exceptions-by-priority.component';
import { ActivitiesConstraintsComponent } from './components/reports/health/activities-constraints/activities-constraints.component';
import { PLIPlanningComponent } from './components/weekly-planning/pli-planning/pli-planning.component';
import { PowerBIReportsComponent } from './components/reports/power-bi';
import { ITRConstraintsComponent } from './components/weekly-planning/planning-upload/itr-constraints/itr-constraints.component';
import { PASCRComponent } from './components/pascr-status/pascr-status.component';
import { StatusUpdateComponent } from './components/admin-section/automatic-upload/status-update/status-update.component';
import { ConstraintsContactsComponent } from './components/weekly-planning/planning-upload/itr-constraints-contacts/itr-constraints-contacts.component';
import { CITRPartialProgressComponent } from './components/weekly-planning/planning-upload/citr-partial-progress/citr-partial-progress.component';
import { IsRFSUSkylineForecastGuard } from 'src/guards/is-rfsu-skyline-forecast-guard';
import { CriticalPathComponent } from './components/weekly-planning/planning-upload/critical-path/critical-path.component';
import { ExceptionsUploadComponent } from './components/weekly-planning/planning-upload/exceptions-upload/exceptions-upload.component';
import { PriorityNumbersComponent } from './components/admin-section/automatic-upload/priority-numbers/priority-numbers.component';
import { CriticalRfiComponent } from './components/critical-rfi/critical-rfi.component';
import { LeakTestPackTrackerSearchComponent } from './components/leak-test-pack-tracker-search/leak-test-pack-tracker-search.component';
import { IsRFSUSkylineCommitmentGuard } from 'src/guards/is-rfsu-skyline-commitment-guard';
import { PLIDataUploadComponent } from './components/weekly-planning/planning-upload/pli-data-upload/pli-data-upload.component';
import { ITRCommentUploadComponent } from './components/weekly-planning/planning-upload/itr-comment-upload/itr-comment-upload.component';
import { RFODatesUploadComponent } from './components/weekly-planning/planning-upload/rfo-dates-upload/rfo-dates-upload.component';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [MsalGuard],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'exportpage', component: ExportPageComponent, canActivate: [IsInGroupGuard], },
            { path: 'home', component: HomeComponent, canActivate: [IsInGroupGuard, IsNotGathering] },
            { path: 'redzone', component: RedZoneComponent, canActivate: [IsInGroupGuard, IsNotGathering] },
            { path: 'bluezone', component: BlueZoneComponent, canActivate: [IsInGroupGuard, IsNotGathering] },
            { path: 'rfo', component: RFOComponent, canActivate: [IsInGroupGuard, IsNotGathering] },
            {
                path: 'detailedstatus',
                component: DetailedStatusComponent,
                canActivate: [IsInGroupGuard, IsNotGathering],
            },
            { path: 'pascrstatus', component: PASCRComponent, canActivate: [IsInGroupGuard] },
            { path: 'tagsearch', component: TagSearchComponent, canActivate: [IsInGroupGuard] },
            { path: 'ltpsearch', component: LeakTestPackTrackerSearchComponent, canActivate: [IsInGroupGuard] },
            { path: 'loopstatus', component: LoopStatusComponent, canActivate: [IsInGroupGuard] },
            { path: 'cta', component: CTARegisterComponent, canActivate: [IsInGroupGuard] },
            { path: 'systemiseddrawing', component: SystemisedDrawingComponent, canActivate: [IsInGroupGuard] },
            { path: 'certificatesearch', component: CertificateSearchComponent, canActivate: [IsInGroupGuard] },
            { path: 'details', component: DetailsComponent, canActivate: [IsInGroupGuard] },
            {
                path: 'changeDocumentDetails/:type/:id',
                component: ChangeDocumentDetailsComponent,
                canActivate: [IsInGroupGuard],
            },
            { path: 'notfound', component: NotFoundComponent, canActivate: [IsInGroupGuard] },
            { path: 'synchronize', component: UserSynchronizationComponent, canActivate: [IsInGroupGuard] },
            {
                path: 'admin',
                component: AdminComponent,
                children: [
                    {
                        path: '',
                        canActivateChild: [IsAdminGuard],
                        children: [
                            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
                            { path: 'changedocument/:type', component: ChangeDocumentComponent },
                            { path: 'contractormapping', component: ContractorsMappingsComponent },
                            { path: 'mcplus', component: MCPlusComponent },
                            { path: 'dashboard', component: DashboardComponent },
                            {
                                path: 'manualupload',
                                component: ManualUploadComponent,
                                children: [
                                    { path: '', redirectTo: 'mastersubsystemlist', pathMatch: 'full' },
                                    { path: 'mastersubsystemlist', component: MasterSubsystemListComponent },
                                    { path: 'cwpregister', component: CWPRegisterComponent },
                                    { path: 'iwplisting', component: IwpListingComponent },
                                    { path: 'rfo', component: RFOImportComponent },
                                    { path: 'systemizeddrawingslist', component: SystemizedDrawingsComponent },
                                    { path: 'cowlist', component: COWListComponent },
                                    { path: 'flangemanagement', component: FlangeManagementComponent },
                                    { path: 'redline', component: RedlineComponent },
                                ],
                            },
                            {
                                path: 'automaticupload',
                                component: AutomaticUploadComponent,
                                children: [
                                    { path: '', redirectTo: 'itr', pathMatch: 'full' },
                                    { path: 'itr', component: ITRComponent },
                                    { path: 'pli', component: PLIComponent },
                                    { path: 'tag', component: TagComponent },
                                    { path: 'certificate', component: CertificateComponent },
                                    { path: 'exceptions', component: ExceptionComponent },
                                    { path: 'imdb', component: IMDBComponent },
                                    { path: 'loop', component: LoopComponent },
                                    { path: 'pipeline', component: PipelineComponent },
                                    { path: 'status-update', component: StatusUpdateComponent },
                                    { path: 'priority-numbers', component: PriorityNumbersComponent },
                                ],
                            },
                            {
                                path: 'dataMaintenance',
                                component: DataMaintenanceComponent,
                                children: [
                                    { path: '', redirectTo: 'team-data', pathMatch: 'full' },
                                    { path: 'team-data', component: TeamDataComponent },
                                    { path: 'subsystem-data', component: SubsystemMaintenanceComponent },
                                    { path: 'project-team-data', component: ProjectTeamMaintenanceComponent },
                                    { path: 'waypoint-milestone-data', component: MilestoneWaypointComponent },
                                    { path: 'user-to-contracts', component: UserToContractsComponent },
                                ],
                            },
                            {
                                path: 'scmt/configuration',
                                component: ConfigurationComponent,
                                children: [
                                    { path: 'general-app-configuration', component: GeneralAppConfigurationComponent },
                                    { path: 'change-management-manual', component: ChangeManagementManualComponent },
                                    {
                                        path: 'automated-file-transfers-mcplus',
                                        component: AutomatedFileTransfersMcplusComponent,
                                    },
                                    {
                                        path: 'automated-file-transfers-other-sources',
                                        component: AutomatedFileTransfersOtherSourcesComponent,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            { path: 'changemanagement', component: ChangeManagementComponent, canActivate: [IsInGroupGuard] },
            {
                path: 'changemanagement/fieldchangesummary/:subsystem',
                component: FieldChangeSummaryComponent,
                canActivate: [IsInGroupGuard],
            },
            {
                path: 'weeklyplanning',
                component: WeeklyPlanningComponent,
                canActivate: [IsInGroupGuard],
                children: [
                    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
                    { path: 'dashboard', component: WeeklyPlanningDashboardComponent },
                    {
                        path: 'session/:projectTeamName',
                        component: WeeklyPlanningSessionComponent,
                        canActivate: [IsPlanningGroupGuard],
                        children: [
                            { path: '', redirectTo: 'rfsu', pathMatch: 'full' },
                            { path: 'mc', component: WeeklyPlanningSessionMCComponent },
                            { path: 'rfsu', component: WeeklyPlanningSessionRFSUComponent },
                            { path: 'rfo', component: WeeklyPlanningSessionRFOComponent },
                        ],
                    },
                    {
                        path: 'upload',
                        component: PlanningUploadComponent,
                        children: [
                            { path: '', redirectTo: 'subsystemscheduleplandata', pathMatch: 'full' },
                            { path: 'subsystemscheduleplandata', component: PlanningDataComponent },
                            { path: 'scheduleactivity', component: ScheduleActivityComponent },
                            { path: 'activitybarcode', component: ActivityBarcodeComponent },
                            { path: 'exceptionspriority', component: ExceptionsPriorityComponent },
                            { path: 'itrmanhours', component: ITRManhoursComponent },
                            { path: 'itrconstraints', component: ITRConstraintsComponent },
                            { path: 'constraintcontacts', component: ConstraintsContactsComponent},
                            { path: 'citrpartialprogress', component: CITRPartialProgressComponent },
                            { path: 'criticalpath', component: CriticalPathComponent },
                            { path: 'exceptionsupload', component: ExceptionsUploadComponent },
                            { path: 'plidataupload', component: PLIDataUploadComponent },
                            { path: 'itrcommentupload', component: ITRCommentUploadComponent },
                            { path: 'rfodatesupload', component: RFODatesUploadComponent },                            
                        ],
                    },
                    { path: 'scworkareaconfiguration', component: SCWorkAreaConfigurationComponent },
                    { path: 'scplanningconfiguration', component: ScPlanningConfigurationComponent },
                    { path: 'scfocusconfiguration', component: ScPriorityConfigurationComponent },
                    { path: 'excludeconfiguration', component: ScExcludeConfigurationComponent},

                    {
                        path: 'commitments/:projectTeamName/:planningType',
                        component: WeeklyPlanningCommitmentsComponenet,
                        canActivate: [IsPlanningGroupGuard],
                    },
                    {
                        path: 'daily/:projectTeamName/:planningType',
                        component: WeeklyPlanningDailyComponent,
                        canActivate: [IsInGroupGuard],
                    },
                    {
                        path: 'lookahead/:period',
                        component: ScheduleActivityPlanningComponent,
                        canActivate: [IsInGroupGuard],
                    },
                    {
                        path: 'lookaheaditrs',
                        component: ScheduleActivityItrComponent,
                        canActivate: [IsInGroupGuard],
                        canDeactivate: [PendingChangesGuard],
                    },
                    {
                        path: 'pli',
                        component: PLIPlanningComponent,
                        canActivate: [IsInGroupGuard],
                    }
                ],
            },
            { path: 'changevalidation', component: ChangeValidationComponent, canActivate: [IsValidatorRole] },
            { path: 'validation/:validationId', component: ValidationFormComponent, canActivate: [IsValidatorRole] },
            {
                path: 'changemanagement/fieldchangesummary/:subsystem',
                component: FieldChangeSummaryComponent,
                canActivate: [IsInGroupGuard],
            },
            {
                path: 'mypage',
                component: MyPageComponent,
                canActivate: [IsInGroupGuard],
            },
            {
                path: 'reports',
                component: ReportsComponent,
                canActivate: [IsInGroupGuard],
                children: [
                    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
                    { path: 'dashboard', component: ReportsDashboardComponent },
                    {
                        path: 'milestonedashboard',
                        component: MilestoneDashboardComponent,
                        canActivate: [IsInGroupGuard],
                        children: [
                            { path: '', redirectTo: 'mc', pathMatch: 'full' },
                            { path: 'mc', component: MCMilestoneDashboardComponent },
                            { path: 'rfo', component: RFOMilestoneDashboardComponent },
                        ],
                    },
                    {
                        path: 'gooc1',
                        component: GOOC1Component,
                    },
                    {
                        path: 'gooc2',
                        component: GOOC2Component,
                    },
                    {
                        path: 'subsystem',
                        component: SubsystemMCDashboardComponent,
                    },
                    {
                        path: 'subsystem2',
                        component: SubsystemMCDashboard2Component,
                    },
                    {
                        path: 'subsystemrfsu',
                        component: SubsystemRfsuDashboardComponent,
                    },
                    {
                        path: 'skyline',
                        component: ReportsSkylineComponent,
                        children: [
                            { path: '', redirectTo: 'mc/plan', pathMatch: 'full' },
                            { path: 'mc/plan', component: SkylineSimpleComponent },
                            { path: 'mc/forecast', component: SkylineSimpleComponent },
                            { path: 'rfsu/plan', component: SkylineSimpleComponent },
                            { path: 'rfsu/forecast', component: SkylineSimpleComponent},
                            { path: 'citr/forecast', component: SkylineSimpleComponent },
                            { path: 'mcwalkdown/forecast', component: SkylineSimpleComponent },
                            { path: 'rfsuwalkdown/forecast', component: SkylineSimpleComponent },
                            { path: 'rfsu/commitment', component: SkylineSimpleComponent , canActivate: [IsRFSUSkylineCommitmentGuard]},
                            { path: 'mc/commitment', component: SkylineSimpleComponent },
                            { path: 'rfo/plan', component: SkylineSimpleComponent },
                            { path: 'rfo/forecast', component: SkylineSimpleComponent },
                        ],
                    },
                    {
                        path: 'planning/lookahead',
                        component: ReportsPlanningLookaheadComponent,
                        children: [
                            { path: '', redirectTo: 'mc', pathMatch: 'full' },
                            { path: 'mc', component: ReportsPlanningLookaheadContentComponent },
                            { path: 'rfsu', component: ReportsPlanningLookaheadContentComponent },
                        ],
                    },
                    {
                        path: 'planning/commitmentreliability',
                        component: ReportsPlanningCommitmentReliabilityComponent,
                        children: [
                            { path: '', redirectTo: 'mc', pathMatch: 'full' },
                            { path: 'mc', component: ReportsPlanningCommitmentReliabilityContentComponent },
                            { path: 'rfsu', component: ReportsPlanningCommitmentReliabilityContentComponent },
                            { path: 'itr', component: ReportsPlanningCommitmentReliabilityContentComponent },
                        ],
                    },
                    {
                        path: 'health/cleanliness',
                        component: ReportsHealthCleanlinessComponent,
                        children: [
                            { path: '', redirectTo: 'mc', pathMatch: 'full' },
                            { path: 'mc', component: ReportsHealthCleanlinessContentComponent },
                            { path: 'rfsu', component: ReportsHealthCleanlinessContentComponent },
                        ],
                    },
                    {
                        path: 'health/overdue-dpli',
                        component: OverdueDPLIComponent,
                    },
                    {
                        path: 'health/overdue-exceptions',
                        component: OverdueExceptionsComponent,
                    },
                    {
                        path: 'health/overdue-exceptions-by-priority',
                        component: OverdueExceptionsByPriorityComponent,
                    },
                    {
                        path: 'health/activities-constraints',
                        component: ActivitiesConstraintsComponent,
                    },
                    {
                        path: 'planning/icicle',
                        component: IcicleReportComponent,
                        children: [
                            { path: '', redirectTo: 'mc', pathMatch: 'full' },
                            { path: 'mc', component: IcicleReportComponent },
                            { path: 'rfsu', component: IcicleReportComponent },
                        ],
                    },
                    {
                        path: 'rundown',
                        component: ReportsRundownComponent,
                        children: [
                            { path: '', redirectTo: 'mc', pathMatch: 'full' },
                            { path: 'mc', component: ReportsRundownContentComponent },
                            { path: 'rfsu', component: ReportsRundownContentComponent },
                            { path: 'aqvd', component: ReportsRundownContentComponent },
                            { path: 'bitr', component: ReportsRundownContentComponent },
                            { path: 'citr', component: ReportsRundownContentComponent },
                            { path: 'pli1', component: ReportsRundownContentComponent },
                            { path: 'pli2', component: ReportsRundownContentComponent },
                        ],
                    },
                    {
                        path: 'subsystemconflicts',
                        component: SubsystemConflictsComponent,
                    },
                    {
                        path: 'changevalidationdashboard',
                        component: ChangeValidationDashboardComponent,
                    },
                    {
                        path: 'traceheatingstatus',
                        component: TraceHeatingStatusComponent,
                    },
                    {
                        path: 'powerbi/:reporttype',
                        component: PowerBIReportsComponent,
                    },
                ],
            },
            { 
                path: 'criticalrfi', component: CriticalRfiComponent
            },
            { 
                path: 'register', component: DcnRfiRedlineRegisterComponent
            },
            {
                path: 'register/details/:type/:id', component: DcnRfiDetailsComponent
            }
        ],
    },
    { path: 'notauthorized', component: NotAuthorizedComponent },
    { path: '**', component: NotFoundComponent, canActivate: [IsInGroupGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
