import { Injectable } from '@angular/core';
import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    MyPageActionTypes,
    MyPageFilterSuccess,
    MyPageFilterError,
    MyPageMarkReadRequest,
    MyPageMarkReadSuccess,
    MyPageMarkReadError,
    MyPageFilterRequest,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { MyPageService } from 'src/app/services/api/webapi-services/my-page.service';

@Injectable()
export class MyPageEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private myPageService: MyPageService,
        private toastService: ToastService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType(MyPageActionTypes.MyPageFilterRequest),
        withLatestFrom(this.store.select((store) => store.myPageState.filter)),
        mergeMap(([, filter]) => {
            return this.myPageService.getMyPageData(filter).pipe(
                map((dataPagination) => {
                    return new MyPageFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering My Page data. Please contact Program Administrator.'
                    );
                    return of(new MyPageFilterError(error));
                })
            );
        })
    );

    @Effect()
    markReadRequest$ = this.actions$.pipe(
        ofType<MyPageMarkReadRequest>(MyPageActionTypes.MyPageMarkReadRequest),
        mergeMap((action) => {
            return this.myPageService.markRead(action.payload.mentionId, action.payload.isRead).pipe(
                map(() => {
                    return new MyPageMarkReadSuccess();
                }),
                catchError((error) => {
                    this.toastService.Error('Error occurred while mark as read. Please contact Program Administrator.');
                    return of(new MyPageMarkReadError(error));
                })
            );
        })
    );

    @Effect()
    markReadSuccess$ = this.actions$.pipe(
        ofType<MyPageMarkReadSuccess>(MyPageActionTypes.MyPageMarkReadSuccess),
        mergeMap(() => {
            return of(new MyPageFilterRequest());
        })
    );
}
