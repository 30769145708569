import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum IWPListingActionTypes {
    IWPListingUploadLogRequest = '[IWP Listing] Upload Log Request',
    IWPListingUploadLogSuccess = '[IWP Listing] Upload Log Success',
    IWPListingUploadLogError = '[IWP Listing] Upload Log Error',
    IWPListingSetStatusAndStartDate = '[IWP Listing] Set Status and Start Date',
    IWPListingFileUploaded = '[IWP Listing] File Uploaded',
    IWPListingClearInProgressSpinner = '[IWP Listing] Clear In Progress Spinner',
    IWPListingTemplateFileRequest = '[IWP Listing] Template File Request',
    IWPListingTemplateFileSuccess = '[IWP Listing] Template File Success',
    IWPListingTemplateFileError = '[IWP Listing] Template File Error',
    IWPListingValidateButtonStateRequest = '[IWP Listing] Validate Button State Request',
    IWPListingValidateButtonStateSuccess = '[IWP Listing] Validate Button State Success',
    IWPListingValidateButtonStateError = '[IWP Listing] Validate Button State Error',
    IWPListingDownloadOutputDataRequest = '[IWP Listing] Download Output Data Request',
    IWPListingDownloadOutputDataSuccess = '[IWP Listing] Download Output Data Success',
    IWPListingDownloadOutputDataError = '[IWP Listing] Download Output Data Error',
    IWPListingAddStartedLog = '[IWP Listing] Add Started Log',
    IWPListingValidateDeltaRequest = '[IWP Listing] Validate Delta Request',
    IWPListingValidateDeltaSuccess = '[IWP Listing] Validate Delta Success',
    IWPListingValidateDeltaError = '[IWP Listing] Validate Delta Error',
}

export class IWPListingAddStartedLog implements Action {
    readonly type = IWPListingActionTypes.IWPListingAddStartedLog;
}

export class IWPListingUploadLogRequest implements Action {
    readonly type = IWPListingActionTypes.IWPListingUploadLogRequest;
}

export class IWPListingUploadLogSuccess implements Action {
    readonly type = IWPListingActionTypes.IWPListingUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class IWPListingUploadLogError implements Action {
    readonly type = IWPListingActionTypes.IWPListingUploadLogError;

    constructor(public payload: string) {}
}

export class IWPListingSetStatusAndStartDate implements Action {
    readonly type = IWPListingActionTypes.IWPListingSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class IWPListingFileUploaded implements Action {
    readonly type = IWPListingActionTypes.IWPListingFileUploaded;
}

export class IWPListingClearInProgressSpinner implements Action {
    readonly type = IWPListingActionTypes.IWPListingClearInProgressSpinner;
}

export class IWPListingTemplateFileRequest implements Action {
    readonly type = IWPListingActionTypes.IWPListingTemplateFileRequest;

    constructor(public payload: string) {}
}

export class IWPListingTemplateFileSuccess implements Action {
    readonly type = IWPListingActionTypes.IWPListingTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class IWPListingTemplateFileError implements Action {
    readonly type = IWPListingActionTypes.IWPListingTemplateFileError;

    constructor(public payload: string) {}
}

export class IWPListingValidateButtonStateRequest implements Action {
    readonly type = IWPListingActionTypes.IWPListingValidateButtonStateRequest;
}

export class IWPListingValidateButtonStateSuccess implements Action {
    readonly type = IWPListingActionTypes.IWPListingValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class IWPListingValidateButtonStateError implements Action {
    readonly type = IWPListingActionTypes.IWPListingValidateButtonStateError;

    constructor(public payload: string) {}
}

export class IWPListingDownloadOutputDataRequest implements Action {
    readonly type = IWPListingActionTypes.IWPListingDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class IWPListingDownloadOutputDataSuccess implements Action {
    readonly type = IWPListingActionTypes.IWPListingDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class IWPListingDownloadOutputDataError implements Action {
    readonly type = IWPListingActionTypes.IWPListingDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class IWPListingValidateDeltaRequest implements Action {
    readonly type = IWPListingActionTypes.IWPListingValidateDeltaRequest;
}

export class IWPListingValidateDeltaSuccess implements Action {
    readonly type = IWPListingActionTypes.IWPListingValidateDeltaSuccess;
}

export class IWPListingValidateDeltaError implements Action {
    readonly type = IWPListingActionTypes.IWPListingValidateDeltaError;

    constructor(public payload: string) {}
}

export type IWPListingActions =
    | IWPListingUploadLogRequest
    | IWPListingUploadLogSuccess
    | IWPListingUploadLogError
    | IWPListingSetStatusAndStartDate
    | IWPListingFileUploaded
    | IWPListingClearInProgressSpinner
    | IWPListingTemplateFileRequest
    | IWPListingTemplateFileSuccess
    | IWPListingTemplateFileError
    | IWPListingValidateButtonStateRequest
    | IWPListingValidateButtonStateSuccess
    | IWPListingValidateButtonStateError
    | IWPListingDownloadOutputDataRequest
    | IWPListingDownloadOutputDataSuccess
    | IWPListingDownloadOutputDataError
    | IWPListingAddStartedLog
    | IWPListingValidateDeltaRequest
    | IWPListingValidateDeltaSuccess
    | IWPListingValidateDeltaError;
