import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, OrderDirection } from '../../common.model';

export interface PliDataUploadState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deleteData: PliDataUploadDeletePagination;
    isDeleteDataLoading: boolean;
    deleteFilter: PliDataUploadDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class PliDataUploadDeleteDTO {
    deleteState: boolean;
    itrpliNumber: string;
    exceptionNumber: string;
}

export class PliDataUploadDeleteFilter {
    page = 0;
    take = 10;
    sortBy = 'pli';
    direction: OrderDirection = OrderDirection.Desc;
    columnExceptionNumber: CheckListColumn = null;
    columnitrpliNumber: CheckListColumn = null;
}
export class PliDataUploadDeletePagination {
    items: PliDataUploadDeleteDTO[] = [];
    totalCount = 0;
}
