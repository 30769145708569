import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { catchError, map, take, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { SubsystemScope } from 'src/app/enums';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ChangeManagementFilterReset, ChangeManagementSetMakeRequest } from 'src/app/store/change-management/actions';
import { Contractor, Gooc, OrderDirection, StagedStartUpPriority, TCOUser } from 'src/app/store/common.model';
import { DetailedStatusSetMakeRequest } from 'src/app/store/detailed-status/actions';
import { ApplicationState } from 'src/app/store/model';
import {
    SubsystemMCDasbboard1RedirectToDetailedStatus,
    SubsystemMCDashboard1ExportToExcelRequest,
    SubsystemMCDashboard1Request,
    SubsystemMCDashboard1UpdateFilterProperties,
} from 'src/app/store/reports/subsystem-mc/actions';
import {
    SubsystemMCDashboardColumnSummary,
    SubsystemMCDashboardDTO,
    SubsystemMCDashboardFilter,
    SubsystemMCDashboardPLIColumnSummary,
} from 'src/app/store/reports/subsystem-mc/model';

@Component({
    selector: 'app-subsystem-mc-dashboard',
    templateUrl: './subsystem-mc-dashboard.component.html',
    styleUrls: ['./subsystem-mc-dashboard.component.scss'],
})
export class SubsystemMCDashboardComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    displayedColumns = [
        'subsystemNo',
        'subsystemName',
        'mcPlanDate',
        'mcForecast',
        'mcActualDate',
        'aqvd',
        'redlines',
        'pli',
        'npw',
    ];
    data: SubsystemMCDashboardDTO[] = [];
    pageSize = 10;
    selectedPageSize = 10;
    resultsLength = 0;
    isPaginatorVisible = true;
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    stagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    stagedStartUpPriorityAutocompleteDisplayedColumns = ['priority', 'priorityName'];
    isUsersPerProjectLoading = false;
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    contractors: Contractor[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    scrollSearch: boolean = false;
    isLoading$ = this.store.select((state) => state.subsystemMCDashboardState.dashboard1IsLoading);
    items$ = this.store.select((state) => state.subsystemMCDashboardState.dashboard1Items);
    filter$ = this.store.select((state) => state.subsystemMCDashboardState.dashboard1Filter);
    totalCount$ = this.store.select((state) => state.subsystemMCDashboardState.dashboard1TotalCount);
    sortBy = 'subsystemNo';
    direction = OrderDirection.Desc;

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private router: Router
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new SubsystemMCDashboardFilter());
    }

    private areAnyFiltersApplied() {
        const {
            contractors,
            goocs,
            mcEngineer,
            stagedStartUpPriorities,
            systemOwner,
            scManager,
        } = this.filterForm.value;
        return Boolean(
            contractors.length ||
                goocs.length ||
                mcEngineer.length ||
                scManager.length ||
                stagedStartUpPriorities.length ||
                systemOwner.length
        );
    }

    ngOnInit() {
        this.filterExpansionPanel.expanded = true;

        this.items$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            if (!items) {
                this.filterSubsystemMCData();
                return;
            }
            this.data = items;
        });

        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.direction = filter.direction;
            this.sortBy = filter.sortBy;
            this.scrollSearch = filter.scrollSearch;
            if (!this.scrollSearch) {
                this.paginator.pageIndex = filter.page;
                this.pageSize = filter.take;
            }
        });

        this.totalCount$.pipe(takeWhile(() => this.isAlive)).subscribe((totalCount) => {
            this.resultsLength = totalCount;
            if (this.areAnyFiltersApplied()) {
                this.pageSize = totalCount;
                this.addTotalRecord();
            }
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.filterForm.controls.sortBy.setValue(sortChange.active);
            this.filterForm.controls.direction.setValue(sortChange.direction);
            this.store.dispatch(
                new SubsystemMCDashboard1UpdateFilterProperties({
                    direction: this.filterForm.value.direction,
                    sortBy: this.filterForm.value.sortBy,
                })
            );
            this.filterSubsystemMCData();
        });

        forkJoin([
            this.lookupService.getMCEngineers([]),
            this.lookupService.getSysOwners([]),
            this.lookupService.getStagedStartUpPriorities(),
            this.lookupService.getSCManagers([]),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners, stagedStartUpPriorities, scManagers]) => {
                    this.mcEngineers = mcEngineers;
                    this.sysOwners = sysOwners;
                    this.scManagers = scManagers;
                    this.stagedStartUpPriorities = stagedStartUpPriorities;
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    displayMultipleSelected(values: Contractor[]) {
        return values.map((x) => x.name).join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }
    onStagedStartUpPrioritiesClosed() {
        this.stagedStartUpPrioritiesInput.emit(new SetInputEventArgs(true));
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((stagedStartUpPriorities: StagedStartUpPriority[]) => stagedStartUpPriorities));
    };

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    applySort(property: string) {
        if (this.sortBy !== property) {
            this.direction = OrderDirection.Desc;
        } else {
            this.direction = this.direction === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc;
        }
        this.sortBy = property;
        this.filterForm.controls.sortBy.setValue(property);
        this.filterForm.controls.direction.setValue(this.direction);
        this.store.dispatch(
            new SubsystemMCDashboard1UpdateFilterProperties({
                direction: this.filterForm.value.direction,
                sortBy: this.filterForm.value.sortBy,
            })
        );
        this.filterSubsystemMCData();
    }

    addTotalRecord() {
        const lastRecord = _.last(this.data);
        if (lastRecord && !lastRecord.subsystemNo) return;

        let totalRecord = new SubsystemMCDashboardDTO();
        totalRecord.aqvd = new SubsystemMCDashboardColumnSummary();
        totalRecord.aqvd.total = _.sum(this.data.map((s) => s.aqvd.total));
        totalRecord.aqvd.remaining = _.sum(this.data.map((s) => s.aqvd.remaining));
        totalRecord.aqvd.complete = _.sum(this.data.map((s) => s.aqvd.complete));
        totalRecord.aqvd.exceptions = _.sum(this.data.map((s) => s.aqvd.exceptions));
        totalRecord.aqvd.percentComplete = (totalRecord.aqvd.total === 0
            ? 100
            : Math.round((totalRecord.aqvd.complete / totalRecord.aqvd.total + Number.EPSILON) * 100 * 100) / 100
        )
            .toFixed(2)
            .toString();
        totalRecord.pli = new SubsystemMCDashboardPLIColumnSummary();
        totalRecord.pli.apliOpen = _.sum(this.data.map((s) => s.pli.apliOpen));
        totalRecord.pli.bpliOpen = _.sum(this.data.map((s) => s.pli.bpliOpen));
        totalRecord.pli.cpliOpen = _.sum(this.data.map((s) => s.pli.cpliOpen));
        totalRecord.pli.dpliOpen = _.sum(this.data.map((s) => s.pli.dpliOpen));
        totalRecord.redlines = new SubsystemMCDashboardColumnSummary();
        totalRecord.redlines.total = _.sum(this.data.map((s) => s.redlines.total));
        totalRecord.redlines.remaining = _.sum(this.data.map((s) => s.redlines.remaining));
        totalRecord.redlines.complete = _.sum(this.data.map((s) => s.redlines.complete));
        totalRecord.redlines.percentComplete = (totalRecord.redlines.total === 0
            ? 100
            : Math.round((totalRecord.redlines.complete / totalRecord.redlines.total + Number.EPSILON) * 100 * 100) /
              100
        )
            .toFixed(2)
            .toString();
        totalRecord.npw = new SubsystemMCDashboardColumnSummary();
        totalRecord.npw.total = _.sum(this.data.map((s) => s.npw.total));
        totalRecord.npw.remaining = _.sum(this.data.map((s) => s.npw.remaining));
        totalRecord.npw.complete = _.sum(this.data.map((s) => s.npw.complete));
        totalRecord.npw.percentComplete = (totalRecord.npw.total === 0
            ? 100
            : Math.round((totalRecord.npw.complete / totalRecord.npw.total + Number.EPSILON) * 100 * 100) / 100
        )
            .toFixed(2)
            .toString();
        this.data = [...this.data, totalRecord];
    }

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    resetFilters() {
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.stagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.mcEngineer.setValue([]);
        this.filterForm.controls.systemOwner.setValue([]);
        this.filterForm.controls.scManager.setValue([]);
        this.filterForm.controls.stagedStartUpPriorities.setValue([]);
        this.store.dispatch(
            new SubsystemMCDashboard1UpdateFilterProperties({
                goocs: [],
                contractors: [],
                mcEngineer: [],
                systemOwner: [],
                scManager: [],
                stagedStartUpPriorities: [],
            })
        );
    }

    search() {
        this.scrollSearch = this.areAnyFiltersApplied();
        if (!this.areAnyFiltersApplied()) {
            this.pageSize = this.selectedPageSize;
        }
        this.paginator.pageIndex = 0;
        this.updateFilterStore();
        this.filterSubsystemMCData();
    }

    updateFilterStore() {
        this.store.dispatch(
            new SubsystemMCDashboard1UpdateFilterProperties({
                goocs: this.filterForm.value.goocs,
                contractors: this.filterForm.value.contractors,
                systemOwner: this.filterForm.value.systemOwner,
                mcEngineer: this.filterForm.value.mcEngineer,
                scManager: this.filterForm.value.scManager,
                stagedStartUpPriorities: this.filterForm.value.stagedStartUpPriorities,
                page: this.paginator.pageIndex,
                take: this.pageSize,
                direction: this.filterForm.value.direction,
                sortBy: this.filterForm.value.sortBy,
                scrollSearch: this.scrollSearch,
            })
        );
    }

    onPaginatorChange(pageEvent: PageEvent) {
        this.pageSize = pageEvent.pageSize;
        this.selectedPageSize = pageEvent.pageSize;
        this.store.dispatch(
            new SubsystemMCDashboard1UpdateFilterProperties({
                page: pageEvent.pageIndex,
                take: pageEvent.pageSize,
            })
        );
        this.filterSubsystemMCData();
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    private filterSubsystemMCData() {
        this.store.dispatch(new SubsystemMCDashboard1Request());
    }

    exportToExcel() {
        this.updateFilterStore();
        this.store.dispatch(new SubsystemMCDashboard1ExportToExcelRequest());
    }

    openDetailedStatusPage(subsystem: string, scope: string, category: string = undefined, zone: string = undefined) {
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        this.store.dispatch(
            new SubsystemMCDasbboard1RedirectToDetailedStatus({
                subsystem,
                scope,
                exceptions: SubsystemScope[scope] === SubsystemScope.EX,
                category,
                zone,
            })
        );
    }

    openChangeManagementPage(subsystem: string, changeType: string, lockFilters = true) {
        const goocs = this.filterForm.controls.goocs.value.map((g) => g.no);
        const mcEngineers = this.filterForm.controls.mcEngineer.value;
        const systemOwners = this.filterForm.controls.systemOwner.value;
        const contractors = this.filterForm.controls.contractors.value.map((m) => m.name);
        this.store.dispatch(new ChangeManagementSetMakeRequest());
        this.store.dispatch(new ChangeManagementFilterReset());
        this.router.navigate(['/changemanagement'], {
            queryParams: { subsystem, changeType, lockFilters, goocs, mcEngineers, systemOwners, contractors },
        });
    }
}
