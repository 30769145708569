import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ExcludeFilterAssignmentDTO } from 'src/app/store/common.model';

export interface ExcludeFilterData {
    id: string;
    excludeFilters: string[];
}

export interface ExcludeFilterAssignmentProps {
    entityType: 'subsystem' | 'itr';
    isAssignment?: boolean;
    data: ExcludeFilterData[];
    getOptions?(): Observable<string[]>;
    onSumbit?(data: ExcludeFilterAssignmentDTO): void;
    onCancel?(): void;
}

@Component({
    selector: 'app-exclude-filter-assignment',
    templateUrl: './exclude-filter-assignment.component.html',
    styleUrls: ['./exclude-filter-assignment.component.scss'],
})
export class ExcludeFilterAssignmentComponent extends BaseComponent implements OnInit {
    @ViewChild('excludeInput') excludeInput: ElementRef;
    excludeControl = new FormControl();
    isLoading = false;
    separatorKeysCodes = [ENTER, COMMA];
    selectedExcludeValue = [];
    allExcludeValues = [];
    filteredExcludeValues$: Observable<any[]>;
    config: ExcludeFilterAssignmentProps;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ExcludeFilterAssignmentProps,
        private dialogRef: MatDialogRef<ExcludeFilterAssignmentComponent>
    ) {
        super();
        this.config = data;
    }

    ngOnInit() {
        if (this.config.getOptions) {
            this.isLoading = true;
            this.config
                .getOptions()
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((data) => {
                    this.allExcludeValues = data;
                    this.isLoading = false;
                });
        }

        this.filteredExcludeValues$ = this.excludeControl.valueChanges.pipe(
            startWith(null),
            map((excludeValue: string | null) => this.filterOptions(excludeValue))
        );
    }

    filterOptions(name: string | null) {
        const staging = name
            ? this.allExcludeValues.filter(
                  (excludeValue) => excludeValue.toLowerCase().indexOf(name.toLowerCase()) === 0
              )
            : this.allExcludeValues.slice();

        return staging.filter((v) => !this.selectedExcludeValue.includes(v));
    }

    onCancel() {
        if (this.config.onCancel) {
            this.config.onCancel();
        }
        this.dialogRef.close(false);
    }

    onSubmit() {
        if (this.config.onSumbit) {
            const toSave: ExcludeFilterAssignmentDTO = {
                excludeFilters: this.selectedExcludeValue,
                ids: this.config.data.map((x) => x.id),
            };
            this.config.onSumbit(toSave);
        }
        this.dialogRef.close(true);
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add value
        if ((value || '').trim()) {
            this.selectedExcludeValue.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.excludeControl.setValue(null);
    }

    remove(excludeValue: any): void {
        const index = this.selectedExcludeValue.indexOf(excludeValue);

        if (index >= 0) {
            this.selectedExcludeValue.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.selectedExcludeValue.push(event.option.viewValue);
        this.excludeInput.nativeElement.value = '';
        this.excludeControl.setValue(null);
    }

    isSaveDisabled() {
        return this.config.data.length == 0 || this.selectedExcludeValue.length == 0;
    }
}
