import { Pipe, PipeTransform } from '@angular/core';
import { Contractor } from '../store/common.model';

@Pipe({
    name: 'contractorList',
    pure: false,
})
export class ContractorListPipe implements PipeTransform {
    transform(items: Contractor[], projectTeam: string): any {
        if (!items || projectTeam === null) {
            return items;
        }
        return items.filter((item) => item.projectTeamName === projectTeam);
    }
}
