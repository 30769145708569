<h2 mat-dialog-title class="title">Select constraint</h2>
<form [formGroup]="constraintTypeForm" style="margin-left:15px;" (ngSubmit)="save()">
    <mat-dialog-content style="min-height: 800px;">
        <mat-form-field>
            <mat-label>Constraint Type</mat-label>
            <mat-select formControlName="constraintType">
                <mat-option *ngFor="let type of constraintTypes" [value]="type" (click)="populateusers(type.id)">
                    {{ type.name }}
                </mat-option>
            </mat-select>
            
            <mat-error *ngIf="constraintTypeForm.controls.constraintType.errors?.required"
                >Constraint Type is required.</mat-error
            >
        </mat-form-field>
        <br>

        <mat-form-field class="detailedstatus-input-fmrorpo" appearance="fill">
            <mat-label>Constraint Description</mat-label>
            <textarea matInput formControlName="itrConstraintDescription"></textarea>
        </mat-form-field>
        <br>

        <app-multiple-items-selector
        class="container300"
        [setInput]="setResponsibleGroupInput"
        [searchFunc]="searchITRConstraintResponsibleGroup"
        [isAsync]="true"
        [isSelectAll]="false"
        [oneItemMode]="true"
        formControlName="responsiblepersongroup"
        [propertyToShow]="'name'"
        [placeholder]="'Responsible Group'"
        [formSetter]="'responsiblepersongroup'"
        [filterForm]="constraintTypeForm"
        (autocompleteClosed)="onUsersClosed()"
        [isTableAutoComplete]="true"
        [displayedColumns]="usersAutocompleteDisplayedColumnsWithoutEmail"
        >
        </app-multiple-items-selector>
        <br>
        <mat-form-field>
            <mat-label>Constraint Raised By (Team)</mat-label>
            <mat-select formControlName="teamname">
                <mat-option *ngFor="let type of constraintTeams" [value]="type">
                    {{ type.projectSubTeamName }}
                </mat-option>
            </mat-select>

        </mat-form-field>
        <br>      
        <app-multiple-items-selector
        *ngIf="displayPliNumber"
        class="container300"
        [setInput]="setAssociatedpliInput"
        [searchFunc]="getFinalPunchItems"
        [isAsync]="true"
        [isSelectAll]="false"
        [oneItemMode]="true"
        formControlName="associatedPLI"
        [propertyToShow]="'tagNo'"
        [placeholder]="'PLI Number'"
        [formSetter]="'associatedPLI'"
        [filterForm]="constraintTypeForm"
        (autocompleteClosed)="onUsersClosed()"
        [isTableAutoComplete]="true"
        [displayedColumns]="pliAutocompleteDisplayedColumns"
        [required]="true"
        >
        </app-multiple-items-selector>  
        <mat-error *ngIf="constraintTypeForm.controls.associatedPLI.errors?.required">
            PLI Number is required.</mat-error>
            <br>
            <app-multiple-items-selector
            *ngIf="displayAQVDNumber"
            class="container300"
            [setInput]="setAssociatedAQVDInput"
            [searchFunc]="getAQVDItems"
            [isAsync]="true"
            [isSelectAll]="false"
            [oneItemMode]="true"
            formControlName="associatedAQVD"
            [propertyToShow]="'tagNo'"
            [placeholder]="'A-QVD Barcode'"
            [formSetter]="'associatedAQVD'"
            [filterForm]="constraintTypeForm"
            (autocompleteClosed)="onUsersClosed()"
            [isTableAutoComplete]="true"
            [displayedColumns]="aqvdAutocompleteDisplayedColumns"
            [required]="true"
            >
            </app-multiple-items-selector>  
            <mat-error *ngIf="constraintTypeForm.controls.associatedAQVD.errors?.required && displayAQVDNumber">
                A-QVD Barcode is required.</mat-error>
        <br>
        <app-multiple-items-selector
        class="container300"
        [setInput]="setAdditionalUsersInput"
        [searchFunc]="searchAdditionalUsers"
        [isAsync]="true"
        [isSelectAll]="false"
        [oneItemMode]="true"
        formControlName="additionalUsername"
        [propertyToShow]="'name'"
        [placeholder]="'Additional Responsible user'"
        [formSetter]="'additionalUsername'"
        [filterForm]="constraintTypeForm"
        (autocompleteClosed)="onUsersClosed()"
        [isTableAutoComplete]="true"
        [displayedColumns]="usersAutocompleteDisplayedColumns"
        >
        </app-multiple-items-selector>
        <br>

        <div class="controls__week-info">
            <mat-form-field appearance="standard" class="container300 margin-right20">
                <mat-label>Constraint Forecast Closure Date</mat-label>
                <input
                    matInput
                    formControlName="duedate"
                    [matDatepicker]="picker"
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            
        </div>


        <mat-form-field class="detailedstatus-input-waypointrequired">
            <mat-label>Required for Milestone/Waypoint</mat-label>
            <mat-select matInput formControlName="waypointRequired">
                <mat-option value=""></mat-option>
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field class="detailedstatus-input-fmrorpo" appearance="fill">
            <mat-label>FMR or PO</mat-label>
            <textarea matInput formControlName="fmRorPO"></textarea>
        </mat-form-field>
        <br>

        <div class="controls__week-info">
            <mat-form-field appearance="standard" class="container300 margin-right20">
                <mat-label>FMR or PO ETA Date</mat-label>
                <input
                    matInput
                    formControlName="fmRorPOETADate"
                    [matDatepicker]="FMRpicker"
                />
                <mat-datepicker-toggle matSuffix [for]="FMRpicker">
                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #FMRpicker></mat-datepicker>
            </mat-form-field>
            
        </div>

        <app-multiple-items-selector
        class="container300"
        [setInput]=""
        [searchFunc]="searchChanges"
        [isAsync]="true"
        [isSelectAll]="false"
        [oneItemMode]="true"
        formControlName="associatedNPW"
        [propertyToShow]="'numberedName'"
        [placeholder]="'Associated NPW'"
        [formSetter]="'associatedNPW'"
        [filterForm]="constraintTypeForm"
        
        [isTableAutoComplete]="false"
        [displayedColumns]="changeAutocompleteDisplayedColumns"
        >
        </app-multiple-items-selector>
        <div class="container300">
            <app-multiple-items-selector
                class="container950"
                [setInput]="setBarcodeInput"
                formControlName="barcodeNo"
                [searchFunc]="getBarcodes"
                [isAsync]="true"
                placeholder="ITR Barcode"
                [formSetter]="'barcodeNo'"
                [filterForm]="constraintTypeForm"
                (autocompleteClosed)="onUsersClosed()"
                [displayedColumns]="barcodesAutocompleteDisplayedColumns"
                [propertyToShow]="'tagNo'"
                [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                [required]="true"
                >
        </app-multiple-items-selector>
        <mat-error *ngIf="constraintTypeForm.controls.barcodeNo.errors?.required"
                >ITR Barcode is required.</mat-error
            >
        </div>


        <mat-checkbox formControlName="deleted" *ngIf="!isAdding">remove this constraint</mat-checkbox>
        <br>

        <button mat-button class="sct-button sct-button-light" type="submit" [disabled]="!constraintTypeForm.valid">
            save
        </button>
        <button mat-button class="sct-button sct-button-white" style="margin-left: 10px;" mat-dialog-close="">cancel</button>

    </mat-dialog-content>

</form>
