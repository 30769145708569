<div class="table-container">
    <div *ngIf="(tableData$ | async) && (tableData$ | async).length">
        <div *ngIf="rundownType === rundownTypes.PLI1 || rundownType === rundownTypes.PLI2">
            <div class="totals-container">
                <div class="total-label">MCWalkdowns</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'MCWalkdowns', 'closed') }}</div>
                </div>
            </div>
            <div
                *ngIf="(filteredByClosureStage$ | async) === false && (pliCategories?.length === 0 || pliCategories?.includes('A'))"
                class="totals-container">
                <div class="total-label">PLI A NET</div>
                <div *ngFor="let total of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(total, 'A', 'total') }}</div>
                </div>
            </div>
            <div *ngIf="pliCategories?.length === 0 || pliCategories?.includes('A')" class="totals-container">
                <div class="total-label">PLI A Raised</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'A', 'raised') }}</div>
                </div>
            </div>
            <div *ngIf="pliCategories?.length === 0 || pliCategories?.includes('A')" class="totals-container">
                <div class="total-label">PLI A Closed</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'A', 'closed') }}</div>
                </div>
            </div>
            <div
                *ngIf="(filteredByClosureStage$ | async) === false && (pliCategories?.length === 0 || pliCategories?.includes('B'))"
                class="totals-container">
                <div class="total-label">PLI B NET</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'B', 'total') }}</div>
                </div>
            </div>
            <div *ngIf="pliCategories?.length === 0 || pliCategories?.includes('B')" class="totals-container">
                <div class="total-label">PLI B Raised</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'B', 'raised') }}</div>
                </div>
            </div>
            <div *ngIf="pliCategories?.length === 0 || pliCategories?.includes('B')" class="totals-container">
                <div class="total-label">PLI B Closed</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'B', 'closed') }}</div>
                </div>
            </div>
            <div
                *ngIf="(filteredByClosureStage$ | async) === false && (pliCategories?.length === 0 || pliCategories?.includes('C'))"
                class="totals-container">
                <div class="total-label">PLI C NET</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'C', 'total') }}</div>
                </div>
            </div>
            <div *ngIf="pliCategories?.length === 0 || pliCategories?.includes('C')" class="totals-container">
                <div class="total-label">PLI C Raised</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'C', 'raised') }}</div>
                </div>
            </div>
            <div *ngIf="pliCategories?.length === 0 || pliCategories?.includes('C')" class="totals-container">
                <div class="total-label">PLI C Closed</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'C', 'closed') }}</div>
                </div>
            </div>
            <div
                *ngIf="(filteredByClosureStage$ | async) === false && (pliCategories?.length === 0 || pliCategories?.includes('D'))"
                class="totals-container">
                <div class="total-label">PLI D NET</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'D', 'total') }}</div>
                </div>
            </div>
            <div *ngIf="pliCategories?.length === 0 || pliCategories?.includes('D')" class="totals-container">
                <div class="total-label">PLI D Raised</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'D', 'raised') }}</div>
                </div>
            </div>
            <div *ngIf="pliCategories?.length === 0 || pliCategories?.includes('D')" class="totals-container">
                <div class="total-label">PLI D Closed</div>
                <div *ngFor="let item of tableData$ | async" class="totals">
                    <div class="total-value">{{ getValue(item, 'D', 'closed') }}</div>
                </div>
            </div>
        </div>

        <div *ngIf="rundownType !== rundownTypes.PLI1 && rundownType !== rundownTypes.PLI2">
            <div class="totals-container">
                <div class="total-label">Actual</div>
                <div *ngFor="let item of tableData" class="totals">
                    <div class="total-value">{{ getValue(item, 'Actual', 'total') }}</div>
                </div>
            </div>
            <ng-container *ngIf="homePage">    
            <ng-container *ngFor="let area of categoriesArea">
                <div class="totals-container-break">
                    <div class="total-label-break">{{ area }}</div>
                    <div *ngFor="let item of tableData" class="totals-break">
                        <div class="total-value-break">{{ getBreakdownData(item, 'Actual', area) }}</div>
                    </div>
                </div>
            </ng-container>
            </ng-container>

            <div *ngIf="rundownType === rundownTypes.RFSU" class="totals-container">
                <div class="total-label">Forecast</div>
                <div *ngFor="let item of tableData" class="totals">
                    <div class="total-value">{{ getValue(item, 'Forecast', 'total') }}</div>
                </div>
            </div>
            <ng-container *ngIf="homePage"> 
            <ng-container *ngFor="let area of categoriesArea">
                <div class="totals-container-break">
                    <div class="total-label-break">{{ area }}</div>
                    <div *ngFor="let item of tableData" class="totals-break">
                        <div class="total-value-break">{{ getBreakdownData(item, 'Forecast', area) }}</div>
                    </div>
                </div>
            </ng-container>
            </ng-container>            

            <div *ngIf="rundownType !== rundownTypes.AQVD" class="totals-container">
                <div class="total-label">Plan</div>
                <div *ngFor="let item of tableData" class="totals">
                    <div class="total-value">{{ getValue(item, 'Plan', 'total') }}</div>
                </div>
            </div>
            <ng-container *ngIf="homePage"> 
            <ng-container *ngFor="let area of categoriesArea">
                <div class="totals-container-break">
                    <div class="total-label-break">{{ area }}</div>
                    <div *ngFor="let item of tableData" class="totals-break">
                        <div class="total-value-break">{{ getBreakdownData(item, 'Plan', area) }}</div>
                    </div>
                </div>
            </ng-container>
            </ng-container>

        </div>
        <div class="weeks-header-container">
            <div class="type-header"></div>
            <div *ngFor="let header of headers" class="past-week">
                <div class="week-label">
                    <span class="week-number">{{ header.week }}</span>
                    <br />
                </div>
            </div>
        </div>
    </div>
</div>
