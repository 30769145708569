
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    ActivityBarcodeActionTypes,
    ActivityBarcodeUploadLogSuccess,
    ActivityBarcodeUploadLogError,
    ActivityBarcodeValidateButtonStateSuccess,
    ActivityBarcodeValidateButtonStateError,
    ActivityBarcodeDownloadOutputDataSuccess,
    ActivityBarcodeDownloadOutputDataError,
    ActivityBarcodeDownloadOutputDataRequest,
    ActivityBarcodeTemplateFileSuccess,
    ActivityBarcodeTemplateFileRequest,
    ActivityBarcodeTemplateFileError,
    ActivityBarcodeClearInProgressSpinner,
    ActivityBarcodeValidateDeleteSuccess,
    ActivityBarcodeValidateDeleteError,
    ActivityBarcodePatchAllDeleteRequest,
    ActivityBarcodePatchAllDeleteSuccess,
    ActivityBarcodePatchAllDeleteError,
    ActivityBarcodePatchDeleteRequest,
    ActivityBarcodePatchDeleteSuccess,
    ActivityBarcodePatchDeleteError,
    ActivityBarcodeDeleteSuccess,
    ActivityBarcodeDeleteError,
    ActivityBarcodeDeleteRequest,
    ActivityBarcodeSetStatusAndStartDate,
} from './actions';

@Injectable()
export class ActivityBarcodeEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(ActivityBarcodeActionTypes.ActivityBarcodeUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['ActivityBarcode']).pipe(
                map((uploadLogData) => new ActivityBarcodeUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new ActivityBarcodeUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<ActivityBarcodeUploadLogSuccess>(ActivityBarcodeActionTypes.ActivityBarcodeUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.activityBarcodeState.uploadLogStatus),
            this.store.select((store) => store.activityBarcodeState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new ActivityBarcodeSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    invokeDeleteRequest$ = this.actions$.pipe(
        ofType(ActivityBarcodeActionTypes.ActivityBarcodeSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.activityBarcodeState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        map(() => new ActivityBarcodeDeleteRequest())
    );

    @Effect()
    getDelete$ = this.actions$.pipe(
        ofType(ActivityBarcodeActionTypes.ActivityBarcodeDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.activityBarcodeState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getActivityBarcodeDeleteRecords(deleteFilter).pipe(
                map((deleteData: any) => new ActivityBarcodeDeleteSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete data records. Please contact Program Administrator'
                    );
                    return of(new ActivityBarcodeDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteData$ = this.actions$.pipe(
        ofType<ActivityBarcodePatchDeleteRequest>(ActivityBarcodeActionTypes.ActivityBarcodePatchDeleteRecordRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchActivityBarcodeDeleteRecord(
                action.payload.barcode, action.payload.deleteState
                ).pipe(
                map(() => new ActivityBarcodePatchDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ActivityBarcodePatchDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchAllDelete$ = this.actions$.pipe(
        ofType<ActivityBarcodePatchAllDeleteRequest>(ActivityBarcodeActionTypes.ActivityBarcodePatchAllDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.activityBarcodeState.deleteFilter)),
        mergeMap(([action, deleteFilter]) =>
            this.manualUploadsService.patchAllActivityBarcodeDeleteRecords(deleteFilter, action.payload.deleteState).pipe(
                map(() => new ActivityBarcodePatchAllDeleteSuccess({ deleteState: action.payload.deleteState })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ActivityBarcodePatchAllDeleteError(error));
                })
            )
        )
    );

    @Effect()
    validateDelete$ = this.actions$.pipe(
        ofType(ActivityBarcodeActionTypes.ActivityBarcodeValidateDataRequest),
        mergeMap(() =>
            this.manualUploadsService.validateActivityBarcodeData().pipe(
                map(() => new ActivityBarcodeValidateDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new ActivityBarcodeValidateDeleteError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(ActivityBarcodeActionTypes.ActivityBarcodeDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.activityBarcodeState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new ActivityBarcodeClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<ActivityBarcodeTemplateFileRequest>(ActivityBarcodeActionTypes.ActivityBarcodeTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new ActivityBarcodeTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Activity Barcode structure file. Please contact Program Administrator'
                    );
                    return of(new ActivityBarcodeTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<ActivityBarcodeTemplateFileSuccess>(ActivityBarcodeActionTypes.ActivityBarcodeTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'ITR to P6 Activity Upload.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<ActivityBarcodeDownloadOutputDataRequest>(ActivityBarcodeActionTypes.ActivityBarcodeDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new ActivityBarcodeDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new ActivityBarcodeDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<ActivityBarcodeDownloadOutputDataSuccess>(ActivityBarcodeActionTypes.ActivityBarcodeDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(ActivityBarcodeActionTypes.ActivityBarcodeDeleteRecordsSuccess,
            ActivityBarcodeActionTypes.ActivityBarcodePatchAllDeleteRecordsSuccess,
            ActivityBarcodeActionTypes.ActivityBarcodePatchDeleteRecordSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('ActivityBarcode').pipe(
                map((response: any) => new ActivityBarcodeValidateButtonStateSuccess(response)),
                catchError((error) => of(new ActivityBarcodeValidateButtonStateError(error)))
            )
        )
    );
}

