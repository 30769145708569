import { Component, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import { PLIPLanningDataRequest, PLIPlanningFilterUpdate } from 'src/app/store/pli-planning/actions';
import { FormService } from 'src/app/services/shared/form.service';
import { PLIPlanningFilter } from 'src/app/store/pli-planning/model';
import { FormGroup } from '@angular/forms';
import { catchError, map, pairwise, startWith, take, takeWhile } from 'rxjs/operators';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { CheckListColumn, Contractor, Gooc, McMilestone, Milestone, OrderDirection, RFO, StagedStartUpPriority, SubsystemDTO, TCOACManager, TCOUser } from 'src/app/store/common.model';
import * as moment from 'moment';
import { ExceptionType } from 'src/app/enums';
import { forkJoin } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';

@Component({
    selector: 'app-pli-planning-filter',
    templateUrl: './pli-planning-filter.component.html',
    styleUrls: ['./pli-planning-filter.component.scss'],
})
export class PLIPlanningFilterComponent extends BaseComponent implements OnInit {
    filterForm: FormGroup;
    filter$ = this.store.select((store) => store.pliPlanningState.filter);
    displayPeriodDays = 14;
    weekStart: moment.Moment;
    isUsersPerProjectLoading = false;


    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    mcMilestoneAutocompleteDisplayedColumns = ['name'];
    subsystemAutocompleteDisplayedColumns = ['value'];
    projectTeamNamesCurrent: string[];
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    acms: TCOACManager[] = [];
    scManagers: TCOUser[] = [];
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    disciplines: any[] = [];
    mcMilestones: McMilestone[];
    goocs: Gooc[];
    category: string;
    systemOwnersFilter: number[];
    mcEngineersFilter: number[];
    stagedStartUpPriorityFilter: StagedStartUpPriority[];
    exceptionTypes = ExceptionType;
    RFexceptionTypes = [
        { key: ExceptionType.RFSU, value: ExceptionType[ExceptionType.RFSU] },
        { key: ExceptionType.RFO, value: ExceptionType[ExceptionType.RFO] },
        { key: ExceptionType.MC, value: ExceptionType[ExceptionType.MC]}
    ];


    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMcMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPriorityInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setConstructionAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    get currentWeekEnd() {
        return (
            moment(this.filterForm.value?.weekStart)
                .add(this.displayPeriodDays - 1, 'day')
                .toDate() ?? ''
        );
    }

    constructor(
        private store: Store<ApplicationState>,
        private filterService: FormService,
        private lookupService: LookupService,
        private toastService: ToastService,
        private projectTeamsService: ProjectTeamsService
    ) {
        super();
        this.filterForm = this.filterService.createSimpleForm(new PLIPlanningFilter());
    }

    ngOnInit(): void {
        this.search();
        this.watchFormChanges();

        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
                this.getSystemOwnerAndEnginner(this.projectTeamNames);
                this.getUsersPerProjectTeam(this.projectTeamNames);
            });

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: any[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines. Please contact Program Administrator.'
                    );
                }
            );
    }

    private watchFormChanges() {
        this.filterForm.valueChanges
            .pipe(startWith(this.filterForm.value))
            .pipe(takeWhile(() => this.isAlive))
            .pipe(pairwise())
            .subscribe(([prev, next]) => {
                this.updateFilter({
                    ...next,
                    weekStart:
                        prev.weekStart === next.weekStart ? next.weekStart : this.dateDayToUTC(moment(next.weekStart)),
                    weekEnd:
                        prev.weekEnd === next.weekEnd ? next.weekEnd : this.dateDayToUTC(moment(next.weekEnd))
                } as PLIPlanningFilter);

                if (prev.weekStart !== next.weekStart) {
                    this.search();
                }
            });
    }

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.isUsersPerProjectLoading = true;
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    compareExceptionTypes(r1: ExceptionType, r2: ExceptionType): boolean {
        return ExceptionType[r1] === ExceptionType[r2];
    }

    updateFilter(filter: PLIPlanningFilter) {
        this.store.dispatch(
            new PLIPlanningFilterUpdate({
                ...filter,
            } as PLIPlanningFilter)
        );
    }

    displayMultipleSelected(values: (Contractor | Milestone | RFO)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displaySelectedMCCompleted(mcCompleted: boolean) {
        if (mcCompleted === null) {
            return 'All';
        }
        return mcCompleted ? 'Yes' : 'No';
    }

    displaySelectedRFSUCompleted(rfsuCompleted: boolean) {
        if (rfsuCompleted === null) {
            return 'All';
        }
        return rfsuCompleted ? 'Yes' : 'No';
    }

    displayExcludeExceptions(values: ExceptionType[]) {
        return values.map((x) => ExceptionType[x]).join(', ');
    }

    displaySelectedPriorities(priorities: StagedStartUpPriority[]) {
        return priorities.map((p) => p.priorityName).join(', ');
    }

    search() {
        this.store.dispatch(new PLIPLanningDataRequest());
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName] &&
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'contractors');
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'goocs');
        this.setMcMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'mcMilestones');
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'rfos');
        this.setSubsystemInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'subsystems');

        forkJoin([
            this.lookupService.getACMs(projectTeamNames),
            this.lookupService.getSCManagers(projectTeamNames),
            this.lookupService.getMCEngineers(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([acms, scManagers, mcEngineers, sysOwners]) => {
                    this.acms = acms;
                    this.checkIfSelectedUsersValid(this.acms, 'acm', 'id');
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    getSystemOwnerAndEnginner(projectTeamNames: string[]) {
        forkJoin([
            this.lookupService.getMCEngineers(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners]) => {
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    getContractorsMulti = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getMcMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMcMilestones(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((mcMilestones: McMilestone[]) => mcMilestones));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    getConstructionArea = (search = '', take = 30, page = 0) => {
        return this.lookupService
            .searchConstructionArea(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) =>
    this.lookupService
        .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
        .pipe(map((startUpPriorities: StagedStartUpPriority[]) => startUpPriorities));


    checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName] &&
            this.filterForm.controls[formControlName].value &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm.controls[formControlName].setValue(valid);
            this.updateFilter(this.filterForm.value);
        }
    }

    clearFilters(event: Event) {
        event.stopPropagation();
        this.filterForm.patchValue(new PLIPlanningFilter(), { emitEvent: false });
        this.updateFilter(this.filterForm.value);
        this.getUsersPerProjectTeam(this.projectTeamNames);
    }

    dateDayToUTC = (date: moment.Moment) => {
        const utcWeekStart = moment.utc().startOf('day');
        utcWeekStart.year(date.year());
        utcWeekStart.month(date.month());
        utcWeekStart.date(date.date());

        return utcWeekStart;
    };

    dateRangePickerFilter = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    searchSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchSubsystems(search, take, page, this.filterForm.value.projectTeamNames, this.filterForm.value.goocs, this.filterForm.value.rfos)
            .pipe(map((systems: SubsystemDTO[]) => systems));
    };

    subsystemLabelFormat = (item: SubsystemDTO) => `${item.id} - ${item.name}`;
}
