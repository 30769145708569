<main>
    <form [formGroup]="filterForm">
        <span class="close" (click)="close()">&times;</span>
        <p>add TDB model shots</p>
        <div class="container300-mh margin-right20 filter-container test">
            <app-multiple-items-selector
                class="container620"
                [setInput]="setTdbSubsystemsInput"
                formControlName="tdbSubsystems"
                [searchFunc]="getTdbSubsystems"
                [isAsync]="true"
                [displayedColumns]="['id', 'name']"
                [filterForm]="filterForm"
                [formSetter]="'tdbSubsystems'"
                [propertyToShow]="'id'"
                [placeholder]="'Subsystem'"
                [panelWidth]="480"
                [reloadOnOpen]="false"
                [isSelectAll]="false"
                [oneItemMode]="true"
                [required]="true"
                [invalid]="uploadDisabled"
            >
            </app-multiple-items-selector>
        </div>
        <div>
            <div class="attachments-header">{{ scopeToString }}</div>
            <div appDragAndDrop [disabled]="uploadDisabled" (filesAdded)="fileChangeEvent($event)" class="drag-and-drop-container">
                <div class="DandD-img"></div>
                <div class="drag-and-drop">
                    Drop files here or click to
                    <button class="upload-file-button" type="button" [disabled]="uploadDisabled" (click)="attachmentFiles.click($event)">
                        select files to upload
                    </button>
                    <div
                        class="tooltip-icon"
                        matTooltip="Maximum file upload limit is 100 MB at a time"
                        matTooltipClass="cofftooltip"
                    ></div>
                    <input
                        multiple
                        #attachmentFiles
                        type="file"
                        (click)="attachmentFiles.value = null"
                        (change)="fileChangeEvent($event.target.files); uploadNewAttachments()"
                    />
                </div>
            </div>
            <div class="new-attachments-container" *ngIf="attachments.length">
                <div class="new-attachment" *ngFor="let newAttachment of attachments; let i = index">
                    <a class="download-attachment" (click)="downloadUploadedAttachment(i)">{{ newAttachment.name }}</a>
                    <button
                        type="button"
                        class="remove-new-attachment"
                        (click)="deleteUploadedAttachment(i)"
                        [disabled]="isUploadInProgress"
                    ></button>
                </div>
            </div>
        </div>
    </form>
</main>
<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
