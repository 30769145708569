import { Action } from "@ngrx/store";
import { ManualPriority, ResponsiblePersonOrGroupDTO } from "./model";
import { DetailedStatusDTO } from "../detailed-status/model";

export enum DataUpdateHistoryActionTypes{
    PostRFOManualPriorityHistoryRequest = '[PostRFO] Manual Priority History Request',
    PostRFOManualPriorityHistorySuccess = '[PostRFO] Manual Priority History Success',
    PostRFOManualPriorityHistoryError = '[PostRFO] Manual Priority History Error',
    PostRFOResponsiblePersonOrGroupHistoryRequest = '[PostRFO] ResponsiblePersonOrGroup History Request',
    PostRFOResponsiblePersonOrGroupHistorySuccess = '[PostRFO] ResponsiblePersonOrGroup History Success',
    PostRFOResponsiblePersonOrGroupHistoryError = '[PostRFOWork] ResponsiblePersonOrGroup History Error',
}

export class PostRFOManualPriorityHistoryRequest implements Action {
    readonly type = DataUpdateHistoryActionTypes.PostRFOManualPriorityHistoryRequest;

    constructor(public payload: {  itemNumber: string, exceptionNumber: string, rfo: string, subsystem:string }) {}
}

export class PostRFOManualPriorityHistorySuccess implements Action {
    readonly type = DataUpdateHistoryActionTypes.PostRFOManualPriorityHistorySuccess;

    constructor(public payload: ManualPriority[]) {}
}

export class PostRFOManualPriorityHistoryError implements Action {
    readonly type = DataUpdateHistoryActionTypes.PostRFOManualPriorityHistoryError;

    constructor(public payload: string) {}
}


export class PostRFOResponsiblePersonOrGroupHistoryRequest implements Action {
    readonly type = DataUpdateHistoryActionTypes.PostRFOResponsiblePersonOrGroupHistoryRequest;

    constructor(public payload: { postRFOData: DetailedStatusDTO }) {}
}

export class PostRFOResponsiblePersonOrGroupHistorySuccess implements Action {
    readonly type = DataUpdateHistoryActionTypes.PostRFOResponsiblePersonOrGroupHistorySuccess;

    constructor(public payload: ResponsiblePersonOrGroupDTO[]) {}
}

export class PostRFOResponsiblePersonOrGroupHistoryError implements Action {
    readonly type = DataUpdateHistoryActionTypes.PostRFOResponsiblePersonOrGroupHistoryError;

    constructor(public payload: string) {}
}
export type DataUpdateHistoryActions = 
    | PostRFOManualPriorityHistoryRequest
    | PostRFOManualPriorityHistorySuccess
    | PostRFOManualPriorityHistoryError
    | PostRFOResponsiblePersonOrGroupHistoryRequest
    | PostRFOResponsiblePersonOrGroupHistorySuccess
    | PostRFOResponsiblePersonOrGroupHistoryError;