import { Component, OnInit, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, map, takeWhile } from 'rxjs/operators';
import * as moment from 'moment';
import { WeeklyPlanningService } from 'src/app/services/api/webapi-services/weekly-planning.service';
import { CommitmentType, DailyDashboardDTO, WeeklyPlanningFilter } from 'src/app/store/weekly-planning/model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { MatTableDataSource } from '@angular/material/table';
import { PhaseType } from 'src/app/enums';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';
import { BaseComponent } from '../../base.component';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import {
    WeeklyPlanningSetPlanningType,
    WeeklyPlanningTopHeaderSectionDataRequest,
} from 'src/app/store/weekly-planning/actions';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-weekly-planning-daily',
    templateUrl: './weekly-planning-daily.component.html',
    styleUrls: ['./weekly-planning-daily.component.scss'],
})
export class WeeklyPlanningDailyComponent extends BaseComponent implements OnInit {
    projectTeamName: string;
    planningType: string;
    phaseType: PhaseType;
    todayDate: Date;

    currentWeekStartDate: moment.Moment;
    weekOptions: { startDate: moment.Moment; endDate: moment.Moment }[] = [];
    selectedWeekStartDate: UntypedFormControl;
    selectedWeekEndDate: Date;
    isWeekSelectorVisible = false;
    @ViewChild('weekSelector') weekSelector: MatSelect;

    mcDisplayedColumns = [
        'subsystem',
        'subsystemName',
        'mcActual',
        'walkdownActual',
        'aqvd',
        'apli',
        'redline',
        'npw',
        'bpli',
        'cpli',
        'comment',
        'actions',
    ];
    rfsuDisplayedColumns = [
        'subsystem',
        'subsystemName',
        'systemOwner',
        'rfsuActual',
        'rfsuPlan',
        'rfsuForecast',
        'walkdownActual',
        'walkdownForecast',
        'edossierInitiationDate',
        'edossierStatus',
        'aqvd',
        'bitr',
        'citr',
        'apli',
        'redline',
        'bpli',
        'cpli',
        'npw',
        'comment',
        'actions',
    ];
    rfoDisplayedColumns = [
        'rfo',
        'rfoName',
        'rfoPlan',
        'rfoForecast',
        'aqvd',
        'bitr',
        'citr',
        'apli',
        'redline',
        'bpli',
        'cpli',
        'npw',
        'comment',
        'actions',
    ];
    displayedColumns = this.mcDisplayedColumns;
    addWeeklyText = 'Add weekly subsystem';
    commitmentTypes = CommitmentType;
    weeklyCommitments: { element: DailyDashboardDTO; isGreenForced: boolean }[] = [];
    additionsToWeeklyCommitments: DailyDashboardDTO[] = [];
    additionsToWeeklyCommitmentsTable: MatTableDataSource<{
        element: DailyDashboardDTO;
        isInEditMode: boolean;
        isInitial: boolean;
    }>;
    isLoading = false;
    printTitle = '';
    extendedMode = false;
    isAddingWeeklySubsystemDisabled = false;
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    additionalAdditions: DailyDashboardDTO[] = [];
    additionalForced: DailyDashboardDTO[] = [];
    hasAdminPriviliged: boolean = false;
    showConfig = {
        showWeeklyCommitmentsComments: true,
        showAdditionsComments: true,
    };
    filter: WeeklyPlanningFilter;

    constructor(
        private activatedRoute: ActivatedRoute,
        private weeklyPlanningService: WeeklyPlanningService,
        private toastService: ToastService,
        private lookupService: LookupService,
        private changeDetectorRef: ChangeDetectorRef,
        private roleService: RoleService,
        private store: Store<ApplicationState>
    ) {
        super();
        this.hasAdminPriviliged = this.roleService.isInRole(Constants.applicableGroups.Admin);
        const todayDateMoment = moment();
        this.todayDate = todayDateMoment.toDate();
        this.printTitle = `daily dashboard ${todayDateMoment.format('MMM D Y').toLowerCase()}`;

        this.additionsToWeeklyCommitmentsTable = new MatTableDataSource();
        this.currentWeekStartDate = getCurrentWeekStartDate();
        this.selectedWeekStartDate = new UntypedFormControl(this.currentWeekStartDate);
        this.selectedWeekEndDate = moment(this.selectedWeekStartDate.value).add(6, 'days').toDate();
        this.setWeekOptions();
        this.filter = new WeeklyPlanningFilter();
        this.filter.startDate = this.selectedWeekStartDate.value;
    }

    ngOnInit() {
        this.activatedRoute.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            this.projectTeamName = paramMap.get('projectTeamName');
            this.planningType = paramMap.get('planningType');
            switch (this.planningType) {
                case 'mc': {
                    this.phaseType = PhaseType.MC;
                    break;
                }
                case 'rfsu': {
                    this.phaseType = PhaseType.RFSU;
                    break;
                }
                case 'rfo': {
                    this.phaseType = PhaseType.RFO;
                    break;
                }
                default: {
                    this.phaseType = -1;
                    break;
                }
            }
            this.store.dispatch(new WeeklyPlanningSetPlanningType(this.planningType));

            if (this.commitmentTypes[this.planningType.toUpperCase()] === CommitmentType.RFSU) {
                this.displayedColumns = this.rfsuDisplayedColumns;
            }
            if (this.commitmentTypes[this.planningType.toUpperCase()] === CommitmentType.RFO) {
                this.displayedColumns = this.rfoDisplayedColumns;
                this.addWeeklyText = 'Add weekly RFO';
            }
        });
        this.search(this.filter);

        this.selectedWeekStartDate.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
            this.filter.startDate = this.selectedWeekStartDate.value;
            this.search(this.filter);
            this.selectedWeekEndDate = moment(this.selectedWeekStartDate.value).add(6, 'days').toDate();
        });
    }

    private setWeekOptions() {
        const currentWeek = {
            startDate: moment(this.currentWeekStartDate),
            endDate: moment(this.currentWeekStartDate).add(6, 'days'),
        };
        this.weekOptions.push(currentWeek);
        for (let index = 1; index <= 4; index++) {
            this.weekOptions.push({
                startDate: moment(currentWeek.startDate).add(-index, 'weeks'),
                endDate: moment(currentWeek.endDate).add(-index, 'weeks'),
            });
        }
        this.weekOptions = _.sortBy(this.weekOptions, ['startDate']);
    }

    compareDates(d1: moment.Moment, d2: moment.Moment) {
        return d1.isSame(d2, 'day');
    }

    showWeekSelector() {
        this.isWeekSelectorVisible = true;
        setTimeout(() => {
            this.weekSelector.open();
        }, 0);
    }

    onWeekSelectorClosed(isOpen: boolean) {
        this.isWeekSelectorVisible = isOpen;
    }

    setWeekOptionClasses(week: { startDate: moment.Moment; endDate: moment.Moment }) {
        return {
            'current-week': week.startDate.isSame(this.currentWeekStartDate, 'day'),
            'past-week': week.startDate.isBefore(this.currentWeekStartDate, 'day'),
            'future-week': week.startDate.isAfter(this.currentWeekStartDate, 'day'),
        };
    }

    search(filter: WeeklyPlanningFilter) {
        this.filter = filter;
        this.filter.startDate = this.selectedWeekStartDate.value;
        this.isLoading = true;

        this.store.dispatch(
            new WeeklyPlanningTopHeaderSectionDataRequest({ projectTeamName: this.projectTeamName, filter: filter })
        );

        this.weeklyPlanningService
            .getDailyDashboardData(this.projectTeamName, this.planningType, filter)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                ({ weeklyCommitments, additionsToWeeklyCommitments }: any) => {
                    this.weeklyCommitments = weeklyCommitments.map((x) => ({ element: x, isGreenForced: false }));
                    this.weeklyCommitments = _.orderBy(this.weeklyCommitments, [
                        'element.scManager',
                        'element.systemOwner',
                    ]);
                    this.additionsToWeeklyCommitments = additionsToWeeklyCommitments;
                    this.additionsToWeeklyCommitmentsTable = new MatTableDataSource(
                        additionsToWeeklyCommitments.map((x) => ({ element: x, isInEditMode: false, isInitial: true }))
                    );
                },
                () => {
                    this.toastService.Error(
                        'Error has occurred while getting daily dashboard data. Please contact Program Administrator.'
                    );
                }
            );
    }

    addWeeklySubsystem() {
        this.additionsToWeeklyCommitmentsTable.data.push({
            element: new DailyDashboardDTO(),
            isInEditMode: true,
            isInitial: false,
        });
        this.additionsToWeeklyCommitmentsTable.filter = '';

        this.isAddingWeeklySubsystemDisabled = true;
    }

    getSubsystems = (search = '') => {
        return this.lookupService.getSubsystemsForDailyDashboard(this.projectTeamName, this.phaseType).pipe(
            map((data: DailyDashboardDTO[]) =>
                data
                    .filter(
                        (x) =>
                            !this.additionsToWeeklyCommitments
                                .map((y) => y.subsystem)
                                .concat(
                                    this.additionsToWeeklyCommitmentsTable.data
                                        .filter((y) => !y.isInEditMode)
                                        .map((y) => y.element.subsystem)
                                )
                                .includes(x.subsystem)
                    )
                    .filter((x) => x.subsystem.toLowerCase().trim().includes(search.toLowerCase().trim()))
            )
        );
    };

    getRFOs = (search = '') => {
        return this.lookupService.getRFOsForDailyDashboard(this.projectTeamName, this.phaseType).pipe(
            map((data: DailyDashboardDTO[]) =>
                data
                    .filter(
                        (x) =>
                            !this.additionsToWeeklyCommitments
                                .map((y) => y.rfo)
                                .concat(
                                    this.additionsToWeeklyCommitmentsTable.data
                                        .filter((y) => !y.isInEditMode)
                                        .map((y) => y.element.rfo)
                                )
                                .includes(x.rfo)
                    )
                    .filter((x) => x.rfo.toLowerCase().trim().includes(search.toLowerCase().trim()))
            )
        );
    };

    changeExtendedMode() {
        if (this.extendedMode) {
            this.additionalForced = [];
            this.additionalAdditions = [];
            this.isAddingWeeklySubsystemDisabled = false;

            this.weeklyCommitments.forEach((x) => (x.isGreenForced = false));
            this.additionsToWeeklyCommitmentsTable.data = this.additionsToWeeklyCommitmentsTable.data.filter(
                (x) => x.isInitial === true
            );
        }
        this.extendedMode = !this.extendedMode;
    }

    onSubsystemsClosed($event: any[], index: number) {
        if ($event[0]) {
            const item = this.additionsToWeeklyCommitmentsTable.data[index];

            if (item.isInEditMode) {
                item.element = $event[0];
                item.isInEditMode = false;
                this.additionalAdditions = [...this.additionalAdditions, item.element];

                this.setSubsystemInput.emit(new SetInputEventArgs(true));
                this.isAddingWeeklySubsystemDisabled = false;
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    onRFOsClosed($event: any[], index: number) {
        if ($event[0]) {
            const item = this.additionsToWeeklyCommitmentsTable.data[index];

            if (item.isInEditMode) {
                item.element = $event[0];
                item.isInEditMode = false;
                this.additionalAdditions = [...this.additionalAdditions, item.element];

                this.setSubsystemInput.emit(new SetInputEventArgs(true));
                this.isAddingWeeklySubsystemDisabled = false;
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    removeRow(index: number) {
        const item = this.additionsToWeeklyCommitmentsTable.data[index];

        if (item.isInEditMode) {
            this.isAddingWeeklySubsystemDisabled = false;
        }

        this.additionsToWeeklyCommitmentsTable.data.splice(index, 1);
        this.additionsToWeeklyCommitmentsTable.filter = '';
        this.additionalAdditions = this.additionalAdditions.filter((x) => x.subsystem !== item.element.subsystem);
    }

    toggleGreen(item: { element: DailyDashboardDTO; isGreenForced: boolean }) {
        item.isGreenForced = !item.isGreenForced;

        if (item.isGreenForced) {
            this.additionalForced = [...this.additionalForced, item.element];
        } else {
            this.additionalForced = this.additionalForced.filter((x) => x.subsystem !== item.element.subsystem);
        }
    }

    shouldHighlightWeeklyCommitment(item: { element: DailyDashboardDTO; isGreenForced: boolean }) {
        if (item.isGreenForced) {
            return true;
        }

        const actualDate =
            this.planningType.toLocaleLowerCase() === 'mc'
                ? item.element.mcActual
                : this.planningType.toLocaleLowerCase() === 'rfsu'
                ? item.element.rfsuActual
                : item.element.rfoActual;
        if (!actualDate) return false;

        return moment(actualDate).isSameOrBefore(this.selectedWeekEndDate, 'days');
    }

    shouldHighlightAdditionToWeeklyCommitment(item: {
        element: DailyDashboardDTO;
        isInEditMode: boolean;
        isInitial: boolean;
    }) {
        if (item.isInitial) {
            const actualDate =
                this.planningType.toLocaleLowerCase() === 'mc'
                    ? item.element.mcActual
                    : this.planningType.toLocaleLowerCase() === 'rfsu'
                    ? item.element.rfsuActual
                    : item.element.rfoActual;
            if (!actualDate) return false;

            return (
                moment(actualDate).isSameOrAfter(this.selectedWeekStartDate.value, 'days') &&
                moment(actualDate).isSameOrBefore(this.selectedWeekEndDate, 'days')
            );
        }

        return true;
    }
}
