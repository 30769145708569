import { Action } from "@ngrx/store";
import { BaseResultDTO } from "../../common.model";
import { SubsystemRFSUDashboardDTO } from "./model";

export enum SubsystemRFSUDashboardActionTypes {
    SubsystemRFSUDashboardRequest = '[Subsystem RFSU Dashboard] Request',
    SubsystemRFSUDashboardSuccess = '[Subsystem RFSU Dashboard] Success',
    SubsystemRFSUDashboardError = '[Subsystem RFSU Dashboard] Error',

    SubsystemRFSUDashboardUpdateFilterProperties = '[Subsystem RFSU Dashboard] Update Filter Properties',
    SubsystemRFSUDashboardResetFilter = '[Subsystem RFSU Dashboard] Reset Filter',
    SubsystemRFSUDashboardExportToExcelRequest = '[Subsystem RFSU Dashboard] Export To Excel Request',
    SubsystemRFSUDashboardExportToExcelSuccess = '[Subsystem RFSU Dashboard] Export to Excel Success',
    SubsystemRFSUDashboardExportToExcelError = '[Subsystem RFSU Dashboard] Export to Excel Error',
    SubsystemRFSUDasbboardRedirectToDetailedStatus = '[Subsystem RFSU Dashboard] Redirect to DetailedStatus',
}

export class SubsystemRFSUDashboardRequest implements Action {
    readonly type = SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardRequest;
}

export class SubsystemRFSUDashboardExportToExcelRequest implements Action {
    readonly type = SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardExportToExcelRequest;
}

export class SubsystemRFSUDashboardExportToExcelSuccess implements Action {
    readonly type = SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class SubsystemRFSUDashboardExportToExcelError implements Action {
    readonly type = SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardExportToExcelError;

    constructor(public payload: string) {}
}

export class SubsystemRFSUDashboardSuccess implements Action {
    readonly type = SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardSuccess;

    constructor(public payload: BaseResultDTO<SubsystemRFSUDashboardDTO>) {}
}

export class SubsystemRFSUDashboardError implements Action {
    readonly type = SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardError;

    constructor(public payload: string) {}
}

export class SubsystemRFSUDashboardUpdateFilterProperties implements Action {
    readonly type = SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardUpdateFilterProperties;

    constructor(public payload: { [key: string]: any }) {}
}

export class SubsystemRFSUDashboardResetFilter implements Action {
    readonly type = SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardResetFilter;
}

export class SubsystemRFSUDasbboardRedirectToDetailedStatus implements Action {
    readonly type = SubsystemRFSUDashboardActionTypes.SubsystemRFSUDasbboardRedirectToDetailedStatus;

    constructor(public payload: { subsystem: string; scope: string; exceptions: boolean; category: string; zone: string; status: string[]; }) {}
}

export type SubsystemRFSUDashboardActions =
    | SubsystemRFSUDashboardRequest
    | SubsystemRFSUDashboardSuccess
    | SubsystemRFSUDashboardError
    | SubsystemRFSUDashboardUpdateFilterProperties
    | SubsystemRFSUDashboardResetFilter
    | SubsystemRFSUDasbboardRedirectToDetailedStatus
    | SubsystemRFSUDashboardExportToExcelRequest
    | SubsystemRFSUDashboardExportToExcelSuccess
    | SubsystemRFSUDashboardExportToExcelError;