<main class="main">
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    <div class="main-container">
        <mat-accordion>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>general app configuration</h4>
                </mat-expansion-panel-header>
                <div class="results-container">
                    <table mat-table [dataSource]="commonConfigData" matSortDisableClear>
                        <ng-container matColumnDef="key">
                            <th mat-header-cell disableClear *matHeaderCellDef>Key</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="infotooltip" *ngIf="element.tooltip">
                                    {{ element.key }}
                                    <mat-icon matTooltip="{{ element.tooltip }}" matTooltipClass="scttooltip"
                                        >info
                                    </mat-icon>
                                </div>
                                <ng-container *ngIf="!element.tooltip">{{ element.key }}</ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <th mat-header-cell disableClear *matHeaderCellDef>Value</th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngIf="!element.isInEditMode">{{ element.value }}</div>
                                <div *ngIf="element.isInEditMode" class="editmode">
                                    <mat-form-field
                                        ><input
                                            matInput
                                            [(ngModel)]="element.value"
                                            [ngModelOptions]="{ standalone: true }"
                                            name="value"
                                            autocomplete="off"
                                        />
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell disableClear *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button
                                    *ngIf="!element.isInEditMode"
                                    mat-icon-button
                                    (click)="enterEditMode(element)"
                                    title="edit"
                                >
                                    <mat-icon svgIcon="edit"></mat-icon>
                                </button>
                                <button
                                    *ngIf="element.isInEditMode"
                                    mat-icon-button
                                    (click)="save(element)"
                                    title="accept"
                                >
                                    <mat-icon>check_circle_outline</mat-icon>
                                </button>
                                <button
                                    *ngIf="element.isInEditMode"
                                    mat-icon-button
                                    (click)="cancelEdit(element)"
                                    title="cancel"
                                >
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>A-QVD/B-ITR/C-ITR rundown configuration</h4>
                </mat-expansion-panel-header>
                <div class="import-subtitle">
                    The A-QVD rundown (Plan) is calculated assuming all QVDs for a given subsystem are completed within
                    an Admin set duration from the MC Date (Plan). This assumes a linear distribution of QVDs within
                    this duration for each subsystem.
                    <img src="/assets/images/aqvdduration.PNG" alt="image" />
                </div>
                <div class="results-container">
                    <table mat-table [dataSource]="rundownConfigData" matSortDisableClear>
                        <ng-container matColumnDef="key">
                            <th mat-header-cell disableClear *matHeaderCellDef>Key</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="infotooltip" *ngIf="element.tooltip">
                                    {{ element.key }}
                                    <mat-icon matTooltip="{{ element.tooltip }}" matTooltipClass="scttooltip"
                                        >info
                                    </mat-icon>
                                </div>
                                <ng-container *ngIf="!element.tooltip">{{ element.key }}</ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <th mat-header-cell disableClear *matHeaderCellDef>Value</th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngIf="!element.isInEditMode">{{ element.value }}</div>
                                <div *ngIf="element.isInEditMode" class="editmode">
                                    <mat-form-field
                                        ><input
                                            matInput
                                            [(ngModel)]="element.value"
                                            [ngModelOptions]="{ standalone: true }"
                                            name="value"
                                            autocomplete="off"
                                        />
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell disableClear *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button
                                    *ngIf="!element.isInEditMode"
                                    mat-icon-button
                                    (click)="enterEditMode(element)"
                                    title="edit"
                                >
                                    <mat-icon svgIcon="edit"></mat-icon>
                                </button>
                                <button
                                    *ngIf="element.isInEditMode"
                                    mat-icon-button
                                    (click)="save(element)"
                                    title="accept"
                                >
                                    <mat-icon>check_circle_outline</mat-icon>
                                </button>
                                <button
                                    *ngIf="element.isInEditMode"
                                    mat-icon-button
                                    (click)="cancelEdit(element)"
                                    title="cancel"
                                >
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>B-ITR and C-ITR duration configuration by RFO</h4>
                </mat-expansion-panel-header>
                <table mat-table [dataSource]="durationConfig">
                    <ng-container matColumnDef="rfoNumber">
                        <th mat-header-cell disableClear *matHeaderCellDef>RFO Number</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="infotooltip" *ngIf="element.tooltip">
                                {{ element.rfoNumber }}
                                <mat-icon matTooltip="{{ element.tooltip }}" matTooltipClass="scttooltip"
                                    >info
                                </mat-icon>
                            </div>
                            <ng-container *ngIf="!element.tooltip">{{ element.rfoNumber }}</ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="rfoName">
                        <th mat-header-cell disableClear *matHeaderCellDef>RFO Name</th>
                        <td mat-cell *matCellDef="let element">{{ element.rfoName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="bitrDuration">
                        <th mat-header-cell disableClear *matHeaderCellDef>B-ITR Duration</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="!element.isInEditMode">{{ element.bitrDuration }}</div>
                            <div *ngIf="element.isInEditMode" class="editmode">
                                <mat-form-field
                                    ><input
                                        matInput
                                        [(ngModel)]="element.bitrDuration"
                                        [ngModelOptions]="{ standalone: true }"
                                        name="bitrDuration"
                                        autocomplete="off"
                                        type="number"
                                        min="1"
                                    />
                                </mat-form-field>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="citrDuration">
                        <th mat-header-cell disableClear *matHeaderCellDef>C-ITR Duration</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="!element.isInEditMode">{{ element.citrDuration }}</div>
                            <div *ngIf="element.isInEditMode" class="editmode">
                                <mat-form-field
                                    ><input
                                        matInput
                                        [(ngModel)]="element.citrDuration"
                                        [ngModelOptions]="{ standalone: true }"
                                        name="citrDuration"
                                        autocomplete="off"
                                        type="number"
                                        min="1"
                                    />
                                </mat-form-field>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell disableClear *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                *ngIf="!element.isInEditMode"
                                mat-icon-button
                                (click)="enterDurationEditMode(element)"
                                title="edit"
                            >
                                <mat-icon svgIcon="edit"></mat-icon>
                            </button>
                            <button
                                *ngIf="element.isInEditMode"
                                mat-icon-button
                                (click)="saveDuration(element)"
                                title="accept"
                            >
                                <mat-icon>check_circle_outline</mat-icon>
                            </button>
                            <button
                                *ngIf="element.isInEditMode"
                                mat-icon-button
                                (click)="cancelDurationEdit(element)"
                                title="cancel"
                            >
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="durationDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: durationDisplayedColumns"></tr>
                </table>
            </mat-expansion-panel>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>B-ITR and C-ITR rundown HVAC configuration</h4>
                </mat-expansion-panel-header>
                <app-subsystem-selector
                    class="subsys-select filter-container"
                    [setSelectedSubsystems]="hvacSelectedSubsystems"
                    (selectedSubsystemsChanged)="setHVACSubsystems($event)"
                    [showButtons]="true"
                >
                </app-subsystem-selector>
            </mat-expansion-panel>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>Cleanliness configuration</h4>
                </mat-expansion-panel-header>
                <app-cleanliness-configuration [cleanlinessType]="'MC'"></app-cleanliness-configuration>
                <app-cleanliness-configuration [cleanlinessType]="'RFSU'"></app-cleanliness-configuration>
            </mat-expansion-panel>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>FTP configuration</h4>
                </mat-expansion-panel-header>
                <ftp-configuration></ftp-configuration>
            </mat-expansion-panel>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>BlueZone Backlog Categories configuration</h4>
                </mat-expansion-panel-header>
                <app-backlog-categories></app-backlog-categories>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>ITR Constraint Flag configuration</h4>
                </mat-expansion-panel-header>
                <app-constraintflag></app-constraintflag>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>DCN/RFI register configuration</h4>
                </mat-expansion-panel-header>
                <div class="results-container">
                    <table mat-table [dataSource]="dcnrfiConfigData" matSortDisableClear>
                        <ng-container matColumnDef="key">
                            <th mat-header-cell disableClear *matHeaderCellDef>Key</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="infotooltip" *ngIf="element.tooltip">
                                    {{ element.key }}
                                    <mat-icon matTooltip="{{ element.tooltip }}" matTooltipClass="scttooltip">
                                        info
                                    </mat-icon>
                                </div>
                                <ng-container *ngIf="!element.tooltip">{{ element.key }}</ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <th mat-header-cell disableClear *matHeaderCellDef>Value</th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngIf="!element.isInEditMode">{{ signOffFieldImplementationModeDisplay[element.value] }}</div>
                                <div *ngIf="element.isInEditMode" class="editmode">
                                    <mat-form-field>
                                        <mat-select 
                                            class="mat-arrow"
                                            appearance="none"
                                            disableOptionCentering
                                            matInput
                                            [(ngModel)]="element.value"
                                            [ngModelOptions]="{ standalone: true }"
                                        >
                                            <mat-option [value]="SignOffFieldImplementationMode.DisabledForRLMUToGo">
                                                {{ signOffFieldImplementationModeDisplay[SignOffFieldImplementationMode.DisabledForRLMUToGo] }}
                                            </mat-option>
                                            <mat-option [value]="SignOffFieldImplementationMode.EnabledForAll">
                                                {{ signOffFieldImplementationModeDisplay[SignOffFieldImplementationMode.EnabledForAll] }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell disableClear *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button
                                    *ngIf="!element.isInEditMode"
                                    mat-icon-button
                                    (click)="enterEditMode(element)"
                                    title="edit"
                                >
                                    <mat-icon svgIcon="edit"></mat-icon>
                                </button>
                                <button
                                    *ngIf="element.isInEditMode"
                                    mat-icon-button
                                    (click)="save(element)"
                                    title="accept"
                                >
                                    <mat-icon>check_circle_outline</mat-icon>
                                </button>
                                <button
                                    *ngIf="element.isInEditMode"
                                    mat-icon-button
                                    (click)="cancelEdit(element)"
                                    title="cancel"
                                >
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>Constraint Type configuration</h4>
                </mat-expansion-panel-header>
                <app-constraint-type></app-constraint-type>
            </mat-expansion-panel>

            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" [hidden] = "true">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>PLI Implementation Team</h4>
                </mat-expansion-panel-header>
                <app-pli-implementation-team></app-pli-implementation-team>
            </mat-expansion-panel>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <h4>Responsible Group configuration</h4>
                </mat-expansion-panel-header>
                <app-responsible-group></app-responsible-group>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</main>
