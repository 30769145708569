import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { ChangeType } from 'src/app/enums';
import { changeTypeEnumToString} from 'src/app/extensions';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';

@Component({
  selector: 'app-change-management-manual',
  templateUrl: './change-management-manual.component.html',
  styleUrls: ['./change-management-manual.component.scss']
})
export class ChangeManagementManualComponent extends BaseComponent implements OnInit {
  changeTypes = ChangeType;
  MACDCNRegisterName = 'MAC DCN List.xlsx';
  RFIRegisterName = 'RFI Register.xlsx';

  constructor(private adminPanelService: AdminPanelService) {
    super();
  }

  ngOnInit(): void {
    // This is intentional
  }

  downloadRegisterTemplate(name: string) {
    this.adminPanelService.downloadRegisterTemplate(name);
  }

  getChangeTypeString(changeType: ChangeType){
    return changeTypeEnumToString(changeType)
  }

}
