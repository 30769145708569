import * as moment from 'moment';
import { CommentDTO } from '../common.model';

export interface DetailsState {
    item: DetailedStatusDTO;
    comments: CommentDTO[];
    constraintComments: CommentDTO[];
    materialDetails: MaterialsDTO[];
    isLoading: boolean;
}

export class DetailedStatusDTO {
    id: number = null;
    itrid: number = null;
    subsystem = '';
    subsystemName = '';
    tagNumber = '';
    tagType = '';
    number = '';
    description = '';
    contractor = '';
    status = '';
    comments: CommentDTO[] = [];
    category = '';
    actionBy = '';
    raisedBy = '';
    createdDate: moment.Moment;
    punchType = '';
    exceptionNumber = '';
    projectTeamName = '';
    redReqType = '';
    coreworxLink = '';
    docGroup = '';
    area = '';
    unit = '';
    revisionType = '';
    cwp = '';
    moc = '';
    mcdrConstrRedCat = '';
    redStatus = '';
    redDate: Date;
    redType = '';
    discipline = '';
    revision = '';
    docType = '';
    attachments: AttachmentDTO[] = [];
    cowDocNumber = '';
    reviewStatus: ReviewStatusDTO[] = [];
    reviewStatusComplete = '';
    barcode = '';
    constraintFlag = '';
    activityName = '';
    activityID = '';
    rfo = '';
    workOrderNum = '';

    itrConstraintDeleted = '';

    itrConstraintDueDate: moment.Moment;
    fmRorPOETADate: moment.Moment;
    itrConstraintRaisedDate: moment.Moment;
    itrConstraintStatusDate: moment.Moment;
    itrConstraintClosedByUser = '';
    itrConstraintRaisedByUser = '';
    itrConstraintRaisedByTeam = '';
    itrConstraintResponsibleUser = '';
    itrConstraintResponsibleGroup = '';
    itrConstraintAdditionalResponsibleUser = '';
    itrConstraintTypeName = '';
    fmRorPO = '';
    waypointRequired = '';
    associatedNPW = '';
    associatedPLI = '';
    latestITRComment = '';
    associatedComment = '';
    aqvdContractor = '';
    pliActionedBy = '';
    itrConstraintIDString  = '';
    itrConstraintDescription = '';
    itrConstraintStatus = '';
    tagName = 0;
    itrConstraintId: number = null;
    constraintComments: CommentDTO[] = [];
    materialDetails : MaterialsDTO[] = [];
    itrConstraintCount:number = null;    
    itrConstraintToITRId:number = null;
    exceptionPriority='';
    materialReviewStatus= '';
    materialReviewAssignedTo= '';
}

export class ReviewStatusDTO {
    signOffDate: moment.Moment = null;
    user = '';
    type: PLIReviewType = PLIReviewType.TBC;
}

export class MaterialsDTO {
    itemCode : string = '';
    abbreviated : string = '';
    size : string  = '' ;
    qty: string = '' ;
    uom: string = '' ;
    status: string = '';
    quarantine: string = '';
    eta: moment.Moment;
    cwp: string = '';
    }

export enum PLIReviewType {
    TBC = 0,
    Rejected = 1,
    Endorsed = 2,
}

export class AttachmentDTO {
    name: string;
    link: string;
}

export class Attachment {
    id: number;
    name = '';
    link = '';
    documentNo = '';
    loading: boolean;
    file: File;
    isValid: boolean;
    isBeingProcessed: boolean;
}
