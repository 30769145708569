import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { appConfig } from 'src/app/app.config';
import { ProjectTeamMappingDTO } from "src/app/models/project-team-mapping-dto";

@Injectable({
    providedIn: 'root',
})
export class ProjectTeamMappingService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/projectteammapping';
    }

    get(uploadType: string) {
        return this.http.get<ProjectTeamMappingDTO[]>(`${this.rootUrl}${this.endpoint}?uploadType=${uploadType}`);
    }

    remove(id: number) {
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?id=${id}`);
    }

    update(projectTeamMapping: ProjectTeamMappingDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, projectTeamMapping);
    }

    add(projectTeamMapping: ProjectTeamMappingDTO) {
        return this.http.post<ProjectTeamMappingDTO>(`${this.rootUrl}${this.endpoint}`, projectTeamMapping);
    }
}