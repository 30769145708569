import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { ExceptionsPriorityDeletePagination } from './model';

export enum ExceptionsPriorityActionTypes {
    ExceptionsPriorityUploadLogRequest = '[Exceptions Priority] Upload Log Request',
    ExceptionsPriorityUploadLogSuccess = '[Exceptions Priority] Upload Log Success',
    ExceptionsPriorityUploadLogError = '[Exceptions Priority] Upload Log Error',
    ExceptionsPriorityDeleteRecordsRequest = '[Exceptions Priority] Delete Records Request',
    ExceptionsPriorityDeleteRecordsSuccess = '[Exceptions Priority] Delete Records Success',
    ExceptionsPriorityDeleteRecordsError = '[Exceptions Priority] Delete Records Error',
    ExceptionsPrioritySetStatusAndStartDate = '[Exceptions Priority] Set Status and Start Date',
    ExceptionsPriorityDeleteFilterPropertyUpdate = '[Exceptions Priority] Delete Filter Property Update',
    ExceptionsPriorityDeleteFilterReset = '[Exceptions Priority] Delete Filter Reset',
    ExceptionsPriorityPatchDeleteRecordRequest = '[Exceptions Priority] Patch Delete Record Request',
    ExceptionsPriorityPatchDeleteRecordSuccess = '[Exceptions Priority] Patch Delete Record Success',
    ExceptionsPriorityPatchDeleteRecordError = '[Exceptions Priority] Patch Delete Record Error',
    ExceptionsPriorityPatchAllDeleteRecordsRequest = '[Exceptions Priority] Patch All Delete Records Request',
    ExceptionsPriorityPatchAllDeleteRecordsSuccess = '[Exceptions Priority] Patch All Delete Records Success',
    ExceptionsPriorityPatchAllDeleteRecordsError = '[Exceptions Priority] Patch All Delete Records Error',
    ExceptionsPriorityValidateDataRequest = '[Exceptions Priority] Validate Data Request',
    ExceptionsPriorityValidateDataSuccess = '[Exceptions Priority] Validate Data Success',
    ExceptionsPriorityValidateDataError = '[Exceptions Priority] Validate Data Error',
    ExceptionsPriorityFileUploaded = '[Exceptions Priority] File Uploaded',
    ExceptionsPriorityClearInProgressSpinner = '[Exceptions Priority] Clear In Progress Spinner',
    ExceptionsPriorityTemplateFileRequest = '[Exceptions Priority] Template File Request',
    ExceptionsPriorityTemplateFileSuccess = '[Exceptions Priority] Template File Success',
    ExceptionsPriorityTemplateFileError = '[Exceptions Priority] Template File Error',
    ExceptionsPriorityDownloadOutputDataRequest = '[Exceptions Priority] Download Output Data Request',
    ExceptionsPriorityDownloadOutputDataSuccess = '[Exceptions Priority] Download Output Data Success',
    ExceptionsPriorityDownloadOutputDataError = '[Exceptions Priority] Download Output Data Error',
    ExceptionsPriorityValidateButtonStateRequest = '[Exceptions Priority] Validate Button State Request',
    ExceptionsPriorityValidateButtonStateSuccess = '[Exceptions Priority] Validate Button State Success',
    ExceptionsPriorityValidateButtonStateError = '[Exceptions Priority] Validate Button State Error',
    ExceptionsPriorityAddStartedLog = '[Exceptions Priority] Add Started Log',
}

export class ExceptionsPriorityAddStartedLog implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityAddStartedLog;
}

export class ExceptionsPriorityUploadLogRequest implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityUploadLogRequest;
}

export class ExceptionsPriorityUploadLogSuccess implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ExceptionsPriorityUploadLogError implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityUploadLogError;

    constructor(public payload: string) {}
}

export class ExceptionsPriorityDeleteRequest implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteRecordsRequest;
}

export class ExceptionsPriorityDeleteSuccess implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteRecordsSuccess;

    constructor(public payload: ExceptionsPriorityDeletePagination) {}
}

export class ExceptionsPriorityDeleteError implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ExceptionsPrioritySetStatusAndStartDate implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPrioritySetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class ExceptionsPriorityDeleteFilterPropertyUpdate implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ExceptionsPriorityDeleteFilterReset implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteFilterReset;
}

export class ExceptionsPriorityPatchDeleteRequest implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityPatchDeleteRecordRequest;

    constructor(public payload: { exception: string; deleteState: boolean }) {}
}

export class ExceptionsPriorityPatchAllDeleteRequest implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ExceptionsPriorityPatchDeleteSuccess implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityPatchDeleteRecordSuccess;
}

export class ExceptionsPriorityPatchAllDeleteSuccess implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ExceptionsPriorityPatchDeleteError implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class ExceptionsPriorityPatchAllDeleteError implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ExceptionsPriorityValidateDeleteRequest implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityValidateDataRequest;
}

export class ExceptionsPriorityValidateDeleteSuccess implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityValidateDataSuccess;
}

export class ExceptionsPriorityValidateDeleteError implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityValidateDataError;

    constructor(public payload: string) {}
}

export class ExceptionsPriorityFileUploaded implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityFileUploaded;
}

export class ExceptionsPriorityClearInProgressSpinner implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityClearInProgressSpinner;
}

export class ExceptionsPriorityTemplateFileRequest implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ExceptionsPriorityTemplateFileSuccess implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ExceptionsPriorityTemplateFileError implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityTemplateFileError;

    constructor(public payload: string) {}
}

export class ExceptionsPriorityDownloadOutputDataRequest implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ExceptionsPriorityDownloadOutputDataSuccess implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ExceptionsPriorityDownloadOutputDataError implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ExceptionsPriorityValidateButtonStateRequest implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityValidateButtonStateRequest;
}

export class ExceptionsPriorityValidateButtonStateSuccess implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class ExceptionsPriorityValidateButtonStateError implements Action {
    readonly type = ExceptionsPriorityActionTypes.ExceptionsPriorityValidateButtonStateError;

    constructor(public payload: string) {}
}

export type ExceptionsPriorityActions =
    | ExceptionsPriorityUploadLogRequest
    | ExceptionsPriorityUploadLogSuccess
    | ExceptionsPriorityUploadLogError
    | ExceptionsPriorityDeleteRequest
    | ExceptionsPriorityDeleteSuccess
    | ExceptionsPriorityDeleteError
    | ExceptionsPrioritySetStatusAndStartDate
    | ExceptionsPriorityDeleteFilterPropertyUpdate
    | ExceptionsPriorityDeleteFilterReset
    | ExceptionsPriorityPatchDeleteRequest
    | ExceptionsPriorityPatchDeleteSuccess
    | ExceptionsPriorityPatchDeleteError
    | ExceptionsPriorityPatchAllDeleteRequest
    | ExceptionsPriorityPatchAllDeleteSuccess
    | ExceptionsPriorityPatchAllDeleteError
    | ExceptionsPriorityValidateDeleteRequest
    | ExceptionsPriorityValidateDeleteSuccess
    | ExceptionsPriorityValidateDeleteError
    | ExceptionsPriorityFileUploaded
    | ExceptionsPriorityClearInProgressSpinner
    | ExceptionsPriorityTemplateFileRequest
    | ExceptionsPriorityTemplateFileSuccess
    | ExceptionsPriorityTemplateFileError
    | ExceptionsPriorityDownloadOutputDataRequest
    | ExceptionsPriorityDownloadOutputDataSuccess
    | ExceptionsPriorityDownloadOutputDataError
    | ExceptionsPriorityValidateButtonStateRequest
    | ExceptionsPriorityValidateButtonStateSuccess
    | ExceptionsPriorityValidateButtonStateError
    | ExceptionsPriorityAddStartedLog;
