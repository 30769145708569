import * as moment from 'moment';
import { OrderDirection, CheckListColumn, CalendarColumn, UserDetail } from '../common.model';

export interface PascrStatusState {
    dataPagination: PascrStatusDataPagination;
    filter: PascrStatusFilter;
    isLoading: boolean;
}

export class PascrStatusDataPagination {
    items: PascrStatusDTO[] = [];
    totalCount = 0;
}

export class PascrStatusFilter {
    page = 0;
    take = 25
    tagTypes: string[] = [];
    tags: { id: string }[] = [];
    tagDescription = '';
    tagStatuses: string[] = [];
    projectTeamNames: string[] = [];
    subsystems: { id: string }[] = [];
    timezoneOffset = 0;
    sortBy = 'pascrNumber';
    direction = OrderDirection.Asc;
    showHideColumns = new ShowHideColumns();
    columnPASCRNumber: CheckListColumn = null;
    columnDescription: CheckListColumn = null;	
    columnTagType: CheckListColumn = null;	
    columnSubSystem: CheckListColumn = null;	
    columnRfo: CheckListColumn = null;	
    columnApprovalStage: CheckListColumn = null;	
    columnStatus: CheckListColumn = null;	
    columnCRChangeType: CheckListColumn = null;	
    columnOriginatorDate: CalendarColumn = null;	
    columnTargetCompletionDate: CalendarColumn = null;	
    columnRFI_RIMSRef: CheckListColumn = null;	
    columnMOCRef: CheckListColumn = null;
    columnTagNo: CheckListColumn = null;
    columnComments: CheckListColumn = null;
}

export class PascrStatusDTO {
    id: number;
    pascrNumber: string;	
    Description: string;	
    TagType: string;	
    SubSystem: string;	
    ApprovalStage: string;	
    Status: string;	
    CRChangeType: string;	
    OriginatorDate: moment.Moment;	
    TargetCompletionDate: moment.Moment;	
    RFI_RIMSRef: string;	
    MOCRef: string;
    TagNo: string;
    comment: string;
    mentions: UserDetail[];
}

export class ShowHideColumns {
    tagType = false;
    comment = true;
}

