import { Action } from '@ngrx/store';
import { Gooc } from '../../common.model';
import { SkylineResponseDTO } from '../skyline/model';
import {
    ChangeValidationDTO,
    EarlyWalkdownsDTO,
    EarlyWorksDTO,
    PLIValidationDTO,
    Schedule,
    SOCPlanDTO,
} from './model';

export enum PreMCActionTypes {
    EarlyWorksRequest = '[Early Works] Request',
    EarlyWorksSuccess = '[Early Works] Success',
    EarlyWorksError = '[Early Works] Error',
    EarlyWorksSaveRequest = '[Early Works] Save Request',
    EarlyWorksSaveSuccess = '[Early Works] Save Success',
    EarlyWorksSaveError = '[Early Works] Save Error',

    EarlyWalkdownsRequest = '[Early Walkdowns] Request',
    EarlyWalkdownsSuccess = '[Early Walkdowns] Success',
    EarlyWalkdownsError = '[Early Walkdowns] Error',
    EarlyWalkdownsSaveRequest = '[Early Walkdowns] Save Request',
    EarlyWalkdownsSaveSuccess = '[Early Walkdowns] Save Success',
    EarlyWalkdownsSaveError = '[Early Walkdowns] Save Error',

    PLIValidationRequest = '[PLI Validation] Request',
    PLIValidationSuccess = '[PLI Validation] Success',
    PLIValidationError = '[PLI Validation] Error',
    PLIValidationSaveRequest = '[PLI Validation] Save Request',
    PLIValidationSaveSuccess = '[PLI Validation] Save Success',
    PLIValidationSaveError = '[PLI Validation] Save Error',

    ChangeValidationRequest = '[Change Validation] Request',
    ChangeValidationSuccess = '[Change Validation] Success',
    ChangeValidationError = '[Change Validation] Error',

    AQVDRequestStatusRequest = '[A-QVD Request Status] Request',
    AQVDRequestStatusSuccess = '[A-QVD Request Status] Success',
    AQVDRequestStatusError = '[A-QVD Request Status] Error',

    SOCPlanRequest = '[SOC Plan] Request',
    SOCPlanSuccess = '[SOC Plan] Success',
    SOCPlanError = '[SOC Plan] Error',
    SOCPlanSaveRequest = '[SOC Plan] Save Request',
    SOCPlanSaveSuccess = '[SOC Plan] Save Success',
    SOCPlanSaveError = '[SOC Plan] Save Error',

    PreMCSkylineRequest = '[Pre MC Skyline] Request',
    PreMCSkylineSuccess = '[Pre MC Skyline] Success',
    PreMCSkylineError = '[Pre MC Skyline] Error',

    ScheduleRequest = '[Pre MC Schedule] Request',
    ScheduleSuccess = '[Pre MC Schedule] Success',
    ScheduleError = '[Pre MC Schedule] Error',
    ScheduleSaveRequest = '[Pre MC Schedule] Save Request',
    ScheduleSaveSuccess = '[Pre MC Schedule] Save Success',
    ScheduleSaveError = '[Pre MC Schedule] Save Error',
}

export class EarlyWorksRequest implements Action {
    readonly type = PreMCActionTypes.EarlyWorksRequest;

    constructor(public payload: Gooc) {}
}

export class EarlyWorksSuccess implements Action {
    readonly type = PreMCActionTypes.EarlyWorksSuccess;

    constructor(public payload: EarlyWorksDTO) {}
}

export class EarlyWorksError implements Action {
    readonly type = PreMCActionTypes.EarlyWorksError;

    constructor(public payload: string) {}
}

export class EarlyWorksSaveRequest implements Action {
    readonly type = PreMCActionTypes.EarlyWorksSaveRequest;

    constructor(public payload: EarlyWorksDTO) {}
}

export class EarlyWorksSaveSuccess implements Action {
    readonly type = PreMCActionTypes.EarlyWorksSaveSuccess;

    constructor(public payload: EarlyWorksDTO) {}
}

export class EarlyWorksSaveError implements Action {
    readonly type = PreMCActionTypes.EarlyWorksSaveError;

    constructor(public payload: string) {}
}

export class EarlyWalkdownsRequest implements Action {
    readonly type = PreMCActionTypes.EarlyWalkdownsRequest;

    constructor(public payload: Gooc) {}
}

export class EarlyWalkdownsSuccess implements Action {
    readonly type = PreMCActionTypes.EarlyWalkdownsSuccess;

    constructor(public payload: EarlyWalkdownsDTO) {}
}

export class EarlyWalkdownsError implements Action {
    readonly type = PreMCActionTypes.EarlyWalkdownsError;

    constructor(public payload: string) {}
}

export class EarlyWalkdownsSaveRequest implements Action {
    readonly type = PreMCActionTypes.EarlyWalkdownsSaveRequest;

    constructor(public payload: EarlyWalkdownsDTO) {}
}

export class EarlyWalkdownsSaveSuccess implements Action {
    readonly type = PreMCActionTypes.EarlyWalkdownsSaveSuccess;

    constructor(public payload: EarlyWalkdownsDTO) {}
}

export class EarlyWalkdownsSaveError implements Action {
    readonly type = PreMCActionTypes.EarlyWalkdownsSaveError;

    constructor(public payload: string) {}
}

export class PLIValidationRequest implements Action {
    readonly type = PreMCActionTypes.PLIValidationRequest;

    constructor(public payload: Gooc) {}
}

export class PLIValidationSuccess implements Action {
    readonly type = PreMCActionTypes.PLIValidationSuccess;

    constructor(public payload: PLIValidationDTO) {}
}

export class PLIValidationError implements Action {
    readonly type = PreMCActionTypes.PLIValidationError;

    constructor(public payload: string) {}
}

export class PLIValidationSaveRequest implements Action {
    readonly type = PreMCActionTypes.PLIValidationSaveRequest;

    constructor(public payload: PLIValidationDTO) {}
}

export class PLIValidationSaveSuccess implements Action {
    readonly type = PreMCActionTypes.PLIValidationSaveSuccess;

    constructor(public payload: PLIValidationDTO) {}
}

export class PLIValidationSaveError implements Action {
    readonly type = PreMCActionTypes.PLIValidationSaveError;

    constructor(public payload: string) {}
}

export class ChangeValidationRequest implements Action {
    readonly type = PreMCActionTypes.ChangeValidationRequest;

    constructor(public payload: Gooc) {}
}

export class ChangeValidationSuccess implements Action {
    readonly type = PreMCActionTypes.ChangeValidationSuccess;

    constructor(public payload: ChangeValidationDTO) {}
}

export class ChangeValidationError implements Action {
    readonly type = PreMCActionTypes.ChangeValidationError;

    constructor(public payload: string) {}
}

export class AQVDRequestStatusRequest implements Action {
    readonly type = PreMCActionTypes.AQVDRequestStatusRequest;

    constructor(public payload: Gooc) {}
}

export class AQVDRequestStatusSuccess implements Action {
    readonly type = PreMCActionTypes.AQVDRequestStatusSuccess;

    constructor(public payload: { key: string; value: number }[]) {}
}

export class AQVDRequestStatusError implements Action {
    readonly type = PreMCActionTypes.AQVDRequestStatusError;

    constructor(public payload: string) {}
}

export class SOCPlanRequest implements Action {
    readonly type = PreMCActionTypes.SOCPlanRequest;

    constructor(public payload: Gooc) {}
}

export class SOCPlanSuccess implements Action {
    readonly type = PreMCActionTypes.SOCPlanSuccess;

    constructor(public payload: SOCPlanDTO) {}
}

export class SOCPlanError implements Action {
    readonly type = PreMCActionTypes.SOCPlanError;

    constructor(public payload: string) {}
}

export class SOCPlanSaveRequest implements Action {
    readonly type = PreMCActionTypes.SOCPlanSaveRequest;

    constructor(public payload: SOCPlanDTO) {}
}

export class SOCPlanSaveSuccess implements Action {
    readonly type = PreMCActionTypes.SOCPlanSaveSuccess;

    constructor(public payload: SOCPlanDTO) {}
}

export class SOCPlanSaveError implements Action {
    readonly type = PreMCActionTypes.SOCPlanSaveError;

    constructor(public payload: string) {}
}

export class PreMCSkylineRequest implements Action {
    readonly type = PreMCActionTypes.PreMCSkylineRequest;

    constructor(public payload: Gooc) {}
}

export class PreMCSkylineSuccess implements Action {
    readonly type = PreMCActionTypes.PreMCSkylineSuccess;

    constructor(public payload: SkylineResponseDTO[]) {}
}

export class PreMCSkylineError implements Action {
    readonly type = PreMCActionTypes.PreMCSkylineError;

    constructor(public payload: string) {}
}

export class ScheduleRequest implements Action {
    readonly type = PreMCActionTypes.ScheduleRequest;

    constructor(public payload: Gooc) {}
}

export class ScheduleSuccess implements Action {
    readonly type = PreMCActionTypes.ScheduleSuccess;

    constructor(public payload: Schedule) {}
}

export class ScheduleError implements Action {
    readonly type = PreMCActionTypes.ScheduleError;

    constructor(public payload: string) {}
}

export class ScheduleSaveRequest implements Action {
    readonly type = PreMCActionTypes.ScheduleSaveRequest;

    constructor(public payload: Schedule) {}
}

export class ScheduleSaveSuccess implements Action {
    readonly type = PreMCActionTypes.ScheduleSaveSuccess;

    constructor(public payload: Schedule) {}
}

export class ScheduleSaveError implements Action {
    readonly type = PreMCActionTypes.ScheduleSaveError;

    constructor(public payload: string) {}
}

export type PreMCActions =
    | EarlyWorksRequest
    | EarlyWorksSuccess
    | EarlyWorksError
    | EarlyWorksSaveRequest
    | EarlyWorksSaveSuccess
    | EarlyWorksSaveError
    | EarlyWalkdownsRequest
    | EarlyWalkdownsSuccess
    | EarlyWalkdownsError
    | EarlyWalkdownsSaveRequest
    | EarlyWalkdownsSaveSuccess
    | EarlyWalkdownsSaveError
    | PLIValidationRequest
    | PLIValidationSuccess
    | PLIValidationError
    | PLIValidationSaveRequest
    | PLIValidationSaveSuccess
    | PLIValidationSaveError
    | ChangeValidationRequest
    | ChangeValidationSuccess
    | ChangeValidationError
    | AQVDRequestStatusRequest
    | AQVDRequestStatusSuccess
    | AQVDRequestStatusError
    | SOCPlanRequest
    | SOCPlanSuccess
    | SOCPlanError
    | SOCPlanSaveRequest
    | SOCPlanSaveSuccess
    | SOCPlanSaveError
    | PreMCSkylineRequest
    | PreMCSkylineSuccess
    | PreMCSkylineError
    | ScheduleRequest
    | ScheduleSuccess
    | ScheduleError
    | ScheduleSaveRequest
    | ScheduleSaveSuccess
    | ScheduleSaveError;
