import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    SystemizedDrawingsActionTypes,
    SystemizedDrawingsUploadLogSuccess,
    SystemizedDrawingsUploadLogError,
    SystemizedDrawingsSetStatusAndStartDate,
    SystemizedDrawingsClearInProgressSpinner,
    SystemizedDrawingsTemplateFileRequest,
    SystemizedDrawingsTemplateFileSuccess,
    SystemizedDrawingsTemplateFileError,
    SystemizedDrawingsValidateButtonStateSuccess,
    SystemizedDrawingsValidateButtonStateError,
    SystemizedDrawingsDownloadOutputDataRequest,
    SystemizedDrawingsDownloadOutputDataSuccess,
    SystemizedDrawingsDownloadOutputDataError,
    SystemizedDrawingsValidateDeltaSuccess,
    SystemizedDrawingsValidateDeltaError,
    SystemizedDrawingsValidateButtonStateRequest,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class SystemizedDrawingsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(SystemizedDrawingsActionTypes.SystemizedDrawingsUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['SystemizedDrawings']).pipe(
                map((uploadLogData) => new SystemizedDrawingsUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new SystemizedDrawingsUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<SystemizedDrawingsUploadLogSuccess>(SystemizedDrawingsActionTypes.SystemizedDrawingsUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.systemizedDrawingsState.uploadLogStatus),
            this.store.select((store) => store.systemizedDrawingsState.uploadLogStartDate)
        ),
        filter(([{ payload }, status, startDate]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return logs[0] && (status !== logs[0].status || startDate !== logs[0].startDate);
        }),
        map(([{ payload }]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return new SystemizedDrawingsSetStatusAndStartDate({ status: logs[0].status, startDate: logs[0].startDate });
        })
    );

    @Effect()
    invokeDeltaRequest$ = this.actions$.pipe(
        ofType(SystemizedDrawingsActionTypes.SystemizedDrawingsSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.systemizedDrawingsState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        switchMap(() => [new SystemizedDrawingsValidateButtonStateRequest(), new SystemizedDrawingsClearInProgressSpinner()])
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<SystemizedDrawingsTemplateFileRequest>(SystemizedDrawingsActionTypes.SystemizedDrawingsTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new SystemizedDrawingsTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading IWP Listing structure file. Please contact Program Administrator'
                    );
                    return of(new SystemizedDrawingsTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<SystemizedDrawingsTemplateFileSuccess>(SystemizedDrawingsActionTypes.SystemizedDrawingsTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'SystemizedDrawingsList.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<SystemizedDrawingsDownloadOutputDataRequest>(SystemizedDrawingsActionTypes.SystemizedDrawingsDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new SystemizedDrawingsDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new SystemizedDrawingsDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<SystemizedDrawingsDownloadOutputDataSuccess>(SystemizedDrawingsActionTypes.SystemizedDrawingsDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(SystemizedDrawingsActionTypes.SystemizedDrawingsValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateSystemizedDrawingsDelta().pipe(
                map(() => new SystemizedDrawingsValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new SystemizedDrawingsValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(SystemizedDrawingsActionTypes.SystemizedDrawingsValidateButtonStateRequest),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('SystemizedDrawings').pipe(
                map((response: any) => new SystemizedDrawingsValidateButtonStateSuccess(response)),
                catchError((error) => of(new SystemizedDrawingsValidateButtonStateError(error)))
            )
        )
    );
}
