import { Component, OnInit, Input } from '@angular/core';
import { RundownType } from 'src/app/store/reports/rundown/model';
import { ActivatedRoute } from '@angular/router';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { RundownSetType, RundownFilterReset, RundownRequest } from 'src/app/store/reports/rundown/actions';

@Component({
    selector: 'app-reports-rundown-content',
    templateUrl: './reports-rundown-content.component.html',
    styleUrls: ['./reports-rundown-content.component.scss'],
})
export class ReportsRundownContentComponent implements OnInit {
    rundownType: RundownType;
    rundownTypes = RundownType;
    @Input() homePage: boolean = false;
    filteredByClosureStage$ = this.store.select((state) => state.rundownState.filteredByClosureStage);

    constructor(private activatedRoute: ActivatedRoute, private store: Store<ApplicationState>) {}

    ngOnInit() {
        //Solution for Expression has changed after it was checked
        setTimeout(() => {
            this.rundownType = this.rundownTypes[this.activatedRoute.routeConfig.path.toUpperCase()];
            if(this.homePage) { this.rundownType = RundownType.RFSU };
            this.store.dispatch(new RundownSetType(this.rundownType));
            this.store.dispatch(new RundownFilterReset());
            this.store.dispatch(new RundownRequest());
        });
    }
}
