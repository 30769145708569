import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ToastService } from "src/app/services/shared/toast.service";
import { ApplicationState } from "../model";
import { LeakTestPackTrackerSearchService } from "src/app/services/api/webapi-services/leak-test-pack-tracker-search.service";
import { map } from 'rxjs/operators';
import { of } from "rxjs";
import { withLatestFrom, mergeMap, catchError } from "rxjs/operators";
import { LeakTestPackTrackerSearchActionTypes, LeakTestPackTrackerSearchFilterSuccess, LeakTestPackTrackerSearchFilterError, LeakTestPackTrackerSearchExportToExcelSuccess, LeakTestPackTrackerSearchExportToExcelError, LeakTestPackTrackerSearchUpdatePriorityRequest, LeakTestPackTrackerSearchUpdatePriorityError, LeakTestPackTrackerSearchUpdatePrioritySuccess } from "./actions";
import * as moment from "moment";


@Injectable()
export class LeakTestPackTrackerSearchEffects{
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService, 
        private leakTestPackTrackerSearchService : LeakTestPackTrackerSearchService
    ){}

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType(LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterRequest),
        withLatestFrom(this.store.select((store) => store.leakTestPackTrackerSearchState?.filter)),
        mergeMap(([action, leakTestPackTrackerSearchFilter]) => {
            return this.leakTestPackTrackerSearchService.searchLeakTestPackTrackerService(leakTestPackTrackerSearchFilter).pipe(
                map((dataPagination) => {
                    return new LeakTestPackTrackerSearchFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Leak Test Pack Tracker search. Please contact Program Administrator.'
                    );
                    return of(new LeakTestPackTrackerSearchFilterError(error));
                })
            );
        })
    );


    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.leakTestPackTrackerSearchState.filter)),
        mergeMap(([, leakTestPackTrackerSearchFilter]) => {
            const filter = {
                ...leakTestPackTrackerSearchFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.leakTestPackTrackerSearchService.generateExcel(filter).pipe(
                map((excelData) => new LeakTestPackTrackerSearchExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new LeakTestPackTrackerSearchExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchExportToExcelSuccess),
        map((action: LeakTestPackTrackerSearchExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, `LeakTestPackTrackerSearch_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect()
    updatePriority$ = this.actions$.pipe(
        ofType<LeakTestPackTrackerSearchUpdatePriorityRequest>(LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchUpdatePriorityRequest),
        mergeMap(({ payload }) =>
                this.leakTestPackTrackerSearchService.updateLTPpriority(payload.ltp).pipe(
                map(() => {                    
                    this.toastService.Success('LTP Priority Updated');
                    return new LeakTestPackTrackerSearchUpdatePrioritySuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating ChangeDocument SubPriority. Please contact Program Administrator.'
                    );
                    return of(new LeakTestPackTrackerSearchUpdatePriorityError(error));
                })
            )
        )
    );
}