import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import { WeekSummaryDto } from 'src/app/store/weekly-planning/model';
import { ScheduleActivityNotInWeekPlanningDataRequest, ScheduleActivityPlanningDataRequest, ScheduleActivityPlanningFilterPropertyUpdate, ScheduleActivityWeekSummaryDataRequest } from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import { ScheduleActivityPlanningFilter, WeekSummaryFilter } from 'src/app/store/weekly-planning/schedule-activity-planning/model';

@Component({
  selector: 'app-week-summary',
  templateUrl: './week-summary.component.html',
  styleUrls: ['./week-summary.component.scss']
})
export class WeekSummaryComponent extends BaseComponent implements OnInit {
  @Output() openPanelEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  weekSummaryDataSource: MatTableDataSource<WeekSummaryDto>;

  weekSummaryFilter: WeekSummaryFilter = new WeekSummaryFilter();
  weekSummary$ = this.store.select((store) => store.scheduleActivityPlanningState.weekSummary);
  
  displayedColumns: string[] = [
    '#OfActivities',
    'plan',
    'commitedAsPerPlan',
    'commitedOutOfPlan',
    'notCommitedAndRequiredForRFSU',
    'notConstrainedAndNotCommited',
    'constrainedAndNotCommited'
];
  constructor(
    private store: Store<ApplicationState>,
  ) {
    super()
   }

  ngOnInit(): void {
    this.weekSummary$.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(weekSummary => this.weekSummaryDataSource = new MatTableDataSource([weekSummary]))
  }

  filterItems(): void {
    this.store.dispatch(
      new ScheduleActivityPlanningFilterPropertyUpdate({
          filter: { weekSummaryFilter: this.weekSummaryFilter } as ScheduleActivityPlanningFilter
      })
    );

    if (this.weekSummaryFilter.showCommittedOutOfPlan) {
      this.store.dispatch(new ScheduleActivityNotInWeekPlanningDataRequest());
      this.openPanelEvent.emit(false);
    } else {
      this.store.dispatch(new ScheduleActivityPlanningDataRequest());
      this.openPanelEvent.emit(true);
    }
  }

  clearFilter() {
    this.weekSummaryFilter.showCommittedAsPerPlan = false;
    this.weekSummaryFilter.showCommittedOutOfPlan = false;
    this.weekSummaryFilter.showConstrainedAndNotCommitted = false;
    this.weekSummaryFilter.showNotConstrainedAndNotCommitted = false;
    this.weekSummaryFilter.showNotCommittedAndRequiredForRFSU = false;
  }
}
