<main class="main">
    <a class="navigator" (click)="goToPreviousPage()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
    </a>
    <div class="header-title-container">
        <div class="header-title">dcn/rfi register</div>
        <div class="buttons-container flex">
            <button
                type="button"
                class="sct-button sct-button-white sct-icon-button"
                (click)="openHistoryPopup()"
            >
                <mat-icon svgIcon="history"></mat-icon>
                comments history
            </button>
            <button *ngIf="registerType === registerTypes.Dcnrfi" [disabled]="isDisabledSignOff()"
                type="button"
                class="sct-button sct-button-light"
                (click)="signOff()"
            >
                sign-off field implementation
            </button>
        </div>
    </div>
    
    <div *ngIf="registerType === registerTypes.Dcnrfi" class="details-container">
        <div class="details-header">
            <div class="details-title">details</div>
            <div class="margin-top-bottom-10">
                <mat-form-field appearance="none" class="container180 margin-right20">
                    <mat-label>Date</mat-label>
                    <input matInput placeholder="" [value]="dcnRfiDetails.rfsuForecast | date: 'dd/MM/yyyy'" readonly />
                </mat-form-field>
                <mat-form-field appearance="none" class="container180 margin-right20 mat-arrow">
                    <mat-label>Workflow Status</mat-label>
                    <mat-select 
                        (selectionChange)="updateWorkflowStatus(dcnRfiDetails.id, $event.value)"
                        appearance="none"
                        [ngClass]="getNgClass(statusTypes.Workflow, dcnRfiDetails.workflowStatus)"
                        matInput 
                        [value]="dcnRfiDetails.workflowStatus">
                        <mat-option value="Review Required">Review Required</mat-option>
                        <mat-option value="Reviewed">Reviewed</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div>
            <mat-form-field appearance="none" class="container300 margin-right20">
                <mat-label>Number</mat-label>
                <input (click)="goToEb()" matInput class="eb-link" placeholder="Number" [value]="dcnRfiDetails.number" readonly />
            </mat-form-field>
            <mat-form-field appearance="none" class="container180 margin-right20">
                <mat-label>Revision</mat-label>
                <input matInput placeholder="Revision" [value]="dcnRfiDetails.revision" readonly />
            </mat-form-field>
            <mat-form-field appearance="none" class="container180 margin-right20">
                <mat-label>Discipline</mat-label>
                <input matInput placeholder="Discipline" [value]="dcnRfiDetails.discipline" readonly />
            </mat-form-field>
            <hr>
            <div class="textarea-title">
                <mat-form-field appearance="none" class="container100pct">
                    <mat-label>Title</mat-label>
                    <textarea
                        matInput
                        [value]="dcnRfiDetails.title"
                        data-title="Title"
                        cdkTextareaAutosize
                        readonly
                    ></textarea>
                </mat-form-field>
            </div>
            <hr>
            <mat-form-field appearance="none" class="container300 margin-right20">
                <mat-label>Implementation Status</mat-label>
                <input matInput placeholder="Implementation Status" [value]="dcnRfiDetails.implementationStatus" readonly />
            </mat-form-field>
            <mat-form-field appearance="none" class="container300 margin-right20">
                <mat-label>Engineering Status</mat-label>
                <input [ngClass]="getNgClass(statusTypes.Engineering, dcnRfiDetails.engineeringStatus)" matInput placeholder="Engineering Status" [value]="dcnRfiDetails.engineeringStatus" readonly />
            </mat-form-field>
            <hr>
            <div class="grid-container grid-container-subsystems">
                <div class="grid-item grid-area-subsystems-content">
                    <mat-form-field appearance="none" class="container600 margin-right20">
                        <mat-label>Subsystems</mat-label>
                        <textarea
                            class="small-initial-height"
                            matInput
                            [value]="currentElement.subsystems === null ? '' : currentElement.subsystems"
                            placeholder="Subsystems"
                            [cdkTextareaAutosize] = "expandTextArea"
                            readonly
                        ></textarea>
                    </mat-form-field>
                </div>
                <div class="grid-item grid-area-comment-content">
                    <mat-form-field appearance="none" class="container600 margin-right20">
                        <mat-label>Comment</mat-label>
                        <textarea
                            class="small-initial-height"
                            matInput
                            [(ngModel)]="newComment"
                            [value]="isEditingComment || currentElement.comment === null ? '' : currentElement.comment"
                            placeholder="Comment"
                            [cdkTextareaAutosize] = "expandTextArea"
                            [readonly]="!isEditingComment"
                        ></textarea>
                    </mat-form-field>
                </div>
                <div class="grid-item grid-area-subsystems-actions">
                    <button
                        style="color: #009dd9;"
                        mat-icon-button
                        title="expand/collapse"
                        (click)="expandCollapseItems()"
                        *ngIf="currentElement?.subsystems.length > 285  || currentElement?.comment?.length > 285"
                    >
                        <mat-icon style="width: 15px; height: 15px;" *ngIf="!expandTextArea" svgIcon="expand"></mat-icon>
                        <mat-icon style="width: 15px; height: 15px;" *ngIf="expandTextArea" svgIcon="collapse"></mat-icon>
                        {{ expandTextArea === false ? 'expand all' : 'collapse all' }}
                    </button>
                </div>
                <div class="grid-item grid-area-comment-actions flex-container flex-space-between">
                    <button
                        mat-icon-button
                        color="primary"
                        (click)="startEditingComment()"
                        [disabled]="isEditingComment"
                    >
                        <mat-icon svgIcon="add" *ngIf="!isEditingComment"></mat-icon>
                        <mat-icon svgIcon="add-gray" *ngIf="isEditingComment"></mat-icon>
                        add comment
                    </button>
                    <div class="flex-container" *ngIf="isEditingComment">
                        <button
                            mat-icon-button
                            [disabled]="newComment.length <= 0"
                            (click)="saveNewComment()"
                        >
                            <mat-icon svgIcon="accept" *ngIf="newComment.length > 0"></mat-icon>
                            <mat-icon svgIcon="accept-gray" *ngIf="newComment.length <= 0"></mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEditingComment()">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="registerType === registerTypes.Dcnrfi" class="details-container scroll">
        <app-mat-table-sticky-header [scrollKey]="'workpack-details'">
        <div class="details-header">
            <div class="details-title">workpack</div>
        </div>
        <table mat-table matSort [dataSource]="dcnRfiDetails.workpacks">
            <ng-container matColumnDef="workpackNumber">
                <th mat-header-cell disableClear *matHeaderCellDef>NB Workpack Number</th>
                <td mat-cell *matCellDef="let element">{{ element.workpackNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="workpackStatus">
                <th mat-header-cell disableClear *matHeaderCellDef>Workpack Status</th>
                <td mat-cell *matCellDef="let element" class="padding-right-0">
                    <mat-select 
                        class="mat-arrow"
                        (selectionChange)="updateWorkpackStatus(element.workpackNumber, $event.value)"
                        appearance="none"
                        [ngClass]="getNgClass(statusTypes.Workpack, element.workpackStatus)"
                        matInput 
                        [value]="element.workpackStatus">
                        <mat-option value="Quarantined">Quarantined</mat-option>
                        <mat-option value="Released">Released</mat-option>
                    </mat-select>
                    <div class="table-icon">
                        <button mat-icon-button class="button-icon-delete" title="delete" (click)="deleteWorkpack(element.workpackNumber)">
                            <mat-icon class="accept-icon">delete</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedWorkpackColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedWorkpackColumns"></tr>
        </table>
        <div *ngIf="showNewWorkpackField" class="add-row">
            <mat-form-field appearance="none" class="form-input">
                <input [(ngModel)]="newNbWorkpackNumber" matInput placeholder="Type in workpack name">
            </mat-form-field>
            <mat-form-field appearance="none" class="select-option">
                <mat-select 
                    [(ngModel)]="newWorkpackStatus"
                    appearance="none"
                    [ngClass]="getNgClass(statusTypes.Workpack, newWorkpackStatus)"
                    placeholder="Choose status">
                <mat-option value="Quarantined">Quarantined</mat-option>
                <mat-option value="Released">Released</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="icons">
                <button mat-icon-button title="accept" [disabled]="isNewWorkpackDisabled" (click)="addNewItem(statusTypes.Workpack)">
                    <mat-icon [ngClass]="{ 'accept-icon': !isNewWorkpackDisabled, 'disabled-accept-icon': isNewWorkpackDisabled }">check_circle</mat-icon>
                </button>
                <button mat-icon-button title="delete" (click)="removeNewItem(statusTypes.Workpack)">
                    <mat-icon class="accept-icon">delete</mat-icon>
                </button>
            </div>
        </div>
        <hr class="hr-margin" *ngIf="showNewWorkpackField">
        <div class="add-container">
            <div class="add" (click)="showNewWorkpackField = !showNewWorkpackField">
                <button  [ngClass]="{'add-button': !showNewWorkpackField, 'add-button-disabled': showNewWorkpackField}"><span>+</span></button>
                <div [ngClass]="{'add-content': !showNewWorkpackField, 'add-content-disabled': showNewWorkpackField}">Add Workpack</div>
            </div>
        </div>
        </app-mat-table-sticky-header>
    </div>

    <div *ngIf="registerType === registerTypes.Dcnrfi" class="details-container scroll">
        <app-mat-table-sticky-header [scrollKey]="'redline-details'">
        <div class="details-header">
            <div class="details-title">redline details</div>
        </div>
        <table mat-table matSort [dataSource]="dcnRfiDetails.redlineBaseDetails">
            <ng-container matColumnDef="redlineNumber">
                <th mat-header-cell disableClear *matHeaderCellDef>Redline Document Number</th>
                <td mat-cell *matCellDef="let element">{{ element.redlineNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="tcoStatus">
                <th mat-header-cell disableClear *matHeaderCellDef>TCO Status</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="getNgClass(statusTypes.Tco, element.tcoStatus)">
                        {{ element.tcoStatus }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="rlmuStatus">
                <th mat-header-cell disableClear *matHeaderCellDef>RLMU Status</th>
                <td mat-cell *matCellDef="let element">
                    <mat-select 
                        class="mat-arrow"
                        (selectionChange)="updateRlmuStatus(element.id, element.redlineNumber, element.tcoStatus, $event.value)"
                        appearance="none"
                        [ngClass]="getNgClass(statusTypes.Rlmu, element.rlmuStatus)"
                        matInput 
                        [value]="element.rlmuStatus">
                        <mat-option value="Not Validated">Not Validated</mat-option>
                        <mat-option value="Validated">Validated</mat-option>
                        <mat-option value="Submitted to DLH">Submitted to DLH</mat-option>
                        <mat-option value="Rejected by DLH">Rejected by DLH</mat-option>
                        <mat-option value="Accepted by DLH">Accepted by DLH</mat-option>
                        <mat-option value="Not Consolidated">Not Consolidated</mat-option>
                        <mat-option value="Consolidated">Consolidated</mat-option>
                    </mat-select>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="showNewRedlineField" class="add-row">
            <form class="form-input">
                <mat-form-field class="inline"  [floatLabel]="'never'">
                <mat-label *ngIf="!input.value">Start to type in Redline Document Number</mat-label>
                <input 
                    class="input-redline" 
                    #input 
                    type="text" 
                    matInput 
                    (keyup)="searchRedlineNumbers(input.value)" 
                    [formControl]="formControl" 
                    [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of redlineNumbers" [value]="option.id">
                    {{option.id}}
                    </mat-option>
                </mat-autocomplete>
                </mat-form-field>
            </form>
            <mat-form-field appearance="none" class="select-option">
                <mat-select 
                    [(ngModel)]="newTcoStatus"
                    appearance="none"
                    [ngClass]="getNgClass(statusTypes.Tco, newTcoStatus)"
                    placeholder="Choose status">
                <mat-option value="Required">Required</mat-option>
                <mat-option value="Received">Received</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="none" class="select-option">
                <mat-select 
                    [(ngModel)]="newRlmuStatus"
                    appearance="none"
                    [ngClass]="getNgClass(statusTypes.Rlmu, newRlmuStatus)"
                    disableOptionCentering
                    placeholder="Choose status">
                <mat-option value="Not Validated">Not Validated</mat-option>
                <mat-option value="Validated">Validated</mat-option>
                <mat-option value="Submitted to DLH" hidden>Submitted to DLH</mat-option>
                <mat-option value="Rejected by DLH" hidden>Rejected by DLH</mat-option>
                <mat-option value="Accepted by DLH" hidden>Accepted by DLH</mat-option>
                <mat-option value="Not Consolidated" hidden>Not Consolidated</mat-option>
                <mat-option value="Consolidated" hidden>Consolidated</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="icons">
                <button mat-icon-button title="accept" [disabled]="isNewRedlineDisabled" (click)="addNewItem(statusTypes.Redline)">
                    <mat-icon [ngClass]="{ 'accept-icon': !isNewRedlineDisabled, 'disabled-accept-icon': isNewRedlineDisabled }">check_circle</mat-icon>
                </button>
                <button mat-icon-button title="delete" (click)="removeNewItem(statusTypes.Redline)">
                    <mat-icon class="accept-icon">delete</mat-icon>
                </button>
            </div>
        </div>
        <hr class="hr-margin" *ngIf="showNewRedlineField">
        <div class="add-container">
            <div class="add" (click)="showNewRedlineField = !showNewRedlineField">
                <button [ngClass]="{'add-button': !showNewRedlineField, 'add-button-disabled': showNewRedlineField}"><span>+</span></button>
                <div [ngClass]="{'add-content': !showNewRedlineField, 'add-content-disabled': showNewRedlineField}">Add Redline</div>
            </div>
        </div>
        </app-mat-table-sticky-header>
    </div>
    <div *ngIf="registerType === registerTypes.Redline" class="details-container">
        <div class="details-header">
            <div class="details-title">details</div>
            <div class="margin-top-bottom-10">
                <mat-form-field appearance="none" class="container180 margin-right20">
                    <mat-label>Date</mat-label>
                    <input matInput placeholder="" [value]="redlineDetails.rfsuForecast | date: 'dd/MM/yyyy'" readonly />
                </mat-form-field>
            </div>
        </div>
        <div>
            <mat-form-field appearance="none" class="container300 margin-right20">
                <mat-label>Number</mat-label>
                <input matInput placeholder="Number" [value]="redlineDetails.number" readonly />
            </mat-form-field>
            <mat-form-field appearance="none" class="container180 margin-right20">
                <mat-label>Revision</mat-label>
                <input matInput placeholder="Revision" [value]="redlineDetails.revision" readonly />
            </mat-form-field>
            <mat-form-field appearance="none" class="container180 margin-right20">
                <mat-label>Discipline</mat-label>
                <input matInput placeholder="Discipline" [value]="redlineDetails.discipline" readonly />
            </mat-form-field>
            <mat-form-field appearance="none" class="container180 margin-right20">
              <mat-label>TCO Redline Status</mat-label>
              <input [ngClass]="getNgClass(statusTypes.Tco, redlineDetails.tcoRedlineStatus)" matInput [value]="redlineDetails.tcoRedlineStatus" readonly />
            </mat-form-field>
            <mat-form-field appearance="none" class="container180 margin-right20">
              <mat-label>RLMU Status</mat-label>
            <input [ngClass]="getNgClass(statusTypes.Rlmu, redlineDetails.rlmuStatus)" matInput [value]="redlineDetails.rlmuStatus" readonly />
           </mat-form-field>
            <hr>
            <div class="textarea-title">
                <mat-form-field appearance="none" class="container100pct">
                    <mat-label>Title</mat-label>
                    <textarea
                        matInput
                        [value]="redlineDetails.title"
                        data-title="Title"
                        cdkTextareaAutosize
                        readonly
                    ></textarea>
                </mat-form-field>
            </div>
            <hr>
            <div class="grid-container grid-container-subsystems">
                <div class="grid-item grid-area-subsystems-content">
                    <mat-form-field appearance="none" class="container600 margin-right20">
                        <mat-label>Subsystems</mat-label>
                        <textarea
                            class="small-initial-height"
                            matInput
                            [value]="currentElement.subsystems === null ? '' : currentElement.subsystems"
                            placeholder="Subsystems"
                            [cdkTextareaAutosize] = "expandTextArea"
                            readonly
                        ></textarea>
                    </mat-form-field>
                </div>
                <div class="grid-item grid-area-comment-content">
                    <mat-form-field appearance="none" class="container600 margin-right20">
                        <mat-label>Comment</mat-label>
                        <textarea
                            class="small-initial-height"
                            matInput
                            [(ngModel)]="newComment"
                            [value]="isEditingComment || currentElement.comment === null ? '' : currentElement.comment"
                            placeholder="Comment"
                            [cdkTextareaAutosize] = "expandTextArea"
                            [readonly]="!isEditingComment"
                            cdkAutosizeMinRows="1"
                        ></textarea>
                    </mat-form-field>
                </div>
                <div class="grid-item grid-area-subsystems-actions">
                    <button
                        style="color: #009dd9;"
                        mat-icon-button
                        title="expand/collapse"
                        (click)="expandCollapseItems()"
                        *ngIf="currentElement?.subsystems.length > 285  || currentElement?.comment?.length > 285"
                    >
                        <mat-icon style="width: 15px; height: 15px;" *ngIf="!expandTextArea" svgIcon="expand"></mat-icon>
                        <mat-icon style="width: 15px; height: 15px;" *ngIf="expandTextArea" svgIcon="collapse"></mat-icon>
                        {{ expandTextArea === false ? 'expand all' : 'collapse all' }}
                    </button>
                </div>
                <div class="grid-item grid-area-comment-actions flex-container flex-space-between">
                    <button
                        mat-icon-button
                        color="primary"
                        (click)="startEditingComment()"
                        [disabled]="isEditingComment"
                    >
                        <mat-icon svgIcon="add" *ngIf="!isEditingComment"></mat-icon>
                        <mat-icon svgIcon="add-gray" *ngIf="isEditingComment"></mat-icon>
                        add comment
                    </button>
                    <div class="flex-container" *ngIf="isEditingComment">
                        <button
                            mat-icon-button
                            [disabled]="newComment.length <= 0"
                            (click)="saveNewComment()"
                        >
                            <mat-icon svgIcon="accept" *ngIf="newComment.length > 0"></mat-icon>
                            <mat-icon svgIcon="accept-gray" *ngIf="newComment.length <= 0"></mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEditingComment()">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>
