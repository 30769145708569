<div class="title-container">
    <div class="dmtitle-container">
        <h4>modify waypoint</h4>
    </div>
</div>
<div class="results-container">
    <table
        mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="sortBy"
        matSortDisableClear
        [matSortDirection]="direction"
    >
        <ng-container matColumnDef="name">
            <th mat-header-cell disableClear *matHeaderCellDef>
                <div class="sort-container">
                    <span
                        [ngClass]="{
                            'header-underline': showUnderlineForCheckListColumn(columnNames)
                        }"
                        class="hover-pointer"
                        (click)="openHeaderCheckListFilter('name', getValues, 'columnNames', 'Names', columnNames)"
                        >Name</span
                    >
                    <div class="sort-icon" *ngIf="sortBy === 'name'">
                        <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.name }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell disableClear *matHeaderCellDef>
                <div class="sort-container">
                    <span
                        [ngClass]="{
                            'header-underline': showUnderlineForCheckListColumn(columnDescriptions)
                        }"
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'description',
                                getValues,
                                'columnDescriptions',
                                'Descriptions',
                                columnDescriptions,
                                false,
                                true
                            )
                        "
                        >Description</span
                    >
                    <div class="sort-icon" *ngIf="sortBy === 'description'">
                        <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.isInEditMode">{{ element.description }}</div>
                <div *ngIf="element.isInEditMode" class="editmode">
                    <mat-form-field
                        ><input
                            matInput
                            [(ngModel)]="element.description"
                            [ngModelOptions]="{ standalone: true }"
                            name="description"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="edit">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="!element.isInEditMode" mat-icon-button (click)="enterEditMode(element)" title="edit">
                    <mat-icon svgIcon="edit"></mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="save(element)" title="accept">
                    <mat-icon>check_circle_outline</mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
                    <mat-icon>cancel</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="remove">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="remove(element)" title="remove">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-toolbar>
        <span>Add new Waypoint</span>
        <button mat-icon-button (click)="add()" title="add">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </mat-toolbar>
    <div class="paginator page-counter">
        <mat-paginator
            [length]="resultsLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 15, 20]"
            showFirstLastButtons
        >
        </mat-paginator>
        <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
    </div>
</div>
<app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
