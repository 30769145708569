import { Action } from '@ngrx/store';
import { ContractorDataPagination, ContractorFilter } from './model';
import { ContractorDTO } from 'src/app/models/contractor-dto';

export enum ContractorMaintenanceActionTypes {
    ContractorFilterRequest = '[Contractor] Filter Request',
    ContractorFilterSuccess = '[Contractor] Filter Success',
    ContractorFilterError = '[Contractor] Filter Error',
    ContractorFilterUpdate = '[Contractor] Filter Update',
    ContractorFilterPropertyUpdate = '[Contractor] Filter Property Update',

    ContractorSaveRequest = '[Contractor] Save contractor Request',
    ContractorSaveSuccess = '[Contractor] Save contractor Success',
    ContractorSaveError = '[Contractor] Save contractor Error',

    ContractorRemoveRequest = '[Contractor] Remove contractor Request',
    ContractorRemoveSuccess = '[Contractor] Remove contractor Success',
    ContractorRemoveError = '[Contractor] Remove contractor Error',
}

export class ContractorFilterRequest implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorFilterRequest;
}

export class ContractorFilterSuccess implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorFilterSuccess;

    constructor(public payload: ContractorDataPagination) {}
}

export class ContractorFilterError implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorFilterError;

    constructor(public payload: string) {}
}

export class ContractorFilterUpdate implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorFilterUpdate;

    constructor(public payload: ContractorFilter) {}
}

export class ContractorFilterPropertyUpdate implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ContractorSaveRequest implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorSaveRequest;

    constructor(public payload: ContractorDTO) {}
}

export class ContractorSaveSuccess implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorSaveSuccess;
}

export class ContractorSaveError implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorSaveError;

    constructor(public payload: string) {}
}

export class ContractorRemoveRequest implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorRemoveRequest;

    constructor(public contractNo: string) {}
}

export class ContractorRemoveSuccess implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorRemoveSuccess;
}

export class ContractorRemoveError implements Action {
    readonly type = ContractorMaintenanceActionTypes.ContractorRemoveError;

    constructor(public payload: string) {}
}

export type TeamDataActions =
    | ContractorFilterRequest
    | ContractorFilterSuccess
    | ContractorFilterError
    | ContractorFilterUpdate
    | ContractorFilterPropertyUpdate
    | ContractorSaveRequest
    | ContractorSaveSuccess
    | ContractorSaveError
    | ContractorRemoveRequest
    | ContractorRemoveSuccess
    | ContractorRemoveError;
