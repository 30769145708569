import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, filter } from 'rxjs/operators';
import {
    NameSeriesDataDTO,
    CUMULATIVE_VALIDATION_PROGRESS_MONTH_RANGE,
} from 'src/app/store/reports/change-validation-dashboard/model';
import {
    ChangeValidationDashboardCumulativeValidationProgressRequest,
    ChangeValidationDashboardCumulativeValidationProgressSetMonthRange,
} from 'src/app/store/reports/change-validation-dashboard/actions';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
    selector: 'app-cumulative-validation-progress',
    templateUrl: './cumulative-validation-progress.component.html',
    styleUrls: ['./cumulative-validation-progress.component.scss'],
})
export class CumulativeValidationProgressComponent extends BaseComponent implements OnInit {
    cumulativeValidationProgressData$ = this.store.select(
        (state) => state.changeValidationDashboardState.cumulativeValidationProgress.data
    );
    isLoading$ = this.store.select(
        (state) => state.changeValidationDashboardState.cumulativeValidationProgress.isLoading
    );
    endDate$ = this.store.select(
        (state) => state.changeValidationDashboardState.cumulativeValidationProgress.rangeDateFilter.endDate
    );

    cumulativeValidationProgressData: NameSeriesDataDTO[] = [];
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    colorScheme = {
        domain: ['#32CD32', '#FF963E', '#8C8F93'],
    };
    maxDate = moment().endOf('month');

    constructor(private store: Store<ApplicationState>) {
        super();
        this.dateRangePickerControl = new UntypedFormControl();
        this.dateRangePickerControlFormatted = new UntypedFormControl({ value: '', disabled: true });
    }

    ngOnInit() {
        this.cumulativeValidationProgressData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (!data.length) {
                this.store.dispatch(new ChangeValidationDashboardCumulativeValidationProgressRequest());
            } else {
                this.cumulativeValidationProgressData = data;
            }
        });

        this.endDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((endDate) => !!endDate)
            )
            .subscribe((endDate) => {
                this.dateRangePickerControl.setValue(moment(endDate), { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(endDate)));
            });

        this.dateRangePickerControl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((date: moment.Moment) => {
                this.store.dispatch(new ChangeValidationDashboardCumulativeValidationProgressSetMonthRange(date));
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
            });
    }

    chosenYearHandler(normalizedYear: moment.Moment) {
        const dateChozen = moment(normalizedYear);
        const ctrlValue = this.dateRangePickerControl.value;
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue, { emitEvent: false });
    }

    chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
        const ctrlValue = this.dateRangePickerControl.value;
        const dateChozen = moment(normalizedMonth);
        ctrlValue.month(dateChozen.month());
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue);
        datepicker.close();
    }

    private formatDateRange(endDate: moment.Moment) {
        return `${moment(endDate)
            .add(-CUMULATIVE_VALIDATION_PROGRESS_MONTH_RANGE + 1, 'months')
            .format('MMM YYYY')} - ${endDate.format('MMM YYYY')}`;
    }
}
