<main class="main">
    <a class="navigator doNotPrint" [routerLink]="['/weeklyplanning']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div>
            <div class="title">daily dashboard | {{ todayDate | date: 'MMM d y' | lowercase }}</div>
            <div>
                <span class="weekdate title">week of </span>
                <span
                    matTooltip="Click to change date range"
                    matTooltipClass="scttooltip"
                    class="date weekdate title"
                    *ngIf="!isWeekSelectorVisible"
                    mat-icon-button
                    (click)="showWeekSelector()"
                >
                    {{ selectedWeekStartDate.value | date: 'MMM d' | lowercase }} -
                    {{ selectedWeekEndDate | date: 'MMM d y' | lowercase }}
                </span>
                <mat-form-field class="margin-left20" *ngIf="isWeekSelectorVisible" style="visibility: hidden;">
                    <mat-label>Select week</mat-label>
                    <mat-select
                        #weekSelector="matSelect"
                        [formControl]="selectedWeekStartDate"
                        [compareWith]="compareDates"
                        (openedChange)="onWeekSelectorClosed($event)"
                    >
                        <mat-option
                            *ngFor="let week of weekOptions"
                            [value]="week.startDate"
                            [ngClass]="setWeekOptionClasses(week)"
                            class="dateselect"
                        >
                            {{ week.startDate | date: 'MMM d y' }} - {{ week.endDate | date: 'MMM d y' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="filter && filter.contractors.length > 0" class="header-text title">
                {{ filter.contractors[0].contractNo }} : {{ filter.contractors[0].contract }}
            </div>
        </div>
        <div class="buttons-container flex doNotPrint">
            <button
                *ngIf="hasAdminPriviliged"
                class="sct-button sct-button-light margin-right20"
                (click)="changeExtendedMode()"
            >
                {{ extendedMode === true ? 'print - normal' : 'print - customize' }}
            </button>
            <button
                class="sct-button sct-button-light"
                printSectionId="print-section"
                ngxPrint
                [useExistingCss]="true"
                [printTitle]="printTitle"
                styleSheetFile="../../../assets/css/print/landscape.css,../../../assets/css/print/daily-dashboard.css"
                [disabled]="isLoading"
            >
                print
            </button>
        </div>
    </div>
    <div class="filter-container">
        <app-daily-planning-session-filter
            [projectTeamName]="projectTeamName.toUpperCase()"
            (searchEvent)="search($event)"
            [startDate]="selectedWeekStartDate.value"
            [planningType]="this.planningType.toLocaleLowerCase()"
        ></app-daily-planning-session-filter>
    </div>
    <div id="print-section">
        <div class="only-print page1">
            <div class="container">
                <div class="header">
                    <div class="print-title">
                        {{ projectTeamName | uppercase }} Weekly {{ planningType | uppercase }} Commitments
                    </div>
                    <div *ngIf="filter && filter.contractors.length > 0" class="header-text title">
                        {{ filter.contractors[0].contractNo }} : {{ filter.contractors[0].contract }}
                    </div>
                </div>
                <div class="footer">
                    <h4>Useful links:</h4>
                    <div class="links">
                        <div class="link-item">
                            <div>
                                {{ projectTeamName | uppercase }} {{ planningType | uppercase }} Daily Dashboard:
                                <span class="link">
                                    go.chevron.com/{{ projectTeamName }}Daily{{ planningType | uppercase }}
                                </span>
                            </div>
                        </div>
                        <div class="link-item">
                            <div>
                                {{ projectTeamName | uppercase }} {{ planningType | uppercase }} Walkdown Skyline:
                                <span class="link" *ngIf="planningType === 'mc'">
                                    go.chevron.com/{{ projectTeamName }}Walkdowns
                                </span>
                                <span class="link" *ngIf="planningType === 'rfsu'">
                                    go.chevron.com/{{ projectTeamName }}{{ planningType | uppercase }}Walkdown
                                </span>
                            </div>
                        </div>
                        <div class="link-item">
                            <div>
                                {{ projectTeamName | uppercase }} {{ planningType | uppercase }} Plan Skyline:
                                <span class="link">
                                    go.chevron.com/{{ projectTeamName }}{{ planningType | uppercase }}Plan
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page2" [ngClass]="planningType">
            <div class="header only-print">
                <div>Daily Dashboard | {{ todayDate | date: 'MMM d y' }}</div>
                <div class="weekdate">
                    week of {{ selectedWeekStartDate.value | date: 'MMM d' | lowercase }} -
                    {{ selectedWeekEndDate | date: 'MMM d y' | lowercase }}
                </div>
            </div>
            <app-top-header-table
                [ngClass]="planningType"
                *ngIf="planningType !== 'rfo'"
                [startDate]="selectedWeekStartDate.value"
                [projectTeamName]="projectTeamName"
                [planningType]="planningType"
                [additionalForced]="additionalForced"
                [additionalAdditions]="additionalAdditions"
            ></app-top-header-table>
        </div>

        <div class="daily-container">
            <div class="avoid-break">
                <div class="weekly-commitments-header" [ngClass]="{ doNotPrint: weeklyCommitments.length === 0 }">
                    <h4>Weekly Commitment</h4>
                    <div class="table-checkboxes doNotPrint">
                        <mat-checkbox
                            [checked]="showConfig.showWeeklyCommitmentsComments"
                            (change)="
                                showConfig.showWeeklyCommitmentsComments = !showConfig.showWeeklyCommitmentsComments
                            "
                        >
                            Show comments
                        </mat-checkbox>
                    </div>
                </div>
                <table
                    mat-table
                    [dataSource]="weeklyCommitments"
                    [ngClass]="{ doNotPrint: weeklyCommitments.length === 0 }"
                >
                    <ng-container matColumnDef="subsystem">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Subsystem
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.subsystem }}</td>
                    </ng-container>
                    <ng-container matColumnDef="subsystemName">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Subsystem Name
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.subsystemName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="systemOwner">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            System Owner
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.systemOwner }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuActual">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFSU Actual
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfsuActual | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfo">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfo }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoName">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO Name
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfoName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mcPlan">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            MC Plan
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.mcPlan | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuPlan">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFSU Plan
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfsuPlan | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoPlan">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO Plan
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfoPlan | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mcActual">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            MC Actual
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.mcActual | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoActual">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO Actual
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfoActual | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mcForecast">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            MC Forecast
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.mcForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuForecast">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFSU Forecast
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfsuForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoForecast">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO Forecast
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfoForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="walkdownActual">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Walkdown Actual
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.walkdownActual | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="walkdownForecast">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Walkdown Forecast
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.walkdownForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="edossierInitiationDate">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            E Dossier
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.element.edossierInitiationDate | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="edossierStatus">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            E Dossier Status
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.element.edossierStatus }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="aqvd">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            A-QVD
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.aqvd }}</td>
                    </ng-container>
                    <ng-container matColumnDef="bitr">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            B-ITR
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.bitr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="citr">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            C-ITR
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.citr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="apli">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            A-PLI
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.apli }}</td>
                    </ng-container>
                    <ng-container matColumnDef="redline">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Redline
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.redline }}</td>
                    </ng-container>
                    <ng-container matColumnDef="bpli">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            B-PLI
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.bpli }}</td>
                    </ng-container>
                    <ng-container matColumnDef="cpli">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            C-PLI
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.cpli }}</td>
                    </ng-container>
                    <ng-container matColumnDef="npw">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            NPW
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.npw }}</td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{ hidecells: !showConfig.showWeeklyCommitmentsComments }"
                        >
                            Comments
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let row"
                            [ngClass]="{ hidecells: !showConfig.showWeeklyCommitmentsComments }"
                        >
                            <span [innerHTML]="row.element.comment | formatMentions: row.element.mentions"></span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="doNotPrint"></th>
                        <td mat-cell *matCellDef="let row; let i = index" class="doNotPrint">
                            <button mat-icon-button (click)="toggleGreen(row)">
                                <mat-icon>done</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr
                        mat-header-row
                        *matHeaderRowDef="extendedMode ? displayedColumns : displayedColumns.slice(0, -1)"
                    ></tr>
                    <tr
                        [ngClass]="{ highlight: shouldHighlightWeeklyCommitment(row) }"
                        mat-row
                        *matRowDef="let row; columns: extendedMode ? displayedColumns : displayedColumns.slice(0, -1)"
                    ></tr>
                </table>
            </div>
            <div class="avoid-break">
                <div
                    class="additions-header"
                    [ngClass]="{ doNotPrint: additionsToWeeklyCommitmentsTable.data.length === 0 }"
                >
                    <h4>Additions to Weekly Commitments</h4>
                    <div class="table-checkboxes doNotPrint">
                        <mat-checkbox
                            [checked]="showConfig.showAdditionsComments"
                            (change)="showConfig.showAdditionsComments = !showConfig.showAdditionsComments"
                        >
                            Show comments
                        </mat-checkbox>
                    </div>
                </div>
                <table
                    mat-table
                    [dataSource]="additionsToWeeklyCommitmentsTable"
                    [ngClass]="{ doNotPrint: additionsToWeeklyCommitmentsTable.data.length === 0 }"
                >
                    <ng-container matColumnDef="subsystem">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Subsystem
                        </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                            <ng-container *ngIf="!row.isInEditMode">{{ row.element.subsystem }}</ng-container>
                            <app-multiple-items-selector
                                *ngIf="row.isInEditMode"
                                [setInput]="setSubsystemInput"
                                [isDisabled]="row.element === null"
                                [searchFunc]="getSubsystems"
                                [isAsync]="true"
                                [isSelectAll]="false"
                                [isTableAutoComplete]="true"
                                (autocompleteClosed)="onSubsystemsClosed($event, i)"
                                [propertyToShow]="'subsystem'"
                                [oneItemMode]="true"
                            >
                            </app-multiple-items-selector>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="subsystemName">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Subsystem Name
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.subsystemName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="systemOwner">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            System Owner
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.systemOwner }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfo">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO
                        </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                            <div *ngIf="!row.isInEditMode">{{ row.element.rfo }}</div>
                            <app-multiple-items-selector
                                *ngIf="row.isInEditMode"
                                [setInput]="setRFOInput"
                                [isDisabled]="row.element === null"
                                [searchFunc]="getRFOs"
                                [isSelectAll]="false"
                                [isAsync]="true"
                                (autocompleteClosed)="onRFOsClosed($event, i)"
                                [propertyToShow]="'rfo'"
                                [oneItemMode]="true"
                                [secondPropertyToShow]="'rfoName'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name', 'rfoName']"
                            >
                            </app-multiple-items-selector>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="rfoName">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO Name
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfoName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mcPlan">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            MC Plan
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.mcPlan | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuPlan">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFSU Plan
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfsuPlan | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoPlan">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO Plan
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfoPlan | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mcActual">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            MC Actual
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.mcActual | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuActual">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFSU Actual
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfsuActual | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoActual">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO Actual
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfoActual | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mcForecast">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            MC Forecast
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.mcForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuForecast">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFSU Forecast
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfsuForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoForecast">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            RFO Forecast
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.rfoForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="walkdownActual">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Walkdown Actual
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.walkdownActual | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="walkdownForecast">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Walkdown Forecast
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.walkdownForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="edossierInitiationDate">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            E Dossier
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.element.edossierInitiationDate | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="edossierStatus">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            E Dossier Status
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.element.edossierStatus }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="aqvd">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            A-QVD
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.aqvd }}</td>
                    </ng-container>
                    <ng-container matColumnDef="bitr">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            B-ITR
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.bitr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="citr">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            C-ITR
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.citr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="apli">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            A-PLI
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.apli }}</td>
                    </ng-container>
                    <ng-container matColumnDef="redline">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            Redline
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.redline }}</td>
                    </ng-container>
                    <ng-container matColumnDef="bpli">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            B-PLI
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.bpli }}</td>
                    </ng-container>
                    <ng-container matColumnDef="cpli">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            C-PLI
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.cpli }}</td>
                    </ng-container>
                    <ng-container matColumnDef="npw">
                        <th mat-header-cell disableClear *matHeaderCellDef>
                            NPW
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.element.npw }}</td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{ hidecells: !showConfig.showAdditionsComments }"
                        >
                            Comments
                        </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showConfig.showAdditionsComments }">
                            <span [innerHTML]="row.element.comment | formatMentions: row.element.mentions"></span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="doNotPrint"></th>
                        <td mat-cell *matCellDef="let row; let i = index" class="doNotPrint">
                            <button mat-icon-button (click)="removeRow(i)" *ngIf="!row.isInitial">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr
                        mat-header-row
                        *matHeaderRowDef="extendedMode ? displayedColumns : displayedColumns.slice(0, -1)"
                    ></tr>
                    <tr
                        [ngClass]="{ highlight: shouldHighlightAdditionToWeeklyCommitment(row) }"
                        mat-row
                        *matRowDef="let row; columns: extendedMode ? displayedColumns : displayedColumns.slice(0, -1)"
                    ></tr>
                </table>
            </div>
            <button
                *ngIf="extendedMode"
                class="sct-button sct-button-light doNotPrint"
                (click)="addWeeklySubsystem()"
                [disabled]="isAddingWeeklySubsystemDisabled"
            >
                {{ addWeeklyText }}
            </button>
            <div *ngIf="isLoading" class="delta-loader">
                <div class="lds-hourglass"></div>
            </div>
        </div>
    </div>
</main>
