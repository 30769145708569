import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { SaveScrollPosition } from 'src/app/store/ux/actions';
import { ScrollPosition } from 'src/app/store/ux/model';
import { delay, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mat-table-sticky-header',
  templateUrl: './mat-table-sticky-header.component.html'
})
export class MatTableStickyHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() scrollKey?: string = '';
  @ViewChild('tableRef', { static: false }) table: ElementRef;

  routerEvents$: Subscription;

  constructor(
    private store: Store<ApplicationState>,
    private router: Router
  ) {
    this.routerEvents$ = router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.saveScrollPosition();
        }
      });
  }

  ngOnInit(): void {
    // This is intentional
  }

  ngOnChanges() {
    this.setupScrollPosition();
  }

  ngOnDestroy(): void {
    this.routerEvents$.unsubscribe();
  }

  saveScrollPosition() {
    if (this.scrollKey) {
      this.store.dispatch(new SaveScrollPosition({ [this.scrollKey]: this.table.nativeElement.scrollTop } as ScrollPosition));
    }
  }

  setupScrollPosition() {
    if (this.scrollKey) {
      this.store.select((state) => state.uxState.scrollPosition)
        .pipe(take(1))
        .pipe(delay(100))
        .subscribe(position => {
          this.table.nativeElement.scrollTop = position[this.scrollKey] || 0;
        });
    }
  }
}
