<main class="main">
    <a class="navigator" [routerLink]="[]" (click)="goBack()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span *ngIf="commitmentReliabilityType !== 'ITR'"
                >{{ commitmentReliabilityType }} commitment reliability</span
            >
            <span *ngIf="commitmentReliabilityType === 'ITR'">SC Weekly Plan commitment reliability</span>
        </div>
    </div>
    <nav mat-tab-nav-bar class="area-centered">
        <a
            mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
        >
            {{ link.label }}
        </a>
    </nav>
    <div class="filter-container">
        <form [formGroup]="filterForm">
            <mat-accordion class="filter-container">
                <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                    <mat-expansion-panel-header collapsedHeight="auto">
                        <div *ngIf="!filterExpansionPanel.expanded">
                            <mat-chip-list aria-label="Active filters">
                                <ng-container *ngIf="!isITRType">
                                    <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                        Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                            >cancel
                                        </mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                        System Owner:
                                        {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')"
                                            >cancel
                                        </mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                                        RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                                    </mat-basic-chip>
                                </ng-container>
                                <ng-container *ngIf="isITRType">
                                    <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                                        RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.subsystems.value.length" removable>
                                        Subsystems: {{ displayMultipleSelected(filterForm.controls.subsystems.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('subsystems')"
                                            >cancel
                                        </mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                        Disciplines:
                                        {{ displayMultipleSelected(filterForm.controls.disciplines.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')"
                                            >cancel
                                        </mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.areas.value.length" removable>
                                        Areas: {{ displaySelectedAreas(filterForm.controls.areas.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('areas')">cancel</mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.workAreas.value.length" removable>
                                        Work Areas: {{ displaySelectedWorkAreas(filterForm.controls.workAreas.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('workAreas')"
                                            >cancel
                                        </mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip
                                        *ngIf="filterForm.controls.nbDisciplineLeads.value.length"
                                        removable
                                    >
                                        NB Discipline Leads:
                                        {{ displayMultipleSelected(filterForm.controls.nbDisciplineLeads.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('nbDisciplineLeads')"
                                            >cancel
                                        </mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.nbEngineers.value.length" removable>
                                        NB Engineers:
                                        {{ displayMultipleSelected(filterForm.controls.nbEngineers.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('nbEngineers')"
                                            >cancel
                                        </mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip
                                        *ngIf="filterForm.controls.tcoAreaCoordinators.value.length"
                                        removable
                                    >
                                        TCO Area Coordinators:
                                        {{ displayMultipleSelected(filterForm.controls.tcoAreaCoordinators.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('tcoAreaCoordinators')"
                                            >cancel
                                        </mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                        SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('scManager')"
                                            >cancel
                                        </mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                        Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                        Area Breakdown:
                                        {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                                        RFSU Contractor:
                                        {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                                    </mat-basic-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                    </mat-expansion-panel-header>

                    <ng-container *ngIf="isITRType" class="itr-filters">
                        <div class="mixedapp-container">

                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    class="container300 margin-right20"
                                    [displayedColumns]="['name', 'rfoName']"
                                    [filterForm]="filterForm"
                                    formControlName="rfos"
                                    [formSetter]="'rfos'"
                                    [isAsync]="true"
                                    [isTableAutoComplete]="true"
                                    [panelWidth]="480"
                                    [placeholder]="'RFO'"
                                    [propertyToShow]="'name'"
                                    [searchFunc]="getRFOs"
                                    [secondPropertyToShow]="'rfoName'"
                                    [setInput]="setRFOInput"
                                >
                                </app-multiple-items-selector>
                            </div>

                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    class="container300 margin-right20"
                                    [displayedColumns]="['id', 'name']"
                                    [filterForm]="filterForm"
                                    formControlName="subsystems"
                                    [formSetter]="'subsystems'"
                                    [isAsync]="true"
                                    [panelWidth]="480"
                                    [placeholder]="'Subsystem'"
                                    [propertyToShow]="'id'"
                                    [reloadOnOpen]="true"
                                    [searchFunc]="searchSubsystems"
                                    [setInput]="setSubsystemInput"
                                >
                                </app-multiple-items-selector>
                            </div>

                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    class="container300 margin-right20"
                                    [displayedColumns]="['name']"
                                    [filterForm]="filterForm"
                                    formControlName="disciplines"
                                    [formSetter]="'disciplines'"
                                    [isAsync]="false"
                                    [placeholder]="'Discipline'"
                                    [propertyToShow]="'name'"
                                    [searchFunc]="searchDiscipline"
                                >
                                </app-multiple-items-selector>
                            </div>

                            <mat-form-field class="container300 margin-right20">
                                <mat-label>Area</mat-label>
                                <mat-select formControlName="areas" multiple>
                                    <mat-option *ngFor="let area of mslAreas" [value]="area">{{ area }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="container300 margin-right20">
                                <mat-label>SC Execution Area Manager</mat-label>
                                <mat-select formControlName="scManager" multiple>
                                    <mat-option *ngFor="let sc of scManagers" [value]="sc.id"
                                        >{{ sc.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    class="container300"
                                    [setInput]="setRFSUContractorsInput"
                                    formControlName="rfsuContractors"
                                    [searchFunc]="getRFSUContractors"
                                    [isAsync]="true"
                                    [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                    [filterForm]="filterForm"
                                    [formSetter]="'rfsuContractors'"
                                    [propertyToShow]="'contractNo'"
                                    [placeholder]="'RFSU Contractor'"
                                    [panelWidth]="480"
                                    [secondPropertyToShow]="'contract'"
                                >
                                </app-multiple-items-selector>
                            </div>
    
                            <mat-form-field class="container300 margin-right15">
                                <mat-label>Area Completion Lead</mat-label>
                                <mat-select formControlName="areaCompletionLead" multiple>
                                    <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    class="container300"
                                    [setInput]="setAreaBreakdownInput"
                                    formControlName="areaBreakdown"
                                    [searchFunc]="getAreaBreakdown"
                                    [isAsync]="true"
                                    [filterForm]="filterForm"
                                    [formSetter]="'areaBreakdown'"
                                    [placeholder]="'Area Breakdown'"
                                >
                                </app-multiple-items-selector>
                            </div>

                        </div>
                        <div>
                            <h4>Work Area:</h4>
                        </div>
                        <div class="mixedapp-container">
                            <app-multiple-items-selector
                                class="container300 margin-right20"
                                #workAreaItemSelector
                                placeholder="Work Area"
                                formControlName="workAreas"
                                formSetter="workAreas"
                                propertyToShow="workAreaName"
                                [oneItemMode]="true"
                                [displayedColumns]="['workAreaName']"
                                [searchFunc]="searchAreas"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                (itemRemoved)="resetWorkAreaFilters()"
                            >
                            </app-multiple-items-selector>

                            <app-chip-list
                                collectionName="Work Area NB Discipline Lead"
                                [collectionItems]="nbDisciplineLeads"
                            >
                            </app-chip-list>

                            <app-chip-list collectionName="Work Area NB Engineer" [collectionItems]="nbEngineers">
                            </app-chip-list>

                            <app-chip-list
                                collectionName="Work Area TCO Coordinator"
                                [collectionItems]="tcoAreaCoordinators"
                            >
                            </app-chip-list>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!isITRType">
                        <div class="mixedapp-container">

                            <mat-form-field class="container300 margin-right20">
                                <mat-label>Project Team</mat-label>
                                <mat-select
                                    formControlName="projectTeamNames"
                                    multiple
                                    (openedChange)="onProjectTeamsClosed($event)"
                                >
                                    <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    [setInput]="setContractorInput"
                                    formControlName="contractors"
                                    [searchFunc]="getContractors"
                                    [isAsync]="true"
                                    [isTableAutoComplete]="true"
                                    [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                    (autocompleteClosed)="onContractorsClosed()"
                                    (itemRemoved)="onContractorsClosed()"
                                    [filterForm]="filterForm"
                                    formSetter="contractors"
                                    propertyToShow="contractNo"
                                    placeholder="MSL Contractor"
                                    [panelWidth]="480"
                                    [secondPropertyToShow]="'contract'"
                                >
                                </app-multiple-items-selector>
                            </div>

                            <mat-form-field class="container300 margin-right20">
                                <mat-label>SC Execution Area Manager</mat-label>
                                <mat-select formControlName="scManager" multiple>
                                    <mat-option *ngFor="let sc of scManagers" [value]="sc.id"
                                        >{{ sc.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    [setInput]="setStagedStartUpPrioritiesInput"
                                    formControlName="stagedStartUpPriorities"
                                    [searchFunc]="getStagedStartUpPriorities"
                                    [isAsync]="true"
                                    [isTableAutoComplete]="true"
                                    [displayedColumns]="['priority', 'priorityName']"
                                    (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                                    (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                                    [filterForm]="filterForm"
                                    formSetter="stagedStartUpPriorities"
                                    [propertyToShow]="'priority'"
                                    [secondPropertyToShow]="'priorityName'"
                                    placeholder="Staged Start-up Priority"
                                    [panelWidth]="480"
                                    class="container300"
                                >
                                </app-multiple-items-selector>
                            </div>

                            <mat-form-field class="container300 margin-right15">
                                <mat-label>System Owner</mat-label>
                                <mat-select formControlName="systemOwner" multiple>
                                    <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    *ngIf="commitmentReliabilityType === 'RFSU'"
                                    class="container300"
                                    [setInput]="setRFOInput"
                                    formControlName="rfos"
                                    [searchFunc]="getRFOs"
                                    [isAsync]="true"
                                    [filterForm]="filterForm"
                                    [formSetter]="'rfos'"
                                    [propertyToShow]="'name'"
                                    [placeholder]="'RFO'"
                                    [secondPropertyToShow]="'rfoName'"
                                    [isTableAutoComplete]="true"
                                    [displayedColumns]="['name', 'rfoName']"
                                    [panelWidth]="480"
                                >
                                </app-multiple-items-selector>
                            </div>

                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    class="container300"
                                    [setInput]="setRFSUContractorsInput"
                                    formControlName="rfsuContractors"
                                    [searchFunc]="getRFSUContractors"
                                    [isAsync]="true"
                                    [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                    [filterForm]="filterForm"
                                    [formSetter]="'rfsuContractors'"
                                    [propertyToShow]="'contractNo'"
                                    [placeholder]="'RFSU Contractor'"
                                    [panelWidth]="480"
                                    [secondPropertyToShow]="'contract'"
                                >
                                </app-multiple-items-selector>
                            </div>
    
                            <mat-form-field class="container300 margin-right15">
                                <mat-label>Area Completion Lead</mat-label>
                                <mat-select formControlName="areaCompletionLead" multiple>
                                    <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <div class="container300 margin-right15">
                                <app-multiple-items-selector
                                    class="container300"
                                    [setInput]="setAreaBreakdownInput"
                                    formControlName="areaBreakdown"
                                    [searchFunc]="getAreaBreakdown"
                                    [isAsync]="true"
                                    [filterForm]="filterForm"
                                    [formSetter]="'areaBreakdown'"
                                    [placeholder]="'Area Breakdown'"
                                >
                                </app-multiple-items-selector>
                            </div>

                        </div>
                    </ng-container>

                    <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                        <div class="lds-hourglass"></div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </form>
        <div class="buttons-container flex">
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
    </div>
    <div class="commitment-reliability-container">
        <div class="subtitle-container">
            <h4 *ngIf="commitmentReliabilityType !== 'ITR'">
                {{ commitmentReliabilityType?.toLowerCase() }} commitment reliability
            </h4>
            <h4 *ngIf="commitmentReliabilityType === 'ITR'">
                sc weekly plan commitment reliability
            </h4>
            <div class="options-container">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="dateRangePickerControlFormatted" />
                    <input
                        matInput
                        [matDatepicker]="dateRangePicker"
                        [matDatepickerFilter]="monthViewEnabled ? null : dateRangePickerFilter"
                        [formControl]="dateRangePickerControl"
                        [max]="rangeFilterDate"
                        hidden
                    />
                    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                    <mat-datepicker
                        #dateRangePicker
                        [startView]="monthViewEnabled ? 'year' : 'month'"
                        (yearSelected)="monthViewEnabled ? chosenYearHandler($event) : null"
                        (monthSelected)="monthViewEnabled ? chosenMonthHandler($event, dateRangePicker) : null"
                        disabled="false"
                    ></mat-datepicker>
                </mat-form-field>
                <mat-slide-toggle
                    [checked]="planViewEnabled"
                    (toggleChange)="togglePlanView()"
                    *ngIf="commitmentReliabilityType === 'RFSU'"
                >
                    show plan
                </mat-slide-toggle>
            </div>
            <div class="legend-container">
                <div
                    *ngIf="commitmentReliabilityType === 'ITR' || commitmentReliabilityType === 'RFSU'"
                    class="legend-container-first-line"
                >
                    <div class="legend-item black">
                        {{ commitmentReliabilityType === 'ITR' ? 'ITRs ' : 'RFSUs ' }}per Plan
                    </div>
                    <div class="legend-item orange">
                        {{ commitmentReliabilityType === 'ITR' ? 'ITRs ' : 'RFSUs ' }}in Week
                    </div>
                </div>
                <div class="legend-container-second-line">
                    <div class="legend-item green">
                        {{
                            commitmentReliabilityType === 'ITR'
                                ? 'ITR i'
                                : commitmentReliabilityType === 'RFSU'
                                ? 'RFSU i'
                                : 'I'
                        }}n Week and achieved
                    </div>
                    <div class="legend-item red">
                        {{
                            commitmentReliabilityType === 'ITR'
                                ? 'ITR i'
                                : commitmentReliabilityType === 'RFSU'
                                ? 'RFSU i'
                                : 'I'
                        }}n Week but not achieved
                    </div>
                </div>
                <div class="legend-container-third-line">
                    <div class="legend-item grey">
                        {{
                            commitmentReliabilityType === 'ITR'
                                ? 'ITR a'
                                : commitmentReliabilityType === 'RFSU'
                                ? 'RFSU a'
                                : 'A'
                        }}chieved but not in Week
                    </div>
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</main>
