import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { StagedStartUpPriority } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import { ScheduleActivityClearFilters } from 'src/app/store/weekly-planning/schedule-activity-planning/actions';

@Component({
    selector: 'app-lookahead-filter-top-header-section',
    templateUrl: './lookahead-filter-top-header-section.component.html',
    styleUrls: ['./lookahead-filter-top-header-section.component.scss'],
})
export class LookaheadFilterTopHeaderSectionComponent implements OnInit {
    @Input() showFilters;
    @Input() filterForm: UntypedFormGroup;

    constructor(private store: Store<ApplicationState>) {}

    ngOnInit(): void {
        // This is intentional
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    clearFilters(event: any) {
        event.stopPropagation();
        this.store.dispatch(new ScheduleActivityClearFilters());
        this.filterForm.controls.workAreas.setValue([]);
        this.filterForm.controls.nbDisciplineLeads.setValue([]);
        this.filterForm.controls.nbEngineers.setValue([]);
        this.filterForm.controls.tcoAreaCoordinators.setValue([]);
        this.filterForm.controls.disciplines.setValue([]);
    }

    displayMultipleSelected(values: any[]) {
      return values.map((x) => x.name ?? x).join(', ');
    }

    displayStageUpPriorities(values: StagedStartUpPriority[]) {
        return values.map((x) => x.priority).join(', ');
    }
}

