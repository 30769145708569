import { MyPageState, MyPageDataPagination, MyPageFilter } from './model';
import { MyPageActions, MyPageActionTypes } from './actions';

const initialState: MyPageState = {
    dataPagination: new MyPageDataPagination(),
    filter: new MyPageFilter(),
    isLoading: false,
};

export function reducer(state = initialState, action: MyPageActions) {
    switch (action.type) {
        case MyPageActionTypes.MyPageMarkReadRequest:
        case MyPageActionTypes.MyPageFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case MyPageActionTypes.MyPageFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: action.payload,
            };
        }
        case MyPageActionTypes.MyPageMarkReadSuccess:
        case MyPageActionTypes.MyPageMarkReadError:
        case MyPageActionTypes.MyPageFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case MyPageActionTypes.MyPageFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                state.filter.sortBy = action.payload.value.active;
                state.filter.direction = action.payload.value.direction;
            } else {
                if (Array.isArray(state.filter[action.payload.key])) {
                    state.filter[action.payload.key] = Object.assign([], action.payload.value);
                } else {
                    state.filter[action.payload.key] = action.payload.value;
                }
            }
            return {
                ...state,
            };
        }
        case MyPageActionTypes.MyPageFilterReset: {
            const newFilter = new MyPageFilter();
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        default:
            return state;
    }
}
