import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ManualUploadSetActivatedRoute } from 'src/app/store/manual-upload/actions';
import { FlangeManagementDownloadOutputDataRequest, FlangeManagementTemplateFileRequest, FlangeManagementUploadLogRequest } from 'src/app/store/manual-upload/flange-management/actions';
import { ApplicationState } from 'src/app/store/model';

@Component({
  selector: 'app-flange-management',
  templateUrl: './flange-management.component.html',
  styleUrls: ['./flange-management.component.scss']
})
export class FlangeManagementComponent extends BaseComponent implements OnInit {
  uploadLogData: MatTableDataSource<UpdateDataLog>;
  displayedUploadLogDataColumns = [
    'type',
    'status',
    'startDate',
    'endDate',
    'infoMessage',
    'errorMessage',
    'user',
    'downloadResult',
  ];

  uploadLogData$ = this.store.select((store) => store.flangeManagementState.uploadLogData);
  isUploadLogLoading$ = this.store.select((store) => store.flangeManagementState.isUploadLogLoading);
  isLoading$ = this.store.select((store) => store.flangeManagementState.isLoading);
  interval$ = this.store.select((store) => store.manualUploadState.interval);
  intervalSub: Subscription = null;
  showOrder = true;
  constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
      this.store.dispatch(new ManualUploadSetActivatedRoute(urlSegments[0].path));
    });
    this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
      if (this.intervalSub) {
        this.intervalSub.unsubscribe();
      }
      this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
        this.store.dispatch(new FlangeManagementUploadLogRequest());
      });
    });

    this.uploadLogData$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

  }

  downloadTemplate(fileName: string) {
    this.store.dispatch(new FlangeManagementTemplateFileRequest(fileName));
  }

  downloadData(filePath: string) {
    this.store.dispatch(new FlangeManagementDownloadOutputDataRequest(filePath));
  }

}
