<div class="filter-header-container">
  <span class="filter-header-button">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M13 0H1C0.45 0 0 0.449679 0 0.999286C0 1.27909 0.11 1.52891 0.29 1.70878L5 6.41542V13.0007C5 13.5503 5.45 14 6 14C6.28 14 6.53 13.8901 6.71 13.7102L8.71 11.7116C8.89 11.5318 9 11.2819 9 11.0021V6.41542L13.71 1.70878C13.89 1.52891 14 1.27909 14 0.999286C14 0.449679 13.55 0 13 0Z"
              fill="#0066B2"
          />
      </svg>
      filter by
  </span>
  <div class="active-filters-container" *ngIf="!filterExpansionPanel.expanded">
      <mat-chip-list aria-label="Active filters">
        <mat-basic-chip *ngIf="filterForm.controls.dcnRfiNumber.value.length" removable>
          DCN/RFI Number: {{ displayMultipleSelected(filterForm.controls.dcnRfiNumber.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('dcnRfiNumber')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.redlineNumber.value.length" removable>
          Redline Document Number: {{ displayMultipleSelected(filterForm.controls.redlineNumber.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('redlineNumber')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.dcnRfiDiscipline.value.length" removable>
          DCN/RFI Discipline: {{ displayMultipleSelected(filterForm.controls.dcnRfiDiscipline.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('dcnRfiDiscipline')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.dcnRfiEngineeringStatus.value.length" removable>
          DCN/RFI Engineering Status: {{ displayMultipleSelected(filterForm.controls.dcnRfiEngineeringStatus.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('dcnRfiEngineeringStatus')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.dcnRfiImpactedSubsystem.value.length" removable>
          DCN/RFI Subsystems: {{ displayMultipleSelected(filterForm.controls.dcnRfiImpactedSubsystem.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('dcnRfiImpactedSubsystem')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.dcnRfiTitle.value.length" removable>
          DCN/RFI Title: {{ displayMultipleSelected(filterForm.controls.dcnRfiTitle.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('dcnRfiTitle')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.dcnRfiContent.value.length" removable>
          DCN/RFI Content: {{ displayMultipleSelected(filterForm.controls.dcnRfiContent.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('dcnRfiContent')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.dcnRfiImplementationStatus.value.length" removable>
          Implementation Status: {{ displayMultipleSelected(filterForm.controls.dcnRfiImplementationStatus.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('dcnRfiimplementationStatus')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.redlineDiscipline.value.length" removable>
          Redline Disciplines: {{ displayMultipleSelected(filterForm.controls.redlineDiscipline.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('redlineDiscipline')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.redlineSubsystem.value.length" removable>
          Redline Subsystem: {{ displayMultipleSelected(filterForm.controls.redlineSubsystem.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('redlineSubsystem')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.redlineTitle.value.length" removable>
          Redline Title: {{ displayMultipleSelected(filterForm.controls.redlineTitle.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('redlineTitle')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.rlmuStatus.value.length" removable>
          RLMU Status: {{ displayMultipleSelected(filterForm.controls.rlmuStatus.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('rlmuStatus')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.redlineStatus.value.length" removable>
          Redline Status: {{ displayMultipleSelected(filterForm.controls.redlineStatus.value) }}
          <mat-icon matChipRemove (click)="clearFilterProperty('redlineStatus')">cancel</mat-icon>
        </mat-basic-chip>
        <mat-basic-chip *ngIf="filterForm.controls.redlineReadyForSubmission.value" removable>
          Ready For Submission:
          {{ displaySelectedBoolean(filterForm.controls.redlineReadyForSubmission.value) }}
        </mat-basic-chip>

      </mat-chip-list>
  </div>
  <div class="filter-panel-header-buttons">
    <button mat-button class="light-blue" (click)="toggleExpansionPanel($event)">
        {{ filterExpansionPanel.expanded ? 'Hide panel' : 'Show panel' }}
    </button>
  </div>
</div>
