<div class="chart-container">
    <mat-expansion-panel #chartPanel>
        <mat-expansion-panel-header></mat-expansion-panel-header>
        <div class="chartsbar-container">
            <div>
                <h4>
                    Subsystems with exceptions
                </h4>
                <ngx-charts-bar-vertical-2d
                    [scheme]="colorScheme"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [results]="subsystemChart$ | async"
                    [roundDomains]="true"
                    [roundEdges]="false"
                    [barPadding]="1"
                    [view]="[600, 300]"
                    (select)="onSelect($event, 'subsystems')"
                >
                </ngx-charts-bar-vertical-2d>
            </div>
            <div>
                <h4>
                    Exceptions Count
                </h4>
                <ngx-charts-bar-vertical-2d
                    [scheme]="colorScheme"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [results]="chart$ | async"
                    [roundDomains]="true"
                    [roundEdges]="false"
                    [barPadding]="1"
                    [view]="[600, 300]"
                    (select)="onSelect($event, 'exceptions')"
                >
                </ngx-charts-bar-vertical-2d>
            </div>
        </div>
        <ngx-charts-legend class="chart-legend" [data]="barChartLabels" [colors]="barChartColors"> </ngx-charts-legend>
    </mat-expansion-panel>
</div>
