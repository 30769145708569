import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Subscription } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { CriticalPathDeleteDTO, CriticalPathDeleteFilter } from 'src/app/store/planning-upload/critical-path/model';
import {
    CriticalPathUploadLogRequest,
    CriticalPathPatchDeleteRequest,
    CriticalPathPatchAllDeleteRequest,
    CriticalPathDeleteFilterPropertyUpdate,
    CriticalPathDeleteRequest,
    CriticalPathTemplateFileRequest,
    CriticalPathDownloadOutputDataRequest,
    CriticalPathDeleteFilterReset,
} from 'src/app/store/planning-upload/critical-path/actions';
import { PlanningUploadSetActivatedRoute } from 'src/app/store/planning-upload/actions';
import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';

@Component({
    selector: 'app-critical-path',
    templateUrl: './critical-path.component.html',
    styleUrls: ['./critical-path.component.scss'],
})
export class CriticalPathComponent extends BaseComponent implements OnInit {

    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    // deleteData: MatTableDataSource<CriticalPathDeleteDTO>;
    // deleteDataResultsLength = 0;
    // deleteDataPageSize = 10;
    // displayedDeleteDataColumns = [
    //     'activityId',
    //     'actions',
    // ];

    // sortBy$ = this.store.select((state) => state.criticalPathState.deleteFilter.sortBy);
    // direction$ = this.store.select((state) => state.criticalPathState.deleteFilter.direction);
    uploadLogData$ = this.store.select((store) => store.criticalPathState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.criticalPathState.isUploadLogLoading);
    // deleteData$ = this.store.select((store) => store.criticalPathState.deleteData);
    // isDeleteDataLoading$ = this.store.select((store) => store.criticalPathState.isDeleteDataLoading);
    isLoading$ = this.store.select((store) => store.criticalPathState.isLoading);
    interval$ = this.store.select((store) => store.planningUploadState.interval);
    intervalSub: Subscription = null;
    //deleteFilter$ = this.store.select((store) => store.criticalPathState.deleteFilter);

    //columnActivityIds$ = this.store.select((state) => state.criticalPathState.deleteFilter.columnActivityIds);

    showOrder = true;
    // sortBy: string;
    // direction = OrderDirection.Desc;
    // deleteValidationFilterForm: UntypedFormGroup;
    //columnActivityIds: CheckListColumn = null;

    constructor(private store: Store<ApplicationState>,
        private activatedRoute: ActivatedRoute,
        private popupSvc: PopupService,
        private formService: FormService,
        private lookupService: LookupService) {
        super();

        //this.deleteValidationFilterForm = this.formService.createSimpleForm(new CriticalPathDeleteFilter());
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new PlanningUploadSetActivatedRoute(urlSegments[0].path));
        });
        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new CriticalPathUploadLogRequest());
            });
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

        // this.deleteData$.pipe(takeWhile(() => this.isAlive)).subscribe((deleteData) => {
        //     this.deleteData = new MatTableDataSource(deleteData.items);
        //     this.deleteDataResultsLength = deleteData.totalCount;
        // });

        // this.deleteFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
        //     this.deleteValidationFilterForm.patchValue(filter, { emitEvent: false });
        //     this.sortBy = filter.sortBy;
        //     this.direction = filter.direction;
        // });

        // this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.sortBy = data));
        // this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.direction = data));

        // this.columnActivityIds$
        //     .pipe(takeWhile(() => this.isAlive))
        //     .subscribe((data) => (this.columnActivityIds = data));

    }

    // restoreItem(element: CriticalPathDeleteDTO) {

    //     this.store.dispatch(
    //         new CriticalPathPatchDeleteRequest({
    //             activityId: element.activityId,
    //             deleteState: false,
    //         })
    //     );
    // }

    // deleteItem(element: CriticalPathDeleteDTO) {

    //     this.store.dispatch(
    //         new CriticalPathPatchDeleteRequest({
    //             activityId: element.activityId,
    //             deleteState: true,
    //         })
    //     );
    // }

    // patchAllDeleteRecords(deleteState: boolean) {
    //     this.store.dispatch(
    //         new CriticalPathPatchAllDeleteRequest({
    //             deleteState: deleteState,
    //         })
    //     );
    // }

    // onDeleteDataPaginatorChange(pageEvent: PageEvent) {
    //     if (this.deleteDataPageSize !== pageEvent.pageSize) {
    //         this.store.dispatch(
    //             new CriticalPathDeleteFilterPropertyUpdate({
    //                 key: 'take',
    //                 value: pageEvent.pageSize,
    //             })
    //         );
    //         this.deleteDataPageSize = pageEvent.pageSize;
    //     } else {
    //         this.store.dispatch(
    //             new CriticalPathDeleteFilterPropertyUpdate({
    //                 key: 'page',
    //                 value: pageEvent.pageIndex,
    //             })
    //         );
    //     }
    //     this.store.dispatch(new CriticalPathDeleteRequest());
    // }

    downloadTemplate(fileName: string) {
        this.store.dispatch(new CriticalPathTemplateFileRequest(fileName));
    }

    downloadData(filePath: string) {
        this.store.dispatch(new CriticalPathDownloadOutputDataRequest(filePath));
    }

    // openHeaderDateFilter(
    //     columnName: string,
    //     propertyName: string,
    //     placeholder: string,
    //     calendarColumn: CalendarColumn
    // ) {
    //     const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
    //     const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
    //     const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
    //     this.popupSvc.openPopup(
    //         new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
    //             isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
    //             isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
    //             calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
    //             placeHolder: placeholder,
    //             isSortingOff: false,
    //             action: (data) => {
    //                 this.deleteValidationFilterForm.controls[propertyName].setValue(data.calendarColumn);
    //                 this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
    //                 this.search();
    //             },
    //         },
    //         345,
    //         580)
    //     );
    // }

    // openHeaderCheckListFilter(
    //     columnName: string,
    //     searchFunc: any,
    //     propertyName: string,
    //     placeholder: string,
    //     selected: CheckListColumn,
    //     showCounts: boolean = false,
    //     showInputSearch: boolean = true
    // ) {
    //     this.popupSvc.openPopup(
    //         new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
    //             isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
    //             isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
    //             placeHolder: placeholder,
    //             searchFunc: searchFunc,
    //             selectedItems: selected ? [...selected.items] : [],
    //             isSortingOff: false,
    //             showCounts: showCounts,
    //             showInputSearch: showInputSearch,
    //             action: (data) => {
    //                 let value = new CheckListColumn();
    //                 value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
    //                 this.deleteValidationFilterForm.controls[propertyName].setValue(value);

    //                 this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

    //                 this.search();
    //             },
    //             resetColumnAction: () => {
    //                 this.store.dispatch(
    //                     new CriticalPathDeleteFilterPropertyUpdate({
    //                         key: propertyName,
    //                         value: null,
    //                     })
    //                 );
    //             },
    //             cancelAction: () => {
    //                 this.store.dispatch(
    //                     new CriticalPathDeleteFilterPropertyUpdate({
    //                         key: propertyName,
    //                         value: this.deleteValidationFilterForm.controls[propertyName].value,
    //                     })
    //                 );
    //             },
    //         })
    //     );
    // }

    // private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
    //     if (isAscendingSort || isDescendingSort) {
    //         const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
    //         this.store.dispatch(
    //             new CriticalPathDeleteFilterPropertyUpdate({
    //                 key: 'sortBy',
    //                 value: { active: columnName, direction: direction },
    //             })
    //         );
    //     } else {
    //         this.store.dispatch(
    //             new CriticalPathDeleteFilterPropertyUpdate({
    //                 key: 'sortBy',
    //                 value: { active: 'barcode', direction: OrderDirection.Desc },
    //             })
    //         );
    //     }
    // }

    // search() {
    //     this.updateFilterByFormProperties();

    //     this.store.dispatch(
    //         new CriticalPathDeleteFilterPropertyUpdate({
    //             key: 'page',
    //             value: 0,
    //         })
    //     );
    //     this.store.dispatch(new CriticalPathDeleteRequest());
    // }

    // private updateFilterByFormProperties() {
    //     for (const key of Object.keys(this.deleteValidationFilterForm.controls)) {
    //         if (!(key === 'sortBy' || key === 'direction')) {
    //             const value = this.deleteValidationFilterForm.controls[key].value;
    //             this.store.dispatch(new CriticalPathDeleteFilterPropertyUpdate({ key, value }));
    //             if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
    //                 this.setHeaderFilterPerPageFilter(this.deleteValidationFilterForm, key, value);
    //             }
    //         }
    //     }
    // }
    
}

