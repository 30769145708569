import { ContractorMaintenanceState, ContractorDataPagination, ContractorFilter } from './model';
import { TeamDataActions, ContractorMaintenanceActionTypes } from './actions';
import * as _ from 'lodash';

const initialState: ContractorMaintenanceState = {
    contractorDataPagination: new ContractorDataPagination(),
    filter: new ContractorFilter(),
    isLoading: false,
};

export function reducer(state = initialState, action: TeamDataActions):ContractorMaintenanceState {
    switch (action.type) {
        case ContractorMaintenanceActionTypes.ContractorSaveRequest:
        case ContractorMaintenanceActionTypes.ContractorRemoveRequest:
        case ContractorMaintenanceActionTypes.ContractorFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ContractorMaintenanceActionTypes.ContractorFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                contractorDataPagination: action.payload,
            };
        }
        case ContractorMaintenanceActionTypes.ContractorRemoveError:
        case ContractorMaintenanceActionTypes.ContractorSaveError:
        case ContractorMaintenanceActionTypes.ContractorFilterError:
        case ContractorMaintenanceActionTypes.ContractorSaveSuccess:
        case ContractorMaintenanceActionTypes.ContractorRemoveSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ContractorMaintenanceActionTypes.ContractorFilterUpdate: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload
                }
            };
        }
        case ContractorMaintenanceActionTypes.ContractorFilterPropertyUpdate: {
            let newFilter = _.cloneDeep(state.filter);
            if (action.payload.key === 'sortBy') {
                newFilter.sortBy = action.payload.value.active;
                newFilter.direction = action.payload.value.direction;
            } else {
                if (Array.isArray(state.filter[action.payload.key])) {
                    newFilter[action.payload.key] = Object.assign([], action.payload.value);
                } else {
                    newFilter[action.payload.key] = action.payload.value;
                }
            }
            return {
                ...state,
                filter: {...newFilter}
            };
        }
        default:
            return state;
    }
}
