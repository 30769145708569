export enum SubsystemScope {
    AQVD,
    PLI,
    BITR,
    CITR,
    EX,
    Redline,
    COW,
    THS,
    ChangeDocument,
    BITRandCITR,
    ConstraintDetails,
    RFSUWorkToGoSearch,
    RFOWorkToGoSearch
}

export enum Zone {
    Red,
    Blue,
    ChangeManagement,
    RFO,
    RedNoDiscipline,
    BlueNoDiscipline,
    Redline,
    CriticalRfi,
    LTP,
    ResponsiblePerson
}

export enum UploadType {
    Tags,
}

export enum ChangeType {
    DCN,
    RFI,
    NPW,
    NCR,
    PCN,
    CallOff,
    TQ,
    SCCallOff,
    NPWBluezone,
    NPWRedzone,
    MACDCN,
    ContractorNCR,
    TMOC,
    Surveillance,
    RFIIPIMS,
    SID,
}

export enum ExceptionScope {
    ITR,
    PLI,
    All,
    Redline,
    ChangeDocument
}
export enum UserDefinedInputs {
    ResponsiblePerson,
    AdditionalResponsiblePerson
}

export enum TCORole {
    SCManager,
    MCEngineer,
    SystemOwner,
    ACM,
}

export enum WeekType {
    Past,
    Upcoming,
}

export enum ExceptionType {
    MC,
    RFSU,
    RFO,
}

export enum PhaseType {
    MC,
    RFSU,
    RFO,
    Walkdown,
    DAC,
    Constr90Walkdown,
}

export enum ForecastHistoryDateType {
    MCForecast,
    RFSUForecast,
    MCWalkdownForecast,
    RFSUWalkdownForecast,
    Construction90Walkdown,
    RFOForeCastDate,
    PLIForecastClosureDate,
    PostRFOExpectedClosureDate,
    ExceptionForecastClosureDate,
}

export enum WeeklyPlanType {
    weekly,
    monthly,
}

export enum WeeklyPlanActivityType {
    inWeek,
    notInWeek,
}

export function GetTCORoleName(tcoRole: TCORole) {
    switch (tcoRole) {
        case TCORole.SCManager: {
            return 'SC Manager';
        }
        case TCORole.MCEngineer: {
            return 'MC Engineer';
        }
        case TCORole.SystemOwner: {
            return 'System Owner';
        }
        case TCORole.ACM: {
            return 'ACM';
        }
    }
}

export function mapStringToChangeTypeEnum(toMap: string) {
    let type = ChangeType[toMap];

    if (!type) {
        if (toMap === 'Call-Off') {
            type = ChangeType.CallOff;
        } else if (toMap === 'SC Call-Off') {
            type = ChangeType.SCCallOff;
        }
    }

    return type;
}

export enum DcnRfiSignOffFieldImplementationMode {
    DisabledForRLMUToGo = 'DisabledForRLMUToGo',
    EnabledForAll = 'EnabledForAll',
}
