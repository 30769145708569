import { Component, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { forkJoin } from 'rxjs';
import { take, map, takeWhile } from 'rxjs/operators';
import { Contractor, TCOACManager, TCOUser, Waypoint, Milestone, RFO, Gooc, StagedStartUpPriority } from 'src/app/store/common.model';
import { FormService } from 'src/app/services/shared/form.service';
import { WeeklyPlanningFilter } from 'src/app/store/weekly-planning/model';
import { UntypedFormGroup } from '@angular/forms';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import {
    WeeklyPlanningCommitmentsFilterRequest,
    WeeklyPlanningFilterPropertyUpdate,
    WeeklyPlanningFilterRequest,
    WeeklyPlanningFilterReset,
    WeeklyPlanningTopHeaderSectionDataRequest,
} from 'src/app/store/weekly-planning/actions';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-weekly-planning-session-filter',
    templateUrl: './weekly-planning-session-filter.component.html',
    styleUrls: ['./weekly-planning-session-filter.component.scss'],
})
export class WeeklyPlanningSessionFilterComponent extends BaseComponent implements OnInit {
    @Input() projectTeamName: string;
    @Input() applyToWeeklyPlanningCommitments: false;
    @Input() applyToWeeklyTopHeaderData: false;
    @Input() expanded: true;
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    filterForm: UntypedFormGroup;
    contractors: Contractor[] = [];
    acms: TCOACManager[] = [];
    scManagers: TCOUser[] = [];
    systemOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    autocompleteDisplayedColumns = ['name', 'description'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    isUsersPerProjectLoading = false;
    planningTableIndex = [0, 1, 2, 3, 4];
    isRFOWeeklyPlanning = false;
    isRFSUWeeklyPlanning = false;
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setConstructionAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    filter$ = this.store.select((store) => store.weeklyPlanningState.filter);
    planningType$ = this.store.select((store) => store.weeklyPlanningState.planningType);

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new WeeklyPlanningFilter());
    }

    ngOnInit() {
        this.planningType$.pipe(takeWhile(() => this.isAlive)).subscribe((planningType) =>
            setTimeout(() => {
                this.isRFOWeeklyPlanning = planningType === 'rfo';
                this.isRFSUWeeklyPlanning = planningType === 'rfsu';
            })
        );
        this.isUsersPerProjectLoading = true;
        forkJoin([
            this.lookupService.getACMs([this.projectTeamName]),
            this.lookupService.getSCManagers([this.projectTeamName]),
            this.lookupService.getMCEngineers([this.projectTeamName]),
            this.lookupService.getSysOwners([this.projectTeamName]),
        ])
            .pipe(take(1))
            .subscribe(
                ([acms, scManagers, mcEngineers, systemOwners]) => {
                    this.acms = acms;
                    this.scManagers = scManagers;
                    this.mcEngineers = mcEngineers;
                    this.systemOwners = systemOwners;
                },
                () => { },
                () => (this.isUsersPerProjectLoading = false)
            );

        this.filterExpansionPanel.expanded = this.expanded;

        this.filter$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((filter) => this.filterForm.patchValue(filter, { emitEvent: false }));

        this.watchFormChanges();
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, [this.projectTeamName])
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, [this.projectTeamName])
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, [this.projectTeamName]).pipe(
            map((waypoints: Waypoint[]) => {
                return waypoints;
            })
        );
    };

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMilestones(search, take, page, this.filterForm.value.waypoints, [this.projectTeamName])
            .pipe(map((milestones: Milestone[]) => milestones));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, [this.projectTeamName])
            .pipe(map((RFOs: RFO[]) => RFOs));
    };

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    onStagedStartUpPrioritiesClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    displayMultipleSelected(values: (Milestone | Waypoint | Contractor)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayMultipleIdSelected(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getConstructionArea = (search = '', take = 30, page = 0) =>
        this.lookupService.searchConstructionArea(search, take, page, this.filterForm.value.projectTeamNames);

    getAreaBreakdown = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((prio: StagedStartUpPriority[]) => prio));
    };

    displaySelectedACM(acmIds: number[]) {
        return this.acms
            .filter((acm) => acmIds.includes(acm.id))
            .map((acm) => acm.name)
            .join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.systemOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displayMultipleFilterSelected(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    resetFilters() {
        this.store.dispatch(new WeeklyPlanningFilterReset());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setConstructionAreaInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.stagedStartUpPriorities.setValue([]);
    }

    search() {
        this.store.dispatch(new WeeklyPlanningFilterRequest(this.planningTableIndex));

        if (this.applyToWeeklyPlanningCommitments) {
            this.store.dispatch(new WeeklyPlanningCommitmentsFilterRequest(this.projectTeamName));
        }

        if (this.applyToWeeklyTopHeaderData) {
            this.store.dispatch(new WeeklyPlanningTopHeaderSectionDataRequest({ projectTeamName: this.projectTeamName, filter: null }))
        }
    }

    private watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new WeeklyPlanningFilterPropertyUpdate({ key, value }));
            });
        }
    }
}
