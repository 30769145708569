
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    CITRPartialProgressActionTypes,
    CITRPartialProgressUploadLogSuccess,
    CITRPartialProgressUploadLogError,
    CITRPartialProgressValidateButtonStateSuccess,
    CITRPartialProgressValidateButtonStateError,
    CITRPartialProgressDownloadOutputDataSuccess,
    CITRPartialProgressDownloadOutputDataError,
    CITRPartialProgressDownloadOutputDataRequest,
    CITRPartialProgressTemplateFileSuccess,
    CITRPartialProgressTemplateFileRequest,
    CITRPartialProgressTemplateFileError,
    CITRPartialProgressClearInProgressSpinner,
    CITRPartialProgressValidateDeleteSuccess,
    CITRPartialProgressValidateDeleteError,
    CITRPartialProgressPatchAllDeleteRequest,
    CITRPartialProgressPatchAllDeleteSuccess,
    CITRPartialProgressPatchAllDeleteError,
    CITRPartialProgressPatchDeleteRequest,
    CITRPartialProgressPatchDeleteSuccess,
    CITRPartialProgressPatchDeleteError,
    CITRPartialProgressDeleteSuccess,
    CITRPartialProgressDeleteError,
    CITRPartialProgressDeleteRequest,
    CITRPartialProgressSetStatusAndStartDate,
} from './actions';

@Injectable()
export class CITRPartialProgressEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<CITRPartialProgressTemplateFileRequest>(CITRPartialProgressActionTypes.CITRPartialProgressTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new CITRPartialProgressTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading CITR Partial Progress Input structure file. Please contact Program Administrator'
                    );
                    return of(new CITRPartialProgressTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<CITRPartialProgressTemplateFileSuccess>(CITRPartialProgressActionTypes.CITRPartialProgressTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'CITR Partial Progress Input File.xlsx');
        })
    );

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(CITRPartialProgressActionTypes.CITRPartialProgressUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['CITRPartialProgress']).pipe(
                map((uploadLogData) => new CITRPartialProgressUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new CITRPartialProgressUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<CITRPartialProgressUploadLogSuccess>(CITRPartialProgressActionTypes.CITRPartialProgressUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.citrPartialProgressState.uploadLogStatus),
            this.store.select((store) => store.citrPartialProgressState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new CITRPartialProgressSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    invokeDeleteRequest$ = this.actions$.pipe(
        ofType(CITRPartialProgressActionTypes.CITRPartialProgressSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.citrPartialProgressState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        map(() => new CITRPartialProgressDeleteRequest())
    );

    @Effect()
    getDelete$ = this.actions$.pipe(
        ofType(CITRPartialProgressActionTypes.CITRPartialProgressDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.citrPartialProgressState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getCITRPartialProgressDeleteRecords(deleteFilter).pipe(
                map((deleteData: any) => new CITRPartialProgressDeleteSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete data records. Please contact Program Administrator'
                    );
                    return of(new CITRPartialProgressDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteData$ = this.actions$.pipe(
        ofType<CITRPartialProgressPatchDeleteRequest>(CITRPartialProgressActionTypes.CITRPartialProgressPatchDeleteRecordRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchCITRPartialProgressDeleteRecord(
                action.payload.barcode, action.payload.deleteState
                ).pipe(
                map(() => new CITRPartialProgressPatchDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new CITRPartialProgressPatchDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchAllDelete$ = this.actions$.pipe(
        ofType<CITRPartialProgressPatchAllDeleteRequest>(CITRPartialProgressActionTypes.CITRPartialProgressPatchAllDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.citrPartialProgressState.deleteFilter)),
        mergeMap(([action, deleteFilter]) =>
            this.manualUploadsService.patchAllCITRPartialProgressBarcodeDeleteRecords(deleteFilter, action.payload.deleteState).pipe(
                map(() => new CITRPartialProgressPatchAllDeleteSuccess({ deleteState: action.payload.deleteState })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new CITRPartialProgressPatchAllDeleteError(error));
                })
            )
        )
    );

    @Effect()
    validateDelete$ = this.actions$.pipe(
        ofType(CITRPartialProgressActionTypes.CITRPartialProgressValidateDataRequest),
        mergeMap(() =>
            this.manualUploadsService.validateCITRPartialProgressData().pipe(
                map(() => new CITRPartialProgressValidateDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new CITRPartialProgressValidateDeleteError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(CITRPartialProgressActionTypes.CITRPartialProgressDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.citrPartialProgressState.uploadLogData)),
        filter(([, uploadLogs]) => {
            let logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new CITRPartialProgressClearInProgressSpinner())
    );



    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<CITRPartialProgressDownloadOutputDataRequest>(CITRPartialProgressActionTypes.CITRPartialProgressDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new CITRPartialProgressDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new CITRPartialProgressDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<CITRPartialProgressDownloadOutputDataSuccess>(CITRPartialProgressActionTypes.CITRPartialProgressDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(CITRPartialProgressActionTypes.CITRPartialProgressDeleteRecordsSuccess,
            CITRPartialProgressActionTypes.CITRPartialProgressPatchAllDeleteRecordsSuccess,
            CITRPartialProgressActionTypes.CITRPartialProgressPatchDeleteRecordSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('CITRPartialProgress').pipe(
                map((response: any) => new CITRPartialProgressValidateButtonStateSuccess(response)),
                catchError((error) => of(new CITRPartialProgressValidateButtonStateError(error)))
            )
        )
    );
}

