<div class="chart-container">
    <ngx-charts-bar-vertical-2d
        [scheme]="colorScheme"
        [results]="chartData$ | async"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [roundDomains]="true"
        [yAxisTickFormatting]="axisFormat"
        [roundEdges]="false"
        [barPadding]="1"
        [showDataLabel]="true"
    >
    </ngx-charts-bar-vertical-2d>
    <div class="legend-container">
        <div class="legend-item plan">Plan</div>
        <div class="legend-item actual">Actual</div>
        <div class="legend-item forecast">Forecast</div>
    </div>
</div>
