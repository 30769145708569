import {
    SkylineFilter,
    SKYLINE_NUM_OF_PAST_WEEKS,
    SKYLINE_NUM_OF_PAST_WEEKS_WIDE,
    SKYLINE_NUM_OF_UPCOMING_WEEKS_LOW,
    SkylineLevelOfDetails,
    SkylineState,
    SKYLINE_NUM_PRINT_WEEKS,
    SkylinePart,
    SKYLINE_NUM_OF_UPCOMING_WEEKS_MEDIUM_HIGH,
    SKYLINE_NUM_OF_UPCOMING_WEEKS_WIDE,
    SkylineSuccessPayload,
    SkylineDTO,
    SkylineRFODTO,
} from './model';
import { SkylineActions, SkylineActionTypes } from './actions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { initWeekRange, getCurrentWeekStartDate } from 'src/app/extensions';

const initialState: SkylineState = {
    skylinePhase: '',
    skylineType: '',
    filter: new SkylineFilter(),
    levelOfDetails: SkylineLevelOfDetails.Low,
    weeks: initWeekRange(
        getCurrentWeekStartDate().add(-SKYLINE_NUM_OF_PAST_WEEKS, 'weeks'),
        SKYLINE_NUM_OF_PAST_WEEKS + SKYLINE_NUM_OF_UPCOMING_WEEKS_LOW
    ),
    isLoading: false,
    printIsLoading: false,
    subsystemsByWeek: [],
    rfosByWeek: [],
    openAqvdsPerWeek: [],
    openBitrsPerWeek: [],
    openCitrsPerWeek: [],
    skylineParts: [new SkylinePart()],
    isExcelGenerating: false,
    flagToggles: {
        showWalkdownCommitted: false,
        showMcIncomplete: false,
        showOpsAppPending: false,
        showRfsuEdossierInitiated: false,
        showNonCommissionable: false
    },
};

export function reducer(state = initialState, action: SkylineActions): SkylineState {
    switch (action.type) {
        case SkylineActionTypes.SkylineSetPhaseAndType:
            return {
                ...state,
                skylinePhase: action.payload.phase,
                skylineType: action.payload.type,
            };
        case SkylineActionTypes.SkylineFilterUpdate: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
            };
        }
        case SkylineActionTypes.SkylineFilterPropertyUpdate: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                        ? [...action.payload.value]
                        : action.payload.value,
                },
            };
        }
        case SkylineActionTypes.SkylineFilterReset: {
            return {
                ...state,
                filter: new SkylineFilter(),
            };
        }
        case SkylineActionTypes.SkylineRequest: {
            if (action.payload.printMode) {
                return {
                    ...state,
                    printIsLoading: true,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }
        case SkylineActionTypes.SkylineSuccess: {
            if (action.payload.printMode) {
                const { parts } = calculateSkylineParts(state, action.payload);
                return {
                    ...state,
                    printIsLoading: false,
                    skylineParts: parts,
                };
            } else {
                const {
                    subsystemsByWeek,
                    rfosByWeek,
                    openAqvdsPerWeek,
                    openBitrsPerWeek,
                    openCitrsPerWeek,
                } = calculateSkylineParts(state, action.payload);
                return {
                    ...state,
                    isLoading: false,
                    subsystemsByWeek,
                    rfosByWeek,
                    openAqvdsPerWeek,
                    openBitrsPerWeek,
                    openCitrsPerWeek,
                };
            }
        }
        case SkylineActionTypes.SkylineError: {
            return {
                ...state,
                isLoading: false,
                printIsLoading: false,
                subsystemsByWeek: [],
                rfosByWeek: [],
            };
        }
        case SkylineActionTypes.SkylineSetLevelOfDetails: {
            const weeks = calculateSkylineSetLevelOfDetails(action.payload);
            return {
                ...state,
                levelOfDetails: action.payload,
                weeks,
            };
        }
        case SkylineActionTypes.SkylineSetWeekRange: {
            return {
                ...state,
                weeks: initWeekRange(
                    action.payload,
                    (state.levelOfDetails !== SkylineLevelOfDetails.Wide
                        ? SKYLINE_NUM_OF_PAST_WEEKS
                        : SKYLINE_NUM_OF_PAST_WEEKS_WIDE) +
                        (state.levelOfDetails === SkylineLevelOfDetails.Low
                            ? SKYLINE_NUM_OF_UPCOMING_WEEKS_LOW
                            : state.levelOfDetails === SkylineLevelOfDetails.Medium ||
                              state.levelOfDetails === SkylineLevelOfDetails.High
                            ? SKYLINE_NUM_OF_UPCOMING_WEEKS_MEDIUM_HIGH
                            : SKYLINE_NUM_OF_UPCOMING_WEEKS_WIDE)
                ),
            };
        }
        case SkylineActionTypes.SkylinePrintReset: {
            return {
                ...state,
                skylineParts: [],
            };
        }
        case SkylineActionTypes.SkylineGetExcelRequest: {
            return {
                ...state,
                isExcelGenerating: true,
            };
        }
        case SkylineActionTypes.SkylineGetExcelRequestSuccess: {
            return {
                ...state,
                isExcelGenerating: false,
            };
        }
        case SkylineActionTypes.SkylineGetExcelRequestError: {
            return {
                ...state,
                isExcelGenerating: false,
            };
        }
        case SkylineActionTypes.SkylineFlagTogglesUpdate: {
            return {
                ...state,
                flagToggles: {
                    ...state.flagToggles,
                    [action.payload.propertyName]: action.payload.value,
                },
            };
        }

        default:
            return state;
    }
}

const calculateSkylineParts = (state: SkylineState, payload: SkylineSuccessPayload) => {
    let weeks = state.weeks;
    let data = payload.data;

    if (payload.printMode) {
        let numberOfWeeks = data.length;

        while (numberOfWeeks % SKYLINE_NUM_PRINT_WEEKS !== 0) {
            numberOfWeeks++;
        }

        weeks = initWeekRange(moment(_.head(data).weekStartDate), numberOfWeeks).map((x) => ({
            date: x.date,
            weekType: x.weekType,
        }));
    }

    let openAqvdsPerWeek = [];
    let openBitrsPerWeek = [];
    let openCitrsPerWeek = [];
    let parts: SkylinePart[] = [];
    let subsystemsByWeek: SkylineDTO[][] = [];
    let rfosByWeek: SkylineRFODTO[][] = [];

    data.map((week) => {
        if (state.skylinePhase === 'mc' || state.skylinePhase === 'mcwalkdown') {
            openAqvdsPerWeek.push({ week: week.weekStartDate, count: _.sumBy(week.subsystems, (x) => x.aqvd) });
        } else if (state.skylinePhase === 'rfsu' || state.skylinePhase === 'rfsuwalkdown' || state.skylinePhase === 'rfo') {
            openBitrsPerWeek.push({ week: week.weekStartDate, count: _.sumBy(week.subsystems, (x) => x.bitr) });
            openCitrsPerWeek.push({ week: week.weekStartDate, count: _.sumBy(week.subsystems, (x) => x.citr) });
        }
    });

    if (payload.printMode) {
        for (let i = 0; i < weeks.length / SKYLINE_NUM_PRINT_WEEKS; i++) {
            let part = new SkylinePart();
            part.subsystemsByWeek = _(data.map((week) => week.subsystems))
                .drop(i * SKYLINE_NUM_PRINT_WEEKS)
                .take(SKYLINE_NUM_PRINT_WEEKS)
                .value();
            part.rfosByWeek = _(data.map((week) => week.rfos))
                .drop(i * SKYLINE_NUM_PRINT_WEEKS)
                .take(SKYLINE_NUM_PRINT_WEEKS)
                .value();

            if (state.skylinePhase === 'mc') {
                part.openAqvdsPerWeek = _(openAqvdsPerWeek)
                    .drop(i * SKYLINE_NUM_PRINT_WEEKS)
                    .take(SKYLINE_NUM_PRINT_WEEKS)
                    .value();
            } else if (state.skylinePhase === 'rfsu') {
                part.openBitrsPerWeek = _(openBitrsPerWeek)
                    .drop(i * SKYLINE_NUM_PRINT_WEEKS)
                    .take(SKYLINE_NUM_PRINT_WEEKS)
                    .value();
                part.openCitrsPerWeek = _(openCitrsPerWeek)
                    .drop(i * SKYLINE_NUM_PRINT_WEEKS)
                    .take(SKYLINE_NUM_PRINT_WEEKS)
                    .value();
            }

            part.weeks = _(weeks)
                .drop(i * SKYLINE_NUM_PRINT_WEEKS)
                .take(SKYLINE_NUM_PRINT_WEEKS)
                .value();
            parts.push(part);
        }
    } else {
        subsystemsByWeek = data.map((week) => week.subsystems);
        rfosByWeek = data.map((week) => week.rfos);
    }

    return {
        parts,
        subsystemsByWeek,
        rfosByWeek,
        openAqvdsPerWeek,
        openBitrsPerWeek,
        openCitrsPerWeek,
    };
};

const calculateSkylineSetLevelOfDetails = (payload: SkylineLevelOfDetails) => {
    const startDate =
        payload !== SkylineLevelOfDetails.Wide
            ? getCurrentWeekStartDate().add(-SKYLINE_NUM_OF_PAST_WEEKS, 'weeks')
            : getCurrentWeekStartDate().add(-SKYLINE_NUM_OF_PAST_WEEKS_WIDE, 'weeks');
    const weeks =
        payload === SkylineLevelOfDetails.Low
            ? initWeekRange(startDate, SKYLINE_NUM_OF_PAST_WEEKS + SKYLINE_NUM_OF_UPCOMING_WEEKS_LOW)
            : payload === SkylineLevelOfDetails.Medium || payload === SkylineLevelOfDetails.High
            ? initWeekRange(startDate, SKYLINE_NUM_OF_PAST_WEEKS + SKYLINE_NUM_OF_UPCOMING_WEEKS_MEDIUM_HIGH)
            : initWeekRange(startDate, SKYLINE_NUM_OF_PAST_WEEKS_WIDE + SKYLINE_NUM_OF_UPCOMING_WEEKS_WIDE);
    return weeks;
};
