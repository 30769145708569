<main class="main">
    <app-loading-indicator *ngIf="isLoading$ | async"></app-loading-indicator>
    <a class="navigator" [routerLink]="" (click)="goBack()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>

    <div class="title-container">
        <div class="title">PLI week plan</div>
        <div class="button-container">
            <button class="sct-button sct-button-light margin-right20" (click)="exportToExcel()">Export</button>
            <button class="sct-button sct-button-light margin-right20" (click)="saveWeekPlan()" [disabled]="!isFutureWeek">Save week plan</button>
        </div>
    </div>
    <div>
        <app-pli-planning-filter></app-pli-planning-filter>
        <app-pli-planning-table></app-pli-planning-table>
    </div>
</main>
