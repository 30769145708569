import { createAction, props } from '@ngrx/store';
import { PipelineLog } from 'src/app/models/pipeline-log';

export const pipelineLogRequest = createAction('[Pipeline] Upload Log Request');
export const pipelineLogSuccess = createAction(
    '[Pipeline] Upload Log Success', props<{ response: PipelineLog[] }>()
    );
export const pipelineLogError = createAction(
    '[Pipeline] Upload Log Error', props<{ error: string }>()
    );
export const pipelineClearInProgressSpinner = createAction(
    '[Pipeline] Clear In Progress Spinner'
    );
