<mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    #expansionPanel="matExpansionPanel"
>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h3>{{ activeLink }} Icicle Chart</h3>
        </mat-panel-title>
        <mat-panel-description *ngIf="!panelOpenState">Expand panel to view details.</mat-panel-description>
    </mat-expansion-panel-header>
    <mat-form-field appearance="outline" class="options-container">
        <input matInput [formControl]="dateRangePickerControlFormatted" />
        <input
            matInput
            [matDatepicker]="dateRangePicker"
            [matDatepickerFilter]="dateRangePickerFilter"
            [formControl]="dateRangePickerControl"
            hidden
        />
        <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
        <mat-datepicker #dateRangePicker disabled="false"></mat-datepicker>
    </mat-form-field>
    <div *ngIf="!isLoading" class="chart-container">
        <app-icicle-chart
            [results]="results"
            [xAxis]="true"
            [yAxis]="true"
            [autoScale]="true"
            [animations]="true"
            [tooltipDisabled]="false"
            [gradient]="false"
            [legend]="displayLegend"
            [view]="view"
            [xAxisTicks]="xAxisTicks"
            [trimXAxisTicks]="false"
            [xAxisTickFormatting]="formatToDateString"
            [yAxisTicks]="yAxisTicks"
            [trimYAxisTicks]="false"
            [yAxisTickFormatting]="formatToDateString"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="'Update Week'"
            [xScaleMin]="xScaleMin"
            [xScaleMax]="xScaleMax"
        ></app-icicle-chart>
    </div>

    <div *ngIf="isLoading" class="delta-loader2">
        <div class="lds-hourglass"></div>
    </div>
</mat-expansion-panel>
