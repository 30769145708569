<main class="main">
    <a
        class="navigator"
        *ngIf="isNextWorkingWeek"
        [routerLink]="[getGoBackRouterLink()]"
        [queryParams]="{ withScroll: true }"
        style="margin-bottom: -28px;"
    >
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back to {{ getGoBackTitle() }}
    </a>
    <form [formGroup]="filterForm">
        <div class="title-container">
            <div class="title">
                <span
                    >{{ getTitle() }}: {{ weekStart.toDate() | date: 'd/MMM/yy' }} to
                    {{ weekEnd.toDate() | date: 'd/MMM/yy' }}</span
                >
            </div>
            <div *ngIf="showExportButton" class="buttons-container flex">
                <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light">
                    export
                </button>
            </div>
        </div>
        <div class="results-container">
            <div *ngIf="saveWeeklyPlan" class="buttons-container">
                <button
                    type="button"
                    class="sct-button sct-button-light margin-right20"
                    (click)="saveWeeklyPlanSelectedITRIds()"
                    hidden
                >
                    save weekly plan ({{ weeklyPlanSelection?.selected.length || 0 }})
                </button>
            </div>
            <div class="results-title">
                <div class="table-checkboxes">
                    <mat-checkbox [checked]="showColumns.showSubsysName" (change)="reverseValue('showSubsysName')"
                        >Subsystem Name</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumns.showActivityName" (change)="reverseValue('showActivityName')"
                        >Activity Name</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumns.showBarcode" (change)="reverseValue('showBarcode')"
                        >Barcode</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumns.showManHour" (change)="reverseValue('showManHour')">
                        Man Hours
                    </mat-checkbox>
                    
                    <mat-checkbox [checked]="showColumns.showStatusDate" (change)="reverseValue('showStatusDate')"
                        >Status Date</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumns.showTagType" (change)="reverseValue('showTagType')"
                        >Tag Type</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumns.showDiscipline" (change)="reverseValue('showDiscipline')">
                        Discipline</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumns.showQVD" (change)="reverseValue('showQVD')">
                        Description</mat-checkbox
                    >

                    <mat-checkbox [checked]="showColumns.showPreCommDates" (change)="reverseValue('showPreCommDates')">
                        Activity Dates</mat-checkbox
                    >

                    <mat-checkbox [checked]="showColumns.showDocType" (change)="reverseValue('showDocType')">
                        Doc Type
                    </mat-checkbox>


                    <mat-checkbox
                        *ngIf="additionToPlan"
                        [checked]="showColumns.showAdditionToPlan"
                        (change)="reverseValue('showAdditionToPlan')"
                        >Addition To Plan</mat-checkbox
                    >
                    <mat-checkbox
                        *ngIf="this.scope == 0"
                        [checked]="showColumns.showConstraintFlag"
                        (change)="reverseValue('showConstraintFlag')"
                        >Constraint Flag</mat-checkbox
                    >

                    <mat-checkbox [checked]="showColumns.showWorkArea" (change)="reverseValue('showWorkArea')">
                        Work Area
                    </mat-checkbox>

                    <mat-checkbox [checked]="showColumns.showStagedStartUpPriority" (change)="reverseValue('showStagedStartUpPriority')">
                        Staged Start-up Priority
                    </mat-checkbox>

                </div>
            </div>
            <div class="scroll" app-double-scroll>
                <div class="table-container">
                    <table
                        mat-table
                        matSort
                        [dataSource]="data"
                        [matSortActive]="sortBy"
                        matSortDisableClear
                        [matSortDirection]="direction"
                        [ngClass]="{ ITR: true }"
                    >
                        <ng-container matColumnDef="addConstraint">
                            <th
                                app-col-resize
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                            >
                                <div class="sort-container center">

                                    <span class="hover-pointer">Add Constraint</span>                                   
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                            >
                            <a  class="" (click)="
                            openStatusDetailsPage(element, $event)
                            ">
                              Add Constraint
                        </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="subsystem">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showSubsys
                                }"
                            >
                                <div class="sort-container">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'subsystem',
                                                getSubsystems,
                                                'columnITRSubsystems',
                                                'Subsystem',
                                                columnITRSubsystems,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRSubsystems)
                                        }"
                                        >Subsystem</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showSubsys }">
                                {{ element.subsystem }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="subsystemName">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                class="hover-pointer"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'subsystemName',
                                        getSubsystemNames,
                                        'columnITRSubsystemNames',
                                        'Subsystem Name',
                                        columnITRSubsystemNames,
                                        true
                                    )
                                "
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnITRSubsystemNames),
                                    hidecells: !showColumns.showSubsysName
                                }"
                            >
                                <div class="sort-container">
                                    <span>Subsystem Name</span>
                                    <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showSubsysName }"
                            >
                                {{ element.subsystemName }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="tagNumber">
                            <th mat-header-cell disableClear *matHeaderCellDef>
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'tagNumber',
                                                getTagNumbers,
                                                'columnITRTagNumbers',
                                                'Tag Name',
                                                columnITRTagNumbers
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRTagNumbers)
                                        }"
                                        >Tag Number</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'tagNumber'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.tagNumber }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="activityId">
                            <th mat-header-cell disableClear *matHeaderCellDef>
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'activityId',
                                                getActivityIds,
                                                'columnITRActivityIds',
                                                'Activity ID',
                                                columnITRActivityIds
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRActivityIds)
                                        }"
                                        >Activity ID</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'activityId'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.activityId }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="activityName">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                class="hover-pointer"
                                (click)="
                                    openHeaderCheckListFilter(
                                        'activityName',
                                        getActivityNames,
                                        'columnITRActivityNames',
                                        'Activity Name',
                                        columnITRActivityNames,
                                        true
                                    )
                                "
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnITRActivityNames),
                                    hidecells: !showColumns.showActivityName
                                }"
                            >
                                <div class="sort-container">
                                    <span>Activity Name</span>
                                    <div class="sort-icon" *ngIf="sortBy === 'activityName'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showActivityName }"
                            >
                                {{ element.activityName }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="weeklyPlanCheckbox">
                            <th mat-header-cell disableClear *matHeaderCellDef>
                                <mat-checkbox
                                    (change)="$event ? weeklyPlanMasterToggle() : null"
                                    [checked]="weeklyPlanSelection?.hasValue() && isWeeklyPlanAllSelected()"
                                    [indeterminate]="weeklyPlanSelection?.hasValue() && !isWeeklyPlanAllSelected()"
                                ></mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox
                                    (click)="$event.stopPropagation()"
                                    (change)="$event ? weeklyPlanSelection?.toggle(element.id) : null"
                                    [checked]="weeklyPlanSelection?.isSelected(element.id)"
                                    [disabled]="isWeeklyPlanITRDisabled(element.id)"
                                ></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="tagType">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showTagType
                                }"
                                [ngStyle]="showHideColumns(449)"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'tagType',
                                                getTagTypes,
                                                'columnITRTagTypes',
                                                'Tag Type',
                                                columnITRTagTypes,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRTagTypes)
                                        }"
                                        >Tag Type</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'tagType'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showTagType }"
                                [ngStyle]="showHideColumns(449)"
                            >
                                {{ element.tagType }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="discipline">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showDiscipline
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'discipline',
                                                getDisciplines,
                                                'columnITRDisciplines',
                                                'Discipline',
                                                columnITRDisciplines,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRDisciplines)
                                        }"
                                        >Discipline</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showDiscipline }"
                            >
                                {{ element.discipline }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="isItrInCurrentWeek">
                            <th mat-header-cell disableClear *matHeaderCellDef>
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'isItrInCurrentWeek',
                                                getItrInCurrentWeek,
                                                'columnIsItrInCurrentWeek',
                                                'ITR in Current Week?',
                                                columnIsItrInCurrentWeek,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(
                                                columnIsItrInCurrentWeek
                                            )
                                        }"
                                        >ITR in Current Week?</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'isItrInCurrentWeek'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ displayAdditionToPlan(element.isItrInCurrentWeek) }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="number">
                            <th mat-header-cell disableClear *matHeaderCellDef>
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'qvdNumber',
                                                getITRNumbers,
                                                'columnITRNumbers',
                                                'Number',
                                                columnITRNumbers,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRNumbers)
                                        }"
                                        >ITR Number</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'qvdNumber'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="showHideColumns(482)">
                                <span>{{ element.qvdNumber }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="additionToPlan">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showAdditionToPlan
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'additionToPlan',
                                                getITRAdditionToPlan,
                                                'columnITRAdditionToPlan',
                                                'Addition To Plan',
                                                columnITRAdditionToPlan
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRAdditionToPlan)
                                        }"
                                        >Addition To Plan</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'additionToPlan'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showAdditionToPlan }"
                            >
                                {{ displayAdditionToPlan(element.additionToPlan) }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="description">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showQVD
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'qvdDescription',
                                                getITRDescriptions,
                                                'columnITRDescriptions',
                                                'ITR Descriptions',
                                                columnITRDescriptions,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRDescriptions)
                                        }"
                                        >ITR Description</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'qvdDescription'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showQVD }">
                                {{ element.qvdDescription }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="constraintFlag">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showConstraintFlag
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'constraintFlag',
                                                getConstraintFlags,
                                                'columnITRConstraintFlags',
                                                'Constraint Flag',
                                                columnITRConstraintFlags,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(
                                                columnITRConstraintFlags
                                            )
                                        }"
                                        >Constraint Flag</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'constraintFlag'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showConstraintFlag }"
                            >
                                {{ element.constraintFlag }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="preCommStart">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showPreCommDates
                                }"
                            >
                                <div class="sort-container">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'columnITRPreCommStart',
                                                'Pre-Comm Start',
                                                columnITRPreCommStart
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCalendarColumn(columnITRPreCommStart)
                                        }"
                                        >Activity Start</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'preCommStart'">
                                        <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showPreCommDates }"
                            >
                                {{ element.preCommStart | date: 'd/MMM/yy' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="preCommFinish">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showPreCommDates
                                }"
                            >
                                <div class="sort-container">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'columnITRPreCommFinish',
                                                'Pre-Comm Finish',
                                                columnITRPreCommFinish
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCalendarColumn(columnITRPreCommFinish)
                                        }"
                                        >Activity Finish</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'preCommFinish'">
                                        <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showPreCommDates }"
                            >
                                {{ element.preCommFinish | date: 'd/MMM/yy' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="barcode">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showBarcode
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'barcode',
                                                getBarcodes,
                                                'columnITRBarcodes',
                                                'Barcode',
                                                columnITRBarcodes
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRBarcodes)
                                        }"
                                        >Barcode</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'barcode'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showBarcode }">
                                {{ element.barcode }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="stagedStartUpPriority">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showStagedStartUpPriority
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnStagedStartUpPriority)
                                        }"
                                        >Staged Start-up Priority</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'stagedStartUpPriority'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showStagedStartUpPriority }">
                                {{ element.stagedStartUpPriority }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="manHour">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showManHour
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnManHour)
                                        }"
                                        >Man Hours</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'manHour'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showManHour }">
                                {{ element.manHour }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="contractor">
                            <th mat-header-cell disableClear *matHeaderCellDef>
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'contractor',
                                                getContractors,
                                                'columnITRContractors',
                                                'Contractor',
                                                columnITRContractors,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRContractors)
                                        }"
                                        >Contractor</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'contractor'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">{{ element.contractor }}</td>
                        </ng-container>
                        <ng-container matColumnDef="docType">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                class="hover-pointer"
                                [ngClass]="{
                                    hidecells: !showColumns.showDocType
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'docType',
                                                getDocTypes,
                                                'columnITRDocTypes',
                                                'Doc Type',
                                                columnITRDocTypes,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRDocTypes)
                                        }"
                                        >Doc Type</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'docType'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{
                                    hidecells: !showColumns.showDocType
                                }"
                            >
                                {{ element.docType }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell disableClear *matHeaderCellDef>
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'status',
                                                getStatuses,
                                                'columnITRStatuses',
                                                'Status',
                                                columnITRStatuses,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRStatuses)
                                        }"
                                        >Status</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'status'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.status }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="itrConstraintTypeName">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showITRConstraintTypeName
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRStatuses)
                                        }"
                                        >ITR Constraint Name</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'status'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" (click)="updateIsConstrained($event)" [ngClass]="{ hidecells: !showColumns.showITRConstraintTypeName }">
                                {{ element.itrConstraintTypeName }}
                             
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="itrConstraintDueDate">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showITRConstraintDueDate
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        >Constraint Forecast Closure Date</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'status'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showITRConstraintDueDate }">
                                {{ element.itrConstraintDueDate | date: 'd/MMM/yy' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="itrConstraintRaisedDate">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showITRConstraintRaisedDate
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        >ITR Constraint Raised Date</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'status'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showITRConstraintRaisedDate }">
                                {{ element.itrConstraintRaisedeDate | date: 'd/MMM/yy' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="itrConstraintRaisedByUser">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showITRConstraintRaisedByUser
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        >ITR Constraint Raised By</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'status'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showITRConstraintRaisedByUser }">
                                {{ element.itrConstraintRaisedByUser }}
                            </td>
                        </ng-container>                        
                        <ng-container matColumnDef="itrConstraintResponsibleUser">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showITRConstraintResponsibleUser
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        >ITR Constraint Responsible</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'status'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showITRConstraintResponsibleUser }">
                                {{ element.itrConstraintResponsibleUser }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="statusDate">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showStatusDate
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'columnITRStatusDate',
                                                'Status Date',
                                                columnITRStatusDate
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCalendarColumn(columnITRStatusDate)
                                        }"
                                        >Status Date</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'statusDate'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showStatusDate }"
                            >
                                {{ element.statusDate | date: 'd/MMM/yy' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="latestComment">
                            <th mat-header-cell disableClear *matHeaderCellDef>
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'comments',
                                                getComments,
                                                'columnITRComments',
                                                'Comment',
                                                columnITRComments,
                                                false,
                                                true,
                                                false,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRComments)
                                        }"
                                        >Comment</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'comments'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" (click)="openCommentPopup(element, $event)">
                                <div *ngIf="element.comments && !isReadOnly; else notShow">
                                    <a
                                        ><span [innerHTML]="element.comments | formatMentions: element.mentions"></span
                                    ></a>
                                </div>
                                <div *ngIf="isReadOnly">
                                    <span [innerHTML]="element.comments | formatMentions: element.mentions"></span>
                                </div>
                                <ng-template #notShow>
                                    <button type="button" *ngIf="!isReadOnly" mat-stroked-button class="add-comment">
                                        Add
                                    </button>
                                </ng-template>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="workAreaName">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showWorkArea
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'workAreaName',
                                                getWorkAreas,
                                                'columnITRWorkAreas',
                                                'Work Area',
                                                columnITRWorkAreas
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRWorkAreas)
                                        }"
                                        >Work Area</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'workAreaName'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showWorkArea }">
                                {{ element.workAreaName }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="constraintType">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                [ngClass]="{
                                    hidecells: !showColumns.showConstraintType
                                }"
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'constraintType',
                                                getConstraintTypes,
                                                'columnConstraintType',
                                                'Constraint Type',
                                                columnITRWorkAreas,
                                                true
                                            )
                                        "
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnConstraintType)
                                        }"
                                        >Activity Constraint</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'constraintType'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showConstraintType }">
                                {{ element.constraintType }}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDetailsPage(row)"></tr>
                    </table>
                </div>
            </div>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 50]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>
