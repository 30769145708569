import { Injectable } from '@angular/core';
import { RundownType, RUNDOWN_TYPE_KEYS, RundownFilter, RundownResponseDTO } from 'src/app/store/reports/rundown/model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class RundownService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly rundownEndpoint: string = '/rundown';

    constructor(private http: HttpClient) {}

    filterRundownData(
        rundownType: RundownType,
        startDate: string,
        currentWeek: string,
        filter: RundownFilter,
        monthlyView: boolean,
        weeklyView: boolean,
        quarteryView: boolean,
    ) {
        let requestFilter = {
            ...filter,
            actionBy: filter.actionBy.map(x => typeof x === 'string' ? x : x['id'] ).filter(x => x != null || x != undefined)
        } as RundownFilter;

        return this.http.post<RundownResponseDTO>(
            `${this.rootUrl}${this.rundownEndpoint}/${RUNDOWN_TYPE_KEYS.get(
                rundownType
            )}?startDate=${startDate}&currentWeek=${currentWeek}&monthlyView=${monthlyView}&weeklyView=${weeklyView}&quarteryView=${quarteryView}`,
            requestFilter
        );
    }

    exportToExcelData(
        rundownType: RundownType,
        startDate: string,
        currentWeek: string,
        filter: RundownFilter,
        monthlyView: boolean,
        weeklyView: boolean,
        quarteryView: boolean,
    ) {
        let requestFilter = {
            ...filter,
            actionBy: filter.actionBy.map(x => typeof x === 'string' ? x : x['id'] ).filter(x => x != null || x != undefined)
        } as RundownFilter;

        return this.http.post(
            `${this.rootUrl}${this.rundownEndpoint}/generateexcel?startDate=${startDate}&currentWeek=${currentWeek}&monthlyView=${monthlyView}&weeklyView=${weeklyView}&quarteryView=${quarteryView}`,
            requestFilter ,
             {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Disposition',
                }),
                responseType: 'blob' as 'json',
            }
        );
    }
}
