import * as moment from 'moment';
import { Contractor, Gooc, OrderDirection, StagedStartUpPriority, TCOUser } from '../../common.model';

export class SubsystemMCDashboardFilter {
    goocs: Gooc[] = [];
    page = 0;
    take = 10;
    timezoneOffset = 0;
    sortBy = 'subsystemNo';
    direction = OrderDirection.Desc;
    contractors: Contractor[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    mcEngineer: TCOUser[] = [];
    scManager: TCOUser[] = [];
    systemOwner: TCOUser[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    scrollSearch: boolean = false;
    showHideColumns = new ShowHideColumns();
}

export class SubsystemMCDashboardPLIColumnSummary {
    apliOpen: number;
    bpliOpen: number;
    cpliOpen: number;
    dpliOpen: number;
}

export class SubsystemMCDashboardColumnSummary {
    total: number;
    complete: number;
    remaining: number;
    exceptions: number;
    percentComplete: string;
}

export class SubsystemMCDashboardDTO {
    subsystemNo: string;
    subsystemName: string;
    mcPlanDate: moment.Moment;
    mcForecast: moment.Moment;
    mcActualDate: moment.Moment;
    aqvd: SubsystemMCDashboardColumnSummary;
    redlines: SubsystemMCDashboardColumnSummary;
    pli: SubsystemMCDashboardPLIColumnSummary;
    npw: SubsystemMCDashboardColumnSummary;
}

export class SubsystemMCDashboard2DTO {
    subsystemNo: string;
    subsystemName: string;
    unallocated: SubsystemMCDashboardColumnSummary;
    hydrotests: SubsystemMCDashboardColumnSummary;
    reinstatements: SubsystemMCDashboardColumnSummary;
    terminations: SubsystemMCDashboardColumnSummary;
    instrument: SubsystemMCDashboardColumnSummary;
    exInsp: SubsystemMCDashboardColumnSummary;
    heatTrace: SubsystemMCDashboardColumnSummary;
    lsp: SubsystemMCDashboardColumnSummary;
}

export interface SubsystemMCDashboardState {
    dashboard1Filter: SubsystemMCDashboardFilter;
    dashboard2Filter: SubsystemMCDashboardFilter;
    dashboard1Items: SubsystemMCDashboardDTO[];
    dashboard2Items: SubsystemMCDashboard2DTO[];
    dashboard1IsLoading: boolean;
    dashboard2IsLoading: boolean;
    dashboard1TotalCount: number;
    dashboard2TotalCount: number;
}

export class ShowHideColumns {
    goocNo = false;
    constructionArea = false;
    mcSequence = false;
}
