import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IValidators } from 'src/app/validators/validators';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    constructor() {
        // This is intentional
    }

    createForm(model: any, validators: IValidators): FormGroup {
        const formDetail = new FormGroup({});
        // tslint:disable-next-line: forin
        for (const fieldName in model) {
            formDetail.addControl(fieldName, new FormControl(model[fieldName], validators.getValidators(fieldName)));
        }
        return formDetail;
    }

    createSimpleForm(model: any): FormGroup {
        const formDetail = new FormGroup({});
        // tslint:disable-next-line: forin
        for (const fieldName in model) {
            formDetail.addControl(fieldName, new FormControl(model[fieldName]));
        }
        return formDetail;
    }
}
