import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, filter } from 'rxjs/operators';
import {
    NameSeriesDataDTO,
    CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS,
} from 'src/app/store/reports/change-validation-dashboard/model';
import {
    ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange,
    ChangeValidationDashboardMonthlyChangeRaisedRequest,
} from 'src/app/store/reports/change-validation-dashboard/actions';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
    selector: 'app-monthly-change-raised',
    templateUrl: './monthly-change-raised.component.html',
    styleUrls: ['./monthly-change-raised.component.scss'],
})
export class MonthlyChangeRaisedComponent extends BaseComponent implements OnInit {
    monthlyChangeRaisedData$ = this.store.select(
        (state) => state.changeValidationDashboardState.monthlyChangeRaised.data
    );
    isLoading$ = this.store.select((state) => state.changeValidationDashboardState.monthlyChangeRaised.isLoading);
    data: NameSeriesDataDTO[] = [];
    currentDate = moment();
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    endDate$ = this.store.select(
        (store) => store.changeValidationDashboardState.monthlyChangeRaised.rangeDateFilter.endDate
    );

    @ViewChild('dateRangePicker') dateRangePicker: MatDatepicker<moment.Moment>;
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = true;
    showXAxisLabel: boolean = false;
    showYAxisLabel: boolean = false;
    colorScheme = {
        domain: ['#32CD32', '#FFFF00', '#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    };

    constructor(private store: Store<ApplicationState>) {
        super();
        this.dateRangePickerControl = new UntypedFormControl();
        this.dateRangePickerControlFormatted = new UntypedFormControl({ value: '', disabled: true });
    }

    ngOnInit() {
        this.monthlyChangeRaisedData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (!data.length) {
                this.store.dispatch(new ChangeValidationDashboardMonthlyChangeRaisedRequest());
            }
            this.data = data;
        });

        this.endDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((endDate) => !!endDate)
            )
            .subscribe((endDate) => {
                this.dateRangePickerControl.setValue(moment(endDate), { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(endDate)));
            });

        this.dateRangePickerControl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((date: moment.Moment) => {
                this.store.dispatch(new ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange(date));
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
            });
    }

    private formatDateRange(endDate: moment.Moment) {
        const startDate = moment(endDate).add(
            -CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS + 1,
            'months'
        );
        return `${startDate.format('MMM YY')} - ${endDate.format('MMM YY')}`;
    }

    axisFormat(val: number) {
        if (val % 1 === 0) {
            return val.toLocaleString();
        } else {
            return '';
        }
    }

    chosenYearHandler(normalizedYear: moment.Moment) {
        const ctrlValue = this.dateRangePickerControl.value;
        const dateChozen = moment(normalizedYear);
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue, { emitEvent: false });
    }

    chosenMonthHandler(normalizedMonth: moment.Moment) {
        const dateChozen = moment(normalizedMonth);
        const ctrlValue = this.dateRangePickerControl.value;
        ctrlValue.month(dateChozen.month());
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue);
        this.dateRangePicker.close();
    }
}
