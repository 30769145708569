import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[app-double-scroll]',
})
export class DoubleScrollDirective implements AfterViewInit {
    constructor(private element: ElementRef) {}

    ngAfterViewInit(): void {
        var scrollbar = document.createElement('div');
        scrollbar.appendChild(document.createElement('div'));
        scrollbar.style.overflow = 'auto';
        scrollbar.style.overflowY = 'hidden';
        (scrollbar.children[0] as HTMLDivElement).style.width = this.element.nativeElement.scrollWidth + 'px';
        (scrollbar.children[0] as HTMLDivElement).style.paddingTop = '1px';
        scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
        scrollbar.onscroll = () => {
            this.element.nativeElement.scrollLeft = scrollbar.scrollLeft;
        };
        this.element.nativeElement.onscroll = () => {
            scrollbar.scrollLeft = this.element.nativeElement.scrollLeft;
        };
        this.element.nativeElement.parentNode.insertBefore(scrollbar, this.element.nativeElement);
    }
}
