import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SkylineService } from 'src/app/services/api/webapi-services/reports/skyline.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    ReportsDashboardActionTypes,
    ReportsDashboardDownloadFishboneError,
    ReportsDashboardDownloadFishboneRequest,
    ReportsDashboardDownloadFishboneSuccess,
} from './actions';

@Injectable()
export class ReportsDashboardEffects {
    constructor(
        private actions$: Actions,
        private skylineService: SkylineService,
        private toastService: ToastService
    ) {}

    @Effect()
    downloadFishbone$ = this.actions$.pipe(
        ofType<ReportsDashboardDownloadFishboneRequest>(
            ReportsDashboardActionTypes.ReportsDashboardDownloadFishboneRequest
        ),
        mergeMap((action) =>
            this.skylineService.downloadFishbone(action.payload).pipe(
                map((file) => new ReportsDashboardDownloadFishboneSuccess({ file, phaseType: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while downloading Fishbone file. Please contact Program Administrator`
                    );
                    return of(new ReportsDashboardDownloadFishboneError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveFishboneFile$ = this.actions$.pipe(
        ofType<ReportsDashboardDownloadFishboneSuccess>(
            ReportsDashboardActionTypes.ReportsDashboardDownloadFishboneSuccess
        ),
        map((action) => {
            const blob = new Blob([action.payload.file], { type: 'application/octet-stream' });
            saveAs(blob, `${action.payload.phaseType.toUpperCase()} Fishbone.pdf`);
        })
    );
}
