import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import * as _ from 'lodash';
import { filter, map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'app-reports-health-cleanliness-chart',
    templateUrl: './reports-health-cleanliness-chart.component.html',
    styleUrls: ['./reports-health-cleanliness-chart.component.scss'],
})
export class ReportsHealthCleanlinessChartComponent {
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = false;
    showXAxisLabel: boolean = false;
    showYAxisLabel: boolean = false;
    colorScheme = {
        domain: ['#32CD32', '#FF0000', '#808080'],
    };

    lineData$ = this.store.pipe(
        filter(
            (store) =>
                store.healthState.cleanliness.weeks.length > 0 &&
                store.healthState.cleanliness.data &&
                store.healthState.cleanliness.data.length > 0
        ),
        map((store) => {
            return [
                {
                    name: `Percentage`,
                    series: _.zipWith(
                        store.healthState.cleanliness.weeks,
                        store.healthState.cleanliness.data,
                        (week, total) => {
                            return {
                                name: `WK${moment(week.date).format('w')}`,
                                value: total.percentage,
                            };
                        }
                    ),
                },
            ];
        })
    );

    chartData$ = this.store.pipe(
        filter(
            (store) =>
                store.healthState.cleanliness.weeks.length > 0 &&
                store.healthState.cleanliness.data &&
                store.healthState.cleanliness.data.length > 0
        ),
        map((store) => {
            return _.zipWith(store.healthState.cleanliness.weeks, store.healthState.cleanliness.data, (week, total) => {
                return {
                    name: `WK${moment(week.date).format('w')}`,
                    series: [
                        {
                            name: 'Clean',
                            value: total.greenCount,
                        },
                        {
                            name: 'Not Clean',
                            value: total.redCount,
                        },
                    ],
                };
            });
        })
    );

    constructor(private store: Store<ApplicationState>) {}

    axisFormat(val: number) {
        if (val % 1 === 0) {
            return val.toLocaleString();
        } else {
            return '';
        }
    }

    yAxisTickPercentage(value) {
        return `${value}%`;
    }
}
