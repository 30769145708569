import { Component, OnInit, EventEmitter, ViewChild, NgZone } from '@angular/core';
import { BaseComponent } from '../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { CriticalRfiFilter, CriticalRfiDTO, ShowHideColumns } from 'src/app/store/critical-rfi/model';
import { Constants } from 'src/app/constants';
import {
    OrderDirection, CheckListColumn,
    CalendarColumn,
    UserDetail, RFO, StagedStartUpPriority, NumericColumn
} from 'src/app/store/common.model';
import { map, takeWhile, take } from 'rxjs/operators';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from 'src/app/store/model';
import { Store, select } from '@ngrx/store';
import {
    CriticalRfiFilterPropertyUpdate,
    CriticalRfiFilterReset,
    CriticalRfiFilterRequest,
    CriticalRfiAddCommentRequest,
    CriticalRfiExportToExcelRequest,
    CriticalRfiAddBulkCommentRequest,
    CriticalRfiDeleteCommentRequest,
    CriticalRfiUpdateSubPriorityRequest,
    CriticalRfiUpdateManualPriorityRequest,
    CriticalRfiAddExpectedClosureDateRequest
} from 'src/app/store/critical-rfi/actions';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from '../../modules/shared/components/comment-popup/comment-popup.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from 'src/app/services/shared/role.service';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { Location } from '@angular/common';
import { HeaderDateFilter, HeaderCheckListFilter, HeaderNumericFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { CommentsHistoryComponent } from '../comments-history/comments-history.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HeaderNumericFilterComponent } from 'src/app/modules/shared/components/filter/header-numeric-filter/header-numeric-filter.component';
import { ChangeDocumentDTO } from 'src/app/store/subsystem-conflicts/model';
import { ManualPriroityHistoryComponent } from './manual-priority-history/manual-priority-history.component';
import { ClearableMatDatepickerComponent } from '../clearable-mat-datepicker/clearable-mat-datepicker.component';
import { EtcHistoryComponent } from './etc-history/etc-history.component';

@Component({
    selector: 'app-critical-rfi',
    templateUrl: './critical-rfi.component.html',
    styleUrls: ['./critical-rfi.component.scss'],
})
export class CriticalRfiComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    changeTypes: string[] = [];
    disciplines: string[] = [];
    designStatuses: string[] = [];
    isLoading = false;
    sortBy: string;
    direction = OrderDirection.Desc;
    lockFilters = false;
    allowCommentsBulkUpdate = false;
    subsystem: string;
    rfo: string;
    rfos: RFO[] = [];
    data: CriticalRfiDTO[] = [];
    todayDate = new Date();
    resultsLength = 0;
    pageSize = 25;
    SubPriorities : string[] = ['','A','B'];
    Manualpriorities:string[] = ['P1','P2','P3','P4','P5'];
    displayedColumns = [
        'id',
        'type',
        'discipline',
        'title',
        'subsystem',       
        'walkdownForecast',
        //'rfsuPlan',  
        'RFOActual' ,
        'rfsuActual',
        'areaBreakDown',
        'rfo',     
        'designStatus',
        'isVendorClarification',
        'vendorName',
        'stepStatus',
        'responseDuration',
        'rlmuToGo',
        //'criticalPath',
        'tiComment',        
        'kpjvComment',
        'expectedTimeOfCompletion',
        'priority',
        'priorityManualOverride',
        'priorityFinal',
        //'subPriority',
        'rfiOriginator',
        'revision',
        'systemOwner',
        'rfsuForecast',
        'rfoForecast',
        'nextToSign' 
    ];
    numericColumns = ['columnRlmuToGo'];
    showHideColumns = new ShowHideColumns();
    subPriority: string;
    priorityManualOverride: string;
    CriticalRfiFilter$ = this.store.select((store) => store.criticalRfiState.filter);
    CriticalRfiLoader$ = this.store.select((store) => store.criticalRfiState.isLoading);
    CriticalRfiDataPagination$ = this.store.select((store) => store.criticalRfiState.dataPagination);
    columnChangeType$ = this.store.select((state) => state.criticalRfiState.filter.columnChangeType);
    columnTitle$ = this.store.select((state) => state.criticalRfiState.filter.columnTitle);
    columnId$ = this.store.select((state) => state.criticalRfiState.filter.columnId);
    columnSubsystem$ = this.store.select((state) => state.criticalRfiState.filter.columnSubsystem);
    columnDiscipline$ = this.store.select((state) => state.criticalRfiState.filter.columnDiscipline);
    columnTiComments$ = this.store.select((state) => state.criticalRfiState.filter.columnTiComments);
    columnKpjvComments$ = this.store.select((state) => state.criticalRfiState.filter.columnKpjvComments);
    columnDesignStatus$ = this.store.select((state) => state.criticalRfiState.filter.columnDesignStatus);
    columnRfo$ = this.store.select((state) => state.criticalRfiState.filter.columnRfo);
    columnStepStatus$ = this.store.select((state) => state.criticalRfiState.filter.columnStepStatus);
    columnPriority$ = this.store.select((state) => state.criticalRfiState.filter.columnPriority);
    columnPriorityManualOverride$ = this.store.select((state) => state.criticalRfiState.filter.columnPriorityManualOverride);
    columnPriorityFinal$ = this.store.select((state) => state.criticalRfiState.filter.columnPriorityFinal);
    columnSubPriority$ = this.store.select((state) => state.criticalRfiState.filter.columnSubPriority);
    columnRFIOriginator$ = this.store.select((state) => state.changeManagementState.filter.columnRFIOriginator);
    columnRevision$ = this.store.select((state) => state.criticalRfiState.filter.columnRevision);
    columnSystemOwner$ = this.store.select((state) => state.criticalRfiState.filter.columnSystemOwner);
    columnAreaBreakDown$ = this.store.select((state) => state.criticalRfiState.filter.columnAreaBreakDown);
    columnRFSUForecast$ = this.store.select((state) => state.criticalRfiState.filter.columnRFSUForecast);
    columnRFSUActual$ = this.store.select((state) => state.criticalRfiState.filter.columnRFSUActual);
    columnRFOActual$ = this.store.select((state) => state.criticalRfiState.filter.columnRFOActual); 
    columnRFOForecast$ = this.store.select((state) => state.criticalRfiState.filter.columnRFOForecast);
    columnWalkdownForecast$ = this.store.select((state) => state.criticalRfiState.filter.columnWalkdownForecast);
    columnRFSUPlan$ = this.store.select((state) => state.criticalRfiState.filter.columnRFSUPlan);
    columnResponseDuration$ = this.store.select((state) => state.criticalRfiState.filter.columnResponseDuration);
    columnisVendorClarification$= this.store.select((state) => state.criticalRfiState.filter.columnisVendorClarification);
    columnVendorName$= this.store.select((state) => state.criticalRfiState.filter.columnVendorName);
    columnCriticalPath$= this.store.select((state) => state.criticalRfiState.filter.columnCriticalPath);
    columnExpectedTimeOfCompletion$= this.store.select((state) => state.criticalRfiState.filter.columnExpectedTimeOfCompletion);
    sortBy$ = this.store.select((state) => state.criticalRfiState.filter.sortBy);
    direction$ = this.store.select((state) => state.criticalRfiState.filter.direction);
    columnRlmuToGo$ = this.store.select((state) => state.criticalRfiState.filter.columnRlmuToGo);
    columnNextToSign$ = this.store.select((state) => state.criticalRfiState.filter.columnNextToSign);
    makeRequest$ = this.store.select((state) => state.criticalRfiState.makeRequest);

    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    isReadOnly = false;
    isNewPage = false;

    columnChangeType: CheckListColumn = null;
    columnTitle: CheckListColumn = null;
    columnId: CheckListColumn = null;
    columnSubsystem: CheckListColumn = null;
    columnFieldImplementationContractor: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnDesignStatus: CheckListColumn = null;
    columnPriority: CheckListColumn = null;
    columnPriorityManualOverride: CheckListColumn = null;
    columnPriorityFinal: CheckListColumn = null;
    columnSubPriority: CheckListColumn = null;
    columnRFIOriginator: CheckListColumn = null;
    columnSystemOwner: CheckListColumn = null;
    columnAreaBreakDown: CheckListColumn = null;
    columnRevision: CheckListColumn = null;
    columnRFSUForecast: CalendarColumn = null;
    columnRFOForecast: CalendarColumn = null;
    columnRFSUActual: CalendarColumn = null;
    columnRFOActual: CalendarColumn = null;
    columnStepStatus: CheckListColumn = null;
    columnTiComments: CheckListColumn = null;
    columnKpjvComments: CheckListColumn = null;
    columnWalkdownForecast: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;
    columnRfo:CheckListColumn = null;
    columnResponseDuration: CheckListColumn = null;
    columnisVendorClarification: CheckListColumn = null;
    columnVendorName: CheckListColumn = null;
    columnCriticalPath :CheckListColumn = null;
    columnRlmuToGo: NumericColumn = null;
    columnNextToSign:CheckListColumn = null;
    columnExpectedTimeOfCompletion: CalendarColumn = null;
    isAdmin = false;
    isCriticalRFIExpectedTimeOfCompletionUpdateAllowed = false;
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    

    headerText: string;

    constructor(
        private formService: FormService,
        private lookupService: LookupService,
        private toastService: ToastService,
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        private router: Router,
        private roleService: RoleService,
        private projectTeamsService: ProjectTeamsService,
        private location: Location,
        private commentService: CommentService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );  
        
        this.filterForm = this.formService.createSimpleForm(new CriticalRfiFilter());
    }

    ngOnInit() {
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.isCriticalRFIExpectedTimeOfCompletionUpdateAllowed = this.roleService.isInRole(Constants.applicableGroups.CriticalRFIPriorityOverride);

        this.CriticalRfiDataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;           
            this.resultsLength = data.totalCount;
        });

        this.CriticalRfiFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.paginator.pageIndex = filter.page;
            this.pageSize = filter.take;
            this.showHideColumns = filter.showHideColumns;
        });
        this.isReadOnly = this.roleService.isReadOnly();

        this.activatedRoute.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((queryParamMap) => {
            const subsystem = queryParamMap.get('subsystem');
            const rfo = queryParamMap.get('rfo');
            const changeType = queryParamMap.get('changeType');
            const discipline = queryParamMap.get('discipline');
            const subsystems = queryParamMap.getAll('subsystems').map((s) => ({ id: s }));
            this.rfos = queryParamMap.getAll('rfos').map((s) => ({ name: s } as RFO));
            const remaining = queryParamMap.get('remaining') === 'true';

            if (subsystem != null) {
                this.headerText = `Change by subsystem: ${subsystem}`;
            } else if (rfo != null) {
                this.headerText = `Change by RFO: ${rfo}`;
            }

            this.lockFilters = queryParamMap.get('lockFilters') === 'true';
            this.isNewPage = queryParamMap.get('new') === 'true';
            this.allowCommentsBulkUpdate = queryParamMap.get('allowCommentsBulkUpdate') === 'true';

            if (this.isNewPage) {
                this.store.dispatch(new CriticalRfiFilterReset());
                this.router.navigate(['/CriticalRfi']);
                this.store.dispatch(new CriticalRfiFilterRequest());
            }
            if (this.lockFilters) {
                this.filterForm.controls.subsystems.setValue(
                    subsystem ? [{ id: subsystem }] : subsystems ? subsystems : []
                );
                let rfoFilter = new RFO();
                rfoFilter.name = rfo;

                this.filterForm.controls.rfos.setValue(rfo ? [rfoFilter] : []);
                if (this.rfos && this.rfos.length > 0) {
                    this.filterForm.controls.rfos.setValue(this.rfos);
                }
                this.filterForm.controls.changeTypes.setValue(changeType ? [changeType] : []);
                this.filterForm.controls.disciplines.setValue(discipline ? [discipline] : []);
                this.filterForm.controls.remaining.setValue(remaining);

                this.updateFilterByFormProperties();
            }

            this.makeRequest$.pipe(takeWhile(() => this.isAlive)).subscribe((makeRequest) => {
                if (makeRequest || this.resultsLength === 0) {
                    this.store.dispatch(new CriticalRfiFilterRequest());
                }
            });

            this.subsystem = subsystem;
            this.rfo = rfo;
        });

        if (!this.lockFilters) {
            forkJoin([this.lookupService.getDesignStatuses(), this.lookupService.getChangeTypes()])
                .pipe(takeWhile(() => this.isAlive))
                .subscribe(([designStatuses, changeTypes]) => {
                    this.designStatuses = designStatuses;
                    this.changeTypes = changeTypes;
                });
        }

        this.CriticalRfiLoader$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => (this.isLoading = isLoading));

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: any[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines. Please contact Program Administrator.'
                    );
                }
            );

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new CriticalRfiFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new CriticalRfiFilterRequest());
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.sortBy = data));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.direction = data));

        this.columnTiComments$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnTiComments = data));
        this.columnKpjvComments$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnKpjvComments = data));
        this.columnDiscipline$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDiscipline = data));
        this.columnDesignStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnDesignStatus = data));
        this.columnStepStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnStepStatus = data));        
        this.columnPriority$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPriority = data));
        this.columnPriorityManualOverride$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPriorityManualOverride = data));
        this.columnPriorityFinal$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPriorityFinal = data));
        this.columnSubPriority$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnSubPriority = data));
        this.columnRFIOriginator$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnRFIOriginator = data));
        this.columnRevision$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRevision = data));
        this.columnSystemOwner$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSystemOwner = data));
        this.columnAreaBreakDown$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnAreaBreakDown = data));
        this.columnRFSUForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFSUForecast = data));
        this.columnRFSUActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFSUActual = data));
        this.columnRFOActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFOActual = data));
        this.columnRFOForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFOForecast = data));
        this.columnId$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnId = data));
        this.columnSubsystem$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSubsystem = data));
        this.columnTitle$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnTitle = data));
        this.columnChangeType$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnChangeType = data));
        this.columnWalkdownForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnWalkdownForecast = data));
        this.columnRFSUPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFSUPlan = data));
        this.columnRfo$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRfo = data));
        this.columnResponseDuration$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnResponseDuration = data));
        this.columnisVendorClarification$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnisVendorClarification = data));
        this.columnVendorName$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnVendorName = data));
        this.columnCriticalPath$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnCriticalPath = data));
        this.columnRlmuToGo$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRlmuToGo = data));
        this.columnNextToSign$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnNextToSign = data));
        this.columnExpectedTimeOfCompletion$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnExpectedTimeOfCompletion = data));
        
    }

    stopeventProp(event: MouseEvent){
        event.stopPropagation();        
    }

    cloneElement(element: any): any {
        return _.cloneDeep(element);
      }

    updateSubPriority(change: MatSelectChange, changeDocStatus: CriticalRfiDTO){
        const clonedChangeDocStatus = _.cloneDeep(changeDocStatus);
        clonedChangeDocStatus.subPriority = change.value;
        this.store.dispatch(
            new CriticalRfiUpdateSubPriorityRequest({
                subPriority : clonedChangeDocStatus              
            })
        );
    }

    reverseValue(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };
        this.store.dispatch(
            new CriticalRfiFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showHideColumns,
            })
        );
    }

    resetColumns() {
        this.showHideColumns = new ShowHideColumns();
    }

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.filterForm.value.projectTeamNames);
    };

    private getLatestFilterData(): CriticalRfiFilter {
        let filter: CriticalRfiFilter;
        this.store.pipe(select((x) => x.criticalRfiState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getDisciplines = (search = '', take = 30, page = 0) =>
        this.lookupService.searchDisciplinesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
    getStepStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchStepStatusesWithCriticalRfiFilter(search, take, page, this.getLatestFilterData());
    getTypes = (search = '', take = 30, page = 0) =>
        this.lookupService.searchChangeTypesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
    getIds = (search = '', take = 30, page = 0) =>
        this.lookupService.searchIdsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
    getSubsystemsForColumn = (search = '', take = 30, page = 0) =>
        this.lookupService
            .searchSubsystemsWithChangeManagementFilter(search, take, page, this.getLatestFilterData())
            .pipe(
                map((subsys) => {
                    if ((search == '' || search == 'N/A') && page === 0) {
                        subsys.unshift('N/A');
                    }
                    return subsys;
                })
            );

    getTitles = (search = '', take = 30, page = 0) =>
        this.lookupService.searchTitlesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getPriorities = (search = '', take = 30, page = 0) =>
        this.lookupService.searchPrioritiesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getRFIOriginators = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRFIOriginatorsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
        
    getManualPriorities = (search = '', take = 30, page = 0) =>
        this.lookupService.searchManualPrioritiesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getFinalPriorities = (search = '', take = 30, page = 0) =>
        this.lookupService.searchFinalPrioritiesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getSubPriorities = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubPrioritiesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getRevisions = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRevisionsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getSystemOwners = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSystemOwnersWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getAreaBreakDown = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakDownWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getNextToSign = (search = '', take = 30, page = 0) =>
        this.lookupService.searchNextToSignWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    gettiComments = (search = '', take = 30, page = 0) =>
        this.lookupService.searchtiCommentsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
    getkpjvComments = (search = '', take = 30, page = 0) =>
        this.lookupService.searchkpjvCommentsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchStatusesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getRfos = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRfoWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getresponseDuration = (search = '', take = 30, page = 0) =>
        this.lookupService.searchResponseDurationWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getisVendorClarification = (search = '', take = 30, page = 0) =>
        this.lookupService.searchisVendorClarificationWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
    
    getVendorName = (search = '', take = 30, page = 0) =>
        this.lookupService.searchVendorNameWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getCriticalPath = (search = '', take = 30, page = 0) =>
        this.lookupService.searchCriticalPathWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
        
        onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new CriticalRfiFilterReset());
        this.filterForm.setValue(new CriticalRfiFilter());
	}

    displaySelectedPriorities(priorities: StagedStartUpPriority[]) {
        return priorities.map((p) => p.priorityName).join(', ');
    }

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new CriticalRfiFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.filterForm, key, value);
                }
            }
        }
    }

    search() {
        this.updateFilterByFormProperties();

        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new CriticalRfiFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new CriticalRfiFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new CriticalRfiFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new CriticalRfiFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new CriticalRfiFilterRequest());
    }

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    openCommentPopup(element: CriticalRfiDTO, event: any, type: string) {
        let comment = '';
        if(type == 'kpjvcomment')
            comment = element.kpjvComment || '';
        else
            comment = element.tiComment || '';

        if (this.isReadOnly) return;

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                filteredCount: this.allowCommentsBulkUpdate ? this.resultsLength : 0,
                mentions: '',
                url: `changeDocumentDetails/${element.type}/${element.id}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new CriticalRfiAddBulkCommentRequest({
                                description: value,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new CriticalRfiAddCommentRequest({
                                id: element.id,
                                description: value,
                                mentions,
                                type,
                            })
                        );
                    }
                },
            })
        );
        event.stopPropagation();
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(
                HeaderChecklistFilterComponent,
                columnName === 'title' ? 800 : 400,
                650,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                    placeHolder: placeholder,
                    searchFunc: searchFunc,
                    selectedItems: selected ? [...selected.items] : [],
                    excludeBlanks: excludeBlanks,
                    onlyBlanks: onlyBlanks,
                    isSortingOff: columnName === 'subsystem' || columnName === 'rfo' || columnName === 'areaBreakDown' || columnName === 'rfo' || columnName === 'linkedIWPs' ? true : false,
                    showCounts: showCounts,
                    showInputSearch: showInputSearch,
                    showBlankOptions: showBlankOptions,
                    action: (data) => {
                        let value = new CheckListColumn();
                        value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                        value.excludeBlanks = data.excludeBlanks;
                        value.onlyBlanks = data.onlyBlanks;
                        this.filterForm.controls[propertyName].setValue(value);

                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                        this.search();
                    },
                    resetColumnAction: () => {
                        this.store.dispatch(
                            new CriticalRfiFilterPropertyUpdate({
                                key: propertyName,
                                value: null,
                            })
                        );
                    },
                    cancelAction: () => {
                        this.store.dispatch(
                            new CriticalRfiFilterPropertyUpdate({
                                key: propertyName,
                                value: this.filterForm.controls[propertyName].value,
                            })
                        );
                    },
                }
            )
        );
    }

    openHeaderNumericFilter(property: string, currentState: NumericColumn, placeHolder: string) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderNumericFilter>(HeaderNumericFilterComponent, 400, 350, {
                placeHolder: placeHolder,
                showBlankOptions: true,
                isAscendingSort: this.sortBy === property && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === property && this.direction == OrderDirection.Desc,
                minValue: currentState ? currentState.minValue : null,
                maxValue: currentState ? currentState.maxValue : null,
                excludeBlanks: currentState ? currentState.excludeBlanks : false,
                onlyBlanks: currentState ? currentState.onlyBlanks : false,
                onSumbit: (data: HeaderNumericFilter) => {
                    let newFilterState = new NumericColumn();
                    newFilterState = { ...data };

                    const columnName = `column${this.capitalizeFirstLetter(property)}`;
                    if (
                        this.numericColumns.includes(columnName) &&
                        this.filterForm.controls.hasOwnProperty(columnName)
                    ) {
                        this.filterForm.controls[columnName].setValue(newFilterState);
                    }

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, property);
                    this.search();
                },
                onReset: () => {
                    const columnName = `column${this.capitalizeFirstLetter(property)}`;                  
                    this.store.dispatch(
                        new CriticalRfiFilterPropertyUpdate({
                            key: columnName,
                            value: null,
                        })
                    );
                },
            } as HeaderNumericFilter)
        );
    }
    
    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new CriticalRfiFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName == 'id' ? 'number' : columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new CriticalRfiFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'number', direction: OrderDirection.Desc },
                })
            );
        }
    }

    openHistoryPopup(element: CriticalRfiDTO, type: string) {
        if (type== "ticomment" && element.tiComment === null) {
            return;
        } else if (type== "kpjvcomment" && element.kpjvComment === null) {
            return;
        } else {
            this.isLoading = true;
            this.commentService.getCriticalRfiComments(element.id.toString(), type).subscribe((data) => {
                this.isLoading = false;
                this.popupSvc.openPopup(
                    new PopupSettings(
                        CommentsHistoryComponent,
                        null,
                        null,
                        {
                            commentHist: data,
                            action: (id: number) => {
                                data = data.filter((s) => s.id !== id);
                                this.store.dispatch(new CriticalRfiDeleteCommentRequest({ id: id, type }));
                            },
                        },
                        200,
                        200
                    )
                );
            });
        }
    }

    openDetailsPage(row: any) {
        this.ngZone.run(() => this.router.navigate([`/changeDocumentDetails/${row.type}/${row.id}`]));
    }

    exportToExcel() {
        this.store.dispatch(new CriticalRfiExportToExcelRequest());
    }
    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(
                HeaderDateFilterComponent,
                null,
                null,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                    placeHolder: placeholder,
                    isSortingOff: false,
                    action: (data) => {
                        this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                        this.search();
                    },
                },
                345,
                580
            )
        );
    }

    expectedTimeofCompletionHistory(event: MouseEvent,changeDocId: number) {
        event.preventDefault();
        this.popupSvc.openPopup(
            new PopupSettings(EtcHistoryComponent, 550, 500, {
                changeDocId: changeDocId
            })
        );
    }

    priorityUpdateHistory(event: MouseEvent,changeDocId: number) {
        event.preventDefault();
        this.popupSvc.openPopup(
            new PopupSettings(ManualPriroityHistoryComponent, 550, 500, {
                changeDocId: changeDocId
            })
        );
    }

    updateManualPriority(change: MatSelectChange, changeDocStatus: CriticalRfiDTO){
        const clonedChangeDocStatus = _.cloneDeep(changeDocStatus);
        clonedChangeDocStatus.priorityManualOverride = change.value;
        changeDocStatus.priorityManualOverride = change.value;
        this.store.dispatch(
            new CriticalRfiUpdateManualPriorityRequest({
                manualPriority: clonedChangeDocStatus              
            })
        );
    }

    manualPriorityUpdateAccess(){
        let isValid = this.roleService.isInRole(Constants.applicableGroups.Admin) ||
        this.roleService.isInRole(Constants.applicableGroups.CriticalRFIPriorityOverride)
        ? true : false;

        return isValid;
    }

    openCriticalRFIExpectedTimeOfCompletionDatepicker(event: any ,element: CriticalRfiDTO, type: string) {
        event.stopPropagation();
        let date;
        if (this.isAdmin || this.isCriticalRFIExpectedTimeOfCompletionUpdateAllowed) {

            this.popupSvc.openPopup(
                new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                  selectedDate: date,
                  dateType: 'common',               
                  commonSaveAction: (d: moment.Moment) => {

                    this.saveDate(element, type,d);
                  },
                })
              );
        } else{
            return;
        }
    }

    saveDate(row: CriticalRfiDTO, type: string, expectedClosureDate: moment.Moment) {

        if (type== "expectedTimeOfCompletion"){
             let selectedExpectedClosureDate =  expectedClosureDate ;
                 const payload = {
                    criticalRfi: row,
                    expectedClosureDate: selectedExpectedClosureDate
                 } ;
             this.store.dispatch(new CriticalRfiAddExpectedClosureDateRequest(payload));
        }
     }
}
