import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import {
    PlanningDataActionTypes,
    PlanningDataUploadLogSuccess,
    PlanningDataUploadLogError,
    PlanningDataDeltaSuccess,
    PlanningDataSetStatusAndStartDate,
    PlanningDataDeltaRequest,
    PlanningDataDeltaError,
    PlanningDataPatchDeltaRequest,
    PlanningDataPatchDeltaSuccess,
    PlanningDataPatchDeltaError,
    PlanningDataValidateDeltaSuccess,
    PlanningDataValidateDeltaError,
    PlannignDataClearInProgressSpinner,
    PlanningDataTemplateFileRequest,
    PlanningDataTemplateFileSuccess,
    PlanningDataTemplateFileError,
    PlanningDataDownloadOutputDataRequest,
    PlanningDataDownloadOutputDataSuccess,
    PlanningDataDownloadOutputDataError,
    PlanningDataPatchAllDeltaRequest,
    PlanningDataPatchAllDeltaSuccess,
    PlanningDataPatchAllDeltaError,
    PlanningDataValidateButtonStateSuccess,
    PlanningDataValidateButtonStateError,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class PlanningDataEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(PlanningDataActionTypes.PlanningDataUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['Planning']).pipe(
                map((uploadLogData) => new PlanningDataUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new PlanningDataUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<PlanningDataUploadLogSuccess>(PlanningDataActionTypes.PlanningDataUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.planningDataState.uploadLogStatus),
            this.store.select((store) => store.planningDataState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new PlanningDataSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    invokeDeltaRequest$ = this.actions$.pipe(
        ofType(PlanningDataActionTypes.PlanningDataSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.planningDataState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        map(() => new PlanningDataDeltaRequest())
    );

    @Effect()
    getDelta$ = this.actions$.pipe(
        ofType(PlanningDataActionTypes.PlanningDataDeltaRequest),
        withLatestFrom(this.store.select((store) => store.planningDataState.deltaFilter)),
        mergeMap(([, deltaFilter]) =>
            this.manualUploadsService.getPlanningDataDelta(deltaFilter.page, deltaFilter.take).pipe(
                map((deltaData: any) => new PlanningDataDeltaSuccess(deltaData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delta records. Please contact Program Administrator'
                    );
                    return of(new PlanningDataDeltaError(error));
                })
            )
        )
    );

    @Effect()
    patchDelta$ = this.actions$.pipe(
        ofType<PlanningDataPatchDeltaRequest>(PlanningDataActionTypes.PlanningDataPatchDeltaRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchPlanningDataDelta(action.payload.subsystem, action.payload.deltaState).pipe(
                map(() => new PlanningDataPatchDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deltaState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new PlanningDataPatchDeltaError(error));
                })
            )
        )
    );

    @Effect()
    patchAllDelta$ = this.actions$.pipe(
        ofType<PlanningDataPatchAllDeltaRequest>(PlanningDataActionTypes.PlanningDataPatchAllDeltaRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchAllPlanningDataDelta(action.payload.deltaState).pipe(
                map(() => new PlanningDataPatchAllDeltaSuccess({ deltaState: action.payload.deltaState })),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deltaState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new PlanningDataPatchAllDeltaError(error));
                })
            )
        )
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(PlanningDataActionTypes.PlanningDataValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validatePlanningDataDelta().pipe(
                map(() => new PlanningDataValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new PlanningDataValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(PlanningDataActionTypes.PlanningDataDeltaRequest),
        withLatestFrom(this.store.select((store) => store.planningDataState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new PlannignDataClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<PlanningDataTemplateFileRequest>(PlanningDataActionTypes.PlanningDataTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new PlanningDataTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Planning Dates structure file. Please contact Program Administrator'
                    );
                    return of(new PlanningDataTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<PlanningDataTemplateFileSuccess>(PlanningDataActionTypes.PlanningDataTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'Subsystem Planning Dates.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<PlanningDataDownloadOutputDataRequest>(PlanningDataActionTypes.PlanningDataDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new PlanningDataDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new PlanningDataDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<PlanningDataDownloadOutputDataSuccess>(PlanningDataActionTypes.PlanningDataDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(PlanningDataActionTypes.PlanningDataDeltaSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('PlanningData').pipe(
                map((response: any) => new PlanningDataValidateButtonStateSuccess(response)),
                catchError((error) => of(new PlanningDataValidateButtonStateError(error)))
            )
        )
    );
}
