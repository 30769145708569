import * as moment from "moment";
import { TagKeyDTO } from "../store/tag-search/model";

export class ITRConstraintDTO {
    itrId: number;
    constraintType: number;
    constraintTypeName: string;
    responsibleGroup: number;
    // responsibleUser: number;
    responsibleUserName: string;
    additionalResponsibleUser: number;
    additionalResponsibleUserName: string;
    dueDate: moment.Moment;
    fmRorPOETADate: moment.Moment; 
    deleted: string;
    constraintRaisedDate : moment.Moment;
    constraintRaisedByUser: string;
    constraintRaisedByTeamId: string;
    raisedByUserName: string;
    id: number;
    fmrOrPO: string;
    waypointRequired: string;
    associatedNPW: string;
    associatedPLI: string;
    associatedQVD: string;
    ConstraintStatus: string;
    ConstraintStatusDate: moment.Moment;
    associatedNPWChangeType: string;
    itrConstraintDescription: string;
    tagNo: TagKeyDTO[];
    barcodes: TagKeyDTO[];
}
