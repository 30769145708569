import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    CTARegisterActionTypes,
    CTARegisterFilterSuccess,
    CTARegisterFilterError,
    CTARegisterFilterRequest,
    CTARegisterDownloadDataSuccess,
    CTARegisterDownloadDataRequest,
    CTARegisterDownloadDataError,
    CTADeleteRequest,
    CTADeleteSuccess,
    CTADeleteError,
} from './actions';
import { map, mergeMap, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { CTAService } from 'src/app/services/api/webapi-services/cta.service';

@Injectable()
export class CTARegisterEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private ctaService: CTAService
    ) {}

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<CTARegisterFilterRequest>(CTARegisterActionTypes.CTARegisterFilterRequest),
        withLatestFrom(this.store.select((store) => store.ctaRegisterState.filter)),
        mergeMap(([action, CTARegisterFilter]) => {
            let filter = action.payload ?? CTARegisterFilter;
            return this.ctaService.getCTARegister(filter).pipe(
                map((dataPagination) => {
                    return new CTARegisterFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering CTA Register. Please contact Program Administrator.'
                    );
                    return of(new CTARegisterFilterError(error));
                })
            );
        })
    );

    @Effect()
    downloadData$ = this.actions$.pipe(
        ofType<CTARegisterDownloadDataRequest>(CTARegisterActionTypes.CTARegisterDownloadDataRequest),
        mergeMap((action) =>
            this.ctaService.downloadCTAAttachment(action.payload).pipe(
                map((blob) => new CTARegisterDownloadDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while downloading file for CTA Registers. Please contact Program Administrator.'
                    );
                    return of(new CTARegisterDownloadDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveData$ = this.actions$.pipe(
        ofType<CTARegisterDownloadDataSuccess>(CTARegisterActionTypes.CTARegisterDownloadDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    deleteCTA$ = this.actions$.pipe(
        ofType(CTARegisterActionTypes.CTADeleteRequest),
        mergeMap((action: CTADeleteRequest) =>
            this.ctaService.deleteCTA(action.payload).pipe(
                switchMap(() => [new CTADeleteSuccess(action.payload), new CTARegisterFilterRequest()]),
                catchError((error) => {
                    this.toastService.Error('Error occurred while removing WR. Please contact Program Administrator.');
                    return of(new CTADeleteError(error));
                })
            )
        )
    );
}
