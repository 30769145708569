<div class="weekly-validation-progress-container">
    <!-- <h4>weekly validation progress</h4> -->
    <div class="subtitle-container">
        <div class="options-container">
            <mat-form-field appearance="outline">
                <input matInput [formControl]="dateRangePickerControlFormatted" />
                <input
                    matInput
                    [matDatepicker]="dateRangePicker"
                    [matDatepickerFilter]="dateRangePickerFilter"
                    [formControl]="dateRangePickerControl"
                    hidden
                    [max]="rangeDateFilter"
                />
                <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                <mat-datepicker #dateRangePicker disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="legend-container">
            <div class="legend-item green">Validation Completed</div>
            <!-- <div class="legend-item yellow">In Progress</div> -->
        </div>
    </div>

    <div class="chart-container">
        <div class="bar-chart-container">
            <ngx-charts-bar-vertical-2d
                [scheme]="colorScheme"
                [results]="chartData$ | async"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [roundDomains]="true"
                [yAxisTickFormatting]="axisFormat"
                [roundEdges]="false"
                [barPadding]="1"
                [groupPadding]="6"
            >
            </ngx-charts-bar-vertical-2d>
        </div>
    </div>

    <div *ngIf="isLoading$ | async" class="weekly-validation-progress-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
