import { Action } from '@ngrx/store';
import { BaseResultDTO } from '../../common.model';
import { SubsystemMCDashboard2DTO, SubsystemMCDashboardDTO } from './model';

export enum SubsystemMCDashboardActionTypes {
    SubsystemMCDashboard1Request = '[Subsystem MC Dashboard 1] Request',
    SubsystemMCDashboard1Success = '[Subsystem MC Dashboard 1] Success',
    SubsystemMCDashboard1Error = '[Subsystem MC Dashboard 1] Error',
    SubsystemMCDashboard1UpdateFilterProperties = '[Subsystem MC Dashboard 1] Update Filter Properties',
    SubsystemMCDashboard1ResetFilter = '[Subsystem MC Dashboard 1] Reset Filter',
    SubsystemMCDashboard1ExportToExcelRequest = '[Subsystem MC Dashboard 1] Export To Excel Request',
    SubsystemMCDashboard1ExportToExcelSuccess = '[Subsystem MC Dashboard 1] Export to Excel Success',
    SubsystemMCDashboard1ExportToExcelError = '[Subsystem MC Dashboard 1] Export to Excel Error',
    SubsystemMCDasbboard1RedirectToDetailedStatus = '[Subsystem MC Dashboard 1] Redirect to DetailedStatus',

    SubsystemMCDashboard2Request = '[Subsystem MC Dashboard 2] Request',
    SubsystemMCDashboard2Success = '[Subsystem MC Dashboard 2] Success',
    SubsystemMCDashboard2Error = '[Subsystem MC Dashboard 2] Error',
    SubsystemMCDashboard2UpdateFilterProperties = '[Subsystem MC Dashboard 2] Update Filter Properties',
    SubsystemMCDashboard2ResetFilter = '[Subsystem MC Dashboard 2] Reset Filter',
    SubsystemMCDashboard2ExportToExcelRequest = '[Subsystem MC Dashboard 2] Export To Excel Request',
    SubsystemMCDashboard2ExportToExcelSuccess = '[Subsystem MC Dashboard 2] Export to Excel Success',
    SubsystemMCDashboard2ExportToExcelError = '[Subsystem MC Dashboard 2] Export to Excel Error',
    SubsystemMCDasbboard2RedirectToDetailedStatus = '[Subsystem MC Dashboard 2] Redirect to DetailedStatus',
}

export class SubsystemMCDashboard1Request implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard1Request;
}

export class SubsystemMCDashboard1ExportToExcelRequest implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard1ExportToExcelRequest;
}

export class SubsystemMCDashboard1ExportToExcelSuccess implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard1ExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class SubsystemMCDashboard1ExportToExcelError implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard1ExportToExcelError;

    constructor(public payload: string) {}
}

export class SubsystemMCDashboard1Success implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard1Success;

    constructor(public payload: BaseResultDTO<SubsystemMCDashboardDTO>) {}
}

export class SubsystemMCDashboard1Error implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard1Error;

    constructor(public payload: string) {}
}

export class SubsystemMCDashboard1UpdateFilterProperties implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard1UpdateFilterProperties;

    constructor(public payload: { [key: string]: any }) {}
}

export class SubsystemMCDashboard1ResetFilter implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard1ResetFilter;
}

export class SubsystemMCDasbboard1RedirectToDetailedStatus implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDasbboard1RedirectToDetailedStatus;

    constructor(public payload: { subsystem: string; scope: string; exceptions: boolean; category: string; zone: string }) {}
}

export class SubsystemMCDashboard2Request implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard2Request;
}

export class SubsystemMCDashboard2Success implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard2Success;

    constructor(public payload: BaseResultDTO<SubsystemMCDashboard2DTO>) {}
}

export class SubsystemMCDashboard2Error implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard2Error;

    constructor(public payload: string) {}
}

export class SubsystemMCDashboard2UpdateFilterProperties implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard2UpdateFilterProperties;

    constructor(public payload: { [key: string]: any }) {}
}

export class SubsystemMCDashboard2ExportToExcelRequest implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard2ExportToExcelRequest;
}

export class SubsystemMCDashboard2ExportToExcelSuccess implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard2ExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class SubsystemMCDashboard2ExportToExcelError implements Action {
    readonly type = SubsystemMCDashboardActionTypes.SubsystemMCDashboard2ExportToExcelError;

    constructor(public payload: string) {}
}

export type SubsystemMCDashboardActions =
    | SubsystemMCDashboard1Request
    | SubsystemMCDashboard1Success
    | SubsystemMCDashboard1Error
    | SubsystemMCDashboard1UpdateFilterProperties
    | SubsystemMCDashboard1ResetFilter
    | SubsystemMCDasbboard1RedirectToDetailedStatus
    | SubsystemMCDashboard1ExportToExcelRequest
    | SubsystemMCDashboard1ExportToExcelSuccess
    | SubsystemMCDashboard1ExportToExcelError
    | SubsystemMCDashboard2Request
    | SubsystemMCDashboard2Success
    | SubsystemMCDashboard2Error
    | SubsystemMCDashboard2UpdateFilterProperties
    | SubsystemMCDashboard2ExportToExcelRequest
    | SubsystemMCDashboard2ExportToExcelSuccess
    | SubsystemMCDashboard2ExportToExcelError;
