<div class="results-panel results-container">
    <div class="results-panel-container">
        <div class="results-panel-header-container">
            <div class="results-panel-header-title-row">
                <div class="title">
                    Subsystems {{ (selectedPriority$ | async) ? '(' + (selectedPriority$ | async) + '-' + (selectedLabel$ | async) + ')' : 'with exceptions'}}
                </div>
                <div class="results-header-actions">
                    <button
                        type="button"
                        mat-button
                        (click)="showAllSubsystems()"
                        class="results-clear-flags"
                    >
                        <mat-icon>close</mat-icon>
                        remove filters
                    </button>
                </div>
            </div>
        </div>

        <div class="results-panel-content-container">
            <div class="table-wrapper">
                <div class="scroll">
                    <div #tableContainer class="table-container">
                        <table
                            mat-table
                            matSort
                            [dataSource]="(table$ | async)?.items"
                            [matSortActive]="sortBy"
                            matSortStart="subsystem"
                            matSortDisableClear
                            [matSortDirection]="sortDirection"
                        >
                            <ng-container matColumnDef="subsystem" sticky>
                                <th
                                    mat-header-cell
                                    app-col-resize
                                    disableClear
                                    *matHeaderCellDef
                                    class="sticky-col1"
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnSubsystem)
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'subsystem',
                                                    getSubsystems,
                                                    'columnSubsystem',
                                                    'Subsystem',
                                                    columns.columnSubsystem,
                                                    true
                                                )
                                            "
                                        >
                                            Subsystem
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                            <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    class="sticky-col1"
                                >
                                    {{ element.subsystem }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="priorities">
                                <th 
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnPriorities)
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'priorities',
                                                    getPriorities,
                                                    'columnPriorities',
                                                    'Priorities',
                                                    columns.columnPriorities,
                                                    true,
                                                    true,
                                                    false,
                                                    false
                                                )
                                            "
                                        >
                                            Staged Start-up Priorities
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'priorities'">
                                            <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.priorities }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mcExceptionsCount">
                                <th 
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForNumericColumn(columns.columnMCExceptionsCount)
                                    }"
                                    class="end"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="openHeaderNumericFilter(
                                                'mcExceptionsCount',
                                                'columnMCExceptionsCount',
                                                columns.columnMCExceptionsCount,
                                                'MC Exceptions')"
                                        > MC Exceptions Count</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'mcExceptionsCount'">
                                            <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                >
                                    <span>
                                        <a
                                            (click)="
                                                openDetailedStatusPage(
                                                    element.subsystem,
                                                    'EX',
                                                    element.discipline,
                                                    Zone.Red
                                                )
                                            "
                                        >
                                            {{ element.mcExceptionsCount }}
                                        </a>
                                    </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuExceptionsCount">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForNumericColumn(columns.columnRFSUExceptionsCount)
                                    }"
                                    class="end"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="openHeaderNumericFilter(
                                                'rfsuExceptionsCount',
                                                'columnRFSUExceptionsCount',
                                                columns.columnRFSUExceptionsCount,
                                                'RFSU Exceptions')"
                                        >RFSU Exceptions Count</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuExceptionsCount'">
                                            <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span>
                                        <a
                                            (click)="
                                                openDetailedStatusPage(
                                                    element.subsystem,
                                                    'EX',
                                                    element.discipline,
                                                    Zone.Blue
                                                )
                                            "
                                        >
                                            {{ element.rfsuExceptionsCount }}
                                        </a>
                                    </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfoExceptionsCount">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForNumericColumn(columns.columnRFOExceptionsCount)
                                    }"
                                    class="end"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="openHeaderNumericFilter(
                                                'rfoExceptionsCount',
                                                'columnRFOExceptionsCount',
                                                columns.columnRFOExceptionsCount,
                                                'RFO Exceptions')"
                                        >RFO Exceptions Count</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'rfoExceptionsCount'">
                                            <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span>
                                        <a
                                            (click)="
                                                openDetailedStatusPage(
                                                    element.subsystem,
                                                    'EX',
                                                    element.discipline,
                                                    Zone.RFO
                                                )
                                            "
                                        >
                                            {{ element.rfoExceptionsCount }}
                                        </a>
                                    </span>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="paginator page-counter">
        <mat-paginator
            [length]="(table$ | async)?.totalCount"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50]"
            (page)="onPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <input
            type="number"
            [value]="this.paginator.pageIndex + 1"
            (change)="onPageChange($event.target.value)"
        />
    </div>

    <div *ngIf="(isTableLoading$ | async)" class="table-complete-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>