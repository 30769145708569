import { sortBy } from "lodash";
import { ExceptionType } from "src/app/enums";
import { getCurrentWeekStartDate } from "src/app/extensions";
import { BaseResultDTO, CalendarColumn, CheckListColumn, Contractor, Gooc, Milestone, OrderDirection, RFO, StagedStartUpPriority } from "../common.model";
import { Subsystem } from "../trace-heating-status/model";

export interface PLIPlanningState {
    data: BaseResultDTO<PLIPlanningDTO>;
    filter: PLIPlanningFilter;
    columnsVisibility: PLIColumnsVisibility;
    uncommittedPLIs: PLIPlanningDTO[];
    isLoading: boolean;
}

export class PLIPlanningDTO {
    finalPunchItem: string;
    tagNo: string;
    tagType: string;
    raisedBy: string;
    description: string;
    category: string;
    punchType: string;
    isCommitted: boolean;
    isConstrained: boolean;
    constraintFlag: string;
    actionBy: string;
    subsystemName: string;
    tagName: string;
    createdDate: Date;
    statusDate: Date;
    materialReference: string;
    attachments: Object [];
    mcPlan: Date;
    mcForecast: Date;
    rfsuPlan: Date;
    rfsuForecast: Date;
    discipline: string;
}

export class PLIPlanningFilter {
    take = 25;
    page = 0;
    sortBy: string = 'finalPunchItem';
    direction: OrderDirection = OrderDirection.Desc;

    weekStart = getCurrentWeekStartDate().toDate();
    subsystems: Subsystem[] = [];
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    disciplines: any[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    scManager: string[] = [];
    mcEngineer: string[] = [];
    systemOwner: string[] = [];
    goocs: Gooc[] = [];
    mcMilestones: Milestone[] = [];
    rfos: RFO[] = [];
    category: string[] =[];
    mcCompleted: boolean = null;
    rfsuCompleted: boolean = null;
    excludeExceptions: ExceptionType[] = [];
    constructionArea: string[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    columnConstraintFlag: CheckListColumn = null;
    columnPunchNumber: CheckListColumn = null;
    columnPunchType: CheckListColumn = null;
    columnDescription: CheckListColumn = null;
    columnSubsystem: CheckListColumn = null;
    columnSubsystemName: CheckListColumn = null;
    columnCategory: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnActionBy: CheckListColumn = null;
    columnMaterialReference: CheckListColumn = null;
    columnRaisedBy: CheckListColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnStatus: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnMcPlan: CalendarColumn = null;
    columnMcForecast: CalendarColumn = null;
    columnRfsuPlan: CalendarColumn = null;
    columnRfsuForecast: CalendarColumn = null;
}

export class PLIColumnsVisibility {
    constraintFlag = true;
    description = false;
    tagNo = true;
    raisedBy = false;
    subsystemName = false;
    tagType = false;
    tagName = false;
    createdDate = false;
    statusDate = false;
    materialReference = false;
    attachments = false;
    mcPlan = false;
    mcForecast = false;
    rfsuPlan = false;
    rfsuForecast = false;
    actionBy = false;
    discipline = false;
    status = true;
}

export class PLIColumnNames {
    select = 'Selection';
    finalPunchItem = 'PLI Number';
    description = 'PLI Description';
    tagNo = 'Tag Number';
    raisedBy = 'Raised By';
    category = 'Category';
    punchType = 'Punch Type';
    subsystem = 'Subsystem';
    subsystemName = 'Subsystem Name';
    tagType = 'Tag Type';
    tagName = 'Tag Name';
    createdDate = 'Created Date';
    statusDate = 'Status Date';
    materialReference = 'Materal Refference';
    attachments = 'Attachments';
    mcPlan = 'MC Plan';
    mcForecast = 'MC Forecast';
    rfsuPlan = 'RFSU Plan';
    rfsuForecast = 'RFSU Forecast';
    actionBy = 'Action By';
    discipline = 'Discipline';
    constraintFlag = 'Constraint Flag';
    status = 'Status';
}
export class AttachmentDTO {
    name: string;
    link: string;
}

export class Attachment {
    id: number;
    name = '';
    link = '';
    documentNo = '';
    loading: boolean;
    file: File;
    isValid: boolean;
    isBeingProcessed: boolean;
}
