<div class="table-container">
    <div *ngIf="!monthViewEnabled" class="weeks-header-container">
        <div *ngFor="let pastWeek of weeks" class="past-week">
            <div class="week-label">
                <span class="week-number">WK{{ pastWeek.week }}</span>
                <br />
                <span>W/E {{ pastWeek.date | date: 'd MMM' }}</span>
            </div>
        </div>
        <div class="type-header"><span></span></div>
    </div>
    <div *ngIf="monthViewEnabled" class="weeks-header-container">
        <div *ngFor="let month of months" class="past-week">
            <div class="week-label">
                <span>{{ month.date | date: 'MMM YY' }}</span>
            </div>
        </div>
        <div class="type-header"><span></span></div>
    </div>
    <div *ngIf="(grandTotals$ | async) && (grandTotals$ | async).length">
        <div
            *ngIf="commitmentReliabilityType === 'itr' || commitmentReliabilityType === 'rfsu'"
            class="grand-totals-container black"
        >
            <div *ngFor="let total of grandTotals$ | async" class="totals">
                <div class="plan-totals">{{ total.blackCount }}</div>
            </div>
            <div class="total-label">
                <div>{{ commitmentReliabilityType === 'itr' ? 'ITRs ' : 'RFSUs ' }}per Plan</div>
            </div>
        </div>
        <div
            *ngIf="commitmentReliabilityType === 'itr' || commitmentReliabilityType === 'rfsu'"
            class="grand-totals-container orange"
        >
            <div *ngFor="let total of grandTotals$ | async" class="totals">
                <div class="plan-totals">
                    <span
                        (click)="openItrPage(itrTypes.Committed, total.weekStart)"
                        [ngClass]="{ 'underline-text': commitmentReliabilityType === 'itr' }"
                        >{{ total.orangeCount }}</span
                    >
                </div>
            </div>
            <div class="total-label">
                <div>{{ commitmentReliabilityType === 'itr' ? 'ITRs ' : 'RFSUs ' }}in Week</div>
            </div>
        </div>
        <div class="grand-totals-container green">
            <div *ngFor="let total of grandTotals$ | async" class="totals">
                <div class="plan-totals">
                    <span
                        (click)="openItrPage(itrTypes.CommittedAchieved, total.weekStart)"
                        [ngClass]="{ 'underline-text': commitmentReliabilityType === 'itr' }"
                        >{{ total.greenCount }}</span
                    >
                </div>
            </div>
            <div class="total-label">
                <div>
                    {{
                        commitmentReliabilityType === 'itr'
                            ? 'ITR i'
                            : commitmentReliabilityType === 'rfsu'
                            ? 'RFSU i'
                            : 'I'
                    }}n Week and achieved
                </div>
            </div>
        </div>
        <div class="grand-totals-container red">
            <div *ngFor="let total of grandTotals$ | async" class="totals">
                <div class="plan-totals">
                    <span
                        (click)="openItrPage(itrTypes.CommittedNotAchieved, total.weekStart)"
                        [ngClass]="{ 'underline-text': commitmentReliabilityType === 'itr' }"
                        >{{ total.redCount }}</span
                    >
                </div>
            </div>
            <div class="total-label">
                <div>
                    {{
                        commitmentReliabilityType === 'itr'
                            ? 'ITR i'
                            : commitmentReliabilityType === 'rfsu'
                            ? 'RFSU i'
                            : 'I'
                    }}n Week but not achieved
                </div>
            </div>
        </div>
        <div class="grand-totals-container grey">
            <div *ngFor="let total of grandTotals$ | async" class="totals">
                <div class="plan-totals">
                    <span
                        (click)="openItrPage(itrTypes.AchievedNotCommitted, total.weekStart)"
                        [ngClass]="{ 'underline-text': commitmentReliabilityType === 'itr' }"
                        >{{ total.greyCount }}</span
                    >
                </div>
            </div>
            <div class="total-label">
                <div>
                    {{
                        commitmentReliabilityType === 'itr'
                            ? 'ITR a'
                            : commitmentReliabilityType === 'rfsu'
                            ? 'RFSU a'
                            : 'A'
                    }}chieved but not in Week
                </div>
            </div>
        </div>
    </div>
</div>
