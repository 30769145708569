import { interval, Observable } from 'rxjs';
import { startWith, delay } from 'rxjs/operators';
import { Constants } from 'src/app/constants';
import { PlanningUploadActions, PlanningUploadActionTypes } from './actions';
import { PlanningUploadState } from './model';

const initialState: PlanningUploadState = {
    activatedRoute: '',
    uploadInProgress: false,
    interval: interval(Constants.logRefreshInterval).pipe(startWith(0)),
};

export function reducer(state = initialState, action: PlanningUploadActions) {
    switch (action.type) {
        case PlanningUploadActionTypes.PlanningUploadSetActivatedRoute: {
            return {
                ...state,
                activatedRoute: action.payload,
            };
        }
        case PlanningUploadActionTypes.PlanningUploadFilesUploadRequest: {
            return {
                ...state,
                uploadInProgress: true,                
                interval: new Observable()
            };
        }
        case PlanningUploadActionTypes.PlanningUploadFilesUploadSuccess: {
            return {
                ...state,
                uploadInProgress: false,
                interval: interval(Constants.logRefreshInterval).pipe(
                    startWith(0),
                    delay(Constants.delayAfterUploadInterval)
                ),
            };
        }
        case PlanningUploadActionTypes.PlanningUploadFilesUploadError: {
            return {
                ...state,
                uploadInProgress: false,
            };
        }
        default:
            return state;
    }
}
