import * as moment from 'moment';

export class UserDetail {
    cai = '';
    name = '';
    position = '';
    email = '';
    id: number = null;
}

export class ContraintContactUserDetail {
    constraintTypeId: number = null;
    responsibleUserId: number = null;
    additionalResponsibleUserId: number = null;
    constraintResponsibleGroupId: number = null;
}

export class JsonPatchOperation {
    op: string;
    path: string;
    value: string | number;
}

export class RFO {
    name = '';
    rfoName = '';
    projectTeamName = '';
}

export class ChangeManagementSimple {
    name = '';
    numberedName = '';
    typeName = '';
}

export class Area {
    name = '';
    projectTeamName = '';
}

export class AreaDTO {
    id: number;
    name: string;
    code: string;
}

export class Contractor {
    contractNo = '';
    contract = '';
    tcopm = '';
    mcPlusId = '';
    projectTeamName = '';
    masterSubsystemListKey = '';
    name = '';
}

export class TCOACManager {
    id: number = null;
    name = '';
    contractorNo = '';
    projectTeamName = '';
}

export class TCOUser {
    id: number = null;
    name = '';
    role: Role = null;
    projectTeamName = '';
}

export enum Role {
    scAreaManager = 0,
    mcEngineer = 1,
    sysOwner = 2,
}

export class Waypoint {
    name = '';
    description = '';
    projectTeamName = '';
    waypointScope='';
}

export class Milestone {
    name = '';
    description = '';
    projectTeamName = '';
    waypointName = '';
}

export class McMilestone {
    name = '';
    projectTeamName = '';
}

export class Gooc {
    no = '';
    name = '';
    projectTeamName = '';
}

export class System {
    no = '';
    name = '';
    projectTeamName = '';
}

export class StagedStartUpPriority {
    priority = '';
    priorityName = '';
}

export enum OrderDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export class CommentDTO {
    id: number;
    description: string = '';
    userName: string = '';
    date: moment.Moment;
    mentions: UserDetail[];
}

export class CalendarColumn {
    rangeDates: Date[] = [];
    excludeBlanks = false;
    onlyBlanks = false;
}

export class CheckListColumn {
    items: string[] = [];
    selectAll = false;
    searchText = '';
    excludeBlanks = false;
    onlyBlanks = false;
}

export class NumericColumn {
    minValue?: number = null;
    maxValue?: number = null;
    excludeBlanks = false;
    onlyBlanks = false;
}

export class ChangeDocStatus {
    public static readonly inEngineering = 'In Engineering';
    public static readonly toImplement = 'To Implement';
    public static readonly losed = 'Closed';
    public static readonly implemented = 'Implemented';
    public static readonly noPhysicalWork = 'No Physical Work';
}

export interface BaseResultDTO<T> {
    items: T[];
    totalCount: number;
}

export interface BaseResultWithSubmissionState<T> {
    items: T[];
    totalCount: number;
    isAllSubmitted: boolean;
}

export enum PriorityType {
    Manual = 1,
    MCPlus = 2,
    ChangeDocImport = 3,
}

export class UserWithRoleDetail extends UserDetail {
    role: AppRole;
}

export enum AppRole {
    None = 0,
    Admin = 1,
    GPEngineer = 2,
    GIEngineer = 4,
    GatheringEngineer = 8,
    ReadOnly = 16,
    GPValidator = 64,
    GIValidator = 128,
}

export class WorkAreaDTO {
    workAreaName = '';
    disciplines: { id: string }[] = [];
    nbEngineer = '';
    nbDisciplineLead = '';
    tcoAreaCoordinator = '';
    workAreaSubsystems: any[] = [];
}

export class WorkAreaWorkerDTO {
    name: string;

    disciplines: string[];
}

export class WorkAreaFilter {
    subsystem = '';
    goocs: Gooc[] = [];
    rfo: RFO[] = [];
    priority: StagedStartUpPriority[] = [];
}

export class ActivityPriorityDTO {
    priorityName = '';
    activityFocusSubsystems: any[] = [];
}

export class ExcludeFilterRequestDTO {
    search = '';
    page = 0;
    pageSize = 10;
    direction: OrderDirection = OrderDirection.Desc;
}

export class SubsystemExcludeFilterRequestDTO extends ExcludeFilterRequestDTO {
    sortBy: string = 'subsystem';
    columnSubsystem?: CheckListColumn = null;
    columnSubsystemName?: CheckListColumn = null;
    columnExcludeFilter?: CheckListColumn = null;
}

export class ITRExcludeFilterRequestDTO extends ExcludeFilterRequestDTO {
    sortBy = 'itrNumber';
    columnITRNumber?: CheckListColumn = null;
    columnExcludeFilter?: CheckListColumn = null;
}

export class ITRExcludeFiltersDTO {
    itrNumber = '';
    excludeFilters: ITRExcludeFilterDTO[] = [];
}

export class SubsystemExcludeFiltersDTO {
    subsystem = '';
    name = '';
    projectTeamName = '';
    excludeFilters: SubsystemExcludeFilterDTO[] = [];
}

export class SubsystemExcludeFilterDTO {
    subsystem = '';
    excludeFilter = '';
}

export class ITRExcludeFilterDTO {
    itrNumber = '';
    excludeFilter = '';
}

export class SubsystemExcludeFiltersResponseDTO {
    data: SubsystemExcludeFiltersDTO[];
    total: number;
}

export class ITRExcludeFiltersResponseDTO {
    data: ITRExcludeFiltersDTO[];
    total: number;
}

export class ExcludeFilterAssignmentDTO {
    ids = [];
    excludeFilters = [];
}

export class SubsystemDTO {
    gooc = '';
    goocName = '';
    id = '';
    name = '';
    projectTeamName = '';
    rfo = '';
    rfoName = '';
}
