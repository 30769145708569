import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { ExcludeFilterAssignmentDTO } from 'src/app/store/common.model';

@Injectable({
    providedIn: 'root',
})
export class ExcludeFilterService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly configurationEndpoint: string = '/excludefilter';

    constructor(private http: HttpClient) {}

    assignSubsystemExcludeFilter(data: ExcludeFilterAssignmentDTO, isAssignment = true) {
        return this.http.post(
            `${this.rootUrl}${this.configurationEndpoint}/subsystem?isAssignment=${isAssignment}`,
            data
        );
    }

    assignITRExcludeFilter(data: ExcludeFilterAssignmentDTO, isAssignment = true) {
        return this.http.post(
            `${this.rootUrl}${this.configurationEndpoint}/itr?isAssignment=${isAssignment}`,
            data
        );
    }
}
