
<div class="title-container">
    <div class="title">
        <span
            >PLI In Week: {{ weekStart.toDate() | date: 'd/MMM/yy' }} to
            {{ currentlySetWeekEnd | date: 'd/MMM/yy' }}</span
        >
    </div>
</div>
<div class="results-panel results-container scroll">
    <div class="results-panel-header-container">
        <div class="results-header-actions results-title">
            <div class="table-checkboxes">
                <mat-checkbox
                    *ngFor="let columnKey of columnKeys"
                    [checked]="columnsVisibility[columnKey]"
                    (change)="reverseValue(columnKey)"
                    (click)="$event.stopPropagation()"
                    >{{ columnNames[columnKey] }}
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="table-container">
        <table mat-table matSort [dataSource]="dataSource" matSortDisableClear>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                        *ngIf="isFutureWeek"
                        (click)="highlightRow(row)"
                        (change)="$event"
                        [checked]="isSelected(row)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="subsystem">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'subsystem',
                                    getSubsystems,
                                    'columnSubsystem',
                                    'Subsystem',
                                    columnPLISubsystems,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLISubsystems)
                            }"
                            >Subsystem</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.subsystem }}</td>
            </ng-container>

            <ng-container matColumnDef="finalPunchItem">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'finalPunchItem',
                                    getPliNumbers,
                                    'columnPunchNumber',
                                    'PLI Number',
                                    columnPLIPunchNumbers,
                                    false
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLIPunchNumbers)
                            }"
                            >PLI Number</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.finalPunchItem }}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'description',
                                    getDescriptions,
                                    'columnDescription',
                                    'Description',
                                    columnPLIDescriptions,
                                    false
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLIDescriptions)
                            }"
                            >Description</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.description }}</td>
            </ng-container>

            <ng-container matColumnDef="tagNo">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'tagNo',
                                    getTagNumbers,
                                    'columnTagNumber',
                                    'Tag Number',
                                    columnTagNumbers,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnTagNumbers)
                            }"
                            >Tag Number</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.tagNo }}</td>
            </ng-container>

            <ng-container matColumnDef="actionBy">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'actionBy',
                                    getActionBys,
                                    'columnActionBy',
                                    'Action By',
                                    columnActionBys,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnActionBys)
                            }"
                            >Action By</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.actionBy }}</td>
            </ng-container>

            <ng-container matColumnDef="category">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'category',
                                    getCategories,
                                    'columnCategory',
                                    'Category',
                                    columnPLICategories,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLICategories)
                            }"
                            >Category</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.category }}</td>
            </ng-container>

            <ng-container matColumnDef="punchType">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'punchType',
                                    getPunchTypes,
                                    'columnPunchType',
                                    'Punch Type',
                                    columnPLIPunchTypes,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLIPunchTypes)
                            }"
                            >Punch Type</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.punchType }}</td>
            </ng-container>

            <ng-container matColumnDef="raisedBy">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'raisedBy',
                                    getRaisedBys,
                                    'columnRaisedBy',
                                    'Raised By',
                                    columnPLIRaisedBy,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLIRaisedBy)
                            }"
                            >Raised By</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.raisedBy }}</td>
            </ng-container>

            <ng-container matColumnDef="subsystemName">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'subsystemName',
                                    getSubsystemNames,
                                    'columnSubsystemName',
                                    'Subsystem Name',
                                    columnPLISubsystemName,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLISubsystemName)
                            }"
                            >Subsystem Name</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.subsystemName }}</td>
            </ng-container>

            <ng-container matColumnDef="tagType">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'tagType',
                                    getTagTypes,
                                    'columntagType',
                                    'Tag Type',
                                    columnPLITagType,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLITagType)
                            }"
                            >Tag Type</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.tagType }}</td>
            </ng-container>

            <ng-container matColumnDef="tagName">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'tagName',
                                    getTagNames,
                                    'columnTagName',
                                    'Tag Name',
                                    columnPLITagName,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLITagName)
                            }"
                            >Tag Name</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.tagName }}</td>
            </ng-container>

            <ng-container matColumnDef="createdDate">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="openHeaderDateFilter('columnCreatedDate', 'Created Date', columnPLICreatedDate)"
                            [ngClass]="{
                                'header-underline': showUnderlineForCalendarColumn(columnPLICreatedDate)
                            }"
                            >Created Date</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.createdDate | date: 'd/MMM/yy' }}</td>
            </ng-container>

            <ng-container matColumnDef="statusDate">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="openHeaderDateFilter('columnStatusDate', 'Status Date', columnPLIStatusDate)"
                            [ngClass]="{
                                'header-underline': showUnderlineForCalendarColumn(columnPLIStatusDate)
                            }"
                            >Status Date</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.statusDate | date: 'd/MMM/yy' }}</td>
            </ng-container>

            <ng-container matColumnDef="materialReference">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'materialReference',
                                    getMaterialReferences,
                                    'columnMaterialReference',
                                    'Material Refference',
                                    columnPLIMaterialReference,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLIMaterialReference)
                            }"
                            >Material Reference</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.materialReference }}</td>
            </ng-container>

            <ng-container matColumnDef="attachments">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span>Attachments</span>
                    </div>
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="attachments"
                >
                    <div class="uploaded-attachment-buttons" *ngFor="let att of element.attachments">
                        <div
                            class="lds-hourglass"
                            *ngIf="uploadedAttachmentsActionInProgress.getValue(att.link) || false"
                        ></div>
                        <button
                            *ngIf="!uploadedAttachmentsActionInProgress.getValue(att.link)"
                            [title]="att.name"
                            type="button"
                            class="download-uploaded-attachment"
                            (click)="download(att, $event)"
                        ></button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="mcPlan">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="openHeaderDateFilter('columnMcPlan', 'MC Plan', columnPLIMcPlan)"
                            [ngClass]="{
                                'header-underline': showUnderlineForCalendarColumn(columnPLIMcPlan)
                            }"
                            >MC Plan</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.mcPlan | date: 'd/MMM/yy' }}</td>
            </ng-container>

            <ng-container matColumnDef="mcForecast">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="openHeaderDateFilter('columnMcForecast', 'MC Forecast', columnPLIMcForecast)"
                            [ngClass]="{
                                'header-underline': showUnderlineForCalendarColumn(columnPLIMcForecast)
                            }"
                            >MC Forecast</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.mcForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>

            <ng-container matColumnDef="rfsuPlan">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="openHeaderDateFilter('columnRfsuPlan', 'RFSU Plan', columnPLIRfsuPlan)"
                            [ngClass]="{
                                'header-underline': showUnderlineForCalendarColumn(columnPLIRfsuPlan)
                            }"
                            >RFSU Plan</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfsuPlan | date: 'd/MMM/yy' }}</td>
            </ng-container>

            <ng-container matColumnDef="rfsuForecast">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="openHeaderDateFilter('columnRfsuForecast', 'RFSU Forecast', columnPLIRfsuForecast)"
                            [ngClass]="{
                                'header-underline': showUnderlineForCalendarColumn(columnPLIRfsuForecast)
                            }"
                            >RFSU Forecast</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.rfsuForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>

            <ng-container matColumnDef="discipline">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'discipline',
                                    getDisciplines,
                                    'columnDiscipline',
                                    'Discipline',
                                    columnPLIDisciplines,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLIDisciplines)
                            }"
                            >Discipline</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.discipline }}</td>
            </ng-container>

            <ng-container matColumnDef="constraintFlag">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'constraintFlag',
                                    getConstraintFlags,
                                    'columnConstraintFlag',
                                    'Constraint Flag',
                                    columnPLIConstraintFlags,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLIConstraintFlags)
                            }"
                            >Constraint Flag</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'constraintFlag'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ getConstraintFlag(element) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'status',
                                    getStatuses,
                                    'columnStatus',
                                    'Status',
                                    columnPLIStatus,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnPLIStatus)
                            }"
                            >Status</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'status'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.status }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
        </table>
    </div>
    <div class="paginator page-counter">
        <mat-paginator
            [length]="totalLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50]"
            (page)="onPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
    </div>
</div>
