import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { filter, map } from 'rxjs/operators';
import { WeekType } from 'src/app/enums';
import { PlanningLookaheadState } from 'src/app/store/reports/planning/model';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Component({
    selector: 'app-reports-planning-lookahead-chart',
    templateUrl: './reports-planning-lookahead-chart.component.html',
    styleUrls: ['./reports-planning-lookahead-chart.component.scss'],
})
export class ReportsPlanningLookaheadChartComponent implements OnInit {
    showXAxis: boolean = true;
    showYAxis: boolean = false;
    gradient: boolean = false;
    showLegend: boolean = false;
    showXAxisLabel: boolean = false;
    showYAxisLabel: boolean = false;
    colorScheme = {
        domain: ['#a1a1a1', '#01b529', '#543ebf'],
    };

    @Input() lookahead$: Observable<PlanningLookaheadState>;
    chartData$;

    ngOnInit() {
        this.chartData$ = this.lookahead$.pipe(
            filter(
                (lookahead) =>
                    lookahead.weeks.length > 0 && lookahead.grandTotals.length > 0
            ),
            map((lookahead) => {
                return _.zipWith(
                    lookahead.weeks,
                    lookahead.grandTotals,
                    (week, total) => {
                        return {
                            name: `WK${moment(week.date).format('w')}`,
                            series: [
                                {
                                    name: 'Plan',
                                    value: total.planTotal ? total.planTotal : '',
                                },
                                {
                                    name: 'Actual',
                                    value:
                                        week.weekType === WeekType.Past ? (total.actualTotal ? total.actualTotal : '') : '',
                                },
                                {
                                    name: 'Forecast',
                                    value:
                                        week.weekType === WeekType.Upcoming
                                            ? total.forecastTotal
                                                ? total.forecastTotal
                                                : ''
                                            : '',
                                },
                            ],
                        };
                    }
                );
            })
        )
    }

    axisFormat(val: number) {
        if (val % 1 === 0) {
            return val.toLocaleString();
        } else {
            return '';
        }
    }
}
