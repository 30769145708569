import { UserState } from './model';
import * as UserActions from './actions';
import { createReducer, on } from '@ngrx/store';

const initialState: UserState = {
    user: null,
};

export const userReducer = createReducer(
    initialState,
    on(UserActions.saveUser, (state, action) => ({
        ...state,
        user: action.payload,
    }))
);
    
