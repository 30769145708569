import { PreMCActions, PreMCActionTypes } from './actions';
import {
    CertificationStatusState,
    ChangeValidationDTO,
    EarlyWalkdownsState,
    EarlyWorksState,
    MCScheduleState,
    DashboardSkylineState,
    PreMCState,
    SOCPlanState,
} from './model';
import * as _ from 'lodash';
import { WeekType } from 'src/app/enums';
import * as moment from 'moment';

const initialState: PreMCState = {
    earlyWorks: new EarlyWorksState(),
    earlyWalkdowns: new EarlyWalkdownsState(),
    certificationStatus: new CertificationStatusState(),
    socPlan: new SOCPlanState(),
    preMCSkyline: new DashboardSkylineState(),
    preMCSchedule: new MCScheduleState(),
    gooc: null,
};

export function reducer(state = initialState, action: PreMCActions): PreMCState {
    switch (action.type) {
        case PreMCActionTypes.EarlyWorksRequest: {
            return {
                ...state,
                gooc: action.payload,
                earlyWorks: {
                    ...state.earlyWorks,
                    isLoading: true,
                },
            };
        }
        case PreMCActionTypes.EarlyWorksSuccess: {
            return {
                ...state,
                earlyWorks: {
                    isLoading: false,
                    entity: action.payload,
                },
            };
        }
        case PreMCActionTypes.EarlyWorksSaveError:
        case PreMCActionTypes.EarlyWorksError: {
            return {
                ...state,
                earlyWorks: {
                    ...state.earlyWorks,
                    isLoading: false,
                },
            };
        }
        case PreMCActionTypes.EarlyWorksSaveRequest: {
            return {
                ...state,
                earlyWorks: {
                    ...state.earlyWorks,
                    isLoading: true,
                },
            };
        }
        case PreMCActionTypes.EarlyWorksSaveSuccess: {
            return {
                ...state,
                earlyWorks: {
                    ...state.earlyWorks,
                    isLoading: false,
                    entity: action.payload,
                },
            };
        }
        case PreMCActionTypes.EarlyWalkdownsRequest: {
            return {
                ...state,
                gooc: action.payload,
                earlyWalkdowns: {
                    ...state.earlyWalkdowns,
                    isLoading: true,
                },
            };
        }
        case PreMCActionTypes.EarlyWalkdownsSaveSuccess:
        case PreMCActionTypes.EarlyWalkdownsSuccess: {
            return {
                ...state,
                earlyWalkdowns: {
                    ...state.earlyWalkdowns,
                    isLoading: false,
                    entity: action.payload,
                },
            };
        }
        case PreMCActionTypes.EarlyWalkdownsSaveError:
        case PreMCActionTypes.EarlyWalkdownsError: {
            return {
                ...state,
                earlyWalkdowns: {
                    ...state.earlyWalkdowns,
                    isLoading: false,
                },
            };
        }
        case PreMCActionTypes.EarlyWalkdownsSaveRequest: {
            return {
                ...state,
                earlyWalkdowns: {
                    ...state.earlyWalkdowns,
                    isLoading: true,
                },
            };
        }
        case PreMCActionTypes.PLIValidationRequest: {
            return {
                ...state,
                gooc: action.payload,
                certificationStatus: {
                    ...state.certificationStatus,
                    pliValidation: {
                        ...state.certificationStatus.pliValidation,
                        isLoading: true,
                    },
                },
            };
        }
        case PreMCActionTypes.PLIValidationSaveSuccess:
        case PreMCActionTypes.PLIValidationSuccess: {
            return {
                ...state,
                certificationStatus: {
                    ...state.certificationStatus,
                    pliValidation: {
                        isLoading: false,
                        entity: action.payload,
                    },
                },
            };
        }
        case PreMCActionTypes.PLIValidationSaveError:
        case PreMCActionTypes.PLIValidationError: {
            return {
                ...state,
                certificationStatus: {
                    ...state.certificationStatus,
                    pliValidation: {
                        ...state.certificationStatus.pliValidation,
                        isLoading: false,
                    },
                },
            };
        }
        case PreMCActionTypes.PLIValidationSaveRequest: {
            return {
                ...state,
                certificationStatus: {
                    ...state.certificationStatus,
                    pliValidation: {
                        ...state.certificationStatus.pliValidation,
                        isLoading: true,
                    },
                },
            };
        }
        case PreMCActionTypes.ChangeValidationRequest: {
            return {
                ...state,
                gooc: action.payload,
                certificationStatus: {
                    ...state.certificationStatus,
                    changeValidation: {
                        ...state.certificationStatus.changeValidation,
                        isLoading: true,
                    },
                },
            };
        }
        case PreMCActionTypes.ChangeValidationSuccess: {
            const entity = {
                ...new ChangeValidationDTO(),
                ...action.payload,
            };
            const total = entity.NotStarted + entity.InProgress + entity.Completed;
            const percentResult = total ? ((entity.Completed / total) * 100).toFixed(2) : 0;
            const percentCompleted = percentResult ? `${percentResult}%` : '0';
            return {
                ...state,
                certificationStatus: {
                    ...state.certificationStatus,
                    changeValidation: {
                        isLoading: false,
                        entity: {
                            ...entity,
                            Total: total,
                            PercentCompleted: percentCompleted,
                        },
                    },
                },
            };
        }
        case PreMCActionTypes.ChangeValidationError: {
            return {
                ...state,
                certificationStatus: {
                    ...state.certificationStatus,
                    changeValidation: {
                        ...state.certificationStatus.changeValidation,
                        isLoading: false,
                    },
                },
            };
        }
        case PreMCActionTypes.AQVDRequestStatusRequest: {
            return {
                ...state,
                gooc: action.payload,
                certificationStatus: {
                    ...state.certificationStatus,
                    aqvdRequestStatus: {
                        ...state.certificationStatus.aqvdRequestStatus,
                        isLoading: true,
                    },
                },
            };
        }
        case PreMCActionTypes.AQVDRequestStatusSuccess: {
            const sortedArray = action.payload.sort((a, b) =>
                a.key === 'Unrequested' || b.key === 'Unrequested' ? -1 : a > b ? 1 : -1
            );
            return {
                ...state,
                certificationStatus: {
                    ...state.certificationStatus,
                    aqvdRequestStatus: {
                        isLoading: false,
                        items: sortedArray,
                        total: _.sumBy(action.payload, (i) => i.value),
                    },
                },
            };
        }
        case PreMCActionTypes.AQVDRequestStatusError: {
            return {
                ...state,
                certificationStatus: {
                    ...state.certificationStatus,
                    aqvdRequestStatus: {
                        ...state.certificationStatus.aqvdRequestStatus,
                        isLoading: false,
                    },
                },
            };
        }
        case PreMCActionTypes.SOCPlanRequest: {
            return {
                ...state,
                gooc: action.payload,
                socPlan: {
                    ...state.socPlan,
                    isLoading: true,
                },
            };
        }
        case PreMCActionTypes.SOCPlanSuccess: {
            return {
                ...state,
                socPlan: {
                    isLoading: false,
                    entity: action.payload,
                },
            };
        }
        case PreMCActionTypes.SOCPlanSaveError:
        case PreMCActionTypes.SOCPlanError: {
            return {
                ...state,
                socPlan: {
                    ...state.socPlan,
                    isLoading: false,
                },
            };
        }
        case PreMCActionTypes.SOCPlanSaveRequest: {
            return {
                ...state,
                socPlan: {
                    ...state.socPlan,
                    isLoading: true,
                },
            };
        }
        case PreMCActionTypes.SOCPlanSaveSuccess: {
            return {
                ...state,
                socPlan: {
                    ...state.socPlan,
                    isLoading: false,
                    entity: action.payload,
                },
            };
        }
        case PreMCActionTypes.PreMCSkylineRequest: {
            return {
                ...state,
                gooc: action.payload,
                preMCSkyline: {
                    ...state.preMCSkyline,
                    isLoading: true,
                },
            };
        }
        case PreMCActionTypes.PreMCSkylineSuccess: {
            const openAqvdsPerWeek = action.payload.map((week) => ({
                week: week.weekStartDate,
                count: _.sumBy(week.subsystems, (x) => x.aqvd),
            }));
            return {
                ...state,
                preMCSkyline: {
                    isLoading: false,
                    items: action.payload.map((week) => week.subsystems),
                    weeks: action.payload.map((week) => ({
                        date: moment(week.weekStartDate),
                        weekType: WeekType.Past,
                    })),
                    openAqvdsPerWeek,
                },
            };
        }
        case PreMCActionTypes.PreMCSkylineError: {
            return {
                ...state,
                preMCSkyline: {
                    ...state.preMCSkyline,
                    isLoading: false,
                },
            };
        }
        case PreMCActionTypes.ScheduleRequest: {
            return {
                ...state,
                gooc: action.payload,
                preMCSchedule: {
                    ...state.preMCSchedule,
                    isLoading: true,
                },
            };
        }
        case PreMCActionTypes.ScheduleSuccess: {
            return {
                ...state,
                preMCSchedule: {
                    isLoading: false,
                    entity: action.payload,
                },
            };
        }
        case PreMCActionTypes.ScheduleSaveError:
        case PreMCActionTypes.ScheduleError: {
            return {
                ...state,
                preMCSchedule: {
                    ...state.preMCSchedule,
                    isLoading: false,
                },
            };
        }
        case PreMCActionTypes.ScheduleSaveRequest: {
            return {
                ...state,
                preMCSchedule: {
                    ...state.preMCSchedule,
                    isLoading: true,
                },
            };
        }
        case PreMCActionTypes.ScheduleSaveSuccess: {
            return {
                ...state,
                preMCSchedule: {
                    ...state.preMCSchedule,
                    isLoading: false,
                    entity: action.payload,
                },
            };
        }
        default:
            return state;
    }
}
