<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            Subsystem comparisons vs. Changes
        </div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')">
                                    cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.changeTypes.value.length" removable>
                                Change Type: {{ filterForm.controls.changeTypes.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('changeTypes')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.cwps.value.length" removable>
                                CWP: {{ displayMultipleSelected(filterForm.controls.cwps.value, 'id') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('cwps')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.number.value" removable>
                                Number: {{ filterForm.controls.number.value }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('number')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.title.value" removable>
                                Title: {{ filterForm.controls.title.value }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('title')">cancel </mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Project Team</mat-label>
                        <mat-select formControlName="projectTeamNames" multiple>
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Change Type</mat-label>
                        <mat-select formControlName="changeTypes" multiple>
                            <mat-option *ngFor="let ct of changeTypes" [value]="ct">{{ ct }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="container300-mh55 margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="cwps"
                            [searchFunc]="getCWPs"
                            [isAsync]="true"
                            placeholder="CWP"
                            [setInput]="setCWPInput"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <mat-form-field class="container300">
                        <mat-label>Discipline</mat-label>
                        <mat-select formControlName="disciplines" multiple>
                            <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mixedapp-container mixedapp-container-last">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Number</mat-label>
                        <input formControlName="number" matInput />
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Title</mat-label>
                        <input formControlName="title" matInput />
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
    </form>
    <div class="results-container">
        <div class="results-title">
            <div class="table-checkboxes">
                <mat-checkbox
                    [disabled]="isManualOverrideDisabled"
                    [checked]="showHideColumns.manualOverrideSubsystems"
                    (change)="showHideColumn('manualOverrideSubsystems')"
                    >Subsystem - SCMT Overwrite
                </mat-checkbox>
                <mat-checkbox
                    [disabled]="isMCPlusDisabled"
                    [checked]="showHideColumns.mcPlusSubsystems"
                    (change)="showHideColumn('mcPlusSubsystems')"
                    >Subsystem - MCPlus Change Table</mat-checkbox
                >
                <mat-checkbox
                    [disabled]="isImportDataSourceDisabled"
                    [checked]="showHideColumns.importDataSourceSubsystems"
                    (change)="showHideColumn('importDataSourceSubsystems')"
                    >Subsystem - Source Database</mat-checkbox
                >
            </div>
        </div>
        <table
            mat-table
            matSort
            [dataSource]="data"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container matColumnDef="type">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">{{ element.type }}</td>
            </ng-container>
            <ng-container matColumnDef="number">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="openValidationView(element.validationId, element.id)">{{ element.number }}</a>
                </td>
            </ng-container>
            <ng-container matColumnDef="manualOverrideSubsystems">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(
                            'manualOverrideSubsystems',
                            getManualOverrideSubsystems,
                            'columnManualOverrideSubsystems',
                            'Subsystem - SCMT Overwrite',
                            columnManualOverrideSubsystems
                        )
                    "
                >
                    <div class="sort-container">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnManualOverrideSubsystems)
                            }"
                            >Subsystem - SCMT Overwrite</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'manualOverrideSubsystems'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.manualOverrideSubsystems }}</td>
            </ng-container>
            <ng-container matColumnDef="mcPlusSubsystems">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(
                            'mcPlusSubsystems',
                            getMCPlusSubsystems,
                            'columnMCPlusSubsystems',
                            'Subsystem - MCPlus Change Table',
                            columnMCPlusSubsystems
                        )
                    "
                >
                    <div class="sort-container">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnMCPlusSubsystems)
                            }"
                            >Subsystem - MCPlus Change Table</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'mcPlusSubsystems'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.mcPlusSubsystems }}</td>
            </ng-container>
            <ng-container matColumnDef="importDataSourceSubsystems">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(
                            'importDataSourceSubsystems',
                            getImportDataSourceSubsystems,
                            'columnImportDataSourceSubsystems',
                            'Subsystem - Source Database',
                            columnImportDataSourceSubsystems
                        )
                    "
                >
                    <div class="sort-container">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnImportDataSourceSubsystems)
                            }"
                            >Subsystem - Source Database</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'importDataSourceSubsystems'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.importDataSourceSubsystems }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>
