import { Action } from '@ngrx/store';
import { ValidationDocument, ValidationDocumentHeader, ValidationDocumentResult } from './model';
import { ValidStatuses } from 'src/app/models/validation-statuses';

export enum ValidationFormActionTypes {
    ValidationDocumentSaveSubsystemsRequest = '[Validation Form] Save Subsystems Request',
    ValidationDocumentSaveSubsystemsSuccess = '[Validation Form] Save Subsystems Success',
    ValidationDocumentSaveSubsystemsError = '[Validation Form] Save Subsystems Error',
    ValidationDocumentRequest = '[Validation Form] Validation Document Request',
    ValidationDocumentSuccess = '[Validation Form] Validation Document Success',
    ValidationDocumentError = '[Validation Form] Validation Document Error',
    ValidationDocumentHeaderRequest = '[Validation Form] Validation Document Header Request',
    ValidationDocumentHeaderSuccess = '[Validation Form] Validation Document Header Success',
    ValidationDocumentHeaderError = '[Validation Form] Validation Document Header Error',
    ValidationUpdateStatusRequest = '[Validation Form] Validation Update Status Request',
    ValidationUpdateStatusSuccess = '[Validation Form] Validation Update Status Success',
    ValidationUpdateStatusError = '[Validation Form] Validation Update Status Error',
    ValidationDocumentClear = '[Validation Form] Validation Document Clear',
    ValidationUpdateRequest = '[Validation Form] Validation Update Request',
    ValidationUpdateSuccess = '[Validation Form] Validation Update Success',
    ValidationUpdateError = '[Validation Form] Validation Update Error',
    ValidationUpdateProperty = '[Validation Form] Validation Update Property',
    ValidationICTUpdateProperty = '[Validation Form] ICT Validation Update Property',
    ValidationSMPUpdateProperty = '[Validation Form] SMP Validation Update Property',
    ValidationDocumentChangesReviewedRequest = '[Validation Form] Validation Document Changes Reviewed Request',
    ValidationDocumentChangesReviewedError = '[Validation Form] Validation Document Changes Reviewed Error',
    ValidationDocumentChangesReviewedSuccess = '[Validation Form] Validation Document Changes Reviewed Success',
    ValidationDocumentChangeIsActiveRequest = '[Validation Form] Validation Document Change IsActive Request',
    ValidationDocumentChangeIsActiveError = '[Validation Form] Validation Document Change IsActive Error',
    ValidationDocumentChangeIsActiveSuccess = '[Validation Form] Validation Document Change IsActive Success',
    ValidationDocumentUpdateIsActiveForDisciplineRequest= '[Validation Form] Validation Document Update IsActive Request For Disciplines',
    ValidationDocumentUpdateIsActiveForDisciplineError = '[Validation Form] Validation Document Update IsActive Request For Disciplines Error',
    ValidationDocumentUpdateIsActiveForDisciplineSuccess = '[Validation Form] Validation Document Update IsActive Request For Disciplines Success',
    ValidationDocumentUpdateSystemizationRemarkRequest = '[Validation Form] Validation Document Update Systemization Remark Update Request Success',
    ValidationDocumentUpdateSystemizationCompletedRequest = '[Validation Form] Validation Document Update Systemization Completed Update Request',
    ValidationDocumentUpdateSystemizationCompletedSuccess = '[Validation Form] Validation Document Update Systemization Completed Update Request Success',
}

export class ValidationDocumentRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentRequest;

    constructor(public payload: number) {
        // This is intentional
    }
}

export class ValidationDocumentSuccess implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentSuccess;

    constructor(public payload: ValidationDocumentResult) {
        // This is intentional
    }
}

export class ValidationDocumentError implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ValidationDocumentHeaderRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentHeaderRequest;

    constructor(public payload: number) {
        // This is intentional
    }
}

export class ValidationDocumentHeaderSuccess implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentHeaderSuccess;

    constructor(public payload: ValidationDocumentHeader) {
        // This is intentional
    }
}

export class ValidationDocumentHeaderError implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentHeaderError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ValidationUpdateStatusRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationUpdateStatusRequest;

    constructor(public payload: { id: number; newStatus: ValidStatuses; type:string }) {
        // This is intentional
    }
}

export class ValidationUpdateStatusSuccess implements Action {
    readonly type = ValidationFormActionTypes.ValidationUpdateStatusSuccess;

    constructor(public payload: ValidationDocumentResult) {
        // This is intentional
    }
}

export class ValidationUpdateStatusError implements Action {
    readonly type = ValidationFormActionTypes.ValidationUpdateStatusError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ValidationDocumentClear implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentClear;
}

export class ValidationUpdateRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationUpdateRequest;

    constructor() {
        // This is intentional
    }
}

export class ValidationUpdateSuccess implements Action {
    readonly type = ValidationFormActionTypes.ValidationUpdateSuccess;

    constructor(public payload: ValidationDocumentResult) {
        // This is intentional
    }
}

export class ValidationUpdateError implements Action {
    readonly type = ValidationFormActionTypes.ValidationUpdateError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ValidationUpdateProperty implements Action {
    readonly type = ValidationFormActionTypes.ValidationUpdateProperty;

    constructor(public payload: { key: string; value: any; updatedProperties: string[] }) {
        // This is intentional
    }
}

export class ValidationICTUpdateProperty implements Action {
    readonly type = ValidationFormActionTypes.ValidationICTUpdateProperty;

    constructor(public payload: { key: string; value: any; updatedProperties: string[] }) {
        // This is intentional
    }
}

export class ValidationSMPUpdateProperty implements Action {
    readonly type = ValidationFormActionTypes.ValidationSMPUpdateProperty;

    constructor(public payload: { key: string; value: any; updatedProperties: string[] }) {
        // This is intentional
    }
}

export class ValidationDocumentChangesReviewedRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentChangesReviewedRequest;

    constructor(public payload: number) {
        // This is intentional
    }
}

export class ValidationDocumentChangesReviewedError implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentChangesReviewedError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ValidationDocumentChangesReviewedSuccess implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentChangesReviewedSuccess;

    constructor(public payload: ValidationDocumentHeader) {
        // This is intentional
    }
}

export class ValidationDocumentChangeIsActiveRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentChangeIsActiveRequest;

    constructor(public payload: number) {
        // This is intentional
    }
}

export class ValidationDocumentChangeIsActiveError implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentChangeIsActiveError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ValidationDocumentChangeIsActiveSuccess implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentChangeIsActiveSuccess;

    constructor(public payload: ValidationDocumentHeader) {
        // This is intentional
    }
}

export class ValidationDocumentUpdateIsActiveForDisciplineRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentUpdateIsActiveForDisciplineRequest;

    constructor(public payload: {id: number, discipline: string}) {
        // This is intentional
    }
}

export class ValidationDocumentUpdateSystemizationRemarkRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentUpdateSystemizationRemarkRequest;

    constructor(public payload: {id: number, remark: string}) {
        // This is intentional
    }
}

export class ValidationDocumentUpdateSystemizationCompletedRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentUpdateSystemizationCompletedRequest;

    constructor(public payload: {id: number, validId: number}) {
        // This is intentional
    }
}

export class ValidationDocumentUpdateSystemizationCompletedSuccess implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentUpdateSystemizationCompletedSuccess;

    constructor(public payload: ValidationDocumentHeader) {
        // This is intentional
    }
}

export class ValidationDocumentUpdateIsActiveForDisciplineError implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentUpdateIsActiveForDisciplineError;

    constructor(public payload: number) {
        // This is intentional
    }
}
export class ValidationDocumentUpdateIsActiveForDisciplineSuccess implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentUpdateIsActiveForDisciplineSuccess;

    constructor(public payload: ValidationDocumentResult) {
        // This is intentional
    }
}
export class ValidationDocumentSaveSubsystemsRequest implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentSaveSubsystemsRequest;

    constructor(public payload: { id: number; subsystems: { id: string }[]; validationId: number }) {
        // This is intentional
    }
}

export class ValidationDocumentSaveSubsystemsSuccess implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentSaveSubsystemsSuccess;

    constructor(public payload: ValidationDocumentHeader) {
        // This is intentional
    }
}

export class ValidationDocumentSaveSubsystemsError implements Action {
    readonly type = ValidationFormActionTypes.ValidationDocumentSaveSubsystemsError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export type ValidationFormAction =
    | ValidationUpdateStatusRequest
    | ValidationUpdateStatusSuccess
    | ValidationUpdateStatusError
    | ValidationDocumentSuccess
    | ValidationDocumentRequest
    | ValidationDocumentError
    | ValidationDocumentClear
    | ValidationUpdateRequest
    | ValidationUpdateSuccess
    | ValidationUpdateError
    | ValidationUpdateProperty
    | ValidationICTUpdateProperty
    | ValidationSMPUpdateProperty
    | ValidationDocumentHeaderSuccess
    | ValidationDocumentHeaderRequest
    | ValidationDocumentHeaderError
    | ValidationDocumentChangesReviewedRequest
    | ValidationDocumentChangesReviewedError
    | ValidationDocumentChangesReviewedSuccess
    | ValidationDocumentChangeIsActiveRequest
    | ValidationDocumentChangeIsActiveError
    | ValidationDocumentChangeIsActiveSuccess
    | ValidationDocumentSaveSubsystemsRequest
    | ValidationDocumentSaveSubsystemsSuccess
    | ValidationDocumentSaveSubsystemsError
    |ValidationDocumentUpdateIsActiveForDisciplineRequest
    |ValidationDocumentUpdateIsActiveForDisciplineError
    |ValidationDocumentUpdateIsActiveForDisciplineSuccess
    |ValidationDocumentUpdateSystemizationRemarkRequest
    |ValidationDocumentUpdateSystemizationCompletedRequest
    |ValidationDocumentUpdateSystemizationCompletedSuccess;

