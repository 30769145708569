import { Component, Inject,EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, takeWhile, finalize, take } from 'rxjs/operators';
import * as moment from 'moment';
import {
    ConstraintSelectionMode,
    ScheduleActivityPlanningConstraint,
} from 'src/app/components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import { BaseComponent } from 'src/app/components/base.component';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { AppRole, UserDetail, ChangeManagementSimple, ContraintContactUserDetail } from 'src/app/store/common.model';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { of } from 'rxjs';
import { ITRConstraintDTO } from 'src/app/models/itr-constraint';
import { ConstraintTeamsDTO, DetailedStatusDTO } from 'src/app/store/detailed-status/model';
import { MsalService } from '@azure/msal-angular';
import { TagKeyDTO } from 'src/app/store/tag-search/model';
import { ProjectTeamService } from 'src/app/services/api/webapi-services/project-team.service';
import * as _ from 'lodash';



@Component({
    selector: 'app-itr-constraint-type-selector',
    templateUrl: './constraint-type-selector.component.html',
    styleUrls: ['./constraint-type-selector.component.scss'],
})
export class ITRConstraintTypeSelectorPopupComponent extends BaseComponent implements OnInit {
    loading: boolean;
    selectionMode: ConstraintSelectionMode;
    constraintTypes: ScheduleActivityPlanningConstraint[] = [];
    filteredConstraintTypes: ScheduleActivityPlanningConstraint[] = [];
    constraintTypeForm: UntypedFormGroup;
    currentUser: string;
    isLoading = false;
    setResponsibleGroupInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAdditionalUsersInput: EventEmitter<SetInputEventArgs> = new EventEmitter();    
    setAssociatedpliInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAssociatedAQVDInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setBarcodeInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    users: UserDetail[] = [];
    allUsers: UserDetail[] = [];
    responsibleGroup: UserDetail[] = [];
    allResponsibleGroup: UserDetail[] = [];
    constraintContactUsers: ContraintContactUserDetail[] = [];
    constraintTeams : ConstraintTeamsDTO[] ;
    changes: ChangeManagementSimple[] = [];
    usersAutocompleteDisplayedColumns = ['name', 'email'];
    usersAutocompleteDisplayedColumnsWithoutEmail = ['name'];
    pliAutocompleteDisplayedColumns = ['tagNo'];
    aqvdAutocompleteDisplayedColumns = ['tagNo'];
    changeAutocompleteDisplayedColumns = ['name', 'numberedName'];
    barcodesAutocompleteDisplayedColumns = ['tagNo'];
    addUserToContractsForm: UntypedFormGroup;
    roleCurrent: AppRole;
    selecteditrid: number;
    selecteditr: DetailedStatusDTO;
    isAdding: false;
    fmRorPO: string[];
    waypointRequired: string[];
    associatedNPW: string[];
    tagNo: TagKeyDTO[];  
    finalPunchItemList: string[];
    displayPliNumber: boolean = false;
    displayAQVDNumber: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<ITRConstraintTypeSelectorPopupComponent>,
        private scheduleActivityLookupService: ScheduleActivityLookupService,
        private lookupService: LookupService,
        private authService: MsalService,
        private projectTeamService: ProjectTeamService,
    ) {
        super();
        this.selecteditrid = data.selecteditrid;
        this.selecteditr = data.selecteditr;
        this.isAdding = data.isadding;

        this.constraintTypeForm = new UntypedFormGroup({ 
            constraintType: new UntypedFormControl('', Validators.required), 
            responsiblepersongroup: new UntypedFormControl(''),
            additionalUsername: new UntypedFormControl(''),
            teamname: new UntypedFormControl(''),
            associatedPLI: new UntypedFormControl(''),
            duedate: new UntypedFormControl(''),
            deleted: new UntypedFormControl(false),
            fmRorPO: new UntypedFormControl(''),
            waypointRequired: new UntypedFormControl(''),
            fmRorPOETADate: new UntypedFormControl(''),
            associatedNPW: new UntypedFormControl(''),
            itrConstraintDescription: new UntypedFormControl(''),
            barcodeNo: new UntypedFormControl('', Validators.required),
            associatedAQVD: new UntypedFormControl(''),
        });
        
    }

    ngOnInit() {
        this.isLoading = true;
        this.currentUser = this.authService.instance.getActiveAccount().name;
        this.getConstraintTeams();        
        this.getAQVDItems();       
        this.scheduleActivityLookupService.searchConstraintTypes(100000)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((data) => {
                this.constraintTypes = data.items;                
                if (!this.isAdding) {
                    this.constraintTypeForm.controls.constraintType.setValue(this.constraintTypes.find((t) => t.name === this.selecteditr.itrConstraintTypeName));
                    this.constraintTypeForm.controls['fmRorPO'].setValue(this.selecteditr.fmRorPO);
                    this.constraintTypeForm.controls['waypointRequired'].setValue(this.selecteditr.waypointRequired);
                    this.constraintTypeForm.controls['itrConstraintDescription'].setValue(this.selecteditr.itrConstraintDescription);

                    if (this.selecteditr.itrConstraintDueDate instanceof moment) {
                        this.constraintTypeForm.controls['duedate'].setValue(this.selecteditr.itrConstraintDueDate.format());
                    } else {
                        this.constraintTypeForm.controls['duedate'].setValue(this.selecteditr.itrConstraintDueDate);
                    }                   
                    try {
                        if (this.selecteditr.fmRorPOETADate instanceof moment) {
                            this.constraintTypeForm.controls['fmRorPOETADate'].setValue(this.selecteditr.fmRorPOETADate.format());
                        } else {
                            this.constraintTypeForm.controls['fmRorPOETADate'].setValue(this.selecteditr.fmRorPOETADate);
                        }
                        this.getTagsForConstraintID().subscribe((data) => {

                            if (data.length > 0) {
                                this.constraintTypeForm.controls['barcodeNo'].setValue([...data]);
                            }                   
                        });
                    } catch { this.constraintTypeForm.controls['fmRorPOETADate'].setValue(null); }
                    
                }
                else {
                    const allowedConstraintTypes = [
                        'DCN / RFI Pending Response', 
                        'NPW , DCN , RFI Pending Implementation (Physical Change)',
                        'MAC',
                        'Open Punch Item',
                        'Open A-QVD',
                        'Materials / Spares',
                        'Area Team Interface']
                    this.constraintTypes = this.constraintTypes.filter(x => allowedConstraintTypes.includes(x.name))
                    let uniqureBarcodes = _.uniqBy(JSON.parse(localStorage.getItem('selectedbarcodes')), (item: TagKeyDTO)=> item.tagNo);
                    this.constraintTypeForm.controls['barcodeNo'].setValue([...uniqureBarcodes]); 
                }
                        
        });

        this.lookupService.searchContraintUsers(0, 5000)
        .pipe(
            take(1)
        )
        .subscribe(
            (data) => {
                this.constraintContactUsers = data;
            }
        )  
        
        this.lookupService.searchUsers('', 0, 5000)
            .pipe(
                take(1)
            )
            .subscribe(
                (data) => {
                    this.users = data;
                    this.allUsers = data;
                    if (!this.isAdding) {
                        let respuser = this.users.find((t) => t.name === this.selecteditr.itrConstraintResponsibleUser);
                        if (respuser != undefined) {
                            this.constraintTypeForm.controls['responsiblepersongroup'].setValue([respuser]);
                        }                        
                        let additionaluser = this.users.find((t) => t.name === this.selecteditr.itrConstraintAdditionalResponsibleUser);
                        if ( additionaluser != undefined) {
                            this.constraintTypeForm.controls['additionalUsername'].setValue([additionaluser]);
                        }
                        
                    }
                   
                }
            )
     this.lookupService.searchResponsibleGroup()
            .pipe(
                take(1)
            )
            .subscribe(
                (data: UserDetail[]) => {
                    this.responsibleGroup = data;
                    this.allResponsibleGroup = data;
                    if (!this.isAdding) {
                        let respuser = this.responsibleGroup.find((t) => t.name === this.selecteditr.itrConstraintResponsibleGroup);
                        if (respuser != undefined) {
                            this.constraintTypeForm.controls['responsiblepersongroup'].setValue([respuser]);
                        }                                                                 
                    }else{
                        if(this.constraintTypeForm.controls.responsiblepersongroup.value.id == null)
                            this.responsibleGroup = [];
                        
                    }                    
                }
            );
                      
            
        this.lookupService.searchIDandNameWithChangeManagementFilter('', 0, 5000)
            .pipe(
                take(1)
            )
            .subscribe(
                (data) => {
                    this.changes = <ChangeManagementSimple[]>data;
                    if (this.isAdding == false) {
                        let npw = this.changes.find((t) => t.name === this.selecteditr.associatedNPW)
                        if ( npw != undefined) {
                            this.constraintTypeForm.controls['associatedNPW'].setValue([npw]);
                        } 
                    }

                }
            )
            
    }
    populateusers(type : number){
        this.displayPliNumber = type === 18 ?  true : false ;
        this.displayAQVDNumber = type === 20 ? true: false;
        let filteredconstraintContactUsers = this.constraintContactUsers.filter((cu)=> cu.constraintTypeId === type)
        this.responsibleGroup = this.allResponsibleGroup.filter(user => {
         return filteredconstraintContactUsers.some(cc => cc.constraintResponsibleGroupId===user.id)
        });    
        this.constraintTypeForm.controls['responsiblepersongroup'].setValue([]);           
        this.setResponsibleGroupInput.emit(new SetInputEventArgs(true));
        this.setAdditionalUsersInput.emit(new SetInputEventArgs(true));
        this.setAssociatedpliInput.emit(new SetInputEventArgs(true));
        this.setAssociatedAQVDInput.emit(new SetInputEventArgs(true));
    }

    save() {
        this.loading = true;
        const form = new ITRConstraintDTO();
        const currentUser = this.currentUser;
        form.itrId = this.selecteditrid;
        form.constraintType = this.constraintTypeForm.controls.constraintType.value.id;
        form.constraintRaisedByTeamId = this.constraintTypeForm.controls.teamname.value.id;
        if(form.constraintType == 18)
        { 
            form.associatedPLI = this.constraintTypeForm.controls.associatedPLI.value[0]?.tagNo;
        }
        if(form.constraintType == 20)
        { 
            form.associatedQVD = this.constraintTypeForm.controls.associatedAQVD.value[0]?.tagNo;
        }
        
        form.constraintTypeName = this.constraintTypeForm.controls.constraintType.value.name;
        form.raisedByUserName = this.isAdding ? currentUser : this.selecteditr.itrConstraintRaisedByUser;
        try {
            form.responsibleGroup = this.constraintTypeForm.controls.responsiblepersongroup.value[0]?.id ?? null;
            form.responsibleUserName = this.constraintTypeForm.controls.responsiblepersongroup.value[0]?.name ?? "";
            form.additionalResponsibleUser = this.constraintTypeForm.controls.additionalUsername.value[0]?.id ?? null;
            form.additionalResponsibleUserName = this.constraintTypeForm.controls.additionalUsername.value[0]?.name ?? "";
            form.associatedNPW = this.constraintTypeForm.controls.associatedNPW.value[0]?.name ?? "";
            form.tagNo = this.constraintTypeForm.controls.barcodeNo.value.map(x => x.id);
            form.barcodes =  this.constraintTypeForm.controls.barcodeNo.value.map(x => x.tagNo);            
            form.associatedNPWChangeType = this.changes.find((t) => t.name === form.associatedNPW).typeName;            
        }
        catch {        
                // This is intentional
        }
        form.dueDate = moment(this.constraintTypeForm.controls.duedate.value).utc();
        form.constraintRaisedDate = moment().utc();
        form.id = this.selecteditr?.itrConstraintId;
        
        form.fmrOrPO = this.constraintTypeForm.controls.fmRorPO.value;
        form.waypointRequired = this.constraintTypeForm.controls.waypointRequired.value;
        form.itrConstraintDescription = this.constraintTypeForm.controls.itrConstraintDescription.value;

        var fmrdate = this.constraintTypeForm.controls.fmRorPOETADate?.value;
        if(fmrdate != null) {
            form.fmRorPOETADate = moment(fmrdate)?.utc();
        } else {
            form.fmRorPOETADate = null;
        }
        
        if (this.constraintTypeForm.controls['deleted'].value) {
            form.deleted = "deleted";
        }

        this.dialogRef.close({ constraintForm: form});
    }

    searchUsers = (search: string, take: number, page: number) => {    
        let filteredconstraintContactUsers = this.constraintContactUsers.filter((cu)=> cu.constraintTypeId === this.constraintTypeForm.controls.constraintType.value.id)
        this.responsibleGroup = this.allResponsibleGroup.filter(user => {
         return filteredconstraintContactUsers.some(cc => cc.constraintResponsibleGroupId===user.id)
        });    
        let elements = this.users.filter(
            (item) =>                
                item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        if (elements.length) {
            elements.forEach((s: any) => {
                s.Selected = false;
                return s;
            });
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    searchITRConstraintResponsibleGroup = (search: string, take: number, page: number) => {    
        let filteredconstraintContactUsers = this.constraintContactUsers.filter((cu)=> cu.constraintTypeId === this.constraintTypeForm.controls.constraintType.value.id)
        this.responsibleGroup = this.allResponsibleGroup.filter(data => {
         return filteredconstraintContactUsers.some(cc => cc.constraintResponsibleGroupId === data.id)
        });    
        let elements = this.responsibleGroup.filter(
            (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        if (elements.length) {
            elements.forEach((s: any) => {
                s.Selected = false;
                return s;
            });
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    searchAdditionalUsers = (search: string, take: number, page: number) => {
        let filteredconstraintContactUsers = this.constraintContactUsers.filter((cu)=> cu.constraintTypeId === this.constraintTypeForm.controls.constraintType.value.id)
        this.users = this.allUsers.filter(user => {
            return filteredconstraintContactUsers.some(cc => cc.additionalResponsibleUserId===user.id)
           });        
        let elements = this.users.filter(
            (item) =>                
                item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        if (elements.length) {
            elements.forEach((s: any) => {
                s.Selected = false;
                return s;
            });
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    getConstraintTeams = () => {
        this.projectTeamService.getProjectSubTeams()
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((data) => {
                this.constraintTeams = data;
             });
    };

    getFinalPunchItems = (search = '',take = 10, page = 0) =>
    {
       return this.lookupService.searchFinalPunchItems(search, take, page).pipe(
            takeWhile(() => this.isAlive),           
        map((data:string[])=> data));
    }

    getAQVDItems = (search = '',take = 10, page = 0) =>
    {
        return this.lookupService.searchAQVDBarcodeBySubsystemItems(search, take, page, this.selecteditrid).pipe(
            takeWhile(() => this.isAlive),           
        map((data:string[])=> data));
      
    }

    searchChanges = (search : string, take: number, page: number) => {
        console.log('search: ', search);
        let elements = this.changes.filter(
            (item) =>                
                item.numberedName.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        if (elements.length) {
            elements.forEach((s: any) => {
                s.Selected = false;
                return s;
            });
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }

    };
    getTags = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchTagsforSubsystem(search, take, page, [], this.selecteditrid).pipe(
            takeWhile(() => this.isAlive),
            map((tags: string[]) => tags
            )
        );
    };  

    getBarcodes = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchBarcodesforConstraints(search, take, page).pipe(
            takeWhile(() => this.isAlive),
            map((barcodes: string[]) => barcodes
            )
        );        
    };
    getTagsForConstraintID = () => {
        return this.lookupService.searchTagsforConstraintID('', [], this.selecteditrid,
                                                             this.selecteditr.itrConstraintId).pipe(
            takeWhile(() => this.isAlive),
            map((tags: string[]) => tags
            )
        )
    };

    onUsersClosed() {
        this.setResponsibleGroupInput.emit(new SetInputEventArgs(true));
        this.setBarcodeInput.emit(new SetInputEventArgs(true));
        this.setAssociatedpliInput.emit(new SetInputEventArgs(true));
        this.setAssociatedAQVDInput.emit(new SetInputEventArgs(true));        
    }   

}
