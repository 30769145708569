<main class="main">
    <form [formGroup]="filterForm">
        <div class="title-container">
            <div class="title">
                <span *ngIf="lockFilters; else changeMgmtTitle">{{ headerText }}</span>
                <ng-template #changeMgmtTitle>Critical RFIs</ng-template>
            </div>
        </div>
        <div class="buttons-container flex">
            <button type="button" class="sct-button sct-button-light margin-right20" (click)="exportToExcel()">
                export to excel
            </button>
        </div>
        <div class="results-container">
            <div class="results-title">
                <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>

                <div class="results-header-actions">
                    <button
                        mat-button
                        [matMenuTriggerFor]="columnsCustomizer"
                        class="results-columns-customizer"
                    >
                        <mat-icon svgIcon="edit"></mat-icon>
                        customize columns
                    </button>
                    <mat-menu #columnsCustomizer class="columns-customizer">
                        <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                        <div class="columns-customizer-list">
                            <mat-checkbox
                                [checked]="showHideColumns.type"
                                (change)="reverseValue('type')"
                                (click)="$event.stopPropagation()"
                                >Type
                            </mat-checkbox>
                            <mat-checkbox
                                [checked]="showHideColumns.designStatus"
                                (change)="reverseValue('designStatus')"
                                (click)="$event.stopPropagation()"
                                >Design Status</mat-checkbox
                            >
                            <mat-checkbox 
                            [checked]="showHideColumns.rfiOriginator" 
                            (change)="reverseValue('rfiOriginator')"
                            (click)="$event.stopPropagation()"
                            >RFI Originator</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.revision"
                                (change)="reverseValue('revision')"
                                (click)="$event.stopPropagation()"
                                >Revision</mat-checkbox
                            >
                            <mat-checkbox                         
                                [checked]="showHideColumns.rfsuActual"
                                (change)="reverseValue('rfsuActual')"
                                (click)="$event.stopPropagation()"
                                >RFSU Actual</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.rfoActual" 
                                (change)="reverseValue('rfoActual')"
                                (click)="$event.stopPropagation()"
                                >RFO Actual</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.nextToSign"
                                (change)="reverseValue('nextToSign')"
                                (click)="$event.stopPropagation()"
                                >Next To Sign</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.systemOwner"
                                (change)="reverseValue('systemOwner')"
                                (click)="$event.stopPropagation()"
                                >System Owner</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.areaBreakDown"
                                (change)="reverseValue('areaBreakDown')"
                                (click)="$event.stopPropagation()"
                                >Area Breakdown</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.vendorName"
                                (change)="reverseValue('vendorName')"
                                (click)="$event.stopPropagation()"
                                >Vendor Name</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.rfsuForecast"
                                (change)="reverseValue('rfsuForecast')"
                                (click)="$event.stopPropagation()"
                                >RFSU Forecast</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.rfoForecast"
                                (change)="reverseValue('rfoForecast')"
                                (click)="$event.stopPropagation()"
                                >RFO Forecast</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.rlmuToGo"
                                (change)="reverseValue('rlmuToGo')"
                                (click)="$event.stopPropagation()"
                                >RLMU To Go</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.expectedTimeOfCompletion"
                                (change)="reverseValue('expectedTimeOfCompletion')"
                                (click)="$event.stopPropagation()"
                                >ETC - Expected Time of Completion</mat-checkbox
                            >                           
                        </div>
                    </mat-menu>
                </div>
            </div>
            <div class="scroll">
                <div class="table-container">
                    <app-mat-table-sticky-header [scrollKey]="'criticalrfi'">
                        <table
                            mat-table
                            matSort
                            [dataSource]="data"
                            [matSortActive]="sortBy"
                            matSortDisableClear
                            [matSortDirection]="direction"
                        >
                            <ng-container matColumnDef="id" sticky>
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        idasfirstcol: !showHideColumns.type
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter('id', getIds, 'columnId', 'ID', columnId)
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnId)
                                            }"
                                            >ID</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'number'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ idasfirstcol: !showHideColumns.type }"
                                >
                                    <a (click)="openDetailsPage(element)">{{ element.number }}</a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="type">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.type
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'type',
                                            getTypes,
                                            'columnChangeType',
                                            'Type',
                                            columnChangeType,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnChangeType)
                                            }"
                                            >Type</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'type'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.type }">
                                    {{ element.type }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="discipline">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.discp
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'discipline',
                                            getDisciplines,
                                            'columnDiscipline',
                                            'Discipline',
                                            columnDiscipline,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnDiscipline)
                                            }"
                                            >Discipline</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.discp }"
                                >
                                    {{ element.discipline }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="title">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.title
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'title',
                                                    getTitles,
                                                    'columnTitle',
                                                    'Title',
                                                    columnTitle
                                                )
                                            "
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnTitle)
                                            }"
                                            >Doc Title</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'title'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.title }"
                                >
                                    {{ element.title }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="subsystem">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.subsystem,
                                        'header-underline': showUnderlineForCheckListColumn(columnSubsystem)
                                    }"
                                >
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'subsystem',
                                                getSubsystemsForColumn,
                                                'columnSubsystem',
                                                'Subsystem',
                                                columnSubsystem,
                                                false,
                                                true,
                                                true
                                            )
                                        "
                                        >Subsystem</span
                                    >
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.subsystem }"
                                >
                                    {{ element.subsystem | breakLine: ',' }}
                                    {{ element.subsystemImpactedNA ? 'N/A' : '' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="walkdownForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.walkdownForecast
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('walkdownForecast', 'columnWalkdownForecast', 'Walkdown Forecast Date', columnWalkdownForecast)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnWalkdownForecast)
                                            }"
                                            >Walkdown Forecast Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'walkdownForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.walkdownForecast }">
                                    {{ element.walkdownForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuPlan">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuPlan
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('rfsuPlan', 'columnRFSUPlan', 'RFSU Plan Date', columnRFSUPlan)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFSUPlan)
                                            }"
                                            >RFSU Plan Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.rfsuPlan }">
                                    {{ element.rfsuPlan | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="RFOActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfoActual
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'RFOActual',
                                            'columnRFOActual',
                                            'RFO Actual',
                                            columnRFOActual
                                        )
                                    "
                                >
                                    <div class="sort-container">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFOActual)
                                            }"
                                            >RFO Actual</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'RFOActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfoActual }"
                                >
                                    {{ element.rfoActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuActual,
                                        'header-underline': showUnderlineForCalendarColumn(columnRFSUActual)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfsuActual',
                                            'columnRFSUActual',
                                            'RFSU Actual',
                                            columnRFSUActual
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span>RFSU Actual</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuActual                                     
                                    }"
                                >
                                    {{ element.rfsuActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuForecast
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('rfsuForecast', 'columnRFSUForecast', 'RFSU Forecast Date', columnRFSUForecast)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFSUForecast)
                                            }"
                                            >RFSU Forecast Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.rfsuForecast }">
                                    {{ element.rfsuForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfoForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfoForecast
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('rfoForecast', 'columnRFOForecast', 'RFO Forecast Date', columnRFOForecast)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFOForecast)
                                            }"
                                            >RFO Forecast Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfoForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.rfoForecast }">
                                    {{ element.rfoForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfo">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfo,
                                        'header-underline': showUnderlineForCheckListColumn(columnRfo)
                                    }"
                                >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'rfo',
                                                getRfos,
                                                'columnRfo',
                                                'RFO',
                                                columnRfo,
                                                false,
                                                true,
                                                true
                                            )
                                        "
                                        >RFO</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'rfo'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfo }"
                                >
                                    {{ element.rfo | breakLine: ',' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="designStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.designStatus
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'designStatus',
                                            getStatuses,
                                            'columnDesignStatus',
                                            'Design Status',
                                            columnDesignStatus,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnDesignStatus)
                                            }"
                                            >Design Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'designStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.designStatus }"
                                >
                                    {{ element.designStatus }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="stepStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.stepStatus
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'currentStep',
                                            getStepStatuses,
                                            'columnStepStatus',
                                            'Step Status',
                                            columnStepStatus,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnStepStatus)
                                            }"
                                            >Current Step Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'currentStep'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.stepStatus }"
                                >
                                    {{ element.currentStep }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="responseDuration">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.responseDuration
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'responseDuration',
                                            getresponseDuration,
                                            'columnResponseDuration',
                                            'Not Responded Duration',
                                            columnResponseDuration,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnResponseDuration)
                                            }"
                                            >RFI Not Responded Day(s)</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'responseDuration'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.responseDuration }"
                                >
                                    {{ element.responseDuration }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="isVendorClarification">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.isVendorClarification
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'isVendorClarification',
                                            getisVendorClarification,
                                            'columnisVendorClarification',
                                            'Vendor Clarification',
                                            columnisVendorClarification,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnisVendorClarification)
                                            }"
                                            >Is Vendor Clarification</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'isVendorClarification'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.isVendorClarification }"
                                >
                                    {{ element.isVendorClarification }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="vendorName">
                                <th
                                    mat-header-cell
                                    app-col-resize
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.vendorName
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'vendorName',
                                            getVendorName,
                                            'columnVendorName',
                                            'Vendor Name',
                                            columnVendorName,
                                            false,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnVendorName)
                                            }"
                                            >Vendor Name</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'vendorName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.vendorName }"
                                >
                                    {{ element.vendorName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="criticalPath">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.criticalPath
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'criticalPath',
                                            getCriticalPath,
                                            'columnCriticalPath',
                                            'Critical Path',
                                            columnCriticalPath,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnCriticalPath)
                                            }"
                                            >Critical Path</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'criticalPath'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.criticalPath }"
                                >
                                    {{ element.criticalPath }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tiComment">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.tiComment }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnTiComments)
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'tiComment',
                                                    gettiComments,
                                                    'columnTiComments',
                                                    'Comment',
                                                    columnTiComments,
                                                    false,
                                                    true,
                                                    true
                                                )
                                            "
                                            >Comments by SC</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'tiComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.tiComment }"
                                    [ngStyle]="{ 'max-width': showHideColumns.title ? '468px' : '' }"
                                >
                                    <div (click)="openCommentPopup(element, $event, 'ticomment')">
                                        <div *ngIf="element.tiComment && !isReadOnly; else notShow">
                                            <a
                                                ><span
                                                    [innerHTML]="element.tiComment | formatMentions: element.mentions"
                                                ></span
                                            ></a>
                                        </div>
                                        <div *ngIf="isReadOnly">
                                            <span
                                                [innerHTML]="element.tiComment | formatMentions: element.mentions"
                                            ></span>
                                        </div>
                                        <ng-template #notShow>
                                            <button
                                                type="button"
                                                *ngIf="!isReadOnly"
                                                mat-stroked-button
                                                class="add-comment"
                                            >
                                                Add
                                            </button>
                                        </ng-template>
                                    </div>
                                    <div
                                        [ngClass]="{ notactive: !element.tiComment, active: element.tiComment }"
                                        class="comment-hist"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            (click)="openHistoryPopup(element,'ticomment')"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                            />
                                        </svg>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="kpjvComment">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.kpjvComment }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnKpjvComments)
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'kpjvComment',
                                                    getkpjvComments,
                                                    'columnKpjvComments',
                                                    'Comment',
                                                    columnKpjvComments,
                                                    false,
                                                    true,
                                                    true
                                                )
                                            "
                                            >Comments by Eng Teams</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'kpjvComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.kpjvComment }"
                                    [ngStyle]="{ 'max-width': showHideColumns.title ? '468px' : '' }"
                                >
                                    <div (click)="openCommentPopup(element, $event,'kpjvcomment')">
                                        <div *ngIf="element.kpjvComment && !isReadOnly; else notShow">
                                            <a
                                                ><span
                                                    [innerHTML]="element.kpjvComment | formatMentions: element.mentions"
                                                ></span
                                            ></a>
                                        </div>
                                        <div *ngIf="isReadOnly">
                                            <span
                                                [innerHTML]="element.kpjvComment | formatMentions: element.mentions"
                                            ></span>
                                        </div>
                                        <ng-template #notShow>
                                            <button
                                                type="button"
                                                *ngIf="!isReadOnly"
                                                mat-stroked-button
                                                class="add-comment"
                                            >
                                                Add
                                            </button>
                                        </ng-template>
                                    </div>
                                    <div
                                        [ngClass]="{ notactive: !element.kpjvComment, active: element.kpjvComment }"
                                        class="comment-hist"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            (click)="openHistoryPopup(element,'kpjvcomment')"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                            />
                                        </svg>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="rlmuToGo">
                                <th mat-header-cell disableClear *matHeaderCellDef [ngClass]="{
                                                                hidecells: !showHideColumns.rlmuToGo,
                                                                'header-filtered': showUnderlineForNumericColumn(columnRlmuToGo)
                                                            }">
                                    <div class="sort-container center">
                                        <span class="hover-pointer" (click)="openHeaderNumericFilter('rlmuToGo', columnRlmuToGo, 'RLMU To Go')">RLMU
                                            To Go</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'rlmuToGo'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.rlmuToGo }">{{ row.rlmuToGo }}</td>
                            </ng-container>

                            <ng-container matColumnDef="nextToSign">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.nextToSign
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'nextToSign',
                                            getNextToSign,
                                            'columnNextToSign',
                                            'Next To Sign',
                                            columnNextToSign,
                                            false,
                                            false
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnNextToSign)
                                            }"
                                            >Next To Sign</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'nextToSign'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.nextToSign }"
                                    *matCellDef="let element"
                                >
                                    {{ element.nextToSign }}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="priority">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.priority
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'priority',
                                            getPriorities,
                                            'columnPriority',
                                            'Priority',
                                            columnPriority,                                       
                                            true,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPriority)
                                            }"
                                            >Priority - Automated</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'Priority'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.priority }"
                                    *matCellDef="let element"
                                >
                                    {{ element.priority }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="priorityManualOverride">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.priorityManualOverride
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'priorityManualOverride',
                                            getManualPriorities,
                                            'columnPriorityManualOverride',
                                            'Priority - Manual Override',
                                            columnPriorityManualOverride,
                                            true,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPriorityManualOverride)
                                            }"
                                            >Priority - Manual Override</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'PriorityManualOverride'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <span *ngIf="!manualPriorityUpdateAccess()">
                                    <td 
                                        mat-cell
                                        [ngClass]="{ hidecells: !showHideColumns.priorityManualOverride }"
                                        *matCellDef="let element"
                                    >
                                        {{ element.priorityManualOverride }}
                                    </td>
                                </span>
                                <span *ngIf="manualPriorityUpdateAccess()">
                                    <td 
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngClass]="{ hidecells: !showHideColumns.priorityManualOverride }"
                                        (contextmenu)="priorityUpdateHistory($event,element.id)"
                                    >                                    
                                        <mat-select                               
                                        matNativeControl
                                        [value]="element.priorityManualOverride" 
                                        (selectionChange)="updateManualPriority($event, cloneElement(element))"                                
                                        >
                                            <mat-option *ngFor="let manualPriority of Manualpriorities" [value]="manualPriority">
                                                {{ manualPriority }}
                                            </mat-option>
                                        </mat-select>
                                    </td>
                                </span>
                            </ng-container>
                            <ng-container matColumnDef="priorityFinal">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.priorityFinal
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'priorityFinal',
                                            getFinalPriorities,
                                            'columnPriorityFinal',
                                            'Priority - Final',
                                            columnPriorityFinal,
                                            true,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPriorityFinal)
                                            }"
                                            >Priority - Final</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'PriorityFinal'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.priorityFinal }"
                                    *matCellDef="let element"
                                >
                                    {{ element.priorityFinal }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="subPriority">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.subPriority
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subPriority',
                                            getSubPriorities,
                                            'columnSubPriority',
                                            'Sub-Priority',
                                            columnSubPriority,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnSubPriority)
                                            }"
                                            >Sub-Priority</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'subPriority'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <span *ngIf="isReadOnly">
                                    <td
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngClass]="{ hidecells: !showHideColumns.subPriority }"
                                        >
                                    {{ element.subPriority }}
                                    </td>
                                </span>
                                <span *ngIf="!isReadOnly">
                                    <td
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngClass]="{ hidecells: !showHideColumns.subPriority }"                                        
                                        (click)="stopeventProp($event)"
                                    >                                    
                                        <mat-select                               
                                        matNativeControl
                                        [value]="element.subPriority" 
                                        (selectionChange)="updateSubPriority($event, cloneElement(element))"
                                        >
                                            <mat-option *ngFor="let subPriority of SubPriorities" [value]="subPriority">
                                                {{ subPriority }}
                                            </mat-option>                                            
                                        </mat-select>                                    
                                    </td>
                                </span> 
                            </ng-container>
                            <ng-container matColumnDef="revision">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.revision
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'revision',
                                            getRevisions,
                                            'columnRevision',
                                            'Revision',
                                            columnRevision,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRevision)
                                            }"
                                            >Revision</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'Revision'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.revision }"
                                    *matCellDef="let element"
                                >
                                    {{ element.revision }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfiOriginator">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfiOriginator
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'originatingPersonName',
                                            getRFIOriginators,
                                            'columnRFIOriginator',
                                            'RFI Originator',
                                            columnRFIOriginator,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRFIOriginator)
                                            }"
                                            >RFI Originator</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'originatingPersonName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.rfiOriginator }"
                                    *matCellDef="let element"
                                >
                                    {{ element.originatingPersonName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="systemOwner">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.systemOwner
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'systemOwner',
                                            getSystemOwners,
                                            'columnSystemOwner',
                                            'SystemOwner',
                                            columnSystemOwner,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnSystemOwner)
                                            }"
                                            >System Owner</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'systemOwner'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.systemOwner }"
                                    *matCellDef="let element"
                                >
                                    {{ element.systemOwner }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="areaBreakDown">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.areaBreakDown
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'areaBreakDown',
                                            getAreaBreakDown,
                                            'columnAreaBreakDown',
                                            'AreaBreakDown',
                                            columnAreaBreakDown,
                                            false,
                                            true,
                                            true
                                        )
                                    "
                                >
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAreaBreakDown)
                                            }"
                                            >Area Breakdown</span
                                        >
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.areaBreakDown }"
                                    *matCellDef="let element"
                                >
                                    {{ element.areaBreakDown }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="expectedTimeOfCompletion">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.expectedTimeOfCompletion,
                                        'header-filtered': showUnderlineForCalendarColumn(columnExpectedTimeOfCompletion)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                    openHeaderDateFilter(
                                        'expectedTimeOfCompletion',
                                        'columnExpectedTimeOfCompletion',
                                        'ETC - Expected Time of Completion',
                                        columnExpectedTimeOfCompletion
                                    )
                                "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnExpectedTimeOfCompletion)
                                            }"
                                            >ETC - Expected Time of Completion</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'expectedTimeOfCompletion'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    (click)="openCriticalRFIExpectedTimeOfCompletionDatepicker($event,element, 'expectedTimeOfCompletion')"
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    (contextmenu)="expectedTimeofCompletionHistory($event,element.id)"
                                    [ngClass]="{
                                        'cursor-pointer':
                                            isAdmin || isCriticalRFIExpectedTimeOfCompletionUpdateAllowed,
                                        'row-underline': element.expectedTimeOfCompletion != null ? true : false,
                                        hidecells: !showHideColumns.expectedTimeOfCompletion
                                    }"
                                >
                                    {{ element.expectedTimeOfCompletion != null ? (element.expectedTimeOfCompletion | date: 'd/MMM/yy') : "TBD" }}
                                </td>
                            </ng-container> 

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                    </app-mat-table-sticky-header>
                </div>
            </div>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 50]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    min="1"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>
