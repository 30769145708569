import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    ScheduleActivityActionTypes,
    ScheduleActivityUploadLogSuccess,
    ScheduleActivityUploadLogError,
    ScheduleActivityValidateButtonStateSuccess,
    ScheduleActivityValidateButtonStateError,
    ScheduleActivityDownloadOutputDataSuccess,
    ScheduleActivityDownloadOutputDataError,
    ScheduleActivityDownloadOutputDataRequest,
    ScheduleActivityTemplateFileSuccess,
    ScheduleActivityTemplateFileRequest,
    ScheduleActivityTemplateFileError,
    ScheduleActivityClearInProgressSpinner,
    ScheduleActivityValidateDeleteSuccess,
    ScheduleActivityValidateDeleteError,
    ScheduleActivityPatchAllDeleteRequest,
    ScheduleActivityPatchAllDeleteSuccess,
    ScheduleActivityPatchAllDeleteError,
    ScheduleActivityPatchDeleteRequest,
    ScheduleActivityPatchDeleteSuccess,
    ScheduleActivityPatchDeleteError,
    ScheduleActivityDeleteSuccess,
    ScheduleActivityDeleteError,
    ScheduleActivityDeleteRequest,
    ScheduleActivitySetStatusAndStartDate,
} from './actions';

@Injectable()
export class ScheduleActivityEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(ScheduleActivityActionTypes.ScheduleActivityUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['ScheduleActivity']).pipe(
                map((uploadLogData) => new ScheduleActivityUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new ScheduleActivityUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<ScheduleActivityUploadLogSuccess>(ScheduleActivityActionTypes.ScheduleActivityUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.scheduleActivityState.uploadLogStatus),
            this.store.select((store) => store.scheduleActivityState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new ScheduleActivitySetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    invokeDeleteRequest$ = this.actions$.pipe(
        ofType(ScheduleActivityActionTypes.ScheduleActivitySetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.scheduleActivityState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        map(() => new ScheduleActivityDeleteRequest())
    );

    @Effect()
    getDelete$ = this.actions$.pipe(
        ofType(ScheduleActivityActionTypes.ScheduleActivityDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.scheduleActivityState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getScheduleActivityDeleteRecords(deleteFilter).pipe(
                map((deleteData: any) => new ScheduleActivityDeleteSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete data records. Please contact Program Administrator'
                    );
                    return of(new ScheduleActivityDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteData$ = this.actions$.pipe(
        ofType<ScheduleActivityPatchDeleteRequest>(ScheduleActivityActionTypes.ScheduleActivityPatchDeleteRecordRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchScheduleActivityDeleteRecord(
                action.payload.activityId, action.payload.activityDiscipline, action.payload.deleteState
                ).pipe(
                map(() => new ScheduleActivityPatchDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ScheduleActivityPatchDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchAllDelete$ = this.actions$.pipe(
        ofType<ScheduleActivityPatchAllDeleteRequest>(ScheduleActivityActionTypes.ScheduleActivityPatchAllDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.scheduleActivityState.deleteFilter)),
        mergeMap(([action, deleteFilter]) =>
            this.manualUploadsService.patchAllScheduleActivityDeleteRecords(deleteFilter, action.payload.deleteState).pipe(
                map(() => new ScheduleActivityPatchAllDeleteSuccess({ deleteState: action.payload.deleteState })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ScheduleActivityPatchAllDeleteError(error));
                })
            )
        )
    );

    @Effect()
    validateDelete$ = this.actions$.pipe(
        ofType(ScheduleActivityActionTypes.ScheduleActivityValidateDataRequest),
        mergeMap(() =>
            this.manualUploadsService.validateScheduleActivityData().pipe(
                map(() => new ScheduleActivityValidateDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new ScheduleActivityValidateDeleteError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(ScheduleActivityActionTypes.ScheduleActivityDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.scheduleActivityState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new ScheduleActivityClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<ScheduleActivityTemplateFileRequest>(ScheduleActivityActionTypes.ScheduleActivityTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new ScheduleActivityTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Schedule Activity structure file. Please contact Program Administrator'
                    );
                    return of(new ScheduleActivityTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<ScheduleActivityTemplateFileSuccess>(ScheduleActivityActionTypes.ScheduleActivityTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'L4 (Live) Schedule_template.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<ScheduleActivityDownloadOutputDataRequest>(ScheduleActivityActionTypes.ScheduleActivityDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new ScheduleActivityDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new ScheduleActivityDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<ScheduleActivityDownloadOutputDataSuccess>(ScheduleActivityActionTypes.ScheduleActivityDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(ScheduleActivityActionTypes.ScheduleActivityDeleteRecordsSuccess,
            ScheduleActivityActionTypes.ScheduleActivityPatchAllDeleteRecordsSuccess,
            ScheduleActivityActionTypes.ScheduleActivityPatchDeleteRecordSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('ScheduleActivity').pipe(
                map((response: any) => new ScheduleActivityValidateButtonStateSuccess(response)),
                catchError((error) => of(new ScheduleActivityValidateButtonStateError(error)))
            )
        )
    );
}
