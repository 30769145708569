import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { take, takeWhile } from 'rxjs/operators';
import { ManualUploadSetActivatedRoute } from 'src/app/store/manual-upload/actions';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { PriorityNumbersUploadLogRequest, PriorityNumbersDownloadOutputDataRequest } from 'src/app/store/automatic-upload/priority-numbers/actions';

@Component({
    selector: 'app-priority-numbers',
    templateUrl: './priority-numbers.component.html',
    styleUrls: ['./priority-numbers.component.scss'],
})
export class PriorityNumbersComponent extends BaseComponent implements OnInit {
    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    uploadLogData$ = this.store.select((store) => store.priorityNumbersState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.priorityNumbersState.isUploadLogLoading);
    isLoading$ = this.store.select((store) => store.priorityNumbersState.isLoading);
    interval$ = this.store.select((store) => store.manualUploadState.interval);
    intervalSub: Subscription = null;
    showOrder = true;

    constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new ManualUploadSetActivatedRoute(urlSegments[0].path));
        });

        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new PriorityNumbersUploadLogRequest());
            });
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));
    }
    downloadData(filePath: string) {
        this.store.dispatch(new PriorityNumbersDownloadOutputDataRequest(filePath));
    }
}
