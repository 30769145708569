import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { appConfig } from 'src/app/app.config';
import { DisciplineMappingDTO } from "src/app/models/discipline-mapping-dto";

@Injectable({
    providedIn: 'root',
})
export class DisciplineMappingService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/disciplinemapping';
    }

    get(uploadType: string) {
        return this.http.get<DisciplineMappingDTO[]>(`${this.rootUrl}${this.endpoint}?uploadType=${uploadType}`);
    }

    remove(id: number) {
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?id=${id}`);
    }

    update(disciplineMapping: DisciplineMappingDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, disciplineMapping);
    }

    add(discipline: DisciplineMappingDTO) {
        return this.http.post<DisciplineMappingDTO>(`${this.rootUrl}${this.endpoint}`, discipline);
    }
}