<mat-checkbox *ngIf="dateType === 'mcWalkdown' || dateType === 'rfsuWalkdown'" [(ngModel)]="allDisciplinesChecked" class="margin-right20" [disabled]="!discipline"
    >Apply for all Disciplines</mat-checkbox
>
<button mat-button class="save" (click)="saveDate()">
    <mat-icon>done</mat-icon>
    Save
</button>

<button mat-button (click)="clearDate()">
    <mat-icon>clear</mat-icon>
    Clear date
</button>

<button mat-button (click)="cancel()">
    Cancel
</button>

<mat-calendar #calendar [(selected)]="selectedDate" [minDate]="minDate" [maxDate]="maxDate"></mat-calendar>
<mat-checkbox *ngIf="dateType === 'mcWalkdown' || dateType === 'rfsuWalkdown'" [(ngModel)]="adjustMCForecastChecked" class="adjmcforcast"
    >Adjust {{dateType === 'mcWalkdown' ? 'MC' : 'RFSU'}} Forecast = Walkdown +
    <mat-form-field class="adjust-input" appearance="legacy">
        <input matInput type="number" min="0" [(ngModel)]="adjustDays" [disabled]="!adjustMCForecastChecked" />
    </mat-form-field>
    days</mat-checkbox
>
