<div class="results-container">
    <div class="scroll">
        <ng-template #activityAction>
            <div class="activity-actions">
                <button class="activityActionButton" (click)="updateIsConstrained()">
                    {{ isSelectedRowConstrained ? 'Activity Unconstrained' : 'Activity Constrained' }}
                </button>
                <button class="activityActionButton" (click)="addNewActivity()">'Add new Activity'</button>
            </div>
        </ng-template>

        <popover-content #activityActionPopover [placement]="PopoverPlacement.Top" [closeOnClickOutside]="true">
            <ng-content *ngTemplateOutlet="activityAction"> </ng-content>
        </popover-content>
        <table mat-table *ngIf="showActivities" [dataSource]="activitiesDataSource">
            <ng-container matColumnDef="activityId">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="{
                        hidecells: !showHideColumns.showActivityId
                    }"
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(getActivityIds, 'columnActivityIds', 'Activity ID', columnActivityIds)
                    "
                >
                    Activity ID
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="{
                        hidecells: !showHideColumns.showActivityId
                    }"
                >
                    {{ row.activityId }}
                </td>
            </ng-container>

            <ng-container matColumnDef="activityName" sticky>
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(
                            getActivityNames,
                            'columnActivityNames',
                            'Activity Name',
                            columnActivityNames
                        )
                    "
                >
                    Activity Name
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [popover]="activityActionPopover"
                    [popoverOnHover]="false"
                    (click)="highlightRow(row)"
                >
                    <span>
                        {{ row.activity }}
                    </span>
                    <mat-icon *ngIf="row.isConstrained">warning</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="activityDiscipline" sticky>
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(getDisciplines, 'columnDisciplines', 'discipline', columnDisciplines)
                    "
                >
                    Discipline
                </th>
                <td mat-cell *matCellDef="let row">{{ row.activityDiscipline }}</td>
            </ng-container>
            <ng-container matColumnDef="constraintType">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="{
                        hidecells: !showHideColumns.showConstraintType
                    }"
                >
                    Constraint Type
                </th>
                <td
                    mat-cell
                    [ngClass]="{
                        hidecells: !showHideColumns.showConstraintType
                    }"
                    *matCellDef="let row"
                >
                    {{ row.constraintType }}
                </td>
            </ng-container>
            <ng-container matColumnDef="planStart">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="{
                        hidecells: !showHideColumns.showPlanDates
                    }"
                    class="hover-pointer"
                    (click)="openHeaderDateFilter('columnPlanStart', 'Plan Start', columnPlanStart)"
                >
                    Plan Start
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="{
                        hidecells: !showHideColumns.showPlanDates
                    }"
                >
                    {{ row.planStart | date: 'd/MMM/yy' }}&nbsp;{{ getStartDateActivityStatusSuffix(row) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="planFinish">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="openHeaderDateFilter('columnPlanFinish', 'Plan Finish', columnPlanFinish)"
                >
                    Plan Finish
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.planFinish | date: 'd/MMM/yy' }}&nbsp;{{ getFinishDateActivityStatusSuffix(row) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="forecastStart">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="{
                        hidecells: !showHideColumns.showForecastDates
                    }"
                    class="hover-pointer"
                    (click)="openHeaderDateFilter('columnForecastStart', 'Forecast Start', columnForecastStart)"
                >
                    Forecast Start
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="{
                        hidecells: !showHideColumns.showForecastDates,
                        'cursor-pointer': row.activityId && row.forecastStart
                    }"
                    (click)="row.activityId && openDatepicker(row, 'forecastStart')"
                >
                    {{ row.forecastStart | date: 'd/MMM/yy' }}
                    <button *ngIf="isAdmin && row.activityId && !row.forecastStart" type="button" mat-icon-button>
                        <mat-icon>calendar_today</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="forecastFinish">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="{ hidecells: !showHideColumns.showForecastDates }"
                    class="hover-pointer"
                    (click)="openHeaderDateFilter('columnForecastFinish', 'Forecast Finish', columnForecastFinish)"
                >
                    Forecast Finish
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="{
                        hidecells: !showHideColumns.showForecastDates,
                        'cursor-pointer': row.activityId && row.forecastFinish
                    }"
                    (click)="row.activityId && openDatepicker(row, 'forecastFinish')"
                >
                    {{ row.forecastFinish | date: 'd/MMM/yy' }}
                    <button *ngIf="isAdmin && row.activityId && !row.forecastFinish" type="button" mat-icon-button>
                        <mat-icon>calendar_today</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="itrToGo">
                <th mat-header-cell *matHeaderCellDef>ITR To Go</th>
                <td mat-cell *matCellDef="let row">
                    <span class="cursor-pointer">
                        <a (click)="showITRs(itrScopes.ToGo, row.activityId, row.activityDiscipline, false)">
                            {{ row.itrToGo }}
                        </a></span
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="comment" stickyEnd>
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="openHeaderCheckListFilter(
                        getComments,
                        'columnComments',
                        'comment',
                        columnComments,
                        false,
                        true,
                        true
                    )"
                >
                    Comments
                </th>
                <td mat-cell *matCellDef="let row; let rowIndex = index">
                    <div *ngIf="row.activityId" (click)="openCommentPopup(row, $event)" class="comment-description">
                        <div *ngIf="row.fourWeeksComment; else notShow">
                            <a> <span [innerHTML]="row.fourWeeksComment"></span></a>
                        </div>
                        <ng-template #notShow>
                            <button
                                (click)="openCommentPopup(row, $event)"
                                type="button"
                                mat-stroked-button
                                class="add-comment"
                            >
                                Add
                            </button>
                        </ng-template>
                    </div>
                </td>
            </ng-container>

            <ng-container
                class="weekColumn"
                *ngFor="let weekDate of subsystemWithActivities.activities[0].weekColumns; let i = index"
                matColumnDef="{{ weekDate.columnId }}"
                stickyEnd
            >
                <th class="weekColumn" mat-header-cell *matHeaderCellDef>
                    <div class="weekColumnHeader">{{ weekDate.week | date: 'd-MMM' }}</div>
                </th>
                <td
                    class="weekColumn"
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="{ inWeek: row.activityId && inWeek(row, weekDate.columnId) }"
                >
                    <div [ngClass]="{ inWeekDiv: row.activityId && inWeek(row, weekDate.columnId) }"></div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [ngClass]="{ selected: selectedRow === row }"
                (click)="highlightRow(row)"
            ></tr>
        </table>
    </div>
    <app-loading-indicator *ngIf="subsystemWithActivities.isLoading"></app-loading-indicator>
</div>
