import { OrderDirection } from '../../../common.model';
import { ProjectTeamRoleDTO } from 'src/app/models/project-team-role-dto';

export class ProjectTeamRoleFilter {
    page = 0;
    take = 5;
    sortBy = 'projectTeamName';
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    roles: number[] = [];
}

export interface ProjectTeamRoleMaintenanceState {
    projectTeamRoleDataPagination: ProjectTeamRolesDataPagination;
    filter: ProjectTeamRoleFilter;
    isLoading: boolean;
}

export class ProjectTeamRolesDataPagination {
    items: ProjectTeamRoleDTO[] = [];
    totalCount = 0;
}
