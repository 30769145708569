import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import { take, takeWhile } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import {
    WeeklyPlanningSetStartDate,
    WeeklyPlanningSetProjectTeam,
    WeeklyPlanningFilterRequest,
} from 'src/app/store/weekly-planning/actions';
import { getCurrentWeekStartDate } from 'src/app/extensions';

@Component({
    selector: 'app-weekly-planning-session',
    templateUrl: './weekly-planning-session.component.html',
    styleUrls: ['./weekly-planning-session.component.scss'],
})
export class WeeklyPlanningSessionComponent extends BaseComponent implements OnInit {
    projectTeamName = '';
    navLinks: { label: string; link: string; index: number }[];
    currentWeekStartDate: moment.Moment;
    weekOptions: { startDate: moment.Moment; endDate: moment.Moment }[] = [];
    selectedWeekStartDate: UntypedFormControl;
    selectedWeekEndDate: Date;
    isWeekSelectorVisible = false;
    planningTableIndex = [0, 1, 2, 3, 4];
    isFilterVisible$ = this.store.select((store) => {
        const currentWeek = getCurrentWeekStartDate();
        return store.weeklyPlanningState.filter.startDate.isSameOrAfter(currentWeek);
    });
    startDate$ = this.store.select((store) => store.weeklyPlanningState.filter.startDate);

    @ViewChild('weekSelector') weekSelector: MatSelect;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private store: Store<ApplicationState>
    ) {
        super();
        this.navLinks = [
            // {
            //     label: 'mc planning',
            //     link: './mc',
            //     index: 0,
            // },
            {
                label: 'rfsu planning',
                link: './rfsu',
                index: 0,
            },
            {
                label: 'rfo planning',
                link: './rfo',
                index: 1,
            },
        ];
        this.currentWeekStartDate = getCurrentWeekStartDate();
        this.selectedWeekStartDate = new UntypedFormControl(this.currentWeekStartDate);
        this.selectedWeekEndDate = moment(this.selectedWeekStartDate.value).add(6, 'days').toDate();
        this.setWeekOptions();
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.pipe(take(1)).subscribe((paramMap) => {
            this.projectTeamName = paramMap.get('projectTeamName').toUpperCase();
            this.store.dispatch(new WeeklyPlanningSetProjectTeam(this.projectTeamName));
        });

        this.selectedWeekStartDate.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
            this.store.dispatch(new WeeklyPlanningSetStartDate(value));
            this.store.dispatch(new WeeklyPlanningFilterRequest(this.planningTableIndex));
            this.selectedWeekEndDate = moment(this.selectedWeekStartDate.value).add(6, 'days').toDate();
        });

        this.startDate$.pipe(take(1)).subscribe((startDate) => {
            this.selectedWeekStartDate.patchValue(startDate, { emitEvent: false });
            this.selectedWeekEndDate = moment(this.selectedWeekStartDate.value).add(6, 'days').toDate();
        });
    }

    compareDates(d1: moment.Moment, d2: moment.Moment) {
        return d1.isSame(d2, 'day');
    }

    showWeekSelector() {
        this.isWeekSelectorVisible = true;
        setTimeout(() => {
            this.weekSelector.open();
        }, 0);
    }

    onWeekSelectorClosed(isOpen: boolean) {
        this.isWeekSelectorVisible = isOpen;
    }

    setWeekOptionClasses(week: { startDate: moment.Moment; endDate: moment.Moment }) {
        return {
            'current-week': week.startDate.isSame(this.currentWeekStartDate, 'day'),
            'past-week': week.startDate.isBefore(this.currentWeekStartDate, 'day'),
            'future-week': week.startDate.isAfter(this.currentWeekStartDate, 'day'),
        };
    }

    reviewCommitments() {
        this.router.navigate([
            'weeklyplanning/commitments',
            this.projectTeamName,
            this.activatedRoute.firstChild.routeConfig.path,
        ]);
    }

    private setWeekOptions() {
        const currentWeek = {
            startDate: moment(this.currentWeekStartDate),
            endDate: moment(this.currentWeekStartDate).add(6, 'days'),
        };
        this.weekOptions.push(currentWeek);
        for (let index = 1; index <= 6; index++) {
            this.weekOptions.push({
                startDate: moment(currentWeek.startDate).add(index, 'weeks'),
                endDate: moment(currentWeek.endDate).add(index, 'weeks'),
            });
            this.weekOptions.push({
                startDate: moment(currentWeek.startDate).add(-index, 'weeks'),
                endDate: moment(currentWeek.endDate).add(-index, 'weeks'),
            });
        }
        this.weekOptions = _.sortBy(this.weekOptions, ['startDate']);
    }
}
