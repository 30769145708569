import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { RFODatesUploadDeletePagination } from './model';

export enum RFODatesUploadActionTypes {
    RFODatesUploadTemplateFileRequest = '[RFODatesUpload] Template File Request',
    RFODatesUploadTemplateFileSuccess = '[RFODatesUpload] Template File Success',
    RFODatesUploadTemplateFileError = '[RFODatesUpload] Template File Error',
    RFODatesUploadLogRequest = '[RFODatesUpload] Upload Log Request',
    RFODatesUploadLogSuccess = '[RFODatesUpload] Upload Log Success',
    RFODatesUploadLogError = '[RFODatesUpload] Upload Log Error',
    RFODatesUploadDeleteRecordsRequest = '[RFODatesUpload] Delete Records Request',
    RFODatesUploadDeleteRecordsSuccess = '[RFODatesUpload] Delete Records Success',
    RFODatesUploadDeleteRecordsError = '[RFODatesUpload] Delete Records Error',
    RFODatesUploadSetStatusAndStartDate = '[RFODatesUpload] Set Status and Start Date',
    RFODatesUploadDeleteFilterPropertyUpdate = '[RFODatesUpload] Delete Filter Property Update',
    RFODatesUploadDeleteFilterReset = '[RFODatesUpload] Delete Filter Reset',
    RFODatesUploadPatchDeleteRecordRequest = '[RFODatesUpload] Patch Delete Record Request',
    RFODatesUploadPatchDeleteRecordSuccess = '[RFODatesUpload] Patch Delete Record Success',
    RFODatesUploadPatchDeleteRecordError = '[RFODatesUpload] Patch Delete Record Error',
    RFODatesUploadPatchAllDeleteRecordsRequest = '[RFODatesUpload] Patch All Delete Records Request',
    RFODatesUploadPatchAllDeleteRecordsSuccess = '[RFODatesUpload] Patch All Delete Records Success',
    RFODatesUploadPatchAllDeleteRecordsError = '[RFODatesUpload] Patch All Delete Records Error',
    RFODatesUploadValidateDataRequest = '[RFODatesUpload] Validate Data Request',
    RFODatesUploadValidateDataSuccess = '[RFODatesUpload] Validate Data Success',
    RFODatesUploadValidateDataError = '[RFODatesUpload] Validate Data Error',
    RFODatesUploadFileUploaded = '[RFODatesUpload] File Uploaded',
    RFODatesUploadClearInProgressSpinner = '[RFODatesUpload] Clear In Progress Spinner',
    RFODatesUploadDownloadOutputDataRequest = '[RFODatesUpload] Download Output Data Request',
    RFODatesUploadDownloadOutputDataSuccess = '[RFODatesUpload] Download Output Data Success',
    RFODatesUploadDownloadOutputDataError = '[RFODatesUpload] Download Output Data Error',
    RFODatesUploadValidateButtonStateRequest = '[RFODatesUpload] Validate Button State Request',
    RFODatesUploadValidateButtonStateSuccess = '[RFODatesUpload] Validate Button State Success',
    RFODatesUploadValidateButtonStateError = '[RFODatesUpload] Validate Button State Error',
    RFODatesUploadAddStartedLog = '[RFODatesUpload] Add Started Log',
}

export class RFODatesUploadTemplateFileRequest implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadTemplateFileRequest;

    constructor(public payload: string) {}
}

export class RFODatesUploadTemplateFileSuccess implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class RFODatesUploadTemplateFileError implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadTemplateFileError;

    constructor(public payload: string) {}
}

export class RFODatesUploadAddStartedLog implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadAddStartedLog;
}

export class RFODatesUploadLogRequest implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadLogRequest;
}

export class RFODatesUploadLogSuccess implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class RFODatesUploadLogError implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadLogError;

    constructor(public payload: string) {}
}

export class RFODatesUploadDeleteRequest implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadDeleteRecordsRequest;
}

export class RFODatesUploadDeleteSuccess implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadDeleteRecordsSuccess;

    constructor(public payload: RFODatesUploadDeletePagination) {}
}

export class RFODatesUploadDeleteError implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadDeleteRecordsError;

    constructor(public payload: string) {}
}

export class RFODatesUploadSetStatusAndStartDate implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class RFODatesUploadDeleteFilterPropertyUpdate implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class RFODatesUploadDeleteFilterReset implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadDeleteFilterReset;
}

export class RFODatesUploadPatchDeleteRequest implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadPatchDeleteRecordRequest;

    constructor(public payload: { itrpliNumber: string; deleteState: boolean }) {}
}

export class RFODatesUploadPatchAllDeleteRequest implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class RFODatesUploadPatchDeleteSuccess implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadPatchDeleteRecordSuccess;
}

export class RFODatesUploadPatchAllDeleteSuccess implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class RFODatesUploadPatchDeleteError implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class RFODatesUploadPatchAllDeleteError implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class RFODatesUploadValidateDeleteRequest implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadValidateDataRequest;
}

export class RFODatesUploadValidateDeleteSuccess implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadValidateDataSuccess;
}

export class RFODatesUploadValidateDeleteError implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadValidateDataError;

    constructor(public payload: string) {}
}

export class RFODatesUploadFileUploaded implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadFileUploaded;
}

export class RFODatesUploadClearInProgressSpinner implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadClearInProgressSpinner;
}


export class RFODatesUploadDownloadOutputDataRequest implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class RFODatesUploadDownloadOutputDataSuccess implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class RFODatesUploadDownloadOutputDataError implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class RFODatesUploadValidateButtonStateRequest implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadValidateButtonStateRequest;
}

export class RFODatesUploadValidateButtonStateSuccess implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class RFODatesUploadValidateButtonStateError implements Action {
    readonly type = RFODatesUploadActionTypes.RFODatesUploadValidateButtonStateError;

    constructor(public payload: string) {}
}

export type RFODatesUploadActions =
    | RFODatesUploadTemplateFileRequest
    | RFODatesUploadTemplateFileSuccess
    | RFODatesUploadTemplateFileError
    | RFODatesUploadLogRequest
    | RFODatesUploadLogSuccess
    | RFODatesUploadLogError
    | RFODatesUploadDeleteRequest
    | RFODatesUploadDeleteSuccess
    | RFODatesUploadDeleteError
    | RFODatesUploadSetStatusAndStartDate
    | RFODatesUploadDeleteFilterPropertyUpdate
    | RFODatesUploadDeleteFilterReset
    | RFODatesUploadPatchDeleteRequest
    | RFODatesUploadPatchDeleteSuccess
    | RFODatesUploadPatchDeleteError
    | RFODatesUploadPatchAllDeleteRequest
    | RFODatesUploadPatchAllDeleteSuccess
    | RFODatesUploadPatchAllDeleteError
    | RFODatesUploadValidateDeleteRequest
    | RFODatesUploadValidateDeleteSuccess
    | RFODatesUploadValidateDeleteError
    | RFODatesUploadFileUploaded
    | RFODatesUploadClearInProgressSpinner
    | RFODatesUploadDownloadOutputDataRequest
    | RFODatesUploadDownloadOutputDataSuccess
    | RFODatesUploadDownloadOutputDataError
    | RFODatesUploadValidateButtonStateRequest
    | RFODatesUploadValidateButtonStateSuccess
    | RFODatesUploadValidateButtonStateError
    | RFODatesUploadAddStartedLog;
