<div class="filter-container">
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-basic-chip *ngIf="filterForm.controls.exScope.value" removable>
                            Scope: {{ filterForm.controls.exScope.value }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('exScope')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                            ITR/PLI Contractor: {{ displayMultipleByName(filterForm.controls.contractors.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                            ITR/PLI Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.scManagers.value.length" removable>
                            SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManagers.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('scManagers')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.exceptionPhases.value.length" removable>
                            Exception Phase: {{ filterForm.controls.exceptionPhases.value.join(', ') }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('exceptionPhases')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.rfos?.value.length" removable>
                            RFO: {{ displayMultipleByName(filterForm.controls.rfos.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.overdueOnly.value" removable>
                            Overdue Only: {{ filterForm.controls.overdueOnly.value }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('overdueOnly')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames?.value.length" removable>
                            Project Team: {{ displayMultipleByName(filterForm.controls.projectTeamNames.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.stagedStartUpPriorities?.value.length" removable>
                            Staged Start-up Priority:
                            {{ displayMultipleByName(filterForm.controls.stagedStartUpPriorities.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('stagedStartUpPriorities')"
                                >cancel</mat-icon
                            >
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.subsystems?.value.length" removable>
                            Subsystem: {{ displayMultipleByName(filterForm.controls.subsystems.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('subsystems')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.categories?.value.length" removable>
                            Category: {{ displayMultipleByName(filterForm.controls.categories.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('categories')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.punchTypes?.value.length" removable>
                            Punch Type: {{ displayMultipleByName(filterForm.controls.punchTypes.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('punchTypes')">cancel</mat-icon>
                        </mat-basic-chip>
                    </div>
                </mat-expansion-panel-header>

                <div class="filter-row">

                    <mat-form-field class="container300">
                        <mat-label>Scope</mat-label>
                        <mat-select formControlName="exScope" (openedChange)="onScopeClosed($event)">
                            <mat-option [value]="exceptionScopes.ITR">ITR</mat-option>
                            <mat-option [value]="exceptionScopes.PLI">PLI</mat-option>
                            <mat-option [value]="exceptionScopes.All">All</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="container300">
                        <mat-label>Exception Phase</mat-label>
                        <mat-select formControlName="exceptionPhases" multiple>
                            <mat-option value="Fabrication MC">Fabrication MC</mat-option>
                            <mat-option value="Mechanical Completion">Mechanical Completion</mat-option>
                            <mat-option value="Pre Startup Safety Review">Pre Startup Safety Review</mat-option>
                            <mat-option value="Ready for Operations">Ready for Operations</mat-option>
                            <mat-option value="Ready For Start Up">Ready For Start Up</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="radio-container">
                        <mat-label class="radio-title">Overdue Only</mat-label>
                        <mat-radio-group formControlName="overdueOnly">
                            <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                            <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                            <mat-radio-button [value]="null">All</mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>
                
                <div class="filter-row">

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>Project Team</mat-label>
                        <mat-select formControlName="projectTeamNames" multiple>
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="contractors"
                            [searchFunc]="getContractorsData"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['contractNo', 'contract']"
                            [filterForm]="filterForm"
                            [formSetter]="'contractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'ITR/PLI Contractor'"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            [secondPropertyToShow]="'contract'"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setStagedStartUpPriorityInput"
                            formControlName="stagedStartUpPriorities"
                            [isAsync]="true"
                            [searchFunc]="getStagedStartUpPriorities"
                            [filterForm]="filterForm"
                            [formSetter]="'stagedStartUpPriorities'"
                            [propertyToShow]="'priorityName'"
                            [placeholder]="'Staged Start-up Priority'"
                            [secondPropertyToShow]="'priority'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['priority', 'priorityName']"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManagers" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            formControlName="subsystems"
                            [setInput]="setSubsystemInput"
                            [searchFunc]="getSubsystems"
                            [isAsync]="true"
                            placeholder="Subsystem"
                            [displayedColumns]="['id', 'name']"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300">
                        <mat-label>ITR/PLI Discipline</mat-label>
                        <mat-select formControlName="disciplines" multiple>
                            <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div
                        *ngIf="scope === exceptionScopes.All || scope === exceptionScopes.ITR"
                        class="container300 margin-right15"
                    >
                        <app-multiple-items-selector
                            formControlName="qvdNumbers"
                            [searchFunc]="getQVDNumbers"
                            [isAsync]="true"
                            placeholder="ITR Number"
                            [isSelectAll]="false"
                            propertyToShow="value"
                            [setInput]="setQVDNumbersInput"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field
                        *ngIf="scope === exceptionScopes.All || scope === exceptionScopes.PLI"
                        class="container300 margin-right15"
                    >
                        <mat-label>Category</mat-label>
                        <mat-select formControlName="categories" multiple>
                            <mat-option value="A">A</mat-option>
                            <mat-option value="B">B</mat-option>
                            <mat-option value="C">C</mat-option>
                            <mat-option value="D">D</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div
                        *ngIf="scope === exceptionScopes.All || scope === exceptionScopes.PLI"
                        class="container300 margin-right15"
                    >
                        <app-multiple-items-selector
                            class="container300 margin-right20"
                            [filterForm]="filterForm"
                            formControlName="actionBy"
                            [isAsync]="false"
                            [placeholder]="'Action By'"
                            [searchFunc]="searchActionBys"
                            [formSetter]="'actionBy'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field
                        *ngIf="scope === exceptionScopes.All || scope === exceptionScopes.PLI"
                        class="container300 margin-right15"
                    >
                        <mat-label>Punch Type</mat-label>
                        <mat-select formControlName="punchType" multiple>
                            <mat-option *ngFor="let p of punchTypes" [value]="p">{{ p }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="rfsuContractors"
                            [searchFunc]="getRFSUContractors"
                            [isAsync]="true"
                            [displayedColumns]="['contractNo', 'contract']"
                            [filterForm]="filterForm"
                            [formSetter]="'rfsuContractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'RFSU Contractor'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>Area Completion Lead</mat-label>
                        <mat-select formControlName="areaCompletionLead" multiple>
                            <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>

    <div class="buttons-container flex">
        <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
            export to excel
        </button>
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filters
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
    </div>
</div>

