import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterHidden',
})
export class FilterHiddenPipe implements PipeTransform {
    transform(arr: any[], args?: any): any[] {
        return arr.filter((s) => !s.hidden);
    }
}
