<mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    *ngIf="(lookahead$ | async).data || (lookahead$ | async).isLoading"
>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h3>MC 6 Week Lookahead</h3>
        </mat-panel-title>
        <mat-panel-description *ngIf="!panelOpenState">Expand panel to view details.</mat-panel-description>
    </mat-expansion-panel-header>
    <div class="lookahead-container">
        <app-reports-planning-lookahead-chart [lookahead$]="lookahead$"></app-reports-planning-lookahead-chart>
        <app-reports-planning-lookahead-table [lookahead$]="lookahead$"></app-reports-planning-lookahead-table>
        <div *ngIf="(lookahead$ | async).isLoading" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
</mat-expansion-panel>
