import {
    WeeklyPlanningState,
    WeeklyPlanningFilter,
    WeeklyPlanningTableResult,
    MCWeeklyPlanningDTO,
    RFSUWeeklyPlanningDTO,
    WeeklyPlanningTableHeaderFilter,
    RFOWeeklyPlanningDTO,
    WeeklyPlanningCommitmentsDTO,
    WeeklyPlanningTopHeaderResultDTO,
} from './model';
import { WeeklyPlanningActions, WeeklyPlanningActionTypes } from './actions';

const initialState: WeeklyPlanningState = {
    mc: [
        new WeeklyPlanningTableResult<MCWeeklyPlanningDTO>(),
        new WeeklyPlanningTableResult<MCWeeklyPlanningDTO>(),
        new WeeklyPlanningTableResult<MCWeeklyPlanningDTO>(),
        new WeeklyPlanningTableResult<MCWeeklyPlanningDTO>(),
        new WeeklyPlanningTableResult<MCWeeklyPlanningDTO>(),
    ],
    rfsu: [
        new WeeklyPlanningTableResult<RFSUWeeklyPlanningDTO>(),
        new WeeklyPlanningTableResult<RFSUWeeklyPlanningDTO>(),
        new WeeklyPlanningTableResult<RFSUWeeklyPlanningDTO>(),
        new WeeklyPlanningTableResult<RFSUWeeklyPlanningDTO>(),
        new WeeklyPlanningTableResult<RFSUWeeklyPlanningDTO>(),
    ],
    rfo: [
        new WeeklyPlanningTableResult<RFOWeeklyPlanningDTO>('rfo'),
        new WeeklyPlanningTableResult<RFOWeeklyPlanningDTO>('rfo'),
        new WeeklyPlanningTableResult<RFOWeeklyPlanningDTO>('rfo'),
        new WeeklyPlanningTableResult<RFOWeeklyPlanningDTO>('rfo'),
        new WeeklyPlanningTableResult<RFOWeeklyPlanningDTO>('rfo'),
    ],
    filter: new WeeklyPlanningFilter(),
    planningType: 'mc',
    commitments: new WeeklyPlanningCommitmentsDTO(),
    commitmentsLoading: false,
    topHeaderSectionData: new WeeklyPlanningTopHeaderResultDTO(),
    topHeaderSectionDataLoading: false,
};

export function reducer(state = initialState, action: WeeklyPlanningActions): WeeklyPlanningState {
    switch (action.type) {
        case WeeklyPlanningActionTypes.WeeklyPlanningDataRequest: {
            return {
                ...state,
                [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                    if (i === +action.payload.planningTableIndex) {
                        return {
                            ...x,
                            isLoading: true,
                        };
                    }
                    return x;
                }),
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningDataSuccess: {
            return {
                ...state,
                [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                    if (i === +action.payload.planningTableIndex) {
                        return {
                            ...x,
                            isLoading: false,
                            items: action.payload.result.items,
                            totalCount: action.payload.result.totalCount,
                        };
                    }
                    return x;
                }),
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningSubmitCommitmentsRequest: {
            return {
                ...state,
                commitmentsLoading: true,
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningSubmitCommitmentsSuccess: {
            return {
                ...state,
                commitmentsLoading: false,
                commitments: {
                    ...state.commitments,
                    additionalCommitments: state.commitments.additionalCommitments
                        ? state.commitments.additionalCommitments
                              .filter((s) => !s.isDeleted)
                              .map((x) => ({
                                  ...x,
                                  isSubmitted: true,
                                  isDeleted: false,
                              }))
                        : [],
                    plannedAndCommitted: state.commitments.plannedAndCommitted
                        ? state.commitments.plannedAndCommitted
                              .filter((s) => !s.isDeleted)
                              .map((x) => ({
                                  ...x,
                                  isSubmitted: true,
                                  isDeleted: false,
                              }))
                        : [],
                },
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningSubmitCommitmentsError: {
            return {
                ...state,
                commitmentsLoading: false,
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningTopHeaderSectionDataRequest: {
            return {
                ...state,
                topHeaderSectionDataLoading: true,
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningTopHeaderSectionDataSuccess: {
            return {
                ...state,
                topHeaderSectionDataLoading: false,
                topHeaderSectionData: action.payload.result,
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningTopHeaderSectionDataError: {
            return {
                ...state,
                topHeaderSectionDataLoading: false,
            };
        }

        case WeeklyPlanningActionTypes.WeeklyPlanningCommitmentsRequest: {
            return {
                ...state,
                commitmentsLoading: true,
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningCommitmentsSuccess: {
            return {
                ...state,
                commitmentsLoading: false,
                commitments: action.payload.result,
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningCommitmentsError: {
            return {
                ...state,
                commitmentsLoading: false,
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningDataError: {
            return {
                ...state,
                [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                    if (i === +action.payload.planningTableIndex) {
                        return {
                            ...x,
                            isLoading: false,
                        };
                    }
                    return x;
                }),
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningSetStartDate: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    startDate: action.payload,
                },
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningSetProjectTeam: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    projectTeamName: action.payload,
                },
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                        if (i === action.payload.planningTableIndex) {
                            return {
                                ...x,
                                sortBy: action.payload.value.active,
                                direction: action.payload.value.direction,
                            };
                        }
                        return x;
                    }),
                };
            } else if (
                action.payload.key === 'take' ||
                action.payload.key === 'page' ||
                action.payload.key === 'expanded'
            ) {
                return {
                    ...state,
                    [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                        if (i === action.payload.planningTableIndex) {
                            return {
                                ...x,
                                [action.payload.key]: action.payload.value,
                            };
                        }
                        return x;
                    }),
                };
            } else {
                if (action.payload.planningType != null && action.payload.planningTableIndex != null) {
                    return {
                        ...state,
                        [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                            if (i === +action.payload.planningTableIndex) {
                                return {
                                    ...x,
                                    tableHeaderFilter: {
                                        ...state[action.payload.planningType][action.payload.planningTableIndex]
                                            .tableHeaderFilter,
                                        [action.payload.key]: action.payload.value,
                                    },
                                };
                            }
                            return x;
                        }),
                    };
                } else {
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                                ? [...action.payload.value]
                                : action.payload.value,
                        },
                    };
                }
            }
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningSetPlanningType: {
            return {
                ...state,
                planningType: action.payload,
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningFilterReset: {
            return {
                ...state,
                mc: state.mc.map((x) => ({ ...x, tableHeaderFilter: new WeeklyPlanningTableHeaderFilter() })),
                rfsu: state.rfsu.map((x) => ({ ...x, tableHeaderFilter: new WeeklyPlanningTableHeaderFilter() })),
                rfo: state.rfo.map((x) => ({ ...x, tableHeaderFilter: new WeeklyPlanningTableHeaderFilter() })),
                filter: {
                    ...state.filter,
                    acms: [],
                    scManagers: [],
                    mcEngineers: [],
                    systemOwners: [],
                    contractors: [],
                    waypoints: [],
                    milestones: [],
                    rfos: [],
                    goocs: [],
                    areaBreakdown:[],
                },
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningCommitRequest: {
            return {
                ...state,
                [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                    if (i === +action.payload.planningTableIndex) {
                        return {
                            ...x,
                            isLoading: true,
                        };
                    }
                    return x;
                }),
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningCommitSuccess: {
            const itemIndex = state[action.payload.planningType][action.payload.planningTableIndex].items.findIndex(
                (i) => (i.subsystem || i.rfo) === action.payload.commit.commitmentKey
            );
            return {
                ...state,
                [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                    if (i === +action.payload.planningTableIndex) {
                        return {
                            ...x,
                            isLoading: false,
                            items: state[action.payload.planningType][action.payload.planningTableIndex].items.map(
                                (item, index) => {
                                    if (index === itemIndex) {
                                        return {
                                            ...item,
                                            committedForWeek: action.payload.commit.committedForWeek,
                                        };
                                    }
                                    return item;
                                }
                            ),
                        };
                    }
                    return x;
                }),
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningCommitError: {
            return {
                ...state,
                [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                    if (i === +action.payload.planningTableIndex) {
                        return {
                            ...x,
                            isLoading: false,
                        };
                    }
                    return x;
                }),
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningDeleteCommitmentRequest: {
            return {
                ...state,
                [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                    if (i === +action.payload.planningTableIndex) {
                        return {
                            ...x,
                            isLoading: true,
                        };
                    }
                    return x;
                }),
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningDeleteCommitmentSuccess: {
            const itemIndex = state[action.payload.planningType][action.payload.planningTableIndex].items.findIndex(
                (i) => (i.subsystem || i.rfo) === action.payload.commit.commitmentKey
            );
            return {
                ...state,
                [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                    if (i === +action.payload.planningTableIndex) {
                        return {
                            ...x,
                            isLoading: false,
                            items: state[action.payload.planningType][action.payload.planningTableIndex].items.map(
                                (item, index) => {
                                    if (index === itemIndex) {
                                        return {
                                            ...item,
                                            committedForWeek: null,
                                        };
                                    }
                                    return item;
                                }
                            ),
                        };
                    }
                    return x;
                }),
            };
        }
        case WeeklyPlanningActionTypes.WeeklyPlanningDeleteCommitmentError: {
            return {
                ...state,
                [action.payload.planningType]: state[action.payload.planningType].map((x, i) => {
                    if (i === +action.payload.planningTableIndex) {
                        return {
                            ...x,
                            isLoading: false,
                        };
                    }
                    return x;
                }),
            };
        }
        default:
            return state;
    }
}
