<main class="main">
    <form [formGroup]="filterForm">
        <div class="header-container">
            <div class="header-title">
                BlueZone: subsystem RFSU status
            </div>
            <div class="header-buttons">
                <button
                    type="button"
                    (click)="onePager()"
                    class="sct-button sct-button-light margin-right15"
                    [style.width]="'100px'"
                >
                    one pager
                </button>
                <button
                    type="button"
                    (click)="exportToExcel()"
                    class="sct-button sct-button-light"
                    [style.width]="'100px'"
                >
                    export
                </button>
            </div>
        </div>
        <mat-accordion>
            <mat-expansion-panel [ngClass]="{'hideme':!showFilterPanel}" #filterExpansionPanel="matExpansionPanel" class="filter-panel-container">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div class="filter-panel-header-container">
                        <div class="filter-panel-header-content">
                            <div class="filter-by-button">
                                <img src="assets/images/icons/filter-blue.svg" />
                                filter by
                            </div>
                            <div *ngIf="!filterExpansionPanel.expanded">
                                <mat-chip-list aria-label="Active filters">
                                    <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                        Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                        Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('contractors')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.systems.value.length" removable>
                                        System: {{ displaySystemsSelected(filterForm.controls.systems.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('systems')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.goocs.value.length" removable>
                                        GOOC: {{ displayGoocsSelected(filterForm.controls.goocs.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('goocs')">cancel</mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                                        RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip>
                                        RFSU Completed:
                                        {{ displaySelectedBoolean(filterForm.controls.rfsuCompleted.value) }}
                                    </mat-basic-chip>
                                    <mat-basic-chip>
                                        RFSU Committed:
                                        {{ displaySelectedBoolean(filterForm.controls.rfsuCommitted.value) }}
                                    </mat-basic-chip>
                                    <mat-basic-chip>
                                        Has LUN:
                                        {{ displaySelectedBoolean(filterForm.controls.hasLUN.value) }}
                                    </mat-basic-chip>
                                    <!-- <mat-basic-chip *ngIf="filterForm.controls.constructionArea.value.length" removable>
                                        Construction Area:
                                        {{ displayMultipleFilterSelected(filterForm.controls.constructionArea.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('contructionArea')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip> -->
                                    <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                        Area Breakdown:
                                        {{ displayMultipleFilterSelected(filterForm.controls.areaBreakdown.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                        SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('scManager')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.areaCompletionLead.value.length" removable>
                                        Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('areaCompletionLead')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.deliveryEngineer.value.length" removable>
                                        Delivery Engineer: {{ displaySelectedDeliveryEngineer(filterForm.controls.deliveryEngineer.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('deliveryEngineer')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.mcEngineer.value.length" removable>
                                        MC Engineer:
                                        {{ displaySelectedMCEngineer(filterForm.controls.mcEngineer.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('mcEngineer')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                        System Owner:
                                        {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip>
                                        Required for Steamblow:
                                        {{ displaySelectedBoolean(filterForm.controls.requiredForSteamblow.value) }}
                                    </mat-basic-chip>
                                </mat-chip-list>
                            </div>
                        </div>

                        <div class="filter-panel-header-buttons">
                            <button mat-button (click)="resetFilters($event)">Reset filters &times;</button>
                            <button mat-button class="light-blue" (click)="toggleExpansionPanel($event)">
                                {{ filterExpansionPanel.expanded ? 'Hide panel' : 'Show panel' }}
                            </button>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <div class="filter-panel-content-container">
                    <div class="filters-row col4">
                        <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setRFOInput"
                                formControlName="rfos"
                                [searchFunc]="getRFOs"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'rfos'"
                                [propertyToShow]="'name'"
                                [placeholder]="'RFO'"
                                [secondPropertyToShow]="'rfoName'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name', 'rfoName']"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>RFSU Committed</mat-label>
                                <mat-select
                                    placeholder="All"
                                    [formGroup]="filterForm"
                                    formControlName="rfsuCommitted"
                                    aria-label="RFSU Committed"
                                    disableOptionCentering
                                >
                                    <mat-option [value]="null">All</mat-option>
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>RFSU Completed</mat-label>
                                <mat-select
                                    placeholder="All"
                                    [formGroup]="filterForm"
                                    formControlName="rfsuCompleted"
                                    aria-label="RFSU Completed"
                                    disableOptionCentering
                                >
                                    <mat-option [value]="null">All</mat-option>
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>LUN</mat-label>
                                <mat-select
                                    placeholder="All"
                                    [formGroup]="filterForm"
                                    formControlName="hasLUN"
                                    aria-label="LUN"
                                    disableOptionCentering
                                >
                                    <mat-option [value]="null">All</mat-option>
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filters-row col4">
                        <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setContractorInput"
                                formControlName="contractors"
                                [searchFunc]="getContractors"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                (autocompleteClosed)="onContractorsClosed()"
                                (itemRemoved)="onContractorsClosed()"
                                [filterForm]="filterForm"
                                [formSetter]="'contractors'"
                                [propertyToShow]="'contractNo'"
                                [placeholder]="'RFSU Contractor'"
                                [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                                [secondPropertyToShow]="'contract'"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <!-- <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setConstructionAreaInput"
                                formControlName="constructionArea"
                                [searchFunc]="getConstructionArea"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'constructionArea'"
                                [placeholder]="'Construction Area'"
                            >
                            </app-multiple-items-selector>
                        </div> -->
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>System Owner</mat-label>
                                <mat-select formControlName="systemOwner" multiple disableOptionCentering>
                                    <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>Project Team</mat-label>
                                <mat-select
                                    formControlName="projectTeamNames"
                                    multiple
                                    (openedChange)="onProjectTeamsClosed($event)"
                                    disableOptionCentering
                                >
                                    <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filters-row col4">
                        <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setSystemInput"
                                formControlName="systems"
                                [searchFunc]="getSystems"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="systemAutocompleteDisplayedColumns"
                                [filterForm]="filterForm"
                                [formSetter]="'systems'"
                                [propertyToShow]="'no'"
                                [placeholder]="'System'"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setAreaBreakdownInput"
                                formControlName="areaBreakdown"
                                [searchFunc]="getAreaBreakdown"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'areaBreakdown'"
                                [placeholder]="'Area Breakdown'"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>Area Completion Lead</mat-label>
                                <mat-select formControlName="areaCompletionLead" multiple disableOptionCentering>
                                    <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id"
                                        >{{ acl.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>SC Execution Area Manager</mat-label>
                                <mat-select formControlName="scManager" multiple disableOptionCentering>
                                    <mat-option *ngFor="let sc of scManagers" [value]="sc.id"
                                        >{{ sc.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filters-row col4">
                        <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setStagedStartUpPrioritiesInput"
                                formControlName="stagedStartUpPriorities"
                                [searchFunc]="getStagedStartUpPriorities"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['priority', 'priorityName']"
                                (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                                (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                                [filterForm]="filterForm"
                                formSetter="stagedStartUpPriorities"
                                [propertyToShow]="'priority'"
                                [secondPropertyToShow]="'priorityName'"
                                placeholder="Staged Start-up Priority"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>Required for Steamblow</mat-label>
                                <mat-select
                                    placeholder="All"
                                    [formGroup]="filterForm"
                                    formControlName="requiredForSteamblow"
                                    aria-label="Required for Steamblow"
                                    disableOptionCentering
                                >
                                    <mat-option [value]="null">All</mat-option>
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>Delivery Engineer</mat-label>
                                <mat-select formControlName="deliveryEngineer" multiple disableOptionCentering>
                                    <mat-option *ngFor="let de of deliveryEngineers" [value]="de.id"
                                        >{{ de.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setWaypointInput"
                                formControlName="waypoints"
                                [searchFunc]="getWaypoints"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'waypoints'"
                                [propertyToShow]="'name'"
                                [placeholder]="'Waypoint'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name']"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>
                    </div>
                </div>
                <div class="search-btn-container">
                    <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
                </div>

                <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="results-panel results-container">
            <div class="results-panel-container">
                <div class="results-panel-header-container">
                    <div class="results-panel-header-title-row">
                        <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
                        <div class="results-header-actions">
                            <button
                                mat-button
                                [matMenuTriggerFor]="columnsCustomizer"
                                class="results-columns-customizer"
                            >
                                <mat-icon svgIcon="edit"></mat-icon>
                                customize columns
                            </button>
                            <mat-menu #columnsCustomizer class="columns-customizer">
                                <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                                <div class="columns-customizer-list">
                                    <mat-checkbox
                                        [checked]="showHideColumns.subsysName"
                                        (change)="reverseValue('subsysName')"
                                        (click)="$event.stopPropagation()"
                                        >Subsystem Name
                                    </mat-checkbox>
                                    <mat-checkbox
                                        [checked]="showHideColumns.rfsuPlanDate"
                                        (change)="reverseValue('rfsuPlanDate')"
                                        (click)="$event.stopPropagation()"
                                        >RFSU Plan</mat-checkbox
                                    >
                                    <!--
                                    <mat-checkbox
                                        [checked]="showHideColumns.rfsuWaldownPlanDate"
                                        (change)="reverseValue('rfsuWaldownPlanDate')"
                                        (click)="$event.stopPropagation()"
                                        >RFSU Walkdown Plan</mat-checkbox
                                    >
                                    -->
                                    <mat-checkbox
                                        [checked]="showHideColumns.rfsuForecastDate"
                                        (change)="reverseValue('rfsuForecastDate')"
                                        (click)="$event.stopPropagation()"
                                        >RFSU Forecast</mat-checkbox
                                    >
                                    <mat-checkbox
                                    [checked]="showHideColumns.rfsuCommitmentDate"
                                    (change)="reverseValue('rfsuCommitmentDate')"
                                    (click)="$event.stopPropagation()"
                                    >RFSU Commitment</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.rfoPlan"
                                        (change)="reverseValue('rfoPlan')"
                                        (click)="$event.stopPropagation()"
                                        >RFO Plan</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.rfsuActualDate"
                                        (change)="reverseValue('rfsuActualDate')"
                                        (click)="$event.stopPropagation()"
                                        >RFSU Actual</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.rfo"
                                        (change)="reverseValue('rfo')"
                                        (click)="$event.stopPropagation()"
                                        >RFO</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.waypoint"
                                        (change)="reverseValue('waypoint')"
                                        (click)="$event.stopPropagation()"
                                        >Waypoint</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.milestone"
                                        (change)="reverseValue('milestone')"
                                        (click)="$event.stopPropagation()"
                                        >Milestone</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.systemOwner"
                                        (change)="reverseValue('systemOwner')"
                                        (click)="$event.stopPropagation()"
                                        >System Owner</mat-checkbox
                                    >
                                    <!-- <mat-checkbox
                                        [checked]="showHideColumns.mcPlanDate"
                                        (change)="reverseValue('mcPlanDate')"
                                        (click)="$event.stopPropagation()"
                                        >MC Plan</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.mcForecastDate"
                                        (change)="reverseValue('mcForecastDate')"
                                        (click)="$event.stopPropagation()"
                                        >MC Forecast</mat-checkbox
                                    > -->
                                    <mat-checkbox
                                        [checked]="showHideColumns.mcActualDate"
                                        (change)="reverseValue('mcActualDate')"
                                        (click)="$event.stopPropagation()"
                                        >MC Actual</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.walkdownForecast"
                                        (change)="reverseValue('walkdownForecast')"
                                        (click)="$event.stopPropagation()"
                                        >Walkdown Forecast</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.walkdownScheduled"
                                        (change)="reverseValue('walkdownScheduled')"
                                        (click)="$event.stopPropagation()"
                                        >Walkdown Scheduled</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.walkdownActual"
                                        (change)="reverseValue('walkdownActual')"
                                        (click)="$event.stopPropagation()"
                                        >Walkdown Actual</mat-checkbox
                                    >
                                    <!-- <mat-checkbox
                                    [checked]="showHideColumns.showSafetyRewalkdown"
                                    (change)="reverseValue('showSafetyRewalkdown')"
                                    (click)="$event.stopPropagation()"
                                    >RFSU Safety Re-walkdown</mat-checkbox
                                    > -->
                                    <mat-checkbox
                                        [checked]="showHideColumns.showFPLUpload"
                                        (change)="reverseValue('showFPLUpload')"
                                        (click)="$event.stopPropagation()"
                                        >FPL Upload</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showEdossierInitiation"
                                        (change)="reverseValue('showEdossierInitiation')"
                                        (click)="$event.stopPropagation()"
                                        >eDossier Initiation</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showEdossierStatus"
                                        (change)="reverseValue('showEdossierStatus')"
                                        (click)="$event.stopPropagation()"
                                        >eDossier Status</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.BITR"
                                        (change)="reverseValue('BITR')"
                                        (click)="$event.stopPropagation()"
                                        >B-ITR</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showBITRTotalAndComplete"
                                        (change)="reverseValue('showBITRTotalAndComplete')"
                                        (click)="$event.stopPropagation()"
                                    >
                                        B-ITR Total & Complete</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.CITR"
                                        (change)="reverseValue('CITR')"
                                        (click)="$event.stopPropagation()"
                                        >C-ITR</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showCITRTotalAndComplete"
                                        (change)="reverseValue('showCITRTotalAndComplete')"
                                        (click)="$event.stopPropagation()"
                                    >
                                        C-ITR Total & Complete</mat-checkbox
                                    >
                                    <mat-checkbox
                                    [checked]="showHideColumns.ACT"
                                    (change)="reverseValue('ACT')"
                                    (click)="$event.stopPropagation()"
                                    >ACTs</mat-checkbox
                                >
                                    <mat-checkbox
                                        [checked]="showHideColumns.NPW"
                                        (change)="reverseValue('NPW')"
                                        (click)="$event.stopPropagation()"
                                        >NPW</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.AQVD"
                                        (change)="reverseValue('AQVD')"
                                        (click)="$event.stopPropagation()"
                                        >A-QVD
                                    </mat-checkbox>
                                    <mat-checkbox
                                        [checked]="showHideColumns.APLI"
                                        (change)="reverseValue('APLI')"
                                        (click)="$event.stopPropagation()"
                                        >A-PLI
                                    </mat-checkbox>
                                    <mat-checkbox
                                        [checked]="showHideColumns.BPLI"
                                        (change)="reverseValue('BPLI')"
                                        (click)="$event.stopPropagation()"
                                        >B-PLI
                                    </mat-checkbox>
                                    <mat-checkbox
                                        [checked]="showHideColumns.CPLI"
                                        (change)="reverseValue('CPLI')"
                                        (click)="$event.stopPropagation()"
                                        >C-PLI
                                    </mat-checkbox>
                                    <mat-checkbox
                                        [checked]="showHideColumns.DPLI"
                                        (change)="reverseValue('DPLI')"
                                        (click)="$event.stopPropagation()"
                                        >D-PLI
                                    </mat-checkbox>
                                    <mat-checkbox
                                        [checked]="showHideColumns.PASCR"
                                        (change)="reverseValue('PASCR')"
                                        (click)="$event.stopPropagation()"
                                        >PAS CR
                                    </mat-checkbox>                                    
                                    <mat-checkbox
                                        [checked]="showHideColumns.EX"
                                        (change)="reverseValue('EX')"
                                        (click)="$event.stopPropagation()"
                                        >Exc</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.COW"
                                        (change)="reverseValue('COW')"
                                        (click)="$event.stopPropagation()"
                                        >COW</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.redline"
                                        (change)="reverseValue('redline')"
                                        (click)="$event.stopPropagation()"
                                        >Redline</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showPriorityName"
                                        (change)="reverseValue('showPriorityName')"
                                        (click)="$event.stopPropagation()"
                                        >Staged Start-up Priority</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showAreaBreakdown"
                                        (change)="reverseValue('showAreaBreakdown')"
                                        (click)="$event.stopPropagation()"
                                        >Area Breakdown</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showContractorNo"
                                        (change)="reverseValue('showContractorNo')"
                                        (click)="$event.stopPropagation()"
                                        >RFSU Contractor</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showAreaCompletionLead"
                                        (change)="reverseValue('showAreaCompletionLead')"
                                        (click)="$event.stopPropagation()"
                                        >Area Completion Lead</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showNonComm"
                                        (change)="reverseValue('showNonComm')"
                                        (click)="$event.stopPropagation()"
                                        >Non Comm</mat-checkbox
                                    >
                                    <mat-checkbox
                                    [checked]="showHideColumns.showFlangeMgt"
                                    (change)="reverseValue('showFlangeMgt')"
                                    (click)="$event.stopPropagation()"
                                    >
                                    Flange Mgt</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.LUN"
                                        (change)="reverseValue('LUN')"
                                        (click)="$event.stopPropagation()"
                                        >LUN</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showGoocNo"
                                        (change)="reverseValue('showGoocNo')"
                                        (click)="$event.stopPropagation()"
                                        >GOOC No</mat-checkbox
                                    >
                                    <mat-checkbox
                                        *ngIf="!disciplineBreakdownEnabled"
                                        [checked]="showHideColumns.showBacklogCat"
                                        (change)="reverseValue('showBacklogCat')"
                                        (click)="$event.stopPropagation()"
                                        >Backlog Cat</mat-checkbox
                                    >
                                </div>
                            </mat-menu>

                            <button hidden *ngIf="isAdmin" type="button" mat-button (click)="clearflagSubsystem()" class="results-clear-flags">
                                <mat-icon>close</mat-icon>
                                clear flag(s)
                            </button>

                            <mat-slide-toggle
                                [color]="'primary'"
                                [checked]="disciplineBreakdownEnabled"
                                (toggleChange)="toggleDisciplineBreakdown()"
                                class="results-header-toggle"
                            >
                                show discipline breakdown
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>

            <div class="results-panel-content-container">
                <div class="results-info">
                    <span class="results-info-highlight">
                        <mat-icon>info</mat-icon>
                        {{ resultsLength + ' ' }} {{ getSubsystemsInfoMessage() }}
                    </span>
                    found
                </div>
                <div class="table-wrapper">
                    <div
                        class="scroll"
                        [ngClass]="{
                            'subsysnm-hidden': !showHideColumns.subsysName && isIE
                        }"
                    >
                        <div #tableContainer class="table-container">
                            <popover-content #trafficLightPopover [closeOnClickOutside]="true" placement="top">
                                <ng-content
                                    *ngTemplateOutlet="trafficLightTemplate; context: { subsystem: selectedSubsystem }"
                                ></ng-content>
                            </popover-content>
                            <table
                                mat-table
                                matSort
                                [dataSource]="data"
                                [matSortActive]="sortBy"
                                matSortDisableClear
                                matSortStart="desc"
                                [matSortDirection]="direction"
                            >
                                <ng-container matColumnDef="subsystem" [sticky]="!isIE ? 'sticky' : null">
                                    <th
                                        app-col-resize
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        class="sticky-col1"
                                        [ngClass]="{
                                            'header-filtered':
                                                showUnderlineForCheckListColumn(columnSubsystems) ||
                                                (columnFlags && columnFlags.items.length)
                                        }"
                                    >
                                        <div class="sort-container">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                                    openHeaderCheckListFilter(
                                                        'subsystem',
                                                        getSubsystems,
                                                        'columnSubsystems',
                                                        'Subsystem',
                                                        columnSubsystems
                                                    )
                                                "
                                                >Subsystem</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        class="sticky-col1"
                                        
                                        (click)="selectSubsystem(element.subsystem)"
                                        [popover]="popoverContent"
                                        [popoverOnHover]="false"
                                        [ngClass]="getSubsystemClasses(data, idx)"
                                    >
                                        {{ element.subsystem }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="subsystemName" [sticky]="!isIE ? 'sticky' : null">
                                    <th
                                        mat-header-cell
                                        app-col-resize
                                        disableClear
                                        *matHeaderCellDef
                                        class="sticky-col3"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.subsysName,
                                            'header-filtered': showUnderlineForCheckListColumn(columnSubsystemNames)
                                        }"
                                    >
                                        <div class="sort-container">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                                    openHeaderCheckListFilter(
                                                        'subsystemName',
                                                        getSubsystemNames,
                                                        'columnSubsystemNames',
                                                        'Subsystem Name',
                                                        columnSubsystemNames
                                                    )
                                                "
                                                >Subsystem Name</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        class="sticky-col3"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.subsysName,
                                            'green-indicator': element.isReadyForRFSU,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.subsystemName }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="walkdownCommitment">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            'header-filtered': showUnderlineForCheckListColumn(
                                                columnWalkdownCommitments
                                            )
                                        }"
                                    >
                                        <div class="sort-container">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                                    openHeaderCheckListFilter(
                                                        'walkdownCommitted',
                                                        getWalkdownCommitments,
                                                        'columnWalkdownCommitments',
                                                        'Walkdown Commitment',
                                                        columnWalkdownCommitments,
                                                        true
                                                    )
                                                "
                                                >Walkdown Commitment</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <button
                                            [ngClass]="{ committed: element.walkdownCommitted }"
                                            (click)="toggleWalkdownCommitment(element)"
                                            [disabled]="!isAdmin && (element | walkdownCommitmentDisabled)"
                                            class="add-comment"
                                        >
                                            {{ element.walkdownCommitted ? 'Committed' : 'Commit' }}
                                        </button>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="discipline" [sticky]="!isIE ? 'sticky' : null">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !disciplineBreakdownEnabled,
                                            'header-filtered': showUnderlineForCheckListColumn(columnDisciplines)
                                        }"
                                        class="hover-pointer sticky-col2"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'discipline',
                                                getDisciplines,
                                                'columnDisciplines',
                                                'Discipline',
                                                columnDisciplines
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>Discipline</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        class="sticky-col2"
                                        [ngClass]="{
                                            hidecells: !disciplineBreakdownEnabled,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.discipline }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="rfsuPlan">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuPlanDate,
                                            'header-filtered': showUnderlineForCalendarColumn(columnRFSUPlan)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'rfsuPlan',
                                                'columnRFSUPlan',
                                                'RFSU Plan',
                                                columnRFSUPlan
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>RFSU Plan</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuPlanDate,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.rfsuPlan | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="rfsuWalkdownPlan">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuWaldownPlanDate,
                                            'header-filtered': showUnderlineForCalendarColumn(columnRFSUWalkdownPlan)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                           
                                            openHeaderDateFilter(
                                                'rfsuWalkdownPlan',
                                                'columnRFSUWalkdownPlan',
                                                'RFSU Walkdown Plan',
                                                columnRFSUWalkdownPlan
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>RFSU Walkdown Plan</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'rfsuWalkdownPlan'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuWaldownPlanDate,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                    {{ element.rfsuWalkdownPlan | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="rfsuCommitment">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuCommitmentDate,
                                            'header-filtered': showUnderlineForCalendarColumn(columnRFSUCommitment)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'rfsuCommitment',
                                                'columnRFSUCommitment',
                                                'RFSU Commitment',
                                                columnRFSUCommitment
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>RFSU Commitment</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'rfsuCommitment'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuCommitmentDate,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.rfsuCommitment | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="rfsuForecast">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuForecastDate,
                                            'header-filtered': showUnderlineForCalendarColumn(columnRFSUForecast)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'rfsuForecast',
                                                'columnRFSUForecast',
                                                'RFSU Forecast',
                                                columnRFSUForecast
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>RFSU Forecast</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'rfsuForecast'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        *matCellDef="let element; let idx = index"
                                        (contextmenu)="forecastDateHistory($event, element, 'rfsu')"
                                        (click)="openDatepicker(element, 'rfsu')"
                                        mat-cell
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuForecastDate,
                                            'cursor-pointer': (isAdmin || isDeliveryEngineer) && !element.rfsuActual,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.rfsuForecast | date: 'd/MMM/yy' }}
                                        <button
                                            type="button"
                                            *ngIf="!element.rfsuForecast && (isAdmin || isDeliveryEngineer) && !element.rfsuActual"
                                            mat-icon-button
                                            class="date-picker-icon"
                                        >
                                            <mat-icon>calendar_today</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="RFOPlan">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfoPlan,
                                            'header-filtered': showUnderlineForCalendarColumn(columnRFOPlan)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter('RFOPlan', 'columnRFOPlan', 'RFO Plan', columnRFOPlan)
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>RFO Plan</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'RFOPlan'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{ hidecells: !showHideColumns.rfoPlan, 'row-underline': isRowUnderlined(data, idx) }"
                                    >
                                        {{ element.rfoPlan | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="rfsuActual">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuActualDate,
                                            'header-filtered': showUnderlineForCalendarColumn(columnRFSUActual)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'rfsuActual',
                                                'columnRFSUActual',
                                                'RFSU Actual',
                                                columnRFSUActual
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>RFSU Actual</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'rfsuActual'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfsuActualDate,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.rfsuActual | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="rfo">
                                    <th
                                        mat-header-cell                                        
                                        disableClear
                                        *matHeaderCellDef                                        
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfo,
                                            'header-filtered': showUnderlineForCheckListColumn(columnRfo)
                                        }"
                                    >
                                        <div class="sort-container">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                            openHeaderCheckListFilter(
                                                'rfo',
                                                getRfosFilter,
                                                'columnRfo',
                                                'RFO',
                                                columnRfo
                                            )
                                        "
                                                >RFO</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'rfo'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"  
                                        style="text-align: center;"                                  
                                        [ngClass]="{
                                            hidecells: !showHideColumns.rfo,                                            
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.rfo }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="waypointDescription">
                                    <th
                                        mat-header-cell                                        
                                        disableClear
                                        *matHeaderCellDef                                        
                                        [ngClass]="{
                                            hidecells: !showHideColumns.waypoint,
                                            'header-filtered': showUnderlineForCheckListColumn(columnWaypointDescription)
                                        }"
                                    >
                                        <div class="sort-container">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                            openHeaderCheckListFilter(
                                                'waypointDescription',
                                                getWaypointDescriptionFilter,
                                                'columnWaypointDescription',
                                                'Waypoint',
                                                columnWaypointDescription
                                            )
                                        "
                                                >Waypoint</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'waypointDescription'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"  
                                        style="text-align: center;"                                  
                                        [ngClass]="{
                                            hidecells: !showHideColumns.waypoint,                                            
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.waypointDescription }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="milestoneDescription">
                                    <th
                                        mat-header-cell                                        
                                        disableClear
                                        *matHeaderCellDef                                        
                                        [ngClass]="{
                                            hidecells: !showHideColumns.milestone,
                                            'header-filtered': showUnderlineForCheckListColumn(columnMilestoneDescription)
                                        }"
                                    >
                                        <div class="sort-container">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                            openHeaderCheckListFilter(
                                                'milestoneDescription',
                                                getMilestoneDescriptionFilter,
                                                'columnMilestoneDescription',
                                                'Milestone',
                                                columnMilestoneDescription
                                            )
                                        "
                                                >Milestone</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'milestoneDescription'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"  
                                        style="text-align: center;"                                  
                                        [ngClass]="{
                                            hidecells: !showHideColumns.milestone,                                            
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.milestoneDescription }}
                                    </td>
                                </ng-container>
                                
                                <!-- <ng-container matColumnDef="milestoneTargetDate">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.milestone && !showHideColumns.waypoint,
                                            'header-filtered': showUnderlineForCalendarColumn(columnMilestoneTargetDate)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'milestoneTargetDate',
                                                'columnMilestoneTargetDate',
                                                'Milestone Target Date',
                                                columnMilestoneTargetDate
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>Roadmap Target Date</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'milestoneTargetDate'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.milestone && !showHideColumns.waypoint,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.milestoneTargetDate | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container> -->


                                <ng-container matColumnDef="systemOwner">
                                    <th
                                        mat-header-cell                                        
                                        disableClear
                                        *matHeaderCellDef                                        
                                        [ngClass]="{
                                            hidecells: !showHideColumns.systemOwner,
                                            'header-filtered': showUnderlineForCheckListColumn(columnSystemOwner)
                                        }"
                                    >
                                        <div class="sort-container">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                                    openHeaderCheckListFilter(
                                                        'systemOwner',
                                                        getSystemOwnerFilter,
                                                        'columnSystemOwner',
                                                        'System Owner',
                                                        columnSystemOwner
                                                    )
                                                "
                                                >System Owner</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"                
                                        [ngClass]="{
                                            hidecells: !showHideColumns.systemOwner,                                           
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.systemOwner }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="mcActual">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.mcActualDate,
                                            'header-filtered': showUnderlineForCalendarColumn(columnMCActual)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'mcActual',
                                                'columnMCActual',
                                                'MC Actual',
                                                columnMCActual
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>MC Actual</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'mcActual'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.mcActualDate,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.mcActual | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="walkdownForecast">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.walkdownForecast,
                                            'header-filtered': showUnderlineForCalendarColumn(columnWalkdownForecast)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'walkdownForecast',
                                                'columnWalkdownForecast',
                                                'Walkdown Forecast',
                                                columnWalkdownForecast
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>Walkdown Forecast</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'walkdownForecast'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        (click)="openDatepicker(element, 'rfsuWalkdown')"
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.walkdownForecast,
                                            'cursor-pointer':
                                                isAdmin ||
                                                (!isReadOnly && !element.walkdownActual && !element.walkdownCommitted),
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                        (contextmenu)="forecastDateHistory($event, element, 'rfsuWalkdown')"
                                    >
                                        {{ element.walkdownForecast | date: 'd/MMM/yy' }}
                                        <button
                                            *ngIf="!element.walkdownForecast && !isReadOnly && !element.walkdownActual"
                                            type="button"
                                            mat-icon-button
                                        >
                                            <mat-icon>calendar_today</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="walkdownSch">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.walkdownScheduled,
                                            'header-filtered': showUnderlineForCalendarColumn(columnWalkdownSch)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'walkdownSch',
                                                'columnWalkdownSch',
                                                'Walkdown Scheduled',
                                                columnWalkdownSch
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>Walkdown Scheduled</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'walkdownSch'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.walkdownScheduled,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.walkdownSch | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="walkdownActual">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.walkdownActual,
                                            'header-filtered': showUnderlineForCalendarColumn(columnWalkdownActual)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'walkdownActual',
                                                'columnWalkdownActual',
                                                'Walkdown Actual',
                                                columnWalkdownActual
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>Walkdown Actual</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'walkdownActual'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.walkdownActual,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.walkdownActual | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="safetyRewalkdown">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showSafetyRewalkdown,
                                            'header-filtered': showUnderlineForNumericColumn(columnSafetyRewalkdown)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('safetyRewalkdown', columnSafetyRewalkdown, 'Days to re-walkdown')"
                                                >RFSU Safety Re-walkdown</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'safetyRewalkdown'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showSafetyRewalkdown,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                    <div>
                                        <div>
                                            {{ element.safetyRewalkdown }}
                                        </div>
                                    </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="fplUpload">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showFPLUpload,
                                            'header-filtered': showUnderlineForCalendarColumn(columnFPLUpload)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'fplUpload',
                                                'columnFPLUpload',
                                                'FPL Upload',
                                                columnFPLUpload
                                            )
                                        "
                                    >
                                        <div class="sort-container">
                                            <span>FPL Upload</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'fplUpload'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showFPLUpload,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.fplUpload | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="edossierInitiationDate">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showEdossierInitiation,
                                            'header-filtered': showUnderlineForCalendarColumn(columnEdossierInitiation)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderDateFilter(
                                                'edossierInitiationDate',
                                                'columnEdossierInitiation',
                                                'eDossier Initiation',
                                                columnEdossierInitiation
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>eDossier Initiation</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'edossierInitiationDate'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showEdossierInitiation,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.edossierInitiationDate | date: 'd/MMM/yy' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="edossierStatus">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showEdossierStatus,
                                            'header-filtered': showUnderlineForCheckListColumn(columnEdossierStatus)
                                        }"
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'edossierStatus',
                                                getEdossierStatuses,
                                                'columnEdossierStatus',
                                                'eDossier Status',
                                                columnEdossierStatus,
                                                true,
                                                false,
                                                false
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>eDossier Status</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'edossierStatus'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showEdossierStatus,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                    <a href="{{ eDossierLink(element.subsystem) }}" target="_blank">
                                        {{ element.edossierStatus }}
                                    </a>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="bitr">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells:
                                                !showHideColumns.showBITRTotalAndComplete && !showHideColumns.BITR
                                        }"
                                        [ngStyle]="
                                            showHideColumns.showBITRTotalAndComplete
                                                ? { width: '225px' }
                                                : { width: '80px' }
                                        "
                                    >
                                        <div>B-ITR</div>
                                        <div class="sub-header-container">
                                            <div
                                                *ngIf="showHideColumns.showBITRTotalAndComplete"
                                                class="sort-container hover-pointer"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(columnBitRsTotal)
                                                }"
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'bitRsTotal',
                                                        columnBitRsTotal,
                                                        'B-ITR Total'
                                                    )
                                                "
                                            >
                                                <span>Total</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'bitRsTotal'">
                                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction === 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="showHideColumns.showBITRTotalAndComplete"
                                                class="sort-container hover-pointer"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnBitRsCompleted
                                                    )
                                                }"
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'bitRsCompleted',
                                                        columnBitRsCompleted,
                                                        'B-ITR Complete'
                                                    )
                                                "
                                            >
                                                <span>Complete</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'bitRsCompleted'">
                                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction === 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="showHideColumns.showBITRTotalAndComplete || showHideColumns.BITR"
                                                class="sort-container hover-pointer"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnBitRsRemaining
                                                    )
                                                }"
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'bitRsRemaining',
                                                        columnBitRsRemaining,
                                                        'B-ITR Remaining'
                                                    )
                                                "
                                            >
                                                <span>Remaining</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'bitRsRemaining'">
                                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction === 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="showHideColumns.showBITRTotalAndComplete"
                                                class="sort-container hover-pointer"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnBitRsPercentage
                                                    )
                                                }"
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'bitRsPercentage',
                                                        columnBitRsPercentage,
                                                        'B-ITR Percentage'
                                                    )
                                                "
                                            >
                                                <span>% </span>
                                                <div class="sort-icon" *ngIf="sortBy === 'bitRsPercentage'">
                                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction === 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells:
                                                !showHideColumns.showBITRTotalAndComplete && !showHideColumns.BITR,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                        [ngStyle]="
                                            showHideColumns.showBITRTotalAndComplete
                                                ? { width: '225px' }
                                                : { width: '80px' }
                                        "
                                    >
                                        <div>
                                            <div *ngIf="showHideColumns.showBITRTotalAndComplete">
                                                <span
                                                    ><a
                                                        (click)="
                                                            openDetailedStatusPage(
                                                                element.subsystem,
                                                                'BITR',
                                                                element.discipline,
                                                                null,
                                                                ['Complete', 'Not Started']
                                                            )
                                                        "
                                                        >{{ element.bitRsTotal }}</a
                                                    ></span
                                                >
                                            </div>
                                            <div *ngIf="showHideColumns.showBITRTotalAndComplete">
                                                <span
                                                    ><a
                                                        (click)="
                                                            openDetailedStatusPage(
                                                                element.subsystem,
                                                                'BITR',
                                                                element.discipline,
                                                                null,
                                                                ['Complete']
                                                            )
                                                        "
                                                        >{{ element.bitRsCompleted }}</a
                                                    ></span
                                                >
                                            </div>
                                            <div
                                                *ngIf="showHideColumns.showBITRTotalAndComplete || showHideColumns.BITR"
                                            >
                                                <span
                                                    ><a
                                                        (click)="
                                                            openDetailedStatusPage(
                                                                element.subsystem,
                                                                'BITR',
                                                                element.discipline,
                                                                null,
                                                                ['Not Started']
                                                            )
                                                        "
                                                        >{{ element.bitRsRemaining }}</a
                                                    ></span
                                                >
                                            </div>
                                            <div *ngIf="showHideColumns.showBITRTotalAndComplete">
                                                {{ element.bitRsPercentage | number: '1.0-0' }}%
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="citr">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells:
                                                !showHideColumns.showCITRTotalAndComplete && !showHideColumns.CITR
                                        }"
                                        [ngStyle]="
                                            showHideColumns.showCITRTotalAndComplete
                                                ? { width: '225px' }
                                                : { width: '80px' }
                                        "
                                    >
                                        <div>C-ITR</div>
                                        <div class="sub-header-container">
                                            <div
                                                *ngIf="showHideColumns.showCITRTotalAndComplete"
                                                class="sort-container hover-pointer"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(columnCitRsTotal)
                                                }"
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'citRsTotal',
                                                        columnCitRsTotal,
                                                        'C-ITR Total'
                                                    )
                                                "
                                            >
                                                <span>Total</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'citRsTotal'">
                                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction === 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="showHideColumns.showCITRTotalAndComplete"
                                                class="sort-container hover-pointer"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnCitRsCompleted
                                                    )
                                                }"
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'citRsCompleted',
                                                        columnCitRsCompleted,
                                                        'C-ITR Complete'
                                                    )
                                                "
                                            >
                                                <span>Complete</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'citRsCompleted'">
                                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction === 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="showHideColumns.showCITRTotalAndComplete || showHideColumns.CITR"
                                                class="sort-container hover-pointer"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnCitRsRemaining
                                                    )
                                                }"
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'citRsRemaining',
                                                        columnCitRsRemaining,
                                                        'C-ITR Remaining'
                                                    )
                                                "
                                            >
                                                <span>Remaining</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'citRsRemaining'">
                                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction === 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="showHideColumns.showCITRTotalAndComplete"
                                                class="sort-container hover-pointer"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnCitRsPercentage
                                                    )
                                                }"
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'citRsPercentage',
                                                        columnCitRsPercentage,
                                                        'C-ITR Percentage'
                                                    )
                                                "
                                            >
                                                <span>% </span>
                                                <div class="sort-icon" *ngIf="sortBy === 'citRsPercentage'">
                                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction === 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells:
                                                !showHideColumns.showCITRTotalAndComplete && !showHideColumns.CITR,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                        [ngStyle]="
                                            showHideColumns.showCITRTotalAndComplete
                                                ? { width: '225px' }
                                                : { width: '80px' }
                                        "
                                    >
                                        <div>
                                            <div *ngIf="showHideColumns.showCITRTotalAndComplete">
                                                <span
                                                    ><a
                                                        (click)="
                                                            openDetailedStatusPage(
                                                                element.subsystem,
                                                                'CITR',
                                                                element.discipline,
                                                                null,
                                                                ['Complete', 'Not Started']
                                                            )
                                                        "
                                                        >{{ element.citRsTotal }}</a
                                                    ></span
                                                >
                                            </div>
                                            <div *ngIf="showHideColumns.showCITRTotalAndComplete">
                                                <span
                                                    ><a
                                                        (click)="
                                                            openDetailedStatusPage(
                                                                element.subsystem,
                                                                'CITR',
                                                                element.discipline,
                                                                null,
                                                                ['Complete']
                                                            )
                                                        "
                                                        >{{ element.citRsCompleted }}</a
                                                    ></span
                                                >
                                            </div>
                                            <div
                                                *ngIf="showHideColumns.showCITRTotalAndComplete || showHideColumns.CITR"
                                            >
                                                <span
                                                    ><a
                                                        (click)="
                                                            openDetailedStatusPage(
                                                                element.subsystem,
                                                                'CITR',
                                                                element.discipline,
                                                                null,
                                                                ['Not Started']
                                                            )
                                                        "
                                                        >{{ element.citRsRemaining }}</a
                                                    ></span
                                                >
                                            </div>
                                            <div *ngIf="showHideColumns.showCITRTotalAndComplete">
                                                {{ element.citRsPercentage | number: '1.0-0' }}%
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="act">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.ACT,
                                            'header-filtered': showUnderlineForNumericColumn(columnACTsRemaining)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('ACTsRemaining', columnACTsRemaining, 'ACT Remaining')"
                                                >ACTs Remaining</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'act'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.ACT,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a (click)="openDetailedStatusPage(
                                                element.subsystem,
                                                'BITRandCITR',
                                                element.discipline,
                                                'act',
                                                ['Not Started']
                                                )">
                                                {{ element.acTsRemaining }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="npw">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.NPW,
                                            'header-filtered': showUnderlineForNumericColumn(columnNpw)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('npw', columnNpw, 'NPW')"
                                                >NPW</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'npw'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.NPW,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a (click)="openChangeManagementPage(element.subsystem, 'NPWBluezone')">
                                                {{ element.npw }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="aqvd">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.AQVD,
                                            'header-filtered': showUnderlineForNumericColumn(columnAqvd)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('aqvd', columnAqvd, 'A-QVD')"
                                                >A-QVD</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'aqvd'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.AQVD,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                                (click)="
                                                    openDetailedStatusPage(
                                                        element.subsystem,
                                                        'AQVD',
                                                        element.discipline,
                                                        null,
                                                        ['Not Started']
                                                    )
                                                "
                                            >
                                                {{ element.aqvd }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="apli">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.APLI,
                                            'header-filtered': showUnderlineForNumericColumn(columnApli)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('apli', columnApli, 'A-PLI')"
                                                >A-PLI</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'apli'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.APLI,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                                (click)="
                                                    openDetailedStatusPage(
                                                        element.subsystem,
                                                        'PLI',
                                                        element.discipline,
                                                        'A'
                                                    )
                                                "
                                            >
                                                {{ element.apli }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="bpli">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.BPLI,
                                            'header-filtered': showUnderlineForNumericColumn(columnBpli)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('bpli', columnBpli, 'B-PLI')"
                                                >B-PLI</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'bpli'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.BPLI,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                                (click)="
                                                    openDetailedStatusPage(
                                                        element.subsystem,
                                                        'PLI',
                                                        element.discipline,
                                                        'B'
                                                    )
                                                "
                                            >
                                                {{ element.bpli }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="cpli">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.CPLI,
                                            'header-filtered': showUnderlineForNumericColumn(columnCpli)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('cpli', columnCpli, 'C-PLI')"
                                                >C-PLI</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'cpli'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.CPLI,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                                (click)="
                                                    openDetailedStatusPage(
                                                        element.subsystem,
                                                        'PLI',
                                                        element.discipline,
                                                        'C'
                                                    )
                                                "
                                            >
                                                {{ element.cpli }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="dpli">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.DPLI,
                                            'header-filtered': showUnderlineForNumericColumn(columnDpli)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('dpli', columnDpli, 'D-PLI')"
                                                >D-PLI</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'dpli'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.DPLI,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                                (click)="
                                                    openDetailedStatusPage(
                                                        element.subsystem,
                                                        'PLI',
                                                        element.discipline,
                                                        'D'
                                                    )
                                                "
                                            >
                                                {{ element.dpli }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="pascr">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.PASCR,
                                            'header-filtered': showUnderlineForNumericColumn(columnPascr)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('pascr', columnPascr, 'PAS CR')"
                                                >PAS CR</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'pascr'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.PASCR,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                                (click)="gotoPascr(element)"
                                            >
                                                {{ element.pascr }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ex">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.EX,
                                            'header-filtered': showUnderlineForNumericColumn(columnEx)
                                        }"
                                        class="end"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('ex', columnEx, 'Exceptions')"
                                                >Exc</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'ex'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.EX,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                                (click)="
                                                    openDetailedStatusPage(element.subsystem, 'EX', element.discipline)
                                                "
                                            >
                                                {{ element.ex }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="deliveryEng">
                                    <th
                                        mat-header-cell
                                        app-col-resize
                                        disableClear
                                        *matHeaderCellDef
                                        class="sticky-col3"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.DE,
                                            'header-filtered': showUnderlineForCheckListColumn(columnDeliveryEngineers)
                                        }"
                                    >
                                        <div class="sort-container">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                                    openHeaderCheckListFilter(
                                                        'deliveryEngineer',
                                                        getDeliveryEngineer,
                                                        'columnDeliveryEngineer',
                                                        'Delivery Engineer',
                                                        columnDeliveryEngineers
                                                    )
                                                "
                                                >Delivery Engineer</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'deliveryEng'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.DE,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.deliveryEngineer }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="constrainedActivities">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.constrainedActivities,
                                            'header-filtered': showUnderlineForNumericColumn(
                                                columnConstrainedActivitiesCount
                                            )
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'constrainedActivitiesCount',
                                                        columnConstrainedActivitiesCount,
                                                        'Constrained Activities'
                                                    )
                                                "
                                                >Constrained ITRs</span
                                            >
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.constrainedActivities,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                            (click)="
                                                openDetailedStatusPage(
                                                    element.subsystem,
                                                    'ConstraintDetails',
                                                    '',
                                                    ''
                                                )
                                            "
                                        >
                                                {{ element.constrainedActivitiesCount }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="cow">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.COW,
                                            'header-filtered': showUnderlineForNumericColumn(columnCow)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('cow', columnCow, 'COW')"
                                                >COW</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'cow'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.COW,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                                (click)="
                                                    openDetailedStatusPage(element.subsystem, 'COW', element.discipline)
                                                "
                                            >
                                                {{ element.cow }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="redline">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.redline,
                                            'header-filtered': showUnderlineForNumericColumn(columnRedline)
                                        }"
                                        class="end"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('redline', columnRedline, 'Redline')"
                                                >Redline</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'redline'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.redline,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a
                                                (click)="
                                                    openDetailedStatusPage(
                                                        element.subsystem,
                                                        'Redline',
                                                        element.discipline
                                                    )
                                                "
                                            >
                                                {{ element.redline }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="priorityName">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        class="hover-pointer"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showPriorityName,
                                            'header-filtered': columnPriorityName && columnPriorityName.items.length > 0
                                        }"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'priorityName',
                                                getPriorityNames,
                                                'columnPriorityName',
                                                'PriorityName',
                                                columnPriorityName,
                                                true,
                                                false,
                                                false
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>Staged Start-up Priority</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'priorityName'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showPriorityName,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.priorityName }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="areaBreakdown">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        class="hover-pointer"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showAreaBreakdown,
                                            'header-filtered': columnAreaBreakdown && columnAreaBreakdown.items.length > 0
                                        }"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'areaBreakdown',
                                                getAreaBreakdowns,
                                                'columnAreaBreakdown',
                                                'AreaBreakdown',
                                                columnAreaBreakdown,
                                                true,
                                                false,
                                                false
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>Area Breakdown</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'areaBreakdown'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showAreaBreakdown,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.areaBreakdown }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="contractorNo">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        class="hover-pointer"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showContractorNo,
                                            'header-filtered': columnContractorNo && columnContractorNo.items.length > 0
                                        }"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'contractorNo',
                                                getContractorNo,
                                                'columnContractorNo',
                                                'ContractorNo',
                                                columnContractorNo,
                                                true,
                                                false,
                                                false
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>RFSU Contractor</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'contractorNo'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showContractorNo,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.contractorNo }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="areaCompletionLead">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        class="hover-pointer"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showAreaCompletionLead,
                                            'header-filtered': columnAreaCompletionLead && columnAreaCompletionLead.items.length > 0
                                        }"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'areaCompletionLead',
                                                getAreaCompletionLeads,
                                                'columnAreaCompletionLead',
                                                'AreaCompletionLead',
                                                columnAreaCompletionLead,
                                                true,
                                                false,
                                                false
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>Area Completion Lead</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'areaCompletionLead'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showAreaCompletionLead,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.areaCompletionLead }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="nonCommissionable">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        class="hover-pointer"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showNonComm,
                                            'header-filtered':
                                                columnNonCommissionable && columnNonCommissionable.items?.length > 0
                                        }"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'nonCommissionable',
                                                getNonCommissionable,
                                                'columnNonCommissionable',
                                                'NonCommissionable',
                                                columnNonCommissionable,
                                                false,
                                                false
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>Non Comm</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'nonCommissionable'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showNonComm,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.nonCommissionable }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="flangeMgt">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showFlangeMgt
                                        }"
                                        [ngStyle]="{ width: '225px' }"
                                    >
                                        <div>Flange Mgt</div>
                                        <div class="sub-header-container">
                                            <div
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'flangeMgtTotal',
                                                        columnFlangeMgtTotal,
                                                        'Flange Management Total'
                                                    )
                                                "
                                                class="sort-container hover-pointer center"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnFlangeMgtTotal
                                                    )
                                                }"
                                            >
                                                <span>Total</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'flangeMgtTotal'">
                                                    <mat-icon *ngIf="direction == 'asc'"
                                                        >keyboard_arrow_up</mat-icon
                                                    >
                                                    <mat-icon *ngIf="direction == 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'flangeMgtTotalCompleted',
                                                        columnFlangeMgtTotalCompleted,
                                                        'Flange Management Complete'
                                                    )
                                                "
                                                class="sort-container hover-pointer center"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnFlangeMgtTotalCompleted
                                                    )
                                                }"
                                            >
                                                <span>Complete</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'flangeMgtCompleted'">
                                                    <mat-icon *ngIf="direction == 'asc'"
                                                        >keyboard_arrow_up</mat-icon
                                                    >
                                                    <mat-icon *ngIf="direction == 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'flangeMgtTotalRemaining',
                                                        columnFlangeMgtTotalRemaining,
                                                        'Flange Management Remaining'
                                                    )
                                                "
                                                class="sort-container hover-pointer center"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnFlangeMgtTotalRemaining
                                                    )
                                                }"
                                            >
                                                <span>Remaining</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'flangeMgtRemaining'">
                                                    <mat-icon *ngIf="direction == 'asc'"
                                                        >keyboard_arrow_up</mat-icon
                                                    >
                                                    <mat-icon *ngIf="direction == 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                (click)="
                                                    openHeaderNumericFilter(
                                                        'flangeMgtTotalPercentage',
                                                        columnFlangeMgtTotalPercentage,
                                                        'Flange Management Percentage'
                                                    )
                                                "
                                                class="sort-container hover-pointer center"
                                                [ngClass]="{
                                                    'header-filtered': showUnderlineForNumericColumn(
                                                        columnFlangeMgtTotalPercentage
                                                    )
                                                }"
                                            >
                                                <span>%</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'flangeMgtPercentage'">
                                                    <mat-icon *ngIf="direction == 'asc'"
                                                        >keyboard_arrow_up</mat-icon
                                                    >
                                                    <mat-icon *ngIf="direction == 'desc'"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showFlangeMgt,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                        [ngStyle]="{ width: '225px' }"
                                    >
                                        <div>
                                            <div class="total" *ngIf="showHideColumns.showFlangeMgt">
                                                {{ element.flangeMgtTotal }}
                                            </div>
                                            <div *ngIf="showHideColumns.showFlangeMgt">
                                                {{ element.flangeMgtTotalCompleted }}
                                            </div>
                                            <div *ngIf="showHideColumns.showFlangeMgt">
                                                {{ element.flangeMgtTotalRemaining }}
                                            </div>
                                            <div class="percent" *ngIf="showHideColumns.showFlangeMgt">
                                                {{ element.flangeMgtTotalPercentage | number: '1.0-0' }}%
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="lun">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.LUN,
                                            'header-filtered': showUnderlineForNumericColumn(columnLun)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="openHeaderNumericFilter('lun', columnLun, 'LUN')"
                                                >LUN</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'lun'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.LUN,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <span
                                            ><a (click)="openLUNPage(element.subsystem)">
                                                {{ element.lun }}
                                            </a></span
                                        >
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="goocNo">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        class="hover-pointer"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showGoocNo,
                                            'header-filtered': columnGoocNo && columnGoocNo.items.length > 0
                                        }"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'goocNo',
                                                getGoocNo,
                                                'columnGoocNo',
                                                'GoocNo',
                                                columnGoocNo,
                                                false,
                                                false
                                            )
                                        "
                                    >
                                        <div class="sort-container center">
                                            <span>GOOC No</span>
                                            <div class="sort-icon" *ngIf="sortBy === 'goocNo'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showGoocNo,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        {{ element.goocNo }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="backlogCat">
                                    <th
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showBacklogCat,
                                            'header-filtered': showUnderlineForCheckListColumn(columnBacklogCats)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                                    openHeaderCheckListFilter(
                                                        'backlogCat',
                                                        getBacklogCats,
                                                        'columnBacklogCats',
                                                        'Backlog Cat',
                                                        columnBacklogCats,
                                                        true
                                                    )
                                                "
                                                >Backlog Cat</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'backlogCat'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{
                                            hidecells: !showHideColumns.showBacklogCat,
                                            'row-underline': isRowUnderlined(data, idx)
                                        }"
                                    >
                                        <div *ngIf="!element.isInEditMode">
                                            <span>{{ element.backlogCat }}</span>
                                            <button
                                                *ngIf="isBacklogCatEditable(element)"
                                                mat-icon-button
                                                (click)="enterEditMode(element)"
                                                title="edit"
                                            >
                                                <mat-icon svgIcon="edit"></mat-icon>
                                            </button>
                                        </div>
                                        <div *ngIf="element.isInEditMode">
                                            <mat-form-field appearance="fill">
                                                <mat-label>Backlog Cat</mat-label>
                                                <mat-select [(value)]="element.backlogCat" disableOptionCentering>
                                                    <mat-option
                                                        *ngFor="let backlogCat of backlogCats"
                                                        [value]="backlogCat"
                                                        >{{ backlogCat }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <button mat-icon-button (click)="saveBacklogCat(element)" title="accept">
                                                <mat-icon>check_circle_outline</mat-icon>
                                            </button>
                                            <button mat-icon-button (click)="cancelEdit(element)" title="cancel">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="comment" stickyEnd>
                                    <th
                                        app-col-resize
                                        mat-header-cell
                                        disableClear
                                        *matHeaderCellDef
                                        [ngClass]="{
                                            'header-filtered': showUnderlineForCheckListColumn(columnComments)
                                        }"
                                    >
                                        <div class="sort-container center">
                                            <span
                                                class="hover-pointer"
                                                (click)="
                                                    openHeaderCheckListFilter(
                                                        'comment',
                                                        getComments,
                                                        'columnComments',
                                                        'Comment',
                                                        columnComments,
                                                        false,
                                                        true,
                                                        true
                                                    )
                                                "
                                                >Comment</span
                                            >
                                            <div class="sort-icon" *ngIf="sortBy === 'comment'">
                                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let element; let idx = index"
                                        [ngClass]="{ 'row-underline': isRowUnderlined(data, idx) }"
                                    >
                                        <div>
                                            <div
                                                (click)="openCommentPopup(element, $event)"
                                                class="comment-description"
                                            >
                                                <div *ngIf="element.comment && !isReadOnly; else notShow">
                                                    <a
                                                        ><span
                                                            [innerHTML]="
                                                                element.comment | formatMentions: element.mentions
                                                            "
                                                        ></span
                                                    ></a>
                                                </div>
                                                <div *ngIf="isReadOnly">
                                                    <span
                                                        [innerHTML]="element.comment | formatMentions: element.mentions"
                                                    ></span>
                                                </div>
                                                <ng-template #notShow>
                                                    <button
                                                        type="button"
                                                        *ngIf="!isReadOnly"
                                                        mat-stroked-button
                                                        class="add-comment"
                                                    >
                                                        Add
                                                    </button>
                                                </ng-template>
                                            </div>
                                            <div
                                                [ngClass]="{ notactive: !element.comment, active: element.comment }"
                                                class="comment-hist"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    (click)="openHistoryPopup(element)"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr
                                    mat-row
                                    *matRowDef="let row; columns: displayedColumns"
                                    [ngClass]="{ lastsubsystem: row.isLastInGroup, highlight: selectedRowIndex == row }"
                                    (click)="highlightRow(row)"
                                ></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 50]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>
<ng-template #trafficLightTemplate let-subsystem="subsystem">
    <button class="red" type="button" mat-mini-fab (click)="flagSubsystem(subsystem, 'red')">&nbsp;</button>
    <button class="orange" type="button" mat-mini-fab (click)="flagSubsystem(subsystem, 'orange')">&nbsp;</button>
    <button class="green" type="button" mat-mini-fab (click)="flagSubsystem(subsystem, 'green')">&nbsp;</button>
    <button class="purple" type="button" mat-mini-fab (click)="flagSubsystem(subsystem, 'purple')">&nbsp;</button>
</ng-template>

<div class="tables-container" #detailedPage>   

    <div *ngIf="activeScopeDetailedView() && showDetailedPage !== 'NPWBluezone' && showDetailedPage !== 'PASCR'">
        <app-detailed-status
        [blueScreen]="true"
        [blueScope]="blueScope"
        [blueSubsystem]="blueSubsystem"
        [blueStatus]="blueStatus"
        [blueDiscipline]="blueDiscipline"
        [blueZone]="blueZone"
        [blueCategory]="blueCategory"
        [blueAllowComments]="true"
        [blueLockFilters]="true"
        >                
        </app-detailed-status>
    </div>

    <div *ngIf="activeScopeDetailedView() && showDetailedPage === 'NPWBluezone'">
        <app-change-management
        [blueScreen]="true"
        [blueSubsystem]="blueSubsystem"
        [blueChangeType]="blueChangeType"
        [blueAllowComments]="true"
        [blueLockFilters]="true"
        >                
        </app-change-management>
    </div>

    <div *ngIf="activeScopeDetailedView() && showDetailedPage === 'PASCR'">
        <pascr-status
        [blueScreen]="true"
        [blueSubsystem]="blueSubsystem"
        >                
        </pascr-status>
    </div>

    
</div>


