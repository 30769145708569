import { Injectable } from '@angular/core';
import { ChangeDocumentDetailsDTO } from 'src/app/store/change-document-details/model';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class AddNewChangeService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly addNewChangeEndpoint: string = '/addnewchange';

    constructor(private http: HttpClient) {}

    addNewChange(changeDocument: Partial<ChangeDocumentDetailsDTO>) {
        return this.http.post(`${this.rootUrl}${this.addNewChangeEndpoint}`, changeDocument);
    }
}
