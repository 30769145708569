<main class="main">
    <a class="navigator" [routerLink]="['/weeklyplanning/dashboard']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            Exclude configuration
        </div>
    </div>
    <div class="body-container">
        <div class="content-container">
            <mat-tab-group
                animationDuration="0ms"
                [selectedIndex]="selected.value"
                (selectedIndexChange)="selected.setValue($event)"
            >
                <mat-tab label="Subsystem">
                    <app-sc-exclude-subsystem-configuration
                        *ngIf="selected.value == 0"
                    ></app-sc-exclude-subsystem-configuration>
                </mat-tab>
                <mat-tab label="ITR">
                    <app-sc-exclude-itr-configuration *ngIf="selected.value == 1"></app-sc-exclude-itr-configuration>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading$ | async"></app-loading-indicator>
</main>
