import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, OrderDirection } from '../../common.model';

export interface CITRPartialProgressState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deleteData: CITRPartialProgressDeletePagination;
    isDeleteDataLoading: boolean;
    deleteFilter: CITRPartialProgressDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class CITRPartialProgressDeleteDTO {
    deleteState: boolean;
    qvdNumber: string;
    barcode: string;
}

export class CITRPartialProgressDeleteFilter {
    page = 0;
    take = 10;
    sortBy = 'barcode';
    direction: OrderDirection = OrderDirection.Desc;
    columnQVDNumber: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
}
export class CITRPartialProgressDeletePagination {
    items: CITRPartialProgressDeleteDTO[] = [];
    totalCount = 0;
}
