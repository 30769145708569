<div class="wrapper">
    <div
        *ngIf="planningItemsTableDataSource?.length === 0 && activityType === activityTypes.notInWeek"
        class="buttons-container-end flex"
    >
        <button class="sct-button sct-button-light button-wide" (click)="addSubsystem()">add subsystem</button>
    </div>
    <div *ngIf="planningItemsTableDataSource?.length" class="buttons-container flex">
        <span>
            <button class="sct-button sct-button-light" (click)="expandAll()" *ngIf="!expanded">expand all</button>
            <button class="sct-button sct-button-light" (click)="collapseAll()" *ngIf="expanded">collapse all</button>
            <mat-checkbox
                class="table-checkbox"
                [checked]="showHideColumns.showPlanDates"
                (change)="reverseValue('showPlanDates')"
            >
                Schedule Dates
            </mat-checkbox>

            <mat-checkbox
                class="table-checkbox"
                [checked]="showHideColumns.showForecastDates"
                (change)="reverseValue('showForecastDates')"
            >
                Forecast Dates
            </mat-checkbox>
            <mat-checkbox
                *ngIf="weeklyPlanType === weeklyPlanTypes.monthly"
                class="table-checkbox"
                [checked]="showHideColumns.showMCMilestone"
                (change)="reverseValue('showMCMilestone')"
            >
                MC Milestone
            </mat-checkbox>
            <mat-checkbox
                *ngIf="weeklyPlanType === weeklyPlanTypes.monthly"
                class="table-checkbox"
                [checked]="showHideColumns.showRFSUMilestone"
                (change)="reverseValue('showRFSUMilestone')"
            >
                RFSU Milestone
            </mat-checkbox>
            <mat-checkbox
                *ngIf="weeklyPlanType === weeklyPlanTypes.monthly"
                class="table-checkbox"
                [checked]="showHideColumns.showActivityId"
                (change)="reverseValue('showActivityId')"
            >
                Activity Id
            </mat-checkbox>
            <mat-checkbox
                *ngIf="weeklyPlanType === weeklyPlanTypes.monthly"
                class="table-checkbox"
                [checked]="showHideColumns.showConstraintType"
                (change)="reverseValue('showConstraintType')"
            >
                Constraint Type
            </mat-checkbox>
        </span>
        <div *ngIf="weeklyPlanType === weeklyPlanTypes.weekly && !lockFilters">
            <button
                *ngIf="activityType === activityTypes.inWeek"
                class="sct-button sct-button-light button-wide"
                (click)="goToAddSubsystem()"
            >
                add subsystem not in week
            </button>
            <button
                *ngIf="activityType === activityTypes.notInWeek"
                class="sct-button sct-button-light button-wide"
                (click)="addSubsystem()"
            >
                add subsystem
            </button>
            <button class="sct-button sct-button-light" (click)="showITRInWeek()">show ITR in week</button>
        </div>
    </div>
    <div class="activity" *ngFor="let element of planningItemsTableDataSource">
        <div *ngIf="weeklyPlanType === weeklyPlanTypes.weekly">
            <div class="activity__header">
                <p>
                    <span class="margin-right40 redirect-link" (click)="redirectToBluezone(element.subsystem)"
                        >{{ element.subsystem }} </span
                    ><span (click)="table.showActivities = !table.showActivities">{{ element.subsystemName }}</span>
                    <mat-icon (click)="table.showActivities = true" *ngIf="!table.showActivities"
                        >chevron_right
                    </mat-icon>
                    <mat-icon class="margin-right40" (click)="table.showActivities = false" *ngIf="table.showActivities">expand_more</mat-icon>
                    <span>Priority = {{ element.activityFocus }}</span>
                </p>
            </div>

            <app-schedule-activity-planning-subsystem-table
                #table
                [subsystemWithActivities]="element"
                [additionToPlan]="additionToPlan"
                [showHideColumns]="showHideColumns"
                [activityType]="activityType"
            >
            </app-schedule-activity-planning-subsystem-table>
        </div>

        <div *ngIf="weeklyPlanType !== weeklyPlanTypes.weekly">
            <div class="activity__header">
                <p>
                    <span class="margin-right40 redirect-link" (click)="redirectToBluezone(element.subsystem)"
                        >{{ element.subsystem }} </span
                    ><span (click)="table.showActivities = !table.showActivities">{{ element.subsystemName }}</span>
                    <mat-icon (click)="table.showActivities = true" *ngIf="!table.showActivities"
                        >chevron_right
                    </mat-icon>
                    <mat-icon class="margin-right40" (click)="table.showActivities = false" *ngIf="table.showActivities">expand_more</mat-icon>
                    <span>Priority = {{ element.activityFocus }}</span>
                </p>
            </div>

            <app-schedule-activity-lookahead-table
                #table
                [subsystemWithActivities]="element"
                [activityType]="activityType"
            >
            </app-schedule-activity-lookahead-table>
        </div>
    </div>
</div>
