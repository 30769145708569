import { PascrStatusState, PascrStatusFilter, PascrStatusDataPagination } from './model';
import { PascrStatusActions, PascrStatusActionTypes } from './actions';
import { Constants } from 'src/app/constants';
import * as moment from 'moment';
import { act } from '@ngrx/effects';
import * as _ from "lodash";
import { Config } from 'src/app/models/config';
import { constant } from 'lodash';

import { dateNow } from '@microsoft/applicationinsights-core-js';
import { dateTimeUtilsNow } from '@microsoft/applicationinsights-common';
import { Action } from 'rxjs/internal/scheduler/Action';

const initialState: PascrStatusState = {
    dataPagination: new PascrStatusDataPagination(),
    isLoading: false,
    filter: new PascrStatusFilter(),
};

export function reducer(state: PascrStatusState = initialState, action: PascrStatusActions): PascrStatusState {
    switch (action.type) {
        case PascrStatusActionTypes.PascrStatusAddCommentError:
        case PascrStatusActionTypes.PascrStatusAddBulkCommentError:
        case PascrStatusActionTypes.PascrStatusRemoveCommentError:
        case PascrStatusActionTypes.PascrStatusAddBulkCommentRequest:
        case PascrStatusActionTypes.PascrStatusRemoveCommentRequest:
        case PascrStatusActionTypes.PascrStatusAddCommentRequest:
        case PascrStatusActionTypes.PascrStatusFilterRequest: {
            return {
                ...state,
                isLoading: true
            };
        }

        case PascrStatusActionTypes.PascrStatusFilterError:{
            return {
                ...state,
                isLoading: false,
            };
        }
        case PascrStatusActionTypes.PascrStatusFilterSuccess: {
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        }

        case PascrStatusActionTypes.PascrStatusFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(action.payload.value)
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case PascrStatusActionTypes.PascrStatusFilterReset: {
            const newFilter = new PascrStatusFilter();
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case PascrStatusActionTypes.PascrStatusAddCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex(
                (i) =>
                    i.pascrNumber === action.payload.pascrNumber
            );

            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                comment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case PascrStatusActionTypes.PascrStatusAddBulkCommentSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((item) => ({
                        ...item,
                        comment: action.payload.description,
                        mentions: action.payload.mentions,
                    })),
                },
            };
        }
        case PascrStatusActionTypes.PascrStatusRemoveCommentSuccess:{
            const itemIndex = state.dataPagination.items.findIndex(
                (i) =>
                    i.pascrNumber === action.payload.pascrNumber
            );
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                comment: action.payload.lastComment.description,
                                mentions: action.payload.lastComment.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case PascrStatusActionTypes.PascrStatusExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case PascrStatusActionTypes.PascrStatusExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case PascrStatusActionTypes.PascrStatusExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
