import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import * as _ from 'lodash';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { ScheduleActivityPlanningConstraint } from 'src/app/components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-constraint-type',
  templateUrl: './constraint-type.component.html',
  styleUrls: ['./constraint-type.component.scss']
})
export class ConstraintTypeComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isLoading = false;
  constraintTypes: ScheduleActivityPlanningConstraint[] = [];
  displayedColumns = ['name', 'actions'];
  resultsLength = 0;
  pageSize = 10;
  
  constraintTypesData: MatTableDataSource<ScheduleActivityPlanningConstraint>;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private lookupService: ScheduleActivityLookupService,
    private configurationService: ConfigurationService,
    private toastService: ToastService
  ) {

    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
    );
    this.iconRegistry.addSvgIcon(
      'delete',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
    );
    this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
  }

  ngOnInit(): void {

    this.lookupService
      .getConstraintTypes()
      .pipe(take(1))
      .subscribe((data) => {
        this.constraintTypes = [
          ...data.map((d) => ({ id: d.id, name: d.name, isInEditMode: false, prevName: '' })),
        ];
        this.constraintTypesData = new MatTableDataSource(this.constraintTypes);
        this.resultsLength = this.constraintTypesData.data.length;
        this.constraintTypesData.paginator = this.paginator;
      });
  }

  add() {
    this.constraintTypes = [{ id: 0, name: '', isInEditMode: true, prevName: '' } ,...this.constraintTypes];
    this.constraintTypesData.data = this.constraintTypes;
    this.constraintTypesData.paginator.firstPage();
  }
  onPageChange(newPage: number) {
    if (newPage < 1) {
        newPage = 1;
    } else if (newPage > this.paginator.getNumberOfPages()) {
        newPage = this.paginator.getNumberOfPages();
    }
    this.paginator.pageIndex = newPage - 1;
    this.constraintTypesData.paginator = this.paginator;
}
  remove(element: ScheduleActivityPlanningConstraint) {
    this.isLoading = true;
    this.configurationService
      .deleteConstraintType(element.id)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false)),
        catchError((error) => {
          this.toastService.Info(
            'This Constraint Type is linked in several places , if you need it to be deleted. Please contact Program Administrator.'
          );
          return of(error);
        })
      )
      .subscribe((id) => {
        if(id > 0) {
          this.constraintTypes = [..._.filter(this.constraintTypes, (c) => c.id !== id)];
          this.constraintTypesData.data = this.constraintTypes;
          this.toastService.Success('Constraint Type has been removed.');
        }
      });
  }

  enterEditMode(element: ScheduleActivityPlanningConstraint) {
    element.isInEditMode = true;
    element.prevName = element.name;
  }

  cancelEdit(element: ScheduleActivityPlanningConstraint) {
    element.isInEditMode = false;
    element.name = element.prevName;
    this.constraintTypes = [..._.filter(this.constraintTypes, (c) => c.id > 0)];
    this.constraintTypesData.data = this.constraintTypes;
    this.resultsLength = this.constraintTypesData.data.length;
    this.constraintTypesData.paginator = this.paginator;
  }

  update(element: ScheduleActivityPlanningConstraint) {
    this.isLoading = true;
    this.configurationService
      .updateConstraintType(element)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          element.isInEditMode = false;
        }),
        catchError((error) => {
          this.toastService.Error(
            'Error has occurred while updating/creating Constraint Type. Please contact Program Administrator.'
          );
          return of(error);
        })
      )
      .subscribe((data) => {
        this.toastService.Success(
          `Constraint Type has been successfully ${data.id !== element.id ? 'created.' : 'updated.'}`
        );
        element.id = data.id;
        this.constraintTypesData.data =  this.constraintTypesData.data.sort((a,b) => a.id < b.id ? -1 : a.id > b.id ? 1: 0);
      });
  }

}
