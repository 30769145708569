

import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { ITRCommentUploadDeleteFilter, ITRCommentUploadDeletePagination, ITRCommentUploadState } from './model';
import { ITRCommentUploadActions, ITRCommentUploadActionTypes } from './actions';

const initialState: ITRCommentUploadState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new ITRCommentUploadDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new ITRCommentUploadDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: ITRCommentUploadActions): ITRCommentUploadState {
    switch (action.type) {
        case ITRCommentUploadActionTypes.ITRCommentUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadPatchAllDeleteRecordsRequest:
        case ITRCommentUploadActionTypes.ITRCommentUploadDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadDeleteRecordsSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadValidateDataError:
        case ITRCommentUploadActionTypes.ITRCommentUploadPatchAllDeleteRecordsError:
        case ITRCommentUploadActionTypes.ITRCommentUploadPatchDeleteRecordError:
        case ITRCommentUploadActionTypes.ITRCommentUploadPatchDeleteRecordSuccess:
        case ITRCommentUploadActionTypes.ITRCommentUploadDeleteRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadDeleteFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        [action.payload.key]: Array.isArray(state.deleteFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadDeleteFilterReset: {
            const newFilter = new ITRCommentUploadDeleteFilter();
            return {
                ...state,
                deleteFilter: { ...newFilter },
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadPatchDeleteRecordRequest: {
            let itemIndex = state.deleteData.items.findIndex((d) => d.itrpliNumber === action.payload.itrpliNumber);
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                deleteState: action.payload.deleteState,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadPatchAllDeleteRecordsSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item) => ({ ...item, deleteState: action.payload.deleteState })),
                },
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadValidateDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadValidateDataSuccess: {
            return {
                ...state,
                deleteData: new ITRCommentUploadDeletePagination(),
                isDeleteDataLoading: false,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new ITRCommentUploadDeletePagination(),
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadDownloadOutputDataRequest:
        case ITRCommentUploadActionTypes.ITRCommentUploadTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadTemplateFileError:
        case ITRCommentUploadActionTypes.ITRCommentUploadDownloadOutputDataSuccess:
        case ITRCommentUploadActionTypes.ITRCommentUploadDownloadOutputDataError:
        case ITRCommentUploadActionTypes.ITRCommentUploadTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case ITRCommentUploadActionTypes.ITRCommentUploadAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'ITRCommentUpload';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

