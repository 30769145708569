import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum PLIActionTypes {
    PLIUploadLogRequest = '[PLI] Upload Log Request',
    PLIUploadLogSuccess = '[PLI] Upload Log Success',
    PLIUploadLogError = '[PLI] Upload Log Error',
    PLISetStatusAndStartDate = '[PLI] Set Status and Start Date',
    PLIFileUploaded = '[PLI] File Uploaded',
    PLIClearInProgressSpinner = '[PLI] Clear In Progress Spinner',
    PLITemplateFileRequest = '[PLI] Template File Request',
    PLITemplateFileSuccess = '[PLI] Template File Success',
    PLITemplateFileError = '[PLI] Template File Error',
    PLIValidateDeltaRequest = '[PLI] Validate Delta Request',
    PLIValidateDeltaSuccess = '[PLI] Validate Delta Success',
    PLIValidateDeltaError = '[PLI] Validate Delta Error',
    PLIDataInfoRequest = '[PLI] Data Info Request',
    PLIDataInfoSuccess = '[PLI] Data Info Success',
    PLIDataInfoError = '[PLI] Data Info Error',
    PLIDownloadOutputDataRequest = '[PLI] Output data Request',
    PLIDownloadOutputDataSuccess = '[PLI] Output data Success',
    PLIDownloadOutputDataError = '[PLI] Output data File Error',
    PLIAddStartedLog = '[PLI] Add Started Log',
    PLIValidateButtonStateError = '[PLI] Validate Button State Error',
    PLIValidateButtonStateSuccess = '[PLI] Validate Button State Success',
}

export class PLIAddStartedLog implements Action {
    readonly type = PLIActionTypes.PLIAddStartedLog;
}

export class PLIUploadLogRequest implements Action {
    readonly type = PLIActionTypes.PLIUploadLogRequest;
}

export class PLIUploadLogSuccess implements Action {
    readonly type = PLIActionTypes.PLIUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class PLIUploadLogError implements Action {
    readonly type = PLIActionTypes.PLIUploadLogError;

    constructor(public payload: string) {}
}

export class PLIFileUploaded implements Action {
    readonly type = PLIActionTypes.PLIFileUploaded;
}

export class PLIClearInProgressSpinner implements Action {
    readonly type = PLIActionTypes.PLIClearInProgressSpinner;
}

export class PLITemplateFileRequest implements Action {
    readonly type = PLIActionTypes.PLITemplateFileRequest;

    constructor(public payload: string) {}
}

export class PLITemplateFileSuccess implements Action {
    readonly type = PLIActionTypes.PLITemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class PLITemplateFileError implements Action {
    readonly type = PLIActionTypes.PLITemplateFileError;

    constructor(public payload: string) {}
}

export class PLIDownloadOutputDataRequest implements Action {
    readonly type = PLIActionTypes.PLIDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class PLIDownloadOutputDataSuccess implements Action {
    readonly type = PLIActionTypes.PLIDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class PLIDownloadOutputDataError implements Action {
    readonly type = PLIActionTypes.PLIDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class PLISetStatusAndStartDate implements Action {
    readonly type = PLIActionTypes.PLISetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class PLIValidateDeltaRequest implements Action {
    readonly type = PLIActionTypes.PLIValidateDeltaRequest;
}

export class PLIValidateDeltaSuccess implements Action {
    readonly type = PLIActionTypes.PLIValidateDeltaSuccess;
}

export class PLIValidateDeltaError implements Action {
    readonly type = PLIActionTypes.PLIValidateDeltaError;

    constructor(public payload: string) {}
}

export class PLIDataInfoRequest implements Action {
    readonly type = PLIActionTypes.PLIDataInfoRequest;
}

export class PLIValidateButtonStateSuccess implements Action {
    readonly type = PLIActionTypes.PLIValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; isAutomaticUploadInProgress: boolean; }) {}
}

export class PLIValidateButtonStateError implements Action {
    readonly type = PLIActionTypes.PLIValidateButtonStateError;

    constructor(public payload: string) {}
}

export type PLIActions =
    | PLIUploadLogRequest
    | PLIUploadLogSuccess
    | PLIUploadLogError
    | PLIFileUploaded
    | PLIClearInProgressSpinner
    | PLITemplateFileRequest
    | PLITemplateFileSuccess
    | PLITemplateFileError
    | PLISetStatusAndStartDate
    | PLIValidateDeltaRequest
    | PLIValidateDeltaSuccess
    | PLIValidateDeltaError
    | PLIDataInfoRequest
    | PLIDownloadOutputDataRequest
    | PLIDownloadOutputDataSuccess
    | PLIDownloadOutputDataError
    | PLIAddStartedLog
    | PLIValidateButtonStateSuccess
    | PLIValidateButtonStateError;
