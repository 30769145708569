import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    RFODatesUploadActionTypes,
    RFODatesUploadLogSuccess,
    RFODatesUploadLogError,
    RFODatesUploadValidateButtonStateSuccess,
    RFODatesUploadValidateButtonStateError,
    RFODatesUploadDownloadOutputDataSuccess,
    RFODatesUploadDownloadOutputDataError,
    RFODatesUploadDownloadOutputDataRequest,
    RFODatesUploadTemplateFileSuccess,
    RFODatesUploadTemplateFileRequest,
    RFODatesUploadTemplateFileError,
    RFODatesUploadClearInProgressSpinner,
    RFODatesUploadValidateDeleteSuccess,
    RFODatesUploadValidateDeleteError,
    RFODatesUploadPatchAllDeleteRequest,
    RFODatesUploadPatchAllDeleteSuccess,
    RFODatesUploadPatchAllDeleteError,
    RFODatesUploadPatchDeleteRequest,
    RFODatesUploadPatchDeleteSuccess,
    RFODatesUploadPatchDeleteError,
    RFODatesUploadDeleteSuccess,
    RFODatesUploadDeleteError,
    RFODatesUploadDeleteRequest,
    RFODatesUploadSetStatusAndStartDate,
} from './actions';

@Injectable()
export class RFODatesUploadEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(RFODatesUploadActionTypes.RFODatesUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['RFO']).pipe(
                map((uploadLogData) => new RFODatesUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new RFODatesUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<RFODatesUploadLogSuccess>(RFODatesUploadActionTypes.RFODatesUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.RFODatesUploadState.uploadLogStatus),
            this.store.select((store) => store.RFODatesUploadState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new RFODatesUploadSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    invokeDeleteRequest$ = this.actions$.pipe(
        ofType(RFODatesUploadActionTypes.RFODatesUploadSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.RFODatesUploadState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        map(() => new RFODatesUploadDeleteRequest())
    );

    @Effect()
    getDelete$ = this.actions$.pipe(
        ofType(RFODatesUploadActionTypes.RFODatesUploadDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.RFODatesUploadState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getRFODatesUploadDeleteRecords(deleteFilter).pipe(
                map((deleteData: any) => new RFODatesUploadDeleteSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete data records. Please contact Program Administrator'
                    );
                    return of(new RFODatesUploadDeleteError(error));
                })
            )
        )
    );

    // @Effect()
    // patchDeleteData$ = this.actions$.pipe(
    //     ofType<RFODatesUploadPatchDeleteRequest>(RFODatesUploadActionTypes.RFODatesUploadPatchDeleteRecordRequest),
    //     mergeMap((action) =>
    //         this.manualUploadsService.patchRFODatesUploadDeleteRecord(
    //             action.payload.activityId, action.payload.activityDiscipline, action.payload.deleteState
    //             ).pipe(
    //             map(() => new RFODatesUploadPatchDeleteSuccess()),
    //             catchError((error) => {
    //                 this.toastService.Error(
    //                     `Error has occurred while ${
    //                         action.payload.deleteState ? 'accepting' : 'rejecting'
    //                     } the change. Please contact Program Administrator`
    //                 );
    //                 return of(new RFODatesUploadPatchDeleteError(error));
    //             })
    //         )
    //     )
    // );

    @Effect()
    patchAllDelete$ = this.actions$.pipe(
        ofType<RFODatesUploadPatchAllDeleteRequest>(RFODatesUploadActionTypes.RFODatesUploadPatchAllDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.RFODatesUploadState.deleteFilter)),
        mergeMap(([action, deleteFilter]) =>
            this.manualUploadsService.patchAllRFODatesUploadDeleteRecords(deleteFilter, action.payload.deleteState).pipe(
                map(() => new RFODatesUploadPatchAllDeleteSuccess({ deleteState: action.payload.deleteState })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new RFODatesUploadPatchAllDeleteError(error));
                })
            )
        )
    );

    @Effect()
    validateDelete$ = this.actions$.pipe(
        ofType(RFODatesUploadActionTypes.RFODatesUploadValidateDataRequest),
        mergeMap(() =>
            this.manualUploadsService.validateRFODelta().pipe(
                map(() => new RFODatesUploadValidateDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new RFODatesUploadValidateDeleteError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(RFODatesUploadActionTypes.RFODatesUploadDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.RFODatesUploadState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new RFODatesUploadClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<RFODatesUploadTemplateFileRequest>(RFODatesUploadActionTypes.RFODatesUploadTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new RFODatesUploadTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Schedule Activity structure file. Please contact Program Administrator'
                    );
                    return of(new RFODatesUploadTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<RFODatesUploadTemplateFileSuccess>(RFODatesUploadActionTypes.RFODatesUploadTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'RFO Dates Upload.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<RFODatesUploadDownloadOutputDataRequest>(RFODatesUploadActionTypes.RFODatesUploadDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new RFODatesUploadDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new RFODatesUploadDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<RFODatesUploadDownloadOutputDataSuccess>(RFODatesUploadActionTypes.RFODatesUploadDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(RFODatesUploadActionTypes.RFODatesUploadDeleteRecordsSuccess,
            RFODatesUploadActionTypes.RFODatesUploadPatchAllDeleteRecordsSuccess,
            RFODatesUploadActionTypes.RFODatesUploadPatchDeleteRecordSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('RFO').pipe(
                map((response: any) => new RFODatesUploadValidateButtonStateSuccess(response)),
                catchError((error) => of(new RFODatesUploadValidateButtonStateError(error)))
            )
        )
    );
}
