<div class="results-container">
    <div class="title-container">
        <div class="result-table-title">
            Discipline Mapping
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" matSortDisableClear>
        <ng-container matColumnDef="id">
            <th mat-header-cell disableClear *matHeaderCellDef>id</th>
            <td mat-cell *matCellDef="let element">
                <ng-container>{{ element.id }}</ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="disciplineName">
            <th mat-header-cell disableClear *matHeaderCellDef>SCMT</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.disciplineName }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="sourceFileDiscipline">
            <th mat-header-cell disableClear *matHeaderCellDef>Source File "Discipline"</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.sourceFileDiscipline }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="remove">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="remove(element)" title="remove">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-toolbar>
        <span>Add new Discipline Mapping</span>
        <button mat-icon-button (click)="add()" title="add">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </mat-toolbar>
</div>