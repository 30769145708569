import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    LoopStatusActionTypes,
    LoopStatusFilterSuccess,
    LoopStatusFilterError,
    LoopStatusExportToExcelSuccess,
    LoopStatusExportToExcelError,
    LoopStatusAddCommentError,
    LoopStatusAddCommentRequest,
    LoopStatusAddCommentSuccess,
    LoopStatusFilterRequest,
    LoopStatusUpdateRequest,
    LoopStatusUpdateSuccess,
    LoopStatusUpdateError,
    LoopStatusAddBulkCommentRequest,
    LoopStatusAddBulkCommentSuccess,
    LoopStatusAddBulkCommentError,
    LoopStatusRemoveCommentRequest,
    LoopStatusRemoveCommentSuccess,
    LoopStatusRemoveCommentError,
} from './actions';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { LoopStatusService } from 'src/app/services/api/webapi-services/loop-status.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';

@Injectable()
export class LoopStatusEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private loopStatusService: LoopStatusService,
        private commentService: CommentService
    ) {}

    @Effect()
    addComment$ = this.actions$.pipe(
        ofType<LoopStatusAddCommentRequest>(LoopStatusActionTypes.LoopStatusAddCommentRequest),
        withLatestFrom(this.store.select((state) => state.detailedStatusState.dataPagination.items)),
        mergeMap(([{ payload }]) =>
            this.commentService.addCommentLoop(payload.loopName, payload.tagNumber, payload.tagType, payload.description, payload.mentions).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully added.');
                    return new LoopStatusAddCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating comment. Please contact Program Administrator.'
                    );
                    return of(new LoopStatusAddCommentError(error));
                })
            )
        )
    );

    @Effect()
    addBulkComment$ = this.actions$.pipe(
        ofType<LoopStatusAddBulkCommentRequest>(LoopStatusActionTypes.LoopStatusAddBulkCommentRequest),
        withLatestFrom(this.store.select((state) => state.loopStatusState.filter)),
        mergeMap(([{ payload }, filter]) =>
            this.commentService.addBulkCommentLoop(payload.description, payload.mentions, filter).pipe(
                map(() => {
                    this.toastService.Success('Bulk Comment successfully added.');
                    return new LoopStatusAddBulkCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating bulk comment. Please contact Program Administrator.'
                    );
                    return of(new LoopStatusAddBulkCommentError(error));
                })
            )
        )
    );

    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<LoopStatusRemoveCommentRequest>(LoopStatusActionTypes.LoopStatusRemoveCommentRequest),
        mergeMap(({ payload }) =>
            this.commentService.deleteCommentLoop(payload.id).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully removed.');
                    return new LoopStatusRemoveCommentSuccess({
                        id: payload.id,
                        loopName: payload.loopName,
                        tagNumber: payload.tagNumber,
                        tagType: payload.tagType,
                        lastComment: payload.lastComment,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while removing Loop comment. Please contact Program Administrator.'
                    );
                    return of(new LoopStatusRemoveCommentError(error));
                })
            )
        )
    );

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<LoopStatusFilterRequest>(LoopStatusActionTypes.LoopStatusFilterRequest),
        withLatestFrom(this.store.select((store) => store.loopStatusState.filter)),
        mergeMap(([action, loopStatusFilter]) => {
            let filter = action.payload ?? loopStatusFilter;
            return this.loopStatusService.searchTag(filter).pipe(
                map((dataPagination) => {
                    return new LoopStatusFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering loop status. Please contact Program Administrator.'
                    );
                    return of(new LoopStatusFilterError(error));
                })
            );
        })
    );

    @Effect()
    updateResults$ = this.actions$.pipe(
        ofType<LoopStatusUpdateRequest>(LoopStatusActionTypes.LoopStatusUpdateRequest),
        mergeMap((action) => {
            return this.loopStatusService.updateLoop(action.payload).pipe(
                map(() => {
                    
                    return new LoopStatusUpdateSuccess(action.payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering loop status. Please contact Program Administrator.'
                    );
                    return of(new LoopStatusUpdateError(error));
                })
            );
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(LoopStatusActionTypes.LoopStatusExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.loopStatusState.filter)),
        mergeMap(([, loopStatusFilter]) => {
            const filter = {
                ...loopStatusFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.loopStatusService.generateExcel(filter).pipe(
                map((excelData) => new LoopStatusExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new LoopStatusExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(LoopStatusActionTypes.LoopStatusExportToExcelSuccess),
        map((action: LoopStatusExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `LoopStatus_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );
}
