import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-data-maintenance',
    templateUrl: './data-maintenance.component.html',
    styleUrls: ['./data-maintenance.component.scss'],
})
export class DataMaintenanceComponent extends BaseComponent implements OnInit {
    navLinks: { label: string; link: string; index: number }[] = [];

    constructor() {
        super();
        this.navLinks = [
            {
                label: 'team data',
                link: './team-data',
                index: 0,
            },
            {
                label: 'subsystem data',
                link: './subsystem-data',
                index: 1,
            },
            {
                label: 'project team',
                link: './project-team-data',
                index: 2,
            },
/*             {
                label: 'waypoint & milestone data',
                link: './waypoint-milestone-data',
                index: 3,
            }, */
            {
                label: 'NPW sign-off users',
                link: './user-to-contracts',
                index: 3,
            },
        ];
    }

    ngOnInit(): void {
        // This is intentional
    }
}
