import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { map } from 'rxjs/operators';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private popupService: PopupService) {}

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        return component.canDeactivate() ? true : this.openConfirmDialog();
    }

    openConfirmDialog() {
        return this.popupService
            .openPopup(
                new PopupSettings(ConfirmDialogComponent, 600, 220, {
                    title: 'Pending changes',
                    text: 'There are pending changes. Do you want to discard them and leave this page?',
                })
            )
            .afterClosed()
            .pipe(map((response) => response));
    }
}
