import { CertificateSearchActions, CertificateSearchActionTypes } from './actions';
import { CertificateSearchFilter, CertificateSearchState } from './model';

const initialState: CertificateSearchState = {
    filter: new CertificateSearchFilter(),
    data: null,
    isLoading: false,
};

export function reducer(state = initialState, action: CertificateSearchActions): CertificateSearchState {
    switch (action.type) {
        case CertificateSearchActionTypes.CertificateSearchRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CertificateSearchActionTypes.CertificateSearchSuccess: {
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        }
        case CertificateSearchActionTypes.CertificateSearchError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CertificateSearchActionTypes.CertificateSearchFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case CertificateSearchActionTypes.CertificateSearchFilterReset: {
            const newFilter = new CertificateSearchFilter();
            newFilter.sortBy = state.filter.sortBy;
            newFilter.direction = state.filter.direction;
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case CertificateSearchActionTypes.CertificateSearchExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CertificateSearchActionTypes.CertificateSearchExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CertificateSearchActionTypes.CertificateSearchExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}
