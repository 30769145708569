import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    ChangeValidationActionTypes,
    ChangeValidationFilterSuccess,
    ChangeValidationFilterError,
    ChangeValidationExportToExcelSuccess,
    ChangeValidationExportToExcelError,
} from './actions';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ChangeValidationService } from 'src/app/services/api/webapi-services/change-validation.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Injectable()
export class ChangeValidationEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private changeValidationService: ChangeValidationService
    ) {}

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType(ChangeValidationActionTypes.ChangeValidationFilterRequest),
        withLatestFrom(this.store.select((store) => store.changeValidationState.filter)),
        mergeMap(([action, ChangeValidationFilter]) => {
            return this.changeValidationService.getChangeValidationData(ChangeValidationFilter).pipe(
                map((dataPagination) => new ChangeValidationFilterSuccess(dataPagination)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering change documents. Please contact Program Administrator.'
                    );
                    return of(new ChangeValidationFilterError(error));
                })
            );
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(ChangeValidationActionTypes.ChangeValidationExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.changeValidationState.filter)),
        mergeMap(([, changeValidationFilter]) => {
            const filter = {
                ...changeValidationFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.changeValidationService.generateExcel(filter).pipe(
                map((excelData) => new ChangeValidationExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new ChangeValidationExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(ChangeValidationActionTypes.ChangeValidationExportToExcelSuccess),
        map((action: ChangeValidationExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `${moment().format('YYYYMMDD_HHmmss')}_ChangeValidation_DATA` + '.xlsx');
        })
    );
}
