import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import * as _ from 'lodash';
import { filter, map, takeWhile } from 'rxjs/operators';
import { SeriesBuilder } from 'src/app/components/charts/utils/SeriesBuilder';
import { BaseComponent } from 'src/app/components/base.component';
import * as moment from 'moment';
import { getWeekEndDate } from 'src/app/extensions';

@Component({
    selector: 'app-reports-planning-commitment-reliability-chart',
    templateUrl: './reports-planning-commitment-reliability-chart.component.html',
    styleUrls: ['./reports-planning-commitment-reliability-chart.component.scss'],
})
export class ReportsPlanningCommitmentReliabilityChartComponent extends BaseComponent implements OnInit {
    @Input() commitmentReliabilityType: string;

    planViewEnabled = false;
    planViewEnabled$ = this.store.select((store) => store.planningState.commitmentReliability.planViewEnabled);
    monthViewEnabled = false;
    monthViewEnabled$ = this.store.select((store) => store.planningState.commitmentReliability.monthViewEnabled);

    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = false;
    showXAxisLabel: boolean = false;
    showYAxisLabel: boolean = false;
    colorSchemeItr = {
        domain: ['#000000', '#FFA500', '#FF0000', '#32CD32', '#808080'],
    };
    colorSchemeItrWithoutPlan = {
        domain: ['#FFA500', '#FF0000', '#32CD32', '#808080'],
    };
    colorSchemeNotItr = {
        domain: ['#32CD32', '#FF0000', '#808080'],
    };
    colorSchemeLines = {
        domain: ['#32CD32', '#0055ff', '#808080'],
    };

    lineData$ = this.store.pipe(
        filter(this.filterCommitmentReliability()),
        map((store) => {
            return [
                {
                    name: this.commitmentReliabilityType === 'itr' ? `Commitment Reliability` : `Percentage`,
                    series: _.zipWith(
                        this.monthViewEnabled ? store.planningState.commitmentReliability.months : store.planningState.commitmentReliability.weeks,
                        store.planningState.commitmentReliability.data,
                        (week, total) => {
                            return {
                                name: this.monthViewEnabled ? moment(week.date).format('MMM YY') : `WK${moment(week.date).format('w')}`,
                                value: total.percentage,
                            };
                        }
                    ),
                },
                ...(this.commitmentReliabilityType === 'itr'
                    ? [
                          {
                              name: `Working to Schedule`,
                              series: _.zipWith(
                                  store.planningState.commitmentReliability.weeks,
                                  store.planningState.commitmentReliability.data,
                                  (week, total) => {
                                      return {
                                          name: `WK${moment(week.date).format('w')}`,
                                          value: total.blueCount,
                                      };
                                  }
                              ),
                          },
                      ]
                    : []),
            ];
        })
    );

    chartNotItrData$ = this.getChartData(
        new SeriesBuilder().committedAchieved().committedNotAchieved().achievedNotCommited()
    );

    chartItrVerticalData$ = this.getChartData(
        !this.planViewEnabled
            ? new SeriesBuilder().committed().committedNotAchieved()
            : new SeriesBuilder().perPlan().committed().committedNotAchieved()
    );

    chartItrStackedData$ = this.store.pipe(
        filter(this.filterCommitmentReliability()),
        map((store) => {
            return {
                place: 3,
                data: _.zipWith(
                    this.monthViewEnabled ? store.planningState.commitmentReliability.months : store.planningState.commitmentReliability.weeks,
                    store.planningState.commitmentReliability.data,
                    (week, total) => {
                        return {
                            name: this.monthViewEnabled ? moment(week.date).format('MMM YY') : `W/E ${moment(getWeekEndDate(moment(week.date))).format('D MMM')}`,
                            series: new SeriesBuilder().committedAchieved().achievedNotCommited().build(total),
                        };
                    }
                ),
            };
        })
    );

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit(): void {
        this.planViewEnabled$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.planViewEnabled = data;
            this.chartItrVerticalData$ = this.getChartData(
                !this.planViewEnabled && this.commitmentReliabilityType === 'rfsu'
                    ? new SeriesBuilder().committed().committedNotAchieved()
                    : new SeriesBuilder().perPlan().committed().committedNotAchieved()
            );
        });

        this.monthViewEnabled$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.monthViewEnabled = data;
        });
    }

    axisFormat(val: number) {
        if (val % 1 === 0) {
            return val.toLocaleString();
        } else {
            return '';
        }
    }

    yAxisTickPercentage(value) {
        return `${value}%`;
    }

    private filterCommitmentReliability(): (value: ApplicationState, index: number) => boolean {
        return (store) =>
            (store.planningState.commitmentReliability.weeks.length > 0 ||
                store.planningState.commitmentReliability.months.length > 0) &&
            store.planningState.commitmentReliability.data &&
            store.planningState.commitmentReliability.data.length > 0;
    }

    private getChartData(seriesBuilder: SeriesBuilder) {
        return this.store.pipe(
            filter(this.filterCommitmentReliability()),
            map((store) => {
                return _.zipWith(
                    this.monthViewEnabled ? store.planningState.commitmentReliability.months : store.planningState.commitmentReliability.weeks,
                    store.planningState.commitmentReliability.data,
                    (week, total) => {
                        return {
                            name: this.monthViewEnabled ? moment(week.date).format('MMM YY') : `W/E ${moment(getWeekEndDate(moment(week.date))).format('D MMM')}`,
                            series: seriesBuilder.build(total),
                        };
                    }
                );
            })
        );
    }
}
