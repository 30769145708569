import {
    HealthState,
    HealthFilter,
    HealthCleanlinessState,
    HEALTH_CLEANLINESS_NUM_OF_PAST_WEEKS,
    HealthCleanlinessTotalItem,
    OverdueDPLIState,
    OverdueDPLIFilter,
    OverdueExceptionsState,
    OverdueExceptionsFilters,
    OverdueExceptionsByPriorityState,
    OverdueExceptionsByPriorityFilters,
    ActivitiesConstraintsState,
    ActivitiesConstraintsFilter,
    HEALTH_ACTIVITIES_CONSTRAINTS_NUM_OF_PAST_WEEKS,
    HEALTH_ACTIVITIES_CONSTRAINTS_NUM_OF_UPCOMING_WEEKS,
} from './model';
import {
    HealthActions,
    HealthActionTypes,
    overdueExceptionsByPriorityTableRequest,
    overdueExceptionsByPriorityTableSuccess,
    overdueExceptionsByPriorityTableError,
    overdueExceptionsByPriorityFilterColumnsReset,
    overdueExceptionsByPriorityColumnsVisibilityUpdate,
    overdueExceptionsByPriorityTablesVisibilityUpdate,
    overdueExceptionsBySubsystemTableRequest,
    overdueExceptionsBySubsystemTableSuccess,
    overdueExceptionsBySubsystemTableError
} from './actions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { getWeekEndDate, getWeekRange, initWeekRange } from 'src/app/extensions';
import { I } from '@angular/cdk/keycodes';

const initialState: HealthState = {
    cleanliness: new HealthCleanlinessState(),
    overdueDpli: new OverdueDPLIState(),
    overdueExceptions: new OverdueExceptionsState(),
    overdueExceptionsByPriority: new OverdueExceptionsByPriorityState(),
    activitiesConstraints: new ActivitiesConstraintsState(),
};

export function reducer(state = initialState, action: HealthActions): HealthState {
    switch (action.type) {
        case HealthActionTypes.HealthCleanlinessSetType: {
            return {
                ...state,
                cleanliness: {
                    ...state.cleanliness,
                    cleanlinessType: action.payload.toUpperCase(),
                },
            };
        }
        case HealthActionTypes.HealthCleanlinessFilterUpdate: {
            return {
                ...state,
                cleanliness: {
                    ...state.cleanliness,
                    filter: {
                        ...state.cleanliness.filter,
                        ...action.payload,
                    },
                },
            };
        }
        case HealthActionTypes.HealthCleanlinessFilterPropertyUpdate: {
            return {
                ...state,
                cleanliness: {
                    ...state.cleanliness,
                    filter: {
                        ...state.cleanliness.filter,
                        [action.payload.key]: Array.isArray(state.cleanliness.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLIFilterUpdate: {
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    filter: {
                        ...state.overdueDpli.filter,
                        ...action.payload,
                    },
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLIFilterPropertyUpdate: {
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    filter: {
                        ...state.overdueDpli.filter,
                        [action.payload.key]: Array.isArray(state.overdueDpli.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                },
            };
        }
        case HealthActionTypes.HealthCleanlinessFilterReset: {
            return {
                ...state,
                cleanliness: {
                    ...state.cleanliness,
                    filter: new HealthFilter(),
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLIFilterReset: {
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    filter: {
                        ...new OverdueDPLIFilter(),
                        projectActionOnly: action.payload ? state.overdueDpli.filter.projectActionOnly : null,
                        overdueOnly: action.payload ? state.overdueDpli.filter.overdueOnly : null,
                    },
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLITableFilterReset: {
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    filter: {
                        ...new OverdueDPLIFilter(),
                        projectTeamNames: action.payload ? state.overdueDpli.filter.projectTeamNames : null,
                        disciplines: action.payload ? state.overdueDpli.filter.disciplines : null,
                        systemOwners: action.payload ? state.overdueDpli.filter.systemOwners : null,
                        scManager: action.payload ? state.overdueDpli.filter.scManager : null,
                        projectActionOnly: action.payload ? state.overdueDpli.filter.projectActionOnly : null,
                        overdueOnly: action.payload ? state.overdueDpli.filter.overdueOnly : null,
                    },
                },
            };
        }
        case HealthActionTypes.HealthCleanlinessRequest: {
            return {
                ...state,
                cleanliness: {
                    ...state.cleanliness,
                    isLoading: true,
                },
            };
        }
        case HealthActionTypes.HealthCleanlinessSuccess: {
            const data = state.cleanliness.weeks.map((week) => {
                const greenCount = _.filter(
                    action.payload,
                    (v) => withinWorkWeek(v.week, moment(week.date)) && v.isClean
                ).length;
                const redCount = _.filter(
                    action.payload,
                    (v) => withinWorkWeek(v.week, moment(week.date)) && !v.isClean
                ).length;

                let percentage = 0;
                if (greenCount !== 0) {
                    percentage = Math.round((greenCount / (greenCount + redCount)) * 100);
                }

                const result: HealthCleanlinessTotalItem = {
                    greenCount,
                    redCount,
                    percentage,
                };
                return result;
            });

            return {
                ...state,
                cleanliness: {
                    ...state.cleanliness,
                    data,
                    isLoading: false,
                },
            };
        }
        case HealthActionTypes.HealthCleanlinessError: {
            return {
                ...state,
                cleanliness: {
                    ...state.cleanliness,
                    isLoading: false,
                },
            };
        }
        case HealthActionTypes.HealthCleanlinessSetWeekRange: {
            return {
                ...state,
                cleanliness: {
                    ...state.cleanliness,
                    weeks: initWeekRange(action.payload, HEALTH_CLEANLINESS_NUM_OF_PAST_WEEKS),
                    data: [],
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLITableRequest: {
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    isTableLoading: true,
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLITableSuccess: {
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    isTableLoading: false,
                    table: action.payload,
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLITableError: {
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    isTableLoading: false,
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLIChartRequest: {
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    isChartLoading: true,
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLIChartSuccess: {
            let chart = [
                {
                    name: '4+ Months',
                    value: 0,
                },
                { name: '3 Months', value: 0 },
                { name: '2 Months', value: 0 },
                { name: '1 Month', value: 0 },
                { name: 'within 1 Month', value: 0 },
            ];
            chart = chart.map((c) => ({
                name: c.name,
                value: action.payload.find((p) => p.name === c.name)?.value || c.value,
            }));
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    isChartLoading: false,
                    chart,
                },
            };
        }
        case HealthActionTypes.HealthOverdueDPLIChartError: {
            return {
                ...state,
                overdueDpli: {
                    ...state.overdueDpli,
                    isChartLoading: false,
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsFilterStateUpdate: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    filter: { ...action.payload },
                },
            };
        }

        case HealthActionTypes.HealthOverdueExceptionsFilterPropertyUpdate: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    filter: {
                        ...state.overdueExceptions.filter,
                        [action.payload.key]: Array.isArray(state.overdueExceptions.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsFilterReset: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    filter: {
                        ...new OverdueExceptionsFilters(),
                        overdueOnly: action.payload ? state.overdueExceptions.filter.overdueOnly : null,
                    },
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsTableFilterReset: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    filter: {
                        ...new OverdueExceptionsFilters(),
                        disciplines: action.payload ? state.overdueExceptions.filter.disciplines : null,
                        systemOwners: action.payload ? state.overdueExceptions.filter.systemOwners : null,
                        scManagers: action.payload ? state.overdueExceptions.filter.scManagers : null,
                        overdueOnly: action.payload ? state.overdueExceptions.filter.overdueOnly : null,
                    },
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsColumnsVisibilityUpdate: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    showColumns: { ...action.payload },
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsAddCommentRequest:
        case HealthActionTypes.HealthOverdueExceptionsExportToExcelRequest:
        case HealthActionTypes.HealthOverdueExceptionsTableRequest: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    isTableLoading: true,
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsTableSuccess: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    isTableLoading: false,
                    table: action.payload,
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsChartRequest: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    isChartLoading: true,
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsChartSuccess: {
            let chart = [
                { name: '3+ Months', value: 0 },
                { name: '2 Months', value: 0 },
                { name: '1 Month', value: 0 },
                { name: 'within 1 Month', value: 0 },
                { name: 'Not Overdue', value: 0 },
            ];
            chart = chart.map((c) => ({
                name: c.name,
                value: action.payload.find((p) => p.name === c.name)?.value || c.value,
            }));
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    isChartLoading: false,
                    chart,
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsChartError: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    isChartLoading: false,
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsAddCommentSuccess: {
            const newItems = [...state.overdueExceptions.table.items].map((x) => {
                if (x.id.toString() === action.payload.entityId) {
                    return { ...x, latestComment: action.payload.description };
                } else {
                    return x;
                }
            });

            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    table: {
                        ...state.overdueExceptions.table,
                        items: newItems,
                    },
                    isTableLoading: false,
                },
            };
        }
        case HealthActionTypes.HealthOverdueExceptionsTableError:
        case HealthActionTypes.HealthOverdueExceptionsAddCommentError:
        case HealthActionTypes.HealthOverdueExceptionsExportToExcelRequestSuccess:
        case HealthActionTypes.HealthOverdueExceptionsExportToExcelRequestError: {
            return {
                ...state,
                overdueExceptions: {
                    ...state.overdueExceptions,
                    isTableLoading: false,
                },
            };
        }

        case HealthActionTypes.OverdueExceptionsByPriorityFilterStateUpdate: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    filter: { ...action.payload },
                },
            };
        }

        case HealthActionTypes.OverdueExceptionsByPriorityFilterPropertyUpdate: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    filter: {
                        ...state.overdueExceptionsByPriority.filter,
                        [action.payload.key]: Array.isArray(
                            state.overdueExceptionsByPriority.filter[action.payload.key]
                        )
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                },
            };
        }
        case HealthActionTypes.OverdueExceptionsByPriorityFilterReset: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    filter: {
                        ...new OverdueExceptionsByPriorityFilters(),
                        overdueOnly: action.payload ? state.overdueExceptionsByPriority.filter.overdueOnly : null,
                    },
                },
            };
        }
        case overdueExceptionsByPriorityFilterColumnsReset.type: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    filter: {
                        ...state.overdueExceptionsByPriority.filter,
                        columnSubsystem: null,
                        columnNumber: null,
                        columnDescription: null,
                        columnTagNumber: null,
                        columnTagType: null,
                        columnComments: null,
                        columnTargetCompletionDate: null,
                        columnMCExceptionsCount: null,
                        columnRFSUExceptionsCount: null,
                        columnRFOExceptionsCount: null,
                        columnPriorities: null
                    },
                },
            };
        }
        case HealthActionTypes.OverdueExceptionsByPriorityChartRequest: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isChartLoading: true,
                },
            };
        }
        case HealthActionTypes.OverdueExceptionsByPriorityChartSuccess: {
            var barChartData = [
                ...action.payload.map((series) => {
                    return {
                        name: series.name,
                        series: [
                            {
                                name: 'overdue',
                                value: series.overdue,
                            },
                            {
                                name: 'not overdue',
                                value: series.notOverdue,
                            },
                        ],
                    };
                }),
            ];
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isChartLoading: false,
                    chart: barChartData,
                },
            };
        }
        case HealthActionTypes.OverdueExceptionsByPriorityChartError: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isChartLoading: false,
                },
            };
        }

        case overdueExceptionsByPriorityTableRequest.type: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isTableLoading: true,
                },
            };
        }
        case overdueExceptionsByPriorityTableSuccess.type: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isTableLoading: false,
                    table: action.payload,
                },
            };
        }
        case overdueExceptionsByPriorityTableError.type: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isTableLoading: false,
                },
            };
        }
        case overdueExceptionsByPriorityColumnsVisibilityUpdate.type: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    showColumns: { ...action.payload },
                },
            };
        }
        case overdueExceptionsByPriorityTablesVisibilityUpdate.type: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    ...action,
                    table: action.showTable ? state.overdueExceptionsByPriority.table : null,
                    subsystemTable: action.showSubsystemTable ? state.overdueExceptionsByPriority.subsystemTable : null,
                },
            };
        }

        case HealthActionTypes.OverdueExceptionsBySubsystemChartRequest: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isSubsystemChartLoading: true,
                },
            };
        }
        case HealthActionTypes.OverdueExceptionsBySubsystemChartSuccess: {
            var barChartData = [
                ...action.payload.map((series) => {
                    return {
                        name: series.name,
                        series: [
                            {
                                name: 'overdue',
                                value: series.overdue,
                            },
                            {
                                name: 'not overdue',
                                value: series.notOverdue,
                            },
                        ],
                    };
                }),
            ];
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isSubsystemChartLoading: false,
                    subsystemChart: barChartData,
                },
            };
        }
        case HealthActionTypes.OverdueExceptionsBySubsystemChartError: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isSubsystemChartLoading: false,
                },
            };
        }

        case overdueExceptionsBySubsystemTableRequest.type: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isSubsystemTableLoading: true,
                },
            };
        }
        case overdueExceptionsBySubsystemTableSuccess.type: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isSubsystemTableLoading: false,
                    subsystemTable: action.payload,
                },
            };
        }
        case overdueExceptionsBySubsystemTableError.type: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isSubsystemTableLoading: false,
                },
            };
        }

        case HealthActionTypes.OverdueExceptionsByPriorityExportToExcelRequest: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isChartLoading: true,
                },
            };
        }
        case HealthActionTypes.OverdueExceptionsByPriorityExportToExcelRequestSuccess:
        case HealthActionTypes.OverdueExceptionsByPriorityExportToExcelRequestError: {
            return {
                ...state,
                overdueExceptionsByPriority: {
                    ...state.overdueExceptionsByPriority,
                    isChartLoading: false,
                },
            };
        }

        case HealthActionTypes.ActivitiesConstraintsFilterReset: {
            return {
                ...state,
                activitiesConstraints: {
                    ...state.activitiesConstraints,
                    filter: {
                        ...new ActivitiesConstraintsFilter(),
                    },
                },
            };
        }
        case HealthActionTypes.ITRConstraintsChartRequest:
        case HealthActionTypes.ActivitiesConstraintsChartRequest: {
            return {
                ...state,
                activitiesConstraints: {
                    ...state.activitiesConstraints,
                    isChartLoading: true,
                },
            };
        }
        case HealthActionTypes.ITRConstraintsChartSuccess:
        case HealthActionTypes.ActivitiesConstraintsChartSuccess: {
            return {
                ...state,
                activitiesConstraints: {
                    ...state.activitiesConstraints,
                    isChartLoading: false,
                    chart: action.payload,
                },
            };
        }
        case HealthActionTypes.ActivitiesConstraintsChartError: {
            return {
                ...state,
                activitiesConstraints: {
                    ...state.activitiesConstraints,
                    isChartLoading: false,
                },
            };
        }
        case HealthActionTypes.ActivitiesConstraintsFilterStateUpdate: {
            return {
                ...state,
                activitiesConstraints: {
                    ...state.activitiesConstraints,
                    filter: { ...action.payload },
                },
            };
        }
        case HealthActionTypes.ITRConstraintsFilterSetWeekRange:
        case HealthActionTypes.ActivitiesConstraintsFilterSetWeekRange: {
            const endDate = getWeekEndDate(moment(state.activitiesConstraints.filter.endDate));
            return {
                ...state,
                activitiesConstraints: {
                    ...state.activitiesConstraints,
                    weeks: initWeekRange(action.payload, getWeekRange(action.payload.add(-1, 'week'), endDate)),
                },
            };
        }
        case HealthActionTypes.ITRConstraintsChartError: {
            return {
                ...state,
                activitiesConstraints: {
                    ...state.activitiesConstraints,
                    isChartLoading: false,
                },
            };
        }
        default:
            return state;
    }
}

const withinWorkWeek = (dateToCheck: moment.Moment, startDate: moment.Moment) => {
    return (
        moment(dateToCheck).isSameOrAfter(moment(startDate), 'days') &&
        moment(dateToCheck).isSameOrBefore(moment(startDate).add(6, 'days'), 'days')
    );
};
