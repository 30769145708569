import { OrderDirection, CheckListColumn, CalendarColumn, UserDetail } from '../common.model';

export class MyPageFilter {
    page = 0;
    take = 10;
    sortBy = 'date';
    direction: OrderDirection = OrderDirection.Desc;
    columnTypes: CheckListColumn = null;
    columnAuthors: CheckListColumn = null;
    columnDates: CalendarColumn = null;
    unreadOnly: boolean = true;
}

export class MyPageDTO {
    id: number;
    type: CommentType;
    comment: string;
    author: string;
    date: string;
    link: string;
    mentions: UserDetail[];
    isRead: boolean;
}

export enum CommentType {
    ITR,
    PLI,
    Redline,
    RFO,
    Redzone,
    Bluezone,
    ChangeDocument,
    Loop,
    COW,
    MCMilestone,
    RedzoneNoDiscipline,
    Activity,
    BlueZoneNoDiscipline,
    ITRConstraint
}

export interface MyPageState {
    dataPagination: MyPageDataPagination;
    filter: MyPageFilter;
    isLoading: boolean;
}

export class MyPageDataPagination {
    items: MyPageDTO[] = [];
    totalCount = 0;
}
