import { OrderDirection, PriorityType, CheckListColumn } from '../common.model';

export class ChangeDocumentFilter {
    page = 0;
    take = 10;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    changeTypes: string[] = [];
    cwps: { id: string }[] = [];
    disciplines: string[] = [];
    number: string = '';
    title: string = '';
    comparison: PriorityType[] = [PriorityType.Manual, PriorityType.MCPlus, PriorityType.ChangeDocImport];
    columnManualOverrideSubsystems: CheckListColumn = null;
    columnMCPlusSubsystems: CheckListColumn = null;
    columnImportDataSourceSubsystems: CheckListColumn = null;
    filterType: PriorityType = PriorityType.ChangeDocImport;
    showHideColumns = new ShowHideConflictsColumns();
}

export class ShowHideConflictsColumns {
    manualOverrideSubsystems = true;
    mcPlusSubsystems = true;
    importDataSourceSubsystems = true;
}

export class ChangeDocumentDTO {
    id: number;
    type: string;
    number: string;
    manualOverrideSubsystems: string;
    mcPlusSubsystems: string;
    importDataSourceSubsystems: string;
    validationId: number;
    SubPriority: string;
}

export interface SubystemConflictsState {
    dataPagination: SubystemConflictsDataPagination;
    filter: ChangeDocumentFilter;
    isLoading: boolean;
}

export class SubystemConflictsDataPagination {
    items: ChangeDocumentDTO[] = [];
    totalCount = 0;
}
