import { Action } from '@ngrx/store';

export enum ReportsDashboardActionTypes {
    ReportsDashboardDownloadFishboneRequest = '[Reports Dashboard] Download Fishbone Request',
    ReportsDashboardDownloadFishboneSuccess = '[Reports Dashboard] Download Fishbone Success',
    ReportsDashboardDownloadFishboneError = '[Reports Dashboard] Download Fishbone Error',
}

export class ReportsDashboardDownloadFishboneRequest implements Action {
    readonly type = ReportsDashboardActionTypes.ReportsDashboardDownloadFishboneRequest;

    constructor(public payload: string) {}
}

export class ReportsDashboardDownloadFishboneSuccess implements Action {
    readonly type = ReportsDashboardActionTypes.ReportsDashboardDownloadFishboneSuccess;

    constructor(public payload: { file: any; phaseType: string }) {}
}

export class ReportsDashboardDownloadFishboneError implements Action {
    readonly type = ReportsDashboardActionTypes.ReportsDashboardDownloadFishboneError;

    constructor(public payload: string) {}
}

export type ReportsDashboardActions =
    | ReportsDashboardDownloadFishboneRequest
    | ReportsDashboardDownloadFishboneSuccess
    | ReportsDashboardDownloadFishboneError;
