import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import * as _ from 'lodash';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';

export class BacklogCat {
    id: number;
    name: string;
    prevName: string;
    isInEditMode = false;
}

@Component({
    selector: 'app-backlog-categories',
    templateUrl: './backlog-categories.component.html',
    styleUrls: ['./backlog-categories.component.scss'],
})
export class BacklogCategoriesConfigurationComponent implements OnInit {
    isLoading = false;
    backlogCats: BacklogCat[] = [];
    displayedColumns = ['name', 'actions'];

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private lookupService: LookupService,
        private configurationService: ConfigurationService,
        private toastService: ToastService
    ) {
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit() {
        this.lookupService
            .getBacklogCats()
            .pipe(take(1))
            .subscribe((data) => {
                this.backlogCats = [
                    ...data.map((d) => ({ id: d.id, name: d.name, isInEditMode: false, prevName: '' })),
                ];
            });
    }

    add() {
        this.backlogCats = [...this.backlogCats, { id: 0, name: '', isInEditMode: true, prevName: '' }];
    }

    remove(element: BacklogCat) {
        this.isLoading = true;
        this.configurationService
            .deleteBacklogCat(element.id)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while deleting Backlog Category. Please contact Program Administrator.'
                    );
                    return of(error);
                })
            )
            .subscribe((id) => {
                this.backlogCats = [..._.filter(this.backlogCats, (c) => c.id !== id)];
                this.toastService.Success('Backlog Category has been removed.');
            });
    }

    enterEditMode(element: BacklogCat) {
        element.isInEditMode = true;
        element.prevName = element.name;
    }

    cancelEdit(element: BacklogCat) {
        element.isInEditMode = false;
        element.name = element.prevName;
        this.backlogCats = [..._.filter(this.backlogCats, (c) => c.id > 0)];
    }

    update(element: BacklogCat) {
        this.isLoading = true;
        this.configurationService
            .updateBacklogCat(element)
            .pipe(
                take(1),
                finalize(() => {
                    this.isLoading = false;
                    element.isInEditMode = false;
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while updating/creating Backlog Category. Please contact Program Administrator.'
                    );
                    return of(error);
                })
            )
            .subscribe((data) => {
                this.toastService.Success(
                    `Backlog Category has been successfully ${data.id !== element.id ? 'created.' : 'updated.'}`
                );
                element.id = data.id;
            });
    }
}
