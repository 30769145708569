import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import {
    AttachmentDTO,
    PLIColumnNames,
    PLIColumnsVisibility,
    PLIPlanningDTO,
    PLIPlanningFilter,
} from 'src/app/store/pli-planning/model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { takeWhile, take, map, finalize, catchError} from 'rxjs/operators';
import { of } from 'rxjs';
import {
    PLIPLanningDataRequest,
    PLIPLanningDataUpdate,
    PLIPlanningFilterUpdate,
    UncommittedPLIsUpdate,
} from 'src/app/store/pli-planning/actions';
import * as moment from 'moment';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { differenceBy } from 'lodash';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { CalendarColumn, CheckListColumn, OrderDirection, SubsystemDTO } from 'src/app/store/common.model';
import { PLIPlanningService } from 'src/app/services/api/webapi-services/pli-planning-service';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { ConstraintTypeSelectorPopupComponent } from '../../sc-planning/lookahead/planning-table/subsystem-activities-table/constraint-type-selector/constraint-type-selector.component';
import { PopoverPlacement } from 'ngx-smart-popover';
import * as _ from 'lodash';
import { Dictionary } from 'typescript-collections';
import { ToastService } from 'src/app/services/shared/toast.service';

export class PlanningConstraint {
    id: number;
    name: string;
    prevName: string;
    isInEditMode = false;
}

export enum ConstraintSelectionMode {
    Mandatory,
    Optional,
}
@Component({
    selector: 'app-pli-planning-table',
    templateUrl: './pli-planning-table.component.html',
    styleUrls: ['./pli-planning-table.component.scss'],
})
export class PLIPlanningTableComponent extends BaseComponent implements OnInit {
    PopoverPlacement = PopoverPlacement;

    @ViewChild('activityActionPopover', { static: false }) popover: any;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    columnsVisibility = new PLIColumnsVisibility();
    columnNames = new PLIColumnNames();
    constraintFlags: string[] = [];
    loaded = false;
    totalLength = 0;
    pageSize = 25;
    sortBy = 'finalPunchItem';
    defaultColumns = ['select', 'subsystem', 'finalPunchItem', 'category', 'punchType'];
    direction: OrderDirection = OrderDirection.Desc;
    selectedRow: PLIPlanningDTO;
    get isSelectedRowConstrained() {
        return this?.selectedRow?.isConstrained ?? false;
    }

    dataSource = new MatTableDataSource<PLIPlanningDTO>([]);
    selection = new SelectionModel<PLIPlanningDTO>(true, []);

    data$ = this.store.select((store) => store.pliPlanningState.data);

    filter = new PLIPlanningFilter();
    filter$ = this.store.select((store) => store.pliPlanningState.filter);


    uploadedAttachmentsActionInProgress = new Dictionary<string, boolean>();

    weeklyPlanUncommittedPLIs = new Array<PLIPlanningDTO>();
    weeklyPlanUncommittedPLIs$ = this.store.select((store) => store.pliPlanningState.uncommittedPLIs);

    columnPLICreatedDate: CalendarColumn = null;
    columnPLICreatedDate$ = this.store.select((state) => state.pliPlanningState.filter.columnCreatedDate);

    columnPLIStatusDate: CalendarColumn = null;
    columnPLIStatusDate$ = this.store.select((state) => state.pliPlanningState.filter.columnCreatedDate);

    columnPLIMcPlan: CalendarColumn = null;
    columnPLIMcPlan$ = this.store.select((state) => state.pliPlanningState.filter.columnCreatedDate);

    columnPLIMcForecast: CalendarColumn = null;
    columnPLIMcForecast$ = this.store.select((state) => state.pliPlanningState.filter.columnCreatedDate);

    columnPLIRfsuPlan: CalendarColumn = null;
    columnPLIRfsuPlan$ = this.store.select((state) => state.pliPlanningState.filter.columnCreatedDate);

    columnPLIRfsuForecast: CalendarColumn = null;
    columnPLIRfsuForecast$ = this.store.select((state) => state.pliPlanningState.filter.columnCreatedDate);

    columnPLISubsystems: CheckListColumn = null;
    columnPLISubsystems$ = this.store.select((state) => state.pliPlanningState.filter.columnSubsystem);

    columnPLIPunchNumbers: CheckListColumn = null;
    columnPLIPunchNumbers$ = this.store.select((state) => state.pliPlanningState.filter.columnPunchNumber);

    columnPLIDescriptions: CheckListColumn = null;
    columnPLIDescriptions$ = this.store.select((state) => state.pliPlanningState.filter.columnDescription);

    columnTagNumbers: CheckListColumn = null;
    columnTagNumbers$ = this.store.select((state) => state.pliPlanningState.filter.columnTagNumber);

    columnActionBys: CheckListColumn = null;
    columnActionBys$ = this.store.select((state) => state.pliPlanningState.filter.columnActionBy);

    columnPLICategories: CheckListColumn = null;
    columnPLICategories$ = this.store.select((state) => state.pliPlanningState.filter.columnCategory);

    columnPLIPunchTypes: CheckListColumn = null;
    columnPLIPunchTypes$ = this.store.select((state) => state.pliPlanningState.filter.columnPunchType);

    columnPLIRaisedBy: CheckListColumn = null;
    columnPLIRaisedBy$ = this.store.select((state) => state.pliPlanningState.filter.columnRaisedBy);

    columnPLISubsystemName: CheckListColumn = null;
    columnPLISubsystemName$ = this.store.select((state) => state.pliPlanningState.filter.columnSubsystemName);

    columnPLITagType: CheckListColumn = null;
    columnPLITagType$ = this.store.select((state) => state.pliPlanningState.filter.columnTagType);

    columnPLITagName: CheckListColumn = null;
    columnPLITagName$ = this.store.select((state) => state.pliPlanningState.filter.columnTagName);

    columnPLIMaterialReference: CheckListColumn = null;
    columnPLIMaterialReference$ = this.store.select((state) => state.pliPlanningState.filter.columnMaterialReference);

    columnPLIDisciplines: CheckListColumn = null;
    columnPLIDisciplines$ = this.store.select((state) => state.pliPlanningState.filter.columnDiscipline);

    columnPLIConstraintFlags: CheckListColumn = null;
    columnPLIConstraintFlags$ = this.store.select((state) => state.pliPlanningState.filter.columnConstraintFlag);

    columnPLIStatus: CheckListColumn = null;
    columnPLIStatus$ = this.store.select((state) => state.pliPlanningState.filter.columnStatus);

    get columnKeys(): string[] {
        return Object.keys(this.columnsVisibility);
    }

    get columnKeysDropdown(): string[] {
        return this.columnKeys.filter((k) => k !== 'select');
    }

    get displayedColumns(): string[] {
        return [...this.defaultColumns, ...this.columnKeys.filter((key) => this.columnsVisibility[key])];
    }

    get isFutureWeek(): boolean {
        return moment(this.filter.weekStart).isAfter(getCurrentWeekStartDate());
    }

    get weekStart(): moment.Moment {
        return moment(this.filter.weekStart);
    }

    get currentlySetWeekEnd() {
        return moment(this.weekStart).add(6, 'day').toDate() ?? '';
    }

    constructor(
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private pliPlanningService: PLIPlanningService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private lookupService: LookupService,
        private toastService: ToastService
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
    }

    ngOnInit(): void {
        this.data$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data) {
                this.loaded = false;
                this.dataSource.data = data.items;
                this.applyLocalChangesToData();
                this.totalLength = data.totalCount;

                this.selection.clear();
                var dataToSelect = data.items.filter((pli) => !this.isUnCommitted(pli) && pli.isCommitted);

                this.selection.select(...dataToSelect);


                const uncommittedPLIs =
                this.selection.selected.length > 0
                        ? differenceBy(this.dataSource.data, this.selection.selected, 'finalPunchItem')
                        : this.dataSource.data.filter((pli) => !pli.isCommitted);
                const toUncommit = differenceBy(uncommittedPLIs, this.weeklyPlanUncommittedPLIs, 'finalPunchItem');
                if(toUncommit.length > 0) {
                    this.uncommitPLIs(toUncommit);
                }
                this.loaded = true;
            }
        });

        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data) {
                this.filter = { ...this.filter, ...data };
            }
        });

        this.weeklyPlanUncommittedPLIs$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if(data) {
                this.weeklyPlanUncommittedPLIs = data;
            }
        })

        this.selection.changed
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data: SelectionChange<PLIPlanningDTO>) => {
                if(this.loaded) {
                    const committedPLIs = this.weeklyPlanUncommittedPLIs.filter((pli) => data.source.selected.find(result => result.finalPunchItem === pli.finalPunchItem) != undefined);
                    if (committedPLIs.length > 0) {
                        this.commitPLIs(committedPLIs);
                    }
                    const unselected = differenceBy(this.dataSource.data, this.selection.selected, 'finalPunchItem')
                    const toUncommit = differenceBy(unselected, this.weeklyPlanUncommittedPLIs, 'finalPunchItem');
                    if(toUncommit.length > 0 && !this.selectedRow) {
                        this.uncommitPLIs(toUncommit);
                    } else if (toUncommit.length > 0 && this.selectedRow) this.updateIsConstrained();
                }
            });

        this.lookupService
            .getConstraintFlags()
            .pipe(take(1))
            .subscribe((data) => {
                this.constraintFlags = [...data.map((x) => x.name)];
                this.constraintFlags.unshift('');
            });

        this.columnPLIConstraintFlags$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIConstraintFlags = data));

        this.columnPLICreatedDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLICreatedDate = data));

        this.columnPLIStatusDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIStatusDate = data));

        this.columnPLIMcPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnPLIMcPlan = data));

        this.columnPLIMcForecast$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIMcForecast = data));

        this.columnPLIRfsuPlan$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIRfsuPlan = data));

        this.columnPLIRfsuForecast$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIRfsuForecast = data));

        this.columnPLISubsystems$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLISubsystems = data));

        this.columnPLIPunchNumbers$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIPunchNumbers = data));

        this.columnPLIDescriptions$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIDescriptions = data));

        this.columnTagNumbers$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnTagNumbers = data));

        this.columnActionBys$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnActionBys = data));

        this.columnPLICategories$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLICategories = data));

        this.columnPLIPunchTypes$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIPunchTypes = data));

        this.columnPLIRaisedBy$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIRaisedBy = data));

        this.columnPLISubsystemName$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLISubsystemName = data));

        this.columnPLITagType$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnPLITagType = data));

        this.columnPLITagName$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnPLITagName = data));

        this.columnPLIMaterialReference$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIMaterialReference = data));

        this.columnPLIDisciplines$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIDisciplines = data));

        this.columnPLIStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPLIStatus = data));
    }

    getSubsystems = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getPLISubsystemsFiltered(search, take, page, this.filter)
            .pipe(map((systems: SubsystemDTO[]) => systems));
    };

    getPliNumbers = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getPLINumbersFiltered(search, take, page, this.filter)
            .pipe(map((pliNUmbers: string[]) => pliNUmbers));
    };

    getDescriptions = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getDescriptionsFiltered(search, take, page, this.filter)
            .pipe(map((descriptions: string[]) => descriptions));
    };

    getTagNumbers = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getTagNumbersFiltered(search, take, page, this.filter)
            .pipe(map((tagNos: string[]) => tagNos));
    };

    getActionBys = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getActionBysFiltered(search, take, page, this.filter)
            .pipe(map((actionBys: string[]) => actionBys));
    };

    getCategories = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getCategoriesFiltered(search, take, page, this.filter)
            .pipe(map((categories: string[]) => categories));
    };

    getPunchTypes = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getPunchTypesFiltered(search, take, page, this.filter)
            .pipe(map((punchTypes: string[]) => punchTypes));
    };

    getRaisedBys = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getRaisedBys(search, take, page, this.filter)
            .pipe(map((raisedBys: string[]) => raisedBys));
    };

    getSubsystemNames = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getSubsystemNames(search, take, page, this.filter)
            .pipe(map((subsystemNames: string[]) => subsystemNames));
    };

    getTagTypes = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getTagTypes(search, take, page, this.filter)
            .pipe(map((tagTypes: string[]) => tagTypes));
    };

    getTagNames = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getTagNames(search, take, page, this.filter)
            .pipe(map((tagNames: string[]) => tagNames));
    };

    getMaterialReferences = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getMaterialReferences(search, take, page, this.filter)
            .pipe(map((manterialReferences: string[]) => manterialReferences));
    };

    getDisciplines = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .getDisciplines(search, take, page, this.filter)
            .pipe(map((disciplines: string[]) => disciplines));
    };

    getConstraintFlags = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .searchConstraintFlagForPLIFilter(search, take, page, this.filter)
            .pipe(map((constraints: string[]) => constraints));
    };

    getStatuses = (search = '', take = 30, page = 0) => {
        return this.pliPlanningService
            .searchStatusForPLIFilter(search, take, page, this.filter)
            .pipe(map((statuses: string[]) => statuses));
    };

    getConstraintFlag(element: PLIPlanningDTO){
        let constraintFlag = this.dataSource.data.find((pli)=> pli.finalPunchItem === element.finalPunchItem)?.constraintFlag;
        return constraintFlag ? constraintFlag : this.weeklyPlanUncommittedPLIs.find((pli)=> pli.finalPunchItem === element.finalPunchItem)?.constraintFlag;
    }

    isUnCommitted(pli: PLIPlanningDTO): boolean {
        return this.weeklyPlanUncommittedPLIs.find((result) => result.finalPunchItem === pli.finalPunchItem) != undefined;
    }

    isSelected(row: PLIPlanningDTO) {
        return this.weeklyPlanUncommittedPLIs.find((result) => result.finalPunchItem === row.finalPunchItem) === undefined;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    isAnySelected() {
        return this.selection.selected.length > 0;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    /** Columns visibility management */
    resetColumns() {
        this.columnsVisibility = { ...new PLIColumnsVisibility() };
    }

    reverseValue(columnName: string) {
        this.columnsVisibility = {
            ...this.columnsVisibility,
            [columnName]: !this.columnsVisibility[columnName],
        };
    }

    /** Pagination management */
    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.pageSize = pageEvent.pageSize;
            this.filter.take = this.pageSize;
            this.store.dispatch(
                new PLIPlanningFilterUpdate({
                    ...this.filter,
                    pageSize: pageEvent.pageSize,
                } as PLIPlanningFilter)
            );
        } else {
            this.store.dispatch(
                new PLIPlanningFilterUpdate({
                    ...this.filter,
                    page: pageEvent.pageIndex,
                } as PLIPlanningFilter)
            );
        }
        this.selection.clear();
        this.store.dispatch(new PLIPLanningDataRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    updateFilter(filter: PLIPlanningFilter) {
        this.store.dispatch(
            new PLIPlanningFilterUpdate({
                ...filter,
            } as PLIPlanningFilter)
        );
    }

    search() {
        this.store.dispatch(new PLIPLanningDataRequest());
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        isSortingOff: boolean = false,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(
                HeaderChecklistFilterComponent,
                columnName === 'description' ? 800 : 400,
                650,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    placeHolder: placeholder,
                    searchFunc: searchFunc,
                    selectedItems: selected ? [...selected.items] : [],
                    excludeBlanks: excludeBlanks,
                    onlyBlanks: onlyBlanks,
                    isSortingOff: isSortingOff,
                    showCounts,
                    showInputSearch: showInputSearch,
                    showBlankOptions: showBlankOptions,
                    action: (data) => {
                        let value = new CheckListColumn();
                        value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                        value.excludeBlanks = data.excludeBlanks;
                        value.onlyBlanks = data.onlyBlanks;
                        this.store.dispatch(
                            new PLIPlanningFilterUpdate({
                                ...this.filter,
                                [propertyName]: value,
                            } as PLIPlanningFilter)
                        );

                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                        this.search();
                    },
                    resetColumnAction: () => {
                        this.filter.direction = OrderDirection.Desc;
                        this.filter.sortBy = 'finalPunchItem';
                        this.store.dispatch(
                            new PLIPlanningFilterUpdate({
                                ...this.filter,
                                [propertyName]: null,
                            } as PLIPlanningFilter)
                        );
                    },
                    cancelAction: () => {
                        this.store.dispatch(
                            new PLIPlanningFilterUpdate({
                                ...this.filter,
                                [propertyName]: null,
                            } as PLIPlanningFilter)
                        );
                    },
                }
            )
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new PLIPlanningFilterUpdate({
                    ...this.filter,
                    sortBy: columnName,
                    direction: direction,
                } as PLIPlanningFilter)
            );
        } else {
            this.store.dispatch(
                new PLIPlanningFilterUpdate({
                    ...this.filter,
                    sortBy: 'finalPunchItem',
                    direction: OrderDirection.Desc,
                } as PLIPlanningFilter)
            );
        }
    }

    openHeaderDateFilter(propertyName: string, placeholder: string, calendarColumn: CalendarColumn) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(
                HeaderDateFilterComponent,
                null,
                null,
                {
                    isAscendingSort: false,
                    isDescendingSort: false,
                    calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                    placeHolder: placeholder,
                    isSortingOff: true,
                    action: (data) => {
                        this.store.dispatch(
                            new PLIPlanningFilterUpdate({
                                ...this.filter,
                                [propertyName]: data.calendarColumn,
                            } as PLIPlanningFilter)
                        );
                        this.search();
                    },
                },
                345,
                580
            )
        );
    }

    updateIsConstrained() {
        if(this.selectedRow)
            if (!this.selectedRow.isConstrained || !this.selectedRow.constraintFlag) {
                this.popupSvc
                    .openPopup(new PopupSettings(ConstraintTypeSelectorPopupComponent, 450, 300, {}))
                    .afterClosed()
                    .subscribe((dialogResult) => {
                        if (_.has(dialogResult, 'constraintType')) {
                                const updatedPli = {
                                    ...this.selectedRow,
                                    constraintFlag: dialogResult.constraintType.name,
                                    isConstrained: true
                                } as PLIPlanningDTO;
                                this.selectedRow = updatedPli;
                                this.uncommitPLI(updatedPli);
                            } else {
                                this.store.dispatch(new PLIPLanningDataUpdate([...this.dataSource.data]));
                            }
                    });
            }
        return;
    }

    commitPLIs(toCommit: PLIPlanningDTO[]) {
        toCommit.forEach(itemToCommit => {
            this.weeklyPlanUncommittedPLIs = this.weeklyPlanUncommittedPLIs.filter((pli) => pli.finalPunchItem != itemToCommit.finalPunchItem);

            const updatedPLI = {
                ...itemToCommit,
                isCommitted: true
            } as PLIPlanningDTO
            this.updateDataPLI(updatedPLI);
        });
        this.store.dispatch(new UncommittedPLIsUpdate(this.weeklyPlanUncommittedPLIs));
    }

    uncommitPLI(pli: PLIPlanningDTO) {
        if(!this.weeklyPlanUncommittedPLIs.find(result => result.finalPunchItem === pli.finalPunchItem))
        {
            const updatedPLI = {
                ...pli,
                isCommitted: false
            } as PLIPlanningDTO
            this.weeklyPlanUncommittedPLIs = [...this.weeklyPlanUncommittedPLIs, updatedPLI]
            this.store.dispatch(new UncommittedPLIsUpdate(this.weeklyPlanUncommittedPLIs));
            this.updateDataPLI(updatedPLI);
        }
    }

    uncommitPLIs(plis: PLIPlanningDTO[]) {
        plis.forEach(pli => {
            if(!this.weeklyPlanUncommittedPLIs.find(result => result.finalPunchItem === pli.finalPunchItem))
            {
                const updatedPLI = {
                    ...pli,
                    isCommitted: false
                } as PLIPlanningDTO
                this.weeklyPlanUncommittedPLIs = [...this.weeklyPlanUncommittedPLIs, updatedPLI]
            }
        });
        this.store.dispatch(new UncommittedPLIsUpdate(this.weeklyPlanUncommittedPLIs));
    }

    updateDataPLI(pli: PLIPlanningDTO){
        var newData = this.dataSource.data.map(result => {
            if(result.finalPunchItem === pli.finalPunchItem)
               return {
                ...result,
                isCommitted: pli.isCommitted,
                constraintFlag: pli.constraintFlag,
                isConstrained: pli.isConstrained
            } as PLIPlanningDTO
            return result
        });
        this.dataSource.data = newData;
    }

    applyLocalChangesToData(){
        var changesApplied = false;
        var newData = this.dataSource.data.map(result => {
            const found = this.weeklyPlanUncommittedPLIs.find((pli) => pli.finalPunchItem === result.finalPunchItem);
            if(found) {
                changesApplied = true;
                return {
                    ...result,
                    isCommitted: found.isCommitted,
                    constraintFlag: found.constraintFlag,
                    isConstrained: found.isConstrained
                } as PLIPlanningDTO
            }
            return result
        });
        if(changesApplied)
            this.dataSource.data = newData;
    }

    updateData(){
        this.weeklyPlanUncommittedPLIs.forEach((item) => {
            this.updateDataPLI(item)
        })
    }

    highlightRow(row: any) {
        this.selectedRow = row;
    }

    download(attachment: AttachmentDTO, e) {
        e.stopPropagation();
        e.preventDefault();
        this.uploadedAttachmentsActionInProgress.setValue(attachment.link, true);
        this.pliPlanningService
            .downloadAttachment(attachment.link)
            .pipe(
                take(1),
                finalize(() => this.uploadedAttachmentsActionInProgress.setValue(attachment.link, false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, attachment.name);
            });
    }
}
