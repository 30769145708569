<div class="validation-progress-container">
    <h4>validation progress</h4>
    <div class="chart-container">
        <div *ngIf="totalChangeDocuments$ | async" class="progress-bar-chart-container">
            <div app-progress-bar [value]="completed"></div>
            <div class="progress-bar-footer">
                <div class="label">0</div>
                <div class="label center">Completed</div>
                <div class="label right">{{ totalChangeDocuments$ | async }}</div>
            </div>
        </div>
        <div *ngIf="validationProgressData.length" class="validation-progress-chart-container">
            <ngx-charts-bar-horizontal-stacked
                [results]="validationProgressData"
                [scheme]="colorScheme"
                [view]="[300, 150]"
                [xAxis]="true"
                [xAxisTickFormatting]="formatPercentage"
                [yAxis]="true"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [showGridLines]="false"
            >
                <ng-template #tooltipTemplate let-model="model">
                    <span>{{ model.series }} &#9679; {{ model.name }}</span
                    ><br />
                    <span class="tooltip-value">{{ getTooltipValue(model.value, model.series) }}</span>
                </ng-template>
            </ngx-charts-bar-horizontal-stacked>
            <div class="legend">
                <div class="completed">completed: {{ getLegendValue('completed') }}%</div>
                <div class="test-pack-requested">test pack requested: {{ getLegendValue('test pack requested') }}%</div>
                <!-- <div class="data-updated">data updated: {{ getLegendValue('data updated') }}%</div> -->
                <div class="in-progress">in progress: {{ getLegendValue('in progress') }}%</div>
                <div class="in-engineering">in engineering: {{ getLegendValue('in engineering') }}%</div>
                <div class="not-started">not started: {{ getLegendValue('not started') }}%</div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading$ | async" class="validation-progress-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
