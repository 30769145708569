import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sc-planning-configuration',
    templateUrl: './sc-planning-configuration.component.html',
    styleUrls: ['./sc-planning-configuration.component.scss']
})
export class ScPlanningConfigurationComponent implements OnInit {
    isLoading = false;

    constructor() {
    }

    ngOnInit(): void {
        // This is intentional
    }
}
