import { RedZoneState, RedZoneDataPagination, RedZoneFilter } from './model';
import { createReducer, on } from '@ngrx/store';
import * as RedZoneActions from './actions';

const initialState: RedZoneState = {
    dataPagination: new RedZoneDataPagination(),
    isLoading: false,
    filter: new RedZoneFilter(),
};

export const redZoneStoreFeatureKey = 'redZone';

export const redZoneReducer = createReducer(
    initialState,
    on(RedZoneActions.filterSuccess, (state, action) => ({
        ...state,
        dataPagination: { items: action.items, totalCount: action.totalCount },
        isLoading: false,
    })),
    on(RedZoneActions.filterPropertyUpdate, (state, action) => {
        if (action.key === 'sortBy') {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    sortBy: action.value.active,
                    direction: action.value.direction,
                },
            };
        } else {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.key]: Array.isArray(action.value)
                        ? [...action.value]
                        : action.value && typeof action.value === 'object'
                        ? { ...action.value }
                        : action.value,
                },
            };
        }
    }),
    on(RedZoneActions.filterReset, (state) => {
        const newFilter = new RedZoneFilter();
        newFilter.showHideColumns = state.filter.showHideColumns;
        newFilter.sortBy = state.filter.sortBy;
        newFilter.direction = state.filter.direction;
        newFilter.disciplineBreakdownEnabled = state.filter.disciplineBreakdownEnabled;
        return {
            ...state,
            filter: { ...newFilter },
        };
    }),
    on(RedZoneActions.addCommentSuccess, (state, action) => {
        const itemIndex = state.dataPagination.items.findIndex((x) =>
            state.filter.disciplineBreakdownEnabled
                ? x.subsystemToDisciplineId === action.id
                : x.subsystem === action.id
        );
        return {
            ...state,
            isLoading: false,
            dataPagination: {
                ...state.dataPagination,
                items: state.dataPagination.items.map((x, i) => {
                    if (i === itemIndex) {
                        return {
                            ...x,
                            comment: action.description,
                            mentions: action.mentions,
                        };
                    }
                    return x;
                }),
            },
        };
    }),
    on(RedZoneActions.addBulkCommentSuccess, (state, action) => ({
        ...state,
        dataPagination: {
            ...state.dataPagination,
            items: state.dataPagination.items.map((i) => ({
                ...i,
                comment: action.description,
                mentions: action.mentions,
            })),
        },
        isLoading: false,
    })),
    on(RedZoneActions.clearFlagColorSuccess, (state) => ({
        ...state,
        dataPagination: {
            ...state.dataPagination,
            items: state.dataPagination.items.map((i) => (i.redZoneFlag = null)),
        },
        isLoading: false,
    })),
    on(RedZoneActions.removeCommentSuccess, (state, action) => {
        const itemIndex = state.dataPagination.items.findIndex((x) =>
            state.filter.disciplineBreakdownEnabled
                ? x.subsystemToDisciplineId === action.entityId
                : x.subsystem === action.entityId
        );
        return {
            ...state,
            isLoading: false,
            dataPagination: {
                ...state.dataPagination,
                items: state.dataPagination.items.map((x, i) => {
                    if (i === itemIndex) {
                        return {
                            ...x,
                            comment: action.lastComment.description,
                            mentions: action.lastComment.mentions,
                        };
                    }
                    return x;
                }),
            },
        };
    }),
    on(RedZoneActions.setFlagColorSuccess, (state, action) => ({
        ...state,
        dataPagination: {
            ...state.dataPagination,
            items: state.dataPagination.items.map((value) =>
                value.subsystem === action.subsystem
                    ? { ...value, redZoneFlag: value.redZoneFlag !== action.color ? action.color : '' }
                    : value
            ),
        },
        isLoading: false,
    })),
    on(
        RedZoneActions.setFlagColorRequest,
        RedZoneActions.removeCommentRequest,
        RedZoneActions.clearFlagColorRequest,
        RedZoneActions.addBulkCommentRequest,
        RedZoneActions.exportToExcelRequest,
        RedZoneActions.forecastDateUpdateRequest,
        RedZoneActions.addCommentRequest,
        RedZoneActions.filterRequest,
        (state) => ({ ...state, isLoading: true })
    ),
    on(
        RedZoneActions.exportToExcelSuccess,
        RedZoneActions.exportToExcelError,
        RedZoneActions.forecastDateUpdateSuccess,
        RedZoneActions.forecastDateUpdateError,
        RedZoneActions.exportToExcelError,
        RedZoneActions.addCommentError,
        RedZoneActions.addBulkCommentError,
        RedZoneActions.clearFlagColorError,
        RedZoneActions.setFlagColorError,
        RedZoneActions.filterError,
        RedZoneActions.removeCommentError,
        (state) => ({ ...state, isLoading: false })
    )
);
