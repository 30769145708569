import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, Gooc, RFO } from '../common.model';

export interface ChangeValidationState {
    dataPagination: ChangeValidationDataPagination;
    filter: ChangeValidationFilter;
    isLoading: boolean;
}

export class ChangeValidationDataPagination {
    items: ChangeValidationChangeDocumentDTO[] = [];
    totalCount = 0;
}

export enum OrderDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export class ChangeValidationFilter {
    page = 0;
    take = 25;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;
    types: string[] = [];
    number = '';
    subsystem = '';
    area = '';
    validators: string[] = [];
    disciplines: number[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    validationStatuses: string[] = [];
    title = '';
    dataUpdated: string[] = ['0', '1'];
    isActive = true;
    isSystemisationComplete = 'all';
    subsystems: { id: string }[] = [];
    projectTeamNames: string[] = [];
    cwps: { id: string }[] = [];
    changeStatuses: string[] = [];
    columnNumber: CheckListColumn = null;
    columnRevision: CheckListColumn = null;
    columnType: CheckListColumn = null;
    columnChangeStatus: CheckListColumn = null;
    columnSubsystem: CheckListColumn = null;
    columnRfo: CheckListColumn = null;
    columnIssueDate: CalendarColumn = null;
    columnValidationStatus: CheckListColumn = null;
    columnELEValidationStatus: CheckListColumn = null;
    columnICTValidationStatus: CheckListColumn = null;
    columnSMPValidationStatus: CheckListColumn = null;
    columnTitle: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnProjectTeamName: CheckListColumn = null;
    columnArea: CheckListColumn = null;
    columnCWP: CheckListColumn = null;
    columnDataUpdated: CheckListColumn = null;    
    columnValidator: CheckListColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnrfsuPlan: CalendarColumn = null;
    columnrfsuForecast: CalendarColumn = null;
    columnrfsuActual: CalendarColumn = null;
    columnmcPlan: CalendarColumn = null;
    columnmcForecast: CalendarColumn = null;
    columnmcActual: CalendarColumn = null;
    columnrfoPlan: CalendarColumn = null;
    columnrfoForecast: CalendarColumn = null;
    columnrfoActual: CalendarColumn = null;
    columnimplementationStatus: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnFieldImplementationContractor: CheckListColumn = null;
    columnStepStatus: CheckListColumn = null;
    timezoneOffset = 0;
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
    singleViewEnabled : boolean = true;
    showHideColumns = new ShowHideColumns();
}

export class ChangeValidationChangeDocumentDTO {
    id: number;
    validationId: number;
    number: string;
    revision: string;
    changeType: string;
    status: string;
    issueDate: moment.Moment;
    validationStatus: string;
    validationELEStatus: string;
    validationICTStatus: string;
    validationSMPStatus: string;
    title: string;
    discipline: string;
    projectTeamName: string;
    contractor: string;
    fieldImplementationContractor: string;
    impactedArea: string;
    impactedCWP: string;
    dataUpdated: boolean;
    validator: string;
    ictvalidator: string;
    smpvalidator: string;
    subsystem: string;
    rfo: string;
    createdDate: moment.Moment = null;
    rfsuPlan: moment.Moment = null;
    rfsuForecast: moment.Moment = null;
    rfsuActual: moment.Moment = null;
    mcPlan: moment.Moment = null;
    mcForecast: moment.Moment = null;
    mcActual: moment.Moment = null;
    rfoPlan: moment.Moment = null;
    rfoForecast: moment.Moment = null;
    rfoActual: moment.Moment = null;
    implementationStatus:string;
    currentStep: number;
}

export class ShowHideColumns {
    number = true;
    revision = true;
    changeType = true;
    status = true;
    createdDate = true;
    issueDate = true;
    validationStatus = true;
    title = true;
    discipline = true;
    projectTeamName = true;
    contractor = true;
    fieldImplementationContractor = false;
    dataUpdated = true;
    validator = true;
    subsystem = false;
    rfsuPlan = false;
    rfsuForecast = false;
    rfsuActual = false;
    mcPlan = false;
    mcForecast = false;
    mcActual = false;
    rfo = false;
    rfoPlan = false;
    rfoForecast = false;
    rfoActual = false;
    implementationStatus = false;
    stepStatus = true;
}
