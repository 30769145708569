import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum ITRActionTypes {
    ITRUploadLogRequest = '[ITR] Upload Log Request',
    ITRUploadLogSuccess = '[ITR] Upload Log Success',
    ITRUploadLogError = '[ITR] Upload Log Error',
    ITRSetStatusAndStartDate = 'ITRI] Set Status and Start Date',
    ITRFileUploaded = '[ITR] File Uploaded',
    ITRClearInProgressSpinner = '[ITR] Clear In Progress Spinner',
    ITRTemplateFileRequest = '[ITR] Template File Request',
    ITRTemplateFileSuccess = '[ITR] Template File Success',
    ITRTemplateFileError = '[ITR] Template File Error',
    ITRValidateDeltaRequest = '[ITR] Validate Delta Request',
    ITRValidateDeltaSuccess = '[ITR] Validate Delta Success',
    ITRValidateDeltaError = '[ITR] Validate Delta Error',
    ITRDownloadOutputDataRequest = '[ITR] Output data Request',
    ITRDownloadOutputDataSuccess = '[ITR] Output data Success',
    ITRDownloadOutputDataError = '[ITR] Output data File Error',
    ITRAddStartedLog = '[ITR] Add Started Log',
    ITRValidateButtonStateSuccess = '[ITR] Validate Button State Success',
    ITRValidateButtonStateError = '[ITR] Validate Button State Error',
}

export class ITRAddStartedLog implements Action {
    readonly type = ITRActionTypes.ITRAddStartedLog;
}

export class ITRUploadLogRequest implements Action {
    readonly type = ITRActionTypes.ITRUploadLogRequest;
}

export class ITRUploadLogSuccess implements Action {
    readonly type = ITRActionTypes.ITRUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ITRUploadLogError implements Action {
    readonly type = ITRActionTypes.ITRUploadLogError;

    constructor(public payload: string) {}
}

export class ITRFileUploaded implements Action {
    readonly type = ITRActionTypes.ITRFileUploaded;
}

export class ITRClearInProgressSpinner implements Action {
    readonly type = ITRActionTypes.ITRClearInProgressSpinner;
}

export class ITRTemplateFileRequest implements Action {
    readonly type = ITRActionTypes.ITRTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ITRTemplateFileSuccess implements Action {
    readonly type = ITRActionTypes.ITRTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ITRTemplateFileError implements Action {
    readonly type = ITRActionTypes.ITRTemplateFileError;

    constructor(public payload: string) {}
}

export class ITRDownloadOutputDataRequest implements Action {
    readonly type = ITRActionTypes.ITRDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ITRDownloadOutputDataSuccess implements Action {
    readonly type = ITRActionTypes.ITRDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ITRDownloadOutputDataError implements Action {
    readonly type = ITRActionTypes.ITRDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ITRSetStatusAndStartDate implements Action {
    readonly type = ITRActionTypes.ITRSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class ITRValidateDeltaRequest implements Action {
    readonly type = ITRActionTypes.ITRValidateDeltaRequest;
}

export class ITRValidateDeltaSuccess implements Action {
    readonly type = ITRActionTypes.ITRValidateDeltaSuccess;
}

export class ITRValidateDeltaError implements Action {
    readonly type = ITRActionTypes.ITRValidateDeltaError;

    constructor(public payload: string) {}
}

export class ITRValidateButtonStateSuccess implements Action {
    readonly type = ITRActionTypes.ITRValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; isAutomaticUploadInProgress: boolean; }) {}
}

export class ITRValidateButtonStateError implements Action {
    readonly type = ITRActionTypes.ITRValidateButtonStateError;

    constructor(public payload: string) {}
}

export type ITRActions =
    | ITRUploadLogRequest
    | ITRUploadLogSuccess
    | ITRUploadLogError
    | ITRFileUploaded
    | ITRClearInProgressSpinner
    | ITRTemplateFileRequest
    | ITRTemplateFileSuccess
    | ITRTemplateFileError
    | ITRSetStatusAndStartDate
    | ITRValidateDeltaRequest
    | ITRValidateDeltaSuccess
    | ITRValidateDeltaError
    | ITRDownloadOutputDataRequest
    | ITRDownloadOutputDataSuccess
    | ITRDownloadOutputDataError
    | ITRAddStartedLog
    | ITRValidateButtonStateSuccess
    | ITRValidateButtonStateError;
