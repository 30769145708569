import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap, takeWhile, delay, take, finalize } from 'rxjs/operators';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { OrderDirection } from 'src/app/store/common.model';
import * as moment from 'moment';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { BaseComponent } from 'src/app/components/base.component';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';

@Component({
    selector: 'app-mcplus',
    templateUrl: './mcplus.component.html',
    styleUrls: ['./mcplus.component.scss'],
})
export class MCPlusComponent extends BaseComponent implements OnInit {
    isAdmin = false;
    isImportHistoryLoading = false;
    fileUploading: boolean;
    processInProgress: boolean;
    fileDownloading: boolean;
    showOrder: boolean = true;
    isConfigLoading = false;
    readonly displayedColumns: string[] = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];

    dataSource: MatTableDataSource<UpdateDataLog>;
    resultsLength = 0;
    direction = OrderDirection.Desc;
    pageSize = 10;
    dataTypesReadyToProcess: boolean = false;
    dataTypesInProgress: boolean = false;
    recordsForDeletion = 0;

    MCPlusRegisterName = 'SCMT CM to Subsystem.xlsx';
    logSubscription$: Subscription;
    dataType = 'MCPlus';

    constructor(
        private adminPanelService: AdminPanelService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private roleService: RoleService,
        private popupService: PopupService,
        private manualUploadsService: ManualUploadService,
        private configurationService: ConfigurationService
    ) {
        super();
    }

    ngOnInit() {
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);

        this.getImportHistoryData([this.dataType]);
        this.adminPanelService.downloadFileSubject$.pipe(takeWhile(() => this.isAlive)).subscribe((downloadSuccess) => {
            this.fileDownloading = false;

            if (!downloadSuccess) {
                this.toastService.Error('Error occured while downloading file. Please contact Program Administrator.');
            }
        });
    }

    setDataInfo() {
        this.manualUploadsService
            .getValidateButtonState(this.dataType)
            .pipe(take(1))
            .subscribe(
                (data: any) => {
                    this.dataTypesReadyToProcess = data.isReadyForUpload;
                    this.recordsForDeletion = data.recordsForDeletion;
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while refreshing buttons status. Please contact Program Administrator.'
                    );
                }
            );
    }

    getImportHistoryData(types: string[], withDelay: boolean = false) {
        if (this.logSubscription$) {
            this.logSubscription$.unsubscribe();
        }
        let logsInterval = interval(Constants.logRefreshInterval).pipe(
            startWith(0),
            takeWhile(() => this.isAlive),
            delay(+withDelay * Constants.delayAfterUploadInterval),
            switchMap((_) => {
                this.isImportHistoryLoading = true;
                return this.adminPanelService.getLogs(types);
            })
        );
        this.logSubscription$ = logsInterval.pipe(takeWhile(() => this.isAlive)).subscribe(
            (data) => {
                this.isImportHistoryLoading = false;
                this.setDataInfo();
                this.dataSource = new MatTableDataSource(data);
                this.changeDetectorRef.detectChanges();
            },
            () => {
                this.toastService.Error('Error occurred while getting logs. Please contact Program Administrator.');
            }
        );
    }

    fileChangeEvent(files: Array<File>) {
        this.showOrder = false;
        const formData = new FormData();
        const filesWrapper = new Array<any>();
        filesWrapper.push(...files);

        this.isConfigLoading = true;
        this.configurationService
            .getConfigData()
            .pipe(
                takeWhile(() => this.isAlive),
                finalize(() => (this.isConfigLoading = false))
            )
            .subscribe((configData) => {
                const expectedFilenames = configData
                    .find((x) => x.key === 'Filenames for MCPlus Change-Subsystem Import')
                    .value.split(',')
                    .map((n) => n.trim());

                if (!filesWrapper.every((f) => expectedFilenames.indexOf(f.name) > -1)) {
                    this.toastService.Error(
                        'Please select all expected files: ' +
                            expectedFilenames.join(', ') +
                            ' (you can change it in the configuration)'
                    );
                    return;
                } else {
                    filesWrapper.forEach((file) => {
                        const fileName = file.name || file.fileName;
                        formData.append('MCPlus', file, fileName);
                    });
                    this.dataTypesReadyToProcess = false;
                    this.dataTypesInProgress = true;
                    this.recordsForDeletion = 0;
                    let updateLog = new UpdateDataLog();
                    updateLog.status = ImportStatuses.Started;
                    updateLog.type = this.dataType;
                    updateLog.startDate = moment();
                    const data = this.dataSource.data;
                    data.unshift(updateLog);
                    this.dataSource.data = data;
                    this.fileUploading = true;
                    this.adminPanelService
                        .uploadFiles(formData)
                        .pipe(takeWhile(() => this.isAlive))
                        .subscribe(
                            () => {
                                this.getImportHistoryData([this.dataType], true);
                                this.fileUploading = false;
                                this.toastService.Success(
                                    'Files successfully uploaded. Please check progress indicator and table with logs to check status of preprocessing.'
                                );
                            },
                            () => {
                                this.fileUploading = false;
                                this.toastService.Error(
                                    'Error occurred while uploading files. Please contact Program Administrator.'
                                );
                            }
                        );
                }
            });
    }

    downloadData(filePath: string) {
        this.fileDownloading = true;
        this.adminPanelService.downloadFile(filePath);
    }

    process() {
        let warning = this.recordsForDeletion > 0 ?
        `<p><span class="warning"">Warning: </span> This action will delete ${this.recordsForDeletion} record(s).</p>` : '';
        
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    htmlText: `<p>Are you sure you want to import data and update existing records?</p>`
                    + warning,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    this.processInProgress = true;
                    this.manualUploadsService
                        .validateMCPlusDelta()
                        .pipe(takeWhile(() => this.isAlive))
                        .subscribe(
                            () => {
                                this.dataTypesReadyToProcess = false;
                                this.processInProgress = false;
                                this.recordsForDeletion = 0;
                            },
                            () => {
                                this.processInProgress = false;
                                this.toastService.Error(
                                    'Error occurred while processing data type. Please contact Program Administrator'
                                );
                            }
                        );
                }
            });
    }

    downloadRegisterTemplate() {
        this.fileDownloading = true;
        this.adminPanelService.downloadRegisterTemplate(this.MCPlusRegisterName);
    }
}
