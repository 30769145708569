import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import {
    CheckListColumn,
    OrderDirection,
    ExcludeFilterAssignmentDTO,
    SubsystemExcludeFilterDTO,
    SubsystemExcludeFilterRequestDTO,
    SubsystemExcludeFiltersDTO,
} from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    PlanningConfigSubsystemAssignExcludeFiltersRequest,
    PlanningConfigSubsystemExcludeFilterPropertyUpdate,
    PlanningConfigSubsystemExcludeFilterReset,
    PlanningConfigSubsystemExcludeFiltersRequest,
} from 'src/app/store/planning-configuration/actions';
import {
    ExcludeFilterAssignmentComponent,
    ExcludeFilterAssignmentProps,
    ExcludeFilterData,
} from '../exclude-filter-assignment/exclude-filter-assignment.component';

@Component({
    selector: 'app-sc-exclude-subsystem-configuration',
    templateUrl: './sc-exclude-subsystem-configuration.component.html',
    styleUrls: ['./sc-exclude-subsystem-configuration.component.scss'],
})
export class ScExcludeSubsystemConfigurationComponent extends BaseComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    subsystemData$ = this.store.select((store) => store.planningConfigState.subsystemData);
    subsystemFilter$ = this.store.select((store) => store.planningConfigState.subsystemFilter);
    displayedColumns: string[] = ['select', 'subsystem', 'name', 'excludeFilters'];
    totalLength = 0;
    pageSize = 10;

    subsystemFilter = new SubsystemExcludeFilterRequestDTO();
    dataSource = new MatTableDataSource<SubsystemExcludeFiltersDTO>([]);
    selection = new SelectionModel<SubsystemExcludeFiltersDTO>(true, []);

    constructor(
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private lookupService: LookupService
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(new PlanningConfigSubsystemExcludeFiltersRequest());
        this.subsystemData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data) {
                this.dataSource.data = data.data;
                this.totalLength = data.total;
            }
        });

        this.subsystemFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data) {
                this.subsystemFilter = { ...this.subsystemFilter, ...data };
            }
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    isAnySelected() {
        return this.selection.selected.length > 0;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new PlanningConfigSubsystemExcludeFilterPropertyUpdate({
                    pageSize: pageEvent.pageSize,
                } as SubsystemExcludeFilterRequestDTO)
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new PlanningConfigSubsystemExcludeFilterPropertyUpdate({
                    page: pageEvent.pageIndex,
                } as SubsystemExcludeFilterRequestDTO)
            );
        }
        this.selection.clear();
        this.store.dispatch(new PlanningConfigSubsystemExcludeFiltersRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        isSortingOff: boolean = false,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort:
                    this.subsystemFilter.sortBy === columnName && this.subsystemFilter.direction == OrderDirection.Asc,
                isDescendingSort:
                    this.subsystemFilter.sortBy === columnName && this.subsystemFilter.direction == OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                excludeBlanks: excludeBlanks,
                onlyBlanks: onlyBlanks,
                isSortingOff: isSortingOff,
                showCounts,
                showInputSearch: showInputSearch,
                showBlankOptions: showBlankOptions,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.store.dispatch(
                        new PlanningConfigSubsystemExcludeFilterPropertyUpdate({
                            ...this.subsystemFilter,
                            [propertyName]: value,
                        } as SubsystemExcludeFilterRequestDTO)
                    );
                    this.selection.clear();
                    this.store.dispatch(new PlanningConfigSubsystemExcludeFiltersRequest());
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new PlanningConfigSubsystemExcludeFilterPropertyUpdate({
                            ...this.subsystemFilter,
                            [propertyName]: null,
                        } as SubsystemExcludeFilterRequestDTO)
                    );
                    this.selection.clear();
                    this.store.dispatch(new PlanningConfigSubsystemExcludeFiltersRequest());
                },
            })
        );
    }

    openAssignmentDialog(isAssignment: boolean = true) {
        this.popupSvc.openPopup(
            new PopupSettings<ExcludeFilterAssignmentProps>(ExcludeFilterAssignmentComponent, 400, 300, {
                entityType: 'subsystem',
                data: this.getSelectedData(),
                isAssignment,
                getOptions: isAssignment ? this.getExcludeValuesOptions : this.getExcludeValuesOptionsFromSelections,
                onSumbit: (data: ExcludeFilterAssignmentDTO) => {
                    this.store.dispatch(new PlanningConfigSubsystemAssignExcludeFiltersRequest({ data, isAssignment }));
                    this.selection.clear();
                },
                onCancel: () => {},
            })
        );
    }

    getSelectedData = (): ExcludeFilterData[] =>
        this.selection.selected.map(
            (item) =>
                ({
                    id: item.subsystem,
                    excludeFilters: item.excludeFilters.map((f) => f.excludeFilter),
                } as ExcludeFilterData)
        );

    resetFilters() {
        this.store.dispatch(new PlanningConfigSubsystemExcludeFilterReset());
        this.store.dispatch(new PlanningConfigSubsystemExcludeFiltersRequest());
    }

    getSubsystems = (search = '', pageSize = 10, page = 0) =>
        this.lookupService.searchSubsystemsWithExcludeFilter({ ...this.subsystemFilter, search, pageSize, page });

    getSubsystemNames = (search = '', pageSize = 10, page = 0) =>
        this.lookupService.searchSubsystemNamesWithExcludeFilter({ ...this.subsystemFilter, search, pageSize, page });

    getExcludeValues = (search = '', pageSize = 10, page = 0) =>
        this.lookupService.searchExcludeValuesWithExcludeFilter({ ...this.subsystemFilter, search, pageSize, page });

    getExcludeValuesOptions = () =>
        this.lookupService
            .searchExcludeValuesWithExcludeFilter({ pageSize: 100, page: 0 } as SubsystemExcludeFilterRequestDTO)
            .pipe(map((x: any[]) => x.map((f) => `${f.value}`)));

    getExcludeValuesOptionsFromSelections = () => {
        const flat = _.flatten(this.selection.selected.map((item) => item.excludeFilters.map((f) => f.excludeFilter)));
        const uniq = _.uniq(flat);
        return of(uniq);
    };

    excludeFiltersToString = (filters: SubsystemExcludeFilterDTO[]) => {
        return filters.map((x) => x.excludeFilter).join(', ');
    };
}
