import { Injectable } from '@angular/core';
import { ProjectTeamRoleDTO } from 'src/app/models/project-team-role-dto';
import {
    ProjectTeamRoleFilter,
    ProjectTeamRolesDataPagination,
} from 'src/app/store/data-maintenance/team-data/projectTeamRole/model';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ProjectTeamRolesMaintenanceService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/projectteamrolemaintenance';
    }

    get(filter: ProjectTeamRoleFilter) {
        return this.http.post<ProjectTeamRolesDataPagination>(`${this.rootUrl}${this.endpoint}/filter`, filter);
    }

    remove(id: string | number) {
        return this.http.delete<ProjectTeamRolesDataPagination>(`${this.rootUrl}${this.endpoint}?id=${id}`);
    }

    update(contractor: ProjectTeamRoleDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, contractor);
    }

    add(contractor: ProjectTeamRoleDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}`, contractor);
    }

    getDbLinksInfo(id: string) {
        return this.http.get(`${this.rootUrl}${this.endpoint}/getDbLinksInfo/${id}`, { responseType: 'text' });
    }
}
