import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    PascrStatusActionTypes,
    PascrStatusFilterSuccess,
    PascrStatusFilterError,
    PascrStatusFilterRequest,
    PascrStatusAddCommentRequest,
    PascrStatusAddCommentSuccess,
    PascrStatusAddCommentError,
    PascrStatusAddBulkCommentRequest,
    PascrStatusAddBulkCommentSuccess,
    PascrStatusAddBulkCommentError,
    PascrStatusRemoveCommentRequest,
    PascrStatusRemoveCommentSuccess,
    PascrStatusRemoveCommentError,
    PascrStatusExportToExcelSuccess,
    PascrStatusExportToExcelError,
} from './actions';
import { map, mergeMap, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { PascrStatusService } from 'src/app/services/api/webapi-services/pascr-status.service';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { Zone } from 'src/app/enums';
import * as moment from 'moment';

@Injectable()
export class PascrStatusEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private pascrStatusService: PascrStatusService,
        private commentService: CommentService
    ) { }

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<PascrStatusFilterRequest>(PascrStatusActionTypes.PascrStatusFilterRequest),
        withLatestFrom(this.store.select((store) => store.pascrStatusState.filter)),
        mergeMap(([action, PascrStatusFilter]) => {
            let filter = action.payload ?? PascrStatusFilter;
            return this.pascrStatusService.searchTag(filter).pipe(
                map((dataPagination) => {
                    return new PascrStatusFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Pascr status. Please contact Program Administrator.'
                    );
                    return of(new PascrStatusFilterError(error));
                })
            );
        })
    );

    @Effect()
    addComment$ = this.actions$.pipe(
        ofType<PascrStatusAddCommentRequest>(PascrStatusActionTypes.PascrStatusAddCommentRequest),
        withLatestFrom(this.store.select((state) => state.detailedStatusState.dataPagination.items)),
        mergeMap(([{ payload }]) =>
            this.commentService.addCommentPascr(payload.pascrNumber, payload.description, payload.mentions).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully added.');
                    return new PascrStatusAddCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating comment. Please contact Program Administrator.'
                    );
                    return of(new PascrStatusAddCommentError(error));
                })
            )
        )
    );

    @Effect()
    addBulkComment$ = this.actions$.pipe(
        ofType<PascrStatusAddBulkCommentRequest>(PascrStatusActionTypes.PascrStatusAddBulkCommentRequest),
        withLatestFrom(this.store.select((state) => state.pascrStatusState.filter)),
        mergeMap(([{ payload }, filter]) =>
            this.commentService.addBulkCommentPascr(payload.description, payload.mentions, filter).pipe(
                map(() => {
                    this.toastService.Success('Bulk Comment successfully added.');
                    return new PascrStatusAddBulkCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating bulk comment. Please contact Program Administrator.'
                    );
                    return of(new PascrStatusAddBulkCommentError(error));
                })
            )
        )
    );

    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<PascrStatusRemoveCommentRequest>(PascrStatusActionTypes.PascrStatusRemoveCommentRequest),
        mergeMap(({ payload }) =>
            this.commentService.deleteCommentPascr(payload.id).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully removed.');
                    return new PascrStatusRemoveCommentSuccess({
                        id: payload.id,
                        pascrNumber: payload.pascrNumber,
                        lastComment: payload.lastComment,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while removing Pascr comment. Please contact Program Administrator.'
                    );
                    return of(new PascrStatusRemoveCommentError(error));
                })
            )
        )
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(PascrStatusActionTypes.PascrStatusExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.pascrStatusState.filter)),
        mergeMap(([, PascrStatusFilter]) => {
            const filter = {
                ...PascrStatusFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.pascrStatusService.generateExcel(filter).pipe(
                map((excelData) => new PascrStatusExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new PascrStatusExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(PascrStatusActionTypes.PascrStatusExportToExcelSuccess),
        map((action: PascrStatusExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, `PASCR_Status_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );
}
