import { Contractor, Gooc, TCOUser } from '../../common.model';

export class GOOCComparisonDashboardFilter {
    goocs: Gooc[] = [];
    page = 0;
    take = 10;
    timezoneOffset = 0;
    contractors: Contractor[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    mcEngineer: TCOUser[] = [];
    systemOwner: TCOUser[] = [];
    scManager: TCOUser[] = [];
    scrollSearch: boolean = false;
}

export interface GOOCComparisonDashboardColumnSummary {
    total: number;
    complete: number;
    percentComplete: string;
}

export interface GOOC1ComparisonDashboardDTO {
    gooc: string;
    goocName: string;
    subsystemMCs: GOOCComparisonDashboardColumnSummary;
    mcWalkdowns: GOOCComparisonDashboardColumnSummary;
    aqvd: GOOCComparisonDashboardColumnSummary;
    apli: GOOCComparisonDashboardColumnSummary;
    npw: GOOCComparisonDashboardColumnSummary;
    redlines: GOOCComparisonDashboardColumnSummary;
}

export interface GOOC2ComparisonDashboardDTO {
    gooc: string;
    goocName: string;
    unallocated: GOOCComparisonDashboardColumnSummary;
    hydrotests: GOOCComparisonDashboardColumnSummary;
    reinstatements: GOOCComparisonDashboardColumnSummary;
    terminations: GOOCComparisonDashboardColumnSummary;
    instrument: GOOCComparisonDashboardColumnSummary;
    exInsp: GOOCComparisonDashboardColumnSummary;
    heatTrace: GOOCComparisonDashboardColumnSummary;
    lsp: GOOCComparisonDashboardColumnSummary;
}

export interface GOOCComparisonDashboardState {
    gooc1Filter: GOOCComparisonDashboardFilter;
    gooc2Filter: GOOCComparisonDashboardFilter;
    gooc1: GOOC1ComparisonDashboardDTO[];
    gooc2: GOOC2ComparisonDashboardDTO[];
    gooc1IsLoading: boolean;
    gooc2IsLoading: boolean;
    gooc1TotalCount: number;
    gooc2TotalCount: number;
}
