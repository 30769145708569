import { BaseComponent } from '../../base.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationState } from 'src/app/store/model';
import { Store, Action } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ManualUploadFilesUploadRequest } from 'src/app/store/manual-upload/actions';
import * as _ from 'lodash';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import {
    CWPRegisterValidateDeltaRequest,
    CWPRegisterFileUploaded,
    CWPRegisterAddStartedLog,
} from 'src/app/store/manual-upload/cwp-register/actions';
import {
    MasterSubsystemListValidateDeltaRequest,
    MasterSubsystemListFileUploaded,
    MasterSubsystemListAddStartedLog,
} from 'src/app/store/manual-upload/master-subsystem-list/actions';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { LiveDataService } from 'src/app/services/api/webapi-services/live-data.service';
import { ImportType } from '../import-type';
import * as moment from 'moment';
import {
    IWPListingFileUploaded,
    IWPListingAddStartedLog,
    IWPListingValidateDeltaRequest,
} from 'src/app/store/manual-upload/iwp-listing/actions';
import { RFOValidateDeltaRequest, RFOFileUploaded, RFOAddStartedLog } from 'src/app/store/manual-upload/rfo/actions';
import { MatTabNav } from '@angular/material/tabs';
import { UploadLinkDTO } from 'src/app/models/upload-link-dto';
import { SystemizedDrawingsValidateDeltaRequest, SystemizedDrawingsFileUploaded, SystemizedDrawingsAddStartedLog } from 'src/app/store/manual-upload/systemized-drawings/actions';
import { COWAddStartedLog, COWFileUploaded, COWValidateDeltaRequest } from 'src/app/store/manual-upload/carry-over-work/actions';
import { FlangeManagementAddStartedLog, FlangeManagementFileUploaded, FlangeManagementValidateDeltaRequest } from 'src/app/store/manual-upload/flange-management/actions';
import { RedlineFileUploaded, RedlineAddStartedLog, RedlineValidateDeltaRequest, } from 'src/app/store/manual-upload/redline/actions';

@Component({
    selector: 'app-manual-upload',
    templateUrl: './manual-upload.component.html',
    styleUrls: ['./manual-upload.component.scss'],
})
export class ManualUploadComponent extends BaseComponent implements OnInit {
    isAdmin = false;
    activatedRoute$ = this.store.select((store) => store.manualUploadState.activatedRoute);

    navLinks: UploadLinkDTO[] = [];
    activeNavLink: UploadLinkDTO;
    isValidateButtonDisabled = true;
    recordsForDeletion = 0;
    validateActions: Action[] = [
        new MasterSubsystemListValidateDeltaRequest(),
        // new CWPRegisterValidateDeltaRequest(),
        // new IWPListingValidateDeltaRequest(),
        // new RFOValidateDeltaRequest(),
        new SystemizedDrawingsValidateDeltaRequest(),
        new COWValidateDeltaRequest(),
        new FlangeManagementValidateDeltaRequest(),
        //new RedlineValidateDeltaRequest(),
    ];
    inProgressObservables = [
        this.store.select((store) => store.masterSubsystemListState.isImportInProgress),
        // this.store.select((store) => store.cwpRegisterState.isImportInProgress),
        // this.store.select((store) => store.iwpListingState.isImportInProgress),
        this.store.select((store) => store.systemizedDrawingsState.isImportInProgress),
        this.store.select((store) => store.cowListState.isImportInProgress),
        this.store.select((store) => store.flangeManagementState.isImportInProgress),
        //this.store.select((store) => store.redlineState.isImportInProgress),
    ];

    isLoading: boolean;
    isUploading$ = this.store.select((store) => store.manualUploadState.uploadInProgress);
    isAutomaticUploadInProgress = true;

    constructor(
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private roleService: RoleService,
        private popupService: PopupService,
        private liveDataService: LiveDataService
    ) {
        super();
        this.navLinks = [
            {
                label: 'master subsystem list',
                link: './mastersubsystemlist',
                index: 0,
                type: 'Subsystems',
                fileUploadedAction: new MasterSubsystemListFileUploaded(),
                addLogAction: new MasterSubsystemListAddStartedLog(),
            },
            // {
            //     label: 'cwp register',
            //     link: './cwpregister',
            //     index: 1,
            //     type: 'CWP',
            //     fileUploadedAction: new CWPRegisterFileUploaded(),
            //     addLogAction: new CWPRegisterAddStartedLog(),
            // },
            // {
            //     label: 'iwp listing',
            //     link: './iwplisting',
            //     index: 2,
            //     type: 'IWP',
            //     fileUploadedAction: new IWPListingFileUploaded(),
            //     addLogAction: new IWPListingAddStartedLog(),
            // },
            // {
            //     label: 'rfo',
            //     link: './rfo',
            //     index: 3,
            //     type: 'RFO',
            //     fileUploadedAction: new RFOFileUploaded(),
            //     addLogAction: new RFOAddStartedLog(),
            // },
            {
                label: 'systemized drawings list',
                link: './systemizeddrawingslist',
                index: 1,
                type: 'SystemizedDrawings',
                fileUploadedAction: new SystemizedDrawingsFileUploaded(),
                addLogAction: new SystemizedDrawingsAddStartedLog(),
            },
            // {
            //     label: 'cow list',
            //     link: './cowlist',
            //     index: 2,
            //     type: 'COW',
            //     fileUploadedAction: new COWFileUploaded(),
            //     addLogAction: new COWAddStartedLog(),
            // },
            {
                label: 'flange management',
                link: './flangemanagement',
                index: 3,
                type: 'FlangeManagement',
                fileUploadedAction: new FlangeManagementFileUploaded(),
                addLogAction: new FlangeManagementAddStartedLog(),
            },
            // {
            //     label: 'redline',
            //     link: './redline',
            //     index: 7,
            //     type: 'RedlineRegister',
            //     fileUploadedAction: new RedlineFileUploaded(),
            //     addLogAction: new RedlineAddStartedLog(),
            // }
        ];
    }
    ngOnInit() {
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);

        combineLatest([
            this.store.select((store) => store.masterSubsystemListState.isValidateButtonEnabled),
            // this.store.select((store) => store.cwpRegisterState.isValidateButtonEnabled),
            // this.store.select((store) => store.iwpListingState.isValidateButtonEnabled),
            // this.store.select((store) => store.rfoImportState.isValidateButtonEnabled),
            this.store.select((store) => store.systemizedDrawingsState.isValidateButtonEnabled),
            //this.store.select((store) => store.cowListState.isValidateButtonEnabled),
            this.store.select((store) => store.flangeManagementState.isValidateButtonEnabled),
            // this.store.select((store) => store.redlineState.isValidateButtonEnabled),
            this.activatedRoute$,
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => {
                setTimeout(() => {
                    this.activeNavLink = this.navLinks.find((n) => n.link === `./${data[data.length - 1]}`);
                    this.isValidateButtonDisabled = this.activeNavLink ? !data[this.activeNavLink.index] : true;
                });
            });

        combineLatest([
            this.store.select((store) => store.masterSubsystemListState.recordsForDeletion),
            // this.store.select((store) => store.cwpRegisterState.recordsForDeletion),
            // this.store.select((store) => store.iwpListingState.recordsForDeletion),
            // this.store.select((store) => store.rfoImportState.recordsForDeletion),
            this.store.select((store) => store.systemizedDrawingsState.recordsForDeletion),
            //this.store.select((store) => store.cowListState.recordsForDeletion),
            this.store.select((store) => store.flangeManagementState.recordsForDeletion),
            //this.store.select((store) => store.redlineState.recordsForDeletion),
            this.activatedRoute$,
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data: any[]) => {
                setTimeout(() => {
                    this.activeNavLink = this.navLinks.find((n) => n.link === `./${data[data.length - 1]}`);
                    this.recordsForDeletion = this.activeNavLink ? data[this.activeNavLink.index] : 0;
                });
            });
        
        combineLatest([
            this.store.select((store) => store.masterSubsystemListState.isAutomaticUploadInProgress),
            this.activatedRoute$,
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data: any) => {
                setTimeout(() => {
                    this.activeNavLink = this.navLinks.find((n) => n.link === `./${data[data.length - 1]}`);
                    if (this.activeNavLink?.type === 'Subsystems') {
                        this.isAutomaticUploadInProgress = data[0];
                    } else {
                        this.isAutomaticUploadInProgress = false;
                    }
                });
            });
    }

    fileChangeEvent(files: Array<File>) {
        const filesWrapper = new Array<File>();
        const formData = new FormData();
        filesWrapper.push(...files);
        const fileTypes: string[] = [];
        filesWrapper.forEach((file) => {
            const type = this.activeNavLink.type;
            fileTypes.push(type);
            formData.append(type, file, file.name);
        });

        _.uniq(fileTypes).forEach((t) => {
            this.store.dispatch(this.activeNavLink.fileUploadedAction);
            this.store.dispatch(this.activeNavLink.addLogAction);
        });

        this.store.dispatch(new ManualUploadFilesUploadRequest(formData));
    }

    validateDelta() {
        let warning = this.recordsForDeletion > 0 ?
        `<p><span class="warning"">Warning: </span> This action will delete ${this.recordsForDeletion} record(s).</p>` : '';
        
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    htmlText: `<p>Are you sure you want to import data and update existing records?</p>`
                    + warning,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.store.dispatch(this.validateActions[this.activeNavLink.index]);
                    this.isValidateButtonDisabled = true;
                    this.recordsForDeletion = 0
                }
            });
    }

    downloadLiveFile() {
        this.isLoading = true;
        let importType: ImportType;

        switch (this.activeNavLink.label) {
            case 'master subsystem list':
                importType = ImportType.MasterSubsystemList;
                break;
            // case 'cwp register':
            //     importType = ImportType.CWPRegister;
            //     break;
            // case 'iwp listing':
            //     importType = ImportType.IWPListing;
            //     break;
            // case 'rfo':
            //     importType = ImportType.RFO;
            //     break;
            case 'systemized drawings list':
                importType = ImportType.SystemizedDrawings;
                break;
            // case 'cow list':
            //     importType = ImportType.COW;
            //     break;
            case 'flange management':
                importType = ImportType.FlangeManagement;
                break;
            // case 'redline':
            //     importType = ImportType.Redline;
            //     break;
        }

        this.liveDataService.downloadLiveDataAsync(importType).subscribe((data: any) => {
            const blob = new Blob([data], {
                type: 'application/octet-stream',
            });
            saveAs(blob, `livedata_${ImportType[importType]}_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);

            this.isLoading = false;
        });
    }

    //Fix for bug 1939060 - mat-ink-bar not aligned to the tab
    @ViewChild(MatTabNav) public matTabNav: MatTabNav;
    public ngAfterViewChecked(): void {
        setTimeout(() => this.matTabNav._alignInkBarToSelectedTab(), 0);
    }
}
