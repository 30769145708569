import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    MasterSubsystemListActionTypes,
    MasterSubsystemListUploadLogSuccess,
    MasterSubsystemListUploadLogError,
    MasterSubsystemListSetStatusAndStartDate,
    MasterSubsystemListDeltaRequest,
    MasterSubsystemListDeltaSuccess,
    MasterSubsystemListDeltaError,
    MasterSubsystemListPatchDeltaRequest,
    MasterSubsystemListPatchDeltaSuccess,
    MasterSubsystemListPatchDeltaError,
    MasterSubsystemListValidateDeltaSuccess,
    MasterSubsystemListValidateDeltaError,
    MasterSubsystemListClearInProgressSpinner,
    MasterSubsystemListTemplateFileRequest,
    MasterSubsystemListTemplateFileSuccess,
    MasterSubsystemListTemplateFileError,
    MasterSubsystemListDeleteDataRequest,
    MasterSubsystemListDeleteDataSuccess,
    MasterSubsystemListDeleteDataError,
    MasterSubsystemListPatchDeleteRecordRequest,
    MasterSubsystemListPatchDeleteRecordSuccess,
    MasterSubsystemListPatchDeleteRecordError,
    MasterSubsystemListValidateButtonStateSuccess,
    MasterSubsystemListValidateButtonStateError,
    MasterSubsystemListDownloadOutputDataRequest,
    MasterSubsystemListDownloadOutputDataSuccess,
    MasterSubsystemListDownloadOutputDataError,
    MasterSubsystemListPatchAllDeltaRequest,
    MasterSubsystemListPatchAllDeltaSuccess,
    MasterSubsystemListPatchAllDeltaError,
    MasterSubsystemListPatchDeleteAllRecordsRequest,
    MasterSubsystemListPatchDeleteAllRecordsSuccess,
    MasterSubsystemListPatchDeleteAllRecordsError,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class MasterSubsystemListEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(MasterSubsystemListActionTypes.MasterSubsystemListUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['MSL']).pipe(
                map((uploadLogData) => new MasterSubsystemListUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new MasterSubsystemListUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<MasterSubsystemListUploadLogSuccess>(MasterSubsystemListActionTypes.MasterSubsystemListUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.masterSubsystemListState.uploadLogStatus),
            this.store.select((store) => store.masterSubsystemListState.uploadLogStartDate)
        ),
        filter(([{ payload }, status, startDate]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return logs[0] && (status !== logs[0].status || startDate !== logs[0].startDate);
        }),
        map(([{ payload }]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return new MasterSubsystemListSetStatusAndStartDate({
                status: logs[0].status,
                startDate: logs[0].startDate,
            });
        })
    );

    @Effect()
    invokeDeltaRequest$ = this.actions$.pipe(
        ofType(MasterSubsystemListActionTypes.MasterSubsystemListSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.masterSubsystemListState.uploadLogData)),
        filter(([, uploadLogs]) => {
            const logs = uploadLogs.filter((l) => l.status !== ImportStatuses.Info);
            return (logs[0] && logs[0].status === ImportStatuses.Finished) || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new MasterSubsystemListDeltaRequest(), new MasterSubsystemListDeleteDataRequest()])
    );

    @Effect()
    getDelta$ = this.actions$.pipe(
        ofType(MasterSubsystemListActionTypes.MasterSubsystemListDeltaRequest),
        withLatestFrom(this.store.select((store) => store.masterSubsystemListState.deltaFilter)),
        mergeMap(([, deltaFilter]) =>
            this.manualUploadsService.getMasterSubsystemListDelta(deltaFilter.page, deltaFilter.take).pipe(
                map((deltaData: any) => new MasterSubsystemListDeltaSuccess(deltaData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delta records. Please contact Program Administrator'
                    );
                    return of(new MasterSubsystemListDeltaError(error));
                })
            )
        )
    );

    @Effect()
    getDeleteRecords$ = this.actions$.pipe(
        ofType(MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataRequest),
        withLatestFrom(this.store.select((store) => store.masterSubsystemListState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getMasterSubsystemListDeleteRecords(deleteFilter.page, deleteFilter.take).pipe(
                map((deleteData: any) => new MasterSubsystemListDeleteDataSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete records. Please contact Program Administrator'
                    );
                    return of(new MasterSubsystemListDeleteDataError(error));
                })
            )
        )
    );

    @Effect()
    patchDelta$ = this.actions$.pipe(
        ofType<MasterSubsystemListPatchDeltaRequest>(
            MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaRequest
        ),
        mergeMap((action) =>
            this.manualUploadsService
                .patchMasterSubsystemListDelta(action.payload.subsystem, action.payload.deltaState)
                .pipe(
                    map(() => new MasterSubsystemListPatchDeltaSuccess()),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while ${
                                action.payload.deltaState ? 'accepting' : 'rejecting'
                            } the change. Please contact Program Administrator`
                        );
                        return of(new MasterSubsystemListPatchDeltaError(error));
                    })
                )
        )
    );

    @Effect()
    patchAllDelta$ = this.actions$.pipe(
        ofType<MasterSubsystemListPatchAllDeltaRequest>(
            MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaRequest
        ),
        mergeMap((action) =>
            this.manualUploadsService.patchMasterSubsystemListAllDelta(action.payload.deltaState).pipe(
                map(() => new MasterSubsystemListPatchAllDeltaSuccess({ deltaState: action.payload.deltaState })),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deltaState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new MasterSubsystemListPatchAllDeltaError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteRecord$ = this.actions$.pipe(
        ofType<MasterSubsystemListPatchDeleteRecordRequest>(
            MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordRequest
        ),
        mergeMap((action) =>
            this.manualUploadsService
                .patchMasterSubsystemListDeleteRecord(action.payload.subsystem, action.payload.deleteState)
                .pipe(
                    map(() => new MasterSubsystemListPatchDeleteRecordSuccess()),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while ${
                                action.payload.deleteState ? 'deleting' : 'restoring'
                            } the record. Please contact Program Administrator`
                        );
                        return of(new MasterSubsystemListPatchDeleteRecordError(error));
                    })
                )
        )
    );

    @Effect()
    patchDeleteAllRecords$ = this.actions$.pipe(
        ofType<MasterSubsystemListPatchDeleteAllRecordsRequest>(
            MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsRequest
        ),
        mergeMap((action) =>
            this.manualUploadsService.patchMasterSubsystemListDeleteAllRecords(action.payload.deleteState).pipe(
                map(
                    () =>
                        new MasterSubsystemListPatchDeleteAllRecordsSuccess({ deleteState: action.payload.deleteState })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'deleting' : 'restoring'
                        } the record. Please contact Program Administrator`
                    );
                    return of(new MasterSubsystemListPatchDeleteAllRecordsError(error));
                })
            )
        )
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateMasterSubsystemListDelta().pipe(
                map(() => new MasterSubsystemListValidateDeltaSuccess()),
                catchError((error) => {
                    if (error?.error === 'AutomaticUploadPipelineInProgress') {
                        this.toastService.Info(
                            'Cannot validate the data while automatic upload pipeline is in progress.'
                        );
                    } else {
                        this.toastService.Error(
                            'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                        );
                    }
                    return of(new MasterSubsystemListValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(MasterSubsystemListActionTypes.MasterSubsystemListDeltaRequest),
        withLatestFrom(this.store.select((store) => store.masterSubsystemListState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new MasterSubsystemListClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<MasterSubsystemListTemplateFileRequest>(
            MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileRequest
        ),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new MasterSubsystemListTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Master Subsystem List structure file. Please contact Program Administrator'
                    );
                    return of(new MasterSubsystemListTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<MasterSubsystemListTemplateFileSuccess>(
            MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileSuccess
        ),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'Master Subsystem List.xlsx');
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(
            MasterSubsystemListActionTypes.MasterSubsystemListDeltaSuccess,
            MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsSuccess,
            MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordSuccess,
            MasterSubsystemListActionTypes.MasterSubsystemListUploadLogSuccess
        ),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('Subsystem').pipe(
                map((response: any) => new MasterSubsystemListValidateButtonStateSuccess(response)),
                catchError((error) => of(new MasterSubsystemListValidateButtonStateError(error)))
            )
        )
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<MasterSubsystemListDownloadOutputDataRequest>(
            MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataRequest
        ),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map(
                    (blob) =>
                        new MasterSubsystemListDownloadOutputDataSuccess({ content: blob, fileName: action.payload })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new MasterSubsystemListDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<MasterSubsystemListDownloadOutputDataSuccess>(
            MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataSuccess
        ),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );
}
