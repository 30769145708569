import { IcicleState, IcicleFilter, ICICLE_RANGE_WEEKS, ICICLE_API_CALL_DATE_FORMAT } from './model';
import { IcicleActions, IcicleActionTypes } from './actions';
import { getCurrentWeekStartDate } from 'src/app/extensions';

const initialState: IcicleState = {
    isLoading: false,
    data: [],
    type: 'Forecast',
    filter: new IcicleFilter(),
    startDate: getCurrentWeekStartDate().add(ICICLE_RANGE_WEEKS, 'weeks').format(ICICLE_API_CALL_DATE_FORMAT),
};

export function reducer(state = initialState, action: IcicleActions) {
    switch (action.type) {
        case IcicleActionTypes.IcicleRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case IcicleActionTypes.IcicleRequestSuccess: {
            return {
                ...state,
                data: action.payload.data,
                type: action.payload.type,
                isLoading: false,
            };
        }
        case IcicleActionTypes.IcicleRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case IcicleActionTypes.IcicleFilterReset: {
            return {
                ...state,
                filter: new IcicleFilter(),
            };
        }
        case IcicleActionTypes.IcicleFilterPropertyUpdate: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                        ? [...action.payload.value]
                        : action.payload.value,
                },
            };
        }
        case IcicleActionTypes.IcicleWeekRangeSet: {
            return {
                ...state,
                startDate: action.payload.startDate,
            };
        }
        default:
            return state;
    }
}
