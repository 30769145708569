import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { of } from 'rxjs';
import { map, withLatestFrom, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { Zone } from 'src/app/enums';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { DcnRfiRedlineRegisterService } from 'src/app/services/api/webapi-services/dcn-rfi-redline-register.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from '../model';
import {
    DcnRfiRedlineRegisterActionTypes,
    DcnRfiRedlineRegisterAddCommentRequest,
    DcnRfiRedlineRegisterAddCommentSuccess,
    DcnRfiRedlineRegisterExportToExcelError,
    DcnRfiRedlineRegisterExportToExcelSuccess,
    DcnRfiRedlineRegistertAddCommentError,
    DcnRfiRegisterAddRedlineError,
    DcnRfiRegisterAddRedlineRequest,
    DcnRfiRegisterAddRedlineSuccess,
    DcnRfiRegisterAddWorkpackError,
    DcnRfiRegisterAddWorkpackRequest,
    DcnRfiRegisterAddWorkpackSuccess,
    DcnRfiRegisterDataRequest,
    DcnRfiRegisterDataRequestError,
    DcnRfiRegisterDataRequestSuccess,
    dcnRfiRegisterDeleteCommentError,
    dcnRfiRegisterDeleteCommentRequest,
    dcnRfiRegisterDeleteCommentSuccess,
    DcnRfiRegisterDeleteWorkpackError,
    DcnRfiRegisterDeleteWorkpackRequest,
    DcnRfiRegisterDeleteWorkpackSuccess,
    DcnRfiRegisterDetailsDataRequest,
    DcnRfiRegisterDetailsDataRequestError,
    DcnRfiRegisterDetailsDataRequestSuccess,
    DcnRfiRegisterUpdateRlmuStatusError,
    DcnRfiRegisterUpdateRlmuStatusRequest,
    DcnRfiRegisterUpdateRlmuStatusSuccess,
    DcnRfiRegisterUpdateWorkflowStatusError,
    DcnRfiRegisterUpdateWorkflowStatusRequest,
    DcnRfiRegisterUpdateWorkflowStatusSuccess,
    DcnRfiRegisterUpdateWorkpackError,
    DcnRfiRegisterUpdateWorkpackRequest,
    DcnRfiRegisterUpdateWorkpackSuccess,
    RedlineRegisterDataRequest,
    RedlineRegisterDataRequestError,
    RedlineRegisterDataRequestSuccess,
    RedlineRegisterDetailsDataRequest,
    RedlineRegisterDetailsDataRequestError,
    RedlineRegisterDetailsDataRequestSuccess,
} from './actions';
import {
    DcnRfiRegisterDetails,
    DcnRfiRegisterResultDto,
    RedlineRegisterDetails,
    RedlineRegisterResultDto,
} from './model';

@Injectable()
export class DcnRfiRedlineRegisterEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private dcnRfiRedlineRegisterService: DcnRfiRedlineRegisterService,
        private toastService: ToastService,
        private commentService: CommentService
    ) {}

    @Effect()
    getDcnRfiRegister$ = this.actions$.pipe(
        ofType<DcnRfiRegisterDataRequest>(DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDataRequest),
        withLatestFrom(this.store.select((store) => store.dcnRfiRedlineRegisterState?.dcnRfiRedlineRegisterFilter)),
        switchMap(([action, filter]) => {
            return this.dcnRfiRedlineRegisterService.searchDcnRfiRegister(filter).pipe(
                map((response: DcnRfiRegisterResultDto) => {
                    return new DcnRfiRegisterDataRequestSuccess(response);
                }),
                catchError((error) => of(new DcnRfiRegisterDataRequestError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onDcnRfiRegisterError = this.actions$.pipe(
        ofType<DcnRfiRegisterDataRequestError>(DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDataRequestError),
        map(() => {
            this.toastService.Error('Failed to fetch Dcn/Rfi register data. Please contact Program Administrator.');
        })
    );

    @Effect()
    getRedlineRegister$ = this.actions$.pipe(
        ofType<RedlineRegisterDataRequest>(DcnRfiRedlineRegisterActionTypes.RedlineRegisterDataRequest),
        withLatestFrom(this.store.select((store) => store.dcnRfiRedlineRegisterState?.dcnRfiRedlineRegisterFilter)),
        switchMap(([action, filter]) => {
            return this.dcnRfiRedlineRegisterService.searchRedlineRegister(filter).pipe(
                map((response: RedlineRegisterResultDto) => {
                    return new RedlineRegisterDataRequestSuccess(response);
                }),
                catchError((error) => of(new RedlineRegisterDataRequestError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onRedlineRegisterError = this.actions$.pipe(
        ofType<RedlineRegisterDataRequestError>(DcnRfiRedlineRegisterActionTypes.RedlineRegisterDataRequestError),
        map(() => {
            this.toastService.Error('Failed to fetch Redline register data. Please contact Program Administrator.');
        })
    );

    @Effect()
    getDcnRfiRegisterDetails$ = this.actions$.pipe(
        ofType<DcnRfiRegisterDetailsDataRequest>(DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDetailsDataRequest),
        switchMap((action) => {
            return this.dcnRfiRedlineRegisterService.getDcnRfiRegisterDetails(action.id).pipe(
                map((response: DcnRfiRegisterDetails) => {
                    return new DcnRfiRegisterDetailsDataRequestSuccess(response);
                }),
                catchError((error) => of(new DcnRfiRegisterDetailsDataRequestError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onDcnRfiRegisterDetailsError = this.actions$.pipe(
        ofType<DcnRfiRegisterDetailsDataRequestError>(
            DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDetailsDataRequestError
        ),
        map(() => {
            this.toastService.Error(
                'Failed to fetch Dcn/Rfi register details data. Please contact Program Administrator.'
            );
        })
    );

    @Effect()
    getRedlineRegisterDetails$ = this.actions$.pipe(
        ofType<RedlineRegisterDetailsDataRequest>(DcnRfiRedlineRegisterActionTypes.RedlineRegisterDetailsDataRequest),
        switchMap((action) => {
            return this.dcnRfiRedlineRegisterService.getRedlineRegisterDetails(action.id).pipe(
                map((response: RedlineRegisterDetails) => {
                    return new RedlineRegisterDetailsDataRequestSuccess(response);
                }),
                catchError((error) => of(new RedlineRegisterDetailsDataRequestError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onRedlineRegisterDetailsError = this.actions$.pipe(
        ofType<RedlineRegisterDetailsDataRequestError>(
            DcnRfiRedlineRegisterActionTypes.RedlineRegisterDetailsDataRequestError
        ),
        map(() => {
            this.toastService.Error(
                'Failed to fetch Redline register details data. Please contact Program Administrator.'
            );
        })
    );

    @Effect()
    addDetailComment$ = this.actions$.pipe(
        ofType<DcnRfiRedlineRegisterAddCommentRequest>(
            DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterAddCommentRequest
        ),
        mergeMap((payload) =>
            this.commentService
                .addCommentZone(payload.id.toString(), payload.description, payload.zone, payload.mentions)
                .pipe(
                    map((comment) => {
                        this.toastService.Success('Comment successfully added.');
                        return new DcnRfiRedlineRegisterAddCommentSuccess(
                            payload.id,
                            comment,
                            payload.zone
                        );
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding comment. Please contact Program Administrator.'
                        );
                        return of(new DcnRfiRedlineRegistertAddCommentError(error));
                    })
                )
        )
    );

    @Effect()
    addWorkpack$ = this.actions$.pipe(
        ofType<DcnRfiRegisterAddWorkpackRequest>(DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddWorkpackRequest),
        switchMap((action) => {
            return this.dcnRfiRedlineRegisterService.addWorkpack(action.workpack).pipe(
                map(() => {
                    return new DcnRfiRegisterAddWorkpackSuccess(action.workpack);
                }),
                catchError((error) => of(new DcnRfiRegisterAddWorkpackError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onAddWorkpackError = this.actions$.pipe(
        ofType<DcnRfiRegisterAddWorkpackError>(DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddWorkpackError),
        map(() => {
            this.toastService.Error('Error occurred while adding workpack. Please contact Program Administrator.');
        })
    );

    @Effect()
    addRedline$ = this.actions$.pipe(
        ofType<DcnRfiRegisterAddRedlineRequest>(DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddRedlineRequest),
        switchMap((action) => {
            return this.dcnRfiRedlineRegisterService.addRedline(action.redline).pipe(
                map(() => {
                    return new DcnRfiRegisterAddRedlineSuccess(action.redline);
                }),
                catchError((error) => of(new DcnRfiRegisterAddRedlineError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onAddRedlineError = this.actions$.pipe(
        ofType<DcnRfiRegisterAddRedlineError>(DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterAddRedlineError),
        map(() => {
            this.toastService.Error('Error occurred while adding redline. Please contact Program Administrator.');
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter)),
        mergeMap(([, filter]) => {
            const f = {
                ...filter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.dcnRfiRedlineRegisterService.generateExcel(f).pipe(
                map((excelData) => new DcnRfiRedlineRegisterExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new DcnRfiRedlineRegisterExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(DcnRfiRedlineRegisterActionTypes.DcnRfiRedlineRegisterExportToExcelSuccess),
        map((action: DcnRfiRedlineRegisterExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `DcnRfiRegisterRedline${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect()
    updateWorkflowStatus$ = this.actions$.pipe(
        ofType<DcnRfiRegisterUpdateWorkflowStatusRequest>(
            DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkflowStatusRequest
        ),
        switchMap((action) => {
            return this.dcnRfiRedlineRegisterService.updateWorkflowStatus(action.workflowStatus).pipe(
                map(() => {
                    return new DcnRfiRegisterUpdateWorkflowStatusSuccess(action.workflowStatus);
                }),
                catchError((error) => of(new DcnRfiRegisterUpdateWorkflowStatusError(error)))
            );
        })
    );

    @Effect({ dispatch: false })
    updateWorkflowStatusError = this.actions$.pipe(
        ofType<DcnRfiRegisterUpdateWorkflowStatusError>(
            DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkflowStatusError
        ),
        map(() => {
            this.toastService.Error(
                'Error occurred while updating workflow status. Please contact Program Administrator.'
            );
        })
    );

    @Effect()
    updateWorkpack$ = this.actions$.pipe(
        ofType<DcnRfiRegisterUpdateWorkpackRequest>(
            DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkpackRequest
        ),
        switchMap((action) => {
            return this.dcnRfiRedlineRegisterService.updateWorkpack(action.workpack).pipe(
                map(() => {
                    return new DcnRfiRegisterUpdateWorkpackSuccess(action.workpack);
                }),
                catchError((error) => of(new DcnRfiRegisterUpdateWorkpackError(error)))
            );
        })
    );

    @Effect({ dispatch: false })
    updateWorkpackError = this.actions$.pipe(
        ofType<DcnRfiRegisterUpdateWorkpackError>(DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateWorkpackError),
        map(() => {
            this.toastService.Error('Error occurred while updating workpack. Please contact Program Administrator.');
        })
    );

    @Effect()
    updateRlmuStatus$ = this.actions$.pipe(
        ofType<DcnRfiRegisterUpdateRlmuStatusRequest>(
            DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateRlmuStatusRequest
        ),
        switchMap((action) => {
            return this.dcnRfiRedlineRegisterService.updateRlmuStatus(action.redlineBaseDetails).pipe(
                map(() => {
                    return new DcnRfiRegisterUpdateRlmuStatusSuccess(action.redlineBaseDetails);
                }),
                catchError((error) => of(new DcnRfiRegisterUpdateRlmuStatusError(error)))
            );
        })
    );

    @Effect({ dispatch: false })
    updateRlmuStatusError = this.actions$.pipe(
        ofType<DcnRfiRegisterUpdateRlmuStatusError>(
            DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterUpdateRlmuStatusError
        ),
        map(() => {
            this.toastService.Error('Error occurred while updating rlmu status. Please contact Program Administrator.');
        })
    );

    @Effect()
    deleteWorkpack$ = this.actions$.pipe(
        ofType<DcnRfiRegisterDeleteWorkpackRequest>(
            DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDeleteWorkpackRequest
        ),
        switchMap((action) => {
            return this.dcnRfiRedlineRegisterService.deleteWorkpack(action.changeDocId, action.workpackNumber).pipe(
                map(() => {
                    return new DcnRfiRegisterDeleteWorkpackSuccess(action.changeDocId, action.workpackNumber);
                }),
                catchError((error) => of(new DcnRfiRegisterDeleteWorkpackError(error)))
            );
        })
    );

    @Effect({ dispatch: false })
    deleteWorkpackError = this.actions$.pipe(
        ofType<DcnRfiRegisterDeleteWorkpackError>(DcnRfiRedlineRegisterActionTypes.DcnRfiRegisterDeleteWorkpackError),
        map(() => {
            this.toastService.Error('Error occurred while deleting workpack. Please contact Program Administrator.');
        })
    );

    deleteComment$ = createEffect(
        () => this.actions$.pipe(
            ofType(dcnRfiRegisterDeleteCommentRequest),
            mergeMap((action) => this.commentService.deleteComment(action.id, action.scope).pipe(
                map(() => {

                    this.toastService.Success('Comment deleted successfully.');
                    return dcnRfiRegisterDeleteCommentSuccess(action);
                }),
                catchError(error => {
                    this.toastService.Error(
                        'Error occurred while deleting comment. Please contact Program Administrator.'
                    );
                    return of(dcnRfiRegisterDeleteCommentError(error));
                })
            ))
        )
    );
}
