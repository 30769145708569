<div class="monthly-change-raised-container">
    <h4>changes raised by type</h4>
    <div class="subtitle-container">
        <div class="options-container">
            <!-- <mat-form-field appearance="outline">
                <mat-label>Choose month for end range date</mat-label>
                <input matInput [formControl]="dateRangePickerControlFormatted" />
                <input
                    matInput
                    [matDatepicker]="dateRangePicker"
                    [max]="currentDate"
                    [formControl]="dateRangePickerControl"
                    hidden
                />
                <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                <mat-datepicker
                    #dateRangePicker
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event)"
                    (monthSelected)="chosenMonthHandler($event)"
                    disabled="false"
                ></mat-datepicker>
            </mat-form-field> -->
        </div>
    </div>
    <div class="chart-container">
        <div class="bar-chart-container">
            <ngx-charts-bar-vertical-stacked
                [results]="data"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
            >
            </ngx-charts-bar-vertical-stacked>
        </div>
    </div>
    <div *ngIf="isLoading$ | async" class="monthly-change-raised-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
