import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile, take, catchError } from 'rxjs/operators';
import { CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AddMilestonePopupComponent } from './add-popup/add-milestone-popup.component';
import { BaseComponent } from 'src/app/components/base.component';
import { ToastService } from 'src/app/services/shared/toast.service';
import { MilestoneDTO } from 'src/app/models/milestone-dto';
import { MilestoneService } from 'src/app/services/api/webapi-services/milestone.service';
import { WaypointService } from 'src/app/services/api/webapi-services/waypoint.service';
import { WaypointDTO } from 'src/app/models/waypoint-dto';
import { of } from 'rxjs';
import * as _ from 'lodash';
import { HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';

@Component({
    selector: 'app-milestone-maintenance',
    templateUrl: './milestone-maintenance.component.html',
    styleUrls: ['./milestone-maintenance.component.scss'],
})
export class MilestoneMaintenanceComponent extends BaseComponent implements OnInit {
    resultsLength = 0;
    pageSize = 10;
    sortBy: string = 'name';
    direction = OrderDirection.Asc;
    isLoading = false;
    displayedColumns = ['name', 'description', 'waypointName', 'edit', 'remove'];
    dataSource = new MatTableDataSource<MilestoneDTO>();
    milestonesOriginal: MilestoneDTO[] = [];
    @Input() waypoints: WaypointDTO[] = [];
    @Input() waypointRemoved: EventEmitter<WaypointDTO> = new EventEmitter();
    columnNames: CheckListColumn = null;
    columnDescriptions: CheckListColumn = null;
    columnWaypoints: CheckListColumn = null;
    columnFilterValueBeforeReset: CheckListColumn = null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private popupService: PopupService,
        private milestoneService: MilestoneService,
        private waypointService: WaypointService,
        private toastService: ToastService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.waypointService
            .get()
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error('Error occurred while getting waypoints. Please contact Program Administrator.');
                })
            )
            .subscribe((data) => (this.waypoints = data));

        this.getMilestones();

        this.waypointRemoved.pipe(takeWhile(() => this.isAlive === true)).subscribe((waypoint: WaypointDTO) => {
            this.milestonesOriginal = this.milestonesOriginal.filter((s) => s.waypointName !== waypoint.name);
            let data = this.dataSource.data.filter((s) => s.waypointName !== waypoint.name);
            this.setMilestoneTable(data);
        });
    }

    getMilestones() {
        this.isLoading = true;
        this.milestoneService
            .get()
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error('Error occurred while getting milestones. Please contact Program Administrator.');
                })
            )
            .subscribe(
                (data) => {
                    this.milestonesOriginal = data;
                    this.setMilestoneTable(data);
                    this.isLoading = false;
                },
                (error) => {
                    this.isLoading = false;
                    this.toastService.Error(error);
                }
            );
    }

    remove(element: MilestoneDTO) {
        const popupText = element.subsystemLinked
            ? `Milestone ${element.name} linked to existing Subsystem. Are you sure you want to delete?`
            : `Delete Milestone ${element.name}?`;
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: popupText,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.isLoading = true;
                    this.milestoneService
                        .remove(element.name)
                        .pipe(takeWhile(() => this.isAlive === true))
                        .subscribe(
                            () => {
                                var data = this.dataSource.data.filter((s) => s.name !== element.name);
                                this.milestonesOriginal = data;
                                this.setMilestoneTable(data);
                                this.isLoading = false;
                                this.toastService.Success(`Milestone ${element.name} removed.`);
                            },
                            () => {
                                this.isLoading = false;
                                this.toastService.Error(
                                    `Error occurred while deleting ${element.name}. Please contact Program Administrator.`
                                );
                            }
                        );
                }
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(
            new PopupSettings(AddMilestonePopupComponent, 450, 455, { waypoints: this.waypoints })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    const data = this.dataSource.data;
                    data.push(result.data);
                    this.milestonesOriginal = data;
                    this.setMilestoneTable(data);
                }
            });
    }

    enterEditMode(milestone: MilestoneDTO) {
        milestone.isInEditMode = !milestone.isInEditMode;
        milestone.prevDescription = milestone.description;
        milestone.prevWaypointName = milestone.waypointName;
    }

    save(milestone: MilestoneDTO) {
        milestone.isInEditMode = !milestone.isInEditMode;

        if (
            milestone.description !== milestone.prevDescription ||
            milestone.waypointName !== milestone.prevWaypointName
        ) {
            this.isLoading = true;
            this.milestoneService
                .update(milestone)
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe(
                    () => {
                        this.isLoading = false;
                        this.toastService.Success(`Milestone ${milestone.name} saved.`);
                    },
                    (error) => {
                        this.isLoading = false;
                        if (error.status === 409) {
                            this.toastService.Error(`Milestone entered ${milestone.name} already exists in the SCMT.`);
                        } else {
                            this.toastService.Error(
                                `Error occurred while updating ${milestone.name}. Please contact Program Administrator.`
                            );
                        }
                        this.rollbackChanges(milestone);
                    }
                );
        }
    }

    cancelEdit(milestone: MilestoneDTO) {
        milestone.isInEditMode = !milestone.isInEditMode;
        this.rollbackChanges(milestone);
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        this.paginator.pageIndex = newPage - 1;
        this.dataSource.paginator = this.paginator;
    }

    private filterElements(elements: MilestoneDTO[]) {
        let filteredElements = elements;
        if (this.columnNames && this.columnNames.items.length) {
            filteredElements = elements.filter((e) => this.columnNames.items.some((i) => i === e.name));
        }
        if (this.columnDescriptions && this.columnDescriptions.items.length) {
            filteredElements = elements.filter((e) => this.columnDescriptions.items.some((i) => i === e.description));
        }
        if (this.columnWaypoints && this.columnWaypoints.items.length) {
            filteredElements = elements.filter((e) => this.columnWaypoints.items.some((i) => i === e.waypointName))
        }
        return filteredElements;
    }

    private rollbackChanges(milestone: MilestoneDTO) {
        milestone.description = milestone.prevDescription;
        milestone.waypointName = milestone.prevWaypointName;
    }

    private setMilestoneTable(data: MilestoneDTO[]) {
        this.dataSource = new MatTableDataSource(data);
        this.resultsLength = this.dataSource.data.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    getValues = (search = '', take = 30, page = 0, column = 'number') => {
        let elements = this.filterElements(this.milestonesOriginal);
        if (elements.length) {
            let result = elements.map((s) => s[column]).sort();
            if (search !== '') {
                result = result.filter((s) => s && s.toLowerCase().indexOf(search.toLowerCase()) > -1);
            }
            let counts = _.countBy(result);
            let keys = _.keys(counts);
            return of(keys.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                column: columnName,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this[propertyName] = value;

                    let results = this.search();
                    this.setMilestoneTable(results);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                },
                resetColumnAction: () => {
                    this.columnFilterValueBeforeReset = this[propertyName];
                    this[propertyName] = null;
                },
                cancelAction: () => {
                    this[propertyName] = this.columnFilterValueBeforeReset;
                    this.columnFilterValueBeforeReset = null;
                }
            }, 400, 620)
        );
    }

    private search() {
        let results = this.milestonesOriginal;
        if (this.columnNames && this.columnNames.items.length) {
            results = results.filter((s) => this.columnNames.items.indexOf(s.name) > -1);
        }
        if (this.columnDescriptions && this.columnDescriptions.items.length) {
            results = results.filter((s) => this.columnDescriptions.items.indexOf(s.description) > -1);
        }
        if (this.columnWaypoints && this.columnWaypoints.items.length) {
            results = results.filter((s) => this.columnWaypoints.items.indexOf(s.waypointName) > -1);
        }
        return results;
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction = isAscendingSort ? 'asc' : 'desc';
            const matSort = this.dataSource.sort;
            const disableClear = false;

            matSort.sort({ id: null, start: direction, disableClear });
            matSort.sort({ id: columnName, start: direction, disableClear });

            this.dataSource.sort = matSort;
            this.direction = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.sortBy = columnName;
        } else {
            this.direction = OrderDirection.Asc;
            this.sortBy = 'name';
            const matSort = this.dataSource.sort;
            const disableClear = false;

            matSort.sort({ id: null, start: 'asc', disableClear });
            matSort.sort({ id: this.sortBy, start: 'asc', disableClear });
        }
    }
}
