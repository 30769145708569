import { Action } from '@ngrx/store';
import { RFODataPagination, RFOFilter } from './model';
import * as moment from 'moment';
import { UserDetail } from '../common.model';
import { CommentDetailDTO } from '../detailed-status/model';

export enum RFOActionTypes {
    RFOFilterRequest = '[RFO] Filter Request',
    RFOFilterSuccess = '[RFO] Filter Success',
    RFOFilterError = '[RFO] Filter Error',
    RFOFilterPropertyUpdate = '[RFO] Property Update',
    RFOFilterReset = '[RFO] Filter Reset',
    RFOExportToExcelRequest = '[RFO] Export to Excel Request',
    RFOExportToExcelSuccess = '[RFO] Export to Excel Success',
    RFOExportToExcelError = '[RFO] Export to Excel Error',
    RFOForecastDateUpdateRequest = '[RFO] RFSU Forecast Date Update Request',
    RFORFSUForecastDateUpdateSuccess = '[RFO] RFSU Forecast Date Update Success',
    RFORFSUForecastDateUpdateError = '[RFO] RFSU Forecast Date Update Error',
    RFOFreezeDateUpdateRequest = '[RFO] RFSU Freeze Date Update Request',
    RFOFreezeDateUpdateSuccess = '[RFO] RFSU Freeze Date Update Success',
    RFOFreezeUpdateError = '[RFO] RFSU Freeze Date Update Error',
    RFOAddCommentRequest = '[RFO] Add comment Request',
    RFOAddCommentSuccess = '[RFO] Add comment Success',
    RFOAddCommentError = '[RFO] Add comment Error',
    RFORemoveCommentRequest = '[RFO] Remove Comment Request',
    RFORemoveCommentSuccess = '[RFO] Remove Comment Success',
    RFORemoveCommentError = '[RFO] Remove Comment Error',
    RFOAddBulkCommentRequest = '[RFO] Add bulk comment Request',
    RFOAddBulkCommentSuccess = '[RFO] Add bulk comment Success',
    RFOAddBulkCommentError = '[RFO] Add bulk comment Error',
}

export class RFORemoveCommentRequest implements Action {
    readonly type = RFOActionTypes.RFORemoveCommentRequest;

    constructor(public payload: { id: number; lastComment: CommentDetailDTO; rfo: string }) {}
}

export class RFORemoveCommentSuccess implements Action {
    readonly type = RFOActionTypes.RFORemoveCommentSuccess;

    constructor(public payload: { id: number; lastComment: CommentDetailDTO; rfo: string }) {}
}

export class RFORemoveCommentError implements Action {
    readonly type = RFOActionTypes.RFORemoveCommentError;

    constructor(public payload: string) {}
}

export class RFOFilterRequest implements Action {
    readonly type = RFOActionTypes.RFOFilterRequest;

    constructor(public payload: RFOFilter = null) {}
}

export class RFOFilterSuccess implements Action {
    readonly type = RFOActionTypes.RFOFilterSuccess;

    constructor(public payload: RFODataPagination) {}
}

export class RFOFilterError implements Action {
    readonly type = RFOActionTypes.RFOFilterError;

    constructor(public payload: string) {}
}

export class RFOFilterPropertyUpdate implements Action {
    readonly type = RFOActionTypes.RFOFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class RFOFilterReset implements Action {
    readonly type = RFOActionTypes.RFOFilterReset;
}

export class RFOForecastDateUpdateRequest implements Action {
    readonly type = RFOActionTypes.RFOForecastDateUpdateRequest;

    constructor(public payload: { rfo: string; rfoForecastDate: moment.Moment }) {}
}

export class RFORFSUForecastDateUpdateSuccess implements Action {
    readonly type = RFOActionTypes.RFORFSUForecastDateUpdateSuccess;
}

export class RFORFSUForecastDateUpdateError implements Action {
    readonly type = RFOActionTypes.RFORFSUForecastDateUpdateError;

    constructor(public payload: string) {}
}
export class RFOFreezeDateUpdateRequest implements Action {
    readonly type = RFOActionTypes.RFOFreezeDateUpdateRequest;

    constructor(public payload: { rfo: string; rfoFreezeDate: moment.Moment }) {}
}

export class RFOFreezeDateUpdateSuccess implements Action {
    readonly type = RFOActionTypes.RFOFreezeDateUpdateSuccess;
}

export class RFOFreezeUpdateError implements Action {
    readonly type = RFOActionTypes.RFOFreezeUpdateError;

    constructor(public payload: string) {}
}

export class RFOAddCommentRequest implements Action {
    readonly type = RFOActionTypes.RFOAddCommentRequest;

    constructor(public payload: { entityId: string; description: string; mentions: UserDetail[] }) {}
}

export class RFOAddCommentSuccess implements Action {
    readonly type = RFOActionTypes.RFOAddCommentSuccess;

    constructor(public payload: { entityId: string; description: string; mentions: UserDetail[] }) {}
}

export class RFOAddCommentError implements Action {
    readonly type = RFOActionTypes.RFOAddCommentError;

    constructor(public payload: string) {}
}

export class RFOAddBulkCommentRequest implements Action {
    readonly type = RFOActionTypes.RFOAddBulkCommentRequest;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class RFOAddBulkCommentSuccess implements Action {
    readonly type = RFOActionTypes.RFOAddBulkCommentSuccess;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class RFOAddBulkCommentError implements Action {
    readonly type = RFOActionTypes.RFOAddBulkCommentError;

    constructor(public payload: string) {}
}

export class RFOExportToExcelRequest implements Action {
    readonly type = RFOActionTypes.RFOExportToExcelRequest;
}

export class RFOExportToExcelSuccess implements Action {
    readonly type = RFOActionTypes.RFOExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class RFOExportToExcelError implements Action {
    readonly type = RFOActionTypes.RFOExportToExcelError;

    constructor(public payload: string) {}
}

export type RFOActions =
    | RFOFilterRequest
    | RFOFilterSuccess
    | RFOFilterError
    | RFOFilterPropertyUpdate
    | RFOFilterReset
    | RFOForecastDateUpdateRequest
    | RFORFSUForecastDateUpdateSuccess
    | RFORFSUForecastDateUpdateError
    | RFOAddCommentRequest
    | RFOAddCommentSuccess
    | RFOAddCommentError
    | RFOAddBulkCommentRequest
    | RFOAddBulkCommentSuccess
    | RFOAddBulkCommentError
    | RFORemoveCommentRequest
    | RFORemoveCommentSuccess
    | RFORemoveCommentError
    | RFOExportToExcelRequest
    | RFOExportToExcelSuccess
    | RFOExportToExcelError
    | RFOFreezeDateUpdateRequest
    | RFOFreezeDateUpdateSuccess
    | RFOFreezeUpdateError;

