import { CommentDTO } from '../common.model';
import * as moment from 'moment';

export interface ChangeDocumentDetailsState {
    item: ChangeDocumentDetailsDTO;
    comments: CommentDTO[];
    isLoading: boolean;
    iwpLink: IWPLink;
}

export class ChangeDocumentDetailsDTO {
    id: number = null;
    number = '';
    title = '';
    designStatus = '';
    implementationStatus = '';
    revision = '';
    date: Date = null;
    projectTeamName = '';
    area = '';
    fieldImpact = '';
    discipline = '';
    impactedTags = '';
    reasonForChange = '';
    contractor = '';
    cwp = '';
    impactedDrawings = '';
    impactedSubsystems = '';
    notes = '';
    comments: CommentDTO[] = [];
    signOffs: SignOffStatusDTO[] = [];
    linkedIWPs: string[] = [];
    isIWPListComplete = false;
    issueDate: moment.Moment = null;
    remark = '';
    parentChangeDoc = '';
    affectedDeliverables = '';
    manualUserEntry = '';
    manualDateTimeEntry: Date = null;
    scopeOfWork = '';
    description = '';
    response = '';
    subsystemImpactedNA = false;
    attachments: CallOffAttachment[] = [];
    tqAttachments: TQAttachment[] = [];
    subsystems: string[];
    subsystemsExceptions: string[];
    changeDocTags = [];
    validationStatus = '';
    isFieldImplementationStatusComplete = false;
    qrCode = '';
    rfiImpact = '';
    rfiCategory = '';
    rfsuContractorToSS = '';
    testpackComlpetedSubsystems = '';
}

export class SignOffStatusDTO {
    signOffDate: moment.Moment = null;
    user = '';
    revision = '';
    subsystems = '';
    type: SignOffType = null;
    id: number;
    signOffId: number;
}

export enum SignOffType {
    Manual = 0,
    IWP = 1,
    BySourceData = 2,
}

export class IWP {
    id: number;
    number: number;
    changeDocId: string;
}

export class IWPLink {
    changeDocId: number;
    isListComplete: boolean;
    changeDocIwps: ChangeDocIWP[];
}

export class ChangeDocIWP {
    contractNo: string;
    iwpNumber: string;
    subsystems: string[];
}

export class CallOffAttachment {
    name: string;
    link: string;
}

export enum TechnicalQueryAttachmentType {
    Description = 1,
    Response = 2,
}

export class TQAttachment extends CallOffAttachment {
    technicalQueryAttachmentType: TechnicalQueryAttachmentType;
}
