
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    ExceptionsPriorityActionTypes,
    ExceptionsPriorityUploadLogSuccess,
    ExceptionsPriorityUploadLogError,
    ExceptionsPriorityValidateButtonStateSuccess,
    ExceptionsPriorityValidateButtonStateError,
    ExceptionsPriorityDownloadOutputDataSuccess,
    ExceptionsPriorityDownloadOutputDataError,
    ExceptionsPriorityDownloadOutputDataRequest,
    ExceptionsPriorityTemplateFileSuccess,
    ExceptionsPriorityTemplateFileRequest,
    ExceptionsPriorityTemplateFileError,
    ExceptionsPriorityClearInProgressSpinner,
    ExceptionsPriorityValidateDeleteSuccess,
    ExceptionsPriorityValidateDeleteError,
    ExceptionsPriorityPatchAllDeleteRequest,
    ExceptionsPriorityPatchAllDeleteSuccess,
    ExceptionsPriorityPatchAllDeleteError,
    ExceptionsPriorityPatchDeleteRequest,
    ExceptionsPriorityPatchDeleteSuccess,
    ExceptionsPriorityPatchDeleteError,
    ExceptionsPriorityDeleteSuccess,
    ExceptionsPriorityDeleteError,
    ExceptionsPriorityDeleteRequest,
    ExceptionsPrioritySetStatusAndStartDate,
} from './actions';

@Injectable()
export class ExceptionsPriorityEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(ExceptionsPriorityActionTypes.ExceptionsPriorityUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['ExceptionsPriority']).pipe(
                map((uploadLogData) => new ExceptionsPriorityUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new ExceptionsPriorityUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<ExceptionsPriorityUploadLogSuccess>(ExceptionsPriorityActionTypes.ExceptionsPriorityUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.exceptionsPriorityState.uploadLogStatus),
            this.store.select((store) => store.exceptionsPriorityState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new ExceptionsPrioritySetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    invokeDeleteRequest$ = this.actions$.pipe(
        ofType(ExceptionsPriorityActionTypes.ExceptionsPrioritySetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.exceptionsPriorityState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        map(() => new ExceptionsPriorityDeleteRequest())
    );

    @Effect()
    getDelete$ = this.actions$.pipe(
        ofType(ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.exceptionsPriorityState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getExceptionsPriorityDeleteRecords(deleteFilter).pipe(
                map((deleteData: any) => new ExceptionsPriorityDeleteSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete data records. Please contact Program Administrator'
                    );
                    return of(new ExceptionsPriorityDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteData$ = this.actions$.pipe(
        ofType<ExceptionsPriorityPatchDeleteRequest>(ExceptionsPriorityActionTypes.ExceptionsPriorityPatchDeleteRecordRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchExceptionsPriorityDeleteRecord(
                action.payload.exception, action.payload.deleteState
                ).pipe(
                map(() => new ExceptionsPriorityPatchDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ExceptionsPriorityPatchDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchAllDelete$ = this.actions$.pipe(
        ofType<ExceptionsPriorityPatchAllDeleteRequest>(ExceptionsPriorityActionTypes.ExceptionsPriorityPatchAllDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.exceptionsPriorityState.deleteFilter)),
        mergeMap(([action, deleteFilter]) =>
            this.manualUploadsService.patchAllExceptionsPriorityDeleteRecords(deleteFilter, action.payload.deleteState).pipe(
                map(() => new ExceptionsPriorityPatchAllDeleteSuccess({ deleteState: action.payload.deleteState })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ExceptionsPriorityPatchAllDeleteError(error));
                })
            )
        )
    );

    @Effect()
    validateDelete$ = this.actions$.pipe(
        ofType(ExceptionsPriorityActionTypes.ExceptionsPriorityValidateDataRequest),
        mergeMap(() =>
            this.manualUploadsService.validateExceptionsPriorityData().pipe(
                map(() => new ExceptionsPriorityValidateDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new ExceptionsPriorityValidateDeleteError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.exceptionsPriorityState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new ExceptionsPriorityClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<ExceptionsPriorityTemplateFileRequest>(ExceptionsPriorityActionTypes.ExceptionsPriorityTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new ExceptionsPriorityTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Exceptions Priority structure file. Please contact Program Administrator'
                    );
                    return of(new ExceptionsPriorityTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<ExceptionsPriorityTemplateFileSuccess>(ExceptionsPriorityActionTypes.ExceptionsPriorityTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'Exceptions Priority.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<ExceptionsPriorityDownloadOutputDataRequest>(ExceptionsPriorityActionTypes.ExceptionsPriorityDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new ExceptionsPriorityDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new ExceptionsPriorityDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<ExceptionsPriorityDownloadOutputDataSuccess>(ExceptionsPriorityActionTypes.ExceptionsPriorityDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteRecordsSuccess,
            ExceptionsPriorityActionTypes.ExceptionsPriorityPatchAllDeleteRecordsSuccess,
            ExceptionsPriorityActionTypes.ExceptionsPriorityPatchDeleteRecordSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('ExceptionsPriority').pipe(
                map((response: any) => new ExceptionsPriorityValidateButtonStateSuccess(response)),
                catchError((error) => of(new ExceptionsPriorityValidateButtonStateError(error)))
            )
        )
    );
}

