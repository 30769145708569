import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { SubsystemScope } from 'src/app/enums';
import { TraceHeatingStatusService } from 'src/app/services/api/webapi-services/reports/trace-heating-status.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Dictionary } from 'typescript-collections';
import { ApplicationState } from '../model';
import { TraceHeatingStatusActionTypes, TraceHeatingStatusAttachmentsUpdated, TraceHeatingStatusDataRequest, TraceHeatingStatusDataRequestError, TraceHeatingStatusDataRequestSuccess, TraceHeatingStatusDeleteAttachmentError, TraceHeatingStatusDeleteAttachmentRequest, TraceHeatingStatusDeleteAttachmentSuccess, TraceHeatingStatusDownloadAttachmentError, TraceHeatingStatusDownloadAttachmentRequest, TraceHeatingStatusDownloadAttachmentSuccess, TraceHeatingStatusExportToExcelError, TraceHeatingStatusExportToExcelSuccess, TraceHeatingStatusUploadAttachmentsError, TraceHeatingStatusUploadAttachmentsRequest, TraceHeatingStatusUploadAttachmentsSuccess } from './action';
import { THSAttachmentDto, TraceHeatingStatusResultDto } from './model';

@Injectable()
export class TraceHeatingStatusEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private traceHeatingStatusService: TraceHeatingStatusService,
        private toastService: ToastService,
    ) {}


    @Effect()
    getTraceHeatingStatus$ = this.actions$.pipe(
        ofType<TraceHeatingStatusDataRequest>(TraceHeatingStatusActionTypes.TraceHeatingStatusDataRequest),
        withLatestFrom(
            this.store.select((store) => store.traceHeatingStatusState?.traceHeatingStatusFilter)
        ),
        switchMap(([action, filter]) => {
            return this.traceHeatingStatusService.search(filter, action.printMode).pipe(
                map((response: TraceHeatingStatusResultDto) => {
                    return new TraceHeatingStatusDataRequestSuccess(response, action.printMode);
                }),
                catchError((error) => of(new TraceHeatingStatusDataRequestError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onTraceHeatingStatusDataError = this.actions$.pipe(
        ofType<TraceHeatingStatusDataRequestError>(TraceHeatingStatusActionTypes.TraceHeatingStatusDataRequestError),
        map(() => {
            this.toastService.Error('Failed to fetch Week Summary data. Please contact Program Administrator.');
        })
    );


    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(TraceHeatingStatusActionTypes.TraceHeatingStatusExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.traceHeatingStatusState.traceHeatingStatusFilter)),
        mergeMap(([, filter]) => {
            filter.timezoneOffset = new Date().getTimezoneOffset();
            return this.traceHeatingStatusService.generateExcel(filter).pipe(
                map((excelData) => new TraceHeatingStatusExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new TraceHeatingStatusExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(TraceHeatingStatusActionTypes.TraceHeatingStatusExportToExcelSuccess),
        map((action: TraceHeatingStatusExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `TaceHeatingStatus_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect()
    downloadAttachment$ = this.actions$.pipe(
        ofType<TraceHeatingStatusDownloadAttachmentRequest>(TraceHeatingStatusActionTypes.TraceHeatingStatusDownloadAttachmentRequest),
        switchMap((action) => {
            return this.traceHeatingStatusService.downloadAttachment(action.attachments[action.index].link, SubsystemScope.THS).pipe(
                map((file: Blob) => {
                    const blob = new Blob([file], {
                        type: 'application/octet-stream',
                    });
                    saveAs(blob, action.attachments[action.index].name);
                    return new TraceHeatingStatusDownloadAttachmentSuccess();
                }),
                catchError((error) => of(new TraceHeatingStatusDataRequestError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    downloadAttachmentError = this.actions$.pipe(
        ofType<TraceHeatingStatusDownloadAttachmentError>(TraceHeatingStatusActionTypes.TraceHeatingStatusDownloadAttachmentError),
        map(() => {
            this.toastService.Error('Error has occurred while downloading attachment. Please contact Program Administrator.');
        })
    );
}
