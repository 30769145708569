export interface SummaryStatus {
    number: string;
    title: string;
    status: string;
    exceptionNumber: string[];
}

export class FieldChangeDocumentSummaryDTO {
    subsystem: string;
    toImplement: SummaryStatus[];
    closed: SummaryStatus[];
    inEngineering: SummaryStatus[];
}

export interface FieldChangeDocumentSummaryState {
    data: FieldChangeDocumentSummaryDTO;
    isLoading: boolean;
}
