import {
    Component,
    Input,
    Output,
    EventEmitter,
    TemplateRef,
    ContentChild,
    HostListener,
} from '@angular/core';
import {
    BaseChartComponent,
    ViewDimensions,
    ColorHelper,
    calculateViewDimensions,
    id,
    getUniqueXDomainValues,
    getScaleType,
    ScaleType,
    LegendPosition,
} from '@swimlane/ngx-charts';
import { scaleLinear, scaleTime, scalePoint } from 'd3-scale';
import { curveLinear } from 'd3-shape';

//https://github.com/swimlane/ngx-charts/blob/master/projects/swimlane/ngx-charts/src/lib/line-chart/line-chart.component.ts

@Component({
    selector: 'app-icicle-chart',
    templateUrl: './icicle-chart.component.html',
    styleUrls: ['./icicle-chart.component.scss'],
})
export class IcicleChartComponent extends BaseChartComponent {
    @Input() legend;
    @Input() legendTitle: string = 'Legend';
    @Input() legendPosition: LegendPosition = LegendPosition.Right;
    @Input() xAxis;
    @Input() yAxis;
    @Input() showXAxisLabel;
    @Input() showYAxisLabel;
    @Input() xAxisLabel;
    @Input() yAxisLabel;
    @Input() autoScale;
    @Input() timeline;
    @Input() gradient: boolean;
    @Input() showGridLines: boolean = true;
    @Input() curve: any = curveLinear;
    @Input() activeEntries: any[] = [];
    @Input() schemeType: ScaleType;
    @Input() rangeFillOpacity: number;
    @Input() trimXAxisTicks: boolean = true;
    @Input() trimYAxisTicks: boolean = true;
    @Input() rotateXAxisTicks: boolean = true;
    @Input() maxXAxisTickLength: number = 16;
    @Input() maxYAxisTickLength: number = 16;
    @Input() xAxisTickFormatting: any;
    @Input() yAxisTickFormatting: any;
    @Input() xAxisTicks: any[];
    @Input() yAxisTicks: any[];
    @Input() roundDomains: boolean = false;
    @Input() tooltipDisabled: boolean = false;
    @Input() showRefLines: boolean = false;
    @Input() referenceLines: any;
    @Input() showRefLabels: boolean = true;
    @Input() xScaleMin: any;
    @Input() xScaleMax: any;
    @Input() yScaleMin: number;
    @Input() yScaleMax: number;

    @Output() activate: EventEmitter<any> = new EventEmitter();
    @Output() deactivate: EventEmitter<any> = new EventEmitter();

    @ContentChild('tooltipTemplate', { static: true }) tooltipTemplate: TemplateRef<any>;
    @ContentChild('seriesTooltipTemplate', { static: true }) seriesTooltipTemplate: TemplateRef<any>;

    dims: ViewDimensions;
    xSet: any;
    ySet: any;
    xDomain: any;
    yDomain: any;
    seriesDomain: any;
    yScale: any;
    xScale: any;
    colors: ColorHelper;
    scaleType: string;
    transform: string;
    clipPath: string;
    clipPathId: string;
    series: any;
    areaPath: any;
    margin = [10, 20, 10, 20];
    hoveredVertical: any; // the value of the x axis that is hovered over
    xAxisHeight: number = 0;
    yAxisWidth: number = 0;
    filteredDomain: any;
    legendOptions: any;
    hasRange: boolean; // whether the line has a min-max range around it
    timelineWidth: any;
    timelineHeight: number = 50;
    timelineXScale: any;
    timelineYScale: any;
    timelineXDomain: any;
    timelineTransform: any;
    timelinePadding: number = 10;

    update(): void {
        super.update();

        this.dims = calculateViewDimensions({
            width: this.width,
            height: this.height,
            margins: this.margin,
            showXAxis: this.xAxis,
            showYAxis: this.yAxis,
            xAxisHeight: this.xAxisHeight,
            yAxisWidth: this.yAxisWidth,
            showXLabel: this.showXAxisLabel,
            showYLabel: this.showYAxisLabel,
            showLegend: this.legend,
            legendType: this.schemeType,
            legendPosition: this.legendPosition,
        });

        if (this.timeline) {
            this.dims.height -= this.timelineHeight + this.margin[2] + this.timelinePadding;
        }

        this.xDomain = this.getXDomain();
        if (this.filteredDomain) {
            this.xDomain = this.filteredDomain;
        }

        this.yDomain = this.getYDomain();
        this.seriesDomain = this.getSeriesDomain();

        this.xScale = this.getXScale(this.xDomain, this.dims.width);
        this.yScale = this.getYScale(this.yDomain, this.dims.height);

        this.updateTimeline();

        this.setColors();
        this.legendOptions = this.getLegendOptions();

        this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;

        this.clipPathId = 'clip' + id().toString();
        this.clipPath = `url(#${this.clipPathId})`;
    }

    updateTimeline(): void {
        if (this.timeline) {
            this.timelineWidth = this.dims.width;
            this.timelineXDomain = this.getXDomain();
            this.timelineXScale = this.getXScale(this.timelineXDomain, this.timelineWidth);
            this.timelineYScale = this.getYScale(this.yDomain, this.timelineHeight);
            this.timelineTransform = `translate(${this.dims.xOffset}, ${-this.margin[2]})`;
        }
    }

    getXDomain(): any[] {
        let values = getUniqueXDomainValues(this.results);

        this.scaleType = getScaleType(values);
        let domain = [];

        if (this.scaleType === 'linear') {
            values = values.map((v) => Number(v));
        }

        let min;
        let max;
        if (this.scaleType === 'time' || this.scaleType === 'linear') {
            min = this.xScaleMin ? this.xScaleMin : Math.min(...values);

            max = this.xScaleMax ? this.xScaleMax : Math.max(...values);
        }

        if (this.scaleType === 'time') {
            domain = [new Date(min), new Date(max)];
            this.xSet = [...values].sort((a, b) => {
                const aDate = a.getTime();
                const bDate = b.getTime();
                if (aDate > bDate) return 1;
                if (bDate > aDate) return -1;
                return 0;
            });
        } else if (this.scaleType === 'linear') {
            domain = [min, max];
            // Use compare function to sort numbers numerically
            this.xSet = [...values].sort((a, b) => a - b);
        } else {
            domain = values;
            this.xSet = values;
        }

        return domain;
    }

    getYDomain(): any[] {
        const domain = [];
        for (const results of this.results) {
            for (const d of results.series) {
                if (domain.indexOf(d.value) < 0) {
                    domain.push(d.value);
                }
                if (d.min !== undefined) {
                    this.hasRange = true;
                    if (domain.indexOf(d.min) < 0) {
                        domain.push(d.min);
                    }
                }
                if (d.max !== undefined) {
                    this.hasRange = true;
                    if (domain.indexOf(d.max) < 0) {
                        domain.push(d.max);
                    }
                }
            }
        }

        this.ySet = [...this.yAxisTicks];

        const values = [...domain];
        if (!this.autoScale) {
            values.push(0);
        }

        const min = this.yScaleMin ? this.yScaleMin : Math.min(...values);

        const max = this.yScaleMax ? this.yScaleMax : Math.max(...values);

        return [min, max];
    }

    getSeriesDomain(): any[] {
        return this.results.map((d) => d.name);
    }

    getXScale(domain, width): any {
        let scale;

        if (this.scaleType === 'time') {
            scale = scaleTime().range([0, width]).domain(domain);
        } else if (this.scaleType === 'linear') {
            scale = scaleLinear().range([0, width]).domain(domain);

            if (this.roundDomains) {
                scale = scale.nice();
            }
        } else if (this.scaleType === 'ordinal') {
            scale = scalePoint().range([0, width]).padding(0.1).domain(domain);
        }

        return scale;
    }

    getYScale(domain, height): any {
        const scale = scaleLinear().range([0, height]).domain(domain);

        return this.roundDomains ? scale.nice() : scale;
    }

    updateDomain(domain): void {
        this.filteredDomain = domain;
        this.xDomain = this.filteredDomain;
        this.xScale = this.getXScale(this.xDomain, this.dims.width);
    }

    updateHoveredVertical(item): void {
        this.hoveredVertical = item.value;
        this.deactivateAll();
    }

    @HostListener('mouseleave')
    hideCircles(): void {
        this.hoveredVertical = null;
        this.deactivateAll();
    }

    onClick(data): void {
        this.select.emit(data);
    }

    trackBy(index, item): string {
        return item.name;
    }

    setColors(): void {
        let domain;
        if (this.schemeType === 'ordinal') {
            domain = this.seriesDomain;
        } else {
            domain = this.yDomain;
        }

        this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    }

    getLegendOptions() {
        const opts = {
            scaleType: this.schemeType,
            colors: undefined,
            domain: [],
            title: undefined,
            position: this.legendPosition,
        };
        if (opts.scaleType === 'ordinal') {
            opts.domain = this.seriesDomain;
            opts.colors = this.colors;
            opts.title = this.legendTitle;
        } else {
            opts.domain = this.yDomain;
            opts.colors = this.colors.scale;
        }
        return opts;
    }

    updateYAxisWidth({ width }): void {
        this.yAxisWidth = width;
        this.update();
    }

    updateXAxisHeight({ height }): void {
        this.xAxisHeight = height;
        this.update();
    }

    onActivate(item) {
        this.deactivateAll();

        const idx = this.activeEntries.findIndex((d) => {
            return d.name === item.name && d.value === item.value;
        });
        if (idx > -1) {
            return;
        }

        this.activeEntries = [item];
        this.activate.emit({ value: item, entries: this.activeEntries });
    }

    onDeactivate(item) {
        const idx = this.activeEntries.findIndex((d) => {
            return d.name === item.name && d.value === item.value;
        });

        this.activeEntries.splice(idx, 1);
        this.activeEntries = [...this.activeEntries];

        this.deactivate.emit({ value: item, entries: this.activeEntries });
    }

    deactivateAll() {
        this.activeEntries = [...this.activeEntries];
        for (const entry of this.activeEntries) {
            this.deactivate.emit({ value: entry, entries: [] });
        }
        this.activeEntries = [];
    }
}
