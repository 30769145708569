<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add project team mapping</span></p>
    <div>
        <form [formGroup]="addProjectTeamMappingForm">
            <mat-form-field class="container300">
                <mat-label>SCMT</mat-label>
                <mat-select formControlName="projectTeamName">
                    <mat-option *ngFor="let s of projectTeams" [value]="s">{{ s }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="container300">
                <mat-label>Source File "Data Owner"</mat-label>
                <textarea formControlName="sourceFileDataOwner" matInput name="sourceFileDataOwner"></textarea>
            </mat-form-field>
            <div class="row">
                <button mat-button type="button" class="sct-button sct-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!addProjectTeamMappingForm.valid"
                    class="sct-button sct-button-light"
                    (click)="onConfirm()"
                >
                    add mapping
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>