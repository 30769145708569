

import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { CITRPartialProgressDeleteFilter, CITRPartialProgressDeletePagination, CITRPartialProgressState } from './model';
import { CITRPartialProgressActions, CITRPartialProgressActionTypes } from './actions';

const initialState: CITRPartialProgressState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new CITRPartialProgressDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new CITRPartialProgressDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: CITRPartialProgressActions): CITRPartialProgressState {
    switch (action.type) {
        case CITRPartialProgressActionTypes.CITRPartialProgressUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressPatchAllDeleteRecordsRequest:
        case CITRPartialProgressActionTypes.CITRPartialProgressDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressDeleteRecordsSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressValidateDataError:
        case CITRPartialProgressActionTypes.CITRPartialProgressPatchAllDeleteRecordsError:
        case CITRPartialProgressActionTypes.CITRPartialProgressPatchDeleteRecordError:
        case CITRPartialProgressActionTypes.CITRPartialProgressPatchDeleteRecordSuccess:
        case CITRPartialProgressActionTypes.CITRPartialProgressDeleteRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressDeleteFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        [action.payload.key]: Array.isArray(state.deleteFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressDeleteFilterReset: {
            const newFilter = new CITRPartialProgressDeleteFilter();
            return {
                ...state,
                deleteFilter: { ...newFilter },
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressPatchDeleteRecordRequest: {
            let itemIndex = state.deleteData.items.findIndex((d) => d.barcode === action.payload.barcode);
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                deleteState: action.payload.deleteState,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressPatchAllDeleteRecordsSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item) => ({ ...item, deleteState: action.payload.deleteState })),
                },
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressValidateDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressValidateDataSuccess: {
            return {
                ...state,
                deleteData: new CITRPartialProgressDeletePagination(),
                isDeleteDataLoading: false,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new CITRPartialProgressDeletePagination(),
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressDownloadOutputDataRequest:
        case CITRPartialProgressActionTypes.CITRPartialProgressTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressTemplateFileError:
        case CITRPartialProgressActionTypes.CITRPartialProgressDownloadOutputDataSuccess:
        case CITRPartialProgressActionTypes.CITRPartialProgressDownloadOutputDataError:
        case CITRPartialProgressActionTypes.CITRPartialProgressTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case CITRPartialProgressActionTypes.CITRPartialProgressAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'CITRPartialProgress';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

