import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { ScheduleActivityDeleteFilter, ScheduleActivityDeletePagination, ScheduleActivityState } from './model';
import { ScheduleActivityActions, ScheduleActivityActionTypes } from './actions';

const initialState: ScheduleActivityState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new ScheduleActivityDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new ScheduleActivityDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: ScheduleActivityActions): ScheduleActivityState {
    switch (action.type) {
        case ScheduleActivityActionTypes.ScheduleActivityUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityPatchAllDeleteRecordsRequest:
        case ScheduleActivityActionTypes.ScheduleActivityDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityDeleteRecordsSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityValidateDataError:
        case ScheduleActivityActionTypes.ScheduleActivityPatchAllDeleteRecordsError:
        case ScheduleActivityActionTypes.ScheduleActivityPatchDeleteRecordError:
        case ScheduleActivityActionTypes.ScheduleActivityPatchDeleteRecordSuccess:
        case ScheduleActivityActionTypes.ScheduleActivityDeleteRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivitySetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityDeleteFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        [action.payload.key]: Array.isArray(state.deleteFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case ScheduleActivityActionTypes.ScheduleActivityDeleteFilterReset: {
            const newFilter = new ScheduleActivityDeleteFilter();
            return {
                ...state,
                deleteFilter: { ...newFilter },
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityPatchDeleteRecordRequest: {
            var itemIndex = state.deleteData.items.findIndex((d) => d.activityId === action.payload.activityId);
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                deleteState: action.payload.deleteState,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityPatchAllDeleteRecordsSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item) => ({ ...item, deleteState: action.payload.deleteState })),
                },
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityValidateDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityValidateDataSuccess: {
            return {
                ...state,
                deleteData: new ScheduleActivityDeletePagination(),
                isDeleteDataLoading: false,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new ScheduleActivityDeletePagination(),
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityDownloadOutputDataRequest:
        case ScheduleActivityActionTypes.ScheduleActivityTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityTemplateFileError:
        case ScheduleActivityActionTypes.ScheduleActivityDownloadOutputDataSuccess:
        case ScheduleActivityActionTypes.ScheduleActivityDownloadOutputDataError:
        case ScheduleActivityActionTypes.ScheduleActivityTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case ScheduleActivityActionTypes.ScheduleActivityAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'ScheduleActivity';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}
