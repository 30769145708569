<main class="main">
    <div class="main-container">
        <h4>modify project teams</h4>
        <table mat-table [dataSource]="projectTeamNames$ | async">
            <ng-container matColumnDef="projectTeamName">
                <th mat-header-cell disableClear *matHeaderCellDef>Project Team</th>
                <td mat-cell *matCellDef="let element">{{ element }}</td>
            </ng-container>
            <ng-container matColumnDef="remove">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="remove(element)" title="remove">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-toolbar>
            <span>Add new Project Team</span>
            <button mat-icon-button (click)="add()" title="add">
                <mat-icon svgIcon="add"></mat-icon>
            </button>
        </mat-toolbar>
    </div>
</main>

<app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
