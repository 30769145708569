import { createReducer, on } from '@ngrx/store';
import { ExcelExportState } from './model';
import * as ExcelExportActions from './actions';

const initialState: ExcelExportState = {
    exportEvents: [],
    anyUnprocessedEvents: false,
    isLoading: false,
    connected: true,
};

export const excelExportReducer = createReducer(
    initialState, 
    on(ExcelExportActions.getRecentExportEventsRequest, (state) => ({
        ...state,
        isLoading: true,
    })),
    on(ExcelExportActions.getRecentExportEventsSuccess, (state, action) => {
        let anyUnprocessedEvents = action.payload.some(x => x.status === 'New');
        return {
            ...state,
            isLoading: false,
            exportEvents: action.payload,
            anyUnprocessedEvents,
        }
    }),
    on(ExcelExportActions.getRecentExportEventsError, (state) => ({
        ...state,
        isLoading: false,
        exportEvents: [],
        anyUnprocessedEvents: false,
    })),
    on(ExcelExportActions.exportEventUpdated, (state, action) => {
        let exportEvents = [];
        state.exportEvents.forEach((exportEvent) => {
            if (exportEvent.id == action.exportEvent.id) {
                exportEvents.push(action.exportEvent);
            } else {
                exportEvents.push(exportEvent);
            }
        });
        let anyUnprocessedEvents = exportEvents.some(x => x.status === 'New');
        return {
            ...state,
            exportEvents,
            anyUnprocessedEvents,
        }
    }),
    on(ExcelExportActions.detailedStatusExportToExcelRequest, (state) => ({
            ...state,
            isLoading: true,
    })),
    on(ExcelExportActions.detailedStatusExportToExcelSuccess, (state, action) => {
        let exportEvents = [action.payload, ...state.exportEvents];
        return {
            ...state,
            isLoading: false,
            exportEvents,
            anyUnprocessedEvents: true
        };
    }),
    on(ExcelExportActions.detailedStatusExportToExcelError, (state) => ({
            ...state,
            isLoading: false,
    })),
    on(ExcelExportActions.connectionSet, (state, action) => ({
            ...state,
            connected: action.payload,
    }))
);
