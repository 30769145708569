import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LoopStatusDTO, LoopStatusFilter, ShowHideColumns } from 'src/app/store/loop-status/model';
import { FormService } from 'src/app/services/shared/form.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { select, Store } from '@ngrx/store';
import {
    LoopStatusFilterPropertyUpdate,
    LoopStatusFilterRequest,
    LoopStatusFilterReset,
    LoopStatusExportToExcelRequest,
    LoopStatusAddCommentRequest,
    LoopStatusUpdateRequest,
    LoopStatusAddBulkCommentRequest,
    LoopStatusRemoveCommentRequest,
} from 'src/app/store/loop-status/actions';
import { takeWhile, take } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { OrderDirection, CheckListColumn, CalendarColumn, UserDetail } from 'src/app/store/common.model';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { CommentPopupComponent } from 'src/app/modules/shared/components/comment-popup/comment-popup.component';
import { Constants } from 'src/app/constants';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { MatSelectChange } from '@angular/material/select';
import { Config } from 'src/app/models/config';
import { combineLatest } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { CommentsHistoryComponent } from '../comments-history/comments-history.component';
import { CommentDetailDTO } from 'src/app/store/detailed-status/model';

@Component({
    selector: 'app-loop-status',
    templateUrl: './loop-status.component.html',
    styleUrls: ['./loop-status.component.scss'],
})
export class LoopStatusComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    isLoading = false;
    data: LoopStatusDTO[] = [];
    resultsLength = 0;
    pageSize = 25;
    loopStatusGridData$ = this.store.select((state) => state.loopStatusState.dataPagination);
    loopStatusGridLoader$ = this.store.select((state) => state.loopStatusState.isLoading);
    loopStatusFilter$ = this.store.select((state) => state.loopStatusState.filter);
    sortBy$ = this.store.select((state) => state.loopStatusState.filter.sortBy);
    sortDirection$ = this.store.select((state) => state.loopStatusState.filter.direction);
    showHideColumns = new ShowHideColumns();
    columnGoocms: CheckListColumn = null;
    columnAreas: CheckListColumn = null;
    columnSubsystems: CheckListColumn = null;
    columnLoops: CheckListColumn = null;
    columnTagNumbers: CheckListColumn = null;
    columnActivityIds: CheckListColumn = null;
    columnQVDNumbers: CheckListColumn = null;
    columnQVDDescriptions: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    columnFolderRequests: CheckListColumn = null;
    columnMCPlan: CalendarColumn = null;
    columnMCForecast: CalendarColumn = null;
    columnMCActual: CalendarColumn = null;
    columnActivityStartDate: CalendarColumn = null;
    columnFolderStatuses: CheckListColumn = null;
    columnFolderStatusDate: CalendarColumn = null;
    columnFolderAssignments: CheckListColumn = null;
    columnFolderAssignmentDate: CalendarColumn = null;
    columnEngineerStatuses: CheckListColumn = null;
    columnEngineerStatusDate: CalendarColumn = null;
    columnConstraintClasses: CheckListColumn = null;
    columnResponsibleParties: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnLoopFolderConstraints: CheckListColumn = null;
    sortBy = '';
    direction: OrderDirection = OrderDirection.Desc;
    isReadOnly = false;
    displayedColumns = [
        'loop',
        'subsystem',
        'tagNumber',
        'activityId',
        'activityStartDate',
        'qvdNumber',
        'qvdDescription',
        'barcode',
        'loopFolderRequest',
        'area',
        'mcPlan',
        'mcForecast',
        'mcActual',
        'folderStatus',
        'folderStatusDate',
        'loopFolderConstraint',
        'comment',
    ];
    folderStatuses: string[] = [];
    folderAssignments: string[] = [];
    engineerStatuses: string[] = [];
    responsibleParties: string[] = [];
    constraintClasses: string[] = [];
    loopFolderConstraints: string[] = [];
    configKeys = Constants.Config;
    config: Config[] = [];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    isLoopCordinator = false;
    isLoopEngineer = false;
    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private route: ActivatedRoute,
        private configService: ConfigurationService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private commentService: CommentService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );

        this.filterForm = this.formSvc.createSimpleForm(new LoopStatusFilter());
        
    }

    ngOnInit() {
        this.populateDropdownOptions();
        let isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.isLoopCordinator = this.roleService.isInRole(Constants.applicableGroups.LoopCoordinator) || isAdmin;
        this.isLoopEngineer = this.roleService.isInRole(Constants.applicableGroups.LoopEngineer) || isAdmin;
        this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            if (paramMap.get('loop')) {
                this.resetFilters();
                let loopFilter = new CheckListColumn();
                loopFilter.items = [decodeURIComponent(paramMap.get('loop'))];
                this.columnLoops = loopFilter;
                this.filterForm.controls.columnLoops.setValue(loopFilter);
                this.paginator.pageIndex = 0;
                this.store.dispatch(
                    new LoopStatusFilterPropertyUpdate({
                        key: 'page',
                        value: 0,
                    })
                );
                this.store.dispatch(new LoopStatusFilterRequest(this.filterForm.value));
            } else if (this.resultsLength < this.pageSize) {
                this.store.dispatch(new LoopStatusFilterRequest());
            }
        });
        this.isReadOnly = this.roleService.isReadOnly();
        this.loopStatusGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items.map((item) => ({ ...item }));
            this.resultsLength = data.totalCount;
        });

        this.loopStatusFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.paginator.pageIndex = filter.page;
            this.showHideColumns = filter.showHideColumns;
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => {
            this.sortBy = sortBy;
        });

        this.sortDirection$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => {
            this.direction = direction;
        });

        this.loopStatusGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });
        this.registerColumnHeaderFilters();
    }

    private populateDropdownOptions() {
        this.configService
            .getConfigData()
            .pipe(take(1))
            .subscribe((configData) => {
                this.config = configData;
                this.folderStatuses = configData
                    .find((s) => s.key === Constants.Config.loopFolderStatusKey)
                    .value.split(',')
                    .map((n) => n.trim());
                this.folderAssignments = configData
                    .find((s) => s.key === Constants.Config.loopFolderAssignmentKey)
                    .value.split(',')
                    .map((n) => n.trim());
                this.engineerStatuses = configData
                    .find((s) => s.key === Constants.Config.engineerStatusKey)
                    .value.split(',')
                    .map((n) => n.trim());
                this.constraintClasses = configData
                    .find((s) => s.key === Constants.Config.constraintClassKey)
                    .value.split(',')
                    .map((n) => n.trim());
                this.responsibleParties = configData
                    .find((s) => s.key === Constants.Config.responsiblePartyKey)
                    .value.split(',')
                    .map((n) => n.trim());
                this.loopFolderConstraints = configData
                    .find((s) => s.key === Constants.Config.loopFolderConstraintKey)
                    .value.split(',')
                    .map((n) => n.trim());
            });
    }

    updateLoop(change: MatSelectChange, loopName: string, tagNumber: string, tagType: string, 
        key: string, /*activiyStartDate:string, mcActual:string,*/objectKey?: string,element?: any ,loopfolderRequest? :string) {
             
        this.store.dispatch(
            new LoopStatusUpdateRequest({
                loopName: loopName,
                tagNumber: tagNumber,
                tagType: tagType,
                value: change.value,
                updateKey: key,
                default: this.config
                    .find((s) => s.key === key)
                    .value.split(',')
                    .map((n) => n.trim())[0],
                objectKey,
                loopfolderRequest :element.loopFolderRequest,
                //objectkey2 : element.folderStatus,
                // activiyStartDate : element.activityStartDate,
                // mcActual : element.mcActual,
            })
        );
    }

    openCommentPopup(element: LoopStatusDTO, event: any) {
        const comment = element.comment || '';
        if (this.isReadOnly) return;

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: element.mentions,
                url: `loopstatus?loop=${element.loop}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new LoopStatusAddBulkCommentRequest({
                                description: value,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new LoopStatusAddCommentRequest({
                                loopName: element.loop,
                                tagNumber: element.tagNumber,
                                tagType: element.tagType,
                                description: value,
                                mentions,
                            })
                        );
                    }
                },
            })
        );

        event.stopPropagation();
    }

    openHistoryPopup(element: LoopStatusDTO) {
        if (element.comment === null) {
            return;
        } else {
            this.isLoading = true;
            this.commentService
                .getLoopComments(element.loop, element.tagNumber, element.tagType)
                .subscribe((data) => {
                    this.isLoading = false;
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            CommentsHistoryComponent,
                            null,
                            null,
                            {
                                commentHist: data,
                                action: (id: number) => {
                                    data = data.filter((s) => s.id !== id);
                                    this.store.dispatch(
                                       new LoopStatusRemoveCommentRequest({
                                            id,
                                            loopName: element.loop,
                                            tagNumber: element.tagNumber,
                                            tagType: element.tagType,
                                            lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                        })
                                    );
                                },
                            },
                            200,
                            200
                        )
                    );
                });
        }
    }

    private getLatestFilterData(): LoopStatusFilter {
        let filter: LoopStatusFilter;
        this.store.pipe(select((x) => x.loopStatusState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getLoops = (search = '', take = 30, page = 0) =>
        this.lookupService.searchLoopsWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getTagNumbers = (search = '', take = 30, page = 0) =>
        this.lookupService.searchTagNumbersWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getActivityIds = (search = '', take = 30, page = 0) =>
        this.lookupService.searchActivityIdsWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getQVDNumbers = (search = '', take = 30, page = 0) =>
        this.lookupService.searchQVDNumbersWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getQVDDescriptions = (search = '', take = 30, page = 0) =>
        this.lookupService.searchQVDDescriptionsWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getFolderRequests = (search = '', take = 30, page = 0) =>
        this.lookupService.searchFolderRequestsWithLoopStatusFilter(search, take, page, this.getLatestFilterData());
    getBarcodes = (search = '', take = 30, page = 0) =>
       this.lookupService.searchBarcodesWithLoopStatusFilter (search, take, page , this.getLatestFilterData());

    getAreas = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreasWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getGoocms = (search = '', take = 30, page = 0) =>
        this.lookupService.searchGoocmsWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getComments = (search = '', take = 30, page = 0) =>
        this.lookupService.searchCommentsWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getSubsystems = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemsWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getFolderStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchFolderStatusesWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getLoopFolderConstraints = (search = '', take = 30, page = 0) =>
        this.lookupService.searchLoopFolderConstraintsWithLoopStatusFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );

    getFolderAssignments = (search = '', take = 30, page = 0) =>
        this.lookupService.searchFolderAssignmentsWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getEngineerStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchEngineerStatusesWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getConstraintClasses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchConstraintClassesWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    getResponsibleParties = (search = '', take = 30, page = 0) =>
        this.lookupService.searchResponsiblePartiesWithLoopStatusFilter(search, take, page, this.getLatestFilterData());

    resetFilters() {
        this.store.dispatch(new LoopStatusFilterReset());
        this.filterForm.setValue(new LoopStatusFilter());
    }

    exportToExcel() {
        this.store.dispatch(new LoopStatusExportToExcelRequest());
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
        this.store.dispatch(
            new LoopStatusFilterPropertyUpdate({
                key: propertyName,
                value: [],
            })
        );
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (key !== 'sortBy' && key !== 'direction') {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new LoopStatusFilterPropertyUpdate({ key, value }));
            }
        }
    }

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    search() {
        this.updateFilterByFormProperties();
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new LoopStatusFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(
            new LoopStatusFilterPropertyUpdate({
                key: 'take',
                value: this.pageSize,
            })
        );
        this.store.dispatch(new LoopStatusFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new LoopStatusFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new LoopStatusFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new LoopStatusFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    private registerColumnHeaderFilters() {
        combineLatest([
            this.store.select((state) => state.loopStatusState.filter.columnAreas),
            this.store.select((state) => state.loopStatusState.filter.columnConstraintClasses),
            this.store.select((state) => state.loopStatusState.filter.columnEngineerStatusDate),
            this.store.select((state) => state.loopStatusState.filter.columnEngineerStatuses),
            this.store.select((state) => state.loopStatusState.filter.columnFolderAssignmentDate),
            this.store.select((state) => state.loopStatusState.filter.columnFolderAssignments),
            this.store.select((state) => state.loopStatusState.filter.columnFolderStatusDate),
            this.store.select((state) => state.loopStatusState.filter.columnFolderStatuses),
            this.store.select((state) => state.loopStatusState.filter.columnGoocms),
            this.store.select((state) => state.loopStatusState.filter.columnLoops),
            this.store.select((state) => state.loopStatusState.filter.columnMCActual),
            this.store.select((state) => state.loopStatusState.filter.columnMCForecast),
            this.store.select((state) => state.loopStatusState.filter.columnMCPlan),
            this.store.select((state) => state.loopStatusState.filter.columnResponsibleParties),
            this.store.select((state) => state.loopStatusState.filter.columnSubsystems),
            this.store.select((state) => state.loopStatusState.filter.columnComments),
            this.store.select((state) => state.loopStatusState.filter.columnLoopFolderConstraints),
            this.store.select((state) => state.loopStatusState.filter.columnTagNumbers),
            this.store.select((state) => state.loopStatusState.filter.columnActivityIds),
            this.store.select((state) => state.loopStatusState.filter.columnQVDNumbers),
            this.store.select((state) => state.loopStatusState.filter.columnQVDDescriptions),
            this.store.select((state) => state.loopStatusState.filter.columnBarcode),
            this.store.select((state) => state.loopStatusState.filter.columnFolderRequests),
            this.store.select((state) => state.loopStatusState.filter.columnActivityStartDate),
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([
                    areas,
                    contstraintClass,
                    engineerStatusDate,
                    engineerStatus,
                    folderAssignmentDate,
                    folderAssignment,
                    folderStatusDate,
                    folderStatus,
                    goocms,
                    loops,
                    mcActual,
                    mcForecast,
                    mcPlan,
                    responsibleParty,
                    subsystem,
                    comments,
                    loopFolderConstraints,
                    tagNumbers,
                    activityIds,
                    qvdNumbers,
                    qvdDescriptions,
                    barcode,
                    folderRequests,
                    activityStartDate
                ]) => {
                    this.columnAreas = areas as CheckListColumn;
                    this.columnConstraintClasses = contstraintClass as CheckListColumn;
                    this.columnEngineerStatusDate = engineerStatusDate as CalendarColumn;
                    this.columnEngineerStatuses = engineerStatus as CheckListColumn;
                    this.columnFolderAssignmentDate = folderAssignmentDate as CalendarColumn;
                    this.columnFolderAssignments = folderAssignment as CheckListColumn;
                    this.columnFolderStatusDate = folderStatusDate as CalendarColumn;
                    this.columnFolderStatuses = folderStatus as CheckListColumn;
                    this.columnGoocms = goocms as CheckListColumn;
                    this.columnLoops = loops as CheckListColumn;
                    this.columnMCActual = mcActual as CalendarColumn;
                    this.columnMCForecast = mcForecast as CalendarColumn;
                    this.columnMCPlan = mcPlan as CalendarColumn;
                    this.columnResponsibleParties = responsibleParty as CheckListColumn;
                    this.columnSubsystems = subsystem as CheckListColumn;
                    this.columnComments = comments as CheckListColumn;
                    this.columnLoopFolderConstraints = loopFolderConstraints as CheckListColumn;
                    this.columnTagNumbers = tagNumbers as CheckListColumn;
                    this.columnActivityIds = activityIds as CheckListColumn; 
                    this.columnQVDNumbers = qvdNumbers as CheckListColumn; 
                    this.columnQVDDescriptions = qvdDescriptions as CheckListColumn; 
                    this.columnBarcode = barcode as CheckListColumn;
                    this.columnFolderRequests = folderRequests as CheckListColumn; 
                    this.columnActivityStartDate = activityStartDate as CalendarColumn; 
                }
            );
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                excludeBlanks: excludeBlanks,
                onlyBlanks: onlyBlanks,
                isSortingOff: false,
                showCounts: showCounts,
                showInputSearch: showInputSearch,
                showBlankOptions: showBlankOptions,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new LoopStatusFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new LoopStatusFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn,
        isSortingOff = false,
        fireSearch = true
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: isSortingOff,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    if (fireSearch) {
                        this.search();
                    }
                },
            },
            345,
            580)
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new LoopStatusFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new LoopStatusFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'loop', direction: OrderDirection.Asc },
                })
            );
        }
    }

    resetColumns() {
        this.showHideColumns = new ShowHideColumns();
    }

    reverseValue(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };
        this.store.dispatch(
            new LoopStatusFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showHideColumns,
            })
        );
    }
}
