import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { DisciplineMappingDTO } from 'src/app/models/discipline-mapping-dto';
import { PopupSettings } from 'src/app/models/popup-settings';
import { DisciplineMappingService } from 'src/app/services/api/webapi-services/discipline-mapping-service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AddDisciplineMappingComponent } from './add-discipline-mapping/add-discipline-mapping.component';

@Component({
  selector: 'app-discipline-mapping',
  templateUrl: './discipline-mapping.component.html',
  styleUrls: ['./discipline-mapping.component.scss']
})
export class DisciplineMappingComponent extends BaseComponent implements OnInit {
  @Input() uploadType: string;

  displayedColumns = ['disciplineName', 'sourceFileDiscipline', 'remove'];
  dataSource = new MatTableDataSource<DisciplineMappingDTO>();
  isLoading: boolean;

  constructor(private disciplineMappingService: DisciplineMappingService,
    private popupService: PopupService,
    private toastService: ToastService) {
    super();

  }

  ngOnInit(): void {
    this.disciplineMappingService.get(this.uploadType)
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((data) => this.dataSource = new MatTableDataSource(data));
  }


  add() {
    const dialogRef = this.popupService.openPopup(
      new PopupSettings(AddDisciplineMappingComponent, 450, 430, {
        uploadType: this.uploadType,
      })
    );

    dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((result) => {
        if (result && result.success) {
          const data = this.dataSource.data;
          data.push(result.data);
          this.dataSource.data = data;
        }
      });
  }

  remove(element: DisciplineMappingDTO) {
    this.popupService
      .openPopup(
        new ConfirmDialogPopupSettings({
          title: 'Confirm action',
          text: `Are you sure you want to delete ${element.sourceFileDiscipline} mapping?`,
        })
      )
      .afterClosed()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((answer) => {
        if (answer) {
          this.isLoading = true;
          this.disciplineMappingService
            .remove(element.id)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
              () => {
                let data = this.dataSource.data.filter((s) => s.id !== element.id);
                this.dataSource = new MatTableDataSource(data);
                this.isLoading = false;
              },
              () => {
                this.isLoading = false;
                this.toastService.Error(
                  `Error occurred while deleting ${element.sourceFileDiscipline} mapping. Please contact Program Administrator.`
                );
              }
            );
        }
      });
  }

}
