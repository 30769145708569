import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { ToastService } from "src/app/services/shared/toast.service";
import {
    COWActionTypes,
    COWUploadLogSuccess,
    COWUploadLogError,
    COWSetStatusAndStartDate,
    COWValidateButtonStateRequest,
    COWValidateButtonStateSuccess,
    COWValidateButtonStateError,
    COWClearInProgressSpinner,
    COWDownloadOutputDataRequest,
    COWDownloadOutputDataSuccess,
    COWDownloadOutputDataError,
    COWTemplateFileRequest,
    COWTemplateFileSuccess,
    COWTemplateFileError,
    COWValidateDeltaSuccess,
    COWValidateDeltaError,
}
    from "./actions";
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import { ImportStatuses } from 'src/app/models/import-statuses';

@Injectable()
export class COWListEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) { }

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(COWActionTypes.COWUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['COW']).pipe(
                map((uploadLogData) => new COWUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new COWUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<COWUploadLogSuccess>(COWActionTypes.COWUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.cowListState.uploadLogStatus),
            this.store.select((store) => store.cowListState.uploadLogStartDate)
        ),
        filter(([{ payload }, status, startDate]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return logs[0] && (status !== logs[0].status || startDate !== logs[0].startDate);
        }),
        map(([{ payload }]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return new COWSetStatusAndStartDate({ status: logs[0].status, startDate: logs[0].startDate });
        })
    );
    
    @Effect()
    invokeDeltaRequest$ = this.actions$.pipe(
        ofType(COWActionTypes.COWSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.cowListState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        switchMap(() => [new COWValidateButtonStateRequest(), new COWClearInProgressSpinner()])
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(COWActionTypes.COWValidateButtonStateRequest),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('COW').pipe(
                map((response: any) => new COWValidateButtonStateSuccess(response)),
                catchError((error) => of(new COWValidateButtonStateError(error)))
            )
        )
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<COWDownloadOutputDataRequest>(COWActionTypes.COWDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new COWDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new COWDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<COWDownloadOutputDataSuccess>(COWActionTypes.COWDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<COWTemplateFileRequest>(COWActionTypes.COWTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new COWTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading COW List structure file. Please contact Program Administrator'
                    );
                    return of(new COWTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<COWTemplateFileSuccess>(COWActionTypes.COWTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'CarryOverWorkList.xlsx');
        })
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(COWActionTypes.COWValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateCOWDelta().pipe(
                map(() => new COWValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new COWValidateDeltaError(error));
                })
            )
        )
    );
}