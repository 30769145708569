<main class="main">
    <a class="navigator" (click)="goToPreviousPage()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">{{ changeTypeTitle }} details</div>
        <div class="buttons-container flex">
            <button
                *ngIf="(((data.designStatus === changeDocStatuses.toImplement || data.designStatus === changeDocStatuses.noPhysicalWork) && (data.validationStatus === 'completed' || data.validationStatus === 'test pack requested') && canSignOff) || isAdmin) && !alreadySignedOff"
                type="button"
                class="sct-button sct-button-light"
                (click)="signOff()"
            >
                sign-off field implementation
            </button>
        </div>
    </div>
    <div class="manually-added" *ngIf="data.manualUserEntry">
        Manually added by {{ data.manualUserEntry }} on {{ data.manualDateTimeEntry | date: 'd/MMM/y' }}
    </div>
    <div class="change-document-details-container">
        <mat-form-field class="container300 margin-right20">
            <input
                (click)="goToEb(data.number)"
                class="eb-link"
                matInput
                placeholder="Number"
                [value]="data.number"
                readonly
            />
        </mat-form-field>
        <mat-form-field class="container170 margin-right20">
            <input matInput placeholder="Revision" [value]="data.revision" readonly />
        </mat-form-field>
        <mat-form-field class="container170 margin-right20">
            <input matInput placeholder="Date" [value]="data.date | date: 'd/MMM/y'" readonly />
        </mat-form-field>
        <mat-form-field class="container300 margin-right20">
            <input matInput placeholder="Discipline" [value]="data.discipline" readonly />
        </mat-form-field>
        <mat-form-field class="container100pct">
            <mat-label>Title</mat-label>
            <textarea
                matInput
                [value]="data.title"
                data-title="Title"
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                readonly
            ></textarea>
        </mat-form-field>
        <mat-form-field class="container140 margin-right20">
            <input matInput placeholder="Project Team" [value]="data.projectTeamName" readonly />
        </mat-form-field>
        <mat-form-field class="container300 margin-right20">
            <input matInput placeholder="Contractor" [value]="data.contractor" readonly />
        </mat-form-field>
        <mat-form-field class="container140 margin-right20">
            <input matInput placeholder="Field Impact" [value]="data.fieldImpact" readonly />
        </mat-form-field>
        <mat-form-field appearance="none" class="container140 margin-right20">
            <mat-label>Change Validation Status</mat-label>
            <input
                class="validation-status"
                [ngClass]="getValidationClassName(data.validationStatus)"
                matInput
                placeholder="Change Validation Status"
                [value]="data.validationStatus"
                readonly
            />
        </mat-form-field>
        <mat-form-field appearance="none" class="container170">
            <mat-label>Design Status</mat-label>
            <input class="status" matInput placeholder="Design Status" [value]="data.designStatus" readonly />
        </mat-form-field>
        <mat-form-field appearance="none" class="container170">
            <mat-label>Implementation Status</mat-label>
            <input
                [ngClass]="{ status: data.implementationStatus !== '' }"
                matInput
                placeholder="Implementation Status"
                [value]="data.implementationStatus"
                readonly
            />
        </mat-form-field>
        <div class="textarea-elements">
            <mat-form-field class="container620 margin-right20">
                <textarea
                    class="small-initial-height"
                    matInput
                    [value]="data.impactedTags === null ? '' : (data.impactedTags | breakLine: ', ')"
                    placeholder="Tags Impacted"
                    matTextareaAutosize
                    matAutosizeMinRows="5"
                    readonly
                ></textarea>
            </mat-form-field>
            <mat-form-field class="container620">
                <textarea
                    class="small-initial-height"
                    matInput
                    [value]="data.impactedDrawings === null ? '' : data.impactedDrawings"
                    placeholder="Drawings Impacted"
                    matTextareaAutosize
                    matAutosizeMinRows="5"
                    readonly
                ></textarea>
            </mat-form-field>
        </div>
        <div class="textarea-elements">
            <mat-form-field class="container620 margin-right20">
                <textarea
                    class="small-initial-height"
                    matInput
                    [value]="data.impactedSubsystems === null ? '' : data.impactedSubsystems"
                    placeholder="Subsystems Impacted"
                    matTextareaAutosize
                    matAutosizeMinRows="5"
                    readonly
                ></textarea>
            </mat-form-field>
            <mat-form-field class="container620">
                <textarea
                    class="small-initial-height"
                    matInput
                    [value]="data.notes === null ? '' : data.notes"
                    placeholder="Notes"
                    matTextareaAutosize
                    matAutosizeMinRows="5"
                    readonly
                ></textarea>
            </mat-form-field>
        </div>
        <div class="textarea-elements">
            <mat-form-field class="container620 margin-right20">
                <textarea
                    class="small-initial-height"
                    matInput
                    [value]="data.linkedIWPs.join(', ')"
                    [placeholder]="linkedIWPsPlaceholder"
                    matTextareaAutosize
                    matAutosizeMinRows="5"
                    readonly
                ></textarea>
            </mat-form-field>            
            <mat-form-field *ngIf="type === ChangeType.RFI" class="container620">
                <textarea
                    class="small-initial-height"
                    matInput
                    [value]="data.rfiCategory === null ? '' : data.rfiCategory"
                    placeholder="RFI Category"
                    matTextareaAutosize
                    matAutosizeMinRows="5"
                    readonly
                ></textarea>
            </mat-form-field>
        </div>
        <div class="textarea-elements">
            <mat-form-field *ngIf="type === ChangeType.RFI" class="container620 margin-right20">
                <textarea
                    class="small-initial-height"
                    matInput
                    [value]="data.rfiImpact === null ? '' : data.rfiImpact"
                    placeholder="RFI Impact"
                    matTextareaAutosize
                    matAutosizeMinRows="5"
                    readonly
                ></textarea>
            </mat-form-field>
        </div>
        <div class="sign-off" *ngIf="manualSignOffs && manualSignOffs.length > 0">
            <div *ngFor="let so of manualSignOffs">
                <span *ngIf="so.type === 2">
                    Revision <b>{{ so.revision }}</b> Field implementation complete based on source data at
                    <b>{{ so.signOffDate | date: 'MMMM  dd yyyy, h:mm:ss a' }}</b>
                </span>
                <span *ngIf="so.type === 0">
                    Revision <b>{{ so.revision }}</b> Field implementation signed off by <b>{{ so.user }}</b> on
                    <b>{{ so.signOffDate | date: 'MMMM  dd yyyy, h:mm:ss a' }}</b>
                    <span *ngIf="so.subsystems">
                        for <b><span [innerHTML]="so.subsystems | noBreakingDash | replaceChar: ','"></span></b
                    ></span>
                    <mat-icon
                        class="hover-pointer delete-icon"
                        *ngIf="isAdmin"
                        svgIcon="del-icon"
                        (click)="deleteSignOff(so)"
                    ></mat-icon>
                </span>
            </div>
        </div>
        <table mat-table matSort [dataSource]="rfsuContractorToSSList" *ngIf="rfsuContractorToSSList.length > 0" style="margin-top: 20px;">
            <ng-container matColumnDef="ss">
                <th mat-header-cell disableClear *matHeaderCellDef>Subsystem</th>
                <td mat-cell *matCellDef="let element">{{ element.ss }}</td>
            </ng-container>
            <ng-container matColumnDef="rfsu">
                <th mat-header-cell disableClear *matHeaderCellDef>RFSU Contractor</th>
                <td mat-cell *matCellDef="let element">{{ element.rfsu }}</td>
            </ng-container>
            <ng-container matColumnDef="rfo">
                <th mat-header-cell disableClear *matHeaderCellDef>RFO</th>
                <td mat-cell *matCellDef="let element">{{ element.rfo }}</td>
            </ng-container>
            <ng-container matColumnDef="rfsuforecast">
                <th mat-header-cell disableClear *matHeaderCellDef>RFSU Forecast</th>
                <td mat-cell *matCellDef="let element">{{ element.rfsuForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="rfoforecast">
                <th mat-header-cell disableClear *matHeaderCellDef>RFO Forecast</th>
                <td mat-cell *matCellDef="let element">{{ element.rfoForecast | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="signedSubsystem">
                <th mat-header-cell disableClear *matHeaderCellDef>Signed Subsystem</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.signedSubsystem== 'Yes'">
                        <mat-icon svgIcon="completed"></mat-icon>
                    </div>
                    <div *ngIf="element.signedSubsystem== 'No'">
                        <mat-icon svgIcon="inactive"></mat-icon>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="subsystemsExceptions">
                <th mat-header-cell disableClear *matHeaderCellDef>Exceptions</th>
                <td mat-cell *matCellDef="let element">{{ element.subsystemsExceptions | breakLine: ';' }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="rfsuToSSColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: rfsuToSSColumns"></tr>
        </table>
    </div>

    <div class="calloff-container" *ngIf="type === ChangeType.CallOff || type === ChangeType.SCCallOff">
        <div class="scope-of-work-editor-container">
            <div>
                Scope of work
            </div>
            <div class="separator-10px">
                <div class="jodit-container description-disabled">
                    <div [innerHTML]="data.scopeOfWork | keepHtml"></div>
                </div>
            </div>
            <div>
                <br />
                Attachments
                <mat-list>
                    <mat-list-item *ngFor="let attachment of data.attachments">
                        <a (click)="downloadAttachment(attachment)">{{ attachment.name }}</a>
                        <div class="flex-align-right">
                            <button class="download-icon" (click)="downloadAttachment(attachment)">
                                <svg
                                    width="18px"
                                    height="20px"
                                    viewBox="0 0 16 17"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                    <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                                    <title>download</title>
                                    <desc>Created with sketchtool.</desc>
                                    <g
                                        id="Page-1"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <g
                                            transform="translate(-1259.000000, -1440.000000)"
                                            stroke="#0066B2"
                                            stroke-width="2"
                                        >
                                            <g
                                                id="Buttons/Primary-Copy"
                                                transform="translate(1256.000000, 1438.000000)"
                                            >
                                                <path d="M4,18 L18,18" id="Line-3"></path>
                                                <path
                                                    d="M5.5,8.5 L16.5,8.5"
                                                    id="Line-3-Copy"
                                                    transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                                ></path>
                                                <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>

    <div class="tq-container" *ngIf="type === ChangeType.TQ">
        <div class="scope-of-work-editor-container">
            <div>
                Description
            </div>
            <div class="separator-10px">
                <div class="jodit-container description-disabled">
                    <div [innerHTML]="data.description | keepHtml"></div>
                </div>
            </div>
            <div>
                <br />
                Attachments
                <mat-list>
                    <mat-list-item
                        *ngFor="let attachment of data.tqAttachments | attachments: attachmentTypes.Description"
                    >
                        <a (click)="downloadAttachment(attachment)">{{ attachment.name }}</a>
                        <div class="flex-align-right">
                            <button class="download-icon" (click)="downloadAttachment(attachment)">
                                <svg
                                    width="18px"
                                    height="20px"
                                    viewBox="0 0 16 17"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                    <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                                    <title>DB9A0693-F198-4BA2-9F22-DB074699D58C</title>
                                    <desc>Created with sketchtool.</desc>
                                    <g
                                        id="Page-1"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <g
                                            transform="translate(-1259.000000, -1440.000000)"
                                            stroke="#0066B2"
                                            stroke-width="2"
                                        >
                                            <g
                                                id="Buttons/Primary-Copy"
                                                transform="translate(1256.000000, 1438.000000)"
                                            >
                                                <path d="M4,18 L18,18" id="Line-3"></path>
                                                <path
                                                    d="M5.5,8.5 L16.5,8.5"
                                                    id="Line-3-Copy"
                                                    transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                                ></path>
                                                <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
            <div>
                Response
            </div>
            <div class="separator-10px">
                <div class="jodit-container description-disabled">
                    <div [innerHTML]="data.response | keepHtml"></div>
                </div>
            </div>
            <div>
                <br />
                Response Attachments
                <mat-list>
                    <mat-list-item
                        *ngFor="let attachment of data.tqAttachments | attachments: attachmentTypes.Response"
                    >
                        <a (click)="downloadAttachment(attachment)">{{ attachment.name }}</a>
                        <div class="flex-align-right">
                            <button class="download-icon" (click)="downloadAttachment(attachment)">
                                <svg
                                    width="18px"
                                    height="20px"
                                    viewBox="0 0 16 17"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                    <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                                    <title>download</title>
                                    <desc>Created with sketchtool.</desc>
                                    <g
                                        id="Page-1"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <g
                                            transform="translate(-1259.000000, -1440.000000)"
                                            stroke="#0066B2"
                                            stroke-width="2"
                                        >
                                            <g
                                                id="Buttons/Primary-Copy"
                                                transform="translate(1256.000000, 1438.000000)"
                                            >
                                                <path d="M4,18 L18,18" id="Line-3"></path>
                                                <path
                                                    d="M5.5,8.5 L16.5,8.5"
                                                    id="Line-3-Copy"
                                                    transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                                ></path>
                                                <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>

    <div class="comments-container">
        <button
            *ngIf="!isReadOnly"
            type="button"
            class="sct-button sct-button-light"
            (click)="openCommentPopup($event)"
        >
            Add comment
        </button>
        <table mat-table matSort [dataSource]="comments.reverse()">
            <ng-container matColumnDef="comment">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Comment</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.description | formatMentions: element.mentions"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell disableClear *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell disableClear *matHeaderCellDef mat-sort-header>Date</th>
                <td mat-cell *matCellDef="let element">{{ element.date | date: 'MMMM  dd yyyy, h:mm:ss a' }}</td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell disableClear *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon
                        class="hover-pointer"
                        *ngIf="canRemoveComment(element)"
                        svgIcon="del-icon"
                        (click)="removeComment(element)"
                    ></mat-icon>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <app-loading-indicator *ngIf="isLoading || isUserContractLoading"></app-loading-indicator>
</main>
