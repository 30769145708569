<div class="results-panel results-container">
  <div class="results-panel-container">
    <div class="results-panel-header-container">
      <div class="results-panel-header-title-row">
        <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
        <div class="results-header-actions">
            <button
                mat-button
                [matMenuTriggerFor]="columnsCustomizer"
                class="results-columns-customizer"
            >
                <mat-icon svgIcon="edit"></mat-icon>
                customize columns
            </button>
            <mat-menu #columnsCustomizer class="columns-customizer">
                <div class="columns-customizer-list">
                    <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                        [checked]="showHideColumns.dcnRfiNumber"
                        (change)="reverseValue('dcnRfiNumber')"
                        (click)="$event.stopPropagation()"
                        >DCN/RFI Number
                    </mat-checkbox>
                    <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                      [checked]="showHideColumns.dcnRfiDiscipline"
                      (change)="reverseValue('dcnRfiDiscipline')"
                      (click)="$event.stopPropagation()"
                      >Discipline
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.dcnRfiTitle"
                    (change)="reverseValue('dcnRfiTitle')"
                    (click)="$event.stopPropagation()"
                    >DCN/RFI Title
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.dcnRfiSubsystem"
                    (change)="reverseValue('dcnRfiSubsystem')"
                    (click)="$event.stopPropagation()"
                    >Subsystem
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.dcnRfiEngineeringStatus"
                    (change)="reverseValue('dcnRfiEngineeringStatus')"
                    (click)="$event.stopPropagation()"
                    >Engineering Status
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.dcnRfiWorkflowStatus"
                    (change)="reverseValue('dcnRfiWorkflowStatus')"
                    (click)="$event.stopPropagation()"
                    >DCN/RFI Workflow Status
                  </mat-checkbox>

                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.walkdownActual"
                    (change)="reverseValue('walkdownActual')"
                    (click)="$event.stopPropagation()"
                    >Walkdown Actual
                  </mat-checkbox>


                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.mcActual"
                    (change)="reverseValue('mcActual')"
                    (click)="$event.stopPropagation()"
                    >MC Actual
                  </mat-checkbox>

                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.walkdownForecast"
                    (change)="reverseValue('walkdownForecast')"
                    (click)="$event.stopPropagation()"
                    >Walkdown Forecast
                  </mat-checkbox>

                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.rfsuPlan"
                    (change)="reverseValue('rfsuPlan')"
                    (click)="$event.stopPropagation()"
                    >RFSU Plan
                  </mat-checkbox>

                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.implementationStatus"
                    (change)="reverseValue('implementationStatus')"
                    (click)="$event.stopPropagation()"
                    >Implementation Status
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                  [checked]="showHideColumns.rlmuToGo"
                  (change)="reverseValue('rlmuToGo')"
                  (click)="$event.stopPropagation()"
                  >RLMU To Go
                </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Dcnrfi"
                    [checked]="showHideColumns.comments"
                    (change)="reverseValue('comments')"
                    (click)="$event.stopPropagation()"
                    >Comments
                  </mat-checkbox>

                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.redlineNumber"
                    (change)="reverseValue('redlineNumber')"
                    (click)="$event.stopPropagation()"
                    >Document Number
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.redlineTitle"
                    (change)="reverseValue('redlineTitle')"
                    (click)="$event.stopPropagation()"
                    >Document Title
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.revision"
                    (change)="reverseValue('revision')"
                    (click)="$event.stopPropagation()"
                    >Revision
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.requiredFor"
                    (change)="reverseValue('requiredFor')"
                    (click)="$event.stopPropagation()"
                    >Required For
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.redlineSubsystem"
                    (change)="reverseValue('redlineSubsystem')"
                    (click)="$event.stopPropagation()"
                    >Subsystem
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.rfo"
                    (change)="reverseValue('rfo')"
                    (click)="$event.stopPropagation()"
                    >RFO
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.submissionStatus"
                    (change)="reverseValue('submissionStatus')"
                    (click)="$event.stopPropagation()"
                    >Priority
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.rlmuStatus"
                    (change)="reverseValue('rlmuStatus')"
                    (click)="$event.stopPropagation()"
                    >NB RLMU Status
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                  [checked]="showHideColumns.redlineDiscipline"
                  (change)="reverseValue('redlineDiscipline')"
                  (click)="$event.stopPropagation()"
                  >Discipline
                </mat-checkbox>
                <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.nbResponsibleEngineer"
                    (change)="reverseValue('nbResponsibleEngineer')"
                    (click)="$event.stopPropagation()"
                    >NB Responsible Engineer
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="!showHideColumns.dcnRfiNumber"
                    (change)="reverseValue('dcnRfiNumber')"
                    (click)="$event.stopPropagation()"
                    >DCN/RFI Number
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.tcoStatus"
                    (change)="reverseValue('tcoStatus')"
                    (click)="$event.stopPropagation()"
                    >TCO RLMU Status
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.walkdownForecast"
                    (change)="reverseValue('walkdownForecast')"
                    (click)="$event.stopPropagation()"
                    >Walkdown Forecast
                  </mat-checkbox>


                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.walkdownActual"
                    (change)="reverseValue('walkdownActual')"
                    (click)="$event.stopPropagation()"
                    >Walkdown Actual
                  </mat-checkbox>

                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                  [checked]="showHideColumns.mcActual"
                  (change)="reverseValue('mcActual')"
                  (click)="$event.stopPropagation()"
                  >MC Actual
                </mat-checkbox>

                <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                [checked]="showHideColumns.rfsuPlan"
                (change)="reverseValue('rfsuPlan')"
                (click)="$event.stopPropagation()"
                >RFSU Plan
              </mat-checkbox>

                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.rfsuActual"
                    (change)="reverseValue('rfsuActual')"
                    (click)="$event.stopPropagation()"
                    >RFSU Actual
                  </mat-checkbox>
                  <mat-checkbox *ngIf="registerType === registerTypes.Redline"
                    [checked]="showHideColumns.comments"
                    (change)="reverseValue('comments')"
                    (click)="$event.stopPropagation()"
                    >Comments
                  </mat-checkbox>
                </div>
            </mat-menu>
      </div>
    </div>
  </div>   
</div>
<div class="scroll padding-color" *ngIf="registerType === registerTypes.Dcnrfi">
  <div class="table-container">
    <app-mat-table-sticky-header [scrollKey]="'dcn/rfi-register'">
      <table mat-table [dataSource]="dcnRfiDataSource">
        <ng-container matColumnDef="dcnRfiNumber">
            <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.dcnRfiNumber
              }"
              class="hover-pointer"
              (click)="
                  openHeaderCheckListFilter(
                      'number',
                      getDcnRfiNumbers,
                      'columnDcnRfiNumber',
                      'DCN/RFI Number',
                      columnDcnRfiNumber,
                      true
                  )
              "
            >
              <div class="sort-container center">
                  <span
                      [ngClass]="{
                          'header-underline': showUnderlineForCheckListColumn(columnDcnRfiNumber)
                      }"
                      >DCN/RFI Number</span
                  >
                  <div class="sort-icon" *ngIf="sortBy === 'number'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
            </th>
            <td class="color-column" mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.dcnRfiNumber }">
              <a (click)="openDetailsPage(row)">{{ row.number }}</a>
            </td>
        </ng-container>
        <ng-container matColumnDef="dcnRfiDiscipline">
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.dcnRfiDiscipline
            }"
            class="hover-pointer"
            (click)="
                openHeaderCheckListFilter(
                    'discipline',
                    getDcnRfiDisciplines,
                    'columnDcnRfiDiscipline',
                    'Discipline',
                    columnDcnRfiDiscipline,
                    true
                )
            "
          >
            <div class="sort-container center">
                <span
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnDcnRfiDiscipline)
                    }"
                    >Discipline</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.dcnRfiDiscipline }">{{ row.discipline }}</td>
        </ng-container>
        <ng-container matColumnDef="dcnRfiTitle">
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.dcnRfiTitle
            }"
            class="hover-pointer"
            (click)="
                openHeaderCheckListFilter(
                    'title',
                    getDcnRfiTitles,
                    'columnDcnRfiTitle',
                    'Title',
                    columnDcnRfiTitle,
                    true
                )
            "
          >
            <div class="sort-container center">
                <span
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnDcnRfiTitle)
                    }"
                    >Title</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'title'">
                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.dcnRfiTitle }">{{ row.title }}</td>
        </ng-container>
        <ng-container matColumnDef="dcnRfiSubsystem">
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.dcnRfiSubsystem
            }"
            class="hover-pointer"
            (click)="
                openHeaderCheckListFilter(
                    'subsystem',
                    getDcnRfiSubsystems,
                    'columnDcnRfiSubsystem',
                    'Subsystem',
                    columnDcnRfiSubsystem,
                    true,
                    true,
                    true
                )
            "
          >
            <div class="sort-container center">
                <span
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnDcnRfiSubsystem)
                    }"
                    >Subsystem</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.dcnRfiSubsystem }">
            <span
                *ngIf="NumberOfSubsystems(row.subsystem) > 3"
                matTooltip="{{ row.subsystem }}"
                matTooltipClass="scttooltip-width800"
                matTooltipPosition="below"
                (mouseenter)="$event.stopImmediatePropagation()"
                (click)="tooltip.show()"
                #tooltip="matTooltip"
                ><span class="tabletooltip">{{ NumberOfSubsystems(row.subsystem) }} subsystems</span>
            </span>
            {{ NumberOfSubsystems(row.subsystem) < 4 ? (row.subsystem | breakLine: ', ') : '' }}
        </td>
        </ng-container>
        <ng-container matColumnDef="dcnRfiEngineeringStatus">
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.dcnRfiEngineeringStatus
            }"
            class="hover-pointer"
            (click)="
                openHeaderCheckListFilter(
                    'engineeringStatus',
                    getDcnRfiEngineeringStatus,
                    'columnDcnRfiEngineeringStatus',
                    'Engineering Status',
                    columnDcnRfiEngineeringStatus,
                    true
                )
            "
          >
            <div class="sort-container center">
                <span
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnDcnRfiEngineeringStatus)
                    }"
                    >Engineering Status</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'engineeringStatus'">
                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.dcnRfiEngineeringStatus }">
            <span 
              [class.status-grey]="row.engineeringStatus === 'In Engineering'" 
              [class.status-blue]="row.engineeringStatus === 'No Physical Work' || row.engineeringStatus === 'To Implement'"
              [class.status-red]="row.engineeringStatus === 'Void' || row.engineeringStatus === 'Superseded'"
            >{{ row.engineeringStatus }}
            </span> 
          </td>
        </ng-container>
        <ng-container matColumnDef="dcnRfiWorkflowStatus">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.dcnRfiWorkflowStatus
              }"
              class="hover-pointer"
              (click)="
                  openHeaderCheckListFilter(
                      'workflowStatus',
                      getDcnRfiWorkflowStatus,
                      'columnDcnRfiWorkflowStatus',
                      'Workflow Status',
                      columnDcnRfiWorkflowStatus,
                      true
                  )
              "
            >
              <div class="sort-container center">
                  <span
                      [ngClass]="{
                          'header-underline': showUnderlineForCheckListColumn(columnDcnRfiWorkflowStatus)
                      }"
                      >Workflow Status</span
                  >
                  <div class="sort-icon" *ngIf="sortBy === 'workflowStatus'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.dcnRfiWorkflowStatus }">
            <span 
              [class.status-blue]=" row.workflowStatus === 'Reviewed'"
              [class.status-red]=" row.workflowStatus === 'Review Required'"
          >{{ row.workflowStatus }}
          </span> 
        </td>
        </ng-container>
        <ng-container matColumnDef="rfsuForecast">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.rfsuForecast,
                  'header-filtered': showUnderlineForCalendarColumn(columnDcnRfiRFSUForecast)
              }"
              class="hover-pointer"
              (click)="
                  openHeaderDateFilter(
                      'rfsuForecast',
                      'columnDcnRfiRFSUForecast',
                      'RFSU Forecast',
                      columnDcnRfiRFSUForecast
                  )
                "
            >
                <div class="sort-container center">
                    <span>RFSU Forecast</span>
                    <div class="sort-icon" *ngIf="sortBy === 'rfsuForecast'">
                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.rfsuForecast }">{{ row.rfsuForecast  | date: 'dd/MMM/yy' }}</td>
        </ng-container>

        <ng-container matColumnDef="walkdownActual">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.walkdownActual,
                  'header-filtered': showUnderlineForCalendarColumn(columnDcnRfiWalkdownActual)
              }"
              class="hover-pointer"
              (click)="
                  openHeaderDateFilter(
                      'walkdownActual',
                      'columnDcnRfiWalkdownActual',
                      'Walkdown Actual',
                      columnDcnRfiWalkdownActual
                  )
                "
            >
                <div class="sort-container center">
                    <span>Walkdown Actual</span>
                    <div class="sort-icon" *ngIf="sortBy === 'walkdownActual'">
                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.walkdownActual }">{{ row.walkdownActual  | date: 'dd/MMM/yy' }}</td>
        </ng-container>



        <ng-container matColumnDef="mcActual">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.mcActual,
                  'header-filtered': showUnderlineForCalendarColumn(columnDcnRfiMcActual)
              }"
              class="hover-pointer"
              (click)="
                  openHeaderDateFilter(
                      'mcActual',
                      'columnDcnRfiMcActual',
                      'MC Actual',
                      columnDcnRfiMcActual
                  )
                "
            >
                <div class="sort-container center">
                    <span>MC Actual</span>
                    <div class="sort-icon" *ngIf="sortBy === 'mcActual'">
                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.mcActual }">{{ row.mcActual  | date: 'dd/MMM/yy' }}</td>
        </ng-container>


        <ng-container matColumnDef="walkdownForecast">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.walkdownForecast,
                  'header-filtered': showUnderlineForCalendarColumn(columnDcnRfiWalkdownForecast)
              }"
              class="hover-pointer"
              (click)="
                  openHeaderDateFilter(
                      'walkdownForecast',
                      'columnDcnRfiWalkdownForecast',
                      'Walkdown Forecast',
                      columnDcnRfiWalkdownForecast
                  )
                "
            >
                <div class="sort-container center">
                    <span>Walkdown Forecast</span>
                    <div class="sort-icon" *ngIf="sortBy === 'walkdownForecast'">
                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.walkdownForecast }">{{ row.walkdownForecast  | date: 'dd/MMM/yy' }}</td>
        </ng-container>


        <ng-container matColumnDef="rfsuPlan">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.rfsuPlan,
                  'header-filtered': showUnderlineForCalendarColumn(columnDcnRfiRfsuPlan)
              }"
              class="hover-pointer"
              (click)="
                  openHeaderDateFilter(
                      'rfsuPlan',
                      'columnDcnRfiRfsuPlan',
                      'RFSU Plan',
                      columnDcnRfiRfsuPlan
                  )
                "
            >
                <div class="sort-container center">
                    <span>RFSU Plan</span>
                    <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.rfsuPlan }">{{ row.rfsuPlan  | date: 'dd/MMM/yy' }}</td>
        </ng-container>

        <ng-container matColumnDef="implementationStatus">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.implementationStatus
              }"
              class="hover-pointer"
              (click)="
                  openHeaderCheckListFilter(
                      'implementationStatus',
                      getDcnRfiImplementationStatus,
                      'columnDcnRfiImplementationStatus',
                      'Implementation Status',
                      columnDcnRfiImplementationStatus,
                      true
                  )
              "
            >
              <div class="sort-container center">
                  <span
                      [ngClass]="{
                          'header-underline': showUnderlineForCheckListColumn(columnDcnRfiImplementationStatus)
                      }"
                      >Implementation Status</span
                  >
                  <div class="sort-icon" *ngIf="sortBy === 'implementationStatus'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.implementationStatus }">{{ row.implementationStatus }}</td>
        </ng-container>
        <ng-container matColumnDef="rlmuToGo">
          <th
          mat-header-cell
          disableClear
          *matHeaderCellDef
          [ngClass]="{
              hidecells: !showHideColumns.rlmuToGo,
              'header-filtered': showUnderlineForNumericColumn(columnRlmuToGo)
          }"
      >
          <div class="sort-container center">
              <span
                  class="hover-pointer"
                  (click)="openHeaderNumericFilter('rlmuToGo', columnRlmuToGo, 'RLMU To Go')"
                  >RLMU To Go</span
              >
              <div class="sort-icon" *ngIf="sortBy === 'rlmuToGo'">
                  <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                  <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
              </div>
          </div>
      </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.rlmuToGo }">{{ row.rlmuToGo }}</td>
        </ng-container>
        <ng-container matColumnDef="comments">
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.comments
            }"
            class="hover-pointer"
            (click)="
                openHeaderCheckListFilter(
                    'comment',
                    getDcnRfiComments,
                    'columnDcnRfiComments',
                    'Comments',
                    columnDcnRfiComments,
                    true,
                    false,
                    false,
                    true
                )
            "
          >
            <div class="sort-container center">
                <span
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnDcnRfiComments)
                    }"
                    >Comments</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'comment'">
                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
        </th>
        <td
            class="color-column"
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ hidecells: !showHideColumns.comments }"
        >
          <div class="comment-description">
            <div *ngIf="element.comment; else notShow">
              <a
                *ngIf="!isReadOnly"
                (click)="openCommentPopup(element, $event)"
              >
                <span [innerHTML]="asString(element?.comment) | slice:0:180 | formatMentions: element.mentions"></span>
                <span
                  *ngIf="element.comment?.length > 180"
                  (click)="openCommentPopup(element, $event)"
                >... </span>
              </a>
              <span
                *ngIf="isReadOnly"
                (click)="openCommentPopup(element, $event)"
                [innerHTML]="asString(element?.comment) | slice:0:180 | formatMentions: element.mentions"
              ></span>
              <span
                *ngIf="isReadOnly && element.comment?.length > 180"
                (click)="openCommentPopup(element, $event)"
              >... </span>
              <span
                *ngIf="element.comment?.length > 180 "
                matTooltip="{{ element.comment }}"
                matTooltipClass="scttooltip-width400"
                matTooltipPosition="below"
                (mouseenter)="$event.stopImmediatePropagation()"
                (click)="tooltipComment.show()"
                #tooltipComment="matTooltip"
              >
                <span class="tabletooltip">read more</span>
              </span>
            </div>
            <ng-template #notShow>
              <button
                type="button"
                *ngIf="!isReadOnly"
                mat-stroked-button class="add-comment"
                (click)="openCommentPopup(element, $event)"
              >
                Add
              </button>
            </ng-template>
          </div>
          <div
            [ngClass]="{ notactive: !element.comment, active: element.comment }"
            class="comment-hist"
          >
            <mat-icon svgIcon="history" (click)="openHistoryPopup(element)"></mat-icon>
          </div>
        </td>
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="dcnRfiRegisterColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: dcnRfiRegisterColumns"></tr>
    </table>
    </app-mat-table-sticky-header> 
  </div>
</div>

<div class="scroll padding-color" *ngIf="registerType === registerTypes.Redline">
  <div class="table-container">
    <app-mat-table-sticky-header [scrollKey]="'redline-register'">
      <table mat-table [dataSource]="redlineDataSource">
        <ng-container matColumnDef="redlineNumber"> <!-- RENAMED TO 'Document Number'-->
            <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.redlineNumber
              }"
              class="hover-pointer"
              (click)="
                  openHeaderCheckListFilter(
                      'redlineNumber',
                      getRedlineNumbers,
                      'columnRedlineNumber',
                      'Document Number',
                      columnRedlineNumber,
                      true
                  )
              "
            >
              <div class="sort-container center">
                  <span
                      [ngClass]="{
                          'header-underline': showUnderlineForCheckListColumn(columnRedlineNumber)
                      }"
                      >Document Number</span
                  >
                  <div class="sort-icon" *ngIf="sortBy === 'redlineNumber'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
            </th>
            <td class="color-column" mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.redlineNumber }">
              <a (click)="openDetailsPage(row)">{{ row.redlineNumber }}</a>
            </td>
        </ng-container>
        <ng-container matColumnDef="redlineDiscipline"> <!-- RENAMED TO 'Discipline'-->
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.redlineDiscipline
            }"
            class="hover-pointer"
            (click)="
                openHeaderCheckListFilter(
                    'redlineDiscipline',
                    getRedlineDisciplines,
                    'columnRedlineDiscipline',
                    'Discipline',
                    columnRedlineDiscipline,
                    true
                )
            "
          >
            <div class="sort-container center">
                <span
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnRedlineDiscipline)
                    }"
                    >Discipline</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'redlineDiscipline'">
                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.redlineDiscipline }">{{ row.redlineDiscipline }}</td>
        </ng-container>
        <ng-container matColumnDef="redlineTitle"> <!-- RENAMED TO 'Document Title'-->
            <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.redlineTitle
              }"
              class="hover-pointer"
              (click)="
                  openHeaderCheckListFilter(
                      'title',
                      getRedlineTitles,
                      'columnRedlineTitle',
                      'Document Title',
                      columnRedlineTitle,
                      true
                  )
              "
            >
              <div class="sort-container center">
                  <span
                      [ngClass]="{
                          'header-underline': showUnderlineForCheckListColumn(columnRedlineTitle)
                      }"
                      >Document Title</span
                  >
                  <div class="sort-icon" *ngIf="sortBy === 'title'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.redlineTitle }">{{ row.title }}</td>
        </ng-container>
        <ng-container matColumnDef="revision">
            <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.revision
              }"
              class="hover-pointer"
              (click)="
                  openHeaderCheckListFilter(
                      'revision',
                      getRedlineRevisions,
                      'columnRedlineRevision',
                      'Revision',
                      columnRedlineRevision,
                      true
                  )
              "
            >
              <div class="sort-container center">
                  <span
                      [ngClass]="{
                          'header-underline': showUnderlineForCheckListColumn(columnRedlineRevision)
                      }"
                      >Revision</span
                  >
                  <div class="sort-icon" *ngIf="sortBy === 'revision'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.revision }">{{ row.revision }}</td>
        </ng-container>
        <ng-container matColumnDef="dcnRfiNumber">
              <th
                mat-header-cell
                disableClear
                *matHeaderCellDef
                [ngClass]="{
                    hidecells: showHideColumns.dcnRfiNumber
                }"
                class="hover-pointer"
                (click)="
                    openHeaderCheckListFilter(
                        'dcnRfiNumber',
                        getDcnRfiNumbers,
                        'columnDcnRfiNumber',
                        'DCN/RFI Number',
                        columnDcnRfiNumber,
                        true
                    )
                "
              >
                <div class="sort-container center">
                    <span
                        [ngClass]="{
                            'header-underline': showUnderlineForCheckListColumn(columnDcnRfiNumber)
                        }"
                        >DCN/RFI Number</span
                    >
                    <div class="sort-icon" *ngIf="sortBy === 'dcnRfiNumber'">
                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: showHideColumns.dcnRfiNumber }">{{ row.dcnRfiNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="rlmuStatus"> <!-- RENAMED TO 'NB RLMU Status'-->
            <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.rlmuStatus
              }"
              class="hover-pointer"
              (click)="
                  openHeaderCheckListFilter(
                      'rlmuStatus',
                      getRedlineRlmuStatus,
                      'columnRedlineRlmuStatus',
                      'NB RLMU Status',
                      columnRedlineRlmuStatus,
                      true
                  )
              "
            >
              <div class="sort-container center">
                  <span
                      [ngClass]="{
                          'header-underline': showUnderlineForCheckListColumn(columnRedlineRlmuStatus)
                      }"
                      >NB RLMU Status</span
                  >
                  <div class="sort-icon" *ngIf="sortBy === 'rlmuStatus'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.rlmuStatus }">
              <span 
                [class.status-grey]="row.rlmuStatus === 'Pending'" 
                [class.status-blue]=" row.rlmuStatus === 'Released to DCC'"
                [class.status-red]=" row.rlmuStatus === 'Rejected'"
                [class.status-green]=" row.rlmuStatus === 'To Consolidate'"
              >
                {{ row.rlmuStatus }}
              </span>
          </td>
          </ng-container>
          <ng-container matColumnDef="tcoStatus"> <!-- RENAMED TO 'TCO RLMU Status'-->
            <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.tcoStatus
              }"
              class="hover-pointer"
              (click)="
                  openHeaderCheckListFilter(
                      'tcoStatus',
                      getRedlineTcoStatus,
                      'columnRedlineTcoStatus',
                      'TCO RLMU Status',
                      columnRedlineTcoStatus,
                      true
                  )
              "
            >
              <div class="sort-container center">
                  <span
                      [ngClass]="{
                          'header-underline': showUnderlineForCheckListColumn(columnRedlineTcoStatus)
                      }"
                      >TCO RLMU Status</span
                  >
                  <div class="sort-icon" *ngIf="sortBy === 'tcoStatus'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.tcoStatus }">{{ row.tcoStatus }}</td>
          </ng-container>
        <ng-container matColumnDef="redlineSubsystem">
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.redlineSubsystem
            }"
            class="hover-pointer"
            (click)="
                openHeaderCheckListFilter(
                    'redlineSubsystem',
                    getRedlineSubsystems,
                    'columnRedlineSubsystems',
                    'Subsystem',
                    columnRedlineSubsystems,
                    true
                )
            "
          >
            <div class="sort-container center">
                <span
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnRedlineSubsystems)
                    }"
                    >Subsystem</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'redlineSubsystem'">
                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.redlineSubsystem }">{{ row.redlineSubsystem }}</td>
        </ng-container>

        <ng-container matColumnDef="walkdownForecast">
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.walkdownForecast,
                'header-filtered': showUnderlineForCalendarColumn(columnRedlineWalkdownForecast)
            }"
            class="hover-pointer"
            (click)="
                openHeaderDateFilter(
                    'walkdownForecast',
                    'columnRedlineWalkdownForecast',
                    'Walkdown Forecast',
                    columnRedlineWalkdownForecast
                )
              "
          >
              <div class="sort-container center">
                  <span>Walkdown Forecast</span>
                  <div class="sort-icon" *ngIf="sortBy === 'walkdownForecast'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
          </th>
            <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.walkdownForecast }">{{ row.walkdownForecast  | date: 'dd/MMM/yy' }}</td>
        </ng-container>


        <ng-container matColumnDef="walkdownActual">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.walkdownActual,
                  'header-filtered': showUnderlineForCalendarColumn(columnRedlineWalkdownActual)
              }"
              class="hover-pointer"
              (click)="
                  openHeaderDateFilter(
                      'walkdownActual',
                      'columnRedlineWalkdownActual',
                      'Walkdown Actual',
                      columnRedlineWalkdownActual
                  )
                "
            >
                <div class="sort-container center">
                    <span>Walkdown Actual</span>
                    <div class="sort-icon" *ngIf="sortBy === 'walkdownActual'">
                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.walkdownActual }">{{ row.walkdownActual  | date: 'dd/MMM/yy' }}</td>
        </ng-container>



        <ng-container matColumnDef="mcActual">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.mcActual,
                  'header-filtered': showUnderlineForCalendarColumn(columnRedlineMcActual)
              }"
              class="hover-pointer"
              (click)="
                  openHeaderDateFilter(
                      'mcActual',
                      'columnRedlineMcActual',
                      'MC Actual',
                      columnRedlineMcActual
                  )
                "
            >
                <div class="sort-container center">
                    <span>MC Actual</span>
                    <div class="sort-icon" *ngIf="sortBy === 'mcActual'">
                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.mcActual }">{{ row.mcActual  | date: 'dd/MMM/yy' }}</td>
        </ng-container>




        <ng-container matColumnDef="rfsuPlan">
          <th
              mat-header-cell
              disableClear
              *matHeaderCellDef
              [ngClass]="{
                  hidecells: !showHideColumns.rfsuPlan,
                  'header-filtered': showUnderlineForCalendarColumn(columnRedlineRfsuPlan)
              }"
              class="hover-pointer"
              (click)="
                  openHeaderDateFilter(
                      'rfsuPlan',
                      'columnRedlineRfsuPlan',
                      'RFSU Plan',
                      columnRedlineRfsuPlan
                  )
                "
            >
                <div class="sort-container center">
                    <span>RFSU Plan</span>
                    <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.rfsuPlan }">{{ row.rfsuPlan  | date: 'dd/MMM/yy' }}</td>
        </ng-container>

        <ng-container matColumnDef="rfsuActual">
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.rfsuActual,
                'header-filtered': showUnderlineForCalendarColumn(columnRedlineRFSUActual)
            }"
            class="hover-pointer"
            (click)="
                openHeaderDateFilter(
                    'rfsuActual',
                    'columnRedlineRFSUActual',
                    'RFSU Actual',
                    columnRedlineRFSUActual
                )
              "
          >
              <div class="sort-container center">
                  <span>RFSU Actual</span>
                  <div class="sort-icon" *ngIf="sortBy === 'rfsuActual'">
                      <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                  </div>
              </div>
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.rfsuActual }">{{ row.rfsuActual  | date: 'dd/MMM/yy' }}</td>
        </ng-container>
        <ng-container matColumnDef="submissionStatus"> <!-- RENAMED TO 'Priority'-->
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.submissionStatus
            }"
            class="hover-pointer"
            (click)="
                openHeaderCheckListFilter(
                    'submissionStatus',
                    getRedlineSubmissionStatus,
                    'columnRedlineSubmissionStatus',
                    'Priority',
                    columnRedlineSubmissionStatus,
                    true
                )
            "
          >
            <div class="sort-container center">
                <span
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnRedlineSubmissionStatus)
                    }"
                    >Priority</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'submissionStatus'">
                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.submissionStatus }">{{ row.submissionStatus }}</td>
        </ng-container>
        <ng-container matColumnDef="comments">
          <th
            mat-header-cell
            disableClear
            *matHeaderCellDef
            [ngClass]="{
                hidecells: !showHideColumns.comments
            }"
            class="hover-pointer"
            (click)="
                openHeaderCheckListFilter(
                    'comment',
                    getRedlineComments,
                    'columnRedlineComments',
                    'Comments',
                    columnRedlineComments,
                    true,
                    false,
                    false,
                    true
                )
            "
          >
            <div class="sort-container center">
                <span
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnRedlineComments)
                    }"
                    >Comments</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'comment'">
                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
        </th>
        <td
            class="color-column"
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ hidecells: !showHideColumns.comments }"
        >
          <div class="comment-description">
            <div *ngIf="element.comment?.length > 0; else notShow">
              <a
                *ngIf="!isReadOnly"
                (click)="openCommentPopup(element, $event)"
              >
              <span [innerHTML]="asString(element?.comment) | slice:0:180 | formatMentions: element.mentions"></span>
                <span
                  *ngIf="element.comment?.length > 180"
                  (click)="openCommentPopup(element, $event)"
                >... </span>
              </a>
              <span
                *ngIf="isReadOnly"
                (click)="openCommentPopup(element, $event)"
                [innerHTML]="asString(element?.comment) | slice:0:180 | formatMentions: element.mentions"
              ></span>
              <span
                *ngIf="isReadOnly && element.comment?.length > 180"
                (click)="openCommentPopup(element, $event)"
              >... </span>
              <span
                *ngIf="element.comment?.length > 180 "
                matTooltip="{{ element.comment }}"
                matTooltipClass="scttooltip-width400"
                matTooltipPosition="below"
                (mouseenter)="$event.stopImmediatePropagation()"
                (click)="tooltipComment.show()"
                #tooltipComment="matTooltip"
              >
                <span class="tabletooltip">read more</span>
              </span>
            </div>
            <ng-template #notShow>
              <button
                type="button"
                *ngIf="!isReadOnly"
                mat-stroked-button class="add-comment"
                (click)="openCommentPopup(element, $event)"
              >
                Add
              </button>
            </ng-template>
          </div>
          <div
            [ngClass]="{ notactive: !element.comment, active: element.comment }"
            class="comment-hist"
          >
            <mat-icon svgIcon="history" (click)="openHistoryPopup(element)"></mat-icon>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="requiredFor">
        <th
          mat-header-cell
          disableClear
          *matHeaderCellDef
          [ngClass]="{
              hidecells: !showHideColumns.requiredFor
          }"
          class="hover-pointer"
          (click)="
              openHeaderCheckListFilter(
                  'requiredFor',
                  getRedlineRequiredFor,
                  'columnRedlineRequiredFor',
                  'Required For',
                  columnRedlineRequiredFor,
                  true
              )
          "
        >
          <div class="sort-container center">
              <span
                  [ngClass]="{
                      'header-underline': showUnderlineForCheckListColumn(columnRedlineRequiredFor)
                  }"
                  >Required For</span
              >
              <div class="sort-icon" *ngIf="sortBy === 'requiredFor'">
                  <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                  <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
              </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.requiredFor }">{{ row.requiredFor }}</td>
      </ng-container>
      <ng-container matColumnDef="rfo">
        <th
          mat-header-cell
          disableClear
          *matHeaderCellDef
          [ngClass]="{
              hidecells: !showHideColumns.rfo
          }"
          class="hover-pointer"
          (click)="
              openHeaderCheckListFilter(
                  'rfo',
                  getRedlineRfo,
                  'columnRedlineRfo',
                  'RFO',
                  columnRedlineRfo,
                  true
              )
          "
        >
          <div class="sort-container center">
              <span
                  [ngClass]="{
                      'header-underline': showUnderlineForCheckListColumn(columnRedlineRfo)
                  }"
                  >RFO</span
              >
              <div class="sort-icon" *ngIf="sortBy === 'rfo'">
                  <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                  <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
              </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.rfo }">{{ row.rfo }}</td>
      </ng-container>
      <ng-container matColumnDef="nbResponsibleEngineer">
        <th
          mat-header-cell
          disableClear
          *matHeaderCellDef
          [ngClass]="{
              hidecells: !showHideColumns.nbResponsibleEngineer
          }"
          class="hover-pointer"
          (click)="
              openHeaderCheckListFilter(
                  'nbResponsibleEngineer',
                  getRedlineNbRespEngineer,
                  'columnRedlineNbRespEngineer',
                  'NB Responsible Engineer',
                  columnRedlineNbRespEngineer,
                  true
              )
          "
        >
          <div class="sort-container center">
              <span
                  [ngClass]="{
                      'header-underline': showUnderlineForCheckListColumn(columnRedlineNbRespEngineer)
                  }"
                  >NB Responsible Engineer</span
              >
              <div class="sort-icon" *ngIf="sortBy === 'nbResponsibleEngineer'">
                  <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                  <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
              </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let row" [ngClass]="{ hidecells: !showHideColumns.nbResponsibleEngineer }">{{ row.nbResponsibleEngineer }}</td>
    </ng-container>
        <tr mat-header-row *matHeaderRowDef="redlineRegisterColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: redlineRegisterColumns"></tr>
      </table>
    </app-mat-table-sticky-header>
  </div>
</div>
<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
