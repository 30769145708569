import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { WeeklyPlanType } from 'src/app/enums';
import { WeekSummaryDto } from '../model';
import { ScheduleActivityPlanningActions, ScheduleActivityPlanningActionTypes } from './actions';
import {
    ActivityInWeekColumn,
    ScheduleActivityPlanningDTO,
    ScheduleActivityPlanningFilter,
    ScheduleActivityWeeklyStatus,
    ShowHideColumns,
    SubsystemWithScheduleActivitiesDTO,
} from './model';

export interface ScheduleActivityPlanningState {
    subsystemsWithScheduleActivitiesIsLoading: boolean;
    subsystemsWithScheduleActivities: SubsystemWithScheduleActivitiesDTO[];
    printInWeekIsLoading: boolean;
    printData: SubsystemWithScheduleActivitiesDTO[];
    subsystemsWithScheduleActivitiesTotalCount: number;
    subsystemsWithScheduleActivitiesIsAllSubmitted: boolean;
    subsystemsNotInWeekWithScheduleActivitiesIsLoading: boolean;
    subsystemsNotInWeekWithScheduleActivities: SubsystemWithScheduleActivitiesDTO[];
    subsystemsNotInWeekWithScheduleActivitiesTotalCount: number;
    subsystemsNotInWeekWithScheduleActivitiesIsAllSubmitted: boolean;
    subsystemsNotInWeekWithScheduleActivitiesPagination: { take: number; page: number };
    subsystemsNotInWeekWithScheduleActivitiesShowHideColumns: ShowHideColumns;
    subsystemsNotInWeekWithScheduleActivitiesPanelExpanded: boolean;
    subsystemsInWeekWithScheduleActivitiesPanelExpanded: boolean;
    scheduleActivityPlanningFilter: ScheduleActivityPlanningFilter;
    printNotInWeekIsLoading: boolean;
    notInWeekPrintData: SubsystemWithScheduleActivitiesDTO[];
    weekSummary: WeekSummaryDto;
    multiPanels: boolean;
    isLoading: boolean;
    scrollTop: number;
}

const initialState: ScheduleActivityPlanningState = {
    subsystemsWithScheduleActivities: [],
    subsystemsWithScheduleActivitiesTotalCount: 0,
    subsystemsWithScheduleActivitiesIsAllSubmitted: true,
    subsystemsWithScheduleActivitiesIsLoading: false,
    printInWeekIsLoading: false,
    printData: [],
    subsystemsNotInWeekWithScheduleActivities: null,
    subsystemsNotInWeekWithScheduleActivitiesTotalCount: 0,
    subsystemsNotInWeekWithScheduleActivitiesIsAllSubmitted: true,
    subsystemsNotInWeekWithScheduleActivitiesIsLoading: false,
    subsystemsNotInWeekWithScheduleActivitiesPagination: { take: 10, page: 0 },
    subsystemsNotInWeekWithScheduleActivitiesShowHideColumns: new ShowHideColumns(),
    subsystemsNotInWeekWithScheduleActivitiesPanelExpanded: null,
    subsystemsInWeekWithScheduleActivitiesPanelExpanded: true,
    scheduleActivityPlanningFilter: new ScheduleActivityPlanningFilter(),
    printNotInWeekIsLoading: false,
    notInWeekPrintData: [],
    weekSummary: null,
    multiPanels: false,
    isLoading: false,
    scrollTop: 0,
};

const getAdditionalWeekColumns = (weeklyPlanType: WeeklyPlanType, weekStartDate: moment.Moment) => {
    if (weeklyPlanType === WeeklyPlanType.monthly && weekStartDate) {
        const firstWeek = moment(weekStartDate).toDate();
        const secondWeek = moment(firstWeek).add(7, 'days').toDate();
        const thirdWeek = moment(secondWeek).add(7, 'days').toDate();
        const fourthWeek = moment(thirdWeek).add(7, 'days').toDate();
        const result = [firstWeek, secondWeek, thirdWeek, fourthWeek];

        return result;
    }
    return [];
};

const prepareMonthlyActivities = (
    weeklyPlanType: WeeklyPlanType,
    weekStartDate: moment.Moment,
    activities: SubsystemWithScheduleActivitiesDTO[]
) => {
    return activities.map((item) => {
        const mcDatesRow = new ScheduleActivityPlanningDTO();
        mcDatesRow.activity = 'Mechanical Completion';
        mcDatesRow.subsystem = item.subsystem;
        mcDatesRow.subsystemName = item.subsystemName;
        mcDatesRow.planFinish = item.mcActual ?? item.mcPlan;
        mcDatesRow.forecastFinish = item.mcForecast;
        mcDatesRow.weeklyStatus = new ScheduleActivityWeeklyStatus();

        const rfsuDatesRow = new ScheduleActivityPlanningDTO();
        rfsuDatesRow.activity = 'Ready for Start-up';
        rfsuDatesRow.subsystem = item.subsystem;
        rfsuDatesRow.subsystemName = item.subsystemName;
        rfsuDatesRow.planFinish = item.rfsuActual ?? item.rfsuPlan;
        rfsuDatesRow.forecastFinish = item.rfsuForecast;
        rfsuDatesRow.weeklyStatus = new ScheduleActivityWeeklyStatus();

        const activityItem = {
            ...item,
            activities: [mcDatesRow, ...item.activities, rfsuDatesRow],
        };
        const additionalWeekColumns = getAdditionalWeekColumns(weeklyPlanType, weekStartDate);
        activityItem.activities = activityItem.activities.map((x) => ({
            ...x,
            weekColumns: setActivityInWeek(x, additionalWeekColumns),
        }));

        return activityItem;
    });
};

const setActivityInWeek = (activity: ScheduleActivityPlanningDTO, additionalColumns: Date[]) => {
    let inWeeks = [];
    additionalColumns.forEach((week, index) => {
        const inWeek =
            (week >= moment(activity.planStart).toDate() && week <= moment(activity.planFinish).toDate()) ||
            (moment(activity.planStart).toDate() >= week &&
                moment(activity.planStart).toDate() <= moment(week).add(6, 'days').utc().toDate()) ||
            (moment(activity.planFinish).toDate() >= week &&
                moment(activity.planFinish).toDate() <= moment(week).add(6, 'days').utc().toDate());

        const activityInWeek = new ActivityInWeekColumn();
        activityInWeek.week = week;
        activityInWeek.inWeek = inWeek;
        activityInWeek.columnId = `W${index}`;
        inWeeks.push(activityInWeek);
    });
    return inWeeks;
};

export function reducer(state = initialState, action: ScheduleActivityPlanningActions): ScheduleActivityPlanningState {
    switch (action.type) {
        case ScheduleActivityPlanningActionTypes.PlanningDataRequestSuccess:
            return action.payload.printMode
                ? { ...state, printData: action.payload.items, printInWeekIsLoading: false }
                : {
                      ...state,
                      subsystemsWithScheduleActivities:
                          state.scheduleActivityPlanningFilter.weeklyPlanType !== WeeklyPlanType.weekly
                              ? prepareMonthlyActivities(
                                    state.scheduleActivityPlanningFilter.weeklyPlanType,
                                    state.scheduleActivityPlanningFilter.weekStart,
                                    action.payload.items
                                )
                              : action.payload.items,
                      subsystemsWithScheduleActivitiesTotalCount: action.payload.totalCount,
                      subsystemsWithScheduleActivitiesIsAllSubmitted: action.payload.isAllSubmitted,
                      subsystemsWithScheduleActivitiesIsLoading: false,
                  };
        case ScheduleActivityPlanningActionTypes.PlanningDataRequestError:
            return { ...state, subsystemsWithScheduleActivitiesIsLoading: false };
        case ScheduleActivityPlanningActionTypes.PlanningDataRequest: {
            return action.payload
                ? { ...state, printInWeekIsLoading: true }
                : { ...state, subsystemsWithScheduleActivitiesIsLoading: true };
        }
        case ScheduleActivityPlanningActionTypes.NotInWeekPlanningDataRequestSuccess:
            return action.payload.printMode
                ? { ...state, notInWeekPrintData: action.payload.items, printNotInWeekIsLoading: false }
                : {
                      ...state,
                      subsystemsNotInWeekWithScheduleActivities: action.payload.items,
                      subsystemsNotInWeekWithScheduleActivitiesTotalCount: action.payload.totalCount,
                      subsystemsNotInWeekWithScheduleActivitiesIsAllSubmitted: action.payload.isAllSubmitted,
                      subsystemsNotInWeekWithScheduleActivitiesIsLoading: false,
                  };
        case ScheduleActivityPlanningActionTypes.NotInWeekPlanningDataRequestError:
            return { ...state, subsystemsNotInWeekWithScheduleActivitiesIsLoading: false };
        case ScheduleActivityPlanningActionTypes.NotInWeekPlanningDataRequest: {
            return action.payload
                ? { ...state, printNotInWeekIsLoading: true }
                : { ...state, subsystemsNotInWeekWithScheduleActivitiesIsLoading: true };
        }
        case ScheduleActivityPlanningActionTypes.CreateNewActivityRequest: {
            const itemIndex = state.subsystemsWithScheduleActivities.findIndex(
                (sub) => sub.subsystem === action.payload.subsystem
            );

            return {
                ...state,
                subsystemsWithScheduleActivities: state.subsystemsWithScheduleActivities.map((item, index) => {
                    if (index === itemIndex) {
                        return {
                            ...item,
                            isLoading: true,
                        };
                    }
                    return item;
                }),
            };
        }
        case ScheduleActivityPlanningActionTypes.CreateNewActivitySuccess: {
            const itemIndex = state.subsystemsWithScheduleActivities.findIndex(
                (sub) => sub.subsystem === action.subsystem
            );

            const subsystem = {
                ...state.subsystemsWithScheduleActivities[itemIndex],
                activities: [action.payload],
            };

            const additionalWeekColumns = getAdditionalWeekColumns(
                state.scheduleActivityPlanningFilter.weeklyPlanType,
                state.scheduleActivityPlanningFilter.weekStart
            );

            subsystem.activities.forEach((activity) => {
                activity.weekColumns = setActivityInWeek(activity, additionalWeekColumns);
            });

            subsystem.isLoading = false;
            return {
                ...state,
                subsystemsWithScheduleActivities: state.subsystemsWithScheduleActivities.map((item, index) => {
                    if (index === itemIndex) {
                        return subsystem;
                    }
                    return item;
                }),
            };
        }
        case ScheduleActivityPlanningActionTypes.CreateNewActivityError: {
            const subsystemIndex = state.subsystemsWithScheduleActivities.findIndex(
                (sub) => sub.subsystem === action.subsystem
            );

            return {
                ...state,
                subsystemsWithScheduleActivities: state.subsystemsWithScheduleActivities.map((item, index) => {
                    if (index === subsystemIndex) {
                        return {
                            ...item,
                            isLoading: false,
                        };
                    }
                    return item;
                }),
            };
        }
        case ScheduleActivityPlanningActionTypes.ActivityUpdate: {
            const subsystemIndex = state.subsystemsWithScheduleActivities.findIndex(
                (sub) => sub.subsystem === action.subsystem
            );

            return {
                ...state,
                subsystemsWithScheduleActivities: state.subsystemsWithScheduleActivities.map((item, index) => {
                    if (index === subsystemIndex) {
                        return {
                            ...item,
                            isLoading: false,
                        };
                    }
                    return item;
                }),
            };
        }
        case ScheduleActivityPlanningActionTypes.ActivityUpdateSuccess: {
            let subsystem = cloneDeep(
                state.subsystemsWithScheduleActivities.find((sub) => sub.subsystem === action.subsystem)
            );
            subsystem.isLoading = false;

            const activity = subsystem.activities.find(
                (act) =>
                    act.activityId === action.payload.activityId &&
                    act.activityDiscipline === action.payload.activityDiscipline
            );

            for (const key in action.payload) {
                if (activity.hasOwnProperty(key)) {
                    activity[key] = action.payload[key];
                    if (key === 'forecastFinish' && activity.userId) {
                        activity.planFinish = action.payload[key];
                    }
                    if (key === 'forecastStart' && activity.userId) {
                        activity.planStart = action.payload[key];
                    }
                }
            }

            if (
                state.scheduleActivityPlanningFilter.weeklyPlanType === WeeklyPlanType.monthly &&
                (activity.hasOwnProperty('forecastStart') || activity.hasOwnProperty('forecastFinish'))
            ) {
                const additionalWeekColumns = getAdditionalWeekColumns(
                    state.scheduleActivityPlanningFilter.weeklyPlanType,
                    state.scheduleActivityPlanningFilter.weekStart
                );

                activity.weekColumns = setActivityInWeek(activity, additionalWeekColumns);
            }

            const newSubsActivities = [
                ...state.subsystemsWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? subsystem : sub
                ),
            ];
            return { ...state, subsystemsWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.ActivityUpdateError: {
            const newSubsActivities = [
                ...state.subsystemsWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? { ...sub, isLoading: false } : sub
                ),
            ];

            return { ...state, subsystemsWithScheduleActivities: newSubsActivities };
        }

        case ScheduleActivityPlanningActionTypes.ActivityNotInWeekUpdate: {
            const newSubsActivities = [
                ...state.subsystemsNotInWeekWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? { ...sub, isLoading: true } : sub
                ),
            ];

            return { ...state, subsystemsNotInWeekWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.ActivityNotInWeekUpdateSuccess: {
            let subsystem = cloneDeep(
                state.subsystemsNotInWeekWithScheduleActivities.find((sub) => sub.subsystem === action.subsystem)
            );
            subsystem.isLoading = false;

            const activity = subsystem.activities.find(
                (act) =>
                    act.activityId === action.payload.activityId &&
                    act.activityDiscipline === action.payload.activityDiscipline
            );

            for (const key in action.payload) {
                if (activity.hasOwnProperty(key)) {
                    activity[key] = action.payload[key];
                    if (key === 'forecastFinish' && activity.userId) {
                        activity.planFinish = action.payload[key];
                    }
                    if (key === 'forecastStart' && activity.userId) {
                        activity.planStart = action.payload[key];
                    }
                }
            }

            if (
                state.scheduleActivityPlanningFilter.weeklyPlanType === WeeklyPlanType.monthly &&
                (activity.hasOwnProperty('forecastStart') || activity.hasOwnProperty('forecastFinish'))
            ) {
                const additionalWeekColumns = getAdditionalWeekColumns(
                    state.scheduleActivityPlanningFilter.weeklyPlanType,
                    state.scheduleActivityPlanningFilter.weekStart
                );

                activity.weekColumns = setActivityInWeek(activity, additionalWeekColumns);
            }

            const newSubsActivities = [
                ...state.subsystemsNotInWeekWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? subsystem : sub
                ),
            ];

            return { ...state, subsystemsNotInWeekWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.ActivityNotInWeekUpdateError: {
            const newSubsActivities = [
                ...state.subsystemsNotInWeekWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? { ...sub, isLoading: false } : sub
                ),
            ];

            return { ...state, subsystemsNotInWeekWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.PropertyUpdate: {
            const newSubsActivities = [
                ...state.subsystemsWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? { ...sub, isLoading: true } : sub
                ),
            ];
            return { ...state, subsystemsWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.PropertyUpdateSuccess: {
            let subsystem = state.subsystemsWithScheduleActivities.find((sub) => sub.subsystem === action.subsystem);
            subsystem = {
                ...subsystem,
                isLoading: false,
                activities: [
                    ...subsystem.activities.map((act) => {
                        if (
                            act.activityId === action.payload.activityId &&
                            act.activityDiscipline === action.payload.activityDiscipline
                        ) {
                            return {
                                ...act,
                                weeklyStatus: { ...act.weeklyStatus, [action.payload.property]: action.payload.value },
                                selectedITRsCount:
                                    action.payload.removeSelectedItrs === true ? 0 : act.selectedITRsCount,
                            };
                        } else {
                            return act;
                        }
                    }),
                ],
            } as SubsystemWithScheduleActivitiesDTO;

            const newSubsActivities = [
                ...state.subsystemsWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? subsystem : sub
                ),
            ];

            return { ...state, subsystemsWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.PropertyUpdateError: {
            const newSubsActivities = [
                ...state.subsystemsWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? { ...sub, isLoading: false } : sub
                ),
            ];
            return { ...state, subsystemsWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.NotInWeekPropertyUpdate: {
            const newSubsActivities = [
                ...state.subsystemsNotInWeekWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? { ...sub, isLoading: true } : sub
                ),
            ];
            return { ...state, subsystemsNotInWeekWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.NotInWeekPropertyUpdateSuccess: {
            let subsystem = cloneDeep(
                state.subsystemsNotInWeekWithScheduleActivities.find((sub) => sub.subsystem === action.subsystem)
            );
            subsystem.isLoading = false;
            const activity = subsystem.activities.find(
                (act) =>
                    act.activityId === action.payload.activityId &&
                    act.activityDiscipline === action.payload.activityDiscipline
            );

            activity.weeklyStatus = { ...activity.weeklyStatus, [action.payload.property]: action.payload.value };

            const newSubsActivities = [
                ...state.subsystemsNotInWeekWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? subsystem : sub
                ),
            ];
            return { ...state, subsystemsNotInWeekWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.NotInWeekPropertyUpdateError: {
            const newSubsActivities = [
                ...state.subsystemsNotInWeekWithScheduleActivities.map((sub) =>
                    sub.subsystem === action.subsystem ? { ...sub, isLoading: false } : sub
                ),
            ];
            return { ...state, subsystemsNotInWeekWithScheduleActivities: newSubsActivities };
        }
        case ScheduleActivityPlanningActionTypes.InferDisciplines: {
            let disciplines = state.scheduleActivityPlanningFilter.disciplines;
            const { nbDisciplineLeads, nbEngineers, tcoAreaCoordinators } = state.scheduleActivityPlanningFilter;

            const disciplineSources = [nbDisciplineLeads, nbEngineers, tcoAreaCoordinators]
                .map((workers) => workers.map((w) => w.disciplines))
                .reduce((prev, next) => [...prev, ...next], []);

            const uniqueDisciplines = new Set(disciplineSources.reduce((prev, next) => [...prev, ...next], []));

            disciplines = Array.from(uniqueDisciplines).map((name) => ({ name }));
            state.scheduleActivityPlanningFilter = {
                ...state.scheduleActivityPlanningFilter,
                disciplines,
            };

            return { ...state };
        }
        case ScheduleActivityPlanningActionTypes.ResetITRFilter: {
            return {
                ...state,
                scheduleActivityPlanningFilter: {
                    ...state.scheduleActivityPlanningFilter,
                    columnITRSubsystems: null,
                    columnITRSubsystemNames: null,
                    columnITRTagNumbers: null,
                    columnITRTagTypes: null,
                    columnITRDisciplines: null,
                    columnITRNumbers: null,
                    columnIsItrInCurrentWeek: null,
                    columnITRDescriptions: null,
                    columnITRContractors: null,
                    columnITRDocTypes: null,
                    columnITRStatuses: null,
                    columnITRBarcodes: null,
                    columnITRComments: null,
                    columnITRStatusDate: null,
                    columnITRPreCommStart: null,
                    columnITRPreCommFinish: null,
                    columnITRActivityIds: null,
                    columnITRActivityNames: null,
                    columnITRAdditionToPlan: null,
                },
            };
        }
        case ScheduleActivityPlanningActionTypes.FilterPropertyUpdate: {
            let newState = {};
            if (
                action.payload.filter.weeklyPlanType !== undefined &&
                state.scheduleActivityPlanningFilter.weeklyPlanType !== action.payload.filter.weeklyPlanType
            ) {
                newState = {
                    ...state,
                    subsystemsWithScheduleActivities: [],
                    subsystemsWithScheduleActivitiesTotalCount: 0,
                    scheduleActivityPlanningFilter: {
                        ...new ScheduleActivityPlanningFilter(),
                        hasNoITRsToGo: action.payload.filter.weeklyPlanType === WeeklyPlanType.weekly ? false : null,
                        selectedTabIndex: state.scheduleActivityPlanningFilter.selectedTabIndex,
                    },
                };
            }
            newState = {
                ...newState,
                scheduleActivityPlanningFilter: {
                    ...state.scheduleActivityPlanningFilter,
                    ...action.payload.filter,
                },
            };

            return { ...state, ...newState };
        }
        case ScheduleActivityPlanningActionTypes.ShowHideColumnsUpdate: {
            return {
                ...state,
                subsystemsNotInWeekWithScheduleActivitiesShowHideColumns: {
                    ...action.payload.showHideColumns,
                },
            };
        }
        case ScheduleActivityPlanningActionTypes.PaginationUpdate: {
            return {
                ...state,
                subsystemsNotInWeekWithScheduleActivitiesPagination: {
                    page: action.payload.page,
                    take: action.payload.take,
                },
            };
        }
        case ScheduleActivityPlanningActionTypes.ExpansionNotInWeekPanelToggle: {
            return {
                ...state,
                subsystemsNotInWeekWithScheduleActivitiesPanelExpanded: action.notInWeekExpanded,
            };
        }

        case ScheduleActivityPlanningActionTypes.ExpansionInWeekPanelToggle: {
            return {
                ...state,
                subsystemsInWeekWithScheduleActivitiesPanelExpanded: action.inWeekExpanded,
            };
        }

        case ScheduleActivityPlanningActionTypes.SetMultiPanels: {
            return {
                ...state,
                multiPanels: action.multiPanels,
            };
        }

        case ScheduleActivityPlanningActionTypes.FillFilterPropertiesBasedonAreaSuccess: {
            return {
                ...state,
                scheduleActivityPlanningFilter: {
                    ...state.scheduleActivityPlanningFilter,
                    disciplines: action.payload.area?.disciplines
                        ? action.payload.area?.disciplines.map((d) => ({ name: d.id }))
                        : [],
                    nbDisciplineLeads: action.payload.area?.nbDisciplineLead
                        ? [
                              {
                                  name: action.payload.area.nbDisciplineLead,
                                  disciplines: [],
                              },
                          ]
                        : [],
                    nbEngineers: action.payload.area?.nbEngineer
                        ? [
                              {
                                  name: action.payload.area.nbEngineer,
                                  disciplines: [],
                              },
                          ]
                        : [],
                    tcoAreaCoordinators: action.payload.area?.tcoAreaCoordinator
                        ? [
                              {
                                  name: action.payload.area.tcoAreaCoordinator,
                                  disciplines: [],
                              },
                          ]
                        : [],
                },
            };
        }
        case ScheduleActivityPlanningActionTypes.ExportToExcelRequest: {
            return {
                ...state,
                subsystemsWithScheduleActivitiesIsLoading: true,
            };
        }
        case ScheduleActivityPlanningActionTypes.ExportToExcelSuccess: {
            return {
                ...state,
                subsystemsWithScheduleActivitiesIsLoading: false,
            };
        }
        case ScheduleActivityPlanningActionTypes.ExportToExcelError: {
            return {
                ...state,
                subsystemsWithScheduleActivitiesIsLoading: false,
            };
        }
        case ScheduleActivityPlanningActionTypes.ClearFilters: {
            return {
                ...state,
                scheduleActivityPlanningFilter: {
                    ...new ScheduleActivityPlanningFilter(),
                    weekStart: state.scheduleActivityPlanningFilter.weekStart,
                    weeklyPlanType: state.scheduleActivityPlanningFilter.weeklyPlanType,
                    selectedTabIndex: state.scheduleActivityPlanningFilter.selectedTabIndex,
                    itrCommittmentReliabilityScope: null,
                },
            };
        }
        case ScheduleActivityPlanningActionTypes.ResetPrint: {
            return {
                ...state,
                printData: [],
                notInWeekPrintData: [],
            };
        }
        case ScheduleActivityPlanningActionTypes.SetSubsystemsUpdate: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ScheduleActivityPlanningActionTypes.SetSubsystemsUpdateSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ScheduleActivityPlanningActionTypes.SetSubsystemsUpdateError: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case ScheduleActivityPlanningActionTypes.WeekSummaryDataRequest: {
            return {
                ...state,
                weekSummary: new WeekSummaryDto(),
            };
        }
        case ScheduleActivityPlanningActionTypes.WeekSummaryDataRequestSuccess: {
            return {
                ...state,
                weekSummary: action.weekSummary,
            };
        }
        case ScheduleActivityPlanningActionTypes.WeekSummaryDataRequestError: {
            return {
                ...state,
            };
        }
        case ScheduleActivityPlanningActionTypes.RemoveCommentSuccess: {
            const subsystemNotInWeek = state.subsystemsNotInWeekWithScheduleActivities.find(
                (sub) => sub.subsystem === action.payload.subsystem
            );

            if (subsystemNotInWeek) {
                subsystemNotInWeek.activities.find(
                    (act) =>
                        act.activityId === action.payload.activityId &&
                        act.activityDiscipline === action.payload.activityDiscipline
                ).comment = action.payload.lastComment.description;
            }

            const subsystem = state.subsystemsWithScheduleActivities.find(
                (sub) => sub.subsystem === action.payload.subsystem
            );

            if (subsystem) {
                subsystem.activities.find(
                    (act) =>
                        act.activityId === action.payload.activityId &&
                        act.activityDiscipline === action.payload.activityDiscipline
                ).comment = action.payload.lastComment.description;
            }

            return {
                ...state,
            };
        }
        case ScheduleActivityPlanningActionTypes.UpdateScrollTop: {
            return {
                ...state,
                scrollTop: action.payload,
            };
        }
        default:
            return state;
    }
}
