import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { of, Subject, timer } from 'rxjs';
import { debounce, exhaustMap, filter, map, scan, startWith, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Gooc, RFO, StagedStartUpPriority, WorkAreaFilter } from 'src/app/store/common.model';
import * as _ from 'lodash';

type FilterTypes = 'rfo' | 'gooc' | 'subsystem' | 'priority';

interface Subsystem {
    id: string;
    Selected: boolean;
}

class SubsystemValueSet {
    content: Subsystem[] = [];

    constructor(initialSet = []) {
        this.content = initialSet;
    }

    add(sub: Subsystem) {
        if (!this.content.some(s => this.equals(s, sub))) {
            this.content.push(sub);
        }
    }

    remove(sub: Subsystem) {
        const index = this.content.findIndex(s => this.equals(s, sub));
        if (index !== -1) {
            this.content.splice(index, 1);
        }
    }

    has(sub: Subsystem) {
        return this.content.some(s => this.equals(s, sub));
    }

    [Symbol.iterator]() {
        return this.content[Symbol.iterator]();
    }

    equals(one: Subsystem, another: Subsystem) {
        return one.id === another.id;
    }
}

@Component({
    selector: 'app-subsystem-selector',
    templateUrl: './subsystem-selector.component.html',
    styleUrls: ['./subsystem-selector.component.scss'],
})
export class SubsystemSelectorComponent extends BaseComponent implements OnInit {
    areAllAvailableSubsystemsChecked = false;
    areAllSelectedSubsystemsChecked = false;


    @Input() set setSelectedSubsystems(value: any[]) {
        this.selectedSubsystems = [...value];
        this.selectedSubsystemsInitial = [...value];
    }
    @Input() showButtons: boolean = false;
    @Input() preventClosing: boolean = false;

    get setSelectedSubsystems(): any[] {
        return [...this.selectedSubsystems];
    }

    constructor(
        private toastService: ToastService,
        private lookupService: LookupService,
        private changeDetectorRef: ChangeDetectorRef,
        private formService: FormService,
        @Optional() private dialogRef: MatDialogRef<SubsystemSelectorComponent>
    ) {
        super();
        this.subsystemSearchFilterForm = this.formService.createSimpleForm(new WorkAreaFilter());
        this.subsystemSelectedSearchFilterForm = this.formService.createSimpleForm(new WorkAreaFilter());
    }

    selectedSubsystems: any[] = [];

    @Input() setInput: EventEmitter<any>;
    @Input() searchFunc = (takeCount = 10, page = 0) => {
        return this.lookupService
            .searchSubsystemsByWorkAreaFilter(takeCount, page, this.subsystemSearchFilterForm.value)
            .pipe(map((subs: any[]) => subs));
    }
    @Output() selectedSubsystemsChanged: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() subsystemFilterChanged: EventEmitter<any> = new EventEmitter<any>();
    displayedColumns = ['selected', 'subsystem', 'subsystemName'];
    availableSubsystems: any[] = [];
    selectedSubsystemsInitial: any[] = [];
    itemsSelected = new SubsystemValueSet();
    itemsSelectedFromSubsystemSelected = new SubsystemValueSet();
    isLoading = true;
    isLoadingSelected = true;
    nextPage$ = new Subject();
    nextPageSelectedSubsystems$ = new Subject();
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    subsystemSearchFilterForm: UntypedFormGroup;
    subsystemSelectedSearchFilterForm: UntypedFormGroup;

    isAvailableGOOCFilter = false;
    isAvailableRFOFilter = false;
    isAvailableSubsystemFilter = true;
    isAvailablePriorityFilter = false;

    isSelectedGOOCFilter = false;
    isSelectedRFOFilter = false;
    isSelectedSubsystemFilter = true;
    isSelectedPriorityFilter = false;

    ngOnInit(): void {
        if (this.setInput) {
            this.setInput.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.applyAvailableSubsystemFilters();
                this.applySubsystemSelectedFilters();
            });
        }

        this.subsystemSelectedSearchFilterForm.valueChanges.pipe(
            takeWhile(() => this.isAlive)
        ).subscribe(() => this.areAllSelectedSubsystemsChecked = false);

        this.subsystemSearchFilterForm.valueChanges.pipe(
            takeWhile(() => this.isAlive)
        ).subscribe(() => {
            this.areAllAvailableSubsystemsChecked = false;
        });


        this.subsystemSearchFilterForm.valueChanges
            .pipe(
                takeWhile(() => this.isAlive),
                tap(data => {
                    this.isLoading = true;
                    this.changeDetectorRef.detectChanges();
                    this.subsystemFilterChanged.emit(data);
                }),
                debounce(() => timer(this.isAvailableSubsystemFilter ? 800 : 0)),
                startWith(''),
                filter(() => this.searchFunc !== undefined),
                switchMap(() => this.applyInfiniteScroll())
            )
            .subscribe(
                (data) => {
                    this.populateAvailableSubystemsData(data);
                },
                () => {
                    this.toastService.Error('Error occurred while searching. Please contact Program Administrator.');
                }
            );

        this.subsystemSelectedSearchFilterForm.valueChanges
            .pipe(
                takeWhile(() => this.isAlive),
                tap(() => {
                    this.isLoadingSelected = true;
                    this.changeDetectorRef.detectChanges();
                }),
                debounce(() => timer(this.isSelectedSubsystemFilter ? 800 : 0)),
                startWith(''),
                filter(() => this.searchFuncSubsystemSelected !== undefined),
                switchMap(() => this.applyInfiniteScrollForSubsystemSelected())
            )
            .subscribe((data) => {
                    data.filter((r) => this.itemsSelectedFromSubsystemSelected.has(r)).forEach((r) => (r.Selected = true));
                    this.selectedSubsystems = data;
                    this.isLoadingSelected = false;
                    if (this.isAlive) {
                        this.changeDetectorRef.detectChanges();
                    }
                },
                () => {
                    this.toastService.Error('Error occurred while searching. Please contact Program Administrator.');
                }
            );
    }

    applyAvailableSubsystemFilters() {
        if (!this.isAvailableGOOCFilter) {
            this.subsystemSearchFilterForm.controls.goocs.setValue([], { emitEvent: false });
        }
        if (!this.isAvailableRFOFilter) {
            this.subsystemSearchFilterForm.controls.rfo.setValue([], { emitEvent: false });
        }
        if (!this.isAvailablePriorityFilter) {
            this.subsystemSearchFilterForm.controls.priority.setValue([], { emitEvent: false });
        }
        this.subsystemSearchFilterForm.setValue(this.subsystemSearchFilterForm.value);
    }

    applySubsystemSelectedFilters() {
        if (!this.isSelectedGOOCFilter) {
            this.subsystemSelectedSearchFilterForm.controls.goocs.setValue([], { emitEvent: false });
        }
        if (!this.isSelectedRFOFilter) {
            this.subsystemSelectedSearchFilterForm.controls.rfo.setValue([], { emitEvent: false });
        }
        if (!this.isSelectedPriorityFilter) {
            this.subsystemSelectedSearchFilterForm.controls.priority.setValue([], { emitEvent: false });
        }
        this.subsystemSelectedSearchFilterForm.setValue(this.subsystemSelectedSearchFilterForm.value);
    }

    populateAvailableSubystemsData(data) {
        data.filter(sub => this.itemsSelected.has(sub)).forEach(sub => sub.Selected = true);

        this.availableSubsystems = data;
        this.isLoading = false;
        if (this.isAlive) {
            this.changeDetectorRef.detectChanges();
        }
    }

    getGoocs = (search = '', takeCount = 10, page = 0) => {
        return this.lookupService.searchGoocs(search, takeCount, page, []).pipe(map((goocs: Gooc[]) => goocs));
    }

    getRFOs = (search = '', takeCount = 10, page = 0) => {
        return this.lookupService.searchRFOs(search, takeCount, page, []).pipe(map((rfos: RFO[]) => rfos));
    }

    getPriorities = (search = '', takeCount = 10, page = 0) => {
        return this.lookupService.searchStagedStartUpPriorities(search, takeCount, page, []).pipe(map((item: StagedStartUpPriority[]) => item));
    }

    changeFilter(type: FilterTypes) {
        this.isAvailableGOOCFilter = type === 'gooc';
        this.isAvailableRFOFilter = type === 'rfo';
        this.isAvailableSubsystemFilter = type === 'subsystem';
        this.isAvailablePriorityFilter = type === 'priority';
        this.applyAvailableSubsystemFilters();
    }

    changeFilterSubsystemSelected(type: FilterTypes) {
        this.isSelectedGOOCFilter = type === 'gooc';
        this.isSelectedRFOFilter = type === 'rfo';
        this.isSelectedSubsystemFilter = type === 'subsystem';
        this.isSelectedPriorityFilter = type === 'priority';
        this.applySubsystemSelectedFilters();
    }

    searchFuncSubsystemSelected = (takeCount = 10, page = 0) => {
        let result = [...this.selectedSubsystemsInitial];

        if (
            this.subsystemSelectedSearchFilterForm.controls.subsystem.value !== ''
        ) {
            result = result.filter(
                (s) =>
                    s &&
                    s.id
                        .toLowerCase()
                        .indexOf(this.subsystemSelectedSearchFilterForm.controls.subsystem.value.toLowerCase()) > -1
            );
        }
        if (
            this.isSelectedRFOFilter &&
            this.subsystemSelectedSearchFilterForm.controls.rfo.value.length > 0
        ) {
            result = result.filter(
                (s) =>
                    s &&
                    s.rfo &&
                    this.subsystemSelectedSearchFilterForm.controls.rfo.value.map((m) => m.name).indexOf(s.rfo) > -1
            );
        } else if (
            this.isSelectedGOOCFilter &&
            this.subsystemSelectedSearchFilterForm.controls.goocs.value.length > 0
        ) {
            result = result.filter(
                (s) =>
                    s &&
                    s.gooc &&
                    this.subsystemSelectedSearchFilterForm.controls.goocs.value.map((m) => m.no).indexOf(s.gooc) > -1
            );
        }else if (
            this.isSelectedPriorityFilter &&
            this.subsystemSelectedSearchFilterForm.controls.priority.value.length > 0
        ) {
            result = result.filter(
                (s) =>
                    s &&
                    s.priority &&
                    this.subsystemSelectedSearchFilterForm.controls.priority.value.map((m) => m.priority).indexOf(s.priority) > -1
            );
        }
        
        return of(result.slice(page * takeCount, page * takeCount + takeCount));
    }

    moveSelected(data: SubsystemValueSet = this.itemsSelected) {
        this.isLoading = true;
        this.areAllAvailableSubsystemsChecked = false;
        this.changeDetectorRef.detectChanges();
        const newItems = [...data];
        const itemMapped = newItems.map((r) => {
            return { ...r, Selected: false, };
        });
        this.selectedSubsystems = this.selectedSubsystems
            .concat(itemMapped)
            .filter((thing, i, arr) => {
                return arr.indexOf(arr.find((t) => t.id === thing.id)) === i;
            })
            .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));
            
            this.selectedSubsystemsInitial = [...this.selectedSubsystemsInitial, ...itemMapped]
            if (!this.showButtons) {
                this.selectedSubsystemsChanged.emit(this.selectedSubsystemsInitial);
            }

        this.itemsSelected = new SubsystemValueSet();
        this.availableSubsystems.forEach((r) => (r.Selected = false));
        this.availableSubsystems = this.availableSubsystems.filter(
            (r) => this.selectedSubsystems.findIndex((s) => s.id === r.id) === -1
        );
        if (
            (this.isSelectedSubsystemFilter &&
                this.subsystemSelectedSearchFilterForm.controls.subsystem.value !== '') ||
            (this.isSelectedGOOCFilter &&
                this.subsystemSelectedSearchFilterForm.controls.goocs.value.length > 0) ||
            (this.isSelectedRFOFilter &&
                this.subsystemSelectedSearchFilterForm.controls.rfo.value.length > 0) ||
            (this.isSelectedPriorityFilter &&
                    this.subsystemSelectedSearchFilterForm.controls.priority.value.length > 0)
        ) {
            this.applySubsystemSelectedFilters();
        }
        this.applyAvailableSubsystemFilters();
        this.isLoading = false;
    }

    reverseSelected(data: SubsystemValueSet = this.itemsSelectedFromSubsystemSelected) {
        this.isLoading = true;
        this.areAllSelectedSubsystemsChecked = false;
        this.changeDetectorRef.detectChanges();
        const reverseItems = [...data];
        const itemMapped = reverseItems.map((r) => {
            return { ...r, Selected: false, };
        });
        this.availableSubsystems = this.availableSubsystems
            .concat(itemMapped)
            .filter((thing, i, arr) => {
                return arr.indexOf(arr.find((t) => t.id === thing.id)) === i;
            })
            .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));
        this.selectedSubsystems.forEach((r) => (r.Selected = false));
        this.selectedSubsystems = this.selectedSubsystems.filter(
            (r) => this.availableSubsystems.findIndex((s) => s.id === r.id) === -1
        );
        if (!this.showButtons) {
            this.selectedSubsystemsInitial = this.selectedSubsystemsInitial.filter((s) => !data.has(s));
            this.selectedSubsystemsChanged.emit(this.selectedSubsystemsInitial);
        } else {
            this.selectedSubsystemsInitial = _.differenceBy(this.selectedSubsystemsInitial, itemMapped, 'id');
        }
        this.itemsSelectedFromSubsystemSelected = new SubsystemValueSet();
        if (
            (this.isAvailableSubsystemFilter && this.subsystemSearchFilterForm.controls.subsystem.value !== '') ||
            (this.isAvailableGOOCFilter && this.subsystemSearchFilterForm.controls.goocs.value.length > 0) ||
            (this.isAvailableRFOFilter && this.subsystemSearchFilterForm.controls.rfo.value.length > 0) || 
            (this.isAvailablePriorityFilter && this.subsystemSearchFilterForm.controls.priority.value.length > 0)
        ) {
            this.applyAvailableSubsystemFilters();
        }
        this.isLoading = false;
    }

    onTableScroll(e) {
        const tableViewHeight = e.target.offsetHeight;
        const tableScrollHeight = e.target.scrollHeight;
        const scrollLocation = e.target.scrollTop;
        const buffer = 200;
        const limit = tableScrollHeight - tableViewHeight - buffer;
        if (scrollLocation > limit) {
            this.nextPage$.next();
        }
    }

    onTableScrollSelected(e) {
        const tableViewHeight = e.target.offsetHeight;
        const tableScrollHeight = e.target.scrollHeight;
        const scrollLocation = e.target.scrollTop;
        const buffer = 200;
        const limit = tableScrollHeight - tableViewHeight - buffer;
        if (scrollLocation > limit) {
            this.nextPageSelectedSubsystems$.next();
        }
    }

    applyInfiniteScroll() {
        let currentPage = 0;
        return this.nextPage$.pipe(
            startWith(currentPage),
            exhaustMap(() => this.searchFunc(10, currentPage)),
            takeWhile((newItems: any[]) => {
                if (newItems.length === 0 && currentPage === 0) {
                    this.isLoading = false;
                    this.availableSubsystems = [];
                    this.changeDetectorRef.detectChanges();
                }
                return newItems.length > 0;
            }),
            scan((allItems, newItems) => {
                if (currentPage === 0) {
                    allItems = [];
                }
                const result = allItems.concat(newItems);
                const filteredResult = result.filter(
                    (r) => this.selectedSubsystems.findIndex((s) => s.id === r.id) === -1
                );
                if (newItems.length !== 0 && filteredResult.length < 9) {
                    setTimeout(() => this.nextPage$.next(), 0);
                }
                return filteredResult;
            }, []),
            tap(() => currentPage++)
        );
    }

    applyInfiniteScrollForSubsystemSelected() {
        let currentPage = 0;
        return this.nextPageSelectedSubsystems$.pipe(
            startWith(currentPage),
            exhaustMap(() => this.searchFuncSubsystemSelected(10, currentPage)),
            takeWhile((newItems: any[]) => {
                if (newItems.length === 0 && currentPage === 0) {
                    this.isLoadingSelected = false;
                    this.selectedSubsystems = [];
                    this.changeDetectorRef.detectChanges();
                }
                return newItems.length > 0;
            }),
            scan((allItems, newItems) => {
                if (currentPage === 0) {
                    allItems = [];
                }
                return allItems.concat(newItems);
            }, []),
            tap(() => currentPage++)
        );
    }

    onSelect = (item: any, setTo: boolean = !item.Selected) => {
        if (setTo) {
            this.itemsSelected.add(item);
            item.Selected = true;
            this.availableSubsystems.filter((r) => this.compareItems(r, item)).forEach((r) => (r.Selected = true));
        } else {
            this.itemsSelected.remove(item);
            item.Selected = false;
            this.areAllAvailableSubsystemsChecked = false;
            this.availableSubsystems.filter((r) => this.compareItems(r, item)).forEach((r) => (r.Selected = false));
        }
    }

    onSelectSubsystemSelected = (item: any, setTo: boolean = !item.Selected) => {
        if (setTo) {
            this.itemsSelectedFromSubsystemSelected.add(item);
            item.Selected = true;
            this.selectedSubsystems.filter((r) => this.compareItems(r, item)).forEach((r) => (r.Selected = true));
        } else {
            this.itemsSelectedFromSubsystemSelected.remove(item);
            item.Selected = false;
            this.selectedSubsystems.filter((r) => this.compareItems(r, item)).forEach((r) => (r.Selected = false));
            this.areAllSelectedSubsystemsChecked = false;
        }
    }

    private compareItems(i1: any, i2: any) {
        return i1.id === i2.id;
    }

    triggerCheckAllAvailableSubsystems() {
        this.isLoading = true;
        if (!this.areAllAvailableSubsystemsChecked) {
            this.areAllAvailableSubsystemsChecked = true;
            this.searchFunc(1000000, 0)
                .pipe(take(1))
                .subscribe(allItems => {
                    allItems.forEach(i => this.onSelect(i, true));
                    this.isLoading = false;
                });
        } else {
            this.areAllAvailableSubsystemsChecked = false;
            this.searchFunc(1000000, 0)
                .pipe(take(1))
                .subscribe(allItems => {
                    allItems.forEach(i => this.onSelect(i, false));
                    this.isLoading = false;
                });
        }
    }

    triggerCheckAllSelectedSubsystems() {
        this.isLoading = true;
        this.changeDetectorRef.detectChanges();
        if (this.areAllSelectedSubsystemsChecked) {
            this.selectedSubsystems.filter(sub => sub.Selected).forEach(sub => this.onSelectSubsystemSelected(sub));
            this.areAllSelectedSubsystemsChecked = false;
        } else {
            this.selectedSubsystems.filter(sub => !sub.Selected).forEach(sub => this.onSelectSubsystemSelected(sub));
            this.areAllSelectedSubsystemsChecked = true;
        }
        this.isLoading = false;
    }

    close() {
        this.dialogRef?.close();
    }

    onSubmit() {
        this.selectedSubsystemsChanged.emit(this.selectedSubsystemsInitial);
        if (!this.preventClosing) {
            this.dialogRef?.close();
        }
    }
}
