import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    ITRActionTypes,
    ITRUploadLogSuccess,
    ITRUploadLogError,
    ITRSetStatusAndStartDate,
    ITRClearInProgressSpinner,
    ITRTemplateFileRequest,
    ITRTemplateFileSuccess,
    ITRTemplateFileError,
    ITRValidateDeltaSuccess,
    ITRValidateDeltaError,
    ITRDownloadOutputDataRequest,
    ITRDownloadOutputDataSuccess,
    ITRDownloadOutputDataError,
    ITRValidateButtonStateSuccess,
    ITRValidateButtonStateError,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class ITREffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(ITRActionTypes.ITRUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['ITR']).pipe(
                map((uploadLogData) => new ITRUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting logs for ITR. Please contact Program Administrator.'
                    );
                    return of(new ITRUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<ITRUploadLogSuccess>(ITRActionTypes.ITRUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.itrState.uploadLogStatus),
            this.store.select((store) => store.itrState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new ITRSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<ITRTemplateFileRequest>(ITRActionTypes.ITRTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new ITRTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting template for ITR. Please contact Program Administrator.'
                    );
                    return of(new ITRTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<ITRTemplateFileSuccess>(ITRActionTypes.ITRTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'SCMT ITR.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<ITRDownloadOutputDataRequest>(ITRActionTypes.ITRDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new ITRDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting output result for ITR. Please contact Program Administrator.'
                    );
                    return of(new ITRDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<ITRDownloadOutputDataSuccess>(ITRActionTypes.ITRDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(ITRActionTypes.ITRValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateITRDelta().pipe(
                map(() => new ITRValidateDeltaSuccess()),
                catchError((error) => {
                    if (error?.error === 'AutomaticUploadPipelineInProgress') {
                        this.toastService.Info(
                            'Cannot validate the data while automatic upload pipeline is in progress.'
                        );
                    } else {
                        this.toastService.Error(
                            'Error occurred while validating and pushing ITR. Please contact Program Administrator.'
                        );
                    }
                    return of(new ITRValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(ITRActionTypes.ITRSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.itrState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new ITRClearInProgressSpinner()])
    );

    @Effect()
    setValidateButtonEnabled$ = this.actions$.pipe(
        ofType(ITRActionTypes.ITRUploadLogSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('ITR').pipe(
                map((response: any) => new ITRValidateButtonStateSuccess(response)),
                catchError((error) => of(new ITRValidateButtonStateError(error)))
            )
        )
    );
}
