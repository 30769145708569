<main class="main">
    <div class="import-container">
        <h4>Automatic Status Update - Azure Data Factory Pipeline</h4>
        <div class="import-subtitle">
            The Automatic status update pipeline checks for completed PLI, ITR and Exceptions file on McPlus scmt Blob and Updates the status of Respective completed items in SCMT Database.
        </div>
    </div>
    <div class="upload-log-container">
        <h4>Import history</h4>
        <table mat-table [dataSource]="logs">
            <ng-container matColumnDef="runId">
                <th mat-header-cell *matHeaderCellDef>Run Id</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.runId }}
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="getImportClassName(element.status)" class="pipeline-status">
                        {{ element.status }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Start date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.startDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.endDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef>Message</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.message"></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedUploadLogDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedUploadLogDataColumns"></tr>
        </table>
        <div *ngIf="isLoading$ | async" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading$ | async"> </app-loading-indicator>
</main>
