import { Component, Inject, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-history-popup',
    templateUrl: './history-popup.component.html',
    styleUrls: ['./history-popup.component.scss'],
})
export class HistoryPopupComponent extends BaseComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = ['status', 'date', 'user', 'action'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    resultsLength = 0;
    pageSize = 5;
    sortBy = 'date';
    direction = 'asc';
    dataSource = new MatTableDataSource();

    constructor(public dialogRef: MatDialogRef<HistoryPopupComponent>, @Inject(MAT_DIALOG_DATA) data) {
        super();
        this.dataSource = new MatTableDataSource(data.history);
        this.resultsLength = this.dataSource.data.length;
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'user':
                    return item[property].name;
                default:
                    return item[property];
            }
        };
    }
}
