import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    RedlineActionTypes,
    RedlineUploadLogSuccess,
    RedlineUploadLogError,
    RedlineSetStatusAndStartDate,
    RedlineClearInProgressSpinner,
    RedlineTemplateFileRequest,
    RedlineTemplateFileSuccess,
    RedlineTemplateFileError,
    RedlineValidateButtonStateSuccess,
    RedlineValidateButtonStateError,
    RedlineDownloadOutputDataRequest,
    RedlineDownloadOutputDataSuccess,
    RedlineDownloadOutputDataError,
    RedlineValidateDeltaSuccess,
    RedlineValidateDeltaError,
    RedlineValidateButtonStateRequest,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class RedlineEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(RedlineActionTypes.RedlineUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['RedlineRegister']).pipe(
                map((uploadLogData) => new RedlineUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new RedlineUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<RedlineUploadLogSuccess>(RedlineActionTypes.RedlineUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.redlineState.uploadLogStatus),
            this.store.select((store) => store.redlineState.uploadLogStartDate)
        ),
        filter(([{ payload }, status, startDate]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return logs[0] && (status !== logs[0].status || startDate !== logs[0].startDate);
        }),
        map(([{ payload }]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return new RedlineSetStatusAndStartDate({ status: logs[0].status, startDate: logs[0].startDate });
        })
    );

    @Effect()
    invokeDeltaRequest$ = this.actions$.pipe(
        ofType(RedlineActionTypes.RedlineSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.redlineState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        switchMap(() => [new RedlineValidateButtonStateRequest(), new RedlineClearInProgressSpinner()])
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<RedlineTemplateFileRequest>(RedlineActionTypes.RedlineTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new RedlineTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Redline Register.csv file. Please contact Program Administrator'
                    );
                    return of(new RedlineTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<RedlineTemplateFileSuccess>(RedlineActionTypes.RedlineTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'Redline Register.csv');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<RedlineDownloadOutputDataRequest>(RedlineActionTypes.RedlineDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new RedlineDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new RedlineDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<RedlineDownloadOutputDataSuccess>(RedlineActionTypes.RedlineDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(RedlineActionTypes.RedlineValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateRedlineDelta().pipe(
                map(() => new RedlineValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing Redline data to live database. Please contact Program Administrator'
                    );
                    return of(new RedlineValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(RedlineActionTypes.RedlineValidateButtonStateRequest),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('RedlineRegister').pipe(
                map((response: any) => new RedlineValidateButtonStateSuccess(response)),
                catchError((error) => of(new RedlineValidateButtonStateError(error)))
            )
        )
    );
}
