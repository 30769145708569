import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, OrderDirection } from '../../common.model';

export interface ActivityBarcodeState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deleteData: ActivityBarcodeDeletePagination;
    isDeleteDataLoading: boolean;
    deleteFilter: ActivityBarcodeDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class ActivityBarcodeDeleteDTO {
    deleteState: boolean;
    activityId: string;
    barcode: string;
}

export class ActivityBarcodeDeleteFilter {
    page = 0;
    take = 10;
    sortBy = 'barcode';
    direction: OrderDirection = OrderDirection.Desc;
    columnActivityIds: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
}
export class ActivityBarcodeDeletePagination {
    items: ActivityBarcodeDeleteDTO[] = [];
    totalCount = 0;
}
