import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../../base.component';
import { LeakTestPackPriorityHistoryRequest } from 'src/app/store/ltp-priority-history/actions';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-ltp-priority-history',
  templateUrl: './ltp-priority-history.component.html',
  styleUrls: ['./ltp-priority-history.component.scss']
})
export class LtpPriorityHistoryComponent extends BaseComponent implements OnInit {
  displayedColumns: string[] = ['oldPriority', 'newPriority', 'updatedDate', 'userFullName'];
  dataSource = new MatTableDataSource();
  isLoading = false;
  ltpPriorityHistory$ = this.store.select((state) => state.leakTestPackPriorityHistoryState.data);
  isLoading$ = this.store.select((state) => state.leakTestPackPriorityHistoryState.isLoading);
  leakTestPackTrackerNo: string;
  
  constructor(public dialogRef: MatDialogRef<LtpPriorityHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private store: Store<ApplicationState>) {
    super();

    this.leakTestPackTrackerNo = data.leakTestPackTrackerNo
  }
  ngOnInit(): void {

    this.ltpPriorityHistory$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data)
      });

    this.isLoading$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((loading) => this.isLoading = loading);

    this.store.dispatch(
      new LeakTestPackPriorityHistoryRequest(
        {
          leakTestPackTrackerNo: this.leakTestPackTrackerNo
        }));
  }

  public onCancel(): void {
    this.dialogRef.close();
  } 

}
