import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../base.component';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { catchError, finalize, take, takeWhile } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { CTAAttachment, CTARegisterDTO, CTARegisterFilter } from 'src/app/store/cta-register/model';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import {
    CTADeleteRequest,
    CTARegisterDownloadDataRequest,
    CTARegisterFilterPropertyUpdate,
    CTARegisterFilterRequest,
} from 'src/app/store/cta-register/actions';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { CTAFormComponent } from '../admin-section/manual-upload/cta/cta-form.component';
import { of } from 'rxjs';
import { Dictionary } from 'typescript-collections';
import { ToastService } from 'src/app/services/shared/toast.service';
import { CTAService } from 'src/app/services/api/webapi-services/cta.service';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';

@Component({
    selector: 'app-cta-register',
    templateUrl: './cta-register.component.html',
    styleUrls: ['./cta-register.component.scss'],
})
export class CTARegisterComponent extends BaseComponent implements OnInit {
    isLoading = false;
    data: CTARegisterDTO[] = [];
    resultsLength = 0;
    pageSize = 25;
    ctaRegisterGridData$ = this.store.select((state) => state.ctaRegisterState.dataPagination);
    ctaRegisterGridLoader$ = this.store.select((state) => state.ctaRegisterState.isLoading);
    sortBy$ = this.store.select((state) => state.ctaRegisterState.filter.sortBy);
    sortDirection$ = this.store.select((state) => state.ctaRegisterState.filter.direction);
    ctaRegisterFilter$ = this.store.select((state) => state.ctaRegisterState.filter);
    currentPage$ = this.store.select((state) => state.ctaRegisterState.filter.page);
    columnFileName$ = this.store.select((state) => state.ctaRegisterState.filter.columnFileName);
    columnFileName: CheckListColumn = null;
    columnArea$ = this.store.select((state) => state.ctaRegisterState.filter.columnArea);
    columnArea: CheckListColumn = null;
    columnDescription$ = this.store.select((state) => state.ctaRegisterState.filter.columnDescription);
    columnDescription: CheckListColumn = null;
    columnDate$ = this.store.select((state) => state.ctaRegisterState.filter.columnDate);
    columnDate: CalendarColumn = null;
    sortBy = 'fileName';
    direction: OrderDirection = OrderDirection.Desc;
    displayedColumns = ['edit', 'remove', 'fileName', 'description', 'date', 'area', 'attachments'];
    filterForm: UntypedFormGroup;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    canEditCTA = false;
    uploadedAttachmentsActionInProgress = new Dictionary<string, boolean>();

    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private roleService: RoleService,
        private popupService: PopupService,
        private toastService: ToastService,
        private ctaService: CTAService
    ) {
        super();
        this.filterForm = this.formSvc.createSimpleForm(new CTARegisterFilter());
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.canEditCTA = this.roleService.isInAnyRole([Constants.applicableGroups.Admin]);
    }

    ngOnInit() {
        this.watchFormChanges();
        this.ctaRegisterGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => {
            this.sortBy = sortBy;
        });

        this.sortDirection$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => {
            this.direction = direction;
        });

        this.currentPage$.pipe(take(1)).subscribe((currentPage) => (this.paginator.pageIndex = currentPage));

        this.columnFileName$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnFileName = data));
        this.columnDescription$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnDescription = data));
        this.columnArea$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnArea = data));
        this.columnDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDate = data));

        this.ctaRegisterGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.ctaRegisterFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.paginator.pageIndex = filter.page;
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new CTARegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new CTARegisterFilterRequest());
        });

        this.store.dispatch(new CTARegisterFilterRequest());
    }

    private getLatestFilterData(): CTARegisterFilter {
        let filter: CTARegisterFilter;
        this.store.pipe(select((x) => x.ctaRegisterState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getFileNames = (search = '', take = 10, page = 0) =>
        this.lookupService.getFileNames(search, take, page, this.getLatestFilterData());

    getDescriptions = (search = '', take = 10, page = 0) =>
        this.lookupService.getDescriptions(search, take, page, this.getLatestFilterData());

    getAreas = (search = '', take = 10, page = 0) =>
        this.lookupService.getAreasForFilter(search, take, page, this.getLatestFilterData());

    watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new CTARegisterFilterPropertyUpdate({ key, value }));
            });
        }
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 620, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new CTARegisterFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupService.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            },
            345,
            580)
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new CTARegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new CTARegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'fileName', direction: OrderDirection.Desc },
                })
            );
        }
    }

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new CTARegisterFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(
            new CTARegisterFilterPropertyUpdate({
                key: 'take',
                value: this.pageSize,
            })
        );
        this.store.dispatch(new CTARegisterFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new CTARegisterFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new CTARegisterFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new CTARegisterFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    downloadData(filePath: string) {
        this.store.dispatch(new CTARegisterDownloadDataRequest(filePath));
    }

    remove(element: CTARegisterDTO) {
        this.popupService
            .openPopup(new ConfirmDialogPopupSettings({ title: 'Confirm action', text: 'Do you want to remove CTA?' }))
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    this.store.dispatch(new CTADeleteRequest(element));
                }
            });
    }

    edit(element: CTARegisterDTO) {
        this.popupService.openPopup(new PopupSettings(CTAFormComponent, null, null, element, 480, 460, true));
    }

    download(attachment: CTAAttachment) {
        this.uploadedAttachmentsActionInProgress.setValue(attachment.link, true);
        this.ctaService
            .downloadCTAAttachment(attachment.link)
            .pipe(
                take(1),
                finalize(() => this.uploadedAttachmentsActionInProgress.setValue(attachment.link, false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, attachment.name);
            });
    }
}
