import { Action } from '@ngrx/store';
import { IcicleDTO } from './model';
import { PhaseType } from 'src/app/enums';

export enum IcicleActionTypes {
    IcicleRequest = '[Icicle] Request',
    IcicleRequestSuccess = '[Icicle] Success',
    IcicleRequestError = '[Icicle] Error',
    IcicleFilterPropertyUpdate = '[Icicle] Filter Property Update',
    IcicleFilterReset = '[Icicle] Filter Reset',
    IcicleWeekRangeSet = '[Icicle] Week Range Set',
}

export class IcicleWeekRangeSet implements Action {
    readonly type = IcicleActionTypes.IcicleWeekRangeSet;

    constructor(public payload: { startDate: string; type: PhaseType }) {}
}

export class IcicleRequest implements Action {
    readonly type = IcicleActionTypes.IcicleRequest;

    constructor(public payload: PhaseType) {}
}

export class IcicleFilterReset implements Action {
    readonly type = IcicleActionTypes.IcicleFilterReset;
}

export class IcicleRequestSuccess implements Action {
    readonly type = IcicleActionTypes.IcicleRequestSuccess;

    constructor(public payload: { data: IcicleDTO[]; type: string }) {}
}

export class IcicleRequestError implements Action {
    readonly type = IcicleActionTypes.IcicleRequestError;

    constructor(public error: string) {}
}

export class IcicleFilterPropertyUpdate implements Action {
    readonly type = IcicleActionTypes.IcicleFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export type IcicleActions =
    | IcicleRequest
    | IcicleRequestSuccess
    | IcicleRequestError
    | IcicleFilterPropertyUpdate
    | IcicleFilterReset
    | IcicleWeekRangeSet;
