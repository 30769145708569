<div class="popup">
    <div class="icon-remove" (click)="onCancel()"></div>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold" *ngIf="tableType !== 'postRFOExpectedClosureDate'">Forecast History</span>
        <span class="font-gotham-narrow-bold" *ngIf="tableType === 'postRFOExpectedClosureDate'">Expected Closure History</span>
    </p>
    <mat-dialog-content>
        <table
            mat-table
            [dataSource]="dataSource"
        >
            <ng-container matColumnDef="date" *ngIf="tableType !== 'postRFOExpectedClosureDate'">
                <th mat-header-cell *matHeaderCellDef> Forecast date </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.date | date: 'd/MMM/y' | breakLine: ' ':true }}
                </td>
            </ng-container>
            <ng-container matColumnDef="date" *ngIf="tableType === 'postRFOExpectedClosureDate'">
                <th mat-header-cell *matHeaderCellDef> Expected Closure date </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.date | date: 'd/MMM/y' | breakLine: ' ':true }}
                </td>
            </ng-container>
            <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef>changed by</th>
                <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
            </ng-container>
            <ng-container matColumnDef="updateDate">
                <th mat-header-cell *matHeaderCellDef>Update date</th>
                <td mat-cell *matCellDef="let element">{{ element.updateDate | date: 'd/MMM/y' }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-dialog-content>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</div>
