import { ReportsDashboardActions, ReportsDashboardActionTypes } from './actions';

const initialState = {
    isLoading: false,
};

export function reducer(state = initialState, action: ReportsDashboardActions) {
    switch (action.type) {
        case ReportsDashboardActionTypes.ReportsDashboardDownloadFishboneRequest:
            return {
                ...state,
                isLoading: true,
            };
        case ReportsDashboardActionTypes.ReportsDashboardDownloadFishboneError:
        case ReportsDashboardActionTypes.ReportsDashboardDownloadFishboneSuccess:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}
