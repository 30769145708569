import { Injectable } from "@angular/core";
import {
    Actions,
    Effect,
    ofType
} from "@ngrx/effects";
import { Store } from "@ngrx/store";
import * as moment from "moment";
import { of } from "rxjs";
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom
} from "rxjs/operators";
import { CommentService } from "src/app/services/api/webapi-services/comment.service";
import { ScheduleActivityLookupService } from "src/app/services/api/webapi-services/schedule-activity-lookup.service";
import { ScheduleActivityPlanningService } from "src/app/services/api/webapi-services/schedule-activity-planning.service";
import { ToastService } from "src/app/services/shared/toast.service";
import { Contractor } from "../common.model";
import { ApplicationState } from "../model";
import { ScheduleActivityPlanningFilter, WeekSummaryFilter } from "../weekly-planning/schedule-activity-planning/model";
import {
    ScheduleActivityITRActionTypes,
    ScheduleActivityITRAddCommentError,
    ScheduleActivityITRAddCommentRequest,
    ScheduleActivityITRAddCommentSuccess,
    ScheduleActivityITRDataError,
    ScheduleActivityITRDataRequest,
    ScheduleActivityITRDataSuccess,
    ScheduleActivityITRExportToExcelError,
    ScheduleActivityITRExportToExcelRequest,
    ScheduleActivityITRExportToExcelSuccess,
    ScheduleActivityITRSaveSelectedITRIdsError,
    ScheduleActivityITRSaveSelectedITRIdsRequest,
    ScheduleActivityITRSaveSelectedITRIdsSuccess,
    ScheduleActivityITRsToGoDetailsSuccess
} from "./actions";
import { ScheduleActivityITRFilter, ScheduleActivityITRScope } from "./model";

@Injectable()
export class ScheduleActivityITREffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private scheduleActivityPlanningService: ScheduleActivityPlanningService,
        private toastService: ToastService,
        private commentService: CommentService,
        private scheduleActivityLookupService: ScheduleActivityLookupService
    ) { }

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<ScheduleActivityITRDataRequest>(ScheduleActivityITRActionTypes.ITRDataRequest),
        withLatestFrom(
            this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter),
            this.store.select((state) => state.scheduleActivityITRState.filter)
        ),
        switchMap(([action, scheduleActivityfilter, itrFilter]: [any, ScheduleActivityPlanningFilter, ScheduleActivityITRFilter]) => {
            const filter: ScheduleActivityPlanningFilter = {
                ...scheduleActivityfilter,
                itrContractors: scheduleActivityfilter.itrContractors.map((c) => ({ id: c.contractNo})),
                page: itrFilter.page,
                take: itrFilter.take,
                sortBy: itrFilter.sortBy,
                direction: itrFilter.direction,
                activityId: itrFilter.activityId,
                activityDiscipline: itrFilter.activityDiscipline,
                hasNoITRsToGo: null,
                skipActivityDatesValidation: true,
                weekSummaryFilter: new WeekSummaryFilter()
            };

            return this.scheduleActivityPlanningService
                .getITRsForActivities(filter, itrFilter.scope).pipe(
                    map((response) => {
                        return new ScheduleActivityITRDataSuccess({
                            items: response.items,
                            totalCount: response.totalCount,
                        })
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while filtering detailed status page. Please contact Program Administrator.'
                        );
                        return of(new ScheduleActivityITRDataError(error));
                    })
                );
        })
    );

    @Effect()
    getWeeklyPlanDetails$ = this.actions$.pipe(
        ofType<ScheduleActivityITRDataRequest>(ScheduleActivityITRActionTypes.ITRDataRequest),
        mergeMap((scope) =>
            of(scope).pipe(
                withLatestFrom(
                    this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter),
                    this.store.select((state) => state.scheduleActivityITRState.filter),
                    this.store.select((state) => !!state.scheduleActivityITRState.weeklyPlanITRsToGo)
                ),
                filter(
                    ([action, scheduleActivityFilter, itrFilter, itrsToGoInitialized]) =>
                        scheduleActivityFilter && itrFilter.saveWeeklyPlan && !itrsToGoInitialized
                )
            )
        ),
        switchMap(([action, scheduleActivityFilter, itrFilter, itrsToGoInitialized]) => {
            const filter = {
                ...scheduleActivityFilter,
                page: itrFilter.page,
                take: itrFilter.take,
                sortBy: itrFilter.sortBy,
                direction: itrFilter.direction,
                activityId: itrFilter.activityId,
                activityDiscipline: itrFilter.activityDiscipline
            };

            return this.scheduleActivityLookupService
                .getITRsToGoWeeklyPlanDetails(
                    { 
                        ...filter,
                        skipActivityDatesValidation: itrFilter.additionToPlan,
                    })
                .pipe(map((data) => new ScheduleActivityITRsToGoDetailsSuccess(data)))
        }
        )
    );

    @Effect()
    saveWeeklyPlanSelectedITRIds$ = this.actions$.pipe(
        ofType<ScheduleActivityITRSaveSelectedITRIdsRequest>(
            ScheduleActivityITRActionTypes.SaveSelectedITRIdsRequest
        ),
        withLatestFrom(
            this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekStart)
        ),
        switchMap(([{ payload }, weekStart]) =>
            this.scheduleActivityPlanningService
                .saveSelectedITRIds(
                    payload.activityId,
                    weekStart,
                    payload.selectedIds,
                    payload.activityDiscipline,
                    payload.additionToPlan
                )
                .pipe(
                    map(() => {
                        this.toastService.Success('Weekly Plan has been successfully saved.');
                        return new ScheduleActivityITRSaveSelectedITRIdsSuccess();
                    }),
                    catchError((error) => of(new ScheduleActivityITRSaveSelectedITRIdsError(error)))
                )
        )
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType<ScheduleActivityITRExportToExcelRequest>(ScheduleActivityITRActionTypes.ExportToExcelRequest),
        map((action) => action.payload),
        mergeMap((scope) =>
            of(scope).pipe(
                withLatestFrom(
                    this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter)
                )
            )
        ),
        switchMap(([payload, scheduleActivityFilter]) => {
            const filter = {
                ...scheduleActivityFilter,
                activityId: payload.activityId,
                activityDiscipline: payload.activityDiscipline,
                sortBy: payload.sortBy,
                itrContractors: scheduleActivityFilter.itrContractors.map((c) => ({ id: c.contractNo }))
            }
            if (ScheduleActivityITRScope[payload.scope] == ScheduleActivityITRScope.InWeek.toString() || payload.scope == 1) {
                filter.skipActivityDatesValidation = true;
            }
            return this.scheduleActivityPlanningService.exportITRsToExcel(filter, payload.scope).pipe(
                map((excelData) => new ScheduleActivityITRExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new ScheduleActivityITRExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    exportToExcelSuccess = this.actions$.pipe(
        ofType(ScheduleActivityITRActionTypes.ExportToExcelSuccess),
        map((action: ScheduleActivityITRExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `WeeklyPlanITR_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect()
    addComment$ = this.actions$.pipe(
        ofType<ScheduleActivityITRAddCommentRequest>(ScheduleActivityITRActionTypes.AddCommentRequest),
        mergeMap(({ payload }) =>
            this.commentService.addComment(payload.entityId, payload.description, payload.scope, payload.mentions).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully added.');
                    return new ScheduleActivityITRAddCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating comment. Please contact Program Administrator.'
                    );
                    return of(new ScheduleActivityITRAddCommentError(error));
                })
            )
        )
    );
}