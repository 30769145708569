

import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { PriorityNumbersState } from './model';
import { PriorityNumbersActionTypes, PriorityNumbersActions } from './actions';

const initialState: PriorityNumbersState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    isDeleteDataLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: PriorityNumbersActions): PriorityNumbersState {
    switch (action.type) {
        case PriorityNumbersActionTypes.PriorityNumbersUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case PriorityNumbersActionTypes.PriorityNumbersUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case PriorityNumbersActionTypes.PriorityNumbersUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case PriorityNumbersActionTypes.PriorityNumbersDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case PriorityNumbersActionTypes.PriorityNumbersSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        
        case PriorityNumbersActionTypes.PriorityNumbersClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case PriorityNumbersActionTypes.PriorityNumbersDownloadOutputDataRequest:
        case PriorityNumbersActionTypes.PriorityNumbersTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case PriorityNumbersActionTypes.PriorityNumbersTemplateFileError:
        case PriorityNumbersActionTypes.PriorityNumbersDownloadOutputDataSuccess:
        case PriorityNumbersActionTypes.PriorityNumbersDownloadOutputDataError:
        case PriorityNumbersActionTypes.PriorityNumbersTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case PriorityNumbersActionTypes.PriorityNumbersAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'PriorityNumbers';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

