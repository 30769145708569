import { Action } from '@ngrx/store';
import { ExportPageDataPagination } from './model';

export enum ExportPageActionTypes {
    ExportPageFilterRequest = '[Export Page] Filter Request',
    ExportPageFilterSuccess = '[Export Page] Filter Success',
    ExportPageFilterError = '[Export Page] Filter Error',
    ExportPageFileRequest = '[Export Page] File Request',
    ExportPageFileSuccess = '[Export Page] File Success',
}

export class ExportPageFilterRequest implements Action {
    readonly type = ExportPageActionTypes.ExportPageFilterRequest;
}

export class ExportPageFilterSuccess implements Action {
    readonly type = ExportPageActionTypes.ExportPageFilterSuccess;

    constructor(public payload: ExportPageDataPagination) {}
}

export class ExportPageFilterError implements Action {
    readonly type = ExportPageActionTypes.ExportPageFilterError;

    constructor(public payload: string) {}
}

export class ExportPageFileRequest implements Action {
    readonly type = ExportPageActionTypes.ExportPageFileRequest;

    constructor(public payload: { link: string; filename: string; }) {}
}
export class ExportPageFileSuccess implements Action {
    readonly type = ExportPageActionTypes.ExportPageFileSuccess;
}


export type ExportPageActions =
    | ExportPageFilterRequest
    | ExportPageFilterSuccess
    | ExportPageFilterError
    | ExportPageFileRequest
    | ExportPageFileSuccess
