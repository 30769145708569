import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum ExceptionsActionTypes {
    ExceptionsUploadLogRequest = '[Exceptions] Upload Log Request',
    ExceptionsUploadLogSuccess = '[Exceptions] Upload Log Success',
    ExceptionsUploadLogError = '[Exceptions] Upload Log Error',
    ExceptionsSetStatusAndStartDate = '[Exceptions] Set Status and Start Date',
    ExceptionsFileUploaded = '[Exceptions] File Uploaded',
    ExceptionsClearInProgressSpinner = '[Exceptions] Clear In Progress Spinner',
    ExceptionsTemplateFileRequest = '[Exceptions] Template File Request',
    ExceptionsTemplateFileSuccess = '[Exceptions] Template File Success',
    ExceptionsTemplateFileError = '[Exceptions] Template File Error',
    ExceptionsValidateDeltaRequest = '[Exceptions] Validate Delta Request',
    ExceptionsValidateDeltaSuccess = '[Exceptions] Validate Delta Success',
    ExceptionsValidateDeltaError = '[Exceptions] Validate Delta Error',
    ExceptionsDataInfoRequest = '[Exceptions] Data Info Request',
    ExceptionsDataInfoSuccess = '[Exceptions] Data Info Success',
    ExceptionsDataInfoError = '[Exceptions] Data Info Error',
    ExceptionsDownloadOutputDataRequest = '[Exceptions] Output data Request',
    ExceptionsDownloadOutputDataSuccess = '[Exceptions] Output data Success',
    ExceptionsDownloadOutputDataError = '[Exceptions] Output data File Error',
    ExceptionsAddStartedLog = '[Exceptions] Add Started Log',
}

export class ExceptionsAddStartedLog implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsAddStartedLog;
}

export class ExceptionsUploadLogRequest implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsUploadLogRequest;
}

export class ExceptionsUploadLogSuccess implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ExceptionsUploadLogError implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsUploadLogError;

    constructor(public payload: string) {}
}

export class ExceptionsFileUploaded implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsFileUploaded;
}

export class ExceptionsClearInProgressSpinner implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsClearInProgressSpinner;
}

export class ExceptionsTemplateFileRequest implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ExceptionsTemplateFileSuccess implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ExceptionsTemplateFileError implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsTemplateFileError;

    constructor(public payload: string) {}
}

export class ExceptionsDownloadOutputDataRequest implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ExceptionsDownloadOutputDataSuccess implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ExceptionsDownloadOutputDataError implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ExceptionsSetStatusAndStartDate implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class ExceptionsValidateDeltaRequest implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsValidateDeltaRequest;
}

export class ExceptionsValidateDeltaSuccess implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsValidateDeltaSuccess;
}

export class ExceptionsValidateDeltaError implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsValidateDeltaError;

    constructor(public payload: string) {}
}

export class ExceptionsDataInfoSuccess implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsDataInfoSuccess;

    constructor(public payload: {
        readyToProcess: boolean[];
        inProgress: boolean[];
        recordsForDeletion: number[];
        isAutomaticUploadInProgress: boolean;
    }) {}
}

export class ExceptionsDataInfoError implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsDataInfoError;

    constructor(public payload: string) {}
}

export class ExceptionsDataInfoRequest implements Action {
    readonly type = ExceptionsActionTypes.ExceptionsDataInfoRequest;
}

export type ExceptionsActions =
    | ExceptionsUploadLogRequest
    | ExceptionsUploadLogSuccess
    | ExceptionsUploadLogError
    | ExceptionsFileUploaded
    | ExceptionsClearInProgressSpinner
    | ExceptionsTemplateFileRequest
    | ExceptionsTemplateFileSuccess
    | ExceptionsTemplateFileError
    | ExceptionsSetStatusAndStartDate
    | ExceptionsValidateDeltaRequest
    | ExceptionsValidateDeltaSuccess
    | ExceptionsValidateDeltaError
    | ExceptionsDataInfoRequest
    | ExceptionsDataInfoSuccess
    | ExceptionsDataInfoError
    | ExceptionsDownloadOutputDataRequest
    | ExceptionsDownloadOutputDataSuccess
    | ExceptionsDownloadOutputDataError
    | ExceptionsAddStartedLog;
