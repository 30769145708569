import { Injectable } from '@angular/core';

@Injectable()
export class Constants {
    static readonly changeTypes = [
        'DCN',
        'SWIFTPLI',
        'RFI',
        'NCR',
        'Contractor NCR',
        // 'PCN',
        // 'Surveillance',
        'TMOC',
        'MAC DCN',
        'RFI (iPIMS)',
        'SID',
        'PAS CR',
        'LeakTestPackTracker',
        'Materials'
    ];

    static readonly applicableGroups = {
        ReadOnly: 'TCO-ReadOnly',
        MCEngineer3GP: 'TCO-3GP-EditUser',
        MCEngineer3GI: 'TCO-3GI-EditUser',
        Admin: 'Admin',
        NPWValidation: 'TCO-Gathering-NPW-Validation',
        Validator3GI: 'TCO-3GI-Validator',
        Validator3GP: 'TCO-3GP-Validator',
        LoopCoordinator: 'TCO-LoopCoordinator',
        LoopEngineer: 'TCO-LoopEngineer',
        Planners: 'TCO-Planners',
        DPLIReviewers: 'TCO-DPLIReviewers',
        ExecutionPlanning: 'TCO-3GP-SCExecutionPlanning',
        ContractorFieldAccess: 'TCO-FieldImplementationContractor',
        DeliveryEngineerAccess: 'TCO-BlueZoneRFSUForecastDate',
        ExceptionsTracking: 'TCO-ExceptionsTracking',
        SetInactiveNPW:'TCO-SetInactiveNPW',
        RFSUForecastSkyline: 'TCO-RFSUForecastSkyline',
        SystemOwners: 'TCO-SystemOwners',
        PLIImplementationTeamCOG: 'TCO-PLIImplementationTeamCOG',
        PLIImplementationTeamNB: 'TCO-PLIImplementationTeamNB',
        ResponsiblePerson: 'TCO-Responsibleperson',
        PLIImplementationTeamICT:'TCO-PLIImplementationTeamICT',
        RFSUCommitmentSkylineViewers:'TCO-RFSUCommitmentSkyline',
        BlueZoneWalkdownForecastDate:'TCO-BlueZoneWalkdownForecastDate',
        RFOForecastDate:"TCO-RFOForecastDate",
        PLIForecastClosureDate:"TCO-PLIForecastClosureDate",
        Constraintsforecastclosuredate: "TCO-Constraintsforecastclosuredate",
        CriticalRFIPriorityOverride:"TCO-CriticalRFIPriorityOverride",
        RFSUCommitButton: "TCO-RFSUCommitButton",
    };

    static readonly subsystemType = {
        aqvd: 'A-QVD',
        pli: 'PLI',
        bitr: 'B-ITR',
        citr: 'C-ITR',
    };

    static readonly projectTeamNames = {
        GP: '3GP',
        GI: '3GI',
    };

    static readonly popupWidth: number = 775;
    static readonly popupHeight: number = 300;
    static readonly ConstraintPunchItemType: string = 'Open Punch Item';

    static readonly TokenRefreshInterval = 1800000;

    static readonly logRefreshInterval = 45 * 1000;

    static readonly delayAfterUploadInterval = 7 * 1000;

    static readonly baseEbUrl: string =
        'https://eb-fgp.chevron.com/FGP/Search/QuickLink.aspx?n={docNo}&state=LatestRevision&i=view';

    static readonly START_OF_WEEK_DAY = 6;

    static readonly tagSearchTableType = {
        pli: 'PLI',
        itr: 'ITR',
        changeDoc: 'ChangeDoc',
    };

    static readonly LeakTestPackTrackerSearchTableType = {
       activityDetails : 'ActivityDetails'
    };

    static readonly Config = {
        loopFolderStatusKey: 'Loop Folder Status',
        loopFolderAssignmentKey: 'Loop Folder Assignment',
        engineerStatusKey: 'Engineer Status',
        constraintClassKey: 'Constraint Class',
        responsiblePartyKey: 'Responsible Party',
        loopFolderConstraintKey: 'Loop Folder Constraint',
        tagsImportType: 'Tags Import Type',
        filenamesForTagImport: 'Filenames for Tag Import',
        signOffFiledImplementationModeKey: 'DCN/RFI Sign Off Field Implementation Mode',
        complete :'Complete in MC+',
        HardCopy : 'Hard Copy Ready',
        SoftCopy :'Soft Copy Ready',
        IssueToExecution :'Issued to Execution',
        Requested : 'Requested',
        Late : 'Late' 

    };
}
