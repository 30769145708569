import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { map, take, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Contractor, RFO, TCOUser } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    ActivitiesConstraintsChartRequest,
    ActivitiesConstraintsFilterReset,
    ActivitiesConstraintsFilterStateUpdate,
} from 'src/app/store/reports/health/actions';
import { ActivitiesConstraintsFilter } from 'src/app/store/reports/health/model';

@Component({
    selector: 'app-activities-constraints-filter',
    templateUrl: './activities-constraints-filter.component.html',
    styleUrls: ['./activities-constraints-filter.component.scss'],
})
export class ActivitiesConstraintsFilterComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    disciplines: any[] = [];
    constraintTypes: string[] = [];
    mslAreas = [];
    scManagers: TCOUser[] = [];
    areaCompletionLeads: TCOUser[] = [];
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWorkAreaInput = new EventEmitter<SetInputEventArgs>();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    filter$ = this.store.select((state) => state.healthState.activitiesConstraints.filter);

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>,
        private scheduleActivityLookupService: ScheduleActivityLookupService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new ActivitiesConstraintsFilter());
    }

    ngOnInit(): void {
        this.filterExpansionPanel.expanded = true;

        this.lookupService
            .getSCManagers([])
            .pipe(take(1))
            .subscribe((scManagers: any[]) => (this.scManagers = scManagers));

        this.lookupService
            .getMSLAreas()
            .pipe(take(1))
            .subscribe((mslAreas) => (this.mslAreas = mslAreas));

        this.lookupService
            .getDisciplines()
            .pipe(take(1))
            .subscribe((disciplines: any[]) => (this.disciplines = disciplines));

        this.scheduleActivityLookupService
            .searchConstraintTypes(1000)
            .pipe(take(1))
            .subscribe((data: any) => (this.constraintTypes = data.items.map((i) => i.name)));

        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
        });

        this.subscribeToFormChanges();

        this.lookupService
            .getACLeads([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads));
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    subscribeToFormChanges = () => {
        this.filterForm.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
            const newFilter = value as ActivitiesConstraintsFilter;
            this.store.dispatch(new ActivitiesConstraintsFilterStateUpdate(newFilter));
        });
    };

    displayMultipleByName(values: (RFO | Contractor)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    clearFilterProperty(propertyName: string) {
        let value: any;

        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            value = [];
        } else if (typeof this.filterForm.controls[propertyName].value === 'object') {
            value = null;
        } else {
            value = '';
        }

        this.filterForm.controls[propertyName].setValue(value);
    }

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFOs(search, take, page, []).pipe(map((rfos: RFO[]) => rfos));
    };

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, [], [], this.filterForm.controls.rfos.value);
    };

    searchWorkAreas = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWorkAreas(search, take, page);
    };

    getContractorsData = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, [])
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    resetFilters() {
        this.store.dispatch(new ActivitiesConstraintsFilterReset());
    }

    search() {
        this.store.dispatch(new ActivitiesConstraintsChartRequest());
    }
}

