<main class="main">
    <div class="title-container">
        <div class="title">CTA Register</div>
    </div>
    <div class="results-container">
        <table
            mat-table
            matSort
            [dataSource]="data"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
            class="cta-table"
        >
            <ng-container matColumnDef="fileName">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(
                            'fileName',
                            getFileNames,
                            'columnFileName',
                            'File Name',
                            columnFileName
                        )
                    "
                >
                    <div class="sort-container">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnFileName)
                            }"
                            >File Name</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'fileName'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(
                            'description',
                            getDescriptions,
                            'columnDescription',
                            'Description',
                            columnDescription
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnDescription)
                            }"
                            >Description</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'description'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.description }}</td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderDateFilter('date', 'columnDate', 'Date', columnDate)
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCalendarColumn(columnDate)
                            }"
                            >Date</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'date'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="center"
                >
                    {{ element.date | date: 'd/MMM/y' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="area">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(
                            'area',
                            getAreas,
                            'columnArea',
                            'Area',
                            columnArea,
                            true
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnArea)
                            }"
                            >Area</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'area'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.area }}</td>
            </ng-container>
            <ng-container matColumnDef="attachments">
                <th mat-header-cell disableClear *matHeaderCellDef class="center">Attachments</th>
                <td mat-cell *matCellDef="let element" class="attachments">
                    <div class="uploaded-attachment-buttons" *ngFor="let att of element.attachments">
                        <div
                            class="lds-hourglass"
                            *ngIf="uploadedAttachmentsActionInProgress.getValue(att.link) || false"
                        ></div>
                        <button
                            *ngIf="!uploadedAttachmentsActionInProgress.getValue(att.link)"
                            [title]="att.name"
                            type="button"
                            class="download-uploaded-attachment"
                            (click)="download(att)"
                        ></button>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="edit" sticky>
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button
                        *ngIf="canEditCTA"
                        mat-icon-button
                        (click)="edit(element)"
                        title="edit"
                    >
                        <mat-icon svgIcon="edit"></mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="remove" sticky>
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button
                        *ngIf="canEditCTA"
                        mat-icon-button
                        (click)="remove(element)"
                        title="remove"
                    >
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>
