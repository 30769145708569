import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UniqueProjectTeamNameValidator } from 'src/app/validators/validators';

@Component({
    selector: 'app-add-project-team-popup',
    templateUrl: './add-project-team-popup.component.html',
    styleUrls: ['./add-project-team-popup.component.scss'],
})
export class AddProjectTeamPopupComponent {
    projectTeamName: UntypedFormControl;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<AddProjectTeamPopupComponent>
    ) {
        const uniqueProjectTeamValidator = new UniqueProjectTeamNameValidator(data.projectTeamNames).validate;
        this.projectTeamName = new UntypedFormControl('', [Validators.required, uniqueProjectTeamValidator]);
    }

    onCancel() {
        this.dialogRef.close();
    }

    onConfirm() {
        this.dialogRef.close(this.projectTeamName.value);
    }
}
