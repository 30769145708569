import { Component, OnInit, EventEmitter, ViewChild, NgZone, ElementRef, AfterViewChecked } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ApplicationState } from 'src/app/store/model';
import { Store, select } from '@ngrx/store';
import {
    BlueZoneFilterRequest,
    BlueZoneFilterPropertyUpdate,
    BlueZoneFilterReset,
    BlueZoneAddCommentRequest,
    BlueZoneExportToExcelRequest,
    BlueZoneRemoveCommentRequest,
    BlueZoneAddBulkCommentRequest,
    BlueZoneUpdateBacklogCatRequest,
    BlueZoneClearFlagColorRequest,
    BlueZoneSetFlagColorRequest,
    BlueZoneExportOnePagerRequest,
    BlueZoneToggleWalkdownCommitmentRequest,
} from 'src/app/store/bluezone/actions';
import { Constants } from 'src/app/constants';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { BlueZoneFilter, BlueZoneDTO, ShowHideColumns } from 'src/app/store/bluezone/model';
import {
    Contractor,
    TCOACManager,
    TCOUser,
    Waypoint,
    Milestone,
    OrderDirection,
    CalendarColumn,
    CheckListColumn,
    RFO,
    System,
    UserDetail,
    Gooc,
    NumericColumn,
    StagedStartUpPriority,
} from 'src/app/store/common.model';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { take, map, takeWhile, catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from '../../modules/shared/components/comment-popup/comment-popup.component';
import { forkJoin, of } from 'rxjs';
import * as _ from 'lodash';
import { RoleService } from 'src/app/services/shared/role.service';
import { Zone } from 'src/app/enums';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { HeaderCheckListFilter, HeaderDateFilter, HeaderNumericFilter } from 'src/app/models/filter-settings';
import { CommentsHistoryComponent } from '../comments-history/comments-history.component';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import {
    DetailedStatusFilterReset,
    DetailedStatusResetLockedFilter,
    DetailedStatusSetMakeRequest,
    DetailedStatusSetTableOffset,
} from 'src/app/store/detailed-status/actions';
import { ChangeManagementSetMakeRequest, ChangeManagementFilterReset } from 'src/app/store/change-management/actions';
import { PascrStatusFilterReset } from 'src/app/store/pascr-status/actions';
import { ClearableMatDatepickerComponent } from '../clearable-mat-datepicker/clearable-mat-datepicker.component';
import { getDetailedStatusFilterInstance, getNextWeekStartDate } from 'src/app/extensions';
import { CommentDetailDTO } from 'src/app/store/detailed-status/model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ForecastHistoryComponent } from '../forecast-history/forecast-history.component';
import * as moment from 'moment';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopoverContentComponent } from 'ngx-smart-popover';
import { MatTable } from '@angular/material/table';
import { HeaderNumericFilterComponent } from 'src/app/modules/shared/components/filter/header-numeric-filter/header-numeric-filter.component';
import {
    ScheduleActivityClearFilters,
    ScheduleActivityPlanningDataRequest,
    ScheduleActivityPlanningFilterPropertyUpdate,
    ScheduleActivityWeekSummaryDataRequest,
} from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import { ScheduleActivityPlanningFilter } from 'src/app/store/weekly-planning/schedule-activity-planning/model';
import { dateTimeUtilsDuration } from '@microsoft/applicationinsights-common';

@Component({
    selector: 'app-bluezone',
    templateUrl: './bluezone.component.html',
    styleUrls: ['./bluezone.component.scss'],
})
export class BlueZoneComponent extends BaseComponent implements OnInit, AfterViewChecked {
    filterForm: UntypedFormGroup;
    isLoading = false;
    isUsersPerProjectLoading = false;
    autocompleteDisplayedColumns = ['name', 'description'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    systemAutocompleteDisplayedColumns = ['no', 'name'];
    todayDate = new Date();

    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    // setConstructionAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    milestones$ = this.store.select((state) => state.blueZoneState.filter.milestones);
    waypoints$ = this.store.select((state) => state.blueZoneState.filter.waypoints);
    sortBy$ = this.store.select((state) => state.blueZoneState.filter.sortBy);
    direction$ = this.store.select((state) => state.blueZoneState.filter.direction);

    columnMCForecast$ = this.store.select((state) => state.blueZoneState.filter.columnMCForecast);
    columnMCPlan$ = this.store.select((state) => state.blueZoneState.filter.columnMCPlan);
    columnMCActual$ = this.store.select((state) => state.blueZoneState.filter.columnMCActual);
    columnWalkdownForecast$ = this.store.select((state) => state.blueZoneState.filter.columnWalkdownForecast);
    columnWalkdownActual$ = this.store.select((state) => state.blueZoneState.filter.columnWalkdownActual);
    columnWalkdownSch$ = this.store.select((state) => state.blueZoneState.filter.columnWalkdownSch);
    columnDisciplines$ = this.store.select((state) => state.blueZoneState.filter.columnDisciplines);
    columnRFSUForecast$ = this.store.select((state) => state.blueZoneState.filter.columnRFSUForecast);
    columnRFOPlan$ = this.store.select((state) => state.blueZoneState.filter.columnRFOPlan);
    columnRFSUPlan$ = this.store.select((state) => state.blueZoneState.filter.columnRFSUPlan);
    columnRFSUWalkdownPlan$ = this.store.select((state) => state.blueZoneState.filter.columnRFSUWalkdownPlan);
    columnRFSUCommitment$ = this.store.select((state) => state.blueZoneState.filter.columnRFSUCommitment);
    columnRFSUActual$ = this.store.select((state) => state.blueZoneState.filter.columnRFSUActual);
    columnRfo$ = this.store.select((state) => state.blueZoneState.filter.columnRfo);
    columnWaypoints$ = this.store.select((state) => state.blueZoneState.filter.columnWaypoints);
    columnWaypointDescription$ = this.store.select((state) => state.blueZoneState.filter.columnWaypointDescription);
    columnWaypointTargetDate$ = this.store.select((state) => state.blueZoneState.filter.columnWaypointTargetDate);
    columnMilestones$ = this.store.select((state) => state.blueZoneState.filter.columnMilestones);
    columnMilestoneDescription$ = this.store.select((state) => state.blueZoneState.filter.columnMilestoneDescription);
    columnMilestoneTargetDate$ = this.store.select((state) => state.blueZoneState.filter.columnMilestoneTargetDate);
    columnSystemOwner$ = this.store.select((state) => state.blueZoneState.filter.columnSystemOwner);
    columnEdossierInitiation$ = this.store.select((state) => state.blueZoneState.filter.columnEdossierInitiation);
    columnEdossierStatus$ = this.store.select((state) => state.blueZoneState.filter.columnEdossierStatus);
    columnSubsystems$ = this.store.select((state) => state.blueZoneState.filter.columnSubsystems);
    columnDeliveryEngineers$ = this.store.select((state) => state.blueZoneState.filter.columnDeliveryEngineer);
    columnSubsystemNames$ = this.store.select((state) => state.blueZoneState.filter.columnSubsystemNames);
    columnComments$ = this.store.select((state) => state.blueZoneState.filter.columnComments);
    columnPriorityName$ = this.store.select((state) => state.blueZoneState.filter.columnPriorityName);
    columnAreaBreakdown$ = this.store.select((state) => state.blueZoneState.filter.columnAreaBreakdown);
    columnContractorNo$ = this.store.select((state) => state.blueZoneState.filter.columnContractorNo);
    columnAreaCompletionLead$ = this.store.select((state) => state.blueZoneState.filter.columnAreaCompletionLead);
    columnGoocNo$ = this.store.select((state) => state.blueZoneState.filter.columnGoocNo);
    columnBacklogCats$ = this.store.select((state) => state.blueZoneState.filter.columnBacklogCats);
    columnFlags$ = this.store.select((state) => state.blueZoneState.filter.columnFlags);
    columnWalkdownCommitments$ = this.store.select((state) => state.blueZoneState.filter.columnWalkdownCommitments);

    columnAqvd$ = this.store.select((state) => state.blueZoneState.filter.columnAqvd);
    columnApli$ = this.store.select((state) => state.blueZoneState.filter.columnApli);
    columnNpw$ = this.store.select((state) => state.blueZoneState.filter.columnNpw);
    columnBitRsRemaining$ = this.store.select((state) => state.blueZoneState.filter.columnBitRsRemaining);
    columnBitRsTotal$ = this.store.select((state) => state.blueZoneState.filter.columnBitRsTotal);
    columnBitRsCompleted$ = this.store.select((state) => state.blueZoneState.filter.columnBitRsCompleted);
    columnBitRsPercentage$ = this.store.select((state) => state.blueZoneState.filter.columnBitRsPercentage);
    columnCitRsRemaining$ = this.store.select((state) => state.blueZoneState.filter.columnCitRsRemaining);
    columnACTsRemaining$ = this.store.select((state) => state.blueZoneState.filter.columnACTsRemaining);
    columnCitRsTotal$ = this.store.select((state) => state.blueZoneState.filter.columnCitRsTotal);
    columnCitRsCompleted$ = this.store.select((state) => state.blueZoneState.filter.columnCitRsCompleted);
    columnCitRsPercentage$ = this.store.select((state) => state.blueZoneState.filter.columnCitRsPercentage);
    columnBpli$ = this.store.select((state) => state.blueZoneState.filter.columnBpli);
    columnCpli$ = this.store.select((state) => state.blueZoneState.filter.columnCpli);
    columnDpli$ = this.store.select((state) => state.blueZoneState.filter.columnDpli);
    columnPascr$ = this.store.select((state) => state.blueZoneState.filter.columnPascr);
    columnEx$ = this.store.select((state) => state.blueZoneState.filter.columnEx);
    columnCow$ = this.store.select((state) => state.blueZoneState.filter.columnCow);
    columnRedline$ = this.store.select((state) => state.blueZoneState.filter.columnRedline);
    columnNonCommissionable$ = this.store.select((state) => state.blueZoneState.filter.columnNonCommissionable);
    columnFlangeMgtTotal$ = this.store.select((state) => state.blueZoneState.filter.columnFlangeMgtTotal);
    columnFlangeMgtTotalCompleted$ = this.store.select((state) => state.blueZoneState.filter.columnFlangeMgtTotalCompleted);
    columnFlangeMgtTotalRemaining$ = this.store.select((state) => state.blueZoneState.filter.columnFlangeMgtTotalRemaining);
    columnFlangeMgtTotalPercentage$ = this.store.select((state) => state.blueZoneState.filter.columnFlangeMgtTotalPercentage);
    
    columnLun$ = this.store.select((state) => state.blueZoneState.filter.columnLun);
    columnConstrainedActivitiesCount$ = this.store.select(
        (state) => state.blueZoneState.filter.columnConstrainedActivitiesCount
    );

    prevWaypoints: Waypoint[] = [];
    projectTeamNames: string[] = [];
    projectTeamNamesCurrent: string[];
    contractors: Contractor[] = [];
    acms: TCOACManager[] = [];
    scManagers: TCOUser[] = [];
    areaCompletionLeads: TCOUser[] = [];
    deliveryEngineers: TCOUser[] = [];
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    backlogCats: string[] = [];
    customdetailedStatusScopes = [
        'AQVD' , 
        'BITR' , 
        'CITR' , 
        'BITRandCITR' , 
        'PLI' , 
        'EX' , 
        'ConstraintDetails' , 
        'Redline' ,
        'COW',
        'NPWBluezone',
        'PASCR'
       ];

    numericColumns = [
        'columnAqvd',
        'columnApli',
        'columnNpw',
        'columnBitRsRemaining',
        'columnBitRsTotal',
        'columnBitRsCompleted',
        'columnBitRsPercentage',
        'columnCitRsRemaining',
        'columnACTsRemaining',
        'columnCitRsTotal',
        'columnCitRsCompleted',
        'columnCitRsPercentage',
        'columnBpli',
        'columnCpli',
        'columnDpli',
        'columnPascr',
        'columnEx',
        'columnCow',
        'columnRedline',
        'columnLun',
        'columnConstrainedActivitiesCount',
        'columnFlangeMgtTotal',
        'columnFlangeMgtTotalCompleted',
        'columnFlangeMgtTotalRemaining',
        'columnFlangeMgtTotalPercentage',
        'columnSafetyRewalkdown',
    ];

    defaultDisplayedColumns: string[] = [
        'subsystem',
        'discipline',
        'subsystemName',
        'walkdownCommitment',
        'rfsuCommitment',
        'rfsuPlan',
        //'rfsuWalkdownPlan',
        'rfsuForecast',
        'rfsuActual',
        'RFOPlan',
        // 'mcPlan',
        // 'mcForecast',
        'mcActual',
        'rfo',
        'waypointDescription',
        'milestoneDescription',
        //'milestoneTargetDate',
        'systemOwner',
        'walkdownForecast',
        'walkdownSch',
        'walkdownActual',
        //'safetyRewalkdown',
        'fplUpload',
        'edossierInitiationDate',
        'edossierStatus',
        'bitr',
        'citr',
        'act',
        'npw',
        'aqvd',
        'apli',
        'bpli',
        'cpli',
        'dpli',
        'pascr',
        'cow',
        'ex',
        'constrainedActivities',
        'redline',
        'priorityName',
        'areaBreakdown',
        'areaCompletionLead',
        'contractorNo',
        'nonCommissionable',
        'flangeMgt',
        'deliveryEng',
        'lun',
        'goocNo',
        'backlogCat',
        'comment',
    ];
    displayedColumns = this.defaultDisplayedColumns;
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    priorityAutocompleteDisplayedColumns = ['priority', 'priorityName'];
    data: BlueZoneDTO[] = [];
    resultsLength = 0;
    sortBy = 'subsystem';
    direction = OrderDirection.Desc;
    pageSize = 25;
    isReadOnly = false;
    disciplineBreakdownEnabled = false;
    eDLinksubsystem = '';

    showHideColumns = new ShowHideColumns();

    blueZoneDataPagination$ = this.store.select((store) => store.blueZoneState.dataPagination);
    blueZoneLoader$ = this.store.select((store) => store.blueZoneState.isLoading);
    blueZoneFilter$ = this.store.select((store) => store.blueZoneState.filter);

    columnMCForecast: CalendarColumn = null;
    columnMCPlan: CalendarColumn = null;
    columnMCActual: CalendarColumn = null;
    columnSafetyRewalkdown: NumericColumn = null;
    columnWalkdownForecast: CalendarColumn = null;
    columnWalkdownActual: CalendarColumn = null;
    columnRFSUForecast: CalendarColumn = null;
    columnRFOPlan: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;
    columnRFSUWalkdownPlan: CalendarColumn = null;
    columnRFSUCommitment: CalendarColumn = null;
    columnRFSUActual: CalendarColumn = null;
    columnEdossierInitiation: CalendarColumn = null;
    columnEdossierStatus: CheckListColumn = null;
    columnSubsystems: CheckListColumn = null;
    columnSubsystemNames: CheckListColumn = null;
    columnDeliveryEngineers: CheckListColumn = null;
    columnRfo:CheckListColumn = null;
    columnWaypoints: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestones: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnSystemOwner:CheckListColumn = null;
    columnDisciplines: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnWalkdownSch: CalendarColumn = null;
    columnFPLUpload: CalendarColumn = null;
    columnPriorityName: CheckListColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    columnContractorNo: CheckListColumn = null;
    columnAreaCompletionLead: CheckListColumn = null;
    columnGoocNo: CheckListColumn = null;
    columnBacklogCats: CheckListColumn = null;
    columnFlags: CheckListColumn = null;
    columnWalkdownCommitments: CheckListColumn = null;
    columnNonCommissionable : CheckListColumn = null;
    columnFlangeMgtTotal: NumericColumn = null;
    columnFlangeMgtTotalCompleted: NumericColumn = null;
    columnFlangeMgtTotalRemaining: NumericColumn = null;
    columnFlangeMgtTotalPercentage: NumericColumn = null;

    columnAqvd: NumericColumn = null;
    columnApli: NumericColumn = null;
    columnBpli: NumericColumn = null;
    columnCpli: NumericColumn = null;
    columnDpli: NumericColumn = null;
    columnPascr: NumericColumn = null;
    columnNpw: NumericColumn = null;
    columnBitRsTotal: NumericColumn = null;
    columnBitRsCompleted: NumericColumn = null;
    columnBitRsRemaining: NumericColumn = null;
    columnBitRsPercentage: NumericColumn = null;
    columnCitRsTotal: NumericColumn = null;
    columnCitRsCompleted: NumericColumn = null;
    columnCitRsRemaining: NumericColumn = null;
    columnCitRsPercentage: NumericColumn = null;
    columnACTsRemaining: NumericColumn = null;
    columnEx: NumericColumn = null;
    columnCow: NumericColumn = null;
    columnRedline: NumericColumn = null;
    columnLun: NumericColumn = null;
    columnConstrainedActivitiesCount: NumericColumn = null;

    lunUrl: string = '';
    selectedRowIndex = -1;
    selectedSubsystem = '';
    isAdmin = false;
    isWalkdownForecast = false;
    isDeliveryEngineer = false;
    rfoNumber:string;
    rfoName : string;
    rfsuRemaining:boolean;
    rfsuCompleted: boolean;
    
    showDetailedPage: string;
    blueScreen: boolean;
    blueScope: string;
    blueSubsystem: string;
    blueStatus: string[];
    blueDiscipline: string[];
    blueZone: string;
    blueCategory: string;
    blueChangeType: string;
    //blueAllowComments="blueAllowComments"
    //blueLockFilters="blueLockFilters"
    showFilterPanel=true;

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('tableContainer', { static: false }) table: ElementRef;
    @ViewChild('trafficLightPopover', { static: true }) popoverContent: PopoverContentComponent;
    @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;
    @ViewChild("detailedPage") detailedPage: ElementRef;

    constructor(
        private store: Store<ApplicationState>,
        private formService: FormService,
        private lookupService: LookupService,
        private toastService: ToastService,
        private router: Router,
        private ngZone: NgZone,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private projectTeamsService: ProjectTeamsService,
        private commentService: CommentService,
        private route: ActivatedRoute,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new BlueZoneFilter());
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );   
        
        this.rfoNumber = route.snapshot.queryParamMap.get('rfo');
        this.rfoName = route.snapshot.queryParamMap.get('rfoName');  
        this.rfsuRemaining = route.snapshot.queryParamMap.get('rfsuRemaining') === 'true'; 
         this.rfsuCompleted = route.snapshot.queryParamMap.getAll('rfsucompleted').length === 0 ? null : JSON.parse(route.snapshot.queryParamMap.get('rfsucompleted'));       
    } 
     activeScopeDetailedView() {       
        return this.customdetailedStatusScopes.includes(this.showDetailedPage);       
    }

    ngOnInit() {
        this.checkWalkdownForecatAccessibility();
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.isDeliveryEngineer = this.roleService.isInRole(Constants.applicableGroups.DeliveryEngineerAccess);
        this.filterExpansionPanel.expanded = false;
        this.lookupService
            .getLunUrl()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((url) => {
                this.lunUrl = url;
            });

        this.lookupService
            .getBacklogCats()
            .pipe(take(1))
            .subscribe((data) => (this.backlogCats = data.map((d) => d.name)));

        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });
        this.isReadOnly = this.roleService.isReadOnly();
        this.blueZoneDataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            _.map(
                _.groupBy(
                    data.items.map((i) => ({ ...i, ...new BlueZoneDTO() })),
                    (item) => item.subsystem
                ),
                (group) => (_.last(group).isLastInGroup = true)
            );
            this.data = data.items;
            this.setReadyForRFSUFlags();
            this.resultsLength = data.totalCount;
        });

        if(this.rfoNumber != null)
        {
          let rfoData: RFO = {
            name: this.rfoNumber,
            rfoName: this.rfoName,
            projectTeamName: "3GP",
            }

          this.filterForm.controls['rfos'].setValue([rfoData])
          if(this.rfsuRemaining)
            this.filterForm.controls['rfsuRemaining'].setValue(this.rfsuRemaining);
          
          this.filterForm.controls['rfsuCompleted'].setValue(this.rfsuCompleted);       
          this.search()
        }

        this.blueZoneLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => (this.isLoading = isLoading));

        this.blueZoneFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam(filter.projectTeamNames || []);
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.paginator.pageIndex = filter.page;
            this.showHideColumns = filter.showHideColumns;
            this.pageSize = filter.take;
            this.disciplineBreakdownEnabled = filter.disciplineBreakdownEnabled;
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new BlueZoneFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new BlueZoneFilterRequest());
        });

        this.filterForm.controls.waypoints.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((waypoints: Waypoint[]) => {
                this.prevWaypoints = waypoints;
                this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
            });

        

        this.filterForm.controls.milestones.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((milestones: Milestone[]) => {
                let waypoints = JSON.parse(JSON.stringify(milestones));
                waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
                waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name');
                this.setMilestoneInput.emit(new SetInputEventArgs(false, '', milestones));
                if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) {
                    this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                    this.filterForm.controls.waypoints.setValue(waypoints);
                }
            });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => (this.sortBy = sortBy));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => (this.direction = direction));

        this.columnRFSUForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFSUForecast = data;
        });
        this.columnRFOPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFOPlan = data;
        });
        this.columnDisciplines$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnDisciplines = items));
        this.columnRFSUPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFSUPlan = data;
        });
        this.columnRFSUWalkdownPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFSUWalkdownPlan = data;
        });
        this.columnRFSUCommitment$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFSUCommitment = data;
        });
        this.columnComments$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnComments = data;
        });
        this.columnBacklogCats$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnBacklogCats = data;
        });
        this.columnRFSUActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRFSUActual = data;
        });
        this.columnRfo$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnRfo = data;
        });
        this.columnWaypoints$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnWaypoints = data;
        });
        this.columnWaypointDescription$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnWaypointDescription = data;
        });
        this.columnWaypointTargetDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnWaypointTargetDate = data;
        });
        this.columnMilestones$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnMilestones = data;
        });
        this.columnMilestoneDescription$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnMilestoneDescription = data;
        });
        this.columnMilestoneTargetDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnMilestoneTargetDate = data;
        });    
        this.columnSystemOwner$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnSystemOwner = data;
        });
        this.columnDeliveryEngineers$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnDeliveryEngineers = data;
        });
        this.columnEdossierInitiation$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnEdossierInitiation = data;
        });
        this.columnEdossierStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnEdossierStatus = data;
        });
        this.columnWalkdownActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnWalkdownActual = data;
        });
        this.columnMCPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnMCPlan = data;
        });
        this.columnMCForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnMCForecast = data;
        });
        this.columnMCActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnMCActual = data;
        });
        this.columnWalkdownForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnWalkdownForecast = data;
        });
        this.columnWalkdownSch$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.columnWalkdownSch = data;
        });

        this.columnSubsystems$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnSubsystems = items;
        });

        this.columnSubsystemNames$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnSubsystemNames = items;
        });
        this.columnPriorityName$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnPriorityName = items;
        });
        this.columnAreaBreakdown$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnAreaBreakdown = items;
        });
        this.columnContractorNo$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnContractorNo  = items;
        });
        this.columnAreaCompletionLead$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            this.columnAreaCompletionLead = items;
        });
        this.columnNonCommissionable$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnNonCommissionable = items));
            this.columnFlangeMgtTotal$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnFlangeMgtTotal = items));
            this.columnFlangeMgtTotalCompleted$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnFlangeMgtTotalCompleted = items));
            this.columnFlangeMgtTotalRemaining$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnFlangeMgtTotalRemaining = items));
            this.columnFlangeMgtTotalPercentage$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnFlangeMgtTotalPercentage = items));

        this.columnACTsRemaining$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnACTsRemaining = items));
            
        this.columnGoocNo$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnGoocNo = items));
        this.columnFlags$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => (this.columnFlags = items));
        this.columnWalkdownCommitments$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((items) => (this.columnWalkdownCommitments = items));

        this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            if (paramMap.get('subsystem') && (paramMap.get('showDisciplineBreakdown') || !paramMap.get('discipline'))) {
                this.resetFilters();
                let subystemFilter = new CheckListColumn();
                subystemFilter.items = [decodeURIComponent(paramMap.get('subsystem'))];
                this.columnSubsystems = subystemFilter;
                this.filterForm.controls.columnSubsystems.setValue(subystemFilter);
                this.paginator.pageIndex = 0;
                this.store.dispatch(
                    new BlueZoneFilterPropertyUpdate({
                        key: 'page',
                        value: 0,
                    })
                );
                if (this.disciplineBreakdownEnabled) {
                    this.toggleDisciplineBreakdown();
                } else {
                    this.store.dispatch(new BlueZoneFilterRequest(this.filterForm.value));
                }
            } else if (paramMap.get('subsystem') && paramMap.get('discipline')) {
                this.resetFilters();

                let subystemFilter = new CheckListColumn();
                subystemFilter.items = [decodeURIComponent(paramMap.get('subsystem'))];

                let disciplineFilter = new CheckListColumn();
                const discipline = decodeURIComponent(paramMap.get('discipline'));
                disciplineFilter.items = discipline === '(Blanks)' ? [''] : [discipline];

                this.columnSubsystems = subystemFilter;
                this.columnDisciplines = disciplineFilter;
                this.filterForm.controls.columnSubsystems.setValue(subystemFilter);
                this.filterForm.controls.columnDisciplines.setValue(disciplineFilter);
                this.paginator.pageIndex = 0;
                this.store.dispatch(
                    new BlueZoneFilterPropertyUpdate({
                        key: 'page',
                        value: 0,
                    })
                );
                if (!this.disciplineBreakdownEnabled) {
                    this.toggleDisciplineBreakdown();
                } else {
                    this.store.dispatch(new BlueZoneFilterPropertyUpdate(this.filterForm.value));
                }
            } else if (this.resultsLength < this.pageSize) {
                this.store.dispatch(new BlueZoneFilterRequest());
            }
        });

        this.subscribeNumericFiltersUpdates();
    }

    checkWalkdownForecatAccessibility() {
        this.isWalkdownForecast = this.roleService.isInRole(Constants.applicableGroups.Admin) ||this.roleService.isInRole(Constants.applicableGroups.BlueZoneWalkdownForecastDate);       
    }

    ngAfterViewInit() {
        this.store
            .select((state) => state.detailedStatusState.tableOffset.bluezone)
            .pipe(take(1))
            .subscribe((offset) => (this.table.nativeElement.scrollTop = offset ?? 0));

        this.store.dispatch(new DetailedStatusSetTableOffset({ bluezone: 0 }));
    }

    ngAfterViewChecked(): void {
        if (this.matTable) {
            this.matTable.updateStickyColumnStyles();
        }
    }

    getSubsystemsInfoMessage() {
        if (this.resultsLength == 1) {
            if (this.disciplineBreakdownEnabled) {
                return 'subsystem-discipline';
            } else {
                return 'subsystem';
            }
        } else {
            if (this.disciplineBreakdownEnabled) {
                return 'subsystems-disciplines';
            } else {
                return 'subsystems';
            }
        }
    }

    subscribeNumericFiltersUpdates() {
        for (let col of this.numericColumns) {
            if (this[`${col}$`]) {
                this[`${col}$`].pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this[col] = data));
            }
        }
    }

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    reverseValue(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };
        this.store.dispatch(
            new BlueZoneFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showHideColumns,
            })
        );
    }

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.isUsersPerProjectLoading = true;
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((contractors: Contractor[]) => {
                return contractors;
            })
        );
    };

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((waypoints: Waypoint[]) => {
                return waypoints;
            })
        );
    };

    getNonCommissionable = () => of(['Yes', 'No', 'Empty']);

    getSystems = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchSystems(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((systems: System[]) => systems));
    };

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMilestones(
                search,
                take,
                page,
                this.filterForm.value.waypoints,
                this.filterForm.value.projectTeamNames
            )
            .pipe(map((milestones: Milestone[]) => milestones));
    };

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    resetFilters(event: Event = null) {
        if (event) {
            event.stopPropagation();
        }
        this.filterExpansionPanel.expanded = false;
        this.store.dispatch(new BlueZoneFilterReset());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setSystemInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        // this.setConstructionAreaInput.emit(new SetInputEventArgs(false, ''));
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.setValue(new BlueZoneFilter());
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new BlueZoneFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.filterForm, key, value);
                }
            }
        }
    }

    search() {
        this.updateFilterByFormProperties();

        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new BlueZoneFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new BlueZoneFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new BlueZoneFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new BlueZoneFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new BlueZoneFilterRequest());
    }

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    saveScrollProgress() {
        this.store.dispatch(new DetailedStatusSetTableOffset({ bluezone: this.table.nativeElement.scrollTop }));
    }

    scrollToDetails() {
        setTimeout(() => {
            this.detailedPage.nativeElement.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 500);
    }

    resetDetailedDataITRs(scope: string) {
        this.showDetailedPage = null;

        setTimeout(() => {
            this.showDetailedPage = scope
            this.store.dispatch(new DetailedStatusResetLockedFilter());
            this.store.dispatch(new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(scope), scope }));
        //this.store.dispatch(new DetailedStatusSetMakeRequest());
        }, 100);
    }

    openDetailedStatusPage(subsystem: string, scope: string, discipline: string, category?: string, status?: string[]) {
        const disciplines = discipline ? [discipline] : [];
        this.blueScope = scope;
        this.blueSubsystem = subsystem;
        this.blueStatus = status;
        this.blueDiscipline = disciplines;
        this.blueZone = scope != 'ConstraintDetails' ? Zone.Blue.toString() : null;
        this.blueCategory = category;
        this.resetDetailedDataITRs(scope);
    }

    resetDetailedDataDocs(scope: string) {
        this.showDetailedPage = null;

        setTimeout(() => {
            this.showDetailedPage = scope
            this.store.dispatch(new ChangeManagementSetMakeRequest());
            this.store.dispatch(new ChangeManagementFilterReset());
            this.scrollToDetails();
          }, 100);
    }

    openChangeManagementPage(subsystem: string, changeType: string, lockFilters = true) {
        this.blueSubsystem = subsystem;
        this.blueChangeType = changeType;
        this.resetDetailedDataDocs(changeType);
    }

    resetDetailedDataPASCR(scope: string) {
        this.showDetailedPage = null;

        setTimeout(() => {
            this.showDetailedPage = scope
            this.store.dispatch(new PascrStatusFilterReset());
            this.scrollToDetails();
          }, 100);
    }

    gotoPascr(element : any)
    {
        this.blueSubsystem = element.subsystem;
        this.resetDetailedDataPASCR('PASCR');
    }

    /*
    openDetailedStatusPage(subsystem: string, scope: string, discipline: string, category?: string, status?: string[]) {
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.store.dispatch(
            new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(scope), scope })
        );

        this.saveScrollProgress();

        this.store.dispatch(new DetailedStatusSetMakeRequest());
        const disciplines = discipline ? [discipline] : null;
        this.ngZone.run(() =>
            this.router.navigate([`/detailedstatus`], {
                queryParams: {
                    scope,
                    subsystem,
                    discipline: disciplines,
                    category,
                    zone: scope != 'ConstraintDetails' ? Zone.Blue : null,
                    status,
                    lockFilters: true,
                    allowCommentsBulkUpdate: true,
                },
            })
        );
    }

    openChangeManagementPage(subsystem: string, changeType: string, lockFilters = true) {
        this.store.dispatch(new ChangeManagementSetMakeRequest());
        this.store.dispatch(new ChangeManagementFilterReset());
        this.saveScrollProgress();
        this.router.navigate(['/changemanagement'], {
            queryParams: { subsystem, changeType, lockFilters, allowCommentsBulkUpdate: true },
        });
    }

    gotoPascr(element : any)
    {
        this.ngZone.run(() =>
        this.router.navigate([`/pascrstatus`], {
            queryParams: {
                SubSystem : element.subsystem
            },
        })
        );
    }
    */

    openWeeklPlanningPage(element: BlueZoneDTO) {
        if (element.constrainedActivitiesCount > 0) {
            this.store.dispatch(new ScheduleActivityClearFilters());

            const disciplines = element.discipline ? [{ name: element.discipline }] : [];
            this.store.dispatch(
                new ScheduleActivityPlanningFilterPropertyUpdate({
                    filter: {
                        subsystems: [{ id: element.subsystem, name: element.subsystemName }],
                        disciplines: disciplines,
                        hasNoConstraint: false,
                        weekStart: getNextWeekStartDate(),
                    } as ScheduleActivityPlanningFilter,
                })
            );
            this.store.dispatch(new ScheduleActivityPlanningDataRequest());
            this.store.dispatch(new ScheduleActivityWeekSummaryDataRequest());

            this.ngZone.run(() => this.router.navigate([`/weeklyplanning/lookahead/weekly`]));
        }
    }

    openLUNPage(subsystem: string) {
        window.open(`${this.lunUrl}/register?subsystems=${subsystem}`, '_blank');
    }

    forecastDateHistory(event: MouseEvent, element: BlueZoneDTO, type: string) {
        event.preventDefault();
        this.popupSvc.openPopup(
            new PopupSettings(ForecastHistoryComponent, 550, 500, {
                subsystem: element.subsystem,
                dateType: type,
                discipline: element.discipline,
            })
        );
    }

    openDatepicker(element: BlueZoneDTO, type: string) {
        let date;
        let startDate: Date;
        startDate = new Date();
        if (type === 'rfsuWalkdown') {
            if(!this.isAdmin)
            startDate.setDate( startDate.getDate() + 3 );
        }

        if (type === 'rfsu' && !this.isDeliveryEngineer && !this.isAdmin  ) {
            return;
        }
        if (type === 'rfsuWalkdown' && (this.isReadOnly || !this.isWalkdownForecast)  ) {
            return;
        }

        if (type === 'rfsu' && !this.isAdmin) {
            if (element.rfsuActual && moment(element.rfsuPlan).isAfter(moment())) {
            return;
            }
            date = element.rfsuForecast ? element.rfsuForecast : '';
        } else if (type === 'rfsuWalkdown' && !this.isWalkdownForecast) {
            if (element.walkdownActual && element.walkdownCommitted) {
                return;
            }
            date = element.walkdownForecast ? element.walkdownForecast : '';
        }

        this.popupSvc
            .openPopup(
                new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                    data: this.data,
                    selectedDate: date,
                    selectedDateSubsystem: element.subsystem,
                    dateType: type,
                    discipline: element.discipline,
                    minDate: startDate,
                })
            )
            .afterClosed()
            .pipe(take(1))
            .subscribe((answer) => {
                if (!answer) return;
                this.data = this.data.map((i) => (i.subsystem === element.subsystem ? { ...i } : i));
                this.isLoading = false;
            });
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    displaySystemsSelected(values: System[]) {
        return values.map((x) => x.no).join(', ');
    }

    displaySelectedBoolean(boolValue: boolean) {
        if (boolValue === null) {
            return 'All';
        }
        return boolValue ? 'Yes' : 'No';
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    displayMultipleSelected(values: (Milestone | Waypoint | Contractor | RFO)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayMultipleFilterSelected(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    displaySelectedACM(acmIds: number[]) {
        return this.acms
            .filter((acm) => acmIds.includes(acm.id))
            .map((acm) => acm.name)
            .join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displaySelectedDeliveryEngineer(deliveryEngineerIds: number[]) {
        return this.deliveryEngineers
            .filter((deliveryEngineer) => deliveryEngineerIds.includes(deliveryEngineer.id))
            .map((deliveryEngineer) => deliveryEngineer.name)
            .join(', ');
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        if (this.filterForm.controls[controlName] != null) {
            let valid =
                this.filterForm.controls[controlName].value.length &&
                this.filterForm.controls[controlName].value.filter(
                    (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
                );
            if (valid) {
                this.filterForm.controls[controlName].setValue(valid);
            }
        }
    }

    resetColumns() {
        this.showHideColumns = new ShowHideColumns();
    }

    private getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'milestones');
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'waypoints');
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'contractors');
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'rfos');
        this.setSystemInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'systems');
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'goocs');
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'priorityNames');

        forkJoin([
            this.lookupService.getACMs(projectTeamNames),
            this.lookupService.getSCManagers(projectTeamNames),
            this.lookupService.getACLeads(projectTeamNames),
            this.lookupService.getDeliveryEngineers(projectTeamNames),
            this.lookupService.getMCEngineers(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([acms, scManagers, areaCompletionLeads, deliveryEngineers, mcEngineers, sysOwners]) => {
                    this.acms = acms;
                    this.checkIfSelectedUsersValid(this.acms, 'acm', 'id');
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                    this.areaCompletionLeads = areaCompletionLeads;
                    this.checkIfSelectedUsersValid(this.areaCompletionLeads, 'areaCompletionLead', 'id');
                    this.deliveryEngineers = deliveryEngineers;
                    this.checkIfSelectedUsersValid(this.deliveryEngineers, 'deliveryEngineer', 'id');
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    private checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm?.controls[formControlName].value.length &&
            this.filterForm?.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm?.controls[formControlName].setValue(valid);
        }
    }

    openCommentPopup(element: BlueZoneDTO, event: any) {
        const comment = element.comment || '';
        if (this.isReadOnly) return;

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: element.mentions,
                url: `bluezone?subsystem=${element.subsystem}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new BlueZoneAddBulkCommentRequest({
                                description: value,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new BlueZoneAddCommentRequest({
                                entityId: this.disciplineBreakdownEnabled
                                    ? element.subsystemToDisciplineId
                                    : element.subsystem,
                                description: value,
                                mentions,
                            })
                        );
                        this.search();
                    }
                },
            })
        );

        event.stopPropagation();
    }

    private getLatestFilterData(): BlueZoneFilter {
        let filter: BlueZoneFilter;
        this.store.pipe(select((x) => x.blueZoneState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getWalkdownCommitments = (search = '', take = 30, page = 0) =>
        this.lookupService.searchWalkdownCommitmentsWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getSubsystems = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemsWithBlueZoneFilter(search, take, page, this.getLatestFilterData());
    
    getRfosFilter = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRfosWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getWaypointsFilter = (search = '', take = 30, page = 0) =>
        this.lookupService.searchWaypointsWithBlueZoneFilter(search, take, page, this.getLatestFilterData());
    
    getWaypointDescriptionFilter = (search = '', take = 30, page = 0) =>
        this.lookupService.searchWaypointDescriptionWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getMilestonesFilter = (search = '', take = 30, page = 0) =>
        this.lookupService.searchMilestonesWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getMilestoneDescriptionFilter = (search = '', take = 30, page = 0) =>
        this.lookupService.searchMilestoneDescriptionWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getSystemOwnerFilter = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSystemOwnerWithBlueZoneFilter(search, take, page, this.getLatestFilterData());
    
    getDeliveryEngineer = (search = '', take=30, page =0) =>
    this.lookupService.searchDeliveryEngineerWithBlueZoneFilter(search, take, page,this.getLatestFilterData());

    getSubsystemNames = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemNamesWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    // getConstructionArea = (search = '', take = 30, page = 0) =>
    //     this.lookupService.searchConstructionAreaWithBlueZoneFilter(search, take, page, this.getLatestFilterData());
    
    getAreaBreakdown = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakdownWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getComments = (search = '', take = 30, page = 0) =>
        this.lookupService.searchCommentsWithBluezoneFilter(search, take, page, this.getLatestFilterData());

    getDisciplines = (search = '', take = 30, page = 0) =>
        this.lookupService.searchDisciplinesWithBluezoneFilter(search, take, page, this.getLatestFilterData());

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getPriorityNames = (search = '', take = 30, page = 0) =>
        this.lookupService.searchPriorityNamesWithBlueZoneFilter(search, take, page, this.getLatestFilterData());
    
    getAreaCompletionLeads = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaCompletionLeadsWithBlueZoneFilter(search, take, page, this.getLatestFilterData());
    
    getAreaBreakdowns = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakdownsWithBlueZoneFilter(search, take, page, this.getLatestFilterData());
    
    getContractorNo = (search = '', take = 30, page = 0) =>
        this.lookupService.searchContractNoWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getGoocNo = (search = '', take = 30, page = 0) =>
        this.lookupService.searchGoocNoWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getBacklogCats = (search = '', take = 30, page = 0) =>
        this.lookupService.searchBacklogCatsWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getEdossierStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchEdossierStatusesWithBlueZoneFilter(search, take, page, this.getLatestFilterData());

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((prio: StagedStartUpPriority[]) => prio));
    };

    onStagedStartUpPrioritiesClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    eDossierLink(subsystem: string) {
        let Link = "https://chevron.sharepoint.com/sites/3PC-syscompcd/3gp/rfsu/sitepages/rfsu_tracking.aspx?selectedTab=0&subsystemno=";
        return Link + encodeURIComponent(subsystem); //subsystem.replace("&", "%26").replace("/","_");
    };

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 660, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                excludeBlanks: excludeBlanks,
                onlyBlanks: onlyBlanks,
                selectedFlags: this.columnFlags ? [...this.columnFlags.items] : [],
                isSortingOff: false,
                showCounts: showCounts,
                showInputSearch,
                showBlankOptions: showBlankOptions,
                showTrafficLightFilter: columnName === 'subsystem',
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.filterForm.controls[propertyName].setValue(value);
                    if (columnName === 'subsystem') {
                        let flags = new CheckListColumn();
                        flags.items = data.selectedFlags;
                        this.filterForm.controls['columnFlags'].setValue(flags);
                    }
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new BlueZoneFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                    if (columnName === 'subsystem') {
                        this.store.dispatch(new BlueZoneFilterPropertyUpdate({ key: 'columnFlags', value: null }));
                    }
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new BlueZoneFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                    if (columnName === 'subsystem') {
                        this.store.dispatch(
                            new BlueZoneFilterPropertyUpdate({
                                key: 'columnFlags',
                                value: this.filterForm.controls['columnFlags'].value,
                            })
                        );
                    }
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(
                HeaderDateFilterComponent,
                null,
                null,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                    placeHolder: placeholder,
                    isSortingOff: false,
                    action: (data) => {
                        if (data.calendarColumn.rangeDates.length > 0) {
                            data.calendarColumn.rangeDates[0] = this.isNotNullAndNotUndefined(data.calendarColumn.rangeDates[0]) ? moment.utc(data.calendarColumn.rangeDates[0]).startOf('day').toDate() : undefined;
                            data.calendarColumn.rangeDates[1] = this.isNotNullAndNotUndefined(data.calendarColumn.rangeDates[1]) ? moment.utc(data.calendarColumn.rangeDates[1]).startOf('day').toDate() : undefined;
                        }
                        this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                        this.search();
                    },
                },
                345,
                580
            )
        );
    }

    openHistoryPopup(element: BlueZoneDTO) {
        if (element.comment === null) {
            return;
        } else {
            this.isLoading = true;
            this.commentService
                .getCommentsBySubsystem(
                    this.disciplineBreakdownEnabled ? element.subsystemToDisciplineId.toString() : element.subsystem,
                    this.disciplineBreakdownEnabled ? Zone.Blue : Zone.BlueNoDiscipline
                )
                .subscribe((data) => {
                    this.isLoading = false;
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            CommentsHistoryComponent,
                            null,
                            null,
                            {
                                commentHist: data,
                                action: (id: number) => {
                                    data = data.filter((s) => s.id !== id);
                                    this.store.dispatch(
                                        new BlueZoneRemoveCommentRequest({
                                            id,
                                            lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                            entityId: this.disciplineBreakdownEnabled
                                                ? element.subsystemToDisciplineId
                                                : element.subsystem,
                                        })
                                    );
                                },
                            },
                            200,
                            200
                        )
                    );
                });
        }
    }

    openHeaderNumericFilter(property: string, currentState: NumericColumn, placeHolder: string) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderNumericFilter>(HeaderNumericFilterComponent, 400, 350, {
                placeHolder: placeHolder,
                showBlankOptions: true,
                isAscendingSort: this.sortBy === property && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === property && this.direction == OrderDirection.Desc,
                minValue: currentState ? currentState.minValue : null,
                maxValue: currentState ? currentState.maxValue : null,
                excludeBlanks: currentState ? currentState.excludeBlanks : false,
                onlyBlanks: currentState ? currentState.onlyBlanks : false,
                onSumbit: (data: HeaderNumericFilter) => {
                    let newFilterState = new NumericColumn();
                    newFilterState = { ...data };

                    const columnName = `column${this.capitalizeFirstLetter(property)}`;
                    if (
                        this.numericColumns.includes(columnName) &&
                        this.filterForm.controls.hasOwnProperty(columnName)
                    ) {
                        this.filterForm.controls[columnName].setValue(newFilterState);
                    }

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, property);
                    this.search();
                },
                onReset: () => {
                    const columnName = `column${this.capitalizeFirstLetter(property)}`;
                    this.store.dispatch(
                        new BlueZoneFilterPropertyUpdate({
                            key: columnName,
                            value: null,
                        })
                    );
                },
            } as HeaderNumericFilter)
        );
    }

    applySort(property: string) {
        let isDesc = true;
        if (this.sortBy === property) {
            isDesc = this.direction === OrderDirection.Asc;
        }

        this.sortUpdate(!isDesc, isDesc, property);
        this.search();
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new BlueZoneFilterPropertyUpdate({ key: 'sortBy', value: { active: columnName, direction: direction } })
            );
        } else {
            this.store.dispatch(
                new BlueZoneFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: this.sortBy, direction: this.direction },
                })
            );
        }
    }

    setReadyForRFSUFlags() {
        this.data = this.data.map((d) => ({
            ...d,
            isReadyForRFSU:
                d.aqvd === 0 &&
                d.apli === 0 &&
                d.bitRsRemaining === 0 &&
                d.citRsRemaining === 0 &&
                d.bpli === 0 &&
                d.npw === 0 &&
                d.ex === 0,
        }));
    }

    exportToExcel() {
        this.store.dispatch(new BlueZoneExportToExcelRequest());
    }

    onePager() {
        this.store.dispatch(new BlueZoneExportOnePagerRequest());
    }

    toggleDisciplineBreakdown() {
        this.displayedColumns = this.disciplineBreakdownEnabled
            ? this.defaultDisplayedColumns.filter((c) => c !== 'discipline')
            : this.defaultDisplayedColumns;

        this.disciplineBreakdownEnabled = !this.disciplineBreakdownEnabled;
        if (this.disciplineBreakdownEnabled && this.showHideColumns.showBacklogCat) {
            this.reverseValue('showBacklogCat');
        }
        this.filterForm.controls.disciplineBreakdownEnabled.setValue(this.disciplineBreakdownEnabled);
        this.store.dispatch(
            new BlueZoneFilterPropertyUpdate({
                key: 'disciplineBreakdownEnabled',
                value: this.disciplineBreakdownEnabled,
            })
        );
        this.filterForm.controls.columnDisciplines.setValue(null);
        this.store.dispatch(
            new BlueZoneFilterPropertyUpdate({
                key: 'columnDisciplines',
                value: null,
            })
        );
        this.store.dispatch(new BlueZoneFilterRequest(this.filterForm.value));
    }

    highlightRow(row: any) {
        this.selectedRowIndex = row;
    }

    enterEditMode(element: BlueZoneDTO) {
        element.isInEditMode = true;
        element.prevBacklogCat = element.backlogCat;
    }

    cancelEdit(element: BlueZoneDTO) {
        element.isInEditMode = false;
        element.backlogCat = element.prevBacklogCat;
    }

    saveBacklogCat(element: BlueZoneDTO) {
        element.isInEditMode = false;
        if (element.backlogCat !== element.prevBacklogCat) {
            this.store.dispatch(new BlueZoneUpdateBacklogCatRequest(element));
        }
    }

    isBacklogCatEditable(element: BlueZoneDTO) {
        return !element.rfsuActual || moment().utc().isAfter(moment(element.rfsuPlan), 'days');
    }

    selectSubsystem(subsystem: string) {
        this.selectedSubsystem = subsystem;
    }

    getSubsystemClasses(data: BlueZoneDTO[], index: number) {
        const flagClasses = this.getFlagColorClassName(data[index]);
        return {
            ...flagClasses,
            'row-underline': this.isRowUnderlined(data, index),
        };
    }

    getFlagColorClassName(item: BlueZoneDTO) {
        return { [`${item.blueZoneFlag}-flag`]: !!item.blueZoneFlag, flag: true };
    }

    isRowUnderlined(data: BlueZoneDTO[], index: number) {
        if (!this.disciplineBreakdownEnabled || index === data.length - 1) {
            return false;
        } else {
            return data[index].subsystem != data[index + 1].subsystem;
        }
    }

    clearflagSubsystem() {
        this.popupSvc
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to clear flags for all filtered subsystems (${this.resultsLength})?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.store.dispatch(new BlueZoneClearFlagColorRequest());
                }
            });
    }

    flagSubsystem(subsystem: string, color: string) {
        this.store.dispatch(new BlueZoneSetFlagColorRequest({ subsystem, color }));
        this.popoverContent.hide();
    }

    toggleExpansionPanel(event: Event) {
        if (event) event.stopPropagation();
        this.filterExpansionPanel.toggle();
    }

    toggleWalkdownCommitment(item: BlueZoneDTO) {
        if (!item.walkdownCommitted || this.isAdmin) {
            this.popupSvc
                .openPopup(
                    new ConfirmDialogPopupSettings({
                        title: 'Confirm action',
                        text: `Would you like to ${
                            item.walkdownCommitted ? 'remove walkdown commitment' : 'commit to the walkdown'
                        } for this subsystem?`,
                    })
                )
                .afterClosed()
                .pipe(take(1))
                .subscribe((answer) => {
                    if (answer) {
                        this.store.dispatch(
                            new BlueZoneToggleWalkdownCommitmentRequest({
                                subsystem: item.subsystem,
                                committed: !item.walkdownCommitted,
                            })
                        );
                        this.data = this.data.map((i) => {
                            if (i.subsystem === item.subsystem) {
                                return { ...i, walkdownCommitted: !item.walkdownCommitted };
                            }
                            return i;
                        });
                    }
                });
        }
    }   
}
