import { IMDBState } from './model';
import { IMDBActions, IMDBActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: IMDBState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isDataReadyToProcess: false,
    recordsForDeletion: 0,
    isAutomaticUploadInProgress: true,
};

export function reducer(state = initialState, action: IMDBActions) {
    switch (action.type) {
        case IMDBActionTypes.IMDBUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case IMDBActionTypes.IMDBUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case IMDBActionTypes.IMDBUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case IMDBActionTypes.IMDBSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case IMDBActionTypes.IMDBFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case IMDBActionTypes.IMDBClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case IMDBActionTypes.IMDBDataInfoSuccess: {
            return {
                ...state,
                isDataReadyToProcess: action.payload.readyToProcess[0],
                recordsForDeletion: action.payload.recordsForDeletion[0],
                isAutomaticUploadInProgress: action.payload.isAutomaticUploadInProgress,
            };
        }
        case IMDBActionTypes.IMDBDataInfoError: {
            return {
                ...state,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case IMDBActionTypes.IMDBDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case IMDBActionTypes.IMDBDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case IMDBActionTypes.IMDBDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case IMDBActionTypes.IMDBAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'IMDB';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        case IMDBActionTypes.IMDBValidateDeltaRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case IMDBActionTypes.IMDBValidateDeltaSuccess: {
            return {
                ...state,
                isLoading: false,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case IMDBActionTypes.IMDBValidateDeltaError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}
