<form [formGroup]="filterForm">
    <mat-accordion class="filter-container">
        <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" expanded>
            <mat-expansion-panel-header collapsedHeight="auto">
                <div *ngIf="!filterExpansionPanel.expanded">
                    <mat-chip-list aria-label="Active filters">
                        <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                            Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                            Area Breakdown:
                            {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                            RFSU Contractor:
                            {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                        </mat-basic-chip>
                    </mat-chip-list>
                </div>
            </mat-expansion-panel-header>
            <ng-container>
                
                <div *ngIf="planningType != 'rfsu'" class="mixedapp-container">
                    <app-multiple-items-selector
                        class="container620"
                        [setInput]="setGoocInput"
                        formControlName="goocs"
                        [searchFunc]="getGoocs"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['no', 'name']"
                        [filterForm]="filterForm"
                        [formSetter]="'goocs'"
                        [propertyToShow]="'no'"
                        [placeholder]="'GOOC'"
                        [panelWidth]="480"
                    >
                    </app-multiple-items-selector>
                </div>

                <div class="mixedapp-container" *ngIf="planningType === 'mc'">
                    <app-multiple-items-selector
                        [setInput]="setStagedStartUpPrioritiesInput"
                        formControlName="stagedStartUpPriorities"
                        [searchFunc]="getStagedStartUpPriorities"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['priority', 'priorityName']"
                        (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                        (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                        [filterForm]="filterForm"
                        formSetter="stagedStartUpPriorities"
                        [propertyToShow]="'priority'"                                    
                        [secondPropertyToShow]="'priorityName'"
                        placeholder="Staged Start-up Priority"
                        [panelWidth]="480"
                        class="container300"
                    >
                    </app-multiple-items-selector>
                </div>

                <div class="mixedapp-container" *ngIf="planningType === 'rfsu'">

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManagers" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwners" multiple>
                            <mat-option *ngFor="let so of systemOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            [setInput]="setStagedStartUpPrioritiesInput"
                            formControlName="stagedStartUpPriorities"
                            [searchFunc]="getStagedStartUpPriorities"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['priority', 'priorityName']"
                            (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                            (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                            [filterForm]="filterForm"
                            formSetter="stagedStartUpPriorities"
                            [propertyToShow]="'priority'"                                    
                            [secondPropertyToShow]="'priorityName'"
                            placeholder="Staged Start-up Priority"
                            [panelWidth]="480"
                            class="container300"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFSUContractorsInput"
                            formControlName="rfsuContractors"
                            [searchFunc]="getRFSUContractors"
                            [isAsync]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            [filterForm]="filterForm"
                            [formSetter]="'rfsuContractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'RFSU Contractor'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>

                </div>
                <div class="buttons-container flex">
                    <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                        reset filters
                    </button>
                    <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
                </div>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</form>
