<main class="main">
    <div class="title-container">
        <div class="title">
            Tag Search
        </div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.tagTypes.value.length" removable>
                                Tag Type: {{ filterForm.controls.tagTypes.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('tagTypes')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.tags.value.length" removable>
                                Tag No:
                                {{ displayMultipleSelected(filterForm.controls.tags.value, 'id') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('tags')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.tagDescription.value" removable>
                                Tag Description: {{ filterForm.controls.tagDescription.value }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('tagDescription')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.tagStatuses.value.length" removable>
                                Tag Status: {{ filterForm.controls.tagStatuses.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('tagStatuses')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.subsystems.value.length" removable>
                                Subsystem: {{ displayMultipleSelected(filterForm.controls.subsystems.value, 'id') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('subsystems')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfos?.value.length" removable>
                                RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value, 'name') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('rfos')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                Area Breakdown:
                                {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Tag Type</mat-label>
                        <mat-select formControlName="tagTypes" multiple>
                            <mat-option *ngFor="let ct of tagTypes" [value]="ct">{{ ct }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="container950-mh55 margin-right20">
                        <app-multiple-items-selector
                            class="container950"
                            [setInput]="setTagInput"
                            formControlName="tags"
                            [searchFunc]="getTags"
                            [isAsync]="true"
                            placeholder="Tag No"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div class="mixedapp-container">
                    <mat-form-field class="container600 margin-right20">
                        <mat-label>Tag Description</mat-label>
                        <input matInput formControlName="tagDescription" />
                    </mat-form-field>
                    <mat-form-field class="container156 margin-right20">
                        <mat-label>Tag Status</mat-label>
                        <mat-select formControlName="tagStatuses" multiple>
                            <mat-option *ngFor="let ts of tagStatuses" [value]="ts">{{ ts }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container156 margin-right20">
                        <mat-label>Project Team</mat-label>
                        <mat-select formControlName="projectTeamNames" multiple>
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-multiple-items-selector
                        class="container300"
                        [setInput]="setRFOInput"
                        formControlName="rfos"
                        [searchFunc]="getRFOs"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'rfos'"
                        [propertyToShow]="'name'"
                        [placeholder]="'RFO'"
                        [secondPropertyToShow]="'rfoName'"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['name', 'rfoName']"
                        [panelWidth]="480"
                    >
                    </app-multiple-items-selector>
                </div>
                <div class="mixedapp-container">
                    <div class="container620-mh55 margin-right20">
                        <app-multiple-items-selector
                            class="containerfull"
                            formControlName="subsystems"
                            [searchFunc]="getSubsystems"
                            [isAsync]="true"
                            [displayedColumns]="subsystemAutocompleteDisplayedColumns"
                            placeholder="Subsystem"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container620-mh55 margin-right20">
                        <app-multiple-items-selector
                                class="container300"
                                [setInput]="setAreaBreakdownInput"
                                formControlName="areaBreakdown"
                                [searchFunc]="getAreaBreakdown"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'areaBreakdown'"
                                [placeholder]="'Area Breakdown'"
                            >
                        </app-multiple-items-selector>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
                export to excel
            </button>
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
    </form>
    <div class="results-container">
        <div class="scroll" app-double-scroll>
            <table mat-table [dataSource]="data" multiTemplateDataRows class="mat-elevation-z8">
                <ng-container matColumnDef="tagNo" sticky>
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnTagNo)
                                }"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'tagNo',
                                        getTagNumbers,
                                        'columnTagNo',
                                        'TagNo',
                                        columnTagNo
                                    )
                                "
                                >Tag No</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'tagNo'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="tree-icon">
                            <div
                                [ngClass]="{
                                    hidden: !element.itRs.length && !element.plIs.length && !element.changeDocs.length
                                }"
                            >
                                <mat-icon
                                    (click)="expandedElement = expandedElement === element ? null : element"
                                    *ngIf="expandedElement != element"
                                    >chevron_right</mat-icon
                                >
                                <mat-icon
                                    (click)="expandedElement = expandedElement === element ? null : element"
                                    *ngIf="expandedElement == element"
                                    >expand_more</mat-icon
                                >
                            </div>
                            <span>{{ element.tagDetails.tagNo }}</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="tagType">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container center">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnTagType)
                                }"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'tagType',
                                        getTagTypes,
                                        'columnTagType',
                                        'TagType',
                                        columnTagType
                                    )
                                "
                                >Tag Type</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'tagType'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.tagDetails.tagType }}</td>
                </ng-container>
                <ng-container matColumnDef="tagName">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnTagName)
                                }"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'tagName',
                                        getTagNames,
                                        'columnTagName',
                                        'Tag Description',
                                        columnTagName
                                    )
                                "
                                >Tag Description</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'tagName'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.tagDetails.tagName }}</td>
                </ng-container>
                <ng-container matColumnDef="serviceDescription">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnTagName)
                                }"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'serviceDescription',
                                        getServiceDescription,
                                        'columnServiceDescription',
                                        'Service Description',
                                        columnServiceDescription
                                    )
                                "
                                >Service Description</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'serviceDescription'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.tagDetails.serviceDescription }}</td>
                </ng-container>
                <ng-container matColumnDef="subValidateComment">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnTagName)
                                }"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'subValidateComment',
                                        getSubValidateComment,
                                        'columnSubValidateComment',
                                        'Sub Validate Comment',
                                        columnSubValidateComment
                                    )
                                "
                                >Sub Validate Comment</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'subValidateComment'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.tagDetails.subValidateComment }}</td>
                </ng-container>
                <ng-container matColumnDef="tagStatus">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>Tag Status</th>
                    <td mat-cell *matCellDef="let element">{{ element.tagDetails.tagStatus }}</td>
                </ng-container>
                <ng-container matColumnDef="projectTeamName">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container center">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnProjectTeamName)
                                }"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'projectTeamName',
                                        getProjectTeamNames,
                                        'columnProjectTeamName',
                                        'Project Team',
                                        columnProjectTeamName
                                    )
                                "
                                >Project Team</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'projectTeamName'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.tagDetails.projectTeamName }}</td>
                </ng-container>
                <ng-container matColumnDef="exInspectReqd">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnExInspectReqd)
                                }"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'exInspectReqd',
                                        getExInspections,
                                        'columnExInspectReqd',
                                        'Ex Inspection Required',
                                        columnExInspectReqd
                                    )
                                "
                                >Ex Inspection Required</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'exInspectReqd'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.tagDetails.exInspectReqd }}</td>
                </ng-container>
                <ng-container matColumnDef="discipline">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnDiscipline)
                                }"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'discipline',
                                        getDisciplines,
                                        'columnDiscipline',
                                        'Discipline',
                                        columnDiscipline
                                    )
                                "
                                >Discipline</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.tagDetails.discipline }}</td>
                </ng-container>
                <ng-container matColumnDef="subsystemsList">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span
                                class="hover-pointer"
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnSubsystemsList)
                                }"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'subsystemsList',
                                        getSubsystemsList,
                                        'columnSubsystemsList',
                                        'Subsystems',
                                        columnSubsystemsList,
                                        false,
                                        true,
                                        true
                                    )
                                "
                                >Subsystems</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'subsystemsList'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.tagDetails.subsystemsList }}</td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div
                            class="tag-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                        >
                            <div class="tag-element-description">
                                <div *ngIf="element.itRs.length">
                                    <div class="tree-icon">
                                        <mat-icon (click)="element.showITRs = 1" *ngIf="!element.showITRs"
                                            >chevron_right</mat-icon
                                        >
                                        <mat-icon (click)="element.showITRs = 0" *ngIf="element.showITRs"
                                            >expand_more</mat-icon
                                        >
                                        <span>QVDs/ITRs</span>
                                    </div>
                                    <div class="scroll">
                                        <table
                                            *ngIf="element.showITRs"
                                            mat-table
                                            [dataSource]="element.itRs | filterHidden"
                                            multiTemplateDataRows
                                            class="qvditr-subtable"
                                        >
                                            <ng-container
                                                matColumnDef="{{ column }}"
                                                *ngFor="let column of columnsToDisplayITR"
                                                [sticky]="column === 'number' ? 'sticky' : null"
                                            >
                                                <th mat-header-cell *matHeaderCellDef app-col-resize>
                                                    <div class="sort-container">
                                                        <span
                                                            class="hover-pointer"
                                                            [ngClass]="{
                                                                'header-underline': showUnderlineForCheckListColumn(
                                                                    element.itrHeaderFilter['column' + column]
                                                                )
                                                            }"
                                                            (click)="
                                                                openHeaderCheckListFilter(
                                                                    tableTypes.itr,
                                                                    element.itRs,
                                                                    element,
                                                                    column,
                                                                    getValues,
                                                                    'column' + column,
                                                                    ITRColumnNames[column],
                                                                    element.itrHeaderFilter['column' + column],
                                                                    false,
                                                                    true
                                                                )
                                                            "
                                                            >{{ ITRColumnNames[column] }}</span
                                                        >
                                                        <div
                                                            class="sort-icon"
                                                            *ngIf="element.itrHeaderFilter.sortBy.active === column"
                                                        >
                                                            <mat-icon
                                                                *ngIf="
                                                                    element.itrHeaderFilter.sortBy.direction === 'asc'
                                                                "
                                                                >keyboard_arrow_up</mat-icon
                                                            >
                                                            <mat-icon
                                                                *ngIf="
                                                                    element.itrHeaderFilter.sortBy.direction === 'desc'
                                                                "
                                                                >keyboard_arrow_down</mat-icon
                                                            >
                                                        </div>
                                                    </div>
                                                </th>
                                                <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="columnsToDisplayITR"></tr>
                                            <tr mat-row *matRowDef="let row; columns: columnsToDisplayITR"></tr>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="element.plIs.length">
                                    <div class="tree-icon">
                                        <mat-icon (click)="element.showPLIs = 1" *ngIf="!element.showPLIs"
                                            >chevron_right</mat-icon
                                        >
                                        <mat-icon (click)="element.showPLIs = 0" *ngIf="element.showPLIs"
                                            >expand_more</mat-icon
                                        >
                                        <span>PLIs</span>
                                    </div>
                                    <div class="scroll">
                                        <table
                                            *ngIf="element.showPLIs"
                                            mat-table
                                            [dataSource]="element.plIs | filterHidden"
                                            multiTemplateDataRows
                                            class="pli-subtable"
                                        >
                                            <ng-container
                                                matColumnDef="{{ column }}"
                                                *ngFor="let column of columnsToDisplayPLI"
                                                [sticky]="column === 'number' ? 'sticky' : null"
                                            >
                                                <th mat-header-cell *matHeaderCellDef app-col-resize>
                                                    <div class="sort-container">
                                                        <span
                                                            class="hover-pointer"
                                                            [ngClass]="{
                                                                'header-underline': showUnderlineForCheckListColumn(
                                                                    element.pliHeaderFilter['column' + column]
                                                                )
                                                            }"
                                                            (click)="
                                                                openHeaderCheckListFilter(
                                                                    tableTypes.pli,
                                                                    element.plIs,
                                                                    element,
                                                                    column,
                                                                    getValues,
                                                                    'column' + column,
                                                                    PLIColumnNames[column],
                                                                    element.pliHeaderFilter['column' + column],
                                                                    false,
                                                                    true
                                                                )
                                                            "
                                                        >
                                                            {{ PLIColumnNames[column] }}</span
                                                        >
                                                        <div
                                                            class="sort-icon"
                                                            *ngIf="element.pliHeaderFilter.sortBy.active === column"
                                                        >
                                                            <mat-icon
                                                                *ngIf="
                                                                    element.pliHeaderFilter.sortBy.direction === 'asc'
                                                                "
                                                                >keyboard_arrow_up</mat-icon
                                                            >
                                                            <mat-icon
                                                                *ngIf="
                                                                    element.pliHeaderFilter.sortBy.direction === 'desc'
                                                                "
                                                                >keyboard_arrow_down</mat-icon
                                                            >
                                                        </div>
                                                    </div>
                                                </th>
                                                <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="columnsToDisplayPLI"></tr>
                                            <tr mat-row *matRowDef="let row; columns: columnsToDisplayPLI"></tr>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="element.changeDocs.length">
                                    <div class="tree-icon">
                                        <mat-icon (click)="element.showChangeDocs = 1" *ngIf="!element.showChangeDocs"
                                            >chevron_right</mat-icon
                                        >
                                        <mat-icon (click)="element.showChangeDocs = 0" *ngIf="element.showChangeDocs"
                                            >expand_more</mat-icon
                                        >
                                        <span>Change Docs</span>
                                    </div>
                                    <div class="scroll">
                                        <table
                                            *ngIf="element.showChangeDocs"
                                            mat-table
                                            [dataSource]="element.changeDocs | filterHidden"
                                            multiTemplateDataRows
                                            class="changedocs-subtable"
                                        >
                                            <ng-container
                                                matColumnDef="{{ column }}"
                                                *ngFor="let column of columnsToDisplayChangeDoc"
                                                [sticky]="column === 'number' ? 'sticky' : null"
                                            >
                                                <th mat-header-cell *matHeaderCellDef app-col-resize>
                                                    <div class="sort-container">
                                                        <span
                                                            class="hover-pointer"
                                                            [ngClass]="{
                                                                'header-underline': showUnderlineForCheckListColumn(
                                                                    element.changeDocHeaderFilter['column' + column]
                                                                )
                                                            }"
                                                            (click)="
                                                                openHeaderCheckListFilter(
                                                                    tableTypes.changeDoc,
                                                                    element.changeDocs,
                                                                    element,
                                                                    column,
                                                                    getValues,
                                                                    'column' + column,
                                                                    ChangeDocColumnNames[column],
                                                                    element.changeDocHeaderFilter['column' + column],
                                                                    false,
                                                                    true
                                                                )
                                                            "
                                                        >
                                                            {{ ChangeDocColumnNames[column] }}</span
                                                        >
                                                        <div
                                                            class="sort-icon"
                                                            *ngIf="
                                                                element.changeDocHeaderFilter.sortBy.active === column
                                                            "
                                                        >
                                                            <mat-icon
                                                                *ngIf="
                                                                    element.changeDocHeaderFilter.sortBy.direction ===
                                                                    'asc'
                                                                "
                                                                >keyboard_arrow_up</mat-icon
                                                            >
                                                            <mat-icon
                                                                *ngIf="
                                                                    element.changeDocHeaderFilter.sortBy.direction ===
                                                                    'desc'
                                                                "
                                                                >keyboard_arrow_down</mat-icon
                                                            >
                                                        </div>
                                                    </div>
                                                </th>
                                                <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="columnsToDisplayChangeDoc"></tr>
                                            <tr mat-row *matRowDef="let row; columns: columnsToDisplayChangeDoc"></tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr
                    mat-row
                    *matRowDef="let element; columns: columnsToDisplay"
                    class="tag-element-row"
                    [class.tag-expanded-row]="expandedElement === element"
                ></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="tag-detail-row"></tr>
            </table>
        </div>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>
