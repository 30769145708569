import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { RedZoneService } from 'src/app/services/api/webapi-services/redzone.service';
import * as moment from 'moment';
import {
    RFOActionTypes,
    RFOFilterSuccess,
    RFOFilterError,
    RFOForecastDateUpdateRequest,
    RFORFSUForecastDateUpdateSuccess,
    RFORFSUForecastDateUpdateError,
    RFOAddCommentRequest,
    RFOAddCommentSuccess,
    RFOAddCommentError,
    RFOFilterRequest,
    RFORemoveCommentRequest,
    RFORemoveCommentSuccess,
    RFORemoveCommentError,
    RFOAddBulkCommentRequest,
    RFOAddBulkCommentSuccess,
    RFOAddBulkCommentError,
    RFOExportToExcelSuccess,
    RFOExportToExcelError,
    RFOFreezeDateUpdateRequest,
    RFOFreezeDateUpdateSuccess,
    RFOFreezeUpdateError,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { Zone } from 'src/app/enums';
import { RFOService } from 'src/app/services/api/webapi-services/RFO.service';

@Injectable()
export class RFOEffects {
    constructor(
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private RFOService: RFOService,
        private actions$: Actions,
        private commentService: CommentService
    ) {}

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<RFOFilterRequest>(RFOActionTypes.RFOFilterRequest),
        withLatestFrom(this.store.select((store) => store.rfoState.filter)),
        mergeMap(([action, RFOFilter]) => {
            let filter = action.payload ?? RFOFilter;
            return this.RFOService.getRFOData(filter).pipe(
                map((dataPagination) => {
                    return new RFOFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering RFO subsystem in RFO status page. Please contact Program Administrator.'
                    );
                    return of(new RFOFilterError(error));
                })
            );
        })
    );

    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<RFORemoveCommentRequest>(RFOActionTypes.RFORemoveCommentRequest),
        mergeMap(({ payload }) =>
            this.commentService.deleteZoneComment(payload.id, Zone.RFO).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully removed.');
                    return new RFORemoveCommentSuccess({
                        id: payload.id,
                        lastComment: payload.lastComment,
                        rfo: payload.rfo,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while removing RFO comment. Please contact Program Administrator.'
                    );
                    return of(new RFORemoveCommentError(error));
                })
            )
        )
    );

    @Effect()
    updateMcForecastDate$ = this.actions$.pipe(
        ofType<RFOForecastDateUpdateRequest>(RFOActionTypes.RFOForecastDateUpdateRequest),
        mergeMap((action) => {
            return this.RFOService.updateRFOForecastDate(action.payload.rfo, action.payload.rfoForecastDate).pipe(
                map(() => new RFORFSUForecastDateUpdateSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating RFO Forecast date. Please contact Program Administrator.'
                    );
                    return of(new RFORFSUForecastDateUpdateError(error));
                })
            );
        })
    );

    @Effect()
    updateRFOFreezeDate$ = this.actions$.pipe(
        ofType<RFOFreezeDateUpdateRequest>(RFOActionTypes.RFOFreezeDateUpdateRequest),
        mergeMap((action) => {
            return this.RFOService.updateRFOFreezeDate(action.payload.rfo, action.payload.rfoFreezeDate).pipe(
                map(() => new RFOFreezeDateUpdateSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating RFO Freeze date. Please contact Program Administrator.'
                    );
                    return of(new RFOFreezeUpdateError(error));
                })
            );
        })
    );

    @Effect()
    addComment$ = this.actions$.pipe(
        ofType<RFOAddCommentRequest>(RFOActionTypes.RFOAddCommentRequest),
        mergeMap((action) => {
            return this.commentService
                .addCommentZone(action.payload.entityId, action.payload.description, Zone.RFO, action.payload.mentions)
                .pipe(
                    map(() => {
                        this.toastService.Success('Comment added successfully.');
                        return new RFOAddCommentSuccess({
                            entityId: action.payload.entityId,
                            description: action.payload.description,
                            mentions: action.payload.mentions,
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding the comment. Please contact Program Administrator.'
                        );
                        return of(new RFOAddCommentError(error));
                    })
                );
        })
    );

    @Effect()
    addBulkComment$ = this.actions$.pipe(
        ofType<RFOAddBulkCommentRequest>(RFOActionTypes.RFOAddBulkCommentRequest),
        withLatestFrom(this.store.select((store) => store.rfoState.filter)),
        mergeMap(([action, filter]) => {
            return this.commentService
                .addBulkCommentZone(action.payload.description, action.payload.mentions, filter, Zone.RFO)
                .pipe(
                    map(() => {
                        this.toastService.Success('Bulk Comment added successfully.');
                        return new RFOAddBulkCommentSuccess({
                            description: action.payload.description,
                            mentions: action.payload.mentions,
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding the bulk comment. Please contact Program Administrator.'
                        );
                        return of(new RFOAddBulkCommentError(error));
                    })
                );
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(RFOActionTypes.RFOExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.rfoState.filter)),
        mergeMap(([, RFOFilter]) => {
            const filter = {
                ...RFOFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.RFOService.generateExcel(filter).pipe(
                map((excelData) => new RFOExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new RFOExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(RFOActionTypes.RFOExportToExcelSuccess),
        map((action: RFOExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `RFO_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );
}
