import { Action } from '@ngrx/store';
import { ProjectTeamRoleDTO } from 'src/app/models/project-team-role-dto';
import { ProjectTeamRolesDataPagination } from './model';

export enum ProjectTeamRoleMaintenanceActionTypes {
    ProjectTeamRoleFilterRequest = '[ProjectTeamRole] Filter Request',
    ProjectTeamRoleFilterSuccess = '[ProjectTeamRole] Filter Success',
    ProjectTeamRoleFilterError = '[ProjectTeamRole] Filter Error',
    ProjectTeamRoleFilterPropertyUpdate = '[ProjectTeamRole] Filter Property Update',

    ProjectTeamRoleSaveRequest = '[ProjectTeamRole] Save ProjectTeamRole Request',
    ProjectTeamRoleSaveSuccess = '[ProjectTeamRole] Save ProjectTeamRole Success',
    ProjectTeamRoleSaveError = '[ProjectTeamRole] Save ProjectTeamRole Error',

    ProjectTeamRoleRemoveRequest = '[ProjectTeamRole] Remove ProjectTeamRole Request',
    ProjectTeamRoleRemoveSuccess = '[ProjectTeamRole] Remove ProjectTeamRole Success',
    ProjectTeamRoleRemoveError = '[ProjectTeamRole] Remove ProjectTeamRole Error',
}

export class ProjectTeamRoleFilterRequest implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleFilterRequest;
}

export class ProjectTeamRoleFilterSuccess implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleFilterSuccess;

    constructor(public payload: ProjectTeamRolesDataPagination) {}
}

export class ProjectTeamRoleFilterError implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleFilterError;

    constructor(public payload: string) {}
}

export class ProjectTeamRoleFilterPropertyUpdate implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ProjectTeamRoleSaveRequest implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleSaveRequest;

    constructor(public payload: ProjectTeamRoleDTO) {}
}

export class ProjectTeamRoleSaveSuccess implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleSaveSuccess;
}

export class ProjectTeamRoleSaveError implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleSaveError;

    constructor(public payload: string) {}
}

export class ProjectTeamRoleRemoveRequest implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleRemoveRequest;

    constructor(public id: string) {}
}

export class ProjectTeamRoleRemoveSuccess implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleRemoveSuccess;
}

export class ProjectTeamRoleRemoveError implements Action {
    readonly type = ProjectTeamRoleMaintenanceActionTypes.ProjectTeamRoleRemoveError;

    constructor(public payload: string) {}
}

export type ProjectTeamRoleActions =
    | ProjectTeamRoleFilterRequest
    | ProjectTeamRoleFilterSuccess
    | ProjectTeamRoleFilterError
    | ProjectTeamRoleFilterPropertyUpdate
    | ProjectTeamRoleSaveRequest
    | ProjectTeamRoleSaveSuccess
    | ProjectTeamRoleSaveError
    | ProjectTeamRoleRemoveRequest
    | ProjectTeamRoleRemoveSuccess
    | ProjectTeamRoleRemoveError;
