import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { Contractor, Waypoint, Milestone, TCOUser, Gooc, RFO, StagedStartUpPriority } from 'src/app/store/common.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { PlanningFilter, PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_MONTH, PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_WEEKS } from 'src/app/store/reports/planning/model';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, filter, map, take, catchError } from 'rxjs/operators';
import {
    PlanningCommitmentReliabilityRequest,
    PlanningCommitmentReliabilityFilterReset,
    PlanningCommitmentReliabilitySetWeekRange,
    CommitmentReliabilityTogglePlanView,
    PlanningCommitmentReliabilityFilterUpdate,
    PlanningCommitmentReliabilitySetMonthRange,
} from 'src/app/store/reports/planning/actions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { WorkAreaService } from 'src/app/services/api/webapi-services/work-area.service';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-reports-planning-commitment-reliability',
    templateUrl: './reports-planning-commitment-reliability.component.html',
    styleUrls: ['./reports-planning-commitment-reliability.component.scss'],
})
export class ReportsPlanningCommitmentReliabilityComponent extends BaseComponent implements OnInit {    
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setDisciplineInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWorkAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();    
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    areaCompletionLeads: TCOUser[] = [];
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    navLinks: { label: string; link: string; index: number }[];
    isUsersPerProjectLoading = false;
    mslAreas = [];
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    autocompleteDisplayedColumns = ['name', 'description'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    projectTeamNamesCurrent: string[];
    prevWaypoints: Waypoint[] = [];
    filterForm: UntypedFormGroup;
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    planViewEnabled = true;
    period: number = 0;
    monthViewEnabled = false;
    weeklyViewEnabled = true;

    get nbDisciplineLeads() {
        return this.filterForm?.value?.nbDisciplineLeads?.map((l) => l.name) ?? [];
    }

    get nbEngineers() {
        return this.filterForm?.value?.nbEngineers?.map((l) => l.name) ?? [];
    }

    get tcoAreaCoordinators() {
        return this.filterForm?.value?.tcoAreaCoordinators?.map((l) => l.name) ?? [];
    }

    commitmentReliabilityType$ = this.store.select(
        (store) => store.planningState.commitmentReliability.commitmentReliabilityType
    );
    commitmentReliabilityType: string;
    isITRType = false;

    planningCommitmentReliabilityFilter$ = this.store.select(
        (store) => store.planningState.commitmentReliability.filter
    );
    startDate$ = this.store.select((store) => store.planningState.commitmentReliability.filter.startDate);
    startDateValue: moment.Moment = getCurrentWeekStartDate().add(-6, 'weeks');

    @ViewChild('dateRangePicker', { static: true }) dateRangePicker: MatDatepicker<moment.Moment>;
    rangeFilterDate = getCurrentWeekStartDate().add(-6, 'weeks');
    isWorkAreaSet = false;

    constructor(
        private formService: FormService,
        private store: Store<ApplicationState>,
        private scheduleActivityLookupService: ScheduleActivityLookupService,
        private lookupService: LookupService,
        private projectTeamsService: ProjectTeamsService,
        private workAreaService: WorkAreaService,
        private location: Location,
        private toastService: ToastService
    ) {
        super();
        this.navLinks = [
            // {
            //     label: 'mc commitment reliability',
            //     link: './mc',
            //     index: 0,
            // },
            {
                label: 'rfsu commitment reliability',
                link: './rfsu',
                index: 1,
            },
            {
                label: 'sc weekly plan commitment reliability',
                link: './itr',
                index: 2,
            },
        ];
        this.filterForm = this.formService.createSimpleForm(new PlanningFilter());
        this.dateRangePickerControl = new UntypedFormControl();
        this.dateRangePickerControlFormatted = new UntypedFormControl({ value: '', disabled: true });
    }

    ngOnInit() {
        this.commitmentReliabilityType$.pipe(takeWhile(() => this.isAlive)).subscribe((commitmentReliabilityType) =>
            setTimeout(() => {
                this.commitmentReliabilityType = commitmentReliabilityType;
                this.isITRType = commitmentReliabilityType === 'ITR';
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(this.startDateValue));
            })
        );

        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.filterForm.controls.waypoints.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((waypoints: Waypoint[]) => {
                this.prevWaypoints = waypoints;
                this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
            });

        this.filterForm.controls.milestones.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((milestones: Milestone[]) => {
                let waypoints = JSON.parse(JSON.stringify(milestones));
                waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
                waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name');
                this.setMilestoneInput.emit(new SetInputEventArgs(false, '', milestones));
                if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) {
                    this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                    this.filterForm.controls.waypoints.setValue(waypoints);
                }
            });

        this.startDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startDate) => !!startDate)
            )
            .subscribe((startDate) => {
                this.startDateValue = moment(startDate);
                this.dateRangePickerControl.setValue(this.startDateValue, { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(this.startDateValue));
            });

        this.dateRangePickerControl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((date: moment.Moment) => {
                if(this.monthViewEnabled){
                    this.store.dispatch(new PlanningCommitmentReliabilitySetMonthRange(date));
                }else{
                    this.store.dispatch(new PlanningCommitmentReliabilitySetWeekRange(date));
                }
                
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
            });

        this.lookupService
            .getMSLAreas()
            .pipe(take(1))
            .subscribe((mslAreas) => (this.mslAreas = mslAreas));

        this.filterForm.controls.workAreas.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
            if (value.length === 1) {
                this.workAreaService
                    .getWorkAreaByName(value[0].workAreaName)
                    .pipe(take(1))
                    .subscribe((data) => {
                        this.filterForm.controls.nbDisciplineLeads.setValue(
                            data.nbDisciplineLead ? [{ name: data.nbDisciplineLead }] : []
                        );
                        this.filterForm.controls.nbEngineers.setValue(
                            data.nbEngineer ? [{ name: data.nbEngineer }] : []
                        );
                        this.filterForm.controls.tcoAreaCoordinators.setValue(
                            data.tcoAreaCoordinator ? [{ name: data.tcoAreaCoordinator }] : []
                        );
                        this.filterForm.controls.disciplines.setValue(
                            data.disciplines ? data.disciplines.map((d) => ({ name: d.id })) : []
                        );
                    });
            }
        });

        this.planningCommitmentReliabilityFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: true });
            this.getUsersPerProjectTeam();
        });

        this.lookupService
            .getACLeads([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads));
        
        this.filterExpansionPanel.expanded = true;
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    searchDiscipline = (search = '') => {
        return this.scheduleActivityLookupService
            .searchDisciplines()
            .pipe(
                map((disciplines: any[]) =>
                    disciplines.filter((d: { name: string }) => d.name.toLowerCase().includes(search.toLowerCase()))
                )
            );
    };

    searchSubsystems = (search = '', takeCount = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchSubsystems(search, takeCount, page, {
            ...this.filterForm.value,
            lookaheadDays: 42,
            weekStart: this.startDateValue,
        });
    };

    searchAreas = (search = '', takeCount = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchWorkAreas(search, takeCount, page, this.filterForm.value);
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.projectTeamNames)
            .pipe(map((RFOs: RFO[]) => RFOs));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getContractors = (search = '', take = 10, page = 0) => {
        return !this.isITRType
            ? this.lookupService.searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames)
            : this.lookupService.searchContractors(search, take, page, this.filterForm.value.projectTeamNames);
    };

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.getUsersPerProjectTeam();
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMilestones(
            search,
            take,
            page,
            this.filterForm.value.waypoints,
            this.filterForm.value.projectTeamNames
        );
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((prio: StagedStartUpPriority[]) => prio));
    };

    onStagedStartUpPrioritiesClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    displayMultipleSelected(values: (Milestone | Waypoint)[]) {
        return values
            .filter((x) => x)
            .map((x) => x.name)
            .join(', ');
    }

    displaySelectedAreas(values: number[]) {
        return values.map((x) => x).join(', ');
    }

    displaySelectedWorkAreas(values: any[]) {
        return values.map((x) => x.workAreaName).join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    togglePlanView() {
        this.planViewEnabled = !this.planViewEnabled;
        this.store.dispatch(new CommitmentReliabilityTogglePlanView());
    }

    search() {
        this.updateFilterByFormProperties();
        this.store.dispatch(new PlanningCommitmentReliabilityRequest());
    }

    resetFilters() {
        this.store.dispatch(new PlanningCommitmentReliabilityFilterReset());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setSubsystemInput.emit(new SetInputEventArgs(false, ''));
        this.setDisciplineInput.emit(new SetInputEventArgs(false, ''));
        this.setAreaInput.emit(new SetInputEventArgs(false, ''));
        this.setWorkAreaInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.stagedStartUpPriorities.setValue([]);
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    dateRangePickerFilter = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    private formatDateRange(startDate: moment.Moment) {
        if (this.monthViewEnabled) {
            const endDate = moment(startDate).add(8, 'month');
            return `${moment(startDate).format('MMM YYYY')} - ${endDate.format('MMM YYYY')}`;
        }
        else{
            const endDate = moment(startDate).add(8, 'weeks');
            return `${moment(startDate).format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
        }
    }

    private updateFilterByFormProperties() {
        let newFilter = new PlanningFilter();
        for (const key of Object.keys(this.filterForm.controls)) {
            if (key !== 'startDate' && key !== 'endDate') {
                const value = this.filterForm.controls[key].value;
                newFilter[key] = this.filterForm.controls[key].value;
            }
        }

        this.store.dispatch(new PlanningCommitmentReliabilityFilterUpdate(newFilter));
    }

    private checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName].value.length &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm.controls[formControlName].setValue(valid);
        }
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    resetWorkAreaFilters() {
        this.filterForm.controls.workAreas.setValue([]);
        this.filterForm.controls.nbDisciplineLeads.setValue([]);
        this.filterForm.controls.nbEngineers.setValue([]);
        this.filterForm.controls.tcoAreaCoordinators.setValue([]);
        this.filterForm.controls.disciplines.setValue([]);
    }

    enableWeeklyView() {
        this.period = 0;
        this.monthViewEnabled = false;
        this.weeklyViewEnabled = true;
        this.startDateValue = getCurrentWeekStartDate().add(-PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_WEEKS, 'weeks');
        this.setControl();
    }

    enableMonthlyView() {
        this.period = 1;
        this.monthViewEnabled = true;
        this.weeklyViewEnabled = false;
        this.startDateValue = getCurrentWeekStartDate().add(-PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_MONTH, 'months');
        this.setControl();
    }

    setControl() {
        this.dateRangePickerControl.setValue(this.startDateValue);
    }

    chosenYearHandler(normalizedYear: moment.Moment) {
        const dateChozen = moment(normalizedYear);
        const ctrlValue = moment(this.dateRangePickerControl.value);
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue.toDate(), { emitEvent: false });
    }

    chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
        const dateChozen = moment(normalizedMonth);
        const ctrlValue = moment(this.dateRangePickerControl.value);
        ctrlValue.month(dateChozen.month());
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue.toDate());
        datepicker.close();
    }


    private getUsersPerProjectTeam() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'milestones');
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'waypoints');
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'contractors');
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'goocs');
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'rfos');

        forkJoin([
            this.lookupService.getSCManagers(this.filterForm.controls.projectTeamNames.value),
            this.lookupService.getMCEngineers(this.filterForm.controls.projectTeamNames.value),
            this.lookupService.getSysOwners(this.filterForm.controls.projectTeamNames.value),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([scManagers, mcEngineers, sysOwners]) => {
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    goBack() {
        this.location.back();
    }
}
