import { Component, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { switchMap, mergeMap, withLatestFrom, takeWhile, filter, finalize } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { WeeklyPlanningService } from 'src/app/services/api/webapi-services/weekly-planning.service';
import { BaseComponent } from 'src/app/components/base.component';
import * as moment from 'moment';
import { CommitmentType } from 'src/app/store/weekly-planning/model';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-weekly-planning-past-week',
    templateUrl: './weekly-planning-past-week.component.html',
    styleUrls: ['./weekly-planning-past-week.component.scss'],
})
export class WeeklyPlanningPastWeekComponent extends BaseComponent implements OnInit {
    displayedColumns = ['id', 'name', 'actual', 'plan', 'forecast'];
    isLoading = false;
    commitmentTypes = CommitmentType;
    planningType: CommitmentType;
    filterForm: UntypedFormGroup;
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];

    data$: Observable<any>;
    planningType$ = this.store.select((store) => store.weeklyPlanningState.planningType.toLowerCase());

    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    constructor(
        private store: Store<ApplicationState>,
        private weeklyPlanningService: WeeklyPlanningService,
        private lookupService: LookupService
    ) {
        super();
        this.filterForm = new UntypedFormGroup({
            contractors: new UntypedFormControl(),
            rfos: new UntypedFormControl(),
        });
    }

    ngOnInit(): void {
        this.loadData();
        this.planningType$.pipe(takeWhile(() => this.isAlive)).subscribe((planningType) =>
            setTimeout(() => {
                this.planningType = this.commitmentTypes[planningType.toUpperCase()];
                if (this.planningType === CommitmentType.MC) {
                    this.displayedColumns = [...this.displayedColumns, 'walkdownActual'];
                }
            })
        );
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.store
            .select((state) => state.weeklyPlanningState.filter.projectTeamName)
            .pipe(
                switchMap((projectTeamName) =>
                    this.lookupService.searchContractors(search, take, page, [projectTeamName])
                )
            );
    };

    searchRfos = (search = '', take = 10, page = 0) => {
        return this.store
            .select((state) => state.weeklyPlanningState.filter.projectTeamName)
            .pipe(
                switchMap((projectTeamName) =>
                    this.lookupService.searchRFOs(search, take, page, [projectTeamName])
                )
            );
    };

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    search() {
        this.loadData();
    }

    resetFilters() {
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOsInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.contractors.setValue([]);
        this.filterForm.controls.rfos.setValue([]);
    }

    private loadData() {
        this.data$ = this.store
            .select((store) => store.weeklyPlanningState.filter.startDate)
            .pipe(
                takeWhile(() => this.isAlive),
                mergeMap((startDate) =>
                    of(startDate).pipe(
                        withLatestFrom(
                            this.store.select((store) => store.weeklyPlanningState.planningType),
                            this.store.select((store) => store.weeklyPlanningState.filter.projectTeamName)
                        )
                    )
                ),
                filter(([startDate]) => {
                    const today = moment().utc().startOf('day');
                    if (today.isoWeekday() !== 6) {
                        today.add(-1, 'weeks').isoWeekday(6);
                    }
                    return startDate.isBefore(today);
                }),
                switchMap(([startDate, planningType, projectTeamName]) => {
                    this.isLoading = true;
                    return this.weeklyPlanningService
                        .getPastWeekCommitments(
                            startDate,
                            planningType,
                            projectTeamName,
                            this.filterForm.controls.contractors.value,
                            this.filterForm.controls.rfos.value
                        )
                        .pipe(finalize(() => (this.isLoading = false)));
                })
            );
    }
}
