import { Action } from "@ngrx/store";
import { ForecastHistoryDateType } from "src/app/enums";
import { ForecastDateHistory } from "./model";

export enum ForecastHistoryActionTypes {
    ForecastDateHistoryRequest = 'Forecast Date History Request',
    ExceptionForecastDateHistoryRequest = 'Exception Forecast Date History Request',
    ForecastDateHistorySuccess = 'Forecast Date History Success',
    ForecastDateHistoryError = 'Forecast Date History Error',
}

export class ForecastDateHistoryRequest implements Action {
    readonly type = ForecastHistoryActionTypes.ForecastDateHistoryRequest;

    constructor(
        public payload: {
            rfo: string;
            subsystem: string;
            type: ForecastHistoryDateType;
            discipline: string;
            allDisciplines: boolean;
            number:string;
        }
    ) { }
}

export class ExceptionForecastDateHistoryRequest implements Action {
    readonly type = ForecastHistoryActionTypes.ExceptionForecastDateHistoryRequest;

    constructor(
        public payload: {            
            number: string;
            pliOrBarcode: string;
            itrId: number;
            scope: number;
        }
    ) { }
}

export class ForecastDateHistorySuccess implements Action {
    readonly type = ForecastHistoryActionTypes.ForecastDateHistorySuccess;

    constructor(public payload: ForecastDateHistory[]) { }
}

export class ForecastDateHistoryError implements Action {
    readonly type = ForecastHistoryActionTypes.ForecastDateHistoryError;

    constructor(public payload: string) { }
}

export type ForecastHistoryActions =
    ForecastDateHistoryRequest
    | ExceptionForecastDateHistoryRequest
    | ForecastDateHistorySuccess
    | ForecastDateHistoryError;