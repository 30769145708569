import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    TagsActionTypes,
    TagsUploadLogSuccess,
    TagsUploadLogError,
    TagsSetStatusAndStartDate,
    TagsClearInProgressSpinner,
    TagsTemplateFileRequest,
    TagsTemplateFileSuccess,
    TagsTemplateFileError,
    TagsValidateDeltaSuccess,
    TagsValidateDeltaError,
    TagsDownloadOutputDataRequest,
    TagsDownloadOutputDataSuccess,
    TagsDownloadOutputDataError,
    TagsValidateButtonStateSuccess,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class TagsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(TagsActionTypes.TagsUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['Tags']).pipe(
                map((uploadLogData) => new TagsUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting logs for Tags. Please contact Program Administrator.'
                    );
                    return of(new TagsUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<TagsUploadLogSuccess>(TagsActionTypes.TagsUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.tagsState.uploadLogStatus),
            this.store.select((store) => store.tagsState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new TagsSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<TagsTemplateFileRequest>(TagsActionTypes.TagsTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new TagsTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting template for Tags. Please contact Program Administrator.'
                    );
                    return of(new TagsTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<TagsTemplateFileSuccess>(TagsActionTypes.TagsTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'SCMT TagList [part].xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<TagsDownloadOutputDataRequest>(TagsActionTypes.TagsDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new TagsDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting result output for Tags. Please contact Program Administrator.'
                    );
                    return of(new TagsDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<TagsDownloadOutputDataSuccess>(TagsActionTypes.TagsDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(TagsActionTypes.TagsValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateTagsDelta().pipe(
                map(() => new TagsValidateDeltaSuccess()),
                catchError((error) => {
                    if (error?.error === 'AutomaticUploadPipelineInProgress') {
                        this.toastService.Info(
                            'Cannot validate the data while automatic upload pipeline is in progress.'
                        );
                    } else {
                        this.toastService.Error(
                            'Error occurred while validating and pushing Tags. Please contact Program Administrator.'
                        );
                    }
                    return of(new TagsValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(TagsActionTypes.TagsSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.tagsState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) {
                return false;
            }
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new TagsClearInProgressSpinner()])
    );

    @Effect()
    setValidateButtonEnabled$ = this.actions$.pipe(
        ofType(TagsActionTypes.TagsUploadLogSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('Tags').pipe(
                map((response: any) => new TagsValidateButtonStateSuccess(response)),
                catchError((error) => of(new TagsValidateButtonStateSuccess(error)))
            )
        )
    );
}
