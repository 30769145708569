import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseComponent } from 'src/app/components/base.component';
import { appConfig } from 'src/app/app.config';
import { THSAttachmentDto, TraceHeatingStatusFilter, TraceHeatingStatusResultDto } from 'src/app/store/trace-heating-status/model';
import { SubsystemScope } from 'src/app/enums';

@Injectable({ providedIn: 'root' })
export class TraceHeatingStatusService extends BaseComponent {

    constructor(private http: HttpClient) {
        super();
    }
    private readonly rootUrl = appConfig.apiEndpoint;
    private readonly endpoint = '/traceHeatingStatus/';

    private static getParams(search: string, take: number, page: number) {
        return new HttpParams()
            .append('page', page.toString())
            .append('take', take.toString())
            .append('search', search);
    }


    searchSubsystems(search: string, take: number, page: number, isTdb: boolean) {
        let params = TraceHeatingStatusService.getParams(search, take, page);
        params = params.append('isTdb', isTdb.toString());
        return this.http.get(`${this.rootUrl + this.endpoint}subsystems`, { params });
    }

    searchCircuits(search: string, take: number, page: number) {
        const params = TraceHeatingStatusService.getParams(search, take, page);
        return this.http.get(`${this.rootUrl + this.endpoint}circuit`, { params });
    }

    searchHeatTrackingTapes(search: string, take: number, page: number) {
        const params = TraceHeatingStatusService.getParams(search, take, page);
        return this.http.get(`${this.rootUrl + this.endpoint}heattracingtapes`, { params });
    }

    search(filter: TraceHeatingStatusFilter, printMode: boolean) {
        const params = new HttpParams().append('printMode', printMode.toString());
        return this.http.post<TraceHeatingStatusResultDto>(`${this.rootUrl + this.endpoint}search`, filter, { params });
    }

    generateExcel(filter: TraceHeatingStatusFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    uploadAttachments(formData: FormData, tdbSubsystem: string, scope: SubsystemScope) {
        return this.http.post(
            `${this.rootUrl + this.endpoint}attachments?tdbSubsystem=${tdbSubsystem}&scope=${scope}`,
            formData
        );
    }

    deleteAttachment(links: string[], scope: SubsystemScope) {
        let params = new HttpParams();
        links.forEach((link) => {
            params = params.append('links', link);
            params = params.append('scope', scope.toString());
        });
        return this.http.delete(`${this.rootUrl + this.endpoint}attachments`, { params });
    }

    downloadAttachment(link: string, scope: SubsystemScope) {
        return this.http.get<Blob>(
            `${this.rootUrl + this.endpoint}attachments?link=${link}&scope=${scope}`,
            {
                responseType: 'arraybuffer' as 'json',
            }
        );
    }

    getTHSAttachment(tdbSubsystem: string) {
        const params = new HttpParams()
            .append('tdbSubsystem', tdbSubsystem);
        return this.http.get<THSAttachmentDto[]>(`${this.rootUrl + this.endpoint}attachment`, { params });
    }
}
