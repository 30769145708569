<div class="results-container">
    <div class="scroll">
        <ng-template #activityAction>
            <div class="activity-actions">
                <button class="activityActionButton" (click)="toggleWeeklyStatusProp('areWorkPacksReady')">
                    {{ doesSelectedRowHaveWorkPacksReady ? 'Work Packs not Ready' : 'Work Packs are Ready' }}
                </button>
                <hr />

                <button class="activityActionButton" (click)="updateIsConstrained()">
                    {{ isSelectedRowConstrained ? 'Activity Unconstrained' : 'Activity Constrained' }}
                </button>
                <hr />

                <button class="activityActionButton" (click)="toggleWeeklyStatusProp('inWeeklyPlan')">
                    {{
                        isSelectedRowInWeeklyPlan
                            ? 'Remove Activity from Weekly Plan'
                            : 'Confirm Activity is in Weekly Plan'
                    }}
                </button>

                <ng-container *ngIf="areWorkCompleteStatusFeaturesEnabled">
                    <hr />
                    <button class="activityActionButton" (click)="toggleWeeklyStatusProp('isWorkComplete')">
                        {{
                            doesSelectedRowHaveWorkComplete
                                ? 'Planned Work in the Week Incomplete'
                                : 'Planned Work in the Week Completed'
                        }}
                    </button>
                </ng-container>
            </div>
        </ng-template>

        <popover-content #activityActionPopover [placement]="PopoverPlacement.Top" [closeOnClickOutside]="true">
            <ng-content *ngTemplateOutlet="activityAction"> </ng-content>
        </popover-content>
        <table mat-table *ngIf="showActivities" [dataSource]="subsystemWithActivities.activities" [ngClass]="{print: printMode}">
            <ng-container matColumnDef="activityId" sticky>
                <th mat-header-cell *matHeaderCellDef>Activity ID</th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [popover]="activityActionPopover"
                    [popoverOnHover]="false"
                    (click)="highlightRow(row)"
                >
                    <span>
                        {{ row.activityId }}
                    </span>
                    <ng-container *ngIf="areWorkCompleteStatusFeaturesEnabled">
                        <mat-icon *ngIf="row.weeklyStatus.isWorkComplete">done</mat-icon>
                        <mat-icon *ngIf="!row.weeklyStatus.isWorkComplete">cancel</mat-icon>
                    </ng-container>
                    <mat-icon *ngIf="row.isConstrained">warning</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="activityName" [sticky]="!printMode ? 'sticky' : null">
                <th app-col-resize mat-header-cell *matHeaderCellDef>Activity Name</th>
                <td mat-cell *matCellDef="let row">{{ row.activity }}</td>
            </ng-container>
            <ng-container matColumnDef="activityDiscipline" [sticky]="!printMode ? 'sticky' : null">
                <th mat-header-cell *matHeaderCellDef>Discipline</th>
                <td mat-cell *matCellDef="let row">{{ row.activityDiscipline }}</td>
            </ng-container>
            <ng-container matColumnDef="constraintType">
                <th mat-header-cell *matHeaderCellDef>Constraint Type</th>
                <td mat-cell *matCellDef="let row">{{ row.constraintType }}</td>
            </ng-container>
            <ng-container matColumnDef="planStart">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="{
                        hidecells: !showHideColumns.showPlanDates
                    }"
                >
                    <span *ngIf="!showActivities">Plan </span>Start
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="{
                        hidecells: !showHideColumns.showPlanDates
                    }"
                >
                    {{ row.planStart | date: 'd/MMM/yy' }}&nbsp;{{ getStartDateActivityStatusSuffix(row) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="planFinish">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="{
                        hidecells: !showHideColumns.showPlanDates
                    }"
                >
                    <span *ngIf="!showActivities">Plan </span>Finish
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="{
                        hidecells: !showHideColumns.showPlanDates
                    }"
                >
                    {{ row.planFinish | date: 'd/MMM/yy' }}&nbsp;{{ getFinishDateActivityStatusSuffix(row) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="forecastStart">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="{
                        hidecells: !showHideColumns.showForecastDates
                    }"
                >
                    Forecast Start
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="{
                        hidecells: !showHideColumns.showForecastDates,
                        'cursor-pointer': row.forecastStart
                    }"
                    (click)="openDatepicker(row, 'forecastStart')"
                >
                    {{ row.forecastStart | date: 'd/MMM/yy' }}
                    <button *ngIf="isAdmin && !row.forecastStart" type="button" mat-icon-button>
                        <mat-icon>calendar_today</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="forecastFinish">
                <th mat-header-cell *matHeaderCellDef [ngClass]="{ hidecells: !showHideColumns.showForecastDates }">
                    Forecast Finish
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="{
                        hidecells: !showHideColumns.showForecastDates,
                        'cursor-pointer': row.forecastFinish
                    }"
                    (click)="openDatepicker(row, 'forecastFinish')"
                >
                    {{ row.forecastFinish | date: 'd/MMM/yy' }}
                    <button *ngIf="isAdmin && !row.forecastFinish" type="button" mat-icon-button>
                        <mat-icon>calendar_today</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="mhrsInWeek">
                <th mat-header-cell *matHeaderCellDef>Mhrs In Week</th>
                <td mat-cell *matCellDef="let row" class="numerical">
                    <mat-form-field>
                        <input
                            type="number"
                            matInput
                            [(ngModel)]="row.weeklyStatus.mhrsInWeek"
                            (change)="updateMhrsInWeek(row)"
                            min="0"
                        />
                    </mat-form-field>
                </td>
            </ng-container>
            <ng-container matColumnDef="itrToGo">
                <th mat-header-cell *matHeaderCellDef>ITR To Go</th>
                <td mat-cell *matCellDef="let row">
                    <span class="cursor-pointer">
                        <a (click)="showITRs(itrScopes.ToGo, row.activityId, row.activityDiscipline, true)">
                            {{ row.itrToGo }}
                        </a></span
                    >
                </td>
            </ng-container>
            <ng-container matColumnDef="itrInWeek">
                <th mat-header-cell *matHeaderCellDef>ITR In Period</th>
                <td mat-cell *matCellDef="let row">
                    <span class="cursor-pointer">
                        <a (click)="showITRs(itrScopes.ToGo, row.activityId, row.activityDiscipline, true)">
                            {{ row.itrToGo }}
                        </a></span
                    >
                </td>
            </ng-container>
            <ng-container matColumnDef="workPacksReady">
                <th mat-header-cell *matHeaderCellDef>Work Pack</th>
                <td mat-cell *matCellDef="let row; let rowIndex = index">
                    <mat-checkbox [checked]="row.weeklyStatus.areWorkPacksReady" disabled></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef>Comments</th>
                <td [ngClass]="{ 'sticky-col-comment': isIE }" mat-cell *matCellDef="let row; let rowIndex = index">
                    <div (click)="openCommentPopup(row, $event)" class="comment-description">
                        <div *ngIf="row.comment; else notShow">
                            <a> <span [innerHTML]="row.comment"></span></a>
                        </div>
                        <ng-template #notShow>
                            <button
                                (click)="openCommentPopup(row, $event)"
                                type="button"
                                mat-stroked-button
                                class="add-comment"
                            >
                                Add
                            </button>
                        </ng-template>
                    </div>
                    <div
                        [ngClass]="{ notactive: !row.comment, active: row.comment }"
                        class="comment-hist"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            (click)="openHistoryPopup(row)"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                            />
                        </svg>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [ngClass]="{ selected: selectedRow === row, inWeeklyPlan: row.weeklyStatus.inWeeklyPlan }"
                (click)="highlightRow(row)"
            ></tr>
        </table>
    </div>
    <app-loading-indicator *ngIf="subsystemWithActivities.isLoading"></app-loading-indicator>
</div>
