<nav id="site-specific-nav" class="doNotPrint">
    <ul>
        <li><a [routerLink]="['/home']">Home</a></li>
        <li><a [routerLink]="['/exportpage']">Excel Exports</a></li>
        <!-- <li *ngIf="!isGatheringOnly"><a [routerLink]="['/redzone']">RedZone</a></li> -->
        <li *ngIf="!isGatheringOnly"><a [routerLink]="['/bluezone']">BlueZone</a></li>
        <li *ngIf="!isGatheringOnly"><a [routerLink]="['/rfo']">RFO</a></li>
        <li *ngIf="!isGatheringOnly" class="nested-menu">
            Search
            <ul>
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'AQVD', new: true }">A-QVD status</a>
                </li>
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'BITRandCITR', new: true }">Constraints</a>
                </li>
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'BITR', new: true }">B-ITR status</a>
                </li>
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'CITR', new: true }">C-ITR status</a>
                </li>
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'PLI', new: true }">PLI status</a>
                </li>
                <li>
                    <a [routerLink]="['/certificatesearch']">Certificate status</a>
                </li>
                <!--
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'COW', new: true }">COW status</a>
                </li>
                -->
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'Redline', new: true }"
                        >Redline status</a
                    >
                </li>
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'EX', new: true }"
                        >Exception status</a
                    >
                </li>
                <li>
                    <a [routerLink]="['/pascrstatus']">PAS CR Status</a>
                </li>
                <li>
                    <a [routerLink]="['/tagsearch']">Tag search</a>
                </li>
                <!-- <li>
                    <a [routerLink]="['/loopstatus']">Loop Folder status</a>
                </li> -->
                <li>
                    <a [routerLink]="['/systemiseddrawing']">Systemised Drawings</a>
                </li>
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'RFSUWorkToGoSearch', new: true }"
                        >RFSU Work To Go Search</a
                    >
                </li>
                <li>
                    <a [routerLink]="['/detailedstatus']" [queryParams]="{ scope: 'RFOWorkToGoSearch', new: true }"
                        >RFO Work To Go Search</a
                    >
                </li>
                <li>
                    <a [routerLink]="['/ltpsearch']">Leak Test Pack Tracker</a>
                </li>
            </ul>
        </li>
        <li><a [routerLink]="['/reports']">Reports</a></li>
        <li><a [routerLink]="['/weeklyplanning']">Execution</a></li>
        <li class="nested-menu">
            Change management
            <ul>
                <li><a [routerLink]="['/changemanagement']" [queryParams]="{ new: true }">Search</a></li>
                <li *ngIf="isChangeDocValidator"><a [routerLink]="['/changevalidation']">Change Validation</a></li>
                <li><a [routerLink]="['/criticalrfi']">Critical RFI Report</a></li>
                <!--<li><a [routerLink]="['/register']">DCN/RFI/RLMU NOVUS Register</a></li>-->
            </ul>
        </li>
        <li class="nested-menu">
            SC Area Permit Control
            <ul>
                <li><a (click)="downloadData('Master')">SC Area Permit Control - Master Plot Plan</a></li>
                <li><a [routerLink]="['/cta']">Custody Transfer Act Register</a></li>
                <li><a (click)="downloadData('UndergroundEnergization')">U/G Energization Drawing</a></li>
                <li>
                    <a (click)="downloadData('IAFWDrawing')"
                        >Instrument Air (IA)&nbsp;/ Utility&nbsp;Air&nbsp;UA)&nbsp;/ Nitrogen (N)&nbsp;/ Fire Water (FW)
                        Drawing</a
                    >
                </li>
            </ul>
        </li>
        <li><a [routerLink]="['/mypage']">My Page</a></li>
    </ul>
</nav>
<app-loading-indicator *ngIf="fileDownloading"></app-loading-indicator>
