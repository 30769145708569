import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { catchError, map, take, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Contractor, Gooc, OrderDirection, StagedStartUpPriority, TCOUser } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    SubsystemMCDashboard2ExportToExcelRequest,
    SubsystemMCDashboard2Request,
    SubsystemMCDashboard2UpdateFilterProperties,
} from 'src/app/store/reports/subsystem-mc/actions';
import {
    ShowHideColumns,
    SubsystemMCDashboard2DTO,
    SubsystemMCDashboardFilter,
} from 'src/app/store/reports/subsystem-mc/model';

@Component({
    selector: 'app-subsystem-mc-dashboard2',
    templateUrl: './subsystem-mc-dashboard2.component.html',
    styleUrls: ['./subsystem-mc-dashboard2.component.scss'],
})
export class SubsystemMCDashboard2Component extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    displayedColumns = [
        'subsystemNo',
        'subsystemName',
        'goocNo',
        'constructionArea',
        'mcSequence',
        'unallocated',
        'hydrotests',
        'reinstatements',
        'terminations',
        'instrument',
        'exInsp',
        'heatTrace',
        'lsp',
    ];
    data: SubsystemMCDashboard2DTO[] = [];
    pageSize = 10;
    selectedPageSize = 10;
    resultsLength = 0;
    isPaginatorVisible = true;
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPriorityInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    stagedStartUpPrioritiesAutocompleteDisplayedColumns = ['priority', 'priorityName'];
    isUsersPerProjectLoading = false;
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    contractors: Contractor[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    scrollSearch: boolean = false;
    isLoading$ = this.store.select((state) => state.subsystemMCDashboardState.dashboard2IsLoading);
    items$ = this.store.select((state) => state.subsystemMCDashboardState.dashboard2Items);
    filter$ = this.store.select((state) => state.subsystemMCDashboardState.dashboard2Filter);
    totalCount$ = this.store.select((state) => state.subsystemMCDashboardState.dashboard2TotalCount);
    sortBy = 'subsystemNo';
    direction = OrderDirection.Desc;
    showHideColumns = new ShowHideColumns();

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>,
        private toastService: ToastService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new SubsystemMCDashboardFilter());
    }

    private areAnyFiltersApplied() {
        const {
            goocs,
            mcEngineer,
            systemOwner,
            scManagers,
            stagedStartUpPriorities,
            contractors,
        } = this.filterForm.value;
        return Boolean(
            goocs.length ||
                mcEngineer.length ||
                systemOwner.length ||
                (scManagers && scManagers.length) ||
                stagedStartUpPriorities.length ||
                contractors.length
        );
    }
    ngOnInit() {
        this.filterExpansionPanel.expanded = true;

        this.items$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            if (!items) {
                this.filterSubsystemMCData();
                return;
            }
            this.data = items;
        });

        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.direction = filter.direction;
            this.sortBy = filter.sortBy;
            this.scrollSearch = filter.scrollSearch;
            this.showHideColumns = filter.showHideColumns;
            if (!this.scrollSearch) {
                this.paginator.pageIndex = filter.page;
                this.pageSize = filter.take;
            }
        });

        this.totalCount$.pipe(takeWhile(() => this.isAlive)).subscribe((totalCount) => {
            this.resultsLength = totalCount;
            if (this.areAnyFiltersApplied()) {
                this.pageSize = totalCount;
            }
        });

        forkJoin([
            this.lookupService.getMCEngineers([]),
            this.lookupService.getSysOwners([]),
            this.lookupService.getStagedStartUpPriorities(),
            this.lookupService.getSCManagers([]),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners, stagedStartUpPriorities, scManagers]) => {
                    this.mcEngineers = mcEngineers;
                    this.sysOwners = sysOwners;
                    this.stagedStartUpPriorities = stagedStartUpPriorities;
                    this.scManagers = scManagers;
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }

    displayMultipleSelected(values: Contractor[]) {
        return values.map((x) => x.name).join(', ');
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onStagedStartUpPrioritiesClosed() {
        this.setStagedStartUpPriorityInput.emit(new SetInputEventArgs(true));
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((priorities: StagedStartUpPriority[]) => priorities));
    };

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    resetFilters() {
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.mcEngineer.setValue([]);
        this.filterForm.controls.systemOwner.setValue([]);
        this.filterForm.controls.scManager.setValue([]);
        this.filterForm.controls.stagedStartUpPriorities.setValue([]);
        this.store.dispatch(
            new SubsystemMCDashboard2UpdateFilterProperties({
                goocs: [],
                contractors: [],
                mcEngineer: [],
                systemOwner: [],
                scManager: [],
                stagedStartUpPriorities: [],
            })
        );
    }

    search() {
        this.scrollSearch = this.areAnyFiltersApplied();
        if (!this.areAnyFiltersApplied) {
            this.pageSize = this.selectedPageSize;
        }
        this.paginator.pageIndex = 0;
        this.updateFilterStore();
        this.filterSubsystemMCData();
    }

    updateFilterStore() {
        this.store.dispatch(
            new SubsystemMCDashboard2UpdateFilterProperties({
                goocs: this.filterForm.value.goocs,
                contractors: this.filterForm.value.contractors,
                systemOwner: this.filterForm.value.systemOwner,
                scManager: this.filterForm.value.scManager,
                mcEngineer: this.filterForm.value.mcEngineer,
                stagedStartUpPriorities: this.filterForm.value.stagedStartUpPriorities,
                page: this.paginator.pageIndex,
                take: this.pageSize,
                direction: this.filterForm.value.direction,
                sortBy: this.filterForm.value.sortBy,
                scrollSearch: this.scrollSearch,
            })
        );
    }

    onPaginatorChange(pageEvent: PageEvent) {
        this.pageSize = pageEvent.pageSize;
        this.selectedPageSize = pageEvent.pageSize;
        this.store.dispatch(
            new SubsystemMCDashboard2UpdateFilterProperties({
                page: pageEvent.pageIndex,
                take: pageEvent.pageSize,
            })
        );
        this.filterSubsystemMCData();
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    reverseValue(columnName: string) {
        this.showHideColumns[columnName] = !this.showHideColumns[columnName];
        this.store.dispatch(
            new SubsystemMCDashboard2UpdateFilterProperties({
                showHideColumns: this.showHideColumns,
            })
        );
    }

    private filterSubsystemMCData() {
        this.store.dispatch(new SubsystemMCDashboard2Request());
    }

    exportToExcel() {
        this.updateFilterStore();
        this.store.dispatch(new SubsystemMCDashboard2ExportToExcelRequest());
    }
}
