<form [formGroup]="filterForm">
    <div class="dmtitle-container">
        <h4>modify contractor data</h4>
        <p>
            <span>Filter by </span>
            <mat-form-field appearance="outline" class="margin-right20">
                <mat-label>Project Team</mat-label>
                <mat-select formControlName="projectTeamNames" multiple (openedChange)="onProjectTeamsClosed($event)">
                    <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                </mat-select>
            </mat-form-field>
        </p>
    </div>
    <div class="results-container">
        <table
            mat-table
            matSort
            [dataSource]="data"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container matColumnDef="projectTeamName">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Project team</th>
                <td mat-cell *matCellDef="let element">{{ element.projectTeamName }}</td>
            </ng-container>
            <ng-container matColumnDef="contractNo">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Contract No</th>
                <td mat-cell *matCellDef="let element">{{ element.contractNo }}</td>
            </ng-container>
            <ng-container matColumnDef="contract">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Contractor</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="!element.isInEditMode">{{ element.contract }}</div>
                    <div *ngIf="element.isInEditMode" class="editmode">
                        <mat-form-field
                            ><input
                                matInput
                                [(ngModel)]="element.contract"
                                [ngModelOptions]="{ standalone: true }"
                                name="contract"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.isInEditMode" mat-icon-button (click)="enterEditMode(element)" title="edit">
                        <mat-icon svgIcon="edit"></mat-icon>
                    </button>
                    <button *ngIf="element.isInEditMode" mat-icon-button (click)="save(element)" title="accept">
                        <mat-icon>check_circle_outline</mat-icon>
                    </button>
                    <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="remove">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="remove(element.contractNo)" title="remove">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-toolbar>
            <span>Add new Contract No</span>
            <button mat-icon-button (click)="add()" title="add">
                <mat-icon svgIcon="add"></mat-icon>
            </button>
        </mat-toolbar>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
</form>

<app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
