import { Pipe, PipeTransform } from '@angular/core';
import { TechnicalQueryAttachmentType, TQAttachment } from '../store/change-document-details/model';

@Pipe({
    name: 'attachments',
    pure: false,
})
export class AttachmentsPipe implements PipeTransform {
    transform(items: TQAttachment[], type: TechnicalQueryAttachmentType): any {
        if (!items || type === null) {
            return items;
        }
        return items.filter((item) => item.technicalQueryAttachmentType === type);
    }
}
