import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { catchError, take, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { ContractorMappingDTO } from 'src/app/models/contractor-mapping-dto';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ContractorsMappingService } from 'src/app/services/api/webapi-services/contractor-mapping.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Contractor, OrderDirection } from 'src/app/store/common.model';
import { AddContractorMappingComponent } from './add-contractor-mapping/add-contractor-mapping.component';

@Component({
    selector: 'app-contractor-mapping',
    templateUrl: './contractor-mapping.component.html',
    styleUrls: ['./contractor-mapping.component.scss']
})
export class ContractorMappingComponent extends BaseComponent implements OnInit {
    @Input() changeType: string;
    resultsLength = 0;
    pageSize = 10;
    sortBy: string = 'changeType';
    direction = OrderDirection.Desc;
    isLoading = false;
    filterForm: UntypedFormGroup;
    displayedColumns = ['mapping', 'contractNo', 'remove'];
    dataSource = new MatTableDataSource<ContractorMappingDTO>();
    contractors: Contractor[] = [];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private popupService: PopupService,
        private contractorMappingService: ContractorsMappingService,
        private lookupService: LookupService,
        private toastService: ToastService) {
        super();

        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.isLoading = true;
        forkJoin([this.lookupService.searchContractors(null, null, null, []), this.contractorMappingService.get(this.changeType)])
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error('Error occurred while getting data. Please contact Program Administrator.');
                })
            )
            .subscribe(
                ([contractors, mappings]) => {
                    this.contractors = contractors;
                    this.dataSource = new MatTableDataSource(mappings);
                    this.resultsLength = this.dataSource.data.length;
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.isLoading = false;
                },
                (error) => {
                    this.isLoading = false;
                    this.toastService.Error(error);
                }
            );
    }

    remove(element: ContractorMappingDTO) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete ${element.mapping} mapping?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.isLoading = true;
                    this.contractorMappingService
                        .remove(element.id)
                        .pipe(takeWhile(() => this.isAlive === true))
                        .subscribe(
                            () => {
                                let data = this.dataSource.data.filter((s) => s.id !== element.id);
                                this.dataSource = new MatTableDataSource(data);
                                this.resultsLength = this.dataSource.data.length;
                                this.dataSource.sort = this.sort;
                                this.dataSource.paginator = this.paginator;
                                this.isLoading = false;
                            },
                            () => {
                                this.isLoading = false;
                                this.toastService.Error(
                                    `Error occurred while deleting ${element.mapping} mapping. Please contact Program Administrator.`
                                );
                            }
                        );
                }
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(
            new PopupSettings(AddContractorMappingComponent, 450, 430, {
                changeType: this.changeType,
                contractors: this.contractors,
            })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    const data = this.dataSource.data;
                    data.push(result.data);
                    this.dataSource.data = data;
                    this.resultsLength = this.dataSource.data.length;
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                }
            });
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        this.paginator.pageIndex = newPage - 1;
        this.dataSource.paginator = this.paginator;
    }

}
