import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';
import { CheckListColumn, OrderDirection } from '../../common.model';

export interface ITRConstraintsContactsState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deleteData: ITRConstraintsContactsDeletePagination;
    isDeleteDataLoading: boolean;
    deleteFilter: ITRConstraintsContactsDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class ITRConstraintsContactsDeleteDTO {
    deleteState: boolean;
    constraintType: string;
    constraintResponsibleUser: string;
    constraintAdditionalResponsibleUser: string;
}

export class ITRConstraintsContactsDeleteFilter {
    page = 0;
    take = 10;
    sortBy = 'constraintId';
    direction: OrderDirection = OrderDirection.Desc;
    columnConstraintType: CheckListColumn = null;
}
export class ITRConstraintsContactsDeletePagination {
    items: ITRConstraintsContactsDeleteDTO[] = [];
    totalCount = 0;
}
