import { Component, OnInit } from '@angular/core';
import { map, takeWhile } from 'rxjs/operators';
import { ScheduleActivityPlanningService } from 'src/app/services/api/webapi-services/schedule-activity-planning.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ScheduleActivityPlanningFilter } from 'src/app/store/weekly-planning/schedule-activity-planning/model';
import { BaseComponent } from 'src/app/components/base.component';
import * as moment from 'moment';
import { WorkAreaFilter } from 'src/app/store/common.model';
import { ScheduleActivitySetSubsytemsUpdate } from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { MatDialogRef } from '@angular/material/dialog';
import { SubsystemSelectorComponent } from 'src/app/modules/shared/components/subsystem-selector/subsystem-selector.component';

@Component({
  selector: 'app-add-subsystem',
  templateUrl: './add-subsystem.component.html',
  styleUrls: ['./add-subsystem.component.scss']
})
export class AddSubsystemComponent extends BaseComponent implements OnInit {
  selectedSubsystems: any[] = [];
  filter = new ScheduleActivityPlanningFilter();
  subsystemFilter: WorkAreaFilter;
  weekStart: moment.Moment;
  weekStart$ = this.store.select((state) =>
    state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekStart
  );
  isLoading = false;
  constructor(
    private store: Store<ApplicationState>,
    private scheduleActivityService: ScheduleActivityPlanningService,
    private dialogRef: MatDialogRef<SubsystemSelectorComponent>,
    private popupService: PopupService) {
      super();
    }

  ngOnInit(): void {
    this.weekStart$.pipe(takeWhile(() => this.isAlive))
      .subscribe(data => this.weekStart = data);
    
    this.scheduleActivityService
      .getSubsystemsInWeek({ ...new ScheduleActivityPlanningFilter, weekStart: this.weekStart})
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data: any[]) => this.selectedSubsystems = data);
  }

  setSubsystems(subsystems: any[]) {
    const subsystemsToUpdate = subsystems.map(s => s.id);
    this.scheduleActivityService.getSubsystemsInWeekITRsCount({ weekStart: this.weekStart, subsystems: subsystemsToUpdate })
    .subscribe(
      (data) => {
        if (data > 0) {
          let warning = data > 0 ?
            `<p><span class="warning"">Warning: </span> This action will delete ${data} selected ITR(s).</p>` : '';
          this.popupService
          .openPopup(
              new ConfirmDialogPopupSettings({
                  title: 'Confirm action',
                  htmlText: `<p>Are you sure you want to change subsystem(s) in week records?</p>` + warning,
              })
          )
          .afterClosed()
          .pipe(takeWhile(() => this.isAlive === true))
          .subscribe((answer) => {
              if (answer) {
                this.store.dispatch(new ScheduleActivitySetSubsytemsUpdate({ weekStart: this.weekStart, subsystems }))
              }
              this.dialogRef?.close();
          });
        } else {
          this.store.dispatch(new ScheduleActivitySetSubsytemsUpdate({ weekStart: this.weekStart, subsystems }))
          this.dialogRef?.close();
        }
      }
    );
  }

  searchSubsystems = (takeCount = 10, page = 0) => {
    return this.scheduleActivityService
        .getSubsystemsNotInWeek(
          this.subsystemFilter?.subsystem ?? '', 
          {
            ...this.filter,
            weekStart: this.weekStart,
            page,
            take: takeCount,
            rfos: this.subsystemFilter?.rfo,
            goocs: this.subsystemFilter?.goocs
          }
        )
        .pipe(map((subs: any[]) => subs));
  }

  setSubsystemFilter(filter: any) {
    this.subsystemFilter = filter;
  }
}
