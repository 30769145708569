<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span>{{ activeLink }} Icicle Chart</span>
        </div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.subsystems.value.length" removable>
                                Subsystem: {{ displayMultipleSelected(filterForm.controls.subsystems.value, 'id') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('subsystems')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                System Owner:
                                {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                        <mat-chip-list *ngIf="activeLink == 'RFSU'" aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                                RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value, 'name') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                        <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                            SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                            Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                            Area Breakdown:
                            {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                            RFSU Contractor:
                            {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                        </mat-basic-chip>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">

                    <div class="container620 margin-right15">
                        <app-multiple-items-selector
                            formControlName="subsystems"
                            [searchFunc]="getSubsystems"
                            [setInput]="setSubsystemInput"
                            [isAsync]="true"
                            placeholder="Subsystem"
                            [displayedColumns]="subsystemAutocompleteDisplayedColumns"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            [setInput]="setStagedStartUpPrioritiesInput"
                            formControlName="stagedStartUpPriorities"
                            [searchFunc]="getStagedStartUpPriorities"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['priority', 'priorityName']"
                            (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                            (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                            [filterForm]="filterForm"
                            formSetter="stagedStartUpPriorities"
                            [propertyToShow]="'priority'"                                    
                            [secondPropertyToShow]="'priorityName'"
                            placeholder="Staged Start-up Priority"
                            [panelWidth]="480"
                            class="container300"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>Project Team</mat-label>
                        <mat-select
                            formControlName="projectTeamNames"
                            multiple
                            (openedChange)="onProjectTeamsClosed($event)"
                        >
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManager" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwner" multiple>
                            <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div *ngIf="activeLink == 'RFSU'" class="container300 margin-right15">
                        <app-multiple-items-selector
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFSUContractorsInput"
                            formControlName="rfsuContractors"
                            [searchFunc]="getRFSUContractors"
                            [isAsync]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            [filterForm]="filterForm"
                            [formSetter]="'rfsuContractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'RFSU Contractor'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>Area Completion Lead</mat-label>
                        <mat-select formControlName="areaCompletionLead" multiple>
                            <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>

                </div>

                <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">
                search
            </button>
        </div>
        <div class="icicle-container">
            <app-icicle-chart-main
                [data]="data$ | async"
                [type]="type$ | async"
                [view]="[1260, 600]"
                [startDate]="startDate"
                (startDateSet)="startDateSet($event)"
            ></app-icicle-chart-main>
            <div *ngIf="isLoading$ | async" class="delta-loader">
                <div class="lds-hourglass"></div>
            </div>
        </div>
    </form>
</main>
