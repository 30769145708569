import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from '../../base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { FieldChangeSummaryRequest } from 'src/app/store/field-change-summary/actions';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-field-change-summary',
    templateUrl: './field-change-summary.component.html',
    styleUrls: ['./field-change-summary.component.scss'],
})
export class FieldChangeSummaryComponent extends BaseComponent implements OnInit {
    closedColumns: string[] = ['id', 'title', 'status', 'discipline', 'contract', 'exceptionNumber', 'comment'];
    toImplementColumns: string[] = ['id', 'title', 'status', 'discipline', 'contract', 'exceptionNumber', 'comment'];
    inEngineeringColumns: string[] = ['id', 'title', 'discipline', 'contract', 'exceptionNumber', 'comment'];

    summaryStateData$ = this.store.select((x) => x.fieldChangeDocumentSummaryState.data);
    loader$ = this.store.select((x) => x.fieldChangeDocumentSummaryState.isLoading);

    closedSource: MatTableDataSource<any> = new MatTableDataSource();
    toImplementSource: MatTableDataSource<any> = new MatTableDataSource();
    inEngineeringSource: MatTableDataSource<any> = new MatTableDataSource();

    subsystem: string;
    isLoading: boolean = false;

    numberHeader: string = 'number';
    now = new Date();

    @ViewChild('closedSort') closedSort: MatSort;
    @ViewChild('toImplementSort') toImplementSort: MatSort;
    @ViewChild('inEngineeringSort') inEngineeringSort: MatSort;

    constructor(
        private store: Store<ApplicationState>,
        private activatedRoute: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            const subsystem = paramMap.get('subsystem');
            this.subsystem = decodeURIComponent(subsystem);
            this.store.dispatch(new FieldChangeSummaryRequest(this.subsystem));
        });

        this.loader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.summaryStateData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.closedSource = new MatTableDataSource(data.closed);
            this.closedSource.sort = this.closedSort;

            this.toImplementSource = new MatTableDataSource(data.toImplement);
            this.toImplementSource.sort = this.toImplementSort;

            this.inEngineeringSource = new MatTableDataSource(data.inEngineering);
            this.inEngineeringSource.sort = this.inEngineeringSort;
        });
    }

    onPrint() {
        window.print();
    }
}
