import { ChangeDocumentDetailsActions, ChangeDocumentDetailsActionTypes } from './actions';
import { ChangeDocumentDetailsDTO, ChangeDocumentDetailsState, IWPLink } from './model';
import { CommentDTO } from '../common.model';

const initialState: ChangeDocumentDetailsState = {
    item: new ChangeDocumentDetailsDTO(),
    comments: [],
    isLoading: false,
    iwpLink: new IWPLink(),
};

export function reducer(state: ChangeDocumentDetailsState = initialState, action: ChangeDocumentDetailsActions) {
    switch (action.type) {
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsUpdateIwpRequest:
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsGetIwpRequest:
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsDeleteCommentRequest:
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsAddCommentRequest:
        case ChangeDocumentDetailsActionTypes.GetChangeDocumentDetailsRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ChangeDocumentDetailsActionTypes.GetChangeDocumentDetailsSuccess: {
            // if (action.payload.subsystemImpactedNA) {
            //     action.payload.impactedSubsystems =
            //         'N/A' +
            //         (action.payload.impactedSubsystems != null && action.payload.impactedSubsystems != ''
            //             ? ', ' + action.payload.impactedSubsystems
            //             : '');
            // }
            return {
                ...state,
                item: action.payload,
                comments: action.payload.comments,
                isLoading: false,
            };
        }
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsUpdateIwpError:
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsUpdateIwpSuccess:
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsGetIwpError:
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsDeleteCommentError:
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsAddCommentError:
        case ChangeDocumentDetailsActionTypes.GetChangeDocumentDetailsError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsAddCommentSuccess: {
            const newComment = new CommentDTO();
            newComment.description = action.payload.description;
            newComment.date = action.payload.date;
            newComment.userName = action.payload.userName;
            newComment.id = action.payload.id;
            newComment.mentions = action.payload.mentions;

            return {
                ...state,
                comments: [...state.comments, newComment],
                isLoading: false,
            };
        }
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsDeleteCommentSuccess: {
            return {
                ...state,
                comments: [...state.comments].filter((x) => x.id !== action.payload.id),
                isLoading: false,
            };
        }
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsGetIwpSuccess: {
            return {
                ...state,
                iwpLink: action.payload,
                isLoading: false,
            };
        }
        case ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsClearIwpLinks: {
            return {
                ...state,
                iwpLink: {
                    ...new IWPLink(),
                },
            };
        }
        default: {
            return state;
        }
    }
}
