import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { ImplementationStatusMappingDTO } from 'src/app/models/implementation-status-mapping-dto';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ImplementationStatusMappingService } from 'src/app/services/api/webapi-services/implementation-status-mapping-service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AddImplementationStatusMappingComponent } from './add-implementation-status-mapping/add-implementation-status-mapping.component';

@Component({
  selector: 'app-implementation-status-mapping',
  templateUrl: './implementation-status-mapping.component.html',
  styleUrls: ['./implementation-status-mapping.component.scss']
})
export class ImplementationStatusMappingComponent extends BaseComponent implements OnInit {
  @Input() changeType: string;

  displayedColumns = ['implementationStatus', 'sourceFileStatus', 'remove'];
  dataSource = new MatTableDataSource<ImplementationStatusMappingDTO>();
  isLoading: boolean;

  constructor(private implementationStatusService: ImplementationStatusMappingService,
    private popupService: PopupService,
    private toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.implementationStatusService.get(this.changeType)
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((data) => this.dataSource = new MatTableDataSource(data));
  }

  add() {
    const dialogRef = this.popupService.openPopup(
      new PopupSettings(AddImplementationStatusMappingComponent, 450, 430, {
        changeType: this.changeType,
      })
    );

    dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((result) => {
        if (result && result.success) {
          const data = this.dataSource.data;
          data.push(result.data);
          this.dataSource.data = data;
        }
      });
  }
  remove(element: ImplementationStatusMappingDTO) {
    this.popupService
      .openPopup(
        new ConfirmDialogPopupSettings({
          title: 'Confirm action',
          text: `Are you sure you want to delete ${element.sourceFileStatus} mapping?`,
        })
      )
      .afterClosed()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((answer) => {
        if (answer) {
          this.isLoading = true;
          this.implementationStatusService
            .remove(element.id)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
              () => {
                let data = this.dataSource.data.filter((s) => s.id !== element.id);
                this.dataSource = new MatTableDataSource(data);
                this.isLoading = false;
              },
              () => {
                this.isLoading = false;
                this.toastService.Error(
                  `Error occurred while deleting ${element.sourceFileStatus} mapping. Please contact Program Administrator.`
                );
              }
            );
        }
      });
  }
}
