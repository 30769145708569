import { Injectable } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { Dictionary } from "typescript-collections";
import { IValidators } from "./validators";

@Injectable({
    providedIn: 'root',
})
export class ActivityValidators implements IValidators {
    private validators: Dictionary<string, ValidatorFn[]>;

    constructor() {
        this.validators = new Dictionary<string, ValidatorFn[]>();
        this.configure();
    }

    getValidators(fieldName: string) {
        return this.validators.getValue(fieldName);
    }

    private configure(): void {
        this.validators.setValue('activity', [Validators.required]);
        this.validators.setValue('activityDiscipline', [Validators.required]);
        this.validators.setValue('forecastFinish', [Validators.required]);
        this.validators.setValue('forecastStart', [Validators.required]);
        this.validators.setValue('phase', [Validators.required]);
    }
}