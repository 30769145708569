<main class="main">
    <div class="import-container">
        <h4>CWP register import</h4>
        <div class="import-subtitle">
            <button
                mat-icon-button
                (click)="showOrder = !showOrder"
                title="{{
                showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                }}"
            >
                <mat-icon>info</mat-icon>
            </button>
            See <b (click)="downloadTemplate('CwpRegister.csv')">CwpRegister.csv</b> structure. SCMT synchronised to
            upload file. Data only imported for Project Teams in SCMT. Where an existing CWP in live database is linked
            to a&nbsp;different Project Team (as compared to the uploaded file), the Admin is given the opportunity
            reject the update. Where an existing CWP in live database isn't found in the uploaded file, the Admin is
            given the opportunity to keep the record in live database.
        </div>
        <div *ngIf="!showOrder" class="list-container">
            <div class="list-structure">
                <ol>
                    <li>Project Name</li>
                    <li>CWP Number</li>
                    <li>Description</li>
                    <li>Model Review Area</li>
                    <li>Construction Type</li>
                    <li>Material Destination</li>
                    <li>Revision</li>
                    <li>Area Sub Code</li>
                    <li>Sub Code Description</li>
                    <li>Phase</li>
                    <li>ExecutionType</li>
                    <li>Change Request Number</li>
                    <li>CWP Status</li>
                    <li>Last Update</li>
                    <li>Last Update By</li>
                    <li>Contract Combined</li>
                </ol>
            </div>
        </div>
    </div>
    <div class="delta-container">
        <div class="header">
            <h4>Delta validation</h4>
            <span> - validate changes to CWP-Project Team association in live database</span>
        </div>
        <span *ngIf="!deltaData.data.length">No delta validation data available.</span>
        <div class="changebuttons-container">
            <button (click)="patchAllChanges(false)" *ngIf="deltaData.data.length" mat-icon-button title="accept">
                <mat-icon>check_circle_outline</mat-icon>
            </button>
            <span *ngIf="deltaData.data.length" class="margin-right20">Accept all changes</span>
            <button (click)="patchAllChanges(true)" *ngIf="deltaData.data.length" mat-icon-button title="reject">
                <mat-icon>highlight_off</mat-icon>
            </button>
            <span *ngIf="deltaData.data.length">Reject all changes</span>
        </div>
        <table mat-table [dataSource]="deltaData" *ngIf="deltaData.data.length">
            <ng-container matColumnDef="cwp">
                <th mat-header-cell *matHeaderCellDef class="empty">CWP</th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.cwp }}
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="empty">CWP Description</th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.description }}
                </td>
            </ng-container>
            <ng-container matColumnDef="projectTeamNameLive">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150">Project Team</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.projectTeamNameLive }}
                </td>
            </ng-container>
            <ng-container matColumnDef="arrow">
                <th mat-header-cell *matHeaderCellDef class="empty cell-width-75"></th>
                <td mat-cell *matCellDef="let element" class="empty">
                    <mat-icon>arrow_forward</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="projectTeamNameImport">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150">Project Team</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.projectTeamNameImport }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="empty cell-width-150"></th>
                <td mat-cell *matCellDef="let element" class="empty">
                    <div *ngIf="element.deltaState" (click)="acceptChange(element)">
                        <button mat-icon-button title="accept">
                            <mat-icon>check_circle_outline</mat-icon>
                        </button>
                        <span>Accept</span>
                    </div>
                    <div *ngIf="!element.deltaState" (click)="rejectChange(element)">
                        <button mat-icon-button title="reject">
                            <mat-icon>highlight_off</mat-icon>
                        </button>
                        <span>Reject</span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedDeltaDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDeltaDataColumns"></tr>
        </table>
        <mat-paginator
                *ngIf="deltaData.data.length"
                [length]="deltaDataResultsLength"
                [pageSize]="deltaDataPageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onDeltaDataPaginatorChange($event)"
                showFirstLastButtons
        >
        </mat-paginator>
        <div *ngIf="isDeltaDataLoading$ | async" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="delete-container">
        <div class="section-header">
            <h4>Records marked for deletion</h4>
            <span> - record not present in upload file; validate deletion of existing record in live database</span>
        </div>
        <span *ngIf="!deleteData.data.length">No delete records available.</span>
        <div class="changebuttons-container">
            <button (click)="patchAllDeleteRecords(true)" *ngIf="deleteData.data.length" mat-icon-button title="accept">
                <mat-icon>delete</mat-icon>
            </button>
            <span *ngIf="deleteData.data.length" class="margin-right20">Delete all records</span>
            <button
                    (click)="patchAllDeleteRecords(false)"
                    *ngIf="deleteData.data.length"
                    mat-icon-button
                    title="reject"
            >
                <mat-icon>restore_from_trash</mat-icon>
            </button>
            <span *ngIf="deleteData.data.length">Keep all records</span>
        </div>
        <table mat-table [dataSource]="deleteData" *ngIf="deleteData.data.length">
            <ng-container matColumnDef="projectTeamName">
                <th mat-header-cell *matHeaderCellDef>Project Team</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.projectTeamName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="cwp">
                <th mat-header-cell *matHeaderCellDef>CWP</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.cwp }}
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>CWP Description</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.description }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150"></th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleteState" (click)="restoreCWP(element)">
                        <button mat-icon-button title="restore">
                            <mat-icon>restore_from_trash</mat-icon>
                        </button>
                        <span>Restore</span>
                    </div>
                    <div *ngIf="!element.deleteState" (click)="deleteCWP(element)">
                        <button mat-icon-button title="reject">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <span>Delete</span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedDeleteDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDeleteDataColumns"></tr>
        </table>
        <mat-paginator
                *ngIf="deleteData.data.length"
                [length]="deleteDataResultsLength"
                [pageSize]="deleteDataPageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onDeleteDataPaginatorChange($event)"
                showFirstLastButtons
        >
        </mat-paginator>
        <div *ngIf="isDeleteDataLoading$ | async" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="upload-log-container">
        <h4>Import history</h4>
        <table mat-table [dataSource]="uploadLogData">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="getImportClassName(element.status)">
                        {{ element.status }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Start date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.startDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.endDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="infoMessage">
                <th mat-header-cell *matHeaderCellDef>Info message</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.infoMessage"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="errorMessage">
                <th mat-header-cell *matHeaderCellDef>Error message</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.errorMessage ? element.errorMessage : 'No errors' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.user }}
                </td>
            </ng-container>
            <ng-container matColumnDef="downloadResult">
                <th mat-header-cell *matHeaderCellDef>Download result</th>
                <td mat-cell *matCellDef="let element">
                    <svg
                            class="downloadIcon"
                            *ngIf="element.outputDataLink"
                            (click)="downloadData(element.outputDataLink)"
                            width="18px"
                            height="20px"
                            viewBox="0 0 16 17"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                        <title>{{ element.outputDataLink }}</title>
                        <desc>Created with sketchtool.</desc>
                        <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        >
                            <g
                                    id="New-FCNi-Copy"
                                    transform="translate(-1259.000000, -1440.000000)"
                                    stroke="#0066B2"
                                    stroke-width="2"
                            >
                                <g id="Buttons/Primary-Copy" transform="translate(1256.000000, 1438.000000)">
                                    <path d="M4,18 L18,18" id="Line-3"></path>
                                    <path
                                            d="M5.5,8.5 L16.5,8.5"
                                            id="Line-3-Copy"
                                            transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                    ></path>
                                    <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span *ngIf="isImportInProgress(element.status)" class="in-progress"></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedUploadLogDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedUploadLogDataColumns"></tr>
        </table>
        <div *ngIf="isUploadLogLoading$ | async" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading$ | async"></app-loading-indicator>
</main>
