import { Gooc, Milestone, McMilestone, RFO, Contractor, StagedStartUpPriority } from '../../common.model';

export const ICICLE_API_CALL_DATE_FORMAT = 'YYYY-MM-DD';
export const ICICLE_RANGE_WEEKS = -9;
export interface Point {
    date: string;
    updateWeek: string;
}

export class IcicleDTO {
    name: string;
    points: Point[];
}

export interface IcicleState {
    type: string;
    data: IcicleDTO[];
    isLoading: boolean;
    filter: IcicleFilter;
    startDate: string;
}

export interface Result {
    name: string;
    series: { name: any; value: any }[];
}

export class IcicleFilter {
    subsystems: { id: string }[] = [];
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
    milestones: Milestone[] = [];
    waypoints: Milestone[] = [];
    mcMilestones: McMilestone[] = [];
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    mcEngineer: string[] = [];
    scManager: string[] = [];
    systemOwner: string[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
}
