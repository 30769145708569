import { Component, Inject } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AssignProjectTeamRequest } from 'src/app/models/assign-project-team-request';
import { ValidationFormService } from 'src/app/services/api/webapi-services/validation-form.service';
import { ProjectTeamWithContract } from 'src/app/models/project-team-with-contract';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-assign-project-team-popup',
    templateUrl: './assign-project-team-popup.component.html',
    styleUrls: ['./assign-project-team-popup.component.scss'],
})
export class AssignProjectTeamPopupComponent extends BaseComponent {
    loading: boolean;
    id: number;
    assignForm: UntypedFormGroup;
    projectWithContractArr: ProjectTeamWithContract[];
    constructor(
        public dialogRef: MatDialogRef<AssignProjectTeamPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: ToastService,
        private validationFormService: ValidationFormService
    ) {
        super();
        this.id = data.validationId;
        this.projectWithContractArr = [...(data.projectTeamWithContract as ProjectTeamWithContract[])];
        this.assignForm = new UntypedFormGroup({
            projectWithContract: new UntypedFormControl('', Validators.required),
        });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        var form = new AssignProjectTeamRequest();
        form.id = this.id;
        form.projectTeamName = this.assignForm.controls.projectWithContract.value.projectTeamName;
        form.contractor = this.assignForm.controls.projectWithContract.value.contractor;
        this.validationFormService
            .assignProjectTeam(form)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (data) => {
                    this.loading = false;
                    this.toastService.Success('Project Team and Contract successfully assigned.');
                    this.dialogRef.close({ projectTeamName: data.projectTeamName, contractor: data.contractor });
                },
                () => {
                    this.loading = false;
                    this.toastService.Error(
                        'Error occurred while assigining Project Team and Contract. Please contact Program Administrator.'
                    );
                }
            );
    }
}
