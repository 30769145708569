import { Injectable } from '@angular/core';
import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
    WeeklyPlanningDataRequest,
    WeeklyPlanningActionTypes,
    WeeklyPlanningDataSuccess,
    WeeklyPlanningDataError,
    WeeklyPlanningCommitRequest,
    WeeklyPlanningCommitSuccess,
    WeeklyPlanningCommitError,
    WeeklyPlanningDeleteCommitmentRequest,
    WeeklyPlanningDeleteCommitmentSuccess,
    WeeklyPlanningDeleteCommitmentError,
    WeeklyPlanningFilterRequest,
    WeeklyPlanningSubmitCommitmentsRequest,
    WeeklyPlanningSubmitCommitmentsSuccess,
    WeeklyPlanningSubmitCommitmentsError,
    WeeklyPlanningCommitmentsRequest,
    WeeklyPlanningCommitmentsSuccess,
    WeeklyPlanningCommitmentsError,
    WeeklyPlanningCommitmentsFilterRequest,
    WeeklyPlanningTopHeaderSectionDataRequest,
    WeeklyPlanningTopHeaderSectionDataSuccess,
    WeeklyPlanningTopHeaderSectionDataError,
} from './actions';
import { mergeMap, withLatestFrom, map, catchError, switchMap, filter } from 'rxjs/operators';
import { WeeklyPlanningService } from 'src/app/services/api/webapi-services/weekly-planning.service';
import { of } from 'rxjs';
import {
    WeeklyPlanningFilter,
    WeeklyPlanningTableResult,
    MCWeeklyPlanningDTO,
    RFSUWeeklyPlanningDTO,
    WeeklyPlanningCommitDTO,
} from './model';
import * as moment from 'moment';

@Injectable()
export class WeeklyPlanningEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private weeklyPlanningService: WeeklyPlanningService
    ) { }

    @Effect()
    getWeeklyPlanningData$ = this.actions$.pipe(
        ofType<WeeklyPlanningDataRequest>(WeeklyPlanningActionTypes.WeeklyPlanningDataRequest),
        mergeMap((action) =>
            of(action).pipe(
                withLatestFrom(
                    this.store.select((store) => store.weeklyPlanningState.filter),
                    this.store.select(
                        (store) =>
                            store.weeklyPlanningState[action.payload.planningType][action.payload.planningTableIndex]
                    )
                )
            )
        ),
        mergeMap(
            ([action, filter, tableResult]: [
                WeeklyPlanningDataRequest,
                WeeklyPlanningFilter,
                WeeklyPlanningTableResult<MCWeeklyPlanningDTO | RFSUWeeklyPlanningDTO>
            ]) =>
                this.weeklyPlanningService
                    .getPlanningData(
                        action.payload.planningType,
                        action.payload.planningTableIndex,
                        filter,
                        tableResult.page,
                        tableResult.take,
                        tableResult.sortBy,
                        tableResult.direction,
                        tableResult.tableHeaderFilter
                    )
                    .pipe(
                        map(
                            (response: any) =>
                                new WeeklyPlanningDataSuccess({
                                    result: response,
                                    planningTableIndex: action.payload.planningTableIndex,
                                    planningType: action.payload.planningType,
                                })
                        ),
                        catchError((error) =>
                            of(
                                new WeeklyPlanningDataError({
                                    error,
                                    planningTableIndex: action.payload.planningTableIndex,
                                    planningType: action.payload.planningType,
                                })
                            )
                        )
                    )
        )
    );


    @Effect()
    getWeeklyCommitmentsData$ = this.actions$.pipe(
        ofType<WeeklyPlanningCommitmentsRequest>(WeeklyPlanningActionTypes.WeeklyPlanningCommitmentsRequest),
        mergeMap((action) =>
            of(action).pipe(
                withLatestFrom(
                    this.store.select((store) => store.weeklyPlanningState.filter)
                )
            )
        ),
        mergeMap(
            ([action, filter]: [
                WeeklyPlanningCommitmentsRequest,
                WeeklyPlanningFilter
            ]) =>
                this.weeklyPlanningService
                    .getWeeklyCommitments(
                        action.payload.projectTeamName,
                        action.payload.planningType,
                        filter,
                    )
                    .pipe(
                        map(
                            (response: any) =>
                                new WeeklyPlanningCommitmentsSuccess({
                                    result: response,
                                })
                        ),
                        catchError((error) =>
                            of(
                                new WeeklyPlanningCommitmentsError({
                                    error,
                                    planningType: action.payload.planningType,
                                })
                            )
                        )
                    )
        )
    );


    @Effect()
    getWeeklyTopHeaderSectionData$ = this.actions$.pipe(
        ofType<WeeklyPlanningTopHeaderSectionDataRequest>(WeeklyPlanningActionTypes.WeeklyPlanningTopHeaderSectionDataRequest),
        mergeMap((action) =>
            of(action).pipe(
                withLatestFrom(
                    this.store.select((store) => store.weeklyPlanningState.filter),
                    this.store.select((store) => store.weeklyPlanningState.planningType)
                )
            )
        ),
        mergeMap(
            ([action, filter, planningType]: [
                WeeklyPlanningTopHeaderSectionDataRequest,
                WeeklyPlanningFilter,
                string
            ]) =>
                this.weeklyPlanningService
                    .getTopHeaderSectionData(
                        action.payload.projectTeamName,
                        planningType,
                        action.payload.filter ?? filter,
                    )
                    .pipe(
                        map(
                            (response: any) =>
                                new WeeklyPlanningTopHeaderSectionDataSuccess({
                                    result: response,
                                })
                        ),
                        catchError((error) =>
                            of(
                                new WeeklyPlanningTopHeaderSectionDataError({
                                    error,
                                    planningType: planningType,
                                })
                            )
                        )
                    )
        )
    );

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType<WeeklyPlanningFilterRequest>(WeeklyPlanningActionTypes.WeeklyPlanningFilterRequest),
        withLatestFrom(
            this.store.select((store) => store.weeklyPlanningState.planningType),
            this.store.select((store) => store.weeklyPlanningState.filter.startDate)
        ),
        filter(([, , startDate]) => {
            const today = moment().utc().startOf('day');
            if (today.isoWeekday() !== 6) {
                today.add(-1, 'weeks').isoWeekday(6);
            }
            return startDate.isSameOrAfter(today);
        }),
        switchMap(([action, planningType]) =>
            action.payload.map(
                (index) =>
                    new WeeklyPlanningDataRequest({
                        planningType,
                        planningTableIndex: index,
                    })
            )
        )
    );

    @Effect()
    filterCommitmentsRequest$ = this.actions$.pipe(
        ofType<WeeklyPlanningCommitmentsFilterRequest>(WeeklyPlanningActionTypes.WeeklyPlanningCommitmentsFilterRequest),
        withLatestFrom(
            this.store.select((store) => store.weeklyPlanningState.planningType),
        ),
        map(([action, planningType,]) =>
                    new WeeklyPlanningCommitmentsRequest({
                        projectTeamName: action.projectTeamName,
                        planningType: planningType,
                    })

        )
    );



    @Effect()
    submitCommitments$ = this.actions$.pipe(
        ofType<WeeklyPlanningSubmitCommitmentsRequest>(WeeklyPlanningActionTypes.WeeklyPlanningSubmitCommitmentsRequest),
        withLatestFrom(this.store.select((store) => store.weeklyPlanningState.filter)),
        mergeMap(([action, filter]) =>
            this.weeklyPlanningService
                .submitCommitments(
                    action.payload.planningType, filter,
                )
                .pipe(
                    map(
                        () =>
                            new WeeklyPlanningSubmitCommitmentsSuccess()
                    ),
                    catchError((error) =>
                        of(
                            new WeeklyPlanningSubmitCommitmentsError({
                                error,
                                planningType: action.payload.planningType,
                            })
                        )
                    )
                )
        )
    );


    @Effect()
    commitSubsystem$ = this.actions$.pipe(
        ofType<WeeklyPlanningCommitRequest>(WeeklyPlanningActionTypes.WeeklyPlanningCommitRequest),
        withLatestFrom(this.store.select((store) => store.weeklyPlanningState.filter.startDate)),
        mergeMap(([action, startDate]) =>
            this.weeklyPlanningService
                .commitSubsystem({
                    commitmentKey: action.payload.subsystem,
                    commitmentType: action.payload.planningType,
                    committedForWeek: startDate,
                })
                .pipe(
                    map(
                        (commit: WeeklyPlanningCommitDTO) =>
                            new WeeklyPlanningCommitSuccess({
                                planningType: action.payload.planningType,
                                planningTableIndex: action.payload.planningTableIndex,
                                commit,
                            })
                    ),
                    catchError((error) =>
                        of(
                            new WeeklyPlanningCommitError({
                                error,
                                planningType: action.payload.planningType,
                                planningTableIndex: action.payload.planningTableIndex,
                            })
                        )
                    )
                )
        )
    );

    @Effect()
    deleteCommitment$ = this.actions$.pipe(
        ofType<WeeklyPlanningDeleteCommitmentRequest>(WeeklyPlanningActionTypes.WeeklyPlanningDeleteCommitmentRequest),
        withLatestFrom(this.store.select((store) => store.weeklyPlanningState.filter.startDate)),
        mergeMap(([action, startDate]) =>
            this.weeklyPlanningService
                .deleteCommitment({
                    commitmentKey: action.payload.subsystem,
                    commitmentType: action.payload.planningType,
                    committedForWeek: startDate,
                })
                .pipe(
                    map(
                        (commit: WeeklyPlanningCommitDTO) =>
                            new WeeklyPlanningDeleteCommitmentSuccess({
                                planningType: action.payload.planningType,
                                planningTableIndex: action.payload.planningTableIndex,
                                commit,
                            })
                    ),
                    catchError((error) =>
                        of(
                            new WeeklyPlanningDeleteCommitmentError({
                                error,
                                planningType: action.payload.planningType,
                                planningTableIndex: action.payload.planningTableIndex,
                            })
                        )
                    )
                )
        )
    );
}
