import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile } from 'rxjs/operators';
import {
    MilestoneDashboardRedirectToDetailedStatus,
    MilestoneDashboardTotalCompleteRequest,
} from 'src/app/store/reports/milestone-dashboard/actions';
import { MilestoneDashboardType, TotalCompleteDataDTO } from 'src/app/store/reports/milestone-dashboard/model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DetailedStatusSetMakeRequest } from 'src/app/store/detailed-status/actions';
import { ChangeManagementSetMakeRequest } from 'src/app/store/change-management/actions';

@Component({
    selector: 'app-table-total-complete',
    templateUrl: './table-total-complete.component.html',
    styleUrls: ['./table-total-complete.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TableTotalCompleteComponent extends BaseComponent implements OnInit {
    milestoneDashboardTotalCompleteData$ = this.store.select(
        (state) => state.milestoneDashboardState.totalCompleteData
    );

    milestoneDashboardType$ = this.store.select((state) => state.milestoneDashboardState.dashboardType);

    isLoading$ = this.store.select((state) => state.milestoneDashboardState.isLoadingTotalComplete);
    totalCompleteData: TotalCompleteDataDTO[] = [];
    totalCompleteColumns = ['category', 'total', 'complete', 'exceptions', 'remaining', 'completePercentage'];
    datesList = [];
    panelOpenState = true;
    dashboardType: MilestoneDashboardType;
    dashboardTypes = MilestoneDashboardType;

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {
        this.totalCompleteColumns = this.totalCompleteColumns.concat(this.sortDays());
        this.totalCompleteColumns.push('weeklyProgress');
        this.datesList = this.setDatesArray();
        this.milestoneDashboardTotalCompleteData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data === null) {
                this.store.dispatch(new MilestoneDashboardTotalCompleteRequest());
            } else {
                this.totalCompleteData = data;
            }
        });

        this.milestoneDashboardType$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.dashboardType = data;
        });
    }

    toggleTotalCompleteDataDTO(element: TotalCompleteDataDTO) {
        this.totalCompleteData = [
            ...this.totalCompleteData.map((x) =>
                x.category === element.category ? { ...x, expanded: !x.expanded } : x
            ),
        ];
    }

    openDetailedStatusPage(
        category: string,
        discipline: string = undefined,
        exceptions = false,
        zone: string = undefined
    ) {
        category === 'NPW'
            ? this.store.dispatch(new ChangeManagementSetMakeRequest())
            : this.store.dispatch(new DetailedStatusSetMakeRequest());
        this.store.dispatch(new MilestoneDashboardRedirectToDetailedStatus({ category, discipline, exceptions, zone }));
    }

    sortDays() {
        let day_of_week = new Date().getDay();
        let list = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        let sorted_list = list.slice(day_of_week).concat(list.slice(0, day_of_week));
        return sorted_list.map((x) => x.toLowerCase());
    }

    addDays(days: number) {
        var date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    }

    setDatesArray() {
        let dates_list = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        var i;
        for (i = 0; i < 7; i++) {
            dates_list[i] = this.getLastDateOfWeekDay(i);
        }
        return dates_list;
    }

    getLastDateOfWeekDay(day: number) {
        for (var i = 0; i <= 7; i++) {
            var curr = this.addDays(-i - 1);
            if (curr.getDay() === day) {
                return curr.toLocaleDateString();
            }
        }
    }
}
