<div class="configuration-panel-container">
    <div class="doubletable-container">
        <div class="doubletable-item">
            <app-design-status-mapping *ngIf="shouldDisplayDesignStatusMapping" [changeType]="changeType"></app-design-status-mapping>
        </div>
        <div class="doubletable-item">
            <app-contractor-mapping [changeType]="changeType" *ngIf="shouldDisplayContractorMapping"></app-contractor-mapping>
        </div>
    </div>
    <div class="doubletable-container">
        <div class="doubletable-item">
            <app-implementation-status-mapping *ngIf="shouldDisplayImplementationStatusMapping" [changeType]="changeType"></app-implementation-status-mapping>
        </div>
    </div>
</div>