<div class="popup">
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">delete sign-off field implementation</span>
    </p>
    <div>
        <form [formGroup]="signOffForm">
            <span>Please choose subsystems to delete:</span>
            <mat-form-field class="container300">
                <mat-label>Subsystems</mat-label>
                <mat-select formControlName="subsystems" multiple>
                    <mat-option
                        (onSelectionChange)="selectionChange($event)"
                        *ngFor="let ss of subsystemsArr"
                        [value]="ss"
                        >{{ ss }}</mat-option
                    >
                </mat-select>
            </mat-form-field>

            <div class="row">
                <button mat-button type="button" class="sct-button sct-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button mat-button type="button" class="sct-button sct-button-light" (click)="onConfirm()">
                    delete sign-off
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
