import { Pipe, PipeTransform } from '@angular/core';
import { BlueZoneDTO } from '../store/bluezone/model';
import * as moment from 'moment';

@Pipe({
    name: 'walkdownCommitmentDisabled',
})
export class WalkdownCommitmentDisabledPipe implements PipeTransform {
    transform(value: BlueZoneDTO) {
        return moment(value.walkdownForecast).utc().isBefore(moment().utc().add(72, 'hours')) || value.walkdownSch;
    }
}
