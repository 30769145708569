import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormService } from 'src/app/services/shared/form.service';
import { DcnRfiRedlineRegisterFilterPropertyUpdate, DcnRfiRedlineRegisterFilterReset, DcnRfiRegisterDataRequest, RedlineRegisterDataRequest } from 'src/app/store/dcn-rfi-redline/actions';
import { DcnRfiRedlineRegisterFilter, RegisterType } from 'src/app/store/dcn-rfi-redline/model';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-register-filter',
  templateUrl: './register-filter.component.html',
  styleUrls: ['./register-filter.component.scss']
})
export class RegisterFilterComponent extends BaseComponent implements OnInit {
  @Input() filterForm: FormGroup;
  @Input() registerType: RegisterType;
  registerTypes = RegisterType;

  constructor(
    private store: Store<ApplicationState>,
    
  ) {
    super();

  }

  get isDcnRfiFilterApplied() {
    return (
      this.filterForm.controls.dcnRfiContent?.value.length > 0 ||
      this.filterForm.controls.dcnRfiDisciplines?.value.length > 0 ||
      this.filterForm.controls.dcnRfiEngineeringStatus?.value.length > 0 ||
      this.filterForm.controls.dcnRfiImpactedSubsystems?.value.length > 0 ||
      this.filterForm.controls.dcnRfiTitles?.value.length > 0 ||
      this.filterForm.controls.implementationStatuses?.value.length > 0
    );
  }

  get isRedlineFilterApplied() {
    return (
        this.filterForm.controls.redlineDisciplines?.value.length > 0 ||
        this.filterForm.controls.redlineSubsystems?.value.length > 0 ||
        this.filterForm.controls.redlineStatuses?.value.length > 0 ||
        this.filterForm.controls.redlineTitles?.value.length > 0 ||
        this.filterForm.controls.rlmuStatuses?.value.length > 0 ||
        this.filterForm.controls.redlineRequiredFors?.value.length > 0
    );
  }

  ngOnInit(): void {
    // This is intentional
  }

  setTabIndex($event) {
    this.registerType = $event.index === 0 ? RegisterType.Dcnrfi : RegisterType.Redline;
    this.filterForm.controls.sortBy.setValue(this.registerType === RegisterType.Dcnrfi ? 'number' : 'redlineNumber');
    this.RegisterRequest();
  }

  search(registerType: RegisterType) {
    this.registerType = registerType;
    this.RegisterRequest();
  }

  clearFilters(event: any) {
    event.stopPropagation();
    this.store.dispatch(new DcnRfiRedlineRegisterFilterReset(this.registerType));
    if (this.registerType === RegisterType.Dcnrfi) {
      this.filterForm.controls.sortBy.setValue('number');
      this.filterForm.controls.dcnRfiNumber.setValue([]);
      this.filterForm.controls.dcnRfiContent.setValue([]);
      this.filterForm.controls.dcnRfiDiscipline.setValue([]);
      this.filterForm.controls.dcnRfiEngineeringStatus.setValue([]);
      this.filterForm.controls.dcnRfiImpactedSubsystem.setValue([]);
      this.filterForm.controls.dcnRfiTitle.setValue([]);
      this.filterForm.controls.dcnRfiImplementationStatus.setValue([]);
      this.filterForm.controls.dcnRfiWorkpackStatus.setValue([]);
      this.filterForm.controls.dcnRfiWorkpackNumber.setValue([]);
      this.filterForm.controls.redlineNumber.setValue([]);
    } else {
      this.filterForm.controls.sortBy.setValue('redlineNumber');
      this.filterForm.controls.redlineNumber.setValue([]);
      this.filterForm.controls.redlineDiscipline.setValue([]);
      this.filterForm.controls.redlineSubsystem.setValue([]);
      this.filterForm.controls.redlineStatus.setValue([]);
      this.filterForm.controls.redlineTitle.setValue([]);
      this.filterForm.controls.rlmuStatus.setValue([]);
      this.filterForm.controls.dcnRfiNumber.setValue([]);
      this.filterForm.controls.redlineReadyForSubmission.setValue(null);
      this.filterForm.controls.redlineRequiredFor.setValue([]);
      this.filterForm.controls.redlineNbRespEngineer.setValue([]);
    }

  }

  private RegisterRequest() {
    this.filterForm.controls.registerType.setValue(this.registerType);
    this.store.dispatch(new DcnRfiRedlineRegisterFilterPropertyUpdate(this.filterForm.value as DcnRfiRedlineRegisterFilter));
    if (this.registerType === RegisterType.Dcnrfi) {
      this.store.dispatch(new DcnRfiRegisterDataRequest());
    }
    else {
      this.store.dispatch(new RedlineRegisterDataRequest());
    }
  }
}
