import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { AutomaticUploadsService } from 'src/app/services/api/webapi-services/automatic-uploads.service';

export class AutomaticUploadTrigger {
    id: number;
    time: string;
    isInEditMode: boolean;
    prevTime: string;
}

@Component({
    selector: 'app-time-triggers',
    templateUrl: './time-triggers.component.html',
    styleUrls: ['./time-triggers.component.scss'],
})
export class TimeTriggersConfigurationComponent implements OnInit {
    isLoading = false;
    triggers: AutomaticUploadTrigger[] = [];
    displayedColumns = ['time', 'actions'];

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private configurationService: ConfigurationService,
        private automaticUploadsService: AutomaticUploadsService,
        private toastService: ToastService
    ) {
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit() {
        this.automaticUploadsService
            .getAutomaticUploadTriggers()
            .pipe(take(1))
            .subscribe((data) => {
                this.triggers = [
                    ...data.map((d) => ({ id: d.id, time: d.time, isInEditMode: false, prevTime: '' })),
                ];
            });
    }

    add() {
        this.triggers = [...this.triggers, { id: 0, time: '', isInEditMode: true, prevTime: '' }];
    }

    remove(element: AutomaticUploadTrigger) {
        this.isLoading = true;
        this.automaticUploadsService
            .deleteAutomaticUploadTrigger(element.id)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while deleting Automatic Upload Trigger. Please contact Program Administrator.'
                    );
                    return of(error);
                })
            )
            .subscribe((id) => {
                this.triggers = [..._.filter(this.triggers, (c) => c.id !== id)];
                this.toastService.Success('Automatic Upload Trigger has been removed.');
            });
    }

    enterEditMode(element: AutomaticUploadTrigger) {
        element.isInEditMode = true;
        element.prevTime = element.time;
    }

    cancelEdit(element: AutomaticUploadTrigger) {
        element.isInEditMode = false;
        element.time = element.prevTime;
        this.triggers = [..._.filter(this.triggers, (c) => c.id > 0)];
    }

    update(element: AutomaticUploadTrigger) {
        if (element.time === '') {
            this.toastService.Error(
                `Adding empty or invalid Automatic Upload Trigger is not allowed.`
            );
            return;
        }
        this.isLoading = true;
        this.automaticUploadsService
            .updateAutomaticUploadTrigger(element)
            .pipe(
                take(1),
                finalize(() => {
                    this.isLoading = false;
                    element.isInEditMode = false;
                }),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while Automatic Upload Trigger. Please contact Program Administrator.`
                    );
                    return of(error);
                })
            )
            .subscribe((data) => {
                this.toastService.Success(
                    `Automatic Upload Trigger has been successfully ${data.id !== element.id ? 'created.' : 'updated.'}`
                );
                element.id = data.id;
            });
    }
}
