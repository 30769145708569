<main class="main">
    <div class="main-container">
        <app-waypoint-maintenance
            (waypointsChanged)="onWaypointsChanged($event)"
            (waypointRemoved)="onWaypointRemoved($event)"
        ></app-waypoint-maintenance>
        <app-milestone-maintenance
            [waypoints]="waypoints"
            [waypointRemoved]="waypointRemoved"
        ></app-milestone-maintenance>
    </div>
</main>
