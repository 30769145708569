import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { CWPRegisterDeltaPagination, CWPRegisterDeletePagination } from './model';
import { Moment } from 'moment';

export enum CWPRegisterActionTypes {
    CWPRegisterUploadLogRequest = '[CWP Register] Upload Log Request',
    CWPRegisterUploadLogSuccess = '[CWP Register] Upload Log Success',
    CWPRegisterUploadLogError = '[CWP Register] Upload Log Error',
    CWPRegisterDeltaRequest = '[CWP Register] Delta Request',
    CWPRegisterDeltaSuccess = '[CWP Register] Delta Success',
    CWPRegisterDeltaError = '[CWP Register] Delta Error',
    CWPRegisterSetStatusAndStartDate = '[CWP Register] Set Status and Start Date',
    CWPRegisterDeltaFilterPropertyUpdate = '[CWP Register] Delta Filter Property Update',
    CWPRegisterDeleteFilterPropertyUpdate = '[CWP Register] Delete Filter Property Update',
    CWPRegisterPatchDeltaRequest = '[CWP Register] Patch Delta Request',
    CWPRegisterPatchDeltaSuccess = '[CWP Register] Patch Delta Success',
    CWPRegisterPatchDeltaError = '[CWP Register] Patch Delta Error',
    CWPRegisterPatchAllDeltaRequest = '[CWP Register] Patch All Delta Request',
    CWPRegisterPatchAllDeltaSuccess = '[CWP Register] Patch All Delta Success',
    CWPRegisterPatchAllDeltaError = '[CWP Register] Patch All Delta Error',
    CWPRegisterValidateDeltaRequest = '[CWP Register] Validate Delta Request',
    CWPRegisterValidateDeltaSuccess = '[CWP Register] Validate Delta Success',
    CWPRegisterValidateDeltaError = '[CWP Register] Validate Delta Error',
    CWPRegisterFileUploaded = '[CWP Register] File Uploaded',
    CWPRegisterClearInProgressSpinner = '[CWP Register] Clear In Progress Spinner',
    CWPRegisterTemplateFileRequest = '[CWP Register] Template File Request',
    CWPRegisterTemplateFileSuccess = '[CWP Register] Template File Success',
    CWPRegisterTemplateFileError = '[CWP Register] Template File Error',
    CWPRegisterDeleteDataRequest = '[CWP Register] Delete Data Request',
    CWPRegisterDeleteDataSuccess = '[CWP Register] Delete Data Success',
    CWPRegisterDeleteDataError = '[CWP Register] Delete Data Error',
    CWPRegisterPatchDeleteRecordRequest = '[CWP Register] Patch Delete Record Request',
    CWPRegisterPatchDeleteRecordSuccess = '[CWP Register] Patch Delete Record Success',
    CWPRegisterPatchDeleteRecordError = '[CWP Register] Patch Delete Record Error',
    CWPRegisterPatchDeleteAllRecordsRequest = '[CWP Register] Patch Delete All Records Request',
    CWPRegisterPatchDeleteAllRecordsSuccess = '[CWP Register] Patch Delete All Records Success',
    CWPRegisterPatchDeleteAllRecordsError = '[CWP Register] Patch Delete All Records Error',
    CWPRegisterValidateButtonStateRequest = '[CWP Register] Validate Button State Request',
    CWPRegisterValidateButtonStateSuccess = '[CWP Register] Validate Button State Success',
    CWPRegisterValidateButtonStateError = '[CWP Register] Validate Button State Error',
    CWPRegisterDownloadOutputDataRequest = '[CWP Register] Download Output Data Request',
    CWPRegisterDownloadOutputDataSuccess = '[CWP Register] Download Output Data Success',
    CWPRegisterDownloadOutputDataError = '[CWP Register] Download Output Data Error',
    CWPRegisterAddStartedLog = '[CWPRegister] Add Started Log',
}

export class CWPRegisterAddStartedLog implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterAddStartedLog;
}

export class CWPRegisterUploadLogRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterUploadLogRequest;
}

export class CWPRegisterUploadLogSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class CWPRegisterUploadLogError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterUploadLogError;

    constructor(public payload: string) {}
}

export class CWPRegisterDeltaRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDeltaRequest;
}

export class CWPRegisterDeltaSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDeltaSuccess;

    constructor(public payload: CWPRegisterDeltaPagination) {}
}

export class CWPRegisterDeltaError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDeltaError;

    constructor(public payload: string) {}
}

export class CWPRegisterSetStatusAndStartDate implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class CWPRegisterDeltaFilterPropertyUpdate implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDeltaFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class CWPRegisterDeleteFilterPropertyUpdate implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class CWPRegisterPatchDeltaRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchDeltaRequest;

    constructor(public payload: { cwp: string; deltaState: boolean }) {}
}

export class CWPRegisterPatchAllDeltaRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchAllDeltaRequest;

    constructor(public payload: { deltaState: boolean }) {}
}

export class CWPRegisterPatchDeltaSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchDeltaSuccess;
}

export class CWPRegisterPatchAllDeltaSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchAllDeltaSuccess;

    constructor(public payload: { deltaState: boolean }) {}
}

export class CWPRegisterPatchDeltaError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchDeltaError;

    constructor(public payload: string) {}
}

export class CWPRegisterPatchAllDeltaError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchAllDeltaError;

    constructor(public payload: string) {}
}

export class CWPRegisterValidateDeltaRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterValidateDeltaRequest;
}

export class CWPRegisterValidateDeltaSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterValidateDeltaSuccess;
}

export class CWPRegisterValidateDeltaError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterValidateDeltaError;

    constructor(public payload: string) {}
}

export class CWPRegisterFileUploaded implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterFileUploaded;
}

export class CWPRegisterClearInProgressSpinner implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterClearInProgressSpinner;
}

export class CWPRegisterTemplateFileRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterTemplateFileRequest;

    constructor(public payload: string) {}
}

export class CWPRegisterTemplateFileSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class CWPRegisterTemplateFileError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterTemplateFileError;

    constructor(public payload: string) {}
}

export class CWPRegisterDeleteDataRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDeleteDataRequest;
}

export class CWPRegisterDeleteDataSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDeleteDataSuccess;

    constructor(public payload: CWPRegisterDeletePagination) {}
}

export class CWPRegisterDeleteDataError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDeleteDataError;

    constructor(public payload: string) {}
}

export class CWPRegisterPatchDeleteRecordRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchDeleteRecordRequest;

    constructor(public payload: { cwp: string; deleteState: boolean }) {}
}

export class CWPRegisterPatchDeleteRecordSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchDeleteRecordSuccess;
}

export class CWPRegisterPatchDeleteRecordError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class CWPRegisterPatchDeleteAllRecordsRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchDeleteAllRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class CWPRegisterPatchDeleteAllRecordsSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchDeleteAllRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class CWPRegisterPatchDeleteAllRecordsError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterPatchDeleteAllRecordsError;

    constructor(public payload: string) {}
}

export class CWPRegisterValidateButtonStateRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterValidateButtonStateRequest;
}

export class CWPRegisterValidateButtonStateSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class CWPRegisterValidateButtonStateError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterValidateButtonStateError;

    constructor(public payload: string) {}
}

export class CWPRegisterDownloadOutputDataRequest implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class CWPRegisterDownloadOutputDataSuccess implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class CWPRegisterDownloadOutputDataError implements Action {
    readonly type = CWPRegisterActionTypes.CWPRegisterDownloadOutputDataError;

    constructor(public payload: string) {}
}

export type CWPRegisterActions =
    | CWPRegisterUploadLogRequest
    | CWPRegisterUploadLogSuccess
    | CWPRegisterUploadLogError
    | CWPRegisterDeltaRequest
    | CWPRegisterDeltaSuccess
    | CWPRegisterDeltaError
    | CWPRegisterSetStatusAndStartDate
    | CWPRegisterDeltaFilterPropertyUpdate
    | CWPRegisterDeleteFilterPropertyUpdate
    | CWPRegisterPatchDeltaRequest
    | CWPRegisterPatchDeltaSuccess
    | CWPRegisterPatchDeltaError
    | CWPRegisterPatchAllDeltaRequest
    | CWPRegisterPatchAllDeltaSuccess
    | CWPRegisterPatchAllDeltaError
    | CWPRegisterValidateDeltaRequest
    | CWPRegisterValidateDeltaSuccess
    | CWPRegisterValidateDeltaError
    | CWPRegisterFileUploaded
    | CWPRegisterClearInProgressSpinner
    | CWPRegisterTemplateFileRequest
    | CWPRegisterTemplateFileSuccess
    | CWPRegisterTemplateFileError
    | CWPRegisterDeleteDataRequest
    | CWPRegisterDeleteDataSuccess
    | CWPRegisterDeleteDataError
    | CWPRegisterPatchDeleteRecordRequest
    | CWPRegisterPatchDeleteRecordSuccess
    | CWPRegisterPatchDeleteRecordError
    | CWPRegisterPatchDeleteAllRecordsRequest
    | CWPRegisterPatchDeleteAllRecordsSuccess
    | CWPRegisterPatchDeleteAllRecordsError
    | CWPRegisterValidateButtonStateRequest
    | CWPRegisterValidateButtonStateSuccess
    | CWPRegisterValidateButtonStateError
    | CWPRegisterDownloadOutputDataRequest
    | CWPRegisterDownloadOutputDataSuccess
    | CWPRegisterDownloadOutputDataError
    | CWPRegisterAddStartedLog;
