<main class="main">
    <form [formGroup]="filterForm" class="main-content">
        <div class="header-container">
            <div class="header-title">
                DCN/RFI/RLMU NOVUS Register
            </div>
            <div class="header-buttons">
                <button
                    type="button"
                    (click)="exportToExcel()"
                    class="sct-button sct-button-light"
                    [style.width]="'100px'"
                >
                    export
                </button>
            </div>
        </div>
        <app-register-filter
            [filterForm]="filterForm"
            [registerType]="registerType"
        >
        </app-register-filter>
        <app-dcn-rfi-redline-table #table
            [dcnRfiRegister]="dcnRfiRegister"
            [redlineRegister]="redlineRegister"
            [registerType]="registerType"
            [filterForm]="filterForm"
            [paginator]="paginator"
        >
        </app-dcn-rfi-redline-table>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="registerType === registerTypes.Dcnrfi ? dcnRfiRegister.totalCount : redlineRegister.totalCount"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input
                type="number"
                [value]="this.paginator.pageIndex + 1"
                (change)="onPageChange($event.target.value)"
            />
        </div>
    </form>
</main>
