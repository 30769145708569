import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { ContractorMappingDTO } from 'src/app/models/contractor-mapping-dto';
import { ContractorsMappingService } from 'src/app/services/api/webapi-services/contractor-mapping.service';
import { Contractor } from 'src/app/store/common.model';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-add-contractor-mapping-popup',
    templateUrl: './add-contractor-mapping-popup.component.html',
    styleUrls: ['./add-contractor-mapping-popup.component.scss'],
})
export class AddContractorMappingPopupComponent extends BaseComponent {
    loading: boolean;
    addContractorMappingForm: UntypedFormGroup;
    contractors: Contractor[] = [];
    changeTypes: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<AddContractorMappingPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: ToastService,
        private contractorMappingService: ContractorsMappingService
    ) {
        super();
        this.contractors = data.contractors;
        this.changeTypes = data.changeTypes;

        this.addContractorMappingForm = new UntypedFormGroup({
            changeType: new UntypedFormControl('', Validators.required),
            mapping: new UntypedFormControl('', Validators.required),
            contractNo: new UntypedFormControl('', Validators.required),
        });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        const form = new ContractorMappingDTO();

        form.contractNo = this.addContractorMappingForm.controls.contractNo.value;
        form.changeType = this.addContractorMappingForm.controls.changeType.value;
        form.mapping = this.addContractorMappingForm.controls.mapping.value;

        this.contractorMappingService
            .add(form)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
                (data) => {
                    this.loading = false;
                    this.toastService.Success(
                        `The contractor mapping for ${form.changeType} ${form.mapping} has been added.`
                    );
                    data.projectTeamName = this.contractors.find(
                        (s) => s.contractNo === form.contractNo
                    ).projectTeamName;
                    this.dialogRef.close({ success: true, data: data });
                },
                (error) => {
                    this.loading = false;
                    if (error.status === 409) {
                        this.toastService.Error('Contract mapping entered already exists in SCMT.');
                    } else {
                        this.toastService.Error(
                            'An error occurred while adding a contractor mapping. Please contact a Program Administrator.'
                        );
                    }
                }
            );
    }
}
