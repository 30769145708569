import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RedZoneEffects } from './effects';
import { redZoneReducer, redZoneStoreFeatureKey } from './reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(redZoneStoreFeatureKey, redZoneReducer),
        EffectsModule.forFeature([RedZoneEffects]),
    ],
})
export class RedZoneStoreModule {}
