import { FieldImplementationContractorHistoryActions, FieldImplementationContractorHistoryActionTypes } from "./actions";
import { FieldImplementationContractorHistoryState } from "./model";


const initialState: FieldImplementationContractorHistoryState = {
    data: [],
    isLoading: false,
};

export function reducer(state: FieldImplementationContractorHistoryState = initialState, action: FieldImplementationContractorHistoryActions): FieldImplementationContractorHistoryState {
    switch (action.type) {
        case FieldImplementationContractorHistoryActionTypes.ChangeManagementFieldImplementationContractorHistoryRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case FieldImplementationContractorHistoryActionTypes.ChangeManagementFieldImplementationContractorHistorySuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        }
        case FieldImplementationContractorHistoryActionTypes.ChangeManagementFieldImplementationContractorHistoryError: {
            return {
                ...state,
                data: [],
                isLoading: false
            };
        }
        default: {
            return state;
        }

    }
}