import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum CertificateActionTypes {
    CertificateUploadLogRequest = '[Certificate] Upload Log Request',
    CertificateUploadLogSuccess = '[Certificate] Upload Log Success',
    CertificateUploadLogError = '[Certificate] Upload Log Error',
    CertificateSetStatusAndStartDate = '[Certificate] Set Status and Start Date',
    CertificateFileUploaded = '[Certificate] File Uploaded',
    CertificateClearInProgressSpinner = '[Certificate] Clear In Progress Spinner',
    CertificateTemplateFileRequest = '[Certificate] Template File Request',
    CertificateTemplateFileSuccess = '[Certificate] Template File Success',
    CertificateTemplateFileError = '[Certificate] Template File Error',
    CertificateValidateDeltaRequest = '[Certificate] Validate Delta Request',
    CertificateValidateDeltaSuccess = '[Certificate] Validate Delta Success',
    CertificateValidateDeltaError = '[Certificate] Validate Delta Error',
    CertificateDataInfoRequest = '[Certificate] Data Info Request',
    CertificateDataInfoSuccess = '[Certificate] Data Info Success',
    CertificateDataInfoError = '[Certificate] Data Info Error',
    CertificateDownloadOutputDataRequest = '[Certificate] Output data Request',
    CertificateDownloadOutputDataSuccess = '[Certificate] Output data Success',
    CertificateDownloadOutputDataError = '[Certificate] Output data File Error',
    CertificateAddStartedLog = '[Certificate] Add Started Log',
}

export class CertificateAddStartedLog implements Action {
    readonly type = CertificateActionTypes.CertificateAddStartedLog;
}

export class CertificateUploadLogRequest implements Action {
    readonly type = CertificateActionTypes.CertificateUploadLogRequest;
}

export class CertificateUploadLogSuccess implements Action {
    readonly type = CertificateActionTypes.CertificateUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class CertificateUploadLogError implements Action {
    readonly type = CertificateActionTypes.CertificateUploadLogError;

    constructor(public payload: string) {}
}

export class CertificateFileUploaded implements Action {
    readonly type = CertificateActionTypes.CertificateFileUploaded;
}

export class CertificateClearInProgressSpinner implements Action {
    readonly type = CertificateActionTypes.CertificateClearInProgressSpinner;
}

export class CertificateTemplateFileRequest implements Action {
    readonly type = CertificateActionTypes.CertificateTemplateFileRequest;

    constructor(public payload: string) {}
}

export class CertificateTemplateFileSuccess implements Action {
    readonly type = CertificateActionTypes.CertificateTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class CertificateTemplateFileError implements Action {
    readonly type = CertificateActionTypes.CertificateTemplateFileError;

    constructor(public payload: string) {}
}

export class CertificateDownloadOutputDataRequest implements Action {
    readonly type = CertificateActionTypes.CertificateDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class CertificateDownloadOutputDataSuccess implements Action {
    readonly type = CertificateActionTypes.CertificateDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class CertificateDownloadOutputDataError implements Action {
    readonly type = CertificateActionTypes.CertificateDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class CertificateSetStatusAndStartDate implements Action {
    readonly type = CertificateActionTypes.CertificateSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class CertificateValidateDeltaRequest implements Action {
    readonly type = CertificateActionTypes.CertificateValidateDeltaRequest;
}

export class CertificateValidateDeltaSuccess implements Action {
    readonly type = CertificateActionTypes.CertificateValidateDeltaSuccess;
}

export class CertificateValidateDeltaError implements Action {
    readonly type = CertificateActionTypes.CertificateValidateDeltaError;

    constructor(public payload: string) {}
}

export class CertificateDataInfoSuccess implements Action {
    readonly type = CertificateActionTypes.CertificateDataInfoSuccess;

    constructor(public payload: {
        readyToProcess: boolean[];
        inProgress: boolean[];
        recordsForDeletion: number[];
        isAutomaticUploadInProgress: boolean;
    }) {}
}

export class CertificateDataInfoError implements Action {
    readonly type = CertificateActionTypes.CertificateDataInfoError;

    constructor(public payload: string) {}
}

export class CertificateDataInfoRequest implements Action {
    readonly type = CertificateActionTypes.CertificateDataInfoRequest;
}

export type CertificateActions =
    | CertificateUploadLogRequest
    | CertificateUploadLogSuccess
    | CertificateUploadLogError
    | CertificateFileUploaded
    | CertificateClearInProgressSpinner
    | CertificateTemplateFileRequest
    | CertificateTemplateFileSuccess
    | CertificateTemplateFileError
    | CertificateSetStatusAndStartDate
    | CertificateValidateDeltaRequest
    | CertificateValidateDeltaSuccess
    | CertificateValidateDeltaError
    | CertificateDataInfoRequest
    | CertificateDataInfoSuccess
    | CertificateDataInfoError
    | CertificateDownloadOutputDataRequest
    | CertificateDownloadOutputDataSuccess
    | CertificateDownloadOutputDataError
    | CertificateAddStartedLog;
