import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { CTARegisterDataPagination, CTARegisterDTO, CTARegisterFilter } from 'src/app/store/cta-register/model';

@Injectable({
    providedIn: 'root',
})
export class CTAService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly ctaEndpoint: string = '/cta';
    constructor(private http: HttpClient) {}

    addCTA(cta: CTARegisterDTO): Observable<any> {
        return this.http.post(`${this.rootUrl}${this.ctaEndpoint}`, cta);
    }

    updateCTA(cta: CTARegisterDTO): Observable<any> {
        return this.http.put(`${this.rootUrl}${this.ctaEndpoint}`, cta);
    }

    getCTAById(id: number) {
        return this.http.get<CTARegisterDTO>(`${this.rootUrl}${this.ctaEndpoint}/${id}`);
    }

    getCTARegister(filter: CTARegisterFilter) {
        return this.http.post<CTARegisterDataPagination>(`${this.rootUrl}${this.ctaEndpoint}/register`, filter);
    }

    deleteCTA(cta: CTARegisterDTO) {
        return this.http.delete(`${this.rootUrl}${this.ctaEndpoint}/${cta.id}`, {});
    }

    uploadCTAAttachments(formData: FormData) {
        return this.http.post(`${this.rootUrl}${this.ctaEndpoint}/attachments`, formData);
    }

    deleteCTAAttachment(links: string[], ctaId: number = null) {
        let params = new HttpParams();
        links.forEach((link) => {
            params = params.append('links', link);
        });
        params = params.append('ctaId', ctaId ? ctaId.toString() : '');

        return this.http.delete(`${this.rootUrl}${this.ctaEndpoint}/attachments`, { params });
    }

    downloadCTAAttachment(link: string) {
        return this.http.get<Blob>(`${this.rootUrl}${this.ctaEndpoint}/attachments?link=${encodeURIComponent(link)}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }
}
