import { SubsystemRFSUDashboardActions, SubsystemRFSUDashboardActionTypes } from "./actions";
import {SubsystemRFSUDashboardFilter, SubsystemRFSUDashboardState} from "./model";

const initialState: SubsystemRFSUDashboardState = {
    filter: new SubsystemRFSUDashboardFilter(),
    items: null,
    isLoading: false,
    totalCount: 0,
};

export function reducer(state = initialState, action: SubsystemRFSUDashboardActions): SubsystemRFSUDashboardState {
    switch (action.type) {
        case SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardRequest:
            return {
                ...state,
                isLoading: true,
            };
        case SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardSuccess:
            return {
                ...state,
                isLoading: false,
                items: action.payload.items,
                totalCount: action.payload.totalCount,
            };
        case SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardError:
            return {
                ...state,
                isLoading: false,
            };
        case SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardUpdateFilterProperties:
            return {
                ...state,
                filter: { ...state.filter, ...action.payload },
            };
        case SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SubsystemRFSUDashboardActionTypes.SubsystemRFSUDashboardExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}
