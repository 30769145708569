import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ApplicationState } from '../../model';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ScheduleActivityPlanningService } from '../../../services/api/webapi-services/schedule-activity-planning.service';
import { EMPTY, empty, of } from 'rxjs';
import {
    ScheduleActivityFillFilterPropertiesBasedonAreaError,
    ScheduleActivityFillFilterPropertiesBasedonAreaSuccess,
    ScheduleActivityFillFilterPropertiesBasedonAreaRequest,
    ScheduleActivityPlanningActionTypes,
    ScheduleActivityPlanningDataError,
    ScheduleActivityPlanningDataRequest,
    ScheduleActivityPlanningDataSuccess,
    ScheduleActivityPropertyUpdate,
    ScheduleActivityPropertyUpdateSuccess,
    ScheduleActivityPropertyUpdateError,
    ScheduleActivityUpdate,
    ScheduleActivityUpdateSuccess,
    ScheduleActivityUpdateError,
    ScheduleActivityExportToExcelRequest,
    ScheduleActivityExportToExcelSuccess,
    ScheduleActivityExportToExcelError,
    ScheduleActivityITRsSubmit,
    ScheduleActivityITRsSubmitSuccess,
    ScheduleActivityITRsSubmitError,
    CreateNewActivityRequest,
    CreateNewActivitySuccess,
    CreateNewActivityError,
    ScheduleActivityNotInWeekPlanningDataRequest,
    ScheduleActivityNotInWeekPlanningDataSuccess,
    ScheduleActivityNotInWeekPlanningDataError,
    ScheduleActivityNotInWeekPropertyUpdateSuccess,
    ScheduleActivityNotInWeekPropertyUpdateError,
    ScheduleActivityNotInWeekUpdate,
    ScheduleActivityNotInWeekUpdateSuccess,
    ScheduleActivityNotInWeekUpdateError,
    ScheduleActivityNotInWeekPropertyUpdate,
    ScheduleActivitySetSubsytemsUpdate,
    ScheduleActivitySetSubsytemsUpdateSuccess,
    ScheduleActivitySetSubsytemsUpdateError,
    ScheduleActivityWeekSummaryDataRequest,
    ScheduleActivityWeekSummaryDataError,
    ScheduleActivityWeekSummaryDataSuccess,
    ScheduleActivityAddCommentRequest,
    ScheduleActivityAddCommentSuccess,
    ScheduleActivityAddCommentError,
    ScheduleActivityRemoveCommentRequest,
    ScheduleActivityRemoveCommentSuccess,
    ScheduleActivityRemoveCommentError,
} from './actions';
import {
    ScheduleActivityPlanningDTO,
    ScheduleActivityPlanningFilter,
    SubsystemWithScheduleActivitiesDTO,
    WeekSummaryFilter,
} from './model';
import { BaseResultWithSubmissionState, WorkAreaDTO } from '../../common.model';
import { ToastService } from '../../../services/shared/toast.service';
import { WorkAreaService } from 'src/app/services/api/webapi-services/work-area.service';
import { ScheduleActivityITRActionTypes } from '../../schedule-activity-itr/actions';
import * as moment from 'moment';
import { WeeklyPlanActivityType } from 'src/app/enums';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { WeekSummaryDto } from '../model';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';

@Injectable()
export class ScheduleActivityPlanningEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private scheduleActivityPlanningService: ScheduleActivityPlanningService,
        private toastService: ToastService,
        private workAreaService: WorkAreaService,
        private scheduleActivityLookupService: ScheduleActivityLookupService,
        private commentService: CommentService
    ) {}

    @Effect({ dispatch: false })
    onScheduleActivityDataError = this.actions$.pipe(
        ofType<ScheduleActivityPlanningDataError>(ScheduleActivityPlanningActionTypes.PlanningDataRequestError),
        map(() => {
            this.toastService.Error('Failed to fetch Schedule Activity data. Please contact Program Administrator.');
        })
    );

    @Effect()
    getScheduleActivityData$ = this.actions$.pipe(
        ofType<ScheduleActivityPlanningDataRequest>(ScheduleActivityPlanningActionTypes.PlanningDataRequest),
        withLatestFrom(
            this.store.select((store) => store.scheduleActivityPlanningState?.scheduleActivityPlanningFilter)
        ),
        switchMap(([action, filter]) => {
            return this.scheduleActivityPlanningService
                .getScheduleActivities({ ...filter, activityType: WeeklyPlanActivityType.inWeek }, action.payload)
                .pipe(
                    map((response: BaseResultWithSubmissionState<SubsystemWithScheduleActivitiesDTO>) => {
                        return new ScheduleActivityPlanningDataSuccess({
                            items: response.items,
                            totalCount: response.totalCount,
                            isAllSubmitted: response.isAllSubmitted,
                            printMode: action.payload,
                        });
                    }),
                    catchError((error) => of(new ScheduleActivityPlanningDataError({ error })))
                );
        })
    );

    @Effect()
    getNotInWeekScheduleActivityData$ = this.actions$.pipe(
        ofType<ScheduleActivityPlanningDataRequest>(ScheduleActivityPlanningActionTypes.NotInWeekPlanningDataRequest),
        withLatestFrom(
            this.store.select((store) => store.scheduleActivityPlanningState?.scheduleActivityPlanningFilter),
            this.store.select(
                (store) => store.scheduleActivityPlanningState?.subsystemsNotInWeekWithScheduleActivitiesPagination
            )
        ),
        switchMap(([action, filter, pagination]) => {
            return this.scheduleActivityPlanningService
                .getScheduleActivities(
                    {
                        ...filter,
                        activityType: WeeklyPlanActivityType.notInWeek,
                        take: pagination.take,
                        page: pagination.page,
                    },
                    action.payload
                )
                .pipe(
                    map((response: BaseResultWithSubmissionState<SubsystemWithScheduleActivitiesDTO>) => {
                        return new ScheduleActivityNotInWeekPlanningDataSuccess({
                            items: response.items,
                            totalCount: response.totalCount,
                            isAllSubmitted: response.isAllSubmitted,
                            printMode: action.payload,
                        });
                    }),
                    catchError((error) => of(new ScheduleActivityNotInWeekPlanningDataError({ error })))
                );
        })
    );

    @Effect()
    refreshScheduleActivityData$ = this.actions$.pipe(
        ofType(
            ScheduleActivityITRActionTypes.SaveSelectedITRIdsSuccess,
            ScheduleActivityPlanningActionTypes.ITRsSubmitSuccess
        ),
        mergeMap(() => {
            return [new ScheduleActivityPlanningDataRequest(), new ScheduleActivityNotInWeekPlanningDataRequest()];
        })
    );

    @Effect()
    fillFilterPropertiesBasedonArea$ = this.actions$.pipe(
        ofType<ScheduleActivityFillFilterPropertiesBasedonAreaRequest>(
            ScheduleActivityPlanningActionTypes.FillFilterPropertiesBasedonAreaRequest
        ),
        switchMap((action) => {
            return this.workAreaService.getWorkAreaByName(action.payload.areaName).pipe(
                map(
                    (response: WorkAreaDTO) =>
                        new ScheduleActivityFillFilterPropertiesBasedonAreaSuccess({ area: response })
                ),
                catchError((error) => of(new ScheduleActivityFillFilterPropertiesBasedonAreaError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onScheduleActivityFillFilterPropertiesBasedonAreaError = this.actions$.pipe(
        ofType<ScheduleActivityFillFilterPropertiesBasedonAreaError>(
            ScheduleActivityPlanningActionTypes.FillFilterPropertiesBasedonAreaError
        ),
        map(() => {
            this.toastService.Error(
                'Failed to fill Schedule Activity filter properties. Please contact Program Administrator.'
            );
        })
    );

    @Effect()
    onScheduleActivityUpdate = this.actions$.pipe(
        ofType<ScheduleActivityUpdate>(ScheduleActivityPlanningActionTypes.ActivityUpdate),
        switchMap((action) => {
            return this.scheduleActivityPlanningService.updateScheduleActivity(action.payload).pipe(
                map((resp) => {
                    return new ScheduleActivityUpdateSuccess({ ...action.payload }, action.subsystem);
                }),
                catchError((error) => of(new ScheduleActivityUpdateError({ error }, action.subsystem)))
            );
        })
    );

    @Effect()
    onScheduleActivityUpdateSuccess = this.actions$.pipe(
        ofType<ScheduleActivityUpdateSuccess>(ScheduleActivityPlanningActionTypes.ActivityUpdateSuccess),
        map(() => {
            return new ScheduleActivityWeekSummaryDataRequest();
        })
    );

    @Effect({ dispatch: false })
    onScheduleActivityUpdateError = this.actions$.pipe(
        ofType(
            ScheduleActivityPlanningActionTypes.ActivityUpdateError,
            ScheduleActivityPlanningActionTypes.ActivityNotInWeekUpdateError
        ),
        map(() => {
            this.toastService.Error('Failed to update an Activity. Please contact Program Administrator.');
        })
    );

    @Effect()
    onScheduleActivityNotInWeekUpdate = this.actions$.pipe(
        ofType<ScheduleActivityNotInWeekUpdate>(ScheduleActivityPlanningActionTypes.ActivityNotInWeekUpdate),
        switchMap((action) => {
            return this.scheduleActivityPlanningService.updateScheduleActivity(action.payload).pipe(
                map((resp) => {
                    return new ScheduleActivityNotInWeekUpdateSuccess({ ...action.payload }, action.subsystem);
                }),
                catchError((error) => of(new ScheduleActivityNotInWeekUpdateError({ error }, action.subsystem)))
            );
        })
    );

    @Effect()
    onScheduleActivityNotInWeekUpdateSuccess = this.actions$.pipe(
        ofType<ScheduleActivityNotInWeekUpdateSuccess>(
            ScheduleActivityPlanningActionTypes.ActivityNotInWeekUpdateSuccess
        ),
        map(() => {
            return new ScheduleActivityWeekSummaryDataRequest();
        })
    );

    @Effect()
    onScheduleActivityPropertyUpdate = this.actions$.pipe(
        ofType<ScheduleActivityPropertyUpdate>(ScheduleActivityPlanningActionTypes.PropertyUpdate),
        switchMap((action) => {
            return this.scheduleActivityPlanningService
                .updateWeeklyProperty({
                    ...action.payload,
                    [action.payload.property]: action.payload.value,
                })
                .pipe(
                    map(() => {
                        return new ScheduleActivityPropertyUpdateSuccess({ ...action.payload }, action.subsystem);
                    }),
                    catchError((error) => of(new ScheduleActivityPropertyUpdateError({ error }, action.subsystem)))
                );
        })
    );

    @Effect()
    onScheduleActivityPropertyUpdateSuccess = this.actions$.pipe(
        ofType<ScheduleActivityPropertyUpdateSuccess>(ScheduleActivityPlanningActionTypes.PropertyUpdateSuccess),
        switchMap((action) =>
            action.payload.property === 'inWeeklyPlan' ? of(new ScheduleActivityWeekSummaryDataRequest()) : empty()
        )
    );

    @Effect()
    onScheduleActivityNotInWeekPropertyUpdate = this.actions$.pipe(
        ofType<ScheduleActivityNotInWeekPropertyUpdate>(ScheduleActivityPlanningActionTypes.NotInWeekPropertyUpdate),
        switchMap((action) => {
            return this.scheduleActivityPlanningService
                .updateWeeklyProperty({
                    ...action.payload,
                    [action.payload.property]: action.payload.value,
                })
                .pipe(
                    map(() => {
                        return new ScheduleActivityNotInWeekPropertyUpdateSuccess(
                            { ...action.payload },
                            action.subsystem
                        );
                    }),
                    catchError((error) =>
                        of(new ScheduleActivityNotInWeekPropertyUpdateError({ error }, action.subsystem))
                    )
                );
        })
    );

    @Effect()
    onScheduleActivityAddCommentRequest = this.actions$.pipe(
        ofType<ScheduleActivityAddCommentRequest>(ScheduleActivityPlanningActionTypes.AddCommentRequest),
        switchMap((action) => {
            return this.commentService.addCommentAcativity(action.payload).pipe(
                map((result) => {
                    return new ScheduleActivityAddCommentSuccess(result);
                }),
                catchError((error) => of(new ScheduleActivityAddCommentError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onScheduleActivityAddCommentError = this.actions$.pipe(
        ofType<ScheduleActivityAddCommentError>(ScheduleActivityPlanningActionTypes.AddCommentError),
        map(() => {
            this.toastService.Error('Error occurred while adding comment. Please contact Program Administrator.');
        })
    );

    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<ScheduleActivityRemoveCommentRequest>(ScheduleActivityPlanningActionTypes.RemoveCommentRequest),
        mergeMap(({ payload }) =>
            this.commentService.deleteCommentActivity(payload.id).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully removed.');
                    return new ScheduleActivityRemoveCommentSuccess({
                        id: payload.id,
                        subsystem: payload.subsystem,
                        activityId: payload.activityId,
                        activityDiscipline: payload.activityDiscipline,
                        lastComment: payload.lastComment,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while removing ACtivity comment. Please contact Program Administrator.'
                    );
                    return of(new ScheduleActivityRemoveCommentError(error));
                })
            )
        )
    );

    @Effect()
    onScheduleActivityNotInWeekPropertyUpdateSuccess = this.actions$.pipe(
        ofType<ScheduleActivityNotInWeekPropertyUpdateSuccess>(
            ScheduleActivityPlanningActionTypes.NotInWeekPropertyUpdateSuccess
        ),
        switchMap((action) =>
            action.payload.property === 'inWeeklyPlan' ? of(new ScheduleActivityWeekSummaryDataRequest()) : empty()
        )
    );

    @Effect({ dispatch: false })
    onScheduleActivityPropertyUpdateError = this.actions$.pipe(
        ofType<ScheduleActivityPropertyUpdateError>(ScheduleActivityPlanningActionTypes.PropertyUpdateError),
        map(() => {
            this.toastService.Error('Failed to update an Activity. Please contact Program Administrator.');
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType<ScheduleActivityExportToExcelRequest>(ScheduleActivityPlanningActionTypes.ExportToExcelRequest),
        withLatestFrom(
            this.store.select((store) => store.scheduleActivityPlanningState?.scheduleActivityPlanningFilter)
        ),
        mergeMap(([action, filter]) => {
            return this.scheduleActivityPlanningService.generateExcel(action.payload, filter).pipe(
                map((excelData) => new ScheduleActivityExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new ScheduleActivityExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(ScheduleActivityPlanningActionTypes.ExportToExcelSuccess),
        map((action: ScheduleActivityExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `WeeklyPlan_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect()
    onScheduleActivityITRsSubmit = this.actions$.pipe(
        ofType<ScheduleActivityITRsSubmit>(ScheduleActivityPlanningActionTypes.ITRsSubmit),
        withLatestFrom(
            this.store.select((store) => store.scheduleActivityPlanningState?.scheduleActivityPlanningFilter)
        ),
        switchMap(([, filter]: [any, ScheduleActivityPlanningFilter]) => {
            return this.scheduleActivityPlanningService.submitSelectedITRs(filter).pipe(
                map((response) => {
                    return new ScheduleActivityITRsSubmitSuccess({ response });
                }),
                catchError((error) => of(new ScheduleActivityITRsSubmitError({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    onScheduleActivityITRsSubmitSuccess = this.actions$.pipe(
        ofType<ScheduleActivityITRsSubmitSuccess>(ScheduleActivityPlanningActionTypes.ITRsSubmitSuccess),
        map(() => {
            this.toastService.Success('Selected ITRs successfully submitted.');
        })
    );

    @Effect({ dispatch: false })
    onScheduleActivityITRsSubmitError = this.actions$.pipe(
        ofType<ScheduleActivityITRsSubmitError>(ScheduleActivityPlanningActionTypes.ITRsSubmitError),
        map(() => {
            this.toastService.Error('Failed to submit selected ITRs. Please contact Program Administrator.');
        })
    );

    @Effect()
    onCreateNewActivity = this.actions$.pipe(
        ofType<CreateNewActivityRequest>(ScheduleActivityPlanningActionTypes.CreateNewActivityRequest),
        switchMap((action) => {
            return this.scheduleActivityPlanningService.createNewActivity(action.payload).pipe(
                map((result: ScheduleActivityPlanningDTO) => {
                    return new CreateNewActivitySuccess(result, action.payload.subsystem);
                }),
                catchError((error) => of(new CreateNewActivityError({ error }, action.payload.subsystem)))
            );
        })
    );

    @Effect()
    setSubsystemsUpdate = this.actions$.pipe(
        ofType<ScheduleActivitySetSubsytemsUpdate>(ScheduleActivityPlanningActionTypes.SetSubsystemsUpdate),
        switchMap((action) => {
            const subsystemsToUpdate = action.payload.subsystems.map((s) => s.id);
            return this.scheduleActivityPlanningService
                .setSubsystemsInWeek({ weekStart: action.payload.weekStart, subsystems: subsystemsToUpdate })
                .pipe(
                    map(() => {
                        return new ScheduleActivitySetSubsytemsUpdateSuccess();
                    }),
                    catchError((error) => of(new ScheduleActivitySetSubsytemsUpdateError({ error })))
                );
        })
    );

    @Effect({ dispatch: false })
    setSubsystemsUpdateSuccess = this.actions$.pipe(
        ofType<ScheduleActivitySetSubsytemsUpdateSuccess>(
            ScheduleActivityPlanningActionTypes.SetSubsystemsUpdateSuccess
        ),
        map(() => {
            this.toastService.Success('Subsystems in week have been saved.');
        })
    );

    @Effect({ dispatch: false })
    setSubsystemsUpdateError = this.actions$.pipe(
        ofType<ScheduleActivitySetSubsytemsUpdateError>(ScheduleActivityPlanningActionTypes.SetSubsystemsUpdateError),
        map(() => {
            this.toastService.Error(
                'An error occured while saving Subsystems in week. Subsystems in week have not been saved.'
            );
        })
    );

    @Effect({ dispatch: false })
    onWeekSummaryDataError = this.actions$.pipe(
        ofType<ScheduleActivityWeekSummaryDataError>(ScheduleActivityPlanningActionTypes.WeekSummaryDataRequestError),
        map(() => {
            this.toastService.Error('Failed to fetch Week Summary data. Please contact Program Administrator.');
        })
    );

    @Effect()
    getWeekSummaryData$ = this.actions$.pipe(
        ofType<ScheduleActivityWeekSummaryDataRequest>(ScheduleActivityPlanningActionTypes.WeekSummaryDataRequest),
        withLatestFrom(
            this.store.select((store) => store.scheduleActivityPlanningState?.scheduleActivityPlanningFilter)
        ),
        switchMap(([action, filter]) => {
            const newFilter = {
                ...filter,
                weekSummaryFilter: new WeekSummaryFilter(),
            };
            return this.scheduleActivityLookupService.getWeekSummary(newFilter).pipe(
                map((response: WeekSummaryDto) => {
                    return new ScheduleActivityWeekSummaryDataSuccess(response);
                }),
                catchError((error) => of(new ScheduleActivityWeekSummaryDataError({ error })))
            );
        })
    );
}
