import { OrderDirection, CheckListColumn, RFO } from '../common.model';

export interface TagSearchState {
    dataPagination: TagSearchDataPagination;
    filter: TagSearchFilter;
    isLoading: boolean;
}

export class TagSearchDataPagination {
    items: TagSearchDTO[] = [];
    totalCount = 0;
}

export class TagSearchFilter {
    page = 0;
    take = 25;
    tagTypes: string[] = [];
    tags: { id: string }[] = [];
    tagDescription = '';
    tagStatuses: string[] = [];
    projectTeamNames: string[] = [];
    subsystems: { id: string }[] = [];
    areaBreakdown: string[] = [];
    rfos: RFO[] = [];
    timezoneOffset = 0;
    sortBy = 'tagNo';
    direction = OrderDirection.Asc;
    columnTagNo: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnServiceDescription: CheckListColumn = null;
    columnSubValidateComment: CheckListColumn = null;
    columnProjectTeamName: CheckListColumn = null;
    columnTagStatus: CheckListColumn = null;
    columnExInspectReqd: CheckListColumn = null;
    columnSubsystemsList: CheckListColumn = null;
}

export class TagSearchDTO {
    tagDetails: TagDetailsDTO;
    itRs: TagITRDetailsDTO[];
    plIs: TagPLIDetailsDTO[];
    changeDocs: TagChangeDocDetailsDTO[];
    showITRs: boolean;
    showPLIs: boolean;
    showDetails: boolean;
    showChangeDocs: boolean;
    itrHeaderFilter = new TagSearchITRHeaderFilter();
    pliHeaderFilter = new TagSearchPLIHeaderFilter();
    changeDocHeaderFilter = new TagSearchChangeDocHeaderFilter();
}

export class TagSearchITRHeaderFilter {
    sortBy = {
        active: 'number',
        direction: OrderDirection.Desc,
    };
    columnnumber: CheckListColumn = null;
    columntype: CheckListColumn = null;
    columnstatus: CheckListColumn = null;
    columncontractor: CheckListColumn = null;
    columndescription: CheckListColumn = null;
}

export class TagSearchPLIHeaderFilter {
    sortBy = {
        active: 'number',
        direction: OrderDirection.Desc,
    };
    columnnumber: CheckListColumn = null;
    columncategory: CheckListColumn = null;
    columnstatus: CheckListColumn = null;
    columncontractor: CheckListColumn = null;
    columndescription: CheckListColumn = null;
}

export class TagSearchChangeDocHeaderFilter {
    sortBy = {
        active: 'number',
        direction: OrderDirection.Desc,
    };
    columnnumber: CheckListColumn = null;
    columnchangeType: CheckListColumn = null;
    columnstatus: CheckListColumn = null;
    columncontractor: CheckListColumn = null;
    columntitle: CheckListColumn = null;
    columnsignOffStatus: CheckListColumn = null;
}

export class TagDetailsDTO {
    tagNo: string;
    tagType: string;
    tagName: string;
    tagStatus: string;
    projectTeamName: string;
    exInspectReqd: string;
    discipline: string;
    subsystemsList: string;
}

export class TagITRDetailsDTO {
    number: string;
    type: string;
    description: string;
    status: string;
    contractor: string;
    hidden: boolean;
}

export class TagPLIDetailsDTO {
    number: string;
    description: string;
    category: string;
    status: string;
    contractor: string;
    hidden: boolean;
}

export class TagChangeDocDetailsDTO {
    changeType: string;
    number: string;
    title: string;
    contractor: string;
    status: string;
    signOffStatus: string;
}
export class TagKeyDTO {
    id: number;
    tagNo: string;
}
