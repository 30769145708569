import { Injectable } from '@angular/core';
import { PascrStatusFilter, PascrStatusDataPagination } from 'src/app/store/pascr-status/model';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class PascrStatusService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/pascrstatus';
    constructor(private http: HttpClient) { }

    searchTag(filter: PascrStatusFilter) {
        return this.http.post<PascrStatusDataPagination>(`${this.rootUrl}${this.endpoint}`, filter);
    }

    generateExcel(filter: PascrStatusFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

}