import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import {
    IcicleRequest,
    IcicleFilterPropertyUpdate,
    IcicleFilterReset,
    IcicleWeekRangeSet,
} from 'src/app/store/reports/icicle/actions';
import { takeWhile, map, take, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { IcicleFilter } from 'src/app/store/reports/icicle/model';
import { UntypedFormGroup } from '@angular/forms';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { FormService } from 'src/app/services/shared/form.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { Gooc, Milestone, RFO, StagedStartUpPriority, TCOUser, Waypoint, Contractor } from 'src/app/store/common.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { PhaseType } from 'src/app/enums';
import { Router, NavigationEnd } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-icicle-report',
    templateUrl: './icicle-report.component.html',
    styleUrls: ['./icicle-report.component.scss'],
})
export class IcicleReportComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    areaCompletionLeads: TCOUser[] = [];
    data$ = this.store.select((state) => state.icicleState.data);
    type$ = this.store.select((state) => state.icicleState.type);
    startDate$ = this.store.select((state) => state.icicleState.startDate);
    isLoading$ = this.store.select((state) => state.icicleState.isLoading);
    autocompleteDisplayedColumns = ['name', 'description'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    subsystemAutocompleteDisplayedColumns = ['id', 'name'];
    projectTeamNames: string[] = [];
    navLinks: { label: string; link: string; index: number }[];
    activeLink: string;
    activeType: PhaseType;
    startDate: string;
    sysOwners: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    isUsersPerProjectLoading = false;
    projectTeamNamesCurrent: string[];
    prevWaypoints: Waypoint[] = [];
    milestones$ = this.store.select((store) => store.icicleState.filter.milestones);
    waypoints$ = this.store.select((store) => store.icicleState.filter.waypoints);

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    constructor(
        private store: Store<ApplicationState>,
        private formService: FormService,
        private lookupService: LookupService,
        private projectTeamsService: ProjectTeamsService,
        private router: Router,
        private toastService: ToastService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new IcicleFilter());
        this.navLinks = [
            // {
            //     label: 'mc icicle',
            //     link: './mc',
            //     index: 0,
            // },
            {
                label: 'rfsu icicle',
                link: './rfsu',
                index: 1,
            },
        ];
        this.router.events.pipe(takeWhile(() => this.isAlive)).subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.activeLink = event.url.split('/')[4];
                if (this.activeLink && this.activeLink === 'mc') {
                    this.activeLink = this.activeLink.toUpperCase();
                    this.activeType = PhaseType.MC;
                } else if (this.activeLink && this.activeLink === 'rfsu') {
                    this.activeLink = this.activeLink.toUpperCase();
                    this.activeType = PhaseType.RFSU;
                }
                this.store.dispatch(new IcicleRequest(this.activeType));
            }
        });
    }

    ngOnInit() {
        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.startDate$.pipe(takeWhile(() => this.isAlive)).subscribe((startDate) => {
            this.startDate = startDate;
        });

        this.filterForm.controls.waypoints.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((waypoints: Waypoint[]) => {
                this.prevWaypoints = waypoints;
                this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
            });

        this.filterForm.controls.milestones.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((milestones: Milestone[]) => {
                let waypoints = JSON.parse(JSON.stringify(milestones));
                waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
                waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name');
                this.setMilestoneInput.emit(new SetInputEventArgs(false, '', milestones));
                if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) {
                    this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                    this.filterForm.controls.waypoints.setValue(waypoints);
                }
            });

        this.getUsersPerProjectTeam();
        this.lookupService
        .getACLeads([])
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads));
    
        this.filterExpansionPanel.expanded = true;
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };

    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    startDateSet(startDate: string) {
        this.store.dispatch(new IcicleWeekRangeSet({ startDate, type: this.activeType }));
    }

    search() {
        this.updateFilterByFormProperties();
        this.store.dispatch(new IcicleRequest(this.activeType));
    }

    resetFilters() {
        this.setSubsystemInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.subsystems.setValue([]);
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.goocs.setValue([]);
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.rfos.setValue([]);
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.contractors.setValue([]);
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.milestones.setValue([]);
        this.filterForm.controls.waypoints.setValue([]);
        this.filterForm.controls.mcEngineer.setValue([]);
        this.filterForm.controls.systemOwner.setValue([]);
        this.filterForm.controls.scManager.setValue([]);
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.stagedStartUpPriorities.setValue([]);
        this.store.dispatch(new IcicleFilterReset());
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction' || key === 'scope' || key === 'zone')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new IcicleFilterPropertyUpdate({ key, value }));
            }
        }
    }

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.projectTeamNames);
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.projectTeamNames)
            .pipe(map((RFOs: RFO[]) => RFOs));
    };

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames);
    };

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.getUsersPerProjectTeam();
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMilestones(
            search,
            take,
            page,
            this.filterForm.value.waypoints,
            this.filterForm.value.projectTeamNames
        );
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((prio: StagedStartUpPriority[]) => prio));
    };

    onStagedStartUpPrioritiesClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    private checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName].value.length &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm.controls[formControlName].setValue(valid);
        }
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    private getUsersPerProjectTeam() {
        this.setSubsystemInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'subsystems');
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'milestones');
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'waypoints');
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'contractors');
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'goocs');
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(this.filterForm.controls.projectTeamNames.value, 'rfos');
        forkJoin([
            this.lookupService.getMCEngineers(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getSysOwners(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getSCManagers(this.filterForm.controls.projectTeamNames.value || []),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners, scManagers]) => {
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }
}
