<main class="main">
    <div class="title-container">
        <div class="title">
            Leak Test Pack Tracker
        </div>
    </div>


    <div class="buttons-container flex">
        <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
            export to excel
        </button>
    </div>

    <div class="results-container">

        <div class="results-title">
            <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
            <div class="results-panel-header-container">
                <div class="results-panel-header-title-row">
                    <div class="results-header-actions">
                        <button mat-button [matMenuTriggerFor]="columnsCustomizer" class="results-columns-customizer">
                            <mat-icon svgIcon="edit"></mat-icon>
                            customize columns
                        </button>
                        <mat-menu #columnsCustomizer class="columns-customizer">
                            <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                            <div class="columns-customizer-list">
                                <mat-checkbox 
                                    [checked]="showColumns.showSubsysName"
                                    (change)="reverseValue('showSubsysName')"
                                    (click)="$event.stopPropagation()"
                                    >Subsystem Name</mat-checkbox
                                >
                                <mat-checkbox 
                                    [checked]="showColumns.showPreCommDates"
                                    (change)="reverseValue('showPreCommDates')"
                                    (click)="$event.stopPropagation()"
                                    >Activity Dates</mat-checkbox
                                >
                                <mat-checkbox 
                                    [checked]="showColumns.showBarcode"
                                    (change)="reverseValue('showBarcode')"
                                    (click)="$event.stopPropagation()"
                                    >Barcode</mat-checkbox
                                >
                            </div>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll">
            <div class="table-container">
                <app-mat-table-sticky-header [scrollKey]="'detailed-status'">
                    <table mat-table matSort [dataSource]="data" [matSortActive]="sortBy" matSortDisableClear
                        [matSortDirection]="direction">
                        <ng-container matColumnDef="leakTestPackTrackerNo">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="sort-container">
                                    <span class="hover-pointer" [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnLeakTestPackTrackerNo)
                                        }" (click)="
                                            openMainHeaderCheckListFilter(
                                                'leakTestPackTrackerNo',
                                                getLeakTestPackTrackerNo,
                                                'columnLeakTestPackTrackerNo',
                                                'Leak Test Pack Tracker',
                                                columnLeakTestPackTrackerNo
                                            )
                                        ">Leak Test Pack Number</span>
                                 
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
           
                                    <span>{{ element.leakTestPackTrackerNo }}</span>
                                
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="priority">
                            <th
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef
                                class="hover-pointer"
                                (click)="
                                    openMainHeaderCheckListFilter(
                                        'priority',
                                        getPriorities,
                                        'columnPriority',
                                        'Priority-Manual',
                                        columnPriority
                                    )
                                "
                            >
                                <div class="sort-container center">
                                    <span
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnPriority)
                                        }"
                                        >Priority-Manual</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'PriorityManualOverride'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                           
                                <td 
                                    mat-cell
                                    *matCellDef="let element"
                                    (contextmenu)="priorityUpdateHistory($event,element.leakTestPackTrackerNo)"                                 
                                >                                    
                                    <mat-select                               
                                    matNativeControl
                                    [value]="element.priority" 
                                    (selectionChange)="updatePriority($event, cloneElement(element))"                                
                                    >
                                        <mat-option *ngFor="let priority of Priorities" [value]="priority">
                                            {{ priority }}
                                        </mat-option>
                                    </mat-select>
                                </td>
                       
                        </ng-container>
                        <ng-container matColumnDef="subsystem">
                            <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                                <div class="sort-container center">
                                    <span class="hover-pointer" (click)="
                                    openMainHeaderCheckListFilter(
                                                'instrumentSubsystem',
                                                getInstrumentSubsystem,
                                                'columnInstrumentSubsystem',
                                                'Subsystem',
                                                columnInstrumentSubsystem,
                                                false
                                            )
                                        " [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnInstrumentSubsystem)
                                        }">Subsystem Number</span>
                                    <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.instrumentSubsystem }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="subsystemName">
                            <th app-col-resize mat-header-cell disableClear *matHeaderCellDef [ngClass]="{
                                    hidecells: !showColumns.showSubsysName 
                                }"  (click)="
                                openMainHeaderCheckListFilter(
                                        'instrumentSubsystemDescription',
                                        getInstrumentSubsystemName,
                                        'columnSubsystemName',
                                        'Subsystem Name',
                                        columnSubsystemName
                                    )
                                ">
                                <div class="sort-container center">
                                    <span class="hover-pointer" [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnSubsystemName)
                                        }">Sub System Description</span>
                                    <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showSubsysName }">
                                {{ element.instrumentSubsystemDescription }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="bitrRemaining">
                            <th
                                app-col-resize
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef                              
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        [ngClass]="{
                                            'header-filtered': showUnderlineForNumericColumn(columnBITRRemaining)
                                        }"
                                        (click)="
                                            openHeaderNumericFilter(
                                                'bitrRemaining',
                                                columnBITRRemaining,
                                                'B-ITRs Remaining (LTP)',
                                                'columnBITRRemaining'
                                            )
                                        "
                                        >B-ITRs Remaining (LTP)</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'bitrRemaining'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                (click)="goToITRandPLI('BITR',$event,element)">   
                                <span class="cursor-pointer center">
                                    <a>
                                        {{ element.bitrRemaining }}
                                    </a>
                                </span>                             

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="citrRemaining">
                            <th
                                app-col-resize
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef                              
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        [ngClass]="{
                                            'header-filtered': showUnderlineForNumericColumn(columnCITRRemaining)
                                        }"
                                        (click)="
                                            openHeaderNumericFilter(
                                                'citrRemaining',
                                                columnCITRRemaining,
                                                'C-ITRs Remaining(LTP)',
                                                'columnCITRRemaining'
                                            )
                                        "
                                        >C-ITRs Remaining(LTP)</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'citrRemaining'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                (click)="goToITRandPLI('CITR',$event,element)"
                            >   <span class="cursor-pointer center">
                                    <a>
                                        {{ element.citrRemaining }}
                                    </a>
                                </span>                             

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="pliRemaining">
                            <th
                                app-col-resize
                                mat-header-cell
                                disableClear
                                *matHeaderCellDef                              
                            >
                                <div class="sort-container center">
                                    <span
                                        class="hover-pointer"
                                        [ngClass]="{
                                            'header-filtered': showUnderlineForNumericColumn(columnPLIRemaining)
                                        }"
                                        (click)="
                                            openHeaderNumericFilter(
                                                'pliRemaining',
                                                columnPLIRemaining,
                                                'PLIs Remaining(LTP)',
                                                'columnPLIRemaining'
                                            )
                                        "
                                        >PLIs Remaining(LTP)</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'pliRemaining'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                (click)="goToITRandPLI('PLI',$event,element)"
                            >   <span class="cursor-pointer center">
                                    <a>
                                        {{ element.pliRemaining }}
                                    </a>
                                </span>                             

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="preCommStart">
                            <th mat-header-cell disableClear *matHeaderCellDef [ngClass]="{
                                    hidecells: !showColumns.showPreCommDates
                                }" class="hover-pointer" (click)="
                                    openHeaderDateFilter(
                                        'activityStartDate',
                                        'columnPreCommStart',
                                        'Pre-Comm Start',
                                        columnPreCommStart,
                                        false
                                    )
                                ">
                                <div class="sort-container">
                                    <span [ngClass]="{
                                            'header-underline': showUnderlineForCalendarColumn(columnPreCommStart)
                                        }">Activity Start date</span>
                                    <div class="sort-icon" *ngIf="sortBy === 'preCommStart'">
                                        <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showPreCommDates }">
                                {{ element.activityStartDate | date: 'd/MMM/yy' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="preCommFinish">
                            <th mat-header-cell disableClear *matHeaderCellDef [ngClass]="{
                                    hidecells: !showColumns.showPreCommDates
                                }" class="hover-pointer" (click)="
                                    openHeaderDateFilter(
                                        'activityFinishDate',
                                        'columnPreCommFinish',
                                        'Pre-Comm Finish',
                                        columnPreCommFinish,
                                        false
                                    )
                                ">
                                <div class="sort-container">
                                    <span [ngClass]="{
                                            'header-underline': showUnderlineForCalendarColumn(columnPreCommFinish)
                                        }">Activity End date</span>
                                    <div class="sort-icon" *ngIf="sortBy === 'preCommFinish'">
                                        <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="{ hidecells: !showColumns.showPreCommDates }">
                                {{ element.activityFinishDate | date: 'd/MMM/yy' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="barcode">
                            <th app-col-resize mat-header-cell disableClear *matHeaderCellDef [ngClass]="{
                                    hidecells: !showColumns.showBarcode
                                }">
                                <div class="sort-container center">
                                    <span class="hover-pointer" (click)="
                                            openMainHeaderCheckListFilter(
                                                'barcode',
                                                getBarcodes,
                                                'columnBarcode',
                                                'Barcode',
                                                columnBarcode
                                            )
                                        " [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnBarcode)
                                        }">ITR Barcode </span>
                                    <div class="sort-icon" *ngIf="sortBy === 'barcode'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell 
                            *matCellDef="let element" 
                            [ngClass]="{ hidecells: !showColumns.showBarcode }"
                            >
                                {{ element.barcode }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="certificateComment">
                            <th mat-header-cell disableClear *matHeaderCellDef  (click)="
                                            openMainHeaderCheckListFilter(
                                                'certificateComment',
                                                getCertificateComment,
                                                'columnCertificateComment',
                                                'certificateComment',
                                                columnCertificateComment,
                                                false,
                                                true,
                                                false,
                                                true                                                 
                                            )
                                        " app-col-resize>
                                <div class="sort-container center">
                                    <span class="hover-pointer" [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnCertificateComment)
                                        }">Certification Comment</span>
                                    <div class="sort-icon" *ngIf="sortBy === 'certificateComment'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.certificateComment }}
                            </td>
                        </ng-container>                      
                        <ng-container matColumnDef="itrComment" stickyEnd>
                            <th mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                                <div class="sort-container center">
                                    <span class="hover-pointer" [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(columnITRComment)                                              
                                        }" (click)="
                                            openMainHeaderCheckListFilter(
                                                'itrComment',
                                                getITRComments,
                                                'columnITRComment',
                                                'ITR Comment',
                                                columnITRComment,
                                                false,
                                                true,
                                                false,
                                                true  
                                            )
                                        ">
                                      ITR Comment
                                    </span>
                                    <div class="sort-icon" *ngIf="sortBy === 'itrComment'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.itrComment }}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                    </table>
                </app-mat-table-sticky-header>
            </div>
        </div>
        <div class="paginator page-counter">
            <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)" showFirstLastButtons>
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>

    <!-- Old implementation starts here -->

    <!-- <div class="results-container">
        <div class="scroll" app-double-scroll>
            <table mat-table [dataSource]="data" multiTemplateDataRows class="mat-elevation-z8">
                <ng-container matColumnDef="leakTestPackTrackerNo" sticky>
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span class="hover-pointer" [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnLeakTestPackTrackerNo)
                                }" (click)="
                                    openMainHeaderCheckListFilter(
                                        'leakTestPackTrackerNo',
                                        getLeakTestPackTrackerNo,
                                        'columnLeakTestPackTrackerNo',
                                        'Leak Test Pack Tracker',
                                        columnLeakTestPackTrackerNo
                                    )
                                ">Leak Test Pack Number</span>
                            <div class="sort-icon" *ngIf="sortBy === 'leakTestPackTrackerNo'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="tree-icon">
                            <div>
                                <mat-icon (click)="visiblePanel(element,true)"
                                    *ngIf="!element.expandElement">chevron_right</mat-icon>
                                <mat-icon (click)="visiblePanel(element,false)"
                                    *ngIf="element.expandElement">expand_more</mat-icon> 
                            </div>
                            <span>{{ element.leakTestPackTracker.leakTestPackTrackerNo }}</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="instrumentSubsystem">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span class="hover-pointer" [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnInstrumentSubsystem)
                                }" (click)="
                                    openMainHeaderCheckListFilter(
                                        'instrumentSubsystem',
                                        getInstrumentSubsystem,
                                        'columnInstrumentSubsystem',
                                        'Instrument Subsystem',
                                        columnInstrumentSubsystem
                                    )
                                ">Subsystem</span>
                            <div class="sort-icon" *ngIf="sortBy === 'instrumentSubsystem'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.leakTestPackTracker.instrumentSubsystem }}</td>
                </ng-container>
                <ng-container matColumnDef="forecastStartDate">
                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                        <div class="sort-container">
                            <span class="hover-pointer" [ngClass]="{
                                    'header-underline': showUnderlineForCalendarColumn(columnForecastStartDate)
                                }" (click)="
                                openHeaderDateFilter(
                                        'forecastStartDate',                                        
                                        'columnForecastStartDate',
                                        'Forecast Start Date',
                                        columnForecastStartDate
                                    )
                                ">LTP Forecast Start (from P6)</span>
                            <div class="sort-icon" *ngIf="sortBy === 'forecastStartDate'">
                                <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.leakTestPackTracker.forecastStartDate | date:
                        'd-MMM-yyyy' }}</td>
                </ng-container>                

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div class="tag-element-detail"
                            [@detailExpand]="element?.expandElement ? 'expanded' : 'collapsed'">
                            <div class="tag-element-description">
                                <div *ngIf="element.tagNoList.length">
                                    <div *ngFor="let tg of element.tagNoList; let i=index">
                                        <div class="tree-icon" style="font-weight: 600;">
                                            <mat-icon (click)="showHidePropertyUpdate(element,tg.tagNo,true)" *ngIf="!tg.showActivity"
                                                >chevron_right</mat-icon
                                            >
                                            <mat-icon (click)="showHidePropertyUpdate(element,tg.tagNo,false)" *ngIf="tg.showActivity"
                                                >expand_more</mat-icon
                                            >
                                            <span>{{tg.tagNo}}</span>
                                        </div>
                                        <div class="scroll">
                                            <table
                                                *ngIf="tg.showActivity"
                                                mat-table
                                                [dataSource]="getFilteredData(element.leakTestPackTrackerDetails,tg.tagNo) | filterHidden"
                                                multiTemplateDataRows
                                                class="qvditr-subtable"
                                            >
                                                <ng-container
                                                    matColumnDef="{{ column }}"
                                                    *ngFor="let column of columnToDisplayDetails"
                                                    [sticky]="column === 'number' ? 'sticky' : null"
                                                >
                                                    <th mat-header-cell *matHeaderCellDef app-col-resize>
                                                        <div class="sort-container">
                                                            <span
                                                                class="hover-pointer"
                                                                [ngClass]="{
                                                                    'header-underline': showUnderlineForCheckListColumn(
                                                                        element.leakTestPackTrackerDetailHeaderFilter['column' + column]
                                                                    )
                                                                }"
                                                                (click)="
                                                                    openHeaderCheckListFilter(
                                                                        tableTypes.activityDetails,
                                                                        getFilteredData(element.leakTestPackTrackerDetails,tg.tagNo),
                                                                        element,
                                                                        column,
                                                                        getValues,
                                                                        'column' + column,
                                                                        detailedColumnName[column],
                                                                        element.leakTestPackTrackerDetailHeaderFilter['column' + column],
                                                                        false,
                                                                        true
                                                                    )
                                                                "
                                                                >{{ detailedColumnName[column] }}</span
                                                            >
                                                            <div
                                                                class="sort-icon"
                                                                *ngIf="element.leakTestPackTrackerDetailHeaderFilter.sortBy.active === column"
                                                            >
                                                                <mat-icon
                                                                    *ngIf="
                                                                        element.leakTestPackTrackerDetailHeaderFilter.sortBy.direction === 'asc'
                                                                    "
                                                                    >keyboard_arrow_up</mat-icon
                                                                >
                                                                <mat-icon
                                                                    *ngIf="
                                                                        element.leakTestPackTrackerDetailHeaderFilter.sortBy.direction === 'desc'
                                                                    "
                                                                    >keyboard_arrow_down</mat-icon
                                                                >
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="columnToDisplayDetails"></tr>
                                                <tr mat-row *matRowDef="let row; columns: columnToDisplayDetails"></tr>
                                            </table>
                                        </div>
                                </div>
                                </div>

                            </div>
                        </div>
                    </td>
                </ng-container> 

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                 <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="tag-element-row"
                    [class.tag-expanded-row]="expandedElement === element"></tr> 
                 <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="tag-detail-row"></tr> 
            </table>
        </div>
        <div class="paginator page-counter">
            <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)" showFirstLastButtons>
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator> -->

    <!-- Old implementation ends here -->
</main>