import { Component, OnInit, ChangeDetectorRef, ViewChild, NgZone } from '@angular/core';
import { BaseComponent } from '../base.component';
import { take, takeWhile } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { select, Store } from '@ngrx/store';
import { ExportPageDTO, ExportPageFilter } from 'src/app/store/export-page/model';
import { ExportPageFilterRequest, ExportPageFileRequest } from 'src/app/store/export-page/actions';


@Component({
    selector: 'app-export-page',
    templateUrl: './export-page.component.html',
    styleUrls: ['./export-page.component.scss'],
})
export class ExportPageComponent extends BaseComponent implements OnInit {
    data: ExportPageDTO[] = [];
    displayedColumns: string[] = ['createdBy', 'created', 'fileName'];
    isLoading = false;
    dataPagination$ = this.store.select((state) => state.ExportPageState.dataPagination);
    stateLoader$ = this.store.select((state) => state.ExportPageState.isLoading);
    registerFilter$ = this.store.select((state) => state.ExportPageState.filter);
    resultsLength = 0;

    constructor(
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
        //this.filterForm = this.formService.createSimpleForm(new ExportPageFilter());
    }

    ngOnInit() {
        this.stateLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.dataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.store.dispatch(new ExportPageFilterRequest());

    }

    openLink(link: string, filename: string) {
        this.store.dispatch(new ExportPageFileRequest({ link: link, filename: filename}));
    }

}
