import { TagsState } from './model';
import { TagsActions, TagsActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: TagsState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isDataReadyToProcess: false,
    recordsForDeletion: 0,
    isAutomaticUploadInProgress: true,
};

export function reducer(state = initialState, action: TagsActions): TagsState {
    switch (action.type) {
        case TagsActionTypes.TagsUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case TagsActionTypes.TagsUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case TagsActionTypes.TagsUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case TagsActionTypes.TagsSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case TagsActionTypes.TagsFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case TagsActionTypes.TagsClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case TagsActionTypes.TagsTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case TagsActionTypes.TagsTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case TagsActionTypes.TagsTemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case TagsActionTypes.TagsValidateDeltaRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case TagsActionTypes.TagsValidateDeltaSuccess: {
            return {
                ...state,
                isLoading: false,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case TagsActionTypes.TagsValidateDeltaError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case TagsActionTypes.TagsDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case TagsActionTypes.TagsDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case TagsActionTypes.TagsDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case TagsActionTypes.TagsAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'Tags';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        case TagsActionTypes.TagsValidateButtonStateSuccess: {
            return {
                ...state,
                isDataReadyToProcess: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
                isAutomaticUploadInProgress: action.payload.isAutomaticUploadInProgress,
            };
        }
        default:
            return state;
    }
}
