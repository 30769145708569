import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, takeWhile, withLatestFrom } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { SubsystemScope } from 'src/app/enums';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { DetailedStatusService } from 'src/app/services/api/webapi-services/detailed-status.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { CalendarColumn, CheckListColumn, OrderDirection, TCOUser } from 'src/app/store/common.model';
import { AttachmentDTO } from 'src/app/store/details/model';
import { ApplicationState } from 'src/app/store/model';
import {
    HealthOverdueDPLIChartRequest,
    HealthOverdueDPLIExportToExcelRequest,
    HealthOverdueDPLIFilterPropertyUpdate,
    HealthOverdueDPLIFilterReset,
    HealthOverdueDPLIFilterUpdate,
    HealthOverdueDPLITableFilterReset,
    HealthOverdueDPLITableRequest,
} from 'src/app/store/reports/health/actions';
import { OverdueDPLIFilter } from 'src/app/store/reports/health/model';
import { Dictionary } from 'typescript-collections';

@Component({
    selector: 'app-overdue-dpli',
    templateUrl: './overdue-dpli.component.html',
    styleUrls: ['./overdue-dpli.component.scss'],
})
export class OverdueDPLIComponent extends BaseComponent implements OnInit {
    projectTeamNames: string[] = [];
    projectTeamNamesCurrent: string[] = [];
    sysOwners: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    disciplines: any[] = [];
    filterForm: UntypedFormGroup;
    showSubsysName = false;
    showTagNo = true;
    showTagType = false;
    showPunchType = true;
    showRaisedBy = false;
    showRaisedDate = false;
    showMaterialReference = false;
    showAttachments = false;
    showSystemOwner = false;
    showReviewStatus = false;
    displayedColumns = [
        'subsystem',
        'subsystemName',
        'number',
        'description',
        'category',
        'tagNumber',
        'tagType',
        'systemOwner',
        'actionBy',
        'punchType',
        'createdDate',
        'raisedBy',
        'discipline',
        'materialReference',
        'reviewStatusComplete',
        'attachments',
        'latestComment',
    ];
    sortBy = '';
    pageSize = 25;
    direction: OrderDirection = OrderDirection.Desc;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnNumber: CheckListColumn = null;
    columnActionBy: CheckListColumn = null;
    columnPunchType: CheckListColumn = null;
    columnRaisedBy: CheckListColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnDescription: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnSubsystem: CheckListColumn = null;
    columnSubsystemName: CheckListColumn = null;
    columnReviewStatus: CheckListColumn = null;
    columnMaterialReference: CheckListColumn = null;
    columnSystemOwner: CheckListColumn = null;
    colorScheme = { domain: ['#0b2d71', '#0b2d71', '#0b2d71', '#0b2d71'] };
    uploadedAttachmentsActionInProgress = new Dictionary<string, boolean>();
    selectedBarLabel = '';

    overdueDPLIFilter$ = this.store.select((state) => state.healthState.overdueDpli.filter);
    columnSystemOwner$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnSystemOwner);
    columnCreatedDate$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnCreatedDate);
    columnSubsystem$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnSubsystem);
    columnComments$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnComments);
    columnNumber$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnNumber);
    columnDescription$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnDescription);
    columnSubsystemName$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnSubsystemName);
    columnTagNumber$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnTagNumber);
    columnTagType$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnTagType);
    columnActionBy$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnActionBy);
    columnMaterialReference$ = this.store.select(
        (state) => state.healthState.overdueDpli.filter.columnMaterialReference
    );
    columnPunchType$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnPunchType);
    columnRaisedBy$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnRaisedBy);
    columnDiscipline$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnDiscipline);
    columnReviewStatus$ = this.store.select((state) => state.healthState.overdueDpli.filter.columnReviewStatusComplete);
    table$ = this.store.select((state) => state.healthState.overdueDpli.table).pipe(filter((table) => !!table));
    chart$ = this.store.select((state) => state.healthState.overdueDpli.chart);
    isTableLoading$ = this.store.select((state) => state.healthState.overdueDpli.isTableLoading);
    isChartLoading$ = this.store.select((state) => state.healthState.overdueDpli.isChartLoading);

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('chartPanel', { static: true }) chartExpansionPanel: MatExpansionPanel;
    @ViewChild('chart', { static: true }) chart: ElementRef<HTMLElement>;

    constructor(
        private lookupService: LookupService,
        private toastService: ToastService,
        private projectTeamsService: ProjectTeamsService,
        private formService: FormService,
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private detailedStatusService: DetailedStatusService,
        private renderer: Renderer2
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new OverdueDPLIFilter());
    }

    ngOnInit() {
        this.filterExpansionPanel.expanded = true;
        this.chartExpansionPanel.expanded = true;

        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.overdueDPLIFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam();
        });

        this.columnCreatedDate$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnCreatedDate = column;
        });
        this.columnSystemOwner$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnSystemOwner = column;
        });
        this.columnSubsystem$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnSubsystem = column;
        });
        this.columnComments$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnComments = column;
        });
        this.columnNumber$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnNumber = column;
        });
        this.columnDescription$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnDescription = column;
        });
        this.columnSubsystemName$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnSubsystemName = column;
        });
        this.columnTagNumber$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnTagNumber = column;
        });
        this.columnTagType$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnTagType = column;
        });
        this.columnActionBy$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnActionBy = column;
        });
        this.columnMaterialReference$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnMaterialReference = column;
        });
        this.columnPunchType$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnPunchType = column;
        });
        this.columnRaisedBy$.pipe(takeWhile(() => this.isAlive)).subscribe((column) => {
            this.columnRaisedBy = column;
        });
        this.columnReviewStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((column) => (this.columnReviewStatus = column));

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: any[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines. Please contact Program Administrator.'
                    );
                }
            );

        this.filterForm.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((filters) => {
            this.store.dispatch(new HealthOverdueDPLIFilterUpdate(filters as OverdueDPLIFilter));
        });

        this.filterForm.controls.overdueOnly.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((overdueOnlyValue) => {
                if (overdueOnlyValue !== null) {
                    this.filterForm.controls.projectActionOnly.setValue(true);
                    this.filterForm.controls.projectActionOnly.disable();
                } else {
                    this.filterForm.controls.projectActionOnly.enable();
                }
            });

        this.store.dispatch(new HealthOverdueDPLITableRequest());
        this.store.dispatch(new HealthOverdueDPLIChartRequest());
    }

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.getUsersPerProjectTeam();
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    search(resetTable: boolean, resetChart: boolean) {
        if (resetChart === true) {
            this.selectedBarLabel = '';
            this.chart.nativeElement.querySelectorAll('g[ngx-charts-bar] path:not(.hidden)').forEach((element) => {
                this.renderer.removeClass(element, 'grayed-out');
            });
        }
        this.filterForm.controls.selectedBarLabel.setValue(this.selectedBarLabel);
        if (resetTable === true) {
            this.store.dispatch(new HealthOverdueDPLITableFilterReset(true));
        }
        this.paginator.pageIndex = 0;
        this.store.dispatch(new HealthOverdueDPLITableRequest());
        this.store.dispatch(new HealthOverdueDPLIChartRequest());
    }

    resetFilters() {
        this.store.dispatch(new HealthOverdueDPLIFilterReset());
        this.filterForm.controls.projectActionOnly.enable();
        this.selectedBarLabel = '';
        this.chart.nativeElement.querySelectorAll('g[ngx-charts-bar] path:not(.hidden)').forEach((element) => {
            this.renderer.removeClass(element, 'grayed-out');
        });
    }

    displaySelectedRadiobutton(value: boolean) {
        if (value === null) {
            return 'All';
        }
        return value ? 'Yes' : 'No';
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new HealthOverdueDPLIFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new HealthOverdueDPLIFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new HealthOverdueDPLITableRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                excludeBlanks: excludeBlanks,
                onlyBlanks: onlyBlanks,
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                showBlankOptions: showBlankOptions,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search(false, false);
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new HealthOverdueDPLIFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new HealthOverdueDPLIFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search(false, false);
                },
            },
            345,
            580)
        );
    }

    download(attachment: AttachmentDTO, e) {
        e.stopPropagation();
        e.preventDefault();
        this.uploadedAttachmentsActionInProgress.setValue(attachment.link, true);
        this.detailedStatusService
            .downloadAttachment(attachment.link, SubsystemScope.PLI)
            .pipe(
                take(1),
                finalize(() => this.uploadedAttachmentsActionInProgress.setValue(attachment.link, false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, attachment.name);
            });
    }

    getDisciplines = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchDisciplinesWithOverdueDPLIFilter, search, take, page);

    getCategories = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchCategoriesWithOverdueDPLIFilter, search, take, page);

    getTagNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchTagNumbersWithOverdueDPLIFilter, search, take, page);

    getTagTypes = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchTagTypesWithOverdueDPLIFilter, search, take, page);

    getNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchNumbersWithOverdueDPLIFilter, search, take, page);

    getActionBys = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchActionBysWithOverdueDPLIFilter, search, take, page);

    getDescriptions = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchDescriptionsWithOverdueDPLIFilter, search, take, page);

    getReviewStatusComplete = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchReviewStatusCompleteWithOverdueDPLIFilter, search, take, page);

    getSubsystemNames = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchSubsystemNamesWithOverdueDPLIFilter, search, take, page);

    getSubsystems = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchSubsystemsWithOverdueDPLIFilter, search, take, page);

    getComments = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchCommentsWithOverdueDPLIFilter, search, take, page);

    getMaterialReferences = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchMaterialReferencesWithOverdueDPLIFilter, search, take, page);

    getPunchTypeValues = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchPunchTypeValuesWithOverdueDPLIFilter, search, take, page);

    getRaisedByValues = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchRaisedByValuesWithOverdueDPLIFilter, search, take, page);

    getSystemOwners = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchSystemOwnersWithOverdueDPLIFilter, search, take, page);

    showHideColumns(initWidth: number) {
        return {
            left: `${initWidth - 202 - 165 - +!this.showSubsysName * 115 - +!this.showTagType * 110}px`,
            width: `110px`,
        };
    }

    onSelect(event) {
        this.selectedBarLabel = this.selectedBarLabel === event.label ? '' : event.label;
        this.chart.nativeElement.querySelectorAll('g[ngx-charts-bar] path:not(.hidden)').forEach((element) => {
            if (element.classList.contains('active') || !this.selectedBarLabel) {
                this.renderer.removeClass(element, 'grayed-out');
            } else {
                this.renderer.addClass(element, 'grayed-out');
            }
        });
        this.store.dispatch(new HealthOverdueDPLITableFilterReset(true));
        this.filterForm.controls.projectActionOnly.setValue(this.selectedBarLabel ? true : null);
        this.store.dispatch(
            new HealthOverdueDPLIFilterPropertyUpdate({
                key: 'projectActionOnly',
                value: this.filterForm.controls.projectActionOnly.value,
            })
        );
        this.store.dispatch(
            new HealthOverdueDPLIFilterPropertyUpdate({ key: 'selectedBarLabel', value: this.selectedBarLabel })
        );
        this.store.dispatch(new HealthOverdueDPLITableRequest());
        this.paginator.pageIndex = 0;
    }

    private withLatestFilter(action: (...actionArgs: any) => Observable<any>, ...args: any) {
        return of(null).pipe(
            withLatestFrom(this.store.select((state) => state.healthState.overdueDpli.filter)),
            switchMap(([, filter]) => action(...args, filter))
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new HealthOverdueDPLIFilterUpdate({
                    sortBy: columnName,
                    direction: direction,
                } as OverdueDPLIFilter)
            );
        } else {
            this.store.dispatch(
                new HealthOverdueDPLIFilterUpdate({
                    sortBy: 'number',
                    direction: OrderDirection.Desc,
                } as OverdueDPLIFilter)
            );
        }
    }

    private getUsersPerProjectTeam() {
        forkJoin([
            this.lookupService.getSysOwners(this.filterForm.value.projectTeamNames || []),
            this.lookupService.getSCManagers(this.filterForm.value.projectTeamNames || []),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([sysOwners, scManagers]) => {
                    this.sysOwners = sysOwners;
                    this.scManagers = scManagers;
                },
                (error) => {
                    this.toastService.Error(error);
                }
            );
    }

    exportToExcel() {
        this.store.dispatch(new HealthOverdueDPLIExportToExcelRequest());
    }

    yAxisTickFormatting(val: any) {
        return parseInt(val);
    }
}
