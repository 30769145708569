import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import {
    MCWeeklyPlanningDTO,
    RFSUWeeklyPlanningDTO,
    CommitmentType,
    RFOWeeklyPlanningDTO,
    WeeklyPlanningFilter,
} from 'src/app/store/weekly-planning/model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { BaseComponent } from '../../base.component';
import {
    WeeklyPlanningCommitmentsRequest,
    WeeklyPlanningSubmitCommitmentsRequest,
    WeeklyPlanningTopHeaderSectionDataRequest
} from 'src/app/store/weekly-planning/actions';

@Component({
    selector: 'app-weekly-planning-commitments',
    templateUrl: './weekly-planning-commitments.component.html',
    styleUrls: ['./weekly-planning-commitments.component.scss'],
})
export class WeeklyPlanningCommitmentsComponenet extends BaseComponent implements OnInit {
    projectTeamName = '';
    planningType = '';
    isLoading = false;
    mcDefaultColumns = ['subsystem', 'subsystemName', 'mcPlan', 'mcForecast', 'aqvd', 'apli', 'npw', 'redline'];
    rfsuDefaultColumns = [
        'subsystem',
        'subsystemName',
        'rfsuPlan',
        'rfsuForecast',
        'aqvd',
        'bitr',
        'citr',
        'apli',
        'bpli',
        'cpli',
        'npw',
        'redline',
    ];
    rfoDefaultColumns = ['rfo', 'rfoName', 'rfoPlan', 'rfoForecast'];
    mcDisplayedColumns = [...this.mcDefaultColumns, 'status'];
    rfsuDisplayedColumns = [...this.rfsuDefaultColumns, 'status'];
    rfoDisplayedColumns = [...this.rfoDefaultColumns, 'status'];
    displayedColumns = this.mcDisplayedColumns;
    leftBehindDisplayedColumns = this.mcDefaultColumns;
    plannedAndCommitted: (MCWeeklyPlanningDTO | RFSUWeeklyPlanningDTO | RFOWeeklyPlanningDTO)[] = [];
    additionalCommitments: (MCWeeklyPlanningDTO | RFSUWeeklyPlanningDTO | RFOWeeklyPlanningDTO)[] = [];
    startDate: Date;
    endDate: Date;
    submitButtonDisabled = true;
    commitmentTypes = CommitmentType;

    isLoading$ = this.store.select((store) => store.weeklyPlanningState.commitmentsLoading);
    additionalCommitments$ = this.store.select((store) => store.weeklyPlanningState.commitments.additionalCommitments);
    plannedAndCommitted$ = this.store.select((store) => store.weeklyPlanningState.commitments.plannedAndCommitted);
    weeklyPlanningFilter$ = this.store.select((store) => store.weeklyPlanningState.filter);
    startDate$ = this.store.select((store) => store.weeklyPlanningState.filter.startDate);
    filter: WeeklyPlanningFilter;
    isFilterApplied = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private store: Store<ApplicationState>
    ) {
        super();
    }

    ngOnInit(): void {

        this.activatedRoute.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            this.projectTeamName = paramMap.get('projectTeamName');
            this.planningType = paramMap.get('planningType');
            switch (this.commitmentTypes[this.planningType.toUpperCase()]) {
                case CommitmentType.RFSU:
                    this.displayedColumns = this.rfsuDisplayedColumns;
                    this.leftBehindDisplayedColumns = this.rfsuDefaultColumns;
                    break;
                case CommitmentType.RFO:
                    this.displayedColumns = this.rfoDisplayedColumns;
                    this.leftBehindDisplayedColumns = this.rfoDefaultColumns;
                    break;
                default:
                    this.displayedColumns = this.mcDisplayedColumns;
                    this.leftBehindDisplayedColumns = this.mcDefaultColumns;
                    break;
            }
        });

        this.startDate$.pipe(takeWhile(() => this.isAlive)).subscribe((startDate) => {
            this.startDate = startDate.toDate();
            this.endDate = moment(this.startDate).add(6, 'days').toDate();
        })

        this.isLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((loading) => {
            this.isLoading = loading;
            this.submitButtonDisabled = !_.union(this.plannedAndCommitted, this.additionalCommitments).some(
                (item) => !item.isSubmitted || item.isDeleted
            );
        });

        combineLatest([this.weeklyPlanningFilter$, this.additionalCommitments$, this.plannedAndCommitted$])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(([filter, additionalCommitments, plannedAndCommitted]) => {
                this.plannedAndCommitted = plannedAndCommitted?.filter(s => !s.isDeleted);
                this.additionalCommitments = additionalCommitments?.filter(s => !s.isDeleted);

                this.submitButtonDisabled = !_.union(additionalCommitments, plannedAndCommitted).some(
                    (item) => !item.isSubmitted || item.isDeleted
                );

                this.filter = filter

                this.isFilterApplied = filter.acms?.length > 0 || filter.constructionArea?.length > 0
                    || filter.contractors?.length > 0 || filter.mcEngineers?.length > 0
                    || filter.goocs?.length > 0 || filter.milestones?.length > 0
                    || filter.rfos?.length > 0 || filter.scManagers?.length > 0
                    || filter.systemOwners?.length > 0 || filter.waypoints?.length > 0;
            });

        this.store.dispatch(new WeeklyPlanningCommitmentsRequest({ projectTeamName: this.projectTeamName, planningType: this.planningType }));
        this.store.dispatch(new WeeklyPlanningTopHeaderSectionDataRequest({ projectTeamName: this.projectTeamName, filter: null }));
    }

    submit() {
        this.store.dispatch(new WeeklyPlanningSubmitCommitmentsRequest({ planningType: this.planningType }));
        setTimeout(() => {
            this.store.dispatch(new WeeklyPlanningTopHeaderSectionDataRequest({ projectTeamName: this.projectTeamName, filter: null }));
        }, 500);
    }
}
