<form [formGroup]="filterForm">
    <mat-accordion>
        <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" expanded>
            <mat-expansion-panel-header collapsedHeight="auto">
                <app-lookahead-filter-top-header-section
                    *ngIf="!printMode"
                    [filterForm]="filterForm"
                    [showFilters]="!filterExpansionPanel.expanded"
                ></app-lookahead-filter-top-header-section>
            </mat-expansion-panel-header>
            <div class="filter-container">
                <app-multiple-items-selector
                    class="container300 margin-right20"
                    #workAreaItemSelector
                    placeholder="Work Area"
                    formControlName="workAreas"
                    formSetter="workAreas"
                    propertyToShow="workAreaName"
                    [oneItemMode]="true"
                    [displayedColumns]="['workAreaName']"
                    [searchFunc]="searchAreas"
                    [isAsync]="true"
                    [filterForm]="filterForm"
                    (itemRemoved)="resetWorkAreaFilters()"
                    [setInput]="setWorkAreaInput"
                >
                </app-multiple-items-selector>
                <app-chip-list
                    collectionName="Contractor Discipline Lead"
                    [collectionItems]="nbDisciplineLeads"
                    [displayIfEmpty]="true"
                >
                </app-chip-list>
                <app-chip-list collectionName="Contractor Engineer" [collectionItems]="nbEngineers" [displayIfEmpty]="true">
                </app-chip-list>
                <app-chip-list
                    collectionName="Work Area TCO Coordinator"
                    [collectionItems]="tcoAreaCoordinators"
                    [displayIfEmpty]="true"
                >
                </app-chip-list>
            </div>

            <app-lookahead-filter-common-section
                *ngIf="!printMode"
                [filterForm]="filterForm"
                [planType]="planType"
                [displayPeriodDays]="displayPeriodDays"
            ></app-lookahead-filter-common-section>
        </mat-expansion-panel>
    </mat-accordion>
</form>

