<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add project team</span></p>
    <mat-form-field class="container300">
        <mat-label>Project Team</mat-label>
        <input [formControl]="projectTeamName" matInput name="projectTeamName" />
        <mat-error *ngIf="projectTeamName.hasError('uniqueProjectTeamName') && !projectTeamName.hasError('required')">
            Project Team already exists.
        </mat-error>
        <mat-error *ngIf="projectTeamName.hasError('required')"> Project Team is <strong>required</strong> </mat-error>
    </mat-form-field>
    <div class="row">
        <button mat-button type="button" class="sct-button sct-button-white" (click)="onCancel()">
            cancel
        </button>
        <button
            mat-button
            type="button"
            (click)="onConfirm()"
            class="sct-button sct-button-light"
            [disabled]="!projectTeamName.valid"
        >
            add project team
        </button>
    </div>
</div>
