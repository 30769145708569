<div class="chart-container">
    <popover-content
        #subsystemDetailsPopover
        [closeOnClickOutside]="true"
        [placement]="popoverPlacement"
        *ngIf="!printMode && subsystemsByWeek.length>0"
        [ngClass]="{
            'medium-level-of-details': levelOfDetails === detailsLevels.Medium,
            'wide-level-of-details': levelOfDetails === detailsLevels.Wide
        }"
    >
        <ng-content *ngTemplateOutlet="tileTemplate; context: { subsystem: selectedSubsystem }"></ng-content>
    </popover-content>
    <popover-content
        #rfoDetailsPopover
        [closeOnClickOutside]="true"
        [placement]="popoverPlacement"
        *ngIf="!printMode && rfosByWeek.length>0"
        [ngClass]="{
            'medium-level-of-details': levelOfDetails === detailsLevels.Medium,
            'wide-level-of-details': levelOfDetails === detailsLevels.Wide
        }"
    >
        <ng-content *ngTemplateOutlet="tileTemplate; context: { subsystem: selectedRFO }"></ng-content>
    </popover-content>
    <div class="week">
        <div class="week-header">&nbsp;</div>
        <div class="week-content">
            <ng-container *ngIf="!isLoading">
                <div
                    class="tile scale"
                    [ngClass]="{
                        'bottom-pad': printMode === true,
                        'most-detailed': levelOfDetails === detailsLevels.High
                    }"
                    *ngFor="let index of maxNumberOfStackedItems"
                >
                    {{ index }}
                </div>
            </ng-container>
        </div>
        <div class="week-footer open-aqvd-desc"
            *ngIf="!isLoading && (skylinePhase === 'mc' || skylinePhase === 'mcwalkdown')"
            [ngClass]="{ wide: levelOfDetails === detailsLevels.Wide }"
        >
            &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
        </div>
        <div class="week-footer open-bcitr-desc"
            *ngIf="!isLoading && (skylinePhase === 'rfsu' || skylinePhase === 'rfsuwalkdown')"
            [ngClass]="{ wide: levelOfDetails === detailsLevels.Wide }"
        >
            &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
        </div>
        <div class="week-footer open-citr-desc" 
            *ngIf="!isLoading && (skylinePhase === 'citr')"
            [ngClass]="{ wide: levelOfDetails === detailsLevels.Wide }">
            &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
        </div>
        <div class="week-footer open-rfo-desc" 
            *ngIf="!isLoading && (skylinePhase === 'rfo')"
            [ngClass]="{ wide: levelOfDetails === detailsLevels.Wide }">
            &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
        </div>
    </div>
    <div
        class="week"
        *ngFor="let week of weeks; index as i"
        [ngClass]="{ current: pastWeeksCount && compareWithCurrentWeek(week.date) && !isLoading }"
    >
        <div class="week-header" *ngIf="levelOfDetails === detailsLevels.Wide">
            W{{ parseWeekNumber(week.date) | slice: 0:parseWeekNumber(week.date).search('/') }}
        </div>
        <div class="week-header" *ngIf="levelOfDetails !== detailsLevels.Wide">W{{ parseWeekNumber(week.date) }}</div>
        <div class="week-content" [ngStyle]="printMode ? { width: '160px' } : { 'width.px': 1224 / weeks.length - 4 }">
            <ng-container *ngIf="(!isLoading && levelOfDetails !== detailsLevels.High) && subsystemsByWeek.length > 0">
                <div
                    *ngFor="let subsystem of subsystemsByWeek[i]; index as j"
                    class="tile"
                    [ngClass]="setBorderClass(subsystem)"
                    [popover]="popover()"
                    [popoverOnHover]="false"
                    [ngStyle]="setBackgroundColor(subsystem)"
                    [style.color]="setFontColor(subsystem)"
                >
                    <div (click)="selectSubsystem(subsystem, i, j)" *ngIf="skylinePhase!='citr'" class="tile-content">
                        {{ subsystem.subsystem }}
                    </div>
                    <div (click)="selectSubsystem(subsystem, i, j)" *ngIf="skylinePhase==='citr'" class="tile-content">
                        ITR: {{ subsystem.qvdNumber }}
                        Tag: {{ subsystem.tagNumber }} 
                        Subsystem : {{ subsystem.subsystem }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(!isLoading && levelOfDetails !== detailsLevels.High) && rfosByWeek.length > 0">
                <div
                    *ngFor="let rfo of rfosByWeek[i]; index as j"
                    class="tile"
                    [ngClass]="setRFOBorderClass(rfo)"
                    [popover]="rfopopover()"
                    [popoverOnHover]="false"
                    [ngStyle]="setRFOBackgroundColor(rfo)"
                    [style.color]="setRFOFontColor(rfo)"
                >
                    <div (click)="selectRfo(rfo, i, j)" *ngIf="skylinePhase=='rfo'" class="tile-content">
                        {{ rfo.rfo }}
                    </div>
                    <!-- <div (click)="selectSubsystem(subsystem, i, j)" *ngIf="skylinePhase==='citr'" class="tile-content">
                        ITR: {{ subsystem.qvdNumber }}
                        Tag: {{ subsystem.tagNumber }} 
                        Subsystem : {{ subsystem.subsystem }}
                    </div> -->
                </div>
            </ng-container>
            <ng-container *ngIf="(!isLoading && levelOfDetails === detailsLevels.High) && subsystemsByWeek.length > 0">
                <div
                    class="tile most-detailed"
                    *ngFor="let subsystem of subsystemsByWeek[i]"
                    [ngClass]="setBorderClass(subsystem)"
                    [ngStyle]="setBackgroundColor(subsystem)"
                    [style.color]="setFontColor(subsystem)"
                >
                    <ng-content *ngTemplateOutlet="tileTemplate; context: { subsystem: subsystem }"></ng-content>
                </div>
            </ng-container>
            <ng-container *ngIf="(!isLoading && levelOfDetails === detailsLevels.High) && rfosByWeek.length > 0">
                <div
                    class="tile most-detailed"
                    *ngFor="let rfo of rfosByWeek[i]"
                    [ngClass]="setRFOBorderClass(rfo)"
                    [ngStyle]="setRFOBackgroundColor(rfo)"
                    [style.color]="setRFOFontColor(rfo)"
                >
                    <ng-content *ngTemplateOutlet="tileTemplate; context: { subsystem: rfo }"></ng-content>
                </div>
            </ng-container>
        </div>
        <div
            class="week-footer wide"
            *ngIf="
                !isLoading &&
                (skylinePhase === 'mc' || skylinePhase === 'mcwalkdown') &&
                levelOfDetails === detailsLevels.Wide
            "
        >
            {{ parseWeekNumber(week.date) | slice: parseWeekNumber(week.date).search('/') + 1 }}<br />
            {{ week.date | date: 'yyyy' }}<br />
            <span class="open-count">{{ getSubsystemsCount(week.date) }}</span
            ><br /><br />
            <span class="open-count">{{ getOpenWeekCount(openAqvdsPerWeek, week.date) }}</span>
        </div>
        <div
            class="week-footer"
            *ngIf="
                !isLoading &&
                (skylinePhase === 'mc' || skylinePhase === 'mcwalkdown') &&
                levelOfDetails !== detailsLevels.Wide
            "
        >
            W{{ parseWeekNumber(week.date) }}<br />
            {{ week.date | date: 'yyyy' }}<br />
            <span class="open-count">{{ getSubsystemsCount(week.date) }}</span
            ><br /><br />
            <span class="open-count">{{ getOpenWeekCount(openAqvdsPerWeek, week.date) }}</span>
        </div>
        <div
            class="week-footer wide"
            *ngIf="
                !isLoading &&
                (skylinePhase === 'rfsu' || skylinePhase === 'rfsuwalkdown') &&
                levelOfDetails === detailsLevels.Wide
            "
        >
            {{ parseWeekNumber(week.date) | slice: parseWeekNumber(week.date).search('/') + 1 }}<br />
            {{ week.date | date: 'yyyy' }}<br />
            <span class="open-count">{{ getSubsystemsCount(week.date) }}</span
            ><br /><br />
            <span class="open-count">{{ getOpenWeekCount(openBitrsPerWeek, week.date) }}</span
            ><br /><br />
            <span class="open-count">{{ getOpenWeekCount(openCitrsPerWeek, week.date) }}</span>
        </div>
        <div
            class="week-footer"
            *ngIf="
                !isLoading &&
                (skylinePhase === 'rfsu' || skylinePhase === 'rfsuwalkdown') &&
                levelOfDetails !== detailsLevels.Wide
            "
        >
            W{{ parseWeekNumber(week.date) }}<br />
            {{ week.date | date: 'yyyy' }}<br />
            <span class="open-count">{{ getSubsystemsCount(week.date) }}</span
            ><br /><br />
            <span class="open-count">{{ getOpenWeekCount(openBitrsPerWeek, week.date) }}</span
            ><br /><br />
            <span class="open-count">{{ getOpenWeekCount(openCitrsPerWeek, week.date) }}</span>
        </div>
        <div
            class="week-footer wide"
            *ngIf="
                !isLoading &&
                (skylinePhase === 'citr') &&
                levelOfDetails === detailsLevels.Wide
            "
        >
            {{ parseWeekNumber(week.date) | slice: parseWeekNumber(week.date).search('/') + 1 }}<br />
            {{ week.date | date: 'yyyy' }}<br /><br />
            <span class="open-count">{{ getSubsystemsCount(week.date) }}</span
            ><br /><br />
            <span class="open-count"></span
            ><br /><br />
            <span class="open-count"></span>
        </div>
        <div class="week-footer" *ngIf="
                !isLoading &&
                (skylinePhase === 'citr' && levelOfDetails !== detailsLevels.Wide)
                ">
                W{{ parseWeekNumber(week.date) }}<br />
                {{ week.date | date: 'yyyy' }}<br /><br />
            <span>{{ getSubsystemsCount(week.date) }}</span>
            <br /><br />
            <span class="open-count"></span>
            <br /><br />
            <span class="open-count"></span>
        </div>
        <div
        class="week-footer wide"
        *ngIf="
            !isLoading &&
            (skylinePhase === 'rfo') &&
            levelOfDetails === detailsLevels.Wide
        "
        >
        {{ parseWeekNumber(week.date) | slice: parseWeekNumber(week.date).search('/') + 1 }}<br />
        {{ week.date | date: 'yyyy' }}<br /><br />
        <span class="open-count">{{ getRFOsCount(week.date) }}</span
        ><br /><br />
        <span class="open-count"></span
        ><br /><br />
        <span class="open-count"></span>
        </div>
        <div class="week-footer" *ngIf="
            !isLoading &&
            (skylinePhase === 'rfo' && levelOfDetails !== detailsLevels.Wide)
            ">
            W{{ parseWeekNumber(week.date) }}<br />
            {{ week.date | date: 'yyyy' }}<br /><br />
        <span>{{ getRFOsCount(week.date) }}</span>
        <br /><br />
        <span class="open-count"></span>
        <br /><br />
        <span class="open-count"></span>
        </div>
    </div>
    <div class="week">
        <div class="week-header">&nbsp;</div>
        <div class="week-content">
            <ng-container *ngIf="!isLoading">
                <div
                    class="tile scale"
                    [ngClass]="{
                        'bottom-pad': printMode === true,
                        'most-detailed': levelOfDetails === detailsLevels.High
                    }"
                    *ngFor="let index of maxNumberOfStackedItems"
                >
                    {{ index }}
                </div>
            </ng-container>
        </div>
        <div
            class="week-footer"
            *ngIf="!isLoading && (skylinePhase === 'mc' || skylinePhase === 'mcwalkdown')"
            [ngClass]="{ wide: levelOfDetails === detailsLevels.Wide }"
        >
            &nbsp;<br />&nbsp;<br />&nbsp;&nbsp;<br />&nbsp;<br />&nbsp;
        </div>
        <div
            class="week-footer"
            *ngIf="!isLoading && (skylinePhase === 'rfsu' || skylinePhase === 'rfo' || skylinePhase === 'rfsuwalkdown')"
            [ngClass]="{ wide: levelOfDetails === detailsLevels.Wide }"
        >
            &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
        </div>
        <div class="week-footer" 
            *ngIf="!isLoading && (skylinePhase === 'citr' )"
            [ngClass]="{ wide: levelOfDetails === detailsLevels.Wide }">
            &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
        </div>
    </div>
    <div *ngIf="isLoading" class="delta-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>

<ng-template #tileTemplate let-subsystem="subsystem">
    <div
        *ngIf="!isLoading && skylinePhase !== 'rfsu' && skylinePhase !== 'rfsuwalkdown' && skylinePhase !== 'citr' && skylinePhase !== 'rfo'"
        class="popover-subsystem red-zone-link"
        (click)="redirectToRedZone(subsystem.subsystem)"
    >
        {{ subsystem ? subsystem.subsystem : '' }}
    </div>
    <div
        *ngIf="!isLoading && (skylinePhase === 'rfsu' || skylinePhase === 'rfsuwalkdown')"
        class="popover-subsystem red-zone-link"
        (click)="redirectToBluezone(subsystem.subsystem)"
    >
        {{ subsystem ? subsystem.subsystem : '' }}
    </div>
    <div *ngIf="!isLoading && (skylinePhase === 'citr')" class="popover-citrdetailsheader">
        C-ITR Details <hr /><hr />
    </div>
    <div class="popover-subsystemname" 
        *ngIf="skylinePhase != 'citr' && skylinePhase != 'rfo'"
        [ngClass]="{ rfsu: skylinePhase === 'rfsu' || skylinePhase === 'rfsuwalkdown'}">
        {{ subsystem ? subsystem.subsystemName : '' }}
    </div>
    <div class="popover-citrdetails" 
        *ngIf="skylinePhase === 'citr'"
        [ngClass]="{ rfsu: skylinePhase === 'citr' }">
        ITR :{{ subsystem ? subsystem.qvdNumber : '' }}
        <hr />
        ITR Description :{{ subsystem ? subsystem.qvdDescription : '' }}
        <hr />
        Barcode :{{ subsystem ? subsystem.barcode : '' }}
        <hr />
        Tag Number :{{ subsystem ? subsystem.tagNumber : '' }}
        <hr />
        SubSystem :{{ subsystem ? subsystem.subsystem : '' }}
    </div>
    <div class="popover-rfoname" 
        *ngIf="skylinePhase === 'rfo'"
        [ngClass]="{ rfo: skylinePhase === 'rfo'}">
        {{ subsystem ? subsystem.rfo : '' }}
    </div>
    <hr />
    <div class="flex" *ngIf="skylinePhase != 'citr' && skylinePhase != 'rfo' && skylineType !== 'commitment'">
        <div>Waypoint</div>
        <div class="popover-selectedss">{{ subsystem ? subsystem.waypoint : '' }}</div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'mc' && (skylineType === 'plan' || skylineType === 'commitment')">
        <div>
            <label>{{ skylineType === 'plan' ? 'Plan' : 'Monthly Commitment' }}</label>
            <div>{{ subsystem ? ((skylineType === 'plan' ? subsystem.mcPlan : subsystem.commitmentDate) | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div *ngIf="subsystem && subsystem.mcActual">
            <label>Actual</label>
            <div>{{ subsystem ? (subsystem.mcActual | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div *ngIf="subsystem && !subsystem.mcActual">
            <label>Forecast</label>
            <div>{{ subsystem ? (subsystem.mcForecast | date: 'd/MMM/yy') : '' }}</div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'rfsu' && (skylineType === 'plan' || skylineType === 'commitment')">
        <div>
            <label>{{ skylineType === 'plan' ? 'Plan' : 'Commitment' }}</label>
            <div>{{ subsystem ? ((skylineType === 'plan' ? subsystem.rfsuPlan : subsystem.commitmentDate) | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div *ngIf="subsystem && subsystem.rfsuActual">
            <label>Actual</label>
            <div>{{ subsystem ? (subsystem.rfsuActual | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div *ngIf="subsystem && !subsystem.rfsuActual">
            <label>Forecast</label>
            <div>{{ subsystem ? (subsystem.rfsuForecast | date: 'd/MMM/yy') : '' }}</div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'rfo' && skylineType === 'plan'">
        <div>
            <label>{{ skylineType === 'plan' ? 'Plan' : 'Commitment' }}</label>
            <div>{{ subsystem ? ((skylineType === 'plan' ? subsystem.rfoPlan : subsystem.commitmentDate) | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div *ngIf="subsystem && subsystem.rfoActual">
            <label>Actual</label>
            <div>{{ subsystem ? (subsystem.rfoActual | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div *ngIf="subsystem && !subsystem.rfoActual">
            <label>Forecast</label>
            <div>{{ subsystem ? (subsystem.rfoForecast | date: 'd/MMM/yy') : '' }}</div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'mc' && skylineType === 'forecast'">
        <div>
            <label>Forecast</label>
            <div>{{ subsystem ? (subsystem.mcForecast | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div>
            <label>Plan</label>
            <div>{{ subsystem ? (subsystem.mcPlan | date: 'd/MMM/yy') : '' }}</div>
        </div>
    </div>
    <div
        class="wlkdwnfrcst"
        *ngIf="(skylinePhase === 'mcwalkdown' || skylinePhase === 'rfsuwalkdown') && skylineType === 'forecast'"
    >
        <div><label>Walkdown Forecast</label></div>
        <div>{{ subsystem ? (subsystem.walkdownForecast | date: 'd/MMM/yy') : '' }}</div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'mcwalkdown' && skylineType === 'forecast'">
        <div>
            <label>MC Forecast</label>
            <div>{{ subsystem ? (subsystem.mcForecast | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div>
            <label>MC Plan</label>
            <div>{{ subsystem ? (subsystem.mcPlan | date: 'd/MMM/yy') : '' }}</div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'rfsuwalkdown' && skylineType === 'forecast'">
        <div>
            <label>RFSU Forecast</label>
            <div>{{ subsystem ? (subsystem.rfsuForecast | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div>
            <label>RFSU Plan</label>
            <div>{{ subsystem ? (subsystem.rfsuPlan | date: 'd/MMM/yy') : '' }}</div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'rfsu' && skylineType === 'forecast'">
        <div>
            <label>Forecast</label>
            <div>{{ subsystem ? (subsystem.rfsuForecast | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div>
            <label>Plan</label>
            <div>{{ subsystem ? (subsystem.rfsuPlan | date: 'd/MMM/yy') : '' }}</div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'rfo' && skylineType === 'forecast'">
        <div>
            <label>Forecast</label>
            <div>{{ subsystem ? (subsystem.rfoForecast | date: 'd/MMM/yy') : '' }}</div>
        </div>
        <div>
            <label>Plan</label>
            <div>{{ subsystem ? (subsystem.rfoPlan | date: 'd/MMM/yy') : '' }}</div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'mc' || skylinePhase === 'mcwalkdown'">
        <div>
            <label>#A-QVD open/closed</label>
            <div>
                {{ subsystem ? subsystem.aqvd : 0 }}/{{ subsystem && subsystem.aqvdClosed ? subsystem.aqvdClosed : 0 }}
            </div>
        </div>
        <div>
            <label>#A-PLI open/closed</label>
            <div>
                {{ subsystem ? subsystem.apli : 0 }}/{{ subsystem && subsystem.apliClosed ? subsystem.apliClosed : 0 }}
            </div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'rfsu' || skylinePhase === 'rfsuwalkdown'">
        <div>
            <label>#B-ITR open/closed</label>
            <div>
                {{ subsystem ? subsystem.bitr : 0 }}/{{ subsystem && subsystem.bitrClosed ? subsystem.bitrClosed : 0 }}
            </div>
        </div>
        <div>
            <label>#C-ITR open/closed</label>
            <div>
                {{ subsystem ? subsystem.citr : 0 }}/{{ subsystem && subsystem.citrClosed ? subsystem.citrClosed : 0 }}
            </div>
        </div>
        <div>
            <label>#RFSU-PLI open/closed</label>
            <div>
                {{ subsystem ? subsystem.apli + subsystem.bpli : 0 }}/{{
                    subsystem && subsystem.apliClosed && subsystem.bpliClosed
                        ? subsystem.apliClosed + subsystem.bpliClosed
                        : 0
                }}
            </div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'rfo' && (skylineType === 'forecast' || skylineType === 'plan')">
        <div>
            <label>Total Number Of Subsystems</label>
            <div>{{ subsystem ? subsystem.numberOfSubsystems : '' }}</div>
        </div>
        <div>
            <label>Number of Subsystems RFSU Completed</label>
            <div>{{ subsystem ? subsystem.numberOfSubsystemsRFSUCompleted : '' }}</div>
        </div>
        <div>
            <label>Number of Subsystems RFSU Remaining</label>
            <div>{{ subsystem ? subsystem.numberOfSubsystemsRFSURemaining : '' }}</div>
        </div>
    </div>
    <div class="flex" *ngIf="skylinePhase === 'rfo' && (skylineType === 'forecast' || skylineType === 'plan')">
        <div>
            <label>Open B-ITRs</label>
            <div>{{ subsystem ? subsystem.openBITR : '' }}</div>
        </div>
        <div>
            <label>Open C-ITRs</label>
            <div>{{ subsystem ? subsystem.openCITR : '' }}</div>
        </div>
    </div>
</ng-template>