import { Injectable } from '@angular/core';
import { ContractorDataPagination, ContractorFilter } from 'src/app/store/data-maintenance/team-data/contractor/model';
import { ContractorDTO } from 'src/app/models/contractor-dto';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ContractorsMaintenanceService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/contractormaintenance';
    }

    get(filter: ContractorFilter) {
        return this.http.post<ContractorDataPagination>(`${this.rootUrl}${this.endpoint}/filter`, filter);
    }

    remove(id: string | number) {
        return this.http.delete<ContractorDataPagination>(`${this.rootUrl}${this.endpoint}?id=${id}`);
    }

    update(contractor: ContractorDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, contractor);
    }

    add(contractor: ContractorDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}`, contractor);
    }

    getDbLinksInfo(id: string) {
        return this.http.get(`${this.rootUrl}${this.endpoint}/getDbLinksInfo/${id}`, { responseType: 'text' });
    }
}
