import { Action } from '@ngrx/store';
import { SubsystemDataPagination } from './model';
import { SubsystemMaintenanceDTO } from 'src/app/components/admin-section/data-maintenance/subsystem-data/subsystem-maintenance-dto';

export enum SubsystemMaintenanceActionTypes {
    SubsystemFilterRequest = '[Subsystem] Filter Request',
    SubsystemFilterSuccess = '[Subsystem] Filter Success',
    SubsystemFilterError = '[Subsystem] Filter Error',
    SubsystemFilterPropertyUpdate = '[Subsystem] Filter Property Update',

    SubsystemSaveRequest = '[Subsystem] Save Subsystem Request',
    SubsystemSaveSuccess = '[Subsystem] Save Subsystem Success',
    SubsystemSaveError = '[Subsystem] Save Subsystem Error',

    SubsystemRemoveRequest = '[Subsystem] Remove Subsystem Request',
    SubsystemRemoveSuccess = '[Subsystem] Remove Subsystem Success',
    SubsystemRemoveError = '[Subsystem] Remove Subsystem Error',
}

export class SubsystemFilterRequest implements Action {
    readonly type = SubsystemMaintenanceActionTypes.SubsystemFilterRequest;
}

export class SubsystemFilterSuccess implements Action {
    readonly type = SubsystemMaintenanceActionTypes.SubsystemFilterSuccess;

    constructor(public payload: SubsystemDataPagination) {}
}

export class SubsystemFilterError implements Action {
    readonly type = SubsystemMaintenanceActionTypes.SubsystemFilterError;

    constructor(public payload: string) {}
}

export class SubsystemFilterPropertyUpdate implements Action {
    readonly type = SubsystemMaintenanceActionTypes.SubsystemFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class SubsystemSaveRequest implements Action {
    readonly type = SubsystemMaintenanceActionTypes.SubsystemSaveRequest;

    constructor(public payload: SubsystemMaintenanceDTO) {}
}

export class SubsystemSaveSuccess implements Action {
    readonly type = SubsystemMaintenanceActionTypes.SubsystemSaveSuccess;
}

export class SubsystemSaveError implements Action {
    readonly type = SubsystemMaintenanceActionTypes.SubsystemSaveError;

    constructor(public payload: string) {}
}

export type SubsystemActions =
    | SubsystemFilterRequest
    | SubsystemFilterSuccess
    | SubsystemFilterError
    | SubsystemFilterPropertyUpdate
    | SubsystemSaveRequest
    | SubsystemSaveSuccess
    | SubsystemSaveError;
