import { Injectable } from '@angular/core';
import { SubsystemMaintenanceDTO } from 'src/app/components/admin-section/data-maintenance/subsystem-data/subsystem-maintenance-dto';
import {
    SubsystemMaintenanceFilter,
    SubsystemDataPagination,
} from 'src/app/store/data-maintenance/subsystem-data/model';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class SubsystemMaintenanceService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/subsystemmaintenance';
    }

    get(filter: SubsystemMaintenanceFilter) {
        return this.http.post<SubsystemDataPagination>(`${this.rootUrl}${this.endpoint}/filter`, filter);
    }

    update(contractor: SubsystemMaintenanceDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}/updateComplex`, contractor);
    }
}
