import { ValidationFormState, ValidationDocument, ValidationDocumentHeader, ValidationDocumentResult } from './model';
import { ValidationFormAction, ValidationFormActionTypes } from './action';
import { cloneDeep } from 'lodash';

const initialState: ValidationFormState = {
    isLoading: false,
    isHeaderLoading: false,
    validationDocument: new ValidationDocument(),
    validationDocumentResult : new ValidationDocumentResult(),
    validationDocumentHeader: new ValidationDocumentHeader(),
    updatedProperties: [],
};

export function reducer(state: ValidationFormState = initialState, action: ValidationFormAction): ValidationFormState {
    switch (action.type) {
        case ValidationFormActionTypes.ValidationUpdateStatusRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ValidationFormActionTypes.ValidationUpdateStatusSuccess: {
            return {
                ...state,
                validationDocumentResult : action.payload,
                isLoading: false,
                updatedProperties: [],
            };
        }
        case ValidationFormActionTypes.ValidationUpdateStatusError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentSuccess: {
            const validationStatus = action.payload.validation.validationStatus
                ? action.payload.validation.validationStatus.toLocaleLowerCase()
                : action.payload.validation.validationStatus;
            return {
                ...state,
                validationDocumentResult: { 
                    ...state.validationDocumentResult,
                    validation: action.payload.validation,
                    validationICT:action.payload.validationICT,
                    validationSMP:action.payload.validationSMP,
                },
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentHeaderRequest: {
            return {
                ...state,
                isHeaderLoading: true,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentHeaderSuccess: {
            return {
                ...state,
                validationDocumentHeader: action.payload,
                isHeaderLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentHeaderError: {
            return {
                ...state,
                isHeaderLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentClear: {
            const newValidation = new ValidationDocumentResult();
            return {
                ...state,
                validationDocumentResult: {
                    ...newValidation,
                },
            };
        }
        case ValidationFormActionTypes.ValidationUpdateRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ValidationFormActionTypes.ValidationUpdateSuccess: {
            let updatedObject = cloneDeep(state.validationDocumentHeader)
            if (action.payload.validation.status) {
                updatedObject.status = action.payload.validation.status;
            }
            if (action.payload.validationICT.status) {
                updatedObject.status = action.payload.validationICT.status;
            }
            if (action.payload.validationSMP.status) {
                updatedObject.status = action.payload.validationSMP.status;
            }

            return {
                ...state,
                validationDocumentHeader :updatedObject,
                validationDocumentResult: action.payload,
                isLoading: false,
                updatedProperties: [],
            };
        }
        case ValidationFormActionTypes.ValidationUpdateError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationUpdateProperty: {
            let updatedObject = cloneDeep(state.validationDocumentResult.validation);
            updatedObject[action.payload.key] = action.payload.value;
            return {
                ...state,
                validationDocumentResult:{
                    ...state.validationDocumentResult,
                    validation :updatedObject
                },
                updatedProperties: action.payload.updatedProperties,
            };
        }
        case ValidationFormActionTypes.ValidationICTUpdateProperty: {
            let updatedObject = cloneDeep(state.validationDocumentResult.validationICT);
            updatedObject[action.payload.key] = action.payload.value;
            return {
                ...state,
                validationDocumentResult:{
                    ...state.validationDocumentResult,
                    validationICT :updatedObject
                },
                updatedProperties: action.payload.updatedProperties,
            };
        }
        case ValidationFormActionTypes.ValidationSMPUpdateProperty: {
            let updatedObject = cloneDeep(state.validationDocumentResult.validationSMP);
            updatedObject[action.payload.key] = action.payload.value;
            return {
                ...state,
                validationDocumentResult:{
                    ...state.validationDocumentResult,
                    validationSMP :updatedObject
                },
                updatedProperties: action.payload.updatedProperties,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentChangesReviewedRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentChangesReviewedError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentChangesReviewedSuccess: {
            return {
                ...state,
                validationDocumentHeader: action.payload,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentChangeIsActiveRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentChangeIsActiveError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentChangeIsActiveSuccess: {
            return {
                ...state,
                validationDocumentHeader: action.payload,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentUpdateIsActiveForDisciplineRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentUpdateIsActiveForDisciplineError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentUpdateIsActiveForDisciplineSuccess: {
            return {
                ...state,
                validationDocumentResult : action.payload,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentSaveSubsystemsRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentSaveSubsystemsSuccess: {
            return {
                ...state,
                validationDocumentHeader: action.payload !== null ? action.payload : state.validationDocumentHeader,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentSaveSubsystemsError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentUpdateSystemizationCompletedRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ValidationFormActionTypes.ValidationDocumentUpdateSystemizationCompletedSuccess: {
            return {
                ...state,
                validationDocumentHeader: action.payload,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
