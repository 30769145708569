<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add milestone</span></p>
    <div>
        <form [formGroup]="addMilestoneForm">
            <mat-form-field class="container300">
                <mat-label>Name</mat-label>
                <textarea formControlName="name" matInput name="name"></textarea>
            </mat-form-field>
            <mat-form-field class="container300">
                <mat-label>Description</mat-label>
                <textarea formControlName="description" matInput name="description"></textarea>
            </mat-form-field>
            <mat-form-field class="container300">
                <mat-label>Waypoint</mat-label>
                <mat-select formControlName="waypointName">
                    <mat-option *ngFor="let w of waypoints" [value]="w.name">
                        {{ w.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="row">
                <button mat-button type="button" class="sct-button sct-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!addMilestoneForm.valid"
                    class="sct-button sct-button-light"
                    (click)="onConfirm()"
                >
                    add
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
