import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { Contractor, Waypoint, Milestone, Gooc, TCOUser, RFO, StagedStartUpPriority } from 'src/app/store/common.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { PlanningFilter } from 'src/app/store/reports/planning/model';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, filter, map, take, catchError } from 'rxjs/operators';
import {
    PlanningLookaheadRequest,
    PlanningLookaheadFilterReset,
    PlanningLookaheadSetWeekRange,
    PlanningLookaheadFilterUpdate,
} from 'src/app/store/reports/planning/actions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { forkJoin } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-reports-planning-lookahead',
    templateUrl: './reports-planning-lookahead.component.html',
    styleUrls: ['./reports-planning-lookahead.component.scss'],
})
export class ReportsPlanningLookaheadComponent extends BaseComponent implements OnInit {
    @ViewChild('dateRangePicker', { static: true }) dateRangePicker: MatDatepicker<moment.Moment>;
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPrioritiesInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    areaCompletionLeads: TCOUser[] = [];

    navLinks: { label: string; link: string; index: number }[];
    isUsersPerProjectLoading = false;
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    autocompleteDisplayedColumns = ['name', 'description'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    projectTeamNamesCurrent: string[];
    prevWaypoints: Waypoint[] = [];
    filterForm: UntypedFormGroup;
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    lookaheadType$ = this.store.select((store) => store.planningState.lookahead.lookaheadType);
    lookaheadType: string;
    scManagers: TCOUser[] = [];
    planningLookaheadFilter$ = this.store.select((store) => store.planningState.lookahead.filter);
    startDate$ = this.store.select((store) => store.planningState.lookahead.filter.startDate);
    startDateValue: moment.Moment = getCurrentWeekStartDate().add(-6, 'weeks');

    constructor(
        private formService: FormService,
        private store: Store<ApplicationState>,
        private lookupService: LookupService,
        private projectTeamsService: ProjectTeamsService,
        private toastService: ToastService
    ) {
        super();
        this.navLinks = [
            // {
            //     label: 'mc lookahead',
            //     link: './mc',
            //     index: 0,
            // },
            {
                label: 'rfsu lookahead',
                link: './rfsu',
                index: 1,
            },
        ];
        this.filterForm = this.formService.createSimpleForm(new PlanningFilter());
        this.dateRangePickerControl = new UntypedFormControl();
        this.dateRangePickerControlFormatted = new UntypedFormControl({
            value: this.formatDateRange(this.startDateValue),
            disabled: true,
        });
    }

    ngOnInit() {
        this.filterExpansionPanel.expanded = true;
        this.lookaheadType$.pipe(takeWhile(() => this.isAlive)).subscribe((lookaheadType) =>
            setTimeout(() => {
                this.lookaheadType = lookaheadType;
            })
        );

        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.planningLookaheadFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam(filter.projectTeamNames || []);
        });

        this.filterForm.controls.waypoints.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((waypoints: Waypoint[]) => {
                this.prevWaypoints = waypoints;
                this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
            });

        this.filterForm.controls.milestones.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((milestones: Milestone[]) => {
                let waypoints = JSON.parse(JSON.stringify(milestones));
                waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
                waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name');
                this.setMilestoneInput.emit(new SetInputEventArgs(false, '', milestones));
                if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) {
                    this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                    this.filterForm.controls.waypoints.setValue(waypoints);
                }
            });

        this.startDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startDate) => !!startDate)
            )
            .subscribe((startDate) => {
                this.dateRangePickerControl.setValue(moment(startDate).toDate(), { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(startDate)));
            });

        this.dateRangePickerControl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((date: moment.Moment) => {
                this.store.dispatch(new PlanningLookaheadSetWeekRange(date));
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
            });

        this.lookupService
            .getACLeads([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads));
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames);
    };

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMilestones(
            search,
            take,
            page,
            this.filterForm.value.waypoints,
            this.filterForm.value.projectTeamNames
        );
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((prio: StagedStartUpPriority[]) => prio));
    };

    onStagedStartUpPrioritiesClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    displayMultipleSelected(values: (Milestone | Waypoint)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    search() {
        this.updateFilterByFormProperties();
        this.store.dispatch(new PlanningLookaheadRequest());
    }

    resetFilters() {
        this.store.dispatch(new PlanningLookaheadFilterReset());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPrioritiesInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.stagedStartUpPriorities.setValue([]);
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    dateRangePickerFilter = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.projectTeamNames)
            .pipe(map((RFOs: RFO[]) => RFOs));
    };

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    private formatDateRange(startDate: moment.Moment) {
        const endDate = moment(startDate).add(9, 'weeks').add(6, 'days');
        return `${moment(startDate).format('D MMM')} - ${moment(endDate).format('D MMM YYYY')}`;
    }

    private updateFilterByFormProperties() {
        let newFilter = new PlanningFilter();
        for (const key of Object.keys(this.filterForm.controls)) {
            if (key !== 'startDate' && key !== 'endDate') {
                const value = this.filterForm.controls[key].value;
                newFilter[key] = this.filterForm.controls[key].value;
            }
        }

        this.store.dispatch(new PlanningLookaheadFilterUpdate(newFilter));
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    private checkIfSelectedUsersValid(dropdownValues: any[], formControlName: string, property: string) {
        let valid =
            this.filterForm.controls[formControlName].value.length &&
            this.filterForm.controls[formControlName].value.filter(
                (userId) => dropdownValues.map((s) => s[property]).indexOf(userId) !== -1
            );
        if (valid) {
            this.filterForm.controls[formControlName].setValue(valid);
        }
    }

    private getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'milestones');
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'waypoints');
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'contractors');
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'rfos');
        forkJoin([
            this.lookupService.getMCEngineers(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getSysOwners(this.filterForm.controls.projectTeamNames.value || []),
            this.lookupService.getSCManagers(this.filterForm.controls.projectTeamNames.value || []),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners, scManagers]) => {
                    this.mcEngineers = mcEngineers;
                    this.checkIfSelectedUsersValid(this.mcEngineers, 'mcEngineer', 'id');
                    this.sysOwners = sysOwners;
                    this.checkIfSelectedUsersValid(this.sysOwners, 'systemOwner', 'id');
                    this.scManagers = scManagers;
                    this.checkIfSelectedUsersValid(this.scManagers, 'scManager', 'id');
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }
}
