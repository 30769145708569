import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { ExceptionsUploadDeletePagination } from './model';

export enum ExceptionsUploadActionTypes {
    ExceptionsUploadTemplateFileRequest = '[ExceptionsUpload] Template File Request',
    ExceptionsUploadTemplateFileSuccess = '[ExceptionsUpload] Template File Success',
    ExceptionsUploadTemplateFileError = '[ExceptionsUpload] Template File Error',
    ExceptionsUploadLogRequest = '[ExceptionsUpload] Upload Log Request',
    ExceptionsUploadLogSuccess = '[ExceptionsUpload] Upload Log Success',
    ExceptionsUploadLogError = '[ExceptionsUpload] Upload Log Error',
    ExceptionsUploadDeleteRecordsRequest = '[ExceptionsUpload] Delete Records Request',
    ExceptionsUploadDeleteRecordsSuccess = '[ExceptionsUpload] Delete Records Success',
    ExceptionsUploadDeleteRecordsError = '[ExceptionsUpload] Delete Records Error',
    ExceptionsUploadSetStatusAndStartDate = '[ExceptionsUpload] Set Status and Start Date',
    ExceptionsUploadDeleteFilterPropertyUpdate = '[ExceptionsUpload] Delete Filter Property Update',
    ExceptionsUploadDeleteFilterReset = '[ExceptionsUpload] Delete Filter Reset',
    ExceptionsUploadPatchDeleteRecordRequest = '[ExceptionsUpload] Patch Delete Record Request',
    ExceptionsUploadPatchDeleteRecordSuccess = '[ExceptionsUpload] Patch Delete Record Success',
    ExceptionsUploadPatchDeleteRecordError = '[ExceptionsUpload] Patch Delete Record Error',
    ExceptionsUploadPatchAllDeleteRecordsRequest = '[ExceptionsUpload] Patch All Delete Records Request',
    ExceptionsUploadPatchAllDeleteRecordsSuccess = '[ExceptionsUpload] Patch All Delete Records Success',
    ExceptionsUploadPatchAllDeleteRecordsError = '[ExceptionsUpload] Patch All Delete Records Error',
    ExceptionsUploadValidateDataRequest = '[ExceptionsUpload] Validate Data Request',
    ExceptionsUploadValidateDataSuccess = '[ExceptionsUpload] Validate Data Success',
    ExceptionsUploadValidateDataError = '[ExceptionsUpload] Validate Data Error',
    ExceptionsUploadFileUploaded = '[ExceptionsUpload] File Uploaded',
    ExceptionsUploadClearInProgressSpinner = '[ExceptionsUpload] Clear In Progress Spinner',
    ExceptionsUploadDownloadOutputDataRequest = '[ExceptionsUpload] Download Output Data Request',
    ExceptionsUploadDownloadOutputDataSuccess = '[ExceptionsUpload] Download Output Data Success',
    ExceptionsUploadDownloadOutputDataError = '[ExceptionsUpload] Download Output Data Error',
    ExceptionsUploadValidateButtonStateRequest = '[ExceptionsUpload] Validate Button State Request',
    ExceptionsUploadValidateButtonStateSuccess = '[ExceptionsUpload] Validate Button State Success',
    ExceptionsUploadValidateButtonStateError = '[ExceptionsUpload] Validate Button State Error',
    ExceptionsUploadAddStartedLog = '[ExceptionsUpload] Add Started Log',
}

export class ExceptionsUploadTemplateFileRequest implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ExceptionsUploadTemplateFileSuccess implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ExceptionsUploadTemplateFileError implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadTemplateFileError;

    constructor(public payload: string) {}
}

export class ExceptionsUploadAddStartedLog implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadAddStartedLog;
}

export class ExceptionsUploadLogRequest implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadLogRequest;
}

export class ExceptionsUploadLogSuccess implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ExceptionsUploadLogError implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadLogError;

    constructor(public payload: string) {}
}

export class ExceptionsUploadDeleteRequest implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadDeleteRecordsRequest;
}

export class ExceptionsUploadDeleteSuccess implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadDeleteRecordsSuccess;

    constructor(public payload: ExceptionsUploadDeletePagination) {}
}

export class ExceptionsUploadDeleteError implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ExceptionsUploadSetStatusAndStartDate implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class ExceptionsUploadDeleteFilterPropertyUpdate implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ExceptionsUploadDeleteFilterReset implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadDeleteFilterReset;
}

export class ExceptionsUploadPatchDeleteRequest implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadPatchDeleteRecordRequest;

    constructor(public payload: { itrpliNumber: string; deleteState: boolean }) {}
}

export class ExceptionsUploadPatchAllDeleteRequest implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ExceptionsUploadPatchDeleteSuccess implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadPatchDeleteRecordSuccess;
}

export class ExceptionsUploadPatchAllDeleteSuccess implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ExceptionsUploadPatchDeleteError implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class ExceptionsUploadPatchAllDeleteError implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ExceptionsUploadValidateDeleteRequest implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadValidateDataRequest;
}

export class ExceptionsUploadValidateDeleteSuccess implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadValidateDataSuccess;
}

export class ExceptionsUploadValidateDeleteError implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadValidateDataError;

    constructor(public payload: string) {}
}

export class ExceptionsUploadFileUploaded implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadFileUploaded;
}

export class ExceptionsUploadClearInProgressSpinner implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadClearInProgressSpinner;
}


export class ExceptionsUploadDownloadOutputDataRequest implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ExceptionsUploadDownloadOutputDataSuccess implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ExceptionsUploadDownloadOutputDataError implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ExceptionsUploadValidateButtonStateRequest implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadValidateButtonStateRequest;
}

export class ExceptionsUploadValidateButtonStateSuccess implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class ExceptionsUploadValidateButtonStateError implements Action {
    readonly type = ExceptionsUploadActionTypes.ExceptionsUploadValidateButtonStateError;

    constructor(public payload: string) {}
}

export type ExceptionsUploadActions =
    | ExceptionsUploadTemplateFileRequest
    | ExceptionsUploadTemplateFileSuccess
    | ExceptionsUploadTemplateFileError
    | ExceptionsUploadLogRequest
    | ExceptionsUploadLogSuccess
    | ExceptionsUploadLogError
    | ExceptionsUploadDeleteRequest
    | ExceptionsUploadDeleteSuccess
    | ExceptionsUploadDeleteError
    | ExceptionsUploadSetStatusAndStartDate
    | ExceptionsUploadDeleteFilterPropertyUpdate
    | ExceptionsUploadDeleteFilterReset
    | ExceptionsUploadPatchDeleteRequest
    | ExceptionsUploadPatchDeleteSuccess
    | ExceptionsUploadPatchDeleteError
    | ExceptionsUploadPatchAllDeleteRequest
    | ExceptionsUploadPatchAllDeleteSuccess
    | ExceptionsUploadPatchAllDeleteError
    | ExceptionsUploadValidateDeleteRequest
    | ExceptionsUploadValidateDeleteSuccess
    | ExceptionsUploadValidateDeleteError
    | ExceptionsUploadFileUploaded
    | ExceptionsUploadClearInProgressSpinner
    | ExceptionsUploadDownloadOutputDataRequest
    | ExceptionsUploadDownloadOutputDataSuccess
    | ExceptionsUploadDownloadOutputDataError
    | ExceptionsUploadValidateButtonStateRequest
    | ExceptionsUploadValidateButtonStateSuccess
    | ExceptionsUploadValidateButtonStateError
    | ExceptionsUploadAddStartedLog;
