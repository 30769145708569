import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { FormControl } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-sc-exclude-configuration',
    templateUrl: './sc-exclude-configuration.component.html',
    styleUrls: ['./sc-exclude-configuration.component.scss'],
})
export class ScExcludeConfigurationComponent extends BaseComponent implements OnInit, AfterViewChecked {
    isLoading$ = this.store.select((state) => state.planningConfigState.isLoading).pipe(takeWhile(() => this.isAlive));
    selected = new FormControl(0);

    constructor(private store: Store<ApplicationState>, private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        // This is intentional
    }

    ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
    }
}
