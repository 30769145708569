import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImportType } from 'src/app/components/admin-section/import-type';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class LiveDataService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly liveDataEndpoint: string = '/livedata';

    constructor(private http: HttpClient) {}

    downloadLiveDataAsync(importType: ImportType) {
        return this.http.get(`${this.rootUrl}${this.liveDataEndpoint}/${importType}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }
}
