import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { Injectable } from '@angular/core';
import { ActivityPriorityDTO } from 'src/app/store/common.model';

@Injectable({
    providedIn: 'root',
})
export class ActivityFocusService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly activityFocusConfigurationEndpoint: string = '/Activityfocusconfiguration';
    constructor(private http: HttpClient) {}


    updateActivityFocus(activityFocus: ActivityPriorityDTO): Observable<any> {
        return this.http.put(`${this.rootUrl}${this.activityFocusConfigurationEndpoint}`, activityFocus);
    }

    getActivityFocusByName(priorityName: string) {
        const name = encodeURIComponent(priorityName);
        return this.http.get<ActivityPriorityDTO>(`${this.rootUrl}${this.activityFocusConfigurationEndpoint}?name=${name}`);
    }

}
