import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';

export interface RFOImportState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deltaData: RFODeltaPagination;
    isDeltaDataLoading: boolean;
    deleteData: RFODeletePagination;
    isDeleteDataLoading: boolean;
    deltaFilter: RFODeltaFilter;
    deleteFilter: RFODeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class RFODeltaDTO {
    rfoId: string;
    rfoNameLive: string;
    rfoNameImport: string;
    rfoPlanLive: string;
    rfoPlanImport: string;
    rfoActualLive: string;
    rfoActualImport: string;
    projectTeamLive: string;
    projectTeamImport: string;
    deltaState: boolean;
}

export class RFODeleteDTO {
    rfoId: string;
    RFOName: string;
    RFOPlan: string;
    RFOActual: string;
    projectTeam: string;
    deleteState: boolean;
}

export class RFODeltaFilter {
    page = 0;
    take = 10;
}

export class RFODeleteFilter {
    page = 0;
    take = 10;
}

export class RFODeltaPagination {
    items: RFODeltaDTO[] = [];
    totalCount = 0;
}

export class RFODeletePagination {
    items: RFODeleteDTO[] = [];
    totalCount = 0;
}
