<mat-expansion-panel
    #expansionPanel="matExpansionPanel"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"    
>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h3>Rundown Chart</h3>
        </mat-panel-title>
        <mat-panel-description *ngIf="!panelOpenState">Expand panel to view details.</mat-panel-description>
    </mat-expansion-panel-header>
    <div class="rundown-container">
        <div class="slide-container">
            <mat-slide-toggle [color]="'primary'" [checked]="monthViewEnabled" (toggleChange)="toggleMonthView()">
                show monthly view
            </mat-slide-toggle>
        </div>
        <ngx-charts-line-chart
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [results]="chartData"
            [roundDomains]="true"
            [legend]="true"
            [xAxisLabel]="monthViewEnabled ? 'Month Ending' : 'Week Ending'"
            yAxisLabel="% Remaining"
            [autoScale]="true"
        >
        </ngx-charts-line-chart>
    </div>
    <div *ngIf="isLoading$ | async" class="rundown-loader">
        <div class="lds-hourglass"></div>
    </div>
</mat-expansion-panel>
