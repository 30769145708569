import * as moment from 'moment';
import { OrderDirection, CheckListColumn, CalendarColumn, UserDetail } from '../common.model';

export class ExportPageFilter {
    page = 0;
    take = 10;
    sortBy = 'date';
    direction: OrderDirection = OrderDirection.Desc;
    columnTypes: CheckListColumn = null;
    columnAuthors: CheckListColumn = null;
    columnDates: CalendarColumn = null;
    unreadOnly: boolean = true;
}

export class ExportPageDTO {
    blobName: string;
    created: string;
    eventType: string;
    fileName: string;
    status: string;
    updated: string;
}



export interface ExportPageState {
    dataPagination: ExportPageDataPagination;
    filter: ExportPageFilter;
    isLoading: boolean;
}

export class ExportPageDataPagination {
    items: ExportPageDTO[] = [];
    totalCount = 0;
}
