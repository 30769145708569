<div class="chart-container">
    <div *ngIf="commitmentReliabilityType === 'itr' || commitmentReliabilityType === 'rfsu'" class="bar-chart-container">
        <app-custom-grouped-vertical-stacked-chart
            [view]="[1200, 300]"
            [scheme]="!planViewEnabled && commitmentReliabilityType === 'rfsu' ? colorSchemeItrWithoutPlan : colorSchemeItr"
            [results]="chartItrVerticalData$ | async"
            [stackChartResult]="chartItrStackedData$ | async"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [showLegend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [roundDomains]="true"
            [yAxisTickFormatting]="axisFormat"
            [roundEdges]="false"
            [barPadding]="1"
        >
        </app-custom-grouped-vertical-stacked-chart>
    </div>
    <div *ngIf="commitmentReliabilityType !== 'itr' && commitmentReliabilityType !== 'rfsu'" class="bar-chart-container">
        <ngx-charts-bar-vertical-2d
            [view]="[1200, 300]"
            [scheme]="colorSchemeNotItr"
            [results]="chartNotItrData$ | async"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [roundDomains]="true"
            [yAxisTickFormatting]="axisFormat"
            [roundEdges]="false"
            [barPadding]="1"
        >
        </ngx-charts-bar-vertical-2d>
    </div>

    <div *ngIf="commitmentReliabilityType === 'itr'" class="line-chart-legend" hidden>
        <div class="line-chart-legend-item">
            <div class="legend-item-indicator blue"></div>
            Working to Schedule = total committed ITRs per schedule / total commitments
        </div>
        <div class="line-chart-legend-item">
            <div class="legend-item-indicator green"></div>
            Commitment Reliability = total achieved ITRs in commitment / total commitments
        </div>
    </div>
    <div class="line-chart-container padding-left" hidden>
        <ngx-charts-line-chart
            [view]="[1100, 300]"
            [scheme]="colorSchemeLines"
            [results]="lineData$ | async"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisTickFormatting]="yAxisTickPercentage"
            [roundDomains]="true"
            [yScaleMin]="0"
            [yScaleMax]="100"
        >
        <ng-template #tooltipTemplate let-model="model">                            
            <span>{{ model.series }} - {{ model.name }}: {{ model.value }} %</span>
        </ng-template>
        <ng-template #seriesTooltipTemplate let-model="model">
            <p *ngFor="let serie of model; let index = index" class="chart-series-tooltip-body">
                <span class="chart-series-color" [style.background-color]="serie.color"></span>
                <span>{{ serie.series }} : {{ serie.value }}%</span>
            </p>
        </ng-template>
        </ngx-charts-line-chart>
    </div>
</div>
