<main class="main">
    <a class="navigator doNotPrint" [routerLink]="['/changemanagement']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">SCMT Change Management</div>
        <div class="buttons-container flex">
            <button type="button" class="sct-button sct-button-light margin-right20 doNotPrint" (click)="onPrint()">
                print
            </button>
        </div>
        <div class="title">subsystem - field change summary {{ subsystem }}</div>
    </div>
    <div class="fieldchange-container">
        <h4>Changes In Engineering</h4>
        <table
            mat-table
            #inEngineeringSort="matSort"
            matSortActive="number"
            matSortDirection="desc"
            [dataSource]="inEngineeringSource"
            matSort
        >
            <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header="number" disableClear *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element">{{ element.number }}</td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let element">{{ element.title }}</td>
            </ng-container>
            <ng-container matColumnDef="discipline">
                <th mat-header-cell *matHeaderCellDef>Discipline</th>
                <td mat-cell *matCellDef="let element">{{ element.discipline }}</td>
            </ng-container>
            <ng-container matColumnDef="contract">
                <th mat-header-cell *matHeaderCellDef>Contractor</th>
                <td mat-cell *matCellDef="let element">{{ element.contractNo }}</td>
            </ng-container>
            <ng-container matColumnDef="exceptionNumber">
                <th mat-header-cell *matHeaderCellDef>Exception Number</th>
                <td mat-cell *matCellDef="let element">{{ element.exceptionNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef>Comments</th>
                <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="inEngineeringColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: inEngineeringColumns"></tr>
        </table>
        <h4>Changes To Implement</h4>
        <table
            mat-table
            #toImplementSort="matSort"
            matSortActive="number"
            matSortDirection="desc"
            [dataSource]="toImplementSource"
            matSort
        >
            <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header="number" disableClear *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element">{{ element.number }}</td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let element">{{ element.title }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Implementation status</th>
                <td mat-cell *matCellDef="let element">{{ element.status }}</td>
            </ng-container>
            <ng-container matColumnDef="discipline">
                <th mat-header-cell *matHeaderCellDef>Discipline</th>
                <td mat-cell *matCellDef="let element">{{ element.discipline }}</td>
            </ng-container>
            <ng-container matColumnDef="contract">
                <th mat-header-cell *matHeaderCellDef>Contractor</th>
                <td mat-cell *matCellDef="let element">{{ element.contractNo }}</td>
            </ng-container>
            <ng-container matColumnDef="exceptionNumber">
                <th mat-header-cell *matHeaderCellDef>Exception Number</th>
                <td mat-cell *matCellDef="let element">{{ element.exceptionNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef>Comments</th>
                <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="toImplementColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: toImplementColumns"></tr>
        </table>
        <h4>Changes No Physical Work</h4>
        <table
            mat-table
            #closedSort="matSort"
            matSortActive="number"
            matSortDirection="desc"
            [dataSource]="closedSource"
            matSort
        >
            <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header="number" disableClear *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element">{{ element.number }}</td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let element">{{ element.title }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Implementation status</th>
                <td mat-cell *matCellDef="let element">{{ element.status }}</td>
            </ng-container>
            <ng-container matColumnDef="discipline">
                <th mat-header-cell *matHeaderCellDef>Discipline</th>
                <td mat-cell *matCellDef="let element">{{ element.discipline }}</td>
            </ng-container>
            <ng-container matColumnDef="contract">
                <th mat-header-cell *matHeaderCellDef>Contractor</th>
                <td mat-cell *matCellDef="let element">{{ element.contractNo }}</td>
            </ng-container>
            <ng-container matColumnDef="exceptionNumber">
                <th mat-header-cell *matHeaderCellDef>Exception Number</th>
                <td mat-cell *matCellDef="let element">{{ element.exceptionNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef>Comments</th>
                <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="closedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: closedColumns"></tr>
        </table>
    </div>
    <!--
    <div class="legend-footer">
        <span> In Engineering: </span>Change Document is still under development with the Engineering/Project
        Engineering Team and not IFC.<br />

        <span>Changes to Implement: </span>Change Document has been issued IFC.<br />
        <span>Changes No Physical Work: </span>Change Document has been Cancelled or issued IFC with no field execution work.
    </div>
    -->
    <div class="timestamp">
        <span>Timestamp: </span>{{ now | date: 'medium'}}
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>
