import { Injectable } from '@angular/core';
import { RoleService } from './role.service';
import { Constants } from 'src/app/constants';
import { LookupService } from '../api/webapi-services/lookup.service';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProjectTeamsService {
    constructor(private roleService: RoleService, private lookupService: LookupService) {}

    getTeams(readonly = true): Observable<string[]> {
        const teams = new Array();

        return this.lookupService.getProjectTeamNames().pipe(
            map((projectTeamNames) => {
                if (
                    this.roleService.isInRole(Constants.applicableGroups.Admin) ||
                    (this.roleService.isReadOnly() && readonly)
                ) {
                    return projectTeamNames;
                } else {
                    if (
                        projectTeamNames.indexOf(Constants.projectTeamNames.GP) > -1 &&
                        (this.roleService.isInRole(Constants.applicableGroups.MCEngineer3GP) ||
                            this.roleService.isInRole(Constants.applicableGroups.Validator3GP))
                    ) {
                        teams.push(Constants.projectTeamNames.GP);
                    }
                    if (
                        projectTeamNames.indexOf(Constants.projectTeamNames.GI) > -1 &&
                        (this.roleService.isInRole(Constants.applicableGroups.MCEngineer3GI) ||
                            this.roleService.isInRole(Constants.applicableGroups.Validator3GI))
                    ) {
                        teams.push(Constants.projectTeamNames.GI);
                    }
                }

                return teams;
            })
        );
    }

    getValidatorTeams(): Observable<string[]> {
        var teams = new Array();
        return this.lookupService.getProjectTeamNames().pipe(
            map((projectTeamNames) => {
                if (this.roleService.isInRole(Constants.applicableGroups.Admin)) {
                    return projectTeamNames;
                } else {
                    if (
                        projectTeamNames.indexOf(Constants.projectTeamNames.GP) > -1 &&
                        this.roleService.isInRole(Constants.applicableGroups.Validator3GP)
                    )
                        teams.push(Constants.projectTeamNames.GP);
                    if (
                        projectTeamNames.indexOf(Constants.projectTeamNames.GI) > -1 &&
                        this.roleService.isInRole(Constants.applicableGroups.Validator3GI)
                    )
                        teams.push(Constants.projectTeamNames.GI);
                }
                return teams;
            })
        );
    }
}
