import { Action } from '@ngrx/store';

export enum PlanningUploadActionTypes {
    PlanningUploadSetActivatedRoute = '[Planning Upload] Set Activated Route',
    PlanningUploadFilesUploadRequest = '[Planning Upload] Files Upload Request',
    PlanningUploadFilesUploadSuccess = '[Planning Upload] Files Upload Success',
    PlanningUploadFilesUploadError = '[Planning Upload] Files Upload Error',
}

export class PlanningUploadSetActivatedRoute implements Action {
    readonly type = PlanningUploadActionTypes.PlanningUploadSetActivatedRoute;

    constructor(public payload: string) {}
}

export class PlanningUploadFilesUploadRequest implements Action {
    readonly type = PlanningUploadActionTypes.PlanningUploadFilesUploadRequest;

    constructor(public payload: FormData) {}
}

export class PlanningUploadFilesUploadSuccess implements Action {
    readonly type = PlanningUploadActionTypes.PlanningUploadFilesUploadSuccess;
}

export class PlanningUploadFilesUploadError implements Action {
    readonly type = PlanningUploadActionTypes.PlanningUploadFilesUploadError;

    constructor(public payload: string) {}
}

export type PlanningUploadActions =
    | PlanningUploadSetActivatedRoute
    | PlanningUploadFilesUploadRequest
    | PlanningUploadFilesUploadSuccess
    | PlanningUploadFilesUploadError;
