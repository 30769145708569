import { Component, OnInit, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { MatExpansionPanel } from '@angular/material/expansion';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastService } from 'src/app/services/shared/toast.service';
import { TagSearchDTO, TagSearchFilter } from 'src/app/store/tag-search/model';
import { FormService } from 'src/app/services/shared/form.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { select, Store } from '@ngrx/store';
import {
    TagSearchFilterPropertyUpdate,
    TagSearchFilterRequest,
    TagSearchFilterReset,
    TagSearchExportToExcelRequest,
    TagSearchHeaderFilterPropertyUpdate,
} from 'src/app/store/tag-search/actions';
import { takeWhile, map, take } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import * as _ from 'lodash';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { OrderDirection, CheckListColumn, RFO } from 'src/app/store/common.model';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { of } from 'rxjs';
import { Constants } from 'src/app/constants';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'app-tag-search',
    templateUrl: './tag-search.component.html',
    styleUrls: ['./tag-search.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TagSearchComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    isLoading = false;
    data: TagSearchDTO[] = [];
    resultsLength = 0;
    pageSize = 25;
    tagSearchGridData$ = this.store.select((state) => state.tagSearchState.dataPagination);
    tagSearchGridLoader$ = this.store.select((state) => state.tagSearchState.isLoading);
    tagSearchFilter$ = this.store.select((state) => state.tagSearchState.filter);
    sortBy$ = this.store.select((state) => state.tagSearchState.filter.sortBy);
    sortDirection$ = this.store.select((state) => state.tagSearchState.filter.direction);
    columnTagNo: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnServiceDescription: CheckListColumn = null;
    columnSubValidateComment: CheckListColumn = null;
    columnProjectTeamName: CheckListColumn = null;
    columnTagStatus: CheckListColumn = null;
    columnExInspectReqd: CheckListColumn = null;
    columnSubsystemsList: CheckListColumn = null;
    sortBy = '';
    direction: OrderDirection = OrderDirection.Desc;

    setTagInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    tagTypes: string[] = [];
    projectTeamNames: string[] = [];
    tagStatuses: string[] = [];
    columnsToDisplay = [
        'tagNo',
        'tagType',
        'tagName',
        'serviceDescription',
        'subValidateComment',
        'tagStatus',
        'projectTeamName',
        'exInspectReqd',
        'discipline',
        'subsystemsList',
    ];
    columnsToDisplayITR = ['type', 'number', 'description', 'status', 'contractor'];
    ITRColumnNames = {
        type: 'QVD/ITR Type',
        number: 'QVD/ITR No',
        description: 'QVD/ITR Description',
        status: 'QVD/ITR Status',
        contractor: 'Contractor',
    };
    columnsToDisplayPLI = ['number', 'description', 'category', 'status', 'contractor'];
    PLIColumnNames = {
        number: 'PLI No',
        description: 'PLI Description',
        status: 'PLI Status',
        contractor: 'Contractor',
        category: 'Category',
    };
    columnsToDisplayChangeDoc = ['number', 'changeType', 'title', 'contractor', 'status', 'signOffStatus'];
    ChangeDocColumnNames = {
        number: 'Change ID',
        changeType: 'Change Type',
        title: 'Title',
        contractor: 'Contractor',
        status: 'Change Status',
        signOffStatus: 'Field Implementation Status',
    };
    subsystemAutocompleteDisplayedColumns = ['id', 'name'];
    expandedElement: TagSearchDTO | null;
    tableTypes = Constants.tagSearchTableType;

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private toastService: ToastService,
        private projectTeamsService: ProjectTeamsService,
        private popupSvc: PopupService
    ) {
        super();

        this.filterForm = this.formSvc.createSimpleForm(new TagSearchFilter());
    }

    ngOnInit() {
        //this.filterExpansionPanel.expanded = true;
        this.tagSearchGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items.map((item) => ({ ...item, showPLIs: false, showITRs: false }));
            this.resultsLength = data.totalCount;
        });

        this.tagSearchFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.paginator.pageIndex = filter.page;
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => {
            this.sortBy = sortBy;
        });

        this.sortDirection$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => {
            this.direction = direction;
        });

        this.lookupService
            .getTagTypes()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (tagTypes: string[]) => (this.tagTypes = tagTypes),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting tag types. Please contact Program Administrator.'
                    );
                }
            );

        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.tagStatuses = ['ACTIVE'];

        this.filterForm.controls.tagStatuses.patchValue(['ACTIVE']);
        this.filterForm.controls.tagStatuses.disable();

        this.tagSearchGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });
        this.registerColumnHeaderFilters();

        this.store.dispatch(new TagSearchFilterRequest());
    }

    getTags = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchTags(search, take, page, []).pipe(
            takeWhile(() => this.isAlive),
            map((tags: string[]) =>
                tags.map((s) => {
                    return { id: s };
                })
            )
        );
    };

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    private getLatestFilterData(): TagSearchFilter {
        let filter: TagSearchFilter;
        this.store.pipe(select((x) => x.tagSearchState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getTagNumbers = (search = '', take = 30, page = 0) =>
        this.lookupService.searchTagNumbersWithTagSearchFilter(search, take, page, this.getLatestFilterData());

    getTagTypes = (search = '', take = 30, page = 0) =>
        this.lookupService.searchTagTypesWithTagSearchFilter(search, take, page, this.getLatestFilterData());

    getDisciplines = (search = '', take = 30, page = 0) =>
        this.lookupService.searchDisciplinesWithTagSearchFilter(search, take, page, this.getLatestFilterData());

    getTagNames = (search = '', take = 30, page = 0) =>
        this.lookupService.searchTagNamesWithTagSearchFilter(search, take, page, this.getLatestFilterData());
    
    getServiceDescription = (search = '', take = 30, page = 0) =>
        this.lookupService.searchServiceDescriptionWithTagSearchFilter(search, take, page, this.getLatestFilterData());

    getSubValidateComment = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubValidateCommentWithTagSearchFilter(search, take, page, this.getLatestFilterData());

    getTagStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchTagStatusesWithTagSearchFilter(search, take, page, this.getLatestFilterData());

    getProjectTeamNames = (search = '', take = 30, page = 0) =>
        this.lookupService.searchProjectTeamsWithTagSearchFilter(search, take, page, this.getLatestFilterData());

    getExInspections = (search = '', take = 30, page = 0) =>
        this.lookupService.searchExInspectionsWithTagSearchFilter(search, take, page, this.getLatestFilterData());

    getSubsystemsList = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSubsystemsWithTagSearchFilter(search, take, page, this.getLatestFilterData());
    
    getAreaBreakdown = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    
    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new TagSearchFilterReset());
        this.setTagInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.setValue({ ...new TagSearchFilter(), tagStatuses: ['ACTIVE'] });
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
    }

    exportToExcel() {
        this.store.dispatch(new TagSearchExportToExcelRequest());
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
        this.store.dispatch(
            new TagSearchFilterPropertyUpdate({
                key: propertyName,
                value: [],
            })
        );
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (key !== 'sortBy' && key !== 'direction') {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new TagSearchFilterPropertyUpdate({ key, value }));
            }
        }
    }

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    search() {
        this.updateFilterByFormProperties();
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new TagSearchFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(
            new TagSearchFilterPropertyUpdate({
                key: 'take',
                value: this.pageSize,
            })
        );
        this.store.dispatch(new TagSearchFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new TagSearchFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new TagSearchFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new TagSearchFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    private sortUpdate(
        isAscendingSort: boolean,
        isDescendingSort: boolean,
        columnName: string,
        tagNo: string,
        tagType: string,
        type: string
    ) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new TagSearchHeaderFilterPropertyUpdate({
                    key: 'sortBy',
                    value: {
                        active: columnName,
                        direction,
                    },
                    tagNo,
                    tagType,
                    type,
                })
            );
        } else {
            this.store.dispatch(
                new TagSearchHeaderFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'number', direction: OrderDirection.Desc },
                    tagNo,
                    tagType,
                    type,
                })
            );
        }
    }

    private sortUpdateMain(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new TagSearchFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new TagSearchFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'tagNo', direction: OrderDirection.Asc },
                })
            );
        }
    }

    getValues = (search = '', take = 30, page = 0, column = 'number', allElements = []) => {
        let elements = allElements.filter(
            (s) => s[column] && s[column].toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        if (elements.length) {
            let el = elements.map((s) => s[column]);
            let counts = _.countBy(el);
            let keys = _.keys(counts);
            return of(keys.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    private registerColumnHeaderFilters() {
        combineLatest([
            this.store.select((state) => state.tagSearchState.filter.columnTagNo),
            this.store.select((state) => state.tagSearchState.filter.columnTagType),
            this.store.select((state) => state.tagSearchState.filter.columnDiscipline),
            this.store.select((state) => state.tagSearchState.filter.columnTagName),
            this.store.select((state) => state.tagSearchState.filter.columnServiceDescription),
            this.store.select((state) => state.tagSearchState.filter.columnSubValidateComment),
            this.store.select((state) => state.tagSearchState.filter.columnProjectTeamName),
            this.store.select((state) => state.tagSearchState.filter.columnTagStatus),
            this.store.select((state) => state.tagSearchState.filter.columnExInspectReqd),
            this.store.select((state) => state.tagSearchState.filter.columnSubsystemsList),
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(([tagNo, tagType, discipline, tagName, serviceDescription, subValidateComment, pt, tagStatus, exInspections, subsystems]) => {
                this.columnTagNo = tagNo;
                this.columnTagType = tagType;
                this.columnDiscipline = discipline;
                this.columnTagName = tagName;
                this.columnServiceDescription = serviceDescription;
                this.columnSubValidateComment = subValidateComment;
                this.columnProjectTeamName = pt;
                this.columnTagStatus = tagStatus;
                this.columnExInspectReqd = exInspections;
                this.columnSubsystemsList = subsystems;
            });
    }

    openHeaderCheckListFilter(
        type: string,
        allElements: any[],
        currElement: TagSearchDTO,
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        let sortBy =
            type === Constants.tagSearchTableType.itr
                ? currElement.itrHeaderFilter.sortBy
                : type === Constants.tagSearchTableType.pli
                ? currElement.pliHeaderFilter.sortBy
                : type === Constants.tagSearchTableType.changeDoc
                ? currElement.changeDocHeaderFilter.sortBy
                : null;

        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 660, {
                isAscendingSort: sortBy.active === columnName && sortBy.direction === OrderDirection.Asc,
                isDescendingSort: sortBy.active === columnName && sortBy.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch,
                column: columnName,
                allElements: allElements,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];

                    this.store.dispatch(
                        new TagSearchHeaderFilterPropertyUpdate({
                            key: propertyName,
                            value,
                            tagNo: currElement.tagDetails.tagNo,
                            tagType: currElement.tagDetails.tagType,
                            type,
                        })
                    );

                    this.sortUpdate(
                        data.isAscendingSort,
                        data.isDescendingSort,
                        columnName,
                        currElement.tagDetails.tagNo,
                        currElement.tagDetails.tagType,
                        type
                    );
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new TagSearchHeaderFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                            tagNo: currElement.tagDetails.tagNo,
                            tagType: currElement.tagDetails.tagType,
                            type,
                        })
                    );
                },
            })
        );
    }

    openMainHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        isSortingOff: boolean = false
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: isSortingOff,
                showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdateMain(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new TagSearchFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new TagSearchFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }
}
