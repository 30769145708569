import { Action } from '@ngrx/store';
import { ChangeValidationDataPagination } from './model';

export enum ChangeValidationActionTypes {
    ChangeValidationFilterRequest = '[ChangeValidation] Filter Request',
    ChangeValidationFilterSuccess = '[ChangeValidation] Filter Success',
    ChangeValidationFilterError = '[ChangeValidation] Filter Error',
    ChangeValidationFilterPropertyUpdate = '[ChangeValidation] Property Update',
    ChangeValidationFilterReset = '[ChangeValidation] Filter Reset',
    ChangeValidationExportToExcelRequest = '[ChangeValidation] Export to Excel Request',
    ChangeValidationExportToExcelSuccess = '[ChangeValidation] Export to Excel Success',
    ChangeValidationExportToExcelError = '[ChangeValidation] Export to Excel Error',
}

export class ChangeValidationFilterRequest implements Action {
    readonly type = ChangeValidationActionTypes.ChangeValidationFilterRequest;
}

export class ChangeValidationFilterSuccess implements Action {
    readonly type = ChangeValidationActionTypes.ChangeValidationFilterSuccess;

    constructor(public payload: ChangeValidationDataPagination) {}
}

export class ChangeValidationFilterError implements Action {
    readonly type = ChangeValidationActionTypes.ChangeValidationFilterError;

    constructor(public payload: string) {}
}

export class ChangeValidationFilterPropertyUpdate implements Action {
    readonly type = ChangeValidationActionTypes.ChangeValidationFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ChangeValidationFilterReset implements Action {
    readonly type = ChangeValidationActionTypes.ChangeValidationFilterReset;
}

export class ChangeValidationExportToExcelRequest implements Action {
    readonly type = ChangeValidationActionTypes.ChangeValidationExportToExcelRequest;
}

export class ChangeValidationExportToExcelSuccess implements Action {
    readonly type = ChangeValidationActionTypes.ChangeValidationExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class ChangeValidationExportToExcelError implements Action {
    readonly type = ChangeValidationActionTypes.ChangeValidationExportToExcelError;

    constructor(public payload: string) {}
}

export type ChangeValidationActions =
    | ChangeValidationFilterRequest
    | ChangeValidationFilterSuccess
    | ChangeValidationFilterError
    | ChangeValidationFilterPropertyUpdate
    | ChangeValidationFilterReset
    | ChangeValidationExportToExcelRequest
    | ChangeValidationExportToExcelSuccess
    | ChangeValidationExportToExcelError;
