import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import {
    CreateActivityDTO,
    ScheduleActivityPlanningFilter,
    SubsystemsInWeek,
    SubsystemWithScheduleActivitiesDTO,
} from '../../../store/weekly-planning/schedule-activity-planning/model';
import { BaseResultWithSubmissionState, UserDetail } from '../../../store/common.model';
import { ScheduleActivityPlanningConstraint } from '../../../components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import * as moment from 'moment';
import { ScheduleActivityITRDataPagination, ScheduleActivityITRScope } from 'src/app/store/schedule-activity-itr/model';
import { WeeklyPlanType } from 'src/app/enums';

export type WeeklyStatusBooleanProperties = 'areWorkPacksReady' | 'inWeeklyPlan' | 'isWorkComplete' | 'isConstrained';

export type WeeklyStatusProperties = WeeklyStatusBooleanProperties | 'comment' | 'MhrsInWeek';

export class ScheduleActivityPropertyUpdateDto {
    activityId: string;
    activityDiscipline: string;
    weekStart: Date;
    removeSelectedItrs?: boolean;
    property: WeeklyStatusProperties;
    value: boolean | string | number;
}

export class ScheduleActivityAddCommentDto {
    activityId: string;
    activityDiscipline: string;
    description: string;
    mentions: UserDetail[];
}

export class ScheduleActivityUpdateDto {
    activityId: string;
    activityDiscipline: string;

    forecastStart?: Date;
    forecastFinish?: Date;
    fourWeeksComment?: string;

    isConstrained?: boolean;
    constraintTypeId?: number;
    constraintType?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ScheduleActivityPlanningService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint = '/scheduleActivity';

    constructor(private http: HttpClient) {}

    getScheduleActivities(
        filter: ScheduleActivityPlanningFilter,
        printMode = false
    ): Observable<BaseResultWithSubmissionState<SubsystemWithScheduleActivitiesDTO>> {
        const endpointUrl = `${this.rootUrl}${this.endpoint}/search?printMode=${printMode}`;
        const requestFilter = {
            ...filter,
            itrContractors: filter.itrContractors.map((c) => ({ id: c.contractNo })),
        } as ScheduleActivityPlanningFilter;
        return this.http.post<BaseResultWithSubmissionState<SubsystemWithScheduleActivitiesDTO>>(endpointUrl, {
            ...requestFilter,
        });
    }

    updateWeeklyProperty(updateDto: ScheduleActivityPropertyUpdateDto) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, {
            ...updateDto,
        });
    }

    updateScheduleActivity(updateDto: ScheduleActivityUpdateDto) {
        return this.http.put(`${this.rootUrl}${this.endpoint}`, {
            ...updateDto,
        });
    }

    addConstraintType(model: ScheduleActivityPlanningConstraint): Observable<any> {
        return this.http.post(`${this.rootUrl}${this.endpoint}/constraint`, model);
    }

    updateConstraintType(model: ScheduleActivityPlanningConstraint): Observable<any> {
        return this.http.patch(`${this.rootUrl}${this.endpoint}/constraint`, model);
    }

    deleteConstraintType(model: ScheduleActivityPlanningConstraint): Observable<any> {
        const params = new HttpParams().append('name', model.name);

        return this.http.delete(`${this.rootUrl}${this.endpoint}/constraint`, { params });
    }

    saveSelectedITRIds(
        activityId: string,
        weekStart: moment.Moment,
        selectedIds: number[],
        activityDiscipline: string,
        additionToPlan: boolean
    ) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/saveitrids`, {
            activityId,
            weekStart,
            selectedIds,
            activityDiscipline,
            additionToPlan,
        });
    }

    generateExcel(weeklyPlanType: WeeklyPlanType, filter: ScheduleActivityPlanningFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel/${weeklyPlanType}`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    submitSelectedITRs(filter: ScheduleActivityPlanningFilter) {
        const endpointUrl = `${this.rootUrl}${this.endpoint}/itr/submit`;
        return this.http.post(endpointUrl, { ...filter });
    }

    getITRsForActivities(filter: ScheduleActivityPlanningFilter, scope: ScheduleActivityITRScope) {
        const endpointUrl = `${this.rootUrl}${this.endpoint}/itrsforactivities?type=${scope}`;
        return this.http.post<ScheduleActivityITRDataPagination>(endpointUrl, { ...filter });
    }

    exportITRsToExcel(filter: ScheduleActivityPlanningFilter, scope: ScheduleActivityITRScope) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateitrsexcel?type=${scope}`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    createNewActivity(activity: CreateActivityDTO) {
        const endpointUrl = `${this.rootUrl}${this.endpoint}/createactivity`;
        return this.http.post(endpointUrl, activity);
    }

    getSubsystemsNotInWeek(search: string, filter: ScheduleActivityPlanningFilter) {
        let params = new HttpParams();
        params = params.append('search', search);
        const endpointUrl = `${this.rootUrl}${this.endpoint}/subsystemsnotinweek`;
        return this.http.post(endpointUrl, filter, { params });
    }

    getSubsystemsInWeek(filter: ScheduleActivityPlanningFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/subsystemsinweek`, filter);
    }

    setSubsystemsInWeek(subsystems: SubsystemsInWeek) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/savesubsystemsinweek`, subsystems);
    }

    getSubsystemsInWeekITRsCount(subsystems: SubsystemsInWeek) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/subsystemsinweek/selecteditrscount`, subsystems);
    }
}
