import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ReportsDashboardDownloadFishboneRequest } from 'src/app/store/reports/dashboard/actions';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';

@Component({
    selector: 'app-reports-dashboard',
    templateUrl: './reports-dashboard.component.html',
    styleUrls: ['./reports-dashboard.component.scss'],
})
export class ReportsDashboardComponent {
    isLoading$ = this.store.select((state) => state.reportsDashboardState.isLoading);
    isRFSUCommitmentSkylineViewer= false;
    constructor(private store: Store<ApplicationState>,
        private roleService: RoleService
        ) {}

    downloadFishbone(phase: string) {
        this.store.dispatch(new ReportsDashboardDownloadFishboneRequest(phase));
    }
    skylineForecastAccess() {
        var isValid = true;
        // this.roleService.isInRole(Constants.applicableGroups.Admin) ||
        // this.roleService.isInRole(Constants.applicableGroups.RFSUForecastSkyline)
        // ? true : false;

        return isValid;
    }

    isRFSUSkylineCommitmentViewer(){
        this.isRFSUCommitmentSkylineViewer = this.roleService.isInRole(Constants.applicableGroups.Admin) || this.roleService.isInRole(Constants.applicableGroups.RFSUCommitmentSkylineViewers);
        return this.isRFSUCommitmentSkylineViewer;
    }
}
