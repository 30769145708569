import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from 'src/app/app.config';
import { CertificateSearchDTO, CertificateSearchFilter } from 'src/app/store/certificate/model';
import { BaseResultDTO } from 'src/app/store/common.model';

@Injectable({
    providedIn: 'root',
})
export class CertificateSearchService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/certificatesearch';

    constructor(private http: HttpClient) {}

    searchCertificate(filter: CertificateSearchFilter) {
        return this.http.post<BaseResultDTO<CertificateSearchDTO>>(`${this.rootUrl}${this.endpoint}`, filter);
    }

    searchColumnSubsystemsWithFilter = (
        search: string,
        take: number,
        page: number,
        filter: CertificateSearchFilter
    ) => {
        const params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}/searchColumnSubsystems`, filter, { params });
    };

    searchColumnDisciplinesWithFilter = (
        search: string,
        take: number,
        page: number,
        filter: CertificateSearchFilter
    ) => {
        const params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}/searchColumnDisciplines`, filter, { params });
    };

    searchColumnCertificateTypesWithFilter = (
        search: string,
        take: number,
        page: number,
        filter: CertificateSearchFilter
    ) => {
        const params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}/searchColumnCertificateTypes`, filter, { params });
    };

    searchColumnStatusesWithFilter = (search: string, take: number, page: number, filter: CertificateSearchFilter) => {
        const params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}/searchColumnStatuses`, filter, { params });
    };

    searchColumnCertificateNumbersWithFilter = (
        search: string,
        take: number,
        page: number,
        filter: CertificateSearchFilter
    ) => {
        const params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}/searchColumnCertificateNumbers`, filter, { params });
    };

    generateExcel(filter: CertificateSearchFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    private getParams(search: string, take: number, page: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        return params;
    }
}
