import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { ChangeManagementFieldImplementationContractorHistoryRequest } from 'src/app/store/field-implementation-contractor-history/actions';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-field-implementation-contractor-history',
  templateUrl: './field-implementation-contractor-history.component.html',
  styleUrls: ['./field-implementation-contractor-history.component.scss']
})
export class FieldImplementationContractorHistoryComponent extends BaseComponent implements OnInit {
  displayedColumns: string[] = ['oldContractor', 'newContractor', 'updatedDate', 'userFullName'];
  dataSource = new MatTableDataSource();
  isLoading = false;
  fieldImplementationContractorHistory$ = this.store.select((state) => state.fieldImplementationContractorHistoryState.data);
  isLoading$ = this.store.select((state) => state.fieldImplementationContractorHistoryState.isLoading);
  changeDocId: number;
  constructor(public dialogRef: MatDialogRef<FieldImplementationContractorHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private store: Store<ApplicationState>) {
    super();

    this.changeDocId = data.changeDocId
  }

  ngOnInit(): void {

    this.fieldImplementationContractorHistory$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data)
      });

    this.isLoading$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((loading) => this.isLoading = loading);

    this.store.dispatch(
      new ChangeManagementFieldImplementationContractorHistoryRequest(
        {
          id: this.changeDocId
        }));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

}
