import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { CriticalPathDeletePagination } from './model';

export enum CriticalPathActionTypes {
    CriticalPathUploadLogRequest = '[Critical Path] Upload Log Request',
    CriticalPathUploadLogSuccess = '[Critical Path] Upload Log Success',
    CriticalPathUploadLogError = '[Critical Path] Upload Log Error',
    CriticalPathDeleteRecordsRequest = '[Critical Path] Delete Records Request',
    CriticalPathDeleteRecordsSuccess = '[Critical Path] Delete Records Success',
    CriticalPathDeleteRecordsError = '[Critical Path] Delete Records Error',
    CriticalPathSetStatusAndStartDate = '[Critical Path] Set Status and Start Date',
    CriticalPathDeleteFilterPropertyUpdate = '[Critical Path] Delete Filter Property Update',
    CriticalPathDeleteFilterReset = '[Critical Path] Delete Filter Reset',
    CriticalPathPatchDeleteRecordRequest = '[Critical Path] Patch Delete Record Request',
    CriticalPathPatchDeleteRecordSuccess = '[Critical Path] Patch Delete Record Success',
    CriticalPathPatchDeleteRecordError = '[Critical Path] Patch Delete Record Error',
    CriticalPathPatchAllDeleteRecordsRequest = '[Critical Path] Patch All Delete Records Request',
    CriticalPathPatchAllDeleteRecordsSuccess = '[Critical Path] Patch All Delete Records Success',
    CriticalPathPatchAllDeleteRecordsError = '[Critical Path] Patch All Delete Records Error',
    CriticalPathValidateDataRequest = '[Critical Path] Validate Data Request',
    CriticalPathValidateDataSuccess = '[Critical Path] Validate Data Success',
    CriticalPathValidateDataError = '[Critical Path] Validate Data Error',
    CriticalPathFileUploaded = '[Critical Path] File Uploaded',
    CriticalPathClearInProgressSpinner = '[Critical Path] Clear In Progress Spinner',
    CriticalPathTemplateFileRequest = '[Critical Path] Template File Request',
    CriticalPathTemplateFileSuccess = '[Critical Path] Template File Success',
    CriticalPathTemplateFileError = '[Critical Path] Template File Error',
    CriticalPathDownloadOutputDataRequest = '[Critical Path] Download Output Data Request',
    CriticalPathDownloadOutputDataSuccess = '[Critical Path] Download Output Data Success',
    CriticalPathDownloadOutputDataError = '[Critical Path] Download Output Data Error',
    CriticalPathValidateButtonStateRequest = '[Critical Path] Validate Button State Request',
    CriticalPathValidateButtonStateSuccess = '[Critical Path] Validate Button State Success',
    CriticalPathValidateButtonStateError = '[Critical Path] Validate Button State Error',
    CriticalPathAddStartedLog = '[Critical Path] Add Started Log',
}

export class CriticalPathAddStartedLog implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathAddStartedLog;
}

export class CriticalPathUploadLogRequest implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathUploadLogRequest;
}

export class CriticalPathUploadLogSuccess implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class CriticalPathUploadLogError implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathUploadLogError;

    constructor(public payload: string) {}
}

export class CriticalPathDeleteRequest implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathDeleteRecordsRequest;
}

export class CriticalPathDeleteSuccess implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathDeleteRecordsSuccess;

    constructor(public payload: CriticalPathDeletePagination) {}
}

export class CriticalPathDeleteError implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathDeleteRecordsError;

    constructor(public payload: string) {}
}

export class CriticalPathSetStatusAndStartDate implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class CriticalPathDeleteFilterPropertyUpdate implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class CriticalPathDeleteFilterReset implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathDeleteFilterReset;
}

export class CriticalPathPatchDeleteRequest implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathPatchDeleteRecordRequest;

    constructor(public payload: { activityId: string; deleteState: boolean }) {}
}

export class CriticalPathPatchAllDeleteRequest implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class CriticalPathPatchDeleteSuccess implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathPatchDeleteRecordSuccess;
}

export class CriticalPathPatchAllDeleteSuccess implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class CriticalPathPatchDeleteError implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class CriticalPathPatchAllDeleteError implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class CriticalPathValidateDeleteRequest implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathValidateDataRequest;
}

export class CriticalPathValidateDeleteSuccess implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathValidateDataSuccess;
}

export class CriticalPathValidateDeleteError implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathValidateDataError;

    constructor(public payload: string) {}
}

export class CriticalPathFileUploaded implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathFileUploaded;
}

export class CriticalPathClearInProgressSpinner implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathClearInProgressSpinner;
}

export class CriticalPathTemplateFileRequest implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathTemplateFileRequest;

    constructor(public payload: string) {}
}

export class CriticalPathTemplateFileSuccess implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class CriticalPathTemplateFileError implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathTemplateFileError;

    constructor(public payload: string) {}
}

export class CriticalPathDownloadOutputDataRequest implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class CriticalPathDownloadOutputDataSuccess implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class CriticalPathDownloadOutputDataError implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class CriticalPathValidateButtonStateRequest implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathValidateButtonStateRequest;
}

export class CriticalPathValidateButtonStateSuccess implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class CriticalPathValidateButtonStateError implements Action {
    readonly type = CriticalPathActionTypes.CriticalPathValidateButtonStateError;

    constructor(public payload: string) {}
}

export type CriticalPathActions =
    | CriticalPathUploadLogRequest
    | CriticalPathUploadLogSuccess
    | CriticalPathUploadLogError
    | CriticalPathDeleteRequest
    | CriticalPathDeleteSuccess
    | CriticalPathDeleteError
    | CriticalPathSetStatusAndStartDate
    | CriticalPathDeleteFilterPropertyUpdate
    | CriticalPathDeleteFilterReset
    | CriticalPathPatchDeleteRequest
    | CriticalPathPatchDeleteSuccess
    | CriticalPathPatchDeleteError
    | CriticalPathPatchAllDeleteRequest
    | CriticalPathPatchAllDeleteSuccess
    | CriticalPathPatchAllDeleteError
    | CriticalPathValidateDeleteRequest
    | CriticalPathValidateDeleteSuccess
    | CriticalPathValidateDeleteError
    | CriticalPathFileUploaded
    | CriticalPathClearInProgressSpinner
    | CriticalPathTemplateFileRequest
    | CriticalPathTemplateFileSuccess
    | CriticalPathTemplateFileError
    | CriticalPathDownloadOutputDataRequest
    | CriticalPathDownloadOutputDataSuccess
    | CriticalPathDownloadOutputDataError
    | CriticalPathValidateButtonStateRequest
    | CriticalPathValidateButtonStateSuccess
    | CriticalPathValidateButtonStateError
    | CriticalPathAddStartedLog;
