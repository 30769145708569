import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { catchError, map, take, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Contractor, Gooc, TCOUser } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    GOOCComparison2DashboardExportToExcelRequest,
    GOOCComparison2DashboardRequest,
    GOOCComparison2DashboardUpdateFilterProperties,
} from 'src/app/store/reports/gooc/actions';
import { GOOC2ComparisonDashboardDTO, GOOCComparisonDashboardFilter } from 'src/app/store/reports/gooc/model';

@Component({
    selector: 'app-gooc2',
    templateUrl: './gooc2.component.html',
    styleUrls: ['./gooc2.component.scss'],
})
export class GOOC2Component extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    contractors: Contractor[] = [];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    displayedColumns = [
        'gooc',
        'name',
        'unallocated',
        'hydrotests',
        'reinstatements',
        'terminations',
        'instrument',
        'exInsp',
        'heatTrace',
        'lsp',
    ];
    data: GOOC2ComparisonDashboardDTO[] = [];
    pageSize = 10;
    selectedPageSize = 10;
    resultsLength = 0;
    isPaginatorVisible = true;
    scrollSearch: boolean = false;

    isLoading$ = this.store.select((state) => state.goocComparisonDashboardState.gooc2IsLoading);
    items$ = this.store.select((state) => state.goocComparisonDashboardState.gooc2);
    filter$ = this.store.select((state) => state.goocComparisonDashboardState.gooc2Filter);
    totalCount$ = this.store.select((state) => state.goocComparisonDashboardState.gooc2TotalCount);

    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>,
        private toastService: ToastService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new GOOCComparisonDashboardFilter());
    }

    ngOnInit() {
        this.filterExpansionPanel.expanded = true;

        this.items$.pipe(takeWhile(() => this.isAlive)).subscribe((items) => {
            if (!items) {
                this.filterGOOCData();
                return;
            }
            this.data = items;
        });

        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.scrollSearch = filter.scrollSearch;
            if (!this.scrollSearch) {
                this.paginator.pageIndex = filter.page;
                this.pageSize = filter.take;
            }
        });

        this.totalCount$.pipe(takeWhile(() => this.isAlive)).subscribe((totalCount) => {
            this.resultsLength = totalCount;
            if (
                this.filterForm.value.goocs.length > 0 ||
                this.filterForm.value.mcEngineer.length > 0 ||
                this.filterForm.value.systemOwner.length > 0 ||
                this.filterForm.value.contractors.length > 0
            ) {
                this.pageSize = totalCount;
            }
        });

        forkJoin([
            this.lookupService.getMCEngineers([]),
            this.lookupService.getSysOwners([]),
            this.lookupService.getSCManagers([]),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners, scManagers]) => {
                    this.mcEngineers = mcEngineers;
                    this.sysOwners = sysOwners;
                    this.scManagers = scManagers;
                },
                (error) => {
                    this.toastService.Error(error);
                }
            );
    }

    displayMultipleSelected(values: Contractor[]) {
        return values.map((x) => x.name).join(', ');
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    exportToExcel() {
        this.updateFilterStore();
        this.store.dispatch(new GOOCComparison2DashboardExportToExcelRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        this.pageSize = pageEvent.pageSize;
        this.selectedPageSize = pageEvent.pageSize;
        this.store.dispatch(
            new GOOCComparison2DashboardUpdateFilterProperties({
                page: pageEvent.pageIndex,
                take: pageEvent.pageSize,
            })
        );
        this.filterGOOCData();
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    resetFilters() {
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.controls.mcEngineer.setValue([]);
        this.filterForm.controls.systemOwner.setValue([]);
        this.filterForm.controls.scManager.setValue([]);
        this.store.dispatch(
            new GOOCComparison2DashboardUpdateFilterProperties({
                goocs: [],
                contractors: [],
                mcEngineer: [],
                systemOwner: [],
            })
        );
    }

    search() {
        this.scrollSearch =
            this.filterForm.value.goocs.length > 0 ||
            this.filterForm.value.contractors.length > 0 ||
            this.filterForm.value.mcEngineer.length > 0 ||
            this.filterForm.value.scManager.length > 0 ||
            this.filterForm.value.systemOwner.length > 0;
        if (
            this.filterForm.value.goocs.length === 0 &&
            this.filterForm.value.contractors.length === 0 &&
            this.filterForm.value.mcEngineer.length === 0 &&
            this.filterForm.value.scManager.length === 0 &&
            this.filterForm.value.systemOwner.length === 0
        ) {
            this.pageSize = this.selectedPageSize;
        }
        this.paginator.pageIndex = 0;
        this.updateFilterStore();
        this.filterGOOCData();
    }

    updateFilterStore() {
        this.store.dispatch(
            new GOOCComparison2DashboardUpdateFilterProperties({
                goocs: this.filterForm.value.goocs,
                contractors: this.filterForm.value.contractors,
                systemOwner: this.filterForm.value.systemOwner,
                mcEngineer: this.filterForm.value.mcEngineer,
                scManager: this.filterForm.value.scManager,
                page: this.paginator.pageIndex,
                take: this.pageSize,
                scrollSearch: this.scrollSearch,
            })
        );
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    private filterGOOCData() {
        this.store.dispatch(new GOOCComparison2DashboardRequest());
    }
}
