import { Action } from '@ngrx/store';
import { BaseResultDTO } from '../common.model';
import { CertificateSearchDTO } from './model';

export enum CertificateSearchActionTypes {
    CertificateSearchRequest = '[Certificate Search] Request',
    CertificateSearchSuccess = '[Certificate Search] Success',
    CertificateSearchError = '[Certificate Search] Error',
    CertificateSearchFilterPropertyUpdate = '[Certificate Search] Filter Property Update',
    CertificateSearchFilterReset = '[Certificate Search] Filter Reset',
    CertificateSearchExportToExcelRequest = '[Certificate Search] Export to Excel Request',
    CertificateSearchExportToExcelSuccess = '[Certificate Search] Export to Excel Success',
    CertificateSearchExportToExcelError = '[Certificate Search] Export to Excel Error',
}

export class CertificateSearchRequest implements Action {
    readonly type = CertificateSearchActionTypes.CertificateSearchRequest;
}

export class CertificateSearchSuccess implements Action {
    readonly type = CertificateSearchActionTypes.CertificateSearchSuccess;

    constructor(public payload: BaseResultDTO<CertificateSearchDTO>) {}
}

export class CertificateSearchError implements Action {
    readonly type = CertificateSearchActionTypes.CertificateSearchError;

    constructor(public payload: string) {}
}

export class CertificateSearchFilterPropertyUpdate implements Action {
    readonly type = CertificateSearchActionTypes.CertificateSearchFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class CertificateSearchFilterReset implements Action {
    readonly type = CertificateSearchActionTypes.CertificateSearchFilterReset;
}

export class CertificateSearchExportToExcelRequest implements Action {
    readonly type = CertificateSearchActionTypes.CertificateSearchExportToExcelRequest;
}

export class CertificateSearchExportToExcelSuccess implements Action {
    readonly type = CertificateSearchActionTypes.CertificateSearchExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class CertificateSearchExportToExcelError implements Action {
    readonly type = CertificateSearchActionTypes.CertificateSearchExportToExcelError;

    constructor(public payload: string) {}
}

export type CertificateSearchActions =
    | CertificateSearchRequest
    | CertificateSearchSuccess
    | CertificateSearchError
    | CertificateSearchFilterPropertyUpdate
    | CertificateSearchFilterReset
    | CertificateSearchExportToExcelRequest
    | CertificateSearchExportToExcelSuccess
    | CertificateSearchExportToExcelError;
