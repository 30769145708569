import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserToContractsDTO } from 'src/app/models/user-to-contracts-dto';
import { AppRole } from 'src/app/store/common.model';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class UserToContractsService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/userContracts';

    constructor(private http: HttpClient) {}

    get() {
        return this.http.get<UserToContractsDTO[]>(`${this.rootUrl}${this.endpoint}`);
    }

    add(userToContracts: UserToContractsDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}`, userToContracts);
    }

    remove(email: string, role: AppRole) {
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?email=${email}&role=${role}`);
    }

    update(userToContracts: UserToContractsDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, userToContracts);
    }
}
