import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    CertificateActionTypes,
    CertificateUploadLogSuccess,
    CertificateUploadLogError,
    CertificateSetStatusAndStartDate,
    CertificateClearInProgressSpinner,
    CertificateTemplateFileRequest,
    CertificateTemplateFileSuccess,
    CertificateTemplateFileError,
    CertificateValidateDeltaSuccess,
    CertificateValidateDeltaError,
    CertificateDataInfoSuccess,
    CertificateDataInfoError,
    CertificateDataInfoRequest,
    CertificateDownloadOutputDataRequest,
    CertificateDownloadOutputDataSuccess,
    CertificateDownloadOutputDataError,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class CertificateEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(CertificateActionTypes.CertificateUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['Certificate']).pipe(
                map((uploadLogData) => new CertificateUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting logs for Certificates. Please contact Program Administrator.'
                    );
                    return of(new CertificateUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<CertificateUploadLogSuccess>(CertificateActionTypes.CertificateUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.certificateState.uploadLogStatus),
            this.store.select((store) => store.certificateState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new CertificateSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<CertificateTemplateFileRequest>(CertificateActionTypes.CertificateTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new CertificateTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting template for Certificates. Please contact Program Administrator.'
                    );
                    return of(new CertificateTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<CertificateTemplateFileSuccess>(CertificateActionTypes.CertificateTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'SCMT Certs.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<CertificateDownloadOutputDataRequest>(CertificateActionTypes.CertificateDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new CertificateDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting result output for Certificates. Please contact Program Administrator.'
                    );
                    return of(new CertificateDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<CertificateDownloadOutputDataSuccess>(CertificateActionTypes.CertificateDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(CertificateActionTypes.CertificateValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateCertificatesDelta().pipe(
                map(() => new CertificateValidateDeltaSuccess()),
                catchError((error) => {
                    if (error?.error === 'AutomaticUploadPipelineInProgress') {
                        this.toastService.Info(
                            'Cannot validate the data while automatic upload pipeline is in progress.'
                        );
                    } else {
                        this.toastService.Error(
                            'Error occurred while validating and pushing Certificates. Please contact Program Administrator.'
                        );
                    }
                    return of(new CertificateValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(CertificateActionTypes.CertificateSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.certificateState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new CertificateClearInProgressSpinner(), new CertificateDataInfoRequest()])
    );

    @Effect()
    setValidateButtonEnabled$ = this.actions$.pipe(
        ofType(
            CertificateActionTypes.CertificateDataInfoRequest,
            CertificateActionTypes.CertificateUploadLogSuccess
        ),
        mergeMap(() =>
            this.adminPanelService.getDataInfo(['Certificate']).pipe(
                map((dataInfo: any) => new CertificateDataInfoSuccess(dataInfo)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting data info for Certificates. Please contact Program Administrator.'
                    );
                    return of(new CertificateDataInfoError(error));
                })
            )
        )
    );
}
