import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { HeaderCheckListFilter, HeaderCheckListFilterResult } from 'src/app/models/filter-settings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { Subject, Observable, timer } from 'rxjs';
import { startWith, exhaustMap, takeWhile, scan, tap, debounce, filter, switchMap, take } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CdkVirtualScrollViewport, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { BaseComponent } from 'src/app/components/base.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-header-checklist-filter',
    templateUrl: './header-checklist-filter.component.html',
    styleUrls: ['./header-checklist-filter.component.scss'],
})
export class HeaderChecklistFilterComponent extends BaseComponent implements OnInit {
    showBlankOptions = false;
    isAscendingSort: boolean;
    isDescendingSort: boolean;
    isSortingOff: boolean;
    placeholder: string;
    nextPage$ = new Subject();
    isLoading = false;
    noResultFound = false;
    elements: string[] = [];
    showCounts = false;
    showTrafficLightFilter = false;
    selectedItems: string[] = [];
    itemCtrl = new UntypedFormControl('');
    itemsPerPage = 30;
    showInputSearch = true;
    selectAll = false;
    searchFunc: (id?: string, take?: number, page?: number, column?: string, allElements?: any[]) => Observable<any[]>;
    action: (data: HeaderCheckListFilterResult) => void;
    resetColumnAction: () => void;
    cancelAction: () => void;
    column: string;
    allElements: any[];
    isAddCurrentToFilter = false;
    addCurrentToFilter = false;
    previouslySelected: string[] = [];
    flagsGroup: UntypedFormGroup;
    flags = ['white', 'red', 'orange', 'green', 'purple'];
    selectedFlags: string[] = [];
    excludeBlanks = false;
    onlyBlanks = false;

    @ViewChild(CdkVirtualForOf, { static: true }) virtualFor: CdkVirtualForOf<any>;
    @ViewChild(MatSelectionList, { static: true }) matSelectionList: MatSelectionList;
    @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: HeaderCheckListFilter,
        private dialogRef: MatDialogRef<HeaderChecklistFilterComponent>,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder
    ) {
        super();
        this.isSortingOff = data.isSortingOff;
        this.isAscendingSort = data.isAscendingSort;
        this.isDescendingSort = data.isDescendingSort;
        this.placeholder = data.placeHolder;
        this.searchFunc = data.searchFunc;
        this.action = data.action;
        this.resetColumnAction = data.resetColumnAction;
        this.cancelAction = data.cancelAction;
        this.column = data.column;
        this.allElements = data.allElements ? [...data.allElements] : [];
        this.showInputSearch = data.showInputSearch;
        this.selectedItems = data.selectedItems === null ? [] : data.selectedItems;
        this.previouslySelected = [...this.selectedItems];
        this.showCounts = data.showCounts;
        this.showTrafficLightFilter = data.showTrafficLightFilter;
        this.showBlankOptions = data.showBlankOptions;
        this.excludeBlanks = data.excludeBlanks;
        this.onlyBlanks = data.onlyBlanks;
        if (this.showTrafficLightFilter) {
            this.selectedFlags = data.selectedFlags;
            this.flagsGroup = this.formBuilder.group({
                flags: this.formBuilder.array(this.flags.map((f) => this.selectedFlags.indexOf(f) > -1)),
            });
        }
        dialogRef.afterClosed().subscribe((saveFilter: boolean) => {
            if (saveFilter) {
                if (this.addCurrentToFilter) {
                    this.selectedItems = [...new Set(this.selectedItems.concat(this.previouslySelected))];
                }
                this.action({
                    selectedItems: this.selectedItems,
                    isAscendingSort: this.isAscendingSort,
                    isDescendingSort: this.isDescendingSort,
                    selectedFlags: this.showTrafficLightFilter
                        ? (this.flagsGroup.controls.flags as UntypedFormArray).value
                              .map((f: boolean, i: number) => (f ? this.flags[i] : null))
                              .filter((f: string) => !!f)
                        : [],
                    excludeBlanks: this.excludeBlanks,
                    onlyBlanks: this.onlyBlanks,
                });
            }
        });
    }

    ngAfterViewInit() {
        this.matSelectionList.selectionChange
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((listChange) => this.selectionChange(listChange));
    }

    ngOnInit() {
        this.itemCtrl.valueChanges
            .pipe(
                takeWhile(() => this.isAlive),
                tap(() => {
                    this.selectAll = false;
                    this.previouslySelected = this.previouslySelected.concat(this.selectedItems);
                    this.selectedItems = [];
                    this.isAddCurrentToFilter = this.previouslySelected.length > 0 && this.itemCtrl.value;
                    this.isLoading = true;
                    this.changeDetectorRef.detectChanges();
                }),
                debounce(() => timer(800)),
                startWith(''),
                filter(() => this.searchFunc !== undefined),
                switchMap((value) => this.applyInfiniteScroll(value))
            )
            .subscribe((data) => {
                this.elements = [];

                if (this.showBlankOptions) {
                    this.elements = data.filter((x) => x !== '');
                } else {
                    this.elements = data.filter((x) => x?.hasOwnProperty('value') ? x.value : x);
                }
                this.isLoading = false;
                this.noResultFound = data ? data.length === 0 : true;

                if (this.isAlive) {
                    this.changeDetectorRef.detectChanges();
                    this.selectItemsOnList();
                }
            });
    }

    selectAllChange() {
        this.selectAll = !this.selectAll;
        if (this.selectAll) {
            this.matSelectionList.selectAll();
            this.isLoading = true;
            this.searchFunc(this.itemCtrl.value, 1000000, 0)
                .pipe(take(1))
                .subscribe((data) => {
                    if (data && typeof data[0] === 'object') {
                        data = data.map((x) => x.value);
                    }
                    this.selectedItems = data;
                    this.isLoading = false;
                });
        } else {
            this.matSelectionList.deselectAll();
            this.selectedItems = [];
        }
    }

    getElementValue(element: any) {
        return element !== null ? (element.value !== undefined ? element.value : element) : '';
    }

    applyInfiniteScroll(value: string) {
        let currentPage = 0;
        return this.nextPage$.pipe(
            startWith(currentPage),
            tap(() => (this.isLoading = true)),
            exhaustMap(() => this.searchFunc(value, this.itemsPerPage, currentPage, this.column, this.allElements)),
            tap(() => (this.isLoading = false)),
            takeWhile((newItems: any[]) => {
                if (newItems.length === 0 && currentPage === 0) {
                    this.noResultFound = true;
                    this.elements = [];
                }
                return newItems.length > 0;
            }),
            scan((allItems, newItems) => {
                if (currentPage === 0) {
                    allItems = [];
                }
                return allItems.concat(newItems);
            }, []),
            tap(() => currentPage++)
        );
    }

    nextBatch($event: any) {
        const end = this.viewport.getRenderedRange().end;
        const total = this.viewport.getDataLength();

        this.selectItemsOnList();

        if (end === total && this.elements.length >= this.itemsPerPage) {
            this.nextPage$.next();
        }
    }

    trackByFn(index: number, item: string) {
        return item;
    }

    selectionChange(listChange: MatSelectionListChange) {
        if (listChange.options[0].selected && !_.some(this.selectedItems, (i) => i === listChange.options[0].value)) {
            this.selectedItems.push(listChange.options[0].value);
        } else {
            this.selectedItems = this.selectedItems.filter((x) => x !== listChange.options[0].value);
            this.selectAll = false;
        }
    }

    resetFilters() {
        if (this.resetColumnAction) {
            this.resetColumnAction();
        }
        this.matSelectionList.deselectAll();
        this.itemCtrl.setValue('');
        this.selectedItems = [];
        if (this.showTrafficLightFilter) { 
            this.flagsGroup.controls.flags.setValue([false, false, false, false,false]);
            this.selectedFlags = [];
        }
        this.previouslySelected = [];
        this.viewport.scrollToOffset(0);
        this.selectAll = false;
        this.excludeBlanks = false;
        this.onlyBlanks = false;
    }

    close() {
        if (this.cancelAction) {
            this.cancelAction();
        }
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.dialogRef.close(true);
    }

    private selectItemsOnList() {
        this.matSelectionList.options
            .map((x) => {
                x.selected = false;
                return x;
            })
            .filter(
                (x) =>
                    this.selectedItems.filter((i) => i === x.value).length > 0 ||
                    this.previouslySelected.filter((i) => i === x.value).length > 0 ||
                    this.selectAll
            )
            .forEach((x) => (x.selected = true));
    }

    updateBlanks(radio: any) {
        switch (radio.value) {
            case 'all':
                this.excludeBlanks = false;
                this.onlyBlanks = false;
                break;
            case 'onlyBlanks':
                this.excludeBlanks = false;
                this.onlyBlanks = true;
                break;
            case 'excludeBlanks':
                this.excludeBlanks = true;
                this.onlyBlanks = false;
                break;
        }
    }
}
