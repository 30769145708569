import { Action } from "@ngrx/store";
import { FieldImplementationContractor } from "./model";

export enum FieldImplementationContractorHistoryActionTypes{
    ChangeManagementFieldImplementationContractorHistoryRequest = '[Change Management] Field Implementation Contractor History Request',
    ChangeManagementFieldImplementationContractorHistorySuccess = '[Change Management] Field Implementation Contractor History Success',
    ChangeManagementFieldImplementationContractorHistoryError = '[Change Management] Field Implementation Contractor History Error',
}

export class ChangeManagementFieldImplementationContractorHistoryRequest implements Action {
    readonly type = FieldImplementationContractorHistoryActionTypes.ChangeManagementFieldImplementationContractorHistoryRequest;

    constructor(public payload: { id: number }) {}
}

export class ChangeManagementFieldImplementationContractorHistorySuccess implements Action {
    readonly type = FieldImplementationContractorHistoryActionTypes.ChangeManagementFieldImplementationContractorHistorySuccess;

    constructor(public payload: FieldImplementationContractor[]) {}
}

export class ChangeManagementFieldImplementationContractorHistoryError implements Action {
    readonly type = FieldImplementationContractorHistoryActionTypes.ChangeManagementFieldImplementationContractorHistoryError;

    constructor(public payload: string) {}
}

export type FieldImplementationContractorHistoryActions = 
    | ChangeManagementFieldImplementationContractorHistoryRequest
    | ChangeManagementFieldImplementationContractorHistorySuccess
    | ChangeManagementFieldImplementationContractorHistoryError;