import { Action } from '@ngrx/store';
import { TagSearchDataPagination } from './model';

export enum TagSearchActionTypes {
    TagSearchFilterRequest = '[Tag Search] Filter Request',
    TagSearchFilterSuccess = '[Tag Search] Filter Success',
    TagSearchFilterError = '[Tag Search] Filter Error',
    TagSearchFilterPropertyUpdate = '[Tag Search] Property Update',
    TagSearchHeaderFilterPropertyUpdate = '[Tag Search] Header Filter Property Update',
    TagSearchFilterReset = '[Tag Search] Filter Reset',
    TagSearchExportToExcelRequest = '[Tag Search] Export to Excel Request',
    TagSearchExportToExcelSuccess = '[Tag Search] Export to Excel Success',
    TagSearchExportToExcelError = '[Tag Search] Export to Excel Error',
}

export class TagSearchFilterRequest implements Action {
    readonly type = TagSearchActionTypes.TagSearchFilterRequest;
}

export class TagSearchFilterSuccess implements Action {
    readonly type = TagSearchActionTypes.TagSearchFilterSuccess;

    constructor(public payload: TagSearchDataPagination) {}
}

export class TagSearchFilterError implements Action {
    readonly type = TagSearchActionTypes.TagSearchFilterError;

    constructor(public payload: string) {}
}

export class TagSearchFilterPropertyUpdate implements Action {
    readonly type = TagSearchActionTypes.TagSearchFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class TagSearchHeaderFilterPropertyUpdate implements Action {
    readonly type = TagSearchActionTypes.TagSearchHeaderFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any; tagNo: string; tagType: any; type: string }) {}
}

export class TagSearchFilterReset implements Action {
    readonly type = TagSearchActionTypes.TagSearchFilterReset;
}

export class TagSearchExportToExcelRequest implements Action {
    readonly type = TagSearchActionTypes.TagSearchExportToExcelRequest;
}

export class TagSearchExportToExcelSuccess implements Action {
    readonly type = TagSearchActionTypes.TagSearchExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class TagSearchExportToExcelError implements Action {
    readonly type = TagSearchActionTypes.TagSearchExportToExcelError;

    constructor(public payload: string) {}
}

export type TagSearchActions =
    | TagSearchFilterRequest
    | TagSearchFilterSuccess
    | TagSearchFilterError
    | TagSearchFilterPropertyUpdate
    | TagSearchFilterReset
    | TagSearchExportToExcelRequest
    | TagSearchExportToExcelSuccess
    | TagSearchExportToExcelError
    | TagSearchHeaderFilterPropertyUpdate;
