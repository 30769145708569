import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum IMDBActionTypes {
    IMDBUploadLogRequest = '[IMDB] Upload Log Request',
    IMDBUploadLogSuccess = '[IMDB] Upload Log Success',
    IMDBUploadLogError = '[IMDB] Upload Log Error',
    IMDBSetStatusAndStartDate = '[IMDB] Set Status and Start Date',
    IMDBFileUploaded = '[IMDB] File Uploaded',
    IMDBClearInProgressSpinner = '[IMDB] Clear In Progress Spinner',
    IMDBDataInfoRequest = '[IMDB] Data Info Request',
    IMDBDataInfoSuccess = '[IMDB] Data Info Success',
    IMDBDataInfoError = '[IMDB] Data Info Error',
    IMDBDownloadOutputDataRequest = '[IMDB] Output data Request',
    IMDBDownloadOutputDataSuccess = '[IMDB] Output data Success',
    IMDBDownloadOutputDataError = '[IMDB] Output data File Error',
    IMDBAddStartedLog = '[IMDB] Add Started Log',
    IMDBValidateDeltaRequest = '[IMDB] Validate Delta Request',
    IMDBValidateDeltaSuccess = '[IMDB] Validate Delta Success',
    IMDBValidateDeltaError = '[IMDB] Validate Delta Error',
}

export class IMDBAddStartedLog implements Action {
    readonly type = IMDBActionTypes.IMDBAddStartedLog;
}

export class IMDBUploadLogRequest implements Action {
    readonly type = IMDBActionTypes.IMDBUploadLogRequest;
}

export class IMDBUploadLogSuccess implements Action {
    readonly type = IMDBActionTypes.IMDBUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class IMDBUploadLogError implements Action {
    readonly type = IMDBActionTypes.IMDBUploadLogError;

    constructor(public payload: string) {}
}

export class IMDBFileUploaded implements Action {
    readonly type = IMDBActionTypes.IMDBFileUploaded;
}

export class IMDBClearInProgressSpinner implements Action {
    readonly type = IMDBActionTypes.IMDBClearInProgressSpinner;
}

export class IMDBDownloadOutputDataRequest implements Action {
    readonly type = IMDBActionTypes.IMDBDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class IMDBDownloadOutputDataSuccess implements Action {
    readonly type = IMDBActionTypes.IMDBDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class IMDBDownloadOutputDataError implements Action {
    readonly type = IMDBActionTypes.IMDBDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class IMDBSetStatusAndStartDate implements Action {
    readonly type = IMDBActionTypes.IMDBSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class IMDBDataInfoSuccess implements Action {
    readonly type = IMDBActionTypes.IMDBDataInfoSuccess;
    
    constructor(public payload: {
        readyToProcess: boolean[];
        inProgress: boolean[];
        recordsForDeletion: number[];
        isAutomaticUploadInProgress: boolean;
    }) {}
}

export class IMDBDataInfoError implements Action {
    readonly type = IMDBActionTypes.IMDBDataInfoError;

    constructor(public payload: string) {}
}

export class IMDBDataInfoRequest implements Action {
    readonly type = IMDBActionTypes.IMDBDataInfoRequest;
}

export class IMDBValidateDeltaRequest implements Action {
    readonly type = IMDBActionTypes.IMDBValidateDeltaRequest;
}

export class IMDBValidateDeltaSuccess implements Action {
    readonly type = IMDBActionTypes.IMDBValidateDeltaSuccess;
}

export class IMDBValidateDeltaError implements Action {
    readonly type = IMDBActionTypes.IMDBValidateDeltaError;

    constructor(public payload: string) {}
}

export type IMDBActions =
    | IMDBUploadLogRequest
    | IMDBUploadLogSuccess
    | IMDBUploadLogError
    | IMDBFileUploaded
    | IMDBClearInProgressSpinner
    | IMDBSetStatusAndStartDate
    | IMDBDataInfoRequest
    | IMDBDataInfoSuccess
    | IMDBDataInfoError
    | IMDBDownloadOutputDataRequest
    | IMDBDownloadOutputDataSuccess
    | IMDBDownloadOutputDataError
    | IMDBAddStartedLog
    | IMDBValidateDeltaRequest
    | IMDBValidateDeltaSuccess
    | IMDBValidateDeltaError;
