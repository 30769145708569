import { DetailsState } from './model';
import { DetailsActions, DetailsActionTypes } from './actions';
import { DetailedStatusDTO } from '../details/model';
import { CommentDTO } from '../common.model';

const initialState: DetailsState = {
    item: new DetailedStatusDTO(),
    comments: [],
    isLoading: false,
    constraintComments: [],
    materialDetails : []
};

export function reducer(state: DetailsState = initialState, action: DetailsActions) {
    switch (action.type) {
        case DetailsActionTypes.UpdateDetailsRequest: {
            return {
                ...state,
                item: action.payload,
            };
        }
        case DetailsActionTypes.DetailsConstraintFlagUpdateRequest:
        case DetailsActionTypes.DetailsPLIReviewDeleteRequest:
        case DetailsActionTypes.DetailsPLIReviewRequest:
        case DetailsActionTypes.DetailsDeleteConstraintCommentRequest:
        case DetailsActionTypes.DetailsDeleteCommentRequest:
        case DetailsActionTypes.GetConstraintCommentsRequest:
        case DetailsActionTypes.GetMaterialsDetailsRequest:
        case DetailsActionTypes.GetCommentsRequest:
        case DetailsActionTypes.DetailsAddConstraintCommentRequest:
        case DetailsActionTypes.DetailsAddCommentRequest:
        case DetailsActionTypes.GetDetailsRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case DetailsActionTypes.GetDetailsSuccess: {
            return {
                ...state,
                item: action.payload,
                comments: action.payload.comments,
                materialDetails: action.payload.materialDetails,
                isLoading: false,
            };
        }
        case DetailsActionTypes.GetConstraintDetailsError:
        case DetailsActionTypes.DetailsConstraintFlagUpdateError:
        case DetailsActionTypes.DetailsConstraintFlagUpdateSuccess:
        case DetailsActionTypes.DetailsPLIReviewDeleteError:
        case DetailsActionTypes.DetailsPLIReviewError:
        case DetailsActionTypes.DetailsDeleteCommentError:
        case DetailsActionTypes.DetailsDeleteConstraintCommentError:
        case DetailsActionTypes.GetConstraintCommentsError:
        case DetailsActionTypes.GetMaterialsDetailsError:
        case DetailsActionTypes.GetCommentsError:
        case DetailsActionTypes.DetailsAddConstraintCommentError:
        case DetailsActionTypes.DetailsAddCommentError:
        case DetailsActionTypes.GetDetailsError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case DetailsActionTypes.DetailsAddCommentSuccess: {
            const newComment = new CommentDTO();
            newComment.description = action.payload.description;
            newComment.date = action.payload.date;
            newComment.userName = action.payload.userName;
            newComment.id = action.payload.id;
            newComment.mentions = action.payload.mentions;
            return {
                ...state,
                comments: [...state.comments, newComment],
                isLoading: false,
            };
        }
        case DetailsActionTypes.DetailsAddConstraintCommentSuccess: {
            const newComment = new CommentDTO();
            newComment.description = action.payload.description;
            newComment.date = action.payload.date;
            newComment.userName = action.payload.userName;
            newComment.id = action.payload.id;
            newComment.mentions = action.payload.mentions;
            return {
                ...state,
                constraintComments: [...state.constraintComments, newComment],
                isLoading: false,
            };
        }
        case DetailsActionTypes.GetCommentsSuccess: {
            return {
                ...state,
                comments: action.payload,
                isLoading: false,
            };
        }
        case DetailsActionTypes.GetConstraintCommentsSuccess: {
            return {
                ...state,
                constraintComments: action.payload,
                isLoading: false,
            };
        }
        case DetailsActionTypes.GetMaterialDetailsSuccess: {
            return {
                ...state,
                materials: action.payload.materials,
                isLoading: false,
            };
        }
        case DetailsActionTypes.DetailsDeleteCommentSuccess: {
            return {
                ...state,
                comments: [...state.comments].filter((x) => x.id !== action.payload.id),
                isLoading: false,
            };
        }
        case DetailsActionTypes.DetailsDeleteConstraintCommentSuccess: {
            return {
                ...state,
                constraintComments: [...state.constraintComments].filter((x) => x.id !== action.payload.id),
                isLoading: false,
            };
        }
        case DetailsActionTypes.DetailsPLIReviewSuccess: {
            return {
                ...state,
                item: action.payload,
                comments: action.payload.comments,
                isLoading: false,
            };
        }
        case DetailsActionTypes.DetailsPLIReviewDeleteSuccess: {
            return {
                ...state,
                item: action.payload,
                comments: action.payload.comments,
                isLoading: false,
            };
        }
        case DetailsActionTypes.GetConstraintCommentDetailsSuccess: {
            return {
                ...state,             
                comments: action.payload.comments, 
                constraintComments: action.payload.constraintComments,               
                isLoading: false,
            };
        }
        case DetailsActionTypes.GetConstraintDetailsSuccess: {
            return {
                ...state,
                item: action.payload.detail,
                comments: action.payload.detail.comments, 
                constraintComments: action.payload.detail.constraintComments,               
                isLoading: true,
            };
        }
        default: {
            return state;
        }
    }
}
