<div class="main">
    <form [formGroup]="filterForm">
        <div class="filter-container">
            <div class="mixedapp-container">
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Project Team</mat-label>
                    <mat-select formControlName="projectTeamNames" multiple>
                        <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="container300-mh margin-right20">
                    <app-multiple-items-selector
                        class="container300"
                        [setInput]="setContractorInput"
                        formControlName="contractors"
                        [searchFunc]="getContractors"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                        (autocompleteClosed)="onContractorsClosed()"
                        (itemRemoved)="onContractorsClosed()"
                        [filterForm]="filterForm"
                        [formSetter]="'contractors'"
                        [propertyToShow]="'contractNo'"
                        [placeholder]="'Contractor'"
                        [panelWidth]="480"
                        [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        [secondPropertyToShow]="'contract'"
                    >
                    </app-multiple-items-selector>
                </div>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>ACM</mat-label>
                    <mat-select formControlName="acManagers" multiple>
                        <mat-option *ngFor="let acm of acms" [value]="acm.id">{{ acm.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="container300">
                    <mat-label>SC Execution Area Manager</mat-label>
                    <mat-select formControlName="scAreaManagers" multiple>
                        <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mixedapp-container">
                <mat-form-field class="container300 margin-right20">
                    <mat-label>MC Engineer</mat-label>
                    <mat-select formControlName="mcEngineers" multiple>
                        <mat-option *ngFor="let mce of mcEngineers" [value]="mce.id">{{ mce.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>System Owner</mat-label>
                    <mat-select formControlName="sysOwners" multiple>
                        <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="container300-mh55 margin-right20">
                    <app-multiple-items-selector
                        class="container300"
                        formControlName="subsystem"
                        [searchFunc]="getSubsystems"
                        [isAsync]="true"
                        placeholder="Subsystem"
                        [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </div>
                <div class="container300-mh55 margin-right20">
                    <app-multiple-items-selector
                        class="container300"
                        formControlName="subsystemName"
                        [searchFunc]="getSubsystemsNames"
                        [isAsync]="true"
                        placeholder="Subsystem names"
                        [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </div>
            </div>
        </div>
        <div class="buttons-container flex">
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
        <div class="results-container">
            <h4>modify subsystem data</h4>
            <table
                mat-table
                matSort
                [dataSource]="data"
                [matSortActive]="sortBy"
                matSortDisableClear
                [matSortDirection]="direction"
            >
                <ng-container matColumnDef="subsystem">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        [ngClass]="{
                            'header-underline': showUnderlineForCheckListColumn(columnSubsystems)
                        }"
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'subsystem',
                                getSubsystemsForColumnHeader,
                                'columnSubsystems',
                                'Subsystem',
                                columnSubsystems
                            )
                        "
                    >
                        Subsystem
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.subsystem }}</td>
                </ng-container>
                <ng-container matColumnDef="subsystemName">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'subsystemName',
                                getSubsystemsNamesForColumnHeader,
                                'columnSubsystemNames',
                                'Subsystem Name',
                                columnSubsystemNames
                            )
                        "
                    >
                        <div class="sort-container">
                            <span
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnSubsystemNames)
                                }"
                                >Subsystem name</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.subsystemName }}</td>
                </ng-container>
                <ng-container matColumnDef="contractorNo">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'contractorNo',
                                getContractorsForColumnHeader,
                                'columnContracts',
                                'Contractor',
                                columnContracts,
                                true
                            )
                        "
                    >
                        <div class="sort-container">
                            <span
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnContracts)
                                }"
                                >Contract No</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'contractorNo'">
                                <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.isInEditMode">{{ element.contractor?.contractNo }}</div>
                        <div *ngIf="element.isInEditMode" class="editmode">
                            <mat-select [(value)]="element.contractor" [compareWith]="compareContractors">
                                <mat-option
                                    *ngFor="let contractor of projectTeamNameMap[element.projectTeamName].contractors"
                                    [value]="contractor"
                                >
                                    {{ contractor.contractNo }}
                                </mat-option>
                            </mat-select>
                            <span
                                *ngIf="element.contractor != null"
                                (click)="element.contractor = null"
                                class="cancel-small"
                                ><mat-icon>cancel</mat-icon></span
                            >
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="TCOASCAreaManager">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'TCOASCAreaManager',
                                getSCMs,
                                'columnAreaManagers',
                                'SC Area Manager',
                                columnAreaManagers,
                                true
                            )
                        "
                    >
                        <div class="sort-container">
                            <span
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnAreaManagers)
                                }"
                                >SC Area Manager</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'TCOASCAreaManager'">
                                <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.isInEditMode">{{ element.tcoascAreaManager?.name }}</div>
                        <div *ngIf="element.isInEditMode" class="editmode">
                            <mat-select [(value)]="element.tcoascAreaManager" [compareWith]="compareTCOUsers">
                                <mat-option
                                    *ngFor="let sc of projectTeamNameMap[element.projectTeamName].scManagers"
                                    [value]="sc"
                                    >{{ sc.name }}
                                </mat-option>
                            </mat-select>
                            <span
                                *ngIf="element.tcoascAreaManager != null"
                                (click)="element.tcoascAreaManager = null"
                                class="cancel-small"
                                ><mat-icon>cancel</mat-icon></span
                            >
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="TCOSysOwner">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'TCOSysOwner',
                                getSysOwners,
                                'columnSystemOwner',
                                'System Owner',
                                columnSystemOwner,
                                true
                            )
                        "
                    >
                        <div class="sort-container">
                            <span
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnSystemOwner)
                                }"
                                >System Owner</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'TCOSysOwner'">
                                <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.isInEditMode">{{ element.tcoSysOwner?.name }}</div>
                        <div *ngIf="element.isInEditMode" class="editmode">
                            <mat-select [(value)]="element.tcoSysOwner" [compareWith]="compareTCOUsers">
                                <mat-option
                                    *ngFor="let so of projectTeamNameMap[element.projectTeamName].sysOwners"
                                    [value]="so"
                                    >{{ so.name }}
                                </mat-option>
                            </mat-select>
                            <span
                                *ngIf="element.tcoSysOwner != null"
                                (click)="element.tcoSysOwner = null"
                                class="cancel-small"
                                ><mat-icon>cancel</mat-icon></span
                            >
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="TCOACManager">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'TCOACManager',
                                getACMs,
                                'columnConstructionManager',
                                'Area Construction Manager',
                                columnConstructionManager,
                                true
                            )
                        "
                    >
                        <div class="sort-container">
                            <span
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnConstructionManager)
                                }"
                                >Area Construction Manager</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'TCOACManager'">
                                <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.isInEditMode">{{ element.tcoacManager?.name }}</div>
                        <div *ngIf="element.isInEditMode" class="editmode">
                            <mat-select [(value)]="element.tcoacManager" [compareWith]="compareTCOUsers">
                                <mat-option
                                    *ngFor="let acm of projectTeamNameMap[element.projectTeamName].acms"
                                    [value]="acm"
                                    >{{ acm.name }}
                                </mat-option>
                            </mat-select>
                            <span
                                *ngIf="element.tcoacManager != null"
                                (click)="element.tcoacManager = null"
                                class="cancel-small"
                                ><mat-icon>cancel</mat-icon></span
                            >
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="TCOMCEngineer">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'TCOMCEngineer',
                                getMcEngineers,
                                'columnMCEngineer',
                                'MC Engineer',
                                columnMCEngineer,
                                true
                            )
                        "
                    >
                        <div class="sort-container">
                            <span
                                [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnMCEngineer)
                                }"
                                >MC Engineer</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'TCOMCEngineer'">
                                <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.isInEditMode">{{ element.tcomcEngineer?.name }}</div>
                        <div *ngIf="element.isInEditMode" class="editmode">
                            <mat-select [(value)]="element.tcomcEngineer" [compareWith]="compareTCOUsers">
                                <mat-option
                                    *ngFor="let mce of projectTeamNameMap[element.projectTeamName].mcEngineers"
                                    [value]="mce"
                                    >{{ mce.name }}
                                </mat-option>
                            </mat-select>
                            <span
                                *ngIf="element.tcomcEngineer != null"
                                (click)="element.tcomcEngineer = null"
                                class="cancel-small"
                                ><mat-icon>cancel</mat-icon></span
                            >
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="MCPlan">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        class="hover-pointer"
                        (click)="openHeaderDateFilter('mcPlan', 'columnMCPlan', 'MC Plan', columnMCPlan)"
                    >
                        <div class="sort-container">
                            <span
                                [ngClass]="{
                                    'header-underline': showUnderlineForCalendarColumn(columnMCPlan)
                                }"
                                >MC Plan Date</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'mcPlan'">
                                <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.isInEditMode">{{ element.mcPlan | date: 'd/MMM/yy' }}</div>
                        <div *ngIf="element.isInEditMode" class="editdate editmode">
                            <span (click)="openDatepicker(element, 'mcPlan')">{{
                                element.mcPlan | date: 'd/MMM/yy'
                            }}</span>
                            <mat-icon (click)="openDatepicker(element, 'mcPlan')" *ngIf="!element.mcPlan"
                                >calendar_today
                            </mat-icon>
                            <span *ngIf="element.mcPlan" (click)="element.mcPlan = null" class="cancel-small"
                                ><mat-icon>cancel</mat-icon></span
                            >
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="RFSUPlan">
                    <th
                        mat-header-cell
                        disableClear
                        *matHeaderCellDef
                        class="hover-pointer"
                        (click)="openHeaderDateFilter('rfsuPlan', 'columnRFSUPlan', 'RFSU Plan', columnRFSUPlan)"
                    >
                        <div class="sort-container">
                            <span
                                [ngClass]="{
                                    'header-underline': showUnderlineForCalendarColumn(columnRFSUPlan)
                                }"
                                >RFSU Plan Date</span
                            >
                            <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                                <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.isInEditMode">{{ element.rfsuPlan | date: 'd/MMM/yy' }}</div>
                        <div *ngIf="element.isInEditMode" class="editdate editmode">
                            <span (click)="openDatepicker(element, 'rfsuPlan')">{{
                                element.rfsuPlan | date: 'd/MMM/yy'
                            }}</span>
                            <mat-icon (click)="openDatepicker(element, 'rfsuPlan')" *ngIf="!element.rfsuPlan"
                                >calendar_today
                            </mat-icon>
                            <span *ngIf="element.rfsuPlan" (click)="element.rfsuPlan = null" class="cancel-small"
                                ><mat-icon>cancel</mat-icon></span
                            >
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell disableClear *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            *ngIf="!element.isInEditMode"
                            mat-icon-button
                            (click)="enterEditMode(element)"
                            title="edit"
                        >
                            <mat-icon svgIcon="edit"></mat-icon>
                        </button>
                        <button *ngIf="element.isInEditMode" mat-icon-button (click)="save(element)" title="accept">
                            <mat-icon>check_circle_outline</mat-icon>
                        </button>
                        <button
                            *ngIf="element.isInEditMode"
                            mat-icon-button
                            (click)="cancelEdit(element)"
                            title="cancel"
                        >
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 15, 20]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
    </form>
</div>

<input
    hidden
    matInput
    [min]="todayDate"
    (dateChange)="onDatepickerClosed()"
    [matDatepicker]="datepicker"
    [formControl]="datepickerSelectedDate"
/>
<mat-datepicker touchUi #datepicker="matDatepicker"></mat-datepicker>

<app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
