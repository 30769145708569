<div class="results-container">
    <div class="title-container">
        <div class="result-table-title">
            Design Status Mapping
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" matSortDisableClear>
        <ng-container matColumnDef="id">
            <th mat-header-cell disableClear *matHeaderCellDef>id</th>
            <td mat-cell *matCellDef="let element">
                <ng-container>{{ element.id }}</ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="designStatus">
            <th mat-header-cell disableClear *matHeaderCellDef>SCMT</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.designStatus }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="fieldImpact">
            <th mat-header-cell disableClear *matHeaderCellDef>Field Impact</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.fieldImpact }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="sourceFileStatus">
            <th mat-header-cell disableClear *matHeaderCellDef>Source File "Status"</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.sourceFileStatus }}</div>
            </td>
        </ng-container>
        <ng-container *ngIf="changeType === rfiType" matColumnDef="rfiImpact">
            <th mat-header-cell disableClear *matHeaderCellDef>RFI Impact</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.rfiImpact }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="revision">
            <th mat-header-cell disableClear *matHeaderCellDef>Revision</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.revision }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="remove">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="remove(element)" title="remove">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-toolbar>
        <span>Add new Design Status Mapping</span>
        <button mat-icon-button (click)="add()" title="add">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </mat-toolbar>
    <app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
</div>