import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { take, takeWhile } from 'rxjs/operators';
import { ManualUploadSetActivatedRoute } from 'src/app/store/manual-upload/actions';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { RFODeltaDTO, RFODeleteDTO } from 'src/app/store/manual-upload/rfo/model';
import { Subscription } from 'rxjs';
import {
    RFOUploadLogRequest,
    RFOPatchDeltaRequest,
    RFOPatchDeleteRecordRequest,
    RFOTemplateFileRequest,
    RFODeltaFilterPropertyUpdate,
    RFODeltaRequest,
    RFODeleteFilterPropertyUpdate,
    RFODeleteDataRequest,
    RFODownloadOutputDataRequest,
    RFOPatchAllDeltaRequest,
    RFOPatchDeleteAllRecordsRequest,
} from 'src/app/store/manual-upload/rfo/actions';

@Component({
    selector: 'app-rfo',
    templateUrl: './rfo.component.html',
    styleUrls: ['./rfo.component.scss'],
})
export class RFOImportComponent extends BaseComponent implements OnInit {
    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    deltaData: MatTableDataSource<RFODeltaDTO>;
    deltaDataResultsLength = 0;
    deltaDataPageSize = 10;
    displayedDeltaDataColumns = ['RFO', 'RFOName', 'RFOPlan', 'RFOActual', 'projectTeam', 'actions'];
    deleteData: MatTableDataSource<RFODeleteDTO>;
    deleteDataResultsLength = 0;
    deleteDataPageSize = 10;
    displayedDeleteDataColumns = ['rfoId', 'RFOName', 'RFOPlan', 'RFOActual', 'projectTeam', 'actions'];

    uploadLogData$ = this.store.select((store) => store.rfoImportState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.rfoImportState.isUploadLogLoading);
    deltaData$ = this.store.select((store) => store.rfoImportState.deltaData);
    isDeltaDataLoading$ = this.store.select((store) => store.rfoImportState.isDeltaDataLoading);
    deleteData$ = this.store.select((store) => store.rfoImportState.deleteData);
    isDeleteDataLoading$ = this.store.select((store) => store.rfoImportState.isDeleteDataLoading);
    isLoading$ = this.store.select((store) => store.rfoImportState.isLoading);
    interval$ = this.store.select((store) => store.manualUploadState.interval);
    intervalSub: Subscription = null;
    showOrder = true;

    constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new ManualUploadSetActivatedRoute(urlSegments[0].path));
        });

        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new RFOUploadLogRequest());
            });
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

        this.deltaData$.pipe(takeWhile(() => this.isAlive)).subscribe((deltaData) => {
            this.deltaData = new MatTableDataSource(deltaData.items);
            this.deltaDataResultsLength = deltaData.totalCount;
        });

        this.deleteData$.pipe(takeWhile(() => this.isAlive)).subscribe((deleteData) => {
            this.deleteData = new MatTableDataSource(deleteData.items);
            this.deleteDataResultsLength = deleteData.totalCount;
        });
    }

    rejectChange(element: RFODeltaDTO) {
        element.deltaState = false;

        this.store.dispatch(
            new RFOPatchDeltaRequest({
                RFOId: element.rfoId,
                deltaState: element.deltaState,
            })
        );
    }

    acceptChange(element: RFODeltaDTO) {
        element.deltaState = true;

        this.store.dispatch(
            new RFOPatchDeltaRequest({
                RFOId: element.rfoId,
                deltaState: element.deltaState,
            })
        );
    }

    patchAllChanges(deltaState: boolean) {
        this.store.dispatch(
            new RFOPatchAllDeltaRequest({
                deltaState: deltaState,
            })
        );
    }

    deleteRFO(element: RFODeleteDTO) {
        element.deleteState = true;

        this.store.dispatch(
            new RFOPatchDeleteRecordRequest({
                rfoId: element.rfoId,
                deleteState: element.deleteState,
            })
        );
    }

    restoreRFO(element: RFODeleteDTO) {
        element.deleteState = false;

        this.store.dispatch(
            new RFOPatchDeleteRecordRequest({
                rfoId: element.rfoId,
                deleteState: element.deleteState,
            })
        );
    }

    patchAllDeleteRecords(deleteState: boolean) {
        this.store.dispatch(
            new RFOPatchDeleteAllRecordsRequest({
                deleteState: deleteState,
            })
        );
    }

    downloadTemplate(fileName: string) {
        this.store.dispatch(new RFOTemplateFileRequest(fileName));
    }

    onDeltaDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deltaDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new RFODeltaFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deltaDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new RFODeltaFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new RFODeltaRequest());
    }

    onDeleteDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deleteDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new RFODeleteFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deleteDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new RFODeleteFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new RFODeleteDataRequest());
    }

    downloadData(filePath: string) {
        this.store.dispatch(new RFODownloadOutputDataRequest(filePath));
    }
}
