import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { ScheduleActivityDeleteFilter } from 'src/app/store/planning-upload/schedule-activity/model';
import { ActivityBarcodeDeleteFilter } from 'src/app/store/planning-upload/activity-barcode/model';
import { ExceptionsPriorityDeleteFilter } from 'src/app/store/planning-upload/exceptions-priority/model';
import { ITRManhoursDeleteFilter } from 'src/app/store/planning-upload/itr-manhours/model';
import { ITRConstraintsDeleteFilter } from 'src/app/store/planning-upload/itr-constraints/model';
import { ITRConstraintsContactsDeleteFilter } from 'src/app/store/planning-upload/itr-constraints-contacts/model';
import { CITRPartialProgressDeleteFilter } from 'src/app/store/planning-upload/citr-partial-progress/model';
import { CriticalPathDeleteFilter } from 'src/app/store/planning-upload/critical-path/model';
import { ExceptionsUploadDeleteFilter } from 'src/app/store/planning-upload/exceptions-upload/model';
import { RFODatesUploadDeleteFilter } from 'src/app/store/planning-upload/rfo-dates-upload/model';

@Injectable({
    providedIn: 'root',
})
export class ManualUploadService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly manualUploadsEndpoint: string = '/manualupload';

    constructor(private http: HttpClient) {}

    getPlanningDataDelta(page: number, take: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/planning/delta`, { params });
    }

    getScheduleActivityDeleteRecords(filter: ScheduleActivityDeleteFilter) {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/scheduleactivity/deleterecords`, filter);
    }

    getRFODatesUploadDeleteRecords(filter: RFODatesUploadDeleteFilter) {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/rfo/deleterecords`, filter);
    }

    getActivityBarcodeDeleteRecords(filter: ActivityBarcodeDeleteFilter) {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/ActivityBarcode/deleterecords`, filter);
    }

    getExceptionsPriorityDeleteRecords(filter: ExceptionsPriorityDeleteFilter) {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/ExceptionsPriority/deleterecords`, filter);
    }

    getCriticalPathDeleteRecords(filter: CriticalPathDeleteFilter) {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/CriticalPath/deleterecords`, filter);
    }

    getCITRPartialProgressDeleteRecords(filter: CITRPartialProgressDeleteFilter) {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/CITRPartialProgress/deleterecords`, filter);
    }

    getITRManhoursDeleteRecords(filter: ITRManhoursDeleteFilter) {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/ITRManhours/deleterecords`, filter);
    }

    getCWPRegisterDelta(page: number, take: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/cwp/delta`, { params });
    }

    getMasterSubsystemListDelta(page: number, take: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/delta`, { params });
    }

    getRFODelta(page: number, take: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/rfo/delta`, { params });
    }

    getCWPRegisterDeleteRecords(page: number, take: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/cwp/deleterecords`, { params });
    }

    getMasterSubsystemListDeleteRecords(page: number, take: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/deleterecords`, { params });
    }

    getRFODeleteRecords(page: number, take: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/rfo/deleterecords`, { params });
    }

    patchScheduleActivityDeleteRecord(activityId: string, activityDiscipline: string, deleteState: boolean) {
        return this.http.patch(
            `${this.rootUrl}${this.manualUploadsEndpoint}/scheduleactivity/delete/${activityId}/${activityDiscipline}`,
            [{ op: 'replace', path: '/DeleteState', value: deleteState }]
        );
    }

    patchActivityBarcodeDeleteRecord(barcode: string, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/ActivityBarcode/delete/${barcode}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
        ]);
    }

    patchExceptionsPriorityDeleteRecord(exception: string, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/ExceptionsPriority/delete/${exception}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
        ]);
    }

    patchCriticalPathDeleteRecord(activityId: string, deleteState: boolean) {
        return this.http.patch(
            `${this.rootUrl}${this.manualUploadsEndpoint}/CriticalPath/delete/${activityId}`,
            [{ op: 'replace', path: '/DeleteState', value: deleteState }]
        );
    }

    patchCITRPartialProgressDeleteRecord(barcode: string, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/CITRPartialProgress/delete/${barcode}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
        ]);
    }

    patchITRManhoursDeleteRecord(qvdnumber: string, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/ITRManhours/delete/${qvdnumber}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
        ]);
    }

    patchPlanningDataDelta(subsystem: string, deltaState: boolean) {
        return this.http.patch(
            `${this.rootUrl}${this.manualUploadsEndpoint}/planning/delta/${encodeURIComponent(subsystem)}`,
            [{ op: 'replace', path: '/DeltaState', value: deltaState }]
        );
    }

    patchAllScheduleActivityDeleteRecords(filter: ScheduleActivityDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/scheduleactivity/deleterecords/update`,
            filter,
            { params }
        );
    }

    patchAllRFODatesUploadDeleteRecords(filter: RFODatesUploadDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/RFODatesUpload/deleterecords/update`,
            filter,
            { params }
        );
    }

    patchAllActivityBarcodeDeleteRecords(filter: ActivityBarcodeDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/ActivityBarcode/deleterecords/update`,
            filter,
            { params }
        );
    }

    patchAllExceptionsPriorityDeleteRecords(filter: ExceptionsPriorityDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/ExceptionsPriority/deleterecords/update`,
            filter,
            { params }
        );
    }

    patchAllCriticalPathDeleteRecords(filter: CriticalPathDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/CriticalPath/deleterecords/update`,
            filter,
            { params }
        );
    }

    patchAllCITRPartialProgressBarcodeDeleteRecords(filter: CITRPartialProgressDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/CITRPartialProgress/deleterecords/update`,
            filter,
            { params }
        );
    }

    patchAllITRManhoursDeleteRecords(filter: ITRManhoursDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/ITRManhours/deleterecords/update`, filter, {
            params,
        });
    }

    patchAllPlanningDataDelta(deltaState: boolean) {
        let params = new HttpParams();
        params = params.append('deltaState', `${deltaState}`);
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/planning/delta`, null, { params });
    }

    patchCWPRegisterDelta(cwp: string, deltaState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/cwp/delta/${cwp}`, [
            { op: 'replace', path: '/DeltaState', value: deltaState },
        ]);
    }

    patchAllCWPRegisterDelta(deltaState: boolean) {
        let params = new HttpParams();
        params = params.append('deltaState', `${deltaState}`);
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/cwp/delta`, null, { params });
    }

    patchMasterSubsystemListDelta(subsystem: string, deltaState: boolean) {
        return this.http.patch(
            `${this.rootUrl}${this.manualUploadsEndpoint}/msl/delta/${encodeURIComponent(subsystem)}`,
            [{ op: 'replace', path: '/DeltaState', value: deltaState }]
        );
    }

    patchMasterSubsystemListAllDelta(deltaState: boolean) {
        let params = new HttpParams();
        params = params.append('deltaState', `${deltaState}`);
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/delta`, null, { params });
    }

    patchRFODelta(rfoId: string, deltaState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/rfo/delta/${rfoId}`, [
            { op: 'replace', path: '/DeltaState', value: deltaState },
        ]);
    }

    patchRFOAllDelta(deltaState: boolean) {
        let params = new HttpParams();
        params = params.append('deltaState', `${deltaState}`);

        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/rfo/delta`, null, { params });
    }

    patchCWPRegisterDeleteRecord(cwp: string, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/cwp/delete/${cwp}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
        ]);
    }

    patchCWPRegisterDeleteAllRecords(deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/cwp/deleterecords`, null, { params });
    }

    patchMasterSubsystemListDeleteRecord(subsystem: string, deleteState: boolean) {
        return this.http.patch(
            `${this.rootUrl}${this.manualUploadsEndpoint}/msl/delete/${encodeURIComponent(subsystem)}`,
            [{ op: 'replace', path: '/DeleteState', value: deleteState }]
        );
    }

    patchMasterSubsystemListDeleteAllRecords(deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/deleterecords`, null, { params });
    }

    patchRFODeleteRecord(rfoId: string, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/rfo/delete/${rfoId}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
        ]);
    }

    patchRFODeleteAllRecords(deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);

        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/rfo/deleterecords`, null, { params });
    }

    validatePlanningDataDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/planning/validate`);
    }

    validateScheduleActivityData() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/scheduleactivity/validate`);
    }

    validateActivityBarcodeData() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/ActivityBarcode/validate`);
    }

    validateExceptionsPriorityData() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/ExceptionsPriority/validate`);
    }

    validateCriticalPathData() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/CriticalPath/validate`);
    }

    validateITRManhoursData() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/ITRManhours/validate`);
    }

    validateFlangeManagementDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/flangemanagement/validate`);
    }

    validateCWPRegisterDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/cwp/validate`);
    }

    validateIWPDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/iwp/validate`);
    }

    validateRedlineDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/redline/validate`);
    }

    validateRFODelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/rfo/validate`);
    }

    validateExceptionsDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/exceptions/validate`);
    }

    validateIMDBDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/imdb/validate`);
    }

    validateLoopDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/loop/validate`);
    }

    validateMasterSubsystemListDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/validate`);
    }

    validateCertificatesDelta() {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/certificate/validate`, {});
    }

    validateMCPlusDelta() {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/mcplus/validate`, {});
    }

    validateTagsDelta() {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/tags/validate`, {});
    }

    validatePLIDelta() {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/pli/validate`, {});
    }

    validateITRDelta() {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/itr/validate`, {});
    }

    validateSystemizedDrawingsDelta() {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/systemizeddrawings/validate`, {});
    }

    downloadTemplate(fileName: string) {
        return this.http.get<BlobPart>(`${this.rootUrl}/admin/downloadtemplate/${fileName}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    validateCOWDelta() {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/cow/validate`, {});
    }

    validateCITRPartialProgressData() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/CITRPartialProgress/validate`);
    }

    getValidateButtonState(type: string) {
        if (
            type.toUpperCase() === 'CWP' ||
            type === 'Subsystem' ||
            type === 'PlanningData' ||
            type === 'ITR' ||
            type === 'PLI' ||
            type === 'Tags' ||
            type === 'IWP' ||
            type === 'RFO' ||
            type === 'MCPlus' ||
            type === 'SystemizedDrawings' ||
            type === 'COW' ||
            type === 'FlangeManagement' ||
            type === 'Redline' ||
            type === 'RedlineRegister' ||
            type === 'ScheduleActivity' ||
            type === 'ActivityBarcode' ||
            type === 'ExceptionsPriority' ||
            type === 'ITRManhours' ||
            type === 'ITRConstraints' ||
            type === 'CITRPartialProgress'
        ) {
            return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/${type}/validationState`);
        }

        let params = new HttpParams();
        params = params.append('types', type);
        return this.http.get(`${this.rootUrl}admin/getDataInfo`, { params });
    }
    getITRConstraintsDeleteRecords(filter: ITRConstraintsDeleteFilter) {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/ITRConstraints/deleterecords`, filter);
    }
    getITRConstraintsContactsDeleteRecords(filter: ITRConstraintsContactsDeleteFilter) {
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/ITRConstraintsContcats/deleterecords`,
            filter
        );
    }
    patchITRConstraintsDeleteRecord(constraintId: string, constraintStatus: string, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/ITRConstraints/delete/${constraintId}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
            { op: 'replace', path: '/ConstraintStatus', value: constraintStatus },
        ]);
    }
    patchITRConstraintsContactsDeleteRecord(constraintType: string, deleteState: boolean) {
        return this.http.patch(
            `${this.rootUrl}${this.manualUploadsEndpoint}/ITRConstraintsContacts/delete/${constraintType}`,
            [{ op: 'replace', path: '/DeleteState', value: deleteState }]
        );
    }
    patchAllITRConstraintsDeleteRecords(filter: ITRConstraintsDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/ITRConstraints/deleterecords/update`,
            filter,
            { params }
        );
    }
    patchAllITRConstraintsContactsDeleteRecords(filter: ITRConstraintsContactsDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/ITRConstraintsContacts/deleterecords/update`,
            filter,
            { params }
        );
    }
    validateITRConstraintsData() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/ITRConstraints/validate`);
    }
    validateITRConstraintsContactsData() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/ITRConstraintsContacts/validate`);
    }

    getExceptionsUploadDeleteRecords(filter: ExceptionsUploadDeleteFilter) {
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/ExceptionsUpload/deleterecords`, filter);
    }

    patchExceptionsUploadDeleteRecord(barcode: string, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/CITRPartialProgress/delete/${barcode}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
        ]);
    }

    patchAllExceptionsUploadDeleteRecords(filter: ExceptionsUploadDeleteFilter, deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(
            `${this.rootUrl}${this.manualUploadsEndpoint}/CITRPartialProgress/deleterecords/update`,
            filter,
            { params }
        );
    }

    validateExceptionsUploadData() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/CITRPartialProgress/validate`);
    }
}
