<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">GOOC Comparison Dashboard 1</div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.goocs.value.length" removable>
                                GOOC: {{ displayGoocsSelected(filterForm.controls.goocs.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('goocs')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcEngineer.value.length" removable>
                                MC Engineer: {{ displaySelectedMCEngineer(filterForm.controls.mcEngineer.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('mcEngineer')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                System Owner: {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <app-multiple-items-selector
                        class="container620 margin-right20"
                        [setInput]="setGoocInput"
                        formControlName="goocs"
                        [searchFunc]="getGoocs"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="goocAutocompleteDisplayedColumns"
                        [filterForm]="filterForm"
                        [formSetter]="'goocs'"
                        [propertyToShow]="'no'"
                        [placeholder]="'GOOC'"
                    >
                    </app-multiple-items-selector>

                    <mat-form-field class="container300 margin-right20">
                        <mat-label>MC Engineer</mat-label>
                        <mat-select formControlName="mcEngineer" multiple>
                            <mat-option *ngFor="let mce of mcEngineers" [value]="mce.id">{{ mce.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwner" multiple>
                            <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mixedapp-container">
                    <div class="container300 margin-right20">
                        <app-multiple-items-selector
                            [setInput]="setContractorInput"
                            formControlName="contractors"
                            [searchFunc]="getContractors"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            (autocompleteClosed)="onContractorsClosed()"
                            (itemRemoved)="onContractorsClosed()"
                            [filterForm]="filterForm"
                            formSetter="contractors"
                            propertyToShow="contractNo"
                            placeholder="MSL Contractor"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManager" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
    <div class="buttons-container flex">
        <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
            export to excel
        </button>
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filter
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">
            search
        </button>
    </div>
    <div class="results-container">
        <div
            [ngClass]="{
                'scroll-container': scrollSearch
            }"
        >
            <table mat-table [dataSource]="data">
                <ng-container matColumnDef="gooc">
                    <th mat-header-cell disableClear *matHeaderCellDef>GOOC</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.gooc }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell disableClear *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.goocName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="subsystemMCs">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>Subsystem MCs</div>
                        <div>
                            <div>Total</div>
                            <div>Complete</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.subsystemMCs?.total }}</div>
                            <div>{{ element.subsystemMCs?.complete }}</div>
                            <div>{{ element.subsystemMCs?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="mcWalkdowns">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>MC Walkdowns</div>
                        <div>
                            <div>Total</div>
                            <div>Complete</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.mcWalkdowns?.total }}</div>
                            <div>{{ element.mcWalkdowns?.complete }}</div>
                            <div>{{ element.mcWalkdowns?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="aqvds">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>A-QVDs</div>
                        <div>
                            <div>Total</div>
                            <div>Complete</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.aqvd?.total }}</div>
                            <div>{{ element.aqvd?.complete }}</div>
                            <div>{{ element.aqvd?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="apli">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>A-PLI</div>
                        <div>
                            <div>Total</div>
                            <div>Complete</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.apli?.total }}</div>
                            <div>{{ element.apli?.complete }}</div>
                            <div>{{ element.apli?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="npw">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>NPW</div>
                        <div>
                            <div>Total</div>
                            <div>Complete</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.npw?.total }}</div>
                            <div>{{ element.npw?.complete }}</div>
                            <div>{{ element.npw?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="redlines">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>Redlines</div>
                        <div>
                            <div>Total</div>
                            <div>Complete</div>
                            <div>%</div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>{{ element.redlines?.total }}</div>
                            <div>{{ element.redlines?.complete }}</div>
                            <div>{{ element.redlines?.percentComplete }}%</div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
                [disabled]="scrollSearch"
            >
            </mat-paginator>
            <input
                type="number"
                [value]="this.paginator.pageIndex + 1"
                (change)="onPageChange($event.target.value)"
                [disabled]="scrollSearch"
            />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading$ | async"></app-loading-indicator>
</main>
