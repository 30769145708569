<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span>Exceptions Table</span>
        </div>
    </div>

    <app-overdue-exceptions-filter></app-overdue-exceptions-filter>
    <app-overdue-exceptions-chart></app-overdue-exceptions-chart>
    <app-overdue-exceptions-table></app-overdue-exceptions-table>

    <app-loading-indicator *ngIf="(isTableLoading$ | async) || (isChartLoading$ | async)"></app-loading-indicator>
</main>
