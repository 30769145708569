import { Action } from "@ngrx/store";
import { LeakTestPackTrackerSearchDTO, LeakTestPackTrackerSearchDataPagination } from "./model";

export enum LeakTestPackTrackerSearchActionTypes
{
    LeakTestPackTrackerSearchFilterRequest = '[Leak Test Pack Tracker Search] Filter Request',
    LeakTestPackTrackerSearchFilterSuccess = '[Leak Test Pack Tracker Search] Filter Success',
    LeakTestPackTrackerSearchFilterError = '[Leak Test Pack Tracker Search] Filter Error',
    LeakTestPackTrackerSearchFilterPropertyUpdate = '[Leak Test Pack Tracker Search] Property Update',
    LeakTestPackTrackerSearchFilterReset = '[Leak Test Pack Tracker Search] Filter Reset',
    LeakTestPackTrackerSearchExportToExcelRequest = '[Leak Test Pack Tracker Search] Export to Excel Request',
    LeakTestPackTrackerSearchExportToExcelSuccess = '[Leak Test Pack Tracker Search] Export to Excel Success',
    LeakTestPackTrackerSearchExportToExcelError = '[Leak Test Pack Tracker Search] Export to Excel Error',
    LeakTestPackTrackerSearchUpdateDisplayProperty = '[Leak Test Pack Tracker Search] Update Display Property',
    LeakTestPackTrackerSearchPanelVisible = '[Leak Test Pack Tracker Search] Panel Visible',
    LeakTestPackTrackerSearchUpdatePriorityRequest = '[Leak Test Pack Tracker Search] Update Priority Request',
    LeakTestPackTrackerSearchUpdatePrioritySuccess = '[Leak Test Pack Tracker Search] Update Priority Success',
    LeakTestPackTrackerSearchUpdatePriorityError = '[Leak Test Pack Tracker Search] Update Priority Error',
}

export class LeakTestPackTrackerSearchFilterRequest implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterRequest;
}

export class LeakTestPackTrackerSearchFilterSuccess implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterSuccess;

    constructor(public payload: LeakTestPackTrackerSearchDataPagination) {}
}

export class LeakTestPackTrackerSearchFilterError implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterError;

    constructor(public payload: string) {}
}

export class LeakTestPackTrackerSearchFilterPropertyUpdate implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class LeakTestPackTrackerSearchFilterReset implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterReset;
}

export class LeakTestPackTrackerSearchExportToExcelRequest implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchExportToExcelRequest;
}

export class LeakTestPackTrackerSearchExportToExcelSuccess implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class LeakTestPackTrackerSearchExportToExcelError implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchExportToExcelError;

    constructor(public payload: string) {}
}

export class LeakTestPackTrackerSearchUpdateDisplayProperty implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchUpdateDisplayProperty;
    constructor( public payload :{ leakpackTracker:LeakTestPackTrackerSearchDTO, tagNo:string,value:boolean }){}
}

export class LeakTestPackTrackerSearchPanelVisible implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchPanelVisible;
    constructor( public payload :{ leakpackTracker:LeakTestPackTrackerSearchDTO, value:boolean }){}
}

export class LeakTestPackTrackerSearchUpdatePriorityRequest implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchUpdatePriorityRequest;
    constructor(public payload: { ltp: LeakTestPackTrackerSearchDTO }) {}
}

export class LeakTestPackTrackerSearchUpdatePrioritySuccess implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchUpdatePrioritySuccess;
    constructor(public payload: {ltp: LeakTestPackTrackerSearchDTO }) {}
}

export class LeakTestPackTrackerSearchUpdatePriorityError implements Action {
    readonly type = LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchUpdatePriorityError;
    constructor(public payload: string) {}
}

export type LeakTestPackTrackerSearchAction = 
    | LeakTestPackTrackerSearchFilterRequest
    | LeakTestPackTrackerSearchFilterSuccess
    | LeakTestPackTrackerSearchFilterError
    | LeakTestPackTrackerSearchFilterPropertyUpdate
    | LeakTestPackTrackerSearchFilterReset
    | LeakTestPackTrackerSearchExportToExcelRequest
    | LeakTestPackTrackerSearchExportToExcelSuccess
    | LeakTestPackTrackerSearchExportToExcelError
    | LeakTestPackTrackerSearchUpdateDisplayProperty
    | LeakTestPackTrackerSearchPanelVisible
    | LeakTestPackTrackerSearchUpdatePriorityRequest
    | LeakTestPackTrackerSearchUpdatePrioritySuccess
    | LeakTestPackTrackerSearchUpdatePriorityError;