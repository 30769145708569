import { ChangeImpactedTagsState, ChangeImpactedTagsDataPagination, ChangeImpactedTagsFilter } from './model';
import { ChangeImpactedTagsActions, ChangeImpactedTagsActionTypes } from './actions';

const initialState: ChangeImpactedTagsState = {
    dataPagination: new ChangeImpactedTagsDataPagination(),
    filter: new ChangeImpactedTagsFilter(),
    isLoading: false,
};

export function reducer(state = initialState, action: ChangeImpactedTagsActions) {
    switch (action.type) {
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: action.payload,
            };
        }
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsUploadError:
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsNewDataSetError:
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsNewDataSetSuccess:
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                state.filter.sortBy = action.payload.value.active;
                state.filter.direction = action.payload.value.direction;
            } else {
                if (Array.isArray(state.filter[action.payload.key])) {
                    state.filter[action.payload.key] = Object.assign([], action.payload.value);
                } else {
                    state.filter[action.payload.key] = action.payload.value;
                }
            }
            return {
                ...state,
            };
        }
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterReset: {
            const newFilter = new ChangeImpactedTagsFilter();
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsUpdateSetRequest: {
            const dcn = state.dataPagination.items.find((s) => s.id === action.payload.id);
            if (dcn) {
                dcn.impactedTags = [...action.payload.impactedTags];
            }

            return {
                ...state,
                isLoading: true,
            };
        }
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsUploadRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ChangeImpactedTagsActionTypes.ChangeImpactedTagsUploadSuccess: {
            const dcn = state.dataPagination.items.find((s) => s.id === action.payload.id);
            dcn.impactedTags = [...action.payload.tags];
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}
