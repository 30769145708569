import { Action } from '@ngrx/store';
import * as moment from 'moment';
import { UserDetail } from '../common.model';
import { CommentDetailDTO } from '../detailed-status/model';
import { LoopStatusDataPagination, LoopStatusFilter } from './model';

export enum LoopStatusActionTypes {
    LoopStatusFilterRequest = '[Loop Status] Filter Request',
    LoopStatusFilterSuccess = '[Loop Status] Filter Success',
    LoopStatusFilterError = '[Loop Status] Filter Error',
    LoopStatusFilterPropertyUpdate = '[Loop Status] Property Update',
    LoopStatusFilterReset = '[Loop Status] Filter Reset',
    LoopStatusExportToExcelRequest = '[Loop Status] Export to Excel Request',
    LoopStatusExportToExcelSuccess = '[Loop Status] Export to Excel Success',
    LoopStatusExportToExcelError = '[Loop Status] Export to Excel Error',
    LoopStatusAddCommentRequest = '[Loop Status] Add Comment Request',
    LoopStatusAddCommentSuccess = '[Loop Status] Add Comment Success',
    LoopStatusAddCommentError = '[Loop Status] Add Comment Error',
    LoopStatusAddBulkCommentRequest = '[Loop Status] Add Bulk Comment Request',
    LoopStatusAddBulkCommentSuccess = '[Loop Status] Add Bulk Comment Success',
    LoopStatusAddBulkCommentError = '[Loop Status] Add Bulk Comment Error',
    LoopStatusUpdateRequest = '[Loop Status] Update Request',
    LoopStatusUpdateSuccess = '[Loop Status] Update Success',
    LoopStatusUpdateError = '[Loop Status] Update Error',

    LoopStatusRemoveCommentRequest = '[Loop Status] Remove Comment Request',
    LoopStatusRemoveCommentSuccess = '[Loop Status] Remove Comment Success',
    LoopStatusRemoveCommentError = '[Loop Status] Remove Comment Error',
}

export class LoopStatusUpdateRequest implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusUpdateRequest;

    constructor(
        public payload: { loopName: string; tagNumber: string; tagType: string; value: string; updateKey: string; default: string;
            /*activiyStartDate: string;mcActual:string;*/objectKey?: string; loopfolderRequest?:string; }
    ) {}
}

export class LoopStatusUpdateSuccess implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusUpdateSuccess;

    constructor(
        public payload: { loopName: string; tagNumber: string; tagType: string; value: string;
             updateKey: string; default: string;/*activiyStartDate: string;mcActual:string ;*/ objectKey?: string;loopfolderRequest?:string; }
    ) {}
}

export class LoopStatusUpdateError implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusUpdateError;

    constructor(public payload: string) {}
}
export class LoopStatusAddCommentRequest implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusAddCommentRequest;

    constructor(public payload: { loopName: string; tagNumber: string; tagType: string; description: string; mentions: UserDetail[] }) {}
}

export class LoopStatusAddCommentSuccess implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusAddCommentSuccess;

    constructor(public payload: { loopName: string; tagNumber: string; tagType: string; description: string; mentions: UserDetail[] }) {}
}

export class LoopStatusAddCommentError implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusAddCommentError;

    constructor(public payload: string) {}
}

export class LoopStatusAddBulkCommentRequest implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusAddBulkCommentRequest;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class LoopStatusAddBulkCommentSuccess implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusAddBulkCommentSuccess;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class LoopStatusAddBulkCommentError implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusAddBulkCommentError;

    constructor(public payload: string) {}
}

export class LoopStatusRemoveCommentRequest implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusRemoveCommentRequest;

    constructor(public payload: { id: number; loopName: string; tagNumber: string; tagType: string; lastComment: CommentDetailDTO }) {}
}

export class LoopStatusRemoveCommentSuccess implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusRemoveCommentSuccess;

    constructor(public payload: { id: number; loopName: string; tagNumber: string; tagType: string; lastComment: CommentDetailDTO }) {}
}

export class LoopStatusRemoveCommentError implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusRemoveCommentError;

    constructor(public payload: string) {}
}

export class LoopStatusFilterRequest implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusFilterRequest;

    constructor(public payload: LoopStatusFilter = null) {}
}

export class LoopStatusFilterSuccess implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusFilterSuccess;

    constructor(public payload: LoopStatusDataPagination) {}
}

export class LoopStatusFilterError implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusFilterError;

    constructor(public payload: string) {}
}

export class LoopStatusFilterPropertyUpdate implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class LoopStatusFilterReset implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusFilterReset;
}

export class LoopStatusExportToExcelRequest implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusExportToExcelRequest;
}

export class LoopStatusExportToExcelSuccess implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class LoopStatusExportToExcelError implements Action {
    readonly type = LoopStatusActionTypes.LoopStatusExportToExcelError;

    constructor(public payload: string) {}
}

export type LoopStatusActions =
    | LoopStatusFilterRequest
    | LoopStatusFilterSuccess
    | LoopStatusFilterError
    | LoopStatusFilterPropertyUpdate
    | LoopStatusFilterReset
    | LoopStatusExportToExcelRequest
    | LoopStatusExportToExcelSuccess
    | LoopStatusExportToExcelError
    | LoopStatusAddCommentRequest
    | LoopStatusAddCommentSuccess
    | LoopStatusAddCommentError
    | LoopStatusAddBulkCommentRequest
    | LoopStatusAddBulkCommentSuccess
    | LoopStatusAddBulkCommentError
    | LoopStatusUpdateRequest
    | LoopStatusUpdateSuccess
    | LoopStatusUpdateError
    | LoopStatusRemoveCommentRequest
    | LoopStatusRemoveCommentSuccess
    | LoopStatusRemoveCommentError;
