import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { Constants } from 'src/app/constants';
import { Config } from 'src/app/models/config';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-source-file-configuration',
    templateUrl: './source-file-configuration.component.html',
    styleUrls: ['./source-file-configuration.component.scss'],
})
export class SourceFileConfigurationComponent extends BaseComponent implements OnInit {
    @Input() uploadType: string;
    @Input() showUploadSource = true;
    isInEditMode: boolean = false;
    configs: Config[];
    uploadFileSourceConfigForm: UntypedFormGroup;
    tooltipMessage = `
  Adjust rules for mapping the "Data Owner" column in upload file to Project Teams in SCMT and rules for mapping the "Discipline" column in upload file to Discipline in SCMT.
  Multiple source values can be mapped to single SCMT value - where there are multiple values, enter as comma-separated (e.g. "J, T" mapped to Instrumentation in SCMT).
  The Project Team data mapping is used for importing records into SCMT.`;

    constructor(private configurationService: ConfigurationService, private toastService: ToastService) {
        super();

        this.uploadFileSourceConfigForm = new UntypedFormGroup({
            importTypeValue: new UntypedFormControl('', Validators.required),
            sourceFilenames: new UntypedFormControl('', Validators.required),
        });
    }

    ngOnInit(): void {
        this.configurationService
            .getTagsSourceFileConfiguration()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((data) => {
                this.configs = data;

                let fileNames = this.configs.find((s) => s.key === Constants.Config.filenamesForTagImport)?.value;
                let importTypes = this.configs.find((s) => s.key === Constants.Config.tagsImportType)?.value;

                this.uploadFileSourceConfigForm.controls['sourceFilenames'].setValue(fileNames);
                this.uploadFileSourceConfigForm.controls['importTypeValue'].setValue(importTypes);
            });
    }

    enterEditMode() {
        this.isInEditMode = !this.isInEditMode;
    }

    cancelEditMode() {
        this.isInEditMode = !this.isInEditMode;
        let fileNames = this.configs.find((s) => s.key === Constants.Config.filenamesForTagImport)?.value;
        this.uploadFileSourceConfigForm.controls['sourceFilenames'].setValue(fileNames);
    }

    saveSourceFileNames() {
        let fileNames = this.uploadFileSourceConfigForm.controls['sourceFilenames'].value;

        if (!fileNames.includes('Tag')) {
            this.toastService.Error('All expected filenames have to contain "Tag" in the filename.');
            return;
        }

        let fileNamesCount = fileNames.split(',').length;
        if (fileNamesCount > 10) {
            this.toastService.Error('Maximum 10 files permitted. Re-enter key.');
            return;
        }

        if (this.findDuplicates(fileNames.split(',').map((s) => s.trim())).length > 0) {
            this.toastService.Error('Duplicate filenames in entry. Re-enter key.');
            return;
        }

        this.isInEditMode = !this.isInEditMode;

        this.configs.find((c) => c.key === Constants.Config.filenamesForTagImport).value = fileNames;
        this.updateConfiguration();
    }

    findDuplicates = (arr: any[]) => arr.filter((item, index) => arr.indexOf(item) != index);

    importTypeChange($event: MatRadioChange) {
        this.configs.find((c) => c.key === Constants.Config.tagsImportType).value = $event.value;
        this.updateConfiguration();
    }

    updateConfiguration() {
        this.configurationService
            .updateConfigData(this.configs)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
                () => {
                    this.toastService.Success('Configuration has been updated.');
                },
                () => {
                    this.toastService.Error(
                        'An error occured while updating configuration. Configuration has not been updated.'
                    );
                }
            );
    }
}
