<div class="results-container">
    <div class="results-title">
        <div class="table-checkboxes">
            <mat-checkbox [checked]="showColumns.showTagType" (change)="reverseValue('showTagType')"
                >Tag Type</mat-checkbox
            >
            <mat-checkbox [checked]="showColumns.showDiscipline" (change)="reverseValue('showDiscipline')"
                >Discipline</mat-checkbox
            >
            <mat-checkbox [checked]="showColumns.showDescription" (change)="reverseValue('showDescription')"
                >Description</mat-checkbox
            >
            <mat-checkbox
                [checked]="showColumns.showTargetCompletionDate"
                (change)="reverseValue('showTargetCompletionDate')"
                >Target Completion Date</mat-checkbox
            >
        </div>
    </div>
    <div class="scroll">
        <div class="table-container">
            <app-mat-table-sticky-header>
                <table
                    mat-table
                    matSort
                    [dataSource]="(table$ | async)?.items"
                    [matSortActive]="sortBy"
                    matSortDisableClear
                    [matSortDirection]="sortDirection"
                >
                    <ng-container matColumnDef="subsystem" sticky>
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subsystem',
                                            getSubsystems,
                                            'columnSubsystem',
                                            'Subsystem',
                                            columns.columnSubsystem,
                                            true
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columns.columnSubsystem)
                                    }"
                                    >Subsystem</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                    <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.subsystem }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="number">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'number',
                                            getNumbers,
                                            'columnNumber',
                                            'Number',
                                            columns.columnNumber,
                                            true
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columns.columnNumber)
                                    }"
                                    >PLI/QVD No</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'number'">
                                    <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{ element.number }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showColumns.showDescription
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'description',
                                            getDescriptions,
                                            'columnDescription',
                                            'Description',
                                            columns.columnDescription,
                                            false
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columns.columnDescription)
                                    }"
                                    >PLI/QVD Description</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'description'">
                                    <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showDescription }">
                            {{ element.description }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tagNumber">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columns.columnTagNumber)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'tagNumber',
                                            getTagNumbers,
                                            'columnTagNumber',
                                            'Tag Number',
                                            columns.columnTagNumber
                                        )
                                    "
                                    >Tag Number</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'tagNumber'">
                                    <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.tagNumber }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tagType">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showColumns.showTagType
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columns.columnTagType)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'tagType',
                                            getTagTypes,
                                            'columnTagType',
                                            'Tag Type',
                                            columns.columnTagType,
                                            false
                                        )
                                    "
                                    >Tag Type</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'tagType'">
                                    <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showTagType }">
                            {{ element.tagType }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="discipline">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showColumns.showDiscipline
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columns.columnDiscipline)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'discipline',
                                            getDisciplines,
                                            'columnDiscipline',
                                            'Discipline',
                                            columns.columnDiscipline,
                                            true
                                        )
                                    "
                                    class="hover-pointer"
                                    >Discipline</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                    <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showDiscipline }">
                            {{ element.discipline }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="exceptionNumber">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(
                                            columns.columnExceptionNumber
                                        )
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'exceptionNumber',
                                            getExceptionNumbers,
                                            'columnExceptionNumber',
                                            'Exception Number',
                                            columns.columnExceptionNumber,
                                            true
                                        )
                                    "
                                    >Exception Number</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'exceptionNumber'">
                                    <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.exceptionNumber }}</td>
                    </ng-container>
                    <ng-container matColumnDef="exceptionPhase">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(
                                            columns.columnExceptionPhase
                                        )
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'exceptionPhase',
                                            getExceptionPhases,
                                            'columnExceptionPhase',
                                            'Exception Phase',
                                            columns.columnExceptionPhase,
                                            true
                                        )
                                    "
                                    >Exception Phase</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'exceptionPhase'">
                                    <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.exceptionPhase }}</td>
                    </ng-container>

                    <ng-container matColumnDef="targetCompletionDate">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showColumns.showTargetCompletionDate
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            columns.columnTargetCompletionDate
                                        )
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'targetCompletionDate',
                                            'columnTargetCompletionDate',
                                            'Target Completion Date',
                                            columns.columnTargetCompletionDate
                                        )
                                    "
                                    >Target Completion Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'targetCompletionDate'">
                                    <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{ hidecells: !showColumns.showTargetCompletionDate }"
                        >
                            {{ element.targetCompletionDate | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuForecast">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showColumns.showRFSUForecast
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            columns.columnRFSUForecast
                                        )
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfsuForecast',
                                            'columnRFSUForecast',
                                            'RFSU Forecast Date',
                                            columns.columnRFSUForecast
                                        )
                                    "
                                    >RFSU Forecast Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'rfsuForecast'">
                                    <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{ hidecells: !showColumns.showRFSUForecast }"
                        >
                            {{ element.rfsuForecast | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuPlan">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showColumns.showRFSUPlan
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            columns.columnRFSUPlan
                                        )
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfsuPlan',
                                            'columnRFSUPlan',
                                            'RFSU Plan Date',
                                            columns.columnRFSUPlan
                                        )
                                    "
                                    >RFSU Plan Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                                    <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{ hidecells: !showColumns.showRFSUPlan }"
                        >
                            {{ element.rfsuPlan | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="latestComment" stickyEnd>
                        <th mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columns.columnComments)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'latestComment',
                                            getComments,
                                            'columnComments',
                                            'Comment',
                                            columns.columnComments,
                                            false,
                                            true,
                                            false
                                        )
                                    "
                                    >Comment</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'latestComment'">
                                    <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" (click)="openCommentPopup(element, $event)">
                            <div *ngIf="element.latestComment && !isReadOnly; else notShow">
                                <a
                                    ><span [innerHTML]="element.latestComment | formatMentions: element.mentions"></span
                                ></a>
                            </div>
                            <div *ngIf="isReadOnly">
                                <span [innerHTML]="element.latestComment | formatMentions: element.mentions"></span>
                            </div>
                            <ng-template #notShow>
                                <button type="button" *ngIf="!isReadOnly" mat-stroked-button class="add-comment">
                                    Add
                                </button>
                            </ng-template>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </app-mat-table-sticky-header>
        </div>
    </div>
    <div class="paginator page-counter">
        <mat-paginator
            [length]="(table$ | async)?.totalCount"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50]"
            (page)="onPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
    </div>
</div>
