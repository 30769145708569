
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    ITRConstraintsContactsActionTypes,
    ITRConstraintsContactsUploadLogSuccess,
    ITRConstraintsContactsUploadLogError, ITRConstraintsContactsDownloadOutputDataSuccess,
    ITRConstraintsContactsDownloadOutputDataError,
    ITRConstraintsContactsDownloadOutputDataRequest,
    ITRConstraintsContactsTemplateFileSuccess,
    ITRConstraintsContactsTemplateFileRequest,
    ITRConstraintsContactsTemplateFileError
} from './actions';

@Injectable()
export class ITRConstraintsContactsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(ITRConstraintsContactsActionTypes.ITRConstraintsContactsUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['ConstraintContacts']).pipe(
                map((uploadLogData) => new ITRConstraintsContactsUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new ITRConstraintsContactsUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<ITRConstraintsContactsTemplateFileRequest>(ITRConstraintsContactsActionTypes.ITRConstraintsContactsTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new ITRConstraintsContactsTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading ITR Constraints structure file. Please contact Program Administrator'
                    );
                    return of(new ITRConstraintsContactsTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<ITRConstraintsContactsTemplateFileSuccess>(ITRConstraintsContactsActionTypes.ITRConstraintsContactsTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'Constraints Contacts Upload.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<ITRConstraintsContactsDownloadOutputDataRequest>(ITRConstraintsContactsActionTypes.ITRConstraintsContactsDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new ITRConstraintsContactsDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new ITRConstraintsContactsDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<ITRConstraintsContactsDownloadOutputDataSuccess>(ITRConstraintsContactsActionTypes.ITRConstraintsContactsDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

}

