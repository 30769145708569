import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';

@Component({
  selector: 'app-overdue-exceptions',
  templateUrl: './overdue-exceptions.component.html',
  styleUrls: ['./overdue-exceptions.component.scss']
})
export class OverdueExceptionsComponent implements OnInit {
  isTableLoading$ = this.store.select((state) => state.healthState.overdueExceptions.isTableLoading);
  isChartLoading$ = this.store.select((state) => state.healthState.overdueExceptions.isChartLoading);


  constructor(private store: Store<ApplicationState>,) { }

  ngOnInit(): void {
    // This is intentional
  }

}
