import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Result, IcicleDTO } from 'src/app/store/reports/icicle/model';
import { BaseComponent } from 'src/app/components/base.component';
import * as _ from 'lodash';
import { MatDatepicker } from '@angular/material/datepicker';
import { UntypedFormControl } from '@angular/forms';
import { PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT } from 'src/app/store/reports/planning/model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-icicle-chart-main',
    templateUrl: './icicle-chart-main.component.html',
    styleUrls: ['./icicle-chart-main.component.scss'],
})
export class IcicleChartMainComponent extends BaseComponent implements OnChanges, OnInit {
    results: Result[] = [];
    yAxisTicks: moment.Moment[] = [];
    xAxisTicks: Date[] = [];
    xScaleMin;
    xScaleMax;
    displayLegend = true;
    panelOpenState = true;
    activeLink: string;
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    startDateValue: moment.Moment;
    @ViewChild('dateRangePicker', { static: true }) dateRangePicker: MatDatepicker<moment.Moment>;
    @ViewChild(MatExpansionPanel, { static: true }) expansionPanel: MatExpansionPanel;
    @Input('data') data: IcicleDTO[];
    @Input('view') view: number[];
    @Input('isLoading') isLoading: boolean = false;
    @Input('type') xAxisLabel: string = 'Forecast';
    @Input('startDate') startDate: string = '';

    @Output() startDateSet: EventEmitter<string> = new EventEmitter();
    @Output() longerLabel: EventEmitter<boolean> = new EventEmitter();

    constructor(private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.startDateValue = moment(this.startDate);
        this.dateRangePickerControl = new UntypedFormControl(moment(this.startDate).toDate());
        this.dateRangePickerControlFormatted = new UntypedFormControl({
            value: this.formatDateRange(moment(this.startDate)),
            disabled: true,
        });

        this.dateRangePickerControl.valueChanges.subscribe((date: moment.Moment) => {
            this.startDateValue = moment(date);
            let startDateFormatted = moment(date).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT);
            this.startDateSet.emit(startDateFormatted);
            this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
        });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                startWith(this.router)
            )
            .subscribe((event: NavigationEnd) => {
                this.activeLink = event.url.split('/')[4];
                if (this.activeLink && this.activeLink === 'mc') {
                    this.activeLink = this.activeLink.toUpperCase();
                } else if (this.activeLink && this.activeLink === 'rfsu') {
                    this.activeLink = this.activeLink.toUpperCase();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dateRangePickerControl?.setValue(this.startDateValue.toDate(), { emitEvent: false });
        this.dateRangePickerControlFormatted?.setValue(this.formatDateRange(this.startDateValue));
        if (this.data !== null && this.data.length > 0) {
            this.displayLegend = true;
            this.results = [
                ...this.data.map((x) => ({
                    name: x.name,
                    series: x.points.map((y) => ({
                        name: moment(y.date).utc().toDate(),
                        value: moment(y.updateWeek).utc(),
                    })),
                })),
            ];
            this.longerLabel.emit(this.results.length > 7);
            const updateWeeks = this.data
                .map((x) => x.points.map((y) => moment(y.updateWeek).utc()))
                .reduce((a, b) => a.concat(b), []);

            const dates = this.data
                .map((x) => x.points.map((y) => moment(y.date).utc()))
                .reduce((a, b) => a.concat(b), []);

            const minDate = _.minBy(dates, (date) => date.toDate().getTime());
            const maxDate = _.maxBy(dates, (date) => date.toDate().getTime());

            const diff = this.getMonthsDiff(minDate, maxDate);

            const minDateYear = minDate.year();
            const minDateMonth = minDate.month();

            const monthsCount = _.range(0, diff + 2, 1);

            const x = _.map(monthsCount, (x) => new Date(minDateYear, minDateMonth + x));

            this.xScaleMin = moment(_.head(x));
            this.xScaleMax = moment(_.last(x));

            this.xAxisTicks = x;
            this.yAxisTicks = _.uniqBy(updateWeeks, (date) => date.toDate().getTime());
        } else {
            this.results = [];
            this.displayLegend = false;
            this.xAxisTicks = [];
            this.yAxisTicks = [];
            this.xScaleMax = 1;
            this.xScaleMin = 1;
        }
    }

    private getMonthsDiff(minDate: moment.Moment, maxDate: moment.Moment): number {
        return maxDate.year() * 12 + maxDate.month() - (minDate.year() * 12 + minDate.month());
    }

    formatToDateString(value: any): string {
        return moment(value).format('DD-MMM-YYYY');
    }

    dateRangePickerFilter = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    private formatDateRange(startDate: moment.Moment) {
        const endDate = moment(startDate).add(9, 'weeks').add(6, 'days');
        return `${moment(startDate).format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
    }
}
