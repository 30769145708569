<div class="planning-container">
    <div *ngIf="isInPastWeek$ | async" class="past-week-container">
        <app-weekly-planning-past-week></app-weekly-planning-past-week>
    </div>
    <div *ngIf="!(isInPastWeek$ | async)" class="tables-container">
        <app-weekly-planning-session-table 
            planningType="rfsu" 
            [planningTableIndex]="0"
            tableHeader="planned & forecasted in the next 4 weeks">
        </app-weekly-planning-session-table>
        <app-weekly-planning-session-table 
            planningType="rfsu" 
            [planningTableIndex]="1"
            tableHeader="forecasted in the next 4 weeks">
        </app-weekly-planning-session-table>
        <app-weekly-planning-session-table 
            planningType="rfsu" 
            [planningTableIndex]="2"
            tableHeader="left behind in plan / not forecasted in the next 4 weeks">
        </app-weekly-planning-session-table>
        <app-weekly-planning-session-table 
            planningType="rfsu" 
            [planningTableIndex]="3"
            tableHeader="planned in the next 4 weeks / not forecasted in the next 4 weeks">
        </app-weekly-planning-session-table>
        <app-weekly-planning-session-table 
            planningType="rfsu" 
            [planningTableIndex]="4"
            tableHeader="opportunity commitment">
        </app-weekly-planning-session-table>
    </div>
</div>
