<div class="results-panel results-container">
    <div class="results-panel-container">
        <div class="results-panel-header-container">
            <div class="results-panel-header-title-row">
                <div class="title">
                    Exceptions {{ (selectedPriority$ | async) ? '(' + (selectedPriority$ | async) + '-' + (selectedLabel$ | async) + ')' : 'by priority'}}
                </div>
                <div class="results-header-actions">
                    <button
                        mat-button
                        [matMenuTriggerFor]="columnsCustomizer"
                        class="results-columns-customizer"
                    >
                        <mat-icon svgIcon="edit"></mat-icon>
                        customize columns
                    </button>
                    <mat-menu #columnsCustomizer class="columns-customizer">
                        <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                        <div class="columns-customizer-list">
                            <mat-checkbox
                                [checked]="showColumns.showTagType"
                                (change)="reverseValue('showTagType')"
                                (click)="$event.stopPropagation()"
                            >
                                Tag Type
                            </mat-checkbox>
                            <mat-checkbox
                                [checked]="showColumns.showDiscipline"
                                (change)="reverseValue('showDiscipline')"
                                (click)="$event.stopPropagation()"
                            >
                                Discipline
                            </mat-checkbox>
                            <mat-checkbox
                                [checked]="showColumns.showDescription"
                                (change)="reverseValue('showDescription')"
                                (click)="$event.stopPropagation()"
                            >
                                Description
                            </mat-checkbox>
                            <mat-checkbox
                                [checked]="showColumns.showTargetCompletionDate"
                                (change)="reverseValue('showTargetCompletionDate')"
                                (click)="$event.stopPropagation()"
                            >
                                Target Completion Date
                            </mat-checkbox>
                        </div>
                    </mat-menu>
                    <button
                        type="button"
                        mat-button
                        (click)="showAllExceptions()"
                        class="results-clear-flags"
                    >
                        <mat-icon>close</mat-icon>
                        remove filters
                    </button>
                </div>
            </div>
        </div>
        <div class="results-panel-content-container">
            <div class="table-wrapper">
                <div class="scroll">
                    <div #tableContainer class="table-container">
                        <table
                            mat-table
                            matSort
                            [dataSource]="(table$ | async)?.items"
                            [matSortActive]="sortBy"
                            matSortStart="subsystem"
                            matSortDisableClear
                            [matSortDirection]="sortDirection"
                        >
                            <ng-container matColumnDef="subsystem" sticky>
                                <th
                                    mat-header-cell
                                    app-col-resize
                                    disableClear
                                    *matHeaderCellDef
                                    class="sticky-col1"
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnSubsystem)
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'subsystem',
                                                    getSubsystems,
                                                    'columnSubsystem',
                                                    'Subsystem',
                                                    columns.columnSubsystem,
                                                    true
                                                )
                                            "
                                        >
                                            Subsystem
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                            <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    class="sticky-col1"
                                >
                                    {{ element.subsystem }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="number">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnNumber)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'number',
                                                    getNumbers,
                                                    'columnNumber',
                                                    'Number',
                                                    columns.columnNumber,
                                                    true
                                                )
                                            "
                                        >
                                            PLI/QVD No
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'number'">
                                            <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span>{{ element.number }}</span>
                                </td>
                            </ng-container>
        
                            <ng-container matColumnDef="description">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showDescription,
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnDescription)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'description',
                                                    getDescriptions,
                                                    'columnDescription',
                                                    'Description',
                                                    columns.columnDescription,
                                                    false
                                                )
                                            "
                                        >
                                            PLI/QVD Description</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'description'">
                                            <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showDescription }">
                                    {{ element.description }}
                                </td>
                            </ng-container>
        
                            <ng-container matColumnDef="tagNumber">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnTagNumber)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'tagNumber',
                                                    getTagNumbers,
                                                    'columnTagNumber',
                                                    'Tag Number',
                                                    columns.columnTagNumber
                                                )
                                            "
                                        >
                                            Tag Number</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'tagNumber'">
                                            <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.tagNumber }}
                                </td>
                            </ng-container>
        
                            <ng-container matColumnDef="tagType">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showTagType,
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnTagType)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'tagType',
                                                    getTagTypes,
                                                    'columnTagType',
                                                    'Tag Type',
                                                    columns.columnTagType,
                                                    false
                                                )
                                            "
                                        >
                                            Tag Type</span>
                                        <div class="sort-icon" *ngIf="sortBy === 'tagType'">
                                            <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showTagType }">
                                    {{ element.tagType }}
                                </td>
                            </ng-container>
        
                            <ng-container matColumnDef="discipline">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showDiscipline,
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnDiscipline)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'discipline',
                                                    getDisciplines,
                                                    'columnDiscipline',
                                                    'Discipline',
                                                    columns.columnDiscipline,
                                                    true
                                                )
                                            "
                                            class="hover-pointer"
                                        >
                                            Discipline
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                            <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showColumns.showDiscipline }">
                                    {{ element.discipline }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="exceptionNumber">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnExceptionNumber)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'exceptionNumber',
                                                    getExceptionNumbers,
                                                    'columnExceptionNumber',
                                                    'Exception Number',
                                                    columns.columnExceptionNumber,
                                                    true
                                                )
                                            "
                                        >
                                            Exception Number
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'exceptionNumber'">
                                            <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.exceptionNumber }}</td>
                            </ng-container>

                            <ng-container matColumnDef="exceptionPhase">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnExceptionPhase)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'exceptionPhase',
                                                    getExceptionPhases,
                                                    'columnExceptionPhase',
                                                    'Exception Phase',
                                                    columns.columnExceptionPhase,
                                                    true
                                                )
                                            "
                                        >
                                            Exception Phase
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'exceptionPhase'">
                                            <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.exceptionPhase }}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="targetCompletionDate">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showColumns.showTargetCompletionDate,
                                        'header-underline': showUnderlineForCalendarColumn(columns.columnTargetCompletionDate)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'targetCompletionDate',
                                                    'columnTargetCompletionDate',
                                                    'Target Completion Date',
                                                    columns.columnTargetCompletionDate
                                                )
                                            "
                                        >
                                            Target Completion Date
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'targetCompletionDate'">
                                            <mat-icon *ngIf="sortDirection === 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection === 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showColumns.showTargetCompletionDate }"
                                >
                                    {{ element.targetCompletionDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
        
                            <ng-container matColumnDef="latestComment" stickyEnd>
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    app-col-resize
                                    [ngClass]="{
                                        'header-filtered': showUnderlineForCheckListColumn(columns.columnComments)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'latestComment',
                                                    getComments,
                                                    'columnComments',
                                                    'Comment',
                                                    columns.columnComments,
                                                    false,
                                                    true,
                                                    false
                                                )
                                            "
                                        >
                                            Comment
                                        </span>
                                        <div class="sort-icon" *ngIf="sortBy === 'latestComment'">
                                            <mat-icon *ngIf="sortDirection == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="sortDirection == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" (click)="openCommentPopup(element, $event)">
                                    <div *ngIf="element.latestComment && !isReadOnly; else notShow">
                                        <a>
                                            <span [innerHTML]="element.latestComment | formatMentions: element.mentions"></span>
                                        </a>
                                    </div>
                                    <div *ngIf="isReadOnly">
                                        <span [innerHTML]="element.latestComment | formatMentions: element.mentions"></span>
                                    </div>
                                    <ng-template #notShow>
                                        <button type="button" *ngIf="!isReadOnly" mat-stroked-button class="add-comment">
                                            Add
                                        </button>
                                    </ng-template>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="paginator page-counter">
        <mat-paginator
            [length]="(table$ | async)?.totalCount"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50]"
            (page)="onPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <input
            type="number"
            [value]="this.paginator.pageIndex + 1"
            (change)="onPageChange($event.target.value)"
        />
    </div>

    <div *ngIf="(isTableLoading$ | async)" class="table-complete-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>