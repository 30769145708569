<div class="popup">
    <div class="icon-remove" (click)="onCancel()"></div>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">Field Implementation Contractor History</span>
    </p>
    <mat-dialog-content>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="oldContractor">
                <th mat-header-cell *matHeaderCellDef>Old Contractor</th>
                <td mat-cell *matCellDef="let element">{{ element.oldContractor }}</td>
            </ng-container>

            <ng-container matColumnDef="newContractor">
                <th mat-header-cell *matHeaderCellDef>New Contractor</th>
                <td mat-cell *matCellDef="let element">{{ element.newContractor }}</td>
            </ng-container>
           
            <ng-container matColumnDef="updatedDate">
                <th mat-header-cell *matHeaderCellDef>Update date</th>
                <td mat-cell *matCellDef="let element">{{ element.updatedDate | date: 'd/MMM/y' }}</td>
            </ng-container>

            <ng-container matColumnDef="userFullName">
                <th mat-header-cell *matHeaderCellDef>changed by</th>
                <td mat-cell *matCellDef="let element">{{ element.userFullName }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-dialog-content>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</div>