import { Directive, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';

declare const ProgressBar: any;

@Directive({
    selector: '[app-progress-bar]',
})
export class ProgressBarDirective implements OnChanges {
    @Input() public value: any;

    progressBar: any;

    constructor(private elementRef: ElementRef<HTMLDivElement>) {
        this.progressBar = new ProgressBar.SemiCircle(this.elementRef.nativeElement, {
            strokeWidth: 8,
            color: '#5AA454',
            trailColor: '#eee',
            trailWidth: 8,
            easing: 'easeInOut',
            duration: 1400,
            svgStyle: null,
            text: {
                value: '',
                alignToBottom: true,
            },
            from: { color: '#5AA454' },
            to: { color: '#5AA454' },
            step: (state, bar) => {
                bar.path.setAttribute('stroke', state.color);
                var value = Math.abs(bar.value() * 100.0).toFixed(2);
                bar.setText(`${value}%`);

                bar.text.style.color = state.color;
            },
        });
        this.progressBar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
        this.progressBar.text.style.fontSize = '1.25rem';
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            this.progressBar.animate(changes.value.currentValue);
        }
    }
}
