import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { WeeklyPlanType } from 'src/app/enums';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { TCOUser } from 'src/app/store/common.model';

@Component({
    selector: 'app-lookahead-activities-filter',
    templateUrl: './lookahead-activities-filter.component.html',
    styleUrls: ['./lookahead-activities-filter.component.scss'],
})
export class LookaheadActivitiesFilterComponent extends BaseComponent implements OnInit {
    @Input() planType: WeeklyPlanType;
    @Input() filterForm: UntypedFormGroup;
    @Input() displayPeriodDays: number;
    @Input() printMode?: boolean = false;
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    setRfoSelectorValue = new EventEmitter<SetInputEventArgs>();
    setGoocSelectorValue = new EventEmitter<SetInputEventArgs>();
    setDisciplineSelectorValue = new EventEmitter<SetInputEventArgs>();

    weeklyPlanTypes = WeeklyPlanType;
    mslAreas = [];
    scManagers: TCOUser[] = [];

    constructor(
        private lookupService: LookupService,
        private scheduleActivityLookupService: ScheduleActivityLookupService,
        private toastService: ToastService
    ) {
        super();

        forkJoin([this.lookupService.getSCManagers([])])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([scManagers]) => {
                    this.scManagers = scManagers;
                },
                (error) => {
                    this.toastService.Error(error);
                }
            );
    }

    ngOnInit(): void {
        this.lookupService
            .getMSLAreas()
            .pipe(take(1))
            .subscribe((mslAreas) => (this.mslAreas = mslAreas));
    }

    searchGoocs = (search = '', takeCount = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchGoocs(search, takeCount, page, this.filterForm.value);
    };

    searchRfos = (search = '', takeCount = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchRfos(search, takeCount, page, this.filterForm.value);
    };

    searchDiscipline = (search = '') => {
        return this.scheduleActivityLookupService
            .searchDisciplines()
            .pipe(
                map((disciplines: []) =>
                    disciplines.filter((d: { name: string }) => d.name.toLowerCase().includes(search.toLowerCase()))
                )
            );
    };
}

