import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { ImplementationStatusMappingDTO } from 'src/app/models/implementation-status-mapping-dto';

@Injectable({
    providedIn: 'root',
})
export class ImplementationStatusMappingService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/implementationstatusmapping';
    }

    get(importtype: string) {
        return this.http.get<ImplementationStatusMappingDTO[]>(`${this.rootUrl}${this.endpoint}?changeType=${importtype}`);
    }

    remove(id: number) {
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?id=${id}`);
    }

    update(contractor: ImplementationStatusMappingDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, contractor);
    }

    add(contractor: ImplementationStatusMappingDTO) {
        return this.http.post<ImplementationStatusMappingDTO>(`${this.rootUrl}${this.endpoint}`, contractor);
    }
}