import { Action } from '@ngrx/store';
import { RundownType, RundownResponseDTO, RundownFilter } from './model';
import * as moment from 'moment';

export enum RundownActionTypes {
    RundownFilterUpdate = '[Rundown] Filter Update',
    RundownFilterPropertyUpdate = '[Rundown] Filter Property Update',
    RundownFilterReset = '[Rundown] Filter Reset',
    RundownSetType = '[Rundown] Set Type',
    RundownTogglePlanView = '[Rundown] Toggle Plan View',
    RundownSetWeekRange = '[Rundown] Set Week Range',
    RundownSetMonthRange = '[Rundown] Set Month Range',
    RundownSetQuarterRange = '[Rundown] Set Quarter Range',
    RundownRequest = '[Rundown] Request',
    RundownSuccess = '[Rundown] Success',
    RundownError = '[Rundown] Error',
    RundownRangeUpdate = '[Rundown] Range Update',
    RundownExportToExcelRequest = '[Rundown] Export to Excel Request',
    RundownExportToExcelSuccess = '[Rundown] Export to Excel Success',
    RundownExportToExcelError = '[Rundown] Export to Excel Error',
}

export class RundownFilterUpdate implements Action {
    readonly type = RundownActionTypes.RundownFilterUpdate;

    constructor(public payload: RundownFilter) {}
}

export class RundownFilterPropertyUpdate implements Action {
    readonly type = RundownActionTypes.RundownFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class RundownFilterReset implements Action {
    readonly type = RundownActionTypes.RundownFilterReset;
}

export class RundownSetType implements Action {
    readonly type = RundownActionTypes.RundownSetType;

    constructor(public payload: RundownType) {}
}

export class RundownTogglePlanView implements Action {
    readonly type = RundownActionTypes.RundownTogglePlanView;
}

export class RundownSetWeekRange implements Action {
    readonly type = RundownActionTypes.RundownSetWeekRange;

    constructor(public payload: moment.Moment) {}
}

export class RundownSetMonthRange implements Action {
    readonly type = RundownActionTypes.RundownSetMonthRange;

    constructor(public payload: moment.Moment) {}
}

export class RundownSetQuarterRange implements Action {
    readonly type = RundownActionTypes.RundownSetQuarterRange;

    constructor(public payload: moment.Moment) {}
}

export class RundownRequest implements Action {
    readonly type = RundownActionTypes.RundownRequest;
}

export class RundownSuccess implements Action {
    readonly type = RundownActionTypes.RundownSuccess;

    constructor(
        public payload: {
            response: RundownResponseDTO;
            rundownType: RundownType;
            monthlyView: boolean;
            weeklyView: boolean;
            quarteryView: boolean;
        }
    ) {}
}

export class RundownError implements Action {
    readonly type = RundownActionTypes.RundownError;

    constructor(public payload: string) {}
}

export class RundownRangeUpdate implements Action {
    readonly type = RundownActionTypes.RundownRangeUpdate;

    constructor(public payload: number) {}
}


export class RundownExportToExcelRequest implements Action {
    readonly type = RundownActionTypes.RundownExportToExcelRequest
}

export class RundownExportToExcelSuccess implements Action {
    readonly type = RundownActionTypes.RundownExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class RundownExportToExcelError implements Action {
    readonly type = RundownActionTypes.RundownExportToExcelError;

    constructor(public payload: string) {}
}

export type RundownActions =
    | RundownFilterUpdate
    | RundownFilterPropertyUpdate
    | RundownFilterReset
    | RundownSetType
    | RundownSetWeekRange
    | RundownSetMonthRange
    | RundownRequest
    | RundownSuccess
    | RundownError
    | RundownTogglePlanView
    | RundownSetQuarterRange
    | RundownRangeUpdate
    | RundownExportToExcelRequest
    | RundownExportToExcelSuccess
    | RundownExportToExcelError;
