<main class="main">
    <div class="main-container">
        <mat-accordion>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="64px">
                    <h4>Tag import</h4>
                </mat-expansion-panel-header>
                <div class="import-subtitle">
                    See
                    <b (click)="downloadTagTemplate('SCMT TagList [part].xlsx')">SCMT TagList.xlsx</b> structure.
                    Records in upload file are synchronised to SCMT. Data only imported for matching Project Teams in
                    SCMT using Source Data mapping rules. Foreign key links to any matching subsystems are made at
                    upload based on subsystems in upload file. Recommended sequence of data update: Contractor, Master
                    Subsystem List, Tag List.
                    Result file has tabs (Quarantine Results) that contains list Tags imported from the upload file that
                    do not contain match to Discipline in SCMT (tagged as "NoMatchingDiscipline"). Result file also has
                    a tabs (Records for deletion 1, 2, 3... ) that contain list of tag records being removed from SCMT.
                    Data mapping rules for Project Team & Discipline in SCMT are adjustable through Admin Configuration
                    page.
                </div>

                <app-source-file-configuration [uploadType] = "getUploadTypeString(uploadTypes.Tags)" [showUploadSource]="false"></app-source-file-configuration>

                <h4>Source Data mapping</h4>
                <div class="import-subtitle">
                    <div>
                        Adjust rules for mapping the "Data Owner" column in upload file to Project Teams in SCMT and
                        rules for mapping the "Discipline" column in upload file to Discipline in SCMT.
                        Multiple source values can be mapped to single SCMT value - where there are multiple values,
                        enter as comma-separated (e.g. "J, T" mapped to Instrumentation in SCMT).
                        The Project Team data mapping is used for importing records into SCMT.
                    </div>
                </div>
                <app-configuration-panel-mcplus [uploadType] = "getUploadTypeString(uploadTypes.Tags)">
                </app-configuration-panel-mcplus>
            </mat-expansion-panel>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="64px">
                    <h4>Automatic Uploads</h4>
                </mat-expansion-panel-header>
                <app-automatic-uploads></app-automatic-uploads>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</main>