import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { takeWhile, filter, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Constants } from 'src/app/constants';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from '../../modules/shared/components/comment-popup/comment-popup.component';
import * as moment from 'moment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChangeType, mapStringToChangeTypeEnum } from 'src/app/enums';
import { DeleteConfirmationPopupComponent } from '../../modules/shared/components/delete-confirmation-popup/delete-confirmation-popup.component';
import {
    CallOffAttachment,
    ChangeDocumentDetailsDTO,
    SignOffStatusDTO,
    SignOffType,
    TechnicalQueryAttachmentType,
} from 'src/app/store/change-document-details/model';
import { CommentDTO, ChangeDocStatus, UserDetail } from 'src/app/store/common.model';
import { changeTypeEnumToString } from 'src/app/extensions';
import {
    GetChangeDocumentDetailsRequest,
    ChangeDocumentDetailsDeleteCommentRequest,
    ChangeDocumentDetailsAddCommentRequest,
    ChangeDocumentDetailsClearIwpLinks,
    DownloadCallOffAttachmentRequest,
} from 'src/app/store/change-document-details/actions';
import { RoleService } from 'src/app/services/shared/role.service';
import { SignOffPopupComponent } from './sign-off-popup/sign-off-popup.component';
import { IwpPopupComponent } from './iwp-popup/iwp-popup.component';
import { UserService } from 'src/app/services/api/webapi-services/user.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { MsalService } from '@azure/msal-angular';
import { DeleteSignOffPopupComponent } from './delete-sign-off-popup/delete-sign-off-popup.component';
import { AccountInfo } from '@azure/msal-browser';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-change-document-details',
    templateUrl: './change-document-details.component.html',
    styleUrls: ['./change-document-details.component.scss'],
})
export class ChangeDocumentDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
    data: ChangeDocumentDetailsDTO = new ChangeDocumentDetailsDTO();
    comments: CommentDTO[] = [];
    rfsuContractorToSSList: any[] = [];
    comment = '';
    displayedColumns: string[] = ['comment', 'user', 'date', 'delete'];
    rfsuToSSColumns: string[] = ['ss', 'rfsu', 'rfo', 'rfsuforecast', 'rfoforecast','signedSubsystem','subsystemsExceptions'];
    type: ChangeType;
    ChangeType = ChangeType;
    changeTypeTitle = '';
    isLoading = false;
    item$ = this.store.select((state) => state.changeDocumentDetailsState.item);
    comments$ = this.store.select((state) => state.changeDocumentDetailsState.comments);
    stateLoader$ = this.store.select((state) => state.changeDocumentDetailsState.isLoading);
    hasHistory = false;
    changeDocStatuses = ChangeDocStatus;
    isReadOnly = false;
    canSignOff = false;
    subsToSignOff: string[] = [];
    alreadySignedOff = false;
    linkedIWPsPlaceholder = 'Linked IWPs';
    isUserContractLoading = false;
    manualSignOffs: SignOffStatusDTO[] = [];
    attachmentTypes = TechnicalQueryAttachmentType;
    account: AccountInfo = null;
    isAdmin = false;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private router: Router,
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
        private popupSvc: PopupService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private roleService: RoleService,
        private popupService: PopupService,
        private userService: UserService,
        private toastService: ToastService,
        private authService: MsalService
    ) {
        super();
        this.hasHistory = this.router.navigated;
        iconRegistry.addSvgIcon(
            'del-icon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
        iconRegistry.addSvgIcon(
            'inactive',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/error.svg')
        );
        iconRegistry.addSvgIcon(
            'completed',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/checked.svg')
        );
    }

    ngOnInit() {
        window.scroll(0, 0);
        this.account = this.authService.instance.getActiveAccount();
        this.isReadOnly = this.roleService.isReadOnly();
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        if (this.isReadOnly) this.displayedColumns = ['comment', 'user', 'date'];

        this.route.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap: ParamMap) => {
            this.type = mapStringToChangeTypeEnum(paramMap.get('type'));
            this.changeTypeTitle = changeTypeEnumToString(this.type);
            this.store.dispatch(new GetChangeDocumentDetailsRequest({ id: paramMap.get('id') }));
        });

        this.stateLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.item$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((s) => s.id !== null)
            )
            .subscribe((item) => {
                this.data = item;
                this.linkedIWPsPlaceholder = `Linked IWPs${
                    this.data.linkedIWPs && this.data.linkedIWPs.length
                        ? this.data.isIWPListComplete
                            ? ' - Complete List'
                            : ' - Partial List'
                        : ''
                }`;
                this.assignRFSUContractorToSS(item.rfsuContractorToSS)
                this.comments = cloneDeep(item.comments) || this.comments;
                this.comments.sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)));
                this.updateSubsToSignOff();
                this.setCanSignOff();
            });

        this.comments$.pipe(takeWhile(() => this.isAlive)).subscribe((comments) => {
            this.comments = cloneDeep(comments) || this.comments;
            this.comments.sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)));
        });
    }

    assignRFSUContractorToSS(rfsuss: string) {
        this.rfsuContractorToSSList = []
        rfsuss.split(',').forEach(el => {
            const arr = el.split('|')
            if (arr.length === 7) {
                this.rfsuContractorToSSList.push({ss:arr[0], rfsu: arr[1], rfo: arr[2], rfsuForecast: arr[3], rfoForecast: arr[4] ,signedSubsystem:arr[5], subsystemsExceptions:arr[6]})
            }            
        })
    }

    setCanSignOff() {
        let isEditUser = this.roleService.isInAnyRole([
            Constants.applicableGroups.MCEngineer3GI,
            Constants.applicableGroups.MCEngineer3GP,
        ]);

        if (this.isAdmin) {
            this.canSignOff = true;
        } else if (isEditUser) {
            this.getUserContracts();
        }
    }

    getUserContracts() {
        this.isUserContractLoading = true;
        this.userService
            .getUserContracts()
            .pipe(take(1))
            .subscribe(
                (contracts: string[]) => {
                    this.canSignOff = contracts.indexOf(this.data.contractor) > -1;
                    this.isUserContractLoading = false;
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while getting user contracts. Please contact Program Administrator.'
                    );
                    this.isUserContractLoading = false;
                }
            );
    }

    ngOnDestroy() {
        this.store.dispatch(new ChangeDocumentDetailsClearIwpLinks());
    }

    updateSubsToSignOff() {
        let subs = this.data.impactedSubsystems
            .split(',')
            .map(s => s.trim())
            .filter((s) => s !== '');

        if(this.data.validationStatus == 'test pack requested'){
                subs = this.data.testpackComlpetedSubsystems
                .split(',')
                .map(s => s.trim())
                .filter((s) => s !== '');   
        }

        this.data.signOffs.forEach((el: SignOffStatusDTO) => {
            const elSubs = el.subsystems 
                ? el.subsystems.split(',').map(s => s.trim()).filter((s) => s !== '') : [];
            if(elSubs.length > 0) {
                subs = [...subs.filter(s => !elSubs.includes(s))];
            }
        });
        
        this.subsToSignOff = subs;
        this.alreadySignedOff =
            this.data.isFieldImplementationStatusComplete ||
            (this.subsToSignOff.length === 0 && this.data.signOffs.length > 0) ;
        this.manualSignOffs = this.data.signOffs.filter(
            (s) => s.type === SignOffType.Manual || s.type === SignOffType.BySourceData
        );

        if(this.data.validationStatus == 'test pack requested'){
            this.alreadySignedOff =  (this.data.isFieldImplementationStatusComplete ||
            (this.subsToSignOff.length === 0 && this.data.signOffs.length > 0)) && this.data.impactedSubsystems.split(',').map(s => s.trim()).filter((s) => s !== '').every( is => this.data.testpackComlpetedSubsystems.includes(is))
        }
    }

    goToPreviousPage() {
        if (this.hasHistory) {
            this.location.back();
        } else {
            this.router.navigate(['/changemanagement']);
        }
    }

    removeComment(element: CommentDTO) {
        this.popupSvc.openPopup(
            new PopupSettings(DeleteConfirmationPopupComponent, 400, 130, {
                action: (value: boolean) => {
                    if (value) {
                        this.store.dispatch(new ChangeDocumentDetailsDeleteCommentRequest({ id: element.id }));
                    }
                },
            })
        );
    }

    canRemoveComment(element: CommentDTO) {
        if (
            element.userName.toLowerCase() === this.account.username.toLowerCase() ||
            this.roleService.isInRole(Constants.applicableGroups.Admin)
        ) {
            return true;
        }
        return false;
    }

    openCommentPopup(event: any) {
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                url: `changeDocumentDetails/${this.type}/${this.data.id.toString()}`,
                action: (value: string, mentions: UserDetail[]) => {
                    this.store.dispatch(
                        new ChangeDocumentDetailsAddCommentRequest({
                            entityId: this.data.id.toString(),
                            description: value,
                            mentions,
                        })
                    );
                    this.comment = '';
                },
            })
        );

        event.stopPropagation();
    }

    linkIwpPopup(event: any) {
        this.popupService.openPopup(
            new PopupSettings(IwpPopupComponent, 700, 600, {
                changeDocNo: this.data.number,
                changeDocId: this.data.id,
                contractNo: this.data.contractor,
            })
        );

        event.stopPropagation();
    }

    signOff() {
        let dialogRef = this.popupService.openPopup(
            new PopupSettings(SignOffPopupComponent, 450, 300, {
                id: this.data.id,
                subsystems: this.subsToSignOff,
                contract: this.data.contractor,
            })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.changeDoc) {
                    this.data = result.changeDoc;
                    this.assignRFSUContractorToSS(result.changeDoc.rfsuContractorToSS)
                    this.updateSubsToSignOff();
                }
            });
    }

    deleteSignOff(signOff: SignOffStatusDTO) {
        let dialogRef = this.popupService.openPopup(
            new PopupSettings(DeleteSignOffPopupComponent, 450, 300, {
                id: this.data.id,
                subsystems: signOff.subsystems,
                contract: this.data.contractor,
                signOffId: signOff.signOffId,
            })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.changeDoc) {
                    this.data = result.changeDoc;
                    this.assignRFSUContractorToSS(result.changeDoc.rfsuContractorToSS)
                    this.updateSubsToSignOff();
                }
            });
    }

    goToEb(documentNo: string) {
        if (this.data.qrCode !== '' && this.data.qrCode !== null) {
            window.open(this.data.qrCode, '_blank');
        } else {
            let url = Constants.baseEbUrl.replace('{docNo}', documentNo);
            window.open(url, '_blank');
        }
    }

    downloadAttachment(attachment: CallOffAttachment) {
        this.store.dispatch(new DownloadCallOffAttachmentRequest({ attachment, type: this.changeTypeTitle }));
    }
}
