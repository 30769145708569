import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum PriorityNumbersActionTypes {
    PriorityNumbersUploadLogRequest = '[Priority Numbers] Upload Log Request',
    PriorityNumbersUploadLogSuccess = '[Priority Numbers] Upload Log Success',
    PriorityNumbersUploadLogError = '[Priority Numbers] Upload Log Error',
    PriorityNumbersSetStatusAndStartDate = '[Priority Numbers] Set Status and Start Date',
    PriorityNumbersClearInProgressSpinner = '[Priority Numbers] Clear In Progress Spinner',
    PriorityNumbersTemplateFileRequest = '[Priority Numbers] Template File Request',
    PriorityNumbersTemplateFileSuccess = '[Priority Numbers] Template File Success',
    PriorityNumbersTemplateFileError = '[Priority Numbers] Template File Error',
    PriorityNumbersDownloadOutputDataRequest = '[Priority Numbers] Download Output Data Request',
    PriorityNumbersDownloadOutputDataSuccess = '[Priority Numbers] Download Output Data Success',
    PriorityNumbersDownloadOutputDataError = '[Priority Numbers] Download Output Data Error',
    PriorityNumbersAddStartedLog = '[Priority Numbers] Add Started Log',
    PriorityNumbersDeleteRecordsRequest = '[Priority Numbers] Delete Records Request',
    PriorityNumbersDeltaRequest = '[Priority Numbers] Validate Delta Request',
}

export class PriorityNumbersAddStartedLog implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersAddStartedLog;
}

export class PriorityNumbersUploadLogRequest implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersUploadLogRequest;
}

export class PriorityNumbersUploadLogSuccess implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class PriorityNumbersUploadLogError implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersUploadLogError;

    constructor(public payload: string) {}
}

export class PriorityNumbersSetStatusAndStartDate implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class PriorityNumbersClearInProgressSpinner implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersClearInProgressSpinner;
}

export class PriorityNumbersTemplateFileRequest implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersTemplateFileRequest;

    constructor(public payload: string) {}
}

export class PriorityNumbersTemplateFileSuccess implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class PriorityNumbersTemplateFileError implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersTemplateFileError;

    constructor(public payload: string) {}
}

export class PriorityNumbersDownloadOutputDataRequest implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class PriorityNumbersDownloadOutputDataSuccess implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class PriorityNumbersDownloadOutputDataError implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersDownloadOutputDataError;

    constructor(public payload: string) {}
}
export class PriorityNumbersDeleteRequest implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersDeleteRecordsRequest;
}
export class PriorityNumbersDeltaRequest implements Action {
    readonly type = PriorityNumbersActionTypes.PriorityNumbersDeltaRequest;
}

export type PriorityNumbersActions =
    | PriorityNumbersUploadLogRequest
    | PriorityNumbersUploadLogSuccess
    | PriorityNumbersUploadLogError
    | PriorityNumbersSetStatusAndStartDate
    | PriorityNumbersClearInProgressSpinner
    | PriorityNumbersTemplateFileRequest
    | PriorityNumbersTemplateFileSuccess
    | PriorityNumbersTemplateFileError
    | PriorityNumbersDownloadOutputDataRequest
    | PriorityNumbersDownloadOutputDataSuccess
    | PriorityNumbersDownloadOutputDataError
    | PriorityNumbersAddStartedLog
    | PriorityNumbersDeleteRequest
    | PriorityNumbersDeltaRequest;
