import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { ChangeType } from 'src/app/enums';
import { changeTypeEnumToString } from 'src/app/extensions';

@Component({
  selector: 'app-configuration-panel',
  templateUrl: './configuration-panel.component.html',
  styleUrls: ['./configuration-panel.component.scss']
})

export class ConfigurationPanelComponent extends BaseComponent implements OnInit {
  @Input() changeType: string;
  shouldDisplayDesignStatusMapping = false;
  shouldDisplayImplementationStatusMapping = false;
  shouldDisplayContractorMapping = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.shouldDisplayDesignStatusMapping = [ChangeType.MACDCN, ChangeType.RFI].map(v => changeTypeEnumToString(v)).includes(this.changeType);
    this.shouldDisplayImplementationStatusMapping = [ChangeType.MACDCN].map(v => changeTypeEnumToString(v)).includes(this.changeType);
    this.shouldDisplayContractorMapping = [ChangeType.MACDCN, ChangeType.RFI].map(v => changeTypeEnumToString(v)).includes(this.changeType);
  }

}
