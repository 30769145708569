import { SignOffStatusDTO } from "../change-document-details/model";
import { CalendarColumn, CheckListColumn, NumericColumn, OrderDirection, UserDetail } from "../common.model";
import { CommentDetailDTO } from "../detailed-status/model";

export class DcnRfiRedlineRegisterFilter {
    dcnRfiNumber: string[] = [];
    dcnRfiDiscipline: string[] = [];
    dcnRfiImpactedSubsystem: string[] = [];
    dcnRfiEngineeringStatus: string[] = [];
    dcnRfiTitle: string[] = [];
    dcnRfiWorkpackNumber: string[] = [];
    dcnRfiWorkpackStatus: string[] = [];
    dcnRfiContent: string[] = [];
    dcnRfiImplementationStatus: string[] = [];
    workArea: string[] = [];
    redlineNumber: string[] = [];
    redlineDiscipline: string[] = [];
    redlineSubsystem: string[] = [];
    redlineTitle: string[] = [];
    rlmuStatus: string[] = [];
    redlineStatus: string[] = [];
    registerType: RegisterType = RegisterType.Dcnrfi;
    take: number = 25;
    page: number = 0;
    showHideColumns = new ShowHideColumns();
    timezoneOffset: number = 0;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;
    redlineReadyForSubmission?: boolean = null;
    redlineRequiredFor: string[] = [];
    redlineNbRespEngineer: string[] = [];

    columnDcnRfiRFSUForecast: CalendarColumn = null;
    columnDcnRfiWalkdownActual: CalendarColumn = null;
    columnDcnRfiMcActual: CalendarColumn = null;
    columnDcnRfiWalkdownForecast: CalendarColumn = null;
    columnDcnRfiRfsuPlan: CalendarColumn = null;
    columnDcnRfiDiscipline: CheckListColumn = null;
    columnDcnRfiNumber: CheckListColumn = null;
    columnDcnRfiTitle: CheckListColumn = null;
    columnDcnRfiSubsystem: CheckListColumn = null;
    columnDcnRfiEngineeringStatus: CheckListColumn = null;
    columnDcnRfiWorkflowStatus: CheckListColumn = null;
    columnDcnRfiImplementationStatus: CheckListColumn = null;
    columnRlmuToGo: NumericColumn = null;
    columnDcnRfiComments: CheckListColumn = null;

    columnRedlineNumber: CheckListColumn = null;
    columnRedlineDiscipline: CheckListColumn = null;
    columnRedlineTitle: CheckListColumn = null;
    columnRedlineRevision: CheckListColumn = null;
    columnRedlineRlmuStatus: CheckListColumn = null;
    columnRedlineTcoStatus: CheckListColumn = null;
    columnRedlineSubsystems: CheckListColumn = null;
    columnRedlineRFSUForecast: CalendarColumn = null;
    columnRedlineWalkdownForecast: CalendarColumn = null;
    columnRedlineWalkdownActual: CalendarColumn = null;
    columnRedlineMcActual: CalendarColumn = null;    
    columnRedlineRfsuPlan: CalendarColumn = null;
    columnRedlineRFSUActual: CalendarColumn = null;
    columnRedlineSubmissionStatus: CheckListColumn = null;
    columnRedlineComments: CheckListColumn = null;
    columnRedlineRequiredFor: CheckListColumn = null;
    columnRedlineRfo: CheckListColumn = null;
    columnRedlineNbRespEngineer: CheckListColumn = null;
}

export enum RegisterType {
    Dcnrfi,
    Redline
}

export class DcnRfiRegisterDto {
    id: number;
    number: string;
    discipline: string;
    title: string;
    subsystem: string;
    engineeringStatus: string;
    workflowStatus: string;
    comment: string;
    commentId?: number;
    rfsuForecast?: Date;
    implementationStatus: string;
    mentions: UserDetail[];
    rlmuToGo: number;
    walkdownForeCastDate?: Date;
    walkdownActual?: Date;
    rfsuPlan?: Date;
    mcActual?: Date;
}

export class DcnRfiRegisterResultDto {
    items: DcnRfiRegisterDto[];
    totalCount: number;
}

export class RedlineRegisterDto {
    id: number;
    redlineNumber: string;
    title: string;
    revision: string;
    dcnRfiNumber: string;
    rlmuStatus: string;
    comment: string;
    commentId?: number;
    mentions: UserDetail[];
    subsystem: string;
    tcoStatus: string;
    rfsuForecast?: Date;
    walkdownForecast?: Date;
    rfsuActual?: Date;
    submissionStatus: string;    
    walkdownActual?: Date;
    rfsuPlan?: Date;
    mcActual?: Date;
}

export class RedlineRegisterResultDto {
    items: RedlineRegisterDto[];
    totalCount: number;
}

export class ShowHideColumns {
    dcnRfiNumber = true;
    dcnRfiDiscipline = true;
    dcnRfiTitle = true;
    dcnRfiSubsystem = true;
    dcnRfiEngineeringStatus = true;
    dcnRfiWorkflowStatus = false;
    rfsuForecast = false;
    implementationStatus = true;
    rlmuToGo = false;
    comments = true;
    redlineNumber = true;
    redlineDiscipline = false;
    redlineTitle = true;
    revision = true;
    rlmuStatus = true;
    redlineSubsystem = true;
    tcoStatus = false;
    rfsuActual = false;
    submissionStatus = true;
    walkdownForecast = false;
    walkdownActual = false;
    mcActual = false;
    rfsuPlan = false;
    requiredFor = true;
    rfo = true;
    nbResponsibleEngineer = false;
}

export class DcnRfiRegisterDetails {
    id: number = null;
    number: string = '';
    revision: string = '';
    discipline: string = '';
    title: string = '';
    subsystems: string = '';
    qrCode: string = '';
    implementationStatus: string = '';
    engineeringStatus: string = '';
    notes: string = '';
    comment: string = '';
    commentId?: number = null;
    workflowStatus: string = '';
    rfsuForecast?: Date = null;
    redlineBaseDetails: RedlineBaseDetails[] = [];
    workpacks: Workpacks[] = [];
    signOffs: SignOffStatusDTO[] = [];
}

export class RedlineRegisterDetails {
    id: number = null;
    number: string = '';
    revision: string = '';
    discipline: string = '';
    tcoRedlineStatus: string = '';
    rlmuStatus: string = '';
    title: string = '';
    subsystems: string = '';
    submissionRequiredFor: string = '';
    rfsuForecast?: Date = null;
    engineeringStatus: string = '';
    signOffs: SignOffStatusDTO[] = [];
    comment: string = '';
    commentId?: number = null;
}


export class RedlineBaseDetails {
    id: number = null;
    changeDocId: number = null;
    redlineNumber: string = '';
    tcoStatus: string = '';
    rlmuStatus: string = '';
}

export class Workpacks {
    id: number = null;
    changeDocId: number = null;
    workpackNumber: string = '';
    workpackStatus: string = '';
}

export class WorkflowStatusUpdate {
    changeDocId: number = null;
    workflowStatus: string = '';
}

export enum StatusType {
    Workflow,
    Engineering,
    Tco,
    Rlmu,
    Workpack,
    Redline
}