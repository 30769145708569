<mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    *ngIf="(data$ | async) || (isLoading$ | async)"
>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h3>Subsystem Table</h3>
        </mat-panel-title>
        <mat-panel-description *ngIf="!panelOpenState">Expand panel to view details.</mat-panel-description>
    </mat-expansion-panel-header>
    <div class="table-container">
        <table mat-table [dataSource]="data$ | async" matSortDisableClear>
            <ng-container matColumnDef="subsystemNo">
                <th mat-header-cell disableClear *matHeaderCellDef>Subsystem</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.subsystemNo }}
                </td>
            </ng-container>
            <ng-container matColumnDef="subsystemName">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    Subsystem Name
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.subsystemName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="mcPlanDate">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    MC Plan Date
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.mcPlanDate | date: 'd/MMM/yy' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="mcForecast">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    MC Forecast
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.mcForecast | date: 'd/MMM/yy' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="mcActualDate">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    MC Actual
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.mcActualDate | date: 'd/MMM/yy' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="aqvd">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div>A-QVDs</div>
                    <div>
                        <div class="total">Total</div>
                        <div>Complete</div>
                        <div>Remaining</div>
                        <div>Exceptions</div>
                        <div class="percent">%</div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <div class="total">{{ element.aqvd?.total }}</div>
                        <div>{{ element.aqvd?.complete }}</div>
                        <div>
                            <span
                                class="detailed-status-link"
                                (click)="openDetailedStatusPage(element.subsystemNo, 'AQVD')"
                            >
                                {{ element.aqvd?.remaining }}
                            </span>
                        </div>
                        <div>
                            <span
                                class="detailed-status-link"
                                (click)="openDetailedStatusPage(element.subsystemNo, 'EX')"
                            >
                                {{ element.aqvd?.exceptions }}
                            </span>
                        </div>
                        <div class="percent">
                            {{ element.aqvd?.percentComplete.replace(',', '.') | number: '1.0-0' }}%
                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="redlines">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div>Redlines</div>
                    <div>
                        <div class="total">Total</div>
                        <div>Complete</div>
                        <div>Remaining</div>
                        <div class="percent">%</div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <div class="total">{{ element.redlines?.total }}</div>
                        <div>{{ element.redlines?.complete }}</div>
                        <div>
                            <span
                                class="detailed-status-link"
                                (click)="openDetailedStatusPage(element.subsystemNo, 'Redline', undefined, 'Red')"
                            >
                                {{ element.redlines?.remaining }}
                            </span>
                        </div>
                        <div class="percent">
                            {{ element.redlines?.percentComplete.replace(',', '.') | number: '1.0-0' }}%
                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="pli">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div>PLI</div>
                    <div>
                        <div>A Open</div>
                        <div>B Open</div>
                        <div>C Open</div>
                        <div>D Open</div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <div>
                            <span
                                class="detailed-status-link"
                                (click)="openDetailedStatusPage(element.subsystemNo, 'PLI', 'A')"
                            >
                                {{ element.pli?.apliOpen }}
                            </span>
                        </div>
                        <div>
                            <span
                                class="detailed-status-link"
                                (click)="openDetailedStatusPage(element.subsystemNo, 'PLI', 'B')"
                            >
                                {{ element.pli?.bpliOpen }}
                            </span>
                        </div>
                        <div>
                            <span
                                class="detailed-status-link"
                                (click)="openDetailedStatusPage(element.subsystemNo, 'PLI', 'C')"
                            >
                                {{ element.pli?.cpliOpen }}
                            </span>
                        </div>
                        <div>
                            <span
                                class="detailed-status-link"
                                (click)="openDetailedStatusPage(element.subsystemNo, 'PLI', 'D')"
                            >
                                {{ element.pli?.dpliOpen }}
                            </span>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr [ngClass]="{ total: !row.subsystemNo }" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="isLoading$ | async" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
</mat-expansion-panel>
