<main class="main">
    <form [formGroup]="ctaFormGroup">
        <span class="close" (click)="close()">&times;</span>
        <p mat-dialog-title class="title">
            <span class="font-gotham-narrow-bold">{{ titleText }}</span>
        </p>
        <div>
            <div class="cta-row">
                <mat-form-field class="margin-right20">
                    <mat-label>Area</mat-label>
                    <mat-select matNativeControl formControlName="areaId" required>
                        <mat-option *ngFor="let area of areas" [value]="area.id">{{ area.name }}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            ctaFormGroup.controls['areaId'].invalid && ctaFormGroup.controls['areaId'].errors.required
                        "
                    >
                        Area is required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="container170">
                    <mat-label>Date</mat-label>
                    <input autocomplete="off" matInput [matDatepicker]="picker" formControlName="date" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <mat-form-field class="margin-right15">
                <mat-label>Description</mat-label>
                <input matInput autocomplete="off" formControlName="description" placeholder="description" />
                <mat-error
                    *ngIf="
                        ctaFormGroup.controls['description'].invalid &&
                        ctaFormGroup.controls['description'].errors.required
                    "
                >
                    Description is required.
                </mat-error>
            </mat-form-field>

            <div class="attachments-container">
                <div class="attachments-header">attachments</div>
                <div appDragAndDrop (filesAdded)="fileChangeEvent($event)" class="drag-and-drop-container">
                    <div class="DandD-img"></div>
                    <div class="drag-and-drop">
                        Drop files here or click to
                        <button class="upload-file-button" type="button" (click)="attachmentFiles.click($event)">
                            select files to upload
                        </button>
                        <div
                            class="tooltip-icon"
                            matTooltip="Maximum file upload limit is 100 MB at a time"
                            matTooltipClass="sctftooltip"
                        ></div>
                        <input
                            multiple
                            #attachmentFiles
                            type="file"
                            (click)="attachmentFiles.value = null"
                            (change)="fileChangeEvent($event.target.files)"
                        />
                    </div>
                </div>
                <div
                    class="uploaded-attachments-container"
                    *ngIf="ctaFormGroup.controls.attachments.value && ctaFormGroup.controls.attachments.value.length"
                >
                    <div
                        class="uploaded-attachment"
                        *ngFor="let uploadedAttachment of ctaFormGroup.controls.attachments.value"
                    >
                        <label>{{ uploadedAttachment.name }}</label>
                        <div class="uploaded-attachment-buttons">
                            <button
                                type="button"
                                class="download-uploaded-attachment"
                                (click)="downloadUploadedAttachment(uploadedAttachment)"
                                *ngIf="uploadedAttachment.link"
                            ></button>
                            <button
                                type="button"
                                class="delete-uploaded-attachment"
                                (click)="deleteUploadedAttachment(uploadedAttachment)"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons-container flex center">
            <button type="button" (click)="close()" class="sct-button sct-button-white">cancel</button>
            <button
                type="button"
                (click)="submit()"
                [disabled]="!ctaFormGroup.valid"
                class="sct-button sct-button-light ok-button"
            >
                submit
            </button>
        </div>
        <app-loading-indicator *ngIf="isLoading || isUploadInProgress"></app-loading-indicator>
    </form>
</main>
