import { UXState } from './model';
import { UXActions, UXActionTypes } from './actions';

const initialState: UXState = {
    scrollPosition: {}
};

export function reducer(state: UXState = initialState, action: UXActions) {
    switch (action.type) {
        case UXActionTypes.SaveScrollPosition: {
            return {
                ...state,
                scrollPosition: { ...state.scrollPosition, ...action.payload },
            };
        }
        default: {
            return state;
        }
    }
}
