import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { finalize, takeWhile} from 'rxjs/operators';
import { TotalCompleteDataDTO } from 'src/app/store/reports/milestone-dashboard/model';
import { Contractor } from 'src/app/store/common.model';
import {

    HomeSystemCompletionDataRequest,
    HomeNPWDataRequest,
} from 'src/app/store/home/actions';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {
    WeeklyPlanningFilter,
    WeeklyPlanningPastTopHeaderDTO,
    WeeklyPlanningTopHeaderResultDTO,
} from 'src/app/store/weekly-planning/model';
import { WeeklyPlanningService } from 'src/app/services/api/webapi-services/weekly-planning.service';
import { getCurrentWeekStartDate, getWeekEndDate } from 'src/app/extensions';
import { HomeCommitment } from 'src/app/store/home/model';
import * as moment from 'moment';
import { ChartSeriesDataDTO } from 'src/app/models/chart-series-dto';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class HomeComponent extends BaseComponent implements OnInit {

    systemCompletionData: TotalCompleteDataDTO[] = [];
    systemCompletionDataTags: TotalCompleteDataDTO[] = [];
    npwData: TotalCompleteDataDTO[] = [];

    isSystemCompletionLoading$ = this.store.select((state) => state.homeState.isLoadingSystemCompletionComplete);
    isNPWLoading$ = this.store.select((state) => state.homeState.isLoadingNPWComplete);

    systemCompletionDataColumns = ['category', 'total', 'complete', 'remaining', 'completePercentage'];
    npwDataColumns = ['category', 'total', 'complete', 'remaining', 'completePercentage'];


    systemCompletionData$ = this.store.select((state) => state.homeState.systemCompletionData);
    systemCompletionDataTags$ = this.store.select((state) => state.homeState.systemCompletionData);
    npwData$ = this.store.select((state) => state.homeState.npwData);
    weeklyTableColumns = ['week', 'committed', 'committedAndAchieved', 'currentWeekPercentage'];
    data = {
        green: {
            chartData: new Array<ChartSeriesDataDTO>(),
            loading: false,
        },
        blue: {
            chartData: new Array<ChartSeriesDataDTO>(),
            loading: false,
        },
        sc: {
            chartData: new Array<ChartSeriesDataDTO>(),
            loading: false,
        },
    };
    colorScheme = { domain: ['#32CD32'] };

    constructor(private store: Store<ApplicationState>, private weeklyPlanningService: WeeklyPlanningService) {
        super();
    }

    ngOnInit(): void {
        const categoriesArea = ['PBF Area', 'GTG Area', 'CM Area', 'WSG Area', 'DP Area'];
        const categoriesTags = ['Heat Tracing Tapes', 'Piping Test Packages', 'Leak Tests'];

        this.npwData$
            .pipe(
                takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data === null) {
                this.store.dispatch(new HomeNPWDataRequest());
            } else {
                this.npwData = data.map((d) => ({ ...new TotalCompleteDataDTO(), ...d }));
            }
            });

        this.systemCompletionData$
            .pipe(
                takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data === null) {
                this.store.dispatch(new HomeSystemCompletionDataRequest());
            } else {
                this.systemCompletionData = data.map((d) => ({ ...new TotalCompleteDataDTO(), ...d })).filter(item => {
                    return categoriesArea.includes(item.category)
                  });
                this.systemCompletionDataTags = data.map((d) => ({ ...new TotalCompleteDataDTO(), ...d })).filter(item => {
                    return categoriesTags.includes(item.category)
                  });
            }
            });




        let rfsuFilter = new WeeklyPlanningFilter();
        rfsuFilter.startDate = getCurrentWeekStartDate().add(1, 'weeks');
        this.searchCommitments(rfsuFilter, 'sc', 'RFSU');
    }

    searchCommitments(filter: WeeklyPlanningFilter, type: string, commtimetType: string) {
        this.data[type].loading = true;
        this.weeklyPlanningService
            .getTopHeaderSectionData('3GP', commtimetType, filter)
            .pipe(
                takeWhile(() => this.isAlive),
                finalize(() => (this.data[type].loading = false))
            )
            .subscribe((data: WeeklyPlanningTopHeaderResultDTO) => {
                let result = new Array<HomeCommitment>();
                result.push(this.getHomeCommitment(data, 0));
                result.push(this.getHomeCommitment(data, 1));
                this.data[type].chartData = [
                    this.formatWeeksToChartSeries(
                        data,
                        'Commitment Reliability',
                        (data: WeeklyPlanningPastTopHeaderDTO) => data.committmentReliability
                    ),
                ];
            });
    }

    private formatWeeksToChartSeries(
        data: WeeklyPlanningTopHeaderResultDTO,
        name: string,
        selector: (data: WeeklyPlanningPastTopHeaderDTO) => number
    ) {
        let result: ChartSeriesDataDTO = { name: name, series: [] };
        data.byPastWeeks.reverse().forEach((weekData) => {
            result.series.push({
                name: `W/E ${getWeekEndDate(weekData.week).format('D MMM')}`,
                value: selector(weekData),
            });
        });
        return result;
    }

    getHomeCommitment(data: WeeklyPlanningTopHeaderResultDTO, index: number) {
        let commitment = new HomeCommitment();
        commitment.week = data.byPastWeeks[index];
        commitment.weekEndDate = moment(commitment.week.week).add(6, 'days');
        commitment.weekPercentage =
            data.byPastWeeks[index].committed && data.byPastWeeks[index].committedAndAchieved
                ? Math.round((data.byPastWeeks[index].committedAndAchieved / data.byPastWeeks[index].committed) * 100)
                : 0;
        return commitment;
    }

    yAxisTickPercentage(value) {
        return `${value}%`;
    }
}
