<div class="table-container">
    <div class="header-container">
        <div
            *ngIf="(pastWeeks$ | async)?.length"
            class="past-weeks"
            [ngStyle]="{ 'flex-grow': (pastWeeks$ | async)?.length }"
            [ngClass]="{
                'vertical-divider': (pastWeeks$ | async)?.length < 10
            }"
        >
            <img src="../../../../../../../assets/images/icons/arrow_back-gray.svg" />
            <span>past weeks</span>
        </div>
        <div
            *ngIf="(upcomingWeeks$ | async)?.length"
            class="forecast"
            [ngStyle]="{ 'flex-grow': (upcomingWeeks$ | async)?.length + 2 }"
        >
            <span>Forecast</span>
            <img src="../../../../../../../assets/images/icons/arrow_forward-gray.svg" />
        </div>
        <div *ngIf="!(upcomingWeeks$ | async)?.length" class="empty-header"></div>
    </div>
    <div class="weeks-header-container">
        <div *ngFor="let pastWeek of pastWeeks" class="past-week">
            <div class="week-label">
                <span class="week-number">WK{{ pastWeek.week }}</span>
                <br />
                <span>{{ pastWeek.date | date: 'd MMM' }}</span>
            </div>
            <div class="labels">
                <div><span class="label-left">Plan</span></div>
                <div><span>Act</span></div>
            </div>
        </div>
        <div *ngFor="let upcomingWeek of upcomingWeeks" class="upcoming-week">
            <div class="week-label">
                <span class="week-number">WK{{ upcomingWeek.week }}</span>
                <br />
                <span>{{ upcomingWeek.date | date: 'd MMM' }}</span>
            </div>
            <div class="labels">
                <div><span class="label-left">Plan</span></div>
                <div><span>Fcst</span></div>
            </div>
        </div>
        <div class="acm-header">
            <span> {{ lookaheadType === 'MC' ? 'Construction Area' : 'System Owner' }}</span>
        </div>
    </div>
    <div *ngIf="(giData$ | async)?.length" class="space-container">
        <div *ngIf="(pastWeeks$ | async)?.length" class="label" [ngStyle]="{ 'flex-grow': (pastWeeks$ | async)?.length }">
            <span>3GI</span>
        </div>
        <div class="empty" [ngStyle]="{ 'flex-grow': (upcomingWeeks$ | async)?.length + 2 }">
            <span *ngIf="!(pastWeeks$ | async)?.length">3GI</span>
        </div>
    </div>
    <div *ngFor="let giRow of giData$ | async" class="gi-row">
        <div *ngFor="let counters of giRow.countersByWeek" class="counters-container">
            <div class="plan-counter">{{ counters.planCount }}</div>
            <div class="actual-counter">
                {{ counters.weekType === weekTypes.Past ? counters.actualCount : counters.forecastCount }}
            </div>
        </div>
        <div class="acm">{{ giRow.forecastHeader }}</div>
    </div>
    <div *ngIf="(giTotals$ | async)?.length && is3giSelected" class="gi-totals-container">
        <div *ngFor="let total of giTotals$ | async" class="totals">
            <div class="plan-totals">{{ total.planTotal }}</div>
            <div class="actual-totals">
                {{ total.weekType === weekTypes.Past ? total.actualTotal : total.forecastTotal }}
            </div>
        </div>
        <div class="total-label">Total</div>
    </div>
    <div *ngIf="(gpData$ | async)?.length" class="space-container">
        <div *ngIf="(pastWeeks$ | async)?.length" class="label" [ngStyle]="{ 'flex-grow': (pastWeeks$ | async)?.length }">
            <span>3GP</span>
        </div>
        <div class="empty" [ngStyle]="{ 'flex-grow': (upcomingWeeks$ | async)?.length + 2 }">
            <span *ngIf="!(pastWeeks$ | async)?.length">3GP</span>
        </div>
    </div>
    <div *ngFor="let gpRow of gpData$ | async" class="gp-row">
        <div *ngFor="let counters of gpRow.countersByWeek" class="counters-container">
            <div class="plan-counter">{{ counters.planCount }}</div>
            <div class="forecast-counter">
                {{ counters.weekType === weekTypes.Past ? counters.actualCount : counters.forecastCount }}
            </div>
        </div>
        <div class="acm">{{ gpRow.forecastHeader }}</div>
    </div>
    <div *ngIf="(gpTotals$ | async)?.length && is3gpSelected" class="gp-totals-container">
        <div *ngFor="let total of gpTotals$ | async" class="totals">
            <div class="plan-totals">{{ total.planTotal }}</div>
            <div class="forecast-totals">
                {{ total.weekType === weekTypes.Past ? total.actualTotal : total.forecastTotal }}
            </div>
        </div>
        <div class="total-label">Total</div>
    </div>
    <div *ngIf="(grandTotals$ | async)?.length" class="grand-totals-container">
        <div *ngFor="let total of grandTotals$ | async" class="totals">
            <div class="plan-totals">{{ total.planTotal }}</div>
            <div class="forecast-totals">
                {{ total.weekType === weekTypes.Past ? total.actualTotal : total.forecastTotal }}
            </div>
        </div>
        <div class="total-label">Grand Total</div>
    </div>
</div>
