import { Injectable } from '@angular/core';
import { MilestoneDTO } from 'src/app/models/milestone-dto';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class MilestoneService {
    private readonly endpoint: string;
    private readonly rootUrl: string = appConfig.apiEndpoint;

    constructor(private http: HttpClient) {
        this.endpoint = '/milestone';
    }

    get() {
        return this.http.get<MilestoneDTO[]>(`${this.rootUrl}${this.endpoint}`);
    }

    remove(name: string) {
        var encodedName = encodeURIComponent(name);
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?name=${encodedName}`);
    }

    update(milestone: MilestoneDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, milestone);
    }

    add(milestone: MilestoneDTO) {
        return this.http.post<MilestoneDTO>(`${this.rootUrl}${this.endpoint}`, milestone);
    }
}
