import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
// tslint:disable-next-line: max-line-length
import {
    SubsystemMaintenanceActionTypes,
    SubsystemFilterSuccess,
    SubsystemFilterError,
    SubsystemSaveSuccess,
    SubsystemSaveRequest,
    SubsystemSaveError,

} from './actions';
import { withLatestFrom, mergeMap, map, catchError} from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { ApplicationState } from '../../model';
import { SubsystemMaintenanceService } from 'src/app/services/api/webapi-services/subsystem-maintenance.service';

@Injectable()
export class SubsystemMaintenanceEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private SubsystemsMaintenanceService: SubsystemMaintenanceService,
        private toastService: ToastService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType(SubsystemMaintenanceActionTypes.SubsystemFilterRequest),
        withLatestFrom(this.store.select((store) => store.subsystemMaintenanceState.filter)),
        mergeMap(([, filter]) => {
            return this.SubsystemsMaintenanceService.get(filter).pipe(
                map((dataPagination) => {
                    return new SubsystemFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Subsystem data. Please contact Program Administrator.'
                    );
                    return of(new SubsystemFilterError(error));
                })
            );
        })
    );

    @Effect()
    saveSubsystemRequest$ = this.actions$.pipe(
        ofType<SubsystemSaveRequest>(SubsystemMaintenanceActionTypes.SubsystemSaveRequest),
        mergeMap((action) => {
            return this.SubsystemsMaintenanceService.update(action.payload).pipe(
                map(() => {
                    return new SubsystemSaveSuccess();
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while saving a Subsystem. Please contact Program Administrator.'
                    );
                    return of(new SubsystemSaveError(error));
                })
            );
        })
    );
}
