
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    ExceptionsUploadActionTypes,
    ExceptionsUploadLogSuccess,
    ExceptionsUploadLogError,
    ExceptionsUploadValidateButtonStateSuccess,
    ExceptionsUploadValidateButtonStateError,
    ExceptionsUploadDownloadOutputDataSuccess,
    ExceptionsUploadDownloadOutputDataError,
    ExceptionsUploadDownloadOutputDataRequest,
    ExceptionsUploadTemplateFileSuccess,
    ExceptionsUploadTemplateFileRequest,
    ExceptionsUploadTemplateFileError,
    ExceptionsUploadClearInProgressSpinner,
    ExceptionsUploadValidateDeleteSuccess,
    ExceptionsUploadValidateDeleteError,
    ExceptionsUploadPatchAllDeleteRequest,
    ExceptionsUploadPatchAllDeleteSuccess,
    ExceptionsUploadPatchAllDeleteError,
    ExceptionsUploadPatchDeleteRequest,
    ExceptionsUploadPatchDeleteSuccess,
    ExceptionsUploadPatchDeleteError,
    ExceptionsUploadDeleteSuccess,
    ExceptionsUploadDeleteError,
    ExceptionsUploadDeleteRequest,
    ExceptionsUploadSetStatusAndStartDate,
} from './actions';

@Injectable()
export class ExceptionsUploadEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<ExceptionsUploadTemplateFileRequest>(ExceptionsUploadActionTypes.ExceptionsUploadTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new ExceptionsUploadTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading ITR-PLI Exception Upload file. Please contact Program Administrator'
                    );
                    return of(new ExceptionsUploadTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<ExceptionsUploadTemplateFileSuccess>(ExceptionsUploadActionTypes.ExceptionsUploadTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'ITR-PLI Exception Upload.xlsx');
        })
    );

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(ExceptionsUploadActionTypes.ExceptionsUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['ExceptionsUpload']).pipe(
                map((uploadLogData) => new ExceptionsUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new ExceptionsUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<ExceptionsUploadLogSuccess>(ExceptionsUploadActionTypes.ExceptionsUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.exceptionsUploadState.uploadLogStatus),
            this.store.select((store) => store.exceptionsUploadState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new ExceptionsUploadSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    // @Effect()
    // invokeDeleteRequest$ = this.actions$.pipe(
    //     ofType(ExceptionsUploadActionTypes.ExceptionsUploadSetStatusAndStartDate),
    //     withLatestFrom(this.store.select((store) => store.exceptionsUploadState.uploadLogData[0])),
    //     filter(
    //         ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
    //     ),
    //     map(() => new ExceptionsUploadDeleteRequest())
    // );

    // @Effect()
    // getDelete$ = this.actions$.pipe(
    //     ofType(ExceptionsUploadActionTypes.ExceptionsUploadDeleteRecordsRequest),
    //     withLatestFrom(this.store.select((store) => store.exceptionsUploadState.deleteFilter)),
    //     mergeMap(([, deleteFilter]) =>
    //         this.manualUploadsService.getExceptionsUploadDeleteRecords(deleteFilter).pipe(
    //             map((deleteData: any) => new ExceptionsUploadDeleteSuccess(deleteData)),
    //             catchError((error) => {
    //                 this.toastService.Error(
    //                     'Error has occurred while getting Delete data records. Please contact Program Administrator'
    //                 );
    //                 return of(new ExceptionsUploadDeleteError(error));
    //             })
    //         )
    //     )
    // );

    // @Effect()
    // patchDeleteData$ = this.actions$.pipe(
    //     ofType<ExceptionsUploadPatchDeleteRequest>(ExceptionsUploadActionTypes.ExceptionsUploadPatchDeleteRecordRequest),
    //     mergeMap((action) =>
    //         this.manualUploadsService.patchExceptionsUploadDeleteRecord(
    //             action.payload.itrpliNumber, action.payload.deleteState
    //             ).pipe(
    //             map(() => new ExceptionsUploadPatchDeleteSuccess()),
    //             catchError((error) => {
    //                 this.toastService.Error(
    //                     `Error has occurred while ${
    //                         action.payload.deleteState ? 'accepting' : 'rejecting'
    //                     } the change. Please contact Program Administrator`
    //                 );
    //                 return of(new ExceptionsUploadPatchDeleteError(error));
    //             })
    //         )
    //     )
    // );

    // @Effect()
    // patchAllDelete$ = this.actions$.pipe(
    //     ofType<ExceptionsUploadPatchAllDeleteRequest>(ExceptionsUploadActionTypes.ExceptionsUploadPatchAllDeleteRecordsRequest),
    //     withLatestFrom(this.store.select((store) => store.exceptionsUploadState.deleteFilter)),
    //     mergeMap(([action, deleteFilter]) =>
    //         this.manualUploadsService.patchAllExceptionsUploadDeleteRecords(deleteFilter, action.payload.deleteState).pipe(
    //             map(() => new ExceptionsUploadPatchAllDeleteSuccess({ deleteState: action.payload.deleteState })
    //             ),
    //             catchError((error) => {
    //                 this.toastService.Error(
    //                     `Error has occurred while ${
    //                         action.payload.deleteState ? 'accepting' : 'rejecting'
    //                     } the change. Please contact Program Administrator`
    //                 );
    //                 return of(new ExceptionsUploadPatchAllDeleteError(error));
    //             })
    //         )
    //     )
    // );

    // @Effect()
    // validateDelete$ = this.actions$.pipe(
    //     ofType(ExceptionsUploadActionTypes.ExceptionsUploadValidateDataRequest),
    //     mergeMap(() =>
    //         this.manualUploadsService.validateExceptionsUploadData().pipe(
    //             map(() => new ExceptionsUploadValidateDeleteSuccess()),
    //             catchError((error) => {
    //                 this.toastService.Error(
    //                     'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
    //                 );
    //                 return of(new ExceptionsUploadValidateDeleteError(error));
    //             })
    //         )
    //     )
    // );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(ExceptionsUploadActionTypes.ExceptionsUploadDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.exceptionsUploadState.uploadLogData)),
        filter(([, uploadLogs]) => {
            let logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new ExceptionsUploadClearInProgressSpinner())
    );



    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<ExceptionsUploadDownloadOutputDataRequest>(ExceptionsUploadActionTypes.ExceptionsUploadDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new ExceptionsUploadDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new ExceptionsUploadDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<ExceptionsUploadDownloadOutputDataSuccess>(ExceptionsUploadActionTypes.ExceptionsUploadDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(ExceptionsUploadActionTypes.ExceptionsUploadDeleteRecordsSuccess,
            ExceptionsUploadActionTypes.ExceptionsUploadPatchAllDeleteRecordsSuccess,
            ExceptionsUploadActionTypes.ExceptionsUploadPatchDeleteRecordSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('ExceptionsUpload').pipe(
                map((response: any) => new ExceptionsUploadValidateButtonStateSuccess(response)),
                catchError((error) => of(new ExceptionsUploadValidateButtonStateError(error)))
            )
        )
    );
}

