import {
    Waypoint,
    Milestone,
    OrderDirection,
    Contractor,
    CheckListColumn,
    CalendarColumn,
    UserDetail,
    Gooc,
    RFO,
    TCOUser,
    StagedStartUpPriority,
} from '../common.model';
import * as moment from 'moment';

export class TurnoverGroupDTO {
    id: string;
    name: string;
}

export class ChangeManagementFilter {
    page = 0;
    take = 25;
    sortBy = 'id';
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    changeTypes: string[] = [];
    turnoverGroups: TurnoverGroupDTO[] = [];
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    rfsuContractsPending: string[] = [];
    subsystems: { id: string }[] = [];
    rfos: RFO[] = [];
    mcMilestones: Milestone[] = [];
    goocs: Gooc[] = [];
    disciplines: string[] = [];
    designStatuses: string[] = [];
    priorities: string[] = [];
    timezoneOffset = 0;
    columnChangeType: CheckListColumn = null;
    columnTitle: CheckListColumn = null;
    columnId: CheckListColumn = null;
    columnSubsystem: CheckListColumn = null;
    columnSignedSubsystem: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnLinkedIWPs: CheckListColumn = null;
    columnLinkedCOFs: CheckListColumn = null;
    columnRFSUContractor: CheckListColumn = null;
    columnRFSUContractsPending: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnFieldImplementationContractor: CheckListColumn = null;
    columnValidationStatus: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnDesignStatus: CheckListColumn = null;
    columnPriority: CheckListColumn = null;
    columnRFIOriginator: CheckListColumn = null;
    columnAreaBreakDown: CheckListColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    columnRFO : CheckListColumn = null;
    columnRFOStatus : CheckListColumn = null;
    columnDate: CalendarColumn = null;
    columnRFSUForecast: CalendarColumn = null;
    columnWalkdownForecast: CalendarColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;
    columnRevision: CheckListColumn = null;
    columnSystemOwner: CheckListColumn = null;
    columnRFOForecast: CalendarColumn = null;
    columnMcActual: CalendarColumn = null;
    columnWalkdownActual : CalendarColumn = null;
    columnImplementationStatus: CheckListColumn = null;
    columnNBResponsiblePersonComments: CheckListColumn =null;
    showHideColumns = new ShowHideColumns();
    remaining = false;
    mcEngineer: TCOUser[] = [];
    systemOwner: TCOUser[] = [];
    stagedStartUpPriority: StagedStartUpPriority[] = [];
    columnStepStatus: CheckListColumn = null;
}
export class ShowHideColumns {
    type = true;
    discp = true;
    title = true;
    subsystem = true;
    signedSubsystem = false;
    responsiblePerson = false;
    linkedIWPs = false;
    contract = false;
    comment = true;
    linkedCOFs = false;
    rfsuContractor = false;
    rfsuContractsPending= false;
    designStatus = true;
    implementationStatus = true;
    date = false;
    rfsuForecast = true;
    rfsuPlan = true;
    mcActual = false;
    walkdownActual = false;
    fieldImplementationContractor = true;
    exceptionNumber = true;
    validationStatus = false;
    priority = false;
    rfiOriginator = false;
    walkdownForecast = false;
    createdDate = false;
    revision = false;
    systemOwner = true;
    rfoForecast = true;
    rfo = true;
    rfoStatus = false;
    areaBreakDown = true;
    stepStatus = false;
}

export class ChangeManagementDTO {
    type: string;
    id: number;
    number: string;
    discipline: string;
    title: string;
    subsystem: string;
    signedSubsystem: string;
    contractor: string;
    designStatus: string;
    reference: string;
    comment: string;
    linkedIWPs: string;
    linkedCOFs: string;
    rfsuContractor: string;
    rfsuContractsPending: string;
    subsystemsImpactedNA: boolean;
    mentions: UserDetail[];
    date: moment.Moment = null;
    rfsuForecast: moment.Moment = null;
    walkdownForecast: moment.Moment = null;
    createdDate: moment.Moment = null;
    rfsuPlan: moment.Moment = null;
    mcActual: moment.Moment = null;
    walkdownActual: moment.Moment = null;
    fieldImplementationContractor : string;
    validationStatus : string;
    priority : string;
    fieldImplementationContractors : string[];
    latestNBResponsiblePersonComment: string;
    currentStep: number;
    OriginatingPersonName : string;
}

export interface ChangeManagementState {
    dataPagination: ChangeManagementDataPagination;
    filter: ChangeManagementFilter;
    isLoading: boolean;
    makeRequest: boolean;
}

export class ChangeManagementDataPagination {
    items: ChangeManagementDTO[] = [];
    totalCount = 0;
}
