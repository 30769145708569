import { Injectable } from '@angular/core';
import {
    ChangeValidationDashboardFilter,
    WeeklyValidationProgressDataDTO,
    MonthlyValidationProgressDataDTO,
    ChangeValidationDashboardProgressDataDTO,
    NameSeriesDataDTO,
    ValidatorPerformanceScoreboardDataDTO,
    ProgressRangeDateFilter,
} from 'src/app/store/reports/change-validation-dashboard/model';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ChangeValidationDashboardService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/changevalidationdashboard';

    constructor(private http: HttpClient) {}

    filterWeeklyValidationProgressData(filter: ProgressRangeDateFilter & ChangeValidationDashboardFilter) {
        return this.http.post<WeeklyValidationProgressDataDTO[]>(
            `${this.rootUrl}${this.endpoint}/weeklyvalidationprogress`,
            filter
        );
    }

    filterMonthlyValidationProgressData(filter: ProgressRangeDateFilter & ChangeValidationDashboardFilter) {
        return this.http.post<MonthlyValidationProgressDataDTO[]>(
            `${this.rootUrl}${this.endpoint}/weeklyvalidationprogress`,
            filter
        );
    }

    filterValidationProgressData(filter: ChangeValidationDashboardFilter) {
        return this.http.post<ChangeValidationDashboardProgressDataDTO>(
            `${this.rootUrl}${this.endpoint}/validationprogress`,
            filter
        );
    }
    filterCumulativeValidationProgressData(filter: ProgressRangeDateFilter & ChangeValidationDashboardFilter) {
        return this.http.post<NameSeriesDataDTO[]>(
            `${this.rootUrl}${this.endpoint}/cumulativevalidationprogress`,
            filter
        );
    }
    filterWeeklyCumulativeValidationProgressData(filter: ProgressRangeDateFilter & ChangeValidationDashboardFilter) {
        return this.http.post<NameSeriesDataDTO[]>(
            `${this.rootUrl}${this.endpoint}/weeklycumulativevalidationprogress`,
            filter
        );
    }
    filterSystemisationProgressData(filter: ChangeValidationDashboardFilter) {
        return this.http.post<ChangeValidationDashboardProgressDataDTO>(
            `${this.rootUrl}${this.endpoint}/systemisationprogress`,
            filter
        );
    }
    filterMonthlyChangeRaisedData(filter: ProgressRangeDateFilter & ChangeValidationDashboardFilter) {
        return this.http.post<NameSeriesDataDTO[]>(`${this.rootUrl}${this.endpoint}/monthlychangeraised`, filter);
    }
    filterWeeklyChangeRaisedData(filter: ProgressRangeDateFilter & ChangeValidationDashboardFilter) {
        return this.http.post<NameSeriesDataDTO[]>(`${this.rootUrl}${this.endpoint}/weeklychangeraised`, filter);
    }
    filterValidatorPerformanceScoreboardData(filter: ProgressRangeDateFilter) {
        return this.http.post<ValidatorPerformanceScoreboardDataDTO[]>(
            `${this.rootUrl}${this.endpoint}/validatorperformancescoreboard`,
            filter
        );
    }
}
