<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span>{{pagerTitle}}</span>
        </div>
    </div>
    <div class="results-panel results-container align-items-center">
        <iframe title="Power BI Report" width="1750" height="1000" [src]="reportUrlSafe" frameborder="0"
            allowFullScreen="true"></iframe>
    </div>
</main>