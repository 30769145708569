import { Injectable } from '@angular/core';
import {
    MilestoneDashboardFilter,
    TotalCompleteDataDTO,
    RundownDataDTO,
    MilestoneDashboardType,
} from 'src/app/store/reports/milestone-dashboard/model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseResultDTO } from 'src/app/store/common.model';
import {
    GOOC1ComparisonDashboardDTO,
    GOOC2ComparisonDashboardDTO,
    GOOCComparisonDashboardFilter,
} from 'src/app/store/reports/gooc/model';
import { appConfig } from 'src/app/app.config';
import { SubsystemMCDashboard2DTO, SubsystemMCDashboardDTO, SubsystemMCDashboardFilter } from 'src/app/store/reports/subsystem-mc/model';
import {
    ChangeValidationDTO,
    EarlyWalkdownsDTO,
    EarlyWorksDTO,
    PLIValidationDTO,
    Schedule,
    SOCPlanDTO,
} from 'src/app/store/reports/pre-mc/model';
import {SubsystemRFSUDashboardDTO, SubsystemRFSUDashboardFilter} from 'src/app/store/reports/subsystem-rfsu/model';

@Injectable({
    providedIn: 'root',
})
export class MilestoneDashboardService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/milestonedashboard';

    constructor(private http: HttpClient) {}

    filterTotalCompleteData(
        filter: MilestoneDashboardFilter,
        dashboardType: MilestoneDashboardType = MilestoneDashboardType.MC
    ) {
        return this.http.post<TotalCompleteDataDTO[]>(
            `${this.rootUrl}${this.endpoint}/totalcomplete?dashboardType=${dashboardType}`,
            filter
        );
    }

    filterRundownData(filter: MilestoneDashboardFilter, monthlyViewEnabled: boolean) {
        return this.http.post<RundownDataDTO[]>(
            `${this.rootUrl}${this.endpoint}/rundown?monthlyView=${monthlyViewEnabled}`,
            filter
        );
    }

    generateSubsystemRFSUExcel(filter: SubsystemRFSUDashboardFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel/subsystemrfsu`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    generateSubsystemMCExcel(filter: SubsystemMCDashboardFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel/subsystemmc`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    generateSubsystemMC2Excel(filter: SubsystemMCDashboardFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel/subsystemmc2`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    generateExcelGOOCComparison1(filter: GOOCComparisonDashboardFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel/gooc1`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    generateExcelGOOCComparison2(filter: GOOCComparisonDashboardFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel/gooc2`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    filterGOOC1Data(filter: GOOCComparisonDashboardFilter) {
        return this.http.post<BaseResultDTO<GOOC1ComparisonDashboardDTO>>(
            `${this.rootUrl}${this.endpoint}/gooc1`,
            filter
        );
    }

    filterGOOC2Data(filter: GOOCComparisonDashboardFilter) {
        return this.http.post<BaseResultDTO<GOOC2ComparisonDashboardDTO>>(
            `${this.rootUrl}${this.endpoint}/gooc2`,
            filter
        );
    }

    filterSubsystemMC1Data(filter: SubsystemMCDashboardFilter) {
        return this.http.post<BaseResultDTO<SubsystemMCDashboardDTO>>(
            `${this.rootUrl}${this.endpoint}/subsystemmc`,
            filter
        );
    }

    filterSubsystemMC2Data(filter: SubsystemMCDashboardFilter) {
        return this.http.post<BaseResultDTO<SubsystemMCDashboard2DTO>>(
            `${this.rootUrl}${this.endpoint}/subsystemmc2`,
            filter
        );
    }

    filterSubsystemRFSUData(filter: SubsystemRFSUDashboardFilter) {
        return this.http.post<BaseResultDTO<SubsystemRFSUDashboardDTO>>(
            `${this.rootUrl}${this.endpoint}/subsystemrfsu`,
            filter
        );
    }

    filterEarlyWorksData(goocNo: string) {
        return this.http.get<EarlyWorksDTO>(`${this.rootUrl}${this.endpoint}/pre-mc/earlyworks?goocNo=${goocNo}`);
    }

    saveEarlyWorks(goocNo: string, earlyWorks: EarlyWorksDTO) {
        return this.http.post<EarlyWorksDTO>(
            `${this.rootUrl}${this.endpoint}/pre-mc/earlyworks?goocNo=${goocNo}`,
            earlyWorks
        );
    }

    filterEarlyWalkdownsData(goocNo: string) {
        return this.http.get<EarlyWalkdownsDTO>(
            `${this.rootUrl}${this.endpoint}/pre-mc/earlywalkdowns?goocNo=${goocNo}`
        );
    }

    saveEarlyWalkdowns(goocNo: string, earlyWalkdowns: EarlyWalkdownsDTO) {
        return this.http.post<EarlyWalkdownsDTO>(
            `${this.rootUrl}${this.endpoint}/pre-mc/earlywalkdowns?goocNo=${goocNo}`,
            earlyWalkdowns
        );
    }

    filterPLIValidationData(goocNo: string) {
        return this.http.get<PLIValidationDTO>(`${this.rootUrl}${this.endpoint}/pre-mc/plivalidation?goocNo=${goocNo}`);
    }

    savePLIValidation(goocNo: string, pliValidation: PLIValidationDTO) {
        return this.http.post<PLIValidationDTO>(
            `${this.rootUrl}${this.endpoint}/pre-mc/plivalidation?goocNo=${goocNo}`,
            pliValidation
        );
    }

    filterChangeValidationData(goocNo: string) {
        return this.http.get<ChangeValidationDTO>(
            `${this.rootUrl}${this.endpoint}/pre-mc/changevalidation?goocNo=${goocNo}`
        );
    }

    filterAQVDRequestStatusData(goocNo: string) {
        return this.http.get<{ key: string; value: number }[]>(
            `${this.rootUrl}${this.endpoint}/pre-mc/aqvdrequeststatus?goocNo=${goocNo}`
        );
    }

    filterSOCPlanData(goocNo: string) {
        return this.http.get<SOCPlanDTO>(`${this.rootUrl}${this.endpoint}/pre-mc/socplan?goocNo=${goocNo}`);
    }

    saveSOCPlan(goocNo: string, socPlan: SOCPlanDTO) {
        return this.http.post<SOCPlanDTO>(`${this.rootUrl}${this.endpoint}/pre-mc/socplan?goocNo=${goocNo}`, socPlan);
    }

    filterPreMCSkylineData(goocNo: string) {
        return this.http.get<any>(`${this.rootUrl}${this.endpoint}/pre-mc/skyline?goocNo=${goocNo}`);
    }

    filterPreRFSUSkylineData(rfo: string) {
        return this.http.get<any>(`${this.rootUrl}${this.endpoint}/pre-rfsu/skyline?rfo=${rfo}`);
    }

    filterScheduleData(goocNo: string) {
        return this.http.get<Schedule>(`${this.rootUrl}${this.endpoint}/pre-mc/schedule?goocNo=${goocNo}`);
    }

    saveSchedule(goocNo: string, schedule: Schedule) {
        return this.http.post<Schedule>(
            `${this.rootUrl}${this.endpoint}/pre-mc/schedule?goocNo=${goocNo}`,
            schedule
        );
    }
}
