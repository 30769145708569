import { Injectable } from '@angular/core';
import {
    PlanningFilter,
    PlanningLookaheadDTO,
    PlanningCommitmentReliabilityDTO,
    WeeklyPlanningCommitmentDTO,
} from 'src/app/store/reports/planning/model';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class PlanningService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly planningEndpoint: string = '/planning';

    constructor(private http: HttpClient) {}

    filterPlanningLookaheadData(type: string, filter: PlanningFilter) {
        return this.http.post<PlanningLookaheadDTO[]>(
            `${this.rootUrl}${this.planningEndpoint}/lookahead/${type}`,
            filter
        );
    }

    filterPlanningCommitmentReliabilityData(type: string, filter: PlanningFilter, monthlyViewEnabled: boolean) {
        return this.http.post<WeeklyPlanningCommitmentDTO>(
            `${this.rootUrl}${this.planningEndpoint}/commitmentreliability/${type}?monthlyView=${monthlyViewEnabled}`,
            filter
        );
    }
}
