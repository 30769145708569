<main class="main">
    <a class="navigator" [routerLink]="['/weeklyplanning/dashboard']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            {{ updateTitle }}
        </div>
        <div class="form-buttons-container">
            <button type="button" class="sct-button sct-button-light" (click)="generateexcel()">
                export
            </button>
        </div>
    </div>
    <form [formGroup]="scWorkAreaForm">
        <div class="filter-container">
            <div class="work-area-container">
                <div class="subtitle">
                    work area
                </div>
                <div class="mixedapp-container">
                    <div class="container300 margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="workAreaName"
                            [searchFunc]="getWorkArea"
                            placeholder="Work Area"
                            [canAddManually]="true"
                            [oneItemMode]="true"
                            [isAsync]="true"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            required
                            [customActions]="getCustomActions"
                            [invalid]="
                                scWorkAreaForm.controls['workAreaName'].invalid &&
                                scWorkAreaForm.controls['workAreaName'].errors.required
                            "
                            [errorMessage]="'Work area name is required'"
                            [setInput]="setWorkAreaInput"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300 margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="disciplines"
                            [filterForm]="scWorkAreaForm"
                            [formSetter]="'disciplines'"
                            [searchFunc]="getDiscipline"
                            [isAsync]="true"
                            placeholder="Discipline"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            required
                            [invalid]="
                                scWorkAreaForm.controls['disciplines'].invalid &&
                                scWorkAreaForm.controls['disciplines'].errors.required
                            "
                            [errorMessage]="'Discipline is required'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300 margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="nbEngineer"
                            [searchFunc]="getNBEngineer"
                            [filterForm]="scWorkAreaForm"
                            [formSetter]="'nbEngineer'"
                            [isAsync]="true"
                            placeholder="NB Engineer"
                            [canAddManually]="true"
                            [oneItemMode]="true"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div class="mixedapp-container">
                    <div class="container300 margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="tcoAreaCoordinator"
                            [searchFunc]="getTCOAreaCoordinator"
                            [filterForm]="scWorkAreaForm"
                            [formSetter]="'tcoAreaCoordinator'"
                            [isAsync]="true"
                            placeholder="TCO Area Coordinator"
                            [canAddManually]="true"
                            [oneItemMode]="true"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300 margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="nbDisciplineLead"
                            [searchFunc]="getNBDisciplineLead"
                            [filterForm]="scWorkAreaForm"
                            [formSetter]="'nbDisciplineLead'"
                            [isAsync]="true"
                            placeholder="NB Discipline Lead"
                            [canAddManually]="true"
                            [oneItemMode]="true"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="create-button-container">
                        <button
                            type="button"
                            [disabled]="!scWorkAreaForm.valid || (updatedProperties.length === 0 && isInEditMode)"
                            class="sct-button sct-button-light"
                            (click)="onConfirm()"
                        >
                            {{ isInEditMode ? updateText : addText }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="work-area-container">
                <div class="subtitle">
                    subsystem
                </div>
                <app-subsystem-selector
                    [setSelectedSubsystems]="scWorkAreaForm.controls.workAreaSubsystems.value"
                    (selectedSubsystemsChanged)="setSubsystems($event)"
                    [setInput]="setSubsystemInput"
                ></app-subsystem-selector>
            </div>
        </div>
    </form>
    <app-loading-indicator *ngIf="loading"></app-loading-indicator>
</main>
