import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, OrderDirection } from '../../common.model';

export interface ExceptionsUploadState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deleteData: ExceptionsUploadDeletePagination;
    isDeleteDataLoading: boolean;
    deleteFilter: ExceptionsUploadDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class ExceptionsUploadDeleteDTO {
    deleteState: boolean;
    itrpliNumber: string;
    exceptionNumber: string;
}

export class ExceptionsUploadDeleteFilter {
    page = 0;
    take = 10;
    sortBy = 'itrpliNumber';
    direction: OrderDirection = OrderDirection.Desc;
    columnExceptionNumber: CheckListColumn = null;
    columnitrpliNumber: CheckListColumn = null;
}
export class ExceptionsUploadDeletePagination {
    items: ExceptionsUploadDeleteDTO[] = [];
    totalCount = 0;
}
