import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/components/base.component';
import { UploadType } from 'src/app/enums';
import { uploadTypeEnumToString } from 'src/app/extensions';
import { TagsTemplateFileRequest } from 'src/app/store/automatic-upload/tags/actions';
import { ApplicationState } from 'src/app/store/model';

@Component({
  selector: 'app-automated-file-transfers-mcplus',
  templateUrl: './automated-file-transfers-mcplus.component.html',
  styleUrls: ['./automated-file-transfers-mcplus.component.scss']
})
export class AutomatedFileTransfersMcplusComponent extends BaseComponent implements OnInit {
  uploadTypes = UploadType;


  constructor(private store: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {
    // This is intentional
  }

  getUploadTypeString(uploadType: UploadType) {
    return uploadTypeEnumToString(uploadType)
  }

  downloadTagTemplate(fileName: string) {
    this.store.dispatch(new TagsTemplateFileRequest(fileName));
  }

}
