import { OrderDirection } from '../common.model';

export class ChangeImpactedTagsFilter {
    page = 0;
    take = 10;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    changeTypes: string[] = [];
    number: string = '';
    title: string = '';
    impactedTags: string = '';
}

export class DcnDTO {
    id: number;
    number: string;
    title: string;
    impactedTags: string[];
}

export class ImpactedTagsPopupInput {
    element: DcnDTO;
    action: (impactedTags: string[]) => void;
}

export interface ChangeImpactedTagsState {
    dataPagination: ChangeImpactedTagsDataPagination;
    filter: ChangeImpactedTagsFilter;
    isLoading: boolean;
}

export class ChangeImpactedTagsDataPagination {
    items: DcnDTO[] = [];
    totalCount = 0;
}
