import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { WeeklyPlanningFilterRequest, WeeklyPlanningSetPlanningType } from 'src/app/store/weekly-planning/actions';
import * as moment from 'moment';

@Component({
    selector: 'app-weekly-planning-session-rfo',
    templateUrl: './weekly-planning-session-rfo.component.html',
    styleUrls: ['./weekly-planning-session-rfo.component.scss'],
})
export class WeeklyPlanningSessionRFOComponent {
    projectTeamName = '';
    planningTableIndex = [0, 1, 2, 3, 4];
    isInPastWeek$ = this.store.select((store) => {
        const today = moment().utc().startOf('day');
        if (today.isoWeekday() !== 6) {
            today.add(-1, 'weeks').isoWeekday(6);
        }
        return store.weeklyPlanningState.filter.startDate.isBefore(today);
    });

    constructor(private activatedRoute: ActivatedRoute, private store: Store<ApplicationState>) {}

    ngOnInit(): void {
        this.activatedRoute.parent.paramMap
            .pipe(take(1))
            .subscribe((paramMap) => (this.projectTeamName = paramMap.get('projectTeamName').toUpperCase()));

        this.store.dispatch(new WeeklyPlanningSetPlanningType('rfo'));
        this.store.dispatch(new WeeklyPlanningFilterRequest(this.planningTableIndex));
    }
}
