import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { Observable, of } from 'rxjs';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { take, mergeMap, finalize, tap, catchError } from 'rxjs/operators';
import { ProjectTeamService } from 'src/app/services/api/webapi-services/project-team.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AddProjectTeamPopupComponent } from './add-popup/add-project-team-popup.component';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-project-team-maintenance',
    templateUrl: './project-team-maintenance.component.html',
    styleUrls: ['./project-team-maintenance.component.scss'],
})
export class ProjectTeamMaintenanceComponent implements OnInit {
    displayedColumns = ['projectTeamName', 'remove'];
    isLoading = false;
    projectTeamNames: string[] = [];

    projectTeamNames$: Observable<string[]>;

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private lookupService: LookupService,
        private popupService: PopupService,
        private projectTeamService: ProjectTeamService,
        private toastService: ToastService
    ) {
        this.iconRegistry.addSvgIcon(
            'delete',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon(
            'add',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg')
        );
    }

    ngOnInit() {
        this.isLoading = true;
        this.projectTeamNames$ = this.lookupService.getProjectTeams().pipe(
            tap((projectTeamNames: any) => (this.projectTeamNames = projectTeamNames)),
            finalize(() => (this.isLoading = false))
        );
    }

    remove = (projectTeamName: string) => {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete project team: ${projectTeamName}?`,
                })
            )
            .afterClosed()
            .pipe(take(1))
            .subscribe((answer: boolean) => {
                this.isLoading = answer;
                this.projectTeamNames$ = answer
                    ? this.projectTeamService.removeProjectTeam(projectTeamName).pipe(
                          mergeMap(() =>
                              this.lookupService
                                  .getProjectTeams()
                                  .pipe(tap((projectTeamNames: any) => (this.projectTeamNames = projectTeamNames)))
                          ),
                          finalize(() => (this.isLoading = false)),
                          catchError((error) => {
                              this.toastService.Error(
                                  `Error has occurred while removing Project Team: ${projectTeamName}. Please contact Program Administrator.`
                              );
                              return of(error);
                          })
                      )
                    : this.projectTeamNames$;
            });
    };

    add = () => {
        this.popupService
            .openPopup(
                new PopupSettings(AddProjectTeamPopupComponent, 450, 280, {
                    projectTeamNames: this.projectTeamNames,
                })
            )
            .afterClosed()
            .pipe(take(1))
            .subscribe((newProjectTeamName) => {
                this.isLoading = !!newProjectTeamName;
                this.projectTeamNames$ = newProjectTeamName
                    ? this.projectTeamService.addProjectTeam(newProjectTeamName).pipe(
                          tap(() =>
                              this.toastService.Success(
                                  `Project Team: ${newProjectTeamName} successfully added. Remember to add Contractors and Contractors Mapping.`
                              )
                          ),
                          mergeMap(() =>
                              this.lookupService
                                  .getProjectTeams()
                                  .pipe(tap((projectTeamNames: any) => (this.projectTeamNames = projectTeamNames)))
                          ),
                          finalize(() => (this.isLoading = false))
                      )
                    : this.projectTeamNames$;
            });
    };
}
