import { Injectable } from '@angular/core';
import { ForecastHistoryDateType } from 'src/app/enums';
import { appConfig } from 'src/app/app.config';
import { HttpClient } from '@angular/common/http';
import { ForecastDateHistory } from 'src/app/store/forecast-history/model';
@Injectable({
    providedIn: 'root',
})
export class ForecastHistoryService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly forecastHistoryEndpoint: string = '/forecasthistory';
    constructor(private http: HttpClient) {}

    getForecastDateHistory(
        rfo:string,
        subsystem: string,
        type: ForecastHistoryDateType,
        discipline: string,
        allDisciplines = false,
        number: string = ""
    ) {
        if(type == ForecastHistoryDateType.PostRFOExpectedClosureDate){
            return this.http.get<ForecastDateHistory[]>(
                `${this.rootUrl}${this.forecastHistoryEndpoint}/getpostrfoexpectedclosuredatehistory?rfo=${encodeURIComponent(
                    rfo
                )}&subsystem=${subsystem}&number=${number}`,
                {}
            );
        } else if(type == ForecastHistoryDateType.PLIForecastClosureDate ){
            return this.http.get<ForecastDateHistory[]>(
                `${this.rootUrl}${this.forecastHistoryEndpoint}/getpliforecastclosuredatehistory?pli=${encodeURIComponent(
                    number
                )}&type=${type}`,
                {}
            );
        }else{
            return this.http.get<ForecastDateHistory[]>(
                `${this.rootUrl}${this.forecastHistoryEndpoint}/getforecasthistory?subsystem=${encodeURIComponent(
                    subsystem
                )}&type=${type}&discipline=${discipline ?? ''}&allDisciplines=${allDisciplines}`,
                {}
            );
        }
    }

    getExceptionForecastDateHistory(
        number: string,
        pliOrBarcode: string,
        itrId: number,
        scope: number
    ) {
        return this.http.get<ForecastDateHistory[]>(
            `${this.rootUrl}${this.forecastHistoryEndpoint}/getexceptionforecastclosuredatehistory?pliOrBarcode=${pliOrBarcode}&exceptionNo=${number}&itrId=${itrId}&scope=${scope}`,
            {}
        );
    }
}
