import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { WeeklyPlanType } from 'src/app/enums';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import { FormService } from 'src/app/services/shared/form.service';
import { ApplicationState } from 'src/app/store/model';
import {
    ScheduleActivityITRDataRequest,
    ScheduleActivityITRFilterPropertyReset,
    ScheduleActivityITRFilterPropertyUpdate,
} from 'src/app/store/schedule-activity-itr/actions';
import { ScheduleActivityITRFilter, ScheduleActivityITRScope } from 'src/app/store/schedule-activity-itr/model';
import {
    ScheduleActivityInferFilterDisciplines,
    ScheduleActivityNotInWeekPlanningDataRequest,
    ScheduleActivityPlanningDataRequest,
    ScheduleActivityPlanningFilterPropertyUpdate,
    ScheduleActivityPlanningResetITRFilter,
    ScheduleActivityWeekSummaryDataRequest,
} from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import {
    ScheduleActivityPlanningFilter,
    WeekSummaryFilter,
} from 'src/app/store/weekly-planning/schedule-activity-planning/model';

@Component({
    selector: 'app-lookahead-filter',
    templateUrl: './lookahead-filter.component.html',
    styleUrls: ['./lookahead-filter.component.scss'],
})
export class LookaheadFilterComponent extends BaseComponent implements OnInit {
    @Input() planType: WeeklyPlanType;
    @Input() printMode?: boolean = false;
    @Input() tabIndex: number = 1;
    @Output() searchRequest: EventEmitter<ScheduleActivityPlanningFilter> = new EventEmitter<
        ScheduleActivityPlanningFilter
    >();

    filterForm: UntypedFormGroup;
    filter$ = this.store.select((store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter);
    displayPeriodDays = 7;

    weekStart: moment.Moment;
    weekEnd: moment.Moment;
    weeklyPlanTypes = WeeklyPlanType;
    weekStart$ = this.store.select(
        (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekStart
    );
    weekEnd$ = this.store.select(
        (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekEnd
    );

    get isNextWorkingWeek(): boolean {
        return false
    }

    constructor(private store: Store<ApplicationState>, private filterService: FormService) {
        super();
        this.filterForm = this.filterService.createSimpleForm(new ScheduleActivityPlanningFilter());
    }

    ngOnInit(): void {
        this.weekStart$.pipe(takeWhile(() => this.isAlive)).subscribe((date) => (this.weekStart = date));
        this.weekEnd$.pipe(takeWhile(() => this.isAlive)).subscribe((date) => (this.weekEnd = date));
        this.displayPeriodDays = this.planType === WeeklyPlanType.weekly ? 7 : 28;

        this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
                filter: {
                    lookaheadDays: this.displayPeriodDays,
                    weeklyPlanType: this.planType,
                    selectedTabIndex: this.planType === WeeklyPlanType.monthly ? 1 : 0,
                } as ScheduleActivityPlanningFilter,
            })
        );

        this.moveToCurrentPeriod();

        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(
                { ...filter, weekStart: this.dateDayToUTC(moment(filter.weekStart)).toDate(), 
                    weekEnd: this.dateDayToUTC(moment(filter.weekEnd)).toDate() },
                { emitEvent: false }
            );
            this.tabIndex = filter.selectedTabIndex;
        });

        this.watchFormChanges();
    }

    setTabIndex($event) {
        this.tabIndex = $event.index;
        this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
                filter: { ...this.filterForm.value, selectedTabIndex: $event.index } as ScheduleActivityPlanningFilter,
            })
        );
    }

    private watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(
                    new ScheduleActivityPlanningFilterPropertyUpdate({
                        filter: { ...this.filterForm.value, [key]: value } as ScheduleActivityPlanningFilter,
                    })
                );

                // if (key === 'weekStart') {
                //     this.search();
                // }
                const shouldTriggerDisciplineFilterInference =
                    ['nbEngineers', 'nbDisciplineLeads', 'tcoAreaCoordinators'].some((k) => k === key) &&
                    value.length > 0;
                if (shouldTriggerDisciplineFilterInference) {
                    this.store.dispatch(new ScheduleActivityInferFilterDisciplines());
                }
            });
        }
    }

    search = () => {
        this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
                filter: {
                    ...this.filterForm.value,
                    page: 0,
                    weekSummaryFilter: new WeekSummaryFilter(),
                    weekStart: this.dateDayToUTC(moment(this.filterForm.value.weekStart)),
                    weekEnd:this.dateDayToUTC(moment(this.filterForm.value.weekEnd)),
                } as ScheduleActivityPlanningFilter,
            })
        );
        if (this.isNextWorkingWeek || this.planType === WeeklyPlanType.monthly) {
            this.SetHasNoItrsToGo(false);
            this.store.dispatch(new ScheduleActivityPlanningDataRequest());
            this.store.dispatch(new ScheduleActivityNotInWeekPlanningDataRequest());
            this.store.dispatch(
                new ScheduleActivityPlanningFilterPropertyUpdate({
                    filter: { weekSummaryFilter: new WeekSummaryFilter() } as ScheduleActivityPlanningFilter,
                })
            );
            this.store.dispatch(new ScheduleActivityWeekSummaryDataRequest());
        } else {
            this.SetHasNoItrsToGo(null);
            this.store.dispatch(new ScheduleActivityPlanningResetITRFilter());
            this.store.dispatch(new ScheduleActivityITRFilterPropertyReset());

            this.store.dispatch(
                new ScheduleActivityITRFilterPropertyUpdate({
                    scope: ScheduleActivityITRScope.InWeek,
                } as ScheduleActivityITRFilter)
            );
            this.store.dispatch(new ScheduleActivityITRDataRequest());
        }
    };

    dateDayToUTC = (date: moment.Moment) => {
        const utcWeekStart = moment.utc().startOf('day');
        utcWeekStart.year(date.year());
        utcWeekStart.month(date.month());
        utcWeekStart.date(date.date());

        return utcWeekStart;
    };

    moveToCurrentPeriod = () => {
        const localNow = moment();
        const utcWeekStart = moment.utc().startOf('day');

        utcWeekStart.year(localNow.year());
        utcWeekStart.month(localNow.month());
        utcWeekStart.date(localNow.date());

        const offsetFromSaturday = (utcWeekStart.isoWeekday() + 1) % 7;
        utcWeekStart.add(-offsetFromSaturday, 'day');

        this.filterForm.controls.weekStart.setValue(utcWeekStart);
        this.store.dispatch(
            new ScheduleActivityITRFilterPropertyUpdate({
                scope: ScheduleActivityITRScope.InWeek,
            } as ScheduleActivityITRFilter)
        );
    };

    private SetHasNoItrsToGo(hasNoITRsToGo: boolean) {
        this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
                filter: { ...this.filterForm.value, hasNoITRsToGo } as ScheduleActivityPlanningFilter,
            })
        );
    }

    get isWorkAreaFilterApplied() {
        return this.filterForm.controls.workAreas?.value.length > 0;
    }

    get isActivityFilterApplied() {
        return (
            this.filterForm.controls.goocs?.value.length > 0 ||
            this.filterForm.controls.rfos?.value.length > 0 ||
            this.filterForm.controls.areas?.value.length > 0 ||
            this.filterForm.controls.scManager?.value.length > 0 ||
            this.filterForm.controls.stagedStartUpPriorities?.value.length > 0
        );
    }
}
