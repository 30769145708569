<main class="main">
    <div class="title-container">
        <div class="title">Systemised Drawings Search</div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.systemisedDrawingNo.value.length" removable>
                                Systemised Drawings No:
                                {{ displayMultipleSelected(filterForm.controls.systemisedDrawingNo.value, 'id') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('systemisedDrawingNo')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.engineeringDrawingNo.value.length" removable>
                                Engineering Drawings No:
                                {{ displayMultipleSelected(filterForm.controls.engineeringDrawingNo.value, 'id') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('engineeringDrawingNo')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.subsystems.value.length" removable>
                                Subsystem: {{ displayMultipleSelected(filterForm.controls.subsystems.value, 'id') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('subsystems')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfos?.value.length" removable>
                                RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value, 'name') }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('rfos')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                Area Breakdown:
                                {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="systemisedDrawingNo"
                            [searchFunc]="getSystemisedDrawingNo"
                            [setInput]="setSystemisedDrawingNoInput"
                            [isAsync]="true"
                            placeholder="Systemised Drawings No"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="engineeringDrawingNo"
                            [searchFunc]="getEngineeringDrawingNo"
                            [setInput]="setEngineeringDrawingNoInput"
                            [isAsync]="true"
                            placeholder="Engineering Drawings No"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container620-mh55 margin-right20">
                        <app-multiple-items-selector
                            class="container620"
                            formControlName="subsystems"
                            [searchFunc]="getSubsystems"
                            [displayedColumns]="subsystemAutocompleteDisplayedColumns"
                            [setInput]="setSubsystemInput"
                            [isAsync]="true"
                            placeholder="Subsystem"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div class="mixedapp-container">
                    <div class="container620-mh55 margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container620-mh55 margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
    <div class="buttons-container flex">
        <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
            export to excel
        </button>
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filters
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
    </div>
    <div class="results-container">
        <app-mat-table-sticky-header [scrollKey]="'systemdrawings'">
            <table
                mat-table
                matSort
                [dataSource]="data"
                [matSortActive]="sortBy"
                matSortDisableClear
                [matSortDirection]="direction"
            >
                <ng-container matColumnDef="systemisedDrawingNo">
                    <th mat-sort-header disableClear mat-header-cell *matHeaderCellDef>Systemised Drawing No</th>
                    <td mat-cell *matCellDef="let element">
                        <a (click)="openSysDrawingPage(element.systemisedDrawingNo)">
                            {{ element.systemisedDrawingNo }}
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="engineeringDrawingNo">
                    <th mat-sort-header disableClear mat-header-cell *matHeaderCellDef>Engineering Drawing No</th>
                    <td mat-cell *matCellDef="let element">
                        <a (click)="openEngDrawingPage(element.engineeringDrawingNo)">
                            {{ element.engineeringDrawingNo }}
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="subsystems">
                    <th mat-sort-header disableClear mat-header-cell *matHeaderCellDef>Subsystems</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.subsystems }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </app-mat-table-sticky-header>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>
