import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { BaseComponent } from "src/app/components/base.component";
import { Config } from 'src/app/models/config';
import { ConfigurationService } from "src/app/services/api/webapi-services/configuration.service";
import { ToastService } from "src/app/services/shared/toast.service";
import { MatIconRegistry } from '@angular/material/icon';
import { finalize, take } from "rxjs/operators";

@Component({
  selector: 'ftp-configuration',
  templateUrl: './ftp-configuration.component.html',
  styleUrls: ['./ftp-configuration.component.scss']
})
export class FtpConfigurationComponent extends BaseComponent implements OnInit {
  displayedColumns = ['key', 'value', 'edit'];
  ftpConfigData: Config[];
  isLoading = false;
  saveButtonDisabled = true;

  constructor(
    private configurationService: ConfigurationService,
    private toastService: ToastService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) {
    super();

    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
    );
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.configurationService.getFTPConfigData()
      .subscribe(ftpConfigData => {
        this.ftpConfigData = ftpConfigData;
        this.isLoading = false;
      })

  }

  testFTPConnection() {
    this.isLoading = true;
    this.configurationService
      .testFTPConnection(this.ftpConfigData)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {
          this.saveButtonDisabled = false;
          this.toastService.Success('Successfully connected to FTP server.');
        },
        () => {
          this.toastService.Error(
            'Error has occurred while connecting to FTP server or listing FTP location. Validate your connection parameters and try again.'
          );
        }
      );
  }

    saveFTPConfiguration() {
        this.isLoading = true;
        this.configurationService
            .saveFTPConfiguration(this.ftpConfigData)
            .pipe(
                take(1),
                finalize(() => {
                    this.saveButtonDisabled = true;
                    this.isLoading = false;
                })
            )
            .subscribe(
                () => this.toastService.Success('FTP configuration successfully saved.'),
                () => {
                    this.toastService.Error(
                        'Error has occurred while saving FTP configuration. Please contact Program Administrator.'
                    );
                }
            );
    }
    
    enterEditMode(config: Config) {
        config.isInEditMode = !config.isInEditMode;
        config.prevValue = config.value;
    }

    exitEditMode(config: Config) {
        config.isInEditMode = !config.isInEditMode;
    }

    cancelEdit(config: Config) {
        config.isInEditMode = !config.isInEditMode;
        config.value = config.prevValue;
    }
}
