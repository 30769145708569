import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { RUNDOWN_TYPE_KEYS, RundownChartData, RundownType } from 'src/app/store/reports/rundown/model';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { combineLatest } from 'rxjs';
import * as _ from 'lodash';
import { ColorHelper } from '@swimlane/ngx-charts';
import { ScaleType } from 'src/app/components/charts/utils/Types';

@Component({
    selector: 'app-rundown-chart',
    templateUrl: './reports-rundown-chart.component.html',
    styleUrls: ['./reports-rundown-chart.component.scss'],
})
export class ReportsRundownChartComponent extends BaseComponent implements OnInit {
    @Input() homePage: boolean = false;
    xAxis: boolean = true;
    yAxis: boolean = true;
    showYAxisLabel: boolean = false;
    showXAxisLabel: boolean = false;
    showBarLegend: Boolean = false;

    pliColorScheme = {
        domain: ['#7f7f7f', '#ff0000', '#ed7c31', '#ffbf00', '#5b9ad5', '#808080'],
    };
    colorScheme = {
        domain: ['#01b529', '#543ebf', '#757474', '#eb0533', '#d1c928', '#808080'],
    };
    displayColorScheme = {
        domain: [],
    };
    barColorScheme = {
        domain: [],
    };
    customScheme = [
        { name: 'PLI Raised', value: '#a6a6a6' },
        { name: 'PLI Closed', value: '#a9d18e' },
    ];
    pli1BarColorScheme = {
        domain: ['#a6a6a6', '#a9d18e'],
    };

    lineChartData: RundownChartData[];
    barChartData: RundownChartData[];
    barChartLabels: string[] = [];
    barChartColors: ColorHelper;
    displayLineChartData: RundownChartData[];
    displayBarChartData: RundownChartData[];
    headerMarginLeft = '92px';
    headerWidth = '1103px';

    lineChartData$ = this.store.select<RundownChartData[]>(
        (store) => store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].lineChart
    );
    yScaleMin$ = this.store.select<number>(
        (store) => store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].yScaleMin
    );
    yScaleMax$ = this.store.select<number>(
        (store) => store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].yScaleMax
    );
    barChartData$ = this.store.select<RundownChartData[]>(
        (store) => store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].barChart
    );
    pastWeeks$ = this.store.select((state) =>
        state.rundownState[RUNDOWN_TYPE_KEYS.get(state.rundownState.rundownType).toLowerCase()].weeks.filter(
            (w) => !w.weekType
        )
    );
    range$ = this.store.select((state) => state.rundownState.filter.range);

    showGroupedVerticalChart$ = this.store.select<boolean>(
        (store) =>
            store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()]
                .showGroupedVerticalChart
    );
    rundownType$ = this.store.select((store) => store.rundownState.rundownType);
    displayTotal$ = this.store.select((store) => store.rundownState.filter.displayTotal);
    planViewEnabled$ = this.store.select((store) => store.rundownState.planViewEnabled);
    monthViewEnabled$ = this.store.select((state) => state.rundownState.monthViewEnabled);
    quarterViewEnabled$ = this.store.select((state) => state.rundownState.quarterViewEnabled);
    pliCategories$ = this.store.select((state) => state.rundownState.filter.categories);

    @ViewChild('lineChart', { static: true }) lineChart: ElementRef<HTMLElement>;

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {
        this.lineChartData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            const copy = _.cloneDeep(data)
            this.lineChartData = copy;
            this.displayLineChartData = copy;
            this.refreshHeader();
        });

        this.barChartData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            const copy = _.cloneDeep(data)
            this.barChartData = copy;
            this.displayBarChartData = copy;
        });

        combineLatest(
            this.lineChartData$,
            this.barChartData$,
            this.displayTotal$,
            this.planViewEnabled$,
            this.rundownType$,
            this.pliCategories$
        )
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(([lineChart, barChart, displayTotal, planViewEnabled, rundownType, pliCategories]) => {
                this.displayColorScheme.domain = Object.assign(
                    [],
                    rundownType === RundownType.PLI2 || rundownType === RundownType.PLI1
                        ? this.pliColorScheme.domain
                        : this.colorScheme.domain
                );

                this.showBarLegend = rundownType === RundownType.PLI1;

                if (displayTotal === false) {
                    this.displayLineChartData = lineChart.filter((_, idx) => idx > 0);

                    this.displayBarChartData = barChart.map((x) => {
                        let tmp = x.series.filter((y) => y.name !== 'Total');
                        return { name: x.name, series: tmp };
                    });

                    this.displayColorScheme.domain = Object.assign(
                        [],
                        rundownType === RundownType.PLI2 || rundownType === RundownType.PLI1
                            ? this.pliColorScheme.domain.filter((_, idx) => idx > 0)
                            : this.colorScheme.domain.filter((_, idx) => idx > 0)
                    );
                } else if (planViewEnabled === false) {
                    this.displayLineChartData = lineChart.filter((_, idx) => idx != 2);

                    this.displayBarChartData = barChart.map((x) => {
                        let tmp = x.series.filter((y) => y.name !== 'Plan');
                        return { name: x.name, series: tmp };
                    });
                    if (
                        rundownType !== RundownType.RFSU &&
                        rundownType !== RundownType.PLI2 &&
                        rundownType !== RundownType.PLI1
                    ) {
                        var last = '';
                        if (this.displayLineChartData[0].series.length > this.displayLineChartData[1].series.length) {
                            last = this.displayLineChartData[0].series[this.displayLineChartData[0].series.length - 1]
                                .name;
                        } else {
                            last = this.displayLineChartData[1].series[this.displayLineChartData[1].series.length - 1]
                                .name;
                        }
                        this.displayBarChartData = this.displayBarChartData.slice(
                            0,
                            this.displayBarChartData.findIndex((x) => x.name === last) + 1
                        );
                    }
                } else {
                    this.displayLineChartData = this.lineChartData;
                    this.displayBarChartData = this.barChartData;
                }

                this.barColorScheme.domain = Object.assign(
                    [],
                    rundownType === RundownType.PLI1 ? this.pli1BarColorScheme.domain : this.displayColorScheme.domain
                );

                this.barChartLabels = this.displayBarChartData[0]?.series.map((x) => x.name) ?? [];
                this.barChartColors = new ColorHelper(
                    'natural',
                    ScaleType.Ordinal,
                    this.barChartLabels,
                    this.customScheme
                );

                if (
                    (rundownType === RundownType.PLI2 || rundownType === RundownType.PLI1) &&
                    pliCategories.length > 0
                ) {
                    this.displayLineChartData.forEach((d) => {
                        if (d.name !== '_' && d.name !== 'Total' && pliCategories.indexOf(d.name) === -1) {
                            d.series = d.series.slice(0, 1);
                        }
                    });
                }
            });
    }

    formatToIntegerString(value: any): string {
        return Number.isInteger(value) ? value : '';
    }

    private refreshHeader() {
        setTimeout(() => {
            const headerWidth = this.lineChart?.nativeElement
                .querySelector('g[ngx-charts-y-axis-ticks] .gridline-path')
                ?.getBoundingClientRect().width;
            const ticksWidth = this.lineChart?.nativeElement
                .querySelector('g[ngx-charts-y-axis-ticks]')
                .firstElementChild.getBoundingClientRect().width;
            const spaceLeft = (1200 - headerWidth - ticksWidth) / 2;
            this.headerWidth = `${headerWidth}px`;
            this.headerMarginLeft = `${28 + ticksWidth + spaceLeft}px`;
        }, 500);
    }
}
