import {
    ChangeValidationDashboardState,
    ChangeValidationDashboardFilter,
    WeeklyValidationProgressState,
    MonthlyValidationProgressState,
    CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS,
    WeeklyValidationProgressItem,
    MonthlyValidationProgressItem,
    MonthlyChangeRaisedState,
    WeeklyChangeRaisedState,
    MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT,
    CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS,
    CumulativeValidationProgressState,
    WeeklyCumulativeValidationProgressState,
    CUMULATIVE_VALIDATION_PROGRESS_MONTH_RANGE,
    ChangeValidationDashboardProgressDataDTO,
    ValidatorPerformanceScoreboardState,
    VALIDATOR_PERFORMANCE_SCOREBOARD_NUM_OF_PAST_WEEKS,
} from './model';
import { ChangeValidationDashboardActions, ChangeValidationDashboardActionTypes } from './actions';
import { initWeekRange, initMonthRange } from 'src/app/extensions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT } from '../planning/model';

const initialState: ChangeValidationDashboardState = {
    weeklyValidationProgress: new WeeklyValidationProgressState(),
    monthlyValidationProgress: new MonthlyValidationProgressState(),
    isValidationProgressLoading: false,
    validationProgressData: new ChangeValidationDashboardProgressDataDTO(),
    monthlyChangeRaised: new MonthlyChangeRaisedState(),
    weeklyChangeRaised: new WeeklyChangeRaisedState(),
    cumulativeValidationProgress: new CumulativeValidationProgressState(),
    weeklycumulativeValidationProgress: new WeeklyCumulativeValidationProgressState(),
    isSystemisationProgressLoading: false,
    systemisationProgressData: new ChangeValidationDashboardProgressDataDTO(),
    validatorPerformanceScoreboard: new ValidatorPerformanceScoreboardState(),
    filter: new ChangeValidationDashboardFilter(),
};

export function reducer(
    state = initialState,
    action: ChangeValidationDashboardActions
): ChangeValidationDashboardState {
    switch (action.type) {
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressRequest: {
            var startDate = _.head(state.weeklyValidationProgress.weeks);
            var endDate = _.last(state.weeklyValidationProgress.weeks);
            var startDateFormatted = moment(startDate.date).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT);
            var endDateFormatted = moment(endDate.date).add(7, 'days').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT);

            return {
                ...state,
                weeklyValidationProgress: {
                    ...state.weeklyValidationProgress,
                    rangeDateFilter: {
                        ...state.weeklyValidationProgress.rangeDateFilter,
                        startDate: startDateFormatted,
                        endDate: endDateFormatted,
                    },
                    isLoading: true,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressRequestSuccess: {
            const data = state.weeklyValidationProgress.weeks.map((week) => {
                const greenCount = _.filter(action.payload, (v) => withinWorkWeek(v.completedDate, moment(week.date))).length;

                const yellowCount = _.filter(
                    action.payload,
                    (v) => withinWorkWeek(v.inProgressDate, moment(week.date)) && v.completedDate === null
                ).length;

                const result: WeeklyValidationProgressItem = {
                    greenCount,
                    yellowCount,
                };
                return result;
            });

            return {
                ...state,
                weeklyValidationProgress: {
                    ...state.weeklyValidationProgress,
                    data,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressRequestError: {
            return {
                ...state,
                weeklyValidationProgress: {
                    ...state.weeklyValidationProgress,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressRequest: {
            //console.log('state: ', state.monthlyValidationProgress)
            var startDate0 = state.monthlyValidationProgress.rangeDateFilter.startDate 
            var endDate0 = state.monthlyValidationProgress.rangeDateFilter.endDate ?? moment().endOf('year').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT)
            startDate0 = startDate0 == "" ? moment().startOf('year').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT) : startDate0
            endDate0 = endDate0 == "" ? moment().endOf('year').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT) : endDate0
            return {
                ...state,
                monthlyValidationProgress: {
                    ...state.monthlyValidationProgress,
                    rangeDateFilter: {
                        ...state.monthlyValidationProgress.rangeDateFilter,
                        startDate: startDate0,
                        endDate: endDate0,
                    },
                    isLoading: true,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressRequestSuccess: {
            //console.log('months: ', state.monthlyValidationProgress.months)
            const data = state.monthlyValidationProgress.months.map((month) => {
                const greenCount = _.filter(action.payload, (v) => withinMonth(v.completedDate, moment(month.date))).length;

                const yellowCount = _.filter(
                    action.payload,
                    (v) => withinMonth(v.inProgressDate, moment(month.date)) && v.completedDate === null
                ).length;

                const result: MonthlyValidationProgressItem = {
                    greenCount,
                    yellowCount,
                };
                return result;
            });

            return {
                ...state,
                monthlyValidationProgress: {
                    ...state.monthlyValidationProgress,
                    data,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressRequestError: {
            return {
                ...state,
                monthlyValidationProgress: {
                    ...state.monthlyValidationProgress,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidationProgressRequest: {
            return {
                ...state,
                isValidationProgressLoading: true,
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidationProgressRequestSuccess: {
            return {
                ...state,
                validationProgressData: action.payload,
                isValidationProgressLoading: false,
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidationProgressRequestError: {
            return {
                ...state,
                isValidationProgressLoading: false,
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressRequest: {
            return {
                ...state,
                cumulativeValidationProgress: {
                    ...state.cumulativeValidationProgress,
                    isLoading: true,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressRequestSuccess: {
            return {
                ...state,
                cumulativeValidationProgress: {
                    ...state.cumulativeValidationProgress,
                    data: action.payload,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyCumulativeValidationProgressRequest: {
            return {
                ...state,
                weeklycumulativeValidationProgress: {
                    ...state.weeklycumulativeValidationProgress,
                    isLoading: true,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyCumulativeValidationProgressRequestSuccess: {
            return {
                ...state,
                weeklycumulativeValidationProgress: {
                    ...state.weeklycumulativeValidationProgress,
                    data: action.payload,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressRequestError: {
            return {
                ...state,
                cumulativeValidationProgress: {
                    ...state.cumulativeValidationProgress,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedRequest: {
            return {
                ...state,
                monthlyChangeRaised: {
                    ...state.monthlyChangeRaised,
                    isLoading: true,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedRequestSuccess: {
            return {
                ...state,
                monthlyChangeRaised: {
                    ...state.monthlyChangeRaised,
                    data: action.payload,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedRequestError: {
            return {
                ...state,
                monthlyChangeRaised: {
                    ...state.monthlyChangeRaised,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyChangeRaisedRequest: {
            return {
                ...state,
                weeklyChangeRaised: {
                    ...state.weeklyChangeRaised,
                    isLoading: true,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyChangeRaisedRequestSuccess: {
            return {
                ...state,
                weeklyChangeRaised: {
                    ...state.weeklyChangeRaised,
                    data: action.payload,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardSystemisationProgressRequest: {
            return {
                ...state,
                isSystemisationProgressLoading: true,
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardSystemisationProgressRequestSuccess: {
            return {
                ...state,
                systemisationProgressData: action.payload,
                isSystemisationProgressLoading: false,
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardSystemisationProgressRequestError: {
            return {
                ...state,
                isSystemisationProgressLoading: false,
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardRequest: {
            return {
                ...state,
                validatorPerformanceScoreboard: {
                    ...state.validatorPerformanceScoreboard,
                    isLoading: true,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardRequestSuccess: {
            return {
                ...state,
                validatorPerformanceScoreboard: {
                    ...state.validatorPerformanceScoreboard,
                    data: action.payload,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardRequestError: {
            return {
                ...state,
                validatorPerformanceScoreboard: {
                    ...state.validatorPerformanceScoreboard,
                    isLoading: false,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardFilterReset: {
            return {
                ...state,
                filter: new ChangeValidationDashboardFilter(),
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardFilterUpdate: {
            return {
                ...state,
                filter: {
                    ...action.payload
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardFilterPropertyUpdate: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                        ? [...action.payload.value]
                        : action.payload.value,
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressSetWeekRange: {
            return {
                ...state,
                weeklyValidationProgress: {
                    ...state.weeklyValidationProgress,
                    weeks: initWeekRange(
                        action.payload,
                        CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS
                    ),
                    data: [],
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressSetMonthRange: {
            return {
                ...state,
                monthlyValidationProgress: {
                    ...state.monthlyValidationProgress,
                    months: initMonthRange(
                        moment(action.payload)
                            .add(-CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS + 1, 'months'),
                        CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS
                    ),
                    rangeDateFilter: {
                        startDate: moment(action.payload)
                            .add(-CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS + 1, 'months')
                            .format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT),
                        endDate: moment(action.payload).add(1, 'months').format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT),
                    },
                    data: [],
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange: {
            return {
                ...state,
                monthlyChangeRaised: {
                    ...state.monthlyChangeRaised,
                    rangeDateFilter: {
                        startDate: moment(action.payload)
                            .add(-CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS + 1, 'months')
                            .format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT),
                        endDate: moment(action.payload).format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT),
                    },
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyChangeRaisedSetWeekRange: {
            return {
                ...state,
                weeklyChangeRaised: {
                    ...state.weeklyChangeRaised,
                    rangeDateFilter: {
                        startDate: moment(action.payload).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                        endDate: moment(action.payload).add(12, 'weeks').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                    },
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardSetWeekRange: {
            return {
                ...state,
                validatorPerformanceScoreboard: {
                    ...state.validatorPerformanceScoreboard,
                    rangeDateFilter: {
                        startDate: moment(action.payload).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                        endDate: moment(action.payload)
                            .add(VALIDATOR_PERFORMANCE_SCOREBOARD_NUM_OF_PAST_WEEKS, 'weeks')
                            .format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                    },
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressSetMonthRange: {
            return {
                ...state,
                cumulativeValidationProgress: {
                    ...state.cumulativeValidationProgress,
                    rangeDateFilter: {
                        startDate: moment(action.payload)
                            .add(-CUMULATIVE_VALIDATION_PROGRESS_MONTH_RANGE + 1, 'months')
                            .format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                        endDate: moment(action.payload).add(1, 'months').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                    },
                },
            };
        }
        case ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyCumulativeValidationProgressSetWeekRange: {
            return {
                ...state,
                weeklycumulativeValidationProgress: {
                    ...state.weeklycumulativeValidationProgress,
                    rangeDateFilter: {
                        startDate: moment(action.payload).add(-1, 'days').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                        endDate: moment(action.payload).add(12, 'weeks').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                    },
                },
            };
        }
        default:
            return state;
    }
}

const withinWorkWeek = (dateToCheck: moment.Moment, startDate: moment.Moment) => {
    return (
        moment(dateToCheck).isSameOrAfter(moment(startDate), 'days') &&
        moment(dateToCheck).isSameOrBefore(moment(startDate).add(6, 'days'), 'days')
    );
};

const withinMonth = (dateToCheck: moment.Moment, startDate: moment.Moment) => {
    return (
        moment(dateToCheck).isSameOrAfter(moment(startDate), 'days') &&
        moment(dateToCheck).isBefore(moment(startDate).add(1, 'months'), 'days')
    );
};
