import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ChangeDocumentDeltaFilter } from 'src/app/models/change-document-delta-filter';
import { ChangeDocumentDeltaDataPagination } from 'src/app/models/change-document-delta-data-pagination';
import {
    ChangeImpactedTagsFilter,
    ChangeImpactedTagsDataPagination,
} from 'src/app/store/change-management-upload-dcn/model';
import { ChangeDocumentDeleteDataPagination, ChangeDocumentImportDTO } from 'src/app/models/change-document-dto';
import { ValidationDocumentHeader } from 'src/app/store/validation-form/model';
import { ChangeDocumentFilter, SubystemConflictsDataPagination } from 'src/app/store/subsystem-conflicts/model';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class AdminPanelService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly adminPanelEndpoint: string = '/admin';
    downloadFileSubject$ = new Subject<boolean>();

    constructor(private http: HttpClient) {}

    getLogs(types: string[]) {
        let params = new HttpParams();
        types.forEach((type) => {
            params = params.append('types', type);
        });
        return this.http.get<UpdateDataLog[]>(`${this.rootUrl}${this.adminPanelEndpoint}/logs`, { params });
    }

    getChangeDocumentDeleteRecords(
        page: number,
        take: number,
        type: string
    ): Observable<ChangeDocumentDeleteDataPagination> {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('type', type);

        return this.http.get<ChangeDocumentDeleteDataPagination>(
            `${this.rootUrl}${this.adminPanelEndpoint}/deleterecords`,
            { params }
        );
    }

    getDeltaData(filter: ChangeDocumentDeltaFilter) {
        return this.http.post<ChangeDocumentDeltaDataPagination>(
            `${this.rootUrl}${this.adminPanelEndpoint}/deltaData`,
            filter
        );
    }

    getChangeDocumentData(filter: ChangeDocumentFilter) {
        return this.http.post<SubystemConflictsDataPagination>(
            `${this.rootUrl}${this.adminPanelEndpoint}/changeDocument`,
            filter
        );
    }

    getDCNsWithImpactedTags(filter: ChangeImpactedTagsFilter) {
        return this.http.post<ChangeImpactedTagsDataPagination>(
            `${this.rootUrl}${this.adminPanelEndpoint}/dcnimpactedtags`,
            filter
        );
    }

    uploadFiles(formData: FormData) {
        return this.http.post(`${this.rootUrl}${this.adminPanelEndpoint}/importdata`, formData);
    }

    uploadImpactedTagsFile(formData: FormData, id: number) {
        return this.http.post<string[]>(
            `${this.rootUrl}${this.adminPanelEndpoint}/importdata/impactedtags/${id}`,
            formData
        );
    }

    downloadFile(filePath: string) {
        this.http
            .get(`${this.rootUrl}${this.adminPanelEndpoint}/downloaddata?filePath=${filePath}`, {
                responseType: 'arraybuffer' as 'json',
            })
            .subscribe(
                (fileContent: BlobPart) => {
                    const blob = new Blob([fileContent], {
                        type: 'application/octet-stream',
                    });

                    const fileName = filePath.replace(/^.*[\\\/]/, '');

                    saveAs(blob, fileName);

                    this.downloadFileSubject$.next(true);
                },
                () => {
                    this.downloadFileSubject$.next(false);
                }
            );
    }

    downloadOutputData(filePath: string) {
        return this.http.get<BlobPart>(`${this.rootUrl}${this.adminPanelEndpoint}/downloaddata?filePath=${filePath}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    downloadImportData(filePath: string) {
        return this.http.get<BlobPart>(`${this.rootUrl}${this.adminPanelEndpoint}/downloadimportdata?filePath=${filePath}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    processDataSource(type: string) {
        return this.http.post(`${this.rootUrl}${this.adminPanelEndpoint}/updatedata/${type}`, {});
    }

    acceptChange(item: ChangeDocumentImportDTO) {
        return this.http.patch(`${this.rootUrl}${this.adminPanelEndpoint}/changeDocument/isAccepted/${item.id}`, {});
    }

    patchChangeDocumentDeleteRecord(id: number, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.adminPanelEndpoint}/delete/${id}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
        ]);
    }

    patchChangeDocumentDeleteAllRecords(deleteState: boolean, type: string) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        params = params.append('type', `${type}`);
        return this.http.post(`${this.rootUrl}${this.adminPanelEndpoint}/deleterecords`, null, { params });
    }

    acceptAllDeltaRecords(changeDocumentType: string, acceptAll: boolean) {
        return this.http.patch(
            `${this.rootUrl}${this.adminPanelEndpoint}/changeDocument/acceptAll?acceptAll=${acceptAll}&changeDocumentType=${changeDocumentType}`,
            null
        );
    }

    updateSubsystems(id: number, subsystems: { id: string }[]) {
        return this.http.patch<ValidationDocumentHeader>(
            `${this.rootUrl}${this.adminPanelEndpoint}/changeDocument/subsystems/${id}`,
            subsystems
        );
    }

    getDataInfo(types: string[]) {
        let params = new HttpParams();
        types.forEach((type) => {
            params = params.append('types', type);
        });
        return this.http.get(`${this.rootUrl}${this.adminPanelEndpoint}/getdatainfo`, { params });
    }

    downloadRegisterTemplate(name: string) {
        return this.http
            .get(`${this.rootUrl}${this.adminPanelEndpoint}/downloadtemplate/${name}`, {
                responseType: 'arraybuffer' as 'json',
            })
            .subscribe(
                (fileContent: BlobPart) => {
                    const blob = new Blob([fileContent], {
                        type: 'application/octet-stream',
                    });

                    saveAs(blob, name);

                    this.downloadFileSubject$.next(true);
                },
                () => {
                    this.downloadFileSubject$.next(false);
                }
            );
    }

    downloadSourceFile(importType: string) {
        return this.http.get(`${this.rootUrl}${this.adminPanelEndpoint}/downloadlastsourcefile/${importType}`, {
            observe: 'response',
            responseType: 'arraybuffer' as 'json',
        });
    }

    updateImpactedTags(id: number, impactedTags: string[]) {
        return this.http.post(
            `${this.rootUrl}${this.adminPanelEndpoint}/dcnimpactedtags/impactedtags/${id}`,
            impactedTags
        );
    }
}
