import { Injectable } from '@angular/core';
import { BaseComponent } from '../../../components/base.component';
import { appConfig } from '../../../app.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ScheduleActivityPlanningFilter } from '../../../store/weekly-planning/schedule-activity-planning/model';
import { ScheduleActivityPlanningConstraint } from 'src/app/components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import { BaseResultDTO, Contractor } from 'src/app/store/common.model';
import { ScheduleActivityITRScope } from 'src/app/store/schedule-activity-itr/model';
import { WeekSummaryDto } from 'src/app/store/weekly-planning/model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScheduleActivityLookupService extends BaseComponent {
    constructor(private http: HttpClient) {
        super();
    }
    private readonly rootUrl = appConfig.apiEndpoint;
    private readonly endpoint = '/scheduleActivityLookup/';

    private static getParams(search: string, take: number, page: number) {
        return new HttpParams()
            .append('page', page.toString())
            .append('take', take.toString())
            .append('search', search);
    }

    searchWorkAreas(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}workareas`, filter, { params });
    }

    searchDisciplines() {
        return this.http.get(`${this.rootUrl + this.endpoint}disciplines`);
    }

    searchNbDisciplineLeads(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}nbdisciplineleads`, filter, { params });
    }

    searchNbEngineers(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}nbengineers`, filter, { params });
    }

    searchTCOAreaCoordinators(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}tcoareacoordinators`, filter, { params });
    }
    searchGoocs(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}goocs`, filter, { params });
    }

    searchRfos(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}rfos`, filter, { params });
    }

    searchConstraintTypes(take = 5, page = 0, search: string = '') {
        const params = new HttpParams()
            .append('search', search)
            .append('take', take.toString())
            .append('page', page.toString());
        return this.http.get<BaseResultDTO<ScheduleActivityPlanningConstraint>>(
            `${this.rootUrl}${this.endpoint}constraintTypes`,
            {
                params,
            }
        );
    }

    getConstraintTypes() {
        return this.http.get<{ name: string; id: number }[]>(`${this.rootUrl}${this.endpoint}constraintTypesAll`);
    }

    searchSubsystems(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}subsystems`, filter, { params });
    }

    searchItrContractors(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter): Observable<Contractor[]> {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post<Contractor[]>(`${this.rootUrl + this.endpoint}itrcontractors`, filter, { params });
    }

    searchPriorities(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}priorities`, filter, { params });
    }

    getITRsToGoWeeklyPlanDetails(filter: ScheduleActivityPlanningFilter) {
        return this.http.post(`${this.rootUrl + this.endpoint}itrstogodetails`, filter);
    }

    searchActivityNames(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}activitynames`, filter, { params });
    }

    searchActivityIds(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}activityids`, filter, { params });
    }

    searchComments(search: string, take: number, page: number, filter: ScheduleActivityPlanningFilter) {
        const params = ScheduleActivityLookupService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl + this.endpoint}activitycomments`, filter, { params });
    }

    searchSubsystemsForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/itr`, filter, { params });
    };

    searchSubsystemNamesForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystemnames/itr`, filter, { params });
    };

    searchConstraintTypesForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}constrainttypes/itr`, filter, { params });
    };

    searchActivityNamesForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}activitynames/itr`, filter, { params });
    };

    searchTagNumbersForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: any
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}tagnumbers/itr`, filter, { params });
    };

    searchTagTypesForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}tagtypes/itr`, filter, { params });
    };

    searchConstraintFlagForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}constraintflags/itr`, filter, { params });
    };

    searchDisciplinesForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}disciplines/itr`, filter, { params });
    };

    searchItrInCurrentWeek = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}itrincurrentweek/itr`, filter, { params });
    };

    searchITRNumbersForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}itrnumbers/itr`, filter, { params });
    };

    searchITRDescriptionsForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}itrdescriptions/itr`, filter, { params });
    };

    searchContractorsForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}contractors/itr`, filter, { params });
    };

    searchDocTypesForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}doctypes/itr`, filter, { params });
    };

    searchStatusesForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}statuses/itr`, filter, { params });
    };

    searchBarcodesForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}barcodes/itr`, filter, { params });
    };

    searchCommentsForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/itr`, filter, { params });
    };

    searchActivityIdsForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}activityids/itr`, filter, { params });
    };

    searchWorkAreasForITRFilter = (
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityPlanningFilter,
        type: ScheduleActivityITRScope
    ) => {
        filter.skipActivityDatesValidation = type === ScheduleActivityITRScope.InWeek;
        let params = ScheduleActivityLookupService.getParams(search, take, page);
        params = params.append('type', type.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}workareas/itr`, filter, { params });
    };

    getWeekSummary(filter: ScheduleActivityPlanningFilter): Observable<WeekSummaryDto> {
        return this.http.post<WeekSummaryDto>(`${this.rootUrl + this.endpoint}weeksummary`, filter);
    }
}
