import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';

@Injectable({
    providedIn: 'root',
})
export class IsPlanningGroupGuard implements CanActivate {
    constructor(private groupService: RoleService, private router: Router) {}

    canActivate() {
        return this.groupService.isInRole(Constants.applicableGroups.Admin) ||
            this.groupService.isInRole(Constants.applicableGroups.Planners) ||
            this.groupService.isInRole(Constants.applicableGroups.ExecutionPlanning)
        ? true
            : this.router.navigate(['/notauthorized']);
    }
}
