import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { CommentDetailDTO } from 'src/app/store/detailed-status/model';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-comments-history',
    templateUrl: './comments-history.component.html',
    styleUrls: ['./comments-history.component.scss'],
})
export class CommentsHistoryComponent extends BaseComponent implements OnInit {
    displayedColumns: string[] = ['date', 'user', 'description', 'delete'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    resultsLength = 0;
    pageSize = 5;
    sortBy = 'date';
    direction = 'desc';
    dataSource = new MatTableDataSource();
    isAdmin = false;
    action: (id: number) => void;

    constructor(
        public dialogRef: MatDialogRef<CommentsHistoryComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private roleService: RoleService
    ) {
        super();

        this.dataSource = new MatTableDataSource(data.commentHist.reverse());
        this.resultsLength = data.commentHist.length;
        this.action = data.action;
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        if (!this.isAdmin) {
            this.displayedColumns = ['date', 'user', 'description'];
        }
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'user':
                    return item[property].name;
                default:
                    return item[property];
            }
        };
    }

    removeComment(comment: CommentDetailDTO) {
        this.action(comment.id);
        this.dialogRef.close();
    }
}
