
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    ITRConstraintsActionTypes,
    ITRConstraintsUploadLogSuccess,
    ITRConstraintsUploadLogError,
    ITRConstraintsValidateButtonStateSuccess,
    ITRConstraintsValidateButtonStateError,
    ITRConstraintsDownloadOutputDataSuccess,
    ITRConstraintsDownloadOutputDataError,
    ITRConstraintsDownloadOutputDataRequest,
    ITRConstraintsTemplateFileSuccess,
    ITRConstraintsTemplateFileRequest,
    ITRConstraintsTemplateFileError,
    ITRConstraintsClearInProgressSpinner,
    ITRConstraintsValidateDeleteSuccess,
    ITRConstraintsValidateDeleteError,
    ITRConstraintsPatchAllDeleteRequest,
    ITRConstraintsPatchAllDeleteSuccess,
    ITRConstraintsPatchAllDeleteError,
    ITRConstraintsPatchDeleteRequest,
    ITRConstraintsPatchDeleteSuccess,
    ITRConstraintsPatchDeleteError,
    ITRConstraintsDeleteSuccess,
    ITRConstraintsDeleteError,
    ITRConstraintsDeleteRequest,
    ITRConstraintsSetStatusAndStartDate,
} from './actions';

@Injectable()
export class ITRConstraintsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(ITRConstraintsActionTypes.ITRConstraintsUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['ITRConstraints']).pipe(
                map((uploadLogData) => new ITRConstraintsUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new ITRConstraintsUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<ITRConstraintsUploadLogSuccess>(ITRConstraintsActionTypes.ITRConstraintsUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.itrConstraintsState.uploadLogStatus),
            this.store.select((store) => store.itrConstraintsState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new ITRConstraintsSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    invokeDeleteRequest$ = this.actions$.pipe(
        ofType(ITRConstraintsActionTypes.ITRConstraintsSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.itrConstraintsState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        map(() => new ITRConstraintsDeleteRequest())
    );

    @Effect()
    getDelete$ = this.actions$.pipe(
        ofType(ITRConstraintsActionTypes.ITRConstraintsDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.itrConstraintsState.deleteFilter)),
        mergeMap(([, deleteFilter]) =>
            this.manualUploadsService.getITRConstraintsDeleteRecords(deleteFilter).pipe(
                map((deleteData: any) => new ITRConstraintsDeleteSuccess(deleteData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delete data records. Please contact Program Administrator'
                    );
                    return of(new ITRConstraintsDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchDeleteData$ = this.actions$.pipe(
        ofType<ITRConstraintsPatchDeleteRequest>(ITRConstraintsActionTypes.ITRConstraintsPatchDeleteRecordRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchITRConstraintsDeleteRecord(
                action.payload.constraintId,action.payload.constraintStatus, action.payload.deleteState
                ).pipe(
                map(() => new ITRConstraintsPatchDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ITRConstraintsPatchDeleteError(error));
                })
            )
        )
    );

    @Effect()
    patchAllDelete$ = this.actions$.pipe(
        ofType<ITRConstraintsPatchAllDeleteRequest>(ITRConstraintsActionTypes.ITRConstraintsPatchAllDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.itrConstraintsState.deleteFilter)),
        mergeMap(([action, deleteFilter]) =>
            this.manualUploadsService.patchAllITRConstraintsDeleteRecords(deleteFilter, action.payload.deleteState).pipe(
                map(() => new ITRConstraintsPatchAllDeleteSuccess({ deleteState: action.payload.deleteState })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deleteState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new ITRConstraintsPatchAllDeleteError(error));
                })
            )
        )
    );

    @Effect()
    validateDelete$ = this.actions$.pipe(
        ofType(ITRConstraintsActionTypes.ITRConstraintsValidateDataRequest),
        mergeMap(() =>
            this.manualUploadsService.validateITRConstraintsData().pipe(
                map(() => new ITRConstraintsValidateDeleteSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new ITRConstraintsValidateDeleteError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(ITRConstraintsActionTypes.ITRConstraintsDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.itrConstraintsState.uploadLogData)),
        filter(([, uploadLogs]) => {
            let logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new ITRConstraintsClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<ITRConstraintsTemplateFileRequest>(ITRConstraintsActionTypes.ITRConstraintsTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new ITRConstraintsTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading ITR Constraints structure file. Please contact Program Administrator'
                    );
                    return of(new ITRConstraintsTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<ITRConstraintsTemplateFileSuccess>(ITRConstraintsActionTypes.ITRConstraintsTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'ITR Constraints Upload.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<ITRConstraintsDownloadOutputDataRequest>(ITRConstraintsActionTypes.ITRConstraintsDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new ITRConstraintsDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new ITRConstraintsDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<ITRConstraintsDownloadOutputDataSuccess>(ITRConstraintsActionTypes.ITRConstraintsDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(ITRConstraintsActionTypes.ITRConstraintsDeleteRecordsSuccess,
            ITRConstraintsActionTypes.ITRConstraintsPatchAllDeleteRecordsSuccess,
            ITRConstraintsActionTypes.ITRConstraintsPatchDeleteRecordSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('ITRConstraints').pipe(
                map((response: any) => new ITRConstraintsValidateButtonStateSuccess(response)),
                catchError((error) => of(new ITRConstraintsValidateButtonStateError(error)))
            )
        )
    );
}

