import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from 'src/app/components/base.component';
import { take, takeWhile } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { PlanningCommitmentReliabilityState } from 'src/app/store/reports/planning/model';
import { ScheduleActivityPlanningFilter } from 'src/app/store/weekly-planning/schedule-activity-planning/model';
import * as moment from 'moment';
import { ScheduleActivityITRScope } from 'src/app/store/schedule-activity-itr/model';

@Component({
    selector: 'app-reports-planning-commitment-reliability-table',
    templateUrl: './reports-planning-commitment-reliability-table.component.html',
    styleUrls: ['./reports-planning-commitment-reliability-table.component.scss'],
})
export class ReportsPlanningCommitmentReliabilityTableComponent extends BaseComponent implements OnInit {
    pastWeeks$ = this.store.select((store) => store.planningState.commitmentReliability.weeks);
    weeks = [];
    months$ = this.store.select((store) => store.planningState.commitmentReliability.months);
    months = [];
    grandTotals$ = this.store.select((store) => store.planningState.commitmentReliability.data);
    monthViewEnabled$ = this.store.select((store) => store.planningState.commitmentReliability.monthViewEnabled);
    monthViewEnabled = false;
    commitmentReliabilityType = '';
    itrTypes = ScheduleActivityITRScope;
    mappedScheduleActivityPlanningFilterState: string;
    scheduleActivityFilter: ScheduleActivityPlanningFilter = new ScheduleActivityPlanningFilter();
    planningCommitmentReliabilityState: PlanningCommitmentReliabilityState;
    planningCommitmentReliabilityState$ = this.store.select((state) => state.planningState.commitmentReliability);

    constructor(private activatedRoute: ActivatedRoute, private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {
        this.commitmentReliabilityType = this.activatedRoute.routeConfig.path;
        this.pastWeeks$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.weeks = data.map((s) => {
                return { week: moment(s.date).format('w'), date: s.weekEnd };
            });
        });
        this.months$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.months = data.map((s) => {
                return { date: s.date };
            });
        });
        this.monthViewEnabled$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.monthViewEnabled = data;
        });
    }

    openItrPage(itrScope: ScheduleActivityITRScope, week: moment.Moment) {
        if (this.commitmentReliabilityType === 'itr') {
            this.planningCommitmentReliabilityState$
                .pipe(take(1))
                .subscribe((commitmentReliability: PlanningCommitmentReliabilityState) => {
                    localStorage.setItem(
                        'scheduleActivityItrFilter',
                        JSON.stringify({
                            rfos: commitmentReliability.filter.rfos,
                            subsystems: commitmentReliability.filter.subsystems,
                            disciplines: commitmentReliability.filter.disciplines,
                            areas: commitmentReliability.filter.areas,
                            scManager: commitmentReliability.filter.scManager,
                            workAreas: commitmentReliability.filter.workAreas,
                            weekStart: moment(week).startOf('day').utcOffset(0, true),
                            itrCommittmentReliabilityScope: itrScope,
                            lookaheadDays: 6,
                            itrContractors: commitmentReliability.filter.contractors.map((value) => ({
                                id: value.contractNo,
                            })),
                        })
                    );
                });
            window.open(`/weeklyplanning/lookaheaditrs`, '_blank');
        }
    }
}
