import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum COWActionTypes {
    COWUploadLogRequest = '[COW] Upload Log Request',
    COWUploadLogSuccess = '[COW] Upload Log Success',
    COWUploadLogError = '[COW] Upload Log Error',
    COWAddStartedLog = '[COW] Add Started Log',
    COWFileUploaded = '[COW] File Uploaded',
    COWTemplateFileRequest = '[COW] Template File Request',
    COWTemplateFileSuccess = '[COW] Template File Success',
    COWTemplateFileError = '[COW] Template File Error',
    COWSetStatusAndStartDate = '[COW] Set Status and Start Date',
    COWValidateButtonStateRequest = '[COW] Validate Button State Request',
    COWValidateButtonStateSuccess = '[COW] Validate Button State Success',
    COWValidateButtonStateError = '[COW] Validate Button State Error',
    COWClearInProgressSpinner = '[COW] Clear In Progress Spinner',
    COWDownloadOutputDataRequest = '[COW] Download Output Data Request',
    COWDownloadOutputDataSuccess = '[COW] Download Output Data Success',
    COWDownloadOutputDataError = '[COW] Download Output Data Error',
    COWValidateDeltaRequest = '[COW] Validate Delta Request',
    COWValidateDeltaSuccess = '[COW] Validate Delta Success',
    COWValidateDeltaError = '[COW] Validate Delta Error',
}

export class COWAddStartedLog implements Action {
    readonly type = COWActionTypes.COWAddStartedLog;
}

export class COWUploadLogRequest implements Action {
    readonly type = COWActionTypes.COWUploadLogRequest;
}

export class COWFileUploaded implements Action {
    readonly type = COWActionTypes.COWFileUploaded;
}

export class COWTemplateFileRequest implements Action {
    readonly type = COWActionTypes.COWTemplateFileRequest;

    constructor(public payload: string) {}
}

export class COWUploadLogSuccess implements Action {
    readonly type = COWActionTypes.COWUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class COWUploadLogError implements Action {
    readonly type = COWActionTypes.COWUploadLogError;

    constructor(public payload: string) {}
}

export class COWSetStatusAndStartDate implements Action {
    readonly type = COWActionTypes.COWSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class COWValidateButtonStateRequest implements Action {
    readonly type = COWActionTypes.COWValidateButtonStateRequest;
}

export class  COWValidateButtonStateSuccess implements Action {
    readonly type = COWActionTypes.COWValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class COWValidateButtonStateError implements Action {
    readonly type = COWActionTypes.COWValidateButtonStateError;

    constructor(public payload: string) {}
}

export class COWClearInProgressSpinner implements Action {
    readonly type = COWActionTypes.COWClearInProgressSpinner;
}

export class COWDownloadOutputDataRequest implements Action {
    readonly type = COWActionTypes.COWDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class COWDownloadOutputDataSuccess implements Action {
    readonly type = COWActionTypes.COWDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class  COWDownloadOutputDataError implements Action {
    readonly type =  COWActionTypes.COWDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class COWTemplateFileSuccess implements Action {
    readonly type = COWActionTypes.COWTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class COWTemplateFileError implements Action {
    readonly type = COWActionTypes.COWTemplateFileError;

    constructor(public payload: string) {}
}

export class COWValidateDeltaRequest implements Action {
    readonly type = COWActionTypes.COWValidateDeltaRequest;
}

export class COWValidateDeltaSuccess implements Action {
    readonly type = COWActionTypes.COWValidateDeltaSuccess;
}

export class COWValidateDeltaError implements Action {
    readonly type = COWActionTypes.COWValidateDeltaError;

    constructor(public payload: string) {}
}

export type COWActions = 
    | COWAddStartedLog
    | COWFileUploaded 
    | COWUploadLogRequest 
    | COWUploadLogSuccess
    | COWUploadLogError
    | COWSetStatusAndStartDate
    | COWValidateButtonStateSuccess
    | COWValidateButtonStateRequest
    | COWValidateButtonStateError
    | COWClearInProgressSpinner
    | COWDownloadOutputDataRequest
    | COWDownloadOutputDataSuccess
    | COWDownloadOutputDataError
    | COWTemplateFileRequest
    | COWTemplateFileSuccess
    | COWTemplateFileError
    | COWValidateDeltaRequest
    | COWValidateDeltaSuccess
    | COWValidateDeltaError;