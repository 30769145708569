import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { Constants } from 'src/app/constants';
import { PopupSettings } from 'src/app/models/popup-settings';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { TraceHeatingStatusService } from 'src/app/services/api/webapi-services/reports/trace-heating-status.service';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { OrderDirection, StagedStartUpPriority } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    TraceHeatingStatusDataRequest,
    TraceHeatingStatusExportToExcelRequest,
    TraceHeatingStatusFilterUpdate,
    TraceHeatingStatusFilterReset,
    TraceHeatingStatusResetPrint,
} from 'src/app/store/trace-heating-status/action';
import { Subsystem, TraceHeatingStatusDto, TraceHeatingStatusFilter } from 'src/app/store/trace-heating-status/model';
import { ModelShotsComponent } from './model-shots/model-shots.component';

@Component({
    selector: 'app-trace-heating-status',
    templateUrl: './trace-heating-status.component.html',
    styleUrls: ['./trace-heating-status.component.scss'],
})
export class TraceHeatingStatusComponent extends BaseComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    filterForm: UntypedFormGroup;
    setTdbSubsystemsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setProcessSubsystemsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setCircuit: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setHeatTrackingTapes: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setTdbStagedPriority: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setProcessStagedPriority: EventEmitter<SetInputEventArgs> = new EventEmitter();

    stagedStartUpPriorities: StagedStartUpPriority[] = [];

    traceHeatingStatusDataSource: MatTableDataSource<TraceHeatingStatusDto>;
    traceHeatingStatus$ = this.store.select((store) => store.traceHeatingStatusState.traceHeatingStatus);

    pageSize: number = 25;
    resultsLength: number = 0;
    showPortal = false;
    isAdmin = false;
    orderBy = OrderDirection.Asc;

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private scheduleActivityLookupService: ScheduleActivityLookupService,
        private traceHeatingStatusService: TraceHeatingStatusService,
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private roleService: RoleService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new TraceHeatingStatusFilter());
    }

    ngOnInit(): void {
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new TraceHeatingStatusFilterUpdate(this.filterForm.value as TraceHeatingStatusFilter));
        this.store.dispatch(new TraceHeatingStatusDataRequest());
        this.traceHeatingStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((traceHeatingStatus) => {
            this.traceHeatingStatusDataSource = new MatTableDataSource(traceHeatingStatus.items);
            this.resultsLength = traceHeatingStatus.totalCount;
        });
    }

    searchSubsystems = (search = '', takeCount = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchSubsystems(search, takeCount, page, this.filterForm.value);
    };

    getTdbSubsystems = (search = '', take = 10, page = 0) => {
        return this.traceHeatingStatusService.searchSubsystems(search, take, page, true);
    };

    getProcessSubsystems = (search = '', take = 10, page = 0) => {
        return this.traceHeatingStatusService.searchSubsystems(search, take, page, false);
    };

    getCircuits = (search = '', take = 10, page = 0) => {
        return this.traceHeatingStatusService.searchCircuits(search, take, page);
    };

    getHeatTrackingTapes = (search = '', take = 10, page = 0) => {
        return this.traceHeatingStatusService.searchHeatTrackingTapes(search, take, page);
    };

    getStagedUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchStagedStartUpPriorities(search, take, page, []);
    };

    exportToExcel() {
        this.store.dispatch(new TraceHeatingStatusExportToExcelRequest());
    }

    resetFilters(event: Event = null) {
        if (event) {
            event.stopPropagation();
        }
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new TraceHeatingStatusFilterReset());
        this.filterForm.controls['tdbSubsystems'].setValue([]);
        this.filterForm.controls['tdbSubsystems'].updateValueAndValidity();
        this.filterForm.controls['processSubsystems'].setValue([]);
        this.filterForm.controls['processSubsystems'].updateValueAndValidity();
        this.filterForm.controls['circuit'].setValue([]);
        this.filterForm.controls['circuit'].updateValueAndValidity();
        this.filterForm.controls['heatTrackingTapes'].setValue([]);
        this.filterForm.controls['heatTrackingTapes'].updateValueAndValidity();
        this.filterForm.controls['tdbStagedPriority'].setValue([]);
        this.filterForm.controls['tdbStagedPriority'].updateValueAndValidity();
        this.filterForm.controls['processStagedPriority'].setValue([]);
        this.filterForm.controls['processStagedPriority'].updateValueAndValidity();
        this.filterForm.controls['isTdbMcComplete'].setValue(null);
        this.filterForm.controls['isAllProcessMcCompleteOnCircuit'].setValue(null);
    }

    toggleExpansionPanel(event: Event) {
        if (event) event.stopPropagation();
        this.filterExpansionPanel.toggle();
    }

    search() {
        this.store.dispatch(new TraceHeatingStatusFilterUpdate(this.filterForm.value as TraceHeatingStatusFilter));
        this.store.dispatch(new TraceHeatingStatusDataRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.filterForm.controls.page.setValue(pageEvent.pageIndex);
        }
        this.store.dispatch(new TraceHeatingStatusFilterUpdate(this.filterForm.value as TraceHeatingStatusFilter));
        this.store.dispatch(new TraceHeatingStatusDataRequest());
    }

    displaySelectedBoolean(boolValue: boolean) {
        if (boolValue === null) {
            return 'All';
        }
        return boolValue ? 'Yes' : 'No';
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    displayMultipleItemsSelected(values: Subsystem[]) {
        return values.map((x) => x.id).join(', ');
    }

    displayStageUpPriorities(values: StagedStartUpPriority[]) {
        return values.map((x) => x.priority).join(', ');
    }

    changeOrderDirection() {
        if (this.orderBy === OrderDirection.Asc) {
            this.orderBy = OrderDirection.Desc;
        } else {
            this.orderBy = OrderDirection.Asc;
        }
        this.filterForm.controls['orderBy'].setValue(this.orderBy);
        this.store.dispatch(new TraceHeatingStatusFilterUpdate({ orderBy: this.orderBy } as TraceHeatingStatusFilter));
        this.store.dispatch(new TraceHeatingStatusDataRequest());
    }

    onPrintWindowClose() {
        this.showPortal = false;
        this.store.dispatch(new TraceHeatingStatusResetPrint());
    }

    openPopupUploadModelShots() {
        this.popupSvc.openPopup(new PopupSettings(ModelShotsComponent, 2000, 400));
    }
}
