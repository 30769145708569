import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastService } from 'src/app/services/shared/toast.service';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    ChangeValidationDashboardActionTypes,
    ChangeValidationDashboardWeeklyValidationProgressRequestSuccess,
    ChangeValidationDashboardWeeklyValidationProgressRequestError,
    ChangeValidationDashboardMonthlyValidationProgressRequestSuccess,
    ChangeValidationDashboardMonthlyValidationProgressRequestError,
    ChangeValidationDashboardValidationProgressRequestSuccess,
    ChangeValidationDashboardValidationProgressRequestError,
    ChangeValidationDashboardCumulativeValidationProgressRequestSuccess,
    ChangeValidationDashboardCumulativeValidationProgressRequestError,
    ChangeValidationDashboardSystemisationProgressRequestSuccess,
    ChangeValidationDashboardSystemisationProgressRequestError,
    ChangeValidationDashboardMonthlyChangeRaisedRequestSuccess,
    ChangeValidationDashboardWeeklyChangeRaisedRequestSuccess,
    ChangeValidationDashboardMonthlyChangeRaisedRequestError,
    ChangeValidationDashboardValidatorPerformanceScoreboardRequestSuccess,
    ChangeValidationDashboardValidatorPerformanceScoreboardRequestError,
    ChangeValidationDashboardWeeklyValidationProgressRequest,
    ChangeValidationDashboardMonthlyValidationProgressRequest,
    ChangeValidationDashboardMonthlyChangeRaisedRequest,
    ChangeValidationDashboardWeeklyChangeRaisedRequest,
    ChangeValidationDashboardCumulativeValidationProgressRequest,
    ChangeValidationDashboardWeeklyCumulativeValidationProgressRequest,
    ChangeValidationDashboardValidatorPerformanceScoreboardRequest,
    ChangeValidationDashboardWeeklyCumulativeValidationProgressRequestSuccess,
} from './actions';
import { ChangeValidationDashboardService } from 'src/app/services/api/webapi-services/reports/change-validation-dashboard.service';
import { ProgressRangeDateFilter } from './model';

@Injectable()
export class ChangeValidationDashboardEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private changeValidationDashboardService: ChangeValidationDashboardService,
        private toastService: ToastService
    ) {}

    @Effect()
    weeklyValidationProgressRequest$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressRequest),
        withLatestFrom(
            this.store.select(
                (store) => store.changeValidationDashboardState.weeklyValidationProgress.rangeDateFilter.startDate
            ),
            this.store.select(
                (store) => store.changeValidationDashboardState.weeklyValidationProgress.rangeDateFilter.endDate
            ),
            this.store.select((store) => store.changeValidationDashboardState.filter)
        ),
        mergeMap(([, startDate, endDate, changeValidationFilter]) => {
            var filter = {
                ...changeValidationFilter,
                startDate,
                endDate,
            };

            return this.changeValidationDashboardService.filterWeeklyValidationProgressData(filter).pipe(
                map((data) => new ChangeValidationDashboardWeeklyValidationProgressRequestSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Change Validation Dashboard Weekly Validation Progress data. Please contact Program Administrator.`
                    );
                    return of(new ChangeValidationDashboardWeeklyValidationProgressRequestError(error));
                })
            );
        })
    );
    
    @Effect()
    monthlyValidationProgressRequest$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressRequest),
        withLatestFrom(
            this.store.select(
                (store) => store.changeValidationDashboardState.monthlyValidationProgress.rangeDateFilter.startDate
            ),
            this.store.select(
                (store) => store.changeValidationDashboardState.monthlyValidationProgress.rangeDateFilter.endDate
            ),
            this.store.select((store) => store.changeValidationDashboardState.filter)
        ),
        mergeMap(([, startDate, endDate, changeValidationFilter]) => {
            var filter = {
                ...changeValidationFilter,
                startDate,
                endDate,
            };

            return this.changeValidationDashboardService.filterMonthlyValidationProgressData(filter).pipe(
                map((data) => new ChangeValidationDashboardMonthlyValidationProgressRequestSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Change Validation Dashboard Monthly Validation Progress data. Please contact Program Administrator.`
                    );
                    return of(new ChangeValidationDashboardMonthlyValidationProgressRequestError(error));
                })
            );
        })
    );

    @Effect()
    validationProgressRequest$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidationProgressRequest),
        withLatestFrom(this.store.select((store) => store.changeValidationDashboardState.filter)),
        mergeMap(([action, changeValidationDashboardFilter]) => {
            return this.changeValidationDashboardService
                .filterValidationProgressData(changeValidationDashboardFilter)
                .pipe(
                    map((data) => new ChangeValidationDashboardValidationProgressRequestSuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while getting Change Validation Dashboard Validation Progress data. Please contact Program Administrator.`
                        );
                        return of(new ChangeValidationDashboardValidationProgressRequestError(error));
                    })
                );
        })
    );

    @Effect()
    cumulativeValidationProgressRequest$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressRequest),
        withLatestFrom(
            this.store.select((store) => store.changeValidationDashboardState.filter),
            this.store.select(
                (store) => store.changeValidationDashboardState.cumulativeValidationProgress.rangeDateFilter
            )
        ),
        mergeMap(([, changeValidationDashboardFilter, { startDate, endDate }]) => {
            const filter = {
                ...changeValidationDashboardFilter,
                startDate,
                endDate,
            };
            return this.changeValidationDashboardService.filterCumulativeValidationProgressData(filter).pipe(
                map((data) => new ChangeValidationDashboardCumulativeValidationProgressRequestSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Change Validation Dashboard Cumulative Validation Progress data. Please contact Program Administrator.`
                    );
                    return of(new ChangeValidationDashboardCumulativeValidationProgressRequestError(error));
                })
            );
        })
    );

    @Effect()
    weeklycumulativeValidationProgressRequest$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyCumulativeValidationProgressRequest),
        withLatestFrom(
            this.store.select((store) => store.changeValidationDashboardState.filter),
            this.store.select(
                (store) => store.changeValidationDashboardState.weeklyValidationProgress.rangeDateFilter
            )
        ),
        mergeMap(([, changeValidationDashboardFilter, { startDate, endDate }]) => {
            const filter = {
                ...changeValidationDashboardFilter,
                startDate,
                endDate,
            };
            return this.changeValidationDashboardService.filterWeeklyCumulativeValidationProgressData(filter).pipe(
                map((data) => new ChangeValidationDashboardWeeklyCumulativeValidationProgressRequestSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Change Validation Dashboard Cumulative Validation Progress data. Please contact Program Administrator.`
                    );
                    return of(new ChangeValidationDashboardCumulativeValidationProgressRequestError(error));
                })
            );
        })
    );

    @Effect()
    systemisationProgressRequest$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardSystemisationProgressRequest),
        withLatestFrom(this.store.select((store) => store.changeValidationDashboardState.filter)),
        mergeMap(([action, changeValidationDashboardFilter]) => {
            return this.changeValidationDashboardService
                .filterSystemisationProgressData(changeValidationDashboardFilter)
                .pipe(
                    map((data) => new ChangeValidationDashboardSystemisationProgressRequestSuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while getting Change Validation Dashboard Systemisation Progress data. Please contact Program Administrator.`
                        );
                        return of(new ChangeValidationDashboardSystemisationProgressRequestError(error));
                    })
                );
        })
    );

    @Effect()
    monthlyChangeRaisedRequest$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedRequest),
        withLatestFrom(
            this.store.select(
                (store) => store.changeValidationDashboardState.monthlyChangeRaised.rangeDateFilter.startDate
            ),
            this.store.select(
                (store) => store.changeValidationDashboardState.monthlyChangeRaised.rangeDateFilter.endDate
            ),
            this.store.select((store) => store.changeValidationDashboardState.filter)
        ),
        mergeMap(([, startDate, endDate, changeValidationFilter]) => {
            var filter = {
                ...changeValidationFilter,
                startDate,
                endDate,
            };
            return this.changeValidationDashboardService.filterMonthlyChangeRaisedData(filter).pipe(
                map((data) => new ChangeValidationDashboardMonthlyChangeRaisedRequestSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Change Validation Dashboard Monthly Change Raised data. Please contact Program Administrator.`
                    );
                    return of(new ChangeValidationDashboardMonthlyChangeRaisedRequestError(error));
                })
            );
        })
    );

    @Effect()
    weeklyChangeRaisedRequest$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyChangeRaisedRequest),
        withLatestFrom(
            this.store.select(
                (store) => store.changeValidationDashboardState.weeklyValidationProgress.rangeDateFilter.startDate
            ),
            this.store.select(
                (store) => store.changeValidationDashboardState.weeklyValidationProgress.rangeDateFilter.endDate
            ),
            this.store.select((store) => store.changeValidationDashboardState.filter)
        ),
        mergeMap(([, startDate, endDate, changeValidationFilter]) => {
            var filter = {
                ...changeValidationFilter,
                startDate,
                endDate,
            };
            return this.changeValidationDashboardService.filterWeeklyChangeRaisedData(filter).pipe(
                map((data) => new ChangeValidationDashboardWeeklyChangeRaisedRequestSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Change Validation Dashboard Weekly Change Raised data. Please contact Program Administrator.`
                    );
                    return of(new ChangeValidationDashboardMonthlyChangeRaisedRequestError(error));
                })
            );
        })
    );

    @Effect()
    validatorPerformanceScoreboardRequest$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardRequest),
        withLatestFrom(
            this.store.select(
                (store) => store.changeValidationDashboardState.validatorPerformanceScoreboard.rangeDateFilter.startDate
            ),
            this.store.select(
                (store) => store.changeValidationDashboardState.validatorPerformanceScoreboard.rangeDateFilter.endDate
            )
        ),
        mergeMap(([, startDate, endDate]) => {
            var filter = new ProgressRangeDateFilter(startDate, endDate);
            return this.changeValidationDashboardService.filterValidatorPerformanceScoreboardData(filter).pipe(
                map((data) => new ChangeValidationDashboardValidatorPerformanceScoreboardRequestSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Change Validation Dashboard Validator Performance Scoreboard data. Please contact Program Administrator.`
                    );
                    return of(new ChangeValidationDashboardValidatorPerformanceScoreboardRequestError(error));
                })
            );
        })
    );

    @Effect()
    requestWeeklyValidationProgressOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyValidationProgressSetWeekRange),
        map(() => new ChangeValidationDashboardWeeklyValidationProgressRequest())
    );

    @Effect()
    requestMonthlyValidationProgressOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyValidationProgressSetMonthRange),
        map(() => new ChangeValidationDashboardMonthlyValidationProgressRequest())
    );

    @Effect()
    requestMonthlyChangeRaisedOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange),
        map(() => new ChangeValidationDashboardMonthlyChangeRaisedRequest())
    );

    @Effect()
    requestWeeklyChangeRaisedOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyChangeRaisedSetWeekRange),
        map(() => new ChangeValidationDashboardWeeklyChangeRaisedRequest())
    );

    @Effect()
    requestCumulativeValidationProgressOnMonthRangeChanged$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardCumulativeValidationProgressSetMonthRange),
        map(() => new ChangeValidationDashboardCumulativeValidationProgressRequest())
    );

    @Effect()
    requestWeeklyCumulativeValidationProgressOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(ChangeValidationDashboardActionTypes.ChangeValidationDashboardWeeklyCumulativeValidationProgressSetWeekRange),
        map(() => new ChangeValidationDashboardWeeklyCumulativeValidationProgressRequest())
    );

    @Effect()
    requestValidatorPerformanceScoreboardOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(
            ChangeValidationDashboardActionTypes.ChangeValidationDashboardValidatorPerformanceScoreboardSetWeekRange
        ),
        map(() => new ChangeValidationDashboardValidatorPerformanceScoreboardRequest())
    );
}
