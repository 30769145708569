import { Action } from '@ngrx/store';
import { SkylineFilter, SkylineLevelOfDetails, SkylineResponseDTO, SkylineSuccessPayload } from './model';
import * as moment from 'moment';

export enum SkylineActionTypes {
    SkylineSetPhaseAndType = '[Skyline] Set Phase and Type',
    SkylineFilterUpdate = '[Skyline] Filter Update',
    SkylineFilterPropertyUpdate = '[Skyline] Filter Property Update',
    SkylineFilterReset = '[Skyline] Filter Reset',
    SkylineRequest = '[Skyline] Request',
    SkylinePrintReset = '[Skyline] Print Reset',
    SkylineSuccess = '[Skyline] Success',
    SkylineError = '[Skyline] Error',
    SkylineSetLevelOfDetails = '[Skyline] Set Level of Details',
    SkylineSetWeekRange = '[Skyline] Set Week Range',
    SkylineGetExcelRequest = '[Skyline] Get Skyline Excel Request',
    SkylineGetExcelRequestSuccess = '[Skyline] Get Skyline Excel Success',
    SkylineGetExcelRequestError = '[Skyline] Get Skyline Excel Error',
    SkylineFlagTogglesUpdate = '[Skyline] Flag Toggles Update',
}

export class SkylineSetPhaseAndType implements Action {
    readonly type = SkylineActionTypes.SkylineSetPhaseAndType;

    constructor(public payload: { phase: string; type: string }) {
        // This is intentional
    }
}

export class SkylineFilterUpdate implements Action {
    readonly type = SkylineActionTypes.SkylineFilterUpdate;

    constructor(public payload: SkylineFilter) {
        // This is intentional
    }
}

export class SkylineFilterPropertyUpdate implements Action {
    readonly type = SkylineActionTypes.SkylineFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {
        // This is intentional
    }
}

export class SkylineRequest implements Action {
    readonly type = SkylineActionTypes.SkylineRequest;

    constructor(public payload: { printMode: boolean }) {
        // This is intentional
    }
}

export class SkylinePrintReset implements Action {
    readonly type = SkylineActionTypes.SkylinePrintReset;
}

export class SkylineSuccess implements Action {
    readonly type = SkylineActionTypes.SkylineSuccess;

    constructor(public payload: SkylineSuccessPayload) {
        // This is intentional
    }
}

export class SkylineError implements Action {
    readonly type = SkylineActionTypes.SkylineError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class SkylineFilterReset implements Action {
    readonly type = SkylineActionTypes.SkylineFilterReset;
}

export class SkylineSetLevelOfDetails implements Action {
    readonly type = SkylineActionTypes.SkylineSetLevelOfDetails;

    constructor(public payload: SkylineLevelOfDetails) {
        // This is intentional
    }
}

export class SkylineSetWeekRange implements Action {
    readonly type = SkylineActionTypes.SkylineSetWeekRange;

    constructor(public payload: moment.Moment) {
        // This is intentional
    }
}

export class SkylineGetExcelRequest implements Action {
    readonly type = SkylineActionTypes.SkylineGetExcelRequest;
    constructor() {
        // This is intentional
    }
}

export class SkylineGetExcelRequestSuccess implements Action {
    readonly type = SkylineActionTypes.SkylineGetExcelRequestSuccess;
    constructor(public payload: { data: BlobPart; phase: string; type: string }) {
        // This is intentional
    }
}

export class SkylineGetExcelRequestError implements Action {
    readonly type = SkylineActionTypes.SkylineGetExcelRequestError;
    constructor(public payload: BlobPart) {
        // This is intentional
    }
}

export class SkylineFlagTogglesUpdate implements Action {
    readonly type = SkylineActionTypes.SkylineFlagTogglesUpdate;
    constructor(public payload: { propertyName: string; value: boolean }) {
        // This is intentional
    }
}
export type SkylineActions =
    | SkylineSetPhaseAndType
    | SkylineFilterUpdate
    | SkylineFilterPropertyUpdate
    | SkylineFilterReset
    | SkylineRequest
    | SkylinePrintReset
    | SkylineSuccess
    | SkylineError
    | SkylineSetLevelOfDetails
    | SkylineSetWeekRange
    | SkylineGetExcelRequest
    | SkylineGetExcelRequestSuccess
    | SkylineGetExcelRequestError
    | SkylineFlagTogglesUpdate;
