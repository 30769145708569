import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';

@Component({
    selector: 'app-rfsu-skyline',
    templateUrl: './skyline.component.html',
    styleUrls: ['./skyline.component.scss'],
})
export class RFSUSkylineComponent {
    isLoading$ = this.store.select((state) => state.milestoneDashboardState.rfsuMilestoneSkyline.isLoading);
    items$ = this.store.select((state) => state.milestoneDashboardState.rfsuMilestoneSkyline.items);
    weeks$ = this.store.select((state) => state.milestoneDashboardState.rfsuMilestoneSkyline.weeks);
    openAqvdsPerWeek$ = this.store.select((state) => state.milestoneDashboardState.rfsuMilestoneSkyline.openAqvdsPerWeek);

    constructor(private store: Store<ApplicationState>) {}
}
