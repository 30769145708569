import { Action } from '@ngrx/store';
import { BaseResultDTO } from '../../common.model';
import { GOOC1ComparisonDashboardDTO, GOOC2ComparisonDashboardDTO } from './model';

export enum GOOCComparisonDashboardActionTypes {
    GOOCComparison1DashboardRequest = '[GOOC Comparison 1 Dashboard] Request',
    GOOCComparison1DashboardSuccess = '[GOOC Comparison 1 Dashboard] Success',
    GOOCComparison1DashboardError = '[GOOC Comparison 1 Dashboard] Error',
    GOOCComparison1DashboardUpdateFilterProperties = '[GOOC Comparison 1 Dashboard] Update Filter Properties',
    GOOCComparison1DashboardResetFilter = '[GOOC Comparison 1 Dashboard] Reset Filter',
    GOOCComparison1DashboardExportToExcelRequest = '[GOOC Comparison 1 Dashboard] Export To Excel Request',
    GOOCComparison1DashboardExportToExcelSuccess = '[GOOC Comparison 1 Dashboard] Export to Excel Success',
    GOOCComparison1DashboardExportToExcelError = '[GOOC Comparison 1 Dashboard] Export to Excel Error',

    GOOCComparison2DashboardRequest = '[GOOC Comparison 2 Dashboard] Request',
    GOOCComparison2DashboardSuccess = '[GOOC Comparison 2 Dashboard] Success',
    GOOCComparison2DashboardError = '[GOOC Comparison 2 Dashboard] Error',
    GOOCComparison2DashboardUpdateFilterProperties = '[GOOC Comparison 2 Dashboard] Update Filter Properties',
    GOOCComparison2DashboardResetFilter = '[GOOC Comparison 2 Dashboard] Reset Filter',
    GOOCComparison2DashboardExportToExcelRequest = '[GOOC Comparison 2 Dashboard] Export To Excel Request',
    GOOCComparison2DashboardExportToExcelSuccess = '[GOOC Comparison 2 Dashboard] Export to Excel Success',
    GOOCComparison2DashboardExportToExcelError = '[GOOC Comparison 2 Dashboard] Export to Excel Error',
}


export class GOOCComparison1DashboardExportToExcelRequest implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardExportToExcelRequest;
}

export class GOOCComparison1DashboardExportToExcelSuccess implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class GOOCComparison1DashboardExportToExcelError implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardExportToExcelError;

    constructor(public payload: string) {}
}

export class GOOCComparison1DashboardRequest implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardRequest;
}

export class GOOCComparison1DashboardSuccess implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardSuccess;

    constructor(public payload: BaseResultDTO<GOOC1ComparisonDashboardDTO>) {}
}

export class GOOCComparison1DashboardError implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardError;

    constructor(public payload: string) {}
}

export class GOOCComparison1DashboardUpdateFilterProperties implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardUpdateFilterProperties;

    constructor(public payload: { [key: string]: any }) {}
}

export class GOOCComparison1DashboardResetFilter implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardResetFilter;
}

export class GOOCComparison2DashboardExportToExcelRequest implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardExportToExcelRequest;
}

export class GOOCComparison2DashboardExportToExcelSuccess implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class GOOCComparison2DashboardExportToExcelError implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardExportToExcelError;

    constructor(public payload: string) {}
}

export class GOOCComparison2DashboardRequest implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardRequest;
}

export class GOOCComparison2DashboardSuccess implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardSuccess;

    constructor(public payload: BaseResultDTO<GOOC2ComparisonDashboardDTO>) {}
}

export class GOOCComparison2DashboardError implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardError;

    constructor(public payload: string) {}
}

export class GOOCComparison2DashboardUpdateFilterProperties implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardUpdateFilterProperties;

    constructor(public payload: { [key: string]: any }) {}
}

export class GOOCComparison2DashboardResetFilter implements Action {
    readonly type = GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardResetFilter;
}

export type GOOCComparisonDashboardActions =
    | GOOCComparison1DashboardRequest
    | GOOCComparison1DashboardSuccess
    | GOOCComparison1DashboardError
    | GOOCComparison1DashboardUpdateFilterProperties
    | GOOCComparison1DashboardResetFilter
    | GOOCComparison1DashboardExportToExcelRequest
    | GOOCComparison1DashboardExportToExcelSuccess
    | GOOCComparison1DashboardExportToExcelError
    | GOOCComparison2DashboardRequest
    | GOOCComparison2DashboardSuccess
    | GOOCComparison2DashboardError
    | GOOCComparison2DashboardUpdateFilterProperties
    | GOOCComparison2DashboardResetFilter
    | GOOCComparison2DashboardExportToExcelRequest
    | GOOCComparison2DashboardExportToExcelSuccess
    | GOOCComparison2DashboardExportToExcelError;
