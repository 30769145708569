import { LoopState } from './model';
import { LoopActions, LoopActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: LoopState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isDataReadyToProcess: false,
    recordsForDeletion: 0,
    isAutomaticUploadInProgress: false,
};

export function reducer(state = initialState, action: LoopActions) {
    switch (action.type) {
        case LoopActionTypes.LoopUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case LoopActionTypes.LoopUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case LoopActionTypes.LoopUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case LoopActionTypes.LoopSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case LoopActionTypes.LoopFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case LoopActionTypes.LoopClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case LoopActionTypes.LoopDataInfoSuccess: {
            return {
                ...state,
                isDataReadyToProcess: action.payload.readyToProcess[0],
                recordsForDeletion: action.payload.recordsForDeletion[0],
                isAutomaticUploadInProgress: action.payload.isAutomaticUploadInProgress,
            };
        }
        case LoopActionTypes.LoopDataInfoError: {
            return {
                ...state,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case LoopActionTypes.LoopDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LoopActionTypes.LoopDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LoopActionTypes.LoopDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LoopActionTypes.LoopAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'Loop';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        case LoopActionTypes.LoopValidateDeltaRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LoopActionTypes.LoopValidateDeltaSuccess: {
            return {
                ...state,
                isLoading: false,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case LoopActionTypes.LoopValidateDeltaError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LoopActionTypes.LoopTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LoopActionTypes.LoopTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LoopActionTypes.LoopTemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}
