import { ChangeDetectorRef, Component, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { UserDetail } from 'src/app/store/common.model';
import { BaseComponent } from '../../base.component';
import { DetailedStatusDTO} from 'src/app/store/detailed-status/model';
import { DetailedStatusAddResponsiblePersonRequest } from 'src/app/store/detailed-status/actions';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { UserDefinedInputs } from 'src/app/enums';

@Component({
  selector: 'app-responsbile-person-selector',
  templateUrl: './responsbile-person-selector.component.html',
  styleUrls: ['./responsbile-person-selector.component.scss']
})
export class ResponsbilePersonSelectorComponent extends BaseComponent {

  loading: boolean;
  id: number;
  responsiblePersonForm: UntypedFormGroup;
  usersAutocompleteDisplayedColumns = ['name', 'email'];
  setUsersInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
  detailedStatus$ = this.store.select((state) => state.detailedStatusState.isLoading);
  responsiblePersonaArr: string[];
  data: DetailedStatusDTO = new DetailedStatusDTO();
  users: UserDetail[] = [];
  userDefinedPropertyName: string ='';
  userDefinedPropertyId: number = -1;
  typeInput : UserDefinedInputs;
  headerTitle: string;
  url: string;
  constructor(
      public dialogRef: MatDialogRef<ResponsbilePersonSelectorComponent>,
      @Inject(MAT_DIALOG_DATA) public respData: any,
      private lookupService: LookupService,
      private store: Store<ApplicationState>,
      private changeDetectorRef: ChangeDetectorRef,
  ) {
      super();
      this.responsiblePersonForm = new UntypedFormGroup({
        username: new UntypedFormControl('', Validators.required),   
    });
    this.data = respData.row;  
    this.userDefinedPropertyName = respData.userDefinedPropertyName || '';
    this.userDefinedPropertyId = respData.userDefinedPropertyId ;
    this.url = respData.url || '';
    this.typeInput = respData.type;
  }
  ngOnInit() {
    this.getUsers();
    this.setHeader();
    this.detailedStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
      this.loading = isLoading;
      // hack for the Angular bug:
      // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
      this.changeDetectorRef.detectChanges();
  });
  }
  onUsersClosed() {
    this.setUsersInput.emit(new SetInputEventArgs(true));
}

getUsers() {
  this.lookupService
      .searchUsers('', 0, 5000)
      .pipe(take(1))
      .subscribe((data) => {
          this.users = data;

          let respuser = this.users.find((t) => t.id === this.userDefinedPropertyId);
          if (respuser != undefined) {
              this.responsiblePersonForm.controls['username'].setValue([respuser]);
          }
      });
}

searchUsers = (search: string, take: number, page: number) => {
  let elements = this.users.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  if (elements.length) {
      elements.forEach((s: any) => {
          s.Selected = false;
          return s;
      });
      return of(elements.slice(page * take, page * take + take));
  } else {
      return of([]);
  }
};
setHeader = () => {
  switch (this.typeInput) {
      case UserDefinedInputs.ResponsiblePerson:
        this.headerTitle ='choose Responsible person';
      break;
      case UserDefinedInputs.AdditionalResponsiblePerson:
        this.headerTitle=   'choose Additional Responsible Person';
        break;
      default:
        this.headerTitle = '';
        break;
  }
}
setLabel = () => {
  let placeHolderLabel : string;
  switch (this.typeInput) {
    case UserDefinedInputs.ResponsiblePerson:
      placeHolderLabel ='Responsible Person';
      break;
      case UserDefinedInputs.AdditionalResponsiblePerson:
        placeHolderLabel=   'Additional Responsible Person';
        break;
  
    default:
      placeHolderLabel = '';
      break;
  }
  return placeHolderLabel;
}

  public onCancel(): void {
      this.dialogRef.close();
  }

  public onConfirm(): void {
      let selectedResponsiblePersonId = this.responsiblePersonForm.controls.username.value[0]?.id ?? null;
      let selectedResponsiblePerson = this.responsiblePersonForm.controls.username.value[0]?.name ?? null;
      
      const payload = {
          ex: this.data,
          responsiblePersonId:selectedResponsiblePersonId,
          responsiblePerson:selectedResponsiblePerson,
          typeInput: this.typeInput
      } ;
     this.store.dispatch(new DetailedStatusAddResponsiblePersonRequest(payload));
     this.dialogRef.close({ element: this.data });
  }
}
