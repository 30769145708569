import { Area, Contractor } from '../../common.model';
import { initWeekRange, initMonthRange, getNextWeekStartDate, getCurrentWeekStartDate } from 'src/app/extensions';
import * as moment from 'moment';
import { PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT } from '../planning/model';

export const CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS = 12;
export const CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS = 12;
export const MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT = 'YYYY-MM';
export const CUMULATIVE_VALIDATION_PROGRESS_MONTH_RANGE = 12;
export const VALIDATOR_PERFORMANCE_SCOREBOARD_NUM_OF_PAST_WEEKS = 1;

export class WeeklyValidationProgressDataDTO {
    completedDate?: moment.Moment;
    inProgressDate?: moment.Moment;
}

export class WeeklyValidationProgressItem {
    greenCount: number;
    yellowCount: number;
}

export class MonthlyValidationProgressDataDTO {
    completedDate?: moment.Moment;
    inProgressDate?: moment.Moment;
}

export class MonthlyValidationProgressItem {
    greenCount: number;
    yellowCount: number;
}

export class WeeklyValidationProgressState {
    weeks = initWeekRange(
        getNextWeekStartDate().add(-CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS, 'weeks'),
        CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS
    );
    isLoading = false;
    data: WeeklyValidationProgressItem[] = [];
    rangeDateFilter: ProgressRangeDateFilter = new ProgressRangeDateFilter();
}

export class MonthlyValidationProgressState {
    isLoading = false;
    data: WeeklyValidationProgressItem[] = [];
    months = initMonthRange(
        moment()
            .add(-CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS + 1, 'months'),
        CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS
    );
    //rangeDateFilter: ProgressRangeDateFilter = new ProgressRangeDateFilter();
    rangeDateFilter = new ProgressRangeDateFilter(
        moment()
            .add(-CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS + 1, 'months')
            .format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT),
        moment().format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT)
    );
}

export class ValidatorPerformanceScoreboardState {
    isLoading = false;
    data: ValidatorPerformanceScoreboardDataDTO[] = [];
    rangeDateFilter = new ProgressRangeDateFilter(
        getCurrentWeekStartDate().format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
        getNextWeekStartDate().format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT)
    );
}

export class MonthlyChangeRaisedState {
    isLoading = false;
    data: NameSeriesDataDTO[] = [];
    rangeDateFilter = new ProgressRangeDateFilter(
        moment()
            .add(-CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS + 1, 'months')
            .format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT),
        moment().format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT)
    );
}

export class WeeklyChangeRaisedState {
    isLoading = false;
    data: NameSeriesDataDTO[] = [];
    rangeDateFilter = new ProgressRangeDateFilter(
        moment()
            .add(-CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS + 1, 'months')
            .format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT),
        moment().format(MONTHLY_CHANGE_RAISED_API_CALL_DATE_FORMAT)
    );
}

export class CumulativeValidationProgressState {
    isLoading = false;
    data: NameSeriesDataDTO[] = [];
    rangeDateFilter = new ProgressRangeDateFilter(
        moment()
            .startOf('month')
            .add(-CUMULATIVE_VALIDATION_PROGRESS_MONTH_RANGE + 1, 'months')
            .format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
        moment().endOf('month').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT)
    );
}
export class WeeklyCumulativeValidationProgressState {
    isLoading = false;
    data: NameSeriesDataDTO[] = [];
    rangeDateFilter = new ProgressRangeDateFilter(
        moment()
            .startOf('month')
            .add(-CUMULATIVE_VALIDATION_PROGRESS_MONTH_RANGE + 1, 'months')
            .format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
        moment().endOf('month').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT)
    );
}

export class ChangeValidationDashboardProgressDataDTO {
    totalChangeDocuments: number = null;
    totalsByProjectTeam: ChangeValidationDashboardProgressTotalByProjectTeamDTO[];
    totalsByStatus: ChangeValidationDashboardProgressTotalByStatusDTO[];
    completedChangeDocuments: number;
    progressChart: NameSeriesDataDTO[] = [];
}

export class ChangeValidationDashboardProgressTotalByProjectTeamDTO {
    projectTeam: string;
    total: number;
}

export class ChangeValidationDashboardProgressTotalByStatusDTO {
    status: string;
    total: number;
}

export class NameSeriesDataDTO {
    name: string;
    series: { name: string; value: number }[];
}

export class ValidatorPerformanceScoreboardDataDTO {
    name: string;
    value: number;
}

export interface ChangeValidationDashboardState {
    monthlyChangeRaised: MonthlyChangeRaisedState;
    weeklyChangeRaised: WeeklyChangeRaisedState;
    cumulativeValidationProgress: CumulativeValidationProgressState;
    weeklycumulativeValidationProgress: WeeklyCumulativeValidationProgressState;
    systemisationProgressData: ChangeValidationDashboardProgressDataDTO;
    isSystemisationProgressLoading: boolean;
    validationProgressData: ChangeValidationDashboardProgressDataDTO;
    isValidationProgressLoading: boolean;
    validatorPerformanceScoreboard: ValidatorPerformanceScoreboardState;
    weeklyValidationProgress: WeeklyValidationProgressState;
    monthlyValidationProgress: MonthlyValidationProgressState;
    filter: ChangeValidationDashboardFilter;
}

export class ChangeValidationDashboardFilter {
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    disciplines: string[] = [];
    changeTypes: string[] = [];
    changeStatuses: string[] = [];
    area: Area[] = [];
}

export class ProgressRangeDateFilter {
    startDate: string;
    endDate: string;

    constructor(startDate = '', endDate = '') {
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
