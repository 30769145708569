import { Component, Inject } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ChangeManagementService } from 'src/app/services/api/webapi-services/change-management.service';
import { SignOffRequest } from 'src/app/models/sing-off-request';

@Component({
    selector: 'app-sign-off-popup',
    templateUrl: './sign-off-popup.component.html',
    styleUrls: ['./sign-off-popup.component.scss'],
})
export class SignOffPopupComponent extends BaseComponent {
    loading: boolean;
    id: number;
    signOffForm: UntypedFormGroup;
    subsystemsArr: string[];
    contract: string;
    constructor(
        public dialogRef: MatDialogRef<SignOffPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: ToastService,
        private changeManagementService: ChangeManagementService
    ) {
        super();
        this.id = data.id;
        this.subsystemsArr = [...(data.subsystems as string[])];
        this.subsystemsArr.length > 0 ? this.subsystemsArr.unshift('All') : this.subsystemsArr;
        this.contract = data.contract;
        this.signOffForm = new UntypedFormGroup({ subsystems: new UntypedFormControl('', Validators.required) });
    }

    selectionChange(change: MatOptionSelectionChange) {
        if (change.source.value === 'All' && change.isUserInput) {
            if (change.source.selected) {
                this.signOffForm.controls['subsystems'].setValue(this.subsystemsArr);
            } else {
                this.signOffForm.controls['subsystems'].setValue([]);
            }
        } else if (change.isUserInput && !change.source.selected) {
            let sub = this.signOffForm.controls['subsystems'].value;
            if (sub && sub.indexOf('All') > -1) {
                let filteredVal = sub.filter((s: string) => s !== 'All' && s !== change.source.value);
                this.signOffForm.controls['subsystems'].setValue(filteredVal);
            }
        }
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        let form = new SignOffRequest();
        form.id = this.id;
        form.subsystems = this.signOffForm.controls.subsystems.value.filter((s: string) => s !== 'All');
        form.contract = this.contract;
        this.changeManagementService.signOff(form).subscribe(
            (data) => {
                this.loading = false;
                this.toastService.Success('Field implementation successfully signed off.');
                this.dialogRef.close({ changeDoc: data });
            },
            (result) => {
                this.loading = false;
                if (result.status === 400) {
                    this.toastService.Error(result.error);
                } else {
                    this.toastService.Error(
                        'Error occurred while signing off of field implementation. Please contact Program Administrator.'
                    );
                }
            }
        );
    }
}
