import { Component, Inject } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { SignOffRequest } from 'src/app/models/sing-off-request';
import { ValidationFormService } from 'src/app/services/api/webapi-services/validation-form.service';
import { AddSubsystemsForSignOffRequest } from 'src/app/models/add-subsystems-for-sing-off-request';

@Component({
    selector: 'app-subsystem-ready-for-sign-off-popup',
    templateUrl: './subsystem-ready-for-sign-off-popup.html',
    styleUrls: ['./subsystem-ready-for-sign-off-popup.scss'],
})
export class SubsystemReadyForSignOffPopupComponent extends BaseComponent {
    loading: boolean;
    id: number;
    validId: number;
    signOffForm: UntypedFormGroup;
    subsystemsArr: string[];
    constructor(
        public dialogRef: MatDialogRef<SubsystemReadyForSignOffPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: ToastService,
        private validationFormService: ValidationFormService
    ) {
        super();
        this.id = data.id;
        this.validId = data.validid
        this.subsystemsArr = [...(data.subsystems as string[])];
        this.signOffForm = new UntypedFormGroup({ subsystems: new UntypedFormControl('', Validators.required) });
    }

    selectionChange(change: MatOptionSelectionChange) {
        if (change.source.value === 'All' && change.isUserInput) {
            if (change.source.selected) {
                this.signOffForm.controls['subsystems'].setValue(this.subsystemsArr);
            } else {
                this.signOffForm.controls['subsystems'].setValue([]);
            }
        } else if (change.isUserInput && !change.source.selected) {
            let sub = this.signOffForm.controls['subsystems'].value;
            if (sub && sub.indexOf('All') > -1) {
                let filteredVal = sub.filter((s: string) => s !== 'All' && s !== change.source.value);
                this.signOffForm.controls['subsystems'].setValue(filteredVal);
            }
        }
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        let form = new AddSubsystemsForSignOffRequest();
        form.changeDocId = this.id;
        form.validId = this.validId;
        form.subsystems = this.signOffForm.controls.subsystems.value.filter((s: string) => s !== 'All');
        this.validationFormService.signOff(form).subscribe(
            (data) => {
                this.loading = false;
                this.toastService.Success('Selected Subsystem is ready for sign-off.');
                this.dialogRef.close({ validDocHeader: data });
            },
            (result) => {
                this.loading = false;
                if (result.status === 400) {
                    this.toastService.Error(result.error);
                } else {
                    this.toastService.Error(
                        'Error occurred while making subsystem ready for sign-off. Please contact Program Administrator.'
                    );
                }
            }
        );
    }
}
