import * as moment from 'moment';
import { Waypoint, Milestone, Contractor, Gooc, RFO, WorkAreaWorkerDTO, StagedStartUpPriority } from '../../common.model';
import { initWeekRange, getCurrentWeekStartDate, initMonthRange } from 'src/app/extensions';
import { WeekType } from 'src/app/enums';

export const PLANNING_LOOKAHEAD_NUM_OF_PAST_WEEKS = 4;
export const PLANNING_LOOKAHEAD_NUM_OF_UPCOMING_WEEKS = 6;
export const PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT = 'YYYY-MM-DD';
export const PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_WEEKS = 6;
export const PLANNING_COMMITMENT_RELIABILITY_NUM_OF_UPCOMING_WEEKS = 2;
export const PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_MONTH = 6;
export const PLANNING_COMMITMENT_RELIABILITY_NUM_OF_UPCOMING_MONTH = 2;

export class PlanningLookaheadDTO {
    projectTeamName: string;
    forecastHeader: string;
    plan?: moment.Moment;
    actual?: moment.Moment;
    forecast?: moment.Moment;
}

export class PlanningCommitmentReliabilityDTO {
    subsystem: string;
    isSubmitted?: boolean;
    mcActual?: moment.Moment;
    rfsuActual?: moment.Moment;
    itrCompleted?: moment.Moment;
    committedForWeek?: moment.Moment;
    id?: number;
    isSubsystemNotInWeek?: boolean;
}

export class WeeklyPlanningCommitmentDTO {
    planningCommitment: PlanningCommitmentReliabilityDTO[];
    planValue: PlanValueCountByWeek[];
}

export class PlanValueCountByWeek {
    count: number;
    date: moment.Moment;;
}
export class PlanningFilter {
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    startDate: string;
    endDate: string;
    mcEngineer: string[] = [];
    systemOwner: string[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    scManager: string[] = [];
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
    workAreas: any[] = [];
    disciplines: any[] = [];
    nbDisciplineLeads: WorkAreaWorkerDTO[] = [];
    nbEngineers: WorkAreaWorkerDTO[] = [];
    tcoAreaCoordinators: WorkAreaWorkerDTO[] = [];
    areas: string[] = [];
    subsystems: { id: string; name: string }[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
}

export class PlanningLookaheadCounters {
    planCount: number;
    actualCount: number;
    forecastCount: number;
    weekType: WeekType;
}

export interface PlanningLookaheadReportDataItem {
    projectTeamName: string;
    forecastHeader: string;
    countersByWeek: PlanningLookaheadCounters[];
}

export interface PlanningLookaheadTotalItem {
    planTotal: number;
    actualTotal?: number;
    forecastTotal?: number;
    weekType: WeekType;
}

export interface PlanningCommitmentReliabilityTotalItem {
    greenCount: number;
    redCount: number;
    greyCount: number;
    percentage: number;
    blackCount: number;
    orangeCount: number;
    blueCount: number;
    weekStart: moment.Moment
}
export class PlanningLookaheadState {
    lookaheadType = '';
    filter = new PlanningFilter();
    weeks = initWeekRange(
        getCurrentWeekStartDate().add(-PLANNING_LOOKAHEAD_NUM_OF_PAST_WEEKS, 'weeks'),
        PLANNING_LOOKAHEAD_NUM_OF_PAST_WEEKS + PLANNING_LOOKAHEAD_NUM_OF_UPCOMING_WEEKS
    );
    isLoading = false;
    data: PlanningLookaheadReportDataItem[] = [];
    giTotals: PlanningLookaheadTotalItem[] = [];
    gpTotals: PlanningLookaheadTotalItem[] = [];
    grandTotals: PlanningLookaheadTotalItem[] = [];
}

export class PlanningCommitmentReliabilityState {
    commitmentReliabilityType = '';
    filter = new PlanningFilter();
    weeks = initWeekRange(
        getCurrentWeekStartDate().add(-PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_WEEKS, 'weeks'),
        PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_WEEKS + PLANNING_COMMITMENT_RELIABILITY_NUM_OF_UPCOMING_WEEKS
    );
    months = initMonthRange(
        getCurrentWeekStartDate().add(-PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_MONTH, 'months'),
        PLANNING_COMMITMENT_RELIABILITY_NUM_OF_PAST_MONTH + PLANNING_COMMITMENT_RELIABILITY_NUM_OF_UPCOMING_MONTH
    );
    isLoading = false;
    data: PlanningCommitmentReliabilityTotalItem[] = [];
    planViewEnabled = true;
    monthViewEnabled = false;
}

export interface PlanningState {
    lookahead: PlanningLookaheadState;
    commitmentReliability: PlanningCommitmentReliabilityState;
}
