import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, OrderDirection } from '../common.model';

export interface CTARegisterState {
    dataPagination: CTARegisterDataPagination;
    filter: CTARegisterFilter;
    isLoading: boolean;
}

export class CTARegisterDataPagination {
    items: CTARegisterDTO[] = [];
    totalCount = 0;
}

export class CTARegisterFilter {
    page = 0;
    take = 25;
    sortBy = 'fileName';
    direction = OrderDirection.Desc;
    columnFileName: CheckListColumn = null;
    columnDescription: CheckListColumn = null;
    columnArea: CheckListColumn = null;
    columnDate: CalendarColumn = null;
}

export class CTARegisterDTO {
    id: number = null;
    fileName: string = '';
    area: string = '';
    areaId: number = null;
    description: string = '';
    date: moment.Moment = null;
    attachments: CTAAttachment[] = [];
    selected? = false;
}

export class CTAFormGroup {
    area: string = '';
    areaId: number = null;
    description: string = '';
    date: moment.Moment = null;
    fileName: string = '';
    attachments: CTAAttachment[] = [];
    isEdit = false;
}
export class CTAAttachment {
    name: string;
    link: string;
}

export class Attachment {
    id: number;
    name = '';
    link = '';
    loading: boolean;
    file: File;
    isValid: boolean;
    isBeingProcessed: boolean;
}
