import { FlangeManagementState} from './model';
import { FlangeManagementActions, FlangeManagementActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: FlangeManagementState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: FlangeManagementActions) {
    switch (action.type) {
        case FlangeManagementActionTypes.FlangeManagementUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementFileUploaded:
        case FlangeManagementActionTypes.FlangeManagementFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'FlangeManagement';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        case FlangeManagementActionTypes.FlangeManagementSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementValidateDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
                isValidateButtonEnabled: false,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementValidateDeltaError:
        case FlangeManagementActionTypes.FlangeManagementValidateDeltaSuccess: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementDownloadOutputDataRequest:
        case FlangeManagementActionTypes.FlangeManagementTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementTemplateFileError:
        case FlangeManagementActionTypes.FlangeManagementDownloadOutputDataSuccess:
        case FlangeManagementActionTypes.FlangeManagementDownloadOutputDataError:
        case FlangeManagementActionTypes.FlangeManagementTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case FlangeManagementActionTypes.FlangeManagementValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
            };
        }
        default:
            return state;
    }
}