import { Injectable } from '@angular/core';
import { Config, DurationConfig } from 'src/app/models/config';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { ConstraintSelectionMode, ScheduleActivityPlanningConstraint } from 'src/app/components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import { BacklogCat } from 'src/app/components/admin-section/scmt/backlog-categories/backlog-categories.component';
import { ConstraintFlagDto } from 'src/app/models/constraintflag-dto';
import { AutomaticUploadTrigger } from 'src/app/components/admin-section/scmt/automated-file-transfers-mcplus/automatic-uploads/time-triggers/time-triggers.component';
import { PliImplementationTeam } from 'src/app/models/pli-implementation-team';
import { ResponsibleGroup } from 'src/app/models/responsible-group';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly configurationEndpoint: string = '/configuration';

    constructor(private http: HttpClient) {}

    getConfigData() {
        return this.http.get<Config[]>(`${this.rootUrl}${this.configurationEndpoint}`);
    }

    getDurationConfigData() {
        return this.http.get<DurationConfig[]>(`${this.rootUrl}${this.configurationEndpoint}/duration`);
    }

    getFTPConfigData() {
        return this.http.get<Config[]>(`${this.rootUrl}${this.configurationEndpoint}/ftp`);
    }

    testFTPConnection(ftpConfigData: Config[]) {
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/ftp/test`, ftpConfigData);
    }

    saveFTPConfiguration(ftpConfigData: Config[]) {
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/ftp/save`, ftpConfigData);
    }

    updateConfigData(configData: Config[]) {
        return this.http.patch(`${this.rootUrl}${this.configurationEndpoint}`, configData);
    }

    updateDurationConfigData(config: DurationConfig) {
        return this.http.patch(`${this.rootUrl}${this.configurationEndpoint}/duration`, config);
    }

    getTagsSourceFileConfiguration() {
        return this.http.get<Config[]>(`${this.rootUrl}${this.configurationEndpoint}/sourcefileconfiguration/tags`);
    }

    getConstraintSelectionMode() {
        return this.http.get<ConstraintSelectionMode>(`${this.rootUrl}${this.configurationEndpoint}/constraintselectionmode`);
    }

    updateConstraintSelectionMode(mode: string) {
        return this.http.patch(`${this.rootUrl}${this.configurationEndpoint}/constraintselectionmode?mode=${mode}`, {});
    }

    getCleanlinessConfigData(type: string){
        return this.http.get<Config[]>(`${this.rootUrl}${this.configurationEndpoint}/cleanliness/${type}`);
    }

    getHVACSubsystemsConfigData(){
        return this.http.get<any[]>(`${this.rootUrl}${this.configurationEndpoint}/hvacsubsystems`);
    }

    updateHVACSubsystemsConfigData(subsystems: string[]){
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/hvacsubsystems`, subsystems);
    }

    updateBacklogCat(entity: BacklogCat) {
        return this.http.post<BacklogCat>(`${this.rootUrl}${this.configurationEndpoint}/backlogCat`, entity);
    }

    deleteBacklogCat(id: number) {
        return this.http.delete<number>(`${this.rootUrl}${this.configurationEndpoint}/backlogCat/${id}`);
    }

    updateConstraintFlag(entity: ConstraintFlagDto) {
        return this.http.post<BacklogCat>(`${this.rootUrl}${this.configurationEndpoint}/constraintflag`, entity);
    }

    deleteConstraintFlag(id: number) {
        return this.http.delete<number>(`${this.rootUrl}${this.configurationEndpoint}/constraintflag/${id}`);
    }

    updateConstraintType(entity: ScheduleActivityPlanningConstraint) {
        return this.http.post<ScheduleActivityPlanningConstraint>(`${this.rootUrl}${this.configurationEndpoint}/constrainttype`, entity);
    }

    deleteConstraintType(id: number) {
        return this.http.delete<number>(`${this.rootUrl}${this.configurationEndpoint}/constrainttype/${id}`);
    }

    updatePliImplementationTeam(entity: PliImplementationTeam) {
        return this.http.post<PliImplementationTeam>(`${this.rootUrl}${this.configurationEndpoint}/implementationteam`, entity);
    }

    deletePliImplementationTeam(id: number) {
        return this.http.delete<number>(`${this.rootUrl}${this.configurationEndpoint}/implementationteam/${id}`);
    }

    updateResponsibleGroup(entity: ResponsibleGroup) {
        return this.http.post<ResponsibleGroup>(`${this.rootUrl}${this.configurationEndpoint}/responsibleGroup`, entity);
    }

    deleteResponsibleGroup(id: number) {
        return this.http.delete<number>(`${this.rootUrl}${this.configurationEndpoint}/responsibleGroup/${id}`);
    }

    getAllResponsbileGroup() {
        return this.http.get<{ name: string; id: number }[]>(`${this.rootUrl}${this.configurationEndpoint}/responsibleGroupAll`);
    }
}
