<div class="chart-container">
    <mat-expansion-panel #chartPanel>
        <mat-expansion-panel-header></mat-expansion-panel-header>
        <div class="range-selection-container">
            <mat-form-field appearance="outline" class="datepicker-container">
                <input matInput [formControl]="dateRangePickerControlFormatted" />
                <input
                    matInput
                    [matDatepicker]="dateRangePicker"
                    [matDatepickerFilter]="dateRangePickerFilter"
                    [formControl]="dateRangePickerControl"
                    hidden
                />
                <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                <mat-datepicker
                    #dateRangePicker
                    [startView]="'month'"
                    disabled="false"
                ></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="radio-container">
            <mat-radio-group (change)="chartTypeChange($event)" aria-label="Chart Data switch">
                    <mat-radio-button [value]="ChartType.Activities" [checked]="true" class="margin-right40">Activities</mat-radio-button>
                    <mat-radio-button [value]="ChartType.ITR" class="margin-right40">ITR</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="line-chart-container">
            <ngx-charts-line-chart
                [view]="[1110, 300]"
                [scheme]="colorScheme"
                [results]="lineChartData"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [roundDomains]="true"
                [yScaleMin]="0"
                [yScaleMax]="maxValue"
                [legendPosition]="'right'"
                [legend]="true"
                [legendTitle]="null"
            >
            </ngx-charts-line-chart>
        </div>
    </mat-expansion-panel>
</div>
