import { PLIState } from './model';
import { PLIActions, PLIActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: PLIState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isDataReadyToProcess: false,
    recordsForDeletion: 0,
    isAutomaticUploadInProgress: true,
};

export function reducer(state = initialState, action: PLIActions) {
    switch (action.type) {
        case PLIActionTypes.PLIUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case PLIActionTypes.PLIUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case PLIActionTypes.PLIUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case PLIActionTypes.PLISetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case PLIActionTypes.PLIFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case PLIActionTypes.PLIClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case PLIActionTypes.PLITemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case PLIActionTypes.PLITemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case PLIActionTypes.PLITemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case PLIActionTypes.PLIValidateDeltaRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case PLIActionTypes.PLIValidateDeltaSuccess: {
            return {
                ...state,
                isLoading: false,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case PLIActionTypes.PLIValidateDeltaError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case PLIActionTypes.PLIDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case PLIActionTypes.PLIDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case PLIActionTypes.PLIDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case PLIActionTypes.PLIAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'PLI';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        case PLIActionTypes.PLIValidateButtonStateSuccess: {
            return {
                ...state,
                isDataReadyToProcess: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
                isAutomaticUploadInProgress: action.payload.isAutomaticUploadInProgress,
            };
        }
        default:
            return state;
    }
}
