<div class="notification-container" *ngIf="items?.length > 0">
    <div class="notification-header" (click)="toggle()" [ngClass]="{ 'disconnected': !connected }">
        <div class="notification-header-label">
            <span>
                {{ collectionName }}
            </span>
            <mat-icon *ngIf="showError && !expanded" class="header-attention" svgIcon="attention"></mat-icon>
        </div>
        <mat-icon class="notification-header-icon" [ngClass]="{ 'header-icon-rotated': expanded }">
            keyboard_control_key
        </mat-icon>
    </div>
    <div class="notification-list" *ngIf="expanded">
        <div class="notification-item" [ngClass]="classes[item.status]" *ngFor="let item of items">
            <span class="notification-item-title" matTooltip="{{ tooltips[item.status] }}">
                {{ item.fileName }}
            </span>
            <mat-icon *ngIf="item.status==='Success'" class="clickable-icon"
              (click)="download(item)" matTooltip="Download" svgIcon="downloadcircle">
            </mat-icon>

            <mat-icon *ngIf="item.status=='New'" class="icon-progress"
              matTooltip="{{ item.status }}" svgIcon="loadingcircle">
            </mat-icon>

            <mat-icon *ngIf="item.status=='Error'" matTooltip="{{ item.status }}"
              svgIcon="errorcircle">
            </mat-icon>
        </div>
    </div>
</div>