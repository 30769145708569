<div class="systemisation-progress-container">
    <h4>systemisation progress</h4>
    <div class="chart-container">
        <div *ngIf="totalChangeDocuments$ | async" class="progress-bar-chart-container">
            <div app-progress-bar [value]="completed"></div>
            <div class="progress-bar-footer">
                <div class="label">0</div>
                <div class="label center">Completed</div>
                <div class="label right">{{ totalChangeDocuments$ | async }}</div>
            </div>
        </div>
        <div *ngIf="systemisationProgressData.length" class="systemisation-progress-chart-container">
            <ngx-charts-bar-horizontal-stacked
                [results]="systemisationProgressData"
                [scheme]="colorScheme"
                [xAxis]="true"
                [xAxisTickFormatting]="formatPercentage"
                [yAxis]="true"
                [view]="[300, 150]"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [showGridLines]="false"
            >
                <ng-template #tooltipTemplate let-model="model">
                    <span>{{ model.series }} &#9679; {{ model.name }}</span
                    ><br />
                    <span class="tooltip-value">{{ getTooltipValue(model.value, model.series) }}</span>
                </ng-template>
            </ngx-charts-bar-horizontal-stacked>
            <div class="legend">
                <div class="completed">completed: {{ getLegendValue('completed') }}%</div>
                <div class="incomplete">incomplete: {{ getLegendValue('incomplete') }}%</div>
                <div class="open">open: {{ getLegendValue('open') }}%</div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading$ | async" class="systemisation-progress-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
