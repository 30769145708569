<main>
    <form>
        <span class="close" (click)="close()">&times;</span>
        <p>do you want to delete this comment?</p>
        <div class="buttons-container flex center">
            <button type="button" (click)="close()" class="sct-button sct-button-white">cancel</button>
            <button type="button" (click)="onConfirmation()" class="sct-button sct-button-light ok-button">
                confirm
            </button>
        </div>
    </form>
</main>
