<main class="main">
    <a class="navigator" [routerLink]="['/admin']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">automatic uploads</div>
        <div class="buttons-container flex" *ngIf="activeNavLink?.label !== 'pipeline' && activeNavLink?.label !== 'Status Update'">
            <div matTooltip="You cannot validate the data while automatic upload pipeline is in progress"
                [matTooltipDisabled]="!(isAutomaticUploadInProgress && !isValidateButtonDisabled)">
                <button
                    [disabled]="isValidateButtonDisabled || isAutomaticUploadInProgress"
                    class="sct-button sct-button-light margin-right20 validate-button"
                    (click)="validateDelta()"
                >
                    validate & push
                    <span>{{ activeNavLink && activeNavLink.label }}</span>
                </button>
            </div>
            <div matTooltip="You cannot upload the data while automatic upload pipeline is in progress"
                [matTooltipDisabled]="!isAutomaticUploadInProgress">
                <button
                    *ngIf="activeNavLink && activeNavLink.type !== 'IMDB' && activeNavLink.type !== 'PriorityNumbers'"
                    type="button"
                    [disabled]="isAutomaticUploadInProgress"
                    class="sct-button sct-button-light margin-right20"
                    (click)="importFile.click($event)"
                >
                    upload
                </button>
            </div>
            <input
                multiple
                #importFile
                type="file"
                (click)="importFile.value = null"
                (change)="fileChangeEvent($event.target.files)"
            />
            <button
                *ngIf="isAdmin"
                type="button"
                class="sct-button sct-button-light margin-right20"
                (click)="downloadLiveFile()"
            >
                download live data
            </button>
        </div>
    </div>
    <nav mat-tab-nav-bar class="tab-nav-px15">
        <a
            [ngClass]="{ 'in-progress': inProgressObservables[link.index] | async }"
            mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
        >
            {{ link.label }}
        </a>
    </nav>
    <router-outlet></router-outlet>
    <app-loading-indicator *ngIf="(uploadInProgress$ | async) || isConfigLoading"> </app-loading-indicator>
</main>
