import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { throwError } from 'rxjs';
import { finalize, catchError, takeWhile, take } from 'rxjs/operators';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { WorkAreaService } from 'src/app/services/api/webapi-services/work-area.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { WorkAreaValidators } from 'src/app/validators/work-area.validators';
import { BaseComponent } from '../../base.component';
import { WorkAreaDTO } from '../../../store/common.model';
import * as _ from 'lodash';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import * as moment from 'moment';

@Component({
    selector: 'app-sc-work-area-configuration',
    templateUrl: './sc-work-area-configuration.component.html',
    styleUrls: ['./sc-work-area-configuration.component.scss'],
})
export class SCWorkAreaConfigurationComponent extends BaseComponent implements OnInit {
    addTitle = 'add new work area';
    addText = 'create';
    updateTitle = 'edit work area';
    updateText = 'update';
    loading = false;
    scWorkAreaForm: UntypedFormGroup;
    scWorkAreaFormInitialForm = new WorkAreaDTO();
    isInEditMode = false;
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWorkAreaInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    updatedProperties: string[] = [];

    constructor(
        private lookupService: LookupService,
        private toastService: ToastService,
        private workAreaService: WorkAreaService,
        private formService: FormService,
        private workAreaValidators: WorkAreaValidators,
        private changeDetectorRef: ChangeDetectorRef,
        private popupService: PopupService
    ) {
        super();
        this.scWorkAreaForm = this.formService.createForm(new WorkAreaDTO(), this.workAreaValidators);
    }

    ngOnInit() {
        this.scWorkAreaForm.controls.workAreaName.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((value) => {
                if (value.length === 0) {
                    return;
                }
                this.setSubsystemInput.emit();
                if (!value[0]?.Manual) {
                    this.loading = true;
                    this.workAreaService
                        .getWorkAreaByName(value[0].id)
                        .pipe(
                            take(1),
                            finalize(() => (this.loading = false)),
                            catchError((err) => {
                                this.toastService.Error(
                                    'Error has occurred while getting Work Area. Please contact Program Administrator.'
                                );
                                return throwError(err);
                            })
                        )
                        .subscribe((data) => {
                            let form = {
                                disciplines: data.disciplines ? data.disciplines : [],
                                workAreaName: data.workAreaName ? [{ id: data.workAreaName }] : [],
                                nbEngineer: data.nbEngineer ? [{ id: data.nbEngineer }] : [],
                                nbDisciplineLead: data.nbDisciplineLead ? [{ id: data.nbDisciplineLead }] : [],
                                tcoAreaCoordinator: data.tcoAreaCoordinator ? [{ id: data.tcoAreaCoordinator }] : [],
                                workAreaSubsystems: data.workAreaSubsystems,
                            };
                            this.scWorkAreaForm.patchValue(form, { emitEvent: false });
                            this.isInEditMode = true;
                            this.setInitialForm();
                            this.changeDetectorRef.detectChanges();
                        });
                } else {
                    this.isInEditMode = false;
                    this.scWorkAreaForm.controls.disciplines.setValue([]);
                    this.scWorkAreaForm.controls.nbEngineer.setValue([]);
                    this.scWorkAreaForm.controls.nbDisciplineLead.setValue([]);
                    this.scWorkAreaForm.controls.tcoAreaCoordinator.setValue([]);
                }
            });
        this.watchFormChanges();
    }

    watchFormChanges() {
        for (const key of Object.keys(this.scWorkAreaForm.controls)) {
            this.scWorkAreaForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                const updatedProperties = this.getUpdatedProperties();
                if (Array.isArray(value)) {
                    value = Object.assign([], value);
                }
                this.updatedProperties = updatedProperties;
            });
        }
    }

    clearFormValues() {
        this.isInEditMode = false;
        this.scWorkAreaForm.controls.workAreaName.setValue([]);
        this.scWorkAreaForm.controls.disciplines.setValue([]);
        this.scWorkAreaForm.controls.nbEngineer.setValue([]);
        this.scWorkAreaForm.controls.nbDisciplineLead.setValue([]);
        this.scWorkAreaForm.controls.tcoAreaCoordinator.setValue([]);
        this.scWorkAreaForm.controls.workAreaSubsystems.setValue([]);
    }

    getUpdatedProperties(): string[] {
        const result = [];
        if (!this.isNotNullAndNotUndefined(this.scWorkAreaFormInitialForm.workAreaName)) {
            return result;
        }
        for (const key of Object.keys(this.scWorkAreaForm.controls)) {
            if (
                (!this.isNotNullAndNotUndefined(this.scWorkAreaFormInitialForm[key]) ||
                    this.scWorkAreaFormInitialForm[key] === '') &&
                (!this.isNotNullAndNotUndefined(this.scWorkAreaForm.controls[key].value) ||
                    this.scWorkAreaForm.controls[key].value === '')
            ) {
                continue;
            }
            if (
                !this.compareArraysOfObjectsByProperty(
                    this.scWorkAreaFormInitialForm[key],
                    this.scWorkAreaForm.controls[key].value,
                    'id'
                )
            ) {
                result.push(key);
            }
        }
        return result;
    }

    compareArraysOfObjectsByProperty(array1: {}[], array2: {}[], property: string) {
        if (!Array.isArray(array1) || !Array.isArray(array2) || array1.length !== array2.length) return false;

        const a1 = _.sortBy(array1, property);
        const a2 = _.sortBy(array2, property);
        var result = true;

        a1.forEach((element, i) => {
            result = result && element[property] === a2[i][property];
        });
        return result;
    }

    setInitialForm() {
        if (this.isNotNullAndNotUndefined(this.scWorkAreaForm.value.workAreaName)) {
            for (const key of Object.keys(this.scWorkAreaForm.controls)) {
                this.scWorkAreaFormInitialForm[key] = JSON.parse(
                    JSON.stringify(this.scWorkAreaForm.controls[key].value)
                );
            }
        }
        this.updatedProperties = [];
    }

    setSubsystems(subsystems: any[]) {
        this.scWorkAreaForm.controls.workAreaSubsystems.setValue(subsystems, { emitEvent: false });
        const updatedProperties = this.getUpdatedProperties();
        this.updatedProperties = updatedProperties;
    }

    getWorkArea = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWorkAreas(search, take, page);
    };

    getDiscipline = (search = '', take = 10, page = 0) => {
        return this.lookupService.getActivityDiscipline(search, take, page);
    };

    getNBEngineer = (search = '', take = 10, page = 0) => {
        return this.lookupService.getNBEngineer(search, take, page);
    };

    getNBDisciplineLead = (search = '', take = 10, page = 0) => {
        return this.lookupService.getNBDisciplineLead(search, take, page);
    };

    getTCOAreaCoordinator = (search = '', take = 10, page = 0) => {
        return this.lookupService.getTCOAreaCoordinator(search, take, page);
    };

    onConfirm() {

        let entity = new WorkAreaDTO();
        entity.workAreaName = this.scWorkAreaForm.controls.workAreaName.value[0]?.id;
        entity.workAreaSubsystems = this.scWorkAreaForm.controls.workAreaSubsystems.value;
        entity.disciplines = this.scWorkAreaForm.controls.disciplines.value;
        entity.nbDisciplineLead = this.scWorkAreaForm.controls.nbDisciplineLead.value[0]?.id;
        entity.nbEngineer = this.scWorkAreaForm.controls.nbEngineer.value[0]?.id;
        entity.tcoAreaCoordinator = this.scWorkAreaForm.controls.tcoAreaCoordinator.value[0]?.id;

        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: `${this.isInEditMode ? this.updateText : this.addText} work area`,
                    text: `Are you sure you want to ${this.isInEditMode ? this.updateText : this.addText} '${
                        entity.workAreaName
                    }' work area?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.loading = true;
                    if (this.isInEditMode) {
                        this.workAreaService
                            .updateWorkArea(entity)
                            .pipe(
                                takeWhile(() => this.isAlive),
                                finalize(() => (this.loading = false)),
                                catchError((err) => {
                                    this.toastService.Error(
                                        'Error has occurred while updating Work Area. Please contact Program Administrator.'
                                    );
                                    return throwError(err);
                                })
                            )
                            .subscribe(() => {
                                this.toastService.Success('Work Area updated correctly.');
                                this.isInEditMode = true;
                                this.setInitialForm();
                            });
                    } else {
                        this.workAreaService
                            .addWorkArea(entity)
                            .pipe(
                                takeWhile(() => this.isAlive),
                                finalize(() => (this.loading = false)),
                                catchError((err) => {
                                    this.toastService.Error(
                                        'Error has occurred while creating new Work Area. Please contact Program Administrator.'
                                    );
                                    return throwError(err);
                                })
                            )
                            .subscribe(() => {
                                this.toastService.Success('Work Area added correctly.');
                                this.isInEditMode = true;
                                this.setInitialForm();
                            });
                    }
                }
            });
    }

    onDelete(workAreaName: string) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'delete work area',
                    text: `Are you sure you want to delete '${workAreaName}' work area?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.loading = true;
                    this.workAreaService
                        .deleteWorkAreaByName(workAreaName)
                        .pipe(
                            takeWhile(() => this.isAlive),
                            catchError((err) => {
                                this.toastService.Error(
                                    'Error has occurred while deleting Work Area. Please contact Program Administrator.'
                                );
                                return throwError(err);
                            })
                        )
                        .pipe(
                            takeWhile(() => this.isAlive === true),
                            finalize(() => (this.loading = false))
                        )
                        .subscribe((deleted) => {
                            if (deleted) {
                                this.toastService.Success('Work Area has been deleted.');
                                this.clearFormValues();
                                this.setInitialForm();
                                this.setWorkAreaInput.emit(new SetInputEventArgs(false, ''));
                            } else {
                                this.toastService.Error('Work Area with the given name was not found.');
                            }
                        });
                }
            });
    }

    generateexcel(){
        this.loading = true;
        this.workAreaService
        .generateexcel()
        .pipe(
            takeWhile(() => this.isAlive),
            catchError((err) => {
                this.toastService.Error(
                    'Error has occurred while exporting Work Area. Please contact Program Administrator.'
                );
                return throwError(err);
            })
        )
        .pipe(
            takeWhile(() => this.isAlive === true),
            finalize(() => (this.loading = false))
        )
        .subscribe((data: any) => {
            const blob = new Blob([data], {
                type: 'application/octet-stream',
            });
            saveAs(blob, `WorkAreas_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        });
    }

    onDuplicate(workAreaName: string) {
        this.loading = true;
        this.workAreaService
            .getWorkAreaByName(workAreaName)
            .pipe(
                take(1),
                finalize(() => (this.loading = false)),
                catchError((err) => {
                    this.toastService.Error(
                        'Error has occurred while getting Work Area. Please contact Program Administrator.'
                    );
                    return throwError(err);
                })
            )
            .subscribe((data) => {
                let form = {
                    workAreaSubsystems: data.workAreaSubsystems,
                };
                this.scWorkAreaForm.patchValue(form, { emitEvent: false });
                this.isInEditMode = true;
                this.setInitialForm();
                this.changeDetectorRef.detectChanges();
            });

        this.scWorkAreaForm.controls.workAreaName.setValue([]);
        this.scWorkAreaForm.controls.disciplines.setValue([]);
        this.scWorkAreaForm.controls.nbEngineer.setValue([]);
        this.scWorkAreaForm.controls.nbDisciplineLead.setValue([]);
        this.scWorkAreaForm.controls.tcoAreaCoordinator.setValue([]);
    }

    get getCustomActions() {
        return [
            {
                name: 'duplicate',
                icon: 'duplicate',
                action: (data: any) => {
                    this.onDuplicate(data.id);
                },
            },
            {
                name: 'delete',
                icon: 'delete_gray',
                action: (data: any) => {
                    this.onDelete(data.id);
                },
            },
        ];
    }
}
