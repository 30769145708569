<div class="popup">
    <div class="icon-remove" (click)="onCancel()"></div>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">Expected Time Of Completion History</span>
    </p>
    <mat-dialog-content>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="oldExpectedTimeOfCompletion">
                <th mat-header-cell *matHeaderCellDef>Old ETC</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.oldExpectedTimeOfCompletion != null ? (element.oldExpectedTimeOfCompletion | date: 'd/MMM/yy') : "TBD" }}
                    </td>
            </ng-container>

            <ng-container matColumnDef="newExpectedTimeofCompletion">
                <th mat-header-cell *matHeaderCellDef>New ETC</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.newExpectedTimeOfCompletion != null ? (element.newExpectedTimeOfCompletion | date: 'd/MMM/yy') : "TBD" }}
                     </td>
            </ng-container>
           
            <ng-container matColumnDef="updatedDate">
                <th mat-header-cell *matHeaderCellDef>Update date</th>
                <td mat-cell *matCellDef="let element">{{ element.updatedDate | date: 'd/MMM/y' }}</td>
            </ng-container>

            <ng-container matColumnDef="userFullName">
                <th mat-header-cell *matHeaderCellDef>changed by</th>
                <td mat-cell *matCellDef="let element">{{ element.userFullName }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-dialog-content>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</div>
