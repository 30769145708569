import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../base.component';
import { CriticalRFIManualPriorityHistoryRequest } from 'src/app/store/manual-priority-history/actions';
import { PostRFOResponsiblePersonOrGroupHistoryRequest, PostRFOResponsiblePersonOrGroupHistorySuccess } from 'src/app/store/data-update-history/actions';
import { DetailedStatusDTO } from 'src/app/store/detailed-status/model';

@Component({
  selector: 'app-data-update-history',
  templateUrl: './data-update-history.component.html',
  styleUrls: ['./data-update-history.component.scss']
})
export class DataUpdateHistoryComponent extends BaseComponent implements OnInit {
  displayedColumns: string[] = ['oldData', 'newData', 'updatedDate', 'userFullName'];
  recordUpdateType= '';
  historyHeader='';
  dataSource = new MatTableDataSource();
  isLoading = false;
  recordType$=this.store.select((state) => state.dataUpdateHistoryState.recordType);
  responsiblePersonOrGroupHistory$ = this.store.select((state) => state.dataUpdateHistoryState.responsiblePersonOrGroupData);
  isLoading$ = this.store.select((state) => state.dataUpdateHistoryState.isLoading);
  itemNumber: string;
  exceptionNumber: string = null;
  rfo: string;
  subsystem: string;
  postRFOData: DetailedStatusDTO;
  constructor(public dialogRef: MatDialogRef<DataUpdateHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private store: Store<ApplicationState>) {
    super();

    this.itemNumber = data.itemNumber;
    this.exceptionNumber = data.exceptionNumber;
    this.rfo = data.rfo;
    this.subsystem = data.subsystem;
    this.recordUpdateType = data.recordUpdateType;
    this.postRFOData = data.postRFOData;
  }

  ngOnInit(): void {
    this.historyHeader = this.recordUpdateType.toLocaleLowerCase()=='responsiblepersonorgroup'? 'ResponsiblePersonOrGroup' : 'DataUpdateHistory';

    this.responsiblePersonOrGroupHistory$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data)
      });

    this.isLoading$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((loading) => this.isLoading = loading);

    this.store.dispatch(
      new PostRFOResponsiblePersonOrGroupHistoryRequest(
        {
          postRFOData: this.postRFOData
        }));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

}
