import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';

@Component({
    selector: 'app-team-data',
    templateUrl: './team-data.component.html',
    styleUrls: ['./team-data.component.scss'],
})
export class TeamDataComponent extends BaseComponent implements OnInit {
    ngOnInit(): void {
        // This is intentional
    }
}
