import { Component, Input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import {
    DcnRfiRedlineRegisterFilter,
    DcnRfiRegisterDto,
    DcnRfiRegisterResultDto,
    RedlineRegisterDto,
    RedlineRegisterResultDto,
    RegisterType,
    ShowHideColumns,
} from 'src/app/store/dcn-rfi-redline/model';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../../base.component';
import { takeWhile } from 'rxjs/operators';
import {
    DcnRfiRedlineRegisterAddCommentRequest,
    DcnRfiRedlineRegisterFilterPropertyUpdate,
    DcnRfiRegisterDataRequest,
    dcnRfiRegisterDeleteCommentRequest,
    RedlineRegisterDataRequest,
} from 'src/app/store/dcn-rfi-redline/actions';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from 'src/app/modules/shared/components/comment-popup/comment-popup.component';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { CalendarColumn, CheckListColumn, NumericColumn, OrderDirection, UserDetail } from 'src/app/store/common.model';
import { Router } from '@angular/router';
import { HeaderCheckListFilter, HeaderDateFilter, HeaderNumericFilter } from 'src/app/models/filter-settings';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { DcnRfiRedlineRegisterService } from 'src/app/services/api/webapi-services/dcn-rfi-redline-register.service';
import { HeaderNumericFilterComponent } from 'src/app/modules/shared/components/filter/header-numeric-filter/header-numeric-filter.component';
import { SubsystemScope, Zone } from 'src/app/enums';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { CommentsHistoryComponent } from '../../comments-history/comments-history.component';

@Component({
    selector: 'app-dcn-rfi-redline-table',
    templateUrl: './dcn-rfi-redline-table.component.html',
    styleUrls: ['./dcn-rfi-redline-table.component.scss'],
})
export class DcnRfiRedlineTableComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() dcnRfiRegister: DcnRfiRegisterResultDto;
    @Input() redlineRegister: RedlineRegisterResultDto;
    @Input() registerType: RegisterType;
    @Input() filterForm: UntypedFormGroup;
    @Input() paginator: MatPaginator;

    dcnRfiRedlineRegisterFilter: DcnRfiRedlineRegisterFilter;
    dcnRfiDataSource: MatTableDataSource<DcnRfiRegisterDto>;
    redlineDataSource: MatTableDataSource<RedlineRegisterDto>;
    registerTypes = RegisterType;
    isReadOnly = false;
    todayDate = new Date();
    showHideColumns = new ShowHideColumns();
    isLoading: boolean = false;
    resultsLength = 0;
    dcnRfiRegisterColumns = [
        'dcnRfiNumber',
        'dcnRfiDiscipline',
        'dcnRfiTitle',
        'dcnRfiSubsystem',
        'dcnRfiEngineeringStatus',
        'dcnRfiWorkflowStatus',        
        'walkdownActual',
        'mcActual',
        'walkdownForecast',
        'rfsuPlan',
        'implementationStatus',
        'rlmuToGo',
        'comments',
    ];
    redlineRegisterColumns: string[] = [
        'redlineNumber',
        'redlineTitle',
        'revision',
        'requiredFor',
        'redlineSubsystem',
        'rfo',
        'submissionStatus',
        'rlmuStatus',
        'redlineDiscipline',
        'nbResponsibleEngineer',
        'dcnRfiNumber',
        'tcoStatus',
        'walkdownForecast',        
        'walkdownActual',
        'mcActual',        
        'rfsuPlan',
        'rfsuActual',
        'comments',
    ];

    numericColumns = ['columnRlmuToGo'];

    dcnRfiRedlineRegisterGridLoader$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.isLoading);
    dcnRfiRedlineRegisterFilter$ = this.store.select(
        (state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter
    );

    sortBy = 'number';
    direction = OrderDirection.Desc;

    columnDcnRfiRFSUForecast: CalendarColumn = null;
    columnDcnRfiWalkdownActual: CalendarColumn = null;
    columnDcnRfiMcActual: CalendarColumn = null;
    columnDcnRfiWalkdownForecast: CalendarColumn = null;
    columnDcnRfiRfsuPlan: CalendarColumn = null;
    columnDcnRfiDiscipline: CheckListColumn = null;
    columnDcnRfiNumber: CheckListColumn = null;
    columnDcnRfiTitle: CheckListColumn = null;
    columnDcnRfiSubsystem: CheckListColumn = null;
    columnDcnRfiEngineeringStatus: CheckListColumn = null;
    columnDcnRfiWorkflowStatus: CheckListColumn = null;
    columnDcnRfiImplementationStatus: CheckListColumn = null;
    columnRlmuToGo: NumericColumn = null;
    columnDcnRfiComments: CheckListColumn = null;

    columnRedlineNumber: CheckListColumn = null;
    columnRedlineDiscipline: CheckListColumn = null;
    columnRedlineTitle: CheckListColumn = null;
    columnRedlineRevision: CheckListColumn = null;
    columnRedlineRlmuStatus: CheckListColumn = null;
    columnRedlineTcoStatus: CheckListColumn = null;
    columnRedlineSubsystems: CheckListColumn = null;
    columnRedlineRFSUActual: CalendarColumn = null;
    columnRedlineRFSUForecast: CalendarColumn = null;
    columnRedlineWalkdownForecast: CalendarColumn = null;
    columnRedlineWalkdownActual: CalendarColumn = null;
    columnRedlineMcActual: CalendarColumn = null;    
    columnRedlineRfsuPlan: CalendarColumn = null;
    columnRedlineSubmissionStatus: CheckListColumn = null;
    columnRedlineComments: CheckListColumn = null;
    columnRedlineRequiredFor: CheckListColumn = null;
    columnRedlineRfo: CheckListColumn = null;
    columnRedlineNbRespEngineer: CheckListColumn = null;

    columnDcnRfiRFSUForecast$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiRFSUForecast);
    columnDcnRfiWalkdownActual$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiWalkdownActual);
    columnDcnRfiMcActual$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiMcActual);
    columnDcnRfiRfsuPlan$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiRfsuPlan);
    columnDcnRfiWalkdownForecast$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiWalkdownForecast);
    columnDcnRfiDiscipline$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiDiscipline);
    columnDcnRfiNumber$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiNumber);
    columnDcnRfiTitle$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiTitle);
    columnDcnRfiSubsystem$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiSubsystem);
    columnDcnRfiEngineeringStatus$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiEngineeringStatus);
    columnDcnRfiWorkflowStatus$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiWorkflowStatus);
    columnDcnRfiImplementationStatus$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiImplementationStatus);
    columnRlmuToGo$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRlmuToGo);
    columnDcnRfiComments$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnDcnRfiComments);

    columnRedlineNumber$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineNumber);
    columnRedlineDiscipline$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineDiscipline);
    columnRedlineTitle$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineTitle);
    columnRedlineRevision$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineRevision);
    columnRedlineRlmuStatus$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineRlmuStatus);
    columnRedlineTcoStatus$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineTcoStatus);
    columnRedlineSubsystems$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineSubsystems);
    columnRedlineRFSUActual$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineRFSUActual);
    columnRedlineRFSUForecast$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineRFSUForecast);

    columnRedlineWalkdownActual$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineWalkdownActual);
    columnRedlineMcActual$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineMcActual);
    columnRedlineRfsuPlan$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineRfsuPlan);
    columnRedlineWalkdownForecast$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineWalkdownForecast);

    columnRedlineSubmissionStatus$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineSubmissionStatus);
    columnRedlineComments$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineComments);
    columnRedlineRequiredFor$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineRequiredFor);
    columnRedlineRfo$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineRfo);
    columnRedlineNbRespEngineer$ = this.store.select((state) => state.dcnRfiRedlineRegisterState.dcnRfiRedlineRegisterFilter.columnRedlineNbRespEngineer);

    constructor(
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private ngZone: NgZone,
        private router: Router,
        private dcnRfiRedlineRegisterService: DcnRfiRedlineRegisterService,
        private commentService: CommentService
    ) {
        super();
    }

    ngOnInit() {

        this.sortBy = this.registerType === RegisterType.Dcnrfi ? 'number' : 'redlineNumber';

        this.dcnRfiRedlineRegisterGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
        });
        this.isReadOnly = this.roleService.isReadOnly();
        this.dcnRfiRedlineRegisterFilter$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((dcnRfiRedlineRegisterFilter) => {
                this.dcnRfiRedlineRegisterFilter = dcnRfiRedlineRegisterFilter;
                this.sortBy = dcnRfiRedlineRegisterFilter.sortBy;
                this.direction = dcnRfiRedlineRegisterFilter.direction;
            });

        this.columnDcnRfiComments$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiComments = data));
        this.columnDcnRfiRFSUForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiRFSUForecast = data));
        this.columnDcnRfiWalkdownActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiWalkdownActual = data));
        this.columnDcnRfiMcActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiMcActual = data));
        this.columnDcnRfiRfsuPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiRfsuPlan = data));
        this.columnDcnRfiWalkdownForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiWalkdownForecast = data));
        this.columnDcnRfiDiscipline$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiDiscipline = data));
        this.columnDcnRfiNumber$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiNumber = data));
        this.columnDcnRfiTitle$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiTitle = data));
        this.columnDcnRfiSubsystem$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiSubsystem = data));
        this.columnDcnRfiEngineeringStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiEngineeringStatus = data));
        this.columnDcnRfiWorkflowStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiWorkflowStatus = data));
        this.columnDcnRfiImplementationStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDcnRfiImplementationStatus = data));
        this.columnRlmuToGo$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRlmuToGo = data));

        this.columnRedlineNumber$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineNumber = data));
        this.columnRedlineDiscipline$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineDiscipline = data));
        this.columnRedlineTitle$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineTitle = data));
        this.columnRedlineRevision$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineRevision = data));
        this.columnRedlineRlmuStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineRlmuStatus = data));
        this.columnRedlineTcoStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineTcoStatus = data));
        this.columnRedlineSubsystems$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineSubsystems = data));
        this.columnRedlineRFSUActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineRFSUActual = data));
        this.columnRedlineRFSUForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineRFSUForecast = data));


        this.columnRedlineWalkdownActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineWalkdownActual = data));
        this.columnRedlineMcActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineMcActual = data));
        this.columnRedlineRfsuPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineRfsuPlan = data));
        this.columnRedlineWalkdownForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineWalkdownForecast = data));

        this.columnRedlineSubmissionStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineSubmissionStatus = data));
        this.columnRedlineComments$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineComments = data));
        this.columnRedlineRequiredFor$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRedlineRequiredFor = data));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dcnRfiDataSource = new MatTableDataSource(this.dcnRfiRegister.items);
        this.redlineDataSource = new MatTableDataSource(this.redlineRegister.items);
        if (this.registerType === RegisterType.Dcnrfi) {
            this.resultsLength = this.dcnRfiRegister.totalCount;
        } else {
            this.resultsLength = this.redlineRegister.totalCount;
        }
    }

    reverseValue(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };
        this.store.dispatch(
            new DcnRfiRedlineRegisterFilterPropertyUpdate({
                showHideColumns: this.showHideColumns,
            } as DcnRfiRedlineRegisterFilter)
        );
    }

    openCommentPopup(element: DcnRfiRegisterDto | RedlineRegisterDto, event: any) {
        const comment = element.comment;
        if (this.isReadOnly) return;
        if (this.registerType === RegisterType.Dcnrfi) {
            this.popupSvc.openPopup(
                new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                    comment: comment || '',
                    mentions: element.mentions,
                    emailButtonVisible: false,
                    url: `dcnrfiredlineregister/${this.registerType}/${element.id}`,
                    action: (description: string, mentions: UserDetail[]) => {
                        this.store.dispatch(
                            new DcnRfiRedlineRegisterAddCommentRequest(
                                element.id,
                                description,
                                mentions,
                                Zone.ChangeManagement
                            )
                        );
                    },
                })
            );
        } else {
            this.popupSvc.openPopup(
                new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                    comment: comment || '',
                    mentions: element.mentions,
                    emailButtonVisible: false,
                    url: `dcnrfiredlineregister/${this.registerType}/${element.id}`,
                    action: (description: string, mentions: UserDetail[]) => {
                        this.store.dispatch(
                            new DcnRfiRedlineRegisterAddCommentRequest(element.id, description, mentions, Zone.Redline)
                        );
                    },
                })
            );
        }

        event.stopPropagation();
    }

    openDetailsPage(element: DcnRfiRegisterDto | RedlineRegisterDto) {
        this.ngZone.run(() =>
            this.router.navigate([
                `/register/details/${this.registerTypes[this.registerType].toLowerCase()}/${element.id}`,
            ])
        );
    }

    NumberOfSubsystems(temp: string) {
        if (temp !== null) {
            let count = (temp.match(/,/g) || []).length;
            return count + 1;
        }
    }

    getDcnRfiDisciplines = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchDcnRfiDisciplinesWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getDcnRfiNumbers = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchDcnRfiNumbersWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getDcnRfiTitles = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchDcnRfiTitlesWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getDcnRfiSubsystems = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchDcnRfiSubsystemsWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getDcnRfiEngineeringStatus = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchDcnRfiEngineeringStatusWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getDcnRfiWorkflowStatus = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchDcnRfiWorkflowStatusWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getDcnRfiImplementationStatus = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchDcnRfiImplementationStatusWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getDcnRfiComments = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchDcnRfiCommentsWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineNumbers = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineNumbersWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );
        
    getRedlineDisciplines = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineDisciplinesWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineTitles = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineTitlesWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineRevisions = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineRevisionsWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineRlmuStatus = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineRlmuStatusWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineTcoStatus = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineTcoStatusWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineSubsystems = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineSubsystemsWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineSubmissionStatus = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineSubmissionStatusWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineComments = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineCommentsWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineRequiredFor = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineRequiredForWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineRfo = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineRfoWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    getRedlineNbRespEngineer = (search = '', take = 30, page = 0) =>
        this.dcnRfiRedlineRegisterService.searchRedlineNbRespEngineersWithFilter(
            search,
            take,
            page,
            this.dcnRfiRedlineRegisterFilter
        );

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn,
        isSortingOff = false,
        fireSearch = true
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(
                HeaderDateFilterComponent,
                345,
                600,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                    placeHolder: placeholder,
                    isSortingOff: isSortingOff,
                    action: (data) => {
                        this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                        this.store.dispatch(
                            new DcnRfiRedlineRegisterFilterPropertyUpdate(({
                                [propertyName]: this.filterForm.controls[propertyName].value,
                            } as unknown) as DcnRfiRedlineRegisterFilter)
                        );
                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                        if (fireSearch) {
                            this.search();
                        }
                    },
                },
                345,
                580
            )
        );
    }

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(new DcnRfiRedlineRegisterFilterPropertyUpdate({ page: 0 } as DcnRfiRedlineRegisterFilter));

        if (this.dcnRfiRedlineRegisterFilter.registerType === RegisterType.Dcnrfi) {
            this.store.dispatch(new DcnRfiRegisterDataRequest());
        } else {
            this.store.dispatch(new RedlineRegisterDataRequest());
        }
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        isSortingOff: boolean = false,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(
                HeaderChecklistFilterComponent,
                columnName === 'description' ? 800 : 400,
                650,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    placeHolder: placeholder,
                    searchFunc: searchFunc,
                    selectedItems: selected ? [...selected.items] : [],
                    excludeBlanks: excludeBlanks,
                    onlyBlanks: onlyBlanks,
                    isSortingOff: isSortingOff,
                    showCounts,
                    showInputSearch: showInputSearch,
                    showBlankOptions: showBlankOptions,
                    action: (data) => {
                        let value = new CheckListColumn();
                        value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                        value.excludeBlanks = data.excludeBlanks;
                        value.onlyBlanks = data.onlyBlanks;
                        this.filterForm.controls[propertyName].setValue(value);
                        this.store.dispatch(
                            new DcnRfiRedlineRegisterFilterPropertyUpdate({
                                [propertyName]: this.filterForm.controls[propertyName].value,
                            } as DcnRfiRedlineRegisterFilter)
                        );

                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                        this.search();
                    },
                    resetColumnAction: () => {
                        this.store.dispatch(
                            new DcnRfiRedlineRegisterFilterPropertyUpdate({
                                [propertyName]: null,
                            } as DcnRfiRedlineRegisterFilter)
                        );
                    },
                    cancelAction: () => {
                        this.store.dispatch(
                            new DcnRfiRedlineRegisterFilterPropertyUpdate({
                                [propertyName]: this.filterForm.controls[propertyName].value,
                            } as DcnRfiRedlineRegisterFilter)
                        );
                    },
                }
            )
        );
    }

    openHeaderNumericFilter(property: string, currentState: NumericColumn, placeHolder: string) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderNumericFilter>(HeaderNumericFilterComponent, 400, 350, {
                placeHolder: placeHolder,
                showBlankOptions: true,
                isAscendingSort: this.sortBy === property && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === property && this.direction == OrderDirection.Desc,
                minValue: currentState ? currentState.minValue : null,
                maxValue: currentState ? currentState.maxValue : null,
                excludeBlanks: currentState ? currentState.excludeBlanks : false,
                onlyBlanks: currentState ? currentState.onlyBlanks : false,
                onSumbit: (data: HeaderNumericFilter) => {
                    let newFilterState = new NumericColumn();
                    newFilterState = { ...data };

                    const columnName = `column${this.capitalizeFirstLetter(property)}`;
                    if (
                        this.numericColumns.includes(columnName) &&
                        this.filterForm.controls.hasOwnProperty(columnName)
                    ) {
                        this.filterForm.controls[columnName].setValue(newFilterState);
                        this.store.dispatch(
                            new DcnRfiRedlineRegisterFilterPropertyUpdate(({
                                [columnName]: data,
                            } as unknown) as DcnRfiRedlineRegisterFilter)
                        );
                    }

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, property);
                    this.search();
                },
                onReset: () => {
                    const columnName = `column${this.capitalizeFirstLetter(property)}`;
                    this.store.dispatch(
                        new DcnRfiRedlineRegisterFilterPropertyUpdate({
                            [columnName]: null,
                        } as DcnRfiRedlineRegisterFilter)
                    );
                },
            } as HeaderNumericFilter)
        );
    }

    openHistoryPopup = (element: DcnRfiRegisterDto | RedlineRegisterDto) => {
        if (element.comment === null) {
            return;
        }

        const scope = this.registerType === RegisterType.Dcnrfi
            ? SubsystemScope.ChangeDocument
            : SubsystemScope.Redline;

        this.isLoading = true;
        this.commentService
            .getComments(`${element.id}`, scope)
            .subscribe((data) => {
                this.isLoading = false;
                this.popupSvc.openPopup(
                    new PopupSettings(
                        CommentsHistoryComponent,
                        null,
                        null,
                        {
                            commentHist: data,
                            action: (id: number) => {
                                data = data.filter((s) => s.id !== id);
                                this.store.dispatch(dcnRfiRegisterDeleteCommentRequest({
                                    id,
                                    scope,
                                    lastComment: data.length ? data[0] : null
                                }));
                            },
                        },
                        200,
                        200
                    )
                );
            });
    };

    asString = (val: any) : string => val as string; 

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new DcnRfiRedlineRegisterFilterPropertyUpdate({
                    sortBy: columnName,
                    direction: direction,
                } as DcnRfiRedlineRegisterFilter)
            );
        } else {
            this.store.dispatch(
                new DcnRfiRedlineRegisterFilterPropertyUpdate({
                    sortBy: this.sortBy,
                    direction: this.direction,
                } as DcnRfiRedlineRegisterFilter)
            );
        }
    }
}
