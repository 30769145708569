import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../../base.component';
import { CriticalRFIManualPriorityHistoryRequest } from 'src/app/store/manual-priority-history/actions';

@Component({
  selector: 'app-manual-priority-history',
  templateUrl: './manual-priority-history.component.html',
  styleUrls: ['./manual-priority-history.component.scss']
})
export class ManualPriroityHistoryComponent extends BaseComponent implements OnInit {
  displayedColumns: string[] = ['oldPriority', 'newPriority', 'updatedDate', 'userFullName'];
  dataSource = new MatTableDataSource();
  isLoading = false;
  manualPriorityHistory$ = this.store.select((state) => state.manualPriorityHistoryState.data);
  isLoading$ = this.store.select((state) => state.manualPriorityHistoryState.isLoading);
  changeDocId: number;
  constructor(public dialogRef: MatDialogRef<ManualPriroityHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private store: Store<ApplicationState>) {
    super();

    this.changeDocId = data.changeDocId
  }

  ngOnInit(): void {

    this.manualPriorityHistory$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data)
      });

    this.isLoading$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((loading) => this.isLoading = loading);

    this.store.dispatch(
      new CriticalRFIManualPriorityHistoryRequest(
        {
          id: this.changeDocId
        }));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

}
