import { RFOState, RFODataPagination, RFOFilter } from './model';
import { RFOActions, RFOActionTypes } from './actions';

const initialState: RFOState = {
    dataPagination: new RFODataPagination(),
    filter: new RFOFilter(),
    isLoading: false,
};

export function reducer(state = initialState, action: RFOActions): RFOState {
    switch (action.type) {
        case RFOActionTypes.RFORemoveCommentRequest:
        case RFOActionTypes.RFOFilterRequest:
        case RFOActionTypes.RFOFilterError:
        case RFOActionTypes.RFORemoveCommentError:
        case RFOActionTypes.RFOExportToExcelRequest:
        case RFOActionTypes.RFOForecastDateUpdateRequest: 
        case RFOActionTypes.RFOFreezeDateUpdateRequest:           
        case RFOActionTypes.RFOExportToExcelError:        
        case RFOActionTypes.RFOAddCommentRequest:
        case RFOActionTypes.RFOAddBulkCommentRequest:
        case RFOActionTypes.RFOAddBulkCommentError:   {
            return {
                ...state,
                isLoading: true,
            };
        }
        case RFOActionTypes.RFORFSUForecastDateUpdateSuccess:
        case RFOActionTypes.RFOFreezeDateUpdateSuccess:
        case RFOActionTypes.RFOExportToExcelSuccess:    
        case RFOActionTypes.RFORFSUForecastDateUpdateError:{
            return {
                ...state,
                isLoading: false,
            };
        }

        case RFOActionTypes.RFORemoveCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((s) => s.rfo === action.payload.rfo);

            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                comment: action.payload.lastComment.description,
                                mentions: action.payload.lastComment.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case RFOActionTypes.RFOFilterSuccess: {
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        }
        case RFOActionTypes.RFOFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case RFOActionTypes.RFOFilterReset: {
            const newFilter = new RFOFilter();
            newFilter.showHideColumns = state.filter.showHideColumns;
            newFilter.sortBy = state.filter.sortBy;
            newFilter.direction = state.filter.direction;
            return {
                ...state,
                filter: { ...newFilter },
            };
        }

        case RFOActionTypes.RFOAddCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((x) => x.rfo === action.payload.entityId);
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                comment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case RFOActionTypes.RFOAddCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case RFOActionTypes.RFOAddBulkCommentSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((item) => ({
                        ...item,
                        comment: action.payload.description,
                        mentions: action.payload.mentions,
                    })),
                },
            };
        }
        default:
            return state;
    }
}
