import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { MasterSubsystemListDeltaPagination, MasterSubsystemListDeletePagination } from './model';
import { Moment } from 'moment';

export enum MasterSubsystemListActionTypes {
    MasterSubsystemListUploadLogRequest = '[Master Subsystem List] Upload Log Request',
    MasterSubsystemListUploadLogSuccess = '[Master Subsystem List] Upload Log Success',
    MasterSubsystemListUploadLogError = '[Master Subsystem List] Upload Log Error',
    MasterSubsystemListDeltaRequest = '[Master Subsystem List] Delta Request',
    MasterSubsystemListDeltaSuccess = '[Master Subsystem List] Delta Success',
    MasterSubsystemListDeltaError = '[Master Subsystem List] Delta Error',
    MasterSubsystemListSetStatusAndStartDate = '[Master Subsystem List] Set Status and Start Date',
    MasterSubsystemListDeltaFilterPropertyUpdate = '[Master Subsystem List] Delta Filter Property Update',
    MasterSubsystemListDeleteFilterPropertyUpdate = '[Master Subsystem List] Delete Filter Property Update',
    MasterSubsystemListPatchDeltaRequest = '[Master Subsystem List] Patch Delta Request',
    MasterSubsystemListPatchDeltaSuccess = '[Master Subsystem List] Patch Delta Success',
    MasterSubsystemListPatchDeltaError = '[Master Subsystem List] Patch Delta Error',
    MasterSubsystemListPatchAllDeltaRequest = '[Master Subsystem List] Patch All Delta Request',
    MasterSubsystemListPatchAllDeltaSuccess = '[Master Subsystem List] Patch All Delta Success',
    MasterSubsystemListPatchAllDeltaError = '[Master Subsystem List] Patch All Delta Error',
    MasterSubsystemListValidateDeltaRequest = '[Master Subsystem List] Validate Delta Request',
    MasterSubsystemListValidateDeltaSuccess = '[Master Subsystem List] Validate Delta Success',
    MasterSubsystemListValidateDeltaError = '[Master Subsystem List] Validate Delta Error',
    MasterSubsystemListFileUploaded = '[Master Subsystem List] File Uploaded',
    MasterSubsystemListClearInProgressSpinner = '[Master Subsystem List] Clear In Progress Spinner',
    MasterSubsystemListTemplateFileRequest = '[Master Subsystem List] Template File Request',
    MasterSubsystemListTemplateFileSuccess = '[Master Subsystem List] Template File Success',
    MasterSubsystemListTemplateFileError = '[Master Subsystem List] Template File Error',
    MasterSubsystemListDeleteDataRequest = '[Master Subsystem List] Delete Data Request',
    MasterSubsystemListDeleteDataSuccess = '[Master Subsystem List] Delete Data Success',
    MasterSubsystemListDeleteDataError = '[Master Subsystem List] Delete Data Error',
    MasterSubsystemListPatchDeleteRecordRequest = '[Master Subsystem List] Patch Delete Record Request',
    MasterSubsystemListPatchDeleteRecordSuccess = '[Master Subsystem List] Patch Delete Record Success',
    MasterSubsystemListPatchDeleteRecordError = '[Master Subsystem List] Patch Delete Record Error',
    MasterSubsystemListPatchDeleteAllRecordsRequest = '[Master Subsystem List] Patch Delete All Records Request',
    MasterSubsystemListPatchDeleteAllRecordsSuccess = '[Master Subsystem List] Patch Delete All Records Success',
    MasterSubsystemListPatchDeleteAllRecordsError = '[Master Subsystem List] Patch Delete All Records Error',
    MasterSubsystemListValidateButtonStateRequest = '[Master Subsystem List] Validate Button State Request',
    MasterSubsystemListValidateButtonStateSuccess = '[Master Subsystem List] Validate Button State Success',
    MasterSubsystemListValidateButtonStateError = '[Master Subsystem List] Validate Button State Error',
    MasterSubsystemListDownloadOutputDataRequest = '[Master Subsystem List] Download Output Data Request',
    MasterSubsystemListDownloadOutputDataSuccess = '[Master Subsystem List] Download Output Data Success',
    MasterSubsystemListDownloadOutputDataError = '[Master Subsystem List] Download Output Data Error',
    MasterSubsystemListAddStartedLog = '[MasterSubsystemList] Add Started Log',
}

export class MasterSubsystemListAddStartedLog implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListAddStartedLog;
}

export class MasterSubsystemListUploadLogRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListUploadLogRequest;
}

export class MasterSubsystemListUploadLogSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class MasterSubsystemListUploadLogError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListUploadLogError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListDeltaRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDeltaRequest;
}

export class MasterSubsystemListDeltaSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDeltaSuccess;

    constructor(public payload: MasterSubsystemListDeltaPagination) {}
}

export class MasterSubsystemListDeltaError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDeltaError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListSetStatusAndStartDate implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class MasterSubsystemListDeltaFilterPropertyUpdate implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDeltaFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class MasterSubsystemListDeleteFilterPropertyUpdate implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class MasterSubsystemListPatchDeltaRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaRequest;

    constructor(public payload: { subsystem: string; deltaState: boolean }) {}
}

export class MasterSubsystemListPatchAllDeltaRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaRequest;

    constructor(public payload: { deltaState: boolean }) {}
}

export class MasterSubsystemListPatchDeltaSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaSuccess;
}

export class MasterSubsystemListPatchAllDeltaSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaSuccess;

    constructor(public payload: { deltaState: boolean }) {}
}

export class MasterSubsystemListPatchDeltaError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListPatchAllDeltaError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListValidateDeltaRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaRequest;
}

export class MasterSubsystemListValidateDeltaSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaSuccess;
}

export class MasterSubsystemListValidateDeltaError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListFileUploaded implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListFileUploaded;
}

export class MasterSubsystemListClearInProgressSpinner implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListClearInProgressSpinner;
}

export class MasterSubsystemListTemplateFileRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileRequest;

    constructor(public payload: string) {}
}

export class MasterSubsystemListTemplateFileSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class MasterSubsystemListTemplateFileError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListDeleteDataRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataRequest;
}

export class MasterSubsystemListDeleteDataSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataSuccess;

    constructor(public payload: MasterSubsystemListDeletePagination) {}
}

export class MasterSubsystemListDeleteDataError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListPatchDeleteRecordRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordRequest;

    constructor(public payload: { subsystem: string; deleteState: boolean }) {}
}

export class MasterSubsystemListPatchDeleteRecordSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordSuccess;
}

export class MasterSubsystemListPatchDeleteRecordError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListPatchDeleteAllRecordsRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class MasterSubsystemListPatchDeleteAllRecordsSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class MasterSubsystemListPatchDeleteAllRecordsError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListValidateButtonStateRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListValidateButtonStateRequest;
}

export class MasterSubsystemListValidateButtonStateSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; isAutomaticUploadInProgress: boolean; }) {}
}

export class MasterSubsystemListValidateButtonStateError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListValidateButtonStateError;

    constructor(public payload: string) {}
}

export class MasterSubsystemListDownloadOutputDataRequest implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class MasterSubsystemListDownloadOutputDataSuccess implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class MasterSubsystemListDownloadOutputDataError implements Action {
    readonly type = MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataError;

    constructor(public payload: string) {}
}

export type MasterSubsystemListActions =
    | MasterSubsystemListUploadLogRequest
    | MasterSubsystemListUploadLogSuccess
    | MasterSubsystemListUploadLogError
    | MasterSubsystemListDeltaRequest
    | MasterSubsystemListDeltaSuccess
    | MasterSubsystemListDeltaError
    | MasterSubsystemListSetStatusAndStartDate
    | MasterSubsystemListDeltaFilterPropertyUpdate
    | MasterSubsystemListDeleteFilterPropertyUpdate
    | MasterSubsystemListPatchDeltaRequest
    | MasterSubsystemListPatchDeltaSuccess
    | MasterSubsystemListPatchDeltaError
    | MasterSubsystemListPatchAllDeltaRequest
    | MasterSubsystemListPatchAllDeltaSuccess
    | MasterSubsystemListPatchAllDeltaError
    | MasterSubsystemListValidateDeltaRequest
    | MasterSubsystemListValidateDeltaSuccess
    | MasterSubsystemListValidateDeltaError
    | MasterSubsystemListFileUploaded
    | MasterSubsystemListClearInProgressSpinner
    | MasterSubsystemListTemplateFileRequest
    | MasterSubsystemListTemplateFileSuccess
    | MasterSubsystemListTemplateFileError
    | MasterSubsystemListDeleteDataRequest
    | MasterSubsystemListDeleteDataSuccess
    | MasterSubsystemListDeleteDataError
    | MasterSubsystemListPatchDeleteRecordRequest
    | MasterSubsystemListPatchDeleteRecordSuccess
    | MasterSubsystemListPatchDeleteRecordError
    | MasterSubsystemListPatchDeleteAllRecordsRequest
    | MasterSubsystemListPatchDeleteAllRecordsSuccess
    | MasterSubsystemListPatchDeleteAllRecordsError
    | MasterSubsystemListValidateButtonStateRequest
    | MasterSubsystemListValidateButtonStateSuccess
    | MasterSubsystemListValidateButtonStateError
    | MasterSubsystemListDownloadOutputDataRequest
    | MasterSubsystemListDownloadOutputDataSuccess
    | MasterSubsystemListDownloadOutputDataError
    | MasterSubsystemListAddStartedLog;
