import {BaseComponent} from '../../base.component';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ApplicationState} from 'src/app/store/model';
import {Store, Action} from '@ngrx/store';
import {takeWhile} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {ToastService} from 'src/app/services/shared/toast.service';
import * as _ from 'lodash';
import {PopupService} from 'src/app/services/shared/popup.service';
import {ConfirmDialogPopupSettings} from 'src/app/models/confirm-dialog-popup-settings';
import {Constants} from 'src/app/constants';
import {RoleService} from 'src/app/services/shared/role.service';
import {LiveDataService} from 'src/app/services/api/webapi-services/live-data.service';
import * as moment from 'moment';
import {MatTabNav} from '@angular/material/tabs';
import {UploadLinkDTO} from 'src/app/models/upload-link-dto';
import {
  PlanningDataAddStartedLog,
  PlanningDataFileUploaded,
  PlanningDataValidateDeltaRequest,
} from 'src/app/store/planning-upload/planning-data/actions';
import {
  ScheduleActivityAddStartedLog,
  ScheduleActivityFileUploaded,
  ScheduleActivityValidateDeleteRequest,
} from 'src/app/store/planning-upload/schedule-activity/actions';
import {
  ActivityBarcodeAddStartedLog,
  ActivityBarcodeFileUploaded,
  ActivityBarcodeValidateDeleteRequest,
} from 'src/app/store/planning-upload/activity-barcode/actions';
import {
  ExceptionsPriorityAddStartedLog,
  ExceptionsPriorityFileUploaded,
  ExceptionsPriorityValidateDeleteRequest,
} from 'src/app/store/planning-upload/exceptions-priority/actions';
import {
  ITRManhoursAddStartedLog,
  ITRManhoursFileUploaded,
  ITRManhoursValidateDeleteRequest,
} from 'src/app/store/planning-upload/itr-manhours/actions';
import {
  ITRConstraintsAddStartedLog,
  ITRConstraintsFileUploaded,
  ITRConstraintsValidateDeleteRequest,
} from 'src/app/store/planning-upload/itr-constraints/actions';
import {PlanningUploadFilesUploadRequest} from 'src/app/store/planning-upload/actions';
import {ImportType} from '../../admin-section/import-type';
import { ITRConstraintsContactsAddStartedLog, ITRConstraintsContactsFileUploaded } from 'src/app/store/planning-upload/itr-constraints-contacts/actions';
import { CITRPartialProgressAddStartedLog, CITRPartialProgressDeleteRequest, CITRPartialProgressFileUploaded, CITRPartialProgressValidateDeleteRequest } from 'src/app/store/planning-upload/citr-partial-progress/actions';
import { CITRPartialProgressDeleteDTO } from 'src/app/store/planning-upload/citr-partial-progress/model';
import { CriticalPathAddStartedLog, CriticalPathFileUploaded, CriticalPathValidateDeleteRequest } from 'src/app/store/planning-upload/critical-path/actions';
import { ExceptionsUploadAddStartedLog, ExceptionsUploadFileUploaded } from 'src/app/store/planning-upload/exceptions-upload/actions';
import { PliDataUploadAddStartedLog, PliDataUploadFileUploaded } from 'src/app/store/planning-upload/pli-data-upload/actions';
import { ITRCommentUploadAddStartedLog, ITRCommentUploadFileUploaded } from 'src/app/store/planning-upload/itr-comment-upload/actions';
import { RFODatesUploadAddStartedLog, RFODatesUploadFileUploaded, RFODatesUploadValidateDeleteRequest } from 'src/app/store/planning-upload/rfo-dates-upload/actions';
@Component({
  selector: 'app-planning-upload',
  templateUrl: './planning-upload.component.html',
  styleUrls: ['./planning-upload.component.scss'],
})
export class PlanningUploadComponent extends BaseComponent implements OnInit {
  isAdmin = false;
  activatedRoute$ = this.store.select((store) => store.planningUploadState.activatedRoute);

  navLinks: UploadLinkDTO[] = [];
  activeNavLink: UploadLinkDTO;
  isValidateButtonDisabled = true;
  recordsForDeletion = 0;
  showValidateButton = true;
  disableLiveDateButton = true;
  validateActions: Action[] = [
    new PlanningDataValidateDeltaRequest(), 
    new ScheduleActivityValidateDeleteRequest(), 
    new ActivityBarcodeValidateDeleteRequest(),
    new ITRManhoursValidateDeleteRequest(), 
    new ITRConstraintsValidateDeleteRequest(),
    , 
    new CITRPartialProgressValidateDeleteRequest(), 
    new CriticalPathValidateDeleteRequest(),
    ,
    new ExceptionsPriorityValidateDeleteRequest(),
    ,
    ,
    new RFODatesUploadValidateDeleteRequest()
    ];
  inProgressObservables = [
    this.store.select((store) => store.planningDataState.isImportInProgress),
    this.store.select((store) => store.scheduleActivityState.isImportInProgress),
    this.store.select((store) => store.activityBarcodeState.isImportInProgress),    
    this.store.select((store) => store.itrManhoursState.isImportInProgress),
    this.store.select((store) => store.itrConstraintsState.isImportInProgress),
    this.store.select((store) => store.itrConstraintsContactsState.isImportInProgress),
    this.store.select((store) => store.citrPartialProgressState.isImportInProgress),
    this.store.select((store) => store.criticalPathState.isImportInProgress),
    ,
    this.store.select((store) => store.exceptionsPriorityState.isImportInProgress),
    ,
    ,
    this.store.select((store) => store.RFODatesUploadState.isImportInProgress)
  ];

  isLoading: boolean;
  isUploading$ = this.store.select((store) => store.planningUploadState.uploadInProgress);

  constructor(
    private store: Store<ApplicationState>,
    private toastService: ToastService,
    private roleService: RoleService,
    private popupService: PopupService,
    private liveDataService: LiveDataService
  ) {
    super();
    this.navLinks = [
      {
        label: 'subsystem schedule/plan data',
        link: './subsystemscheduleplandata',
        index: 0,
        type: 'Planning',
        fileUploadedAction: new PlanningDataFileUploaded(),
        addLogAction: new PlanningDataAddStartedLog(),
      },
      {
        label: 'schedule activity',
        link: './scheduleactivity',
        index: 1,
        type: 'ScheduleActivity',
        fileUploadedAction: new ScheduleActivityFileUploaded(),
        addLogAction: new ScheduleActivityAddStartedLog(),
      },
      {
        label: 'ITR to P6 Activity Upload',
        link: './activitybarcode',
        index: 2,
        type: 'ActivityBarcode',
        fileUploadedAction: new ActivityBarcodeFileUploaded(),
        addLogAction: new ActivityBarcodeAddStartedLog(),
      },
      {
        label: 'ITR Manhours Upload',
        link: './itrmanhours',
        index: 3,
        type: 'ITRManhours',
        fileUploadedAction: new ITRManhoursFileUploaded(),
        addLogAction: new ITRManhoursAddStartedLog(),
      },
      {
        label: 'Constraints Upload',
        link: './itrconstraints',
        index: 4,
        type: 'ITRConstraints',
        fileUploadedAction: new ITRConstraintsFileUploaded(),
        addLogAction: new ITRConstraintsAddStartedLog(),
      },
      {
        label: 'Constraint Contacts Upload',
        link: './constraintcontacts',
        index: 5,
        type: 'ConstraintContacts',
        fileUploadedAction: new ITRConstraintsContactsFileUploaded(),
        addLogAction: new ITRConstraintsContactsAddStartedLog(),
      },
      {
        label: 'CITR Partial Progress Upload',
        link: './citrpartialprogress',
        index: 6,
        type: 'CITRPartialProgress',
        fileUploadedAction: new CITRPartialProgressFileUploaded(),
        addLogAction: new CITRPartialProgressAddStartedLog(),
      },
      {
        label: 'Critical Path Upload',
        link: './criticalpath',
        index: 7,
        type: 'CriticalPath',
        fileUploadedAction: new CriticalPathFileUploaded(),
        addLogAction: new CriticalPathAddStartedLog(),
      },
      {
        label: 'Exceptions Upload',
        link: './exceptionsupload',
        index: 8,
        type: 'exceptionsupload',
        fileUploadedAction: new ExceptionsUploadFileUploaded(),
        addLogAction: new ExceptionsUploadAddStartedLog(),
      },
      {
        label: 'Exceptions Plant Start Up Impact Upload',
        link: './exceptionspriority',
        index: 9,
        type: 'ExceptionsPriority',
        fileUploadedAction: new ExceptionsPriorityFileUploaded(),
        addLogAction: new ExceptionsPriorityAddStartedLog(),
      },
      {
        label: 'PLI Data Upload',
        link: './plidataupload',
        index: 10,
        type: 'PliDataUpload',
        fileUploadedAction: new PliDataUploadFileUploaded(),
        addLogAction: new PliDataUploadAddStartedLog(),
      },
      {
        label: 'ITR Comment Upload',
        link: './itrcommentupload',
        index: 11,
        type: 'ITRCommentUpload',
        fileUploadedAction: new ITRCommentUploadFileUploaded(),
        addLogAction: new ITRCommentUploadAddStartedLog(),
      },
      {
        label: 'RFO Dates Upload',
        link: './rfodatesupload',
        index: 12,
        type: 'RFO',
        fileUploadedAction: new RFODatesUploadFileUploaded(),
        addLogAction: new RFODatesUploadAddStartedLog(),
      },
    ];
  }

  ngOnInit() {
    this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);

    combineLatest([
      this.store.select((store) => store.planningDataState.isValidateButtonEnabled),
      this.store.select((store) => store.scheduleActivityState.isValidateButtonEnabled),
      this.store.select((store) => store.activityBarcodeState.isValidateButtonEnabled),
      this.store.select((store) => store.itrManhoursState.isValidateButtonEnabled),
      this.store.select((store) => store.itrConstraintsState.isValidateButtonEnabled),
      this.store.select((store) => false),
      this.store.select((store) => store.citrPartialProgressState.isValidateButtonEnabled),
      this.store.select((store) => false),
      this.store.select((store) => false),
      this.store.select((store) => store.exceptionsPriorityState.isValidateButtonEnabled),
      this.store.select((store) => false),
      this.store.select((store) => false),
      this.store.select((store) => store.RFODatesUploadState.isValidateButtonEnabled),
      this.activatedRoute$,
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        setTimeout(() => {
          this.activeNavLink = this.navLinks.find((n) => n.link === `./${data[data.length - 1]}`);
          this.isValidateButtonDisabled = this.activeNavLink ? !data[this.activeNavLink.index] : true;
        });
      });

    combineLatest([
      this.store.select((store) => store.planningDataState.recordsForDeletion),
      this.store.select((store) => store.scheduleActivityState.recordsForDeletion),
      this.store.select((store) => store.activityBarcodeState.recordsForDeletion),
      this.store.select((store) => store.exceptionsPriorityState.recordsForDeletion),
      this.store.select((store) => store.itrManhoursState.recordsForDeletion),
      this.store.select((store) => store.itrConstraintsState.recordsForDeletion),
      this.store.select((store) => store.itrConstraintsContactsState.recordsForDeletion),
      this.store.select((store) => store.citrPartialProgressState.recordsForDeletion),
      //this.store.select((store) => store.criticalPathState.recordsForDeletion),
      this.activatedRoute$,
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data: any) => {
        setTimeout(() => {
          this.activeNavLink = this.navLinks.find((n) => n.link === `./${data[data.length - 1]}`);
          this.recordsForDeletion = this.activeNavLink ? data[this.activeNavLink.index] : 0;
          this.disableLiveDateButton = this.activeNavLink?.label !== 'PLI Data Upload' ? false : true;
        });
      });
  }

  fileChangeEvent(files: Array<File>) {
    const filesWrapper = new Array<File>();
    const formData = new FormData();
    filesWrapper.push(...files);
    const fileTypes: string[] = [];
    filesWrapper.forEach((file) => {
      const type = this.activeNavLink.type;
      fileTypes.push(type);
      formData.append(type, file, file.name);
    });

    _.uniq(fileTypes).forEach((t) => {
      this.store.dispatch(this.activeNavLink.fileUploadedAction);
      this.store.dispatch(this.activeNavLink.addLogAction);
    });

    this.store.dispatch(new PlanningUploadFilesUploadRequest(formData));
  }

  validateDelta() {
    let warning = this.recordsForDeletion > 0 ?
        `<p><span class="warning"">Warning: </span> This action will delete ${this.recordsForDeletion} record(s).</p>` : '';
        
    this.popupService
      .openPopup(
        new ConfirmDialogPopupSettings({
          title: 'Confirm action',
          htmlText: `<p>Are you sure you want to import data and update existing records?</p>`
          + warning,
        })
      )
      .afterClosed()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((answer) => {
        if (answer) {
          this.store.dispatch(this.validateActions[this.activeNavLink.index]);
          this.isValidateButtonDisabled = true;
          this.recordsForDeletion = 0;
        }
      });
  }

  downloadLiveFile() {
    this.isLoading = true;
    let importType: ImportType;

    switch (this.activeNavLink.label) {
      case 'subsystem schedule/plan data':
        importType = ImportType.SubsystemSchedulePlan;
        break;
      case 'schedule activity':
        importType = ImportType.ScheduleActivity;
        break;
      case 'ITR to P6 Activity Upload':
        importType = ImportType.ActivityBarcode;
        break;
      case 'Exceptions Plant Start Up Impact Upload':
        importType = ImportType.ExceptionsPriority;
        break;
      case 'ITR Manhours Upload':
        importType = ImportType.ITRManhours;
        break;
      case 'Constraints Upload':
        importType = ImportType.ITRConstraints;
        break;
      case 'Constraint Contacts Upload':
          importType = ImportType.ITRConstraintsContacts;
          break;
      case 'CITR Partial Progress Upload':
          importType = ImportType.CITRPartialProgress;
          break;
      case 'Critical Path Upload':
          importType = ImportType.CriticalPath;
          break;                
      case 'Exceptions Upload':
          importType = ImportType.ExceptionsUpload;
          break;    
      case 'PLI Data Upload':
          importType = ImportType.PliDataUpload;
          break;              
      case 'ITR Comment Upload':
          importType = ImportType.ITRCommentUpload;
          break;              
    }

    this.liveDataService.downloadLiveDataAsync(importType).subscribe((data: any) => {
      const blob = new Blob([data], {
        type: 'application/octet-stream',
      });
      saveAs(blob, `livedata_${ImportType[importType]}_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);

      this.isLoading = false;
    });
  }

  //Fix for bug 1939060 - mat-ink-bar not aligned to the tab
  @ViewChild(MatTabNav) public matTabNav: MatTabNav;

  public ngAfterViewChecked(): void {
    setTimeout(() => this.matTabNav._alignInkBarToSelectedTab(), 0);
  }
}
