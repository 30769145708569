<form [formGroup]="filterForm">
    <mat-accordion class="filter-container">
        <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" expanded>
            <mat-expansion-panel-header collapsedHeight="auto">
                <div *ngIf="!filterExpansionPanel.expanded">
                    <mat-chip-list aria-label="Active filters">
                        <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                            Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.constructionArea.value.length" removable>
                            Construction Area: {{ displayMultipleIdSelected(filterForm.controls.constructionArea.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('constructionArea')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.scManagers.value.length" removable>
                            SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManagers.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('scManagers')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.mcEngineers.value.length" removable>
                            MC Engineer: {{ displaySelectedMCEngineer(filterForm.controls.mcEngineers.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('mcEngineers')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.systemOwners.value.length" removable>
                            System Owner: {{ displaySelectedSystemOwner(filterForm.controls.systemOwners.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('systemOwners')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                            Area Breakdown: {{ displayMultipleFilterSelected(filterForm.controls.areaBreakdown.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                            RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.goocs.value.length" removable>
                            GOOC: {{ displayGoocsSelected(filterForm.controls.goocs.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('goocs')">cancel</mat-icon>
                        </mat-basic-chip>
                    </mat-chip-list>
                </div>
            </mat-expansion-panel-header>
            <ng-container *ngIf="!isRFOWeeklyPlanning">
                <div class="mixedapp-container">
                    <app-multiple-items-selector
                        class="container620 margin-right20"
                        [setInput]="setContractorInput"
                        formControlName="contractors"
                        [searchFunc]="getContractors"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                        (autocompleteClosed)="onContractorsClosed()"
                        (itemRemoved)="onContractorsClosed()"
                        [filterForm]="filterForm"
                        [formSetter]="'contractors'"
                        [propertyToShow]="'contractNo'"
                        [placeholder]="'Contractor'"
                        [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        [secondPropertyToShow]="'contract'"
                    >
                    </app-multiple-items-selector>                   
                    <app-multiple-items-selector
                        class="container300 margin-right20"
                        [setInput]="setConstructionAreaInput"
                        formControlName="constructionArea"
                        [searchFunc]="getConstructionArea"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'constructionArea'"
                        [placeholder]="'Construction Area'"
                    >
                    </app-multiple-items-selector>
                    <app-multiple-items-selector
                        [setInput]="setStagedStartUpPrioritiesInput"
                        formControlName="stagedStartUpPriorities"
                        [searchFunc]="getStagedStartUpPriorities"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['priority', 'priorityName']"
                        (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                        (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                        [filterForm]="filterForm"
                        formSetter="stagedStartUpPriorities"
                        [propertyToShow]="'priority'"                                    
                        [secondPropertyToShow]="'priorityName'"
                        placeholder="Staged Start-up Priority"
                        [panelWidth]="480"
                        class="container300"
                    >
                    </app-multiple-items-selector>                   
                </div>
                
                <div>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManagers" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>MC Engineer</mat-label>
                        <mat-select formControlName="mcEngineers" multiple>
                            <mat-option *ngFor="let mce of mcEngineers" [value]="mce.id">{{ mce.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwners" multiple>
                            <mat-option *ngFor="let so of systemOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-multiple-items-selector 
                        class="container300" 
                        [panelWidth]="480" 
                        [setInput]="setAreaBreakdownInput"
                        formControlName="areaBreakdown" 
                        [searchFunc]="getAreaBreakdown" 
                        [isAsync]="true" 
                        [filterForm]="filterForm"
                        [formSetter]="'areaBreakdown'" 
                        [placeholder]="'Area Breakdown'">
                    </app-multiple-items-selector>
                </div>

            </ng-container>
            <ng-container *ngIf="isRFOWeeklyPlanning || isRFSUWeeklyPlanning">
                <div class="mixedapp-container">
                    <div class="container620 margin-right20">
                        <app-multiple-items-selector
                            class="container620"
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container620-mh">
                        <app-multiple-items-selector
                            class="container620 margin-right15"
                            [setInput]="setGoocInput"
                            formControlName="goocs"
                            [searchFunc]="getGoocs"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="goocAutocompleteDisplayedColumns"
                            [filterForm]="filterForm"
                            [formSetter]="'goocs'"
                            [propertyToShow]="'no'"
                            [placeholder]="'GOOC'"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!isRFOWeeklyPlanning && !isRFSUWeeklyPlanning">
                <div class="container620-mh">
                    <app-multiple-items-selector
                        class="container620 margin-right15"
                        [setInput]="setGoocInput"
                        formControlName="goocs"
                        [searchFunc]="getGoocs"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="goocAutocompleteDisplayedColumns"
                        [filterForm]="filterForm"
                        [formSetter]="'goocs'"
                        [propertyToShow]="'no'"
                        [placeholder]="'GOOC'"
                        [panelWidth]="480"
                    >
                    </app-multiple-items-selector>
                </div>
            </ng-container>
            <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                <div class="lds-hourglass"></div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="buttons-container flex">
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filters
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
    </div>
</form>
