import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ForecastHistoryService } from "src/app/services/api/webapi-services/forecastHistory.service";
import { ToastService } from "src/app/services/shared/toast.service";
import { ApplicationState } from "../model";
import { ExceptionForecastDateHistoryRequest, ForecastDateHistoryError, ForecastDateHistoryRequest, ForecastDateHistorySuccess, ForecastHistoryActionTypes } from "./actions";

@Injectable()
export class ForecastHistoryEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private forecastHistoryService: ForecastHistoryService
    ) {}

    @Effect()
    getMcForecastDateHistory$ = this.actions$.pipe(
        ofType<ForecastDateHistoryRequest>(ForecastHistoryActionTypes.ForecastDateHistoryRequest),
        mergeMap((action) => {
            return this.forecastHistoryService
                .getForecastDateHistory(
                    action.payload.rfo,
                    action.payload.subsystem,
                    action.payload.type,
                    action.payload.discipline,
                    action.payload.allDisciplines,
                    action.payload.number
                )
                .pipe(
                    map((data) => new ForecastDateHistorySuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting Forecast date history. Please contact Program Administrator.'
                        );
                        return of(new ForecastDateHistoryError(error));
                    })
                );
        })
    );

    @Effect()
    getExceptionForecastDateHistory$ = this.actions$.pipe(
        ofType<ExceptionForecastDateHistoryRequest>(ForecastHistoryActionTypes.ExceptionForecastDateHistoryRequest),
        mergeMap((action) => {
            return this.forecastHistoryService
                .getExceptionForecastDateHistory(
                    action.payload.number,
                    action.payload.pliOrBarcode,
                    action.payload.itrId,
                    action.payload.scope
                )
                .pipe(
                    map((data) => new ForecastDateHistorySuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting Forecast date history. Please contact Program Administrator.'
                        );
                        return of(new ForecastDateHistoryError(error));
                    })
                );
        })
    );
}