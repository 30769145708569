import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { Contractor, Waypoint, Milestone, TCOUser, RFO } from 'src/app/store/common.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, filter, take, catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { HealthFilter } from 'src/app/store/reports/health/model';
import {
    HealthCleanlinessFilterPropertyUpdate,
    HealthCleanlinessSetWeekRange,
    HealthCleanlinessRequest,
    HealthCleanlinessFilterReset,
    HealthCleanlinessFilterUpdate,
} from 'src/app/store/reports/health/actions';
import { ToastService } from 'src/app/services/shared/toast.service';
import { forkJoin } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-reports-health-cleanliness',
    templateUrl: './reports-health-cleanliness.component.html',
    styleUrls: ['./reports-health-cleanliness.component.scss'],
})
export class ReportsHealthCleanlinessComponent extends BaseComponent implements OnInit {
    navLinks: { label: string; link: string; index: number }[];

    isUsersPerProjectLoading = false;
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    areaCompletionLeads: TCOUser[] = [];
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    autocompleteDisplayedColumns = ['name', 'description'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    projectTeamNamesCurrent: string[];
    prevWaypoints: Waypoint[] = [];
    filterForm: UntypedFormGroup;
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;

    cleanlinessType$ = this.store.select((store) => store.healthState.cleanliness.cleanlinessType);
    cleanlinessType: string;
    healthCleanlinessFilter$ = this.store.select((store) => store.healthState.cleanliness.filter);
    milestones$ = this.store.select((store) => store.healthState.cleanliness.filter.milestones);
    waypoints$ = this.store.select((store) => store.healthState.cleanliness.filter.waypoints);
    startDate$ = this.store.select((store) => store.healthState.cleanliness.filter.startDate);
    rangeDateFilter = getCurrentWeekStartDate().add(-6, 'weeks');
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    scManagers: TCOUser[] = [];

    @ViewChild('dateRangePicker', { static: true }) dateRangePicker: MatDatepicker<moment.Moment>;

    constructor(
        private formService: FormService,
        private store: Store<ApplicationState>,
        private lookupService: LookupService,
        private projectTeamsService: ProjectTeamsService,
        private toastService: ToastService
    ) {
        super();
        this.navLinks = [
            // {
            //     label: 'mc cleanliness',
            //     link: './mc',
            //     index: 0,
            // },
            {
                label: 'rfsu cleanliness',
                link: './rfsu',
                index: 1,
            },
        ];
        this.filterForm = this.formService.createSimpleForm(new HealthFilter());
        this.dateRangePickerControl = new UntypedFormControl(this.rangeDateFilter.toDate())
        this.dateRangePickerControlFormatted = new UntypedFormControl({
            value: this.formatDateRange(this.rangeDateFilter),
            disabled: true,
        });
    }

    ngOnInit() {
        this.cleanlinessType$.pipe(takeWhile(() => this.isAlive)).subscribe((cleanlinessType) =>
            setTimeout(() => {
                this.cleanlinessType = cleanlinessType;
            })
        );

        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });
        this.store.dispatch(
            new HealthCleanlinessFilterPropertyUpdate({
                key: 'projectTeams',
                value: this.projectTeamNames,
            })
        );
        this.healthCleanlinessFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam();
        });

        this.filterForm.controls.waypoints.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((waypoints: Waypoint[]) => {
                this.prevWaypoints = waypoints;
                this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
            });

        this.filterForm.controls.milestones.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((milestones: Milestone[]) => {
                let waypoints = JSON.parse(JSON.stringify(milestones));
                waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
                waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name');
                this.setMilestoneInput.emit(new SetInputEventArgs(false, '', milestones));
                if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) {
                    this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                    this.filterForm.controls.waypoints.setValue(waypoints);
                }
            });

        this.startDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startDate) => !!startDate)
            )
            .subscribe((startDate) => {
                this.dateRangePickerControl.setValue(moment(startDate), { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(startDate)));
            });

        this.dateRangePickerControl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((date: moment.Moment) => {
                this.store.dispatch(new HealthCleanlinessSetWeekRange(date));
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
            });
            this.lookupService
            .getACLeads([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads));
        
        this.filterExpansionPanel.expanded = true;
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames);
    };

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.getUsersPerProjectTeam();
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMilestones(
            search,
            take,
            page,
            this.filterForm.value.waypoints,
            this.filterForm.value.projectTeamNames
        );
    };

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    displayMultipleSelected(values: (Milestone | Waypoint)[]) {
        return values.map((x) => x.name).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    search() {
        this.updateFilterByFormProperties();
        this.store.dispatch(new HealthCleanlinessRequest());
    }

    resetFilters() {
        this.store.dispatch(new HealthCleanlinessFilterReset());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    dateRangePickerFilter = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    private formatDateRange(startDate: moment.Moment) {
        const endDate = moment(startDate).add(6, 'weeks');
        return `${moment(startDate).format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
    }

    private updateFilterByFormProperties() {
        let newFilter = new HealthFilter();
        for (const key of Object.keys(this.filterForm.controls)) {
            if (key !== 'startDate' && key !== 'endDate') {
                const value = this.filterForm.controls[key].value;
                newFilter[key] = this.filterForm.controls[key].value;
            }
        }
        this.store.dispatch(new HealthCleanlinessFilterUpdate(newFilter));
    }

    private getUsersPerProjectTeam() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setRFOInput.emit(new SetInputEventArgs(true));
        forkJoin([
            this.lookupService.getMCEngineers(this.filterForm.value.projectTeamNames || []),
            this.lookupService.getSysOwners(this.filterForm.value.projectTeamNames || []),
            this.lookupService.getSCManagers(this.filterForm.controls.projectTeamNames.value || []),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners, scManagers]) => {
                    this.mcEngineers = mcEngineers;
                    this.sysOwners = sysOwners;
                    this.scManagers = scManagers;
                },
                (error) => {
                    this.toastService.Error(error);
                },
                () => {
                    this.isUsersPerProjectLoading = false;
                }
            );
    }
}
