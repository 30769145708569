import {
    CWPRegisterState,
    CWPRegisterDeltaPagination,
    CWPRegisterDeltaFilter,
    CWPRegisterDeletePagination,
    CWPRegisterDeleteFilter,
    CWPRegisterDeltaDTO,
} from './model';
import { CWPRegisterActions, CWPRegisterActionTypes } from './actions';
import * as moment from 'moment';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';

const initialState: CWPRegisterState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deltaData: new CWPRegisterDeltaPagination(),
    isDeltaDataLoading: false,
    deleteData: new CWPRegisterDeletePagination(),
    isDeleteDataLoading: false,
    deltaFilter: new CWPRegisterDeltaFilter(),
    deleteFilter: new CWPRegisterDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: CWPRegisterActions) {
    switch (action.type) {
        case CWPRegisterActionTypes.CWPRegisterUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterPatchAllDeltaRequest:
        case CWPRegisterActionTypes.CWPRegisterDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterDeltaSuccess: {
            return {
                ...state,
                deltaData: action.payload,
                isDeltaDataLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterPatchDeltaSuccess:
        case CWPRegisterActionTypes.CWPRegisterPatchDeltaError:
        case CWPRegisterActionTypes.CWPRegisterPatchAllDeltaError:
        case CWPRegisterActionTypes.CWPRegisterDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterDeltaFilterPropertyUpdate: {
            state.deltaFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterDeleteFilterPropertyUpdate: {
            state.deleteFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterPatchDeltaRequest: {
            state.deltaData.items.find((d) => d.cwp === action.payload.cwp).deltaState = action.payload.deltaState;
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterPatchAllDeltaSuccess: {
            const delta = state.deltaData.items.map(
                (i) => ({ ...i, deltaState: action.payload.deltaState } as CWPRegisterDeltaDTO)
            );
            return {
                ...state,
                deltaData: {
                    ...state.deltaData,
                    items: delta,
                },
                isDeltaDataLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterDeleteDataRequest:
        case CWPRegisterActionTypes.CWPRegisterPatchDeleteAllRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterPatchDeleteAllRecordsSuccess: {
            state.deleteData.items.forEach((i) => (i.deleteState = action.payload.deleteState));
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterPatchDeleteAllRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterValidateDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterValidateDeltaSuccess: {
            return {
                ...state,
                deltaData: new CWPRegisterDeltaPagination(),
                deleteData: new CWPRegisterDeletePagination(),
                isDeltaDataLoading: false,
                isDeleteDataLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterValidateDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
                isDeleteDataLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deltaData: new CWPRegisterDeltaPagination(),
                deleteData: new CWPRegisterDeletePagination(),
            };
        }
        case CWPRegisterActionTypes.CWPRegisterClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterDownloadOutputDataRequest:
        case CWPRegisterActionTypes.CWPRegisterTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterTemplateFileError:
        case CWPRegisterActionTypes.CWPRegisterDownloadOutputDataSuccess:
        case CWPRegisterActionTypes.CWPRegisterDownloadOutputDataError:
        case CWPRegisterActionTypes.CWPRegisterTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterDeleteDataSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterPatchDeleteRecordSuccess:
        case CWPRegisterActionTypes.CWPRegisterPatchDeleteRecordError:
        case CWPRegisterActionTypes.CWPRegisterDeleteDataError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterPatchDeleteRecordRequest: {
            state.deleteData.items.find((d) => d.cwp === action.payload.cwp).deleteState = action.payload.deleteState;
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case CWPRegisterActionTypes.CWPRegisterAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'CWP';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}
