import { Component, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { Contractor, Waypoint, Milestone, Gooc, TCOUser, RFO, StagedStartUpPriority } from 'src/app/store/common.model';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { FormService } from 'src/app/services/shared/form.service';
import { BaseComponent } from '../../base.component';
import { takeWhile, filter, map } from 'rxjs/operators';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ClosureStages, RundownFilter, RundownType, RUNDOWN_TYPE_KEYS } from 'src/app/store/reports/rundown/model';
import { WeekType } from 'src/app/enums';
import {
    RundownSetWeekRange,
    RundownFilterPropertyUpdate,
    RundownFilterReset,
    RundownRequest,
    RundownSetMonthRange,
    RundownTogglePlanView,
    RundownSetQuarterRange,
    RundownFilterUpdate,
    RundownRangeUpdate,
    RundownExportToExcelRequest,
} from 'src/app/store/reports/rundown/actions';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { MatSliderChange } from '@angular/material/slider';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { DetailedStatusFilterBase } from 'src/app/store/detailed-status/model';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-reports-rundown',
    templateUrl: './reports-rundown.component.html',
    styleUrls: ['./reports-rundown.component.scss'],
})
export class ReportsRundownComponent extends BaseComponent implements OnInit {
    navLinks: { label: string; link: string; index: number }[];
    contractorLabel = '';
    disciplineLabel = 'qwe';
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWorkAreaInput = new EventEmitter<SetInputEventArgs>();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPriorityInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setQVDNumbersInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setitrDescriptionsInput:EventEmitter<SetInputEventArgs> = new EventEmitter();
    setExcepQVDNumbersInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    autocompleteDisplayedColumns = ['name', 'description'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    itrAutocompleteDisplayedColumns = ['description'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    subsystemAutocompleteDisplayedColumns = ['id', 'name'];
    stagedStartUpPriorityAutocompleteDisplayedColumns = ['priority', 'priorityName'];
    projectTeamNamesCurrent: string[];
    prevWaypoints: Waypoint[] = [];
    filterForm: FormGroup;
    dateRangePickerControl: FormControl;
    dateRangePickerControlFormatted: FormControl;
    range: FormControl;
    rundownType = RundownType.RFSU;
    isLoading = false;
    rundownTypes = RundownType;
    disciplines: string[] = [];
    actionBys: string[] = [];
    punchTypes: string[] = [];
    responsiblePeople: string[] = [];
    sysOwners: TCOUser[] = [];
    scManagers: TCOUser[] = [];
    areaCompletionLeads: TCOUser[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    monthViewEnabled = false;
    weeklyViewEnabled = true;
    quarterViewEnabled = false;
    monthViewEnabled$ = this.store.select((store) => store.rundownState.monthViewEnabled);
    weeklyViewEnabled$ = this.store.select((store) => store.rundownState.weeklyViewEnabled);
    quarterViewEnabled$ = this.store.select((store) => store.rundownState.quarterViewEnabled);
    planViewEnabled = true;
    planViewEnabled$ = this.store.select((store) => store.rundownState.planViewEnabled);
    rundownType$ = this.store.select((store) => store.rundownState.rundownType);
    rundownFilter$ = this.store.select((store) => store.rundownState.filter);
    categories$ = this.store.select((store) => store.rundownState.filter.categories);
    categories: string[] = [];
    milestones$ = this.store.select((store) => store.rundownState.filter.milestones);
    waypoints$ = this.store.select((store) => store.rundownState.filter.waypoints);
    isLoading$ = this.store.select((store) => store.rundownState.isLoading);
    startDate$ = this.store.select((store) =>
        _.head<{ date: Date; weekType: WeekType }>(
            this.monthViewEnabled
                ? store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].months
                : this.weeklyViewEnabled
                ? store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].weeks
                : store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].quarters
        )
    );
    startWeek$ = this.store.select((store) =>
        _.head<{ date: Date; weekType: WeekType }>(
            store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].weeks
        )
    );
    startMonth$ = this.store.select((store) =>
        _.head<{ date: Date; weekType: WeekType }>(
            store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].months
        )
    );
    startQuarter$ = this.store.select((store) =>
        _.head<{ date: Date; weekType: WeekType }>(
            store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].quarters
        )
    );
    startMonth: { date: Date; weekType: WeekType };
    startWeek: { date: Date; weekType: WeekType };
    startQuarter: { date: Date; weekType: WeekType };
    period: number = 0;
    @ViewChild('dateRangePicker', { static: true }) dateRangePicker: MatDatepicker<Date>;

    constructor(
        private store: Store<ApplicationState>,
        private formService: FormService,
        private lookupService: LookupService,
        private roleService: RoleService,
        private scheduleActivityLookupService: ScheduleActivityLookupService,
        private projectTeamsService: ProjectTeamsService
    ) {
        super();
        this.navLinks = [
            // {
            //     label: 'mc rundown',
            //     link: './mc',
            //     index: 0,
            // },
            {
                label: 'rfsu rundown',
                link: './rfsu',
                index: 1,
            },
            {
                label: 'a-qvd rundown',
                link: './aqvd',
                index: 2,
            },
            {
                label: 'b-itr rundown',
                link: './bitr',
                index: 3,
            },
            {
                label: 'c-itr rundown',
                link: './citr',
                index: 4,
            },
            {
                label: 'pli rundown',
                link: './pli1',
                index: 5,
            },
        ];
        this.filterForm = this.formService.createSimpleForm(new RundownFilter());
        this.dateRangePickerControl = new FormControl();
        this.dateRangePickerControlFormatted = new FormControl({ value: '', disabled: true });
        this.range = new FormControl();
    }

    ngOnInit() {
        if(this.rundownType == RundownType.AQVD)
        {
            this.planViewEnabled = true;
        }
        this.filterExpansionPanel.expanded = true;
        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.categories$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.categories = data;
        });

        this.rundownFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.range.patchValue(filter.range);
        });

        this.monthViewEnabled$.pipe(takeWhile(() => this.isAlive)).subscribe((monthViewEnabled) => {
            this.monthViewEnabled = monthViewEnabled;
        });

        this.quarterViewEnabled$.pipe(takeWhile(() => this.isAlive)).subscribe((quarterViewEnabled) => {
            this.quarterViewEnabled = quarterViewEnabled;
        });

        this.weeklyViewEnabled$.pipe(takeWhile(() => this.isAlive)).subscribe((weeklyViewEnabled) => {
            this.weeklyViewEnabled = weeklyViewEnabled;
        });

        this.filterForm.controls.waypoints.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((waypoints: Waypoint[]) => {
                this.prevWaypoints = waypoints;
                this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
            });

        this.filterForm.controls.goocs.valueChanges.subscribe(() =>
            this.setSubsystemInput.emit(new SetInputEventArgs(true))
        );
        this.filterForm.controls.rfos.valueChanges.subscribe(() =>
            this.setSubsystemInput.emit(new SetInputEventArgs(true))
        );

        this.filterForm.controls.milestones.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((milestones: Milestone[]) => {
                let waypoints = JSON.parse(JSON.stringify(milestones));
                waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
                waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name');
                this.setMilestoneInput.emit(new SetInputEventArgs(false, '', milestones));
                if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) {
                    this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                    this.filterForm.controls.waypoints.setValue(waypoints);
                }
            });

        this.lookupService
            .getActionBy()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data: any[]) => (this.actionBys = data));

        this.lookupService
            .getPunchType()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data: any[]) => (this.punchTypes = data));

        this.lookupService
            .getPLIResponsiblePeople()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data: any[]) => (this.responsiblePeople = data));

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((disciplines: any[]) => (this.disciplines = disciplines));

        this.lookupService
            .getSysOwners([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((sysOwners: TCOUser[]) => (this.sysOwners = sysOwners));

        this.lookupService
            .getSCManagers([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((scManagers: TCOUser[]) => (this.scManagers = scManagers));
        
        this.lookupService
            .getACLeads([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads));

        this.lookupService
            .getStagedStartUpPriorities()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((priorities: StagedStartUpPriority[]) => {
                this.stagedStartUpPriorities = priorities;
            });

        this.rundownType$.pipe(takeWhile(() => this.isAlive)).subscribe((rundownType) =>
            setTimeout(() => {
                this.rundownType = rundownType;
                this.setQVDNumbersInput.emit(new SetInputEventArgs(false, '', []));
                this.setExcepQVDNumbersInput.emit(new SetInputEventArgs(false, '', []));
                this.contractorLabel = this.getContractorLabel(this.rundownType);
                this.disciplineLabel = this.getDisciplineLabel(this.rundownType);
            })
        );

        this.isLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) =>
            setTimeout(() => {
                this.isLoading = isLoading;
            })
        );

        this.startDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startDate) => !!startDate)
            )
            .subscribe((startDate) => {
                this.dateRangePickerControl.setValue(startDate.date, { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(startDate.date)));
            });

        this.startMonth$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startMonth) => !!startMonth)
            )
            .subscribe((startMonth) => {
                this.startMonth = startMonth;
            });

        this.startQuarter$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startQuarter) => !!startQuarter)
            )
            .subscribe((startQuarter) => {
                this.startQuarter = startQuarter;
            });

        this.startWeek$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startWeek) => !!startWeek)
            )
            .subscribe((startWeek) => {
                this.startWeek = startWeek;
            });

        this.dateRangePickerControl.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((date: Date) => {
            if (this.monthViewEnabled) {
                this.store.dispatch(new RundownSetMonthRange(moment(date)));
            } else if (this.weeklyViewEnabled) {
                this.store.dispatch(new RundownSetWeekRange(moment(date)));
            } else {
                this.store.dispatch(new RundownSetQuarterRange(moment(date)));
            }

            this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(date)));
        });
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    enableMonthlyView() {
        this.period = 1;
        this.monthViewEnabled = true;
        this.weeklyViewEnabled = false;
        this.quarterViewEnabled = false;
        this.setControl();
    }

    enableWeeklyView() {
        this.period = 0;
        this.monthViewEnabled = false;
        this.weeklyViewEnabled = true;
        this.quarterViewEnabled = false;
        this.setControl();
    }
    enableQuarteryView() {
        this.period = 2;
        this.monthViewEnabled = false;
        this.weeklyViewEnabled = false;
        this.quarterViewEnabled = true;
        this.setControl();
    }

    setControl() {
        if (this.monthViewEnabled) {
            this.dateRangePickerControl.setValue(this.startMonth.date);
        } else if (this.weeklyViewEnabled) {
            this.dateRangePickerControl.setValue(this.startWeek.date);
        } else {
            this.dateRangePickerControl.setValue(this.startQuarter.date);
        }
    }

    togglePlanView() {
        this.planViewEnabled = !this.planViewEnabled;
        this.store.dispatch(new RundownTogglePlanView());
    }

    chosenYearHandler(normalizedYear: moment.Moment) {
        const dateChozen = moment(normalizedYear);
        const ctrlValue = moment(this.dateRangePickerControl.value);
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue.toDate(), { emitEvent: false });
    }

    chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
        const dateChozen = moment(normalizedMonth);
        const ctrlValue = moment(this.dateRangePickerControl.value);
        ctrlValue.month(dateChozen.month());
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue.toDate());
        datepicker.close();
    }

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getContractors = (search = '', take = 10, page = 0) => {
        return this.rundownType === RundownType.RFSU
            ? this.lookupService.searchMSLContractors(search, take, page, this.filterForm.value.projectTeamNames)
            : this.lookupService.searchContractors(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((startUpPriorities: StagedStartUpPriority[]) => startUpPriorities));
    };

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(
            search,
            take,
            page,
            this.filterForm.value.projectTeamNames,
            this.filterForm.controls.goocs.value,
            this.filterForm.controls.rfos.value
        );
    };

    getQVDNumbers = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchQVDNumbers(search, take, page, this.rundownType);
    };

    getitrDescriptions = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchITRDecriptions(search, take, page, this.rundownType);
    };

    getExceQVDNumbers = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchExcepQVDNumbers(search, take, page, this.rundownType);
    };


    searchActionBys = (search = '') => {
        return this.lookupService.getActionBy().pipe(
            map((actionBys) => {
                var list = actionBys.filter((a) => a.toLowerCase().includes(search.toLowerCase()));
                var newList = list.map((x: { id: string }) => {
                    return { id: x };
                });
                return newList;
            })
        );
    };

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.getUsersPerProjectTeam();
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchMilestones(
            search,
            take,
            page,
            this.filterForm.value.waypoints,
            this.filterForm.value.projectTeamNames
        );
    };

    searchAreas = (search = '', take = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchWorkAreas(search, take, page,this.filterForm.value);
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    displayMultipleSelected(values: (Milestone | Waypoint | RFO)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    displaySubsystemsSelected(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayWorkAreasSelected(values: any[]) {
        return values.map((x) => x.workAreaName).join(', ');
    }

    displayClosureStage(stage: number) {
        switch (stage) {
            case ClosureStages.PostMC: {
                return 'Post MC';
            }
            case ClosureStages.PostRFSU: {
                return 'Post RFSU';
            }
            default: {
                return 'All';
            }
        }
    }

    displaySelectedActionBy(actionBys: any[]) {
        return actionBys.map((x) => x.id).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    search() {
        this.updateFilterByFormProperties();
        this.store.dispatch(new RundownRequest());
    }

    exportToExcel()
    {
        this.updateFilterByFormProperties();
        this.store.dispatch(new RundownExportToExcelRequest());
    }

    displayExportToExcelButton()
    {
        let isValid = this.roleService.isInRole(Constants.applicableGroups.Admin) && this.rundownType === RundownType.BITR        
        ? true : false;
        return isValid;

    }

    resetFilters() {
        this.store.dispatch(new RundownFilterReset());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setStagedStartUpPriorityInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setWorkAreaInput.emit(new SetInputEventArgs(false, ''));
        this.setitrDescriptionsInput.emit(new SetInputEventArgs(false, ''));
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    dateRangePickerFilter = (d: Date | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    quarterRangePickerFilter = (d: Date | null): boolean => {
        const month = (moment(d) || moment()).month();
        return month % 3 === 0;
    };

    toggleTotal(event: any) {
        this.store.dispatch(new RundownFilterPropertyUpdate({ key: 'displayTotal', value: event.value }));
    }

    toggleClosureStage(event: any) {
        this.store.dispatch(new RundownFilterPropertyUpdate({ key: 'closureStage', value: event.value }));
    }

    onRangeChanged($event: MatSliderChange) {
        this.store.dispatch(new RundownRangeUpdate($event.value));
        this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(this.dateRangePickerControl.value)));
        this.store.dispatch(new RundownRequest());
    }

    private formatDateRange(startDate: moment.Moment) {
        if (this.monthViewEnabled) {
            const endDate = moment(startDate).add(this.range.value, 'month');
            return `${startDate.format('D MMM YYYY')} - ${endDate.format('D MMM YYYY')}`;
        } else if (this.weeklyViewEnabled) {
            const endDate = moment(startDate)
                .add(this.range.value - 1, 'weeks')
                .add(6, 'days');
            return `${startDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
        } else {
            const endDate = moment(startDate).add(this.range.value * 3, 'month');
            return `Q${startDate.format('Q YYYY')} - Q${endDate.format('Q YYYY')}`;
        }
    }

    private updateFilterByFormProperties() {
        let newFilter = {} as RundownFilter;
        for (const key of Object.keys(this.filterForm.controls)) {
            let value;
            if (key !== 'startDate' && key !== 'endDate') {
                value = this.filterForm.controls[key].value;
            }
            if (key === 'workAreas') {
                value = this.filterForm.controls['workAreas'].value.map((w) => {
                    return { workAreaName: w.workAreaName };
                });
            }
            newFilter[key] = value;
        }

        this.store.dispatch(new RundownFilterUpdate(newFilter));
    }

    private getUsersPerProjectTeam() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setStagedStartUpPriorityInput.emit(new SetInputEventArgs(true));
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setStagedStartUpPriorityInput.emit(new SetInputEventArgs(true));
    }
    
    private getContractorLabel(type: RundownType) {
        return type === RundownType.AQVD
            ? 'Contractor assigned to QVD'
            : type === RundownType.BITR || type === RundownType.CITR
            ? 'Contractor assigned to ITR'
            : type === RundownType.RFSU
            ? 'MSL Contractor'
            : 'Contractor';
    }

    private getDisciplineLabel(type: RundownType) {
        return type === RundownType.AQVD || type === RundownType.BITR || type === RundownType.CITR
            ? 'QVD Discipline'
            : type === RundownType.PLI1 || type === RundownType.PLI2
            ? 'PLI Discipline'
            : 'Discipline';
    }
}
