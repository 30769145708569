import { Action } from '@ngrx/store';
import { MilestoneCommentType } from '../reports/milestone-dashboard/model';

export enum JoditDescriptionActionTypes {
    SetType = '[Jodit Description] Set Type',
    PreserveBackButton = '[Jodit Description] Preserve Back Button',
}

export class JoditDescriptionSetType implements Action {
    readonly type = JoditDescriptionActionTypes.SetType;

    constructor(public payload: { type: MilestoneCommentType; userType: string }) {}
}

export class JoditDescriptionPreserveBackButton implements Action {
    readonly type = JoditDescriptionActionTypes.PreserveBackButton;

    constructor(public payload: boolean) {}
}

export type JoditDescriptionActions = JoditDescriptionSetType | JoditDescriptionPreserveBackButton;
