import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ToastService } from 'src/app/services/shared/toast.service';
import { DataUpdateHistoryActionTypes, PostRFOResponsiblePersonOrGroupHistoryRequest, PostRFOResponsiblePersonOrGroupHistorySuccess } from './actions';
import { Injectable } from "@angular/core";
import { DetailedStatusService } from "src/app/services/api/webapi-services/detailed-status.service";

@Injectable()
export class DataUpdateHistoryEffects{

    constructor(
        private actions$: Actions,        
        private toastService: ToastService,
        private detailedStatusService: DetailedStatusService
    ) {}

    getResponsiblePersonOrGroupHistory$ = createEffect(() => this.actions$.pipe(
        ofType<PostRFOResponsiblePersonOrGroupHistoryRequest>(DataUpdateHistoryActionTypes.PostRFOResponsiblePersonOrGroupHistoryRequest),
        mergeMap((action) => {
            return this.detailedStatusService.getPostRFOResponsiblePersonOrGroupHistory(action.payload.postRFOData)
                .pipe(
                    map((data) => new PostRFOResponsiblePersonOrGroupHistorySuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting Post RFO Work Responsible Person Or Group history. Please contact Program Administrator.'
                        );
                        return of(new PostRFOResponsiblePersonOrGroupHistorySuccess(error));
                    })
                );
        })
    ));
}
