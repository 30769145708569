import { Action } from '@ngrx/store';
import { CTARegisterDataPagination, CTARegisterDTO, CTARegisterFilter } from './model';

export enum CTARegisterActionTypes {
    CTARegisterFilterRequest = '[CTA Register] Filter Request',
    CTARegisterFilterSuccess = '[CTA Register] Filter Success',
    CTARegisterFilterError = '[CTA Register] Filter Error',
    CTARegisterFilterPropertyUpdate = '[CTA Register] Property Update',
    CTARegisterDownloadDataRequest = '[CTA Register] Download Data Request',
    CTARegisterDownloadDataSuccess = '[CTA Register] Download Data Success',
    CTARegisterDownloadDataError = '[CTA Register] Download Data Error',
    CTADeleteRequest = '[CTA Register] CTA Delete Request',
    CTADeleteSuccess = '[CTA Register] CTA Delete Success',
    CTADeleteError = '[CTA Register] CTA Delete Error'
}

export class CTARegisterFilterRequest implements Action {
    readonly type = CTARegisterActionTypes.CTARegisterFilterRequest;

    constructor(public payload: CTARegisterFilter = null) {}
}

export class CTARegisterFilterSuccess implements Action {
    readonly type = CTARegisterActionTypes.CTARegisterFilterSuccess;

    constructor(public payload: CTARegisterDataPagination) {}
}

export class CTARegisterFilterError implements Action {
    readonly type = CTARegisterActionTypes.CTARegisterFilterError;

    constructor(public payload: string) {}
}

export class CTARegisterFilterPropertyUpdate implements Action {
    readonly type = CTARegisterActionTypes.CTARegisterFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class CTARegisterDownloadDataRequest implements Action {
    readonly type = CTARegisterActionTypes.CTARegisterDownloadDataRequest;

    constructor(public payload: string) {}
}

export class CTARegisterDownloadDataSuccess implements Action {
    readonly type = CTARegisterActionTypes.CTARegisterDownloadDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class CTARegisterDownloadDataError implements Action {
    readonly type = CTARegisterActionTypes.CTARegisterDownloadDataError;

    constructor(public payload: string) {}
}

export class CTADeleteRequest implements Action {
    readonly type = CTARegisterActionTypes.CTADeleteRequest;

    constructor(public payload: CTARegisterDTO) {}
}

export class CTADeleteSuccess implements Action {
    readonly type = CTARegisterActionTypes.CTADeleteSuccess;

    constructor(public payload: CTARegisterDTO) {}
}

export class CTADeleteError implements Action {
    readonly type = CTARegisterActionTypes.CTADeleteError;

    constructor(public payload: string) {}
}

export type CTARegisterActions =
    | CTARegisterFilterRequest
    | CTARegisterFilterSuccess
    | CTARegisterFilterError
    | CTARegisterFilterPropertyUpdate
    | CTARegisterDownloadDataRequest
    | CTARegisterDownloadDataSuccess
    | CTARegisterDownloadDataError
    | CTADeleteRequest
    | CTADeleteSuccess
    | CTADeleteError;
