import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';

@Injectable({
    providedIn: 'root',
})
export class IsRFSUSkylineCommitmentGuard implements CanActivate {
    constructor(private groupService: RoleService, private router: Router) {}

    canActivate(){
        return this.groupService.isInRole(Constants.applicableGroups.RFSUCommitmentSkylineViewers) 
        || this.groupService.isInRole(Constants.applicableGroups.Admin) 
        ? true
            : this.router.navigate(['/notauthorized']);
    }
}
