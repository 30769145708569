import { Component, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from '../../base.component';
import { CTAFormComponent } from '../manual-upload/cta/cta-form.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseComponent implements OnInit {
    fileUploading = false;
    uploadType = '';

    constructor(private adminPanelService: AdminPanelService, private toastService: ToastService, private popupService: PopupService) {
        super();
    }

    ngOnInit() {
        // This is intentional
    }

    openCTAFormPopup() {
        this.popupService.openPopup(new PopupSettings(CTAFormComponent, null, null, null, 582, 460, true));
    }

    uploadFiles(files: Array<File>) {
        const filesWrapper = new Array<any>();
        filesWrapper.push(...files);
        const formData = new FormData();
        filesWrapper.forEach((file) => {
            const fileName = file.name || file.fileName;
            formData.append(this.uploadType, file, fileName);
        });
        this.fileUploading = true;
        this.adminPanelService
            .uploadFiles(formData)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.fileUploading = false;
                    this.toastService.Success(
                        'Files successfully uploaded.'
                    );
                },
                () => {
                    this.fileUploading = false;
                    this.toastService.Error(
                        'Error occurred while uploading files. Please contact Program Administrator.'
                    );
                }
            );
    }
}
