import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { ContractorDTO } from 'src/app/models/contractor-dto';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FormService } from 'src/app/services/shared/form.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ContractorFilter } from 'src/app/store/data-maintenance/team-data/contractor/model';
import { takeWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import {
    ContractorFilterRequest,
    ContractorFilterPropertyUpdate,
    ContractorRemoveRequest,
    ContractorSaveRequest,
    ContractorFilterUpdate,
} from 'src/app/store/data-maintenance/team-data/contractor/actions';
import { OrderDirection } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AddContractorPopupComponent } from './add-popup/add-contractor-popup.component';
import { ContractorsMaintenanceService } from 'src/app/services/api/webapi-services/contractor-maintenance.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-contractors-maintenance',
    templateUrl: './contractors-maintenance.component.html',
    styleUrls: ['./contractors-maintenance.component.scss'],
})
export class ContractorsMaintenanceComponent extends BaseComponent implements OnInit {
    projectTeamNames: string[] = [];
    projectTeamNamesCurrent: string[];
    resultsLength = 0;
    pageSize = 5;
    sortBy: string;
    direction = OrderDirection.Desc;
    isLoading = false;

    contractorFilter$ = this.store.select((store) => store.contractorMaintenanceState.filter);
    contractorDataPagination$ = this.store.select((store) => store.contractorMaintenanceState.contractorDataPagination);
    contractorLoader$ = this.store.select((store) => store.contractorMaintenanceState.isLoading);

    filterForm: UntypedFormGroup;

    displayedColumns = ['projectTeamName', 'contractNo', 'contract', 'edit', 'remove'];

    data: ContractorDTO[] = [];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private formService: FormService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store<ApplicationState>,
        private popupService: PopupService,
        private contractorsMaintenanceService: ContractorsMaintenanceService,
        private lookupService: LookupService
    ) {
        super();

        this.filterForm = this.formService.createSimpleForm(new ContractorFilter());

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.lookupService
            .getProjectTeamNames()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });
        this.watchFormChanges();

        this.contractorDataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            const copy = cloneDeep(data);
            this.data = copy.items;
            this.resultsLength = copy.totalCount;
        });

        this.contractorFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.paginator.pageIndex = filter.page;
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new ContractorFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new ContractorFilterRequest());
        });

        this.contractorLoader$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => (this.isLoading = isLoading));

        this.store.dispatch(new ContractorFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new ContractorFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new ContractorFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new ContractorFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
            this.store.dispatch(new ContractorFilterUpdate({ ...this.filterForm.value } as ContractorFilter));
            this.store.dispatch(new ContractorFilterRequest());
        }
    }

    private watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new ContractorFilterPropertyUpdate({ key, value }));
            });
        }
    }

    remove(id: string) {
        this.isLoading = true;

        this.contractorsMaintenanceService.getDbLinksInfo(id).subscribe((dbLinksInfo: string) => {
            this.isLoading = false;

            if (dbLinksInfo !== '') {
                this.popupService
                    .openPopup(
                        new ConfirmDialogPopupSettings({
                            title: 'Confirm action',
                            text: dbLinksInfo,
                        })
                    )
                    .afterClosed()
                    .pipe(takeWhile(() => this.isAlive === true))
                    .subscribe((answer) => {
                        if (answer) {
                            this.removeContractorSecondConfirmation(id);
                        }
                    });
            } else {
                this.removeContractorSecondConfirmation(id);
            }
        });
    }

    removeContractorSecondConfirmation(contractNo: string) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete ${contractNo}?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.store.dispatch(new ContractorRemoveRequest(contractNo));
                }
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(
            new PopupSettings(AddContractorPopupComponent, 450, 460, { projectTeamNames: this.projectTeamNames })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    this.store.dispatch(new ContractorFilterRequest());
                }
            });
    }

    enterEditMode(contractor: ContractorDTO) {
        contractor.isInEditMode = !contractor.isInEditMode;
        contractor.prevContract = contractor.contract;
    }

    save(contractor: ContractorDTO) {
        contractor.isInEditMode = !contractor.isInEditMode;

        if (contractor.contract !== contractor.prevContract) {
            this.store.dispatch(new ContractorSaveRequest(contractor));
        }
    }

    cancelEdit(contractor: ContractorDTO) {
        contractor.isInEditMode = !contractor.isInEditMode;
        contractor.contract = contractor.prevContract;
    }
}
