import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/components/base.component';
import { WeeklyPlanType } from 'src/app/enums';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ApplicationState } from 'src/app/store/model';
import {
    ScheduleActivityNotInWeekPlanningDataRequest,
    ScheduleActivityPlanningDataRequest,
    ScheduleActivityPlanningFilterPropertyUpdate,
    ScheduleActivityWeekSummaryDataRequest,
    ScheduleActivityPlanningResetITRFilter,
} from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import { ScheduleActivityPlanningFilter, WeekSummaryFilter } from 'src/app/store/weekly-planning/schedule-activity-planning/model';
import { map, takeWhile } from 'rxjs/operators';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import { ScheduleActivityITRDataRequest, ScheduleActivityITRFilterPropertyReset, ScheduleActivityITRFilterPropertyUpdate } from 'src/app/store/schedule-activity-itr/actions';
import { ScheduleActivityITRFilter, ScheduleActivityITRScope } from 'src/app/store/schedule-activity-itr/model';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { StagedStartUpPriority, SubsystemExcludeFilterRequestDTO, Contractor } from 'src/app/store/common.model';

export const DAY_MONTH_YEAR_DATE_FORMAT = {
    parse: {
        dateInput: 'DD-MM-YYYY',
    },
    display: {
        dateInput: 'D/MMM/YY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-lookahead-filter-common-section',
    templateUrl: './lookahead-filter-common-section.component.html',
    styleUrls: ['./lookahead-filter-common-section.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: DAY_MONTH_YEAR_DATE_FORMAT },
    ],
})
export class LookaheadFilterCommonSectionComponent extends BaseComponent implements OnInit {
    @Input() planType: WeeklyPlanType;
    @Input() filterForm: UntypedFormGroup;
    @Input() displayPeriodDays: number;
    @Input() printMode?: boolean = false;
    @ViewChild('picker') picker;
    @ViewChild('weekendPicker') weekendPicker;

    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    setSubsystemInput = new EventEmitter<SetInputEventArgs>();
    setPriorityInput = new EventEmitter<SetInputEventArgs>();
    setItrContractorsInput = new EventEmitter<SetInputEventArgs>();
    setStagedStartUpPrioritiesInput = new EventEmitter<SetInputEventArgs>();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    weeklyPlanTypes = WeeklyPlanType;
    weekStart: moment.Moment;
    weekEnd:moment.Moment;
    weekStart$ = this.store.select(
        (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekStart
    );
    weekEnd$ = this.store.select(
        (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekEnd
    );
    tabIndex: number;
    tabIndex$ = this.store.select(
        (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.selectedTabIndex
    );

    constructor(
        private store: Store<ApplicationState>,
        private scheduleActivityLookupService: ScheduleActivityLookupService,
        private lookupService: LookupService
    ) {
        super();
    }

    ngOnInit(): void {
        this.weekStart$.pipe(takeWhile(() => this.isAlive)).subscribe((date) => (this.weekStart = date));
        this.weekEnd$.pipe(takeWhile(() => this.isAlive)).subscribe((date) => (this.weekEnd = date));
        this.tabIndex$.pipe(takeWhile(() => this.isAlive)).subscribe((index) => (this.tabIndex = index));
    }

    get currentlySetWeekEnd() {
        return (
            moment(this.filterForm.value?.weekStart)
                .add(this.displayPeriodDays - 1, 'day')
                .toDate() ?? ''
        );
    }

    get isNextWorkingWeek(): boolean {
        return false
    }

    dateRangePickerFilter = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    dateRangePickerFilter1 = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 5;
    };

    searchSubsystems = (search = '', takeCount = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchSubsystems(search, takeCount, page, this.filterForm.value);
    };

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    searchItrContractors = (search = '', takeCount = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchItrContractors(search, takeCount, page, this.filterForm.value);
    };

    searchPriorities = (search = '', takeCount = 10, page = 0) => {
        return this.scheduleActivityLookupService.searchPriorities(search, takeCount, page, this.filterForm.value);
    };

    searchSubsystemExcludeFilters = (search = '', takeCount = 10, page = 0) =>
        this.lookupService
        .searchExcludeValuesWithExcludeFilter({ pageSize: takeCount, page, search } as SubsystemExcludeFilterRequestDTO)
        .pipe(map((x: any[]) => x.filter(f => f.value != '').map((f) => ({id: f.value}))));

    searchItrExcludeFilters = (search = '', takeCount = 10, page = 0) =>
        this.lookupService
        .searchITRExcludeValuesWithExcludeFilter({ pageSize: takeCount, page, search } as SubsystemExcludeFilterRequestDTO)
        .pipe(map((x: any[]) => x.filter(f => f.value != '').map((f) => ({id: f.value}))));
    
    searchStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, [])
            .pipe(map((prio: StagedStartUpPriority[]) => prio));
    };

    search = () => {
        this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
                filter: { ...this.filterForm.value, page: 0, weekSummaryFilter: new WeekSummaryFilter() } as ScheduleActivityPlanningFilter,
            })
        );
        if (this.isNextWorkingWeek || this.planType === WeeklyPlanType.monthly) {
            this.store.dispatch(new ScheduleActivityPlanningDataRequest());
            this.store.dispatch(new ScheduleActivityNotInWeekPlanningDataRequest());
            this.store.dispatch(new ScheduleActivityWeekSummaryDataRequest())
        } 
        else {
            this.store.dispatch(new ScheduleActivityPlanningResetITRFilter());
            this.store.dispatch(new ScheduleActivityITRFilterPropertyReset());
            this.store.dispatch(
                new ScheduleActivityITRFilterPropertyUpdate(
                    ({ scope: ScheduleActivityITRScope.InWeek }) as ScheduleActivityITRFilter
                    )
                );
            this.store.dispatch(new ScheduleActivityITRDataRequest());
        }
    };
}
