<div>
    <app-asc-desc-order
        *ngIf="!isSortingOff"
        [(isAscendingSort)]="isAscendingSort"
        [(isDescendingSort)]="isDescendingSort"
    ></app-asc-desc-order>
    <mat-form-field class="mat-input-field">
        <mat-icon>iconCalendar</mat-icon>
        <input placeholder="{{ placeHolder }}" matInput [value]="selectedRangeValue | rangeDate" readonly />
        <span matSuffix (click)="clearDate()">&times;</span>
    </mat-form-field>
    <div class="inlineCalendarContainer">
        <mat-calendar [selected]="selectedRangeValue" (selectedChange)="inlineRangeChange($event)"> </mat-calendar>
    </div>
    <mat-radio-group aria-label="Select an option">
        <mat-radio-button
            [checked]="excludeBlanks === false && onlyBlanks === false"
            value="all"
            (change)="updateBlanks($event)"
            disableRipple
            >All</mat-radio-button
        >
        <mat-radio-button
            [checked]="excludeBlanks === true"
            value="excludeBlanks"
            (change)="updateBlanks($event)"
            disableRipple
            >Exclude blanks</mat-radio-button
        >
        <mat-radio-button
            [checked]="onlyBlanks === true"
            value="onlyBlanks"
            (change)="updateBlanks($event)"
            disableRipple
            >Only blanks</mat-radio-button
        >
    </mat-radio-group>
    <div class="buttons-container flex">
        <button type="button" (click)="close()" class="sct-button sct-button-white sct-button-small">cancel</button>
        <button type="button" (click)="onSubmit()" class="sct-button sct-button-light sct-button-small ok-button">
            ok
        </button>
    </div>
</div>
