import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
    ManualUploadActionTypes,
    ManualUploadFilesUploadRequest,
    ManualUploadFilesUploadSuccess,
    ManualUploadFilesUploadError,
} from './actions';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';

@Injectable()
export class ManualUploadEffects {
    constructor(
        private actions$: Actions,
        private adminPanelService: AdminPanelService,
        private toastService: ToastService
    ) {}

    @Effect()
    uploadFiles$ = this.actions$.pipe(
        ofType<ManualUploadFilesUploadRequest>(ManualUploadActionTypes.ManualUploadFilesUploadRequest),
        mergeMap((action) =>
            this.adminPanelService.uploadFiles(action.payload).pipe(
                map(() => {
                    this.toastService.Success(
                        'Files successfully uploaded. Please check progress indicator and table with logs to check status of preprocessing.'
                    );
                    return new ManualUploadFilesUploadSuccess();
                }),
                catchError((error) => {
                    if (error?.error === 'AutomaticUploadPipelineInProgress') {
                        this.toastService.Info(
                            'Cannot upload the data while automatic upload pipeline is in progress.'
                        );
                    } else {
                        this.toastService.Error(
                            'Error has occurred while uploading file(s) to the server. Please contact Program Administrator'
                        );
                    }
                    return of(new ManualUploadFilesUploadError(error));
                })
            )
        )
    );
}
