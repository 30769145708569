import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, finalize, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { ResponsibleGroup } from 'src/app/models/responsible-group';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-responsible-group',
  templateUrl: './responsible-group.component.html',
  styleUrls: ['./responsible-group.component.scss']
})
export class ResponsibleGroupComponent implements OnInit  {
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isLoading = false;
  resultsLength = 0;
  pageSize = 5;
  responsibleGroup: ResponsibleGroup[] = [];
  displayedColumns = ['id','name', 'actions'];
  responsibleGroupData: MatTableDataSource<ResponsibleGroup>;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private configurationService: ConfigurationService,
    private toastService: ToastService
  ) {

    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
    );
    this.iconRegistry.addSvgIcon(
      'delete',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
    );
    this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
  }

  ngOnInit(): void {

    this.configurationService
      .getAllResponsbileGroup()
      .pipe(take(1))
      .subscribe((data) => {
        this.responsibleGroup = [
          ...data.map((d) => ({ id: d.id, name: d.name, isInEditMode: false, prevName: '' })),
        ];
        this.responsibleGroupData = new MatTableDataSource(this.responsibleGroup);
        this.resultsLength = this.responsibleGroupData.data.length;
        this.responsibleGroupData.paginator = this.paginator;
      });
  }

  add() {
     this.responsibleGroup = [{ id: 0, name: '', isInEditMode: true, prevName: '' }, ...this.responsibleGroup ];
     this.responsibleGroupData.data = this.responsibleGroup;
    // this.responsibleGroup.push({ id: 0, name: '', isInEditMode: true, prevName: '' });
    // this.responsibleGroupData = new MatTableDataSource(this.responsibleGroup);
    // this.resultsLength = this.responsibleGroupData.data.length;
    // this.responsibleGroupData.paginator = this.paginator;
     this.responsibleGroupData.paginator.firstPage();
  }

  onPageChange(newPage: number) {
    if (newPage < 1) {
        newPage = 1;
    } else if (newPage > this.paginator.getNumberOfPages()) {
        newPage = this.paginator.getNumberOfPages();
    }
    this.paginator.pageIndex = newPage - 1;
    this.responsibleGroupData.paginator = this.paginator;
}

  remove(element: ResponsibleGroup) {
    this.isLoading = true;
    this.configurationService
      .deleteResponsibleGroup(element.id)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false)),
        catchError((error) => {
          this.toastService.Info(
            'This Responsible Group is linked in several places , if you need it to be deleted. Please contact Program Administrator.'
          );
          return of(error);
        })
      )
      .subscribe((id) => {
        if(id > 0) {
          this.responsibleGroup = [..._.filter(this.responsibleGroup, (c) => c.id !== id)];
          this.responsibleGroupData.data = this.responsibleGroup;
          this.toastService.Success('Responsible Group has been removed.');       
        }
      });
  }

  enterEditMode(element: ResponsibleGroup) {
    element.isInEditMode = true;
    element.prevName = element.name;
  }

  cancelEdit(element: ResponsibleGroup) {
    element.isInEditMode = false;
    element.name = element.prevName;
    this.responsibleGroup = [..._.filter(this.responsibleGroup, (c) => c.id > 0)];
      this.responsibleGroupData.data = this.responsibleGroup;
      this.resultsLength = this.responsibleGroupData.data.length;
      this.responsibleGroupData.paginator = this.paginator;
  }

  update(element: ResponsibleGroup) {
    this.isLoading = true;
    this.configurationService
      .updateResponsibleGroup(element)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          element.isInEditMode = false;
        }),
        catchError((error) => {
          this.toastService.Error(
            'Error has occurred while updating/creating Responsible Group. Please contact Program Administrator.'
          );
          return of(error);
        })
      )
      .subscribe((data) => {
        this.toastService.Success(
          `Responible Group has been successfully ${data.id !== element.id ? 'created.' : 'updated.'}`
        );
        element.id = data.id;
        this.responsibleGroupData.data =  this.responsibleGroupData.data.sort((a,b) => a.id < b.id ? -1 : a.id > b.id ? 1: 0);
      });
  }

}
