import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { ConstraintFlagState } from 'src/app/models/constraintflag-dto';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-constraintflag',
    templateUrl: './constraintflag.component.html',
    styleUrls: ['./constraintflag.component.scss'],
})
export class ConstraintflagComponent implements OnInit {
    isLoading = false;
    constraintFlags: ConstraintFlagState[] = [];

    constructor(
        private lookupService: LookupService,
        private configurationService: ConfigurationService,
        private toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.lookupService
            .getConstraintFlags()
            .pipe(take(1))
            .subscribe((data) => {
                this.constraintFlags = [
                    ...data.map(
                        (d) => ({ id: d.id, name: d.name, isInEditMode: false, prevName: '' } as ConstraintFlagState)
                    ),
                ];
            });
    }

    addConstraintFlag() {
        this.constraintFlags = [...this.constraintFlags, { id: 0, name: '', isInEditMode: true, prevName: '' }];
    }

    enterEditMode(element: ConstraintFlagState) {
        element.isInEditMode = true;
        element.prevName = element.name;
    }

    cancelEdit(element: ConstraintFlagState) {
        element.isInEditMode = false;
        element.name = element.prevName;
        this.constraintFlags = [..._.filter(this.constraintFlags, (c) => c.id > 0)];
    }

    update(element: ConstraintFlagState) {
        this.isLoading = true;
        this.configurationService
            .updateConstraintFlag(element)
            .pipe(
                take(1),
                finalize(() => {
                    this.isLoading = false;
                    element.isInEditMode = false;
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while updating/creating Constraint Flag. Please contact Program Administrator.'
                    );
                    return of(error);
                })
            )
            .subscribe((data) => {
                this.toastService.Success(
                    `Constraint Flag has been successfully ${data.id !== element.id ? 'created.' : 'updated.'}`
                );
                element.id = data.id;
            });
    }

    remove(element: ConstraintFlagState) {
        this.isLoading = true;
        this.configurationService
            .deleteConstraintFlag(element.id)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while deleting Constraint Flag. Please contact Program Administrator.'
                    );
                    return of(error);
                })
            )
            .subscribe((id) => {
                this.constraintFlags = [..._.filter(this.constraintFlags, (c) => c.id !== id)];
                this.toastService.Success('Constraint Flag has been removed.');
            });
    }
}

