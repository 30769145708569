import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BaseComponent } from 'src/app/components/base.component';
import { WeekType } from 'src/app/enums';
import { Constants } from 'src/app/constants';
import { map, takeWhile } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
    PlanningLookaheadReportDataItem,
    PlanningLookaheadState,
    PlanningLookaheadTotalItem,
} from 'src/app/store/reports/planning/model';
import * as moment from 'moment';

@Component({
    selector: 'app-reports-planning-lookahead-table',
    templateUrl: './reports-planning-lookahead-table.component.html',
    styleUrls: ['./reports-planning-lookahead-table.component.scss'],
})
export class ReportsPlanningLookaheadTableComponent extends BaseComponent implements OnInit {
    @Input() lookahead$: Observable<PlanningLookaheadState>;

    weekTypes = WeekType;

    pastWeeks$;
    upcomingWeeks$;
    gpData$: Observable<PlanningLookaheadReportDataItem[]>;
    giData$: Observable<PlanningLookaheadReportDataItem[]>;
    gpTotals$: Observable<PlanningLookaheadTotalItem[]>;
    giTotals$: Observable<PlanningLookaheadTotalItem[]>;
    grandTotals$: Observable<PlanningLookaheadTotalItem[]>;
    selectedProjectTeamNames$: Observable<string[]>;
    lookaheadType$: Observable<string>;
    lookaheadType: string;
    is3gpSelected = true;
    is3giSelected = true;
    pastWeeks = [];
    upcomingWeeks = [];

    ngOnInit() {
        this.pastWeeks$ = this.lookahead$.pipe(
            map((lookahead) => _.filter(lookahead.weeks, (week) => week.weekType === WeekType.Past))
        );
        this.pastWeeks$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.pastWeeks = data.map((s) => {
                return { week: moment(s.date).format('w'), date: s.weekEnd };
            });
        });
        this.upcomingWeeks$ = this.lookahead$.pipe(
            map((lookahead) => _.filter(lookahead.weeks, (week) => week.weekType === WeekType.Upcoming))
        );

        this.upcomingWeeks$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.upcomingWeeks = data.map((s) => {
                return { week: moment(s.date).format('w'), date: s.weekEnd };
            });
        });

        this.gpData$ = this.lookahead$.pipe(
            map((lookahead) =>
                lookahead.data ? lookahead.data.filter((i) => i.projectTeamName === Constants.projectTeamNames.GP) : []
            )
        );
        this.giData$ = this.lookahead$.pipe(
            map((lookahead) =>
                lookahead.data ? lookahead.data.filter((i) => i.projectTeamName === Constants.projectTeamNames.GI) : []
            )
        );
        this.gpTotals$ = this.lookahead$.pipe(map((lookahead) => lookahead.gpTotals));
        this.giTotals$ = this.lookahead$.pipe(map((lookahead) => lookahead.giTotals));
        this.grandTotals$ = this.lookahead$.pipe(map((lookahead) => lookahead.grandTotals));
        this.selectedProjectTeamNames$ = this.lookahead$.pipe(map((lookahead) => lookahead.filter.projectTeamNames));
        this.lookaheadType$ = this.lookahead$.pipe(map((lookahead) => lookahead.lookaheadType));

        this.selectedProjectTeamNames$.subscribe((teams) => {
            this.is3gpSelected = teams.filter((t) => t === Constants.projectTeamNames.GP).length > 0;
            this.is3giSelected = teams.filter((t) => t === Constants.projectTeamNames.GI).length > 0;
        });
        this.lookaheadType$.pipe(takeWhile(() => this.isAlive)).subscribe((lookaheadType) => {
            this.lookaheadType = lookaheadType;
        });
    }
}
