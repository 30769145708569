import { Action } from '@ngrx/store';
import { TotalCompleteDataDTO, RundownDataDTO, MilestoneDashboardType, MilestoneCommentType } from './model';
import { IcicleDTO } from '../icicle/model';
import { Gooc, RFO, UserDetail } from '../../common.model';
import { CommentDetailDTO } from '../../detailed-status/model';
import { Schedule } from '../pre-mc/model';
import { SkylineResponseDTO } from '../skyline/model';
import { PlanningLookaheadDTO } from '../planning/model';
import { SubsystemMCDashboardDTO } from '../subsystem-mc/model';

export enum MilestoneDashboardActionTypes {
    MilestoneDashboardTotalCompleteRequest = '[Milestone Dashboard] Total Complete Request',
    MilestoneDashboardTotalCompleteRequestSuccess = '[Milestone Dashboard] Total Complete Success',
    MilestoneDashboardTotalCompleteRequestError = '[Milestone Dashboard] Total Complete Error',
    MilestoneDashboardFilterUpdate = '[Milestone Dashboard] Filter Update',
    MilestoneDashboardFilterReset = '[Milestone Dashboard] Filter Reset',
    MCMilestoneDashboardIcicleRequest = '[MC Milestone Dashboard] Icicle Request',
    MCMilestoneDashboardIcicleRequestSuccess = '[MC Milestone Dashboard] IcicleRequest Success',
    MCMilestoneDashboardIcicleRequestError = '[MC Milestone Dashboard] Icicle Request Error',
    RFOMilestoneDashboardIcicleRequest = '[RFO Milestone Dashboard] Icicle Request',
    RFOMilestoneDashboardIcicleRequestSuccess = '[RFO Milestone Dashboard] IcicleRequest Success',
    RFOMilestoneDashboardIcicleRequestError = '[RFO Milestone Dashboard] Icicle Request Error',
    MilestoneDashboardRundownRequest = '[Milestone Dashboard] Rundown Request',
    MilestoneDashboardRundownSuccess = '[Milestone Dashboard] Rundown Success',
    MilestoneDashboardRundownError = '[Milestone Dashboard] Rundown Error',
    MilestoneDashboardSetType = '[Milestone Dashboard] Set Type',
    MilestoneDashboardWeekRangeSet = '[Milestone Dashboard] Set Week Range',
    MilestoneDashboardRedirectToDetailedStatus = '[Milestone Dashboard] Redirect To Detailed Status',
    MilestoneDashboardCommentsRequest = '[Milestone Dashboard] Comments Request',
    MilestoneDashboardCommentsSuccess = '[Milestone Dashboard] Comments Success',
    MilestoneDashboardCommentsError = '[Milestone Dashboard] Comments Error',
    MilestoneDashboardAddCommentRequest = '[Milestone Dashboard] Add Comment Request',
    MilestoneDashboardAddCommentSuccess = '[Milestone Dashboard] Add Comment Success',
    MilestoneDashboardAddCommentError = '[Milestone Dashboard] Add Comment Error',
    MilestoneDashboardRemoveCommentRequest = '[Milestone Dashboard] Remove Comment Request',
    MilestoneDashboardRemoveCommentSuccess = '[Milestone Dashboard] Remove Comment Success',
    MilestoneDashboardRemoveCommentError = '[Milestone Dashboard] Remove Comment Error',

    MCMilestoneDashboardSkylineRequest = '[MC Milestone Dashboard Skyline] Request',
    MCMilestoneDashboardSkylineSuccess = '[MC Milestone Dashboard Skyline] Success',
    MCMilestoneDashboardSkylineError = '[MC Milestone Dashboard Skyline] Error',

    RFSUMilestoneDashboardSkylineRequest = '[RFSU Milestone Dashboard Skyline] Request',
    RFSUMilestoneDashboardSkylineSuccess = '[RFSU Milestone Dashboard Skyline] Success',
    RFSUMilestoneDashboardSkylineError = '[RFSU Milestone Dashboard Skyline] Error',

    MCMilestoneDashboardLookaheadRequest = '[MC Milestone Dashboard] Lookahead Request',
    MCMilestoneDashboardLookaheadSuccess = '[MC Milestone Dashboard] Lookahead Success',
    MCMilestoneDashboardLookaheadError = '[MC Milestone Dashboard] Lookahead Error',

    RFSUMilestoneDashboardLookaheadRequest = '[RFSU Milestone Dashboard] Lookahead Request',
    RFSUMilestoneDashboardLookaheadSuccess = '[RFSU Milestone Dashboard] Lookahead Success',
    RFSUMilestoneDashboardLookaheadError = '[RFSU Milestone Dashboard] Lookahead Error',

    MCMilestoneDashboardSubsystemTableRequest = '[MC Milestone Dashboard] Subsystem Table Request',
    MCMilestoneDashboardSubsystemTableSuccess = '[MC Milestone Dashboard] Subsystem Table Success',
    MCMilestoneDashboardSubsystemTableError = '[MC Milestone Dashboard] Subsystem Table Error',

    ScheduleRequest = '[MC Milestone Dashboard Schedule] Request',
    ScheduleSuccess = '[MC Milestone Dashboard Schedule] Success',
    ScheduleError = '[MC Milestone Dashboard Schedule] Error',
    ScheduleSaveRequest = '[MC Milestone Dashboard Schedule] Save Request',
    ScheduleSaveSuccess = '[MC Milestone Dashboard Schedule] Save Success',
    ScheduleSaveError = '[MC Milestone Dashboard Schedule] Save Error',

    MCMilestoneDashboardResetCharts = '[MC Milestone Dashboard Schedule] Reset Charts',
}

export class MCMilestoneDashboardSkylineRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardSkylineRequest;

    constructor(public payload: Gooc) {
        // This is intentional
    }
}

export class RFSUMilestoneDashboardSkylineRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.RFSUMilestoneDashboardSkylineRequest;

    constructor(public payload: RFO) {
        // This is intentional
    }
}

export class MCMilestoneDashboardResetCharts implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardResetCharts;

    constructor() {
        // This is intentional
    }
}

export class MCMilestoneDashboardSkylineSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardSkylineSuccess;

    constructor(public payload: SkylineResponseDTO[]) {
        // This is intentional
    }
}

export class MCMilestoneDashboardSkylineError implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardSkylineError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class RFSUMilestoneDashboardSkylineSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.RFSUMilestoneDashboardSkylineSuccess;

    constructor(public payload: SkylineResponseDTO[]) {
        // This is intentional
    }
}

export class RFSUMilestoneDashboardSkylineError implements Action {
    readonly type = MilestoneDashboardActionTypes.RFSUMilestoneDashboardSkylineError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class MCMilestoneDashboardLookaheadRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardLookaheadRequest;

    constructor(public payload: Gooc) {
        // This is intentional
    }
}

export class MCMilestoneDashboardLookaheadSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardLookaheadSuccess;

    constructor(public payload: PlanningLookaheadDTO[]) {
        // This is intentional
    }
}

export class MCMilestoneDashboardLookaheadError implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardLookaheadError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class RFSUMilestoneDashboardLookaheadRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.RFSUMilestoneDashboardLookaheadRequest;

    constructor(public payload: RFO) {
        // This is intentional
    }
}

export class RFSUMilestoneDashboardLookaheadSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.RFSUMilestoneDashboardLookaheadSuccess;

    constructor(public payload: PlanningLookaheadDTO[]) {
        // This is intentional
    }
}

export class RFSUMilestoneDashboardLookaheadError implements Action {
    readonly type = MilestoneDashboardActionTypes.RFSUMilestoneDashboardLookaheadError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class MCMilestoneDashboardSubsystemTableRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardSubsystemTableRequest;

    constructor(public payload: Gooc) {
        // This is intentional
    }
}

export class MCMilestoneDashboardSubsystemTableSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardSubsystemTableSuccess;

    constructor(public payload: SubsystemMCDashboardDTO[]) {
        // This is intentional
    }
}

export class MCMilestoneDashboardSubsystemTableError implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardSubsystemTableError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ScheduleRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.ScheduleRequest;

    constructor(public payload: Gooc) {
        // This is intentional
    }
}

export class ScheduleSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.ScheduleSuccess;

    constructor(public payload: Schedule) {
        // This is intentional
    }
}

export class ScheduleError implements Action {
    readonly type = MilestoneDashboardActionTypes.ScheduleError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ScheduleSaveError implements Action {
    readonly type = MilestoneDashboardActionTypes.ScheduleSaveError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ScheduleSaveRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.ScheduleSaveRequest;

    constructor(public payload: Schedule) {
        // This is intentional
    }
}

export class ScheduleSaveSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.ScheduleSaveSuccess;

    constructor(public payload: Schedule) {
        // This is intentional
    }
}

export class MilestoneDashboardWeekRangeSet implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardWeekRangeSet;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class MilestoneDashboardTotalCompleteRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardTotalCompleteRequest;
}

export class MilestoneDashboardFilterReset implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardFilterReset;

    constructor(public payload: { type: string; }) {
        // This is intentional
    }
}

export class MilestoneDashboardTotalCompleteRequestSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardTotalCompleteRequestSuccess;

    constructor(public payload: TotalCompleteDataDTO[]) {
        // This is intentional
    }
}

export class MilestoneDashboardTotalCompleteRequestError implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardTotalCompleteRequestError;

    constructor(public error: string) {
        // This is intentional
    }
}

export class MilestoneDashboardFilterUpdate implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardFilterUpdate;

    constructor(public payload: { filter: any; type: MilestoneDashboardType; }) {
        // This is intentional
    }
}

export class MCMilestoneDashboardIcicleRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardIcicleRequest;
}

export class MCMilestoneDashboardIcicleRequestSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardIcicleRequestSuccess;

    constructor(public payload: { data: IcicleDTO[]; type: string }) {
        // This is intentional
    }
}

export class MCMilestoneDashboardIcicleRequestError implements Action {
    readonly type = MilestoneDashboardActionTypes.MCMilestoneDashboardIcicleRequestError;

    constructor(public error: string) {
        // This is intentional
    }
}

export class RFOMilestoneDashboardIcicleRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.RFOMilestoneDashboardIcicleRequest;
}

export class RFOMilestoneDashboardIcicleRequestSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.RFOMilestoneDashboardIcicleRequestSuccess;

    constructor(public payload: { data: IcicleDTO[]; type: string }) {
        // This is intentional
    }
}

export class RFOMilestoneDashboardIcicleRequestError implements Action {
    readonly type = MilestoneDashboardActionTypes.RFOMilestoneDashboardIcicleRequestError;

    constructor(public error: string) {
        // This is intentional
    }
}

export class MilestoneDashboardRundownRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardRundownRequest;

    constructor(public payload: { monthlyView: boolean }) {
        // This is intentional
    }
}
export class MilestoneDashboardRundownSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardRundownSuccess;

    constructor(public payload: RundownDataDTO[]) {
        // This is intentional
    }
}
export class MilestoneDashboardRundownError implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardRundownError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class MilestoneDashboardSetType implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardSetType;

    constructor(public payload: MilestoneDashboardType) {
        // This is intentional
    }
}

export class MilestoneDashboardRedirectToDetailedStatus implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardRedirectToDetailedStatus;

    constructor(public payload: { category: string; discipline: string; exceptions: boolean; zone: string; }) {
        // This is intentional
    }
}

export class MilestoneDashboardCommentsRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardCommentsRequest;

    constructor() {
        // This is intentional
    }
}
export class MilestoneDashboardCommentsSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardCommentsSuccess;

    constructor(
        public payload: {
            progress: CommentDetailDTO[];
            forecast: CommentDetailDTO[];
            issues: CommentDetailDTO[];
            type: string;
        }[]
    ) {
        // This is intentional
    }
}
export class MilestoneDashboardCommentsError implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardCommentsError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class MilestoneDashboardAddCommentRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardAddCommentRequest;

    constructor(
        public payload: {
            entityId: string;
            description: string;
            mentions: UserDetail[];
            type: MilestoneCommentType;
            userType: string;
        }
    ) {
        // This is intentional
    }
}

export class MilestoneDashboardAddCommentSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardAddCommentSuccess;

    constructor(public payload: { newComment: CommentDetailDTO; type: MilestoneCommentType; userType: string }) {
        // This is intentional
    }
}

export class MilestoneDashboardAddCommentError implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardAddCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class MilestoneDashboardRemoveCommentRequest implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardRemoveCommentRequest;

    constructor(public payload: { id: number; type: MilestoneCommentType; userType: string }) {
        // This is intentional
    }
}

export class MilestoneDashboardRemoveCommentSuccess implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardRemoveCommentSuccess;

    constructor(public payload: { id: number; type: MilestoneCommentType; userType: string }) {
        // This is intentional
    }
}

export class MilestoneDashboardRemoveCommentError implements Action {
    readonly type = MilestoneDashboardActionTypes.MilestoneDashboardRemoveCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export type MilestoneDashboardActions =
    | MilestoneDashboardTotalCompleteRequest
    | MilestoneDashboardTotalCompleteRequestSuccess
    | MilestoneDashboardTotalCompleteRequestError
    | MilestoneDashboardFilterUpdate
    | MilestoneDashboardFilterReset
    | MCMilestoneDashboardIcicleRequest
    | MCMilestoneDashboardIcicleRequestSuccess
    | MCMilestoneDashboardIcicleRequestError
    | RFOMilestoneDashboardIcicleRequest
    | RFOMilestoneDashboardIcicleRequestSuccess
    | RFOMilestoneDashboardIcicleRequestError
    | MilestoneDashboardRundownRequest
    | MilestoneDashboardRundownSuccess
    | MilestoneDashboardRundownError
    | MilestoneDashboardSetType
    | MilestoneDashboardWeekRangeSet
    | MilestoneDashboardRedirectToDetailedStatus
    | MilestoneDashboardCommentsRequest
    | MilestoneDashboardCommentsSuccess
    | MilestoneDashboardCommentsError
    | MilestoneDashboardAddCommentRequest
    | MilestoneDashboardAddCommentSuccess
    | MilestoneDashboardAddCommentError
    | MilestoneDashboardRemoveCommentRequest
    | MilestoneDashboardRemoveCommentSuccess
    | MilestoneDashboardRemoveCommentError
    | MCMilestoneDashboardSkylineRequest
    | MCMilestoneDashboardSkylineSuccess
    | MCMilestoneDashboardSkylineError
    | RFSUMilestoneDashboardSkylineRequest
    | RFSUMilestoneDashboardSkylineSuccess
    | RFSUMilestoneDashboardSkylineError
    | ScheduleRequest
    | ScheduleSuccess
    | ScheduleError
    | ScheduleSaveRequest
    | ScheduleSaveSuccess
    | ScheduleSaveError
    | MCMilestoneDashboardLookaheadRequest
    | MCMilestoneDashboardLookaheadSuccess
    | MCMilestoneDashboardLookaheadError
    | RFSUMilestoneDashboardLookaheadRequest
    | RFSUMilestoneDashboardLookaheadSuccess
    | RFSUMilestoneDashboardLookaheadError
    | MCMilestoneDashboardSubsystemTableRequest
    | MCMilestoneDashboardSubsystemTableSuccess
    | MCMilestoneDashboardSubsystemTableError
    | MCMilestoneDashboardResetCharts;
