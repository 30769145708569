import { Action } from '@ngrx/store';
import * as moment from 'moment';
import { UserDetail } from '../common.model';
import { CommentDetailDTO } from '../detailed-status/model';
import { PascrStatusDataPagination, PascrStatusFilter } from './model';

export enum PascrStatusActionTypes {
    PascrStatusFilterRequest = '[PAS CR Status] Filter Request',
    PascrStatusFilterSuccess = '[PAS CR Status] Filter Success',
    PascrStatusFilterError = '[PAS CR Status] Filter Error',
    PascrStatusFilterPropertyUpdate = '[PAS CR Status] Property Update',
    PascrStatusFilterReset = '[PAS CR Status] Filter Reset',
    PascrStatusAddCommentRequest = '[PAS CR Status] Add Comment Request',
    PascrStatusAddCommentSuccess = '[PAS CR Status] Add Comment Success',
    PascrStatusAddCommentError = '[PAS CR Status] Add Comment Error',
    PascrStatusAddBulkCommentRequest = '[PAS CR Status] Add Bulk Comment Request',
    PascrStatusAddBulkCommentSuccess = '[PAS CR Status] Add Bulk Comment Success',
    PascrStatusAddBulkCommentError = '[PAS CR Status] Add Bulk Comment Error',
    PascrStatusRemoveCommentRequest = '[PAS CR Status] Remove Comment Request',
    PascrStatusRemoveCommentSuccess = '[PAS CR Status] Remove Comment Success',
    PascrStatusRemoveCommentError = '[PAS CR Status] Remove Comment Error',
    PascrStatusExportToExcelRequest = '[PAS CR Status] Export to Excel Request',
    PascrStatusExportToExcelSuccess = '[PAS CR Status] Export to Excel Success',
    PascrStatusExportToExcelError = '[PAS CR Status] Export to Excel Error',
}

export class PascrStatusFilterRequest implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusFilterRequest;

    constructor(public payload: PascrStatusFilter = null) {}
}

export class PascrStatusAddCommentRequest implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusAddCommentRequest;

    constructor(public payload: { pascrNumber: string; description: string; mentions: UserDetail[] }) {}
}

export class PascrStatusAddCommentSuccess implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusAddCommentSuccess;

    constructor(public payload: { pascrNumber: string; description: string; mentions: UserDetail[] }) {}
}

export class PascrStatusAddCommentError implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusAddCommentError;

    constructor(public payload: string) {}
}

export class PascrStatusAddBulkCommentRequest implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusAddBulkCommentRequest;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class PascrStatusAddBulkCommentSuccess implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusAddBulkCommentSuccess;

    constructor(public payload: { description: string; mentions: UserDetail[] }) {}
}

export class PascrStatusAddBulkCommentError implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusAddBulkCommentError;

    constructor(public payload: string) {}
}

export class PascrStatusRemoveCommentRequest implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusRemoveCommentRequest;

    constructor(public payload: { id: number; pascrNumber: string; lastComment: CommentDetailDTO }) {}
}

export class PascrStatusRemoveCommentSuccess implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusRemoveCommentSuccess;

    constructor(public payload: { id: number; pascrNumber: string; lastComment: CommentDetailDTO }) {}
}

export class PascrStatusRemoveCommentError implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusRemoveCommentError;

    constructor(public payload: string) {}
}

export class PascrStatusFilterSuccess implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusFilterSuccess;

    constructor(public payload: PascrStatusDataPagination) {}
}

export class PascrStatusFilterError implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusFilterError;

    constructor(public payload: string) {}
}

export class PascrStatusFilterPropertyUpdate implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class PascrStatusFilterReset implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusFilterReset;
}

export class PascrStatusExportToExcelRequest implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusExportToExcelRequest;
}

export class PascrStatusExportToExcelSuccess implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class PascrStatusExportToExcelError implements Action {
    readonly type = PascrStatusActionTypes.PascrStatusExportToExcelError;

    constructor(public payload: string) {}
}

export type PascrStatusActions =
    | PascrStatusFilterRequest
    | PascrStatusFilterSuccess
    | PascrStatusFilterError
    | PascrStatusFilterPropertyUpdate
    | PascrStatusFilterReset
    | PascrStatusExportToExcelRequest
    | PascrStatusExportToExcelSuccess
    | PascrStatusExportToExcelError
    | PascrStatusRemoveCommentRequest
    | PascrStatusRemoveCommentSuccess
    | PascrStatusRemoveCommentError
    | PascrStatusAddCommentRequest
    | PascrStatusAddCommentSuccess
    | PascrStatusAddCommentError
    | PascrStatusAddBulkCommentRequest
    | PascrStatusAddBulkCommentSuccess
    | PascrStatusAddBulkCommentError;
