<div class="results-container">
    <table mat-table [dataSource]="weekSummaryDataSource">
        <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef>L4 Schedule</th>
            <td mat-cell *matCellDef="let row">{{ row.plan }}</td>
        </ng-container>
        <ng-container matColumnDef="#OfActivities">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <span># of Activities</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="commitedAsPerPlan">
            <th mat-header-cell *matHeaderCellDef>Committed as per Schedule</th>
            <td mat-cell *matCellDef="let row">
                <span class="cursor-pointer">
                    <a (click)="clearFilter(); weekSummaryFilter.showCommittedAsPerPlan = true; filterItems()">{{
                        row.committedAsPerPlan
                    }}</a>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="commitedOutOfPlan">
            <th mat-header-cell *matHeaderCellDef>Committed out of Schedule</th>
            <td mat-cell *matCellDef="let row">
                <span class="cursor-pointer">
                    <a (click)="clearFilter(); weekSummaryFilter.showCommittedOutOfPlan = true; filterItems()">{{
                        row.committedOutOfPlan
                    }}</a>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="notCommitedAndRequiredForRFSU">
            <th mat-header-cell *matHeaderCellDef>Uncommitted & Rq'd for Forecast WD</th>
            <td mat-cell *matCellDef="let row">
                <span class="cursor-pointer">
                    <a (click)="clearFilter(); weekSummaryFilter.showNotCommittedAndRequiredForRFSU = true; filterItems()">{{
                        row.notCommittedAndRequiredForRFSU
                    }}</a>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="notConstrainedAndNotCommited">
            <th mat-header-cell *matHeaderCellDef>Not Constrained & Not Committed</th>
            <td mat-cell *matCellDef="let row">
                <span class="cursor-pointer">
                    <a
                        (click)="
                            clearFilter(); weekSummaryFilter.showNotConstrainedAndNotCommitted = true; filterItems()
                        "
                        >{{ row.notConstrainedAndNotCommitted }}</a
                    >
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="constrainedAndNotCommited">
            <th mat-header-cell *matHeaderCellDef>Constrained & Not Committed</th>
            <td mat-cell *matCellDef="let row">
                <span class="cursor-pointer">
                    <a
                        (click)="clearFilter(); weekSummaryFilter.showConstrainedAndNotCommitted = true; filterItems()"
                        >{{ row.constrainedAndNotCommitted }}</a
                    >
                </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
