<div class="margin-bottom20">
    <button mat-button (click)="add()" class="add">
        <mat-icon>done</mat-icon>
        Add new change
    </button>

    <button mat-button (click)="cancel()">
        Cancel
    </button>
</div>

<form [formGroup]="addNewChangeForm">
    <div>
        <mat-form-field class="container170 margin-right20">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type" (openedChange)="onTypeClosed($event)">
                <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="matselectors-container">
        <mat-form-field class="container300">
            <mat-label>Change Document No</mat-label>
            <input matInput formControlName="changeDocumentNo" />
        </mat-form-field>

        <mat-form-field class="container170">
            <mat-label>Revision</mat-label>
            <input matInput formControlName="revision" />
        </mat-form-field>

        <mat-form-field class="container170">
            <mat-label>Issued date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="issuedDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="container170">
            <mat-label>Discipline</mat-label>
            <mat-select formControlName="discipline">
                <mat-option *ngFor="let discipline of disciplines" [value]="discipline">{{ discipline }} </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="container170">
            <mat-label>Change Status</mat-label>
            <mat-select formControlName="changeStatus">
                <mat-option *ngFor="let is of designStatuses" [value]="is">{{ is }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-form-field class="container100pct">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" />
    </mat-form-field>

    <div class="mixedapp-container">
        <mat-form-field class="container170 margin-right20">
            <mat-label>Project Team</mat-label>
            <mat-select formControlName="projectTeam" (openedChange)="onProjectTeamsClosed($event)">
                <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
            </mat-select>
        </mat-form-field>

        <app-multiple-items-selector
            class="container300 margin-right20"
            [setInput]="setContractorInput"
            formControlName="contractor"
            [searchFunc]="getContractorsMulti"
            [isAsync]="true"
            [isTableAutoComplete]="true"
            [displayedColumns]="autocompleteDisplayedColumns"
            [filterForm]="addNewChangeForm"
            [formSetter]="'contractor'"
            [propertyToShow]="'contractNo'"
            [placeholder]="'Contractor'"
            [oneItemMode]="true"
            [isSelectAll]="false"
            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
            [secondPropertyToShow]="'contract'"
        >
        </app-multiple-items-selector>

    </div>
    <div class="mixedapp-container">
        <app-multiple-items-selector
            class="container580 margin-right20"
            formControlName="subsystems"
            [searchFunc]="getSubsystems"
            [isAsync]="true"
            placeholder="Subsystems"
            [setInput]="setSubsystemInput"
            [isSelectAll]="false"
            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
        >
        </app-multiple-items-selector>

        <app-multiple-items-selector
            class="container620"
            formControlName="tags"
            [searchFunc]="getTags"
            [setInput]="setTagsInput"
            [isAsync]="true"
            [placeholder]="'Tags'"
            [isTableAutoComplete]="true"
            [displayedColumns]="autocompleteTagsDisplayedColumns"
            [isSelectAll]="false"
            [propertyToShow]="'tagNo'"
            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
        >
        </app-multiple-items-selector>
    </div>
    <div>
        <mat-form-field class="wide-width">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" />
        </mat-form-field>
    </div>

</form>

<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
