import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    IMDBActionTypes,
    IMDBUploadLogSuccess,
    IMDBUploadLogError,
    IMDBSetStatusAndStartDate,
    IMDBClearInProgressSpinner,
    IMDBDataInfoSuccess,
    IMDBDataInfoError,
    IMDBDataInfoRequest,
    IMDBDownloadOutputDataRequest,
    IMDBDownloadOutputDataSuccess,
    IMDBDownloadOutputDataError,
    IMDBValidateDeltaSuccess,
    IMDBValidateDeltaError,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class IMDBEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private manualUploadsService: ManualUploadService,
        private adminPanelService: AdminPanelService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(IMDBActionTypes.IMDBUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['IMDB']).pipe(
                map((uploadLogData) => new IMDBUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting logs for IMDB. Please contact Program Administrator.'
                    );
                    return of(new IMDBUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<IMDBUploadLogSuccess>(IMDBActionTypes.IMDBUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.imdbState.uploadLogStatus),
            this.store.select((store) => store.imdbState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new IMDBSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<IMDBDownloadOutputDataRequest>(IMDBActionTypes.IMDBDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new IMDBDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting output Result for IMDB. Please contact Program Administrator.'
                    );
                    return of(new IMDBDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(IMDBActionTypes.IMDBValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateIMDBDelta().pipe(
                map(() => new IMDBValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while validating and pushing IMDB. Please contact Program Administrator.'
                    );
                    return of(new IMDBValidateDeltaError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<IMDBDownloadOutputDataSuccess>(IMDBActionTypes.IMDBDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(IMDBActionTypes.IMDBSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.imdbState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new IMDBClearInProgressSpinner(), new IMDBDataInfoRequest()])
    );

    @Effect()
    setValidateButtonEnabled$ = this.actions$.pipe(
        ofType(IMDBActionTypes.IMDBDataInfoRequest),
        mergeMap(() =>
            this.adminPanelService.getDataInfo(['IMDB']).pipe(
                map((dataInfo: any) => new IMDBDataInfoSuccess(dataInfo)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting data info for IMDB. Please contact Program Administrator.'
                    );
                    return of(new IMDBDataInfoError(error));
                })
            )
        )
    );
}
