import { Directive, HostListener, Output, EventEmitter, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
    @Output() filesAdded: EventEmitter<FileList> = new EventEmitter<FileList>();
    @Input() disabled: boolean = false;
    @HostBinding('style.background') background = '#fff';

    constructor() {
        // This is intentional
    }

    @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
        if (this.disabled === false) {
            evt.preventDefault();
            evt.stopPropagation();
            this.background = '#999';
            let files = evt.dataTransfer.files;
            if (files.length > 0) {
                this.filesAdded.emit(files);
            }
        }
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
        if (this.disabled === false) {
            evt.preventDefault();
            evt.stopPropagation();
            this.background = '#fff';
        }
    }

    @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
        if (this.disabled === false) {
            evt.preventDefault();
            evt.stopPropagation();
            let files = evt.dataTransfer.files;
            if (files.length > 0) {
                this.filesAdded.emit(files);
                this.background = '#fff';
            }
        }
    }
}
