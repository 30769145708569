import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { find } from 'lodash';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { PLIPlanningService } from 'src/app/services/api/webapi-services/pli-planning-service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from '../model';
import {
    PLIPlanningActions,
    PLIPlanningActionTypes,
    PLIPLanningDataRequest,
    PLIPLanningDataRequestError,
    PLIPLanningDataRequestSuccess,
    PLIPlanningExportToExcelRequest,
    PLIPlanningExportToExcelRequestError,
    PLIPlanningExportToExcelRequestSuccess,
    SaveWeekPlanRequest,
    SaveWeekPlanRequestError,
    SaveWeekPlanRequestSuccess,
} from './actions';
import { PLIPlanningDTO, PLIPlanningFilter, PLIPlanningState } from './model';

@Injectable()
export class PLIPlanningEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private pliPlanningService: PLIPlanningService,
        private toastService: ToastService,
        private commentService: CommentService
    ) {}

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<PLIPLanningDataRequest>(PLIPlanningActionTypes.PLIPlanningDataRequest),
        withLatestFrom(this.store.select((state) => state.pliPlanningState.filter)),
        switchMap(([action, filter]: [PLIPlanningActions, PLIPlanningFilter]) => {
            return this.pliPlanningService.getPLIPlanningData(filter, false).pipe(
                map((response) => {
                    return new PLIPLanningDataRequestSuccess(response);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while fetching PLIs. Please contact Program Administrator.'
                    );
                    return of(new PLIPLanningDataRequestError(error));
                })
            );
        })
    );

    @Effect()
    uncommittedPlis$ = this.actions$.pipe(
        ofType<SaveWeekPlanRequest>(PLIPlanningActionTypes.SaveWeekPlanRequest),
        withLatestFrom(this.store.select((state) => state.pliPlanningState)),
        switchMap(([action, state]: [PLIPlanningActions, PLIPlanningState]) => {
            const weekPlis = state.data.items.map(pli => {
                const found = find(state.uncommittedPLIs, { finalPunchItem: pli.finalPunchItem });
                return {...pli, isCommitted: !found, isConstrained: found?.isConstrained, constraintFlag: found?.constraintFlag}
            })

            return this.pliPlanningService.uncommitPLIs(state.filter.weekStart, weekPlis).pipe(
                map((response) => {
                    return new PLIPLanningDataRequest();
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while uncommitting PLIs. Please contact Program Administrator.'
                    );
                    return of(new SaveWeekPlanRequestError(error));
                })
            );
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType<PLIPlanningExportToExcelRequest>(PLIPlanningActionTypes.ExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.pliPlanningState?.filter)
        ),
        mergeMap(([action, filter]) => {
            return this.pliPlanningService.generateExcel(filter).pipe(
                map((excelData) => new PLIPlanningExportToExcelRequestSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting the data to Excel. Please contact program Administrator.'
                    );
                    return of(new PLIPlanningExportToExcelRequestError(error));
                })
            );
        })
    );

    @Effect()
    saveExcelData$ = this.actions$.pipe(
        ofType(PLIPlanningActionTypes.ExportToExcelSuccess),
        map((action: PLIPlanningExportToExcelRequestSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `WeeklyPlan_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    )
}
