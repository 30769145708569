<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            <span>{{ (rundownType$ | async) === rundownTypes.PLI1 ? 'PLI' : (rundownType$ | async) }} Rundown</span>
        </div>
    </div>
    <nav mat-tab-nav-bar class="area-centered">
        <a
            mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
        >
            {{ link.label }}
        </a>
    </nav>
    <div class="filter-container">
        <form [formGroup]="filterForm">
            <mat-accordion class="filter-container">
                <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                    <mat-expansion-panel-header collapsedHeight="auto">
                        <div *ngIf="!filterExpansionPanel.expanded">
                            <mat-chip-list aria-label="Active filters">
                                <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                    Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                    Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('contractors')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                                    RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip
                                    *ngIf="filterForm.controls.stagedStartUpPriorities.value.length"
                                    removable
                                >
                                    StagedStartupPriorities:
                                    {{ displayMultipleSelected(filterForm.controls.stagedStartUpPriorities.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('stagedStartupPriorities')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.subsystems.value.length" removable>
                                    Subsystem: {{ displaySubsystemsSelected(filterForm.controls.subsystems.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('subsystems')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                    System Owner:
                                    {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                    SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                    Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                    Area Breakdown:
                                    {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                                    RFSU Contractor:
                                    {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                    Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.workAreas.value.length" removable>
                                    Work Area: {{ displayWorkAreasSelected(filterForm.controls.workAreas.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('workAreas')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.actionBy.value.length" removable>
                                    Action By: {{ displaySelectedActionBy(filterForm.controls.actionBy.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('actionBy')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.punchType.value.length" removable>
                                    Punch Type: {{ filterForm.controls.punchType.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('punchType')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.categories.value.length" removable>
                                    Category: {{ filterForm.controls.categories.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('categories')">
                                        cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip
                                    *ngIf="rundownType === rundownTypes.PLI1 || rundownType === rundownTypes.PLI2"
                                >
                                    Total Rundown: {{ filterForm.controls.displayTotal.value ? 'Show' : 'Hide' }}
                                </mat-basic-chip>
                                <mat-basic-chip
                                    *ngIf="rundownType === rundownTypes.PLI1 || rundownType === rundownTypes.PLI2"
                                >
                                    Closure Stage: {{ displayClosureStage(filterForm.controls.closureStage.value) }}
                                </mat-basic-chip>
                                <mat-basic-chip
                                    *ngIf="rundownType === rundownTypes.BITR || rundownType === rundownTypes.CITR"
                                >
                                    HVAC:
                                    {{
                                        filterForm.controls.hvac.value === true
                                            ? 'Show Only HVAC '
                                            : filterForm.controls.hvac.value === false
                                            ? 'Exclude'
                                            : 'Include'
                                    }}
                                </mat-basic-chip>
                            </mat-chip-list>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="mixedapp-container">
                        <mat-form-field class="container300 margin-right15">
                            <mat-label>Project Team</mat-label>
                            <mat-select
                                formControlName="projectTeamNames"
                                multiple
                                (openedChange)="onProjectTeamsClosed($event)"
                            >
                                <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div
                        class="container300 margin-right15"
                        *ngIf="
                            rundownType === rundownTypes.AQVD ||
                            rundownType === rundownTypes.BITR ||
                            rundownType === rundownTypes.CITR
                        "
                        >
                            <app-multiple-items-selector
                                [setInput]="setContractorInput"
                                formControlName="contractors"
                                [searchFunc]="getContractors"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                (autocompleteClosed)="onContractorsClosed()"
                                (itemRemoved)="onContractorsClosed()"
                                [filterForm]="filterForm"
                                formSetter="contractors"
                                propertyToShow="contractNo"
                                [placeholder]="contractorLabel"
                                [panelWidth]="480"
                                [secondPropertyToShow]="'contract'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setRFOInput"
                                formControlName="rfos"
                                [searchFunc]="getRFOs"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'rfos'"
                                [propertyToShow]="'name'"
                                [placeholder]="'RFO'"
                                [secondPropertyToShow]="'rfoName'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name', 'rfoName']"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300-mh margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setStagedStartUpPriorityInput"
                                formControlName="stagedStartUpPriorities"
                                [isAsync]="true"
                                [searchFunc]="getStagedStartUpPriorities"
                                [filterForm]="filterForm"
                                [formSetter]="'stagedStartUpPriorities'"
                                [propertyToShow]="'priorityName'"
                                [placeholder]="'Staged Start-up Priority'"
                                [secondPropertyToShow]="'priority'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="stagedStartUpPriorityAutocompleteDisplayedColumns"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setRFSUContractorsInput"
                                formControlName="rfsuContractors"
                                [searchFunc]="getRFSUContractors"
                                [isAsync]="true"
                                [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                [filterForm]="filterForm"
                                [formSetter]="'rfsuContractors'"
                                [propertyToShow]="'contractNo'"
                                [placeholder]="'RFSU Contractor'"
                                [panelWidth]="480"
                                [secondPropertyToShow]="'contract'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <mat-form-field class="container300">
                                <mat-label>SC Execution Area Manager</mat-label>
                                <mat-select formControlName="scManager" multiple>
                                    <mat-option *ngFor="let sc of scManagers" [value]="sc.id"
                                        >{{ sc.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <mat-form-field class="container300 margin-right15">
                            <mat-label>Area Completion Lead</mat-label>
                            <mat-select formControlName="areaCompletionLead" multiple>
                                <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <div class="container300-mh margin-right15">
                            <app-multiple-items-selector
                                class="container300"
                                [setInput]="setAreaBreakdownInput"
                                formControlName="areaBreakdown"
                                [searchFunc]="getAreaBreakdown"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'areaBreakdown'"
                                [placeholder]="'Area Breakdown'"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div
                            class="container300 margin-right15"
                            *ngIf="
                                rundownType === rundownTypes.AQVD ||
                                rundownType === rundownTypes.BITR ||
                                rundownType === rundownTypes.CITR ||
                                rundownType === rundownTypes.PLI1 ||
                                rundownType === rundownTypes.PLI2
                            "
                        >
                            <app-multiple-items-selector
                                formControlName="subsystems"
                                [setInput]="setSubsystemInput"
                                [searchFunc]="getSubsystems"
                                [isAsync]="true"
                                placeholder="Subsystem"
                                [displayedColumns]="subsystemAutocompleteDisplayedColumns"
                                [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>

                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                [setInput]="setWaypointInput"
                                formControlName="waypoints"
                                [searchFunc]="getWaypoints"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'waypoints'"
                                [propertyToShow]="'name'"
                                [placeholder]="'Waypoint'"
                                [secondPropertyToShow]="'description'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name', 'description']"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div class="container300 margin-right15">
                            <app-multiple-items-selector
                                [setInput]="setMilestoneInput"
                                formControlName="milestones"
                                [searchFunc]="getMilestones"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [formSetter]="'milestones'"
                                [propertyToShow]="'name'"
                                [placeholder]="'Milestone'"
                                [secondPropertyToShow]="'description'"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="['name', 'description']"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div *ngIf="rundownType === rundownTypes.RFSU" class="container300 margin-right15">
                            <mat-form-field>
                                <mat-label>System Owner</mat-label>
                                <mat-select formControlName="systemOwner" multiple>
                                    <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                        <mat-form-field
                            *ngIf="
                                rundownType === rundownTypes.AQVD ||
                                rundownType === rundownTypes.BITR ||
                                rundownType === rundownTypes.CITR ||
                                rundownType === rundownTypes.PLI1 ||
                                rundownType === rundownTypes.PLI2
                            "
                            class="container300 margin-right15"
                        >
                            <mat-label>{{ disciplineLabel }}</mat-label>
                            <mat-select formControlName="disciplines" multiple>
                                <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div
                            class="container300 margin-right15"
                            *ngIf="rundownType === rundownTypes.BITR || rundownType === rundownTypes.CITR"
                        >
                            <app-multiple-items-selector
                                formControlName="qvdNumbers"
                                [searchFunc]="getQVDNumbers"
                                [isAsync]="true"
                                placeholder="ITR Number"
                                [isSelectAll]="false"
                                propertyToShow="number"
                                [setInput]="setQVDNumbersInput"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div
                            class="container300 margin-right15"
                            *ngIf="rundownType === rundownTypes.BITR ||
                            rundownType === rundownTypes.CITR||
                               rundownType === rundownTypes.PLI1 ||
                            rundownType === rundownTypes.PLI2"
                        >
                            <app-multiple-items-selector
                                class="container300 margin-right20"
                                #workAreaItemSelector
                                placeholder="Work Area"
                                formControlName="workAreas"
                                formSetter="workAreas"
                                propertyToShow="workAreaName"
                                [displayedColumns]="['workAreaName']"
                                [searchFunc]="searchAreas"
                                [isAsync]="true"
                                [filterForm]="filterForm"
                                [setInput]="setWorkAreaInput"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div
                            *ngIf="rundownType === rundownTypes.BITR || rundownType === rundownTypes.CITR"
                            class="radiobuttons-container"
                        >
                            <mat-label class="title-radiobuttons">HVAC</mat-label>
                            <mat-radio-group formControlName="hvac" class="radiobuttons-group">
                                <mat-radio-button class="radio-button-item" [value]="null">Include </mat-radio-button>
                                <mat-radio-button class="radio-button-item" [value]="false">Exclude </mat-radio-button>
                                <mat-radio-button class="radio-button-item" [value]="true"
                                    >Show Only HVAC
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div
                            class="container300 margin-right15"
                            *ngIf="rundownType === rundownTypes.BITR "
                            >
                            <app-multiple-items-selector
                                formControlName="excepqvdNumbers"
                                [searchFunc]="getExceQVDNumbers"
                                [isAsync]="true"
                                placeholder="Exception"
                                [isSelectAll]="true"
                                propertyToShow="number"
                                [setInput]="setExcepQVDNumbersInput"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <mat-form-field
                            class="container300 margin-right15"
                            *ngIf="rundownType === rundownTypes.PLI1 || rundownType === rundownTypes.PLI2"
                        >
                            <mat-label>Category</mat-label>
                            <mat-select formControlName="categories" multiple>
                                <mat-option value="A">A</mat-option>
                                <mat-option value="B">B</mat-option>
                                <mat-option value="C">C</mat-option>
                                <mat-option value="D">D</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div
                            class="container300 margin-right15"
                            *ngIf="rundownType === rundownTypes.PLI1 || rundownType === rundownTypes.PLI2"
                        >
                            <app-multiple-items-selector
                                class="container300 margin-right20"
                                [filterForm]="filterForm"
                                formControlName="actionBy"
                                [isAsync]="false"
                                [placeholder]="'Action By'"
                                [searchFunc]="searchActionBys"
                                [formSetter]="'actionBy'"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <mat-form-field
                            *ngIf="rundownType === rundownTypes.PLI1 || rundownType === rundownTypes.PLI2"
                            class="container300 margin-right15"
                        >
                            <mat-label>Punch Type</mat-label>
                            <mat-select formControlName="punchType" multiple>
                                <mat-option *ngFor="let p of punchTypes" [value]="p">{{ p }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field
                            *ngIf="rundownType === rundownTypes.PLI1 "
                            class="container300 margin-right15"
                        >
                            <mat-label>Responsible Person</mat-label>
                            <mat-select formControlName="responsiblePerson" multiple>
                                <mat-option *ngFor="let p of responsiblePeople" [value]="p">{{ p }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="mixedapp-container">
                            <div
                            class="container300 radiobuttons-container margin-right15"
                            *ngIf="rundownType === rundownTypes.PLI1 || rundownType === rundownTypes.PLI2"
                            >
                                <mat-label class="title-radiobuttons">Total Rundown</mat-label>
                                <mat-radio-group
                                    formControlName="displayTotal"
                                    (change)="toggleTotal($event)"
                                    aria-label="Show/Hide Total Rundown"
                                >
                                    <mat-radio-button [value]="true" class="mat-radio-button-first-item">Show</mat-radio-button>
                                    <mat-radio-button [value]="false">Hide</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="mixedapp-container">
                            <div
                                class="container300 radiobuttons-container margin-right15"
                                *ngIf="rundownType === rundownTypes.PLI1 || rundownType === rundownTypes.PLI2"
                            >
                                <mat-label class="title-radiobuttons">Closure Stage</mat-label>
                                <mat-radio-group
                                    formControlName="closureStage"
                                    (change)="toggleClosureStage($event)"
                                    aria-label="Show/Hide Closure Stage"
                                >
                                    <mat-radio-button [value]="0">All &nbsp;</mat-radio-button>
                                    <mat-radio-button [value]="1">Post MC &nbsp;</mat-radio-button>
                                    <mat-radio-button [value]="2">Post RFSU &nbsp;</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="container300 margin-right15" *ngIf="rundownType === rundownTypes.BITR || rundownType === rundownTypes.CITR">
                            <app-multiple-items-selector formControlName="itrDescriptions" [searchFunc]="getitrDescriptions" [isAsync]="true" [isTableAutoComplete]="true" [filterForm]="filterForm" [formSetter]="'itrDescriptions'"
                                placeholder="ITR Description" [isSelectAll]="false" propertyToShow="description" [displayedColumns]="itrAutocompleteDisplayedColumns" [setInput]="setitrDescriptionsInput">
                            </app-multiple-items-selector>
                        </div>
                    </div>
                    
                    
                </mat-expansion-panel>
            </mat-accordion>
        </form>
        <div class="buttons-container flex">
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right15">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
            <button type="button" *ngIf="displayExportToExcelButton()" (click)="exportToExcel()" class="sct-button sct-button-light margin-left15">Export To Excel</button>
        </div>
    </div>
    <div class="rundown-container">
        <div class="subtitle-container">
            <h4>
                {{ (rundownType$ | async) === rundownTypes.PLI1 ? 'pli' : (rundownType$ | async).toLowerCase() }}
                rundown
            </h4>
            <div class="options-container">
                <div class="range-selection-container">
                    <mat-slider
                        class="example-margin"
                        max="24"
                        min="12"
                        step="1"
                        tickInterval="1"
                        [thumbLabel]="true"
                        [formControl]="range"
                        (change)="onRangeChanged($event)"
                    >
                    </mat-slider>
                    <mat-form-field appearance="outline" class="datepicker-container">
                        <input matInput [formControl]="dateRangePickerControlFormatted" />
                        <input
                            matInput
                            [matDatepicker]="dateRangePicker"
                            [matDatepickerFilter]="
                                monthViewEnabled
                                    ? null
                                    : quarterViewEnabled
                                    ? quarterRangePickerFilter
                                    : dateRangePickerFilter
                            "
                            [formControl]="dateRangePickerControl"
                            hidden
                        />
                        <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                        <mat-datepicker
                            #dateRangePicker
                            [startView]="monthViewEnabled || quarterViewEnabled ? 'year' : 'month'"
                            (yearSelected)="monthViewEnabled || quarterViewEnabled ? chosenYearHandler($event) : null"
                            (monthSelected)="
                                monthViewEnabled || quarterViewEnabled
                                    ? chosenMonthHandler($event, dateRangePicker)
                                    : null
                            "
                            disabled="false"
                        ></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="container-radiobuttons">
                    <mat-radio-group [(ngModel)]="period" aria-label="Choose period">
                        <mat-radio-button [value]="0" (click)="enableWeeklyView()" class="margin-right40"
                            >Weekly</mat-radio-button
                        >
                        <mat-radio-button [value]="1" (click)="enableMonthlyView()" class="margin-right40"
                            >Monthly</mat-radio-button
                        >
                        <mat-radio-button [value]="2" (click)="enableQuarteryView()">Quarterly</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-slide-toggle
                    class="plan-toggle"
                    [checked]="planViewEnabled"
                    (toggleChange)="togglePlanView()"
                    *ngIf="rundownType !== rundownTypes.PLI1 && rundownType !== rundownTypes.PLI2 && rundownType !== rundownTypes.AQVD"
                >
                    show plan
                </mat-slide-toggle>
            </div>
            <div
                *ngIf="rundownType !== rundownTypes.PLI1 && rundownType !== rundownTypes.PLI2"
                class="legend-container"
            >
                <div class="legend-item actual">Actual</div>
                <div
                    *ngIf="rundownType === rundownTypes.RFSU"
                    class="legend-item forecast"
                >
                    Forecast
                </div>
                <div 
                    *ngIf="rundownType !== rundownTypes.AQVD"
                    class="legend-item plan">Plan</div>
            </div>
            <div
                *ngIf="rundownType === rundownTypes.PLI1 || rundownType === rundownTypes.PLI2"
                class="legend-container"
            >
                <div *ngIf="filterForm.controls.displayTotal.value === true" class="legend-item total-pli">Total</div>
                <div *ngIf="categories.length === 0 || categories.includes('A')" class="legend-item a-pli">
                    A
                </div>
                <div *ngIf="categories.length === 0 || categories.includes('B')" class="legend-item b-pli">
                    B
                </div>
                <div *ngIf="categories.length === 0 || categories.includes('C')" class="legend-item c-pli">
                    C
                </div>
                <div *ngIf="categories.length === 0 || categories.includes('D')" class="legend-item d-pli">
                    D
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
        <div *ngIf="isLoading" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
</main>
