import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { MilestoneDashboardService } from 'src/app/services/api/webapi-services/reports/milestone-dashboard.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from '../../model';
import {
    AQVDRequestStatusError,
    AQVDRequestStatusRequest,
    AQVDRequestStatusSuccess,
    ChangeValidationError,
    ChangeValidationRequest,
    ChangeValidationSuccess,
    EarlyWalkdownsError,
    EarlyWalkdownsRequest,
    EarlyWalkdownsSaveError,
    EarlyWalkdownsSaveRequest,
    EarlyWalkdownsSaveSuccess,
    EarlyWalkdownsSuccess,
    EarlyWorksError,
    EarlyWorksRequest,
    EarlyWorksSaveError,
    EarlyWorksSaveRequest,
    EarlyWorksSaveSuccess,
    EarlyWorksSuccess,
    PLIValidationError,
    PLIValidationRequest,
    PLIValidationSaveError,
    PLIValidationSaveRequest,
    PLIValidationSaveSuccess,
    PLIValidationSuccess,
    PreMCActionTypes,
    PreMCSkylineError,
    PreMCSkylineRequest,
    PreMCSkylineSuccess,
    ScheduleError,
    ScheduleRequest,
    ScheduleSaveError,
    ScheduleSaveRequest,
    ScheduleSaveSuccess,
    ScheduleSuccess,
    SOCPlanError,
    SOCPlanRequest,
    SOCPlanSaveError,
    SOCPlanSaveRequest,
    SOCPlanSaveSuccess,
    SOCPlanSuccess,
} from './actions';

@Injectable()
export class PreMCEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private milestoneDashboardService: MilestoneDashboardService,
        private toastService: ToastService
    ) {}

    @Effect()
    earlyWorksRequest$ = this.actions$.pipe(
        ofType<EarlyWorksRequest>(PreMCActionTypes.EarlyWorksRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterEarlyWorksData(action.payload.no).pipe(
                map((earlyWorks) => new EarlyWorksSuccess(earlyWorks)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Early Works data. Please contact Program Administrator.'
                    );
                    return of(new EarlyWorksError(error));
                })
            )
        )
    );

    @Effect()
    saveEarlyWorks$ = this.actions$.pipe(
        ofType<EarlyWorksSaveRequest>(PreMCActionTypes.EarlyWorksSaveRequest),
        withLatestFrom(this.store.select((state) => state.preMCState.earlyWorks.entity?.goocNo)),
        mergeMap(([action, goocNo]) =>
            this.milestoneDashboardService.saveEarlyWorks(goocNo, action.payload).pipe(
                map((earlyWorks) => {
                    this.toastService.Success('Early Works saved successfully.');
                    return new EarlyWorksSaveSuccess(earlyWorks);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while saving Early Works. Please contact Program Administrator.'
                    );
                    return of(new EarlyWorksSaveError(error));
                })
            )
        )
    );

    @Effect()
    earlyWalkdownsRequest$ = this.actions$.pipe(
        ofType<EarlyWalkdownsRequest>(PreMCActionTypes.EarlyWalkdownsRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterEarlyWalkdownsData(action.payload.no).pipe(
                map((earlyWalkdowns) => new EarlyWalkdownsSuccess(earlyWalkdowns)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Early Walkdowns data. Please contact Program Administrator.'
                    );
                    return of(new EarlyWalkdownsError(error));
                })
            )
        )
    );

    @Effect()
    saveEarlyWalkdowns$ = this.actions$.pipe(
        ofType<EarlyWalkdownsSaveRequest>(PreMCActionTypes.EarlyWalkdownsSaveRequest),
        withLatestFrom(this.store.select((state) => state.preMCState.earlyWalkdowns.entity?.goocNo)),
        mergeMap(([action, goocNo]) =>
            this.milestoneDashboardService.saveEarlyWalkdowns(goocNo, action.payload).pipe(
                map((earlyWalkdowns) => {
                    this.toastService.Success('Early Walkdowns saved successfully.');
                    return new EarlyWalkdownsSaveSuccess(earlyWalkdowns);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while saving Early Walkdowns. Please contact Program Administrator.'
                    );
                    return of(new EarlyWalkdownsSaveError(error));
                })
            )
        )
    );

    @Effect()
    pliValidationRequest$ = this.actions$.pipe(
        ofType<PLIValidationRequest>(PreMCActionTypes.PLIValidationRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterPLIValidationData(action.payload.no).pipe(
                map((pliValidation) => new PLIValidationSuccess(pliValidation)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting PLI Validation data. Please contact Program Administrator.'
                    );
                    return of(new PLIValidationError(error));
                })
            )
        )
    );

    @Effect()
    savePLIValidation$ = this.actions$.pipe(
        ofType<PLIValidationSaveRequest>(PreMCActionTypes.PLIValidationSaveRequest),
        withLatestFrom(this.store.select((state) => state.preMCState.certificationStatus.pliValidation.entity?.goocNo)),
        mergeMap(([action, goocNo]) =>
            this.milestoneDashboardService.savePLIValidation(goocNo, action.payload).pipe(
                map((pliValidation) => {
                    this.toastService.Success('PLI Validation saved successfully.');
                    return new PLIValidationSaveSuccess(pliValidation);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while saving PLI Validation. Please contact Program Administrator.'
                    );
                    return of(new PLIValidationSaveError(error));
                })
            )
        )
    );

    @Effect()
    changeValidationRequest$ = this.actions$.pipe(
        ofType<ChangeValidationRequest>(PreMCActionTypes.ChangeValidationRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterChangeValidationData(action.payload.no).pipe(
                map((changeValidation) => new ChangeValidationSuccess(changeValidation)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Change Validation data. Please contact Program Administrator.'
                    );
                    return of(new ChangeValidationError(error));
                })
            )
        )
    );

    @Effect()
    aqvdRequestStatusRequest$ = this.actions$.pipe(
        ofType<AQVDRequestStatusRequest>(PreMCActionTypes.AQVDRequestStatusRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterAQVDRequestStatusData(action.payload.no).pipe(
                map((aqvdRequestStatus) => new AQVDRequestStatusSuccess(aqvdRequestStatus)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting A-QVD Request Status data. Please contact Program Administrator.'
                    );
                    return of(new AQVDRequestStatusError(error));
                })
            )
        )
    );

    @Effect()
    socPlanRequest$ = this.actions$.pipe(
        ofType<SOCPlanRequest>(PreMCActionTypes.SOCPlanRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterSOCPlanData(action.payload.no).pipe(
                map((socPlan) => new SOCPlanSuccess(socPlan)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting System Owner Coordination Plan data. Please contact Program Administrator.'
                    );
                    return of(new SOCPlanError(error));
                })
            )
        )
    );

    @Effect()
    saveSOCPlan$ = this.actions$.pipe(
        ofType<SOCPlanSaveRequest>(PreMCActionTypes.SOCPlanSaveRequest),
        withLatestFrom(this.store.select((state) => state.preMCState.socPlan.entity?.goocNo)),
        mergeMap(([action, goocNo]) =>
            this.milestoneDashboardService.saveSOCPlan(goocNo, action.payload).pipe(
                map((pliValidation) => {
                    this.toastService.Success('System Owner Coordination Plan saved successfully.');
                    return new SOCPlanSaveSuccess(pliValidation);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while saving System Owner Coordination Plan. Please contact Program Administrator.'
                    );
                    return of(new SOCPlanSaveError(error));
                })
            )
        )
    );

    @Effect()
    preMCSkylineRequest$ = this.actions$.pipe(
        ofType<PreMCSkylineRequest>(PreMCActionTypes.PreMCSkylineRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterPreMCSkylineData(action.payload.no).pipe(
                map((skyline) => new PreMCSkylineSuccess(skyline)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting MC Skyline Plan data. Please contact Program Administrator.'
                    );
                    return of(new PreMCSkylineError(error));
                })
            )
        )
    );

    @Effect()
    scheduleRequest$ = this.actions$.pipe(
        ofType<ScheduleRequest>(PreMCActionTypes.ScheduleRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterScheduleData(action.payload.no).pipe(
                map((schedule) => new ScheduleSuccess(schedule)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Schedule data. Please contact Program Administrator.'
                    );
                    return of(new ScheduleError(error));
                })
            )
        )
    );

    @Effect()
    saveSchedule$ = this.actions$.pipe(
        ofType<ScheduleSaveRequest>(PreMCActionTypes.ScheduleSaveRequest),
        withLatestFrom(this.store.select((state) => state.preMCState.preMCSchedule.entity?.goocNo)),
        mergeMap(([action, goocNo]) =>
            this.milestoneDashboardService.saveSchedule(goocNo, action.payload).pipe(
                map((schedule) => {
                    this.toastService.Success('Schedule saved successfully.');
                    return new ScheduleSaveSuccess(schedule);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while saving Schedule. Please contact Program Administrator.'
                    );
                    return of(new ScheduleSaveError(error));
                })
            )
        )
    );
}
