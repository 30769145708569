

import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { ITRManhoursDeleteFilter, ITRManhoursDeletePagination, ITRManhoursState } from './model';
import { ITRManhoursActions, ITRManhoursActionTypes } from './actions';

const initialState: ITRManhoursState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new ITRManhoursDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new ITRManhoursDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: ITRManhoursActions): ITRManhoursState {
    switch (action.type) {
        case ITRManhoursActionTypes.ITRManhoursUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursPatchAllDeleteRecordsRequest:
        case ITRManhoursActionTypes.ITRManhoursDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursDeleteRecordsSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursValidateDataError:
        case ITRManhoursActionTypes.ITRManhoursPatchAllDeleteRecordsError:
        case ITRManhoursActionTypes.ITRManhoursPatchDeleteRecordError:
        case ITRManhoursActionTypes.ITRManhoursPatchDeleteRecordSuccess:
        case ITRManhoursActionTypes.ITRManhoursDeleteRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursDeleteFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        [action.payload.key]: Array.isArray(state.deleteFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case ITRManhoursActionTypes.ITRManhoursDeleteFilterReset: {
            const newFilter = new ITRManhoursDeleteFilter();
            return {
                ...state,
                deleteFilter: { ...newFilter },
            };
        }
        case ITRManhoursActionTypes.ITRManhoursPatchDeleteRecordRequest: {
            var itemIndex = state.deleteData.items.findIndex((d) => d.qvdNumber === action.payload.qvdNumber);
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                deleteState: action.payload.deleteState,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case ITRManhoursActionTypes.ITRManhoursPatchAllDeleteRecordsSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item) => ({ ...item, deleteState: action.payload.deleteState })),
                },
            };
        }
        case ITRManhoursActionTypes.ITRManhoursValidateDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursValidateDataSuccess: {
            return {
                ...state,
                deleteData: new ITRManhoursDeletePagination(),
                isDeleteDataLoading: false,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new ITRManhoursDeletePagination(),
            };
        }
        case ITRManhoursActionTypes.ITRManhoursClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursDownloadOutputDataRequest:
        case ITRManhoursActionTypes.ITRManhoursTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursTemplateFileError:
        case ITRManhoursActionTypes.ITRManhoursDownloadOutputDataSuccess:
        case ITRManhoursActionTypes.ITRManhoursDownloadOutputDataError:
        case ITRManhoursActionTypes.ITRManhoursTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case ITRManhoursActionTypes.ITRManhoursAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'ITRManhours';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

