<div class="filter-container">
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                            ITR/PLI Contractor: {{ displayMultipleByName(filterForm.controls.contractors.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                            ITR/PLI Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.systemOwners.value.length" removable>
                            System Owner:
                            {{ displaySelectedSystemOwner(filterForm.controls.systemOwners.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('systemOwners')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.scManagers.value.length" removable>
                            SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManagers.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('scManagers')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.exceptionPhases.value.length" removable>
                            Exception Phase: {{ filterForm.controls.exceptionPhases.value.join(', ') }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('exceptionPhases')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.rfos?.value.length" removable>
                            RFO: {{ displayMultipleByName(filterForm.controls.rfos.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                            Area Completion Lead: {{ displaySelectedACLead(filterForm.controls.areaCompletionLead.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                            Area Breakdown:
                            {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                        </mat-basic-chip>
                        <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                            RFSU Contractor:
                            {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                            <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                        </mat-basic-chip>
                    </div>
                </mat-expansion-panel-header>

                <div class="filter-row">

                    <mat-form-field class="container300">
                        <mat-label>Scope</mat-label>
                        <mat-select formControlName="exScope" (openedChange)="onScopeClosed($event)">
                            <mat-option [value]="exceptionScopes.ITR">ITR</mat-option>
                            <mat-option [value]="exceptionScopes.PLI">PLI</mat-option>
                            <mat-option [value]="exceptionScopes.All">All</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="container300">
                        <mat-label>Exception Phase</mat-label>
                        <mat-select formControlName="exceptionPhases" multiple>
                            <mat-option value="Fabrication MC">Fabrication MC</mat-option>
                            <mat-option value="Mechanical Completion">Mechanical Completion</mat-option>
                            <mat-option value="Pre Startup Safety Review">Pre Startup Safety Review</mat-option>
                            <mat-option value="Ready for Operations">Ready for Operations</mat-option>
                            <mat-option value="Ready For Start Up">Ready For Start Up</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="radio-container">
                        <mat-label class="radio-title">Overdue Only</mat-label>
                        <mat-radio-group formControlName="overdueOnly">
                            <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                            <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                            <mat-radio-button [value]="null">All</mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>

                <div class="filter-row">

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="contractors"
                            [searchFunc]="getContractorsData"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['contractNo', 'contract']"
                            [filterForm]="filterForm"
                            [formSetter]="'contractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'ITR/PLI Contractor'"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            [secondPropertyToShow]="'contract'"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>ITR/PLI Discipline</mat-label>
                        <mat-select formControlName="disciplines" multiple>
                            <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwners" multiple>
                            <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManagers" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="rfsuContractors"
                            [searchFunc]="getRFSUContractors"
                            [isAsync]="true"
                            [displayedColumns]="['contractNo', 'contract']"
                            [filterForm]="filterForm"
                            [formSetter]="'rfsuContractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'RFSU Contractor'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>

                    <mat-form-field class="container300 margin-right15">
                        <mat-label>Area Completion Lead</mat-label>
                        <mat-select formControlName="areaCompletionLead" multiple>
                            <mat-option *ngFor="let acl of areaCompletionLeads" [value]="acl.id">{{ acl.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="container300 margin-right15">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>

    <div class="buttons-container flex">
        <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
            export to excel
        </button>
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filters
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
    </div>
</div>
