import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-register-filter-top-header',
  templateUrl: './register-filter-top-header.component.html',
  styleUrls: ['./register-filter-top-header.component.scss']
})
export class RegisterFilterTopHeaderComponent extends BaseComponent implements OnInit {
  @Input() filterForm: FormGroup;
  @Input() filterExpansionPanel: MatExpansionPanel;

  constructor() {
    super();
  }

  ngOnInit(): void {
    // This is intentional
  }

  clearFilterProperty(propertyName: string) {
      if (Array.isArray(this.filterForm.controls[propertyName].value)) {
          this.filterForm.controls[propertyName].setValue([]);
      } else {
          this.filterForm.controls[propertyName].setValue('');
      }
  }

  displayMultipleSelected(values: any[]) {
    return values.map((x) => x.id ?? x).join(', ');
  }

  displaySelectedBoolean(boolValue: boolean) {
    if (boolValue === null) {
        return 'All';
    }
    return boolValue ? 'Yes' : 'No';
}

  toggleExpansionPanel(event: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.filterExpansionPanel.toggle();
  }
}
