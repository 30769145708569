import { Injectable } from '@angular/core';
import { DetailedStatusFilterBase } from 'src/app/store/detailed-status/model';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { ExportEvent } from 'src/app/store/excel-export/model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ExcelExportService {
   
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/excel';
    constructor(private http: HttpClient) {}

    getRecent(): Observable<ExportEvent[]> {
        return this.http.get<ExportEvent[]>(`${this.rootUrl}${this.endpoint}/recent`);
    }

    getUnprocessed(): Observable<ExportEvent[]> {
        return this.http.get<ExportEvent[]>(`${this.rootUrl}${this.endpoint}/unprocessed`);
    }

    sendDetailedStatusExportEvent(filter: DetailedStatusFilterBase): Observable<ExportEvent> {
        return this.http.post<ExportEvent>(`${this.rootUrl}${this.endpoint}/detailedstatus`, filter);
    }

    download(path: string) {
        return this.http.get<BlobPart>(
            `${this.rootUrl}${this.endpoint}/file?path=${path}`,
            {
                responseType: 'arraybuffer' as 'json',
            }
        );
    }
}
