export class Config {
    id: number;
    key: string;
    value: string;
    isInEditMode: boolean;
    prevValue: string;
}

export class DurationConfig {
    rfoNumber: string;
    rfoName: string;
    bitrDuration: number;
    citrDuration: number;
    isInEditMode: boolean;
    prevBitrDuration: number;
    prevCitrDuration: number;
}

export const signOffFieldImplementationModeDisplay = {
    DisabledForRLMUToGo: 'Disabled if redlines associated to the DCN/RFI are outstanding (TCO Redline Status = Required)',
    EnabledForAll: 'Enabled'
};