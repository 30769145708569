import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Subscription } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { PlanningUploadSetActivatedRoute } from 'src/app/store/planning-upload/actions';
import { ITRConstraintsDeleteDTO, ITRConstraintsDeleteFilter } from 'src/app/store/planning-upload/itr-constraints/model';
import { UntypedFormGroup } from '@angular/forms';
import { HeaderDateFilter, HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { OrderDirection, CheckListColumn, CalendarColumn } from 'src/app/store/common.model';
import { ITRConstraintsUploadLogRequest, ITRConstraintsPatchDeleteRequest, ITRConstraintsPatchAllDeleteRequest, ITRConstraintsDeleteFilterPropertyUpdate, ITRConstraintsDeleteRequest, ITRConstraintsTemplateFileRequest, ITRConstraintsDownloadOutputDataRequest, ITRConstraintsDeleteFilterReset } from 'src/app/store/planning-upload/itr-constraints/actions';

@Component({
  selector: 'app-itr-constraints',
  templateUrl: './itr-constraints.component.html',
  styleUrls: ['./itr-constraints.component.scss']
})
export class ITRConstraintsComponent extends BaseComponent implements OnInit  {
    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    deleteData: MatTableDataSource<ITRConstraintsDeleteDTO>;
    deleteDataResultsLength = 0;
    deleteDataPageSize = 10;
    displayedDeleteDataColumns = [
        'constraintId',
        'constraintStatus',
        'actions',
    ];

    sortBy$ = this.store.select((state) => state.itrConstraintsState.deleteFilter.sortBy);
    direction$ = this.store.select((state) => state.itrConstraintsState.deleteFilter.direction);
    uploadLogData$ = this.store.select((store) => store.itrConstraintsState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.itrConstraintsState.isUploadLogLoading);
    deleteData$ = this.store.select((store) => store.itrConstraintsState.deleteData);
    isDeleteDataLoading$ = this.store.select((store) => store.itrConstraintsState.isDeleteDataLoading);
    isLoading$ = this.store.select((store) => store.itrConstraintsState.isLoading);
    interval$ = this.store.select((store) => store.planningUploadState.interval);
    intervalSub: Subscription = null;
    deleteFilter$ = this.store.select((store) => store.itrConstraintsState.deleteFilter);

    columnITRConstraintsId$ = this.store.select((state) => state.itrConstraintsState.deleteFilter.columnConstraintId);

    showOrder = true;
    sortBy: string;
    direction = OrderDirection.Desc;
    deleteValidationFilterForm: UntypedFormGroup;
    columnITRConstraintsId: CheckListColumn = null;

    constructor(private store: Store<ApplicationState>,
        private activatedRoute: ActivatedRoute,
        private popupSvc: PopupService,
        private formService: FormService,
        private lookupService: LookupService) {
        super();

        this.deleteValidationFilterForm = this.formService.createSimpleForm(new ITRConstraintsDeleteFilter());
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new PlanningUploadSetActivatedRoute(urlSegments[0].path));
        });
        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new ITRConstraintsUploadLogRequest());
            });
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

        this.deleteData$.pipe(takeWhile(() => this.isAlive)).subscribe((deleteData) => {
            this.deleteData = new MatTableDataSource(deleteData.items);
            this.deleteDataResultsLength = deleteData.totalCount;
        });

        this.deleteFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.deleteValidationFilterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.sortBy = data));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.direction = data));

        this.columnITRConstraintsId$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnITRConstraintsId = data));
    }

    restoreItem(element: ITRConstraintsDeleteDTO) {
        this.store.dispatch(
            new ITRConstraintsPatchDeleteRequest({
                constraintId: element.constraintId.split("SC-CON-")[1],
                constraintStatus: "Open",
                deleteState: true,
            })
        );
    }

    deleteItem(element: ITRConstraintsDeleteDTO) {
        this.store.dispatch(
            new ITRConstraintsPatchDeleteRequest({
                constraintId: element.constraintId.split("SC-CON-")[1],
                constraintStatus: "Closed",
                deleteState: false,
            })
        );
    }

    patchAllDeleteRecords(deleteState: boolean) {
        this.store.dispatch(
            new ITRConstraintsPatchAllDeleteRequest({
                deleteState: deleteState,
            })
        );
    }

    onDeleteDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deleteDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new ITRConstraintsDeleteFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deleteDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new ITRConstraintsDeleteFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new ITRConstraintsDeleteRequest());
    }

    downloadTemplate(fileName: string) {
        this.store.dispatch(new ITRConstraintsTemplateFileRequest(fileName));
    }

    downloadData(filePath: string) {
        this.store.dispatch(new ITRConstraintsDownloadOutputDataRequest(filePath));
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: false,
                action: (data) => {
                    this.deleteValidationFilterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            },
            345,
            580)
        );
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts: showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.deleteValidationFilterForm.controls[propertyName].setValue(value);
                    this.store.dispatch(
                        new ITRConstraintsDeleteFilterPropertyUpdate({
                            key: propertyName,
                            value: this.deleteValidationFilterForm.controls[propertyName].value,
                        })
                    );
                    
                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new ITRConstraintsDeleteFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new ITRConstraintsDeleteFilterPropertyUpdate({
                            key: propertyName,
                            value: this.deleteValidationFilterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new ITRConstraintsDeleteFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new ITRConstraintsDeleteFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'constraintId', direction: OrderDirection.Desc },
                })
            );
        }
    }

    search() {
        this.updateFilterByFormProperties();

        this.store.dispatch(
            new ITRConstraintsDeleteFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new ITRConstraintsDeleteRequest());
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.deleteValidationFilterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.deleteValidationFilterForm.controls[key].value;
                this.store.dispatch(new ITRConstraintsDeleteFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.deleteValidationFilterForm, key, value);
                }
            }
        }
    }

    resetFilters() {
        this.store.dispatch(new ITRConstraintsDeleteFilterReset());
        this.store.dispatch(new ITRConstraintsDeleteRequest());
    }

    private getLatestFilterData(): ITRConstraintsDeleteFilter {
        let filter: ITRConstraintsDeleteFilter;
        this.store.pipe(select((x) => x.itrConstraintsState, take(1))).subscribe((data) => (filter = data.deleteFilter));
        return filter;
    }

    getITRConstraintId = (search = '', take = 30, page = 0) =>
        this.lookupService.searchConstraintIdNumbersWithITRConstraintDeleteFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );


}
