import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, takeWhile, withLatestFrom } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ExceptionScope } from 'src/app/enums';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Contractor, Gooc, RFO, StagedStartUpPriority, TCOUser } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    OverdueExceptionsByPriorityChartRequest,
    OverdueExceptionsByPriorityExportToExcelRequest,
    OverdueExceptionsByPriorityFilterPropertyUpdate,
    OverdueExceptionsByPriorityFilterReset,
    overdueExceptionsByPriorityFilterColumnsReset,
    OverdueExceptionsByPriorityFilterStateUpdate,
    OverdueExceptionsBySubsystemChartRequest,
    overdueExceptionsByPriorityTablesVisibilityUpdate,
} from 'src/app/store/reports/health/actions';
import { OverdueExceptionsByPriorityFilters } from 'src/app/store/reports/health/model';

@Component({
    selector: 'app-overdue-exceptions-by-priority-filter',
    templateUrl: './overdue-exceptions-by-priority-filter.component.html',
    styleUrls: ['./overdue-exceptions-by-priority-filter.component.scss'],
})
export class OverdueExceptionsByPriorityFilterComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    disciplines: any[] = [];
    scManagers: TCOUser[] = [];
    areaCompletionLeads: TCOUser[] = [];
    exceptionScopes = ExceptionScope;
    projectTeamNames: string[] = [];
    punchTypes: string[] = [];
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    setStagedStartUpPriorityInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setQVDNumbersInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    overdueExceptionsFilter$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter);
    scope$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter.exScope);
    scope: ExceptionScope;

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private toastService: ToastService,
        private store: Store<ApplicationState>,
        private projectTeamsService: ProjectTeamsService
    ) {
        super();
        this.filterForm = this.formService.createSimpleForm(new OverdueExceptionsByPriorityFilters());
    }

    ngOnInit(): void {
        this.projectTeamsService
            .getTeams()
            .pipe(take(1))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.lookupService
            .getPunchType()
            .pipe(take(1))
            .subscribe((data: any[]) => (this.punchTypes = data));

        this.filterExpansionPanel.expanded = true;

        this.subscribeToFormChanges();
        this.fetchPopulateDisciplines();
        this.fetchPopulateScManagers();
        this.fetchPopulateACLs();

        this.overdueExceptionsFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
        });

        this.scope$.pipe(takeWhile(() => this.isAlive)).subscribe((scope) => {
            this.scope = scope;
        });
    }

    subscribeToFormChanges = () => {
        this.filterForm.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
            const newFilter = value as OverdueExceptionsByPriorityFilters;
            this.store.dispatch(new OverdueExceptionsByPriorityFilterStateUpdate(newFilter));
        });
    };

    fetchPopulateDisciplines = () => {
        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: any[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines. Please contact Program Administrator.'
                    );
                }
            );
    };

    fetchPopulateScManagers = () => {
        this.lookupService
            .getSCManagers([])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (scManagers: any[]) => (this.scManagers = scManagers),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting SC Managers. Please contact Program Administrator.'
                    );
                }
            );
    };

    fetchPopulateACLs = () => {
        this.lookupService
        .getACLeads([])
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((areaCompletionLeads: TCOUser[]) => (this.areaCompletionLeads = areaCompletionLeads),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting Area Completion Leads. Please contact Program Administrator.'
                    );
                }
            );
    };

    displaySelectedACLead(areaCompletionLeadIds: number[]) {
        return this.areaCompletionLeads
            .filter((areaCompletionLead) => areaCompletionLeadIds.includes(areaCompletionLead.id))
            .map((areaCompletionLead) => areaCompletionLead.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getRFSUContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((rfsuContractors: Contractor[]) => {
                return rfsuContractors;
            })
        );
    };
    
    getAreaBreakdown = (search = '', take = 30, page = 0) => {
        return this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getContractorsData = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, [])
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getStagedStartUpPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((startUpPriorities: StagedStartUpPriority[]) => startUpPriorities));
    };

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(
            search,
            take,
            page,
            this.filterForm.value.projectTeamNames,
            this.filterForm.controls.goocs.value,
            this.filterForm.controls.rfos.value
        );
    };

    private withLatestFilter(action: (...actionArgs: any) => Observable<any>, ...args: any) {
        return of(null).pipe(
            withLatestFrom(this.store.select((state) => state.healthState.overdueExceptions.filter)),
            switchMap(([, filter]) => action(...args, filter))
        );
    }

    getQVDNumbers = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchNumbersWithOverdueExceptionFilter, search, take, page);

    searchActionBys = (search = '') => {
        return this.lookupService.getActionBy().pipe(
            map((actionBys) => {
                let list = actionBys.filter((a) => a.toLowerCase().includes(search.toLowerCase()));
                let newList = list.map((x: { id: string }) => {
                    return { id: x };
                });
                return newList;
            })
        );
    };

    displayMultipleByName(values: (Gooc | RFO | Contractor)[]) {
        return values.map((x) => x.name).join(', ');
    }

    displaySelectedSCManager(scManagerIds: number[]) {
        return this.scManagers
            .filter((scManager) => scManagerIds.includes(scManager.id))
            .map((scManager) => scManager.name)
            .join(', ');
    }

    clearFilterProperty(propertyName: string) {
        let value: any;

        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            value = [];
        } else if (typeof this.filterForm.controls[propertyName].value === 'object') {
            value = null;
        } else {
            value = '';
        }

        this.filterForm.controls[propertyName].setValue(value);
    }

    onScopeClosed(isOpen: boolean) {
        if (!isOpen) {
            this.search();
        }
    }

    resetFilters() {
        this.store.dispatch(new OverdueExceptionsByPriorityFilterReset());
    }

    exportToExcel() {
        this.store.dispatch(new OverdueExceptionsByPriorityExportToExcelRequest());
    }

    search() {
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(overdueExceptionsByPriorityFilterColumnsReset());
        this.store.dispatch(
            overdueExceptionsByPriorityTablesVisibilityUpdate({
                showTable: false,
                showSubsystemTable: false
            })
        );
        this.store.dispatch(new OverdueExceptionsByPriorityChartRequest());
        this.store.dispatch(new OverdueExceptionsBySubsystemChartRequest());
    }
}
