import { Moment } from 'moment';
import { WeekType } from 'src/app/enums';
import { Gooc } from '../../common.model';
import { SkylineDTO } from '../skyline/model';

export class EarlyWorksState {
    isLoading = false;
    entity: EarlyWorksDTO = null;
}

export class EarlyWalkdownsState {
    isLoading = false;
    entity: EarlyWalkdownsDTO = null;
}

export class CertificationStatusState {
    pliValidation: { isLoading: boolean; entity: PLIValidationDTO } = { isLoading: false, entity: null };
    changeValidation: { isLoading: boolean; entity: ChangeValidationDTO } = { isLoading: false, entity: null };
    aqvdRequestStatus: { isLoading: boolean; items: { key: string; value: number }[]; total: number } = {
        isLoading: false,
        items: [],
        total: 0,
    };
}

export class SOCPlanState {
    isLoading = false;
    entity: SOCPlanDTO = null;
}

export class DashboardSkylineState {
    isLoading = false;
    items: SkylineDTO[][] = [];
    weeks: { date: Moment; weekType: WeekType }[] = [];
    openAqvdsPerWeek: { week: Moment; count: number }[] = [];
}

export class MCScheduleState {
    isLoading = false;
    entity: Schedule = null;
}

export class EarlyWorksDTO {
    electrical_FiberOpticChecksCompleted = 0;
    electrical_FiberOpticChecksPlanned = 0;
    electrical_HeatTraceColdCheckCompleted = 0;
    electrical_HeatTraceColdCheckPlanned = 0;
    electrical_HeatTraceHotCheckCompleted = 0;
    electrical_HeatTraceHotCheckPlanned = 0;
    iC_ColdLoopChecksCompleted = 0;
    iC_ColdLoopChecksPlanned = 0;
    iC_LouverActuationTestingCompleted = 0;
    iC_LouverActuationTestingPlanned = 0;
    smP_PulleyAlignmentCompleted = 0;
    smP_PulleyAlignmentPlanned = 0;
    smP_PumpAlignmentCompleted = 0;
    smP_PumpAlignmentPlanned = 0;
    goocNo = '';
}

export class EarlyWalkdownsDTO {
    scheduled = 0;
    complete = 0;
    pliRaised = 0;
    goocNo = '';
}

export class PLIValidationDTO {
    total = 0;
    changed = 0;
    noChange = 0;
    goocNo = '';
}

export class ChangeValidationDTO {
    NotStarted = 0;
    InProgress = 0;
    Completed = 0;
    Total = 0;
    PercentCompleted = '';
}

export class SOCPlanDTO {
    complete = 0;
    inProgress = 0;
    notStarted = 0;
    hold = 0;
    goocNo = '';
}

export class Schedule {
    earlyWorksComplete: Date = null;
    earlyWalkdownsComplete: Date = null;
    firstRedZone: Date = null;
    firstMCWalkdown: Date = null;
    firstSubsystemMC: Date = null;
    goocMilestone: Date = null;
    goocNo = '';
    earlyWorksCompleteTitle = '';
    earlyWalkdownsCompleteTitle = '';
    firstRedZoneTitle = '';
    firstMCWalkdownTitle = '';
    firstSubsystemMCTitle = '';
}

export interface PreMCState {
    earlyWorks: EarlyWorksState;
    earlyWalkdowns: EarlyWalkdownsState;
    certificationStatus: CertificationStatusState;
    socPlan: SOCPlanState;
    preMCSkyline: DashboardSkylineState;
    preMCSchedule: MCScheduleState;
    gooc: Gooc;
}
