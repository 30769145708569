import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { AutomaticUploadsService } from 'src/app/services/api/webapi-services/automatic-uploads.service';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-automatic-uploads',
    templateUrl: './automatic-uploads.component.html',
    styleUrls: ['./automatic-uploads.component.scss'],
})
export class AutomaticUploadsConfigurationComponent implements OnInit {

    constructor(
        private automaticUploadsService: AutomaticUploadsService,
        private toastService: ToastService
    ) {}
    isLoading = false;

    ngOnInit() {
        // This is intentional
    }

    runAutomaticUpload() {
        this.isLoading = true;
        this.automaticUploadsService.runAutomaticUpload()
        .pipe(
            take(1),
            finalize(() => (this.isLoading = false)),
            catchError((error) => {
                this.toastService.Error(
                    'Error has occurred while trying to run Automatic Upload. Please contact Program Administrator.'
                );
                return of(error);
            })
        )
        .subscribe((data) => {
            if (data === true) {
                this.toastService.Success('The Automatic Upload will be triggered now.');
            } else if (data === false) {
                this.toastService.Error(
                    'The Automatic Upload is already in progress. It cannot be started now.'
                );
            }
        });
    }

}
