import { Constants } from "src/app/constants";
import { LeakTestPackTrackerSearchAction, LeakTestPackTrackerSearchActionTypes } from "./actions";
import { LeakTestPackTrackerSearchState, LeakTestPackTrackerSearchDataPagination, LeakTestPackTrackerSearchFilter } from "./model";
import { OrderDirection } from "../common.model";
import * as _ from "lodash";

const initialState: LeakTestPackTrackerSearchState = {
    dataPagination: new LeakTestPackTrackerSearchDataPagination(),
    isLoading: false,
    filter: new LeakTestPackTrackerSearchFilter(),
};

export function reducer(state: LeakTestPackTrackerSearchState = initialState, action: LeakTestPackTrackerSearchAction): LeakTestPackTrackerSearchState {

    switch(action.type){
        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterSuccess: {
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: action.payload.items.map((s) => {
                        return {
                            ...s                         
                        };
                    }),
                    totalCount: action.payload.totalCount,
                },
                isLoading: false,
            };
        }
        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchUpdateDisplayProperty:{
           
            
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        return d;
                    }),
                },
            };


        }

        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchPanelVisible:{
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        return { ...d}
                                            
                    }),
                },
            };


        }
        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchUpdatePrioritySuccess :{
            return {
                ...state,
                isLoading: false,
            };
        }
        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchUpdatePriorityRequest: {
            const newPriorityStatus = _.cloneDeep(state.dataPagination.items);
            newPriorityStatus.forEach((ltp) => {
                 if(ltp.leakTestPackTrackerNo === action.payload.ltp.leakTestPackTrackerNo) {
                     ltp.priority= action.payload.ltp.priority;                  
                 }                       
             });
             return {
                 ...state,
                 dataPagination: {
                     ...state.dataPagination,
                     items: newPriorityStatus
                 },
                 isLoading: true,
             };
         }

        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }

        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchFilterReset: {
            const newFilter = new LeakTestPackTrackerSearchFilter();            
            newFilter.sortBy = state.filter.sortBy;
            newFilter.direction = state.filter.direction;
            return {
                ...state,
                filter: { ...newFilter },
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((s) => ({
                        ...s
                       
                    })),
                },
            };
        }

        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchExportToExcelError:
        case LeakTestPackTrackerSearchActionTypes.LeakTestPackTrackerSearchUpdatePriorityError:     {
            return {
                ...state,
                isLoading: false,
            };
        }
        default:{
            return state;
        }

    }
}