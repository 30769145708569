import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    DetailsAddCommentRequest,
    DetailsAddCommentSuccess,
    DetailsAddCommentError,
    DetailsActionTypes,
    GetDetailsRequest,
    GetDetailsSuccess,
    GetDetailsError,
    GetCommentsRequest,
    GetCommentsSuccess,
    GetCommentsError,
    DetailsDeleteCommentRequest,
    DetailsDeleteCommentSuccess,
    DetailsDeleteCommentError,
    DetailsPLIReviewRequest,
    DetailsPLIReviewSuccess,
    DetailsPLIReviewError,
    DetailsPLIReviewDeleteSuccess,
    DetailsPLIReviewDeleteError,
    DetailsPLIReviewDeleteRequest,
    DetailsConstraintFlagUpdateRequest,
    DetailsConstraintFlagUpdateSuccess,
    DetailsConstraintFlagUpdateError,
    GetConstraintCommentDetailsRequest,
    GetConstraintCommentDetailsError,
    GetConstraintCommentDetailsSuccess,
    DetailsAddConstraintCommentRequest,
    DetailsAddConstraintCommentSuccess,
    DetailsAddConstraintCommentError,
    GetConstraintCommentsRequest,
    GetConstraintCommentsError,
    GetConstraintCommentsSuccess,
    DetailsDeleteConstraintCommentError,
    DetailsDeleteConstraintCommentRequest,
    DetailsDeleteConstraintCommentSuccess,
    GetMaterialsDetailsRequest,
    GetMaterialsDetailsSuccess,
    GetMaterialsDetailsError,
} from './actions';
import { map, mergeMap, catchError, withLatestFrom} from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { DetailedStatusService } from 'src/app/services/api/webapi-services/detailed-status.service';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { DetailedStatusDTO } from './model';
import { CommentDTO } from '../common.model';

@Injectable()
export class DetailsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private detailedStatusService: DetailedStatusService,
        private commentService: CommentService
    ) {}

    @Effect()
    getDetails$ = this.actions$.pipe(
        ofType<GetDetailsRequest>(DetailsActionTypes.GetDetailsRequest),
        mergeMap(({ payload }) => {
            return this.detailedStatusService.getDetailsData(payload.id, payload.scope).pipe(
                map((item: any) => {
                    return new GetDetailsSuccess(item);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering detailed status page. Please contact Program Administrator.'
                    );
                    return of(new GetDetailsError(error));
                })
            );
        })
    );

    
    @Effect()
    getConstraintCommentDetails$ = this.actions$.pipe(
        ofType<GetConstraintCommentDetailsRequest>(DetailsActionTypes.GetConstraintCommentDetailsRequest),
        mergeMap(({ payload }) => {
            return this.detailedStatusService.getConstraintCommentDetailsData(payload.id,payload.itrConstraintId, payload.scope).pipe(
                map((item: DetailedStatusDTO) => {
                    const comments = item.comments;
                    const constraintComments = item.constraintComments;
                   var constraintData = { 
                        item, 
                        comments, 
                        constraintComments
                    }
                    return new GetConstraintCommentDetailsSuccess(constraintData);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting comment details. Please contact Program Administrator.'
                    );
                    return of(new GetConstraintCommentDetailsError(error));
                })
            );
        })
    );

    @Effect()
    review$ = this.actions$.pipe(
        ofType<DetailsPLIReviewRequest>(DetailsActionTypes.DetailsPLIReviewRequest),
        withLatestFrom(this.store.select((store) => store.detailedStatusState.dataPagination.items)),
        mergeMap(([{ payload }, items]) => {
            return this.detailedStatusService.reviewPLI(payload.finalPunchItem, payload.type).pipe(
                map((item: DetailedStatusDTO) => {
                    var detail = items.find((s) => s.number === payload.finalPunchItem);
                    if (detail) {
                        detail.reviewStatusComplete = item.reviewStatusComplete;
                    }
                    return new DetailsPLIReviewSuccess(item);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while complete review. Please contact Program Administrator.'
                    );
                    return of(new DetailsPLIReviewError(error));
                })
            );
        })
    );

    @Effect()
    reviewDelete$ = this.actions$.pipe(
        ofType<DetailsPLIReviewDeleteRequest>(DetailsActionTypes.DetailsPLIReviewDeleteRequest),
        withLatestFrom(this.store.select((store) => store.detailedStatusState.dataPagination.items)),
        mergeMap(([{ payload }, items]) => {
            return this.detailedStatusService.deleteReviewPLI(payload.id).pipe(
                map((item: DetailedStatusDTO) => {
                    var detail = items.find((s) => s.number === item.number);
                    if (detail) {
                        detail.reviewStatusComplete = item.reviewStatusComplete;
                    }
                    return new DetailsPLIReviewDeleteSuccess(item);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while complete review. Please contact Program Administrator.'
                    );
                    return of(new DetailsPLIReviewDeleteError(error));
                })
            );
        })
    );

    @Effect()
    addDetailComment$ = this.actions$.pipe(
        ofType<DetailsAddCommentRequest>(DetailsActionTypes.DetailsAddCommentRequest),
        withLatestFrom(this.store.select((store) => store.detailedStatusState.dataPagination.items)),
        mergeMap(([action, items]) =>
            this.commentService
                .addComment(
                    action.payload.entityId,
                    action.payload.description,
                    action.payload.scope,
                    action.payload.mentions
                )
                .pipe(
                    map((item) => {
                        let oldDetail = items.find(
                            (s) => s.number === action.payload.entityId || s.id.toString() === action.payload.entityId
                        );
                        let detail = JSON.parse(JSON.stringify(oldDetail));
                        if (detail) {
                            detail.latestComment = item.description;
                            detail.mentions = action.payload.mentions;
                        }
                        this.toastService.Success('Comment successfully added.');
                        return new DetailsAddCommentSuccess(item);
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.toastService.Error(
                            'Error occurred while adding comment. Please contact Program Administrator.'
                        );
                        return of(new DetailsAddCommentError(error));
                    })
                )
        )
    );

    @Effect()
    addConstraintDetailComment$ = this.actions$.pipe(
        ofType<DetailsAddConstraintCommentRequest>(DetailsActionTypes.DetailsAddConstraintCommentRequest),
        withLatestFrom(this.store.select((store) => store.detailedStatusState.dataPagination.items)),
        mergeMap(([action, items]) =>
            this.commentService
                .addComment(
                    action.payload.entityId,
                    action.payload.description,
                    action.payload.scope,
                    action.payload.mentions
                )
                .pipe(
                    map((item) => {
                        var detail = items.find(
                            (s) => s.number === action.payload.entityId || s.id.toString() === action.payload.entityId
                        );
                        if (detail) {
                            detail.latestComment = item.description;
                            detail.mentions = action.payload.mentions;
                        }
                        this.toastService.Success('Comment successfully added.');
                        return new DetailsAddConstraintCommentSuccess(item);
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding comment. Please contact Program Administrator.'
                        );
                        return of(new DetailsAddConstraintCommentError(error));
                    })
                )
        )
    );

    @Effect()
    getComments$ = this.actions$.pipe(
        ofType<GetCommentsRequest>(DetailsActionTypes.GetCommentsRequest),
        mergeMap(({ payload }) =>
            this.commentService.getComments(payload.entityId, payload.scope).pipe(
                map((items) => {
                    return new GetCommentsSuccess(items);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting comments. Please contact Program Administrator.'
                    );
                    return of(new GetCommentsError(error));
                })
            )
        )
    );

    @Effect()
    getConstraintComments$ = this.actions$.pipe(
        ofType<GetConstraintCommentsRequest>(DetailsActionTypes.GetConstraintCommentsRequest),
        mergeMap(({ payload }) =>
            this.commentService.getComments(payload.entityId, payload.scope).pipe(
                map((items) => {
                    return new GetConstraintCommentsSuccess(items);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting comments. Please contact Program Administrator.'
                    );
                    return of(new GetConstraintCommentsError(error));
                })
            )
        )
    );


    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<DetailsDeleteCommentRequest>(DetailsActionTypes.DetailsDeleteCommentRequest),
        withLatestFrom(
            this.store.select((store) => store.detailedStatusState.dataPagination.items),
            this.store.select((store) => store.detailsState)
        ),
        mergeMap(([{ payload }, items, detail]) =>
            this.commentService.deleteComment(payload.id, payload.scope).pipe(
                map(() => {
                    var isLatestComment = detail.comments[detail.comments.length - 1].id === payload.id;
                    if (isLatestComment) {
                        let oldItem = items.find((s) => s.number === detail.item.number || s.id === detail.item.id);
                        let item =JSON.parse(JSON.stringify(oldItem));
                        var newLatestComment = detail.comments[detail.comments.length - 2];
                        if (item) {
                            item.latestComment = newLatestComment ? newLatestComment.description : '';
                        }
                    }
                    this.toastService.Success('Comment deleted successfully.');
                    return new DetailsDeleteCommentSuccess({ id: payload.id });
                }),
                catchError((error) => {
                    console.log(error);
                    this.toastService.Error(
                        'Error occurred while deleting comment. Please contact Program Administrator.'
                    );
                    return of(new DetailsDeleteCommentError(error));
                })
            )
        )
    );

    @Effect()
    deleteConstraintComment$ = this.actions$.pipe(
        ofType<DetailsDeleteConstraintCommentRequest>(DetailsActionTypes.DetailsDeleteConstraintCommentRequest),
        withLatestFrom(
            this.store.select((store) => store.detailedStatusState.dataPagination.items),
            this.store.select((store) => store.detailsState)
        ),
        mergeMap(([{ payload }, items, detail]) =>
            this.commentService.deleteComment(payload.id, payload.scope).pipe(
                map(() => {
                    var isLatestComment = detail.constraintComments[detail.constraintComments.length - 1].id === payload.id;
                    if (isLatestComment) {
                        var oldItem = items.find((s) => s.number === detail.item.number || s.id === detail.item.id);
                        let item =JSON.parse(JSON.stringify(oldItem));
                        var newLatestComment = detail.constraintComments[detail.constraintComments.length - 2];
                        if (item) {
                            item.latestComment = newLatestComment ? newLatestComment.description : '';
                        }
                    }
                    this.toastService.Success('Comment deleted successfully.');
                    return new DetailsDeleteConstraintCommentSuccess({ id: payload.id });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while deleting comment. Please contact Program Administrator.'
                    );
                    return of(new DetailsDeleteConstraintCommentError(error));
                })
            )
        )
    );


    @Effect()
    updateConstraintFlag$ = this.actions$.pipe(
        ofType<DetailsConstraintFlagUpdateRequest>(DetailsActionTypes.DetailsConstraintFlagUpdateRequest),
        mergeMap((action) => {
            return this.detailedStatusService.patchConstraintFlag(action.payload).pipe(
                map(() => {
                    this.toastService.Success('Constraint Flag has been successfully updated.')
                    return new DetailsConstraintFlagUpdateSuccess();
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating Constraint Flag. Please contact Program Administrator.'
                    );
                    return of(new DetailsConstraintFlagUpdateError(error));
                })
            );
        })
    );
}
