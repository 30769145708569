import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
    WeeklyPlanningFilter,
    WeeklyPlanningCommitDTO,
    WeeklyPlanningCommitmentsDTO,
    HeaderFilterFetchColumn,
    WeeklyPlanningTableHeaderFilter,
    WeeklyPlanningTopHeaderResultDTO,
} from 'src/app/store/weekly-planning/model';
import { Contractor, OrderDirection, RFO } from 'src/app/store/common.model';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class WeeklyPlanningService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly weeklyPlanningEndpoint: string = '/weeklyplanning';

    constructor(private http: HttpClient) {}

    getPlanningData(
        planningType: string,
        planningTableIndex: number,
        filter: WeeklyPlanningFilter,
        page: number,
        take: number,
        sortBy: string,
        direction: OrderDirection,
        tableHeaderFilter: WeeklyPlanningTableHeaderFilter
    ) {
        return this.http.post(`${this.rootUrl}${this.weeklyPlanningEndpoint}/${planningType}/${planningTableIndex}`, {
            filter,
            page,
            take,
            sortBy,
            direction,
            tableHeaderFilter,
        });
    }

    getPlanningHeaderFilterData(
        planningType: string,
        planningTableIndex: number,
        filter: WeeklyPlanningFilter,
        page: number,
        take: number,
        sortBy: string,
        direction: OrderDirection,
        tableHeaderFilter: WeeklyPlanningTableHeaderFilter,
        headerFilterFetchColumn: HeaderFilterFetchColumn,
        headerFilterSearch: string
    ) {
        return this.http.post(
            `${this.rootUrl}${this.weeklyPlanningEndpoint}/headerFilter/${planningType}/${planningTableIndex}`,
            {
                filter,
                page,
                take,
                sortBy,
                direction,
                tableHeaderFilter,
                headerFilterFetchColumn,
                headerFilterSearch,
            }
        );
    }

    commitSubsystem(commit: WeeklyPlanningCommitDTO) {
        return this.http.post(`${this.rootUrl}${this.weeklyPlanningEndpoint}/commit`, commit);
    }

    deleteCommitment(commit: WeeklyPlanningCommitDTO) {
        return this.http.post(`${this.rootUrl}${this.weeklyPlanningEndpoint}/deleteCommitment`, commit);
    }

    getPastWeekCommitments(
        startDate: moment.Moment,
        planningType: string,
        projectTeamName: string,
        contractors: Contractor[],
        rfos: RFO[] = []
    ) {
        return this.http.post(
            `${this.rootUrl}${this.weeklyPlanningEndpoint}/${projectTeamName}/${planningType}/pastWeek`,
            { startDate, contractors, rfos }
        );
    }

    getWeeklyCommitments(
        projectTeamName: string,
        planningType: string,
        filter: WeeklyPlanningFilter,
        leftBehind = true,
        onlySubmitted = false
    ) {
        return this.http.post<WeeklyPlanningCommitmentsDTO>(
            `${this.rootUrl}${this.weeklyPlanningEndpoint}/${projectTeamName}/${planningType}/commitments?leftBehind=${leftBehind}&onlySubmitted=${onlySubmitted}`,
            filter
        );
    }

    submitCommitments(planningType: string, filter: WeeklyPlanningFilter) {
        return this.http.post(`${this.rootUrl}${this.weeklyPlanningEndpoint}/${planningType}/submit`, filter);
    }

    getDailyDashboardData(projectTeamName: string, commitmentType: string, filter: WeeklyPlanningFilter) {
        filter = this.getWeeklyPlaningFilterByCommitmentType(commitmentType, filter);
        return this.http.post(
            `${this.rootUrl}${this.weeklyPlanningEndpoint}/${projectTeamName}/${commitmentType}/daily`,
            {
                contractors: filter.contractors,
                systemOwners: filter.systemOwners,
                rfsuContractors: filter.rfsuContractors,
                areaBreakdown: filter.areaBreakdown,
                scManagers: filter.scManagers,
                rfos: filter.rfos,
                startDate: filter.startDate,
                goocs: filter.goocs,
                stagedStartUpPriorities: filter.stagedStartUpPriorities
            }
            /* Should have passed just the 'filter' property*/
        );
    }

    getTopHeaderSectionData(projectTeamName: string, commitmentType: string, filter: WeeklyPlanningFilter) {
        filter = this.getWeeklyPlaningFilterByCommitmentType(commitmentType, filter);
        return this.http.post<WeeklyPlanningTopHeaderResultDTO>(
            `${this.rootUrl}${this.weeklyPlanningEndpoint}/${projectTeamName}/${commitmentType}/topSectionHeader`,
            filter
        );
    }

    private getWeeklyPlaningFilterByCommitmentType(
        commitmentType: string,
        filter: WeeklyPlanningFilter
    ): WeeklyPlanningFilter {
        if (commitmentType === 'rfsu') {
            return {
                ...filter,
                contractors: [],
            };
        } else {
            return {
                ...filter,
                scManagers: [],
                systemOwners: [],
                rfos: [],
            };
        }
    }
}
