import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ClearableMatDatepickerComponent } from 'src/app/components/clearable-mat-datepicker/clearable-mat-datepicker.component';
import { Constants } from 'src/app/constants';
import { WeeklyPlanActivityType, WeeklyPlanType } from 'src/app/enums';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from 'src/app/modules/shared/components/comment-popup/comment-popup.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { CalendarColumn, CheckListColumn, UserDetail } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import { CreateNewActivityRequest, ScheduleActivityNotInWeekUpdate, ScheduleActivityPlanningDataRequest, ScheduleActivityPlanningFilterPropertyUpdate, ScheduleActivityPlanningResetITRFilter, ScheduleActivityUpdate } from 'src/app/store/weekly-planning/schedule-activity-planning/actions';
import { CreateActivityDTO, ScheduleActivityPlanningDTO, ScheduleActivityPlanningFilter, ShowHideColumns, SubsystemWithScheduleActivitiesDTO } from 'src/app/store/weekly-planning/schedule-activity-planning/model';
import * as _ from 'lodash';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { ScheduleActivityLookupService } from 'src/app/services/api/webapi-services/schedule-activity-lookup.service';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { PopoverPlacement } from 'ngx-smart-popover';
import { ConstraintTypeSelectorPopupComponent } from '../subsystem-activities-table/constraint-type-selector/constraint-type-selector.component';
import { ScheduleActivityPlanningConstraint } from 'src/app/components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import { CreateActivityComponent } from '../create-activity/create-activity.component';
import { ScheduleActivityUpdateDto } from 'src/app/services/api/webapi-services/schedule-activity-planning.service';
import { ScheduleActivityITRFilterPropertyUpdate } from 'src/app/store/schedule-activity-itr/actions';
import { ScheduleActivityITRFilter, ScheduleActivityITRScope } from 'src/app/store/schedule-activity-itr/model';

type ForecastDateType = 'forecastStart' | 'forecastFinish';

@Component({
  selector: 'app-schedule-activity-lookahead-table',
  templateUrl: './schedule-activity-lookahead-table.component.html',
  styleUrls: ['./schedule-activity-lookahead-table.component.scss']
})
export class ScheduleActivityLookaheadTableComponent extends BaseComponent implements OnInit {
  PopoverPlacement = PopoverPlacement;

  @ViewChild('activityActionPopover', { static: false }) popover: any;
  @Input() subsystemWithActivities: SubsystemWithScheduleActivitiesDTO;
  @Input() activityType: WeeklyPlanActivityType;

  constructor(
    private scheduleActivityLookupService: ScheduleActivityLookupService,
    private roleService: RoleService,
    private popupSvc: PopupService,
    private store: Store<ApplicationState>,
    private ngZone: NgZone,
    private router: Router
  ) {
    super();
  }

  get isSelectedRowConstrained() {
    return this?.selectedRow?.isConstrained ?? false;
  }

  get activitiesDataSource() {
    return this.subsystemWithActivities.activities
      .filter(f => f.activityId || (this.showHideColumns.showMCMilestone && f.activity == 'Mechanical Completion')
        || (this.showHideColumns.showRFSUMilestone && f.activity == 'Ready for Start-up'));
  }

  currentlyViewedWeekStart$: Observable<moment.Moment> = this.store.select(
    (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weekStart
  );
  currentlyViewedWeekStart: moment.Moment;

  weeklyPlanType$ = this.store.select(
    (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.weeklyPlanType
  );
  weeklyPlanType: WeeklyPlanType;
  weeklyPlanTypes = WeeklyPlanType;

  showHideColumns = new ShowHideColumns();
  showHideColumns$ = this.store.select(
    (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.showHideColumns
  );

  columnDisciplines$ = this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnDisciplines);
  columnDisciplines: CheckListColumn = null;
  columnActivityNames$ = this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnActivityNames);
  columnActivityNames: CheckListColumn = null;
  columnActivityIds$ = this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnActivityIds);
  columnActivityIds: CheckListColumn = null;
  columnComments$ = this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnComments);
  columnComments: CheckListColumn = null;
  columnPlanFinish$ = this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnPlanFinish);
  columnPlanFinish: CalendarColumn = null;
  columnPlanStart$ = this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnPlanStart);
  columnPlanStart: CalendarColumn = null;
  columnForecastStart$ = this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnForecastStart);
  columnForecastStart: CalendarColumn = null;
  columnForecastFinish$ = this.store.select((state) => state.scheduleActivityPlanningState.scheduleActivityPlanningFilter.columnForecastFinish);
  columnForecastFinish: CalendarColumn = null;


  filter$ = this.store.select(
    (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter
  );
  filter: ScheduleActivityPlanningFilter;

  monthPlanDisplayedColumns: string[] = [
    'activityName',
    'activityDiscipline',
    'activityId',
    'planStart',
    'planFinish',
    'forecastStart',
    'forecastFinish',
    'constraintType',
    'itrToGo',
    'comment',
  ];

  displayedColumns: string[] = [];
  itrScopes = ScheduleActivityITRScope;

  showActivities = true;
  showActivities$ = this.store.select(
    (store) => store.scheduleActivityPlanningState.scheduleActivityPlanningFilter.showActivities
  );

  selectedRow: ScheduleActivityPlanningDTO;
  isAdmin = false;

  ngOnInit(): void {

    this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.filter = data));

    this.weeklyPlanType$.pipe(takeWhile(() => this.isAlive)).subscribe((type) => (this.weeklyPlanType = type));

    this.currentlyViewedWeekStart$.pipe(takeWhile(() => this.isAlive)).subscribe((next) => {
      this.currentlyViewedWeekStart = next
    });

    this.showActivities$.pipe(takeWhile(() => this.isAlive)).subscribe((value) => (this.showActivities = value));

    this.showHideColumns$.pipe(takeWhile(() => this.isAlive)).subscribe((value) => (this.showHideColumns = value));

    this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);

    this.columnDisciplines$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((items) => (this.columnDisciplines = items));

    this.columnActivityNames$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((items) => (this.columnActivityNames = items));

    this.columnActivityIds$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((items) => (this.columnActivityIds = items));

    this.columnComments$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((items) => (this.columnComments = items));

    this.columnPlanFinish$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((items) => (this.columnPlanFinish = items));

    this.columnPlanStart$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((items) => (this.columnPlanStart = items));

    this.columnForecastStart$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((items) => (this.columnForecastStart = items));

    this.columnForecastFinish$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((items) => (this.columnForecastFinish = items));

    this.displayedColumns = this.monthPlanDisplayedColumns;

    this.setWeekColumns();
  }

  setWeekColumns() {
    this.displayedColumns = this.monthPlanDisplayedColumns;
    this.subsystemWithActivities.activities[0]?.weekColumns?.forEach((column) => {
      this.displayedColumns.push(column.columnId);
    })
  }

  inWeek(row: ScheduleActivityPlanningDTO, week: string) {
    return row.weekColumns.filter(w => w.columnId === week && w.inWeek === true).length > 0
  }

  saveComment(row: ScheduleActivityPlanningDTO, value: string) {
    row.fourWeeksComment = value;
    const payload = {
      activityId: row.activityId,
      activityDiscipline: row.activityDiscipline,
      fourWeeksComment: value
    } as ScheduleActivityUpdateDto;
    const subsystem = this.subsystemWithActivities.subsystem;
    
    this.activityType == WeeklyPlanActivityType.inWeek ?
      this.store.dispatch(new ScheduleActivityUpdate(payload, subsystem))
      : this.store.dispatch(new ScheduleActivityNotInWeekUpdate(payload, subsystem));
  }

  saveDate(row: ScheduleActivityPlanningDTO, type: 'forecastStart' | 'forecastFinish') {
    const payload = {
      activityId: this.selectedRow.activityId,
      activityDiscipline: this.selectedRow.activityDiscipline,
      [type]: row[type]
    } as ScheduleActivityUpdateDto;
    const subsystem = this.subsystemWithActivities.subsystem;
    
    this.activityType == WeeklyPlanActivityType.inWeek ?
      this.store.dispatch(new ScheduleActivityUpdate(payload, subsystem))
      : this.store.dispatch(new ScheduleActivityNotInWeekUpdate(payload, subsystem));
  }

  highlightRow(row: any) {
    this.selectedRow = row;
  }

  openDatepicker(row: ScheduleActivityPlanningDTO, type: ForecastDateType) {
    let date;
    if (type === 'forecastStart') {
      date = row.forecastStart ? row.forecastStart : '';
    } else if (type === 'forecastFinish') {
      date = row.forecastFinish ? row.forecastFinish : '';
    }

    this.popupSvc.openPopup(
      new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
        selectedDate: date,
        dateType: 'common',
        minDate: type === 'forecastStart' ? new Date() : row.forecastStart ?? new Date(),
        maxDate: type === 'forecastStart' ? row.forecastFinish : null,
        commonSaveAction: (d: moment.Moment) => {
          type === 'forecastStart'
            ? row.forecastStart = (d?.toDate())
            : row.forecastFinish = (d?.toDate());
          this.saveDate(row, type);
        },
      })
    );
  }

  showITRs(itrScope: ScheduleActivityITRScope, activityId: string, activityDiscipline: string, saveWeeklyPlan = false) {
    this.store.dispatch(new ScheduleActivityPlanningResetITRFilter());
    this.store.dispatch(
        new ScheduleActivityITRFilterPropertyUpdate(
            ({ 
                scope: itrScope,
                activityId: activityId,
                saveWeeklyPlan: saveWeeklyPlan,
                activityDiscipline: activityDiscipline,
                activityType: this.activityType
            }) as ScheduleActivityITRFilter
        )
    );
    this.ngZone.run(() =>
        this.router.navigate([`/weeklyplanning/lookaheaditrs`])
    );
  }

  openCommentPopup(row: ScheduleActivityPlanningDTO, event: any) {
    this.popupSvc.openPopup(
      new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
        comment: row.fourWeeksComment,
        emailButtonVisible: false,
        action: (value: string, mentions: UserDetail[], bulk: boolean) => {
          this.saveComment(row, value);
        },
      })
    );

    event.stopPropagation();
  }

  getStartDateActivityStatusSuffix(row) {
    return row.activityStatus === 'Started' || row.activityStatus === 'Complete' ? 'A' : '';
  }

  getFinishDateActivityStatusSuffix(row) {
    return row.activityStatus === 'Complete'
          || ((row.activityId === undefined) && (this.subsystemWithActivities.mcActual && row.activity === 'Mechanical Completion') 
                                                  || (this.subsystemWithActivities.rfsuActual && row.activity === 'Ready for Start-up')) ? 'A' : '';
  }

  openHeaderCheckListFilter(
    searchFunc: any,
    propertyName: string,
    placeholder: string,
    selected: CheckListColumn,
    showCounts: boolean = false,
    showInputSearch: boolean = true,
    showBlankOptions: boolean = false
  ) {
    const excludeBlanks = selected === null ? false : selected.excludeBlanks;
      const onlyBlanks = selected === null ? false : selected.onlyBlanks;
      this.popupSvc.openPopup(
      new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
        isAscendingSort: false,
        isDescendingSort: false,
        placeHolder: placeholder,
        searchFunc: searchFunc,
        selectedItems: selected ? [...selected.items] : [],
        excludeBlanks: excludeBlanks,
        onlyBlanks: onlyBlanks,
        isSortingOff: true,
        showCounts: showCounts,
        showInputSearch,
        showBlankOptions: showBlankOptions,
        action: (data) => {
          let value = new CheckListColumn();
          value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
          value.excludeBlanks = data.excludeBlanks;
          value.onlyBlanks = data.onlyBlanks;
          
          this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
              filter: {
                ...this.filter,
                [propertyName]: value,
              } as ScheduleActivityPlanningFilter,
            }));
          this.store.dispatch(new ScheduleActivityPlanningDataRequest());
        },
        resetColumnAction: () => {
          this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
              filter: {
                ...this.filter,
                [propertyName]: null,
              } as ScheduleActivityPlanningFilter,
            }));
        },
      })
    );
  }

  openHeaderDateFilter(
    propertyName: string,
    placeholder: string,
    calendarColumn: CalendarColumn
  ) {
    const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
    const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
    const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
    this.popupSvc.openPopup(
      new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
        isAscendingSort: false,
        isDescendingSort: false,
        calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
        placeHolder: placeholder,
        isSortingOff: true,
        action: (data) => {
          this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
              filter: {
                ...this.filter,
                [propertyName]: data.calendarColumn,
              } as ScheduleActivityPlanningFilter,
            }));
          this.store.dispatch(new ScheduleActivityPlanningDataRequest());
        },
      },
      345,
      580)
    );
  }

  getDisciplines = (search = '', take = 30, page = 0) => {
    return this.scheduleActivityLookupService
      .searchDisciplines()
      .pipe(
        map((disciplines: []) =>
          disciplines.filter((d: { name: string }) => d.name.toLowerCase().includes(search.toLowerCase()))
        ),
        map((disciplines: []) => disciplines.map((d: { name: string }) => d.name))
      );
  };

  getActivityNames = (search = '', take = 30, page = 0) => {
    return this.scheduleActivityLookupService
      .searchActivityNames(search, take, page, this.filter);
  };

  getActivityIds = (search = '', take = 30, page = 0) => {
    return this.scheduleActivityLookupService
      .searchActivityIds(search, take, page, this.filter);
  };

  getComments = (search = '', take = 30, page = 0) => {
    return this.scheduleActivityLookupService
      .searchComments(search, take, page, this.filter);
  };

  updateIsConstrained() {
    const changeTo = !this.isSelectedRowConstrained;

    if (changeTo) {
      this.popover.hide();
      this.popupSvc
        .openPopup(new PopupSettings(ConstraintTypeSelectorPopupComponent, 450, 300, {}))
        .afterClosed()
        .subscribe((dialogResult) => {
          if (_.has(dialogResult, 'constraintType')) {
            this.updateIsConstrainedRequest(changeTo, dialogResult.constraintType);
          }
        });
      return;
    }

    this.updateIsConstrainedRequest(changeTo);
  }

  private updateIsConstrainedRequest(
    isConstrained: boolean,
    constraintType: ScheduleActivityPlanningConstraint = null
  ) {
    const payload = {
      activityId: this.selectedRow.activityId,
      activityDiscipline: this.selectedRow.activityDiscipline,
      constraintTypeId: constraintType?.id,
      isConstrained,
      constraintType: constraintType?.name
    } as ScheduleActivityUpdateDto;
    const subsystem = this.subsystemWithActivities.subsystem;
    
    this.activityType == WeeklyPlanActivityType.inWeek ?
      this.store.dispatch(new ScheduleActivityUpdate(payload, subsystem))
      : this.store.dispatch(new ScheduleActivityNotInWeekUpdate(payload, subsystem));
  }

  addNewActivity() {
    this.popover.hide();
    this.popupSvc
      .openPopup(new PopupSettings(CreateActivityComponent, 685, 450, {}))
      .afterClosed()
      .subscribe((dialogResult) => {
        if (_.has(dialogResult, 'newActivity')) {
            
            const activity: CreateActivityDTO = {
              ...dialogResult.newActivity,
              baseActivityId: this.selectedRow.activityId,
              subsystem: this.selectedRow.subsystem,
            }

            this.store.dispatch(new CreateNewActivityRequest(activity));
          }
    });
    return;
  }

}
