import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    ExceptionsActionTypes,
    ExceptionsUploadLogSuccess,
    ExceptionsUploadLogError,
    ExceptionsSetStatusAndStartDate,
    ExceptionsClearInProgressSpinner,
    ExceptionsTemplateFileRequest,
    ExceptionsTemplateFileSuccess,
    ExceptionsTemplateFileError,
    ExceptionsValidateDeltaSuccess,
    ExceptionsValidateDeltaError,
    ExceptionsDataInfoSuccess,
    ExceptionsDataInfoError,
    ExceptionsDataInfoRequest,
    ExceptionsDownloadOutputDataRequest,
    ExceptionsDownloadOutputDataSuccess,
    ExceptionsDownloadOutputDataError,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class ExceptionsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(ExceptionsActionTypes.ExceptionsUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['Exceptions']).pipe(
                map((uploadLogData) => new ExceptionsUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting logs for Exceptions. Please contact Program Administrator.'
                    );
                    return of(new ExceptionsUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<ExceptionsUploadLogSuccess>(ExceptionsActionTypes.ExceptionsUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.exceptionsState.uploadLogStatus),
            this.store.select((store) => store.exceptionsState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new ExceptionsSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<ExceptionsTemplateFileRequest>(ExceptionsActionTypes.ExceptionsTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new ExceptionsTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting template for Exceptions. Please contact Program Administrator.'
                    );
                    return of(new ExceptionsTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<ExceptionsTemplateFileSuccess>(ExceptionsActionTypes.ExceptionsTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'SCMT Exceptions.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<ExceptionsDownloadOutputDataRequest>(ExceptionsActionTypes.ExceptionsDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new ExceptionsDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting output Result for Exceptions. Please contact Program Administrator.'
                    );
                    return of(new ExceptionsDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<ExceptionsDownloadOutputDataSuccess>(ExceptionsActionTypes.ExceptionsDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(ExceptionsActionTypes.ExceptionsValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateExceptionsDelta().pipe(
                map(() => new ExceptionsValidateDeltaSuccess()),
                catchError((error) => {
                    if (error?.error === 'AutomaticUploadPipelineInProgress') {
                        this.toastService.Info(
                            'Cannot validate the data while automatic upload pipeline is in progress.'
                        );
                    } else {
                        this.toastService.Error(
                            'Error occurred while validating and pushing Exceptions. Please contact Program Administrator.'
                        );
                    }
                    return of(new ExceptionsValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(ExceptionsActionTypes.ExceptionsSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.exceptionsState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new ExceptionsClearInProgressSpinner(), new ExceptionsDataInfoRequest()])
    );

    @Effect()
    setValidateButtonEnabled$ = this.actions$.pipe(
        ofType(
            ExceptionsActionTypes.ExceptionsDataInfoRequest,
            ExceptionsActionTypes.ExceptionsUploadLogRequest
        ),
        mergeMap(() =>
            this.adminPanelService.getDataInfo(['Exceptions']).pipe(
                map((dataInfo: any) => new ExceptionsDataInfoSuccess(dataInfo)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting data info for Exceptions. Please contact Program Administrator.'
                    );
                    return of(new ExceptionsDataInfoError(error));
                })
            )
        )
    );
}
