import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import {
    DetailedStatusActionTypes,
    DetailedStatusFilterSuccess,
    DetailedStatusFilterError,
    DetailedStatusAddCommentRequest,
    DetailedStatusAddCommentSuccess,
    DetailedStatusAddITRResponsibleCommentSuccess,
    DetailedStatusAddCommentError,
    DetailedStatusFilterRequest,
    DetailedStatusAddBulkCommentRequest,
    DetailedStatusAddBulkCommentSuccess,
    DetailedStatusAddBulkCommentError,
    DetailedStatusAddITRConstraintRequest,
    DetailedStatusAddITRConstraintSuccess,
    DetailedStatusUpdateITRConstraintRequest,
    DetailedStatusUpdateITRConstraintSuccess,
    DetailedStatusITRConstraintError,
    GetStatusDetailsRequest,
    GetStatusDetailsSuccess,
    GetStatusDetailsError,
    DetailedStatusAddEXForecastClosureDateRequest,
    DetailedStatusAddEXForecastClosureDateSuccess,
    DetailedStatusAddEXForecastClosureDateError,
    DetailedStatusAddResponsiblePersonRequest,
    DetailedStatusAddResponsiblePersonSuccess,
    DetailedStatusAddResponsiblePersonError,
    DetailedStatusAddExPriorityRequest,
    DetailedStatusAddExPrioritySuccess,
    DetailedStatusAddExPriorityError,
    DetailedStatusRemoveCommentRequest,
    DetailedStatusRemoveCommentError,
    DetailedStatusRemoveCommentSuccess,
    DetailedStatusRemoveExceptionCommentRequest,
    DetailedStatusRemoveExceptionCommentSuccess,
    DetailedStatusRemoveExceptionCommentError,
    DetailedStatusAddO3ConstraintRequest,
    DetailedStatusAddO3ConstraintSuccess,
    DetailedStatusAddO3ConstraintError,
    DetailedStatusAddBulkExceptionCommentRequest,
    DetailedStatusAddBulkExceptionCommentSuccess,
    DetailedStatusAddBulkExceptionCommentError,
    DetailedStatusUpdatePliImplementationTeamRequest,
    DetailedStatusUpdatePliImplementationTeamSuccess,
    DetailedStatusUpdatePliImplementationTeamError,
    DetailedStatusAddPLIForecastClosureDateSuccess,
    DetailedStatusAddPLIForecastClosureDateError,
    DetailedStatusAddPLIForecastClosureDateRequest,
    DetailedStatusAddPostRFOWorkExpectedClosureDateRequest,
    DetailedStatusAddPostRFOWorkExpectedClosureDateError,
    DetailedStatusAddPostRFOWorkExpectedClosureDateSuccess,
    DetailedStatusPostRFOWorkUpdateManualPriorityRequest,
    DetailedStatusPostRFOWorkUpdateManualPrioritySuccess,
    DetailedStatusPostRFOWorkUpdateManualPriorityError,
    DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest,
    DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupSuccess,
    DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupError,
} from './actions';
import { map, mergeMap, catchError, withLatestFrom, switchMap} from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { DetailedStatusService } from 'src/app/services/api/webapi-services/detailed-status.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { CommentService} from 'src/app/services/api/webapi-services/comment.service';
import { ITRConstraintsService} from 'src/app/services/api/webapi-services/itr-constraints.service';
import { ExceptionScope, SubsystemScope, UserDefinedInputs } from 'src/app/enums';
import * as _ from 'lodash';
import { PLIPlanningService } from 'src/app/services/api/webapi-services/pli-planning-service';

@Injectable()
export class DetailedStatusEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private detailedStatusService: DetailedStatusService,
        private commentService: CommentService,
        private itrconstraintservice: ITRConstraintsService,
        private pliservice : PLIPlanningService
    ) {}
    subsystemScopes = SubsystemScope;

    @Effect()
    getDetails$ = this.actions$.pipe(
        ofType<GetStatusDetailsRequest>(DetailedStatusActionTypes.GetStatusDetailsRequest),
        mergeMap(({ payload }) => {
            return this.detailedStatusService.getDetailsData(payload.id, payload.scope).pipe(
                map((item: any) => {
                    return new GetStatusDetailsSuccess(item);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering detailed status page. Please contact Program Administrator.'
                    );
                    return of(new GetStatusDetailsError(error));
                })
            );
        })
    );

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<DetailedStatusFilterRequest>(DetailedStatusActionTypes.DetailedStatusFilterRequest),
        map((action) => action.payload),
        mergeMap((scope) =>
            of(scope).pipe(
                withLatestFrom(
                    this.store.select((state) => state.detailedStatusState[scope]),
                    this.store.select((state) => state.detailedStatusState.lockedFilter)
                )
            )
        ),
        switchMap(([scope, filter, lockedFilter]) => {
            const requestFilter = {
                ...(lockedFilter || filter),
                exScope:
                    (lockedFilter || filter).exScope === ExceptionScope.All ? null : (lockedFilter || filter).exScope,
            };
            return this.detailedStatusService.getDetailedStatusData(requestFilter).pipe(
                map((dataPagination) => {
                    return new DetailedStatusFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering detailed status page. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusFilterError(error));
                })
            );
        })
    );

    @Effect()
    addITRConstraint$ = this.actions$.pipe(
        ofType<DetailedStatusAddITRConstraintRequest>(DetailedStatusActionTypes.DetailedStatusAddITRConstraintRequest),
        mergeMap(({ payload }) =>
                this.itrconstraintservice.add(payload.itrConstraint).pipe(
                map((constId) => {
                    this.toastService.Success('ITR Constraint successfully added.');
                    return new DetailedStatusAddITRConstraintSuccess(payload, Number(constId.toString()));
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding ITR constraint. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusITRConstraintError(error));
                })
            )
        )
    );

    @Effect()
    addEXForecastClosureDate$ = this.actions$.pipe(
        ofType<DetailedStatusAddEXForecastClosureDateRequest>(DetailedStatusActionTypes.DetailedStatusAddEXForecastClosureDateRequest),
        mergeMap(({ payload }) => {
            let newPayload = _.cloneDeep(payload);
            newPayload.ex.forecastClosureDate = newPayload.forecastClosureDate;        
              return this.itrconstraintservice.addEXForeCastClosureDate(newPayload.ex).pipe(
                map((exId) => {
                    this.toastService.Success('Forecast Closure Date added successfully.');
                    return new DetailedStatusAddEXForecastClosureDateSuccess(payload, Number(exId.toString()));
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding forecast closure Date. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddEXForecastClosureDateError(error));
                })
            )
            })
    );

    @Effect()
    addEXResponsiblePersons$ = this.actions$.pipe(
        ofType<DetailedStatusAddResponsiblePersonRequest>(DetailedStatusActionTypes.DetailedStatusAddResponsiblePersonRequest),
        mergeMap(({ payload }) => {
            let newPayload = _.cloneDeep(payload);
            if(newPayload.typeInput === UserDefinedInputs.ResponsiblePerson) {
                newPayload.ex.responsiblePersonId = newPayload.responsiblePersonId;  
            } else if(newPayload.typeInput === UserDefinedInputs.AdditionalResponsiblePerson) {
                newPayload.ex.exceptionAdditionalResponsibleUserId = newPayload.responsiblePersonId;  
            }
                
               return this.itrconstraintservice.addEXResponsiblePerson(newPayload.ex).pipe(
                map((exId) => {
                    newPayload.typeInput === UserDefinedInputs.ResponsiblePerson ?
                    this.toastService.Success('Responsible Person added successfully.') :
                    this.toastService.Success('Addtl Responsible Person added successfully.');
                    return new DetailedStatusAddResponsiblePersonSuccess(payload, Number(exId.toString()));
                }),
                catchError((error) => {
                    newPayload.typeInput === UserDefinedInputs.ResponsiblePerson ?
                    this.toastService.Error(
                        'Error occurred while adding responsible person. Please contact Program Administrator.'
                    ):
                    this.toastService.Error(
                        'Error occurred while adding addtl responsible person. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddResponsiblePersonError(error));
                })
            )
            })
    );
    
    @Effect()
    updateITRConstraint$ = this.actions$.pipe(
        ofType<DetailedStatusUpdateITRConstraintRequest>(DetailedStatusActionTypes.DetailedStatusUpdateITRConstraintRequest),
        mergeMap(({ payload }) =>
                this.itrconstraintservice.update(payload.itrConstraint).pipe(
                map(() => {
                    if (payload.itrConstraint.deleted === "deleted") {
                        this.toastService.Success('ITR Constraint is successfully closed')
                    } else {
                        this.toastService.Success('ITR Constraint successfully updated');
                    }
                    
                    return new DetailedStatusUpdateITRConstraintSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating ITR constraint. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusITRConstraintError(error));
                })
            )
        )
    );

    @Effect()
    updateExPriority$ = this.actions$.pipe(
        ofType<DetailedStatusAddExPriorityRequest>(DetailedStatusActionTypes.DetailedStatusAddExPriorityRequest),
        mergeMap(({ payload }) =>
                this.itrconstraintservice.addExceptionPriority(payload.priority).pipe(
                map(() => {                    
                    this.toastService.Success('Exception Priority Updated');
                    return new DetailedStatusAddExPrioritySuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating Exception Priority. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddExPriorityError(error));
                })
            )
        )
    );

    @Effect()
    addComment$ = this.actions$.pipe(
        ofType<DetailedStatusAddCommentRequest>(DetailedStatusActionTypes.DetailedStatusAddCommentRequest),
        mergeMap(({ payload }) => {
          return  ((payload.additionaEntityId === null || payload.additionaEntityId === undefined)
           && payload.nbResponsiblePerson !== UserDefinedInputs.ResponsiblePerson && payload.scope !== SubsystemScope.RFOWorkToGoSearch) ?
           this.commentService.addComment(payload.entityId, payload.description, payload.scope, payload.mentions).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully added.');
                    return new DetailedStatusAddCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating comment. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddCommentError(error));
                })
            ) : (payload.nbResponsiblePerson === UserDefinedInputs.ResponsiblePerson) ? 
            this.commentService.addNBResponsiblePersonComment(payload.entityId, payload.description, payload.scope, payload.mentions).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully added.');
                    return  payload.scope === this.subsystemScopes.PLI ?
                     new DetailedStatusAddCommentSuccess(payload) :
                    new DetailedStatusAddITRResponsibleCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating comment. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddCommentError(error));
                })
            )
            : (payload.subSystem !== null && payload.scope === SubsystemScope.RFOWorkToGoSearch) ? 
            this.commentService.addRFOWorksToGOComment(payload.entityId, payload.description, payload.scope, payload.mentions,payload.subSystem, payload.rfo).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully added.');
                    return new DetailedStatusAddCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating comment. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddCommentError(error));
                })
            )
            :
             this.commentService.addExceptionComment(payload.entityId, payload.description,payload.additionaEntityId, payload.scope, payload.mentions).pipe(
                map(() => {
                   this.toastService.Success('Comment successfully added.');
                    return new DetailedStatusAddCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating comment. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddCommentError(error));
                })
            ) ;
        })
    );

    @Effect()
    addO3Constraint$ = this.actions$.pipe(
        ofType<DetailedStatusAddO3ConstraintRequest>(DetailedStatusActionTypes.DetailedStatusAddO3ConstraintRequest),
        mergeMap(({ payload }) => {
          return this.commentService.addO3Constraint(payload.tagNumber,payload.exceptionNumber,payload.pliqvdNumber, payload.subsystem,payload.o3constraint, payload.scope, payload.mentions).pipe(
                map(() => {
                    this.toastService.Success('O3 Constraint successfully added.');
                    return new DetailedStatusAddO3ConstraintSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating o3 constraint. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddO3ConstraintError(error));
                })
            );
        })
    );

    @Effect()
    addBulkComment$ = this.actions$.pipe(
        ofType<DetailedStatusAddBulkCommentRequest>(DetailedStatusActionTypes.DetailedStatusAddBulkCommentRequest),
        mergeMap((action) =>
            of(action).pipe(
                withLatestFrom(
                    this.store.select((state) => state.detailedStatusState[this.subsystemScopes[action.payload.scope]]),
                    this.store.select((state) => state.detailedStatusState.lockedFilter)
                )
            )
        ),
        switchMap(([{ payload }, filter, lockedFilter]) =>
            this.commentService
                .addBulkComment(payload.description, payload.scope, payload.mentions, lockedFilter || filter)
                .pipe(
                    map(() => {
                        this.toastService.Success('Bulk Comment successfully added.');
                        return new DetailedStatusAddBulkCommentSuccess(payload);
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while updating bulk comment. Please contact Program Administrator.'
                        );
                        return of(new DetailedStatusAddBulkCommentError(error));
                    })
                )
        )
    );


    @Effect()
    addBulkExceptionComment$ = this.actions$.pipe(
        ofType<DetailedStatusAddBulkExceptionCommentRequest>(DetailedStatusActionTypes.DetailedStatusAddBulkExceptionCommentRequest),
        mergeMap((action) =>
            this.commentService
                .addBulkExceptionComment(action.payload.description, action.payload.mentions, action.payload.exceptionNumber)
                .pipe(
                    map(() => {
                        this.toastService.Success('Exception Bulk Comment successfully added.');
                        this.store.dispatch(new DetailedStatusAddBulkExceptionCommentSuccess(action.payload));
                        return action.payload.scope;
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while updating exception bulk comment. Please contact Program Administrator.'
                        );
                        this.store.dispatch(new DetailedStatusAddBulkExceptionCommentError(error));
                        return of(action.payload.scope);
                    })
                )
        ),
        switchMap(( payload ) => of(new DetailedStatusFilterRequest( this.subsystemScopes[payload])))
    );



    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<DetailedStatusRemoveCommentRequest>(DetailedStatusActionTypes.DetailedStatusRemoveCommentRequest),                
        mergeMap(({ payload }) =>
            payload.scope === SubsystemScope.RFOWorkToGoSearch ?
            this.commentService
                .deleteRFOWorksToGoComment(payload.id, payload.scope)
                .pipe(
                    map(() => {
                        this.toastService.Success('Comment successfully removed.');

                        this.store.dispatch(new DetailedStatusRemoveCommentSuccess({
                            id: payload.id,
                            lastComment: payload.lastComment,
                            entityId: payload.entityId,
                        }));
                        return  payload.scope;
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while removing RFO Works To Go comment. Please contact Program Administrator.'
                        );
                        this.store.dispatch(new DetailedStatusRemoveCommentError(error));
                        return of(payload.scope);
                    })
                )
            :this.commentService
                .deleteITRComment(payload.id, payload.scope)
                .pipe(
                    map(() => {
                        this.toastService.Success('Comment successfully removed.');

                        this.store.dispatch(new DetailedStatusRemoveCommentSuccess({
                            id: payload.id,
                            lastComment: payload.lastComment,
                            entityId: payload.entityId,
                        }));
                        return  payload.mainScope;
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while removing DetailedStatus comment. Please contact Program Administrator.'
                        );
                        this.store.dispatch(new DetailedStatusRemoveCommentError(error));
                        return of(payload.mainScope);
                    })
                )
        ),   
        switchMap(( payload ) => of(new DetailedStatusFilterRequest( this.subsystemScopes[payload])))    
    );

    @Effect()
    updateImplementationTeam$ = this.actions$.pipe(
        ofType<DetailedStatusUpdatePliImplementationTeamRequest>(DetailedStatusActionTypes.DetailedStatusUpdatePliImplementationTeamRequest),
        mergeMap((action) => {
            return this.detailedStatusService.updatePliImplementationTeam(action.payload)
            .pipe(
                map(() => {
                    this.toastService.Success('Implementation Team Updated.');
                    return new DetailedStatusUpdatePliImplementationTeamSuccess(action.payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering PLI Implementation Team. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusUpdatePliImplementationTeamError(error));
                })
            );
        })
    );





    @Effect()
    deleteExceptionComment$ = this.actions$.pipe(
        ofType<DetailedStatusRemoveExceptionCommentRequest>(DetailedStatusActionTypes.DetailedStatusRemoveExceptionCommentRequest),        
        mergeMap(({ payload }) =>
            this.commentService
                .deleteExceptionComment(payload.id, payload.exscope)
                .pipe(
                    map(() => {
                        this.toastService.Success('Comment successfully removed.');

                        this.store.dispatch(new DetailedStatusRemoveExceptionCommentSuccess({
                            id: payload.id,
                            lastComment: payload.lastComment,
                            exscope: payload.exscope,
                            
                        }));
                        return payload.mainScope;
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while removing DetailedStatus comment. Please contact Program Administrator.'
                        );
                        this.store.dispatch(new DetailedStatusRemoveExceptionCommentError(error));
                        return of(payload.mainScope);
                    })
                )
        ),
        switchMap(( payload ) => of(new DetailedStatusFilterRequest( this.subsystemScopes[payload])))    
    );

    @Effect()
    addPLIForecastClosureDate$ = this.actions$.pipe(
        ofType<DetailedStatusAddPLIForecastClosureDateRequest>(DetailedStatusActionTypes.DetailedStatusAddPLIForecastClosureDateRequest),
        mergeMap(({ payload }) => {
            let newPayload = _.cloneDeep(payload);
            newPayload.pli.pliForecastClosureDate = newPayload.forecastClosureDate;        
              return this.pliservice.addPLIForeCastClosureDate(newPayload.pli).pipe(
                map((pliId) => {
                    this.toastService.Success('PLI Forecast Closure Date added successfully.');
                    return new DetailedStatusAddPLIForecastClosureDateSuccess(payload, Number(pliId.toString()));
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding PLI forecast closure Date. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddPLIForecastClosureDateError(error));
                })
            )
            })
    );

    @Effect()
    addPostRFOWorkExpectedClosureDate$ = this.actions$.pipe(
        ofType<DetailedStatusAddPostRFOWorkExpectedClosureDateRequest>(DetailedStatusActionTypes.DetailedStatusAddPostRFOWorkExpectedClosureDateRequest),
        mergeMap(({ payload }) => {
            let newPayload = _.cloneDeep(payload);
            newPayload.postRfoData.postRFOWorkExpectedClosureDate = newPayload.expectedClosureDate;        
              return this.detailedStatusService.addPostRFOWorkExpectedClosureDate(newPayload.postRfoData).pipe(
                map((postRfoData) => {
                    this.toastService.Success('POST RFO Works Expected Closure Date updated successfully.');
                    return new DetailedStatusAddPostRFOWorkExpectedClosureDateSuccess(newPayload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding POST RFO Works Expected closure Date. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusAddPostRFOWorkExpectedClosureDateError(error));
                })
            )
            })
    );


    updateManualPriority$ = createEffect(() => this.actions$.pipe(
        ofType<DetailedStatusPostRFOWorkUpdateManualPriorityRequest>(DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateManualPriorityRequest),
        mergeMap(({ payload }) =>
                this.detailedStatusService.updatePostRFOManualPriority(payload.manualPriority).pipe(
                map(() => {                    
                    this.toastService.Success('Post RFO Work Manual Priority Updated');
                    return new DetailedStatusPostRFOWorkUpdateManualPrioritySuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating Post RFO Work Manual Priority. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusPostRFOWorkUpdateManualPriorityError(error));
                })
            )
        )
    ));

    updateResponsiblePersonOrGroup$ = createEffect(() => this.actions$.pipe(
        ofType<DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest>(DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest),
        mergeMap(({ payload }) =>
                this.detailedStatusService.updatePostRFOResponsiblePersonOrGroup(payload.responsiblePersonOrGroup).pipe(
                map(() => {                    
                    this.toastService.Success('Post RFO Work Responsible Person Or Group Updated');
                    return new DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating Post RFO Work Responsible Person Or Group. Please contact Program Administrator.'
                    );
                    return of(new DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupError(error));
                })
            )
        )
    ));
    

}
