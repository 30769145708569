import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/components/base.component';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { ManualUploadSetActivatedRoute } from 'src/app/store/manual-upload/actions';
import { COWUploadLogRequest, COWTemplateFileRequest, COWDownloadOutputDataRequest } from 'src/app/store/manual-upload/carry-over-work/actions';

@Component({
  selector: 'app-cow-list',
  templateUrl: './cow-list.component.html',
  styleUrls: ['./cow-list.component.scss']
})
export class COWListComponent extends BaseComponent {
  uploadLogData: MatTableDataSource<UpdateDataLog>;
  displayedUploadLogDataColumns = [
    'type',
    'status',
    'startDate',
    'endDate',
    'infoMessage',
    'errorMessage',
    'user',
    'downloadResult',
  ];

  uploadLogData$ = this.store.select((store) => store.cowListState.uploadLogData);
  isUploadLogLoading$ = this.store.select((store) => store.cowListState.isUploadLogLoading);
  isLoading$ = this.store.select((store) => store.cowListState.isLoading);
  interval$ = this.store.select((store) => store.manualUploadState.interval);
  intervalSub: Subscription = null;
  showOrder = true;

  constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
      this.store.dispatch(new ManualUploadSetActivatedRoute(urlSegments[0].path));
    });

    this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
      if (this.intervalSub) {
        this.intervalSub.unsubscribe();
      }
      this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
        this.store.dispatch(new COWUploadLogRequest());
      });
    });

    this.uploadLogData$.pipe(takeWhile(() => this.isAlive))
      .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));
  }

  downloadTemplate(fileName: string){
    this.store.dispatch(new COWTemplateFileRequest(fileName));
  }

  downloadData(filePath: string) {
    this.store.dispatch(new COWDownloadOutputDataRequest(filePath));
}
}
