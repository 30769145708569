import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { HeaderDateFilter, HeaderDateFilterResult } from 'src/app/models/filter-settings';
import { BaseComponent } from 'src/app/components/base.component';
import { DateRange } from '@angular/material/datepicker';

@Component({
    selector: 'app-header-date-filter',
    templateUrl: './header-date-filter.component.html',
    styleUrls: ['./header-date-filter.component.scss'],
})
export class HeaderDateFilterComponent extends BaseComponent implements OnDestroy {
    isAscendingSort: boolean = false;
    isDescendingSort: boolean = false;
    placeHolder: string;
    excludeBlanks = false;
    onlyBlanks = false;
    isSortingOff: boolean;
    action: (data: HeaderDateFilterResult) => void;
    selectedRangeValue: DateRange<Date> | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: HeaderDateFilter,
        private dialogRef: MatDialogRef<HeaderDateFilterComponent>,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        super();

        iconRegistry.addSvgIcon('iconCalendar', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon-cal.svg'));

        this.action = data.action;
        const { rangeDates } = data.calendarColumn;
        this.selectedRangeValue = new DateRange(rangeDates ? rangeDates[0] : null, rangeDates ? rangeDates[1] : null);
        this.excludeBlanks = data.calendarColumn.excludeBlanks;
        this.onlyBlanks = data.calendarColumn.onlyBlanks;
        this.placeHolder = data.placeHolder;
        this.isAscendingSort = data.isAscendingSort;
        this.isDescendingSort = data.isDescendingSort;
        this.isSortingOff = data.isSortingOff;

        dialogRef.afterClosed().subscribe((applyChanges: boolean) => {
            if (applyChanges) {
                this.action({
                    isAscendingSort: this.isAscendingSort,
                    isDescendingSort: this.isDescendingSort,
                    calendarColumn: {
                        rangeDates: (this.selectedRangeValue && this.selectedRangeValue.start && this.selectedRangeValue.end)
                            ? [moment(this.selectedRangeValue.start).startOf('day').utc(true).toDate(), moment(this.selectedRangeValue.end).startOf('day').utc(true).toDate()]
                            : [],
                        excludeBlanks: this.excludeBlanks,
                        onlyBlanks: this.onlyBlanks,
                    },
                });
            }
        });
    }

    clearDate() {
        this.selectedRangeValue = null;
    }

    close() {
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.dialogRef.close(true);
    }

    inlineRangeChange($event) {
        if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
            this.selectedRangeValue = new DateRange<Date>($event, null);
        } else {
            const start = this.selectedRangeValue.start;
            const end = $event;
            if (end < start) {
                this.selectedRangeValue = new DateRange<Date>(end, start);
            } else {
                this.selectedRangeValue = new DateRange<Date>(start, end);
            }
        }
    }

    updateBlanks(radio: any) {
        switch (radio.value) {
            case 'all':
                this.excludeBlanks = false;
                this.onlyBlanks = false;
                break;
            case 'onlyBlanks':
                this.excludeBlanks = false;
                this.onlyBlanks = true;
                break;
            case 'excludeBlanks':
                this.excludeBlanks = true;
                this.onlyBlanks = false;
                break;
        }
    }
}
