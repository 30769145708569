import * as moment from 'moment';
import {
    Waypoint,
    Milestone,
    Contractor,
    Gooc,
    RFO,
    OrderDirection,
    CheckListColumn,
    BaseResultDTO,
    CalendarColumn,
    StagedStartUpPriority,
    NumericColumn,
} from '../../common.model';
import { initWeekRange, getCurrentWeekStartDate } from 'src/app/extensions';
import { DetailedStatusDTO } from '../../details/model';
import { ExceptionScope } from 'src/app/enums';

export const HEALTH_API_CALL_DATE_FORMAT = 'YYYY-MM-DD';
export const HEALTH_CLEANLINESS_NUM_OF_PAST_WEEKS = 6;
export const HEALTH_ACTIVITIES_CONSTRAINTS_NUM_OF_PAST_WEEKS = 4;
export const HEALTH_ACTIVITIES_CONSTRAINTS_NUM_OF_UPCOMING_WEEKS = 1;

export class HealthCleanlinessDTO {
    isClean: boolean;
    week: moment.Moment;
}

export class HealthFilter {
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    startDate: string;
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    endDate: string;
    mcEngineer: string[] = [];
    scManager: string[] = [];
    systemOwner: string[] = [];
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
}

export class OverdueDPLIFilter {
    page = 0;
    take = 25;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    disciplines: string[] = [];
    systemOwners: string[] = [];
    scManager: string[] = [];
    projectActionOnly: boolean = null;
    overdueOnly: boolean = null;
    selectedBarLabel = '';

    columnSubsystem: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnNumber: CheckListColumn = null;
    columnDescription: CheckListColumn = null;
    columnSubsystemName: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnActionBy: CheckListColumn = null;
    columnMaterialReference: CheckListColumn = null;
    columnPunchType: CheckListColumn = null;
    columnRaisedBy: CheckListColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnReviewStatusComplete: CheckListColumn = null;
    columnSystemOwner: CheckListColumn = null;
}

export interface HealthCleanlinessTotalItem {
    greenCount: number;
    redCount: number;
    percentage: number;
}

export class HealthCleanlinessState {
    cleanlinessType = '';
    filter = new HealthFilter();
    weeks = initWeekRange(
        getCurrentWeekStartDate().add(-HEALTH_CLEANLINESS_NUM_OF_PAST_WEEKS, 'weeks'),
        HEALTH_CLEANLINESS_NUM_OF_PAST_WEEKS
    );
    isLoading = false;
    data: HealthCleanlinessTotalItem[] = [];
}

export class OverdueDPLIState {
    filter = new OverdueDPLIFilter();
    table: BaseResultDTO<DetailedStatusDTO> = null;
    chart = [
        {
            name: '4+ Months',
            value: 0,
        },
        { name: '3 Months', value: 0 },
        { name: '2 Months', value: 0 },
        { name: '1 Month', value: 0 },
        { name: 'within 1 Month', value: 0 },
    ];
    isTableLoading = false;
    isChartLoading = false;
}

export class ExceptionStatusDTO {
    id: number = null;
    subsystem = '';
    subsystemName = '';
    tagNumber = '';
    tagType = '';
    tagName = '';
    number = '';
    description = '';
    discipline = '';
    contractor = '';
    exceptionNumber = '';
    exceptionPhase = '';
    targetCompletionDate: moment.Moment;
    rfsuForecast: moment.Moment;
    rfsuPlan: moment.Moment;
    commentId: number = null;
    latestComment = '';
}

export class OverdueExceptionsFilters {
    contractors: Contractor[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    disciplines: string[] = [];
    systemOwners: string[] = [];
    scManagers: string[] = [];
    overdueOnly: boolean = null;
    exceptionPhases: string[] = [];
    rfos: RFO[] = [];
    goocs: Gooc[] = [];
    exScope = ExceptionScope.All;
    selectedBarLabel = '';

    page = 0;
    take = 25;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;

    columnSubsystem: CheckListColumn = null;
    columnNumber: CheckListColumn = null;
    columnDescription: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnTargetCompletionDate: CalendarColumn = null;
    columnRFSUForecast: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;
}

export class OverdueExceptionsState {
    filter = new OverdueExceptionsFilters();
    showColumns = new OverdueExceptionsShowHideColumns();
    table: BaseResultDTO<ExceptionStatusDTO> = null;
    chart = [
        { name: '3+ Months', value: 0 },
        { name: '2 Months', value: 0 },
        { name: '1 Month', value: 0 },
        { name: 'within 1 Month', value: 0 },
        { name: 'Not Overdue', value: 0}
    ];
    isTableLoading = false;
    isChartLoading = false;
}

export interface HealthState {
    cleanliness: HealthCleanlinessState;
    overdueDpli: OverdueDPLIState;
    overdueExceptions: OverdueExceptionsState;
    overdueExceptionsByPriority: OverdueExceptionsByPriorityState;
    activitiesConstraints: ActivitiesConstraintsState;
}

export class OverdueExceptionsShowHideColumns {
    showTagType = false;
    showDiscipline = true;
    showDescription = true;
    showTargetCompletionDate = true;
    showRFSUForecast = true;
    showRFSUPlan = true;
}

export class OverdueExceptionLabels
{
    public static readonly NotOverdue = "Not Overdue";
    public static readonly WithinMonth = "within 1 Month";
    public static readonly OneMonth = "1 Month";
    public static readonly TwoMonths = "2 Months";
    public static readonly ThreeMoreMonths = "3+ Months";
}

export class ActivitiesConstraintsState {
    filter = new ActivitiesConstraintsFilter();
    chart: ActivityConstrainsReportDTO[] = [];
    weeks = initWeekRange(
        getCurrentWeekStartDate().add(-HEALTH_ACTIVITIES_CONSTRAINTS_NUM_OF_PAST_WEEKS, 'weeks'),
        HEALTH_ACTIVITIES_CONSTRAINTS_NUM_OF_PAST_WEEKS + HEALTH_ACTIVITIES_CONSTRAINTS_NUM_OF_UPCOMING_WEEKS
    );
    isChartLoading = false;
}

export class OverdueExceptionsByPriorityState {
    filter = new OverdueExceptionsByPriorityFilters();
    chart: any[] = [];
    table: BaseResultDTO<ExceptionStatusDTO> = null;
    subsystemChart: any[] = [];
    subsystemTable: BaseResultDTO<SubsystemExceptionsDTO> = null;
    isChartLoading = false;
    isSubsystemChartLoading = false;
    isTableLoading = false;
    isSubsystemTableLoading = false;
    showColumns = new OverdueExceptionsShowHideColumns();
    showTable = false;
    showSubsystemTable = false;
}

export class OverdueExceptionsByPriorityDTO{
    name: string;
    overdue: number;
    notOverdue: number;
}

export class SubsystemExceptionsDTO{
    subsystem: string;
    priorities: string;
    mcExceptionsCount: number;
    rfsuExceptionsCount: number;
    rfoExceptionsCount: number;
}

export class ActivityConstrainsReportDTO{
    constraintType: string;
    values: {
        key: moment.Moment;
        count: number;
    }[];
}

export class ITRConstrainsReportDTO{
    constraintType: string;
    values: {
        key: moment.Moment;
        count: number;
    }[];
}

export class OverdueExceptionsByPriorityFilters {
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    disciplines: string[] = [];
    systemOwners: string[] = [];
    scManagers: string[] = [];
    overdueOnly: boolean = null;
    exceptionPhases: string[] = [];
    rfos: RFO[] = [];
    goocs: Gooc[] = [];
    exScope = ExceptionScope.All;

    categories: string[] = [];
    actionBy: string[] = [];
    punchType: string[] = [];
    subsystems: string[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    qvdNumbers: string[] = [];

    page = 0;
    take = 25;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;

    selectedBarLabel = '';
    selectedBarSeries = '';

    columnSubsystem: CheckListColumn = null;
    columnNumber: CheckListColumn = null;
    columnDescription: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnTargetCompletionDate: CalendarColumn = null;
    columnRFSUForecast: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;
    columnPriorities: CheckListColumn = null;
    columnMCExceptionsCount: NumericColumn = null;
    columnRFSUExceptionsCount: NumericColumn = null;
    columnRFOExceptionsCount: NumericColumn = null;
}

export class ActivitiesConstraintsFilter{
    startDate: string;
    endDate: string;
    range: number = 12;
    rfos: RFO[] = [];
    subsystems: string[] = [];
    disciplines: string[] = [];
    areas: string[] = [];
    scManagers: string[] = [];
    contractors: Contractor[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    constraintTypes: string[] = [];
    workAreas: string[] = [];
}

export enum ChartType {
    Activities = 0,
    ITR = 1
}
