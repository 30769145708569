import { BlueZoneState, BlueZoneDataPagination, BlueZoneFilter } from './model';
import { BlueZoneActions, BlueZoneActionTypes } from './actions';

const initialState: BlueZoneState = {
    dataPagination: new BlueZoneDataPagination(),
    filter: new BlueZoneFilter(),
    isLoading: false,
};

export function reducer(state = initialState, action: BlueZoneActions): BlueZoneState {
    switch (action.type) {
        case BlueZoneActionTypes.BlueZoneFilterRequest:
        case BlueZoneActionTypes.BlueZoneRemoveCommentRequest:
        case BlueZoneActionTypes.BlueZoneRemoveCommentError:
        case BlueZoneActionTypes.BlueZoneRFSUForecastDateUpdateRequest:
        case BlueZoneActionTypes.BlueZoneRFSUForecastDateUpdateSuccess:
        case BlueZoneActionTypes.BlueZoneRFSUForecastDateUpdateError:
        case BlueZoneActionTypes.BlueZoneAddCommentRequest:
        case BlueZoneActionTypes.BlueZoneAddCommentError:
        case BlueZoneActionTypes.BlueZoneAddBulkCommentRequest:
        case BlueZoneActionTypes.BlueZoneAddBulkCommentError:
        case BlueZoneActionTypes.BlueZoneExportToExcelRequest:
        case BlueZoneActionTypes.BlueZoneExportOnePagerRequest:   
        case BlueZoneActionTypes.BlueZoneExportOnePagerError:
        case BlueZoneActionTypes.BlueZoneUpdateBacklogCatRequest: 
        case BlueZoneActionTypes.BlueZoneUpdateBacklogCatSuccess:
        case BlueZoneActionTypes.BlueZoneUpdateBacklogCatError:
        case BlueZoneActionTypes.BlueZoneSetFlagColorRequest:
        case BlueZoneActionTypes.BlueZoneSetFlagColorError:
        case BlueZoneActionTypes.BlueZoneToggleWalkdownCommitmentRequest:
        case BlueZoneActionTypes.BlueZoneToggleWalkdownCommitmentError:
        case BlueZoneActionTypes.BlueZoneClearFlagColorRequest:
        case BlueZoneActionTypes.BlueZoneClearFlagColorError: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case BlueZoneActionTypes.BlueZoneFilterSuccess: {
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        }
        case BlueZoneActionTypes.BlueZoneFilterError:
        case BlueZoneActionTypes.BlueZoneExportToExcelSuccess:
        case BlueZoneActionTypes.BlueZoneExportToExcelError:
        case BlueZoneActionTypes.BlueZoneExportOnePagerSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case BlueZoneActionTypes.BlueZoneFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case BlueZoneActionTypes.BlueZoneFilterReset: {
            const newFilter = new BlueZoneFilter();
            newFilter.showHideColumns = state.filter.showHideColumns;
            newFilter.sortBy = state.filter.sortBy;
            newFilter.direction = state.filter.direction;
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case BlueZoneActionTypes.BlueZoneRemoveCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((s) =>
                state.filter.disciplineBreakdownEnabled
                    ? s.subsystemToDisciplineId === action.payload.entityId
                    : s.subsystem === action.payload.entityId
            );
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                comment: action.payload.lastComment.description,
                                mentions: action.payload.lastComment.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }

        case BlueZoneActionTypes.BlueZoneAddCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((x) =>
                state.filter.disciplineBreakdownEnabled
                    ? x.subsystemToDisciplineId === action.payload.entityId
                    : x.subsystem === action.payload.entityId
            );
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                comment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }

        case BlueZoneActionTypes.BlueZoneAddBulkCommentSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d) => ({
                        ...d,
                        comment: action.payload.description,
                        mentions: action.payload.mentions,
                    })),
                },
            };
        }

        case BlueZoneActionTypes.BlueZoneSetFlagColorSuccess: {
            var array = state.dataPagination.items.map((value) =>
                value.subsystem === action.payload.subsystem
                    ? {
                          ...value,
                          blueZoneFlag: value.blueZoneFlag !== action.payload.color ? action.payload.color : '',
                      }
                    : value
            );
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: [...array],
                },
                isLoading: false,
            };
        }
        case BlueZoneActionTypes.BlueZoneToggleWalkdownCommitmentSuccess: {
            const array = state.dataPagination.items.map((value) =>
                value.subsystem === action.payload.subsystem
                    ? {
                          ...value,
                          walkdownCommitted: action.payload.committed,
                      }
                    : value
            );
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: [...array],
                },
                isLoading: false,
            };
        }

        case BlueZoneActionTypes.BlueZoneClearFlagColorSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d) => (d.blueZoneFlag = null)),
                },
            };
        }
        default:
            return state;
    }
}
