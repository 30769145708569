import { Action } from '@ngrx/store';
import { DetailedStatusDTO, MaterialsDTO, PLIReviewType } from './model';
import { CommentDetailDTO } from '../detailed-status/model';
import { SubsystemScope } from 'src/app/enums';
import { CommentDTO, UserDetail } from '../common.model';

export enum DetailsActionTypes {
    GetDetailsRequest = '[Details] Get Details',
    GetDetailsSuccess = '[Details] Get Details Success',
    GetDetailsError = '[Details] Get Details Error',
    DetailsAddCommentRequest = '[Details] Add Comment Request',
    DetailsAddCommentSuccess = '[Details] Add Comment Success',
    DetailsAddCommentError = '[Details] Add Comment Error',

    DetailsAddConstraintCommentRequest = '[Details] Add Constraint Comment Request',
    DetailsAddConstraintCommentSuccess = '[Details] Add Constraint Comment Success',
    DetailsAddConstraintCommentError = '[Details] Add Constraint Comment Error',    
    GetCommentsRequest = '[Details] Get comments Request',
    GetCommentsSuccess = '[Details] Get comments Success',
    GetCommentsError = '[Details] Get comments Error',
    
    GetConstraintCommentsRequest = '[Details] Get Constraint comments Request',
    GetConstraintCommentsSuccess = '[Details] Get Constraint comments Success',
    GetConstraintCommentsError = '[Details] Get Constraint comments Error',
       
    GetMaterialsDetailsRequest = '[Details] Get Materials Details Request',
    GetMaterialDetailsSuccess = '[Details] Get Materials Details Success',
    GetMaterialsDetailsError = '[Details] Get Materials Details Error',
    DetailsDeleteCommentRequest = '[Details] Delete Comment Request',
    DetailsDeleteCommentSuccess = '[Details] Delete Comment Success',
    DetailsDeleteCommentError = '[Details] Delete Comment Error',

    DetailsDeleteConstraintCommentRequest = '[Details] Delete Constraint Comment Request',
    DetailsDeleteConstraintCommentSuccess = '[Details] Delete Constraint Comment Success',
    DetailsDeleteConstraintCommentError = '[Details] Delete Constraint Comment Error',
    UpdateDetailsRequest = '[Details] Update Details Request',
    DetailsPLIReviewRequest = '[Details] PLI Review Request',
    DetailsPLIReviewSuccess = '[Details] PLI Review Success',
    DetailsPLIReviewError = '[Details] PLI Review Error',
    DetailsPLIReviewDeleteRequest = '[Details] PLI Review Delete Request',
    DetailsPLIReviewDeleteSuccess = '[Details] PLI Review Delete Success',
    DetailsPLIReviewDeleteError = '[Details] PLI Review Delete Error',
    DetailsConstraintFlagUpdateRequest = '[Details] ITR Constraint Glag Update Request',
    DetailsConstraintFlagUpdateSuccess = '[Details] ITR Constraint Glag Update Success',
    DetailsConstraintFlagUpdateError = '[Details] ITR Constraint Glag Update Error',
    GetConstraintDetailsSuccess = '[Details] Get Constraint Details Success',
    GetConstraintDetailsError = '[Details] Get Constraint Details Error',
    GetConstraintCommentDetailsRequest = '[Details] Get Constraint Comment Details',
    GetConstraintCommentDetailsSuccess = '[Details] Get Constraint Comment Success',
    GetConstraintCommentDetailsError = '[Details] Get Constraint Comment Error',
}

export class UpdateDetailsRequest implements Action {
    readonly type = DetailsActionTypes.UpdateDetailsRequest;

    constructor(public payload: DetailedStatusDTO) {}
}

export class GetDetailsRequest implements Action {
    readonly type = DetailsActionTypes.GetDetailsRequest;

    constructor(public payload: { id: string; scope: SubsystemScope }) {}
}

export class GetDetailsSuccess implements Action {
    readonly type = DetailsActionTypes.GetDetailsSuccess;

    constructor(public payload: DetailedStatusDTO) {}
}

export class GetDetailsError implements Action {
    readonly type = DetailsActionTypes.GetDetailsError;

    constructor(public payload: string) {}
}


export class GetConstraintDetailsSuccess implements Action {
    readonly type = DetailsActionTypes.GetConstraintDetailsSuccess;

    constructor(public payload:{ id: number; detail: DetailedStatusDTO }) {}
}

export class GetConstraintDetailsError implements Action {
    readonly type = DetailsActionTypes.GetConstraintDetailsError;

    constructor(public payload: string) {}
}

export class GetConstraintCommentsRequest implements Action {
    readonly type = DetailsActionTypes.GetConstraintCommentsRequest;

    constructor(public payload: { entityId: string; scope: SubsystemScope }) {}
}

export class GetConstraintCommentsSuccess implements Action {
    readonly type = DetailsActionTypes.GetConstraintCommentsSuccess;

    constructor(public payload: CommentDetailDTO[]) {}
}

export class GetConstraintCommentsError implements Action {
    readonly type = DetailsActionTypes.GetConstraintCommentsError;

    constructor(public payload: string) {}
}

export class GetCommentsRequest implements Action {
    readonly type = DetailsActionTypes.GetCommentsRequest;

    constructor(public payload: { entityId: string; scope: SubsystemScope }) {}
}

export class GetCommentsSuccess implements Action {
    readonly type = DetailsActionTypes.GetCommentsSuccess;

    constructor(public payload: CommentDetailDTO[]) {}
}

export class GetCommentsError implements Action {
    readonly type = DetailsActionTypes.GetCommentsError;

    constructor(public payload: string) {}
}

export class DetailsAddCommentRequest implements Action {
    readonly type = DetailsActionTypes.DetailsAddCommentRequest;

    constructor(public payload: { entityId: string; description: string; scope: SubsystemScope, mentions: UserDetail[] }) {}
}

export class DetailsAddCommentSuccess implements Action {
    readonly type = DetailsActionTypes.DetailsAddCommentSuccess;

    constructor(public payload: CommentDetailDTO) {}
}

export class DetailsAddCommentError implements Action {
    readonly type = DetailsActionTypes.DetailsAddCommentError;

    constructor(public payload: string) {}
}

export class DetailsAddConstraintCommentRequest implements Action {
    readonly type = DetailsActionTypes.DetailsAddConstraintCommentRequest;

    constructor(public payload: { entityId: string; description: string; scope: SubsystemScope, mentions: UserDetail[] }) {}
}

export class DetailsAddConstraintCommentSuccess implements Action {
    readonly type = DetailsActionTypes.DetailsAddConstraintCommentSuccess;

    constructor(public payload: CommentDetailDTO) {}
}

export class DetailsAddConstraintCommentError implements Action {
    readonly type = DetailsActionTypes.DetailsAddConstraintCommentError;

    constructor(public payload: string) {}
}

export class DetailsDeleteCommentRequest implements Action {
    readonly type = DetailsActionTypes.DetailsDeleteCommentRequest;

    constructor(public payload: { id: number; scope: SubsystemScope }) {}
}

export class DetailsDeleteCommentSuccess implements Action {
    readonly type = DetailsActionTypes.DetailsDeleteCommentSuccess;

    constructor(public payload: { id: number }) {}
}

export class DetailsDeleteCommentError implements Action {
    readonly type = DetailsActionTypes.DetailsDeleteCommentError;

    constructor(public payload: string) {}
}

export class DetailsDeleteConstraintCommentRequest implements Action {
    readonly type = DetailsActionTypes.DetailsDeleteConstraintCommentRequest;

    constructor(public payload: { id: number; scope: SubsystemScope }) {}
}

export class DetailsDeleteConstraintCommentSuccess implements Action {
    readonly type = DetailsActionTypes.DetailsDeleteConstraintCommentSuccess;

    constructor(public payload: { id: number }) {}
}

export class DetailsDeleteConstraintCommentError implements Action {
    readonly type = DetailsActionTypes.DetailsDeleteConstraintCommentError;

    constructor(public payload: string) {}
}

export class DetailsPLIReviewRequest implements Action {
    readonly type = DetailsActionTypes.DetailsPLIReviewRequest;

    constructor(public payload: { finalPunchItem: string, type: PLIReviewType }) {}
}

export class DetailsPLIReviewSuccess implements Action {
    readonly type = DetailsActionTypes.DetailsPLIReviewSuccess;

    constructor(public payload: DetailedStatusDTO) {}
}

export class  DetailsPLIReviewError implements Action {
    readonly type = DetailsActionTypes.DetailsPLIReviewError;

    constructor(public payload: string) {}
}

export class DetailsPLIReviewDeleteRequest implements Action {
    readonly type = DetailsActionTypes.DetailsPLIReviewDeleteRequest;

    constructor(public payload: { id: number }) {}
}

export class DetailsPLIReviewDeleteSuccess implements Action {
    readonly type = DetailsActionTypes.DetailsPLIReviewDeleteSuccess;

    constructor(public payload: DetailedStatusDTO) {}
}

export class  DetailsPLIReviewDeleteError implements Action {
    readonly type = DetailsActionTypes.DetailsPLIReviewDeleteError;

    constructor(public payload: string) {}
}

export class DetailsConstraintFlagUpdateRequest implements Action {
    readonly type = DetailsActionTypes.DetailsConstraintFlagUpdateRequest;

    constructor(public payload: DetailedStatusDTO) {}
}
export class DetailsConstraintFlagUpdateSuccess implements Action {
    readonly type = DetailsActionTypes.DetailsConstraintFlagUpdateSuccess;
}
export class DetailsConstraintFlagUpdateError implements Action {
    readonly type = DetailsActionTypes.DetailsConstraintFlagUpdateError;

    constructor(public payload: string) {}
}

export class GetConstraintCommentDetailsRequest implements Action {
    readonly type = DetailsActionTypes.GetConstraintCommentDetailsRequest;

    constructor(public payload: { id: string; itrConstraintId:string; scope: SubsystemScope }) {}
}

export class GetConstraintCommentDetailsSuccess implements Action {
    readonly type = DetailsActionTypes.GetConstraintCommentDetailsSuccess;

    constructor(public payload:{ item: DetailedStatusDTO; comments: CommentDTO[];constraintComments: CommentDTO[]}) {}
}

export class GetConstraintCommentDetailsError implements Action {
    readonly type = DetailsActionTypes.GetConstraintCommentDetailsError;

    constructor(public payload: string) {}
}

export class GetMaterialsDetailsRequest implements Action {
    readonly type = DetailsActionTypes.GetMaterialsDetailsRequest;

    constructor(public payload: { id: string; itemCodeId:string; scope: SubsystemScope }) {}
}

export class GetMaterialsDetailsSuccess implements Action {
    readonly type = DetailsActionTypes.GetMaterialDetailsSuccess;

    constructor(public payload:{ item: DetailedStatusDTO; materials: MaterialsDTO[]}) {}
}

export class GetMaterialsDetailsError implements Action {
    readonly type = DetailsActionTypes.GetMaterialsDetailsError;

    constructor(public payload: string) {}
}

export type DetailsActions =
    | GetDetailsRequest
    | GetDetailsSuccess
    | GetDetailsError
    | DetailsAddCommentRequest
    | DetailsAddCommentSuccess
    | DetailsAddCommentError
    | GetCommentsRequest
    | GetCommentsSuccess
    | GetCommentsError
    | DetailsDeleteCommentRequest
    | DetailsDeleteCommentSuccess
    | DetailsDeleteCommentError
    | UpdateDetailsRequest
    | DetailsPLIReviewRequest
    | DetailsPLIReviewSuccess
    | DetailsPLIReviewError
    | DetailsPLIReviewDeleteRequest
    | DetailsPLIReviewDeleteSuccess
    | DetailsPLIReviewDeleteError
    | DetailsConstraintFlagUpdateRequest
    | DetailsConstraintFlagUpdateSuccess
    | DetailsConstraintFlagUpdateError
    | GetConstraintDetailsSuccess 
    | GetConstraintDetailsError
    | GetConstraintCommentDetailsError
    | GetConstraintCommentDetailsRequest
    | GetConstraintCommentDetailsSuccess
    | DetailsAddConstraintCommentRequest
    | DetailsAddConstraintCommentSuccess
    | DetailsAddConstraintCommentError
    | DetailsDeleteConstraintCommentRequest
    | DetailsDeleteConstraintCommentSuccess
    | DetailsDeleteConstraintCommentError
    | GetConstraintCommentsRequest
    | GetConstraintCommentsSuccess
    | GetConstraintCommentsError
    | GetMaterialsDetailsRequest
    | GetMaterialsDetailsSuccess
    | GetMaterialsDetailsError;
