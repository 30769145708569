import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-power-bi-reports',
    templateUrl: './index.html',
    styleUrls: ['./index.scss'],
})

export class PowerBIReportsComponent extends BaseComponent implements OnInit {

    reports: any = [];
    pagerTitle: string;
    reportType: string;
    reportUrl: string;
    reportUrlSafe: SafeResourceUrl;

    constructor(
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer) {
        super();
    }

    ngOnInit() {
        this.allReports();
        this.activatedRoute.paramMap
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((paramMap) => {
                this.reportType = decodeURIComponent(paramMap.get('reporttype'));
                let reportObject = this.reports.filter((x: { type: string; }) => x.type == this.reportType);
                if (this.isItemExistsInArray(reportObject)) {
                    reportObject = reportObject[0];
                    this.pagerTitle = reportObject.title;
                    this.reportUrl = reportObject.url;
                    this.reportUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.reportUrl);
                }
            });
    }

    allReports() {
        this.reports.push(this.getReportObject('wdplanner', 'WD Planner', 'reportId=04db90d2-abb4-471d-88a7-6a1b8e3c8e3a&pageName=ReportSectiondef282b5a154ae74d83e'));
        // this.reports.push(this.getReportObject('constraint', 'Constraint Management', 'reportId=db702691-3661-499e-8b3a-a7be931910dd&pageName=ReportSectionec6445c4c3449d1c1a4a'));
        this.reports.push(this.getReportObject('constraint', 'Constraint Management', 'reportId=6605bcd9-841b-4e29-a6f0-fa8b4d37a2b8&pageName=ReportSection690fc7e60922a2ad87e2'));
        this.reports.push(this.getReportObject('fng', 'Fuel Gas Introduction', 'reportId=932cf6e6-abe0-4181-93d4-79147606113f'));
        this.reports.push(this.getReportObject('boiler', 'Boiler', 'reportId=2b6d2428-d3ac-405c-840d-d9e697a0bf51'));
        this.reports.push(this.getReportObject('gtg1', 'GTG1', 'reportId=b2b46a86-e2c2-489a-9d90-3ca52d7e614e'));
        this.reports.push(this.getReportObject('pbfstartup', 'PBF StartUp', 'reportId=928ab3e7-cabc-4312-8755-b0fbceb5bd4f'));
        this.reports.push(this.getReportObject('pbfcompletion', 'PBFCompletion', 'reportId=5ab7d098-a92f-44f8-bc9f-bab49c1ca0b1'));
        this.reports.push(this.getReportObject('3gpstartup', '3GPStartUp', 'reportId=1d7a5fb3-bd72-414f-aaf1-e8cf6fba8036'));
        this.reports.push(this.getReportObject('3gpfinalcompletion', '3GPFinalCompletion', 'reportId=20cfb847-5491-4f26-9b32-018eaf89186d'));
        this.reports.push(this.getReportObject('ptwcertificates', 'PTW Certificates Status', 'reportId=5f83f09f-9b1a-4461-a45f-0f523c296ae4&pageName=ReportSectionea5900e426a43dd2de70'));
        }
    getReportObject(type: string, title: string, url: string) {
        url = 'https://app.powerbi.com/reportEmbed?autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26&navContentPaneEnabled=false&filterPaneEnabled=false&' + url
        return {
            type: type,
            title: title,
            url: url
        };
    }

}

