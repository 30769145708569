import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { ActivityBarcodeDeletePagination } from './model';

export enum ActivityBarcodeActionTypes {
    ActivityBarcodeUploadLogRequest = '[Activity Barcode] Upload Log Request',
    ActivityBarcodeUploadLogSuccess = '[Activity Barcode] Upload Log Success',
    ActivityBarcodeUploadLogError = '[Activity Barcode] Upload Log Error',
    ActivityBarcodeDeleteRecordsRequest = '[Activity Barcode] Delete Records Request',
    ActivityBarcodeDeleteRecordsSuccess = '[Activity Barcode] Delete Records Success',
    ActivityBarcodeDeleteRecordsError = '[Activity Barcode] Delete Records Error',
    ActivityBarcodeSetStatusAndStartDate = '[Activity Barcode] Set Status and Start Date',
    ActivityBarcodeDeleteFilterPropertyUpdate = '[Activity Barcode] Delete Filter Property Update',
    ActivityBarcodeDeleteFilterReset = '[Activity Barcode] Delete Filter Reset',
    ActivityBarcodePatchDeleteRecordRequest = '[Activity Barcode] Patch Delete Record Request',
    ActivityBarcodePatchDeleteRecordSuccess = '[Activity Barcode] Patch Delete Record Success',
    ActivityBarcodePatchDeleteRecordError = '[Activity Barcode] Patch Delete Record Error',
    ActivityBarcodePatchAllDeleteRecordsRequest = '[Activity Barcode] Patch All Delete Records Request',
    ActivityBarcodePatchAllDeleteRecordsSuccess = '[Activity Barcode] Patch All Delete Records Success',
    ActivityBarcodePatchAllDeleteRecordsError = '[Activity Barcode] Patch All Delete Records Error',
    ActivityBarcodeValidateDataRequest = '[Activity Barcode] Validate Data Request',
    ActivityBarcodeValidateDataSuccess = '[Activity Barcode] Validate Data Success',
    ActivityBarcodeValidateDataError = '[Activity Barcode] Validate Data Error',
    ActivityBarcodeFileUploaded = '[Activity Barcode] File Uploaded',
    ActivityBarcodeClearInProgressSpinner = '[Activity Barcode] Clear In Progress Spinner',
    ActivityBarcodeTemplateFileRequest = '[Activity Barcode] Template File Request',
    ActivityBarcodeTemplateFileSuccess = '[Activity Barcode] Template File Success',
    ActivityBarcodeTemplateFileError = '[Activity Barcode] Template File Error',
    ActivityBarcodeDownloadOutputDataRequest = '[Activity Barcode] Download Output Data Request',
    ActivityBarcodeDownloadOutputDataSuccess = '[Activity Barcode] Download Output Data Success',
    ActivityBarcodeDownloadOutputDataError = '[Activity Barcode] Download Output Data Error',
    ActivityBarcodeValidateButtonStateRequest = '[Activity Barcode] Validate Button State Request',
    ActivityBarcodeValidateButtonStateSuccess = '[Activity Barcode] Validate Button State Success',
    ActivityBarcodeValidateButtonStateError = '[Activity Barcode] Validate Button State Error',
    ActivityBarcodeAddStartedLog = '[Activity Barcode] Add Started Log',
}

export class ActivityBarcodeAddStartedLog implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeAddStartedLog;
}

export class ActivityBarcodeUploadLogRequest implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeUploadLogRequest;
}

export class ActivityBarcodeUploadLogSuccess implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ActivityBarcodeUploadLogError implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeUploadLogError;

    constructor(public payload: string) {}
}

export class ActivityBarcodeDeleteRequest implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeDeleteRecordsRequest;
}

export class ActivityBarcodeDeleteSuccess implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeDeleteRecordsSuccess;

    constructor(public payload: ActivityBarcodeDeletePagination) {}
}

export class ActivityBarcodeDeleteError implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ActivityBarcodeSetStatusAndStartDate implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class ActivityBarcodeDeleteFilterPropertyUpdate implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ActivityBarcodeDeleteFilterReset implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeDeleteFilterReset;
}

export class ActivityBarcodePatchDeleteRequest implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodePatchDeleteRecordRequest;

    constructor(public payload: { barcode: string; deleteState: boolean }) {}
}

export class ActivityBarcodePatchAllDeleteRequest implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodePatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ActivityBarcodePatchDeleteSuccess implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodePatchDeleteRecordSuccess;
}

export class ActivityBarcodePatchAllDeleteSuccess implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodePatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ActivityBarcodePatchDeleteError implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodePatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class ActivityBarcodePatchAllDeleteError implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodePatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ActivityBarcodeValidateDeleteRequest implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeValidateDataRequest;
}

export class ActivityBarcodeValidateDeleteSuccess implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeValidateDataSuccess;
}

export class ActivityBarcodeValidateDeleteError implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeValidateDataError;

    constructor(public payload: string) {}
}

export class ActivityBarcodeFileUploaded implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeFileUploaded;
}

export class ActivityBarcodeClearInProgressSpinner implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeClearInProgressSpinner;
}

export class ActivityBarcodeTemplateFileRequest implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ActivityBarcodeTemplateFileSuccess implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ActivityBarcodeTemplateFileError implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeTemplateFileError;

    constructor(public payload: string) {}
}

export class ActivityBarcodeDownloadOutputDataRequest implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ActivityBarcodeDownloadOutputDataSuccess implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ActivityBarcodeDownloadOutputDataError implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ActivityBarcodeValidateButtonStateRequest implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeValidateButtonStateRequest;
}

export class ActivityBarcodeValidateButtonStateSuccess implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class ActivityBarcodeValidateButtonStateError implements Action {
    readonly type = ActivityBarcodeActionTypes.ActivityBarcodeValidateButtonStateError;

    constructor(public payload: string) {}
}

export type ActivityBarcodeActions =
    | ActivityBarcodeUploadLogRequest
    | ActivityBarcodeUploadLogSuccess
    | ActivityBarcodeUploadLogError
    | ActivityBarcodeDeleteRequest
    | ActivityBarcodeDeleteSuccess
    | ActivityBarcodeDeleteError
    | ActivityBarcodeSetStatusAndStartDate
    | ActivityBarcodeDeleteFilterPropertyUpdate
    | ActivityBarcodeDeleteFilterReset
    | ActivityBarcodePatchDeleteRequest
    | ActivityBarcodePatchDeleteSuccess
    | ActivityBarcodePatchDeleteError
    | ActivityBarcodePatchAllDeleteRequest
    | ActivityBarcodePatchAllDeleteSuccess
    | ActivityBarcodePatchAllDeleteError
    | ActivityBarcodeValidateDeleteRequest
    | ActivityBarcodeValidateDeleteSuccess
    | ActivityBarcodeValidateDeleteError
    | ActivityBarcodeFileUploaded
    | ActivityBarcodeClearInProgressSpinner
    | ActivityBarcodeTemplateFileRequest
    | ActivityBarcodeTemplateFileSuccess
    | ActivityBarcodeTemplateFileError
    | ActivityBarcodeDownloadOutputDataRequest
    | ActivityBarcodeDownloadOutputDataSuccess
    | ActivityBarcodeDownloadOutputDataError
    | ActivityBarcodeValidateButtonStateRequest
    | ActivityBarcodeValidateButtonStateSuccess
    | ActivityBarcodeValidateButtonStateError
    | ActivityBarcodeAddStartedLog;
