import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { RundownDataDTO } from 'src/app/store/reports/milestone-dashboard/model';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile } from 'rxjs/operators';
import { MilestoneDashboardRundownRequest } from 'src/app/store/reports/milestone-dashboard/actions';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-rundown-curves',
    templateUrl: './rundown-curves.component.html',
    styleUrls: ['./rundown-curves.component.scss'],
})
export class RundownCurvesComponent extends BaseComponent implements OnInit {
    chartData$ = this.store.select((store) => store.milestoneDashboardState.rundownData);
    isLoading$ = this.store.select((store) => store.milestoneDashboardState.isRundownLoading);
    panelOpenState = false;
    chartData: RundownDataDTO[];
    monthViewEnabled = false;
    monthViewEnabled$ = this.store.select((store) => store.milestoneDashboardState.monthViewEnabled);
    @ViewChild(MatExpansionPanel, { static: true }) expansionPanel: MatExpansionPanel;
    
    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {
        this.chartData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data === null) {
                this.store.dispatch(new MilestoneDashboardRundownRequest({ monthlyView: this.monthViewEnabled }));
            } else {
                this.chartData = data;
            }
        });
        this.monthViewEnabled$.pipe(takeWhile(() => this.isAlive)).subscribe((monthViewEnabled) => {
            this.monthViewEnabled = monthViewEnabled;
        });
    }

    toggleMonthView() {
        this.monthViewEnabled = !this.monthViewEnabled;
        this.store.dispatch(new MilestoneDashboardRundownRequest({ monthlyView: this.monthViewEnabled }));
    }
}
