import { Injectable } from '@angular/core';
import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    ChangeImpactedTagsActionTypes,
    ChangeImpactedTagsFilterSuccess,
    ChangeImpactedTagsFilterError,
    ChangeImpactedTagsNewDataSetRequest,
    ChangeImpactedTagsNewDataSetSuccess,
    ChangeImpactedTagsNewDataSetError,
    ChangeImpactedTagsUploadRequest,
    ChangeImpactedTagsUploadSuccess,
    ChangeImpactedTagsUploadError,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';

@Injectable()
export class ChangeImpactedTagsEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private adminPanelService: AdminPanelService,
        private toastService: ToastService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType(ChangeImpactedTagsActionTypes.ChangeImpactedTagsFilterRequest),
        withLatestFrom(this.store.select((store) => store.changeImpactedTagsState.filter)),
        mergeMap(([, filter]) => {
            return this.adminPanelService.getDCNsWithImpactedTags(filter).pipe(
                map((dataPagination) => {
                    return new ChangeImpactedTagsFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Change documents data. Please contact Program Administrator.'
                    );
                    return of(new ChangeImpactedTagsFilterError(error));
                })
            );
        })
    );

    @Effect()
    impactedTagsRequest$ = this.actions$.pipe(
        ofType<ChangeImpactedTagsNewDataSetRequest>(ChangeImpactedTagsActionTypes.ChangeImpactedTagsUpdateSetRequest),
        mergeMap((action) => {
            return this.adminPanelService.updateImpactedTags(action.payload.id, action.payload.impactedTags).pipe(
                map(() => {
                    return new ChangeImpactedTagsNewDataSetSuccess();
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while save Change documents subsytems. Please contact Program Administrator.'
                    );
                    return of(new ChangeImpactedTagsNewDataSetError(error));
                })
            );
        })
    );

    @Effect()
    uploadFile$ = this.actions$.pipe(
        ofType<ChangeImpactedTagsUploadRequest>(ChangeImpactedTagsActionTypes.ChangeImpactedTagsUploadRequest),
        mergeMap((action) =>
            this.adminPanelService.uploadImpactedTagsFile(action.payload.formData, action.payload.id).pipe(
                map((tags) => new ChangeImpactedTagsUploadSuccess({ tags: tags, id: action.payload.id })),
                catchError((error) => {
                    if (error.status === 400) {
                        this.toastService.Error(error.error);
                    } else {
                        this.toastService.Error(
                            'Error has occurred while uploading file(s) to the server. Please contact Program Administrator'
                        );
                    }

                    return of(new ChangeImpactedTagsUploadError(error));
                })
            )
        )
    );
}
