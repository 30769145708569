import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, withLatestFrom, switchMap, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { Zone } from 'src/app/enums';
import { getDetailedStatusFilterInstance } from 'src/app/extensions';
import { HeaderCheckListFilter, HeaderNumericFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderNumericFilterComponent } from 'src/app/modules/shared/components/filter/header-numeric-filter/header-numeric-filter.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { CheckListColumn, NumericColumn, OrderDirection } from 'src/app/store/common.model';
import { DetailedStatusFilterReset, DetailedStatusResetLockedFilter, DetailedStatusSetMakeRequest } from 'src/app/store/detailed-status/actions';
import { ApplicationState } from 'src/app/store/model';
import { OverdueExceptionsByPriorityChartRequest, overdueExceptionsByPriorityFilterColumnsReset, OverdueExceptionsByPriorityFilterPropertyUpdate, OverdueExceptionsBySubsystemChartRequest, overdueExceptionsBySubsystemTableRequest } from 'src/app/store/reports/health/actions';

@Component({
    selector: 'app-subsystem-exceptions-table',
    templateUrl: './subsystem-exceptions-table.component.html',
    styleUrls: ['./subsystem-exceptions-table.component.scss']
})
export class SubsystemExceptionsTableComponent extends BaseComponent implements OnInit {
    displayedColumns = [
        'subsystem',
        'priorities',
        'mcExceptionsCount',
        'rfsuExceptionsCount',
        'rfoExceptionsCount',
    ];
    sortBy = '';
    sortDirection = OrderDirection.Desc;
    pageSize = 25;
    Zone = Zone;

    columns = {
        columnSubsystem: new CheckListColumn(),
        columnPriorities: new CheckListColumn(),
        columnMCExceptionsCount: new NumericColumn(),
        columnRFSUExceptionsCount: new NumericColumn(),
        columnRFOExceptionsCount: new NumericColumn(),
    };

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    table$ = this.store
        .select((state) => state.healthState.overdueExceptionsByPriority.subsystemTable)
        .pipe(filter((table) => !!table));
    filter$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter);
    selectedPriority$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter.selectedBarSeries);
    selectedLabel$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter.selectedBarLabel);
    isTableLoading$ = this.store.select((state) => state.healthState.overdueExceptionsByPriority.isSubsystemTableLoading);

    constructor(
        private store: Store<ApplicationState>,
        private popupService: PopupService,
        private lookupService: LookupService,
        private router: Router,
        private ngZone: NgZone
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeToFilterColumnUpdate();
    }

    subscribeToFilterColumnUpdate = () => {
        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            for (let property in filter) {
                if (this.columns.hasOwnProperty(property) && this.columns[property] != filter[property]) {
                    this.columns[property] = filter[property];
                }

                if(filter.page !== this.paginator.pageIndex) {
                    this.paginator.pageIndex = filter.page;
                }
            }
        });
    };

    showAllSubsystems = () => {
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'selectedBarLabel',
                value: null,
            })
        );
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'selectedBarSeries',
                value: null,
            })
        );
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'sortBy',
                value: 'number',
            })
        );
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );

        this.store.dispatch(overdueExceptionsByPriorityFilterColumnsReset());
        this.store.dispatch(overdueExceptionsBySubsystemTableRequest());
        this.store.dispatch(new OverdueExceptionsBySubsystemChartRequest());
        this.store.dispatch(new OverdueExceptionsByPriorityChartRequest());
    };

    openDetailedStatusPage(subsystem: string, scope: string, discipline: string, zone: number, category?: string, status?: string[]) {
        this.store.dispatch(new DetailedStatusResetLockedFilter());
        this.store.dispatch(
            new DetailedStatusFilterReset({ filterInstance: getDetailedStatusFilterInstance(scope), scope })
        );
        this.store.dispatch(new DetailedStatusSetMakeRequest());
        const disciplines = discipline ? [discipline] : null;
        this.ngZone.run(() =>
            this.router.navigate([`/detailedstatus`], {
                queryParams: {
                    scope,
                    subsystem,
                    discipline: disciplines,
                    category,
                    zone: zone,
                    status,
                    lockFilters: true,
                    allowCommentsBulkUpdate: true,
                },
            })
        );
    }

    search() {
        this.store.dispatch(
            new OverdueExceptionsByPriorityFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new OverdueExceptionsByPriorityChartRequest());
        this.store.dispatch(new OverdueExceptionsBySubsystemChartRequest());
        this.store.dispatch(overdueExceptionsBySubsystemTableRequest());
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false,
        isSortingOff: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;

        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.sortDirection == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.sortDirection == OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                excludeBlanks: excludeBlanks,
                onlyBlanks: onlyBlanks,
                isSortingOff: isSortingOff,
                showCounts,
                showInputSearch: showInputSearch,
                showBlankOptions: showBlankOptions,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.store.dispatch(
                        new OverdueExceptionsByPriorityFilterPropertyUpdate({
                            key: propertyName,
                            value: value,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new OverdueExceptionsByPriorityFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {},
            })
        );
    }

    openHeaderNumericFilter(
        dtoProperty: string,
        filterProperty: string,
        currentState: NumericColumn,
        placeHolder: string,
        showBlankOptions: boolean = false) {
        this.popupService.openPopup(
            new PopupSettings<HeaderNumericFilter>(HeaderNumericFilterComponent, 400, 350, {
                placeHolder: placeHolder,
                showBlankOptions: showBlankOptions,
                isAscendingSort: this.sortBy === dtoProperty && this.sortDirection == OrderDirection.Asc,
                isDescendingSort: this.sortBy === dtoProperty && this.sortDirection == OrderDirection.Desc,
                minValue: currentState ? currentState.minValue : null,
                maxValue: currentState ? currentState.maxValue : null,
                excludeBlanks: currentState ? currentState.excludeBlanks : false,
                onlyBlanks: currentState ? currentState.onlyBlanks : false,
                onSumbit: (data: HeaderNumericFilter) => {
                    let newFilterState = new NumericColumn();
                    newFilterState = { ...data };
                    this.store.dispatch(
                        new OverdueExceptionsByPriorityFilterPropertyUpdate({
                            key: filterProperty,
                            value: newFilterState,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, dtoProperty);
                    this.search();
                },
                onReset: () => {
                    this.store.dispatch(
                        new OverdueExceptionsByPriorityFilterPropertyUpdate({
                            key: filterProperty,
                            value: null,
                        })
                    );
                },
            } as HeaderNumericFilter)
        );
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.pageSize = pageEvent.pageSize;
            this.store.dispatch(
                new OverdueExceptionsByPriorityFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
        } else {
            this.store.dispatch(
                new OverdueExceptionsByPriorityFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(overdueExceptionsBySubsystemTableRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    getSubsystems = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchSubsystemsWithOverdueExceptionByPriorityFilter, search, take, page);

    getPriorities = (search = '', take = 30, page = 0) =>
        this.withLatestFilter(this.lookupService.searchPrioritiesWithOverdueExceptionByPriorityFilter, search, take, page);

    private withLatestFilter(action: (...actionArgs: any) => Observable<any>, ...args: any) {
        return of(null).pipe(
            withLatestFrom(this.store.select((state) => state.healthState.overdueExceptionsByPriority.filter)),
            switchMap(([, filter]) => action(...args, filter))
        );
    }
    
    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.sortDirection = direction;
            this.store.dispatch(
                new OverdueExceptionsByPriorityFilterPropertyUpdate({
                    key: 'direction',
                    value: direction,
                })
            );
        }

        if (columnName) {
            this.sortBy = columnName;
            this.store.dispatch(
                new OverdueExceptionsByPriorityFilterPropertyUpdate({
                    key: 'sortBy',
                    value: columnName,
                })
            );
        }
    }
}
