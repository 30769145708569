import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'enumKeyValuelist',
})
export class EnumKeyValueListPipe implements PipeTransform {
    transform(value: any): any {
        const items: any[] = [];
        Object.keys(value).forEach((el: any) => {
            const isNumberProperty = parseInt(value[el], 10) >= 0;
            if (!isNumberProperty) {
                items.push({ key: el, value: value[el] });
            }
        });
        return items;
    }
}
