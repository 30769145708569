<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add activity</span></p>
    <div>
        <form [formGroup]="activityForm">
            <div>
                <mat-form-field class="container620">
                    <mat-label>Description</mat-label>
                    <textarea formControlName="activity" matInput name="activity"></textarea>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Activity Type</mat-label>
                    <mat-select formControlName="phase">
                        <mat-option *ngFor="let d of activityTypes" [value]="d.value">
                            {{ d.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="container300">
                    <mat-label>Discipline</mat-label>
                    <mat-select formControlName="activityDiscipline">
                        <mat-option *ngFor="let d of disciplines" [value]="d">
                            {{ d }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Forecast Start</mat-label>
                    <input matInput [matDatepicker]="forecastStart" formControlName="forecastStart" />
                    <mat-datepicker-toggle matSuffix [for]="forecastStart"></mat-datepicker-toggle>
                    <mat-datepicker #forecastStart></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="container300">
                    <mat-label>Forecast Finish</mat-label>
                    <input matInput [matDatepicker]="forecastFinish" formControlName="forecastFinish" />
                    <mat-datepicker-toggle matSuffix [for]="forecastFinish"></mat-datepicker-toggle>
                    <mat-datepicker #forecastFinish></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="actions">
                <button
                    mat-button
                    type="button"
                    class="sct-button sct-button-white"
                    (click)="onCancel()"
                >
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!activityForm.valid"
                    class="sct-button sct-button-light"
                    (click)="onConfirm()"
                >
                    add
                </button>
            </div>
        </form>
    </div>
</div>
