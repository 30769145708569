import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { appConfig } from 'src/app/app.config';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import * as ExcelExportActions from 'src/app/store/excel-export/actions';
import * as ImportActions from 'src/app/store/import-logs/actions';
import { MsalService } from '@azure/msal-angular';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly exportHub: string = '/excelexport';
    private readonly importHub: string = '/importstatus';
    private excelHubConnection: signalR.HubConnection;
    private importHubConnection: signalR.HubConnection;
    tryingToReconnect = false;

    constructor(private store: Store<ApplicationState>, private authService: MsalService) {}

    token = '';
    isImportHubOn = false;
    isExportHubOn = false;

    accessTokenFactory = () => this.authService
        .acquireTokenSilent({ scopes: [`${appConfig.clientId}/User.Read`] })
        .pipe(map((result) => {
            return result.accessToken;
        })).toPromise();

    connectToExcelHub() {
        if (this.isExportHubOn) {
            return;
        }
        this.isExportHubOn = true;
        this.setUpExcelHubConnection();
    }

    connectToImportHub(token?: string) {
        if (this.isImportHubOn) {
            return;
        }
        this.isImportHubOn = true;
        this.setUpImportHubConnection();
    }

    setUpExcelHubConnection() {
        this.excelHubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${this.rootUrl}${this.exportHub}`, {
                accessTokenFactory: this.accessTokenFactory,
                withCredentials: false,
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        this.excelHubConnection
            .start()
            .then(() => {
                this.store.dispatch(ExcelExportActions.connectionSet({ payload: true }));
            })
            .catch((err) => console.error(err.toString()));

        this.excelHubConnection.on('receiveexportevent', (message: any) => {
            let aid = this.authService.instance.getActiveAccount().localAccountId;
            let exportEvent = {
                id: message.Id,
                eventType: message.EventType,
                status: message.Status,
                blobName: message.BlobName,
                fileName: message.FileName,
                createdBy: message.CreatedBy,
                created: message.Created,
            };
            if (aid === message.CreatedBy) {
                this.store.dispatch(ExcelExportActions.exportEventUpdated({ exportEvent }));
                if (exportEvent.blobName && exportEvent.status === 'Success') {
                    this.store.dispatch(ExcelExportActions.downloadExportedFileRequest({ exportEvent }));
                }
            }
        });

        this.excelHubConnection.onreconnecting(() => {
            this.store.dispatch(ExcelExportActions.connectionSet({ payload: false }));
        });

        this.excelHubConnection.onreconnected(() => {
            this.store.dispatch(ExcelExportActions.connectionSet({ payload: true }));
            this.store.dispatch(ExcelExportActions.getRecentExportEventsRequest());
        });
    }

    setUpImportHubConnection() {
        this.importHubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${this.rootUrl}${this.importHub}`, {
                accessTokenFactory: this.accessTokenFactory,
                withCredentials: false,
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        this.importHubConnection
            .start()
            .then(() => {
                this.store.dispatch(ImportActions.connectionSet({ payload: true }));
            })
            .catch((err) => console.error(err.toString()));

        this.importHubConnection.on('receiveimportstatus', (message: any) => {
            if (Array.isArray(message)) {
               let results = [];
               message.forEach((item) => {
                    results.push(
                        {
                            id: item.Id,
                            status: item.Status,
                            type: item.Type,
                            startDate: item.StartDate,
                            endDate: item.endDate
                        }
                    )
                });
                this.store.dispatch(ImportActions.importStatusReceived({ importLogs: results }));
            }
        });

        this.importHubConnection.onreconnecting(() => {
            this.store.dispatch(ImportActions.connectionSet({ payload: false }));
        });

        this.importHubConnection.onreconnected(() => {
            this.store.dispatch(ImportActions.connectionSet({ payload: true }));
        });
    }
}
