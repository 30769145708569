import { ITRState } from './model';
import { ITRActions, ITRActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: ITRState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isDataReadyToProcess: false,
    recordsForDeletion: 0,
    isAutomaticUploadInProgress: true,
};

export function reducer(state = initialState, action: ITRActions) {
    switch (action.type) {
        case ITRActionTypes.ITRUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case ITRActionTypes.ITRUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case ITRActionTypes.ITRUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case ITRActionTypes.ITRSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case ITRActionTypes.ITRFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case ITRActionTypes.ITRClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case ITRActionTypes.ITRTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ITRActionTypes.ITRTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRActionTypes.ITRTemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRActionTypes.ITRValidateDeltaRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ITRActionTypes.ITRValidateDeltaSuccess: {
            return {
                ...state,
                isLoading: false,
                isDataReadyToProcess: false,
                recordsForDeletion: 0,
            };
        }
        case ITRActionTypes.ITRValidateDeltaError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRActionTypes.ITRDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ITRActionTypes.ITRDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRActionTypes.ITRDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ITRActionTypes.ITRAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'ITR';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        case ITRActionTypes.ITRValidateButtonStateSuccess: {
            return {
                ...state,
                isDataReadyToProcess: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
                isAutomaticUploadInProgress: action.payload.isAutomaticUploadInProgress,
            };
        }
        default:
            return state;
    }
}
