import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { MilestoneDashboardType } from 'src/app/store/reports/milestone-dashboard/model';
import { ActivatedRoute } from '@angular/router';
import { MilestoneDashboardSetType } from 'src/app/store/reports/milestone-dashboard/actions';

@Component({
    selector: 'app-rfo-milestone-dashboard',
    template: '',
})
export class RFOMilestoneDashboardComponent implements OnInit {
    dashboardTypes = MilestoneDashboardType;

    constructor(private activatedRoute: ActivatedRoute, private store: Store<ApplicationState>) {}

    ngOnInit() {
        this.store.dispatch(
            new MilestoneDashboardSetType(this.dashboardTypes[this.activatedRoute.routeConfig.path.toUpperCase()])
        );
    }
}
