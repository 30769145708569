import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as StatusUpdateActions from './actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AutomaticUploadsService } from 'src/app/services/api/webapi-services/automatic-uploads.service';

@Injectable()
export class StatusUpdateEffects {
    constructor(
        private actions$: Actions,
        private automaticUploadsService: AutomaticUploadsService,
        private toastService: ToastService
    ) {}

    getLogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StatusUpdateActions.statusUpdateLogRequest),
            mergeMap(() =>
                this.automaticUploadsService.getLogs().pipe(
                    map((data) => StatusUpdateActions.statusUpdateLogSuccess({ response: data })),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting logs for status update Pipeline. Please contact Program Administrator.'
                        );
                        return of(StatusUpdateActions.statusUpdateLogError({ error }));
                    })
                )
            )
        )
    );
}
