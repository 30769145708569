
<main class="main">
    <div class="import-container">
        <h4>ITR Manhours Upload</h4>
        <div class="import-subtitle">
            <button mat-icon-button (click)="showOrder = !showOrder" title="{{
                    showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                }}">
               <mat-icon>info</mat-icon>
            </button>
            See <b (click)="downloadTemplate('ITR Manhours.xlsx')">ITR Manhours.xlsx</b>
            structure. The Live ITR Manhours association is uploaded and previous data in SCMT (matched through QVD Number) synchronised to upload file.
        </div>
        <div *ngIf="!showOrder" class="list-container">
            <div class="list-structure">
                <ol>
                    <li>QVD Number</li>
                    <li>Manhour</li>
                </ol>
            </div>
        </div>
    </div>
    
    <div class="delete-container">
        <div class="section-header">
            <h4>Records marked for deletion</h4>
            <span> - record not present in upload file; validate deletion of existing record in live database</span>
        </div>
        <span *ngIf="!deleteData.data.length">No delete records available.</span>
        <div class="changebuttons-container">
            <button (click)="resetFilters()" class="sct-button sct-button-light" title="reset filters">
                reset filters
            </button>
            <button
                (click)="patchAllDeleteRecords(true)"
                *ngIf="deleteData.data.length"
                class="sct-button sct-button-red"
                title="delete all filtered records"
            >
                delete all filtered records
            </button>
            <button
                (click)="patchAllDeleteRecords(false)"
                *ngIf="deleteData.data.length"
                title="reject"
                class="sct-button sct-button-green"
                title="keep all filtered records"
            >
                keep all filtered records
            </button>
        </div>
        
        <table mat-table [dataSource]="deleteData">
            <ng-container matColumnDef="qvdNumber">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="
                        openHeaderCheckListFilter(
                            'qvdNumber',
                            getQVDNumbers,
                            'columnQVDNumbers',
                            'QVD Number',
                            columnQVDNumbers,
                            false
                        )
                    "
                >
                    <div class="sort-container">
                        <span
                            [ngClass]="{
                                'header-underline': 
                                columnQVDNumbers && 
                                columnQVDNumbers.items.length > 0
                            }"
                        >
                            QVD Number</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'qvdNumber'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.qvdNumber }}
                </td>
            </ng-container>
            <ng-container matColumnDef="manhour">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                >
                    <div class="sort-container">
                        <span
                        >
                            Manhour</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'manhour'">
                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.manhour }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150"></th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleteState" (click)="restoreItem(element)">
                        <button mat-icon-button title="restore">
                            <mat-icon>restore_from_trash</mat-icon>
                        </button>
                        <span>Restore</span>
                    </div>
                    <div *ngIf="!element.deleteState" (click)="deleteItem(element)">
                        <button mat-icon-button title="reject">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <span>Delete</span>
                    </div>
                </td>
            </ng-container>           
            <tr mat-header-row *matHeaderRowDef="displayedDeleteDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDeleteDataColumns"></tr>
        </table>
        <mat-paginator
            *ngIf="deleteData.data.length"
            [length]="deleteDataResultsLength"
            [pageSize]="deleteDataPageSize"
            [pageSizeOptions]="[10, 15, 20]"
            (page)="onDeleteDataPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        
        <div *ngIf="isDeleteDataLoading$ | async" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    
    
    <div class="upload-log-container">
        <h4>Import history</h4>
        <table mat-table [dataSource]="uploadLogData">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="getImportClassName(element.status)">
                        {{ element.status }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Start date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.startDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.endDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="infoMessage">
                <th mat-header-cell *matHeaderCellDef>Info message</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.infoMessage ? element.infoMessage : ''"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="errorMessage">
                <th mat-header-cell *matHeaderCellDef>Error message</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.errorMessage ? element.errorMessage : 'No errors' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.user }}
                </td>
            </ng-container>
            <ng-container matColumnDef="downloadResult">
                <th mat-header-cell *matHeaderCellDef>Download result</th>
                <td mat-cell *matCellDef="let element">
                    <svg
                        class="downloadIcon"
                        *ngIf="element.outputDataLink"
                        (click)="downloadData(element.outputDataLink)"
                        width="18px"
                        height="20px"
                        viewBox="0 0 16 17"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        
                        <title>{{ element.outputDataLink }}</title>
                        <desc>Created with sketchtool.</desc>
                        <g
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <g
                                id="New-FCNi-Copy"
                                transform="translate(-1259.000000, -1440.000000)"
                                stroke="#0066B2"
                                stroke-width="2"
                            >
                                <g id="Buttons/Primary-Copy" transform="translate(1256.000000, 1438.000000)">
                                    <path d="M4,18 L18,18" id="Line-3"></path>
                                    <path
                                        d="M5.5,8.5 L16.5,8.5"
                                        id="Line-3-Copy"
                                        transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                    ></path>
                                    <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span *ngIf="isImportInProgress(element.status)" class="in-progress"></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedUploadLogDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedUploadLogDataColumns"></tr>
        </table>
        <div *ngIf="isUploadLogLoading$ | async" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    
    <app-loading-indicator *ngIf="isLoading$ | async"> </app-loading-indicator>
</main>