import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    IWPListingActionTypes,
    IWPListingUploadLogSuccess,
    IWPListingUploadLogError,
    IWPListingSetStatusAndStartDate,
    IWPListingClearInProgressSpinner,
    IWPListingTemplateFileRequest,
    IWPListingTemplateFileSuccess,
    IWPListingTemplateFileError,
    IWPListingValidateButtonStateSuccess,
    IWPListingValidateButtonStateError,
    IWPListingDownloadOutputDataRequest,
    IWPListingDownloadOutputDataSuccess,
    IWPListingDownloadOutputDataError,
    IWPListingValidateDeltaSuccess,
    IWPListingValidateDeltaError,
    IWPListingValidateButtonStateRequest,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class IWPListingEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(IWPListingActionTypes.IWPListingUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['IWP']).pipe(
                map((uploadLogData) => new IWPListingUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new IWPListingUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<IWPListingUploadLogSuccess>(IWPListingActionTypes.IWPListingUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.iwpListingState.uploadLogStatus),
            this.store.select((store) => store.iwpListingState.uploadLogStartDate)
        ),
        filter(([{ payload }, status, startDate]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return logs[0] && (status !== logs[0].status || startDate !== logs[0].startDate);
        }),
        map(([{ payload }]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return new IWPListingSetStatusAndStartDate({ status: logs[0].status, startDate: logs[0].startDate });
        })
    );

    @Effect()
    invokeDeltaRequest$ = this.actions$.pipe(
        ofType(IWPListingActionTypes.IWPListingSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.iwpListingState.uploadLogData[0])),
        filter(
            ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
        ),
        switchMap(() => [new IWPListingValidateButtonStateRequest(), new IWPListingClearInProgressSpinner()])
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<IWPListingTemplateFileRequest>(IWPListingActionTypes.IWPListingTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new IWPListingTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading IWP Listing structure file. Please contact Program Administrator'
                    );
                    return of(new IWPListingTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<IWPListingTemplateFileSuccess>(IWPListingActionTypes.IWPListingTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'IWPListing.csv');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<IWPListingDownloadOutputDataRequest>(IWPListingActionTypes.IWPListingDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new IWPListingDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new IWPListingDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<IWPListingDownloadOutputDataSuccess>(IWPListingActionTypes.IWPListingDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(IWPListingActionTypes.IWPListingValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateIWPDelta().pipe(
                map(() => new IWPListingValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new IWPListingValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    setValidateButtonState$ = this.actions$.pipe(
        ofType(IWPListingActionTypes.IWPListingValidateButtonStateRequest),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('IWP').pipe(
                map((response: any) => new IWPListingValidateButtonStateSuccess(response)),
                catchError((error) => of(new IWPListingValidateButtonStateError(error)))
            )
        )
    );
}
