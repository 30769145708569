import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { delay, takeWhile } from 'rxjs/operators';
import {
    ScheduleActivityExpansionInWeekPanelToggled,
    ScheduleActivityExpansionNotInWeekPanelToggled,
    ScheduleActivityExportToExcelRequest,
    ScheduleActivityITRsSubmit,
    ScheduleActivityMultiPanels,
    ScheduleActivityNotInWeekPlanningDataRequest,
    ScheduleActivityNotInWeekPlanningPaginationUpdate,
    ScheduleActivityNotInWeekPlanningShowHideColumnsUpdate,
    ScheduleActivityPlanningDataRequest,
    ScheduleActivityPlanningFilterPropertyUpdate,
    ScheduleActivityResetPrint,
    ScheduleActivityUpdateScrollTop,
} from '../../../../store/weekly-planning/schedule-activity-planning/actions';
import {
    ScheduleActivityPlanningFilter,
    ShowHideColumns,
    SubsystemWithScheduleActivitiesDTO,
} from '../../../../store/weekly-planning/schedule-activity-planning/model';
import { ApplicationState } from '../../../../store/model';
import { Store } from '@ngrx/store';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { WeeklyPlanActivityType, WeeklyPlanType } from 'src/app/enums';
import { MatExpansionPanel } from '@angular/material/expansion';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import * as moment from 'moment';
import { PlanningCommitmentReliabilityFilterPropertyUpdate } from 'src/app/store/reports/planning/actions';
import { ScheduleActivityITRExportToExcelRequest } from 'src/app/store/schedule-activity-itr/actions';
import { ScheduleActivityITRScope } from 'src/app/store/schedule-activity-itr/model';
import { Location } from '@angular/common';

@Component({
    selector: 'app-schedule-activity-planning',
    templateUrl: './schedule-activity-planning.component.html',
    styleUrls: ['./schedule-activity-planning.component.scss'],
})
export class ScheduleActivityPlanningComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('inWeekPaginator') inWeekPaginator: MatPaginator;
    @ViewChild('notInWeekPaginator') notInWeekPaginator: MatPaginator;
    @ViewChild('inWeekExpansionPanel') inWeekExpansionPanel: MatExpansionPanel;
    @ViewChild('notInWeekExpansionPanel') notInWeekExpansionPanel: MatExpansionPanel;
    scheduleActivityPlanningState$ = this.store.select((store) => store.scheduleActivityPlanningState);
    scrollTop$ = this.store.select((store) => store.scheduleActivityPlanningState.scrollTop);

    setupScroll = false;
    subsystemWithScheduleActivitiesDataLoading = false;
    subsystemsWithScheduleActivities: SubsystemWithScheduleActivitiesDTO[] = [];
    subsystemsWithScheduleActivitiesTotalLength = 0;
    subsystemsNotInWeekWithScheduleActivitiesDataLoading = false;
    subsystemsNotInWeekWithScheduleActivities: SubsystemWithScheduleActivitiesDTO[];
    subsystemsNotInWeekWithScheduleActivitiesTotalLength = 0;
    submitButtonDisabled = true;
    get isWeekly() {
        return this.weeklyPlanType === WeeklyPlanType.weekly;
    }
    weeklyPlanType: WeeklyPlanType;
    weeklyPlanTypes = WeeklyPlanType;
    inWeekPageSize = 0;
    notInWeekPageSize = 0;
    activityTypes = WeeklyPlanActivityType;
    notInWeekExpanded: boolean = null;
    inWeekExpanded: boolean = null;
    inWeekShowHideColumns: ShowHideColumns = new ShowHideColumns();
    notInWeekShowHideColumns = new ShowHideColumns();
    panelExpansionSet = false;
    currentWeek: moment.Moment = getCurrentWeekStartDate();
    selectedWorkAreas: any[];
    selectedDisciplines: any[];
    get isNextWorkingWeek(): boolean {
        //return moment(this.currentWeek).isAfter(getCurrentWeekStartDate());
        return false
    }
    showPortal = false;
    multiPanels: boolean = false;
    lockFilters = false;

    constructor(
        private store: Store<ApplicationState>,
        private router: Router,
        private location: Location,
        private roleService: RoleService,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            const period = paramMap.get('period');
            switch (period) {
                case 'weekly': {
                    this.weeklyPlanType = WeeklyPlanType.weekly;
                    break;
                }
                case 'monthly': {
                    this.weeklyPlanType = WeeklyPlanType.monthly;
                    break;
                }
                default: {
                    break;
                }
            }
        });

        this.activatedRoute.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            const activityId = paramMap.get('activityid');
            const discipline = paramMap.get('discipline');
            const lockFilters = paramMap.get('lockfilters');
            const withScroll = paramMap.get('withScroll');
            this.lockFilters = lockFilters === 'true';

            this.setupScroll = withScroll?.includes('true');

            this.store.dispatch(
                new ScheduleActivityPlanningFilterPropertyUpdate({
                    filter: {
                        activityId: activityId,
                        activityDiscipline: discipline,
                        lockFilters: this.lockFilters,
                    } as ScheduleActivityPlanningFilter,
                })
            );

            if (this.lockFilters) {
                this.store.dispatch(new ScheduleActivityPlanningDataRequest());
            }
        });

        this.scheduleActivityPlanningState$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ({
                    subsystemsWithScheduleActivitiesIsLoading,
                    scheduleActivityPlanningFilter,
                    subsystemsWithScheduleActivitiesTotalCount,
                    subsystemsWithScheduleActivitiesIsAllSubmitted,
                    subsystemsWithScheduleActivities,
                    subsystemsNotInWeekWithScheduleActivitiesIsLoading,
                    subsystemsNotInWeekWithScheduleActivitiesTotalCount,
                    subsystemsNotInWeekWithScheduleActivitiesIsAllSubmitted,
                    subsystemsNotInWeekWithScheduleActivities,
                    subsystemsNotInWeekWithScheduleActivitiesPagination,
                    subsystemsNotInWeekWithScheduleActivitiesShowHideColumns,
                    subsystemsNotInWeekWithScheduleActivitiesPanelExpanded,
                    subsystemsInWeekWithScheduleActivitiesPanelExpanded,
                    multiPanels
                }) => {
                    setTimeout(() => {
                        this.inWeekPageSize = scheduleActivityPlanningFilter.take;
                        if (this.inWeekPaginator) {
                            this.inWeekPaginator.pageIndex = scheduleActivityPlanningFilter.page;
                        }
                        this.notInWeekPageSize = subsystemsNotInWeekWithScheduleActivitiesPagination.take;
                        if (this.notInWeekPaginator) {
                            this.notInWeekPaginator.pageIndex =
                                subsystemsNotInWeekWithScheduleActivitiesPagination.page;
                        }
                        this.notInWeekExpanded = subsystemsNotInWeekWithScheduleActivitiesPanelExpanded;
                        this.inWeekExpanded = subsystemsInWeekWithScheduleActivitiesPanelExpanded;

                        if (
                            subsystemsNotInWeekWithScheduleActivitiesPanelExpanded !== null &&
                            !this.panelExpansionSet
                        ) {
                            this.panelExpansionSet = true;
                            if (subsystemsNotInWeekWithScheduleActivitiesPanelExpanded) {
                                this.notInWeekExpansionPanel.open();
                            }
                            if (
                                (this.isNextWorkingWeek || this.weeklyPlanType == WeeklyPlanType.monthly) &&
                                this.inWeekExpanded &&
                                multiPanels
                            ) {
                                this.inWeekExpansionPanel.open();
                            }
                        }
                    });

                    this.subsystemWithScheduleActivitiesDataLoading = subsystemsWithScheduleActivitiesIsLoading;
                    this.subsystemsWithScheduleActivities = subsystemsWithScheduleActivities;
                    this.subsystemsWithScheduleActivitiesTotalLength = subsystemsWithScheduleActivitiesTotalCount;
                    this.inWeekShowHideColumns = scheduleActivityPlanningFilter.showHideColumns;
                    this.subsystemsNotInWeekWithScheduleActivitiesDataLoading = subsystemsNotInWeekWithScheduleActivitiesIsLoading;
                    this.subsystemsNotInWeekWithScheduleActivities = subsystemsNotInWeekWithScheduleActivities;
                    this.subsystemsNotInWeekWithScheduleActivitiesTotalLength = subsystemsNotInWeekWithScheduleActivitiesTotalCount;
                    this.notInWeekShowHideColumns = subsystemsNotInWeekWithScheduleActivitiesShowHideColumns;
                    this.currentWeek = scheduleActivityPlanningFilter.weekStart;
                    this.selectedWorkAreas = scheduleActivityPlanningFilter.workAreas;
                    this.selectedDisciplines = scheduleActivityPlanningFilter.disciplines;
                    this.multiPanels = multiPanels;

                    this.submitButtonDisabled =
                        !this.roleService.isInAnyRole([
                            Constants.applicableGroups.Planners,
                            Constants.applicableGroups.Admin,
                        ]) ||
                        (subsystemsWithScheduleActivitiesIsAllSubmitted &&
                            subsystemsNotInWeekWithScheduleActivitiesIsAllSubmitted);
                }
            );
        
        // delay 2s, because some render time required 
        // to open and populate extension panels
        this.scrollTop$
            .pipe(delay(2000))
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((val) => {
                if(this.setupScroll && this.isAlive) {
                    document.documentElement.scrollBy({top: val});
                }
            })
    }

    ngOnDestroy(): void {
        this.store.dispatch(new ScheduleActivityUpdateScrollTop(document.documentElement.scrollTop));        
    }

    onInWeekPaginatorChange({ pageSize, pageIndex }: PageEvent) {
        if (this.inWeekPageSize !== pageSize) {
            this.store.dispatch(
                new ScheduleActivityPlanningFilterPropertyUpdate({
                    filter: { take: pageSize } as ScheduleActivityPlanningFilter,
                })
            );
            this.inWeekPageSize = pageSize;
        } else {
            this.store.dispatch(
                new ScheduleActivityPlanningFilterPropertyUpdate({
                    filter: { page: pageIndex } as ScheduleActivityPlanningFilter,
                })
            );
        }
        this.store.dispatch(new ScheduleActivityPlanningDataRequest());
    }

    onNotInWeekPaginatorChange({ pageSize, pageIndex }: PageEvent) {
        this.store.dispatch(
            new ScheduleActivityNotInWeekPlanningPaginationUpdate({
                page: pageIndex,
                take: pageSize,
            })
        );
        this.notInWeekPageSize = pageSize;
        this.store.dispatch(new ScheduleActivityNotInWeekPlanningDataRequest());
    }

    onInWeekPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.inWeekPaginator.getNumberOfPages()) {
            newPage = this.inWeekPaginator.getNumberOfPages();
        }
        const pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.inWeekPageSize;
        this.inWeekPaginator.pageIndex = pageEvt.pageIndex;
        this.onInWeekPaginatorChange(pageEvt);
    }

    onNotInWeekPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.notInWeekPaginator.getNumberOfPages()) {
            newPage = this.notInWeekPaginator.getNumberOfPages();
        }
        const pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.notInWeekPageSize;
        this.notInWeekPaginator.pageIndex = pageEvt.pageIndex;
        this.onNotInWeekPaginatorChange(pageEvt);
    }

    onNotInWeekShowHideColumnsChange(showHideColumns: ShowHideColumns) {
        this.store.dispatch(
            new ScheduleActivityNotInWeekPlanningShowHideColumnsUpdate({
                showHideColumns,
            })
        );
    }

    onInWeekShowHideColumnsChange(showHideColumns: ShowHideColumns) {
        this.store.dispatch(
            new ScheduleActivityPlanningFilterPropertyUpdate({
                filter: {
                    showHideColumns,
                } as ScheduleActivityPlanningFilter,
            })
        );
    }

    submit() {
        this.store.dispatch(new ScheduleActivityITRsSubmit());
    }

    goToITRReport() {
        const workAreas: any[] = [];
        this.selectedWorkAreas?.map((area) => {
            workAreas.push(area);
        });

        const disciplines: any[] = [];
        this.selectedDisciplines?.map((discipline) => {
            disciplines.push(discipline);
        });

        this.store.dispatch(
            new PlanningCommitmentReliabilityFilterPropertyUpdate({ key: 'workAreas', value: workAreas })
        );
        this.store.dispatch(
            new PlanningCommitmentReliabilityFilterPropertyUpdate({ key: 'disciplines', value: disciplines })
        );

        this.router.navigate(['/reports/planning/commitmentreliability/itr']);
    }

    getTitle() {
        return this.weeklyPlanType === WeeklyPlanType.weekly
            ? 'Available ITRs'
            : this.weeklyPlanType === WeeklyPlanType.monthly
            ? '4-week lookahead'
            : 'Available ITRs';
    }

    goToNotInWeek() {
        this.store.dispatch(new ScheduleActivityExpansionNotInWeekPanelToggled(true));
        this.inWeekExpansionPanel.close();
        this.notInWeekExpansionPanel.open();
    }

    trackExpansionPanel(event) {
        if (event == 'openedNotInWeek') {
            this.store.dispatch(new ScheduleActivityExpansionNotInWeekPanelToggled(true));
        } else if (event == 'closedNotInWeek') {
            this.store.dispatch(new ScheduleActivityExpansionNotInWeekPanelToggled(false));
        } else if (event == 'openedInWeek') {
            this.store.dispatch(new ScheduleActivityExpansionInWeekPanelToggled(true));
        } else {
            this.store.dispatch(new ScheduleActivityExpansionInWeekPanelToggled(false));
        }
    }

    openExpansionPanel(open: boolean) {
        if (open) {
            this.store.dispatch(new ScheduleActivityExpansionNotInWeekPanelToggled(false));
            this.inWeekExpansionPanel.open();
        } else {
            this.store.dispatch(new ScheduleActivityExpansionNotInWeekPanelToggled(true));
            this.notInWeekExpansionPanel.open();
        }
    }

    exportToExcel() {
        if (this.weeklyPlanType === this.weeklyPlanTypes.monthly || this.isNextWorkingWeek) {
            this.store.dispatch(new ScheduleActivityExportToExcelRequest(this.weeklyPlanType));
        } else {
            this.store.dispatch(
                new ScheduleActivityITRExportToExcelRequest({
                    scope: ScheduleActivityITRScope.InWeek,
                    activityId: null,
                    activityDiscipline: null,
                    sortBy: 'qvdNumber',
                })
            );
        }
    }

    onPrintWindowClose() {
        this.showPortal = false;
        this.store.dispatch(new ScheduleActivityResetPrint());
    }

    setMultiPanels() {
        this.multiPanels = !this.multiPanels;
        this.store.dispatch(new ScheduleActivityMultiPanels(this.multiPanels));
    }

    goBack() {
        this.location.back();
    }
}
