

import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { RFODatesUploadDeleteFilter, RFODatesUploadDeletePagination, RFODatesUploadState } from './model';
import { RFODatesUploadActions, RFODatesUploadActionTypes } from './actions';

const initialState: RFODatesUploadState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new RFODatesUploadDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new RFODatesUploadDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: RFODatesUploadActions): RFODatesUploadState {
    switch (action.type) {
        case RFODatesUploadActionTypes.RFODatesUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadPatchAllDeleteRecordsRequest:
        case RFODatesUploadActionTypes.RFODatesUploadDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadDeleteRecordsSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadValidateDataError:
        case RFODatesUploadActionTypes.RFODatesUploadPatchAllDeleteRecordsError:
        case RFODatesUploadActionTypes.RFODatesUploadPatchDeleteRecordError:
        case RFODatesUploadActionTypes.RFODatesUploadPatchDeleteRecordSuccess:
        case RFODatesUploadActionTypes.RFODatesUploadDeleteRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadDeleteFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        [action.payload.key]: Array.isArray(state.deleteFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case RFODatesUploadActionTypes.RFODatesUploadDeleteFilterReset: {
            const newFilter = new RFODatesUploadDeleteFilter();
            return {
                ...state,
                deleteFilter: { ...newFilter },
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadPatchDeleteRecordRequest: {
            let itemIndex = state.deleteData.items.findIndex((d) => d.itrpliNumber === action.payload.itrpliNumber);
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                deleteState: action.payload.deleteState,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadPatchAllDeleteRecordsSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item) => ({ ...item, deleteState: action.payload.deleteState })),
                },
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadValidateDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadValidateDataSuccess: {
            return {
                ...state,
                deleteData: new RFODatesUploadDeletePagination(),
                isDeleteDataLoading: false,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new RFODatesUploadDeletePagination(),
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadDownloadOutputDataRequest:
        case RFODatesUploadActionTypes.RFODatesUploadTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadTemplateFileError:
        case RFODatesUploadActionTypes.RFODatesUploadDownloadOutputDataSuccess:
        case RFODatesUploadActionTypes.RFODatesUploadDownloadOutputDataError:
        case RFODatesUploadActionTypes.RFODatesUploadTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case RFODatesUploadActionTypes.RFODatesUploadAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'RFODatesUpload';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

