import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { ApplicationState } from 'src/app/store/model';
import { WeekType } from 'src/app/enums';
import { getCurrentWeekStartDate } from 'src/app/extensions';
import * as moment from 'moment';
import { SkylineRequest, SkylineSetPhaseAndType } from 'src/app/store/reports/skyline/actions';

@Component({
    selector: 'app-simple',
    templateUrl: './skyline-simple.component.html',
    styleUrls: ['./skyline-simple.component.scss'],
})
export class SkylineSimpleComponent extends BaseComponent implements OnInit {
    currentWeekStartDate: moment.Moment;
    skylinePhase = '';
    skylineType = '';

    constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
        super();
        this.currentWeekStartDate = getCurrentWeekStartDate();
    }

    weeks$ = this.store.select((store) => store.skylineState.weeks);
    subsystemsByWeek$ = this.store.select((store) => store.skylineState.subsystemsByWeek);
    rfosByWeek$ = this.store.select((store) => store.skylineState.rfosByWeek);
    isLoading$ = this.store.select((store) => store.skylineState.isLoading);
    pastWeeksCount$ = this.store.select(
        (store) => store.skylineState.weeks.filter((w) => w.weekType === WeekType.Past).length
    );
    openAqvdsPerWeek$ = this.store.select((state) => state.skylineState.openAqvdsPerWeek);
    openBitrsPerWeek$ = this.store.select((state) => state.skylineState.openBitrsPerWeek);
    openCitrsPerWeek$ = this.store.select((state) => state.skylineState.openCitrsPerWeek);
    levelOfDetails$ = this.store.select((state) => state.skylineState.levelOfDetails);

    ngOnInit(): void {
        [this.skylinePhase, this.skylineType] = this.activatedRoute.routeConfig.path.split('/');

        if (this.skylinePhase === 'skyline' && this.skylineType === undefined) {
            [this.skylinePhase, this.skylineType] = this.activatedRoute.firstChild.snapshot.routeConfig.path.split('/');
        }

        this.store.dispatch(new SkylineSetPhaseAndType({ phase: this.skylinePhase, type: this.skylineType }));
        this.store.dispatch(new SkylineRequest({ printMode: false }));
    }
}
