<main class="main">
    <form [formGroup]="filterForm" class="main-content">
        <div class="header-container">
            <div class="header-title">
                RedZone: subsystem MC status
            </div>
            <div class="header-buttons">
                <button
                    type="button"
                    (click)="exportToExcel()"
                    class="sct-button sct-button-light"
                    [style.width]="'100px'"
                >
                    export
                </button>
            </div>
        </div>
        <mat-accordion>
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" class="filter-panel-container">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div class="filter-panel-header-container">
                        <div class="filter-panel-header-content">
                            <div class="filter-by-button">
                                <img src="assets/images/icons/filter-blue.svg" />
                                filter by
                            </div>
                            <div *ngIf="!filterExpansionPanel.expanded">
                                <mat-chip-list aria-label="Active filters">
                                    <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                        Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                        Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('contractors')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                        Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip>
                                        MC Completed:
                                        {{ displaySelectedRadioYesNo(filterForm.controls.mcCompleted.value) }}
                                    </mat-basic-chip>
                                    <mat-basic-chip>
                                        MC Committed:
                                        {{ displaySelectedRadioYesNo(filterForm.controls.mcCommitted.value) }}
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.mcMilestones.value.length" removable>
                                        MC Milestone:
                                        {{ displayMultipleSelected(filterForm.controls.mcMilestones.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('mcMilestones')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.goocs.value.length" removable>
                                        GOOC: {{ displayGoocsSelected(filterForm.controls.goocs.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('goocs')">cancel</mat-icon>
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.systems.value.length" removable>
                                        System: {{ displaySystemsSelected(filterForm.controls.systems.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('systems')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.constructionArea.value.length" removable>
                                        Construction Area:
                                        {{ displayMultipleFilterSelected(filterForm.controls.constructionArea.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('contructionArea')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.mcEngineer.value.length" removable>
                                        MC Engineer:
                                        {{ displaySelectedMCEngineer(filterForm.controls.mcEngineer.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('mcEngineer')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                        System Owner:
                                        {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                        <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')"
                                            >cancel</mat-icon
                                        >
                                    </mat-basic-chip>
                                    <mat-basic-chip>
                                        Required for Steamblow:
                                        {{ displaySelectedRadioYesNo(filterForm.controls.requiredForSteamblow.value) }}
                                    </mat-basic-chip>
                                </mat-chip-list>
                            </div>
                        </div>

                        <div class="filter-panel-header-buttons">
                            <button mat-button (click)="resetFilters($event)">Reset filters &times;</button>
                            <button mat-button class="light-blue" (click)="toggleExpansionPanel($event)">
                                {{ filterExpansionPanel.expanded ? 'Hide panel' : 'Show panel' }}
                            </button>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <div class="filter-panel-content-container">
                    <div class="filters-row col4">
                        <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setGoocInput"
                                formControlName="goocs"
                                [searchFunc]="getGoocs"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="goocAutocompleteDisplayedColumns"
                                [filterForm]="filterForm"
                                [formSetter]="'goocs'"
                                [propertyToShow]="'no'"
                                [placeholder]="'GOOC'"
                                [panelWidth]="480"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>MC Completed</mat-label>
                                <mat-select
                                    placeholder="All"
                                    [formGroup]="filterForm"
                                    formControlName="mcCompleted"
                                    disableOptionCentering
                                >
                                    <mat-option [value]="null">All</mat-option>
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>MC Committed</mat-label>
                                <mat-select
                                    placeholder="All"
                                    [formGroup]="filterForm"
                                    formControlName="mcCommitted"
                                    disableOptionCentering
                                >
                                    <mat-option [value]="null">All</mat-option>
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setMcMilestoneInput"
                                formControlName="mcMilestones"
                                [searchFunc]="getMcMilestones"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="mcMilestoneAutocompleteDisplayedColumns"
                                [filterForm]="filterForm"
                                [formSetter]="'mcMilestones'"
                                [propertyToShow]="'name'"
                                [placeholder]="'MC Milestone'"
                            >
                            </app-multiple-items-selector>
                        </div>
                    </div>
                    <div class="filters-row col4">
                        <div class="filter-item">
                            <app-multiple-items-selector
                                [setInput]="setContractorInput"
                                formControlName="contractors"
                                [searchFunc]="getContractors"
                                [isAsync]="true"
                                [isTableAutoComplete]="true"
                                [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                                (autocompleteClosed)="onContractorsClosed()"
                                (itemRemoved)="onContractorsClosed()"
                                [filterForm]="filterForm"
                                [formSetter]="'contractors'"
                                [propertyToShow]="'contractNo'"
                                [placeholder]="'MSL Contractor'"
                                [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                                [panelWidth]="480"
                                [secondPropertyToShow]="'contract'"
                            >
                            </app-multiple-items-selector>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>MC Engineer</mat-label>
                                <mat-select formControlName="mcEngineer" multiple disableOptionCentering>
                                    <mat-option *ngFor="let mce of mcEngineers" [value]="mce.id"
                                        >{{ mce.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>System Owner</mat-label>
                                <mat-select formControlName="systemOwner" multiple disableOptionCentering>
                                    <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-item">
                            <mat-form-field>
                                <mat-label>Project Team</mat-label>
                                <mat-select
                                    formControlName="projectTeamNames"
                                    multiple
                                    (openedChange)="onProjectTeamsClosed($event)"
                                    disableOptionCentering
                                >
                                    <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filters-row flex-row-space-between">
                        <div class="filters-row col4">
                            <div class="filter-item">
                                <app-multiple-items-selector
                                    [setInput]="setSystemInput"
                                    formControlName="systems"
                                    [searchFunc]="getSystems"
                                    [isAsync]="true"
                                    [isTableAutoComplete]="true"
                                    [displayedColumns]="systemAutocompleteDisplayedColumns"
                                    [filterForm]="filterForm"
                                    [propertyToShow]="'no'"
                                    [formSetter]="'systems'"
                                    [placeholder]="'System'"
                                    [panelWidth]="480"
                                >
                                </app-multiple-items-selector>
                            </div>
                            <div class="filter-item">
                                <app-multiple-items-selector
                                    [setInput]="setConstructionAreaInput"
                                    formControlName="constructionArea"
                                    [searchFunc]="getConstructionArea"
                                    [isAsync]="true"
                                    [filterForm]="filterForm"
                                    [formSetter]="'constructionArea'"
                                    [placeholder]="'Construction Area'"
                                >
                                </app-multiple-items-selector>
                            </div>
                            <div class="filter-item">
                                <app-multiple-items-selector
                                    [setInput]="setStagedStartUpPrioritiesInput"
                                    formControlName="stagedStartUpPriorities"
                                    [searchFunc]="getStagedStartUpPriorities"
                                    [isAsync]="true"
                                    [isTableAutoComplete]="true"
                                    [displayedColumns]="['priority', 'priorityName']"
                                    (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                                    (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                                    [filterForm]="filterForm"
                                    formSetter="stagedStartUpPriorities"
                                    [propertyToShow]="'priority'"
                                    [secondPropertyToShow]="'priorityName'"
                                    placeholder="Staged Start-up Priority"
                                    [panelWidth]="480"
                                >
                                </app-multiple-items-selector>
                            </div>
                            <div class="filter-item">
                                <mat-form-field>
                                    <mat-label>Required for Steamblow</mat-label>
                                    <mat-select
                                        placeholder="All"
                                        [formGroup]="filterForm"
                                        formControlName="requiredForSteamblow"
                                        disableOptionCentering
                                    >
                                        <mat-option [value]="null">All</mat-option>
                                        <mat-option [value]="true">Yes</mat-option>
                                        <mat-option [value]="false">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="search-btn-container">
                        <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
                    </div>
                </div>

                <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="results-panel results-container">
            <div class="results-panel-container">
                <div class="results-panel-header-container">
                    <div class="results-panel-header-title-row">
                        <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
                        <div class="results-header-actions">
                            <button
                                mat-button
                                [matMenuTriggerFor]="columnsCustomizer"
                                class="results-columns-customizer"
                            >
                                <mat-icon svgIcon="edit"></mat-icon>
                                customize columns
                            </button>
                            <mat-menu #columnsCustomizer class="columns-customizer">
                                <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                                <div class="columns-customizer-list">
                                    <mat-checkbox
                                        [checked]="showHideColumns.showSubsystemName"
                                        (change)="reverseValue('showSubsystemName')"
                                        (click)="$event.stopPropagation()"
                                        >Sub. Name
                                    </mat-checkbox>
                                    <mat-checkbox
                                        [checked]="showHideColumns.showMCPlanDate"
                                        (change)="reverseValue('showMCPlanDate')"
                                        (click)="$event.stopPropagation()"
                                        >MC Plan</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showMCForecastDate"
                                        (change)="reverseValue('showMCForecastDate')"
                                        (click)="$event.stopPropagation()"
                                        >MC Forecast</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showMCActualDate"
                                        (change)="reverseValue('showMCActualDate')"
                                        (click)="$event.stopPropagation()"
                                        >MC Actual</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showConstructionWalkdown"
                                        (change)="reverseValue('showConstructionWalkdown')"
                                        (click)="$event.stopPropagation()"
                                        >Construction 90% Walkdown</mat-checkbox
                                    >
                                    <mat-checkbox
                                        *ngIf="disciplineBreakdownEnabled"
                                        [checked]="showHideColumns.showDacStatus"
                                        (change)="reverseValue('showDacStatus')"
                                        (click)="$event.stopPropagation()"
                                        >DAC</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showWalkdownForecast"
                                        (change)="reverseValue('showWalkdownForecast')"
                                        (click)="$event.stopPropagation()"
                                        >Walkdown Forecast</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showWalkdownScheduled"
                                        (change)="reverseValue('showWalkdownScheduled')"
                                        (click)="$event.stopPropagation()"
                                        >Walkdown Scheduled</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showWalkdownActual"
                                        (change)="reverseValue('showWalkdownActual')"
                                        (click)="$event.stopPropagation()"
                                        >Walkdown Actual</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showSafetyRewalkdown"
                                        (change)="reverseValue('showSafetyRewalkdown')"
                                        (click)="$event.stopPropagation()"
                                        >MC Safety Re-walkdown</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showFPLUpload"
                                        (change)="reverseValue('showFPLUpload')"
                                        (click)="$event.stopPropagation()"
                                        >FPL Upload</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showAQVD"
                                        (change)="reverseValue('showAQVD')"
                                        (click)="$event.stopPropagation()"
                                    >
                                        A-QVD</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showAQVDTotalAndComplete"
                                        (change)="reverseValue('showAQVDTotalAndComplete')"
                                        (click)="$event.stopPropagation()"
                                    >
                                        A-QVD Total & Complete</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showAPLI"
                                        (change)="reverseValue('showAPLI')"
                                        (click)="$event.stopPropagation()"
                                    >
                                        A-PLI</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showNpw"
                                        (change)="reverseValue('showNpw')"
                                        (click)="$event.stopPropagation()"
                                        >NPW</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showBITR"
                                        (change)="reverseValue('showBITR')"
                                        (click)="$event.stopPropagation()"
                                        >B-ITR</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showBPLI"
                                        (change)="reverseValue('showBPLI')"
                                        (click)="$event.stopPropagation()"
                                        >B-PLI</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showCPLI"
                                        (change)="reverseValue('showCPLI')"
                                        (click)="$event.stopPropagation()"
                                        >C-PLI</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showDPLI"
                                        (change)="reverseValue('showDPLI')"
                                        (click)="$event.stopPropagation()"
                                        >D-PLI</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showCOW"
                                        (change)="reverseValue('showCOW')"
                                        (click)="$event.stopPropagation()"
                                        >COW</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showExc"
                                        (change)="reverseValue('showExc')"
                                        (click)="$event.stopPropagation()"
                                        >Exc</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showRedline"
                                        (change)="reverseValue('showRedline')"
                                        (click)="$event.stopPropagation()"
                                        >Redline</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showRedlineTotalAndComplete"
                                        (change)="reverseValue('showRedlineTotalAndComplete')"
                                        (click)="$event.stopPropagation()"
                                    >
                                        Redline Total & Complete</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showPriorityName"
                                        (change)="reverseValue('showPriorityName')"
                                        (click)="$event.stopPropagation()"
                                        >Staged Start-up Priority</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showNonComm"
                                        (change)="reverseValue('showNonComm')"
                                        (click)="$event.stopPropagation()"
                                        >Non Comm</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showFlangeMgt"
                                        (change)="reverseValue('showFlangeMgt')"
                                        (click)="$event.stopPropagation()"
                                    >
                                        Flange Mgt</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showMCSequence"
                                        (change)="reverseValue('showMCSequence')"
                                        (click)="$event.stopPropagation()"
                                    >
                                        MC Sequence</mat-checkbox
                                    >
                                    <mat-checkbox
                                        [checked]="showHideColumns.showGoocNo"
                                        (change)="reverseValue('showGoocNo')"
                                        (click)="$event.stopPropagation()"
                                        >GOOC No</mat-checkbox
                                    >
                                </div>
                            </mat-menu>

                            <button *ngIf="isAdmin" hidden
                                type="button"
                                mat-button
                                (click)="clearflagSubsystem()"
                                class="results-clear-flags"
                            >
                                <mat-icon>close</mat-icon>
                                clear flag(s)
                            </button>

                            <mat-slide-toggle
                                [color]="'primary'"
                                [checked]="disciplineBreakdownEnabled"
                                (toggleChange)="toggleDisciplineBreakdown()"
                                class="results-header-toggle"
                            >
                                show discipline breakdown
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>

                <div class="results-panel-content-container">
                    <div class="results-info">
                        <span class="results-info-highlight">
                            <mat-icon>info</mat-icon>
                            {{ resultsLength + ' ' }} {{ getSubsystemsInfoMessage() }}
                        </span>
                        found
                    </div>
                    <div class="table-wrapper">
                        <div
                            class="scroll"
                            [ngClass]="{
                                'subsysnm-hidden': !showHideColumns.showSubsystemName && isIE
                            }"
                        >
                            <div #tableContainer class="table-container">
                                <popover-content #trafficLightPopover [closeOnClickOutside]="true" placement="top">
                                    <ng-content
                                        *ngTemplateOutlet="
                                            trafficLightTemplate;
                                            context: { subsystem: selectedSubsystem }
                                        "
                                    ></ng-content>
                                </popover-content>
                                <table
                                    mat-table
                                    matSort
                                    [dataSource]="data"
                                    [matSortActive]="sortBy"
                                    matSortStart="desc"
                                    matSortDisableClear
                                    [matSortDirection]="direction"
                                >
                                    <ng-container matColumnDef="subsystem" [sticky]="!isIE ? 'sticky' : null">
                                        <th
                                            mat-header-cell
                                            app-col-resize
                                            disableClear
                                            *matHeaderCellDef
                                            class="sticky-col1"
                                            [ngClass]="{
                                                'header-filtered':
                                                    showUnderlineForCheckListColumn(columnSubsystems) ||
                                                    (columnFlags && columnFlags.items?.length)
                                            }"
                                        >
                                            <div class="sort-container">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="
                                                        openHeaderCheckListFilter(
                                                            'subsystem',
                                                            getSubsystems,
                                                            'columnSubsystems',
                                                            'Subsystem',
                                                            columnSubsystems
                                                        )
                                                    "
                                                    >Subsystem</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            class="sticky-col1"
                                            (click)="selectSubsystem(element.subsystem)"
                                            [popover]="popoverContent"
                                            [popoverOnHover]="false"
                                            [ngClass]="getSubsystemClasses(data, idx)"
                                        >
                                            {{ element.subsystem }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="subsystemName" [sticky]="!isIE ? 'sticky' : null">
                                        <th
                                            mat-header-cell
                                            app-col-resize
                                            disableClear
                                            *matHeaderCellDef
                                            class="sticky-col3"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showSubsystemName,
                                                'header-filtered': showUnderlineForCheckListColumn(columnSubsystemNames)
                                            }"
                                        >
                                            <div class="sort-container">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="
                                                        openHeaderCheckListFilter(
                                                            'subsystemName',
                                                            getSubsystemNames,
                                                            'columnSubsystemNames',
                                                            'Subsystem Name',
                                                            columnSubsystemNames
                                                        )
                                                    "
                                                    >Subsystem Name</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            class="sticky-col3"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showSubsystemName,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.subsystemName }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="discipline" [sticky]="!isIE ? 'sticky' : null">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                'subsysnm-hidden': !showHideColumns.showSubsystemName && isIE,
                                                hidecells: !disciplineBreakdownEnabled,
                                                'header-filtered': showUnderlineForCheckListColumn(columnDisciplines)
                                            }"
                                            class="hover-pointer sticky-col2"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'discipline',
                                                    getDisciplines,
                                                    'columnDisciplines',
                                                    'Discipline',
                                                    columnDisciplines
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>Discipline</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            class="sticky-col2"
                                            [ngClass]="{
                                                'subsysnm-hidden': !showHideColumns.showSubsystemName && isIE,
                                                hidecells: !disciplineBreakdownEnabled,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.discipline }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="mcPlan">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCPlanDate,
                                                'header-filtered': showUnderlineForCalendarColumn(columnMCPlan)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter('mcPlan', 'columnMCPlan', 'MC Plan', columnMCPlan)
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>MC Plan</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'mcPlan'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCPlanDate,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.mcPlan | date: 'd/MMM/yy' }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="mcCommitment">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCCommitmentDate,
                                                'header-filtered': showUnderlineForCalendarColumn(columnMCCommitment)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'mcCommitment',
                                                    'columnMCCommitment',
                                                    'MC Commitment',
                                                    columnMCCommitment
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>MC Commitment</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'mcCommitment'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCCommitmentDate,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.mcCommitment | date: 'd/MMM/yy' }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="mcForecast">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCForecastDate,
                                                'header-filtered': showUnderlineForCalendarColumn(columnMCForecast)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'mcForecast',
                                                    'columnMCForecast',
                                                    'MC Forecast',
                                                    columnMCForecast
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>MC Forecast</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'mcForecast'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            *matCellDef="let element; let idx = index"
                                            (contextmenu)="forecastDateHistory($event, element, 'mc')"
                                            (click)="openDatepicker(element, 'mc')"
                                            mat-cell
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCForecastDate,
                                                'cursor-pointer': !isReadOnly && !element.mcActual,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.mcForecast | date: 'd/MMM/yy' }}
                                            <button
                                                *ngIf="!element.mcForecast && !isReadOnly && !element.mcActual"
                                                type="button"
                                                mat-icon-button
                                            >
                                                <mat-icon>calendar_today</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="mcActual">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCActualDate,
                                                'header-filtered': showUnderlineForCalendarColumn(columnMCActual)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'mcActual',
                                                    'columnMCActual',
                                                    'MC Actual',
                                                    columnMCActual
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>MC Actual</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'mcActual'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCActualDate,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.mcActual | date: 'd/MMM/yy' }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="construction90Walkdown">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showConstructionWalkdown,
                                                'header-filtered': showUnderlineForCalendarColumn(
                                                    columnConstruction90Walkdown
                                                )
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'construction90Walkdown',
                                                    'columnConstruction90Walkdown',
                                                    'Construction 90% Walkdown',
                                                    columnConstruction90Walkdown
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>Construction 90% Walkdown</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'construction90Walkdown'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            *matCellDef="let element; let idx = index"
                                            (contextmenu)="forecastDateHistory($event, element, 'constr90Walkdown')"
                                            (click)="openDatepicker(element, 'constr90Walkdown')"
                                            mat-cell
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showConstructionWalkdown,
                                                'cursor-pointer': element.construction90Walkdown,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.construction90Walkdown | date: 'd/MMM/yy' }}
                                            <button
                                                *ngIf="!element.construction90Walkdown"
                                                type="button"
                                                mat-icon-button
                                            >
                                                <mat-icon>calendar_today</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dacForecast">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showDacStatus,
                                                'header-filtered': showUnderlineForCalendarColumn(columnDACForecast)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'dacForecast',
                                                    'columnDACForecast',
                                                    'DAC Forecast',
                                                    columnDACForecast
                                                )
                                            "
                                        >
                                            <div class="sort-container">
                                                <span>DAC Forecast</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'dacForecast'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            *matCellDef="let element; let idx = index"
                                            (click)="openDatepicker(element, 'dac')"
                                            mat-cell
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showDacStatus,
                                                'cursor-pointer': !isReadOnly && !element.dacActual,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.dacForecast | date: 'd/MMM/yy' }}
                                            <button
                                                *ngIf="!element.dacForecast && !isReadOnly && !element.dacActual"
                                                type="button"
                                                mat-icon-button
                                            >
                                                <mat-icon>calendar_today</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dacActual">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showDacStatus,
                                                'header-filtered': showUnderlineForCalendarColumn(columnDACActual)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'dacActual',
                                                    'columnDACActual',
                                                    'DAC Actual',
                                                    columnDACActual
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>DAC Actual</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'dacActual'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showDacStatus,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.dacActual | date: 'd/MMM/yy' }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="walkdownForecast">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showWalkdownForecast,
                                                'header-filtered': showUnderlineForCalendarColumn(
                                                    columnWalkdownForecast
                                                )
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'walkdownForecast',
                                                    'columnWalkdownForecast',
                                                    'Walkdown Forecast',
                                                    columnWalkdownForecast
                                                )
                                            "
                                        >
                                            <div class="sort-container">
                                                <span>Walkdown Forecast</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'walkdownForecast'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            *matCellDef="let element; let idx = index"
                                            (contextmenu)="forecastDateHistory($event, element, 'mcWalkdown')"
                                            (click)="openDatepicker(element, 'mcWalkdown')"
                                            mat-cell
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showWalkdownForecast,
                                                'cursor-pointer': !isReadOnly && !element.walkdownActual,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.walkdownForecast | date: 'd/MMM/yy' }}
                                            <button
                                                *ngIf="
                                                    !element.walkdownForecast && !isReadOnly && !element.walkdownActual
                                                "
                                                type="button"
                                                mat-icon-button
                                            >
                                                <mat-icon>calendar_today</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="walkdownSch">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showWalkdownScheduled,
                                                'header-filtered': showUnderlineForCalendarColumn(columnWalkdownSch)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'walkdownSch',
                                                    'columnWalkdownSch',
                                                    'Walkdown Scheduled',
                                                    columnWalkdownSch
                                                )
                                            "
                                        >
                                            <div class="sort-container">
                                                <span>Walkdown Scheduled</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'walkdownSch'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showWalkdownScheduled,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.walkdownSch | date: 'd/MMM/yy' }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="walkdownActual">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showWalkdownActual,
                                                'header-filtered': showUnderlineForCalendarColumn(columnWalkdownActual)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'walkdownActual',
                                                    'columnWalkdownActual',
                                                    'Walkdown Actual',
                                                    columnWalkdownActual
                                                )
                                            "
                                        >
                                            <div class="sort-container">
                                                <span>Walkdown Actual</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'walkdownActual'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showWalkdownActual,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.walkdownActual | date: 'd/MMM/yy' }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="safetyRewalkdown">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showSafetyRewalkdown,
                                                'header-filtered': showUnderlineForNumericColumn(columnSafetyRewalkdown)
                                            }"
                                        >
                                            <div class="sort-container center">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="openHeaderNumericFilter('safetyRewalkdown', columnSafetyRewalkdown, 'Days to re-walkdown')"
                                                    >MC Safety Re-walkdown</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'safetyRewalkdown'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showSafetyRewalkdown,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                        <div>
                                            <div>
                                                {{ element.safetyRewalkdown }}
                                            </div>
                                        </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="fplUpload">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showFPLUpload,
                                                'header-filtered': showUnderlineForCalendarColumn(columnFPLUpload)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderDateFilter(
                                                    'fplUpload',
                                                    'columnFPLUpload',
                                                    'FPL Upload',
                                                    columnFPLUpload
                                                )
                                            "
                                        >
                                            <div class="sort-container">
                                                <span>FPL Upload</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'fplUpload'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showFPLUpload,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.fplUpload | date: 'd/MMM/yy' }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="aqvd">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells:
                                                    !showHideColumns.showAQVDTotalAndComplete &&
                                                    !showHideColumns.showAQVD
                                            }"
                                            [ngStyle]="
                                                showHideColumns.showAQVDTotalAndComplete
                                                    ? { width: '225px' }
                                                    : { width: '80px' }
                                            "
                                        >
                                            <div>A-QVD</div>
                                            <div class="sub-header-container">
                                                <div
                                                    *ngIf="showHideColumns.showAQVDTotalAndComplete"
                                                    class="sort-container hover-pointer"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnAqvDsTotal
                                                        )
                                                    }"
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'aqvDsTotal',
                                                            columnAqvDsTotal,
                                                            'A-QVDs Total'
                                                        )
                                                    "
                                                >
                                                    <span>
                                                        Total
                                                    </span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'aqvDsTotal'">
                                                        <mat-icon *ngIf="direction === 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction === 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="showHideColumns.showAQVDTotalAndComplete"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnAqvDsCompleted
                                                        )
                                                    }"
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'aqvDsCompleted',
                                                            columnAqvDsCompleted,
                                                            'A-QVDs Completed'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                >
                                                    <span>Complete</span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'aqvDsCompleted'">
                                                        <mat-icon *ngIf="direction === 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction === 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showHideColumns.showAQVDTotalAndComplete ||
                                                        showHideColumns.showAQVD
                                                    "
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnAqvDsRemaining
                                                        )
                                                    }"
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'aqvDsRemaining',
                                                            columnAqvDsRemaining,
                                                            'A-QVDs Remaining'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                >
                                                    <span>Remaining</span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'aqvDsRemaining'">
                                                        <mat-icon *ngIf="direction === 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction === 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="showHideColumns.showAQVDTotalAndComplete"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnAqvDsPercentage
                                                        )
                                                    }"
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'aqvDsPercentage',
                                                            columnAqvDsPercentage,
                                                            'A-QVDs Percentage'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                >
                                                    <span>% </span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'aqvDsPercentage'">
                                                        <mat-icon *ngIf="direction === 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction === 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells:
                                                    !showHideColumns.showAQVDTotalAndComplete &&
                                                    !showHideColumns.showAQVD,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                            [ngStyle]="
                                                showHideColumns.showAQVDTotalAndComplete
                                                    ? { width: '225px' }
                                                    : { width: '80px' }
                                            "
                                        >
                                            <div>
                                                <div *ngIf="showHideColumns.showAQVDTotalAndComplete">
                                                    <span
                                                        ><a
                                                            (click)="
                                                                openDetailedStatusPage(
                                                                    element.subsystem,
                                                                    'AQVD',
                                                                    element.discipline,
                                                                    null,
                                                                    ['Complete', 'Not Started']
                                                                )
                                                            "
                                                            >{{ element.aqvDsTotal }}</a
                                                        ></span
                                                    >
                                                </div>
                                                <div *ngIf="showHideColumns.showAQVDTotalAndComplete">
                                                    <span
                                                        ><a
                                                            (click)="
                                                                openDetailedStatusPage(
                                                                    element.subsystem,
                                                                    'AQVD',
                                                                    element.discipline,
                                                                    null,
                                                                    ['Complete']
                                                                )
                                                            "
                                                            >{{ element.aqvDsCompleted }}</a
                                                        ></span
                                                    >
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showHideColumns.showAQVDTotalAndComplete ||
                                                        showHideColumns.showAQVD
                                                    "
                                                >
                                                    <span
                                                        ><a
                                                            (click)="
                                                                openDetailedStatusPage(
                                                                    element.subsystem,
                                                                    'AQVD',
                                                                    element.discipline,
                                                                    null,
                                                                    ['Not Started']
                                                                )
                                                            "
                                                            >{{ element.aqvDsRemaining }}</a
                                                        ></span
                                                    >
                                                </div>
                                                <div *ngIf="showHideColumns.showAQVDTotalAndComplete">
                                                    {{ element.aqvDsPercentage | number: '1.0-0' }}%
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="apli">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showAPLI,
                                                'header-filtered': showUnderlineForNumericColumn(columnApli)
                                            }"
                                        >
                                            <div class="sort-container center">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="openHeaderNumericFilter('apli', columnApli, 'A-PLI')"
                                                    >A-PLI</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'apli'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showAPLI,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            <span
                                                ><a
                                                    (click)="
                                                        openDetailedStatusPage(
                                                            element.subsystem,
                                                            'PLI',
                                                            element.discipline,
                                                            'A'
                                                        )
                                                    "
                                                    >{{ element.apli }}</a
                                                ></span
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="npw">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showNpw,
                                                'header-filtered': showUnderlineForNumericColumn(columnNpw)
                                            }"
                                        >
                                            <div class="sort-container center">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="openHeaderNumericFilter('npw', columnNpw, 'NPW')"
                                                    >NPW</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'npw'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showNpw,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            <span
                                                ><a
                                                    (click)="
                                                        openChangeManagementPage(
                                                            element.subsystem,
                                                            'NPWRedzone',
                                                            element.discipline
                                                        )
                                                    "
                                                    >{{ element.npw }}</a
                                                ></span
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="bitr">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showBITR,
                                                'header-filtered': showUnderlineForNumericColumn(columnBitr)
                                            }"
                                        >
                                            <div class="sort-container center">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="openHeaderNumericFilter('bitr', columnBitr, 'B-ITR')"
                                                    >B-ITR</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'bitr'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showBITR,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            <span
                                                ><a
                                                    (click)="
                                                        openDetailedStatusPage(
                                                            element.subsystem,
                                                            'BITR',
                                                            element.discipline,
                                                            null,
                                                            ['Not Started']
                                                        )
                                                    "
                                                    >{{ element.bitr }}</a
                                                ></span
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="bpli">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showBPLI,
                                                'header-filtered': showUnderlineForNumericColumn(columnBpli)
                                            }"
                                        >
                                            <div class="sort-container center">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="openHeaderNumericFilter('bpli', columnBpli, 'B-PLI')"
                                                    >B-PLI</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'bpli'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showBPLI,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            <span
                                                ><a
                                                    (click)="
                                                        openDetailedStatusPage(
                                                            element.subsystem,
                                                            'PLI',
                                                            element.discipline,
                                                            'B'
                                                        )
                                                    "
                                                    >{{ element.bpli }}</a
                                                ></span
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="cpli">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showCPLI,
                                                'header-filtered': showUnderlineForNumericColumn(columnCpli)
                                            }"
                                        >
                                            <div class="sort-container center">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="openHeaderNumericFilter('cpli', columnCpli, 'C-PLI')"
                                                    >C-PLI</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'cpli'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showCPLI,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            <span
                                                ><a
                                                    (click)="
                                                        openDetailedStatusPage(
                                                            element.subsystem,
                                                            'PLI',
                                                            element.discipline,
                                                            'C'
                                                        )
                                                    "
                                                    >{{ element.cpli }}</a
                                                ></span
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dpli">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showDPLI,
                                                'header-filtered': showUnderlineForNumericColumn(columnDpli)
                                            }"
                                        >
                                            <div class="sort-container center">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="openHeaderNumericFilter('dpli', columnDpli, 'D-PLI')"
                                                    >D-PLI</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'dpli'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showDPLI,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            <span
                                                ><a
                                                    (click)="
                                                        openDetailedStatusPage(
                                                            element.subsystem,
                                                            'PLI',
                                                            element.discipline,
                                                            'D'
                                                        )
                                                    "
                                                    >{{ element.dpli }}</a
                                                ></span
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="cow">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showCOW,
                                                'header-filtered': showUnderlineForNumericColumn(columnCow)
                                            }"
                                        >
                                            <div class="sort-container center">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="openHeaderNumericFilter('cow', columnCow, 'COW')"
                                                    >COW</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'cow'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showCOW,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            <span
                                                ><a
                                                    (click)="
                                                        openDetailedStatusPage(
                                                            element.subsystem,
                                                            'COW',
                                                            element.discipline
                                                        )
                                                    "
                                                    >{{ element.cow }}</a
                                                ></span
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ex">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showExc,
                                                'header-filtered': showUnderlineForNumericColumn(columnEx)
                                            }"
                                            class="end"
                                        >
                                            <div class="sort-container center">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="openHeaderNumericFilter('ex', columnEx, 'Exceptions')"
                                                    >Exc</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'ex'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showExc,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            <span
                                                ><a
                                                    (click)="
                                                        openDetailedStatusPage(
                                                            element.subsystem,
                                                            'EX',
                                                            element.discipline
                                                        )
                                                    "
                                                    >{{ element.ex }}</a
                                                ></span
                                            >
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="redline">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells:
                                                    !showHideColumns.showRedlineTotalAndComplete &&
                                                    !showHideColumns.showRedline
                                            }"
                                            [ngStyle]="
                                                showHideColumns.showRedlineTotalAndComplete
                                                    ? { width: '225px' }
                                                    : { width: '80px' }
                                            "
                                        >
                                            <div>Redline</div>
                                            <div class="sub-header-container">
                                                <div
                                                    *ngIf="showHideColumns.showRedlineTotalAndComplete"
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'redlinesTotal',
                                                            columnRedlinesTotal,
                                                            'Redlines Total'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnRedlinesTotal
                                                        )
                                                    }"
                                                >
                                                    <span>Total</span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'redlinesTotal'">
                                                        <mat-icon *ngIf="direction == 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction == 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="showHideColumns.showRedlineTotalAndComplete"
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'redlinesCompleted',
                                                            columnRedlinesCompleted,
                                                            'Redlines Complete'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnRedlinesCompleted
                                                        )
                                                    }"
                                                >
                                                    <span>Complete</span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'redlinesCompleted'">
                                                        <mat-icon *ngIf="direction == 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction == 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showHideColumns.showRedline ||
                                                        showHideColumns.showRedlineTotalAndComplete
                                                    "
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'redlinesRemaining',
                                                            columnRedlinesRemaining,
                                                            'Redlines Remaining'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnRedlinesRemaining
                                                        )
                                                    }"
                                                >
                                                    <span>Remaining</span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'redlinesRemaining'">
                                                        <mat-icon *ngIf="direction == 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction == 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="showHideColumns.showRedlineTotalAndComplete"
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'redlinesPercentage',
                                                            columnRedlinesPercentage,
                                                            'Redlines Percentage'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnRedlinesPercentage
                                                        )
                                                    }"
                                                >
                                                    <span>% </span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'redlinesPercentage'">
                                                        <mat-icon *ngIf="direction == 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction == 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells:
                                                    !showHideColumns.showRedlineTotalAndComplete &&
                                                    !showHideColumns.showRedline,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                            [ngStyle]="
                                                showHideColumns.showRedlineTotalAndComplete
                                                    ? { width: '225px' }
                                                    : { width: '80px' }
                                            "
                                        >
                                            <div>
                                                <div *ngIf="showHideColumns.showRedlineTotalAndComplete">
                                                    <span>
                                                        <a
                                                            (click)="
                                                                openDetailedStatusPage(
                                                                    element.subsystem,
                                                                    'Redline',
                                                                    element.discipline,
                                                                    null,
                                                                    ['Received', 'Required']
                                                                )
                                                            "
                                                            >{{ element.redlinesTotal }}</a
                                                        ></span
                                                    >
                                                </div>
                                                <div *ngIf="showHideColumns.showRedlineTotalAndComplete">
                                                    <span>
                                                        <a
                                                            (click)="
                                                                openDetailedStatusPage(
                                                                    element.subsystem,
                                                                    'Redline',
                                                                    element.discipline,
                                                                    null,
                                                                    ['Received']
                                                                )
                                                            "
                                                            >{{ element.redlinesCompleted }}</a
                                                        ></span
                                                    >
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showHideColumns.showRedline ||
                                                        showHideColumns.showRedlineTotalAndComplete
                                                    "
                                                >
                                                    <span>
                                                        <a
                                                            (click)="
                                                                openDetailedStatusPage(
                                                                    element.subsystem,
                                                                    'Redline',
                                                                    element.discipline,
                                                                    null,
                                                                    ['Required']
                                                                )
                                                            "
                                                            >{{ element.redlinesRemaining }}</a
                                                        ></span
                                                    >
                                                </div>
                                                <div *ngIf="showHideColumns.showRedlineTotalAndComplete">
                                                    {{ element.redlinesPercentage | number: '1.0-0' }}%
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="priorityName">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            class="hover-pointer"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showPriorityName,
                                                'header-filtered':
                                                    columnPriorityName && columnPriorityName.items?.length > 0
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'priorityName',
                                                    getPriorityNames,
                                                    'columnPriorityName',
                                                    'PriorityName',
                                                    columnPriorityName,
                                                    true,
                                                    false,
                                                    false
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>Staged Start-up Priority</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'priorityName'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showPriorityName,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.priorityName }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="flangeMgt">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showFlangeMgt
                                            }"
                                            [ngStyle]="{ width: '225px' }"
                                        >
                                            <div>Flange Mgt</div>
                                            <div class="sub-header-container">
                                                <div
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'flangeMgtTotal',
                                                            columnFlangeMgtTotal,
                                                            'Flange Management Total'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnFlangeMgtTotal
                                                        )
                                                    }"
                                                >
                                                    <span>Total</span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'flangeMgtTotal'">
                                                        <mat-icon *ngIf="direction == 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction == 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'flangeMgtTotalCompleted',
                                                            columnFlangeMgtTotalCompleted,
                                                            'Flange Management Complete'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnFlangeMgtTotalCompleted
                                                        )
                                                    }"
                                                >
                                                    <span>Complete</span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'flangeMgtCompleted'">
                                                        <mat-icon *ngIf="direction == 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction == 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'flangeMgtTotalRemaining',
                                                            columnFlangeMgtTotalRemaining,
                                                            'Flange Management Remaining'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnFlangeMgtTotalRemaining
                                                        )
                                                    }"
                                                >
                                                    <span>Remaining</span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'flangeMgtRemaining'">
                                                        <mat-icon *ngIf="direction == 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction == 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    (click)="
                                                        openHeaderNumericFilter(
                                                            'flangeMgtTotalPercentage',
                                                            columnFlangeMgtTotalPercentage,
                                                            'Flange Management Percentage'
                                                        )
                                                    "
                                                    class="sort-container hover-pointer center"
                                                    [ngClass]="{
                                                        'header-filtered': showUnderlineForNumericColumn(
                                                            columnFlangeMgtTotalPercentage
                                                        )
                                                    }"
                                                >
                                                    <span>%</span>
                                                    <div class="sort-icon" *ngIf="sortBy === 'flangeMgtPercentage'">
                                                        <mat-icon *ngIf="direction == 'asc'"
                                                            >keyboard_arrow_up</mat-icon
                                                        >
                                                        <mat-icon *ngIf="direction == 'desc'"
                                                            >keyboard_arrow_down</mat-icon
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showFlangeMgt,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                            [ngStyle]="{ width: '225px' }"
                                        >
                                            <div>
                                                <div class="total" *ngIf="showHideColumns.showFlangeMgt">
                                                    {{ element.flangeMgtTotal }}
                                                </div>
                                                <div *ngIf="showHideColumns.showFlangeMgt">
                                                    {{ element.flangeMgtTotalCompleted }}
                                                </div>
                                                <div *ngIf="showHideColumns.showFlangeMgt">
                                                    {{ element.flangeMgtTotalRemaining }}
                                                </div>
                                                <div class="percent" *ngIf="showHideColumns.showFlangeMgt">
                                                    {{ element.flangeMgtTotalPercentage | number: '1.0-0' }}%
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="comment" [stickyEnd]="!isIE ? 'stickyEnd' : null">
                                        <th
                                            [ngClass]="{
                                                'sticky-col-comment': isIE,
                                                'header-filtered': showUnderlineForCheckListColumn(columnComments)
                                            }"
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                        >
                                            <div class="sort-container">
                                                <span
                                                    class="hover-pointer"
                                                    (click)="
                                                        openHeaderCheckListFilter(
                                                            'comment',
                                                            getComments,
                                                            'columnComments',
                                                            'Comment',
                                                            columnComments,
                                                            false,
                                                            true,
                                                            true
                                                        )
                                                    "
                                                    >Comment</span
                                                >
                                                <div class="sort-icon" *ngIf="sortBy === 'comment'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                'sticky-col-comment': isIE,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                            mat-cell
                                        >
                                            <div
                                                (click)="openCommentPopup(element, $event)"
                                                class="comment-description"
                                            >
                                                <div *ngIf="element.comment && !isReadOnly; else notShow">
                                                    <a
                                                        ><span
                                                            [innerHTML]="
                                                                element.comment | formatMentions: element.mentions
                                                            "
                                                        ></span
                                                    ></a>
                                                </div>
                                                <div *ngIf="isReadOnly">
                                                    <span
                                                        [innerHTML]="element.comment | formatMentions: element.mentions"
                                                    ></span>
                                                </div>
                                                <ng-template #notShow>
                                                    <button
                                                        type="button"
                                                        *ngIf="!isReadOnly"
                                                        mat-stroked-button
                                                        class="add-comment"
                                                    >
                                                        Add
                                                    </button>
                                                </ng-template>
                                            </div>
                                            <div
                                                [ngClass]="{ notactive: !element.comment, active: element.comment }"
                                                class="comment-hist"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    (click)="openHistoryPopup(element)"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                                    />
                                                </svg>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nonCommissionable">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            class="hover-pointer"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showNonComm,
                                                'header-filtered':
                                                    columnNonCommissionable && columnNonCommissionable.items?.length > 0
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'nonCommissionable',
                                                    getNonCommissionable,
                                                    'columnNonCommissionable',
                                                    'NonCommissionable',
                                                    columnNonCommissionable,
                                                    false,
                                                    false
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>Non Comm</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'nonCommissionable'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showNonComm,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.nonCommissionable }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="mcSequence">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCSequence,
                                                'header-filtered': showUnderlineForCheckListColumn(columnMCSequence)
                                            }"
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'mcSequence',
                                                    getMCSequences,
                                                    'columnMCSequence',
                                                    'MC Sequence',
                                                    columnMCSequence,
                                                    false,
                                                    false
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>MC Sequence</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'mcSequence'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showMCSequence,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.mcSequence }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="goocNo">
                                        <th
                                            mat-header-cell
                                            disableClear
                                            *matHeaderCellDef
                                            class="hover-pointer"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showGoocNo,
                                                'header-filtered': columnGoocNo && columnGoocNo.items?.length > 0
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'goocNo',
                                                    getGoocNo,
                                                    'columnGoocNo',
                                                    'GoocNo',
                                                    columnGoocNo,
                                                    false,
                                                    false
                                                )
                                            "
                                        >
                                            <div class="sort-container center">
                                                <span>GOOC No</span>
                                                <div class="sort-icon" *ngIf="sortBy === 'goocNo'">
                                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                                </div>
                                            </div>
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let idx = index"
                                            [ngClass]="{
                                                hidecells: !showHideColumns.showGoocNo,
                                                'row-underline': isRowUnderlined(data, idx)
                                            }"
                                        >
                                            {{ element.goocNo }}
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let row; columns: displayedColumns"
                                        [ngClass]="{
                                            lastsubsystem: row.isLastInGroup,
                                            highlight: selectedRowIndex == row
                                        }"
                                        (click)="highlightRow(row)"
                                    ></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 50]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>

<ng-template #trafficLightTemplate let-subsystem="subsystem">
    <button class="red" type="button" mat-mini-fab (click)="flagSubsystem(subsystem, 'red')">&nbsp;</button>
    <button class="orange" type="button" mat-mini-fab (click)="flagSubsystem(subsystem, 'orange')">&nbsp;</button>
    <button class="green" type="button" mat-mini-fab (click)="flagSubsystem(subsystem, 'green')">&nbsp;</button>
</ng-template>
