import { Action } from '@ngrx/store';
import {
    ITRExcludeFilterRequestDTO,
    ITRExcludeFiltersResponseDTO,
    ExcludeFilterAssignmentDTO,
    SubsystemExcludeFilterRequestDTO,
    SubsystemExcludeFiltersResponseDTO,
} from '../common.model';

export enum PlanningConfigurationActionTypes {
    PlanningConfigSubsystemExcludeFilterPropertyUpdate = '[Planning Configuration] Subsystem Exclude Filter Property Update',
    PlanningConfigSubsystemExcludeFilterUpdate = '[Planning Configuration] Subsystem Exclude Filter Update',
    PlanningConfigSubsystemExcludeFilterReset = '[Planning Configuration] Subsystem Exclude Filter Reset',
    PlanningConfigSubsystemExcludeFiltersRequest = '[Planning Configuration] Subsystem Exclude Filter Request',
    PlanningConfigSubsystemExcludeFiltersSuccess = '[Planning Configuration] Subsystem Exclude Filter Success',
    PlanningConfigSubsystemExcludeFiltersError = '[Planning Configuration] Subsystem Exclude Filter Error',
    PlanningConfigSubsystemAssignExcludeFiltersRequest = '[Planning Configuration] Subsystem Assign Exclude Filter Request',
    PlanningConfigSubsystemAssignExcludeFiltersSuccess = '[Planning Configuration] Subsystem Assign Exclude Filter Success',
    PlanningConfigSubsystemAssignExcludeFiltersError = '[Planning Configuration] Subsystem Assign Exclude Filter Error',

    PlanningConfigITRExcludeFilterPropertyUpdate = '[Planning Configuration] ITR Exclude Filter Property Update',
    PlanningConfigITRExcludeFilterUpdate = '[Planning Configuration] ITR Exclude Filter Update',
    PlanningConfigITRExcludeFilterReset = '[Planning Configuration] ITR Exclude Filter Reset',
    PlanningConfigITRExcludeFiltersRequest = '[Planning Configuration] ITR Exclude Filter Request',
    PlanningConfigITRExcludeFiltersSuccess = '[Planning Configuration] ITR Exclude Filter Success',
    PlanningConfigITRExcludeFiltersError = '[Planning Configuration] ITR Exclude Filter Error',
    PlanningConfigITRAssignExcludeFiltersRequest = '[Planning Configuration] ITR Assign Exclude Filter Request',
    PlanningConfigITRAssignExcludeFiltersSuccess = '[Planning Configuration] ITR Assign Exclude Filter Success',
    PlanningConfigITRAssignExcludeFiltersError = '[Planning Configuration] ITR Assign Exclude Filter Error',
}

export class PlanningConfigSubsystemExcludeFilterPropertyUpdate implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFilterPropertyUpdate;

    constructor(public payload: SubsystemExcludeFilterRequestDTO) {}
}

export class PlanningConfigSubsystemExcludeFilterUpdate implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFilterUpdate;

    constructor(public payload: SubsystemExcludeFilterRequestDTO) {}
}

export class PlanningConfigSubsystemExcludeFilterReset implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFilterReset;
}

export class PlanningConfigSubsystemExcludeFiltersRequest implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFiltersRequest;
}

export class PlanningConfigSubsystemExcludeFiltersSuccess implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFiltersSuccess;

    constructor(public payload: SubsystemExcludeFiltersResponseDTO) {}
}

export class PlanningConfigSubsystemExcludeFiltersError implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFiltersError;

    constructor(public payload: string) {}
}

export class PlanningConfigSubsystemAssignExcludeFiltersRequest implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigSubsystemAssignExcludeFiltersRequest;

    constructor(public payload: { data: ExcludeFilterAssignmentDTO; isAssignment: boolean }) {}
}

export class PlanningConfigSubsystemAssignExcludeFiltersSuccess implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigSubsystemAssignExcludeFiltersSuccess;
}

export class PlanningConfigSubsystemAssignExcludeFiltersError implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigSubsystemAssignExcludeFiltersError;

    constructor(public payload: string) {}
}

export class PlanningConfigITRExcludeFilterPropertyUpdate implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigITRExcludeFilterPropertyUpdate;

    constructor(public payload: ITRExcludeFilterRequestDTO) {}
}

export class PlanningConfigITRExcludeFilterUpdate implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigITRExcludeFilterUpdate;

    constructor(public payload: ITRExcludeFilterRequestDTO) {}
}

export class PlanningConfigITRExcludeFilterReset implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigITRExcludeFilterReset;
}

export class PlanningConfigITRExcludeFiltersRequest implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigITRExcludeFiltersRequest;
}

export class PlanningConfigITRExcludeFiltersSuccess implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigITRExcludeFiltersSuccess;

    constructor(public payload: ITRExcludeFiltersResponseDTO) {}
}

export class PlanningConfigITRExcludeFiltersError implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigITRExcludeFiltersError;

    constructor(public payload: string) {}
}

export class PlanningConfigITRAssignExcludeFiltersRequest implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigITRAssignExcludeFiltersRequest;

    constructor(public payload: { data: ExcludeFilterAssignmentDTO; isAssignment: boolean }) {}
}

export class PlanningConfigITRAssignExcludeFiltersSuccess implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigITRAssignExcludeFiltersSuccess;
}

export class PlanningConfigITRAssignExcludeFiltersError implements Action {
    readonly type = PlanningConfigurationActionTypes.PlanningConfigITRAssignExcludeFiltersError;

    constructor(public payload: string) {}
}

export type PlanningConfigurationActions =
    | PlanningConfigSubsystemExcludeFilterPropertyUpdate
    | PlanningConfigSubsystemExcludeFilterUpdate
    | PlanningConfigSubsystemExcludeFilterReset
    | PlanningConfigSubsystemExcludeFiltersRequest
    | PlanningConfigSubsystemExcludeFiltersSuccess
    | PlanningConfigSubsystemExcludeFiltersError
    | PlanningConfigSubsystemAssignExcludeFiltersRequest
    | PlanningConfigSubsystemAssignExcludeFiltersSuccess
    | PlanningConfigSubsystemAssignExcludeFiltersError
    | PlanningConfigITRExcludeFilterPropertyUpdate
    | PlanningConfigITRExcludeFilterUpdate
    | PlanningConfigITRExcludeFilterReset
    | PlanningConfigITRExcludeFiltersRequest
    | PlanningConfigITRExcludeFiltersSuccess
    | PlanningConfigITRExcludeFiltersError
    | PlanningConfigITRAssignExcludeFiltersRequest
    | PlanningConfigITRAssignExcludeFiltersSuccess
    | PlanningConfigITRAssignExcludeFiltersError;
