import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, filter, map, take } from 'rxjs/operators';
import {
    ChangeValidationDashboardMonthlyValidationProgressRequest,
    ChangeValidationDashboardMonthlyValidationProgressSetMonthRange,
    ChangeValidationDashboardCumulativeValidationProgressSetMonthRange,
    ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange
} from 'src/app/store/reports/change-validation-dashboard/actions';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { getNextWeekStartDate, getWeekEndDate } from 'src/app/extensions';
import * as _ from 'lodash';
import { CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS, CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS } from 'src/app/store/reports/change-validation-dashboard/model';

@Component({
    selector: 'app-monthly-validation-progress',
    templateUrl: './monthly-validation-progress.component.html',
    styleUrls: ['./monthly-validation-progress.component.scss'],
})
export class MonthlyValidationProgressComponent extends BaseComponent implements OnInit {
    isLoading$ = this.store.select((state) => state.changeValidationDashboardState.monthlyValidationProgress.isLoading);
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    startDate$ = this.store.select(
        (store) => store.changeValidationDashboardState.monthlyValidationProgress.rangeDateFilter.startDate
    );
    endDate$ = this.store.select(
        (store) => store.changeValidationDashboardState.monthlyValidationProgress.rangeDateFilter.endDate
    );

    @ViewChild('dateRangePicker') dateRangePicker: MatDatepicker<moment.Moment>;

    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = false;
    showXAxisLabel: boolean = false;
    showYAxisLabel: boolean = false;
    colorScheme = {
        domain: ['#32CD32', '#FF963E'],
    };
    rangeDateFilter = getNextWeekStartDate().add(
        -CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS,
        'weeks'
    );
    currentDate = moment();



    data$ = this.store.select((state) => state.changeValidationDashboardState.monthlyValidationProgress.data);


    chartData$ = this.store.pipe(
        filter(
            (store) =>
                store.changeValidationDashboardState.monthlyValidationProgress.months.length > 0 &&
                store.changeValidationDashboardState.monthlyValidationProgress.data &&
                store.changeValidationDashboardState.monthlyValidationProgress.data.length > 0
        ),
        map((store) => {
            return _.zipWith(
                store.changeValidationDashboardState.monthlyValidationProgress.months,
                store.changeValidationDashboardState.monthlyValidationProgress.data,
                (month, total) => {
                    return {
                        name: moment(month.date).startOf('month').format('MMM YYYY'),
                        series: [
                            {
                                name: 'Validation Completed',
                                value: total.greenCount,
                            },
                            {
                                name: 'In Progress',
                                value: total.yellowCount,
                            },
                        ],
                    };
                }
            );
        })
    );

    constructor(private store: Store<ApplicationState>) {
        super();
        this.dateRangePickerControl = new UntypedFormControl();
        this.dateRangePickerControlFormatted = new UntypedFormControl({ value: '', disabled: true });
    }

    ngOnInit() {
        this.data$.pipe(take(1)).subscribe((data) => {
            if (data.length === 0) {
                console.log('keldi: ', data)
                this.store.dispatch(new ChangeValidationDashboardMonthlyValidationProgressRequest());
            }
        });

       
        this.endDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((endDate) => !!endDate)
            )
            .subscribe((endDate) => {
                this.dateRangePickerControl.setValue(moment(endDate), { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(endDate)));
            });

        this.dateRangePickerControl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((date: moment.Moment) => {
                this.store.dispatch(new ChangeValidationDashboardMonthlyValidationProgressSetMonthRange(date));
                this.store.dispatch(new ChangeValidationDashboardCumulativeValidationProgressSetMonthRange(date));
                this.store.dispatch(new ChangeValidationDashboardMonthlyChangeRaisedSetMonthRange(date));
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
            });
    }

    // dateRangePickerFilter = (d: moment.Moment | null): boolean => {
    //     const day = (moment(d) || moment()).isoWeekday();
    //     return day === 6;
    // };

    private formatDateRange(endDate: moment.Moment) {
        const startDate = moment(endDate).add(
            -CHANGE_VALIDATION_DASHBOARD_MONTHLY_CHANGE_RAISED_NUM_OF_PAST_MONTHS + 1,
            'months'
        );
        return `${startDate.format('MMM YY')} - ${endDate.format('MMM YY')}`;
    }

    axisFormat(val: number) {
        if (val % 1 === 0) {
            return val.toLocaleString();
        } else {
            return '';
        }
    }

    chosenYearHandler(normalizedYear: moment.Moment) {
        const ctrlValue = this.dateRangePickerControl.value;
        const dateChozen = moment(normalizedYear);
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue, { emitEvent: false });
    }

    chosenMonthHandler(normalizedMonth: moment.Moment) {
        const dateChozen = moment(normalizedMonth);
        const ctrlValue = this.dateRangePickerControl.value;
        ctrlValue.month(dateChozen.month());
        ctrlValue.year(dateChozen.year());
        this.dateRangePickerControl.setValue(ctrlValue);
        this.dateRangePicker.close();
    }
}
