<div class="results-container">
    <div class="title-container">
        <div class="result-table-title">
            Contractor Mapping
        </div>
    </div>
    <table mat-table matSort [dataSource]="dataSource" [matSortActive]="sortBy" matSortDisableClear
        [matSortDirection]="direction">
        <ng-container matColumnDef="mapping">
            <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Mapping</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.mapping }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="contractNo">
            <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Contract No</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.contractNo }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="remove">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="remove(element)" title="remove">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-toolbar>
        <span>Add new Contract Mapping</span>
        <button mat-icon-button (click)="add()" title="add">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </mat-toolbar>
    <div class="paginator page-counter">
        <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20]"
            showFirstLastButtons>
        </mat-paginator>
        <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
    </div>
    <app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
</div>