<div class="filter-header-container">
    <span class="filter-header-button">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 0H1C0.45 0 0 0.449679 0 0.999286C0 1.27909 0.11 1.52891 0.29 1.70878L5 6.41542V13.0007C5 13.5503 5.45 14 6 14C6.28 14 6.53 13.8901 6.71 13.7102L8.71 11.7116C8.89 11.5318 9 11.2819 9 11.0021V6.41542L13.71 1.70878C13.89 1.52891 14 1.27909 14 0.999286C14 0.449679 13.55 0 13 0Z"
                fill="#0066B2"
            />
        </svg>
        filter by
    </span>
    <div class="active-filters-container" *ngIf="showFilters">
        <mat-chip-list aria-label="Active filters">
            <mat-basic-chip *ngIf="filterForm.controls.workAreas.value.length" removable>
                Work Area: {{ filterForm.controls.workAreas.value[0].workAreaName }}
                <mat-icon matChipRemove (click)="clearFilterProperty('workAreas')">cancel</mat-icon>
            </mat-basic-chip>
            <mat-basic-chip *ngIf="filterForm.controls.nbDisciplineLeads.value.length" removable>
                NB Discipline Lead: {{ filterForm.controls.nbDisciplineLeads.value[0].name }}
            </mat-basic-chip>
            <mat-basic-chip *ngIf="filterForm.controls.nbEngineers.value.length" removable>
                NB Engineer: {{ filterForm.controls.nbEngineers.value[0].name }}
            </mat-basic-chip>
            <mat-basic-chip *ngIf="filterForm.controls.tcoAreaCoordinators.value.length" removable>
                TCO Area Coordinator: {{ filterForm.controls.tcoAreaCoordinators.value[0].name }}
            </mat-basic-chip>
            <mat-basic-chip *ngIf="filterForm.controls.goocs.value.length" removable>
                GOOC: {{ displayMultipleSelected(filterForm.controls.goocs.value) }}
                <mat-icon matChipRemove (click)="clearFilterProperty('goocs')">cancel</mat-icon>
            </mat-basic-chip>
            <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
            </mat-basic-chip>
            <mat-basic-chip *ngIf="filterForm.controls.areas.value.length" removable>
                Area: {{ displayMultipleSelected(filterForm.controls.areas.value) }}
                <mat-icon matChipRemove (click)="clearFilterProperty('areas')">cancel</mat-icon>
            </mat-basic-chip>
            <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                SC Execution Area Manager: {{ displayMultipleSelected(filterForm.controls.scManager.value) }}
                <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
            </mat-basic-chip>
            <mat-basic-chip *ngIf="filterForm.controls.subsystems.value.length" removable>
                Subsystems: {{ displayMultipleSelected(filterForm.controls.subsystems.value) }}
                <mat-icon matChipRemove (click)="clearFilterProperty('subsystems')">cancel</mat-icon>
            </mat-basic-chip>
            <mat-basic-chip>
                In weekly plan?: {{ filterForm.controls.inWeeklyPlan.value === true ? 'Yes' : 'All' }}
            </mat-basic-chip>
            <mat-basic-chip>
                Hide activities with 0 remaining ITR?:
                {{ filterForm.controls.hasNoITRsToGo.value === false ? 'Yes' : 'No' }}
            </mat-basic-chip>
            <mat-basic-chip>
                Hide constrained activities?: {{ filterForm.controls.hasNoConstraint.value === true ? 'Yes' : 'No' }}
            </mat-basic-chip>
            <mat-basic-chip>
                Show activities marked as complete?:
                {{
                    filterForm.controls.markedAsComplete.value === true
                        ? 'Yes'
                        : filterForm.controls.markedAsComplete.value === false
                        ? 'No'
                        : 'All'
                }}
            </mat-basic-chip>
            <mat-basic-chip> Week start: {{ filterForm.controls.weekStart.value | date: 'd/MMM/yy' }} </mat-basic-chip>
            <mat-basic-chip
                *ngIf="filterForm.controls.stagedStartUpPriorities.value.length"
                removable
            >
                TDB Staged Start-up Priority:
                {{ displayStageUpPriorities(filterForm.controls.stagedStartUpPriorities.value) }}
                <mat-icon matChipRemove (click)="clearFilterProperty('stagedStartUpPriorities')"
                    >cancel</mat-icon
                >
            </mat-basic-chip>
        </mat-chip-list>
    </div>
    <span class="button-reset" (click)="clearFilters($event)">
        Reset filters &times;
    </span>
</div>
