

import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';
import { ExceptionsPriorityDeleteFilter, ExceptionsPriorityDeletePagination, ExceptionsPriorityState } from './model';
import { ExceptionsPriorityActions, ExceptionsPriorityActionTypes } from './actions';

const initialState: ExceptionsPriorityState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deleteData: new ExceptionsPriorityDeletePagination(),
    isDeleteDataLoading: false,
    deleteFilter: new ExceptionsPriorityDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: ExceptionsPriorityActions): ExceptionsPriorityState {
    switch (action.type) {
        case ExceptionsPriorityActionTypes.ExceptionsPriorityUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityPatchAllDeleteRecordsRequest:
        case ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteRecordsSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityValidateDataError:
        case ExceptionsPriorityActionTypes.ExceptionsPriorityPatchAllDeleteRecordsError:
        case ExceptionsPriorityActionTypes.ExceptionsPriorityPatchDeleteRecordError:
        case ExceptionsPriorityActionTypes.ExceptionsPriorityPatchDeleteRecordSuccess:
        case ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPrioritySetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    deleteFilter: {
                        ...state.deleteFilter,
                        [action.payload.key]: Array.isArray(state.deleteFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityDeleteFilterReset: {
            const newFilter = new ExceptionsPriorityDeleteFilter();
            return {
                ...state,
                deleteFilter: { ...newFilter },
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityPatchDeleteRecordRequest: {
            var itemIndex = state.deleteData.items.findIndex((d) => d.exception === action.payload.exception);
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                deleteState: action.payload.deleteState,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityPatchAllDeleteRecordsSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    ...state.deleteData,
                    items: state.deleteData.items.map((item) => ({ ...item, deleteState: action.payload.deleteState })),
                },
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityValidateDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityValidateDataSuccess: {
            return {
                ...state,
                deleteData: new ExceptionsPriorityDeletePagination(),
                isDeleteDataLoading: false,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deleteData: new ExceptionsPriorityDeletePagination(),
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityDownloadOutputDataRequest:
        case ExceptionsPriorityActionTypes.ExceptionsPriorityTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityTemplateFileError:
        case ExceptionsPriorityActionTypes.ExceptionsPriorityDownloadOutputDataSuccess:
        case ExceptionsPriorityActionTypes.ExceptionsPriorityDownloadOutputDataError:
        case ExceptionsPriorityActionTypes.ExceptionsPriorityTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case ExceptionsPriorityActionTypes.ExceptionsPriorityAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'ExceptionsPriority';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}

