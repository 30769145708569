import { Action } from "@ngrx/store";
import { THSAttachmentDto, TraceHeatingStatusFilter, TraceHeatingStatusResultDto } from "./model";

export enum TraceHeatingStatusActionTypes {
    TraceHeatingStatusDataRequest = '[Trace Heating Status] Requesting Trace Heating Status',
    TraceHeatingStatusDataRequestSuccess = '[Trace Heating Status] Request for Trace Heating Status data Success',
    TraceHeatingStatusDataRequestError = '[Trace Heating Status] Request for Trace Heating Status data Error',
    TraceHeatingStatusFilterUpdate = '[Trace Heating Status] Filter was set',
    TraceHeatingStatusFilterReset = '[Trace Heating Status] Filter Reset',
    TraceHeatingStatusExportToExcelRequest = '[Trace Heating Status] Export to Excel Request',
    TraceHeatingStatusExportToExcelSuccess = '[Trace Heating Status] Export to Excel Success',
    TraceHeatingStatusExportToExcelError = '[Trace Heating Status] Export to Excel Error',
    ResetPrint = '[Trace Heating Status] Reset Print',
    TraceHeatingStatusAttachmentsUpdated = '[Trace Heating Status] Attachments Updated',

    TraceHeatingStatusDownloadAttachmentRequest = '[Trace Heating Status] Download Attachment Request',
    TraceHeatingStatusDownloadAttachmentSuccess = '[Trace Heating Status] Download Attachment Success',
    TraceHeatingStatusDownloadAttachmentError = '[Trace Heating Status] Download Attachment Error',

    TraceHeatingStatusDeleteAttachmentRequest = '[Trace Heating Status] Delete Attachment Request',
    TraceHeatingStatusDeleteAttachmentSuccess = '[Trace Heating Status] Delete Attachment Success',
    TraceHeatingStatusDeleteAttachmentError = '[Trace Heating Status] Delete Attachment Error',

    TraceHeatingStatusUploadAttachmentsRequest = '[Trace Heating Status] Upload Attachments Request',
    TraceHeatingStatusUploadAttachmentsSuccess = '[Trace Heating Status] Upload Attachments Success',
    TraceHeatingStatusUploadAttachmentsError = '[Trace Heating Status] Upload Attachments Error',
}

export class TraceHeatingStatusDataRequest implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusDataRequest;

    constructor(public printMode: boolean = false) {
        // This is intentional
    }
}

export class TraceHeatingStatusDataRequestSuccess implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusDataRequestSuccess;

    constructor(public traceHeatingStatus: TraceHeatingStatusResultDto, public printMode: boolean) {
        // This is intentional
    }
}

export class TraceHeatingStatusDataRequestError implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusDataRequestError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class TraceHeatingStatusFilterUpdate implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusFilterUpdate;

    constructor(public filter: TraceHeatingStatusFilter) {
        // This is intentional
    }
}

export class TraceHeatingStatusFilterReset implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusFilterReset;
}

export class TraceHeatingStatusExportToExcelRequest implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusExportToExcelRequest;
}

export class TraceHeatingStatusExportToExcelSuccess implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusExportToExcelSuccess;

    constructor(public payload: any) {
        // This is intentional
    }
}

export class TraceHeatingStatusExportToExcelError implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusExportToExcelError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class TraceHeatingStatusResetPrint implements Action {
    readonly type = TraceHeatingStatusActionTypes.ResetPrint;
}

export class TraceHeatingStatusAttachmentsUpdated implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusAttachmentsUpdated;

    constructor(public tdbSubsystem: string, public attachments: THSAttachmentDto[]) {
        // This is intentional
    }
}

export class TraceHeatingStatusDownloadAttachmentRequest implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusDownloadAttachmentRequest;

    constructor(public attachments: THSAttachmentDto[], public index: number) {
        // This is intentional
    }
}

export class TraceHeatingStatusDownloadAttachmentSuccess implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusDownloadAttachmentSuccess;

    constructor() {
        // This is intentional
    }
}

export class TraceHeatingStatusDownloadAttachmentError implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusDownloadAttachmentError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class TraceHeatingStatusDeleteAttachmentRequest implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusDeleteAttachmentRequest;

    constructor(public tdbSubsystem: string, public attachments: THSAttachmentDto[], public index: number) {
        // This is intentional
    }
}

export class TraceHeatingStatusDeleteAttachmentSuccess implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusDeleteAttachmentSuccess;

    constructor(public tdbSubsystem: string, public attachments: THSAttachmentDto[], public index: number) {
        // This is intentional
    }
}

export class TraceHeatingStatusDeleteAttachmentError implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusDeleteAttachmentError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class TraceHeatingStatusUploadAttachmentsRequest implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusUploadAttachmentsRequest;

    constructor(public tdbSubsystem: string, public formData: FormData) {
        // This is intentional
    }
}

export class TraceHeatingStatusUploadAttachmentsSuccess implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusUploadAttachmentsSuccess;

    constructor(public tdbSubsystem: string, public attachments: THSAttachmentDto[]) {
        // This is intentional
    }
}

export class TraceHeatingStatusUploadAttachmentsError implements Action {
    readonly type = TraceHeatingStatusActionTypes.TraceHeatingStatusUploadAttachmentsError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export type TraceHeatingStatusActions =
    | TraceHeatingStatusDataRequest
    | TraceHeatingStatusDataRequestSuccess
    | TraceHeatingStatusDataRequestError
    | TraceHeatingStatusFilterUpdate
    | TraceHeatingStatusFilterReset
    | TraceHeatingStatusExportToExcelRequest
    | TraceHeatingStatusExportToExcelSuccess
    | TraceHeatingStatusExportToExcelError
    | TraceHeatingStatusResetPrint
    | TraceHeatingStatusAttachmentsUpdated
    | TraceHeatingStatusDownloadAttachmentRequest
    | TraceHeatingStatusDownloadAttachmentSuccess
    | TraceHeatingStatusDownloadAttachmentError
    | TraceHeatingStatusDeleteAttachmentRequest
    | TraceHeatingStatusDeleteAttachmentSuccess
    | TraceHeatingStatusDeleteAttachmentError
    | TraceHeatingStatusUploadAttachmentsRequest
    | TraceHeatingStatusUploadAttachmentsSuccess
    | TraceHeatingStatusUploadAttachmentsError;
