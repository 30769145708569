<div class="results-container scroll">
    <div class="actions-row">
        <div>
            <button mat-button (click)="openAssignmentDialog()" [disabled]="!isAnySelected()">
                <mat-icon>add_circle_outline</mat-icon>
                Assign Exclude Filter
            </button>
            <button mat-button (click)="openAssignmentDialog(false)" [disabled]="!isAnySelected()">
                <mat-icon>remove_circle_outline</mat-icon>
                Remove Exclude Filter
            </button>
        </div>
        <button mat-button (click)="resetFilters()" class="reset-button">Reset filters &times;</button>
    </div>
    <div class="table-container">
        <table mat-table matSort [dataSource]="dataSource" matSortDisableClear>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                    >
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Subsystem Column -->
            <ng-container matColumnDef="subsystem">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'subsystem',
                                    getSubsystems,
                                    'columnSubsystem',
                                    'Subsystem',
                                    subsystemFilter.columnSubsystem
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(subsystemFilter.columnSubsystem)
                            }"
                            >Subsystem</span
                        >
                        <div class="sort-icon" *ngIf="subsystemFilter.sortBy === 'subsystem'">
                            <mat-icon *ngIf="subsystemFilter.direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="subsystemFilter.direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.subsystem }}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'name',
                                    getSubsystemNames,
                                    'columnSubsystemName',
                                    'Subsystem Name',
                                    subsystemFilter.columnSubsystemName
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(subsystemFilter.columnSubsystemName)
                            }"
                            >Subsystem Name</span
                        >
                        <div class="sort-icon" *ngIf="subsystemFilter.sortBy === 'name'">
                            <mat-icon *ngIf="subsystemFilter.direction == 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="subsystemFilter.direction == 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="excludeFilters">
                <th mat-header-cell disableClear *matHeaderCellDef>
                    <div class="sort-container">
                        <span
                            class="hover-pointer"
                            (click)="
                                openHeaderCheckListFilter(
                                    'excludeFilters',
                                    getExcludeValues,
                                    'columnExcludeFilter',
                                    'Exclude Filter',
                                    subsystemFilter.columnExcludeFilter,
                                    false,
                                    true,
                                    true
                                )
                            "
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(subsystemFilter.columnExcludeFilter)
                            }"
                            >Exclude Filter</span
                        >
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ excludeFiltersToString(element.excludeFilters) }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
        </table>
    </div>
    <div class="paginator page-counter">
        <mat-paginator
            [length]="totalLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50]"
            (page)="onPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
    </div>
</div>
