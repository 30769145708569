import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExportPageDataPagination, ExportPageFilter } from 'src/app/store/export-page/model';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ExportPageService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/Excel';
    constructor(private http: HttpClient) {}

    getExportPageData() {
        return this.http.get<ExportPageDataPagination>(`${this.rootUrl}${this.endpoint}/automatedexports`);
    }

    getExportPageFile(path: string) {
        return this.http.get<BlobPart>(
            `${this.rootUrl}${this.endpoint}/file?path=${path}`,
            {
                responseType: 'arraybuffer' as 'json',
            }
        );
    }

    // markRead(mentionId: number, isRead: boolean){
    //     return this.http.post(`${this.rootUrl}${this.endpoint}/read/${mentionId}/${isRead}`, {});
    // }
}
