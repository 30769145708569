import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Subscription } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import {
    PlanningDataUploadLogRequest,
    PlanningDataDeltaFilterPropertyUpdate,
    PlanningDataDeltaRequest,
    PlanningDataPatchDeltaRequest,
    PlanningDataTemplateFileRequest,
    PlanningDataDownloadOutputDataRequest,
    PlanningDataPatchAllDeltaRequest,
} from 'src/app/store/planning-upload/planning-data/actions';
import { PlanningDataDeltaDTO } from 'src/app/store/planning-upload/planning-data/model';
import { ActivatedRoute } from '@angular/router';
import { PlanningUploadSetActivatedRoute } from 'src/app/store/planning-upload/actions';

@Component({
    selector: 'app-planning-data',
    templateUrl: './planning-data.component.html',
    styleUrls: ['./planning-data.component.scss'],
})
export class PlanningDataComponent extends BaseComponent implements OnInit {
    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    deltaData: MatTableDataSource<PlanningDataDeltaDTO>;
    deltaDataResultsLength = 0;
    deltaDataPageSize = 10;
    displayedDeltaDataColumns = [
        'subsystem',
        'projectTeamName',
        'contractor',
        'mcPlanLive',
        'rfsuPlanLive',
        'arrow',
        'mcPlanImport',
        'rfsuPlanImport',
        'actions',
    ];

    uploadLogData$ = this.store.select((store) => store.planningDataState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.planningDataState.isUploadLogLoading);
    deltaData$ = this.store.select((store) => store.planningDataState.deltaData);
    isDeltaDataLoading$ = this.store.select((store) => store.planningDataState.isDeltaDataLoading);
    isLoading$ = this.store.select((store) => store.planningDataState.isLoading);
    interval$ = this.store.select((store) => store.planningUploadState.interval);
    intervalSub: Subscription = null;

    showOrder = true;

    constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new PlanningUploadSetActivatedRoute(urlSegments[0].path));
        });
        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new PlanningDataUploadLogRequest());
            });
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

        this.deltaData$.pipe(takeWhile(() => this.isAlive)).subscribe((deltaData) => {
            this.deltaData = new MatTableDataSource(deltaData.items);
            this.deltaDataResultsLength = deltaData.totalCount;
        });
    }

    rejectChange(element: PlanningDataDeltaDTO) {
        element.deltaState = false;

        this.store.dispatch(
            new PlanningDataPatchDeltaRequest({
                subsystem: element.subsystem,
                deltaState: false,
            })
        );
    }

    acceptChange(element: PlanningDataDeltaDTO) {
        element.deltaState = true;

        this.store.dispatch(
            new PlanningDataPatchDeltaRequest({
                subsystem: element.subsystem,
                deltaState: true,
            })
        );
    }

    patchAllChanges(deltaState: boolean) {
        this.store.dispatch(
            new PlanningDataPatchAllDeltaRequest({
                deltaState: deltaState,
            })
        );
    }

    onDeltaDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deltaDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new PlanningDataDeltaFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deltaDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new PlanningDataDeltaFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new PlanningDataDeltaRequest());
    }

    downloadTemplate(fileName: string) {
        this.store.dispatch(new PlanningDataTemplateFileRequest(fileName));
    }

    downloadData(filePath: string) {
        this.store.dispatch(new PlanningDataDownloadOutputDataRequest(filePath));
    }
}
