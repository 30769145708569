<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add project team role</span></p>
    <div>
        <form [formGroup]="addForm">
            <mat-form-field class="container300">
                <mat-label>Project Team</mat-label>
                <mat-select formControlName="projectTeamName">
                    <mat-option *ngFor="let projectTeamName of projectTeamNames" [value]="projectTeamName">{{
                        projectTeamName
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="container300">
                <mat-label>Role</mat-label>
                <mat-select formControlName="role">
                    <mat-option *ngFor="let role of roles" [value]="role.value">{{ role.text }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="container300">
                <mat-label>Name</mat-label>
                <textarea formControlName="name" matInput name="name"></textarea>
            </mat-form-field>
            <div class="row">
                <button mat-button type="button" class="sct-button sct-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!addForm.valid"
                    class="sct-button sct-button-light"
                    (click)="onConfirm()"
                >
                    add role
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
