<ng-container *cdkPortal>
    <div class="page-break" *ngFor="let part of parts$ | async">
        <app-reports-skyline-chart
            [weeks]="part.weeks"
            [subsystemsByWeek]="part.subsystemsByWeek"
            [openAqvdsPerWeek]="part.openAqvdsPerWeek"
            [openBitrsPerWeek]="part.openBitrsPerWeek"
            [openCitrsPerWeek]="part.openCitrsPerWeek"
            [isLoading]="printIsLoading$ | async"
            [pastWeeksCount]="getPastWeeksLength(part)"
            [skylinePhase]="skylinePhase"
            [skylineType]="skylineType"
            [printMode]="true"
        >
        </app-reports-skyline-chart>
        <div class="skyline-legend">
            <div>
                <span class="legend-item--progress">&nbsp;</span>
                {{ skylinePhase === 'mc' ? 'QVD' : skylinePhase === 'citr' ? 'C-ITR' : 'B-ITR & C-ITR' }} Completion %
            </div>
            <div *ngIf="skylinePhase !== 'citr'">
                <span class="legend-item--wdcompl">&nbsp;</span> {{ skylinePhase === 'mc' ? 'MC' : 'RFSU' }} Walkdown
                completed
            </div>
            <div *ngIf="skylinePhase !== 'citr'">
                <span class="legend-item--mccompl">&nbsp;</span> {{ skylinePhase === 'mc' ? 'MC' : 'RFSU' }} Completed
            </div>
        </div>
    </div>
    <ng-content></ng-content>
</ng-container>
