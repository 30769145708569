import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';

@Injectable({
    providedIn: 'root',
})
export class IsInGroupGuard implements CanActivate {
    constructor(private groupSvc: RoleService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (
            this.groupSvc.isInAnyRole([
                Constants.applicableGroups.ReadOnly,
                Constants.applicableGroups.MCEngineer3GI,
                Constants.applicableGroups.MCEngineer3GP,
                Constants.applicableGroups.Admin,
                Constants.applicableGroups.Validator3GI,
                Constants.applicableGroups.Validator3GP,
                Constants.applicableGroups.LoopCoordinator,
                Constants.applicableGroups.LoopEngineer,
                Constants.applicableGroups.Planners,
                Constants.applicableGroups.ExecutionPlanning,
            ])
        ) {
            return true;
        }

        this.router.navigate(['/notauthorized']);
        return false;
    }
}
