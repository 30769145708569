import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    PLIActionTypes,
    PLIUploadLogSuccess,
    PLIUploadLogError,
    PLISetStatusAndStartDate,
    PLIClearInProgressSpinner,
    PLITemplateFileRequest,
    PLITemplateFileSuccess,
    PLITemplateFileError,
    PLIValidateDeltaSuccess,
    PLIValidateDeltaError,
    PLIDataInfoRequest,
    PLIDownloadOutputDataRequest,
    PLIDownloadOutputDataSuccess,
    PLIDownloadOutputDataError,
    PLIValidateButtonStateError,
    PLIValidateButtonStateSuccess,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class PLIEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(PLIActionTypes.PLIUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['PLI']).pipe(
                map((uploadLogData) => new PLIUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting logs for PLI. Please contact Program Administrator.'
                    );
                    return of(new PLIUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<PLIUploadLogSuccess>(PLIActionTypes.PLIUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.pliState.uploadLogStatus),
            this.store.select((store) => store.pliState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new PLISetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<PLITemplateFileRequest>(PLIActionTypes.PLITemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new PLITemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting template for PLI. Please contact Program Administrator.'
                    );
                    return of(new PLITemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<PLITemplateFileSuccess>(PLIActionTypes.PLITemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'SCMT PLI.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<PLIDownloadOutputDataRequest>(PLIActionTypes.PLIDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new PLIDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting output result for PLI. Please contact Program Administrator.'
                    );
                    return of(new PLIDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<PLIDownloadOutputDataSuccess>(PLIActionTypes.PLIDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(PLIActionTypes.PLIValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validatePLIDelta().pipe(
                map(() => new PLIValidateDeltaSuccess()),
                catchError((error) => {
                    if (error?.error === 'AutomaticUploadPipelineInProgress') {
                        this.toastService.Info(
                            'Cannot validate the data while automatic upload pipeline is in progress.'
                        );
                    } else {
                        this.toastService.Error(
                            'Error occurred while validating and pushing PLI. Please contact Program Administrator.'
                        );
                    }
                    return of(new PLIValidateDeltaError(error));
                })
            )
        )
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(PLIActionTypes.PLISetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.pliState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new PLIClearInProgressSpinner(), new PLIDataInfoRequest()])
    );

    @Effect()
    setValidateButtonEnabled$ = this.actions$.pipe(
        ofType(PLIActionTypes.PLIUploadLogSuccess),
        mergeMap(() =>
            this.manualUploadsService.getValidateButtonState('PLI').pipe(
                map((response: any) => new PLIValidateButtonStateSuccess(response)),
                catchError((error) => of(new PLIValidateButtonStateError(error)))
            )
        )
    );
}
