import { SubsystemMaintenanceState, SubsystemMaintenanceFilter, SubsystemDataPagination } from './model';
import { SubsystemActions, SubsystemMaintenanceActionTypes } from './actions';
import * as _ from 'lodash';

const initialState: SubsystemMaintenanceState = {
    subsystemDataPagination: new SubsystemDataPagination(),
    filter: new SubsystemMaintenanceFilter(),
    isLoading: false,
};

export function reducer(state = initialState, action: SubsystemActions): SubsystemMaintenanceState {
    switch (action.type) {
        case SubsystemMaintenanceActionTypes.SubsystemSaveRequest:
        case SubsystemMaintenanceActionTypes.SubsystemFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case SubsystemMaintenanceActionTypes.SubsystemFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                subsystemDataPagination: action.payload,
            };
        }
        case SubsystemMaintenanceActionTypes.SubsystemSaveSuccess:
        case SubsystemMaintenanceActionTypes.SubsystemSaveError:
        case SubsystemMaintenanceActionTypes.SubsystemFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SubsystemMaintenanceActionTypes.SubsystemFilterPropertyUpdate: {
            let newFilter = _.cloneDeep(state.filter);
            if (action.payload.key === 'sortBy') {
                newFilter.sortBy = action.payload.value.active;
                newFilter.direction = action.payload.value.direction;
            } else {
                if (Array.isArray(state.filter[action.payload.key])) {
                    newFilter[action.payload.key] = Object.assign([], action.payload.value);
                } else {
                    newFilter[action.payload.key] = action.payload.value;
                }
            }
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        default:
            return state;
    }
}
