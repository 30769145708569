import {
    Waypoint,
    Milestone,
    OrderDirection,
    Contractor,
    CalendarColumn,
    CheckListColumn,
    RFO,
    System,
    UserDetail,
    Gooc,
    StagedStartUpPriority,
    NumericColumn,
} from '../common.model';
import * as moment from 'moment';

export interface BlueZoneState {
    dataPagination: BlueZoneDataPagination;
    filter: BlueZoneFilter;
    isLoading: boolean;
}

export class BlueZoneDataPagination {
    items: BlueZoneDTO[] = [];
    totalCount = 0;
}

export class BlueZoneFilter {
    page = 0;
    take = 25;
    sortBy = 'subsystem';
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    rfos: RFO[] = [];
    goocs: Gooc[] = [];
    scManager: string[] = [];
    deliveryEngineer: string[] = [];
    constructionArea: string[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    mcEngineer: string[] = [];
    systemOwner: string[] = [];
    acm: string[] = [];
    rfsuCompleted: boolean = null;
    rfsuCommitted: boolean = null;
    rfsuRemaining:boolean = false;
    hasLUN: boolean = null;
    requiredForSteamblow: boolean = null;
    columnMCForecast: CalendarColumn = null;
    columnMCPlan: CalendarColumn = null;
    columnMCActual: CalendarColumn = null;
    columnSafetyRewalkdown: NumericColumn = null;
    columnWalkdownForecast: CalendarColumn = null;
    columnWalkdownActual: CalendarColumn = null;
    columnWalkdownSch: CalendarColumn = null;
    columnFPLUpload: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;
    columnRFSUWalkdownPlan: CalendarColumn = null;
    columnRFSUCommitment: CalendarColumn = null;
    columnRFSUForecast: CalendarColumn = null;
    columnRFOPlan: CalendarColumn = null;
    columnRFSUActual: CalendarColumn = null;
    columnEdossierInitiation: CalendarColumn = null;
    columnEdossierStatus: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnSubsystems: CheckListColumn = null;
    columnSubsystemNames: CheckListColumn = null;
    columnRfo: CheckListColumn = null;
    columnDeliveryEngineer: CheckListColumn = null;
    columnWaypoints: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestones: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnSystemOwner: CheckListColumn = null;
    columnDisciplines: CheckListColumn = null;
    columnPriorityName: CheckListColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    columnContractorNo: CheckListColumn = null;
    columnAreaCompletionLead: CheckListColumn = null;
    columnBacklogCats: CheckListColumn = null;
    showHideColumns = new ShowHideColumns();
    timezoneOffset = 0;
    systems: System[] = [];
    disciplineBreakdownEnabled: boolean = false;
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    columnGoocNo: CheckListColumn = null;
    columnFlags: CheckListColumn = null;
    columnWalkdownCommitments: CheckListColumn = null;

    columnAqvd: NumericColumn = null;
    columnApli: NumericColumn = null;
    columnNpw: NumericColumn = null;
    columnBitRsRemaining: NumericColumn = null;
    columnBitRsTotal: NumericColumn = null;
    columnBitRsCompleted: NumericColumn = null;
    columnBitRsPercentage: NumericColumn = null;
    columnCitRsRemaining: NumericColumn = null;
    columnCitRsTotal: NumericColumn = null;
    columnCitRsCompleted: NumericColumn = null;
    columnCitRsPercentage: NumericColumn = null;
    columnBpli: NumericColumn = null;
    columnCpli: NumericColumn = null;
    columnDpli: NumericColumn = null;
    columnPascr: NumericColumn = null;
    columnEx: NumericColumn = null;
    columnCow: NumericColumn = null;
    columnRedline: NumericColumn = null;
    columnLun: NumericColumn = null;
    columnNonCommissionable : CheckListColumn = null;
    columnConstrainedActivitiesCount: NumericColumn = null;
    columnFlangeMgtTotal: NumericColumn = null;
    columnFlangeMgtTotalCompleted: NumericColumn = null;
    columnFlangeMgtTotalRemaining: NumericColumn = null;
    columnFlangeMgtTotalPercentage: NumericColumn = null;
    columnACTsRemaining: NumericColumn = null;
}

export class ShowHideColumns {
    subsysName = true;
    rfsuPlanDate = true;
    rfsuWaldownPlanDate = false;
    rfsuForecastDate = true;
    rfoPlan = false;
    rfsuActualDate = true;
    rfsuCommitmentDate = false;
    mcPlanDate = false;
    mcForecastDate = false;
    mcActualDate = false;
    showSafetyRewalkdown = false;
    rfo = false;
    waypoint = false;
    milestone = false;
    systemOwner = false;
    walkdownForecast = true;
    walkdownScheduled = false;
    walkdownActual = true;
    showFPLUpload = false;
    showBITRTotalAndComplete = false;
    showCITRTotalAndComplete = false;
    BITR = true;
    CITR = true;
    ACT=true;
    NPW = true;
    LUN = false;
    AQVD = true;
    APLI = true;
    BPLI = true;
    CPLI = true;
    DPLI = true;
    PASCR = true;
    COW = false;
    EX = true;
    DE= true;
    redline = true;
    showPriorityName = false;
    showAreaBreakdown = false;
    showContractorNo = false;
    showAreaCompletionLead = false;
    showNonComm = false;
    showGoocNo = false;
    showBacklogCat = false;
    showEdossierInitiation = false;
    constrainedActivities = true;
    showEdossierStatus = false;

    showFlangeMgt = false;
}

export class BlueZoneDTO {
    subsystem: string;
    subsystemName: string;
    discipline: string;
    rfsuPlan: moment.Moment;
    RFOPlan: moment.Moment;
    rfsuWalkdownPlan: moment.Moment;
    rfsuForecast: moment.Moment;
    rfsuActual: moment.Moment;
    rfsuCommitment: moment.Moment;
    mcActual: moment.Moment;
    safetyRewalkdown: number;
    mcPlan: moment.Moment;
    mcForecast: moment.Moment;
    walkdownActual: moment.Moment;
    walkdownSch: moment.Moment;
    walkdownForecast: moment.Moment;
    fplUpload: moment.Moment;
    edossierInitiationDate: moment.Moment;
    aqvd: number;
    apli: number;
    npw: number;
    bitRsRemaining: number;
    bitRsTotal: number;
    bitRsCompleted: number;
    bitRsPercentage: number;
    citRsRemaining: number;
    citRsTotal: number;
    citRsCompleted: number;
    citRsPercentage: number;
    acTsRemaining: number;
    bpli: number;
    cpli: number;
    dpli: number;
    pascr: number;
    ex: number;
    cow: number;
    comment: string;
    isReadyForRFSU = false;
    redline: number;
    mentions: UserDetail[];
    lun: number;
    isLastInGroup = false;
    subsystemToDisciplineId: number;
    priorityName: string;
    areaBreakdown: string;
    contractorNo: string;
    areaCompletionLead: string;
    goocNo: string;
    isInEditMode = false;
    backlogCat: string;
    prevBacklogCat: string;
    blueZoneFlag: string;
    constrainedActivitiesCount: number;
    walkdownCommitted: boolean;
    rfo: string;
    waypoint: string;
    milestone: string;
    systemOwner:string;
    
    flangeMgtTotal: number;
    flangeMgtTotalCompleted: number;
    flangeMgtTotalRemaining: number;
    flangeMgtTotalPercentage: number;
    lastActivityFinishDate: moment.Moment;
}
