import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { PopupSettings } from 'src/app/models/popup-settings';
import { PopupService } from 'src/app/services/shared/popup.service';
import { OrderDirection } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import { TraceHeatingStatusDataRequest, TraceHeatingStatusDownloadAttachmentRequest, TraceHeatingStatusFilterUpdate } from 'src/app/store/trace-heating-status/action';
import { THSAttachmentDto, TraceHeatingStatusDto, TraceHeatingStatusFilter } from 'src/app/store/trace-heating-status/model';
import { MultipleAttachmentsComponent } from '../multiple-attachments/multiple-attachments.component';

@Component({
  selector: 'app-trace-heating-status-table',
  templateUrl: './trace-heating-status-table.component.html',
  styleUrls: ['./trace-heating-status-table.component.scss']
})
export class TraceHeatingStatusTableComponent extends BaseComponent implements OnInit {
  @Input() printMode: boolean = false;
  @Input() dataSource: MatTableDataSource<TraceHeatingStatusDto>;

  orderBy$ = this.store.select((state) => state.traceHeatingStatusState.traceHeatingStatusFilter.orderBy);
  traceHeatingStatusGridLoader$ = this.store.select((state) => state.traceHeatingStatusState.isLoading);

  displayedColumns: string[] = [
    'tdbSubsystem',
    'tdbMcDate',
    'tdbCircuit',
    'tdbHeatTracingTape',
    'processSubsystem',
    'processMcDate',
    'attachments'
  ];
  orderBy: OrderDirection;
  isLoading: boolean = false;
  

  constructor(
      private store: Store<ApplicationState>,
      private popupService: PopupService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.traceHeatingStatusGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.orderBy$.pipe(takeWhile(() => this.isAlive)).subscribe((orderBy) => (this.orderBy = orderBy));

  }

  changeOrderDirection() {
    if (this.orderBy === OrderDirection.Asc) {
        this.orderBy = OrderDirection.Desc;
    } else {
        this.orderBy = OrderDirection.Asc;
    }
    this.store.dispatch(
        new TraceHeatingStatusFilterUpdate({ orderBy: this.orderBy } as TraceHeatingStatusFilter)
    );
    this.store.dispatch(new TraceHeatingStatusDataRequest());
  }

  getTdbFlagColorClassName(date?: Date) {
    return { [`${date === null ? 'red' : 'green'}-flag`]: true, flag: true };
  }

  openAttachmentsPopup(element: TraceHeatingStatusDto) {
    if (element.attachments === null) {
        return;
    } else {
        this.popupService.openPopup(
            new PopupSettings(
                MultipleAttachmentsComponent,
                null,
                null,
                { multiAttach: element.attachments },
                200,
                200
            )
        );
    }
  }

  download(attachment: THSAttachmentDto) {
    this.store.dispatch(new TraceHeatingStatusDownloadAttachmentRequest([attachment], 0))
  }
}
