import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { ScheduleActivityDeletePagination } from './model';

export enum ScheduleActivityActionTypes {
    ScheduleActivityUploadLogRequest = '[Schedule Activity] Upload Log Request',
    ScheduleActivityUploadLogSuccess = '[Schedule Activity] Upload Log Success',
    ScheduleActivityUploadLogError = '[Schedule Activity] Upload Log Error',
    ScheduleActivityDeleteRecordsRequest = '[Schedule Activity] Delete Records Request',
    ScheduleActivityDeleteRecordsSuccess = '[Schedule Activity] Delete Records Success',
    ScheduleActivityDeleteRecordsError = '[Schedule Activity] Delete Records Error',
    ScheduleActivitySetStatusAndStartDate = '[Schedule Activity] Set Status and Start Date',
    ScheduleActivityDeleteFilterPropertyUpdate = '[Schedule Activity] Delete Filter Property Update',
    ScheduleActivityDeleteFilterReset = '[Schedule Activity] Delete Filter Reset',
    ScheduleActivityPatchDeleteRecordRequest = '[Schedule Activity] Patch Delete Record Request',
    ScheduleActivityPatchDeleteRecordSuccess = '[Schedule Activity] Patch Delete Record Success',
    ScheduleActivityPatchDeleteRecordError = '[Schedule Activity] Patch Delete Record Error',
    ScheduleActivityPatchAllDeleteRecordsRequest = '[Schedule Activity] Patch All Delete Records Request',
    ScheduleActivityPatchAllDeleteRecordsSuccess = '[Schedule Activity] Patch All Delete Records Success',
    ScheduleActivityPatchAllDeleteRecordsError = '[Schedule Activity] Patch All Delete Records Error',
    ScheduleActivityValidateDataRequest = '[Schedule Activity] Validate Data Request',
    ScheduleActivityValidateDataSuccess = '[Schedule Activity] Validate Data Success',
    ScheduleActivityValidateDataError = '[Schedule Activity] Validate Data Error',
    ScheduleActivityFileUploaded = '[Schedule Activity] File Uploaded',
    ScheduleActivityClearInProgressSpinner = '[Schedule Activity] Clear In Progress Spinner',
    ScheduleActivityTemplateFileRequest = '[Schedule Activity] Template File Request',
    ScheduleActivityTemplateFileSuccess = '[Schedule Activity] Template File Success',
    ScheduleActivityTemplateFileError = '[Schedule Activity] Template File Error',
    ScheduleActivityDownloadOutputDataRequest = '[Schedule Activity] Download Output Data Request',
    ScheduleActivityDownloadOutputDataSuccess = '[Schedule Activity] Download Output Data Success',
    ScheduleActivityDownloadOutputDataError = '[Schedule Activity] Download Output Data Error',
    ScheduleActivityValidateButtonStateRequest = '[Schedule Activity] Validate Button State Request',
    ScheduleActivityValidateButtonStateSuccess = '[Schedule Activity] Validate Button State Success',
    ScheduleActivityValidateButtonStateError = '[Schedule Activity] Validate Button State Error',
    ScheduleActivityAddStartedLog = '[Schedule Activity] Add Started Log',
}

export class ScheduleActivityAddStartedLog implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityAddStartedLog;
}

export class ScheduleActivityUploadLogRequest implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityUploadLogRequest;
}

export class ScheduleActivityUploadLogSuccess implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ScheduleActivityUploadLogError implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityUploadLogError;

    constructor(public payload: string) {}
}

export class ScheduleActivityDeleteRequest implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityDeleteRecordsRequest;
}

export class ScheduleActivityDeleteSuccess implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityDeleteRecordsSuccess;

    constructor(public payload: ScheduleActivityDeletePagination) {}
}

export class ScheduleActivityDeleteError implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ScheduleActivitySetStatusAndStartDate implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivitySetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class ScheduleActivityDeleteFilterPropertyUpdate implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ScheduleActivityDeleteFilterReset implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityDeleteFilterReset;
}

export class ScheduleActivityPatchDeleteRequest implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityPatchDeleteRecordRequest;

    constructor(public payload: { activityId: string; activityDiscipline: string; deleteState: boolean }) {}
}

export class ScheduleActivityPatchAllDeleteRequest implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ScheduleActivityPatchDeleteSuccess implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityPatchDeleteRecordSuccess;
}

export class ScheduleActivityPatchAllDeleteSuccess implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ScheduleActivityPatchDeleteError implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class ScheduleActivityPatchAllDeleteError implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ScheduleActivityValidateDeleteRequest implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityValidateDataRequest;
}

export class ScheduleActivityValidateDeleteSuccess implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityValidateDataSuccess;
}

export class ScheduleActivityValidateDeleteError implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityValidateDataError;

    constructor(public payload: string) {}
}

export class ScheduleActivityFileUploaded implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityFileUploaded;
}

export class ScheduleActivityClearInProgressSpinner implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityClearInProgressSpinner;
}

export class ScheduleActivityTemplateFileRequest implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ScheduleActivityTemplateFileSuccess implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ScheduleActivityTemplateFileError implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityTemplateFileError;

    constructor(public payload: string) {}
}

export class ScheduleActivityDownloadOutputDataRequest implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ScheduleActivityDownloadOutputDataSuccess implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ScheduleActivityDownloadOutputDataError implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ScheduleActivityValidateButtonStateRequest implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityValidateButtonStateRequest;
}

export class ScheduleActivityValidateButtonStateSuccess implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class ScheduleActivityValidateButtonStateError implements Action {
    readonly type = ScheduleActivityActionTypes.ScheduleActivityValidateButtonStateError;

    constructor(public payload: string) {}
}

export type ScheduleActivityActions =
    | ScheduleActivityUploadLogRequest
    | ScheduleActivityUploadLogSuccess
    | ScheduleActivityUploadLogError
    | ScheduleActivityDeleteRequest
    | ScheduleActivityDeleteSuccess
    | ScheduleActivityDeleteError
    | ScheduleActivitySetStatusAndStartDate
    | ScheduleActivityDeleteFilterPropertyUpdate
    | ScheduleActivityDeleteFilterReset
    | ScheduleActivityPatchDeleteRequest
    | ScheduleActivityPatchDeleteSuccess
    | ScheduleActivityPatchDeleteError
    | ScheduleActivityPatchAllDeleteRequest
    | ScheduleActivityPatchAllDeleteSuccess
    | ScheduleActivityPatchAllDeleteError
    | ScheduleActivityValidateDeleteRequest
    | ScheduleActivityValidateDeleteSuccess
    | ScheduleActivityValidateDeleteError
    | ScheduleActivityFileUploaded
    | ScheduleActivityClearInProgressSpinner
    | ScheduleActivityTemplateFileRequest
    | ScheduleActivityTemplateFileSuccess
    | ScheduleActivityTemplateFileError
    | ScheduleActivityDownloadOutputDataRequest
    | ScheduleActivityDownloadOutputDataSuccess
    | ScheduleActivityDownloadOutputDataError
    | ScheduleActivityValidateButtonStateRequest
    | ScheduleActivityValidateButtonStateSuccess
    | ScheduleActivityValidateButtonStateError
    | ScheduleActivityAddStartedLog;
