import { Action } from '@ngrx/store';
import { WorkAreaDTO } from '../../common.model';
import {
    CreateActivityDTO,
    ScheduleActivityPlanningDTO,
    ScheduleActivityPlanningFilter,
    ShowHideColumns,
    SubsystemWithScheduleActivitiesDTO,
} from './model';
import {
    ScheduleActivityUpdateDto,
    ScheduleActivityPropertyUpdateDto,
    ScheduleActivityAddCommentDto,
} from '../../../services/api/webapi-services/schedule-activity-planning.service';
import { WeeklyPlanType } from 'src/app/enums';
import * as moment from 'moment';
import { WeekSummaryDto } from '../model';
import { CommentDetailDTO } from '../../detailed-status/model';

export enum ScheduleActivityPlanningActionTypes {
    PlanningDataRequest = '[Schedule Activity] Requesting Schedule Activities',
    PlanningDataRequestSuccess = '[Schedule Activity] Request for Schedule Activities data Success',
    PlanningDataRequestError = '[Schedule Activity] Request for Schedule Activities data Error',

    NotInWeekPlanningDataRequest = '[Schedule Activity] Requesting Not In Week Schedule Activities',
    NotInWeekPlanningDataRequestSuccess = '[Schedule Activity] Request for Not In Week Schedule Activities data Success',
    NotInWeekPlanningDataRequestError = '[Schedule Activity] Request for Not In Week Schedule Activities data Error',

    ActivityUpdate = '[Schedule Activity] Updating an Activity',
    ActivityUpdateSuccess = '[Schedule Activity] Updating an Activity Success',
    ActivityUpdateError = '[Schedule Activity] Updating an Activity Error',

    ActivityNotInWeekUpdate = '[Schedule Activity] Updating Not In Week Activity',
    ActivityNotInWeekUpdateSuccess = '[Schedule Activity] Updating Not In Week Activity Success',
    ActivityNotInWeekUpdateError = '[Schedule Activity] Updating Not In Week Activity Error',

    FilterPropertyUpdate = '[Schedule Activity] Filter property update',
    PaginationUpdate = '[Schedule Activity] Pagination for Subsystem Not In Week was set',
    ShowHideColumnsUpdate = '[Schedule Activity] Show Hide Columns for Subsystem Not In Week was set',
    FillFilterPropertiesBasedonAreaRequest = '[Schedule Activity] Fill Filter Properties Based On Area Request',
    FillFilterPropertiesBasedonAreaSuccess = '[Schedule Activity] Fill Filter Properties Based On Area Success',
    FillFilterPropertiesBasedonAreaError = '[Schedule Activity] Fill Filter Properties Based On Area Error',

    PropertyUpdate = '[Schedule Activity] Activity Property Update Request',
    PropertyUpdateSuccess = '[Schedule Activity] Activity Property Update Success',
    PropertyUpdateError = '[Schedule Activity] Activity Property Update Error',

    NotInWeekPropertyUpdate = '[Schedule Activity] Not In Week Activity Property Update Request',
    NotInWeekPropertyUpdateSuccess = '[Schedule Activity] Not In Week Activity Property Update Success',
    NotInWeekPropertyUpdateError = '[Schedule Activity] Not In Week Activity Property Update Error',

    ExportToExcelRequest = '[Schedule Activity] Export to Excel Request',
    ExportToExcelSuccess = '[Schedule Activity] Export to Excel Success',
    ExportToExcelError = '[Schedule Activity] Export to Excel Error',

    InferDisciplines = '[Schedule Activity] Inferring Discipline Filter based on current state',
    ClearFilters = '[Schedule Activity] Clearing all filters',
    ITRsSubmit = '[Schedule Activity] Submitting Selected ITRs',
    ITRsSubmitSuccess = '[Schedule Activity] Submitting Selected ITRs Success',
    ITRsSubmitError = '[Schedule Activity] Submitting Selected ITRs Error',
    ExpansionNotInWeekPanelToggle = '[Schedule Activity] Expansion Not In Week Panel Toggle',
    ExpansionInWeekPanelToggle = '[Schedule Activity] Expansion In Week Panel Toggle',
    SetMultiPanels = '[Schedule Activity] Set Multi Panels',

    CreateNewActivityRequest = '[Schedule Activity] Create New Activity Request',
    CreateNewActivitySuccess = '[Schedule Activity] Create New Activity Success',
    CreateNewActivityError = '[Schedule Activity] Create New Activity Error',

    ResetITRFilter = '[Schedule Activity] Reset ITR Filter',

    SetSubsystemsUpdate = '[Schedule Activity] Set subsystems Update',
    SetSubsystemsUpdateSuccess = '[Schedule Activity] Set subsystems Update Success',
    SetSubsystemsUpdateError = '[Schedule Activity] Set subsystems Update Error',

    ResetPrint = '[Schedule Activity] Reset Print',

    WeekSummaryDataRequest = '[Schedule Activity] Week Summary Request',
    WeekSummaryDataRequestSuccess = '[Schedule Activity]Week Summary Success',
    WeekSummaryDataRequestError = '[Schedule Activity] Week Summary Error',

    AddCommentRequest = '[Schedule Activity] Add Comment Request',
    AddCommentSuccess = '[Schedule Activity] Add Comment Success',
    AddCommentError = '[Schedule Activity] Add Comment Error',

    RemoveCommentRequest = '[Schedule Activity] Remove Comment Request',
    RemoveCommentSuccess = '[Schedule Activity] Remove Comment Success',
    RemoveCommentError = '[Schedule Activity] Remove Comment Error',

    UpdateScrollTop = '[Schedule Activity] UpdateScrollTop',
}

export class ScheduleActivityPlanningDataRequest implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.PlanningDataRequest;

    constructor(public payload = false) {
        // This is intentional
    }
}

export class ScheduleActivityPlanningDataSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.PlanningDataRequestSuccess;

    constructor(
        public payload: {
            items: SubsystemWithScheduleActivitiesDTO[];
            totalCount: number;
            printMode: boolean;
            isAllSubmitted: boolean;
        }
    ) {
        // This is intentional
    }
}

export class ScheduleActivityPlanningDataError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.PlanningDataRequestError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class ScheduleActivityNotInWeekPlanningDataRequest implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.NotInWeekPlanningDataRequest;

    constructor(public payload = false) {
        // This is intentional
    }
}

export class ScheduleActivityNotInWeekPlanningDataSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.NotInWeekPlanningDataRequestSuccess;

    constructor(
        public payload: {
            items: SubsystemWithScheduleActivitiesDTO[];
            totalCount: number;
            isAllSubmitted: boolean;
            printMode: boolean;
        }
    ) {
        // This is intentional
    }
}

export class ScheduleActivityNotInWeekPlanningDataError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.NotInWeekPlanningDataRequestError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class ScheduleActivityPlanningResetITRFilter implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ResetITRFilter;
}

export class ScheduleActivityPlanningFilterPropertyUpdate implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.FilterPropertyUpdate;

    constructor(public payload: { filter: ScheduleActivityPlanningFilter }) {
        // This is intentional
    }
}

export class ScheduleActivityNotInWeekPlanningPaginationUpdate implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.PaginationUpdate;

    constructor(public payload: { take: number; page: number }) {
        // This is intentional
    }
}
export class ScheduleActivityNotInWeekPlanningShowHideColumnsUpdate implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ShowHideColumnsUpdate;

    constructor(public payload: { showHideColumns: ShowHideColumns }) {
        // This is intentional
    }
}

export class ScheduleActivityFillFilterPropertiesBasedonAreaRequest implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.FillFilterPropertiesBasedonAreaRequest;

    constructor(public payload: { areaName: string }) {
        // This is intentional
    }
}

export class ScheduleActivityFillFilterPropertiesBasedonAreaSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.FillFilterPropertiesBasedonAreaSuccess;

    constructor(public payload: { area: WorkAreaDTO }) {
        // This is intentional
    }
}

export class ScheduleActivityFillFilterPropertiesBasedonAreaError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.FillFilterPropertiesBasedonAreaError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class ScheduleActivityPropertyUpdate implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.PropertyUpdate;

    constructor(public payload: ScheduleActivityPropertyUpdateDto, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityPropertyUpdateSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.PropertyUpdateSuccess;

    constructor(public payload: ScheduleActivityPropertyUpdateDto, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityPropertyUpdateError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.PropertyUpdateError;

    constructor(param: { error }, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityNotInWeekPropertyUpdate implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.NotInWeekPropertyUpdate;

    constructor(public payload: ScheduleActivityPropertyUpdateDto, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityNotInWeekPropertyUpdateSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.NotInWeekPropertyUpdateSuccess;

    constructor(public payload: ScheduleActivityPropertyUpdateDto, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityNotInWeekPropertyUpdateError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.NotInWeekPropertyUpdateError;

    constructor(param: { error }, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityAddCommentRequest implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.AddCommentRequest;

    constructor(public payload: ScheduleActivityAddCommentDto) {
        // This is intentional
    }
}

export class ScheduleActivityAddCommentSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.AddCommentSuccess;

    constructor(public payload: CommentDetailDTO) {
        // This is intentional
    }
}

export class ScheduleActivityAddCommentError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.AddCommentError;

    constructor(param: { error }) {
        // This is intentional
    }
}

export class ScheduleActivityRemoveCommentRequest implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.RemoveCommentRequest;

    constructor(public payload: { id: number; subsystem: string; activityId: string; activityDiscipline: string; lastComment: CommentDetailDTO }) {
        // This is intentional
    }
}

export class ScheduleActivityRemoveCommentSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.RemoveCommentSuccess;

    constructor(public payload: { id: number; subsystem: string; activityId: string; activityDiscipline: string; lastComment: CommentDetailDTO }) {
        // This is intentional
    }
}

export class ScheduleActivityRemoveCommentError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.RemoveCommentError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class CreateNewActivityRequest implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.CreateNewActivityRequest;

    constructor(public payload: CreateActivityDTO) {
        // This is intentional
    }
}

export class CreateNewActivitySuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.CreateNewActivitySuccess;

    constructor(public payload: ScheduleActivityPlanningDTO, public subsystem: string) {
        // This is intentional
    }
}

export class CreateNewActivityError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.CreateNewActivityError;

    constructor(param: { error }, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityUpdate implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ActivityUpdate;

    constructor(public payload: ScheduleActivityUpdateDto, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityUpdateSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ActivityUpdateSuccess;

    constructor(public payload: ScheduleActivityUpdateDto, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityUpdateError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ActivityUpdateError;

    constructor(param: { error }, public subsystem: string) {
        // This is intentional
    }
}
export class ScheduleActivityNotInWeekUpdate implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ActivityNotInWeekUpdate;

    constructor(public payload: ScheduleActivityUpdateDto, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityNotInWeekUpdateSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ActivityNotInWeekUpdateSuccess;

    constructor(public payload: ScheduleActivityUpdateDto, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityNotInWeekUpdateError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ActivityNotInWeekUpdateError;

    constructor(param: { error }, public subsystem: string) {
        // This is intentional
    }
}

export class ScheduleActivityExportToExcelRequest implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ExportToExcelRequest;

    constructor(public payload: WeeklyPlanType) {
        // This is intentional
    }
}

export class ScheduleActivityExportToExcelSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ExportToExcelSuccess;

    constructor(public payload: any) {
        // This is intentional
    }
}

export class ScheduleActivityExportToExcelError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ExportToExcelError;

    constructor(public payload: string) {
        // This is intentional
    }
}

export class ScheduleActivityInferFilterDisciplines implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.InferDisciplines;
}

export class ScheduleActivityClearFilters implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ClearFilters;
}

export class ScheduleActivityITRsSubmit implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ITRsSubmit;
}

export class ScheduleActivityITRsSubmitSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ITRsSubmitSuccess;

    constructor(public payload: any) {
        // This is intentional
    }
}

export class ScheduleActivityITRsSubmitError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ITRsSubmitError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}
export class ScheduleActivityExpansionNotInWeekPanelToggled implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ExpansionNotInWeekPanelToggle;

    constructor(public notInWeekExpanded: boolean) {
        // This is intentional
    }
}

export class ScheduleActivityExpansionInWeekPanelToggled implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ExpansionInWeekPanelToggle;

    constructor(public inWeekExpanded: boolean) {
        // This is intentional
    }
}

export class ScheduleActivityMultiPanels implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.SetMultiPanels;

    constructor(public multiPanels: boolean ) {
        // This is intentional
    }
}

export class ScheduleActivitySetSubsytemsUpdate implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.SetSubsystemsUpdate;
    constructor(public payload: { weekStart: moment.Moment; subsystems: any[] }) {
        // This is intentional
    }
}

export class ScheduleActivitySetSubsytemsUpdateSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.SetSubsystemsUpdateSuccess;
}

export class ScheduleActivitySetSubsytemsUpdateError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.SetSubsystemsUpdateError;
    constructor(param: { error }) {
        // This is intentional
    }
}

export class ScheduleActivityResetPrint implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.ResetPrint;
}

export class ScheduleActivityWeekSummaryDataRequest implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.WeekSummaryDataRequest;

    constructor() {
        // This is intentional
    }
}

export class ScheduleActivityWeekSummaryDataSuccess implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.WeekSummaryDataRequestSuccess;

    constructor(public weekSummary: WeekSummaryDto) {
        // This is intentional
    }
}

export class ScheduleActivityWeekSummaryDataError implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.WeekSummaryDataRequestError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class ScheduleActivityUpdateScrollTop implements Action {
    readonly type = ScheduleActivityPlanningActionTypes.UpdateScrollTop;

    constructor(public payload: number) {
        // This is intentional
    }
}

export type ScheduleActivityPlanningActions =
    | ScheduleActivityPlanningDataRequest
    | ScheduleActivityPlanningDataSuccess
    | ScheduleActivityPlanningDataError
    | ScheduleActivityNotInWeekPlanningDataRequest
    | ScheduleActivityNotInWeekPlanningDataSuccess
    | ScheduleActivityNotInWeekPlanningDataError
    | ScheduleActivityPlanningFilterPropertyUpdate
    | ScheduleActivityNotInWeekPlanningPaginationUpdate
    | ScheduleActivityNotInWeekPlanningShowHideColumnsUpdate
    | ScheduleActivityFillFilterPropertiesBasedonAreaRequest
    | ScheduleActivityFillFilterPropertiesBasedonAreaSuccess
    | ScheduleActivityFillFilterPropertiesBasedonAreaError
    | ScheduleActivityPropertyUpdate
    | ScheduleActivityPropertyUpdateSuccess
    | ScheduleActivityPropertyUpdateError
    | ScheduleActivityNotInWeekPropertyUpdate
    | ScheduleActivityNotInWeekPropertyUpdateSuccess
    | ScheduleActivityNotInWeekPropertyUpdateError
    | ScheduleActivityUpdate
    | ScheduleActivityUpdateSuccess
    | ScheduleActivityUpdateError
    | ScheduleActivityNotInWeekUpdate
    | ScheduleActivityNotInWeekUpdateSuccess
    | ScheduleActivityNotInWeekUpdateError
    | ScheduleActivityExportToExcelRequest
    | ScheduleActivityExportToExcelSuccess
    | ScheduleActivityExportToExcelError
    | ScheduleActivityInferFilterDisciplines
    | ScheduleActivityClearFilters
    | ScheduleActivityITRsSubmit
    | ScheduleActivityITRsSubmitSuccess
    | ScheduleActivityITRsSubmitError
    | ScheduleActivityExpansionNotInWeekPanelToggled
    | ScheduleActivityExpansionInWeekPanelToggled
    | ScheduleActivityMultiPanels
    | CreateNewActivityRequest
    | CreateNewActivitySuccess
    | CreateNewActivityError
    | ScheduleActivityPlanningResetITRFilter
    | ScheduleActivitySetSubsytemsUpdate
    | ScheduleActivitySetSubsytemsUpdateSuccess
    | ScheduleActivitySetSubsytemsUpdateError
    | ScheduleActivityResetPrint
    | ScheduleActivityWeekSummaryDataRequest
    | ScheduleActivityWeekSummaryDataSuccess
    | ScheduleActivityWeekSummaryDataError
    | ScheduleActivityAddCommentRequest
    | ScheduleActivityAddCommentSuccess
    | ScheduleActivityAddCommentError
    | ScheduleActivityRemoveCommentRequest
    | ScheduleActivityRemoveCommentSuccess
    | ScheduleActivityRemoveCommentError
    | ScheduleActivityUpdateScrollTop;
