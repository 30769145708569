import * as moment from 'moment';
import {
    TotalCompleteDataDTO,
    MilestoneDashboardFilter,

} from '../reports/milestone-dashboard/model';
import { WeeklyPlanningPastTopHeaderDTO } from '../weekly-planning/model';

export interface HomeState {
    systemCompletionData: TotalCompleteDataDTO[];
    isLoadingSystemCompletionComplete: boolean;
    filterSystemCompletion: MilestoneDashboardFilter;
    npwData: TotalCompleteDataDTO[];
    isLoadingNPWComplete: boolean;
    filterNPW: MilestoneDashboardFilter;
}

export class HomeCommitment {
    week: WeeklyPlanningPastTopHeaderDTO;
    weekPercentage: number;
    weekEndDate: moment.Moment;
}
