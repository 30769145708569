<form [formGroup]="filterForm">
    <mat-accordion class="filter-container">
        <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" expanded>
            <mat-expansion-panel-header collapsedHeight="auto">
                <div class="filter-header-container">
                    <div>
                        <span class="filter-header-button">
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 0H1C0.45 0 0 0.449679 0 0.999286C0 1.27909 0.11 1.52891 0.29 1.70878L5 6.41542V13.0007C5 13.5503 5.45 14 6 14C6.28 14 6.53 13.8901 6.71 13.7102L8.71 11.7116C8.89 11.5318 9 11.2819 9 11.0021V6.41542L13.71 1.70878C13.89 1.52891 14 1.27909 14 0.999286C14 0.449679 13.55 0 13 0Z"
                                    fill="#0066B2"
                                />
                            </svg>
                            filter by
                        </span>
                        <div *ngIf="!filterExpansionPanel.expanded" class="active-filters-container">
                            <mat-chip-list aria-label="Active filters">
                                <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                    Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('projectTeamNames')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                    Contractors: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('contractors')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                    Disciplines: {{ filterForm.controls.disciplines.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('disciplines')"
                                        >cancel</mat-icon> 
                                </mat-basic-chip>

                                <mat-basic-chip *ngIf="filterForm.controls.rfos?.value.length" removable>
                                    RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('rfos')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.category?.value.length" removable>
                                    Category: {{ filterForm.controls.category.value.join(', ') }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('category')">
                                        cancel</mat-icon> 
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.rfsuCompleted">
                                    RFSU Completed:
                                    {{ displaySelectedRFSUCompleted(filterForm.controls.rfsuCompleted.value) }}
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.excludeExceptions?.value.length">
                                    Exclude Exceptions:
                                    {{ displayExcludeExceptions(filterForm.controls.excludeExceptions.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('excludeExceptions')">
                                        cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.constructionArea?.value.length" removable>
                                    Construction Area:
                                    {{ displayMultipleSelectedById(filterForm.controls.constructionArea.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('constructionArea')"
                                        >cancel
                                    </mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.scManager?.value.length" removable>
                                    SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.systemOwner?.value.length" removable>
                                    System Owner:
                                    {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.stagedStartUpPriorities?.value.length" removable>
                                    Priorities:
                                    {{ displaySelectedPriorities(filterForm.controls.stagedStartUpPriorities.value) }}
                                    <mat-icon
                                        matChipRemove
                                        (click)="clearFilterProperty('stagedStartUpPriorities')"
                                        >cancel</mat-icon
                                    >
                                </mat-basic-chip>
                                <mat-basic-chip>
                                    Week start: {{ filterForm.controls.weekStart.value | date: 'd/MMM/yy' }}
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                    Area Breakdown:
                                    {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                                </mat-basic-chip>
                                <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                                    RFSU Contractor:
                                    {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                                    <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                                </mat-basic-chip>
                            </mat-chip-list>
                        </div>
                    </div>

                    <span class="button-reset" (click)="clearFilters($event)">
                        Reset filters &times;
                    </span>
                </div>
            </mat-expansion-panel-header>

            <div class="mixedapp-container">

                <div class="container300 margin-right15">
                    <app-multiple-items-selector
                            class="container300"
                            [setInput]="setStagedStartUpPriorityInput"
                            formControlName="stagedStartUpPriorities"
                            [isAsync]="true"
                            [searchFunc]="getStagedStartUpPriorities"
                            [filterForm]="filterForm"
                            [formSetter]="'stagedStartUpPriorities'"
                            [propertyToShow]="'priorityName'"
                            [placeholder]="'Staged Start-up Priority'"
                            [secondPropertyToShow]="'priority'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['priority', 'priorityName']"
                        >
                        </app-multiple-items-selector>
                </div>

                <mat-form-field class="container300 margin-right15">
                    <mat-label>Project Team</mat-label>
                    <mat-select
                        formControlName="projectTeamNames"
                        multiple
                        (openedChange)="onProjectTeamsClosed($event)"
                    >
                        <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="container300 margin-right15">
                    <app-multiple-items-selector
                        class="container300 filter-control"
                        [setInput]="setContractorInput"
                        formControlName="contractors"
                        [searchFunc]="getContractorsMulti"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                        (autocompleteClosed)="onContractorsClosed()"
                        (itemRemoved)="onContractorsClosed()"
                        [filterForm]="filterForm"
                        [formSetter]="'contractors'"
                        [propertyToShow]="'contractNo'"
                        [placeholder]="'PLI Contractor'"
                        [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                        [secondPropertyToShow]="'contract'"
                    >
                    </app-multiple-items-selector>
                </div>

                <mat-form-field class="container300 margin-right15">
                    <mat-label>PLI Discipline</mat-label>
                    <mat-select formControlName="disciplines" multiple>
                        <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <div class="container300 margin-right15">
                    <app-multiple-items-selector
                        class="container300"
                        [setInput]="setRFOInput"
                        formControlName="rfos"
                        [searchFunc]="getRFOs"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'rfos'"
                        [propertyToShow]="'name'"
                        [placeholder]="'RFO'"
                        [secondPropertyToShow]="'rfoName'"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="['name', 'rfoName']"
                        [panelWidth]="480"
                    >
                    </app-multiple-items-selector>
                </div>

                <mat-form-field class="container300 margin-right15">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category" multiple>
                        <mat-option value="A">A</mat-option>
                        <mat-option value="B">B</mat-option>
                        <mat-option value="C">C</mat-option>
                        <mat-option value="D">D</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="container300 container-radiobuttons margin-right15">
                    <mat-label class="title-radiobuttons">RFSU Completed</mat-label>
                    <mat-radio-group formControlName="rfsuCompleted" aria-label="RFSU Completed">
                        <mat-radio-button [value]="true" class="margin-right40">Yes</mat-radio-button>
                        <mat-radio-button [value]="false" class="margin-right40">No</mat-radio-button>
                        <mat-radio-button [value]="null" class="margin-right40">All</mat-radio-button>
                    </mat-radio-group>
                </div>

                <mat-form-field class="container300 margin-right15">
                    <mat-label>Exclude Exceptions</mat-label>
                    <mat-select formControlName="excludeExceptions" multiple [compareWith]="compareExceptionTypes">
                        <mat-option *ngFor="let ex of RFexceptionTypes" [value]="ex.key">{{ ex.value }} </mat-option>
                    </mat-select>
                </mat-form-field>
               
                <div class="container300 margin-right15">
                    <app-multiple-items-selector
                        class="container300"
                        [setInput]="setConstructionAreaInput"
                        formControlName="constructionArea"
                        [searchFunc]="getConstructionArea"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'constructionArea'"
                        [placeholder]="'Construction Area'"
                    >
                    </app-multiple-items-selector>
                </div>

                <mat-form-field class="container300 margin-right15">
                    <mat-label>SC Execution Area Manager</mat-label>
                    <mat-select formControlName="scManager" multiple>
                        <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="container300 margin-right15">
                    <mat-label>System Owner</mat-label>
                    <mat-select formControlName="systemOwner" multiple>
                        <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <div class="container300 margin-right15">
                    <app-multiple-items-selector
                        class="container300"
                        [setInput]="setRFSUContractorsInput"
                        formControlName="rfsuContractors"
                        [searchFunc]="getRFSUContractors"
                        [isAsync]="true"
                        [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                        [filterForm]="filterForm"
                        [formSetter]="'rfsuContractors'"
                        [propertyToShow]="'contractNo'"
                        [placeholder]="'RFSU Contractor'"
                        [panelWidth]="480"
                        [secondPropertyToShow]="'contract'"
                    >
                    </app-multiple-items-selector>
                </div>

                <div class="container300 margin-right15">
                    <app-multiple-items-selector
                        class="container300"
                        [setInput]="setAreaBreakdownInput"
                        formControlName="areaBreakdown"
                        [searchFunc]="getAreaBreakdown"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'areaBreakdown'"
                        [placeholder]="'Area Breakdown'"
                    >
                    </app-multiple-items-selector>
                </div>

            </div>

            <div class="filter-container week-info">
                <mat-form-field appearance="standard" class="filter-control margin-right20">
                    <mat-label>Week start</mat-label>
                    <input
                        matInput
                        formControlName="weekStart"
                        [matDatepicker]="picker"
                        [matDatepickerFilter]="dateRangePickerFilter"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="standard" class="filter-control">
                    <mat-label>Week end</mat-label>
                    <input matInput disabled [value]="currentWeekEnd | date: 'd/MMM/yy'" />
                </mat-form-field>
                <div class="buttons-container">
                    <button
                        type="submit"
                        class="sct-button sct-button-light sct-search margin-right20"
                        (click)="search()"
                    >
                        show plan
                    </button>

                    <button
                        type="button"
                        (click)="clearFilters($event)"
                        class="sct-button sct-button-light margin-right20"
                    >
                        reset filters
                    </button>
                </div>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</form>
