import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'breakLine',
})
export class BreakLinePipe implements PipeTransform {
    transform(value: string, character: string, onlyfirst: boolean = false): string {
        let regex = new RegExp(character, 'g');
        if (value?.indexOf(character) > 0) {
            if (onlyfirst) {
                return value.replace(character, '\n');
            } else return value.replace(regex, '\n');
        } else return value;
    }
}
