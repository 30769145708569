<ng-container *cdkPortal>
    <div class="print-header">
        <h2 class="header-title">{{ getWorkAreas(filter) }}</h2>
        <p class="header-date">
            {{ filter?.weekStart | date: 'd/MMM/yy' }} - {{ getWeekEnd(filter) | date: 'd/MMM/yy' }}
        </p>
        <table>
            <tr>
                <th class="cell-title">Discipline Lead:</th>
                <td>{{ workersToList(filter?.nbDisciplineLeads) }}</td>
            </tr>
            <tr>
                <th class="cell-title">Engineer:</th>
                <td>{{ workersToList(filter?.nbEngineers) }}</td>
            </tr>
            <tr>
                <th class="cell-title">TCO Area Coordinator:</th>
                <td>{{ workersToList(filter?.tcoAreaCoordinators) }}</td>
            </tr>
        </table>
    </div>
    <h2 class="panel-title">Activities in Week</h2>
    <div class="activity" *ngFor="let element of printData$ | async">
        <div class="activity__header">
            <p>
                <span class="margin-right40 redirect-link">{{ element.subsystem }}: </span
                ><span (click)="table.showActivities = !table.showActivities">{{ element.subsystemName }}</span>
            </p>
        </div>
        <app-schedule-activity-planning-subsystem-table #table [subsystemWithActivities]="element" [printMode]="true">
        </app-schedule-activity-planning-subsystem-table>
    </div>

    <h2 class="panel-title">Activities not in Week</h2>
    <div class="activity" *ngFor="let element of notInWeekPrintData$ | async">
        <div class="activity__header">
            <p>
                <span class="margin-right40 redirect-link">{{ element.subsystem }}: </span
                ><span (click)="table.showActivities = !table.showActivities">{{ element.subsystemName }}</span>
            </p>
        </div>
        <app-schedule-activity-planning-subsystem-table #table [subsystemWithActivities]="element" [printMode]="true">
        </app-schedule-activity-planning-subsystem-table>
    </div>
    <ng-content></ng-content>
    <app-loading-indicator *ngIf="printIsLoading$ | async"></app-loading-indicator>
</ng-container>
