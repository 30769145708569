<main class="main">
    <a class="navigator" [routerLink]="['/weeklyplanning/dashboard']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            {{ updateTitle }}
        </div>
    </div>
    <form [formGroup]="scPriorityForm">
        <div class="filter-container">
            <div class="priority-container">
                <div class="subtitle">
                    Activity focus 
                </div>
                <div class="mixedapp-container">
                    <div class="container300 margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="priorityName"
                            [searchFunc]="getPriorities"
                            placeholder="Priority (High, Medium, Low)"
                            [oneItemMode]="true"
                            [isAsync]="true"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            required
                            [invalid]="
                                scPriorityForm.controls['priorityName'].invalid &&
                                scPriorityForm.controls['priorityName'].errors.required
                            "
                            [setInput]="setPriorityFormInput"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div class="mixedapp-container">
                    <div class="create-button-container">
                        <button
                            type="button"
                            [disabled]="!scPriorityForm.valid || (updatedProperties.length === 0 && isInEditMode)"
                            class="sct-button sct-button-light"
                            (click)="onConfirm()"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
            <div class="work-area-container">
                <div class="subtitle">
                    subsystem
                </div>
                <app-subsystem-selector
                    [setSelectedSubsystems]="scPriorityForm.controls.activityFocusSubsystems.value"
                    (selectedSubsystemsChanged)="setSubsystems($event)"
                    [setInput]="setSubsystemInput"
                ></app-subsystem-selector>
            </div>
        </div>
    </form>
    <app-loading-indicator *ngIf="loading"></app-loading-indicator>
</main>
