<div class="table-container">
    <div class="weeks-header-container">
        <div *ngFor="let pastWeek of pastWeeks" class="past-week">
            <div class="week-label">
                <span class="week-number">WK{{ pastWeek.week }}</span>
                <br />
                <span>{{ pastWeek.date | date: 'd MMM' }}</span>
            </div>
        </div>
        <div class="type-header"><span></span></div>
    </div>
    <div *ngIf="(grandTotals$ | async) && (grandTotals$ | async).length">
        <div class="grand-totals-container green">
            <div *ngFor="let total of grandTotals$ | async" class="totals">
                <div class="plan-totals">{{ total.greenCount }}</div>
            </div>
            <div class="total-label">Clean</div>
        </div>
        <div class="grand-totals-container red">
            <div *ngFor="let total of grandTotals$ | async" class="totals">
                <div class="plan-totals">{{ total.redCount }}</div>
            </div>
            <div class="total-label">Not Clean</div>
        </div>
        <div class="grand-totals-container">
            <div *ngFor="let total of grandTotals$ | async" class="totals">
                <div class="plan-totals">{{ total.percentage }} %</div>
            </div>
            <div class="total-label">Cleanliness %</div>
        </div>
    </div>
</div>
