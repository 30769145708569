import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, interval } from 'rxjs';
import { delay, startWith, switchMap, take, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { Constants } from 'src/app/constants';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import { PlanningUploadSetActivatedRoute } from 'src/app/store/planning-upload/actions';
import { ITRConstraintsContactsDownloadOutputDataRequest, ITRConstraintsContactsTemplateFileRequest, ITRConstraintsContactsUploadLogRequest } from 'src/app/store/planning-upload/itr-constraints-contacts/actions';

@Component({
  selector: 'app-itr-constraints-contacts',
  templateUrl: './itr-constraints-contacts.component.html',
  styleUrls: ['./itr-constraints-contacts.component.scss']
})
export class ConstraintsContactsComponent extends BaseComponent implements OnInit  {
    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];

    sortBy$ = this.store.select((state) => state.itrConstraintsContactsState.deleteFilter.sortBy);
    direction$ = this.store.select((state) => state.itrConstraintsContactsState.deleteFilter.direction);
    uploadLogData$ = this.store.select((store) => store.itrConstraintsContactsState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.itrConstraintsContactsState.isUploadLogLoading);
    isDeleteDataLoading$ = this.store.select((store) => store.itrConstraintsContactsState.isDeleteDataLoading);
    isLoading$ = this.store.select((store) => store.itrConstraintsContactsState.isLoading);
    interval$ = this.store.select((store) => store.planningUploadState.interval);
    intervalSub: Subscription = null;
    logSubscription$: Subscription;
    columnITRConstraintsContactsId$ = this.store.select((state) => state.itrConstraintsContactsState.deleteFilter.columnConstraintType);

    showOrder = true;
    sortBy: string;
    direction = OrderDirection.Desc;
    deleteValidationFilterForm: UntypedFormGroup;
    columnITRConstraintsContactsId: CheckListColumn = null;

    constructor(private store: Store<ApplicationState>,
        private activatedRoute: ActivatedRoute,
        private adminPanelService: AdminPanelService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService) {
        super();

    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new PlanningUploadSetActivatedRoute(urlSegments[0].path));
        });
        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new ITRConstraintsContactsUploadLogRequest());
            });
        });
        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData))
        );       
    }


    downloadTemplate(fileName: string) {
        this.store.dispatch(new ITRConstraintsContactsTemplateFileRequest(fileName));
    }

    downloadData(filePath: string) {
        this.store.dispatch(new ITRConstraintsContactsDownloadOutputDataRequest(filePath));
    }

    getImportHistoryData(withDelay: boolean = false) {
        if (this.logSubscription$) {
            this.logSubscription$.unsubscribe();
        }
        let logsInterval = interval(Constants.logRefreshInterval).pipe(
            startWith(0),
            takeWhile(() => this.isAlive),
            delay(+withDelay * Constants.delayAfterUploadInterval),
            switchMap((_) => {
                return this.adminPanelService.getLogs(["ConstraintContacts"]);
            })
        );
        this.logSubscription$ = logsInterval.pipe(takeWhile(() => this.isAlive)).subscribe(
            (data) => {
                this.uploadLogData = new MatTableDataSource(data);
                this.changeDetectorRef.detectChanges();
            },
            () => {
                this.toastService.Error('Error occurred while getting logs. Please contact Program Administrator.');
            }
        );
    }

}
