import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from 'src/app/app.config';
import { BaseComponent } from 'src/app/components/base.component';
import { DcnRfiRedlineRegisterFilter, DcnRfiRegisterDetails, DcnRfiRegisterResultDto, RedlineRegisterDetails, RedlineRegisterResultDto, Workpacks, RedlineBaseDetails, WorkflowStatusUpdate } from 'src/app/store/dcn-rfi-redline/model';

@Injectable({
  providedIn: 'root'
})
export class DcnRfiRedlineRegisterService extends BaseComponent {
  private readonly rootUrl = appConfig.apiEndpoint;
  private readonly endpoint = '/dcnRfiRedlineRegister/';
  
  constructor(private http: HttpClient) {
    super();
  }

  private static getParams(search: string, take: number, page: number) {
    return new HttpParams()
        .append('page', page.toString())
        .append('take', take.toString())
        .append('search', search);
  }

  searchDcnRfiNumbers(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}dcnrfi/numbers`, { params });
  }

  searchRfiNumbers(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}rfi/numbers`, { params });
  }

  getDisciplines() {
    return this.http.get(`${this.rootUrl + this.endpoint}disciplines`);
  }

  searchDcnRfiImpactedSubsystems(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}dcnrfi/impactedsubsystems`, { params });
  }

  getDcnRfiEngineeringStatus() {
    return this.http.get(`${this.rootUrl + this.endpoint}dcnrfi/engineeringstatus`);
  }

  searchDcnRfiTitles(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}dcnrfi/titles`, { params });
  }

  searchDcnRfiContent(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}dcnrfi/content`, { params });
  }

  getImplementationStatuses() {
    return this.http.get(`${this.rootUrl + this.endpoint}dcnrfi/implementationstatuses`);
  }

  getWorkpackStatuses() {
    return this.http.get(`${this.rootUrl + this.endpoint}dcnrfi/workpackstatuses`);
  }
  
  searchDcnRfiWorkpackNumbers(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}dcnrfi/workpacknumbers`, { params });
  }

  searchWorkArea(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}workareas`, { params });
  }

  searchRedlineNumbers(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}redline/numbers`, { params });
  }

  searchRedlineSubsystems(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}redline/subsystems`, { params });
  }

  searchRedlineTitles(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}redline/titles`, { params });
  }

  searchRedlineNbEngineers(search: string, take: number, page: number) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.get(`${this.rootUrl + this.endpoint}redline/nbEngineer`, { params });
  }

  getRedlineRlmuStatuses() {
    return this.http.get(`${this.rootUrl + this.endpoint}redline/rlmustatuses`);
  }

  getRedlineStatuses() {
    return this.http.get(`${this.rootUrl + this.endpoint}redline/statuses`);
  }

  getRedlineRequiredFor() {
    return this.http.get(`${this.rootUrl + this.endpoint}redline/requiredFor`);
  }

  searchDcnRfiRegister(filter: DcnRfiRedlineRegisterFilter) {
    return this.http.post<DcnRfiRegisterResultDto>(`${this.rootUrl + this.endpoint}dcnrfi/search`, filter);
  }

  searchRedlineRegister(filter: DcnRfiRedlineRegisterFilter) {
    return this.http.post<RedlineRegisterResultDto>(`${this.rootUrl + this.endpoint}redline/search`, filter);
  }

  getDcnRfiRegisterDetails(id: number) {
    return this.http.get<DcnRfiRegisterDetails>(`${this.rootUrl + this.endpoint}dcnrfi/${id}`, );
  }

  getRedlineRegisterDetails(id: number) {
    return this.http.get<RedlineRegisterDetails>(`${this.rootUrl + this.endpoint}redline/${id}`, );
  }

  addWorkpack(workpack: Workpacks) {
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/workpack`, workpack);
  }

  addRedline(redline: RedlineBaseDetails) {
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/redline`, redline);
  }

  generateExcel(filter: DcnRfiRedlineRegisterFilter) {
    return this.http.post(`${this.rootUrl}${this.endpoint}generateexcel`, filter, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Disposition',
        }),
        responseType: 'blob' as 'json',
    });
  }

  updateWorkflowStatus(workflowStatus: WorkflowStatusUpdate) {
    return this.http.put(`${this.rootUrl + this.endpoint}dcnrfi/workflowstatus`, workflowStatus);
  }

  updateWorkpack(workpack: Workpacks) {
    return this.http.put(`${this.rootUrl + this.endpoint}dcnrfi/workpack`, workpack);
  }

  updateRlmuStatus(redlineBaseDetails: RedlineBaseDetails) {
    return this.http.put(`${this.rootUrl + this.endpoint}dcnrfi/rlmustatus`, redlineBaseDetails);
  }

  deleteWorkpack(changeDocId: number, workpackNumber: string) {
    return this.http.delete(`${this.rootUrl + this.endpoint}dcnrfi/${changeDocId}/workpack/${workpackNumber}`);
  }

  searchDcnRfiDisciplinesWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/disciplines`, filter, { params });
  }

  searchDcnRfiNumbersWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/numbers`, filter, { params });
  }
  
  searchDcnRfiTitlesWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/titles`, filter, { params });
  }

  searchDcnRfiSubsystemsWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/subsystems`, filter, { params });
  }

  searchDcnRfiEngineeringStatusWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/engineeringstatus`, filter, { params });
  }

  searchDcnRfiWorkflowStatusWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/workflowstatus`, filter, { params });
  }

  searchDcnRfiImplementationStatusWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/implementationstatus`, filter, { params });
  }

  searchDcnRfiCommentsWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}dcnrfi/comments`, filter, { params });
  }

  searchRedlineNumbersWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/numbers`, filter, { params });
  }

  searchRedlineDisciplinesWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/disciplines`, filter, { params });
  }

  searchRedlineTitlesWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/titles`, filter, { params });
  }

  searchRedlineRevisionsWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/revisions`, filter, { params });
  }

  searchRedlineRlmuStatusWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/rlmustatus`, filter, { params });
  }

  searchRedlineTcoStatusWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/tcostatus`, filter, { params });
  }

  searchRedlineSubsystemsWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/subsystems`, filter, { params });
  }

  searchRedlineSubmissionStatusWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/submissionstatus`, filter, { params });
  }

  searchRedlineCommentsWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/comments`, filter, { params });
  }

  searchRedlineRequiredForWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/requiredFor`, filter, { params });
  }

  searchRedlineRfoWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/rfo`, filter, { params });
  }

  searchRedlineNbRespEngineersWithFilter(search: string, take: number, page: number, filter: DcnRfiRedlineRegisterFilter) {
    const params = DcnRfiRedlineRegisterService.getParams(search, take, page);
    return this.http.post(`${this.rootUrl + this.endpoint}redline/nbEngineer`, filter, { params });
  }
}
