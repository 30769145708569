import {
    RundownState,
    RundownTypeState,
    RundownFilter,
    RundownType,
    RUNDOWN_TYPE_KEYS,
    RUNDOWN_NUM_OF_PAST_WEEKS,
    RUNDOWN_NUM_OF_UPCOMING_WEEKS,
    RundownResponseResultType,
    RUNDOWN_CHART_DATE_FORMAT,
    RundownResponseDTO,
    RundownResponsePLIResultType,
    RUNDOWN_MONTH_CHART_DATE_FORMAT,
    RUNDOWN_QUARTER_CHART_DATE_FORMAT,
    ClosureStages,
} from './model';
import { RundownActions, RundownActionTypes } from './actions';
import {
    initWeekRange,
    getCurrentWeekStartDate,
    initMonthRange,
    initQuarterRange,
    getWeekEndDate,
} from 'src/app/extensions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { WeekType } from 'src/app/enums';

const initialState: RundownState = {
    mc: new RundownTypeState(),
    rfsu: new RundownTypeState(),
    aqvd: new RundownTypeState(),
    bitr: new RundownTypeState(),
    citr: new RundownTypeState(),
    pli2: new RundownTypeState(),
    pli1: new RundownTypeState(),
    rundownType: RundownType.RFSU,
    filter: new RundownFilter(),
    isLoading: false,
    monthViewEnabled: false,
    weeklyViewEnabled: true,
    quarterViewEnabled: false,
    planViewEnabled: true,
    filteredByClosureStage: false,
};

export function reducer(state = initialState, action: RundownActions): RundownState {
    switch (action.type) {
        case RundownActionTypes.RundownSetType: {
            return {
                ...state,
                rundownType: action.payload,
            };
        }
        case RundownActionTypes.RundownTogglePlanView: {
            return {
                ...state,
                planViewEnabled: !state.planViewEnabled,
            };
        }
        case RundownActionTypes.RundownSetWeekRange: {
            const key = RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase();
            const weeks = initWeekRange(action.payload, RUNDOWN_NUM_OF_PAST_WEEKS + (state.filter.range - RUNDOWN_NUM_OF_PAST_WEEKS));

            return {
                ...state,
                [key]: {
                    ...state[key],
                    weeks,
                },
                monthViewEnabled: false,
                weeklyViewEnabled: true,
                quarterViewEnabled: false,
            };
        }
        case RundownActionTypes.RundownSetMonthRange: {
            const key = RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase();
            const months = initMonthRange(action.payload, state.filter.range);

            return {
                ...state,
                [key]: {
                    ...state[key],
                    months,
                },
                monthViewEnabled: true,
                weeklyViewEnabled: false,
                quarterViewEnabled: false,
            };
        }
        case RundownActionTypes.RundownSetQuarterRange: {
            const key = RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase();
            const quarters = initQuarterRange(action.payload, state.filter.range);

            return {
                ...state,
                [key]: {
                    ...state[key],
                    quarters,
                },
                monthViewEnabled: false,
                weeklyViewEnabled: false,
                quarterViewEnabled: true,
            };
        }
        case RundownActionTypes.RundownFilterUpdate: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
            };
        }
        case RundownActionTypes.RundownFilterPropertyUpdate: {
            let newFilter = { ...state.filter };
            if (Array.isArray(state.filter[action.payload.key])) {
                if (action.payload.key === 'actionBy') {
                    newFilter[action.payload.key] = action.payload.value.map((x) => x.id);
                } else {
                    newFilter[action.payload.key] = [...action.payload.value];
                }
            } else {
                newFilter[action.payload.key] = action.payload.value;
            }

            return {
                ...state,
                filter: {
                    ...newFilter,
                },
            };
        }
        case RundownActionTypes.RundownFilterReset: {
            return {
                ...state,
                filter: new RundownFilter(),
            };
        }
        case RundownActionTypes.RundownRequest:
        case RundownActionTypes.RundownExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }        
        case RundownActionTypes.RundownSuccess: {
            let newState = JSON.parse(JSON.stringify(state)) as RundownState;
            newState.filteredByClosureStage = newState.filter.closureStage !== ClosureStages.All;

            switch (action.payload.rundownType) {
                case RundownType.RFSU:
                case RundownType.AQVD:
                case RundownType.BITR:
                case RundownType.CITR:
                    prepareRundownCharts(
                        newState,
                        action.payload.response,
                        action.payload.rundownType,
                        action.payload.monthlyView,
                        action.payload.weeklyView,
                        action.payload.quarteryView
                    );
                    break;
                case RundownType.PLI1:
                case RundownType.PLI2:
                    preparePLICharts(
                        newState,
                        action.payload.response,
                        action.payload.rundownType,
                        action.payload.monthlyView,
                        action.payload.weeklyView,
                        action.payload.quarteryView
                    );
                    break;
                default:
                    break;
            }

            newState[RUNDOWN_TYPE_KEYS.get(action.payload.rundownType).toLowerCase()].showGroupedVerticalChart =
                action.payload.rundownType === RundownType.PLI2 || action.payload.rundownType === RundownType.PLI1;

            return {
                ...newState,
                isLoading: false,
            };
        }
        case RundownActionTypes.RundownExportToExcelSuccess:
        case RundownActionTypes.RundownError:
        case RundownActionTypes.RundownExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case RundownActionTypes.RundownRangeUpdate: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    range: action.payload,
                },
                [RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase()]: {
                    ...state[RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase()],
                    weeks: state.weeklyViewEnabled
                        ? initWeekRange(
                              state[RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase()].weeks[0].date,
                              action.payload
                          )
                        : state[RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase()].weeks,
                    months: state.monthViewEnabled
                        ? initMonthRange(
                              state[RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase()].months[0].date,
                              action.payload
                          )
                        : state[RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase()].months,
                    quartesr: state.quarterViewEnabled
                        ? initQuarterRange(
                              state[RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase()].quarters[0].date,
                              action.payload
                          )
                        : state[RUNDOWN_TYPE_KEYS.get(state.rundownType).toLowerCase()].quarters,
                },
            };
        }
        default:
            return state;
    }
}

const preparePLICharts = (
    state: RundownState,
    response: RundownResponseDTO,
    rundownType: RundownType,
    monthlyView: boolean,
    weeklyView: boolean,
    quarteryView: boolean
) => {
    preparePLILineChart(state, response, rundownType, monthlyView, weeklyView, quarteryView);
    preparePLIBarChart(state, response, rundownType, monthlyView, weeklyView, quarteryView);
    preparePLITableData(state, response, rundownType, monthlyView, weeklyView, quarteryView);
};

const getWeekEnd = (date: moment.Moment, weeklyView: boolean) => {
    return weeklyView ? getWeekEndDate(date) : moment(date);
};

const preparePLILineChart = (
    state: RundownState,
    response: RundownResponseDTO,
    rundownType: RundownType,
    monthlyView: boolean,
    weeklyView: boolean,
    quarteryView: boolean
) => {
    const format = monthlyView
        ? RUNDOWN_MONTH_CHART_DATE_FORMAT
        : weeklyView
        ? RUNDOWN_CHART_DATE_FORMAT
        : RUNDOWN_QUARTER_CHART_DATE_FORMAT;
    const totalPLILineChartData = {
        name: 'Total',
        series: [],
    };
    const aPLILineChartData = {
        name: 'A',
        series: [],
    };
    const bPLILineChartData = {
        name: 'B',
        series: [],
    };
    const cPLILineChartData = {
        name: 'C',
        series: [],
    };
    const dPLILineChartData = {
        name: 'D',
        series: [],
    };

    const currentDateRange = monthlyView
        ? moment().startOf('month')
        : weeklyView
        ? getCurrentWeekStartDate()
        : moment().startOf('quarter');

    if (!response.results.length) {
        state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].yScaleMin = 0;
        state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].yScaleMax = 0;
        state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].lineChart = [];
        return;
    }

    const totalDates =
        response.results[RundownResponsePLIResultType.Total]?.countsByDate?.filter((x) =>
            moment(x.date).isSameOrBefore(currentDateRange, 'day')
        ) || [];
    const apliDates =
        response.results[RundownResponsePLIResultType.APLI]?.countsByDate?.filter((x) =>
            moment(x.date).isSameOrBefore(currentDateRange, 'day')
        ) || [];
    const bpliDates =
        response.results[RundownResponsePLIResultType.BPLI]?.countsByDate?.filter((x) =>
            moment(x.date).isSameOrBefore(currentDateRange, 'day')
        ) || [];
    const cpliDates =
        response.results[RundownResponsePLIResultType.CPLI]?.countsByDate?.filter((x) =>
            moment(x.date).isSameOrBefore(currentDateRange, 'day')
        ) || [];
    const dpliDates =
        response.results[RundownResponsePLIResultType.DPLI]?.countsByDate?.filter((x) =>
            moment(x.date).isSameOrBefore(currentDateRange, 'day')
        ) || [];

    _.reduce(
        totalDates,
        (sum, n) => {
            const count = sum + n.count;
            totalPLILineChartData.series.push({
                name: (quarteryView ? 'Q' : '') + getWeekEnd(n.date, weeklyView).format(format),
                value: count,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.Total]?.open
    );

    _.reduce(
        apliDates,
        (sum, n) => {
            const count = sum + n.count;
            aPLILineChartData.series.push({
                name: (quarteryView ? 'Q' : '') + getWeekEnd(n.date, weeklyView).format(format),
                value: count,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.APLI]?.open
    );
    _.reduce(
        bpliDates,
        (sum, n) => {
            const count = sum + n.count;
            bPLILineChartData.series.push({
                name: (quarteryView ? 'Q' : '') + getWeekEnd(n.date, weeklyView).format(format),
                value: count,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.BPLI]?.open
    );
    _.reduce(
        cpliDates,
        (sum, n) => {
            const count = sum + n.count;
            cPLILineChartData.series.push({
                name: (quarteryView ? 'Q' : '') + getWeekEnd(n.date, weeklyView).format(format),
                value: count,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.CPLI]?.open
    );
    _.reduce(
        dpliDates,
        (sum, n) => {
            const count = sum + n.count;
            dPLILineChartData.series.push({
                name: (quarteryView ? 'Q' : '') + getWeekEnd(n.date, weeklyView).format(format),
                value: count,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.DPLI]?.open
    );

    const yScaleMin = 0;
    const yScaleMax = Math.max(...totalPLILineChartData.series.map((x) => x.value));

    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].yScaleMin = yScaleMin;
    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].yScaleMax = yScaleMax;
    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].lineChart = [
        totalPLILineChartData,
        aPLILineChartData,
        bPLILineChartData,
        cPLILineChartData,
        dPLILineChartData,
    ];

    const upcomingRange = monthlyView
        ? state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].months.filter((x) => x.weekType === WeekType.Upcoming)
        : weeklyView
        ? state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].weeks.filter((x) => x.weekType === WeekType.Upcoming)
        : state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].quarters.filter(
              (x) => x.weekType === WeekType.Upcoming
          );

    if (upcomingRange.length > 0) {
        const fakeLineChartData = {
            name: '_',
            series: [],
        };
        upcomingRange.map((x) =>
            fakeLineChartData.series.push({
                name: (quarteryView ? 'Q' : '') + getWeekEnd(x.date, weeklyView).format(format),
                value: 0,
            })
        );

        state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].lineChart.push(fakeLineChartData);
    }
};

const preparePLITableData = (
    state: RundownState,
    response: RundownResponseDTO,
    rundownType: RundownType,
    monthlyView: boolean,
    weeklyView: boolean,
    quarteryView: boolean
) => {
    const apliWeeks = response.results[RundownResponsePLIResultType.APLI]?.countsByDate || [];
    const bpliWeeks = response.results[RundownResponsePLIResultType.BPLI]?.countsByDate || [];
    const cpliWeeks = response.results[RundownResponsePLIResultType.CPLI]?.countsByDate || [];
    const dpliWeeks = response.results[RundownResponsePLIResultType.DPLI]?.countsByDate || [];
    const mcWalkdownsWeeks = response.results[RundownResponsePLIResultType.MCWalkdowns]?.countsByDate || [];
    const format = monthlyView
        ? RUNDOWN_MONTH_CHART_DATE_FORMAT
        : weeklyView
        ? RUNDOWN_CHART_DATE_FORMAT
        : RUNDOWN_QUARTER_CHART_DATE_FORMAT;

    const aPLIData = [];
    const bPLIData = [];
    const cPLIData = [];
    const dPLIData = [];
    const mcWalkdownsData = [];

    _.reduce(
        apliWeeks,
        (sum, n) => {
            const count = sum + n.count;
            aPLIData.push({
                count: count,
                raised: n.raised,
                closed: n.closed,
                date: n.date,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.APLI]?.open
    );

    _.reduce(
        bpliWeeks,
        (sum, n) => {
            const count = sum + n.count;
            bPLIData.push({
                count: count,
                raised: n.raised,
                closed: n.closed,
                date: n.date,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.BPLI]?.open
    );

    _.reduce(
        cpliWeeks,
        (sum, n) => {
            const count = sum + n.count;
            cPLIData.push({
                count: count,
                raised: n.raised,
                closed: n.closed,
                date: n.date,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.CPLI]?.open
    );

    _.reduce(
        dpliWeeks,
        (sum, n) => {
            const count = sum + n.count;
            dPLIData.push({
                count: count,
                raised: n.raised,
                closed: n.closed,
                date: n.date,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.DPLI]?.open
    );

    _.reduce(
        mcWalkdownsWeeks,
        (sum, n) => {
            const count = sum + n.count;
            mcWalkdownsData.push({
                count: count,
                raised: n.raised,
                closed: n.closed,
                date: n.date,
            });
            return count;
        },
        response.results[RundownResponsePLIResultType.MCWalkdowns]?.open
    );

    var tableData = [
        ...(monthlyView
            ? state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].months
            : weeklyView
            ? state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].weeks
            : state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].quarters
        ).map((countByWeek) => {
            return {
                name: (quarteryView ? 'Q' : '') + getWeekEnd(countByWeek.date, weeklyView).format(format),
                series: [
                    {
                        name: 'A',
                        total: aPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.count || 0,
                        closed:
                            aPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.closed || 0,
                        raised:
                            aPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.raised || 0,
                    },
                    {
                        name: 'B',
                        total: bPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.count || 0,
                        closed:
                            bPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.closed || 0,
                        raised:
                            bPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.raised || 0,
                    },
                    {
                        name: 'C',
                        total: cPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.count || 0,
                        closed:
                            cPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.closed || 0,
                        raised:
                            cPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.raised || 0,
                    },
                    {
                        name: 'D',
                        total: dPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.count || 0,
                        closed:
                            dPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.closed || 0,
                        raised:
                            dPLIData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))?.raised || 0,
                    },
                    {
                        name: 'MCWalkdowns',
                        total:
                            mcWalkdownsData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                ?.count || 0,
                        closed:
                            mcWalkdownsData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                ?.closed || 0,
                        raised:
                            mcWalkdownsData.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                ?.raised || 0,
                    },
                ],
            };
        }),
    ];

    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].tableData = tableData;
};

const preparePLIBarChart = (
    state: RundownState,
    response: RundownResponseDTO,
    rundownType: RundownType,
    monthlyView: boolean,
    weeklyView: boolean,
    quarteryView: boolean
) => {
    const totalWeeks = response.results[RundownResponsePLIResultType.Total]?.countsByDate || [];
    const apliWeeks = response.results[RundownResponsePLIResultType.APLI]?.countsByDate || [];
    const bpliWeeks = response.results[RundownResponsePLIResultType.BPLI]?.countsByDate || [];
    const cpliWeeks = response.results[RundownResponsePLIResultType.CPLI]?.countsByDate || [];
    const dpliWeeks = response.results[RundownResponsePLIResultType.DPLI]?.countsByDate || [];
    const format = monthlyView
        ? RUNDOWN_MONTH_CHART_DATE_FORMAT
        : weeklyView
        ? RUNDOWN_CHART_DATE_FORMAT
        : RUNDOWN_QUARTER_CHART_DATE_FORMAT;

    var barChartData = [
        ...(monthlyView
            ? state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].months
            : weeklyView
            ? state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].weeks
            : state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].quarters
        ).map((countByWeek) => {
            if (rundownType === RundownType.PLI2) {
                return {
                    name: (quarteryView ? 'Q' : '') + moment(countByWeek.date).format(format),
                    series: [
                        {
                            name: 'Total',
                            series: [
                                {
                                    name: 'Total',
                                    value:
                                        totalWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.count || 0,
                                },
                            ],
                        },
                        {
                            name: 'Stack',
                            series: [
                                {
                                    name: 'A',
                                    value:
                                        apliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.count || 0,
                                },
                                {
                                    name: 'B',
                                    value:
                                        bpliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.count || 0,
                                },
                                {
                                    name: 'C',
                                    value:
                                        cpliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.count || 0,
                                },
                                {
                                    name: 'D',
                                    value:
                                        dpliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.count || 0,
                                },
                            ],
                        },
                    ],
                };
            }
            if (rundownType == RundownType.PLI1) {
                return {
                    name: (quarteryView ? 'Q' : '') + getWeekEnd(countByWeek.date, weeklyView).format(format),
                    series: [
                        {
                            name: 'PLI Raised',
                            series: [
                                {
                                    name: 'Raised',
                                    value: [
                                        apliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.raised || 0,
                                        bpliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.raised || 0,
                                        cpliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.raised || 0,
                                        dpliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.raised || 0,
                                    ].reduce((acc, val) => acc + val),
                                },
                            ],
                        },
                        {
                            name: 'PLI Closed',
                            series: [
                                {
                                    name: 'Closed',
                                    value: [
                                        apliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.closed || 0,
                                        bpliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.closed || 0,
                                        cpliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.closed || 0,
                                        dpliWeeks.find((x) => moment(x.date).isSame(moment(countByWeek.date), 'day'))
                                            ?.closed || 0,
                                    ].reduce((acc, val) => acc + val),
                                },
                            ],
                        },
                    ],
                };
            }
        }),
    ];

    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].barChart = barChartData;
};

const prepareRundownCharts = (
    state: RundownState,
    data: RundownResponseDTO,
    rundownType: RundownType,
    monthlyView: boolean,
    weeklyView: boolean,
    quarteryView: boolean
) => {
    prepareRundownLineChart(state, data, rundownType, monthlyView, weeklyView, quarteryView);
    prepareRundownBarChart(state, data, rundownType, monthlyView, weeklyView, quarteryView);
    prepareRundownTableData(state, data, rundownType, monthlyView, weeklyView, quarteryView);
};

const prepareRundownLineChart = (
    state: RundownState,
    data: RundownResponseDTO,
    rundownType: RundownType,
    monthlyView: boolean,
    weeklyView: boolean,
    quarteryView: boolean
) => {
    const rundownPlanLineChartData = {
            name: 'Plan',
            series: [],
        },
        rundownActualLineChartData = {
            name: 'Actual',
            series: [],
        },
        rundownForecastLineChartData = {
            name: 'Forecast',
            series: [],
        };
    const format = monthlyView
        ? RUNDOWN_MONTH_CHART_DATE_FORMAT
        : weeklyView
        ? RUNDOWN_CHART_DATE_FORMAT
        : RUNDOWN_QUARTER_CHART_DATE_FORMAT;

    if (rundownType === RundownType.RFSU) {
        _.reduce(
            data.results[RundownResponseResultType.Plan].countsByDate,
            (sum, n) => {
                const newValue = sum - n.count;
                rundownPlanLineChartData.series.push({
                    name: (quarteryView ? 'Q' : '') + getWeekEnd(n.date, weeklyView).format(format),
                    value: newValue
                });
                return newValue;
            },
            data.results[RundownResponseResultType.Plan].numberOfAlreadyCompleted
        );
    } else {
        _.reduce(
            data.results[RundownResponseResultType.Plan].countsByDate,
            (sum, n) => {
                const newValue = Math.max(0, sum - n.count);
                rundownPlanLineChartData.series.push({
                    name: (quarteryView ? 'Q' : '') + getWeekEnd(n.date, weeklyView).format(format),
                    value: newValue,
                });
                return newValue;
            },
            data.results[RundownResponseResultType.Plan].open
        );
    }

    if (rundownType === RundownType.AQVD || rundownType === RundownType.BITR || rundownType === RundownType.CITR) {
        _.reduce(
            data.results[RundownResponseResultType.Actual].countsByDate,
            (sum, n) => {
                const newValue = sum - n.count;
                rundownActualLineChartData.series.push({
                    name: (quarteryView ? 'Q' : '') + getWeekEnd(n.date, weeklyView).format(format),
                    value: newValue,
                });
                return newValue;
            },
            data.results[RundownResponseResultType.Actual].numberOfAlreadyCompleted
        );
    } else {
        _.reduceRight(
            data.results[RundownResponseResultType.Actual].countsByDate,
            (sum, n) => {
                rundownActualLineChartData.series.unshift({
                    name: (quarteryView ? 'Q' : '') + getWeekEnd(n.date, weeklyView).format(format),
                    value: sum,
                });
                return sum + n.count;
            },
            data.total + data.results[RundownResponseResultType.Actual].numberOfAlreadyCompleted
        );
    }

    if (rundownType === RundownType.RFSU) {
        rundownForecastLineChartData.series = (
            data.results[RundownResponseResultType.ForecastCurve].countsByDate || []
        ).map((item) => ({
            name: (quarteryView ? 'Q' : '') + getWeekEnd(item.date, weeklyView).format(format),
            value: item.count,
        }));
        if (
            data.results[RundownResponseResultType.Forecast].countsByDate &&
            data.results[RundownResponseResultType.Forecast].countsByDate.length !==
                data.results[RundownResponseResultType.ForecastCurve].countsByDate?.length
        ) {
            rundownForecastLineChartData.series.unshift(_.last(rundownActualLineChartData.series));
        }
    }

    let yScaleMin = _.min(
        [rundownActualLineChartData.series, rundownForecastLineChartData.series, rundownPlanLineChartData.series]
            .filter((s) => s.length)
            .map((s) => _.minBy(s, 'value').value)
    );
    yScaleMin -= yScaleMin >= 100 ? 100 : yScaleMin;

    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].lineChart = [
        rundownActualLineChartData,
        rundownForecastLineChartData,
        rundownPlanLineChartData,
    ];
    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].yScaleMin = yScaleMin;
};

const prepareRundownBarChart = (
    state: RundownState,
    data: RundownResponseDTO,
    rundownType: RundownType,
    monthlyView: boolean,
    weeklyView: boolean,
    quarteryView: boolean
) => {
    const rundownActualCountsByDate = data.results[RundownResponseResultType.Actual]?.countsByDate
        ? data.results[RundownResponseResultType.Actual].countsByDate
        : [];
    const rundownForecastCountsByDate = data.results[RundownResponseResultType.Forecast]?.countsByDate
        ? data.results[RundownResponseResultType.Forecast].countsByDate
        : [];
    const rundownPlanCountsByDate = data.results[RundownResponseResultType.Plan]?.countsByDate
        ? data.results[RundownResponseResultType.Plan].countsByDate
        : [];

    const format = monthlyView
        ? RUNDOWN_MONTH_CHART_DATE_FORMAT
        : weeklyView
        ? RUNDOWN_CHART_DATE_FORMAT
        : RUNDOWN_QUARTER_CHART_DATE_FORMAT;

    const barChartData = [
        ...rundownActualCountsByDate.map((countByWeek) => {
            return {
                name: (quarteryView ? 'Q' : '') + getWeekEnd(countByWeek.date, weeklyView).format(format),
                series: [
                    {
                        name: 'Actual',
                        value: countByWeek.count,
                    },
                    {
                        name: 'Forecast',
                        value: 0,
                    },
                    {
                        name: 'Plan',
                        value: 0,
                    },
                ],
            };
        }),
        ...rundownForecastCountsByDate.map((countByWeek) => {
            return {
                name: (quarteryView ? 'Q' : '') + getWeekEnd(countByWeek.date, weeklyView).format(format),
                series: [
                    {
                        name: 'Actual',
                        value: 0,
                    },
                    {
                        name: 'Forecast',
                        value: countByWeek.count,
                    },
                    {
                        name: 'Plan',
                        value: 0,
                    },
                ],
            };
        }),
        ...rundownPlanCountsByDate.map((countByWeek, i) => {
            const lineChartValue = state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].lineChart[2].series[i].value;
            return {
                name: (quarteryView ? 'Q' : '') + getWeekEnd(countByWeek.date, weeklyView).format(format),
                series: [
                    {
                        name: 'Actual',
                        value: 0,
                    },
                    {
                        name: 'Forecast',
                        value: 0,
                    },
                    {
                        name: 'Plan',
                        value:
                            lineChartValue > 0
                                ? countByWeek.count
                                : i > 0
                                ? state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].lineChart[2].series[i - 1]
                                      .value
                                : 0,
                    },
                ],
            };
        }),
    ];

    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].barChart = barChartData;
};

const prepareRundownTableData = (
    state: RundownState,
    data: RundownResponseDTO,
    rundownType: RundownType,
    monthlyView: boolean,
    weeklyView: boolean,
    quarteryView: boolean
) => {
    const rundownActualCountsByDate = data.results[RundownResponseResultType.Actual]?.countsByDate
        ? data.results[RundownResponseResultType.Actual].countsByDate
        : [];
    const rundownForecastCountsByDate = data.results[RundownResponseResultType.Forecast]?.countsByDate
        ? data.results[RundownResponseResultType.Forecast].countsByDate
        : [];
    const rundownPlanCountsByDate = data.results[RundownResponseResultType.Plan]?.countsByDate
        ? data.results[RundownResponseResultType.Plan].countsByDate
        : [];

    const format = monthlyView
        ? RUNDOWN_MONTH_CHART_DATE_FORMAT
        : weeklyView
        ? RUNDOWN_CHART_DATE_FORMAT
        : RUNDOWN_QUARTER_CHART_DATE_FORMAT;

    const tableData = [
        ...(monthlyView
            ? state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].months
            : weeklyView
            ? state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].weeks
            : state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].quarters
        ).map((countByWeek) => {
            const barChartPlanValue =
                _.maxBy(
                    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].barChart.filter(
                        (b) =>
                            b.name ==
                            (quarteryView ? 'Q' : '') + getWeekEnd(countByWeek.date, weeklyView).format(format)
                    ),
                    (b: any) => b.series[2].value
                )?.series[2].value || 0;
            return {
                name: (quarteryView ? 'Q' : '') + getWeekEnd(countByWeek.date, weeklyView).format(format),
                series: [
                    {
                        name: 'Actual',
                        total:
                            rundownActualCountsByDate.find((x) =>
                                moment(x.date).isSame(moment(countByWeek.date), 'day')
                            )?.count || 0,
                        breakdown:
                            rundownActualCountsByDate.find((x) =>
                            moment(x.date).isSame(moment(countByWeek.date), 'day')
                            )?.breakdown || null,
                    },
                    {
                        name: 'Forecast',
                        total:
                            rundownForecastCountsByDate.find((x) =>
                                moment(x.date).isSame(moment(countByWeek.date), 'day')
                            )?.count || 0,
                        breakdown:
                            rundownForecastCountsByDate.find((x) =>
                            moment(x.date).isSame(moment(countByWeek.date), 'day')
                            )?.breakdown || null,
                    },
                    {
                        name: 'Plan',
                        total: barChartPlanValue,
                        breakdown:
                            rundownPlanCountsByDate.find((x) =>
                            moment(x.date).isSame(moment(countByWeek.date), 'day')
                            )?.breakdown || null,
                    },
                ],
            };
        }),
    ];

    state[RUNDOWN_TYPE_KEYS.get(rundownType).toLowerCase()].tableData = tableData;
};
