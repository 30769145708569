import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BlueZoneService } from 'src/app/services/api/webapi-services/bluezone.service';
import { Effect, ofType, Actions } from '@ngrx/effects';
import {
    BlueZoneActionTypes,
    BlueZoneFilterSuccess,
    BlueZoneFilterError,
    BlueZoneRFSUForecastDateUpdateRequest,
    BlueZoneRFSUForecastDateUpdateSuccess,
    BlueZoneRFSUForecastDateUpdateError,
    BlueZoneAddCommentRequest,
    BlueZoneAddCommentSuccess,
    BlueZoneAddCommentError,
    BlueZoneExportToExcelSuccess,
    BlueZoneExportToExcelError,
    BlueZoneFilterRequest,
    BlueZoneRemoveCommentRequest,
    BlueZoneRemoveCommentSuccess,
    BlueZoneRemoveCommentError,
    BlueZoneAddBulkCommentRequest,
    BlueZoneAddBulkCommentSuccess,
    BlueZoneAddBulkCommentError,
    BlueZoneUpdateBacklogCatRequest,
    BlueZoneUpdateBacklogCatSuccess,
    BlueZoneUpdateBacklogCatError,
    BlueZoneSetFlagColorRequest,
    BlueZoneSetFlagColorError,
    BlueZoneSetFlagColorSuccess,
    BlueZoneClearFlagColorRequest,
    BlueZoneClearFlagColorSuccess,
    BlueZoneClearFlagColorError,
    BlueZoneExportOnePagerSuccess,
    BlueZoneExportOnePagerError,
    BlueZoneToggleWalkdownCommitmentRequest,
    BlueZoneToggleWalkdownCommitmentSuccess,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { Zone } from 'src/app/enums';
import * as moment from 'moment';
import { getCurrentWeekStartDate } from 'src/app/extensions';

@Injectable()
export class BlueZoneEffects {
    constructor(
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private blueZoneService: BlueZoneService,
        private actions$: Actions,
        private commentService: CommentService
    ) {}

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<BlueZoneFilterRequest>(BlueZoneActionTypes.BlueZoneFilterRequest),
        withLatestFrom(this.store.select((store) => store.blueZoneState.filter)),
        mergeMap(([action, blueZoneFilter]) => {
            let filter = action.payload ?? blueZoneFilter;
            return this.blueZoneService.getRedZoneData(filter).pipe(
                map((dataPagination) => {
                    return new BlueZoneFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering BlueZone subsystem RFSU status page. Please contact Program Administrator.'
                    );
                    return of(new BlueZoneFilterError(error));
                })
            );
        })
    );

    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<BlueZoneRemoveCommentRequest>(BlueZoneActionTypes.BlueZoneRemoveCommentRequest),
        withLatestFrom(this.store.select((store) => store.blueZoneState.filter)),
        mergeMap(([{ payload }, filter]) =>
            this.commentService
                .deleteZoneComment(payload.id, filter.disciplineBreakdownEnabled ? Zone.Blue : Zone.BlueNoDiscipline)
                .pipe(
                    map(() => {
                        this.toastService.Success('Comment successfully removed.');
                        return new BlueZoneRemoveCommentSuccess({
                            id: payload.id,
                            lastComment: payload.lastComment,
                            entityId: payload.entityId,
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while removing BlueZone comment. Please contact Program Administrator.'
                        );
                        return of(new BlueZoneRemoveCommentError(error));
                    })
                )
        )
    );

    @Effect()
    updateMcForecastDate$ = this.actions$.pipe(
        ofType<BlueZoneRFSUForecastDateUpdateRequest>(BlueZoneActionTypes.BlueZoneRFSUForecastDateUpdateRequest),
        mergeMap((action) => {
            return this.blueZoneService
                .updateRFSUForecastDate(
                    action.payload.subsystem,
                    action.payload.type,
                    action.payload.rfsuForecastDate,
                    getCurrentWeekStartDate().format('YYYY-MM-DD'),
                    action.payload.discipline,
                    action.payload.allDisciplines
                )
                .pipe(
                    map(() => new BlueZoneRFSUForecastDateUpdateSuccess()),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while updating RFSU Forecast date. Please contact Program Administrator.'
                        );
                        return of(new BlueZoneRFSUForecastDateUpdateError(error));
                    })
                );
        })
    );

    @Effect()
    updateBacklogCat$ = this.actions$.pipe(
        ofType<BlueZoneUpdateBacklogCatRequest>(BlueZoneActionTypes.BlueZoneUpdateBacklogCatRequest),
        mergeMap((action) => {
            return this.blueZoneService.updateBacklogCat(action.payload).pipe(
                map(() => {
                    this.toastService.Success('Backlog Category has been successfully updated.');
                    return new BlueZoneUpdateBacklogCatSuccess();
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating Backlog Category. Please contact Program Administrator.'
                    );
                    return of(new BlueZoneUpdateBacklogCatError(error));
                })
            );
        })
    );

    @Effect()
    addComment$ = this.actions$.pipe(
        ofType<BlueZoneAddCommentRequest>(BlueZoneActionTypes.BlueZoneAddCommentRequest),
        withLatestFrom(this.store.select((store) => store.blueZoneState.filter)),
        mergeMap(([action, filter]) => {
            return this.commentService
                .addCommentZone(
                    action.payload.entityId.toString(),
                    action.payload.description,
                    filter.disciplineBreakdownEnabled ? Zone.Blue : Zone.BlueNoDiscipline,
                    action.payload.mentions
                )
                .pipe(
                    map(() => {
                        this.toastService.Success('Comment added successfully.');
                        return new BlueZoneAddCommentSuccess({
                            entityId: action.payload.entityId,
                            description: action.payload.description,
                            mentions: action.payload.mentions,
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding comment. Please contact Program Administrator.'
                        );
                        return of(new BlueZoneAddCommentError(error));
                    })
                );
        })
    );

    @Effect()
    addBulkComment$ = this.actions$.pipe(
        ofType<BlueZoneAddBulkCommentRequest>(BlueZoneActionTypes.BlueZoneAddBulkCommentRequest),
        withLatestFrom(this.store.select((store) => store.blueZoneState.filter)),
        mergeMap(([action, filter]) => {
            return this.commentService
                .addBulkCommentZone(action.payload.description, action.payload.mentions, filter, Zone.Blue)
                .pipe(
                    map(() => {
                        this.toastService.Success('Bulk Comment added successfully.');
                        return new BlueZoneAddBulkCommentSuccess({
                            description: action.payload.description,
                            mentions: action.payload.mentions,
                        });
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while adding bulk comment. Please contact Program Administrator.'
                        );
                        return of(new BlueZoneAddBulkCommentError(error));
                    })
                );
        })
    );

    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType(BlueZoneActionTypes.BlueZoneExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.blueZoneState.filter)),
        mergeMap(([, blueZoneFilter]) => {
            const filter = {
                ...blueZoneFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.blueZoneService.generateExcel(filter).pipe(
                map((excelData) => new BlueZoneExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new BlueZoneExportToExcelError(error));
                })
            );
        })
    );

    @Effect()
    exportOnePager$ = this.actions$.pipe(
        ofType(BlueZoneActionTypes.BlueZoneExportOnePagerRequest),
        withLatestFrom(this.store.select((store) => store.blueZoneState.filter)),
        mergeMap(([, blueZoneFilter]) => {
            const filter = {
                ...blueZoneFilter,
                timezoneOffset: new Date().getTimezoneOffset(),
            };
            return this.blueZoneService.generateOnePager(filter).pipe(
                map((excelData) => new BlueZoneExportOnePagerSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new BlueZoneExportOnePagerError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(BlueZoneActionTypes.BlueZoneExportToExcelSuccess),
        map((action: BlueZoneExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `BlueZone_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect({ dispatch: false })
    saveOnePager$ = this.actions$.pipe(
        ofType(BlueZoneActionTypes.BlueZoneExportOnePagerSuccess),
        map((action: BlueZoneExportOnePagerSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `BlueZone_OnePager_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect()
    flagSubsystem$ = this.actions$.pipe(
        ofType<BlueZoneSetFlagColorRequest>(BlueZoneActionTypes.BlueZoneSetFlagColorRequest),
        mergeMap((action) =>
            this.blueZoneService.flagSubsystem(action.payload.subsystem, action.payload.color).pipe(
                map(() => {
                    this.toastService.Success('Successfully updated subsystem flag color.');
                    return new BlueZoneSetFlagColorSuccess({
                        subsystem: action.payload.subsystem,
                        color: action.payload.color,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occured while updating subsystem flag color. Please contact Program Administrator.'
                    );
                    return of(new BlueZoneSetFlagColorError(error));
                })
            )
        )
    );

    @Effect()
    clearFlagSubsystem$ = this.actions$.pipe(
        ofType<BlueZoneClearFlagColorRequest>(BlueZoneActionTypes.BlueZoneClearFlagColorRequest),
        withLatestFrom(this.store.select((store) => store.blueZoneState.filter)),
        mergeMap(([action, filter]) => {
            return this.blueZoneService.clearSubystemFlag(filter).pipe(
                map(() => {
                    this.toastService.Success('Successfully cleared subsystems flag color.');
                    return new BlueZoneClearFlagColorSuccess();
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while clearing subsystem flag color. Please contact Program Administrator.'
                    );
                    return of(new BlueZoneClearFlagColorError(error));
                })
            );
        })
    );

    @Effect()
    toggleWalkdownCommitment$ = this.actions$.pipe(
        ofType<BlueZoneToggleWalkdownCommitmentRequest>(BlueZoneActionTypes.BlueZoneToggleWalkdownCommitmentRequest),
        mergeMap((action) =>
            this.blueZoneService.toggleWalkdownCommitment(action.payload.subsystem, action.payload.committed).pipe(
                map(() => {
                    this.toastService.Success('Successfully toggled walkdown commitment.');
                    return new BlueZoneToggleWalkdownCommitmentSuccess({
                        subsystem: action.payload.subsystem,
                        committed: action.payload.committed,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while toggling walkdown commitment. Please contact Program Administrator.'
                    );
                    return of(new BlueZoneClearFlagColorError(error));
                })
            )
        )
    );
}
