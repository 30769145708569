import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum FlangeManagementActionTypes {
    FlangeManagementAddStartedLog = '[Flange Management] Add Started Log',
    FlangeManagementUploadLogRequest = '[Flange Management] Upload Log Request',
    FlangeManagementUploadLogSuccess = '[Flange Management] Upload Log Success',
    FlangeManagementUploadLogError = '[Flange Management] Upload Log Error',
    FlangeManagementFileUploaded = '[Flange Management] File Uploaded',
    FlangeManagementSetStatusAndStartDate = '[Flange Management] Set Status and Start Date',

    FlangeManagementValidateDeltaRequest = '[Flange Management] Validate Delta Request',
    FlangeManagementValidateDeltaSuccess = '[Flange Management] Validate Delta Success',
    FlangeManagementValidateDeltaError = '[Flange Management] Validate Delta Error',

    FlangeManagementClearInProgressSpinner = 'Flange Management] Clear In Progress Spinner',

    FlangeManagementTemplateFileRequest = '[Flange Management] Template File Request',
    FlangeManagementTemplateFileSuccess = '[Flange Management] Template File Success',
    FlangeManagementTemplateFileError = '[Flange Management] Template File Error',

    FlangeManagementDownloadOutputDataRequest = '[Flange Management] Download Output Data Request',
    FlangeManagementDownloadOutputDataSuccess = '[Flange Management] Download Output Data Success',
    FlangeManagementDownloadOutputDataError = '[Flange Management] Download Output Data Error',

    FlangeManagementValidateButtonStateRequest = '[Flange Management] Validate Button State Request',
    FlangeManagementValidateButtonStateSuccess = '[Flange Management] Validate Button State Success',
    FlangeManagementValidateButtonStateError = '[Flange Management] Validate Button State Error',

}

export class FlangeManagementAddStartedLog implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementAddStartedLog;
}

export class FlangeManagementUploadLogRequest implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementUploadLogRequest;
}

export class FlangeManagementUploadLogSuccess implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) { }
}

export class FlangeManagementUploadLogError implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementUploadLogError;

    constructor(public payload: string) { }
}

export class FlangeManagementFileUploaded implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementFileUploaded;
}

export class FlangeManagementSetStatusAndStartDate implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) { }
}

export class FlangeManagementValidateDeltaRequest implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementValidateDeltaRequest;
}

export class FlangeManagementValidateDeltaSuccess implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementValidateDeltaSuccess;
}

export class FlangeManagementValidateDeltaError implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementValidateDeltaError;

    constructor(public payload: string) { }
}

export class FlangeManagementClearInProgressSpinner implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementClearInProgressSpinner;
}

export class FlangeManagementTemplateFileRequest implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementTemplateFileRequest;

    constructor(public payload: string) { }
}

export class FlangeManagementTemplateFileSuccess implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementTemplateFileSuccess;

    constructor(public payload: BlobPart) { }
}

export class FlangeManagementTemplateFileError implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementTemplateFileError;

    constructor(public payload: string) { }
}

export class FlangeManagementDownloadOutputDataRequest implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementDownloadOutputDataRequest;

    constructor(public payload: string) { }
}

export class FlangeManagementDownloadOutputDataSuccess implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) { }
}

export class FlangeManagementDownloadOutputDataError implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementDownloadOutputDataError;

    constructor(public payload: string) { }
}

export class FlangeManagementValidateButtonStateRequest implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementValidateButtonStateRequest;
}

export class FlangeManagementValidateButtonStateSuccess implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) { }
}

export class FlangeManagementValidateButtonStateError implements Action {
    readonly type = FlangeManagementActionTypes.FlangeManagementValidateButtonStateError;

    constructor(public payload: string) { }
}

export type FlangeManagementActions =
    | FlangeManagementAddStartedLog
    | FlangeManagementUploadLogRequest
    | FlangeManagementUploadLogSuccess
    | FlangeManagementUploadLogError
    | FlangeManagementFileUploaded
    | FlangeManagementSetStatusAndStartDate
    | FlangeManagementValidateDeltaRequest
    | FlangeManagementValidateDeltaSuccess
    | FlangeManagementValidateDeltaError
    | FlangeManagementClearInProgressSpinner
    | FlangeManagementTemplateFileRequest
    | FlangeManagementTemplateFileSuccess
    | FlangeManagementTemplateFileError
    | FlangeManagementDownloadOutputDataRequest
    | FlangeManagementDownloadOutputDataSuccess
    | FlangeManagementDownloadOutputDataError
    | FlangeManagementValidateButtonStateRequest
    | FlangeManagementValidateButtonStateSuccess
    | FlangeManagementValidateButtonStateError;