<div class="filter-container">
    <form [formGroup]="filterForm">
        <div class="container-selectable-filters">

            <app-multiple-items-selector
                class="container300 margin-right20"
                [isAsync]="true"
                formControlName="subsystems"
                formSetter="subsystems"
                propertyToShow="id"
                [displayedColumns]="['id', 'name']"
                [setInput]="setSubsystemInput"
                [filterForm]="filterForm"
                [reloadOnOpen]="true"
                [searchFunc]="searchSubsystems"
                placeholder="Subsystem"
                [panelWidth]="480"
            >
            </app-multiple-items-selector>

            <div *ngIf="planType === weeklyPlanTypes.weekly && isNextWorkingWeek" class="container300">
                <mat-form-field>
                    <mat-label>In weekly plan?</mat-label>
                    <mat-select placeholder="All" [formGroup]="filterForm" formControlName="inWeeklyPlan">
                        <mat-option [value]="null">All</mat-option>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="planType === weeklyPlanTypes.weekly && isNextWorkingWeek" class="container300  margin-right20">
                <mat-form-field>
                    <mat-label>Hide activities with 0 remaining ITR?</mat-label>
                    <mat-select placeholder="No" [formGroup]="filterForm" formControlName="hasNoITRsToGo">
                        <mat-option [value]="false">Yes</mat-option>
                        <mat-option [value]="null">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <app-multiple-items-selector
                 *ngIf="planType === weeklyPlanTypes.weekly && isNextWorkingWeek"
                class="container300"
                [isAsync]="true"
                formControlName="subsystemExcludeFilter"
                formSetter="subsystemExcludeFilter"
                propertyToShow="id"
                [displayedColumns]="['id']"
                [filterForm]="filterForm"
                [reloadOnOpen]="true"
                [searchFunc]="searchSubsystemExcludeFilters"
                placeholder="Subsystem Exclude Filter"
                [panelWidth]="300"
            ></app-multiple-items-selector>

            <div *ngIf="planType === weeklyPlanTypes.monthly || isNextWorkingWeek" class="container300">
                <mat-form-field>
                    <mat-label>Hide constrained activities?</mat-label>
                    <mat-select placeholder="No" [formGroup]="filterForm" formControlName="hasNoConstraint">
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="null">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="planType === weeklyPlanTypes.weekly && isNextWorkingWeek" class="container300">
                <mat-form-field>
                    <mat-label>Hide ITRs in Current Weekly Commitment?</mat-label>
                    <mat-select placeholder="No" [formGroup]="filterForm" formControlName="hideCurrentITRs">
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="null">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <app-multiple-items-selector
                 *ngIf="planType === weeklyPlanTypes.weekly && isNextWorkingWeek"
                class="container300"
                [isAsync]="true"
                formControlName="itrExcludeFilter"
                formSetter="itrExcludeFilter"
                propertyToShow="id"
                [displayedColumns]="['id']"
                [filterForm]="filterForm"
                [reloadOnOpen]="true"
                [searchFunc]="searchItrExcludeFilters"
                placeholder="ITR Exclude Filter"
                [panelWidth]="300"
            ></app-multiple-items-selector>

            <div *ngIf="planType === weeklyPlanTypes.monthly" class="container300">
                <mat-form-field>
                    <mat-label>Show activities marked as complete?</mat-label>
                    <mat-select placeholder="All" [formGroup]="filterForm" formControlName="markedAsComplete">
                        <mat-option [value]="null">All</mat-option>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <app-multiple-items-selector
                class="container300 margin-right20"
                [isAsync]="true"
                formControlName="priorities"
                formSetter="priorities"
                propertyToShow="id"
                [displayedColumns]="['id']"
                [setInput]="setPriorityInput"
                [filterForm]="filterForm"
                [reloadOnOpen]="true"
                [searchFunc]="searchPriorities"
                placeholder="Priority"
                [panelWidth]="480"
                >
            </app-multiple-items-selector>

            <app-multiple-items-selector
                class="container300 margin-right20"
                [isAsync]="true"
                formControlName="itrContractors"
                formSetter="itrContractors"
                propertyToShow="contractNo"
                [displayedColumns]="['contractNo', 'contract']"
                [setInput]="setItrContractorsInput"
                [filterForm]="filterForm"
                [reloadOnOpen]="true"
                [searchFunc]="searchItrContractors"
                placeholder="Contractor assigned to ITR"
                [panelWidth]="480"
            >
            </app-multiple-items-selector>

            <app-multiple-items-selector
                *ngIf="planType === weeklyPlanTypes.weekly && tabIndex === 1"
                class="container300 margin-right20"
                [isAsync]="true"
                formControlName="stagedStartUpPriorities"
                formSetter="stagedStartUpPriorities"
                [propertyToShow]="'priority'"
                [displayedColumns]="['priority', 'priorityName']"
                [setInput]="setStagedStartUpPrioritiesInput"
                [filterForm]="filterForm"
                [searchFunc]="searchStagedStartUpPriorities"
                [secondPropertyToShow]="'priorityName'"
                placeholder="Staged Start-up Priority"
                [panelWidth]="480"
            >
            </app-multiple-items-selector>

                <app-multiple-items-selector
                    class="container300 margin-right20"
                    [setInput]="setRFSUContractorsInput"
                    formControlName="rfsuContractors"
                    [searchFunc]="getRFSUContractors"
                    [isAsync]="true"
                    [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                    [filterForm]="filterForm"
                    [formSetter]="'rfsuContractors'"
                    [propertyToShow]="'contractNo'"
                    [placeholder]="'RFSU Contractor'"
                    [panelWidth]="480"
                    [secondPropertyToShow]="'contract'"
                >
                </app-multiple-items-selector>

                <app-multiple-items-selector
                    class="container300 margin-right20"
                    [setInput]="setAreaBreakdownInput"
                    formControlName="areaBreakdown"
                    [searchFunc]="getAreaBreakdown"
                    [isAsync]="true"
                    [filterForm]="filterForm"
                    [formSetter]="'areaBreakdown'"
                    [placeholder]="'Area Breakdown'"
                >
                </app-multiple-items-selector>
        </div>
        <div class="controls__week-info">
            <mat-form-field appearance="standard" class="container300 margin-right20">
                <mat-label>Week start</mat-label>
                <input
                    matInput
                    formControlName="weekStart"
                    [matDatepicker]="picker"                    
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="standard" class="container300">
                <mat-label>Week end</mat-label>
                <!-- <input matInput disabled [value]="currentlySetWeekEnd | date: 'd/MMM/yy'" /> -->
                <input matInput formControlName="weekEnd" [matDatepicker]="weekendPicker" />
                <mat-datepicker-toggle matSuffix [for]="weekendPicker">
                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #weekendPicker></mat-datepicker>
            </mat-form-field>
            <button *ngIf="!printMode" type="submit" class="sct-button sct-button-light" (click)="search()">show plan</button>
        </div>
    </form>
</div>
