import { SystemizedDrawingsState } from './model';
import * as moment from 'moment';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { SystemizedDrawingsActions, SystemizedDrawingsActionTypes } from './actions';

const initialState: SystemizedDrawingsState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: SystemizedDrawingsActions) {
    switch (action.type) {
        case SystemizedDrawingsActionTypes.SystemizedDrawingsUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsDownloadOutputDataRequest:
        case SystemizedDrawingsActionTypes.SystemizedDrawingsTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsTemplateFileError:
        case SystemizedDrawingsActionTypes.SystemizedDrawingsDownloadOutputDataSuccess:
        case SystemizedDrawingsActionTypes.SystemizedDrawingsDownloadOutputDataError:
        case SystemizedDrawingsActionTypes.SystemizedDrawingsTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'SystemizedDrawings';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsValidateDeltaRequest: {
            return {
                ...state,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsValidateDeltaSuccess: {
            return {
                ...state,
            };
        }
        case SystemizedDrawingsActionTypes.SystemizedDrawingsValidateDeltaError: {
            return {
                ...state,
            };
        }
        default:
            return state;
    }
}
