<div class="cumulative-validation-progress-container">
    <h4>cumulative validation progress</h4>
    <div class="subtitle-container">
        <div class="options-container">
        </div>
        <div class="legend-container">
            <div class="legend-item completed">Validation Completed</div>
            <div class="legend-item raised">Change Raised</div>
            <div class="legend-item remaining">Remaining</div>
        </div>
    </div>

    <div class="chart-container">
        <div class="line-chart-container">
            <ngx-charts-line-chart
                [scheme]="colorScheme"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [xAxis]="true"
                [yAxis]="true"
                [results]="weeklycumulativeValidationProgressData"
                [roundDomains]="true"
                [autoScale]="true"
            >
            </ngx-charts-line-chart>
        </div>
    </div>
    <div *ngIf="isLoading$ | async" class="cumulative-validation-progress-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
