import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ToastService } from 'src/app/services/shared/toast.service';
import { Injectable } from "@angular/core";
import { LeakTestPackPriorityHistoryActionTypes, LeakTestPackPriorityHistoryError, LeakTestPackPriorityHistoryRequest, LeakTestPackPriorityHistorySuccess } from "./actions";
import { LeakTestPackTrackerSearchService } from "src/app/services/api/webapi-services/leak-test-pack-tracker-search.service";

@Injectable()
export class LeakTestPackPriorityHistoryEffects{

    constructor(
        private actions$: Actions,        
        private toastService: ToastService,
        private leakTestPackTrackerSearchService : LeakTestPackTrackerSearchService
    ) {}

    @Effect()
    getManualPriorityHistory$ = this.actions$.pipe(
        ofType<LeakTestPackPriorityHistoryRequest>(LeakTestPackPriorityHistoryActionTypes.LeakTestPackPriorityHistoryRequest),
        mergeMap((action) => {
            return this.leakTestPackTrackerSearchService.getPriorityHistory(action.payload.leakTestPackTrackerNo)
                .pipe(
                    map((data) => new LeakTestPackPriorityHistorySuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting Leak Test Pack Priority history. Please contact Program Administrator.'
                        );
                        return of(new LeakTestPackPriorityHistoryError(error));
                    })
                );
        })
    );
}
