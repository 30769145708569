<form class="numeric-filter-container">
    <div class="header-row">
        <p *ngIf="filterConfig.placeHolder">{{ filterConfig.placeHolder }}</p>
        <button mat-icon-button type="button" (click)="onClose()" aria-label="Close dialog" class="close-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <app-asc-desc-order
        [(isAscendingSort)]="filterConfig.isAscendingSort"
        [(isDescendingSort)]="filterConfig.isDescendingSort"
        class="section-row"
    ></app-asc-desc-order>

    <div class="numeric-range section-row">
        <mat-form-field class="example-form-field" appearance="fill">
            <mat-label>From</mat-label>
            <input
                matInput
                type="number"
                [(ngModel)]="filterConfig.minValue"
                max="{{ filterConfig.maxValue }}"
                autocomplete="off"
                [formControl]="minControl"
                (focusout)="onMinOut($event)"
            />
            <button
                *ngIf="filterConfig.minValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="filterConfig.minValue = null"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="example-form-field" appearance="fill">
            <mat-label>To</mat-label>
            <input
                matInput
                type="number"
                [(ngModel)]="filterConfig.maxValue"
                min="{{ filterConfig.minValue }}"
                autocomplete="off"
                [formControl]="maxControl"
                (focusout)="onMaxOut($event)"
            />
            <button
                *ngIf="filterConfig.maxValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="filterConfig.maxValue = null"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <mat-radio-group *ngIf="filterConfig.showBlankOptions" aria-label="Select an option" class="section-row">
        <mat-radio-button
            [checked]="!filterConfig.excludeBlanks && !filterConfig.onlyBlanks"
            value="all"
            (change)="updateBlanks($event)"
            disableRipple
            >All</mat-radio-button
        >
        <mat-radio-button
            [checked]="filterConfig.excludeBlanks === true"
            value="excludeBlanks"
            (change)="updateBlanks($event)"
            disableRipple
            >Exclude blanks</mat-radio-button
        >
        <mat-radio-button
            [checked]="filterConfig.onlyBlanks === true"
            value="onlyBlanks"
            (change)="updateBlanks($event)"
            disableRipple
            >Only blanks</mat-radio-button
        >
    </mat-radio-group>

    <div class="buttons-container section-row">
        <div class="dialog-actions-row section-row">
            <button mat-stroked-button type="button" (click)="onReset()">
                reset
            </button>
            <button
                mat-flat-button
                color="primary"
                type="submit"
                (click)="onSubmit()"
                [disabled]="minControl.invalid || maxControl.invalid"
            >
                apply
            </button>
        </div>
    </div>
</form>
