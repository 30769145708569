
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { of } from 'rxjs';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import { saveAs } from 'file-saver';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    CriticalPathActionTypes,
    CriticalPathUploadLogSuccess,
    CriticalPathUploadLogError,
    CriticalPathValidateButtonStateSuccess,
    CriticalPathValidateButtonStateError,
    CriticalPathDownloadOutputDataSuccess,
    CriticalPathDownloadOutputDataError,
    CriticalPathDownloadOutputDataRequest,
    CriticalPathTemplateFileSuccess,
    CriticalPathTemplateFileRequest,
    CriticalPathTemplateFileError,
    CriticalPathClearInProgressSpinner,
    CriticalPathValidateDeleteSuccess,
    CriticalPathValidateDeleteError,
    CriticalPathPatchAllDeleteRequest,
    CriticalPathPatchAllDeleteSuccess,
    CriticalPathPatchAllDeleteError,
    CriticalPathPatchDeleteRequest,
    CriticalPathPatchDeleteSuccess,
    CriticalPathPatchDeleteError,
    CriticalPathDeleteSuccess,
    CriticalPathDeleteError,
    CriticalPathDeleteRequest,
    CriticalPathSetStatusAndStartDate,
} from './actions';

@Injectable()
export class CriticalPathEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(CriticalPathActionTypes.CriticalPathUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['CriticalPath']).pipe(
                map((uploadLogData) => new CriticalPathUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new CriticalPathUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<CriticalPathUploadLogSuccess>(CriticalPathActionTypes.CriticalPathUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.criticalPathState.uploadLogStatus),
            this.store.select((store) => store.criticalPathState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new CriticalPathSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    //@Effect()
    // invokeDeleteRequest$ = this.actions$.pipe(
    //     ofType(CriticalPathActionTypes.CriticalPathSetStatusAndStartDate),
    //     withLatestFrom(this.store.select((store) => store.criticalPathState.uploadLogData[0])),
    //     filter(
    //         ([, uploadLog]) => uploadLog.status === ImportStatuses.Finished || uploadLog.status === ImportStatuses.Error
    //     ),
    //     map(() => new CriticalPathDeleteRequest())
    // );

    // @Effect()
    // getDelete$ = this.actions$.pipe(
    //     ofType(CriticalPathActionTypes.CriticalPathDeleteRecordsRequest),
    //     withLatestFrom(this.store.select((store) => store.criticalPathState.deleteFilter)),
    //     mergeMap(([, deleteFilter]) =>
    //         this.manualUploadsService.getCriticalPathDeleteRecords(deleteFilter).pipe(
    //             map((deleteData: any) => new CriticalPathDeleteSuccess(deleteData)),
    //             catchError((error) => {
    //                 this.toastService.Error(
    //                     'Error has occurred while getting Delete data records. Please contact Program Administrator'
    //                 );
    //                 return of(new CriticalPathDeleteError(error));
    //             })
    //         )
    //     )
    // );

    // @Effect()
    // patchDeleteData$ = this.actions$.pipe(
    //     ofType<CriticalPathPatchDeleteRequest>(CriticalPathActionTypes.CriticalPathPatchDeleteRecordRequest),
    //     mergeMap((action) =>
    //         this.manualUploadsService.patchCriticalPathDeleteRecord(
    //             action.payload.activityId, action.payload.deleteState
    //             ).pipe(
    //             map(() => new CriticalPathPatchDeleteSuccess()),
    //             catchError((error) => {
    //                 this.toastService.Error(
    //                     `Error has occurred while ${
    //                         action.payload.deleteState ? 'accepting' : 'rejecting'
    //                     } the change. Please contact Program Administrator`
    //                 );
    //                 return of(new CriticalPathPatchDeleteError(error));
    //             })
    //         )
    //     )
    // );

    // @Effect()
    // patchAllDelete$ = this.actions$.pipe(
    //     ofType<CriticalPathPatchAllDeleteRequest>(CriticalPathActionTypes.CriticalPathPatchAllDeleteRecordsRequest),
    //     withLatestFrom(this.store.select((store) => store.criticalPathState.deleteFilter)),
    //     mergeMap(([action, deleteFilter]) =>
    //         this.manualUploadsService.patchAllCriticalPathDeleteRecords(deleteFilter, action.payload.deleteState).pipe(
    //             map(() => new CriticalPathPatchAllDeleteSuccess({ deleteState: action.payload.deleteState })
    //             ),
    //             catchError((error) => {
    //                 this.toastService.Error(
    //                     `Error has occurred while ${
    //                         action.payload.deleteState ? 'accepting' : 'rejecting'
    //                     } the change. Please contact Program Administrator`
    //                 );
    //                 return of(new CriticalPathPatchAllDeleteError(error));
    //             })
    //         )
    //     )
    // );

    // @Effect()
    // validateDelete$ = this.actions$.pipe(
    //     ofType(CriticalPathActionTypes.CriticalPathValidateDataRequest),
    //     mergeMap(() =>
    //         this.manualUploadsService.validateCriticalPathData().pipe(
    //             map(() => new CriticalPathValidateDeleteSuccess()),
    //             catchError((error) => {
    //                 this.toastService.Error(
    //                     'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
    //                 );
    //                 return of(new CriticalPathValidateDeleteError(error));
    //             })
    //         )
    //     )
    // );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(CriticalPathActionTypes.CriticalPathDeleteRecordsRequest),
        withLatestFrom(this.store.select((store) => store.criticalPathState.uploadLogData)),
        filter(([, uploadLogs]) => {
            let logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(() => new CriticalPathClearInProgressSpinner())
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<CriticalPathTemplateFileRequest>(CriticalPathActionTypes.CriticalPathTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new CriticalPathTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Critical Path structure file. Please contact Program Administrator'
                    );
                    return of(new CriticalPathTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<CriticalPathTemplateFileSuccess>(CriticalPathActionTypes.CriticalPathTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'Critical Path.xlsx');
        })
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<CriticalPathDownloadOutputDataRequest>(CriticalPathActionTypes.CriticalPathDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new CriticalPathDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new CriticalPathDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<CriticalPathDownloadOutputDataSuccess>(CriticalPathActionTypes.CriticalPathDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    // @Effect()
    // setValidateButtonState$ = this.actions$.pipe(
    //     ofType(CriticalPathActionTypes.CriticalPathDeleteRecordsSuccess,
    //         CriticalPathActionTypes.CriticalPathPatchAllDeleteRecordsSuccess,
    //         CriticalPathActionTypes.CriticalPathPatchDeleteRecordSuccess),
    //     mergeMap(() =>
    //         this.manualUploadsService.getValidateButtonState('CriticalPath').pipe(
    //             map((response: any) => new CriticalPathValidateButtonStateSuccess(response)),
    //             catchError((error) => of(new CriticalPathValidateButtonStateError(error)))
    //         )
    //     )
    // );
}

