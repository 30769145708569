<form [formGroup]="filterForm">
    <mat-accordion class="filter-container">
        <mat-expansion-panel #filterExpansionPanel="matExpansionPanel" expanded class="filter-panel-container">
            <mat-expansion-panel-header collapsedHeight="auto">
                <app-register-filter-top-header
                    [filterForm]="filterForm"
                    [filterExpansionPanel]="filterExpansionPanel"
                ></app-register-filter-top-header>
            </mat-expansion-panel-header>
            <div class="filter-panel-content-container">
                <div class="filters-row col4">
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="redlineNumber"
                        [isAsync]="true"
                        [setInput]="setRedlineNumbersSelectorValue"
                        [formSetter]="'redlineNumber'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchRedlineNumbers"
                        [panelWidth]="480"
                        placeholder="Redline Document Number"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="filter-item">
                        <mat-form-field class="container300 margin-right20 grow">
                            <mat-label>Redline Discipline</mat-label>
                            <mat-select formControlName="redlineDiscipline" multiple>
                                <mat-option *ngFor="let discipline of redlineDisciplines" [value]="discipline">{{ discipline }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="redlineSubsystem"
                        [isAsync]="true"
                        [setInput]="setRedlineSubsystemSelectorValue"
                        [formSetter]="'redlineSubsystem'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchRedlineSubsystems"
                        [panelWidth]="480"
                        placeholder="Redline Subsystem"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="dcnRfiNumber"
                        [isAsync]="true"
                        [setInput]="setDcnRfiNumbersSelectorValue"
                        [formSetter]="'dcnRfiNumber'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchRfiNumbers"
                        [panelWidth]="480"
                        placeholder="RFI Number"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
            </div>
            <div class="filter-panel-content-container">
                <div class="filters-row col4">
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="redlineTitle"
                        [isAsync]="true"
                        [setInput]="setRedlineTitlesSelectorValue"
                        [formSetter]="'redlineTitle'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchRedlineTitles"
                        [panelWidth]="480"
                        placeholder="Redline Document Title"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="filter-item">
                        <mat-form-field class="container300 margin-right20 grow">
                            <mat-label>NB RLMU Status</mat-label>
                            <mat-select formControlName="rlmuStatus" multiple>
                                <mat-option *ngFor="let rlmuStatus of rlmuStatuses" [value]="rlmuStatus">{{ rlmuStatus }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filter-item">
                        <mat-form-field class="container300 margin-right20 grow">
                            <mat-label>NB Redline Status</mat-label>
                            <mat-select formControlName="redlineStatus" multiple>
                                <mat-option *ngFor="let redlineStatus of redlineStatuses" [value]="redlineStatus">{{ redlineStatus }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filter-item">
                        <mat-form-field>
                            <mat-label>Ready For Submission</mat-label>
                            <mat-select
                                placeholder="All"
                                [formGroup]="filterForm"
                                formControlName="redlineReadyForSubmission"
                                aria-label="Ready For Submission"
                                disableOptionCentering
                            >
                                <mat-option [value]="null">All</mat-option>
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="filter-panel-content-container">
                <div class="filters-row col3">
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="workArea"
                        [isAsync]="true"
                        [setInput]="setRedlineWorkAreaSelectorValue"
                        [formSetter]="'workArea'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchRedlineWorkArea"
                        [panelWidth]="480"
                        placeholder="Work Area"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="filter-item">
                        <mat-form-field class="container300 margin-right20 grow">
                            <mat-label>Required For</mat-label>
                            <mat-select formControlName="redlineRequiredFor" multiple>
                                <mat-option *ngFor="let redlineRequiredFor of redlineRequiredFors" [value]="redlineRequiredFor">{{ redlineRequiredFor }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filter-item">
                        <app-multiple-items-selector
                        class="container300 margin-right20 grow"
                        formControlName="redlineNbRespEngineer"
                        [isAsync]="true"
                        [setInput]="setNbResponsibleEngineersSelectorValue"
                        [formSetter]="'redlineNbRespEngineer'"
                        [filterForm]="filterForm"
                        [searchFunc]="searchRedlineNbEngineers"
                        [panelWidth]="480"
                        placeholder="NB Responsible Engineer"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>
