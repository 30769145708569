import { OrderDirection, CheckListColumn, CalendarColumn, NumericColumn } from "../common.model";

export interface LeakTestPackTrackerSearchState {
    dataPagination: LeakTestPackTrackerSearchDataPagination;
    filter: LeakTestPackTrackerSearchFilter;
    isLoading: boolean;
}

export class LeakTestPackTrackerSearchDataPagination {
    items: LeakTestPackTrackerSearchDTO[] = [];
    totalCount = 0;
}

export class LeakTestPackTrackerSearchFilter{
    page = 0;
    take = 25;
    timezoneOffset = 0;
    sortBy = 'leakTestPackTrackerNo';
    direction = OrderDirection.Asc;
    columnLeakTestPackTrackerNo: CheckListColumn = null;
    columnSubsystemName : CheckListColumn = null;
    columnInstrumentSubsystem : CheckListColumn = null;
    columnPreCommStart:CalendarColumn = null;
    columnPreCommFinish:CalendarColumn = null;
    columnCertificateComment: CheckListColumn = null;
    columnITRComment: CheckListColumn = null;
    columnBarcode : CheckListColumn = null;
    columnBITRRemaining: NumericColumn = null;
    columnCITRRemaining: NumericColumn = null;
    columnPLIRemaining: NumericColumn = null;
    columnPriority: CheckListColumn = null;
}




export class LeakTestPackTrackerSearchDTO{

    columnLeakTestPackTrackerNo: CheckListColumn = null;
    columnSubsystemName : CheckListColumn = null;
    columnInstrumentSubsystem : CheckListColumn = null;
    columnPreCommStart:CalendarColumn = null;
    columnPreCommFinish:CalendarColumn = null;
    columnCertificateComment: CheckListColumn = null;
    columnITRComment: CheckListColumn = null;
    columnBarcode : CheckListColumn = null;
    columnBITRRemaining: NumericColumn = null;
    columnCITRRemaining: NumericColumn = null;
    columnPLIRRemaining: NumericColumn = null;
    instrumentSubsystem: string = '';
    bitrRemaining:Number = 0;
    citrRemaining: Number = 0;
    pliRemaining: Number = 0;
    barcode: String = '';
    activity: String = '';
    leakTestPackTrackerNo: String = '';
    priority: String ='';

}

export class ShowHideLTPColumns {
    showSubsysName = false;
    showPreCommDates = false;
    showBarcode = false;
    showSubsys = true;
    showCertificateComment = true;

}