import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ExcludeFilterService } from 'src/app/services/api/webapi-services/exclude-filter.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from '../model';
import {
    PlanningConfigITRAssignExcludeFiltersError,
    PlanningConfigITRAssignExcludeFiltersRequest,
    PlanningConfigITRAssignExcludeFiltersSuccess,
    PlanningConfigITRExcludeFiltersError,
    PlanningConfigITRExcludeFiltersRequest,
    PlanningConfigITRExcludeFiltersSuccess,
    PlanningConfigSubsystemAssignExcludeFiltersError,
    PlanningConfigSubsystemAssignExcludeFiltersRequest,
    PlanningConfigSubsystemAssignExcludeFiltersSuccess,
    PlanningConfigSubsystemExcludeFiltersError,
    PlanningConfigSubsystemExcludeFiltersRequest,
    PlanningConfigSubsystemExcludeFiltersSuccess,
    PlanningConfigurationActionTypes,
} from './actions';

@Injectable()
export class PlanningConfigurationEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private lookupService: LookupService,
        private excludeFilterService: ExcludeFilterService
    ) {}

    @Effect()
    filterResults$ = this.actions$.pipe(
        ofType<PlanningConfigSubsystemExcludeFiltersRequest>(
            PlanningConfigurationActionTypes.PlanningConfigSubsystemExcludeFiltersRequest
        ),
        withLatestFrom(this.store.select((store) => store.planningConfigState?.subsystemFilter)),
        mergeMap(([action, subsystemfilter]) => {
            return this.lookupService.searchSubsystemsByExcludeFilter(subsystemfilter).pipe(
                map((data) => {
                    return new PlanningConfigSubsystemExcludeFiltersSuccess(data);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while fetching data. Please contact Program Administrator.'
                    );
                    return of(new PlanningConfigSubsystemExcludeFiltersError(error));
                })
            );
        })
    );

    @Effect()
    filterAssignmentResults$ = this.actions$.pipe(
        ofType<PlanningConfigSubsystemAssignExcludeFiltersRequest>(
            PlanningConfigurationActionTypes.PlanningConfigSubsystemAssignExcludeFiltersRequest
        ),
        mergeMap((action) => {
            return this.excludeFilterService
                .assignSubsystemExcludeFilter(action.payload.data, action.payload.isAssignment)
                .pipe(
                    map((data) => {
                        return new PlanningConfigSubsystemAssignExcludeFiltersSuccess();
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while exclude filter assignment. Please contact Program Administrator.'
                        );
                        return of(new PlanningConfigSubsystemAssignExcludeFiltersError(error));
                    })
                );
        })
    );

    @Effect()
    filterAssignmentSuccessResults$ = this.actions$.pipe(
        ofType<PlanningConfigSubsystemAssignExcludeFiltersSuccess>(
            PlanningConfigurationActionTypes.PlanningConfigSubsystemAssignExcludeFiltersSuccess
        ),
        withLatestFrom(this.store.select((store) => store.planningConfigState?.subsystemFilter)),
        mergeMap(([action, subsystemfilter]) => {
            return this.lookupService.searchSubsystemsByExcludeFilter(subsystemfilter).pipe(
                map((data) => {
                    return new PlanningConfigSubsystemExcludeFiltersSuccess(data);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while fetching data. Please contact Program Administrator.'
                    );
                    return of(new PlanningConfigSubsystemExcludeFiltersError(error));
                })
            );
        })
    );

    @Effect()
    itrFilterResults$ = this.actions$.pipe(
        ofType<PlanningConfigITRExcludeFiltersRequest>(
            PlanningConfigurationActionTypes.PlanningConfigITRExcludeFiltersRequest
        ),
        withLatestFrom(this.store.select((store) => store.planningConfigState?.itrFilter)),
        mergeMap(([action, itrfilter]) => {
            return this.lookupService.searchITRsByExcludeFilter(itrfilter).pipe(
                map((data) => {
                    return new PlanningConfigITRExcludeFiltersSuccess(data);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while fetching data. Please contact Program Administrator.'
                    );
                    return of(new PlanningConfigITRExcludeFiltersError(error));
                })
            );
        })
    );

    @Effect()
    itrFilterAssignmentResults$ = this.actions$.pipe(
        ofType<PlanningConfigITRAssignExcludeFiltersRequest>(
            PlanningConfigurationActionTypes.PlanningConfigITRAssignExcludeFiltersRequest
        ),
        mergeMap((action) => {
            return this.excludeFilterService
                .assignITRExcludeFilter(action.payload.data, action.payload.isAssignment)
                .pipe(
                    map((data) => {
                        return new PlanningConfigITRAssignExcludeFiltersSuccess();
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while exclude filter assignment. Please contact Program Administrator.'
                        );
                        return of(new PlanningConfigITRAssignExcludeFiltersError(error));
                    })
                );
        })
    );

    @Effect()
    itrFilterAssignmentSuccessResults$ = this.actions$.pipe(
        ofType<PlanningConfigITRAssignExcludeFiltersSuccess>(
            PlanningConfigurationActionTypes.PlanningConfigITRAssignExcludeFiltersSuccess
        ),
        withLatestFrom(this.store.select((store) => store.planningConfigState?.itrFilter)),
        mergeMap(([action, itrFilter]) => {
            return this.lookupService.searchITRsByExcludeFilter(itrFilter).pipe(
                map((data) => {
                    return new PlanningConfigITRExcludeFiltersSuccess(data);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while fetching data. Please contact Program Administrator.'
                    );
                    return of(new PlanningConfigITRExcludeFiltersError(error));
                })
            );
        })
    );
}
