import { Component, Inject, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { APP_INSIGHTS_CONFIG, ApplicationInsightsConfig } from './app.config';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ViewportScroller } from '@angular/common';
import { filter, takeUntil } from 'rxjs/operators';
import { UpdateService } from './services/api/webapi-services/prompt-update.service';
import { SignalRService } from './services/api/signalr.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from './store/model';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { getRecentExportEventsRequest } from './store/excel-export/actions';
import { closeImportStatusToasts } from './store/import-logs/actions';
import { synchronizeUser } from './store/user/actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    private readonly _destroying$ = new Subject<void>();

    isIframe = false;
    recentExportEvents$ = this.store.select((store) => store.excelExportState.exportEvents);
    anyUnprocessedEvents$ = this.store.select((store) => store.excelExportState.anyUnprocessedEvents);
    connected$ = this.store.select((store) => store.excelExportState.connected);

    constructor(
        @Inject(APP_INSIGHTS_CONFIG) config: ApplicationInsightsConfig,
        private authService: MsalService,
        private router: Router,
        private viewportScroller: ViewportScroller,
        private broadcastService: MsalBroadcastService,
        private sw: UpdateService,
        public signalRService: SignalRService,
        private store: Store<ApplicationState>
    ) {
        this.sw.checkForUpdates();
        if (config.instrumentationKey) {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: config.instrumentationKey,
                },
            });
            appInsights.loadAppInsights();
            appInsights.trackPageView();
        }
        this.router.events.pipe(filter((e: any) => e instanceof Scroll)).subscribe((e: any) => {
            if (e.position) {
                // backward navigation
                setTimeout(() => this.viewportScroller.scrollToPosition(e.position), 0);
            } else if (e.position === undefined) {
                setTimeout(() => this.viewportScroller.scrollToPosition([0, 5000]), 0);
            } else if (e.anchor) {
                // anchor navigation
                this.viewportScroller.scrollToAnchor(e.anchor);
            } else {
                // forward navigation
                this.viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }

    ngOnInit() {

        this.broadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.store.dispatch(getRecentExportEventsRequest());
                this.signalRService.connectToImportHub();
            });

        this.broadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);
            });

        this.broadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END))
            .subscribe(() => this.store.dispatch(synchronizeUser()));
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.store.dispatch(closeImportStatusToasts());
            });
    }
}
