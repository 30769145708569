import { Injectable } from '@angular/core';
import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SubystemConflictsActionTypes, SubystemConflictsFilterSuccess, SubystemConflictsFilterError } from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';

@Injectable()
export class SubystemConflictsEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private adminPanelService: AdminPanelService,
        private toastService: ToastService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType(SubystemConflictsActionTypes.SubystemConflictsFilterRequest),
        withLatestFrom(this.store.select((store) => store.subsystemConflictsState.filter)),
        mergeMap(([, filter]) => {
            return this.adminPanelService.getChangeDocumentData(filter).pipe(
                map((dataPagination) => {
                    return new SubystemConflictsFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Change documents data. Please contact Program Administrator.'
                    );
                    return of(new SubystemConflictsFilterError(error));
                })
            );
        })
    );
}
