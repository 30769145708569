import { Injectable } from '@angular/core';
import { TagSearchFilter, TagSearchDataPagination } from 'src/app/store/tag-search/model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class TagSearchService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly tagSearchEndpoint: string = '/tagsearch';
    constructor(private http: HttpClient) {}

    searchTag(filter: TagSearchFilter) {
        return this.http.post<TagSearchDataPagination>(`${this.rootUrl}${this.tagSearchEndpoint}`, filter);
    }

    generateExcel(filter: TagSearchFilter) {
        return this.http.post(`${this.rootUrl}${this.tagSearchEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }
}
