import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'noBreakingDash'
})
export class NoBreakingDashPipe implements PipeTransform {
    character ='-';
    transform(value: string): string {
        let regex = new RegExp(this.character, 'g');
        if (value.indexOf(this.character) > 0) {
            return value.replace(regex, '&#8209;');
        } else return value;
    }
}
