import { Action } from '@ngrx/store';
import { CallOffAttachment, ChangeDocumentDetailsDTO, IWPLink } from './model';
import { CommentDetailDTO } from '../detailed-status/model';
import { UserDetail } from '../common.model';

export enum ChangeDocumentDetailsActionTypes {
    GetChangeDocumentDetailsRequest = '[Change Document Details] Get Change Document Details',
    GetChangeDocumentDetailsSuccess = '[Change Document Details] Get Change Document Details Success',
    GetChangeDocumentDetailsError = '[Change Document Details] Get Change Document Details Error',
    ChangeDocumentDetailsAddCommentRequest = '[Change Document Details] Add Comment Request',
    ChangeDocumentDetailsAddCommentSuccess = '[Change Document Details] Add Comment Success',
    ChangeDocumentDetailsAddCommentError = '[Change Document Details] Add Comment Error',
    ChangeDocumentDetailsDeleteCommentRequest = '[Change Document Details] Delete Comment Request',
    ChangeDocumentDetailsDeleteCommentSuccess = '[Change Document Details] Delete Comment Success',
    ChangeDocumentDetailsDeleteCommentError = '[Change Document Details] Delete Comment Error',
    ChangeDocumentDetailsUpdateIwpRequest = '[Change Document Details] Patch Iwp Request',
    ChangeDocumentDetailsUpdateIwpSuccess = '[Change Document Details] Patch Iwp Success',
    ChangeDocumentDetailsUpdateIwpError = '[Change Document Details] Patch Iwp Error',
    ChangeDocumentDetailsGetIwpRequest = '[Change Document Details] Get Iwp Request',
    ChangeDocumentDetailsGetIwpSuccess = '[Change Document Details] Get Iwp Success',
    ChangeDocumentDetailsGetIwpError = '[Change Document Details] Get Iwp Error',
    ChangeDocumentDetailsClearIwpLinks = '[Change Document Details] Clear IWP Links',
    DownloadCallOffAttachmentRequest = '[Change Document Details] Download Call-Off Attachments Request',
    DownloadCallOffAttachmentRequestSuccess = '[Change Document Details] Download Call-Off Attachments Request Success',
    DownloadCallOffAttachmentRequestError = '[Change Document Details] Download Call-Off Attachments Request Error',
}

export class DownloadCallOffAttachmentRequest implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.DownloadCallOffAttachmentRequest;

    constructor(public payload: { attachment: CallOffAttachment; type: string }) {}
}
export class DownloadCallOffAttachmentRequestSuccess implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.DownloadCallOffAttachmentRequestSuccess;

    constructor(public payload: { content: Blob; fileName: string }) {}
}
export class DownloadCallOffAttachmentRequestError implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.DownloadCallOffAttachmentRequestError;

    constructor(public payload: CallOffAttachment) {}
}

export class GetChangeDocumentDetailsRequest implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.GetChangeDocumentDetailsRequest;

    constructor(public payload: { id: string }) {}
}

export class GetChangeDocumentDetailsSuccess implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.GetChangeDocumentDetailsSuccess;

    constructor(public payload: ChangeDocumentDetailsDTO) {}
}

export class GetChangeDocumentDetailsError implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.GetChangeDocumentDetailsError;

    constructor(public payload: string) {}
}

export class ChangeDocumentDetailsAddCommentRequest implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsAddCommentRequest;

    constructor(public payload: { entityId: string; description: string; mentions: UserDetail[] }) {}
}

export class ChangeDocumentDetailsAddCommentSuccess implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsAddCommentSuccess;

    constructor(public payload: CommentDetailDTO) {}
}

export class ChangeDocumentDetailsAddCommentError implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsAddCommentError;

    constructor(public payload: string) {}
}

export class ChangeDocumentDetailsDeleteCommentRequest implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsDeleteCommentRequest;

    constructor(public payload: { id: number }) {}
}

export class ChangeDocumentDetailsDeleteCommentSuccess implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsDeleteCommentSuccess;

    constructor(public payload: { id: number }) {}
}

export class ChangeDocumentDetailsDeleteCommentError implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsDeleteCommentError;

    constructor(public payload: string) {}
}

export class ChangeDocumentDetailsGetIwpRequest implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsGetIwpRequest;

    constructor(public payload: { id: number }) {}
}

export class ChangeDocumentDetailsGetIwpSuccess implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsGetIwpSuccess;

    constructor(public payload: IWPLink) {}
}

export class ChangeDocumentDetailsGetIwpError implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsGetIwpError;

    constructor(public payload: string) {}
}

export class ChangeDocumentDetailsUpdateIwpRequest implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsUpdateIwpRequest;

    constructor(public payload: IWPLink) {}
}

export class ChangeDocumentDetailsUpdateIwpSuccess implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsUpdateIwpSuccess;
}

export class ChangeDocumentDetailsUpdateIwpError implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsUpdateIwpError;

    constructor(public payload: string) {}
}

export class ChangeDocumentDetailsClearIwpLinks implements Action {
    readonly type = ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsClearIwpLinks;
}

export type ChangeDocumentDetailsActions =
    | GetChangeDocumentDetailsRequest
    | GetChangeDocumentDetailsSuccess
    | GetChangeDocumentDetailsError
    | ChangeDocumentDetailsAddCommentRequest
    | ChangeDocumentDetailsAddCommentSuccess
    | ChangeDocumentDetailsAddCommentError
    | ChangeDocumentDetailsDeleteCommentRequest
    | ChangeDocumentDetailsDeleteCommentSuccess
    | ChangeDocumentDetailsDeleteCommentError
    | ChangeDocumentDetailsUpdateIwpRequest
    | ChangeDocumentDetailsUpdateIwpSuccess
    | ChangeDocumentDetailsUpdateIwpError
    | DownloadCallOffAttachmentRequest
    | DownloadCallOffAttachmentRequestSuccess
    | DownloadCallOffAttachmentRequestError
    | ChangeDocumentDetailsGetIwpRequest
    | ChangeDocumentDetailsGetIwpSuccess
    | ChangeDocumentDetailsGetIwpError
    | ChangeDocumentDetailsClearIwpLinks;
