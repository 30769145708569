import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { ForecastHistoryDateType} from 'src/app/enums';
import { ForecastDateHistoryRequest, ExceptionForecastDateHistoryRequest } from 'src/app/store/forecast-history/actions';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-forecast-history',
  templateUrl: './forecast-history.component.html',
  styleUrls: ['./forecast-history.component.scss']
})
export class ForecastHistoryComponent extends BaseComponent implements OnInit {
  displayedColumns: string[] = ['date', 'updateDate', 'userName'];
  dataSource = new MatTableDataSource();
  isLoading = false;
  forecastHistory$ = this.store.select((state) => state.forecastHistoryState.data);
  isLoading$ = this.store.select((state) => state.forecastHistoryState.isLoading);

  dateType: ForecastHistoryDateType;
  subsystem: string;
  rfo: string;
  discipline: string;
  allDisciplines: boolean;
  number: string;
  tableType : string = "";
  scope: number;
  itrId: number;
  pliOrBracode: string;
  
  constructor(public dialogRef: MatDialogRef<ForecastHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private store: Store<ApplicationState>) {
    super();

    this.subsystem = data.subsystem;
    this.discipline = data.discipline;
    this.allDisciplines = !data.discipline;
    this.number = data.number;
    this.rfo = data.rfo;
    this.scope = data.scope ?? 0;
    this.itrId = data.itrId ?? 0;
    this.pliOrBracode = data.pliOrBarcode;

    let type;
    switch (data.dateType) {
      case 'mc':
        type = ForecastHistoryDateType.MCForecast;
        break;
      case 'rfsu':
        type = ForecastHistoryDateType.RFSUForecast;
        break;
      case 'mcWalkdown':
        type = ForecastHistoryDateType.MCWalkdownForecast;
        break;
      case 'rfsuWalkdown':
        type = ForecastHistoryDateType.RFSUWalkdownForecast;
        break;
      case 'constr90Walkdown':
        type = ForecastHistoryDateType.Construction90Walkdown;
        break;
      case 'rfo':
        type = ForecastHistoryDateType.RFOForeCastDate;
        break;
      case 'pliForecastClosureDate':
        type = ForecastHistoryDateType.PLIForecastClosureDate;
        break;
      case 'postRFOExpectedClosureDate':
          type = ForecastHistoryDateType.PostRFOExpectedClosureDate;
          this.tableType = 'postRFOExpectedClosureDate';
          break;     
      case 'forecastClosureDate':
        type = ForecastHistoryDateType.ExceptionForecastClosureDate;
        break;
    }

    this.dateType = type;

  }

  ngOnInit(): void {

    this.forecastHistory$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data)
      });

    this.isLoading$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((loading) => this.isLoading = loading);

    if (this.dateType === ForecastHistoryDateType.ExceptionForecastClosureDate) {
      this.store.dispatch(
        new ExceptionForecastDateHistoryRequest(
          {
            number: this.number,
            pliOrBarcode: this.pliOrBracode,
            itrId: this.itrId,
            scope: this.scope
          }));
    } else {
      this.store.dispatch(
        new ForecastDateHistoryRequest(
          {
          rfo: this.rfo,
            subsystem: this.subsystem,
            type: this.dateType,
            discipline: this.discipline,
            allDisciplines: this.allDisciplines,
            number: this.number != null ? this.number : "",
          }));
    }


  }

  public onCancel(): void {
    this.dialogRef.close();
  }

}
