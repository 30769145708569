import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { throwError } from 'rxjs';
import { catchError, finalize, take, takeWhile } from 'rxjs/operators';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ActivityFocusService } from 'src/app/services/api/webapi-services/activity-focus.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ActivityPriorityDTO } from 'src/app/store/common.model';
import { ActivityFocusValidators } from 'src/app/validators/activity-focus.validators';
import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-sc-priority-configuration',
    templateUrl: './sc-priority-configuration.component.html',
    styleUrls: ['./sc-priority-configuration.component.scss'],
})
export class ScPriorityConfigurationComponent extends BaseComponent implements OnInit {
    updateTitle = 'edit activity focus';
    updateText = 'update';
    loading = false;
    scPriorityForm: UntypedFormGroup;
    scPriorityFormInitialForm = new ActivityPriorityDTO();
    isInEditMode = false;
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setPriorityFormInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    updatedProperties: string[] = [];

    constructor(
        private formService: FormService,
        private activityFocusValidators: ActivityFocusValidators,
        private lookupService: LookupService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private activityFocusService: ActivityFocusService,
        private popupService: PopupService,
    ) {
        super();
        this.scPriorityForm = this.formService.createForm(new ActivityPriorityDTO(), this.activityFocusValidators);
    }

    ngOnInit() {
        this.scPriorityForm.controls.priorityName.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((value) => {
                if (value.length === 0) {
                    return;
                }
                this.setSubsystemInput.emit();

                this.loading = true;
                this.activityFocusService
                    .getActivityFocusByName(value[0].id)
                    .pipe(
                        take(1),
                        finalize(() => (this.loading = false)),
                        catchError((err) => {
                            this.toastService.Error(
                                'Error has occurred while getting activity focus. Please contact Program Administrator.'
                            );
                            return throwError(err);
                        })
                    )
                    .subscribe((data) => {
                        let form = {
                            priorityName: data.priorityName ? [{ id: data.priorityName }] : [],
                            activityFocusSubsystems: data.activityFocusSubsystems,
                        };
                        this.scPriorityForm.patchValue(form, { emitEvent: false });
                        this.isInEditMode = true;
                        this.setInitialForm();
                        this.changeDetectorRef.detectChanges();
                    });
            });
        this.watchFormChanges();
    }

    watchFormChanges() {
        for (const key of Object.keys(this.scPriorityForm.controls)) {
            this.scPriorityForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                const updatedProperties = this.getUpdatedProperties();
                if (Array.isArray(value)) {
                    value = Object.assign([], value);
                }
                this.updatedProperties = updatedProperties;
            });
        }
    }

    clearFormValues() {
        this.isInEditMode = false;
        this.scPriorityForm.controls.priorityName.setValue([]);
        this.scPriorityForm.controls.activityFocusSubsystems.setValue([]);
    }

    getUpdatedProperties(): string[] {
        const result = [];
        if (!this.isNotNullAndNotUndefined(this.scPriorityFormInitialForm.priorityName)) {
            return result;
        }
        for (const key of Object.keys(this.scPriorityForm.controls)) {
            if (
                (!this.isNotNullAndNotUndefined(this.scPriorityFormInitialForm[key]) ||
                    this.scPriorityFormInitialForm[key] === '') &&
                (!this.isNotNullAndNotUndefined(this.scPriorityForm.controls[key].value) ||
                    this.scPriorityForm.controls[key].value === '')
            ) {
                continue;
            }
            if (
                !this.compareArraysOfObjectsByProperty(
                    this.scPriorityFormInitialForm[key],
                    this.scPriorityForm.controls[key].value,
                    'id'
                )
            ) {
                result.push(key);
            }
        }
        return result;
    }

    compareArraysOfObjectsByProperty(array1: {}[], array2: {}[], property: string) {
        if (!Array.isArray(array1) || !Array.isArray(array2) || array1.length !== array2.length) return false;

        const a1 = _.sortBy(array1, property);
        const a2 = _.sortBy(array2, property);
        var result = true;

        a1.forEach((element, i) => {
            result = result && element[property] === a2[i][property];
        });
        return result;
    }

    setInitialForm() {
        if (this.isNotNullAndNotUndefined(this.scPriorityForm.value.priorityName)) {
            for (const key of Object.keys(this.scPriorityForm.controls)) {
                this.scPriorityFormInitialForm[key] = JSON.parse(
                    JSON.stringify(this.scPriorityForm.controls[key].value)
                );
            }
        }
        this.updatedProperties = [];
    }

    setSubsystems(subsystems: any[]) {
        this.scPriorityForm.controls.activityFocusSubsystems.setValue(subsystems, { emitEvent: false });
        const updatedProperties = this.getUpdatedProperties();
        this.updatedProperties = updatedProperties;
    }

    onConfirm() {

      let entity = new ActivityPriorityDTO();
      entity.priorityName = this.scPriorityForm.controls.priorityName.value[0]?.id;
      entity.activityFocusSubsystems = this.scPriorityForm.controls.activityFocusSubsystems.value;

      this.popupService
          .openPopup(
              new ConfirmDialogPopupSettings({
                  title: `update activity focus`,
                  text: `Are you sure you want to update '${
                      entity.priorityName
                  }' activity focus?`,
              })
          )
          .afterClosed()
          .pipe(takeWhile(() => this.isAlive === true))
          .subscribe((answer) => {
              if (answer) {
                  this.loading = true;
                      this.activityFocusService
                          .updateActivityFocus(entity)
                          .pipe(
                              takeWhile(() => this.isAlive),
                              finalize(() => (this.loading = false)),
                              catchError((err) => {
                                  this.toastService.Error(
                                      'Error has occurred while updating Activity focus. Please contact Program Administrator.'
                                  );
                                  return throwError(err);
                              })
                          )
                          .subscribe(() => {
                              this.toastService.Success('Activity focus updated correctly.');
                              this.isInEditMode = true;
                              this.setInitialForm();
                          });
              }
          });
  }

    getPriorities = (search = '', take = 10, page = 0) => {
        return this.lookupService.getActivityFocusTypes(search, take, page);
    };
}

