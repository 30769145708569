import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { downloadExportedFileRequest } from 'src/app/store/excel-export/actions';
import { ExportEvent } from 'src/app/store/excel-export/model';
import { ApplicationState } from 'src/app/store/model';

@Component({
    selector: 'app-excel-export-list',
    templateUrl: './excel-export-list.component.html',
    styleUrls: ['./excel-export-list.component.scss']
})
export class ExcelExportListComponent implements OnInit, OnChanges {

    @Input() expanded: boolean = false;
    @Input() items: ExportEvent[] = [];
    @Input() collectionName: string = "export files";
    @Input() connected: boolean = true;
    showError = false;

    tooltips = {
        Success: 'Excel file was processed succesfully.',
        New: 'Excel file is queued for processing. Download would start automatically when the file is ready.',
        Error: 'There was an error while processing your export file.'
    };
    classes  = {
        Success: 'item-success',
        New: 'item-progress',
        Error: 'item-error'
    };

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store<ApplicationState>) {
            this.iconRegistry.addSvgIcon(
                'attention',
                this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/attention.svg')
            );
            this.iconRegistry.addSvgIcon(
                'downloadcircle',
                this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/download-circle.svg')
            );
            this.iconRegistry.addSvgIcon(
                'loadingcircle',
                this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/loading-circle.svg')
            );
            this.iconRegistry.addSvgIcon(
                'errorcircle',
                this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/error.svg')
            );
        }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            this.setShowError();
        }
    }

    ngOnInit(): void {
        this.setShowError();
    }

    setShowError() {
        this.showError = this.items.some(x => x.status === 'Error');
    }

    toggle() {
        this.expanded = !this.expanded;
    }

    download(exportEvent: ExportEvent) {
        this.store.dispatch(downloadExportedFileRequest({ exportEvent }));
    }
}
