<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">Subsystem MC Dashboard 1</div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.goocs.value.length" removable>
                                GOOC: {{ displayGoocsSelected(filterForm.controls.goocs.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('goocs')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcEngineer.value.length" removable>
                                MC Engineer: {{ displaySelectedMCEngineer(filterForm.controls.mcEngineer.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('mcEngineer')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.systemOwner.value.length" removable>
                                System Owner: {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('systemOwner')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.stagedStartUpPriorities.value.length" removable>
                                Staged Start Up Priority:
                                {{ displayMultipleSelected(filterForm.controls.stagedStartUpPriorities.value) }}
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.scManager.value.length" removable>
                                SC Execution Area Manager: {{ displaySelectedSCManager(filterForm.controls.scManager.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('scManager')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <app-multiple-items-selector
                        class="container620 margin-right20"
                        [setInput]="setGoocInput"
                        formControlName="goocs"
                        [searchFunc]="getGoocs"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="goocAutocompleteDisplayedColumns"
                        [filterForm]="filterForm"
                        [formSetter]="'goocs'"
                        [propertyToShow]="'no'"
                        [placeholder]="'GOOC'"
                    >
                    </app-multiple-items-selector>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>MC Engineer</mat-label>
                        <mat-select formControlName="mcEngineer" multiple>
                            <mat-option *ngFor="let mce of mcEngineers" [value]="mce.id">{{ mce.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>SC Execution Area Manager</mat-label>
                        <mat-select formControlName="scManager" multiple>
                            <mat-option *ngFor="let sc of scManagers" [value]="sc.id">{{ sc.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>System Owner</mat-label>
                        <mat-select formControlName="systemOwner" multiple>
                            <mat-option *ngFor="let so of sysOwners" [value]="so.id">{{ so.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="container300 margin-right20">
                        <app-multiple-items-selector
                            [setInput]="setContractorInput"
                            formControlName="contractors"
                            [searchFunc]="getContractors"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            (autocompleteClosed)="onContractorsClosed()"
                            (itemRemoved)="onContractorsClosed()"
                            [filterForm]="filterForm"
                            formSetter="contractors"
                            propertyToShow="contractNo"
                            placeholder="MSL Contractor"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300">
                        <app-multiple-items-selector
                            [setInput]="stagedStartUpPrioritiesInput"
                            formControlName="stagedStartUpPriorities"
                            [searchFunc]="getStagedStartUpPriorities"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="stagedStartUpPriorityAutocompleteDisplayedColumns"
                            (autocompleteClosed)="onStagedStartUpPrioritiesClosed()"
                            (itemRemoved)="onStagedStartUpPrioritiesClosed()"
                            [filterForm]="filterForm"
                            formSetter="stagedStartUpPriorities"
                            propertyToShow="priority"
                            placeholder="Staged Start-up Priority"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'priorityName'"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div *ngIf="isUsersPerProjectLoading" class="filter-loader">
                    <div class="lds-hourglass"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
    <div class="buttons-container flex">
        <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
            export to excel
        </button>
        <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
            reset filter
        </button>
        <button type="button" (click)="search()" class="sct-button sct-button-light">
            search
        </button>
    </div>
    <div class="results-container">
        <div
            [ngClass]="{
                'scroll-container': scrollSearch
            }"
        >
            <table
                mat-table
                [dataSource]="data"
                matSort
                [matSortActive]="sortBy"
                matSortDisableClear
                [matSortDirection]="direction"
            >
                <ng-container matColumnDef="subsystemNo">
                    <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef>Subsystem</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.subsystemNo }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="subsystemName">
                    <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef>
                        Subsystem Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.subsystemName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="mcPlanDate">
                    <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef>
                        MC Plan Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.mcPlanDate | date: 'd/MMM/yy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="mcForecast">
                    <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef>
                        MC Forecast
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.mcForecast | date: 'd/MMM/yy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="mcActualDate">
                    <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef>
                        MC Actual
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.mcActualDate | date: 'd/MMM/yy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="aqvd">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>A-QVDs</div>
                        <div>
                            <div class="sort-container hover-pointer total" (click)="applySort('AQVDsTotal')">
                                <span>
                                    Total
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'AQVDsTotal'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('AQVDsComplete')" class="sort-container hover-pointer">
                                <span>Complete</span>
                                <div class="sort-icon" *ngIf="sortBy === 'AQVDsComplete'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('AQVDsRemaining')" class="sort-container hover-pointer">
                                <span>Remaining</span>
                                <div class="sort-icon" *ngIf="sortBy === 'AQVDsRemaining'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('AQVDsExceptions')" class="sort-container hover-pointer">
                                <span>Exceptions</span>
                                <div class="sort-icon" *ngIf="sortBy === 'AQVDsExceptions'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('AQVDsPercentage')" class="sort-container hover-pointer percent">
                                <span>% </span>
                                <div class="sort-icon" *ngIf="sortBy === 'AQVDsPercentage'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div class="total">{{ element.aqvd?.total }}</div>
                            <div>{{ element.aqvd?.complete }}</div>
                            <div>
                                <span
                                    class="detailed-status-link"
                                    (click)="openDetailedStatusPage(element.subsystemNo, 'AQVD')"
                                >
                                    {{ element.aqvd?.remaining }}
                                </span>
                            </div>
                            <div>
                                <span
                                    class="detailed-status-link"
                                    (click)="openDetailedStatusPage(element.subsystemNo, 'EX')"
                                >
                                    {{ element.aqvd?.exceptions }}
                                </span>
                            </div>
                            <div class="percent">{{ element.aqvd?.percentComplete.replace(',', '.') | number: '1.0-0' }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="redlines">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>Redlines</div>
                        <div>
                            <div (click)="applySort('RedlinesTotal')" class="sort-container hover-pointer total">
                                <span>Total</span>
                                <div class="sort-icon" *ngIf="sortBy === 'RedlinesTotal'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('RedlinesComplete')" class="sort-container hover-pointer">
                                <span>Complete</span>
                                <div class="sort-icon" *ngIf="sortBy === 'RedlinesComplete'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('RedlinesRemaining')" class="sort-container hover-pointer">
                                <span>Remaining</span>
                                <div class="sort-icon" *ngIf="sortBy === 'RedlinesRemaining'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('RedlinesPercentage')" class="sort-container hover-pointer percent">
                                <span>%</span>
                                <div class="sort-icon" *ngIf="sortBy === 'RedlinesPercentage'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div class="total">{{ element.redlines?.total }}</div>
                            <div>{{ element.redlines?.complete }}</div>
                            <div>
                                <span
                                    class="detailed-status-link"
                                    (click)="openDetailedStatusPage(element.subsystemNo, 'Redline', undefined, 'Red')"
                                >
                                    {{ element.redlines?.remaining }}
                                </span>
                            </div>
                            <div class="percent">{{ element.redlines?.percentComplete.replace(',', '.') | number: '1.0-0' }}%</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="pli">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>PLI</div>
                        <div>
                            <div (click)="applySort('APLIOpen')" class="sort-container hover-pointer">
                                <span>A Open</span>
                                <div class="sort-icon" *ngIf="sortBy === 'APLIOpen'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('BPLIOpen')" class="sort-container hover-pointer">
                                <span>B Open</span>
                                <div class="sort-icon" *ngIf="sortBy === 'BPLIOpen'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('CPLIOpen')" class="sort-container hover-pointer">
                                <span>C Open</span>
                                <div class="sort-icon" *ngIf="sortBy === 'CPLIOpen'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('DPLIOpen')" class="sort-container hover-pointer">
                                <span>D Open</span>
                                <div class="sort-icon" *ngIf="sortBy === 'DPLIOpen'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div>
                                <span
                                    class="detailed-status-link"
                                    (click)="openDetailedStatusPage(element.subsystemNo, 'PLI', 'A')"
                                >
                                    {{ element.pli?.apliOpen }}
                                </span>
                            </div>
                            <div>
                                <span
                                    class="detailed-status-link"
                                    (click)="openDetailedStatusPage(element.subsystemNo, 'PLI', 'B')"
                                >
                                    {{ element.pli?.bpliOpen }}
                                </span>
                            </div>
                            <div>
                                <span
                                    class="detailed-status-link"
                                    (click)="openDetailedStatusPage(element.subsystemNo, 'PLI', 'C')"
                                >
                                    {{ element.pli?.cpliOpen }}
                                </span>
                            </div>
                            <div>
                                <span
                                    class="detailed-status-link"
                                    (click)="openDetailedStatusPage(element.subsystemNo, 'PLI', 'D')"
                                >
                                    {{ element.pli?.dpliOpen }}
                                </span>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="npw">
                    <th mat-header-cell disableClear *matHeaderCellDef>
                        <div>NPW</div>
                        <div>
                            <div (click)="applySort('NPWTotal')" class="sort-container hover-pointer total">
                                <span>Total</span>
                                <div class="sort-icon" *ngIf="sortBy === 'NPWTotal'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('NPWComplete')" class="sort-container hover-pointer">
                                <span>Complete</span>
                                <div class="sort-icon" *ngIf="sortBy === 'NPWComplete'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('NPWRemaining')" class="sort-container hover-pointer">
                                <span>Remaining</span>
                                <div class="sort-icon" *ngIf="sortBy === 'NPWRemaining'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div (click)="applySort('NPWPercentage')" class="sort-container hover-pointer percent">
                                <span>%</span>
                                <div class="sort-icon" *ngIf="sortBy === 'NPWPercentage'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                            <div class="total">{{ element.npw?.total }}</div>
                            <div>{{ element.npw?.complete }}</div>
                            <div>
                                <span
                                    class="detailed-status-link"
                                    (click)="openChangeManagementPage(element.subsystemNo, 'NPW')"
                                >
                                    {{ element.npw?.remaining }}
                                </span>
                            </div>
                            <div class="percent">{{ element.npw?.percentComplete.replace(',', '.') | number: '1.0-0' }}%</div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr
                    [ngClass]="{ total: !row.subsystemNo }"
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </div>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
                [disabled]="scrollSearch"
            >
            </mat-paginator>
            <input
                type="number"
                [value]="this.paginator.pageIndex + 1"
                (change)="onPageChange($event.target.value)"
                [disabled]="scrollSearch"
            />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading$ | async"></app-loading-indicator>
</main>
