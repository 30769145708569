import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/api/webapi-services/user.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'user-synchronization',
    templateUrl: './user-synchronization.component.html',
    styleUrls: ['./user-synchronization.component.scss'],
})
export class UserSynchronizationComponent implements OnInit {
    constructor(private userService: UserService, private router: Router, private toastService: ToastService) {}

    ngOnInit() {
        this.userService.addUserDetails().subscribe(
            () => {
                this.toastService.Success('User has been added.');
            },
            (res) => {
                // status 0 is Edge/IE way out for broken CORS policy.
                // More info: https://stackoverflow.com/questions/50166277/angular-5-http-failure-response-for-unknown-url-0-unknown-error-in-edge-brow?rq=1
                if (res.status !== 302 && res.status !== 0) {
                    this.toastService.Error('Error occurred while adding user. Please contact Program Administrator.');
                }
            }
        );

        const redirectUri = localStorage.getItem('login.request');

        if (redirectUri) {
            const parts = redirectUri.split('/');
            localStorage.removeItem('login.request');
            this.router.navigateByUrl(parts.slice(3, parts.length).join('/'));
        } else {
            this.router.navigate(['/']);
        }
    }
}
