export enum ImportType {
    MasterSubsystemList,
    CWPRegister,
    SubsystemSchedulePlan,
    DCN,
    RFI,
    PLI,
    ITR,
    NCR,
    PCN,
    IWPListing,
    RFO,
    ContractorNCR,
    Surveillance,
    TMOC,
    SystemizedDrawings,
    MACDCN,
    COW,
    Tag,
    Certificate,
    Exception,
    IMDBRedline,
    Loop,
    FlangeManagement,
    Redline,
    RFIIPIMS,
    SID,
    ScheduleActivity,
    ActivityBarcode,
    ExceptionsPriority,
    ITRManhours,
    ITRConstraints,
    PASCR,
    ITRConstraintsContacts,
    CITRPartialProgress,
    ExceptionsUpload,
    CriticalPath,
    PriorityNumbers,
    LeakTestPackTracker,
    Materials,
    SWIFTPLI,
    PliDataUpload,
    ITRCommentUpload  
}
