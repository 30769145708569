import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastService } from 'src/app/services/shared/toast.service';
import { withLatestFrom, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    MilestoneDashboardActionTypes,
    MilestoneDashboardTotalCompleteRequestSuccess,
    MilestoneDashboardTotalCompleteRequestError,
    MCMilestoneDashboardIcicleRequestError,
    MCMilestoneDashboardIcicleRequestSuccess,
    MilestoneDashboardRundownSuccess,
    MilestoneDashboardRundownError,
    MilestoneDashboardSetType,
    MCMilestoneDashboardIcicleRequest,
    RFOMilestoneDashboardIcicleRequest,
    RFOMilestoneDashboardIcicleRequestSuccess,
    RFOMilestoneDashboardIcicleRequestError,
    MilestoneDashboardRedirectToDetailedStatus,
    MilestoneDashboardTotalCompleteRequest,
    MilestoneDashboardCommentsRequest,
    MilestoneDashboardCommentsSuccess,
    MilestoneDashboardCommentsError,
    MilestoneDashboardAddCommentRequest,
    MilestoneDashboardAddCommentSuccess,
    MilestoneDashboardAddCommentError,
    MilestoneDashboardRemoveCommentRequest,
    MilestoneDashboardRemoveCommentSuccess,
    MilestoneDashboardRemoveCommentError,
    MCMilestoneDashboardSkylineRequest,
    MCMilestoneDashboardSkylineError,
    ScheduleRequest,
    ScheduleSuccess,
    ScheduleError,
    ScheduleSaveRequest,
    ScheduleSaveSuccess,
    ScheduleSaveError,
    MCMilestoneDashboardSkylineSuccess,
    MCMilestoneDashboardLookaheadRequest,
    MCMilestoneDashboardLookaheadSuccess,
    MCMilestoneDashboardLookaheadError,
    MCMilestoneDashboardSubsystemTableRequest,
    MCMilestoneDashboardSubsystemTableError,
    MCMilestoneDashboardSubsystemTableSuccess,
    RFSUMilestoneDashboardSkylineRequest,
    RFSUMilestoneDashboardSkylineSuccess,
    RFSUMilestoneDashboardSkylineError,
    RFSUMilestoneDashboardLookaheadRequest,
    RFSUMilestoneDashboardLookaheadSuccess,
    RFSUMilestoneDashboardLookaheadError,
} from './actions';
import { MilestoneDashboardService } from 'src/app/services/api/webapi-services/reports/milestone-dashboard.service';
import { IcicleService } from 'src/app/services/api/webapi-services/reports/icicle.service';
import { IcicleFilter } from '../icicle/model';
import { MilestoneDashboardType } from './model';
import { PhaseType } from 'src/app/enums';
import { Router } from '@angular/router';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT } from '../planning/model';
import { PlanningService } from 'src/app/services/api/webapi-services/reports/planning.service';
import { SubsystemMCDashboardFilter } from '../subsystem-mc/model';

@Injectable()
export class MilestoneDashboardEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private milestoneDashboardService: MilestoneDashboardService,
        private toastService: ToastService,
        private icicleService: IcicleService,
        private router: Router,
        private commentService: CommentService,
        private planningService: PlanningService
    ) {}

    @Effect()
    totalCompleteRequest$ = this.actions$.pipe(
        ofType(MilestoneDashboardActionTypes.MilestoneDashboardTotalCompleteRequest),
        withLatestFrom(
            this.store.select((store) => store.milestoneDashboardState.filters),
            this.store.select((state) => state.milestoneDashboardState.dashboardType)
        ),
        switchMap(([action, milestoneDashboardFilters, dashboardType]) => {
            const type = dashboardType === MilestoneDashboardType.MC ? 'mc' : 'rfo';
            const milestoneDashboardFilter = milestoneDashboardFilters[type];
            return this.milestoneDashboardService
                .filterTotalCompleteData(milestoneDashboardFilter, dashboardType ?? MilestoneDashboardType.MC)
                .pipe(
                    map((data) => new MilestoneDashboardTotalCompleteRequestSuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error has occurred while getting Milestone Dashboard Total Complete table data. Please contact Program Administrator.`
                        );
                        return of(new MilestoneDashboardTotalCompleteRequestError(error));
                    })
                );
        })
    );

    @Effect()
    mcIcicleRequest$ = this.actions$.pipe(
        ofType<MCMilestoneDashboardIcicleRequest>(MilestoneDashboardActionTypes.MCMilestoneDashboardIcicleRequest),
        withLatestFrom(
            this.store.select((store) => store.milestoneDashboardState.dashboardType),
            this.store.select((store) => store.milestoneDashboardState.filters),
            this.store.select((store) => store.milestoneDashboardState.startDate)
        ),
        switchMap(([action, dashboardType, milestoneDashboardFilters, startDate]) => {
            const icicleFilter = new IcicleFilter();
            const type = dashboardType === MilestoneDashboardType.MC ? 'mc' : 'rfo';
            const milestoneDashboardFilter = milestoneDashboardFilters[type];
            icicleFilter.mcMilestones = milestoneDashboardFilter.mcMilestones;
            icicleFilter.subsystems = milestoneDashboardFilter.subsystems;
            icicleFilter.goocs = milestoneDashboardFilter.goocs;
            icicleFilter.projectTeamNames = milestoneDashboardFilter.projectTeamNames;
            icicleFilter.contractors = milestoneDashboardFilter.contractors;
            icicleFilter.mcEngineer = milestoneDashboardFilter.mcEngineer;
            icicleFilter.systemOwner = milestoneDashboardFilter.systemOwner;
            return this.icicleService.getIcicleForecastData(icicleFilter, PhaseType.MC, startDate).pipe(
                switchMap((data) => {
                    if (data.length === 0) {
                        return this.icicleService
                            .getIciclePlanData(icicleFilter, PhaseType.MC, startDate)
                            .pipe(
                                map(
                                    (planData) =>
                                        new MCMilestoneDashboardIcicleRequestSuccess({ data: planData, type: 'Plan' })
                                )
                            );
                    } else return of(new MCMilestoneDashboardIcicleRequestSuccess({ data: data, type: 'Forecast' }));
                }),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting icicle chart data. Please contact Program Administrator.`
                    );
                    return of(new MCMilestoneDashboardIcicleRequestError(error));
                })
            );
        })
    );

    @Effect()
    rfoIcicleRequest$ = this.actions$.pipe(
        ofType(MilestoneDashboardActionTypes.RFOMilestoneDashboardIcicleRequest),
        withLatestFrom(
            this.store.select((store) => store.milestoneDashboardState.dashboardType),
            this.store.select((store) => store.milestoneDashboardState.filters),
            this.store.select((store) => store.milestoneDashboardState.startDate)
        ),
        switchMap(([action, dashboardType, milestoneDashboardFilters, startDate]) => {
            const icicleFilter = new IcicleFilter();
            const type = dashboardType === MilestoneDashboardType.MC ? 'mc' : 'rfo';
            const milestoneDashboardFilter = milestoneDashboardFilters[type];
            icicleFilter.rfos = milestoneDashboardFilter.rfos;
            icicleFilter.subsystems = milestoneDashboardFilter.subsystems;
            icicleFilter.projectTeamNames = milestoneDashboardFilter.projectTeamNames;
            icicleFilter.contractors = milestoneDashboardFilter.contractors;
            icicleFilter.mcEngineer = milestoneDashboardFilter.mcEngineer;
            icicleFilter.systemOwner = milestoneDashboardFilter.systemOwner;
            return this.icicleService.getIcicleForecastData(icicleFilter, PhaseType.RFSU, startDate).pipe(
                switchMap((data) => {
                    if (data.length === 0) {
                        return this.icicleService
                            .getIciclePlanData(icicleFilter, PhaseType.RFSU, startDate)
                            .pipe(
                                map(
                                    (planData) =>
                                        new RFOMilestoneDashboardIcicleRequestSuccess({ data: planData, type: 'Plan' })
                                )
                            );
                    } else return of(new RFOMilestoneDashboardIcicleRequestSuccess({ data: data, type: 'Forecast' }));
                }),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting icicle chart data. Please contact Program Administrator.`
                    );
                    return of(new RFOMilestoneDashboardIcicleRequestError(error));
                })
            );
        })
    );

    @Effect()
    icicleRequestByType$ = this.actions$.pipe(
        ofType<MilestoneDashboardSetType>(MilestoneDashboardActionTypes.MilestoneDashboardSetType),
        switchMap(() => [new MilestoneDashboardTotalCompleteRequest(), new MilestoneDashboardCommentsRequest()])
    );

    @Effect()
    rundownRequest$$ = this.actions$.pipe(
        ofType(MilestoneDashboardActionTypes.MilestoneDashboardRundownRequest),
        withLatestFrom(
            this.store.select((store) => store.milestoneDashboardState.dashboardType),
            this.store.select((store) => store.milestoneDashboardState.filters),
            this.store.select((store) => store.milestoneDashboardState.monthViewEnabled)
        ),
        switchMap(([, dashboardType, filters, monthlyViewEnabled]) => {
            const type = dashboardType === MilestoneDashboardType.MC ? 'mc' : 'rfo';
            const filter = filters[type];
            return this.milestoneDashboardService.filterRundownData(filter, monthlyViewEnabled).pipe(
                map((data) => new MilestoneDashboardRundownSuccess(data)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Milestone Dashboard rundown data. Please contact Program Administrator.'
                    );
                    return of(new MilestoneDashboardRundownError(error));
                })
            );
        })
    );

    @Effect()
    milestoneDashboardOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(MilestoneDashboardActionTypes.MilestoneDashboardWeekRangeSet),
        withLatestFrom(
            this.store.select((store) => store.milestoneDashboardState.dashboardType),
            this.store.select((store) => store.milestoneDashboardState.filters)
        ),
        map(([action, dashboardType, filters]) => {
            const type = dashboardType === MilestoneDashboardType.MC ? 'mc' : 'rfo';
            const filter = filters[type];
            const goocs = filter?.goocs;
            const rfos = filter?.rfos;
            if (dashboardType === MilestoneDashboardType.MC && goocs?.length > 0) {
                return new MCMilestoneDashboardIcicleRequest();
            }
            if (dashboardType === MilestoneDashboardType.RFO && rfos?.length > 0) {
                return new RFOMilestoneDashboardIcicleRequest();
            }
        })
    );

    @Effect({ dispatch: false })
    redirectToDetailedStatus$ = this.actions$.pipe(
        ofType<MilestoneDashboardRedirectToDetailedStatus>(
            MilestoneDashboardActionTypes.MilestoneDashboardRedirectToDetailedStatus
        ),
        withLatestFrom(
            this.store.select((store) => store.milestoneDashboardState.filters),
            this.store.select((state) => state.milestoneDashboardState.dashboardType)
        ),
        map(([action, filters, dashboardType]) => {
            const type = dashboardType === MilestoneDashboardType.MC ? 'mc' : 'rfo';
            const filter = filters[type];
            let scope = action.payload.category;
            let pliCategory = undefined;
            let exScope = undefined;
            let subsystemScope = undefined;
            const subsystems = filter.subsystems.map((s) => s.id);
            const mcMilestones = filter.mcMilestones.map((m) => m.name);
            const goocs = filter.goocs.map((g) => g.no);
            const projectTeamNames = filter.projectTeamNames;
            const mcEngineers = filter.mcEngineer;
            const systemOwners = filter.systemOwner;
            const contractors = filter.contractors.map((m) => m.name);
            const rfos = filter.rfos.map((m) => m.name);
            const stagedStartUpPriorities = filter.stagedStartUpPriorities.map((p) => p.priority);

            if (scope.indexOf('-') === 1) {
                scope = scope.replace('-', '');
                subsystemScope = scope;
                exScope = 'ITR';
            } else if (scope.indexOf('PLI') > 0) {
                pliCategory = scope[0];
                scope = 'PLI';
                exScope = scope;
            }

            if (scope === 'NPW') {
                this.router.navigate(['changemanagement'], {
                    queryParams: {
                        lockFilters: true,
                        changeType: scope,
                        discipline: action.payload.discipline,
                        subsystems: subsystems.length ? subsystems : undefined,
                        mcMilestones: mcMilestones.length ? mcMilestones : undefined,
                        goocs: goocs.length ? goocs : undefined,
                        projectTeamNames: projectTeamNames.length ? projectTeamNames : undefined,
                        mcEngineers: mcEngineers.length ? mcEngineers : undefined,
                        systemOwners: systemOwners.length ? systemOwners : undefined,
                        contractors: contractors.length ? contractors : undefined,
                        rfos: rfos.length ? rfos : undefined,
                        remaining: true,
                    },
                });
                return;
            }
            this.router.navigate(['detailedstatus'], {
                queryParams: {
                    lockFilters: true,
                    scope: action.payload.exceptions ? 'EX' : scope,
                    discipline: action.payload.discipline,
                    category: pliCategory,
                    exceptionPhases: action.payload.exceptions
                        ? dashboardType === MilestoneDashboardType.MC
                            ? [
                                  'Mechanical Completion',
                                  'Ready For Start Up',
                                  'Ready for Operations',
                                  'Pre Startup Safety Review',
                              ]
                            : ['Ready for Operations', 'Pre Startup Safety Review']
                        : undefined,
                    subsystems: subsystems.length ? subsystems : undefined,
                    mcMilestones: mcMilestones.length ? mcMilestones : undefined,
                    goocs: goocs.length ? goocs : undefined,
                    projectTeamNames: projectTeamNames.length ? projectTeamNames : undefined,
                    exScope,
                    subsystemScope: action.payload.exceptions ? subsystemScope : undefined,
                    mcEngineers: mcEngineers.length ? mcEngineers : undefined,
                    systemOwners: systemOwners.length ? systemOwners : undefined,
                    contractors: contractors.length ? contractors : undefined,
                    stagedStartUpPriorities: stagedStartUpPriorities.length ? stagedStartUpPriorities : undefined,
                    rfos: rfos.length ? rfos : undefined,
                    zone: action.payload.zone,
                },
            });
        })
    );

    @Effect()
    getComments$ = this.actions$.pipe(
        ofType<MilestoneDashboardCommentsRequest>(MilestoneDashboardActionTypes.MilestoneDashboardCommentsRequest),
        withLatestFrom(
            this.store.select((store) => store.milestoneDashboardState.filters),
            this.store.select((state) => state.milestoneDashboardState.dashboardType)
        ),
        switchMap(([, filters, dashboardType]) => {
            const type = dashboardType === MilestoneDashboardType.MC ? 'mc' : 'rfo';
            const filter = filters[type];
            if (
                (filter.goocs.length === 0 && dashboardType === MilestoneDashboardType.MC) ||
                (filter.rfos.length === 0 && dashboardType === MilestoneDashboardType.RFO)
            ) {
                return of(new MilestoneDashboardCommentsSuccess([]));
            }
            let param = dashboardType === MilestoneDashboardType.MC ? filter.goocs[0].no : filter.rfos[0].name;
            return this.commentService.getMilestioneComments(param, dashboardType).pipe(
                map((items) => {
                    return new MilestoneDashboardCommentsSuccess(items);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Milestone comments. Please contact Program Administrator.'
                    );
                    return of(new MilestoneDashboardCommentsError(error));
                })
            );
        })
    );

    @Effect()
    addComment$ = this.actions$.pipe(
        ofType<MilestoneDashboardAddCommentRequest>(MilestoneDashboardActionTypes.MilestoneDashboardAddCommentRequest),
        mergeMap(({ payload }) =>
            this.commentService.addCommentMilestone(payload.entityId, payload.description, payload.mentions).pipe(
                map((newComment) => {
                    this.toastService.Success('Comment successfully added.');
                    return new MilestoneDashboardAddCommentSuccess({
                        newComment,
                        type: payload.type,
                        userType: payload.userType,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating Milestone comment. Please contact Program Administrator.'
                    );
                    return of(new MilestoneDashboardAddCommentError(error));
                })
            )
        )
    );

    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<MilestoneDashboardRemoveCommentRequest>(
            MilestoneDashboardActionTypes.MilestoneDashboardRemoveCommentRequest
        ),
        mergeMap(({ payload }) =>
            this.commentService.deleteCommentMilestone(payload.id).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully removed.');
                    return new MilestoneDashboardRemoveCommentSuccess({
                        id: payload.id,
                        type: payload.type,
                        userType: payload.userType,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while removing Milestone comment. Please contact Program Administrator.'
                    );
                    return of(new MilestoneDashboardRemoveCommentError(error));
                })
            )
        )
    );

    @Effect()
    preMCSkylineRequest$ = this.actions$.pipe(
        ofType<MCMilestoneDashboardSkylineRequest>(MilestoneDashboardActionTypes.MCMilestoneDashboardSkylineRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterPreMCSkylineData(action.payload.no).pipe(
                map((skyline) => new MCMilestoneDashboardSkylineSuccess(skyline)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting MC Skyline Plan data. Please contact Program Administrator.'
                    );
                    return of(new MCMilestoneDashboardSkylineError(error));
                })
            )
        )
    );

    @Effect()
    preRFSUSkylineRequest$ = this.actions$.pipe(
        ofType<RFSUMilestoneDashboardSkylineRequest>(
            MilestoneDashboardActionTypes.RFSUMilestoneDashboardSkylineRequest
        ),
        mergeMap((action) =>
            this.milestoneDashboardService.filterPreRFSUSkylineData(action.payload.name).pipe(
                map((skyline) => new RFSUMilestoneDashboardSkylineSuccess(skyline)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting RFSU Skyline Plan data. Please contact Program Administrator.'
                    );
                    return of(new RFSUMilestoneDashboardSkylineError(error));
                })
            )
        )
    );

    @Effect()
    scheduleRequest$ = this.actions$.pipe(
        ofType<ScheduleRequest>(MilestoneDashboardActionTypes.ScheduleRequest),
        mergeMap((action) =>
            this.milestoneDashboardService.filterScheduleData(action.payload.no).pipe(
                map((schedule) => new ScheduleSuccess(schedule)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Schedule data. Please contact Program Administrator.'
                    );
                    return of(new ScheduleError(error));
                })
            )
        )
    );

    @Effect()
    saveSchedule$ = this.actions$.pipe(
        ofType<ScheduleSaveRequest>(MilestoneDashboardActionTypes.ScheduleSaveRequest),
        withLatestFrom(this.store.select((state) => state.milestoneDashboardState.mcMilestoneSchedule.entity?.goocNo)),
        mergeMap(([action, goocNo]) =>
            this.milestoneDashboardService.saveSchedule(goocNo, action.payload).pipe(
                map((schedule) => {
                    this.toastService.Success('Schedule saved successfully.');
                    return new ScheduleSaveSuccess(schedule);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while saving Schedule. Please contact Program Administrator.'
                    );
                    return of(new ScheduleSaveError(error));
                })
            )
        )
    );

    @Effect()
    lookaheadRequest$ = this.actions$.pipe(
        ofType<MCMilestoneDashboardLookaheadRequest>(
            MilestoneDashboardActionTypes.MCMilestoneDashboardLookaheadRequest
        ),
        withLatestFrom(
            this.store.select((store) => _.head(store.milestoneDashboardState.lookahead?.weeks)),
            this.store.select((store) => _.last(store.milestoneDashboardState.lookahead?.weeks)),
            this.store.select((store) => store.milestoneDashboardState.lookahead?.lookaheadType),
            this.store.select((store) => store.milestoneDashboardState.lookahead?.filter)
        ),
        mergeMap(([action, startDate, endDate, lookaheadType, filter]) => {
            const f = {
                ...filter,
                startDate: moment(startDate.date).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                endDate: moment(endDate.date).add(6, 'days').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT),
                goocs: [action.payload],
            };
            return this.planningService.filterPlanningLookaheadData(lookaheadType, f).pipe(
                map((subsystems) => new MCMilestoneDashboardLookaheadSuccess(subsystems)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting MC Milestone Dashboard ${lookaheadType.toUpperCase()} Lookahead data. Please contact Program Administrator.`
                    );
                    return of(new MCMilestoneDashboardLookaheadError(error));
                })
            );
        })
    );

    @Effect()
    rfsulookaheadRequest$ = this.actions$.pipe(
        ofType<RFSUMilestoneDashboardLookaheadRequest>(
            MilestoneDashboardActionTypes.RFSUMilestoneDashboardLookaheadRequest
        ),
        withLatestFrom(
            this.store.select((store) => _.head(store.milestoneDashboardState.rfsulookahead?.weeks)),
            this.store.select((store) => _.last(store.milestoneDashboardState.rfsulookahead?.weeks)),
            this.store.select((store) => store.milestoneDashboardState.rfsulookahead?.lookaheadType),
            this.store.select((store) => store.milestoneDashboardState.rfsulookahead?.filter)
        ),
        mergeMap(([action, startDate, endDate, lookaheadType, filter]) => {
            filter.startDate = moment(startDate.date).format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT);
            filter.endDate = moment(endDate.date).add(6, 'days').format(PLANNING_LOOKAHEAD_API_CALL_DATE_FORMAT);
            filter.rfos = [action.payload];
            return this.planningService.filterPlanningLookaheadData(lookaheadType, filter).pipe(
                map((subsystems) => new RFSUMilestoneDashboardLookaheadSuccess(subsystems)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting RFSU Milestone Dashboard ${lookaheadType.toUpperCase()} Lookahead data. Please contact Program Administrator.`
                    );
                    return of(new RFSUMilestoneDashboardLookaheadError(error));
                })
            );
        })
    );

    @Effect()
    subsystemTableRequest$ = this.actions$.pipe(
        ofType<MCMilestoneDashboardSubsystemTableRequest>(
            MilestoneDashboardActionTypes.MCMilestoneDashboardSubsystemTableRequest
        ),
        mergeMap((action) => {
            const filter = {
                ...new SubsystemMCDashboardFilter(),
                goocs: [action.payload],
                page: -1,
                take: -1,
            };
            return this.milestoneDashboardService.filterSubsystemMC1Data(filter).pipe(
                map((data) => new MCMilestoneDashboardSubsystemTableSuccess(data.items)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Subsystem MC Dashboard data. Please contact Program Administrator.`
                    );
                    return of(new MCMilestoneDashboardSubsystemTableError(error));
                })
            );
        })
    );
}
