import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { ApplicationState } from 'src/app/store/model';
import { WeeklyPlanningFilterReset } from 'src/app/store/weekly-planning/actions';

@Component({
    selector: 'app-weekly-planning-dashboard',
    templateUrl: './weekly-planning-dashboard.component.html',
    styleUrls: ['./weekly-planning-dashboard.component.scss'],
})
export class WeeklyPlanningDashboardComponent implements OnInit {
    isPlanner = false;
    constructor(private store: Store<ApplicationState>, private roleService: RoleService) {}

    ngOnInit(): void {
        this.isPlanner = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.Planners,
            Constants.applicableGroups.ExecutionPlanning,
        ]);
    }

    resetFilter() {
        this.store.dispatch(new WeeklyPlanningFilterReset());
    }
}
