import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';

@Component({
    selector: 'app-skyline-legend',
    templateUrl: './skyline-legend.component.html',
    styleUrls: ['./skyline-legend.component.scss'],
})
export class SkylineLegendComponent {
    flagToggles$ = this.store.select((state) => state.skylineState.flagToggles);
    @Input() skylinePhase = '';
    @Input() skylineType = '';
    constructor(private store: Store<ApplicationState>) {}
}
