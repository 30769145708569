<main class="main">
    <a class="navigator" [routerLink]="['/weeklyplanning']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">
            constraints configuration
        </div>
    </div>

    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>

    <app-constraint-configuration></app-constraint-configuration>

</main>
