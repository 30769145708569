import { Component, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import {
    HealthOverdueExceptionsChartRequest,
    HealthOverdueExceptionsFilterPropertyUpdate,
    HealthOverdueExceptionsTableRequest,
} from 'src/app/store/reports/health/actions';
import { OverdueExceptionLabels } from 'src/app/store/reports/health/model';

@Component({
    selector: 'app-overdue-exceptions-chart',
    templateUrl: './overdue-exceptions-chart.component.html',
    styleUrls: ['./overdue-exceptions-chart.component.scss'],
})
export class OverdueExceptionsChartComponent extends BaseComponent implements OnInit {
    chart$ = this.store.select((state) => state.healthState.overdueExceptions.chart);
    overdueExceptionsFilter$ = this.store.select((state) => state.healthState.overdueExceptions.filter);
    selectedBarLabel = '';

    @ViewChild('chartPanel', { static: true }) chartExpansionPanel: MatExpansionPanel;

    colorScheme = { domain: ['#0b2d71'] };
    customScheme = []; 

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit(): void {
        this.chartExpansionPanel.expanded = true;
        this.customScheme = this.getLabelColor();
        this.subscribeToSelectedBarLabelChange();
        this.store.dispatch(new HealthOverdueExceptionsChartRequest());
    }

    subscribeToSelectedBarLabelChange() {
        this.overdueExceptionsFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            if (filter.selectedBarLabel !== this.selectedBarLabel) {
                this.selectedBarLabel = filter.selectedBarLabel;
            }
        });
    }

    getLabelColor() {
        let result: any[] = [];

        for (const property in OverdueExceptionLabels) {
            if (this.selectedBarLabel === '') {
                result.push({ name: OverdueExceptionLabels[property], value: '#0b2d71' });
            } else if (OverdueExceptionLabels[property] === this.selectedBarLabel) {
                result.push({ name: OverdueExceptionLabels[property], value: '#0b2d71' });
            } else {
                result.push({ name: OverdueExceptionLabels[property], value: '#00000066' });
            }
        }
        return result;
    }

    onSelect(event) {
        this.selectedBarLabel = event.label;
        this.store.dispatch(
            new HealthOverdueExceptionsFilterPropertyUpdate({
                key: 'selectedBarLabel',
                value: event.label,
            })
        );
        this.store.dispatch(
            new HealthOverdueExceptionsFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new HealthOverdueExceptionsTableRequest());
        this.customScheme = this.getLabelColor();
    }

    yAxisTickFormatting(val: any) {
        return parseInt(val);
    }
}
