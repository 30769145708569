import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { RundownService } from 'src/app/services/api/webapi-services/reports/rundown.service';
import { RundownActionTypes, RundownSuccess, RundownError, RundownRequest, RundownExportToExcelRequest, RundownExportToExcelSuccess, RundownExportToExcelError } from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { RUNDOWN_TYPE_KEYS } from './model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { WeekType } from 'src/app/enums';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { getCurrentWeekStartDate } from 'src/app/extensions';

@Injectable()
export class RundownEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private rundownService: RundownService,
        private toastService: ToastService
    ) {}

    @Effect()
    getRundownData$ = this.actions$.pipe(
        ofType<RundownRequest>(RundownActionTypes.RundownRequest),
        withLatestFrom(
            this.store.select((store) => store.rundownState.monthViewEnabled),
            this.store.select((store) => store.rundownState.weeklyViewEnabled),
            this.store.select((store) => store.rundownState.quarterViewEnabled),
            this.store.select((store) => store.rundownState.rundownType),
            this.store.select((store) => store.rundownState.filter),
            this.store.select((store) =>
                _.head<{ date: moment.Moment; weekType: WeekType }>(
                    store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].weeks
                )
            ),
            this.store.select((store) =>
                _.head<{ date: moment.Moment; weekType: WeekType }>(
                    store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].months
                )
            ),
            this.store.select((store) =>
                _.head<{ date: moment.Moment; weekType: WeekType }>(
                    store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].quarters
                )
            )
        ),
        mergeMap(([, monthlyViewEnabled, weeklyViewEnabled, quarterViewEnabled, rundownType, filter, startWeek, startMonth, startQuarter]) => {
            const startDate = monthlyViewEnabled ? startMonth : weeklyViewEnabled ? startWeek : startQuarter;
            const currentDate = monthlyViewEnabled ? moment().startOf('month') : weeklyViewEnabled ? getCurrentWeekStartDate() : moment().startOf('quarter');
            return this.rundownService
                .filterRundownData(
                    rundownType,
                    moment(startDate.date).format('YYYY-MM-DD'),
                    currentDate.format('YYYY-MM-DD'),
                    filter,
                    monthlyViewEnabled,
                    weeklyViewEnabled,
                    quarterViewEnabled
                )
                .pipe(
                    map(
                        (result) =>
                            new RundownSuccess({ response: result, rundownType, monthlyView: monthlyViewEnabled, weeklyView: weeklyViewEnabled, quarteryView: quarterViewEnabled })
                    ),
                    catchError((error) => {
                        this.toastService.Error(
                            `Error occurred while getting ${rundownType} Rundown data. Please contact Program Administrator.`
                        );
                        return of(new RundownError(error));
                    })
                );
        })
    );

    @Effect()
    requestOnWeekRangeChanged$ = this.actions$.pipe(
        ofType(RundownActionTypes.RundownSetWeekRange),
        map(() => new RundownRequest())
    );

    @Effect()
    requestOnMonthRangeChanged$ = this.actions$.pipe(
        ofType(RundownActionTypes.RundownSetMonthRange),
        map(() => new RundownRequest())
    );

    @Effect()
    requestOnQuarterRangeChanged$ = this.actions$.pipe(
        ofType(RundownActionTypes.RundownSetQuarterRange),
        map(() => new RundownRequest())
    );


    @Effect()
    exportToExcel$ = this.actions$.pipe(
        ofType<RundownExportToExcelRequest>(RundownActionTypes.RundownExportToExcelRequest),
        withLatestFrom(
            this.store.select((store) => store.rundownState.monthViewEnabled),
            this.store.select((store) => store.rundownState.weeklyViewEnabled),
            this.store.select((store) => store.rundownState.quarterViewEnabled),
            this.store.select((store) => store.rundownState.rundownType),
            this.store.select((store) => store.rundownState.filter),
            this.store.select((store) =>
                _.head<{ date: moment.Moment; weekType: WeekType }>(
                    store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].weeks
                )
            ),
            this.store.select((store) =>
                _.head<{ date: moment.Moment; weekType: WeekType }>(
                    store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].months
                )
            ),
            this.store.select((store) =>
                _.head<{ date: moment.Moment; weekType: WeekType }>(
                    store.rundownState[RUNDOWN_TYPE_KEYS.get(store.rundownState.rundownType).toLowerCase()].quarters
                )
            )
        ),
        mergeMap(([, monthlyViewEnabled, weeklyViewEnabled, quarterViewEnabled, rundownType, filter, startWeek, startMonth, startQuarter]) => {
            const startDate = monthlyViewEnabled ? startMonth : weeklyViewEnabled ? startWeek : startQuarter;
            const currentDate = monthlyViewEnabled ? moment().startOf('month') : weeklyViewEnabled ? getCurrentWeekStartDate() : moment().startOf('quarter');
            return this.rundownService
                .exportToExcelData(
                    rundownType,
                    moment(startDate.date).format('YYYY-MM-DD'),
                    currentDate.format('YYYY-MM-DD'),
                    filter,
                    monthlyViewEnabled,
                    weeklyViewEnabled,
                    quarterViewEnabled
                )
                .pipe(
                    map(
                        (result) =>
                            new RundownExportToExcelSuccess(result)
                    ),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                        );
                        return of(new RundownExportToExcelError(error));
                    })
                );
        })
    );

    @Effect({ dispatch: false })
    saveExcelData = this.actions$.pipe(
        ofType(RundownActionTypes.RundownExportToExcelSuccess),
        map((action: RundownExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, `BITRRunDown_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );
}
