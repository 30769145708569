import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { Config, DurationConfig } from 'src/app/models/config';
import { ToastService } from 'src/app/services/shared/toast.service';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { OrderDirection } from 'src/app/store/common.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';

@Component({
    selector: 'app-automated-file-transfers-other-sources',
    templateUrl: './automated-file-transfers-other-sources.component.html',
    styleUrls: ['./automated-file-transfers-other-sources.component.scss'],
})
export class AutomatedFileTransfersOtherSourcesComponent extends BaseComponent implements OnInit {
    commonConfigData: Config[];
    ftpConfigData: Config[];
    durationConfig: DurationConfig[];
    isLoading = false;
    sortBy: string = 'key';
    direction: OrderDirection.Desc;
    displayedColumns = ['key', 'value', 'edit'];
    durationDisplayedColumns = ['rfoNumber', 'rfoName', 'bitrDuration', 'citrDuration', 'edit'];

    constructor(
        private configurationService: ConfigurationService,
        private toastService: ToastService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private adminPanelService: AdminPanelService
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
    }

    ngOnInit() {
        this.isLoading = true;

        this.configurationService
            .getConfigData()
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((commonConfigData) => {
                this.commonConfigData = commonConfigData.filter((c) => c.id >= 17 && c.id <= 23);
            });
    }

    updateConfigData() {
        this.isLoading = true;

        this.configurationService
            .updateConfigData(this.commonConfigData)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.toastService.Success('Configuration has been updated.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while updating configuration. Configuration has not been updated.'
                    );
                }
            );
    }

    save(config: Config) {
        if (config.key === 'Filenames for Tag Import') {
            if (!config.value.includes('Tag')) {
                this.toastService.Error('All expected filenames have to contain "Tag" in the filename.');
                return;
            }

            let fileNames = config.value.split(',');
            if (fileNames.length > 10) {
                this.toastService.Error('Maximum 10 files permitted. Re-enter key.');
                return;
            }

            if (this.findDuplicates(fileNames.map((s) => s.trim())).length > 0) {
                this.toastService.Error('Duplicate filenames in entry. Re-enter key.');
                return;
            }
        }

        config.isInEditMode = !config.isInEditMode;

        if (config.value !== config.prevValue) {
            this.updateConfigData();
        }
    }

    findDuplicates = (arr: any[]) => arr.filter((item, index) => arr.indexOf(item) != index);

    enterEditMode(config: Config) {
        config.isInEditMode = !config.isInEditMode;
        config.prevValue = config.value;
    }

    exitEditMode(config: Config) {
        config.isInEditMode = !config.isInEditMode;
    }

    cancelEdit(config: Config) {
        config.isInEditMode = !config.isInEditMode;
        config.value = config.prevValue;
    }

    downloadRegisterTemplate(name: string) {
        this.adminPanelService.downloadRegisterTemplate(name);
    }
}
