<mat-expansion-panel [expanded]="expanded" (opened)="openGroup(true)" (closed)="openGroup(false)">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h4 class="table-header">{{ tableHeader }}</h4>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="table-wrapper">
        <div class="table-title">
            <div class="table-checkboxes" *ngIf="this.planningType.toUpperCase() === 'MC'">
                <mat-checkbox [checked]="showHideColumns.walkdownForecast" (change)="reverseValue('walkdownForecast')"
                    >Walkdown Forecast</mat-checkbox
                >
                <mat-checkbox [checked]="showHideColumns.walkdownActual" (change)="reverseValue('walkdownActual')"
                    >Walkdown Actual
                </mat-checkbox>
            </div>
            <div class="table-checkboxes" *ngIf="this.planningType.toUpperCase() === 'RFSU'">
                <mat-checkbox [checked]="showHideColumns.mcActual" (change)="reverseValue('mcActual')"
                    >MC Actual</mat-checkbox
                >
                <mat-checkbox [checked]="showHideColumns.walkdownForecast" (change)="reverseValue('walkdownForecast')"
                    >Walkdown Forecast</mat-checkbox
                >
                <mat-checkbox [checked]="showHideColumns.walkdownActual" (change)="reverseValue('walkdownActual')"
                    >Walkdown Actual</mat-checkbox
                >
                <mat-checkbox
                    [checked]="showHideColumns.edossierInitiation"
                    (change)="reverseValue('edossierInitiation')"
                    >E Dossier Initiated</mat-checkbox
                >
            </div>
        </div>
        <div class="scroll">
            <div class="table-container">
                <table
                    mat-table
                    matSort
                    [dataSource]="data$ | async"
                    [matSortActive]="sortBy$ | async"
                    matSortDisableClear
                    [matSortDirection]="direction$ | async"
                >
                    <ng-container matColumnDef="subsystem" [sticky]="!isIE ? 'sticky' : null">
                        <ng-container *ngIf="!isIE; else IEBlock">
                            <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                                <div class="sort-container">
                                    <span
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'subsystem',
                                                getSubsystems,
                                                'columnSubsystems',
                                                'Subsystem',
                                                tableHeaderFilter.columnSubsystems
                                            )
                                        "
                                        class="hover-pointer"
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(
                                                tableHeaderFilter?.columnSubsystems
                                            )
                                        }"
                                        >Subsystem</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                        </ng-container>
                        <ng-template #IEBlock>
                            <th mat-header-cell disableClear *matHeaderCellDef class="sticky-col-subsystem">
                                <div class="sort-container">
                                    <span
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'subsystem',
                                                getSubsystems,
                                                'columnSubsystems',
                                                'Subsystem',
                                                tableHeaderFilter.columnSubsystems
                                            )
                                        "
                                        class="hover-pointer"
                                        [ngClass]="{
                                            'header-underline': showUnderlineForCheckListColumn(
                                                tableHeaderFilter?.columnSubsystems
                                            )
                                        }"
                                        >Subsystem</span
                                    >
                                    <div class="sort-icon" *ngIf="sortBy === 'subsystem'">
                                        <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                        <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </th>
                        </ng-template>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ 'sticky-col-subsystem': isIE }">
                            <span class="red-zone-link" (click)="redirectToZone(element.subsystem)">{{
                                element.subsystem
                            }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="rfo" [sticky]="!isIE ? 'sticky' : null">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container">
                                <span
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'rfo',
                                            getRFOs,
                                            'columnRFOs',
                                            'RFO',
                                            tableHeaderFilter.columnRFOs
                                        )
                                    "
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(
                                            tableHeaderFilter?.columnRFOs
                                        )
                                    }"
                                    >RFO</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'rfo'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ 'sticky-col-rfo': isIE }">
                            {{ element.rfo }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="subsystemName">
                        <th mat-header-cell disableClear *matHeaderCellDef app-col-resize class="hover-pointer">
                            <div class="sort-container">
                                <span
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subsystemName',
                                            getSubsystemNames,
                                            'columnSubsystemNames',
                                            'Subsystem Name',
                                            tableHeaderFilter.columnSubsystemNames
                                        )
                                    "
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(
                                            tableHeaderFilter?.columnSubsystemNames
                                        )
                                    }"
                                    >Subsystem Name</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'subsystemName'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.subsystemName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoName">
                        <th mat-header-cell disableClear *matHeaderCellDef app-col-resize class="hover-pointer">
                            <div class="sort-container">
                                <span
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'rfoName',
                                            getRFONames,
                                            'columnRFONames',
                                            'RFO Name',
                                            tableHeaderFilter.columnRFONames
                                        )
                                    "
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(
                                            tableHeaderFilter?.columnRFONames
                                        )
                                    }"
                                    >RFO Name</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'rfoName'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.rfoName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mcPlan">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnMCPlan
                                        )
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'mcPlan',
                                            'columnMCPlan',
                                            'MC Plan',
                                            tableHeaderFilter.columnMCPlan
                                        )
                                    "
                                    >MC Plan</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'mcPlan'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.mcPlan | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuPlan">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnRFSUPlan
                                        )
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfsuPlan',
                                            'columnRFSUPlan',
                                            'RFSU Plan',
                                            tableHeaderFilter.columnRFSUPlan
                                        )
                                    "
                                    >RFSU Plan</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.rfsuPlan | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoPlan">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnRFOPlan
                                        )
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'plan',
                                            'columnRFOPlan',
                                            'RFO Plan',
                                            tableHeaderFilter.columnRFOPlan
                                        )
                                    "
                                    >RFO Plan</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'plan'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.rfoPlan | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mcForecast">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnMCForecast
                                        )
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'mcForecast',
                                            'columnMCForecast',
                                            'MC Forecast',
                                            tableHeaderFilter.columnMCForecast
                                        )
                                    "
                                    >MC Forecast</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'mcForecast'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.mcForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfsuForecast">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnRFSUForecast
                                        )
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'rfsuForecast',
                                            'columnRFSUForecast',
                                            'RFSU Forecast',
                                            tableHeaderFilter.columnRFSUForecast
                                        )
                                    "
                                    >RFSU Forecast</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'rfsuForecast'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.rfsuForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfoForecast">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnRFOForecast
                                        )
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'forecast',
                                            'columnRFOForecast',
                                            'RFO Forecast',
                                            tableHeaderFilter.columnRFOForecast
                                        )
                                    "
                                    >RFO Forecast</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'forecast'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.rfoForecast | date: 'd/MMM/yy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mcActual">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showHideColumns.mcActual
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnMCActual
                                        )
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'mcActual',
                                            'columnMCActual',
                                            'MC Actual',
                                            tableHeaderFilter.columnMCActual
                                        )
                                    "
                                    >MC Actual</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'mcActual'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            [ngClass]="{
                                hidecells: !showHideColumns.mcActual
                            }"
                            *matCellDef="let element"
                        >
                            {{ element.mcActual | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="walkdownForecast">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showHideColumns.walkdownForecast
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnWalkdownForecast
                                        ),
                                        hidecells: !showHideColumns.walkdownForecast
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'walkdownForecast',
                                            'columnWalkdownForecast',
                                            'Walkdown Forecast',
                                            tableHeaderFilter.columnWalkdownForecast
                                        )
                                    "
                                    >Walkdown Forecast</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'walkdownForecast'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            [ngClass]="{
                                hidecells: !showHideColumns.walkdownForecast
                            }"
                            *matCellDef="let element"
                        >
                            {{ element.walkdownForecast | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="walkdownActual">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showHideColumns.walkdownActual
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnWalkdownActual
                                        )
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'walkdownActual',
                                            'columnWalkdownActual',
                                            'Walkdown Actual',
                                            tableHeaderFilter.columnWalkdownActual
                                        )
                                    "
                                    >Walkdown Actual</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'walkdownActual'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            [ngClass]="{
                                hidecells: !showHideColumns.walkdownActual
                            }"
                            *matCellDef="let element"
                        >
                            {{ element.walkdownActual | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="edossierInitiation">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showHideColumns.edossierInitiation
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(
                                            tableHeaderFilter?.columnEdossierInitiation
                                        )
                                    }"
                                    (click)="
                                        openHeaderDateFilter(
                                            'edossierInitiation',
                                            'columnEdossierInitiation',
                                            'E Dossier Initiation',
                                            tableHeaderFilter.columnEdossierInitiation
                                        )
                                    "
                                    >E Dossier Initiated</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'edossierInitiation'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            [ngClass]="{
                                hidecells: !showHideColumns.edossierInitiation
                            }"
                            *matCellDef="let element"
                        >
                            {{ element.edossierInitiation | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="aqvd">
                        <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef class="end">
                            A-QVD
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.aqvd }}</td>
                    </ng-container>
                    <ng-container matColumnDef="bitr">
                        <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef class="end">
                            B-ITR
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.bitr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="citr">
                        <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef class="end">
                            C-ITR
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.citr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="apli">
                        <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef class="end">
                            A-PLI
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.apli }}</td>
                    </ng-container>
                    <ng-container matColumnDef="bpli">
                        <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef class="end">
                            B-PLI
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.bpli }}</td>
                    </ng-container>
                    <ng-container matColumnDef="cpli">
                        <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef class="end">
                            C-PLI
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.cpli }}</td>
                    </ng-container>
                    <ng-container matColumnDef="npw">
                        <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef class="end">
                            NPW
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.npw }}</td>
                    </ng-container>
                    <ng-container matColumnDef="redline">
                        <th mat-sort-header mat-header-cell disableClear *matHeaderCellDef class="end">
                            Redline
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.redline }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rfocomments">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                            Comments
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span [innerHTML]="element.comments | formatMentions: element.mentions"></span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="comments">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                            Comments
                        </th>
                        <td mat-cell *matCellDef="let element; let idx = index">
                            <div>
                                <div (click)="openCommentPopup(element, $event)" class="comment-description">
                                    <div *ngIf="element.comments ; else notShow">
                                        <a><span [innerHTML]="element.comments | formatMentions: element.mentions"></span></a>
                                    </div>
                                    <ng-template #notShow>
                                        <button type="button" mat-stroked-button class="add-comment">
                                            Add
                                        </button>
                                    </ng-template>
                                </div>
                                <div [ngClass]="{ notactive: !element.comments, active: element.comments }" class="comment-hist">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"
                                        (click)="openHistoryPopup(element)">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
                                    </svg>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions" [stickyEnd]="!isIE ? 'stickyEnd' : null">
                        <th mat-header-cell disableClear *matHeaderCellDef [ngClass]="{ 'sticky-col-action': isIE }">
                            Actions
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{ 'sticky-col-action': isIE }">
                            <button
                                class="commit"
                                *ngIf="!element.committedForWeek && isRFSUCommitAllowed"
                                (click)="commit(element.subsystem || element.rfo)"
                            >
                                Commit
                            </button>
                            <button
                                class="commit committed"
                                *ngIf="element.committedForWeek && isRFSUCommitAllowed"
                                (click)="deleteCommitment(element.subsystem || element.rfo)"
                            >
                                Committed
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="totalCount$ | async"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
        <div *ngIf="isLoading$ | async" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
</mat-expansion-panel>
