import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
    AppRole,
    Area,
    AreaDTO,
    ChangeManagementSimple,
    Contractor,
    ExcludeFilterRequestDTO,
    Gooc,
    ITRExcludeFilterRequestDTO,
    ITRExcludeFiltersResponseDTO,
    Milestone,
    RFO,
    StagedStartUpPriority,
    SubsystemExcludeFilterRequestDTO,
    SubsystemExcludeFiltersDTO,
    SubsystemExcludeFiltersResponseDTO,
    System,
    Waypoint,
    WorkAreaFilter,
} from 'src/app/store/common.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RedZoneFilter } from 'src/app/store/redzone/model';
import { BlueZoneFilter } from 'src/app/store/bluezone/model';
import { ChangeManagementFilter } from 'src/app/store/change-management/model';
import { map, takeWhile } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { LookupSave } from 'src/app/store/lookup/actions';
import { BaseComponent } from 'src/app/components/base.component';
import { LookupState } from 'src/app/store/lookup/model';
import { ChangeValidationFilter } from 'src/app/store/change-validation/model';
import * as _ from 'lodash';
import { RFOFilter } from 'src/app/store/RFO/model';
import { ChangeDocumentFilter } from 'src/app/store/subsystem-conflicts/model';
import { PhaseType } from 'src/app/enums';
import { DetailedStatusFilterBase } from 'src/app/store/detailed-status/model';
import { TagSearchFilter } from 'src/app/store/tag-search/model';
import { MyPageFilter } from 'src/app/store/my-page/model';
import { appConfig } from 'src/app/app.config';
import { SubsystemMaintenanceFilter } from 'src/app/store/data-maintenance/subsystem-data/model';
import { LoopStatusFilter } from 'src/app/store/loop-status/model';
import {
    OverdueDPLIFilter,
    OverdueExceptionsFilters,
    OverdueExceptionsByPriorityFilters
} from 'src/app/store/reports/health/model';
import { CTARegisterFilter } from 'src/app/store/cta-register/model';
import { ScheduleActivityDeleteFilter } from 'src/app/store/planning-upload/schedule-activity/model';
import { ActivityBarcodeDeleteFilter } from 'src/app/store/planning-upload/activity-barcode/model';
import { ExceptionsPriorityDeleteFilter } from 'src/app/store/planning-upload/exceptions-priority/model';
import { ITRManhoursDeleteFilter } from 'src/app/store/planning-upload/itr-manhours/model';
import { RundownType } from 'src/app/store/reports/rundown/model';
import { PLIPlanningFilter } from 'src/app/store/pli-planning/model';
import { ITRConstraintsDeleteFilter } from 'src/app/store/planning-upload/itr-constraints/model';
import { PascrStatusFilter } from 'src/app/store/pascr-status/model';
import { ITRConstraintsContactsDeleteFilter } from 'src/app/store/planning-upload/itr-constraints-contacts/model';
import { CITRPartialProgressDeleteFilter } from 'src/app/store/planning-upload/citr-partial-progress/model';
import { CriticalPathDeleteFilter } from 'src/app/store/planning-upload/critical-path/model';
import { ExceptionsUploadDeleteFilter } from 'src/app/store/planning-upload/exceptions-upload/model';
import { CriticalRfiFilter } from 'src/app/store/critical-rfi/model';
import { LeakTestPackTrackerSearchFilter } from 'src/app/store/leak-test-pack-tracker/model';
import { PliImplementationTeam } from 'src/app/models/pli-implementation-team';

@Injectable({
    providedIn: 'root',
})
export class LookupService extends BaseComponent {
    private readonly rootUrl = appConfig.apiEndpoint;
    lookup$ = this.store.select((state) => state.lookupState);
    private readonly endpoint = '/lookup/';
    private lookup: LookupState;

    constructor(private http: HttpClient, private store: Store<ApplicationState>) {
        super();
        this.lookup$.pipe(takeWhile(() => this.isAlive)).subscribe((lookup) => {
            this.lookup = lookup;
        });
    }

    getTagTypes() {
        return this.lookupWithProjectTeamRequest('tagTypes', []);
    }

    getLunUrl() {
        return this.http.get(`${this.rootUrl}${this.endpoint}lunUrl`, { responseType: 'text' });
    }

    getBacklogCats() {
        return this.http.get<{ name: string; id: number }[]>(`${this.rootUrl}${this.endpoint}backlogCats`);
    }

    getConstraintFlags() {
        return this.http.get<{ name: string; id: number }[]>(`${this.rootUrl}${this.endpoint}constraintflags`);
    }

    getLunUrlByRFO(rfo: string) {
        return this.http.get(`${this.rootUrl}${this.endpoint}rfo/lunUrl/${rfo}`, { responseType: 'text' });
    }

    getDisciplines() {
        return this.lookupWithProjectTeamRequest('disciplines', []);
    }

    getActionBy() {
        return this.lookupWithProjectTeamRequest('actionBy', []);
    }

    getPunchType() {
        return this.lookupWithProjectTeamRequest('punchType', []);
    }

    getPLIResponsiblePeople() {
        return this.lookupWithProjectTeamRequest('pliresponsiblepeople', []);
    }

    getMOCs() {
        return this.lookupWithProjectTeamRequest('mocs', []);
    }

    getMcdrConstrRedlineCats() {
        return this.lookupWithProjectTeamRequest('mcdrConstrRedlineCats', []);
    }

    getStagedStartUpPriorities(projectTeamNames = []) {
        return this.lookupWithProjectTeamRequest('stagedStartUpPriorities', projectTeamNames);
    }

    searchChangeIdsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}changeIds/detailedstatus`, filter, { params });
    };

    getFileNames = (search: string, take: number, page: number, filter: CTARegisterFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}cta/filenames`, filter, { params });
    };

    getDescriptions = (search: string, take: number, page: number, filter: CTARegisterFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}cta/descriptions`, filter, { params });
    };

    getAreasForFilter = (search: string, take: number, page: number, filter: CTARegisterFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}cta/areas`, filter, { params });
    };

    getRedReqTypes() {
        return this.lookupWithProjectTeamRequest('redReqTypes', []);
    }

    getRedTypes() {
        return this.lookupWithProjectTeamRequest('redTypes', []);
    }

    getDocGroups() {
        return this.lookupWithProjectTeamRequest('docGroups', []);
    }

    getRevTypes() {
        return this.lookupWithProjectTeamRequest('revTypes', []);
    }

    getChangeDocAreas() {
        return this.lookupWithProjectTeamRequest('areas', []);
    }

    getAreas() {
        return this.http.get<AreaDTO[]>(`${this.rootUrl}${this.endpoint}cta/areas`);
    }

    getPliImplementationTeam() {
        return this.http.get<PliImplementationTeam[]>(`${this.rootUrl}${this.endpoint}implementationteam`);
    }

    getMSLAreas() {
        return this.lookupWithProjectTeamRequest('mslAreas', []);
    }

    getUnits() {
        return this.lookupWithProjectTeamRequest('units', []);
    }

    getSubsystemsForChangeDocument(changeDocId: number) {
        return this.http.get(`${this.rootUrl}${this.endpoint}subsystems/${changeDocId}`);
    }

    getProjectTeams() {
        return this.http.get(`${this.rootUrl}${this.endpoint}projectteamnames`);
    }

    getACMs(projectTeamName: string[]) {
        return this.lookupWithProjectTeamRequest('acms', projectTeamName);
    }

    getSCManagers(projectTeamName: string[]) {
        return this.lookupWithProjectTeamRequest('scmanagers', projectTeamName);
    }

    getACLeads(projectTeamName: string[]) {
        return this.lookupWithProjectTeamRequest('areaCompletionLeads', projectTeamName);
    }

    getDeliveryEngineers(projectTeamName: string[]) {
        return this.lookupWithProjectTeamRequest('deliveryengineers', projectTeamName);
    }

    getSysOwners(projectTeamName: string[]) {
        return this.lookupWithProjectTeamRequest('sysowners', projectTeamName);
    }

    searchContractors(search: string, take: number, page: number, projectTeamName: string[]): Observable<Contractor[]> {
        return this.lookupWithProjectTeamRequest('contractors', projectTeamName, search, take, page);
    }

    searchMSLContractors(search: string, take: number, page: number, projectTeamName: string[]): Observable<Contractor[]> {
        return this.lookupWithProjectTeamRequest('mslcontractors', projectTeamName, search, take, page);
    }
    searchRFSUContractors(search: string, take: number, page: number, projectTeamName: string[]): Observable<Contractor[]> {
        return this.lookupWithProjectTeamRequest('rfsucontractors', projectTeamName, search, take, page);
    }

    searchCITRContractors(search: string, take: number, page: number, projectTeamName: string[]): Observable<Contractor[]> {
        return this.lookupWithProjectTeamRequest('citrcontractors', projectTeamName, search, take, page);
    }

    searchChangeDocContractors(search: string, take: number, page: number, projectTeamName: string[]): Observable<Contractor[]> {
        return this.lookupWithProjectTeamRequest('changedoccontractors', projectTeamName, search, take, page);
    }

    searchAreas(search: string, take: number, page: number, projectTeamName: string[]): Observable<Area[]> {
        return this.lookupWithProjectTeamRequest('area', projectTeamName, search, take, page);
    }

    getMCEngineers(projectTeamName: string[]) {
        return this.lookupWithProjectTeamRequest('mcengineers', projectTeamName);
    }

    searchWaypoints(search: string, take: number, page: number, projectTeamName: string[]): Observable<Waypoint[]> {
        return this.lookupWithProjectTeamRequest('waypoints', projectTeamName, search, take, page);
    }

    searchWaypointScopesForRfo() : Observable<string[]> {
        return this.http.post<string[]>(`${this.rootUrl}${this.endpoint}waypointscopes`,{}           
        );
    }
    searchWaypointsForRfo( scopes: string[]) {
        return this.http.post<Waypoint[]>(`${this.rootUrl}${this.endpoint}waypointnames`,scopes
        );
    }

    searchWorkAreas(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}workareas`, { params });
    }

    searchWorkAreasforCITRSkyline(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}workareasforcitrskyline`, { params });
    }

    getActivityFocusTypes(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}activityfocustypes`, { params });
    }

    getActivityDiscipline(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}activitydiscipline`, { params });
    }

    getNBEngineer(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}nbengineer`, { params });
    }

    getNBDisciplineLead(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}nbdisciplinelead`, { params });
    }

    getTCOAreaCoordinator(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}tcoareacoordinator`, { params });
    }

    getSubsystemName(subsystem: string) {
        let params = new HttpParams();
        params = params.append('subsystem', subsystem.toString());
        return this.http.get(`${this.rootUrl}${this.endpoint}subsystemname`, { params, responseType: 'text' });
    }

    searchMilestones(
        search: string,
        take: number,
        page: number,
        waypoints: Waypoint[],
        projectTeamName: string[]
    ): Observable<Milestone[]> {
        return this.lookupWithProjectTeamRequest('milestones', projectTeamName, search, take, page, waypoints);
    }

    searchMcMilestones(search: string, take: number, page: number, projectTeamName: string[]): Observable<Milestone[]> {
        return this.lookupWithProjectTeamRequest('mcMilestones', projectTeamName, search, take, page);
    }

    searchGoocs(search: string, take: number, page: number, projectTeamName: string[]): Observable<Gooc[]> {
        return this.lookupWithProjectTeamRequest('goocs', projectTeamName, search, take, page);
    }

    searchSystems(search: string, take: number, page: number, projectTeamName: string[]): Observable<System[]> {
        return this.lookupWithProjectTeamRequest('systems', projectTeamName, search, take, page);
    }

    searchRFSUSubsystems(search: string, take: number, page: number, projectTeamName: string[]): Observable<System[]> {
        return this.lookupWithProjectTeamRequest('subsystems', projectTeamName, search, take, page);
    }

    searchRFOs(search: string, take: number, page: number, projectTeamName: string[]): Observable<RFO[]> {
        return this.lookupWithProjectTeamRequest('rfos', projectTeamName, search, take, page);
    }

    searchConstructionArea = (search: string, take: number, page: number, projectTeamName: string[]) => {
        return this.lookupWithProjectTeamRequest('constructionarea', projectTeamName, search, take, page);
    };

    searchAreaBreakdown = (search: string, take: number, page: number, projectTeamName: string[]) => {
        return this.lookupWithProjectTeamRequest('areaBreakdown', projectTeamName, search, take, page);
    };

    searchStagedStartUpPriorities(
        search: string,
        take: number,
        page: number,
        projectTeamName: string[]
    ): Observable<StagedStartUpPriority[]> {
        return this.lookupWithProjectTeamRequest('stagedStartUpPriorities', projectTeamName, search, take, page);
    }

    searchSubsystems(
        search: string,
        take: number,
        page: number,
        projectTeamName: string[] = [],
        goocs: Gooc[] = [],
        rfos: RFO[] = []
    ) {
        return this.lookupWithProjectTeamRequest(
            'subsystems',
            projectTeamName,
            search,
            take,
            page,
            [],
            [],
            goocs,
            rfos
        );
    }

    searchQVDNumbers(search: string, take: number, page: number, rundownType: RundownType) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        params = params.append('rundownType', rundownType === RundownType.BITR ? 'BITR' : 'CITR');
        return this.http.get(`${this.rootUrl}${this.endpoint}qvdNumbers`, { params });
    }

    searchITRDecriptions(search: string, take: number, page: number, rundownType: RundownType) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        params = params.append('rundownType', rundownType === RundownType.BITR ? 'BITR' : 'CITR');
        return this.http.get(`${this.rootUrl}${this.endpoint}itrdescriptions`, { params });
    }
    searchExcepQVDNumbers(search: string, take: number, page: number, rundownType: RundownType) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        params = params.append('rundownType', rundownType === RundownType.BITR ? 'BITR' : 'CITR');
        return this.http.get(`${this.rootUrl}${this.endpoint}excepqvdNumbers`, { params });
    }

    searchSubsystemsByWorkAreaFilter(take: number, page: number, filter: WorkAreaFilter) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystemsByWorkAreaFilter`, filter, { params });
    }

    searchTags(search: string, take: number, page: number, projectTeamName: string[]) {
        let params = this.getParams(search, take, page);
        projectTeamName.forEach((o) => {
            params = params.append('projectTeamName', o);
        });
        return this.http.get(`${this.rootUrl}${this.endpoint}tags`, { params });
    }
    searchTagsforSubsystem(search: string, take: number, page: number, projectTeamName: string[], itrId) {
        let params = this.getParameters(search, take, page, itrId);
        projectTeamName.forEach((o) => {
            params = params.append('projectTeamName', o);
        });
        return this.http.get(`${this.rootUrl}${this.endpoint}tagsForItr`, { params });
    }
   
    searchFinalPunchItems(search: string, take: number, page: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);      
        return this.http.get(`${this.rootUrl}${this.endpoint}finalPunchItem`, { params });
    }
    searchAQVDBarcodeBySubsystemItems(search: string, take: number, page: number, itrId: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search); 
        params = params.append('itrId', itrId.toString());       
        return this.http.get(`${this.rootUrl}${this.endpoint}aqvdbarcodes`, { params });
    }

    searchBarcodesforConstraints(search: string, take: number, page: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        return this.http.get(`${this.rootUrl}${this.endpoint}barcodesForItr`, { params });
    }
    searchTagsforConstraintID(search: string, projectTeamName: string[], itrId, constraintId) {
        let params = new HttpParams();
        params = params.append('search', search);
        params = params.append('Id',itrId);
        params = params.append('ConstId',constraintId);
        projectTeamName.forEach((o) => {
            params = params.append('projectTeamName', o);
        });
        return this.http.get(`${this.rootUrl}${this.endpoint}tagsForConstraint`, { params });
    }
    searchBarcodesforConstraintID(search: string, projectTeamName: string[], itrId, constraintId) {
        let params = new HttpParams();
        params = params.append('search', search);
        params = params.append('Id',itrId);
        params = params.append('ConstId',constraintId);
        projectTeamName.forEach((o) => {
            params = params.append('projectTeamName', o);
        });
        return this.http.get(`${this.rootUrl}${this.endpoint}barcodesForConstraint`, { params });
    }
    

    searchTagsWithTagTypes(search: string, take: number, page: number, projectTeamName: string[]) {
        let params = this.getParams(search, take, page);
        projectTeamName.forEach((o) => {
            params = params.append('projectTeamName', o);
        });
        return this.http.get(`${this.rootUrl}${this.endpoint}tagsWithTagTypes`, { params });
    }

    searchTypesWithMyPageFilter(search: string, take: number, page: number, filter: MyPageFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}types/mypage`, filter, { params });
    }

    searchAuthorsWithMyPageFilter(search: string, take: number, page: number, filter: MyPageFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}authors/mypage`, filter, { params });
    }

    searchSubsystemsWithRedZoneFilter(search: string, take: number, page: number, filter: RedZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/red`, filter, { params });
    }

    searchCommentsWithRedZoneFilter(search: string, take: number, page: number, filter: RedZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/red`, filter, { params });
    }

    searchCommentsWithBluezoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/blue`, filter, { params });
    }

    searchSubsystemsWithConfilctsFilter(search: string, take: number, page: number, filter: ChangeDocumentFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/conflicts`, filter, { params });
    }

    searchLoopsWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}loops/loop`, filter, { params });
    }

    searchTagNumbersWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagnumbers/loop`, filter, { params });
    }

    searchActivityIdsWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}activityids/loop`, filter, { params });
    }

    searchQVDNumbersWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}qvdnumbers/loop`, filter, { params });
    }

    searchQVDDescriptionsWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}qvddescriptions/loop`, filter, { params });
    }

    searchFolderRequestsWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}loopfolderrequests/loop`, filter, { params });
    }
    searchBarcodesWithLoopStatusFilter (search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}barcodes/loopstatus`, filter, { params });
    };

    searchResponsiblePartiesWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}responsibleparties/loop`, filter, { params });
    }

    searchConstraintClassesWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}constraintclasses/loop`, filter, { params });
    }

    searchEngineerStatusesWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}engineerstatuses/loop`, filter, { params });
    }

    searchFolderAssignmentsWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}folderassignments/loop`, filter, { params });
    }

    searchFolderStatusesWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}folderstatuses/loop`, filter, { params });
    }

    searchLoopFolderConstraintsWithLoopStatusFilter(
        search: string,
        take: number,
        page: number,
        filter: LoopStatusFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}folderConstraints/loop`, filter, { params });
    }

    searchSubsystemsWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/loop`, filter, { params });
    }

    searchCommentsWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/loop`, filter, { params });
    }

    searchGoocmsWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}goocms/loop`, filter, { params });
    }

    searchAreasWithLoopStatusFilter(search: string, take: number, page: number, filter: LoopStatusFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areas/loop`, filter, { params });
    }

    searchSubsystemsWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/blue`, filter, { params });
    }

    searchRfosWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfo/blue`, filter, { params });
    }

    searchWaypointsWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}waypoint/blue`, filter, { params });
    }
    searchWaypointDescriptionWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}waypointdescription/blue`, filter, { params });
    }

    searchMilestonesWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}milestone/blue`, filter, { params });
    }
    searchMilestoneDescriptionWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}milestonedescription/blue`, filter, { params });
    }

    searchSystemOwnerWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}systemowner/blue`, filter, { params });
    }

    searchDeliveryEngineerWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}deliveryengineer/blue`, filter, { params });
    }

    searchWalkdownCommitmentsWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}walkdownCommitments/blue`, filter, { params });
    }

    searchDisciplines(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}disciplines`, { params });
    }

    searchDisciplinesWithRedZoneFilter(search: string, take: number, page: number, filter: RedZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}disciplines/red`, filter, { params });
    }

    searchMCSequencesWithRedZoneFilter(search: string, take: number, page: number, filter: RedZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}mcsequence/red`, filter, { params });
    }

    searchDisciplinesWithBluezoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}disciplines/blue`, filter, { params });
    }

    searchSubsystemNames(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.get(`${this.rootUrl}${this.endpoint}subsystemnames`, { params });
    }

    searchDACStatusWithRedZoneFilter(search: string, take: number, page: number, filter: RedZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}dacstatus/red`, filter, { params });
    }

    searchWalkdownStatusWithRedZoneFilter(search: string, take: number, page: number, filter: RedZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}walkdownstatus/red`, filter, { params });
    }

    searchSubsystemNamesWithRedZoneFilter(search: string, take: number, page: number, filter: RedZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystemnames/red`, filter, { params });
    }

    searchSubsystemNamesWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystemnames/blue`, filter, { params });
    }

    searchConstructionAreaWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}constructionarea/blue`, filter, { params });
    }

    searchAreaBreakdownWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areaBreakdown/blue`, filter, { params });
    }

    searchRFOsWithRFOZoneFilter(search: string, take: number, page: number, filter: RFOFilter | ChangeManagementFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfos/rfo`, filter, { params });
    }

    searchRFOStatusWithRFOZoneFilter(search: string, take: number, page: number, filter: RFOFilter | ChangeManagementFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfostatus/changemanagement`, filter, { params });
    }

    searchCommentsWithRFOZoneFilter(search: string, take: number, page: number, filter: RFOFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/rfo`, filter, { params });
    }

    searchRFOsNamesWithRFOZoneFilter(search: string, take: number, page: number, filter: RFOFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfosnames/rfo`, filter, { params });
    }

    searchPriorityNamesWithRedZoneFilter(search: string, take: number, page: number, filter: RedZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}prioritynames/red`, filter, { params });
    }

    searchPriorityNamesWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}prioritynames/blue`, filter, { params });
    }

    searchAreaBreakdownsWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areaBreakdowns/blue`, filter, { params });
    }

    searchContractNoWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}contractorNo/blue`, filter, { params });
    }

    searchAreaCompletionLeadsWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areaCompletionLeads/blue`, filter, { params });
    }

    searchAreaBreakdownsWithRFOZoneFilter(search: string, take: number, page: number, filter: RFOFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areaBreakdowns/rfo`, filter, { params });
    }

    searchAreaCompletionLeadsWithRFOZoneFilter(search: string, take: number, page: number, filter: RFOFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areaCompletionLeads/rfo`, filter, { params });
    }

    searchSCExecutionAreaManagersWithRFOZoneFilter(search: string, take: number, page: number, filter: RFOFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}scexecutionareamanagers/rfo`, filter, { params });
    }

    searchEdossierStatusesWithRFOZoneFilter(search: string, take: number, page: number, filter: RFOFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}edossierStatuses/rfo`, filter, { params });
    }

    searchGoocNoWithRedZoneFilter(search: string, take: number, page: number, filter: RedZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}goocs/no/red`, filter, { params });
    }

    searchGoocNoWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}goocs/no/blue`, filter, { params });
    }

    searchBacklogCatsWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}backlogCats/blue`, filter, { params });
    }

    searchEdossierStatusesWithBlueZoneFilter(search: string, take: number, page: number, filter: BlueZoneFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}edossierStatuses/blue`, filter, { params });
    }

    searchPriorityNames = (search: string, take: number, page: number, projectTeamName: string[]) => {
        return this.lookupWithProjectTeamRequest('prioritynames', projectTeamName, search, take, page);
    };

    searchStatusesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}statuses/detailedstatus`, filter, { params });
    };

    searchTagNumbersWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagnumbers/tagsearch`, filter, { params });
    };

    searchTagTypesWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagtypes/tagsearch`, filter, { params });
    };

    searchTagNamesWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagnames/tagsearch`, filter, { params });
    };
    searchServiceDescriptionWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}servicedescription/tagsearch`, filter, { params });
    };
    searchSubValidateCommentWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subvalidatecomment/tagsearch`, filter, { params });
    };

    searchDisciplinesWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}disciplines/tagsearch`, filter, { params });
    };

    searchTagStatusesWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagstatuses/tagsearch`, filter, { params });
    };

    searchProjectTeamsWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}projectteams/tagsearch`, filter, { params });
    };

    searchExInspectionsWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exinspections/tagsearch`, filter, { params });
    };

    searchSubsystemsWithTagSearchFilter = (search: string, take: number, page: number, filter: TagSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/tagsearch`, filter, { params });
    };

    searchLtpNumbersWithLeakTestPackTrackerSearchFilter = (search: string, take: number, page: number, filter: LeakTestPackTrackerSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ltpno/leaktestpacktrackersearch`, filter, { params });
    };   

    searchInstrumentSubsystemWithLeakTestPackTrackerSearchFilter = (search: string, take: number, page: number, filter: LeakTestPackTrackerSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}instrumentsubsystem/leaktestpacktrackersearch`, filter, { params });
    };
    
    searchPriorityWithLeakTestPackTrackerSearchFilter = (search: string, take: number, page: number, filter: LeakTestPackTrackerSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}priority/leaktestpacktrackersearch`, filter, { params });
    };
    searchInstrumentSubsystemNameWithLeakTestPackTrackerSearchFilter = (search: string, take: number, page: number, filter: LeakTestPackTrackerSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}instrumentsubsystemname/leaktestpacktrackersearch`, filter, { params });
    };

    searchBarcodeWithLeakTestPackTrackerSearchFilter = (search: string, take: number, page: number, filter: LeakTestPackTrackerSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}barcode/leaktestpacktrackersearch`, filter, { params });
    };

    searchCertificatecommentWithLeakTestPackTrackerSearchFilter = (search: string, take: number, page: number, filter: LeakTestPackTrackerSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}certificatecomment/leaktestpacktrackersearch`, filter, { params });
    };

    searchcommentWithLeakTestPackTrackerSearchFilter = (search: string, take: number, page: number, filter: LeakTestPackTrackerSearchFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comment/leaktestpacktrackersearch`, filter, { params });
    };


    searchNumbersWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}qvdnumbers/detailedstatus`, filter, { params });
    };

    searchNumbersWithOverdueDPLIFilter = (search: string, take: number, page: number, filter: OverdueDPLIFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}numbers/overduedpli`, filter, { params });
    };

    searchCowDocNumbersWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}cowdocnumbers/detailedstatus`, filter, { params });
    };

    searchDescriptionsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}qvddescriptions/detailedstatus`, filter, { params });
    };

    searchBarcodesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}barcodes/detailedstatus`, filter, { params });
    };

    searchPlisWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}plis/detailedstatus`, filter, { params });
    };

    searchNpwsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}npws/detailedstatus`, filter, { params });
    };

    searchPascrsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}pascrs/detailedstatus`, filter, { params });
    };

    searchRedlinesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}redlines/detailedstatus`, filter, { params });
    };

    searchResponsiblePersonorGroupWithDetailedStatusFilter  = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}responsiblePersonOrGroup/detailedstatus`, filter, { params });
    };
    
    searchItrInWeekWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}itrinweek/detailedstatus`, filter, { params });
    };

    searchItrConstraintFlag = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}constraintflag/detailedstatus`, filter, { params });
    };

    searchPriorityName = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}PriorityName/detailedstatus`, filter, { params });
    };



    searchITRConstraintTypeName = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintTypeName/detailedstatus`, filter, { params });
    };

    searchAssociatedComment = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}AssociatedComment/detailedstatus`, filter, { params });
    };

    searchAQVDContractor = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}AQVDContractor/detailedstatus`, filter, { params });
    };

    searchPLIActionBy = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}PLIActionedBy/detailedstatus`, filter, { params });
    };

    searchITRConstraintComment = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintComment/detailedstatus`, filter, { params });
    };
    searchExceptionsComment = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ExceptionComment/detailedstatus`, filter, { params });
    };

    searchO3ConstraintId = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}O3ConstraintId/detailedstatus`, filter, { params });
    };

    searchITRConstraintResponsibleUser = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintResponsibleUser/detailedstatus`, filter, { params });
    };
    searchITRConstraintResponsibleGroup = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ): any => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintResponsibleGroup/detailedstatus`, filter, { params });
    };

    searchITRConstraintDescription = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintDescription/detailedstatus`, filter, { params });
    };

    searchITRConstraintStatus = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintStatus/detailedstatus`, filter, { params });
    };

    searchITRConstraintId = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintId/detailedstatus`, filter, { params });
    };

    searchITRConstraintAdditionalResponsibleUser = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintAdditionalResponsibleUser/detailedstatus`, filter, { params });
    };
    
    searchITRConstraintRaisedByUser = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintRaisedByUser/detailedstatus`, filter, { params });
    };
    searchDeliveryEngineerWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}deliveryEngineer/detailedstatus`, filter, { params });
    };
    searchITRConstraintRaisedByTeam = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ITRConstraintRaisedByTeam/detailedstatus`, filter, { params });
    };

    searchFMRorPO = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}FMRorPO/detailedstatus`, filter, { params });
    };

    searchWaypointRequired = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}WaypointRequired/detailedstatus`, filter, { params });
    };
    
    searchAssociatedNPW = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}associatedNPW/detailedstatus`, filter, { params });
    };
    searchAssociatedPLI = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}associatedPLI/detailedstatus`, filter, { params });
    };

    searchAssociatedQVD = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}associatedQVD/detailedstatus`, filter, { params });
    };

    searchConstraintIdNumbersWithITRConstraintDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ITRConstraintsDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}itrnumbers/itrconstraintsdeleterecords`, filter, {
            params,
        });
    }

    searchConstraintIdNumbersWithITRConstraintContactsDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ITRConstraintsContactsDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}itrnumbers/itrconstraintscontactsdeleterecords`, filter, {
            params,
        });
    }


    


    searchRFO = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfo/detailedstatus`, filter, { params });
    };

    searchWaypoint = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}waypoint/detailedstatus`, filter, { params });
    };

    searchMilestone = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}milestone/detailedstatus`, filter, { params });
    };


    searchWaypointDescription = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}waypointdescription/detailedstatus`, filter, { params });
    };

    searchAreaBreakdownValues = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areabreakdown/detailedstatus`, filter, { params });
    };


    searchMilestoneDescription = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}milestonedescription/detailedstatus`, filter, { params });
    };


    searchWorkAreaName = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}workAreaName/detailedstatus`, filter, { params });
    };

    searchWorkOrderNum = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}workOrderNum/detailedstatus`, filter, { params });
    };

    searchETransferNumber = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}etransferNumber/detailedstatus`, filter, { params });
    };

    searchActivityID = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}activityID/detailedstatus`, filter, { params });
    };

    searchActivityName = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}activityName/detailedstatus`, filter, { params });
    };

    searchPriority = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}Priority/detailedstatus`, filter, { params });
    };

    searchExceptionPriority = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exceptionPriority/detailedstatus`, filter, { params });
    };

    searchDescriptionsWithOverdueDPLIFilter = (
        search: string,
        take: number,
        page: number,
        filter: OverdueDPLIFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}descriptions/overduedpli`, filter, { params });
    };

    searchRedTypesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}redTypes/detailedstatus`, filter, { params });
    };
    searchRedlineExceptionNumberWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}redlineExceptionNumber/detailedstatus`, filter, { params });
    };

    searchRedReqTypesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}redReqTypes/detailedstatus`, filter, { params });
    };

    searchRedStatusesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}redStatuses/detailedstatus`, filter, { params });
    };

    searchMcdrConstrRedCatsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}mcdrConstrRedCats/detailedstatus`, filter, { params });
    };

    searchContainsChangeWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}containsChange/detailedstatus`, filter, { params });
    };

    searchSubsystemsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/detailedstatus`, filter, { params });
    };

    searchOriginalSubsystemsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}origsubsystems/detailedstatus`, filter, { params });
    };

    searchSubsystemsWithOverdueDPLIFilter = (search: string, take: number, page: number, filter: OverdueDPLIFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/overduedpli`, filter, { params });
    };

    searchSubsystemNamesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystemNames/detailedstatus`, filter, { params });
    };

    searchSubsystemNamesWithOverdueDPLIFilter = (
        search: string,
        take: number,
        page: number,
        filter: OverdueDPLIFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystemNames/overduedpli`, filter, { params });
    };

    searchCommentsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/detailedstatus`, filter, { params });
    };
    searchNBCommentsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}nbcomments/detailedstatus`, filter, { params });
    };

    searchItemCommentsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}itemcomments/detailedstatus`, filter, { params });
    };

    searchManualPrioritiesWithDetailedStatusFilter = (search: string, take: number, page: number, filter: DetailedStatusFilterBase) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}manualpriorities/detailedstatus`, filter, { params });
    }

    searchCommentsWithOverdueDPLIFilter = (search: string, take: number, page: number, filter: OverdueDPLIFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/overduedpli`, filter, { params });
    };

    searchMaterialReferencesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}materialreferences/detailedstatus`, filter, { params });
    };

    searchClassificationWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}classifications/detailedstatus`, filter, { params });
    };

    searchMaterialReferencesWithOverdueDPLIFilter = (
        search: string,
        take: number,
        page: number,
        filter: OverdueDPLIFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}materialreferences/overduedpli`, filter, { params });
    };

    searchTagTypesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagtypes/detailedstatus`, filter, { params });
    };

    searchTagNamesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagnames/detailedstatus`, filter, { params });
    };

    searchTagTypesWithOverdueDPLIFilter = (search: string, take: number, page: number, filter: OverdueDPLIFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagtypes/overduedpli`, filter, { params });
    };

    searchCategoriesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}categories/detailedstatus`, filter, { params });
    };

    searchCategoriesWithOverdueDPLIFilter = (search: string, take: number, page: number, filter: OverdueDPLIFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}categories/overduedpli`, filter, { params });
    };

    searchActionBysWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}actionbys/detailedstatus`, filter, { params });
    };

    searchImplementationTeamWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}implementationteam/detailedstatus`, filter, { params });
    };

    searchActionBysWithOverdueDPLIFilter = (search: string, take: number, page: number, filter: OverdueDPLIFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}actionbys/overduedpli`, filter, { params });
    };

    searchDisciplinesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}disciplines/detailedstatus`, filter, { params });
    };

    searchDisciplinesWithOverdueDPLIFilter = (
        search: string,
        take: number,
        page: number,
        filter: OverdueDPLIFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}disciplines/overduedpli`, filter, { params });
    };

    searchPunchTypeValuesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}punchtypevalues/detailedstatus`, filter, { params });
    };

    searchReviewStatusCompleteWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}reviewstatuscomplete/detailedstatus`, filter, { params });
    };

    searchACNumberWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}acnumber/detailedstatus`, filter, { params });
    };

    searchReviewStatusCompleteWithOverdueDPLIFilter = (
        search: string,
        take: number,
        page: number,
        filter: OverdueDPLIFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}reviewstatuscomplete/overduedpli`, filter, { params });
    };

    searchPunchTypeValuesWithOverdueDPLIFilter = (
        search: string,
        take: number,
        page: number,
        filter: OverdueDPLIFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}punchtypevalues/overduedpli`, filter, { params });
    };

    searchRaisedByValuesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}raisedbyvalues/detailedstatus`, filter, { params });
    };
    searchMaterialReviewStatusWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}materialReviewStatus/detailedstatus`, filter, { params });
    };
    searchMaterialReviewAssignedToWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}materialReviewAssignedTo/detailedstatus`, filter, { params });
    };
    searchTagDisciplineValuesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagdisciplinevalues/detailedstatus`, filter, { params });
    };
    searchCriticalPathWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}criticalpath/detailedstatus`, filter, { params });
    };
    searchPriorityNoWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}prioritynumbers/detailedstatus`, filter, { params });
    };

    searchRaisedByValuesWithOverdueDPLIFilter = (
        search: string,
        take: number,
        page: number,
        filter: OverdueDPLIFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}raisedbyvalues/overduedpli`, filter, { params });
    };

    searchDocTypesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}doctypes/detailedstatus`, filter, { params });
    };

    searchDocType2sWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}doctype2s/detailedstatus`, filter, { params });
    };

    searchRevisionsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}revisions/detailedstatus`, filter, { params });
    };

    searchTagNumbersWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagnumbers/detailedstatus`, filter, { params });
    };

    searchTagNumbersWithOverdueDPLIFilter = (search: string, take: number, page: number, filter: OverdueDPLIFilter) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagnumbers/overduedpli`, filter, { params });
    };

    searchSystemOwnersWithOverdueDPLIFilter = (
        search: string,
        take: number,
        page: number,
        filter: OverdueDPLIFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}systemOwners/overduedpli`, filter, { params });
    };

    searchContractorsWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}contractors/detailedstatus`, filter, { params });
    };

    searchExceptionNumbersWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exceptionnumbers/detailedstatus`, filter, { params });
    };

    searchExceptionPhasesWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exceptionphases/detailedstatus`, filter, { params });
    };

    searchExceptionStatusWithDetailedStatusFilter = (
        search: string,
        take: number,
        page: number,
        filter: DetailedStatusFilterBase
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exceptionstatus/detailedstatus`, filter, { params });
    };

    searchTurnoverGroups(search: string, take: number, page: number) {
        return this.lookupWithProjectTeamRequest('turnovergroups', [], search, take, page);
    }

    getChangeTypes(): Observable<string[]> {
        return this.lookupWithProjectTeamRequest('changetype');
    }

    getFieldImplementationContractors(): Observable<string[]> {
        return this.lookupWithProjectTeamRequest('fieldImplementationContractor');
    }

    getDesignStatuses(): Observable<string[]> {
        return this.lookupWithProjectTeamRequest('designstatus');
    }

    searchCWPs(search: string, take: number, page: number, projectTeamNames: string[] = []) {
        return this.lookupWithProjectTeamRequest('cwps', projectTeamNames, search, take, page);
    }

    searchIWPs(search: string, take: number, page: number, contractorNo: string) {
        let params = this.getParams(search, take, page);
        params = params.append('contractorNo', contractorNo);
        return this.http.get(`${this.rootUrl}${this.endpoint}iwps`, { params });
    }

    searchContractorsWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}contractors/changemanagement`, filter, { params });
    }

    searchFieldImplementationContractorsWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}fieldimplementationcontractors/changemanagement`, filter, { params });
    }

    searchChangeTypesWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter |CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}changeTypes/changemanagement`, filter, { params });
    }

    searchIdsWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ids/changemanagement`, filter, { params });
    }

    searchIDandNameWithChangeManagementFilter(search: string, take: number, page: number) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}idandnames/changemanagement`, { params });
    }


    searchTitlesWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}titles/changemanagement`, filter, { params });
    }

    searchPrioritiesWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}priorities/changemanagement`, filter, { params });
    }

    searchRFIOriginatorsWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfioriginators/changemanagement`, filter, { params });
    }

    searchManualPrioritiesWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}manualpriorities/changemanagement`, filter, { params });
    }

    searchFinalPrioritiesWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}finalpriorities/changemanagement`, filter, { params });
    }

    searchSubPrioritiesWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subpriorities/changemanagement`, filter, { params });
    }

    searchRevisionsWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}revisions/changemanagement`, filter, { params });
    }

    searchSystemOwnersWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}systemowners/changemanagement`, filter, { params });
    }
    searchAreaBreakDownWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areabreakdown/changemanagement`, filter, { params });
    }
    searchExceptionNumberWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exceptionnumber/changemanagement`, filter, { params });
    }
    searchValidationStatusWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}validationstatus/changemanagement`, filter, { params });
    }
    searchNextToSignWithChangeManagementFilter(search: string, take: number, page: number, filter: ChangeManagementFilter | CriticalRfiFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}nexttosign/changemanagement`, filter, { params });
    }

    searchCommentsWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/changemanagement`, filter, { params });
    }
    searchtiCommentsWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ticomments/changemanagement`, filter, { params });
    }

    searchkpjvCommentsWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}kpjvcomments/changemanagement`, filter, { params });
    }

    searchStatusesWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter | CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}statuses/changemanagement`, filter, { params });
    }

    searchStepStatusesWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}stepstatuses/changemanagement`, filter, { params });
    }

    searchStepStatusesWithCriticalRfiFilter(
        search: string,
        take: number,
        page: number,
        filter: CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}stepstatuses/criticalrfi`, filter, { params });
    }

    searchStepStatusesWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}stepstatuses/changevalidation`, filter, { params });
    }

    searchResponseDurationWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter | CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}responsedurations/changemanagement`, filter, { params });
    }

    searchisVendorClarificationWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter | CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}isvendorclarifications/changemanagement`, filter, { params });
    }

    searchVendorNameWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter | CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}vendornames/changemanagement`, filter, { params });
    }

    searchCriticalPathWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter | CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}criticalpath/changemanagement`, filter, { params });
    }

    searchRfoWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter | CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfos/changemanagement`, filter, { params });
    }

    searchImplementationStatusesWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}implementationstatuses/changemanagement`, filter, {
            params,
        });
    }
    searchResponsiblePersonWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}responsibleperson/changemanagement`, filter, {
            params,
        });
    }

    searchDisciplinesWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter | CriticalRfiFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}disciplines/changemanagement`, filter, { params });
    }

    searchSubsystemsWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter | CriticalRfiFilter
    ): Observable<any> {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/changemanagement`, filter, { params });
    }

    searchSignedSubsystemsWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter | CriticalRfiFilter
    ): Observable<any> {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}signedsubsystems/changemanagement`, filter, { params });
    }

    searchLinkedIWPsWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ): Observable<any> {
        const params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}linkedIWPs/changemanagement`, filter, { params });
    }

    searchLinkedCOFsWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ): Observable<any> {
        const params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}linkedCOFs/changemanagement`, filter, { params });
    }
    
    searchRFSUContractorWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ): Observable<any> {
        const params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfsuContractor/changemanagement`, filter, { params });
    }

    searchRFSUContractsPendingWithChangeManagementFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeManagementFilter
    ): Observable<any> {
        const params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfsuContractsPending/changemanagement`, filter, { params });
    }

    searchNumbersWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}numbers/changevalidation`, filter, { params });
    }

    searchDisciplinesWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}disciplines/changevalidation`, filter, { params });
    }

    searchChangeTypesWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}changetypes/changevalidation`, filter, { params });
    }

    searchDataUpdatedWithChangeValidationFilter(search: string, filter: ChangeValidationFilter) {
        let params = new HttpParams();
        params = params.append('search', search);

        return this.http.post(`${this.rootUrl}${this.endpoint}dataupdated/changevalidation`, filter, { params });
    }

    searchStatusesWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}statuses/changevalidation`, filter, { params });
    }

    searchImplementationStatusesWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}implementationstatuses/changevalidation`, filter, {
            params,
        });
    }

    searchValidationStatusesWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}validationstatuses/changevalidation`, filter, { params });
    }

    searchELEValidationStatusesWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}elevalidationstatuses/changevalidation`, filter, { params });
    }

    searchICTValidationStatusesWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}ictvalidationstatuses/changevalidation`, filter, { params });
    }

    searchSMPValidationStatusesWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}smpvalidationstatuses/changevalidation`, filter, { params });
    }

    searchImpactedAreasWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}impactedareas/changevalidation`, filter, { params });
    }

    searchImpactedCWPsWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}impactedcwps/changevalidation`, filter, { params });
    }

    searchProjectTeamsWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}projectteamnames/changevalidation`, filter, { params });
    }

    searchContractorsWithValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}contractors/changevalidation`, filter, { params });
    }

    searchFieldImplementationContractorWithValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}fieldImplementationContractor/changevalidation`, filter, { params });
    }

    searchRevisionsWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}revisions/changevalidation`, filter, { params });
    }

    searchTitlesWithChangeValidationFilter(search: string, take: number, page: number, filter: ChangeValidationFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}titles/changevalidation`, filter, { params });
    }

    searchValidatorsWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}validators/changevalidation`, filter, { params });
    }

    searchSubsystemWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/changevalidation`, filter, { params });
    }

    searchRfoWithChangeValidationFilter(
        search: string,
        take: number,
        page: number,
        filter: ChangeValidationFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}rfos/changevalidation`, filter, { params });
    }

    getSubsystemsForDailyDashboard(projectTeamName: string, phaseType: PhaseType) {
        return this.http.get(
            `${this.rootUrl}${this.endpoint}subsystemsfordailydashboard/${projectTeamName}/${phaseType}`,
            {}
        );
    }

    getRFOsForDailyDashboard(projectTeamName: string, phaseType: PhaseType) {
        return this.http.get(
            `${this.rootUrl}${this.endpoint}rfosfordailydashboard/${projectTeamName}/${phaseType}`,
            {}
        );
    }

    getProjectTeamNames(): Observable<string[]> {
        return this.lookupWithProjectTeamRequest('projectteamnames');
    }

    searchUsers(search: string, page: number, take: number) {
        return this.lookupWithProjectTeamRequest('users', [], search, take, page);
    }

    searchContraintUsers(page: number, take: number) {
        return this.lookupForConstraintUserRequest('constraintusers', take, page);
    }

    searchUsersWithRoles(roles: AppRole[]) {
        return this.lookupWithProjectTeamRequest('usersWithRoles', [], '', null, null, [], roles);
    }

    searchSubsystemsWithSubsystemMaintenanceFilter = (
        search: string,
        take: number,
        page: number,
        filter: SubsystemMaintenanceFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/subsystemMaintenance`, filter, { params });
    };

    searchResponsibleGroup = () => {      
        return this.http.get(`${this.rootUrl}${this.endpoint}responsiblegroup`);
    };

    searchUsersByType(type: string, page: number, take: number) {
        return this.lookupForUserByTypeRequest(type, take, page);
    }

    searchSubsystemNamesWithSubsystemMaintenanceFilter = (
        search: string,
        take: number,
        page: number,
        filter: SubsystemMaintenanceFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystemNames/subsystemMaintenance`, filter, { params });
    };

    searchContractorsWithSubsystemMaintenanceFilter = (
        search: string,
        take: number,
        page: number,
        filter: SubsystemMaintenanceFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}contractors/subsystemMaintenance`, filter, { params });
    };

    searchAreaManagersWithSubsystemMaintenanceFilter = (
        search: string,
        take: number,
        page: number,
        filter: SubsystemMaintenanceFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areaManagers/subsystemMaintenance`, filter, { params });
    };

    searchSystemOwnersWithSubsystemMaintenanceFilter = (
        search: string,
        take: number,
        page: number,
        filter: SubsystemMaintenanceFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}systemOwners/subsystemMaintenance`, filter, { params });
    };

    searchACManagersWithSubsystemMaintenanceFilter = (
        search: string,
        take: number,
        page: number,
        filter: SubsystemMaintenanceFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}acManagers/subsystemMaintenance`, filter, { params });
    };

    searchMCEngineersWithSubsystemMaintenanceFilter = (
        search: string,
        take: number,
        page: number,
        filter: SubsystemMaintenanceFilter
    ) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}mcEngineers/subsystemMaintenance`, filter, { params });
    };

    private getParams(search: string, take: number, page: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        return params;
    }
    private getParameters(search: string, take: number, page: number, Id) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        params = params.append('Id',Id);
        return params;
    }

    private lookupWithProjectTeamRequest(
        type: string,
        projectTeamNames: string[] = [],
        search: string = null,
        take: number = null,
        page: number = null,
        waypoints: Waypoint[] = [],
        roles: AppRole[] = [],
        goocs: Gooc[] = [],
        rfos: RFO[] = []
    ) {
        const roleParam = roles.length ? `?roles=${roles}` : '';
        const observable = this.lookup[type + roles]
            ? of(this.lookup[type + roles])
            : this.http.get<any[]>(`${this.rootUrl}${this.endpoint}${type}${roleParam}`).pipe(
                  map((value) => {
                      this.store.dispatch(new LookupSave({ key: type + roles, value }));
                      return value;
                  })
              );

        return observable.pipe(
            map((value) => {
                let result = _.cloneDeep(value);
                const searchText = search !== null ? search.toLowerCase() : search;
                if (projectTeamNames !== null && projectTeamNames.length > 0) {
                    result = result.filter((s) => projectTeamNames.indexOf(s.projectTeamName) > -1);
                }
                if (search !== null && search !== '') {
                    if (type === 'systems') {
                        result = result.filter((s) => s.no.toLowerCase().indexOf(searchText) > -1);
                    } else if (type === 'goocs') {
                        result = result.filter(
                            (s) =>
                                s.name.toLowerCase().indexOf(searchText) > -1 ||
                                s.no.toLowerCase().indexOf(searchText) > -1
                        );
                    } else if (type === 'turnovergroups') {
                        result = result.filter(
                            (s) =>
                                s.id.toLowerCase().indexOf(searchText) > -1 ||
                                s.name.toLowerCase().indexOf(searchText) > -1
                        );
                    } else if (type === 'users') {
                        result = result.filter(
                            (s) =>
                                s.email.toLowerCase().indexOf(searchText) > -1 ||
                                s.name.toLowerCase().indexOf(searchText) > -1
                        );
                    } else if (type === 'cwps' || type === 'constructionarea' || type === 'prioritynames') {
                        result = result.filter((s) => s.id.toLowerCase().indexOf(searchText) > -1);
                    } else if (type === 'subsystems') {
                        result = result.filter((s) => s.id.toLowerCase().indexOf(searchText) > -1);
                    } else if (type === 'contractors' || type === 'mslcontractors') {
                        result = result.filter(
                            (s: Contractor) =>
                                s.name.toLowerCase().includes(searchText) ||
                                (s.contract != null && s.contract.toLowerCase().includes(searchText))
                        );
                    } else if (type === 'mcMilestones' || type == 'rfos' || type == 'area') {
                        result = result.filter((s) => s.name.toLowerCase().indexOf(searchText) > -1);
                    } else if (type === 'stagedStartUpPriorities') {
                        result = result.filter(
                            (prio: StagedStartUpPriority) =>
                                prio.priority.toLowerCase().indexOf(searchText) > -1 ||
                                prio.priorityName.toLowerCase().indexOf(searchText) > -1
                        );
                    } else {
                        result = result.filter(
                            (s) =>
                                s.name?.toLowerCase().indexOf(searchText) > -1 ||
                                s.description?.toLowerCase().indexOf(searchText) > -1
                        );
                    }
                }
                if (waypoints.length > 0) {
                    result = result.filter((s) => waypoints.map((w) => w.name).indexOf(s.waypointName) > -1);
                }
                if (type === 'subsystems' && (goocs.length || rfos.length)) {
                    result = goocs.length ? result.filter((s) => goocs.map((g) => g.no).indexOf(s.gooc) > -1) : result;
                    result = rfos.length ? result.filter((s) => rfos.map((r) => r.name).indexOf(s.rfo) > -1) : result;
                }
                if (take !== null && page !== null) {
                    result = result.slice(page * take, page * take + take);
                }

                return result;
            })
        );
    }

    private lookupForConstraintUserRequest(
        type: string,
        take: number = null,
        page: number = null,
    ) {
        const observable = this.http.get<any[]>(`${this.rootUrl}${this.endpoint}${type}`).pipe(
                  map((value) => {
                      this.store.dispatch(new LookupSave({ key: type, value }));
                      return value;
                  })
              );
        return observable.pipe(
            map((value) => {
                let result = _.cloneDeep(value);
                if (take !== null && page !== null) {
                    result = result.slice(page * take, page * take + take);
                }

                return result;
            })
        );
    }

    private lookupForUserByTypeRequest(
        type: string,
        take: number = null,
        page: number = null,
    ) {
        const observable = this.http.get<any[]>(`${this.rootUrl}${this.endpoint}${type}`).pipe(
                  map((value) => {
                      this.store.dispatch(new LookupSave({ key: type, value }));
                      return value;
                  })
              );
        return observable.pipe(
            map((value) => {
                let result = _.cloneDeep(value);
                if (take !== null && page !== null) {
                    result = result.slice(page * take, page * take + take);
                }

                return result;
            })
        );
    }

    searchActivityIdsWithScheduleActivityDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}activityids/scheduleactivitydeleterecords`, filter, {
            params,
        });
    }

    searchActivityNamesWithScheduleActivityDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}activitynames/scheduleactivitydeleterecords`, filter, {
            params,
        });
    }

    searchSubsystemsWithScheduleActivityDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/scheduleactivitydeleterecords`, filter, {
            params,
        });
    }

    searchActivityDisciplinesWithScheduleActivityDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(
            `${this.rootUrl}${this.endpoint}activitydisciplines/scheduleactivitydeleterecords`,
            filter,
            {
                params,
            }
        );
    }

    searchAreasWithScheduleActivityDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ScheduleActivityDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}areas/scheduleactivitydeleterecords`, filter, {
            params,
        });
    }

    





    searchActivityIdsWithActivityBarcodeDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ActivityBarcodeDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}activityids/ActivityBarcodedeleterecords`, filter, {
            params,
        });
    }

    searchBarcodeWithActivityBarcodeDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ActivityBarcodeDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}barcodes/ActivityBarcodedeleterecords`, filter, {
            params,
        });
    }

    searchPriorityWithExceptionsPriorityDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ExceptionsPriorityDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}priority/ExceptionsPrioritydeleterecords`, filter, {
            params,
        });
    }

    searchExceptionWithExceptionsPriorityDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ExceptionsPriorityDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exception/ExceptionsPrioritydeleterecords`, filter, {
            params,
        });
    }

    searchBarcodeWithCITRPartailProgressDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: CITRPartialProgressDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}barcodes/CitrPartialProgressdeleterecords`, filter, {
            params,
        });
    }

    searchQVDNumberWithCITRPartailProgressDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: CITRPartialProgressDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}qvdnumbers/CitrPartialProgressdeleterecords`, filter, {
            params,
        });
    }

    searchBarcodeWithExceptionsUploadDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ExceptionsUploadDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}barcodes/CitrPartialProgressdeleterecords`, filter, {
            params,
        });
    }

    searchQVDNumberWithExceptionsUploadDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ExceptionsUploadDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}qvdnumbers/CitrPartialProgressdeleterecords`, filter, {
            params,
        });
    }

    searchQVDNumbersWithITRManhoursDeleteFilter(
        search: string,
        take: number,
        page: number,
        filter: ITRManhoursDeleteFilter
    ) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}qvdnumbers/ITRManhoursdeleterecords`, filter, {
            params,
        });
    }




    searchSubsystemsWithOverdueExceptionFilter = (search: string, take: number, page: number, filter: OverdueExceptionsFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/overdueexception`, filter, { params });
    };

    searchNumbersWithOverdueExceptionFilter = (search: string, take: number, page: number, filter: OverdueExceptionsFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}numbers/overdueexception`, filter, { params });
    };

    searchDescriptionsWithOverdueExceptionFilter = (search: string, take: number, page: number, filter: OverdueExceptionsFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}descriptions/overdueexception`, filter, { params });
    };

    searchTagNumbersWithOverdueExceptionFilter = (search: string, take: number, page: number, filter: OverdueExceptionsFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagnumbers/overdueexception`, filter, { params });
    };

    searchTagTypesWithOverdueExceptionFilter = (search: string, take: number, page: number, filter: OverdueExceptionsFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagtypes/overdueexception`, filter, { params });
    };

    searchDisciplinesWithOverdueExceptionFilter = (search: string, take: number, page: number, filter: OverdueExceptionsFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}discplines/overdueexception`, filter, { params });
    };

    searchExceptionNumberWithOverdueExceptionFilter = (search: string, take: number, page: number, filter: OverdueExceptionsFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exnumbers/overdueexception`, filter, { params });
    };

    searchExceptionPhaseWithOverdueExceptionFilter = (search: string, take: number, page: number, filter: OverdueExceptionsFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exphases/overdueexception`, filter, { params });
    };

    searchCommentsWithOverdueExceptionFilter = (search: string, take: number, page: number, filter: OverdueExceptionsFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/overdueexception`, filter, { params });
    };

    searchSubsystemsWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/overdueexceptionbypriority`, filter, { params });
    };

    searchNumbersWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}numbers/overdueexceptionbypriority`, filter, { params });
    };

    searchDescriptionsWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}descriptions/overdueexceptionbypriority`, filter, { params });
    };

    searchTagNumbersWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagnumbers/overdueexceptionbypriority`, filter, { params });
    };

    searchTagTypesWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}tagtypes/overdueexceptionbypriority`, filter, { params });
    };

    searchDisciplinesWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}discplines/overdueexceptionbypriority`, filter, { params });
    };

    searchExceptionNumberWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exnumbers/overdueexceptionbypriority`, filter, { params });
    };

    searchExceptionPhaseWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}exphases/overdueexceptionbypriority`, filter, { params });
    };

    searchCommentsWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}comments/overdueexceptionbypriority`, filter, { params });
    };

    searchPrioritiesWithOverdueExceptionByPriorityFilter = (search: string, take: number, page: number, filter: OverdueExceptionsByPriorityFilters) => {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}priorities/overdueexceptionbypriority`, filter, { params });
    };

    searchSubsystemsByExcludeFilter(filter: SubsystemExcludeFilterRequestDTO): Observable<SubsystemExcludeFiltersResponseDTO> {
        return this.http.post<any>(`${this.rootUrl}${this.endpoint}subsystemsbyexcludefilter`, filter);
    }

    searchSubsystemsWithExcludeFilter(filter: SubsystemExcludeFilterRequestDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystems/excludefilter`, filter);
    }

    searchSubsystemNamesWithExcludeFilter(filter: SubsystemExcludeFilterRequestDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}subsystemnames/excludefilter`, filter);
    }

    searchExcludeValuesWithExcludeFilter(filter: SubsystemExcludeFilterRequestDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}excludevalues/excludefilter`, filter);
    }

    searchITRsByExcludeFilter(filter: ITRExcludeFilterRequestDTO): Observable<ITRExcludeFiltersResponseDTO> {
        return this.http.post<any>(`${this.rootUrl}${this.endpoint}itrexcludefilter`, filter);
    }

    searchITRNumberWithExcludeFilter(filter: ITRExcludeFilterRequestDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}itrnumber/excludefilter`, filter);
    }

    searchITRNumberWithExcludeFilterForcitrSkyine(search: string,take: number, page: number,filter: ITRExcludeFilterRequestDTO) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}citrnumber`, filter, {params});
    }

    searchITRExcludeValuesWithExcludeFilter(filter: ITRExcludeFilterRequestDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}itrexcludevalues/excludefilter`, filter);
    }

    searchPascrNumberWithPascrStatusFilter(search: string, take: number, page: number, filter: PascrStatusFilter, selectedColumn: string) {
        let params = this.getParamsForPascrStatusSearch(search, take, page, selectedColumn);
        return this.http.post(`${this.rootUrl}${this.endpoint}filterdata/pascrstatus`, filter, { params });
    }

    private getParamsForPascrStatusSearch(search: string, take: number, page: number, selectedColumn: string) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        params = params.append ('selectedColumn' , selectedColumn);
        return params;
    }
}
