<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
<mat-accordion>
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header collapsedHeight="64px">
            <h4>constraint configuration</h4>
        </mat-expansion-panel-header>

        <label class="margin-right40" for="constraint-mandatory">Constraint Selection:</label>
        <mat-radio-group
            [(ngModel)]="selectedConstraintSelectionMode"
            id="constraint-mandatory"
            (change)="constraintSelectionChanged($event)"
        >
            <mat-radio-button class="margin-right15" [value]="constraintSelectionModes.Mandatory"
                >mandatory</mat-radio-button
            >
            <mat-radio-button [value]="constraintSelectionModes.Optional">optional</mat-radio-button>
        </mat-radio-group>
        
        <div class="results-container">
            <table mat-table [dataSource]="constraints">
                <ng-container matColumnDef="key">
                    <th mat-header-cell *matHeaderCellDef>Weekly Plan Activity Constraint Type</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="cell-content">
                            <span *ngIf="!element.isInEditMode">{{ element.name }}</span>
                            <div *ngIf="element.isInEditMode" class="editmode">
                                <mat-form-field>
                                    <input
                                        matInput
                                        [(ngModel)]="element.name"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell disableClear *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            *ngIf="!element.isInEditMode"
                            mat-icon-button
                            (click)="enterEditMode(element)"
                            title="edit"
                        >
                            <mat-icon svgIcon="edit"></mat-icon>
                        </button>
                        <button *ngIf="element.isInEditMode" mat-icon-button (click)="save(element)" title="accept">
                            <mat-icon>check_circle_outline</mat-icon>
                        </button>
                        <button
                            *ngIf="element.isInEditMode"
                            mat-icon-button
                            (click)="cancelEdit(element)"
                            title="cancel"
                        >
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button (click)="delete(element)" title="delete">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>

        <mat-toolbar>
            <span>Add new Constraint Type</span>
            <button mat-icon-button (click)="add()" title="add">
                <mat-icon svgIcon="add"></mat-icon>
            </button>
        </mat-toolbar>

        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 15, 20]"
                showFirstLastButtons
                (page)="onPaginatorChange($event)"
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </mat-expansion-panel>
</mat-accordion>
<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
