<div class="popup">
    <div class="icon-remove" (click)="onCancel()"></div>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">Comments History</span>
    </p>
    <mat-dialog-content>
        <table
            mat-table
            matSort
            [dataSource]="dataSource"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container matColumnDef="date">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>date <br />updated</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.date | date: 'd/MMM/y h:mm a' | breakLine: ' ':true }}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>changed by</th>
                <td mat-cell *matCellDef="let element">{{ element.user.name }} {{ element.user.surname }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>comment</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.description | formatMentions: element.mentions"></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-dialog-content>
    <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
</div>
