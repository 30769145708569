import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import { TraceHeatingStatusDownloadAttachmentRequest } from 'src/app/store/trace-heating-status/action';
import { THSAttachmentDto } from 'src/app/store/trace-heating-status/model';

@Component({
  selector: 'app-multiple-attachments',
  templateUrl: './multiple-attachments.component.html',
  styleUrls: ['./multiple-attachments.component.scss']
})
export class MultipleAttachmentsComponent extends BaseComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'link'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  resultsLength = 0;
  pageSize = 10;
  sortBy = 'id';
  direction = 'asc';
  isLoading = false;
  dataSource = new MatTableDataSource();
  constructor(
      public dialogRef: MatDialogRef<MultipleAttachmentsComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private store: Store<ApplicationState>,
      private cdRef:ChangeDetectorRef

  ) {
      super();
      this.dataSource = new MatTableDataSource(data.multiAttach);
      this.resultsLength = data.multiAttach.length;
  }

  ngOnInit() {
      this.dataSource.paginator = this.paginator;
  }

  onCancel(): void {
      this.dialogRef.close();
  }
  ngAfterViewInit() {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
              case 'id':
                  return item[property].name;
              default:
                  return item[property];
          }
      };
      this.cdRef.detectChanges();
  }
  download(attachment: THSAttachmentDto) {
    this.store.dispatch(new TraceHeatingStatusDownloadAttachmentRequest([attachment], 0))
  }
}
