import { DataUpdateHistoryActionTypes, DataUpdateHistoryActions } from "./actions";
import { DataUpdateHistoryState } from "./model";


const initialState: DataUpdateHistoryState = {
    manualPrioritydata: [],
    responsiblePersonOrGroupData: [],
    recordType: '',
    isLoading: false,
};

export function reducer(state: DataUpdateHistoryState = initialState, action: DataUpdateHistoryActions): DataUpdateHistoryState {
    switch (action.type) {
        case DataUpdateHistoryActionTypes.PostRFOResponsiblePersonOrGroupHistoryRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case DataUpdateHistoryActionTypes.PostRFOResponsiblePersonOrGroupHistorySuccess: {
            return {
                ...state,
                responsiblePersonOrGroupData: action.payload,
                isLoading: false
            };
        }
        case DataUpdateHistoryActionTypes.PostRFOResponsiblePersonOrGroupHistoryError: {
            return {
                ...state,
                responsiblePersonOrGroupData: [],
                isLoading: false
            };
        }
        default: {
            return state;
        }

    }
}