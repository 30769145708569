import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserToContractsDTO } from 'src/app/models/user-to-contracts-dto';
import { ITRConstraintDTO } from 'src/app/models/itr-constraint';
import { AppRole } from 'src/app/store/common.model';
import { appConfig } from 'src/app/app.config';
import { DetailedStatusDTO } from 'src/app/store/detailed-status/model';

@Injectable({
    providedIn: 'root',
})
export class ITRConstraintsService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/detailedstatus/itrConstraints'
    private readonly endpointEX: string = '/detailedstatus/addForecastClosureDate'  //'/userContracts';
    private readonly endpointEXResponsible: string = '/detailedstatus/addResponsiblePerson' 
    private readonly exPriorityEndpoint: string = '/detailedstatus/addExceptionPriority';
    constructor(private http: HttpClient) {}


    add(itrc: ITRConstraintDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}`, itrc);
    }

    remove(itrcid: number) {
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?itrcid=${itrcid}`);
    }

    update(itrc: ITRConstraintDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, itrc);
    }
    addEXForeCastClosureDate(ex: DetailedStatusDTO) {
        return this.http.post(`${this.rootUrl}${this.endpointEX}`, ex);
    }
    addEXResponsiblePerson(ex: DetailedStatusDTO) {
        return this.http.post(`${this.rootUrl}${this.endpointEXResponsible}`, ex);
    }
    addExceptionPriority(ex: DetailedStatusDTO) {
        return this.http.post(`${this.rootUrl}${this.exPriorityEndpoint}`, ex);
    }
}
