import { Directive, ElementRef, AfterViewInit, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[app-col-resize]',
})
export class ColResizeDirective implements AfterViewInit {
    startX: number;
    startWidth: number;
    clientRect: DOMRect | ClientRect;
    stickySiblingClassNames: string[];
    stickySiblings: Element[];
    stickySiblingCells: Element[][];

    constructor(private elementRef: ElementRef<HTMLTableHeaderCellElement>, private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.clientRect = this.elementRef.nativeElement.getBoundingClientRect();
        this.stickySiblingClassNames = this.getNextStickySiblings(
            this.elementRef.nativeElement,
            (e) => e.className.indexOf('sticky-col') > -1
        ).map((element) => element.className.split(' ').find((c) => c.indexOf('sticky-col') > -1));
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        this.startX = event.pageX;
        this.startWidth = this.clientRect.width;
        this.registerMouseMoveEvent();
        this.stickySiblingCells = this.stickySiblingClassNames.length
            ? this.findAllStickySiblingsCells(this.stickySiblingClassNames)
            : [];
    }

    @HostListener('mouseup')
    onMouseUp() {
        this.removeMouseMoveEvent();
        const totalWidthChange = this.clientRect.width - this.startWidth;
        this.moveStickySiblingCells(totalWidthChange);
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.removeMouseMoveEvent();
    }

    private onMouseMove = (event: MouseEvent) => {
        const dx = event.pageX - this.startX;
        if (this.elementRef.nativeElement.children.length) {
            this.renderer.addClass(this.elementRef.nativeElement.firstChild, 'resize-column');
        }
        const width = this.startWidth + dx;
        if (width < 50) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'width', `58px`);
        } else {
            this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${width}px`);
        }
        event.preventDefault();
    };

    private registerMouseMoveEvent() {
        this.elementRef.nativeElement.addEventListener('mousemove', this.onMouseMove);
    }

    private removeMouseMoveEvent() {
        this.clientRect = this.elementRef.nativeElement.getBoundingClientRect();
        this.elementRef.nativeElement.removeEventListener('mousemove', this.onMouseMove);
        if (this.elementRef.nativeElement.children.length) {
            this.renderer.removeClass(this.elementRef.nativeElement.firstChild, 'resize-column');
        }
    }

    private getNextStickySiblings(element: Element, filter: (e: Element) => boolean) {
        const siblings: Element[] = [];
        while ((element = element.nextElementSibling)) {
            if (element.nodeType === 3) continue;
            if (!filter || filter(element)) siblings.push(element);
        }
        this.stickySiblings = siblings;
        return siblings;
    }

    private findAllStickySiblingsCells(classNames: string[]) {
        const cells: Element[][] = [];
        classNames.forEach((c) => {
            cells.push(Array.from(document.getElementsByClassName(c)));
        });
        return cells;
    }

    private moveStickySiblingCells(dx: number) {
        if (!this.stickySiblingCells) return;
        this.stickySiblingCells.forEach((column, index) => {
            const left = parseInt((this.stickySiblings[index] as HTMLElement).style.left);
            column.forEach((cell) => {
                this.renderer.setStyle(cell, 'left', `${left + dx}px`);
            });
        });
    }
}
