import { SubsystemScope, ChangeType, WeekType, UploadType } from './enums';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Constants } from './constants';
import {
    AQVDDetailedStatusFilter,
    BITRDetailedStatusFilter,
    CITRDetailedStatusFilter,
    EXDetailedStatusFilter,
    PLIDetailedStatusFilter,
    RedlineDetailedStatusFilter,
    COWDetailedStatusFilter,
    BITRandCITRDetailedStatusFilter,
    ConstraintDetailsStatusFilter,
    RFSUWorkToGoSearchDetailedStatusFilter,
    RFOWorkToGoSearchDetailedStatusFilter
} from './store/detailed-status/model';

export function subsystemScopeEnumToString(scope: SubsystemScope) {
    switch (scope) {
        case SubsystemScope.AQVD:
            return 'A-QVD';
        case SubsystemScope.BITR:
            return 'B-ITR';
        case SubsystemScope.CITR:
            return 'C-ITR';
        case SubsystemScope.PLI:
            return 'PLI';
        case SubsystemScope.EX:
            return 'Exceptions';
        case SubsystemScope.RFSUWorkToGoSearch:
            return 'RFSU Work To Go Search';
        case SubsystemScope.RFOWorkToGoSearch:
            return 'Post-RFO Work To Go';
        case SubsystemScope.Redline:
            return 'Redline';
        case SubsystemScope.COW:
            return 'COW';
        case SubsystemScope.BITRandCITR:
            return 'ITR Constraints';
        case SubsystemScope.ConstraintDetails:
            return 'ITR Constraint Details';
        
        default:
            return '';
    }
}

export function uploadTypeEnumToString(type: UploadType): string {
    switch (type) {
        case UploadType.Tags:
            return 'Tags';
        default:
            return '';
    }
}

export function changeTypeEnumToString(type: ChangeType): string {
    switch (type) {
        case ChangeType.DCN:
            return 'DCN';
        case ChangeType.RFI:
            return 'RFI';
        case ChangeType.NPW:
            return 'NPW';
        case ChangeType.NPWRedzone:
            return 'NPWRedzone';
        case ChangeType.NPWBluezone:
            return 'NPWBluezone';
        case ChangeType.NCR:
            return 'NCR';
        case ChangeType.PCN:
            return 'PCN';
        case ChangeType.CallOff:
            return 'Call-Off';
        case ChangeType.SCCallOff:
            return 'SC Call-Off';
        case ChangeType.TQ:
            return 'TQ';
        case ChangeType.MACDCN:
            return 'MAC DCN';
        case ChangeType.ContractorNCR:
            return 'Contractor NCR';
        case ChangeType.TMOC:
            return 'TMOC';
        case ChangeType.Surveillance:
            return 'Surveillance';
        case ChangeType.RFIIPIMS:
            return 'RFI (iPIMS)';
        case ChangeType.SID:
            return 'SID';
        default:
            return '';
    }
}

export function getWeekRange(startDate: moment.Moment, endDate: moment.Moment) {
    return Math.round(moment.duration(endDate.diff(startDate)).as('days') / 7);
}

export function initWeekRange(startDate: moment.Moment, numberOfWeeks: number) {
    if (moment(startDate).isoWeekday() !== 6) {
        throw 'Weekday has to be set to Saturday';
    }

    const weeks: { date: Date; weekType: WeekType; weekEnd: Date }[] = [];
    const currentWeekStartDate = getCurrentWeekStartDate();

    const week = moment(startDate);
    _.times(numberOfWeeks, () => {
        weeks.push({
            date: moment(week).toDate(),
            weekType: week.isBefore(currentWeekStartDate, 'days') ? WeekType.Past : WeekType.Upcoming,
            weekEnd: getWeekEndDate(week).toDate(),
        });
        week.add(1, 'weeks');
    });

    return weeks;
}

export function initMonthRange(startDate: moment.Moment, numberOfMonths: number) {
    const months: { date: Date; weekType: WeekType }[] = [];
    const currentMonthStartDate = moment().startOf('month');

    const month = moment(startDate).startOf('month');
    _.times(numberOfMonths, () => {
        months.push({
            date: moment(month).toDate(),
            weekType: month.isBefore(currentMonthStartDate, 'days') ? WeekType.Past : WeekType.Upcoming,
        });
        month.add(1, 'months');
    });

    return months;
}

export function initQuarterRange(startDate: moment.Moment, numberOfQuarters: number) {
    const months: { date: Date; weekType: WeekType }[] = [];
    const currentQuarterStartDate = moment().startOf('quarter');

    const month = moment(startDate).startOf('month');
    _.times(numberOfQuarters, () => {
        months.push({
            date: moment(month).toDate(),
            weekType: month.isBefore(currentQuarterStartDate, 'days') ? WeekType.Past : WeekType.Upcoming,
        });
        month.add(3, 'months');
    });

    return months;
}

export function getCurrentQuarterStartDate() {
    const todayStartOfDay = moment().utc().startOf('quarter');

    return todayStartOfDay;
}

export function getCurrentWeekStartDate() {
    const todayStartOfDay = moment().utc().startOf('day');

    return getStartWeekFromDate(todayStartOfDay);
}

export function getStartWeekFromDate(startOfDay: moment.Moment) {
    const daysToSubstract = getDaysToSubstract(startOfDay);
    const startOfWeek = startOfDay.subtract(daysToSubstract, 'd');
    return startOfWeek;
}

function getDaysToSubstract(date: moment.Moment) {
    const daysToSubtract =
        date.isoWeekday() >= Constants.START_OF_WEEK_DAY
            ? date.isoWeekday() - Constants.START_OF_WEEK_DAY
            : 7 + date.isoWeekday() - Constants.START_OF_WEEK_DAY;

    return daysToSubtract;
}

export function getStartWeekDateFromDate(date: moment.Moment): moment.Moment {
    const startOfDay = moment(date).utc().startOf('day');

    return getStartWeekFromDate(startOfDay);
}

export function getNextWeekStartDate() {
    return getCurrentWeekStartDate().add(7, 'days');
}

export function getWeekEndDate(date: moment.Moment) {
    const weekStart = moment(date);
    return weekStart.add(6, 'days');
}

export function getWeekEndDates() {
    const weekStart = moment(getCurrentWeekStartDate());
    return weekStart.add(6, 'days');
}

export function getDetailedStatusFilterInstance(scope: string) {
    const subsystemScopes = SubsystemScope;
    switch (scope) {
        case subsystemScopes[SubsystemScope.AQVD]:
            return new AQVDDetailedStatusFilter();
        case subsystemScopes[SubsystemScope.BITR]:
            return new BITRDetailedStatusFilter();
        case subsystemScopes[SubsystemScope.CITR]:
            return new CITRDetailedStatusFilter();
        case subsystemScopes[SubsystemScope.PLI]:
            return new PLIDetailedStatusFilter();
        case subsystemScopes[SubsystemScope.Redline]:
            return new RedlineDetailedStatusFilter();
        case subsystemScopes[SubsystemScope.EX]:
            return new EXDetailedStatusFilter();
        case subsystemScopes[SubsystemScope.RFSUWorkToGoSearch]:
            return new RFSUWorkToGoSearchDetailedStatusFilter();
        case subsystemScopes[SubsystemScope.RFOWorkToGoSearch]:
            return new RFOWorkToGoSearchDetailedStatusFilter();   
        case subsystemScopes[SubsystemScope.COW]:
            return new COWDetailedStatusFilter();
        case subsystemScopes[SubsystemScope.BITRandCITR]:
            return new BITRandCITRDetailedStatusFilter();
        case subsystemScopes[SubsystemScope.ConstraintDetails]:
            return new ConstraintDetailsStatusFilter();
        default:
            throw new Error('Invalid detailed status scope.');
    }
}
