import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ChangeType } from 'src/app/enums';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { DesignStatusMappingDTO } from 'src/app/models/design-status-mapping-dto';
import { PopupSettings } from 'src/app/models/popup-settings';
import { DesignStatusMappingService } from 'src/app/services/api/webapi-services/design-status-mapping-service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AddDesignStatusMappingComponent } from './add-design-status-mapping/add-design-status-mapping.component';

@Component({
  selector: 'app-design-status-mapping',
  templateUrl: './design-status-mapping.component.html',
  styleUrls: ['./design-status-mapping.component.scss']
})
export class DesignStatusMappingComponent extends BaseComponent implements OnInit {
  @Input() changeType: string;
  displayedColumns = [];
  dataSource = new MatTableDataSource<DesignStatusMappingDTO>();
  isLoading: boolean = false;
  changeTypes = ChangeType;
  rfiType: string = ChangeType[this.changeTypes.RFI];

  constructor(private designStatusMappingService: DesignStatusMappingService,
    private popupService: PopupService,
    private toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.displayedColumns = this.changeType === this.rfiType ? 
      ['designStatus', 'fieldImpact', 'sourceFileStatus', 'rfiImpact', 'revision', 'remove'] :
      ['designStatus', 'fieldImpact', 'sourceFileStatus', 'revision', 'remove'];
    
    this.designStatusMappingService.get(this.changeType)
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe(
        (data) => {
          this.dataSource = new MatTableDataSource(data);
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastService.Error(error);
        });
  }

  add() {
    const dialogRef = this.popupService.openPopup(
      new PopupSettings(AddDesignStatusMappingComponent, 450, 430, {
        changeType: this.changeType,
      })
    );

    dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((result) => {
        if (result && result.success) {
          const data = this.dataSource.data;
          data.push(result.data);
          this.dataSource.data = data;
        }
      });
  }

  remove(element: DesignStatusMappingDTO) {
    this.popupService
      .openPopup(
        new ConfirmDialogPopupSettings({
          title: 'Confirm action',
          text: `Are you sure you want to delete ${element.sourceFileStatus} mapping?`,
        })
      )
      .afterClosed()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((answer) => {
        if (answer) {
          this.isLoading = true;
          this.designStatusMappingService
            .remove(element.id)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
              () => {
                let data = this.dataSource.data.filter((s) => s.id !== element.id);
                this.dataSource = new MatTableDataSource(data);
                this.isLoading = false;
              },
              () => {
                this.isLoading = false;
                this.toastService.Error(
                  `Error occurred while deleting ${element.sourceFileStatus} mapping. Please contact Program Administrator.`
                );
              }
            );
        }
      });
  }

}
