import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { MilestoneDashboardService } from 'src/app/services/api/webapi-services/reports/milestone-dashboard.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from '../../model';
import {
    SubsystemMCDasbboard1RedirectToDetailedStatus,
    SubsystemMCDashboardActionTypes,
    SubsystemMCDashboard1Error,
    SubsystemMCDashboard1ExportToExcelError,
    SubsystemMCDashboard1ExportToExcelSuccess,
    SubsystemMCDashboard1Success,
    SubsystemMCDashboard2Success,
    SubsystemMCDashboard2Error,
    SubsystemMCDashboard2ExportToExcelSuccess,
    SubsystemMCDashboard2ExportToExcelError,
} from './actions';

@Injectable()
export class SubsystemMCDashboardEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private milestoneDashboardService: MilestoneDashboardService,
        private toastService: ToastService,
        private router: Router
    ) {}

    @Effect()
    subsystemMCDashboard1Request$ = this.actions$.pipe(
        ofType(SubsystemMCDashboardActionTypes.SubsystemMCDashboard1Request),
        withLatestFrom(this.store.select((state) => state.subsystemMCDashboardState.dashboard1Filter)),
        mergeMap(([action, filter]) =>
            this.milestoneDashboardService.filterSubsystemMC1Data(filter).pipe(
                map((data) => new SubsystemMCDashboard1Success(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Subsystem MC Dashboard data. Please contact Program Administrator.`
                    );
                    return of(new SubsystemMCDashboard1Error(error));
                })
            )
        )
    );

    @Effect()
    subsystemMCDashboard2Request$ = this.actions$.pipe(
        ofType(SubsystemMCDashboardActionTypes.SubsystemMCDashboard2Request),
        withLatestFrom(this.store.select((state) => state.subsystemMCDashboardState.dashboard2Filter)),
        mergeMap(([action, filter]) =>
            this.milestoneDashboardService.filterSubsystemMC2Data(filter).pipe(
                map((data) => new SubsystemMCDashboard2Success(data)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while getting Subsystem MC Dashboard 2 data. Please contact Program Administrator.`
                    );
                    return of(new SubsystemMCDashboard2Error(error));
                })
            )
        )
    );

    @Effect()
    subsystemMCDashboard1ExportToExcel$ = this.actions$.pipe(
        ofType(SubsystemMCDashboardActionTypes.SubsystemMCDashboard1ExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.subsystemMCDashboardState.dashboard1Filter)),
        mergeMap(([, subsystemMCDashboardFilter]) => {
            subsystemMCDashboardFilter.timezoneOffset = new Date().getTimezoneOffset();
            return this.milestoneDashboardService.generateSubsystemMCExcel(subsystemMCDashboardFilter).pipe(
                map((excelData) => new SubsystemMCDashboard1ExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new SubsystemMCDashboard1ExportToExcelError(error));
                })
            );
        })
    );

    @Effect()
    subsystemMCDashboard2ExportToExcel$ = this.actions$.pipe(
        ofType(SubsystemMCDashboardActionTypes.SubsystemMCDashboard2ExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.subsystemMCDashboardState.dashboard2Filter)),
        mergeMap(([, subsystemMCDashboardFilter]) => {
            subsystemMCDashboardFilter.timezoneOffset = new Date().getTimezoneOffset();
            return this.milestoneDashboardService.generateSubsystemMC2Excel(subsystemMCDashboardFilter).pipe(
                map((excelData) => new SubsystemMCDashboard2ExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new SubsystemMCDashboard2ExportToExcelError(error));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    saveSubsystemMCExcelData = this.actions$.pipe(
        ofType(SubsystemMCDashboardActionTypes.SubsystemMCDashboard1ExportToExcelSuccess),
        map((action: SubsystemMCDashboard1ExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `SubsystemMCDashboard_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect({ dispatch: false })
    saveSubsystemMC2ExcelData = this.actions$.pipe(
        ofType(SubsystemMCDashboardActionTypes.SubsystemMCDashboard2ExportToExcelSuccess),
        map((action: SubsystemMCDashboard2ExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `SubsystemMCDashboard2_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    );

    @Effect({ dispatch: false })
    redirectToDetailedStatus$ = this.actions$.pipe(
        ofType<SubsystemMCDasbboard1RedirectToDetailedStatus>(
            SubsystemMCDashboardActionTypes.SubsystemMCDasbboard1RedirectToDetailedStatus
        ),
        withLatestFrom(this.store.select((state) => state.subsystemMCDashboardState.dashboard1Filter)),
        map(([action, filter]) => {
            const goocs = filter.goocs.map((g) => g.no);
            const mcEngineers = filter.mcEngineer;
            const systemOwners = filter.systemOwner;
            const contractors = filter.contractors.map((m) => m.name);

            this.router.navigate(['detailedstatus'], {
                queryParams: {
                    lockFilters: true,
                    subsystem: action.payload.subsystem,
                    scope: action.payload.exceptions ? 'EX' : action.payload.scope,
                    goocs: goocs.length ? goocs : undefined,
                    exScope: action.payload.exceptions ? 'ITR' : undefined,
                    subsystemScope: action.payload.exceptions ? 'AQVD' : undefined,
                    category: action.payload.category,
                    excludeExceptions: action.payload.exceptions ? undefined : ['MC', 'RFSU', 'RFO'],
                    mcEngineers: mcEngineers.length ? mcEngineers : undefined,
                    systemOwners: systemOwners.length ? systemOwners : undefined,
                    contractors: contractors.length ? contractors : undefined,
                    zone: action.payload.zone,
                },
            });
        })
    );
}
