import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { takeWhile, map } from 'rxjs/operators';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { Contractor } from 'src/app/store/common.model';
import { AddNewChangeService } from 'src/app/services/api/webapi-services/add-new-change.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ClearableMatDatepickerComponent } from '../../clearable-mat-datepicker/clearable-mat-datepicker.component';
import * as _ from 'lodash';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-add-new-change',
    templateUrl: './add-new-change.component.html',
    styleUrls: ['./add-new-change.component.scss'],
})
export class AddNewChangeComponent extends BaseComponent implements OnInit {
    addNewChangeForm: UntypedFormGroup;
    types = ['RFI', 'DCN', 'NCR', 'PCN', 'MAC DCN'];
    projectTeamNames: string[] = [];
    projectTeamNameCurrent: string;
    impactedAreas: string[] = [
        '40',
        '41',
        '42',
        '43',
        '44',
        '45',
        '47',
        '48',
        '49',
        '50',
        '51',
        '52',
        '53',
        '54',
        '55',
        '56',
        '57',
        '58',
        '59',
    ];
    disciplines: string[] = [];
    designStatuses: string[] = [];
    contractors: string[] = [];

    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setTagsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    autocompleteDisplayedColumns = ['contract', 'contractNo'];
    autocompleteTagsDisplayedColumns = ['tagNo', 'tagType'];

    disabledControls = {
        RFI: [],
        DCN: [],
        NCR: [],
        PCN: ['description'],
    };

    isLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public popupData: any,
        private projectTeamsService: ProjectTeamsService,
        private lookupService: LookupService,
        private toastService: ToastService,
        private addNewChangeService: AddNewChangeService,
        private dialogRef: MatDialogRef<AddNewChangeComponent>,
        private popupSvc: PopupService
    ) {
        super();

        this.addNewChangeForm = new UntypedFormGroup({
            type: new UntypedFormControl('RFI', Validators.required),
            changeDocumentNo: new UntypedFormControl('', Validators.required),
            title: new UntypedFormControl('', Validators.required),
            revision: new UntypedFormControl('', Validators.required),
            projectTeam: new UntypedFormControl('', Validators.required),
            discipline: new UntypedFormControl('', Validators.required),
            contractor: new UntypedFormControl([], Validators.required),
            changeStatus: new UntypedFormControl('', Validators.required),
            issuedDate: new UntypedFormControl('', Validators.required),
            description: new UntypedFormControl(''),
            subsystems: new UntypedFormControl([]),
            tags: new UntypedFormControl([]),
        });

        dialogRef.disableClose = true;

        dialogRef.backdropClick().subscribe(() => {
            this.cancel();
        });
    }

    ngOnInit() {
        this.projectTeamsService
            .getTeams(false)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (projectTeams) => {
                    this.projectTeamNames = projectTeams;

                    if (this.projectTeamNames.length === 1) {
                        this.addNewChangeForm.controls.projectTeam.patchValue(this.projectTeamNames[0]);
                    }
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while getting project teams. Please contact Program Administrator.'
                    );
                }
            );

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: string[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting discipline. Please contact Program Administrator.'
                    );
                }
            );

        this.lookupService
            .getDesignStatuses()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (designStatuses) => (this.designStatuses = designStatuses),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting statuses. Please contact Program Administrator.'
                    );
                }
            );

        this.setControlsEnableProperty();
    }

    getTags = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchTagsWithTagTypes(search, take, page, [this.addNewChangeForm.value.projectTeam]);
    }

    add() {
        if (this.addNewChangeForm.invalid) {
            let validationErrorMessage = 'Following fields have to be set: ';

            Object.keys(this.addNewChangeForm.controls).forEach((key) => {
                const controlErrors: ValidationErrors = this.addNewChangeForm.get(key).errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach(() => {
                        validationErrorMessage += _.startCase(key) + ', ';
                    });
                }
            });

            validationErrorMessage = validationErrorMessage.slice(0, -2) + '.';
            this.toastService.Error(validationErrorMessage);

            return;
        }

        this.isLoading = true;

        const changeDocument = {
            id: 0,
            changeType: this.addNewChangeForm.controls.type.value,
            number: this.addNewChangeForm.controls.changeDocumentNo.value,
            title: this.addNewChangeForm.controls.title.value,
            status: this.addNewChangeForm.controls.changeStatus.value,
            revision: this.addNewChangeForm.controls.revision.value,
            issueDate: this.addNewChangeForm.controls.issuedDate.value,
            projectTeamName: this.addNewChangeForm.controls.projectTeam.value,
            discipline: this.addNewChangeForm.controls.discipline.value,
            contract: this.addNewChangeForm.controls.contractor.value[0].contractNo,
            remark: this.addNewChangeForm.controls.description.value,
            subsystems: this.addNewChangeForm.controls.subsystems.value.map((x) => x.id).join(', '),
            impactedTags: this.addNewChangeForm.controls.tags.value.map((x) => x.tagNo).join(', '),
            changeDocTags: this.addNewChangeForm.controls.tags.value.map((x) => ({ tagId: x.id })),
        };

        this.addNewChangeService
            .addNewChange(changeDocument)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.dialogRef.close();
                    this.toastService.Success('Change document has been added.');
                },
                (error: HttpErrorResponse) => {
                    this.isLoading = false;

                    if (error.status === 409) {
                        this.toastService.Error(error.error);
                    } else {
                        this.toastService.Error('Error occured while adding change document.');
                    }
                }
            );
    }

    cancel() {
        this.popupSvc
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to discard it?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.dialogRef.close();
                }
            });
    }

    getCWPs = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchCWPs(search, take, page, [this.addNewChangeForm.controls.projectTeam.value]);
    }

    onTypeClosed(isOpen: boolean) {
        if (!isOpen) {
            this.setControlsEnableProperty();
        }
    }

    setControlsEnableProperty() {
        const selectedType = this.addNewChangeForm.controls.type.value;

        Object.keys(this.addNewChangeForm.controls).forEach((key) => {
            this.addNewChangeForm.controls[key].enable();
        });

        this.disabledControls[selectedType]?.forEach((x) => {
            this.addNewChangeForm.controls[x].disable();
        });
    }

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNameCurrent !== this.addNewChangeForm.controls.projectTeam.value) {

            this.setContractorInput.emit(new SetInputEventArgs(true));
            this.setCorrectValuesPerProjectTeam(this.addNewChangeForm.controls.projectTeam.value, 'contractor');

            this.setSubsystemInput.emit(new SetInputEventArgs(true));
            this.setCorrectValuesPerProjectTeam(this.addNewChangeForm.controls.projectTeam.value, 'subsystems');

            this.setTagsInput.emit(new SetInputEventArgs(true));
            this.setCorrectValuesPerProjectTeam(this.addNewChangeForm.controls.projectTeam.value, 'tags');
        }

        this.projectTeamNameCurrent = this.addNewChangeForm.controls.projectTeam.value;
    }

    getContractorsMulti = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, [this.addNewChangeForm.controls.projectTeam.value])
            .pipe(map((contractors: Contractor[]) => contractors));
    }

    openDatepicker() {
        const date = this.addNewChangeForm.controls.issuedDate;

        this.popupSvc.openPopup(
            new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                dateControl: date,
                dateType: 'changeDoc',
            })
        );
    }

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, [this.addNewChangeForm.value.projectTeam]);
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }

        const valid =
            this.addNewChangeForm.controls[controlName].value.length &&
            this.addNewChangeForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );

        if (valid) {
            let selectedValue: any;

            if (controlName === 'cwps') {
                selectedValue = this.addNewChangeForm.controls[controlName].value.map((x) => x.id).join(', ');
            } else if (controlName === 'contractor') {
                selectedValue = this.addNewChangeForm.controls[controlName].value.map((x) => x.contractNo).join(', ');
            } else if (controlName === 'subsystems') {
                selectedValue = this.addNewChangeForm.controls[controlName].value.map((x) => x.id).join(', ');
            } else if (controlName === 'tags') {
                selectedValue = this.addNewChangeForm.controls[controlName].value.map((x) => x.tagNo).join(', ');
            }

            this.toastService.Info(
                `Selected value(s): ${selectedValue} ` +
                    `don't belong to selected ${this.addNewChangeForm.controls.projectTeam.value} project team. ` +
                    `Value(s) have been deselected.`
            );

            this.addNewChangeForm.controls[controlName].setValue(valid);
        }
    }
}
