import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { HealthCleanlinessSetType, HealthCleanlinessRequest } from 'src/app/store/reports/health/actions';

@Component({
    selector: 'app-reports-health-cleanliness-content',
    templateUrl: './reports-health-cleanliness-content.component.html',
    styleUrls: ['./reports-health-cleanliness-content.component.scss'],
})
export class ReportsHealthCleanlinessContentComponent implements OnInit {
    commitmentReliabilityType = '';

    isLoading$ = this.store.select((store) => store.healthState.cleanliness.isLoading);

    constructor(private activatedRoute: ActivatedRoute, private store: Store<ApplicationState>) {}

    ngOnInit() {
        this.commitmentReliabilityType = this.activatedRoute.routeConfig.path;
        this.store.dispatch(new HealthCleanlinessSetType(this.commitmentReliabilityType));
        this.store.dispatch(new HealthCleanlinessRequest());
    }
}
