import { Action } from "@ngrx/store";
import { SubsystemScope } from "src/app/enums";
import { UserDetail } from "../common.model";
import { ScheduleActivityITRDTO, ScheduleActivityITRFilter, ScheduleActivityITRScope, ShowHideColumns } from "./model";

export enum ScheduleActivityITRActionTypes {
    ITRDataRequest = '[Schedule Activity ITR] Requesting ITRs',
    ITRDataRequestSuccess = '[Schedule Activity ITR] Request for ITR data Success',
    ITRDataRequestError = '[Schedule Activity ITR] Request for ITR data Error',
    
    FilterPropertyReset = '[Schedule Activity ITR] Filter Property Reset',
    FilterPropertyUpdate = '[Schedule Activity ITR] Filter Property Update',

    ExportToExcelRequest = '[Schedule Activity ITR] Export to Excel Request',
    ExportToExcelSuccess = '[Schedule Activity ITR] Export to Excel Success',
    ExportToExcelError = '[Schedule Activity ITR] Export to Excel Error',

    AddCommentRequest = '[Schedule Activity ITR] Add Comment Request',
    AddCommentSuccess = '[Schedule Activity ITR] Add Comment Success',
    AddCommentError = '[Schedule Activity ITR] Add Comment Error',

    ITRsToGoDetailsSuccess = '[Schedule Activity ITR] ITRs TO GO Details Success',

    SaveSelectedITRIdsRequest = '[Schedule Activity ITR] Save Selected ITR Ids Request',
    SaveSelectedITRIdsSuccess = '[Schedule Activity ITR] Save Selected ITR Ids Success',
    SaveSelectedITRIdsError = '[Schedule Activity ITR] Save Selected ITR Ids Error',
}

export class ScheduleActivityITRDataRequest implements Action {
    readonly type = ScheduleActivityITRActionTypes.ITRDataRequest;

    constructor() { 
        // This is intentional
    }
}

export class ScheduleActivityITRDataSuccess implements Action {
    readonly type = ScheduleActivityITRActionTypes.ITRDataRequestSuccess;

    constructor(
        public payload: {
            items: ScheduleActivityITRDTO[];
            totalCount: number;
        }
    ) {
    }
}

export class ScheduleActivityITRDataError implements Action {
    readonly type = ScheduleActivityITRActionTypes.ITRDataRequestError;

    constructor(param: { error: any }) {
        // This is intentional
    }
}

export class ScheduleActivityITRFilterPropertyUpdate implements Action {
    readonly type = ScheduleActivityITRActionTypes.FilterPropertyUpdate;

    constructor(public filter: ScheduleActivityITRFilter) { 
        // This is intentional
    }
}

export class ScheduleActivityITRFilterPropertyReset implements Action {
    readonly type = ScheduleActivityITRActionTypes.FilterPropertyReset;
}

export class ScheduleActivityITRExportToExcelRequest implements Action {
    readonly type = ScheduleActivityITRActionTypes.ExportToExcelRequest;

    constructor(
        public payload: {
            scope: ScheduleActivityITRScope;
            activityId?: string;
            activityDiscipline?: string;
            sortBy: string;
        }
    ) {
    }
}

export class ScheduleActivityITRExportToExcelError implements Action {
    readonly type = ScheduleActivityITRActionTypes.ExportToExcelError;

    constructor(public payload: string) { 
        // This is intentional
    }
}

export class ScheduleActivityITRExportToExcelSuccess implements Action {
    readonly type = ScheduleActivityITRActionTypes.ExportToExcelSuccess;

    constructor(public payload: any) { 
        // This is intentional
    }
}

export class ScheduleActivityITRAddCommentRequest implements Action {
    readonly type = ScheduleActivityITRActionTypes.AddCommentRequest;

    constructor(
        public payload: { entityId: string; description: string; scope: SubsystemScope; mentions: UserDetail[] }
    ) { 
        // This is intentional
    }
}

export class ScheduleActivityITRAddCommentSuccess implements Action {
    readonly type = ScheduleActivityITRActionTypes.AddCommentSuccess;

    constructor(
        public payload: { entityId: string; description: string; scope: SubsystemScope; mentions: UserDetail[] }
    ) { 
        // This is intentional
    }
}

export class ScheduleActivityITRAddCommentError implements Action {
    readonly type = ScheduleActivityITRActionTypes.AddCommentError;

    constructor(public payload: string) { 
        // This is intentional
    }
}

export class ScheduleActivityITRsToGoDetailsSuccess implements Action {
    readonly type = ScheduleActivityITRActionTypes.ITRsToGoDetailsSuccess;

    constructor(public payload: any) { 
        // This is intentional
    }
}

export class ScheduleActivityITRSaveSelectedITRIdsRequest implements Action {
    readonly type = ScheduleActivityITRActionTypes.SaveSelectedITRIdsRequest;

    constructor(public payload:
        { 
            activityId: string;
            selectedIds: number[];
            activityDiscipline: string;
            additionToPlan: boolean;
        }) { 
        // This is intentional
    }
}

export class ScheduleActivityITRSaveSelectedITRIdsSuccess implements Action {
    readonly type = ScheduleActivityITRActionTypes.SaveSelectedITRIdsSuccess;
}

export class ScheduleActivityITRSaveSelectedITRIdsError implements Action {
    readonly type = ScheduleActivityITRActionTypes.SaveSelectedITRIdsError;

    constructor(public payload: string) { 
        // This is intentional
    }
}

export type ScheduleActivityITRActions =
    | ScheduleActivityITRDataRequest
    | ScheduleActivityITRDataSuccess
    | ScheduleActivityITRDataError
    | ScheduleActivityITRFilterPropertyUpdate
    | ScheduleActivityITRExportToExcelRequest
    | ScheduleActivityITRExportToExcelError
    | ScheduleActivityITRExportToExcelSuccess
    | ScheduleActivityITRAddCommentRequest
    | ScheduleActivityITRAddCommentSuccess
    | ScheduleActivityITRAddCommentError
    | ScheduleActivityITRsToGoDetailsSuccess
    | ScheduleActivityITRSaveSelectedITRIdsRequest
    | ScheduleActivityITRSaveSelectedITRIdsSuccess
    | ScheduleActivityITRSaveSelectedITRIdsError
    | ScheduleActivityITRFilterPropertyReset;