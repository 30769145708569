import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FormService } from 'src/app/services/shared/form.service';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import {
    ProjectTeamRoleFilterRequest,
    ProjectTeamRoleFilterPropertyUpdate,
    ProjectTeamRoleRemoveRequest,
    ProjectTeamRoleSaveRequest,
} from 'src/app/store/data-maintenance/team-data/projectTeamRole/actions';
import { OrderDirection } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AddProjectTeamRolePopupComponent } from './add-popup/add-project-team-role-popup.component';
import { ProjectTeamRolesMaintenanceService } from 'src/app/services/api/webapi-services/project-team-roles-maintenance.service';
import { ProjectTeamRoleFilter } from 'src/app/store/data-maintenance/team-data/projectTeamRole/model';
import { ProjectTeamRoleDTO } from 'src/app/models/project-team-role-dto';
import { TCORole, GetTCORoleName } from 'src/app/enums';
import { ToastService } from 'src/app/services/shared/toast.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-project-team-roles-maintenance',
    templateUrl: './project-team-roles-maintenance.component.html',
    styleUrls: ['./project-team-roles-maintenance.component.scss'],
})
export class ProjectTeamRolesMaintenanceComponent extends BaseComponent implements OnInit {
    projectTeamNames: string[] = [];

    roles: { value: TCORole; text: string }[] = [
        { value: TCORole.SCManager, text: GetTCORoleName(TCORole.SCManager) },
        { value: TCORole.MCEngineer, text: GetTCORoleName(TCORole.MCEngineer) },
        { value: TCORole.SystemOwner, text: GetTCORoleName(TCORole.SystemOwner) },
        { value: TCORole.ACM, text: GetTCORoleName(TCORole.ACM) },
    ];

    projectTeamNamesCurrent: string[];
    rolesCurrent: number[];
    resultsLength = 0;
    pageSize = 5;
    sortBy: string;
    direction = OrderDirection.Desc;
    isLoading = false;

    projectTeamRoleFilter$ = this.store.select((store) => store.projectTeamRoleMaintenanceState.filter);
    projectTeamRoleDataPagination$ = this.store.select(
        (store) => store.projectTeamRoleMaintenanceState.projectTeamRoleDataPagination
    );
    projectTeamRoleLoader$ = this.store.select((store) => store.projectTeamRoleMaintenanceState.isLoading);

    filterForm: UntypedFormGroup;

    displayedColumns = ['projectTeamName', 'role', 'name', 'edit', 'remove'];

    data: ProjectTeamRoleDTO[] = [];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private formService: FormService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store<ApplicationState>,
        private popupService: PopupService,
        private toastService: ToastService,
        private projectTeamRolesMaintenanceService: ProjectTeamRolesMaintenanceService,
        private lookupService: LookupService
    ) {
        super();

        this.filterForm = this.formService.createSimpleForm(new ProjectTeamRoleFilter());

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.watchFormChanges();

        this.lookupService
            .getProjectTeamNames()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });

        this.projectTeamRoleDataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            const copy = cloneDeep(data);
            this.data = copy.items;
            this.resultsLength = copy.totalCount;
        });

        this.projectTeamRoleFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.paginator.pageIndex = filter.page;
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new ProjectTeamRoleFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new ProjectTeamRoleFilterRequest());
        });

        this.projectTeamRoleLoader$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => (this.isLoading = isLoading));

        this.store.dispatch(new ProjectTeamRoleFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new ProjectTeamRoleFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new ProjectTeamRoleFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new ProjectTeamRoleFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
            this.store.dispatch(new ProjectTeamRoleFilterRequest());
        }
    }

    onRolesClosed(isOpen: boolean) {
        if (!isOpen && this.rolesCurrent !== this.filterForm.controls.roles.value) {
            this.rolesCurrent = this.filterForm.controls.roles.value;
            this.store.dispatch(new ProjectTeamRoleFilterRequest());
        }
    }

    private watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new ProjectTeamRoleFilterPropertyUpdate({ key, value }));
            });
        }
    }

    remove(id: string, name: string) {
        this.isLoading = true;

        this.projectTeamRolesMaintenanceService.getDbLinksInfo(id).subscribe((dbLinksInfo: string) => {
            this.isLoading = false;

            if (dbLinksInfo !== '') {
                this.popupService
                    .openPopup(
                        new ConfirmDialogPopupSettings({
                            title: 'Confirm action',
                            text: dbLinksInfo,
                        })
                    )
                    .afterClosed()
                    .pipe(takeWhile(() => this.isAlive === true))
                    .subscribe((answer) => {
                        if (answer) {
                            this.removeProjectTeamRoleSecondConfirmation(id, name);
                        }
                    });
            } else {
                this.removeProjectTeamRoleSecondConfirmation(id, name);
            }
        });
    }

    removeProjectTeamRoleSecondConfirmation(id: string, name: string) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete ${name}?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.store.dispatch(new ProjectTeamRoleRemoveRequest(id));
                }
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(
            new PopupSettings(AddProjectTeamRolePopupComponent, 450, 450, { projectTeamNames: this.projectTeamNames })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    this.store.dispatch(new ProjectTeamRoleFilterRequest());
                }
            });
    }

    enterEditMode(projectTeamRole: ProjectTeamRoleDTO) {
        projectTeamRole.isInEditMode = !projectTeamRole.isInEditMode;
        projectTeamRole.prevName = projectTeamRole.name;
    }

    save(projectTeamRole: ProjectTeamRoleDTO) {
        if (projectTeamRole.name.trim() === '') {
            projectTeamRole.name = projectTeamRole.prevName;
            this.toastService.Error('Name cannot be empty.');
        }

        projectTeamRole.isInEditMode = !projectTeamRole.isInEditMode;

        if (projectTeamRole.name !== projectTeamRole.prevName) {
            projectTeamRole.name = projectTeamRole.name.trim();
            this.store.dispatch(new ProjectTeamRoleSaveRequest(projectTeamRole));
        }
    }

    cancelEdit(projectTeamRole: ProjectTeamRoleDTO) {
        projectTeamRole.isInEditMode = !projectTeamRole.isInEditMode;
        projectTeamRole.name = projectTeamRole.prevName;
    }
}
