import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';
import { CalendarColumn, CheckListColumn, OrderDirection } from '../../common.model';

export interface CriticalPathState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deleteData: CriticalPathDeletePagination;
    isDeleteDataLoading: boolean;
    deleteFilter: CriticalPathDeleteFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    isImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
    recordsForDeletion: number;
}

export class CriticalPathDeleteDTO {
    deleteState: boolean;
    activityId: string;
}

export class CriticalPathDeleteFilter {
    page = 0;
    take = 10;
    sortBy = 'barcode';
    direction: OrderDirection = OrderDirection.Desc;
    columnActivityIds: CheckListColumn = null;
}
export class CriticalPathDeletePagination {
    items: CriticalPathDeleteDTO[] = [];
    totalCount = 0;
}
