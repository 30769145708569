import { PlanningDataState, PlanningDataDeltaFilter, PlanningDataDeltaPagination, PlanningDataDeltaDTO } from './model';
import { PlanningDataActions, PlanningDataActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: PlanningDataState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deltaData: new PlanningDataDeltaPagination(),
    isDeltaDataLoading: false,
    deltaFilter: new PlanningDataDeltaFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
    recordsForDeletion: 0,
};

export function reducer(state = initialState, action: PlanningDataActions) {
    switch (action.type) {
        case PlanningDataActionTypes.PlanningDataUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case PlanningDataActionTypes.PlanningDataUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case PlanningDataActionTypes.PlanningDataUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case PlanningDataActionTypes.PlanningDataPatchAllDeltaRequest:
        case PlanningDataActionTypes.PlanningDataDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case PlanningDataActionTypes.PlanningDataDeltaSuccess: {
            return {
                ...state,
                deltaData: action.payload,
                isDeltaDataLoading: false,
            };
        }
        case PlanningDataActionTypes.PlanningDataValidateDeltaError:
        case PlanningDataActionTypes.PlanningDataPatchAllDeltaError:
        case PlanningDataActionTypes.PlanningDataPatchDeltaError:
        case PlanningDataActionTypes.PlanningDataPatchDeltaSuccess:
        case PlanningDataActionTypes.PlanningDataDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case PlanningDataActionTypes.PlanningDataSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case PlanningDataActionTypes.PlanningDataDeltaFilterPropertyUpdate: {
            state.deltaFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case PlanningDataActionTypes.PlanningDataPatchDeltaRequest: {
            state.deltaData.items.find((d) => d.subsystem === action.payload.subsystem).deltaState =
                action.payload.deltaState;
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case PlanningDataActionTypes.PlanningDataPatchAllDeltaSuccess: {
            const delta = state.deltaData.items.map(
                (i) => ({ ...i, deltaState: action.payload.deltaState } as PlanningDataDeltaDTO)
            );
            return {
                ...state,
                deltaData: {
                    ...state.deltaData,
                    items: delta
                },
                isDeltaDataLoading: false,
            };
        }
        case PlanningDataActionTypes.PlanningDataValidateDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
                isValidateButtonEnabled: false,
                recordsForDeletion: 0,
            };
        }
        case PlanningDataActionTypes.PlanningDataValidateDeltaSuccess: {
            return {
                ...state,
                deltaData: new PlanningDataDeltaPagination(),
                isDeltaDataLoading: false,
            };
        }
        case PlanningDataActionTypes.PlanningDataFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deltaData: new PlanningDataDeltaPagination(),
            };
        }
        case PlanningDataActionTypes.PlannignDataClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case PlanningDataActionTypes.PlanningDataDownloadOutputDataRequest:
        case PlanningDataActionTypes.PlanningDataTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case PlanningDataActionTypes.PlanningDataTemplateFileError:
        case PlanningDataActionTypes.PlanningDataDownloadOutputDataSuccess:
        case PlanningDataActionTypes.PlanningDataDownloadOutputDataError:
        case PlanningDataActionTypes.PlanningDataTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case PlanningDataActionTypes.PlanningDataValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload.isReadyForUpload,
                recordsForDeletion: action.payload.recordsForDeletion,
            };
        }
        case PlanningDataActionTypes.PlanningDataAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'Planning';
            updateLog.startDate = moment();
            return {
                ...state,
                uploadLogData: [updateLog, ...state.uploadLogData],
            };
        }
        default:
            return state;
    }
}
