import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { take, takeWhile } from 'rxjs/operators';
import { ManualUploadSetActivatedRoute } from 'src/app/store/manual-upload/actions';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Subscription } from 'rxjs';
import { IMDBUploadLogRequest, IMDBDownloadOutputDataRequest } from 'src/app/store/automatic-upload/imdb/actions';

@Component({
    selector: 'app-imdb',
    templateUrl: './imdb.component.html',
    styleUrls: ['./imdb.component.scss'],
})
export class IMDBComponent extends BaseComponent {
    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];

    uploadLogData$ = this.store.select((store) => store.imdbState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.imdbState.isUploadLogLoading);
    isLoading$ = this.store.select((store) => store.imdbState.isLoading);
    isImportInProgress$ = this.store.select((store) => store.imdbState.isImportInProgress);
    showOrder = true;
    interval$ = this.store.select((store) => store.manualUploadState.interval);
    intervalSub: Subscription = null;

    constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new ManualUploadSetActivatedRoute(urlSegments[0].path));
        });

        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new IMDBUploadLogRequest());
            });
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

        this.isImportInProgress$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isImportInProgress) => (this.showOrder = isImportInProgress ? false : this.showOrder));
    }

    downloadData(filePath: string) {
        this.store.dispatch(new IMDBDownloadOutputDataRequest(filePath));
    }
}
