import { Injectable } from '@angular/core';
import { ValidationDocument, ValidationDocumentHeader, ValidationDocumentResult } from 'src/app/store/validation-form/model';
import { ValidStatuses } from 'src/app/models/validation-statuses';
import { AssignProjectTeamRequest } from 'src/app/models/assign-project-team-request';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { AddSubsystemsForSignOffRequest } from 'src/app/models/add-subsystems-for-sing-off-request';

@Injectable({
    providedIn: 'root',
})
export class ValidationFormService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly validationEndpoint: string = '/validation';

    constructor(private http: HttpClient) {}

    getValidationDocument(id: number) {
        return this.http.get<ValidationDocumentResult>(`${this.rootUrl}${this.validationEndpoint}/${id}`);
    }

    getValidationDocumentHeader(id: number) {
        return this.http.get<ValidationDocumentHeader>(`${this.rootUrl}${this.validationEndpoint}/header/${id}`);
    }

    updateValidationDocumentStatus(id: number, newStatus: ValidStatuses, type: string) {
        return this.http.patch<ValidationDocumentResult>(`${this.rootUrl}${this.validationEndpoint}/${id}`, {
            Status: newStatus,
            type:type,
        });
    }

    updateValidationDocument(validationResult: ValidationDocumentResult, updatedProperties: string[]) {
        let validation = validationResult.validation;
        let validationICT = validationResult.validationICT;
        let validationSMP = validationResult.validationSMP;
        return this.http.put<ValidationDocumentResult>(`${this.rootUrl}${this.validationEndpoint}`, {
            validation,
            validationICT,
            validationSMP,
            updatedProperties,
        });
    }

    createValidationDocument(id: number) {
        return this.http.post<number>(`${this.rootUrl}${this.validationEndpoint}/${id}`, {});
    }

    reviewChanges(id: number) {
        return this.http.post<ValidationDocumentHeader>(
            `${this.rootUrl}${this.validationEndpoint}/reviewchanges/${id}`,
            {}
        );
    }

    changeIsActive(id: number) {
        return this.http.post<ValidationDocumentHeader>(
            `${this.rootUrl}${this.validationEndpoint}/changeIsActive/${id}`,
            {}
        );
    }

    assignProjectTeam(form: AssignProjectTeamRequest) {
        return this.http.post<ValidationDocumentHeader>(
            `${this.rootUrl}${this.validationEndpoint}/assignProjectTeam`,
            form
        );
    }

    updateIsActiveForValidationDiscipline(id: number,discipline: string){
        return this.http.post<ValidationDocumentResult>(
            `${this.rootUrl}${this.validationEndpoint}/updateIsActiveForValidationDiscipline/${id}/${discipline}`,
            {}
        );
    }

    updateSystemizationRemark(id: number, remark: string){
        return this.http.post<ValidationDocumentResult>(
            `${this.rootUrl}${this.validationEndpoint}/systemizationremark/${id}`,
            {text: remark}
        );
    }

    updateSystemizationCompleted(id: number){
        return this.http.post<ValidationDocumentHeader>(
            `${this.rootUrl}${this.validationEndpoint}/systemizationiscompleted/${id}`,
            {}
            
        );
    }

    signOff(form: AddSubsystemsForSignOffRequest) {
        return this.http.post<ValidationDocumentHeader>(
            `${this.rootUrl}${this.validationEndpoint}/addtestpackcompletedsubsystemforsignOff`,
            form
        );
    }
}
