<main class="main">
    <div class="header-container">
        <div class="header-title">
            PAS CR Status              
        </div>
        <div class="header-buttons">
            <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20">
                export to excel
            </button>
        </div>
    </div>    
    <div class="results-container">
        <div class="results-panel-header-container">
            <div class="results-panel-header-title-row">
                <!-- <div class="results-header-actions">
                    <button mat-button [matMenuTriggerFor]="columnsCustomizer" class="results-columns-customizer">
                        <mat-icon svgIcon="edit"></mat-icon>
                        customize columns
                    </button>
                    <mat-menu #columnsCustomizer class="columns-customizer">
                        <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                        <div class="columns-customizer-list">
                            <mat-checkbox [checked]="showHideColumns.tagType" (change)="reverseValue('tagType')"
                                (click)="$event.stopPropagation()">Tag Type
                            </mat-checkbox>
                        </div>
                    </mat-menu>
                </div> -->
            </div>
        </div>
        <div class="table-wrapper">
            <div class="scroll" app-double-scroll>
                <table mat-table matSort [dataSource]="data" [matSortActive]="sortBy" matSortDisableClear
                    [matSortDirection]="direction">

                    <ng-container matColumnDef="pascrNumber">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                            (click)="openHeaderCheckListFilter('pascrNumber', getPascrNumber, 'columnPASCRNumber', 'PAS CR Number', columnPASCRNumber)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                    'header-underline': showUnderlineForCheckListColumn(columnPASCRNumber)
                                }">PAS CR Number
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'pascrNumber'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.pascrNumber }}</td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                            (click)="openHeaderCheckListFilter('description', getDescription, 'columnDescription', 'Description', columnDescription)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnDescription)
                                    }">Description
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'description'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
                    </ng-container>

                    <ng-container matColumnDef="subSystem">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer" (click)="openHeaderCheckListFilter(
                            'subSystem',
                            getSubSystem,
                            'columnSubSystem',
                            'SubSystem',
                            columnSubSystem
                            )"
                            >
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnSubSystem)
                                    }">SubSystem
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'subSystem'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.subSystem }}</td>
                    </ng-container>

                    <ng-container matColumnDef="tagType">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer" [ngClass]="{
                                hidecells: !showHideColumns.tagType
                            }">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnTagType)
                                    }">Tag Type</span>
                                <div class="sort-icon" *ngIf="sortBy === 'tagType'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{
                                hidecells: !showHideColumns.tagType
                            }">
                            {{ element.tagType }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="approvalStage">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                        (click)="openHeaderCheckListFilter('approvalStage', getApprovalStage, 'columnApprovalStage', 'Approval Stage', columnApprovalStage)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnApprovalStage)
                                    }">Approval Stage
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'approvalStage'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.approvalStage }}</td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                        (click)="openHeaderCheckListFilter('status', getStatus, 'columnStatus', 'Status', columnStatus)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnStatus)
                                    }">Status
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'status'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                    </ng-container>

                    <ng-container matColumnDef="crChangeType">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                        (click)="openHeaderCheckListFilter('crChangeType', getCRChangeType, 'columnCRChangeType', 'Change Type', columnCRChangeType)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnCRChangeType)
                                    }">Change Type
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'crChangeType'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.crChangeType }}</td>
                    </ng-container>

                    <ng-container matColumnDef="originatorDate">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                        (click)="openHeaderDateFilter('originatorDate', 'columnOriginatorDate', 'Originator Date', columnOriginatorDate)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnOriginatorDate)
                                    }">Originator Date</span>
                                <div class="sort-icon" *ngIf="sortBy === 'originatorDate'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.originatorDate | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="targetCompletionDate">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                        (click)="openHeaderDateFilter('targetCompletionDate', 'columnTargetCompletionDate', 'Target Completion Date', columnTargetCompletionDate)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnTargetCompletionDate)
                                    }">Target Completion Date</span>
                                <div class="sort-icon" *ngIf="sortBy === 'targetCompletionDate'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.targetCompletionDate | date: 'd/MMM/yy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="rfi_RIMSRef">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                        (click)="openHeaderCheckListFilter('rfi_RIMSRef', getRFI_RMSRef, 'columnRFI_RIMSRef', 'RFI/RIMSRef', columnRFI_RIMSRef)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnRFI_RIMSRef)
                                    }">RFI/RIM Ref
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'rfi_RIMSRef'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.rfI_RIMSRef }}</td>
                    </ng-container>

                    <ng-container matColumnDef="mocRef">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                        (click)="openHeaderCheckListFilter('mocRef', getMOCRef, 'columnMOCRef', 'MOC Ref', columnMOCRef)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnMOCRef)
                                    }">MOC Ref
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'mocRef'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.mocRef }}</td>
                    </ng-container> <ng-container matColumnDef="tagNo">
                        <th mat-header-cell disableClear *matHeaderCellDef class="hover-pointer"
                        (click)="openHeaderCheckListFilter('tagNo', gettagNo, 'columnTagNo', 'Tag No', columnTagNo)">
                            <div class="sort-container center">
                                <span [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnTagNo)
                                    }">Tag No
                                </span>
                                <div class="sort-icon" *ngIf="sortBy === 'tagNo'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.tagNo }}</td>
                    </ng-container>

                    <ng-container matColumnDef="comment" stickyEnd>
                        <th mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                            <div class="sort-container">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnComments)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'comment',
                                            getComments,
                                            'columnComments',
                                            'Comment',
                                            columnComments,
                                            false,
                                            true,
                                            true
                                        )
                                    "
                                    >Comment</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'comment'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" >
                            <div class="comment-description" (click)="openCommentPopup(element, $event)">
                                <div *ngIf="element.comment && !isReadOnly; else notShow" class="cursor-pointer">
                                    <a><span [innerHTML]="element.comment | formatMentions: element.mentions"></span></a>
                                </div>
                                <div *ngIf="isReadOnly">
                                    <span [innerHTML]="element.comment | formatMentions: element.mentions"></span>
                                </div>
                                <ng-template #notShow>
                                    <button type="button" *ngIf="!isReadOnly" mat-stroked-button class="add-comment">
                                        Add
                                    </button>
                                </ng-template>
                            </div>          
                            <div
                                [ngClass]="{ notactive: !element.comment, active: element.comment }"
                                class="comment-hist"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    (click)="openHistoryPopup(element)"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                    />
                                </svg>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
        <div class="paginator page-counter">
            <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50]"
                (page)="onPaginatorChange($event)" showFirstLastButtons>
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>