import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import * as ImportsActions from './actions';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import * as ImportLogsActions from './actions';

@Injectable()
export class ImportLogsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService
    ) {}

    importLogsReceived$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImportsActions.importStatusReceived),
            withLatestFrom(this.store.select((store) => store.importLogsState.importLogsNotifications)),
            mergeMap(([action, notifications]) => {
                let newNotifications = [];
                let toastId: number;
                if (action.importLogs?.length > 0) {
                    newNotifications = action.importLogs
                        .filter(x => !notifications.some(y => y.id === x.id));

                    if (newNotifications.length > 0) {
                        toastId = this.toastService.InfoClick(
                            "Import is in progress it may affect your live data. </br>Current import(s): " +
                            newNotifications.map(x => x.type).join(", ")
                        );
                    }
                }
                return of(ImportLogsActions.importStatusDisplayed({ notifications: newNotifications, toastId }));
            })
        )
    );

    closeImportStatusToasts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImportsActions.closeImportStatusToasts),
            withLatestFrom(this.store.select((store) => store.importLogsState.importLogsNotifications)),
            map(([action, notifications]) => {
                const toastIds = this.toastService.GetAllToasts().map(x => x.toastId);
                notifications.forEach(notification => {
                    if (toastIds.some(x => x === notification.toastId && notification.status === 'Validated')
                        && !notifications.some(y => y.toastId === notification.toastId && y.status === 'Validating')) {
                        this.toastService.Close(notification.toastId);
                    }
                });
            })
        ),
        { dispatch: false }
    );
}
