<div class="results-panel results-container">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="tdbSubsystem">
            <th mat-header-cell *matHeaderCellDef>
                <div class="sort-container">
                    <span class="hover-pointer" (click)="changeOrderDirection()">TDB Subsystem</span>
                    <div class="sort-icon">
                        <mat-icon *ngIf="orderBy == 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="orderBy == 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
            <td
                class="text-align-left"
                mat-cell
                *matCellDef="let row"
                [ngClass]="getTdbFlagColorClassName(row.tdbMcDate)"
            >
                {{ row.tdbSubsystem }}
            </td>
        </ng-container>
        <ng-container matColumnDef="tdbMcDate">
            <th mat-header-cell *matHeaderCellDef>MC Date</th>
            <td mat-cell *matCellDef="let row">{{ row.tdbMcDate | date: 'dd/MMM/yy' }}</td>
        </ng-container>
        <ng-container matColumnDef="tdbCircuit">
            <th mat-header-cell *matHeaderCellDef>Circuit</th>
            <td mat-cell *matCellDef="let row">{{ row.tdbCircuit }}</td>
        </ng-container>
        <ng-container matColumnDef="tdbHeatTracingTape">
            <th mat-header-cell *matHeaderCellDef>Heat Tracing Tape</th>
            <td mat-cell *matCellDef="let row">{{ row.tdbHeatTracingTape }}</td>
        </ng-container>
        <ng-container matColumnDef="processSubsystem">
            <th mat-header-cell *matHeaderCellDef>Process Subsystem</th>
            <td
                class="text-align-left"
                mat-cell
                *matCellDef="let row"
                [ngClass]="getTdbFlagColorClassName(row.processMcDate)"
            >
                {{ row.processSubsystem }}
            </td>
        </ng-container>
        <ng-container matColumnDef="processMcDate">
            <th mat-header-cell *matHeaderCellDef>MC Date</th>
            <td mat-cell *matCellDef="let row">{{ row.processMcDate | date: 'dd/MMM/yy' }}</td>
        </ng-container>
        <ng-container matColumnDef="attachments">
            <th mat-header-cell disableClear *matHeaderCellDef class="center">3D Model</th>
            <td mat-cell *matCellDef="let row" class="attachments">
                <div class="uploaded-attachment-buttons">
                    <button
                        *ngIf="row.attachments.length == 1"
                        [title]="row.attachments[0].name"
                        type="button"
                        class="download-uploaded-attachment"
                        (click)="download(row.attachments[0])"
                    ></button>
                    <button
                        *ngIf="row.attachments.length > 1"
                        title="multiple attachments"
                        type="button"
                        class="multiple-attachments"
                        (click)="openAttachmentsPopup(row)"
                    ></button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>