<app-reports-skyline-chart
    [weeks]="weeks$ | async"
    [subsystemsByWeek]="subsystemsByWeek$ | async"
    [rfosByWeek]="rfosByWeek$ | async"
    [openAqvdsPerWeek]="openAqvdsPerWeek$ | async"
    [openBitrsPerWeek]="openBitrsPerWeek$ | async"
    [openCitrsPerWeek]="openCitrsPerWeek$ | async"
    [isLoading]="isLoading$ | async"
    [pastWeeksCount]="pastWeeksCount$ | async"
    [skylinePhase]="skylinePhase"
    [skylineType]="skylineType"
    [levelOfDetails]="levelOfDetails$ | async"
>
</app-reports-skyline-chart>
