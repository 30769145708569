import { LeakTestPackPriorityActions, LeakTestPackPriorityHistoryActionTypes } from "./actions";
import { LeakTestPackPriorityHistoryState } from "./model";



const initialState: LeakTestPackPriorityHistoryState = {
    data: [],
    isLoading: false,
};

export function reducer(state: LeakTestPackPriorityHistoryState = initialState, action: LeakTestPackPriorityActions): LeakTestPackPriorityHistoryState {
    switch (action.type) {
        case LeakTestPackPriorityHistoryActionTypes.LeakTestPackPriorityHistoryRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case LeakTestPackPriorityHistoryActionTypes.LeakTestPackPriorityHistorySuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        }
        case LeakTestPackPriorityHistoryActionTypes.LeakTestPackPriorityHistoryError: {
            return {
                ...state,
                data: [],
                isLoading: false
            };
        }
        default: {
            return state;
        }

    }
}