import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, takeWhile } from 'rxjs/operators';
import { FormService } from 'src/app/services/shared/form.service';
import { ApplicationState } from 'src/app/store/model';
import { Schedule } from 'src/app/store/reports/pre-mc/model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { ScheduleSaveRequest } from 'src/app/store/reports/milestone-dashboard/actions';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
    selector: 'app-mc-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: NativeDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    ],
})
export class MCScheduleComponent extends BaseComponent implements OnInit {
    scheduleForm: UntypedFormGroup;
    events = [];

    isLoading$ = this.store.select((state) => state.milestoneDashboardState.mcMilestoneSchedule.isLoading);
    schedule$ = this.store
        .select((state) => state.milestoneDashboardState.mcMilestoneSchedule.entity)
        .pipe(filter((schedule) => !!schedule));

    constructor(private store: Store<ApplicationState>, private formService: FormService) {
        super();
        this.scheduleForm = this.formService.createSimpleForm(new Schedule());
    }

    ngOnInit() {
        this.schedule$.subscribe((schedule) => {
            this.scheduleForm.patchValue({
                earlyWorksComplete: schedule.earlyWorksComplete ? new Date(schedule.earlyWorksComplete) : null,
                earlyWalkdownsComplete: schedule.earlyWalkdownsComplete
                    ? new Date(schedule.earlyWalkdownsComplete)
                    : null,
                firstRedZone: schedule.firstRedZone ? new Date(schedule.firstRedZone) : null,
                firstMCWalkdown: schedule.firstMCWalkdown ? new Date(schedule.firstMCWalkdown) : null,
                firstSubsystemMC: schedule.firstSubsystemMC ? new Date(schedule.firstSubsystemMC) : null,
                goocMilestone: schedule.goocMilestone ? new Date(schedule.goocMilestone) : null,
                earlyWorksCompleteTitle: schedule.earlyWorksCompleteTitle,
                earlyWalkdownsCompleteTitle: schedule.earlyWalkdownsCompleteTitle,
                firstRedZoneTitle: schedule.firstRedZoneTitle,
                firstMCWalkdownTitle: schedule.firstMCWalkdownTitle,
                firstSubsystemMCTitle: schedule.firstSubsystemMCTitle
            });
            this.refreshTimeline(this.scheduleForm.value);
        });

        this.scheduleForm.valueChanges.pipe(
            takeWhile(() => this.isAlive),
            distinctUntilChanged((prev: Schedule, curr: Schedule) => prev.firstRedZone === curr.firstRedZone
                                                        && prev.earlyWalkdownsComplete === curr.earlyWalkdownsComplete
                                                        && prev.earlyWorksComplete === curr.earlyWorksComplete
                                                        && prev.firstMCWalkdown === curr.firstMCWalkdown
                                                        && prev.firstSubsystemMC === curr.firstSubsystemMC
                                                        && prev.goocMilestone === curr.goocMilestone)
        ).subscribe((schedule) => schedule && this.refreshTimeline(schedule));
    }

    save() {
        this.store.dispatch(new ScheduleSaveRequest(this.scheduleForm.value));
    }

    private refreshTimeline(schedule: Schedule) {
        this.events = [
            {
                title: schedule.earlyWorksCompleteTitle,
                date: schedule.earlyWorksComplete,
                control: this.scheduleForm.controls.earlyWorksComplete,
                labelControl: this.scheduleForm.controls.earlyWorksCompleteTitle,
            },
            {
                title: schedule.earlyWalkdownsCompleteTitle,
                date: schedule.earlyWalkdownsComplete,
                control: this.scheduleForm.controls.earlyWalkdownsComplete,
                labelControl: this.scheduleForm.controls.earlyWalkdownsCompleteTitle,
            },
            {
                title: schedule.firstRedZoneTitle,
                date: schedule.firstRedZone,
                control: this.scheduleForm.controls.firstRedZone,
                labelControl: this.scheduleForm.controls.firstRedZoneTitle,
            },
            {
                title: schedule.firstMCWalkdownTitle,
                date: schedule.firstMCWalkdown,
                control: this.scheduleForm.controls.firstMCWalkdown,
                labelControl: this.scheduleForm.controls.firstMCWalkdownTitle,
            },
            {
                title: schedule.firstSubsystemMCTitle,
                date: schedule.firstSubsystemMC,
                control: this.scheduleForm.controls.firstSubsystemMC,
                labelControl: this.scheduleForm.controls.firstSubsystemMCTitle,
            },
        ];
        this.events = this.events.sort(this.sortNullsFirst);
    }

    private sortNullsFirst(a, b) {
        if (a.date === b.date) {
            return 0;
        } else if (a.date === null) {
            return -1;
        } else if (b.date === null) {
            return 1;
        } else {
            return a.date < b.date ? -1 : 1;
        }
    }
}
