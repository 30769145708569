import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { takeWhile, filter, map, take } from 'rxjs/operators';
import {
    ChangeValidationDashboardWeeklyValidationProgressRequest,
    ChangeValidationDashboardWeeklyValidationProgressSetWeekRange,
    ChangeValidationDashboardWeeklyChangeRaisedSetWeekRange,
    ChangeValidationDashboardWeeklyCumulativeValidationProgressSetWeekRange,
} from 'src/app/store/reports/change-validation-dashboard/actions';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { getNextWeekStartDate, getWeekEndDate } from 'src/app/extensions';
import * as _ from 'lodash';
import { CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS } from 'src/app/store/reports/change-validation-dashboard/model';

@Component({
    selector: 'app-weekly-validation-progress',
    templateUrl: './weekly-validation-progress.component.html',
    styleUrls: ['./weekly-validation-progress.component.scss'],
})
export class WeeklyValidationProgressComponent extends BaseComponent implements OnInit {
    isLoading$ = this.store.select((state) => state.changeValidationDashboardState.weeklyValidationProgress.isLoading);
    dateRangePickerControl: UntypedFormControl;
    dateRangePickerControlFormatted: UntypedFormControl;
    startDate$ = this.store.select(
        (store) => store.changeValidationDashboardState.weeklyValidationProgress.rangeDateFilter.startDate
    );

    @ViewChild('dateRangePicker') dateRangePicker: MatDatepicker<moment.Moment>;

    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = false;
    showXAxisLabel: boolean = false;
    showYAxisLabel: boolean = false;
    colorScheme = {
        domain: ['#32CD32', '#FF963E'],
    };
    rangeDateFilter = getNextWeekStartDate().add(
        -CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS,
        'weeks'
    );

    data$ = this.store.select((state) => state.changeValidationDashboardState.weeklyValidationProgress.data);

    chartData$ = this.store.pipe(
        filter(
            (store) =>
                store.changeValidationDashboardState.weeklyValidationProgress.weeks.length > 0 &&
                store.changeValidationDashboardState.weeklyValidationProgress.data &&
                store.changeValidationDashboardState.weeklyValidationProgress.data.length > 0
        ),
        map((store) => {
            return _.zipWith(
                store.changeValidationDashboardState.weeklyValidationProgress.weeks,
                store.changeValidationDashboardState.weeklyValidationProgress.data,
                (week, total) => {
                    return {
                        name: getWeekEndDate(moment(week.date)).format('DD MMM YYYY'),
                        series: [
                            {
                                name: 'Validation Completed',
                                value: total.greenCount,
                            },
                            {
                                name: 'In Progress',
                                value: total.yellowCount,
                            },
                        ],
                    };
                }
            );
        })
    );

    constructor(private store: Store<ApplicationState>) {
        super();
        this.dateRangePickerControl = new UntypedFormControl();
        this.dateRangePickerControlFormatted = new UntypedFormControl({ value: '', disabled: true });
    }

    ngOnInit() {
        this.data$.pipe(take(1)).subscribe((data) => {
            if (data.length === 0) {
                this.store.dispatch(new ChangeValidationDashboardWeeklyValidationProgressRequest());
            }
        });

        this.startDate$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((startDate) => !!startDate)
            )
            .subscribe((startDate) => {
                this.dateRangePickerControl.setValue(moment(startDate).toDate(), { emitEvent: false });
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(moment(startDate)));
            });

        this.dateRangePickerControl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((date: moment.Moment) => {
                //console.log('date range: ', date)
                this.store.dispatch(new ChangeValidationDashboardWeeklyValidationProgressSetWeekRange(date));
                this.store.dispatch(new ChangeValidationDashboardWeeklyChangeRaisedSetWeekRange(date));
                this.store.dispatch(new ChangeValidationDashboardWeeklyCumulativeValidationProgressSetWeekRange(date));
                this.dateRangePickerControlFormatted.setValue(this.formatDateRange(date));
            });
    }

    dateRangePickerFilter = (d: moment.Moment | null): boolean => {
        const day = (moment(d) || moment()).isoWeekday();
        return day === 6;
    };

    private formatDateRange(startDate: moment.Moment) {
        const endDate = moment(startDate).add(
            CHANGE_VALIDATION_DASHBOARD_WEEKLY_VALIDATION_PROGRESS_NUM_OF_PAST_WEEKS,
            'weeks'
        );
        return `${moment(startDate).format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
    }

    axisFormat(val: number) {
        if (val % 1 === 0) {
            return val.toLocaleString();
        } else {
            return '';
        }
    }
}
