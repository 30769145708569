import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    ChangeDocumentDetailsAddCommentRequest,
    ChangeDocumentDetailsAddCommentSuccess,
    ChangeDocumentDetailsAddCommentError,
    ChangeDocumentDetailsActionTypes,
    GetChangeDocumentDetailsRequest,
    GetChangeDocumentDetailsSuccess,
    GetChangeDocumentDetailsError,
    ChangeDocumentDetailsDeleteCommentRequest,
    ChangeDocumentDetailsDeleteCommentSuccess,
    ChangeDocumentDetailsDeleteCommentError,
    ChangeDocumentDetailsGetIwpRequest,
    ChangeDocumentDetailsGetIwpSuccess,
    ChangeDocumentDetailsGetIwpError,
    ChangeDocumentDetailsUpdateIwpRequest,
    ChangeDocumentDetailsUpdateIwpSuccess,
    ChangeDocumentDetailsUpdateIwpError,
    DownloadCallOffAttachmentRequest,
    DownloadCallOffAttachmentRequestSuccess,
    DownloadCallOffAttachmentRequestError,
} from './actions';
import { map, mergeMap, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/services/shared/toast.service';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { ChangeManagementService } from 'src/app/services/api/webapi-services/change-management.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { IWPLink } from './model';

@Injectable()
export class ChangeDocumentDetailsEffects {
    constructor(
        private actions$: Actions,
        private toastService: ToastService,
        private changeManagementService: ChangeManagementService,
        private commentService: CommentService,
        private store: Store<ApplicationState>
    ) {}

    @Effect()
    getChangeDocumentDetails$ = this.actions$.pipe(
        ofType<GetChangeDocumentDetailsRequest>(ChangeDocumentDetailsActionTypes.GetChangeDocumentDetailsRequest),
        mergeMap(({ payload }) => {
            return this.changeManagementService.getChangeDocumentDetailsData(payload.id).pipe(
                map((item) => {
                    if(item.subsystemImpactedNA){
                        item.impactedSubsystems=
                            'N/A' + (item.impactedSubsystems != null && item.impactedSubsystems != ''
                                ? ', ' + item.impactedSubsystems
                                : '');
                    }
                    return new GetChangeDocumentDetailsSuccess(item);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering detailed status page. Please contact Program Administrator.'
                    );
                    return of(new GetChangeDocumentDetailsError(error));
                })
            );
        })
    );

    @Effect()
    getIWPs$ = this.actions$.pipe(
        ofType<ChangeDocumentDetailsGetIwpRequest>(ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsGetIwpRequest),
        mergeMap(({ payload }) => {
            return this.changeManagementService.getIWP(payload.id).pipe(
                map((data: IWPLink) => {
                    return new ChangeDocumentDetailsGetIwpSuccess(data);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering detailed status page. Please contact Program Administrator.'
                    );
                    return of(new ChangeDocumentDetailsGetIwpError(error));
                })
            );
        })
    );

    @Effect()
    updateIWPs$ = this.actions$.pipe(
        ofType<ChangeDocumentDetailsUpdateIwpRequest>(
            ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsUpdateIwpRequest
        ),
        withLatestFrom(this.store.select((store) => store.changeManagementState.dataPagination.items)),
        mergeMap(([{ payload }, items]) => {
            return this.changeManagementService.updateIWPs(payload).pipe(
                map((data) => {
                    var detail = items.find((s) => s.id === payload.changeDocId);

                    if (detail) {
                        detail.linkedIWPs = payload.changeDocIwps.map((x) => x.iwpNumber).join(', ');
                    }

                    this.toastService.Success('IWPs successfully added.');
                    return new ChangeDocumentDetailsUpdateIwpSuccess();
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering detailed status page. Please contact Program Administrator.'
                    );
                    return of(new ChangeDocumentDetailsUpdateIwpError(error));
                })
            );
        })
    );

    @Effect()
    addDetailComment$ = this.actions$.pipe(
        ofType<ChangeDocumentDetailsAddCommentRequest>(
            ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsAddCommentRequest
        ),
        withLatestFrom(this.store.select((store) => store.changeManagementState.dataPagination.items)),
        mergeMap(([{ payload }, items]) =>
            this.commentService.addCommentChangeDocument(payload.entityId, payload.description, payload.mentions).pipe(
                map((item) => {
                    var detail = items.find((s) => s.id.toString() === payload.entityId);
                    if (detail) {
                        detail.comment = item.description;
                        detail.mentions = payload.mentions;
                    }
                    this.toastService.Success('Comment successfully added.');
                    return new ChangeDocumentDetailsAddCommentSuccess(item);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding comment. Please contact Program Administrator.'
                    );
                    return of(new ChangeDocumentDetailsAddCommentError(error));
                })
            )
        )
    );

    @Effect()
    deleteComment$ = this.actions$.pipe(
        ofType<ChangeDocumentDetailsDeleteCommentRequest>(
            ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsDeleteCommentRequest
        ),
        withLatestFrom(
            this.store.select((store) => store.changeManagementState.dataPagination.items),
            this.store.select((store) => store.changeDocumentDetailsState)
        ),
        mergeMap(([{ payload }, items, detail]) =>
            this.commentService.deleteCommentChangeDocument(payload.id).pipe(
                map(() => {
                    var isLatestComment = detail.comments[detail.comments.length - 1].id === payload.id;
                    if (isLatestComment) {
                        var item = items.find((s) => s.id === detail.item.id);
                        var newLatestComment = detail.comments[detail.comments.length - 2];
                        if (item) {
                            item.comment = newLatestComment ? newLatestComment.description : '';
                        }
                    }
                    this.toastService.Success('Comment deleted successfully.');
                    return new ChangeDocumentDetailsDeleteCommentSuccess({ id: payload.id });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while deleting comment. Please contact Program Administrator.'
                    );
                    return of(new ChangeDocumentDetailsDeleteCommentError(error));
                })
            )
        )
    );

    @Effect()
    refreshDetailsAfterIWPsLinked$ = this.actions$.pipe(
        ofType(ChangeDocumentDetailsActionTypes.ChangeDocumentDetailsUpdateIwpSuccess),
        withLatestFrom(this.store.select((state) => state.changeDocumentDetailsState.item.id)),
        filter(([, id]) => !!id),
        map(([, id]) => new GetChangeDocumentDetailsRequest({ id: id.toString() }))
    );

    @Effect()
    downloadCallOffAttachmentRequest = this.actions$.pipe(
        ofType(ChangeDocumentDetailsActionTypes.DownloadCallOffAttachmentRequest),
        mergeMap((action: DownloadCallOffAttachmentRequest) =>
            this.changeManagementService.downloadCallOffAttachmentRequest(action.payload).pipe(
                map(
                    (attachmentBinaries) =>
                        new DownloadCallOffAttachmentRequestSuccess({
                            content: attachmentBinaries,
                            fileName: action.payload.attachment.name,
                        })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while downloading attachment ${action.payload.attachment.name}. Please contact Program Administrator.`
                    );
                    return of(new DownloadCallOffAttachmentRequestError(action.payload.attachment));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    downloadCallOffAttachmentRequestSuccess = this.actions$.pipe(
        ofType<DownloadCallOffAttachmentRequestSuccess>(
            ChangeDocumentDetailsActionTypes.DownloadCallOffAttachmentRequestSuccess
        ),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });

            saveAs(blob, action.payload.fileName);
        })
    );
}
