import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';
import { ITRManhoursDeletePagination } from './model';

export enum ITRManhoursActionTypes {
    ITRManhoursUploadLogRequest = '[ITR Manhours] Upload Log Request',
    ITRManhoursUploadLogSuccess = '[ITR Manhours] Upload Log Success',
    ITRManhoursUploadLogError = '[ITR Manhours] Upload Log Error',
    ITRManhoursDeleteRecordsRequest = '[ITR Manhours] Delete Records Request',
    ITRManhoursDeleteRecordsSuccess = '[ITR Manhours] Delete Records Success',
    ITRManhoursDeleteRecordsError = '[ITR Manhours] Delete Records Error',
    ITRManhoursSetStatusAndStartDate = '[ITR Manhours] Set Status and Start Date',
    ITRManhoursDeleteFilterPropertyUpdate = '[ITR Manhours] Delete Filter Property Update',
    ITRManhoursDeleteFilterReset = '[ITR Manhours] Delete Filter Reset',
    ITRManhoursPatchDeleteRecordRequest = '[ITR Manhours] Patch Delete Record Request',
    ITRManhoursPatchDeleteRecordSuccess = '[ITR Manhours] Patch Delete Record Success',
    ITRManhoursPatchDeleteRecordError = '[ITR Manhours] Patch Delete Record Error',
    ITRManhoursPatchAllDeleteRecordsRequest = '[ITR Manhours] Patch All Delete Records Request',
    ITRManhoursPatchAllDeleteRecordsSuccess = '[ITR Manhours] Patch All Delete Records Success',
    ITRManhoursPatchAllDeleteRecordsError = '[ITR Manhours] Patch All Delete Records Error',
    ITRManhoursValidateDataRequest = '[ITR Manhours] Validate Data Request',
    ITRManhoursValidateDataSuccess = '[ITR Manhours] Validate Data Success',
    ITRManhoursValidateDataError = '[ITR Manhours] Validate Data Error',
    ITRManhoursFileUploaded = '[ITR Manhours] File Uploaded',
    ITRManhoursClearInProgressSpinner = '[ITR Manhours] Clear In Progress Spinner',
    ITRManhoursTemplateFileRequest = '[ITR Manhours] Template File Request',
    ITRManhoursTemplateFileSuccess = '[ITR Manhours] Template File Success',
    ITRManhoursTemplateFileError = '[ITR Manhours] Template File Error',
    ITRManhoursDownloadOutputDataRequest = '[ITR Manhours] Download Output Data Request',
    ITRManhoursDownloadOutputDataSuccess = '[ITR Manhours] Download Output Data Success',
    ITRManhoursDownloadOutputDataError = '[ITR Manhours] Download Output Data Error',
    ITRManhoursValidateButtonStateRequest = '[ITR Manhours] Validate Button State Request',
    ITRManhoursValidateButtonStateSuccess = '[ITR Manhours] Validate Button State Success',
    ITRManhoursValidateButtonStateError = '[ITR Manhours] Validate Button State Error',
    ITRManhoursAddStartedLog = '[ITR Manhours] Add Started Log',
}

export class ITRManhoursAddStartedLog implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursAddStartedLog;
}

export class ITRManhoursUploadLogRequest implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursUploadLogRequest;
}

export class ITRManhoursUploadLogSuccess implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class ITRManhoursUploadLogError implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursUploadLogError;

    constructor(public payload: string) {}
}

export class ITRManhoursDeleteRequest implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursDeleteRecordsRequest;
}

export class ITRManhoursDeleteSuccess implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursDeleteRecordsSuccess;

    constructor(public payload: ITRManhoursDeletePagination) {}
}

export class ITRManhoursDeleteError implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ITRManhoursSetStatusAndStartDate implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class ITRManhoursDeleteFilterPropertyUpdate implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursDeleteFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class ITRManhoursDeleteFilterReset implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursDeleteFilterReset;
}

export class ITRManhoursPatchDeleteRequest implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursPatchDeleteRecordRequest;

    constructor(public payload: { qvdNumber: string; deleteState: boolean }) {}
}

export class ITRManhoursPatchAllDeleteRequest implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursPatchAllDeleteRecordsRequest;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ITRManhoursPatchDeleteSuccess implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursPatchDeleteRecordSuccess;
}

export class ITRManhoursPatchAllDeleteSuccess implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursPatchAllDeleteRecordsSuccess;

    constructor(public payload: { deleteState: boolean }) {}
}

export class ITRManhoursPatchDeleteError implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursPatchDeleteRecordError;

    constructor(public payload: string) {}
}

export class ITRManhoursPatchAllDeleteError implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursPatchAllDeleteRecordsError;

    constructor(public payload: string) {}
}

export class ITRManhoursValidateDeleteRequest implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursValidateDataRequest;
}

export class ITRManhoursValidateDeleteSuccess implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursValidateDataSuccess;
}

export class ITRManhoursValidateDeleteError implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursValidateDataError;

    constructor(public payload: string) {}
}

export class ITRManhoursFileUploaded implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursFileUploaded;
}

export class ITRManhoursClearInProgressSpinner implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursClearInProgressSpinner;
}

export class ITRManhoursTemplateFileRequest implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursTemplateFileRequest;

    constructor(public payload: string) {}
}

export class ITRManhoursTemplateFileSuccess implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class ITRManhoursTemplateFileError implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursTemplateFileError;

    constructor(public payload: string) {}
}

export class ITRManhoursDownloadOutputDataRequest implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class ITRManhoursDownloadOutputDataSuccess implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class ITRManhoursDownloadOutputDataError implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class ITRManhoursValidateButtonStateRequest implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursValidateButtonStateRequest;
}

export class ITRManhoursValidateButtonStateSuccess implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class ITRManhoursValidateButtonStateError implements Action {
    readonly type = ITRManhoursActionTypes.ITRManhoursValidateButtonStateError;

    constructor(public payload: string) {}
}

export type ITRManhoursActions =
    | ITRManhoursUploadLogRequest
    | ITRManhoursUploadLogSuccess
    | ITRManhoursUploadLogError
    | ITRManhoursDeleteRequest
    | ITRManhoursDeleteSuccess
    | ITRManhoursDeleteError
    | ITRManhoursSetStatusAndStartDate
    | ITRManhoursDeleteFilterPropertyUpdate
    | ITRManhoursDeleteFilterReset
    | ITRManhoursPatchDeleteRequest
    | ITRManhoursPatchDeleteSuccess
    | ITRManhoursPatchDeleteError
    | ITRManhoursPatchAllDeleteRequest
    | ITRManhoursPatchAllDeleteSuccess
    | ITRManhoursPatchAllDeleteError
    | ITRManhoursValidateDeleteRequest
    | ITRManhoursValidateDeleteSuccess
    | ITRManhoursValidateDeleteError
    | ITRManhoursFileUploaded
    | ITRManhoursClearInProgressSpinner
    | ITRManhoursTemplateFileRequest
    | ITRManhoursTemplateFileSuccess
    | ITRManhoursTemplateFileError
    | ITRManhoursDownloadOutputDataRequest
    | ITRManhoursDownloadOutputDataSuccess
    | ITRManhoursDownloadOutputDataError
    | ITRManhoursValidateButtonStateRequest
    | ITRManhoursValidateButtonStateSuccess
    | ITRManhoursValidateButtonStateError
    | ITRManhoursAddStartedLog;
