import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ProjectTeamMappingDTO } from 'src/app/models/project-team-mapping-dto';
import { ProjectTeamMappingService } from 'src/app/services/api/webapi-services/project-team-mapping-service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AddProjectTeamMappingComponent } from './add-project-team-mapping/add-project-team-mapping.component';

@Component({
  selector: 'app-project-team-mapping',
  templateUrl: './project-team-mapping.component.html',
  styleUrls: ['./project-team-mapping.component.scss']
})
export class ProjectTeamMappingComponent extends BaseComponent implements OnInit {
  @Input() uploadType: string;

  displayedColumns = ['projectTeamName', 'sourceFileDataOwner', 'remove'];
  dataSource = new MatTableDataSource<ProjectTeamMappingDTO>();
  isLoading: boolean;

  constructor(private projectTeamMappingService: ProjectTeamMappingService,
    private popupService: PopupService,
    private toastService: ToastService) {
    super();

  }

  ngOnInit(): void {
    this.projectTeamMappingService.get(this.uploadType)
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((data) => this.dataSource = new MatTableDataSource(data));
  }


  add() {
    const dialogRef = this.popupService.openPopup(
      new PopupSettings(AddProjectTeamMappingComponent, 450, 430, {
        uploadType: this.uploadType,
      })
    );

    dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((result) => {
        if (result && result.success) {
          const data = this.dataSource.data;
          data.push(result.data);
          this.dataSource.data = data;
        }
      });
  }

  remove(element: ProjectTeamMappingDTO) {
    this.popupService
      .openPopup(
        new ConfirmDialogPopupSettings({
          title: 'Confirm action',
          text: `Are you sure you want to delete ${element.sourceFileDataOwner} mapping?`,
        })
      )
      .afterClosed()
      .pipe(takeWhile(() => this.isAlive === true))
      .subscribe((answer) => {
        if (answer) {
          this.isLoading = true;
          this.projectTeamMappingService
            .remove(element.id)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
              () => {
                let data = this.dataSource.data.filter((s) => s.id !== element.id);
                this.dataSource = new MatTableDataSource(data);
                this.isLoading = false;
              },
              () => {
                this.isLoading = false;
                this.toastService.Error(
                  `Error occurred while deleting ${element.sourceFileDataOwner} mapping. Please contact Program Administrator.`
                );
              }
            );
        }
      });
  }

}
