import { GOOCComparisonDashboardActions, GOOCComparisonDashboardActionTypes } from './actions';
import { GOOCComparisonDashboardFilter, GOOCComparisonDashboardState } from './model';

const initialState: GOOCComparisonDashboardState = {
    gooc1Filter: new GOOCComparisonDashboardFilter(),
    gooc2Filter: new GOOCComparisonDashboardFilter(),
    gooc1: null,
    gooc2: null,
    gooc1IsLoading: false,
    gooc2IsLoading: false,
    gooc1TotalCount: 0,
    gooc2TotalCount: 0,
};

export function reducer(state = initialState, action: GOOCComparisonDashboardActions): GOOCComparisonDashboardState {
    switch (action.type) {
        case GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardRequest:
        case GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardExportToExcelRequest:
            return {
                ...state,
                gooc1IsLoading: true,
            };
        case GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardSuccess:
            return {
                ...state,
                gooc1IsLoading: false,
                gooc1: action.payload.items,
                gooc1TotalCount: action.payload.totalCount,
            };
        case GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardError:
        case GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardExportToExcelSuccess:
        case GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardExportToExcelError: 
            return {
                ...state,
                gooc1IsLoading: false,
            };
        case GOOCComparisonDashboardActionTypes.GOOCComparison1DashboardUpdateFilterProperties:
            return {
                ...state,
                gooc1Filter: { ...state.gooc1Filter, ...action.payload },
            };
        case GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardRequest:
        case GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardExportToExcelRequest:
            return {
                ...state,
                gooc2IsLoading: true,
            };
        case GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardSuccess:
            return {
                ...state,
                gooc2IsLoading: false,
                gooc2: action.payload.items,
                gooc2TotalCount: action.payload.totalCount,
            };
        case GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardError:
        case GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardExportToExcelSuccess:
        case GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardExportToExcelError:
            return {
                ...state,
                gooc2IsLoading: false,
            };
        case GOOCComparisonDashboardActionTypes.GOOCComparison2DashboardUpdateFilterProperties:
            return {
                ...state,
                gooc2Filter: { ...state.gooc2Filter, ...action.payload },
            };
        default:
            return state;
    }
}
