import { Action } from '@ngrx/store';
import {
    PlanningLookaheadDTO,
    PlanningCommitmentReliabilityDTO,
    WeeklyPlanningCommitmentDTO,
    PlanningFilter,
} from './model';
import * as moment from 'moment';

export enum PlanningActionTypes {
    PlanningLookaheadFilterUpdate = '[Planning Lookahead] Filter Update',
    PlanningLookaheadFilterPropertyUpdate = '[Planning Lookahead] Filter Property Update',
    PlanningLookaheadFilterReset = '[Planning Lookahead] Filter Reset',
    PlanningLookaheadSetType = '[Planning Lookahead] Set Type',
    PlanningLookaheadRequest = '[Planning Lookahead] Request',
    PlanningLookaheadSuccess = '[Planning Lookahead] Success',
    PlanningLookaheadError = '[Planning Lookahead] Error',
    PlanningLookaheadSetWeekRange = '[Planning Lookahead] Set Week Range',

    PlanningCommitmentReliabilityFilterUpdate = '[Planning CommitmentReliability] Filter Update',
    PlanningCommitmentReliabilityFilterPropertyUpdate = '[Planning CommitmentReliability] Filter Property Update',
    PlanningCommitmentReliabilityFilterReset = '[Planning Commitment Reliability] Filter Reset',
    PlanningCommitmentReliabilitySetType = '[Planning Commitment Reliability] Set Type',
    PlanningCommitmentReliabilityRequest = '[Planning Commitment Reliability] Request',
    PlanningCommitmentReliabilitySuccess = '[Planning Commitment Reliability] Success',
    PlanningCommitmentReliabilityError = '[Planning Commitment Reliability] Error',
    PlanningCommitmentReliabilitySetWeekRange = '[Planning Commitment Reliability] Set Week Range',
    PlanningCommitmentReliabilitySetMonthRange = '[Planning Commitment Reliability] Set Month Range',
    CommitmentReliabilityTogglePlanView = '[Planning Commitment Reliability] Toggle Plan View',
}

export class PlanningLookaheadFilterUpdate implements Action {
    readonly type = PlanningActionTypes.PlanningLookaheadFilterUpdate;

    constructor(public payload: PlanningFilter) {}
}
export class PlanningLookaheadFilterPropertyUpdate implements Action {
    readonly type = PlanningActionTypes.PlanningLookaheadFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class PlanningLookaheadFilterReset implements Action {
    readonly type = PlanningActionTypes.PlanningLookaheadFilterReset;
}

export class PlanningLookaheadSetType implements Action {
    readonly type = PlanningActionTypes.PlanningLookaheadSetType;

    constructor(public payload: string) {}
}

export class PlanningLookaheadRequest implements Action {
    readonly type = PlanningActionTypes.PlanningLookaheadRequest;
}

export class PlanningLookaheadSuccess implements Action {
    readonly type = PlanningActionTypes.PlanningLookaheadSuccess;

    constructor(public payload: PlanningLookaheadDTO[]) {}
}

export class PlanningLookaheadError implements Action {
    readonly type = PlanningActionTypes.PlanningLookaheadError;

    constructor(public error: string) {}
}

export class CommitmentReliabilityTogglePlanView implements Action {
    readonly type = PlanningActionTypes.CommitmentReliabilityTogglePlanView;
}

export class PlanningLookaheadSetWeekRange implements Action {
    readonly type = PlanningActionTypes.PlanningLookaheadSetWeekRange;

    constructor(public payload: moment.Moment) {}
}

export class PlanningCommitmentReliabilityFilterUpdate implements Action {
    readonly type = PlanningActionTypes.PlanningCommitmentReliabilityFilterUpdate;

    constructor(public payload: PlanningFilter) {}
}

export class PlanningCommitmentReliabilityFilterPropertyUpdate implements Action {
    readonly type = PlanningActionTypes.PlanningCommitmentReliabilityFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class PlanningCommitmentReliabilityFilterReset implements Action {
    readonly type = PlanningActionTypes.PlanningCommitmentReliabilityFilterReset;
}

export class PlanningCommitmentReliabilitySetType implements Action {
    readonly type = PlanningActionTypes.PlanningCommitmentReliabilitySetType;

    constructor(public payload: string) {}
}

export class PlanningCommitmentReliabilityRequest implements Action {
    readonly type = PlanningActionTypes.PlanningCommitmentReliabilityRequest;
}

export class PlanningCommitmentReliabilitySuccess implements Action {
    readonly type = PlanningActionTypes.PlanningCommitmentReliabilitySuccess;

    constructor(public weeklyPlanning: WeeklyPlanningCommitmentDTO) {}
}

export class PlanningCommitmentReliabilityError implements Action {
    readonly type = PlanningActionTypes.PlanningCommitmentReliabilityError;

    constructor(public error: string) {}
}

export class PlanningCommitmentReliabilitySetWeekRange implements Action {
    readonly type = PlanningActionTypes.PlanningCommitmentReliabilitySetWeekRange;

    constructor(public payload: moment.Moment) {}
}

export class PlanningCommitmentReliabilitySetMonthRange implements Action {
    readonly type = PlanningActionTypes.PlanningCommitmentReliabilitySetMonthRange;

    constructor(public payload: moment.Moment) {}
}

export type PlanningActions =
    | PlanningLookaheadFilterUpdate
    | PlanningLookaheadFilterPropertyUpdate
    | PlanningLookaheadFilterReset
    | PlanningLookaheadSetType
    | PlanningLookaheadRequest
    | PlanningLookaheadSuccess
    | PlanningLookaheadError
    | PlanningLookaheadSetWeekRange
    | PlanningCommitmentReliabilityFilterUpdate
    | PlanningCommitmentReliabilityFilterPropertyUpdate
    | PlanningCommitmentReliabilityFilterReset
    | PlanningCommitmentReliabilitySetType
    | PlanningCommitmentReliabilityRequest
    | PlanningCommitmentReliabilitySuccess
    | PlanningCommitmentReliabilityError
    | PlanningCommitmentReliabilitySetWeekRange
    | CommitmentReliabilityTogglePlanView
    | PlanningCommitmentReliabilitySetMonthRange;
