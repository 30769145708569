<div class="main">
  <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
  <div class="buttons-container">
    <button type="button" (click)="testFTPConnection()" class="sct-button sct-button-white margin-right20">
      test FTP connection
    </button>
    <button type="button" [disabled]="saveButtonDisabled" (click)="saveFTPConfiguration()"
      class="sct-button sct-button-light">
      save
    </button>
  </div>
  <table mat-table [dataSource]="ftpConfigData" matSortDisableClear>
    <ng-container matColumnDef="key">
      <th mat-header-cell disableClear *matHeaderCellDef>Key</th>
      <td mat-cell *matCellDef="let element">
        {{ element.key }}
      </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell disableClear *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="!element.isInEditMode && element.key !== 'FTP:Password'">{{ element.value }}</div>
        <div *ngIf=" !element.isInEditMode 
          && element.key === 'FTP:Password' 
          && element.value !== null 
          && element.value.trim() !== ''
      ">
          *******
        </div>
        <div *ngIf="element.isInEditMode" class="editmode">
          <mat-form-field>
            <input matInput [(ngModel)]="element.value" [ngModelOptions]="{ standalone: true }" name="value"
              autocomplete="off" />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell disableClear *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="!element.isInEditMode" mat-icon-button (click)="enterEditMode(element)" title="edit">
          <mat-icon svgIcon="edit"></mat-icon>
        </button>
        <button *ngIf="element.isInEditMode" mat-icon-button (click)="exitEditMode(element)" title="accept">
          <mat-icon>check_circle_outline</mat-icon>
        </button>
        <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
          <mat-icon>cancel</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>