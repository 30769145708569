import { Component, EventEmitter } from '@angular/core';
import { WaypointDTO } from 'src/app/models/waypoint-dto';

@Component({
    selector: 'app-milestone-waypoint',
    templateUrl: './milestone-waypoint.component.html',
    styleUrls: ['./milestone-waypoint.component.scss'],
})
export class MilestoneWaypointComponent {
    waypoints: WaypointDTO[] = [];
    waypointRemoved: EventEmitter<WaypointDTO> = new EventEmitter<WaypointDTO>();

    onWaypointsChanged(waypoints: WaypointDTO[]) {
        this.waypoints = waypoints;
    }

    onWaypointRemoved(waypoint: WaypointDTO) {
        this.waypointRemoved.emit(waypoint);
    }
}
