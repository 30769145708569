import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { FormService } from 'src/app/services/shared/form.service';
import { DcnRfiRedlineRegisterExportToExcelRequest, DcnRfiRedlineRegisterFilterPropertyUpdate, DcnRfiRegisterDataRequest, RedlineRegisterDataRequest } from 'src/app/store/dcn-rfi-redline/actions';
import { DcnRfiRedlineRegisterFilter, DcnRfiRegisterDto, DcnRfiRegisterResultDto, RedlineRegisterDto, RedlineRegisterResultDto, RegisterType } from 'src/app/store/dcn-rfi-redline/model';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-dcn-rfi-redline-register',
  templateUrl: './dcn-rfi-redline-register.component.html',
  styleUrls: ['./dcn-rfi-redline-register.component.scss']
})
export class DcnRfiRedlineRegisterComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

  filterForm: FormGroup;
  pageSize: number = 25;
  registerType: RegisterType = RegisterType.Dcnrfi;
  registerTypes = RegisterType;
  dcnRfiRegister: DcnRfiRegisterResultDto;
  redlineRegister: RedlineRegisterResultDto;

  dcnRfiRedlineRegisterState$ = this.store.select((store) => store.dcnRfiRedlineRegisterState);

  constructor(
    private store: Store<ApplicationState>,
    private formService: FormService,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer
  ) {
    super();
    this.filterForm = this.formService.createSimpleForm(new DcnRfiRedlineRegisterFilter());

    this.iconRegistry.addSvgIcon(
      "history",
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/history.svg')
    );
    this.iconRegistry.addSvgIcon(
      'del-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
    );
  }

  ngOnInit(): void {
    this.store.dispatch(new DcnRfiRegisterDataRequest());
    this.dcnRfiRedlineRegisterState$.pipe(takeWhile(() => this.isAlive)).subscribe((state) => {
      this.registerType = state.dcnRfiRedlineRegisterFilter.registerType;
      this.dcnRfiRegister = state.dcnRfiRegister;
      this.redlineRegister = state.redlineRegister;
    });
  }

  exportToExcel() {
    this.store.dispatch(new DcnRfiRedlineRegisterExportToExcelRequest());
  }

  onPageChange(newPage: number) {
    if (newPage < 1) {
        newPage = 1;
    } else if (newPage > this.paginator.getNumberOfPages()) {
        newPage = this.paginator.getNumberOfPages();
    }
    let pageEvt = new PageEvent();
    pageEvt.pageIndex = newPage - 1;
    pageEvt.pageSize = this.pageSize;
    this.paginator.pageIndex = pageEvt.pageIndex;
    this.onPaginatorChange(pageEvt);
  }

  onPaginatorChange(pageEvent: PageEvent) {
    if (this.pageSize !== pageEvent.pageSize) {
        this.filterForm.controls.take.setValue(pageEvent.pageSize);
        this.pageSize = pageEvent.pageSize;
    } else {
        this.filterForm.controls.page.setValue(pageEvent.pageIndex);
    }

    this.filterForm.value.registerType = this.registerType;
    this.store.dispatch(new DcnRfiRedlineRegisterFilterPropertyUpdate(this.filterForm.value as DcnRfiRedlineRegisterFilter));

    if (this.registerType === RegisterType.Dcnrfi) {
      this.store.dispatch(new DcnRfiRegisterDataRequest());
    }
    else {
      this.store.dispatch(new RedlineRegisterDataRequest());
    }
  }
}
