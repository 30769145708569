import { Contractor, Waypoint, Milestone, Gooc, RFO, StagedStartUpPriority } from '../../common.model';
import {
    initWeekRange,
    getCurrentWeekStartDate,
    initMonthRange,
    initQuarterRange,
    getCurrentQuarterStartDate,
} from 'src/app/extensions';
import * as moment from 'moment';

export const RUNDOWN_NUM_OF_PAST_WEEKS = 5,
    RUNDOWN_NUM_OF_PAST_MONTHS = 6,
    RUNDOWN_NUM_OF_UPCOMING_WEEKS = 7,
    RUNDOWN_NUM_OF_UPCOMING_MONTHS = 6,
    RUNDOWN_CHART_DATE_FORMAT = 'DD MMM YY',
    RUNDOWN_MONTH_CHART_DATE_FORMAT = 'MMM YY',
    RUNDOWN_QUARTER_CHART_DATE_FORMAT = 'Q YY';

export enum RundownType {
    //MC = 'MC',
    RFSU = 'RFSU',
    AQVD = 'A-QVD',
    BITR = 'B-ITR',
    CITR = 'C-ITR',
    PLI1 = 'PLI1',
    PLI2 = 'PLI2',
}

export enum RundownResponseResultType {
    Plan,
    Actual,
    Forecast,
    ForecastCurve,
}

export enum RundownResponsePLIResultType {
    Total,
    APLI,
    BPLI,
    CPLI,
    DPLI,
    MCWalkdowns,
}

export enum ClosureStages {
    All,
    PostMC,
    PostRFSU,
}

export type RundownTypeKey = keyof typeof RundownType;

export const RUNDOWN_TYPE_KEYS = new Map<RundownType, RundownTypeKey>(
    Object.entries(RundownType).map(([key, value]: [RundownTypeKey, RundownType]) => [value, key])
);

export class RundownFilter {
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    waypoints: Waypoint[] = [];
    milestones: Milestone[] = [];
    goocs: Gooc[] = [];
    disciplines: string[] = [];
    categories: string[] = [];
    systemOwner: string[] = [];
    scManager: string[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    actionBy: string[] = [];
    punchType: string[] = [];
    responsiblePerson: string[] = [];
    subsystems: string[] = [];
    rfos: RFO[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    displayTotal: boolean = true;
    closureStage: ClosureStages = ClosureStages.All;
    hvac?: boolean = null;
    qvdNumbers: string[] = [];
    itrDescriptions: string[] = [];
    workAreas: any[] = [];
    range: number = 12;
    excepqvdNumbers:string[] = [];
}

export interface RundownChartData {
    name: string;
    series: {
        name: string;
        value: number;
    }[];
}

export interface RundownTableData {
    name: string;
    series: {
        total: number;
        closed: number;
        raised: number;
        name: string;
        breakdown: {
            areaBreakdown: string;
            count: number;
        }[];
    }[];
}

export class RundownTypeState {
    weeks = initWeekRange(
        getCurrentWeekStartDate().add(-RUNDOWN_NUM_OF_PAST_WEEKS, 'weeks'),
        RUNDOWN_NUM_OF_PAST_WEEKS + RUNDOWN_NUM_OF_UPCOMING_WEEKS
    );
    months = initMonthRange(
        getCurrentWeekStartDate().add(-RUNDOWN_NUM_OF_PAST_MONTHS, 'months'),
        RUNDOWN_NUM_OF_PAST_MONTHS + RUNDOWN_NUM_OF_UPCOMING_MONTHS
    );
    quarters = initQuarterRange(
        getCurrentQuarterStartDate().add(-RUNDOWN_NUM_OF_PAST_MONTHS * 3, 'months'),
        RUNDOWN_NUM_OF_PAST_MONTHS + RUNDOWN_NUM_OF_UPCOMING_MONTHS
    );
    lineChart: RundownChartData[] = [];
    yScaleMin: number;
    yScaleMax: number;
    barChart: RundownChartData[] = [];
    tableData: RundownTableData[] = [];
    showGroupedVerticalChart: boolean = false;
}

export interface RundownResponseDTO {
    total: number;
    results: {
        open: number;
        numberOfAlreadyCompleted: number;
        countsByDate: {
            count: number;
            closed: number;
            raised: number;
            date: moment.Moment;
            breakdown: {
                areaBreakdown: string;
                count: number;
                date: moment.Moment; 
            }[];
        }[];
    }[];
}

export interface RundownState {
    mc: RundownTypeState;
    rfsu: RundownTypeState;
    aqvd: RundownTypeState;
    bitr: RundownTypeState;
    citr: RundownTypeState;
    pli2: RundownTypeState;
    pli1: RundownTypeState;
    rundownType: RundownType;
    filter: RundownFilter;
    isLoading: boolean;
    monthViewEnabled: boolean;
    weeklyViewEnabled: boolean;
    quarterViewEnabled: boolean;
    planViewEnabled: boolean;
    filteredByClosureStage: boolean;
}
