import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Subscription } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { CITRPartialProgressDeleteDTO, CITRPartialProgressDeleteFilter } from 'src/app/store/planning-upload/citr-partial-progress/model';
import {
    CITRPartialProgressUploadLogRequest,
    CITRPartialProgressPatchDeleteRequest,
    CITRPartialProgressPatchAllDeleteRequest,
    CITRPartialProgressDeleteFilterPropertyUpdate,
    CITRPartialProgressDeleteRequest,
    CITRPartialProgressTemplateFileRequest,
    CITRPartialProgressDownloadOutputDataRequest,
    CITRPartialProgressDeleteFilterReset,
} from 'src/app/store/planning-upload/citr-partial-progress/actions';
import { PlanningUploadSetActivatedRoute } from 'src/app/store/planning-upload/actions';
import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';

@Component({
    selector: 'app-citr-partial-progress',
    templateUrl: './citr-partial-progress.component.html',
    styleUrls: ['./citr-partial-progress.component.scss'],
})
export class CITRPartialProgressComponent extends BaseComponent implements OnInit {

    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    deleteData: MatTableDataSource<CITRPartialProgressDeleteDTO>;
    deleteDataResultsLength = 0;
    deleteDataPageSize = 10;
    displayedDeleteDataColumns = [
        'barcode',
        'qvdNumber',
        'actions',
    ];

    sortBy$ = this.store.select((state) => state.citrPartialProgressState.deleteFilter.sortBy);
    direction$ = this.store.select((state) => state.citrPartialProgressState.deleteFilter.direction);
    uploadLogData$ = this.store.select((store) => store.citrPartialProgressState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.citrPartialProgressState.isUploadLogLoading);
    deleteData$ = this.store.select((store) => store.citrPartialProgressState.deleteData);
    isDeleteDataLoading$ = this.store.select((store) => store.citrPartialProgressState.isDeleteDataLoading);
    isLoading$ = this.store.select((store) => store.citrPartialProgressState.isLoading);
    interval$ = this.store.select((store) => store.planningUploadState.interval);
    intervalSub: Subscription = null;
    deleteFilter$ = this.store.select((store) => store.citrPartialProgressState.deleteFilter);

    columnQVDNumber$ = this.store.select((state) => state.citrPartialProgressState.deleteFilter.columnQVDNumber);
    columnBarcode$ = this.store.select((state) => state.citrPartialProgressState.deleteFilter.columnBarcode);

    showOrder = true;
    sortBy: string;
    direction = OrderDirection.Desc;
    deleteValidationFilterForm: UntypedFormGroup;
    columnQVDNumber: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;

    constructor(private store: Store<ApplicationState>,
        private activatedRoute: ActivatedRoute,
        private popupSvc: PopupService,
        private formService: FormService,
        private lookupService: LookupService) {
        super();

        this.deleteValidationFilterForm = this.formService.createSimpleForm(new CITRPartialProgressDeleteFilter());
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new PlanningUploadSetActivatedRoute(urlSegments[0].path));
        });
        this.interval$.pipe(takeWhile(() => this.isAlive)).subscribe((interval) => {
            if (this.intervalSub) {
                this.intervalSub.unsubscribe();
            }
            this.intervalSub = interval.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
                this.store.dispatch(new CITRPartialProgressUploadLogRequest());
            });
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

        this.deleteData$.pipe(takeWhile(() => this.isAlive)).subscribe((deleteData) => {
            this.deleteData = new MatTableDataSource(deleteData.items);
            this.deleteDataResultsLength = deleteData.totalCount;
        });

        this.deleteFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.deleteValidationFilterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.sortBy = data));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.direction = data));

        this.columnQVDNumber$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnQVDNumber = data));

        this.columnBarcode$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnBarcode = data));

    }

    restoreItem(element: CITRPartialProgressDeleteDTO) {

        this.store.dispatch(
            new CITRPartialProgressPatchDeleteRequest({
                barcode: element.barcode,
                deleteState: false,
            })
        );
    }

    deleteItem(element: CITRPartialProgressDeleteDTO) {

        this.store.dispatch(
            new CITRPartialProgressPatchDeleteRequest({
                barcode: element.barcode,
                deleteState: true,
            })
        );
    }

    patchAllDeleteRecords(deleteState: boolean) {
        this.store.dispatch(
            new CITRPartialProgressPatchAllDeleteRequest({
                deleteState: deleteState,
            })
        );
    }

    onDeleteDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deleteDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new CITRPartialProgressDeleteFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deleteDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new CITRPartialProgressDeleteFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new CITRPartialProgressDeleteRequest());
    }

    downloadTemplate(fileName: string) {
        this.store.dispatch(new CITRPartialProgressTemplateFileRequest(fileName));
    }

    downloadData(filePath: string) {
        this.store.dispatch(new CITRPartialProgressDownloadOutputDataRequest(filePath));
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts: showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.deleteValidationFilterForm.controls[propertyName].setValue(value);
                    this.store.dispatch(
                        new CITRPartialProgressDeleteFilterPropertyUpdate({
                            key: propertyName,
                            value: this.deleteValidationFilterForm.controls[propertyName].value,
                        })
                    );
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();

                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new CITRPartialProgressDeleteFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new CITRPartialProgressDeleteFilterPropertyUpdate({
                            key: propertyName,
                            value: this.deleteValidationFilterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new CITRPartialProgressDeleteFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new CITRPartialProgressDeleteFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'barcode', direction: OrderDirection.Desc },
                })
            );
        }
    }

    search() {
        this.updateFilterByFormProperties();

        this.store.dispatch(
            new CITRPartialProgressDeleteFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new CITRPartialProgressDeleteRequest());
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.deleteValidationFilterForm.controls)) {

            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.deleteValidationFilterForm.controls[key].value;
                this.store.dispatch(new CITRPartialProgressDeleteFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.deleteValidationFilterForm, key, value);
                }
            }
        }
    }

    resetFilters() {
        this.store.dispatch(new CITRPartialProgressDeleteFilterReset());
        this.store.dispatch(new CITRPartialProgressDeleteRequest());
    }

    private getLatestFilterData(): CITRPartialProgressDeleteFilter {
        let filter: CITRPartialProgressDeleteFilter;
        this.store.pipe(select((x) => x.citrPartialProgressState, take(1))).subscribe((data) => (filter = data.deleteFilter));
        return filter;
    }

    getQVDNumbers = (search = '', take = 30, page = 0) =>
        this.lookupService.searchQVDNumberWithCITRPartailProgressDeleteFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );

    getBarcode = (search = '', take = 30, page = 0) =>
        this.lookupService.searchBarcodeWithCITRPartailProgressDeleteFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );

    
}

