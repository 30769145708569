import { Component, OnInit, ViewChild, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { SystemisedDrawingDTO, SystemisedDrawingFilter } from 'src/app/store/systemised-drawing/model';
import { FormService } from 'src/app/services/shared/form.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { Store } from '@ngrx/store';
import {
    SystemisedDrawingFilterPropertyUpdate,
    SystemisedDrawingFilterRequest,
    SystemisedDrawingFilterReset,
    SystemisedDrawingExportToExcelRequest,
} from 'src/app/store/systemised-drawing/actions';
import { takeWhile, take, map } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import { OrderDirection, RFO } from 'src/app/store/common.model';
import { SystemisedDrawingService } from 'src/app/services/api/webapi-services/systemised-drawing.service';

@Component({
    selector: 'app-systemised-drawing',
    templateUrl: './systemised-drawing.component.html',
    styleUrls: ['./systemised-drawing.component.scss'],
})
export class SystemisedDrawingComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    isLoading = false;
    data: SystemisedDrawingDTO[] = [];
    resultsLength = 0;
    pageSize = 25;
    systemisedDrawingGridData$ = this.store.select((state) => state.systemisedDrawingState.dataPagination);
    systemisedDrawingGridLoader$ = this.store.select((state) => state.systemisedDrawingState.isLoading);
    systemisedDrawingFilter$ = this.store.select((state) => state.systemisedDrawingState.filter);
    sortBy$ = this.store.select((state) => state.systemisedDrawingState.filter.sortBy);
    sortDirection$ = this.store.select((state) => state.systemisedDrawingState.filter.direction);
    sortBy = '';
    direction: OrderDirection = OrderDirection.Desc;
    displayedColumns = ['systemisedDrawingNo', 'engineeringDrawingNo', 'subsystems'];
    subsystemAutocompleteDisplayedColumns = ['id', 'name'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    setSystemisedDrawingNoInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setEngineeringDrawingNoInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private systemisedDrawingService: SystemisedDrawingService
    ) {
        super();

        this.filterForm = this.formSvc.createSimpleForm(new SystemisedDrawingFilter());
    }

    ngOnInit() {
        this.store.dispatch(new SystemisedDrawingFilterRequest());
        this.systemisedDrawingGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.systemisedDrawingFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.paginator.pageIndex = filter.page;
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => {
            this.sortBy = sortBy;
        });

        this.sortDirection$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => {
            this.direction = direction;
        });

        this.systemisedDrawingGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new SystemisedDrawingFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new SystemisedDrawingFilterRequest());
        });
    }

    resetFilters() {
        this.setSystemisedDrawingNoInput.emit(new SetInputEventArgs(false, ''));
        this.setEngineeringDrawingNoInput.emit(new SetInputEventArgs(false, ''));
        this.setSubsystemInput.emit(new SetInputEventArgs(false, ''));
        this.store.dispatch(new SystemisedDrawingFilterReset());
        this.filterForm.setValue(new SystemisedDrawingFilter());
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
    }

    exportToExcel() {
        this.store.dispatch(new SystemisedDrawingExportToExcelRequest());
    }

    openSysDrawingPage(sysDrawingNo: string) {
        window.open(
            `https://eb-fgp.chevron.com/FGP/Search/QuickLink.aspx?n=${sysDrawingNo}&state=LatestRevision&i=view`,
            '_blank'
        );
    }

    openEngDrawingPage(engDrawingNo: string) {
        window.open(
            `https://eb-fgp.chevron.com/FGP/Search/QuickLink.aspx?n=${engDrawingNo}&state=LatestRevision&i=view`,
            '_blank'
        );
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
        this.store.dispatch(
            new SystemisedDrawingFilterPropertyUpdate({
                key: propertyName,
                value: [],
            })
        );
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (key !== 'sortBy' && key !== 'direction') {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new SystemisedDrawingFilterPropertyUpdate({ key, value }));
            }
        }
    }

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    search() {
        this.updateFilterByFormProperties();
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new SystemisedDrawingFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(
            new SystemisedDrawingFilterPropertyUpdate({
                key: 'take',
                value: this.pageSize,
            })
        );
        this.store.dispatch(new SystemisedDrawingFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new SystemisedDrawingFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new SystemisedDrawingFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new SystemisedDrawingFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    getSystemisedDrawingNo = (search = '', take = 10, page = 0) => {
        return this.systemisedDrawingService.getSystemisedDrawingNo(search, take, page);
    };

    getEngineeringDrawingNo = (search = '', take = 10, page = 0) => {
        return this.systemisedDrawingService.getEngineeringDrawingNo(search, take, page);
    };

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page);
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    getAreaBreakdown = (search = '', take = 30, page = 0) =>
    this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);

}
