import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { Moment } from 'moment';

export enum RedlineActionTypes {
    RedlineUploadLogRequest = '[Redline] Upload Log Request',
    RedlineUploadLogSuccess = '[Redline] Upload Log Success',
    RedlineUploadLogError = '[Redline] Upload Log Error',
    RedlineSetStatusAndStartDate = '[Redline] Set Status and Start Date',
    RedlineFileUploaded = '[Redline] File Uploaded',
    RedlineClearInProgressSpinner = '[Redline] Clear In Progress Spinner',
    RedlineTemplateFileRequest = '[Redline] Template File Request',
    RedlineTemplateFileSuccess = '[Redline] Template File Success',
    RedlineTemplateFileError = '[Redline] Template File Error',
    RedlineValidateButtonStateRequest = '[Redline] Validate Button State Request',
    RedlineValidateButtonStateSuccess = '[Redline] Validate Button State Success',
    RedlineValidateButtonStateError = '[Redline] Validate Button State Error',
    RedlineDownloadOutputDataRequest = '[Redline] Download Output Data Request',
    RedlineDownloadOutputDataSuccess = '[Redline] Download Output Data Success',
    RedlineDownloadOutputDataError = '[Redline] Download Output Data Error',
    RedlineAddStartedLog = '[Redline] Add Started Log',
    RedlineValidateDeltaRequest = '[Redline] Validate Delta Request',
    RedlineValidateDeltaSuccess = '[Redline] Validate Delta Success',
    RedlineValidateDeltaError = '[Redline] Validate Delta Error',
}

export class RedlineAddStartedLog implements Action {
    readonly type = RedlineActionTypes.RedlineAddStartedLog;
}

export class RedlineUploadLogRequest implements Action {
    readonly type = RedlineActionTypes.RedlineUploadLogRequest;
}

export class RedlineUploadLogSuccess implements Action {
    readonly type = RedlineActionTypes.RedlineUploadLogSuccess;

    constructor(public payload: UpdateDataLog[]) {}
}

export class RedlineUploadLogError implements Action {
    readonly type = RedlineActionTypes.RedlineUploadLogError;

    constructor(public payload: string) {}
}

export class RedlineSetStatusAndStartDate implements Action {
    readonly type = RedlineActionTypes.RedlineSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment }) {}
}

export class RedlineFileUploaded implements Action {
    readonly type = RedlineActionTypes.RedlineFileUploaded;
}

export class RedlineClearInProgressSpinner implements Action {
    readonly type = RedlineActionTypes.RedlineClearInProgressSpinner;
}

export class RedlineTemplateFileRequest implements Action {
    readonly type = RedlineActionTypes.RedlineTemplateFileRequest;

    constructor(public payload: string) {}
}

export class RedlineTemplateFileSuccess implements Action {
    readonly type = RedlineActionTypes.RedlineTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class RedlineTemplateFileError implements Action {
    readonly type = RedlineActionTypes.RedlineTemplateFileError;

    constructor(public payload: string) {}
}

export class RedlineValidateButtonStateRequest implements Action {
    readonly type = RedlineActionTypes.RedlineValidateButtonStateRequest;
}

export class RedlineValidateButtonStateSuccess implements Action {
    readonly type = RedlineActionTypes.RedlineValidateButtonStateSuccess;

    constructor(public payload: { isReadyForUpload: boolean; recordsForDeletion: number; }) {}
}

export class RedlineValidateButtonStateError implements Action {
    readonly type = RedlineActionTypes.RedlineValidateButtonStateError;

    constructor(public payload: string) {}
}

export class RedlineDownloadOutputDataRequest implements Action {
    readonly type = RedlineActionTypes.RedlineDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class RedlineDownloadOutputDataSuccess implements Action {
    readonly type = RedlineActionTypes.RedlineDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class RedlineDownloadOutputDataError implements Action {
    readonly type = RedlineActionTypes.RedlineDownloadOutputDataError;

    constructor(public payload: string) {}
}

export class RedlineValidateDeltaRequest implements Action {
    readonly type = RedlineActionTypes.RedlineValidateDeltaRequest;
}

export class RedlineValidateDeltaSuccess implements Action {
    readonly type = RedlineActionTypes.RedlineValidateDeltaSuccess;
}

export class RedlineValidateDeltaError implements Action {
    readonly type = RedlineActionTypes.RedlineValidateDeltaError;

    constructor(public payload: string) {}
}

export type RedlineActions =
    | RedlineUploadLogRequest
    | RedlineUploadLogSuccess
    | RedlineUploadLogError
    | RedlineSetStatusAndStartDate
    | RedlineFileUploaded
    | RedlineClearInProgressSpinner
    | RedlineTemplateFileRequest
    | RedlineTemplateFileSuccess
    | RedlineTemplateFileError
    | RedlineValidateButtonStateRequest
    | RedlineValidateButtonStateSuccess
    | RedlineValidateButtonStateError
    | RedlineDownloadOutputDataRequest
    | RedlineDownloadOutputDataSuccess
    | RedlineDownloadOutputDataError
    | RedlineAddStartedLog
    | RedlineValidateDeltaRequest
    | RedlineValidateDeltaSuccess
    | RedlineValidateDeltaError;
