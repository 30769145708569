import { Moment } from 'moment';
import {
    BaseResultDTO,
    CalendarColumn,
    CheckListColumn,
    Contractor,
    Gooc,
    OrderDirection,
    RFO,
    StagedStartUpPriority,
} from '../common.model';

export class CertificateSearchFilter {
    page = 0;
    take = 25;
    sortBy = 'subsystem';
    timezoneOffset = 0;
    direction: OrderDirection = OrderDirection.Desc;
    projectTeamNames: string[] = [];
    contractors: Contractor[] = [];
    disciplines: string[] = [];
    goocs: Gooc[] = [];
    rfos: RFO[] = [];
    mcEngineer: string[] = [];
    areaCompletionLead: string[] = [];
    areaBreakdown: string[] = [];
    rfsuContractors: string[] = [];
    systemOwner: string[] = [];
    scManager: string[] = [];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];

    columnSubsystem: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnCertificateType: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnCertificateNumber: CheckListColumn = null;
}

export class CertificateSearchDTO {
    subsystem: string;
    discipline: string;
    certificateType: string;
    status: string;
    statusDate: Moment;
    certificateNumber: string;
}

export interface CertificateSearchState {
    filter: CertificateSearchFilter;
    data: BaseResultDTO<CertificateSearchDTO>;
    isLoading: boolean;
}
