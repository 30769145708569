<h2 mat-dialog-title class="title">select constraint type</h2>
<form [formGroup]="constraintTypeForm" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field>
            <mat-label>Constraint Type</mat-label>
            <mat-select formControlName="constraintType">
                <mat-option *ngFor="let type of constraintTypes" [value]="type">
                    {{ type.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="constraintTypeForm.controls.constraintType.errors?.required"
                >Constraint Type is required.</mat-error
            >
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button class="sct-button sct-button-light" type="submit" [disabled]="!constraintTypeForm.valid">
            save
        </button>
        <button mat-button class="sct-button sct-button-white" mat-dialog-close="">cancel</button>
    </mat-dialog-actions>
</form>
