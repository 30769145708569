import { Action } from '@ngrx/store';
import { SubystemConflictsDataPagination } from './model';

export enum SubystemConflictsActionTypes {
    SubystemConflictsFilterRequest = '[Subystem Conflicts] Filter Request',
    SubystemConflictsFilterSuccess = '[Subystem Conflicts] Filter Success',
    SubystemConflictsFilterError = '[Subystem Conflicts] Filter Error',
    SubystemConflictsFilterPropertyUpdate = '[Subystem Conflicts] Filter Property Update',
    SubystemConflictsFilterReset = '[Subystem Conflicts] Filter Reset',
}

export class SubystemConflictsFilterRequest implements Action {
    readonly type = SubystemConflictsActionTypes.SubystemConflictsFilterRequest;
}

export class SubystemConflictsFilterSuccess implements Action {
    readonly type = SubystemConflictsActionTypes.SubystemConflictsFilterSuccess;

    constructor(public payload: SubystemConflictsDataPagination) {}
}

export class SubystemConflictsFilterError implements Action {
    readonly type = SubystemConflictsActionTypes.SubystemConflictsFilterError;

    constructor(public payload: string) {}
}

export class SubystemConflictsFilterPropertyUpdate implements Action {
    readonly type = SubystemConflictsActionTypes.SubystemConflictsFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class SubystemConflictsFilterReset implements Action {
    readonly type = SubystemConflictsActionTypes.SubystemConflictsFilterReset;
}

export type SubystemConflictsActions =
    | SubystemConflictsFilterRequest
    | SubystemConflictsFilterSuccess
    | SubystemConflictsFilterError
    | SubystemConflictsFilterPropertyUpdate
    | SubystemConflictsFilterReset;
