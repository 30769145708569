import { Component, OnInit, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base.component';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { History, ValidationDocument, ValidationDocumentHeader } from 'src/app/store/validation-form/model';
import { takeWhile } from 'rxjs/operators';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import {
    ValidationDocumentRequest,
    ValidationDocumentClear,
    ValidationUpdateStatusRequest,
    ValidationUpdateRequest,
    ValidationUpdateProperty,
    ValidationDocumentHeaderRequest,
    ValidationDocumentChangesReviewedRequest,
    ValidationDocumentChangeIsActiveRequest,
    ValidationDocumentSaveSubsystemsRequest,
    ValidationDocumentUpdateIsActiveForDisciplineRequest,
    ValidationDocumentUpdateSystemizationRemarkRequest,
    ValidationDocumentUpdateSystemizationCompletedRequest,
    ValidationICTUpdateProperty,
    ValidationSMPUpdateProperty,
} from 'src/app/store/validation-form/action';
import { ValidStatuses } from 'src/app/models/validation-statuses';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HistoryPopupComponent } from './history-popup/history-popup.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { UserDetail } from 'src/app/store/common.model';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { HistoryService } from 'src/app/services/api/webapi-services/history.service';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { AssignProjectTeamPopupComponent } from './assign-project-team-popup/assign-project-team-popup.component';
import { ChangeType } from 'src/app/enums';
import { ValidationFormService } from 'src/app/services/api/webapi-services/validation-form.service';
import { AssignProjectTeamRequest } from 'src/app/models/assign-project-team-request';
import { ToastService } from 'src/app/services/shared/toast.service';
import { MsalService } from '@azure/msal-angular';
import { Account } from 'msal';
import { CallOffAttachment, TechnicalQueryAttachmentType } from 'src/app/store/change-document-details/model';
import { DownloadCallOffAttachmentRequest } from 'src/app/store/change-document-details/actions';
import { AccountInfo } from '@azure/msal-browser';
import { cloneDeep } from 'lodash';
import { MatSelectChange } from '@angular/material/select';
import { SignOffPopupComponent } from '../change-document-details/sign-off-popup/sign-off-popup.component';
import { SubsystemReadyForSignOffPopupComponent } from './subsystem-ready-for-sign-off-popup/subsystem-ready-for-sign-off-popup';

@Component({
    selector: 'app-validation-form',
    templateUrl: './validation-form.component.html',
    styleUrls: ['./validation-form.component.scss'],
})
export class ValidationFormComponent extends BaseComponent implements OnInit {
    validationForm: UntypedFormGroup;
    ictvalidationForm: UntypedFormGroup;
    smpvalidationForm: UntypedFormGroup;
    validDocHeader: ValidationDocumentHeader;
    subsystemFormControl: FormControl;
    validationDocument$ = this.store.select((state) => state.validationFormState.validationDocumentResult);
    // ictValidationDocument$ = this.store.select((state) => state.validationFormState.validationDocumentResult.validationICT);
    // smpValidationDocument$ = this.store.select((state) => state.validationFormState.validationDocumentResult.validationSMP);
    validationDocumentHeader$ = this.store.select((state) => state.validationFormState.validationDocumentHeader);
    validationFormLoader$ = this.store.select((state) => state.validationFormState.isLoading);
    validationFormHeaderLoader$ = this.store.select((state) => state.validationFormState.isHeaderLoading);
    updatedProperties$ = this.store.select((state) => state.validationFormState.updatedProperties);
    ValidationStatuses = ValidStatuses;
    validationInitialForm = new ValidationDocument();
    ictvalidationInitialForm = new ValidationDocument();
    smpvalidationInitialForm = new ValidationDocument();
    validationId: number;
    isFormEnabled: boolean;
    statusBackwardsUpdateEnabled: boolean;
    historyLoading: boolean;
    assignInProgress: boolean;
    account: AccountInfo;
    isPowerUser: boolean;
    isInEditMode: boolean;
    prevSubsystems: { id: string }[];
    hasHistory = false;
    searchCurrentProjectSubsystems = false;
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    previousPage = '';
    saveButtonEnabled = false;
    ictsaveButtonEnabled = false;
    smpsaveButtonEnabled = false;
    attachmentTypes = TechnicalQueryAttachmentType;
    showICT = false;
    showSMP = false;
    isSubsystemManualOverwriteallowed : boolean = false;
    isUserAdminOrValidator : boolean = false;
    users: UserDetail[] = [];
    testPackRequestedSubsystems: string[] = [];

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private router: Router,
        private store: Store<ApplicationState>,
        private formService: FormService,
        private roleService: RoleService,
        private popupService: PopupService,
        private historyService: HistoryService,
        private lookupService: LookupService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private validationFormService: ValidationFormService,
        private toastService: ToastService,
        private authService: MsalService
    ) {
        super();
        this.hasHistory = this.router.navigated;
        this.validationForm = this.formService.createSimpleForm(new ValidationDocument());
        this.ictvalidationForm = this.formService.createSimpleForm(new ValidationDocument());
        this.smpvalidationForm = this.formService.createSimpleForm(new ValidationDocument());
        this.iconRegistry.addSvgIcon(
             'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
    }

    ngOnInit() {
        this.account = this.authService.instance.getActiveAccount();
        this.lookupService.searchUsersByType('validationassigneeusers',null,null).pipe(takeWhile(() => this.isAlive)).subscribe(data => 
            this.users = data);
        this.validationForm.disable({ emitEvent: false });
        this.ictvalidationForm.disable({ emitEvent: false });
        this.smpvalidationForm.disable({ emitEvent: false });

        this.ictvalidationForm.setValue = null;
        this.smpvalidationForm.setValue = null;

        this.isUserAdminOrValidator = this.roleService.isInAnyRole([Constants.applicableGroups.Admin,Constants.applicableGroups.Validator3GP,
            Constants.applicableGroups.Validator3GI]);

        this.route.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((params: ParamMap) => {
            const validationId = Number.parseInt(params.get('validationId'));
            this.store.dispatch(new ValidationDocumentClear());
            this.store.dispatch(new ValidationDocumentHeaderRequest(validationId));
            this.store.dispatch(new ValidationDocumentRequest(validationId));
        });

        this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((params: ParamMap) => {
            this.previousPage = params.get('previousPage');
        });

        this.validationDocument$.pipe(takeWhile(() => this.isAlive)).subscribe((validationDocument) => {
            this.validationId = validationDocument?.validation?.id;
            this.validationForm.patchValue(validationDocument.validation, { emitEvent: false });
            this.setValidationInitialForm();
            this.setFormEnabled(validationDocument.validation);
            this.ictvalidationForm.patchValue(validationDocument.validationICT, { emitEvent: false });
            this.setICTValidationInitialForm();
            this.setFormEnabled(validationDocument.validationICT);
            this.smpvalidationForm.patchValue(validationDocument.validationSMP, { emitEvent: false });
            this.setSMPValidationInitialForm();
            this.setFormEnabled(validationDocument.validationSMP);

            if(validationDocument.validationICT != null){
                this.showICT = true;
            }
            if(validationDocument.validationSMP != null){
                this.showSMP = true;
            }

            this.isSubsystemManualOverwriteallowed = (this.validationForm.value.isActive == true && this.validationForm.value.validationStatus !== 'completed')
                                                    ||(this.ictvalidationForm.value.isActive == true && this.ictvalidationForm.value.validationStatus !== 'completed') 
                                                    ||(this.smpvalidationForm.value.isActive == true && this.smpvalidationForm.value.validationStatus !== 'completed')
                                                    ? true : false ;

        });

        this.validationDocumentHeader$.pipe(takeWhile(() => this.isAlive)).subscribe((validationDocumentHeader) => {
            this.validDocHeader = cloneDeep(validationDocumentHeader);
            this.updatesubsysetmReadyForSignOff();
        });

        this.statusBackwardsUpdateEnabled = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.Validator3GI,
            Constants.applicableGroups.Validator3GP,
        ]);

        this.watchFormChanges();
        this.validationForm.markAllAsTouched();
        this.ictvalidationForm.markAllAsTouched();
        this.smpvalidationForm.markAllAsTouched();
        this.isPowerUser = (this.roleService.isInRole(Constants.applicableGroups.Admin) ||
            this.roleService.isInRole(Constants.applicableGroups.SetInactiveNPW)) ? true : false;

        this.updatedProperties$.pipe(takeWhile(() => this.isAlive)).subscribe((updatedProperties: string[]) => {
            //this.saveButtonEnabled = updatedProperties && updatedProperties.length > 0;
        });

        this.subsystemFormControl = new FormControl({ value: this.validDocHeader.subsystems, disabled: false });
    }

    notStartedButtonVisible() {
        if (
            !this.statusBackwardsUpdateEnabled &&
            (this.validationForm.value.validationStatus === this.ValidationStatuses.InProgress ||
                this.validationForm.value.validationStatus === this.ValidationStatuses.Completed)
        ) {
            return false;
        }

        return this.validationForm.value.validationStatus !== this.ValidationStatuses.NotStarted;
    }
    notStartedButtonVisibleForICT() {
        if (
            !this.statusBackwardsUpdateEnabled &&
            (this.ictvalidationForm.value.validationStatus === this.ValidationStatuses.InProgress ||
                this.ictvalidationForm.value.validationStatus === this.ValidationStatuses.Completed)
        ) {
            return false;
        }

        return this.ictvalidationForm.value.validationStatus !== this.ValidationStatuses.NotStarted;
    }

    notStartedButtonVisibleForSMP() {
        if (
            !this.statusBackwardsUpdateEnabled &&
            (this.smpvalidationForm.value.validationStatus === this.ValidationStatuses.InProgress ||
                this.smpvalidationForm.value.validationStatus === this.ValidationStatuses.Completed ||  
                this.validationForm.value.validationStatus === this.ValidationStatuses.TestPackReview)
        ) {
            return false;
        }

        return this.smpvalidationForm.value.validationStatus !== this.ValidationStatuses.NotStarted;
    }

    inProgressButtonVisible() {
        if (
            !this.statusBackwardsUpdateEnabled &&
            this.validationForm.value.validationStatus === this.ValidationStatuses.Completed
        ) {
            return false;
        }

        return this.validationForm.value.validationStatus !== this.ValidationStatuses.InProgress;
    }

    inProgressButtonVisibleForICT() {
        if (
            !this.statusBackwardsUpdateEnabled &&
            this.ictvalidationForm.value.validationStatus === this.ValidationStatuses.Completed
        ) {
            return false;
        }

        return this.ictvalidationForm.value.validationStatus !== this.ValidationStatuses.InProgress;
    }

    inProgressButtonVisibleForSMP() {
        if (
            !this.statusBackwardsUpdateEnabled &&
            (this.smpvalidationForm.value.validationStatus === this.ValidationStatuses.Completed ||  
                this.validationForm.value.validationStatus === this.ValidationStatuses.TestPackReview)
        ) {
            return false;
        }

        return this.smpvalidationForm.value.validationStatus !== this.ValidationStatuses.InProgress;
    }

    completedButtonVisible() {
        return this.validationForm.value.validationStatus !== this.ValidationStatuses.Completed;
    }

    completedButtonVisibleForICT() {
        return this.ictvalidationForm.value.validationStatus !== this.ValidationStatuses.Completed;
    }

    completedButtonVisibleForSMP() {
        return this.smpvalidationForm.value.validationStatus !== this.ValidationStatuses.Completed;
    }

    testPackReviewButtonVisibleForSMP() {
        return this.smpvalidationForm.value.validationStatus !== (this.ValidationStatuses.Completed &&
        this.ValidationStatuses.NotStarted && this.ValidationStatuses.TestPackReview);
    }

    completedButtonDiabled() {
        return (
            this.validDocHeader.isColumnsChanged ||
            !this.validationForm.valid ||
            (this.validDocHeader.changeType === ChangeType[ChangeType.PCN] && !this.validDocHeader.subsystems.length)
        );
    }

    completedButtonDiabledForICT() {
        return (
            this.validDocHeader.isColumnsChanged ||
            !this.ictvalidationForm.valid ||
            (this.validDocHeader.changeType === ChangeType[ChangeType.PCN] && !this.validDocHeader.subsystems.length)
        );
    }

    completedButtonDiabledForSMP() {
        return (
            this.validDocHeader.isColumnsChanged ||
            !this.smpvalidationForm.valid ||
            (this.validDocHeader.changeType === ChangeType[ChangeType.PCN] && !this.validDocHeader.subsystems.length)
        );
    }

    setFormEnabled(validationDocument: ValidationDocument) {
        if (validationDocument?.validationStatus === ValidStatuses.NotStarted) {
            this.isFormEnabled = this.roleService.isInAnyRole([
                Constants.applicableGroups.Admin,
                Constants.applicableGroups.Validator3GI,
                Constants.applicableGroups.Validator3GP,
            ]);
        }
        if (validationDocument?.validationStatus === ValidStatuses.InProgress) {
            this.isFormEnabled =
                this.roleService.isInAnyRole([Constants.applicableGroups.Admin]) ||
                (this.roleService.isInAnyRole([
                    Constants.applicableGroups.Validator3GP,
                    Constants.applicableGroups.Validator3GI,
                ]) &&
                    this.compareUsers(this.account, validationDocument.validator));
        }
        if (validationDocument?.validationStatus === ValidStatuses.Completed) {
            this.isFormEnabled = this.roleService.isInAnyRole([Constants.applicableGroups.Admin]);
        }

        if (this.isFormEnabled) {
            this.validationForm.enable({ emitEvent: false });
            this.ictvalidationForm.enable({ emitEvent: false });
            this.smpvalidationForm.enable({ emitEvent: false });
        }
    }

    compareUsers(account: AccountInfo, userDetail: UserDetail) {
        return (
            this.isNotNullAndNotUndefined(account) &&
            this.isNotNullAndNotUndefined(userDetail) &&
            account.username.toLowerCase() === userDetail.email.toLowerCase()
        );
    }

    compareItems(r1: any, r2: any): boolean {
        return r1 && r2 ? r1.id === r2.id : r1 === r2;
    }

    changeStatus(newStatus: ValidStatuses, type:string) {
        if (newStatus === ValidStatuses.Completed && this.validDocHeader.changeType === ChangeType[ChangeType.PCN]) {
            this.assignPCNProject();
            return;
        }

        this.store.dispatch(new ValidationUpdateStatusRequest({ id: this.validationId, newStatus , type}));
    }

    assignPCNProject() {
        if (this.validDocHeader.projectTeamWithContract.length > 1) {
            this.openAssignProjectTeamPopup();
        } else {
            this.assignProjectTeam();
        }
    }

    assignProjectTeam() {
        this.assignInProgress = true;
        var form = new AssignProjectTeamRequest();
        form.id = this.validationId;
        form.projectTeamName = this.validDocHeader.projectTeamWithContract[0].projectTeamName;
        form.contractor = this.validDocHeader.projectTeamWithContract[0].contractor;
        this.validationFormService
            .assignProjectTeam(form)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (data) => {
                    this.assignInProgress = false;
                    this.toastService.Success('Project Team and Contract successfully assigned.');
                    this.reloadProjectTeamContractorAndUpdateStatus(data);
                },
                () => {
                    this.assignInProgress = false;
                    this.toastService.Error(
                        'Error occurred while assigining Project Team and Contract. Please contact Program Administrator.'
                    );
                }
            );
    }

    reloadProjectTeamContractorAndUpdateStatus(data: ValidationDocumentHeader) {
        this.validDocHeader.projectTeamName = data.projectTeamName;
        this.validDocHeader.contractor = data.contractor;
        this.store.dispatch(
            new ValidationUpdateStatusRequest({ id: this.validationId, newStatus: ValidStatuses.Completed , type: 'all'})
        );
    }

    save() {
        this.store.dispatch(new ValidationUpdateRequest());
        this.saveButtonEnabled =false;
        this.ictsaveButtonEnabled = false;
        this.smpsaveButtonEnabled = false;
    }

    saveSystemizationRemark(remark: string) {
        //console.log('remark: ', remark)
        const id = this.validDocHeader.id;
        this.store.dispatch(new ValidationDocumentUpdateSystemizationRemarkRequest({id, remark}));
    }

    toggleSystemizationCompleted() {
        const id = this.validDocHeader.id;
        this.store.dispatch(new ValidationDocumentUpdateSystemizationCompletedRequest({id: id , validId: this.validationId}));
    }

    watchFormChanges() {
        for (const key of Object.keys(this.validationForm.controls)) {
            this.validationForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                const updatedProperties = this.getValidationUpdatedProperties();
                if (Array.isArray(value)) {
                    value = Object.assign([], value);
                }
                this.store.dispatch(new ValidationUpdateProperty({ key, value, updatedProperties }));
                if(updatedProperties && updatedProperties.length > 0){
                    this.saveButtonEnabled = true;
                }
            });
        }

        for (const key of Object.keys(this.ictvalidationForm.controls)) {
            this.ictvalidationForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                const updatedProperties = this.getICTValidationUpdatedProperties();
                if (Array.isArray(value)) {
                    value = Object.assign([], value);
                }
                this.store.dispatch(new ValidationICTUpdateProperty({ key, value, updatedProperties }));
                if(updatedProperties && updatedProperties.length > 0){
                    this.ictsaveButtonEnabled = true;
                }
            });
        }

        for (const key of Object.keys(this.smpvalidationForm.controls)) {
            this.smpvalidationForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                const updatedProperties = this.getSMPValidationUpdatedProperties();
                if (Array.isArray(value)) {
                    value = Object.assign([], value);
                }
                this.store.dispatch(new ValidationSMPUpdateProperty({ key, value, updatedProperties }));
                if(updatedProperties && updatedProperties.length > 0){
                    this.smpsaveButtonEnabled = true;
                }
            });
        }
    }


    getValidationUpdatedProperties(): string[] {
        const result = [];
        if (!this.isNotNullAndNotUndefined(this.validationInitialForm.id)) {
            return result;
        }
        for (const key of Object.keys(this.validationForm.controls)) {
            if (
                (!this.isNotNullAndNotUndefined(this.validationInitialForm[key]) ||
                    this.validationInitialForm[key] === '') &&
                (!this.isNotNullAndNotUndefined(this.validationForm.controls[key].value) ||
                    this.validationForm.controls[key].value === '')
            ) {
                continue;
            }
            if (
                JSON.stringify(this.validationInitialForm[key].value) !==
                JSON.stringify(this.validationForm.controls[key].value) && (key == "validationRemark" || key.toLocaleLowerCase() == "fieldimpact")
            ) {
                result.push(key);
            }
        }
        return result;
    }
    getICTValidationUpdatedProperties(): string[] {
        const result = [];
        if (!this.isNotNullAndNotUndefined(this.ictvalidationInitialForm.id)) {
            return result;
        }
        for (const key of Object.keys(this.ictvalidationForm.controls)) {
            if (
                (!this.isNotNullAndNotUndefined(this.ictvalidationInitialForm[key]) ||
                    this.ictvalidationInitialForm[key] === '') &&
                (!this.isNotNullAndNotUndefined(this.ictvalidationForm.controls[key].value) ||
                    this.ictvalidationForm.controls[key].value === '')
            ) {
                continue;
            }
            if (
                JSON.stringify(this.ictvalidationInitialForm[key]) !==
                JSON.stringify(this.ictvalidationForm.controls[key].value)
            ) {
                result.push(key);
            }
        }
        return result;
    }
    getSMPValidationUpdatedProperties(): string[] {
        const result = [];
        if (!this.isNotNullAndNotUndefined(this.smpvalidationInitialForm.id)) {
            return result;
        }
        for (const key of Object.keys(this.smpvalidationForm.controls)) {
            if (
                (!this.isNotNullAndNotUndefined(this.smpvalidationInitialForm[key]) ||
                    this.smpvalidationInitialForm[key] === '') &&
                (!this.isNotNullAndNotUndefined(this.smpvalidationForm.controls[key].value) ||
                    this.smpvalidationForm.controls[key].value === '')
            ) {
                continue;
            }
            if (
                JSON.stringify(this.smpvalidationInitialForm[key]) !==
                JSON.stringify(this.smpvalidationForm.controls[key].value)
            ) {
                result.push(key);
            }
        }
        return result;
    }

    setValidationInitialForm() {
        if (this.isNotNullAndNotUndefined(this.validationForm.value.id)) {
            for (const key of Object.keys(this.validationForm.controls)) {
                if(this.isNotNullAndNotUndefined(this.validationForm.controls[key].value))
                this.validationInitialForm[key] = JSON.parse(JSON.stringify(this.validationForm.controls[key].value));
            }
        }
    }
    setICTValidationInitialForm() {
        if (this.isNotNullAndNotUndefined(this.ictvalidationForm.value.id)) {
            for (const key of Object.keys(this.ictvalidationForm.controls)) {
                if(this.isNotNullAndNotUndefined(key))
                this.ictvalidationInitialForm[key] = JSON.parse(JSON.stringify(this.ictvalidationForm.controls[key].value));
            }
        }
    }
    setSMPValidationInitialForm() {
        if (this.isNotNullAndNotUndefined(this.smpvalidationForm.value.id)) {
            for (const key of Object.keys(this.smpvalidationForm.controls)) {
                if(this.isNotNullAndNotUndefined(key))
                this.smpvalidationInitialForm[key] = JSON.parse(JSON.stringify(this.smpvalidationForm.controls[key].value));
            }
        }
    }

    openHistoryPopup() {
        this.historyLoading = true;
        this.historyService
            .getHistory(this.validationId)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((history: History[]) => {
                this.historyLoading = false;
                this.popupService.openPopup(
                    new PopupSettings(HistoryPopupComponent, null, null, { history: history }, 200, 200)
                );
            });
    }

    updateSubsystemImpactedNA(value: any) {
        if (value && value.checked && this.validDocHeader.subsystems.length > 0) {
            this.validDocHeader.subsystems = null;
            this.store.dispatch(
                new ValidationDocumentSaveSubsystemsRequest({
                    id: this.validDocHeader.id,
                    subsystems: [],
                    validationId: this.validationId,
                })
            );
            this.setSubsystemInput.emit(new SetInputEventArgs(false, ''));
        }
    }

    reviewChanges() {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `By pressing Yes,
         You have confirmed that the changes to the key attributes have been actioned.`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    this.store.dispatch(new ValidationDocumentChangesReviewedRequest(this.validationId));
                }
            });
    }

    changeIsActive() {
        let popupText = this.validDocHeader.isActive
            ? `Do you want to mark this record as Inactive? `
            : `Do you want to mark this record as Active?`;
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: popupText,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    this.store.dispatch(new ValidationDocumentChangeIsActiveRequest(this.validationId));
                }
            });
    }

    formatColumnsChanged(columnsChanged: string): string {
        let result = columnsChanged.replace(/ /g, ', ');
        let removeComma = result.indexOf(', ') > -1;
        return removeComma ? result.substring(0, result.length - 2) : result;
    }

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(
            search,
            take,
            page,
            this.searchCurrentProjectSubsystems ? [this.validDocHeader.projectTeamName] : []
        );
    };

    enterEditMode() {
        this.isInEditMode = !this.isInEditMode;
        this.prevSubsystems = [...this.validDocHeader.subsystems];
        this.subsystemFormControl.setValue(this.prevSubsystems);
    }

    saveSubsystems() {
        this.isInEditMode = !this.isInEditMode;
        var prevItems = this.prevSubsystems.map((s) => s.id).sort();
        var currItems = this.subsystemFormControl.value?.map((s) => s.id).sort();
        var areTheSame =
            prevItems.length === currItems.length && currItems.every((value, index) => value === prevItems[index]);
        if (!areTheSame) {
            this.store.dispatch(
                new ValidationDocumentSaveSubsystemsRequest({
                    id: this.validDocHeader.id,
                    subsystems: this.subsystemFormControl.value,
                    validationId: this.validationId,
                })
            );
        }
    }

    cancelSubsystems() {
        this.isInEditMode = !this.isInEditMode;
        this.validDocHeader.subsystems = [...this.prevSubsystems];
    }

    parseWithCommas(el: { id: string }[]) {
        return el
            .map((s) => s.id)
            .join(', ')
            .replace(/-/g, '‑');
    }

    goToPreviousPage() {
        if (this.hasHistory) {
            this.location.back();
        } else {
            this.router.navigate(['/changevalidation']);
        }
        this.ictvalidationForm.setValue = null;
        this.smpvalidationForm.setValue = null;
    }

    toggleSearchSubsystemProjectTeam() {
        this.searchCurrentProjectSubsystems = !this.searchCurrentProjectSubsystems;
        this.setSubsystemInput.emit(new SetInputEventArgs(true));
    }

    openAssignProjectTeamPopup() {
        let dialogRef = this.popupService.openPopup(
            new PopupSettings(AssignProjectTeamPopupComponent, 450, 300, {
                validationId: this.validationId,
                projectTeamWithContract: this.validDocHeader.projectTeamWithContract,
            })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.projectTeamName) {
                    this.reloadProjectTeamContractorAndUpdateStatus(result);
                }
            });
    }

    goToEb(documentNo: string) {
        let url = Constants.baseEbUrl.replace('{docNo}', documentNo);
        window.open(url, '_blank');
    }

    downloadAttachment(attachment: CallOffAttachment) {
        this.store.dispatch(new DownloadCallOffAttachmentRequest({ attachment, type: this.validDocHeader.changeType }));
    }

    toggleisActive(id:number , discipline:string, isactive:boolean){
        this.store.dispatch(new ValidationDocumentUpdateIsActiveForDisciplineRequest({id,discipline}));
    }

    getUsers = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchUsers(search, page, take);
    };

    updateValidationAssignee(change: MatSelectChange, id: number) {
        this.store.dispatch(new ValidationUpdateRequest());
    }

    subsysetmReadyForSignOff(){
        this.updatesubsysetmReadyForSignOff();
        let dialogRef = this.popupService.openPopup(
            new PopupSettings(SubsystemReadyForSignOffPopupComponent, 500, 300, {
                id: this.validDocHeader.id,
                subsystems: this.testPackRequestedSubsystems ,
                validid: this.validationId
            })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.validDocHeader) {
                    this.validDocHeader = result.validDocHeader;
                    this.updatesubsysetmReadyForSignOff();
                }
            });

    }

    updatesubsysetmReadyForSignOff(){
        let subs = this.validDocHeader.subsystems.length > 0 ? this.validDocHeader.subsystems.map(item => item.id) : 
                    this.validDocHeader.suggestedSubsystems
                    .split(',')
                    .map(s => s.trim())
                    .filter((s) => s !== '');

        let subsCompleted = this.validDocHeader.testPackCompletedSubsystems
        .split(',')
        .map(s => s.trim())
        .filter((s) => s !== '');

        this.testPackRequestedSubsystems = subs.filter(s => !subsCompleted.find(sc => sc === s))
    }
}
