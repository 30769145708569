import { Injectable } from '@angular/core';
import { ApplicationState } from '../model';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ChangeManagementService } from 'src/app/services/api/webapi-services/change-management.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import {
    FieldChangeSummaryRequest,
    FieldChangeSummaryActionType,
    FieldChangeSummarySuccess,
    FieldChangeSummaryError,
} from './actions';

@Injectable()
export class FieldChangeDocumentSummaryEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private changeManagementService: ChangeManagementService,
        private toastService: ToastService
    ) {}

    @Effect()
    filterRequest$ = this.actions$.pipe(
        ofType<FieldChangeSummaryRequest>(FieldChangeSummaryActionType.FieldChangeSummaryRequest),
        mergeMap((action) => {
            return this.changeManagementService.getChangeManagementSummaryForSubsystem(action.payload).pipe(
                map((dataPagination) => {
                    return new FieldChangeSummarySuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Change management data. Please contact Program Administrator.'
                    );
                    return of(new FieldChangeSummaryError(error));
                })
            );
        })
    );
}
