<div class="header-container">
    <div class="title-container">
        Available Subsystems
    </div>
    <div class="space-container"></div>
    <div class="title-container">
        Selected Subsystems
    </div>
</div>
<div class="selector-container">
    <div class="table-container">
        <div class="filter-type-container">
            <span [ngClass]="{ active: isAvailableGOOCFilter }" (click)="changeFilter('gooc')">GOOC</span>
            <span [ngClass]="{ active: isAvailableRFOFilter }" (click)="changeFilter('rfo')">RFO</span>
            <span [ngClass]="{ active: isAvailableSubsystemFilter }" (click)="changeFilter('subsystem')"
            >Subsystem</span
            >
            <span [ngClass]="{ active: isAvailablePriorityFilter }" (click)="changeFilter('priority')">Start-Up Priority</span>
        </div>
        <form [formGroup]="subsystemSearchFilterForm">
            <app-multiple-items-selector
                *ngIf="isAvailableGOOCFilter"
                class="container480"
                formControlName="goocs"
                [searchFunc]="getGoocs"
                [isAsync]="true"
                [isTableAutoComplete]="true"
                [displayedColumns]="goocAutocompleteDisplayedColumns"
                [propertyToShow]="'no'"
                [placeholder]="'Search...'"
                [panelWidth]="480"
                [appearance]="'outline'"
                (autocompleteClosed)="applyAvailableSubsystemFilters()"
                (itemRemoved)="applyAvailableSubsystemFilters()"
                [ngClass]="{ hidesearchph: subsystemSearchFilterForm.controls.goocs.value.length > 0 }"
            >
            </app-multiple-items-selector>
            <app-multiple-items-selector
                *ngIf="isAvailableRFOFilter"
                class="container480"
                formControlName="rfo"
                [searchFunc]="getRFOs"
                [isAsync]="true"
                [isTableAutoComplete]="true"
                [displayedColumns]="['name', 'rfoName']"
                [propertyToShow]="'name'"
                [secondPropertyToShow]="'rfoName'"
                [placeholder]="'Search...'"
                [panelWidth]="480"
                [appearance]="'outline'"
                (autocompleteClosed)="applyAvailableSubsystemFilters()"
                (itemRemoved)="applyAvailableSubsystemFilters()"
                [ngClass]="{ hidesearchph: subsystemSearchFilterForm.controls.rfo.value.length > 0 }"
            >
            </app-multiple-items-selector>

            <app-multiple-items-selector
                *ngIf="isAvailablePriorityFilter"
                class="container480"
                formControlName="priority"
                [searchFunc]="getPriorities"
                [isAsync]="true"
                [isTableAutoComplete]="true"
                [displayedColumns]="['priority', 'priorityName']"
                [propertyToShow]="'priority'"
                [secondPropertyToShow]="'priorityName'"
                [placeholder]="'Search...'"
                [panelWidth]="480"
                [appearance]="'outline'"
                (autocompleteClosed)="applyAvailableSubsystemFilters()"
                (itemRemoved)="applyAvailableSubsystemFilters()"
                [ngClass]="{ hidesearchph: subsystemSearchFilterForm.controls.priority.value.length > 0 }"
            >
            </app-multiple-items-selector>

            <mat-label class="filter-label" *ngIf="!isAvailableSubsystemFilter">Subsystem</mat-label>
            <input matInput autocomplete="off" [placeholder]="'Search...'" formControlName="subsystem" />

            <mat-checkbox (change)="triggerCheckAllAvailableSubsystems()" [checked]="areAllAvailableSubsystemsChecked"
                >Select all
            </mat-checkbox>
        </form>

        <mat-table #table [dataSource]="availableSubsystems" (scroll)="onTableScroll($event)">
            <ng-container matColumnDef="selected">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-checkbox (change)="onSelect(element)" [checked]="element.Selected"></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="subsystem">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="subsystemName">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div *ngIf="isLoading" class="selector-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="button-container">
        <button (click)="moveSelected()" type="button" mat-button [disabled]="itemsSelected.content.length == 0">
            add &gt;
        </button>
        <button (click)="reverseSelected()" type="button" mat-button [disabled]="itemsSelectedFromSubsystemSelected.content.length == 0">
            &lt; remove
        </button>
    </div>
    <div class="table-container">
        <div class="filter-type-container">
            <span [ngClass]="{ active: isSelectedGOOCFilter }" (click)="changeFilterSubsystemSelected('gooc')"
                >GOOC</span
            >
            <span [ngClass]="{ active: isSelectedRFOFilter }" (click)="changeFilterSubsystemSelected('rfo')">RFO</span>
            <span [ngClass]="{ active: isSelectedSubsystemFilter }" (click)="changeFilterSubsystemSelected('subsystem')"
                >Subsystem</span
            >
            <span [ngClass]="{ active: isSelectedPriorityFilter }" (click)="changeFilterSubsystemSelected('priority')">Start-Up Priority</span>
        </div>

        <form [formGroup]="subsystemSelectedSearchFilterForm">
            <app-multiple-items-selector
                *ngIf="isSelectedGOOCFilter"
                class="container480"
                formControlName="goocs"
                [searchFunc]="getGoocs"
                [isAsync]="true"
                [isTableAutoComplete]="true"
                [displayedColumns]="goocAutocompleteDisplayedColumns"
                [propertyToShow]="'no'"
                [placeholder]="'Search...'"
                [panelWidth]="480"
                [appearance]="'outline'"
                (autocompleteClosed)="applySubsystemSelectedFilters()"
                (itemRemoved)="applySubsystemSelectedFilters()"
                [ngClass]="{ hidesearchph: subsystemSelectedSearchFilterForm.controls.goocs.value.length > 0 }"
            >
            </app-multiple-items-selector>
            <app-multiple-items-selector
                *ngIf="isSelectedRFOFilter"
                class="container480"
                formControlName="rfo"
                [searchFunc]="getRFOs"
                [isAsync]="true"
                [isTableAutoComplete]="true"
                [displayedColumns]="['name', 'rfoName']"
                [propertyToShow]="'name'"
                [secondPropertyToShow]="'rfoName'"
                [placeholder]="'Search...'"
                [panelWidth]="480"
                [appearance]="'outline'"
                (autocompleteClosed)="applySubsystemSelectedFilters()"
                (itemRemoved)="applySubsystemSelectedFilters()"
                [ngClass]="{ hidesearchph: subsystemSelectedSearchFilterForm.controls.rfo.value.length > 0 }"
            >
            </app-multiple-items-selector>
            <app-multiple-items-selector
                *ngIf="isSelectedPriorityFilter"
                class="container480"
                formControlName="priority"
                [searchFunc]="getPriorities"
                [isAsync]="true"
                [isTableAutoComplete]="true"
                [displayedColumns]="['priority', 'priorityName']"
                [propertyToShow]="'priority'"
                [secondPropertyToShow]="'priorityName'"
                [placeholder]="'Search...'"
                [panelWidth]="480"
                [appearance]="'outline'"
                (autocompleteClosed)="applySubsystemSelectedFilters()"
                (itemRemoved)="applySubsystemSelectedFilters()"
                [ngClass]="{ hidesearchph: subsystemSelectedSearchFilterForm.controls.priority.value.length > 0 }"
            >
            </app-multiple-items-selector>

            <mat-label class="filter-label" *ngIf="!isSelectedSubsystemFilter">Subsystem</mat-label>
            <input matInput autocomplete="off" [placeholder]="'Search...'" formControlName="subsystem" />
        </form>

        <mat-checkbox (change)="triggerCheckAllSelectedSubsystems()" [checked]="areAllSelectedSubsystemsChecked">
            Select all
        </mat-checkbox>

        <mat-table #table [dataSource]="selectedSubsystems" (scroll)="onTableScrollSelected($event)">
            <ng-container matColumnDef="selected">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-checkbox
                        (change)="onSelectSubsystemSelected(element)"
                        [checked]="element.Selected"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="subsystem">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="subsystemName">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <div *ngIf="isLoadingSelected" class="selector-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <mat-divider class="divider-space"></mat-divider>
</div>
<div *ngIf="showButtons" class="buttons-container flex center">
    <button type="button" (click)="close()" class="sct-button sct-button-small sct-button-white margin-right20">
        cancel
    </button>
    <button type="button" (click)="onSubmit()" class="sct-button sct-button-small sct-button-light ok-button">
        ok
    </button>
</div>
