import {
    Component,
    Input,
    Output,
    ViewEncapsulation,
    EventEmitter,
    ChangeDetectionStrategy,
    ContentChild,
    TemplateRef,
} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { scaleBand, scaleLinear } from 'd3-scale';
import { BaseChartComponent, ViewDimensions, ColorHelper, calculateViewDimensions, ScaleType } from '@swimlane/ngx-charts';

// https://github.com/swimlane/ngx-charts/blob/docs-test/src/bar-chart/bar-vertical-2d.component.ts
// https://github.com/swimlane/ngx-charts/blob/docs-test/src/bar-chart/bar-vertical-stacked.component.ts

@Component({
    selector: 'ngx-charts-bar-vertical-2d-stacked',
    template: `
        <ngx-charts-chart
            [view]="[width, height]"
            [showLegend]="legend"
            [legendOptions]="legendOptions"
            [animations]="animations"
            (legendLabelClick)="onClick($event)"
        >
            <svg:g [attr.transform]="transform" class="bar-chart chart">
                <svg:g
                    ngx-charts-grid-panel-series
                    [xScale]="groupScale"
                    [yScale]="valueScale"
                    [data]="results"
                    [dims]="dims"
                    orient="vertical"
                ></svg:g>
                <svg:g
                    ngx-charts-x-axis
                    *ngIf="xAxis"
                    [xScale]="groupScale"
                    [dims]="dims"
                    [showLabel]="showXAxisLabel"
                    [labelText]="xAxisLabel"
                    [tickFormatting]="xAxisTickFormatting"
                    [ticks]="xAxisTicks"
                    [xAxisOffset]="dataLabelMaxHeight.negative"
                    (dimensionsChanged)="updateXAxisHeight($event)"
                ></svg:g>
                <svg:g
                    ngx-charts-y-axis
                    *ngIf="yAxis"
                    [yScale]="yScale"
                    [dims]="dims"
                    [showGridLines]="showGridLines"
                    [showLabel]="showYAxisLabel"
                    [labelText]="yAxisLabel"
                    [tickFormatting]="yAxisTickFormatting"
                    [ticks]="yAxisTicks"
                    (dimensionsChanged)="updateYAxisWidth($event)"
                ></svg:g>
                <svg:g
                    *ngFor="let group of results; let index = index; trackBy: trackBy"
                    [@animationState]="'active'"
                    [attr.transform]="groupTransform(group)"
                >
                    <svg:g
                        *ngFor="let grp of group.series; let index = index; trackBy: trackBy"
                        [@animationState]="'active'"
                        [attr.transform]="groupStackedTransform(grp)"
                    >
                        <svg:g
                            ngx-charts-series-vertical
                            type="stacked"
                            [xScale]="xScale"
                            [yScale]="yScale"
                            [activeEntries]="activeEntries"
                            [colors]="colors"
                            [series]="grp.series"
                            [dims]="dimsStacks"
                            [gradient]="gradient"
                            [tooltipDisabled]="tooltipDisabled"
                            [tooltipTemplate]="tooltipTemplate"
                            [showDataLabel]="showDataLabel"
                            [dataLabelFormatting]="dataLabelFormatting"
                            [seriesName]="grp.name"
                            [animations]="animations"
                            (select)="onClick($event, grp)"
                            (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)"
                        />
                    </svg:g>
                </svg:g>
            </svg:g>
        </ngx-charts-chart>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('animationState', [
            transition(':leave', [
                style({
                    opacity: 1,
                    transform: '*',
                }),
                animate(500, style({ opacity: 0, transform: 'scale(0)' })),
            ]),
        ]),
    ],
})
export class GroupVerticalStackedComponent extends BaseChartComponent {
    @Input() legend = false;
    @Input() legendTitle: string = 'Legend';
    @Input() xAxis;
    @Input() yAxis;
    @Input() showXAxisLabel;
    @Input() showYAxisLabel;
    @Input() xAxisLabel;
    @Input() yAxisLabel;
    @Input() tooltipDisabled: boolean = false;
    @Input() gradient: boolean;
    @Input() showGridLines: boolean = true;
    @Input() activeEntries: any[] = [];
    @Input() schemeType: ScaleType;
    @Input() xAxisTickFormatting: any;
    @Input() yAxisTickFormatting: any;
    @Input() xAxisTicks: any[];
    @Input() yAxisTicks: any[];
    @Input() groupPadding = 16;
    @Input() barPadding = 8;
    @Input() roundDomains: boolean = false;
    @Input() roundEdges: boolean = true;
    @Input() yScaleMax: number;
    @Input() showDataLabel: boolean = false;
    @Input() dataLabelFormatting: any;

    @Output() activate: EventEmitter<any> = new EventEmitter();
    @Output() deactivate: EventEmitter<any> = new EventEmitter();

    @ContentChild('tooltipTemplate', { static: true }) tooltipTemplate: TemplateRef<any>;

    dims: ViewDimensions;
    dimsStacks: ViewDimensions;
    groupDomain: any[];
    innerDomain: any[];
    valuesDomain: any[];
    groupScale: any;
    innerScale: any;
    valueScale: any;
    transform: string;
    colors: ColorHelper;
    margin = [10, 20, 10, 20];
    xAxisHeight: number = 0;
    yAxisWidth: number = 0;
    legendOptions: any;
    dataLabelMaxHeight: any = { negative: 0, positive: 0 };
    xScale: any;
    yScale: any;
    stackedGroupDomain: any;
    stackedValueDomain: any;

    update(): void {
        super.update();

        if (!this.showDataLabel) {
            this.dataLabelMaxHeight = { negative: 0, positive: 0 };
        }
        this.margin = [10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];

        this.dims = calculateViewDimensions({
            width: this.width,
            height: this.height,
            margins: this.margin,
            showXAxis: this.xAxis,
            showYAxis: this.yAxis,
            xAxisHeight: this.xAxisHeight,
            yAxisWidth: this.yAxisWidth,
            showXLabel: this.showXAxisLabel,
            showYLabel: this.showYAxisLabel,
            showLegend: this.legend,
            legendType: this.schemeType,
        });

        this.dimsStacks = calculateViewDimensions({
            width: this.width / 10,
            height: this.height,
            margins: this.margin,
            showXAxis: this.xAxis,
            showYAxis: this.yAxis,
            xAxisHeight: this.xAxisHeight,
            yAxisWidth: this.yAxisWidth,
            showXLabel: this.showXAxisLabel,
            showYLabel: this.showYAxisLabel,
            showLegend: this.legend,
            legendType: this.schemeType,
        });

        if (this.showDataLabel) {
            this.dims.height -= this.dataLabelMaxHeight.negative;
        }

        this.formatDates();

        this.groupDomain = this.getGroupDomain();
        this.innerDomain = this.getInnerDomain();
        this.valuesDomain = this.getValueDomain();

        this.stackedGroupDomain = this.getStackedGroupDomain();
        this.stackedValueDomain = this.getStackedValueDomain();

        this.groupScale = this.getGroupScale();
        this.innerScale = this.getInnerScale();
        this.valueScale = this.getValueScale();

        this.xScale = this.getXScale();
        this.yScale = this.getYScale();

        this.setColors();
        this.legendOptions = this.getLegendOptions();

        this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
    }

    onDataLabelMaxHeightChanged(event, groupIndex) {
        if (event.size.negative) {
            this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
        } else {
            this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
        }
        if (groupIndex === this.results.length - 1) {
            setTimeout(() => this.update());
        }
    }

    getGroupScale(): any {
        const spacing = this.groupDomain.length / (this.dims.height / this.groupPadding + 1);

        return scaleBand()
            .rangeRound([0, this.dims.width])
            .paddingInner(spacing)
            .paddingOuter(spacing / 2)
            .domain(this.groupDomain);
    }

    getInnerScale(): any {
        const width = this.groupScale.bandwidth();
        const spacing = this.innerDomain.length / (width / this.barPadding + 1);
        return scaleBand().rangeRound([0, width]).paddingInner(spacing).domain(this.innerDomain);
    }

    getValueScale(): any {
        const scale = scaleLinear().range([this.dims.height, 0]).domain(this.valuesDomain);
        return this.roundDomains ? scale.nice() : scale;
    }

    getGroupDomain() {
        const domain = [];
        for (const group of this.results) {
            if (!domain.includes(group.name)) {
                domain.push(group.name);
            }
        }

        return domain;
    }

    getInnerDomain() {
        const domain = [];
        for (const group of this.results) {
            for (const d of group.series) {
                if (!domain.includes(d.name)) {
                    domain.push(d.name);
                }
            }
        }

        return domain;
    }

    getValueDomain() {
        const domain = [];
        for (const group of this.results) {
            for (const d of group.series) {
                if (!domain.includes(d.value)) {
                    domain.push(d.value);
                }
            }
        }

        const min = Math.min(0, ...domain);
        const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);

        return [min, max];
    }

    groupTransform(group) {
        return `translate(${this.groupScale(group.name)}, 0)`;
    }

    groupStackedTransform(group) {
        return `translate(${this.xScale(group.name)}, 0)`;
    }

    onClick(data, group?) {
        if (group) {
            data.series = group.name;
        }

        this.select.emit(data);
    }

    trackBy(index, item) {
        return item.name;
    }

    setColors(): void {
        let domain = Array.from<string>(
            new Set(
                this.results
                    .map((x) => x.series)
                    .flat()
                    .map((x) => x.series)
                    .flat()
                    .map((x) => x.name)
            )
        );

        this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    }

    getLegendOptions() {
        const opts = {
            scaleType: this.schemeType,
            colors: this.colors,
            domain: this.innerDomain,
            title: this.legendTitle,
        };

        return opts;
    }

    updateYAxisWidth({ width }) {
        this.yAxisWidth = width;
        this.update();
    }

    updateXAxisHeight({ height }) {
        this.xAxisHeight = height;
        this.update();
    }

    onActivate(event, group?) {
        const item = Object.assign({}, event);
        if (group) {
            item.series = group.name;
        }

        const idx = this.activeEntries.findIndex((d) => {
            return d.name === item.name && d.value === item.value && d.series === item.series;
        });
        if (idx > -1) {
            return;
        }

        this.activeEntries = [item, ...this.activeEntries];
        this.activate.emit({ value: item, entries: this.activeEntries });
    }

    onDeactivate(event, group?) {
        const item = Object.assign({}, event);
        if (group) {
            item.series = group.name;
        }

        const idx = this.activeEntries.findIndex((d) => {
            return d.name === item.name && d.value === item.value && d.series === item.series;
        });

        this.activeEntries.splice(idx, 1);
        this.activeEntries = [...this.activeEntries];

        this.deactivate.emit({ value: item, entries: this.activeEntries });
    }

    getXScale(): any {
        const spacing = this.stackedGroupDomain.length / (this.dimsStacks.width / this.barPadding + 1);
        return scaleBand().rangeRound([0, this.dimsStacks.width]).paddingInner(spacing).domain(this.stackedGroupDomain);
    }

    getYScale(): any {
        const scale = scaleLinear().range([this.dimsStacks.height, 0]).domain(this.stackedValueDomain);
        return this.roundDomains ? scale.nice() : scale;
    }

    getStackedGroupDomain() {
        const domain = [];
        for (const group of this.results) {
            for (const grp of group.series) {
                if (!domain.includes(grp.name)) {
                    domain.push(grp.name);
                }
            }
        }
        return domain;
    }

    getStackedValueDomain() {
        const domain = [];
        let smallest = 0;
        let biggest = 0;
        for (const group of this.results) {
            for (const d of group.series) {
                let smallestSum = 0;
                let biggestSum = 0;
                for (const dd of d.series) {
                    if (dd.value < 0) {
                        smallestSum += dd.value;
                    } else {
                        biggestSum += dd.value;
                    }
                    smallest = dd.value < smallest ? dd.value : smallest;
                    biggest = dd.value > biggest ? dd.value : biggest;
                }
                domain.push(smallestSum);
                domain.push(biggestSum);
            }
            domain.push(smallest);
            domain.push(biggest);
        }

        const min = Math.min(0, ...domain);
        const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
        return [min, max];
    }
}
