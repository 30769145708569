import { Component, Input, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { Config } from 'src/app/models/config';
import { ConfigurationService } from 'src/app/services/api/webapi-services/configuration.service';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
  selector: 'app-cleanliness-configuration',
  templateUrl: './cleanliness-configuration.component.html',
  styleUrls: ['./cleanliness-configuration.component.scss']
})
export class CleanlinessConfigurationComponent extends BaseComponent implements OnInit {
  @Input() cleanlinessType: string;

  cleanlinessConfigData: Config[];
  displayedColumns = ['key', 'value', 'edit'];
  isLoading = false;

  constructor(private toastService: ToastService,
    private configurationService: ConfigurationService) {
    super();
  }

  ngOnInit(): void {
    this.configurationService.getCleanlinessConfigData(this.cleanlinessType)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((configValues) => {
        this.cleanlinessConfigData = configValues
      });
  }

  enterEditMode(config: Config) {
    config.isInEditMode = !config.isInEditMode;
    config.prevValue = config.value;
  }

  exitEditMode(config: Config) {
    config.isInEditMode = !config.isInEditMode;
  }

  cancelEdit(config: Config) {
    config.isInEditMode = !config.isInEditMode;
    config.value = config.prevValue;
  }

  save(config: Config) {
    if (config.value === null) {
      this.toastService.Error('Please make sure the row contains correct value');
      return;
    }
    config.isInEditMode = !config.isInEditMode;

    if (config.value !== config.prevValue) {
      this.updateConfigData();
    }
  }

  updateConfigData() {
    this.isLoading = true;

    this.configurationService
        .updateConfigData(this.cleanlinessConfigData)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe(
            () => {
                this.isLoading = false;
                this.toastService.Success('Configuration has been updated.');
            },
            () => {
                this.isLoading = false;
                this.toastService.Error(
                    'An error occured while updating configuration. Configuration has not been updated.'
                );
            }
        );
  }

  GetDisplayName(configKey: string) {
    return configKey.replace(`${this.cleanlinessType} Cleanliness `, '');
  }

}
