import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/api/webapi-services/admin-change-document.service';
import { ManualUploadService } from 'src/app/services/api/webapi-services/manual-upload.service';
import {
    LoopActionTypes,
    LoopUploadLogSuccess,
    LoopUploadLogError,
    LoopSetStatusAndStartDate,
    LoopClearInProgressSpinner,
    LoopDataInfoSuccess,
    LoopDataInfoError,
    LoopDataInfoRequest,
    LoopDownloadOutputDataRequest,
    LoopDownloadOutputDataSuccess,
    LoopDownloadOutputDataError,
    LoopValidateDeltaSuccess,
    LoopValidateDeltaError,
    LoopTemplateFileRequest,
    LoopTemplateFileSuccess,
    LoopTemplateFileError,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class LoopEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private manualUploadsService: ManualUploadService,
        private adminPanelService: AdminPanelService,
        private toastService: ToastService
    ) {}

    @Effect()
    getUploadLog$ = this.actions$.pipe(
        ofType(LoopActionTypes.LoopUploadLogRequest),
        mergeMap(() =>
            this.adminPanelService.getLogs(['Loop']).pipe(
                map((uploadLogData) => new LoopUploadLogSuccess(uploadLogData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting logs for Loop. Please contact Program Administrator.'
                    );
                    return of(new LoopUploadLogError(error));
                })
            )
        )
    );

    @Effect()
    setStatusAndStartDate$ = this.actions$.pipe(
        ofType<LoopUploadLogSuccess>(LoopActionTypes.LoopUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.loopState.uploadLogStatus),
            this.store.select((store) => store.loopState.uploadLogStartDate)
        ),
        filter(
            ([{ payload }, status, startDate]) =>
                payload[0] && (status !== payload[0].status || startDate !== payload[0].startDate)
        ),
        map(
            ([{ payload }]) =>
                new LoopSetStatusAndStartDate({ status: payload[0].status, startDate: payload[0].startDate })
        )
    );

    @Effect()
    downloadOutputData$ = this.actions$.pipe(
        ofType<LoopDownloadOutputDataRequest>(LoopActionTypes.LoopDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new LoopDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting output Result for Loop. Please contact Program Administrator.'
                    );
                    return of(new LoopDownloadOutputDataError(error));
                })
            )
        )
    );

    @Effect()
    validateDelta$ = this.actions$.pipe(
        ofType(LoopActionTypes.LoopValidateDeltaRequest),
        mergeMap(() =>
            this.manualUploadsService.validateLoopDelta().pipe(
                map(() => new LoopValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while validating and pushing Loop. Please contact Program Administrator.'
                    );
                    return of(new LoopValidateDeltaError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveOutputData$ = this.actions$.pipe(
        ofType<LoopDownloadOutputDataSuccess>(LoopActionTypes.LoopDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    );

    @Effect()
    clearInProgressSpinner$ = this.actions$.pipe(
        ofType(LoopActionTypes.LoopSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.loopState.uploadLogData)),
        filter(([, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(() => [new LoopClearInProgressSpinner(), new LoopDataInfoRequest()])
    );

    @Effect()
    downloadTemplate$ = this.actions$.pipe(
        ofType<LoopTemplateFileRequest>(LoopActionTypes.LoopTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new LoopTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting template for Loop. Please contact Program Administrator.'
                    );
                    return of(new LoopTemplateFileError(error));
                })
            )
        )
    );

    @Effect({ dispatch: false })
    saveTemplate$ = this.actions$.pipe(
        ofType<LoopTemplateFileSuccess>(LoopActionTypes.LoopTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'MASTER SWIFT.xlsx');
        })
    );

    @Effect()
    setValidateButtonEnabled$ = this.actions$.pipe(
        ofType(LoopActionTypes.LoopDataInfoRequest),
        mergeMap(() =>
            this.adminPanelService.getDataInfo(['Loop']).pipe(
                map((dataInfo: any) => new LoopDataInfoSuccess(dataInfo)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting data info for Loop. Please contact Program Administrator.'
                    );
                    return of(new LoopDataInfoError(error));
                })
            )
        )
    );
}
