import { Injectable } from '@angular/core';
import { LoopStatusFilter, LoopStatusDataPagination } from 'src/app/store/loop-status/model';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class LoopStatusService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/loopstatus';
    constructor(private http: HttpClient) {}

    searchTag(filter: LoopStatusFilter) {
        return this.http.post<LoopStatusDataPagination>(`${this.rootUrl}${this.endpoint}`, filter);
    }

    generateExcel(filter: LoopStatusFilter) {
        return this.http.post(`${this.rootUrl}${this.endpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

updateLoop(payload: { loopName: string; tagNumber: string; tagType: string; value: string; updateKey: string ;/*activiyStartDate: string ;mcActual:string*/}) {
        let params = new HttpParams();
        params = params.append('loopName', payload.loopName);
        params = params.append('tagNumber', payload.tagNumber);
        params = params.append('tagType', payload.tagType);
        params = params.append('value', payload.value);
        params = params.append('key', payload.updateKey);
        // params = params.append('activitystartDate', payload.activiyStartDate)
        // params = params.append('mcActual', payload.mcActual)
        return this.http.post<LoopStatusDataPagination>(`${this.rootUrl}${this.endpoint}/update`, {}, { params });
    }
}
