import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { CalendarColumn, CheckListColumn, OrderDirection, UserDetail } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import { PascrStatusExportToExcelRequest, PascrStatusFilterPropertyUpdate, PascrStatusFilterRequest, PascrStatusFilterReset, PascrStatusAddBulkCommentRequest, PascrStatusAddCommentRequest, PascrStatusRemoveCommentRequest } from 'src/app/store/pascr-status/actions';
import { PascrStatusDTO, PascrStatusFilter, ShowHideColumns } from 'src/app/store/pascr-status/model';
import { BaseComponent } from '../base.component';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { CommentsHistoryComponent } from '../comments-history/comments-history.component';
import { CommentPopupComponent } from '../../modules/shared/components/comment-popup/comment-popup.component';
import { SubsystemScope } from 'src/app/enums';
import { Constants } from 'src/app/constants';
import { CommentDetailDTO } from 'src/app/store/detailed-status/model';


@Component({
    selector: 'pascr-status',
    templateUrl: './pascr-status.component.html',
    styleUrls: ['./pascr-status.component.scss'],
})
export class PASCRComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    isLoading = false;
    data: PascrStatusDTO[] = [];
    resultsLength = 0;
    pageSize = 25;
    pascrStatusGridData$ = this.store.select((state) => state.pascrStatusState.dataPagination);
    pascrStatusGridLoader$ = this.store.select((state) => state.pascrStatusState.isLoading);
    pascrStatusFilter$ = this.store.select((state) => state.pascrStatusState.filter);
    sortBy$ = this.store.select((state) => state.pascrStatusState.filter.sortBy);
    sortDirection$ = this.store.select((state) => state.pascrStatusState.filter.direction);
    columnComments$ = this.store.select((state) => state.pascrStatusState.filter.columnComments);
    showHideColumns = new ShowHideColumns();
    columnPASCRNumber: CheckListColumn = null;
    columnDescription: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnSubSystem: CheckListColumn = null;
    columnRfo: CheckListColumn = null;
    columnApprovalStage: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnCRChangeType: CheckListColumn = null;
    columnOriginatorDate: CalendarColumn = null;
    columnTargetCompletionDate: CalendarColumn = null;
    columnRFI_RIMSRef: CheckListColumn = null;
    columnMOCRef: CheckListColumn = null;
    columnTagNo: CheckListColumn = null;
    subSystem: string;
    rfo: string;
    allowCommentsBulkUpdate = false;
    columnComments: CheckListColumn = null;

    sortBy = '';
    direction: OrderDirection = OrderDirection.Desc;
    isReadOnly = false;
    displayedColumns = [
        'pascrNumber',
        'description',
        'subSystem',
        'tagType',
        'approvalStage',
        'status',
        'crChangeType',
        'originatorDate',
        'targetCompletionDate',
        'rfi_RIMSRef',
        'mocRef',
        'tagNo',
        'comment'
    ];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @Input() blueScreen: boolean = false;
    @Input() blueSubsystem: string;
    @Input() blueRFO: string;

    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private route: ActivatedRoute,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private commentService: CommentService,
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );

        this.filterForm = this.formSvc.createSimpleForm(new PascrStatusFilter());
        

    }

    ngOnInit() {
        this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {

            if(this.blueScreen) {
                this.subSystem = this.blueSubsystem ? this.blueSubsystem : null;
                this.rfo = this.blueRFO ? this.blueRFO : null;
            } else {
                this.subSystem = paramMap.get('SubSystem');
                this.rfo = paramMap.get('RFO');
            }

            if(this.subSystem != null || this.rfo !=null)
            {
                this.resetFilters();
                let pascrFilter = new CheckListColumn();
                pascrFilter.items = this.subSystem !=null ? [this.subSystem] : [this.rfo];
                this.subSystem !=null ? this.columnSubSystem = pascrFilter : this.columnRfo = pascrFilter;
                this.subSystem !=null ? this.filterForm.controls.columnSubSystem.setValue(pascrFilter) : this.filterForm.controls.columnRfo.setValue(pascrFilter);  
                this.paginator.pageIndex = 0;   
              //this.filterForm.controls['columnSubSystem'].setValue(this.subSystem)
              this.search()
            }

            if (paramMap.get('PASCRNumber')) {
                this.resetFilters();
                let pascrFilter = new CheckListColumn();
                pascrFilter.items = [decodeURIComponent(paramMap.get('PASCRNumber'))];
                this.columnPASCRNumber = pascrFilter;
                this.filterForm.controls.columnLoops.setValue(pascrFilter);
                this.paginator.pageIndex = 0;
                this.store.dispatch(
                    new PascrStatusFilterPropertyUpdate({
                        key: 'page',
                        value: 0,
                    })
                );
                this.store.dispatch(new PascrStatusFilterRequest(this.filterForm.value));
            } else if (this.resultsLength < this.pageSize) {
                this.store.dispatch(new PascrStatusFilterRequest());
            }
        });
        this.isReadOnly = this.roleService.isReadOnly();
        this.pascrStatusGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items.map((item) => ({ ...item }));
            this.resultsLength = data.totalCount;
        });

        this.pascrStatusFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.paginator.pageIndex = filter.page;
            this.showHideColumns = filter.showHideColumns;
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => {
            this.sortBy = sortBy;
        });

        this.sortDirection$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => {
            this.direction = direction;
        });

        this.pascrStatusGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });
        this.registerColumnHeaderFilters();
    }

    private getLatestFilterData(): PascrStatusFilter {
        let filter: PascrStatusFilter;
        this.store.pipe(select((x) => x.pascrStatusState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    resetFilters() {
        this.store.dispatch(new PascrStatusFilterReset());
        this.filterForm.setValue(new PascrStatusFilter());
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
        this.store.dispatch(
            new PascrStatusFilterPropertyUpdate({
                key: propertyName,
                value: [],
            })
        );
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (key !== 'sortBy' && key !== 'direction') {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new PascrStatusFilterPropertyUpdate({ key, value }));
            }
        }
    }

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    search() {
        this.updateFilterByFormProperties();
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new PascrStatusFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(
            new PascrStatusFilterPropertyUpdate({
                key: 'take',
                value: this.pageSize,
            })
        );
        this.store.dispatch(new PascrStatusFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new PascrStatusFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new PascrStatusFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new PascrStatusFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    private registerColumnHeaderFilters() {
        combineLatest([
            this.store.select((state) => state.pascrStatusState.filter.columnPASCRNumber),
            this.store.select((state) => state.pascrStatusState.filter.columnDescription),
            this.store.select((state) => state.pascrStatusState.filter.columnTagType),
            this.store.select((state) => state.pascrStatusState.filter.columnSubSystem),
            this.store.select((state) => state.pascrStatusState.filter.columnApprovalStage),
            this.store.select((state) => state.pascrStatusState.filter.columnStatus),
            this.store.select((state) => state.pascrStatusState.filter.columnCRChangeType),
            this.store.select((state) => state.pascrStatusState.filter.columnOriginatorDate),
            this.store.select((state) => state.pascrStatusState.filter.columnTargetCompletionDate),
            this.store.select((state) => state.pascrStatusState.filter.columnRFI_RIMSRef),
            this.store.select((state) => state.pascrStatusState.filter.columnMOCRef),
            this.store.select((state) => state.pascrStatusState.filter.columnTagNo),
            this.store.select((state) => state.pascrStatusState.filter.columnComments),
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([
                    pascrNumber,
                    description,
                    tagType,
                    subSystem,
                    approvalStage,
                    status,
                    crChangeType,
                    originatorDate,
                    targetCompletionDate,
                    rfi_RIMSRef,
                    mocRef,
                    tagNo,
                    comments
                ]) => {
                    this.columnPASCRNumber = pascrNumber as CheckListColumn;
                    this.columnDescription = description as CheckListColumn;
                    this.columnTagType = tagType as CheckListColumn;
                    this.columnSubSystem = subSystem as CheckListColumn;
                    this.columnApprovalStage = approvalStage as CheckListColumn;
                    this.columnStatus = status as CheckListColumn;
                    this.columnCRChangeType = crChangeType as CheckListColumn;
                    this.columnOriginatorDate = originatorDate as CalendarColumn;
                    this.columnTargetCompletionDate = targetCompletionDate as CalendarColumn;
                    this.columnRFI_RIMSRef = rfi_RIMSRef as CheckListColumn;
                    this.columnMOCRef = mocRef as CheckListColumn;
                    this.columnTagNo = tagNo as CheckListColumn;
                    this.columnComments = comments as CheckListColumn;
                }
            );
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null || selected === undefined ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null || selected === undefined ? false : selected.onlyBlanks;
        if(columnName === 'subSystem' ||columnName === 'mocRef' ||columnName === 'rfi_RIMSRef')
        {
            showBlankOptions = true;
        }
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 650, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                excludeBlanks: excludeBlanks,
                onlyBlanks: onlyBlanks,
                isSortingOff: false,
                showCounts: showCounts,
                showInputSearch: showInputSearch,
                showBlankOptions: showBlankOptions,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new PascrStatusFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
                cancelAction: () => {
                    this.store.dispatch(
                        new PascrStatusFilterPropertyUpdate({
                            key: propertyName,
                            value: this.filterForm.controls[propertyName].value,
                        })
                    );
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn,
        isSortingOff = false,
        fireSearch = true
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, null, null, {
                isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                isSortingOff: isSortingOff,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    if (fireSearch) {
                        this.search();
                    }
                },
            },
                345,
                580)
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new PascrStatusFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new PascrStatusFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'pascrNumber', direction: OrderDirection.Asc },
                })
            );
        }
    }

    resetColumns() {
        this.showHideColumns = new ShowHideColumns();
    }

    reverseValue(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };
        this.store.dispatch(
            new PascrStatusFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showHideColumns,
            })
        );
    }

    openCommentPopup(element: PascrStatusDTO, event: any) {
        const comment = element.comment || '';
        if (this.isReadOnly) return;

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                mentions: element.mentions,
                url: `pascrstatus?pascr=/${element.pascrNumber}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new PascrStatusAddBulkCommentRequest({
                                description: value,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new PascrStatusAddCommentRequest({
                                pascrNumber: element.pascrNumber,
                                description: value,
                                mentions,
                            })
                        );
                    }
                },
            })
        );

        event.stopPropagation();
    }

    openHistoryPopup(element: PascrStatusDTO) {
        if (element.comment === null) {
            return;
        } else {
            this.isLoading = true;
            this.commentService
                .getPascrComments(element.pascrNumber)
                .subscribe((data) => {
                    this.isLoading = false;
                    this.popupSvc.openPopup(
                        new PopupSettings(
                            CommentsHistoryComponent,
                            null,
                            null,
                            {
                                commentHist: data,
                                action: (id: number) => {
                                    data = data.filter((s) => s.id !== id);
                                    this.store.dispatch(
                                       new PascrStatusRemoveCommentRequest({
                                            id,
                                            pascrNumber: element.pascrNumber,
                                            lastComment: data.length > 0 ? data[0] : new CommentDetailDTO(),
                                        })
                                    );
                                },
                            },
                            200,
                            200
                        )
                    );
                });
        }
    }

    getPascrNumber = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(),'PASCRNumber');

    getDescription = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(),'Description');

    getSubSystem = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(),'SubSystem');

    getApprovalStage = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(),'ApprovalStage');

    getStatus = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(),'Status');

    getCRChangeType = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(),'CRChangeType');

    getRFI_RMSRef = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(),'RFI_RMSRef');

    getMOCRef = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(),'MOCRef');
   
    gettagNo = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(),'TagNo');

    exportToExcel() {
        this.store.dispatch(new PascrStatusExportToExcelRequest());
      }

    getComments = (search = '', take = 30, page = 0) =>
    this.lookupService.searchPascrNumberWithPascrStatusFilter(search, take, page, this.getLatestFilterData(), 'Comments');

}
