<div class="main">
    <table mat-table [dataSource]="backlogCats">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Backlog Category</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.isInEditMode">{{ element.name }}</div>
                <div *ngIf="element.isInEditMode">
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            [(ngModel)]="element.name"
                            [ngModelOptions]="{ standalone: true }"
                            name="name"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="!element.isInEditMode">
                    <button mat-icon-button (click)="enterEditMode(element)" title="edit">
                        <mat-icon svgIcon="edit"></mat-icon>
                    </button>
                    <button mat-icon-button (click)="remove(element)" title="remove">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="element.isInEditMode">
                    <button mat-icon-button (click)="update(element)" title="accept">
                        <mat-icon>check_circle_outline</mat-icon>
                    </button>
                    <button mat-icon-button (click)="cancelEdit(element)" title="cancel">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-toolbar>
        <span>Add new Backlog Category</span>
        <button mat-icon-button (click)="add()" title="add">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </mat-toolbar>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</div>
